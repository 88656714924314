import { useEffect, useState } from "react";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../../Components/Buttons/ETButton";
import { useLocation } from "react-router-dom";
import { useDeleteEidoColor, useGetEidoColor, usePostEidoColor, useUpdateEidoColor } from './../../../hooks/queries/useEidoQueries';
import { toast } from "react-toastify";
import { status } from "../../../model/Constant";

const Color = () => {
  let location = useLocation();
  const { state } = location;
  let propId = state?.propId;
  const { data: EidoColorData } = useGetEidoColor(propId);
  const { mutateAsync: PostEidoColor } = usePostEidoColor();
  const { mutateAsync: UpdateEidoColor } = useUpdateEidoColor();
  const { mutateAsync: DeleteEidoColor } = useDeleteEidoColor();
  
  const [colors, setColors] = useState({
    primary_text_color: "",
    primary_bg_color: "",
    cta_text_color: "",
    cta_bg_color: "",
  });

  useEffect(()=> {
    if(EidoColorData?.data[0]?.project_id !== undefined) setColors({
      ...EidoColorData?.data[0]
    })
    else setColors({
      primary_text_color: "",
      primary_bg_color: "",
      cta_text_color: "",
      cta_bg_color: "",
    })
  }, [EidoColorData, propId])

  let colorPostData = {}

  // handle colors
  const handleColors = (e) => {
    setColors({
      ...colors,
      [e.target.name]: e?.target?.value,
    });
  };

  const updateEidoColor = () => {
    UpdateEidoColor(colorPostData)
    .then((response) => {
      if (response.status === status.s) {
        toast.success(response.data[0]?.Message);
      } else {
        toast.info(response.data.message);
      }
    })
    .catch((error) => {
      toast.error(error.message);
    });
  }

  const postEidoColor = () => {
    console.log(colorPostData, 'colorPostData')
    PostEidoColor(colorPostData)
    .then((response) => {
      console.log(response, 'response')
      if (response.status === status.s) {
        toast.success(response.data[0]?.Message);
      } else {
        toast.info(response.data.message);
      }
    })
    .catch((error) => {
      toast.error(error.message);
    });
  }

  const handleSave = () => {
    console.log(EidoColorData,'chala')
    colorPostData = {
      "project_id": propId,
      ...colors
    }


    if(EidoColorData?.data?.[0]?.project_id) updateEidoColor();    // update eido color
    else if(EidoColorData?.data?.[0]?.message) postEidoColor(); // post eido color
    // else if(!EidoColorData) postEidoColor();  // post eido color
  }

  const handleReset = () => {

    DeleteEidoColor(propId)
    .then((response) => {
      if (response.status === status.s) {
          setColors({
          primary_text_color: "",
          primary_bg_color: "",
          cta_text_color: "",
          cta_bg_color: "",
        })
        toast.success('Reset Succesfully');
      } else {
        toast.info(response.data.message);
        toast.info("Color is Deleted for this proerty");
      }
    })
    .catch((error) => {
      toast.error(error.message);
    });
  }

  return (
    <div className="document-container p-4 my-2">
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          fontSize: "14px",
          border: "1px solid #F0F0F0",
        }}
      >
        <div
          className="px-4 py-2 d-flex align-items-center flex-wrap justify-content-between"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div style={{ fontFamily: "Cerebri Sans" }} className="d-flex">
            <div className="fw-600 fs-14">Color</div>
            <div style={{ paddingLeft: "15px" }}>
              <CustomToolTip height={12} text="Color" />
            </div>
          </div>
          <Row>
            <Col>
             <ETButton
                  variant={"primary"}
                  buttonText="Reset"
                  onClick={handleReset}
              />
            </Col>
            <Col>
            <ETButton
                  variant={"primary"}
                  buttonText="Save"
                  onClick={handleSave}
            />
            </Col>
          </Row>
        </div>
        <div className="document-content py-3 px-4">
          <Row className="align-items-center justify-content-start my-3">
            <Col lg={3}>
              <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">Primary text</p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="position-relative">
                <label className="radius-8 color-picker">
                  <div className="ps-2 d-flex position-relative align-items-center">
                    <input
                      value={colors.primary_text_color}
                      type="color"
                      name="primary_text_color"
                      onChange={(e) => handleColors(e)}
                      className="color-picker-input"
                    />
                    <span
                      className="fs-16 fw-300 px-2"
                    >
                      {colors.primary_text_color}
                    </span>
                  </div>
                </label>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-start my-3">
            <Col lg={3}>
              <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">Primary background</p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="position-relative">
                <label className="radius-8 color-picker">
                  <div className="ps-2 d-flex position-relative align-items-center">
                    <input
                      value={colors.primary_bg_color}
                      type="color"
                      name="primary_bg_color"
                      onChange={(e) => handleColors(e)}
                      className="color-picker-input"
                    />
                    <span
                      className="fs-16 fw-300 px-2"
                    >
                      {colors.primary_bg_color}
                    </span>
                  </div>
                </label>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-start my-3">
            <Col lg={3}>
              <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">CTA text</p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="position-relative">
                <label className="radius-8 color-picker">
                  <div className="ps-2 d-flex position-relative align-items-center">
                    <input
                      value={colors.cta_text_color}
                      type="color"
                      name="cta_text_color"
                      onChange={(e) => handleColors(e)}
                      className="color-picker-input"
                    />
                    <span
                      className="fs-16 fw-300 px-2"
                    >
                      {colors.cta_text_color}
                    </span>
                  </div>
                </label>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-start my-3">
            <Col lg={3}>
              <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">CTA background</p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="position-relative">
                <label className="radius-8 color-picker">
                  <div className="ps-2 d-flex position-relative align-items-center">
                    <input
                      value={colors.cta_bg_color}
                      type="color"
                      name="cta_bg_color"
                      onChange={(e) => handleColors(e)}
                      className="color-picker-input"
                    />
                    <span
                      className="fs-16 fw-300 px-2"
                    >
                      {colors.cta_bg_color}
                    </span>
                  </div>
                </label>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Color;
