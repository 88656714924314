import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useUserDetails } from "../../hooks/stores/useUserDetailsStore";
import { useOrganizationId } from "../../hooks/stores/useOrganizationStore";
import {
  useGetOrganizationFloorType,
  useGetOrganizationSideType,
} from "../../hooks/queries/useOrganization";
import { useGetOwners } from "../../hooks/queries/useUserQueries";
import { useGetGeographyCountry } from "../../hooks/queries/usePropertyQueries";
import {
  useGetStreetDetails,
  useGetZipcodeByCountry,
} from "../../hooks/queries/useGeographyQueries";
import { capitalizeEachWord } from "../../utils/TextTransform";
import { Col, Form, Row } from "react-bootstrap";
import { H4 } from "../../styles/Common.style";
import InputField from "../Textfield/InputField";
import { SelectPhoneNumber } from "../phoneNumberDropdown/SelectPhoneNumber";
import ETButton from "../Buttons/ETButton";
import StreetInput from "../Textfield/StreetInput";
import ReactSelect from "../DropDown/ReactSelect";
import { useGetTeamsByLevel } from "../../hooks/queries/useTeam";
import { useDivisionId } from "../../hooks/stores/useDivisionsStore";
import CustomReactSelect from "../../Components/DropDown/CustomReactSelect";

const CreateNewInformation = ({
  setStepWizard,
  setProgress,
  setShow,
  flag,
  handleNext,
  handleClose,
  formData = () => {},
  setFormData = () => {},
  commonType,
  flagEdit,
  // divisionId,
}) => {
  //-------------------------- states -----------------------------
  const [searchedStreetName, setSearchedStreetName] = useState("");
  const [isSearchStreetVisible, setIsSearchStreetVisible] = useState(false);
  const [showCreateDivisionOwner, setShowCreateDivisionOwner] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    reset,
    resetField,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      div_name: "",
      division_owner: "",
      property_name: "",
      construction_year: "",
      property_type_id: "",
      company: "",
      care_of: "",
      cvr_number: "",
      vat_number: "",
      gst_number: "",
      floor_number: "",
      country: "",
      street: "",
      side: "",
      door: "",
      zipcode_id: "",
      city: "",
      contact_person_name: "",
      contact_person_email: "",
      country_code: "",
      contact_person_phone_no: "",
      house_number: "",
      short_name: "",
      website: "",
      team_access: "",
    },
  });

  //------------- zustand ------------------//
  const { userDetails } = useUserDetails((state) => state);
  const { organizationId } = useOrganizationId((state) => state);
  const { divisionId } = useDivisionId((state) => state);
  //------------- React Query ------------------//
  const { data: getOrganizationFloorTypeData } = useGetOrganizationFloorType();
  const { data: getOrganizationSideTypeData } = useGetOrganizationSideType();
  const { data: GetOwnerData } = useGetOwners(
    flagEdit ? "D" : "O",
    flagEdit ? divisionId : organizationId
  );

  // ------------------------- react query ----------------------
  const { data: GetTeamsByLevelData } = useGetTeamsByLevel(
    "d",
    divisionId,
    "basic"
  );

  const { data: GetcountryData } = useGetGeographyCountry();
  const { data: GetzipCodeByCountryIdData } = useGetZipcodeByCountry(
    getValues("country")?.value
  );
  const { data: GetStreetDetailsData } =
    useGetStreetDetails(searchedStreetName);

  // static Variable
  const d = new Date();
  let currentYear = d.getFullYear();
  let endYear = currentYear + 10;

  const constructionYear = [];
  for (let i = endYear; i >= 1750; i--) {
    constructionYear.push(i);
  }

  //------Handle street field change--------//
  const handleStreetFieldChange = (event) => {
    if (watch("country")?.value == "DK" && event.target.value?.length >= 4) {
      setIsSearchStreetVisible(true);
      setSearchedStreetName(event?.target?.value);
    } else {
      setIsSearchStreetVisible(false);
    }
  };

  //---------------------------- handle Next --------------------------//
  const handleNextCompanyInfo = (data) => {
    // setStepWizard("CreateNewBillingDetails");
    // setProgress(99);
    handleNext(data);
  };

  //----------------------------handle Close--------------------------//
  const handleCloseCustomerInfo = () => {
    // setShow(false);
    setFormData({
      ...formData,
      ...getValues(),
    });
    handleClose();
  };

  //----------------------------Zipcode handler--------------------------//
  const handleZipCode = (e, ActionType) => {
    if (ActionType.action === "clear") {
      setValue("city", "", { shouldValidate: true });
    } else {
      if (e?.value != "" || e?.value != "undefined") {
        const HandleCityName = GetzipCodeByCountryIdData?.data?.filter(
          (data) => data?.Id == e?.value
        );
        const CityData = HandleCityName[0].Text;
        setValue("city", CityData, { shouldValidate: true });
      }
    }
  };

  //----------------------------handle Street Detail Click--------------------------//
  const handleStreetDetailClick = (item, e) => {
    setIsSearchStreetVisible(false);
    setValue("street", item?.street_name);
    setValue("city", item?.city, { shouldValidate: item?.city !== "" && true });
    setValue("house_number", item?.house_number, {
      shouldValidate: item?.house_number !== "" && true,
    });
    setValue(
      "zipcode_id",
      item?.zipcode == ""
        ? ""
        : {
            value: item?.zipcode,
            label: GetzipCodeByCountryIdData?.data?.filter(
              (el) => el.Id == item?.zipcode
            )?.[0]?.Text,
          },
      { shouldValidate: item?.zipcode !== "" && true }
    );
  };

  //------------------country dropdown value--------------------------------//
  const displayItem = (selected) => {
    return selected
      ? {
          value: selected?.Id,
          label: GetcountryData?.data?.filter(
            (item, i) => item.Id === selected?.value
          )?.[0]?.Text,
        }
      : "";
  };

  //---------------------------- useEffect --------------------------//
  useEffect(() => {
    if (formData) {
      setValue("div_name", capitalizeEachWord(formData?.div_name));
      setValue("property_name", formData?.property_name);

      if (formData?.property_type_id) {
        setValue("property_type_id", formData?.property_type_id);
      }

      if (formData?.division_owner?.value) {
        setValue("division_owner", {
          value: formData?.division_owner?.value,
          label: formData?.division_owner?.label,
          isDisabled: formData?.division_owner?.isDisabled,
        });
      }
      if (formData?.construction_year?.value) {
        setValue("construction_year", formData?.construction_year);
      }
      // setValue("division_id", formData?.division_id?.value);

      setValue("company", formData?.company);
      setValue("sort_name", formData?.sort_name);

      setValue("care_of", formData?.care_of);
      setValue("short_name", formData?.short_name);
      if (formData?.cvr_number) setValue("cvr_number", formData?.cvr_number);
      if (formData?.vat_number) setValue("vat_number", formData?.vat_number);
      if (formData?.gst_number) setValue("gst_number", formData?.gst_number);

      setValue("street", formData?.street);
      setValue("house_number", formData?.house_number);

      if (formData?.floor_number?.value) {
        setValue("floor_number", {
          value: formData?.floor_number?.value,
          label: formData?.floor_number?.label,
          isDisabled: true,
        });
      }

      if (formData?.side?.value) {
        setValue("side", {
          value: formData?.side?.value,
          label: formData?.side?.label,
          isDisabled: true,
        });
      }

      if (formData?.country?.value) {
        setValue("country", {
          value: formData?.country?.value,
          label: formData?.country?.label,
          isDisabled: true,
        });
      }
      setValue("door", formData?.door);

      if (formData?.zipcode_id?.value) {
        setValue("zipcode_id", {
          value: formData?.zipcode_id?.value,
          label: formData?.zipcode_id?.label,
          isDisabled: true,
        });
      }
      setValue("city", formData?.city);
      setValue("contact_person_name", formData?.contact_person_name);
      setValue("contact_person_email", formData?.contact_person_email);
      if (formData?.country_code) {
        setValue("country_code", formData?.country_code);
      }
      setValue("contact_person_phone_no", formData?.contact_person_phone_no);
      setValue("website", formData?.website);
      setValue(
        "team_access",
        formData?.team_access?.map((item) => ({
          value: item?.value,
          label: item?.label,
        }))
      );
    }
  }, [formData]);

  useEffect(() => {
    if (
      getValues("cvr_number")?.length ||
      getValues("vat_number")?.length ||
      getValues("gst_number")?.length
    ) {
      clearErrors(["cvr_number", "vat_number", "gst_number"]);
    }
  }, [watch("cvr_number"), watch("vat_number"), watch("gst_number")]);

  const EnergyLabelFilter = [
    { Id: "A2020", Text: "A2020" },
    { Id: "A2015", Text: "A2015" },
    { Id: "A2010", Text: "A2010" },
    { Id: "B", Text: "B" },
    { Id: "C", Text: "C " },
    { Id: "D", Text: "D" },
    { Id: "E", Text: "E" },
    { Id: "F", Text: "F" },
    { Id: "G", Text: "G" },
  ];

  return (
    <>
      <div>
        <Form onSubmit={handleSubmit(handleNextCompanyInfo)}>
          <div className="scrollable-container-y" style={{ height: "450px" }}>
            {flag == "Division" && (
              <>
                <div className="px-4 pt-4">
                  <Row>
                    <H4 className="mb-3">Division name</H4>
                    <Col
                      xl={3}
                      lg={4}
                      md={6}
                      className="pe-sm-3 mb-lg-3 mb-sm-3 "
                    >
                      <Controller
                        control={control}
                        {...register("div_name")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            id={"div_name"}
                            value={value}
                            type="text"
                            placeholder="Name"
                            textTransform="capitalize"
                            errorscope
                            iserror={errors?.div_name}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("div_name");
                            }}
                          />
                        )}
                      />
                    </Col>
                    {flag === "Division" ? (
                      <Col
                        xl={3}
                        lg={4}
                        md={6}
                        className="pe-3 ps-lg-0 mb-sm-3"
                      >
                        <Controller
                          control={control}
                          {...register("short_name")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"short_name"}
                              value={value}
                              type="text"
                              textTransform="uppercase"
                              placeholder="Shortname (3 letters max)"
                              errorscope
                              maxLength={3}
                              iserror={errors?.short_name}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("short_name");
                              }}
                            />
                          )}
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </div>

                <Row className="px-4 pb-4">
                  <Col
                    xl={3}
                    lg={4}
                    md={6}
                    className="pe-sm-3 pe-lg-3 mb-lg-0 mb-sm-3"
                  >
                    <Controller
                      control={control}
                      {...register("division_owner")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder={"Division owner"}
                          iserror={errors?.division_owner}
                          options={GetOwnerData?.data?.map((item) => ({
                            value: item?.Id,
                            label: item?.Text,
                            isDisabled: value?.value == item?.Id,
                          }))}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("division_owner");
                          }}
                          clearable={false}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <hr className="hr m-0" />
              </>
            )}

            {flag == "Property" && (
              <>
                <div className="px-4  pt-4">
                  <H4 className="mb-3">Property info</H4>
                  <Row>
                    <Col
                      xl={3}
                      lg={4}
                      md={6}
                      className=" pe-sm-3 mb-lg-0 mb-sm-3"
                    >
                      <Controller
                        control={control}
                        {...register("property_name")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            id={"property_name"}
                            value={value}
                            type="text"
                            placeholder="Name"
                            textTransform="capitalize"
                            errorscope
                            iserror={errors?.property_name}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("property_name");
                            }}
                          />
                        )}
                      />
                    </Col>
                    <Col xl={3} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                      <Controller
                        control={control}
                        {...register("construction_year")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <ReactSelect
                            placeholder={"Construction year"}
                            iserror={errors?.construction_year}
                            options={constructionYear.map((Year) => ({
                              value: Year,
                              label: Year,
                            }))}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("construction_year");
                            }}
                            clearable={false}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </div>

                <Row className="px-4 ">
                  <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
                    <Controller
                      control={control}
                      {...register("property_type_id")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder={"Property type"}
                          iserror={errors?.property_type_id}
                          options={commonType?.map((item) => ({
                            value: item?.Id,
                            label: item?.Text,
                            isDisabled: value?.value == item?.Id,
                          }))}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("property_type_id");
                          }}
                          clearable={false}
                        />
                      )}
                    />
                  </Col>
                  <Col xl={3} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                    <Controller
                      control={control}
                      {...register("energy_label_id")}
                      // rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder={"Energy label"}
                          iserror={errors?.energy_label_id}
                          options={EnergyLabelFilter?.map((item) => ({
                            value: item?.Id,
                            label: item?.Text,
                            isDisabled: value?.value == item?.Id,
                          }))}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("energy_label_id");
                          }}
                          clearable={false}
                        />
                      )}
                    />
                  </Col>
                </Row>

                <Row className="px-4 ">
                  <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-3 mb-sm-3">
                    <Controller
                      control={control}
                      {...register("marketing_label")}
                      // rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder={"Marketing label"}
                          iserror={errors?.marketing_label}
                          // options={GetOwnerData?.data?.map((item) => ({
                          //   value: item?.Id,
                          //   label: item?.Text,
                          //   isDisabled: value?.value == item?.Id,
                          // }))}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("marketing_label");
                          }}
                          clearable={false}
                        />
                      )}
                    />
                  </Col>
                </Row>

                <Row className="px-4 pb-4">
                  <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
                    <Controller
                      control={control}
                      {...register("team_access")}
                      // rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <CustomReactSelect
                          placeholder={"Team access"}
                          // inputValue={value}
                          iserror={errors?.team_access}
                          isSearchable={false}
                          searchBarHeader="Search team access"
                          isMulti={true}
                          onChange={(e) => onChange(e)}
                          value={value}
                          options={GetTeamsByLevelData?.data?.team?.map(
                            (item) => ({
                              value: item?.Id,
                              text: item?.Text,
                              label: item?.Text,
                            })
                          )}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <hr className="hr m-0" />
              </>
            )}

            {/* Organisation details */}
            <H4 className=" pt-3 mb-3  ps-4">
              {(flag == "Division" && "Division details") ||
                (flag == "Company" && "Company details") ||
                (flag == "Property" && "Property details")}
            </H4>

            {flag !== "Property" && (
              <div className="px-4">
                <Row>
                  <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
                    <Controller
                      control={control}
                      {...register("company")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          id={"company"}
                          value={value}
                          type="text"
                          placeholder="Company"
                          textTransform="capitalize"
                          errorscope
                          iserror={errors?.company}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("company");
                          }}
                        />
                      )}
                    />
                  </Col>
                  {flag === "Company" ? (
                    <Col xl={3} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                      <Controller
                        control={control}
                        {...register("short_name")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            id={"short_name"}
                            value={value}
                            type="text"
                            textTransform="uppercase"
                            placeholder="Shortname (3 letters max)"
                            errorscope
                            maxLength={3}
                            iserror={errors?.short_name}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("short_name");
                            }}
                          />
                        )}
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col xl={3} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                    <Controller
                      control={control}
                      {...register("care_of")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          id={"C/O"}
                          value={value}
                          type="text"
                          placeholder="C/O"
                          textTransform="capitalize"
                          errorscope
                          maxLength={30}
                          iserror={errors?.care_of}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("care_of");
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col
                    xl={3}
                    lg={4}
                    md={6}
                    className="pe-sm-3 mb-lg-0 mb-sm-3 "
                  >
                    <Controller
                      control={control}
                      {...register("cvr_number")}
                      rules={{
                        required: !(
                          watch("vat_number")?.length ||
                          watch("gst_number")?.length
                        ),
                      }}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          id={"cvr_number"}
                          value={value}
                          type="text"
                          placeholder="CVR Number"
                          errorscope
                          maxLength={30}
                          iserror={errors?.cvr_number}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("cvr_number");
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col xl={3} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                    <Controller
                      {...register("vat_number")}
                      control={control}
                      rules={{
                        required: !(
                          watch("cvr_number")?.length ||
                          watch("gst_number")?.length
                        ),
                      }}
                      render={({ field: { onChange, value } }) => (
                        <div>
                          <InputField
                            type={"text"}
                            placeholder={"VAT Number"}
                            id={"VATNumber"}
                            errorscope
                            maxLength={30}
                            value={value}
                            iserror={errors?.vat_number}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("vat_number");
                            }}
                          />
                        </div>
                      )}
                    />
                  </Col>
                  <Col
                    xl={3}
                    lg={4}
                    md={6}
                    className="pe-lg-3 ps-lg-0  mb-sm-3"
                  >
                    <Controller
                      {...register("gst_number")}
                      control={control}
                      rules={{
                        required: !(
                          watch("cvr_number")?.length ||
                          watch("vat_number")?.length
                        ),
                      }}
                      render={({ field: { onChange, value } }) => (
                        <div>
                          <InputField
                            type={"text"}
                            placeholder={"GST Number"}
                            id={"GSTNumber"}
                            value={value}
                            errorscope
                            maxLength={30}
                            iserror={errors?.gst_number}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("gst_number");
                            }}
                          />
                        </div>
                      )}
                    />
                  </Col>
                </Row>
              </div>
            )}
            <div className="px-4 ">
              <Row>
                <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-3 mb-sm-3">
                  <Controller
                    control={control}
                    {...register("country")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder="Country"
                        clearable={false}
                        options={GetcountryData?.data?.map((item) => ({
                          value: item?.Id,
                          label: (
                            <div
                              className="d-flex align-items-center"
                              style={{ fontFamily: "Cerebri sans" }}
                            >
                              <div className="radius-4 me-2">
                                <img
                                  src={item?.DataValue1}
                                  style={{
                                    width: "20px",
                                    marginRight: "10px",
                                  }}
                                  alt=""
                                />
                              </div>
                              <div>
                                <div className="fs-14 light-font-text ">
                                  {" "}
                                  {item?.Text}
                                </div>
                              </div>
                            </div>
                          ),
                          isDisabled: value?.value == item?.Id,
                        }))}
                        iserror={errors?.country}
                        value={value == undefined ? "" : displayItem(value)}
                        onChange={(e, ActionType) => {
                          onChange(e);
                          resetField("city");
                          resetField("zipcode_id");
                          resetField("street_name");
                          clearErrors("country");
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
            </div>
            <div className="px-4">
              <Row>
                <Col
                  xl={3}
                  lg={4}
                  md={6}
                  className="pe-sm-3 mb-lg-0 mb-sm-3  position-relative"
                >
                  <StreetInput
                    isSearchStreetVisible={isSearchStreetVisible}
                    setIsSearchStreetVisible={setIsSearchStreetVisible}
                    handleStreetFieldChange={handleStreetFieldChange}
                    handleStreetDetailClick={handleStreetDetailClick}
                    control={control}
                    street_name={"street"}
                    errors={errors}
                    watch={watch}
                    searchedStreetName={searchedStreetName}
                    clearErrors={clearErrors}
                    country_name={"country"}
                  />
                  {/* <Controller
                    control={control}
                    {...register("street")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"street"}
                        value={value}
                        type="text"
                        placeholder="Street"
                        errorscope
                        maxLength={30}
                        iserror={errors?.street}
                        onChange={(e) => {
                          onChange(e);
                          handleStreetFieldChange(e);
                          clearErrors("street");
                        }}
                      />
                    )}
                  />
                  {watch("street")?.length < 4 &&
                  watch("street")?.length > 0 &&
                  watch("country")?.value == "DK" ? (
                    <div className="fs-12 fw-400 grey-text pt-1 ">
                      Type in minimum 4 characters to get a search result
                    </div>
                  ) : (
                    <>
                      {isSearchStreetVisible &&
                        watch("country")?.value == "DK" && (
                          <div style={{}}>
                            <div
                              className="bg-white radius-8 pe-1 filtered-list"
                              style={{
                                border: "1px solid #D1D1D1",
                                position: "absolute",
                                zIndex: "10",
                                width: "calc(100% - 12px)",
                              }}
                            >
                              <>
                                {GetStreetDetailsData?.data?.length > 0 ? (
                                  <div>
                                    {GetStreetDetailsData?.data?.map(
                                      (item, index) => (
                                        <div
                                          className="mt-1 px-2 light-font-text"
                                          style={{ cursor: "pointer" }}
                                          key={index}
                                          onClick={() => {
                                            handleStreetDetailClick(item);
                                            setIsSearchStreetVisible(false);
                                          }}
                                        >
                                          {item?.city ||
                                          item?.zipcode ||
                                          item?.house_number ? (
                                            <> {item?.address} </>
                                          ) : (
                                            <> {item?.street_name}</>
                                          )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <div className="mt-1 px-2 light-font-text">
                                    No data found
                                  </div>
                                )}
                              </>
                            </div>
                          </div>
                        )}
                    </>
                  )} */}
                </Col>
                <Col xl={2} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                  <Controller
                    control={control}
                    {...register("house_number")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"house_number"}
                        value={value}
                        type="text"
                        placeholder="No."
                        errorscope
                        maxLength={30}
                        iserror={errors?.house_number}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("house_number");
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xl={2} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                  <Controller
                    control={control}
                    {...register("floor_number")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Floor"}
                        iserror={errors?.floor_number}
                        options={getOrganizationFloorTypeData?.data?.map(
                          (item) => ({
                            value: item?.Id,
                            label: item?.Text,
                            isDisabled: value?.value == item?.Id,
                          })
                        )}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("floor_number");
                        }}
                        clearable={false}
                      />
                    )}
                  />
                </Col>
                <Col
                  xl={2}
                  lg={4}
                  md={6}
                  className="pe-sm-3 ps-xl-0 mb-lg-0 mb-sm-3 "
                >
                  <Controller
                    control={control}
                    {...register("side")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Side"}
                        iserror={errors?.side}
                        options={getOrganizationSideTypeData?.data?.map(
                          (item) => ({
                            value: item?.Id,
                            label: item?.Text,
                            isDisabled: value?.value == item?.Id,
                          })
                        )}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("side");
                        }}
                        clearable={false}
                      />
                    )}
                  />
                </Col>
                <Col xl={2} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3  ">
                  <Controller
                    control={control}
                    {...register("door")}
                    // rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"door"}
                        value={value}
                        type="text"
                        placeholder="Door"
                        errorscope
                        maxLength={30}
                        iserror={errors?.door}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("door");
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
            </div>
            <div className="px-4 ">
              <Row>
                <Col xl={2} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
                  <Controller
                    control={control}
                    {...register("zipcode_id")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder="Zip code"
                        options={GetzipCodeByCountryIdData?.data?.map(
                          (zip) => ({
                            value: zip?.Id,
                            label: `${zip?.Id} ${zip?.Text}`,
                            isDisabled: value?.value == zip?.Id,
                          })
                        )}
                        clearable={false}
                        iserror={errors?.zipcode_id}
                        value={
                          value?.value
                            ? {
                                value: value?.value,
                                label: value?.value,
                              }
                            : ""
                        }
                        onChange={(e, ActionType) => {
                          handleZipCode(e, ActionType);
                          onChange(e);
                          clearErrors("zipcode_id");
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="pe-lg-3 ps-lg-0  mb-sm-3">
                  <Controller
                    control={control}
                    {...register("city")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"city"}
                        value={value}
                        type="text"
                        placeholder="City"
                        errorscope
                        maxLength={30}
                        iserror={errors?.city}
                        disabled
                        // removeOpacity={!isSameDetailAsAboveActive}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("city");
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>{" "}
            </div>

            <div className="px-4 pb-4">
              <Row>
                <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
                  <Controller
                    {...register(`contact_person_name`)}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <InputField
                          type={"text"}
                          placeholder={"Contact Person"}
                          textTransform="capitalize"
                          id={"ContactPerson"}
                          value={value}
                          iserror={errors?.contact_person_name}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("contact_person_name");
                          }}
                        />
                      </div>
                    )}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="pe-lg-3 ps-lg-0  mb-sm-3">
                  <Controller
                    control={control}
                    {...register("contact_person_email")}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Please use a valid email address",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"contact_person_email"}
                        value={value}
                        type="email"
                        placeholder="Contact e-mail"
                        errormessage={
                          errors?.email?.type === "pattern"
                            ? errors?.email.message
                            : false
                        }
                        textTransform="lowercase"
                        errorscope
                        maxLength={30}
                        iserror={errors?.contact_person_email}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("contact_person_email");
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="pe-lg-3 ps-lg-0  mb-sm-3">
                  {/* <Controller
                  control={control}
                  {...register("country_code")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Country code"}
                      iserror={errors?.country_code}
                      options={GetcountryData?.data?.map((item) => ({
                        value: item?.Id,
                        label: item?.DataValue2,
                        isDisabled: value?.value == item?.Id,
                      }))}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("country_code");
                      }}
                    />
                  )}
                /> */}
                  <SelectPhoneNumber
                    errors={errors}
                    setValue={setValue}
                    getPhoneId={"country_code"}
                    getPhoneNumber={"contact_person_phone_no"}
                    control={control}
                    placeholder={"Phone"}
                  />
                </Col>
                <div className="pb-4">
                  <Row>
                    <Col
                      xl={3}
                      lg={4}
                      md={6}
                      className="pe-sm-3 mb-lg-0 mb-sm-3"
                    >
                      <Controller
                        control={control}
                        {...register("website")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            id={"website"}
                            value={value}
                            type="text"
                            placeholder="Website"
                            errorscope
                            maxLength={30}
                            iserror={errors?.website}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("website");
                            }}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </div>
                {/* <Col xl={3} lg={4} md={6} className="pt-3 ">
                <Controller
                  control={control}
                  {...register("contact_person_phone_no")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      id={"contact_person_phone_no"}
                      value={value}
                      type="number"
                      placeholder="Phone"
                      errorscope
                      maxLength={30}
                      iserror={errors?.contact_person_phone_no}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("contact_person_phone_no");
                      }}
                    />
                  )}
                />
              </Col> */}
              </Row>
            </div>
          </div>

          <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            {["Company", "Property"]?.includes(flag) ? (
              <ETButton
                type="button"
                variant={"white"}
                buttonText={flagEdit ? "Cancel" : "Back"}
                onClick={handleCloseCustomerInfo}
              />
            ) : (
              <ETButton
                type="button"
                variant={"white"}
                buttonText={"Cancel"}
                onClick={handleCloseCustomerInfo}
              />
            )}
            <ETButton
              type={"submit"}
              variant={"primary"}
              buttonText="NEXT"
              // onClick={handleNext}
            />
          </div>
        </Form>
        {/* <CreateDivisionOwner
          open={showCreateDivisionOwner}
          onHide={() => setShowCreateDivisionOwner(false)}
          onCancel={() => setShowCreateDivisionOwner(false)}
        /> */}
      </div>
    </>
  );
};

export default CreateNewInformation;
