import { Delete, Get, Post, Put } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

//Promotion label - Get main status
export const GetMainStatus = (unit_type) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.PromotionLabel_GetMainStatus, null, unit_type)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Promotion label - Get sub status
export const GetSubStatus = (mainstatus_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.PromotionLabel_GetSubstatus,
      null,
      mainstatus_id + "/substatus"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Promotion label - Get promotion label by property Id
export const GetPromotionLabelByPropertyId = (property_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.PromotionLabel_GetPromotionLabelByPropertyId,
      null,
      property_id + "/promotionlabel"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Promotion label - Get promotion label by property Id
export const GetPromotionLabelByLabelId = (property_id, label_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.PromotionLabel_GetPromotionLabelByLabelId,
      null,
      property_id + "/promotionlabel/" + label_id
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Add promotion label
export const PostAddPromotionLabel = async (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.PromotionLabel_PostAddPromotionLabel, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Add promotion label
export const PutUpdatePromotionLabel = async (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.PromotionLabel_PutUpdatePromotionLabel, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Delete promotion label
export const Delete_PromotionLabel = async (
  promotion_label_id,
  new_promotionLabel_id
) => {
  return new Promise((resolve, reject) => {
    Delete(
      Site.ET,
      Endpoint.ET.PromotionLabel_DeletePromotionLabel,
      null,
      `${promotion_label_id}/${new_promotionLabel_id}`
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Add promotion label configuration
export const PostAddPromotionLabelConfiguration = async (data) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.PromotionLabel_PostAddPromotionLabelConfiguration,
      null,
      data
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Promotion label - Get promotion label configuration by property Id
export const GetPromotionLabelConfigurationByPropertyId = (property_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.PromotionLabel_GetPromotionLabelConfigurationByPropertyId,
      null,
      property_id + "/promotionlabel/configuration"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Delete promotion label
export const Delete_PromotionLabelConfiguration = async (
  mainstatus_id,
  substatus_id,
  promotion_label_id
) => {
  return new Promise((resolve, reject) => {
    Delete(
      Site.ET,
      Endpoint.ET.PromotionLabel_DeletePromotionLabelConfiguration,
      null,
      mainstatus_id +
        "/substatus/" +
        substatus_id +
        "/promotionlabel/" +
        promotion_label_id +
        "/configuration"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
