import React, { useState } from "react";
import { H3, H4, H5 } from "../../../../styles/Common.style";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customproperties } from "../../../../styles/Theme";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import DateRangePicker from "../../../../Components/DateRangePicker/DateRangePicker";
import FilterComponent from "../../../../reactdataTableComponent/FilterComponent";
import DateRangeFilter from "../../../../Components/DateRangePicker/DateRangeFilter";
import ETAccordion from "../../../../Components/Accordion/ETAccordion";
import Toggle from "../../../../Components/Switch/Switch";
import ETButton from "../../../../Components/Buttons/ETButton";
import Menus from "../../../../Components/Menus/Menus";
import InputField from "../../../../Components/Textfield/InputField";
const Notes = ({ handleCloseButton = () => {} }) => {
  const date = new Date();
  const [dateRange, setDateRange] = useState([
    {
      startDate: date.setMonth(date.getMonth() - 1),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  //============= handle close popup ============//
  const handleClose = () => {
    handleCloseButton();
  };
  return (
    <div>
      {" "}
      <div className="p-4 border-bottom">
        <H4>Internal note</H4>
        <div className="light-font-text c-black w-75 py-2">
          Add a note related to this customer. Notes are only visible internally
          in the system and can only be viewed by users who have authorisation
          for the property and unit the note belongs to
        </div>
        <div
          className="radius-8"
          style={{ border: "1px solid #F0F0F0", backgroundColor: " #f9fafa" }}
        >
          <Form>
            <div className="p-4">
              <div className="d-flex align-items-center  gap-2 mb-3 ">
                <H4>Add note</H4> <CustomToolTip size="xs" text={"Add note"} />
              </div>
              <Row>
                <Col lg={4}>
                  <div className="mb-2">
                    <div className="form-input">
                      <input
                        type="text"
                        placeholder="Subject line"
                        id="subjectline"
                      />
                      <label for="subjectline">Subject line</label>
                    </div>
                  </div>
                  <Row>
                    <Col className="">
                      <div className="">
                        <ReactSelect placeholder={"Category"} />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={8} className="ps-0">
                  <div className="">
                    <InputGroup className="notes-textarea">
                      <Form.Control
                        rows={5}
                        as="textarea"
                        placeholder="Message"
                        aria-label="Large"
                        aria-describedby="inputGroup-sizing-sm"
                      />
                      <InputGroup.Text className="notes-textarea-icon">
                        <button
                          style={{
                            background: `${customproperties.primaryColor}`,
                          }}
                          className="message-send-button"
                        >
                          {
                            <FontAwesomeIcon
                              size="xs"
                              icon="fa-regular fa-paper-plane-top"
                              style={{ color: "#FFFFFF" }}
                            />
                          }
                        </button>
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </div>
      <div className="p-4">
        <Row>
          <Col xl={4} className="pb-2">
            <Row>
              <Col xl={10}>
                <FilterComponent />
              </Col>
            </Row>
          </Col>
          <Col xl={8}>
            <Row>
              <Col lg={3} className="pe-lg-0 pb-2">
                {" "}
                <ReactSelect placeholder={"Category"} />
              </Col>
              <Col lg={4} className="pe-lg-0 pb-2">
                {" "}
                <ReactSelect placeholder={"Created by"} />
              </Col>
              <Col lg={5} className="pb-2">
                <DateRangeFilter />
              </Col>
            </Row>
          </Col>
        </Row>
        <ETAccordion
          contextMenuRequired={true}
          buttonToggle={true}
          leftMenusContent={
            <>
              <button className="document-property-table-btn d-flex justify-content-center align-items-center">
                <FontAwesomeIcon
                  icon="fa-regular fa-pencil"
                  style={{ color: "#000000" }}
                />
              </button>
              <button className="document-property-table-btn d-flex justify-content-center align-items-center">
                <FontAwesomeIcon
                  icon="fa-regular fa-comments"
                  style={{ color: "#000000" }}
                />
              </button>
              <button className="document-property-table-btn d-flex justify-content-center align-items-center">
                <FontAwesomeIcon
                  icon="fa-regular fa-trash"
                  style={{ color: "#000000" }}
                />
              </button>
            </>
          }
          header={
            <div
              className=" p-4 radius-8"
              style={{
                height: "100%",
              }}
            >
              <H3 className="mb-2 ">
                Blomstervangen 13, 2.tv, 2765 Smørum | 212-005-002
              </H3>
              <div className="d-flex gap-3 mb-2">
                <div
                  style={{
                    backgroundColor: "#9370DB",
                    color: "white",
                    borderRadius: "4px",
                    fontSize: "9px",
                    alignItems: "center",
                    textTransform: "uppercase",
                  }}
                  className="mt-1 px-2  d-flex"
                >
                  DEPOSIT
                </div>
                <H4>subject line</H4>
              </div>
              <div className="d-flex gap-3 mb-2">
                <div className="fs-12"> 20 May, 2023 </div>
                <div className="fs-12">
                  Created by
                  <span className="form-label-title ps-1">
                    Max Wilderson Brentford
                  </span>
                </div>
              </div>
              <div className="light-font-text">
                Ut aut reiciendis voluptatibus maiores alias consequatur aut
                perferendis doloribus asperiores repellat. Ut aut reiciendis
                voluptatibus maiores alias consequatur aut perferendis doloribus
                asperiores repellat. Ut aut reiciendis voluptatibus maiores
                alias consequatur aut perferendis doloribus asperiores repellat.
                Ut aut reiciendis.
              </div>
            </div>
          }
          content={
            <>
              <div className="" style={{ background: "#f9fafa" }}>
                <div className="p-4 border-bottom">
                  <Row>
                    <Col xs={9}>
                      <div className="d-flex gap-3 mb-2">
                        <H5>subject line</H5>
                      </div>
                      <div className="d-flex gap-3 mb-2">
                        <div className="fs-12"> 20 May, 2023 </div>
                        <div className="fs-12">
                          Created by
                          <span className="form-label-title ps-1">
                            Max Wilderson Brentford
                          </span>
                        </div>
                      </div>
                      <div className="light-font-text">
                        Ut aut reiciendis voluptatibus maiores alias consequatur
                        aut perferendis doloribus asperiores repellat. Ut aut
                        reiciendis voluptatibus maiores alias consequatur aut
                        perferendis doloribus asperiores repellat. Ut aut
                        reiciendis voluptatibus maiores alias consequatur aut
                        perferendis doloribus asperiores repellat. Ut aut
                        reiciendis.
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="p-4 ">
                  <InputGroup className="notes-textarea">
                    <Form.Control
                      as="input"
                      type="text"
                      placeholder="Add comment..."
                      aria-label="Large"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                    <InputGroup.Text className="notes-input-icon">
                      <button
                        style={{
                          background: `${customproperties.primaryColor}`,
                        }}
                        className="message-send-button"
                      >
                        {
                          <FontAwesomeIcon
                            size="xs"
                            icon="fa-regular fa-paper-plane-top"
                            style={{ color: "#FFFFFF" }}
                          />
                        }
                      </button>
                    </InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
            </>
          }
        />
      </div>
      <div
        className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between
          justify-content-center footer-section-button-container"
      >
        <ETButton
          variant={"white"}
          buttonText="CLOSE"
          onClick={() => {
            handleClose();
          }}
        />
        <ETButton variant={"primary"} buttonText="SAVE" />
      </div>
    </div>
  );
};

export default Notes;
