import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { H4 } from "../../styles/Common.style";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import EtImageUploader from "../../Components/DropZone/EtImageUploader";
import ETButton from "../../Components/Buttons/ETButton";

const UploadDivisionThumbnails = ({
  handleUploadThumbnailBack,
  handleUploadThumbnailNext,
  flag,
  flagEdit,
  thumbnailFile,
  setThumbnailFile,
  thumbNailFilename,
  createButtonDisable,
  setThumbnailFileName,
}) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    primaryColor: "",
    secondaryColor: "",
    stroke: "",
    progressBar: "",
    text: "",
    links: "",
    company_thumbnail_bg: "",
    company_thumbnail_text: "",
  });

  const [toggle, setToggle] = useState(false);

  const handleBackButton = () => {
    handleUploadThumbnailBack();
  };

  const handleSaveButton = () => {
    handleUploadThumbnailNext();
  };

  return (
    <>
      <div className="scrollable-container-y" style={{ height: "450px" }}>
        <div className="p-4 border-bottom">
          <div className="d-flex align-items-center gap-2 mb-3">
            <H4>Thumbnail - Division</H4>
            <CustomToolTip text="Thumbnail - Division" size="sm" />
          </div>

          <div className="light-font-text-16 mb-3">
            This logo is used as the Division thumbnail in the system.
          </div>

          <div className="w-50">
            <EtImageUploader
              myFile={thumbnailFile}
              setMyFile={setThumbnailFile}
              // onFileUploaded={onThumbnailUploaded}
              initialFileName={thumbNailFilename.name}
              initialFileUrl={thumbNailFilename.url}
              initialFileSize={thumbNailFilename.fileSize}
              setThumbnailFileName={setThumbnailFileName}
            />
          </div>

          {/* Dropzone component */}
        </div>

        {/* <div className="p-4">
          <H5 className="mb-2">Use branding from Organisation</H5>

          <div className="light-font-text mb-3">
            If you don't want to apply the same system branding as defined on
            the Organisation, turn it off here.
          </div>

          <div className="d-flex align-items-center gap-3 mb-4">
            <Toggle
              id="use_branding_org"
              value={toggle}
              onChange={(e) => setToggle(e.target.checked)}
            />
            <div className="light-font-text-16">
              Use branding from Organisation
            </div>
          </div>

          {!toggle && (
            <div>
              <Row>
                <Col xl={3} lg={4} className="pe-lg-3 mb-sm-3">
                  <div className="d-flex align-items-center  gap-2 pb-2">
                    <H5>Primary color</H5>
                    <CustomToolTip size="sm" text="Primary color" />
                  </div>

                  <Controller
                    name="primaryColor"
                    control={control}
                    {...register("primaryColor")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <ColorInputField
                        label="Primary color"
                        isDisabled={false}
                        isRemoveOpacity={true}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        // onChange={(e) => setPrimaryColor(e.target.value)}
                      />
                    )}
                  />
                  {errors?.primaryColor && (
                    <p className="error-msg"> {REQUIRED_ERR}</p>
                  )}
                </Col>
                <Col xl={3} lg={4} className="ps-lg-0  ">
                  <div className="d-flex align-items-center  gap-2 pb-2">
                    <H5>Secondary color</H5>
                    <CustomToolTip size="sm" text="Secondary color" />
                  </div>
                  <Controller
                    name="secondaryColor"
                    control={control}
                    {...register("secondaryColor")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <ColorInputField
                        isDisabled={false}
                        isRemoveOpacity={true}
                        label="Secondary color"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        // onChange={(e) => setPrimaryColor(e.target.value)}
                      />
                    )}
                  />
                  {errors?.secondaryColor && (
                    <p className="error-msg"> {REQUIRED_ERR}</p>
                  )}
                </Col>
              </Row>
              <Row className="pt-3">
                <Col xl={3} lg={4} className="pe-lg-3  mb-sm-3">
                  <div className="d-flex align-items-center  gap-2 pb-2">
                    <H5>Stroke</H5>
                    <CustomToolTip size="sm" text="Stroke" />
                  </div>
                  <Controller
                    name="stroke"
                    control={control}
                    {...register("stroke")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <ColorInputField
                        isDisabled={false}
                        isRemoveOpacity={true}
                        label="Stroke"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        // onChange={(e) => setPrimaryColor(e.target.value)}
                      />
                    )}
                  />
                  {errors?.stroke && (
                    <p className="error-msg"> {REQUIRED_ERR}</p>
                  )}
                </Col>
                <Col xl={3} lg={4} className="ps-lg-0  ">
                  <div className="d-flex align-items-center  gap-2 pb-2">
                    <H5>Progress bar</H5>
                    <CustomToolTip size="sm" text="Progress bar" />
                  </div>
                  <Controller
                    name="progressBar"
                    control={control}
                    {...register("progressBar")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <ColorInputField
                        isDisabled={false}
                        isRemoveOpacity={true}
                        label="Progress Bar"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        // onChange={(e) => setPrimaryColor(e.target.value)}
                      />
                    )}
                  />
                  {errors?.progressBar && (
                    <p className="error-msg"> {REQUIRED_ERR}</p>
                  )}
                </Col>
              </Row>
              <Row className="pt-3">
                <Col xl={3} lg={4} className=" pe-lg-3  mb-sm-3">
                  <div className="d-flex align-items-center  gap-2 pb-2">
                    <H5>Text</H5>
                    <CustomToolTip size="sm" text="Text" />
                  </div>
                  <Controller
                    name="text"
                    control={control}
                    {...register("text")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <ColorInputField
                        isDisabled={false}
                        isRemoveOpacity={true}
                        label="Text"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        // onChange={(e) => setPrimaryColor(e.target.value)}
                      />
                    )}
                  />
                  {errors?.font && <p className="error-msg"> {REQUIRED_ERR}</p>}
                </Col>
                <Col xl={3} lg={4} className="ps-lg-0   ">
                  <div className="d-flex align-items-center  gap-2 pb-2">
                    <H5>Links</H5>
                    <CustomToolTip size="sm" text="Links" />
                  </div>
                  <Controller
                    name="links"
                    control={control}
                    {...register("links")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <ColorInputField
                        isDisabled={false}
                        isRemoveOpacity={true}
                        label="Links"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        // onChange={(e) => setPrimaryColor(e.target.value)}
                      />
                    )}
                  />
                  {errors?.links && (
                    <p className="error-msg"> {REQUIRED_ERR}</p>
                  )}
                </Col>
              </Row>
              <Row className="pt-3">
                <Col xl={3} lg={4} className=" pe-lg-3  mb-sm-3">
                  <div className="d-flex align-items-center  gap-2 pb-2">
                    <H5>Company thumbnail background</H5>
                    <CustomToolTip
                      size="sm"
                      text="Company thumbnail background"
                    />
                  </div>
                  <Controller
                    name="company_thumbnail_bg"
                    control={control}
                    {...register("company_thumbnail_bg")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <ColorInputField
                        isDisabled={false}
                        isRemoveOpacity={true}
                        label="Thumbnail background"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        // onChange={(e) => setPrimaryColor(e.target.value)}
                      />
                    )}
                  />
                  {errors?.font && <p className="error-msg"> {REQUIRED_ERR}</p>}
                </Col>
                <Col xl={3} lg={4} className="ps-lg-0">
                  <div className="d-flex align-items-center  gap-2 pb-2">
                    <H5>Company thumbnail text</H5>
                    <CustomToolTip size="sm" text="Company thumbnail text" />
                  </div>
                  <Controller
                    name="company_thumbnail_text"
                    control={control}
                    {...register("company_thumbnail_text")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <ColorInputField
                        isDisabled={false}
                        isRemoveOpacity={true}
                        label="Thumbnail text"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        // onChange={(e) => setPrimaryColor(e.target.value)}
                      />
                    )}
                  />
                  {errors?.links && (
                    <p className="error-msg"> {REQUIRED_ERR}</p>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </div> */}
      </div>

      <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
        <ETButton
          buttonText="back"
          variant={"white"}
          onClick={handleBackButton}
        />

        <div>
          <ETButton
            buttonText={
              flag == "Division" &&
              `${flagEdit ? "EDIT DIVISION" : "CREATE DIVISION"}`
            }
            disabled={createButtonDisable}
            variant={"primary"}
            type="submit"
            onClick={handleSaveButton}
          />
        </div>
      </div>
    </>
  );
};

export default UploadDivisionThumbnails;
