import React, { useMemo, useState } from "react";
import MRTTheme from "../../../Components/table/MRTTheme";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import {
  SearchStyle,
  SortIcon,
} from "../../../Components/table/MRTGlobalProps";
import { SearchIcon } from "../../../Components/table/MRTGlobalProps";
import { paginationoptions } from "../../../Components/table/MRTGlobalProps";
import ShowTooltip from "../../../Components/Tooltip/ShowTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menus from "../../../Components/Menus/Menus";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../../Components/Buttons/ETButton";
import { data } from "./StaticData";

import CustomPopup from "../../../Components/CustomPopup";
import Toggle from "../../../Components/Switch/Switch";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
const InheritedDocuments = ({ level = "division" }) => {
  const [deleteDocument, setDeleteDocument] = useState(false);
  const [sorting, setSorting] = useState([]);
  const columns = useMemo(
    () => [
      {
        accessorKey: "type",
        header: "Type",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
        Cell: ({ row }) => (
          <div className="d-flex justify-content-center gap-2 ">
            <ShowTooltip
              tooltipLabel={
                <button className="document-property-table-btn d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon
                    icon="fa-regular fa-file-pdf"
                    className="fs-6"
                  />
                </button>
              }
              tooltopWidth={80}
              tooltipContent={<div className="p-2 fs-12">Preview</div>}
            />
          </div>
        ),
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 180,
      },
      {
        accessorKey: "documentType",
        header: "Document type",
        size: 120,
      },
      {
        accessorKey: "dateUploaded",
        header: "Date of upload",
        size: 100,
      },
      {
        accessorKey: "inheritfrom",
        header: "Inherited form",
        size: 180,
      },
      {
        accessorKey: "inherit",
        header: "Inherit to companies",
        size: 180,
        Cell: ({ row }) => (
          <div>
            <Toggle
              id={row?.original?.inherit}
              checked={row?.original?.inherit == 1 ? true : false}
            />
          </div>
        ),
      },
      {
        accessorKey: "table_menu", //normal accessorKey
        header: "",
        size: 50,
        enableSorting: false,
        Cell: ({ cell }) => (
          <div className="d-flex gap-2">
            <ShowTooltip
              tooltipLabel={
                <button
                  style={{ height: "23px", width: "23px" }}
                  className="document-property-table-btn d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon
                    icon="fa-regular fa-file-magnifying-glass"
                    size="md"
                  />
                </button>
              }
              tooltopWidth={80}
              tooltipContent={<div className="p-2 fs-12">Preview</div>}
            />

            <ShowTooltip
              tooltipLabel={
                <button
                  onClick={() => {
                    setDeleteDocument(true);
                  }}
                  style={{ height: "23px", width: "23px" }}
                  className="document-property-table-btn d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon="fa-regular fa-trash" size="md" />
                </button>
              }
              tooltopWidth={80}
              tooltipContent={<div className="p-2 fs-12">Delete</div>}
            />
            <Menus
              itemheight={23}
              itemwidth={23}
              iconborderradius="4px"
              itemcontainerwidth="172px"
              component={
                <div
                  className="addpropertyCountryHandle "
                  style={{ fontSize: "14px", width: "198px" }}
                >
                  <div className="addpropContent px-3 py-2 ">
                    <span className="ms-1">View document</span>
                  </div>

                  <hr className="m-0" />
                  <div
                    className="addpropContent px-3 py-2"
                    onClick={() => {
                      setDeleteDocument(true);
                    }}
                  >
                    <span className="ms-1">Delete</span>
                  </div>
                </div>
              }
            />
          </div>
        ),
      },
    ],
    []
  );
  const renderEmptyRowsFallback = () => {
    const isEmpty = data && data?.length === 0;

    return (
      <div className="d-flex justify-content-center w-100 grey-text py-4">
        {isEmpty ? (
          <div>You do not have any inherited documents yet.</div>
        ) : (
          <div className="fs-14">Sorry! No results found</div>
        )}
      </div>
    );
  };
  return (
    <div className="whiteContainerStyle">
      {" "}
      <div>
        <MRTTheme
          colorVariantsForTableData={["odd"]}
          table={
            <MaterialReactTable
              columns={columns?.map((columns) => {
                return {
                  ...columns,
                  sortDescFirst: false,
                };
              })}
              data={data ?? []}
              icons={{
                ArrowDownwardIcon: () => SortIcon?.ArrowDownwardIcon(sorting),
                SyncAltIcon: () => SortIcon?.SyncAltIcon(),
              }}
              state={{
                sorting: sorting,
              }}
              onSortingChange={setSorting}
              enableFilterMatchHighlighting={true}
              enableGlobalFilter
              renderEmptyRowsFallback={renderEmptyRowsFallback}
              enableMultiRowSelection={false}
              enableRowSelection={false}
              // enableRowSelection
              // onRowSelectionChange={setRowSelection}// to add u
              globalFilterFn="contains" //turn off fuzzy matching and use simple contains filter function
              enableRowActions={false}
              enableColumnActions={false}
              muiSearchTextFieldProps={{
                ...SearchStyle,

                InputProps: {
                  ...SearchIcon,
                },
              }}
              renderRowActions={false}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiTablePaperProps={{
                sx: {
                  borderRadius: "0px 0px 8px 8px !important",
                },
              }}
              renderTopToolbar={({ table }) => (
                <>
                  <div className="px-4 pt-4">
                    {" "}
                    <Row>
                      <Col xl={6}>
                        <div className="d-flex align-items-center gap-2">
                          <div className=" form-label-title">
                            Inherited documents form levels above
                          </div>
                          <CustomToolTip
                            size="sm"
                            text={"Inherited documents form levels above "}
                          />
                        </div>
                        <div className="light-font-text">
                          Below are documents inherited from levels above this{" "}
                          {level}.
                        </div>
                      </Col>
                      <Col xl={{ span: 3, offset: 3 }} md={6}>
                        <MRT_GlobalFilterTextField table={table} />
                      </Col>
                    </Row>
                  </div>
                </>
              )}
              positionGlobalFilter="left"
            />
          }
        />
      </div>
      <CustomPopup
        open={deleteDocument}
        onHide={() => setDeleteDocument(false)}
        title="Delete document?"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="p-4">
          <div className="light-font-text mb-3">
            This document is inherited. If you delete this document, it will be
            deleted from {level} and on all lower levels in the system.
          </div>
          <div className="light-font-text mb-3">
            If the document is published, used in a signing package or used on a
            contract, it will then not be deleted there.
          </div>
          <div className="light-font-text">
            Are you sure you want to delete this document?
          </div>
        </div>
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET?"
            onClick={() => {
              setDeleteDocument(false);
            }}
          />
          <ETButton
            variant={"primary"}
            buttonText="DELETE"
            onClick={() => {}}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default InheritedDocuments;
