import React, { useMemo, useState } from "react";
import { CircleMsg, H4 } from "../../../../styles/Common.style";
import ETButton from "../../../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TagToolTip from "../../../../Components/Tooltip/PropertyTagToolTip";
import MRTTheme from "../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";
import Menus from "../../../../Components/Menus/Menus";
import {
  paginationoptions,
  SortIcon,
} from "../../../../Components/table/MRTGlobalProps";
import DateRangeFilter from "../../../../Components/DateRangePicker/DateRangeFilter";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { Col, Row } from "react-bootstrap";
import AddResponsibleAgent from "../../standardComponents/AddResponsibleAgent";
import CustomerDetailTooltip from "../../standardComponents/CustomerDetailTooltip";
import MoveToAnotherUnit from "../MoveToAnotherUnit";
import CreateNewContractWizard from "../createNewContract/CreateNewContractWizard";
import CreateNewInvitation from "./createNewInvitation/CreateNewInvitation";
import RestartScreening from "../../screenings/popups/RestartScreening";
import { ActionsPopup } from "../../screenings/popups/ActionsPopup";
import Heart from "../../../../Components/Star/Heart";
import FollowUp from "../../standardComponents/follow up/FollowUp";
import { useCustomerFunnelTopLoadingBar } from "../../../../hooks/stores/useCustomerFunnelStore";
import CreateNewAppointments from "../CreateNewAppointments/CreateNewAppointments";

const Inquiries = () => {
  //----------Store to handle top loading bar----------//
  const { topLoadingProgressBar, setTopLoadingProgressBar } =
    useCustomerFunnelTopLoadingBar((state) => state);
  //----------Store to handle top loading bar----------//

  const [rowSelection, setRowSelection] = useState({}); // Initialize as an empty object
  const [showPopUp, setShowPopUp] = useState({ create: false, edit: false });
  const [agentDetails, setAgentDetails] = useState("");
  const [showStartScreening, setShowStartScreening] = useState(false);
  const [openNewAppointmentScreen, setOpenNewAppointmentScreen] =
    useState(false);

  /*Archive-popup*/
  const [ArchiveScreen, setArchiveScreen] = useState(false);
  const archiveScreening = (item) => {
    setArchiveScreen(true);
  };
  /*-- Follow-up customer  --*/
  const [showFollowsUpWizard, setShowFollowsUpWizard] = useState(false);
  const [selectedFollowUpIndex, setSelectedFollowUpIndex] = useState(0);
  const handleCustomerDetails = (pageIndex = 0) => {
    // console.log(pageIndex, "i asssssssssssss");
    setShowFollowsUpWizard(true);
    setSelectedFollowUpIndex(pageIndex);
  };
  /*invitation  */
  const [createNewInvitation, setCreateNewInvitation] = useState(false);
  const handleInvite = () => {
    if (Object?.keys(rowSelection).length > 0) {
      setCreateNewInvitation(true);
    }
  };
  const [showMoveToAnotherUnits, setShowMoveToAnotherUnits] = useState(false);
  const [creatingNewContract, setCreatingNewContract] = useState(false);

  const columns = useMemo(
    () => [
      {
        accessorKey: "Id",
        header: "",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: true,
        Cell: ({ row }) => <Heart isFavorite="0" />,
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "0px !important",
            paddingLeft: "3px !important",
          },
        },
      },
      {
        accessorFn: (row) => `${row.Customer} ${row.Id}`,
        header: "Customer",
        enableHiding: false,
        // size: 100,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            <TagToolTip
              iconColor={"#888888"}
              size={"lg"}
              border="1px solid #F0F0F0"
              padding={"0px"}
              boxShadow="2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
              text={
                <div style={{ width: "700px" }}>
                  <CustomerDetailTooltip data={row?.original} />
                </div>
              }
            />
            {row.original.Customer}
          </div>
        ),
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
      },
      {
        accessorFn: (row) => `${row.Info}`,
        header: "Info",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div
            className=" cursor-pointer orange-red-text light-font-text"
            style={{ textDecoration: "underline" }}
          >
            Show
          </div>
        ),
      },

      {
        accessorKey: "Property",
        header: "Property",
        size: 100,
      },
      {
        accessorKey: "Unit", //normal accessorKey
        header: "Unit",
        size: 180,
      },
      {
        accessorKey: "Registration",
        header: "Registration",
        size: 180,
      },
      {
        accessorFn: (row) => `${row.ResponsibleAgent}`,
        header: "Responsible agent",
        enableHiding: false,
        size: 80,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>
            {row.original.ResponsibleAgent == "-" ? (
              <span className="grey-text">Unassigned</span>
            ) : (
              row.original.ResponsibleAgent
            )}{" "}
          </div>
        ),
      },
    ],
    []
  );

  return (
    <div className="p-4">
      {" "}
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div className="">
          <div className="d-flex align-items-center  pb-2">
            <H4> Inquiries </H4>
            <CircleMsg>
              <span>4</span>
            </CircleMsg>
          </div>
          <div className="light-font-text w-75">
            Below is a list of all active leads in the system that have not been
            on a personal viewing, have not registered for an event, have not
            started a booking or started a screening. These leads must therefore
            be handled.
          </div>
        </div>

        <ETButton
          variant={"primary"}
          buttonText={"SEND INVITATION"}
          disabled={Object?.keys(rowSelection).length == 0}
          onClick={() => handleInvite()}
        />
      </div>
      <MRTTheme
        colorVariantsForTableRow={["gray"]}
        colorVariantsForTableHead={["white"]}
        table={
          <MaterialReactTable
            // data={data || []}
            data={[]}
            columns={columns}
            onRowSelectionChange={setRowSelection}
            state={{ rowSelection }}
            renderTopToolbar={({ table }) => (
              <>
                <div className="px-0 ">
                  <Row>
                    <Col xl={4} lg={6} className="pe-lg-0">
                      <DateRangeFilter />
                    </Col>
                    <Col xl={3} lg={5}>
                      <ReactSelect placeholder={"Responsible agent"} />
                    </Col>
                  </Row>
                </div>
              </>
            )}
            enableRowSelection={true}
            enableColumnActions={false}
            // data={unitsData?.filter((data) => data.is_linked_with_screening !== 1) ?? []}
            icons={{ ...SortIcon }}
            enableRowActions={true}
            renderRowActions={({ row, table }) => (
              <Menus
                itemheight={24}
                itemwidth={24}
                iconborderradius="4px"
                itemcontainerwidth="210px"
                itemcontainerheight="300px"
                component={
                  <>
                    <div className="light-font-text">
                      <div
                        className="addpropContent px-3 py-2 "
                        onClick={() => {
                          setOpenNewAppointmentScreen(true);
                          setTopLoadingProgressBar(25);
                        }}
                      >
                        <span className="ms-1">Create appointment</span>
                      </div>
                      <hr className=" m-0"></hr>
                      <div
                        className="addpropContent px-3 py-2 "
                        onClick={() => {
                          setCreatingNewContract(true);
                          setTopLoadingProgressBar(33);
                        }}
                      >
                        <span className="ms-1">Create contract</span>
                      </div>
                      <hr className="m-0"></hr>

                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => setShowStartScreening(true)}
                      >
                        <span className="ms-1">Start screening</span>
                      </div>
                      <hr className="m-0"></hr>
                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => handleCustomerDetails(2)}
                      >
                        <span className="ms-1">Open customer card</span>
                      </div>
                      <hr className="m-0"></hr>
                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => setShowMoveToAnotherUnits(true)}
                      >
                        <span className="ms-1">Move to another unit</span>
                      </div>
                      <hr className="m-0"></hr>
                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => handleCustomerDetails(0)}
                      >
                        <span className="ms-1">Go to customer space </span>
                      </div>
                      <hr className="m-0"></hr>
                      {row.original.ResponsibleAgent == "-" ? (
                        <div
                          className="addpropContent px-3 pt-2 pb-2"
                          onClick={() =>
                            setShowPopUp({ ...showPopUp, create: true })
                          }
                        >
                          <span className="ms-1">Add responsible agent</span>
                        </div>
                      ) : (
                        <div
                          className="addpropContent px-3 pt-2 pb-2"
                          onClick={() =>
                            setShowPopUp({ ...showPopUp, edit: true })
                          }
                        >
                          <span className="ms-1">Change responsible agent</span>
                        </div>
                      )}
                      <hr className="m-0"></hr>
                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => handleCustomerDetails(1)}
                      >
                        <span className="ms-1">Add a note</span>
                      </div>
                      <hr className="m-0"></hr>
                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => archiveScreening()}
                      >
                        <span className="ms-1">Archive</span>
                      </div>
                    </div>
                  </>
                }
              />
            )}
            muiTablePaperProps={{
              sx: {
                table: {
                  borderCollapse: "separate",
                  borderSpacing: "0 5px",
                },
              },
            }}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "",
                size: 50,
              },
              "mrt-row-select": {
                // size: 50,
                muiTableBodyCellProps: {
                  sx: {
                    padding: "0px 0px 0px 16px !important",
                  },
                },
                muiTableHeadCellProps: {
                  sx: {
                    padding: "12px 0px 0px 16px !important",
                  },
                },
              },
            }}
            muiTableContainerProps={{
              sx: {
                padding: "0px !important",
              },
            }}
            muiTablePaginationProps={{
              ...paginationoptions,
            }}
            positionGlobalFilter="right"
            positionToolbarDropZone="bottom"
            positionActionsColumn="last"
          />
        }
      />
      <ActionsPopup
        open={ArchiveScreen}
        onHide={() => setArchiveScreen(false)}
        whiteClick={() => setArchiveScreen(false)}
        title={"Archive Customer"}
        primaryButton={"Archive"}
        titleTooltipText={"Archive Customer"}
        firstText={"Do you want to archive this customer?"}
        secondText={"The customer will be archived and moved to Archive tab."}
      />
      <CreateNewInvitation
        openNewInvitationScreen={createNewInvitation}
        setOpenNewInvitationScreen={setCreateNewInvitation}
        title={"Create new Invitation"}
        titleTooltipText={"Create new Invitation"}
        isPropertyHasRelation={false}
      />
      <AddResponsibleAgent
        showResponsibleAgentPopup={showPopUp.create}
        setShowResponsibleAgentPopup={setShowPopUp}
        // leadId={leadId}
        // createUpdateLeadAgentMutate={createUpdateLeadAgentMutate}
        // createUpdateLeadAgentLoading={createUpdateLeadAgentLoading}
        data={agentDetails}
        // leadsByDivisionIdDataRefetch={leadsByDivisionIdDataRefetch}
      />
      <AddResponsibleAgent
        showResponsibleAgentPopup={showPopUp.edit}
        setShowResponsibleAgentPopup={setShowPopUp}
        usedFor="EDIT"
        data={agentDetails}
        // leadId={leadId}
        // createUpdateLeadAgentMutate={createUpdateLeadAgentMutate}
        // createUpdateLeadAgentLoading={createUpdateLeadAgentLoading}
        // leadsByDivisionIdDataRefetch={leadsByDivisionIdDataRefetch}
      />
      {/*-------------------------- move to another units ------------------------------*/}
      <MoveToAnotherUnit
        showMoveToAnotherUnits={showMoveToAnotherUnits}
        setShowMoveToAnotherUnits={setShowMoveToAnotherUnits}
      />
      <CreateNewContractWizard
        creatingNewContract={creatingNewContract}
        setCreatingNewContract={setCreatingNewContract}
        setTopLoadingBar={setTopLoadingProgressBar}
        topLoadingBar={topLoadingProgressBar}
      />
      {/*-------------------------- Start screening popup ------------------------------*/}
      <RestartScreening
        open={showStartScreening}
        onHide={() => setShowStartScreening(false)}
        onCancel={() => setShowStartScreening(false)}
        usedFor="SSOC"
      />
      <FollowUp
        setFollowUp={setShowFollowsUpWizard}
        followUp={showFollowsUpWizard}
        selectedIndex={selectedFollowUpIndex}
        setSelectedIndex={setSelectedFollowUpIndex}
      />
      {/*-------------------------- Create appointment popup ------------------------------*/}
      <CreateNewAppointments
        openNewAppointmentScreen={openNewAppointmentScreen}
        setOpenNewAppointmentScreen={setOpenNewAppointmentScreen}
        setProgress={setTopLoadingProgressBar}
        progress={topLoadingProgressBar}
      />
    </div>
  );
};

export default Inquiries;

const data = [
  {
    Customer: "Cai Coffey",
    Info: "",
    Property: "Kaktus Towers",
    Unit: "Dyblosoro 3, 1 | 212-404",
    Registration: "12 Jul, 2023 at 10:32",
    ResponsibleAgent: "-",
    customer_details: [
      {
        firstname: "Cai",
        lastname: "Coffey",
        email: "cai@millicent.in",
        phone_calling_code_id: "+45",
        mobilenumber: "",
        age: "45",
        street_name: "Dybbølsbro",
        house_number: "37",
        floor_no: "2",
        side_door: "B",
        country_id: "DK",
        country: "Denmark",
        zipcode_id: "2000",
        city: "Frederiksberg",
        registration_date: "2023-10-18 08:15:16",
        language_id: "en",
        preferred_languages: "English",
        gdpr_confirmation: 0,
      },
    ],
  },
  {
    Customer: "Jacqueline George",
    Info: "",
    Property: "Blomsterbjerget",
    Unit: "Dyblosoro 3, 1 | 212-404",
    Registration: "12 Jul, 2023 at 10:32",
    ResponsibleAgent: "Allan schmith",
    customer_details: [
      {
        firstname: "Jacqueline",
        lastname: "George",
        email: "george@millicent.in",
        phone_calling_code_id: "+45",
        mobilenumber: "",
        age: "35",
        street_name: "Toldbodgade",
        house_number: "37",
        floor_no: "2",
        side_door: "B",
        country_id: "DK",
        country: "Denmark",
        zipcode_id: "2000",
        city: "Frederiksberg",
        registration_date: "2023-10-18 08:15:16",
        language_id: "en",
        preferred_languages: "English",
        gdpr_confirmation: 0,
      },
    ],
  },
  {
    Customer: "Solomon Welch",
    Info: "",
    Property: "Carolinelunden",
    Unit: "Dyblosoro 3, 1 | 212-404",
    Registration: "12 Jul, 2023 at 10:32",
    ResponsibleAgent: "Claus petersen",
    customer_details: [
      {
        firstname: "Solomon",
        lastname: "Welch",
        email: "solomon@millicent.in",
        phone_calling_code_id: "+45",
        mobilenumber: "",
        age: "29",
        street_name: "Toldbodgade",
        house_number: "37",
        floor_no: "2",
        side_door: "B",
        country_id: "DK",
        country: "Denmark",
        zipcode_id: "2000",
        city: "Frederiksberg",
        registration_date: "2023-10-18 08:15:16",
        language_id: "en",
        preferred_languages: "English",
        gdpr_confirmation: 0,
      },
    ],
  },
];
