import React, { useEffect, useMemo, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import { InputAdornment, Menu } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkedbox, UnCheckedbox } from "../../assets/icons/icons";
import TagFilterDropDown from "../../views/settings/Tags/TagFilterDropDown";
import { usePropertyId } from "../../hooks/stores/usePropertyStore";
import { useGetUnitsByPropertyIdUpdated } from "../../hooks/queries/useUnitQueries";
import MRTTheme from "../table/MRTTheme";
import {
  SearchIcon,
  SearchStyle,
  TableRowsStyle,
  paginationoptions,
  SortIcon,
} from "../table/MRTGlobalProps";
import CustomPopup from "../CustomPopup";
import ETButton from "../Buttons/ETButton";
import ScreeningFilterDropdown from "../../views/settings/screening/ScreeningFilterDropdown";
import { H2 } from "../../styles/Common.style";
import {
  useGetScreeningFormBasicDetails,
  useGetScreeningLinkedUnit,
} from "../../hooks/queries/useScreeningQueries";
import { useScreening } from "../../hooks/stores/useScreeningStore";

const ChooseFilter = [
  { Id: "1", Text: "Price" },
  { Id: "2", Text: "No of rooms" },
];

const tags = [
  { Id: "1", Text: "Not active", parent: "2" },
  { Id: "2", Text: "Available", parent: "2" },
  { Id: "3", Text: "Reserved", parent: "2" },
  { Id: "4", Text: "In process", parent: "2" },
  { Id: "5", Text: "Completed", parent: "2" },
  { Id: "6", Text: "Available", parent: "2" },
  { Id: "7", Text: "Total", parent: "2" },
];

const dumnmyDropDownData = {
  Price: "rent",
  "No of rooms": "no_of_rooms",
};

const ScreeningFilterTable = ({
  // setUnitsTableData,
  // unitsTableData,
  setSelectedUnitsId,
  addMoreUnits,
  setAddMoreUnits,
  unitsData,
  setUnitsData,
  // dataContainer,
  deletedUnit,
  rows,
  linkedUnitsData,
  rowSelection,
  setRowSelection,
}) => {
  //---------------------------zustant------------------------//
  const { screeningFormId, EditscreeningForm } = useScreening((state) => state);
  const propertyId = usePropertyId((state) => state.propertyId);

  //-------------------------------state-------------------------//
  // const [rowSelection, setRowSelection] = useState([]);
  console.log(
    "🚀 ~ file: ScreeningFilterTable.js:66 ~ rowSelection:",
    rowSelection
  );
  const [resetCondition, setResetCondition] = useState(false);
  const [dataContainer, setDataContainer] = useState([]);
  const [matchingUnits, setMatchingUnits] = useState("");
  const [unitsTableData, setUnitsTableData] = useState([]);
  const [applyBtn, setApplyBtn] = useState(false);
  const [matchingUnitsCount, setMatchingUnitsCounts] = useState("");
  const [newData, setNewData] = useState([]);
  const [originalTagData, setOriginalTagData] = useState([]);
  const [propertag, setPropertytag] = useState("");
  const [filter, setFilter] = useState(null);
  const selectdata = [];
  const [filterDropdowon, setFilterDropdowon] = useState("");
  const [filterDropData, setFilterDropData] = useState("");
  const [selectedDropdownData, setSelectedDropDownData] = useState([]);
  const [show, setShow] = useState();
  const [status, setStatus] = useState(false);
  const filteredTag = selectedDropdownData;
  const [selecteddata, setSelectedData] = useState([]);
  const [closeDropdown, setCloseDropdown] = useState(false);

  const [filterKeys, setFilterKeys] = useState({
    rent: [],
    no_of_rooms: [],
  });

  //---------------------------react query----------------------------//
  const {
    data: GetUnitsByPropertyIdData,
    refetch: GetUnitsByPropertyIdDataRefetch,
    isLoading,
  } = useGetUnitsByPropertyIdUpdated(propertyId, 0);
  const { data: getScreeningLinkedUnitData } = useGetScreeningLinkedUnit(
    screeningFormId,
    propertyId
  );

  //---------------------------------useeffect-----------------------------------------//

  // useEffect(() => {
  //   // Filter rowSelection based on the rows(when we delete the data from outer table then also remove check from iner table)
  //   const filteredRowSelection = Object?.keys(rowSelection)
  //     ?.filter((unit_id) => rows?.includes(unit_id))
  //     ?.reduce((acc, unit_id) => {
  //       acc[unit_id] = true;
  //       return acc;
  //     }, {});

  //   setRowSelection(filteredRowSelection);
  // }, [rows]);
  useEffect(() => {
    setUnitsTableData(GetUnitsByPropertyIdData?.data);
    setNewData(GetUnitsByPropertyIdData?.data);
    setOriginalTagData(GetUnitsByPropertyIdData?.data);
  }, [GetUnitsByPropertyIdData]);
  const { data: GetScreeningFormBasicDetailsData } =
    useGetScreeningFormBasicDetails(13);

  // useEffect(() => {
  //   setSelectedUnitsId(Object?.keys(rowSelection));
  // }, [rowSelection]);

  // useEffect(() => {
  //   if (resetCondition == true) {
  //     setUnitsTableData(dataContainer);
  //   }
  // }, [resetCondition]);

  // useEffect(() => {
  //   if (GetUnitsByPropertyIdData) {
  //     const updatedRowSelections = {};
  //     GetUnitsByPropertyIdData?.data?.filter(item => item.is_linked_with_screening == 1)?.forEach(item => {
  //       updatedRowSelections[item.unit_id] = true;
  //     });
  //     setRowSelection(updatedRowSelections);
  //   }
  // }, [GetUnitsByPropertyIdData]);

  useEffect(() => {
    // Set rowSelection to include unit IDs where is_linked_with_screening is equal to 1 by default
    const defaultSelection = {};
    GetUnitsByPropertyIdData?.data?.forEach((item) => {
      if (item.is_linked_with_screening == 1) {
        defaultSelection[item.unit_id] = true;
      }
    });
    setRowSelection(defaultSelection);

    setUnitsTableData(GetUnitsByPropertyIdData?.data);
  }, [GetUnitsByPropertyIdData]);

  // useEffect(() => {
  //   const linkedUnitIds = GetScreeningFormBasicDetailsData?.data?.[0]?.unit_data?.map(unit => String(unit.unit_id))
  //   const filteredGetUnitsByPropertyIdData = GetUnitsByPropertyIdData?.data?.filter(item => !linkedUnitIds?.includes(item.unit_id))
  //   setUnitsTableData(filteredGetUnitsByPropertyIdData)
  // }, [GetUnitsByPropertyIdData])

  useEffect(() => {
    if (resetCondition == true) {
      setUnitsTableData(GetUnitsByPropertyIdData?.data);
    }
  }, [resetCondition]);

  // const handleAddUnits = () => {
  //   let unitIds = Object?.keys(rowSelection);
  //   const filtered = GetUnitsByPropertyIdData?.data?.filter((item) =>
  //     unitIds?.includes(item?.unit_id)
  //   );
  //   // Combine the data from both APIs into a single array
  //   const mergedData = [...filtered];

  //   setUnitsData(mergedData);
  //   setAddMoreUnits(false);
  // };

  const handleAddUnits = () => {
    let unitIds = Object?.keys(rowSelection);
    const filtered = GetUnitsByPropertyIdData?.data?.filter((item) =>
      unitIds?.includes(item?.unit_id)
    );
    // Filter out units where is_linked_with_screening is equal to 1
    const filteredUnits = filtered?.filter(
      (item) => item?.is_linked_with_screening != 1
    );
    // Combine the data from both APIs into a single array
    if (EditscreeningForm) {
      const editMergedData = [...filtered];
      setUnitsData(editMergedData);
    } else {
      const mergedData = [...filteredUnits];
      setUnitsData(mergedData);
    }

    setAddMoreUnits(false);

    // setUnitsTableData()
  };

  const handleClearFilter = () => {
    setFilterKeys({
      rent: [],
      no_of_rooms: [],
    });

    setResetCondition(true);
    setFilter("");
  };

  const handleChange = (filter, ActionTypes) => {
    if (ActionTypes.action === "clear") {
      setFilter("");
    } else {
      setFilter(filter);
    }
    setPropertytag(true);
    setShow(false);
    let a = dumnmyDropDownData[filter?.label];
    let b = [...new Set(newData?.map((data) => data[a]))];
    setSelectedDropDownData(b);
    // setFilter(filter.label);
    setFilterDropdowon(ChooseFilter);

    const checkSelectedLabel = filter?.label;
    if (checkSelectedLabel == "Status") {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };
  const test = filter;
  const filteredTagdata = filter
    ? tags.filter((tag) => tag.parent === filter.value)
    : [];

  // Function to push data into a specific filter key
  const pushDataToFilterKey = (key, value) => {
    if (filterKeys[key]?.includes(value)) {
    } else {
      setFilterKeys((prevFilterKeys) => ({
        ...prevFilterKeys,
        [key]: [...prevFilterKeys[key], value],
      }));
    }
  };
  const multiPropsFilter = (unitsTableData, filters) => {
    const filterKeys = Object?.keys(filters);
    const filterdata = originalTagData?.filter((product) => {
      return filterKeys?.every((key) => {
        if (!filters[key].length) return true;
        // Loops again if product[key] is an array (for material attribute).
        if (Array?.isArray(product[key])) {
          return product[key]?.some((keyEle) => filters[key]?.includes(keyEle));
        }
        return filters[key]?.includes(product[key]);
      });
    });
    setUnitsTableData(filterdata);
  };
  //--------------matching units--------------------------//
  const filterKeysLength = Object?.keys(filterKeys);
  const filterDataLength = originalTagData?.filter((product) => {
    return filterKeysLength?.every((key) => {
      if (!filterKeys[key].length) return true;
      // Loops again if product[key] is an array (for material attribute).
      if (Array?.isArray(product[key])) {
        return product[key]?.some((keyEle) =>
          filterKeys[key]?.includes(keyEle)
        );
      }
      return filterKeys[key]?.includes(product[key]);
    });
  });
  const handleFilterData = (i) => {
    const selectfilterdata = filter?.label;
    const parent = ChooseFilter.find(
      (filter) => filter.Id === selectfilterdata
    )?.parent;

    if (selectfilterdata == "Price") {
      const key = "rent";
      const value = i;
      pushDataToFilterKey(key, value);
    } else if (selectfilterdata === "No of rooms") {
      const key = "no_of_rooms";
      const value = i;
      pushDataToFilterKey(key, value);
    } else {
    }

    setFilterDropData(i);
    selectdata.push({ lable: filterDropdowon, value: i });
    setSelectedData((prev) => [...prev, ...selectdata]);
  };

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "unit_icon", //normal accessorKey
        header: "",
        enableSorting: false,
        size: 50,
        Cell: ({ cell }) => (
          <div className=" d-flex gap-2">
            <div>
              {cell?.row?.original?.is_linked_with_screening == 1 ? (
                <FontAwesomeIcon
                  icon="fa-solid fa-circle-exclamation"
                  flip="vertical"
                  style={{ color: "#babec2" }}
                />
              ) : (
                ""
              )}
            </div>
            <div>
              {cell?.row?.original?.is_active_screening == 1 ? (
                <FontAwesomeIcon
                  icon="fa-regular fa-lock"
                  style={{ color: "#0a0a0b" }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        ),
      },
      {
        accessorKey: "calculated_unit_id",
        header: "No.",
        size: 100,
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
      },
      {
        accessorFn: (row) =>
          `${row?.street_name} ${row?.house_number}, ${row?.side_door}`,
        header: "Address",
        size: 180,
      },
      {
        accessorKey: "housing_type_name", //normal accessorKey
        header: "Type",
        size: 100,
      },
      {
        accessorKey: "alt_unit_id", //normal accessorKey
        header: "Unit no.",
        size: 80,
      },
      {
        accessorKey: "floor_number",
        header: "Floor",
        size: 50,
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorFn: (row) => `${row.area} ${row.square_measure_unit}`,
        size: 80,
        // accessorKey: "available",
        header: "Area",
        Cell: ({ cell, row }) => {
          return (
            <div>
              {row.original.area}
              <span
                dangerouslySetInnerHTML={{
                  __html: row.original.square_measure_unit,
                }}
              ></span>
            </div>
          );
        },
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "no_of_rooms",
        header: "Room",
        size: 80,
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },

      {
        accessorFn: (row) => `${row.rent} ${row.currency_id}`,
        header: "Price",
        size: 110,
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );

  // const [resetCondition, setResetCondition] = useState(false);

  const renderEmptyRowsFallback = () => {
    if (unitsTableData && unitsTableData?.length === 0) {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div>You do not have any unit yet.</div>
          </>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div style={{ fontSize: "17px" }}>Sorry! No result found:(</div>
          </>
        </div>
      );
    }
  };

  const selectedValues = Object.values(filterKeys).flat().join(",");

  // Dropdown for filter
  const screeningSection = true;
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      style={{
        backgroundColor: "#FFF",
        color: "#000",
        width: "100%",
        display: "flex",
        height: "41px",
        border: "1px solid #D1D1D1",
        fontWeight: "300",
        justifyContent: "space-between",
        alignItems: "center",
        textDecoration: "none",
        fontSize: "14px",
        borderRadius: "8px",
      }}
      className="px-2 py-2 dropdownInput "
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        setCloseDropdown(!closeDropdown);
      }}
    >
      <div className="d-flex justify-content-between w-100">
        <span> {children}</span>
        <span>
          <FontAwesomeIcon
            icon="fa-regular fa-chevron-down"
            size="sm"
            style={{ color: "#000000" }}
          />
        </span>
      </div>
    </a>
  ));
  return (
    <div>
      <div>
        <CustomPopup
          size="xl"
          open={addMoreUnits}
          onHide={() => setAddMoreUnits(false)}
          title="Link units"
          titleFont="14px"
          title_color="black"
          titleTooltipText="Link units"
          titleTooltip={true}
          enableCrossButton={false}
          onCloseAction={() => {
            setAddMoreUnits(false);
            alert("Action Peformed Successfuly.");
          }}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
        >
          <div>
            <div className="px-4 pt-3 d-flex align-items-center">
              <H2 className=" fs-14 "> Screening package name:</H2>{" "}
              <span className=" ms-1 form-label-title">Student screening </span>
            </div>
            <div className="px-4 pt-3 light-font-text grey-text">
              Select the units on the property to use this form. It is not
              possible to select a unit that is already linked to another form
              and this unit will appear grayed out in this list. Before it can
              be selected, you need to go to the form it is linked to, remove it
              and then link it to this form.
            </div>
            <MRTTheme
              colorVariantsForTableData={["odd"]}
              table={
                <MaterialReactTable
                  positionToolbarAlertBanner="bottom"
                  icons={{ ...SortIcon }}
                  selectAllMode="all"
                  columns={columns}
                  data={unitsTableData ?? []}
                  enableRowSelection={(row) =>
                    row.original.is_linked_with_screening == 0
                  }
                  // enableRowSelection
                  renderEmptyRowsFallback={renderEmptyRowsFallback}
                  getRowId={(row) => row.unit_id}
                  onRowSelectionChange={setRowSelection}
                  // onRowSelectionChange={(newRowSelection) => setRowSelection(newRowSelection)}

                  state={{ rowSelection }}
                  enableTopToolbar={true}
                  enableColumnActions={false}
                  initialState={{
                    showGlobalFilter: true,
                  }}
                  displayColumnDefOptions={{
                    "mrt-row-select": {
                      header: "",
                      size: 50,
                      muiTableBodyCellProps: {
                        sx: {
                          paddingRight: "0px !important",
                          border: "none !important",
                        },
                      },
                    },
                  }}
                  muiTableHeadRowProps={{
                    sx: {
                      ...TableRowsStyle,
                    },
                  }}
                  muiTablePaginationProps={{
                    ...paginationoptions,
                  }}
                  muiSearchTextFieldProps={{
                    ...SearchStyle,

                    InputProps: {
                      ...SearchIcon,
                    },
                  }}
                  renderTopToolbar={({ table }) => (
                    <>
                      <Row className=" py-2 px-4 align-items-center ">
                        <Col xl={2} md={4} className="my-1 pe-1 ">
                          <MRT_GlobalFilterTextField table={table} />
                        </Col>

                        <Col xl={2} md={4} className="my-1 pe-1">
                          <Dropdown show={closeDropdown}>
                            <Dropdown.Toggle
                              as={CustomToggle}
                              id="dropdown-custom-components"
                            >
                              Set filters
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="my-drop-menu">
                              <ScreeningFilterDropdown
                                propertag={propertag}
                                filteredTag={filteredTag}
                                setApplyBtn={setApplyBtn}
                                applyBtn={applyBtn}
                                matchingUnitsCount={matchingUnitsCount}
                                filterDataLength={filterDataLength}
                                // status={status}
                                // filteredTagdata={filteredTagdata}
                                matchingUnits={matchingUnits}
                                selectedValues={selectedValues}
                                screeningSection={screeningSection}
                                setUnitsTableData={setUnitsTableData}
                                multiPropsFilter={multiPropsFilter}
                                selectedDropdownData={selectedDropdownData}
                                dumnmyDropDownData={dumnmyDropDownData}
                                filterDropdowon={filterDropdowon}
                                // handleDropData={handleDropData}
                                unitsTableData={unitsTableData}
                                filterDropData={filterDropData}
                                setFilterDropData={setFilterDropData}
                                ChooseFilter={ChooseFilter}
                                handleFilterData={handleFilterData}
                                filter={filter}
                                selecteddata={selecteddata}
                                setFilter={setFilter}
                                handleChange={handleChange}
                                selectdata={selectdata}
                                filterKeys={filterKeys}
                                setFilterKeys={setFilterKeys}
                                closeDropdown={closeDropdown}
                                setCloseDropdown={setCloseDropdown}
                                setResetCondition={setResetCondition}
                                className="my-drop-menu"
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                        {selectedValues.length > 0 && (
                          <>
                            <Col className="fs-14 fw-300">
                              <div className="d-flex">
                                <div style={{ color: "#888888" }}>
                                  {selectedValues > 0
                                    ? "Selected filter:"
                                    : "Selected filters:"}{" "}
                                  <span className="fs-14">
                                    {selectedValues}
                                  </span>
                                </div>

                                <div
                                  className="ms-4"
                                  style={{ color: "#888888" }}
                                >
                                  Clear filter{" "}
                                  <FontAwesomeIcon
                                    onClick={() => {
                                      handleClearFilter();
                                      // setApplyBtn(false)
                                    }}
                                    className=" mx-1"
                                    icon="fa-solid fa-circle-minus"
                                    style={{
                                      color: "#888888",
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                            {/* <Col xl={2} md={4} className="fs-14 pt-2 fw-400">
                     
                            </Col> */}
                          </>
                        )}

                        {/* <Row>
                          <Col>
                            Selected filters:
                          </Col>
                          <Col>
                            Clear filter
                          </Col>
                        </Row> */}
                      </Row>
                    </>
                  )}
                />
              }
            />
          </div>
          <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => {
                setAddMoreUnits(false);
              }}
            />

            <ETButton
              variant={"primary"}
              buttonText="LINK SELECTED UNITS"
              onClick={() => {
                handleAddUnits();
              }}
            />
          </div>
        </CustomPopup>
      </div>
    </div>
  );
};

export default ScreeningFilterTable;
