// PdfViewer.js
import React, { useState } from "react";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import {
  defaultLayoutPlugin,
  DefaultLayoutPluginProps,
} from "@react-pdf-viewer/default-layout";

import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import {
  fullScreenPlugin,
  RenderEnterFullScreenProps,
} from "@react-pdf-viewer/full-screen";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "./docViewer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShowTooltip from "../../../Components/Tooltip/ShowTooltip";

interface PageNavigationButtonsExampleProps {
  fileUrl: url ;
}
// render-toolbar//
const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
  <Toolbar>
    {(slots: ToolbarSlot) => {
      const {
        CurrentPageInput,
        CurrentScale,
        GoToNextPage,
        GoToPreviousPage,
        NumberOfPages,
        ZoomIn,
        ZoomOut,
        EnterFullScreen,
        Download,
        Print,
        ShowSearchPopover,
      } = slots;
      return (
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="px-4">
            <ShowSearchPopover />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px 24px",
            }}
          >
            {/* right section */}

            <div
              style={{
                padding: "0px 16px 0px 0px",
                display: "flex",
                borderRadius: "4px",
                border: "1px solid #D9D9D9",
                background: "#FFF",
                alignItems: "center",
              }}
            >
              <CurrentPageInput /> / <NumberOfPages />
            </div>
            <div style={{ padding: "0px 2px" }}></div>

            {/*------------------------------- zoom button section --------------------------------*/}
            <div style={{ padding: "0px 8px 0px 24px" }}>
              <ZoomOut>
                {(props) => (
                  <FontAwesomeIcon
                    icon="fa-regular fa-minus"
                    style={{ color: "#48494a", cursor: "pointer" }}
                    onClick={props.onClick}
                    className="icons-hover-in-document-preview"
                  />
                )}
              </ZoomOut>
            </div>
            <div style={{ padding: "0px 2px" }}>
              <CurrentScale>
                {(props) => (
                  <span
                    style={{
                      display: "flex",
                      borderRadius: "4px",
                      border: "1px solid #D9D9D9",
                      background: "#FFF",
                      alignItems: "center",
                      padding: "5px 24px",
                    }}
                  >{`${Math.round(props.scale * 100)}%`}</span>
                )}
              </CurrentScale>
            </div>
            <div style={{ padding: "0px 0px 0px 8px" }}>
              <ZoomIn>
                {(props) => (
                  <FontAwesomeIcon
                    icon="fa-regular fa-plus"
                    style={{ color: "#48494a", cursor: "pointer" }}
                    onClick={props.onClick}
                    className="icons-hover-in-document-preview"
                  />
                )}
              </ZoomIn>
            </div>
            <div className="ps-4">
              <EnterFullScreen>
                {(props: RenderEnterFullScreenProps) => (
                  <FontAwesomeIcon
                    icon="fa-regular fa-arrows-up-down-left-right"
                    style={{ color: "#48494a", cursor: "pointer" }}
                    onClick={props.onClick}
                    className="icons-hover-in-document-preview"
                  />
                )}
              </EnterFullScreen>
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Download />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Print />
            </div>
          </div>
        </div>
      );
    }}
  </Toolbar>
);

const DocViewer = ({ url }) => {
 

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { CurrentPageInput } = pageNavigationPluginInstance;

  const fullScreenPluginInstance = fullScreenPlugin();
  const { EnterFullScreen } = fullScreenPluginInstance;

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    // for custom topbar
    renderToolbar,
    //for by default opening the thumbnail sidebar
    setInitialTab: (doc) => Promise.resolve(0),
    // for adding or removing tabs from sidebar
    sidebarTabs: (defaultTabs) => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      defaultTabs[0], // Bookmarks tab
      // defaultTabs[1], // Thumbnails tab
    ],
  });

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
      <div style={{height:"650px"}}>
        <Viewer
        
          //   fileUrl="/pdf-open-parameters.pdf"
          fileUrl={url}
          plugins={[
            defaultLayoutPluginInstance,
            fullScreenPluginInstance,
            pageNavigationPluginInstance,
          ]}
        />
      </div>
    </Worker>
  );
};

export default DocViewer;
