// created by -- Suraj
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./menus.css";
import { MenusIcon, TabsButtonIcon } from "../Tabs/Tabs.style";
// import CustomScrollBar from "../ScrollBar/CustomScrollBar";

export default function Menus({
  // options,
  itemheight,
  transformOrigin,
  anchorOrigin,
  itemwidth,
  // btnPadding,
  iconColor,
  // fontSize,
  iconbordercolor,
  iconborderradius,
  // onChange,
  itemcontainerwidth,
  itemcontainerheight,
  component,
  // onClose,
  margin,
  menusBackgroundColor,
  burgerIcon = false,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
    // onChange(e);
  };

  return (
    <div>
      <div className="menuhover">
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          style={{
            color: iconColor,
            backgroundColor: menusBackgroundColor,
            height: itemheight,
            width: itemwidth,
            border: `1px solid ${iconbordercolor}`,
            borderRadius:
              itemheight > 30 && itemwidth > 30 ? "8px" : iconborderradius,
            textAlign: "center",
            fontSize: "14px",
          }}
        >
          {burgerIcon ? (
            <MenusIcon>
              <FontAwesomeIcon icon="fa-solid fa-bars" />
            </MenusIcon>
          ) : open ? (
            <MenusIcon>
              <FontAwesomeIcon icon="fa-regular fa-ellipsis" />
            </MenusIcon>
          ) : (
            <MenusIcon>
              <FontAwesomeIcon icon="fa-regular fa-ellipsis-vertical" />
            </MenusIcon>
          )}
        </IconButton>
      </div>
      <Menu
        className={`mt-${margin}`}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: itemcontainerheight,
            borderRadius: "8px",
            width: itemcontainerwidth,
            marginTop: "6px",
            border: "0.5px solid #D1D1D1",
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.12)",
          },
        }}
        transformOrigin={
          !transformOrigin && {
            vertical: "top",
            horizontal: "right",
          }
        }
        anchorOrigin={
          !anchorOrigin && {
            vertical: "bottom",
            horizontal: "right",
          }
        }
        sx={{
          "& .css-6hp17o-MuiList-root-MuiMenu-list": {
            padding: 0,
            borderColor: "#D1D1D1",
          },
        }}
      >
        <div onClick={handleClose}>{component}</div>
      </Menu>
    </div>
  );
}

Menus.defaultProps = {
  iconColor: "#619130",
  iconbordercolor: "#D1D1D1",
  iconborderradius: "4px",
  menusBackgroundColor: "#FFFFFF",
  itemcontainerheight: 200,
  itemcontainerwidth: 200,
  itemheight: 24,
  itemwidth: 24,
  fontSize: "small",
  options: [],
};
