import { Col, Row } from "react-bootstrap";
import SigningCard from "../../../../../../Components/card/SigningCard";

const EditSigningPackages = ({ documentData }) => {
    return (
        <Row className="py-2">
            {
                documentData?.map((item, index) =>
                    <Col xl={4} lg={6} key={index} className="pb-4" >
                        <SigningCard data={item} isUnitEdit={true} />
                    </Col>
                )
            }
        </Row>
    )
}

export default EditSigningPackages;