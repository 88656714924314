// created by Suraj
import React, { useEffect, useState } from "react";
import ETButton from "../../../../../Components/Buttons/ETButton";
import { Col, Form, Row } from "react-bootstrap";
import ReactSelect from "../../../../../Components/DropDown/ReactSelect";
import CustomCheckBox from "../../../../../Components/Checkbox/Checkbox";
import Profile from "../../../../../Components/Profile/Profile";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import InputField from "../../../../../Components/Textfield/InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H4, H5, PrimarySelectedTag } from "../../../../../styles/Common.style";
import { SelectPhoneNumber } from "../../../../../Components/phoneNumberDropdown/SelectPhoneNumber";
import CustomReactSelect from "../../../../../Components/DropDown/CustomReactSelect";
import { useOrganizationId } from "../../../../../hooks/stores/useOrganizationStore";
import { usePostCheckExists } from "../../../../../hooks/queries/useCheckExistsQueries";
import { status } from "../../../../../model/Constant";
import { useGetTeamsByLevel } from "../../../../../hooks/queries/useTeam";
import CustomPopup from "../../../../../Components/CustomPopup";
import FilterComponent from "../../../../../reactdataTableComponent/FilterComponent";
import ETRadioButton from "../../../../../Components/RadioButton/ETRadioButton";
import { useGetUserByLevel } from "../../../../../hooks/queries/useUserQueries";

const UserInformation = ({
  setClosePopup,
  goToNextPage,
  usedFor,
  userDetailsData,
  userDetailsByIdDataRemove = () => {},
  divisionOptions,
  setDivisionOptions,
  userRolesOptions,
  setUserRoelsOptions,
  // assignedToTeamsOptions,
  handleCrateNewUser,
  languageOptions,
  text,
  formSubmit,
  croppedImage,
  setCroppedImage,
  imageName,
  setImageName,
  setNewUserId,
  setUserId = () => {},
}) => {
  //----states
  const [fieldRule, setFieldRule] = useState(true);
  const [userRole, setUserRole] = useState("");
  const [userDivision, setUserDivision] = useState(undefined);
  const [divisionId, setDivisionId] = useState("");
  const [disabledDivisions, setDisabledDivisions] = useState([]);
  const [disabledUserRoles, setDisabledUserRoles] = useState([]);
  const [disableExtraRole, setDisableExtraRole] = useState(false);
  const [assignAnother, setAssignAnother] = useState(false);
  const [radioButton, setRadioButton] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [teamManagerFlag, setTeamManagerFlag] = useState([]);
  // console.log("🚀 ~ assignAnother:", assignAnother);

  //-- zustand
  const { organizationId } = useOrganizationId((state) => state);
  //---react-query
  const {
    data: GetTeamsByDivisionBasicData,
    refetch: GetTeamsByDivisionBasicDataRefetch,
  } = useGetTeamsByLevel("d", userDivision, "details");
  const {
    data: GetTeamsByOrgisationBasicData,
    refetch: GetTeamsByOrgisationBasicDataRefetch,
  } = useGetTeamsByLevel("o", organizationId, "details");

  const { data: GetUserBylevelData } = useGetUserByLevel(
    "d",
    userDivision,
    "0",
    "1,2",
    "details"
  );

  const { mutateAsync: PostCheckExistsMutate, isLoading: isCheckExistLoading } =
    usePostCheckExists();

  const teamsByDivisionOptions = GetTeamsByDivisionBasicData?.data?.map(
    (team, index) => {
      return {
        value: team?.user_team_id,
        label: team?.team_name,
      };
    }
  );
  const teamsByOrganisationOptions = GetTeamsByOrgisationBasicData?.data?.map(
    (team) => {
      return {
        value: team?.user_team_id,
        label: team?.team_name,
      };
    }
  );
  const onInitGetRolesData = (rolesDetails) => {
    const roles = rolesDetails?.map((roleDetail) => {
      // Create an object with mapped values
      return {
        user_role: roleDetail?.role_id
          ? { value: roleDetail?.role_id, label: roleDetail?.role_name }
          : "",
        division: roleDetail?.division_id
          ? {
              value: roleDetail?.division_id,
              label: roleDetail?.division_name,
            }
          : "",

        teams:
          roleDetail?.team_details?.length > 0
            ? roleDetail?.team_details?.map((team) => {
                return {
                  value: team?.user_team_id,
                  label: team?.name,
                };
              })
            : "",
        isNewRole: false,
      };
    });
    reset({
      userRoles: roles,
    });
  };

  //useEffect to fetch data initially
  useEffect(() => {
    if (usedFor === "UserDetails") {
      setCroppedImage(userDetailsData?.[0]?.image_url);
      setImageName(userDetailsData?.[0]?.profile_image_filename);
      const roles = userDetailsData?.[0]?.role_details?.map((roleDetail) => {
        // Create an object with mapped values
        return {
          user_role: roleDetail?.role_id
            ? { value: roleDetail?.role_id, label: roleDetail?.role_name }
            : "",
          division: roleDetail?.division_id
            ? {
                value: roleDetail?.division_id,
                label: roleDetail?.division_name,
              }
            : "",

          teams:
            roleDetail?.team_details?.length > 0
              ? roleDetail?.team_details?.map((team) => {
                  return {
                    value: team?.user_team_id,
                    label: team?.name,
                  };
                })
              : "",
        };
      });

      onInitGetRolesData(userDetailsData?.[0]?.role_details);
      const usedDiv = roles?.map((role) => {
        return role?.division?.value;
      });
      setDisabledDivisions(usedDiv);

      const teamManagerFlags = userDetailsData?.[0]?.role_details?.flatMap(
        (roleDetail) => {
          return roleDetail?.team_details?.map((team) => {
            return {
              teamId: team?.user_team_id,
              managerFlag: team?.is_team_manager,
            };
          });
        }
      );

      setTeamManagerFlag(teamManagerFlags);
      if (roles?.length === 1) {
        // console.log("condition 1");
        setDisabledUserRoles([]);
      } else if (
        roles?.every(
          (role) => role?.user_role !== "OA" || role?.user_role !== ""
        )
      ) {
        // console.log("condition 2");
        setDisabledUserRoles((prev) =>
          !prev.includes("OA") ? [...prev, "OA"] : "OA"
        );
      }

      setValue("firstName", userDetailsData?.[0]?.first_name);
      setValue("lastName", userDetailsData?.[0]?.last_name);
      setValue("email", userDetailsData?.[0]?.email);
      setValue("isd_code", userDetailsData?.[0]?.isd_code);
      setValue("phone", userDetailsData?.[0]?.contact_no);
      setValue("title", userDetailsData?.[0]?.designation);
      setValue("company", userDetailsData?.[0]?.company_name);
      setValue("language", {
        value: userDetailsData?.[0]?.language_id,
        label: userDetailsData?.[0]?.language_name,
      });
    }
  }, [userDetailsData, usedFor]);

  //V1 const checkIsDivisionDisabled = (flag, role, division) => {
  //   if (flag === "CreateUser" && role === "OA") {
  //     // console.log("condition 1");
  //     return true;
  //   } else if (flag === "CreateUser" && role !== "OA") {
  //     // console.log("condition 2");

  //     return false;
  //   } else if (flag === "UserDetails" && role === "OA") {
  //     // console.log("condition 3");

  //     return true;
  //   } else if (
  //     flag === "UserDetails" &&
  //     role === undefined &&
  //     (division === "" || division === undefined || division === null)
  //   ) {
  //     // console.log("condition 4");

  //     return false;
  //   } else if (flag === "UserDetails" && (role !== "OA" || role !== "")) {
  //     // console.log("condition 5");

  //     return true;
  //   } else if (flag === "UserDetails" && role !== "OA") {
  //     return true;
  //   }
  // };
  const checkIsDivisionDisabled = (flag, role, division, isNewRole) => {
    // console.log("checkIsDivisionDisabled division", division);
    if (flag === "CreateUser" && role === "OA") {
      // console.log("condition 1");
      return true;
    } else if (flag === "CreateUser" && role !== "OA") {
      // console.log("condition 2");
      return false;
    } else if (flag === "UserDetails" && role === "OA") {
      // console.log("condition 3");
      return true;
    } else if (
      flag === "UserDetails" &&
      (role !== undefined || role !== "" || role !== null) &&
      isNewRole &&
      (division !== "" || division !== undefined || division !== null)
    ) {
      return false;
    } else if (
      flag === "UserDetails" &&
      (role !== undefined || role !== "" || role !== null) &&
      (division !== "" || division !== undefined || division !== null)
    ) {
      return true;
    } else if (
      flag === "UserDetails" &&
      (role === undefined || role === "" || role === null) &&
      (division === "" || division === undefined || division === null)
    ) {
      return true;
    } else if (
      flag === "UserDetails" &&
      (role === "" || role === undefined || role === null)
    ) {
      // console.log("condition 6");

      return false;
    } else if (
      (role !== "OA" || role !== "") &&
      (division === "" || division === undefined || division === null)
    ) {
      // console.log("condition 7");

      return false;
    } else if (
      flag === "UserDetails" &&
      (role !== "OA" || role !== "") &&
      (division === "" || division === undefined || division === null)
    ) {
      // console.log("condition 8");
      return false;
    }
    // console.log("condition 9");

    // Default case, if none of the conditions are met
    return false;
  };

  // --------------------------- use form ------------------------------------//
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
    getValues,
    setValue,
    watch,
    setError,
  } = useForm({
    defaultValues: {
      userRoles: [{ user_role: "", division: "", teams: "", isNewRole: "" }],
      // userRoles: defaultRoels,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "userRoles", // This should match the name in your form data
  });

  useEffect(() => {
    if (fields?.length === 1) {
      setDisabledUserRoles([]);
    } else if (
      fields?.length > 1 &&
      fields?.every(
        (role) => role?.user_role !== "OA" || role?.user_role !== ""
      )
    ) {
      setDisabledUserRoles((prev) =>
        !prev.includes("OA") ? [...prev, "OA"] : "OA"
      );
    }
  }, [fields]);
  useEffect(() => {
    if (divisionOptions) {
      const updatedOptions = divisionOptions?.map((option) => ({
        ...option,
        isDisabled: disabledDivisions?.includes(option?.value),
      }));
      setDivisionOptions(updatedOptions);
    }
  }, [divisionOptions, disabledDivisions]);

  const handleAppend = () => {
    append({
      user_role: "",
      division: "",
      teams: "",
      isNewRole: true,
    });
  };
  const handleDeleteUserRole = (i) => {
    if (usedFor === "CreateUser") {
      remove(i);
    } else if (usedFor === "UserDetails" && fields?.[i]?.user_role === "") {
      remove(i);
    } else {
      handleDelete(i);
    }
  };
  const userNameFirstLetter = watch("firstName");

  const onFormSubmit = async (data) => {
    if (usedFor === "CreateUser") {
      const checkExistPayload = {
        type: "email",
        value: data?.email,
        id: 0,
      };
      try {
        const response = await PostCheckExistsMutate(checkExistPayload);
        if (response.status == status?.s) {
          formSubmit(data);
          // console.log("CreateUser data", data);
          reset();
          reset({ userRoles: [{ user_role: "", division: "", teams: "" }] });
          setClosePopup(false);
          clearErrors("email", "");
          userDetailsByIdDataRemove();
        } else {
          return null;
        }
      } catch (error) {
        setError("email", { type: "exists", message: error?.message });
      }
    } else {
      formSubmit(data);
      reset();
      reset({ userRoles: [{ user_role: "", division: "", teams: "" }] });
      setClosePopup(false);
      userDetailsByIdDataRemove();
    }
  };
  // ----onClick handlers
  const handleDelete = (i) => {
    setDeleteUser(true);
    setDeleteId(i);
  };
  return (
    <div>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <div
          style={{ height: "450px", overflowY: "scroll" }}
          className="scrollable-container-y me-1"
        >
          {/* User information */}
          <div className="">
            <Row>
              {/* fields sections */}
              <Col
                xl={8}
                lg={7}
                style={{ borderRight: "1px solid #f0f0f0" }}
                className="pe-0"
              >
                {/* User information */}
                <div className="border-bottom p-4">
                  <H4>User information</H4>
                  <div className="mt-4">
                    {/* first name and last name */}
                    <Row>
                      <Col lg={5} className="pe-sm-3 mb-sm-3">
                        <Controller
                          {...register(`firstName`)}
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <div>
                              <InputField
                                type={"text"}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  clearErrors("firstName");
                                }}
                                errorscope={true}
                                iserror={errors?.firstName}
                                placeholder={"First name (required)"}
                                id={"firstName123"}
                              />
                            </div>
                          )}
                        />
                      </Col>
                      <Col lg={5} className="pe-3 ps-lg-0 mb-sm-3">
                        <Controller
                          {...register(`lastName`)}
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <div>
                              <InputField
                                type={"text"}
                                placeholder={"Last name (required)"}
                                value={value}
                                errorscope={true}
                                iserror={errors?.lastName}
                                onChange={(e) => {
                                  onChange(e);
                                  clearErrors("last_name");
                                }}
                                id={"lastName123"}
                              />
                            </div>
                          )}
                        />
                      </Col>
                    </Row>

                    {/* email */}
                    <Row className="">
                      <Col lg={5} className="pe-sm-3 mb-sm-3">
                        <Controller
                          {...register(`email`)}
                          control={control}
                          rules={{
                            required: "Email is required",
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: "Please use a valid email address",
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <div>
                              <InputField
                                type={"email"}
                                placeholder={"Email (required)"}
                                id={"Email123"}
                                value={value}
                                disabled={
                                  usedFor === "UserDetails" ? true : false
                                }
                                errorscope={true}
                                iserror={errors?.email}
                                errormessage={
                                  errors?.email?.type === "pattern" ||
                                  errors?.email?.type === "required" ||
                                  errors?.email?.type === "exists"
                                    ? errors?.email?.message
                                    : false
                                }
                                onChange={(e) => {
                                  onChange(e);
                                  clearErrors("email");

                                  // Manually set the error message for pattern validation
                                  if (
                                    e.target.value &&
                                    !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                                      e.target.value
                                    )
                                  ) {
                                    setError("email", {
                                      type: "pattern",
                                      message:
                                        "Please use a valid email address",
                                    });
                                  }
                                }}
                              />
                            </div>
                          )}
                        />
                      </Col>
                      <Col lg={5} className="pe-3 ps-lg-0 mb-sm-3">
                        {/* <Controller
                        {...register(``)}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <ReactSelect placeholder={"Phone "} />
                        )}
                      /> */}
                        <SelectPhoneNumber
                          errors={errors}
                          getValues={getValues}
                          setValue={setValue}
                          getPhoneId={"isd_code"}
                          getPhoneNumber={"phone"}
                          control={control}
                          placeholder={"Phone"}
                          isdValue={userDetailsData?.[0]?.isd_code}
                        />
                      </Col>
                    </Row>

                    {/* title and company */}
                    <Row className="">
                      <Col lg={5} className="pe-sm-3 mb-sm-0">
                        <Controller
                          {...register(`title`)}
                          control={control}
                          rules={{ required: false }}
                          render={({ field: { onChange, value } }) => (
                            <div>
                              <InputField
                                type={"text"}
                                value={value}
                                placeholder={"Title "}
                                id={"Title123"}
                                errorscope={true}
                                iserror={errors?.title}
                                onChange={(e) => {
                                  onChange(e);
                                  clearErrors("title");
                                }}
                              />
                            </div>
                          )}
                        />
                      </Col>
                      <Col lg={5} className="pe-3 ps-lg-0 mb-sm-0">
                        <Controller
                          {...register(`company`)}
                          control={control}
                          rules={{ required: false }}
                          render={({ field: { onChange, value } }) => (
                            <div>
                              <InputField
                                type={"text"}
                                placeholder={"Company "}
                                id={"Company123"}
                                value={value}
                                errorscope={true}
                                iserror={errors?.company}
                                onChange={(e) => {
                                  onChange(e);
                                  clearErrors("company");
                                }}
                              />
                            </div>
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>

                {/* System language  */}
                <div className="p-4 border-bottom">
                  <H4 className="mb-3">System language</H4>
                  <div className="light-font-text mb-3">
                    Select the user's system language.
                  </div>
                  <Row>
                    <Col
                      lg={5}
                      // className="pt-2"
                    >
                      <Controller
                        {...register(`language`)}
                        control={control}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            <ReactSelect
                              placeholder="Language"
                              searchBarHeader={"Search for languages"}
                              isMulti={true}
                              value={value}
                              options={languageOptions}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("language");
                              }}
                            />
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                </div>

                {/* User role */}
                <div className="">
                  {/* User role #1 */}
                  <div style={{ width: "100%" }} className="">
                    {fields.map((data, i) => (
                      <div
                        key={data.id}
                        className={`px-4 pb-0 ${i > 0 ? "pt-3" : "pt-4"}`}
                      >
                        <div className="d-flex justify-content-between">
                          <H4 className="mb-3">User role no.{i + 1}</H4>
                          <div>
                            <button
                              className="added-document-delete-btn"
                              disabled={fields?.length === 1}
                              // onClick={() => {
                              //   fields?.[i]?.user_role === "" &&
                              //   usedFor === "UserDetails"
                              //     ? remove(i)
                              //     : handleDelete(i);
                              // }}
                              onClick={() => handleDeleteUserRole(i)}
                              type="button"
                              style={
                                fields?.length === 1 ? { display: "none" } : {}
                              }
                            >
                              <FontAwesomeIcon
                                icon="fa-regular fa-trash"
                                className="fs-6"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="light-font-text mb-4">
                          A user can have several different user roles in the
                          system, but only one per division.
                        </div>
                        {/* </div> */}

                        {/* fields sections*/}
                        <div className="">
                          <Row>
                            <Col className="" lg={5}>
                              <div className="form-label-title pb-2">
                                User role
                              </div>
                              <Controller
                                {...register(`userRoles[${i}].user_role`)}
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { value, onChange } }) => {
                                  const userRoleVal = watch(
                                    `userRoles[${i}].user_role`
                                  );
                                  setUserRole(userRoleVal?.value);
                                  const updatedUserRolesOptions =
                                    userRolesOptions?.map((option) => ({
                                      ...option,
                                      isDisabled: disabledUserRoles?.includes(
                                        option?.value
                                      ),
                                    }));

                                  return (
                                    <div>
                                      <ReactSelect
                                        options={updatedUserRolesOptions}
                                        placeholder={"Select role(required)"}
                                        errorScope={true}
                                        iserror={
                                          errors?.userRoles?.[i]?.user_role
                                        }
                                        value={value}
                                        onChange={(e, ActionType) => {
                                          if (
                                            ActionType?.action ===
                                              "select-option" &&
                                            fields?.length === 1
                                          ) {
                                            onChange(e, ActionType);
                                            setDisabledUserRoles([]);
                                          } else if (
                                            ActionType?.action ===
                                            "select-option"
                                          ) {
                                            onChange(e, ActionType);
                                            setDisabledUserRoles((prev) =>
                                              userRole !== "OA" &&
                                              !prev.includes("OA")
                                                ? [...prev, "OA"]
                                                : prev
                                            );

                                            clearErrors(
                                              `userRoles[${i}].user_role`
                                            );
                                          } else if (
                                            ActionType?.action === "clear"
                                          ) {
                                            if (fields?.length === 1) {
                                              // console.log("Action 1");

                                              onChange(e, ActionType);

                                              setDisabledUserRoles([]);
                                            } else if (
                                              fields?.length > 1 &&
                                              fields.every(
                                                (field) => !field?.user_role
                                              ) &&
                                              !fields.every(
                                                (field) =>
                                                  field?.user_role === ""
                                              )
                                            ) {
                                              // console.log("Action 2");

                                              onChange(e, ActionType);
                                              setDisabledUserRoles([]);
                                            } else if (
                                              fields?.length > 1 &&
                                              fields.some(
                                                (field) =>
                                                  field?.user_role !== "OA" &&
                                                  field?.user_role !== ""
                                              ) &&
                                              usedFor === "CreateUser"
                                            ) {
                                              // console.log("Action 3");

                                              const removed =
                                                ActionType?.removedValues?.[0]
                                                  ?.value;
                                              const removeIndex =
                                                fields?.indexOf(removed);
                                              remove(removeIndex);
                                              // Clearing a non-OA role in a multi-row scenario with a selected option
                                              onChange(e, ActionType);
                                              setDisabledUserRoles((prev) =>
                                                userRole !== "OA" &&
                                                !prev.includes("OA")
                                                  ? [...prev, "OA"]
                                                  : prev
                                              );
                                            } else if (
                                              fields?.length > 1 &&
                                              fields.some(
                                                (field) =>
                                                  field?.user_role !== "OA" &&
                                                  field?.user_role !== ""
                                              ) &&
                                              usedFor === "UserDetails"
                                            ) {
                                              // console.log("Action 4");

                                              onChange(e, ActionType);
                                              setDisabledUserRoles((prev) =>
                                                userRole !== "OA" &&
                                                !prev.includes("OA")
                                                  ? [...prev, "OA"]
                                                  : prev
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  );
                                }}
                              />
                            </Col>
                            <Col lg={5} className="">
                              <div className="form-label-title pb-2">
                                Division
                              </div>
                              <Controller
                                {...register(`userRoles[${i}].division`)}
                                control={control}
                                rules={{
                                  required: userRole === "OA" ? false : true,
                                }}
                                render={({ field: { value, onChange } }) => {
                                  const selectedDiv = watch(
                                    `userRoles[${i}].division`
                                  );
                                  setUserDivision(selectedDiv?.value);
                                  // Update divisionOptions based on disabledDivisions
                                  const updatedDivisionOptions =
                                    divisionOptions?.map((option) => ({
                                      ...option,
                                      isDisabled: disabledDivisions?.includes(
                                        option?.value
                                      ),
                                    }));

                                  return (
                                    <div>
                                      <ReactSelect
                                        placeholder={
                                          "Select division(required)"
                                        }
                                        options={updatedDivisionOptions}
                                        value={
                                          userRole === "OA"
                                            ? {
                                                value: "0",
                                                label: "All divisions",
                                              }
                                            : value
                                        }
                                        iserror={
                                          errors?.userRoles?.[i]?.division
                                        }
                                        // disabled={
                                        //   usedFor === "UserDetails" ||

                                        //   userRole === "OA"
                                        //     ? true
                                        //     : false
                                        // }
                                        disabled={checkIsDivisionDisabled(
                                          usedFor,
                                          userRole,
                                          value?.value,
                                          fields[i]?.isNewRole
                                        )}
                                        // onChange={(e, ActionType) => {
                                        //   console.log("ActionType", ActionType);
                                        //   if (
                                        //     ActionType?.action ===
                                        //     "select-option"
                                        //   ) {
                                        //     onChange(e);
                                        //     const selectedDivision = e?.value;

                                        //     setDisabledDivisions((prev) =>
                                        //       prev.includes(selectedDivision)
                                        //         ? prev
                                        //         : [...prev, selectedDivision]
                                        //     );
                                        //   }
                                        //   // onChange(e);
                                        //   // const selectedDivision = e?.value;

                                        //   // setDisabledDivisions((prev) =>
                                        //   //   prev.includes(selectedDivision)
                                        //   //     ? prev
                                        //   //     : [...prev, selectedDivision]
                                        //   // );
                                        // }}
                                        onChange={(e, ActionType) => {
                                          // console.log("ActionType", ActionType);
                                          if (
                                            ActionType?.action ===
                                            "select-option"
                                          ) {
                                            // On selecting an option
                                            onChange(e, ActionType);
                                            const selectedDivision = e?.value;

                                            setDisabledDivisions((prev) =>
                                              prev.includes(selectedDivision)
                                                ? prev
                                                : [...prev, selectedDivision]
                                            );
                                          } else if (
                                            ActionType?.action === "clear" ||
                                            ActionType?.action ===
                                              "remove-value"
                                          ) {
                                            const clearedDivision =
                                              ActionType?.removedValues?.[0]
                                                ?.value;

                                            onChange(e, ActionType);

                                            setDisabledDivisions((prev) =>
                                              clearedDivision
                                                ? prev.filter(
                                                    (division) =>
                                                      division !==
                                                      clearedDivision
                                                  )
                                                : prev
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  );
                                }}
                              />
                            </Col>
                            <Row>
                              <Col className="pt-4 pb-4" lg={5}>
                                <div className="form-label-title pb-2">
                                  Teams
                                </div>
                                <Controller
                                  {...register(`userRoles[${i}].teams`)}
                                  control={control}
                                  rules={{ required: false }}
                                  render={({ field: { onChange, value } }) => (
                                    <div>
                                      {/* ?for multiple team selection , uncomment later */}
                                      <CustomReactSelect
                                        placeholder={"Assign to teams"}
                                        // inputValue={value}
                                        iserror={errors?.userRoles?.[i]?.teams}
                                        isSearchable={false}
                                        searchBarHeader="Search for team"
                                        isMulti={true}
                                        // onChange={(e, ActionType) => {
                                        //   console.log("ActionType", ActionType);
                                        //   // if (
                                        //   //   ActionType?.action === "clear" &&
                                        //   //   usedFor === "UserDetails"
                                        //   // ) {
                                        //   //   debugger;
                                        //   //   console.log("e", e);
                                        //   //   setAssignAnother(true);
                                        //   // } else if (
                                        //   //   ActionType?.action ===
                                        //   //   "select-option"
                                        //   // ) {
                                        //   //   debugger;
                                        //   //   onChange(e);
                                        //   //   clearErrors(
                                        //   //     `userRoles[${i}].teams`
                                        //   //   );
                                        //   // }
                                        // }}
                                        onChange={(e, ActionType) => {
                                          setDivisionId(userDivision);
                                          if (
                                            usedFor === "UserDetails" &&
                                            ActionType?.action ===
                                              "remove-value"
                                          ) {
                                            // const a = teamManagerFlag?.map((flag) => {
                                            //   if(ActionType?.[0]?.removedValue?.[0]?.value)
                                            // })
                                            const removedTeamId =
                                              ActionType?.removedValue?.value;

                                            // Check if removedTeamId exists in teamManagerFlag
                                            const teamManager =
                                              teamManagerFlag?.find(
                                                (team) =>
                                                  String(team?.teamId) ===
                                                  String(removedTeamId)
                                              );

                                            if (
                                              teamManager &&
                                              teamManager?.managerFlag === 1
                                            ) {
                                              setAssignAnother(true);
                                              onChange(e);
                                            } else if (
                                              teamManager &&
                                              teamManager?.managerFlag === 0
                                            ) {
                                              onChange(e);
                                            }
                                          } else if (
                                            usedFor === "UserDetails" &&
                                            ActionType?.action ===
                                              "select-option"
                                          ) {
                                            onChange(e);
                                          } else if (
                                            usedFor === "CreateUser" &&
                                            ActionType?.action ===
                                              "select-option"
                                          ) {
                                            onChange(e);
                                          }
                                        }}
                                        value={value}
                                        options={
                                          userRole === "OA"
                                            ? teamsByOrganisationOptions
                                            : teamsByDivisionOptions
                                        }
                                      />
                                      {/* <ReactSelect
                                        placeholder={"Assign to teams"}
                                        options={assignedToTeamsOptions}
                                        value={value}
                                        onChange={(e) => {
                                          onChange(e);
                                          clearErrors(`userRoles[${i}].teams`);
                                        }}
                                      /> */}
                                    </div>
                                  )}
                                />
                              </Col>
                            </Row>
                          </Row>
                        </div>

                        {/*Teams fields sections*/}
                        {/* <div className="pt-2 "></div> */}
                      </div>
                    ))}
                    {
                      <div className="d-flex justify-content-between align-items-center px-4 pb-4">
                        <ETButton
                          variant={"secondary"}
                          // size={"lg"}
                          buttonText={"CREATE EXTRA USER ROLE"}
                          // disabled={
                          //   userRole === "OA" ||
                          //   fields.every((field) => field?.user_role === "")
                          //     ? true
                          //     : false
                          // }
                          disabled={
                            userRole === "OA" ||
                            userRole === "" ||
                            userRole === undefined
                          }
                          icon={true}
                          onClick={() => {
                            handleAppend();
                          }}
                        />
                      </div>
                    }
                  </div>
                </div>
              </Col>

              {/* Image section */}
              <Col xl={4} lg={5} className="ps-0">
                <div className="p-4">
                  <H4 className="mb-2">Profile image</H4>

                  <div className="light-font-text pe-4">
                    Give the self a recognisable look by uploading a picture of
                    you. If not, the first letter in your name is used.
                  </div>
                </div>
                <Profile
                  firstName={userNameFirstLetter?.toUpperCase()}
                  croppedImage={croppedImage}
                  setCroppedImage={setCroppedImage}
                />
              </Col>
              <Col></Col>
            </Row>
          </div>
          {/* assign another team manager popup */}
          <CustomPopup
            open={assignAnother}
            onHide={() => setAssignAnother(false)}
            modalHeaderText={true}
            title={"Changes in team relations"}
            size="md"
            title_color="black"
            enableCrossButton={false}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={true}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
            deletePopUpStyle={true}
            // subTitle={allDocumentDetails?.documentName}
            titleTooltip={true}
            titleTooltipText={"Changes in team relations"}
            // titleTooltipText={allDocumentDetails?.allDocumentPackageFileName}
          >
            <div>
              <Row>
                <Col>
                  <div className="px-4 pt-4 pb-3">
                    <H5>Warning!</H5>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="paragraph2-light px-4 pb-4">
                    You are about to remove the user from one or more teams
                    where the user is a Team Manager. Since there must be a Team
                    Manager on a team, you need to assign the role to another
                    user in the Division.
                  </div>
                </Col>
              </Row>
            </div>
            <div className="pb-4 px-4">
              <H5>Users in the Division</H5>
              <div className="caption-light pt-2">
                {GetUserBylevelData?.data?.length} Users
              </div>
            </div>
            <div className="px-4 pb-4 d-flex align-items-center gap-3">
              <div className="w-75">
                {" "}
                <FilterComponent
                  className={"grey-input-filter-component"}
                  lableText="Search "
                  placeholder={"Search "}
                />
              </div>
              <div className="w-25">
                <button
                  // onClick={HandleTagReset}
                  className=" fs-12 form-label-title"
                  style={{
                    height: "23px",
                    width: "56px",
                    borderRadius: "4px",
                    backgroundColor: "rgba(240, 240, 240, 1)",
                    border: "1px solid rgba(209, 209, 209, 1)",
                  }}
                >
                  RESET
                </button>
              </div>
            </div>
            <div style={{ height: "200px", overflowY: "scroll" }}>
              {GetUserBylevelData?.data?.map((user) => (
                <div className=" px-3">
                  <PrimarySelectedTag className="d-flex mb-2 justify-content-between align-items-center light-font-text  ps-3 pe-2  cursor-pointer mx-2">
                    <div>
                      {user?.first_name} {user?.last_name}{" "}
                      <span>({user?.roles})</span>
                    </div>
                    <ETRadioButton
                      name="radio-btn-group"
                      value={radioButton}
                      onChange={(e) => {
                        setRadioButton(e.target.checked);
                        setNewUserId(user?.user_id);
                      }}
                    />
                  </PrimarySelectedTag>
                </div>
              ))}
            </div>
            <div
              className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
              style={{
                backgroundColor: "#F9FAFA",
                borderRadius: " 0px 0px 8px 8px",
                border: "1px solid #F0F0F0",
              }}
            >
              {" "}
              <ETButton
                variant={"white"}
                buttonText="CANCEL"
                onClick={() => {
                  setAssignAnother(false);
                }}
              />
              <ETButton
                variant={"primary"}
                buttonText="REPLACE"
                onClick={() => {
                  setAssignAnother(false);
                }}
              />
            </div>
          </CustomPopup>
          {/* delete userrole popup */}
          <CustomPopup
            open={deleteUser}
            onHide={() => setDeleteUser(false)}
            modalHeaderText={true}
            title={"Warning!"}
            size="md"
            title_color="black"
            enableCrossButton={false}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={true}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
            deletePopUpStyle={true}
            // subTitle={allDocumentDetails?.documentName}
            titleTooltip={true}
            titleTooltipText={"Warning!"}
            // titleTooltipText={allDocumentDetails?.allDocumentPackageFileName}
          >
            <div>
              <Row>
                <Col>
                  <div className="px-4 pt-4 pb-3">
                    <H5>Warning!</H5>
                    <div className="paragraph2-light">
                      You are deleting a user role.{" "}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="paragraph2-light px-4 pb-4">
                    Note that this can have major consequences for the user, so
                    this action should be done with caution and only if you are
                    absolutely sure. Please assign the users activities and
                    roles (Team Manager) to another user in the Division.
                  </div>
                </Col>
              </Row>
            </div>
            <div className="pb-4 px-4">
              <H5>Users in the Division</H5>
              <div className="caption-light pt-2">
                {GetUserBylevelData?.data?.length} Users
              </div>{" "}
            </div>
            <div className="px-4 pb-4 d-flex align-items-center gap-3">
              <div className="w-75">
                {" "}
                <FilterComponent
                  className={"grey-input-filter-component"}
                  lableText="Search "
                  placeholder={"Search "}
                />
              </div>
              <div className="w-25">
                <button
                  // onClick={HandleTagReset}
                  className=" fs-12 form-label-title"
                  style={{
                    height: "23px",
                    width: "56px",
                    borderRadius: "4px",
                    backgroundColor: "rgba(240, 240, 240, 1)",
                    border: "1px solid rgba(209, 209, 209, 1)",
                  }}
                >
                  RESET
                </button>
              </div>
            </div>
            <div style={{ height: "200px", overflowY: "scroll" }}>
              {GetUserBylevelData?.data?.map((user) => (
                <div className=" px-3">
                  <PrimarySelectedTag className="d-flex mb-2 justify-content-between align-items-center light-font-text  ps-3 pe-2  cursor-pointer mx-2">
                    <div>
                      {user?.first_name} {user?.last_name}{" "}
                      <span>({user?.roles})</span>
                    </div>
                    <ETRadioButton
                      name="radio-btn-group"
                      value={radioButton}
                      onChange={(e) => {
                        setRadioButton(e.target.checked);
                        setNewUserId(user?.user_id);
                      }}
                    />
                  </PrimarySelectedTag>
                </div>
              ))}
            </div>
            <div
              className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
              style={{
                backgroundColor: "#F9FAFA",
                borderRadius: " 0px 0px 8px 8px",
                border: "1px solid #F0F0F0",
              }}
            >
              {" "}
              <ETButton
                variant={"white"}
                buttonText="CANCEL"
                onClick={() => {
                  setDeleteUser(false);
                }}
              />
              <ETButton
                variant={"primary"}
                buttonText="CONFIRM"
                onClick={() => {
                  remove(deleteId);
                  setDeleteUser(false);
                }}
              />
            </div>
          </CustomPopup>
        </div>

        {/* user footer */}
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => {
              userDetailsByIdDataRemove();
              setClosePopup(false);
            }}
          />

          {usedFor === "CreateUser" && (
            <ETButton
              variant={"primary"}
              buttonText={"Create user"}
              type="submit"
              disabled={isCheckExistLoading ? true : false}
              // onClick={() => setClosePopup(false)}
            />
          )}
          {usedFor === "UserDetails" && (
            <ETButton
              variant={"primary"}
              buttonText={"Save"}
              type={"submit"}
              disabled={isCheckExistLoading ? true : false}

              // onClick={formSubmit}
            />
          )}
        </div>
      </Form>
    </div>
  );
};

export default UserInformation;
