import { useEffect, useState } from "react";
import { ProSidebarProvider } from "react-pro-sidebar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SidebarPanel } from "../views/sidebar/Sidebar";
import AddnewProperty from "../views/properties/addNewProperty/AddnewProperty";
import AddPropertyCreated from "../views/properties/addNewProperty/AddPropertyCreated";
import CreateNewPortfolio from "../views/properties/createNewPortfolio/CreateNewPortfolio";
import Units from "../views/properties/units/Units";
// import UserProfile from "../views/sidebar/UserProfile";
import ControlCenter from "../views/controlCenter/ControlCenter";
import EditUnitTabs from "../views/properties/units/addUnits/EditUnitTabs";
import Property from "../views/properties/Property";
import EditTag from "../views/settings/Tags/editTag/EditTag";
import NewReservation from "../views/properties/units/NewReservation";
import AddNewTextField from "../views/properties/units/addUnits/editSingleUnit/texts/AddNewTextField";
import Actors from "../views/actors/Actors";
import Topbar from "../views/properties/units/Topbar";
// import UnitsTable from "../views/properties/units/UnitsTablePage/UnitsTable";
import Facilities from "../views/facilities/Facilities";
import Settings from "../views/settings/Settings";
import Media from "../views/media/MediaMain";
import { Documents } from "../views/documents/Documents";
import Configuration from "../views/documents/signingPackages/configuration/Configuration";
import Texts from "../views/text/Texts";
import Events from "../views/events/Events";
import { AuthTokenValidation } from "../services/AuthTokenValidation";
import UnitsParent from "../views/properties/units/UnitsParent";
import {
  usePropertyOverview,
  usePropertyId,
} from "../hooks/stores/usePropertyStore.js";
import { useRouting } from "../hooks/stores/useRoutingStore";
import Signer from "../views/signing/Signer";
import { useSigningOrderStore } from "../hooks/stores/useSigningStore";
import LoadingBar from "react-top-loading-bar";
import customproperties from "../styles/Theme";
import { useLoadingBarStore } from "../hooks/stores/useProgressBarStore";
// import Package from "../views/packages/Package";
import { useUnitId } from "../hooks/stores/useUnitStore";
import Eido from "./../views/eido/Eido";
import EidoColor from "./../views/eido/color/Color";
import EidoText from "../views/eido/text/Text";
import EidoMedia from "./../views/eido/media/Media";
import EidoDocuments from "../views/eido/documents/Documents";
import EidoSettings from "../views/eido/settings/Settings";
import EidoPricelist from "./../views/eido/priceList/Pricelist";
import EidoFilters from "./../views/eido/filter/Filter";
import EidoButtons from "./../views/eido/buttons/Buttons";
import { Media as UnitMedia } from "../views/properties/units/addUnits/editSingleUnit/medias/Media";
import { Settings as UnitSettings } from "../views/properties/units/addUnits/editSingleUnit/settings/Settings";
import { Facilities as UnitFacilities } from "../views/properties/units/addUnits/editSingleUnit/facilities/Facilities";

import { BreadcrumbProvider } from "../Components/BreadcrumbContext ";
import Breadcrumbs from "../Components/Breadcrumbs";
import { Overview } from "../views/overview/Overview";
import Company from "../views/Company/Company";
import { ControlCenterOverview } from "../views/controlCenter/overview/Overview";
import Economy from "../views/controlCenter/economy/Economy";
import Traffic from "../views/controlCenter/traffic/Traffic";
import Customers from "../views/controlCenter/customers/Customers";
import Signings from "../views/controlCenter/signings/Signings";
import Contacts from "../views/contacts/Contacts";
import Error from "../Components/ErrorBoundary/Error";
import ControlCenterEvents from "../views/controlCenter/events/Events";
import UnitsOverview from "../views/properties/units/addUnits/editSingleUnit/overview/Overview";
import EditDocuments from "../views/properties/units/addUnits/editSingleUnit/documents/EditDocuments";
import Marketing from "../views/properties/units/addUnits/editSingleUnit/marketing/Marketing";
import EditTexts from "../views/properties/units/addUnits/editSingleUnit/texts/EditTexts";
import { SingleUnitEconomy } from "../views/properties/units/addUnits/editSingleUnit/SingleUnitEconomy";
import UnitDetails from "../views/properties/units/addUnits/editSingleUnit/UnitDetails";
import ActorsCustomers from "../views/actors/customers/Customers";
import { useGetPropertyWithOverviewByPropertyId } from "../hooks/queries/usePropertyQueries";
import ContactsLeads from "../views/contacts/ContactsLeads";
import ContactsOngoing from "../views/contacts/ContactsOngoing";
import ContactsCustomers from "../views/contacts/ContactsCustomers";
import ContactsArchive from "../views/contacts/ContactsArchive";
import ContactsOverview from "../views/contacts/ContactsOverview";
import ParkingLot from "../views/properties/units/parkingLot/ParkingLot";
import UnitsTable from "../views/properties/units/UnitsTablePage/UnitsTable";
import StorageRoom from "../views/properties/units/storageRoom/StorageRoom";
import CompaniesTab from "../views/Company/CompaniesTab";
import CompanyOverview from "../views/Company/CompanyOverview";
import AddCompany from "../views/Company/AddCompany";
import Organization from "../views/organization/Organization";
import OrgOverview from "../views/organization/overview/OrgOverview";
import OrganizationDetail from "../views/organization/detail/OrganizationDetail";
import OrgSetting from "../views/organization/setting/OrgSetting";
import DivisionsTab from "../views/divisions/DivisionsTab";
import DivisionsOverview from "../views/divisions/overview/DivisionsOverview";
import DivisionsSettings from "../views/divisions/settings/DivisionsSettings";
import EditDivisions from "../views/divisions/EditDivisions";
import MainSetting from "../views/settings/MainSetting";
import PromotionLabel from "../views/settings/promotionLabel/PromotionLabel";
import ScreeningForms from "../views/settings/screening/ScreeningForms";
import Signing from "../views/settings/signing/Signing";
import SettingConfiguration from "../views/settings/configuration/Configuration";
import CreateScreeningForm from "../views/settings/screening/createScreeningForms/CreateScreeningForm";
import Customization from "../views/organization/customization/Customization";
import Maintenance from "../views/organization/maintenance/Maintenance";
import OrgDocuments from "../views/organization/documents/Documents";

import Users from "../views/organization/setting/users/Users";
import SystemDefaults from "../views/organization/setting/systemDefaults/SystemDefaults";
import OrgMarketing from "../views/organization/setting/marketing/Marketing";
import OrganizationSettingProperty from "../views/organization/setting/property/OrganizationSettingProperty";

import OrganisationScreening from "../views/organization/setting/screening/OrganisationScreening";
import DivisionDocuments from "../views/divisions/documents/DivisionDocuments";
import DivisionGuides from "../views/divisions/settings/guides/DivisionGuides";
import MyPage from "../views/divisions/settings/myPage/MyPage";
import DivisionScreening from "../views/divisions/settings/screening/DivisionScreening";
import CompanyDocuments from "../views/Company/documents/CompanyDocuments";
import CompanySettings from "../views/Company/settings/CompanySettings";
import DivisionCommunication from "../views/divisions/communication/DivisionCommunication";
import GuidesMain from "../views/divisions/communication/guides/GuidesMain";
import AddNewGuide from "../views/divisions/communication/guides/AddNewGuide";

import FAQ from "../views/divisions/communication/FAQ/FAQ";
import CreateNewEmailTemplate from "../views/organization/setting/marketing/marketingTabs/CreateNewEmailTemplate";
import CustomerFunnelMain from "../views/customerFunnel/CustomerFunnelMain";
import Leads from "../views/customerFunnel/leads/Leads";
import CustomerFunnelAll from "../views/customerFunnel/all/CustomerFunnelAll";
import Opportunities from "../views/customerFunnel/opportunities/Opportunities";
import CustomerFunnelScreenings from "../views/customerFunnel/screenings/Screenings";
import FollowUpLater from "../views/customerFunnel/followUpLater/FollowUpLater";
import CustomerFunnelSignings from "../views/customerFunnel/signings/Signings";
import Communication from "../views/organization/setting/communication/Communication";
import OrganizationSettingSigning from "../views/organization/setting/signing/OrganizationSettingSigning";
import Integration from "../views/organization/setting/integrations/Integration";
import MyProfile from "../views/sidebar/userprofile/MyProfile";
import OrgSettingCustomerFunnel from "../views/organization/setting/customerFunnel/OrgSettingCustomerFunnel";
import CustomerPage from "../views/organization/setting/customerPage/CustomerPage";
import { Details } from "../views/properties/details/Details";
import DivisionsNewTable from "../views/divisions/DivisionsNewTable";
import CompaniesMainTable from "../views/Company/CompaniesMainTable";
import MaintenanceNew from "../views/organization/maintenance/MaintenanceNew";

const Main = () => {
  // zustand
  const propertyId = usePropertyId((state) => state.propertyId);
  const { UnitId } = useUnitId((state) => state);
  const { setViewSigningOrder } = useSigningOrderStore((state) => state);

  const setPropOverview = usePropertyOverview((state) => state.setPropOverview);
  const { setShowPackage, setEditflag } = useRouting((state) => state);

  const { progress, speed } = useLoadingBarStore();

  let location = useLocation();
  const { state } = location;

  // setPropertyId(state?.propId);
  const [unitId, setUnitId] = useState("");

  //---------------states for Documents-----------------//
  //---------------states for Documents -> document packages-----------------//

  //---------------states for Documents -> signing packages-----------------//
  const [showSigningPackages, setShowSigningPackages] = useState(false);
  const [isEditSigningPackages, setIsEditSigningPackages] = useState(false);
  // const [signingPackageId, setSigningPackageId] = useState("");
  const [configurationId, setConfigurationId] = useState("");
  //---------------states for Texts-----------------//

  //---------------states for Facilities-----------------//

  //---------- states for Tags------------//
  const [showEditTag, setShowEditTag] = useState(false);
  const [tagId, setTagId] = useState("");

  //  ----------states for Events-------------  //
  const [showOpenHouse, setShowOpenHouse] = useState(false);
  const [showOpenHouseProperty, setShowOpenHouseProperty] = useState(false);

  //for breadcrumbs ---- edit unit section
  const [initialSelectedName, setInitialSelectedName] = useState(null);
  const [selectedName, setSelectedName] = useState(initialSelectedName);
  const [selectedTabPage, setSelectedTabPage] = useState("Overview");
  const [selectedTab, setSelectedTab] = useState(0);
  const [overviewMainTabs, setOverviewMainTabs] = useState("Status");
  const [overviewTabs, setoverviewTabs] = useState("");
  const [selectedPropertiesId, setSelectedPropertiesId] = useState([]);

  const [showOpenHousePropertyFollowUp, setShowOpenHousePropertyFollowUp] =
    useState(false);
  const [showCreateNewPrivateEvent, setShowCreatePrivateEvent] =
    useState(false);
  const [showPrivateEvent, setShowPrivateEvent] = useState(false);

  const {
    data: GetPropertyWithOverviewByPropertyIdData,
    refetch: RefetchGetPropertyWithOverviewByPropertyIdData,
  } = useGetPropertyWithOverviewByPropertyId(propertyId, 1);

  useEffect(() => {
    if (location?.pathname === "/customers") {
      navigate("/customers/customers");
    }
    if (location?.pathname === "/property-units/units") {
      navigate("/property-units/units/residential");
    }
    if (location?.pathname === "/organization") {
      navigate("/organization/details");
    }
    if (location?.pathname === "/property-units/settings") {
      navigate("/property-units/settings/tag");
    }
    if (location?.pathname === "/control-center") {
      navigate("/control-center/overview");
    }
    if (location?.pathname === "/organization/settings") {
      navigate("/organization/settings/users");
    }
    if (location?.pathname === "/division/settings") {
      navigate("/division/settings/my-page");
    }
    if (location?.pathname === "/company/settings") {
      navigate("/company/settings/screening");
    }
    if (location?.pathname === "/division/communication") {
      navigate("/division/communication/guides");
    }
  }, [location]);
  useEffect(() => {
    RefetchGetPropertyWithOverviewByPropertyIdData();
  }, []);
  useEffect(() => {
    setPropOverview(GetPropertyWithOverviewByPropertyIdData?.data);
  }, [GetPropertyWithOverviewByPropertyIdData]);

  // eido data not refetching so used location in use-effect
  useEffect(() => {
    // if (location?.pathname.includes('eido')) {
    RefetchGetPropertyWithOverviewByPropertyIdData();
    // }
  }, [location]);

  // const unitId = undefined;

  useEffect(() => {
    setShowPackage(false);

    setEditflag(false);

    setShowEditTag(false);

    setShowSigningPackages(false);
    setIsEditSigningPackages(false);
    setShowOpenHouseProperty(false);
    setShowOpenHousePropertyFollowUp(false);
    setShowPrivateEvent(false);
    setShowCreatePrivateEvent(false);
    setViewSigningOrder({ showSigningPage: false, signingOrderData: "" });
  }, [state]);

  const navigate = useNavigate();

  const Loginpage = () => {
    navigate("/");
  };
  const HomePage = () => {
    navigate("properties");
  };
  const jwt = sessionStorage.getItem("et-jwt");
  const jwtrefresh = () => {
    if (jwt == null || jwt == "") {
      Loginpage();
    }
    AuthTokenValidation();
  };

  useEffect(() => {
    jwtrefresh();
  }, [jwt]);

  useEffect(() => {
    setSelectedName(initialSelectedName);
  }, [initialSelectedName]);
  const handleClick = (name) => {
    if (name?.length > 0) {
      setSelectedName(name);
    } else {
      setSelectedName("Tenant name");
    }
    setSelectedTabPage("Overview");
  };

  //For breadcrumbs

  return (
    <div>
      <BreadcrumbProvider>
        <div className="d-flex">
          <ToastContainer
            className={"react-toastify"}
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            icon={false}
            theme="light"
            transition={Slide}
          />
          {/* <BreadcrumbProvider> */}
          <ProSidebarProvider>
            <SidebarPanel
              Breadcrumbs={<Breadcrumbs />}
              propertyId={propertyId}
            />
          </ProSidebarProvider>
          {/* </BreadcrumbProvider> */}

          <div
            className="container-fluid px-0"
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "100vh",
              backgroundColor: "#F9FAFA",
            }}
          >
            <Topbar
              propertyOverview={GetPropertyWithOverviewByPropertyIdData?.data}
              unitId={UnitId}
            />
            <div className="mx-xxl-5 mx-4">
              <LoadingBar
                color={customproperties.primaryColor}
                progress={progress}
                containerStyle={{
                  position: "absolute",
                  height: "2.5px",
                  // background: "#f0f0f0",
                }}
                shadow={false}
                transitionTime={2000}
                loaderSpeed={speed}
                // waitingTime={1000}
              />
              {/* {jwt && ( */}
              <>
                {/* {showPackage === false && ( */}
                {/* <BreadcrumbProvider> */}
                {/* <RouterErrorBoundary
										fallbackRender={
											({ error }) => navigate("/error")

											
										}> */}
                <Routes>
                  <Route
                    path="control-center"
                    element={
                      <ControlCenter
                        Breadcrumbs={<Breadcrumbs />}
                        setSelectedPropertiesId={setSelectedPropertiesId}
                        selectedPropertiesId={selectedPropertiesId}
                      />
                    }
                  >
                    <Route
                      path="overview"
                      element={
                        <ControlCenterOverview
                          selectedPropertiesId={selectedPropertiesId}
                          // propertyOverview={
                          //   GetPropertyWithOverviewByPropertyIdData?.data
                          // }
                        />
                      }
                    />
                    <Route path="economy" element={<Economy />} />
                    <Route path="traffic" element={<Traffic />} />
                    <Route path="events" element={<ControlCenterEvents />} />
                    <Route path="customer" element={<Customers />} />
                    <Route path="signings" element={<Signings />} />
                  </Route>
                  <Route path="properties" element={<Property flag="PL" />} />

                  <Route
                    path="customers"
                    element={<Contacts Breadcrumbs={<Breadcrumbs />} />}
                  >
                    <Route path="overview" element={<ContactsOverview />} />
                    <Route path="leads" element={<ContactsLeads />} />
                    <Route path="ongoing" element={<ContactsOngoing />} />
                    <Route path="customers" element={<ContactsCustomers />} />
                    <Route path="archive" element={<ContactsArchive />} />
                  </Route>
                  <Route
                    path="create-new-property"
                    element={<AddnewProperty />}
                  />
                  <Route
                    path="add-new-property"
                    element={<AddPropertyCreated />}
                  />
                  <Route
                    path="/create-portfolio"
                    element={<CreateNewPortfolio operationName="create" />}
                  />
                  <Route
                    path="/edit-portfolio"
                    element={<CreateNewPortfolio operationName="edit" />}
                  />
                  <Route
                    path="property-units"
                    element={
                      <Units
                        Breadcrumbs={<Breadcrumbs />}
                        propertyOverview={
                          GetPropertyWithOverviewByPropertyIdData?.data
                        }
                        tagId={tagId}
                        showEditTag={showEditTag}
                        setShowEditTag={setShowEditTag}
                        showOpenHouse={showOpenHouse}
                        setShowOpenHouseProperty={setShowOpenHouseProperty}
                        showOpenHouseProperty={showOpenHouseProperty}
                        showOpenHousePropertyFollowUp={
                          showOpenHousePropertyFollowUp
                        }
                        setShowOpenHousePropertyFollowUp={
                          setShowOpenHousePropertyFollowUp
                        }
                        showPrivateEvent={showPrivateEvent}
                        setShowPrivateEvent={setShowPrivateEvent}
                        showCreateNewPrivateEvent={showCreateNewPrivateEvent}
                        setShowCreatePrivateEvent={setShowCreatePrivateEvent}
                      />
                    }
                  >
                    <Route path="overview" element={<Overview />} />
                    <Route
                      path="units"
                      element={
                        <UnitsParent
                          propertyOverviw={
                            GetPropertyWithOverviewByPropertyIdData?.data
                          }
                          propertyId={propertyId}
                          RefetchGetPropertyWithOverviewByPropertyIdData={
                            RefetchGetPropertyWithOverviewByPropertyIdData
                          }
                        />
                      }
                    >
                      <Route
                        path="residential"
                        element={
                          <UnitsTable
                            propertyOverviw={
                              GetPropertyWithOverviewByPropertyIdData?.data
                            }
                            propertyId={propertyId}
                            RefetchGetPropertyWithOverviewByPropertyIdData={
                              RefetchGetPropertyWithOverviewByPropertyIdData
                            }
                          />
                        }
                      ></Route>
                      <Route
                        path="parking-lot"
                        element={
                          <ParkingLot
                            propertyOverviw={
                              GetPropertyWithOverviewByPropertyIdData?.data
                            }
                            propertyId={propertyId}
                          />
                        }
                      ></Route>
                      <Route
                        path="storage-room"
                        element={
                          <StorageRoom
                            propertyOverviw={
                              GetPropertyWithOverviewByPropertyIdData?.data
                            }
                            propertyId={propertyId}
                          />
                        }
                      ></Route>
                    </Route>
                    <Route path="details" element={<Details />} />
                    <Route
                      path="facilities"
                      element={<Facilities propertyId={propertyId} />}
                    />
                    <Route
                      path="media"
                      element={
                        <Media
                          RefetchGetPropertyWithOverviewByPropertyIdData={
                            RefetchGetPropertyWithOverviewByPropertyIdData
                          }
                        />
                      }
                    />
                    <Route
                      path="documents"
                      element={
                        <Documents setConfigurationId={setConfigurationId} />
                      }
                    />
                    <Route
                      path="events"
                      element={
                        <div>
                          <Events
                            setShowOpenHouse={setShowOpenHouse}
                            setShowOpenHouseProperty={setShowOpenHouseProperty}
                            setShowOpenHousePropertyFollowUp={
                              setShowOpenHousePropertyFollowUp
                            }
                            setShowPrivateEvent={setShowPrivateEvent}
                            setShowCreatePrivateEvent={
                              setShowCreatePrivateEvent
                            }
                          />
                        </div>
                      }
                    />
                    <Route path="texts" element={<Texts />} />
                    <Route
                      path="signers"
                      element={
                        <div>
                          <Signer />
                        </div>
                      }
                    />

                    {/* setting routing change */}
                    <Route path="settings" element={<MainSetting />}>
                      <Route
                        path="tag"
                        element={
                          <Settings
                            propertyOverview={
                              GetPropertyWithOverviewByPropertyIdData?.data
                            }
                            propertyId={propertyId}
                            setShowEditTag={setShowEditTag}
                            setTagId={setTagId}
                            tagId={tagId}
                          />
                        }
                      />
                      <Route
                        path="promotion-label"
                        element={<PromotionLabel />}
                      />
                      <Route
                        path="integrations"
                        element={<div>Integrations</div>}
                      />
                      <Route path="screening" element={<ScreeningForms />} />
                      <Route path="signing" element={<Signing />} />
                      <Route
                        path="configuration"
                        element={<SettingConfiguration />}
                      />
                    </Route>

                    {/* manage new screening forms */}
                    <Route
                      path="create-screening-form"
                      element={<CreateScreeningForm />}
                    />
                  </Route>

                  {/* This is edit unit tabs */}
                  <Route
                    path="/property-units-edit"
                    element={
                      <EditUnitTabs
                        Breadcrumbs={<Breadcrumbs />}
                        setUnitId={setUnitId}
                        propertyId={propertyId}
                      />
                    }
                  >
                    <Route
                      path="overview"
                      index
                      element={
                        <UnitsOverview
                          UnitId={UnitId}
                          propertyId={propertyId}
                          setSelectedTabName={setSelectedTabPage}
                          setOverviewMainTabs={setOverviewMainTabs}
                          overviewMainTabs={overviewMainTabs}
                          setoverviewTabs={setoverviewTabs}
                          overviewTabs={overviewTabs}
                        />
                      }
                    />
                    <Route
                      path="contracts"
                      element={
                        <ActorsCustomers
                          setInitialSelectedName={setInitialSelectedName}
                          initialSelectedName={initialSelectedName}
                          setSelectedTab={setSelectedTab}
                          selectedTab={selectedTab}
                          setSelectedTabPage={setSelectedTabPage}
                          handleClick={handleClick}
                          selectedName={selectedName}
                        />
                      }
                    />
                    <Route
                      path="unit-details"
                      element={
                        <UnitDetails UnitId={UnitId} propId={propertyId} />
                      }
                    />
                    <Route
                      path="economy"
                      element={
                        <SingleUnitEconomy
                          UnitId={UnitId}
                          propId={propertyId}
                        />
                      }
                    />
                    <Route
                      path="facilities"
                      element={
                        <UnitFacilities UnitId={UnitId} propId={propertyId} />
                      }
                    />
                    <Route
                      path="texts"
                      element={
                        <EditTexts UnitId={UnitId} propId={propertyId} />
                      }
                    />
                    <Route
                      path="medias"
                      element={
                        <UnitMedia UnitId={UnitId} propId={propertyId} />
                      }
                    />
                    <Route
                      path="marketing"
                      element={
                        <Marketing UnitId={UnitId} propId={propertyId} />
                      }
                    />
                    <Route
                      path="settings"
                      element={
                        <UnitSettings UnitId={UnitId} propId={propertyId} />
                      }
                    />
                    <Route
                      path="documents"
                      element={
                        <EditDocuments UnitId={UnitId} propId={propertyId} />
                      }
                    />
                  </Route>
                  <Route path="/edit-tag" element={<EditTag />} />
                  {/* This addNewTextfield is from edit unit section  */}
                  <Route
                    path="/add-new-textfield"
                    element={<AddNewTextField />}
                  />
                  <Route
                    path="/create-new-reservation"
                    element={<NewReservation />}
                  />
                  <Route
                    path="/user-profile"
                    element={<MyProfile Breadcrumbs={<Breadcrumbs />} />}
                  />
                  <Route
                    path="/actor"
                    element={
                      <Actors
                        propertyOverview={
                          GetPropertyWithOverviewByPropertyIdData?.data
                        }
                      />
                    }
                  />
                  <Route
                    path="/signing-forms"
                    element={
                      <Configuration configurationId={configurationId} />
                    }
                  />
                  {/* EIDO  */}
                  <Route
                    path="eido"
                    element={
                      <Eido
                        propertyOverview={
                          GetPropertyWithOverviewByPropertyIdData?.data
                        }
                      />
                    }
                  >
                    <Route
                      path="color"
                      element={
                        <EidoColor
                          propertyOverviw={
                            GetPropertyWithOverviewByPropertyIdData?.data
                          }
                          propertyId={propertyId}
                        />
                      }
                    />
                    <Route
                      path="text"
                      element={
                        <EidoText
                          propertyOverviw={
                            GetPropertyWithOverviewByPropertyIdData?.data
                          }
                          propertyId={propertyId}
                        />
                      }
                    />
                    <Route
                      path="media"
                      element={
                        <EidoMedia
                          propertyOverviw={
                            GetPropertyWithOverviewByPropertyIdData?.data
                          }
                          propertyId={propertyId}
                        />
                      }
                    />
                    <Route
                      path="documents"
                      element={
                        <EidoDocuments
                          propertyOverviw={
                            GetPropertyWithOverviewByPropertyIdData?.data
                          }
                          propertyId={propertyId}
                        />
                      }
                    />
                    <Route
                      path="filter"
                      element={
                        <EidoFilters
                          propertyOverviw={
                            GetPropertyWithOverviewByPropertyIdData?.data
                          }
                          propertyId={propertyId}
                        />
                      }
                    />
                    <Route
                      path="button"
                      element={
                        <EidoButtons
                          propertyOverviw={
                            GetPropertyWithOverviewByPropertyIdData?.data
                          }
                          propertyId={propertyId}
                        />
                      }
                    />
                    <Route
                      path="pricelist"
                      element={
                        <EidoPricelist
                          propertyOverviw={
                            GetPropertyWithOverviewByPropertyIdData?.data
                          }
                          propertyId={propertyId}
                        />
                      }
                    />
                    <Route
                      path="settings"
                      element={
                        <EidoSettings
                          propertyOverviw={
                            GetPropertyWithOverviewByPropertyIdData?.data
                          }
                          propertyId={propertyId}
                        />
                      }
                    />
                  </Route>

                  <Route path="company" element={<CompaniesTab />}>
                    {/* <Route path="overview" element={<CompanyOverview />} /> */}
                    <Route path="details" element={<AddCompany />} />
                    <Route
                      path="properties"
                      element={<Property flag="company" />}
                    />
                    <Route path="documents" element={<CompanyDocuments />} />
                    <Route path="settings" element={<CompanySettings />}>
                      {/* <Route path="users" element={<Users />} />
                      <Route
                        path="system-defaults"
                        element={<SystemDefaults />}
                      />
                      <Route path="marketing" element={<OrgMarketing />} />
                      <Route path="areas" element={<Areas />} /> */}
                      <Route path="screening" element={<>screening</>} />
                    </Route>
                  </Route>

                  {/* organization  */}
                  <Route path="organization" element={<Organization />}>
                    <Route path="details" element={<OrganizationDetail />} />
                    <Route path="Customisation" element={<Customization />} />
                    <Route path="maintenance" element={<MaintenanceNew />} />
                    <Route path="divisions" element={<DivisionsNewTable />} />
                    {/* <Route path="divisions" element={<div>divisions</div>} /> */}
                    <Route path="documents" element={<OrgDocuments />} />
                    {/* organisation settings and nested routes */}
                    <Route path="settings" element={<OrgSetting />}>
                      <Route path="users" element={<Users />} />
                      <Route
                        path="system-defaults"
                        element={<SystemDefaults />}
                      />
                      <Route path="marketing" element={<OrgMarketing />} />
                      <Route
                        path="property"
                        element={<OrganizationSettingProperty />}
                      />
                      <Route
                        path="screening"
                        element={<OrganisationScreening />}
                      />
                      <Route path="Communication" element={<Communication />} />
                      <Route path="Integrations" element={<Integration />} />
                      <Route
                        path="Signing"
                        element={<OrganizationSettingSigning />}
                      />
                      <Route
                        path="customer-funnel"
                        element={<OrgSettingCustomerFunnel />}
                      />
                      <Route path="customer-page" element={<CustomerPage />} />
                    </Route>
                  </Route>

                  <Route
                    path="create-new-template"
                    element={<CreateNewEmailTemplate />}
                  />
                  {/* division */}
                  <Route path="division" element={<DivisionsTab />}>
                    <Route path="details" element={<EditDivisions />} />
                    <Route path="Companies" element={<CompaniesMainTable />} />
                    <Route path="properties" element={<Property flag="DL" />} />
                    <Route path="documents" element={<DivisionDocuments />} />

                    {/*communication  */}
                    <Route
                      path="communication"
                      element={<DivisionCommunication />}
                    >
                      <Route path="guides" element={<GuidesMain />} />
                      <Route
                        path="notice-messages"
                        element={<>Notice messages</>}
                      />
                      <Route path="FAQ" element={<FAQ />} />
                    </Route>

                    {/* setting sections */}
                    <Route path="settings" element={<DivisionsSettings />}>
                      <Route path="my-page" element={<MyPage />} />
                      <Route path="screening" element={<DivisionScreening />} />
                      <Route path="FAQ" element={<>FAQ</>} />
                      <Route path="guides" element={<DivisionGuides />} />
                      {/* <Route path="Setting 5" element={<Areas />} /> */}
                    </Route>
                  </Route>
                  <Route path="/add-new-guide" element={<AddNewGuide />} />

                  {/* Customer funnel */}
                  <Route
                    path="customer-funnel"
                    element={<CustomerFunnelMain />}
                  >
                    <Route path="all" element={<CustomerFunnelAll />} />
                    <Route path="leads" element={<Leads />} />
                    <Route path="opportunities" element={<Opportunities />} />
                    <Route
                      path="screenings"
                      element={<CustomerFunnelScreenings />}
                    />
                    <Route
                      path="signings"
                      element={
                        <>
                          <CustomerFunnelSignings />
                        </>
                      }
                    />
                    <Route path="follow-up-later" element={<FollowUpLater />} />
                  </Route>

                  <Route path="error" element={<Error />} />
                </Routes>
                {/* </RouterErrorBoundary> */}
                {/* </BreadcrumbProvider> */}

                {/* )} */}

                {/* <>
										{showPackage && packageType === "5" ? (
											<CreateMediaPackages />
										) : showPackage && packageType === "8" ? (
											<CreateNewTextField />
										) : // <Package packageType={packageType} />
										showPackage && packageType === "6" ? (
											<>
												<AddDocumentPackages />
											</>
										) : showPackage && packageType === "Signing" ? (
											<>
												<AddSigningPackages
													// propertyOverview={propertyOverview}
													propertyOverview={
														GetPropertyWithOverviewByPropertyIdData?.data
													}
												/>
											</>
										) : showEditTag ? (
											<EditTag
												setShowEditTag={setShowEditTag}
												tagId={tagId}
												propertyId={propertyId}
											/>
										) : showPackage && packageType === "4" ? (
											<AddNewPackage />
										) : showOpenHouse ? (
											<OpenHouse propertyId={propertyId} />
										) : showOpenHousePropertyFollowUp ? (
											<FollowUpPropertyOpenHouse propertyId={propertyId} />
										) : showOpenHouseProperty ? (
											<PropertyOpenHouse propertyId={propertyId} />
										) : (
											showPackage && packageType === "9" && <AddNewSigner />
										)}
									</> */}
              </>
              {/* )} */}
            </div>
          </div>
        </div>
      </BreadcrumbProvider>
    </div>
  );
};

export default Main;
