import React, { useEffect, useState } from "react";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../Components/Tabs/ReactTabs.style";
import Status from "./Status";
import Leads from "./Leads";
import Ongoing from "./Ongoing";
import Customers from "./Customers";
import Payments from "./Payments";
import Communication from "./Communication";
import Notes from "./Notes";
import "./overview.css";
import { useBreadcrumb } from "../../Components/BreadcrumbContext ";
import { useRouting } from "../../hooks/stores/useRoutingStore";
import { H2 } from "../../styles/Common.style";


export const Overview = () => {
  const [overviewMainTabs, setOverviewMainTabs] = useState("");

  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    updateBreadcrumbs(["Overview", "Status"]);
  }, []);

  useEffect(() => {
    if (overviewMainTabs === "Status" || overviewMainTabs === undefined) {
      updateBreadcrumbs(["Overview", "Status"]);
    } else if (overviewMainTabs === "Leads") {
      updateBreadcrumbs(["Overview", "Leads", "Unassigned"]);
    } else if (overviewMainTabs === "Ongoing") {
      updateBreadcrumbs(["Overview", "Ongoing", "Reservation"]);
    } else if (overviewMainTabs === "Customers") {
      updateBreadcrumbs(["Overview", "Customers"]);
    } else if (overviewMainTabs === "Payments") {
      updateBreadcrumbs(["Overview", "Payments"]);
    } else if (overviewMainTabs === "Communication") {
      updateBreadcrumbs(["Overview", "Communication"]);
    } else if (overviewMainTabs === "Notes") {
      updateBreadcrumbs(["Overview", "Notes"]);
    }
  }, [overviewMainTabs]);

  const OverviewTabsWizards = [
    {
      Id: "1",
      tab: "Status",
      component: <Status />,
    },
    {
      Id: "2",
      tab: "Leads",
      component: <Leads />,
    },
    {
      Id: "3",
      tab: "Ongoing",
      component: <Ongoing />,
    },
    {
      Id: "4",
      tab: "Customers",
      component: <Customers />,
    },
    {
      Id: "5",
      tab: "Payments",
      component: <Payments />,
    },
    {
      Id: "6",
      tab: "Communication",
      component: <Communication />,
    },
    {
      Id: "7",
      tab: "Notes",
      component: <Notes />,
    },
  ];
  return (
    <div className="p-4   containerStyle">
      <div className="bg-white radius-8 fs-14 mainborder">
        <div
          className="px-4 py-3 "
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div className=" d-flex align-items-center">
            <H2 >Overview</H2>
            <div className="ps-2">
              {" "}
              <CustomToolTip text="Overview" />{" "}
            </div>
          </div>
        </div>
        <Tabs lassName="mb-3 pb-1 d-flex flex-column " value={overviewMainTabs}>
          <div
            className="d-flex ps-4 pe-3 py-3  justify-content-between"
            style={{
              width: "100%",
              flexDirection: "row",
              flexWrap: "wrap",
              borderBottom: "1px solid #F0F0F0",
            }}
          >
            <TabList className="light ps-0  ">
              {OverviewTabsWizards.map((page, index) => (
                <>
                  <Tab
                    key={index}
                    style={{ position: "relative" }}
                    onClick={() => setOverviewMainTabs(page?.tab)}
                    className="me-3"
                  >
                    <span style={{ position: "relative" }}>{page?.tab}</span>
                  </Tab>
                </>
              ))}
            </TabList>
          </div>
          <div className="bg-white " style={{ borderRadius: " 10px" }}>
            {OverviewTabsWizards.map((page, index) => (
              <TabPanel key={page?.Id}>{page?.component}</TabPanel>
            ))}
          </div>
        </Tabs>
      </div>
    </div>
  );
};

{
  /* <div className="my-5 d-flex flex-column  text-center justify-content-center align-items-center">
      <Image
      fluid
      alt="property image"
      className=""
      src={Underwork}
      style={{ width: "20%", height: "20%" }}
      />
      <div className=" py-3">
      <div className="fs-32 fw-600 grey-text">UNDER CONSTRUCTION</div>
      <div className="grey-text fs-20 fw-300">
        We are currently working on it
      </div>
      </div>
    </div> */
}
