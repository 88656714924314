import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  GetMediaPackageByPropertyId,
  DeleteMediaPAckageByPackageID,
  PostCloneMediaPackage,
} from "../../services/Media";

import { toast } from "react-toastify";
import Duplicate from "../properties/units/duplicate/Duplicate";
import { useAllPropertyList } from "../../hooks/queries/usePropertyQueries";
import CustomPopup from "../../Components/CustomPopup";
import ETButton from "../../Components/Buttons/ETButton";
import { usePropertyOverview } from "../../hooks/stores/usePropertyStore";
import { useRouting } from "../../hooks/stores/useRoutingStore";
import MediaCard from "../../Components/card/MediaCard";
import { Col, Row } from "react-bootstrap";
import { useAddEditPackage } from "../../hooks/stores/usePackageStore";
import { status } from "../../model/Constant";
const MediaPackages = () => {
  // zustand
  const propOverview = usePropertyOverview((state) => state.propOverview);
  const { setPackageType, setEditflag, setPackageId } = useRouting(
    (state) => state
  );
  const { setCancelPopup } = useAddEditPackage((state) => state);

  //---------React query-------------//
  const { data: mediaPackageByPropertyId, refetch: refetchMediaPAckage } =
    useQuery(["mediaPackageByPropID", propOverview?.[0]?.property_id], () =>
      GetMediaPackageByPropertyId(propOverview?.[0]?.property_id)
    );
  const { data: getAllpropertiesList } = useAllPropertyList(0);

  return (
    <>
      <div className=" p-4 mt-2">
        {mediaPackageByPropertyId?.data == 0 ? (
          <div
            className="d-flex justify-content-center w-100 fs-12"
            style={{ color: "#888888" }}
          >
            <div>
              You do not have any media package yet. Click on “+ ADD NEW PACKAGE
              ” to create your first.
            </div>{" "}
          </div>
        ) : (
          <Row className=" pb-4">
            {mediaPackageByPropertyId?.data?.map((item, index) => (
              <Col xl={4} lg={6} key={index} className="pb-4">
                <MediaPackageCard
                  setEditflag={setEditflag}
                  setPackageId={setPackageId}
                  data={item}
                  refetchMediaPAckage={refetchMediaPAckage}
                  propertyId={propOverview?.[0]?.property_id}
                  getAllpropertiesList={getAllpropertiesList}
                  setPackageType={setPackageType}
                  setCancelPopup={setCancelPopup}
                />
              </Col>
            ))}
          </Row>
        )}
      </div>
    </>
  );
};

export default MediaPackages;

const MediaPackageCard = ({
  setEditflag,
  setPackageId,
  data,
  refetchMediaPAckage,
  propertyId,
  getAllpropertiesList,
  setPackageType,
  setCancelPopup,
}) => {
  useRouting((state) => state);
  const [openDuplicatePackageModal, setOpenDuplicatePackageModal] =
    useState(false);
  const [duplicatePackageName, setDuplicatePackageName] = useState("");
  const [checkedPropertiesId, setCheckedPropertiesId] = useState([]);
  const [openMediaPackageDelete, setOpenMediaPackageDelete] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(false);
  const edittoggle = () => {};
  const { showPackage, setShowPackage } = useRouting((state) => state);

  //-------------handle delete package---------//
  const handleDeleteMediaPackage = async () => {
    setDeleteDisabled(true);
    const response = await DeleteMediaPAckageByPackageID(
      propertyId,
      data?.media_package_id
    );
    if (response.status == status.s) {
      toast.success(response.data.message);
      refetchMediaPAckage();
      setDeleteDisabled(false);
      setOpenMediaPackageDelete(false);
    }
  };
  let mediaPackageId = data?.media_package_id?.toString();
  //------------handle duplicate package---------//
  const handleDuplicatePackage = (data) => {
    const clonePayloadDetails = {
      media_package_id: mediaPackageId,
      title: data?.packageTitle,
      clone_type: "0",
      property_id: checkedPropertiesId,
    };
    PostCloneMediaPackage(clonePayloadDetails).then((response) => {
      toast.success(response?.data?.message);
      setOpenDuplicatePackageModal(false);
      setCancelPopup(false);
    });
  };

  const handleEditMediaPackage = (id) => {
    setPackageId(data?.media_package_id);
    setShowPackage(true); //zustand
    setPackageType("5");
    setEditflag(true);
  };

  const handleDuplicateClick = (id) => {
    setPackageId(id);
    setOpenDuplicatePackageModal(true);
  };
  return (
    <>
      <MediaCard
        data={data}
        handleEditMediaPackage={handleEditMediaPackage}
        handleDuplicateClick={handleDuplicateClick}
        setOpenMediaPackageDelete={setOpenMediaPackageDelete}
      />

      {/* Delete document property */}
      <CustomPopup
        open={openMediaPackageDelete}
        onHide={() => setOpenMediaPackageDelete(false)}
        title="Delete package"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="p-3">
          <div className="px-2">
            <div className="light-font-text my-1" style={{ height: "100px" }}>
              <p>Are you sure you want to delete the package?</p>
              <p>That will conflict with.....</p>
            </div>
          </div>
        </div>
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => setOpenMediaPackageDelete(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="I'M SURE"
            disabled={deleteDisabled}
            onClick={handleDeleteMediaPackage}
          />
        </div>
      </CustomPopup>
      {/* Delete document property */}

      <Duplicate
        openDuplicate={openDuplicatePackageModal}
        setOpenDuplicate={setOpenDuplicatePackageModal}
        handleDuplicate={handleDuplicatePackage}
        propertyData={getAllpropertiesList?.data?.filter(
          (el) => el?.property_id !== propertyId
        )}
        setTitle={setDuplicatePackageName}
        allChecked={checkedPropertiesId}
        setAllChecked={setCheckedPropertiesId}
        duplicateDataName={"media"}
      />
    </>
  );
};
