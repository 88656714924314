import { Col, Placeholder, Row }                                      from "react-bootstrap";
import CustomToolTip                                                  from "../../../Components/Tooltip/CustomTooltip";
import ETButton                                                       from "../../../Components/Buttons/ETButton";
import MRTTheme                                                       from "../../../Components/table/MRTTheme";
import { MRT_GlobalFilterTextField, MaterialReactTable }              from "material-react-table";
import { useEffect, useMemo, useState }                               from "react";
import { SearchIcon, SearchStyle, TableRowsStyle, paginationoptions } from "../../../Components/table/MRTGlobalProps";
import DropdownModalMui                                               from "../../../Components/DropdownModal/DropdownModalMui";
import TagFilterDropDown                                              from "../../settings/Tags/TagFilterDropDown";
import { useLocation } from "react-router-dom";
import { useGetUnitsByPropertyIdUpdated } from "../../../hooks/queries/useUnitQueries";
import { useGetEditUnitTextByPropertyIdByUnitId } from "../../../hooks/queries/useTextQueries";
import { styled } from "styled-components";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { useGetEiDoTextPackage } from './../../../hooks/queries/useEidoQueries';
import EidoAssignText from "./EidoAssignText";
import EidoNotAssignText from "./EidoNotAssignText";
import {   Tab,
  Tabs,
  TabList,
  TabPanel } from './../../../Components/Tabs/ReactTabs.style';

const Text = () => {
  let location = useLocation();
  const { state } = location;
  let propId = state?.propId;

  const {
    data: GetEidoTextPackageData,
    refetch: GetEidoTextPackageDataRefetch,
    isLoading,
  } = useGetEiDoTextPackage(propId, 3);

// Filter arry for the text packgaes
  const [assignedTextPackage, setAssignedTextPackage] = useState([]);
  const [notAssignedTextPackage, setNotAssignedTextPackage] = useState([]);

  useEffect(()=> {
    setAssignedTextPackage([])
    setNotAssignedTextPackage([])

    if(GetEidoTextPackageData?.status === "success" && GetEidoTextPackageData?.data.length) {

      // Filter text package according to assign/not assign 
      GetEidoTextPackageData?.data.forEach((tpd => {
        if(tpd.Package_Name.length === 0){
          setNotAssignedTextPackage((prev) => [...prev, {
            unit_no       : tpd.unit_calculated_number,
            unit_type     : tpd.alt_unit_id,
            package_group : tpd.Package_Name
          }])
        }else if(tpd.Package_Name.length > 0) {
          setAssignedTextPackage((prev) => [...prev, {
            unit_no       : tpd.unit_calculated_number,
            unit_type     : tpd.alt_unit_id,
            package_group : tpd.Package_Name
          }])
        }
      }))
    }
  }, [GetEidoTextPackageData])

  const textTabs = [
    {
      Id: "1",
      tab: "Assigned",
      component: <EidoAssignText assignedTextPackageData={assignedTextPackage} />,
      Notification: assignedTextPackage.length,
    },
    {
      Id: "2",
      tab: "Not assigned",
      component: <EidoNotAssignText notAssignedTextPackageData={notAssignedTextPackage} />,
      Notification: notAssignedTextPackage.length,
    },
  ];

  return (
    <div className = "document-container p-4 my-2">
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius   : "8px",
          fontSize       : "14px",
          border         : "1px solid #F0F0F0",
        }}
      >
        <div
          className = "px-4 pb-3"
          style     = {{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div           style     = {{ fontFamily: "Cerebri Sans" }} className = "pt-3 d-flex">
          <div           className = "fw-600 fs-14">Text</div>
          <div           style     = {{ paddingLeft: "15px" }}>
          <CustomToolTip height    = {12} text                                  = "Text" />
            </div>
          </div>
        </div>
        <Tabs lassName="mb-3 pb-1 d-flex flex-column">
        <div
          className="d-flex ps-4 pe-3 py-3 justify-content-between"
          style={{
            width: "100%",
            flexDirection: "row",
            flexWrap: "wrap",
            borderBottom: "1px solid #F0F0F0",
          }}
        >
          <TabList className="light ps-0  ">
            {textTabs.map((page, index) => (
              <>
                <Tab
                  key={index}
                  style={{ position: "relative" }}
                  // onClick={() => setoverviewTabs(page?.tab)}
                >
                  <span className="me-1" style={{ position: "relative" }}>
                    {page?.tab}
                  </span>
                  (<span>{page?.Notification}</span>)
                </Tab>
              </>
            ))}
          </TabList>
        </div>
        <div className="bg-white" style={{ borderRadius: " 10px" }}>
          {textTabs.map((page, index) => (
            <TabPanel key={page?.Id}>{page?.component}</TabPanel>
          ))}
        </div>
      </Tabs>
      </div>
    </div>
  );
};

export default Text;
 