import React from "react";
import CustomPopup from "../../../../../../Components/CustomPopup";
// import { H5 } from "../../../../../../styles/Common.style";
// import CustomToolTip from "../../../../../../Components/Tooltip/CustomTooltip";
// import ReactSelect from "../../../../../../Components/DropDown/ReactSelect";
import ETButton from "../../../../../../Components/Buttons/ETButton";
import { useNavigate } from "react-router-dom";

// PT= PUBLISH TEMPLATE
// C= CANCEL
const PublishTemplate = ({
  popupShow = true,
  setPopupShow,
  usedFor = "PT",
  template_title = "Template title",
  handleSaveAsDraft =()=>{}
}) => {
  const navigate = useNavigate();
  const handleCancelEmailTemplate = () => {
    navigate("/organization/settings/marketing");
    setPopupShow(false);
  };

  return (
    <div>
      <CustomPopup
        open={popupShow}
        onHide={() => usedFor =="C" && setPopupShow(false)}
        title={usedFor === "C" ? "Are you sure?" : "You’ve created a template"}
        title_color="black"
        enableCrossButton={false}
        deletePopUpStyle={true}
        titleTooltip={false}
        titleTooltipText={
          usedFor === "C" ? "Are you sure?" : "You’ve created a template"
        }
      >
        <div>
          <div className="p-4 border-bottom">
            <div className="light-font-text my-1">
              {usedFor === "C" ? (
                <div>
                  Are you absolutely sure you don’t want to finish your
                  template?<br></br>You can also save the work you’ve done as a
                  draft and not loose your progress.
                </div>
              ) : (
                <>
                  <div>
                    {`Great job! Your template “${template_title}” has now been added to your email template
                    library.`}
                  </div>
                  <div className="pt-4">
                    Remember you can always modify it later.
                  </div>
                </>
              )}
            </div>
          </div>

          {/* <div className="p-4">
            <div className="d-flex align-items-center gap-2">
              <H5>Choose existing screening template</H5>{" "}
              <CustomToolTip text={"Choose existing screening template"} />
            </div>
            <div className="w-75 pt-2">
              <ReactSelect placeholder={"Templates"} />
            </div>
          </div> */}
        </div>

        <div
          className={`py-2 px-4 d-flex flex-md-row flex-column footer-section-button-container ${
            usedFor == "C" ? "justify-content-between" : "justify-content-end"
          }`}
        >
          {usedFor == "C" && (
            <ETButton
              variant={"white"}
              buttonText={"YES, CANCEL"}
              onClick={() => handleCancelEmailTemplate()}
            />
          )}
          <ETButton
            variant={"primary"}
            buttonText={usedFor === "C" ? "SAVE AS DRAFT" : "CLOSE"}
            // disabled={disableDeleteButton}
            onClick={() => usedFor === "C" ? handleSaveAsDraft() :  handleCancelEmailTemplate()}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default PublishTemplate;
