import React from "react";
import CustomPopup from "../../../../../../Components/CustomPopup";
import ETButton from "../../../../../../Components/Buttons/ETButton";

const EmailPreview = ({ popupShow = true, setPopupShow , displayHTML ="" }) => {
  return (
    <div>
      {" "}
      <CustomPopup
        open={popupShow}
        onHide={() => setPopupShow(false)}
        title={"Email preview"}
        title_color="black"
        enableCrossButton={false}
        deletePopUpStyle={true}
        titleTooltip={false}
        titleTooltipText={"Email preview"}
      >
        <div>
          <div className="p-4">
            <div className="" dangerouslySetInnerHTML={{ __html: displayHTML }}></div>
          </div>
        </div>

        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText={"Close"}
            onClick={() => setPopupShow(false)}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default EmailPreview;
