import { Container, Row } from "react-bootstrap";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from "../../../Components/Tabs/ReactTabs.style";
import Netlify from "./netlify/Netlify";
import DnsSettings from './dnsSetting/DnsSettings';
import ProjectType from './projectType/ProjectType';

const Settings = () => {
  return (
    // <div className="document-container p-4 my-2">
    //   <div
    //     style={{
    //       backgroundColor: "#fff",
    //       borderRadius: "8px",
    //       fontSize: "14px",
    //       border: "1px solid #F0F0F0",
    //     }}
    //   >
    //     <div
    //       className="px-4 pb-3"
    //       style={{ borderBottom: "1px solid #F0F0F0" }}
    //     >
    //       <div style={{ fontFamily: "Cerebri Sans" }} className="pt-3 d-flex">
    //         <div className="fw-600 fs-14">Settings</div>
    //         <div style={{ paddingLeft: "15px" }}>
    //           <CustomToolTip height={12} text="Settings" />
    //         </div>
    //       </div>
    //     </div>
    //     <div className="document-content py-3 px-4">
    //       <h1>Settings</h1>
    //     </div>
    //   </div>
    // </div>
    <Container fluid style={{ backgroundColor: "#F9FAFA", padding: "0px" }}>
      <div>
        {/* All Component place here */}
        <Container
          fluid
          style={{ backgroundColor: "#F1F5F0", borderRadius: " 10px" }}
          className="p-3"
        >
          <Tabs>
            <TabList className="light ps-3 ">
              <Tab>Netlify</Tab>
              <Tab>DNS settings</Tab>
              <Tab>Project type</Tab>
              <Tab>Isometry</Tab>
            </TabList>

            <div style={{ backgroundColor: "#F1F5F0", borderRadius: " 10px" }}>
              <TabPanel>
                <Netlify />
              </TabPanel>
              <TabPanel>
                <DnsSettings />
              </TabPanel>
              <TabPanel>
                <ProjectType />
              </TabPanel>
              <TabPanel>
                <Row
                  className="ms-3 me-3  mb-3 pt-2 pb-4  "
                  style={{ backgroundColor: "#fff", borderRadius: "8px" }}
                >
                  <div className="mt-1 px-3 d-flex">
                    <div className="fw-700">Isometry</div>
                    <div className="ps-2">
                      <CustomToolTip height={12} text="Isometry" />
                    </div>
                  </div>
                  <hr className="mt-2" style={{ color: "#F0F0F0" }}></hr>
                </Row>
              </TabPanel>
            </div>
          </Tabs>
        </Container>
      </div>
      <br />
    </Container>
  );
};

export default Settings;
