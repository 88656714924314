import React, { useState, useRef, useEffect } from "react";
import PNGWidget from "../../../Components/DownloadWidget/PNGWidget";
import JPGWidget from "../../../Components/DownloadWidget/JPGWidget";
import SVGWidget from "../../../Components/DownloadWidget/SVGWidget";
import { Col, Row } from "react-bootstrap";
import Menus from "../../../Components/Menus/Menus";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { Pie } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem } from "@mui/material";
import "../../../styles/css/control-center/economy.css";
import GroupButtons from "../../../Components/GroupButton/GroupButton";
import { StatusTable } from "./StatusTable";
import { GreenDiv, H3 } from "../../../styles/Common.style";
import { NoRecordsToDisplay } from "../../../utils/validationConstants";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import { useGetAnalyasis, useGetAnalyasisSubDeatils, useGetCompanyByCompanyId, useGetUnitStatus } from "../../../hooks/queries/useControlCenterQueries";
import { useControlCenter } from "../../../hooks/stores/useControlCenterStore";
import { GetAnalyasis } from "../../../services/ControlCenter";
import { useGetStatusOverviewSubDetails } from "../../../hooks/queries/usePropertyQueries";
import { usePropertyId } from "../../../hooks/stores/usePropertyStore";
import { H2 } from "../../../styles/Common.style";

const Analysis = () => {
  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "analysis";

  const PNGFunction = PNGWidget({ refObj, fileName });
  const JPGFunction = JPGWidget({ refObj, fileName });
  const SVGFunction = SVGWidget({ refObj, fileName });
  const { selectedProperties, selectedDate, selectedEndDate } = useControlCenter((state) => state);

  const { data: getAnalyasisdData } = useGetAnalyasis(selectedProperties, selectedDate, selectedEndDate)
  // console.log(getAnalyasisdData, "getAnalyasisdData")
  const [analysisData, setAnalysisData] = useState([])
  useEffect(() => {
    setAnalysisData(getAnalyasisdData?.data)
  }, [getAnalyasisdData])


  const HistoryPopup = () => {
    return (
      <>
        <div className="history-container">
          <div className="light-font-text fs-12 " style={{ color: "#888" }}>
            2022
          </div>
          <div style={{ lineHeight: "2" }}>
            <div className="d-flex justify-content-between">
              <div className="table-property-name">August</div>
              <div className="d-flex" style={{ gap: "20px" }}>
                <div className="fs-13 light-font-text"> 15%</div>
                <div className=" fs-13 light-font-text">(8 units)</div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="table-property-name">July</div>
              <div className="d-flex" style={{ gap: "20px" }}>
                <div className="fs-13 light-font-text"> 12%</div>
                <div className=" fs-13 light-font-text">(6 units)</div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="table-property-name">June</div>
              <div className="d-flex" style={{ gap: "20px" }}>
                <div className="fs-13 light-font-text"> 10%</div>
                <div className=" fs-13 light-font-text">(4 units)</div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="table-property-name">May</div>
              <div className="d-flex" style={{ gap: "20px" }}>
                <div className="fs-13 light-font-text"> 12%</div>
                <div className=" fs-13 light-font-text">(6 units)</div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className=" table-property-name">April</div>
              <div className="d-flex" style={{ gap: "20px" }}>
                <div className="fs-13 light-font-text">8%</div>
                <div className=" fs-13 light-font-text">(2 units)</div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="table-property-name ">March</div>
              <div className="d-flex" style={{ gap: "20px" }}>
                <div className="fs-13 light-font-text"> 15%</div>
                <div className=" fs-13 light-font-text">(8 units)</div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className=" table-property-name">February</div>
              <div className="d-flex" style={{ gap: "20px" }}>
                <div className="fs-13 light-font-text"> 15%</div>
                <div className=" fs-13 light-font-text">(8 units)</div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="table-property-name">January</div>
              <div className="d-flex" style={{ gap: "20px" }}>
                <div className="fs-13 light-font-text"> 15%</div>
                <div className="fs-13 light-font-text">(8 units)</div>
              </div>
            </div>
          </div>
          <div style={{ padding: "10px 0" }}>
            <div className="light-font-text fs-12 " style={{ color: "#888" }}>
              2021
            </div>
            <div style={{ lineHeight: "2" }}>
              <div className="d-flex justify-content-between">
                <div className="table-property-name">December</div>
                <div className="d-flex" style={{ gap: "20px" }}>
                  <div className="fs-13 light-font-text"> 15%</div>
                  <div className="light fs-13 light-font-text">(8 units)</div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="table-property-name">November</div>
                <div className="d-flex" style={{ gap: "20px" }}>
                  <div className="fs-13 light-font-text"> 15%</div>
                  <div className=" fs-13 light-font-text">(8 units)</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const [user, setUser] = useState(false);

  const open = Boolean(user);

  const handleClickUser = (event) => {
    setUser(event.currentTarget);
  };
  const data = {
    labels: getAnalyasisdData?.data?.filter((entry) => entry?.status_name !== 'Total')?.map((entry) => entry?.status_name),
    datasets: [
      {
        data: getAnalyasisdData?.data?.filter((entry) => entry?.status_name !== 'Total')?.map((entry) => entry?.quantity),
        backgroundColor: getAnalyasisdData?.data?.filter((entry) => entry?.status_name !== 'Total')?.map((entry) => entry?.background_color),
        borderColor: "transparent",
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        yAlign: "top",
        backgroundColor: "#FFFFFF",
        borderWidth: 2,
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
      },
      legend: {
        display: false,
      },
    },
  };

  const plugins = [
    {
      afterDraw: function (chart) {
        if (chart.data.datasets[0].data.every((item) => item === 0)) {
          let ctx = chart.ctx;
          let width = chart.width;
          let height = chart.height;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "25px Cerebri sans";
          ctx.fillText("No data to display", width / 2, height / 2);
          ctx.restore();
        }
      },
    },
  ];
  const [buttonStyle, setButtonStyle] = useState(0);

  const handlechange = (id) => {
    setButtonStyle(id);
  };
  const { data: unitStatusData } =
    useGetUnitStatus(selectedProperties,
      selectedDate,
      selectedEndDate,);

  // const total_units = unitStatusData?.data?.filter((data) => data.status_name == 'Total')?.map((data) => data.quantity)
  const total_units = getAnalyasisdData?.data?.filter((data) => data?.status_name == 'Total')?.map((data) => data.quantity)

  const total_amount = getAnalyasisdData?.data?.filter((item) => item.status_name === "Total")?.map((data) => data.total_amount)
  const currency_id = getAnalyasisdData?.data?.filter((item) => item.status_name === "Total")?.map((data) => data.currency_id)

  const total_quantity = getAnalyasisdData?.data?.filter((item) => item.status_name === "Total")?.map((data) => data.quantity)
  const total_percentage = getAnalyasisdData?.data?.filter((item) => item.status_name === "Total")?.map((data) => data.percentage)
  const [statusId, setStatusId] = useState({
    mainStatusId: "",
    subStatusId: "",
  });
  const propertyId = usePropertyId((state) => state.propertyId);
  const { data: getStatusOverviewSubDetails } = useGetStatusOverviewSubDetails(
    propertyId,
    statusId?.mainStatusId,
    statusId?.subStatusId
  );

  const { data: getAnalyasisSubDeatilsData } = useGetAnalyasisSubDeatils(
    selectedProperties,
    statusId?.mainStatusId,
    statusId?.subStatusId,
    selectedDate,
    selectedEndDate)


  const [propertyOverview, setPropertyOverview] = useState(true)
  return (
    <div className="card-body p-4" ref={myref}>
      <Row>
        <Col className="d-flex align-items-center" sm={8}>
          {" "}
          <div className="d-flex align-items-center">
            <H3 className=" me-2">Analysis</H3>{" "}
            <CustomToolTip size="sm" text="Analysis" />
          </div>
        </Col>
        <Col sm={4} className="d-flex justify-content-end">

          {" "}
          <Menus
            itemheight={40}
            itemwidth={40}
            itemcontainerheight={200}
            itemcontainerwidth={200}
            component={
              <div className="fs-14">
                <div className="addpropContent px-3 py-2 ">
                  <span className="ms-1" onClick={() => PNGFunction()}>
                    Download widget as PNG
                  </span>
                </div>
                <hr className="addpropertyHr m-0"></hr>
                <div
                  onClick={() => JPGFunction()}
                  className="addpropContent px-3 pt-2 pb-2 "
                >
                  <span className="ms-1">Download widget as JPG</span>
                </div>
                <hr className="addpropertyHr m-0"></hr>
                <div
                  onClick={() => SVGFunction()}
                  className="addpropContent px-3 pt-2 pb-2 "
                >
                  <span className="ms-1">Download widget as SVG</span>
                </div>
              </div>
            }
          />
        </Col>
      </Row>
      <Row className="py-4">
        <Col className="mb-md-3 mb-lg-0" lg={6}>
          <div
            style={{ background: "#F9FAFA", height: "250px" }}
            className="radius-8"
          >
            <Row>
              <Col>
                {getAnalyasisdData?.data ? <div className="d-flex justify-content-center align-items-center  p-3">
                  <div style={{ height: "217px", width: "245px" }}>
                    <Pie data={data} options={options} plugins={plugins} />
                  </div>
                  <div className="d-flex justify-content-end align-items-center">

                    <span>
                      <div className="fs-13 light-font-text">Total units</div>
                      <div className="table-property-name fs-16">{total_units}</div>
                      <div className="mt-3 fs-13 light-font-text">
                        Total yearly rent
                      </div>
                      <div className="table-property-name  fs-16">
                        {total_amount}{currency_id}
                      </div>
                    </span>
                  </div>

                </div> :
                  <div className="pt-4">
                    <NoDataScreen height={"167px"} imgHeight={"127px"} />
                  </div>
                }

              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={6}>
          {" "}
          <div
            style={{ background: "#F9FAFA", height: "250px" }}
            className="radius-8 p-3"
          >
            <div className="d-flex justify-content-center align-items-center">
              <div>
                <div className="form-label-title ps-2">Idleness rate</div>
                <div className="d-flex justify-content-center align-items-center gap-4">
                  <H3 style={{ fontSize: "75px" }}>
                    0%
                  </H3>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "17px",
                      background: "#DFF0E8",
                      padding: "3px 20px",
                      color: "#77C7A1",
                      borderRadius: "4px",
                    }}
                    className="form-label-title"
                  >
                    <FontAwesomeIcon
                      icon="fa-regular fa-arrow-up"
                      className="me-1"
                    />
                    0
                  </div>
                </div>
                <GreenDiv
                  className="light-font-text fs-13 ps-2 cursor-pointer "
                  onClick={handleClickUser}
                >
                  Show history
                </GreenDiv>
                <Menu
                  id="basic-menu"
                  anchorEl={user}
                  open={user}
                  autoFocus={false}
                  onClose={() => setUser(null)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.02)",
                    "& .MuiPaper-root": {
                      borderRadius: "8px",
                      width: "fit-content",
                      marginTop: "8px",
                    },
                  }}
                >
                  {/* <div className="untilidleness-container">
                    <HistoryPopup />
                  </div> */}
                  <div className="p-2">{NoRecordsToDisplay}</div>
                </Menu>
              </div>
            </div>
            <div className="d-flex justify-content-end pe-3">
              <div
                style={{
                  marginBottom: "13px",
                  width: "150px",
                }}
              >
                {/* <GroupButtons
                  tabs={[
                    {
                      id: 1,
                      tabTitle: "Daily",
                      title: "Daily",
                    },
                    {
                      id: 2,
                      tabTitle: "Monthly",
                      title: "Monthly",
                    },
                  ]}
                  currentTab={buttonStyle}
                  handleTabClick={(tab) => {
                    handlechange(tab);
                  }}
                /> */}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <StatusTable
        getAnalyasisdData={getAnalyasisdData?.data}
        setStatusId={setStatusId}
        subStatusData={getAnalyasisSubDeatilsData?.data}
        propertyId={propertyId}
        propertyOverview={propertyOverview}

      />
    </div>
  );
};

export default Analysis;
