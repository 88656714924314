
import { Get } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";


export const GetEconomyAvailabilityByRoom = (allProperties, startDateFormate, endDateFormate) => {
    allProperties = allProperties || [];
    const propertyIds = allProperties.join(',');
    allProperties =
        propertyIds == "" || propertyIds == null || propertyIds == undefined
            ? 0
            : propertyIds;
    endDateFormate = endDateFormate == "" || endDateFormate == null || endDateFormate == undefined
        ? endDateFormate
        : endDateFormate;
    startDateFormate = startDateFormate == "" || startDateFormate == null || startDateFormate == undefined
        ? startDateFormate
        : startDateFormate;

    let url;
    url = Endpoint.ET.GetEconomyAvailabilityByRoom + `/${allProperties}/${startDateFormate}/${endDateFormate}`;
    // }
    return new Promise((resolve, reject) => {
        Get(
            Site.ET,
            url,
            null
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject({ error: true, message: error.message });
            });
    });
};


export const GetEconomyAvailabilityBySqmtr = (allProperties, startDateFormate, endDateFormate) => {
    allProperties = allProperties || [];
    const propertyIds = allProperties.join(',');
    allProperties =
        propertyIds == "" || propertyIds == null || propertyIds == undefined
            ? 0
            : propertyIds;
    endDateFormate = endDateFormate == "" || endDateFormate == null || endDateFormate == undefined
        ? endDateFormate
        : endDateFormate;
    startDateFormate = startDateFormate == "" || startDateFormate == null || startDateFormate == undefined
        ? startDateFormate
        : startDateFormate;

    let url;
    url = Endpoint.ET.GetEconomyAvailabilityBySqmtr + `/${allProperties}/${startDateFormate}/${endDateFormate}`;
    // }
    return new Promise((resolve, reject) => {
        Get(
            Site.ET,
            url,
            null
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject({ error: true, message: error.message });
            });
    });
};


// export const GetEconomyAvailabilityBySqmtr = (allProperties, startDateFormate, endDateFormate) => {
//     allProperties = allProperties || [];
//     const propertyIds = allProperties.join(',');
//     allProperties =
//         propertyIds == "" || propertyIds == null || propertyIds == undefined
//             ? 0
//             : propertyIds;
//     let url;
//     if (startDateFormate == null && endDateFormate == null) {
//         startDateFormate = startDateFormate === "" || startDateFormate === null || startDateFormate === undefined
//             ? ""
//             : startDateFormate;

//         endDateFormate = endDateFormate === "" || endDateFormate === null || endDateFormate === undefined
//             ? ""
//             : endDateFormate;
//         url = Endpoint.ET.GetEconomyAvailabilityBySqmtr + `/${allProperties}`;
//     } else {
//         url = Endpoint.ET.GetEconomyAvailabilityBySqmtr + `/${allProperties}/${startDateFormate}/${endDateFormate}`;
//     }
//     return new Promise((resolve, reject) => {
//         Get(
//             Site.ET,
//             url,
//             null
//         )
//             .then((response) => {
//                 resolve(response.data);
//             })
//             .catch((error) => {
//                 reject({ error: true, message: error.message });
//             });
//     });
// };

// export const GetUnitStatus = (allProperties, startDateFormate, endDateFormate) => {
//     allProperties = allProperties || [];
//     const propertyIds = allProperties.join(',');
//     allProperties =
//         propertyIds == "" || propertyIds == null || propertyIds == undefined
//             ? 0
//             : propertyIds;
//     let url;
//     if (startDateFormate == null && endDateFormate == null) {
//         startDateFormate = startDateFormate === "" || startDateFormate === null || startDateFormate === undefined
//             ? ""
//             : startDateFormate;

//         endDateFormate = endDateFormate === "" || endDateFormate === null || endDateFormate === undefined
//             ? ""
//             : endDateFormate;
//         url = Endpoint.ET.Overview_GetUnitStatus + `/${allProperties}`;
//     } else {
//         url = Endpoint.ET.Overview_GetUnitStatus + `/${allProperties}/${startDateFormate}/${endDateFormate}`;
//     }
//     return new Promise((resolve, reject) => {
//         Get(
//             Site.ET,
//             url,
//             null
//         )
//             .then((response) => {
//                 resolve(response.data);
//             })
//             .catch((error) => {
//                 reject({ error: true, message: error.message });
//             });
//     });
// };

export const GetUnitStatus = (allProperties) => {

    allProperties = allProperties || [];
    const propertyIds = allProperties.join(',');
    allProperties =
        propertyIds == "" || propertyIds == null || propertyIds == undefined
            ? 0
            : propertyIds;
    return new Promise((resolve, reject) => {
        Get(
            Site.ET,
            Endpoint.ET.Overview_GetUnitStatus_ByPropertiesId,
            null,
            allProperties,
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject({ error: true, message: error.message });
            });
    });
};

export const GetUpcommingIdleness = (allProperties) => {
    allProperties = allProperties || [];
    const propertyIds = allProperties.join(',');
    allProperties =
        propertyIds == "" || propertyIds == null || propertyIds == undefined
            ? 0
            : propertyIds;

    return new Promise((resolve, reject) => {
        Get(
            Site.ET,
            Endpoint.ET.Economy_GetUpcommingIdleness,
            null,
            allProperties,
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject({ error: true, message: error.message });
            });
    });
};

export const GetNoteCategory = (categoryId, allProperties, startDateFormate, endDateFormate) => {
    allProperties = allProperties || [];
    const propertyIds = allProperties.join(',');
    const category = categoryId.join(',')
    categoryId = category == "" || category == null || category == undefined
        ? 0
        : category;
    allProperties =
        propertyIds == "" || propertyIds == null || propertyIds == undefined
            ? 0
            : propertyIds;
    endDateFormate = endDateFormate == "" || endDateFormate == null || endDateFormate == undefined
        ? endDateFormate
        : endDateFormate;
    startDateFormate = startDateFormate == "" || startDateFormate == null || startDateFormate == undefined
        ? startDateFormate
        : startDateFormate;

    let url;
    url = Endpoint.ET.Overview_GetNoteCategory + `/${categoryId}/properties/${allProperties}/${startDateFormate}/${endDateFormate}`;
    return new Promise((resolve, reject) => {
        Get(
            Site.ET,
            url,
            null
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject({ error: true, message: error.message });
            });
    });
};

// export const GetNoteCategory = (categoryId, allProperties, startDateFormate, endDateFormate) => {
//     allProperties = allProperties || [];
//     const propertyIds = allProperties.join(',');
//     const category = categoryId.join(',')
//     categoryId = category == "" || category == null || category == undefined
//         ? 0
//         : category;
//     allProperties =
//         propertyIds == "" || propertyIds == null || propertyIds == undefined
//             ? 0
//             : propertyIds;
//     let url;
//     if (startDateFormate == null && endDateFormate == null) {
//         startDateFormate = startDateFormate === "" || startDateFormate === null || startDateFormate === undefined
//             ? ""
//             : startDateFormate;

//         endDateFormate = endDateFormate === "" || endDateFormate === null || endDateFormate === undefined
//             ? ""
//             : endDateFormate;
//         url = Endpoint.ET.Overview_GetNoteCategory + `/${categoryId}/properties/${allProperties}`;
//     } else {
//         url = Endpoint.ET.Overview_GetNoteCategory + `/${categoryId}/properties/${allProperties}/${startDateFormate}/${endDateFormate}`;
//     }
//     return new Promise((resolve, reject) => {
//         Get(
//             Site.ET,
//             url,
//             null
//         )
//             .then((response) => {
//                 resolve(response.data);
//             })
//             .catch((error) => {
//                 reject({ error: true, message: error.message });
//             });
//     });
// };


export const GetTransactionsDividedByRooms = (allProperties, startDateFormate, endDateFormate) => {
    allProperties = allProperties || [];
    const propertyIds = allProperties.join(',');
    allProperties =
        propertyIds == "" || propertyIds == null || propertyIds == undefined
            ? 0
            : propertyIds;
    endDateFormate = endDateFormate == "" || endDateFormate == null || endDateFormate == undefined
        ? endDateFormate
        : endDateFormate;
    startDateFormate = startDateFormate == "" || startDateFormate == null || startDateFormate == undefined
        ? startDateFormate
        : startDateFormate;
    let url;
    url = Endpoint.ET.Customer_GetTransactionsDividedByRooms + `/${allProperties}/${startDateFormate}/${endDateFormate}`;
    return new Promise((resolve, reject) => {
        Get(
            Site.ET,
            url,
            null
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject({ error: true, message: error.message });
            });
    });
};


export const GetTransactionsDividedBySqm = (allProperties, startDateFormate, endDateFormate) => {
    allProperties = allProperties || [];
    const propertyIds = allProperties.join(',');
    allProperties =
        propertyIds == "" || propertyIds == null || propertyIds == undefined
            ? 0
            : propertyIds;
    endDateFormate = endDateFormate == "" || endDateFormate == null || endDateFormate == undefined
        ? endDateFormate
        : endDateFormate;
    startDateFormate = startDateFormate == "" || startDateFormate == null || startDateFormate == undefined
        ? startDateFormate
        : startDateFormate;
    let url;
    url = Endpoint.ET.Customer_GetTransactionsDividedBySqm + `/${allProperties}/${startDateFormate}/${endDateFormate}`;
    return new Promise((resolve, reject) => {
        Get(
            Site.ET,
            url,
            null
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject({ error: true, message: error.message });
            });
    });
};

// export const GetTransactionsDividedBySqm = (allProperties, startDateFormate, endDateFormate) => {
//     allProperties = allProperties || [];
//     const propertyIds = allProperties.join(',');
//     allProperties =
//         propertyIds == "" || propertyIds == null || propertyIds == undefined
//             ? 0
//             : propertyIds;
//     let url;
//     if (startDateFormate == null && endDateFormate == null) {
//         startDateFormate = startDateFormate === "" || startDateFormate === null || startDateFormate === undefined
//             ? ""
//             : startDateFormate;

//         endDateFormate = endDateFormate === "" || endDateFormate === null || endDateFormate === undefined
//             ? ""
//             : endDateFormate;
//         url = Endpoint.ET.Customer_GetTransactionsDividedBySqm + `/${allProperties}`;
//     } else {
//         url = Endpoint.ET.Customer_GetTransactionsDividedBySqm + `/${allProperties}/${startDateFormate}/${endDateFormate}`;
//     }
//     return new Promise((resolve, reject) => {
//         Get(
//             Site.ET,
//             url,
//             null
//         )
//             .then((response) => {
//                 resolve(response.data);
//             })
//             .catch((error) => {
//                 reject({ error: true, message: error.message });
//             });
//     });
// };

export const GetBreakDownByRegion = (allProperties, startDateFormate, endDateFormate) => {
    allProperties = allProperties || [];
    const propertyIds = allProperties.join(',');
    allProperties =
        propertyIds == "" || propertyIds == null || propertyIds == undefined
            ? 'dk'
            : propertyIds;
    endDateFormate = endDateFormate == "" || endDateFormate == null || endDateFormate == undefined
        ? endDateFormate
        : endDateFormate;
    startDateFormate = startDateFormate == "" || startDateFormate == null || startDateFormate == undefined
        ? startDateFormate
        : startDateFormate;
    let url;
    url = Endpoint.ET.Customer_GetBreakDownByRegion + `/${allProperties}/${startDateFormate}/${endDateFormate}`;
    return new Promise((resolve, reject) => {
        Get(
            Site.ET,
            url,
            null
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject({ error: true, message: error.message });
            });
    });
};

export const GetAnalyasis = (allProperties, startDateFormate, endDateFormate) => {
    allProperties = allProperties || [];
    const propertyIds = allProperties.join(',');
    allProperties =
        propertyIds == "" || propertyIds == null || propertyIds == undefined
            ? 0
            : propertyIds;
    endDateFormate = endDateFormate == "" || endDateFormate == null || endDateFormate == undefined
        ? endDateFormate
        : endDateFormate;
    startDateFormate = startDateFormate == "" || startDateFormate == null || startDateFormate == undefined
        ? startDateFormate
        : startDateFormate;
    let url;
    url = Endpoint.ET.Economy_GetAnalyasis + `/${allProperties}/${startDateFormate}/${endDateFormate}`;
    return new Promise((resolve, reject) => {
        Get(
            Site.ET,
            url,
            null
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject({ error: true, message: error.message });
            });
    });
};

export const GetBreakDownByZipcode = (selectedRowRegionId, startDateFormate, endDateFormate) => {
    const propertyIds = selectedRowRegionId == "" || selectedRowRegionId == null || selectedRowRegionId == undefined
        ? ''
        : selectedRowRegionId;
    endDateFormate = endDateFormate == "" || endDateFormate == null || endDateFormate == undefined
        ? endDateFormate
        : endDateFormate;
    startDateFormate = startDateFormate == "" || startDateFormate == null || startDateFormate == undefined
        ? startDateFormate
        : startDateFormate;
    let url;
    url = Endpoint.ET.Customer_GetBreakDownByZipcode + `/${propertyIds}/${startDateFormate}/${endDateFormate}`;
    return new Promise((resolve, reject) => {
        Get(
            Site.ET,
            url,
            null
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject({ error: true, message: error.message });
            });
    });
};


export const GetUnitStatusByPropertyId = (allProperties, startDateFormate, endDateFormate) => {
    allProperties = allProperties || [];
    const propertyIds = allProperties.join(',');
    allProperties =
        propertyIds == "" || propertyIds == null || propertyIds == undefined
            ? 0
            : propertyIds;
    endDateFormate = endDateFormate == "" || endDateFormate == null || endDateFormate == undefined
        ? endDateFormate
        : endDateFormate;
    startDateFormate = startDateFormate == "" || startDateFormate == null || startDateFormate == undefined
        ? startDateFormate
        : startDateFormate;
    let url;
    url = Endpoint.ET.Overview_GetUnitStatus + `/${allProperties}/${startDateFormate}/${endDateFormate}`;
    return new Promise((resolve, reject) => {
        Get(
            Site.ET,
            url,
            null
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject({ error: true, message: error.message });
            });
    });
};

export const GetAnalyasisSubDeatils = (allProperties, main_status_id, substatus_id, startDateFormate, endDateFormate) => {
    allProperties = allProperties || [];
    const propertyIds = allProperties.join(',');
    allProperties =
        propertyIds == "" || propertyIds == null || propertyIds == undefined
            ? 0
            : propertyIds;
    endDateFormate = endDateFormate == "" || endDateFormate == null || endDateFormate == undefined
        ? endDateFormate
        : endDateFormate;
    startDateFormate = startDateFormate == "" || startDateFormate == null || startDateFormate == undefined
        ? startDateFormate
        : startDateFormate;
    let url;
    url = Endpoint.ET.Economy_GetAnalyasisSubDeatils + `/${allProperties}/status/${main_status_id}/substatus/${substatus_id}/${startDateFormate}/${endDateFormate}`;
    return new Promise((resolve, reject) => {
        Get(
            Site.ET,
            url,
            null
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject({ error: true, message: error.message });
            });
    });
};


export const GetTransactionsDividedByAge = (allProperties, startDateFormate, endDateFormate) => {
    allProperties = allProperties || [];
    const propertyIds = allProperties.join(',');
    allProperties =
        propertyIds == "" || propertyIds == null || propertyIds == undefined
            ? 0
            : propertyIds;
    endDateFormate = endDateFormate == "" || endDateFormate == null || endDateFormate == undefined
        ? endDateFormate
        : endDateFormate;
    startDateFormate = startDateFormate == "" || startDateFormate == null || startDateFormate == undefined
        ? startDateFormate
        : startDateFormate;
    let url;
    url = Endpoint.ET.Customer_GetTransactionsDividedByAge + `/${allProperties}/${startDateFormate}/${endDateFormate}`;
    return new Promise((resolve, reject) => {
        Get(
            Site.ET,
            url,
            null
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject({ error: true, message: error.message });
            });
    });
};