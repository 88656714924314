import React, { useState } from "react";
import CustomPopup from "../../../../Components/CustomPopup";
import ETButton from "../../../../Components/Buttons/ETButton";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import FilterComponent from "../../../../reactdataTableComponent/FilterComponent";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import { useForm, Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { PrimarySelectedTag } from "../../../../styles/Common.style";

const Duplicate = ({
  openDuplicate,
  setOpenDuplicate,
  propertyData,
  handleDuplicate,
  allChecked,
  setAllChecked,
  setTitle,
  resetData,
  duplicateDataName,
  disableDuplicateButton,
}) => {
  const [searchText, setSearchText] = useState("");
  //------------------validation-----------------------------------------//
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      packageTitle: "",
      whatToDuplicate: "",
    },
  });

  const handleDuplicateMain = async (data) => {
    await handleDuplicate(data);
    reset();
  };

  //----------------- checked  property for packages--------------------//
  const handleOnChecked = (e, index) => {
    const { id, checked } = e.currentTarget;
    if (checked) {
      let newArry = [...allChecked, id];
      setAllChecked(newArry);
    } else {
      let newArry = allChecked.filter((item) => item != String(id));
      if (newArry.length != 0) {
        setAllChecked(newArry);
      } else {
        setAllChecked([]);
      }
    }
  };

  const handleReset = () => {
    setSearchText("");
    setAllChecked([]);
  };

  // ------------dummy options---------------------//

  const dataOption = [
    { label: "Complete ", value: 0 },
    { label: "Without Tags ", value: 1 },
  ];

  const dataOptionsFacility = [
    {
      label: "Complete (Facilities with attributes details and Tags)",
      value: 0,
    },
    { label: "Facilities (With attribute details)", value: 1 },
    { label: "Facilities (Without attribute details)", value: 2 },
  ];

  const dataOptionsText = [
    { label: "Complete text and tags", value: 0 },
    { label: "Text", value: 1 },
  ];

  const dataOptionsMedia = [
    { label: "Complete (Images, Video and Tags)", value: 0 },
    { label: "Media (Images and Video)", value: 1 },
    { label: "Images", value: 2 },
    { label: "Video", value: 3 },
  ];

  const emptyDataSerachMessage = propertyData?.filter((item) => {
    if (searchText === "") {
      return item;
    } else if (
      item?.propertyName?.toLowerCase()?.includes(searchText.toLowerCase())
    ) {
      return item;
    }
  })?.length;

  return (
    <div>
      <CustomPopup
        size="md"
        open={openDuplicate}
        onHide={() => {
          setOpenDuplicate(false);
          handleReset();
        }}
        title="Duplicate package"
        title_color="black"
        titleFont="14px"
        titleTooltip={true}
        enableCrossButton={false}
        titleTooltipText="Duplicate package"
        onCloseAction={() => {
          setOpenDuplicate(false);
          handleReset();
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div>
          <Form onSubmit={handleSubmit(handleDuplicateMain)}>
            <div className="p-4">
              <div className=" form-label-title pb-1">New package title</div>
              <div className="mt-2">
                <Controller
                  control={control}
                  {...register("packageTitle")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <div className="form-input ">
                      <input
                        type="text"
                        placeholder="Package title"
                        id="packageTitleId"
                        className="ms-0"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          setValue("packageTitle", e.target.value);
                          clearErrors("packageTitle");
                        }}
                      />
                      <label for="packageTitleId">Package title</label>
                    </div>
                  )}
                />
                {errors?.packageTitle && (
                  <p className="error-msg">This field is required</p>
                )}
              </div>
            </div>
            <div className="px-4" style={{ borderBottom: "1px solid #F0F0F0" }}>
              <div className="form-label-title pb-1 ">
                Select what to duplicate
              </div>
              <div className="mt-2 mb-4 pb-1">
                <Controller
                  control={control}
                  {...register("whatToDuplicate")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"What to duplicate"}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("whatToDuplicate");
                      }}
                      options={
                        duplicateDataName == "text"
                          ? dataOptionsText.map((item) => ({
                              value: item?.value,
                              label: `${item?.label} `,
                            }))
                          : duplicateDataName == "facility"
                          ? dataOptionsFacility.map((item) => ({
                              value: item?.value,
                              label: `${item?.label} `,
                            }))
                          : duplicateDataName == "media"
                          ? dataOptionsMedia.map((item) => ({
                              value: item?.value,
                              label: `${item?.label} `,
                            }))
                          : dataOption.map((item) => ({
                              value: item?.value,
                              label: `${item?.label} `,
                            }))
                      }
                    />
                  )}
                />
                {errors.whatToDuplicate && (
                  <p className="error-msg">This field is required</p>
                )}
              </div>
            </div>

            <div>
              <div className="d-flex justify-content-between  px-4 pt-4 mt-1 pb-2 mb-1">
                <div className="form-label-title">Select properties</div>
                <div>
                  {" "}
                  <input
                    value={"Reset"}
                    type="button"
                    onClick={handleReset}
                    className="form-label-title fs-12"
                    style={{
                      height: "23px",
                      width: "56px",
                      borderRadius: "4px",
                      backgroundColor: "rgba(240, 240, 240, 1)",
                      border: "1px solid rgba(209, 209, 209, 1)",
                    }}
                  />
                </div>
              </div>

              <div className="px-4 pb-3">
                <FilterComponent
                  filtertext={searchText}
                  handleSearch={(e) => setSearchText(e.target.value)}
                />
              </div>
              {emptyDataSerachMessage == 0 ? (
                <div
                  className="fs-14 grey-text py-3 "
                  style={{ textAlign: "center" }}
                >
                  You do not have any propertie to select.
                </div>
              ) : (
                <>
                  <div
                    className=" scrollable-container-y ps-4 pe-2 me-2 pb-2"
                    style={{
                      height: "198px",
                      overflowY: "scroll",
                    }}
                  >
                    {propertyData
                      ?.filter((item) => {
                        if (searchText === "") {
                          return item;
                        } else if (
                          item?.propertyName
                            ?.toLowerCase()
                            ?.includes(searchText.toLowerCase())
                        ) {
                          return item;
                        }
                      })
                      .map((item, index) => (
                        <PrimarySelectedTag
                          key={index}
                          mode={allChecked?.includes(String(item?.property_id))}
                          // style={{
                          // 	border: allChecked?.includes(
                          // 		String(item?.property_id)
                          // 	)
                          // 		? "1px solid #619130"
                          // 		: "1px solid #D1D1D1",
                          // 	backgroundColor:
                          // 		allChecked?.includes(String(item?.property_id)) &&
                          // 		"rgba(97, 145, 48, 0.08)",
                          // 	height: "44px",
                          // 	alignItems: "center",
                          // }}
                          className="d-flex justify-content-between radius-8 ps-3  my-2 cursor-pointer"
                        >
                          <div
                            className="light-font-text d-flex "
                            style={{ alignItems: "center" }}
                          >
                            {item?.propertyName}
                          </div>
                          <CustomCheckBox
                            id={item?.property_id}
                            checked={allChecked?.includes(
                              String(item?.property_id)
                            )}
                            value={item?.property_id}
                            onChange={(e) => handleOnChecked(e, index)}
                          />
                        </PrimarySelectedTag>
                      ))}
                  </div>
                </>
              )}
            </div>

            <div
              className="py-2 px-3 d-flex  justify-content-between  justify-content-center footer-section-button-container"
              style={{
                zIndex: "5",
              }}
            >
              <ETButton
                variant={"white"}
                buttonText="CANCEL"
                onClick={() => {
                  setOpenDuplicate(false);
                  setSearchText("");
                  reset();
                  setAllChecked([]);
                }}
              />
              <ETButton
                disabled={
                  (allChecked.length > 0 ? false : true) ||
                  disableDuplicateButton
                }
                variant={"primary"}
                buttonText="DUPLICATE"
                type={"submit"}
              />
            </div>
          </Form>
        </div>
      </CustomPopup>
    </div>
  );
};

export default Duplicate;
