import React, { useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import "./Popups.css";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import GroupText from "../../../../Components/GroupText/GroupText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ETButton from "../../../../Components/Buttons/ETButton";
import moment from "moment";
import { PostAddNote } from "../../../../services/Notes";
import { toast } from "react-toastify";

const MovingInDetails = ({
  setMoveInPopup,
  getNotesDetailsData,
  contractId,
  getNotesDetailsDataRefetch,
  contractOverViewData,
}) => {
  //------------------States-------------------//
  const [subjectLine, setSubjectLine] = useState("");
  const [message, setMessage] = useState("");

  const handleSendMovingInNote = () => {
    const sendNotePayload = {
      category_id: "4",
      subject: subjectLine,
      message: message,
      contract_id: String(contractId),
    };

    PostAddNote(sendNotePayload).then((response) => {
      if (response?.data?.bReturn) {
        toast.success(response.data.message);
        setMessage("");
        setSubjectLine("");
        getNotesDetailsDataRefetch();
      }
    });
  };

  return (
    <div>
      {" "}
      <div className="px-3 py-4" style={{ borderBottom: "1px Solid #F4F5F5" }}>
        <Row className="">
          <Col lg={3}>
            <div className="form-input-group ">
              <input
                type="date"
                id="Dateofrental"
                placeholder="Dateofrental"
                disabled={true}
                value={moment(
                  contractOverViewData?.data?.[0]?.moving_in_date
                ).format("YYYY-MM-DD")}
              />
              <label htmlFor="Dateofrental">Date of rental</label>
            </div>
          </Col>
          <Col lg={3}></Col>
          <Col lg={3}> </Col>
        </Row>
      </div>
      <div className="px-3 py-4">
        <div className="mb-3 font-semibold fs-14">Add a note</div>
        <div className="deposit-text-area">
          <div className="pe-2 pt-2">
            <div
              style={{ height: "219px ", overflowY: "scroll" }}
              className="scrollable-container-y "
            >
              {getNotesDetailsData?.data?.filter((el) => el?.category_id === 4)
                ?.length > 0 ? (
                getNotesDetailsData?.data
                  ?.filter((el) => el?.category_id === 4)
                  ?.map((item, index) => (
                    <div className="card-div px-3 py-2 ms-3  mb-2">
                      <div className="d-flex justify-content-between">
                        <div
                          style={{
                            backgroundColor: item?.bg_color || "#9370DB",
                            color: "white",
                            borderRadius: "4px",
                            fontSize: "9px",
                            alignItems: "center",
                            textTransform: "uppercase",
                          }}
                          className="mt-1 px-2 d-flex font-semibold"
                        >
                          {item?.category_name}
                        </div>
                        <div className=" fs-10">
                          {moment(item?.created_on)?.format(
                            "DD MMM, YYYY [at] hh:mm"
                          )}
                        </div>
                      </div>

                      <div className="mt-2 font-semibold fs-14">
                        {item?.subject}
                      </div>

                      <div
                        className="mt-1"
                        style={{
                          fontSize: "14px",
                          fontWeight: "300",
                          fontFamily: "Cerebri Sans",
                        }}
                      >
                        {item?.message}
                      </div>
                      <div className="mt-2 d-flex justify-content-between light-font-text fs-12">
                        <div>by {item?.created_by}</div>
                        <div>
                          <FontAwesomeIcon
                            icon="fa-regular fa-arrow-up-right-from-square"
                            style={{ color: "#000000" }}
                          />
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <div
                  className="d-flex justify-content-center w-100 fs-12 mt-2"
                  style={{ color: "#888888" }}
                >
                  No notes found
                </div>
              )}
            </div>
          </div>
          <div className="text-area p-3">
            <InputGroup className="deposit-textfield">
              <Form.Control
                placeholder="Subject line"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                value={subjectLine}
                onChange={(e) => setSubjectLine(e.target.value)}
              />
            </InputGroup>
            <InputGroup className="deposit-textarea">
              <Form.Control
                as="textarea"
                placeholder="Messages"
                aria-label="Large"
                aria-describedby="inputGroup-sizing-sm"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <InputGroup.Text
                className="deposit-textarea-icon"
                id="inputGroup-sizing-lg"
              >
                <button
                  disabled={
                    subjectLine?.length == 0 || (message?.length == 0 && true)
                  }
                  onClick={() => handleSendMovingInNote()}
                  className="message-send-button"
                  style={{
                    opacity:
                      subjectLine?.length == 0 || message?.length == 0
                        ? "50%"
                        : "inherit",
                  }}
                >
                  {
                    <FontAwesomeIcon
                      size="xs"
                      icon="fa-regular fa-paper-plane-top"
                      style={{ color: "#FFFFFF" }}
                    />
                  }
                </button>
              </InputGroup.Text>
            </InputGroup>
          </div>
        </div>
      </div>
      <div
        className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center 
        footer-section-button-container"
      >
        <ETButton
          variant={"white"}
          buttonText="CLOSE"
          onClick={() => setMoveInPopup(false)}
        />
        <ETButton variant={"primary"} buttonText="CONFIRM"  onClick={() => setMoveInPopup(false)}/>
      </div>
    </div>
  );
};

export default MovingInDetails;
