import React from "react";
import { REQUIRED_ERR } from "../../utils/validationConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TextAreaField = ({
  name,
  id,
  placeholder,
  cols,
  rows,
  value,
  onChange,
  iserror,
  errormessage,
  errorscope = true,
  resize,
}) => {
  return (
    <>
      <div
        className={` ${iserror
            ? "form-float-error openhouse-des-error"
            : "form-float openhouse-des"
          }`}
      >
        <textarea
          style={{
            resize: `${resize}`,
          }}
          name={name}
          placeholder={placeholder}
          id={id}
          cols={cols ? cols : 20}
          rows={rows ? rows : 4}
          value={value}
          onChange={onChange}
        ></textarea>
        <label htmlFor={id} className="floating-label">
          {placeholder}
        </label>
      </div>
      {errorscope && iserror && (
        <p className="error-msg">
           <div className=" d-flex align-items-center gap-1">
              <FontAwesomeIcon
                icon="fa-solid fa-circle-exclamation "
                size="sm"
                style={{ color: "#e33716" }}
              />{" "}
               {errormessage ? errormessage : REQUIRED_ERR}
            </div>
         
        </p>
      )}
    </>
  );
};

export default TextAreaField;
