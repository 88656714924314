import * as React from "react";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { QuestionMark, QuestionMarkRed } from "../../assets/icons/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabsButtonIcon } from "../Tabs/Tabs.style";

const longText = `
Aliquam eget finibus ante, non facilisis lectus. Sed vitae dignissim est, vel aliquam tellus.
Praesent non nunc mollis, fermentum neque at, semper arcu.
Nullam eget est sed sem iaculis gravida eget vitae justo.
`;

const defaultTheme = createTheme();

export default function PromoToolTip({
  color,
  text,
  bgcolor,
  height,
  border,
  iconcolor,
}) {
  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            color: color,
            backgroundColor: bgcolor,
            border: border,
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <div>
        <ThemeProvider theme={theme}>
          <Tooltip title={text}>
            <div style={{ m: 1 }}>
              <TabsButtonIcon>
                {/* <FontAwesomeIcon
                    icon="fa-solid fa-circle-question"
                    size="xs"
                    height={height}
                  /> */}
                <FontAwesomeIcon icon="fa-solid fa-circle-info" size="md" />
              </TabsButtonIcon>

              {/* <QuestionMark height={height}/> */}
            </div>
          </Tooltip>
        </ThemeProvider>
      </div>
    </ThemeProvider>
  );
}

PromoToolTip.defaultProps = {
  height: "20px",
  color: "#000000",
  bgcolor: "#ffffff",
  border: "1px solid #f4f5f5 ",
};
