import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOrganizationId } from "../../hooks/stores/useOrganizationStore";
import { useGetCompanyByLevel } from "../../hooks/queries/useCompanyQueries";
import { Col, Form, Row } from "react-bootstrap";
import { H4 } from "../../styles/Common.style";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import ETButton from "../../Components/Buttons/ETButton";
import {
  useGetDivisionByOrganisationId,
  useGetDivisionOverviewByDivisionId,
} from "../../hooks/queries/useDivisionQueries";
import {
  useDivisionId,
  useDivisionName,
} from "../../hooks/stores/useDivisionsStore";
import { useCompanyId, useEditCompany } from "../../hooks/stores/useCompanyStore";

const SelectDivision = ({
  handleNext,
  handleClose,
  flag,
  formData,
  setFormData,
}) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    clearErrors,
    getValues,
    reset,
    watch,
    resetField,
    formState: { errors },
  } = useForm({
    division_id: "",
    company_id: "",
  });

  useEffect(() => {
    if (formData?.division_id) {
      setValue("division_id", formData?.division_id);
    }
    if (formData?.company_id?.value) {
      setValue("company_id", formData?.company_id);
    }
  }, [formData]);

  // ----------------- zustand -------------------------- ----//
  const { organizationId } = useOrganizationId((state) => state);
  const { divisionName } = useDivisionName((state) => state);
  const { divisionId } = useDivisionId((state) => state);
  const { companyId } = useEditCompany((state) => state);
  const { data: GetDivisionByOrganisationId } = useGetDivisionByOrganisationId(
    organizationId,
    "basic"
  );

  const { data: GetCompanyByLevelData } = useGetCompanyByLevel(
    "d",
    divisionId,
    "basic"
  );

  useEffect(() => {
    setValue("division_id", {
      label: divisionName,
      value: divisionId,
    });
  }, [divisionId, divisionName]);
  useEffect(() => {
    if(companyId){

      const companyData = GetCompanyByLevelData?.data?.filter((data)=> data?.Id?.toString() === companyId?.toString())?.[0]
      setValue("company_id", {
        label: companyData?.Text,
        value: companyData?.Id,
      });
    }else{

      if (GetCompanyByLevelData?.data?.length == 1) {
        setValue("company_id", {
          label: GetCompanyByLevelData?.data?.[0]?.Text,
          value: GetCompanyByLevelData?.data?.[0]?.Id,
        });
      }
    }
  }, [GetCompanyByLevelData]);
  // handle next
  const handleNextButton = (data) => {
    setFormData({
      ...formData,
      ...data,
    });
    handleNext(data);
  };

  // handle back
  const handleCloseButton = () => {
    handleClose();
  };

  return (
    <Form onSubmit={handleSubmit(handleNextButton)}>
      <div className="scrollable-container-y" style={{ height: "450px" }}>
        <div className="p-4">
          <Row>
            <Col>
              <H4 className="mb-3">Division</H4>
              <div className="light-font-text-16 mb-3">
                {`Select which division to create the ${flag.toLowerCase()} under. The choice
                  of division also determines which companies you can select
                  between.`}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              {/* <ReactSelect placeholder="Division" /> */}
              <Controller
                control={control}
                {...register("division_id")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <ReactSelect
                      placeholder={"Division"}
                      iserror={errors?.division_id}
                      options={GetDivisionByOrganisationId?.data?.map(
                        (item) => ({
                          value: item?.Id,
                          label: item?.Text,
                        })
                      )}
                      disabled
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        setValue("company_id", "");
                        clearErrors("division_id");
                      }}
                      clearable={false}
                    />
                  );
                }}
              />
            </Col>
          </Row>
        </div>

        {flag == "property" && (
          <div className="p-4 border-top">
            <Row>
              <Col lg={8}>
                <H4 className="mb-3">Company</H4>
                <div className="light-font-text-16 mb-3">
                  Select the company under which the property is to be created.
                  If the right company is not on the list, it may have been
                  created under a different division or the company needs to be
                  created first.
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Controller
                  control={control}
                  {...register("company_id")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <ReactSelect
                        placeholder={"Company"}
                        disabled={companyId && true}
                        iserror={errors?.company_id}
                        options={GetCompanyByLevelData?.data?.map((item) => ({
                          value: item?.Id,
                          label: item?.Text,
                          isDisabled: value?.value?.toString() === item?.Id?.toString(),
                        }))}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("company_id");
                        }}
                        clearable={false}
                      />
                    );
                  }}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>

      <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
        <ETButton
          variant={"white"}
          buttonText="CANCEL"
          onClick={handleCloseButton}
        />
        <ETButton variant={"primary"} buttonText="NEXT" type={"submit"} />
      </div>
    </Form>
  );
};

export default SelectDivision;
