import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import FilterComponent from "../../../reactdataTableComponent/FilterComponent";
import ETButton from "../../../Components/Buttons/ETButton";
import { H2, StyledCard } from "../../../styles/Common.style";
import ScreeningFormsCard from "../../../Components/card/ScreeningFormsCard";
import { useNavigate } from "react-router-dom";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { Tab, TabList, Tabs } from "../../../Components/Tabs/ReactTabs.style";
import ChangeForm from "./screeningpopups/ChangeForm";
import ArchivedForm from "./screeningpopups/ArchivedForm";
import PublishForm from "./screeningpopups/PublishForm";
import ExistingScreeningTemplate from "./screeningpopups/ExistingScreeningTemplate";
import {
	useGetScreening,
	useGetScreeningByPropertyId,
	useGetScreeningLinkedUnit,
	useUpdateScreeningActorStaus,
	useUpdateScreeningFormStatus,
} from "../../../hooks/queries/useScreeningQueries";
import { usePropertyId } from "../../../hooks/stores/usePropertyStore";
import { toast } from "react-toastify";
import { status } from "../../../model/Constant";
import { useScreening } from "../../../hooks/stores/useScreeningStore";
import CancelScreeningProcess from "./screeningpopups/CancelScreeningProcess";

const ScreeningForms = () => {
	const [changeForm, setChangeForm] = useState(false);
	const [archive, setArchive] = useState(false);
	const [publish, setPublish] = useState(false);
	const [existingScreeningTemplate, setExistingScreeningTemplate] =
		useState(false);
	const [screening, setScreening] = useState(1);
	const [ShowOnPrescreening, setShowOnPrescreening] = useState(1);
	const [formStatusId, setFormStatusId] = useState("");
	const [draftDataFilter, setDraftDataFilter] = useState();
	const [activeTab, setActiveTab] = useState("All");
	const [LinkedUnitData, setLinkedUnitData] = useState([]);
	const [searchBySourceName, setSearchBySourceName] = useState('')
	// console.log("🚀 ~ file: ScreeningForms.js:40 ~ ScreeningForms ~ searchBySourceName:", searchBySourceName)

	const { propertyId } = usePropertyId((state) => state);
	const [cancelActiveScreening, setCancelActiveScreening] = useState(false);
	const {
		data: GetScreeningByPropertyIdData,
		refetch: GetScreeningByPropertyIdRefetch,
	} = useGetScreeningByPropertyId(propertyId);

	const { screeningFormId, setScreeningFormId } = useScreening(
		(state) => state
	);
	const {
		data: getScreeningLinkedUnitData,
		refetch: getScreeningLinkedUnitDataDataRefetch,
	} = useGetScreeningLinkedUnit(screeningFormId, propertyId);
	useEffect(() => {
		setLinkedUnitData(getScreeningLinkedUnitData?.data);
	}, [getScreeningLinkedUnitData]);
	const { data: screeningData, refetch: getScreeningRefetch } =
		useGetScreening(screeningFormId);
	let screeningFormCardData = GetScreeningByPropertyIdData?.data;
	// console.log("🚀 ~ file: ScreeningForms.js:61 ~ ScreeningForms ~ screeningFormCardData:", screeningFormCardData)
	const { mutateAsync: updateFormStatusMutate } =
		useUpdateScreeningFormStatus();

	const {
		mutateAsync: updateScreeningActorStausMutate,
		isLoading: isUpdateScreeningActorStausLoading,
	} = useUpdateScreeningActorStaus();

	const handleCreateNewScreeningForms = () => {
		setExistingScreeningTemplate(true);
	};

	const handleStatusChange = async () => {
		const statusChangePaylaod = {
			screening_form_id: screeningFormId,
			form_status_id: formStatusId,
		};

		try {
			const response = await updateFormStatusMutate(statusChangePaylaod);
			if (response.status === status.s && response?.data?.bReturn) {
				toast.success(response?.data?.message);
				await getScreeningRefetch();
				if (archive) {
					setArchive(false);
				} else if (publish) {
					setPublish(false);
				} else if (cancelActiveScreening) {
					setCancelActiveScreening(false);
				}
			} else {
				toast.error(response?.data?.message);
			}
		} catch (error) { }
	};

	const handleCancelScreening = async (actorScreeningId, screeningStatusId) => {
		const updateScreeningActorStatusPayload = {
			actor_screening_id: actorScreeningId,
			screening_form_id: screeningFormId,
			screening_status_id: screeningStatusId,
		};
		try {
			const response = await updateScreeningActorStausMutate(
				updateScreeningActorStatusPayload
			);
			if (response?.status === status.s && response?.data?.bReturn) {
				toast.success(response?.data?.message, { toastId: actorScreeningId });
				getScreeningRefetch();
				GetScreeningByPropertyIdRefetch();
			} else {
				toast.error(response?.data?.message);
			}
		} catch (error) {
			// console.error(error);
		}
	};

	const handleCanelAllAndArchive = async (data) => {
		await data?.forEach((element) => {
			handleCancelScreening(element?.actor_screening_id, 4);
		});
		await setFormStatusId(3);
		await handleStatusChange();
		await getScreeningRefetch();
		await GetScreeningByPropertyIdRefetch();
	};
	const [filterDataForTab, setFilteredDataForTab] = useState([])

	// const filterData = (tab) => {
	// 	if (tab === "All") {
	// 		return GetScreeningByPropertyIdData?.data;
	// 	} else {
	// 		return GetScreeningByPropertyIdData?.data?.filter(
	// 			(item) => item.form_status_name == tab
	// 		);
	// 	}
	// };

	// useEffect(() => {
	// 	setFilteredDataForTab(filterData(activeTab))
	// }, [filterDataForTab])

	// const filteredData = filterData(activeTab);
	const handleTabChange = (tab) => {
		setActiveTab(tab);
		// if (activeTab == 'All') {
		// 	setFilteredDataForTab(GetScreeningByPropertyIdData?.data)
		// }
		// console.log(activeTab, "activetab")
	};
	useEffect(() => {
		if (activeTab == 'All') {
			setFilteredDataForTab(GetScreeningByPropertyIdData?.data)
		} else {
			const allTabs = GetScreeningByPropertyIdData?.data?.filter(
				(item) => item.form_status_name == activeTab
			);
			setFilteredDataForTab(allTabs)
		}
	}, [GetScreeningByPropertyIdData, activeTab])


	const handleCardSearch = (e) => {
		setSearchBySourceName(e.target.value);

		if (!e.target.value.trim()) {
			setFilteredDataForTab(GetScreeningByPropertyIdData?.data);
		} else {
			const filteredProducts = GetScreeningByPropertyIdData?.data?.filter((item) => {
				const sourceMatches = item?.source?.toLowerCase()?.includes(searchBySourceName?.toLowerCase());
				const nameMatches = item?.screening_name?.toLowerCase().includes(searchBySourceName?.toLowerCase());

				return sourceMatches || nameMatches;
			});

			setFilteredDataForTab(filteredProducts);
		}
	};


	return (
		<div className="radius-8 bg-white ">
			<div className="form-label-title  py-3 px-4 d-flex align-items-center gap-2 border-bottom">
				<H2>Screening</H2> <CustomToolTip text="Screening" />
			</div>

			<div
				className="d-flex px-4 py-3 mb-3 justify-content-between align-items-center"
				style={{
					width: "100%",
					flexDirection: "row",
					flexWrap: "wrap",
					borderBottom: "1px solid #F0F0F0",
				}}
			>
				<div>
					<Tabs className="d-flex flex-column ">
						<TabList className="d-flex light ps-0 light" style={{ gap: "7px" }}>
							<Tab
								className={`Tabsbtn ${activeTab === "All" ? "active" : ""}`}
								onClick={() => handleTabChange("All")}
							>
								All
							</Tab>
							<Tab
								className={`Tabsbtn ${activeTab === "Published" ? "active" : ""
									}`}
								onClick={() => handleTabChange("Published")}
							>
								Published
							</Tab>
							<Tab
								className={`Tabsbtn ${activeTab === "Draft" ? "active" : ""}`}
								onClick={() => handleTabChange("Draft")}
							>
								Drafts
							</Tab>
							<Tab
								className={`Tabsbtn ${activeTab === "Archived" ? "active" : ""
									}`}
								onClick={() => handleTabChange("Archived")}
							>
								Archived
							</Tab>
						</TabList>
					</Tabs>
				</div>

				<div>
					<ETButton
						variant={"primary"}
						buttonText={"Add new form"}
						icon={true}
						width={"100%"}
						onClick={handleCreateNewScreeningForms}
					/>
				</div>
			</div>

			<div className="py-2 px-4">
				<div>
					<Row className="d-flex justify-content-end">
						<Col xl={4} md={8} className="ps-xl-0 pb-2">
							<div style={{ width: "80%", marginLeft: "auto" }}>
								<FilterComponent value={searchBySourceName}
									handleSearch={handleCardSearch} />
								{/* <input
									className=""
									type="text"
									placeholder="Search..."
									value={searchBySoueceName}
									onChange={handleCardSearch}
								></input> */}
							</div>
						</Col>
					</Row>
				</div>

				<div className="py-4">
					<Row>
						{filterDataForTab?.map((data) => (
							<Col key={data?.id} xl={4} className="mb-4">
								<ScreeningFormsCard
									setArchive={setArchive}
									setChangeForm={setChangeForm}
									setPublish={setPublish}
									LinkedUnitData={LinkedUnitData}
									setLinkedUnitData={setLinkedUnitData}
									// setArchiveActiveScreening={setArchiveActiveScreening}
									setCancelActiveScreening={setCancelActiveScreening}
									data={data}
									setScreeningFormId={setScreeningFormId}
									setFormStatusId={setFormStatusId}
								/>
							</Col>
						))}

						<Col xl={4} className="mb-4">
							<StyledCard height="470px">
								<ETButton
									variant={"primary"}
									buttonText="Add new form"
									icon={true}
									width="fit-content"
									onClick={handleCreateNewScreeningForms}
								/>
							</StyledCard>
							{/* <div className="radius-8 d-flex align-items-center justify-content-center create-signing-package p-3"> */}

							{/* </div> */}
						</Col>
					</Row>
				</div>
			</div>
			<ChangeForm setChangeForm={setChangeForm} changeForm={changeForm} />
			<ArchivedForm
				setArchive={setArchive}
				archive={archive}
				handleStatusChange={handleStatusChange}
			// setCancelActiveScreening={setCancelActiveScreening}
			// cancelActiveScreening={cancelActiveScreening}
			/>
			{/* <CancelScreeningProcess /> */}
			<PublishForm
				setPublish={setPublish}
				publish={publish}
				handleStatusChange={handleStatusChange}
			/>

			<ExistingScreeningTemplate
				existingScreeningTemplate={existingScreeningTemplate}
				setExistingScreeningTemplate={setExistingScreeningTemplate}
				setScreening={setScreening}
				screening={screening}
				ShowOnPrescreening={ShowOnPrescreening}
				setShowOnPrescreening={setShowOnPrescreening}
			/>
			<CancelScreeningProcess
				setCancelActiveScreening={setCancelActiveScreening}
				cancelActiveScreening={cancelActiveScreening}
				handleCancelScreening={handleCancelScreening}
				handleCanelAllAndArchive={handleCanelAllAndArchive}
				setFormStatusId={setFormStatusId}
				handleStatusChange={handleStatusChange}
			// setScreeningStatusId={setScreeningStatusId}

			// setActorScreeningId={setActorScreeningId}
			/>
		</div>
	);
};

export default ScreeningForms;
