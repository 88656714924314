import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import CustomPopup from "../../../Components/CustomPopup";
import ETButton from "../../../Components/Buttons/ETButton";
import "./promotionlabel.css";
import {H5, addConfigButton } from "../../../styles/Common.style";
import {
  useGetMainStatus,
  useGetPromotionLabelConfigurationByPropertyId,
  useGetSubStatus,
  usePostAddPromotionLabelConfiguration,
} from "../../../hooks/queries/usePromotionLabelQueries";
import { status } from "../../../model/Constant";
import { toast } from "react-toastify";
import { usePropertyId } from "../../../hooks/stores/usePropertyStore";
import { Delete_PromotionLabelConfiguration } from "../../../services/PromotionLabel";

const Configuration = ({
  getPromotionLabelByPropertyIdData,
  handleAddPromotionLabel,
}) => {
  //--------------zustand----------//
  const propertyId = usePropertyId((state) => state.propertyId);
  //------------------------states------------------------------//
  const [deletePromoConfig, setDeletePromoConfig] = useState(false);
  const [indexDeletePropConfig, setIndexDeletePropConfig] = useState("");
  const [promotionLabelConfigId, setPromotionLabelConfigId] = useState({
    mainstatus_id: "",
    substatus_id: "",
    promotion_label_id: "",
  });
  const [newPromotionLabelId, setNewPromotionLabelId] = useState("");
  const [promotionLabelConfigurationData, setPromotionLabelConfigurationData] =
    useState([]);
  const [defaultConfigurationArray, setDefaultConfigurationArray] = useState([
    {
      mainStatus: "",
      subStatus: "",
      promotionLabel: "",
    },
  ]);

  //-----------------------react query------------------------//
  const {
    data: getPromotionLabelConfigurationData,
    refetch: getPromotionLabelConfigurationDataRefetch,
  } = useGetPromotionLabelConfigurationByPropertyId(propertyId);
  const { data: getMainStatusData } = useGetMainStatus(1); //Unit type will always be 1 for promotion label
  const { data: getSubStatusData } = useGetSubStatus(0); //Main status Id will be 0 to get all sub statuses
  const { mutateAsync: PostAddPromotionLabelConfiguration, isLoading } =
    usePostAddPromotionLabelConfiguration();

  const {
    register,
    getValues,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      addStep: [
        {
          mainStatus: "",
          subStatus: "",
          promotionLabel: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "addStep",
  });

  useEffect(() => {
    const stepsData = getPromotionLabelConfigurationData?.data?.map(
      (item, i) => ({
        mainStatus: {
          value: item?.mainstatus_id,
          label: getMainStatusData?.data?.filter(
            (el) => el?.mainstatus_id === item?.mainstatus_id
          )?.[0]?.name,
        },
        subStatus: {
          value: item?.substatus_id,
          label: getSubStatusData?.data?.filter(
            (el) => el?.substatus_id === item?.substatus_id
          )?.[0]?.name,
        },
        promotionLabel: displayItem(String(item?.promotion_label_id)),
      })
    );

    reset({ addStep: stepsData }, [reset]);
    return () => {};
  }, [getPromotionLabelConfigurationData, getMainStatusData, getSubStatusData]);

  // useEffect(() => {
  //   reset(addStep);

  //   return () => {};
  // }, [addStep]);

  const handleAddStepButton = () => {
    append({
      mainStatus: "",
      subStatus: "",
      promotionLabel: "",
    });
  };

  //------------handle delete promotion label configuration-----------//
  const handleDeletePromotionLabelConfiguration = async () => {
    if (
      promotionLabelConfigId?.mainstatus_id === undefined &&
      promotionLabelConfigId?.substatus_id === undefined &&
      promotionLabelConfigId?.promotion_label_id === undefined
    ) {
      remove(indexDeletePropConfig);
      setDeletePromoConfig(false);
    } else {
      try {
        const response = await Delete_PromotionLabelConfiguration(
          promotionLabelConfigId?.mainstatus_id,
          promotionLabelConfigId?.substatus_id,
          promotionLabelConfigId?.promotion_label_id
        );
        if (response?.data?.bReturn) {
          toast.success(response.data.message);
          remove(indexDeletePropConfig);
          setDeletePromoConfig(false);
          getPromotionLabelConfigurationDataRefetch();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  //------------handle save promotion label configuration-----------//
  const handleSaveConfiguration = async (data) => {
    const addConfigurationPayload = {
      property_id: String(propertyId),
      configuration_details: data?.addStep?.map((item) => ({
        promotion_label_id: String(item?.promotionLabel?.value),
        substatus_id: String(item?.subStatus?.value),
        mainstatus_id: String(item?.mainStatus?.value),
        property_id: String(propertyId),
      })),
    };

    try {
      const response = await PostAddPromotionLabelConfiguration(
        addConfigurationPayload
      );
      if (response.data.bReturn && response.status === status.s) {
        for (let i = 0; i < response?.data?.data?.length; i++) {
          if (response?.data?.data?.[i]?.status === 1) {
            toast.success(response?.data?.data?.[i]?.message, {
              toastId: response?.data?.data?.[i]?.property_id,
            });
          } else {
            toast.error(response?.data?.data?.[i]?.message);
          }
        }
        getPromotionLabelConfigurationDataRefetch();
      } else {
        toast.error(response?.data?.message);
        // toast.update(id, {
        //   render: response?.data?.message,
        //   type: status.e,
        //   isLoading: false,
        //   autoClose: 2000,
        // });
      }
    } catch (error) {
      toast.error(error.message);
      // toast.update(id, {
      //   render: error?.message,
      //   type: status.e,
      //   isLoading: false,
      //   autoClose: 2000,
      // });
    }
  };

  const displayItem = (selected) => {
    return {
      value: selected,
      label: (
        <>
          {getPromotionLabelByPropertyIdData?.data
            .filter(
              (item) =>
                String(item.promotion_label_id) === String(selected?.value)
            )
            .map((data) => (
              <div
                className="d-flex align-items-center gap-2"
                style={{ marginTop: "2px" }}
              >
                <div
                  style={{
                    background: `linear-gradient(135deg, ${data?.background_color} 50%, ${data?.font_color} 50%)`,
                  }}
                  className="config-color-sec px-2"
                ></div>
                <div>
                  <div className="light-font-text">
                    {data?.internal_label !== "" ? data?.internal_label : "-"}
                  </div>
                </div>
              </div>
            ))}
        </>
      ),
    };
  };
  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "400px",
    overflowX: "scroll",
  };
  const FormatGroupLabel = () => {
    return (
      <div style={groupStyles}>
        <tr className="w-100" style={{ textTransform: "capitalize" }}>
          <th style={{ width: "60px" }}>
            <div className="config-table-heading px-2">Color</div>{" "}
          </th>
          <th style={{ width: "190px" }}>
            <div className="config-table-heading ">Internal label </div>
          </th>
          {/* <th style={{ width: "180px" }}>
            <div className="config-table-heading ">Promotion label</div>
          </th> */}
          {/* <th style={{ width: "120px" }}>
            <span className="config-table-heading ">Price text label</span>
          </th> */}
          <th style={{ width: "120px" }}>
            <span className="config-table-heading ">Price visible</span>
          </th>
        </tr>
      </div>
    );
  };
  const PromotionLabelList = ({ data, index }) => {
    return (
      <div className="" style={{ width: "400px", overflowX: "scroll" }}>
        <tr className="cursor-pointer w-100">
          <td className="config-table-data" style={{ width: "60px" }}>
            <div
              style={{
                width: "17px",
                background: `linear-gradient(135deg, ${data?.background_color} 50%, ${data?.font_color} 50%)`,
              }}
              className="config-color-sec"
            ></div>
          </td>
          <td className="config-table-data" style={{ width: "180px" }}>
            <span className="light-font-text ">
              {data?.internal_label !== "" ? data?.internal_label : "-"}
            </span>
          </td>
          {/* <td className="config-table-data" style={{ width: "180px" }}>
            <span className="light-font-text  ">
              {data?.external_label !== "" ? data?.external_label : "-"}
            </span>
          </td>
          <td className="config-table-data" style={{ width: "120px" }}>
            <span className="light-font-text ">
              {data?.price_label ? data?.price_label : "-"}
            </span>
          </td> */}
          <td className="config-table-data" style={{ width: "120px" }}>
            <span className="light-font-text  ">
              {data?.is_price_visible == "1" ? "Yes" : "No"}
            </span>
          </td>
        </tr>
      </div>
    );
  };
  const groupedOptions = [
    {
      label: <FormatGroupLabel />,
      options: getPromotionLabelByPropertyIdData?.data.map((data, index) => ({
        value: data?.promotion_label_id,
        label: <PromotionLabelList data={data} index={index} />,
        isDisabled: watch("addStep")?.some(
          (val) => data?.promotion_label_id == val?.promotionLabel?.value
        ),
      })),
    },
  ];

  return (
    <>
      <Form onSubmit={handleSubmit(handleSaveConfiguration)}>
        <div className="py-3 px-4">
          <Row>
            <Col className="pe-lg-0" lg={11}>
              <div
                className="light-font-text"
                style={{
                  textAlign: "justify",
                }}
              >
                A promotion label is the status label that a unit will be
                promoted with on a website, house selector, my page etc. if
                estatetools APIs are used. A promotion label is dependent on the
                two system statuses; Main status and Sub status. It is possible
                to create individual setups per property and it is also possible
                to manually change a promotion status on a device.
              </div>
            </Col>
          </Row>
          <Row className="mt-4 ">
            <Col sm={11}>
              <Row>
                <Col className="ps-lg-5 ps-sm-4" lg={3} sm={4}>
                  <div className="d-flex align-items-center">
                    <H5 >
                      {" "}
                      Main status{" "}
                    </H5>

                    <div className="ms-2">
                      {" "}
                      <CustomToolTip size="sm" text="Main status" />{" "}
                    </div>
                  </div>
                </Col>
                <Col
                  lg={5}
                  sm={4}
                  className="ps-lg-5 ps-sm-3 d-flex justify-content-sm-center justify-content-lg-start"
                >
                  <div className="d-flex align-items-center">
                    <H5 >
                      {" "}
                      Sub Status{" "}
                    </H5>

                    <div className="ms-2">
                      {" "}
                      <CustomToolTip size="sm" text="Sub status" />{" "}
                    </div>
                  </div>
                </Col>
                <Col
                  className="ps-lg-5 ps-sm-4 d-flex justify-content-lg-center"
                  sm={4}
                >
                  <div className="d-flex align-items-center">
                    <H5 >
                      {" "}
                      Promotion label{" "}
                    </H5>

                    <div className="ms-2">
                      {" "}
                      <CustomToolTip size="sm" text="Promotion label" />{" "}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {fields?.map((item, index) => (
            <Row key={item.id} className="mt-2">
              <Col sm={11} className="ps-4 pe-3">
                <Row className="radius-8" style={{ background: "#f9fafa" }}>
                  <Col lg={3} md={6} className=" ps-lg-3 pe-lg-0 py-3">
                    <Controller
                      {...register(`addStep.${index}.mainStatus`)}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <ReactSelect
                          value={field.value}
                          placeholder={"Main status"}
                          options={getMainStatusData?.data?.map((item) => ({
                            value: item?.mainstatus_id,
                            label: item?.name,
                          }))}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            setValue(`addStep.${index}.subStatus`, "");
                          }}
                        />
                      )}
                    />
                    {errors?.addStep?.[index]?.mainStatus && (
                      <p className="error-msg">This field is required</p>
                    )}
                  </Col>
                  <Col className="py-3 ps-sm-0 ps-lg-3  pe-lg-0" lg={3} md={6}>
                    <Controller
                      {...register(`addStep.${index}.subStatus`)}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <ReactSelect
                          placeholder={"Sub status"}
                          value={field.value}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          options={getSubStatusData?.data
                            ?.filter(
                              (item) =>
                                item?.mainstatus_id ===
                                watch(`addStep.${index}.mainStatus`)?.value
                            )
                            ?.map((item) => ({
                              value: item?.substatus_id,
                              label: item?.name,
                              isDisabled: watch("addStep")?.some(
                                (val) =>
                                  item?.substatus_id == val?.subStatus?.value
                              ),
                            }))}
                          noOptionsMessage={"Please select main status"}
                        />
                      )}
                    />
                    {errors?.addStep?.[index]?.subStatus && (
                      <p className="error-msg">This field is required</p>
                    )}
                  </Col>

                  <Col className="" lg={6} style={{ position: "relative" }}>
                    <Row>
                      <Col sm={4}>
                        <div
                          style={{
                            position: "absolute",
                            top: "27px",
                            left: "40px",
                          }}
                          className="light-font-text fs-12"
                        >
                          Will be displayed as :
                        </div>
                      </Col>
                      <Col
                        className="px-0"
                        sm={2}
                        style={{ background: "#d1d1d1" }}
                      >
                        {" "}
                        <div className="triangle"> </div>{" "}
                      </Col>
                      <Col
                        className="ps-lg-0 py-3"
                        sm={6}
                        style={{
                          background: "#d1d1d1",
                          borderRadius: "0px 8px 8px 0px",
                        }}
                      >
                        <Controller
                          {...register(`addStep.${index}.promotionLabel`)}
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <ReactSelect
                              placeholder={"Promotion label"}
                              value={displayItem(field.value)}
                              innerLabel={true}
                              // setPlaceholderPosition={true}
                              // height={55}
                              clearable={false}
                              searchable={false}
                              options={groupedOptions}
                              onChange={(e) => {
                                // setNewPromotionLabelId(e.value);
                                field.onChange(e);
                              }}
                              // formatGroupLabel={formatGroupLabel}
                              noOptionsMessage={"No promotion label found"}
                            />
                          )}
                        />
                        {errors?.addStep?.[index]?.promotionLabel && (
                          <p className="error-msg">This field is required</p>
                        )}
                      </Col>{" "}
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col
                sm={1}
                className=" ps-lg-0 d-flex justify-content-center align-items-center"
              >
                {" "}
                <button
                  type="button"
                  onClick={() => {
                    setDeletePromoConfig(true);
                    setIndexDeletePropConfig(index);
                    setPromotionLabelConfigId({
                      ...promotionLabelConfigId,
                      mainstatus_id: item?.mainStatus?.value,
                      substatus_id: item?.subStatus?.value,
                      promotion_label_id: item?.promotionLabel?.value,
                    });
                  }}
                  className="icon-btn"
                >
                  <FontAwesomeIcon icon="fa-regular fa-trash" />
                </button>
              </Col>
            </Row>
          ))}
          <Row className="mt-3">
            <Col className="pe-0" sm={11}>

              <div onClick={handleAddStepButton} className="add-config-btn">
                <FontAwesomeIcon
                  size="xs"
                  icon="fa-solid fa-plus"
                  style={{ color: "#F1664B" }}
                />{" "}
                <span className="form-label-title fs-12 ms-2">
                  {" "}
                  ADD NEW CONFIGURATION
                </span>
              </div>
              
            </Col>
          </Row>

          <CustomPopup
            deletePopUpStyle={true}
            style={{ position: "relative" }}
            size="md"
            open={deletePromoConfig}
            onHide={() => setDeletePromoConfig(false)}
            title="Are you carrying out the trash ?"
            title_color="black"
            enableCrossButton={false}
            onCloseAction={() => {
              setDeletePromoConfig(false);
            }}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={true}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
          >
            <div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: "14px",
                  fontFamily: "Cerebri Sans",
                  lineHeight: "17.5px",
                }}
                className="ms-3 ps-2 py-4 fw-300"
              >
                The Configuration will be deleted and cannot be undone. We know,
                it’s not always funny to be the bad guy doing the dirty work.
              </div>

              <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
                {" "}
                <ETButton
                  variant={"white"}
                  buttonText="COLD FEET"
                  onClick={() => {
                    setDeletePromoConfig(false);
                  }}
                />
                <ETButton
                  variant={"primary"}
                  buttonText="I'M SURE"
                  onClick={() => {
                    handleDeletePromotionLabelConfiguration();
                  }}
                />
              </div>
            </div>
          </CustomPopup>
        </div>
        {fields.length > 0 && (
          <div
            className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-end  justify-content-end "
            style={{
              backgroundColor: "#F9FAFA",
              borderRadius: " 0px 0px 8px 8px",
            }}
          >
            <ETButton
              variant={"primary"}
              buttonText="SAVE"
              type="submit"
              disabled={isLoading}
            />
          </div>
        )}
      </Form>
    </>
  );
};

export default Configuration;
