import React, { useEffect, useState } from "react";
import GroupButtons from "../../../../Components/GroupButton/GroupButton";
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useAppState } from "../AllState";
import { H4 } from "../../../../styles/Common.style";

const ConditionPremises = ({
  submitRef,
  handleNext,
  prevRef,
  activeTenancyForm,
  handleEditConfig,
  getConfigurationDetailsData,
  previewRef,
}) => {
  const [premisesToggleOne, setPremisesToggleOne] = useState(0); //landlord rent out more than one residential apartment
  const [premisesToggleTwo, setPremisesToggleTwo] = useState(0); //condition of the tenancy be ascertained during a move-in inspection
  const [premisesToggleThree, setPremisesToggleThree] = useState(0); //condition of the tenancy ascertained during a vacancy inspection
  const [state, setState] = useAppState();
  const {
    handleSubmit,
    register,
    watch,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: state[activeTenancyForm] });

  const saveData = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 7, true);
    // handleNext(1);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  const preview = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 7, false);
    // handleNext(1);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  // saving all field into context state
  // const prevSubmit = () => {
  //   // setState({ ...state, ...getValues() });
  //   setState({ ...state, [activeTenancyForm]: { ...getValues() } });
  // };

  useEffect(() => {
    setValue(
      "l_rent_more",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "l_rent_more"
      )[0]?.actual_value == 2
        ? 1
        : 0
    );
    setValue(
      "t_move_in",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "t_move_in"
      )[0]?.actual_value == 2
        ? 1
        : 0
    );
    setValue(
      "t_vacancy",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "t_vacancy"
      )[0]?.actual_value == 2
        ? 1
        : 0
    );
  }, [getConfigurationDetailsData]);
  return (
    <>
      <Form className="bg-white radius-8">
        <div
          className="py-3 px-4"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <H4 className="actors-section-title">
            §7 The condition of the premises when moving in and out
          </H4>
          <div className="light-font-text grey-text">
            Lorem ipsum dolor sit lorem ipsum dolor sit
          </div>
        </div>
        <div className="light-font-text p-4">
          <div>
            If the landlord wants to be able to demand repairs when the tenant
            moves out, it is the responsibility of the landlord who rents more
            than one residential apartment to organize a move-in inspection and
            the landlord who rents more than one residential apartment at the
            time of vacating to organize a move-out inspection.
          </div>
          <div className="me-2 pb-2 pt-3">
            Does the landlord rent out more than one residential apartment?
          </div>
          <div className="w-25">
            <Controller
              control={control}
              {...register("l_rent_more")}
              render={({ field: { onChange, value } }) => {
                return (
                  <GroupButtons
                    tabs={[
                      {
                        id: 1,
                        tabTitle: "Yes",
                        title: "Yes",
                        content:
                          "This page contains data related to Map milestone",
                      },
                      {
                        id: 2,
                        tabTitle: "No",
                        title: "No",
                        content:
                          "This page contains data related to Chart milestone",
                      },
                    ]}
                    currentTab={value}
                    handleTabClick={(tab) => {
                      setValue("l_rent_more", tab);
                    }}
                  />
                );
              }}
            />
          </div>

          <div className="me-2 pb-2 pt-3">
            Is/will the condition of the tenancy be ascertained during a move-in
            inspection?
          </div>
          <div className="w-25">
            <Controller
              control={control}
              {...register("t_move_in")}
              render={({ field: { onChange, value } }) => {
                return (
                  <GroupButtons
                    tabs={[
                      {
                        id: 1,
                        tabTitle: "Yes",
                        title: "Yes",
                        content:
                          "This page contains data related to Map milestone",
                      },
                      {
                        id: 2,
                        tabTitle: "No",
                        title: "No",
                        content:
                          "This page contains data related to Chart milestone",
                      },
                    ]}
                    currentTab={value}
                    handleTabClick={(tab) => {
                      setValue("t_move_in", tab);
                    }}
                  />
                );
              }}
            />
          </div>

          <div className="fs-14 fw-300 me-2 pb-2 pt-3">
            Is the condition of the tenancy ascertained during a vacancy
            inspection?
          </div>
          <div className="w-25">
            <Controller
              control={control}
              {...register("t_vacancy")}
              render={({ field: { onChange, value } }) => {
                return (
                  <GroupButtons
                    tabs={[
                      {
                        id: 1,
                        tabTitle: "Yes",
                        title: "Yes",
                        content:
                          "This page contains data related to Map milestone",
                      },
                      {
                        id: 2,
                        tabTitle: "No",
                        title: "No",
                        content:
                          "This page contains data related to Chart milestone",
                      },
                    ]}
                    currentTab={value}
                    handleTabClick={(tab) => {
                      setValue("t_vacancy", tab);
                    }}
                  />
                );
              }}
            />
          </div>
        </div>
        <button
          type="submit"
          onClick={handleSubmit(saveData)}
          ref={submitRef}
          style={{ display: "none" }}
        />
        <button
          type="submit"
          onClick={handleSubmit(preview)}
          ref={previewRef}
          style={{ display: "none" }}
        />
      </Form>
      {/* <button ref={prevRef} onClick={prevSubmit} style={{ display: "none" }} /> */}
    </>
  );
};

export default ConditionPremises;
