import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { H4 } from "../../../../styles/Common.style";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import PasswordField from "../../../../Components/GroupText/PasswordField";
import ETButton from "../../../../Components/Buttons/ETButton";
import { Controller, useForm } from "react-hook-form";
import InputField from "../../../../Components/Textfield/InputField";
import {
  useGetGeographyCountry,
  useGetStreetDetails,
  useGetZipcodeByCountry,
} from "../../../../hooks/queries/useGeographyQueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetlanguages } from "../../../../hooks/queries/uselanguageQueries";

const LeadCustomerInformation = ({
  handleNext,
  handleCancel,
  customerInformationData,
}) => {
  //-------------------- states  -------------------------//
  const [isSearchStreetVisible, setIsSearchStreetVisible] = useState(false);
  const [searchedStreetName, setSearchedStreetName] = useState("");
  const [streetname, setStreetname] = useState("");
  const [filteredStreetname, setFilteredStreetname] = useState([]);

  //-----------------   react hooks forms ---------------------//
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { isFavouriteCustomer: 0, social_security_number: "" },
  });

  //-----------------------------react query------------------------------//
  const { data: countryData } = useGetGeographyCountry();
  const { data: getStreetDetailsData } =
    useGetStreetDetails(searchedStreetName);
  const { data: zipCodeByCountryIdData } = useGetZipcodeByCountry(
    watch("country")?.value
  );

  // for langage selection
  const { data: getlanguages } = useGetlanguages();

  useEffect(() => {
    if (customerInformationData?.length !== 0) {
      setValue("firstname", customerInformationData?.firstname);
      setValue("lastname", customerInformationData?.lastname);
      setValue("email", customerInformationData?.email);
      setValue("countryCode", {
        value: customerInformationData?.countryCode?.value,
        label: customerInformationData?.countryCode?.label,
      });
      setValue("mobilenumber", customerInformationData?.mobilenumber);
      setValue(
        "social_security_number",
        customerInformationData?.social_security_number
      );
      setValue("birthdate", customerInformationData?.birthdate);
      setValue("street_name", customerInformationData?.street_name);
      setValue("house_number", customerInformationData?.house_number);
      setValue("floor_no", customerInformationData?.floor_no);
      setValue("side", customerInformationData?.side);
      setValue("door", customerInformationData?.door);
      setValue("country", {
        value: customerInformationData?.country?.value,
        label: customerInformationData?.country?.label,
      });
      setValue("zipCode", {
        value: customerInformationData?.zipCode?.value,
        label: customerInformationData?.zipCode?.label,
      });
      setValue("city", customerInformationData?.city);
      setValue(
        "isFavouriteCustomer",
        customerInformationData?.isFavouriteCustomer
      );
    } else {
      reset();
    }
    return () => {};
  }, [customerInformationData]);

  //------------------------------ displayItem used for showing selected zip code  -----------------------------//
  const displayItem = (selected) => {
    return selected
      ? {
          value: selected?.Id,
          label: countryData?.data?.filter(
            (item, i) => item.Id === selected?.value
          )?.[0]?.Text,
        }
      : "";
  };
  const displayLanguageItem = (selected) => {
    return selected
      ? {
          value: selected?.Id,
          label: getlanguages?.data?.filter(
            (item, i) => item.Id === selected?.value
          )?.[0]?.Text,
        }
      : "";
  };

  const handleSubmitForm = (data) => {
    handleNext(data);
  };

  const handleStreetDetailChange = (event) => {
    setStreetname(event.target.value);
    const countryID = watch("country.value");
    if (countryID == "DK" && streetname?.length >= 4) {
      setIsSearchStreetVisible(true);
      setSearchedStreetName(event.target.value);
    } else {
      setIsSearchStreetVisible(false);
    }
  };
  const handleItemClick = (item, e) => {
    setStreetname(item);
    setFilteredStreetname([]);
    setValue(
      "zipCode",
      item?.zipcode === ""
        ? ""
        : {
            value: item?.zipcode,
            label: item?.zipcode,
          }
    );
    // Set the value directly
    setValue("city", item?.city, { shouldValidate: item?.city !== "" && true });
    setValue("street_name", item?.street_name === "" ? "" : item?.street_name);
    setValue("house_number", item?.house_number, {
      shouldValidate: item?.house_number !== "" && true,
    });
  };

  // --------------------------handle zipcode change-----------------------//
  const handleZipCode = (e, ActionType) => {
    if (ActionType.action === "clear") {
      setValue("city", "");
    } else {
      if (e?.value != "" || e?.value != "undefined") {
        const HandleCityName = zipCodeByCountryIdData?.data?.filter(
          (data) => data?.Id == e?.value
        );
        const CityData = HandleCityName[0].Text;
        setValue("city", CityData);
      }
    }
  };

  return (
    <div>
      <Form
        onSubmit={handleSubmit(handleSubmitForm)}
        onClick={() => {
          setIsSearchStreetVisible(false);
        }}
      >
        <div className="Popups-inner-scrollable-container-height scrollable-container-y">
          <div className="p-4  border-bottom">
            <Row>
              <Col lg={8}>
                <div className="light-font-text">
                  Below you can create a new lead manually in the system. Enter
                  the information you have on the customer and then you can
                  continue working with the lead in the ‘Customer funnel’.
                </div>
              </Col>
            </Row>
          </div>
          <div className="p-4 pb-2 border-bottom">
            {/* all form section  */}
            <div>
              <H4 className=" pb-4">Customer information</H4>

              {/* first name and last name */}
              <Row>
                <Col xxl={3} lg={4} md={5}>
                  <Controller
                    control={control}
                    {...register("firstname")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"firstname"}
                        value={value}
                        type={"text"}
                        placeholder={"First name (required)"}
                        errorscope
                        iserror={errors?.firstname}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("firstname");
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xxl={3} lg={4} md={6} className="ps-0">
                  <Controller
                    control={control}
                    {...register("lastname")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"lastname"}
                        value={value}
                        type={"text"}
                        placeholder={"Last name (required)"}
                        errorscope
                        iserror={errors?.lastname}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("lastname");
                        }}
                      />
                    )}
                  />
                </Col>
                <Col
                  xxl={3}
                  lg={4}
                  md={1}
                  className="ps-0 d-flex align-items-center"
                >
                  <FontAwesomeIcon
                    onClick={() =>
                      setValue(
                        "isFavouriteCustomer",
                        watch("isFavouriteCustomer") === 1 ? 0 : 1
                      )
                    }
                    icon={"fa-solid fa-heart"}
                    size="lg"
                    className="cursor-pointer "
                    style={{
                      stroke: "#AA1518",
                      strokeWidth: "30px",
                      color:
                        watch("isFavouriteCustomer") === 1
                          ? "#AA1518"
                          : "#AA151833",
                      marginLeft: "2px",
                    }}
                  />
                </Col>
              </Row>

              {/* Email section */}
              <Row className="my-3 ">
                <Col xxl={6} lg={8} md={12}>
                  <Controller
                    control={control}
                    {...register("email")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <InputField
                          id={"Email"}
                          value={value}
                          type={"email"}
                          placeholder={"Email (required)"}
                          errorscope
                          iserror={errors?.email}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("email");
                          }}
                        />
                      </div>
                    )}
                  />
                </Col>
              </Row>

              {/* Country code and mobilenumber */}
              <Row className="">
                <Col xxl={6} lg={8}>
                  <Row>
                    <Col xxl={4} lg={5} md={4} className="pe-0">
                      <Controller
                        control={control}
                        {...register("countryCode")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <ReactSelect
                            placeholder={"Country code"}
                            iserror={errors?.countryCode}
                            options={countryData?.data?.map((item) => ({
                              value: item?.Id,
                              label: item?.DataValue2,
                            }))}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("countryCode");
                            }}
                          />
                        )}
                      />
                    </Col>
                    <Col xxl={8} lg={7} md={8}>
                      <Controller
                        control={control}
                        {...register("mobilenumber")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            <InputField
                              id={"Phone"}
                              value={value}
                              maxLength={15}
                              type={"number"}
                              placeholder={"Phone"}
                              errorscope
                              iserror={errors?.mobilenumber}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("mobilenumber");
                              }}
                            />
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* Social security number  */}
              <Row className="my-3 ">
                <Col xxl={3} lg={4} md={5}>
                  <Controller
                    control={control}
                    {...register("social_security_number")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <PasswordField
                          id={"ssn"}
                          placeholder={"Social Security Number"}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("social_security_number");
                          }}
                          maxlength={15}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col xxl={3} lg={4} md={6} className="ps-0">
                  <Controller
                    control={control}
                    {...register("birthdate")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"birthdate"}
                        value={value}
                        type={"date"}
                        placeholder={"Birthdate"}
                        errorscope
                        iserror={errors?.birthdate}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("birthdate");
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>

              {/* Street , no , floor , side / door */}
              <Row>
                <Col xxl={6} lg={8} className="">
                  <Row style={{ position: "relative" }}>
                    <Col xl={4} lg={4} md={6} className="mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("street_name")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            <InputField
                              id={"street_name"}
                              value={value}
                              maxLength={15}
                              type={"text"}
                              placeholder={"Street"}
                              errorscope
                              iserror={errors.street_name}
                              onChange={(e) => {
                                onChange(e);
                                handleStreetDetailChange(e);
                              }}
                            />
                          </div>
                        )}
                      />

                      {watch("street_name")?.length < 4 &&
                      watch("street_name")?.length > 0 &&
                      watch("country")?.value == "DK" ? (
                        <div className="fs-12 fw-400 grey-text pt-1 ">
                          Type in minimum 4 characters to get a search result
                        </div>
                      ) : (
                        <>
                          {watch("country")?.value == "DK" &&
                            isSearchStreetVisible && (
                              <div>
                                <div
                                  className="bg-white radius-8 pe-1 filtered-list"
                                  style={{
                                    border: "1px solid #D1D1D1",
                                    position: "absolute",
                                    // top: "50px",
                                    // left: "14px",
                                    zIndex: "10",
                                    width: "31%",
                                  }}
                                >
                                  <>
                                    {getStreetDetailsData?.data?.length > 0 ? (
                                      <div>
                                        {getStreetDetailsData?.data?.map(
                                          (item, index) => (
                                            <React.Fragment key={index}>
                                              <div
                                                className="mt-1 fs-12"
                                                key={index}
                                                onClick={() => {
                                                  // handleStreetDetailClick(item);
                                                  handleItemClick(item);
                                                  setIsSearchStreetVisible(
                                                    false
                                                  );
                                                }}
                                              >
                                                <span className="px-2">
                                                  {item?.city ||
                                                  item?.zipcode ||
                                                  item?.house_number ? (
                                                    <> {item?.address} </>
                                                  ) : (
                                                    <> {item?.street_name}</>
                                                  )}
                                                </span>
                                              </div>
                                              <hr className="hr mt-1 mb-0"></hr>
                                            </React.Fragment>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      <div className="mt-1 px-2 fw-300 grey-text fs-12">
                                        No data found
                                      </div>
                                    )}
                                  </>
                                </div>
                              </div>
                            )}
                        </>
                      )}
                    </Col>

                    <Col lg={2} md={6} className=" mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("house_number")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            <InputField
                              id={"house_number"}
                              value={value}
                              type={"text"}
                              placeholder={"No."}
                              errorscope
                              iserror={errors.house_number}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("house_number");
                              }}
                            />
                          </div>
                        )}
                      />
                    </Col>
                    <Col lg={2} md={6} className=" mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("floor_no")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            id={"floor_no"}
                            value={value}
                            type={"text"}
                            placeholder={"Floor"}
                            errorscope
                            iserror={errors.floor_no}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("floor_no");
                            }}
                          />
                        )}
                      />
                    </Col>
                    <Col lg={2} md={6} className="mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("side")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            id={"side"}
                            value={value}
                            type={"text"}
                            placeholder={"Side"}
                            errorscope
                            iserror={errors.side}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("side");
                            }}
                          />
                        )}
                      />
                    </Col>
                    <Col lg={2} md={6} className="mb-3">
                      <Controller
                        control={control}
                        {...register("door")}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            id={"door"}
                            value={value}
                            type={"text"}
                            placeholder={"Door"}
                            errorscope
                            iserror={errors.door}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("door");
                            }}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* zip code , city , country */}
              <Row className=" ">
                <Col xxl={6} lg={8}>
                  <Row>
                    <Col lg={4} md={6} className="mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("country")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <ReactSelect
                            placeholder={"Country"}
                            clearable={false}
                            iserror={errors.country}
                            options={countryData?.data?.map((item) => ({
                              value: item?.Id,
                              label: (
                                <div
                                  className="d-flex align-items-center"
                                  style={{ fontFamily: "Cerebri sans" }}
                                >
                                  <div className="radius-4 me-2">
                                    <img
                                      src={item?.DataValue1}
                                      style={{
                                        width: "20px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <div className="fs-14 light-font-text ">
                                      {" "}
                                      {item?.Text}
                                    </div>
                                  </div>
                                </div>
                              ),
                            }))}
                            value={value == undefined ? "" : displayItem(value)}
                            onChange={(e, ActionType) => {
                              onChange(e);
                              setValue("street_name", "");
                              setValue("house_number", "");
                              setValue("zipCode", "");
                              setValue("city", "");
                              clearErrors("country");
                            }}
                          />
                        )}
                      />
                    </Col>
                    <Col lg={4} md={6} className="mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("zipCode")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <ReactSelect
                              placeholder={"Zip code"}
                              iserror={errors.zipCode}
                              options={zipCodeByCountryIdData?.data?.map(
                                (zip) => ({
                                  value: zip?.Id,
                                  label: `${zip?.Id} ${zip?.Text}`,
                                })
                              )}
                              value={
                                value?.value
                                  ? {
                                      value: value?.value,
                                      label: value?.value,
                                    }
                                  : ""
                              }
                              onChange={(e, ActionType) => {
                                handleZipCode(e, ActionType);
                                onChange(e);
                                clearErrors("zipCode");
                              }}
                            />
                          );
                        }}
                      />
                    </Col>

                    <Col lg={4} md={6} className="mb-3">
                      <Controller
                        control={control}
                        {...register("city")}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            <InputField
                              id={"city"}
                              value={value}
                              type={"text"}
                              placeholder={"City"}
                              disabled
                              removeOpacity
                              errorscope
                              iserror={errors.city}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("city");
                              }}
                            />
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>

          {/* Preferred languages */}
          <div className="p-4 pb-5 border-bottom">
            <H4> Preferred languages</H4>
            <div className="light-font-text pt-1 pb-3">
              Select the preferred language in which the customer wants to
              communicate.
            </div>

            <Row>
              <Col lg={3}>
                {/* <ReactSelect placeholder={"Select language"} /> */}
                <Controller
                  control={control}
                  {...register("language")}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Select language"}
                      clearable={false}
                      iserror={errors.language}
                      options={getlanguages?.data?.map((item) => ({
                        value: item?.Id,
                        label: (
                          <div
                            className="d-flex align-items-center"
                            style={{ fontFamily: "Cerebri sans" }}
                          >
                            <div className="radius-4 me-2">
                              <img
                                src={item?.DataValue1}
                                style={{
                                  width: "20px",
                                  marginRight: "10px",
                                }}
                              />
                            </div>
                            <div>
                              <div className="fs-14 light-font-text ">
                                {" "}
                                {item?.Text}
                              </div>
                            </div>
                          </div>
                        ),
                      }))}
                      value={
                        value == undefined ? "" : displayLanguageItem(value)
                      }
                      onChange={(e, ActionType) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="py-2 px-3 d-flex flex-row justify-content-between  footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="Cancel"
            type={"button"}
            onClick={handleCancel}
          />

          <ETButton variant={"primary"} buttonText="NEXT" type={"submit"} />
        </div>
      </Form>
    </div>
  );
};

export default LeadCustomerInformation;

// this page created by Suraj
