import { Endpoint, Site } from "./API/Endpoints";
import { Delete, Get, Post } from "./API/Method";

// Get notes details
export const GetNotesDetails = (tenancy_id, dateFilter, category, userId) => {
  userId = userId == "" || userId == null || userId == undefined ? 0 : userId;
  category =
    category == "" || category == null || category == undefined ? 0 : category;
  const date_filter = `${dateFilter}`;
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Notes_Get_Notes_Details,
      null,
      dateFilter === 0
        ? `${tenancy_id + "/notes/category/" + category + "/user/" + userId}`
        : `${
            tenancy_id +
            "/notes/category/" +
            category +
            "/user/" +
            userId +
            "/" +
            date_filter
          }`
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Get notes category
export const GetNotesCategory = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Notes_Get_Notes_Category, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Post Add note
export const PostAddNote = (addNotePayload) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Notes_Post_AddNote, null, addNotePayload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Delete note
export const DeleteNote = (note_id, tenancy_id) => {
  return new Promise((resolve, reject) => {
    Delete(
      Site.ET,
      Endpoint.ET.Notes_DeleteNote,
      null,
      note_id + "/" + tenancy_id
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
