/* eslint-disable react/jsx-pascal-case */
import React, { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ColorBox from "../../Components/ColorCombinationbox/ColorBox";
import ETButton from "../../Components/Buttons/ETButton";
import CustomCheckBox from "../../Components/Checkbox/Checkbox";
import GeographyDropdown from "./geographyDropdown/GeographyDropdown";
import DropdownModalMui from "../../Components/DropdownModal/DropdownModalMui";
import { MenuItem } from "@mui/material";
import { Col, Image, Row, Stack } from "react-bootstrap";
import photoComingSoon from "../../assets/images/photo-coming-soon.jpg";
import { Menu } from "@mui/material";
import Tabs from "../../Components/Tabs/Tabs";
import { useMutation, useQueryClient } from "react-query";
import {
  useSetFavouriteProperty,
  useGetPropertiesByPortfolioId,
  useGetFavoriteProperties,
  useGetPortfolio,
  useGetPropertyDetailByPropertyId,
  useAddProperty,
  useUpdateProperty,
  useAllPropertyList,
  useGetPropertiesByLevel,
} from "../../hooks/queries/usePropertyQueries";
import {
  DeleteProperty,
  GetPropertiesByPortfolioId,
} from "../../services/Properties";
import {
  DeletePortfolioProperty,
  DeletePortfolio,
} from "../../services/Portfolios";
import "./properties.css";
import Menus from "../../Components/Menus/Menus";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  useAllPropertiesData,
  usePropertyType,
  useCountry,
  useCountryName,
  usePorfolioTypeID,
  useFavoritesData,
  usePropertyId,
} from "../../hooks/stores/usePropertyStore";
import { status } from "../../model/Constant";
import CustomPopup from "../../Components/CustomPopup";
import MRTTheme from "../../Components/table/MRTTheme";
import {
  SearchIcon,
  paginationoptions,
  SearchStyle,
  SortIcon,
} from "../../Components/table/MRTGlobalProps";
import { TabsButton, TabsButtonIcon } from "../../Components/Tabs/Tabs.style";
import { useNavigate } from "react-router-dom";
import { H2, StyledLoadingBar } from "../../styles/Common.style";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import CreateNewInformation from "../../Components/wizard/CreateNewInformation";
import CreateBillingDetails from "../../Components/wizard/CreateBillingDetails";
import { capitalizeEachWord } from "../../utils/TextTransform";
import { useGetCommonType } from "../../hooks/queries/useCommonTypeQueries";
import LoadingBar from "react-top-loading-bar";
import { customproperties } from "../../styles/Theme";
import { billingDetailsDropdownData } from "../../model/commonDropdownData";
import {
  ErrorAlert,
  LoadingAlert,
  SucessAlert,
} from "../../Components/alerts/AlertsAndMessages";
import { useDivisionId } from "../../hooks/stores/useDivisionsStore";
import { useEditCompany } from "../../hooks/stores/useCompanyStore";
import SelectDivision from "../Company/SelectDivision";
import NewColorBox from "../../Components/ColorCombinationbox/NewColorBox";
import { useGetTeamsByLevel } from "../../hooks/queries/useTeam";

function PropertiesTable({
  Loading,
  countryData,
  GetPropertiesByLevelDataRefetch,
  EditPropertyID,
  setEditPropertyID,
  setShowPage,
  setDropdownOpen,
  setButtonType,
  setCountryId,
  setShow,
  propertyTypeSelected,
  setPropertyTypeSelected,
  setEditPortfolioId,
  setOperationName,
  setActiveDefaultTabs,
  setActivePortfolioTabs,
  activeDefaultTabs,
  usedFor = "PL",
  activePortfoliTabs,
  PropertyDataID,
  setPropertyDataID,
  tableData
}) {
  let portfolioIdForRemove;

  const queryClient = useQueryClient();
  // zustand
  const { favoritePropertiesStoreData, setFavoritePropertiesStoreData } =
    useFavoritesData((state) => state);
  const allPropertiesStoreData = useAllPropertiesData(
    (state) => state.allPropertiesStoreData
  );
  const { countryStoreData, setCountryStoreData } = useCountry(
    (state) => state
  );
  const { countryNameStore, setCountryNameStore } = useCountryName(
    (state) => state
  );
  const { divisionId } = useDivisionId((state) => state);
  const { companyId } = useEditCompany((state) => state);
  const {
    portfolioIdStore,
    portfoliotypeStore,
    setPortfolioIdStore,
    setPortfoliotypeStore,
  } = usePorfolioTypeID();
  const [rotateAddIcon, setRotateAddIcon] = useState(false);
  const rotate = rotateAddIcon ? "rotate(45deg)" : "rotate(0deg)";
  const [anchorEl, setAnchorEl] = useState(false);
  const [country, setCountry] = useState([]);
  const [duplicateTableData, setDuplicateTableData] = useState([]);
  const [duplicateTypeData, setDuplicateTyeData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [userPortfolio, setUserPortfolio] = useState([]);
  const [Id, setId] = useState(0);
  const [tabText, setTabText] = useState("");
  const [closeDropdown, setCloseDropdown] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [removePropPopup, setRemovePropShowup] = useState(false);

  //-------------------- Create new property state -------------------
  const [isSameDetailAsAboveActive, setIsSameDetailAsAboveActive] =
    useState(false);
  const [isPropertyEdited, setIsPropertyEdited] = useState(false);
  const [showWizard, setShowWizard] = useState(false);
  const [createPropertyFlag, setCreatePropertyFlag] = useState(false);
  const [progress, setProgress] = useState(0);
  const [stepWizard, setStepWizard] = useState("SelectDivision");
  const [propertyData, setPropertyData] = useState({});
  const [properteId, setProperteId] = useState("");

  //-------------------------------------react query--------------------------------//
  const { data: GetCommonType } = useGetCommonType(null, "PRPTY");

  const { propertyTypesStore } = usePropertyType((state) => state);

  const { data: allPortfoliosData, refetch: allPortfoliosDataRefetch } =
    useGetPortfolio(
      usedFor == "company" ? "c" : usedFor == "DL" ? "d" : "d",
      usedFor == "company"
        ? companyId
        : usedFor == "DL"
        ? divisionId
        : divisionId
    );

  // const {
  //   data: GetPropertiesByLevelData,
  //   isLoading,
  //   isError,
  //   refetch: GetPropertiesByLevelDataRefetch,
  // } = useGetPropertiesByLevel(
  //   usedFor == "company" ? "c" : usedFor == "DL" ? "d" : "d",
  //   usedFor == "company" ? companyId : usedFor == "DL" ? divisionId : divisionId,
  //   "details",
  //   activeDefaultTabs === 'Favorites' ? "favorites" : "all"
  // );
  
  const { data: favouritePropertiesData, refetch: favouritePropertiesRefetch } =
    useGetFavoriteProperties("d", divisionId, "details");
  const {
    data: GetPropertiesByPortfolioIdData,
    refetch: GetPropertiesByPortfolioIdDataRefetch,
  } = useGetPropertiesByPortfolioId(portfolioIdStore);
  const { mutateAsync: setFavoriteMutate } = useSetFavouriteProperty();
  const {
    data: GetPropertyDetailByPropertyIdData,
    refetch: GetPropertyDetailByPropertyIdRefetch,
  } = useGetPropertyDetailByPropertyId(properteId);
  const { data: GetTeamsByLevelData } = useGetTeamsByLevel(
    "d",
    divisionId,
    "basic"
  );
  const { mutateAsync: addProperty, isLoading: IsLoadingaddProperty } =
    useAddProperty();
  const { mutateAsync: updateProperty, isLoading: IsLoadingUpdateProperty } =
    useUpdateProperty();
  useEffect(() => {
    setFavoritePropertiesStoreData(
      favouritePropertiesData?.data
        ?.sort((a, b) => a?.propertyName?.localeCompare(b?.propertyName))
        ?.map((item, i) => item)
    );
  }, [favouritePropertiesData]);

  useEffect(() => {
    setActiveDefaultTabs("All Properties");
  }, []);


  useEffect(() => {
    // setTableData(allPropertiesStoreData);
    setDuplicateTableData(allPropertiesStoreData);
    // setDuplicateTyeData(allPropertiesStoreData);
    // setActiveDefaultTabs("All Properties");
    setActivePortfolioTabs(0);
    setRowCount(allPropertiesStoreData?.length);
  }, [allPropertiesStoreData]);

  useEffect(() => {
    // setTableData(GetPropertiesByPortfolioIdData?.data);
    // setActiveDefaultTabs(tabText);
    setActivePortfolioTabs(Id);
  }, [GetPropertiesByPortfolioIdData]);

  // used for navigation from property to property units
  const navigate = useNavigate();
  const setPropertyId = usePropertyId((state) => state.setPropertyId);

  const {
    mutateAsync: deletePropertyMutate,
    isLoading: deletePropertyMutateIsLoading,
  } = useMutation("deleteProperty", (id) => DeleteProperty(id));
  const { mutateAsync: deletePortfolioPropertyMutate } = useMutation(
    ["deletePortfolioProperty"],
    (propertyId) => DeletePortfolioProperty(propertyId, portfolioIdStore)
  );
  const { mutateAsync: deletePortfolioMutate } = useMutation(
    "deleteProperty",
    (id) => DeletePortfolio(id)
  );

  // to handle the default & User portfolios tab clicks
  const handlePortfolioTabsClick = (portfolioType, id, text) => {
    if (portfolioType === "D" && id === 1) {
      allPortfoliosDataRefetch();
      favouritePropertiesRefetch();
      // setTableData(favoritePropertiesStoreData);
      setPortfoliotypeStore("D");
      setId(id);
      // setActiveDefaultTabs("Favorites");
      setActiveDefaultTabs(text);
      setActivePortfolioTabs(id);
      favouritePropertiesRefetch();
    } else if (portfolioType === "D" && id === 0) {
      allPortfoliosDataRefetch();
      favouritePropertiesRefetch();

      setActiveDefaultTabs(text);
      setActivePortfolioTabs(id);
      // setTableData(allPropertiesStoreData);
      setPortfoliotypeStore("D");
      setId(id);
      // setActiveDefaultTabs("All Properties");
      // allPropertyDataRefetch();
    } else {
      setActiveDefaultTabs(text);
      setActivePortfolioTabs(id);
      setPortfoliotypeStore("U");
      setPortfolioIdStore(id);
      // setTableData(GetPropertiesByPortfolioIdData?.data);
      setActivePortfolioTabs(id);
      setId(id);
      setTabText(text);
      portfolioIdForRemove = id;
      GetPropertiesByPortfolioIdDataRefetch();
    }
  };



  useEffect(() => {
    setUserPortfolio(allPortfoliosData?.data?.user);
  }, [allPortfoliosData]);

  useEffect(() => {
    setCountry(countryData?.data);
  }, [countryData]);

  useEffect(() => {
    setCloseDropdown(false);
  }, [closeDropdown]);

  let filteredData;
  let noOfRows;

  const setFavoriteHandler = async (property_id, isStar) => {
    const setPropertyPayload = {
      property_id: property_id,
      is_star: isStar === 1 ? 0 : 1,
    };
    try {
      const result = await setFavoriteMutate(setPropertyPayload);
      if (result.status === status.s && result.data.bReturn) {
        toast.success(<SucessAlert />);
        GetPropertiesByLevelDataRefetch();
        allPortfoliosDataRefetch();
        favouritePropertiesRefetch();

        // setActiveDefaultTabs("Favorites");
        // setActivePortfolioTabs(Id);
        if (portfoliotypeStore === "D" && Id === 0) {
          GetPropertiesByLevelDataRefetch();
        } else if (portfoliotypeStore === "D" && Id === 1) {
          favouritePropertiesRefetch();
        } else {
          GetPropertiesByPortfolioIdDataRefetch();
        }
      } else {
        toast.error(<ErrorAlert />);
      }
    } catch (error) {}
  };
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [checkedCountry, setCheckedCountry] = useState([]);
  const [optionCheckedZipcode, setOptionCheckedZipcode] = useState([]);
  const [zipCode, setZipCode] = useState([]);
  const [selectedRegionID, setSelectedRegionID] = useState([]);
  const [allCheckedRegions, setAllCheckedRegions] = useState(false);
  const [allCheckedZipcode, setAllCheckedZipcode] = useState(false);
  const [disableDeleteAction, setDisableDeleteAction] = useState(false);
  const [geographyDropDownFilterApply, setGeograohyFilterApply] =
    useState(false);
  const [typeFilterApply, setTypeFilterApply] = useState(false);
  const [selectedZipCode, setSelectedZipCode] = useState([]);

  // Table Data Filter Logic //
  const propertiesTableData = useMemo(() => {
    const zipcodeToCheck = String(selectedZipCode); // Convert to string if not already
    const propertyTypeCheck = String(propertyTypeSelected);
    if (zipcodeToCheck && !propertyTypeCheck) {
      return tableData?.data?.filter((data) =>
        selectedZipCode?.includes(data?.zipcode_id)
      );
    } else if (propertyTypeCheck && !zipcodeToCheck) {
      return tableData?.filter(
        (data) =>
          data?.property_type_id?.toString() === propertyTypeCheck?.toString()
      );
    } else if (zipcodeToCheck && propertyTypeCheck) {
      return tableData?.filter(
        (data) =>
          data?.property_type_id?.toString() ===
            propertyTypeCheck?.toString() &&
          data?.zipcode_id?.toString() === zipcodeToCheck?.toString()
      );
    } else {
      return tableData;
    }
  }, [tableData, selectedZipCode, propertyTypeSelected]);

  // apply geograohy dropdown data //
  const applyGeography = (optionCheckedZipcode) => {
    setSelectedZipCode(optionCheckedZipcode);

    noOfRows = filteredData?.length;
    setRowCount(noOfRows);
    setCloseDropdown(true);
  };

  const handleTypeFilter = (event) => {
    const typeId = parseInt(event.target.value);
    const isSelected = event.target.checked;

    if (isSelected) {
      setPropertyTypeSelected([typeId]);
    } else {
      setPropertyTypeSelected((prevSelectedTypeIds) =>
        prevSelectedTypeIds.filter((id) => id != typeId)
      );
    }

    if (isSelected && optionCheckedZipcode.length > 0) {
      const typeFilteredData = duplicateTableData?.filter((item) =>
        event.target.value?.includes(item?.property_type_id)
      );
      // Update state with filtered data
      // setTableData(typeFilteredData);
      // setDuplicateTyeData(typeFilteredData);
    }
  };

  // Reset geography dropdown //
  const ResetAll = () => {
    setCheckedCountry([]);
    setOptionCheckedZipcode([]);
    setSelectedRegionID([]);
    setZipCode([]);
    setAllCheckedZipcode(false);
    setAllCheckedRegions(false);
  };

  const handleEditProperty = (id, countryId, flag) => {
    let editPropId = id;
    navigate("/property-units/details", {
      state: {
        editPropId: editPropId,
        editCountryId: countryId,
        Tab: "Details",
      },
    });
    setIsPropertyEdited(true);

    // setButtonType("UPDATE");
    // setShowPage("AP");
    // setEditPropertyID(editPropId);
  };

  // edit portfolio from tabs
  const handleEditPortfolio = (id) => {
    setOperationName("edit");
    setShowPage("P");
    setEditPortfolioId(id);
  };
  // delete portfolio from tabsF
  const handleDeletePortfolioFromTab = async (id) => {
    setDisableDeleteAction(true);
    let result = await deletePortfolioMutate(id);
    try {
      if (result?.data?.status) {
        toast.success(result?.data?.message);
        allPortfoliosDataRefetch();
        setActiveDefaultTabs("All Properties");
        // setTableData(allPropertiesStoreData);
        setDisableDeleteAction(false);
      } else {
        toast.error(result?.data?.message);
        setActiveDefaultTabs("All Properties");
        // setTableData(allPropertiesStoreData);
        setDisableDeleteAction(false);
      }
    } catch (error) {}
  };

  const handleNewPortfolio = () => {
    setShowPage("P");
    setOperationName("create");
  };
  const handleAddPropertyDropDown = (event) => {
    setButtonType("ADD");
    setAnchorEl(event?.currentTarget);
    setDropdownOpen(true);
    setRotateAddIcon(!rotateAddIcon);
    setCreatePropertyFlag(false);
  };
  const handleClosePropertyDropDown = () => {
    setAnchorEl(null);
    setDropdownOpen(false);
    setRotateAddIcon("");
  };
  const CreateNewProperty = (id, text) => {
    setButtonType("ADD");
    setShowPage("AP");
    setAnchorEl(null);
    setDropdownOpen(false);
    setCountryId(id);
    setCountryNameStore(text);
    setShow(true);
    setRotateAddIcon("");
  };

  const handleDeleteProperty = async (propertyId, port) => {
    if (portfoliotypeStore === "U") {
      const result = await deletePortfolioPropertyMutate(propertyId, port);
      try {
        if (result?.status === status.s && result?.data?.bReturn) {
          toast.success(result?.data?.message);
          allPortfoliosDataRefetch();
          GetPropertiesByPortfolioIdDataRefetch();
          // setTableData(GetPropertiesByPortfolioIdData?.data);
          // setShowPopup(false);
          setRemovePropShowup(false);
        } else {
          toast.error(result?.data?.message);
          // setShowPopup(false);
          setRemovePropShowup(false);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    } else {
      const result = await deletePropertyMutate(propertyId, {
        onSuccess: () => {
          queryClient.invalidateQueries(
            usedFor === "DL"
              ? "qGetDivisionOverviewByDivisionId"
              : usedFor === "company"
              ? "qGetCompanyOverviewByCompanyId"
              : "qGetPropertyOverviewByPropertyId"
          );
          queryClient.invalidateQueries("qAllPortfoliosData");
        },
      });
      try {
        if (result?.status === status.s && result?.data?.bReturn) {
          toast.success(<SucessAlert />);
          GetPropertiesByLevelDataRefetch();
          allPortfoliosDataRefetch();
          setShowPopup(false);
          setPropertyTypeSelected([]);
        } else {
          toast.error(<ErrorAlert />);
          setShowPopup(false);
        }
      } catch (error) {
        toast.error(<ErrorAlert />);
      }
    }
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: "#000",
      width: 150,
      border: "1px solid #F4F5F5",
      borderRadius: "8px",
    },
  }));

  // used for navigation from property to property units
  const handleLinkProperty = (propertyId) => {
    setPropertyId(propertyId);
    navigate("/property-units/overview", {
      state: {
        Tab: "Overview",
        editPropId: propertyId,
      },
    });
  };

  const handleLinkUnit = (propertyId) => {
    setPropertyId(propertyId);
    navigate("/property-units/units/residential", {
      state: {
        Tab: "Units",
        editPropId: propertyId,
      },
    });
  };

  const columns = useMemo(
    () => [
      // {
      //   id: "star",
      //   header: "",
      //   enableHiding: false,
      //   size: 100,
      //   Cell: ({ row }) => (
      //     <div className="d-flex align-items-center gap-3">
      //       {row?.original?.isStar == "1" ? (
      //         <FontAwesomeIcon
      //           icon="fa-solid fa-star"
      //           size="lg"
      //           style={{ color: "#F6E651", cursor: "pointer" }}
      //           onClick={() =>
      //             setFavoriteHandler(
      //               row?.original?.property_id,
      //               row?.original?.isStar
      //             )
      //           }
      //         />
      //       ) : (
      //         <FontAwesomeIcon
      //           icon="fa-regular fa-star"
      //           style={{ color: "#F6E651", cursor: "pointer" }}
      //           size="lg"
      //           onClick={() =>
      //             setFavoriteHandler(
      //               row?.original?.property_id,
      //               row?.original?.isStar
      //             )
      //           }
      //         />
      //       )}
      //       <Image
      //         className="rounded"
      //         src={
      //           row.original.image_url
      //             ? row.original.image_url
      //             : photoComingSoon
      //         }
      //         height={60}
      //         width={60}
      //       />
      //     </div>
      //   ),

      //   muiTableHeadCellProps: {
      //     align: "right",
      //   },
      //   muiTableBodyCellProps: {
      //     align: "center",
      //     sx: {
      //       paddingLeft: "12px!important",
      //       paddingRight: "12px!important",
      //       borderTop: "1px solid #D1D1D1 !important",
      //       borderBottom: "1px solid #D1D1D1 !important",
      //       borderLeft: "1px solid #D1D1D1 !important",
      //     },
      //   },
      // },
      {
        accessorFn: (row) =>
          `${row.property_name} ${row.zipcode_id} ${row.city_name}`,
        accessorKey: "Project",
        header: "Property",
        enableHiding: false,
        size: 130,
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "24px!important",
            border: "1px solid #D1D1D1 !important",
            paddingTop: "8px!important",
            paddingBottom: "8px!important",
          },
        },
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "136px!important",
          },
        },
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div className="d-flex">
            <div className="d-flex align-items-center gap-3">
              {/* {row?.original?.isStar == "1" ? (
                <FontAwesomeIcon
                  icon="fa-solid fa-star"
                  size="lg"
                  style={{ color: "#F6E651", cursor: "pointer" }}
                  onClick={() =>
                    setFavoriteHandler(
                      row?.original?.property_id,
                      row?.original?.isStar
                    )
                  }
                />
              ) : (
                <FontAwesomeIcon
                  icon="fa-regular fa-star"
                  style={{ color: "#F6E651", cursor: "pointer" }}
                  size="lg"
                  onClick={() =>
                    setFavoriteHandler(
                      row?.original?.property_id,
                      row?.original?.isStar
                    )
                  }
                />
              )} */}

              <FontAwesomeIcon
                icon={
                  row?.original?.is_starred?.toString() === "1"
                    ? "fa-solid fa-star"
                    : "fa-regular fa-star"
                }
                size="lg"
                style={{ color: "#F6E651", cursor: "pointer" }}
                onClick={() =>
                  setFavoriteHandler(
                    row?.original?.property_id,
                    row?.original?.is_starred
                  )
                }
              />
              <Image
                className="rounded"
                src={
                  row?.original?.image_url
                    ? row?.original?.image_url
                    : photoComingSoon
                }
                height={60}
                width={60}
              />
            </div>
            <div
              onClick={() => handleLinkProperty(row.original.property_id)}
              style={{ paddingLeft: "16px" }}
              className="d-flex align-items-center cursor-pointer gap-3"
            >
              {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
              <div className="d-flex flex-row justify-content-start align-items-start gap-3 ">
                <div
                  style={{ textAlign: "left", width: "150px" }}
                  className="d-flex flex-column pt-1"
                >
                  <span className="table-property-name ellipsis" id="property">
                    {row.original.property_name}
                    {/* {row.original.propertyName?.length > 20
                    ? `${row.original.propertyName.substring(0, 18)}...`
                    : row.original.propertyName} */}
                  </span>
                  <span className="fs-11 blackColor">
                    {row.original.zipcode_id} {row.original.city_name}
                  </span>
                  {row.original.portfolio_name?.length > 20 ? (
                    <HtmlTooltip
                      title={
                        <div className="bg-white radius-8 p-1 fs-11  text-capitalize">
                          {row.original.portfolio_name.split(",").join(", ")}
                        </div>
                      }
                    >
                      <span className="fs-11 table-grey-text  text-capitalize">
                        {row.original.portfolio_name?.length > 20
                          ? `${row.original.portfolio_name
                              .substring(0, 17)
                              .split(",")
                              ?.join(", ")}...`
                          : row.original.portfolio_name.split(",").join(", ")}
                      </span>
                    </HtmlTooltip>
                  ) : (
                    <span className="fs-11  grey-text ">
                      {row.original.portfolio_name
                        ? row.original.portfolio_name
                        : ""}
                    </span>
                  )}
                  <span className="fs-11 table-grey-text  text-capitalize ">
                    {row.original.company_name?.length > 17
                      ? `${row.original.company_name
                          ?.substring(0, 17)
                          ?.split(",")
                          ?.join(", ")}...`
                      : row.original.company_name?.split(",")?.join(", ")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      // {
      //   accessorKey: "portfolioName",
      //   header: "Portfolio",
      //   size: 60,
      //   muiTableBodyCellProps: {
      //     align: "center",
      //     sx: {
      //       paddingRight: "0px !important",
      //       borderTop: "1px solid #D1D1D1 !important",
      //       borderBottom: "1px solid #D1D1D1 !important",
      //       // borderRight: "1px solid #D1D1D1 !important",
      //       // borderImage:
      //       //   "linear-gradient(to bottom, rgba(0,0,0,0) 25%,rgba(209,209,209) 25%,rgba(209,209,209) 75%,rgba(0,0,0,0) 75%) !important",
      //       // borderImageSlice: "1 !important",
      //     },
      //   },
      //   enableGlobalFilter: false,
      //   muiTableHeadCellProps: {
      //     sx: {
      //       paddingRight: "0px !important",
      //     },
      //   },

      //   Cell: ({ row }) => {
      //     const portfolioNames = row.original.portfolioName?.split(",");
      //     return (
      //       <div className="d-flex justify-content-between align-items-center">
      //         {row.original.portfolioName?.length > 0 ? (
      //           <HtmlTooltip
      //             title={
      //               <div className="bg-white radius-8 p-1 fs-11  text-capitalize">
      //                 {row.original.portfolioName}
      //               </div>
      //             }
      //           >
      //             <div className="cursor-pointer d-flex  align-items-center">
      //               {portfolioNames?.length}
      //               {/* {portfolioNames?.[0] == '' ? '0' : <>{portfolioNames?.length}</>} */}
      //             </div>
      //           </HtmlTooltip>
      //         ) : (
      //           <>
      //             <div className="cursor-pointer d-flex  align-items-center">
      //               {portfolioNames?.[0] == "" ? "0" : ""}
      //             </div>
      //           </>
      //         )}

      //         <TableDataBorderRight />
      //       </div>
      //     );
      //   },
      // },
      {
        accessorFn: (row) => `${row.property_type}`,
        header: "Type",
        size: 100,
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "24px!important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "2px !important",
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            paddingLeft: "24px !important",
          },
        },
        Cell: ({ row }) => {
          return (
            <div className="d-flex justify-content-between align-items-center">
              {row?.original?.property_type
                ? row?.original?.property_type
                : "-"}{" "}
              <TableDataBorderRight />
            </div>
          );
        },
      },
      {
        accessorKey: "Units",
        header: "Units",
        size: 90,
        enableGlobalFilter: false,
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "2px !important",
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            paddingLeft: "24px!important",
          },
        },
        muiTableHeadCellProps: {
          sx: {
            paddingRight: "0px !important",
            paddingLeft: "24px!important",
          },
        },
        Cell: ({ row }) => {
          return (
            <div className="d-flex justify-content-between align-items-center">
              <div>
                {row?.original?.unit_count?.toString() !== "0" ? (
                  row?.original?.unit_count
                ) : (
                  <span style={{ color: "#888888" }}>No units</span>
                )}
              </div>
              <TableDataBorderRight />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => `${row.available}${row.availablePercentage}`,
        header: "Availability",
        enableGlobalFilter: false,
        size: 120,
        Cell: ({ row }) => {
          return (
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                {row.original.available?.toString() !== "0"
                  ? row.original.available
                  : "0"}{" "}
                {parseInt(row.original.available) > 1 ? "units" : "unit"} |{" "}
                {row.original.available_percentage?.toString() !== "0"
                  ? row.original.available_percentage
                  : "0"}
                %
              </div>
              <TableDataBorderRight />
            </div>
          );
        },
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "0px !important",
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            paddingLeft: "24px!important",
          },
        },
        muiTableHeadCellProps: {
          sx: {
            paddingRight: "2px !important",
            paddingLeft: "24px!important",
          },
        },
      },
      {
        accessorFn: (row) => `${row.idleness}${row.idleness_percentage}`,
        header: "Idealness",
        size: 120,
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "2px !important",
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
            paddingLeft: "24px!important",
          },
        },
        muiTableHeadCellProps: {
          sx: {
            paddingRight: "2px !important",
            paddingLeft: "24px!important",
          },
        },
        enableGlobalFilter: false,
        Cell: ({ cell, row }) => {
          return (
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                {row.original.idleness?.toString() !== "0"
                  ? row.original.idleness
                  : "0"}{" "}
                {parseInt(row.original.idleness) > 1 ? "units" : "unit"} |{" "}
                {row.original.idleness_percentage?.toString() !== "0"
                  ? row.original.idleness_percentage
                  : "0"}
                %
              </div>
              <TableDataBorderRight />
            </div>
          );
        },
      },
      {
        accessorFn: (row) =>
          `${row.not_active} ${row.available} ${row.reserved}${row.in_process}${row.completed}`,
        accessorKey: "Units overview",
        header: "Units overview",
        disableSortBy: true,
        enableGlobalFilter: false,
        size: 180,
        Cell: ({ row }) => (
          <NewColorBox
            unitsOverview={row?.original?.status}
            TotalUnits={row?.original?.unit_count}
          />
        ),
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "24px!important",
            borderTop: "1px solid #D1D1D1 !important",
            borderBottom: "1px solid #D1D1D1 !important",
          },
        },
        muiTableHeadCellProps: {
          sx: {
            paddingRight: "2px !important",
            paddingLeft: "24px!important",
          },
        },
      },
    ],
    []
  );

  const deleteProperty = (propId) => {
    setEditPropertyID(propId);
    setShowPopup(true);
  };
  const removeProperty = (propId) => {
    setEditPropertyID(propId);
    setRemovePropShowup(true);
  };

  const renderEmptyRowsFallback = () => {
    if (
      tableData &&
      tableData?.length === 0
    ) {
      return (
        <div
          className="d-flex justify-content-center w-100 grey-text py-4 radius-4"
          style={{ border: "1px solid #d1d1d1" }}
        >
          {activeDefaultTabs == "Favorites" ? (
            <div>
              You do not have any favorite property. Click on{" "}
              <FontAwesomeIcon
                icon="fa-solid fa-star"
                size="sm"
                style={{ color: "#F6E651" }}
              />{" "}
              to add property to the favorite list
            </div>
          ) : (
            <>
              {activeDefaultTabs == "All Properties" ? (
                <div>
                  You do not have any properties added yet. Click on “+ ADD
                  PROPERTY” to add your very first property!
                </div>
              ) : (
                <div>
                  You do not have any Properties added to portfolio. Click on
                  "EDIT PORTFOLIO" to add one.
                </div>
              )}
            </>
          )}
        </div>
      );
    } else {
      return (
        <div
          className="d-flex justify-content-center w-100 grey-text py-4 radius-4"
          style={{ border: "1px solid #d1d1d1" }}
        >
          <>
            <div className="fs-14">
              Sorry! No result found. Please try again!
            </div>
          </>
        </div>
      );
    }
  };

  //--------- useEffect -----------------------
  useEffect(() => {
    if (
      isPropertyEdited &&
      Object.keys(GetPropertyDetailByPropertyIdData ?? {}).length
    ) {
      const APIcompanyData = GetPropertyDetailByPropertyIdData?.data?.[0];
      let property_type_id = "";
      if (APIcompanyData?.property_type_id) {
        property_type_id = GetCommonType?.data?.filter(
          (d) => d?.Id === APIcompanyData?.property_type_id
        );
      }
      setPropertyData({
        ...propertyData,
        div_name: "",
        division_id: {
          value: APIcompanyData?.division_id,
          label: APIcompanyData?.division_name,
          isDisabled: true,
        },
        property_type_id: {
          value: property_type_id?.length ? property_type_id?.[0].Id : "",
          label: property_type_id?.length ? property_type_id?.[0].Text : "",
          isDisabled: true,
        },
        property_name: APIcompanyData?.property_name,
        company: APIcompanyData?.company_name,
        company_id: {
          value: APIcompanyData?.company_id,
          label: APIcompanyData?.company_name,
          isDisabled: true,
        },
        construction_year: {
          value: APIcompanyData?.construction_year,
          label: APIcompanyData?.construction_year,
          isDisabled: true,
        },
        billing_method: {
          value: APIcompanyData?.billing_method_applicable_id,
          label: billingDetailsDropdownData?.filter(
            (data) =>
              data?.Id?.toString() ===
              APIcompanyData?.billing_method_applicable_id?.toString()
          )?.[0]?.Text,
        },
        care_of: APIcompanyData?.care_of,
        short_name: APIcompanyData?.shortname,
        vat_number: APIcompanyData?.public_id1,
        gst_number: APIcompanyData?.public_id2,
        cvr_number: APIcompanyData?.public_id3,
        street: APIcompanyData?.street_name,
        house_number: APIcompanyData?.house_number,
        floor_number: {
          value: APIcompanyData?.floor_id,
          label: APIcompanyData?.floor_name,
          isDisabled: true,
        },
        website: APIcompanyData?.website_url,
        side: {
          value: APIcompanyData?.side_id,
          label: APIcompanyData?.side_name,
          isDisabled: true,
        },
        country: {
          value: APIcompanyData?.country_id,
          label: APIcompanyData?.country,
          isDisabled: true,
        },
        door: APIcompanyData?.door,
        zipcode_id: {
          value: APIcompanyData?.zipcode_id,
          label: APIcompanyData?.city_name,
          isDisabled: true,
        },
        contact_person_name: APIcompanyData?.contact_person_name,
        country_code: APIcompanyData?.contact_person_isd_code,
        city: APIcompanyData?.city_name,

        team_access: APIcompanyData?.team_access?.map((item) => ({
          value: item?.user_team_id,
          label: item?.user_team_name,
        })),
        contact_person_phone_no: APIcompanyData?.contact_person_phone_no,
        contact_person_email: APIcompanyData?.contact_person_email,
        bInfo_company: APIcompanyData?.billing_details?.[0]?.company_name,
        bInfo_care_of: APIcompanyData?.billing_details?.[0]?.care_of,
        bInfo_cvr_number: APIcompanyData?.billing_details?.[0]?.public_id3,
        bInfo_vat_number: APIcompanyData?.billing_details?.[0]?.public_id1,
        bInfo_gst_number: APIcompanyData?.billing_details?.[0]?.public_id2,
        bInfo_street: APIcompanyData?.billing_details?.[0]?.street_name,
        bInfo_house_number: APIcompanyData?.billing_details?.[0]?.house_number,
        bInfo_floor_number: {
          value: APIcompanyData?.billing_details?.[0]?.floor_id,
          label: APIcompanyData?.billing_details?.[0]?.floor_name,
          disabled: true,
        },

        bInfo_side: {
          value: APIcompanyData?.billing_details?.[0]?.side_id,
          label: APIcompanyData?.billing_details?.[0]?.side_name,
          disabled: true,
        },
        bInfo_door: APIcompanyData?.billing_details?.[0]?.door,
        bInfo_country: {
          value: APIcompanyData?.billing_details?.[0]?.country_id,
          label: APIcompanyData?.billing_details?.[0]?.country,
          disabled: true,
        },
        bInfo_city: APIcompanyData?.billing_details?.[0]?.city,
        invoice_email: APIcompanyData?.billing_details?.[0]?.invoice_email,
        attention_person:
          APIcompanyData?.billing_details?.[0]?.attention_person_name,
        ean_number: APIcompanyData?.billing_details?.[0]?.ean,
        bInfo_zipCode: {
          value: APIcompanyData?.billing_details?.[0]?.zipcode_id,
          label: APIcompanyData?.billing_details?.[0]?.city,
          disabled: true,
        },
      });
      setIsSameDetailAsAboveActive(
        APIcompanyData?.is_same_detail_as_defined_general === "1" ? 1 : 0
      );
    } else {
      setPropertyData({});
    }
  }, [isPropertyEdited, GetPropertyDetailByPropertyIdData, showWizard]);
  //---------------------handlers------------------------//
  const handleSelectDivisionNext = () => {
    setProgress(66.66);
    setStepWizard("CreateNewInfo");
  };

  const handleSelectDivisionBack = () => {
    setProgress(0);
    setStepWizard("SelectDivision");
    setShowWizard(false);
  };

  const handleCreateNewInfoNext = (data) => {
    setStepWizard("CreateNewBillingDetails");
    setProgress(99.99);
    setPropertyData({
      ...propertyData,
      ...data,
    });
  };

  const handleCreateNewInfoBack = () => {
    if (isPropertyEdited) {
      setIsSameDetailAsAboveActive(false);
      setPropertyData({});
      setStepWizard("SelectDivision");
      return setShowWizard(false);
    } // if edit mode is on we don't won't to show select division popup
    setStepWizard("SelectDivision");
    setProgress(isPropertyEdited ? 0 : 25);
  };
  const handleAddCompany = () => {
    setIsSameDetailAsAboveActive(false);
    setIsPropertyEdited(false);
    setShowWizard(true);
    setProgress(33.33);
  };

  const handleCreateNewBillingDetailsBack = () => {
    setStepWizard("CreateNewInfo");
    setProgress(66.66);
  };
  //   --------handle Edit Property----//
  const handleEditedProperty = () => {
    GetPropertyDetailByPropertyIdRefetch();
    setStepWizard("CreateNewInfo");
    setShowWizard(true);
    setIsPropertyEdited(true);
    setProgress(33.33);
  };
  // -------------- handle create new billing details -----------
  const handleCreateNewBillingDetailsNext = async (data) => {
    let id;
    let propertiesDetailsPlayload = {
      name: capitalizeEachWord(data?.property_name),
      property_type_id: data?.property_type_id?.value?.toString(),
      construction_year: data?.construction_year?.value?.toString(),
      energy_label_id: "1",
      team_access: data?.team_access
        ? data?.team_access?.map((item) => item?.value)
        : [],
      area_id: "1",
      area_group_id: "1",
      street_name: data?.street,
      house_number: data?.house_number,
      floor_id: data?.floor_number?.value?.toString(),
      side_id: data?.side?.value?.toString(),
      door: data?.door ?? "",
      zipcode_id: data?.zipcode_id?.value,
      country_id: data?.country?.value?.toUpperCase(),
      contact_person_name: capitalizeEachWord(data?.contact_person_name),
      contact_person_email: data?.contact_person_email?.toLowerCase(),
      contact_person_isd_code: data?.country_code,
      contact_person_phone_no: data?.contact_person_phone_no,
      is_same_detail_as_defined_general: isSameDetailAsAboveActive ? "1" : "0",
      billing_method_applicable_id: data?.billing_method?.value,
      website_url: data?.website,

      billing_details:
        data?.billing_method?.value?.toString() === "P"
          ? {
              company_name: capitalizeEachWord(data?.bInfo_company),
              care_of: capitalizeEachWord(data?.bInfo_care_of),
              public_id1: data?.bInfo_vat_number,
              public_id2: data?.bInfo_gst_number,
              public_id3: data?.bInfo_cvr_number,
              public_type_id1: "1",
              public_type_id2: "1",
              public_type_id3: "1",
              street_name: data?.bInfo_street,
              house_number: data?.bInfo_house_number,
              floor_id: data?.bInfo_floor_number?.value?.toString(),
              side_id: data?.bInfo_side?.value,
              door: data?.bInfo_door ?? "",
              zipcode_id: data?.bInfo_zipCode?.value,
              country_id: data?.bInfo_country?.value,
              invoice_email: data?.invoice_email?.toLowerCase(),
              attention_person: capitalizeEachWord(data?.attention_person),
              ean_number: data?.ean_number,
            }
          : {},
    };

    if (isPropertyEdited) {
      const editProperty = {
        property_id: properteId,
        ...propertiesDetailsPlayload,
      };
      if (!IsLoadingUpdateProperty) {
        id = toast.loading(<LoadingAlert />);
      }
      try {
        const response = await updateProperty(editProperty);
        if (response.status === status.s && response.data.bReturn) {
          toast.update(id, {
            // render: <SucessAlert />,
            isLoading: false,
            type: status.s,
            autoClose: 200,
          });

          setProgress(99.99);
          setStepWizard("SelectDivision");
          setShowWizard(false);
          setPropertyData({});
          GetPropertiesByLevelDataRefetch();
          setIsSameDetailAsAboveActive(false);
        } else {
          toast.update(id, {
            render: <ErrorAlert />,
            isLoading: false,
            type: status.e,
            autoClose: 2000,
          });
        }
      } catch (error) {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } else {
      const createProperty = {
        division_id: data?.division_id?.value?.toString(),
        company_id: data?.company_id?.value?.toString(),
        ...propertiesDetailsPlayload,
      };
      if (!IsLoadingaddProperty) {
        id = toast.loading(<LoadingAlert />);
      }
      try {
        const response = await addProperty(createProperty, {
          onSuccess: () => {
            queryClient.invalidateQueries(
              usedFor === "DL"
                ? "qGetDivisionOverviewByDivisionId"
                : usedFor === "company"
                ? "qGetCompanyOverviewByCompanyId"
                : "qGetPropertyOverviewByPropertyId"
            );

            queryClient.invalidateQueries("qAllPortfoliosData");
          },
        });
        if (response.status === status.s && response.data.bReturn) {
          toast.update(id, {
            // render: <SucessAlert  />,
            isLoading: false,
            type: status.s,
            autoClose: 200,
          });

          setProgress(99.99);
          setStepWizard("SelectDivision");
          setIsSameDetailAsAboveActive(false);
          setShowWizard(false);
          setPropertyData({});
          GetPropertiesByLevelDataRefetch();
          setIsSameDetailAsAboveActive(false);
        } else {
          toast.update(id, {
            render: <ErrorAlert />,
            isLoading: false,
            type: status.e,
            autoClose: 2000,
          });
        }
      } catch (error) {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    }
  };
  //   --------Property created----//

  const HandlePropertyCreated = () => {
    setProgress(0);
    setShowWizard(false);
    setStepWizard("SelectDivision");
    setPropertyData({});
    // setClientFormData([]);
  };
  const props = {
    flag: "Property",
    setShow: setShowWizard,
    setStepWizard: setStepWizard,
    setProgress,
    handleNext: handleCreateNewInfoNext,
    handleClose: handleCreateNewInfoBack,
    formData: propertyData,
    setFormData: setPropertyData,
    isSameDetailAsAboveActive,
    setIsSameDetailAsAboveActive,
    handlePrevious: handleCreateNewBillingDetailsBack,
    handleAddDataFun: handleCreateNewBillingDetailsNext,
    handleCloseButton: HandlePropertyCreated,
    createButtonDisable: IsLoadingaddProperty || IsLoadingUpdateProperty,
    flagEdit: isPropertyEdited,
    commonType: GetCommonType?.data,
    name: propertyData?.property_name,
    address: `${propertyData?.street || ""} ${
      propertyData?.house_number || "-"
    }, ${propertyData?.zipcode_id?.value || ""} ${propertyData?.city || ""}`,
  };

  const WizardsPages = {
    SelectDivision: (
      <SelectDivision
        handleNext={handleSelectDivisionNext}
        handleClose={handleSelectDivisionBack}
        flag="property"
        formData={propertyData}
        setFormData={setPropertyData}
      />
    ),
    CreateNewInfo: <CreateNewInformation {...props} />,
    CreateNewBillingDetails: <CreateBillingDetails {...props} />,
    // DivisionCreated: <CreateClientStepFour {...props} />,
  };
  return (
    <>
      <div className="whiteContainerStyle">
        <div className="p-4 border-bottom">
          <div className="d-flex align-items-center  gap-2">
            <H2>Properties </H2>
            <CustomToolTip size="sm" text="Properties" />
          </div>
        </div>

        <div className="bg-white radius-8">
          <Row>
            <Col
              className="px-3  pb-1"
              style={{ justifyContent: "flex-start" }}
            >
              <div style={{ borderBottom: "1px solid  #F0F0F0" }}>
                <Row className="d-flex mx-2">
                  <Col xl={4} lg={6} md={12} className="portfolioBorder">
                    <Row className=" pb-3 px-2 " style={{ paddingTop: "21px" }}>
                      {/* {allPortfoliosData?.data?.default?.map(
                        (defaultTab, i) => {
                          return (
                            <Col md={6} className="px-1" key={i}>
                              <TabsButton
                                key={defaultTab?.Id}
                                className={"my-1 justify-content-center"}
                                mode={(
                                  defaultTab.Text === activeDefaultTabs
                                ).toString()}
                                style={{ width: "100%" }}
                                onClick={() => {
                                  handlePortfolioTabsClick(
                                    "D",
                                    defaultTab?.Id,
                                    defaultTab?.Text
                                  );
                                }}
                              >
                                {defaultTab.Text === "Favorites" && (
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-star"
                                    size="lg"
                                    style={{ color: "#F6E651" }}
                                  />
                                )}
                                <span className="ms-1">
                                  {defaultTab.Text} ({defaultTab.Datavalue1})
                                </span>
                              </TabsButton>
                            </Col>
                          );
                        }
                      )} */}
                      <Col md={6} className="px-1">
                        <TabsButton
                          // key={defaultTab?.Id}
                          className={"my-1 justify-content-center"}
                          mode={("Favorites" === activeDefaultTabs).toString()}
                          style={{ width: "100%" }}
                          onClick={() => {
                            handlePortfolioTabsClick(
                              "D",
                              allPortfoliosData?.data?.default?.[0]?.Id,
                              allPortfoliosData?.data?.default?.[0]?.Text
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-star"
                            size="lg"
                            style={{ color: "#F6E651" }}
                          />
                          <span className="ms-1">
                            Favourites (
                            {allPortfoliosData?.data?.default?.[0]
                              ?.Datavalue1 || 0}
                            )
                          </span>
                        </TabsButton>
                      </Col>
                      <Col md={6} className="px-1">
                        <TabsButton
                          // key={defaultTab?.Id}
                          className={"my-1 justify-content-center"}
                          mode={(
                            "All Properties" === activeDefaultTabs
                          ).toString()}
                          style={{ width: "100%" }}
                          onClick={() => {
                            handlePortfolioTabsClick(
                              "D",
                              allPortfoliosData?.data?.default?.[1]?.Id,
                              allPortfoliosData?.data?.default?.[1]?.Text
                            );
                          }}
                        >
                          <span className="ms-1">
                            All properties (
                            {allPortfoliosData?.data?.default?.[1]
                              ?.Datavalue1 || 0}
                            )
                          </span>
                        </TabsButton>
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={8} lg={6} md={12} style={{ paddingTop: "21px" }}>
                    <Stack
                      className="mt-1 mb-1 pb-3 scrollable-container-x "
                      direction="horizontal"
                      gap={2}
                      style={{ overflowX: "scroll" }}
                    >
                      {userPortfolio?.map((portfolioTab, i) => {
                        return (
                          <Tabs
                            key={portfolioTab?.Id}
                            text={`${portfolioTab?.Text} (${portfolioTab?.Datavalue1})`}
                            id={portfolioTab?.Id}
                            activeTabs={
                              // portfolioTab?.Id == activePortfoliTabs
                              portfolioTab?.Text == activeDefaultTabs
                                ? true
                                : false
                            }
                            // className={
                            //   portfolioTab.Text == activeDefaultTabs
                            //     ? "tabsbtnFocusActive  my-1 justify-content-center"
                            //     : "Tabsbtn-component  my-1 justify-content-center"
                            // }
                            onClick={() => {
                              handlePortfolioTabsClick(
                                "U",
                                portfolioTab?.Id,
                                portfolioTab?.Text
                              );
                            }}
                            allPropertyList={GetPropertiesByPortfolioId?.data}
                            handleEditPortfolio={handleEditPortfolio}
                            handleDeletePortfolioFromTab={
                              handleDeletePortfolioFromTab
                            }
                            disableDeleteAction={disableDeleteAction}
                          />
                        );
                      })}
                    </Stack>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>

        <div className="px-4 pb-2">
          <MRTTheme
            colorVariantsForTableRow={["gray"]}
            colorVariantsForTableHead={["white"]}
            table={
              <MaterialReactTable
                autoResetPageIndex={false}
                columns={columns}
                data={propertiesTableData ?? []} //data is undefined on first render
                icons={{ ...SortIcon }}
                enablePagination={true}
                state={{ Loading: !tableData }}
                renderEmptyRowsFallback={renderEmptyRowsFallback}
                enableColumnActions={false}
                enableBottomToolbar={true}
                globalFilterFn="contains"
                muiSearchTextFieldProps={{
                  ...SearchStyle,

                  InputProps: {
                    ...SearchIcon,
                  },
                }}
                getRowId={(originalRow) => originalRow.property_id} //give each row a more useful id
                enableSelectAll={false}
                enableRowActions
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "",
                    size: 40,
                    muiTableBodyCellProps: {
                      sx: {
                        // paddingLeft: "0px!important",
                        borderTop: "1px solid #D1D1D1 !important",
                        borderBottom: "1px solid #D1D1D1 !important",
                        borderRight: "1px solid #D1D1D1 !important",
                      },
                    },
                  },
                  "mrt-row-select": {
                    header: "",
                  },
                }}
                renderTopToolbar={({ table }) => (
                  <>
                    <div className="pb-0 pt-3 ">
                      <Row>
                        <Col xl={2} md={4} className="my-1 pe-3">
                          {Loading ? (
                            <div
                              className="placeholder col-10 radius-8 padding-20  bg-secondary"
                              style={{ width: "130px" }}
                            ></div>
                          ) : (
                            <MRT_GlobalFilterTextField table={table} />
                          )}
                        </Col>
                        <Col xl={2} md={4} className="my-1 ps-0 pe-3">
                          {Loading ? (
                            <div
                              className="placeholder col-10 radius-8 padding-20  bg-secondary"
                              style={{ width: "130px" }}
                            ></div>
                          ) : (
                            <DropdownModalMui
                              text="Geography"
                              width="700px"
                              closeDropdown={closeDropdown}
                              component={
                                <GeographyDropdown
                                  applyGeography={applyGeography}
                                  ResetAll={ResetAll}
                                  checkedCountry={checkedCountry}
                                  setCheckedCountry={setCheckedCountry}
                                  optionCheckedZipcode={optionCheckedZipcode}
                                  setOptionCheckedZipcode={
                                    setOptionCheckedZipcode
                                  }
                                  selectedRegionID={selectedRegionID}
                                  setSelectedRegionID={setSelectedRegionID}
                                />
                              }
                            />
                          )}
                        </Col>
                        <Col xl={2} md={3} className="my-1 ps-0 pe-3">
                          {Loading ? (
                            <div
                              className="placeholder col-10 radius-8 padding-20  bg-secondary"
                              style={{ width: "120px" }}
                            ></div>
                          ) : (
                            <DropdownModalMui
                              text="Type"
                              width="170px"
                              component={
                                <>
                                  {propertyTypesStore?.map((type, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="d-flex"
                                        style={{ alignItems: "center" }}
                                      >
                                        <div>
                                          <CustomCheckBox
                                            id={type?.Id}
                                            key={type?.Id}
                                            color="success"
                                            value={type.Id}
                                            checked={propertyTypeSelected?.includes(
                                              type.Id
                                            )}
                                            onChange={handleTypeFilter}
                                            // value={type?.Text}
                                            // onChange={(e) =>
                                            //   handleTypeFilter(e)
                                            // }
                                            // checked={propertyTypeSelected.includes(
                                            //   Number(type?.Id)
                                            // )}
                                          />
                                        </div>
                                        <div className="light-font-text ">
                                          {type?.Text}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              }
                            />
                          )}
                        </Col>
                        <Col xl={1} md={1} className="my-1 pe-1 ps-md-0">
                          <div
                            className="d-flex float-md-end float-xl-none me-xl-0 me-2 "
                            style={{
                              backgroundColor: "#fff",
                              border: "1px solid #D1D1D1",
                              borderRadius: "8px",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "40px",
                              height: "41px",
                              cursor: "pointer",
                            }}
                          >
                            {Loading ? (
                              <div
                                className="placeholder col-10 radius-8 padding-20  bg-secondary"
                                style={{ width: "45px" }}
                              ></div>
                            ) : (
                              <TabsButtonIcon>
                                <FontAwesomeIcon icon="fa-regular fa-gear" />
                              </TabsButtonIcon>
                            )}
                          </div>
                        </Col>

                        {usedFor == "PL" ? (
                          <Col xl={5} className="my-1">
                            <div className="d-flex justify-content-end ">
                              <div className="mx-2">
                                <ETButton
                                  variant={"secondary"}
                                  buttonText="CREATE NEW PORTFOLIO"
                                  icon={true}
                                  onClick={handleNewPortfolio}
                                  fontSize="14px"
                                  width="auto"
                                />
                              </div>
                              <div className="">
                                <ETButton
                                  variant={"primary"}
                                  buttonText="ADD PROPERTY"
                                  rotate={rotate}
                                  icon={true}
                                  onClick={handleAddPropertyDropDown}
                                  width="auto"
                                />

                                <Menu
                                  anchorEl={anchorEl}
                                  open={anchorEl}
                                  onClose={handleClosePropertyDropDown}
                                  PaperProps={{
                                    style: {
                                      borderRadius: "8px",
                                      marginTop: "6px",
                                      border: "0.5px solid #F0F0F0",
                                      boxShadow:
                                        "0px 1px 1px 0px rgba(0,0,0,0.12)",
                                      paddingTop: "0px",
                                    },
                                  }}
                                  sx={{
                                    "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                                      padding: 0,
                                      borderColor: "##F0F0F0",
                                    },
                                  }}
                                >
                                  <div
                                    className="addpropertyCountryHandle "
                                    style={{ fontSize: "14px", width: "208px" }}
                                  >
                                    <div className="addpropHead form-label-title ps-3 pe-4 pt-2 pb-2 me-4">
                                      Choose country
                                    </div>
                                    <hr className="hr m-0"></hr>

                                    {countryStoreData?.map((item, index) => {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className="addpropContent px-3 pt-2 pb-2 "
                                            onClick={() =>
                                              CreateNewProperty(
                                                item?.Id,
                                                item?.Text
                                              )
                                            }
                                          >
                                            <img
                                              src={item.DataValue1}
                                              width="17px"
                                              height="12px"
                                              className="me-1"
                                            />
                                            <span className="ms-1">
                                              {item?.Text}
                                            </span>
                                          </div>
                                          <hr className="hr m-0"></hr>
                                        </>
                                      );
                                    })}
                                  </div>
                                </Menu>
                              </div>
                            </div>
                          </Col>
                        ) : (
                          <Col xl={5} className="my-1">
                            <div className="d-flex justify-content-end gap-3">
                              <div className="mx-2 pe-xl-0 pe-lg-0 me-0">
                                <ETButton
                                  variant={"secondary"}
                                  buttonText="CREATE NEW PORTFOLIO"
                                  icon={true}
                                  disabled
                                  onClick={handleNewPortfolio}
                                  fontSize="14px"
                                  width="auto"
                                />
                              </div>
                              <div className="">
                                <ETButton
                                  variant={"primary"}
                                  buttonText="CREATE PROPERTY"
                                  rotate={rotate}
                                  icon={true}
                                  onClick={handleAddCompany}
                                  width="auto"
                                />
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </>
                )}
                // muiTableHeadCellProps={{
                //   elevation: 0,
                //   // sx: {
                //   //   fontSize: "13px",
                //   //   fontWeight: "500",
                //   //   fontFamily: "Cerebri sans-medium  !important",
                //   //   boxShadow: "none",
                //   // },
                // }}
                muiTableContainerProps={{
                  elevation: 0,
                  sx: {
                    // paddingLeft: "24px",
                    // paddingRight: "24px",
                    padding: "0px !important",
                    paddingBottom: "10px !important",
                    overflow: "scroll",
                    boxShadow: "none",
                    ".css-vv34gr-MuiTableRow-root": {
                      boxShadow: "unset !important",
                    },
                  },
                }}
                muiTableBodyProps={{
                  elevation: 0,
                  sx: {
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: "#F9FAFA",
                      borderRadius: "8px",
                    },
                    td: {
                      fontSize: "13px !important",
                      fontFamily: "Cerebri sans-light !important",
                      color: "#000000 !important",
                    },
                  },
                }}
                renderRowActions={({ row, table }) => (
                  <Menus
                    itemheight={24}
                    itemwidth={24}
                    iconborderradius="4px"
                    itemcontainerwidth="150px"
                    component={
                      portfoliotypeStore === "D"
                        ? [
                            <>
                              {usedFor == "PL" ? (
                                <MenuItem
                                  key="edit"
                                  onClick={() =>
                                    handleEditProperty(
                                      row.original.property_id,
                                      row.original.country_id,
                                      "edit"
                                    )
                                  }
                                  sx={{
                                    fontSize: "13px",
                                    fontFamily: "Cerebri sans-light !important",
                                  }}
                                >
                                  Edit Property details
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  key="go_to_property"
                                  sx={{
                                    fontSize: "13px",
                                    fontFamily: "Cerebri sans-light !important",
                                  }}
                                  onClick={() =>
                                    handleLinkProperty(row.original.property_id)
                                  }
                                >
                                  Go to property
                                </MenuItem>
                              )}
                              <hr className="hr m-0"></hr>
                              <MenuItem
                                key="delete"
                                sx={{
                                  fontSize: "13px",
                                  fontFamily: "Cerebri sans-light !important",
                                }}
                                onClick={() => {
                                  setProperteId(row?.original?.property_id);

                                  handleEditedProperty(
                                    row.original.company_id,
                                    row.original.company_name,
                                    "edit"
                                  );
                                }}
                              >
                                Edit Property
                              </MenuItem>
                              <hr className="hr m-0"></hr>
                              <MenuItem
                                key="delete"
                                sx={{
                                  fontSize: "13px",
                                  fontFamily: "Cerebri sans-light !important",
                                }}
                                disabled={
                                  row?.original?.status?.some(
                                    (item) => item?.units !== 0
                                  )

                                  // ? // row.original.reserved == 0 ||
                                  //   // row.original.in_process == 0 ||
                                  //   // row.original.completed == 0
                                  //   false
                                  // : true
                                }
                                // disabled={true}
                                onClick={() =>
                                  deleteProperty(row?.original.property_id)
                                }
                              >
                                Delete Property
                              </MenuItem>
                            </>,
                          ]
                        : [
                            <MenuItem
                              key="delete"
                              sx={{
                                fontSize: "13px",
                                fontFamily: "Cerebri sans-light !important",
                              }}
                              disabled={
                                portfoliotypeStore === "U" &&
                                tableData?.length === 1
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                removeProperty(row.original.property_id)
                              }
                            >
                              Remove Property
                            </MenuItem>,
                          ]
                    }
                  />
                )}
                positionActionsColumn="last"
                initialState={{
                  showGlobalFilter: true,
                  showColumnFilters: false,
                }}
                rowCount={noOfRows}
                muiSelectCheckboxProps={{
                  icon: (
                    <FontAwesomeIcon
                      icon="fa-regular fa-star"
                      style={{ color: "#f6e651" }}
                    />
                  ),
                  checkedIcon: (
                    <FontAwesomeIcon
                      icon="fa-solid fa-star"
                      style={{ color: "#f6e651" }}
                    />
                  ),
                  style: {
                    color: "#FFFF00",
                    "&.Mui-checked": {
                      color: "#FFFF00",
                    },
                  },
                }}
                muiTablePaginationProps={{
                  ...paginationoptions,
                }}
                muiTablePaperProps={{
                  elevation: 0,
                  sx: {
                    fontFamily: "Cerebri sans !important",
                    borderRadius: "0",
                    border: "none",
                    background: "#FFFFFF",
                    fontWeight: "300",
                    ".css-1f2qhs8": {
                      minWidth: "auto",
                      marginBottom: "5px",
                    },
                    table: {
                      borderCollapse: "separate",
                      borderSpacing: "0 5px",
                    },
                  },
                }}
              />
            }
          />
        </div>

        <CustomPopup
          open={showPopup}
          onHide={() => setShowPopup(false)}
          // onHide={() => setShow(false)}
          title="Delete property?"
          title_color="black"
          enableCrossButton={false}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
        >
          <div className="p-3 fs-14 fw-300">
            <p>You are deleting this property.</p>
            <p>Are you sure you want to delete?</p>
          </div>
          <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => setShowPopup(false)}
            />
            <ETButton
              variant={"primary"}
              buttonText="DELETE"
              disabled={deletePropertyMutateIsLoading}
              onClick={() =>
                handleDeleteProperty(EditPropertyID, portfolioIdStore)
              }
            />
          </div>
        </CustomPopup>

        <CustomPopup
          open={removePropPopup}
          onHide={() => setRemovePropShowup(false)}
          // onHide={() => setShow(false)}
          title="Remove property?"
          title_color="black"
          enableCrossButton={false}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
        >
          <div className="p-3 fs-14 fw-300">
            <p>You are removing this property from portfolio.</p>
            <p>Are you sure you want to remove?</p>
          </div>
          <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => setRemovePropShowup(false)}
            />
            <ETButton
              variant={"primary"}
              buttonText="DELETE"
              onClick={() =>
                handleDeleteProperty(EditPropertyID, portfolioIdStore)
              }
            />
          </div>
        </CustomPopup>

        <div>
          {" "}
          <CustomPopup
            open={showWizard}
            //   onHide={() => setShow(false)}
            title={`${isPropertyEdited ? "Edit Property" : "Create Property"}`}
            titleTooltipText={`${
              isPropertyEdited ? "Edit Property" : "Create Property"
            }`}
            title_color="black"
            size={"xl"}
            titleTooltip={true}
            enableCrossButton={true}
            onClickCrossButton={() => {
              setProgress(0);
              setStepWizard("SelectDivision");
              setPropertyData({});
              setShowWizard(false);
              setIsSameDetailAsAboveActive(false);
            }}
          >
            <div>
              <StyledLoadingBar>
                <LoadingBar
                  color={customproperties.primaryColor}
                  progress={progress}
                  containerStyle={{
                    position: "absolute",
                    height: "2.5px",
                  }}
                  shadow={false}
                  onLoaderFinished={() => setProgress(0)}
                />
              </StyledLoadingBar>
            </div>
            <div>{WizardsPages[stepWizard]}</div>
          </CustomPopup>
        </div>
      </div>
    </>
  );
}
//LAZY export default PropertiesTable;
//LAZY export default MemoizedPropertiesTable = React.memo(PropertiesTable);
export const MemoizedPropertiesTable = React.memo(PropertiesTable);

export const TableDataBorderRight = () => {
  return (
    <div
      style={{
        paddingLeft: "1px",
        backgroundColor: "#d1d1d1",
        width: "1px",
        height: "34px",
      }}
    ></div>
  );
};
