import React from "react";
import GoogleMapReact from "google-map-react";
import {GoogleMapReactWrapper} from "../../../styles/Common.style"

const EventsStatsMap = () => {
  const options = {
    center: {
      lat: 55.67945641,
      lng: 12.51562935,
    },
    zoom: 10,
    // imagePath: { clientMarker },
  };



  const getMapOptions = (maps) => {
    return {
      fullscreenControl: false,
      mapTypeControl: false,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      scrollwheel: true,
      streetViewControl: true,
    };
  };

  const renderMarkers = (map, maps, myData) => {
    // console.log(map,"map");
    // console.log(maps,"maps");
    // {myData.map((i)=>{

    // })}
    new maps.Marker({
      position: {
        lat: 55.67945641,
        lng: 12.51562935,
      },
      map,
      title: "Hello World!",
    });
  };

  return (
    <div>
      <GoogleMapReactWrapper>
        <GoogleMapReact
          options={getMapOptions}
          bootstrapURLKeys={{
            key: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
          }}
          defaultCenter={options.center}
          defaultZoom={options.zoom}
          hoverDistance={30}
          // onChildMouseEnter={(key) => _onChildMouseEnter(key)}
          // onChildMouseLeave={() => setTooltipShow(false)}
          // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
          {/* {myData.map((item, i) => (
      <MarkersComponent
      key={i}
      lat={item.Latitude}
      lng={item.Longitude}
      City={item.City}
      Participants={item.Participants}
      onClick={() => {
          setMarkerData(item);
          setMarkerIndex(i);
          setShowMarker(true);
      }}
      onMouseEnter={() => {
          setMarkerData(item);
          setTooltipShow(true);
        }}
        onMouseLeave={() => {
            setTooltipShow(false);
        }}
      show={true}
      id={i}
    />
  ))} */}
        </GoogleMapReact>
      </GoogleMapReactWrapper>
    </div>
  );
};

export default EventsStatsMap;
