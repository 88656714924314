import React, { useEffect } from "react";
import CustomToolTip from "../../../../../Components/Tooltip/CustomTooltip";
import ETButton from "../../../../../Components/Buttons/ETButton";
import "./AddEconomy.css";
import { Col, Row } from "react-bootstrap";
import { ReactComponent as Greenvector } from "../../../../../assets/icons/svg_icons/greenvector.svg";
import GroupText from "../../../../../Components/GroupText/GroupText";
import { useState } from "react";
import { GetUnitEconomyDetailsByUnitId } from "../../../../../services/Units";

import {
  Button as MUIButton,
  Menu as MUIMenu,
  MenuItem as MUIMenuItem,
  Menu,
} from "@mui/material";

import {
  useEditEconomyDetail,
  useGetEditUnitsTextField,
  useGetUnitEconomyDetailsByUnitId,
  useGetUnitMaintainer,
} from "../../../../../hooks/queries/useUnitQueries";
import { useGetCurrencyRate } from "../../../../../hooks/queries/useUnitQueries";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import moment from "moment";
import ReactSelect from "../../../../../Components/DropDown/ReactSelect";
import CustomCheckBox from "../../../../../Components/Checkbox/Checkbox";
import { status } from "../../../../../model/Constant";
import { usePropertyOverview } from "../../../../../hooks/stores/usePropertyStore";
import { useUnitType } from "../../../../../hooks/stores/useUnitStore";
import { useBreadcrumb } from "../../../../../Components/BreadcrumbContext ";
import { H2, H3 } from "../../../../../styles/Common.style";
import { useUserDetails } from "../../../../../hooks/stores/useUserDetailsStore";
import { UTCtoLocalTimeZone } from "../../../../../utils/UTCtoLocalTimeZone";

export const SingleUnitEconomy = ({ UnitId, propId }) => {
  //---------------States---------------//
  const [anchorEl, setAnchorEl] = useState(null);

  const [unitId, setUnitId] = useState("");
  const [paymentFrequency, setPaymentFrequency] = useState("");
  const [rent, setRent] = useState("");
  // console.log(rent, "rent")
  const [calcDepositDEK, setCalcDepositDEK] = useState("");
  const [prepaidRent, setPrepaidRent] = useState("");
  const [customPrepaidRentDEK, setCustomPrepaidRentDEK] = useState("");
  const [depositMonths, setDepositMonths] = useState();
  const [currencyId, setCurrencyId] = useState("");
  // console.log(calcDepositDEK, "calcDepositDEK");
  // console.log(depositMonths, "depositMonths");
  // console.log(paymentFrequency, "paymentFrequency");
  // console.log(rent, "rent");

  const { updateBreadcrumbs } = useBreadcrumb();

  const { typeOfUnit } = useUnitType((state) => state);

  const propOverview = usePropertyOverview((state) => state.propOverview);
  // filter textFields data //
  //----------------------------react query---------------------//
  const { data: getUnitTextFields } = useGetEditUnitsTextField(
    propId,
    typeOfUnit
  );

  // ----------------- unit rent section-----------------------//
  const economyUnitRentData = getUnitTextFields?.data?.filter(
    (x) => x?.section_id == 301
  );

  const unitRentType1and2Data = economyUnitRentData?.filter(
    (x) =>
      x?.is_displayed !== 0 &&
      (x?.sale_type === propOverview?.[0]?.type_id || x?.sale_type === 0)
  );

  let unitRentCheckboxData = unitRentType1and2Data?.map((x) => ({
    ...x,
    checked: x.is_displayed == 1 ? true : false,
  }));

  // -------------unit operationl costs data-----------------------//
  const operationalCostsData = getUnitTextFields?.data?.filter(
    (x) => x?.section_id == 302
  );
  const operationalCosts1and2Data = operationalCostsData?.filter(
    (x) =>
      x?.is_displayed !== 0 &&
      (x?.sale_type === propOverview?.[0]?.type_id || x?.sale_type === 0)
  );
  let operationalCostsCheckbox = operationalCosts1and2Data?.map((x) => ({
    ...x,
    checked: x.is_displayed == 1 ? true : false,
  }));

  //--------------Terms and conditions ------//
  const termsAndConditionsData = getUnitTextFields?.data?.filter(
    (x) => x?.section_id == 303
  );

  const termsAndConditionsType0 = termsAndConditionsData?.filter(
    (x) =>
      x?.is_displayed == 0 &&
      (x?.sale_type === propOverview?.[0]?.type_id || x?.sale_type === 0)
  );

  const termsAndConditions1and2Data = termsAndConditionsData?.filter(
    (x) =>
      x?.is_displayed != 0 &&
      (x?.sale_type == propOverview?.[0]?.type_id || x?.sale_type === 0)
  );
  let termsAndConditionsCheckbox = termsAndConditions1and2Data?.map((x) => ({
    ...x,
    checked: x.is_displayed == 1 ? true : false,
  }));
  // console.log(termsAndConditionsCheckbox, "termsAndConditionsCheckbox");
  // console.log(economyUnitRentData, "economyUnitRentData");
  // console.log(operationalCostsData, "operationalCostsData");
  // console.log(termsAndConditionsData, "termsAndConditionsData");
  //---------------React query---------------//
  const { data: unitMaintainer, isUnitMaintainerLoading } =
    useGetUnitMaintainer();
  // console.log(unitMaintainer?.data, "unitMaintainer");
  const { data: GetCurrencyRateData, refetch: GetCurrencyRateDataRefetch } =
    useGetCurrencyRate(currencyId, rent);

  const {
    mutateAsync: PutEditEconomyDetail,
    isLoading: PutEditEconomyDetailISLoading,
  } = useEditEconomyDetail();
  // console.log(GetCurrencyRateData, 'useGetCurrencyRate')

  // useGetUnitEconomyDetailsByUnitId

  const {
    data: getUnitEconomyDetailsByUnitId,
    refetch: getUnitEconomyDetailsByUnitIdRefetch,
  } = useGetUnitEconomyDetailsByUnitId(UnitId);
  // console.log(
  //   "getUnitEconomyDetailsByUnitId",
  //   getUnitEconomyDetailsByUnitId?.data
  // );
  // useEffect(() => {
  //   UnitEconomyDetailsByUnitId();
  //   setRent(getValues("baserent"));
  //   setUnitsRentData(unitRentCheckboxData);
  //   setOperationalData(operationalCostsCheckbox);
  //   setTermsAndConditionData(termsAndConditionsCheckbox);
  // }, [UnitId]);

  //API Consumption Get Unit detail by unitId
  const [unitEconomyDetails, setUnitEconomyDetails] = useState([]);

  const [uniteconomydata, setUniteconomydata] = useState([]);

  const [error, setError] = useState({
    error: true,
    status: "",
    message: "",
  });

  //------------------validation-------------------------//
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({});
  // console.log(watch("interior_maintenance_id"), "watch");
  // --------economy rent logic -----------------------------//
  const [unitRentData, setUnitsRentData] = useState([]);
  let unitRentFilterData = unitRentData?.filter((x) => x?.checked == true);
  const unitRentClick = (e, i) => {
    const { id, checked, name } = e.currentTarget;
    let checkValue = [...unitRentData];
    if (checked) {
      checkValue[i].checked = true;
    } else {
      checkValue[i].checked = false;
    }
    setUnitsRentData(checkValue);
  };

  // ---------operationalCosts-------------//
  const [operationalData, setOperationalData] = useState([]);
  let operationalFilterData = operationalData?.filter(
    (x) => x?.checked == true
  );
  const operationalClick = (e, i) => {
    const { id, checked, name } = e.currentTarget;
    let checkValue = [...operationalData];
    if (checked) {
      checkValue[i].checked = true;
    } else {
      checkValue[i].checked = false;
    }
    setOperationalData(checkValue);
  };
  //-------------------terms and conditions-------------//
  const [termsAndConditionData, setTermsAndConditionData] = useState([]);
  let termsAndConditionFilterData = termsAndConditionData?.filter(
    (x) => x?.checked == true
  );

  let TermsAndConditionNormalField = termsAndConditionFilterData?.filter(
    (item) => item.is_mandatory != 1
  );
  let TermsAndConditionMandatoryField = termsAndConditionFilterData?.filter(
    (item) => item.is_mandatory == 1
  );

  // console.log(termsAndConditionData, "termsAndConditionData");
  const termsClick = (e, i) => {
    const { id, checked, name } = e.currentTarget;
    let checkValue = [...termsAndConditionData];
    if (checked) {
      checkValue[i].checked = true;
    } else {
      checkValue[i].checked = false;
    }
    setTermsAndConditionData(checkValue);
  };

  // const UnitEconomyDetailsByUnitId = () => {
  //   try {
  //     GetUnitEconomyDetailsByUnitId(UnitId)
  //       .then((data) => {
  //         // console.log(
  //         //   termsAndConditionFilterData[2].possible_values.filter(
  //         //     (x) => x.Id == data?.data[0]?.notice_period && x.Text
  //         //   )[0].Text,
  //         //   "Notice PeriodNotice PeriodNotice Period"
  //         // );

  //         if (data != null && data != undefined) {
  //           setUnitEconomyDetails(data?.data?.[0]);
  //           setUniteconomydata(data?.data[0]);
  //           setUnitId(data?.data[0]?.unit_id);
  //           setPaymentFrequency(data?.data[0]?.payment_frequency);
  //           setCurrencyId(data?.data[0]?.currency_id);
  //           setRent(data?.data[0]?.rent);
  //           setValue("rent", data?.data[0]?.rent);
  //           setValue("sale_price", data?.data[0]?.sale_price);
  //           setValue("rent_price", data?.data[0]?.rent_price);
  //           setValue("aconto_heat", data?.data[0]?.aconto_heat);
  //           setValue("aconto_water", data?.data[0]?.aconto_water);
  //           setValue("aconto_electricity", data?.data[0]?.aconto_electricity);
  //           setValue("fee_internet", data?.data[0]?.fee_internet);
  //           setValue("aconto_cooling", data?.data[0]?.aconto_cooling);
  //           setValue("fee_antenna", data?.data[0]?.fee_antenna);
  //           setValue(
  //             "aconto_residents_society",
  //             data?.data[0]?.aconto_residents_society
  //           );
  //           setValue("down_payment", data?.data[0]?.down_payment);
  //           setValue("payment_frequency", {
  //             value: data?.data[0]?.payment_frequency,
  //             label: paymentFrequencyDropdownData.filter(
  //               (x) => x.Id == data?.data[0]?.payment_frequency && x.Text
  //             )[0].Text,
  //           });
  //           setValue("deposit_months", data?.data[0]?.deposit_months);
  //           setValue("prepaid_months", {
  //             value: data?.data[0]?.prepaid_months,
  //             label: termsAndConditionFilterData[1].possible_values.filter(
  //               (x) => x.Id == data?.data[0]?.prepaid_months && x.Text
  //             )[0].Text,
  //           });
  //           setValue("non_terminability_period_months", {
  //             value: data?.data[0]?.non_terminability_period_months,
  //             label: termsAndConditionFilterData[2].possible_values.filter(
  //               (x) =>
  //                 x.Id == data?.data[0]?.non_terminability_period_months &&
  //                 x.Text
  //             )[0].Text,
  //           });
  //           setValue("rental_period", {
  //             value: data?.data[0]?.rental_period,
  //             label: termsAndConditionFilterData[3].possible_values.filter(
  //               (x) => x.Id == data?.data[0]?.rental_period && x.Text
  //             )[0].Text,
  //           });
  //           setValue("notice_period", {
  //             value: data?.data[0]?.notice_period,
  //             label: termsAndConditionFilterData[4].possible_values.filter(
  //               (x) => x.Id == data?.data[0]?.notice_period && x.Text
  //             )[0].Text,
  //           });
  //           setValue("interior_maintenance_id", {
  //             value: data?.data[0]?.interior_maintenance_id,
  //             label: termsAndConditionData
  //               ?.filter(
  //                 (x) => x.column_name === "interior_maintenance_id"
  //               )?.[0]
  //               ?.possible_values?.filter(
  //                 (x) => x.Id === data?.data?.[0]?.interior_maintenance_id
  //               )?.[0]?.Text,
  //           });
  //           setValue("exterior_maintenance_id", {
  //             value: data?.data?.[0]?.exterior_maintenance_id,
  //             label: termsAndConditionData
  //               ?.filter(
  //                 (x) => x.column_name === "exterior_maintenance_id"
  //               )?.[0]
  //               ?.possible_values?.filter(
  //                 (x) => x.Id === data?.data?.[0]?.exterior_maintenance_id
  //               )?.[0]?.Text,
  //           });
  //           setRent(data?.data[0]?.rent);
  //           // console.log(data?.data[0]?.rent, "data?.data[0]?.rent");
  //           setDepositMonths(data?.data[0]?.deposit_months);
  //           setPrepaidRent(data?.data[0]?.prepaid_months);
  //         } else {
  //           setUnitEconomyDetails([null]);
  //         }
  //       })
  //       .catch((error) => {
  //         setError({ error: error.message, status: error.status });
  //       });
  //   } catch (e) {
  //     setError({
  //       error: true,
  //       status: "",
  //       message: "Failed to retrieve data",
  //     });
  //   }
  // };
  useEffect(() => {
    // console.log(termsAndConditionsCheckbox, "termsAndConditionsCheckbox");
    // console.log(
    //   getUnitEconomyDetailsByUnitId?.data?.[0],
    //   "getUnitEconomyDetailsByUnitId?.data"
    // );
    // console.log(
    //   termsAndConditionsCheckbox,
    //   "termsAndConditionsCheckbox useeffect"
    // );
    // console.log(
    //   termsAndConditionFilterData,
    //   "termsAndConditionFilterData useeffect"
    // );
    setRent(getValues("baserent"));
    setUnitsRentData(unitRentCheckboxData);
    setOperationalData(operationalCostsCheckbox);
    setTermsAndConditionData(termsAndConditionsCheckbox);

    setUnitEconomyDetails(getUnitEconomyDetailsByUnitId?.data?.[0]);
    setUniteconomydata(getUnitEconomyDetailsByUnitId?.data?.[0]);
    setUnitId(getUnitEconomyDetailsByUnitId?.data[0]?.unit_id);
    setPaymentFrequency(
      getUnitEconomyDetailsByUnitId?.data[0]?.payment_frequency
    );
    setCurrencyId(getUnitEconomyDetailsByUnitId?.data[0]?.currency_id);
    setValue("rent", getUnitEconomyDetailsByUnitId?.data[0]?.rent);
    setValue("sale_price", getUnitEconomyDetailsByUnitId?.data[0]?.sale_price);
    setValue("rent_price", getUnitEconomyDetailsByUnitId?.data[0]?.rent_price);
    setValue(
      "aconto_heat",
      getUnitEconomyDetailsByUnitId?.data[0]?.aconto_heat
    );
    setValue(
      "aconto_water",
      getUnitEconomyDetailsByUnitId?.data[0]?.aconto_water
    );
    setValue(
      "aconto_electricity",
      getUnitEconomyDetailsByUnitId?.data[0]?.aconto_electricity
    );
    setValue(
      "fee_internet",
      getUnitEconomyDetailsByUnitId?.data[0]?.fee_internet
    );
    setValue(
      "aconto_cooling",
      getUnitEconomyDetailsByUnitId?.data[0]?.aconto_cooling
    );
    setValue(
      "fee_antenna",
      getUnitEconomyDetailsByUnitId?.data[0]?.fee_antenna
    );
    setValue(
      "aconto_residents_society",
      getUnitEconomyDetailsByUnitId?.data[0]?.aconto_residents_society
    );
    setValue(
      "down_payment",
      getUnitEconomyDetailsByUnitId?.data[0]?.down_payment
    );
    if (getUnitEconomyDetailsByUnitId?.data[0]?.payment_frequency == "0") {
      setValue("payment_frequency", "");
    } else if (
      getUnitEconomyDetailsByUnitId?.data[0]?.payment_frequency != ""
    ) {
      setValue("payment_frequency", {
        value: getUnitEconomyDetailsByUnitId?.data[0]?.payment_frequency,
        label: paymentFrequencyDropdownData?.filter(
          (x) =>
            x?.Id ==
              getUnitEconomyDetailsByUnitId?.data[0]?.payment_frequency &&
            x?.Text
        )[0]?.Text,
      });
    } else {
      setValue("payment_frequency", "");
    }
    //  console.log(
    //       economyUnitRentData
    //         .filter((item) => item?.column_name == "payment_frequency")?.[0]
    //         ?.possible_values?.filter(
    //           (x) =>
    //             x?.Id ==
    //               getUnitEconomyDetailsByUnitId?.data[0]?.payment_frequency &&
    //             x?.Text
    //         )?.[0]?.Text,
    //       "economyUnitRentData"
    //     );
    setValue(
      "deposit_months",
      getUnitEconomyDetailsByUnitId?.data?.[0]?.deposit_months
    );
    setValue("prepaid_months", {
      value: getUnitEconomyDetailsByUnitId?.data?.[0]?.prepaid_months,
      label: termsAndConditionsCheckbox
        ?.filter((value) => value?.column_name == "prepaid_months")?.[0]
        ?.possible_values?.filter(
          (x) =>
            x?.Id == getUnitEconomyDetailsByUnitId?.data?.[0]?.prepaid_months &&
            x?.Text
        )[0]?.Text,
    });

    setValue("non_terminability_period_months", {
      value:
        getUnitEconomyDetailsByUnitId?.data?.[0]
          ?.non_terminability_period_months,
      label: termsAndConditionsCheckbox
        ?.filter(
          (value) => value?.column_name == "non_terminability_period_months"
        )?.[0]
        ?.possible_values?.filter(
          (x) =>
            x?.Id ==
              getUnitEconomyDetailsByUnitId?.data?.[0]
                ?.non_terminability_period_months && x?.Text
        )[0]?.Text,
    });
    setValue("rental_period", {
      value: getUnitEconomyDetailsByUnitId?.data?.[0]?.rental_period,
      label: termsAndConditionsCheckbox
        ?.filter((value) => value?.column_name == "rental_period")?.[0]
        ?.possible_values?.filter(
          (x) =>
            x?.Id == getUnitEconomyDetailsByUnitId?.data?.[0]?.rental_period &&
            x?.Text
        )[0]?.Text,
    });
    setValue("notice_period", {
      value: getUnitEconomyDetailsByUnitId?.data?.[0]?.notice_period,
      label: termsAndConditionsCheckbox
        ?.filter((value) => value?.column_name == "notice_period")?.[0]
        ?.possible_values?.filter(
          (x) =>
            x?.Id == getUnitEconomyDetailsByUnitId?.data?.[0]?.notice_period &&
            x?.Text
        )[0]?.Text,
    });

    // Used for interior_maintenance_id
    if (
      getUnitEconomyDetailsByUnitId?.data?.[0]?.interior_maintenance_id != "0"
    ) {
      const interior_maintenance_id =
        getUnitEconomyDetailsByUnitId?.data?.[0]?.interior_maintenance_id;
      setValue("interior_maintenance_id", {
        value: interior_maintenance_id,
        label: termsAndConditionsCheckbox
          ?.filter(
            (value) => value?.column_name == "interior_maintenance_id"
          )?.[0]
          ?.possible_values?.filter((x) => x?.Id == interior_maintenance_id)[0]
          ?.Text,
      });
    } else {
      setValue("interior_maintenance_id", "");
    }

    // Used for exterior_maintenance_id
    if (
      getUnitEconomyDetailsByUnitId?.data?.[0]?.exterior_maintenance_id != "0"
    ) {
      const exterior_maintenance_id =
        getUnitEconomyDetailsByUnitId?.data?.[0]?.exterior_maintenance_id;
      setValue("exterior_maintenance_id", {
        value: exterior_maintenance_id,
        label: termsAndConditionsCheckbox
          ?.filter(
            (value) => value?.column_name == "exterior_maintenance_id"
          )?.[0]
          ?.possible_values?.filter((x) => x?.Id == exterior_maintenance_id)[0]
          ?.Text,
      });
    } else {
      setValue("exterior_maintenance_id", "");
    }

    setRent(getUnitEconomyDetailsByUnitId?.data?.[0]?.rent);
    setDepositMonths(getUnitEconomyDetailsByUnitId?.data?.[0]?.deposit_months);
    setPrepaidRent(getUnitEconomyDetailsByUnitId?.data?.[0]?.prepaid_months);
  }, [
    getUnitEconomyDetailsByUnitId,
    getUnitTextFields,
    unitMaintainer,
    UnitId,
  ]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [addAnchorEl, setAddAnchorEl] = useState(false);
  const handleClickAdd = (event) => {
    setAddAnchorEl(event.currentTarget);
    setDropdownOpen(true);
  };
  const handleCloseAdd = () => {
    setAddAnchorEl(null);
    setDropdownOpen(false);
  };
  useEffect(() => {
    setCalcDepositDEK(
      (parseInt(depositMonths) / parseInt(paymentFrequency)) * parseInt(rent)
    );
    setCustomPrepaidRentDEK(
      (parseInt(prepaidRent) / parseInt(paymentFrequency)) * parseInt(rent)
    );
  }, [depositMonths, rent, paymentFrequency, prepaidRent]);

  useEffect(() => {
    // getUnitEconomyDetailsByUnitIdRefetch();
  }, [unitRentData, operationalData, UnitId]);

  useEffect(() => {
    updateBreadcrumbs(["Economy"]);
  }, []);

  //------------------validation-------------------------//
  const handleEconomySave = async (data) => {
    // console.log(data, "dtatatatatatat");
    setRent(data?.rent ? data?.rent : "0");
    GetCurrencyRateDataRefetch();
    const packageTitlePayload = {
      unit_id: unitId,
      payment_frequency: data?.payment_frequency?.value.toString()
        ? data?.payment_frequency?.value.toString()
        : "0",
      rent: data?.rent ? data?.rent : "0",
      aconto_heat: data?.aconto_heat ? data?.aconto_heat : "0",
      aconto_water: data?.aconto_water ? data?.aconto_water : "0",
      aconto_electricity: data?.aconto_electricity
        ? data?.aconto_electricity
        : "0",
      fee_internet: data?.fee_internet ? data?.fee_internet : "0",
      deposit_months: data?.deposit_months.toString()
        ? data?.deposit_months.toString()
        : "0",
      prepaid_months: data?.prepaid_months?.value.toString()
        ? data?.prepaid_months?.value.toString()
        : "0",
      rental_period: data?.rental_period?.value.toString()
        ? data?.rental_period?.value.toString()
        : "0",
      notice_period: data?.notice_period?.value.toString()
        ? data?.notice_period?.value.toString()
        : "0",
      interior_maintenance_id: data?.interior_maintenance_id?.value?.toString(),
      exterior_maintenance_id: data?.exterior_maintenance_id?.value?.toString(),
      sale_price: data?.sale_price ? data?.sale_price : "0",
      // rent_price: data?.rent_price ? data?.rent_price : "",
      aconto_cooling: data?.aconto_cooling ? data?.aconto_cooling : "0",
      fee_antenna: data?.fee_antenna ? data?.fee_antenna : "0",
      aconto_residents_society: data?.aconto_residents_society
        ? data?.aconto_residents_society
        : "0",
      non_terminability_period_months:
        data?.non_terminability_period_months?.value.toString()
          ? data?.non_terminability_period_months?.value.toString()
          : "0",
      down_payment: data?.down_payment ? data?.down_payment : "0",
    };

    // const packageTitlePayload = {
    //   unit_id: unitId,
    //   payment_frequency: data?.payment_frequency.value.toString(),
    //   rent: parseInt(data?.rent),
    //   aconto_heat: data?.aconto_heat,
    //   aconto_water: data?.aconto_water,
    //   aconto_electricity: data?.aconto_electricity,
    //   fee_internet: data?.fee_internet,
    //   deposit_months: data?.deposit_months.toString(),
    //   prepaid_months: data?.prepaid_months.value.toString(),
    //   rental_period: data?.rental_period.value.toString(),
    //   notice_period: data?.notice_period.value.toString(),
    //   interior_maintenance_id: data?.interior_maintenance_id.value.toString(),
    //   exterior_maintenance_id: data?.exterior_maintenance_id.value.toString(),
    //   sale_price:data?.sale_price,
    //   rent_price:data?.rent_price,
    //   aconto_cooling:data?.aconto_cooling,
    //   fee_antenna:data?.fee_antenna,
    //   aconto_residents_society:data?.aconto_residents_society,
    //   non_terminability_period_months:data?.non_terminability_period_months.value.toString(),
    //   down_payment:data?.down_payment,
    // };
    // console.log(packageTitlePayload, "packageTitlePayload");
    try {
      const response = await PutEditEconomyDetail(packageTitlePayload);
      if (response.status == status.s && response.data.bReturn) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  // Currency Converter
  const handleCurrencyConverter = (e) => {
    setRent(e?.target?.value);
    if (e?.target?.value?.length) {
      GetCurrencyRateDataRefetch();
    }
  };
  //payment frequency //
  const paymentFrequencyChange = (e, ActionTypes) => {
    if (ActionTypes.action === "clear") {
      setPaymentFrequency("");
    } else {
      setPaymentFrequency(e?.value);
    }
  };

  // const modifiedOn = moment(uniteconomydata?.modified_on).format(
  //   "DD.MMMM.YYYY | HH:mm:ss"
  // );
  // const createdOn = moment(uniteconomydata?.created_on).format(
  //   "DD.MMMM.YYYY | HH:mm:ss"
  // );

  console.log("ED", uniteconomydata);

  const { userDetails } = useUserDetails((state) => state);
  const { convertedDate: createdOn } = UTCtoLocalTimeZone({
    timestamp: uniteconomydata?.created_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });
  const { convertedDate: modifiedOn } = UTCtoLocalTimeZone({
    timestamp: uniteconomydata?.modified_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });

  return (
    <div className="radius-8 fs-14 bg-white  mainborder">
      <div className="d-flex justify-content-between px-4  pt-3">
        <div className="d-flex flex-column ">
          <div className="d-flex align-items-center">
            <H2>Economy</H2>
            <span className="ms-2">
              <CustomToolTip
                text="Unit details"
                size="md"
              />
            </span>
          </div>
          {uniteconomydata?.modified_by && uniteconomydata?.modified_on ? (
            <div className="fs-12 created-by">
              Last updated by &nbsp;{uniteconomydata?.modified_by},&nbsp;
              {modifiedOn}
            </div>
          ) : (
            <>
              {uniteconomydata?.created_by && uniteconomydata?.created_on ? (
                <div className="fs-12 created-by">
                  Created by &nbsp;{uniteconomydata?.created_by},&nbsp;
                  {createdOn}
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </div>
        <div>
          <ETButton
            buttonText="ADD FIELDS"
            variant={"secondary"}
            icon={true}
            width="fit-content"
            onClick={handleClickAdd}
          />
          <Menu
            id="simple-menu"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={addAnchorEl}
            open={addAnchorEl}
            onClose={handleCloseAdd}
            elevation={0}
            HorizontalAlignment="left"
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              style: {
                borderRadius: "8px",
                marginTop: "6px",
                border: "0.5px solid #F0F0F0",
                boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.12)",
                paddingTop: "0px",
              },
            }}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.02)",
              "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                padding: 0,
                borderColor: "#F0F0F0",
              },
            }}
          >
            <div
              className="px-3 py-3  fs-14 radius-8"
              style={{
                width: "300px",
                height: "267px",
                // overflowX: "hidden",
                // overflowY: "scroll",
              }}
            >
              <div>
                <div className="form-label-title ps-2">Unit rent</div>
                {unitRentData?.map((item, i) => {
                  return (
                    <div
                      className="d-flex py-1 "
                      key={item?.field_id}
                    >
                      <CustomCheckBox
                        height="16px"
                        id={item?.field_id}
                        name={item?.field_label}
                        checked={item?.checked}
                        onChange={(e) => unitRentClick(e, i)}
                      />
                      <div className="ps-1 d-flex align-items-center light-font-text">
                        {item?.field_label}
                      </div>
                    </div>
                  );
                })}

                <div className="form-label-title ps-2 pt-3">
                  Operational costs
                </div>
                {operationalData?.map((item, i) => {
                  return (
                    <div
                      className="d-flex py-1 "
                      key={item?.field_id}
                    >
                      <CustomCheckBox
                        height="16px"
                        id={item?.field_id}
                        name={item?.field_label}
                        checked={item?.checked}
                        onChange={(e) => operationalClick(e, i)}
                      />
                      <div className="ps-1 d-flex align-items-center light-font-text">
                        {item?.field_label}
                      </div>
                    </div>
                  );
                })}
                <div className="form-label-title ps-2 pt-3">
                  Terms and conditions
                </div>
                {termsAndConditionData?.map((item, i) => {
                  return (
                    <div
                      className="d-flex py-1 "
                      key={item?.system_label_id}
                    >
                      <CustomCheckBox
                        height="16px"
                        id={item?.system_label_id}
                        name={item?.field_label}
                        checked={item?.checked}
                        onChange={(e) => termsClick(e, i)}
                      />
                      <div className="ps-1 d-flex align-items-center light-font-text">
                        {item?.field_label}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Menu>
        </div>
      </div>
      <hr className="hr" />

      <form onSubmit={handleSubmit(handleEconomySave)}>
        <div>
          <div className="form-label-title ps-4 pb-2 fs-14">
            {propOverview?.[0]?.type_id == 2 ? "Unit rent" : "Unit sale"}
          </div>
          {propOverview?.[0]?.type_id == 2 ? (
            <Row className="ms-1 me-2 px-2">
              <Col
                xl={4}
                lg={6}
                className="my-2  pe-xl-1"
              >
                <Row>
                  <Col
                    xl={10}
                    lg={10}
                    className="pe-xl-0 pe-lg-0"
                  >
                    <Controller
                      {...register("payment_frequency")}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder="Payment frequency"
                          clearable={false}
                          iserror={errors.payment_frequency}
                          value={value}
                          onChange={(e, ActionTypes) => {
                            onChange(e);
                            paymentFrequencyChange(e, ActionTypes);
                          }}
                          options={paymentFrequencyDropdownData?.map((x) => ({
                            value: x?.Id,
                            label: x?.Text,
                          }))}
                        />
                      )}
                    />
                    {/* {errors.payment_frequency && (
                      <span className="error-msg">This field is required</span>
                    )} */}
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Row className="me-1 ms-2 ps-1">
            <Col
              xl={4}
              lg={6}
            >
              {unitRentFilterData?.map((item, i) => {
                return (
                  <Row key={i}>
                    <Col
                      lg={11}
                      sm={11}
                      className="my-2 pe-xl-2 pe-lg-3 "
                    >
                      <Controller
                        control={control}
                        {...register(`${item?.column_name}`)}
                        rules={{
                          required: item?.is_mandatory == 0 ? false : true,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <GroupText
                            lable={item?.field_label}
                            type={item?.field_type}
                            name={item?.field_label}
                            id={item?.column_name}
                            // graylabel={`DKK/${
                            //   paymentFrequency == 1
                            //     ? "monthly"
                            //     : paymentFrequency == 3
                            //     ? "quarterly"
                            //     : " "
                            // }`}
                            graylabel={unitEconomyDetails?.currency_id}
                            textWidth="40%"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors(`${item?.column_name}`);
                            }}
                          />
                        )}
                      />
                    </Col>
                    {item?.column_name == "rent" && (
                      <Col
                        sm={1}
                        className="px-0 d-flex"
                      >
                        <MUIButton
                          sx={{
                            color: "#000",
                            float: "left",
                            padding: "0px",
                            minWidth: "max-content",
                          }}
                          id="demo-positioned-button"
                          aria-controls={
                            open ? "demo-positioned-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                        >
                          <Greenvector />
                        </MUIButton>
                        <MUIMenu
                          sx={{
                            marginLeft: "15px",
                            "& .MuiPaper-root": {
                              border: "1px solid #F0F0F0",
                              borderRadius: "8px",
                              backgroundColor: "#FFFFFF",
                            },
                            "& .MuiMenuItem-root:hover": {
                              background: "none",
                            },
                          }}
                          elevation={0}
                          id="demo-positioned-menu"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "top",
                          }}
                        >
                          <MUIMenuItem onClick={handleClose}>
                            <div
                              style={{
                                lineHeight: "20px",
                                backgroundColor: "#FFFFFF",
                                padding: "4px 10px 4px",
                                fontFamily: " Cerebri sans-light",
                              }}
                            >
                              <div>
                                {GetCurrencyRateData?.data?.map((data, i) => {
                                  return (
                                    <div
                                      className="d-flex my-1"
                                      key={i}
                                    >
                                      <div className="pe-1"></div>
                                      <div className="px-1 fs-14 created-by ">
                                        {data?.to_currency}
                                      </div>
                                      <div className="px-1 fs-14">
                                        {data?.currency_rate}
                                      </div>
                                    </div>
                                  );
                                })}
                                <div className="mt-3 fs-12 created-by">
                                  Last updated{" "}
                                  {moment(
                                    GetCurrencyRateData?.data[
                                      GetCurrencyRateData?.data?.length - 1
                                    ]?.last_update_date
                                  ).format("DD. MMM. YYYY | HH:mm:ss")}
                                </div>
                              </div>
                            </div>
                          </MUIMenuItem>
                        </MUIMenu>
                      </Col>
                    )}
                  </Row>
                );
              })}
            </Col>
          </Row>
        </div>

        {operationalData?.length !== 0 && (
          <>
            <hr className="hr" />
            <div className="ms-2">
              <div className="form-label-title ps-3 pb-2 fs-14">
                Operational costs
              </div>
              {operationalFilterData?.length !== 0 ? (
                <>
                  <Row className="ps-3 pe-lg-2 pe-3 ">
                    <Col
                      lg={10}
                      xl={7}
                      className="pe-lg-0"
                    >
                      <Row>
                        {operationalFilterData?.map((item, i) => {
                          return (
                            <Col
                              lg={6}
                              className="my-2  pe-xl-2 pe-lg-0"
                              key={i}
                            >
                              <Controller
                                control={control}
                                {...register(`${item?.column_name}`)}
                                rules={{
                                  required:
                                    item?.is_mandatory == 0 ? false : true,
                                }}
                                render={({ field: { onChange, value } }) =>
                                  item?.suffix !== "" ? (
                                    <GroupText
                                      lable={item?.field_label}
                                      type={item?.field_type}
                                      name={item?.field_label}
                                      id={item?.column_name}
                                      // graylabel={`DKK/${
                                      //   paymentFrequency == 1
                                      //     ? "monthly"
                                      //     : paymentFrequency == 3
                                      //     ? "quarterly"
                                      //     : " "
                                      // }`}
                                      graylabel={`${
                                        unitEconomyDetails?.currency_id
                                      }/${
                                        economyUnitRentData
                                          ?.filter(
                                            (x) =>
                                              x.column_name ===
                                              "payment_frequency"
                                          )?.[0]
                                          ?.possible_values?.filter(
                                            (y) =>
                                              y.Id ==
                                              watch("payment_frequency")?.value
                                          )?.[0]?.Text
                                      }`}
                                      textWidth="40%"
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        // setInternetFee(e.target.value);
                                        clearErrors(`${item?.column_name}`);
                                      }}
                                    />
                                  ) : (
                                    <div className="form-input pe-2">
                                      <input
                                        type={item?.field_type}
                                        placeholder={item?.field_label}
                                        id={item?.column_name}
                                        name={item?.field_label}
                                        disabled={
                                          item?.is_non_editable == 1
                                            ? true
                                            : false
                                        }
                                        value={value}
                                        onChange={(e) => {
                                          onChange(e);
                                          clearErrors(`${item?.column_name}`);
                                        }}
                                        // onChange={(e) => handleUnitIdentificationChange(e, item)}
                                      />
                                      <label htmlFor={item?.column_name}>
                                        {item?.field_label}
                                      </label>
                                    </div>
                                  )
                                }
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row>
                </>
              ) : (
                <div
                  className="fs-12 created-by"
                  style={{ textAlign: "center" }}
                >
                  You do not have any Operational costs yet. Click on “+ ADD
                  FIELDS” to create your first.
                </div>
              )}
            </div>
          </>
        )}
        {termsAndConditionData?.length !== 0 && (
          <>
            <hr className="hr" />
            <div className="ms-2">
              <div className="form-label-title  ps-3 pb-2">
                Terms and conditions
              </div>

              <Row className="ps-3 ">
                {termsAndConditionFilterData?.length !== 0 ? (
                  <>
                    <Col
                      lg={9}
                      xl={7}
                    >
                      <Row>
                        {termsAndConditionsType0?.map((item, i) => {
                          return (
                            <Col
                              lg={6}
                              className="mb-2 pe-lg-3 my-2 pe-4"
                              key={i}
                            >
                              <Controller
                                control={control}
                                {...register(`${item?.column_name}`)}
                                rules={{
                                  required:
                                    item?.is_mandatory == 0 ? false : true,
                                }}
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    {item?.field_type == "Dropdown" ? (
                                      <ReactSelect
                                        placeholder={item?.field_label}
                                        value={value}
                                        setPlaceholderPosition={true}
                                        options={
                                          item?.possible_values?.length > 1
                                            ? item?.possible_values.map(
                                                (x) => ({
                                                  value: x?.Id,
                                                  label: x?.Text,
                                                })
                                              )
                                            : paymentFrequencyDropdownData.map(
                                                (x) => ({
                                                  value: x?.Id,
                                                  label: x?.Text,
                                                })
                                              )
                                        }
                                        onChange={(e) => {
                                          onChange(e);
                                        }}
                                      />
                                    ) : (
                                      <div className="form-input ">
                                        <input
                                          type={item?.field_type}
                                          placeholder={item?.field_label}
                                          id={item?.column_name}
                                          name={item?.field_label}
                                          disabled={
                                            item?.is_non_editable == 1
                                              ? true
                                              : false
                                          }
                                          value={value}
                                          onChange={(e) => {
                                            onChange(e);
                                            clearErrors(`${item?.column_name}`);
                                          }}
                                          // onChange={(e) => handleUnitIdentificationChange(e, item)}
                                        />
                                        <label htmlFor={item?.column_name}>
                                          {item?.field_label}
                                        </label>
                                      </div>
                                    )}
                                  </>
                                )}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                  </>
                ) : (
                  <div
                    className="fs-12 created-by"
                    style={{ textAlign: "center" }}
                  >
                    You do not have any Terms and conditions yet. Click on “+
                    ADD FIELDS” to create your first.
                  </div>
                )}
              </Row>
              <Row className="ps-3 pb-4 mb-3">
                <Col
                  lg={9}
                  xl={7}
                >
                  <Row>
                    {TermsAndConditionNormalField?.map((item, i) => {
                      return (
                        <>
                          <Col
                            lg={6}
                            className="mb-2 pe-lg-3 my-2 pe-4"
                            key={i}
                          >
                            <Controller
                              control={control}
                              {...register(`${item?.column_name}`)}
                              rules={{
                                required:
                                  item?.is_mandatory == 0 ? false : true,
                              }}
                              render={({ field: { onChange, value } }) => (
                                <>
                                  {item?.field_type == "Dropdown" ? (
                                    <>
                                      <ReactSelect
                                        placeholder={item?.field_label}
                                        value={value}
                                        setPlaceholderPosition={true}
                                        options={item?.possible_values?.map(
                                          (x) => ({
                                            value: x?.Id,
                                            label: x?.Text,
                                          })
                                        )}
                                        onChange={(e) => {
                                          onChange(e);
                                          item?.column_name ==
                                            "prepaid_months" &&
                                            setPrepaidRent(e?.value);
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <div className="form-input ">
                                      <input
                                        type={item?.field_type}
                                        placeholder={item?.field_label}
                                        id={item?.column_name}
                                        name={item?.field_label}
                                        disabled={
                                          item?.is_non_editable == 1
                                            ? true
                                            : false
                                        }
                                        value={value}
                                        onChange={(e) => {
                                          item?.column_name ==
                                            "deposit_months" &&
                                            setDepositMonths(e.target?.value);
                                          onChange(e);
                                          clearErrors(`${item?.column_name}`);
                                        }}
                                        // onChange={(e) => handleUnitIdentificationChange(e, item)}
                                      />
                                      <label htmlFor={item?.column_name}>
                                        {item?.field_label}
                                      </label>
                                    </div>
                                  )}
                                </>
                              )}
                            />
                            {/* {errors.exterior_maintenance_id && (
                              <span className="error-msg">
                                This field is required
                              </span>
                            )} */}
                          </Col>
                          {item?.column_name == "deposit_months" && (
                            <Col
                              lg={6}
                              className="mb-2 pe-lg-1 my-2"
                            >
                              <GroupText
                                lable="Calculated deposit"
                                graylabel={unitEconomyDetails?.currency_id}
                                textWidth="20%"
                                value={parseFloat(calcDepositDEK).toFixed(2)}
                                disabled={true}
                              />
                            </Col>
                          )}
                          {item?.column_name == "prepaid_months" && (
                            <Col
                              lg={6}
                              className="mb-2 pe-lg-1 my-2"
                            >
                              <GroupText
                                lable="Custom prepaid rent"
                                graylabel={unitEconomyDetails?.currency_id}
                                textWidth="20%"
                                value={parseFloat(customPrepaidRentDEK).toFixed(
                                  2
                                )}
                                disabled={true}
                              />
                            </Col>
                          )}
                        </>
                      );
                    })}

                    {/* below code used to show mandatory fields */}
                    {TermsAndConditionMandatoryField?.map((item, i) => {
                      return (
                        <>
                          {item?.field_id == 44 && (
                            <Col
                              lg={6}
                              className="mb-2 pe-lg-3 my-2 pe-4"
                              key={i}
                            >
                              <Controller
                                control={control}
                                {...register("interior_maintenance_id")}
                                rules={{
                                  required: true,
                                }}
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    <ReactSelect
                                      placeholder={item?.field_label}
                                      value={value || ""}
                                      setPlaceholderPosition={true}
                                      options={item?.possible_values.map(
                                        (x) => ({
                                          value: x?.Id,
                                          label: x?.Text,
                                        })
                                      )}
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                    />
                                  </>
                                )}
                              />
                              {errors.interior_maintenance_id && (
                                <span className="error-msg">
                                  This field is required
                                </span>
                              )}
                            </Col>
                          )}
                          {item?.field_id == 45 && (
                            <Col
                              lg={6}
                              className="mb-2 pe-lg-3 my-2 pe-4"
                              key={i}
                            >
                              <Controller
                                control={control}
                                {...register("exterior_maintenance_id")}
                                rules={{
                                  required: true,
                                }}
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    <ReactSelect
                                      placeholder={item?.field_label}
                                      value={value}
                                      setPlaceholderPosition={true}
                                      options={item?.possible_values.map(
                                        (x) => ({
                                          value: x?.Id,
                                          label: x?.Text,
                                        })
                                      )}
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                    />
                                  </>
                                )}
                              />
                              {errors.exterior_maintenance_id && (
                                <span className="error-msg">
                                  This field is required
                                </span>
                              )}
                            </Col>
                          )}
                        </>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </div>
          </>
        )}
        <div className="py-2 px-3 mt-2 d-sm-flex justify-content-between footer-section-button-container">
          <div className="m-1">
            <NavLink
              to="/property-units/units/residential"
              state={{ propId: propId, Tab: "Units" }}
              // style={{ textDecoration: "none", color: "black" }}
            >
              <ETButton
                variant={"white"}
                buttonText="BACK TO UNITS"
              />
            </NavLink>
          </div>
          <div className="m-1">
            <ETButton
              disabled={PutEditEconomyDetailISLoading}
              variant={"primary"}
              buttonText="SAVE"
              type="submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

const paymentFrequencyDropdownData = [
  {
    Id: 1,
    Text: "Monthly",
  },
  {
    Id: 3,
    Text: "Quarterly",
  },
  // {
  //   Id: 6,
  //   Text: "Half yearly",
  // },
  // {
  //   Id: 12,
  //   Text: "Yearly",
  // },
];
const prepaidMonthsDropdownData = [
  {
    Id: 0,
    Text: "Unlimited",
  },
  {
    Id: 1,
    Text: "1 month",
  },
  {
    Id: 2,
    Text: "2 months",
  },
  {
    Id: 3,
    Text: "3 months",
  },
  {
    Id: 6,
    Text: "6 months",
  },
  {
    Id: 12,
    Text: "12 months",
  },
  {
    Id: 24,
    Text: "24 months",
  },
  {
    Id: 36,
    Text: "36 months",
  },
];

const contractDeterminationDropdownData = [
  {
    Id: 1,
    Text: "1 month",
  },
  {
    Id: 3,
    Text: "3 months",
  },
  {
    Id: 6,
    Text: "6 months",
  },
  {
    Id: 12,
    Text: "12 months",
  },
];
