import React, { useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropertyTagToolTip } from "../../assets/icons/icons";
import MRTTheme from "../../Components/table/MRTTheme";
import Menus from "../../Components/Menus/Menus";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import ETButton from "../../Components/Buttons/ETButton";
import { SortIcon } from "../../Components/table/MRTGlobalProps";

const AddParticipantsTable = ({
  setDeleteRegistration,
  setopenAddParticipantsPopup,
}) => {
  const [favorite, setFavorite] = useState([
    { id: 1, status: false },
    { id: 2, status: false },
    { id: 3, status: false },
    { id: 4, status: false },
    { id: 4, status: false },
  ]);
  const handleFavorite = (index, status) => {
    let newArray = [...favorite];
    newArray[index].status = !status;
  };
  const RegistrationData = [
    {
      name: "Mads Johnson",
      phone_no: "32 16 32 16",
      email: "hello@estatetool.dk",
      rating: "",
      responsible_agent: "Max Wildersans Brentford",
      Reg_date: "24.Feb, 2023",
      Follow_up: "24 Feb,",
      participated: "CONFIRM",
    },
    {
      name: "Caroline johnson",
      phone_no: "32 16 32 16",
      email: "hello@estatetool.dk",
      rating: "",
      responsible_agent: "Max Wildersans Brentford",
      Reg_date: "24.Feb, 2023",
      Follow_up: "24 Feb,",
      participated: "CONFIRM",
    },
    {
      name: "Caroline johnson",
      phone_no: "32 16 32 16",
      email: "hello@estatetool.dk",
      rating: "",
      responsible_agent: "Max Wildersans Brentford",
      Reg_date: "24.Feb, 2023",
      Follow_up: "24 Feb,",
      participated: "CONFIRM",
    },
  ];

  const Registrationcolumns = useMemo(
    () => [
      {
        accessorKey: "type",
        header: "",
        size: 20,
        enableSorting: false,
        Cell: ({ cell }) => (
          <>
            <PropertyTagToolTip />
          </>
        ),
      },

      {
        accessorKey: "name",
        header: "Name",
        size: 180,
      },
      {
        accessorKey: "phone_no",
        header: "Phone",
        size: 20,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 50,
      },
      {
        accessorKey: "rating",
        header: "Rating",
        Cell: ({ row }) => (
          <>
            {favorite?.map((item, index) => (
              <FontAwesomeIcon
                key={index - 1}
                icon={
                  item.status === true
                    ? "fa-solid fa-star"
                    : "fa-regular fa-star"
                }
                size="lg"
                style={{ color: "#F6E651" }}
                onClick={() => handleFavorite(index - 1, item.status)}
              />
            ))}
          </>
        ),
        size: 150,
      },
      {
        accessorKey: "responsible_agent",
        header: "Responsible agent",
        size: 200,
      },

      {
        accessorKey: "Reg_date",
        header: "Reg.date",
        size: 180,
      },
      {
        accessorKey: "phone_no",
        header: "Follow_up appointment",
        size: 200,
      },
    ],
    []
  );

  return (
    <div>
      <MRTTheme
        colorVariantsForTableData={["odd"]}
        table={
          <MaterialReactTable
            columns={Registrationcolumns}
            data={RegistrationData}
            icons={{ ...SortIcon }}
            enableBottomToolbar={true}
            enableColumnActions={false}
            enablePagination={true}
            enableRowActions
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "",
              },
              "mrt-row-select": {
                header: "",
              },
            }}
            renderRowActions={({ row, table }) => (
              <Menus
                itemheight={23}
                itemwidth={23}
                iconborderradius="4px"
                itemcontainerwidth="198px"
                component={
                  <div className="addpropertyCountryHandle ">
                    <div className="addpropContent px-3 py-2 ">
                      <span className="ms-1 align-items-center light-font-text ">
                        View and edit registration
                      </span>
                    </div>
                    <hr className="m-0" />
                    <div
                      className="addpropContent  px-3 py-2"
                      onClick={() => {
                        setDeleteRegistration(true);
                      }}
                    >
                      <span className="ms-1 align-items-center light-font-text">
                        Delete registration
                      </span>
                    </div>
                  </div>
                }
              />
            )}
            positionActionsColumn="last"
            renderTopToolbar={({ table }) => (
              <>
                <div className="mt-4 mx-4">
                  <Row className="mt-1">
                    <Col lg={5}>
                      {" "}
                      <div className="header-semibold mb-2"> Participants</div>
                      <div className=" light-font-text mb-2">
                        {" "}
                        Add participants for the private viewing
                      </div>{" "}
                    </Col>
                    <Col lg={{ span: 3, offset: 4 }}>
                      <div className="d-flex justify-content-end">
                        <ETButton
                          variant={"primary"}
                          buttonText="ADD PARTICIPANTS"
                          onClick={() => {
                            setopenAddParticipantsPopup(true);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          />
        }
      />
    </div>
  );
};

export default AddParticipantsTable;
