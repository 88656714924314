// import { Get, Post, Put, Delete } from ".././utils/Axios-old";
// import { endpoint } from ".././utils/Endpoints";
// import { GetHeader } from ".././utils/Header";

import { Get } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";
import { error } from "jquery";

// Get language
export const UserProfileSystemLanguage = () => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Get_UserProfileSystemLanguage, null)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

// get user by id

export const GetUserById = () => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Get_Users_By_Id, null, 100001)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

export const UserTimeZone = () => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Get_User_TimeZone, null)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};