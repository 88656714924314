import { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";

import { useAllPropertyList } from "../../../hooks/queries/usePropertyQueries";
import { Checkedbox, UnCheckedbox } from "../../../assets/icons/icons";
import MRTTheme from "../../../Components/table/MRTTheme";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../Components/table/MRTGlobalProps";
import { Col, Row } from "react-bootstrap";

function PortfolioTable({ setChekedProperty, rowSelection, setRowSelection }) {
  const { data: allPropertiesData } = useAllPropertyList(0);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setChekedProperty(Object.keys(rowSelection));
  }, [rowSelection]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "propertyName",
        header: "Property Name",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: true,
      },
      {
        accessorKey: "city",
        header: "City",
        enableHiding: false,
        size: 20,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "country",
        header: "Country",
        enableHiding: false,
        size: 20,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "type",
        header: "Type",
        size: 40,
        enableGlobalFilter: true,
      },
    ],
    []
  );
  useEffect(() => {
    setTableData(allPropertiesData?.data);
  }, [allPropertiesData]);
  // const renderEmptyRowsFallback = () => (
  // 	<div className="d-flex justify-content-center  created-by py-4">
  // 		<div>You do not have any new portfolio yet.</div>
  // 	</div>
  // );
  const renderEmptyRowsFallback = () => {
    if (tableData && tableData?.length === 0) {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div>You do not have any new portfolio yet.</div>
          </>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div style={{ fontSize: "14px" }}>
              Sorry! No result found. Please try again!
            </div>
          </>
        </div>
      );
    }
  };

  return (
    <div>
      <MRTTheme
        colorVariantsForTableData={["odd"]}
        table={
          <MaterialReactTable
            columns={columns}
            data={tableData ?? []} //data is undefined on first render
            icons={{ ...SortIcon }}
            enableGlobalFilter={true}
            enableFilterMatchHighlighting={true}
            globalFilterFn="contains" //turn off fuzzy matching and use simple contains filter function
            muiSearchTextFieldProps={{
              ...SearchStyle,

              InputProps: {
                ...SearchIcon,
              },
            }}
            // enableTopToolbar={false}
            positionGlobalFilter="right"
            renderEmptyRowsFallback={renderEmptyRowsFallback}
            enableRowSelection
            enableSelectAll
            getRowId={(originalRow) => originalRow.property_id}
            onRowSelectionChange={setRowSelection}
            state={{ rowSelection }}
            muiTableContainerProps={{
              sx: {
                padding: "0px !important",
              },
            }}
            initialState={{
              showGlobalFilter: true,
            }}
            displayColumnDefOptions={{
              "mrt-row-select": {
                header: "",
              },
            }}
            muiTableBodyRowProps={{
              selected: false,
            }}
            muiTablePaginationProps={{
              ...paginationoptions,
            }}
            muiTableHeadRowProps={{
              sx: {
                ...TableRowsStyle,
              },
            }}
            renderTopToolbar={({ table }) => (
              <>
                <div className="pb-2">
                  <Row>
                    <Col xl={2} md={4} className="my-1 pe-1">
                      {/* eslint-disable-next-line react/jsx-pascal-case */}
                      <MRT_GlobalFilterTextField table={table} />
                    </Col>
                  </Row>
                </div>
              </>
            )}
          />
        }
      />
    </div>
  );
}

export default PortfolioTable;
