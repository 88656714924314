export const status = {
  s: "success",
  e: "error",
};

export const controlCenterTabs = [
  {
    Id: "1",
    tab: "Overview",
    link: "overview",
  },
  {
    Id: "2",
    tab: "Economy",
    link: "economy",
  },
  {
    Id: "3",
    tab: "Traffic",
    link: "traffic",
  },
  {
    Id: "4",
    tab: "Events",
    link: "events",
  },
  {
    Id: "5",
    tab: "Customer",
    link: "customer",
  },
  {
    Id: "6",
    tab: "Signings",
    link: "signings",
  },
];

export const editUnitTabs = [
  {
    Id: "1",
    tab: "Overview",
    link: "overview",
  },
  {
    Id: "2",
    tab: "Contracts",
    link: "contracts",
  },
  {
    Id: "3",
    tab: "Unit details",
    link: "unit-details",
  },
  {
    Id: "4",
    tab: "Economy",
    link: "economy",
  },
  {
    Id: "5",
    tab: "Facilities",
    link: "facilities",
  },
  {
    Id: "6",
    tab: "Texts",
    link: "texts",
  },
  {
    Id: "7",
    tab: "Medias",
    link: "medias",
  },
  {
    Id: "8",
    tab: "Marketing",
    link: "marketing",
  },
  {
    Id: "9",
    tab: "Settings",
    link: "settings",
  },
  {
    Id: "10",
    tab: "Documents",
    link: "documents",
  },
];

export const contactsTabsWizards = [
  // {
  // 	Id: "1",
  // 	tab: "Overview",
  // 	link: "overview",
  // },
  // {
  // 	Id: "2",
  // 	tab: "Leads",
  // 	link: "leads",
  // },
  // {
  // 	Id: "3",
  // 	tab: "Ongoing",
  // 	link: "ongoing",
  // },
  {
    Id: "1",
    tab: "Customers",
    link: "customers",
  },
  {
    Id: "2",
    tab: "Archive",
    link: "archive",
  },
];

export const unitsParentTab = [
  {
    Id: "1",
    tab: "Residential",
    link: "residential",
  },
  //   {
  //     Id: "2",
  //     tab: "Parking lot",
  //     link: "parking-lot",
  //   },
  //   {
  //     Id: "3",
  //     tab: "Storage room",
  //     link: "storage-room",
  //   },
];

export const PropertyTabs = [
  {
    Id: "1",
    tab: "Overview",
    link: "overview",
  },
  {
    Id: "2",
    tab: "Units",
    link: "units",
  },
  {
    Id: "3",
    tab: "Details",
    link: "details",
  },
  {
    Id: "4",
    tab: "Facilities",
    link: "facilities",
  },
  {
    Id: "5",
    tab: "Media",
    link: "media",
  },
  {
    Id: "6",
    tab: "Documents",
    link: "documents",
  },
  {
    Id: "7",
    tab: "Events",
    link: "events",
  },
  {
    Id: "8",
    tab: "Texts",

    link: "texts",
  },
  {
    Id: "9",
    tab: "Signers",
    link: "signers",
  },
  {
    Id: "10",
    tab: "Settings",
    link: "settings",
  },
];

export const organizationTabs = [
  {
    Id: "1",
    tab: "Details",
    link: "details",
  },
  {
    Id: "2",
    tab: "Customisation",
    link: "customisation",
  },
  {
    Id: "3",
    tab: "Maintenance",
    link: "maintenance",
  },
  {
    Id: "4",
    tab: "Divisions",
    link: "divisions",
  },
  {
    Id: "5",
    tab: "Documents",
    link: "documents",
  },
  {
    Id: "6",
    tab: "Settings",
    link: "settings",
  },
];

export const environments = {
  development: "development",
};

export const adjustedMaxCustomerOptions = [
  { value: "0", label: "Equal to number of rooms" },
  { value: "-1", label: "Number of rooms, minus 1" },
  { value: "-2", label: "Number of rooms, minus 2" },
  { value: "-3", label: "Number of rooms, minus 3" },
  { value: "1", label: "Number of rooms, plus 1" },
  { value: "2", label: "Number of rooms, plus 2" },
  { value: "3", label: "Number of rooms, plus 3" },
];

export const CustomerFunnelTabsWizards = [
  {
    Id: "1",
    tab: "All",
    link: "all",
  },
  {
    Id: "2",
    tab: "Leads",
    link: "leads",
  },
  {
    Id: "3",
    tab: "Opportunities",
    link: "opportunities",
  },
  {
    Id: "4",
    tab: "Screenings",
    link: "screenings",
  },
  {
    Id: "5",
    tab: "Signings",
    link: "signings",
  },
  {
    Id: "6",
    tab: "Follow up later",
    link: "follow-up-later",
  },
];
