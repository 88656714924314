import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import "./settings.css";
import Tags from "./Tags/Tags";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import { H2 } from "../../styles/Common.style";
// import { usePropertyOverview } from "../../hooks/stores/usePropertyStore";
// import { useLocation } from "react-router-dom";
// import Configuration from "./configuration/Configuration";
// import Screening from "./screening/Screening";
// import { useRouting } from "../../hooks/stores/useRoutingStore";
// import { useBreadcrumb } from "../../Components/BreadcrumbContext ";

const Settings = ({
  propertyOverview,
  propertyId,
  setShowEditTag,
  setTagId,
  tagId,
}) => {
  // let location = useLocation();
  // const {
  //   setSelectedSubTabType,
  //   selectedSubTabType,
  //   setSelectedSubTab,
  //   selectedSubTab,
  //   selectedSubForSetting,
  // } = useRouting((state) => state);
  // const { state } = location;
  const [show, setShow] = useState(false);
  const [tabloading, setTabloading] = useState();
  const [textFieldStyle, setTextFieldStyle] = useState(false);
  const [modalHeadingStyle, setModalHeadingStyle] = useState(false);

  const [deleteTagPopUp, setDeleteTagPopUp] = useState(false);

  // zustand
  // const propOverview = usePropertyOverview((state) => state.propOverview);

  // Progress Bar
  const [progress, setProgress] = useState(0);
  const [stepWizard, setStepWizard] = useState("CreateNewTag");

  const handleCreateTag = () => {
    setShow(true);
    setTextFieldStyle(true);
    setProgress(14.2);
    setStepWizard("CreateNewTag");
    setModalHeadingStyle(true);
  };

  // const { updateBreadcrumbs } = useBreadcrumb();
  // useEffect(() => {
  //   updateBreadcrumbs(["Tags"]);
  // }, []);
  // const handelTag = () => {
  //   if (selectedSubForSetting === "Configuration") {
  //     updateBreadcrumbs(["Promotion label", "Configuration"]);
  //   } else if (selectedSubForSetting === "Labels") {
  //     updateBreadcrumbs(["Promotion label", "Labels"]);
  //   }
  // };
  return (
    <>
      {/* <Container fluid style={{ backgroundColor: "#F9FAFA", padding: "0px" }}> */}
      <div className="px-3">
        <Row
          className="mb-3 pt-2 pb-4  "
          style={{ backgroundColor: "#fff", borderRadius: "8px" }}
        >
          {tabloading ? (
            <div className="" style={{ width: "100px" }}>
              <div className="placeholder col-10 bg-secondary"></div>
            </div>
          ) : (
            <div className=" px-4 py-2 d-flex align-items-center">
              <H2>Tags</H2>
              <div className="ps-2">
                {" "}
                <CustomToolTip size="sm" text="Tags" />{" "}
              </div>
            </div>
          )}

          <hr className="mt-2 hr" />

          <Tags
            modalHeadingStyle={modalHeadingStyle}
            setShow={setShow}
            stepWizard={stepWizard}
            setStepWizard={setStepWizard}
            setProgress={setProgress}
            progress={progress}
            show={show}
            setTextFieldStyle={setTextFieldStyle}
            textFieldStyle={textFieldStyle}
            propertyId={propertyId}
            setDeleteTagPopUp={setDeleteTagPopUp}
            propertyOverview={propertyOverview}
            setShowEditTag={setShowEditTag}
            setTagId={setTagId}
            tagId={tagId}
            handleCreateTag={handleCreateTag}
            setTabloading={setTabloading}
          />
        </Row>
        {/* <Placeholder animation="glow">
          <Tabs>
            <TabList className="light ps-3 ">
              {tabloading ? (
                <div className=" ps-3 mb-2">
                  <div
                    className="placeholder col-1 bg-secondary"
                    style={{
                      height: "35px",
                      borderRadius: "5px",
                      width: "50px",
                    }}
                  ></div>
                  <div
                    className="placeholder col-1 bg-secondary ms-1"
                    style={{
                      height: "35px",
                      borderRadius: "5px",
                      width: "110px",
                    }}
                  ></div>
                  <div
                    className="placeholder col-1 bg-secondary ms-1"
                    style={{
                      height: "35px",
                      borderRadius: "5px",
                      width: "100px",
                    }}
                  ></div>
                  <div
                    className="placeholder col-1 bg-secondary ms-1"
                    style={{
                      height: "35px",
                      borderRadius: "5px",
                      width: "100px",
                    }}
                  ></div>
                  <div
                    className="placeholder col-1 bg-secondary ms-1"
                    style={{
                      height: "35px",
                      borderRadius: "5px",
                      width: "100px",
                    }}
                  ></div>
                  <div
                    className="placeholder col-1 bg-secondary ms-1"
                    style={{
                      height: "35px",
                      borderRadius: "5px",
                      width: "120px",
                    }}
                  ></div>
                </div>
              ) : (
                <div>
                  <Tab
                    onClick={() => {
                      updateBreadcrumbs(["Tags"]);
                    }}
                  >
                    {" "}
                    Tags
                  </Tab>
                  <Tab
                    onClick={() => {
                      handelTag();
                    }}
                  >
                    Promotion label
                  </Tab>

                  <Tab
                    onClick={() => {
                      updateBreadcrumbs(["Integrations"]);
                    }}
                  >
                    Integrations
                  </Tab>
                  <Tab
                    onClick={() => {
                      updateBreadcrumbs(["Screening"]);
                    }}
                  >
                    Screening
                  </Tab>
                  <Tab
                    onClick={() => {
                      setSelectedSubTab(false);
                      updateBreadcrumbs(["Signing"]);
                    }}
                  >
                    Signing
                  </Tab>
                  <Tab
                    onClick={() => {
                      setSelectedSubTab(false);
                      updateBreadcrumbs(["Configuration"]);
                    }}
                  >
                    Configuration
                  </Tab>
                </div>
              )}
            </TabList>

            <div style={{ backgroundColor: "#F1F5F0", borderRadius: " 10px" }}>
              <TabPanel>
                {" "}
                <Row
                  className="ms-3 me-3  mb-3 pt-2 pb-4  "
                  style={{ backgroundColor: "#fff", borderRadius: "8px" }}
                >
                  {tabloading ? (
                    <div className="" style={{ width: "100px" }}>
                      <div className="placeholder col-10 bg-secondary"></div>
                    </div>
                  ) : (
                    <div className=" px-4 py-2 d-flex">
                      <div className="container-title">Tags</div>
                      <div className="ps-2">
                        {" "}
                        <CustomToolTip height={12} text="Tags" />{" "}
                      </div>
                    </div>
                  )}

                  <hr className="mt-2 hr" />

                  <Tags
                    modalHeadingStyle={modalHeadingStyle}
                    setShow={setShow}
                    stepWizard={stepWizard}
                    setStepWizard={setStepWizard}
                    setProgress={setProgress}
                    progress={progress}
                    show={show}
                    setTextFieldStyle={setTextFieldStyle}
                    textFieldStyle={textFieldStyle}
                    propertyId={propertyId}
                    setDeleteTagPopUp={setDeleteTagPopUp}
                    propertyOverview={propertyOverview}
                    setShowEditTag={setShowEditTag}
                    setTagId={setTagId}
                    tagId={tagId}
                    handleCreateTag={handleCreateTag}
                    setTabloading={setTabloading}
                  />
                </Row>
              </TabPanel>

              <TabPanel>
                <PromotionLabel />
              </TabPanel>

              <TabPanel>Integrations</TabPanel>
              <TabPanel>
                <Screening />
              </TabPanel>
              <TabPanel>
                <Signing />
              </TabPanel>
              <TabPanel>
                <Configuration />
              </TabPanel>
            </div>
          </Tabs>
        </Placeholder> */}
      </div>
      <br />
      {/* </Container> */}
    </>
  );
};

export default Settings;
