import { Menu } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ETButton from "../../../../../../Components/Buttons/ETButton";
import CustomToolTip from "../../../../../../Components/Tooltip/CustomTooltip";
import { GetUnitMarketingByUnitId } from "../../../../../../services/Units";
import CustomCheckBox from "../../../../../../Components/Checkbox/Checkbox";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import moment from "moment";
import { status } from "../../../../../../model/Constant";
import { usePropertyOverview } from "../../../../../../hooks/stores/usePropertyStore";
import {
  useEditUnitMarketingDetail,
  useGetEditUnitsTextField,
} from "../../../../../../hooks/queries/useUnitQueries";
import ReactSelect from "../../../../../../Components/DropDown/ReactSelect";
import { useUnitType } from "../../../../../../hooks/stores/useUnitStore";
import { useBreadcrumb } from "../../../../../../Components/BreadcrumbContext ";
import Toggle from "../../../../../../Components/Switch/Switch";
import { useGetPromotionLabelByPropertyId } from "../../../../../../hooks/queries/usePromotionLabelQueries";
import { H2, H3 } from "../../../../../../styles/Common.style";
import { useUserDetails } from "../../../../../../hooks/stores/useUserDetailsStore";
import { UTCtoLocalTimeZone } from "../../../../../../utils/UTCtoLocalTimeZone";

const ChooseFilter = [
  { Id: "A2020", Text: "A2020" },
  { Id: "A2015", Text: "A2015" },
  { Id: "A2010", Text: "A2010" },
  { Id: "B", Text: "B" },
  { Id: "C", Text: "C " },
  { Id: "D", Text: "D" },
  { Id: "E", Text: "E" },
  { Id: "F", Text: "F" },
  { Id: "G", Text: "G" },
];

const Marketing = ({ UnitId, propId }) => {
  const [unitId, setUnitId] = useState("");
  const [seoTitle, setSeotitle] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [energyLabel, setEnergyLabel] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [marketingdata, setMarketingData] = useState([]);
  //API Consumption Get Unit detail by unitId
  const [unitMarketing, serUnitMarketing] = useState([]);
  const [error, setError] = useState({
    error: true,
    status: "",
    message: "",
  });

  //-------------------zuustand----------------------//
  const propOverview = usePropertyOverview((state) => state.propOverview);
  const { typeOfUnit } = useUnitType((state) => state);
  //------------------validation-------------------------//
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      //   seo_title: "",
      //   meta_description: "",
      //   externallink_1: "",
      //   externallink_2: "",
      //   externallink_3: "",
      //   energy_label_dk: "",
    },
  });
  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    updateBreadcrumbs(["Marketing"]);
  }, []);
  //----------------------react query----------------------//
  const {
    mutateAsync: PutEditUnitMarketingData,
    isLoading: PutEditUnitMarketingDataIsLoading,
  } = useEditUnitMarketingDetail();
  const { data: getUnitTextFields } = useGetEditUnitsTextField(
    propId,
    typeOfUnit
  );
  const { data: getPromotionLabelByPropertyIdData } =
    useGetPromotionLabelByPropertyId(propId);

  const UnitMarketingByUnitId = () => {
    try {
      GetUnitMarketingByUnitId(UnitId)
        .then((data) => {
          if (data != null && data != undefined) {
            setUnitId(data?.data[0]?.unit_id);
            setMarketingData(data?.data);
            setSeotitle(data?.data[0]?.seo_title);
            setSeoDescription(data?.data[0]?.meta_description);
            setEnergyLabel(data?.data[0]?.energy_label_dk);
            setWebsiteLink(data?.data[0]?.externallink_1);
            setValue("seo_title", data?.data[0]?.seo_title);
            setValue("meta_description", data?.data[0]?.meta_description);
            if (data?.data[0]?.energy_label_dk != "") {
              setValue("energy_label_dk", {
                value: data?.data[0]?.energy_label_dk,
                label: additionalInfoFilterData
                  .filter((x) => x.column_name == "energy_label_dk")?.[0]
                  ?.possible_values?.filter(
                    (val) => val?.Id === data?.data[0]?.energy_label_dk
                  )?.[0]?.Text,
              });
            } else {
              setValue("energy_label_dk", "");
            }
            setValue("externallink_1", data?.data[0]?.externallink_1);
            setValue("externallink_2", data?.data[0]?.externallink_2);
            setValue("externallink_3", data?.data[0]?.externallink_3);
          } else {
            serUnitMarketing([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          serUnitMarketing([null]);
        });
    } catch (e) {
      serUnitMarketing([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };

  useEffect(() => {
    UnitMarketingByUnitId(UnitId);
    setAdditionalInfoData(AdditionalInfoCheckData);
  }, [UnitId]);

  // seo data
  const marketingSeoData = getUnitTextFields?.data?.filter(
    (x) =>
      x?.section_id == 701 &&
      (x?.sale_type === propOverview?.[0]?.type_id || x?.sale_type === 0)
  );

  // marketing additional info
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [addAnchorEl, setAddAnchorEl] = useState(false);
  const handleClickAdd = (event) => {
    setAddAnchorEl(event.currentTarget);
    setDropdownOpen(true);
  };
  const handleCloseAdd = () => {
    setAddAnchorEl(null);
    setDropdownOpen(false);
  };
  const marketingAdditionalInfo = getUnitTextFields?.data?.filter(
    (x) => x?.section_id == 702
  );
  const AdditionalInfoType1and2Data = marketingAdditionalInfo?.filter(
    (x) =>
      (x?.is_displayed !== 0 && x?.sale_type === propOverview?.[0]?.type_id) ||
      x?.sale_type === 0
  );
  let AdditionalInfoCheckData = AdditionalInfoType1and2Data?.map((x) => ({
    ...x,
    checked: x.is_displayed == 1 && true,
  }));
  // console.log(marketingSeoData, "marketingSeoData");
  // console.log(AdditionalInfoCheckData, "marketingAdditionalInfo");
  const [AdditionalInfoData, setAdditionalInfoData] = useState([]);
  let additionalInfoFilterData = AdditionalInfoData?.filter(
    (x) => x?.checked == true
  );
  // console.log(AdditionalInfoType1and2Data, "AdditionalInfoType1and2Data");
  const additionalClick = (e, i) => {
    const { id, checked, name } = e.currentTarget;
    let checkValue = [...AdditionalInfoData];
    if (checked) {
      checkValue[i].checked = true;
    } else {
      checkValue[i].checked = false;
    }
    setAdditionalInfoData(checkValue);
  };

  const handleSave = async (formData) => {
    // console.log(formData, "formData");
    const data = {
      unit_id: UnitId,
      seo_title: formData?.seo_title,
      meta_description: formData?.meta_description,
      energy_label_dk: formData?.energy_label_dk?.label,
      externallink_1: formData?.externallink_1,
      externallink_2: formData?.externallink_2,
      externallink_3: formData?.externallink_3,
    };
    // console.log(data, "formData");
    try {
      const response = await PutEditUnitMarketingData(data);
      if (response?.status == status.s && response?.data?.bReturn) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  // const handleBackToMarketing = () => {
  //   navigate("/property-units");
  // }
  useEffect(() => {
    UnitMarketingByUnitId(UnitId);
  }, [AdditionalInfoData]);
  // const modifiedOn = moment(marketingdata?.modified_on).format('DD.MMMM.YYYY | HH:mm:ss');
  // console.log(modifiedOn,"modifiedOn45")
  const modifiedData = marketingdata?.map((item) => {
    return {
      ...item,
      modifiedOn: moment(item.modified_on).format("DD.MMMM.YYYY | HH:mm:ss"),
      createdOn: moment(item.created_on).format("DD.MMMM.YYYY | HH:mm:ss"),
    };
  });

  // const modifiedOn = moment(marketingdata?.modified_on).format('DD.MMMM.YYYY | HH:mm:ss');
  // const createdOn = moment(marketingdata?.created_on).format('DD.MMMM.YYYY | HH:mm:ss');
  const displayItem = (selected) => {
    return {
      value: selected,
      label: (
        <>
          {getPromotionLabelByPropertyIdData?.data
            .filter(
              (item) =>
                String(item.promotion_label_id) === String(selected?.value)
            )
            .map((data, index) => (
              <div
                key={index}
                className="light-font-text"
              >
                {data?.internal_label !== "" ? data?.internal_label : "-"}
              </div>
              //   </div>
              // </div>
            ))}
        </>
      ),
    };
  };
  const groupStyles = {
    display: "flex",
    alignItems: "center",
    // justifyContent: "space-between",
    width: "380px",
    overflowX: "scroll",
  };
  const FormatGroupLabel = () => {
    return (
      <div style={groupStyles}>
        <tr
          className="w-100"
          style={{ textTransform: "capitalize" }}
        >
          <th style={{ width: "200px" }}>
            <div className="config-table-heading ps-2">Internal label </div>
          </th>
          <th style={{ width: "120px" }}>
            <span className="config-table-heading ">Price visible</span>
          </th>
          <th style={{ width: "60px" }}>
            <div className="config-table-heading ">Color</div>{" "}
          </th>
        </tr>
      </div>
    );
  };
  const PromotionLabelList = ({ data, index }) => {
    return (
      <div
        className=""
        style={{ width: "380px", overflowX: "scroll" }}
      >
        <tr className="cursor-pointer w-100">
          <td
            className="config-table-data"
            style={{ width: "190px" }}
          >
            <span className="light-font-text ">
              {data?.internal_label !== "" ? data?.internal_label : "-"}
            </span>
          </td>
          <td
            className="config-table-data"
            style={{ width: "120px" }}
          >
            <span className="light-font-text  ">
              {data?.is_price_visible == "1" ? "Yes" : "No"}
            </span>
          </td>
          <td
            className="config-table-data"
            style={{ width: "60px" }}
          >
            <div
              style={{
                width: "17px",
                background: `linear-gradient(135deg, ${data?.background_color} 50%, ${data?.font_color} 50%)`,
              }}
              className="config-color-sec"
            ></div>
          </td>
        </tr>
      </div>
    );
  };
  const groupedOptions = [
    {
      label: <FormatGroupLabel />,
      options: getPromotionLabelByPropertyIdData?.data.map((data, index) => ({
        value: data?.promotion_label_id,
        label: (
          <PromotionLabelList
            data={data}
            index={index}
          />
        ),
        // isDisabled: watch("addStep")?.some(
        //   (val) => data?.promotion_label_id == val?.promotionLabel?.value
        // ),
      })),
    },
  ];

  console.log("MD", marketingdata);

  const { userDetails } = useUserDetails((state) => state);
  const { convertedDate: createdOn } = UTCtoLocalTimeZone({
    timestamp: marketingdata?.[0]?.created_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });
  const { convertedDate: modifiedOn } = UTCtoLocalTimeZone({
    timestamp: marketingdata?.[0]?.modified_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });

  return (
    <div className="bg-white radius-8 fs-14  mainborder">
      <Form onSubmit={handleSubmit(handleSave)}>
        <div className="d-flex justify-content-between px-4  pt-3">
          <div className="d-flex flex-column ">
            <div className="d-flex align-items-center">
              <H2>Marketing </H2>
              <span className="ms-2 r">
                <CustomToolTip
                  text="Marketing"
                  size="md"
                />
              </span>
            </div>
            <div className="fs-12 created-by">
              {modifiedData.map((data) => (
                <>
                  {data.modified_by && data.modified_on ? (
                    <div>
                      Last updated by &nbsp;{data.modified_by},{modifiedOn}
                    </div>
                  ) : (
                    <>
                      {data.created_by && data.created_on ? (
                        <div>
                          Created by &nbsp;{data.created_by},{createdOn}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              ))}

              {/* {marketingdata[0]?.modified_by && marketingdata?.modified_on ?
            <div className="fs-12 fw-300 grey-text">
              Last updated by {marketingdata[0]?.modified_by},&nbsp;
              {modifiedOn}
            </div> :
            <>{marketingdata?.created_by && marketingdata?.created_on ?
              <div className="fs-12 fw-300 grey-text">
                Created by {marketingdata?.created_by},&nbsp;
                {createdOn}
              </div> : ""}
            </>
          } */}
            </div>
          </div>

          <div>
            <ETButton
              buttonText="ADD FIELDS"
              variant={"secondary"}
              icon={true}
              width="fit-content"
              onClick={handleClickAdd}
              type={"button"}
            />
            <Menu
              id="simple-menu"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorEl={addAnchorEl}
              open={addAnchorEl}
              onClose={handleCloseAdd}
              elevation={0}
              // classes={{list:classes.list}}
              HorizontalAlignment="left"
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                style: {
                  // maxHeight: itemcontainerheight,
                  borderRadius: "8px",
                  // width: itemcontainerwidth,
                  marginTop: "6px",
                  border: "0.5px solid #F0F0F0",
                  boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.12)",
                  paddingTop: "0px",
                },
              }}
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.02)",
                "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                  padding: 0,
                  borderColor: "#F0F0F0",
                },
              }}
            >
              <div
                className="px-3 py-3 scrollable-container-y fs-14 radius-8"
                style={{
                  width: "300px",
                  height: "267px",
                  // overflowX: "hidden",
                  // overflowY: "scroll",
                }}
              >
                <div className="form-label-title ps-2">Additional info</div>
                {AdditionalInfoData?.map((item, i) => {
                  return (
                    <div
                      className="d-flex py-1 "
                      key={item?.field_id}
                    >
                      <CustomCheckBox
                        height="16px"
                        id={item?.field_id}
                        name={item?.field_label}
                        checked={item?.checked}
                        onChange={(e) => additionalClick(e, i)}
                      />
                      <div className="ps-1 d-flex align-items-center light-font-text">
                        {item?.field_label}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Menu>
          </div>
        </div>

        {/* <hr className="hr" /> */}

        {/* <div className="px-4">
          <div className="form-label-title">Promotion label</div>
          <div className="light-font-text">
            It is possible to set a manual promotion label on the unit by
            disabling the automatic update of the promotion label.
          </div>
          <div className="d-flex gap-2 align-items-center">
            <div className="light-font-text">Disable</div>
            <Toggle />
            <div className="light-font-text">Enable</div>
          </div>
          <Row>
            <Col lg={4} className="pt-3 pb-2">
              <Controller
                {...register(`promotionLabel`)}
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder={"Promotion label updated automated"}
                    value={displayItem(value)}
                    setPlaceholderPosition={true}
                    // height={55}
                    innerLabel={true}
                    clearable={false}
                    searchable={false}
                    options={groupedOptions}
                    onChange={(e) => {
                      // setNewPromotionLabelId(e.value);
                      onChange(e);
                    }}
                    // formatGroupLabel={formatGroupLabel}
                    noOptionsMessage={"No promotion label found"}
                  />
                )}
              />
              {errors?.promotionLabel && (
                <p className="error-msg">This field is required</p>
              )}
            </Col>
          </Row>

          <div className="light-font-text py-3">
            It is possible to set an end time on a manual promotion label.
            Select the method, which can be at a status change or on a specific
            date. It is also always possible to manually switch back to
            automatic promotion label by switching to enable above.
          </div>
          <Row>
            <Col lg={4} className="pb-3">
              <Controller
                {...register(`timeOutMethod`)}
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect placeholder={"Time out method"} />
                )}
              />
              {errors?.timeOutMethod && (
                <p className="error-msg">This field is required</p>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="pb-3">
              <Controller
                {...register("mainStatus")}
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect placeholder={"Main status"} />
                )}
              />
              {errors?.mainStatus && (
                <p className="error-msg">This field is required</p>
              )}
            </Col>
            <Col lg={4} className="pb-3">
              <Controller
                {...register("subStatus")}
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder={"Sub status"}
                    value={value}
                    onChange={(e) => onChange(e)}
                  />
                )}
              />
              {errors?.subStatus && (
                <p className="error-msg">This field is required</p>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="pb-3">
              <Controller
                {...register(`timeOutDate`)}
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <div className="form-input ">
                    <input
                      type="date"
                      placeholder="Time out date"
                      id="Time out date"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        // clearErrors(`${item?.column_name}`);
                      }}
                      // onChange={(e) => handleUnitIdentificationChange(e, item)}
                    />
                    <label htmlFor={"Time out date"}>Time out date</label>
                  </div>
                )}
              />
              {errors?.timeOutDate && (
                <p className="error-msg">This field is required</p>
              )}
            </Col>
          </Row>
        </div>  */}

        <hr className="hr" />
        <div className="px-4">
          <div className="form-label-title mb-2">SEO</div>

          {marketingSeoData?.map((item, i) => {
            return (
              <Row key={i}>
                <Col
                  lg={4}
                  md={6}
                  className="my-2 "
                  key={i}
                >
                  <Controller
                    control={control}
                    {...register(`${item?.column_name}`)}
                    rules={{
                      required: item?.is_mandatory == 0 ? false : true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <div className="form-input ">
                        <input
                          type={item?.field_type}
                          placeholder={item?.field_label}
                          id={item?.column_name}
                          name={item?.field_label}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors(`${item?.column_name}`);
                          }}
                          // onChange={(e) => handleUnitIdentificationChange(e, item)}
                        />
                        <label htmlFor={item?.column_name}>
                          {item?.field_label}
                        </label>
                      </div>
                    )}
                  />
                  {`${errors.item?.column_name}` &&
                    `${errors.item?.column_name.type}` === "required" && (
                      <p className="error-msg">This field is required</p>
                    )}
                </Col>
              </Row>
            );
          })}
        </div>

        <hr className="hr" />
        <div>
          <div className="px-4 pb-4">
            <div className="form-label-title mb-2">Additional info</div>
            {/* {console.log(additionalInfoFilterData, "additionalInfoFilterData")} */}
            {additionalInfoFilterData?.length !== 0 ? (
              <>
                {additionalInfoFilterData?.map((item, i) => {
                  return (
                    <Row>
                      <Col
                        lg={4}
                        md={6}
                        className="my-2 "
                        key={i}
                      >
                        <Controller
                          control={control}
                          {...register(`${item?.column_name}`)}
                          rules={{
                            required: item?.is_mandatory == 0 ? false : true,
                          }}
                          render={({ field: { onChange, value } }) =>
                            item?.field_type === "Dropdown" ? (
                              <ReactSelect
                                placeholder={item?.field_label}
                                clearable={false}
                                value={value}
                                onChange={(e, ActionTypes) => {
                                  onChange(e);
                                }}
                                options={item?.possible_values.map((x) => ({
                                  value: x?.Id,
                                  label: x?.Text,
                                }))}
                              />
                            ) : (
                              <div className="form-input ">
                                <input
                                  type={item?.field_type}
                                  placeholder={item?.field_label}
                                  id={item?.column_name}
                                  name={item?.field_label}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    clearErrors(`${item?.column_name}`);
                                  }}
                                  // onChange={(e) => handleUnitIdentificationChange(e, item)}
                                />
                                <label htmlFor={item?.column_name}>
                                  {item?.field_label}
                                </label>
                              </div>
                            )
                          }
                        />
                        {`${errors.item?.column_name}` &&
                          `${errors.item?.column_name.type}` === "required" && (
                            <p className="error-msg">This field is required</p>
                          )}
                      </Col>
                    </Row>
                  );
                })}
              </>
            ) : (
              <div
                className="fs-12 fw-300 grey-text"
                style={{ textAlign: "center" }}
              >
                You do not have any Additional info yet. Click on “+ ADD FIELDS”
                to create your first.
              </div>
            )}
          </div>
        </div>

        <div className="py-2 px-3 mt-2 d-sm-flex justify-content-between footer-section-button-container">
          <div className="m-1">
            <NavLink
              to="/property-units/units/residential"
              state={{ propId: propId, Tab: "Units" }}
            >
              <ETButton
                variant={"white"}
                buttonText="BACK TO UNITS"
                type={"button"}
              />
            </NavLink>
          </div>
          <div className="m-1">
            <ETButton
              disabled={PutEditUnitMarketingDataIsLoading}
              variant={"primary"}
              buttonText="SAVE"
              type={"submit"}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Marketing;
