import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import ETButton from "../../../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../../../Components/CustomPopup";
import {
  H4,
  H5,
  PrimarySelectedTag,
} from "../../../../../../styles/Common.style";
import InputField from "../../../../../../Components/Textfield/InputField";
import CustomCheckBox from "../../../../../../Components/Checkbox/Checkbox";
import CustomToolTip from "../../../../../../Components/Tooltip/CustomTooltip";
import FilterComponent from "../../../../../../reactdataTableComponent/FilterComponent";

// AV = Available
// IP = In progress
// CP = Completed

export const CreateNewLabelPopup = ({
  open,
  onHide = () => {},
  onCancel = () => {},
}) => {
  //---------------React hook form-----------//
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({});

  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [useUnit, setUseUnit] = useState(false);

  // toggle select tags
  const handleClick = (e, data) => {
    const tagId = e.target.value;

    if (selected?.includes(tagId)) {
      setSelected(selected?.filter((id) => id != tagId));
      setSelectedData(
        selectedData?.filter((tag) => tag?.tag_id != parseInt(tagId))
      );
    } else {
      setSelected([...selected, tagId]);

      const isDataAlreadySelected = selectedData?.some(
        (tag) => tag?.tag_id == data?.tag_id
      );
      if (!isDataAlreadySelected) {
        setSelectedData([...selectedData, data]);
      }
    }
  };

  // handle confirm data
  const handleConfirm = (data) => {
    console.log(data);
  };

  return (
    <div>
      <CustomPopup
        open={open}
        onHide={onHide}
        title={"Create new label"}
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        size={"md"}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        titleTooltipText={"Create new label"}
        secondTextShow={false}
        priorityShow={false}
      >
        <Form handleSubmit={handleSubmit(handleConfirm)}>
          <div>
            <div className="d-flex flex-column gap-4 border-bottom p-4">
              <span className="light-font-text">
                Create a marketing label that can be used on units that are
                marketed on the website, Ei.DO, the API, etc. Assign the label
                one or more tags to get it associated with units in the system.
              </span>

              <div>
                <div className="d-flex gap-2 align-items-center pb-1">
                  <H5>Internal title</H5>
                  <CustomToolTip text="Internal title" />
                </div>
                <InputField type="text" />
              </div>

              <div>
                <div className="d-flex gap-2 align-items-center pb-1">
                  <H5>Marketing label</H5>
                  <CustomToolTip text="Marketing label" />
                </div>
                <InputField type="text" />
              </div>
            </div>

            <div className="p-4">
              <H4>Select tags</H4>
              <span className="light-font-text fs-12">9 tags available </span>
              <div className="d-flex gap-2 align-items-center pt-4 pb-2">
                <FilterComponent />
                <ETButton
                  variant="secondary"
                  buttonText="Reset"
                  size="sm"
                />
              </div>
              <div
                className="pt-2 scrollable-container-y"
                style={{
                  height: "350px",
                }}
              >
                {tagsByPropertyIdData
                  ?.filter((item) => {
                    if (searchText === "") {
                      return item;
                    } else if (
                      item.name.toLowerCase().includes(searchText.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  ?.map((data, index) => (
                    <PrimarySelectedTag
                      mode={selected?.includes(String(data?.tag_id))}
                      id={index}
                      key={index}
                      className="d-flex mb-2 justify-content-between align-items-center light-font-text  ps-3 pe-2  cursor-pointer"
                    >
                      <div>{data?.name}</div>
                      <div>
                        <CustomCheckBox
                          id={data?.tag_id?.toString()}
                          checked={selected?.includes(String(data?.tag_id))}
                          value={data?.tag_id}
                          onChange={(e) => {
                            handleClick(e, data);
                            setSelectedId(data?.tag_id);
                          }}
                        />
                      </div>
                    </PrimarySelectedTag>
                  ))}
              </div>
            </div>
          </div>

          <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between justify-content-center footer-section-button-container">
            <ETButton
              buttonText="Cancel"
              variant={"white"}
              onClick={onCancel}
            />
            <div className="d-flex ">
              <div>
                <ETButton
                  buttonText={"CONFIRM"}
                  variant={"primary"}
                  type={"submit"}
                />
              </div>
            </div>
          </div>
        </Form>
      </CustomPopup>
    </div>
  );
};

export default CreateNewLabelPopup;

const tagsByPropertyIdData = [
  {
    tag_id: 0,
    name: "Website",
  },
  {
    tag_id: 1,
    name: "Ei.DO",
  },
  {
    tag_id: 2,
    name: "Boligportal",
  },
  {
    tag_id: 3,
    name: "Boligsiden",
  },
  {
    tag_id: 4,
    name: "Mindworking",
  },
  {
    tag_id: 5,
    name: "ABC",
  },
  {
    tag_id: 6,
    name: "XYZ",
  },
  {
    tag_id: 7,
    name: "PQR",
  },
];

// Created by --------------> Yash
