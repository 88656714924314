import React, { useState } from "react";
import ETButton from "../../../../Components/Buttons/ETButton";
import DropZone from "../../../../Components/DropZone/DropZone";
import { Col, Row } from "react-bootstrap";
import GroupButtons from "../../../../Components/GroupButton/GroupButton";
import { H2, H3, H4 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import ColorInput from "../../../../Components/ColorInput/ColorInput";

const Styling = () => {
  const [backgroundLoginPage, setBackgroundLoginPage] = useState(0);
  return (
    <div>
      {/* ColorsColors */}
      <div className="p-4 pt-1 border-bottom">
        <H3>Colors</H3>

        {/*Primary and Secondary  */}
        <Row>
          <Col lg={3} md={5} className="pt-2">
            <div className="d-flex align-items-center gap-2 py-2">
              <H4>Primary</H4>
              <CustomToolTip text="Primary" />
            </div>
            <ColorInput label="Primary" />
          </Col>
          <Col lg={3} md={5} className="pt-2">
            <div className="d-flex align-items-center gap-2 py-2">
              <H4>Secondary</H4>
              <CustomToolTip text="Secondary" />
            </div>
            <ColorInput label="Secondary" />
          </Col>
        </Row>

        {/*Stroke and Progress bar  */}
        <Row>
          <Col lg={3} md={5} className="pt-2">
            <div className="d-flex align-items-center gap-2 py-2">
              <H4>Stroke</H4>
              <CustomToolTip text="Stroke" />
            </div>
            <ColorInput label="Stroke" />
          </Col>
          <Col lg={3} md={5} className="pt-2">
            <div className="d-flex align-items-center gap-2 py-2">
              <H4>Progress bar</H4>
              <CustomToolTip text="Progress bar" />
            </div>
            <ColorInput label="Progress bar" />
          </Col>
        </Row>

        {/*CTA active and CTA disable  */}
        <Row>
          <Col lg={3} md={5} className="pt-2">
            <div className="d-flex align-items-center gap-2 py-2">
              <H4>CTA active</H4>
              <CustomToolTip text="CTA active" />
            </div>
            <ColorInput label="CTA active" />
          </Col>
          <Col lg={3} md={5} className="pt-2">
            <div className="d-flex align-items-center gap-2 py-2">
              <H4>CTA disable bar</H4>
              <CustomToolTip text="CTA disable" />
            </div>
            <ColorInput label="CTA disable" />
          </Col>
        </Row>

        {/*Navigation button  */}
        <Row>
          <Col lg={3} md={5} className="pt-2">
            <div className="d-flex align-items-center gap-2 py-2">
              <H4>Navigation button</H4>
              <CustomToolTip text="Navigation button" />
            </div>
            <ColorInput label="Navigation button" />
          </Col>
        </Row>

        {/*Question, selected and Question, not selected   */}
        <Row>
          <Col lg={3} md={5} className="pt-2">
            <div className="d-flex align-items-center gap-2 py-2">
              <H4>Question, selected</H4>
              <CustomToolTip text="Question, selected" />
            </div>
            <ColorInput label="Question, selected" />
          </Col>
          <Col lg={3} md={5} className="pt-2">
            <div className="d-flex align-items-center gap-2 py-2">
              <H4>Question, not selected </H4>
              <CustomToolTip text="Question, not selected " />
            </div>
            <ColorInput label="Question, not selected " />
          </Col>
        </Row>

        {/*Canceled & declined and Completed and   */}
        <Row>
          <Col lg={3} md={5} className="pt-2">
            <div className="d-flex align-items-center gap-2 py-2">
              <H4>Canceled & declined</H4>
              <CustomToolTip text="Canceled & declined" />
            </div>
            <ColorInput label="Canceled & declined" />
          </Col>
          <Col lg={3} md={5} className="pt-2">
            <div className="d-flex align-items-center gap-2 py-2">
              <H4>Completed </H4>
              <CustomToolTip text="Completed " />
            </div>
            <ColorInput label="Completed" />
          </Col>
          <Col lg={3} md={5} className="pt-2">
            <div className="d-flex align-items-center gap-2 py-2">
              <H4>Approved </H4>
              <CustomToolTip text="Approved" />
            </div>
            <ColorInput label="Approved" />
          </Col>
        </Row>

        {/*Report a problem  and Desire to move    */}
        <Row>
          <Col lg={3} md={5} className="pt-2">
            <div className="d-flex align-items-center gap-2 py-2">
              <H4>Report a problem </H4>
              <CustomToolTip text="Report a problem " />
            </div>
            <ColorInput label="Report a problem " />
          </Col>
          <Col lg={3} md={5} className="pt-2">
            <div className="d-flex align-items-center gap-2 py-2">
              <H4>Desire to move </H4>
              <CustomToolTip text="Desire to move " />
            </div>
            <ColorInput label="Desire to move " />
          </Col>
        </Row>
      </div>

      {/*Visuals */}
      <div className="p-4">
        <H3>Visuals</H3>

        {/*Thumbnail*/}
        <div className="pt-3 pb-4">
          <div className="d-flex align-items-center  gap-2 ">
            <H4>Thumbnail</H4>
            <CustomToolTip text="Thumbnail" />
          </div>
          <div className="light-font-text pt-2">Recommended logo size: </div>
          <div className="w-50">
            <DropZone />
          </div>
        </div>

        {/*Background - Login page  */}
        <div className="pb-4">
          <div className="d-flex align-items-center  gap-2 ">
            <H4>Background - Login page</H4>
            <CustomToolTip text="Background - Login page" />
          </div>
          <div className="light-font-text pt-1 pb-3">
            Choose whether the background should be a solid color or an image.
          </div>
          <Row>
            <Col xl={3} lg={4} sm={6} className="pt-1 pb-3">
              <GroupButtons
                tabs={[
                  {
                    id: 1,
                    tabTitle: "IMAGE",
                    iconName: "fa-regular fa-image",
                  },
                  {
                    id: 2,
                    tabTitle: "COLOR",
                    iconName: "fa-regular fa-droplet",
                  },
                ]}
                currentTab={backgroundLoginPage}
                handleTabClick={(tab) => {
                  setBackgroundLoginPage(tab);
                  console.log(tab, "tab");
                }}
              />
            </Col>
          </Row>
          <div className="light-font-text">Recommended logo size: </div>
          <div className="w-50">
            <DropZone />
          </div>
        </div>

        {/*Top banner - Main  */}
        <div className="pb-4">
          <div className="d-flex align-items-center  gap-2 ">
            <H4>Top banner - Main</H4>
            <CustomToolTip text="Top banner - Main" />
          </div>
          <div className="light-font-text pt-1 pb-3">
            Choose whether the main top banner should be a solid color or an
            image.
          </div>
          <Row>
            <Col xl={3} lg={4} sm={6} className="pt-1 pb-3">
              <GroupButtons
                tabs={[
                  {
                    id: 1,
                    tabTitle: "IMAGE",
                    iconName: "fa-regular fa-image",
                  },
                  {
                    id: 2,
                    tabTitle: "COLOR",
                    iconName: "fa-regular fa-droplet",
                  },
                ]}
                currentTab={backgroundLoginPage}
                handleTabClick={(tab) => {
                  setBackgroundLoginPage(tab);
                  console.log(tab, "tab");
                }}
              />
            </Col>
          </Row>
          <div className="light-font-text">Recommended logo size: </div>
          <div className="w-50">
            <DropZone />
          </div>
        </div>

        {/*Top banner - Report a problem  */}
        <div className="pb-4">
          <div className="d-flex align-items-center  gap-2 ">
            <H4>Top banner - Report a problem</H4>
            <CustomToolTip text="Top banner - Report a problem" />
          </div>
          <div className="light-font-text pt-1 pb-3">
            Choose whether the top banner on the popup should be a solid color
            or an image.
          </div>
          <Row>
            <Col xl={3} lg={4} sm={6} className="pt-1 pb-3">
              <GroupButtons
                tabs={[
                  {
                    id: 1,
                    tabTitle: "IMAGE",
                    iconName: "fa-regular fa-image",
                  },
                  {
                    id: 2,
                    tabTitle: "COLOR",
                    iconName: "fa-regular fa-droplet",
                  },
                ]}
                currentTab={backgroundLoginPage}
                handleTabClick={(tab) => {
                  setBackgroundLoginPage(tab);
                  console.log(tab, "tab");
                }}
              />
            </Col>
          </Row>
          <div className="light-font-text">Recommended logo size: </div>
          <div className="w-50">
            <DropZone />
          </div>
        </div>

        {/*Top banner - Desire to move  */}
        <div className="pb-4">
          <div className="d-flex align-items-center  gap-2 ">
            <H4>Top banner - Desire to move</H4>
            <CustomToolTip text="Top banner - Desire to move" />
          </div>
          <div className="light-font-text pt-1 pb-3">
            Choose whether the top banner on the popup should be a solid color
            or an image.
          </div>
          <Row>
            <Col xl={3} lg={4} sm={6} className="pt-1 pb-3">
              <GroupButtons
                tabs={[
                  {
                    id: 1,
                    tabTitle: "IMAGE",
                    iconName: "fa-regular fa-image",
                  },
                  {
                    id: 2,
                    tabTitle: "COLOR",
                    iconName: "fa-regular fa-droplet",
                  },
                ]}
                currentTab={backgroundLoginPage}
                handleTabClick={(tab) => {
                  setBackgroundLoginPage(tab);
                  console.log(tab, "tab");
                }}
              />
            </Col>
          </Row>
          <div className="light-font-text">Recommended logo size: </div>
          <div className="w-50">
            <DropZone />
          </div>
        </div>
      </div>
      {/* footer */}
      <div className="mt-4 py-2 px-3 d-flex flex-md-row flex-column justify-content-end footer-section-button-container gap-3">
        <ETButton
          variant={"primary"}
          buttonText="SAVE"
          type={"submit"}
          // onClick={() => setStep1(false)}
        />
      </div>
      {/* footer */}
    </div>
  );
};

export default Styling;
