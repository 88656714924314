import { create } from "zustand";

export const useControlCenter = create((set) => ({
	selectedProperties: [],
	setSelectedProperties: (data) => set({ selectedProperties: data }),
	selectedDate: [],
	setSelectedDate: (data) => set({ selectedDate: data }),
	selectedEndDate: [],
	setSelectedEndDate: (data) => set({ selectedEndDate: data }),
	allProperties: [],
	setAllProperties: (data) => set({ allProperties: data }),
	categoryId: [],
	setCategoryId: (data) => set({ categoryId: data }),
	geographyFilterData: [],
	setGeographyFilterData: (data) => set({ geographyFilterData: data }),
	selectedCountryId: [],
	setSelectedCountryId: (data) => set({ selectedCountryId: data }),
	selectedRegion: [],
	setSelectedRegion: (data) => set({ selectedRegion: data }),
}));
