import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container, Placeholder } from "react-bootstrap";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "../../Components/Tabs/ReactTabs.style";
import {
  useDivisionDocumentBreadcrumbsTabs,
  useDivisionId,
  useDivisionName,
  useDivisionSettingsBreadcrumbsTabs,
} from "../../hooks/stores/useDivisionsStore";
import { useGetDivisionByDivisionId } from "../../hooks/queries/useDivisionQueries";
import { GreenSpan, H1 } from "../../styles/Common.style";

const DivisionTabs = [
  {
    Id: "1",
    tab: "Details",
    link: "details",
  },
  {
    Id: "2",
    tab: "Companies",
    link: "Companies",
  },
  {
    Id: "3",
    tab: "Properties",
    link: "properties",
  },
  {
    Id: "4",
    tab: "Documents",
    link: "documents",
  },
  // {
  //   Id: "5",
  //   tab: "Communication",
  //   link: "communication",
  // },
  // {
  //   Id: "6",
  //   tab: "Settings",
  //   link: "settings",
  // },
];

const DivisionsTab = () => {
  //---------------states-----------------//
  const [selectedTab, setSelectedTab] = useState(null);

  // zustand
  const divisionBreadcrumbsTabs = useDivisionSettingsBreadcrumbsTabs(
    (state) => state.divisionBreadcrumbsTabs
  );
  const setDivisionBreadcrumbsTabs = useDivisionSettingsBreadcrumbsTabs(
    (state) => state.setDivisionBreadcrumbsTabs
  );
  const { divisionDocumentBreadcrumbsTabs } =
    useDivisionDocumentBreadcrumbsTabs((state) => state);

  const { divisionId } = useDivisionId((state) => state);
  // const division_name = useDivisionName((state) => state.divisionName);

  // query
  const {
    data: GetDivisionByDivisionId,
    refetch: divisionByDivisionIdDataRefetch,
  } = useGetDivisionByDivisionId(divisionId);

  let divsion_name = GetDivisionByDivisionId?.data?.[0]?.name;

  const handleHeading = (tab, Id) => {
    setSelectedTab(tab);
  };

  // navigation
  const navigate = useNavigate();
  const navigateToDivisions = () => {
    navigate("/organization/divisions");
  };

  //   Breadcrumbs
  // below function used for converting text into capital case
  const CapitalizedTextConverter = (text) => {
    const convertedText = text?.charAt(0)?.toUpperCase() + text?.slice(1);
    return convertedText;
  };
  const activePath = window.location.pathname?.split("/")[2];
  const activeSettingsSubPath = window.location.pathname?.split("/")[3];
  // console.log(activePath, "activePath");

  useEffect(() => {
    if (CapitalizedTextConverter(activeSettingsSubPath) == "My-page") {
      setDivisionBreadcrumbsTabs("Styling");
    }
    // if (CapitalizedTextConverter(activeSettingsSubPath) == "Marketing") {
    //   setDivisionBreadcrumbsTabs("SoMe integrations ");
    // }
  }, [window.location.pathname]);

  return (
    <>
      <Container
        fluid
        style={{ backgroundColor: "#F9FAFA" }}
        className="units-tabs-main  "
      >
        <>
          <div className="py-2">
            <div className="pt-4 ">
              <Placeholder animation="glow">
                {/* <H1>{TabId ? TabId : selectedTab}</H1> */}
                <H1>Division</H1>
                <div className="light-font-text cursor-pointer pt-2">
                  <span className="me-2 fs-16" onClick={navigateToDivisions}>
                    Division
                  </span>
                  <span className="me-2">
                    <FontAwesomeIcon
                      size="sm"
                      icon="fa-regular fa-angle-right"
                      style={{ color: "#000000" }}
                    />{" "}
                  </span>
                  {/* <span className="me-2" >
                    {divsion_name}
                  </span>
                  <span className="me-2">
                    <FontAwesomeIcon
                      size="sm"
                      icon="fa-regular fa-angle-right"
                      style={{ color: "#000000" }}
                    />{" "}
                  </span> */}
                  <GreenSpan
                    style={{
                      color:
                        activeSettingsSubPath || activePath === "documents"
                          ? "#202020"
                          : "",
                    }}
                    className={  activeSettingsSubPath || activePath === "documents" ? "me-2 fs-16 ":"me-2 fs-16 breadcrumbs"}
                  >
                    {CapitalizedTextConverter(activePath)}
                  </GreenSpan>

                  {activePath === "documents" && (
                    <>
                      <span className="me-2">
                        <FontAwesomeIcon
                          size="sm"
                          icon="fa-regular fa-angle-right"
                          style={{ color: "#000000" }}
                        />{" "}
                      </span>
                      <GreenSpan className="me-2 fs-16 breadcrumbs">
                        {divisionDocumentBreadcrumbsTabs
                          ? divisionDocumentBreadcrumbsTabs
                          : "Documents"}
                      </GreenSpan>
                      {console.log(divisionDocumentBreadcrumbsTabs,"wwwwwww")}
                    </>
                  )}

                  {activeSettingsSubPath && (
                    <>
                      <span className="me-2">
                        <FontAwesomeIcon
                          size="sm"
                          icon="fa-regular fa-angle-right"
                          style={{ color: "#000000" }}
                        />{" "}
                      </span>
                      <GreenSpan
                        style={{
                          color:
                            CapitalizedTextConverter(activeSettingsSubPath) !==
                            "My-page"
                              ? ""
                              : "#000",
                        }}
                        className={"me-2 fs-16"}
                      >
                        {CapitalizedTextConverter(activeSettingsSubPath) ===
                        "My-page"
                          ? "My page"
                          : CapitalizedTextConverter(activeSettingsSubPath)}
                      </GreenSpan>
                      {CapitalizedTextConverter(activeSettingsSubPath) ==
                        "My-page" && (
                        <>
                          <span className="me-2">
                            <FontAwesomeIcon
                              size="sm"
                              icon="fa-regular fa-angle-right"
                              style={{ color: "#000000" }}
                            />{" "}
                          </span>
                          <GreenSpan className="me-2 fs-16" >
                            {divisionBreadcrumbsTabs}
                          </GreenSpan>
                        </>
                      )}
                      {/* {CapitalizedTextConverter(activeSettingsSubPath) ==
                "Marketing" && (
                <>
                  <span className="me-3">
                    <FontAwesomeIcon
                      size="sm"
                      icon="fa-regular fa-angle-right"
                      style={{ color: "#000000" }}
                    />{" "}
                  </span>
                  <span className="me-3">{organizationBreadcrumbsTabs}</span>
                </>
              )} */}
                    </>
                  )}
                </div>
                <div
                  className="d-flex  pt-2 mt-1 pb-2"
                  style={{ flexWrap: "wrap" }}
                >
                  <>
                    {DivisionTabs?.map((item, i) => (
                      <NavLink
                        key={i}
                        to={item.link}
                        style={{ textDecoration: "none" }}
                        onClick={() => {
                          handleHeading(item?.tab);
                        }}
                        className=" me-3"
                      >
                        {item?.tab}
                      </NavLink>
                    ))}
                  </>
                </div>
              </Placeholder>
            </div>
          </div>
          <Outlet />
        </>
      </Container>
    </>
  );
};

export default DivisionsTab;
