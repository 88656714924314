import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
// import { REQUIRED_ERR } from "../../utils/validationConstants";
// import { colourOptions } from './colourOptions';

const { MenuList, ValueContainer, Placeholder } = components;

const CustomMenuList = ({ selectProps, ...props }) => {
  const {
    onInputChange,
    inputValue,
    onMenuInputFocus,
    setInputValuea,
    description,
  } = selectProps;

  const [search, setSearch] = useState("");
  return (
    <div>
      <div className="pt-3 ps-3 fs-14 pb-2 fw-300 grey-text">
        {description ? description : "Search for email templates"}
      </div>
      <div
        className="d-flex justify-content-center pb-2"
        style={{ borderBottom: "1px solid #D1D1D1" }}
      >
        <div
          className="d-flex mx-3  p-2 pe-3"
          style={{
            border: "1px solid #F0F0F0",
            borderRadius: "8px",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <input
              className=""
              style={{
                border: "none",
                outline: "none",
                color: "#202020",
                width: "100%",
              }}
              type="text"
              value={search}
              onKeyDown={(e) => {
                if (e.keyCode === 8) {
                  setSearch((search) => search.slice(0, search.length - 1));
                  setInputValuea((prev) => prev?.slice(0, prev?.length - 1));
                }
              }}
              onChange={(e) => {
                setSearch(e.currentTarget.value);
                setInputValuea(e.currentTarget.value);
                // onInputChange(e.currentTarget.value, {
                // action: "input-change",
                // })
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
                e.target.focus();
              }}
              onTouchEnd={(e) => {
                e.stopPropagation();
                e.target.focus();
              }}
              onFocus={onMenuInputFocus}
              placeholder="Search..."
            />
          </div>

          <div className="ps-4 pt-1" style={{ flex: "none" }}>
            <FontAwesomeIcon
              icon="fa-regular fa-magnifying-glass"
              style={{ color: "#222222" }}
            />
          </div>
        </div>
      </div>

      <MenuList {...props} selectProps={selectProps} />
    </div>
  );
};
export const normalInputStyle = (
  iserror,
  bgColor,
  border,
  dropDownIndicator
) => {
  return {
    container: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
    }),
    valueContainer: (provided, state) => {
      return {
        ...provided,
        overflow: "visible",
        backgroundColor: "#ffffff",
        borderRadius: 8,
        fontFamily: "Cerebri sans-light !important",
        fontSize: 14,
        // fontWeight:300,
        // marginLeft: "10px",
        display: "flex",
        justifyContent: "flex-start",
        marginLeft: state.hasValue
          ? "5px"
          : dropDownIndicator === false
            ? "10px"
            : "0px",
        alignItems: "center",
        padding: "0px",
        // paddingTop: "2px",
        background: bgColor ? bgColor : "",
        "& input": {
          width: 0,
        },
      };
    },
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "#000000", // Custom colour
      transition: "all .2s ease",
      transform: state.isFocused ? "rotate(180deg)" : null,
      padding: "8px 8px",
      display: dropDownIndicator === false ? "none" : "initial",
    }),
    indicatorSeparator: (base, state) => ({
      ...base,
      width: "0px",
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    placeholder: (base, state) => ({
      ...base,
      position: "absolute",
      background:
        state.hasValue ||
          state.selectProps.inputValue ||
          state.selectProps.isFocused
          ? `linear-gradient(0deg, ${bgColor ? bgColor : "rgba(255,255,255,1)"
          } 41%, rgba(247,247,247,0) 45%)`
          : bgColor,
      borderRadius: border === "none" ? "unset" : 2,
      padding:
        state.hasValue ||
          state.selectProps.inputValue ||
          state.selectProps.isFocused
          ? "0px 4px"
          : "0px 1px",
      margin: "0px",
      // marginLeft: "10px",
      color: !state.hasValue
        ? "#000000"
        : state.hasValue ||
          state.selectProps.inputValue ||
          state.selectProps.isFocused
          ? "#888888"
          : "#000",
      fontFamily:
        state.hasValue ||
          state.selectProps.inputValue ||
          state.selectProps.isFocused
          ? " Cerebri sans-medium !important"
          : "Cerebri sans-light !important",
      top:
        state.hasValue ||
          state.selectProps.inputValue ||
          state.selectProps.isFocused
          ? "-85%"
          : "0%",
      transition: "top 0.2s, font-size 0.2s",
      fontSize: state.selectProps.isFocused || state.hasValue ? 8 : 14,
      fontWeight: 300,
      // (state.hasValue ||
      //   state.selectProps.inputValue ||
      //   state.selectProps.isFocused) &&
      // 14,
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      // paddingTop: "4px",
      fontSize: 14,
      fontWeight: 300,
      boxShadow: state.isFocused ? "none" : "none",
      borderColor:
        iserror && !state.hasValue
          ? "#e33716 !important"
          : state.isFocused
            ? "#000 !important"
            : "#d1d1d1 !important",
      borderRadius: "8px 0 0 8px",
      backgroundColor: bgColor ? bgColor : "#fff",
      // "&:hover": {
      //     borderColor: iserror && !state.hasValue ? "#e33716 !important" : state.isFocused ? "#000 !important" : "#d1d1d1 !important",
      //     boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
      // },
      pointerEvents: state.isDisabled && "auto",
      cursor: state.isDisabled ? "not-allowed " : "default",
      borderStyle: border ? border : "solid",
      flexDirection: "row-reverse",
      // opacity: state.isDisabled ? "0.7" : "1",
      height: "40px",
    }),
    option: (base, { isDisabled }, state) => ({
      ...base,
      backgroundColor: "#fff",
      fontSize: 14,
      fontFamily: "Cerebri sans-light !important",
      zIndex: 1,
      color: isDisabled ? "#888888" : "#000000",
      paddingLeft: "15px",
      borderBottom: "1px solid #F0F0F0",
      padding: "11px 16px 11px 16px !important",
    }),
    singleValue: (provided, { data }) => ({
      ...provided,
      marginLeft: "4px",
      // specify a fallback color here for those values not accounted for in the styleMap
    }),
    menu: (base) => ({
      ...base,
      zIndex: 3,
      fontSize: 14,
      fontWeight: 300,
      // width: "max-content",
      // width: "100%",
      width: "260px",
      overflowX: "scroll",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 10000 }),
    menuList: (base) => ({
      ...base,
      maxHeight: "175px", // Adjust the value to your desired maximum height
      overflowY: "auto", // Add vertical scroll if needed
    }),
  };
};

const colourStyles = {
  container: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    backgroundColor: "rgb(255,255,255)",
    fontFamily: "Cerebri sans-light !important",
    borderRadius: 8,
    marginLeft: "10px",
    fontSize: 14,
    paddingTop: "4px",
  }),
  placeholder: (base, state) => ({
    ...base,
    position: "absolute",
    background: "rgb(255,255,255)",

    fontFamily:
      state.hasValue ||
        state.selectProps.inputValue ||
        state.selectProps.isFocused
        ? " Cerebri sans-medium !important"
        : "Cerebri sans-light !important",
    background: "#ffffff",
    borderRadius: 2,
    paddingLeft: "4px",
    paddingRight: "4px",
    // marginLeft: "10px",
    top:
      state.hasValue ||
        state.selectProps.inputValue ||
        state.selectProps.isFocused
        ? "-75%"
        : "0%",
    transition: "top 0.2s, font-size 0.2s",
    fontSize: state.selectProps.isFocused || state.hasValue ? 8 : 14,
    // (state.hasValue ||
    //   state.selectProps.inputValue ||
    //   state.selectProps.isFocused) &&
    // 14,
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    // paddingTop: "4px",
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? "#d1d1d1 !important" : "#d1d1d1 !important",
    borderRadius: "8px",
    backgroundColor: "#fff",
    fontSize: 14,
    "&:hover": {
      borderColor: "#d1d1d1",
      boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
    },
    pointerEvents: state.isDisabled && "auto",
    cursor: state.isDisabled ? "not-allowed " : "default",
    // opacity: state.isDisabled ? "0.6" : "1",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#000000", // Custom colour
    transition: "all .2s ease",
    transform: state.isFocused ? "rotate(180deg)" : null,
    padding: "10px 16px",
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    width: "0px",
  }),
  option: (base, { isDisabled }, state) => ({
    ...base,
    backgroundColor: "#fff",
    zIndex: 1,
    fontSize: 14,
    fontFamily: "Cerebri sans-light !important",
    color: isDisabled ? "#888888" : "#000000",
    paddingLeft: "15px",
    borderBottom: "1px solid #F0F",
    pending: "11px 16px 11px 16px !important",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 3,
    fontSize: 14,
    width: "max-content",
    minWidth: "100%",
    overflowX: "scroll",
  }),
  singleValue: (provided, { data }) => ({
    ...provided,
    marginLeft: "4px",
    // specify a fallback color here for those values not accounted for in the styleMap
  }),
  menuPortal: (base) => ({ ...base, zIndex: 10000 }),
};
// Set custom `SingleValue` and `Placeholder` to keep them when searching
export const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.key !== "placeholder" ? child : null
      )}
    </ValueContainer>
  );
};
export const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon
          icon="fa-solid fa-chevron-down"
          style={{ color: "#000000" }}
        />
      </components.DropdownIndicator>
    )
  );
};
export const ClearIndicator = (props) => {
  return (
    components.ClearIndicator && (
      <components.ClearIndicator {...props}>
        <FontAwesomeIcon
          icon="fa-solid fa-xmark"
          style={{ color: "#000000" }}
        />
      </components.ClearIndicator>
    )
  );
};
const CustomReactSelect = ({
  options,
  inputValue,
  isSearchable,
  value,
  placeholder,
  innerLabel,
  iserror,
  errorScope = true,
  errorMessage,
  description,
  bgColor,
  border,
  dropDownIndicator,
  name,
  setValue,
  height,
  disabled,
  handleInputChange = () => { },

}) => {
  const containerRef = useRef(null);
  const selectInputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [inputValuea, setInputValuea] = useState(inputValue);

  const onDomClick = (e) => {
    let menu = containerRef.current?.querySelector(".select__menu");

    if (
      !containerRef?.current?.contains(e.target) ||
      !menu ||
      !menu?.contains(e.target)
    ) {
      setIsFocused(false);
      setInputValuea("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onDomClick);

    return () => {
      document.removeEventListener("mousedown", onDomClick);
    };
  }, []);

  //Custom search filter
  const customFilter = (o) => {
    if (
      o.countryName.toLowerCase().includes(inputValuea?.toLowerCase()) ||
      o.phoneId.toLowerCase().includes(inputValuea?.toLowerCase()) ||
      o.value.toLowerCase().includes(inputValuea?.toLowerCase()) ||
      (o.value + " " + o.searchText)
        .toLowerCase()
        .includes(inputValuea?.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div ref={containerRef}>
      <Select
        ref={selectInputRef}
        className="basic-single"
        isDisabled={disabled}
        // filterOption={customFilter}
        styles={
          innerLabel === true
            ? colourStyles
            : normalInputStyle(iserror, bgColor, border, dropDownIndicator)
        }
        // classNamePrefix="select"
        // options={options}
        options={options?.filter((o) => customFilter(o))}
        components={{
          MenuList: CustomMenuList,
          ValueContainer: CustomValueContainer,
          DropdownIndicator,
          // ClearIndicator,
        }}
        value={value}
        setInputValuea={setInputValuea}
        // inputValue={inputValuea}
        placeholder={placeholder}
        menuPlacement={"auto"}
        // isSearchable={false}
        description={description}
        isSearchable={isSearchable}
        onMenuInputFocus={() => setIsFocused(true)}
        onChange={(selectedOption, { action, inputValue }) => {
          setValue(name, selectedOption.value, { shouldValidate: true });
          setIsFocused(false);
          handleInputChange()

        }}
        // onInputChange={onInputChange}
        theme={(theme) => ({
          ...theme,
          // borderRadius: 0,
          spacing: {
            ...theme.spacing,
            baseUnit: 2,
            controlHeight: height ? height : 40,
            menuGutter: 8,
          },
        })}
        {...{
          menuIsOpen: isFocused || undefined,
          isFocused: isFocused || undefined,
        }}
      />
      {/* {errorScope && iserror && (
        <p className="error-msg ">
          {errorMessage ? errorMessage : REQUIRED_ERR}
        </p>
      )} */}
    </div>
  );
};
CustomReactSelect.defaultProps = {
  searchable: true,
  clearable: true,
  disabled: false,
  noOptionsMessage: "No options",
};
export default CustomReactSelect;
