// Custom hook for managing loading state and progress
import { useState } from "react";
import { useLoadingBarStore } from "../hooks/stores/useProgressBarStore";
import { toast } from "react-toastify";

const useLoadingProgress = () => {
  const { isLoading, progress, setProgress, setLoading, setLoaderSpeed } =
    useLoadingBarStore();

  const showProgressBarOnInitialState = (
    initialProgressCount,
    message,
    initialLoading,
    finalInitialValue,
    loaderSpeedValue
  ) => {
    console.log("initialProgressCount", initialProgressCount);
    console.log("message", message);
    console.log("initialLoading", initialLoading);
    console.log("finalInitialValue", finalInitialValue);
    console.log("loaderSpeedValue", loaderSpeedValue);
    setLoaderSpeed(loaderSpeedValue ? loaderSpeedValue : 2500);

    const updateProgress = (progressValue) => {
      setProgress(progressValue);
      if (progressValue < (finalInitialValue ? finalInitialValue : 50)) {
        updateProgress(progressValue + 10);
      }
    };
    // setTimeout(() => updateProgress(progressValue + 10), 100);

    updateProgress(initialProgressCount == "" ? 10 : initialProgressCount || 0);

    setLoading(initialLoading);
    toast(message == "" ? null : message);
  };

  const showProgressBar = (
    { result, error, type },
    progress,
    loading,
    finalValue,
    loaderSpeedValue
  ) => {
    setLoaderSpeed(loaderSpeedValue == "" ? 500 : loaderSpeedValue);
    const updateProgress = (progressValue) => {
      setProgress(progressValue);
      if (progressValue < (finalValue ? finalValue : 100)) {
        setTimeout(() => updateProgress(progressValue + 10), 500);
        updateProgress(progressValue + 10);
      }
    };

    updateProgress(progress == "" ? 10 : progress || 0);

    type == false
      ? // setTimeout(() => {
        toast.warn(result?.data?.message)
      : // }, 5000)
        // setTimeout(() => {
        toast.success(result?.data?.message);
    // }, 5000);

    // Error
    // setTimeout(() => {
    toast.error(error?.message);
    // }, 5000);
    // setTimeout(() => {
    setLoading(loading);
    // }, 5000);
  };

  return {
    isLoading,
    progress,
    showProgressBarOnInitialState,
    showProgressBar,
    setProgress,
  };
};

export default useLoadingProgress;
