import React, { useState } from "react";
import { CircleMsg, H4 } from "../../../../../styles/Common.style";
import ScreeningAccordionTable from "../../../standardComponents/ScreeningAccordionTable";
import RestartScreening from "../../popups/RestartScreening";
import { ActionsPopup } from "../../popups/ActionsPopup";
import FollowUp from "../../../standardComponents/follow up/FollowUp";
import AddResponsibleAgent from "../../../standardComponents/AddResponsibleAgent";

const Canceled = () => {
  // Handle the click event based on the item in common component
  const handleItemClick = (itemText) => {
    console.log(`Clicked on: ${itemText}`);
  };
 /*-- Follow-up customer  --*/
 const [showFollowsUpWizard, setShowFollowsUpWizard] = useState(false);
 const [selectedFollowUpIndex, setSelectedFollowUpIndex] = useState(0);
 const handleCustomerDetails = (pageIndex = 0) => {
   // console.log(pageIndex, "i asssssssssssss");
   setShowFollowsUpWizard(true);
   setSelectedFollowUpIndex(pageIndex);
 };
  /*-- Restart Screening --*/
  const [showStartScreening, setShowStartScreening] = useState(false);
  /*-- Archive screening --*/
  const [ArchiveScreen, setArchiveScreen] = useState(false);
  const archiveScreening = (item) => {
    setArchiveScreen(true);
  };
   /*-- change agent --*/
   const [changeAgent, setChangeAgent] = useState(false);
   const handleChangeAgent = (item) => {
     setChangeAgent(true);
   }

  // // customers context menu bar for cancel
  const CanceledMenuItems = [
    {
      text: "Archive",
      onClick: archiveScreening,
    },
    {
      text: "Restart screening",
      onClick: () => setShowStartScreening(true),
    },

    {
      text: "Add follow ups",
      onClick: ()=> handleCustomerDetails(0),
    },
    {
      text: "Add customer as favorite",
      onClick: handleItemClick,
    },
    {
      text: "Go to contract",
      onClick: handleItemClick,
    },
    {
      text: "Go to contact card",
      onClick: ()=> handleCustomerDetails(0),
    },
    {
      text: "Change responsible agent",
      onClick: handleChangeAgent,
    },
  ];

  // main frame container for decline screen & Cancel screening
  const MainFrameMenuDS = [
    {
      text: "Archive all screenings",
      onClick: handleItemClick,
    },
    {
      text: "Add unit as favourite",
      onClick: handleItemClick,
    },
  ];
  return (
    <div>
      {" "}
      <div className="px-4">
        <div className="d-flex ">
          <div className="my-2">
            <div className="d-flex align-items-center pt-3 pb-2">
              <H4>Canceled screenings</H4>
              <CircleMsg>
                <span>{CanceledScreeningData?.length}</span>
              </CircleMsg>
            </div>
            <div className="light-font-text">
              Below is a list of the screenings that have been approved by a
              user in the system, but where an agreement has not yet been
              created yet.
            </div>
          </div>
        </div>
        {/* table section */}
        <div>
          <ActionsPopup
            open={ArchiveScreen}
            onHide={() => setArchiveScreen(false)}
            whiteClick={() => setArchiveScreen(false)}
            title={"Archive Customer"}
            primaryButton={"Archive"}
            titleTooltipText={"Archive Customer"}
            firstText={"Do you want to archive this customer?"}
            secondText={
              "The customer will be archived and moved to Archive tab."
            }
          />

          <RestartScreening
            open={showStartScreening}
            onHide={() => setShowStartScreening(false)}
            onCancel={() => setShowStartScreening(false)}
            usedFor="RS"
          />
              <FollowUp
          setFollowUp={setShowFollowsUpWizard}
          followUp={showFollowsUpWizard}
          selectedIndex={selectedFollowUpIndex}
          setSelectedIndex={setSelectedFollowUpIndex}
        />
          <ScreeningAccordionTable
            data={CanceledScreeningData}
            usedFor="CANS"
            MainFrameMenu={MainFrameMenuDS}
            CustomersContextmenuItemsCondition1={CanceledMenuItems}
            CustomersContextmenuItemsCondition2={CanceledMenuItems}
          />
            <AddResponsibleAgent
            showResponsibleAgentPopup={changeAgent}
            setShowResponsibleAgentPopup={setChangeAgent}
            usedFor="EDIT"
            data={""}
          // leadId={leadId}
          // createUpdateLeadAgentMutate={createUpdateLeadAgentMutate}
          // createUpdateLeadAgentLoading={createUpdateLeadAgentLoading}
          // leadsByDivisionIdDataRefetch={leadsByDivisionIdDataRefetch}
          />
        </div>
      </div>
    </div>
  );
};

export default Canceled;

// Canceled static data
const CanceledScreeningData = [
  {
    id: 1,
    unit: "Dybbølsbro 3, 1 | 212-004",
    property: "Kaktus Towers",
    deal: "No priority set",
    active_screening: "0",
    screening_started: "2 Jul, 2023 at 10:32",
    status: "ALERT",
    bg_color: "#AA1518",
    progress: 20,
    declined_reasons: "Does not meet our requirements for device rental",
    iconData: [
      {
        id: 1,
        ico_name: "fa-solid fa-user",
        iconColor: "#AA1518",
        category: "Information",
      },
      {
        id: 2,
        ico_name: "fa-solid fa-briefcase",
        iconColor: "#D1D1D1",
        category: "Occupation",
      },
      {
        id: 3,
        ico_name: "fa-solid fa-building-columns",
        iconColor: "#349032",
        category: "Economy",
      },
      {
        id: 4,
        ico_name: "fa-solid fa-house-user",
        iconColor: "#D1D1D1",
        category: "Household",
      },
      {
        id: 5,
        ico_name: "fa-solid fa-passport",
        iconColor: "#D1D1D1",
        category: "Identification",
      },
      {
        id: 6,
        ico_name: "fa-solid fa-circle-check",
        iconColor: "#AA1518",
        category: "Confirmation",
      },

      {
        id: 7,
        ico_name: "fa-solid fa-circle-info",
        iconColor: "#D1D1D1",
        category: "Additional information",
      },
      {
        id: 8,
        ico_name: "fak fa-salary-slip-svg-test2",
        iconColor: "#D1D1D1",
        category: "Salary slip",
      },
    ],
  },
  {
    id: 4,
    unit: "Kalundborg Hovedvejen 1,| 049-200",
    property: "Munkesøhuse",
    deal: "No priority set",
    active_screening: "0",
    screening_started: "19 Aug, 2023 at 20:47",
    status: "ALERT",
    bg_color: "#AA1518",
    progress: 10,
    declined_reasons: "Other reason",
    iconData: [
      {
        id: 1,
        ico_name: "fa-solid fa-user",
        iconColor: "#349032",
        category: "Information",
      },
      {
        id: 2,
        ico_name: "fa-solid fa-briefcase",
        iconColor: "#D1D1D1",
        category: "Occupation",
      },
      {
        id: 3,
        ico_name: "fa-solid fa-building-columns",
        iconColor: "#D1D1D1",
        category: "Economy",
      },
      {
        id: 4,
        ico_name: "fa-solid fa-house-user",
        iconColor: "#D1D1D1",
        category: "Household",
      },
      {
        id: 5,
        ico_name: "fa-solid fa-passport",
        iconColor: "#AA1518",
        category: "Identification",
      },
      {
        id: 6,
        ico_name: "fa-solid fa-circle-check",
        iconColor: "#D1D1D1",
        category: "Confirmation",
      },

      {
        id: 7,
        ico_name: "fa-solid fa-circle-info",
        iconColor: "#D1D1D1",
        category: "Additional information",
      },
      {
        id: 8,
        ico_name: "fak fa-salary-slip-svg-test2",
        iconColor: "#D1D1D1",
        category: "Salary slip",
      },
    ],
  },
];
