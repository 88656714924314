import React, { useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import { Line } from "react-chartjs-2";
import Menus from "../../Components/Menus/Menus";
import NoDataScreen from "../../Components/DisplayNoData/NoDataScreen";
import { H2 } from "../../styles/Common.style";

const ContactsOverview = () => {
	const fileName = "progress-over-time-status";
	const myref = useRef();
	const [refObj] = useState(myref);
	const data = {
		labels: [
			"Oct ",
			"Nov ",
			"Dec ",
			"jan ",
			"Feb ",
			"Mar ",
			"Apr ",
			"May ",
			"Jun ",
			"Jul ",
			"Aug ",
			"Sep ",
			"Oct ",
			"Nov ",
		],
		//  myData.map((x) =>
		//   value == 1
		//     ? x.YearMonth
		//     : new Date(x.YearMonth).toLocaleDateString("en-En", {
		//         year: "numeric",
		//         month: "short",
		//       })
		// ),

		datasets: [
			{
				label: "Unassgined leads",
				fill: false,
				borderColor: "#888888",
				fontfamily: "Cerebri sans-bold",
				backgroundColor: "#888888",
				tension: 0.2,
				data: [10, 30, 65, 58, 68, 60, 70, 90, 110, 100, 123, 130, 140, 150],
				unitStatus: [
					114, 558, 24, 88, 114, 558, 24, 88, 114, 558, 24, 88, 114, 558,
				],
			},
			{
				label: "New leads",
				fill: false,
				borderColor: "#77C7A1",
				backgroundColor: "#77C7A1",
				tension: 0.2,
				data: [112, 90, 90, 85, 95, 80, 80, 92, 97, 60, 67, 50, 20],
				unitStatus: [
					114, 558, 24, 88, 114, 558, 24, 88, 114, 558, 24, 88, 114, 558,
				],
			},
			{
				label: "Ongoing reservations",
				fill: false,
				borderColor: "#F6E651",
				backgroundColor: "#F6E651",
				tension: 0.2,
				data: [40, 30, 35, 25, 30, 40, 50, 30, 45, 33, 40, 25, 27],
				unitStatus: [
					114, 558, 24, 88, 114, 558, 24, 88, 114, 558, 24, 88, 114, 558,
				],
			},
			{
				label: "Ongoing screenings",
				fill: false,
				borderColor: "#A2C8D0",
				backgroundColor: "#A2C8D0",
				tension: 0.2,
				data: [10, 30, 65, 58, 68, 60, 70, 90, 110, 100, 123, 130, 140, 150],
				unitStatus: [
					114, 558, 24, 88, 114, 558, 24, 88, 114, 558, 24, 88, 114, 558,
				],
			},
			{
				label: "Ongoing signings",
				fill: false,
				borderColor: "#A2C8D0",
				backgroundColor: "#A2C8D0",
				tension: 0.2,
				data: [10, 30, 65, 58, 68, 60, 70, 90, 110, 100, 123, 130, 140, 150],
				unitStatus: [
					114, 558, 24, 88, 114, 558, 24, 88, 114, 558, 24, 88, 114, 558,
				],
			},
			{
				label: "Tenants",
				fill: false,
				borderColor: "#A2C8D0",
				backgroundColor: "#A2C8D0",
				tension: 0.2,
				data: [10, 30, 65, 58, 68, 60, 70, 90, 110, 100, 123, 130, 140, 150],
				unitStatus: [
					114, 558, 24, 88, 114, 558, 24, 88, 114, 558, 24, 88, 114, 558,
				],
			},
		],
	};
	const getOrCreateTooltip = (chart) => {
		let tooltipEl = chart.canvas.parentNode.querySelector("div");

		if (!tooltipEl) {
			tooltipEl = document.createElement("div");
			tooltipEl.style.background = "#fff";
			tooltipEl.style.borderRadius = "3px";
			tooltipEl.style.border = "1px solid #F4F5F5";
			tooltipEl.style.color = "black";
			tooltipEl.style.opacity = 1;
			tooltipEl.style.pointerEvents = "none";
			tooltipEl.style.position = "absolute";
			tooltipEl.style.transform = "translate(-50%, 0)";
			tooltipEl.style.transition = "all .1s ease";
			tooltipEl.style.zIndex = "10";

			const table = document.createElement("table");
			table.style.margin = "0px 10px";

			tooltipEl.appendChild(table);
			chart.canvas.parentNode.appendChild(tooltipEl);
		}

		return tooltipEl;
	};

	const externalTooltipHandler = (context) => {
		// Tooltip Element
		const { chart, tooltip } = context;
		const tooltipEl = getOrCreateTooltip(chart);

		// Hide if no tooltip
		if (tooltip.opacity === 0) {
			tooltipEl.style.opacity = 0;
			return;
		}

		// Set Text
		if (tooltip.body) {
			// console.log(tooltip.body,"body");
			const titleLines = tooltip.title || [];
			const bodyLines = tooltip.body.map(
				(b) => `${b.before}, ${b.lines}, ${b.after}`
			);
			//  console.log(bodyLines,"bodylines");
			const tableHead = document.createElement("thead");

			titleLines.forEach((title) => {
				const tr = document.createElement("tr");
				tr.style.borderWidth = 0;

				const th = document.createElement("th");
				th.style.borderWidth = 0;
				const text = document.createTextNode(title);

				th.appendChild(text);
				tr.appendChild(th);
				tableHead.appendChild(tr);
			});

			const tableBody = document.createElement("tbody");
			bodyLines.forEach((body, i) => {
				const colors = tooltip.labelColors[i];
				//  console.log(body.split(","),i,"tablebody")
				const span = document.createElement("span");
				// span.style.background = tr;
				// span.style.borderColor = colors.borderColor;
				span.style.borderWidth = "2px";
				span.style.marginRight = "10px";
				span.style.height = "10px";
				span.style.width = "10px";
				span.style.fontSize = "13px";
				span.style.display = "block";

				const tr = document.createElement("tr");
				tr.style.backgroundColor = "inherit";
				tr.style.borderWidth = 0;
				tr.style.fontSize = "13px";

				const tr2 = document.createElement("tr");
				tr2.style.backgroundColor = "inherit";
				tr2.style.borderWidth = 0;
				tr2.style.fontSize = "13px";
				tr2.style.fontFamily = " Cerebri sans-light";

				const tr3 = document.createElement("tr");
				tr3.style.backgroundColor = "inherit";
				tr3.style.borderWidth = 0;
				tr3.style.fontSize = "13px";
				tr3.style.color = "#888888";
				tr3.style.fontFamily = " Cerebri sans-light";

				const tr4 = document.createElement("tr");
				tr2.style.fontSize = "13px";

				const td = document.createElement("td");
				const br1 = document.createElement("br");
				const br2 = document.createElement("br");

				const allBody = body.split(",");
				const text1 = document.createTextNode(allBody[0]);
				// text1.style.fontSize="13px";
				const text2 = document.createTextNode(allBody[1]);
				const text3 = document.createTextNode(allBody[2]);
				const text4 = document.createTextNode("");

				td.appendChild(span);
				tr.appendChild(text1);
				// tr.appendChild(br1);
				tr2.appendChild(text2);
				// tr.appendChild(br2);
				tr3.appendChild(text3);
				tr4.appendChild(text4);

				tableBody.appendChild(td);
				tableBody.appendChild(tr);
				tableBody.appendChild(tr2);
				tableBody.appendChild(tr3);
				tableBody.appendChild(tr4);
			});

			const tableRoot = tooltipEl.querySelector("table");

			// Remove old children
			while (tableRoot.firstChild) {
				tableRoot.firstChild.remove();
			}

			// Add new children
			tableRoot.appendChild(tableHead);
			tableRoot.appendChild(tableBody);
		}

		const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

		// Display, position, and set styles for font
		tooltipEl.style.opacity = 1;
		tooltipEl.style.left = positionX + tooltip.caretX + "px";
		tooltipEl.style.top = positionY + tooltip.caretY + "px";
		tooltipEl.style.font = tooltip.options.bodyFont.string;
		tooltipEl.style.padding =
			tooltip.options.padding + "px " + tooltip.options.padding + "px";
	};
	const options = {
		maintainAspectRatio: false,
		plugins: {
			tooltip: {
				enabled: true,
				position: "average",
				external: externalTooltipHandler,
				callbacks: {
					title: function (tooltipItem, index) {
						let title = "";
						// title.push(tooltipItem.dataset.label);
						// console.log(tooltipItem[0].dataset.label,tooltipItem[1].dataset.label,tooltipItem[2].dataset.label,tooltipItem[3].dataset.label, "title")
						return title;
					},
					beforeLabel: function (tooltipItem, index) {
						let beforlabel = [];
						beforlabel.push(tooltipItem.dataset.label);
						let myhome = beforlabel[0];
						// console.log(beforlabel[0]);
						// tooltipItem[0].dataset.label
						// console.log(tooltipItem, "title");
						// console.log(tooltipItem.dataset.label, "title");
						return myhome;
					},
					label: function (context) {
						let lable = "";
						if (context.parsed.y !== null) {
							lable +=
								new Intl.NumberFormat("en-DK").format(context.parsed.y) +
								" DKK";
						}
						return lable;
					},
					afterLabel: function (tooltipItem, context) {
						let unit = tooltipItem.dataset.unitStatus[tooltipItem.dataIndex];
						let unitTitle = tooltipItem.dataset.label;
						//  console.log(tooltipItem.dataIndex);
						//  console.log(tooltipItem);
						// console.log(tooltipItem.dataset.unitStatus[tooltipItem.dataIndex]);
						return unit + " units " + unitTitle;
					},
				},
				// backgroundColor: "#FFFFFF",
				// borderDash: [2, 2],
				// titleColor: "#000000",
				// bodyColor: "#000000",
				// padding: 20,
				// cornerRadius: 8,
				// borderColor: "#F4F5F5",
				// borderWidth: 1,
				// bodySpacing:5,
				// displayColors:false,
				// titleSpacing:20,
			},
			legend: {
				display: true,
				position: "bottom",
				align: "center",

				labels: {
					boxWidth: 8,
					boxHeight: 8,
					padding: 20,
					color: "#00000",
					font: { family: "Cerebri sans-medium", size: "12px" },
				},
			},
		},
		scales: {
			x: {
				grid: {
					color: "rgba(0,0,0,0.0)",
				},
				ticks: {
					fontSize: 18,
					padding: 0,
					color: "#888888",
					font: {
						family: "Cerebri sans-light",
					},
				},
			},
			y: {
				grid: {
					color: "rgba(0,0,0,0.0375)",
					fontColor: "#fff",
				},
				ticks: {
					color: "#888888",
					font: {
						family: "Cerebri sans-light",
					},
				},
			},
		},
	};

	return (
		<div className="" style={{ backgroundColor: "#ffffff" }}>
			<div className=" d-flex W-100 align-items-center p-4 fs-14 ">
				<H2 className="me-2 ">Overview</H2>
				<div>
					<CustomToolTip height={12} text="Overview" />
				</div>
			</div>{" "}
			<hr className="hr m-0" />
			<div className="p-4">
				<Row className="mb-3">
					<Col lg={4} className="pe-lg-2 mb-sm-3 mb-lg-0">
						<div className=" bgcolor-d-white radius-8 p-4 d-flex flex-column align-items-center justify-content-center lite-grey-border ">
							{" "}
							<div className="actors-section-title fs-24">0 </div>
							<div className="font-light fs-18">Unassigned leads</div>
						</div>
					</Col>{" "}
					<Col lg={4} className="px-lg-2 mb-sm-3 mb-lg-0">
						<div className=" bgcolor-d-white radius-8 p-4 d-flex flex-column align-items-center justify-content-center lite-grey-border ">
							{" "}
							<div className="actors-section-title fs-24">0</div>
							<div className="font-light fs-18">New leads</div>
						</div>
					</Col>{" "}
					<Col lg={4} className="ps-lg-2  mb-lg-0">
						<div className=" bgcolor-d-white radius-8 p-4 d-flex flex-column align-items-center justify-content-center lite-grey-border ">
							{" "}
							<div className="actors-section-title fs-24 ">0</div>
							<div className="font-light fs-18">Ongoing reservations</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col lg={4} className="pe-lg-2 mb-sm-3 mb-lg-0">
						{" "}
						<div className=" bgcolor-d-white radius-8 p-4 d-flex flex-column align-items-center justify-content-center lite-grey-border ">
							{" "}
							<div className="actors-section-title fs-24">0</div>
							<div className="font-light fs-18">Ongoing screenings</div>
						</div>
					</Col>
					<Col lg={4} className="px-lg-2 mb-sm-3 mb-lg-0">
						{" "}
						<div className=" bgcolor-d-white radius-8 p-4 d-flex flex-column align-items-center justify-content-center lite-grey-border ">
							{" "}
							<div className="actors-section-title fs-24">0</div>
							<div className="font-light fs-18">Ongoing signings</div>
						</div>
					</Col>
					<Col lg={4} className="ps-lg-2 mb-sm-3 mb-lg-0">
						{" "}
						<div className=" bgcolor-d-white radius-8 p-4 d-flex flex-column align-items-center justify-content-center lite-grey-border ">
							{" "}
							<div className="actors-section-title fs-24">0</div>
							<div className="font-light fs-18">Tenants</div>
						</div>
					</Col>
				</Row>
			</div>
			<div className="pb-4">
				<div
					className="card-body mx-4 p-4 mb-0 "
					style={{ border: "1px solid #f4f5f5" }}
					ref={myref}
				>
					<Row>
						<Col className="d-flex align-items-center" sm={6}>
							{" "}
							<div className="d-flex">
								<H2 className=" me-2">
									Progress over time divided by status
								</H2>{" "}
								<CustomToolTip
									height="11px"
									text="Progress over time divided by status"
								/>
							</div>
						</Col>
						<Col
							className="ps-0 d-flex justify-content-end"
							sm={{ span: 2, offset: 4 }}
						>
							<Menus
								itemheight={40}
								itemwidth={40}
								itemcontainerheight={200}
								itemcontainerwidth={200}
								// component={
								//   <div className="fs-14">
								//     <div className="addpropContent px-3 py-2 ">
								//       <span className="ms-1">Download widget as PNG</span>
								//     </div>
								//     <hr className="addpropertyHr m-0"></hr>
								//     <div className="addpropContent px-3 pt-2 pb-2 ">
								//       <span className="ms-1">Download widget as JPG</span>
								//     </div>
								//     <hr className="addpropertyHr m-0"></hr>
								//     <div className="addpropContent px-3 pt-2 pb-2 ">
								//       <span className="ms-1">Download widget as SVG</span>
								//     </div>
								//   </div>
								// }
							/>
						</Col>
					</Row>

					<Row className="mt-5">
						<Col>
							<div
								style={{
									height: "378px",
									zIndex: "1",
								}}
							>
								{/* <Line data={data} options={options} /> */}
								<NoDataScreen height={"377px"} imgHeight={"150px"} />
							</div>{" "}
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};

export default ContactsOverview;
