import React, { useState, useEffect } from "react";
import { Container, Placeholder } from "react-bootstrap";
import { MemoizedPropertiesTable } from "./PropertiesTable";
import { useAllPropertyList, useGetPropertiesByLevel } from "../../hooks/queries/usePropertyQueries";
import { useAllPropertiesData } from "../../hooks/stores/usePropertyStore";
import { H1 } from "../../styles/Common.style";

import { useDivisionId } from "../../hooks/stores/useDivisionsStore";
import { useEditCompany } from "../../hooks/stores/useCompanyStore";
//LAZY const PropertiesTable = lazy(() => import("./PropertiesTable.js"));

const PropertiesList = ({
  setShowPage,
  EditPropertyID,
  setEditPropertyID,
  setCountryName,
  setCountryId,
  setButtonType,
  setOperationName,
  setEditPortfolioId,
  setTableData,
  activePortfoliTabs,
  setActivePortfolioTabs,
  activeDefaultTabs,
  setActiveDefaultTabs,
  flag,
}) => {
  // ----------------------- Zustand -------------------------//
  const { divisionId } = useDivisionId((state) => state);
  const { companyId } = useEditCompany((state) => state);
  const [listType, setListType] = useState(0);
  // const {
  //   data: allPropertiesData,
  //   isLoading,
  //   isError,
  //   refetch: allPropertyDataRefetch,
  // } = useAllPropertyList(
  //   flag == "company" ? "c" : flag == "DL" ? "d" : "d",
  //   flag == "company" ? companyId : flag == "DL" ? divisionId : divisionId,
  //   "details"
  // );
  const {
    data: GetPropertiesByLevelData,
    isLoading,
    isError,
    refetch: GetPropertiesByLevelDataRefetch,
  } = useGetPropertiesByLevel(
    flag == "company" ? "c" : flag == "DL" ? "d" : "d",
    flag == "company" ? companyId : flag == "DL" ? divisionId : divisionId,
    "details",
    activeDefaultTabs === 'Favorites' ? "favorites" : "all"
  );

  const [propertyTypes, setPropertyTypes] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [showComponent, setShowComponent] = useState("M");
  const [propertyTypeSelected, setPropertyTypeSelected] = useState([]);

  const [country, setCountry] = useState([]);
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);

  // zustand
  const {
    allPropertiesStoreData,
    setAllPropertiesStoreData,
    favoritePropertiesData,
    setFavoritePropertiesData,
  } = useAllPropertiesData((state) => state);
  // const allPropertiesStoreData = useAllPropertiesData(
  // 	(state) => state.allPropertiesStoreData
  // );
  // const setAllPropertiesStoreData = useAllPropertiesData(
  // 	(state) => state.setAllPropertiesStoreData
  // );
  const [allPropertyList, setAllPropertyList] = useState([]);

  // useEffect(() => {
  //   // setAllPropertyList(
  //   // 	allPropertiesData?.data
  //   // 		.sort((a, b) => a.propertyName.localeCompare(b.propertyName))
  //   // 		.map((item, i) => item)
  //   // );
  //   setAllPropertiesStoreData(
  //     allPropertiesData?.data
  //       .sort((a, b) => a.propertyName?.localeCompare(b.propertyName))
  //       .map((item, i) => item)
  //   );
  // }, [allPropertiesData]);

  // useEffect(() => {
  // 	setPropertyTypes(propertyTypesData?.data);
  // }, [propertyTypesData]);

  // useEffect(() => {
  // 	setCountry(countryData?.data);
  // }, [countryData]);

  return (
    <>
      {showComponent === "M" && (
        <Container fluid className="px-0 pt-1">
          {flag == "PL" ? (
            isLoading ? (
              <div className="my-4 pb-1">
                <div className="fs-4 fw-600">
                  <Placeholder as="p" animation="glow">
                    <Placeholder xs={1} className=" bg-secondary" />
                  </Placeholder>
                </div>
                <div className="light fs-7  fw-300">
                  <Placeholder as="p" animation="glow">
                    <Placeholder xs={2} className=" bg-secondary" />
                  </Placeholder>
                </div>
              </div>
            ) : (
              <div className="my-4 pb-1">
                <H1>Properties</H1>
                <div className="light-font-text">Lorem ipsum dolor sit</div>
              </div>
            )
          ) : (
            <></>
          )}
          <div
            className="p-lg-4 p-3 containerStyle"
            style={{
              minHeight: "460px",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                Height: "max-content",
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
            >
              <div
                className="table-responsive "
                style={{
                  overflow: "hidden",
                  borderRadius: "8px",
                  backgroundColor: "#F1F4EF",
                }}
              >
                {/* {allPropertiesStoreData?.length === 0 ? (
                  <div
                    className="m-3 text-center py-4 bg-light text-muted"
                    style={{
                      minHeight: "92px",
                      border: "1px solid #F4F5F5",
                      borderRadius: "4px",
                    }}
                  >
                    <div className="fs-12 mt-3">
                      You do not have any projects yet. Click on “+ ADD
                      PROPERTY” to create your first.
                    </div>
                  </div>
                ) : ( */}
                {/*LAZY <Suspense fallback={<Placeholder as={<PropertiesTable />} />}> */}
                {/*LAZY <PropertiesTable */}
                <MemoizedPropertiesTable
                  setButtonType={setButtonType}
                  setTableData={setTableData}
                  EditPropertyID={EditPropertyID}
                  setEditPropertyID={setEditPropertyID}
                  allPropertyList={allPropertyList}
                  show={show}
                  // allPropertyDataRefetch={allPropertyDataRefetch}
                  setShow={setShow}
                  // Loading={isLoading}
                  // isError={isError}
                  setShowPage={setShowPage}
                  setDropdownOpen={setDropdownOpen}
                  setCountryId={setCountryId}
                  setCountryName={setCountryName}
                  setPropertyTypeSelected={setPropertyTypeSelected}
                  propertyTypeSelected={propertyTypeSelected}
                  setShowComponent={setShowComponent}
                  setOperationName={setOperationName}
                  setEditPortfolioId={setEditPortfolioId}
                  setListType={setListType}
                  activePortfoliTabs={activePortfoliTabs}
                  setActivePortfolioTabs={setActivePortfolioTabs}
                  activeDefaultTabs={activeDefaultTabs}
                  setActiveDefaultTabs={setActiveDefaultTabs}
                  usedFor={flag}
                  tableData={GetPropertiesByLevelData?.data}
                  GetPropertiesByLevelDataRefetch={GetPropertiesByLevelDataRefetch}
                />
                {/* </Suspense> */}

                {/* )} */}
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

// export default PropertiesList;
export const MemoizedPropertiesList = React.memo(PropertiesList);
