import React, { useEffect, useState } from "react";

import { Col, Row, Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ETButton from "../../Components/Buttons/ETButton";
import Toggle from "../../Components/Switch/Switch";
import ReactSelect from "../../Components/DropDown/ReactSelect";
// import CustomTextfield from "../../Components/Textfield/Textfield";
import {
	useEditFacilityDetail,
	useEditUnitFacilityAttributeValue,
	useGetFacilityByPropertyId,
	useGetFacilityByUnitId,
} from "../../hooks/queries/usefacilityQueries";
import { toast } from "react-toastify";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { REQUIRED_ERR } from "../../utils/validationConstants";
import GroupText from "../../Components/GroupText/GroupText";

const AddDetailPopup = ({
	setAddDetail,
	facilityId,
	propertyId,
	UnitId,
	facilityLevel,
	packageId,
	GetFacilityPackageByPackageIdData,
	GetFacilityPackageByPackageIdDataRefetch,
	GetFacilityAttributesByFacilityIdData,
	GetFacilityAttributesByFacilityIdDataRefetch,
	GetFacilityByPropertyIdRefetch,
	getFacilityByUnitIdRefetch,
	refetch,
	// toggleYes,
	// setToggleYes,
}) => {
	const [defaultAttributeFieldDetail, setDefaultAttributeFieldDetail] =
		useState([]);
	const [selectedFacility, setSelectedFacility] = useState([]);
	const [toggleYes, setToggleYes] = useState(false);

	const {
		register,
		formState: { errors },
		handleSubmit,
		control,
		reset,
	} = useForm({
		defaultValues: {
			attributeDetails: [{ row_id: Math.floor(Date.now() / 1000), fields: [] }],
		},
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: "attributeDetails",
	});

	const {
		mutateAsync: EditUnitFacilityAttributeValueMutate,
		isLoading: EditUnitFacilityAttributeValueMutateIsLoading,
	} = useEditUnitFacilityAttributeValue();
	const { data: GetFacilityByUnitIdData } = useGetFacilityByUnitId(
		propertyId,
		UnitId
	);
	const { data: GetFacilityByPropertyIdData } =
		useGetFacilityByPropertyId(propertyId);

	const { mutateAsync: EditFacilityDetailMutate } = useEditFacilityDetail();

	useEffect(() => {
		GetFacilityAttributesByFacilityIdDataRefetch();
		// GetFacilityByPropertyIdRefetch();
		if (facilityLevel === "package") {
			let facilityData = GetFacilityAttributesByFacilityIdData?.data[0];
			let facility =
				GetFacilityPackageByPackageIdData?.data?.[0]?.facility_details?.filter(
					(fac) => fac.facility_id === facilityId
				);
			setSelectedFacility(facility);
			if (facility?.[0]?.setting == 0) {
				setToggleYes(false);
			} else if (facility?.[0]?.setting == 1) {
				setToggleYes(true);
			}
			onInitSetAttributeDetail(facilityData);
		} else {
			let facilityData = GetFacilityAttributesByFacilityIdData?.data[0];

			var facility = GetFacilityByPropertyIdData?.data?.filter(
				(fac) => fac?.facility_id == facilityData?.facility_id
			);
			setSelectedFacility(facility);
			if (facility?.[0]?.setting == 0) {
				setToggleYes(false);
			} else if (facility?.[0]?.setting == 1) {
				setToggleYes(true);
			}
			onInitSetAttributeDetail(facilityData);
		}
	}, [GetFacilityAttributesByFacilityIdData, facilityId]);
	let attributeFieldDetail;
	const onInitSetAttributeDetail = (data) => {
		//Default Attribute FieldDetail for append or default set when no record is there
		attributeFieldDetail = data?.attribute_field_details;

		setDefaultAttributeFieldDetail(attributeFieldDetail);

		if (data?.attribute_data_details?.length !== 0) {
			let facilityAttributeData = [];
			data?.attribute_data_details?.forEach((row, rowIndex) => {
				let fieldsData = [];
				row.forEach((attribute, colIndex) => {
					let fieldAttribute = structuredClone(
						attributeFieldDetail.find(
							(x) =>
								x?.facility_attribute_id == attribute?.facility_attribute_id
						)
					);

					fieldAttribute["attribute_value"] =
						fieldAttribute?.attribute_type === "DROPDOWN"
							? {
								value: attribute?.attribute_value,
								label: attribute?.attribute_value,
							}
							: attribute?.attribute_value;
					fieldsData?.push(fieldAttribute);
				});
				facilityAttributeData.push({
					row_id: Math.floor(Date.now() / 1000),
					fields: fieldsData,
				});
			});
			reset({
				attributeDetails: facilityAttributeData,
			});
		} else {
			reset({
				attributeDetails: [
					{
						row_id: Math.floor(Date.now() / 1000),
						fields: attributeFieldDetail,
					},
				],
			});
		}
	};
	const handleAttributeValueChange = (value, index, i) => {
		fields[index].fields[i]["attribute_value"] = value;
	};

	const handleAdd = () => {
		append({
			row_id: Math.floor(Date.now() / 1000),
			fields: defaultAttributeFieldDetail,
		});
	};

	const handleDelete = (i) => {
		remove(i);
	};
	let details = [];

	const handleSaveFacilityAttributes = async (data) => {
		let unitFacilityId;
		let facilityPackId;
		let propertyFacilityId;
		data?.attributeDetails?.map((item, index) => {
			let attr = [];
			item.fields.map((attribute, i) => {
				const { facility_attribute_id, attribute_value, attribute_type } =
					attribute;
				if (attribute_type === "DROPDOWN") {
					attr.push({
						facility_attribute_id: facility_attribute_id,
						attribute_value: attribute_value?.value,
					});
				} else {
					attr.push({
						facility_attribute_id: facility_attribute_id,
						attribute_value: attribute_value,
					});
				}
			});
			details.push(attr);
		});

		if (facilityLevel === "units") {
			let facility = GetFacilityByUnitIdData?.data?.filter(
				(fac, i) => fac?.facility_id == facilityId
			);

			unitFacilityId = facility[0]?.unit_facility_id;
			facilityPackId = 0;
			propertyFacilityId = 0;
		} else if (facilityLevel === "package") {
			unitFacilityId = 0;
			facilityPackId = packageId;
			propertyFacilityId = 0;
		} else if (facilityLevel === "property") {
			let Facility = GetFacilityByPropertyIdData?.data?.filter(
				(fac, i) => fac?.facility_id == facilityId
			);
			unitFacilityId = 0;
			facilityPackId = 0;
			propertyFacilityId = Facility[0]?.property_facility_id;
		}

		const editAttributeDetailPayload = {
			facility_package_id: String(facilityPackId),
			facility_id: String(
				GetFacilityAttributesByFacilityIdData?.data[0]?.facility_id
			),
			unit_facility_id: unitFacilityId,
			property_facility_id: propertyFacilityId,
			setting: toggleYes ? 1 : 0,
			details,
		};

		try {
			const response = await EditUnitFacilityAttributeValueMutate(
				editAttributeDetailPayload
			);
			if (response.status === "success" && response?.data?.bReturn) {
				// GetFacilityAttributesByFacilityIdDataRefetch();
				toast.success(response?.data?.message);
				setAddDetail(false);
				refetch();
				// GetFacilityAttributesByFacilityIdDataRefetch();
				// if (facilityLevel === "package") {
				//   console.log("package -- package");
				//   // GetFacilityPackageByPackageIdDataRefetch();
				// } else if (facilityLevel === "property") {
				//   GetFacilityByPropertyIdRefetch();
				// } else if (facilityLevel === "units") {
				//   getFacilityByUnitIdRefetch();
				// }

				// setAddDetail(false);

				remove();

				details = [];
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			<Form onSubmit={handleSubmit(handleSaveFacilityAttributes)}>
				<div className="px-3 pt-4 pb-3">
					<div className="form-label-title">Setting</div>
					<div className="light-font-text grey-text">
						It is possible to display this facility even if it does not exist at
						the unit. This could for example be parking, which you want to show
						does not exist as a facility on the unit. If you set the setting to
						no, the facility is displayed as Parking: No.
					</div>
				</div>
				<div
					className="px-3 pb-4 fs-14 d-flex align-items-center gap-2"
					style={{ borderBottom: "1px solid #F4F5F5" }}
				>
					No
					<Toggle
						id={"adddetailpopup1"}
						checked={toggleYes}
						onChange={(e) => setToggleYes(e.target.checked)}
						disabled={defaultAttributeFieldDetail?.length !== 0 ? false : true}
					/>
					Yes
				</div>

				{defaultAttributeFieldDetail?.length === 0 && (
					<>
						<div className="d-flex justify-content-between p-3">
							<div>
								<div className="form-label-title">A headline here</div>
								<div className="light-font-text grey-text">
									Lorem ipsum dolor sit
								</div>
							</div>

							<div>
								<ETButton
									variant={"primary"}
									buttonText="ADD"
									disabled={defaultAttributeFieldDetail?.length === 0}
									onClick={handleAdd}
								/>
							</div>
						</div>

						<div
							className="grey-text fs-12 light-font-text py-3 "
							style={{ textAlign: "center" }}
						>
							{" "}
							Facility Attributes Not Available Yet.
						</div>
					</>
				)}
				{toggleYes && defaultAttributeFieldDetail?.length !== 0 && (
					<>
						<div className="d-flex justify-content-between p-3">
							<div>
								<div className="form-label-title">A headline here</div>
								<div className="light-font-text grey-text">
									Lorem ipsum dolor sit
								</div>
							</div>

							<div>
								<ETButton
									variant={"primary"}
									buttonText="ADD"
									disabled={defaultAttributeFieldDetail?.length === 0}
									onClick={handleAdd}
								/>
							</div>
						</div>
						{
							<>
								{" "}
								{fields?.map((feild, index) => {
									return (
										<Row
											style={{
												display: "flex",
												justifyContent: "flex-start",
												padding: "1rem",
											}}
										>
											{feild?.fields?.map((f, i) => {
												return (
													<>
														{f?.attribute_type === "TEXT" ||
															(f?.attribute_type === "DECIMAL" ||
																f?.attribute_type === "NUMBER" ? (
																<Col lg={3}>
																	<Controller
																		key={`${index}${i}`}
																		control={control}
																		{...register(
																			`attributeDetails[${index}].fields[${i}].attribute_value`
																		)}
																		rules={{ required: true }}
																		render={({ field: { onChange, value } }) =>
																			f?.postfix !== "" ? (
																				<>
																					<GroupText
																						lable={f?.attribute_name}
																						type={f?.attribute_type}
																						name={f?.attribute_name}
																						id={`attributeDetails[${index}].fields[${i}].attribute_value`}
																						graylabel={f?.postfix}
																						textWidth="42%"
																						value={value}
																						onChange={(e) => {
																							handleAttributeValueChange(
																								e?.target.value,
																								index,
																								i
																							);
																							onChange(e);
																						}}
																					/>
																					{errors?.attributeDetails?.[index]
																						?.fields[i]?.attribute_value &&
																						errors?.attributeDetails?.[index]
																							?.fields[i]?.attribute_value
																							?.type && (
																							<p className="error-msg ">
																								{REQUIRED_ERR}
																							</p>
																						)}
																				</>
																			) : (
																				<>
																					<div className="form-input">
																						<input
																							type={f?.attribute_type}
																							placeholder={f?.attribute_name}
																							id={`attributeDetails[${index}].fields[${i}].attribute_value`}
																							value={value}
																							onChange={(e) => {
																								handleAttributeValueChange(
																									e?.target.value,
																									index,
																									i
																								);
																								onChange(e);
																							}}
																							style={{
																								width: "61% !important",
																							}}
																						/>
																						<label
																							htmlFor={`attributeDetails[${index}].fields[${i}].attribute_value`}
																						>
																							{f?.attribute_name}
																						</label>
																					</div>
																					{errors?.attributeDetails?.[index]
																						?.fields[i]?.attribute_value &&
																						errors?.attributeDetails?.[index]
																							?.fields[i]?.attribute_value
																							?.type && (
																							<p className="error-msg ">
																								{REQUIRED_ERR}
																							</p>
																						)}
																				</>
																			)
																		}
																	/>
																</Col>
															) : (
																f?.attribute_type === "DROPDOWN" && (
																	<Col lg={3}>
																		<Controller
																			control={control}
																			{...register(
																				`attributeDetails[${index}].fields[${i}].attribute_value`
																			)}
																			rules={{ required: true }}
																			id={`attributeDetails[${index}].fields[${i}].attribute_value`}
																			render={({
																				field: { onChange, value },
																			}) => (
																				<div>
																					<ReactSelect
																						// name={"attribute_value"}
																						placeholder={f?.attribute_name}
																						value={value}
																						options={f?.possible_values?.map(
																							(item) => ({
																								value: item?.Id,
																								label: item?.Text,
																							})
																						)}
																						onChange={(e) => {
																							handleAttributeValueChange(
																								e?.value,
																								index,
																								i
																							);
																							onChange(e);
																						}}
																						isSearchable={false}
																						isClearable={false}
																					/>
																					{errors?.attributeDetails?.[index]
																						?.fields[i]?.attribute_value &&
																						errors?.attributeDetails?.[index]
																							?.fields[i]?.attribute_value
																							?.type && (
																							<p className="error-msg ">
																								{REQUIRED_ERR}
																							</p>
																						)}
																				</div>
																			)}
																		/>
																	</Col>
																)
															))}
													</>
												);
											})}
											<Col
												md={1}
												className="d-flex "
												style={{
													textAlign: "center",
													alignItems: "center",
												}}
											>
												{" "}
												<div>
													{
														<button
															className="icon-btn"
															onClick={() => handleDelete(index)}
															disabled={fields?.length === 1 && true}
														>
															<FontAwesomeIcon icon="fa-regular fa-trash" />
														</button>
													}
												</div>
											</Col>
										</Row>
									);
								})}
							</>
						}
					</>
				)}
				<div
					className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
					style={{
						backgroundColor: "#F9FAFA",
						borderRadius: " 0px 0px 8px 8px",
						borderTop: "1px solid #F0F0F0",
					}}
				>
					{" "}
					<ETButton
						variant={"white"}
						buttonText="CANCEL"
						onClick={() => setAddDetail(false)}
					/>
					<ETButton
						type="submit"
						variant={"primary"}
						buttonText="SAVE"
						disabled={
							defaultAttributeFieldDetail?.length === 0
								? true
								: false || EditUnitFacilityAttributeValueMutateIsLoading
						}
					/>
				</div>
			</Form>
		</>
	);
};

export default AddDetailPopup;
