import { useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import ETButton from "../../../Components/Buttons/ETButton";
import CustomPopup from "../../../Components/CustomPopup";
import { useState } from "react";
import { DeletePropertyPortfolio } from "../../../services/Portfolios";
import { Controller, useForm } from "react-hook-form";
import { REQUIRED_ERR } from "../../../utils/validationConstants";
import { GetPortfolio } from "../../../services/Portfolios";
import {
  useCreatePortfolio,
  useEditPortfolio,
} from "../../../hooks/queries/usePropertyQueries";
import { GetPortfolioByPortfolioId } from "../../../services/Portfolios";
import { useQuery, useMutation } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PortfolioTable from "./PortfolioTable";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { toast } from "react-toastify";
import { usePorfolioTypeID } from "../../../hooks/stores/usePropertyStore";
import { status } from "../../../model/Constant";
import moment from "moment";
import { GreenSpan } from "../../../styles/Common.style";
import { Link } from "@mui/material";
import { H1, H2 } from "../../../styles/Common.style";
import InputField from "../../../Components/Textfield/InputField";

const CreateNewPortfolio = ({
  operationName,
  setOperationName,
  setShowPage,
  editPortfolioId,
  activePortfoliTabs,
  setActivePortfolioTabs,
  activeDefaultTabs,
  setActiveDefaultTabs,
}) => {
  // zustand

  const [checkedProperty, setChekedProperty] = useState([]);
  const [show, setShow] = useState(false);

  const { setPortfolioIdStore, setPortfoliotypeStore } = usePorfolioTypeID(
    (state) => state
  );

  const [rowSelection, setRowSelection] = useState({}); //to select the properties
  const { refetch: allPortfoliosDataRefetch } = useQuery(() => GetPortfolio());

  const {
    mutateAsync: postPortfolioMutate,
    isLoading: postPortfolioMutateIsLoading,
  } = useCreatePortfolio();

  const {
    mutateAsync: editPortfolioMutate,
    isLoading: editPortfolioMutateIsLoading,
  } = useEditPortfolio();

  const {
    mutateAsync: deletePortfolioMutate,
    isLoading: deletePortfolioMutateIsLoading,
  } = useMutation("deletePortfolio", (id) => DeletePropertyPortfolio(id));

  const { data: GetPortfolioByPortfolioIdData } = useQuery(
    ["GetPortfolioByPortfolioIdData", editPortfolioId],
    () => GetPortfolioByPortfolioId(editPortfolioId),
    { enabled: !!editPortfolioId }
  );
  let checkedRows = [];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,

    clearErrors,
  } = useForm({
    defaultValues: {
      nameOfPortfolio: "",
    },
  });
  useEffect(() => {
    if (operationName === "edit") {
      setValue(
        "nameOfPortfolio",
        GetPortfolioByPortfolioIdData?.data[0]?.portfolio_name
      );
      GetPortfolioByPortfolioIdData?.data[0]?.properties?.forEach((element) => {
        checkedRows?.push(element?.property_id);
      });
      // to convert checkRows into obj with {41:true} format
      const portfolioPropsChecked = checkedRows?.reduce(
        (acc, curr) => ((acc[curr] = true), acc),
        {}
      );
      // setting the state into rowSelection
      setRowSelection(portfolioPropsChecked);
    } else if (operationName === "create") {
      setValue("nameOfPortfolio", "");
      setRowSelection({});
    }
  }, [GetPortfolioByPortfolioIdData]);

  const onFormSubmit = async (formData) => {
    if (checkedProperty?.length === 0) {
      toast.error("Please select at least one property.");
    } else {
      if (operationName === "create") {
        const creatPortfolioPayload = {
          portfolio_name: formData?.nameOfPortfolio,
          property_id: String(checkedProperty),
        };
        try {
          const result = await postPortfolioMutate(creatPortfolioPayload);

          if (result?.status === status.s && result?.data?.bReturn) {
            toast.success(result?.data?.message);
            allPortfoliosDataRefetch();
            setChekedProperty([]);
            setShowPage("PL");
            setActiveDefaultTabs(formData?.nameOfPortfolio);
            // reset();
          } else {
            toast.error(result?.data?.message);
          }
        } catch (error) { }
      } else if (operationName === "edit") {
        const EditportfolioPayload = {
          portfolio_name: formData?.nameOfPortfolio,
          property_id: String(checkedProperty),
          portfolio_id: editPortfolioId,
        };
        try {
          const result = await editPortfolioMutate(EditportfolioPayload);

          if (result?.status === status.s && result?.data?.bReturn) {
            toast.success(result?.data?.message);
            allPortfoliosDataRefetch();
            setChekedProperty([]);
            setPortfoliotypeStore("U");
            setPortfolioIdStore(editPortfolioId);
            setShowPage("PL");

            setActivePortfolioTabs(editPortfolioId);
            setActiveDefaultTabs(formData?.nameOfPortfolio);
            // reset();
          } else {
            toast.error(result?.data?.message);
          }
        } catch (error) { }
      }
    }
  };

  const handleDeletePrompt = () => {
    setShow(true);
    // setOperationName("delete");
  };

  const deletePortfolio = async () => {
    const result = await deletePortfolioMutate(editPortfolioId);
    try {
      if (result?.status === status.s && result?.data?.status) {
        toast.success(result?.data?.message);
        setShow(false);
        allPortfoliosDataRefetch();
        setShowPage("PL");
      } else {
        toast.error(result?.data?.message);
      }
    } catch (error) { }
  };
  // const [editporflolio, setEditPortfolio] = useState([]);
  // useEffect(() => {
  // 	setEditPortfolio(GetPortfolioByPortfolioIdData?.data);
  // }, [GetPortfolioByPortfolioIdData]);
  const modifiedData = GetPortfolioByPortfolioIdData?.data?.map((item) => {
    return {
      ...item,
      modifiedOn: moment(item.modified_on).format("DD.MMMM.YYYY | HH:mm:ss"),
      createdOn: moment(item.created_on).format("DD.MMMM.YYYY | HH:mm:ss"),
    };
  });

  return (
    <>
      <Container
        fluid
        style={{ backgroundColor: "#F9FAFA" }}
        className="px-md-4 "
      >
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <div>
            <div className="pt-3">
              <H1 className="mb-0">
                {operationName === "edit"
                  ? "Edit portfolio"
                  : "Create new portfolio"}{" "}
              </H1>
            </div>
            <div className="mt-0 pb-4  light-font-text">
              <span
                className="mt-1 "
                style={{ cursor: "pointer" }}
                onClick={() => setShowPage("PL")}
              >
                Properties
              </span>
              <span className="px-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />
              </span>
              <GreenSpan>
                {operationName === "edit"
                  ? "Edit portfolio"
                  : "Create new portfolio"}
              </GreenSpan>
            </div>
          </div>

          <div

            className="p-md-4 p-1 mb-4 mt-1 radius-8 containerStyle"
          >
            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                border: "1px solid #F4F5F5",
                fontSize: "14px",
              }}
            >
              <div className="px-4 py-3">
                <div className="  d-flex align-items-center ">
                  <H2 >
                    {operationName === "create" ? (
                      "Create new portfolio"
                    ) : operationName === "edit" ? (
                      <div>Edit portfolio</div>
                    ) : null}
                  </H2>
                  <span className="ps-2">
                    <CustomToolTip

                      height={"12px"}
                      text={
                        operationName === "create"
                          ? "Create new portfolio"
                          : operationName === "edit"
                            ? "Edit portfolio"
                            : null
                      }
                    />
                  </span>

                  <br />
                </div>
                <div>
                  {modifiedData?.map((item) => (
                    <div className="created-by fs-12 ">
                      {item.modified_by && item.modified_on ? (
                        <>
                          <div>
                            Last update by {item.modified_by},{item.modifiedOn}
                          </div>
                        </>
                      ) : (
                        <>
                          {item.created_by && item.created_on ? (
                            <div>
                              Created by {item.created_by},{item.createdOn}{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <hr className="m-0 hr"></hr>

              <Row className="px-4 py-3  mb-2">
                <Col md={12}>
                  <div>
                    <p className="mb-0 form-label-title">Portfolio name</p>
                    <span className="light-font-text grey-text">
                      Give your portfolio a name to remember. The name will be
                      shown on the property list and other places in the system.
                    </span>
                  </div>
                </Col>

                <Col lg={4} md={6} className="mt-3 ">
                  <div>
                    <Controller
                      control={control}
                      {...register("nameOfPortfolio")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div >
                          {/* <input
                            type="text"
                            placeholder="Portfolio name"
                            id="Portfolioname"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("nameOfPortfolio");
                            }}
                            maxLength={30}
                          /> */}
                          <InputField id={"Portfolioname"} value={value}maxLength type={"text"} placeholder={"Portfolio name"} errorscope={false} iserror={errors?.nameOfPortfolio} onChange={(e) => {

                            onChange(e);
                            clearErrors("nameOfPortfolio");
                          }} />
                          {/* <label htmlFor="Portfolioname">Portfolio name</label> */}
                        </div>
                      )}
                    />
                    {errors?.nameOfPortfolio?.type === "required" && (
                      <p className="error-msg">{REQUIRED_ERR}</p>
                    )}
                  </div>
                </Col>
              </Row>
              <hr className="m-0 hr"></hr>

              <Row className="px-4 pt-3 mb-2">
                <Col>
                  <div>
                    <p className="mb-0 form-label-title">Selected properties</p>
                    <span className="light-font-text grey-text">
                      Select which properties that should be grouped to this
                      portfolio.
                    </span>
                  </div>

                  <div className="my-4">
                    <PortfolioTable
                      checkedProperty={checkedProperty}
                      setChekedProperty={setChekedProperty}
                      rowSelection={rowSelection}
                      setRowSelection={setRowSelection}
                      allPortfoliosDataRefetch={allPortfoliosDataRefetch}
                    />
                  </div>
                </Col>
              </Row>

              <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
                {operationName === "create" ? (
                  <>
                    {" "}
                    <ETButton
                      variant="white"
                      buttonText="CANCEL"
                      onClick={() => setShowPage("PL")}
                    />
                    <ETButton
                      variant="primary"
                      disabled={postPortfolioMutateIsLoading}
                      buttonText="CREATE PORTFOLIO"
                      type="submit"
                    />
                  </>
                ) : operationName === "edit" || operationName === "delete" ? (
                  <>
                    <div className="m-1">
                      <ETButton
                        variant="white"
                        buttonText="CANCEL"
                        onClick={() => setShowPage("PL")}
                      />
                    </div>

                    <div className="d-flex flex-md-row flex-column">
                      <div className="m-1">
                        <ETButton
                          variant="white"
                          buttonText="DELETE PORTFOLIO"
                          onClick={handleDeletePrompt}
                        />
                        <CustomPopup
                          open={show}
                          onHide={() => setShow(false)}
                          title="Delete portfolio?"
                          title_color="black"
                          enableCrossButton={false}
                          onCloseAction={() => {
                            setShow(false);
                            alert("Action Peformed Successfuly.");
                          }}
                          action_button_label="YES"
                          action_button_label_color="white"
                          action_button_color="#619130"
                          cancel_button={true}
                          cancel_button_bordercolor="grey"
                          cancel_button_label="NO"
                          cancel_button_label_color="black"
                          cancel_button_color="#fff"
                          footer_color={"#F9FAFA"}
                        >
                          <div className="p-3">
                            <p style={{ fontSize: "14px", fontWeight: "300" }}>
                              You are deleting this portfolio. Remember you are
                              not deleting your properties but only the group of
                              them.
                            </p>
                            <p style={{ fontSize: "14px", fontWeight: "300" }}>
                              Are you sure you want to delete?
                            </p>
                          </div>
                          <div
                            className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
                            style={{
                              backgroundColor: "#F9FAFA",
                              borderRadius: " 0px 0px 8px 8px",
                            }}
                          >
                            <ETButton
                              variant={"white"}
                              buttonText="CANCEL"
                              onClick={() => setShow(false)}
                            />
                            <ETButton
                              variant={"primary"}
                              buttonText="DELETE"
                              onClick={deletePortfolio}
                            />
                          </div>
                        </CustomPopup>
                      </div>

                      <div className="m-1">
                        <ETButton
                          variant="primary"
                          disabled={editPortfolioMutateIsLoading}
                          buttonText="SAVE"
                          type={"submit"}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </Form>
      </Container>
    </>
  );
};

export default CreateNewPortfolio;
