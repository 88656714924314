import React, { useRef, useState, useMemo } from "react";
import CSVWidget from "../../../Components/DownloadWidget/CSVWidget";
import { Col, Row, Form } from "react-bootstrap";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import Menus from "../../../Components/Menus/Menus";
import ETButton from "../../../Components/Buttons/ETButton";
import { MaterialReactTable } from "material-react-table";
import Checkbox from "../../../Components/Checkbox/Checkbox";
import CustomPopup from "../../../Components/CustomPopup";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { useForm, Controller } from "react-hook-form";
import { REQUIRED_ERR } from "../../../utils/validationConstants";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import MRTTheme from "../../../Components/table/MRTTheme";
import { H2, H3 } from "../../../styles/Common.style";
import InputField from "../../../Components/Textfield/InputField";
import { SortIcon } from "../../../Components/table/MRTGlobalProps";

const FutureEventsData = [
  {
    Blank: "Fuglebakkevej 88",
    By: "by Jesper Thorkildsen",
    EventDate: "Created: Sep 26, 2022 - 10:02",
    Registrations: "OCT 8, 2022",
    Participated: "10:00 - 12:00",
  },
  {
    Blank: "Fuglebakkevej 88",
    By: "by Jesper Thorkildsen",
    EventDate: "Created: Sep 26, 2022 - 10:02",
    Registrations: "OCT 8, 2022",
    Participated: "10:00 - 12:00",
  },
  {
    Blank: "Fuglebakkevej 88",
    By: "by Jesper Thorkildsen",
    EventDate: "Created: Sep 26, 2022 - 10:02",
    Registrations: "OCT 8, 2022",
    Participated: "10:00 - 12:00",
  },
];

const FutureEvents = () => {
  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "future-events";

  const options = {
    keys: [
      { field: "Blank", title: "Blank" },
      { field: "By" },
      { field: "EventDate" },
      { field: "Registrations" },
      { field: "Participated" },
    ],
  };

  const data = FutureEventsData;

  const CSVFunction = CSVWidget({ refObj, fileName, options, data });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      eventName: "",
      typeofevent: "",
      targetDate: "",
      timeTo: "",
      timeFrom: "",
      repeat: "",
      title: "",
      description: "",
    },
  });

  const onHandleAddEvents = (data) => {
    console.log("object", data);
  };

  // State for open add events popup

  const [openAddEvents, setOpenAddEvents] = useState(false);

  //   Type of Events

  const typeOfEvents = [
    {
      id: 1,
      value: "Open house",
    },
    {
      id: 2,
      value: "Showing",
    },
    {
      id: 3,
      value: "Internal campaign",
    },
    {
      id: 3,
      value: "External campaign",
    },
  ];

  //Repeat

  const repeat = [
    {
      id: 1,
      value: "Every day",
    },
    {
      id: 2,
      value: "Every week",
    },
    {
      id: 3,
      value: "Every 2nd week",
    },
    {
      id: 4,
      value: "Every month",
    },
    {
      id: 5,
      value: "Every year",
    },
  ];

  const FutureEventsColumns = useMemo(
    () => [
      {
        header: "Status",
        enableHiding: false,

        size: 370,

        Cell: ({ row }) => (
          <>
            <div
              className="d-flex jusify-content-start ps-3"
              style={{ lineHeight: "1.7" }}
            >
              <div>
                <div className="fw-600 fs-14">{row.original.Blank}</div>
                <div className="fw-300 fs-13">{row.original.By}</div>
                <div className="fw-300 fs-10">{row.original.EventDate}</div>
              </div>
            </div>
          </>
        ),
      },
      {
        accessorKey: "Registrations",
        header: "",
        Cell: ({ row }) => (
          <>
            <div className="d-flex justify-content-start">
              <div>
                <div className="fw-600 fs-16">{row.original.Registrations}</div>
                <div className="fw-600 fs-16">{row.original.Participated}</div>
              </div>
            </div>
          </>
        ),
        size: 120,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "Type",
        header: "Participated",
        size: 50,
        Cell: ({ row }) => (
          <>
            <div>
              <div className="d-flex align-items-center gap-1">
                <div>
                  <Checkbox />
                </div>
                <div className="fs-12 fw-300">Website</div>
              </div>
              <div className="d-flex align-items-center gap-1">
                <div>
                  <Checkbox />
                </div>
                <div className="fs-12 fw-300">Ei.DO</div>
              </div>
            </div>
          </>
        ),
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    []
  );

  return (
    <div className="card-body p-3">
      <Row>
        <Col className="d-flex align-items-center" sm={10}>
          {" "}
          <div className="d-flex ps-1 align-items-center">
            <H3 className=" me-2">Future events</H3>{" "}
            <CustomToolTip size="sm" text="Future events" />
          </div>
        </Col>

        <Col
          sm={2}
          className="d-flex justify-content-end align-items-center gap-3"
        >
          <div>
            <ETButton
              variant={"primary"}
              icon={true}
              onClick={() => setOpenAddEvents(true)}
              buttonText="ADD EVENTS"
            />
          </div>
          <div>
            <Menus
              itemheight={40}
              itemwidth={40}
              itemcontainerheight={200}
              itemcontainerwidth={200}
              component={
                <div className="fs-14">
                  <div className="addpropContent px-3 py-2 ">
                    <span className="ms-1" onClick={() => CSVFunction()}>
                      Download widget as CSV
                    </span>
                  </div>
                </div>
              }
            />
          </div>
        </Col>
      </Row>
      {FutureEventsData.length == 0 ? (
        <Row>
          <Col className="pt-4 px-3 pb-2">
            <MRTTheme
              // TableBodycolors="gray"
              colorVariantsForTableHead="white"
              colorVariantsForTableRow={["gray"]}
              table={
                <MaterialReactTable
                  columns={FutureEventsColumns}
                  data={FutureEventsData}
                  icons={{ ...SortIcon }}
                  enableRowActions
                  positionActionsColumn="last"
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      size: 10,
                      header: "",
                    },
                    "mrt-row-select": {
                      header: "",
                    },
                  }}
                  muiTablePaperProps={{
                    sx: {
                      table: {
                        borderCollapse: "separate",
                        borderSpacing: "0 10px",
                      },
                    },
                  }}
                  renderRowActions={({ row }) => (
                    <div className="d-flex justify-content-center">
                      <Menus
                        itemheight={40}
                        itemwidth={40}
                        iconborderradius="4px"
                        itemcontainerwidth="180px"
                        component={
                          <>
                            <div style={{ fontSize: "14px" }}>
                              <div className="addpropContent px-3 py-2 ">
                                <span className="ms-1">Add information</span>
                              </div>
                              <hr className="addpropertyHr m-0"></hr>
                              <div className="addpropContent px-3 pt-2 pb-2 ">
                                <span className="ms-1">Delete information</span>
                              </div>
                            </div>
                          </>
                        }
                      />
                    </div>
                  )}
                  enableTopToolbar={false}
                  enableBottomToolbar={false}
                  enableSelectAll={false}
                  enableColumnActions={false}
                  enableColumnFilter={false}
                  enableResizing={false}
                  initialState={{ showGlobalFilter: true }}
                  muiTableContainerProps={{
                    sx: {
                      padding: "0px !important",
                    },
                  }}
                  enableColumnFilterModes={false}
                />
              }
            />
          </Col>
        </Row>
      ) : (
        <NoDataScreen height={"377px"} imgHeight={"150px"} />
      )}

      <CustomPopup
        open={openAddEvents}
        onHide={() => setOpenAddEvents(false)}
        title={"Create an event"}
        title_color="black"
        titleFont="14px"
        titleTooltip={true}
        enableCrossButton={true}
        titleTooltipText="Create an event"
        size="xl"
        onCloseAction={() => {
          setOpenAddEvents(false);
          alert("Action Peformed Successfuly.");
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="">
          <Form onSubmit={handleSubmit(onHandleAddEvents)}>
            <div
              className="p-4 scrollable-container-y me-1"
              style={{ height: "300px" }}
            >
              <Row>
                <Col>
                  <Controller
                    name="eventName"
                    {...register("eventName")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        value={value}
                        clearable={false}
                        iserror={errors?.eventName}
                        isSearchable
                        //   onChange={(e) => {
                        //     onChange(e);
                        //     handleZip(e);
                        //   }}
                        error={errors.zip}
                        //   options={zipcodeData?.data?.map((zip) => ({
                        //     value: zip?.Id,
                        //     label: `${zip?.Id} ${zip?.Text}`,
                        //   }))}
                      />
                    )}
                  />
                  {/* {errors?.eventName && (
                    <p
                      className="error-msg"
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )} */}
                </Col>
                <Col sm={8}></Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-label-title py-4">Basic</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Controller
                    name="typeofevent"
                    {...register("typeofevent")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder="Type of events"
                        value={value}
                        iserror={errors?.typeofevent}
                        clearable={false}
                        isSearchable
                        onChange={(e) => {
                          onChange(e);
                        }}
                        error={errors.event}
                        options={typeOfEvents?.map((event) => ({
                          value: event?.id,
                          label: `${event?.value}`,
                        }))}
                      />
                    )}
                  />
                  {/* {errors?.typeofevent && (
                    <p
                      className="error-msg"
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )} */}
                </Col>
                <Col className="d-flex align-items-center gap-2" sm={8}>
                  <div className="d-flex align-items-center">
                    <div>
                      <Checkbox />
                    </div>
                    <div className="fs-12 light-font-text">Website</div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div>
                      <Checkbox />
                    </div>
                    <div className="fs-12 light-font-text">Ei.DO</div>
                  </div>
                </Col>
              </Row>
              <Row className="py-3">
                <Col sm={4}>
                  <Controller
                    name="targetDate"
                    {...register("targetDate")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        {/* <input
                          type="date"
                          id="targetDate"
                          placeholder="target"
                          // min={date}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("targetDate");
                          }}
                        /> */}
                        <InputField
                          id={"targetDate"}
                          value={value}
                          type={"date"}
                          placeholder={"Target"}
                          errorscope
                          iserror={errors?.targetDate}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("targetDate");
                          }}
                        />
                        {/* <label htmlFor="targetDate">Target date</label> */}
                      </div>
                    )}
                  />
                  {/* {errors?.targetDate && (
                    <p
                      className="error-msg"
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )} */}
                </Col>
                <Col>
                  <Controller
                    name="timeTo"
                    {...register("timeTo")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        {/* <input
                          type="time"
                          id="timeTo"
                          placeholder="target"
                          // min={date}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("timeTo");
                          }}
                        /> */}
                        <InputField
                          id={"timeTo"}
                          value={value}
                          type={"time"}
                          maxLength
                          placeholder={"target"}
                          errorscope
                          iserror={errors?.timeTo}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("timeTo");
                          }}
                        />
                      </div>
                    )}
                  />
                  {/* {errors?.timeTo && (
                    <p
                      className="error-msg"
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )} */}
                </Col>
                <Col>
                  <Controller
                    name="timeFrom"
                    {...register("timeFrom")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        {/* <input
                          type="time"
                          id="timeFrom"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("timeFrom");
                          }}
                        /> */}
                        <InputField
                          id={"timeFrom"}
                          value={value}
                          type={"time"}
                          maxLength
                          errorscope
                          iserror={errors?.timeFrom}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("timeFrom");
                          }}
                        />
                      </div>
                    )}
                  />
                  {/* {errors?.timeFrom && (
                    <p
                      className="error-msg"
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )} */}
                </Col>
                <Col sm={4}>
                  <Controller
                    name="repeat"
                    {...register("repeat")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder="Repeat"
                        value={value}
                        iserror={errors?.repeat}
                        clearable={false}
                        isSearchable
                        onChange={(e) => {
                          onChange(e);
                        }}
                        error={errors.zip}
                        options={repeat?.map((event) => ({
                          value: event?.id,
                          label: `${event?.value}`,
                        }))}
                      />
                    )}
                  />
                  {/* {errors?.repeat && (
                    <p
                      className="error-msg"
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )} */}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-label-title pb-2">
                    Title and description
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="pb-3">
                    <Controller
                      control={control}
                      {...register("title")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div>
                          {/* <input
                            type="text"
                            placeholder="title"
                            id="title"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("title");
                            }}
                          /> */}
                          <InputField
                            id={"title"}
                            value={value}
                            type={"text"}
                            placeholder={"Title"}
                            errorscope
                            iserror={errors?.title}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("title");
                            }}
                          />
                        </div>
                      )}
                    />
                    {/* {errors?.title && (
                      <p
                        className="error-msg"
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )} */}
                  </div>
                </Col>
                <Col sm={8}></Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <Controller
                      control={control}
                      {...register("description")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div className="form-input ">
                          <textarea
                            className="actor-signing-package-textarea w-100 px-3"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            style={{
                              resize: "none",
                              border: "1px solid #D1d1d1",
                              borderRadius: "4px",
                              height: "200px",
                            }}
                            placeholder="Description of event"
                          ></textarea>
                        </div>
                      )}
                    />
                  </div>
                </Col>
                <Col sm={7}></Col>
              </Row>
            </div>

            <div
              className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
              style={{
                backgroundColor: "#F9FAFA",
                borderRadius: " 0px 0px 8px 8px",
                border: "1px solid #F0F0F0",
              }}
            >
              <ETButton
                variant={"white"}
                buttonText="CANCEL"
                onClick={() => setOpenAddEvents(false)}
              />
              <div className="d-flex gap-3">
                <ETButton
                  variant={"primary"}
                  buttonText="Create Event"
                  type="submit"
                />
              </div>
            </div>
          </Form>
        </div>
      </CustomPopup>
    </div>
  );
};

export default FutureEvents;
