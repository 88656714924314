import React from "react";

function Error() {
  return (
    <div
      className="fw-500 d-flex justify-content-center"
      style={{ fontSize: "4rem" }}
    >
      {/* <img src={errorImage} alt="something went wrong" /> */}
      Something went wrong
    </div>
  );
}

export default Error;
