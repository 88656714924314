import React, { useRef, useState, useMemo } from "react";
import CSVWidget from "../../../Components/DownloadWidget/CSVWidget";
import { Col, Row } from "react-bootstrap";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import Menus from "../../../Components/Menus/Menus";
import { MaterialReactTable } from "material-react-table";
import "../../../styles/css/control-center/events.css";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import MRTTheme from "../../../Components/table/MRTTheme";
import { NoRecordsToDisplay } from "../../../utils/validationConstants";
import { H2, H3 } from "../../../styles/Common.style";
import { SortIcon } from "../../../Components/table/MRTGlobalProps";

const StaticsGenderData = [
  {
    Type: "Gender",
    Registrations: "37",
    Percentage: "",
  },
  {
    Type: "Male",
    Registrations: "15",
    Percentage: "41%",
  },
  {
    Type: "Female",
    Registrations: "20",
    Percentage: "54%",
  },
  {
    Type: "Other gender",
    Registrations: "2",
    Percentage: "5%",
  },
];
const OverallStaticsOnGender = () => {
  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "statics gender";

  const options = {
    keys: [
      { field: "Type", title: "Type" },
      { field: "Registrations" },
      { field: "Percentage" },
    ],
  };

  const data = StaticsGenderData;

  const CSVFunction = CSVWidget({ refObj, fileName, options, data });

  const breakDownOfCustomersColumns = useMemo(
    () => [
      {
        accessorKey: "Type",
        header: "Type",
        size: 180,
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "40px !important",
          },
        },
      },
      {
        accessorKey: "Registrations",
        header: "Registrations",
        size: 50,
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "Percentage",
        header: "Percentage",
        size: 50,
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );

  return (
    <div className="card-body p-3" ref={myref} style={{ height: "591px" }}>
      <Row>
        <Col className="d-flex align-items-center" sm={10}>
          {" "}
          <div className="d-flex" style={{ alignItems: "center" }}>
            <H3 className=" me-2">
              Overall statistics on participants - Gender
            </H3>{" "}
            <CustomToolTip
              size="sm"
              text="Overall statistics on participants - Age"
            />
          </div>
        </Col>
        <Col sm={2} className="d-flex justify-content-end">
          {" "}
          <Menus
            itemheight={40}
            itemwidth={40}
            itemcontainerheight={200}
            itemcontainerwidth={200}
            component={
              <div className="fs-14">
                <div className="addpropContent px-3 py-2 ">
                  <span className="ms-1" onClick={() => CSVFunction()}>
                    Download widget as CSV
                  </span>
                </div>
              </div>
            }
          />
        </Col>
      </Row>
      {StaticsGenderData.length != 0 ? (
        <Row className="mt-3">
          <Col>
            <div style={{ height: "505px" }}>
              <MRTTheme
                colorVariantsForTableData={["odd"]}
                table={
                  <MaterialReactTable
                    columns={breakDownOfCustomersColumns}
                    // data={StaticsGenderData}
                    data={[]}
                    icons={{ ...SortIcon }}
                    localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                    enableBottomToolbar={false}
                    enableSorting={false}
                    enableColumnActions={false}
                    enablePagination={false}
                    renderTopToolbar={false}
                    muiTableContainerProps={{
                      sx: {
                        padding: "0px !important",
                      },
                    }}

                    //  muiTablePaperProps={{
                    //    elevation: 0,
                    //    sx: {
                    //      fontFamily: "Cerebri sans !important",
                    //      borderRadius: "0px 0px 8px 8px ",
                    //      border: "none",
                    //      background: "#F9FAFA",
                    //      fontWeight: "300",
                    //      ".css-1f2qhs8": {
                    //        minWidth: "auto",
                    //        marginRight: "10px",
                    //      },
                    //      th: {
                    //        height: "50px",
                    //        padding: "0.5rem",
                    //        background: "#FFFFFF",
                    //        paddingLeft: "1rem",
                    //        fontFamily: "Cerebri sans !important",
                    //      },
                    //      td: {
                    //        height: "50px",
                    //        padding: "0.5rem",
                    //        fontWeight: "300",
                    //        fontSize: "13px",
                    //        paddingLeft: "1rem",
                    //        paddingRight: "1rem",
                    //        fontFamily: "Cerebri sans !important",
                    //      },
                    //      tr: {
                    //        borderRadius: "4px",
                    //        border: "2px solid red !important",
                    //        fontFamily: "Cerebri sans !important",
                    //      },
                    //      ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
                    //        display: "none", // pagination space removing from page
                    //      },
                    //    },
                    //  }}
                    //  muiTableBodyProps={{
                    //    sx: {
                    //      fontFamily: "Cerebri sans !important",
                    //      "& tr:nth-of-type(odd)": {
                    //        backgroundColor: "#F9FAFA",
                    //        borderRadius: "8px",
                    //        fontWeight: "300",
                    //      },
                    //    },
                    //  }}
                    //  muiTableContainerProps={{
                    //    elevation: 0,
                    //    sx: {
                    //      background: "#FFFFFF",

                    //      borderRadius: "0px 0px 8px 8px ",

                    //      boxShadow: "none",
                    //      "MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium css-1gbcdvd-MuiTableCell-root":
                    //        {
                    //          fontWeight: "700 !important",
                    //        },
                    //      ".css-vv34gr-MuiTableRow-root": {
                    //        boxShadow: "unset !important",
                    //      },
                    //    },
                    //  }}
                    //  muiTableHeadCellProps={{
                    //    //simple styling with the `sx` prop, works just like a style prop in this example
                    //    sx: {
                    //      fontWeight: "700",
                    //      fontSize: "13px",
                    //      fontFamily: "Cerebri sans !important",
                    //    },
                    //  }}
                  />
                }
              />
            </div>
          </Col>
        </Row>
      ) : (
        <NoDataScreen height={"377px"} imgHeight={"150px"} />
      )}
    </div>
  );
};

export default OverallStaticsOnGender;
