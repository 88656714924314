import React, { useEffect, useState } from "react";
import CustomPopup from "../../../Components/CustomPopup";
import { StyledLoadingBar } from "../../../styles/Common.style";
import LoadingBar from "react-top-loading-bar";
import { customproperties } from "../../../styles/Theme";
import AddPropertyForUnits from "./moveToAnother/AddPropertyForUnits";
import SelectUnitsForMoveTo from "./moveToAnother/SelectUnitsForMoveTo";
import CreatingAppointment from "./CreateNewAppointments/CreatingAppointment";
import AddCustomerToAppointment from "./CreateNewAppointments/AddCustomerToAppointment";
import ConfirmMoving from "./moveToAnother/ConfirmMoving";
import ConfirmCancellation from "./moveToAnother/ConfirmCancellation";
import ConfirmNewInvite from "./moveToAnother/ConfirmNewInvite";
import CreateNewAppoinmentConfirmation from "./CreateNewAppointments/CreateNewAppoinmentConfirmation";

const MoveToAnotherUnit = ({
  showMoveToAnotherUnits,
  setShowMoveToAnotherUnits,
}) => {
  // ------------------------------------- all states -----------------------------------//
  const [progress, setProgress] = useState(20);
  const [stepWizard, setStepWizard] = useState("SelectProperty");
  const [addExtraCustomer, setAddExtraCustomer] = useState(false);
  const [switchForCreateNewAppointment, setSwitchForCreateNewAppointment] =
    useState(true);
  const [
    switchForCancelCurrentAppointment,
    setSwitchForCancelCurrentAppointment,
  ] = useState(true);

  // console.log(
  //   switchForCancelCurrentAppointment,
  //   "switchForCancelCurrentAppointment"
  // );
  // console.log(switchForCreateNewAppointment, "switchForCreateNewAppointment");
  // ------------------------------ all const managed for wizard -------------------------------------------------------------//

  // --------------------- Select Property const  -----------------------------------------------//
  const handleSelectedPropertyForNextButton = (data) => {
    setProgress(40);
    setStepWizard("SelectUnits");
  };

  const handleSelectedPropertyForCancel = () => {
    setShowMoveToAnotherUnits(false);
  };

  //-------------------------- Select Units const --------------------------------------------//
  const handleSelectUnitsForNext = (data) => {
    setProgress(60);
    setStepWizard("CreateNewAppointment");
  };

  const handleSelectUnitsForBack = (data) => {
    setProgress(20);
    setStepWizard("SelectProperty");
  };
  //   ------------------------- create new appointment --------------------------------------//
  const handleCreatingAppointmentNext = (data) => {
    setProgress(99.9);
    setStepWizard("ConfirmationCustomerIsMoved");
  };
  const handleCreatingAppointmentCancel = () => {
    setProgress(40);
    setStepWizard("SelectUnits");
  };

  const handleAddCustomerToAppointmentForNext = () => {
    setAddExtraCustomer(false);
  };

  const handleAddCustomerToAppointmentForBack = () => {
    setAddExtraCustomer(false);
  };

  // ----------------------Confirmation Customer Is Moved ---------------------------------//
  const ConfirmationCustomerIsMovedHandleNext = () => {
    setStepWizard(
      switchForCancelCurrentAppointment == false &&
        switchForCreateNewAppointment == false
        ? "FinalConfirmationPage"
        : "CancelInvitationToTheCustomer"
    );
  };
  const ConfirmationCustomerIsMovedHandleBack = () => {
    setProgress(60);
    setStepWizard("CreateNewAppointment");
  };

  // -------------------- Cancel Invitation To The Customer -----------------------------------//

  const CancelInvitationToTheCustomerHandleNext = () => {
    setStepWizard(
      switchForCancelCurrentAppointment == true &&
        switchForCreateNewAppointment == false
        ? "FinalConfirmationPage"
        : "InvitationSendToTheCustomer"
    );
  };

  const CancelInvitationToTheCustomerHandleBack = () => {
    setStepWizard("ConfirmationCustomerIsMoved");
  };

  // ------------------------- Invitation Send To The Customer ---------------------------------//
  const InvitationSendToTheCustomerHandleNext = () => {
    setStepWizard("FinalConfirmationPage");
  };

  const InvitationSendToTheCustomerHandleBack = () => {
    setStepWizard("ConfirmationCustomerIsMoved");
  };

  // ----------------------- FinalConfirmationPageHandleClose ---------------------------------//
  const FinalConfirmationPageHandleClose = () => {
    setShowMoveToAnotherUnits(false);
  };
  //  ------------------------------ wizard ----------------------------------------------//
  const MoveToAnotherUnitWizard = {
    SelectProperty: (
      <AddPropertyForUnits
        handleCancel={handleSelectedPropertyForCancel}
        handleNext={handleSelectedPropertyForNextButton}
      />
    ),
    SelectUnits: (
      <SelectUnitsForMoveTo
        handleNext={handleSelectUnitsForNext}
        handleBack={handleSelectUnitsForBack}
      />
    ),
    CreateNewAppointment: addExtraCustomer ? (
      <AddCustomerToAppointment
        flag="create new appointment"
        handleNext={handleAddCustomerToAppointmentForNext}
        handleBack={handleAddCustomerToAppointmentForBack}
      />
    ) : (
      <CreatingAppointment
        flag="MCTAU"
        setAddExtraCustomer={setAddExtraCustomer}
        handleNext={handleCreatingAppointmentNext}
        handleCancel={handleCreatingAppointmentCancel}
        switchForCreateNewAppointment={switchForCreateNewAppointment}
        setSwitchForCreateNewAppointment={setSwitchForCreateNewAppointment}
        switchForCancelCurrentAppointment={switchForCancelCurrentAppointment}
        setSwitchForCancelCurrentAppointment={
          setSwitchForCancelCurrentAppointment
        }
      />
    ),
    ConfirmationCustomerIsMoved: (
      <ConfirmMoving
        handleBack={ConfirmationCustomerIsMovedHandleBack}
        handleNext={ConfirmationCustomerIsMovedHandleNext}
        switchForCancelCurrentAppointment={switchForCancelCurrentAppointment}
        switchForCreateNewAppointment={switchForCreateNewAppointment}
      />
    ),
    CancelInvitationToTheCustomer: (
      <ConfirmCancellation
        handleBack={CancelInvitationToTheCustomerHandleBack}
        handleNext={CancelInvitationToTheCustomerHandleNext}
        switchForCancelCurrentAppointment={switchForCancelCurrentAppointment}
        switchForCreateNewAppointment={switchForCreateNewAppointment}
      />
    ),
    InvitationSendToTheCustomer: (
      <ConfirmNewInvite
        handleBack={InvitationSendToTheCustomerHandleBack}
        handleNext={InvitationSendToTheCustomerHandleNext}
      />
    ),
    FinalConfirmationPage: (
      <CreateNewAppoinmentConfirmation
        handleClose={FinalConfirmationPageHandleClose}
        renderContractDetails={true}
        showNewAppointment={
          switchForCancelCurrentAppointment == true &&
          switchForCreateNewAppointment == true
            ? true
            : false
        }
        leadsCustomerDetailData={[]}
        customerData={"Thomas Høgh Mogensen, Camille Josephine Høgh"}
        propertyData={"Kaktus Towers"}
        addressData={"Dybølsbro 3, 17., door 11, 2450 København SV"}
        unitIdData={"212-187-002"}
        newAppointment={
          "Thomas Høeg Mogensen - Dybbølsbro 3, 4 I 7-N340-4 I 212-076"
        }
        heading={
          switchForCancelCurrentAppointment == true &&
          switchForCreateNewAppointment == true
            ? "Moved and created new appointment"
            : "Moved to another unit"
        }
        subHeading={
          switchForCancelCurrentAppointment == false &&
          switchForCreateNewAppointment == false ? (
            <>
              <div className="pb-3">
                System has now moved the customer to the property or unit below.
                A confirmation has been sent to the customer regarding this.
              </div>
              <div>
                If you wish to create a new appointment for the customer, you
                can always do this from the Lead tab.
              </div>
            </>
          ) : switchForCancelCurrentAppointment == true &&
            switchForCreateNewAppointment == false ? (
            <>
              <div className="pb-3">
                System has now moved the customer to the property or unit below.
                A confirmation has been sent to the customer regarding this, as
                well as a cancellation of the current appointment.
              </div>
              <div>
                If you wish to create a new appointment for the customer, you
                can always do this from the Lead tab.
              </div>
            </>
          ) : (
            "System has now moved the customer to the property or unit below. A confirmation has been sent to the customer regarding this, as well as a cancellation of the current appointment and an invitation for a new appointment.The appointment can be found under Events and in My Activity."
          )
        }
      />
    ),
  };

  // -------------------- useEffects manage to set default state value -------------------------------//
  useEffect(() => {
    setStepWizard("SelectProperty");
    setProgress(20);
    setSwitchForCancelCurrentAppointment(true);
    setSwitchForCreateNewAppointment(true);
    return () => {};
  }, [showMoveToAnotherUnits]);

  return (
    <div>
      {" "}
      <CustomPopup
        open={showMoveToAnotherUnits}
        // onHide={() => setShowMoveToAnotherUnits(false)}
        onClickCrossButton={() => setShowMoveToAnotherUnits(false)}
        title="Move customer to another unit"
        titleTooltipText="Move customer to another unit"
        title_color="black"
        size={"xl"}
        enableCrossButton={true}
        deletePopUpStyle={true}
        titleTooltip={true}
        likeFeature={false}
        extraInfo={true}
        extraInfoText={"Thomas Høeg Mogensen"}
      >
        <div>
          <StyledLoadingBar>
            <LoadingBar
              color={customproperties.primaryColor}
              progress={progress}
              containerStyle={{
                position: "absolute",
                height: "2.5px",
              }}
              shadow={false}
              onLoaderFinished={() => setProgress(0)}
            />
          </StyledLoadingBar>
        </div>
        <div className=" me-1">{MoveToAnotherUnitWizard[stepWizard]}</div>
      </CustomPopup>
    </div>
  );
};

export default MoveToAnotherUnit;

// created by Suraj
