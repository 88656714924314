import { useMutation, useQuery } from "react-query";
import {
	GetEditUnitTextByPropertyIdByUnitId,
	GetTextByTextId,
	PostAddText,
	PutUpdateTextBasicDetails,
	PutUpdateTextCompleteDetails,
	GetTextSubCategory,
} from "../../services/Texts";

//Get edit Units Text by propertyId
export const useGetEditUnitTextByPropertyIdByUnitId = (
	propertyId,
	unitId,
	categoryId
) => {
	return useQuery(
		["useGetEditUnitTextByPropertyIdByUnitId", propertyId, unitId, categoryId],
		() => GetEditUnitTextByPropertyIdByUnitId(propertyId, unitId, categoryId),
		{ enabled: !!categoryId }
	);
};
//Get edit Units Text by propertyId
export const useGetTextSubCategory = () => {
	return useQuery(["GetTextSubCategory"], () => GetTextSubCategory());
};
export const useGetTextByTextId = (propertyId, packageId) => {
	return useQuery(
		["getTextByTextId", propertyId, packageId],
		() => GetTextByTextId(propertyId, packageId),
		{ enabled: !!packageId }
	);
};

//Post add text
export const usePostAddText = () => {
	return useMutation(PostAddText);
};
//Put update text basic details
export const useUpdateTextBasicDetails = () => {
	return useMutation(PutUpdateTextBasicDetails);
};
//Put update text complete details
export const useUpdateTextCompleteDetails = () => {
	return useMutation(PutUpdateTextCompleteDetails);
};
