// import React from "react";
// import styled from "styled-components";
// // Import Controller from your form library

// const ColorInputField = ({
//   label = "Font color",
//   value = "#000000",
//   onChange,
//   isDisabled,
//   isRemoveOpacity,
// }) => {
//   return (
//     <ColorPickerContainer isDisabled={isDisabled}>
//       <div className="color-picker" id="favcolor">
//         <div className="color-picker-label">{label}</div>
//         <div className="ps-2 d-flex position-relative ">
//           <div
//             style={{
//               backgroundColor: value,
//               width: "14px",
//               height: "14px",
//               borderRadius: "4px",
//               position: "relative",
//               marginTop: "2px",
//             }}
//           >
//           <input
//             value={value}
//             onChange={onChange}
//             type="color"
//             className="color-picker-input"
//             id="favcolor"
//             disabled={isDisabled}
//             style={{
//               position: "absolute",
//               top: "0",
//               left: "0",
//               height: "100%",
//               width: "100%",
//               opacity: "0",
//               cursor: isDisabled ? "not-allowed" : "pointer",
//             }}
//           />
//           </div>
//           <FontColorText color={value} isDisabled={isDisabled}>
//             {/* {fontColor} */}
//             {value}
//           </FontColorText>
//         </div>
//       </div>
//     </ColorPickerContainer>
//   );
// };

// export default ColorInputField;

// const ColorPickerContainer = styled.div`
//   margin-top: 0;
//   position: relative;

//   .color-picker {
//     border: ${(props) =>
//       props.isDisabled ? "1px solid #d1d1d199" : "1px solid #d1d1d1"};
//     height: 40px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     border-radius: 8px;
//     cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
//     &:hover {
//       box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
//     }
//     &:focus {
//       border: 1px solid black; /* Add a black border on focus */
//     }
//     &:focus-within {
//       border: 1px solid black; /* Add a black border on focus */
//     }
//   }

//   .color-picker-label {
//     width: fit-content;
//     background-color: #fff;
//     position: relative;
//     bottom: 5px;
//     left: 12px;
//     color: #888888;
//     font-size: 8px;
//     font-family: Cerebri sans-medium;
//     padding: 0px 4px;
//   }

//   .color-picker-input {
//     width: 14px;
//     margin: 0;
//     font-family: inherit;
//     font-size: inherit;
//     line-height: inherit;
//     border: 1px solid white;
//     border-radius: 4px !important;
//     height: 14px;
//     margin-top: 3px;
//     padding: 0px;
//     overflow: auto;

//   }
// `;

// const FontColorText = styled.span`
//   font-size: 14px;
//   //   color: ${(props) => props.color || "#000"};
//   opacity: ${(props) => (props.isDisabled ? "0.6" : "1")};
//   padding: 0px 8px 8px;
// `;
import React from "react";
import styled from "styled-components";
// Import Controller from your form library

const ColorInputField = ({
  label = "Font color",
  value = "#000000",
  onChange,
  isDisabled,
  iserror,
  isRemoveOpacity,
}) => {
  return (
    <ColorPickerContainer isError={iserror} isDisabled={isDisabled}>
      <div className="color-picker" id="favcolor">
        <div className="color-picker-label">{label}</div>
        <div className="ps-3 d-flex position-relative ">
          <div
            style={{
              backgroundColor: value,
              width: "14px",
              height: "14px",
              borderRadius: "4px",
              position: "relative",
              marginTop: "2px",
              border: "1px solid #d1d1d1",
            }}
          >
            <input
              value={value}
              onChange={onChange}
              type="color"
              disabled={isDisabled}
              className="color-picker-input"
              id="favcolor"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                height: "100%",
                width: "100%",
                opacity: "0",
                cursor: isDisabled ? "not-allowed" : "pointer",
              }}
            />
          </div>
          <FontColorText color={value} isDisabled={isDisabled}>
            {/* {fontColor} */}
            <input
              type="text"
              value={value}
              disabled={isDisabled}
              onChange={onChange}
              placeholder="Enter color hex code"
              className="no-outline-for-input light-font-text"
              maxLength={7}
              max={7}
              min={7}
              style={{
                cursor: isDisabled && "not-allowed",
                backgroundColor: "#ffffff",
              }}
            />
            {/* {value} */}
          </FontColorText>
        </div>
      </div>
    </ColorPickerContainer>
  );
};

export default ColorInputField;

const ColorPickerContainer = styled.div`
  margin-top: 0;
  position: relative;

  .color-picker {
    border: ${(props) =>
      props.isDisabled
        ? "1px solid #d1d1d199"
        : props.isError
        ? "1px solid #e33716"
        : "1px solid #d1d1d1"};
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
    &:hover {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
    }
    &:focus {
      border: 1px solid black; /* Add a black border on focus */
    }
    &:focus-within {
      border: 1px solid black; /* Add a black border on focus */
    }
  }

  .color-picker-label {
    width: fit-content;
    background-color: #fff;
    position: relative;
    bottom: 5px;
    left: 12px;
    color: #888888;
    font-size: 8px;
    font-family: Cerebri sans-medium;
    padding: 0px 4px;
  }

  .color-picker-input {
    width: 14px;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    border: 1px solid white;
    border-radius: 4px !important;
    height: 14px;
    margin-top: 3px;
    padding: 0px;
    overflow: auto;
  }
  .no-outline-for-input {
    border: none;
    outline: none;
  }
`;

const FontColorText = styled.span`
  font-size: 14px;
  //   color: ${(props) => props.color || "#000"};
  opacity: ${(props) => (props.isDisabled ? "0.6" : "1")};
  padding: 0px 8px 8px;
  line-height: normal;
`;
