import React from "react";
import CustomPopup from "../../../../../../../../../Components/CustomPopup";
import ETButton from "../../../../../../../../../Components/Buttons/ETButton";

const DeleteCustomerAction = ({
  deleteCustomerAction,
  setDeleteCustomerAction,
  //   handleDelete
}) => {
  return (
    <div>
      {" "}
      <CustomPopup
        open={deleteCustomerAction}
        onHide={() => setDeleteCustomerAction(false)}
        title="Delete customer action"
        title_color="black"
        size={"sm"}
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="p-4 light-font-text">
          Are you sure you want to delete the customer action. The customer will
          not be deleted from customer directory.
        </div>
        <div className="py-2 mt-4 px-3 d-flex flex-row justify-content-between  footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setDeleteCustomerAction(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="DELETE"
            type={"submit"}
            onClick={() => setDeleteCustomerAction(false)}
            //   onClick={handleDelete}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default DeleteCustomerAction;
