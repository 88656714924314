import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import PasswordField from "../../../Components/GroupText/PasswordField";
import Toggle from "../../../Components/Switch/Switch";
import ETButton from "../../../Components/Buttons/ETButton";
import { Menu } from "@mui/material";

const ContactInformation = ({ footer }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setDropdownOpen(false);
  };

  return (
    <div>
      <Form
      //   onSubmit={handleSubmit(handleAddActor)}
      >
        <div className=" pt-2 pb-3 px-4 ms-1 form-label-title">
          Customer information
        </div>
        <Row className="mx-3">
          <Col xl={3} lg={4} md={6}>
            {/* <Controller
                  control={control}
                  {...register("firstName")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => ( */}
            <div className="form-input ">
              <input
                type="text"
                placeholder="First name (required)"
                id="firstName"
                // value={value}
                // onChange={(e) => {
                //   onChange(e);
                //   clearErrors("firstName");
                // }}
              />
              <label for="firstName">First name </label>
            </div>
            {/* //   )}
                // />
                // {errors?.firstName && (
                //   <p className="error-msg">This field is required</p>
                // )} */}
          </Col>
          <Col xl={3} lg={4} md={6} className="ps-0">
            {/* <Controller
                  control={control}
                  {...register("lastName")}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => ( */}
            <div className="form-input ">
              <input
                type="text"
                placeholder="Last name"
                id="lastName"
                // value={value}
                // onChange={(e) => {
                //   onChange(e);
                //   clearErrors("lastName");
                // }}
              />
              <label for="lastName">Last name</label>
            </div>
            {/* //   )}
                // />
                // {errors?.lastName && (
                //   <p className="error-msg">This field is required</p>
                // )} */}
          </Col>
        </Row>

        <Row className="my-3 mx-3">
          <Col xl={6} lg={8} md={12}>
            {/* <Controller
                  control={control}
                  {...register("myemail")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => ( */}
            <div className="form-input ">
              <input
                type="email"
                placeholder="Email (required)"
                id="Email"
                // value={value}
                // onChange={(e) => {
                //   onChange(e);
                //   clearErrors("myemail");
                // }}
              />
              <label for="Email">Email</label>
            </div>
            {/* //   )}
                // />
                // {errors?.myemail && (
                //   <p className="error-msg">This field is required</p>
                // )} */}
          </Col>
        </Row>

        <Row className="mx-3">
          <Col xl={6} lg={8}>
            <Row>
              <Col xl={4} lg={5} md={4} className="pe-0">
                {/* <Controller
                      control={control}
                      {...register("countryCode")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => ( */}
                <ReactSelect
                  placeholder={"Country code"}
                  //   options={countryData?.data?.map((item) => ({
                  //     value: item?.Id,
                  //     label: item?.DataValue2,
                  //   }))}
                  //   value={value}
                  //   onChange={(e) => {
                  //     onChange(e);
                  //     clearErrors("countryCode");
                  //   }}
                />
                {/* //   )}
                    // />
                    // {errors.countryCode && (
                    //   <p className="error-msg">This field is required</p>
                    // )} */}
              </Col>
              <Col xl={8} lg={7} md={8}>
                {/* <Controller
                      control={control}
                      {...register("phoneno")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => ( */}
                <div className="form-input ">
                  <input
                    type="number"
                    placeholder="Phone"
                    id="Phone"
                    // value={value}
                    // onChange={(e) => {
                    //   onChange(e);
                    //   clearErrors("phoneno");
                    // }}
                    maxlength={15}
                  />
                  <label for="Phone">Phone</label>
                </div>
                {/* //   )}
                    // />
                    // {errors?.phoneno && (
                    //   <p className="error-msg">This field is required</p>
                    // )} */}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="my-3 mx-3">
          <Col xl={6} lg={8}>
            {/* <Controller
                  control={control}
                  {...register("socialsecuritynumber")}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <> */}
            <PasswordField
              id={"ssn"}
              placeholder={"Social Security Number"}
              // value={value}
              // onChange={(e) => {
              //   onChange(e);
              //   clearErrors("socialsecuritynumber");
              // }}
              maxlength={15}
            />
            {/* </>
                  )}
                />
                {errors?.socialsecuritynumber && (
                  <p className="error-msg">Too Many Characters</p>
                )} */}
          </Col>
        </Row>

        <Row className="mx-3">
          <Col xl={6} lg={8}>
            <Row>
              <Col xl={4} lg={4} md={6} className="mb-3 pe-0">
                {/* <Controller
                      control={control}
                      {...register("street")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => ( */}
                <div className="form-input ">
                  <input
                    type="text"
                    placeholder="Street"
                    id="street"
                    // value={value}
                    // onChange={(e) => {
                    //   onChange(e);
                    //   clearErrors("street");
                    // }}
                  />
                  <label for="street">Street </label>
                </div>
                {/* //   )}
                    // />
                    // {errors.street && (
                    //   <p className="error-msg">This field is required</p>
                    // )} */}
              </Col>

              <Col lg={2} md={6} className=" mb-3 pe-0">
                {/* <Controller
                      control={control}
                      {...register("houseno")}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => ( */}
                <div className="form-input ">
                  <input
                    type="text"
                    placeholder="No."
                    id="houseN0"
                    // value={value}
                    // onChange={(e) => {
                    //   onChange(e);
                    //   clearErrors("houseno");
                    // }}
                  />
                  <label for="houseN0">No. </label>
                </div>
                {/* )}
                    />
                    {errors.houseno && (
                      <p className="error-msg">This field is required</p>
                    )} */}
              </Col>
              <Col lg={2} md={6} className=" mb-3 pe-0">
                {/* <Controller
                      control={control}
                      {...register("internalfloor")}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => ( */}
                <div className="form-input ">
                  <input
                    type="number"
                    placeholder="Floor"
                    id="floor"
                    // value={value}
                    // onChange={(e) => {
                    //   onChange(e);
                    //   // setFloor(e.target.value);
                    //   clearErrors("internalfloor");
                    // }}
                  />
                  <label for="floor">Floor </label>
                </div>
                {/* //   )}
                    // />
                    // {errors.internalfloor && (
                    //   <p className="error-msg">This field is required</p>
                    // )} */}
              </Col>
              <Col lg={4} md={6} className="mb-3 ">
                {/* <Controller
                      control={control}
                      {...register("sidedoor")}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => ( */}
                <div className="form-input ">
                  <input
                    type="text"
                    placeholder="Side / Door"
                    id="Side/Door"
                    // value={value}
                    // onChange={(e) => {
                    //   onChange(e);
                    //   clearErrors("sidedoor");
                    // }}
                  />
                  <label for="Side/Door">Side / Door </label>
                </div>
                {/* //   )}
                    // />
                    // {errors.sidedoor && (
                    //   <p className="error-msg">This field is required</p>
                    // )} */}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mx-3 ">
          <Col xl={6} lg={8}>
            <Row>
              <Col lg={4} md={6} className="mb-3 pe-0">
                {/* <Controller
                      control={control}
                      {...register("country")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => {
                        return ( */}
                <ReactSelect
                  placeholder={"Country"}
                  // options={countryData?.data?.map((item) => ({
                  //   value: item?.Id,
                  //   label: item?.Text,
                  // }))}
                  // value={value}
                  // onChange={(e, ActionType) => {
                  //   onChange(e);
                  //   setValue("zipcode", "");
                  //   setCityName("");
                  //   clearErrors("country");
                  // }}
                />
                {/* //     );
                    //   }}
                    // />

                    // {errors.country && (
                    //   <p className="error-msg">This field is required</p>
                    // )} */}
              </Col>
              <Col lg={4} md={6} className="mb-3 pe-0">
                {/* <Controller
                      control={control}
                      {...register("zipcode")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => {
                        return ( */}
                <ReactSelect
                  placeholder={"Zip code"}
                  // options={zipCodeByCountryIdData?.data?.map(
                  //   (zip) => ({
                  //     value: zip?.Id,
                  //     label: `${zip?.Id} ${zip?.Text}`,
                  //   })
                  // )}
                  // value={
                  //   value?.value
                  //     ? {
                  //         value: value?.value,
                  //         label: value?.value,
                  //       }
                  //     : ""
                  // }
                  // onChange={(e, ActionType) => {
                  //   handleZipCode(e, ActionType);
                  //   onChange(e);
                  //   clearErrors("zipcode");
                  // }}
                />
                {/* );
                      }}
                    />

                    {errors.zipcode && (
                      <p className="error-msg">This field is required</p>
                    )} */}
              </Col>

              <Col lg={4} md={6} className="mb-3">
                <div className="form-input ">
                  <input
                    type="text"
                    disabled
                    placeholder="City"
                    id="city"
                    // value={cityName ? cityName : ""}
                  />
                  <label htmlFor="city">City</label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="px-4 pb-2 ms-1">
          <ETButton
            buttonText="ADD EXTRA CUSTOMER"
            variant={"secondary"}
            icon={true}
            width="fit-content"
          />
        </div>
        <hr></hr>

        <div>
          <div className=" pt-2 pb-3 px-4 ms-1 form-label-title">
            Demographics
          </div>
          <Row className="px-4 ">
            <Col>
              <ReactSelect placeholder={"Gender"} />
            </Col>
            <Col>
              <ReactSelect placeholder={"Student license"} />
            </Col>
            <Col>
              <ReactSelect placeholder={"Family with children"} />
            </Col>
            <Col></Col>
          </Row>
        </div>
        <div className="pt-3 pb-3 px-4 ms-1">
          <ETButton
            buttonText="ADD FIELDS"
            variant={"secondary"}
            icon={true}
            width="fit-content"
            onClick={handleClick}
          />
          <Menu
            id="simple-menu"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            open={anchorEl}
            onClose={handleClose}
            elevation={0}
            HorizontalAlignment="left"
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              style: {
                borderRadius: "8px",
                marginTop: "6px",
                border: "0.5px solid #F0F0F0",
                boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.12)",
                paddingTop: "0px",
              },
            }}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.02)",
              "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                padding: 0,
                borderColor: "#F0F0F0",
              },
            }}
          >
            <div
              className="px-3 py-3  fs-14 radius-8"
              style={{
                width: "200px",
              }}
            >
              <div>Age</div>
              <div>Civil status</div>
              <div>Occupation</div>
              <div>Household size</div>
            </div>
          </Menu>
        </div>

        {footer === false ? (
          ""
        ) : (
          <div className="mt-5 py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            {" "}
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              // type={"button"}
              // onClick={() => setCancelPop(true)}
            />
            <div className=" d-flex ">
              {/* {hideBtn == false && ( */}
              <div className="px-2">
                {" "}
                <ETButton
                  buttonText="DELETE PARTICIPANT"
                  variant={"white"}
                  icon={true}
                  width="100%"
                  type={"submit"}
                />
              </div>
              {/* )} */}

              <div className="px-1">
                {" "}
                <ETButton
                  variant={"primary"}
                  buttonText="CONFIRM"
                  type={"submit"}
                  // onClick={() => setHideBtn(true)}
                />
              </div>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default ContactInformation;
