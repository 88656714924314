/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AddDetailPopup from "./AddDetailPopup";
import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";

import { MemoizedAddNewFacility } from "./AddNewFacility";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import ETButton from "../../Components/Buttons/ETButton";
import CustomPopup from "../../Components/CustomPopup";
import {
  useGetFacilityCategory,
  useGetFacilityGroup,
  useAddFacilityPackage,
  useEditFinalFacilityPackage,
  useEditFacilityPackageBasicDetails,
  useGetFacilityAttributesByFacilityId,
  useGetFacilityPackageByPackageId,
  useGetFacilityByPropertyId,
} from "../../hooks/queries/usefacilityQueries";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import AddEditPackage from "../properties/units/AddEditPackage/AddEditPackage";
import { toast } from "react-toastify";
import {
  usePropertyId,
  usePropertyOverview,
} from "../../hooks/stores/usePropertyStore";
import { useAddEditPackage } from "../../hooks/stores/usePackageStore";
import { status } from "../../model/Constant";
import { useRouting } from "../../hooks/stores/useRoutingStore";
import moment from "moment";
import { PrimaryBreadcrums } from "../../styles/Common.style";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../Components/table/MRTGlobalProps";
import MRTTheme from "../../Components/table/MRTTheme";
import { CustomersTab } from "../../Components/Tabs/CustomersTabs.style";
import { useFacility } from "../../hooks/stores/useFacilityStore";
import { GetTagsByPropertyId } from "../../services/Tags";
import { Link } from "react-router-dom";

function AddNewPackage1() {
  const {
    setShowPackage,
    editflag,
    setEditflag,
    packageId,
    setPackageId,
    packageType,
    setPackageType,
  } = useRouting((state) => state);
  const propOverview = usePropertyOverview((state) => state.propOverview);
  const { propertyId } = usePropertyId((state) => state);

  const {
    title,
    setTitle,
    active,
    setActive,
    setTagRowData,
    selected,
    setSelected,
    setSelectedData,
    cancelPopup,
    setCancelPopup,
  } = useAddEditPackage((state) => state);
  const { setOuterFacilityTable, outerFacilityTable } = useFacility(
    (state) => state
  );
  const [addFacilityPage, setAddFacilityPage] = useState(false);
  const [toggleYes, setToggleYes] = useState(true);
  const [facilityTableData, setFacilityTableData] = useState();
  const [categoryFilter, setCategoryFilter] = useState();
  const [groupFilter, setGroupFilter] = useState();
  const [modalHeadingStyle, setModalHeadingStyle] = useState(false);
  const [addDetail, setAddDetail] = useState(false);
  const [facilityId, setFacilityId] = useState("");
  const [activeSwitch, setActiveSwitch] = useState(undefined);
  const [addFacilityBtn, setAddFaciltyBtn] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [delectedFacilityTag, setDelectedFacilityTag] = useState("");
  const [rows, setRows] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [deletePopUp, setDeletePopUp] = useState(false);

  const {
    data: GetFacilityPackageByPackageIdData,
    refetch: GetFacilityPackageByPackageIdDataRefetch,
  } = useGetFacilityPackageByPackageId(propertyId, packageId, editflag);
  const { data: GetFacilityCategoryData } = useGetFacilityCategory();
  const { data: GetFacilityGroupData } = useGetFacilityGroup();

  const {
    data: GetFacilityByPropertyIdData,
    refetch: GetFacilityByPropertyIdRefetch,
  } = useGetFacilityByPropertyId(propertyId);

  const { data: tagsByPropertyIdData } = useQuery(
    ["propertyId", propertyId],
    () => GetTagsByPropertyId(propertyId),
    {
      enabled: !!propertyId,
    }
  );
  const { mutateAsync: editFacilityPackageBasicDetailsMutate } =
    useEditFacilityPackageBasicDetails();
  const { mutateAsync: addNewPackageMutate } = useAddFacilityPackage();
  const { mutateAsync: editFinalFacilityPackageMutate } =
    useEditFinalFacilityPackage();

  const {
    data: GetFacilityAttributesByFacilityIdData,
    refetch: GetFacilityAttributesByFacilityIdDataRefetch,
  } = useGetFacilityAttributesByFacilityId(
    propertyId,
    0,
    packageId,
    facilityId
  );

  const handleSetFacilityStore = () => {
    setOuterFacilityTable(
      GetFacilityPackageByPackageIdData?.data?.[0]?.facility_details
    );
  };

  useEffect(() => {
    // to prevent the popup from refetching the API
    if (addFacilityBtn) {
      return;
    } else {
      handleSetFacilityStore();
    }
  }, [GetFacilityPackageByPackageIdData?.data]);

  useEffect(() => {
    setFacilityTableData(outerFacilityTable);
  }, [outerFacilityTable]);

  useEffect(() => {
    if (editflag) {
      setFacilityTableData(outerFacilityTable);
      setCancelPopup(false);
      setTitle(GetFacilityPackageByPackageIdData?.data?.[0]?.title);
      const filteredData =
        GetFacilityPackageByPackageIdData?.data?.[0]?.tag_ids?.split(",");
      const tagData = tagsByPropertyIdData?.data?.filter((el) =>
        filteredData?.includes(el.tag_id?.toString())
      );
      setTagRowData(tagData);
      setSelected(filteredData);
      setSelectedData(tagData);
      setActive(
        GetFacilityPackageByPackageIdData?.data?.[0]?.is_active === 1
          ? true
          : false
      );
    }
  }, [editflag, GetFacilityPackageByPackageIdData, tagsByPropertyIdData]);

  const handleAddFacilityDetails = async () => {
    if (editflag) {
      const EditFacilityPackageBasicDetails = {
        facility_package_id: packageId,
        title,
        is_active: active ? "1" : "0",
        tags: selected,
      };
      try {
        const result = await editFacilityPackageBasicDetailsMutate(
          EditFacilityPackageBasicDetails
        );
        if (result.status === status.s) {
          toast.success(result?.data?.message);
          setAddFacilityPage(true);
        } else {
          toast.error(result?.data?.message);
        }
      } catch (error) {}
    } else {
      const createPackagePayload = {
        property_id: propertyId,
        title,
        is_active: active ? "1" : "0",

        tags: selected,
      };
      try {
        const result = await addNewPackageMutate(createPackagePayload);
        if (result?.status === "success" && result?.data?.bReturn) {
          toast.success(result?.data?.message);
          setAddFacilityPage(true);
          setPackageId(result?.data?.data?.facility_package_id);
        } else {
          toast.error(result?.data?.message);
        }
      } catch (error) {}
    }
  };
  const handleCategoryFilter = (e, ActionTypes) => {
    if (ActionTypes.action === "clear") {
      setCategoryFilter(null);
      setFacilityTableData(outerFacilityTable);
    } else {
      let filteredData = outerFacilityTable?.filter(
        (facility) => facility.category === e?.label
      );
      setFacilityTableData(filteredData);
      setCategoryFilter(e);
    }
  };

  const handleGroupFilter = (e, ActionTypes) => {
    if (ActionTypes.action === "clear") {
      setGroupFilter(null);

      setFacilityTableData(outerFacilityTable);
    } else {
      let filteredData = outerFacilityTable?.filter(
        (facility) => facility.group === e?.label
      );
      setFacilityTableData(filteredData);
      setGroupFilter(e);
    }
  };

  const handleAddFacility = async () => {
    setAddFaciltyBtn(true);

    if (groupFilter !== null) {
      setGroupFilter(null);
    }
    if (categoryFilter !== null) {
      setCategoryFilter(null);
    }
    if (globalFilter !== "") {
      setGlobalFilter("");
    }
  };

  const cancelConfirmation = () => {
    setCancelPopup(false);
    setEditflag(false);
    setShowPackage(false);
    setTitle("");
    setTagRowData([]);
    setSelected([]);
  };
  const handlePrevious = () => {
    setAddFacilityPage(false);
  };
  const handleCreatePackage = async () => {
    const handleAddFinalFacilityPayload = {
      facility_package_id: String(packageId),
      title,
      is_active: active ? "1" : "0",
      tags: selected?.map?.((item) => {
        return item;
      }),
      facilities: outerFacilityTable?.map((fac) => {
        return fac?.facility_id;
      }),
    };
    try {
      const result = await editFinalFacilityPackageMutate(
        handleAddFinalFacilityPayload
      );
      if (result.status === "success" && result?.data?.bReturn) {
        toast.success(result.data.message);
        setAddFaciltyBtn(false);
        setShowPackage(false);
        setRowSelection({});
        setOuterFacilityTable([]);
      } else {
        toast.error(result.data.message);
        setRowSelection({});
      }
    } catch (error) {}
  };
  const handleEditFacilityAttribute = (facility_id) => {
    setFacilityId(facility_id);
    setPackageType("4");
    setAddDetail(true);
    GetFacilityAttributesByFacilityIdDataRefetch();
  };
  const handleDeleteFacility = (deleteFaciityId) => {
    const indexToRemove = outerFacilityTable?.findIndex(
      (element) => Number(element?.facility_id) == deleteFaciityId
    );

    if (indexToRemove !== -1) {
      const updatedData = [...outerFacilityTable];

      updatedData?.splice(indexToRemove, 1);
      setOuterFacilityTable(updatedData);
    }
    setDeletePopUp(false);
    // store deleted facility id //
    const deletedFacilityId = facilityTableData
      ?.filter((item) => item?.facility_id != facilityId)
      ?.map((data) => data?.facility_id);
    setRows(deletedFacilityId);
  };
  useEffect(() => {
    // Filter rowSelection based on the rows(when we delete the data from outer table then also remove check from iner table)
    const filteredRowSelection = Object?.keys(rowSelection)
      ?.filter((facility_id) => rows?.includes(facility_id))
      ?.reduce((acc, facility_id) => {
        acc[facility_id] = true;
        return acc;
      }, {});

    setRowSelection(filteredRowSelection);
  }, [rows]);

  const modifiedData = GetFacilityPackageByPackageIdData?.data?.map((item) => {
    return {
      ...item,
      modifiedOn: moment(item.modified_on).format("DD.MMMM.YYYY | HH:mm:ss"),
      createdOn: moment(item.created_on).format("DD.MMMM.YYYY | HH:mm:ss"),
    };
  });

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.icon_name}${row.facility_name}`,
        header: "Facility Name",
        size: 180,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {row.original.icon_name !== "" ? (
              <FontAwesomeIcon
                icon={row.original.icon_name}
                style={{ fontSize: "1rem" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={"fa-solid fa-circle"}
                style={{ fontSize: "1rem" }}
              />
            )}

            <div>{row.original.facility_name}</div>
          </div>
        ),
      },
      {
        accessorKey: "category",
        header: "Category",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "group",
        header: "Group",
        enableHiding: false,
        size: 80,
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => `${row.setting}`,
        header: "Setting",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <>
            {row.original.setting == 1
              ? setToggleYes(true)
              : setToggleYes(false)}
            <span>{row.original.setting == 1 ? "Yes" : "No"}</span>
          </>
        ),
      },
    ],
    []
  );

  // const renderEmptyRowsFallback = () => (
  // 	<div
  // 		className="d-flex justify-content-center w-100 py-4 grey-text"
  // 		style={{ color: "#888888" }}
  // 	>
  // 		<div>
  // 			You do not have any facility yet. Click on “+ ADD FACILITY” to create
  // 			your first.
  // 		</div>
  // 	</div>
  // );
  const renderEmptyRowsFallback = () => {
    if (
      (facilityTableData && facilityTableData?.length === 0) ||
      facilityTableData === undefined
    ) {
      return (
        <div
          className="d-flex justify-content-center w-100 grey-text py-4"
          style={{ color: "#888888" }}
        >
          <>
            <div>
              You do not have added any facilities yet. Click on “+ ADD
              FACILITY” to add your very first facility!
            </div>
          </>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div style={{ fontSize: "14px", color: "#888888" }}>
              Sorry! No result found. Please try again!
            </div>
          </>
        </div>
      );
    }
  };

  return (
    <>
      <Container
        fluid
        style={{ backgroundColor: "#F9FAFA" }}
        className=" pt-3 px-0 "
      >
        <div style={{ fontWeight: "600", fontSize: "24px" }}>
          {editflag ? "Edit" : "Create new"} package
        </div>
        <div
          style={{
            fontFamily: " Cerebri sans-light",
            fontSize: "14px",
          }}
        >
          <Link
            to="/properties"
            style={{ textDecoration: "none", color: "black" }}
          >
            <span className="me-3">Property</span>
          </Link>

          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />
          </span>
          <Link
            to="/property-units/overview"
            state={{
              Tab: "Overview",
            }}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span className="me-3">{propOverview?.[0]?.propertyName}</span>
          </Link>

          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />
          </span>
          <span
            className="me-3 cursor-pointer"
            onClick={() => {
              setShowPackage(false);
            }}
          >
            Facilities
          </span>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />
          </span>
          <span className="me-3 cursor-pointer">
            {editflag ? "Edit" : "Packages"}
          </span>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />
          </span>
          <span className="me-3">
            {editflag ? "Edit" : "Create new"} package
          </span>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />
          </span>
          <PrimaryBreadcrums mode={!addFacilityPage} className="mx-1">
            1. Settings
          </PrimaryBreadcrums>
          {addFacilityPage && (
            <>
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />{" "}
              </span>
              <PrimaryBreadcrums mode={addFacilityPage} className="mx-1">
                2. {editflag ? "Edit" : "Add"} facility
              </PrimaryBreadcrums>
            </>
          )}
        </div>
        <div
          className="radius-8 p-4 my-3 containerStyle"
          // style={{ backgroundColor: "#f1f5f0" }}
        >
          <div
            className="d-flex gap-3 pb-2"
            style={{ alignItems: "center", textAlign: "center" }}
          >
            <div>
              <CustomersTab mode={!addFacilityPage}>
                {addFacilityPage && (
                  <FontAwesomeIcon
                    icon="fa-solid fa-circle-check"
                    className="me-2"
                    style={{ color: "#77C7A1", fontSize: "0.9rem" }}
                  />
                )}
                1. Settings
              </CustomersTab>
            </div>
            <div style={{ alignItems: "center", textAlign: "center" }}>
              <FontAwesomeIcon
                icon="fa-regular fa-greater-than"
                style={{ color: "#010204", width: "8px", height: "10px" }}
              />
            </div>
            <div>
              <CustomersTab mode={addFacilityPage}>
                {editflag ? "2. Edit facility" : "2. Add facility"}
              </CustomersTab>
            </div>
          </div>
          {!addFacilityPage ? (
            <AddEditPackage
              modifiedData={modifiedData}
              packageType={packageType}
              showDropdown={false}
              showActiveInactive={true}
              propertyId={propertyId}
              handleNext={handleAddFacilityDetails}
            />
          ) : (
            <>
              <div className="media-con  pt-0 pb-3">
                <div className="media-sub-container ">
                  <div>
                    <div className="media-header px-4 py-3">
                      <div className="gap-2">
                        <div className="fs-14 fw-700 d-flex">
                          <span className="me-2">
                            {editflag ? "2.Edit facility" : "2. Add facility"}
                          </span>{" "}
                          <CustomToolTip text="Add facility" />
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "300",
                            color: "#888",
                          }}
                        >
                          {modifiedData?.map((data) => (
                            <div>
                              {data.modified_by && data.modified_on ? (
                                <>
                                  <div>
                                    Last updated by {data?.modified_by}{" "}
                                    {data?.modifiedOn}
                                  </div>
                                </>
                              ) : (
                                <>
                                  {data?.created_by && data?.created_by ? (
                                    <div>
                                      Created by {data?.created_by}{" "}
                                      {data?.created_by}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" px-3 py-2">
                    <MRTTheme
                      colorVariantsForTableData={["odd"]}
                      table={
                        <MaterialReactTable
                          columns={columns}
                          data={facilityTableData ?? []}
                          icons={{ ...SortIcon }}
                          renderEmptyRowsFallback={renderEmptyRowsFallback}
                          enableRowActions
                          enableColumnActions={false}
                          globalFilterFn="contains"
                          initialState={{ showGlobalFilter: true }}
                          onGlobalFilterChange={setGlobalFilter} //hoist internal global state to your state
                          state={{ globalFilter }} //pass in your own managed globalFilter state
                          displayColumnDefOptions={{
                            "mrt-row-actions": {
                              header: "",
                              muiTableHeadCellProps: {
                                align: "right",
                              },
                              muiTableBodyCellProps: {
                                align: "right",
                              },
                            },
                          }}
                          muiSearchTextFieldProps={{
                            ...SearchStyle,

                            InputProps: {
                              ...SearchIcon,
                            },
                          }}
                          enableColumnFilterModes={false}
                          renderTopToolbar={({ table }) => (
                            <Row className="pt-3 pb-3 px-4">
                              <Col className="ps-lg-0" lg={2} sm={4}>
                                <div className="">
                                  <MRT_GlobalFilterTextField
                                    table={table}
                                    value={globalFilter || ""}
                                    onChange={setGlobalFilter}
                                  />
                                </div>
                              </Col>
                              <Col className="ps-lg-0" lg={2}>
                                <ReactSelect
                                  placeholder={"Category"}
                                  options={GetFacilityCategoryData?.data?.map(
                                    (item) => ({
                                      value: item?.Id,
                                      label: `${item?.Text}`,
                                    })
                                  )}
                                  value={categoryFilter}
                                  onChange={(e, ActionTypes) =>
                                    handleCategoryFilter(e, ActionTypes)
                                  }
                                />
                              </Col>
                              <Col className="ps-lg-0" lg={2}>
                                <ReactSelect
                                  placeholder={"Group"}
                                  options={GetFacilityGroupData?.data?.map(
                                    (item) => ({
                                      value: item?.Id,
                                      label: `${item?.Text}`,
                                    })
                                  )}
                                  value={groupFilter}
                                  onChange={(e, ActionTypes) =>
                                    handleGroupFilter(e, ActionTypes)
                                  }
                                />
                              </Col>
                              <Col
                                className="pe-lg-0"
                                lg={{ span: 3, offset: 3 }}
                              >
                                <div className="packege-ETButton">
                                  <ETButton
                                    buttonText="ADD FACILITY"
                                    variant={"primary"}
                                    icon={true}
                                    width="fit-content"
                                    onClick={handleAddFacility}
                                  />
                                </div>
                              </Col>
                            </Row>
                          )}
                          positionActionsColumn="last"
                          muiTableHeadRowProps={{
                            sx: {
                              ...TableRowsStyle,
                            },
                          }}
                          muiTablePaginationProps={{
                            ...paginationoptions,
                          }}
                          muiTableContainerProps={{
                            sx: {
                              padding: "0px 8px !important",
                            },
                          }}
                          renderRowActions={({ row, table }) => (
                            <div className="d-flex gap-2">
                              <button
                                className="icon-btn"
                                onClick={() =>
                                  handleEditFacilityAttribute(
                                    row?.original?.facility_id
                                  )
                                }
                              >
                                <FontAwesomeIcon icon="fa-regular fa-pen-to-square" />
                              </button>

                              <button
                                className="icon-btn"
                                onClick={() => {
                                  setFacilityId(row?.original?.facility_id);
                                  setDeletePopUp(true);
                                }}
                              >
                                <FontAwesomeIcon icon="fa-regular fa-trash" />
                              </button>
                            </div>
                          )}
                        />
                      }
                    />
                  </div>
                  {facilityTableData?.length == 0 && (
                    <div className="d-flex justify-content-center w-100 fs-12 mt-1 grey-text">
                      <div>
                        No tags are added to the package. That’s a must before
                        you can continue.{" "}
                      </div>{" "}
                    </div>
                  )}
                  {/* edit facility */}
                  <CustomPopup
                    modalHeadingStyle={modalHeadingStyle}
                    style={{ position: "relative" }}
                    size="lg"
                    open={addDetail}
                    onHide={() => setAddDetail(false)}
                    title="Add details"
                    title_color="black"
                    titleTooltipText="Need Help About Tags"
                    titleTooltip={true}
                    enableCrossButton={false}
                    onCloseAction={() => {
                      setAddDetail(false);
                      alert("Action Peformed Successfuly.");
                    }}
                  >
                    <AddDetailPopup
                      setAddDetail={setAddDetail}
                      facilityId={facilityId}
                      propertyId={propertyId}
                      UnitId={0}
                      packageId={packageId}
                      facilityLevel={"package"}
                      activeSwitch={activeSwitch}
                      setActiveSwitch={setActiveSwitch}
                      GetFacilityAttributesByFacilityIdData={
                        GetFacilityAttributesByFacilityIdData
                      }
                      GetFacilityPackageByPackageIdData={
                        GetFacilityPackageByPackageIdData
                      }
                      GetFacilityAttributesByFacilityIdDataRefetch={
                        GetFacilityAttributesByFacilityIdDataRefetch
                      }
                      GetFacilityByPropertyIdRefetch={
                        GetFacilityByPropertyIdRefetch
                      }
                      refetch={GetFacilityPackageByPackageIdDataRefetch}
                      GetFacilityPackageByPackageIdDataRefetch={
                        GetFacilityPackageByPackageIdDataRefetch
                      }
                    />
                  </CustomPopup>

                  {/* Add facility */}
                  <CustomPopup
                    style={{ position: "relative" }}
                    size="lg"
                    open={addFacilityBtn}
                    onHide={() => setAddFaciltyBtn(false)}
                    title="Add details"
                    title_color="black"
                    titleTooltipText="Need Help About Tags"
                    titleTooltip={true}
                    enableCrossButton={false}
                    onCloseAction={() => {
                      setAddFaciltyBtn(false);
                      alert("Action Peformed Successfuly.");
                    }}
                  >
                    <MemoizedAddNewFacility
                      setAddFaciltyBtn={setAddFaciltyBtn}
                      propertyId={propertyId}
                      packageId={packageId}
                      rowSelection={rowSelection}
                      setRowSelection={setRowSelection}
                      setFacilityTableData={setFacilityTableData}
                      editflag={editflag}
                      facilityTableData={facilityTableData}
                    />
                  </CustomPopup>

                  <div
                    className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
                    style={{
                      backgroundColor: "#F9FAFA",
                      borderRadius: " 0px 0px 8px 8px",
                      borderTop: "1px solid  #F0F0F0",
                    }}
                  >
                    {" "}
                    <ETButton
                      variant={"white"}
                      buttonText="CANCEL"
                      onClick={() => setCancelPopup(true)}
                    />
                    <div className="d-flex gap-2">
                      <ETButton
                        variant={"white"}
                        buttonText="PREVIOUS"
                        onClick={handlePrevious}
                      />
                      <ETButton
                        variant={"primary"}
                        buttonText={
                          editflag ? "SAVE AND CLOSE" : "CREATE PACKAGE"
                        }
                        type="submit"
                        disabled={outerFacilityTable?.length > 0 ? false : true}
                        onClick={handleCreatePackage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Container>
      <CustomPopup
        style={{ position: "relative" }}
        size="md"
        open={cancelPopup}
        onHide={() => setCancelPopup(false)}
        title="Are you sure you want to cancel?"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() => {
          setCancelPopup(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div
            style={{
              fontSize: "14px",
              fontFamily: "Cerebri Sans",
              fontWeight: "500",
              textAlign: "left",
              lineHeight: "17.5px",
            }}
          >
            Cancelling will undo all your changes
          </div>
        </div>

        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="NO"
            onClick={() => setCancelPopup(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="YES"
            onClick={cancelConfirmation}
          />
        </div>
      </CustomPopup>
      <CustomPopup
        open={deletePopUp}
        onHide={() => setDeletePopUp(false)}
        title="Delete Facility"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-3 fs-14 fw-300">
          <p>You are deleting this Facility.</p>
          <p>Are you sure you want to delete?</p>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setDeletePopUp(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="DELETE"
            onClick={() => handleDeleteFacility(facilityId)}
          />
        </div>
      </CustomPopup>
    </>
  );
}
export const MemoizedAddNewPackage = React.memo(AddNewPackage1);
