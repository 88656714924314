import { Col, Form, Row } from "react-bootstrap";
import Menus from "../../../Components/Menus/Menus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ETButton from "../../../Components/Buttons/ETButton";
import { useState } from "react";
import {
  DeleteDocumentPackageByPackageID,
  PostCloneDocumentPackage,
} from "../../../services/Documents";
import CustomPopup from "../../../Components/CustomPopup";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Duplicate from "../../properties/units/duplicate/Duplicate";
import {
  useAllPropertiesData,
  usePropertyOverview,
} from "../../../hooks/stores/usePropertyStore";
import { useRouting } from "../../../hooks/stores/useRoutingStore";
import { useAddEditPackage } from "../../../hooks/stores/usePackageStore";
import DocumentCard from "../../../Components/card/DocumentCard";
import { status } from "../../../model/Constant";
import { usePostCloneDocumentPackage } from "../../../hooks/queries/useDocumentsQueries";
import { StyledCard } from "../../../styles/Common.style";
export const DocumentPackages = ({
  // setShowPackage,
  propertyOverview,
  propertyId,
  // setDocumentPackageId,
  setIsEditDocumentPackages,
  getDocumentPackagesByPropertyIdData,
  getDocumentPackagesByPropertyIdDataRefetch,
  documentPackageId,
  getAllpropertiesList,
}) => {
  // zustand
  const { allPropertiesStoreData } = useAllPropertiesData((state) => state);
  const {
    packageType,
    setPackageType,
    showPackage,
    setShowPackage,
    editflag,
    setEditflag,
    packageId,
    setPackageId,
  } = useRouting((state) => state);
  const {
    title,
    setTitle,
    active,
    setActive,
    tagRowData,
    setTagRowData,
    selected,
    setSelected,
    selectedData,
    setSelectedData,
    cancelPopup,
    setCancelPopup,
  } = useAddEditPackage((state) => state);

  return (
    <Row className="py-3">
      {getDocumentPackagesByPropertyIdData?.data?.map((item, index) => (
        <DocumentPackageCard
          setShowPackage={setShowPackage}
          setEditflag={setEditflag}
          packageId={packageId}
          setPackageId={setPackageId}
          setPackageType={setPackageType}
          title={title}
          setTitle={setTitle}
          setActive={setActive}
          setTagRowData={setTagRowData}
          setSelected={setSelected}
          setSelectedData={setSelectedData}
          setCancelPopup={setCancelPopup}
          key={index}
          data={item}
          propertyId={propertyOverview}
          // propertyId={propertyOverview?.[0]?.property_id}
          getDocumentPackagesByPropertyIdDataRefetch={
            getDocumentPackagesByPropertyIdDataRefetch
          }
          // setDocumentPackageId={setDocumentPackageId}
          // setShowDocumentPackages={setShowDocumentPackages}
          // setIsEditDocumentPackages={setIsEditDocumentPackages}
          getAllpropertiesList={getAllpropertiesList}
          // documentPackageId={documentPackageId}
        />
      ))}
      {getDocumentPackagesByPropertyIdData?.data?.length === 0 && (
        <Col lg={6} xl={4} className="my-2">
          <StyledCard height="410px">
            <ETButton
              variant={"primary"}
              buttonText="ADD NEW PACKAGE"
              icon={true}
              width="fit-content"
              onClick={() => {
                setCancelPopup(true);
                // setIsEditDocumentPackages(false);
                setEditflag(false);
                // setShowDocumentPackages(true); // used prop drilling for showing <AddDocumentPackages /> component outside of document layout.
                setShowPackage(true);
                setPackageType("6");
              }}
            />
          </StyledCard>
          {/* <div className="radius-8 d-flex align-items-center justify-content-center create-document-package p-3"> */}

          {/* </div> */}
        </Col>
      )}
    </Row>
  );
};

export const DocumentPackageCard = ({
  setShowPackage,
  setEditflag,
  packageId,
  title,
  setPackageId,
  data,
  propertyId,
  setTitle,
  setActive,
  setTagRowData,
  setSelected,
  setSelectedData,
  setCancelPopup,
  getDocumentPackagesByPropertyIdDataRefetch,
  setPackageType,
  // setDocumentPackageId,
  // setShowDocumentPackages,
  // setIsEditDocumentPackages,
  getAllpropertiesList,
  // documentPackageId,
  menus,
}) => {
  const propOverview = usePropertyOverview((state) => state.propOverview);
  const [anchorEl, setAnchorEl] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [openDocumetpackageMenu, setOpenDocumetpackageMenu] = useState({
    delete: false,
    duplicate: false,
  });

  const [allChecked, setAllChecked] = useState([]);
  const [openDuplicatePackageModal, setOpenDuplicatePackageModal] =
    useState(false);
  const [deleteDisableButton, setDeleteDisableButton] = useState(false);
  const {
    mutateAsync: PostCloneDocumentPackageMutate,
    isLoading: PostCloneDocumentPackageIsLoading,
  } = usePostCloneDocumentPackage();
  // ---------------------------handleDuplicate---------------//
  const handleDuplicate = async (data) => {
    const duplicatePackagePayload = {
      document_package_id: packageId?.toString(),
      title: data?.packageTitle,
      clone_type: String(data?.whatToDuplicate?.value),
      property_id: allChecked,
    };
    let id;
    if (!PostCloneDocumentPackageIsLoading) {
      id = toast.loading("Copying package...");
    }
    PostCloneDocumentPackageMutate(duplicatePackagePayload)
      .then((response) => {
        if (response.data?.bReturn) {
          getDocumentPackagesByPropertyIdDataRefetch();
          setOpenDocumetpackageMenu({
            ...openDocumetpackageMenu,
            duplicate: false,
          });
          setOpenDuplicatePackageModal(false);
          setAllChecked([]);
          setTitle("");
          setActive(false);
          setTagRowData([]);
          setSelected([]);
          setSelectedData([]);
          toast.update(id, {
            render: response?.data?.message,
            isLoading: false,
            type: status.s,
            autoClose: 2000,
          });
        } else {
          toast.update(id, {
            render: response?.data?.message,
            isLoading: false,
            type: status.e,
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        toast.update(id, {
          render: error.message,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDeletePopUp(false);
  };

  const handleDelete = async (packageId) => {
    setDeleteDisableButton(true);
    const response = await DeleteDocumentPackageByPackageID(
      propOverview[0]?.property_id,
      packageId
    );
    if (response.status === status.s) {
      toast.success(response.data.message);
      handleClose();
      setDeleteDisableButton(false);
      getDocumentPackagesByPropertyIdDataRefetch();
      setOpenDocumetpackageMenu({
        ...openDocumetpackageMenu,
        delete: false,
      });
    }
  };
  const handleEditDocument = (id) => {
    // setDocumentPackageId(data?.document_package_id);
    setPackageId(id);
    setPackageType("6");
    // setIsEditDocumentPackages(true);
    setEditflag(true);
    // setShowDocumentPackages(true);
    setShowPackage(true);
  };

  const duplicatePackage = (id) => {
    setPackageId(id);
    setOpenDuplicatePackageModal(true);
  };
  const deletePackage = (id) => {
    setPackageId(id);

    setOpenDocumetpackageMenu({
      ...openDocumetpackageMenu,
      delete: true,
    });
  };
  return (
    <>
      <Col className="mb-4" lg={6} xl={4}>
        <DocumentCard
          data={data}
          handleEditDocument={handleEditDocument}
          duplicatePackage={duplicatePackage}
          deletePackage={deletePackage}
        />

        {/* Popup for duplicate package property */}
        <Duplicate
          openDuplicate={openDuplicatePackageModal}
          setOpenDuplicate={setOpenDuplicatePackageModal}
          propertyData={getAllpropertiesList?.data?.filter(
            (el) => el?.property_id !== propertyId
          )}
          // zustand
          // propertyData={allPropertiesStoreData?.filter(
          // 	(el) => el?.property_id !== propertyId
          // )}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          handleDuplicate={handleDuplicate}
          disableDuplicateButton={PostCloneDocumentPackageIsLoading}
        />
        {/* Popup for duplicate package property */}
      </Col>

      {/* Delete document property */}
      <CustomPopup
        open={openDocumetpackageMenu.delete}
        onHide={() =>
          setOpenDocumetpackageMenu({
            ...openDocumetpackageMenu,
            delete: false,
          })
        }
        title="Delete package"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="p-3">
          <div className="px-2">
            <div className="fw-300 fs-14 my-1" style={{ height: "100px" }}>
              <p>Are you sure you want to delete the package?</p>
              <p>
                Be aware that both the package and all included documents linked
                to the unit will be deleted.
              </p>
            </div>
          </div>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => {
              setOpenDocumetpackageMenu({
                ...openDocumetpackageMenu,
                delete: false,
              });
            }}
          />
          <ETButton
            variant={"primary"}
            buttonText="I'M SURE"
            disabled={deleteDisableButton}
            onClick={() => {
              handleDelete(data?.document_package_id);
            }}
          />
        </div>
      </CustomPopup>
      {/* Delete document property */}
    </>
  );
};

DocumentPackageCard.defaultProps = {
  menus: true,
};
