import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import CustomToolTip from "../../../../../../Components/Tooltip/CustomTooltip";
import Toggle from "../../../../../../Components/Switch/Switch";
import { H4, H5 } from "../../../../../../styles/Common.style";
import ReactSelect from "../../../../../../Components/DropDown/ReactSelect";
import ETButton from "../../../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../../../Components/CustomPopup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import LanguageSelectSearch from "../../../../../../Components/FilterDropdownSelection/LanguageSelectSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Common component for Create and edit area group && Create and edit area
// usedFor = CAG
// usedFor = EAG
// usedFor = CNA
// usedFor = ENA

export const AreaGroupPopup = ({
  open,
  onHide,
  onCancel = () => {},
  onStart = () => {},
  usedFor,
}) => {
  const {
    register,
    getValues,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      addLanguage: [
        {
          category: "",
          selectLanguage: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "addLanguage",
  });

  const handleAddStepButton = () => {
    append({
      category: "",
      selectLanguage: "",
    });
  };

  return (
    <div>
      <CustomPopup
        open={open}
        onHide={onHide}
        title={
          usedFor == "CAG"
            ? "Create Area group"
            : usedFor == "CNA"
            ? "Create Area"
            : usedFor == "ENA"
            ? "Edit Area"
            : "Edit Area group"
        }
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        size={"lg"}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        titleTooltipText={
          usedFor == "CAG"
            ? "Create Area group"
            : usedFor == "CNA"
            ? "Create Area"
            : usedFor == "ENA"
            ? "Edit Area"
            : "Edit Area group"
        }
        secondTextShow={false}
        priorityShow={false}
      >
        <div className="light-font-text">
          <div className="px-4 py-3 border-bottom">
            {fields?.map((item, index) => (
              <Row
                key={item.id}
                className="pb-3"
              >
                <Col>
                  <div className="d-flex gap-2 align-items-center pb-1">
                    <H5>
                      Name of Area{" "}
                      {(usedFor == "CAG" || usedFor == "EAG") && "group"}
                    </H5>
                    <CustomToolTip text="Name of Area group" />
                  </div>
                  <div>
                    <Controller
                      control={control}
                      {...register(`addLanguage.${index}.category`)}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder={"Category"}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      )}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="d-flex gap-2 align-items-center pb-1">
                    <H5>Language</H5>
                    <CustomToolTip text="Language" />
                  </div>
                  <div className="d-flex gap-1">
                    <Controller
                      control={control}
                      {...register(`addLanguage.${index}.selectLanguage`)}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div className="d-flex align-items-center w-100">
                          <div className="w-100 pe-2">
                            <LanguageSelectSearch placeholder="Select languages" />
                          </div>
                          {fields.length > 1 && (
                            <div
                              onClick={() => remove(index)}
                              style={{
                                width: "25px",
                              }}
                              className="d-flex align-items-center justify-content-center"
                            >
                              {!(index == 0) && (
                                <FontAwesomeIcon
                                  icon="fa-regular fa-trash"
                                  style={{ color: "#000000" }}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </Col>
              </Row>
            ))}
            <ETButton
              buttonText="ADD LANGUAGE VERSION"
              icon={true}
              onClick={handleAddStepButton}
              variant="secondary"
            />
          </div>
        </div>

        <div className="px-4 py-3 border-top">
          <div className="d-flex gap-2 align-items-center pb-1">
            <H5>Add Area to Area group</H5>
            <CustomToolTip text="Name of Area group" />
          </div>
          <div className="w-50">
            <ReactSelect placeholder="Area group" />
          </div>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            buttonText="Cancel"
            variant={"white"}
            onClick={onCancel}
          />
          <div className="d-flex ">
            <div>
              <ETButton
                buttonText={
                  usedFor == "CAG" || usedFor == "CNA" ? "CREATE" : "SAVE"
                }
                variant={"primary"}
                onClick={onStart}
              />
            </div>
          </div>
        </div>
      </CustomPopup>
    </div>
  );
};

export default AreaGroupPopup;

// Created by --------------> Yash
