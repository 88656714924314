import React from "react";
import { Col, Row } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const NewColorBox = (props) => {
  const { unitsOverview, TotalUnits } = props;

  // const getStatusBGDetails = (status_name) => {
  //   let status = propOverview?.[0]?.status_color?.filter(
  //     (ele) => ele.status_name === status_name
  //   );
  //   return status?.[0]?.background_color;
  // };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: "#000",
      width: 220,
      fontFamily: "Cerebri sans",
      fontSize: "14px",
      border: "1px solid #F4F5F5",
      borderRadius: "8px",
      cursor: "pointer",
    },
  }));

  return (
    <div>
      <Row>
        <Col className="d-flex gap-1">
          {unitsOverview?.map((item, i) => {
            return (
              <div key={i}>
                <HtmlTooltip
                  title={
                    <div className="m-2" style={{ fontFamily: "Cerebri sans" }}>
                      <div>
                        <span
                          className="ms-0 my-1 rounded "
                          style={{
                            fontSize: "12px",
                            padding: "3px 10px",
                            color: `${item?.text_color || '#FFFFFF' }`,
                            backgroundColor: `${item?.bg_color}`,
                          }}
                        ></span>{" "}
                        <span className="ms-2 tooltip-header">
                          {item?.status_name}
                        </span>
                      </div>
                      <div className="d-flex mt-2 tooltip-body-text">
                        This property currently has {item?.units}{" "}
                        {item?.status_name}{" "}
                        {item?.status_name > 1 ? "units" : "unit"}. This is
                        equivalent to{" "}
                        {parseInt(TotalUnits) === 0
                          ? "0"
                          : `${parseFloat(
                              (
                                (parseInt(item?.units) / parseInt(TotalUnits)) *
                                100
                              ).toFixed(2)
                            )}`}
                        % of the total number of{" "}
                        {item?.status_name > 1 ? "units" : "unit"} in the
                        property
                      </div>
                    </div>
                  }
                >
                  <span
                    className="mx-0 my-1 rounded property-status-number cursor-pointer"
                    style={{
                      padding: "4px 12px",
                      color: `${item?.text_color}`,
                      backgroundColor: `${item?.bg_color}`,
                    }}
                  >
                    {item?.units}
                  </span>
                </HtmlTooltip>
              </div>
            );
          })}
        </Col>
      </Row>
    </div>
  );
};

export default NewColorBox;
