import React, { useEffect } from "react";
import { Col, Form, Placeholder, Row } from "react-bootstrap";
import { GreenSpan, H1, H2, H4, H5 } from "../../../../styles/Common.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import Toggle from "../../../../Components/Switch/Switch";
import GroupButtons from "../../../../Components/GroupButton/GroupButton";
import { useState } from "react";
import ETButton from "../../../../Components/Buttons/ETButton";
import DropZone from "../../../../Components/DropZone/DropZone";
import { Controller, useForm } from "react-hook-form";
import InputField from "../../../../Components/Textfield/InputField";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";
import SelectTag from "./SelectTag";
import CustomReactSelect from "../../../../Components/DropDown/CustomReactSelect";
import {
  useAddNewGuide,
  useGetContractStatus,
  useGetGuideByGuideId,
  useUpdateGuide,
} from "../../../../hooks/queries/UseGuideQueries";
import { useDivisionId } from "../../../../hooks/stores/useDivisionsStore";
import { toast } from "react-toastify";
import moment from "moment";
import CustomPopup from "../../../../Components/CustomPopup";

const AddNewGuide = () => {
  // ------------------------------ all states ---------------------------------------------- //
  const [automaticPublishGuideTab, setAutomaticPublishGuideTab] = useState(0);
  const [scheduledDisplaySwitch, setScheduledDisplaySwitch] = useState(true);
  const [publishedContractStatusSelected, setPublishedContractStatusSelected] =
    useState([]);
  const [showCancelPopup, setShowCancelPopup] = useState(false);

  //  ------------------ ----------- state used in switches --------------------------------//
  const [toggleWebsite, setToggleWebsite] = useState(false);
  const [toggleEiDo, setToggleEiDo] = useState(false);
  const [toggleMyPage, setToggleMyPage] = useState(false);

  // -------------------------- state created for upload in file and image------------------//
  const [myFileForUploadGuide, setMyFileForUploadGuide] = useState("");
  const [fileNameUploaded, setFileNameUploaded] = useState("");
  const [myFileForUploadThumbnail, setMyFileForUploadThumbnail] = useState("");
  const [imageNameUploaded, setImageNameUploaded] = useState("");
  // --------------state used for select tags-------------------------------------------------//
  const [tagsSelectedData, setTagsSelectedData] = useState([]);
  const [selectedPropertyId, setSelectedPropertyId] = useState([]);

  // --------------created by and updated by state-----------------------------------------//
  const [createdByName, setCreatedByName] = useState("");
  const [createdByDate, setCreatedByDate] = useState("");
  const [updatedByName, setUpdatedByName] = useState("");
  const [updatedByDate, setUpdatedByDate] = useState("");

  // ---------------------------------- navigation -----------------------------------------//
  const navigate = useNavigate();
  let location = useLocation();
  const { state } = location;

  // -----------------------------------zustand -------------------------------------------//
  const { divisionId } = useDivisionId((state) => state);
  //--------------------------------------------- React query -----------------------------//

  const { data: GetContractStatusData } = useGetContractStatus();
  const { data: GetGuideByGuideIdData } = useGetGuideByGuideId(state?.guideId);
  const { mutateAsync: PostAddNewGuide, isLoading: PostAddNewGuideIsLoading } =
    useAddNewGuide();
  const { mutateAsync: PostUpdateGuide, isLoading: PostUpdateGuideIsLoading } =
    useUpdateGuide();

  // --------------------------------react hooks form -------------------------------//
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    clearErrors,
  } = useForm({});

  // ------------------------------- const used -----------------------------------------//
  const navigateToBackPage = () => {
    navigate(-1); // used to go back in guide card page
  };

  // ------------------------------ after submit const ----------------------------------//

  // Function to extract property_id as value and property_name as a label
  const extractPropertyIdName = (data) => {
    const uniqueCombinations = new Set();
    return (
      data?.reduce((result, item) => {
        const combinationKey = `${item?.property_id}_${item?.property_name}`;

        if (!uniqueCombinations.has(combinationKey)) {
          uniqueCombinations.add(combinationKey);
          result.push({
            value: item?.property_id,
            label: item?.property_name,
          });
        }

        return result;
      }, []) || []
    );
  };

  // -------------extract Contract status For Value Label --------------------
  const extractContractForValueLabel = (data) => {
    return data?.map((item) => ({
      value: parseInt(item?.Id),
      label: item?.Text,
    }));
  };

  // -------------------------------- handle submit data -----------------------------------//
  console.log(tagsSelectedData, "tagsSelectedData");
  const handleSubmitAddNewGuide = async (data) => {
    // navigate(-1); // used to go back in guide card page
    // console.log(data, "handleSubmitAddNewGuide");

    if (state?.guideId != "") {
      //--------------------------- used for edit guide -----------------------------------//
      let formData = new FormData();
      const tags = tagsSelectedData?.map((item) => {
        return item?.tag_id;
      });
      formData.append("division_id", divisionId);
      formData.append("document_guide_id", state?.guideId);
      formData.append(
        "published_condition_type",
        scheduledDisplaySwitch === false ? 0 : automaticPublishGuideTab + 1
      );
      formData.append("description", data?.description);
      formData.append(
        "is_automatic_published",
        scheduledDisplaySwitch === true ? 1 : 0
      );
      formData.append("title", data?.title);
      formData.append("tags", JSON.stringify(tags));
      formData.append("is_website", toggleWebsite === true ? 1 : 0);
      formData.append("is_eido", toggleEiDo === true ? 1 : 0);
      formData.append("is_mypage", toggleMyPage === true ? 1 : 0);
      formData.append(
        "published_start_datetime",
        data?.startDateOfShowTheGuide == undefined
          ? ""
          : data?.startDateOfShowTheGuide
      );
      const selectedContract = formData.append(
        "published_contract_status_id",
        publishedContractStatusSelected?.map((item) => {
          return item?.value;
        })
      );

      formData.append(
        "guide_file",
        myFileForUploadGuide?.length
          ? myFileForUploadGuide?.[0]
          : myFileForUploadGuide
      );
      formData.append(
        "guide_image",
        myFileForUploadThumbnail?.length
          ? myFileForUploadThumbnail?.[0]
          : myFileForUploadThumbnail
      );
      formData.append(
        "published_end_datetime",
        data?.endDateOfShowTheGuide == undefined
          ? ""
          : data?.endDateOfShowTheGuide
      );
      formData.append("guide_filename", fileNameUploaded);
      formData.append("image_filename", imageNameUploaded);

      try {
        const response = await PostUpdateGuide(formData);
        if (response?.data?.bReturn) {
          toast.success(response?.data?.message, {
            autoClose: 3000,
          });
          navigate("/division/communication/guides");
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        toast.error(error?.message);
      }
      // console.log(formData, "formData");
    } else {
      // -------------------- below code used for create new guide ------------------------------//
      let formData = new FormData();

      const tags = tagsSelectedData?.map((item) => {
        return item?.tag_id;
      });
      formData.append("tags", JSON.stringify(tags));
      formData.append("division_id", divisionId);
      formData.append(
        "published_condition_type",
        scheduledDisplaySwitch === false ? 0 : automaticPublishGuideTab + 1
      );
      formData.append("description", data?.description);
      formData.append(
        "is_automatic_published",
        scheduledDisplaySwitch === true ? 1 : 0
      );
      formData.append("title", data?.title);

      formData.append("is_website", toggleWebsite === true ? 1 : 0);
      formData.append("is_eido", toggleEiDo === true ? 1 : 0);
      formData.append("is_mypage", toggleMyPage === true ? 1 : 0);
      formData.append(
        "published_start_datetime",
        data?.startDateOfShowTheGuide == undefined
          ? ""
          : data?.startDateOfShowTheGuide
      );
      formData.append(
        "published_contract_status_id",
        publishedContractStatusSelected?.map((item) => {
          return item?.value;
        })
      );
      formData.append("guide_file", myFileForUploadGuide?.[0]);
      formData.append("guide_image", myFileForUploadThumbnail?.[0]);
      formData.append(
        "published_end_datetime",
        data?.endDateOfShowTheGuide == undefined
          ? ""
          : data?.endDateOfShowTheGuide
      );
      // console.log(formData, "formData");
      try {
        const response = await PostAddNewGuide(formData);
        if (response?.data?.bReturn) {
          toast.success(response?.data?.message, {
            autoClose: 3000,
          });
          navigate("/division/communication/guides");
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  // console.log(tags, "tagstags");
  // ----------------------------------- use effects ------------------------------ //
  useEffect(() => {
    //------------------- setting fields value at the time of edit -------------------//
    if (state?.guideId != "") {
      // console.log(GetGuideByGuideIdData?.data?.[0], "it is edit screen");
      const guideData = GetGuideByGuideIdData?.data?.[0];
      setValue("title", guideData?.title);
      setValue("description", guideData?.description);
      setValue(
        "endDateOfShowTheGuide",
        guideData?.published_end_datetime?.split(" ")?.[0]
      );
      setValue(
        "startDateOfShowTheGuide",
        guideData?.published_start_datetime?.split(" ")?.[0]
      );
      setScheduledDisplaySwitch(
        guideData?.is_automatic_published == "1" ? true : false
      );
      setAutomaticPublishGuideTab(
        guideData?.is_automatic_published == "0"
          ? 0
          : guideData?.published_condition_type - 1
      );
      // setToggleWebsite(guideData?.)
      const getPublishedContractStatus = GetContractStatusData?.data?.filter(
        (value) => value?.Id == guideData?.published_contract_status_id
      )?.[0]?.Text;

      // const selectedContractStatus=
      setValue("published_contract_status_id", {
        value: guideData?.published_contract_status_id,
        label: getPublishedContractStatus,
      });
      setPublishedContractStatusSelected(
        guideData?.published_contract_status_id
      );
      setCreatedByName(guideData?.created_by);
      setCreatedByDate(guideData?.created_on);
      setUpdatedByName(guideData?.modified_by);
      setUpdatedByDate(guideData?.modified_on);
      // console.log(getPublishedContractStatus, "tetetettetett");

      setMyFileForUploadGuide({
        ...myFileForUploadGuide,
        name: guideData?.guide_filename,
        path: guideData?.guide_url,
      });

      setMyFileForUploadThumbnail({
        ...myFileForUploadThumbnail,
        name: guideData?.image_filename,
        path: guideData?.image_url,
      });
      setFileNameUploaded(guideData?.guide_filename);
      setImageNameUploaded(guideData?.image_filename);
      // ----used for tags
      setTagsSelectedData(guideData?.tag_to_use);
      const extractedPropertyIdName = extractPropertyIdName(
        guideData?.tag_to_use
      );
      setSelectedPropertyId(extractedPropertyIdName);

      // --- used for contract status

      const contractStatusData = extractContractForValueLabel(
        guideData?.contract_status_used
      );
      setPublishedContractStatusSelected(contractStatusData);
    }
  }, [GetGuideByGuideIdData?.data]);
  // GetContractStatusData?.data,
  // below code used for setting last updated data
  const modifiedOn = moment(updatedByDate)?.format("DD.MMMM.YYYY | HH:mm:ss");
  const createdOn = moment(createdByDate)?.format("DD.MMMM.YYYY | HH:mm:ss");

  // console.log(fileNameUploaded, "fileNameUploaded")
  // console.log(imageNameUploaded, "imageNameUploaded");
  return (
    <div className="px-1">
      {" "}
      <div className="px-2  py-3">
        <Form onSubmit={handleSubmit(handleSubmitAddNewGuide)}>
          {/* top bar header and breadcrumbs */}
          <Placeholder animation="glow">
            <H1 className="fs-32 pt-3">Division</H1>
            <div className="light-font-text cursor-pointer">
              <Link
                to="/organization/divisions"
                style={{ textDecoration: "none", color: "black" }}
              >
                <span className="me-3">Division</span>
              </Link>
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />{" "}
              </span>
              <Link
                to="/division/details"
                style={{ textDecoration: "none", color: "black" }}
              >
                <span className="me-3">Unity Living</span>
              </Link>
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />{" "}
              </span>
              <Link
                to="/division/communication/guides"
                style={{ textDecoration: "none", color: "black" }}
              >
                <span className="me-3">Communication</span>
              </Link>
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />{" "}
              </span>
              <Link
                to="/division/communication/guides"
                style={{ textDecoration: "none", color: "black" }}
              >
                <span className="me-3">Guides</span>
              </Link>
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />{" "}
              </span>
              <GreenSpan>
                {state?.guideId != "" ? "Edit guide" : "Add new guide"}
              </GreenSpan>
            </div>
          </Placeholder>

          <div
            className="mt-4 p-4"
            style={{ backgroundColor: "#E4E7E8", borderRadius: "10px" }}
          >
            {/* white section for both forms */}
            <div className="bg-white radius-8">
              <div className="px-3 pb-3 border-bottom">
                <div className="px-2 pt-3 d-flex">
                  <div className="d-flex align-items-center  gap-2">
                    <H2>
                      {state?.guideId != "" ? "Edit guide" : "Add new guide"}{" "}
                    </H2>
                    <CustomToolTip
                      size="xs"
                      text={
                        state?.guideId != "" ? "Edit guide" : "Add new guide"
                      }
                    />
                  </div>
                </div>
                {state?.guideId != "" && (
                  <div className="px-2 pt-1">
                    {GetGuideByGuideIdData?.data?.modified_by &&
                    GetGuideByGuideIdData?.data?.modified_on ? (
                      <div className="fs-12 created-by">
                        Last updated by &nbsp;
                        {updatedByName},&nbsp;
                        {modifiedOn}
                      </div>
                    ) : (
                      <>
                        <div className="fs-12 created-by">
                          Created by &nbsp;
                          {createdByName},&nbsp;
                          {createdOn}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="p-4 border-bottom">
                <div className="d-flex form-label-title align-items-center gap-2">
                  <H4>External title</H4>
                  <CustomToolTip height={12} text="External title" />
                </div>
                <div className="light-font-text pb-1">
                  The title which will be shown to the customers.
                </div>

                <Row className="mt-2">
                  <Col xl={4} lg={6} className="pb-1">
                    <Controller
                      control={control}
                      {...register("title")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div>
                          <InputField
                            id={"Title"}
                            value={value}
                            type={"text"}
                            placeholder={"Title"}
                            errorscope={true}
                            iserror={errors?.title}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("title");
                            }}
                          />
                        </div>
                      )}
                    />
                  </Col>
                </Row>
              </div>
              <div className="p-4 border-bottom">
                <div className="d-flex form-label-title align-items-center gap-2">
                  <H4> External description </H4>
                  <CustomToolTip height={12} text="Internal title" />
                </div>
                <div className="light-font-text pb-1">
                  The description of the guide that will be shown to customers.
                </div>

                <Row className="mt-2">
                  <Col lg={7} md={9} className="pb-1">
                    <Controller
                      control={control}
                      {...register("description")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div>
                          <div className="form-float openhouse-des">
                            <textarea
                              name="internalNote"
                              className="inputText"
                              cols="20"
                              rows="3"
                              id="description"
                              placeholder=""
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("description");
                              }}
                            />
                            <label className="floating-label">
                              Description
                            </label>
                          </div>
                        </div>
                      )}
                    />
                    {errors?.description && (
                      <p className="error-msg">{REQUIRED_ERR}</p>
                    )}
                  </Col>
                </Row>
              </div>
              {/*---------------------- Automatic publish the guide ----------------------------------  */}
              <div className="p-4 border-bottom">
                <div className="d-flex form-label-title align-items-center gap-2">
                  <H4>Automatic show the guide</H4>
                  <CustomToolTip height={12} text="Automatic show the guide" />
                </div>
                <Row>
                  <Col md={8} className="pe-lg-0">
                    {" "}
                    <div className="light-font-text pt-2 pb-1">
                      <div>
                        It is possible to set a specific date and time when this
                        guide will be shown to your customers.{" "}
                      </div>
                      Select below if you wish to use this feature and, if so,
                      which automation feature you wish to use.
                    </div>
                  </Col>
                </Row>

                <div className="d-flex form-label-title align-items-center gap-2 mb-4">
                  Use scheduled display
                  <Toggle
                    id={"useScheduledDisplay"}
                    checked={scheduledDisplaySwitch}
                    onChange={() =>
                      setScheduledDisplaySwitch(!scheduledDisplaySwitch)
                    }
                  />
                </div>
                <div
                  className={
                    scheduledDisplaySwitch == false && "disabled-container"
                  }
                >
                  <div className="mb-4">
                    <Row>
                      <Col lg={8}>
                        <GroupButtons
                          tabs={[
                            {
                              id: "1",
                              tabTitle: "Specific date and time",
                            },
                            {
                              id: "2",
                              tabTitle: "According to contract status",
                            },
                          ]}
                          currentTab={automaticPublishGuideTab}
                          handleTabClick={(tab) => {
                            setAutomaticPublishGuideTab(tab);
                            setValue("startDateOfShowTheGuide", "");
                            setValue("endDateOfShowTheGuide", "");
                            setValue("contractStatusPublish", "");
                          }}
                        />
                      </Col>
                    </Row>
                  </div>

                  <div
                    style={{ display: automaticPublishGuideTab == 1 && "none" }}
                  >
                    <Row>
                      <Col lg={3} className="pe-lg-0">
                        <Controller
                          control={control}
                          {...register("startDateOfShowTheGuide")}
                          rules={{ required: false }}
                          render={({ field: { onChange, value } }) => (
                            <div>
                              <InputField
                                id={"startDateOfShowTheGuide"}
                                value={value}
                                type={"date"}
                                placeholder={"Start date"}
                                errorscope={false}
                                iserror={errors?.startDateOfShowTheGuide}
                                onChange={(e) => {
                                  onChange(e);
                                  clearErrors("startDateOfShowTheGuide");
                                }}
                              />
                            </div>
                          )}
                        />
                      </Col>
                      <Col lg={3} className="pe-lg-0">
                        <Controller
                          control={control}
                          {...register("endDateOfShowTheGuide")}
                          rules={{ required: false }}
                          render={({ field: { onChange, value } }) => (
                            <div>
                              <InputField
                                id={"endDateOfShowTheGuide"}
                                value={value}
                                type={"date"}
                                placeholder={"End date"}
                                errorscope={false}
                                iserror={errors?.endDateOfShowTheGuide}
                                onChange={(e) => {
                                  onChange(e);
                                  clearErrors("endDateOfShowTheGuide");
                                }}
                              />
                            </div>
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div
                    style={{ display: automaticPublishGuideTab == 0 && "none" }}
                  >
                    <Row>
                      <Col lg={6} className="pe-lg-0">
                        <CustomReactSelect
                          placeholder={"Contract status"}
                          isSearchable={false}
                          isMulti={true}
                          options={GetContractStatusData?.data?.map((item) => ({
                            value: item?.Id,
                            label: item?.Text,
                          }))}
                          value={publishedContractStatusSelected}
                          onChange={(e) => {
                            setPublishedContractStatusSelected(e);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              {/* ------------------------------- Select tags -----------------------------------  */}
              <div className="p-4 border-bottom">
                <SelectTag
                  setTagRowData={setTagsSelectedData}
                  tagRowData={tagsSelectedData}
                  selectedPropertyId={selectedPropertyId}
                  setSelectedPropertyId={setSelectedPropertyId}
                />
              </div>
              {/* ----------------------------- Upload guide ------------------------------------- */}
              <div className="p-4 border-bottom">
                <div className="d-flex align-items-center gap-2 mb-3 ">
                  <H4>Upload guide</H4>
                  <CustomToolTip text="Logo - Main menu" />
                </div>

                <div className="w-50">
                  <DropZone
                    setMyFile={setMyFileForUploadGuide}
                    usedFor={"pdf"}
                    initialFileName={`${fileNameUploaded}`}
                  />
                </div>
              </div>

              {/* ----------------------------- upload thumbnail --------------------------------- */}
              <div className="p-4 border-bottom">
                <div className="d-flex align-items-center gap-2 ">
                  <H4>Upload thumbnail</H4>
                  <CustomToolTip text="Upload thumbnail" />
                </div>
                <Row>
                  <Col lg={8} className="light-font-text mb-3 ">
                    A thumbnail is used to show what this guide is about and is
                    displayed e.g. in the system, in MyPage and other places
                    where the guide is displayed.
                  </Col>
                </Row>

                <div className="w-50">
                  <DropZone
                    setMyFile={setMyFileForUploadThumbnail}
                    initialFileName={imageNameUploaded}
                  />
                </div>
              </div>

              {/* ----------------- Select where to publish the guide ----------------------------- */}
              <div className="p-4">
                <div className="d-flex form-label-title align-items-center gap-2 mb-4">
                  <H4> Select where to publish the guide</H4>
                  <CustomToolTip height={12} text="Internal title" />
                </div>
                <div className="d-flex flex-cloumn gap-4">
                  <div className="d-flex gap-2 align-items-center form-label-title">
                    <Toggle
                      id={"Website"}
                      checked={toggleWebsite}
                      onChange={() => setToggleWebsite(!toggleWebsite)}
                    />
                    Website
                  </div>
                  <div className="d-flex gap-2 align-items-center form-label-title">
                    <Toggle
                      id={"Ei_Do"}
                      checked={toggleEiDo}
                      onChange={() => setToggleEiDo(!toggleEiDo)}
                    />
                    Ei.Do
                  </div>
                  <div className="d-flex gap-2 align-items-center form-label-title">
                    <Toggle
                      id={"MyPage"}
                      checked={toggleMyPage}
                      onChange={() => setToggleMyPage(!toggleMyPage)}
                    />
                    MyPage
                  </div>
                </div>
              </div>
              <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container ">
                <ETButton
                  variant={"white"}
                  buttonText="CANCEL"
                  type={"button"}
                  onClick={() => setShowCancelPopup(true)}
                />
                <ETButton
                  variant={"primary"}
                  buttonText={state?.guideId != "" ? "SAVE" : "CREATE"}
                  type={"submit"}
                  disabled={
                    PostAddNewGuideIsLoading ||
                    PostUpdateGuideIsLoading ||
                    myFileForUploadGuide == [] ||
                    myFileForUploadThumbnail == [] ||
                    tagsSelectedData?.length == 0
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
      {/* Archive guide */}
      <CustomPopup
        open={showCancelPopup}
        onHide={() => setShowCancelPopup(false)}
        title="Cancel guide"
        // title_color="black"
        enableCrossButton={false}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="p-3">
          <div className="px-2">
            <div className="light-font-text my-1" style={{ height: "50px" }}>
              <p>Are you sure you want to cancel the guide?</p>
              {/* <p>
                Be aware that both the package and all included documents linked
                to the unit will be deleted.
              </p> */}
            </div>
          </div>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => setShowCancelPopup(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="Confirm"
            onClick={navigateToBackPage}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default AddNewGuide;

// created by Abu
// modified and updated following things by Suraj
// added commenting and validation with functionality on input field and group button tab and switch
// changed navigation because navigation routes is not correct
// Changed the text as per new figma design
