import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

export const ProcessTimeline = ({
  setReservationPopup,
  setScreeningprocessPopup,
  setRentalContractPopup,
  setDepositPopup,
  setLeasedPopup,
  setMoveInPopup,
  setMoveOutPopup,
  setTerminationPopup,
}) => {
  // dummy data for process timeline
  const processLineData = [
    {
      heading: "Reservation",
      info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      btn: "VIEW DETAILS",
    },
    {
      heading: "Screening process",
      info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      btn: "VIEW DETAILS",
    },
    {
      heading: "Rental contract",
      info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      btn: "VIEW DETAILS",
    },
    {
      heading: "Deposit",
      info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      btn: "CONFIRM",
    },
    {
      heading: "Leased",
      info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      btn: "CONFIRM",
    },
    {
      heading: "Moving in",
      info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      btn: "CONFIRM",
    },
    {
      heading: "Termination",
      info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      btn: "CONFIRM",
    },
    {
      heading: "Moving out",
      info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      btn: "CONFIRM",
    },
  ];

  return (
    <div className="ps-2 py-3">
      <h6 className="actors-section-title">Process</h6>

      <div className="process-container pe-3 py-2">
        <div className="process-timeline">
          {processLineData?.map((data, i) => {
            return (
              <div
                className={`process-item ${
                  processLineData.length - 1 !== i
                    ? data.btn !== "CONFIRM"
                      ? "process-completed-line"
                      : "process-not-completed-line"
                    : ""
                } d-flex`}
              >
                <div className="process-icon">
                  {i !== 3 ? (
                    data.btn !== "CONFIRM" ? (
                      <FontAwesomeIcon
                        icon="fa-regular fa-circle-check"
                        style={{ color: "#77C7A1" }}
                      />
                    ) : (
                      <FontAwesomeIcon icon="fa-regular fa-circle" />
                    )
                  ) : (
                    <span className="hour-glass-icon">
                      <FontAwesomeIcon icon="fa-regular fa-hourglass-half" />
                    </span>
                  )}
                </div>
                <div className="process-content w-100">
                  <div className="process-card d-flex justify-content-between bg-white align-items-center flex-wrap gap-3 gap-lg-0 px-3 py-2 border rounded">
                    <div className="process-heading" style={{ width: "85%" }}>
                      <p className="mb-0 form-label-title">{data.heading}</p>
                      <span className="fs-14 grey-text">{data.info}</span>
                    </div>
                    <div className="process-details">
                      <Button
                        // onClick={()=> data?.heading == "Reservation" ? setReservationPopup(true) : data?.heading == "" }
                        onClick={() =>
                          i == 0
                            ? setReservationPopup(true)
                            : i == 1
                            ? setScreeningprocessPopup(true)
                            : i == 2
                            ? setRentalContractPopup(true)
                            : i == 3
                            ? setDepositPopup(true)
                            : i == 4
                            ? setLeasedPopup(true)
                            : i == 5
                            ? setMoveInPopup(true)
                            : i == 6
                            ? setTerminationPopup(true)
                            : setMoveOutPopup(true)
                        }
                        className="border form-label-title fs-14 px-1 py-1  rounded shadow-none"
                        style={{
                          width: "110px",
                          backgroundColor:
                            data.btn !== "CONFIRM" ? "#fff" : "#bbe3d0",
                          color: data.btn !== "CONFIRM" ? "#000" : "#fff",
                        }}
                      >
                        {data.btn}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
