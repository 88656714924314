import { Controller, useForm } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import Toggle from "../../../Components/Switch/Switch";
import ETButton from "../../../Components/Buttons/ETButton";
import { REQUIRED_ERR } from "../../../utils/validationConstants";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { usePropertyId } from "../../../hooks/stores/usePropertyStore";
import {
  useGetPropertySettingDetails,
  useUpdatePropertySettingsDetails,
} from "../../../hooks/queries/usePropertyQueries";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import PromoToolTip from "../../../Components/Tooltip/PromoToolTip";
import { H2 } from "../../../styles/Common.style";
import InputField from "../../../Components/Textfield/InputField";

const Configuration = () => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
  } = useForm({
    defaultValues: {
      tokenKey: "",
      userId: "",
      senderEmail: "",
      reservationDuration: "",
    },
  });

  //--------------------------zustand---------------------//
  const propertyId = usePropertyId((state) => state.propertyId);

  //------------------------states--------------------------//
  const [isRkiCheck, setIsRkiCheck] = useState(false);
  const [isSetLeasedManually, setIsSetLeasedManually] = useState(false);
  const [isPromotionLabelManual, setIsPromotionLabelManual] = useState(false);

  //------------------------react query---------------------//
  const { data: getPropertySettingDetailsData } =
    useGetPropertySettingDetails(propertyId);

  const { mutateAsync: PutUpdatePropertySettingsDetails, isLoading } =
    useUpdatePropertySettingsDetails();
  useEffect(() => {
    setValue(
      "tokenKey",
      getPropertySettingDetailsData?.data?.[0]?.esignatur_token_key
    );
    setValue(
      "userId",
      getPropertySettingDetailsData?.data?.[0]?.esignatur_user_id
    );
    setValue(
      "senderEmail",
      getPropertySettingDetailsData?.data?.[0]?.esignatur_sender_email
    );
    setValue(
      "reservationDuration",
      getPropertySettingDetailsData?.data?.[0]?.reservation_duration_default
    );
    setIsRkiCheck(
      getPropertySettingDetailsData?.data?.[0]?.is_rki_check === "1"
        ? true
        : false
    );
    setIsPromotionLabelManual(
      getPropertySettingDetailsData?.data?.[0]?.is_promotion_label_manual ===
        "1"
        ? true
        : false
    );
    setIsSetLeasedManually(
      getPropertySettingDetailsData?.data?.[0]?.set_leased_manually === "1"
        ? true
        : false
    );
    return () => { };
  }, [getPropertySettingDetailsData]);

  const handleSavePropertySettings = async (data) => {
    const savePropertySettingsPayload = {
      property_id: String(propertyId),
      esignatur_token_key: data?.tokenKey,
      esignatur_user_id: data?.userId,
      esignatur_sender_email: data?.senderEmail,
      is_rki_check: isRkiCheck ? "1" : "0",
      reservation_duration_default: data?.reservationDuration,
      set_leased_manually: isSetLeasedManually ? "1" : "0",
      is_promotion_label_manual: isPromotionLabelManual ? "1" : "0",
    };
    try {
      const response = await PutUpdatePropertySettingsDetails(
        savePropertySettingsPayload
      );
      if (response?.data?.bReturn) {
        toast.success(response?.data?.message, {
          toastId: savePropertySettingsPayload.is_promotion_label_manual,
        });
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(handleSavePropertySettings)}>
        <div
          className="signing-conatiner radius-8"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div
            className=" px-4 py-3"
            style={{ borderBottom: "1px solid #F0F0F0" }}
          >
            <div className=" d-flex align-items-center ">
              <H2 className="me-2">Configuration</H2>{" "}
              <CustomToolTip size="sm" text="Configuration" />
            </div>
            {/* <div>
              {modifiedData?.map((data, i) => (
                <>
                  {data?.modified_by && data?.modified_on ? (
                    <div key={i} className="created-by">
                      Last updated by {data?.modified_by} ,{" "}
                      {moment(data?.modified_on).format(
                        "DD.MMM.YYYY | HH:mm:ss"
                      )}
                    </div>
                  ) : (
                    <>
                      {data?.created_by && data?.created_on ? (
                        <div className="created-by">
                          Last updated by {data?.created_by} ,{" "}
                          {moment(data?.createdOn).format(
                            "DD.MMM.YYYY | HH:mm:ss"
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              ))}
            </div> */}
          </div>
          <div className="signing-body">
            <div className="p-4">
              <div className="form-label-title fs-14 mb-3"> Esignatur</div>
              <Row>
                <Col xl={4} className="mb-3">
                  <Controller
                    control={control}
                    {...register("tokenKey")}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <div >
                        {/* <input
                          type="text"
                          placeholder="Esignaturtokenkey"
                          id="tokenKey"
                          value={field.value}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            clearErrors("tokenKey");
                          }}
                        /> */}
                        <InputField id={"title"} value={field.value} type={"text"} maxLength placeholder={"Esignatur token key"} errorscope iserror={errors?.tokenKey} onChange={(e) => {

                          field.onChange(e);
                          clearErrors("tokenKey");
                        }} />
                        {/* <label for="tokenKey">Token key</label> */}
                      </div>
                    )}
                  />
                  {/* {errors?.tokenKey && (
                    <p className="error-msg">{REQUIRED_ERR}</p>
                  )} */}
                </Col>

                <Col className="mb-3" xl={4}>
                  <Controller
                    control={control}
                    {...register("userId")}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <div >
                        {/* <input
                          type="text"
                          placeholder="Esignaturuserid"
                          id="userId"
                          {...field}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e);
                            clearErrors("userId");
                          }}
                        /> */}
                        <InputField id={"userId"} value={field.value} type={"text"} maxLength placeholder={"Esignatur user id"} errorscope iserror={errors?.userId} onChange={(e) => {

                          field.onChange(e);
                          clearErrors("userId");
                        }} />
                        {/* <label for="userId">User Id</label> */}
                      </div>
                    )}
                  />
                  {/* {errors?.userId && (
                    <p className="error-msg">{REQUIRED_ERR}</p>
                  )} */}
                </Col>
                <Col xl={4} className="mb-3">
                  <Controller
                    control={control}
                    {...register("senderEmail")}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <div >
                        {/* <input
                          type="text"
                          placeholder="esignatursenderemail"
                          id="senderEmail"
                          value={field.value}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            clearErrors("senderEmail");
                          }}
                        /> */}
                        <InputField id={"senderEmail"} value={field.value} type={"text"} maxLength placeholder={"esignatursenderemail"} errorscope iserror={errors?.senderEmail} onChange={(e) => {
                          field.onChange(e);
                          clearErrors("senderEmail");
                        }} />
                        {/* <label for="senderEmail">Email</label> */}
                      </div>
                    )}
                  />
                  {/* {errors?.senderEmail && (
                    <p className="error-msg">{REQUIRED_ERR}</p>
                  )} */}
                </Col>
              </Row>
            </div>
            <hr className="m-0" />

            <div className="p-4">
              <div className="form-label-title d-flex gap-2 fs-14 mb-3">
                {" "}
                Reservation{" "}
                <PromoToolTip
                  text={
                    <div style={{ width: "207px" }} className="p-2">
                      <div className="actors-label-title">Reservation</div>
                      <div className="actors-lite-text">
                        Please set the duration for reservation expiry.
                      </div>
                    </div>
                  }
                />{" "}
              </div>

              <Row>
                <Col xl={4} className="mb-3">
                  <Controller
                    control={control}
                    {...register("reservationDuration")}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <div className="form-input">
                        <input
                          type="number"
                          placeholder="reservationduration"
                          id="reservationDuration"
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e);
                            clearErrors("senderEmail");
                          }}
                        />
                        <label for="reservationDuration">Expiry duration</label>
                      </div>
                    )}
                  />
                  {errors?.reservationDuration && (
                    <p className="error-msg">{REQUIRED_ERR}</p>
                  )}
                </Col>
              </Row>
            </div>
            <hr className="m-0" />

            <div className="p-4">
              <div className="form-label-title d-flex gap-2  fs-14 mb-3">
                {" "}
                RKI
                <PromoToolTip
                  text={
                    <div style={{ width: "207px" }} className="p-2">
                      <div className="actors-label-title">RKI</div>
                      <div className="actors-lite-text">To be defined</div>
                    </div>
                  }
                />{" "}
              </div>

              <Row>
                <Col xl={4} className="">
                  <Row className="  pb-1 d-flex ">
                    <Col
                      className="pe-lg-0 light-font-text d-flex align-items-center"
                      lg={8}
                    >
                      Check
                    </Col>
                    <Col lg={4}>
                      <Toggle
                        id={"rkiCheck"}
                        checked={isRkiCheck}
                        onChange={(e) => setIsRkiCheck(e.target.checked)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <hr className="m-0" />
            <div className="p-4">
              <div className="form-label-title d-flex gap-2 fs-14 mb-3">
                {" "}
                Contract{" "}
                <PromoToolTip
                  text={
                    <div style={{ width: "207px" }} className="p-2">
                      <div className="actors-label-title">Contract</div>
                      <div className="actors-lite-text">
                        When switch is turned on you need to set unit status
                        manual to leased from units list/table.
                      </div>
                    </div>
                  }
                />{" "}
              </div>

              <Row>
                <Col xl={4} className="">
                  <Row className="  pb-1 d-flex ">
                    <Col
                      className="pe-lg-0 light-font-text d-flex align-items-center"
                      lg={8}
                    >
                      Set leased manually
                    </Col>
                    <Col lg={4}>
                      <Toggle
                        id={"setLeasedManually"}
                        checked={isSetLeasedManually}
                        onChange={(e) =>
                          setIsSetLeasedManually(e.target.checked)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <hr className="m-0" />
            <div className="p-4">
              <div className="form-label-title d-flex gap-2 fs-14 mb-3">
                {" "}
                Marketing{" "}
                <PromoToolTip
                  text={
                    <div style={{ width: "207px" }} className="p-2">
                      <div className="actors-label-title">Marketing</div>
                      <div className="actors-lite-text">To be defined</div>
                    </div>
                  }
                />{" "}
              </div>

              <Row>
                <Col xl={4} className="">
                  <Row className="  pb-1 d-flex ">
                    <Col
                      className="pe-lg-0 light-font-text  d-flex align-items-center"
                      lg={8}
                    >
                      Promotion label manual
                    </Col>
                    <Col lg={4}>
                      <Toggle
                        id={"isPromotionLabelManual"}
                        checked={isPromotionLabelManual}
                        onChange={(e) =>
                          setIsPromotionLabelManual(e.target.checked)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          <div
            className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-end  justify-content-end "
            style={{
              backgroundColor: "#F9FAFA",
              borderRadius: " 0px 0px 8px 8px",
            }}
          >
            <ETButton
              variant={"primary"}
              buttonText="SAVE"
              type="submit"
              disabled={isLoading}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default Configuration;
