import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
const CircularLoader = ({ value, height, strokeWidth }) => {
  const defaultpercentage = 66; // default percentage when no data is available.

  return (
    <>
      {" "}
      <CircularProgressbar
        value={value ? value : defaultpercentage}
        backgroundPadding={6}
        styles={{
          root: {
            height: height ? height : "17px",
          },
          background: {
            fill: "#ffffff",
          },
          text: {
            fill: "#000000",
          },
          path: {
            stroke: "#77C970",
          },
          trail: { stroke: "#d1d1d1" },
        }}
        strokeWidth={strokeWidth ? strokeWidth : 22}
      />
    </>
  );
};

export default CircularLoader;
