import React, {
  useMemo,
  useCallback,
  useState,
  useRef,
  useEffect,
} from "react";
import PNGWidget from "../../../Components/DownloadWidget/PNGWidget";
import JPGWidget from "../../../Components/DownloadWidget/JPGWidget";
import SVGWidget from "../../../Components/DownloadWidget/SVGWidget";
import { Button, Col, Row } from "react-bootstrap";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import Menus from "../../../Components/Menus/Menus";
import { MaterialReactTable } from "material-react-table";
import "../../../styles/css/control-center/customers.css";
import CustomPopup from "../../../Components/CustomPopup";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import MRTTheme from "../../../Components/table/MRTTheme";
import {
  SortIcon,
  TableRowsStyle,
} from "../../../Components/table/MRTGlobalProps";
import {
  useGetBreakDownByRegion,
  useGetBreakDownByZipcode,
} from "../../../hooks/queries/useControlCenterQueries";
import { useControlCenter } from "../../../hooks/stores/useControlCenterStore";
import { H2, H3 } from "../../../../src/styles/Common.style";
import { original } from "immer";

const breakDownOfCustomersData = [
  {
    Status: "Region Nordjylland",
    Quantity: "0",
    Percentage: "0%",
  },
  {
    Status: "Region Midtjylland",
    Quantity: "141",
    Percentage: "1%",
  },
  {
    Status: "Region Syddanmark",
    Quantity: "1340",
    Percentage: "84%",
  },
  {
    Status: "Region Hovedstaden ",
    Quantity: "1230",
    Percentage: "54%",
  },
  {
    Status: " Region Sjælland",
    Quantity: "1130",
    Percentage: "4%",
  },
];
const BreakdowndofCustomers = () => {
  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "breakdown-customer";

  const PNGFunction = PNGWidget({ refObj, fileName });
  const JPGFunction = JPGWidget({ refObj, fileName });
  const SVGFunction = SVGWidget({ refObj, fileName });
  const [showSeeDetails, setShowSeeDetails] = useState(false);
  const {
    selectedProperties,
    selectedDate,
    selectedEndDate,
    geographyFilterData,
  } = useControlCenter((state) => state);
  const { data: breakDownByRegionData } = useGetBreakDownByRegion(
    selectedProperties,
    selectedDate,
    selectedEndDate
  );
  const breakDownOfCustomersColumns = useMemo(
    () => [
      {
        accessorKey: "region_name",
        header: "Status",
        size: 180,
      },
      {
        accessorKey: "quantity",
        header: "Quantity",
        size: 130,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "percentage",
        header: "Percentage",
        size: 130,
        Cell: ({ row }) => (
          <>
            <div>{row.original.percentage} %</div>
          </>
        ),
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    []
  );

  let Total = 0;
  //   myData.forEach(function (elem) {
  //     Total += Number(elem.Quantity);
  //   });
  const [selectedRowRegionId, setSelectedRowRegionId] = useState("");
  const [regionName, setRegionName] = useState("");
  const [quantity, setQuantity] = useState("");
  const { data: breakDownByZipcodeData } = useGetBreakDownByZipcode(
    selectedRowRegionId,
    selectedDate,
    selectedEndDate
  );

  const total_registrations = breakDownByRegionData?.data
    ?.map((item) => item.quantity)
    .reduce((acc, currentval) => acc + currentval, 0);
  const data = {
    labels:
      breakDownByRegionData?.data?.map((entry) => entry?.region_name) || [],
    // labelsPer: myData?.map((x) => x.Percentage),
    datasets: [
      {
        data:
          breakDownByRegionData?.data?.map((entry) => entry.percentage) || [], // ,
        backgroundColor: [
          "#A2C8D0",
          "#77C7A1",
          "#F6E651",
          "#A2C8D0",
          "#F1664B",
        ],
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        formatter: function (value, index, values) {
          if (value > 0) {
            value = value.toString();
            value = value.split(/(?=(?:...)*$)/);
            value = value.join(",");
            return value + "%";
          } else {
            value = "";
            return value;
          }
        },
        display: true,
        color: "white",
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItem, data) {
            let title = tooltipItem[0].raw;
            // console.log(tooltipItem ,"title");
            // console.log(tooltipItem[0].raw
            //   ,"title");
            return title + "%";
          },
          label: function (tooltipItem) {
            let label = tooltipItem.label;
            // console.log(label ,"lable");
            return label;
          },
        },
        backgroundColor: "#FFFFFF",
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
        padding: 10,
        cornerRadius: 8,
        borderColor: "#F4F5F5",
        borderWidth: 1,
      },
      legend: {
        display: false,
      },
      plugins: [ChartDataLabels],
    },
  };

  const detailsColumns = useMemo(
    () => [
      {
        accessorKey: "zipcode_name",
        header: "Status",
        size: 180,
      },
      {
        accessorKey: "quantity",
        header: "Quantity",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "percentage",
        header: "Percentage",
        size: 50,
        Cell: ({ row }) => (
          <>
            <div>{row.original.percentage} %</div>
          </>
        ),
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    []
  );

  const detailsData = [
    {
      Status: "Gistrup",
      Quantity: "0",
      Percentage: "0%",
    },
    {
      Status: "Aars",
      Quantity: "0",

      Percentage: "0%",
    },
    {
      Status: "Vodskov",
      Quantity: "0",

      Percentage: "0%",
    },
    {
      Status: "Løgstør",
      Quantity: "0",
      Percentage: "0%",
    },
    {
      Status: "Gandrup",
      Quantity: "0",
      Percentage: "0%",
    },
    {
      Status: "Gandrup",
      Quantity: "0",
      Percentage: "0%",
    },
    {
      Status: "Gandrup",
      Quantity: "0",
      Percentage: "0%",
    },
    {
      Status: "Gandrup",
      Quantity: "0",
      Percentage: "0%",
    },
    {
      Status: "Gandrup",
      Quantity: "0",
      Percentage: "0%",
    },
    {
      Status: "Gandrup",
      Quantity: "0",
      Percentage: "0%",
    },
  ];

  return (
    <div>
      {" "}
      <div className="card-body p-3" ref={myref}>
        <Row>
          <Col className="d-flex align-items-center" sm={8}>
            {" "}
            <div className="d-flex align-items-center">
              <H3 className=" me-2">Breakdown of customers by region</H3>{" "}
              <CustomToolTip
                size="sm"
                text="Breakdown of customers by region"
              />
            </div>
          </Col>
          <Col sm={4} className="d-flex justify-content-end">
            {" "}
            <Menus
              itemheight={40}
              itemwidth={40}
              itemcontainerheight={200}
              itemcontainerwidth={200}
              component={
                <div className="fs-14">
                  <div className="addpropContent px-3 py-2 ">
                    <span className="ms-1" onClick={() => PNGFunction()}>
                      Download widget as PNG
                    </span>
                  </div>
                  <hr className="addpropertyHr m-0"></hr>
                  <div
                    onClick={() => JPGFunction()}
                    className="addpropContent px-3 pt-2 pb-2 "
                  >
                    <span className="ms-1">Download widget as JPG</span>
                  </div>
                  <hr className="addpropertyHr m-0"></hr>
                  <div
                    onClick={() => SVGFunction()}
                    className="addpropContent px-3 pt-2 pb-2 "
                  >
                    <span className="ms-1">Download widget as SVG</span>
                  </div>
                </div>
              }
            />
          </Col>
        </Row>
        {breakDownByRegionData?.data?.length !== 0 ? (
          <Row className="mt-3">
            <Col
              className="d-flex align-items-center justify-content-center"
              lg={4}
            >
              <div className="d-flex" style={{ flexDirection: "column" }}>
                <div style={{ height: "217px" }}>
                  <Pie
                    data={data}
                    plugins={[ChartDataLabels]}
                    options={options}
                  />
                </div>
                <div className="mt-3" style={{ textAlign: "center" }}>
                  <div className="light-font-text fs-12">
                    Total registrations
                  </div>
                  <H3 className=" fs-16 ">{total_registrations}</H3>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <MRTTheme
                colorVariantsForTableData={["odd"]}
                table={
                  <MaterialReactTable
                    columns={breakDownOfCustomersColumns}
                    data={breakDownByRegionData?.data || []}
                    icons={{ ...SortIcon }}
                    enableBottomToolbar={false}
                    enableSorting={false}
                    enableColumnActions={false}
                    enablePagination={false}
                    renderTopToolbar={false}
                    // muiTablePaperProps={{
                    //   elevation: 0,
                    //   sx: {
                    //     fontFamily: "Cerebri sans !important",
                    //     borderRadius: "0px 0px 8px 8px ",
                    //     border: "none",
                    //     background: "#F9FAFA",
                    //     fontWeight: "300",
                    //     ".css-1f2qhs8": {
                    //       minWidth: "auto",
                    //       marginRight: "10px",
                    //     },
                    //     th: {
                    //       height: "50px",
                    //       padding: "0.5rem",
                    //       background: "#FFFFFF",
                    //       paddingLeft: "1rem",
                    //       fontFamily: "Cerebri sans !important",
                    //     },
                    //     td: {
                    //       height: "64px",
                    //       padding: "0.5rem",
                    //       fontWeight: "300",
                    //       fontSize: "13px",
                    //       paddingLeft: "1rem",
                    //       fontFamily: "Cerebri sans !important",
                    //     },
                    //     tr: {
                    //       borderRadius: "4px",
                    //       border: "2px solid red !important",
                    //       fontFamily: "Cerebri sans !important",
                    //     },
                    //     ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
                    //       display: "none", // pagination space removing from page
                    //     },
                    //   },
                    // }}
                    // muiTableBodyProps={{
                    //   sx: {
                    //     fontFamily: "Cerebri sans !important",
                    //     "& tr:nth-of-type(odd)": {
                    //       backgroundColor: "#F9FAFA",
                    //       borderRadius: "8px",
                    //       fontWeight: "300",
                    //     },
                    //   },
                    // }}
                    muiTableContainerProps={{
                      elevation: 0,
                      sx: {
                        paddingX: "22px",
                        paddingY: "22px",
                      },
                    }}
                    // muiTableContainerProps={{
                    //   elevation: 0,
                    //   sx: {
                    //     background: "#FFFFFF",
                    //     paddingX: "22px",
                    //     paddingY: "22px",
                    //     borderRadius: "0px 0px 8px 8px ",

                    //     boxShadow: "none",
                    //     ".css-vv34gr-MuiTableRow-root": {
                    //       boxShadow: "unset !important",
                    //     },
                    //   },
                    // }}
                    // muiTableHeadCellProps={{
                    //   //simple styling with the `sx` prop, works just like a style prop in this example
                    //   sx: {
                    //     fontWeight: "700",
                    //     fontSize: "13px",
                    //     fontFamily: "Cerebri sans !important",
                    //   },
                    // }}
                    enableRowActions
                    displayColumnDefOptions={{
                      "mrt-row-actions": {
                        header: "",
                      },
                      "mrt-row-select": {
                        header: "",
                      },
                    }}
                    renderRowActions={({ row, table }) => (
                      <span className="breakdown-button">
                        <Button
                          className=" fs-12 px-2 py-2 fw-400  shadow-none"
                          style={{
                            border: "1px solid #F0F0F0",
                            width: "100px",
                            backgroundColor: "#fff",
                            color: "#000",
                            borderRadius: "8px",
                          }}
                          onClick={() => {
                            setShowSeeDetails(true);
                            setSelectedRowRegionId(row?.original?.region_id);
                            setRegionName(row?.original?.region_name);
                            setQuantity(row?.original?.quantity);
                          }}
                        >
                          SEE DETAILS
                        </Button>
                      </span>
                    )}
                    positionActionsColumn="last"
                  />
                }
              />
            </Col>
          </Row>
        ) : (
          <NoDataScreen height={"377px"} imgHeight={"150px"} />
        )}
      </div>
      <CustomPopup
        open={showSeeDetails}
        onHide={() => setShowSeeDetails(false)}
        title={
          <>
            {regionName} ({quantity})
          </>
        }
        title_color="black"
        titleFont="14px"
        titleTooltip={true}
        enableCrossButton={true}
        titleTooltipText="Region Nordjylland (77)"
        size="lg"
        onCloseAction={() => {
          setShowSeeDetails(false);
          alert("Action Peformed Successfuly.");
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        {/* <div style={{ height: "242px", overflow: "scroll" }} className="ps-4"> */}
        <MRTTheme
          colorVariantsForTableData={["odd"]}
          table={
            <MaterialReactTable
              enableStickyHeader
              columns={detailsColumns}
              data={breakDownByZipcodeData?.data || []}
              icons={{ ...SortIcon }}
              enableBottomToolbar={true}
              enableSorting={false}
              enableColumnActions={false}
              enablePagination={false}
              renderTopToolbar={false}
              muiTableContainerProps={{
                sx: {
                  paddingX: "24px",
                  paddingBottom: "24px",
                  paddingTop: "0px !important",
                },
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
              //  muiTablePaperProps={{
              //    elevation: 0,
              //    sx: {
              //      fontFamily: "Cerebri sans !important",
              //      borderRadius: "0px 0px 8px 8px ",
              //      border: "none",
              //      background: "#F9FAFA",
              //      fontWeight: "300",
              //      ".css-1f2qhs8": {
              //        minWidth: "auto",
              //        marginRight: "10px",
              //      },
              //      th: {
              //        height: "50px",
              //        padding: "0.5rem",
              //        background: "#FFFFFF",
              //        paddingLeft: "1rem",
              //        fontFamily: "Cerebri sans !important",
              //      },
              //      td: {
              //        height: "50px",
              //        padding: "0.5rem",
              //        fontWeight: "300",
              //        fontSize: "13px",
              //        paddingLeft: "1rem",
              //        fontFamily: "Cerebri sans !important",
              //      },
              //      tr: {
              //        borderRadius: "4px",
              //        border: "2px solid red !important",
              //        fontFamily: "Cerebri sans !important",
              //      },
              //      ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
              //        display: "none", // pagination space removing from page
              //      },
              //    },
              //  }}
              //  muiTableBodyProps={{
              //    sx: {
              //      fontFamily: "Cerebri sans !important",
              //      "& tr:nth-of-type(odd)": {
              //        backgroundColor: "#F9FAFA",
              //        borderRadius: "8px",
              //        fontWeight: "300",
              //      },
              //    },
              //  }}
              //  muiTableContainerProps={{
              //    elevation: 0,
              //    sx: {
              //      maxHeight: "500px",
              //      background: "#FFFFFF",
              //      paddingX: "24px",
              //      paddingBottom: "24px",
              //      borderRadius: "0px 0px 8px 8px ",

              //      boxShadow: "none",
              //      ".css-vv34gr-MuiTableRow-root": {
              //        boxShadow: "unset !important",
              //      },
              //    },
              //  }}
              //  muiTableHeadCellProps={{
              //    //simple styling with the `sx` prop, works just like a style prop in this example
              //    sx: {
              //      fontWeight: "700",
              //      fontSize: "13px",
              //      fontFamily: "Cerebri sans !important",
              //    },
              //  }}
            />
          }
        />

        {/* </div> */}
      </CustomPopup>
    </div>
  );
};

export default BreakdowndofCustomers;
