import React, { useMemo, useCallback, useState, useRef } from "react";
import PNGWidget from "../../../Components/DownloadWidget/PNGWidget";
import JPGWidget from "../../../Components/DownloadWidget/JPGWidget";
import SVGWidget from "../../../Components/DownloadWidget/SVGWidget";
import { Col, Row } from "react-bootstrap";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import Menus from "../../../Components/Menus/Menus";
import { MaterialReactTable } from "material-react-table";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import MRTTheme from "../../../Components/table/MRTTheme";
import { H2, H3 } from "../../../styles/Common.style";
import { SortIcon } from "../../../Components/table/MRTGlobalProps";

const HeardPropertyData = [
  {
    Status: "Social Media",
    Quantity: "187",
    Percentage: "52%",
  },
  {
    Status: "Outdoor marketing",
    Quantity: "0",
    Percentage: "0%",
  },
  {
    Status: "Website",
    Quantity: "87",
    Percentage: "22%",
  },
  {
    Status: "Newspaper",
    Quantity: "0",
    Percentage: "0%",
  },
  {
    Status: "Instagram",
    Quantity: "56",
    Percentage: "14%",
  },
  {
    Status: "Facebook",
    Quantity: "20",
    Percentage: "8%",
  },
  {
    Status: "Google Ads",
    Quantity: "0",
    Percentage: "0%",
  },
  {
    Status: "Newsletter",
    Quantity: "10",
    Percentage: "4%",
  },
];

const HeardAboutProperty = () => {
  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "Heard-property";

  const PNGFunction = PNGWidget({ refObj, fileName });
  const JPGFunction = JPGWidget({ refObj, fileName });
  const SVGFunction = SVGWidget({ refObj, fileName });

  const breakDownOfCustomersColumns = useMemo(
    () => [
      {
        accessorKey: "Status",
        header: "Status",
        size: 180,
      },
      {
        accessorKey: "Quantity",
        header: "Quantity",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "Percentage",
        header: "Percentage",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    []
  );

  let Total = 0;
  //   myData.forEach(function (elem) {
  //     Total += Number(elem.Quantity);
  //   });
  const data = {
    labels: ["Social Media", "Website", "Instagram", "Facebook", "Newsletter"],

    // labelsPer: myData?.map((x) => x.Percentage),
    datasets: [
      {
        data: [52, 22, 14, 8, 4],
        backgroundColor: [
          "#A2C8D0",
          "#77C7A1",
          "#F6E651",
          "#888888",
          "#F1664B",
        ],
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        formatter: function (value, index, values) {
          if (value > 0) {
            value = value.toString();
            value = value.split(/(?=(?:...)*$)/);
            value = value.join(",");
            return value + "%";
          } else {
            value = "";
            return value;
          }
        },
        display: true,
        color: "white",
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItem, data) {
            let title = tooltipItem[0].raw;
            // console.log(tooltipItem ,"title");
            // console.log(tooltipItem[0].raw
            //   ,"title");
            return title + "%";
          },
          label: function (tooltipItem) {
            let label = tooltipItem.label;
            // console.log(label ,"lable");
            return label;
          },
        },
        backgroundColor: "#FFFFFF",
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
        padding: 10,
        cornerRadius: 8,
        borderColor: "#F4F5F5",
        borderWidth: 1,
      },
      legend: {
        display: false,
      },
      plugins: [ChartDataLabels],
    },
  };

  return (
    <div className="card-body p-3" ref={myref}>
      {" "}
      <Row>
        <Col className="d-flex align-items-center" sm={8}>
          {" "}
          <div className="d-flex" style={{ alignItems: "center" }}>
            <H3 className=" me-2">Heard about the property</H3>{" "}
            <CustomToolTip
              size="sm"
              text="Heard about the property
              "
            />
          </div>
        </Col>
        <Col sm={4} className="d-flex justify-content-end">
          {" "}
          <Menus
            itemheight={40}
            itemwidth={40}
            itemcontainerheight={200}
            itemcontainerwidth={200}
            component={
              <div className="fs-14">
                <div className="addpropContent px-3 py-2 ">
                  <span className="ms-1" onClick={() => PNGFunction()}>
                    Download widget as PNG
                  </span>
                </div>
                <hr className="addpropertyHr m-0"></hr>
                <div
                  onClick={() => JPGFunction()}
                  className="addpropContent px-3 pt-2 pb-2 "
                >
                  <span className="ms-1">Download widget as JPG</span>
                </div>
                <hr className="addpropertyHr m-0"></hr>
                <div
                  onClick={() => SVGFunction()}
                  className="addpropContent px-3 pt-2 pb-2 "
                >
                  <span className="ms-1">Download widget as SVG</span>
                </div>
              </div>
            }
          />
        </Col>
      </Row>
      {HeardPropertyData.length == 0 ? (
        <Row className="mt-3">
          <Col
            className="d-flex align-items-center justify-content-center"
            lg={4}
          >
            <div className="d-flex" style={{ flexDirection: "column" }}>
              <div style={{ height: "217px" }}>
                <Pie
                  data={data}
                  plugins={[ChartDataLabels]}
                  options={options}
                />
              </div>
            </div>
          </Col>
          <Col lg={8}>
            <MRTTheme
              colorVariantsForTableData={["odd"]}
              table={
                <MaterialReactTable
                  columns={breakDownOfCustomersColumns}
                  data={HeardPropertyData}
                  icons={{ ...SortIcon }}
                  enableBottomToolbar={false}
                  enableSorting={false}
                  enableColumnActions={false}
                  enablePagination={false}
                  renderTopToolbar={false}
                  muiTableContainerProps={{
                    sx: {
                      paddingX: "22px",
                      paddingTop: "22px",
                    },
                  }}
                  // muiTablePaperProps={{
                  //   elevation: 0,
                  //   sx: {
                  //     fontFamily: "Cerebri sans !important",
                  //     borderRadius: "0px 0px 8px 8px ",
                  //     border: "none",
                  //     background: "#F9FAFA",
                  //     fontWeight: "300",
                  //     ".css-1f2qhs8": {
                  //       minWidth: "auto",
                  //       marginRight: "10px",
                  //     },
                  //     th: {
                  //       height: "50px",
                  //       padding: "0.5rem",
                  //       background: "#FFFFFF",
                  //       paddingLeft: "1rem",
                  //       fontFamily: "Cerebri sans !important",
                  //     },
                  //     td: {
                  //       height: "50px",
                  //       padding: "0.5rem",
                  //       fontWeight: "300",
                  //       fontSize: "13px",
                  //       paddingLeft: "1rem",
                  //       fontFamily: "Cerebri sans !important",
                  //     },
                  //     tr: {
                  //       borderRadius: "4px",
                  //       border: "2px solid red !important",
                  //       fontFamily: "Cerebri sans !important",
                  //     },
                  //     ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
                  //       display: "none", // pagination space removing from page
                  //     },
                  //   },
                  // }}
                  // muiTableBodyProps={{
                  //   sx: {
                  //     fontFamily: "Cerebri sans !important",
                  //     "& tr:nth-of-type(odd)": {
                  //       backgroundColor: "#F9FAFA",
                  //       borderRadius: "8px",
                  //       fontWeight: "300",
                  //     },
                  //   },
                  // }}
                  // muiTableContainerProps={{
                  //   elevation: 0,
                  //   sx: {
                  //     background: "#FFFFFF",
                  //     paddingX: "22px",
                  //     paddingTop: "22px",
                  //     borderRadius: "0px 0px 8px 8px ",

                  //     boxShadow: "none",
                  //     ".css-vv34gr-MuiTableRow-root": {
                  //       boxShadow: "unset !important",
                  //     },
                  //   },
                  // }}
                  // muiTableHeadCellProps={{
                  //   //simple styling with the `sx` prop, works just like a style prop in this example
                  //   sx: {
                  //     fontWeight: "700",
                  //     fontSize: "13px",
                  //     fontFamily: "Cerebri sans !important",
                  //   },
                  // }}
                />
              }
            />

            <div className="px-4">
              <table
                style={{
                  width: "100%",
                  backgroundColor: "#f9fafa",
                  borderRadius: "4px",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      className="ps-4 fs-13 form-label-title"
                      width="50%"
                      height="50px"
                    >
                      Total
                    </td>
                    <td
                      className="fs-13 form-label-title pe-4"
                      align="center"
                      width="25%"
                      height="50px"
                    >
                      360
                    </td>
                    <td
                      className="fs-13 form-label-title pe-4"
                      align="center"
                      width="25%"
                      style={{ height: "50px !important" }}
                    >
                      100%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      ) : (
        <NoDataScreen height={"377px"} imgHeight={"150px"} />
      )}
    </div>
  );
};

export default HeardAboutProperty;
