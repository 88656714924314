import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { MaterialReactTable } from "material-react-table";
import ETButton from "../../../Components/Buttons/ETButton";
import CustomPopup from "../../../Components/CustomPopup";
import FilterComponent from "../../../reactdataTableComponent/FilterComponent";
import Toggle from "../../../Components/Switch/Switch";
import AddEditPackage from "../../properties/units/AddEditPackage/AddEditPackage";
import { REQUIRED_ERR } from "../../../utils/validationConstants";

import {
  DeleteDocument,
  GetDocumentPackageByPackageId,
  PostAddDocumentPackage,
  PutUpdateDocumentCompleteDetails,
} from "../../../services/Documents";
import { toast } from "react-toastify";
import {
  useGetDocumentType,
  useGetTemplateDocuments,
  useUploadDocument,
} from "../../../hooks/queries/useDocumentsQueries";
import { GetTagsByPropertyId } from "../../../services/Tags";
import { useQuery } from "react-query";
import { usePropertyOverview } from "../../../hooks/stores/usePropertyStore";
import { useAddEditPackage } from "../../../hooks/stores/usePackageStore";
import { useRouting } from "../../../hooks/stores/useRoutingStore";
import { status } from "../../../model/Constant";
import moment from "moment";
import {
  DropZoneBorder,
  GreenSpan,
  PrimaryBreadcrums,
} from "../../../styles/Common.style";
import MRTTheme from "../../../Components/table/MRTTheme";
import {
  SortIcon,
  TableRowsStyle,
} from "../../../Components/table/MRTGlobalProps";
import { CustomersTab } from "../../../Components/Tabs/CustomersTabs.style";
import {
  useGetSigningType,
  usePostAddDocumentPackage,
  usePutUpdateDocumentBasicDetails,
} from "../../../hooks/queries/useSigningQueries";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { H1, H2 } from "../../../styles/Common.style";

export const AddSigningPackages = () => {
  // zustand
  const propOverview = usePropertyOverview((state) => state.propOverview);
  const { packageType, setShowPackage, editflag, packageId, setPackageId } =
    useRouting((state) => state);
  // For Table only it will be change later
  const [documentSearch, setDocumentSearch] = useState("");
  const [openDocumentFromLibrary, setOpenDocumentFromLibrary] = useState(false);
  const [openUploadDocumentManually, setOpenUploadDocumentManually] =
    useState(false);
  const [rowSelection, setRowSelection] = useState({});
  let selectedDocumentFromLibrary = [];
  const [droppedPdf, setDroppedPdf] = useState([]);
  const [isSettingTabOpen, setIsSettingTabOpen] = useState(true);
  const [addedDocument, setAddedDocument] = useState([]);
  const [signingType, setSigningType] = useState("");
  const [templatePdf, setTemplatePdf] = useState([]);
  // const [cancelPopup, setCancelPopup] = useState(false);
  const [templateDocuments, setTemplateDocuments] = useState([]);
  const [flagUpdateDocument, setFlagUpdateDocument] = useState(false);
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);

  const {
    title,
    setTitle,
    active,
    setActive,
    tagRowData,
    setTagRowData,
    setSelected,
    setSelectedData,
    cancelPopup,
    setCancelPopup,
  } = useAddEditPackage((state) => state);
  useEffect(() => {
    if (addedDocument?.length === 0) {
      setAddedDocument([
        {
          type: "-",
          name: "-",
          document_type_name: "-",
          signing_required: "-",
          publish_required: "-",
          delete_document: "-",
        },
      ]);
    }
  }, [addedDocument]);

  //------------Form validation---------//
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({});

  const { fields, append, remove } = useFieldArray({
    control,
    name: "droppedPdf", // This should match the name in your form data
  });

  useEffect(() => {
    let templateDocIds = Object.keys(rowSelection);
    let newArray = templateDocumentsData?.data?.filter((el) =>
      templateDocIds?.includes(el?.document_template_file_id?.toString())
    );
    setTemplateDocuments(newArray);
  }, [rowSelection]);
  //---------------------React query-------------------//
  const { data: documentTypeData } = useGetDocumentType("DOCTY");
  const { data: templateDocumentsData } = useGetTemplateDocuments(signingType);
  const {
    mutateAsync: PostUploadDocument,
    isLoading: PostUploadDocumentIsLoading,
  } = useUploadDocument();
  const {
    mutateAsync: PutUpdateDocumentBasicDetails,
    isLoading: PutUpdateDocumentBasicDetailsIsLoading,
  } = usePutUpdateDocumentBasicDetails();
  const {
    mutateAsync: PostAddDocumentPackage,
    isLoading: PostAddDocumentPackageIsLoading,
  } = usePostAddDocumentPackage();

  useEffect(() => {
    setTemplatePdf(templateDocumentsData?.data);
  }, [templateDocumentsData]);

  const { data: getDocumentPackageByPackageId } = useQuery(
    [
      "getDocumentPackageByPackageId",
      propOverview?.[0]?.property_id,
      packageId,
    ],
    () =>
      GetDocumentPackageByPackageId(propOverview?.[0]?.property_id, packageId),
    { enabled: !!editflag }
  );

  const { data: tagsByPropertyIdData } = useQuery(
    ["tagsByPropertyId", propOverview?.[0]?.property_id],
    () => GetTagsByPropertyId(propOverview?.[0]?.property_id),
    {
      enabled: !!editflag,
    }
  );
  const { data: getSigningType } = useGetSigningType(
    propOverview?.[0]?.property_id
  );

  // Add Document Preview Manually
  const [previewUploadDocumentPopup, setPreviewUploadDocumentPopup] =
    useState(false);
  const [previewUploadDocument, setPreviewUploadDocument] = useState({
    allDocumentFileUrl: "",
    allDocumentTypeFileName: "",
  });

  const HandlePreviewDocumentType = () => {
    setPreviewUploadDocumentPopup(true);
  };
  const [documentbypackage, setDocumentByPackage] = useState([]);
  useEffect(() => {
    setDocumentByPackage(getDocumentPackageByPackageId?.data);
    if (editflag) {
      setTitle(getDocumentPackageByPackageId?.data?.[0]?.package_name);
      const filteredData =
        getDocumentPackageByPackageId?.data?.[0]?.tag_ids?.split(",");
      const tagData = tagsByPropertyIdData?.data?.filter((el) =>
        filteredData?.includes(el?.tag_id?.toString())
      );
      setTagRowData(tagData);
      setSigningType(
        getDocumentPackageByPackageId?.data?.[0]?.signing_package_type
      );
      setAddedDocument(
        getDocumentPackageByPackageId?.data?.[0]?.document_details
      );
      setActive(getDocumentPackageByPackageId?.data?.[0]?.is_active);
      setSelected(filteredData);
      setSelectedData(tagData);
      setCancelPopup(false);
    } else {
      setTitle("");
      setTagRowData([]);
      setAddedDocument([]);
      setSelected([]);
      setSelectedData([]);
      setCancelPopup(false);
      setActive(true);
    }
  }, [getDocumentPackageByPackageId, tagsByPropertyIdData]);

  // dropzone
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    acceptedFiles.forEach((file) => {
      append({
        name: file.name,
        document_type: "",
        file: file,
        is_signing: "",
      });
    });
    fileRejections.map(({ file }) => {
      toast.error("Invalid file type " + file.name);
    });
  }, []);
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
    disabled: PostUploadDocumentIsLoading,
  });

  // Handle toggle switch
  const toggleCheckedData = (e, data, index) => {
    const { checked } = e?.currentTarget;
    const newArray = [...addedDocument];
    if (checked) {
      newArray[index]["is_signing"] = 1;
    } else {
      const newArray = [...addedDocument];
      newArray[index]["is_signing"] = 0;
    }
    setAddedDocument(newArray);
  };
  const publishtoggleCheckedData = (e, data, index) => {
    const { checked } = e?.currentTarget;
    const newArray = [...addedDocument];
    if (checked) {
      newArray[index]["is_published"] = 1;
    } else {
      const newArray = [...addedDocument];
      newArray[index]["is_published"] = 0;
    }
    setAddedDocument(newArray);
  };
  const modifiedData = documentbypackage?.map((item) => {
    return {
      ...item,
      modifiedOn: moment(item.modified_on).format("DD.MMMM.YYYY | HH:mm:ss"),
      createdOn: moment(item.modified_on).format("DD.MMMM.YYYY | HH:mm:ss"),
    };
  });
  // Add document form library columns
  const addDocumentlibraryColumn = useMemo(
    () => [
      {
        accessorKey: "type",
        header: "Type",
        size: 50,
        Cell: ({ cell }) => (
          <>
            <FontAwesomeIcon icon="fa-regular fa-file-pdf" className="fs-6" />
          </>
        ),
      },
      {
        accessorKey: "name",
        header: "Name",
        // size: 300,
      },
      {
        accessorKey: "document_type_name",
        header: "Document type",
        // size: 10,
      },
    ],
    []
  );

  const addedDocumentcolumns = useMemo(
    () => [
      {
        accessorKey: "type",
        header: "Type",
        size: 50,
        Cell: ({ cell }) => (
          <>
            {cell.getValue() === "-" ? (
              <span>-</span>
            ) : (
              <FontAwesomeIcon icon="fa-regular fa-file-pdf" className="fs-6" />
            )}
          </>
        ),
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 250,
        // muiTableBodyCellProps: {
        //   sx: { cursor: "pointer !important" },
        // },
        Cell: ({ row }) => (
          <>
            <div
              style={{ cursor: row.original.name == "-" ? "auto" : "pointer" }}
            >
              {row?.original?.name}
            </div>
          </>
        ),
      },
      {
        accessorKey: "document_type_name",
        header: "Document type",
        size: 100,
      },
      {
        accessorKey: "signing_required",
        header: "Signing required",
        size: 100,
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          return row.original.signing_required === "-" ? (
            <span>-</span>
          ) : (
            <div>
              <Toggle
                checked={row.original.is_signing == 1 ? true : false}
                onChange={(e) => {
                  toggleCheckedData(e, row.original, row.index);
                }}
                id={`signing_required${row.index}`}
              />
            </div>
          );
        },
      },
      {
        accessorKey: "publish_required",
        header: "Publish",
        size: 80,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          return row.original.publish_required === "-" ? (
            <span>-</span>
          ) : (
            <div>
              <Toggle
                checked={row.original.is_published == 1 ? true : false}
                onChange={(e) => {
                  publishtoggleCheckedData(e, row.original, row.index);
                }}
                id={`publish_required${row.index}`}
              />
            </div>
          );
        },
      },
      {
        accessorKey: "delete_document", //normal accessorKey
        header: "",
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) =>
          cell.getValue() !== "-" && (
            <button
              className="added-document-delete-btn"
              onClick={() => {
                setDeletePopUp(!deletePopUp);
                setEditDocumentId(cell.row.original.doc_file_id);
                // setEditDocumentId(
                //   cell.row.original.doc_file_id,
                //   cell.row.index,
                //   "documentAddedPdf"
                // );
              }}
              // onClick={() =>
              //   deletePdf(
              //     cell.row.original.doc_file_id,
              //     cell.row.index,
              //     "documentAddedPdf"
              //   )
              // }
            >
              <FontAwesomeIcon icon="fa-regular fa-trash" className="fs-6" />
            </button>
          ),
      },
    ],
    [addedDocument]
  );
  const [editDocumentId, setEditDocumentId] = useState("");
  const [deletePopUp, setDeletePopUp] = useState(false);

  const [storeTemplateDocumentsDataId, setStoreTemplateDocumentsDataId] =
    useState([]);

  // useEffect(() => {
  // 	const documentId = templatePdf?.map((data) => data.document_type_id)
  // 	setStoreTemplateDocumentsDataId(documentId)
  // }, [templatePdf])
  // deleting selected pdf from upload document manually modal
  const deletePdf = (docFileId, deletePdfIndex, tableType) => {
    setDeletePopUp(false);
    setDisableDeleteButton(true);
    if (tableType === "droppedPdf") {
      setDroppedPdf((prev) => prev.filter((data, i) => i !== deletePdfIndex));
    } else if (tableType === "documentAddedPdf") {
      DeleteDocument(docFileId).then((response) => {
        setAddedDocument((prev) =>
          prev.filter((data, i) => data?.doc_file_id !== docFileId)
        );
        toast.success(response.data.message);
        setDisableDeleteButton(false);
      });
    }
    setStoreTemplateDocumentsDataId([]);
  };

  const RemovePdf = (index) => {
    remove(index);
  };
  const [prevRowSelection, setPrevRowSelection] = useState([]);

  const handleCancelDocument = () => {
    setRowSelection([]);
    setOpenDocumentFromLibrary(false);
  };

  useEffect(() => {
    setTemplatePdf(templateDocumentsData?.data);
  }, [templateDocumentsData]);

  // const handleAddLibraryDocument = () => {
  //   setPrevRowSelection(rowSelection);
  //   const newDocuments = [];

  //   for (let i = 0; i < templateDocuments.length; i++) {
  //     const documentExists = addedDocument.some(
  //       doc => doc.document_type_id == templateDocuments[i]?.document_type_id
  //     );

  //     if (!documentExists) {
  //       newDocuments.push(templateDocuments[i]);
  //     }
  //   }

  //   if (newDocuments.length == 0) {
  //     toast.info("Document Already Exist.");
  //     return;
  //   }

  //   const uploadedDocuments = [];

  //   for (let i = 0; i < newDocuments.length; i++) {
  //     let id;

  //     if (!PostUploadDocumentIsLoading) {
  //       id = toast.loading("Uploading file");
  //     }

  //     let formData = new FormData();
  //     formData.append("property_id", propOverview?.[0].property_id);
  //     formData.append("package_id", packageId);
  //     formData.append("document_type", newDocuments[i]?.document_type_id);
  //     formData.append("document_template_file_id", newDocuments[i]?.document_template_file_id);

  //     PostUploadDocument(formData).then(
  //       (response) => {
  //         uploadedDocuments.push(response?.data?.data?.[0]);
  //         toast.update(id, {
  //           render: response?.data?.message,
  //           isLoading: false,
  //           type: status.s,
  //           autoClose: 2000,
  //         });

  //         if (addedDocument.length === 0 || addedDocument[0]?.name === "-" || addedDocument[0]?.document_type_name === "-") {
  //           setAddedDocument(newDocuments);
  //           toast.success("Documents added successfully.");
  //         } else {
  //           setAddedDocument(prev => [...prev, ...newDocuments]);
  //           toast.success("Documents added successfully.");
  //         }
  //       },
  //       (error) => {
  //         toast.update(id, {
  //           render: "Failed to upload",
  //           isLoading: false,
  //           type: status.e,
  //           autoClose: 2000,
  //         });
  //       }
  //     );
  //   }

  //   setOpenDocumentFromLibrary(false);
  // };
  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(true);

  const handleAddLibraryDocument = () => {
    const documentId = templatePdf?.map((data) => data.document_type_id);
    setStoreTemplateDocumentsDataId(documentId);
    setPrevRowSelection(rowSelection);
    let files = [];
    for (let i = 0; i < templateDocuments?.length; i++) {
      const documentExists = addedDocument?.some(
        (doc) =>
          doc?.document_type_id == templateDocuments?.[i]?.document_type_id
      );

      if (!documentExists) {
        files?.push(templateDocuments[i]);
      }
    }

    if (files?.length == 0) {
      toast.info("Document Is Already Added.");
      return;
    }
    const uploadedDocuments = [];
    for (let i = 0; i < templateDocuments?.length; i++) {
      let id;
      if (!PostUploadDocumentIsLoading) {
        id = toast.loading("Uploading file");
      }
      let formData = new FormData();
      formData.append("property_id", propOverview?.[0].property_id);
      formData.append("package_id", packageId);
      formData.append(
        "document_type",
        templateDocuments?.[i]?.document_type_id
      );
      formData.append(
        "document_template_file_id",
        templateDocuments?.[i]?.document_template_file_id
      );
      PostUploadDocument(formData).then(
        (response) => {
          uploadedDocuments[i] = response?.data?.data?.[0];
          toast.update(id, {
            render: response?.data?.message,
            isLoading: false,
            type: status.s,
            autoClose: 2000,
          });
          // if (uploadedDocuments?.length) {
          if (
            addedDocument[0]?.name === "-" ||
            addedDocument[0]?.document_type_name === "-" // if there is no data in addedDocument state i.e '-'.
          ) {
            setAddedDocument(
              uploadedDocuments?.filter((val) => {
                if (val) {
                  return { file: val.file, is_signing: "0" }; // removing failed pdf (index) -> document type from files
                }
              })
            );
          } else {
            setAddedDocument((prev) => [...prev, response?.data?.data?.[0]]);
          }
          // }
          setOpenDocumentFromLibrary(false);
        },
        (error) => {
          // Some task on failure
          toast.update(id, {
            render: "Failed to upload",
            isLoading: false,
            type: status.e,
            autoClose: 2000,
          });
        }
      );
    }
  };

  //post add signing package & put update basic details
  const handleAddDocumentBasicDetails = async () => {
    // setCancelPopup(false);
    if (editflag || flagUpdateDocument) {
      const putUpdateBasicDetails = {
        package_id: packageId?.toString(),
        tags: tagRowData?.map((item) => {
          return item.tag_id;
        }),
        package_name: title,
        is_active: active ? "1" : "0",
        signing_package_type: String(signingType),
      };
      try {
        const response = await PutUpdateDocumentBasicDetails(
          putUpdateBasicDetails
        );
        if (response.status == status.s) {
          toast.success(response?.data?.message);
          setFlagUpdateDocument(false);
          setIsSettingTabOpen(false);
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    } else {
      const addSigningPayload = {
        package_name: title,
        property_id: propOverview?.[0]?.property_id?.toString(),
        is_active: active ? "1" : "0",
        is_signing: "1",
        signing_package_type: String(signingType),
        tags: tagRowData?.map((item) => {
          return item.tag_id;
        }),
      };
      try {
        const response = await PostAddDocumentPackage(addSigningPayload);
        if (response.status == status.s) {
          toast.success(response.data.message);
          setPackageId(response?.data?.data?.document_package_id);
          setIsSettingTabOpen(false);
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };
  //Put update complete details
  const handleCreatePackage = async () => {
    setDisableDeleteButton(true);
    if (
      addedDocument?.filter((item, i) => item.is_signing === 1)?.length <= 0
    ) {
      toast.error("Atleast one documents signing is required");
      setDisableDeleteButton(false);
    } else {
      const putUpdateCompleteDetails = {
        package_id: packageId,
        property_id: String(propOverview?.[0]?.property_id),
        tags: tagRowData?.map((item) => {
          return item.tag_id;
        }),
        package_name: title,
        is_active: active ? "1" : "0",
        signing_package_type: String(signingType),
        doc_files: addedDocument?.map((item) => ({
          doc_file_id: item?.doc_file_id,
          doc_type_id: item?.document_type_id,
          is_signing: item?.is_signing === "" ? 0 : item?.is_signing,
          is_published:
            item?.is_published === "" ? 0 : item?.is_published?.toString(),
        })),
      };
      PutUpdateDocumentCompleteDetails(putUpdateCompleteDetails).then(
        (response) => {
          if (response?.data?.bReturn) {
            toast.success(response.data.message);
            setShowPackage(false);
            setTitle("");
            setActive(false);
            setTagRowData([]);
            setCancelPopup(false);
            setDisableDeleteButton(false);
          } else {
            toast.info(response.data.message);
            setDisableDeleteButton(false);
          }
        }
      );
    }
  };

  // uploading selected pdf to server and getting response
  const handleAddDocument = async (data) => {
    let files = [];
    for (let i = 0; i < data?.droppedPdf?.length; i++) {
      let id;
      if (!PostUploadDocumentIsLoading) {
        id = toast.loading("Uploading file");
      }
      let formData = new FormData();
      formData.append("files[]", data?.droppedPdf[i]?.file);
      formData.append("property_id", propOverview?.[0].property_id);
      formData.append("package_id", packageId);
      formData.append(
        "document_type",
        data?.droppedPdf[i]?.document_type?.value
      );
      formData.append("document_template_file_id", "0");
      const existingDocumentIds = addedDocument.map((doc) => doc?.id);
      PostUploadDocument(formData).then(
        (response) => {
          files[i] = response?.data?.data?.[0];
          toast.update(id, {
            render: response?.data?.message,
            isLoading: false,
            type: status.s,
            autoClose: 2000,
          });

          if (files?.length) {
            if (
              addedDocument[0]?.name === "-" ||
              addedDocument[0]?.document_type_name === "-" // if there is no data in addedDocument state i.e '-'.
            ) {
              setAddedDocument(
                files?.filter((val) => {
                  if (val) {
                    return { file: val.file, is_signing: "0" }; // removing failed pdf (index) -> document type from files
                  }
                })
              );
            } else {
              // if there is any data in addedDocument state then it will adding previous data with new one
              setAddedDocument((prev) => [
                ...prev,
                ...files?.filter((val) => {
                  if (val) return { file: val.file, is_signing: "0" }; // removing failed pdf (index) -> document type from files
                }),
              ]);
            }
          }
          remove(); // clearing dropped pdf state so every time new pdf will be added in upload document modal
          setOpenUploadDocumentManually(false);
        },
        (error) => {
          // Some task on failure
          toast.update(id, {
            render: "Failed to upload",
            isLoading: false,
            type: status.e,
            autoClose: 2000,
          });
        }
      );
    }
  };
  // const handleAddDocumen = async (data) => {
  //   for (const droppedPdf of data?.droppedPdf || []) {
  //     let id;
  //     if (!PostUploadDocumentIsLoading) {
  //       id = toast.loading("Uploading file");
  //     }

  //     const formData = new FormData();
  //     formData.append("files[]", droppedPdf?.file);
  //     formData.append("property_id", propOverview?.[0]?.property_id);
  //     formData.append("package_id", packageId);
  //     formData.append("document_type", droppedPdf?.document_type?.value);
  //     formData.append("document_template_file_id", "0");

  //     try {
  //       const response = await PostUploadDocument(formData);
  //       const file = response?.data?.data?.[0];

  //       toast.update(id, {
  //         render: response?.data?.message,
  //         isLoading: false,
  //         type: status.s,
  //         autoClose: 2000,
  //       });

  //       const existingDocumentIds = addedDocument.map(doc => doc.id);
  //       const newAddedDocuments = [file].filter(val => val);

  //       setAddedDocument(prev => [
  //         ...prev,
  //         ...newAddedDocuments.map(val => ({
  //           file: val.file,
  //           is_signing: "0"
  //         }))
  //       ]);

  //       remove();
  //       setOpenUploadDocumentManually(false);
  //     } catch (error) {
  //       toast.update(id, {
  //         render: "Failed to upload",
  //         isLoading: false,
  //         type: status.e,
  //         autoClose: 2000,
  //       });
  //     }
  //   }
  // };

  return (
    <div style={{ backgroundColor: "#F9FAFA" }}>
      <H1 className="pt-3">{editflag ? "Edit" : "Create new"} package</H1>
      <div className="pb-4 light-font-text">
        {/* {isEditMediaPackage && ( */}
        <>
          <Link
            to="/properties"
            style={{ textDecoration: "none", color: "black" }}
          >
            <span className="me-3">Property</span>
          </Link>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />
          </span>
          <Link
            to="/property-units/overview"
            state={{
              Tab: "Overview",
            }}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span className="me-3">{propOverview?.[0]?.propertyName}</span>
          </Link>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />
          </span>
        </>
        {/* )} */}
        <span
          className="me-3 cursor-pointer"
          // onClick={() => setShowSigningPackages(false)}
          onClick={() => setShowPackage(false)}
        >
          Documents
        </span>
        <span className="me-3">
          <FontAwesomeIcon
            size="sm"
            icon="fa-regular fa-angle-right"
            style={{ color: "#000000" }}
          />
        </span>
        <span className="me-3 cursor-pointer">Signing packages</span>
        <span className="me-3">
          <FontAwesomeIcon
            size="sm"
            icon="fa-regular fa-angle-right"
            style={{ color: "#000000" }}
          />
        </span>
        <span className="me-3">{editflag ? "Edit" : "Create new"} package</span>
        <span className="me-3">
          <FontAwesomeIcon
            size="sm"
            icon="fa-regular fa-angle-right"
            style={{ color: "#000000" }}
          />
        </span>
        <PrimaryBreadcrums
          mode={isSettingTabOpen}
          // style={{ color: isSettingTabOpen ? "#619130" : "#000" }}
          className="me-3"
        >
          1. Settings
        </PrimaryBreadcrums>
        {!isSettingTabOpen && (
          <>
            <span className="me-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <PrimaryBreadcrums
              mode={!isSettingTabOpen}
              // style={{ color: isSettingTabOpen ? "#000" : "#619130" }}
              className="me-3"
            >
              2. Documents
            </PrimaryBreadcrums>
          </>
        )}
      </div>
      <div className="py-4 radius-8 containerStyle">
        <div
          className="d-flex gap-2 px-4"
          style={{ alignItems: "center", textAlign: "center" }}
        >
          <div>
            <CustomersTab
              mode={isSettingTabOpen}
              // className={`${
              //   isSettingTabOpen == false
              //     ? "document-tabs"
              //     : "document-tabs-active"
              // } px-3`}
            >
              {!isSettingTabOpen && (
                <FontAwesomeIcon
                  icon="fa-solid fa-circle-check"
                  className="me-2"
                  style={{ color: "#77C7A1", fontSize: "0.9rem" }}
                />
              )}
              1. Settings
            </CustomersTab>
          </div>
          <div style={{ alignItems: "center", textAlign: "center" }}>
            <FontAwesomeIcon
              icon="fa-regular fa-chevron-right"
              style={{ color: "#010204", width: "8px", height: "11px" }}
            />
          </div>
          <div>
            <CustomersTab
              mode={!isSettingTabOpen}
              // className={`${
              //   isSettingTabOpen == false
              //     ? "document-tabs-active"
              //     : "document-tabs"
              // } px-3 fw-400`}
            >
              {/* {isEditMediaPackage ? "Edit" : "Add"}  */}
              2. Documents
            </CustomersTab>
          </div>
        </div>
        {isSettingTabOpen ? (
          <div className="px-4 py-2">
            <AddEditPackage
              storeTemplateDocumentsDataId={storeTemplateDocumentsDataId}
              propertyId={propOverview?.[0]?.property_id}
              modifiedData={modifiedData}
              showDropdown={true}
              dropDownData={getSigningType?.data}
              dropDownLabel={"Signing type"}
              setDropDownValue={setSigningType}
              dropDownValue={signingType}
              dropdownHeading={"Signing type"}
              dropDownSubHeading={
                "Choose which signing type the signing package should be connecting to."
              }
              // title={signingPackageTitle}
              // setTitle={setSigningPackageTitle}
              // tagRowData={tagRowData}
              // setTagRowData={setTagRowData}
              showActiveInactive={true}
              packageType={packageType}
              // active={active}
              // setActive={setActive}
              // cancelPackage={setCancelPopup}
              // selected={selected}
              // selectedData={selectedData}
              // setSelected={setSelected}
              // setSelectedData={setSelectedData}
              tagSubHeading="Select the tags to be associated with this package. The package will be linked to the units that use the same tag and documents can be accessed under the units documents."
              handleNext={handleAddDocumentBasicDetails}
              disableNextButton={
                PostAddDocumentPackageIsLoading ||
                PutUpdateDocumentBasicDetailsIsLoading
              }
              disabledDropDownValue={
                addedDocument?.some((item) => item?.document_type_id == 1) ||
                addedDocument?.some((item) => item?.document_type_id == 2) ||
                addedDocument?.some((item) => item?.document_type_id == 3)
                  ? true
                  : false
              }
            />
          </div>
        ) : (
          <>
            <div
              className="mx-4 my-2 overflow-hidden"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                fontSize: "14px",
                border: "1px solid #F0F0F0",
              }}
            >
              <div
                className="px-3 pb-3"
                style={{ borderBottom: "1px solid #F0F0F0" }}
              >
                <div className="px-2 pt-3 d-flex align-items-center ">
                  <H2>2. {editflag ? "Edit" : "Add"} documents</H2>
                  <div className="ps-2">
                    <CustomToolTip
                      text={editflag ? "Edit documents" : "Add documents"}
                    />
                  </div>
                </div>
                <div className=" ps-1">
                  {modifiedData?.map((item) => (
                    <>
                      {item.modified_by && item.modified_on ? (
                        <div className="created-by ps-1">
                          Last updated by {item.modified_by},{item.modifiedOn}
                        </div>
                      ) : (
                        <>
                          {item.created_by && item.created_by ? (
                            <div className="created-by">
                              Created by {item.created_by},{item.createdOn}
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </>
                  ))}
                </div>
              </div>

              <div className="px-4 py-3">
                <div className="my-media-query-section d-flex flex-nowrap flex-lg-row justify-content-between">
                  <div className="mb-3 mb-xl-0">
                    <p className="mb-0 form-label-title">Document</p>
                    <span className="light-font-text grey-text">
                      Below are the documents uploaded to the current package.
                    </span>
                  </div>
                  <div className="d-flex gap-2">
                    <ETButton
                      variant={"primary"}
                      buttonText="UPLOAD DOCUMENT MANUALLY"
                      icon={true}
                      width="fit-content"
                      onClick={() => {
                        setOpenUploadDocumentManually(true);
                      }}
                    />
                    <ETButton
                      variant={"primary"}
                      buttonText="ADD TEMPLATE FROM LIBRARY"
                      icon={true}
                      width="fit-content"
                      onClick={() => {
                        setRowSelection({ ...prevRowSelection });
                        setOpenDocumentFromLibrary(true);
                      }}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <MRTTheme
                    colorVariantsForTableData={["odd"]}
                    table={
                      <MaterialReactTable
                        columns={addedDocumentcolumns}
                        data={addedDocument ?? []}
                        icons={{ ...SortIcon }}
                        muiTableBodyCellProps={({ cell, row }) => ({
                          onClick: (event) => {
                            if (
                              cell.id.includes("name") &&
                              row.original.name !== "-"
                            ) {
                              setPreviewUploadDocumentPopup(true);
                              setPreviewUploadDocument({
                                allDocumentFileUrl: row.original?.file_url,
                                previewUploadDocument:
                                  row.original?.document_type_name,
                              });
                            }
                          },
                        })}
                        // renderEmptyRowsFallback={renderEmptyRowsFallback}
                        enableTopToolbar={false}
                        enableColumnActions={false}
                        enablePagination={false}
                        muiTableContainerProps={{
                          sx: {
                            padding: "0px !important",
                          },
                        }}
                        muiTableHeadRowProps={{
                          sx: {
                            ...TableRowsStyle,
                          },
                        }}
                      />
                    }
                  />
                  <CustomPopup
                  // Popup props...
                  >
                    {/* Popup content */}
                  </CustomPopup>
                  {addedDocument[0]?.name === "-" &&
                    addedDocument[0]?.document_type_name === "-" && (
                      <div className="mt-1 mb-5">
                        <p className="mb-0 grey-text light-font-text text-center">
                          No documents are added to the package. That’s a must
                          before you can continue.
                        </p>
                      </div>
                    )}
                </div>
              </div>

              <div
                className="d-flex flex-wrap gap-2 justify-content-between px-3 py-2"
                style={{ background: "#F9FAFA" }}
              >
                <ETButton
                  variant={"white"}
                  width="fit-content"
                  buttonText={"CANCEL"}
                  onClick={() => setCancelPopup(true)}
                />
                <div className="d-flex gap-2">
                  <ETButton
                    buttonText="PREVIOUS"
                    variant={"white"}
                    width="fit-content"
                    onClick={() => {
                      setIsSettingTabOpen(true);
                      setFlagUpdateDocument(true);
                    }}
                  />

                  <ETButton
                    // disabled={!addedDocument?.filter((item) => item?.is_signing == 1)?.length > 0}
                    disabled={
                      (addedDocument[0]?.document_type_name === "-" && true) ||
                      disableDeleteButton
                    }
                    variant={"primary"}
                    buttonText={editflag ? "SAVE AND CLOSE" : "CREATE PACKAGE"}
                    width="fit-content"
                    onClick={handleCreatePackage}
                  />
                </div>
              </div>
            </div>

            {/* const [previewUploadDocumentPopup, setPreviewUploadDocumentPopup] =
    useState(false);
  const [previewUploadDocument, setPreviewUploadDocument] = useState({
    allDocumentFileUrl: "",
    allDocumentTypeFileName: "",
  }); */}

            {/* ------------------------ Add Template from libarary ----------------------- */}

            {/* {addedDocument?.[0]?.document_type_name !== "-" && ( */}
            <>
              <CustomPopup
                open={previewUploadDocumentPopup}
                onHide={() => setPreviewUploadDocumentPopup(false)}
                modalHeaderText={true}
                title={previewUploadDocument?.previewUploadDocument}
                // title="testing popup"
                size="xl"
                title_color="black"
                enableCrossButton={true}
                action_button_label="YES"
                action_button_label_color="white"
                action_button_color="#619130"
                cancel_button={true}
                cancel_button_bordercolor="grey"
                cancel_button_label="NO"
                cancel_button_label_color="black"
                cancel_button_color="#fff"
                footer_color={"#F9FAFA"}
                deletePopUpStyle={true}
                // subTitle={previewUploadDocument?.previewUploadDocument}
                titleTooltip={false}
                // titleTooltipText={allDocumentDetails?.allDocumentPackageFileName}
              >
                <div>
                  <Row>
                    <Col className="">
                      <div style={{ height: "540px" }}>
                        <iframe
                          src={previewUploadDocument?.allDocumentFileUrl}
                          height="100%"
                          width="100%"
                        ></iframe>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div
                  className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
                  style={{
                    backgroundColor: "#F9FAFA",
                    borderRadius: " 0px 0px 8px 8px",
                    border: "1px solid #F0F0F0",
                  }}
                ></div>
              </CustomPopup>
            </>
            {/* )} */}

            {/* upload document manually */}
            <CustomPopup
              open={openUploadDocumentManually}
              onHide={() => setOpenUploadDocumentManually(false)}
              title="Upload document manually"
              title_color="black"
              enableCrossButton={false}
              action_button_label="YES"
              action_button_label_color="white"
              action_button_color="#619130"
              cancel_button={true}
              cancel_button_bordercolor="grey"
              cancel_button_label="NO"
              cancel_button_label_color="black"
              cancel_button_color="#fff"
              footer_color={"#F9FAFA"}
              deletePopUpStyle={true}
              titleTooltip={true}
              size="lg"
            >
              <form onSubmit={handleSubmit(handleAddDocument)}>
                <div className="p-3">
                  <div className="px-2">
                    <div className="light-font-text my-1">
                      Drag and drop documents directly from your computer. It is
                      possible to change the name of the document and select a
                      document type. Once you have selected and customized the
                      documents, click add and they will be added to the
                      package.
                    </div>
                  </div>
                  <Row className="mt-3 px-2 upload-manually-document-table">
                    <Col md={{ span: 12 }}>
                      <div className="pt-2 pb-4">
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <DropZoneBorder className="py-4 d-flex flex-column justify-content-center align-items-center">
                            <div>
                              {/* <FontAwesomeIcon icon="fa-solid fa-file" /> */}
                              <FontAwesomeIcon
                                icon="fa-solid fa-file-pdf"
                                style={{ color: "#000000" }}
                              />
                            </div>
                            <div className="mt-2 mb-1 form-label-title">
                              Drop your file here, or&nbsp;
                              <GreenSpan
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={open}
                              >
                                browse your computer
                              </GreenSpan>
                            </div>
                            <div className="created-by">Supports only PDF</div>
                          </DropZoneBorder>
                        </div>
                      </div>
                      <div>
                        {/* {droppedPdf?.length !== 0 && ( */}
                        <div className="mb-4">
                          <Row className="m-0 p-0">
                            {fields.length !== 0 && (
                              <Row className="m-0 p-0 mb-2">
                                <Col
                                  className="d-flex align-items-center py-2  actors-section-title fs-13 ps-3"
                                  xs={7}
                                >
                                  Name
                                  {/* <FontAwesomeIcon
                          icon="fa-solid fa-sort"
                          className="ms-1"
                          style={{ color: "#bfbfbf" }}
                        /> */}
                                </Col>
                                <Col
                                  className="d-flex align-items-center py-2 actors-section-title fs-13 ps-0 "
                                  xs={4}
                                >
                                  Document type
                                  {/* <FontAwesomeIcon
                          icon="fa-solid fa-sort"
                          className="ms-1"
                          style={{ color: "#bfbfbf" }}
                        /> */}
                                </Col>
                                <Col xs={2}></Col>
                              </Row>
                            )}

                            <div
                              className="p-0"
                              style={{
                                maxHeight: "160px",
                                overflow: "auto",
                              }}
                            >
                              {fields.map((data, i) => (
                                <div key={data.id}>
                                  <Row
                                    className="m-0 p-0 mb-2"
                                    style={{
                                      borderRadius: "8px",
                                      backgroundColor: "#F9FAFA",
                                    }}
                                  >
                                    <Col
                                      className="d-flex align-items-start py-2 pe-4"
                                      xs={7}
                                    >
                                      <div
                                        className="bg-white radius-6 ms-md-1 w-100 ellipsis "
                                        style={{
                                          padding: "0.3rem 0.5rem",
                                          border: "1px solid #D1D1D1",
                                          marginTop: "2px",
                                          height: "38px",
                                        }}
                                      >
                                        <p className="mb-0 fs-300 fs-14">
                                          <Controller
                                            name={`droppedPdf[${i}].name`}
                                            control={control}
                                            defaultValue={data.name}
                                            render={({ field }) => (
                                              <input
                                                disabled
                                                className="standard-input"
                                                {...field}
                                              />
                                            )}
                                          />
                                        </p>
                                      </div>
                                    </Col>
                                    <Col
                                      className="d-flex align-items-start py-2 pe-4 ps-0"
                                      xs={4}
                                    >
                                      <div className="w-100 fs-300 fs-14">
                                        <Controller
                                          {...register(
                                            `droppedPdf[${i}].document_type`
                                          )}
                                          rules={{ required: true }}
                                          render={({
                                            field: { onChange, value },
                                          }) => (
                                            <ReactSelect
                                              placeholder=""
                                              value={value}
                                              clearable={false}
                                              isSearchable
                                              disabled={
                                                PostUploadDocumentIsLoading
                                              }
                                              onChange={(e) => {
                                                onChange(e);
                                                clearErrors(
                                                  `droppedPdf[${i}].document_type`
                                                );
                                              }}
                                              error={errors.document_type}
                                              options={documentTypeData?.data?.map(
                                                (item) => ({
                                                  value: item?.Id,
                                                  label: item?.Text,
                                                })
                                              )}
                                            />
                                          )}
                                          name={`droppedPdf[${i}].document_type`}
                                          control={control}
                                        />

                                        {errors?.droppedPdf?.[i]
                                          ?.document_type && (
                                          <p className="error-msg">
                                            {REQUIRED_ERR}
                                          </p>
                                        )}
                                      </div>
                                    </Col>
                                    <Col
                                      className="d-flex align-items-start py-2 justify-content-end"
                                      xs={1}
                                    >
                                      <div>
                                        <button
                                          className="added-document-delete-btn"
                                          onClick={() => RemovePdf(i)}
                                          disabled={PostUploadDocumentIsLoading}
                                        >
                                          <FontAwesomeIcon
                                            icon="fa-regular fa-trash"
                                            className="fs-6"
                                          />
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                            </div>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
                  <ETButton
                    variant={"white"}
                    buttonText="CANCEL"
                    onClick={() => {
                      setOpenUploadDocumentManually(false);
                      // setDroppedPdf([]);
                      remove();
                    }}
                    disabled={PostUploadDocumentIsLoading}
                  />
                  <ETButton
                    variant={"primary"}
                    buttonText="ADD"
                    type="submit"
                    disabled={PostUploadDocumentIsLoading}
                    // onClick={handleAddDocument}
                  />
                </div>
              </form>
              {/* ------------------------ Siging document preview Pop Up ----------------------- */}
              {/* const [previewUploadDocumentPopup, setPreviewUploadDocumentPopup] =
    useState(false);
  const [previewUploadDocument, setPreviewUploadDocument] = useState({
    allDocumentFileUrl: "",
    allDocumentTypeFileName: "",
  }); */}

              {/* 
							<CustomPopup
								open={previewUploadDocumentPopup}
								onHide={() => setPreviewUploadDocumentPopup(false)}
								modalHeaderText={true}
								title={previewUploadDocument?.allDocumentTypeFileName}
								// title="testing popup"
								size="xl"
								title_color="black"
								enableCrossButton={true}
								action_button_label="YES"
								action_button_label_color="white"
								action_button_color="#619130"
								cancel_button={true}
								cancel_button_bordercolor="grey"
								cancel_button_label="NO"
								cancel_button_label_color="black"
								cancel_button_color="#fff"
								footer_color={"#F9FAFA"}
								deletePopUpStyle={true}
								subTitle={previewUploadDocument?.allDocumentTypeFileName}
								titleTooltip={false}
							// titleTooltipText={allDocumentDetails?.allDocumentPackageFileName}
							>
								<div>
									<Row>
										<Col className="">
											<div style={{ height: "540px" }}>
												<iframe
													src={previewUploadDocument?.allDocumentFileUrl}
													height="100%"
													width="100%"></iframe>
											</div>
										</Col>
									</Row>
								</div>
								<div
									className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
									style={{
										backgroundColor: "#F9FAFA",
										borderRadius: " 0px 0px 8px 8px",
										border: "1px solid #F0F0F0",
									}}></div>
							</CustomPopup> */}
            </CustomPopup>
            {/* upload document manually */}

            {/* Add document from library */}
            <CustomPopup
              open={openDocumentFromLibrary}
              onHide={() => setOpenDocumentFromLibrary(false)}
              title="Add template from library"
              title_color="black"
              enableCrossButton={false}
              action_button_label="YES"
              action_button_label_color="white"
              action_button_color="#619130"
              cancel_button={true}
              cancel_button_bordercolor="grey"
              cancel_button_label="NO"
              cancel_button_label_color="black"
              cancel_button_color="#fff"
              footer_color={"#F9FAFA"}
              deletePopUpStyle={true}
              titleTooltip={true}
              size="lg"
            >
              <div className="p-3">
                <div className="px-2">
                  <div className="light-font-text my-1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
                  </div>
                </div>
                <div
                  className="mt-3 px-2"
                  style={{ width: "35%", minWidth: "160px" }}
                >
                  <FilterComponent
                    filtertext={documentSearch}
                    handleSearch={(e) => setDocumentSearch(e.target.value)}
                  />
                  {/* const [previewUploadDocumentPopup, setPreviewUploadDocumentPopup] =
    useState(false);
  const [previewUploadDocument, setPreviewUploadDocument] = useState({
    allDocumentFileUrl: "",
    allDocumentTypeFileName: "",
  }); */}
                </div>
                <div className="px-2 mt-4">
                  <div className="my-2">
                    <MRTTheme
                      colorVariantsForTableData={["odd", "white"]}
                      table={
                        <MaterialReactTable
                          columns={addDocumentlibraryColumn}
                          // muiTableBodyCellProps={({ cell, row }) => ({
                          //   onClick: (event) => {
                          //     // if (cell.id.includes("name")) {
                          //     setPreviewUploadDocumentPopup(true);
                          //     setPreviewUploadDocument({
                          //       allDocumentPackageNameUrl:
                          //         row.original?.allDocumentFileUrl,
                          //       allDocumentPackageFileName:
                          //         row.original?.allDocumentTypeFileName,
                          //     });
                          //     // }
                          //   },
                          // })}
                          data={templatePdf ?? []}
                          icons={{ ...SortIcon }}
                          enableTopToolbar={false}
                          enableColumnActions={false}
                          enablePagination={false}
                          enableRowSelection={true}
                          enableBottomToolbar={false}
                          getRowId={(row) => row.document_template_file_id}
                          onRowSelectionChange={setRowSelection}
                          state={{ rowSelection }}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
                <ETButton
                  variant={"white"}
                  buttonText="CANCEL"
                  onClick={handleCancelDocument}
                />
                <ETButton
                  variant={"primary"}
                  buttonText="ADD"
                  onClick={handleAddLibraryDocument}
                  disabled={
                    (isAnyCheckboxChecked && templateDocuments?.length === 0) ||
                    PostUploadDocumentIsLoading
                  }
                />
              </div>
            </CustomPopup>
            {/* Add document from library */}
          </>
        )}
      </div>
      <CustomPopup
        style={{ position: "relative" }}
        size="md"
        open={cancelPopup}
        onHide={() => setCancelPopup(false)}
        title="Are you sure you want to cancel?"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() => {
          setCancelPopup(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div
            style={{
              fontSize: "14px",
              fontFamily: "Cerebri Sans",
              fontWeight: "500",
              textAlign: "left",
              lineHeight: "17.5px",
            }}
          >
            Cancelling will undo all your changes
          </div>
        </div>

        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="NO"
            onClick={() => setCancelPopup(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="YES"
            // onClick={() => setShowSigningPackages(false)}
            onClick={() => setShowPackage(false)}
          />
        </div>
      </CustomPopup>

      <CustomPopup
        // modalHeadingStyle={modalHeadingStyle}
        style={{ position: "relative" }}
        size="md"
        open={deletePopUp}
        onHide={() => setDeletePopUp(false)}
        title="Are you carrying out the trash?"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() => {
          setDeletePopUp(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div className="fs-14 fw-300 text-left mb-3">
            <p>Are you sure you want to delete the document?</p>
            {/* <p>That will conflict with.....</p> */}
          </div>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => setDeletePopUp(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="I'M SURE"
            disabled={disableDeleteButton}
            onClick={() => deletePdf(editDocumentId, null, "documentAddedPdf")}
            // onClick={() =>
            //   deletePdf(editDocumentId, null, "documentAddedPdf")
            // }
          />
        </div>
      </CustomPopup>
    </div>
  );
};
