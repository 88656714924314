import {
  useMutation,
  useQuery,
  QueryClient,
  useQueryClient,
} from "react-query";

import { SendOtp, SendOtpWithPayload, ValidateOtp } from "../../services/Sms";

export const useSendOtp = () => {
  return useMutation(SendOtp);
};

// Post Send Otp With Payload

export const useSendOtpWithPayload = () => {
  return useMutation(SendOtpWithPayload);
};

export const useValidateOtp = () => {
  return useMutation(ValidateOtp);
};
