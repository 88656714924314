import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { H4 } from "../../../../styles/Common.style";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import GroupText from "../../../../Components/GroupText/GroupText";
import InputField from "../../../../Components/Textfield/InputField";
import Toggle from "../../../../Components/Switch/Switch";
import ETButton from "../../../../Components/Buttons/ETButton";

const EconomyAndPriorities = ({
  handleConfirm = () => {},
  handleCancel = () => {},
  handleAddExtraCustomer = () => {},
}) => {
  const handleAccessConfirmationscreen = () => {
    handleConfirm();
  };

  const handleAccessCustomerInformationScreen = () => {
    handleCancel();
  };
  return (
    <div>
      {" "}
      <div className="Popups-inner-scrollable-container-height scrollable-container-y">
        <Row className="p-4">
          <Col lg={8}>
            <div className="light-font-text">
              Below is information retrieved from the unit you are creating a
              contract on.
            </div>{" "}
            <div className="light-font-text">
              If you want to make changes, you can do so here without affecting
              the unit's master details.
            </div>
          </Col>
        </Row>
        <div className="border-bottom px-4 pb-4">
          <div className="d-flex align-items-center gap-2 ">
            <H4>Customer</H4>
            <CustomToolTip size="sm" text="Customer" />
          </div>
          <div className="light-font-text pt-1 pb-3">
            Selected Customer to this appointment.
          </div>

          <Row>
            <Col className="pe-0" lg={6}>
              <div className="position-relative w-100">
                <div
                  className="bg-white position-absolute px-1 font-medium fs-8 grey-text"
                  style={{ left: "14px", top: "-5px" }}
                >
                  To recipients
                </div>
                <div className="badge-input">
                  <span
                    className="radius-4  bgcolor-d-white"
                    style={{
                      border: "0.5px solid #888",
                      width: "fit-content",
                      padding: "0px 8px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon="fa-regular fa-circle-xmark"
                      style={{ color: "#888888", cursor: "pointer" }}
                    />
                    <FontAwesomeIcon
                      icon="fa-regular fa-user-pen"
                      className="ps-2"
                      style={{ color: "#000000", cursor: "pointer" }}
                    />
                    <span className="ps-1">Thomas Høeg Mogensen</span>
                  </span>
                </div>
              </div>
            </Col>
            <Col lg={1} className=" d-flex align-items-center  gap-3">
              {" "}
              <FontAwesomeIcon
                onClick={handleAddExtraCustomer}
                icon="fa-regular fa-user-plus"
                size="lg"
                style={{ color: "#000000", cursor: "pointer" }}
              />
            </Col>
          </Row>
        </div>
        <div className="border-bottom p-4">
          <H4>Contract type</H4>

          <div className="light-font-text  py-2">
            Define the type of the contract
          </div>

          <Row>
            <Col lg={3}>
              <ReactSelect placeholder={"Contract type"} />
            </Col>
          </Row>
        </div>
        <div className="border-bottom p-4">
          <H4 className="mb-2 pb-1">Economy about the unit</H4>

          <Row>
            <Col className="pe-0" sm={3}>
              <GroupText
                lable={"Listing price per month"}
                type={"number"}
                name={"Listing price per month"}
                id={"1"}
                graylabel={"DKK/mthly."}
                textWidth="35%"
              />
            </Col>
            <Col className="ps-1 pe-0" sm={3}>
              <GroupText
                lable={"Calculated deposit"}
                type={"number"}
                name={"Calculated deposit"}
                id={"2"}
                graylabel={"DKK/mthly."}
                textWidth="35%"
              />
            </Col>
            <Col className="ps-1" sm={3}>
              <GroupText
                lable={"Customer prepaid rent"}
                type={"number"}
                name={"Customer prepaid rent"}
                id={"3"}
                graylabel={"DKK/mthly."}
                textWidth="35%"
              />
            </Col>
          </Row>
        </div>
        <div className="p-4 border-bottom">
          <Row>
            <Col className="" sm={3}>
              <H4 className="mb-2 pb-1">Move in date</H4>
              <InputField type={"date"} placeholder={"Move-in date"} />
            </Col>
            <Col className="pe-0" sm={3}>
              <H4 className="mb-2 pb-1">Expected termination date</H4>
              <InputField
                type={"date"}
                placeholder={"Expected termination date"}
              />
            </Col>
          </Row>
        </div>
        <div className="p-4 border-bottom">
          <Row>
            <Col className="" sm={3}>
              <H4 className="mb-2 pb-1">Contract created</H4>
              <InputField type={"date"} placeholder={"Contract created"} />
            </Col>
            <Col className="pe-0" sm={3}>
              <H4 className="mb-2 pb-1">Deadline for the contract</H4>
              <InputField type={"date"} placeholder={"Contract deadline"} />
            </Col>
          </Row>
        </div>
        <div className="p-4 border-bottom">
          <H4>Responsible agent</H4>

          <div className="light-font-text  py-2">
            Select the responsible agent for this contract
          </div>
          <Row>
            <Col lg={6}>
              <ReactSelect placeholder={"Responsible agent"} />
            </Col>
          </Row>
        </div>
        <div className="p-4 border-bottom">
          <H4>Add a priority</H4>
          <div className="light-font-text  pt-2">
            If you waant to prioritise this contract, you can do so here.
          </div>{" "}
          <div className="light-font-text  pb-2">
            The priority can be changed afterwards.
          </div>
          <Row>
            <Col lg={6}>
              <ReactSelect placeholder={"Contract priority"} />
            </Col>
          </Row>
        </div>
        <div className="p-4">
          <H4>Actions</H4>
          <div className="light-font-text  pt-2">
            Select actions to activate when a contract is created.
          </div>{" "}
          <div className="d-flex align-items-center mt-4">
            <Toggle />{" "}
            <span className="ms-3 light-font-text">
              {" "}
              Send confirmation to the customer{" "}
            </span>
          </div>
          <div className="d-flex align-items-center mt-4">
            <Toggle />{" "}
            <span className="ms-3 light-font-text">
              {" "}
              Start screening of the customer
            </span>
          </div>
        </div>
      </div>
      <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-between footer-section-button-container">
        <ETButton
          variant={"white"}
          buttonText={"BACK"}
          onClick={handleAccessCustomerInformationScreen}
        />
        <ETButton
          variant={"primary"}
          buttonText="CREATE CONTRACT"
          onClick={handleAccessConfirmationscreen}
        />
      </div>
    </div>
  );
};

export default EconomyAndPriorities;
