import styled from "styled-components";
import {
  Tab as UnstyledTab,
  TabList as UnstyledTabList,
  Tabs as UnstyledTabs,
  TabPanel as UnstyledTabPanel,
} from "react-tabs";
import { NavLink as UnstyledNavLink } from "react-router-dom";

// This tabs used on the palce of React tabs all over in Et50//
const Tabs = styled(UnstyledTabs)`
  background: transparent;
`;

const TabList = styled(UnstyledTabList)`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 11 */

  // &::-webkit-scrollbar {
  //   display: none;  /* WebKit */
  // }

  padding: 0;
  margin: 0;
`;

const Tab = styled(UnstyledTab).attrs({
  selectedClassName: "selected",
  disabledClassName: "disabled",
})`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  margin-right: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  list-style: none;
  padding: 10px 16px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 16px;
  height: 44px;
  font-family: Cerebri sans-light;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  text-align: center;
  background-color: #f9fafa;
  white-space: nowrap; /* Prevent text from wrapping */

  &.selected {
    background: ${(props) =>
      props.theme.component.tabs.primary.backGroundColor};
    color: #fff;
    font-family: Cerebri sans-medium;
  }

  &.disabled {
    color: #f9fafa;
    cursor: not-allowed;
  }
  &:focus-visible {
    outline: red;
  }
`;

const TabPanel = styled(UnstyledTabPanel).attrs({
  selectedClassName: "selected",
})`
  display: none;
  &.selected {
    display: block;
  }
`;
const NavLink = styled(UnstyledNavLink)`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  margin-right: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  list-style: none;
  padding: 10px 16px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 16px;
  height: 44px;
  font-family: Cerebri sans-light;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  text-align: center;
  background-color: #fff;
  color: #000000;
  :hover {
    color: #000000;
  }
  &.active {
    background: ${(props) =>
      props.theme.component.tabs.primary.backGroundColor};
    color: #fff;
    font-family: Cerebri sans-medium;
  }

  &.disabled {
    color: #fff;
    cursor: not-allowed;
  }
`;

Tab.tabsRole = "Tab";
Tabs.tabsRole = "Tabs";
TabPanel.tabsRole = "TabPanel";
TabList.tabsRole = "TabList";
NavLink.tabsRole = "NavLink";

export { Tab, TabList, Tabs, TabPanel, NavLink };

// This tabs used on the palce of React tabs all over in Et50//
