import React, { useEffect, useState } from "react";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../../Components/Tabs/ReactTabs.style";
import { useBreadcrumb } from "../../../Components/BreadcrumbContext ";
import { H2 } from "../../../styles/Common.style";
import AddnewProperty from "../addNewProperty/AddnewProperty";
import CreateNewBillingDetails from "../../../Components/wizard/CreateNewBillingDetails";
import Operations from "../../organization/setting/property/propertyTabs/operations/Operations";

export const Details = () => {
  const [detailsMainTabs, setDetailsMainTabs] = useState("");
  const { updateBreadcrumbs } = useBreadcrumb();

  useEffect(() => {
    updateBreadcrumbs(["Details", "Property information"]);
  }, []);

  useEffect(() => {
    if (
      detailsMainTabs === "Property information" ||
      detailsMainTabs === undefined
    ) {
      updateBreadcrumbs(["Details", "Property information"]);
    } else if (detailsMainTabs === "Billing details") {
      updateBreadcrumbs(["Details", "Billing details"]);
    } else if (detailsMainTabs === "Operation") {
      updateBreadcrumbs(["Details", "Operation"]);
    }
  }, [detailsMainTabs]);

  const OverviewTabsWizards = [
    {
      Id: "1",
      tab: "Property information",
      component: <AddnewProperty />,
    },
    {
      Id: "2",
      tab: "Billing details",
      // component: <CreateNewBillingDetails />,
      component: <>Billing details</>,
    },
    {
      Id: "3",
      tab: "Operation",
      component: <Operations />,
    },
  ];

  return (
    <div className="p-4   containerStyle">
      <div className="bg-white radius-8 fs-14 mainborder">
        <div
          className="px-4 py-3 "
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div className=" d-flex align-items-center">
            <H2>Details</H2>
            <div className="ps-2">
              {" "}
              <CustomToolTip text="Details" />{" "}
            </div>
          </div>
          {/* <span className="light-font-text fs-12">
            Last updated by Jesper Thorkildsen, 19. Jan. 2023 | 16:21:03
          </span> */}
        </div>
        <Tabs lassName="mb-3 pb-1 d-flex flex-column" value={detailsMainTabs}>
          <div
            className="d-flex ps-4 pe-3 py-3 justify-content-between"
            style={{
              width: "100%",
              flexDirection: "row",
              flexWrap: "wrap",
              borderBottom: "1px solid #F0F0F0",
            }}
          >
            <TabList className="light ps-0  ">
              {OverviewTabsWizards.map((page, index) => (
                <>
                  <Tab
                    key={index}
                    style={{ position: "relative" }}
                    onClick={() => setDetailsMainTabs(page?.tab)}
                    className="me-3"
                  >
                    <span style={{ position: "relative" }}>{page?.tab}</span>
                  </Tab>
                </>
              ))}
            </TabList>
          </div>
          <div className="bg-white" style={{ borderRadius: " 10px" }}>
            {OverviewTabsWizards.map((page, index) => (
              <TabPanel key={page?.Id}>{page?.component}</TabPanel>
            ))}
          </div>
        </Tabs>
      </div>
    </div>
  );
};

// Created by ------> Yash
