import React, { useRef, useState, useMemo } from "react";
import CSVWidget from "../../../Components/DownloadWidget/CSVWidget";

import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { Row, Col, Image, Button } from "react-bootstrap";
import Menus from "../../../Components/Menus/Menus";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import { H2, H3 } from "../../../styles/Common.style";

import img1 from "../../../assets/images/heatmapStaticImg/1.png";
import img2 from "../../../assets/images/heatmapStaticImg/2.png";
import img3 from "../../../assets/images/heatmapStaticImg/3.png";
import img4 from "../../../assets/images/heatmapStaticImg/4.png";
import img5 from "../../../assets/images/heatmapStaticImg/5.png";
import img6 from "../../../assets/images/heatmapStaticImg/6.png";
import img7 from "../../../assets/images/heatmapStaticImg/7.png";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import MRTTheme from "../../../Components/table/MRTTheme";
import { SortIcon } from "../../../Components/table/MRTGlobalProps";

const eiDoTableData = [
  {
    pageHead: "ListView",
    pageBody: "/",
    views: 144,
    visitTime: "0m 30s",
    clicks: "87",
  },
  {
    pageHead: "Compare",
    pageBody: "/compare",
    views: 144,
    visitTime: "0m 30s",
    clicks: "87",
  },
  {
    pageHead: "Downloads",
    pageBody: "/downloads",
    views: 144,
    visitTime: "0m 30s",
    clicks: "87",
  },
  {
    pageHead: "Gallery",
    pageBody: "/gallery",
    views: 144,
    visitTime: "0m 30s",
    clicks: "87",
  },
  {
    pageHead: "Isometry",
    pageBody: "/isometry",
    views: 144,
    visitTime: "0m 30s",
    clicks: "87",
  },
  {
    pageHead: "Maerk-Historiens-Vingesus-I-Fredericias-Groenne-Oaser",
    pageBody: "/maerk-historiens-vingesus-i-fredericias-groenne-oaser",
    views: 144,
    visitTime: "0m 30s",
    clicks: "87",
  },
  {
    pageHead:
      "I-Carolinelunden-Bliver-Det-Nemmere-At-Passe-Paa-Pengene-I-En-Dyr-Tid",
    pageBody:
      "/i-carolinelunden-bliver-det-nemmere-at-passe-paa-pengene-i-en-dyr-tid",
    views: 144,
    visitTime: "0m 30s",
    clicks: "87",
  },
  {
    pageHead: "Nyheder",
    pageBody: "/nyheder",
    views: 144,
    visitTime: "0m 30s",
    clicks: "87",
  },
];

const HeatMapEiDo = () => {
  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "EI.DO";

  const options = {
    keys: [
      { field: "pageHead" },
      { field: "pageBody" },
      { field: "views" },
      { field: "visitTime" },
      { field: "clicks" },
    ],
  };

  const data = eiDoTableData;

  const CSVFunction = CSVWidget({ refObj, fileName, options, data });

  const images = [img1, img2, img3, img4, img5, img6, img7];

  const eiDoColumns = useMemo(() => [
    {
      header: "",
      id: "image",
      enableHiding: false,

      size: 10,

      Cell: ({ row }) => (
        <>
          <div>
            {/* {images.map((img, i) => ( */}
            <div className="fw-600 fs-14">
              <img
                style={{ borderRadius: "8px" }}
                alt="myFrame-list"
                loading="lazy"
                width="50"
                frameBorder="0"
                height="50"
                scrolling="no"
                src={img1}
              />
            </div>
            {/* ))} */}
          </div>
        </>
      ),
    },
    {
      header: "Page",
      enableHiding: false,

      size: 400,

      Cell: ({ row }) => (
        <>
          <div>
            <div className="fw-600 fs-13">{row.original.pageHead}</div>
            <div className="fw-300 fs-14">{row.original.pageBody}</div>
          </div>
        </>
      ),
    },
    {
      accessorKey: "views",
      header: "Views",
      enableGlobalFilter: false,
      enableSorting: false,
      size: 150,
    },
    {
      accessorKey: "visitTime",
      header: "Visit time",
      enableGlobalFilter: false,
      enableSorting: false,
      size: 150,
    },
    {
      accessorKey: "clicks",
      header: "Clicks",
      enableGlobalFilter: false,
      enableSorting: false,
      size: 150,
    },
  ]);

  const listViewColumns = useMemo(
    () => [
      {
        accessorKey: "status",
        header: "listview",
        enableGlobalFilter: false,
        enableSorting: false,
        size: 100,
      },
      {
        accessorKey: "quantity",
        header: "",
        enableSorting: false,
        enableGlobalFilter: false,
        size: 100,
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );

  const listViewData = [
    {
      status: "Views",
      quantity: 0,
    },
    {
      status: "Clicks",
      quantity: 10,
    },
    {
      status: "Visit time",
      quantity: 20,
    },
    {
      status: "Scroll",
      quantity: 30,
    },
    {
      status: "Engagement",
      quantity: 30,
    },
    {
      status: "Entry page",
      quantity: 30,
    },
  ];

  return (
    // <div
    //   className="p-3 customers-region radius-8"
    //   style={{ background: "#FFFFFF" }}
    // >
    <div className="card-body p-3">
      <Row>
        <Col className="p-0" sm={8}>
          {" "}
          <div className="d-flex ps-3 align-items-center">
            <H3 className="  me-2">Heatmap</H3>{" "}
            <CustomToolTip size="sm" text="Heatmap" />
          </div>
        </Col>
        <Col sm={4} className="d-flex justify-content-end">
          {" "}
          <Menus
            itemheight={40}
            itemwidth={40}
            itemcontainerheight={200}
            itemcontainerwidth={200}
            component={
              <div className="fs-14">
                <div className="addpropContent px-3 py-2 ">
                  <span className="ms-1" onClick={() => CSVFunction()}>
                    Download widget as CSV
                  </span>
                </div>
              </div>
            }
          />
        </Col>
      </Row>
      {eiDoTableData.length == 0 ? (
        <>
          <Row>
            <Col>
              <div
                className="fs-14 light-font-text ps-1"
                style={{ position: "relative", bottom: "13px" }}
              >
                Ei.Do
              </div>
              <div className="fs-12 table-property-name py-2 ps-1">
                Last update: Jan 10
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={8} lg={12} md={12} sm={12}>
              <div
                className="radius-8 "
                style={{ border: "1px solid #f4f5f5" }}
              >
                <iframe
                  frameBorder="0"
                  title="mouseflow"
                  scrolling="no"
                  width="100%"
                  height="438px"
                  // src={images[iframePageID]}
                  // src={myData[iframePageID]?.Share_Url}
                ></iframe>
              </div>
            </Col>
            <Col className="pe-3" xl={4} lg={12} md={12} sm={12}>
              <div>
                <MRTTheme
                  colorVariantsForTableData={["odd"]}
                  table={
                    <MaterialReactTable
                      columns={listViewColumns}
                      data={listViewData}
                      icons={{ ...SortIcon }}
                      enableColumnActions={false}
                      enableBottomToolbar={false}
                      enableTopToolbar={false}
                    />
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="pt-3">
                <MRTTheme
                  colorVariantsForTableData={["odd"]}
                  table={
                    <MaterialReactTable
                      columns={eiDoColumns}
                      data={eiDoTableData}
                      icons={{ ...SortIcon }}
                      enableColumnActions={false}
                      enableBottomToolbar={false}
                      enableTopToolbar={false}
                      enableRowActions
                      displayColumnDefOptions={{
                        "mrt-row-actions": {
                          header: "",
                        },
                        "mrt-row-select": {
                          header: "",
                        },
                      }}
                      muiTableContainerProps={{
                        sx: {
                          padding: "0px !important",
                        },
                      }}
                      renderRowActions={({ row, table }) => (
                        <span className="breakdown-button">
                          <Button
                            className=" fs-12 px-2 py-2 fw-400 shadow-none"
                            style={{
                              border: "1px solid #F0F0F0",
                              width: "100px",
                              backgroundColor: "#fff",
                              color: "#000",
                              borderRadius: "8px",
                            }}
                            // onClick={() => {
                            //   setShowSeeDetails(true);
                            // }}
                          >
                            SEE DETAILS
                          </Button>
                        </span>
                      )}
                      positionActionsColumn="last"
                    />
                  }
                />
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <NoDataScreen height={"377px"} imgHeight={"150px"} />
      )}
    </div>
  );
};

export default HeatMapEiDo;
