// import { Get } from ".././utils/Axios-old";
// import { endpoint } from ".././utils/Endpoints";
// import { GetHeader } from ".././utils/Header";
import { Get } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

// Get countries
export const GetGeographyCountry = (header = null) => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Geography_Get_Country, header)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

// Get regions
export const GetRegionByCountryId = (id) => {
	id = id === "" || id === null || id === undefined ? 0 : id;
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Geography_GetRegionByCountryId, null, id)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

// Get zipcodes
export const GetZipCodeByRegionId = (bid) => {
	bid = bid === "" || bid === null || bid === undefined ? 0 : bid;
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Geography_GetZipCodeByRegionId, null, bid)
			.then((response) => {
				resolve(response.data);
				// console.log("GetZipCodeByRegionId", response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

export const GetZipcodeByCountry = (countryId) => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Get_Zipcode_By_Country, null, countryId)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

// --------------- get coordination ------------------- //
export const GetCordinationByInfo = (info) => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Get_Coordinates_By_Info, null, info)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Location - Get street details
export const GetStreetDetails = (streetName) => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Location_GetStreetDetails, null, streetName)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};