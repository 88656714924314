import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ETButton from "../../../../Components/Buttons/ETButton";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScreenAccordion from "./ScreeningAcrrodion";
import { GreenSpan } from "../../../../styles/Common.style";
import EmptyMessage from "../../../../utils/EmptyMessage";
import { useBreadcrumb } from "../../../../Components/BreadcrumbContext ";
import Countdown, { zeroPad } from "react-countdown";
import moment from "moment";
import { format } from "date-fns";
import {
  useGetScreeningTemplateQuestion,
  useGetScreeningTemplateQuestionAnswer,
} from "../../../../hooks/queries/useActorQueries";
import { usePropertyId } from "../../../../hooks/stores/usePropertyStore";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";
import { UTCtoLocalTimeZone } from "../../../../utils/UTCtoLocalTimeZone";

const Screening = ({
  initialSelectedName,
  selectedName,
  tanentNameTab,
  contractOverViewData,
  actorByContractIdData,
}) => {
  const { updateBreadcrumbs } = useBreadcrumb();
  const { propertyId } = usePropertyId((state) => state);

  //----------------------------states-------------------//
  const [screening_question, setScreening_question] = useState([]);
  const [screeningActor, setScreeningActor] = useState("");

  //----------------React query----------------//
  const { data: getScreeningTemplateQuestionAnswerData } =
    useGetScreeningTemplateQuestionAnswer(
      propertyId,
      contractOverViewData?.data?.[0]?.contract_id,
      0
    );
  const { data: getScreeningTemplateQuestionData } =
    useGetScreeningTemplateQuestion(6);

  useEffect(() => {
    updateBreadcrumbs(["Contracts", initialSelectedName, "Screening"]);
  }, [initialSelectedName]);

  useEffect(() => {
    if (tanentNameTab) {
      updateBreadcrumbs(["Contracts", selectedName, "Screening"]);
    } else {
    }
  }, [selectedName]);

  useEffect(() => {
    if (Object.keys(getScreeningTemplateQuestionData ?? {}).length) {
      const formLabelSet = new Set(
        getScreeningTemplateQuestionData?.data.map((q) => q.category_name)
      ); // Getting all unique form labels

      const categorizedData = {};

      getScreeningTemplateQuestionData?.data?.forEach((item) => {
        const { category_name } = item;
        if (!categorizedData[category_name]) {
          categorizedData[category_name] = {
            questionBank: [],
          };
        }
        categorizedData[category_name]?.questionBank?.push(item);
      });
      setScreening_question({
        ...screening_question,
        steps: categorizedData,
      });
    }
  }, [getScreeningTemplateQuestionData, screeningActor]);

  const { userDetails } = useUserDetails((state) => state);

  const { convertedDate: reservation_created } = UTCtoLocalTimeZone({
    timestamp: contractOverViewData?.data?.[0]?.reservation_created,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD MMM, YYYY [at] HH:mm:ss",
  });

  // const renderer = ({ days, hours, minutes, seconds, completed }) => {
  //   if (completed) {
  //     // Render a complete state
  //     return <>Reservation expired</>;
  //   } else {
  //     // Render a countdown
  //     return (
  //       <span>
  //         {zeroPad(days)} : {minutes} : {seconds}
  //       </span>
  //     );
  //   }
  // };

  console.log(contractOverViewData);

  return (
    <Container
      fluid
      className="px-4"
    >
      <div className=" my-3 p-4 radius-8 customers-card-layout">
        <div className=" my-2 d-flex flex-column align-items-center">
          <div className="light-font-text">Screening expires in</div>
          <div
            className="d-flex form-label-title flex-column justify-content-center"
            style={{ fontSize: "42px" }}
          >
            <Countdown
              date={moment(
                contractOverViewData?.data?.[0]?.reservation_deadline
              ).valueOf()}
              daysInHours
              zeroPadTime={2}
              onComplete={() => <>Screening expired</>}
              className="d-flex justify-content-center"
              // renderer={renderer}
            />
            <div className="d-flex w-100">
              <span
                className="fs-10 grey-text d-flex justify-content-center"
                style={{ width: "33.33%" }}
              >
                HOURS
              </span>
              <span
                className="fs-10 grey-text d-flex justify-content-center"
                style={{ width: "33.33%" }}
              >
                MINUTES
              </span>
              <span
                className="fs-10 grey-text d-flex justify-content-center"
                style={{ width: "33.33%" }}
              >
                SECONDS
              </span>
            </div>
            {/* <div className="d-flex flex-column">
              <span>00</span>
              <span className="fs-10  grey-text">HOURS</span>
            </div>
            <div className="px-2">:</div>
            <div className="d-flex flex-column">
              <span>00</span>
              <span className="fs-10 grey-text">MINUTES</span>
            </div>
            <div className="px-2">:</div>
            <div className="d-flex flex-column">
              <span>00</span>
              <span className="fs-10 grey-text">SECONDS</span>
            </div> */}
          </div>
        </div>
        <Row>
          <Col lg={4}>
            <div
              className="radius-8 bg-white mt-4 p-3 scrollable-container-y lite-grey-border"
              style={{
                height: "305px",
                overflowY: "scroll",
              }}
            >
              <div className="actors-section-title">Important dates</div>
              <div>{/* <EmptyMessage /> */}</div>
              <div className="px-2 py-1 mt-2 bg-grey radius-4 fs-13 d-flex justify-content-between">
                <span>Reservation created</span>
                <span className="actors-lite-text">
                  {contractOverViewData?.data?.[0]?.reservation_created !== ""
                    ? reservation_created
                    : "-"}
                </span>
              </div>

              <div className="px-2 py-1 mt-3 bg-grey radius-4 fs-13 d-flex justify-content-between">
                <span>Screening started </span>
                <span className="actors-lite-text">
                  {contractOverViewData?.data?.[0]?.screening_started !== ""
                    ? moment(
                        contractOverViewData?.data?.[0]?.screening_started
                      ).format("DD MMM, YYYY [at] HH:mm:ss")
                    : "-"}
                </span>
              </div>

              <div className="px-2 py-1 mt-2 bg-grey radius-4  fs-13 d-flex justify-content-between">
                <span>Screening deadline</span>
                <span className="actors-lite-text">
                  {contractOverViewData?.data?.[0]?.screening_deadline !== ""
                    ? moment(
                        contractOverViewData?.data?.[0]?.screening_deadline
                      ).format("DD MMM, YYYY [at] HH:mm:ss")
                    : "-"}
                </span>
                {contractOverViewData?.data?.[0]?.screening_deadline !== "" && (
                  <span
                    className="bg-white p-1 radius-4 d-flex align-center lite-grey-border"
                    style={{ height: "22px" }}
                  >
                    <FontAwesomeIcon icon="fa-regular fa-calendar-days" />
                  </span>
                )}
              </div>

              <div className="px-2 py-1 mt-3 bg-grey radius-4 fs-13 d-flex justify-content-between">
                <span>Last reminder</span>
                <span className="actors-lite-text">
                  {contractOverViewData?.data?.[0]?.screening_deadline !== ""
                    ? moment(
                        contractOverViewData?.data?.[0]?.screening_deadline
                      ).format("DD MMM, YYYY [at] HH:mm:ss")
                    : "-"}
                </span>
              </div>
              <div className="px-2 py-1 mt-2 bg-grey radius-4 fs-13 d-flex justify-content-between">
                <span>Next reminder </span>
                <span className="actors-lite-text">
                  {contractOverViewData?.data?.[0]?.screening_deadline !== ""
                    ? moment(
                        contractOverViewData?.data?.[0]?.screening_deadline
                      ).format("DD MMM, YYYY [at] HH:mm:ss")
                    : "-"}
                </span>
              </div>

              <div className="mt-3 mb-2">
                <ETButton
                  disabled={true} //Enable when real data comes
                  variant={"primary"}
                  buttonText={"SEND REMINDER"}
                  size={"sm"}
                />
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div
              className="radius-8 bg-white mt-4 p-3 scrollable-container-y lite-grey-border"
              style={{
                height: "305px",
                overflowY: "scroll",
              }}
            >
              <div className="actors-section-title">
                Total screening fullfilment
              </div>
              <div className="actors-lite-text">
                Indication of how long the customer(s) are in the screening
                process in total
              </div>
              <div
                // className="mx-auto"
                className="mt-4 mb-3 mx-auto"
                style={{ width: "60%", maxWidth: "140px" }}
              >
                <CircularProgressbarWithChildren
                  styles={buildStyles({
                    pathColor: "#77C970",
                    trailColor: "#D9D9D9",
                    strokeLinecap: "round",
                  })}
                  value={
                    parseInt(
                      getScreeningTemplateQuestionAnswerData?.data?.reduce(
                        (accumulator, item) =>
                          accumulator + item.completion_percentage,
                        0
                      ) / getScreeningTemplateQuestionAnswerData?.data?.length
                    ) || 0
                  }
                >
                  <div
                    className="actors-section-title"
                    style={{ fontSize: 38, marginTop: -5 }}
                  >
                    <strong>
                      {parseInt(
                        getScreeningTemplateQuestionAnswerData?.data?.reduce(
                          (accumulator, item) =>
                            accumulator + item?.completion_percentage,
                          0
                        ) / getScreeningTemplateQuestionAnswerData?.data?.length
                      ) || 0}
                      %
                    </strong>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div
              className="radius-8 bg-white mt-4 p-3 scrollable-container-y lite-grey-border"
              style={{
                height: "305px",
                overflowY: "scroll",
              }}
            >
              <div className="actors-section-title">Screening</div>
              <div className="actors-lite-text">
                You can approve or decline the screening by these buttons. It
                will overrule the unfilled task in the screening.
              </div>
              <div className="mt-4 mb-3">
                <ETButton
                  variant={"success"}
                  size={"lg"}
                  buttonText="APPROVE"
                  width={"100%"}
                />
              </div>
              <div>
                <ETButton
                  variant={"error"}
                  size={"lg"}
                  buttonText={"DECLINE"}
                  width={"100%"}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <ScreenAccordion
        getScreeningTemplateQuestionAnswerData={
          getScreeningTemplateQuestionAnswerData
        }
        getScreeningTemplateQuestionData={getScreeningTemplateQuestionData}
        screeningActor={screeningActor}
        setScreeningActor={setScreeningActor}
        screening_question={screening_question}
      />
    </Container>
  );
};

export default Screening;
