import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import MRTTheme from "../../../../Components/table/MRTTheme";
import { H3 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { Col, Row } from "react-bootstrap";
import { SortIcon } from "../../../../Components/table/MRTGlobalProps";
import { useGetScreeningDividedOnAgent } from "../../../../hooks/queries/useCustomerFunnel";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";
const DividedOnAgents = () => {
  //------------------- Zustand --------------//
  const { userDetails } = useUserDetails((state) => state);
  //------------------- State --------------//
  const [dividedOnAgentsTableData, setDividedOnAgentsTableData] = useState("");
  //-------------------React query--------------//
  const { data: GetScreeningDividedOnAgent } = useGetScreeningDividedOnAgent(
    userDetails?.division_id
  );
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "Agent", //access nested data with dot notation
        header: "Agent",
        size: 150,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            {row?.original?.agent == "" ? "-" : row?.original?.agent}
          </div>
        ),
      },
      {
        accessorKey: "Division",
        header: "Division",
        size: 150,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            {row?.original?.division_name == ""
              ? "-"
              : row?.original?.division_name}
          </div>
        ),
      },
      {
        accessorKey: "Ongoing", //normal accessorKey
        header: "Ongoing",
        size: 100,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            {row?.original?.On_going == "" ? "-" : row?.original?.On_going}
          </div>
        ),
      },
      {
        accessorKey: "Completed",
        header: "Completed",
        size: 100,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            {row?.original?.Completed == "" ? "-" : row?.original?.Completed}
          </div>
        ),
      },
      {
        accessorKey: "Approved",
        header: "Approved",
        size: 100,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            {row?.original?.Approved == "" ? "-" : row?.original?.Approved}
          </div>
        ),
      },
      {
        accessorKey: "Declined",
        header: "Declined",
        size: 100,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            {row?.original?.Declined == "" ? "-" : row?.original?.Declined}
          </div>
        ),
      },
      {
        accessorKey: "Canceled",
        header: "Canceled",
        size: 100,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            {row?.original?.Cancelled == "" ? "-" : row?.original?.Cancelled}
          </div>
        ),
      },
    ],
    []
  );
  //------------------- useEffect --------------//
  useEffect(() => {
    setDividedOnAgentsTableData(GetScreeningDividedOnAgent?.data);
  }, [GetScreeningDividedOnAgent]);

  return (
    <div className="whiteContainerStyle px-4 py-3">
      <MRTTheme
        colorVariantsForTableRow={["gray"]}
        colorVariantsForTableHead={["white"]}
        table={
          <MaterialReactTable
            columns={columns}
            enableTopToolbar={true}
            icons={{ ...SortIcon }}
            enableBottomToolbar={false}
            enableColumnActions={false}
            // data={unitsData?.filter((data) => data.is_linked_with_screening !== 1) ?? []}
            data={dividedOnAgentsTableData || []}
            muiTablePaperProps={{
              sx: {
                table: {
                  borderCollapse: "separate",
                  borderSpacing: "0 5px",
                },
              },
            }}
            muiTableContainerProps={{
              sx: {
                padding: "0px !important",
              },
            }}
            renderTopToolbar={({ table }) => (
              <div>
                <Row>
                  <Col xl={8} lg={7}>
                    <div className="d-flex gap-2 align-items-center">
                      <H3>Screening divided on agents</H3>
                      <CustomToolTip
                        text={"Screening divided on agents"}
                        size="sm"
                      />
                    </div>
                  </Col>
                  <Col xl={4} lg={5}>
                    <ReactSelect
                      placeholder={"Filter"}
                      defaultValue={{
                        value: 1,
                        label: "Show only declined screenings",
                      }}
                      options={[
                        { value: 0, label: "Show all" },
                        { value: 1, label: "Show only declined screenings" },
                        { value: 2, label: "Show only canceled screenings" },
                      ]}
                    />{" "}
                  </Col>
                </Row>
              </div>
            )}
            positionGlobalFilter="right"
            positionToolbarDropZone="bottom"
          />
        }
      />
    </div>
  );
};

export default DividedOnAgents;

//nested data is ok, see accessorKeys in ColumnDef below
const data = [
  {
    Agent: "John",
    Team: "Doe",
    Ongoing: 37,
    Completed: 37,
    Approved: 37,
    Declined: 37,
    Canceled: 37,
  },
  {
    Agent: "Jane",
    Team: "Doe",
    Ongoing: 37,
    Completed: 37,
    Approved: 37,
    Declined: 37,
    Canceled: 37,
  },
  {
    Agent: "Joe",
    Team: "Doe",
    Ongoing: 37,
    Completed: 37,
    Approved: 37,
    Declined: 37,
    Canceled: 37,
  },
  {
    Agent: "Kevin",
    Team: "Vandy",
    Ongoing: 37,
    Completed: 37,
    Approved: 37,
    Declined: 37,
    Canceled: 37,
  },
  {
    Agent: "Joshua",
    Team: "Rolluffs",
    Ongoing: 37,
    Completed: 37,
    Approved: 37,
    Declined: 37,
    Canceled: 37,
  },
];

// created logs
// created by Yash
// // move code below components --done by Suraj
// API integration --  Neha
