import React, { useState } from "react";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { Col, Row } from "react-bootstrap";
import PasswordField from "../../../../Components/GroupText/PasswordField";
import { H4 } from "../../../../styles/Common.style";
import Toggle from "../../../../Components/Switch/Switch";
import ETButton from "../../../../Components/Buttons/ETButton";
import { Menu } from "@mui/material";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";

export const CustomerDetails = ({ handleCloseButton = () => {} }) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const handleAddPropertyDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePropertyDropDown = () => {
    setAnchorEl(null);
  };

  //============= handle close popup ============//
  const handleClose = () => {
    handleCloseButton();
  };
  return (
    <div>
      {" "}
      <div className="p-4 border-bottom">
        {" "}
        <div className="mb-3">
          <Row>
            <Col xl={3} lg={4} md={6}>
              <div className="form-input ">
                <input
                  type="text"
                  placeholder="First name (required)"
                  id="firstName"
                />
                <label for="firstName">First name</label>
              </div>
            </Col>
            <Col xl={3} lg={4} md={6} className="ps-lg-0">
              <div className="form-input ">
                <input
                  type="text"
                  placeholder="Last name"
                  id="lastName"

                  // }}
                />
                <label for="lastName">Last name</label>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mb-3">
          <Row>
            <Col xl={6} lg={8} md={12}>
              <div className="form-input ">
                <input type="email" placeholder="Email (required)" id="Email" />
                <label for="Email">Email</label>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mb-3">
          <Row>
            <Col xl={6} lg={8}>
              <Row>
                <Col xl={4} lg={5} md={4} className="pe-0">
                  <ReactSelect placeholder={"Country code"} />
                </Col>
                <Col xl={8} lg={7} md={8}>
                  <div className="form-input ">
                    <input
                      type="number"
                      placeholder="Phone"
                      id="Phone"
                      maxlength={15}
                    />
                    <label for="Phone">Phone</label>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="mb-3">
          <Row>
            <Col xl={6} lg={8}>
              <PasswordField
                id={"ssn"}
                placeholder={"Social Security Number"}
                maxlength={15}
              />
            </Col>
          </Row>
        </div>
        <div className="mb-3">
          <Row>
            <Col xl={6} lg={8}>
              <Row>
                <Col xl={4} lg={4} md={6} className=" pe-0">
                  <div className="form-input ">
                    <input type="text" placeholder="Street" id="street" />
                    <label for="street">Street </label>
                  </div>
                </Col>

                <Col lg={2} md={6} className="pe-0">
                  <div className="form-input ">
                    <input type="text" placeholder="No." id="houseN0" />
                    <label for="houseN0">No. </label>
                  </div>
                </Col>
                <Col lg={2} md={6} className="pe-0">
                  <div className="form-input ">
                    <input type="number" placeholder="Floor" id="floor" />
                    <label for="floor">Floor </label>
                  </div>
                </Col>
                <Col lg={4} md={6} className="">
                  <div className="form-input ">
                    <input
                      type="text"
                      placeholder="Side / Door"
                      id="Side/Door"
                    />
                    <label for="Side/Door">Side / Door </label>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="mb-3">
          <Row>
            <Col xl={6} lg={8}>
              <Row>
                <Col lg={4} md={6} className=" pe-0">
                  <ReactSelect placeholder={"Country"} />
                </Col>
                <Col lg={4} md={6} className=" pe-0">
                  <ReactSelect placeholder={"Zip code"} />
                </Col>

                <Col lg={4} md={6} className="">
                  <div className="form-input ">
                    <input type="text" placeholder="City" id="city" />
                    <label htmlFor="city">City</label>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div className="p-4 border-bottom">
        <H4>Preferred languages</H4>
        <div className="light-font-text c-black w-75 pt-2 pb-4">
          Select the preferred language in which the customer wants to
          communicate.
        </div>
        <Row>
          <Col lg={3}>
            <ReactSelect placeholder={"Select language"} />
          </Col>
        </Row>
      </div>
      <div className="p-4 border-bottom">
        <H4>Customer settings</H4>
        <div className="light-font-text c-black w-75 pt-2 pb-4">
          Select which of the settings below are relevant for this customer
        </div>
        <div className="d-flex align-items-center gap-2">
          <Toggle />

          <span className="light-font-text">
            Use only 2-factor signing (e.g. if the customer does not have an
            electronic ID)
          </span>
        </div>
      </div>
      <div className="p-4 pb-0">
        <H4>Demographics</H4>
        <div className="light-font-text c-black w-50 pt-2 pb-4">
          Add some demographic information about the customer. The information
          is visible to system users in the Dashboard and for statistics
        </div>
        <Row>
          <Col lg={2} className="pe-lg-0">
            <ReactSelect placeholder={"Select language"} />
          </Col>
          <Col lg={2} className="pe-lg-0">
            <ReactSelect placeholder={"Select language"} />
          </Col>
          <Col lg={2} className="pe-lg-0">
            <ReactSelect placeholder={"Select language"} />
          </Col>
        </Row>
        <div className="my-4">
          {" "}
          <ETButton
            variant={"secondary"}
            buttonText="ADD FIELD"
            icon={true}
            onClick={handleAddPropertyDropDown}
            width="auto"
          />
        </div>

        <Menu
          anchorEl={anchorEl}
          open={anchorEl}
          onClose={handleClosePropertyDropDown}
          PaperProps={{
            style: {
              borderRadius: "8px",
              marginTop: "6px",
              border: "0.5px solid #F0F0F0",
              boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.12)",
              paddingTop: "0px",
            },
          }}
          sx={{
            "& .css-6hp17o-MuiList-root-MuiMenu-list": {
              padding: 0,
              borderColor: "##F0F0F0",
            },
          }}
        >
          <div
            className="addpropertyCountryHandle "
            style={{ fontSize: "14px", width: "300px" }}
          >
            <>
              <div className="d-flex align-items-center">
                <span className="ms-1">
                  <CustomCheckBox label={"Occupation"} />
                </span>
              </div>
              <hr className="hr m-0"></hr>
              <div className="d-flex align-items-center">
                <span className="ms-1">
                  <CustomCheckBox label={"Working offshore"} />
                </span>
              </div>
            </>
          </div>
        </Menu>
      </div>
      <div
        className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between
          justify-content-center footer-section-button-container"
      >
        <ETButton
          variant={"white"}
          buttonText="CLOSE"
          onClick={() => {
            handleClose();
          }}
        />
        <ETButton variant={"primary"} buttonText="SAVE" />
      </div>
    </div>
  );
};
