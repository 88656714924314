// created by Suraj
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../../../Components/Buttons/ETButton";
import { CategoryIconBox, H5 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import FilterComponent from "../../../../reactdataTableComponent/FilterComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CreateNewCategory = ({
  newCategoryPopup,
  setNewCategoryPopup,
  isEditCategory = false,
}) => {
  const [selectedIcon, setSelectedIcon] = useState(0);
  return (
    <div>
      {/*Category name  */}
      <div className="p-4 border-bottom">
        <Row>
          <Col lg={6}>
            <div className="light-font-text pb-3">
              If you want to create a new category, give it a name and select an
              icon.
            </div>
            <div className="form-input ">
              <input
                type="text"
                placeholder="Category name"
                id="Category_name"
                maxLength={30}
              />
              <label htmlFor="Category_name">Category name</label>
            </div>
          </Col>
        </Row>
      </div>

      {/* Select icon */}
      <div className="p-4">
        <div className="d-flex align-items-center gap-2 pb-2">
          <H5>Select icon</H5>
          <CustomToolTip text="Select icon" />
        </div>
        <Row>
          <Col lg={6}>
            <div className="light-font-text pb-3">
              If you want to create a new category, give it a name and select an
              icon.
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={7}>
            <FilterComponent />
          </Col>
          <Col>
            <ETButton
              variant={"white"}
              buttonText="Reset"
              width={"fit-content"}
            />
          </Col>
        </Row>
      </div>

      <div className="p-4 pt-0">
        <H5>{iconList?.length} icons</H5>
        <div className="containerStyleColor p-1 mt-3 d-flex  flex-wrap">
          {iconList?.map((item, index) => (
            <CategoryIconBox
              mode={index === selectedIcon ? true : false}
              onClick={() => setSelectedIcon(index)}
            >
              <FontAwesomeIcon
                icon={item?.iconName}
                size="xl"
                style={{ color: "#000000" }}
              />
              <div className="fs-10 pt-1 px-3 d-flex align-items-center justify-content-center">
                {item?.iconLabel}
              </div>
            </CategoryIconBox>
          ))}
        </div>
      </div>

      {/* category footer */}
      <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
        <ETButton
          variant={"white"}
          buttonText="CANCEL"
          onClick={() => {
            setNewCategoryPopup({ create: false, edit: false });
          }}
        />
        <ETButton
          variant={"primary"}
          buttonText={isEditCategory === true ? "SAVE" : "CREATE"}
          onClick={() => setNewCategoryPopup({ create: false, edit: false })}
        />
      </div>
    </div>
  );
};

export default CreateNewCategory;

const iconList = [
  {
    id: 1,
    iconLabel: "washing - machine",
    iconName: "fa-regular fa-washing-machine",
  },
  { id: 2, iconLabel: "dryer-heat", iconName: "fa-solid fa-dryer-heat" },
  { id: 3, iconLabel: "dryer", iconName: "fa-regular fa-dryer" },
  {
    id: 1,
    iconLabel: "machine",
    iconName: "fa-regular fa-washing-machine",
  },
  { id: 2, iconLabel: "dryer-heat", iconName: "fa-solid fa-dryer-heat" },
  { id: 3, iconLabel: "dryer", iconName: "fa-regular fa-dryer" },
  {
    id: 1,
    iconLabel: "washing-machine",
    iconName: "fa-regular fa-washing-machine",
  },
  { id: 2, iconLabel: "dryer-heat", iconName: "fa-solid fa-dryer-heat" },
  { id: 3, iconLabel: "dryer", iconName: "fa-regular fa-dryer" },
  {
    id: 1,
    iconLabel: "machine",
    iconName: "fa-regular fa-washing-machine",
  },
  { id: 2, iconLabel: "dryer-heat", iconName: "fa-solid fa-dryer-heat" },
  { id: 3, iconLabel: "dryer", iconName: "fa-regular fa-dryer" },
  {
    id: 1,
    iconLabel: "washing-machine",
    iconName: "fa-regular fa-washing-machine",
  },
  { id: 2, iconLabel: "dryer-heat", iconName: "fa-solid fa-dryer-heat" },
  { id: 3, iconLabel: "dryer", iconName: "fa-regular fa-dryer" },
];
