import { Site } from "./Endpoints";
import { GetSessionItem } from "../../utils/SessionStorage";

export const GetDefaultHeader = (site) => {
	const header = {};
	if (site === Site.ET) {
		const authToken = GetSessionItem("et-jwt");
		header["auth-token"] = authToken;
	}
	return header;
};
