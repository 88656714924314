import { Endpoint, Site } from "./API/Endpoints";
import { Delete, Get, Post, Put } from "./API/Method";

//------------- Get Guides data used on guid main page --------------//
export const GetGuidesByDivisionId = (divisionId, statusId = 0) => {
  const additionalUrl = `${divisionId}/status/${statusId}`;
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Guide_Get_GuideCard_By_DivisionId,
      null,
      additionalUrl
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//------------------ Delete guide Document  by Guide ID
export const DeleteGuideDocumentByGuideID = (GuideId) => {
  return new Promise((resolve, reject) => {
    Delete(
      Site.ET,
      Endpoint.ET.Guide_Delete_GuideCard_By_GuideId,
      null,
      GuideId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// ----------------Put guide status-----------------------//
export const PutGuideStatus = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Guide_Put_Guide_Status, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// ----------------Add new guide -----------------------//
export const addNewGuide = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Guide_Post_Add_New_Guide, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//---------------------------Get contract status in guide -------------------------------//
export const GetContractStatus = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Guide_Get_Contract_Status, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//-------------------------- Get guide by guide Id ----------------------------------------//
export const GetGuideByGuideId = (id) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Guide_Get_Guide_By_GuideId, null, id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// ----------------Update new guide -----------------------//
export const updateGuide = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Guide_Post_Update_Guide, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
