import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { GreenSpan } from "../../styles/Common.style";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import ETButton from "../../Components/Buttons/ETButton";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import moment from "moment";
import { useGetGeographyCountry } from "../../hooks/queries/usePropertyQueries";
import {
  useGetStreetDetails,
  useGetZipcodeByCountry,
} from "../../hooks/queries/useGeographyQueries";
import { Controller, useForm } from "react-hook-form";

import {
  useGetCompanyByCompanyId,
  useGetCompanyByLevel,
  useUpdateCompany,
} from "../../hooks/queries/useCompanyQueries";

import { toast } from "react-toastify";
import { useEditCompany } from "../../hooks/stores/useCompanyStore";
import { useLocation } from "react-router-dom";
import { status } from "../../model/Constant";
import { H1, H2 } from "../../styles/Common.style";
import Toggle from "../../Components/Switch/Switch";
import InputField from "../../Components/Textfield/InputField";
import {
  useGetOrganizationFloorType,
  useGetOrganizationSideType,
} from "../../hooks/queries/useOrganization";
import { billingDetailsDropdownData } from "../../model/commonDropdownData";
import { GetDivisionByDivisionId } from "../../services/Division";
import {
  ErrorAlert,
  LoadingAlert,
  SucessAlert,
} from "../../Components/alerts/AlertsAndMessages";
import { useDivisionId } from "../../hooks/stores/useDivisionsStore";
import { capitalizeEachWord } from "../../utils/TextTransform";
import { SelectPhoneNumber } from "../../Components/phoneNumberDropdown/SelectPhoneNumber";
import { useGetBillingLevelDetails } from "../../hooks/queries/useCommonTypeQueries";
import StreetInput from "../../Components/Textfield/StreetInput";
import { useQueryClient } from "react-query";

const AddCompany = ({
  setShowCompanyPage,
  buttonType = "UPDATE",
  setButtonType,
}) => {
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    clearErrors,
    watch,
    resetField,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyname: "",
      street: "",
      houseno: "",
      addInfo: "",
      country: "",
      zipcode: "",
      city: "",
    },
  });
  //---------------------------states---------------------//

  const [searchedStreetName, setSearchedStreetName] = useState("");
  const [isSearchInfoStreetVisible, setIsSearchInfoStreetVisible] =
    useState(false);
  const [isSameDetailAsAboveActive, setIsSameDetailAsAboveActive] =
    useState(false);
  const [isSearchStreetVisible, setIsSearchStreetVisible] = useState(false);
  const [timeOutId, setTimeOutId] = useState(null);

  //---------------------------Zustand---------------------//

  const { companyId } = useEditCompany((state) => state);
  let location = useLocation();
  const { state } = location;
  let EditCompanyId =
    state?.editCompanyId == undefined ? companyId : state?.editCompanyId;
  const { divisionId } = useDivisionId((state) => state);

  //---------------------------react query---------------------//
  const queryClient = useQueryClient()
  const {
    data: GetCompanyByCompanyIdData,
    refetch: GetCompanyByCompanyIdDataRefetch,
  } = useGetCompanyByCompanyId(EditCompanyId);

  const { data: getStreetDetailsData } =
    useGetStreetDetails(searchedStreetName);
  const { data: zipCodeByCountryIdData2 } = useGetZipcodeByCountry(
    getValues("bInfo_country")?.value
  );
  const { data: getOrganizationFloorTypeData } = useGetOrganizationFloorType();
  const { data: getOrganizationSideTypeData } = useGetOrganizationSideType();

  const { data: countryData } = useGetGeographyCountry();
  const { data: zipCodeByCountryIdData } = useGetZipcodeByCountry(
    getValues("country")?.value
  );
  const { data: GetCompanyByLevelData, refetch: GetCompanyByLevelDataRefetch } =
    useGetCompanyByLevel("d", divisionId, "details");
  useEffect(() => {
    GetCompanyByCompanyIdDataRefetch();
  }, []);

  const { mutateAsync: updateCompany, isLoading: IsLoadingUpdateCompany } =
    useUpdateCompany();

  const { data: GetBillingLevelDetailsData } = useGetBillingLevelDetails(
    "c",
    divisionId
  );

  useEffect(() => {
    if (buttonType == "UPDATE") {
      if (GetDivisionByDivisionId?.data !== null) {
        const companyByIdData = GetCompanyByCompanyIdData?.data?.[0];
        setValue("company_name", companyByIdData?.company_name);
        setValue("care_of", companyByIdData?.care_of);
        setValue("street_name", companyByIdData?.street_name);
        setValue("house_number", companyByIdData?.house_number);
        setValue("addInfo", companyByIdData?.address_add_info);
        setValue("vat_number", companyByIdData?.public_id1);
        setValue("gst_number", companyByIdData?.public_id2);
        setValue("cvr_number", companyByIdData?.public_id3);
        setValue("floor_id", {
          value: companyByIdData?.floor_id,
          label: companyByIdData?.floor_name,
          isDisabled: true,
        });
        setValue("side_id", {
          value: companyByIdData?.side_id,
          label: companyByIdData?.side_name,
          isDisabled: true,
        });
        setValue("door", companyByIdData?.door);
        setValue("phone", companyByIdData?.contact_person_phone_no);
        setValue("country_code", companyByIdData?.contact_person_isd_code);
        setValue("country", {
          value: companyByIdData?.country_id,
          label: companyByIdData?.country,
          isDisabled: true,
        });
        setValue("zipcode_id", {
          value: companyByIdData?.zipcode_id,
          label: companyByIdData?.city_name,
          isDisabled: true,
        });
        setValue("city", companyByIdData?.city_name);
        setValue("contact_person_name", companyByIdData?.contact_person_name);
        setValue("contact_person_email", companyByIdData?.contact_person_email);
        setValue("short_name", companyByIdData?.shortname);
        setValue("website", companyByIdData?.website_url);

        //billing info
        setValue(
          "bInfo_company",
          companyByIdData?.billing_details?.[0]?.company_name
        );
        setValue(
          "bInfo_care_of",
          companyByIdData?.billing_details?.[0]?.care_of,
          {
            shouldValidate: true,
          }
        );
        setValue(
          "bInfo_company_co",
          companyByIdData?.billing_details?.[0]?.care_of
        );
        setValue("ean_number", companyByIdData?.billing_details?.[0]?.ean);
        setValue(
          "bInfo_vat_number",
          companyByIdData?.billing_details?.[0]?.public_id1
        );
        setValue(
          "bInfo_gst_number",
          companyByIdData?.billing_details?.[0]?.public_id2
        );
        setValue(
          "bInfo_cvr_number",
          companyByIdData?.billing_details?.[0]?.public_id3
        );

        setValue(
          "bInfo_street_name",
          companyByIdData?.billing_details?.[0]?.street_name
        );
        setValue(
          "bInfo_house_number",
          companyByIdData?.billing_details?.[0]?.house_number
        );
        setValue(
          "bInfo_floor_number",
          companyByIdData?.billing_details?.[0]?.floor_id === ""
            ? ""
            : {
              value: companyByIdData?.billing_details?.[0]?.floor_id,
              label: companyByIdData?.billing_details?.[0]?.floor_name,
            }
        );
        setValue(
          "bInfo_side",
          companyByIdData?.billing_details?.[0]?.side_id === ""
            ? ""
            : {
              value: companyByIdData?.billing_details?.[0]?.side_id,
              label: companyByIdData?.billing_details?.[0]?.side_name,
            }
        );
        setValue("bInfo_door", companyByIdData?.billing_details?.[0]?.door);
        setValue(
          "bInfo_country",
          companyByIdData?.billing_details?.[0]?.country_id === ""
            ? ""
            : {
              value: companyByIdData?.billing_details?.[0]?.country_id,
              label: companyByIdData?.billing_details?.[0]?.country,
            }
        );
        setValue(
          "bInfo_zipCode",
          companyByIdData?.billing_details?.[0]?.zipcode_id === ""
            ? ""
            : {
              value: companyByIdData?.billing_details?.[0]?.zipcode_id,
              label: companyByIdData?.billing_details?.[0]?.city,
            }
        );
        setValue("bInfo_city", companyByIdData?.billing_details?.[0]?.city);
        setValue(
          "invoice_email",
          companyByIdData?.billing_details?.[0]?.invoice_email
        );
        setValue(
          "attention_person",
          companyByIdData?.billing_details?.[0]?.attention_person_name
        );

        setIsSameDetailAsAboveActive(
          companyByIdData?.is_same_detail_as_defined_general == 0 ? false : true
        );
        setValue(
          "is_same_detail_as_defined_general",
          companyByIdData?.is_same_detail_as_defined_general == 0 ? false : true
        );

        setValue(
          "billing_method_applicable_id",
          companyByIdData?.billing_method_applicable_id
            ? {
              value: companyByIdData?.billing_method_applicable_id,
              label: billingDetailsDropdownData?.filter(
                (data) =>
                  data?.Id?.toString() ===
                  companyByIdData?.billing_method_applicable_id?.toString()
              )?.[0]?.Text,
            }
            : ""
        );

        setValue("created_on", companyByIdData?.created_on);
        setValue("created_by", companyByIdData?.created_by);
      }
    }
  }, [GetCompanyByCompanyIdData]);

  useEffect(() => {
    if (
      getValues("cvr_number")?.length ||
      getValues("vat_number")?.length ||
      getValues("gst_number")?.length
    ) {
      clearErrors(["cvr_number", "vat_number", "gst_number"]);
    }
    if (
      getValues("bInfo_cvr_number")?.length ||
      getValues("bInfo_vat_number")?.length ||
      getValues("bInfo_gst_number")?.length
    ) {
      clearErrors(["bInfo_cvr_number", "bInfo_vat_number", "bInfo_gst_number"]);
    }
  }, [
    watch("cvr_number"),
    watch("vat_number"),
    watch("gst_number"),
    watch("bInfo_cvr_number"),
    watch("bInfo_vat_number"),
    watch("bInfo_gst_number"),
  ]);

  const onFormSubmit = async (data) => {
    let id;

    const editCompany = {
      company_id: GetCompanyByCompanyIdData?.data?.[0]?.company_id?.toString(),
      name: capitalizeEachWord(data?.company_name),
      shortname: data?.short_name?.toUpperCase(),
      care_of: capitalizeEachWord(data?.care_of),
      public_type_id1: "1",
      public_id1: data?.vat_number,
      public_type_id2: "1",
      public_id2: data?.gst_number,
      public_type_id3: "1",
      public_id3: data?.cvr_number,
      street_name: data?.street_name,
      house_number: data?.house_number,
      floor_id: data?.floor_id?.value?.toString(),
      side_id: data?.side_id?.value?.toString() ?? "",
      door: data?.door,
      zipcode_id: data?.zipcode_id?.value || "",
      country_id: data?.country?.value || "",
      website_url: data?.website_url || "",
      contact_person_name: capitalizeEachWord(data?.contact_person_name),
      contact_person_email: data?.contact_person_email?.toLowerCase(),
      contact_person_isd_code: data?.country_code,
      contact_person_phone_no: data?.phone,
      is_same_detail_as_defined_general: isSameDetailAsAboveActive ? "1" : "0",
      billing_method_applicable_id:
        data?.billing_method_applicable_id?.value || "",
      website_url: data?.website,

      billing_details: {
        company_name: capitalizeEachWord(data?.bInfo_company),
        care_of: capitalizeEachWord(data?.bInfo_care_of),
        street_name: data?.bInfo_street,
        house_number: data?.bInfo_house_number,
        door: data?.bInfo_door,
        floor_id: data?.bInfo_floor_number?.value?.toString() || "",
        side_id: data?.bInfo_side?.value?.toString() || "",
        zipcode_id: data?.bInfo_zipCode?.value || "",
        country_id: data?.bInfo_country?.value,
        public_id1: data?.bInfo_vat_number,
        public_id2: data?.bInfo_gst_number,
        public_id3: data?.bInfo_cvr_number,
        public_type_id1: "1",
        public_type_id2: "1",
        public_type_id3: "1",
        ean_number: data?.ean_number,
        invoice_email: data?.invoice_email?.toLowerCase(),
        attention_person: capitalizeEachWord(data?.attention_person),
      },
    };

    if (!IsLoadingUpdateCompany) {
      id = toast.loading(<LoadingAlert />);
    }
    try {
      const response = await updateCompany(editCompany, {onSuccess:()=>{
        queryClient.invalidateQueries("qGetCompanyOverviewByCompanyId")
      }});
      if (response.status === status.s && response.data.bReturn) {
        toast.update(id, {
          render: <SucessAlert />,
          isLoading: false,
          type: status.s,
          autoClose: 2000,
        });
        GetCompanyByLevelDataRefetch();
      } else {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.update(id, {
        render: <ErrorAlert />,
        isLoading: false,
        type: status.e,
        autoClose: 2000,
      });
    }
  };

  //------Handle street field change--------//
  const handleStreetFieldChange = (event) => {
    if (watch("country")?.value == "DK" && event.target.value?.length >= 4) {
      setIsSearchStreetVisible(true);
      setSearchedStreetName(event?.target?.value);
    } else {
      setIsSearchStreetVisible(false);
    }
  };

  //-------Handle street detail click-------// (Dropdown will be visible only when selected country is Denmark)
  const handleStreetDetailClick = (item, e) => {
    setIsSearchStreetVisible(false);
    setValue("street_name", item?.street_name);
    setValue("city", item?.city, { shouldValidate: item?.city !== "" && true });
    setValue("house_number", item?.house_number, {
      shouldValidate: item?.house_number !== "" && true,
    });
    setValue(
      "zipcode_id",
      item?.zipcode == ""
        ? ""
        : {
          value: item?.zipcode,
          label: zipCodeByCountryIdData?.data?.filter(
            (el) => el.Id == item?.zipcode
          )?.[0]?.Text,
        },
      { shouldValidate: item?.zipcode !== "" && true }
    );
  };

  // selecting dropdown option
  const displayItem = (selected) => {
    return selected
      ? {
        value: selected?.Id,
        label: countryData?.data?.filter(
          (item, i) => item.Id === selected?.value
        )?.[0]?.Text,
      }
      : "";
  };

  // --------------------------handle zipcode change-----------------------//
  const handleZipCode = (e, ActionType) => {
    if (ActionType.action === "clear") {
      setValue("city", "");
    } else {
      if (e?.value != "" || e?.value != "undefined") {
        const HandleCityName = zipCodeByCountryIdData?.data?.filter(
          (data) => data?.Id == e?.value
        );
        const CityData = HandleCityName[0].Text;
        setValue("city", CityData);
      }
    }
  };

  //-------Handle street detail click for billing information-------// (Dropdown will be visible only when selected country is Denmark)
  const handleInfoStreetDetailClick = (item, e) => {
    setIsSearchInfoStreetVisible(false);
    setValue("bInfo_street_name", item?.street_name);
    setValue("bInfo_city", item?.city, {
      shouldValidate: item?.city !== "" && true,
    });
    setValue("bInfo_house_number", item?.house_number, {
      shouldValidate: item?.house_number !== "" && true,
    });
    setValue(
      "bInfo_zipCode",
      item?.zipcode == ""
        ? ""
        : {
          value: item?.zipcode,
          label: zipCodeByCountryIdData2?.data?.filter(
            (el) => el.Id == item?.zipcode
          )?.[0]?.Text,
        },
      { shouldValidate: item?.zipcode !== "" && true }
    );
    handleInputChange()
  };

  //------Handle billing info street field change--------//
  const handleInfoStreetFieldChange = (event) => {
    if (
      watch("bInfo_country")?.value == "DK" &&
      event.target.value?.length >= 4
    ) {
      setIsSearchInfoStreetVisible(true);
      setSearchedStreetName(event?.target?.value);
    } else {
      setIsSearchInfoStreetVisible(false);
    }
  };

  // --------------------------handle billing zipcode change-----------------------//
  const handleBillingZipCode = (e, ActionType) => {
    if (ActionType.action === "clear") {
      setValue("bInfo_city", "");
    } else {
      if (e?.value != "" || e?.value != "undefined") {
        const HandleCityName = zipCodeByCountryIdData2?.data?.filter(
          (data) => data?.Id == e?.value
        );
        const CityData = HandleCityName[0].Text;
        setValue("bInfo_city", CityData);
      }
    }
  };
  // --------------------------handle Set Same Detail As Above-----------------------//
  const handleSetSameDetailAsAbove = (e) => {
    setIsSameDetailAsAboveActive(e.target.checked);
    setValue("is_same_detail_as_defined_general", e.target.checked);
    if (e.target.checked) {
      setValue("bInfo_company", getValues("company_name"), {
        shouldValidate: true,
      });
      setValue("bInfo_cvr_number", getValues("cvr_number"));
      setValue("bInfo_vat_number", getValues("vat_number"));
      setValue("bInfo_gst_number", getValues("gst_number"));
      setValue("bInfo_care_of", getValues("care_of"), {
        shouldValidate: true,
      });
      setValue("bInfo_street_name", getValues("street_name"), {
        shouldValidate: true,
      });
      setValue("bInfo_house_number", getValues("house_number"), {
        shouldValidate: true,
      });
      setValue(
        "bInfo_floor_number",
        {
          value: getValues("floor_id")?.value,
          label: getValues("floor_id")?.label,
        },
        { shouldValidate: true }
      );
      setValue(
        "bInfo_side",
        {
          value: getValues("side_id")?.value,
          label: getValues("side_id")?.label,
        },
        { shouldValidate: true }
      );
      setValue("bInfo_door", getValues("door"), { shouldValidate: true });
      setValue(
        "bInfo_country",
        {
          value: getValues("country")?.value,
          label: getValues("country")?.label,
        },
        { shouldValidate: true }
      );
      setValue(
        "bInfo_zipCode",
        {
          value: getValues("zipcode_id")?.value,
          label: getValues("zipcode_id")?.label,
        },
        { shouldValidate: true }
      );
      setValue("bInfo_city", getValues("city"), { shouldValidate: true });
    } else {
      const companyByIdData = GetCompanyByCompanyIdData?.data?.[0];

      setValue("bInfo_company", "");
      setValue("bInfo_cvr_number", "");
      setValue("bInfo_vat_number", "");
      setValue("bInfo_gst_number", "");
      setValue("bInfo_care_of", "");
      setValue("bInfo_company_co", "");
      setValue("ean_number", companyByIdData?.billing_details?.[0]?.ean);
      setValue("bInfo_street_name", "");
      setValue("bInfo_house_number", "");
      setValue("bInfo_floor_number", "");
      setValue("bInfo_side", "");
      setValue("bInfo_door", "");
      setValue("bInfo_country", "");
      setValue("bInfo_zipCode", "");
      setValue("bInfo_city", "");
    }
  };
  //==========================Form Auto Save functionality===========================//
  const handleAutoSave = () => {
    handleSubmit(onFormSubmit)();
  };
  const handleInputChange = () => {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    const newTimeOutId = setTimeout(() => {
      handleAutoSave();
    }, 3000);
    setTimeOutId(newTimeOutId);
  };
  return (
    <Container
      fluid
      style={{ backgroundColor: "#F9FAFA" }}
      className="pt-1 px-0"
    >
      {buttonType == "ADD" && (
        <div>
          <div className="pt-3">
            <H1 className="mb-0">
              {" "}
              {buttonType == "ADD" ? "Create new company " : "Edit company"}
            </H1>
          </div>
          <div
            className="mt-0 pb-4 light-font-text"
            style={{ cursor: "pointer" }}
          >
            <span className="mt-1 " onClick={() => setShowCompanyPage("CL")}>
              Company
            </span>

            <span className="px-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <GreenSpan>
              {" "}
              {buttonType == "ADD"
                ? "Create new company "
                : "Edit company"}{" "}
            </GreenSpan>
          </div>
        </div>
      )}
      <div
        // style={{ backgroundColor: "#F1F5F0" }}
        className="p-md-4 p-1 mb-4  radius-8 containerStyle"
      >
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            border: "1px solid #F4F5F5",
            fontSize: "14px",
          }}
        >
          <div className="d-flex align-items-center mt-3 ">
            <H2 className="  ps-4 pe-2 ">
              {buttonType == "ADD" ? "Create new company" : "Details"}
            </H2>
            <div style={{ marginTop: "2px" }}>
              <CustomToolTip
                height={12}
                text={buttonType == "ADD" ? "Create new company" : "Details"}
              />
            </div>
          </div>

          <hr className="mb-0" />
          <Form onSubmit={handleSubmit(onFormSubmit)}>
            {/* Organisation details */}
            <div className="form-label-title mt-3 mb-3 ps-4">
              Company details
            </div>

            <div className="px-4">
              <Row>
                <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
                  <Controller
                    control={control}
                    {...register("company_name")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"company_name"}
                        type="text"
                        textTransform="capitalize"
                        placeholder="Company"
                        errorscope={true}
                        value={value}
                        iserror={errors?.company_name}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("company_name");
                          handleInputChange()
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                  <Controller
                    control={control}
                    {...register("care_of")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"C/O"}
                        value={value}
                        type="text"
                        textTransform="capitalize"
                        placeholder="C/O"
                        errorscope
                        maxLength={30}
                        iserror={errors?.care_of}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("care_of");
                          handleInputChange()
                        }}
                      />
                    )}
                  />
                </Col>
                {/* <Col xl={3} lg={4} md={6} className="pt-2 pe-0">
                  <Controller
                    {...register("tax_number")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <InputField
                          id={"tax_number"}
                          type="text"
                          placeholder="Tax number"
                          errorscope={true}
                          value={value}
                          iserror={errors?.tax_number}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("tax_number");
                          }}
                        />
                      </div>
                    )}
                  />
                </Col> */}
                <Col className="created-by gap-3">
                  <div className="d-flex text-end justify-content-end">
                    <Col className="d-flex created-by gap-2 justify-content-end ">
                      <div className="d-flex flex-column text-end">
                        <div>Company created : </div>
                        <div>Created by :</div>
                      </div>
                      <div>
                        <div>
                          {getValues("created_on") === ""
                            ? "-"
                            : moment(getValues("created_on")).format(
                              "DD MMM, YYYY"
                            )}
                        </div>
                        <div> {getValues("created_by")}</div>
                      </div>
                    </Col>
                  </div>
                </Col>
              </Row>
              {/* vat number gst  number */}
              <Row>
                <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
                  <Controller
                    control={control}
                    {...register("cvr_number")}
                    rules={{
                      required: !(
                        watch("vat_number")?.length ||
                        watch("gst_number")?.length
                      ),
                    }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"cvr_number"}
                        value={value}
                        type="text"
                        placeholder="CVR Number"
                        errorscope
                        maxLength={30}
                        iserror={errors?.cvr_number}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("cvr_number");
                          handleInputChange()
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="pe-3 ps-lg-0 mb-sm-3">
                  <Controller
                    control={control}
                    {...register("vat_number")}
                    rules={{
                      required: !(
                        watch("cvr_number")?.length ||
                        watch("gst_number")?.length
                      ),
                    }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"vat_number"}
                        value={value}
                        type="text"
                        placeholder="VAT Number"
                        errorscope
                        maxLength={30}
                        iserror={errors?.vat_number}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("vat_number");
                          handleInputChange()
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="pe-lg-3 ps-lg-0  mb-sm-3">
                  <Controller
                    control={control}
                    {...register("gst_number")}
                    rules={{
                      required: !(
                        watch("vat_number")?.length ||
                        watch("cvr_number")?.length
                      ),
                    }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"gst_number"}
                        value={value}
                        type="text"
                        placeholder="GST Number"
                        errorscope
                        maxLength={30}
                        iserror={errors?.gst_number}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("gst_number");
                          handleInputChange()
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
              {/* country zipcode city */}
              <div>
                <Row>
                  <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-3 mb-sm-3">
                    <Controller
                      control={control}
                      {...register("country")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <ReactSelect
                            placeholder="Country"
                            clearable={false}
                            options={countryData?.data?.map((item) => ({
                              value: item?.Id,
                              label: (
                                <div
                                  className="d-flex align-items-center"
                                  style={{ fontFamily: "Cerebri sans" }}
                                >
                                  <div className="radius-4 me-2">
                                    <img
                                      src={item?.DataValue1}
                                      style={{
                                        width: "20px",
                                        marginRight: "10px",
                                      }}
                                      alt=""
                                    />
                                  </div>
                                  <div>
                                    <div className="fs-14 light-font-text ">
                                      {" "}
                                      {item?.Text}
                                    </div>
                                  </div>
                                </div>
                              ),
                            }))}
                            iserror={errors?.country}
                            value={value == undefined ? "" : displayItem(value)}
                            onChange={(e, ActionType) => {
                              onChange(e);
                              handleInputChange()
                              resetField("city");
                              resetField("zipcode_id");
                              resetField("street_name");
                              clearErrors("country");
                            }}
                          />
                        );
                      }}
                    />
                  </Col>
                  <Col
                    xl={3}
                    lg={4}
                    md={6}
                    className="pe-lg-3 ps-lg-0  mb-sm-3"
                  >
                    <Controller
                      control={control}
                      {...register("zipcode_id")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder="Zip code"
                          options={zipCodeByCountryIdData?.data?.map((zip) => ({
                            value: zip?.Id,
                            label: `${zip?.Id} ${zip?.Text}`,
                          }))}
                          clearable={false}
                          iserror={errors?.zipcode_id}
                          value={
                            value?.value
                              ? {
                                value: value?.value,
                                label: value?.value,
                              }
                              : ""
                          }
                          onChange={(e, ActionType) => {
                            handleZipCode(e, ActionType);
                            handleInputChange()

                            onChange(e);
                            clearErrors("zipcode_id");
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col
                    xl={3}
                    lg={4}
                    md={6}
                    className="pe-lg-3 ps-lg-0  mb-sm-3"
                  >
                    <Controller
                      control={control}
                      {...register("city")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          id={"city"}
                          value={value}
                          type="text"
                          placeholder="City"
                          errorscope
                          maxLength={30}
                          iserror={errors?.city}
                          disabled
                          removeOpacity
                          onChange={(e) => {
                            handleInputChange()
                            onChange(e);
                            clearErrors("city");
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>{" "}
              </div>
            </div>

            <div className="px-4">
              <Row>
                <Col
                  xl={3}
                  lg={4}
                  md={6}
                  className="pe-sm-3 mb-lg-0 mb-sm-3  position-relative"
                >
                  <StreetInput
                    isSearchStreetVisible={isSearchStreetVisible}
                    setIsSearchStreetVisible={setIsSearchStreetVisible}
                    handleStreetFieldChange={handleStreetFieldChange}
                    handleStreetDetailClick={handleStreetDetailClick}
                    control={control}
                    street_name={"street_name"}
                    errors={errors}
                    watch={watch}
                    searchedStreetName={searchedStreetName}
                    clearErrors={clearErrors}
                    country_name={"country"}
                  />
                </Col>
                <Col xl={2} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                  <Controller
                    {...register(`house_number`)}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <InputField
                          type={"text"}
                          placeholder={"No."}
                          value={value}
                          id={"houseNo"}
                          errorscope
                          maxLength={30}
                          iserror={errors?.house_number}
                          onChange={(e) => {
                            handleInputChange()
                            onChange(e);
                            clearErrors("house_number");
                          }}
                        />
                      </div>
                    )}
                  />
                </Col>
                <Col xl={2} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                  <Controller
                    control={control}
                    {...register("floor_id")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Floor"}
                        iserror={errors?.floor_id}
                        options={getOrganizationFloorTypeData?.data?.map(
                          (item) => ({
                            value: item?.Id,
                            label: item?.Text,
                            isDisabled: value?.value == item?.Id,
                          })
                        )}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleInputChange()
                          clearErrors("floor_id");
                        }}
                        clearable={false}
                      />
                    )}
                  />
                </Col>
                <Col
                  xl={2}
                  lg={4}
                  md={6}
                  className="pe-sm-3 ps-xl-0 mb-lg-0 mb-sm-3 "
                >
                  <Controller
                    control={control}
                    {...register("side_id")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Side"}
                        iserror={errors?.side_id}
                        options={getOrganizationSideTypeData?.data?.map(
                          (item) => ({
                            value: item?.Id,
                            label: item?.Text,
                            isDisabled: value?.value == item?.Id,
                          })
                        )}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleInputChange()
                          clearErrors("side_id");
                        }}
                        clearable={false}
                      />
                    )}
                  />
                </Col>
                <Col xl={2} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3 ">
                  <Controller
                    {...register(`door`)}
                    control={control}
                    // rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <InputField
                          type={"text"}
                          placeholder={"Door"}
                          id={"door"}
                          errorscope
                          value={value}
                          maxLength={30}
                          iserror={errors?.door}
                          onChange={(e) => {
                            onChange(e);
                            handleInputChange()
                            clearErrors("door");
                          }}
                        />
                      </div>
                    )}
                  />
                </Col>
              </Row>
            </div>

            <div className="px-4 border-bottom pb-4">
              <Row>
                <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
                  <Controller
                    {...register(`contact_person_name`)}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <InputField
                          type={"text"}
                          placeholder={"Contact Person"}
                          id={"ContactPerson"}
                          value={value}
                          textTransform="capitalize"
                          iserror={errors?.contact_person_name}
                          onChange={(e) => {
                            onChange(e);
                            handleInputChange()
                            clearErrors("contact_person_name");
                          }}
                        />
                      </div>
                    )}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="pe-lg-3 ps-lg-0  mb-sm-3">
                  <Controller
                    control={control}
                    {...register("contact_person_email")}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Please use a valid email address",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"contact_person_email"}
                        value={value}
                        type="text"
                        placeholder="Contact e-mail"
                        textTransform="lowercase"
                        errorscope
                        errormessage={
                          errors?.email?.type === "pattern"
                            ? errors?.email.message
                            : false
                        }
                        maxLength={30}
                        iserror={errors?.contact_person_email}
                        onChange={(e) => {
                          onChange(e);
                          handleInputChange()
                          clearErrors("contact_person_email");
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="pe-lg-3 ps-lg-0  mb-sm-3">
                  <SelectPhoneNumber
                    errors={errors}
                    setValue={setValue}
                    getPhoneId={"country_code"}
                    getPhoneNumber={"phone"}
                    control={control}
                    placeholder={"Phone"}
                    // getValues={getValues}
                    onChangeFun={handleInputChange}

                  />
                </Col>
              </Row>
              <div>
                <Row>
                  <Col lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
                    <Controller
                      control={control}
                      {...register("website")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          id={"website"}
                          value={value}
                          type="text"
                          placeholder="Website"
                          errorscope
                          maxLength={30}
                          iserror={errors?.website}
                          onChange={(e) => {
                            onChange(e);
                            handleInputChange()
                            clearErrors("website");
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            {/*--------- Billing details ------------*/}
            <div className="px-4 py-4">
              <div className=" form-label-title mb-3">Billing details</div>

              <Row className="">
                <Col xl={3} lg={4} md={6} className="pe-lg-0">
                  <Controller
                    control={control}
                    {...register("billing_method_applicable_id")}
                    // rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Billing method"}
                        clearable={false}
                        iserror={errors?.billing_method_applicable_id}
                        options={billingDetailsDropdownData
                          ?.filter((value) =>
                            GetBillingLevelDetailsData?.data?.[0]?.billing_level
                              ?.split(",")
                              ?.includes(value?.Id)
                          )
                          ?.map((item) => ({
                            value: item?.Id,
                            label: item?.Text,
                            isDisabled:
                              value?.value?.toString() === item?.Id?.toString(),
                          }))}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleInputChange()
                          clearErrors("billing_method_applicable_id");
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
            </div>
            {watch("billing_method_applicable_id")?.value?.toString() ===
              "C" ? (
              <>
                {/* Billing information */}
                <div className="px-4 pb-4">
                  <div className=" form-label-title">Billing information</div>
                  <div className="pt-3 d-flex align-items-center gap-3">
                    <Toggle
                      id={"sameDetailAsAbove"}
                      label={"Same details as above"}
                      checked={isSameDetailAsAboveActive}
                      onChange={(e) => {
                        handleSetSameDetailAsAbove(e)
                        handleInputChange()
                      }}
                    />{" "}
                    <span className="paragraph1-light">
                      Same as company details
                    </span>
                  </div>
                </div>
                {/* Billing information fields*/}
                <div className=" pb-4">
                  <div className="px-4">
                    <Row>
                      <Col
                        xl={3}
                        lg={4}
                        md={6}
                        className="pe-sm-3 mb-lg-0 mb-sm-3"
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_company")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <InputField
                                id={"bInfo_company"}
                                value={value}
                                type="text"
                                placeholder="Company"
                                textTransform="capitalize"
                                errorscope
                                disabled={isSameDetailAsAboveActive}
                                iserror={errors?.bInfo_company}
                                removeOpacity={!isSameDetailAsAboveActive}
                                onChange={(e) => {
                                  onChange(e);
                                  handleInputChange()
                                  clearErrors("bInfo_company");
                                }}
                              />
                            );
                          }}
                        />
                      </Col>
                      <Col
                        xl={3}
                        lg={4}
                        md={6}
                        className="pe-3 ps-lg-0  mb-sm-3"
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_care_of")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"C/O"}
                              value={value}
                              type="text"
                              placeholder="C/O"
                              textTransform="capitalize"
                              errorscope
                              maxLength={30}
                              disabled={isSameDetailAsAboveActive}
                              removeOpacity={!isSameDetailAsAboveActive}
                              iserror={errors?.bInfo_care_of}
                              onChange={(e) => {
                                onChange(e);
                                handleInputChange()
                                clearErrors("bInfo_care_of");
                              }}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xl={3}
                        lg={4}
                        md={6}
                        className="pe-sm-3 mb-lg-0 mb-sm-3"
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_cvr_number")}
                          rules={{
                            required: !(
                              watch("bInfo_vat_number")?.length ||
                              watch("bInfo_gst_number")?.length
                            ),
                          }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"bInfo_cvr_number"}
                              value={value}
                              type="text"
                              placeholder="CVR Number"
                              errorscope
                              maxLength={30}
                              removeOpacity={!isSameDetailAsAboveActive}
                              iserror={errors?.bInfo_cvr_number}
                              disabled={isSameDetailAsAboveActive}
                              onChange={(e) => {
                                onChange(e);
                                handleInputChange()
                                clearErrors("bInfo_cvr_number");
                              }}
                            />
                          )}
                        />
                      </Col>
                      <Col
                        xl={3}
                        lg={4}
                        md={6}
                        className="pe-3 ps-lg-0  mb-sm-3"
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_vat_number")}
                          rules={{
                            required: !(
                              watch("bInfo_cvr_number")?.length ||
                              watch("bInfo_gst_number")?.length
                            ),
                          }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"bInfo_vat_number"}
                              value={value}
                              type="text"
                              placeholder="VAT Number"
                              errorscope
                              maxLength={30}
                              removeOpacity={!isSameDetailAsAboveActive}
                              iserror={errors?.bInfo_vat_number}
                              disabled={isSameDetailAsAboveActive}
                              onChange={(e) => {
                                onChange(e);
                                handleInputChange()
                                clearErrors("bInfo_vat_number");
                              }}
                            />
                          )}
                        />
                      </Col>
                      <Col
                        xl={3}
                        lg={4}
                        md={6}
                        className="pe-lg-3 ps-lg-0  mb-sm-3"
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_gst_number")}
                          rules={{
                            required: !(
                              watch("bInfo_cvr_number")?.length ||
                              watch("bInfo_vat_number")?.length
                            ),
                          }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"bInfo_gst_number"}
                              value={value}
                              type="text"
                              placeholder="GST Number"
                              errorscope
                              maxLength={30}
                              iserror={errors?.bInfo_gst_number}
                              removeOpacity={!isSameDetailAsAboveActive}
                              disabled={isSameDetailAsAboveActive}
                              onChange={(e) => {
                                onChange(e);
                                handleInputChange()
                                clearErrors("bInfo_gst_number");
                              }}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 ">
                    <Row>
                      <Col
                        xl={3}
                        lg={4}
                        md={6}
                        className="pe-sm-3 mb-lg-3 mb-sm-3"
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_country")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <ReactSelect
                              disabled={isSameDetailAsAboveActive}
                              placeholder="Country"
                              iserror={errors?.bInfo_country}
                              errorscope
                              clearable={false}
                              options={countryData?.data?.map((item) => ({
                                value: item?.Id,
                                label: (
                                  <div
                                    className="d-flex align-items-center"
                                    style={{ fontFamily: "Cerebri sans" }}
                                  >
                                    <div className="radius-4 me-2">
                                      <img
                                        src={item?.DataValue1}
                                        style={{
                                          width: "20px",
                                          marginRight: "10px",
                                        }}
                                        alt=""
                                      />
                                    </div>
                                    <div>
                                      <div className="fs-14 light-font-text ">
                                        {" "}
                                        {item?.Text}
                                      </div>
                                    </div>
                                  </div>
                                ),
                              }))}
                              value={
                                value == undefined ? "" : displayItem(value)
                              }
                              onChange={(e, ActionType) => {
                                onChange(e);
                                resetField("bInfo_zipCode");
                                resetField("bInfo_city");
                                resetField("bInfo_street_name");
                                clearErrors("bInfo_country");
                                handleInputChange()
                              }}
                            />
                          )}
                        />
                      </Col>
                      <Col
                        xl={3}
                        lg={4}
                        md={6}
                        className="pe-lg-3 ps-lg-0  mb-sm-3 "
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_zipCode")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <ReactSelect
                              disabled={isSameDetailAsAboveActive}
                              placeholder="Zip code"
                              clearable={false}
                              iserror={errors?.bInfo_zipCode}
                              errorscope
                              options={zipCodeByCountryIdData2?.data?.map(
                                (zip) => ({
                                  value: zip?.Id,
                                  label: `${zip?.Id} ${zip?.Text}`,
                                })
                              )}
                              value={
                                value?.value
                                  ? {
                                    value: value?.value,
                                    label: value?.value,
                                  }
                                  : ""
                              }
                              onChange={(e, ActionType) => {
                                handleBillingZipCode(e, ActionType);
                                onChange(e);
                                clearErrors("bInfo_zipCode");
                              }}
                            />
                          )}
                        />
                      </Col>
                      <Col
                        xl={3}
                        lg={4}
                        md={6}
                        className="pe-lg-3 ps-lg-0  mb-sm-3 "
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_city")}
                          rules={{ required: false }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"bInfo_city"}
                              value={value}
                              type="text"
                              placeholder="City"
                              maxLength={30}
                              disabled
                              removeOpacity={!isSameDetailAsAboveActive}
                              onChange={(e) => {
                                onChange(e);
                                handleInputChange()
                                clearErrors("bInfo_city");
                              }}
                            />
                          )}
                        />
                      </Col>
                    </Row>{" "}
                  </div>
                  <div className="px-4  ">
                    <Row>
                      <Col
                        xl={3}
                        lg={4}
                        md={6}
                        className="pe-sm-3 mb-lg-0 mb-sm-3  position-relative"
                      >
                        <StreetInput
                          isSearchStreetVisible={isSearchInfoStreetVisible}
                          setIsSearchStreetVisible={
                            setIsSearchInfoStreetVisible
                          }
                          handleStreetFieldChange={handleInfoStreetFieldChange}
                          handleStreetDetailClick={handleInfoStreetDetailClick}
                          control={control}
                          disabledValue={isSameDetailAsAboveActive}
                          removeOpacity={!isSameDetailAsAboveActive}
                          street_name={"bInfo_street_name"}
                          errors={errors}
                          watch={watch}
                          searchedStreetName={searchedStreetName}
                          clearErrors={clearErrors}
                          country_name={"bInfo_country"}
                        />
                      </Col>
                      <Col
                        xl={2}
                        lg={4}
                        md={6}
                        className="pe-3 ps-lg-0  mb-sm-3"
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_house_number")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"bInfo_house_number"}
                              value={value}
                              type="text"
                              placeholder="No."
                              errorscope
                              maxLength={30}
                              removeOpacity={!isSameDetailAsAboveActive}
                              iserror={errors?.bInfo_house_number}
                              disabled={isSameDetailAsAboveActive}
                              onChange={(e) => {
                                onChange(e);
                                handleInputChange()
                                clearErrors("bInfo_house_number");
                              }}
                            />
                          )}
                        />
                      </Col>
                      <Col
                        xl={2}
                        lg={4}
                        md={6}
                        className="pe-3 ps-lg-0  mb-sm-3"
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_floor_number")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <ReactSelect
                              placeholder={"Floor"}
                              iserror={errors?.bInfo_floor_number}
                              options={getOrganizationFloorTypeData?.data?.map(
                                (item) => ({
                                  value: item?.Id,
                                  label: item?.Text,
                                  isDisabled: value?.value == item?.Id,
                                })
                              )}
                              disabled={isSameDetailAsAboveActive}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                handleInputChange()
                                clearErrors("bInfo_floor_number");
                              }}
                              clearable={false}
                            />
                          )}
                        />
                      </Col>
                      <Col
                        xl={2}
                        lg={4}
                        md={6}
                        className="pe-sm-3 ps-xl-0 mb-lg-0 mb-sm-3 "
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_side")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <ReactSelect
                              placeholder={"Side"}
                              iserror={errors?.bInfo_side}
                              disabled={isSameDetailAsAboveActive}
                              options={getOrganizationSideTypeData?.data?.map(
                                (item) => ({
                                  value: item?.Id,
                                  label: item?.Text,
                                  isDisabled: value?.value == item?.Id,
                                })
                              )}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                handleInputChange()
                                clearErrors("bInfo_side");
                              }}
                              clearable={false}
                            />
                          )}
                        />
                      </Col>
                      <Col
                        xl={2}
                        lg={4}
                        md={6}
                        className="pe-3 ps-lg-0  mb-sm-3  "
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_door")}
                          rules={{ required: false }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"bInfo_door"}
                              value={value}
                              type="text"
                              placeholder="Door"
                              errorscope
                              maxLength={30}
                              iserror={errors?.bInfo_door}
                              disabled={isSameDetailAsAboveActive}
                              onChange={(e) => {
                                onChange(e);
                                handleInputChange()
                                clearErrors("bInfo_door");
                              }}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="px-4 ">
                    <Row>
                      <Col
                        xl={4}
                        lg={4}
                        md={6}
                        className="pe-sm-3 mb-lg-0 mb-sm-3 "
                      >
                        <Controller
                          control={control}
                          {...register("invoice_email")}
                          rules={{
                            required: "Email is required",
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: "Please use a valid email address",
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"invoice_email"}
                              value={value}
                              type="email"
                              placeholder="Invoice e-mail"
                              textTransform="lowercase"
                              errormessage={
                                errors?.email?.type === "pattern"
                                  ? errors?.email.message
                                  : false
                              }
                              errorscope
                              maxLength={30}
                              iserror={errors?.invoice_email}
                              onChange={(e) => {
                                onChange(e);
                                handleInputChange()
                                clearErrors("invoice_email");
                              }}
                            />
                          )}
                        />
                      </Col>
                      <Col
                        xl={4}
                        lg={4}
                        md={6}
                        className="pe-lg-3 ps-lg-0  mb-sm-3 "
                      >
                        <Controller
                          control={control}
                          {...register("attention_person")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"attention_person"}
                              value={value}
                              type="text"
                              placeholder="Attention person"
                              errorscope
                              textTransform="capitalize"
                              maxLength={30}
                              iserror={errors?.attention_person}
                              onChange={(e) => {
                                onChange(e);
                                handleInputChange()
                                clearErrors("attention_person");
                              }}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </>
            ) : (
              <div className="ps-4 pb-4 light-font-text">
                {watch("billing_method_applicable_id")?.value ? (
                  <>
                    As the billing method is chosen to be at{" "}
                    {watch("billing_method_applicable_id")?.value === "D"
                      ? "Division"
                      : watch("billing_method_applicable_id")?.value === "C"
                        ? "Company"
                        : watch("billing_method_applicable_id")?.value === "P"
                          ? "Property"
                          : "Organisation"}{" "}
                    level, billing details must be filled in at{" "}
                    {watch("billing_method_applicable_id")?.value === "D"
                      ? "Division"
                      : watch("billing_method_applicable_id")?.value === "C"
                        ? "Company"
                        : watch("billing_method_applicable_id")?.value === "P"
                          ? "Property"
                          : "Organisation"}{" "}
                    level
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}

            <div className="mt-3 py-2 px-3 d-sm-flex justify-content-between footer-section-button-container">
              <div className="m-1">
                {buttonType == "ADD" && (
                  <ETButton
                    variant={"white"}
                    buttonText="CANCEL"
                    onClick={() => {
                      setButtonType("UPDATE");
                      setShowCompanyPage("CL");
                    }}
                  />
                )}
              </div>
              <div className="m-1">
                {buttonType == "ADD" && (<ETButton
                  variant={"primary"}
                  disabled={IsLoadingUpdateCompany}
                  buttonText={buttonType == "ADD" ? "CREATE COMPANY" : "SAVE"}
                  type="submit"
                />)}

              </div>
            </div>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default AddCompany;
