//This Screen is used for add private event and Follow-up private event.

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { GreenSpan, H2 } from "../../../styles/Common.style";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import ETButton from "../../../Components/Buttons/ETButton";
// import DetailAboutEvent from "../DetailAboutEvent";
import { usePrivateViewingStore } from "../../../hooks/stores/usePrivateViewingStore";
import Publishevent from "../Publishevent";
import EventsUnitsTable from "../EventsUnitsTable";
import TimeOfEvents from "../TimeOfEvents";
import AssignAgents from "../AssignAgents";
import AddParticipantsTable from "../AddParticipantsTable";
import AddParticipantsPopup from "./AddParticipantsPopup";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { Col, Row } from "react-bootstrap";
import { TabsButtonIcon } from "../../../Components/Tabs/Tabs.style";
import Toggle from "../../../Components/Switch/Switch";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import CustomPopup from "../../../Components/CustomPopup";
import { Link } from "react-router-dom";
import { H1 } from "../../../styles/Common.style";

const CustomToolbar = ({ label, date, onNavigate }) => {
  const handleNext = () => {
    // Custom logic for the next button
    // For example, you can navigate to the next month
    onNavigate("next", moment(date).add(1, "month"));
  };

  const handlePrev = () => {
    // Custom logic for the back button
    // For example, you can navigate to the previous month
    onNavigate("prev", moment(date).subtract(1, "month"));
  };

  return (
    <div className="rbc-toolbar ">
      <button onClick={handlePrev}>
        <FontAwesomeIcon
          icon="fa-regular fa-angle-left"
          style={{ color: "#000000" }}
        />
      </button>
      <span className="rbc-toolbar-label ">{label}</span>
      <button onClick={handleNext}>
        <FontAwesomeIcon
          icon="fa-regular fa-angle-right"
          style={{ color: "#000000" }}
        />
      </button>
    </div>
  );
};

const CreateNewPrivateEvent = ({
  setShowPrivateEvent,
  setShowCreatePrivateEvent,
  propertyOverview,
}) => {
  const { setShowPrivateEventPage, showPrivateEventPage } =
    usePrivateViewingStore((state) => state);

  const [publishPrivateEvent, setPublishPrivateEvent] = useState(false);
  const [openAddParticipantsPopup, setopenAddParticipantsPopup] =
    useState(false);

  const localizer = momentLocalizer(moment);
  // console.log(localizer);
  const calendarConfig = {
    components: {
      // event: CustomEvent,
      toolbar: CustomToolbar,
      // eventWrapper: CustomEventWrapper,
    },
  };
  return (
    <div>
      {" "}
      <div className="pt-3 ">
        <H1>Mads Johnson and Caroline Johnson - Private viewing</H1>
        <div
          className="light-font-text"
          style={{
            cursor: "pointer",
          }}
        >
          <Link
            to="/properties"
            style={{ textDecoration: "none", color: "black" }}
          >
            <span className="me-3">Property</span>
          </Link>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <Link
            to="/property-units/overview"
            state={{
              Tab: "Overview",
            }}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span className="me-3">
              {propertyOverview?.[0]?.propertyName
                ? propertyOverview?.[0]?.propertyName
                : "propertyName"}
            </span>
          </Link>

          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <span className="me-3">Events</span>

          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <GreenSpan className="me-3 ">
            Mads Johnson and Caroline Johnson - Private viewing
          </GreenSpan>
        </div>
      </div>{" "}
      <div className="open-house-container p-4 mt-4 mb-4 radius-8">
        <div className="bg-white radius-8">
          <div className="d-flex justify-content-between px-4 py-3">
            <div className="d-flex flex-column ">
              <H2 className="d-flex">
                Mads Johnson and Caroline Johnson - Private viewing
                <span className="ms-2">
                  <CustomToolTip
                    text="Mads Johnson and Caroline Johnson - Private viewing"
                    height={12}
                  />
                </span>
              </H2>
            </div>
          </div>
          <hr className="my-0"></hr>

          {/* <DetailAboutEvent showPrivateEventPage={showPrivateEventPage} /> */}
          <div className="p-4">
            <div className="event-section-title">Details about the event</div>
            <div className="light-font-text py-2 grey-text">
              Add information about the event and remember to set the options if
              you need them
            </div>
            {/* <Form onSubmit={handleSubmit(onFormSubmit)}> */}
            <Row>
              <Col xl={9} lg={8} className="pt-2">
                <Row className="pb-3">
                  <Col lg={6}>
                    <div className="form-input ">
                      <input
                        type="text"
                        placeholder="Name of event"
                        id="nameofEvent"
                        maxLength={30}
                      />
                      <label htmlFor="nameofEvent">Name of event</label>
                    </div>
                  </Col>
                  <Col
                    lg={6}
                    xl={4}
                    className="my-2 my-lg-0 ps-lg-0 d-flex align-items-end"
                  >
                    <GreenSpan className="fs-12 light-font-text  font-medium">
                      Use address from previous event
                    </GreenSpan>
                  </Col>
                </Row>

                <Row className="pb-3">
                  <Col lg={6}>
                    <div className="form-input ">
                      <input
                        type="text"
                        placeholder="Street"
                        id="streetName"
                        maxLength={30}
                      />
                      <label htmlFor="streetName">Street</label>
                    </div>
                  </Col>
                  <Col xl={3} lg={6}>
                    <Row>
                      <Col className="my-2 my-lg-0 ps-lg-0">
                        <div className="form-input ">
                          <input
                            type="text"
                            placeholder="No"
                            id="no"
                            maxLength={30}
                          />
                          <label htmlFor="no">No</label>
                        </div>
                      </Col>
                      <Col className="my-2 my-lg-0 ps-lg-0">
                        <div className="form-input ">
                          <input
                            type="text"
                            placeholder="Add Info"
                            id="addInfo"
                            maxLength={30}
                          />
                          <label htmlFor="addInfo">Add Info</label>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="pb-3">
                  <Col xl={3} lg={4} className="my-2 my-lg-0">
                    <ReactSelect placeholder="Country" clearable={false} />
                  </Col>
                  <Col xl={3} lg={4} className="my-2 my-lg-0 ps-0 ">
                    <ReactSelect placeholder="Zip code" />
                  </Col>
                  <Col xl={3} lg={4} className="ps-lg-0 ps-lg-0">
                    <div className="form-input ">
                      <input
                        type="text"
                        disabled
                        placeholder="City"
                        id="city"
                      />
                      <label htmlFor="city">City</label>
                    </div>
                  </Col>
                </Row>

                <Row className="pb-3">
                  <Col xl={3} lg={4}>
                    <div className="form-input ">
                      <input type="text" placeholder="Latitude" id="latitude" />
                      <label htmlFor="latitude">Latitude</label>
                    </div>
                  </Col>
                  <Col xl={3} lg={4} className="my-2 my-lg-0 ps-lg-0">
                    <div className="form-input ">
                      <input
                        type="text"
                        placeholder="Longitude"
                        id="longitude"
                      />
                      <label htmlFor="longitude">Longitude</label>
                    </div>
                  </Col>
                  <Col xl={3} lg={4}>
                    <div className=" px-xl-2  my-2 ">
                      <span className="d-flex align-items-center">
                        <TabsButtonIcon>
                          <FontAwesomeIcon
                            size="sm"
                            icon="fa-solid fa-location-dot"
                            // onClick={() => getCoordinatesByInfoRefetch()}
                          />
                        </TabsButtonIcon>
                        <GreenSpan className="ms-1 fs-12 font-medium">
                          Change coordinates
                        </GreenSpan>
                      </span>
                    </div>
                  </Col>
                </Row>

                <Row className="pb-3">
                  <Col xl={6} lg={8}>
                    <div className="form-input ">
                      <input
                        type="text"
                        placeholder="Detailed information about the event"
                        id="detailedInfo"
                        maxLength={30}
                      />
                      <label htmlFor="detailedInfo">
                        Detailed information about the event
                      </label>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} lg={8}>
                    <ReactSelect
                      placeholder="Responsible agent"
                      clearable={false}
                      isSearchable
                    />
                  </Col>
                </Row>

                {/* {showPrivateEventPage && ( */}
                <Row>
                  <Col lg={10} className="pe-lg-0 mt-2 mb-sm-3 mb-lg-0">
                    <div className="light-font-text d-flex align-items-center">
                      {" "}
                      Send booking confirmation to the participant{" "}
                      <Toggle id={"Id"} />
                    </div>
                  </Col>
                </Row>
                {/* )} */}
              </Col>
            </Row>
          </div>

          <hr className="my-0"></hr>
          <div>
            <>
              <div className="px-4 py-3 my-4">
                <div className="event-section-title">Internal note</div>
                <div className="light-font-text py-2 grey-text">
                  Add internal note only your colleagues can see
                </div>

                <div>
                  <div className="form-float openhouse-des">
                    <textarea
                      name=""
                      class="inputText"
                      cols="20"
                      rows="4"
                      placeholder=" "
                      //   value={altText}
                      //   onChange={(e) => {
                      //     setAltText(e.target.value);
                      //   }}
                    ></textarea>
                    <label className="floating-label">Internal note</label>
                  </div>
                </div>
              </div>
            </>
          </div>
          <hr className="m-0" />
          <AddParticipantsTable
            setopenAddParticipantsPopup={setopenAddParticipantsPopup}
          />
          <hr className="m-0" />

          <div>
            <EventsUnitsTable />
          </div>
          {/* <TimeOfEvents showPrivateEventPage={showPrivateEventPage} /> */}
          <div
            className="px-4 py-4"
            style={{
              borderTop: "1px solid #f0f0f0",
            }}
          >
            <div className="header-semibold mb-2"> Time of event</div>
            <div className=" light-font-te`xt mb-2 grey-text">
              {" "}
              Please provide details you'd like to incorporate into the event to
              create a more personalized and engaging experience.
            </div>
            <Row className="mt-4">
              {" "}
              <Col lg={6}>
                <div className="form-label-title grey-text"> Date and time</div>{" "}
              </Col>{" "}
              {/* <Col className="ps-lg-5" lg={6}>
                {" "}
                <div className="form-label-title grey-text">
                  {" "}
                  Repetition
                </div>{" "}
              </Col> */}
            </Row>
            <Row className="mt-2">
              <Col lg={6} className="pe-lg-5">
                <Row>
                  <Col lg={4} className="pe-lg-0">
                    {" "}
                    <div className="form-input-group ">
                      <input
                        type="date"
                        id="eventStartingDate"
                        placeholder="eventStartingDate"
                      />
                      <label htmlFor="eventStartingDate">Starting date</label>
                    </div>
                  </Col>
                  <Col lg={4} className="pe-lg-0">
                    <div className="form-input-group ">
                      <input type="time" id="timeTo" placeholder="target" />
                    </div>

                    {/* <ReactSelect placeholder={"Starting time"} /> */}
                  </Col>
                  <Col lg={4} className="pe-lg-0">
                    <div className="form-input-group ">
                      <input type="time" id="timeFrom" />
                    </div>

                    {/* <ReactSelect placeholder={"End time"} /> */}
                  </Col>
                </Row>
              </Col>
              {/* <Col className="ps-lg-5" lg={6}>
                  <Row>
                    <Col lg={4} className="pe-lg-0">
                      <ReactSelect placeholder={"Events "} clearable={true} />
                    </Col>
                    <Col lg={4} className="pe-lg-0">
                      <ReactSelect
                        // value={selectedValue}
                        onChange={handleSelectChange}
                        clearable={false}
                        placeholder={"End event"}
                        options={eventEndAfterdata?.map((item) => ({
                          value: item?.Id,
                          label: item?.Label,
                        }))}
                      />
                    </Col>
                    {selectedValue && (
                      <Col lg={4} className="">
                        {selectedValue === 1 && (
                          <div className="form-input-group ">
                            <input type="date" id="date" />
                            <label htmlFor="date">Date</label>
                          </div>
                        )}
                        {selectedValue === 2 && (
                          <div className="form-input">
                            <input
                              type={"number"}
                              placeholder={"Days"}
                              id={`Days`}
                            />
                            <label htmlFor="Days">Days</label>
                          </div>
                        )}
                      </Col>
                    )}
                  </Row>
                </Col> */}
            </Row>
            <Row>
              <Col className="py-4 open-house-event-calendar">
                <div className="py-4 px-4 radius-8">
                  <Calendar
                    localizer={localizer}
                    // events={myEventsList}
                    toolbar={(toolbarProps) => (
                      <CustomToolbar {...toolbarProps} />
                    )}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    components={calendarConfig.components}
                  />
                </div>
              </Col>
            </Row>
          </div>

          <hr className="mt-0"></hr>
          <AssignAgents />
          <div></div>
          <div className="mt-5 py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            {" "}
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              // type={"button"}
              onClick={() => {
                setShowCreatePrivateEvent(false);
                setShowPrivateEventPage(false);
              }}
            />
            <div className=" d-flex ">
              <ETButton
                variant={"secondary"}
                buttonText="SAVE AS DRAFT"
                fontSize="14px"
                width="auto"
              />
              <div className="ps-2">
                {" "}
                <ETButton
                  variant={"primary"}
                  buttonText="SAVE AND PUBLISH EVENT"
                  type={"submit"}
                  onClick={() => setPublishPrivateEvent(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Publishevent
        // nameOfEvent={`${data?.city} ${data?.zipcode}`}
        showPrivateEventPage={showPrivateEventPage}
        publishEvents={publishPrivateEvent}
        setPublishEvents={setPublishPrivateEvent}
      /> */}
      <CustomPopup
        open={publishPrivateEvent}
        onHide={() => setPublishPrivateEvent(false)}
        title={"Create event"}
        title_color="black"
        size={"md"}
        titleTooltip={true}
        titleTooltipText={"Publish event"}
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        {" "}
        <>
          <div className="px-4 py-3 c-black">
            <div className="light-font-text c-black mb-4">
              <p className="m-0">
                Your event is about to be published. To make sure everything is
                correct we have listed some general information about the event.
              </p>
            </div>
            <div
              className="radius-8"
              style={{ border: "1px solid #F0F0F0", background: "#f9fafa" }}
            >
              <div className="w-100">
                <div className="p-3">
                  <div className="fs-14">Name of event</div>
                  <div className="light-font-text mb-2">
                    Mads Johnson and Caroline Johnson - Private viewing
                  </div>
                  <div className="fs-14">Address</div>
                  <div className="light-font-text mb-2">
                    Dybbølsbro 3, 1, 2300 København S
                  </div>
                  <div className="fs-14">Unit</div>
                  <div className="light-font-text mb-2">
                    Dybbølsbro 3, 1, 2300 Køvenhavn S | 7-W260
                  </div>
                  <div className="fs-14">Date and time</div>
                  <div className="light-font-text mb-2">
                    Sunday 6 January, 2023 at 11:00 - 12:00
                  </div>

                  <div className="fs-14">Assign agents to the event</div>
                  <div className="light-font-text mb-2 ">
                    Assign agents to the event
                  </div>
                  <div className="fs-14">
                    Send booking confirmation to the participant
                  </div>
                  <div className="light-font-text mb-2">Yes</div>
                </div>
              </div>
            </div>
          </div>
        </>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setPublishPrivateEvent(false)}
          />
          <ETButton buttonText="CREATE" variant={"primary"} />
        </div>
      </CustomPopup>
      <AddParticipantsPopup
        open={openAddParticipantsPopup}
        close={setopenAddParticipantsPopup}
      />
    </div>
  );
};

export default CreateNewPrivateEvent;
