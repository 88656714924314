import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import ETButton from "../../../../../Components/Buttons/ETButton";
import ReactSelect from "../../../../../Components/DropDown/ReactSelect";
import TagToolTip from "../../../../../Components/Tooltip/PropertyTagToolTip";
import PromoToolTip from "../../../../../Components/Tooltip/PromoToolTip";
import { useEffect } from "react";
import BigReactSelect from "../../../../../Components/DropDown/BigReactSelect";
const Colors = [
  "#A9A9A9",
  "#228B22",
  "#FFD700",
  "#4169E1",
  "#FF0000",
  "#9370DB",
  "#4169E1",
];

const CreateNewPromotionLabel = ({
  setPromotionLabelFlow,
  setStatusProgress,
  setShowPromotionLabelPopup,
  isPromotionLabelEdit,
  stepperState,
  setStepperState,
}) => {
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      fontColor:
        stepperState?.font_color !== "" ? stepperState?.font_color : "",
      backgroundColor:
        stepperState?.background_color !== ""
          ? stepperState?.background_color
          : "",
      internalLabel:
        stepperState?.internal_label !== "" ? stepperState?.internal_label : "",
      priceVisibility:
        stepperState?.is_price_visible !== ""
          ? {
              value: Number(stepperState?.is_price_visible),
              label: stepperState?.is_price_visible == 1 ? "Visible" : "Hidden",
            }
          : null,
    },
  });

  const priceVisibilityOptions = [
    { value: 1, label: "Visible" },
    { value: 0, label: "Hidden" },
  ];

  useEffect(() => {
    setValue("fontColor", stepperState?.font_color);
    setValue("backgroundColor", stepperState?.background_color);
    setValue("internalLabel", stepperState?.internal_label);
    setValue(
      "priceVisibility",
      stepperState?.is_price_visible === ""
        ? null
        : {
            value: Number(stepperState?.is_price_visible),

            label: stepperState?.is_price_visible == 1 ? "Visible" : "Hidden",
          }
    );
    // setValue("priceVisibility", {
    //   value: Number(stepperState?.is_price_visible),
    //   label:
    //     stepperState?.is_price_visible !== ""
    //       ? stepperState?.is_price_visible == 1
    //         ? "Visible"
    //         : "Hidden"
    //       : null,
    // });

    return () => {};
  }, [stepperState]);

  const onSubmit = (data) => {
    setStepperState({
      ...stepperState,
      internal_label: data?.internalLabel,
      font_color: data?.fontColor,
      background_color: data?.backgroundColor,
      is_price_visible: data?.priceVisibility?.value,
    });

    setPromotionLabelFlow("NameYourPromotionLabel");
    setStatusProgress(66.66);
  };

  // const displayItem = (selected) => {
  //   let categoryValue;
  //   let categoryLabel;

  //   if (selected !== undefined && isPromotionLabelEdit === false) {
  //     categoryValue = selected.value;
  //     categoryLabel = selected.label;
  //   } else {
  //     categoryValue = stepperState?.status_type_id;
  //     categoryLabel = stepperState?.status_type_name;
  //   }
  //   return {
  //     // value: categoryValue,
  //     label: (
  //       <div className="d-flex" style={{ marginTop: "6px" }}>
  //         <div className="fs-14 pb-1 d-flex align-items-center fw-300">0</div>
  //       </div>
  //     ),
  //   };
  // };
  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{ height: "500px", overflow: "scroll" }}
          className="scrollable-container-y  "
        >
          <Row className="d-flex justify-content-center">
            <Col md={{ span: 7, offset: 0 }} className="ps-0">
              <div className="fs-32 form-label-title mt-4  d-flex justify-content-center">
                Let’s{" "}
                {isPromotionLabelEdit
                  ? "Update your promotion label"
                  : "Create your promotion label"}
              </div>
              <div
                className="light-font-text mt-3 c-black"
                style={{ textAlign: "center" }}
              >
                <div>Give your promotion label an internal label name.</div>
                <div>
                  Which must be a name everyone can recognize in the system.
                </div>
              </div>

              {/* <div className="my-5">
              <Controller
                {...register("categoryvalidation")}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    disabled={isPromotionLabelEdit}
                    placeholder={"Category"}
                    value={displayItem(value)}
                    setPlaceholderPosition={true}
                    height={55}
                    clearable={false}
                    options={allLabelStatus?.map((item, i) => ({
                      value: item?.Id,
                      label: (
                        <div
                          className="d-flex"
                          style={{ fontFamily: "Cerebri sans" }}
                        >
                          <div
                            className="radius-4 m-2"
                            style={{
                              backgroundColor: Colors[i],
                              width: "16px",
                              height: "16px",
                            }}
                          ></div>
                          <div>
                            <div className="fs-20 fw-300"> {item?.Text}</div>
                            <div className="fs-16 fw-300 grey-text">
                              Eg. {item?.DataValue1}
                            </div>
                          </div>
                        </div>
                      ),
                    }))}
                    onChange={(e) => {
                      onChange(e);
                      handleChange(e);
                      clearErrors("categoryvalidation");
                    }}
                  />
                )}
              />
              {errors?.categoryvalidation && (
                <p className="fs-14 ps-1" style={{ color: "red" }}>
                  This is required
                </p>
              )}
            </div> */}
              <Row className="my-5">
                <Col lg={6} className="pe-4">
                  <div className="w-100" style={{ position: "relative" }}>
                    {/* <TagToolTip bgcolor={"red"} />
                     */}
                    <div className="d-flex gap-2 align-items-center">
                      <div style={{ position: "absolute", left: "-20px" }}>
                        <PromoToolTip
                          text={
                            <div style={{ width: "207px" }} className="p-2">
                              <div className="actors-label-title">
                                Internal label
                              </div>
                              <div className="actors-lite-text">
                                Is the label that will be visible in the
                                estatetool, e.g. on the unit list and dashboard.
                              </div>
                            </div>
                          }
                        />
                      </div>
                      <Controller
                        {...register("internalLabel")}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div className="form-input">
                            <input
                              style={{ height: "55px" }}
                              // className="mx-sm-1"
                              type="text"
                              placeholder="Subject line"
                              id="internal-label"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("internalLabel");
                              }}
                            />
                            <label htmlFor="internal-label">
                              Internal label
                            </label>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  {errors?.internalLabel && (
                    <p className="error-msg">This is required</p>
                  )}
                </Col>
                <Col lg={6} className="ps-4">
                  <div className="w-100" style={{ position: "relative" }}>
                    <div className="d-flex gap-2 align-items-center">
                      <div style={{ position: "absolute", left: "-20px" }}>
                        <PromoToolTip
                          text={
                            <div className="p-2" style={{ width: "207px" }}>
                              <div className="actors-label-title">
                                Price visibility
                              </div>
                              <div className="actors-lite-text">
                                <div>
                                  {" "}
                                  Should the price of a unit be visible when the
                                  unit is set in this new promotion label? E.g.
                                  if you want to advertise some units as vacant,
                                  but do not want the price to be published.{" "}
                                </div>{" "}
                              </div>
                            </div>
                          }
                        />
                      </div>
                      <div className="w-100">
                        {/* <Controller
                          {...register("priceVisibility")}
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <ReactSelect
                              placeholder={"Price visibility"}
                              height={55}
                              value={value}
                              clearable={false}
                              searchable={false}
                            
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("priceVisibility");
                              }}
                            />
                          )}
                        /> */}
                        <Controller
                          {...register("priceVisibility")}
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <BigReactSelect
                              placeholder={"Price visibility"}
                              clearable={false}
                              value={value}
                              options={priceVisibilityOptions?.map((item) => ({
                                value: item?.value,
                                label: item?.label,
                              }))}
                              searchable={false}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("priceVisibility");
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  {errors?.priceVisibility && (
                    <p className="error-msg">This is required</p>
                  )}
                </Col>
              </Row>
              <div className="mb-4">
                <div className="light-font-text text-align-center c-black">
                  Give your new promotion label an eye-catching colors that
                  attract your customers’ attention.
                </div>
                <div className="light-font-text text-align-center c-black">
                  Choose the font color and background color.
                </div>
              </div>
              <div>
                <div className="mt-0 position-relative">
                  <label className="radius-8 color-picker" id="favcolor">
                    <div
                      className="p-1 color-picker-label"
                      style={{ position: "absolute", top: "-13px" }}
                    >
                      Font color
                    </div>

                    <div className="ps-2 d-flex position-relative align-items-center">
                      <Controller
                        {...register("fontColor")}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <input
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              type="color"
                              className="color-picker-input"
                              id="favcolor"
                            />
                            <span
                              className="fs-20 light-font-text px-2"
                              style={{ color: "#000" }}
                            >
                              {/* {fontColor} */}
                              {value || stepperState?.font_color}
                            </span>
                          </>
                        )}
                      />
                    </div>
                  </label>
                </div>

                <div className="mt-4 mb-4 position-relative">
                  <label className="radius-8 color-picker" id="label-color">
                    <div
                      className="p-1 color-picker-label"
                      style={{ position: "absolute", top: "-13px" }}
                    >
                      Background color
                    </div>
                    <div className="ps-2 d-flex position-relative align-items-center">
                      <Controller
                        {...register("backgroundColor")}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <input
                              // {...register("labelColor")}
                              // value={labelColor}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              type="color"
                              // onChange={(e) => setLabelColor(e.target.value)}
                              // onChange={(e) => handleTextColorChange(e)}
                              className="color-picker-input"
                              id="label-color"
                            />
                            <span
                              className="fs-20 light-font-text px-2"
                              style={{ color: "#000" }}
                            >
                              {/* {labelColor} */}{" "}
                              {value || stepperState?.label_color}
                            </span>
                          </>
                        )}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          {" "}
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setShowPromotionLabelPopup(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="NEXT"
            type={"submit"}

            // onClick={handleClickNext}
          />
        </div>
      </Form>
    </div>
  );
};

export default CreateNewPromotionLabel;
