import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";

import ETButton from "../../../../Components/Buttons/ETButton";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import { GetFacilityPackageByPropertyId } from "../../../../services/Facility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FacilityCard from "../../../../Components/card/FacilityCard";

const FacilitiesCard = ({
  facilityName,
  facilityCount,
  onChange,
  value,
  data,
}) => {
  const [mediaStyle, setMediaStyle] = useState(false);

  return (
    <div
      className="MainDiv"
      style={{
        backgroundColor: mediaStyle ? "#ECF1E6" : "#F9FAFA",
        border: mediaStyle ? "1px solid #619130" : " 1px solid #F0F0F0",
      }}
    >
      <div className="Card-Name">
        <span style={{ position: "absolute", right: "1px", top: "1px" }}>
          {" "}
          <CustomCheckBox
            onChange={onChange}
            value={value}
            onClick={() => {
              setMediaStyle(!mediaStyle);
            }}
          />{" "}
        </span>
        <div className="form-label-title fs-12">Package Name</div>
        <div className="light-font-text fs-12">
          <Row>
            <Col md={12}>{facilityName}</Col>
          </Row>
        </div>
      </div>

      <div className="Source-Type">
        <div className="form-label-title fs-12">
          {facilityCount} Number of facilities
        </div>
        <div className="light-font-text fs-12">Facilities</div>
      </div>
      <div className="Media">
        <div
          style={{
            border: "1px solid #F0F0F0",
            backgroundColor: "White",
            position: "absolute",
            fontSize: "10px",
            fontWeight: "300",
            fontFamily: "Cerebri Sans",
            borderRadius: "8px",
            width: "223px",
            height: "150px",
          }}
        >
          <div
            style={{
              fontSize: "10px",
              fontWeight: "300",
              fontFamily: "Cerebri Sans",
              textAlign: "justify",
            }}
          >
            {data?.icons?.map(
              (icon, index) =>
                index < 11 &&
                (icon ? (
                  <FontAwesomeIcon
                    icon={icon}
                    size="lg"
                    style={{
                      padding: "1rem 1.2rem 1rem 1.2rem",
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon="fa-solid fa-circle"
                    size="lg"
                    style={{
                      padding: "1rem 1.2rem 1rem 1.2rem",
                      color: "#000",
                    }}
                  />
                ))
            )}
            {data?.icons?.length > 12 && (
              <div
                // className="px-2"
                style={{
                  display: "inline-flex",
                }}
              >
                <div
                  className="fs-10 fw-600"
                  style={{ position: "relative", bottom: "15px" }}
                >
                  + {data?.icons?.length - 12} icons
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SelectFacilityPackage = ({
  setStepWizard,
  progress,
  setProgress,
  propertyId,
  tagName,
  facilityPackage,
  setFacilityPackage,
}) => {
  const handleFifthScreen = () => {
    setStepWizard("SelectDocumentPackage");
    setProgress(progress + 12.5);
  };

  const handlefourthScreen = () => {
    setStepWizard("SelectTextPackage");
    setProgress(progress - 12.5);
  };

  //Get facility packages
  const { data: GetFacilityPackageByPropertyIdData } = useQuery(
    ["GetFacilityPackageByPropertyId", propertyId],
    () => GetFacilityPackageByPropertyId(propertyId)
  );
  console.log(
    GetFacilityPackageByPropertyIdData?.data,
    "GetFacilityPackageByPropertyIdData?.data"
  );

  const CheckedFacilityCards = (event, item) => {
    if (event.target.checked) {
      setFacilityPackage((value) => [...value, item]);
    } else {
      setFacilityPackage((value) =>
        value.filter(
          (it) => it?.facility_package_id !== item?.facility_package_id
        )
      );
    }
  };
  // console.log(facilityPackage, "facilityPackage");
  return (
    <div>
      {" "}
      <div className="mb-5 ps-4 pt-3">
        <div className="fs-14 fw-400">
          <span className="form-label-title">Tag name :&nbsp;</span>
          {tagName}
        </div>
        <div className="light-font-text grey-text pb-2 mb-4">
          Do you already have a plan for your new tag. It’s here possible to
          already put the tag on one of the facility packages created on the
          property.
        </div>
        <div>
          {GetFacilityPackageByPropertyIdData?.data == 0 ? (
            <div
              className="grey-text w-100 light-font-text"
              style={{ textAlign: "center" }}
            >
              {" "}
              You do not have any package yet.{" "}
            </div>
          ) : (
            <>
              <Row>
                {GetFacilityPackageByPropertyIdData?.data.map((item, i) => (
                  <Col xl={4} lg={6} className="pb-4" key={i}>
                    <FacilityCard
                      data={item}
                      isTag={"AddTagF"}
                      checkboxOnChange={(e) => CheckedFacilityCards(e, item)}
                      checkValue={facilityPackage?.filter(
                        (x) =>
                          x.facility_package_id === item?.facility_package_id
                      )}
                    />
                  </Col>
                ))}
              </Row>
            </>
          )}
        </div>
      </div>
      <div
        className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container"
        style={{
          position: "absolute",
          width: "100%",
          bottom: "0",
        }}
      >
        <ETButton
          variant={"white"}
          buttonText="PREVIOUS"
          onClick={() => handlefourthScreen()}
        />

        <ETButton
          variant={"primary"}
          buttonText="NEXT"
          onClick={() => {
            handleFifthScreen();
          }}
        />
      </div>
    </div>
  );
};

export default SelectFacilityPackage;
