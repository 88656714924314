import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import ETButton from "../../../../Components/Buttons/ETButton";
import "./Popups.css";
import RentalContractTable from "./RentalContractTable";
import { useGetRentalPopupDetails } from "../../../../hooks/queries/useActorQueries";
import moment from "moment";

const RentalContract = ({
  setRentalContractPopup,
  contractId,
  contractOverViewData,
  setSelectedTab
}) => {
  //----------------React query-------------//
  const { data: getContractRentalPopupDetailsData } =
    useGetRentalPopupDetails(contractId);
  return (
    <div>
      <div className="px-3 py-4" style={{ borderBottom: "1px Solid #F4F5F5" }}>
        <div className="Reservation-data px-3 py-2 d-flex justify-content-between">
          <div
            className="fs-14"
          >
            Status
          </div>
          <div
            className="fw-400 light-font-text d-flex justify-content-between"
          >
            <div className="d-flex align-items-center">
              {getContractRentalPopupDetailsData?.data?.[0]?.total_signed !==
              getContractRentalPopupDetailsData?.data?.[0]?.total_signers
                ? "In process"
                : "Completed"}
            </div>
            <div
              className="extra-data mx-2 px-3 py-1"
              style={{
                backgroundColor:
                  getContractRentalPopupDetailsData?.data?.[0]?.total_signed == 0
                    ? "#FF6868"
                    : getContractRentalPopupDetailsData?.data?.[0]
                        ?.total_signed ===
                      getContractRentalPopupDetailsData?.data?.[0]?.total_signers
                    ? "#ADECA7"
                    : "#FFD700",
              }}
            >
              {getContractRentalPopupDetailsData?.data?.[0]?.total_signed}
              &nbsp;/&nbsp;
              {getContractRentalPopupDetailsData?.data?.[0]?.total_signers}
            </div>
          </div>
        </div>
        <div className="Reservation-data mt-2 px-3 py-2 d-flex justify-content-between">
          <div
            className="fs-14"
          >
            Created
          </div>
          <div
      
            className="light-font-text"
          >
            {moment(getContractRentalPopupDetailsData?.data?.[0]?.created_on).format(
              "DD MMM, YYYY [at] HH:mm:ss"
            )}
          </div>
        </div>
        <div className="Reservation-data mt-2 px-3 py-2 d-flex justify-content-between">
          <div
           className="fs-14"
          >
            Expires
          </div>
          <div
       
            className="light-font-text"
          >
            {moment(getContractRentalPopupDetailsData?.data?.[0]?.expires_on).format(
              "DD MMM, YYYY [at] HH:mm:ss"
            )}
          </div>
        </div>
        <div className="Reservation-data mt-2 px-3 py-2 d-flex justify-content-between">
          <div
           className="fs-14"
          >
            Created by
          </div>
          <div
 
            className="light-font-text"
          >
            {getContractRentalPopupDetailsData?.data?.[0]?.created_by}
          </div>
        </div>

        {/* ---------As discussed, it will be removed------------ */}
        {/* <div className="Reservation-data mt-2 px-3 py-2 d-flex justify-content-between">
          <div
            style={{ fontFamily: "Cerebri Sans", fontSize: "14px" }}
            className="fw-400"
          >
            Reminder
          </div>
          <div
            style={{ fontFamily: "Cerebri Sans", fontSize: "14px" }}
            className="fw-400"
          >
            22 Mar, 2023 at 12:00
          </div>
        </div>
        <div className="Reservation-data mt-2 px-3 py-2 d-flex justify-content-between">
          <div
            style={{ fontFamily: "Cerebri Sans", fontSize: "14px" }}
            className="fw-400"
          >
            Interval
          </div>
          <div
            style={{ fontFamily: "Cerebri Sans", fontSize: "14px" }}
            className="fw-400"
          >
            4 days
          </div>
        </div> */}
      </div>
      {/* ------------------------ProgressBar---------------------------- */}
      <div className="px-3 py-4" style={{ borderBottom: "1px Solid #F4F5F5" }}>
        <div
          className="mb-1 font-semibold fs-14"
        >
          Actors in the signing order
        </div>

        <RentalContractTable tableData={getContractRentalPopupDetailsData?.data?.[0]?.actors} />
      </div>

      {/* -----------------------DropDown And Datepickers----------------- */}
      {/* <div className="p-4">
        <div
          style={{
            fontFamily: "Cerebri Sans",
            fontWeight: "300",
            fontSize: "14px",
          }}
        >
          Below you can see the reservation deadline. It is possible to correct
          the deadline and the responsible agent.
        </div>
        <Row className="mt-2">
          <Col lg={3} className="mt-md-3">
            <div className="form-input-group ">
              <input
                type="date"
                id="DateofDeadline"
                placeholder="DateofDeadline"
              />
              <label htmlFor="DateofDeadline">Date of deadline</label>
            </div>
          </Col>
          <Col lg={3} className="my-md-3">
            <div className="form-input-group ">
              <input
                type="time"
                id="TimeofDeadline"
                placeholder="TimeofDeadline"
              />
              <label htmlFor="TimeofDeadline">Time of deadline</label>
            </div>
          </Col>
          <Col lg={3} className="my-md-3">
            <ReactSelect placeholder={"Responsible agent"} />
          </Col>
        </Row>
      </div> */}
      <div
        className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center 
        footer-section-button-container"
      >
        <ETButton
          variant = {"white"}
          buttonText="CLOSE"
          onClick={() => setRentalContractPopup(false)}
        />
        <ETButton
          variant={"primary"}
          buttonText="GO TO SIGNING ORDER"
          onClick={()=>setSelectedTab(6)}
        />
      </div>
    </div>
  );
};

export default RentalContract;
