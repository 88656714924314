import React, { useMemo, useState } from "react";
import { CustomersTab } from "../../../../Components/Tabs/CustomersTabs.style";
import MRTTheme from "../../../../Components/table/MRTTheme";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../../../Components/Buttons/ETButton";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../../Components/table/MRTGlobalProps";
import { NoRecordsToDisplay } from "../../../../utils/validationConstants";
import { H3 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { attachedDocuments } from "../../../properties/units/addUnits/editSingleUnit/overview/unitsOverview/requests/page/data";

const AttachedDocuments = ({
  handleNext = () => {},
  handleCancel = () => {},
}) => {
  const [customersTabs, setCustomersTabs] = useState(0);

  const handleSubmitAttacedDocument = () => {
    handleNext();
  };

  const handleCancelSubmitAttacedDocument = () => {
    handleCancel();
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "file_name",
        header: "File name",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: true,
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
      },
      {
        accessorKey: "file_type",
        header: "File type",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: true,
      },
      {
        accessorKey: "type",
        header: "Type",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "date_of_upload",
        header: "Date of upload",
        enableHiding: false,
        size: 90,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "uploaded_by",
        header: "Uploaded by",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "source",
        header: "Source",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
      },
    ],
    []
  );
  return (
    <div className="">
      {" "}
      <div>
        <div className="Popups-inner-scrollable-container-height scrollable-container-y">
          <div className="px-4 pt-4  ">
            <div className="d-flex align-items-center gap-2">
              <H3>Attached documents</H3>{" "}
              <CustomToolTip size="sm" text={"Attached documents"} />
            </div>
            <Row>
              <Col lg={8}>
                <div className="light-font-text pt-1">
                  If you want to add documents to the invitation, you can select
                  them below. The documents will be sent together with the
                  invitation to the customer and can be downloaded via a link.
                </div>
              </Col>
            </Row>
          </div>
          <div
            className="d-flex gap-2 px-4 py-3 my-4"
            style={{
              borderTop: "1px solid #F0F0F0",
              borderBottom: "1px solid #F0F0F0",
            }}
          >
            {attachedDocumentsTabs?.map((item, i) => (
              <>
                <CustomersTab
                  key={i}
                  style={{ position: "relative", width: "auto" }}
                  mode={i == customersTabs}
                  className="mb-1"
                  // className={
                  //   i == customersTabs
                  //     ? "px-2 customers-tabs-active mb-1"
                  //     : "customers-tabs px-2 mb-1"
                  // }
                  onClick={() => {
                    // handleClickTab();
                    setCustomersTabs(i);
                    // setTenancyId(item?.tenancy_id);
                    // handleClick(item.name);
                  }}
                >
                  <span style={{ position: "relative" }}>{item?.text}</span>
                </CustomersTab>
              </>
            ))}
          </div>
          <div className="px-4 pb-5">
            <MRTTheme
              colorVariantsForTableRow={["gray"]}
              colorVariantsForTableHead={["white"]}
              table={
                <MaterialReactTable
                  columns={columns}
                  data={attachedDocuments} //data is undefined on first render
                  //   data={[]}
                  icons={{ ...SortIcon }}
                  localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                  enableColumnActions={false}
                  enableTopToolbar={true}
                  enableSelectAll={false}
                  enableRowActions={false}
                  enableRowSelection
                  globalFilterFn="contains"
                  initialState={{
                    showGlobalFilter: true,
                    showColumnFilters: false,
                  }}
                  muiSearchTextFieldProps={{
                    ...SearchStyle,

                    InputProps: {
                      ...SearchIcon,
                    },
                  }}
                  muiTableContainerProps={{
                    sx: {
                      padding: "0px !important",
                    },
                  }}
                  displayColumnDefOptions={{
                    "mrt-row-select": {
                      header: "",
                      size: 50,
                      muiTableBodyCellProps: {
                        sx: {
                          paddingRight: "0px !important",
                        },
                      },
                    },
                    "mrt-row-actions": {
                      header: "",
                    },
                  }}
                  muiTablePaperProps={{
                    elevation: 0,
                    fontFamily: "Cerebri sans-light !important",
                    sx: {
                      borderRadius: "0",
                      border: "none",
                      // backgroundColor: "#F9FAFA",
                      table: {
                        borderCollapse: "separate",
                        borderSpacing: "0 5px",
                      },
                    },
                  }}
                  muiTableBodyRowProps={{
                    selected: false,
                  }}
                  muiTablePaginationProps={{
                    ...paginationoptions,
                  }}
                  muiTableHeadRowProps={{
                    sx: {
                      ...TableRowsStyle,
                    },
                  }}
                  renderTopToolbar={({ table }) => (
                    <>
                      <Row>
                        <Col lg={5}>
                          <MRT_GlobalFilterTextField table={table} />
                        </Col>
                      </Row>
                    </>
                  )}
                />
              }
            />
          </div>
        </div>
      </div>
      <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-between footer-section-button-container">
        <ETButton
          variant={"white"}
          buttonText="BACK"
          onClick={handleCancelSubmitAttacedDocument}
        />

        <ETButton
          variant={"primary"}
          buttonText="NEXT"
          onClick={handleSubmitAttacedDocument}
        />
      </div>
    </div>
  );
};

export default AttachedDocuments;

//   attached document code
const attachedDocumentsTabs = [
  {
    id: 0,
    text: "All",
  },
  {
    id: 1,
    text: "From company",
  },
  {
    id: 2,
    text: "From property",
  },
  {
    id: 3,
    text: "From unit",
  },
];

// this page created by Suraj
