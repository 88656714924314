import React from "react";
import { InputAdornment, styled, TextField } from "@mui/material";
import "./TestField.css";

const StyledTextField = styled(TextField)({});

// if(textFieldStyle===)

const CustomTextfield = ({
	name,
	borderColor,
	helperText,
	label,
	size,
	value,
	className,
	multiline,
	onChange,
	defaultValue,
	disabled,
	textFieldStyle,
	type,
	inputAdornment,
}) => {
	return (
		<div>
			<StyledTextField
				label={label}
				InputLabelProps={{
					style: {
						color: textFieldStyle ? "#619930" : "#888888",
						fontSize: "14px",
					},
				}}
				sx={{
					fontFamily:"cursive !important",
					"& .MuiOutlinedInput-root": {
						Height: "30px",
						background: textFieldStyle ? "#F7F9F4" : "white", // background: "#F7F9F4",

						"& fieldset": {
							// borderColor: borderColor,
							fontSize: "14px",
							borderRadius: "8px",
							borderColor: textFieldStyle ? "#619930" : "#D1D1D1",
						},
						"&:hover fieldset": {
							borderColor: "#D1D1D1",
							boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
						},
						"&.Mui-focused fieldset": {
							borderColor: textFieldStyle ? "#619930" : "#D1D1D1",
						},
					},
				}}
				name={name}
				onChange={onChange}
				multiline={multiline}
				disabled={disabled}
				size={size}
				helperText={helperText}
				value={value}
				className={className}
				defaultValue={defaultValue}
				fullWidth
				type={type}
				InputProps={{
					startAdornment: inputAdornment ? (
						<InputAdornment position="start">{inputAdornment}</InputAdornment>
					) : (
						""
					),
				}}
			/>
		</div>
	);
};

export default CustomTextfield;

CustomTextfield.defaultProps = {
	type: "text",
};
