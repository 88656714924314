import React from "react";
import { Col, Row } from "react-bootstrap";
import OverallStaticsOnAge from "./OverallStaticsOnAge";
import HeardAboutProperty from "./HeardAboutProperty";
import OverallStaticsOnGender from "./OverallStaticsOnGender";
import OverallStaticsOnResidence from "./OverallStaticsOnResidence";
import OverallStaticsOnCivilStatus from "./OverallStaticsOnCivilStatus";
import StatisticsOfParticipantsatEvent from "./StatisticsOfParticipantsatEvent";
import CompleteEvents from "./CompleteEvents";
import EventHistory from "./EventHistory";
import FutureEvents from "./FutureEvents";

const ControlCenterEvents = () => {
  return (
    <div  className=" radius-8 p-4 containerStyle">
      <Row className="mb-4">
        <Col>
          {" "}
          <EventHistory />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          {" "}
          <FutureEvents />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          {" "}
          <CompleteEvents />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          {" "}
          <StatisticsOfParticipantsatEvent />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={6} className="mb-sm-4 mb-lg-0">
          {" "}
          <OverallStaticsOnAge />
        </Col>
        <Col lg={6}>
          {" "}
          <OverallStaticsOnGender />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={6} className="mb-sm-4 mb-lg-0">
          {" "}
          <OverallStaticsOnResidence />
        </Col>
        <Col lg={6}>
          {" "}
          <OverallStaticsOnCivilStatus />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          {" "}
          <HeardAboutProperty />
        </Col>
      </Row>
    </div>
  );
};

export default ControlCenterEvents;
