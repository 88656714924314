import { Col, Row } from "react-bootstrap";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import ETButton from "../../../../Components/Buttons/ETButton";
import letterimage from "../../../../assets/images/LetterImage.png";
import { NavLink } from "react-router-dom";
import { usePropertyOverview } from "../../../../hooks/stores/usePropertyStore";
import { useUnitOverview } from "../../../../hooks/stores/useUnitStore";

const SigningPackageSendtoSigning = ({setOpenSendToSigning}) => {
  const propOverview = usePropertyOverview((state) => state.propOverview);
  const unitOverview = useUnitOverview((state) => state.unitOverview);

  return (
    <>
      <div className="p-4 Signing-package-send-to-signing-main ">
        <div className="Signing-package-send-to-signing">
          <div
            className="d-flex justify-content-between p-4 align-items-center"
            style={{ borderBottom: "1px solid #F0F0F0" }}
          >
            <div className="d-flex">
              <span className="fw-700 fs-14" style={{ paddingTop: "2px" }}>
                Signing package send to signing
              </span>
              <span className="ms-2 mb-1 ">
                <CustomToolTip height={"12px"} text={" Signing package "} />
              </span>
            </div>
            <div className="fw-300 fs-14 grey-text">
              {" "}
              {unitOverview?.[0]?.property_name} | {unitOverview?.[0]?.Address}
            </div>
          </div>
          <div>
            <div
              className="d-flex justify-content-center fw-600"
              style={{ fontSize: "32px", marginTop: "65px" }}
            >
              Fantastic. You rock!
            </div>
            <Row className="mt-3">
              <Col lg={3}></Col>
              <Col lg={6}>
                <div className="fw-300 fs-14" style={{ textAlign: "center" }}>
                  Your signing package has been processed. Lorem ipsum dolor sit
                  amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat.
                </div>
              </Col>
              <Col lg={3}></Col>
            </Row>
            <div
              className="d-flex justify-content-center "
              style={{ marginTop: "122px", marginBottom: "77px" }}
            >
              <img src={letterimage}></img>{" "}
            </div>
          </div>
          <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-end  justify-content-end footer-section-button-container gap-3">
            <NavLink
              to="/property-units/units/residential"
              state={{ propId: propOverview?.[0]?.property_id, Tab: "Units" }}
              // style={{ textDecoration: "none", color: "black" }}
            >
              <ETButton
                variant = {"white"}
                buttonText="GO TO UNITS"
              />
            </NavLink>

            {/* <NavLink
              to="/actor"
              // style={{ textDecoration: "none", color: "black" }}
            >
              <ETButton
              variant={"primary"}
                buttonText="GO TO ACTOR"
                // onClick={()=>setOpenSendToSigning(false)}
              />
            </NavLink> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SigningPackageSendtoSigning;
