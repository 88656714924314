export const InquiriesTableData = [
  {
    Id: 1,
    name: "Joao Warner",
    property: "Blomsterbjerget",
    unit: "Dybbølsbro 3, 1 | 212-004",
    registration_date: "12 Jul, 2023 at 10:32",
    notification: "1",
  },
  {
    Id: 2,
    name: "Bertha Rose",
    property: "Blomsterbjerget",
    unit: "Dybbølsbro 3, 1 | 212-004",
    registration_date: "12 Jul, 2023 at 10:32",
    notification: "0",
  },
  {
    Id: 3,
    name: "Estelle Cisneros",
    property: "Blomsterbjerget",
    unit: "Dybbølsbro 3, 1 | 212-004",
    registration_date: "12 Jul, 2023 at 10:32",
    notification: "0",
  },
  {
    Id: 4,
    name: "Anaya Martinez",
    property: "Blomsterbjerget",
    unit: "Dybbølsbro 3, 1 | 212-004",
    registration_date: "12 Jul, 2023 at 10:32",
    notification: "0",
  },
];

export const PendingsTableData = [
  {
    Id: 1,
    name: "Joao Warner",
    property: "Blomsterbjerget",
    unit: "Dybbølsbro 3, 1 | 212-004",
    registration_date: "12 Jul, 2023 at 10:32",
    Invitation_sent: "12 Jul, 2023 at 12:30",
    Date: "16 Jul, 2023",
    Time: "-",
  },
  {
    Id: 2,
    name: "Bertha Rose",
    property: "Blomsterbjerget",
    unit: "Dybbølsbro 3, 1 | 212-004",
    registration_date: "12 Jul, 2023 at 10:32",
    Invitation_sent: "14 Jul, 2023 at 17:50",
    Date: "16 Jul, 2023",
    Time: "-",
    suggestionDate: "16 Jul, 2023",
    suggestionTime: "-",
  },
  {
    Id: 3,
    name: "Estelle Cisneros",
    property: "Blomsterbjerget",
    unit: "Dybbølsbro 3, 1 | 212-004",
    registration_date: "12 Jul, 2023 at 10:32",
    Invitation_sent: "12 Jul, 2023 at 12:30",
    Date: "16 Jul, 2023",
    Time: "-",
    suggestionDate: "16 Jul, 2023",
    suggestionTime: "-",
  },
  {
    Id: 4,
    name: "Anaya Martinez",
    property: "Blomsterbjerget",
    unit: "Dybbølsbro 3, 1 | 212-004",
    registration_date: "12 Jul, 2023 at 10:32",
    Invitation_sent: "12 Jul, 2023 at 12:30",
    Date: "16 Jul, 2023",
    Time: "11:00 - 12:00",
    suggestionDate: "16 Jul, 2023",
    suggestionTime: "-",
  },
];

export const InterestedTableData = [
  {
    Id: 1,
    name: "Joao Warner",
    responsible_agent: "Max Wildersans Brentford",
    Interest_confirmed: "12 Jul, 2023 at 12:24",
    status: "Interest confirmed",
  },
  {
    Id: 2,
    name: "Bertha Rose",
    responsible_agent: "Max Wildersans Brentford",
    Interest_confirmed: "12 Jul, 2023 at 12:24",
    status: "Follow up appointment planned",
  },
  {
    Id: 3,
    name: "Estelle Cisneros",
    responsible_agent: "Max Wildersans Brentford",
    Interest_confirmed: "12 Jul, 2023 at 12:24",
    status: "Follow up appointment in process",
  },
  {
    Id: 4,
    name: "Anaya Martinez",
    responsible_agent: "Max Wildersans Brentford",
    Interest_confirmed: "12 Jul, 2023 at 12:24",
    status: "Interest re-confirmed",
  },
];

export const DeclinedTableData = [
  {
    Id: 1,
    name: "Joao Warner",
    property: "Blomsterbjerget",
    unit: "Dybbølsbro 3, 1 | 212-004",
    registration_date: "12 Jul, 2023 at 10:32",
    Invitation_sent: "12 Jul, 2023 at 12:30",
    Date: "16 Jul, 2023",
    Time: "-",
    suggestionDate: "16 Jul, 2023",
    suggestionTime: "-",
  },
  {
    Id: 2,
    name: "Bertha Rose",
    property: "Blomsterbjerget",
    unit: "Dybbølsbro 3, 1 | 212-004",
    registration_date: "12 Jul, 2023 at 10:32",
    Invitation_sent: "14 Jul, 2023 at 17:50",
    Date: "16 Jul, 2023",
    Time: "-",
    suggestionDate: "16 Jul, 2023",
    suggestionTime: "-",
  },
  {
    Id: 3,
    name: "Estelle Cisneros",
    property: "Blomsterbjerget",
    unit: "Dybbølsbro 3, 1 | 212-004",
    registration_date: "12 Jul, 2023 at 10:32",
    Invitation_sent: "12 Jul, 2023 at 12:30",
    Date: "16 Jul, 2023",
    Time: "-",
    suggestionDate: "16 Jul, 2023",
    suggestionTime: "-",
  },
  {
    Id: 4,
    name: "Anaya Martinez",
    property: "Blomsterbjerget",
    unit: "Dybbølsbro 3, 1 | 212-004",
    registration_date: "12 Jul, 2023 at 10:32",
    Invitation_sent: "12 Jul, 2023 at 12:30",
    Date: "16 Jul, 2023",
    Time: "11:00 - 12:00",
    suggestionDate: "16 Jul, 2023",
    suggestionTime: "-",
  },
];

export const SigningsTableData = [
  {
    Id: 1,
    name: "Louise Birch Justesen",
    attended_viewing: "17 Jun, 2023 at 12:00",
    reservation_created: "3 Jun, 2023 at 11:52",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
  },
  {
    Id: 2,
    name: "Harris Navarro",
    attended_viewing: "26 Jun, 2023 at 12:00",
    reservation_created: "12 Jun, 2023 at 10:03",
    signing_started: "26 Jun, 2023 at 12:00",
    signing_deadline: "26 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
  },
  {
    Id: 3,
    name: "Donovan Cummings",
    attended_viewing: "28 May, 2023 at 08:05",
    reservation_created: "28 May, 2023 at 08:05",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
  },
  {
    Id: 4,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
  },
];

export const ScreeningsTableData = [
  {
    Id: 1,
    name: "Louise Birch Justesen",
    responsible_agent_count: 2,
    screenings_agent_count: 2,
    attended_viewing: "17 Jun, 2023 at 12:00",
    reservation_created: "3 Jun, 2023 at 11:52",
    Screening_started: "17 Jun, 2023 at 12:00",
    Screening_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
    subRows: [
      {
        Id: 5,
        screenings_agent_count: 2,
        name: "Michael Bjørn Asmunds...",
        attended_viewing: "18 Jun, 2023 at 13:30",
        reservation_created: "4 Jun, 2023 at 08:16",
        Screening_started: "4 Jun, 2023 at 08:16",
        Screening_deadline: "8 Jun, 2023 at 12:00",
        responsible_agent: "Max Wildersans Brentford",
      },
    ],
  },
  {
    Id: 2,
    responsible_agent_count: 1,
    name: "Harris Navarro",
    attended_viewing: "26 Jun, 2023 at 12:00",
    reservation_created: "12 Jun, 2023 at 10:03",
    Screening_started: "26 Jun, 2023 at 12:00",
    Screening_deadline: "26 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
    subRows: [],
  },
  {
    Id: 3,
    responsible_agent_count: 1,
    name: "Donovan Cummings",
    attended_viewing: "28 May, 2023 at 08:05",
    reservation_created: "28 May, 2023 at 08:05",
    Screening_started: "17 Jun, 2023 at 12:00",
    Screening_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
    subRows: [],
  },
  {
    Id: 4,
    responsible_agent_count: 1,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    Screening_started: "17 Jun, 2023 at 12:00",
    Screening_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
    subRows: [],
  },
];

export const attachedDocuments = [
  {
    Id: 1,
    file_name: "salary_slip_may-2023.pdf",
    file_type: "Document",
    type: "Salary slip",
    date_of_upload: "20 May, 2023",
    uploaded_by: "Max Wildersans Brentford",
    source: "Communication",
  },
  {
    Id: 2,
    file_name: "kitchen-door.jpg",
    file_type: "Media",
    type: "Documentation",
    date_of_upload: "20 May, 2023",
    uploaded_by: "Max Wildersans Brentford",
    source: "Communication",
  },
];

export const searchPropertyData = [
  {
    id: 1,
    name: "Terrasserne",
    address: "Blokhusvej 32",
    zipcode: "8200 Aarhus",
    rent: "10.500 - 14.700 DKK/mthly.",
    size: "98 - 150 m2",
    status: "37 available",
  },
  {
    id: 2,
    name: "Brofæstet",
    address: "Blokhusvej 32",
    zipcode: "8200 Aarhus",
    rent: "6.700 - 9.100 DKK/mthly.",
    size: "98 - 150 m2",
    status: "78 available",
  },
  {
    id: 3,
    name: "Blomsterbjerget",
    address: "Blokhusvej 32",
    zipcode: "8200 Aarhus",
    rent: "7.500 - 16.200 DKK/mthly.",
    size: "98 - 150 m2",
    status: "26 available",
  },
  {
    id: 4,
    name: "Carolinelunden",
    address: "Blokhusvej 32",
    zipcode: "8200 Aarhus",
    rent: "9.500 - 18.500 DKK/mthly.",
    size: "98 - 150 m2",
    status: "55 available",
  },
  {
    id: 5,
    name: "Munkesøhuse",
    address: "Blokhusvej 32",
    zipcode: "8200 Aarhus",
    rent: "10.500 - 14.700 DKK/mthly.",
    size: "98 - 150 m2",
    status: "37 available",
  },
  {
    id: 6,
    name: "Terrasserne",
    address: "Blokhusvej 32",
    zipcode: "8200 Aarhus",
    rent: "10.500 - 14.700 DKK/mthly.",
    size: "98 - 150 m2",
    status: "55 available",
  },
  {
    id: 7,
    name: "Carolinelunden",
    address: "Blokhusvej 32",
    zipcode: "8200 Aarhus",
    rent: "10.500 - 14.700 DKK/mthly.",
    size: "98 - 150 m2",
    status: "37 available",
  },
  {
    id: 8,
    name: "Munkesøhuse",
    address: "Blokhusvej 32",
    zipcode: "8200 Aarhus",
    rent: "10.500 - 14.700 DKK/mthly.",
    size: "98 - 150 m2",
    status: "37 available",
  },
  {
    id: 9,
    name: "Carolinelunden",
    address: "Blokhusvej 32",
    zipcode: "8200 Aarhus",
    rent: "10.500 - 14.700 DKK/mthly.",
    size: "98 - 150 m2",
    status: "55 available",
  },
];
export const unitData = [
  {
    id: 1,
    no: "232",
    address: "Blomsterbjerget 14, 3.tv",
    type: "3-room",
    unit_no: "8-W134",
    m2: "89 m2",
    price: "13.400,00",
    status: "AVAILABLE",
    background_color: "#90C590",
  },
  {
    id: 2,
    no: "232",
    address: "Blomsterbjerget 14, 3.tv",
    type: "3-room",
    unit_no: "8-W134",
    m2: "89 m2",
    price: "13.400,00",
    status: "AVAILABLE",
    background_color: "#90C590",
  },
  {
    id: 3,
    no: "232",
    address: "Blomsterbjerget 14, 3.tv",
    type: "3-room",
    unit_no: "8-W134",
    m2: "89 m2",
    price: "13.400,00",
    status: "RESERVED",
    background_color: "#FFEB80",
  },
  {
    id: 4,
    no: "232",
    address: "Blomsterbjerget 14, 3.tv",
    type: "3-room",
    unit_no: "8-W134",
    m2: "89 m2",
    price: "13.400,00",
    status: "RESERVED",
    background_color: "#FFEB80",
  },
  {
    id: 5,
    no: "232",
    address: "Blomsterbjerget 14, 3.tv",
    type: "3-room",
    unit_no: "8-W134",
    m2: "89 m2",
    price: "13.400,00",
    status: "RESERVED",
    background_color: "#FFEB80",
  },
  {
    id: 6,
    no: "232",
    address: "Blomsterbjerget 14, 3.tv",
    type: "3-room",
    unit_no: "8-W134",
    m2: "89 m2",
    price: "13.400,00",
    status: "SOLD",
    background_color: "#FF8080",
  },
  {
    id: 7,
    no: "232",
    address: "Blomsterbjerget 14, 3.tv",
    type: "3-room",
    unit_no: "8-W134",
    m2: "89 m2",
    price: "13.400,00",
    status: "RESERVED",
    background_color: "#FFEB80",
  },
  {
    id: 8,
    no: "232",
    address: "Blomsterbjerget 14, 3.tv",
    type: "3-room",
    unit_no: "8-W134",
    m2: "89 m2",
    price: "13.400,00",
    status: "RESERVED",
    background_color: "#FFEB80",
  },
  {
    id: 9,
    no: "232",
    address: "Blomsterbjerget 14, 3.tv",
    type: "3-room",
    unit_no: "8-W134",
    m2: "89 m2",
    price: "13.400,00",
    status: "AVAILABLE",
    background_color: "#90C590",
  },
  {
    id: 10,
    no: "232",
    address: "Blomsterbjerget 14, 3.tv",
    type: "3-room",
    unit_no: "8-W134",
    m2: "89 m2",
    price: "13.400,00",
    status: "AVAILABLE",
    background_color: "#90C590",
  },
  {
    id: 11,
    no: "232",
    address: "Blomsterbjerget 14, 3.tv",
    type: "3-room",
    unit_no: "8-W134",
    m2: "89 m2",
    price: "13.400,00",
    status: "AVAILABLE",
    background_color: "#90C590",
  },
  {
    id: 5,
    no: "232",
    address: "Blomsterbjerget 14, 3.tv",
    type: "3-room",
    unit_no: "8-W134",
    m2: "89 m2",
    price: "13.400,00",
    status: "RESERVED",
    background_color: "#FFEB80",
  },
  {
    id: 6,
    no: "232",
    address: "Blomsterbjerget 14, 3.tv",
    type: "3-room",
    unit_no: "8-W134",
    m2: "89 m2",
    price: "13.400,00",
    status: "SOLD",
    background_color: "#FF8080",
  },
  {
    id: 7,
    no: "232",
    address: "Blomsterbjerget 14, 3.tv",
    type: "3-room",
    unit_no: "8-W134",
    m2: "89 m2",
    price: "13.400,00",
    status: "RESERVED",
    background_color: "#FFEB80",
  },
  {
    id: 8,
    no: "232",
    address: "Blomsterbjerget 14, 3.tv",
    type: "3-room",
    unit_no: "8-W134",
    m2: "89 m2",
    price: "13.400,00",
    status: "RESERVED",
    background_color: "#FFEB80",
  },
  {
    id: 9,
    no: "232",
    address: "Blomsterbjerget 14, 3.tv",
    type: "3-room",
    unit_no: "8-W134",
    m2: "89 m2",
    price: "13.400,00",
    status: "AVAILABLE",
    background_color: "#90C590",
  },
  {
    id: 10,
    no: "232",
    address: "Blomsterbjerget 14, 3.tv",
    type: "3-room",
    unit_no: "8-W134",
    m2: "89 m2",
    price: "13.400,00",
    status: "AVAILABLE",
    background_color: "#90C590",
  },
  {
    id: 11,
    no: "232",
    address: "Blomsterbjerget 14, 3.tv",
    type: "3-room",
    unit_no: "8-W134",
    m2: "89 m2",
    price: "13.400,00",
    status: "AVAILABLE",
    background_color: "#90C590",
  },
];

export const AreasTableData = [
  {
    group: "Amager",
    areas_in_the_group: "-",
    created: "30 Sep, 2023",
    created_by: "Thomas Høeg Mogensen",
    level: "Organisation",
  },
  {
    group: "Nordsjælland",
    areas_in_the_group: "Storkøbenhavn",
    created: "30 Sep, 2023",
    created_by: "Thomas Høeg Mogensen",
    level: "Organisation",
  },
  {
    group: "Storkøbenhavn",
    areas_in_the_group: "Storkøbenhavn",
    created: "30 Sep, 2023",
    created_by: "Thomas Høeg Mogensen",
    level: "Organisation",
  },
  {
    group: "Sjælland",
    areas_in_the_group: "Storkøbenhavn",
    created: "30 Sep, 2023",
    created_by: "Thomas Høeg Mogensen",
    level: "Organisation",
  },
  {
    group: "Grønttorvet",
    areas_in_the_group: "Storkøbenhavn",
    created: "30 Sep, 2023",
    created_by: "Thomas Høeg Mogensen",
    level: "Organisation",
  },
  {
    group: "Jylland",
    areas_in_the_group: "Region Midtjylland",
    created: "30 Sep, 2023",
    created_by: "Thomas Høeg Mogensen",
    level: "Organisation",
  },
];
