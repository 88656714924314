import React from "react";
import { Row, Col } from "react-bootstrap";
import TrafficDividedByPlatform from "./TrafficDividedByPlatform";
import TrafficOverview from "./TrafficOverview";
import PopularUnits from "../traffic/PopularUnits";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import HeatMapEiDo from "./HeatMapEiDo";
import PopularComponents from "./PopularComponents";
import PopularFilters from "./PopularFilters";
import Funnel from "./Funnel/Funnel";
import HeatMapWebsite from "./HeatMapWebsite";

const Traffic = () => {
  return (
    <div className="containerStyle pb-3 px-4">
      <Row>
        <Col lg={3} className="py-3">
          <ReactSelect
            clearable={false}
            isSearchable
            //   onChange={(e) => {
            //     onChange(e);
            //     handleZip(e);
            //   }}
            //   options={zipcodeData?.data?.map((zip) => ({
            //     value: zip?.Id,
            //     label: `${zip?.Id} ${zip?.Text}`,
            //   }))}
          />
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <TrafficDividedByPlatform />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <TrafficOverview />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <PopularUnits />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <HeatMapEiDo />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <HeatMapWebsite />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <PopularComponents />
        </Col>
        <Col>
          <PopularFilters />
        </Col>
      </Row>
      <Row>
        <Col>
          <Funnel />
        </Col>
      </Row>
    </div>
  );
};

export default Traffic;
