import React, { useCallback } from "react";
import { toPng, toJpeg, toSvg } from "html-to-image";

export default function JPGWidget({ refObj, fileName }) {
  // JPG
  const jpgClick = useCallback(() => {
    setTimeout(function () {
      if (refObj.current === null) {
        return;
      }

      toJpeg(refObj.current, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `${fileName}.jpeg`;
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    }, 500);
  }, [refObj]);

  return jpgClick;
}
