import React, { useMemo, useState } from "react";
import ETButton from "../../../../../../../../../Components/Buttons/ETButton";
import { Col, Image, Row } from "react-bootstrap";
import FilterComponent from "../../../../../../../../../reactdataTableComponent/FilterComponent";
import { searchPropertyData, unitData } from "../page/data";
import photoComingSoon from "../../../../../../../../../assets/images/photo-coming-soon.jpg";
import CustomCheckBox from "../../../../../../../../../Components/Checkbox/Checkbox";
import MRTTheme from "../../../../../../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";

import {
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../../../../../../../Components/table/MRTGlobalProps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  RadioInput,
  RadioWrapper,
} from "../../../../../../../../../Components/RadioButton/ETRadioButton";

const MoveAnotherUnit = ({
  moveToAnotherUnitsShow,
  setMoveToAnotherUnitsShow,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchdiv, setSearchDiv] = useState(true);
  const [checkedPropertyId, setCheckedPropertyId] = useState(0);
  const [checkedPropertyData, setCheckedPropertyData] = useState([]);
  console.log(checkedPropertyData, "checkedPropertyData");
  // search--------------------//
  const searchField = (e) => {
    setSearchText(e.target.value);
    if (searchText.length > 0) {
      setSearchDiv(true);
    }
  };
  // console.log(checkedPropertyId, "checkedPropertyId");
  // console.log(checkedPropertyData, "checkedPropertyData");
  const handleSelectProperty = (event, data) => {
    setCheckedPropertyId(event.target.id);
    setCheckedPropertyData([data]);
    // console.log(event.target.id, "evwnts");
    // console.log(data, "data");
    // if (event.target.checked) {
    //   setCheckedPropertyId([...checkedPropertyId, data.id]);
    //   setCheckedPropertyData([...checkedPropertyData, data]);
    // } else {
    //   setCheckedPropertyId(
    //     checkedPropertyId.filter((item) => item !== data.id)
    //   );
    //   setCheckedPropertyData(
    //     checkedPropertyData.filter((item) => item !== data)
    //   );
    // }
  };
  const handleBadgeDelete = (data) => {
    // setCheckedPropertyId(checkedPropertyId.filter((item) => item !== data.id));
    setCheckedPropertyId(0);
    setCheckedPropertyData([]);
  };
  const clearAllFilter = () => {
    setCheckedPropertyId(0);
    setCheckedPropertyData([]);
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row, index) => index + 1, //normal accessorKey
        header: "No.",
        size: 30,
      },

      {
        accessorKey: "address",
        header: "Address",
        enableHiding: false,
        size: 240,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "type",
        header: "Type",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "unit_no",
        header: "Unit no.",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "m2",
        header: "m2",
        enableHiding: false,
        size: 80,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "price",
        header: "Price",
        size: 100,
        enableHiding: false,
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => `${row.status} ${row.background_color}`,
        size: 80,
        header: "Status",
        Cell: ({ cell, row }) => {
          return (
            <div
              style={{
                backgroundColor: row.original.background_color,
                textTransform: "uppercase",
                textColor: "#000000",
                whiteSpace: "nowrap",
                fontFamily: "Cerebri sans-medium",
                padding: "2px 4px",
              }}
              className="px-1 fs-12 radius-4 d-flex justify-content-center align-item-center w-100"
            >
              {row.original.status}
            </div>
          );
        },
      },
    ],
    []
  );
  const renderEmptyRowsFallback = () => (
    <div className="d-flex justify-content-center w-100 grey-text py-4">
      <div>No units are selected. You must first select a unit to proceed.</div>
    </div>
  );
  return (
    <div>
      <div className="light-font-text p-4">
        If you want to move this customer to another unit, you can search for a
        specific unit below. The customer’s relationship with the current unit
        will be removed and the customer will be moved to the new selected unit.
        You will find the customer under the same step as they have now.
      </div>
      <div className="px-4  form-label-title">Search for a specific unit</div>
      <div className="px-4 py-3">
        <Row>
          <Col lg={8} className="position-relative d-flex">
            <FilterComponent
              filtertext={searchText}
              handleSearch={(e) => searchField(e)}
              lableText={"Search by property, address, zip code or city"}
            />{" "}
            <div className="ps-2">
              {searchText.length > 0 && searchdiv === true ? (
                <ETButton
                  variant={"secondary"}
                  onClick={() => setSearchDiv(false)}
                  buttonText={"Close"}
                />
              ) : (
                <ETButton
                  variant={"primary"}
                  onClick={() => setSearchDiv(true)}
                  buttonText={"Open"}
                />
              )}
            </div>
            {searchText.length > 0 && searchdiv === true && (
              <div>
                <div className="bg-white radius-8 pe-1 search-property-container">
                  {searchText.length >= 1 && (
                    <>
                      {searchPropertyData.length > 0 ? (
                        <div
                          className="scrollable-container-y light-font-text"
                          style={{
                            height: "300px",
                            overflowY: "scroll",
                            overflowX: "hidden",
                          }}
                        >
                          {searchPropertyData.map((e) => (
                            <div key={e.id}>
                              <Row
                                className=" pe-1 addpropContent"
                                style={{
                                  borderBottom: "1px solid #D1D1D1",
                                }}
                                onClick={() => {
                                  // setSearchDiv(false);
                                }}
                              >
                                <Col xl={5} lg={6} sm={6}>
                                  <div className="d-flex py-3">
                                    <div className="d-flex justify-content-center align-items-center px-1">
                                      <RadioWrapper>
                                        <RadioInput
                                          type="radio"
                                          onChange={(event) =>
                                            handleSelectProperty(event, e)
                                          }
                                          name={"select property"}
                                          checked={
                                            checkedPropertyId == e.id
                                              ? true
                                              : false
                                          }
                                          value={e.id}
                                          id={e.id}
                                        />
                                        {/* <RadioLabel>{label}</RadioLabel> */}
                                      </RadioWrapper>
                                      {/* <CustomCheckBox
                                        id={e.id}
                                        checked={checkedPropertyId.includes(
                                          e.id
                                        )}
                                        onChange={(event) =>
                                          handleSelectProperty(event, e)
                                        }
                                      /> */}
                                    </div>
                                    <div>
                                      <Image
                                        src={photoComingSoon}
                                        alt="propertImage"
                                        width="55px"
                                        height="55px"
                                        rounded
                                      />
                                    </div>
                                    <div className="my-md-0 my-1 ps-3">
                                      <div className="m-0 form-label-title">
                                        {e.name}
                                      </div>
                                      <div
                                        className="light-font-text w-100 ellipsis"
                                        style={{ lineHeight: "15px" }}
                                      >
                                        <div>{e.address}</div>
                                        <div>{e.zipcode}</div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col
                                  xl={4}
                                  lg={3}
                                  sm={3}
                                  className="d-flex  align-items-center"
                                >
                                  <div className="d-flex flex-column py-3">
                                    <span>{e.rent}</span>
                                    <span>{e.size}</span>
                                  </div>
                                </Col>
                                <Col lg={3} sm={3} className="py-3">
                                  <div className="pt-2">{e.status}</div>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="light-font-text py-2 px-3">
                          Sorry, it was not possible to find a match among
                          existing property. Please create a new property.
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
      <div
        style={{ height: "75px", flexWrap: "wrap", overflowY: "scroll" }}
        className="px-4 pb-2 d-flex gap-1"
      >
        {checkedPropertyData?.length !== 0 && (
          <div
            className="clear-all-filters fs-14 cursor-pointer"
            onClick={() => clearAllFilter()}
          >
            Clear all filters
          </div>
        )}
        {checkedPropertyData?.map((item, index) => (
          <div key={index}>
            <span className="radius-4 border filtered-property-badge fs-14">
              <FontAwesomeIcon
                icon="fa-regular fa-circle-xmark"
                size="sm"
                style={{ color: "#000000", cursor: "pointer" }}
                onClick={() => handleBadgeDelete(item)}
              />
              <span className="ps-2"> {item.name} </span>
            </span>
          </div>
        ))}
      </div>
      <hr className="hr m-0"></hr>
      <div className="form-label-title px-4 pt-4 pb-2">Select unit</div>

      <div
        className="ps-4 pe-2 pt-2 pb-5  me-1"
        // style={{ height: "350px", overflowY: "hidden" }}
      >
        <MRTTheme
          colorVariantsForTableData={["odd"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={columns}
              data={checkedPropertyId == 0 ? [] : unitData} //data is undefined on first render
              icons={{...SortIcon}}
              renderEmptyRowsFallback={renderEmptyRowsFallback}
              enableColumnActions={false}
              enableRowSelection={true}
              enableRowActions={false}
              enableSelectAll={false}
              enableTopToolbar={false}
              enableGlobalFilter={false}
              enableMultiRowSelection={false}
              enablePagination={false}
              enableRowNumbers={false}
              enableRowVirtualization
              enableBottomToolbar={false}
              initialState={{
                density: "compact",
                showGlobalFilter: false,
                showColumnFilters: false,
                expanded: false,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                  maxHeight: 500,
                },
              }}
              muiSelectCheckboxProps={{
                sx: {
                  color: "#d1d1d1",
                  "& .MuiSvgIcon-root": {
                    fontSize: "1.2rem !important",
                  },
                },
              }}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "",
                },
                "mrt-row-select": {
                  size: 40,
                  header: "",
                },
              }}
              muiTableBodyRowProps={{
                selected: false,
              }}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
            />
          }
        />
      </div>
      <div className="py-2 px-3 d-flex flex-row justify-content-between  footer-section-button-container">
        <ETButton
          variant={"white"}
          buttonText="CANCEL"
          onClick={() => setMoveToAnotherUnitsShow(false)}
        />

        <ETButton
          variant={"primary"}
          buttonText="NEXT"
          type={"submit"}
          onClick={() => setMoveToAnotherUnitsShow(false)}
        />
      </div>
    </div>
  );
};

export default MoveAnotherUnit;
