import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

const NormalTextField = ({
  placeholder,
  id,
  value,
  onChange,
  Icons,
  type,
  maxlength,
  style,
  onClick,
}) => {
  return (
    <div className="d-flex w-100" style={{ position: "relative" }}>
      <div style={{ position: "relative", width: "100%" }}>
        <div className="normal-input">
          <input
            autoComplete="off"
            style={style}
            type={type}
            placeholder={placeholder}
            id={id}
            value={value}
            onChange={onChange}
            maxLength={maxlength}
          />
          <label htmlFor={id}>{placeholder}</label>
        </div>
      </div>
      <div style={{ position: "absolute", right: "20px", top: "8px" }}>
        <button
          onClick={onClick}
          style={{ height: "23px", width: "23px" }}
          className="document-property-table-btn d-flex justify-content-center align-items-center"
        >
          <FontAwesomeIcon icon="fa-regular fa-trash" size="sm" />
        </button>
      </div>
    </div>
  );
};

export default NormalTextField;
