import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { DropZoneBorder, ETIcon, GreenSpan } from "../../styles/Common.style";

import "./imageuploader.css";
import FilesSvg from "../../../src/assets/images/file.svg";

import { Col, Row } from "react-bootstrap";

import CustomPopup from "../CustomPopup";
import ETButton from "../Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./imageuploader.css";

const EtImageUploader = ({
  myFile,
  setMyFile = () => {},
  usedFor = "image",
  initialFileName,
  initialFileUrl,
  initialFileSize = "",
  recommendedImageSize = "300 x 200 px",
  onFileUploaded = () => {},
  // handleUpload = () => {},

  setThumbnailFileName = () => {},
}) => {
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [receivedFileName, setReceivedFileName] = useState(initialFileName);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageSizeRatio, setImageSizeRatio] = useState(null);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [imageIsSet, setImageIsSet] = useState(false);
  const [showImageOnUpload, setShowImageOnUpload] = useState(null);
  const [showImageSizeRatioFromApi, setShowImageSizeRatioFromApi] =
    useState("");

  //------------------------------------------ dropzone ------------------------------------//
  const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxSize: 5242880,
    onDrop: (accepted) => setAcceptedFiles([...acceptedFiles, ...accepted]),
    // disabled: PostUploadDocumentIsLoading,
  });

  // ------------------------------ functions -----------------------------------------------//
  const handleDeleteImage = () => {
    setAcceptedFiles([]);
    setImageIsSet(false);
  };

  const handleOnClickUpload = async () => {
    setMyFile(acceptedFiles?.length === 0 ? myFile : acceptedFiles);

    onFileUploaded(acceptedFiles?.length === 0 ? "" : acceptedFiles);
    setShowImageOnUpload(imagePreview);
    setThumbnailFileName({
      url: imagePreview,
      name: receivedFileName,
      fileSize: fileSize,
    });
    setShowUploadPopup(false);
    setShowImageSizeRatioFromApi(imageSizeRatio);
    setAcceptedFiles([]);
    // handleUpload(acceptedFiles?.[0]);
  };

  const handleCancel = () => {
    setImageIsSet(false);

    setAcceptedFiles([]);
    // console.log(initialFileUrl, "initialFileUrl test cancel");
    setMyFile(myFile);
    setImagePreview(initialFileUrl);
    setShowUploadPopup(false);
  };

  // -------------- all useEffect -------------------------------//
  useEffect(() => {
    if (myFile?.length > 0 || typeof myFile === "object") {
      setImageIsSet(true);
    }
  }, [myFile, showUploadPopup]);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      // setReceivedFile(true);
      setReceivedFileName(acceptedFiles[0]?.path || "");
      // setMyFile(acceptedFiles);
      // console.log(
      //   myFile,
      //   acceptedFiles,
      //   imagePreview,
      //   initialFileUrl,
      //   "first condition run"
      // );
      if (usedFor === "image") {
        const previewURL = URL.createObjectURL(acceptedFiles?.[0] || "");
        setImagePreview(previewURL);

        // Get image size and calculate ratio
        const img = new Image();
        img.src = previewURL;
        img.onload = () => {
          const ratio = img.width + " x " + img.height + " px";
          setImageSizeRatio(ratio);
          // setShowImageSizeRatioFromApi(ratio);
        };
      }
      setImageIsSet(true);
    } else {
      // console.log(
      //   myFile,
      //   acceptedFiles,
      //   imagePreview,
      //   initialFileUrl,
      //   "2nd condition run"
      // );
      if (usedFor === "image") {
        // setImagePreview(initialFileUrl);
        // Get image size and calculate ratio
        const img = new Image();
        img.src = initialFileUrl;
        img.onload = () => {
          const ratio = img.width + " x " + img.height + " px";
          setImageSizeRatio(ratio);
          setShowImageSizeRatioFromApi(ratio);
        };
      }
      setReceivedFileName(initialFileName);
      setImagePreview(initialFileUrl);
      setMyFile(myFile);
    }
  }, [acceptedFiles, initialFileName, showUploadPopup, myFile, setMyFile]);

  // console.log(
  //   myFile,
  //   imageIsSet,
  //   myFile?.length,
  //   typeof myFile,
  //   showImageSizeRatioFromApi,
  //   " myFile,"
  // );
  // console.log(
  //   initialFileName,
  //   showImageSizeRatioFromApi,
  //   showImageOnUpload,
  //   "condition test"
  // );
  // console.log(
  //   acceptedFiles,
  //   imagePreview,
  //   initialFileName,
  //   initialFileUrl,
  //   showImageOnUpload,
  //   "myAccepted File"
  // );
  // ----------------------------------------- const component ----------------------------//
  const fileSize = acceptedFiles?.length
    ? acceptedFiles?.map((file) => (
        <div key={file?.path}>{(file?.size / 1048576).toFixed(2)}</div>
      ))
    : initialFileSize;

  const fileRejectionItems = fileRejections.map(({ file, errors }, index) => (
    <div key={file.path}>
      {index === 0 && (
        <>
          {errors.map((e) => (
            <div key={e.code} className="error-msg fs-14 ps-0">
              {e.code == "file-too-large"
                ? "File is larger than 5MB"
                : e.message}
            </div>
          ))}
        </>
      )}
    </div>
  ));

  return (
    <>
      <div className="light-font-text pb-1">
        Recommended logo size: {recommendedImageSize}
      </div>
      <div className="p-3 gap-3 d-flex align-items-center et-dropzone-container-new justify-content-between">
        <div className="d-flex align-items-center">
          {initialFileUrl ? (
            <img
              className="rounded"
              src={initialFileUrl}
              // alt={altText}

              style={{ width: "100px", height: "68px" }}
            />
          ) : showImageOnUpload ? (
            <img
              className="rounded"
              src={showImageOnUpload}
              // alt={altText}

              style={{ width: "100px", height: "68px" }}
            />
          ) : (
            <div
              className="d-flex align-items-center radius-4 justify-content-center"
              style={{
                width: "100px",
                height: "68px",
                backgroundColor: "#fff",
              }}
            >
              <ETIcon className="fileIcon ">
                <img src={FilesSvg} style={{ width: "27px", height: "39px" }} />
              </ETIcon>
            </div>
          )}
          <div className=" fs-12 ps-3 light-font-text">
            <div style={{}} className="d-flex gap-2">
              <div>File name :</div>
              <div>
                {initialFileName
                  ? initialFileName
                  : showImageOnUpload
                  ? receivedFileName
                  : ""}
              </div>
            </div>
            <div style={{}} className="d-flex gap-2">
              <div>File size :</div>
              <div className="d-flex">
                {initialFileSize ? initialFileSize : fileSize}{" "}
                {initialFileName && "MB"}
              </div>
            </div>
            <div style={{}} className="d-flex gap-2">
              <div>Image size :</div>
              <div>
                {initialFileName
                  ? showImageSizeRatioFromApi
                  : showImageOnUpload
                  ? imageSizeRatio
                  : ""}
                {/* {showImageOnUpload
                  ? imageSizeRatio
                  : initialFileName
                  ? showImageSizeRatioFromApi
                  : ""} */}
              </div>
            </div>
          </div>
        </div>
        <div>
          <ETButton
            // buttonText={imagePreview ? "Change" : "Upload"}
            buttonText={myFile?.length == 0 ? "Upload" : "Change"}
            variant={"white"}
            type="button"
            onClick={() => setShowUploadPopup(true)}
          />
        </div>
      </div>
      <CustomPopup
        open={showUploadPopup}
        // onHide={() => {
        //   setUploadDocument(false);
        // }}
        title="Upload"
        title_color="black"
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Upload"}
        size="md"
      >
        <div className="p-4">
          <div className="">
            <div className="  light-font-text">
              <div style={{}} className="d-flex gap-2 form-label-title pb-2">
                <div>File name :</div>
                <div>{imageIsSet && receivedFileName}</div>
              </div>
              <div style={{}} className="d-flex gap-2">
                <div>File size :</div>
                <div className="d-flex">
                  {imageIsSet && fileSize} {imageIsSet && " MB"}
                </div>
              </div>
              <div style={{}} className="d-flex gap-2">
                <div>Image size :</div>
                <div>{imageIsSet && imageSizeRatio}</div>
              </div>
            </div>
          </div>

          {imageIsSet === false ? (
            <Row className="mt-3 ">
              <Col md={{ span: 12 }}>
                <div className="pb-4">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <DropZoneBorder
                      className="py-5 d-flex flex-column justify-content-center align-items-center"
                      style={{ height: "278px" }}
                    >
                      <ETIcon className="fileIcon ">
                        <img
                          src={FilesSvg}
                          style={{ width: "27px", height: "39px" }}
                        />
                      </ETIcon>
                      <div className="mt-2 mb-1 form-label-title">
                        Drop your file here, or&nbsp;
                        <GreenSpan
                          style={{
                            // textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={open}
                        >
                          browse your computer
                        </GreenSpan>
                      </div>
                      <div className="created-by">
                        Supports only PNG, JPG (Max. file size 5MB)
                      </div>
                      <div className="created-by">
                        Recommended size: 300 x 200 px{" "}
                      </div>
                    </DropZoneBorder>
                  </div>
                  <aside>
                    <div
                      style={{
                        // listStyleType: "none",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      {fileRejectionItems}
                    </div>
                  </aside>
                </div>
              </Col>
            </Row>
          ) : (
            <Row className="mt-3  upload-manually-document-table">
              <Col md={{ span: 12 }}>
                <div className="pb-4 " style={{ position: "relative" }}>
                  <div
                    style={{ position: "absolute", right: "16px", top: "16px" }}
                  >
                    {" "}
                    <button
                      type="button"
                      className="icon-btn"
                      style={{
                        width: "30px",
                        height: "30px",
                      }}
                      onClick={handleDeleteImage}
                    >
                      <FontAwesomeIcon icon="fa-regular fa-trash" />
                    </button>
                  </div>
                  <img
                    src={imagePreview}
                    style={{
                      height: "278px",
                      width: "100%",
                      borderRadius: "4px",
                    }}
                  />
                </div>
              </Col>
            </Row>
          )}
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText={imageIsSet === false ? "CANCEL" : "Close"}
            onClick={handleCancel}
          />
          <ETButton
            variant={"primary"}
            buttonText="Upload"
            onClick={handleOnClickUpload}
            disabled={imageIsSet === false}
          />
        </div>
      </CustomPopup>
    </>
  );
};
export default EtImageUploader;
