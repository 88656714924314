import React, { useEffect, useState } from "react";
import { H2, H3, H4, H5 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { Col, Form, Row } from "react-bootstrap";
import Toggle from "../../../../Components/Switch/Switch";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import {
  useOrganizationId,
  useOrganizationSettingsBreadcrumbsTabs,
} from "../../../../hooks/stores/useOrganizationStore";
import ColorInputField from "../../../../Components/ColorInput/ColorInput";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";
import {
  useGetMainSubStatus,
  usePutUpdateMainSubStatus,
} from "../../../../hooks/queries/useUnitQueries";
import { toast } from "react-toastify";
import {
  ErrorAlert,
  LoadingAlert,
  SucessAlert,
} from "../../../../Components/alerts/AlertsAndMessages";
import { status } from "../../../../model/Constant";
import { ColorWithOpacityHexConverter } from "../../../../utils/CommonFunction";
import { useQueryClient } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MainSubStatusSystemDefaults = () => {
  const {
    register,
    getValues,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      availableMainStatus: "#000000",
      availableSubStatus: "#000000",
      availableOpacity: "100",
      inProcessMainStatus: "#000000",
      inProcessSubStatus: "#000000",
      inProcessOpacity: "100",
      completedMainStatus: "#000000",
      completedSubStatus: "#000000",
      completedOpacity: "100",
      notActiveMainStatus: "#000000",
      notActiveSubStatus: "#000000",
      notActiveOpacity: "100",

      isSystemStandard: false,
    },
  });

  //=============== zustand ===============//
  const setOrganizationBreadcrumbsTabs = useOrganizationSettingsBreadcrumbsTabs(
    (state) => state.setOrganizationBreadcrumbsTabs
  );
  const { organizationId } = useOrganizationId((state) => state);

  //=================== useStates & constants ==================//
  const [timeOutId, setTimeOutId] = useState(null);

  //=================== React queries =====================//
  const queryClient = useQueryClient();
  const { data: GetMainSubStatusData, refetch: GetMainSubStatusDataRefetch } =
    useGetMainSubStatus(organizationId, "1"); //Type will be 1 for residential (For now)
  const {
    mutateAsync: PutUpdateMainSubStatus,
    isLoading: PutUpdateMainSubStatusIsLoading,
  } = usePutUpdateMainSubStatus();

  //============ UseEffects ================//

  useEffect(() => {
    if (GetMainSubStatusData?.data !== null) {
      const isSameArray = compareArrays(
        GetMainSubStatusData?.data?.mainstatus?.system,
        GetMainSubStatusData?.data?.mainstatus?.custom
      );
      if (GetMainSubStatusData?.data?.mainstatus?.custom?.length) {
        setValue("isSystemStandard", isSameArray);
      } else {
        setValue("isSystemStandard", true);
      }
      handlePrefillFormValues(
        GetMainSubStatusData?.data?.mainstatus?.custom?.length
          ? isSameArray
          : true
      );
    }

    return () => {};
  }, [GetMainSubStatusData]);

  //============= function to prefil fields when API is fetched ================ //
  const handlePrefillFormValues = (isSameArray = false) => {
    const statusValue = isSameArray ? "system" : "custom";
    const availableMainSubstatus = GetMainSubStatusData?.data?.mainstatus?.[
      `${statusValue}`
    ]?.filter((x) => x?.id?.toString() === "2")?.[0];

    const inProcessMainSubstatus = GetMainSubStatusData?.data?.mainstatus?.[
      `${statusValue}`
    ]?.filter((x) => x?.id?.toString() === "4")?.[0];

    const completedMainSubstatus = GetMainSubStatusData?.data?.mainstatus?.[
      `${statusValue}`
    ]?.filter((x) => x?.id?.toString() === "5")?.[0];

    const notActiveMainSubstatus = GetMainSubStatusData?.data?.mainstatus?.[
      `${statusValue}`
    ]?.filter((x) => x?.id?.toString() === "1")?.[0];

    setValue("availableMainStatus", availableMainSubstatus?.bg_color);
    setValue("availableSubStatus", availableMainSubstatus?.substatus_bg_color);
    setValue(
      "availableOpacity",
      GetMainSubStatusData?.data?.mainstatus?.system?.filter(
        (x) => x?.id?.toString() === "2"
      )?.[0]?.opacity
    );

    setValue("inProcessMainStatus", inProcessMainSubstatus?.bg_color);
    setValue("inProcessSubStatus", inProcessMainSubstatus?.substatus_bg_color);
    setValue(
      "inProcessOpacity",
      GetMainSubStatusData?.data?.mainstatus?.system?.filter(
        (x) => x?.id?.toString() === "4"
      )?.[0]?.opacity
    );

    setValue("completedMainStatus", completedMainSubstatus?.bg_color);
    setValue("completedSubStatus", completedMainSubstatus?.substatus_bg_color);
    setValue(
      "completedOpacity",
      GetMainSubStatusData?.data?.mainstatus?.system?.filter(
        (x) => x?.id?.toString() === "5"
      )?.[0]?.opacity
    );

    setValue("notActiveMainStatus", notActiveMainSubstatus?.bg_color);
    setValue("notActiveSubStatus", notActiveMainSubstatus?.substatus_bg_color);
    setValue(
      "notActiveOpacity",
      GetMainSubStatusData?.data?.mainstatus?.system?.filter(
        (x) => x?.id?.toString() === "1"
      )?.[0]?.opacity
    );
  };

  //================= handle auto save ================//
  const handleAutoSave = (duration = 3000) => {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    const saveTimeout = duration;
    const newTimeOutId = setTimeout(() => {
      handleSubmit(handleUpdateMainSubStatus)();
    }, saveTimeout);
    setTimeOutId(newTimeOutId);
  };

  //================= handle is system standard ===============//
  const handleIsSystemStandard = (e) => {
    handlePrefillFormValues(true);
    setValue("isSystemStandard", e.target.checked);
    handleAutoSave(100);
  };

  //============= handle update main sub status ================//
  const handleUpdateMainSubStatus = async (data) => {
    const availableMainSubstatus =
      GetMainSubStatusData?.data?.mainstatus?.system?.filter(
        (x) => x?.id?.toString() === "2"
      )?.[0];
    const inProcessMainSubstatus =
      GetMainSubStatusData?.data?.mainstatus?.system?.filter(
        (x) => x?.id?.toString() === "4"
      )?.[0];
    const completedMainSubstatus =
      GetMainSubStatusData?.data?.mainstatus?.system?.filter(
        (x) => x?.id?.toString() === "5"
      )?.[0];
    const notActiveMainSubstatus =
      GetMainSubStatusData?.data?.mainstatus?.system?.filter(
        (x) => x?.id?.toString() === "1"
      )?.[0];
    const mainSubStatusPayload = {
      organisation_id: organizationId,
      mainstatus: {
        system: [
          {
            id: 1,
            opacity: notActiveMainSubstatus?.opacity,
            bg_color: notActiveMainSubstatus?.bg_color,
            substatus_bg_color: notActiveMainSubstatus?.substatus_bg_color,
          },
          {
            id: 2,
            opacity: availableMainSubstatus?.opacity,
            bg_color: availableMainSubstatus?.bg_color,
            substatus_bg_color: availableMainSubstatus?.substatus_bg_color,
          },
          {
            id: 4,
            opacity: inProcessMainSubstatus?.opacity,
            bg_color: inProcessMainSubstatus?.bg_color,
            substatus_bg_color: inProcessMainSubstatus?.substatus_bg_color,
          },
          {
            id: 5,
            opacity: completedMainSubstatus?.opacity,
            bg_color: completedMainSubstatus?.bg_color,
            substatus_bg_color: completedMainSubstatus?.substatus_bg_color,
          },
        ],
        custom: [
          {
            id: 1,
            opacity: data?.notActiveOpacity,
            bg_color: data?.notActiveMainStatus,
            substatus_bg_color: data?.notActiveSubStatus,
          },
          {
            id: 2,
            opacity: data?.availableOpacity,
            bg_color: data?.availableMainStatus,
            substatus_bg_color: data?.availableSubStatus,
          },
          {
            id: 4,
            opacity: data?.inProcessOpacity,
            bg_color: data?.inProcessMainStatus,
            substatus_bg_color: data?.inProcessSubStatus,
          },
          {
            id: 5,
            opacity: data?.completedOpacity,
            bg_color: data?.completedMainStatus,
            substatus_bg_color: data?.completedSubStatus,
          },
        ],
      },
    };

    let id;
    if (!PutUpdateMainSubStatusIsLoading) {
      id = toast.loading(<LoadingAlert />);
    }
    try {
      const response = await PutUpdateMainSubStatus(mainSubStatusPayload, {
        onSuccess: () => {
          queryClient.invalidateQueries("qGetOrganizationOverviewById");
        },
      });
      if (response?.data?.bReturn) {
        toast.update(id, {
          render: <SucessAlert />,
          isLoading: false,
          type: status.s,
          autoClose: 2000,
        });
        GetMainSubStatusDataRefetch();
      } else {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.update(id, {
        render: <ErrorAlert />,
        isLoading: false,
        type: status.e,
        autoClose: 2000,
      });
    }
  };

  //==================== function to compare two arrays ===============//
  function compareArrays(arr1, arr2) {
    if (arr1?.length !== arr2?.length) {
      return false; // Arrays are of different lengths, not equal
    }

    for (let i = 0; i < arr1?.length; i++) {
      // Compare bg_color and substatus_bg_color properties
      if (
        arr1[i]["bg_color"] !== arr2[i]["bg_color"] ||
        arr1[i]["substatus_bg_color"] !== arr2[i]["substatus_bg_color"]
      ) {
        return false; // Objects at index i are not equal based on colors
      }
    }
    return true; // Arrays are equal
  }

  return (
    <>
      <Form onSubmit={handleSubmit(handleUpdateMainSubStatus)}>
        {/* Colours for main and sub status */}
        <div className=" p-4 border-bottom">
          <div className="d-flex align-items-center gap-2 ">
            <H3>Colours for main and sub status</H3>
            <CustomToolTip size="sm" text="Colours for main and sub status" />
          </div>
          <div className="paragraph1-light mt-3 mb-2" style={{ width: "60%" }}>
            Here you have the opportunity to change the colours for the systems
            main and sub-statuses.
          </div>

          <div className="d-flex align-items-center mt-40 gap-2">
            <H4>Use system default colors</H4>
            <CustomToolTip size="sm" text="Use system default colors" />
          </div>
          <div className="paragraph2-light mt-3 mb-4" style={{ width: "60%" }}>
            Choose whether to use the system's default colours or if you want to
            set your own colours.
          </div>
          <div className="d-flex align-items-center gap-2 mb-4">
            <div>
              {" "}
              <Controller
                control={control}
                {...register("isSystemStandard")}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <Toggle
                    id={"isSystemStandard"}
                    checked={value}
                    onChange={(e) => {
                      onChange(e);
                      handleIsSystemStandard(e);
                    }}
                  />
                )}
              />
            </div>
            <div className="paragraph2-regular ">Use system standard</div>
          </div>

          <div style={{ opacity: watch("isSystemStandard") ? "0.6" : "1" }}>
            {/* Available */}
            <div className="d-flex align-items-center gap-2 mb-3">
              <H4>Available</H4>
              <CustomToolTip size="sm" text="Available" />
            </div>
            <div className="paragraph2-light mb-3">
              Define which colour to use throughout the system when a unit is in
              this status.
            </div>
            <Row className="mb-4">
              <Col lg={3}>
                <div>
                  <H5 className="mb-2">Main status</H5>
                  <Controller
                    control={control}
                    {...register("availableMainStatus")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value = "#000000" } }) => (
                      <ColorInputField
                        label="Main status"
                        value={value}
                        isDisabled={watch("isSystemStandard")}
                        onChange={(e) => {
                          onChange(e);
                          setValue(
                            "availableSubStatus",
                            getValues("availableMainStatus") +
                              ColorWithOpacityHexConverter(
                                getValues("availableOpacity")
                              )
                          );
                          handleAutoSave();
                        }}
                        iserror={errors?.availableMainStatus}
                      />
                    )}
                  />
                  {errors?.availableMainStatus && (
                    <p className="error-msg">
                      {" "}
                      <div className=" d-flex align-items-center gap-1">
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-exclamation "
                          size="sm"
                          style={{ color: "#e33716" }}
                        />{" "}
                        {REQUIRED_ERR}
                      </div>
                    </p>
                  )}
                </div>
              </Col>
              <Col lg={3} className="px-1">
                <div>
                  <H5 className="mb-2">Sub status</H5>
                  <Controller
                    control={control}
                    {...register("availableSubStatus")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <ColorInputField
                        isDisabled={true}
                        label="Sub status"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors?.availableSubStatus && (
                    <p className="error-msg"> {REQUIRED_ERR}</p>
                  )}
                </div>
              </Col>
            </Row>

            {/* In process */}
            <div className="d-flex align-items-center gap-2 mb-3 mt-4">
              <H4>In process</H4>
              <CustomToolTip size="sm" text="In process" />
            </div>
            <div className="paragraph2-light mb-3">
              Define which colour to use throughout the system when a unit is in
              this status.
            </div>
            <Row className="mb-4">
              <Col lg={3}>
                <div>
                  <H5 className="mb-2">Main status</H5>
                  <Controller
                    control={control}
                    {...register("inProcessMainStatus")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value = "#000000" } }) => (
                      <ColorInputField
                        label="Main status"
                        value={value}
                        isDisabled={watch("isSystemStandard")}
                        onChange={(e) => {
                          onChange(e);
                          setValue(
                            "inProcessSubStatus",
                            getValues("inProcessMainStatus") +
                              ColorWithOpacityHexConverter(
                                getValues("inProcessOpacity")
                              )
                          );
                          handleAutoSave();
                        }}
                        iserror={errors?.inProcessMainStatus}
                      />
                    )}
                  />
                  {errors?.inProcessMainStatus && (
                    <p className="error-msg">
                      <div className=" d-flex align-items-center gap-1">
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-exclamation "
                          size="sm"
                          style={{ color: "#e33716" }}
                        />{" "}
                        {REQUIRED_ERR}
                      </div>
                    </p>
                  )}
                </div>
              </Col>
              <Col lg={3} className="px-1">
                <div>
                  <H5 className="mb-2">Sub status</H5>
                  <Controller
                    control={control}
                    {...register("inProcessSubStatus")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <ColorInputField
                        isDisabled={true}
                        label="Sub status"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors?.inProcessSubStatus && (
                    <p className="error-msg"> {REQUIRED_ERR}</p>
                  )}
                </div>
              </Col>
            </Row>

            {/* Completed */}
            <div className="d-flex align-items-center gap-2 mb-3">
              <H4>Completed</H4>
              <CustomToolTip size="sm" text="Completed" />
            </div>
            <div className="paragraph2-light mb-3">
              Define which colour to use throughout the system when a unit is in
              this status.
            </div>
            <Row className="mb-4">
              <Col lg={3}>
                <div>
                  <H5 className="mb-2">Main status</H5>
                  <Controller
                    control={control}
                    {...register("completedMainStatus")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value = "#000000" } }) => (
                      <ColorInputField
                        label="Main status"
                        value={value}
                        isDisabled={watch("isSystemStandard")}
                        onChange={(e) => {
                          onChange(e);
                          setValue(
                            "completedSubStatus",
                            getValues("completedMainStatus") +
                              ColorWithOpacityHexConverter(
                                getValues("completedOpacity")
                              )
                          );
                          handleAutoSave();
                        }}
                        iserror={errors?.completedMainStatus}
                      />
                    )}
                  />
                  {errors?.completedMainStatus && (
                    <p className="error-msg">
                      {" "}
                      <div className=" d-flex align-items-center gap-1">
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-exclamation "
                          size="sm"
                          style={{ color: "#e33716" }}
                        />{" "}
                        {REQUIRED_ERR}
                      </div>
                    </p>
                  )}
                </div>
              </Col>
              <Col lg={3} className="px-1">
                <div>
                  <H5 className="mb-2">Sub status</H5>
                  <Controller
                    control={control}
                    {...register("completedSubStatus")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <ColorInputField
                        isDisabled={true}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        label="Sub status"
                      />
                    )}
                  />
                  {errors?.completedSubStatus && (
                    <p className="error-msg"> {REQUIRED_ERR}</p>
                  )}
                </div>
              </Col>
            </Row>
            {/* Not active */}
            <div className="d-flex align-items-center gap-2 mb-3">
              <H4>Not active</H4>
              <CustomToolTip size="sm" text="Not active" />
            </div>
            <div className="paragraph2-light mb-3">
              Define which colour to use throughout the system when a unit is in
              this status.
            </div>
            <Row className="mb-4">
              <Col lg={3}>
                <div>
                  <H5 className="mb-2">Main status</H5>
                  <Controller
                    control={control}
                    {...register("notActiveMainStatus")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value = "#000000" } }) => (
                      <ColorInputField
                        label="Main status"
                        value={value}
                        isDisabled={watch("isSystemStandard")}
                        onChange={(e) => {
                          onChange(e);
                          setValue(
                            "notActiveSubStatus",
                            getValues("notActiveMainStatus") +
                              ColorWithOpacityHexConverter(
                                getValues("notActiveOpacity")
                              )
                          );
                          handleAutoSave();
                        }}
                        iserror={errors?.notActiveMainStatus}
                      />
                    )}
                  />
                  {errors?.notActiveMainStatus && (
                    <p className="error-msg">
                      {" "}
                      <div className=" d-flex align-items-center gap-1">
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-exclamation "
                          size="sm"
                          style={{ color: "#e33716" }}
                        />{" "}
                        {REQUIRED_ERR}
                      </div>
                    </p>
                  )}
                </div>
              </Col>
              <Col lg={3} className="px-1">
                <div>
                  <H5 className="mb-2">Sub status</H5>
                  <Controller
                    control={control}
                    {...register("notActiveSubStatus")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <ColorInputField
                        isDisabled={true}
                        label="Sub status"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors?.notActiveSubStatus && (
                    <p className="error-msg"> {REQUIRED_ERR}</p>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </>
  );
};

export default MainSubStatusSystemDefaults;
