import React, { useMemo, useCallback, useState, useRef } from "react";
import PNGWidget from "../../../Components/DownloadWidget/PNGWidget";
import JPGWidget from "../../../Components/DownloadWidget/JPGWidget";
import SVGWidget from "../../../Components/DownloadWidget/SVGWidget";
import { Button, Col, Row } from "react-bootstrap";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import Menus from "../../../Components/Menus/Menus";
import { MaterialReactTable } from "material-react-table";
import "../../../styles/css/control-center/customers.css";
import Toggle from "../../../Components/Switch/Switch";
import { Bar } from "react-chartjs-2";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import MRTTheme from "../../../Components/table/MRTTheme";
import { useGetTransactionsDividedByAge } from "../../../hooks/queries/useControlCenterQueries";
import { useControlCenter } from "../../../hooks/stores/useControlCenterStore";
import { H2, H3 } from "../../../styles/Common.style";
import { SortIcon } from "../../../Components/table/MRTGlobalProps";

const transactionByAgeData = [
  {
    Age: "18 - 24",
    Tenants: "0",
    Percentage: "0%",
    TotalAnnualRent: "0 DKK",
  },
  {
    Age: "25 - 34",
    Tenants: "0",
    Percentage: "0%",
    TotalAnnualRent: "0 DKK",
  },
  {
    Age: "35 - 44",
    Tenants: "3",
    Percentage: "0.25%",
    TotalAnnualRent: "356.400 DKK",
  },
  {
    Age: "45 - 54",
    Tenants: "0",
    Percentage: "0%",
    TotalAnnualRent: "0 DKK",
  },
  {
    Age: "55 - 64",
    Tenants: "725",
    Percentage: "59.43%",
    TotalAnnualRent: "112.762.536 DKK",
  },
  {
    Age: "55 - 64",
    Tenants: "0",
    Percentage: "0%",
    TotalAnnualRent: "0 DKK",
  },
  {
    Age: "Over 75",
    Tenants: "0",
    Percentage: "0%",
    TotalAnnualRent: "0 DKK",
  },
];
const TransactionByAge = () => {
  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "transaction-age";

  const PNGFunction = PNGWidget({ refObj, fileName });
  const JPGFunction = JPGWidget({ refObj, fileName });
  const SVGFunction = SVGWidget({ refObj, fileName });
  const [compareDataEvents, setCompareDataEvents] = useState(false);
  const { selectedProperties, selectedDate, selectedEndDate } =
    useControlCenter((state) => state);
  const { data: getTransactionsDividedByAgeData } =
    useGetTransactionsDividedByAge(
      selectedProperties,
      selectedDate,
      selectedEndDate
    );
  const ageGroup =
    getTransactionsDividedByAgeData?.data?.map((data) => data.age_group) || [];
  const transactionByAgeColumns = useMemo(
    () => [
      {
        accessorKey: "age_group",
        header: "Age",
        size: 120,
        Cell: ({ row }) => (
          <>
            <div className="d-flex">
              <div>
                {row.original.footer > 0 ? (
                  <div>
                    <b>{row.original.age_group}</b>
                  </div>
                ) : (
                  <div>{row.original.age_group}</div>
                )}
              </div>
            </div>
          </>
        ),
      },
      {
        accessorKey: "tenants",
        header: "Tenants",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "percentage",
        header: "Percentage",
        size: 50,
        Cell: ({ row }) => (
          <>
            <div className="d-flex">
              <div>
                {row.original.footer == 1 ? (
                  <div>{row.original.percentage}</div>
                ) : (
                  <div>{row.original.percentage} % </div>
                )}
              </div>
            </div>
          </>
        ),
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "annual_rent",
        header: "Total annual rent",
        size: 50,
        Cell: ({ row }) => (
          <>
            <div>
              {row.original.footer > 0 ? (
                <div>
                  <b>
                    {row.original.annual_rent} {row.original.currency_id}
                  </b>
                </div>
              ) : (
                <div>
                  {row.original.annual_rent} {row.original.currency_id}
                </div>
              )}
            </div>
          </>
        ),
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );

  const data = {
    labels:
      getTransactionsDividedByAgeData?.data
        ?.filter((data) => data.age_group != "Avg. age")
        ?.map((data) => data.age_group) || [],
    datasets: [
      {
        backgroundColor: "#619130",
        data:
          getTransactionsDividedByAgeData?.data
            ?.filter((data) => data.age_group != "Avg. age")
            ?.map((data) => data.tenants) || [],
        stack: 1,

        maxBarThickness: 25,
        barThickness: 25,
        borderWidth: 1,
      },
      compareDataEvents
        ? {
            backgroundColor: "#D00200",
            data: [0, 10, 30, 40, 35, 15],
            stack: 2,

            maxBarThickness: 25,
            barThickness: 25,
            borderWidth: 1,
          }
        : {},
    ],
  };

  const options = {
    responsive: "true",
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        backgroundColor: "#FFFFFF",
        borderWidth: 2,
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          borderDash: [5, 10],
          color: "#F4F5F5",
        },
        stacked: false,
        ticks: {
          stepSize: 15,
        },
      },

      x: {
        stacked: false,
        grid: {
          color: "transparent",
        },
      },
    },
  };

  return (
    <div>
      {" "}
      <div className="card-body p-3" ref={myref}>
        <Row>
          <Col className="d-flex align-items-center" sm={6}>
            {" "}
            <div className="d-flex" style={{ alignItems: "center" }}>
              <H3 className=" me-2">Transactions divided by age</H3>{" "}
              <CustomToolTip size="sm" text="Transactions divided by age" />
            </div>
          </Col>
          <Col sm={6} className="ps-lg-0">
            <Row>
              <Col
                sm={10}
                className="d-flex align-items-center justify-content-end"
              >
                <span className="light-font-text me-2">
                  Compare with data from events
                </span>
                <Toggle
                  id={1}
                  onChange={(e) => {
                    setCompareDataEvents(e.target.checked);
                  }}
                  checked={compareDataEvents}
                />{" "}
              </Col>
              <Col className="d-flex justify-content-end" sm={2}>
                <Menus
                  itemheight={40}
                  itemwidth={40}
                  itemcontainerheight={200}
                  itemcontainerwidth={200}
                  component={
                    <div className="fs-14">
                      <div className="addpropContent px-3 py-2 ">
                        <span className="ms-1" onClick={() => PNGFunction()}>
                          Download widget as PNG
                        </span>
                      </div>
                      <hr className="addpropertyHr m-0"></hr>
                      <div
                        onClick={() => JPGFunction()}
                        className="addpropContent px-3 pt-2 pb-2 "
                      >
                        <span className="ms-1">Download widget as JPG</span>
                      </div>
                      <hr className="addpropertyHr m-0"></hr>
                      <div
                        onClick={() => SVGFunction()}
                        className="addpropContent px-3 pt-2 pb-2 "
                      >
                        <span className="ms-1">Download widget as SVG</span>
                      </div>
                    </div>
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {getTransactionsDividedByAgeData?.data?.length !== 0 ? (
          <Row className="mt-3">
            <Col lg={6}>
              <MRTTheme
                colorVariantsForTableData={["odd"]}
                table={
                  <MaterialReactTable
                    columns={transactionByAgeColumns}
                    data={getTransactionsDividedByAgeData?.data || []}
                    icons={{ ...SortIcon }}
                    enableBottomToolbar={false}
                    enableSorting={false}
                    enableColumnActions={false}
                    enablePagination={false}
                    renderTopToolbar={false}
                  />
                }
              />

              {/* <div className="d-flex justify-content-between p-3">
                <div className="form-label-title fs-13">Avg. Age</div>
                <div className="form-label-title fs-13">65</div>
              </div> */}
            </Col>
            <Col
              className="d-flex align-items-end justify-content-center"
              lg={6}
            >
              <div
                style={{
                  height: "427px",
                  width: "100%",
                  position: "relative",
                  bottom: "25px",
                }}
              >
                <Bar responsive="true" data={data} options={options} />
              </div>
            </Col>
          </Row>
        ) : (
          <NoDataScreen height={"377px"} imgHeight={"150px"} />
        )}
      </div>
    </div>
  );
};

export default TransactionByAge;
