import { Endpoint, Site } from "./API/Endpoints";
import { Delete, Get, Post, Put } from "./API/Method";

//Get Document Type
export const GetDocumentType = (type) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_Document_Type, null, type)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Post Add Document Package
export const PostAddDocumentPackage = (documentPayloadData) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Post_Documents_AddDocPackage,
      null,
      documentPayloadData
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Put Update Document Basic Details
export const PutUpdateDocumentBasicDetails = (
  documentBasicDetailsPayloadData
) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Put_Documents_UpdateBasicDetails,
      null,
      documentBasicDetailsPayloadData
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Put Update Document Complete Details
export const PutUpdateDocumentCompleteDetails = (
  documentCompleteDetailsPayloadData
) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Put_Document_UpdateCompleteDetails,
      null,
      documentCompleteDetailsPayloadData
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Post Upload Document
export const PostUploadDocument = (formData) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Post_Upload_Document,
      { "Content-Type": "multipart/form-data" },
      formData
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Document Package by property Id
export const GetDocumentPackagesByPropertyId = (
  propertyId,
  unitId,
  package_type,
  signing_type
) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_DocumentPackages_By_PropertyId,
      null,
      propertyId +
        "/unit/" +
        unitId +
        "/packages/type/" +
        package_type +
        "/" +
        signing_type
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Delete Document package by package ID
export const DeleteDocumentPackageByPackageID = (propertyId, docPackageId) => {
  return new Promise((resolve, reject) => {
    Delete(
      Site.ET,
      Endpoint.ET.Delete_DocumentPackage,
      null,
      propertyId + "/packages/" + docPackageId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Documents by property Id
export const GetDocumentByPropertyId = (propertyId) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_Document_By_PropertyId, null, propertyId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Delete Document
export const DeleteDocument = (docId) => {
  return new Promise((resolve, reject) => {
    Delete(Site.ET, Endpoint.ET.Delete_Document, null, docId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get DocumentPackage by PackageId
export const GetDocumentPackageByPackageId = (propertyId, packageID) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_DocumentPackage_By_PackageId,
      null,
      propertyId + "/packages/" + packageID
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Post Clone Document
export const PostCloneDocument = (docData) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Post_Clone_Documents, null, docData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Post Clone DocumentPackage
export const PostCloneDocumentPackage = (docData) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Post_Clone_DocumentPackage, null, docData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Template Documents
export const GetTemplateDocuments = (signing_type) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_Template_Documents, null, signing_type)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Put Update Document configuration
export const PutUpdateDocumentConfiguration = (
  documentConfigurationPayloadData
) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Put_Update_DocumentConfiguration,
      null,
      documentConfigurationPayloadData
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Documents - GetConfigurationDetails
export const GetConfigurationDetails = (docId) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_Document_ConfigurationDetails,
      null,
      docId + "/configuration"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Documents - by Unit Id
export const GetDocumentsByUnitId = (propertyId, unitId, type) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_Documents_ByUnitId,
      null,
      propertyId + "/unit/" + unitId + "/type/" + type
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Put Update Document Details
export const PutUpdateDocumentDetails = (documentDetailsPayloadData) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Documents_UpdateDocumentDetails,
      null,
      documentDetailsPayloadData
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Put Update Signing package status
export const PutUpdateSigningPackageStatus = (signingPackagePayloadData) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Documents_UpdateSigningPackageStatus,
      null,
      signingPackagePayloadData
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// document sort order
export const PutUpdateDocumentSortOrder = (UpdateDocumentSortOrderPayload) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Documents_UpdateDocumentSortOrder,
      null,
      UpdateDocumentSortOrderPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get guide documents by property Id
export const GetGuideDocumentByPropertyId = (propertyId) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Documents_GetGuideDocumentsByPropertyId,
      null,
      propertyId + "/documents/guide"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Post add guide Document
export const PostAddGuideDocument = (formData) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Documents_PostAddGuideDocument,
      { "Content-Type": "multipart/form-data" },
      formData
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Delete guide document
export const DeleteGuideDocument = (document_id) => {
  return new Promise((resolve, reject) => {
    Delete(
      Site.ET,
      Endpoint.ET.Documents_DeleteGuideDocument,
      null,
      document_id
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Put Update Document Guide
export const PutUpdateDocumentGuide = (guideDetailsPayload) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Documents_UpdateDocumentGuide,
      null,
      guideDetailsPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Post Copy Guide To Pacakge
export const PostCopyGuideToPacakge = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Documents_CopyGuideToPacakge, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//========================================== New Document API  ===================================//

//----------- Get Documents by level ----------------
export const GetDocumentByLevel = (levelType, levelId) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Documents_Get_Document_By_Level,
      null,
      levelType + "/" + levelId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// -----------------Put Update Document Details--------------------------//
export const PutUpdateDocumentInherited = (documentInheritedPayloadData) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Documents_Update_Documents_Inherited,
      null,
      documentInheritedPayloadData
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//----------- Delete Document by document id --------------- //
export const DeleteDocumentByDocumentId = (docId) => {
  return new Promise((resolve, reject) => {
    Delete(Site.ET, Endpoint.ET.Document_Delete_By_DocumentId, null, docId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//------------ post add document ----------------------//
export const PostAddDocument = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Documents_post_Add_Document, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
