import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { H4 } from "../../../styles/Common.style";
import InputField from "../../../Components/Textfield/InputField";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import Profile from "../../../Components/Profile/Profile";
import { Controller, useForm } from "react-hook-form";
import { SelectPhoneNumber } from "../../../Components/phoneNumberDropdown/SelectPhoneNumber";
import ETButton from "../../../Components/Buttons/ETButton";
import { useUserDetails } from "../../../hooks/stores/useUserDetailsStore";
import {
  useGetLanguages,
  useGetTimeZone,
} from "../../../hooks/queries/uselanguageQueries";
import {
  usePutUpdateUserDetails,
  usePutUpdateUserProfile,
} from "../../../hooks/queries/useUserQueries";
import { toast } from "react-toastify";
import { useGetCommonType } from "../../../hooks/queries/useCommonTypeQueries";
import ETRadioButton, {
  RadioInput,
  RadioLabel,
  RadioWrapper,
} from "../../../Components/RadioButton/ETRadioButton";
import { REQUIRED_ERR } from "../../../utils/validationConstants";
import {
  SucessAlert,
  LoadingAlert,
  ErrorAlert,
} from "./../../../Components/alerts/AlertsAndMessages";
import { status } from "./../../../model/Constant";
import ChangePhoneNumber from "../../../Components/wizard/ChangePhoneNumber";
import CustomReactSelect from "../../../Components/DropDown/CustomLanguageReactSelect";
import TimeZoneSelect from "../../../Components/DropDown/CustomReactSelect";
import { displayLanguageFlag } from "./../../../utils/DisplayLanguageFlag";

const UserDetails = () => {
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      countryId: "",
      phone: "",
      title: "",
      company: "",
      language: "",
      timezone: "",
      primaryWork: "",
    },
  });
  //------------- State ------------------//

  const [uploadImgFile, setUploadImgFile] = useState("");
  const [croppedImage, setCroppedImage] = useState();
  const [showChangePhone, setShowChangePhone] = useState(false);

  //------------- zustand ------------------//
  const { userDetails } = useUserDetails((state) => state);

  //------------- react query  ------------------//

  const { data: GetLanguageData } = useGetLanguages();
  const { data: TimeZoneDta } = useGetTimeZone();

  const {
    mutateAsync: PutUpdateUserProfile,
    isLoading: PutUpdateUserProfileIsLoading,
  } = usePutUpdateUserProfile();
  const { data: GetCommonTypeData } = useGetCommonType(null, "USRWA");

  //------------- UseEffect ------------------//
  useEffect(() => {
    if (userDetails) {
      setValue("firstName", userDetails?.first_name);
      setValue("lastName", userDetails?.last_name);
      setValue("email", userDetails?.email);
      setValue("phone", userDetails?.contact_no);
      setValue("countryId", userDetails?.isd_code);
      setValue("company", userDetails?.company_name);
      setValue("title", userDetails?.designation);
      setValue(
        "language",
        displayLanguageFlag(userDetails?.language_id, GetLanguageData)
      );
      setCroppedImage(userDetails?.image_url);

      setValue("timezone", {
        value: userDetails?.timezone_id,
        label: userDetails?.timezone_name,
      });
      setValue("primaryWork", userDetails?.work_area_id);
    }
  }, [userDetails, GetLanguageData]);

  useEffect(() => {
    const fetchImageAndSetFile = async () => {
      try {
        if (croppedImage) {
          const response = await fetch(croppedImage);
          const blob = await response.blob();
          const file = new File([blob], `${userDetails?.first_name}.png`, {
            type: blob.type,
          });
          setUploadImgFile(file);
        } else {
          setUploadImgFile("");
        }
      } catch (error) {
        setUploadImgFile(error);
      }
    };

    fetchImageAndSetFile();
  }, [croppedImage, userDetails?.first_name]);

  console.log(croppedImage, "croppedImage");

  //------------- handle Save User Profile ------------------//
  const handleSaveUserProfile = async (data) => {
    let id;
    if (!PutUpdateUserProfileIsLoading) {
      id = toast.loading(<LoadingAlert />);
    }

    let formdata = new FormData();

    formdata.append("first_name", data?.firstName);
    formdata.append("last_name", data?.lastName);
    formdata.append("designation", data?.title);
    formdata.append("language_id", data?.language?.value);
    formdata.append("isd_code", data?.countryId);
    formdata.append("contact_no", data?.phone);
    formdata.append("company_name", data?.company);
    formdata.append("work_area_id", data?.primaryWork);
    // formdata.append(
    //   "profile_image_filename",
    //   uploadImgFile ? uploadImgFile?.name : ""
    // );
    formdata.append(
      "profile_image_filename",
      croppedImage?.includes("https")
        ? userDetails?.profile_image_filename
        : uploadImgFile?.name
        ? uploadImgFile?.name
        : ""
    );
    // formdata.append("profile_image_file", uploadImgFile ? uploadImgFile : "");
    formdata.append(
      "profile_image_file",
      userDetails?.image_url && croppedImage?.includes("https")
        ? ""
        : uploadImgFile
    );

    formdata.append("timezone_id", data?.timezone?.value);
    try {
      const response = await PutUpdateUserProfile(formdata);
      if (response.status === "success" && response.data.bReturn) {
        toast.update(id, {
          render: <SucessAlert />,
          isLoading: false,
          type: status.s,
          autoClose: 2000,
        });
      } else {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.s,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.update(id, {
        render: <ErrorAlert />,
        isLoading: false,
        type: status.s,
        autoClose: 2000,
      });
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(handleSaveUserProfile)}>
        <div className="px-4 ">
          <Row>
            <Col lg={8} className="pb-4">
              <div className="light-font-text">
                Below are all the settings you can change and make on your
                profile in the system. The settings will affect all your user
                roles and across Divisions if you are a member of several.
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <H4>User Information</H4>
            </Col>
          </Row>
          <div className="mb-4 mt-3">
            <Row>
              <Col lg={4} xxl={4} className=" pe-sm-3 mb-lg-0 mb-sm-3 ">
                <div>
                  <Controller
                    {...register("firstName")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <InputField
                          type={"text"}
                          errorscope
                          value={value}
                          placeholder={"First Name"}
                          iserror={errors?.firstName}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("firstName");
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
              </Col>
              <Col lg={4} className="pe-3 ps-lg-0 mb-sm-3">
                <div>
                  <Controller
                    {...register("lastName")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <InputField
                          id={"lastName"}
                          value={value}
                          type={"text"}
                          placeholder={"Last Name"}
                          errorscope
                          iserror={errors?.lastName}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("lastName");
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={4} className="pe-sm-3 mb-lg-0 mb-sm-3">
                <div>
                  <Controller
                    {...register("email")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <InputField
                          id={"email"}
                          value={value}
                          type={"email"}
                          placeholder={"Email"}
                          errorscope
                          disabled
                          iserror={errors?.email}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("email");
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
              </Col>
              <Col lg={4} className="pe-3 ps-lg-0 mb-sm-3">
                <div>
                  <SelectPhoneNumber
                    errors={errors}
                    setValue={setValue}
                    isRemoveOpacity={true}
                    isDisabled={true}
                    getPhoneId={"countryId"}
                    getPhoneNumber={"phone"}
                    control={control}
                    placeholder={"Phone"}
                  />
                </div>
              </Col>
              <Col lg={4} className="pe-3 ps-lg-0 mb-sm-3">
                <ETButton
                  variant={"primary"}
                  buttonText={"change phone number"}
                  onClick={() => setShowChangePhone(true)}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4} className="pe-sm-3 mb-lg-0 mb-sm-3">
                <div>
                  <Controller
                    {...register("title")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <InputField
                          id={"title"}
                          value={value}
                          type={"text"}
                          placeholder={"Title"}
                          errorscope
                          iserror={errors?.title}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("title");
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
              </Col>
              <Col lg={4} className="pe-3 ps-lg-0 mb-sm-3">
                <div>
                  <Controller
                    {...register("company")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <InputField
                          id={"company"}
                          value={value}
                          type={"text"}
                          placeholder={"Company"}
                          errorscope
                          iserror={errors?.company}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("company");
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <hr className=" p-0 m-0" />
        <div className="p-4">
          <Row>
            <Col>
              <H4>System Language</H4>
              <div className="light-font-text pt-2">
                Select the user's desired system language.
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="pe-sm-3 mb-lg-0 mb-sm-3 mt-lg-3">
              <div>
                <Controller
                  {...register(`language`)}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <CustomReactSelect
                      placeholder={"Language"}
                      // inputValue={value}
                      iserror={errors?.language}
                      isSearchable={false}
                      searchBarHeader="Search language"
                      isMulti={false}
                      onChange={(e) => onChange(e)}
                      value={value}
                      options={GetLanguageData?.data?.map((item) => ({
                        value: item?.Id,
                        text: item?.Text,
                        label: (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={item?.DataValue1}
                              alt={`${item?.Text} flag`}
                              style={{
                                marginRight: "8px",
                                width: "18px",
                                height: "12px",
                              }}
                            />
                            <span className="normal-font-text fs-14">
                              {item?.Text}
                            </span>
                          </div>
                        ),
                      }))}
                    />
                  )}
                />
              </div>
            </Col>
            {/* <Col lg={3} md={12} className="pe-3 ps-lg-0 mb-sm-3">
              <Controller
                {...register("timezone")}
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    clearable={false}
                    placeholder={"Timezone"}
                    options={TimeZoneDta?.data?.map((time) => ({
                      value: time?.timezone_id,
                      label: time?.name,
                    }))}
                    onChange={(e) => {
                      onChange(e);
                      // timezoneHandle(e);
                    }}
                    value={value}
                  />
                )}
              />
            </Col> */}
          </Row>
        </div>
        <hr className=" p-0 m-0" />
        <div className="p-4">
          <Row>
            <Col>
              <H4>System Timezone</H4>
              <div className="light-font-text pt-3 mb-3">
                Select the user's desired system timezone.
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={3} xl={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
              <Controller
                {...register("timezone")}
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <TimeZoneSelect
                    placeholder={"Timezone"}
                    isSearchable={false}
                    searchBarHeader="Search for timezone"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    options={TimeZoneDta?.data?.map((time) => ({
                      value: time?.timezone_id,
                      label: time?.name,
                      text: time?.name,
                      isDisabled:
                        value?.value?.toString() ===
                        time?.timezone_id?.toString(),
                    }))}
                  />
                )}
              />
            </Col>
          </Row>
        </div>
        <hr className=" p-0 m-0" />
        <div className="p-4">
          <H4 className="pb-3 ">Primary work area in the system</H4>

          <div className="light-font-text pb-4">
            Choose which of the work areas below best describes your use of
            systems.
          </div>
          {console.log(GetCommonTypeData, 'GetCommonTypeData')}
          <div className="d-flex flex-column gap-2">
            <Controller
              {...register("primaryWork")}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <>
                  {GetCommonTypeData?.data?.map((type, index) => (
                    <RadioWrapper key={index}>
                      <RadioInput
                        type="radio"
                        checked={type?.Id?.toString() === value?.toString()}
                        name="primaryWork"
                        value={value}
                        id={type?.Id}
                        onChange={(e) => {
                          onChange(e);
                          setValue("primaryWork", type?.Id);
                        }}
                      />
                      <RadioLabel>{type?.Text}</RadioLabel>
                    </RadioWrapper>
                  ))}
                </>
              )}
            />
            {errors?.primaryWork && (
              <p
                className="error-msg"
                style={{
                  color: "red",
                }}
              >
                {REQUIRED_ERR}
              </p>
            )}
          </div>
        </div>
        <hr className=" p-0 m-0" />
        <div className="p-4">
          <Row>
            <Col>
              <H4>Profile Image</H4>
              <div className="light-font-text pt-3">
                Give the self a recognisable look by uploading a picture of you.
                If not, the first letter in your name is used.
              </div>
            </Col>
          </Row>
          <div className="py-4 d-flex justify-content-start  ">
            <Row>
              <Col className="px-0">
                <Profile
                  croppedImage={croppedImage}
                  setCroppedImage={setCroppedImage}
                  firstName={userDetails?.first_name}
                />
              </Col>
            </Row>
          </div>
        </div>

        <div className="mt-5 py-2 px-3 d-flex justify-content-end align-items-end footer-section-button-container">
          <ETButton
            variant={"primary"}
            buttonText={"SAVE"}
            disabled={PutUpdateUserProfileIsLoading || croppedImage == null}
            type={"submit"}
          />
        </div>
      </Form>
      <ChangePhoneNumber show={showChangePhone} setShow={setShowChangePhone} />
    </div>
  );
};

export default UserDetails;
