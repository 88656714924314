import React, { useEffect, useState } from "react";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
// import GroupButtonBootstrap from "../../../../Components/GroupButton/GroupButtonBootstrap";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import GroupButtons from "../../../../Components/GroupButton/GroupButton";
import { Controller, useForm } from "react-hook-form";
import { useAppState } from "../AllState";
import {
  RadioInput,
  RadioLabel,
  RadioWrapper,
} from "../../../../Components/RadioButton/ETRadioButton";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";
import { H4 } from "../../../../styles/Common.style";

const HeatingAndCooling = ({
  submitRef,
  handleNext,
  prevRef,
  activeTenancyForm,
  getConfigurationDetailsData,
  handleEditConfig,
  previewRef,
}) => {
  const [state, setState] = useAppState();
  const [leaseIsHeatedCheckBox, setLeaseIsHeatedCheckBox] = useState({});
  const [heatingAndCoolingDates, setHeatingAndCoolingDates] = useState({});

  const {
    handleSubmit,
    register,
    watch,
    control,
    clearErrors,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({ defaultValues: state[activeTenancyForm] });
  const saveData = (data) => {
    const landlordSupplies = [
      "heat_supply_1",
      "heat_supply_2",
      "heat_supply_3",
      "heat_supply_4",
      "heat_supply_5",
      "heat_supply_6",
    ];
    const tenantResponsible = [
      "heat_source_1",
      "heat_source_2",
      "heat_source_3",
      "heat_source_4",
      "heat_source_5",
    ];

    let error = false;
    let count = 0;

    if (data?.heat_supply == 0) {
      landlordSupplies?.forEach((element, index) => {
        if (data?.[element] === "" || data?.[element] === 0) {
          count++;
        }

        if (count === landlordSupplies.length - 1) {
          setError("heat_supply_6", { message: { REQUIRED_ERR } });
          error = true;
        }

        if (landlordSupplies.length - 1 === index) count = 0;
      });
    }
    if (data?.tenent_heat_reponsibility == 0) {
      tenantResponsible?.forEach((element, index) => {
        if (data?.[element] === "" || data?.[element] === 0) {
          count++;
        }

        if (count === tenantResponsible.length - 1) {
          setError("heat_source_5", { message: { REQUIRED_ERR } });
          error = true;
        }

        if (tenantResponsible.length - 1 === index) count = 0;
      });
    }

    if (!error) handleEditConfig(data, getConfigurationDetailsData, 5, true);
    // handleNext(1);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  const preview = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 5, false);
    // handleNext(1);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  // saving all field into context state
  const prevSubmit = () => {
    // setState({ ...state, ...getValues() });
    setState({ ...state, [activeTenancyForm]: { ...getValues() } });
  };
  useEffect(() => {
    setValue(
      "heat_supply",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "heat_supply"
      )[0]?.actual_value == 2
        ? 1
        : 0
    );

    setValue(
      "heat_supply_1",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "heat_supply_1"
      )[0]?.actual_value
    );
    setValue(
      "heat_supply_2",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "heat_supply_2"
      )[0]?.actual_value
    );
    setValue(
      "heat_supply_3",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "heat_supply_3"
      )[0]?.actual_value
    );
    setValue(
      "heat_supply_4",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "heat_supply_4"
      )[0]?.actual_value
    );
    setValue(
      "heat_supply_5",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "heat_supply_5"
      )[0]?.actual_value
    );
    setValue(
      "heat_supply_6",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "heat_supply_6"
      )[0]?.actual_value
    );
    setValue(
      "heat_measurement_date",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "heat_measurement_date"
      )[0]?.actual_value
    );
    setValue(
      "water_supply_date",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "water_supply_date"
      )[0]?.actual_value
    );
    setValue(
      "elec_supply_date",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "elec_supply_date"
      )[0]?.actual_value
    );
    setValue(
      "cooling_supply_date",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "cooling_supply_date"
      )[0]?.actual_value
    );
    setValue(
      "other_supply_value",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "other_supply_value"
      )[0]?.actual_value
    );

    setValue(
      "tenent_heat_reponsibility",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "tenent_heat_reponsibility"
      )[0]?.actual_value == 2
        ? 1
        : 0
    );

    setValue(
      "heat_source_1",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "heat_source_1"
      )[0]?.actual_value
    );
    setValue(
      "heat_source_2",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "heat_source_2"
      )[0]?.actual_value
    );
    setValue(
      "heat_source_3",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "heat_source_3"
      )[0]?.actual_value
    );
    setValue(
      "heat_source_4",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "heat_source_4"
      )[0]?.actual_value
    );
    setValue(
      "heat_source_5",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "heat_source_5"
      )[0]?.actual_value
    );

    setValue(
      "other_source",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "other_source"
      )[0]?.actual_value
    );
    setValue(
      "water_supply",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "water_supply"
      )[0]?.actual_value == 2
        ? 1
        : 0
    );

    setValue(
      "water_supply_detail",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "water_supply_detail"
      )[0]?.actual_value
    );
    setValue(
      "elec_supply",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "elec_supply"
      )[0]?.actual_value == 2
        ? 1
        : 0
    );
    setValue(
      "elec_supply_detail",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "elec_supply_detail"
      )[0]?.actual_value
    );
    setValue(
      "cooling_supply",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "cooling_supply"
      )[0]?.actual_value == 2
        ? 1
        : 0
    );
    setValue(
      "cooling_cost_allocation",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "cooling_cost_allocation"
      )[0]?.actual_value == 2
        ? 1
        : 0
    );
    setLeaseIsHeatedCheckBox({
      ...leaseIsHeatedCheckBox,
      other: !getValues("heat_supply_4"),
      other2: !getValues("heat_source_5"),
    });

    setHeatingAndCoolingDates({
      ...heatingAndCoolingDates,
      dates1: !getValues("heat_supply_5"),
      dates2: getValues("water_supply_detail"),
      dates3: getValues("elec_supply_detail"),
    });
  }, [getConfigurationDetailsData]);

  // heating and cooling dates disabled
  const handleHeatingDates = () => {
    if (getValues("heat_supply_5")) {
      setHeatingAndCoolingDates({
        ...heatingAndCoolingDates,
        dates1: false,
      });
    } else {
      setHeatingAndCoolingDates({
        ...heatingAndCoolingDates,
        dates1: true,
      });
      setValue("heat_measurement_date", "");
    }

    // if (getValues("heat_supply_5")) {
    //   setHeatingAndCoolingDates({
    //     ...heatingAndCoolingDates,
    //     dates1: false,
    //   });
    // } else {
    //   setHeatingAndCoolingDates({
    //     ...heatingAndCoolingDates,
    //     dates1: true,
    //   });
    //   setValue("heat_measurement_date", "");
    // }
  };
  let isDateFieldDisabled = watch("water_supply_date", false);

  const handleWaterDateDisable = (event) => {
    const value = event.target.value === "1";
    setValue("water_supply_date", value);
  };

  let isDateFieldDisabled2 = watch("elec_supply_date", false);
  let isleaseHeatedDate = watch("heat_supply_5", false);

  const handleElectricityDateDisable = (event) => {
    const value = event.target.value === "1";
    setValue("elec_supply_date", value);
  };

  // remove all check box if landlord suplies checkbox is false
  const handleLandlordSuppliesCheckBox = (value) => {
    if (value) {
      setValue("heat_supply_1", 0);
      setValue("heat_supply_2", 0);
      setValue("heat_supply_3", 0);
      setValue("heat_supply_4", 0);
      setValue("heat_supply_5", 0);
      setValue("heat_supply_6", 0);
      setLeaseIsHeatedCheckBox({
        ...leaseIsHeatedCheckBox,
        other: true,
      });
      isleaseHeatedDate = false;
      setValue("heat_measurement_date", "");
      setValue("other_supply_value", "");
    }
  };

  // remove all check box if tenant is responsible checkbox is false
  const handleTenantResponsibleCheckbox = (value) => {
    if (value) {
      setValue("heat_source_1", 0);
      setValue("heat_source_2", 0);
      setValue("heat_source_3", 0);
      setValue("heat_source_4", 0);
      setValue("heat_source_5", 0);
      setValue("other_source", "");
      setLeaseIsHeatedCheckBox({
        ...leaseIsHeatedCheckBox,
        other2: true,
      });
    }
  };

  // remove all check box if Water checkbox is false
  const handleWaterCheckbox = (value) => {
    if (value) {
      setValue("water_supply_detail", "");
      setValue("water_supply_date", "");
      // isDateFieldDisabled = false;
    }
  };

  // remove all check box if Electricity checkbox is false
  const handleElectricityCheckbox = (value) => {
    if (value) {
      setValue("elec_supply_detail", "");
      setValue("elec_supply_date", "");
    }
  };

  const validateCheckboxes = (data) => {
    if (data?.checkboxes?.length === 0) {
      return "Please select at least one checkbox";
    }
    return true;
  };
  return (
    <>
      <Form className="bg-white radius-8">
        <div
          className="py-3 px-4"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <H4 className="actors-section-title">
            §5 Heating, cooling, water and electricity
          </H4>
          <div className="light-font-text grey-text">
            Lorem ipsum dolor sit lorem ipsum dolor sit
          </div>
        </div>
        <div className="p-4">
          {/*---------- -----Heating-------------------- */}
          <div>
            <div className="d-flex align-items-center">
              <span className="form-label-title me-2">Heating</span>{" "}
              <CustomToolTip size="sm" text="Heating" />
            </div>

            {/* ------------The landlord supplies heat to the rental property?------------ */}
            <div className="form-label-title me-2 pb-2 pt-2">
              The landlord supplies heat to the rental property?
            </div>
            <div className="w-25">
              <Controller
                control={control}
                {...register("heat_supply")}
                render={({
                  field: { onChange, value = getValues("heat_supply") },
                }) => {
                  return (
                    <GroupButtons
                      tabs={[
                        {
                          id: 1,
                          tabTitle: "Yes",
                          title: "Yes",
                          content:
                            "This page contains data related to Map milestone",
                        },
                        {
                          id: 2,
                          tabTitle: "No",
                          title: "No",
                          content:
                            "This page contains data related to Chart milestone",
                        },
                      ]}
                      currentTab={value}
                      handleTabClick={(tab) => {
                        setValue("heat_supply", tab);
                        handleLandlordSuppliesCheckBox(tab);
                      }}
                    />
                  );
                }}
              />
            </div>

            <div className="pt-4">
              <div className="light-font-text">
                If yes, the lease is heated by:
              </div>
              <div className="d-flex " style={{ alignItems: "center" }}>
                <div className="me-1">
                  <Controller
                    name="heat_supply_1"
                    control={control}
                    render={({
                      field: {
                        onChange,
                        value = parseInt(getValues("heat_supply_1")),
                      },
                    }) => (
                      <CustomCheckBox
                        checked={value}
                        disabled={watch("heat_supply")}
                        onChange={(e) => {
                          // onChange(e)
                          e.target.checked
                            ? setValue("heat_supply_1", 1)
                            : setValue("heat_supply_1", 0);
                          handleHeatingDates();
                        }}
                      />
                    )}
                  />
                </div>
                <div className="light-font-text">
                  District heating/natural gas
                </div>
              </div>

              <div className="d-flex " style={{ alignItems: "center" }}>
                <div className="me-1">
                  <Controller
                    name="heat_supply_2"
                    control={control}
                    render={({
                      field: {
                        onChange,
                        value = parseInt(getValues("heat_supply_2")),
                      },
                    }) => (
                      <CustomCheckBox
                        checked={value}
                        disabled={watch("heat_supply")}
                        onChange={(e) => {
                          e.target.checked
                            ? setValue("heat_supply_2", 1)
                            : setValue("heat_supply_2", 0);
                          handleHeatingDates();
                        }}
                      />
                    )}
                  />
                </div>
                <div className="light-font-text">Central heating with oil</div>
              </div>
              <div className="d-flex " style={{ alignItems: "center" }}>
                <div className="me-1">
                  <Controller
                    name="heat_supply_3"
                    control={control}
                    render={({
                      field: {
                        onChange,
                        value = parseInt(getValues("heat_supply_3")),
                      },
                    }) => (
                      <CustomCheckBox
                        checked={value}
                        disabled={watch("heat_supply")}
                        onChange={(e) => {
                          e.target.checked
                            ? setValue("heat_supply_3", 1)
                            : setValue("heat_supply_3", 0);
                          handleHeatingDates();
                        }}
                      />
                    )}
                  />
                </div>
                <div className="light-font-text">Electricity for heating</div>
              </div>
              <div className="d-flex " style={{ alignItems: "center" }}>
                <div className="me-1">
                  <Controller
                    name="heat_supply_4"
                    control={control}
                    render={({
                      field: {
                        onChange,
                        value = parseInt(getValues("heat_supply_4")),
                      },
                    }) => (
                      <CustomCheckBox
                        checked={value}
                        disabled={watch("heat_supply")}
                        onChange={(e) => {
                          e.target.checked
                            ? setValue("heat_supply_4", 1)
                            : setValue("heat_supply_4", 0);
                          setLeaseIsHeatedCheckBox({
                            ...leaseIsHeatedCheckBox,
                            other: value,
                          });
                          if (value) setValue("other_supply_value", "");
                          handleHeatingDates();
                        }}
                      />
                    )}
                  />
                </div>
                <div className="light-font-text">Other</div>
                <input
                  {...register("other_supply_value", {
                    required: watch("heat_supply_4"),
                  })}
                  style={{ height: "26px" }}
                  className={`normalTextstyle ${
                    leaseIsHeatedCheckBox?.other1 ? "cursor-not-allowed" : ""
                  } ms-2`}
                  label="other"
                  disabled={leaseIsHeatedCheckBox.other}
                />{" "}
                {errors.other_supply_value && (
                  <label className="red-text fs-14" for="other_supply_value">
                    Other is required
                  </label>
                )}
              </div>
              <div className="d-flex ">
                <div className="me-1">
                  <Controller
                    name="heat_supply_5"
                    control={control}
                    render={({
                      field: {
                        onChange,
                        value = parseInt(getValues("heat_supply_5")),
                      },
                    }) => (
                      <CustomCheckBox
                        checked={value}
                        disabled={watch("heat_supply")}
                        onChange={(e) => {
                          e.target.checked
                            ? setValue("heat_supply_5", 1)
                            : setValue("heat_supply_5", 0);
                          handleHeatingDates();
                        }}
                      />
                    )}
                  />
                </div>
                <div
                  className="light-font-text pt-1"
                  style={{ alignItems: "center" }}
                >
                  The cost of heating is charged in addition to the rent, cf.
                  section 65(1) of the Rent Act. The heating accounting year
                  begins on{" "}
                  <Controller
                    control={control}
                    {...register("heat_measurement_date", {
                      required: watch("heat_supply_5"),
                    })}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <input
                          className="mx-sm-0 p-1 fs-14 radius-8"
                          type="date"
                          style={{
                            height: "26px",
                            border: "1px solid #d1d1d1",
                            outline: "none",
                          }}
                          disabled={!isleaseHeatedDate}
                          onChange={(e) => onChange(e)}
                          value={value}
                        />
                      );
                    }}
                  />
                  {errors.heat_measurement_date && (
                    <div className="ms-2">
                      <p className="error-msg" style={{ width: "max-content" }}>
                        (date is required)
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex ">
                <div className="me-1">
                  <Controller
                    name="heat_supply_6"
                    control={control}
                    render={({
                      field: {
                        onChange,
                        value = parseInt(getValues("heat_supply_6")),
                      },
                    }) => (
                      <CustomCheckBox
                        checked={value}
                        disabled={watch("heat_supply")}
                        onChange={(e) => {
                          e.target.checked
                            ? setValue("heat_supply_6", 1)
                            : setValue("heat_supply_6", 0);
                        }}
                      />
                    )}
                  />
                </div>
                <div
                  className="light-font-text pt-1"
                  style={{ alignItems: "center" }}
                >
                  The cost of heating is included in the rent, cf. section 65(2)
                  of the Rent Act (single rooms etc.).
                </div>
              </div>

              {errors?.heat_supply_6 && (
                <p className="error-msg">{REQUIRED_ERR}</p>
              )}
            </div>

            {/* -----The tenant is responsible for heating the premises? */}
            <div className="pt-2">
              <div className="form-label-title me-2 mt-2">
                The tenant is responsible for heating the premises?
              </div>
              <div className="my-2 w-25">
                <Controller
                  control={control}
                  {...register("tenent_heat_reponsibility")}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <GroupButtons
                        tabs={[
                          {
                            id: 1,
                            tabTitle: "Yes",
                            title: "Yes",
                            content:
                              "This page contains data related to Map milestone",
                          },
                          {
                            id: 2,
                            tabTitle: "No",
                            title: "No",
                            content:
                              "This page contains data related to Chart milestone",
                          },
                        ]}
                        currentTab={value}
                        handleTabClick={(tab) => {
                          setValue("tenent_heat_reponsibility", tab);
                          handleTenantResponsibleCheckbox(tab);
                        }}
                      />
                    );
                  }}
                />
              </div>
              <div className="pt-3">
                <div className="light-font-text">
                  If yes, the lease is heated by:
                </div>
                <div className="d-flex " style={{ alignItems: "center" }}>
                  <div className="me-1">
                    <Controller
                      name="heat_source_1"
                      control={control}
                      render={({
                        field: {
                          onChange,
                          value = parseInt(getValues("heat_source_1")),
                        },
                      }) => (
                        <CustomCheckBox
                          checked={value}
                          disabled={watch("tenent_heat_reponsibility")}
                          onChange={(e) => {
                            e.target.checked
                              ? setValue("heat_source_1", 1)
                              : setValue("heat_source_1", 0);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="light-font-text">Electricity</div>
                </div>

                <div className="d-flex " style={{ alignItems: "center" }}>
                  <div className="me-1">
                    <Controller
                      name="heat_source_2"
                      control={control}
                      render={({
                        field: {
                          onChange,
                          value = parseInt(getValues("heat_source_2")),
                        },
                      }) => (
                        <CustomCheckBox
                          checked={value}
                          disabled={watch("tenent_heat_reponsibility")}
                          onChange={(e) => {
                            e.target.checked
                              ? setValue("heat_source_2", 1)
                              : setValue("heat_source_2", 0);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="light-font-text">Gas</div>
                </div>
                <div className="d-flex " style={{ alignItems: "center" }}>
                  <div className="me-1">
                    <Controller
                      name="heat_source_3"
                      control={control}
                      render={({
                        field: {
                          onChange,
                          value = parseInt(getValues("heat_source_3")),
                        },
                      }) => (
                        <CustomCheckBox
                          checked={value}
                          disabled={watch("tenent_heat_reponsibility")}
                          onChange={(e) => {
                            e.target.checked
                              ? setValue("heat_source_3", 1)
                              : setValue("heat_source_3", 0);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="light-font-text">Oil/petroleum</div>
                </div>
                <div className="d-flex " style={{ alignItems: "center" }}>
                  <div className="me-1">
                    <Controller
                      name="heat_source_4"
                      control={control}
                      render={({
                        field: {
                          onChange,
                          value = parseInt(getValues("heat_source_4")),
                        },
                      }) => (
                        <CustomCheckBox
                          checked={value}
                          disabled={watch("tenent_heat_reponsibility")}
                          onChange={(e) => {
                            e.target.checked
                              ? setValue("heat_source_4", 1)
                              : setValue("heat_source_4", 0);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="light-font-text">
                    District heating/natural gas
                  </div>
                </div>
                <div className="d-flex " style={{ alignItems: "center" }}>
                  <div className="me-1">
                    <Controller
                      name="heat_source_5"
                      control={control}
                      render={({
                        field: {
                          onChange,
                          value = parseInt(getValues("heat_source_5")),
                        },
                      }) => (
                        <CustomCheckBox
                          checked={value}
                          disabled={watch("tenent_heat_reponsibility")}
                          onChange={(e) => {
                            e.target.checked
                              ? setValue("heat_source_5", 1)
                              : setValue("heat_source_5", 0);
                            setLeaseIsHeatedCheckBox({
                              ...leaseIsHeatedCheckBox,
                              other2: value,
                            });
                            if (value) setValue("other_source", "");
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="light-font-text d-flex gap-2">
                    Other{" "}
                    <input
                      {...register("other_source", {
                        required: watch("heat_source_5"),
                      })}
                      type="text"
                      className={`normalTextstyle ${
                        leaseIsHeatedCheckBox?.other2
                          ? "cursor-not-allowed"
                          : ""
                      }`}
                      disabled={leaseIsHeatedCheckBox?.other2}
                    />{" "}
                    {errors.other_source && (
                      <label className="red-text fs-14" for="other_source">
                        Other is required
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {errors?.heat_source_5 && (
              <p className="error-msg">{REQUIRED_ERR}</p>
            )}
          </div>

          {/*----------------------Water----------------------- */}
          <div className="mt-4">
            <div className="d-flex align-items-center">
              <span className="form-label-title me-2">Water</span>{" "}
              <CustomToolTip size="sm" text="Water" />
            </div>
            <div className="light-font-text  me-2 pb-2">
              The landlord supplies water to the rental property?
            </div>

            <div className="w-25">
              <Controller
                control={control}
                {...register("water_supply")}
                render={({ field: { onChange, value } }) => {
                  return (
                    <GroupButtons
                      tabs={[
                        {
                          id: 1,
                          tabTitle: "Yes",
                          title: "Yes",
                          content:
                            "This page contains data related to Map milestone",
                        },
                        {
                          id: 2,
                          tabTitle: "No",
                          title: "No",
                          content:
                            "This page contains data related to Chart milestone",
                        },
                      ]}
                      currentTab={value}
                      handleTabClick={(tab) => {
                        setValue("water_supply", tab);
                        handleWaterCheckbox(tab);
                        clearErrors("water_supply_detail");
                      }}
                    />
                  );
                }}
              />
            </div>

            <div className="light-font-text pt-3">
              <div className=" mb-1">If yes:</div>

              <div
                className="d-flex gap-2 my-3"
                style={{ alignItems: "flex-start" }}
              >
                <RadioWrapper className="pt-1">
                  <RadioInput
                    name="group1"
                    type="radio"
                    value="1"
                    id="radio-1"
                    disabled={watch("water_supply")}
                    {...register("water_supply_detail", {
                      required: true,
                      onChange: (e) => {
                        setHeatingAndCoolingDates({
                          ...heatingAndCoolingDates,
                          dates2: false,
                        });
                        handleWaterDateDisable(e);
                      },
                    })}
                  />
                </RadioWrapper>
                <div>
                  The cost of water is allocated on the basis of individual
                  consumption meters and is charged in addition to the rent. The
                  water accounting year begins on{" "}
                  <Controller
                    control={control}
                    {...register("water_supply_date")}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <input
                          className="mx-sm-0 p-1 fs-14 radius-8"
                          disabled={!isDateFieldDisabled}
                          type="date"
                          style={{
                            height: "26px",
                            border: "1px solid #d1d1d1",
                            outline: "none",
                          }}
                          onChange={(e) => onChange(e)}
                          value={value}
                        />
                      );
                    }}
                  />
                  {errors.water_supply_date && (
                    <div className="ms-2">
                      <p className="error-msg" style={{ width: "max-content" }}>
                        (date is required)
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="d-flex mb-2 gap-2"
                style={{ alignItems: "flex-start" }}
              >
                <RadioWrapper className="pt-1">
                  <RadioInput
                    name="group1"
                    type="radio"
                    value="2"
                    id="radio-1"
                    disabled={watch("water_supply")}
                    {...register("water_supply_detail", {
                      required: watch("water_supply") == 0 ? true : false,
                      onChange: (e) => {
                        setHeatingAndCoolingDates({
                          ...heatingAndCoolingDates,
                          dates2: true,
                        });
                        setValue("water_supply_date", "");
                        handleWaterDateDisable(e);
                      },
                    })}
                  />
                </RadioWrapper>
                <div>
                  The cost of water is not allocated on the basis of individual
                  consumption meters and is therefore included in the rent.
                </div>
              </div>
            </div>
            {errors?.water_supply_detail && (
              <p className="error-msg ">{REQUIRED_ERR}</p>
            )}
          </div>

          {/*----------------------Electricity----------------------- */}
          <div className="mt-5">
            <div className="d-flex align-items-center">
              <span className="form-label-title me-2">Electricity</span>{" "}
              <CustomToolTip size="sm" text="Electricity" />
            </div>
            <div className="light-font-text  me-2 pb-2">
              Does the landlord provide electricity other than heating for the
              rental property?
            </div>

            <div className="w-25">
              <Controller
                control={control}
                {...register("elec_supply")}
                render={({ field: { onChange, value } }) => {
                  return (
                    <GroupButtons
                      tabs={[
                        {
                          id: 1,
                          tabTitle: "Yes",
                          title: "Yes",
                          content:
                            "This page contains data related to Map milestone",
                        },
                        {
                          id: 2,
                          tabTitle: "No",
                          title: "No",
                          content:
                            "This page contains data related to Chart milestone",
                        },
                      ]}
                      currentTab={value}
                      handleTabClick={(tab) => {
                        setValue("elec_supply", tab);
                        clearErrors("elec_supply_detail");
                        handleElectricityCheckbox(tab);
                      }}
                    />
                  );
                }}
              />
            </div>

            <div className="light-font-text  pt-3">
              <div className=" mb-1">If yes:</div>

              <div
                className="d-flex my-3 gap-2"
                style={{ alignItems: "flex-start" }}
              >
                <RadioWrapper className="pt-1">
                  <RadioInput
                    className="Payments-and-rented-checkbox"
                    name="group1"
                    type="radio"
                    value="1"
                    id="radio-2"
                    disabled={watch("elec_supply")}
                    {...register("elec_supply_detail", {
                      required: " rental is required",
                      onChange: (e) => {
                        setHeatingAndCoolingDates({
                          ...heatingAndCoolingDates,
                          dates3: false,
                        });
                        handleElectricityDateDisable(e);
                      },
                    })}
                  />
                </RadioWrapper>
                <div>
                  The cost of electricity is allocated on the basis of
                  individual consumption meters and is charged in addition to
                  the rent. The electricity accounting year begins on{" "}
                  <Controller
                    control={control}
                    {...register("elec_supply_date", {})}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <input
                          className="mx-sm-0 p-1 fs-14 radius-8"
                          disabled={!isDateFieldDisabled2}
                          type="date"
                          style={{
                            height: "26px",
                            border: "1px solid #d1d1d1",
                            outline: "none",
                          }}
                          onChange={(e) => onChange(e)}
                          value={value}
                        />
                      );
                    }}
                  />
                  {errors.elec_supply_date && (
                    <div className="ms-2">
                      <p className="error-msg" style={{ width: "max-content" }}>
                        (date is required)
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="d-flex mb-2 gap-2"
                style={{ alignItems: "flex-start" }}
              >
                <RadioWrapper className="pt-1">
                  <RadioInput
                    name="group1"
                    type="radio"
                    value="2"
                    id="radio-2"
                    disabled={watch("elec_supply")}
                    {...register("elec_supply_detail", {
                      // required: "rental is required",
                      required: watch("elec_supply") == 0 ? true : false,

                      onChange: (e) => {
                        setHeatingAndCoolingDates({
                          ...heatingAndCoolingDates,
                          dates3: true,
                        });
                        setValue("elec_supply_date", "");
                        handleElectricityDateDisable(e);
                      },
                    })}
                  />
                </RadioWrapper>
                <div>
                  The cost of electricity is not allocated on the basis of
                  individual consumption meters and is therefore included in the
                  rent.
                </div>
              </div>
            </div>
            {errors?.elec_supply_detail && (
              <div className="error-msg">{REQUIRED_ERR}</div>
            )}
          </div>

          {/* -------------------Cooling------------------------------ */}
          <div className="mt-5">
            <div className="d-flex align-items-center">
              <span className="form-label-title me-2">Cooling</span>{" "}
              <CustomToolTip size="sm" text="Cooling" />
            </div>
            <div className="light-font-text  me-2 pb-2">
              The landlord provides cooling to the rental property?
            </div>
            <div className="my-2 w-25">
              <Controller
                control={control}
                {...register("cooling_supply")}
                render={({ field: { onChange, value } }) => {
                  return (
                    <GroupButtons
                      tabs={[
                        {
                          id: 1,
                          tabTitle: "Yes",
                          title: "Yes",
                          content:
                            "This page contains data related to Map milestone",
                        },
                        {
                          id: 2,
                          tabTitle: "No",
                          title: "No",
                          content:
                            "This page contains data related to Chart milestone",
                        },
                      ]}
                      currentTab={value}
                      handleTabClick={(tab) => {
                        setValue("cooling_supply", tab);
                      }}
                    />
                  );
                }}
              />
            </div>

            <div className="light-font-text  me-2 pb-2 pt-3">
              If yes, is the cost of cooling allocated on the basis of
              individual consumption meters?
            </div>
            <div className="my-2 w-25">
              <Controller
                control={control}
                {...register("cooling_cost_allocation")}
                render={({ field: { onChange, value } }) => {
                  return (
                    <GroupButtons
                      tabs={[
                        {
                          id: 1,
                          tabTitle: "Yes",
                          title: "Yes",
                          content:
                            "This page contains data related to Map milestone",
                        },
                        {
                          id: 2,
                          tabTitle: "No",
                          title: "No",
                          content:
                            "This page contains data related to Chart milestone",
                        },
                      ]}
                      currentTab={value}
                      handleTabClick={(tab) => {
                        setValue("cooling_cost_allocation", tab);
                        clearErrors("cooling_supply_date");
                      }}
                    />
                  );
                }}
              />
            </div>
            <div className="light-font-text  me-2 pb-2 pt-3">
              The refrigeration accounting year begins on{" "}
              <Controller
                control={control}
                {...register("cooling_supply_date")}
                render={({ field: { onChange, value } }) => {
                  return (
                    <input
                      className="mx-sm-0 p-1 fs-14 radius-8"
                      type="date"
                      style={{
                        height: "26px",
                        border: "1px solid #d1d1d1",
                        outline: "none",
                      }}
                      onChange={(e) => onChange(e)}
                      value={value}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <button
          type="submit"
          onClick={handleSubmit(saveData)}
          ref={submitRef}
          style={{ display: "none" }}
        />
        <button
          type="submit"
          onClick={handleSubmit(preview)}
          ref={previewRef}
          style={{ display: "none" }}
        />
      </Form>
    </>
  );
};

export default HeatingAndCooling;
