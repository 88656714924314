import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Heart = ({ isFavorite = "0", handleClick = () => {} }) => {
  return (
    <div>
      <FontAwesomeIcon
        icon={"fa-solid fa-heart"}
        size="lg"
        className="cursor-pointer"
        style={{
          stroke: `${isFavorite == "1" ? "#AA1518" : "#888888"}`,
          strokeWidth: "30px",
          color: `${isFavorite == "1" ? "#AA1518" : "transparent"}`,
        }}
        onClick={handleClick}
      />
    </div>
  );
};

export default Heart;

// created by suraj
