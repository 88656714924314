import React, { useState } from "react";
import { H2, StyledCard } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { TabsButton } from "../../../../Components/Tabs/Tabs.style";
import ETButton from "../../../../Components/Buttons/ETButton";
import { Col, Row } from "react-bootstrap";
import FilterComponent from "../../../../reactdataTableComponent/FilterComponent";
import { useNavigate } from "react-router-dom";
import GuideCard from "../../../../Components/card/GuideCard";
import {
  useGetGuidesByDivisionId,
  usePutGuideStatus,
} from "../../../../hooks/queries/UseGuideQueries";
import { useDivisionId } from "../../../../hooks/stores/useDivisionsStore";
import { status } from "../../../../model/Constant";
import { DeleteGuideDocumentByGuideID } from "../../../../services/Guides";
import CustomPopup from "../../../../Components/CustomPopup";
import { toast } from "react-toastify";

const GuidesMain = () => {
  // ------------------------------ all states ------------------------------------- //
  const [activeGuidesCategoryTabId, setActiveGuidesCategoryTabId] = useState(0);
  const [showArchivePopup, setShowArchivePopup] = useState(false);
  const [deleteDisableButton, setDeleteDisableButton] = useState(false);
  const [selectedGuideId, setSelectedGuideId] = useState("");
  const [searchText, setSearchText] = useState("");
  // ------------------------- zustand ---------------------------------------------------//
  const { divisionId } = useDivisionId((state) => state);
  // console.log(activeGuidesCategoryTabId, "activeGuidesCategoryTabId");
  //--------------------------------------------- React query -----------------------------//
  //Get guid by division id
  const {
    data: GetGuidesByDivisionId,
    refetch: GetGuidesByDivisionIdDataRefetch,
  } = useGetGuidesByDivisionId(divisionId, activeGuidesCategoryTabId);

  const { mutateAsync: PutGuideStatus } = usePutGuideStatus();

  const showArchiveGuideWithId = (GuideId) => {
    setSelectedGuideId(GuideId);
    setShowArchivePopup(true);

    // console.log("GuideIdGuideId", GuideId);
  };
  const handleArchiveGuide = async () => {
    const response = await DeleteGuideDocumentByGuideID(selectedGuideId);
    if (response.status === status.s) {
      toast.success(response.data.message);
      setDeleteDisableButton(false);
      GetGuidesByDivisionIdDataRefetch();
      setShowArchivePopup(false);
    }
  };

  const handlePublishGuide = async (guidId, statusId) => {
    const handlePublishGuidePayload = {
      document_guide_id: guidId.toString(),
      is_active: statusId == "2" ? "0" : "1",
    };
    try {
      const result = await PutGuideStatus(handlePublishGuidePayload);
      if (result.status === status.s && result.data.bReturn) {
        toast.success(result.data.message);
        GetGuidesByDivisionIdDataRefetch();
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {}
  };

  // console.log(GetGuidesByDivisionId?.data, "GetGetGuidesByDivisionId");

  // ---------------------------------- all const and functions ----------------------------------//
  const navigate = useNavigate();
  const handleAddNewGuide = () => {
    navigate("/add-new-guide", {
      state: {
        guideId: "",
      },
    });
  };

  const handleEditGuide = (guideId) => {
    // console.log(guideId, "guideId");
    navigate("/add-new-guide", {
      state: {
        guideId: guideId,
      },
    });
  };

  return (
    <div>
      <div
        className="px-3 pb-3  whiteContainerStyle  border-bottom"
        style={{
          borderRadius: "8px 8px 0px 0px ",
        }}
      >
        <div className="px-2 pt-3">
          <div className="d-flex align-items-end  gap-2">
            <H2>Guides</H2>
            <CustomToolTip
              size="sm"
              text="Guides"
            />
          </div>
        </div>
      </div>

      <div
        className="d-flex px-4 py-3  justify-content-between align-items-center border-bottom"
        style={{
          width: "100%",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <div
          className=" d-flex light ps-0 light"
          style={{ gap: "7px" }}
        >
          {GuidesTabs?.map((item, index) => (
            <React.Fragment key={item?.id}>
              <TabsButton
                mode={activeGuidesCategoryTabId == item?.id && "true"}
                usedFor={"SingleTabs"}
                onClick={() => setActiveGuidesCategoryTabId(item?.id)}
              >
                {item?.category}
              </TabsButton>
            </React.Fragment>
          ))}
        </div>

        <div>
          <ETButton
            variant={"primary"}
            buttonText={"Add new guide"}
            onClick={handleAddNewGuide}
            icon={true}
          />
        </div>
      </div>

      <div className="py-4 px-4">
        <div>
          <Row className="d-flex justify-content-end">
            <Col
              xl={4}
              md={8}
              className="ps-xl-0 pb-2"
            >
              <div style={{ width: "80%", marginLeft: "auto" }}>
                <FilterComponent
                  filtertext={searchText}
                  handleSearch={(e) => setSearchText(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="py-4">
          <Row>
            {GetGuidesByDivisionId?.data
              ?.filter((item) => {
                if (searchText === "") {
                  return item;
                } else if (
                  item?.title
                    ?.toLowerCase()
                    ?.includes(searchText?.toLowerCase())
                ) {
                  return item;
                }
              })
              .map((data, index) => (
                <Col
                  key={index}
                  xl={4}
                  className="mb-4"
                >
                  <GuideCard
                    cardData={data}
                    handleEditGuide={handleEditGuide}
                    handlePublishGuide={handlePublishGuide}
                    showArchiveGuideWithId={showArchiveGuideWithId}
                    showArchivePopup={showArchivePopup}
                    setShowArchivePopup={setShowArchivePopup}
                  />
                </Col>
              ))}

            <Col
              xl={4}
              className="mb-4"
            >
              <StyledCard height="620px">
                <ETButton
                  variant={"primary"}
                  buttonText="Add new guide"
                  icon={true}
                  width="fit-content"
                  onClick={handleAddNewGuide}
                />
              </StyledCard>
            </Col>
          </Row>
        </div>
      </div>
      {/* Archive guide */}
      <CustomPopup
        open={showArchivePopup}
        onHide={() => setShowArchivePopup(false)}
        title="Archive guide"
        // title_color="black"
        enableCrossButton={false}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="p-3">
          <div className="px-2">
            <div
              className="light-font-text my-1"
              style={{ height: "50px" }}
            >
              <p>Are you sure you want to archive the guide?</p>
              {/* <p>
                Be aware that both the package and all included documents linked
                to the unit will be deleted.
              </p> */}
            </div>
          </div>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => setShowArchivePopup(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="I'M SURE"
            disabled={deleteDisableButton}
            onClick={handleArchiveGuide}
          />
        </div>
      </CustomPopup>
      {/* Archive guide */}
    </div>
  );
};

export default GuidesMain;

// used for division type
const GuidesTabs = [
  {
    id: 0,
    category: "All",
  },
  {
    id: 2,
    category: "Published",
  },
  {
    id: 1,
    category: "Drafts",
  },
  {
    id: 4,
    category: "Archived",
  },
];
