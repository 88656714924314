import React, { useState } from 'react';
import CustomPopup from '../../../../Components/CustomPopup';
import { Col, Row } from 'react-bootstrap';
import ETButton from '../../../../Components/Buttons/ETButton';
import Toggle from '../../../../Components/Switch/Switch';
import ReactSelect from '../../../../Components/DropDown/ReactSelect';
import DateRangePicker from '../../../../Components/DateRangePicker/DateRangePicker';
import DateRangeFilter from '../../../../Components/DateRangePicker/DateRangeFilter';


export const ScreeningDeadline = ({ screeningDeadline, setScreeningDeadline }) => {
    return (
        <div  >
            <CustomPopup
                open={screeningDeadline}
                onHide={() => setScreeningDeadline(false)}
                title="Change deadline on the screening"

                title_color="black"
                enableCrossButton={false}
                action_button_label="YES"
                action_button_label_color="white"
                action_button_color="#619130"
                cancel_button={true}
                size={"md"}
                cancel_button_bordercolor="grey"
                cancel_button_label="NO"
                cancel_button_label_color="black"
                cancel_button_color="#fff"
                footer_color={"#F9FAFA"}
                deletePopUpStyle={true}
                titleTooltip={true}
                titleTooltipText={"Change deadline on the screening"}
            >
                <div className="light-font-text">
                    <div className='px-4 pt-4'>
                        <Row className="mt-2 mb-4"  >

                            <Col>
                                Below you can see the deadline that has been set for this screening. If you want to change it, you can do so below.
                                <br /><br />
                                Please note that the deadline only applies to this screenings and not to any contract associated with it. A screening has its own deadline.
                            </Col>
                        </Row>


                        <Row className='mb-5 w-75'>

                            <Col className="sm-12">
                                <DateRangeFilter           
                                />
                                <div className='light-font-text pt-2' >
                                Last deadline is set by the user: Thomas Høeg  
                                </div>
                            </Col>
                        </Row>
                       


                    </div>
                </div>

                <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">

                    <ETButton buttonText="Cancel" variant={"white"} onClick={() => setScreeningDeadline(false)} />
                    <div className='d-flex '>
                       
                        <div>
                            <ETButton buttonText="Save" variant={"primary"} onClick={() => console.log('Go to contract')} />
                        </div>
                    </div>
                </div>
            </CustomPopup>
        </div>
    );
};

// export default ContractDeadline;
