import React, { useState } from "react";
import { H3, H4 } from "../../../../../styles/Common.style";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "../../../../../Components/DropDown/ReactSelect";
import FilterComponent from "../../../../../reactdataTableComponent/FilterComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menus from "../../../../../Components/Menus/Menus";
import PublishTemplate from "./emailTemplates/PublishTemplate";
import EmailPreview from "./emailTemplates/EmailPreview";
import { useNavigate } from "react-router-dom";
import {
  useGetEmailTemplateById,
  useGetEmailTemplateCategory,
} from "../../../../../hooks/queries/useEmailTemplates";
import moment from "moment/moment";
import { toast } from "react-toastify";
import { DeleteEmailTemplate } from "../../../../../services/EmailTemplates";
import CustomPopup from "../../../../../Components/CustomPopup";
import ETButton from "../../../../../Components/Buttons/ETButton";

const EmailTemplates = ({ type, id }) => {
  //-----------states----------//
  const [publishTemplateShow, setPublishTemplateShow] = useState(false);
  const [cancelShow, setCancelShow] = useState(false);
  const [previewShow, setPreviewShow] = useState(false);
  const [archiveEmailTemplate, setArchiveEmailTemplate] = useState({
    isArchivePopupOpen: false,
    email_template_id: "",
    isDeleteApiLoading: false,
  });

  //-----------navigation const----------//
  const navigate = useNavigate();
  const handleEmailTemplateContextMenu = (
    flag,
    template_id,
    email_template_subcategory_id
  ) => {
    navigate("/create-new-template", {
      state: {
        flag: flag,
        email_template_id: template_id,
        email_template_subcategory_id: email_template_subcategory_id,
      },
    });
  };

  //----------- React query ----------//
  //Get email template by id
  const {
    data: GetEmailTemplatesByIdData,
    refetch: GetEmailTemplatesByIdDataRefetch,
  } = useGetEmailTemplateById(type, id);

  //Get email template category
  const { data: GetEmailTemplateCategoryData } = useGetEmailTemplateCategory();

  //-----------handle archive/delete email template------------//
  const handleArchiveEmailTemplate = async () => {
    try {
      setArchiveEmailTemplate((prev) => ({
        ...prev,
        isDeleteApiLoading: true,
      }));
      const response = await DeleteEmailTemplate(
        archiveEmailTemplate?.email_template_id
      );
      if (response?.data?.bReturn) {
        toast.success(response?.data?.message);
        setArchiveEmailTemplate((prev) => ({
          ...prev,
          isArchivePopupOpen: false,
          isDeleteApiLoading: false,
        }));
        GetEmailTemplatesByIdDataRefetch();
      } else {
        toast.error(response?.data?.message);
        setArchiveEmailTemplate((prev) => ({
          ...prev,
          isDeleteApiLoading: false,
        }));
      }
    } catch (error) {
      toast.error(error?.message);
      setArchiveEmailTemplate((prev) => ({
        ...prev,
        isDeleteApiLoading: false,
      }));
    }
  };

  return (
    <div>
      {/* top section  */}
      <div className="px-4 pb-4 pt-1">
        <H3>Email templates</H3>{" "}
        <div>
          <Row>
            <Col lg={8} className="paragraph1-regular pt-2">
              Below is a list of all your email templates in the system. Set
              filter if you only want to see customised or system standard
              templates.
            </Col>
          </Row>
        </div>
      </div>

      {/* filter section */}
      <div className="px-4 pb-2">
        <Row>
          <Col lg={4} className="pb-2">
            <Row className="justify-content-end">
              <Col lg={6}>
                <ReactSelect placeholder="All (211)" />
              </Col>
            </Row>
          </Col>
          <Col lg={2} className="ps-lg-0 pb-2">
            {" "}
            <ReactSelect placeholder="All statuses" />
          </Col>
          <Col lg={3} className="ps-lg-0 pb-2">
            {" "}
            <ReactSelect
              placeholder="All categories"
              options={GetEmailTemplateCategoryData?.data?.map((item) => ({
                value: item?.Id,
                label: item?.Text,
              }))}
            />
          </Col>
          <Col lg={3} className="ps-lg-0 pb-2">
            <FilterComponent />
          </Col>
        </Row>
      </div>

      {/* templates cards */}
      <div
        style={{ height: "510px", overflowY: "scroll" }}
        className="my-2 scrollable-container-y ps-4 pe-2 me-1"
      >
        {GetEmailTemplatesByIdData?.data?.map((data, i) => (
          <div
            key={data?.email_template_id}
            className="d-flex  bg-white me-1 radius-8 my-2 "
            style={templatesCardContainer}
          >
            <div
              className="p-3 w-100 d-flex "
              style={{
                borderLeft:
                  data?.is_system_template == 1
                    ? "8px solid #999"
                    : "8px solid #77C7A1",
              }}
            >
              <div style={{ width: "90%" }}>
                <H4>{data?.title}</H4>
                <div
                  className="form-label-title fs-12 grey-text py-1"
                  style={{ color: " #888888" }}
                  //  style={{ whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis" }}
                >
                  {data?.category_name} - {data?.subcategory_name},{" "}
                  {data?.type_name} (Created on{" "}
                  {data?.created_on == ""
                    ? "-"
                    : moment(data?.created_on).format("YYYY-MM-DD")}{" "}
                  by {data?.created_by == "" ? "-" : data?.created_by}, last
                  changed on{" "}
                  {data?.modified_on == ""
                    ? "-"
                    : moment(data?.modified_on).format("YYYY-MM-DD")}{" "}
                  by {data?.modifed_by == "" ? "-" : data?.modifed_by})
                </div>

                <div className="light-font-text ">Status : {data?.status}</div>
              </div>

              {/* menu section */}
              <div
                style={{ width: "10%" }}
                className="d-flex align-items-center justify-content-end"
              >
                <Menus
                  itemcontainerwidth="180px"
                  component={
                    <div className="light-font-text">
                      <>
                        <div
                          className="py-2 px-3 cursor-pointer"
                          onClick={() => {
                            handleEmailTemplateContextMenu(
                              "Add",
                              data?.email_template_id,
                              data?.email_template_subcategory_id
                            );
                          }}
                        >
                          Create new version
                        </div>
                        <hr className="hr m-0"></hr>
                        {data?.is_system_template != 1 && (
                          <>
                            <div
                              className="py-2 px-3 cursor-pointer"
                              onClick={() => {
                                handleEmailTemplateContextMenu(
                                  "Edit",
                                  data?.email_template_id,
                                  data?.email_template_subcategory_id
                                );
                              }}
                            >
                              Edit template
                            </div>
                            <hr className="hr m-0"></hr>
                          </>
                        )}
                        <div
                          className="py-2 px-3 cursor-pointer"
                          onClick={() => {
                            handleEmailTemplateContextMenu(
                              "View",
                              data?.email_template_id,
                              data?.email_template_subcategory_id
                            );
                          }}
                        >
                          View template
                        </div>
                        {data?.is_system_template != 1 && (
                          <>
                            <hr className="hr m-0"></hr>
                            <div
                              className="py-2 px-3"
                              style={{
                                opacity: data?.status == "In use" ? "0.5" : "1",
                                cursor:
                                  data?.status == "In use"
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                              onClick={() =>
                                data?.status !== "In use" &&
                                setArchiveEmailTemplate((prev) => ({
                                  ...prev,
                                  isArchivePopupOpen: true,
                                  email_template_id: data?.email_template_id,
                                }))
                              }
                            >
                              Delete template
                            </div>
                          </>
                        )}
                      </>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <PublishTemplate
        popupShow={publishTemplateShow}
        setPopupShow={setPublishTemplateShow}
      />
      <PublishTemplate
        popupShow={cancelShow}
        setPopupShow={setCancelShow}
        usedFor={"C"}
      />

      {/* ---------Archive/Delete popup--------- */}
      <CustomPopup
        open={archiveEmailTemplate?.isArchivePopupOpen}
        onHide={() =>
          setArchiveEmailTemplate((prev) => ({
            ...prev,
            isArchivePopupOpen: false,
          }))
        }
        title={"Archive email template?"}
        title_color="black"
        enableCrossButton={false}
        deletePopUpStyle={true}
        titleTooltip={false}
        titleTooltipText={"Archive email template?"}
      >
        <div>
          <div className="p-4 border-bottom">
            <div className="light-font-text my-1">
              <div>Are you sure you want to archive this email template?</div>
            </div>
          </div>
        </div>

        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText={"CANCEL"}
            onClick={() =>
              setArchiveEmailTemplate((prev) => ({
                ...prev,
                isArchivePopupOpen: false,
              }))
            }
          />
          <ETButton
            disabled={archiveEmailTemplate?.isDeleteApiLoading}
            variant={"primary"}
            buttonText={"ARCHIVE"}
            onClick={() => handleArchiveEmailTemplate()}
          />
        </div>
      </CustomPopup>

      <EmailPreview popupShow={previewShow} setPopupShow={setPreviewShow} />
    </div>
  );
};

export default EmailTemplates;

const templatesCardContainer = {
  borderRadius: "8px",
  border: "1px solid #F0F0F0",
  backgroundColor: "#ffffff",
  overflow: "hidden",
};

const CommunicationData = [
  {
    id: "1",
    name: "Invitation til alle kunder på Kaktus Towers",
    date: "2 Dec at 10:12",
    msg: "Screening - Invitation, New MyPage user (Version 5)",
    subject: 1,
    notification: 1,
    customised: 1,
  },
  {
    id: "2",
    name: "Invitation for existing MyPage customer",
    date: "27 Nov at 9:06",
    msg: "Screening - Invitation, New customers (System standard)",
    subject: 0,
    customised: 0,
  },
  {
    id: "3",
    name: "Invitation til alle",
    date: "27 Nov at 9:06",
    msg: "Screening - Invitation, New customers (Version 2)",
    subject: 0,
    customised: 1,
  },
  {
    id: "4",
    name: "Approved screening",
    date: "27 Nov at 9:06",
    msg: "Screening - Approved screening, New customers (System standard)",
    subject: 0,
    customised: 0,
  },

  {
    id: "5",
    name: "Invitation til alle kunder",
    date: "27 Nov at 9:06",
    msg: "Screening - Invitation, New MyPage user (Version 2)",
    subject: 0,
    customised: 0,
  },
];
