import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

//for company page flag
export const useShowDivisionPage = create((set, get) => ({
  showDivisionPage: "DL",
  setShowDivisionPage: (data) => set({ showDivisionPage: data }),
}));


//Note: This store is only used for storing active division. Not to be used for any other actions
export const useDivisionId = create(
  persist(
    (set, get) => ({
      divisionId: "",
      setDivisionId: (data) => set({ divisionId: data }),
    }),
    {
      name: "divisionId", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useDivisionName = create(
  persist(
    (set, get) => ({
      divisionName: "",
      setDivisionName: (data) => set({ divisionName: data }),
    }),
    {
      name: "divisionName", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

//used for divisions breadcrumbs for setting
export const useDivisionSettingsBreadcrumbsTabs = create((set, get) => ({
  divisionBreadcrumbsTabs: "",
  setDivisionBreadcrumbsTabs: (data) => set({ divisionBreadcrumbsTabs: data }),
}));

//used for divisions breadcrumbs for document
export const useDivisionDocumentBreadcrumbsTabs = create((set, get) => ({
  divisionDocumentBreadcrumbsTabs: "Documents",
  setDivisionDocumentBreadcrumbsTabs: (data) =>
    set({ divisionDocumentBreadcrumbsTabs: data }),
}));
