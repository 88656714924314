import React from "react";
import { Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import "../tags.css";
import { GetMediaPackageByPropertyId } from "../../../../services/Media";
import ETButton from "../../../../Components/Buttons/ETButton";
import MediaCard from "../../../../Components/card/MediaCard";

const SelectMediaPackage = ({
  setStepWizard,
  setProgress,
  progress,
  propertyId,
  tagName,
  mediaPackage,
  setMediaPackage,
}) => {
  //---------React query-------------//
  const { data: mediaPackageByPropertyId, refetch: refetchMediaPAckage } =
    useQuery(["mediaPackageByPropID", propertyId], () =>
      GetMediaPackageByPropertyId(propertyId)
    );

  // checkbox state
  const handleThirdScreen = () => {
    setStepWizard("SelectTextPackage");
    setProgress(progress + 12.5);
  };
  const handleSecondScreen = () => {
    setStepWizard("CreateNewTag");
    setProgress(progress - 12.5);
  };

  // console.log(mediaPackage, "mediaPackage");

  const CheckedMediaCards = (event, item) => {
    if (event.target.checked) {
      setMediaPackage((value) => [...value, item]);
    } else {
      setMediaPackage((value) =>
        value.filter((it) => it?.media_package_id !== item?.media_package_id)
      );
    }
  };

  return (
    <div>
      <div className="mb-5 ps-4 pt-3">
        <div className="fs-14 ">
          <span className="form-label-title">Tag name :&nbsp;</span>
          {tagName}
        </div>
        <div className="light-font-text grey-text pb-2 mb-4">
          Do you already have a plan for your new tag. It’s here possible to
          already put the tag on one of the media packages created on the
          property.
        </div>
        <div
        // className="Card-flex d-flex flex-row "
        // style={{
        //   flexWrap: "wrap",
        //   gap: "16px",
        // }}
        >
          {mediaPackageByPropertyId?.data == 0 ? (
            <div
              className="grey-text w-100 light-font-text"
              style={{ textAlign: "center" }}
            >
              {" "}
              You do not have any package yet.{" "}
            </div>
          ) : (
            <>
              <Row>
                {mediaPackageByPropertyId?.data?.map((item, index) => (
                  <Col
                    xl={4}
                    lg={6}
                    className="pb-4"
                    key={item?.media_package_id}
                  >
                    <MediaCard
                      data={item}
                      index={index}
                      isTag={"AddTagM"}
                      checkboxOnChange={(e) => CheckedMediaCards(e, item)}
                      checkValue={mediaPackage?.filter(
                        (x) => x.media_package_id === item?.media_package_id
                      )}
                      // checkValue={(e) => CheckedMedia(e, item)}
                    />
                  </Col>
                ))}
              </Row>
            </>
          )}
        </div>
      </div>
      <div
        className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container"
        style={{
          position: "absolute",
          width: "100%",
          bottom: "0",
        }}
      >
        <ETButton
          variant={"white"}
          buttonText="PREVIOUS"
          onClick={() => handleSecondScreen()}
        />

        <ETButton
          variant={"primary"}
          buttonText="NEXT"
          onClick={() => {
            handleThirdScreen();
          }}
        />
      </div>
    </div>
  );
};

export default SelectMediaPackage;
