import React from "react";
import { Col, Row } from "react-bootstrap";
import BreakdowndofCustomers from "./BreakdowndofCustomers";
import TransactionByAge from "./TransactionByAge";
import TransactionDividedByRooms from "./TransactionDividedByRooms";
import TransactionBySquareMeter from "./TransactionBySquareMeter";
import CustomerJourney from "./CustomerJourney";

const Customers = () => {
  return (
    <div  className=" radius-8 p-4 containerStyle">
      <Row className="mb-4">
        <Col>
          <BreakdowndofCustomers />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <TransactionByAge />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="mb-sm-4 mb-lg-0" lg={6}>
          <TransactionDividedByRooms />
        </Col>
        <Col lg={6}>
          <TransactionBySquareMeter />
        </Col>
      </Row>
      <Row>
        <Col>
          <CustomerJourney />
        </Col>
      </Row>
    </div>
  );
};

export default Customers;
