import React, { useEffect, useState } from "react";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from "../../../Components/Tabs/ReactTabs.style";
import { H2 } from "../../../styles/Common.style";
import Overview from "./overview/Overview";
import Screening from "./screening/Screening";
import Communicaton from "./communication/Communicaton";
import Documents from "./documents/Documents";
import Notes from "./notes/Notes";
import Signing from "./signing/Signing";
import CustomPopup from "../../../Components/CustomPopup";
import ETButton from "../../../Components/Buttons/ETButton";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import Tenants from "./tenants/Tenants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Placeholder, Row, Stack } from "react-bootstrap";
import { useUnitId } from "../../../hooks/stores/useUnitStore";
import {
  useGetActorByContractId,
  useGetContractByUnitId,
} from "../../../hooks/queries/useActorQueries";
import { useUserDetails } from "../../../hooks/stores/useUserDetailsStore";
import { useGetUserByDivisionId } from "../../../hooks/queries/useUserQueries";
import { PutUpdateResponsibleAgent } from "../../../services/Actors";
import { toast } from "react-toastify";
import { useGetContractOverview } from "../../../hooks/queries/useSigningQueries";
import moment from "moment";
import { useQuery } from "react-query";
import { GetNotesDetails } from "../../../services/Notes";
import { GreenSpan } from "../../../styles/Common.style";
import { CustomersTab } from "../../../Components/Tabs/CustomersTabs.style";
import { filter } from "jszip";
import { useLocation } from "react-router-dom";
import { useBreadcrumb } from "../../../Components/BreadcrumbContext ";
import { data } from "jquery";
import { useSigningOrderStore } from "../../../hooks/stores/useSigningStore";
import { UTCtoLocalTimeZone } from "../../../utils/UTCtoLocalTimeZone";

const ActorsCustomers = ({
  handleClick,
  setInitialSelectedName,
  initialSelectedName,
  // handleClickTab,
  setActorTabs,
  setSelectedTabPage,
  selectedTab,
  setSelectedTab,
  selectedName,
}) => {
  //-------------------Zustand--------------------------//
  const { UnitId } = useUnitId((state) => state);
  const { userDetails } = useUserDetails((state) => state);
  const { isViewSigningOrder } = useSigningOrderStore((state) => state);

  const [contractId, setContractId] = useState("");
  const [tanentNameTab, setTanentNameTab] = useState(false);
  // const [tanentName, setTanentName] = useState(initialSelectedName)
  // useEffect(() => {
  //   setTanentName("Tenant name");
  // }, []);
  const { updateBreadcrumbs } = useBreadcrumb();
  // useEffect(() => {
  //   if (initialSelectedName?.length > 0) {
  //     updateBreadcrumbs(["contracts", initialSelectedName, "Overview"]);
  //   } else { }
  //   // else if (initialSelectedName?.length == 0) {
  //   //   updateBreadcrumbs(["contracts", "Tenant name", "Overview"]);
  //   // }
  // }, [initialSelectedName])
  useEffect(() => {
    if (tanentNameTab) {
      updateBreadcrumbs(["Contracts", selectedName, "Overview"]);
    } else {
    }

    return () => {
      setSelectedTab(0); //Used to set the tab to 1st page when components unmount
    };
  }, [selectedName]);

  useEffect(() => {
    if (selectedName == undefined) {
      updateBreadcrumbs(["Contracts"]);
    }
  }, [selectedName]);

  // useEffect(() => {

  //   if (selectedName?.length == 0) {
  //     updateBreadcrumbs(["contracts", tanentName, "Overview"]);
  //   }
  // }, [selectedName])
  // console.log(selectedName, "selectedName")
  //-------------------States---------------------------//
  const [customersTabs, setCustomersTabs] = useState(0);
  const [openChangeAgentPopup, setOpenChangeAgentPopup] = useState(false);
  const [responsibleAgentId, setResponsibleAgentId] = useState("");

  //-------------------React query----------------------//
  const { data: contractByUnitIdData, refetch: contractByUnitIdDataRefetch } =
    useGetContractByUnitId(UnitId);
  const { data: usersByDivisionIdData } = useGetUserByDivisionId(
    userDetails?.division_id
  );

  const { data: actorByContractIdData, refetch: actorByContractIdDataRefetch } =
    useGetActorByContractId(contractId);
  const { data: contractOverViewData, refetch: contractOverViewDataRefetch } =
    useGetContractOverview(contractId);

  const [actordata, setAcotordat] = useState([]);

  // Refetch is called only when view signing order page is closed
  useEffect(() => {
    if (!isViewSigningOrder?.showSigningPage) {
      contractOverViewDataRefetch();
    }
  }, [isViewSigningOrder?.showSigningPage]);

  useEffect(() => {
    setAcotordat(actorByContractIdData?.data);
  }, [actorByContractIdData]);

  const { convertedDate: createdOn } = UTCtoLocalTimeZone({
    timestamp: actordata?.data?.[0]?.created_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });
  const { convertedDate: modifiedOn } = UTCtoLocalTimeZone({
    timestamp: actordata?.data?.[0]?.modified_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });

  const modifiedData = actordata?.map((item) => {
    return {
      ...item,
      modifiedOn: modifiedOn,
      createdOn: createdOn,
    };
  });

  const handleClickTab = (name) => {
    setSelectedTabPage(name);
  };

  const CustomersTabsWizards = [
    {
      Id: "1",
      tab: "Overview",
      component: (
        <Overview
          contractId={contractId}
          actorByContractIdData={actorByContractIdData}
          contractOverViewData={contractOverViewData}
          usersByDivisionIdData={usersByDivisionIdData}
          contractByUnitIdData={contractByUnitIdData}
          contractOverViewDataRefetch={contractOverViewDataRefetch}
          contractByUnitIdDataRefetch={contractByUnitIdDataRefetch}
          setSelectedTab={setSelectedTab}
          initialSelectedName={initialSelectedName}
          selectedName={selectedName}
          tanentNameTab={tanentNameTab}
        />
      ),
    },
    {
      Id: "2",
      tab: "Screening",
      component: (
        <Screening
          initialSelectedName={initialSelectedName}
          selectedName={selectedName}
          tanentNameTab={tanentNameTab}
          contractOverViewData={contractOverViewData}
          actorByContractIdData={actorByContractIdData}
        />
      ),
    },
    {
      Id: "3",
      tab: "Tenants",
      component: (
        <Tenants
          contractOverViewData={contractOverViewData}
          actorByContractIdData={actorByContractIdData}
          contractId={contractId}
          actorByContractIdDataRefetch={actorByContractIdDataRefetch}
          initialSelectedName={initialSelectedName}
          selectedName={selectedName}
          tanentNameTab={tanentNameTab}
        />
      ),
    },
    {
      Id: "4",
      tab: "Communication",
      component: (
        <Communicaton
          initialSelectedName={initialSelectedName}
          selectedName={selectedName}
          tanentNameTab={tanentNameTab}
        />
      ),
      // msg: ".",
    },
    {
      Id: "5",
      tab: "Documents",
      component: (
        <Documents
          contractId={contractId}
          actorByContractIdData={actorByContractIdData}
          initialSelectedName={initialSelectedName}
          selectedName={selectedName}
          tanentNameTab={tanentNameTab}
        />
      ),
    },
    {
      Id: "6",
      tab: "Notes",
      component: (
        <Notes
          contractId={contractId}
          usersByDivisionIdData={usersByDivisionIdData}
          initialSelectedName={initialSelectedName}
          selectedName={selectedName}
          tanentNameTab={tanentNameTab}
        />
      ),
    },
    {
      Id: "7",
      tab: "Signing",
      component: (
        <Signing
          contractId={contractId}
          contractOverViewData={contractOverViewData}
          initialSelectedName={initialSelectedName}
          selectedName={selectedName}
          tanentNameTab={tanentNameTab}
          contractOverViewDataRefetch={contractOverViewDataRefetch}
        />
      ),
    },
  ];

  useEffect(() => {
    setContractId(contractByUnitIdData?.data?.[0]?.contract_id);
    setInitialSelectedName(contractByUnitIdData?.data?.[0]?.name);
  }, [contractByUnitIdData]);

  const handleUpdateResponsibleAgent = () => {
    const updateResponsibleAgentPayload = {
      contract_id: String(contractId),
      responsible_agent: String(responsibleAgentId),
    };
    PutUpdateResponsibleAgent(updateResponsibleAgentPayload).then(
      (response) => {
        if (response.data.bReturn) {
          toast.success(response.data.message);
          setOpenChangeAgentPopup(false);
          contractByUnitIdDataRefetch();
        }
      }
    );
  };
  return (
    <>
      <div>
        <Placeholder animation="glow">
          <Row className="pt-1">
            <Col
              xl={10}
              lg={9}
              md={8}
            >
              <Stack
                className=" scrollable-container-x "
                direction="horizontal"
                gap={2}
                style={{ overflowX: "scroll" }}
              >
                {contractByUnitIdData?.data.map((item, i) => (
                  <>
                    <CustomersTab
                      key={i}
                      style={{ position: "relative", width: "auto" }}
                      mode={i == customersTabs}
                      className="mb-1"
                      // className={
                      //   i == customersTabs
                      //     ? "px-2 customers-tabs-active mb-1"
                      //     : "customers-tabs px-2 mb-1"
                      // }
                      onClick={() => {
                        // handleClickTab();
                        setCustomersTabs(i);
                        setSelectedTab(0);
                        setContractId(item?.contract_id);
                        handleClick(item.name);
                        setTanentNameTab(true);
                      }}
                    >
                      <span style={{ position: "relative" }}>
                        {item?.name || "Tenant name"}
                      </span>
                    </CustomersTab>
                  </>
                ))}
              </Stack>
            </Col>

            {contractByUnitIdData?.data?.length > 0 && (
              <Col
                xl={2}
                lg={3}
                md={4}
              >
                <div>
                  <CustomersTab
                    className={"px-2"}
                    mode={customersTabs == 1000}
                    onClick={() => {
                      setSelectedTab(0);
                      setCustomersTabs(1000);
                    }}
                  >
                    <span>Former actors</span>
                  </CustomersTab>
                </div>
              </Col>
            )}
          </Row>
          {contractByUnitIdData?.data?.length > 0 ? (
            <>
              <div className="bg-white radius-8 my-2 ">
                <div
                  className="py-3 px-4 "
                  style={{ borderBottom: "1px solid #F4F5F5" }}
                >
                  <div className="d-flex align-items-center">
                    <H2 className="me-2  ">Contract</H2>
                    <CustomToolTip
                      size="sm"
                      text="Contract"
                    />
                  </div>
                  <div className="created-by">
                    {/* {modifiedData?.map((data) => (
                      <>
                        {data.modified_by ? <div>Last updated by &nbsp;{data.modified_by}, {data.modifiedOn}
                        </div>
                          :<> {data.created_by && data.created_on ?<div>Last updated by &nbsp;{data.created_by}, {data.createdOn}</div>:""}</>
                         }
                      </>
                    ))} */}
                  </div>
                </div>

                {/* top tabs  */}

                <Tabs
                  selectedIndex={selectedTab}
                  className=" pb-1 d-flex flex-column"
                >
                  <div
                    className="px-4 my-2 pb-2 d-flex justify-content-between "
                    style={{
                      alignItems: "center",
                      borderBottom: "1px solid #F0F0F0",
                    }}
                  >
                    <TabList className="light ps-0 ">
                      {CustomersTabsWizards.map((page, index) => (
                        <div onClick={() => handleClickTab(page?.tab)}>
                          <Tab
                            style={{ position: "relative" }}
                            onClick={() => setSelectedTab(index)}
                          >
                            <span style={{ position: "relative" }}>
                              {page?.tab}
                            </span>
                            {page.msg && (
                              <FontAwesomeIcon
                                icon="fa-solid fa-circle"
                                size="lg"
                                style={{ color: "#D00200" }}
                                className="px-1 notification"
                              />
                            )}
                          </Tab>
                        </div>
                      ))}
                    </TabList>
                    <div className="light-font-text d-flex flex-column text-end">
                      <span className="form-label-title">
                        Responsible agent
                      </span>
                      <span>
                        {
                          contractByUnitIdData?.data?.filter(
                            (item) => item?.contract_id === contractId
                          )?.[0]?.responsible_agent_name
                        }
                      </span>
                      <div className="d-flex justify-content-end fs-12">
                        <GreenSpan
                          onClick={() => setOpenChangeAgentPopup(true)}
                          className="cursor-pointer"
                        >
                          Change agent
                        </GreenSpan>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="">
                      {CustomersTabsWizards.map((page, index) => (
                        <TabPanel key={page?.Id}>{page?.component}</TabPanel>
                      ))}
                    </div>
                  </div>
                </Tabs>
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center">
              No ongoing contracts found
            </div>
          )}
          <CustomPopup
            open={openChangeAgentPopup}
            onHide={() => setOpenChangeAgentPopup(false)}
            title="Change Agent"
            title_color="black"
            enableCrossButton={false}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={true}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
            deletePopUpStyle={true}
            titleTooltip={true}
          >
            <div className="p-3">
              <div className="px-2">
                <div className="form-label-title  my-2">
                  Current responsible agent
                </div>
                <div className="mt-2">
                  {" "}
                  <div className="form-input">
                    <input
                      disabled
                      style={{ margin: "0px" }}
                      type="text"
                      placeholder="Subject line"
                      id="subjectline"
                      // defaultValue={
                      //   contractByUnitIdData?.data?.[0]?.responsible_agent_name
                      // }
                      defaultValue={
                        contractByUnitIdData?.data?.filter(
                          (item) => item?.contract_id === contractId
                        )?.[0]?.responsible_agent_name
                      }
                    />
                    <label for="firstname">Agent</label>
                  </div>
                </div>
              </div>
              <div className="px-2 mt-4">
                <div className="form-label-title my-2 ">
                  New responsible agent
                </div>
                <div className="mb-3 mt-2">
                  <ReactSelect
                    placeholder={"Agent"}
                    options={usersByDivisionIdData?.data?.map(
                      (item, index) => ({
                        value: item?.Id,
                        label: item?.Text,
                        isDisabled:
                          contractByUnitIdData?.data?.filter(
                            (items) => items?.contract_id == contractId
                          )?.[0]?.responsible_agent == item?.Id,
                      })
                    )}
                    clearable={false}
                    onChange={(e) => setResponsibleAgentId(e.value)}
                  />
                </div>
              </div>
            </div>
            <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container ">
              <ETButton
                variant={"white"}
                buttonText="CANCEL"
                onClick={() => setOpenChangeAgentPopup(false)}
              />
              <ETButton
                disabled={responsibleAgentId === "" && true}
                variant={"primary"}
                buttonText="CHANGE"
                onClick={() => handleUpdateResponsibleAgent()}
              />
            </div>
          </CustomPopup>
        </Placeholder>
      </div>
    </>
  );
};

export default ActorsCustomers;
