import React, { createContext, useContext, useState } from 'react';
//BreadcrumbContext.js
const BreadcrumbContext = createContext();

export function BreadcrumbProvider({ children }) {
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const updateBreadcrumbs = (newBreadcrumbs) => {
        setBreadcrumbs(newBreadcrumbs);
    };
    return (
        <BreadcrumbContext.Provider value={{ breadcrumbs, updateBreadcrumbs }}>
            {children}
        </BreadcrumbContext.Provider>
    );
}

export function useBreadcrumb() {
    return useContext(BreadcrumbContext);
}

// import React from 'react';


// BreadcrumbContext.js 2nd method
// import React, { createContext, useContext, useState } from 'react';

// const BreadcrumbContext = createContext();

// export function BreadcrumbProvider({ children }) {
//     const [breadcrumbs, setBreadcrumbs] = useState([]);
//     const [activeTab, setActiveTab] = useState('');

//     const updateBreadcrumbs = (newBreadcrumbs) => {
//         setBreadcrumbs(newBreadcrumbs);
//     };

//     return (
//         <BreadcrumbContext.Provider
//             value={{ breadcrumbs, updateBreadcrumbs, activeTab, setActiveTab }}
//         >
//             {children}
//         </BreadcrumbContext.Provider>
//     );
// }

// export function useBreadcrumb() {
//     return useContext(BreadcrumbContext);
// }

