import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { MaterialReactTable } from "material-react-table";
import ETButton from "../../../Components/Buttons/ETButton";
import CustomPopup from "../../../Components/CustomPopup";
import AddEditPackage from "../../properties/units/AddEditPackage/AddEditPackage";
import {
  DeleteDocument,
  GetDocumentByPropertyId,
  GetDocumentPackageByPackageId,
  GetDocumentPackagesByPropertyId,
} from "../../../services/Documents";
import { toast } from "react-toastify";
import { REQUIRED_ERR } from "../../../utils/validationConstants";
import { H1, H2 } from "../../../styles/Common.style";
import {
  useGetDocumentType,
  usePostAddDocumentPackage,
  usePutUpdateDocumentBasicDetails,
  usePutUpdateDocumentCompleteDetails,
  useUploadDocument,
} from "../../../hooks/queries/useDocumentsQueries";
import { useQuery } from "react-query";
import { GetTagsByPropertyId } from "../../../services/Tags";
import { usePropertyOverview } from "../../../hooks/stores/usePropertyStore";
import { useAddEditPackage } from "../../../hooks/stores/usePackageStore";
import { useRouting } from "../../../hooks/stores/useRoutingStore";
import { status } from "../../../model/Constant";
import moment from "moment";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import {
  DropZoneBorder,
  GreenSpan,
  PrimaryBreadcrums,
} from "../../../styles/Common.style";
import MRTTheme from "../../../Components/table/MRTTheme";
import {
  SortIcon,
  TableRowsStyle,
} from "../../../Components/table/MRTGlobalProps";
import { CustomersTab } from "../../../Components/Tabs/CustomersTabs.style";
import Toggle from "../../../Components/Switch/Switch";
import { Link } from "react-router-dom";

export const AddDocumentPackages = ({
  propertyOverview,
  isEditDocumentPackages,
  setDocumentPackageId,
  documentPackageId,
}) => {
  // zustand
  const {
    packageType,
    setPackageType,
    showPackage,
    setShowPackage,
    editflag,
    setEditflag,
    packageId,
    setPackageId,
  } = useRouting((state) => state);

  //------------Form validation---------//
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({});

  const { fields, append, remove } = useFieldArray({
    control,
    name: "droppedPdf", // This should match the name in your form data
  });

  const propOverview = usePropertyOverview((state) => state.propOverview);

  const {
    title,
    setTitle,
    active,
    setActive,
    tagRowData,
    setTagRowData,
    selected,
    setSelected,
    selectedData,
    setSelectedData,
    cancelPopup,
    setCancelPopup,
  } = useAddEditPackage((state) => state);
  // For Table only it will be change later
  const [openUploadDocumentManually, setOpenUploadDocumentManually] =
    useState(false);
  const [droppedPdf, setDroppedPdf] = useState([]);
  const [isSettingTabOpen, setIsSettingTabOpen] = useState(true);
  const [addedDocument, setAddedDocument] = useState([]);

  //-----------------states for Document package------------//
  // const [cancelPopup, setCancelPopup] = useState(false);
  const [documentTitle, setDocumentTitle] = useState("");
  // const [tagRowData, setTagRowData] = useState([]);
  // const [active, setActive] = useState(true);
  const [flagUpdateDocument, setFlagUpdateDocument] = useState(false);
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);

  // state used in addEditPackage checkbox logic //
  // const [selected, setSelected] = useState([]);
  // const [selectedData, setSelectedData] = useState([]);

  // document preview

  const [addDocumentPackagePopup, setAddDocumentPackagePopup] = useState(false);
  const [allDocumentDetails, setAllDocumentDetails] = useState({
    allDocumentPackageNameUrl: "",
    allDocumentPackageFileName: "",
  });

  useEffect(() => {
    if (addedDocument?.length === 0) {
      setAddedDocument([
        {
          type: "-",
          name: "-",
          document_type_name: "-",
          publish_required: "-",
          delete_document: "-",
        },
      ]);
    }
  }, [addedDocument]);

  //---------------------React query-------------------//
  const { data: documentTypeData } = useGetDocumentType("DOCTY");
  const {
    mutateAsync: PostUploadDocument,
    isLoading: PostUploadDocumentIsLoading,
  } = useUploadDocument();
  const {
    mutateAsync: PutUpdateDocumentCompleteDetails,
    isLoading: PutUpdateDocumentCompleteDetailsIsLoading,
  } = usePutUpdateDocumentCompleteDetails();
  const { data: getDocumentPackageByPackageId } = useQuery(
    [
      "getDocumentPackageByPackageId",
      propOverview?.[0]?.property_id,
      packageId,
    ],
    () =>
      GetDocumentPackageByPackageId(propOverview?.[0]?.property_id, packageId),
    { enabled: !!editflag }
  );
  const { data: tagsByPropertyIdData } = useQuery(
    ["tagsByPropertyId", propOverview?.[0]?.property_id],
    () => GetTagsByPropertyId(propOverview?.[0]?.property_id),
    {
      enabled: !!editflag,
    }
  );
  const {
    data: getDocumentsByPropertyId,
    refetch: getDocumentsByPropertyIdRefetch,
  } = useQuery(
    ["getDocumentsByPropertyId", propOverview?.[0]?.property_id],
    () => GetDocumentByPropertyId(propOverview?.[0]?.property_id)
  );

  const {
    mutateAsync: PutUpdateDocumentBasicDetails,
    isLoading: PutUpdateDocumentBasicDetailsIsLoading,
  } = usePutUpdateDocumentBasicDetails();
  const {
    mutateAsync: PostAddDocumentPackage,
    isLoading: PostAddDocumentPackageIsLoading,
  } = usePostAddDocumentPackage();

  const [documentbypackage, setDocumentByPackage] = useState([]);

  const modifiedData = getDocumentPackageByPackageId?.data?.map((item) => {
    return {
      ...item,
      modifiedOn: moment(item.modified_on).format("DD.MMMM.YYYY | HH:mm:ss"),
      createdOn: moment(item.modified_on).format("DD.MMMM.YYYY | HH:mm:ss"),
    };
  });

  useEffect(() => {
    setDocumentByPackage(
      getDocumentPackageByPackageId?.data?.[0].document_details
    );
    if (editflag) {
      // setDocumentTitle(getDocumentPackageByPackageId?.data?.[0]?.package_name);
      setTitle(getDocumentPackageByPackageId?.data?.[0]?.package_name);
      const filteredData =
        getDocumentPackageByPackageId?.data[0]?.tag_ids?.split(",");
      const tagData = tagsByPropertyIdData?.data?.filter((el) =>
        filteredData?.includes(el.tag_id.toString())
      );
      setTagRowData(tagData);
      setAddedDocument(
        getDocumentPackageByPackageId?.data?.[0]?.document_details
      );
      setActive(getDocumentPackageByPackageId?.data?.[0]?.is_active);
      setSelected(filteredData);
      setSelectedData(tagData);
      setCancelPopup(false);
    } else {
      // setDocumentTitle("");
      setTitle("");
      setTagRowData([]);
      setAddedDocument([]);
      setSelected([]);
      setSelectedData([]);
      setCancelPopup(false);
      setActive(true);
    }
  }, [getDocumentPackageByPackageId, tagsByPropertyIdData]);

  const HandlePreviewAllDocuments = () => {};

  // dropzone
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    acceptedFiles.forEach((file) => {
      // setDroppedPdf((prev) => [...prev, file]);
      append({
        name: file.name,
        document_type: "",
        file: file,
      });
    });
    fileRejections.map(({ file, errors }) => {
      toast.error("Invalid file type " + file.name);
    });
  }, []);

  const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } =
    useDropzone({
      onDrop,
      accept: {
        "application/pdf": [".pdf"],
      },
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      disabled: PostUploadDocumentIsLoading,
    });

  // Handle toggle switch

  const toggleCheckedData = (e, data, index) => {
    const { checked } = e?.currentTarget;
    const newArray = [...addedDocument];
    if (checked) {
      newArray[index]["is_published"] = 1;
    } else {
      const newArray = [...addedDocument];
      newArray[index]["is_published"] = 0;
    }
    setAddedDocument(newArray);
  };

  // Upload document manually Columns
  const uploadDocumentManuallyColumn = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
        Cell: ({ cell }) => {
          return (
            <div
              className="bg-white radius-6"
              style={{ padding: "0.5rem 0.8rem", border: "1px solid #D1D1D1" }}
            >
              {cell.getValue()}
            </div>
          );
        },
      },
      {
        accessorKey: "document_type",
        header: "Document type",
        minSize: 100, //min size enforced during resizing
        maxSize: 200, //max size enforced during resizing
        size: 180, //medium column
        Cell: ({ cell }) => {
          return (
            <ReactSelect
              placeholder=""
              defaultValue={{
                value: "House regulation",
                label: "House regulation",
              }}
              // options={UpFrontPaymentData?.map((item) => ({
              //   value: item?.value,
              //   label: `${item?.label} `,
              // }))}
              searchable={false}
              clearable={false}
            />
          );
        },
      },

      {
        accessorKey: "delete_uploaded_doc",
        header: "",
        enableSorting: false,
        size: 50,
        Cell: ({ cell }) => (
          <button
            className="added-document-delete-btn"
            onClick={() => deletePdf(cell.row.index, "droppedPdf")}
          >
            <FontAwesomeIcon icon="fa-regular fa-trash" className="fs-6" />
          </button>
        ),
      },
    ],
    []
  );

  // selected pdf showing in table column
  const addedDocumentcolumns = useMemo(
    () => [
      {
        accessorKey: "type",
        header: "Type",
        size: 50,
        Cell: ({ cell }) => (
          <>
            {cell.getValue() === "-" ? (
              <span>-</span>
            ) : (
              <FontAwesomeIcon icon="fa-regular fa-file-pdf" className="fs-6" />
            )}
          </>
        ),
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 180,
        muiTableBodyCellProps: {
          sx: { cursor: "pointer !important" },
        },
      },
      {
        accessorKey: "document_type_name",
        header: "Document type",
        size: 180,
      },

      {
        accessorKey: "publish_required",
        header: "Publish",
        size: 100,
        Cell: ({ row }) => {
          return row.original.publish_required === "-" ? (
            <span>-</span>
          ) : (
            <div>
              <Toggle
                checked={row.original.is_published == 1 ? true : false}
                onChange={(e) => {
                  toggleCheckedData(e, row.original, row.index);
                }}
                id={row.index}
              />
            </div>
          );
        },
      },
      {
        accessorKey: "delete_document", //normal accessorKey
        header: "",
        enableSorting: false,
        size: 50,
        Cell: ({ cell }) =>
          cell.getValue() !== "-" && (
            <button
              className="added-document-delete-btn"
              onClick={() => {
                setDeletePopUp(!deletePopUp);
                setEditDocumentId(cell.row.original.doc_file_id);
              }}

              // onClick={() =>
              //   deletePdf(
              //     cell.row.original.doc_file_id,
              //     cell.row.index,
              //     "documentAddedPdf"
              //   )
              // }
            >
              <FontAwesomeIcon icon="fa-regular fa-trash" className="fs-6" />
            </button>
          ),
      },
    ],
    [addedDocument]
  );

  // uploading selected pdf to server and getting response
  const handleAddDocument = async (data) => {
    let files = [];
    for (let i = 0; i < data?.droppedPdf?.length; i++) {
      let id;
      if (!PostUploadDocumentIsLoading) {
        id = toast.loading("Uploading file");
      }
      let formData = new FormData();
      formData.append("files[]", data?.droppedPdf?.[i]?.file);
      formData.append("property_id", propOverview?.[0].property_id);
      formData.append("package_id", packageId);
      formData.append(
        "document_type",
        data?.droppedPdf?.[i]?.document_type?.value
      );
      formData.append("document_template_file_id", "0");
      PostUploadDocument(formData)
        .then((response) => {
          if (response?.data?.bReturn) {
            files[i] = response?.data?.data?.[0];
            toast.update(id, {
              render: response?.data?.message,
              isLoading: false,
              type: status.s,
              autoClose: 2000,
            });

            if (files?.length) {
              if (
                addedDocument[0]?.name === "-" ||
                addedDocument[0]?.document_type_name === "-" // if there is no data in addedDocument state i.e '-'.
              ) {
                setAddedDocument(
                  files?.filter((val) => {
                    if (val) {
                      return val; // removing failed pdf (index) -> document type from files
                    }
                  })
                );
              } else {
                // if there is any data in addedDocument state then it will adding previous data with new one
                setAddedDocument((prev) => [
                  ...prev,
                  ...files?.filter((val) => {
                    if (val) return val;
                  }),
                ]);
              }
            }
            remove(); // clearing dropped pdf state so every time new pdf will be added in upload document modal
            setOpenUploadDocumentManually(false);
          } else {
            toast.update(id, {
              render: response?.data?.message,
              isLoading: false,
              type: status.e,
              autoClose: 2000,
            });
          }
        })
        .catch((error) => {
          toast.update(id, {
            render: error.message,
            isLoading: false,
            type: status.e,
            autoClose: 2000,
          });
        });
    }
  };

  // deleting selected pdf from upload document manually modal
  const [editDocumentId, setEditDocumentId] = useState("");
  const [deletePopUp, setDeletePopUp] = useState(false);

  const deletePdf = (docFileId, deletePdfIndex, tableType) => {
    setDeletePopUp(false);
    setDisableDeleteButton(true);
    if (tableType === "droppedPdf") {
      setDroppedPdf((prev) => prev.filter((data, i) => i !== deletePdfIndex));
      setDisableDeleteButton(false);
    } else if (tableType === "documentAddedPdf") {
      DeleteDocument(docFileId).then((response) => {
        setAddedDocument((prev) =>
          prev.filter((data, i) => data?.doc_file_id !== docFileId)
        );
        toast.success(response.data.message);
        setDisableDeleteButton(false);
      });
    }
  };

  const RemovePdf = (index) => {
    remove(index);
  };

  //Put update complete details
  const handleCreatePackage = async () => {
    const putUpdateCompleteDetails = {
      package_id: packageId?.toString(),
      property_id: String(propOverview?.[0]?.property_id),
      tags: tagRowData?.map((item) => {
        return item.tag_id;
      }),
      package_name: title,
      is_active: active ? "1" : "0",
      signing_package_type: "0",
      doc_files: addedDocument?.map((item) => ({
        doc_file_id: item?.doc_file_id,
        doc_type_id: item?.document_type_id,
        is_signing: "0",
        is_published:
          item?.is_published === "" ? 0 : item?.is_published?.toString(),
      })),
    };

    const response = await PutUpdateDocumentCompleteDetails(
      putUpdateCompleteDetails
    );
    if (response.status === status.s && response.data.bReturn) {
      toast.success(response.data.message);
      // setShowDocumentPackages(false);
      setShowPackage(false);
    }
  };

  //post add document package & put update basic details
  const handleAddDocumentBasicDetails = async () => {
    if (editflag || flagUpdateDocument) {
      const putUpdateBasicDetails = {
        package_id: packageId,
        tags: tagRowData?.map((item) => {
          return item.tag_id;
        }),
        package_name: title,
        is_active: active ? "1" : "0",
        signing_package_type: "0",
      };

      try {
        const response = await PutUpdateDocumentBasicDetails(
          putUpdateBasicDetails
        );
        if (response.status == status.s && response.data.bReturn) {
          toast.success(response.data.message);
          setFlagUpdateDocument(false);
          setIsSettingTabOpen(false);
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      const addDocumentPayload = {
        package_name: title,
        property_id: propOverview?.[0]?.property_id?.toString(),
        is_active: active ? "1" : "0",
        is_signing: "0",
        signing_package_type: "0",
        tags: tagRowData?.map((item) => {
          return item.tag_id;
        }),
      };

      try {
        const response = await PostAddDocumentPackage(addDocumentPayload);
        if (response.status === status.s && response.data.bReturn) {
          toast.success(response.data.message);
          // setDocumentPackageId(response?.data?.data?.document_package_id);
          setPackageId(response?.data?.data?.document_package_id);
          setIsSettingTabOpen(false);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };
  return (
    <>
      <div style={{ backgroundColor: "#F9FAFA" }} className="pt-3">
        <H1>{editflag ? "Edit" : "Create new"} package</H1>
        <div className="mb-4 light-font-text">
          {/* {isEditMediaPackage && ( */}
          <div>
            <Link
              to="/properties"
              style={{ textDecoration: "none", color: "black" }}
            >
              <span className="">Property</span>
            </Link>
            <span className="mx-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>

            <Link
              to="/property-units/overview"
              state={{
                Tab: "Overview",
              }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <span className="">{propOverview?.[0]?.propertyName}</span>
            </Link>
            <span className="mx-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <span
              className=" cursor-pointer"
              // onClick={() => setShowDocumentPackages(false)}>
              onClick={() => setShowPackage(false)}
            >
              Documents
            </span>
            <span className="mx-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <span className=" cursor-pointer">Document packages</span>
            <span className="mx-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <span className="">{editflag ? "Edit" : "Create new"} package</span>
            <span className="mx-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <PrimaryBreadcrums
              mode={isSettingTabOpen}
              // style={{ color: isSettingTabOpen ? "#619130" : "#000" }}
            >
              1. Settings
            </PrimaryBreadcrums>
            {!isSettingTabOpen && (
              <>
                <span className="mx-3">
                  <FontAwesomeIcon
                    size="sm"
                    icon="fa-regular fa-angle-right"
                    style={{ color: "#000000" }}
                  />
                </span>
                <PrimaryBreadcrums
                  mode={!isSettingTabOpen}
                  // style={{ color: isSettingTabOpen ? "#000" : "#619130" }}
                  className="mx-1 "
                >
                  2. Documents
                </PrimaryBreadcrums>
              </>
            )}
          </div>
          {/* )} */}
        </div>
        <div
          className="pt-4 pb-4 my-3  radius-8 containerStyle"
          // style={{ backgroundColor: "#F1F5F0" }}
        >
          <div
            className="d-flex gap-2 px-4 "
            style={{ alignItems: "center", textAlign: "center" }}
          >
            <div>
              <CustomersTab
                mode={isSettingTabOpen}
                // className={`${
                //   isSettingTabOpen == false
                //     ? "document-tabs"
                //     : "document-tabs-active"
                // } px-3`}
              >
                {!isSettingTabOpen && (
                  <FontAwesomeIcon
                    icon="fa-solid fa-circle-check"
                    className="me-2"
                    style={{ color: "#77C7A1", fontSize: "0.9rem" }}
                  />
                )}
                1. Settings
              </CustomersTab>
            </div>
            <div style={{ alignItems: "center", textAlign: "center" }}>
              <FontAwesomeIcon
                icon="fa-regular fa-chevron-right"
                style={{ color: "#010204", width: "8px", height: "11px" }}
              />
            </div>
            <div>
              <CustomersTab
                mode={!isSettingTabOpen}
                // className={`${
                //   isSettingTabOpen == false
                //     ? "document-tabs-active"
                //     : "document-tabs"
                // } px-3 `}
              >
                {/* {isEditMediaPackage ? "Edit" : "Add"}  */}
                2. Documents
              </CustomersTab>
            </div>
          </div>
          {isSettingTabOpen ? (
            <div className="px-4 mt-2">
              <AddEditPackage
                // isEditPackage={isEditDocumentPackages}
                propertyId={propOverview?.[0]?.property_id}
                documentbypackage={documentbypackage}
                modifiedData={modifiedData}
                packageType={packageType}
                // title={documentTitle}
                // setTitle={setDocumentTitle}
                // tagRowData={tagRowData}
                // setTagRowData={setTagRowData}
                tagSubHeading={
                  "Select the tags to be associated with this package. The package will be linked to the units that use the same tag and documents can be accessed under the units documents."
                }
                showActiveInactive={true}
                // active={active}
                // setActive={setActive}
                handleNext={handleAddDocumentBasicDetails}
                disableNextButton={
                  PostAddDocumentPackageIsLoading ||
                  PutUpdateDocumentBasicDetailsIsLoading
                }
                // cancelPackage={setCancelPopup}
                // selected={selected}
                // setSelected={setSelected}
                // selectedData={selectedData}
                // setSelectedData={setSelectedData}
              />
            </div>
          ) : (
            <>
              <div
                className="my-2 mx-4 overflow-hidden"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  fontSize: "14px",
                  border: "1px solid #F0F0F0",
                }}
              >
                <div
                  className="px-3 pb-3"
                  style={{ borderBottom: "1px solid #F0F0F0" }}
                >
                  <div className="px-2 pt-3 d-flex align-items-center">
                    <H2>2. {editflag ? "Edit" : "Add"} documents</H2>
                    <div className="ps-2">
                      <CustomToolTip
                        height={12}
                        text={editflag ? "Edit documents" : "Add documents"}
                      />
                    </div>
                  </div>
                  <div className=" ps-1">
                    {modifiedData?.map((item) => (
                      <>
                        {item.modified_by && item.modified_on ? (
                          <div className="created-by ps-1">
                            Last updated by {item.modified_by},{item.modifiedOn}
                          </div>
                        ) : (
                          <>
                            {item.created_by && item.created_on ? (
                              <div className="created-by">
                                Created by {item.created_by},{item.createdOn}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </>
                    ))}
                  </div>
                </div>

                <div className="px-4 py-3">
                  <div className="d-flex flex-wrap justify-content-between">
                    <div>
                      <p className="mb-0 form-label-title">Documents</p>
                      <span className="light-font-text grey-text ">
                        Below are the documents uploaded to the current package.
                      </span>
                    </div>
                    <ETButton
                      variant={"primary"}
                      buttonText="UPLOAD DOCUMENT MANUALLY"
                      icon={true}
                      width="fit-content"
                      onClick={() => {
                        setOpenUploadDocumentManually(true);
                      }}
                    />
                  </div>
                  <div className="mt-4">
                    <MRTTheme
                      colorVariantsForTableData={["odd"]}
                      table={
                        <MaterialReactTable
                          columns={addedDocumentcolumns}
                          muiTableBodyCellProps={({ cell, row }) => ({
                            onClick: (event) => {
                              if (
                                (cell.id.includes("name") &&
                                  cell.getValue("name") != "-") ||
                                (cell.id.includes("type") &&
                                  cell.getValue("type") != "-")
                              ) {
                                setAddDocumentPackagePopup(true);
                                setAllDocumentDetails({
                                  allDocumentPackageNameUrl:
                                    row.original?.file_url,
                                  allDocumentPackageFileName:
                                    row.original?.name,
                                });
                              }
                            },
                          })}
                          data={addedDocument ?? []}
                          icons={{ ...SortIcon }}
                          state={{ isLoading: !addedDocument }}
                          enableTopToolbar={false}
                          enableColumnActions={false}
                          enablePagination={false}
                          enableRowActions
                          muiTableContainerProps={{
                            sx: {
                              padding: "0px !important",
                            },
                          }}
                          muiTableHeadRowProps={{
                            sx: {
                              ...TableRowsStyle,
                            },
                          }}
                          positionActionsColumn="last"
                          // renderRowActions={({ row }) => (
                          // <button
                          //   className="added-document-delete-btn"
                          //   onClick={() => {
                          //     setDeletePopUp(!deletePopUp);
                          //     setEditDocumentId(row.original.doc_file_id);
                          //   }}
                          // >
                          //   <FontAwesomeIcon
                          //     icon="fa-regular fa-trash"
                          //     className="fs-6"
                          //   />
                          // </button>
                          // )}
                          displayColumnDefOptions={{
                            "mrt-row-actions": {
                              header: "",
                              size: 50,
                              // muiTableBodyCellProps: {
                              //   sx: {
                              //     // padding: "0px !important",
                              //     backgroundColor: "#F0F0F0 !important",
                              //     border: "none !important",
                              //   },
                              // },
                            },
                          }}
                        />
                      }
                    />
                    <CustomPopup
                      // modalHeadingStyle={modalHeadingStyle}
                      style={{ position: "relative" }}
                      size="md"
                      open={deletePopUp}
                      onHide={() => setDeletePopUp(false)}
                      title="Are you carrying out the trash?"
                      title_color="black"
                      enableCrossButton={false}
                      onCloseAction={() => {
                        setDeletePopUp(false);
                      }}
                      action_button_label="YES"
                      action_button_label_color="white"
                      action_button_color="#619130"
                      cancel_button={true}
                      cancel_button_bordercolor="grey"
                      cancel_button_label="NO"
                      cancel_button_label_color="black"
                      cancel_button_color="#fff"
                      footer_color={"#F9FAFA"}
                    >
                      <div className="p-4">
                        <div className="fs-14 fw-300 text-left mb-3">
                          <p>Are you sure you want to delete the document?</p>
                          {/* <p>That will conflict with.....</p> */}
                        </div>
                      </div>

                      <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
                        <ETButton
                          variant={"white"}
                          buttonText="COLD FEET"
                          onClick={() => setDeletePopUp(false)}
                        />

                        <ETButton
                          variant={"primary"}
                          buttonText="I'M SURE"
                          disabled={disableDeleteButton}
                          onClick={() =>
                            deletePdf(editDocumentId, null, "documentAddedPdf")
                          }
                        />
                      </div>
                    </CustomPopup>
                    {addedDocument[0]?.name === "-" &&
                      addedDocument[0]?.document_type_name === "-" && (
                        <div className="mt-1 mb-5">
                          <p className="mb-0 grey-text fw-300 text-center">
                            You do not have any document yet. Click on “+UPLOAD
                            DOCUMENT MANUALLY” to add your very first document.
                          </p>
                        </div>
                      )}
                  </div>
                </div>
                <div
                  className="d-flex flex-wrap gap-2 justify-content-between px-3 py-2"
                  style={{ background: "#F9FAFA" }}
                >
                  <ETButton
                    buttonText="CANCEL"
                    variant={"white"}
                    width="fit-content"
                    onClick={() => setCancelPopup(true)}
                  />
                  <div className="d-flex gap-2">
                    <ETButton
                      buttonText="PREVIOUS"
                      variant={"white"}
                      width="fit-content"
                      onClick={() => {
                        setIsSettingTabOpen(true);
                        setFlagUpdateDocument(true);
                      }}
                    />
                    {/* <Button
											// disabled
											className="border text-dark document-previous py-1 bg-white shadow-none rounded-5"
											style={{ fontWeight: "600", fontSize: "14px" }}
											onClick={() => {
												setIsSettingTabOpen(true);
												setFlagUpdateDocument(true);
											}}>
											PREVIOUS
										</Button> */}
                    <ETButton
                      disabled={
                        (addedDocument?.[0]?.name === "-" ? true : false) ||
                        PutUpdateDocumentCompleteDetailsIsLoading
                      }
                      variant={"primary"}
                      buttonText={
                        editflag ? "SAVE AND CLOSE" : "CREATE PACKAGE"
                      }
                      width="fit-content"
                      onClick={handleCreatePackage}
                    />
                  </div>
                </div>
              </div>

              {/* upload document manually */}

              <CustomPopup
                open={openUploadDocumentManually}
                onHide={() => {
                  setOpenUploadDocumentManually(false);
                  remove();
                }}
                title="Upload document manually"
                title_color="black"
                enableCrossButton={false}
                action_button_label="YES"
                action_button_label_color="white"
                action_button_color="#619130"
                cancel_button={true}
                cancel_button_bordercolor="grey"
                cancel_button_label="NO"
                cancel_button_label_color="black"
                cancel_button_color="#fff"
                footer_color={"#F9FAFA"}
                deletePopUpStyle={true}
                titleTooltip={true}
                size="lg"
              >
                <form onSubmit={handleSubmit(handleAddDocument)}>
                  <div className="p-3">
                    <div className="px-2">
                      <div className="light-font-text my-1">
                        Drag and drop documents directly from your computer. It
                        is possible to change the name of the document and
                        select a document type. Once you have selected and
                        customized the documents, click add and they will be
                        added to the package.
                      </div>
                    </div>
                    <Row className="mt-3 px-2 upload-manually-document-table">
                      <Col md={{ span: 12 }}>
                        <div className="pt-2 pb-4">
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <DropZoneBorder className="py-4 d-flex flex-column justify-content-center align-items-center">
                              <div>
                                {/* <FontAwesomeIcon icon="fa-solid fa-file" /> */}
                                <FontAwesomeIcon
                                  icon="fa-solid fa-file-pdf"
                                  style={{ color: "#000000" }}
                                />
                              </div>
                              <div className="mt-2 mb-1 form-label-title">
                                Drop your file here, or&nbsp;
                                <GreenSpan
                                  style={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={open}
                                >
                                  browse your computer
                                </GreenSpan>
                              </div>
                              <div className="created-by">
                                Supports only PDF
                              </div>
                            </DropZoneBorder>
                          </div>
                        </div>
                        <div>
                          {/* {droppedPdf?.length !== 0 && ( */}
                          <div className="mb-4">
                            <Row className="m-0 p-0">
                              {fields.length !== 0 && (
                                <Row className="m-0 p-0 mb-2">
                                  <Col
                                    className="d-flex align-items-center py-2  actors-section-title fs-13 ps-3"
                                    xs={7}
                                  >
                                    Name
                                    {/* <FontAwesomeIcon
                          icon="fa-solid fa-sort"
                          className="ms-1"
                          style={{ color: "#bfbfbf" }}
                        /> */}
                                  </Col>
                                  <Col
                                    className="d-flex align-items-center py-2 actors-section-title fs-13 ps-0 "
                                    xs={4}
                                  >
                                    Document type
                                    {/* <FontAwesomeIcon
                          icon="fa-solid fa-sort"
                          className="ms-1"
                          style={{ color: "#bfbfbf" }}
                        /> */}
                                  </Col>
                                  <Col xs={2}></Col>
                                </Row>
                              )}

                              <div
                                className="p-0"
                                style={{
                                  maxHeight: "160px",
                                  overflow: "auto",
                                }}
                              >
                                {fields.map((data, i) => (
                                  <div key={data.id}>
                                    <Row
                                      className="m-0 p-0 mb-2"
                                      style={{
                                        borderRadius: "8px",
                                        backgroundColor: "#F9FAFA",
                                      }}
                                    >
                                      <Col
                                        className="d-flex align-items-start py-2 pe-4"
                                        xs={7}
                                      >
                                        <div
                                          className="bg-white radius-6 ms-md-1 w-100 ellipsis "
                                          style={{
                                            padding: "0.3rem 0.5rem",
                                            border: "1px solid #D1D1D1",
                                            marginTop: "2px",
                                            height: "38px",
                                          }}
                                        >
                                          <p className="mb-0 fs-300 fs-14">
                                            <Controller
                                              name={`droppedPdf[${i}].name`}
                                              control={control}
                                              defaultValue={data.name}
                                              render={({ field }) => (
                                                <input
                                                  disabled
                                                  className="standard-input"
                                                  {...field}
                                                />
                                              )}
                                            />
                                          </p>
                                        </div>
                                      </Col>
                                      <Col
                                        className="d-flex align-items-start py-2 pe-4 ps-0"
                                        xs={4}
                                      >
                                        <div className="w-100 fs-300 fs-14">
                                          <Controller
                                            {...register(
                                              `droppedPdf[${i}].document_type`
                                            )}
                                            rules={{ required: true }}
                                            render={({
                                              field: { onChange, value },
                                            }) => (
                                              <ReactSelect
                                                placeholder=""
                                                value={value}
                                                clearable={false}
                                                isSearchable
                                                disabled={
                                                  PostUploadDocumentIsLoading
                                                }
                                                onChange={(e) => {
                                                  onChange(e);
                                                  clearErrors(
                                                    `droppedPdf[${i}].document_type`
                                                  );
                                                }}
                                                error={errors.document_type}
                                                options={documentTypeData?.data?.map(
                                                  (item) => ({
                                                    value: item?.Id,
                                                    label: item?.Text,
                                                  })
                                                )}
                                              />
                                            )}
                                            name={`droppedPdf[${i}].document_type`}
                                            control={control}
                                          />

                                          {errors?.droppedPdf?.[i]
                                            ?.document_type && (
                                            <p className="error-msg">
                                              {REQUIRED_ERR}
                                            </p>
                                          )}
                                        </div>
                                      </Col>
                                      <Col
                                        className="d-flex align-items-start py-2 justify-content-end"
                                        xs={1}
                                      >
                                        <div>
                                          <button
                                            className="added-document-delete-btn"
                                            onClick={() => RemovePdf(i)}
                                            disabled={
                                              PostUploadDocumentIsLoading
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon="fa-regular fa-trash"
                                              className="fs-6"
                                            />
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                              </div>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
                    <ETButton
                      variant={"white"}
                      buttonText="CANCEL"
                      onClick={() => {
                        setOpenUploadDocumentManually(false);
                        // setDroppedPdf([]);
                        remove();
                      }}
                      disabled={PostUploadDocumentIsLoading}
                    />
                    <ETButton
                      variant={"primary"}
                      buttonText="ADD"
                      type="submit"
                      disabled={PostUploadDocumentIsLoading}
                      // onClick={handleAddDocument}
                    />
                  </div>
                </form>
              </CustomPopup>
              {/* upload document manually */}
            </>
          )}
        </div>
      </div>

      {/* ------------------------ Siging document preview Pop Up ----------------------- */}
      {addedDocument?.[0]?.name !== "-" && (
        <CustomPopup
          open={addDocumentPackagePopup}
          onHide={() => setAddDocumentPackagePopup(false)}
          modalHeaderText={true}
          title={allDocumentDetails?.allDocumentPackageFileName}
          size="xl"
          title_color="black"
          enableCrossButton={true}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
          deletePopUpStyle={true}
          subTitle={allDocumentDetails?.documentName}
          titleTooltip={false}
          // titleTooltipText={allDocumentDetails?.allDocumentPackageFileName}
        >
          <div>
            <Row>
              <Col className="">
                <div style={{ height: "540px" }}>
                  <iframe
                    src={allDocumentDetails?.allDocumentPackageNameUrl}
                    height="100%"
                    width="100%"
                  ></iframe>
                </div>
              </Col>
            </Row>
          </div>
          <div
            className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
            style={{
              backgroundColor: "#F9FAFA",
              borderRadius: " 0px 0px 8px 8px",
              border: "1px solid #F0F0F0",
            }}
          ></div>
        </CustomPopup>
      )}

      {/* cancel popup */}

      <CustomPopup
        style={{ position: "relative" }}
        size="md"
        open={cancelPopup}
        onHide={() => setCancelPopup(false)}
        title="Are you sure you want to cancel?"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() => {
          setCancelPopup(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div
            style={{
              fontSize: "14px",
              fontFamily: "Cerebri Sans",
              fontWeight: "500",
              textAlign: "left",
              lineHeight: "17.5px",
            }}
          >
            Cancelling will undo all your changes
          </div>
        </div>

        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="NO"
            onClick={() => setCancelPopup(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="YES"
            // onClick={() => setShowDocumentPackages(false)}
            onClick={() => setShowPackage(false)}
          />
        </div>
      </CustomPopup>
    </>
  );
};
