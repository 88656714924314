import React, { useEffect, useState } from "react";
import { Col, Image, Placeholder, Row } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import photoComingSoon from "../../../assets/images/photo-coming-soon.jpg";
import {
  usePropertyId,
  usePropertyOverview,
} from "../../../hooks/stores/usePropertyStore.js";
import { useUnitId, useUnitOverview } from "../../../hooks/stores/useUnitStore";
import { useGetUnitOverviewByUnitId } from "../../../hooks/queries/useUnitQueries";
import { useGetPropertyWithOverviewByPropertyId } from "../../../hooks/queries/usePropertyQueries";
import UnitLevelTopBar from "../../../Components/Topbars/UnitLevelTopbar";
import PropertyLevelTopBar from "../../../Components/Topbars/PropertyLevelTopBar";
import OrganisationLevelTopBar from "../../../Components/Topbars/OrganisationLevelTopBar";
import DivisionLevelTopBar from "../../../Components/Topbars/DivisionLevelTopBar";
import CompanyLevelTopBar from "../../../Components/Topbars/CompanyLevelTopBar";

const Topbar = () => {
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: "#000",
      width: 220,
      fontFamily: "Cerebri sans",
      fontSize: "14px",
      border: "1px solid #F4F5F5",
      borderRadius: "8px",
    },
  }));

  const [onHover, setOnHover] = useState(false);
  const [onPropertyNameHover, setPropertyNameHover] = useState(false);
  const { propertyId } = usePropertyId((state) => state);
  const {
    data: GetPropertyWithOverviewByPropertyIdData,
    refetch: RefetchGetPropertyWithOverviewByPropertyIdData,
  } = useGetPropertyWithOverviewByPropertyId(propertyId, 1);
  // Zustand get data for propertyOverview
  const { propOverview, setPropOverview } = usePropertyOverview(
    (state) => state
  );

  // Zustand set and get data for unitOverview
  const unitOverview = useUnitOverview((state) => state.unitOverview);

  const setUnitOverview = useUnitOverview((state) => state.setUnitOverview);

  // Zustand get unitId
  const UnitId = useUnitId((state) => state.UnitId);
  // Api data for unitOverview
  const { data: GetUnitOverviewByUnitId } = useGetUnitOverviewByUnitId(UnitId);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);
  useEffect(() => {
    setUnitOverview(GetUnitOverviewByUnitId?.data);
  }, [GetUnitOverviewByUnitId]);
  useEffect(() => {}, [window.location.pathname]);

  useEffect(() => {
    RefetchGetPropertyWithOverviewByPropertyIdData();
  }, []);
  useEffect(() => {
    setPropOverview(GetPropertyWithOverviewByPropertyIdData?.data);
  }, [
    RefetchGetPropertyWithOverviewByPropertyIdData,
    GetPropertyWithOverviewByPropertyIdData,
  ]);

  // console.log(backgroundColor, "backgroundColor");

  return (
    <>
      {window.location.pathname == "/create-new-reservation" ||
      window.location.pathname == "/actor" ||
      window.location.pathname?.includes("property-units-edit") ? (
        <UnitLevelTopBar unitData={unitOverview} />
      ) : window.location.pathname == "/property-units/overview" ||
        window.location.pathname == "/property-units/units" ||
        window.location.pathname.includes("eido") ||
        window.location.pathname == "/signing-forms" ||
        window.location.pathname?.includes("property-units") ? (
        <div style={{ minHeight: "120px", borderBottom: "1px solid #F0F0F0" }}>
          <Placeholder animation="glow">
            <PropertyLevelTopBar propertyData={propOverview} />
          </Placeholder>
        </div>
      ) : window.location.pathname?.includes("organization") ? (
        <div style={{ minHeight: "120px", borderBottom: "1px solid #F0F0F0" }}>
          <OrganisationLevelTopBar />{" "}
        </div>
      ) : window.location.pathname?.includes("division") ? (
        <div style={{ minHeight: "120px", borderBottom: "1px solid #F0F0F0" }}>
          <DivisionLevelTopBar />
        </div>
      ) : window.location.pathname?.includes("company") ? (
        <div style={{ minHeight: "120px", borderBottom: "1px solid #F0F0F0" }}>
          <CompanyLevelTopBar />
        </div>
      ) : window.location.pathname == "/add-new-guide" ? (
        <div style={{ minHeight: "120px", borderBottom: "1px solid #F0F0F0" }}>
          <DivisionLevelTopBar />
        </div>
      ) : window.location.pathname == "/create-new-template" ? (
        <div style={{ minHeight: "120px", borderBottom: "1px solid #F0F0F0" }}>
          <OrganisationLevelTopBar />{" "}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Topbar;
