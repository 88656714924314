import React, { useState } from "react";
import { Container, Row, Col, Image, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import TooltipComponent from "../../../../Components/Tooltip/CustomTooltip";
import Switch from "../../../../Components/Switch/Switch";
import GroupButtons from "../../../../Components/GroupButton/GroupButton";
import ETButton from "../../../../Components/Buttons/ETButton";
import DateRangePicker from "../../../../Components/DateRangePicker/DateRangePicker";
import GroupText from "../../../../Components/GroupText/GroupText";
import CustomPopup from "../../../../Components/CustomPopup";
import {H2} from "../../../../styles/Common.style";

import moment from "moment";

const AlertPopup = () => {
  return (
    <div>
      <div
        // style={{ border: "1px solid #f4f5f5", backgroundColor: "#fff" }}
        className="px-4 pt-4 pb-5"
      >
        <H2 className="fs-20 ">Whoa that's not possible!</H2>
        <div className="fs-13">
          You cannot have several milestones on the same property on the map at
          the same time. Edit the milestone that is already active or replace
          this milestone to activate it and set the old one inactive. It is
          always possible to reactivate a previous milestone.
        </div>
      </div>

      <div
        className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
        style={{
          backgroundColor: "#F9FAFA",
          borderRadius: " 0px 0px 8px 8px",
          border: "1px solid #F0F0F0",
        }}
      >
        <ETButton
          variant={"white"}
          buttonText="NO"
          //   onClick={() => setChangeAgent(false)}
        />

        <ETButton
          variant={"primary"}
          buttonText="REPLACE"
          //   onClick={() => setChangeAgent(false)}
        />
      </div>
    </div>
  );
};

const ManualMileStone = ({ setManualMileStone }) => {
  const Status = [
    {
      value: "Available",
      name: "available",
      id: "10",
    },
    {
      value: "Reserved",
      name: "reserved",
      id: "50",
    },
    {
      value: "Sold",
      name: "sold",
      id: "80",
    },
    {
      value: "Leased",
      name: "leased",
      id: "90",
    },
    {
      value: "idleness",
      name: "idleness",
      id: "11",
    },
  ];

  const Comparision = [
    {
      value: "is",
      name: "is",
      id: "1",
    },
    {
      value: "over",
      name: "over",
      id: "2",
    },
    {
      value: "under",
      name: "under",
      id: "3",
    },
  ];

  const Parameter = [
    {
      value: "Units",
      name: "Units",
      id: "1",
    },
    {
      value: "Percentage",
      name: "percentage",
      id: "2",
    },
  ];

  const Colors = [
    {
      id: 1,
      value: "Pink",
      color: "#B23977",
    },
    {
      id: 2,
      value: "Black",
      color: "#000000",
    },
    {
      id: 3,
      value: "Blue",
      color: "#A2C8D0",
    },
    {
      id: 4,
      value: "Green",
      color: "#AAC992",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      milestoneName: "",
      selectedProp: "",
      targetDate: "",
      statusValue: "",
      mileResolution: "",
      is: "",
      value: "",
      parameter: "",
      color: "",
      visibility: "",
      useAs: "",
    },
  });
  const [goalsData, setGoalsData] = useState([]);
  const [alertMileStonePopup, setAlertMileStonePopup] = useState(false);

  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const HandleAlertMilestone = () => {
    setAlertMileStonePopup(true);
  };

  const onHandleMileStone = (data) => {
    console.log("object", data);
  };

  const generateGoal = (resolutionID) => {
    setGoalsData([]);
    const startYear = moment(date[0].startDate).year();
    const endYear = moment(date[0].endDate).year();

    let startNumber = 0;
    let endNumber = 0;

    console.log(startNumber, endNumber, "Start and end number");
    console.log(
      "Weeks",
      moment(date[0].endDate, "YYYY-MM-DD").diff(
        moment(date[0].startDate, "YYYY-MM-DD"),
        "week"
      )
    );

    for (let year = startYear; year <= endYear; year++) {
      if (startYear === endYear) {
        startNumber =
          resolutionID === "1"
            ? moment(date[0].startDate, "YYYY-MM-DD").isoWeek()
            : resolutionID === "2"
            ? moment(date[0].startDate, "YYYY-MM-DD").month()
            : resolutionID === "3"
            ? moment(date[0].startDate, "YYYY-MM-DD").quarter()
            : 0;
        endNumber =
          resolutionID === "1"
            ? moment(date[0].endDate, "YYYY-MM-DD").isoWeek()
            : resolutionID === "2"
            ? moment(date[0].endDate, "YYYY-MM-DD").month()
            : resolutionID === "3"
            ? moment(date[0].endDate, "YYYY-MM-DD").quarter()
            : 0;
      } else if (startYear !== endYear) {
        if (year === startYear && year !== endYear) {
          startNumber =
            resolutionID === "1"
              ? moment(date[0].startDate, "YYYY-MM-DD").isoWeek()
              : resolutionID === "2"
              ? moment(date[0].startDate, "YYYY-MM-DD").month()
              : resolutionID === "3"
              ? moment(date[0].startDate, "YYYY-MM-DD").quarter()
              : 0;
          endNumber =
            resolutionID === "1"
              ? moment(
                  moment([year]).endOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).isoWeek()
              : resolutionID === "2"
              ? moment(
                  moment([year]).endOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).month()
              : resolutionID === "3"
              ? moment(
                  moment([year]).endOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).quarter()
              : 0;
          console.log(
            "cur start year",
            year,
            "- ",
            startNumber,
            "-",
            endNumber
          );
        } else if (year !== startYear && year === endYear) {
          //durationNumber = moment(date[0].endDate, "YYYY-MM-DD").diff(moment(moment([year]).startOf('year').format('YYYY-MM-DD'), "YYYY-MM-DD"),"week");
          startNumber =
            resolutionID === "1"
              ? moment(
                  moment([year]).startOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).isoWeek()
              : resolutionID === "2"
              ? moment(
                  moment([year]).startOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).month()
              : resolutionID === "3"
              ? moment(
                  moment([year]).startOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).quarter()
              : 0;
          endNumber =
            resolutionID === "1"
              ? moment(date[0].endDate, "YYYY-MM-DD").isoWeek()
              : resolutionID === "2"
              ? moment(date[0].endDate, "YYYY-MM-DD").month()
              : resolutionID === "3"
              ? moment(date[0].endDate, "YYYY-MM-DD").quarter()
              : 0;
          console.log("cur end year", year, "- ", startNumber, "-", endNumber);
        } else if (year !== startYear && year !== endYear) {
          //let durationNumber1 = moment(moment([year]).endOf('year').format('YYYY-MM-DD'), "YYYY-MM-DD").diff(moment(moment([year]).startOf('year').format('YYYY-MM-DD'), "YYYY-MM-DD"),"week");
          startNumber =
            resolutionID === "1"
              ? 1
              : resolutionID === "2"
              ? moment(
                  moment([year]).startOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).month()
              : resolutionID === "3"
              ? moment(
                  moment([year]).startOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).quarter()
              : 0;
          endNumber =
            resolutionID === "1"
              ? moment(
                  moment([year]).endOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).diff(
                  moment(
                    moment([year]).startOf("year").format("YYYY-MM-DD"),
                    "YYYY-MM-DD"
                  ),
                  "week"
                )
              : resolutionID === "2"
              ? moment(
                  moment([year]).endOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).month()
              : resolutionID === "3"
              ? moment(
                  moment([year]).endOf("year").format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).quarter()
              : 0;
          console.log(
            "cur not matching year",
            year,
            "- ",
            startNumber,
            "-",
            endNumber
          );
        }
      }
      startNumber = resolutionID === "2" ? startNumber + 1 : startNumber;
      endNumber = resolutionID === "2" ? endNumber + 1 : endNumber;
      for (
        let durationNumber = startNumber;
        durationNumber <= endNumber;
        durationNumber++
      ) {
        setGoalsData((old) => [
          ...old,
          {
            year: year,
            durationNo: durationNumber, // Number of Week, Month, Quarter
            value: 0,
            label:
              (resolutionID === "1"
                ? "Week-" + durationNumber
                : resolutionID === "2"
                ? moment()
                    .month(durationNumber - 1)
                    .format("MMM")
                : resolutionID === "3"
                ? moment()
                    .quarter(durationNumber)
                    .startOf("quarter")
                    .format("MMM") +
                  "-" +
                  moment()
                    .quarter(durationNumber)
                    .endOf("quarter")
                    .format("MMM")
                : " ") +
              ", " +
              year, // Label as per Milestone resolution
          },
        ]);
      }
    }
  };

  const handleMilestoneResolution = (e) => {
    const selectedResolutionID = e.target.value;
    // setMilestoneResolutionID((old) => selectedResolutionID);
    generateGoal(selectedResolutionID);
  };

  const displayItem = (selected) => {

    return {
      value: selected.value,
      label: (
        <div>
          <div className="fs-14 fw-300">{selected.label}</div>
        </div>
      ),
    };
  };

  return (
    <div>
      <div className="">
        <Form onSubmit={handleSubmit(onHandleMileStone)}>
          <div className="scrollable-container-y" style={{ height: "500px" }}>
            <div className="p-4">
              {/* Name of milestone */}

              <Row className="pb-4">
                <Col xs={12} lg={3}>
                  <div className="form-label-title"> Name of milestone</div>
                </Col>
                <Col xs={12} lg={3}>
                  <Controller
                    control={control}
                    {...register("milestoneName")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div className="form-input ">
                        <input
                          type="text"
                          placeholder="name"
                          id="MileStoneName"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("milestoneName");
                          }}
                        />
                        <label htmlFor="milestoneName">Name</label>
                      </div>
                    )}
                  />
                  {errors?.milestoneName && (
                    <p
                      className="error-msg"
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </Col>
                <Col></Col>
              </Row>
              {/* Select property */}
              <Row className="pb-4">
                <Col xs={12} lg={3}>
                  <div className="form-label-title">Select property</div>
                </Col>
                <Col xs={12} lg={3}>
                  <Controller
                    name="selectedProp"
                    {...register("selectedProp")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder="Select Property"
                        value={value}
                        clearable={false}
                        isSearchable
                        //   onChange={(e) => {
                        //     onChange(e);
                        //     handleZip(e);
                        //   }}
                        error={errors.zip}
                        //   options={zipcodeData?.data?.map((zip) => ({
                        //     value: zip?.Id,
                        //     label: `${zip?.Id} ${zip?.Text}`,
                        //   }))}
                      />
                    )}
                  />
                  {errors?.selectedProp && (
                    <p
                      className="error-msg"
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </Col>
                <Col></Col>
              </Row>
              {/* Target date */}

              <Row className="pb-4">
                <Col xs={12} lg={3}>
                  <div className="form-label-title d-flex gap-3 ">
                    Period{" "}
                    <span>
                      <TooltipComponent text="period" height="11px" />
                    </span>
                  </div>
                </Col>
                <Col xs={12} lg={5}>
                  <DateRangePicker
                    milestone={true}
                    setDate={setDate}
                    date={date}
                    onChange={(item) => setDate([item.selection])}
                  />
                </Col>
              </Row>
              {/*  */}

              <Row className="pb-4">
                <Col xs={12} lg={3}>
                  <div className="form-label-title d-flex gap-3 ">
                    Milestone resolution{" "}
                    <span>
                      <TooltipComponent
                        text="Milestone resolution"
                        height="11px"
                      />
                    </span>
                  </div>
                </Col>
                <Col xs={12} lg={3}>
                  <Controller
                    control={control}
                    {...register("mileResolution")}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <GroupButtons
                          tabs={[
                            {
                              id: 1,
                              tabTitle: (
                                <div className="light-font-text p-0">
                                  Weekly
                                </div>
                              ),
                              title: "Weekly",
                            },
                            {
                              id: 2,
                              tabTitle: (
                                <div className="light-font-text p-0 ">
                                  Monthly
                                </div>
                              ),
                              title: "Monthly",
                            },
                            {
                              id: 2,
                              tabTitle: (
                                <div className="light-font-text p-0 ">
                                  Quartely
                                </div>
                              ),
                              title: "Quartely",
                            },
                          ]}
                          currentTab={value}
                          handleTabClick={(tab) => {
                            setValue("mileResolution", tab);
                          }}
                        />
                      );
                    }}
                  />
                </Col>
                <Col></Col>
              </Row>
              {/* Status, value and parameter */}
              <Row className="pb-4">
                <Col xs={12} lg={3}>
                  <div className="form-label-title">
                    Status, value and parameter
                  </div>
                </Col>
                <Col xs={12} lg={3} className="mb-2">
                  <Controller
                    name="statusValue"
                    {...register("statusValue")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder="Status"
                        value={value}
                        clearable={false}
                        isSearchable
                        onChange={(e) => {
                          onChange(e);
                        }}
                        error={errors.statusValue}
                        options={Status?.map((stat) => ({
                          value: stat?.id,
                          label: `${stat?.value}`,
                        }))}
                      />
                    )}
                  />
                  {errors?.statusValue && (
                    <p
                      className="error-msg"
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </Col>

                <Col xs={12} lg={2} className="mb-2">
                  <Controller
                    name="is"
                    {...register("is")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        value={value}
                        clearable={false}
                        isSearchable
                        onChange={(e) => {
                          onChange(e);
                        }}
                        error={errors.is}
                        options={Comparision?.map((zip) => ({
                          value: zip?.id,
                          label: `${zip?.value}`,
                        }))}
                      />
                    )}
                  />
                  {errors?.is && (
                    <p
                      className="error-msg"
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </Col>
                <Col xs={12} lg={1} className="mb-2">
                  <Controller
                    control={control}
                    {...register("value")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div className="form-input ">
                        <input
                          type="text"
                          placeholder="value"
                          id="value"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("value");
                          }}
                        />
                        <label htmlFor="is">value</label>
                      </div>
                    )}
                  />
                  {errors?.value && (
                    <p
                      className="error-msg"
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </Col>
                <Col xs={12} lg={3} className="mb-2">
                  <Controller
                    name="Parameter"
                    {...register("parameter")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder="parameter"
                        value={value}
                        clearable={false}
                        isSearchable
                        onChange={(e) => {
                          onChange(e);
                        }}
                        error={errors.parameter}
                        options={Parameter?.map((zip) => ({
                          value: zip?.Id,
                          label: `${zip?.value}`,
                        }))}
                      />
                    )}
                  />
                  {errors?.parameter && (
                    <p
                      className="error-msg"
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </Col>
              </Row>

              {/* Color */}
              <Row className="pb-4">
                <Col xs={12} lg={3}>
                  <div className="form-label-title">Color</div>
                </Col>
                <Col xs={12} lg={3}>
                  <Controller
                    name="color"
                    {...register("color")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder="Color"
                        clearable={false}
                        isSearchable
                        value={
                          value === "" ? "" : displayItem(value)
                        }
                        onChange={(e) => {
                          onChange(e);
                        }}
                        error={errors.color}
                        options={Colors?.map((zip) => ({
                          value: zip?.id,
                          label: (
                            <div className="d-flex align-items-center light-font-text">
                              <div
                                className="radius-4 me-2"
                                style={{
                                  backgroundColor: zip.color,
                                  width: "16px",
                                  height: "16px",
                                }}
                              ></div>
                              <div>
                                <div className="fs-14  light-font-text">
                                  {" "}
                                  {zip?.value}
                                </div>
                              </div>
                            </div>
                          ),
                        }))}
                      />
                    )}
                  />
                  {errors?.color && (
                    <p
                      className="error-msg"
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </Col>
                <Col></Col>
              </Row>
              {/* Visibility */}

              <Row className="pb-4">
                <Col xs={12} lg={3}>
                  <div className="form-label-title d-flex gap-3 ">
                    Visibility{" "}
                    <span>
                      <TooltipComponent text="Visibility" height="11px" />
                    </span>
                  </div>
                </Col>
                <Col xs={12} lg={3}>
                  <Controller
                    control={control}
                    {...register("visibility")}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <GroupButtons
                          tabs={[
                            {
                              id: 1,
                              tabTitle: (
                                <div className="p-0 light-font-text">
                                  Private
                                </div>
                              ),
                              title: "Private",
                            },
                            {
                              id: 2,
                              tabTitle: (
                                <div className="p-0 light-font-text">
                                  Public
                                </div>
                              ),
                              title: "Public",
                            },
                            {
                              id: 3,
                              tabTitle: (
                                <div className="p-0 light-font-text">
                                  Shared
                                </div>
                              ),
                              title: "Shared",
                            },
                          ]}
                          currentTab={value}
                          handleTabClick={(tab) => {
                            setValue("visibility", tab);
                          }}
                        />
                      );
                    }}
                  />
                </Col>
                <Col></Col>
              </Row>
              {/* Use as */}

              <Row className="pb-4">
                <Col xs={12} lg={3}>
                  <div className="form-label-title d-flex gap-3 ">
                    Use as{" "}
                    <span>
                      <TooltipComponent text="Use as" height="11px" />
                    </span>
                  </div>
                </Col>
                <Col xs={12} lg={3}>
                  <Controller
                    control={control}
                    {...register("useAs")}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <GroupButtons
                          tabs={[
                            {
                              id: 1,
                              tabTitle: (
                                <div className="nowrap light-font-text">
                                  Map Milestone
                                </div>
                              ),
                              title: "Map Milestone",
                            },
                            {
                              id: 2,
                              tabTitle: (
                                <div className="nowrap light-font-text">
                                  Chart Milestone
                                </div>
                              ),
                              title: "Chart Milestone",
                            },
                          ]}
                          currentTab={value}
                          handleTabClick={(tab) => {
                            setValue("useAs", tab);
                          }}
                        />
                      );
                    }}
                  />
                </Col>
                <Col></Col>
              </Row>
              {/* Apply your goals */}

              <Row className="pb-4">
                <Col xs={12} lg={3}>
                  <div className="form-label-title d-flex gap-3 ">
                    Apply your goals{" "}
                    <span>
                      <TooltipComponent text="Apply your goals" height="11px" />
                    </span>
                  </div>
                </Col>
                {/* {goalsData.length != 0 && ( */}
                <Col xs={12} lg={3}>
                  <GroupText
                    lable="Number of units"
                    type="dsd"
                    name="www"
                    textWidth="50%"
                    graylabel={
                      <div className="normal-font-text fs-14">Oct, 2023</div>
                    }
                  />
                </Col>
                {/* )} */}
                <Col>{/* <AlertPopup /> */}</Col>
              </Row>
            </div>
          </div>
          <div
            className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
            style={{
              backgroundColor: "#F9FAFA",
              borderRadius: " 0px 0px 8px 8px",
              border: "1px solid #F0F0F0",
            }}
          >
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => setManualMileStone(false)}
            />
            <div className="d-flex gap-3">
              <ETButton
                variant={"white"}
                buttonText="RESET SETTING"
                // onClick={HandleAlertMilestone}
              />
              <ETButton
                variant={"primary"}
                buttonText="SAVE"
                type="submit"
                //   onClick={() => setChangeAgent(false)}
              />
            </div>
          </div>
        </Form>
      </div>
      <CustomPopup
        open={alertMileStonePopup}
        onHide={() => setAlertMileStonePopup(false)}
        headerRequired={false}
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <AlertPopup />
      </CustomPopup>
    </div>
  );
};

export default ManualMileStone;
