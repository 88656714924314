import React, { useEffect, useMemo, useState } from "react";
import AddResponsibleAgent from "../standardComponents/AddResponsibleAgent";
import Menus from "../../../Components/Menus/Menus";
import { MaterialReactTable } from "material-react-table";
import MRTTheme from "../../../Components/table/MRTTheme";
import { H2 } from "../../../styles/Common.style";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../../Components/Buttons/ETButton";
import DateRangeFilter from "../../../Components/DateRangePicker/DateRangeFilter";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import {
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../Components/table/MRTGlobalProps";
import { useMediaQuery } from "react-responsive";
import TagToolTip from "../../../Components/Tooltip/PropertyTagToolTip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Heart from "../../../Components/Star/Heart";
import CreateNewAppointments from "../opportunities/CreateNewAppointments/CreateNewAppointments";
import FollowUp from "../standardComponents/follow up/FollowUp";
import CreateNewContractWizard from "../opportunities/createNewContract/CreateNewContractWizard";
import RestartScreening from "../screenings/popups/RestartScreening";
import MoveToAnotherUnit from "../opportunities/MoveToAnotherUnit";
import { ActionsPopup } from "../screenings/popups/ActionsPopup";
import { useCustomerFunnelTopLoadingBar } from "../../../hooks/stores/useCustomerFunnelStore";

const FollowUpLater = () => {
  //----------Store to handle top loading bar----------//
  const { topLoadingProgressBar, setTopLoadingProgressBar } =
    useCustomerFunnelTopLoadingBar((state) => state);
  //----------Store to handle top loading bar----------//

  // below code is for give responsive design to fixed columns
  const isDesktop = useMediaQuery({ minWidth: 1224 });
  const [columnPinning, setColumnPinning] = useState({});
  const [rowSelection, setRowSelection] = useState({}); // Initialize as an empty object
  const [openNewAppointmentScreen, setOpenNewAppointmentScreen] =
    useState(false);
  const [showFollowUp, setShowFollowUp] = useState(false);
  const [creatingNewContract, setCreatingNewContract] = useState(false);
  const [showStartScreening, setShowStartScreening] = useState(false);
  const [showMoveToAnotherUnits, setShowMoveToAnotherUnits] = useState(false);
  const [showPopUp, setShowPopUp] = useState({ create: false, edit: false });
  const [agentDetails, setAgentDetails] = useState("");
  const [archivePopUp, setArchivePopUp] = useState(false);
  const [multiarchivePopUp, setMultiArchivePopUp] = useState(false);
  const [ArchiveScreen, setArchiveScreen] = useState(false);
  const [showFollowsUpWizard, setShowFollowsUpWizard] = useState(false);
  const [selectedFollowUpIndex, setSelectedFollowUpIndex] = useState(0);

  useEffect(() => {
    if (isDesktop) {
      setColumnPinning({
        left: ["mrt-row-select", "Name"],
        right: ["mrt-row-actions"],
      });
    } else {
      setColumnPinning({});
    }
  }, [isDesktop]);

  //handle go to contract card
  const handleCustomerDetails = (pageIndex = 0) => {
    // console.log(pageIndex, "i asssssssssssss");
    setShowFollowsUpWizard(true);
    setSelectedFollowUpIndex(pageIndex);
  };

  // handle archive
  const archiveScreening = (item) => {
    setArchiveScreen(true);
  };

  // columns used in tables
  const columns = useMemo(
    () => [
      // {
      //   accessorKey: "Id",
      //   header: "",
      //   enableHiding: false,
      //   size: 70,
      //   enableSorting: false,
      //   enableGlobalFilter: true,
      //   Cell: ({ row }) => (
      //     <div className="d-flex align-items-center gap-2 ">
      //       <div>
      //         <FontAwesomeIcon
      //           icon={"fa-solid fa-heart"}
      //           size="lg"
      //           className="cursor-pointer"
      //           style={{
      //             stroke: "#AA1518",
      //             strokeWidth: "30px",
      //             color: "#AA151833",
      //           }}
      //         />
      //       </div>
      //       <div>
      //         <TagToolTip
      //           iconColor={"#888888"}
      //           size={"lg"}
      //           border="1px solid #F0F0F0"
      //           padding={"0px"}
      //           boxShadow="2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
      //           text={""}
      //         />
      //       </div>
      //     </div>
      //   ),
      //   muiTableBodyCellProps: {
      //     sx: {
      //       paddingRight: "4px !important",
      //       backgroundColor: "#F0F0F0 !important",
      //       border: "none !important",
      //     },
      //   },
      // },
      {
        accessorFn: (row) => `${row.name}`,
        header: "Name",
        enableHiding: false,
        size: 180,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 ">
            <div>
              <Heart isFavorite="0" />
            </div>
            <div>
              <TagToolTip
                iconColor={"#888888"}
                size={"lg"}
                border="1px solid #F0F0F0"
                padding={"0px"}
                boxShadow="2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                text={""}
              />
            </div>
            <div>{row?.original?.name}</div>
          </div>
        ),
        muiTableHeadCellProps: {
          sx: {
            // paddingLeft: "3px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            // paddingLeft: "3px !important",
            backgroundColor: "#F0F0F0 !important",
            border: "none !important",
            borderRight: "1px solid #888888 !important",
          },
        },
      },
      {
        accessorKey: "property",
        header: "Property",
        enableHiding: false,
        size: 140,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "unit",
        header: "Unit of interest",
        enableHiding: false,
        size: 240,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "contract_deadline",
        header: "Time added",
        enableHiding: false,
        size: 180,
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => `${row.status}`,
        header: "Status",
        enableHiding: false,
        size: 220,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>
            {" "}
            <div className="d-flex align-items-center gap-2 ">
              <div>
                <TagToolTip
                  iconColor={"#888888"}
                  size={"lg"}
                  border="1px solid #F0F0F0"
                  padding={"0px"}
                  boxShadow="2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                  text={""}
                />
              </div>
              <div>{row.original.status}</div>
            </div>{" "}
          </div>
        ),
      },
      {
        accessorKey: "responsible_agent",
        header: "Responsible agent",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: false,
      },
    ],
    []
  );

  const handleArchive = () => {
    if (Object?.keys(rowSelection).length == 1) {
      setArchivePopUp(true);
    } else if (Object?.keys(rowSelection).length > 1) {
      setMultiArchivePopUp(true);
    }
  };

  return (
    <div className="p-lg-4 p-3 mb-4 containerStyle ">
      {" "}
      <div className="whiteContainerStyle">
        {/* Header section */}
        <div className="p-4 border-bottom">
          <div className=" d-flex justify-content-between align-items-center">
            <div className=" d-flex align-items-center gap-2">
              <H2>Follow up later</H2>
              <CustomToolTip size="sm" text="All" />{" "}
            </div>
            <div>
              <ETButton
                icon={true}
                variant={"primary"}
                buttonText="CREATE NEW APPOINTMENT"
                width={"100%"}
              />
            </div>
          </div>
        </div>

        {/* body section */}
        <div className="p-4  ">
          <Row className="d-flex justify-content-between">
            <Col xl={10} lg={10}>
              <div className="light-font-text ">
                Below is a list of the viewing inquiry that have been made on
                this unit. It is possible to send an invitation to one or more
                customers with a proposed appointment.
              </div>
            </Col>
            <Col xl={2} lg={5}>
              <ETButton
                variant={"primary"}
                buttonText="ARCHIVE"
                width={"100%"}
                disabled={Object?.keys(rowSelection).length == 0}
                onClick={() => handleArchive()}
              />
            </Col>
          </Row>
        </div>

        <div className="px-4 pt-2 pb-5">
          <div>
            <MRTTheme
              colorVariantsForTableRow={["gray"]}
              colorVariantsForTableHead={["white"]}
              table={
                <MaterialReactTable
                  columns={columns}
                  data={data ?? []} //data is undefined on first render
                  // data={[]}
                  icons={{ ...SortIcon }}
                  // localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                  enableColumnActions={false}
                  enableTopToolbar={true}
                  enableRowSelection={true}
                  enableRowActions
                  enableSelectAll
                  enablePinning
                  onRowSelectionChange={setRowSelection}
                  onColumnPinningChange={setColumnPinning}
                  state={{ columnPinning, rowSelection }}
                  enableFilterMatchHighlighting={true}
                  enableGlobalFilter
                  globalFilterFn="contains"
                  initialState={{
                    showGlobalFilter: true,
                    showColumnFilters: false,
                  }}
                  muiTableContainerProps={{
                    sx: {
                      padding: "0px !important",
                    },
                  }}
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      header: "",
                      size: 50,
                      muiTableBodyCellProps: {
                        sx: {
                          // padding: "0px !important",
                          backgroundColor: "#F0F0F0 !important",
                          border: "none !important",
                          borderLeft: "1px solid #888888 !important",
                        },
                      },
                    },
                    "mrt-row-select": {
                      header: "",
                      size: 50,
                      muiTableBodyCellProps: {
                        sx: {
                          // padding: "0px !important",
                          backgroundColor: "#f0f0f0 !important",
                          border: "none !important",
                          boxShadow: "none !important",
                        },
                      },
                    },
                  }}
                  positionActionsColumn="last"
                  renderRowActions={({ row }) => (
                    <Menus
                      itemheight={23}
                      itemwidth={23}
                      iconborderradius="4px"
                      itemcontainerwidth="200px"
                      itemcontainerheight={"300px"}
                      component={
                        <div className="light-font-text">
                          <div
                            className="p-2 cursor-pointer"
                            onClick={() => {
                              setOpenNewAppointmentScreen(true);
                              setTopLoadingProgressBar(25);
                            }}
                          >
                            Create appointment
                          </div>
                          <hr className="hr m-0"></hr>

                          <div
                            className="p-2 cursor-pointer"
                            onClick={() => setShowFollowUp(true)}
                          >
                            Add follow ups
                          </div>
                          <hr className="hr m-0"></hr>
                          <div
                            className="p-2 cursor-pointer"
                            onClick={() => handleCustomerDetails(0)}
                          >
                            Go to contract card
                          </div>
                          <hr className="hr m-0"></hr>
                          <div
                            className="p-2 cursor-pointer"
                            onClick={() => archiveScreening()}
                          >
                            Archive
                          </div>
                          <hr className="hr m-0"></hr>
                          <div
                            className="p-2 cursor-pointer"
                            onClick={() => setCreatingNewContract(true)}
                          >
                            Create contract
                          </div>
                          <hr className="hr m-0"></hr>

                          <div
                            className="p-2 cursor-pointer"
                            onClick={() => setShowStartScreening(true)}
                          >
                            Start screening
                          </div>

                          <hr className="hr m-0"></hr>
                          <div
                            className="p-2 cursor-pointer"
                            onClick={() => setShowMoveToAnotherUnits(true)}
                          >
                            Move to another units
                          </div>
                          <hr className="hr m-0"></hr>
                          <div
                            className="p-2 cursor-pointer"
                            onClick={() =>
                              setShowPopUp({ ...showPopUp, edit: true })
                            }
                          >
                            Change responsible agent
                          </div>
                        </div>
                      }
                    />
                  )}
                  renderTopToolbar={({ table }) => (
                    <>
                      <div className="px-0 ">
                        <Row>
                          <Col xl={4} lg={6} className="pb-2 pe-lg-0">
                            <DateRangeFilter
                            // date={dateRange}
                            // setDate={setDateRange}
                            // handleDateChange={handleDateChange}
                            />
                          </Col>
                          <Col xl={3} lg={5}>
                            <ReactSelect placeholder={"Responsible agent"} />
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                  muiTablePaperProps={{
                    selected: false,
                    elevation: 0,
                    fontFamily: "Cerebri sans-light !important",
                    sx: {
                      borderRadius: "0",
                      border: "none",
                      // backgroundColor: "#F9FAFA",
                      table: {
                        borderCollapse: "separate",
                        borderSpacing: "0 5px",
                      },
                    },
                  }}
                  muiTablePaginationProps={{
                    ...paginationoptions,
                  }}
                  muiTableHeadRowProps={{
                    sx: {
                      ...TableRowsStyle,
                    },
                  }}
                />
              }
            />
          </div>

          {/* ------------> CREATE NEW APPOINTMENT <-------------- */}
          <CreateNewAppointments
            openNewAppointmentScreen={openNewAppointmentScreen}
            setOpenNewAppointmentScreen={setOpenNewAppointmentScreen}
            progress={topLoadingProgressBar}
            setProgress={setTopLoadingProgressBar}
          />
          {/* ------------> FOLLOW UP <-------------- */}
          <FollowUp followUp={showFollowUp} setFollowUp={setShowFollowUp} />
          {/* ------------> CREATE CONTRACT <-------------- */}
          <CreateNewContractWizard
            creatingNewContract={creatingNewContract}
            setCreatingNewContract={setCreatingNewContract}
          />
          {/* ------------> START SCREENING <-------------- */}
          <RestartScreening
            open={showStartScreening}
            onHide={() => setShowStartScreening(false)}
            onCancel={() => setShowStartScreening(false)}
            usedFor="SSOC"
          />
          {/* ------------> MOVE TO ANOTHER UNIT <-------------- */}
          <MoveToAnotherUnit
            showMoveToAnotherUnits={showMoveToAnotherUnits}
            setShowMoveToAnotherUnits={setShowMoveToAnotherUnits}
          />
          {/* ------------> CHANGE RESPONSIBLE AGENT <-------------- */}
          <AddResponsibleAgent
            showResponsibleAgentPopup={showPopUp.edit}
            setShowResponsibleAgentPopup={setShowPopUp}
            usedFor="EDIT"
            data={agentDetails}
          />
          {/* ------------> ARCHIVE FOR SINGLE AND MULTIPLE CUSTOMER <-------------- */}
          <ActionsPopup
            open={ArchiveScreen}
            onHide={() => setArchiveScreen(false)}
            whiteClick={() => setArchiveScreen(false)}
            title={"Archive Customer"}
            primaryButton={"Archive"}
            titleTooltipText={"Archive Customer"}
            firstText={"Do you want to archive this customer?"}
            secondText={
              "The customer will be archived and moved to Archive tab."
            }
          />
          <ActionsPopup
            open={archivePopUp}
            onHide={() => setArchivePopUp(false)}
            whiteClick={() => setArchivePopUp(false)}
            title={"Archive Customer"}
            ThridTextShow={false}
            primaryButton={"Archive"}
            titleTooltipText={"Archive Customer"}
            firstText={"Are you sure the customer should be archived?"}
            secondText={
              "If the customer has no relationships to other properties or units in the system, the customer is automatically deleted when the compliance deletion rule is reached"
            }
          />
          <ActionsPopup
            open={multiarchivePopUp}
            onHide={() => {
              setMultiArchivePopUp(false);
            }}
            whiteClick={() => setMultiArchivePopUp(false)}
            title={"Archive Customers"}
            titleTooltipText={"Archive Customers"}
            primaryButton={"Archive"}
            firstText={"Are you sure all the customers should be archived?"}
            secondText={
              "If the customers has no relationships to other properties or units in the system, the customers is automatically deleted when the compliance deletion rule is reached"
            }
          />
          {/* ------------> GO TO CONTRACT CARD <-------------- */}
          <FollowUp
            setFollowUp={setShowFollowsUpWizard}
            followUp={showFollowsUpWizard}
            selectedIndex={selectedFollowUpIndex}
            setSelectedIndex={setSelectedFollowUpIndex}
          />
        </div>
      </div>
    </div>
  );
};

export default FollowUpLater;

const data = [
  {
    id: 1,
    status: "In Dialog",
    unit: "Dybbølsbro 3, 1 | 212-004",
    property: "Kaktus Towers",
    active_screening: "1",
    name: "Cai Coffey",
    contract_deadline: "12 Jul, 2023 at 12:00",
    date_of_approval: "11 Jul, 2023 at 16:12",
    responsible_agent: "Thomas Høeg Mogensen",
  },
  {
    id: 4,
    status: "Waiting on apartment sale",
    unit: "Blomsterbjerget 14, 1.tv | 742-017",
    property: "Munkesøhuse",
    active_screening: "1",
    name: "Jacqueline George",
    contract_deadline: "28 May, 2023 at 12:00",
    date_of_approval: "10 Jul, 2023 at 10:05",
    responsible_agent: "Charlotte Hamilton",
  },
  {
    id: 5,
    status: "Needs confirmation",
    unit: "Blomsterbjerget 8, 3.tv | 742-024",
    property: "Kolding Sky",
    name: "Solomon Welch",
    contract_deadline: "13 Jul, 2023 at 12:00",
    date_of_approval: "9 Jul, 2023 at 21:45",
    responsible_agent: "Max Wildersans Brentford",
  },
];
