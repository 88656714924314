import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import React, { useMemo, useState, useEffect } from "react";
import { GetFacilityByPropertyId } from "../../services/Facility";
import { useQuery } from "react-query";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import {
  useGetFacilityByPropertyId,
  useGetFacilityCategory,
  useGetFacilityGroup,
} from "../../hooks/queries/usefacilityQueries";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkedbox, UnCheckedbox } from "../../assets/icons/icons";
import { InputAdornment } from "@mui/material";
import { useFacility } from "../../hooks/stores/useFacilityStore";
import MRTTheme from "../../Components/table/MRTTheme";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../Components/table/MRTGlobalProps";

function AddFacilityPopupTable({
  GetFacilityByPropertyIdRefetch,
  propertyId,
  GetFacilityByPropertyIdData,
  rowSelection,
  setRowSelection,
  setFacilityTableData,
  type,
  checkedFacilitiesPackage,
  setCheckedFacilitiesPackage,
  flag,
}) {
  const { outerFacilityTable } = useFacility((state) => state);
  console.log(
    "AddFacilityPopupTable was rendered at",
    new Date().toLocaleTimeString()
  );

  // const {
  //   data: GetFacilityByPropertyIdData,
  //   refetch: GetFacilityByPropertyIdRefetch,
  // } = useGetFacilityByPropertyId(propertyId);

  const { data: GetFacilityCategoryData } = useGetFacilityCategory();
  const { data: GetFacilityGroupData } = useGetFacilityGroup();
  const activityOptions = [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 2 },
  ];
  let filteredData;
  const [tableData, setTableData] = useState([]);
  const [popupData, setPopupData] = useState([]);
  // useEffect(() => {
  // 	// debugger;
  // 	setFacilityTableData(outerFacilityTable);
  // }, []);
  useEffect(() => {
    // GetFacilityByPropertyIdRefetch();
    if (flag === "propertyLevel") {
      console.log("flag", flag);
      let outerFacilities = outerFacilityTable?.map((fTD) => {
        return fTD?.facility_id;
      });
      let popupTableData = GetFacilityByPropertyIdData?.data?.filter(
        (ele) => !outerFacilities?.includes(ele?.facility_id)
      );
      setTableData(popupTableData);
      setPopupData(popupTableData);
    } else if (flag === "packageLevel") {
      console.log("flag", flag);
      // let popupTableData = GetFacilityByPropertyIdData?.data?.filter(
      // 	(ele) => !checkedFacilitiesPackage?.includes(ele?.facility_id)
      // );
      // setTableData(popupTableData);
      // setPopupData(popupTableData);
      console.log("flag", flag);
      let outerFacilities = outerFacilityTable?.map((fTD) => {
        return fTD?.facility_id;
      });
      let popupTableData = GetFacilityByPropertyIdData?.data?.filter(
        (ele) => !outerFacilities?.includes(ele?.facility_id)
      );
      setTableData(popupTableData);
      setPopupData(popupTableData);
    }
  }, []);

  // useEffect(() => {
  // 	let popupTableData = GetFacilityByPropertyIdData?.data?.filter(
  // 		(ele) => !checkedFacilitiesPackage.includes(ele.facility_id)
  // 	);
  // 	setTableData(popupTableData);
  // }, [checkedFacilitiesPackage]);
  const handleGroupFilter = (e, ActionTypes) => {
    if (ActionTypes.action === "clear") {
      setTableData(popupData);
    } else {
      filteredData = tableData?.filter(
        (facility) => facility.group_id === e?.value
      );
      setTableData(filteredData);
    }
  };
  const handleCategoryFilter = (e, ActionTypes) => {
    if (ActionTypes.action === "clear") {
      setTableData(popupData);
    } else {
      filteredData = tableData?.filter(
        (facility) => facility.category_id === e?.value
      );
      setTableData(filteredData);
    }
  };
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row?.icon_name} ${row?.facility_name}`,
        header: "Facility Name",
        size: 100,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {row.original.icon_name !== "" ? (
              <FontAwesomeIcon
                icon={row?.original.icon_name}
                style={{ fontSize: "1rem" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={"fa-solid fa-circle"}
                style={{ fontSize: "1rem" }}
              />
            )}

            <div>{row?.original.facility_name}</div>
          </div>
        ),
      },
      {
        accessorKey: "category",
        header: "Category",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "group",
        header: "Group",
        enableHiding: false,
        size: 80,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "Setting",
        header: "Setting",
        size: 30,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <span>{row.original.setting == 1 ? "Yes" : "No"}</span>
        ),
      },
    ],
    []
  );
  // const renderEmptyRowsFallback = () => (
  // 	<div className="d-flex justify-content-center w-100 py-4 grey-text">
  // 		<div>You do not have any facility to select.</div>
  // 	</div>
  // );
  const renderEmptyRowsFallback = () => {
    if (tableData && tableData?.length === 0) {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div>You do not have any facility to select.</div>
          </>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div style={{ fontSize: "17px" }}>Sorry! No result found:(</div>
          </>
        </div>
      );
    }
  };

  return (
    <div>
      <MRTTheme
        colorVariantsForTableData={["odd", "white"]}
        table={
          <MaterialReactTable
            columns={columns}
            data={tableData ?? []}
            icons={{ ...SortIcon }}
            renderEmptyRowsFallback={renderEmptyRowsFallback}
            positionActionsColumn="last"
            getRowId={(originalRow) => originalRow?.facility_id}
            enableRowSelection
            onRowSelectionChange={setRowSelection}
            state={{ rowSelection }}
            enableSelectAll={false}
            enableColumnActions={false}
            enableColumnFilter={false}
            enableResizing={false}
            // enableBottomToolbar={false}
            globalFilterFn="contains"
            initialState={{ showGlobalFilter: true }}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "Active on property",
              },
              "mrt-row-select": {
                header: "",
                muiTableHeadCellProps: {
                  align: "center",
                },
                muiTableBodyCellProps: {
                  align: "center",
                },
                sx: {
                  padding: "16px 12px",
                },
              },
            }}
            muiTableContainerProps={{
              sx: {
                padding: "0px !important",
              },
            }}
            muiTablePaginationProps={{
              ...paginationoptions,
            }}
            muiTableHeadRowProps={{
              sx: {
                ...TableRowsStyle,
              },
            }}
            muiSearchTextFieldProps={{
              ...SearchStyle,
              InputProps: {
                ...SearchIcon,
              },
            }}
            enableColumnFilterModes={false}
            renderTopToolbar={({ table }) => (
              <Row>
                <Col sm={12} lg={9}>
                  <Row className="pt-2 pb-3 px-4">
                    <Col lg={4} className="  mb-sm-3 mb-lg-0 ps-lg-0">
                      <div className="">
                        <MRT_GlobalFilterTextField table={table} />
                      </div>
                    </Col>
                    <Col className=" mb-sm-3 mb-lg-0 ps-lg-0" lg={4}>
                      <ReactSelect
                        placeholder={"Category"}
                        options={GetFacilityCategoryData?.data?.map((item) => ({
                          value: item?.Id,
                          label: `${item?.Text}`,
                        }))}
                        onChange={(e, ActionTypes) =>
                          handleCategoryFilter(e, ActionTypes)
                        }
                      />
                    </Col>
                    <Col className=" mb-sm-3 mb-lg-0 ps-lg-0" lg={4}>
                      <ReactSelect
                        placeholder={"Group"}
                        options={GetFacilityGroupData?.data?.map((item) => ({
                          value: item?.Id,
                          label: `${item?.Text}`,
                        }))}
                        onChange={(e, ActionTypes) =>
                          handleGroupFilter(e, ActionTypes)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            positionGlobalFilter="left"
            // muiSearchTextFieldProps={{
            // 	placeholder: `Search`,
            // 	variant: "outlined",

            // }}
            // muiSelectCheckboxProps={{
            //   icon: <UnCheckedbox />,
            //   checkedIcon: <Checkedbox />,

            //   color: "success",
            // }}
          />
        }
      />
    </div>
  );
}
export const MemoizedAddFacilityPopupTable = React.memo(AddFacilityPopupTable);

// export default AddFacilityPopupTable;
