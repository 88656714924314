import { Delete, Get, Post, Put } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";


// Post eido color 
export const PostEidoColor = (data) => {
    return new Promise((resolve, reject) => {
      Post(Site.ET, Endpoint.ET.Unit_Eido_PostColor, null, data)
        .then((response) => {
          resolve(response.data);
          console.log(response.data)
        })
        .catch((error) => {
          reject({ error: true, message: error.message });
        });
    });
};

// Update eido color 
export const UpdateEidoColor = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Unit_Eido_PostColor, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Delete eido color 
export const DeleteEidoColor = (data) => {
  return new Promise((resolve, reject) => {
    
    Delete(Site.ET, Endpoint.ET.Unit_Eido_PostColor, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Eido color
export const GetEidoColor = (id) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Unit_Eido_GetColor, null, id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.response.data.message });
      });
  });
};


// Get Ei.DO text packages using property ID
export const GetEiDoTextPackage = (propId, categoryId) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.EIDO.Unit_Eido_GetTextPackage, null, propId + '/units/text/category/'+ categoryId + '/packages')
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Get Ei.DO media packages using property ID
export const GetEiDoMediaPackage = (propId) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.EIDO.Unit_Eido_GetMediaPackage, null, propId + '/units/media/packages')
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};