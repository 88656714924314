import { useEffect, useState } from "react";
import CustomPopup from "../../../Components/CustomPopup";
import { Col, Form, Row } from "react-bootstrap";
import InputField from "../../../Components/Textfield/InputField";
import Toggle from "../../../Components/Switch/Switch";
import { Controller, useForm } from "react-hook-form";
import { useGetOrganisationMaintenanceDetails, usePostOrganisationMaintenanceDetails, usePutOrganisationMaintenanceDetails } from "../../../hooks/queries/useOrganization";
import { useOrganizationId } from "../../../hooks/stores/useOrganizationStore";
import moment from "moment";
import TextAreaField from "../../../Components/Textfield/TextAreaField";
import ETButton from "../../../Components/Buttons/ETButton";
import { H4, H5 } from "../../../styles/Common.style";
import { toast } from "react-toastify";
import { ErrorAlert, SucessAlert } from "../../../Components/alerts/AlertsAndMessages";
import { useUserDetails } from "../../../hooks/stores/useUserDetailsStore";
import { UTCtoLocalTimeZone } from "../../../utils/UTCtoLocalTimeZone";
import TimePicker from "../../../Components/timePicker/TimePicker"

const MaintenanceModePopup = ({
  open,
  onHide,
  onCancel,
  rowOrginazationId,
  usedFor,
  disableButtonOnApiLoad = false,
  historyTableRefetch,
  editMode,
  setEditMode,
  currentMaintenanceModeData,
  setCurrentMaintenanceModeData
}) => {
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    resetField,
    getValues,
    setValue,
    setError,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      start_date: "",
      start_time: "",
      end_date: "",
      end_time: "",
      maintenance_mode_toggle: "",
      message: "",
    },
  });

  // States
  const [staticMaintainanceStartTime, setStaticMaintainanceStartTime] = useState("")

  // Zustand
  const { organizationId } = useOrganizationId((state) => state);
  const { userDetails } = useUserDetails((state) => state);

  // React-query
  const {
    mutateAsync: PostOrganisationMaintenanceDetails,
  } = usePostOrganisationMaintenanceDetails();
  const {
    mutateAsync: UpdateOrganisationDeactivateDetails,
  } = usePutOrganisationMaintenanceDetails();

  // UseEffects
  useEffect(()=> {
    if(Object.keys(currentMaintenanceModeData ?? {}).length) {
      const maintainanceStartTime = currentMaintenanceModeData?.start_on || "";
      const maintainanceEndTime = currentMaintenanceModeData?.end_on || "";
      const { convertedDate: maintenance_start } = UTCtoLocalTimeZone({
        timestamp: maintainanceStartTime === "" ? moment.utc().format('DD MMM, YYYY HH:mm:ss') : maintainanceStartTime,
        timeFormat: "DD MMM, YYYY HH:mm:ss",
        offset_mins: userDetails?.timezone_offset_mins,
        isUtcTimeAvailable: false
      });
      const { convertedDate: present_start } = UTCtoLocalTimeZone({
        timestamp: moment.utc().format('DD MMM, YYYY HH:mm:ss'),
        timeFormat: "DD MMM, YYYY HH:mm:ss",
        offset_mins: userDetails?.timezone_offset_mins,
        isUtcTimeAvailable: false
      });

      setStaticMaintainanceStartTime(present_start);
      const { convertedDate: maintenance_end } = UTCtoLocalTimeZone({
        timestamp: maintainanceEndTime,
        timeFormat: "DD MMM, YYYY HH:mm:ss",
        offset_mins: userDetails?.timezone_offset_mins,
        isUtcTimeAvailable: false
      }); 
      setValue(
        "start_date", moment(maintenance_start).format("YYYY-MM-DD")
      );
      setValue(
        "start_time", moment(maintenance_start)
      );
      setValue(
        "end_date",
        currentMaintenanceModeData?.end_on
          ? moment(maintenance_end).format("YYYY-MM-DD")
          : ""
      );
      setValue(
        "end_time",
        currentMaintenanceModeData?.end_on
          ? moment(maintenance_end)
          : ""
      );
      setValue("maintenance_mode_toggle", (currentMaintenanceModeData?.start_on && currentMaintenanceModeData?.end_on) ? true : false );
      setValue(
        "message",
        currentMaintenanceModeData?.message
      );
    }else {
      const { convertedDate: maintenance_start } = UTCtoLocalTimeZone({
        timestamp: moment.utc().format('DD MMM, YYYY HH:mm:ss'),
        timeFormat: "DD MMM, YYYY HH:mm:ss",
        offset_mins: userDetails?.timezone_offset_mins,
        isUtcTimeAvailable: false
      });
      setValue(
        "start_date", moment(maintenance_start).format("YYYY-MM-DD")
        );
      setValue(
        "start_time", moment(maintenance_start)
      );
      setStaticMaintainanceStartTime(maintenance_start);
      setValue("maintenance_mode_toggle", false);
      setValue("message", "");
    }
  }, [currentMaintenanceModeData, open])

  // Functions
  const handelFormSubmit = async (data) => {
    const { convertedDate: getStartDate } = UTCtoLocalTimeZone({
      timestamp: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
      offset_mins: userDetails?.timezone_offset_mins,
      timeFormat: "DD MMM, YYYY HH:mm:ss",
    }); // Get current Time zone
    
    const startDate = data?.maintenance_mode_toggle === true ? `${data?.start_date} ${moment(data?.start_time).format("HH:mm:ss")}` : `${moment(getStartDate).format('YYYY-MM-DD')} ${moment(getStartDate).format('HH:mm:ss')}`;
    const endDate = data?.maintenance_mode_toggle === true ? `${data?.end_date} ${moment(data?.end_time).format("HH:mm:ss")}` : "";
    const planning_type = data?.maintenance_mode_toggle === false ? "I" : "S";

    if(!editMode) {
      const AddOrganisationMaintenanceDetails = {
        organisation_id: organizationId,
        planning_type: planning_type,  
        start_on: startDate,
        end_on: endDate,
        message: data?.message,
      };

      try {
        const response = await PostOrganisationMaintenanceDetails(
          AddOrganisationMaintenanceDetails
        );
        if (response.status === "success" && response?.data?.bReturn) {
          onHide();
          reset();
          historyTableRefetch();
          toast.success(
            <SucessAlert />
          );
        } else {
          toast.error(
            <ErrorAlert />
          );
        }
      } catch (error) {
        <ErrorAlert />;
      }
    }else {
      const updateOrganisationDeactivateDetails = {
        "maintenance_id": currentMaintenanceModeData?.maintenance_id,
        "organisation_id": organizationId?.toString(),
        "end_on": endDate,
        "message": data?.message
      };

      try {
        const response = await UpdateOrganisationDeactivateDetails(
          updateOrganisationDeactivateDetails
        );
        if (response.status === "success" && response?.data?.bReturn) {
          onHide();
          reset();
          setCurrentMaintenanceModeData({})
          historyTableRefetch();
          setEditMode(false)
          toast.success(
            <SucessAlert />
          );
        } else {
          toast.error(
            <ErrorAlert />
          );
        }
      } catch (error) {
        <ErrorAlert />;
      }
    }
    
  };

  return (
    <div>
      <CustomPopup
        open={open}
        title={"Maintenance mode"}
        title_color="black"
        enableCrossButton={true}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        size={"xl"}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        titleTooltipText={"Maintenance mode"}
        secondTextShow={false}
        priorityShow={false}
        onClickCrossButton={()=> {
          reset()
          onHide()
        }}
      >
        <div>
          <Form onSubmit={handleSubmit(handelFormSubmit)}>
            <div className="p-4">
              <Row className="mb-4">
                <Col
                  className="d-flex flex-column gap-3"
                  lg={9}
                >
                  <H4>Planning type</H4>

                  <span className="light-font-text fs-16">
                    Select whether this is a scheduled maintenance period or if
                    maintenance mode should be activated immediately.
                  </span>

                  <div className="d-flex gap-3 align-items-center">
                    <span className="light-font-text fs-16">
                      Activate immediately
                    </span>

                    <Controller
                      control={control}
                      {...register("maintenance_mode_toggle")}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <Toggle
                          id="maintenance_mode_toggle"
                          value={value}
                          checked={value}
                          onChange={(e) => {
                            onChange(e);
                            setValue('message', '');
                            setValue('end_date', '');
                            setValue('end_time', '');
                            clearErrors("maintenance_mode_toggle");
                          }}
                        />
                      )}
                    />

                    <span className="light-font-text fs-16">
                      Scheduled mode
                    </span>
                  </div>
                </Col>
              </Row>

              {watch("maintenance_mode_toggle") && (
                <div>
                  <Row className="mb-4">
                    <Col
                      className="d-flex flex-column"
                      lg={6}
                    >
                      <div className="d-flex flex-column gap-3">
                        <H5>Maintenance starts</H5>
                        <div className="d-flex gap-3">
                          <div className="w-100">
                            <Controller
                              control={control}
                              {...register("start_date")}
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <InputField
                                  id={"start_date"}
                                  value={value}
                                  min={moment(staticMaintainanceStartTime).format("YYYY-MM-DD")}
                                  disabled={
                                    currentMaintenanceModeData?.is_maintenance_mode === 1
                                      ? true
                                      : false
                                  }
                                  type="date"
                                  placeholder="Date"
                                  iserror={errors?.start_date}
                                  onChange={(e) => {
                                    onChange(e);
                                    clearErrors("start_date");
                                    if(getValues("end_date") && moment(getValues('end_date')).isBefore(moment(getValues('start_date')))){
                                      resetField("end_date")
                                    }
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="w-100">
                            <Controller
                              control={control}
                              {...register("start_time")}
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <TimePicker
                                placeholder={"Time"}
                                id={"start_time"}
                                value={value}
                                errorscope
                                errormessage={
                                  errors?.start_time?.type === "required"
                                    ? errors?.start_time.message
                                    : false
                                }
                                disabled={
                                  currentMaintenanceModeData?.is_maintenance_mode === 1
                                    ? true
                                    : false
                                }
                                iserror={errors?.start_time}
                                onChange={(e) => {
                                  if(getValues('end_date') && getValues('end_time')) {
                                    if(moment(getValues('end_date')).isAfter(moment(getValues('start_date')))) {
                                      onChange(e);
                                      clearErrors("start_time");
                                    }else {
                                         // subtract 1 min to end time to check if start and end time have atleast 1 min gap
                                        if(moment(e).isAfter(moment(getValues('end_time')).subtract(1, 'minutes'))) {
                                          setValue("start_time", "");
                                          setError("start_time", { type: "required", message: "Please select valid time" })
                                        }else {
                                          onChange(e);
                                          clearErrors("start_time");
                                        }
                                    }
                                  }else {
                                    if(moment(getValues('start_date')).isAfter(moment(staticMaintainanceStartTime))) {
                                      onChange(e);
                                      clearErrors("start_time");
                                    }else {
                                      if(moment(e).isAfter(moment(staticMaintainanceStartTime))) {
                                        onChange(e);
                                        clearErrors("start_time");
                                      }else {
                                        setValue("start_time", "");
                                        setError("start_time", { type: "required", message: "Please select valid time" })
                                      }
                                    }
                                  }
                                }}
                              />
                                // <InputField
                                //   type="time"
                                //   placeholder="Time"
                                //   id={"start_time"}
                                //   errorscope
                                //   iserror={errors?.start_time}
                                //   disabled={
                                //     currentMaintenanceModeData?.is_maintenance_mode === 1
                                //       ? true
                                //       : false
                                //   }
                                //   value={value}
                                //   onChange={(e) => {
                                //     onChange(e);
                                //     clearErrors("start_time");
                                //   }}
                                // />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col
                      className="d-flex flex-column"
                      lg={6}
                    >
                      <div className="d-flex flex-column gap-3">
                        <H5>Maintenance ends</H5>
                        <div className="d-flex gap-3">
                          <div className="w-100">
                            <Controller
                              control={control}
                              {...register("end_date")}
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <InputField
                                  id={"end_date"}
                                  value={value}
                                  type="date"
                                  placeholder="Date"
                                  errorscope
                                  iserror={errors?.end_date}
                                  // min={moment(staticMaintainanceStartTime).format("YYYY-MM-DD")}
                                  min={watch("start_date")}
                                  onChange={(e) => {
                                    onChange(e);
                                    clearErrors("end_date");
                                    if(getValues('start_date') && getValues('start_time')) {
                                      if(moment(getValues('end_date')).isSame(moment(getValues('start_date')))) {
                                        // onChange(e);
                                        resetField("end_time")
                                      }
                                    }
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="w-100">
                            <Controller
                              control={control}
                              {...register("end_time")}
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <TimePicker
                                placeholder={"Time"}
                                id={"end_time"}
                                errorscope
                                iserror={errors?.end_time}
                                disabled={watch("toggle")}
                                value={value}
                                errormessage={
                                  errors?.end_time?.type === "required"
                                    ? errors?.end_time.message
                                    : false
                                }
                                onChange={(e) => {
                                  if(getValues('start_date') && getValues('start_time')) {
                                    if(moment(getValues('end_date')).isAfter(moment(getValues('start_date')))) {
                                      onChange(e);
                                      clearErrors("end_time");
                                    }else {
                                      if(!getValues('end_date')) {
                                        onChange(e);
                                        clearErrors("end_time");
                                      }else {
                                        if(moment(e).isBefore(moment(getValues('start_time')).add(1, 'minutes'))) {
                                          setValue("end_time", "");
                                          setError("end_time", { type: "required", message: "Please select valid time" })
                                        }else {
                                          if(moment(e).isSameOrBefore(moment(staticMaintainanceStartTime))) {
                                            setValue("end_time", "");
                                            setError("end_time", { type: "required", message: "Please select valid time" })
                                          }else {
                                            onChange(e);
                                            clearErrors("end_time");
                                          }
                                        }
                                      }
                                    }
                                  }
                                }}
                              />
                                // <InputField
                                //   placeholder="Time"
                                //   id={"end_time"}
                                //   type="time"
                                //   errorscope
                                //   iserror={errors?.end_time}
                                //   value={value}
                                //   onChange={(e) => {
                                //     onChange(e);
                                //     clearErrors("end_time");
                                //   }}
                                // />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              <Row>
                <Col
                  lg={7}
                  className="pe-lg-0"
                >
                  <H4 className="mb-3">
                    System message for all users when system access is block{" "}
                    <span className="light-font-text">(required)</span>
                  </H4>

                  <div className="form-float openhouse-des">
                    <Controller
                      control={control}
                      {...register("message")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <TextAreaField
                          placeholder=""
                          className="inputText"
                          id={"message"}
                          cols="20"
                          rows="4"
                          iserror={errors?.message}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("message");
                          }}
                        />
                      )}
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
              <ETButton
                buttonText="CANCEL"
                variant={"white"}
                onClick={() => {
                  reset();
                  onCancel();
                }}
              />

              <ETButton
                buttonText={"Activate maintenance mode"}
                variant={"primary"}
                type="submit"
              />
            </div>
          </Form>
        </div>
      </CustomPopup>
    </div>
  );
};

export default MaintenanceModePopup;

// Created by --------------> Yash
