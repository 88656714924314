import React from "react";
import estatelogosystem from "../../../assets/images/et-logo-new-user.png";
import { Col, Container, Image, Row } from "react-bootstrap";
import { H2 } from "../../../styles/Common.style";
import "@dotlottie/player-component";

import AnikiHamaster from "../../../assets/animations/AnikiHamaster.lottie";
import { UTCtoLocalTimeZone } from "../../../utils/UTCtoLocalTimeZone";
import { useLocation } from "react-router-dom";
import moment from "moment";

const NoDivision = () => {
  const { state } = useLocation();
  const format = "DD MMM, YYYY [at] HH:mm";
  const { convertedDate, timeZone } = UTCtoLocalTimeZone({
    timestamp: state?.end_date,
    timeFormat: format,
  }); // Get current Time zone
  return (
    <div
      className="Maindiv pt-4 d-flex flex-column container-fluid  justify-content-center align-items-center"
      style={{ backgroundColor: "#2B3C49", height: "100%" }}
    >
      <div className="pt-4">
        <div className="d-flex justify-content-center align-items-center pb-4">
          <Image
            width={"160px"}
            height={"auto"}
            src={estatelogosystem}
            alt="logo"
          />
        </div>
        <Container
          className="StandardForm row justify-content-md-center  mx-auto"
          style={{
            backgroundColor: "#FFFFFF",
            width: "1000px",
            height: "722px",
            borderRadius: "12px",
            boxShadow: " 0px 6px 18px 0px #0000000A",
          }}
        >
          <div className="pt-40 pb-40 d-flex justify-content-center align-items-center ">
            <Row>
              <Col>
                <H2> Sorry, your system access is currently closed...</H2>
              </Col>
            </Row>
          </div>
          <div
            className=" pb-40 d-flex justify-content-center align-items-center text-align-center"
            style={{ width: "50%" }}
          >
            <Row>
              <Col className="light-font-text">
                It is currently not possible to access your estatetool solution.
                Please contact
                <br />
                our support team as soon as possible.
              </Col>
            </Row>
          </div>
          <div style={{ height: "110px" }}>
             
              <div className=" pb-40 d-flex justify-content-center align-items-center">
                <Row>
                  <Col className="light-font-text">
                    We expect the system to be available again as soon as
                    possible.
                  </Col>
                </Row>
              </div>
          </div>

          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              position: "relative",
              height: "520px",
            }}
          >
            <div style={{ position: "absolute", bottom: "0" }}>
              <dotlottie-player
                src={AnikiHamaster}
                autoplay
                loop
                style={{ height: "500px", width: "100%" }}
              />
            </div>
          </div>
        </Container>
        <div
          className="light-font-text d-flex justify-content-center align-items-center pb-4 pt-40"
          style={{ color: "#FFF" }}
        >
          This solution is powered by{" "}
          <span
            className="form-label-title "
            style={{ color: "#FFF", margin: "0 5px" }}
          >
            estatetool
          </span>{" "}
          | © 2023 - All rights reserved
        </div>
      </div>
    </div>
  );
};

export default NoDivision;
