import { Get, Post, Put } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";
//Get Teams By Level
export const GetTeamsByLevel = (level_id, id, type_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Teams_GetTeamsByLevel,
      null,
      level_id + "/" + id + "/type/" + type_id
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Get Team By Team Id
export const GetTeamByTeamId = (team_id) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Teams_GetTeamByTeamId, null, team_id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Assign Properties To Teams

export const UpdateAssignPropertiesToTeams = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Teams_AssignPropertiesToTeams, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Update Team Status
export const UpdateTeamStatus = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Teams_UpdateTeamStatus, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Update Team
export const PostUpdateTeam = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Teams_UpdateTeam, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Add Team

export const PostAddTeam = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Teams_AddTeam, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Teams_AssignUserToTeams

export const PostAssignUserToTeams = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Teams_AssignUserToTeams, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
