import React, { useEffect, useMemo, useState } from "react";
import { Menu } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import UnitsFilterTable from "../../../../../../../../../Components/UnitFilterTable/UnitsFilterTable";
import CustomCheckBox from "../../../../../../../../../Components/Checkbox/Checkbox";
import ETButton from "../../../../../../../../../Components/Buttons/ETButton";

const AdditionalInfo = ({
  setUnitsTableData,
  unitsTableData,
  setSelectedUnitsId,
  dataContainer,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setDropdownOpen(false);
  };

  return (
    <div>
      <div className=" pt-2 pb-3 px-4  form-label-title">Preferred unit</div>
      <UnitsFilterTable
        setUnitsTableData={setUnitsTableData}
        unitsTableData={unitsTableData}
        setSelectedUnitsId={setSelectedUnitsId}
        dataContainer={dataContainer}
      />
      <hr></hr>

      <div className=" pt-2 pb-3 px-4  form-label-title">
        Heard about the property
      </div>
      <div className=" py-2 px-3" style={{ width: "75%" }}>
        <Row>
          <Col sm={3} className="pe-lg-0">
            <CustomCheckBox label={"Social Media"} />
          </Col>
          <Col sm={3} className="px-lg-0">
            <CustomCheckBox label={"Outdoor marketing"} />
          </Col>
          <Col sm={3} className="px-lg-0">
            <CustomCheckBox label={"Website"} />
          </Col>
          <Col sm={3} className="px-lg-0">
            <CustomCheckBox label={"Newspaper"} />
          </Col>
        </Row>
      </div>
      <div className="pb-3 px-3" style={{ width: "75%" }}>
        <Row>
          <Col sm={3} className="pe-lg-0">
            <CustomCheckBox label={"Instagram"} />
          </Col>
          <Col sm={3} className="px-lg-0">
            <CustomCheckBox label={"Facebook"} />
          </Col>
          <Col sm={3} className="px-lg-0">
            <CustomCheckBox label={"Google Ads"} />
          </Col>
          <Col sm={3} className="px-lg-0">
            <CustomCheckBox label={"Newsletter"} />
          </Col>
        </Row>
      </div>

      <div className="pt-3 pb-5 px-4 ms-1">
        <ETButton
          buttonText="ADD FIELDS"
          variant={"secondary"}
          icon={true}
          width="fit-content"
          onClick={handleClick}
        />
        <Menu
          id="simple-menu"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          anchorEl={anchorEl}
          open={anchorEl}
          onClose={handleClose}
          elevation={0}
          HorizontalAlignment="left"
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          PaperProps={{
            style: {
              borderRadius: "8px",
              marginTop: "6px",
              border: "0.5px solid #F0F0F0",
              boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.12)",
              paddingTop: "0px",
            },
          }}
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.02)",
            "& .css-6hp17o-MuiList-root-MuiMenu-list": {
              padding: 0,
              borderColor: "#F0F0F0",
            },
          }}
        >
          <div
            className="py-2  fs-14 radius-8"
            style={{
              width: "200px",
              cursor: "pointer",
            }}
          >
            <div className="px-3 ">Age</div>
            <hr></hr>
            <div className="px-3 ">Civil status</div>
            <hr></hr>
            <div className="px-3 ">Occupation</div>
            <hr></hr>
            <div className="px-3 ">Household size</div>
          </div>
        </Menu>
      </div>
    </div>
  );
};

export default AdditionalInfo;
