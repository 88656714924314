import { useEffect, useMemo, useState } from "react";
import { Col, Form, Placeholder, Row, Toast } from "react-bootstrap";
import ETButton from "../../../Components/Buttons/ETButton";
import { MaterialReactTable } from "material-react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomPopup from "../../../Components/CustomPopup";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { useDropzone } from "react-dropzone";
import Toggle from "../../../Components/Switch/Switch";
import Duplicate from "../../properties/units/duplicate/Duplicate";
import FilterComponent from "../../../reactdataTableComponent/FilterComponent";
import CustomCheckBox from "../../../Components/Checkbox/Checkbox";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useQuery } from "react-query";
import { REQUIRED_ERR } from "../../../utils/validationConstants";

import {
  DeleteDocument,
  GetDocumentByPropertyId,
  PutUpdateDocumentDetails,
} from "../../../services/Documents";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import {
  useGetDocumentType,
  useUploadDocument,
  usePutUpdateDocumentSortOrder,
  usePostCloneDocument,
} from "../../../hooks/queries/useDocumentsQueries";
import { useRouting } from "../../../hooks/stores/useRoutingStore";
import { status } from "../../../model/Constant";
import {
  DropZoneBorder,
  GreenSpan,
  PrimarySelectedTag,
} from "../../../styles/Common.style";
import MRTTheme from "../../../Components/table/MRTTheme";
import {
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../Components/table/MRTGlobalProps";
import moment from "moment";
import ShowTooltip from "../../../Components/Tooltip/ShowTooltip";
const FileSaver = require("file-saver");

export const DocumentProperty = ({
  propertyId,
  getDocumentPackagesByPropertyIdData,
  getDocumentPackagesByPropertyIdDataRefetch,
  setTabType,
}) => {
  const { mutateAsync: PutUpdateDocumentSortOrderMutate } =
    usePutUpdateDocumentSortOrder();
  // zustand
  const { setPackageSubType } = useRouting((state) => state);
  const [openUploadDocumentManually, setOpenUploadDocumentManually] =
    useState(false);
  const [droppedPdf, setDroppedPdf] = useState([]);
  const [addedDocument, setAddedDocument] = useState([
    {
      type: "-",
      name: "-",
      document_type: "-",
      delete_document: "-",
    },
  ]);
  const [documentPropertyMenu, setDocumentPropertyMenu] = useState({
    preview: false,
    copy: false,
    delete: false,
  });
  const [allChecked, setAllChecked] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const [documentbypropertyid, setDocumentByPropertyId] = useState([]);
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);

  //------------------React query-------------------//
  const {
    data: getDocumentsByPropertyId,
    refetch: getDocumentsByPropertyIdRefetch,
    isLoading,
  } = useQuery(
    ["getDocumentsByPropertyId", propertyId],
    () => GetDocumentByPropertyId(propertyId),
    { enabled: !!propertyId }
  );
  useEffect(() => {
    setDocumentByPropertyId(getDocumentsByPropertyId?.data);
  }, [getDocumentsByPropertyId]);

  const { data: documentTypeData } = useGetDocumentType("DOCTY");

  const {
    mutateAsync: PostUploadDocument,
    isLoading: PostUploadDocumentIsLoading,
  } = useUploadDocument();
  const {
    mutateAsync: PostCloneDocument,
    isLoading: PostCloneDocumentIsLoading,
  } = usePostCloneDocument();
  //------------Form validation---------//
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      packageTitle: "",
      whatToDuplicate: "",
      // droppedPdf: [{ name: "", document_type: "", file: "" }], //Set the default value of droppedPdf as an empty array
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "droppedPdf", // This should match the name in your form data
  });

  // dropzone
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles, fileRejections) => {
      acceptedFiles.forEach((file) => {
        append({ name: file.name, document_type: "", file: file });
      });
      fileRejections.map(({ file, errors }) => {
        toast.error("Invalid file type " + file.name);
      });
    },
    disabled: PostUploadDocumentIsLoading,
  });

  const handleDocumentPublish = (e, docId) => {
    const documentDetailsPayload = {
      doc_id: String(docId),
      is_publish: e.target.checked ? "1" : "0",
    };
    PutUpdateDocumentDetails(documentDetailsPayload)
      .then((response) => {
        toast.success(response.data.message);
        getDocumentsByPropertyIdRefetch();
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleIconClick = (cell) => {
    if (status !== "success") {
      toast.info("Pdf is loading.");
      setDocumentUrl(cell?.row?.original?.file_url);
      setDocumentPropertyMenu({
        ...documentPropertyMenu,
        preview: true,
      });
    } else {
      setDocumentUrl(cell?.row?.original?.file_url);
      setDocumentPropertyMenu({
        ...documentPropertyMenu,
        preview: true,
      });
    }
  };

  const addedDocumentcolumns = useMemo(
    () => [
      {
        accessorKey: "type",
        header: "Type",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <div className="d-flex gap-2 ps-3">
            {cell.getValue() === "-" && <span>-</span>}

            <ShowTooltip
              tooltipLabel={
                <button
                  className="document-property-table-btn d-flex justify-content-center align-items-center"
                  onClick={() => {
                    handleIconClick(cell);
                    // if (status === "success") {
                    // 	setDocumentUrl(cell?.row?.original?.file_url);
                    // 	setDocumentPropertyMenu({
                    // 		...documentPropertyMenu,
                    // 		preview: true,
                    // 	});
                    // }
                    // else {
                    // 	toast.info("Pdf is loading.")
                    // 	setDocumentUrl(cell?.row?.original?.file_url);
                    // 	setDocumentPropertyMenu({
                    // 		...documentPropertyMenu,
                    // 		preview: true,
                    // 	});
                    // }
                  }}
                >
                  <FontAwesomeIcon
                    icon="fa-regular fa-file-pdf"
                    className="fs-6"
                  />
                </button>
              }
              tooltopWidth={80}
              tooltipContent={<div className="p-2 fs-12">Preview</div>}
            />
          </div>
        ),
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
        Cell: ({ cell }) => (
          <div
            className="cursor-pointer"
            onClick={() => {
              // handleDownloadFile(cell?.row?.original?.file_url);
              setDocumentUrl(cell?.row?.original?.file_url);
              setDocumentPropertyMenu({
                ...documentPropertyMenu,
                preview: true,
              });
            }}
          >
            {cell.getValue()}
            {/* <FontAwesomeIcon icon="fa-regular fa-file-pdf" className="fs-6" /> */}
          </div>
        ),
      },
      {
        accessorKey: "document_type_name",
        header: "Document type",
        size: 180,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "created_on", //normal accessorKey
        header: "Date of upload",
        size: 50,
        Cell: ({ cell }) => (
          <div>{moment(cell.getValue()).format("DD MMM, YYYY")}</div>
        ),
      },
      {
        accessorKey: "created_by", //normal accessorKey
        header: "Uploaded by",
        // size: 100,
      },
      {
        accessorKey: "is_published", //normal accessorKey
        header: "Published",
        size: 50,
        Cell: ({ cell }) => (
          <div>
            <Toggle
              id={cell?.row?.original?.doc_file_id}
              checked={cell.getValue("is_published") == 1 ? true : false}
              onChange={(e) =>
                handleDocumentPublish(e, cell?.row?.original?.doc_file_id)
              }
            />
          </div>
        ),
      },
      {
        accessorKey: "table_menu", //normal accessorKey
        header: "",
        size: 50,
        enableSorting: false,
        Cell: ({ cell }) => (
          <div className="d-flex gap-2">
            <ShowTooltip
              tooltipLabel={
                <button
                  className="document-property-table-btn d-flex justify-content-center align-items-center"
                  onClick={() => {
                    setDocumentUrl(cell?.row?.original?.file_url);
                    setDocumentPropertyMenu({
                      ...documentPropertyMenu,
                      preview: true,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon="fa-regular fa-file-magnifying-glass"
                    className="fs-6"
                  />
                </button>
              }
              tooltopWidth={80}
              tooltipContent={<div className="p-2 fs-12">Preview</div>}
            />

            <ShowTooltip
              tooltipLabel={
                <button
                  className="document-property-table-btn d-flex justify-content-center align-items-center"
                  onClick={() => {
                    setDocumentId(cell?.row?.original?.doc_file_id);
                    setTabType(0);

                    setDocumentPropertyMenu({
                      ...documentPropertyMenu,
                      copy: true,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon="fa-regular fa-arrow-up-right-from-square"
                    className="fs-6"
                  />
                </button>
              }
              tooltopWidth={150}
              tooltipContent={
                <div className="p-2 fs-12">Copy document to package</div>
              }
            />

            <ShowTooltip
              tooltipLabel={
                <button
                  className="document-property-table-btn d-flex justify-content-center align-items-center"
                  onClick={() => {
                    setDocumentId(cell?.row?.original?.doc_file_id);
                    setDocumentPropertyMenu({
                      ...documentPropertyMenu,
                      delete: true,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon="fa-regular fa-trash"
                    className="fs-6"
                  />
                </button>
              }
              tooltopWidth={80}
              tooltipContent={<div className="p-2 fs-12">Delete</div>}
            />
          </div>
        ),
      },
    ],
    []
  );

  // Upload document manually Columns
  const uploadDocumentManuallyColumn = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
        Cell: ({ cell }) => {
          return (
            <div
              className="bg-white radius-6"
              style={{ padding: "0.5rem 0.8rem", border: "1px solid #D1D1D1" }}
            >
              {cell.getValue()}
            </div>
          );
        },
      },
      {
        accessorKey: "document_type",
        header: "Document type",
        minSize: 100, //min size enforced during resizing
        maxSize: 200, //max size enforced during resizing
        size: 180, //medium column
        Cell: ({ cell }) => {
          return (
            <ReactSelect
              placeholder=""
              options={documentTypeData?.data?.map((item) => ({
                value: item?.Id,
                label: item?.Text,
              }))}
              onChange={(e) =>
                setDroppedPdf((prev) =>
                  prev.map((val, index) => {
                    if (cell.row.index === index) {
                      prev[cell.row.index].document_type = e.value;
                    }
                    return val;
                  })
                )
              }
              searchable={false}
              clearable={false}
            />
          );
        },
      },
      {
        accessorKey: "delete_uploaded_doc",
        header: "",
        enableSorting: false,
        size: 50,
        Cell: ({ cell }) => (
          <button
            className="added-document-delete-btn"
            onClick={() => deletePdf(cell.row.index, "droppedPdf")}
          >
            <FontAwesomeIcon icon="fa-regular fa-trash" className="fs-6" />
          </button>
        ),
      },
    ],
    [documentTypeData]
  );

  // deleting selected pdf from upload document manually modal
  // const deletePdf = (deletePdfIndex, tableType,index) => {
  //   remove(index)
  //   if (tableType === "droppedPdf") {
  //     setDroppedPdf((prev) => prev.filter((data, i) => i !== deletePdfIndex));
  //   } else if (tableType === "documentAddedPdf") {
  //     setAddedDocument((prev) =>
  //       prev.filter((data, i) => i !== deletePdfIndex)
  //     );
  //   }
  // };

  const deletePdf = (index) => {
    remove(index);
  };

  //----------------- checked  property for packages--------------------//
  const handleOnChecked = (e, index) => {
    const { id, checked } = e.currentTarget;
    if (checked) {
      let newArry = [...allChecked, id];
      setAllChecked(newArry);
    } else {
      let newArry = allChecked.filter((item) => item != String(id));
      if (newArry?.length != 0) {
        setAllChecked(newArry);
      } else {
        setAllChecked([]);
      }
    }
  };

  // ---------------------------handleDuplicate---------------//
  const handleDuplicate = async (data) => {
    const cloneDocumentData = {
      document_package_id: allChecked,
      doc_file_id: documentId?.toString(),
    };
    try {
      const response = await PostCloneDocument(cloneDocumentData);
      if (response.data.bReturn) {
        toast.success(response.data.message);
        setDocumentPropertyMenu({
          ...documentPropertyMenu,
          copy: false,
        });
        getDocumentPackagesByPropertyIdDataRefetch();
        setAllChecked([]);
        reset();
        data["isChecked"] = allChecked;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleDeleteDocument = async () => {
    setDisableDeleteButton(true);
    const response = await DeleteDocument(documentId);
    if (response.status == status.s) {
      toast.success(response?.data?.message);
      setDocumentPropertyMenu({
        ...documentPropertyMenu,
        delete: false,
      });
      getDocumentsByPropertyIdRefetch();
      setDisableDeleteButton(false);
    }
  };
  const handleSortOrder = async () => {
    const filteredArray = documentbypropertyid?.map((item) => {
      return { doc_file_id: item?.doc_file_id };
    });
    const sortOrderPayload = {
      property_id: propertyId,
      doc_files: filteredArray,
    };
    try {
      const result = await PutUpdateDocumentSortOrderMutate(sortOrderPayload);
      if (result.status === "success" && result?.data?.bReturn) {
        toast?.success(result?.data?.message);
      } else {
        toast?.error(result?.data?.message);
      }
    } catch (error) {}
  };
  // uploading selected pdf to server and getting response
  const handleAddDocument = async (data) => {
    let droppedPdfData = data?.droppedPdf;
    let files = [];
    for (let i = 0; i < data?.droppedPdf?.length; i++) {
      let id;
      if (!PostUploadDocumentIsLoading) {
        id = toast.loading("Uploading file");
      }
      let formData = new FormData();
      formData.append("files[]", data?.droppedPdf?.[i]?.file);
      formData.append("property_id", propertyId.toString());
      formData.append("package_id", 0);
      formData.append(
        "document_type",
        data?.droppedPdf?.[i]?.document_type?.value
      );
      formData.append("document_template_file_id", "0");

      try {
        const response = await PostUploadDocument(formData);

        if (response?.data?.bReturn) {
          files[i] = response?.data?.data?.[0];
          toast.update(id, {
            render: response?.data?.message,
            isLoading: false,
            type: status.s,
            autoClose: 2000,
          });
          getDocumentsByPropertyIdRefetch();
          setOpenUploadDocumentManually(false);
          remove(); // clearing dropped pdf state so every time new pdf will be added in upload document modal
        } else {
          toast.update(id, {
            render: response?.data?.message,
            isLoading: false,
            type: status.s,
            autoClose: 2000,
          });
        }
      } catch (error) {
        toast.update(id, {
          render: error?.message,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    }
  };

  const renderEmptyRowsFallback = () => (
    <div className="d-flex justify-content-center w-100 grey-text py-4 light-font-text">
      <div>
        You do not have any documents yet. Click on “+ UPLOAD DOCUMENT” to add
        your very first document!
      </div>
    </div>
  );
  const [previewUploadDocumentPopup, setPreviewUploadDocumentPopup] =
    useState(false);
  const [previewUploadDocument, setPreviewUploadDocument] = useState({
    allDocumentFileUrl: "",
    allDocumentTypeFileName: "",
  });

  return (
    <div className="py-2">
      <Placeholder animation="glow">
        <div className="d-flex flex-wrap justify-content-between gap-2 align-items-center">
          <div className="d-flex flex-column" style={{ flex: "1 1 65%" }}>
            {isLoading ? (
              <div className="placeholder col-6 p-2 mb-1 bg-secondary"></div>
            ) : (
              <p className="mb-0 form-label-title">Documents on property</p>
            )}
            {isLoading ? (
              <div className="placeholder col-4 p-2 bg-secondary"></div>
            ) : (
              <span className="light-font-text grey-text d-inline-block">
                Below are the documents uploaded directly on the property.
                <br />
                The documents can be displayed on the website and unit selector
                by setting Published to active.
              </span>
            )}
          </div>

          <div
            className="d-flex align-items-start justify-content-lg-end"
            style={{ flex: "1 1 30%" }}
          >
            {isLoading ? (
              <div
                className="placeholder col-10 radius-8 padding-20 bg-secondary"
                style={{ width: "186px" }}
              ></div>
            ) : (
              <ETButton
                variant={"primary"}
                buttonText="UPLOAD DOCUMENT"
                icon={true}
                width="fit-content"
                onClick={() => {
                  setOpenUploadDocumentManually(true);
                }}
              />
            )}
          </div>
        </div>
      </Placeholder>
      <div
        className="mt-4"
        // style={{ height: "400px" }}
      >
        <MRTTheme
          colorVariantsForTableData={["odd"]}
          table={
            <MaterialReactTable
              columns={addedDocumentcolumns}
              data={documentbypropertyid ?? []}
              icons={{ ...SortIcon }}
              state={{ isLoading: !documentbypropertyid }}
              renderEmptyRowsFallback={renderEmptyRowsFallback}
              enableRowOrdering
              // data={documentPropertyData}
              enableTopToolbar={false}
              enableColumnActions={false}
              enablePagination={true}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    documentbypropertyid.splice(
                      hoveredRow.index,
                      0,
                      documentbypropertyid.splice(draggingRow.index, 1)[0]
                    );
                    setDocumentByPropertyId([...documentbypropertyid]);
                  }
                },
              })}
            />
          }
        />
      </div>
      <div className="d-flex justify-content-end">
        <ETButton
          variant={"primary"}
          buttonText="Save"
          onClick={handleSortOrder}
        />
      </div>
      {/* upload document manually */}
      <CustomPopup
        open={previewUploadDocumentPopup}
        onHide={() => setPreviewUploadDocumentPopup(false)}
        modalHeaderText={true}
        // title={previewUploadDocument?.previewUploadDocument}
        title={previewUploadDocument?.previewUploadDocument}
        size="xl"
        title_color="black"
        enableCrossButton={true}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        subTitle={previewUploadDocument?.previewUploadDocument}
        titleTooltip={false}
        // titleTooltipText={allDocumentDetails?.allDocumentPackageFileName}
      >
        <div>
          <Row>
            <Col className="">
              <div style={{ height: "540px" }}>
                <iframe
                  src={previewUploadDocument?.allDocumentFileUrl}
                  height="100%"
                  width="100%"
                ></iframe>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        ></div>
      </CustomPopup>
      <CustomPopup
        open={openUploadDocumentManually}
        onHide={() => {
          setOpenUploadDocumentManually(false);
          remove();
        }}
        title="Upload document manually"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        size="lg"
      >
        <form onSubmit={handleSubmit(handleAddDocument)}>
          <div className="p-3">
            <div className="px-2">
              <div className="light-font-text my-1">
                Drag and drop documents directly from your computer. It is
                possible to change the name of the document and select a
                document type. Once you have selected and customized the
                documents, click add and they will be added to the package.
              </div>
            </div>
            <Row className="mt-3 px-2 upload-manually-document-table">
              <Col md={{ span: 12 }}>
                <div className="pt-2 pb-4">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <DropZoneBorder className="py-4 d-flex flex-column justify-content-center align-items-center">
                      <div>
                        {/* <FontAwesomeIcon icon="fa-solid fa-file" /> */}
                        <FontAwesomeIcon
                          icon="fa-solid fa-file-pdf"
                          style={{ color: "#000000" }}
                        />
                      </div>
                      <div className="mt-2 mb-1 form-label-title">
                        Drop your file here, or&nbsp;
                        <GreenSpan
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={open}
                        >
                          browse your computer
                        </GreenSpan>
                      </div>
                      <div className="created-by">Supports only PDF</div>
                    </DropZoneBorder>
                  </div>
                </div>
                <div>
                  {/* {droppedPdf?.length !== 0 && ( */}
                  <div className="mb-4">
                    <Row className="m-0 p-0">
                      {fields.length !== 0 && (
                        <Row className="m-0 p-0 mb-2">
                          <Col
                            className="d-flex align-items-center py-2  actors-section-title fs-13 ps-3"
                            xs={7}
                          >
                            Name
                            {/* <FontAwesomeIcon
                          icon="fa-solid fa-sort"
                          className="ms-1"
                          style={{ color: "#bfbfbf" }}
                        /> */}
                          </Col>
                          <Col
                            className="d-flex align-items-center py-2 actors-section-title fs-13 ps-0 "
                            xs={4}
                          >
                            Document type
                            {/* <FontAwesomeIcon
                          icon="fa-solid fa-sort"
                          className="ms-1"
                          style={{ color: "#bfbfbf" }}
                        /> */}
                          </Col>
                          <Col xs={2}></Col>
                        </Row>
                      )}

                      <div
                        className="p-0"
                        style={{
                          maxHeight: "160px",
                          overflow: "auto",
                        }}
                      >
                        {fields.map((data, i) => (
                          <div key={data.id}>
                            <Row
                              className="m-0 p-0 mb-2"
                              style={{
                                borderRadius: "8px",
                                backgroundColor: "#F9FAFA",
                              }}
                            >
                              <Col
                                className="d-flex align-items-start py-2 pe-4"
                                xs={7}
                              >
                                <div
                                  className="bg-white radius-6 ms-md-1 w-100 ellipsis "
                                  style={{
                                    padding: "0.3rem 0.5rem",
                                    border: "1px solid #D1D1D1",
                                    marginTop: "2px",
                                    height: "38px",
                                  }}
                                >
                                  <p className="mb-0 fs-300 fs-14">
                                    <Controller
                                      name={`droppedPdf[${i}].name`}
                                      control={control}
                                      defaultValue={data.name}
                                      render={({ field }) => (
                                        <input
                                          disabled
                                          className="standard-input"
                                          {...field}
                                        />
                                      )}
                                    />
                                  </p>
                                </div>
                              </Col>
                              <Col
                                className="d-flex align-items-start py-2 pe-4 ps-0"
                                xs={4}
                              >
                                <div className="w-100 fs-300 fs-14">
                                  <Controller
                                    {...register(
                                      `droppedPdf[${i}].document_type`
                                    )}
                                    rules={{ required: true }}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <ReactSelect
                                        placeholder=""
                                        value={value}
                                        clearable={false}
                                        isSearchable
                                        disabled={PostUploadDocumentIsLoading}
                                        onChange={(e) => {
                                          onChange(e);
                                          clearErrors(
                                            `droppedPdf[${i}].document_type`
                                          );
                                        }}
                                        error={errors.document_type}
                                        options={documentTypeData?.data?.map(
                                          (item) => ({
                                            value: item?.Id,
                                            label: item?.Text,
                                          })
                                        )}
                                      />
                                    )}
                                    name={`droppedPdf[${i}].document_type`}
                                    control={control}
                                  />

                                  {errors?.droppedPdf?.[i]?.document_type && (
                                    <p className="error-msg">{REQUIRED_ERR}</p>
                                  )}
                                </div>
                              </Col>
                              <Col
                                className="d-flex align-items-start py-2 justify-content-end"
                                xs={1}
                              >
                                <div>
                                  <button
                                    className="added-document-delete-btn"
                                    onClick={() => deletePdf(i)}
                                    disabled={PostUploadDocumentIsLoading}
                                  >
                                    <FontAwesomeIcon
                                      icon="fa-regular fa-trash"
                                      className="fs-6"
                                    />
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </div>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => {
                setOpenUploadDocumentManually(false);
                // setDroppedPdf([]);
                remove();
              }}
              disabled={PostUploadDocumentIsLoading}
            />
            <ETButton
              variant={"primary"}
              buttonText="ADD"
              type="submit"
              disabled={PostUploadDocumentIsLoading}
              // onClick={handleAddDocument}
            />
          </div>
        </form>
      </CustomPopup>
      {/* upload document manually */}

      {/* Delete document property */}
      <CustomPopup
        open={documentPropertyMenu.delete}
        onHide={() =>
          setDocumentPropertyMenu({ ...documentPropertyMenu, delete: false })
        }
        title="Delete document?"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
        size={"md"}
      >
        <div className="p-3">
          <div className="px-2">
            <div className="fw-300 fs-14 my-1" style={{ height: "100px" }}>
              Are you sure you want to delete the document?
            </div>
          </div>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => {
              setDocumentPropertyMenu({
                ...documentPropertyMenu,
                delete: false,
              });
            }}
          />
          <ETButton
            variant={"primary"}
            disabled={disableDeleteButton}
            buttonText="I'M SURE"
            onClick={() => handleDeleteDocument()}
          />
        </div>
      </CustomPopup>
      {/* Delete document property */}

      {/* Popup for preview a pdf of document property */}
      <CustomPopup
        open={documentPropertyMenu.preview}
        onHide={() =>
          setDocumentPropertyMenu({ ...documentPropertyMenu, preview: false })
        }
        headerRequired={false}
      >
        <div className="p-3" style={{ height: "650px" }}>
          <iframe
            src={documentUrl}
            // src={
            //   documentUrl
            //     ? documentUrl
            //     : "https://therichpost.com/sample.pdf#toolbar=0&navpanes=0&scrollbar=0"  need proper error handling, cannot show any url
            // }
            height="100%"
            width="100%"
          ></iframe>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container"></div>
      </CustomPopup>
      {/* Popup for preview a pdf of document property */}

      {/* Popup for copy a document property */}
      <CustomPopup
        open={documentPropertyMenu?.copy}
        onHide={() => {
          setDocumentPropertyMenu({ ...documentPropertyMenu, copy: false });
          setAllChecked([]);
          setSearchText("");
        }}
        title="Copy document to package"
        title_color="black"
        titleFont="14px"
        titleTooltip={true}
        enableCrossButton={false}
        titleTooltipText="Copy document to package"
        modalHeadingStyle
        onCloseAction={() => {
          setDocumentPropertyMenu({ ...documentPropertyMenu, copy: false });
          setAllChecked([]);
          setSearchText("");
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <Form onSubmit={handleSubmit(handleDuplicate)}>
          <div>
            <div className="d-flex justify-content-between px-4 pt-3 pb-2">
              <div className="form-label-title">Select package</div>
              <div>
                <input
                  value={"Reset"}
                  type="button"
                  onClick={() => {
                    setAllChecked([]);
                    setSearchText("");
                  }}
                  className="fs-12 form-label-title"
                  style={{
                    height: "23px",
                    width: "56px",
                    borderRadius: "4px",
                    backgroundColor: "rgba(240, 240, 240, 1)",
                    border: "1px solid rgba(209, 209, 209, 1)",
                  }}
                />
              </div>
            </div>

            <div className="px-4 pb-3">
              <FilterComponent
                lableText={"Search"}
                filtertext={searchText}
                handleSearch={(e) => setSearchText(e.target.value)}
              />
            </div>

            <div
              className=" scrollable-container-y ps-4 pe-2 me-2"
              style={{
                height: "350px",
                overflowY: "scroll",
              }}
            >
              {getDocumentPackagesByPropertyIdData?.data
                .filter((item) => {
                  if (searchText === "") {
                    return item;
                  } else if (
                    item?.document_package_name
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  ) {
                    return item;
                  }
                })
                .map((item, index) => (
                  <PrimarySelectedTag
                    key={index}
                    mode={allChecked.includes(
                      String(item?.document_package_id)
                    )}
                    // style={{
                    //   border: allChecked.includes(
                    //     String(item?.document_package_id)
                    //   )
                    //     ? "1px solid #619130"
                    //     : "1px solid #D1D1D1",
                    //   backgroundColor:
                    //     allChecked.includes(
                    //       String(item?.document_package_id)
                    //     ) && "rgba(97, 145, 48, 0.08)",
                    // }}
                    className="d-flex justify-content-between radius-8 ps-3 p-1 my-2 cursor-pointer"
                  >
                    <div
                      className="light-font-text d-flex "
                      style={{ alignItems: "center" }}
                    >
                      {item?.document_package_name}&nbsp;
                      <span className="grey-text">
                        {item?.is_signing_package === 1
                          ? "(Signing package)"
                          : "(Document package)"}
                      </span>
                    </div>
                    <CustomCheckBox
                      id={item?.document_package_id}
                      checked={allChecked.includes(
                        String(item?.document_package_id)
                      )}
                      value={item?.document_package_id}
                      onChange={(e) => handleOnChecked(e, index)}
                    />
                  </PrimarySelectedTag>
                ))}
            </div>
          </div>

          <div
            className="py-2 px-3 d-flex  justify-content-between  justify-content-center footer-section-button-container"
            style={{
              bottom: "0",
              zIndex: "5",
            }}
          >
            <input
              type="button"
              value="CANCEL"
              onClick={() => {
                setDocumentPropertyMenu({
                  ...documentPropertyMenu,
                  copy: false,
                });
                reset();
                setAllChecked([]);
              }}
              style={{
                backgroundColor: "#fff",
                width: "fit-content",
                alignItems: "center",
                display: "flex",
                gap: "7px",
                fontFamily: "Cerebri sans",
                fontWeight: "500",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
                textAlign: "center",
                padding: "10px 15px ",
                fontSize: "14px",
              }}
            />
            <ETButton
              disabled={allChecked?.length == 0 || PostCloneDocumentIsLoading}
              variant={"primary"}
              buttonText="COPY"
              type={"submit"}
            />
          </div>
        </Form>
      </CustomPopup>
      {/* Popup for copy a document property */}
    </div>
  );
};
