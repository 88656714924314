import React, { useEffect } from "react";
import CustomPopup from "../../../../Components/CustomPopup";
import ETButton from "../../../../Components/Buttons/ETButton";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { H4 } from "../../../../styles/Common.style";
import Toggle from "../../../../Components/Switch/Switch";
import { useForm, Controller } from "react-hook-form";
import InputField from "../../../../Components/Textfield/InputField";
import { useGetCommonType } from "../../../../hooks/queries/useCommonTypeQueries";
import { Form } from "react-bootstrap";
import { usePutUpdateDocumentInherited } from "../../../../hooks/queries/useDocumentsQueries";
import { status } from "../../../../model/Constant";
import {
  ErrorAlert,
  LoadingAlert,
  SucessAlert,
} from "../../../../Components/alerts/AlertsAndMessages";
import { toast } from "react-toastify";

const EditDocumentDetails = ({
  setEditDocumentDetails,
  editDocumentDetails,
  documentData = [],
}) => {
  //-------------------------------------react query--------------------------------//
  const { data: GetCommonType } = useGetCommonType(null, "DOCTY");
  const {
    mutateAsync: PutUpdateDocumentInherited,
    isLoading: PutUpdateDocumentInheritedIsLoading,
  } = usePutUpdateDocumentInherited();

  // --------------------------- react hooks form ------------------//
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      link: "",
      email: "",
      message: "",
    },
  });

  // ---------------- handle form submit --------------------//
  const handleSubmitEditDocument = async (data) => {
    let id;
    // Construct a payload for the document update
    const documentInheritedPayload = {
      document_package_document_id: String(
        documentData?.document_package_document_id
      ),
      name: data?.documentName,
      document_type_id: String(data?.document_type_id?.value),
      is_inherited: data?.inheritDocument,
      organisation_id: "1001",
      division_id: String(documentData?.division_id),
      company_id: String(documentData?.company_id),
      property_id: String(documentData?.property_id),
    };

    // Display a loading toast if the document update is not in progress
    if (!PutUpdateDocumentInheritedIsLoading) {
      id = toast.loading(<LoadingAlert />);
    }

    try {
      // Make an asynchronous request to update the document
      const response = await PutUpdateDocumentInherited(
        documentInheritedPayload
      );

      // Check the response status and update the toast accordingly
      if (response.status === status.s && response.data.bReturn) {
        // console.log(" inside sss");
        toast.update(id, {
          render: <SucessAlert />,
          isLoading: false,
          type: status.s,
          autoClose: 2000,
        });
      } else {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } catch (error) {
      // Handle errors during the document update
      toast.update(id, {
        render: <ErrorAlert />,
        isLoading: false,
        type: status.e,
        autoClose: 2000,
      });
    } finally {
      setEditDocumentDetails({
        showPopup: false,
        documentData: [],
      });
    }
  };

  // -------------------- use effects ----------------------//
  useEffect(() => {
    setValue("inheritDocument", documentData?.is_inherited);
    setValue("documentName", documentData?.file_name);
    setValue("document_type_id", {
      value: documentData?.document_type_id,
      label: documentData?.document_type_name,
    });
    return () => {};
  }, [documentData]);

  return (
    <CustomPopup
      open={editDocumentDetails}
      title="Edit document details"
      title_color="black"
      enableCrossButton={false}
      deletePopUpStyle={true}
      titleTooltip={true}
      titleTooltipText={"Edit document details"}
    >
      <Form onSubmit={handleSubmit(handleSubmitEditDocument)}>
        <div>
          <div className="p-4 border-bottom">
            <H4 className="mb-2 p-1">Document name</H4>
            <div className="mb-3">
              {" "}
              <Controller
                {...register(`documentName`)}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <InputField
                      type={"text"}
                      placeholder={"Name (required)"}
                      id={"name"}
                      iserror={errors.documentName}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("document_type_id");
                      }}
                    />
                  </div>
                )}
              />
            </div>
            <div>
              <Controller
                {...register(`document_type_id`)}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder={"Select document type (required)"}
                    clearable={false}
                    iserror={errors?.document_type_id}
                    options={GetCommonType?.data?.map((item) => ({
                      value: item?.Id,
                      label: item?.Text,
                    }))}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("document_type_id");
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="py-4 ">
            <H4 className="mb-1 px-4">Inherit the document?</H4>
            <div className="mb-2 light-font-text px-4">
              {" "}
              Select if this document should be inherited to all levels below.
            </div>{" "}
            <div className="d-flex justify-content-start align-items-center px-3">
              <Controller
                {...register("inheritDocument")}
                control={control}
                render={({
                  field: {
                    onChange,
                    value = parseInt(getValues("inheritDocument")),
                  },
                }) => (
                  <Toggle
                    id={"inherit"}
                    checked={value}
                    onChange={(e) =>
                      e.target.checked
                        ? setValue("inheritDocument", 1)
                        : setValue("inheritDocument", 0)
                    }
                  />
                )}
              />
              <span className="ms-3 light-font-text fs-16">Inherit</span>
            </div>
          </div>
        </div>
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            type={"button"}
            onClick={() => {
              setEditDocumentDetails({
                showPopup: false,
                documentData: [],
              });
            }}
          />
          <ETButton variant={"primary"} type={"submit"} buttonText="SAVE" />
        </div>
      </Form>
    </CustomPopup>
  );
};

export default EditDocumentDetails;
