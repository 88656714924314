import React, { useState } from "react";
import ETButton from "../../../../../Components/Buttons/ETButton";
import ReactSelect from "../../../../../Components/DropDown/ReactSelect";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import InputField from "../../../../../Components/Textfield/InputField";
import { H5 } from "../../../../../styles/Common.style";
import CustomPopup from "../../../../../Components/CustomPopup";
import { SelectPhoneNumber } from "../../../../../Components/phoneNumberDropdown/SelectPhoneNumber";

const AddPropertyAndEnergy = ({
  setStepWizard,
  setProgress,
  setEnergy = () => {},
  setOperation = () => {},
  isEnergy,
  energy,
  operation,
  handleNext = () => {},
  handleCancel = () => {},
}) => {
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      link: "",
      email: "",
      message: "",
      company_country_code: " ",
      company_phone: " ",
      contact_country_code: " ",
      contact_phone: " ",
    },
  });

  //   const handleNext = () => {
  //     setStepWizard("CreateNewBillingDetails");
  //     setProgress(99);
  //   };

  const handleClose = () => {
    !isEnergy ? setOperation(false) : setEnergy(false);
    handleCancel();
  };
  const handleNextScreen = () => {
    handleNext();
  };
  return (
    <div>
      <div
        className="scrollable-container-y"
        style={{ height: "450px" }}
      >
        <>
          <div className="p-4">
            {" "}
            <Row>
              <Col>
                <H5>
                  {!isEnergy
                    ? "Details about the operation manager"
                    : "Details about the energy label"}
                </H5>

                <div className="paragraph2-regular pt-1">
                  {!isEnergy
                    ? " Fill in the information about the operation manager."
                    : " Fill in the information about the energy label."}
                </div>
              </Col>
            </Row>
            <Row className=" pb-2 pt-3">
              <Col>
                <H5>{!isEnergy ? "Operation Role" : "Energy label"}</H5>
              </Col>
            </Row>
            {!isEnergy ? (
              <>
                <Row>
                  <Col
                    lg={3}
                    className="pe-0"
                  >
                    <ReactSelect placeholder="Role" />
                  </Col>
                  <Col
                    lg={3}
                    className="pe-0"
                  >
                    <Controller
                      {...register(``)}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <div>
                          <InputField
                            type={"date"}
                            placeholder={"Date of assignment"}
                          />
                        </div>
                      )}
                    />
                  </Col>
                  <Col lg={3}>
                    <Controller
                      {...register(``)}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <div>
                          <InputField
                            type={"date"}
                            placeholder={"Date of expiry"}
                          />
                        </div>
                      )}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col
                    lg={3}
                    className="pe-0"
                  >
                    <ReactSelect placeholder="Energy Role" />
                  </Col>
                  <Col
                    lg={3}
                    className="pe-0"
                  >
                    <Controller
                      {...register(``)}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <div>
                          <InputField
                            type={"date"}
                            placeholder={"Date of issued"}
                          />
                        </div>
                      )}
                    />
                  </Col>
                  <Col
                    lg={3}
                    className="pe-0"
                  >
                    <Controller
                      {...register(``)}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <div>
                          <InputField
                            type={"date"}
                            placeholder={"Valid From"}
                          />
                        </div>
                      )}
                    />
                  </Col>
                  <Col lg={3}>
                    <Controller
                      {...register(``)}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <div>
                          <InputField
                            type={"date"}
                            placeholder={"Valid to"}
                          />
                        </div>
                      )}
                    />
                  </Col>
                </Row>
              </>
            )}
          </div>

          <hr className="hr m-0" />
        </>

        <div className="p-4">
          <Row className=" pb-2 ">
            <Col>
              <H5>Company details</H5>
            </Col>
          </Row>
          <Row>
            <Col
              xl={3}
              lg={4}
              md={6}
              className="pt-2 pe-0"
            >
              <Controller
                {...register(``)}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <div>
                    <InputField
                      type={"text"}
                      placeholder={"Company"}
                      id={"Company23"}
                    />
                  </div>
                )}
              />
            </Col>
            <Col
              xl={3}
              lg={4}
              md={6}
              className="pt-2 pe-0"
            >
              <Controller
                {...register(``)}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <div>
                    <InputField
                      type={"text"}
                      placeholder={"C/O"}
                      id={"C/O"}
                    />
                  </div>
                )}
              />
            </Col>
          </Row>

          <Row>
            <Col
              xl={3}
              lg={4}
              md={6}
              className="pt-3 pe-0"
            >
              <Controller
                {...register(``)}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <div>
                    <InputField
                      type={"text"}
                      placeholder={"CVR Number"}
                      id={"CVRNumber"}
                    />
                  </div>
                )}
              />
            </Col>
            <Col
              xl={3}
              lg={4}
              md={6}
              className="pt-3 pe-0"
            >
              <Controller
                {...register(``)}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <div>
                    <InputField
                      type={"text"}
                      placeholder={"VAT Number"}
                      id={"VATNumber"}
                    />
                  </div>
                )}
              />
            </Col>
            <Col
              xl={3}
              lg={4}
              md={6}
              className="pt-3"
            >
              <Controller
                {...register(``)}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <div>
                    <InputField
                      type={"text"}
                      placeholder={"GST Number"}
                      id={"GSTNumber"}
                    />
                  </div>
                )}
              />
            </Col>
          </Row>
          <div className="">
            <Row style={{ position: "relative" }}>
              <Col
                xl={3}
                lg={4}
                md={6}
                className="pt-3 pe-lg-0"
              >
                <Controller
                  {...register(``)}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <div>
                      <InputField
                        type={"text"}
                        placeholder={"Street"}
                        id={"street"}
                      />
                    </div>
                  )}
                />
              </Col>
              <Col
                xl={2}
                lg={4}
                md={6}
                className="pe-lg-0 pt-3"
              >
                <Controller
                  {...register(``)}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <div>
                      <InputField
                        type={"text"}
                        placeholder={"No."}
                        id={"houseNo"}
                      />
                    </div>
                  )}
                />
              </Col>
              <Col
                xl={2}
                lg={4}
                md={6}
                className="pe-xl-0 pt-3"
              >
                <ReactSelect placeholder={"Floor"} />
              </Col>
              <Col
                xl={2}
                lg={4}
                md={6}
                className="pt-3 pe-lg-0"
              >
                <Controller
                  {...register(``)}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <div>
                      <InputField
                        type={"text"}
                        placeholder={"Side"}
                        id={"Side23"}
                      />
                    </div>
                  )}
                />
              </Col>
              <Col
                xl={2}
                lg={4}
                md={6}
                className="pt-3 pe-lg-0 "
              >
                <Controller
                  {...register(``)}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <div>
                      <InputField
                        type={"text"}
                        placeholder={"Door"}
                        id={"Door23"}
                      />
                    </div>
                  )}
                />
              </Col>
            </Row>
          </div>
          <div className="">
            <Row>
              <Col
                xl={2}
                lg={4}
                md={6}
                className="pt-3 pe-lg-0"
              >
                <ReactSelect placeholder="Zip code" />
              </Col>
              <Col
                xl={3}
                lg={4}
                md={6}
                className="pt-3 pe-0 "
              >
                <div className="form-input ">
                  <input
                    type="text"
                    disabled
                    placeholder="City"
                    id="city"
                  />
                  <label htmlFor="city">City</label>
                </div>
              </Col>
              <Col
                xl={3}
                lg={4}
                md={6}
                className="pt-3"
              >
                <ReactSelect placeholder="Country" />
              </Col>
            </Row>{" "}
          </div>
          <div>
            <Row>
              <Col
                xl={3}
                lg={4}
                md={6}
                className="pt-3 pe-0"
              >
                <Controller
                  {...register(``)}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <div>
                      <InputField
                        type={"email"}
                        placeholder={"Email"}
                      />
                    </div>
                  )}
                />
              </Col>

              {/* <Col
                xl={2}
                lg={4}
                md={6}
                className="pt-3 pe-xl-0 pe-lg-2 pe-0"
              >
                <ReactSelect placeholder={"Country code"} />
              </Col>
              <Col
                xl={2}
                lg={4}
                md={6}
                className="pt-3 "
              >
                <Controller
                  {...register(``)}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <div>
                      <InputField
                        type={"number"}
                        placeholder={"Phone"}
                      />
                    </div>
                  )}
                />
              </Col> */}

              <Col
                xl={3}
                lg={4}
                md={6}
                className="pt-3 "
              >
                <SelectPhoneNumber
                  errors={errors}
                  setValue={setValue}
                  getPhoneId={"company_country_code"}
                  getPhoneNumber={"company_phone"}
                  control={control}
                  placeholder={"Phone"}
                />
              </Col>
            </Row>
          </div>
        </div>
        <hr className="hr m-0" />
        <div className="px-4">
          <Row className=" pb-2 pt-3">
            <Col>
              <H5>Contact Details</H5>
            </Col>
          </Row>
          <Row>
            <Col
              lg={3}
              className="pe-0"
            >
              <Controller
                {...register(``)}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <div>
                    <InputField
                      type={"text"}
                      placeholder={"First Name"}
                    />
                  </div>
                )}
              />
            </Col>
            <Col
              lg={3}
              className="pe-0"
            >
              <Controller
                {...register(``)}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <div>
                    <InputField
                      type={"text"}
                      placeholder={"Last Name"}
                    />
                  </div>
                )}
              />
            </Col>
            <Col lg={3}>
              <Controller
                {...register(``)}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <div>
                    <InputField
                      type={"text"}
                      placeholder={"Title"}
                    />
                  </div>
                )}
              />
            </Col>
          </Row>
        </div>
        <div className=" px-4 pb-4">
          <Row>
            <Col
              xl={3}
              lg={4}
              md={6}
              className="pt-3 pe-0"
            >
              <Controller
                {...register(``)}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <div>
                    <InputField
                      type={"email"}
                      placeholder={"Email"}
                    />
                  </div>
                )}
              />
            </Col>

            {/* <Col
              xl={2}
              lg={4}
              md={6}
              className="pt-3 pe-xl-0 pe-lg-2 pe-0"
            >
              <ReactSelect placeholder={"Country code"} />
            </Col>
            <Col
              xl={2}
              lg={4}
              md={6}
              className="pt-3 "
            >
              <Controller
                {...register(``)}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <div>
                    <InputField
                      type={"number"}
                      placeholder={"Phone"}
                    />
                  </div>
                )}
              />
            </Col> */}
            <Col
              xl={3}
              lg={4}
              md={6}
              className="pt-3 pe-xl-0 pe-lg-2 pe-0"
            >
              <SelectPhoneNumber
                errors={errors}
                setValue={setValue}
                getPhoneId={"contact_country_code"}
                getPhoneNumber={"contact_phone"}
                control={control}
                placeholder={"Phone"}
              />
            </Col>
          </Row>
        </div>
      </div>

      <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
        <ETButton
          variant={"white"}
          buttonText="Cancel"
          onClick={handleClose}
        />
        <ETButton
          variant={"primary"}
          buttonText={!isEnergy ? "Add" : "Next"}
          onClick={handleNextScreen}
        />
      </div>
    </div>
  );
};

export default AddPropertyAndEnergy;
