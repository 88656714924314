import React from "react";

import estatelogosystem from "../../../assets/images/et-logo-new-user.png";
import maintenanceVisual from "../../../assets/images/Maintenance.svg";
import { Col, Container, Image, Row } from "react-bootstrap";
import { H2 } from "../../../styles/Common.style";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { UTCtoLocalTimeZone } from "../../../utils/UTCtoLocalTimeZone";

export default function SystemMaintenance() {
  const { state } = useLocation();
  const format = "DD MMM, YYYY [at] HH:mm";
  const { convertedDate, timeZone } = UTCtoLocalTimeZone({
    timestamp: state?.end_date,
    timeFormat: format,
  }); // Get current Time zone
  
  return (
    <div
      className="Maindiv pt-4 d-flex flex-column container-fluid  justify-content-center align-items-center"
      style={{ backgroundColor: "#2B3C49", height: "100%" }}
    >
      <div className="pt-4">
        <div className="d-flex justify-content-center align-items-center pb-4">
          <Image
            width={"160px"}
            height={"auto"}
            src={estatelogosystem}
            alt="logo"
          />
        </div>
        <Container
          className="StandardForm  row   justify-content-md-center  mx-auto "
          style={{
            backgroundColor: "#FFFFFF",
            maxWidth: "1000px",
            height: "100%",
            borderRadius: "12px",
            boxShadow: " 0px 6px 18px 0px #0000000A",
          }}
        >
          <div className="pt-40 pb-40 d-flex justify-content-center align-items-center ">
            <Row>
              <Col>
                <H2> Oh no! The system is under maintenance right now...</H2>
              </Col>
            </Row>
          </div>
          <div
            className=" pb-40 d-flex justify-content-center align-items-center text-align-center"
            style={{ width: "50%" }}
          >
            <Row>
              <Col className="light-font-text">
                {state?.message ? (
                  state?.message
                ) : (
                  <>
                    "We are currently updating the system with exciting new
                    features or working
                    <br />
                    on a critical bug that needs our attention."
                  </>
                )}
              </Col>
            </Row>
          </div>
          <div style={{ height: "110px" }}>
            {state?.end_date ? (
              <>
                <div className="d-flex justify-content-center align-items-center light-font-text">
                  We expect the system to be available again:
                </div>
                <div className="d-flex justify-content-center align-items-center relative">
                  <Row style={{ top: "40px" }} className="relative">
                    <Col className="form-label-title">
                      {/* <AetAdjustedTime/><br /> */}
                      <p className="fs-16 mt-3">
                        {moment(state?.end_date)?.format(
                          "DD MMM, YYYY [at] HH:mm"
                        )}{" "}
                        (UTC)
                      </p>
                      <p className="fs-14 text-center">
                        {convertedDate} {timeZone}
                      </p>
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <div className=" pb-40 d-flex justify-content-center align-items-center ">
                <Row>
                  <Col className="light-font-text">
                    We expect the system to be available again as soon as
                    possible.
                  </Col>
                </Row>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center align-items-center relative">
            <Image
              width={"700px"}
              // height={"320px"}
              src={maintenanceVisual}
              alt="logo"
              style={{ objectPosition: "0px 50.5px" }}
            />
          </div>
        </Container>
        <div
          className="light-font-text d-flex justify-content-center align-items-center pb-4 pt-40"
          style={{ color: "#FFF" }}
        >
          This solution is powered by{" "}
          <span
            className="form-label-title "
            style={{ color: "#FFF", margin: "0 5px" }}
          >
            estatetool
          </span>{" "}
          | © 2023 - All rights reserved
        </div>
      </div>
    </div>
  );
}

const AetAdjustedTime = (timestamp, offsetData) => {
  const adjustedTime = moment(timestamp).add(offsetData, "minutes");
  return adjustedTime.format("DD.MMMM.YYYY | HH:mm:ss");
};

// last updated by Suraj -- solved Image bug
