import moment from "moment";
// var m = require("moment-timezone");
import m from 'moment-timezone';


/**
 * Converts a UNIX timestamp from UTC to the local time zone.
 *
 * @param {Object} options - The options object.
 * @param {string} options.timestamp - TimeZone to convert in desire timezone.
 *                                   - TimeStamp should be DD MMM, YYYY HH:mm:ss format.
 * @param {number} options.offset_mins - TimeZone in Minutes should be provided if its coming from backend.
 * @param {string} options.timeFormat - The desired format for the output time.
 * @param {boolean} options.isUtcTimeAvailable - Flag indicating whether the input timestamp is in UTC.
 *                                             - If backend not providing timeStamp/Utc time - pass false parameter
 */


// change UTC time to local time
export const UTCtoLocalTimeZone = ({timestamp, offset_mins, timeFormat, isUtcTimeAvailable}) => {

  const date = moment(timestamp);
  if(!timestamp || !date.isValid() || timestamp === "") return {
    convertedDate: "-",
    timeZone: ""
  };


  const timeZone = date.tz(m.tz.guess()).format("(z)");
  const utcTime = timestamp;

  if(offset_mins === undefined) {
    const UTCTimeZone = moment.utc(utcTime).format('YYYY-MM-DD HH:mm:ss')
    const localTime = moment.utc(UTCTimeZone).toDate();
    return {
      convertedDate: moment(localTime).format(timeFormat),
      timeZone
    }
  }else {
    const adjustedTime = moment(date).add(offset_mins, "minutes");
    if(isUtcTimeAvailable === false) {
      return {
        convertedDate: adjustedTime.format(timeFormat),
        timeZone
      }
    }else {
      return {
        convertedDate: adjustedTime.format(timeFormat),
        timeZone
      }
    }
  }
}



  // // Get the system's time zone
  // const systemTimeZone = moment.tz.guess();
  // // Convert the UTC timestamp to the system's time zone
  // const convertedTimeeee = moment.utc(utcTimestamp).tz(systemTimeZone).format('YYYY-MM-DD HH:mm:ss');