import React, { useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import photoComingSoon from "../../assets/images/photo-coming-soon.jpg";
import moment from "moment";
import Menus from "../Menus/Menus";
import CustomCheckBox from "../Checkbox/Checkbox";
import styled from "styled-components";
import { useUserDetails } from "../../hooks/stores/useUserDetailsStore";
import { UTCtoLocalTimeZone } from "../../utils/UTCtoLocalTimeZone";

const MediaCard = ({
  data,
  handleEditMediaPackage,
  handleDuplicateClick,
  setOpenMediaPackageDelete,
  isTag,
  setDeletePopUp,
  checkValue,
  checkboxOnChange,
}) => {
  // console.log("🚀 ~ file: MediaCard.js:19 ~ data:", data)
  // const { mediaStyle, setMediaStyle } = useTagPackageStore((state) => state);
  // const [mediaStyle, setMediaStyle] = useState([]);
  // console.log(data, "checkValue");
  //------------- zustand ------------------//
  const { userDetails } = useUserDetails((state) => state);
  const { convertedDate: createdBy } = UTCtoLocalTimeZone({
    timestamp: data?.created_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });
  const { convertedDate: modifiedBy } = UTCtoLocalTimeZone({
    timestamp: data?.modified_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });

  return (
    <CardsDiv
      className="package-card "
      isActive={checkValue?.length}
    >
      {isTag === "AddTagM" && (
        <div className="d-flex justify-content-end">
          <CustomCheckBox
            // value={checkValue[0]?.is_active}
            checked={checkValue?.length}
            onChange={checkboxOnChange}
          />
        </div>
      )}
      <Row className="media-package-image-container px-4 py-3">
        {data?.media_files?.split(",")?.map(
          (item, index) =>
            index < 5 && (
              <Col
                xs={4}
                className="p-1"
                key={index}
                style={{
                  display: "flex",
                  height: "72px",
                  // width: "106px",
                }}
              >
                <Image
                  fluid
                  src={item ? item : photoComingSoon}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "4px",
                  }}
                />
              </Col>
            )
        )}

        {data?.media_files?.split(",").length > 5 && (
          <Col
            xs={4}
            className="p-1 pb-2"
          >
            <div
              style={{
                display: "flex",
                height: "100%",
                // width: "106px",
                backgroundColor: "#D1D1D1",
                borderRadius: "4px",
              }}
            >
              <div className="fs-14 fw-700 m-auto">
                + {data?.media_files?.split(",").length - 5}
              </div>
              <Image
                className=""
                style={{ borderRadius: "4px", border: "0px" }}
              />
            </div>
          </Col>
        )}
      </Row>
      <hr className="hr my-0" />
      <div
        className="d-flex flex-column p-3"
        style={{ gap: "12px" }}
      >
        {isTag && (
          <div className="fs-14">
            <div className="form-label-title ">Source</div>
            <div className=" light-font-text">Media</div>
          </div>
        )}

        <div className="ellipsis">
          <div className="form-label-title">Package title</div>
          <div className="light-font-text ">
            {data?.media_package_name || "-"}
          </div>
        </div>
        <div>
          <div className="form-label-title">Package type</div>
          <div className="light-font-text">{data?.package_type || "-"}</div>
        </div>
        {!isTag && (
          <div>
            <div className="form-label-title">Tag</div>
            <div
              className="light-font-text text-truncate "
              style={{ width: "90%" }}
            >
              {data?.tags ? data?.tags : "-"}
            </div>
          </div>
        )}
        <div className="d-flex justify-content-between">
          <div className="fs-12 fw-300 pt-1 ">
            <div className="fs-12 form-label-title">Created by</div>
            <div className="created-by">
              {data?.created_by ? data?.created_by + ", " + createdBy : "-"}
            </div>
            <div className="fs-12 pt-1 form-label-title">Last updated by</div>
            <div className="created-by">
              {data?.modified_by ? data?.modified_by + ", " + modifiedBy : "-"}
            </div>
          </div>
          {isTag !== "AddTagM" && (
            <div className="d-flex align-items-end">
              <Menus
                itemheight={23}
                itemwidth={23}
                iconborderradius="4px"
                component={
                  <>
                    {isTag !== "M" ? (
                      <div className="light-font-text">
                        <div
                          className="addpropContent px-3 py-2 "
                          onClick={() =>
                            handleEditMediaPackage(data?.media_package_id)
                          }
                        >
                          <span className="ms-1">Edit</span>
                        </div>
                        <hr className=" m-0"></hr>
                        <div
                          className="addpropContent px-3 py-2 "
                          onClick={() =>
                            handleDuplicateClick(data?.media_package_id)
                          }
                        >
                          <span className="ms-1">Duplicate</span>
                        </div>
                        <hr className=" m-0"></hr>
                        <div
                          onClick={() => setOpenMediaPackageDelete(true)}
                          className="addpropContent px-3 pt-2 pb-2 "
                        >
                          <span className="ms-1">Delete</span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="addpropContent px-3 pt-2 pb-2 light-font-text"
                        onClick={() => setDeletePopUp(true)}
                      >
                        <span className="ms-1">Delete</span>
                      </div>
                    )}
                  </>
                }
              />
            </div>
          )}
        </div>
      </div>
    </CardsDiv>
  );
};

export default MediaCard;

const CardsDiv = styled.div`
  background-color: #f9fafa;
  border-color: #f0f0f0;
  ${(props) =>
    props.isActive &&
    `
  background-color:${props.theme.component.checkbox.primary.color + "11"};
   border-color: ${props.theme.component.checkbox.primary.color};
`}
`;
