// created by Suraj
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row, Image } from "react-bootstrap";
import {
  CircleMsg,
  H3,
  H4,
  H5,
  TeamsImageContainer,
  UsersCount,
} from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import ETButton from "../../../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import MRTTheme from "../../../../Components/table/MRTTheme";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  paginationoptions,
} from "../../../../Components/table/MRTGlobalProps";
import Menus from "../../../../Components/Menus/Menus";
import { MenuItem } from "@mui/material";
import CustomPopup from "../../../../Components/CustomPopup";
import UserInformation from "./usersComponents/UserInformation";
import ShowTooltip from "../../../../Components/Tooltip/ShowTooltip";
import CreateNewTeamWizard from "./usersComponents/CreateNewTeamWizard";
import moment from "moment";
import {
  useDeleteUser,
  useGetUserByLevel,
  useGetUserDetailsById,
  useGetUserRoles,
  usePostAddUser,
  useUpdateUserDetails,
  useUpdateUserStatus,
} from "../../../../hooks/queries/useUserQueries";
import { useOrganizationId } from "../../../../hooks/stores/useOrganizationStore";
import { useGetDivisionByOrganisationId } from "../../../../hooks/queries/useDivisionQueries";
import {
  useGetTeamByTeamId,
  useGetTeamsByLevel,
} from "../../../../hooks/queries/useTeam";
import { GetLanguages } from "../../../../services/Language";
import { useGetLanguages } from "../../../../hooks/queries/uselanguageQueries";
import { toast } from "react-toastify";
import {
  ErrorAlert,
  SucessAlert,
} from "../../../../Components/alerts/AlertsAndMessages";
import { status } from "../../../../model/Constant";
import TagToolTip from "../../../../Components/Tooltip/PropertyTagToolTip";
import Avatar from "../../../../assets/images/avatar.png";
import { UTCtoLocalTimeZone } from "../../../../utils/UTCtoLocalTimeZone";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";

const ActiveUsers = ({
  teamsData,
  GetActiveUsersData,
  GetActiveUsersDataRefetch = () => {},
  GetPendingUsersDataRefetch = () => {},
  GetArchiveUserDataRefetch = () => {},
  GetTeamsByLevelBasicDataRefetch = () => {},
  GetDivisionByOrganisationIdData,
  GetDivisionByOrganisationIdRefetch,
  userDetailsByIdData,
  userDetailsByIdDataRemove = () => {},
  userRolesData,
  GetTeamsByLevelData,
  GetLanguagesData,
  userId,
  setUserId,
  teamId,
  setTeamId,
  usedFor,
  setUsedFor,
  // handleUpdateUser,
  handleUpdateUserStatus,
  croppedImage,
  setCroppedImage,
  imageName,
  setImageName,
  uploadImgFile,
  setUploadImgFile,
  userDetailsbyIdDataRefetch,
  newUserId,
  setNewUserId,
}) => {
  // state
  const [createNewUserPopup, setCreateNewUserPopup] = useState(false);
  const [updateUserPopup, setUpdateUserPopup] = useState(false);
  const [createNewTeamPopup, setCreateNewTeamPopup] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showBlockAccess, setShowBlockAccess] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [deactivateUser, setDeactivateUser] = useState(false);
  // const [usedFor, setUsedFor] = useState("");
  const [userName, setUserName] = useState("");
  const [sorting, setSorting] = useState([]);
  const [reactivateAccess, setReactivateAccess] = useState(false);
  const [deleteUserPopup, setDeleteUserPopup] = useState(false);
  const [assignUsersToTeamData, setAssignUsersToTeamData] = useState([]);
  const [stepWizard, setStepWizard] = useState("CreateNewTeam");
  const [stepOneTeamsData, setStepOneTeamData] = useState({});
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [userRolesOptions, setUserRoelsOptions] = useState([]);
  // ----zustand
  const { organizationId } = useOrganizationId((state) => state);
  const { userDetails } = useUserDetails((state) => state);

  // ----react-query
  const { mutateAsync: updateUserStatusMutate } = useUpdateUserStatus();
  const { mutateAsync: deleteUserStatusMutate } = useDeleteUser();
  const { mutateAsync: postAddUserAsync } = usePostAddUser();
  const { mutateAsync: UpdateUserDetailsMutate } = useUpdateUserDetails();

  let userDetailsData = userDetailsByIdData?.data;
  const activeUsersdata = GetActiveUsersData?.data;
  const allUsersData = teamsData?.default;
  const teamData = teamsData?.team;
  const languageOptions = GetLanguagesData?.data?.map((item) => ({
    value: item?.Id,
    text: item?.Text,
    label: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={item?.DataValue1}
          alt={`${item?.Text} flag`}
          style={{
            marginRight: "8px",
            width: "18px",
            height: "12px",
          }}
        />
        <span className="normal-font-text fs-14">{item?.Text}</span>
      </div>
    ),
  }));

  useEffect(() => {
    const divisions = GetDivisionByOrganisationIdData?.data?.map(
      (division, index) => {
        return {
          value: division?.division_id,
          label: division?.name,
        };
      }
    );
    setDivisionOptions(divisions);
  }, [GetDivisionByOrganisationIdData]);
  useEffect(() => {
    const userRoels = userRolesData?.data?.map((role, index) => {
      return {
        value: role?.Id,
        label: role?.Text,
      };
    });
    setUserRoelsOptions(userRoels);
  }, []);

  useEffect(() => {
    GetTeamsByLevelBasicDataRefetch();
    GetActiveUsersDataRefetch();
  }, []);
  useEffect(() => {
    const fetchImageAndSetFile = async () => {
      try {
        const response = await fetch(croppedImage);
        const blob = await response.blob();
        const file = new File(
          [blob],
          `${userDetailsData?.[0]?.first_name}.png`,
          {
            type: blob.type,
          }
        );
        setUploadImgFile(croppedImage ? file : "");
      } catch (error) {
        setUploadImgFile(error);
      }
    };

    fetchImageAndSetFile();
  }, [croppedImage, userDetailsData?.[0]?.first_name]);

  // to archive user

  const handleArchiveUser = async (userId) => {
    try {
      const response = await deleteUserStatusMutate(userId);
      if (response?.status === status?.s && response?.data?.bReturn) {
        toast.success(<SucessAlert />);
        GetPendingUsersDataRefetch();
        GetActiveUsersDataRefetch();
        GetArchiveUserDataRefetch();
        GetTeamsByLevelBasicDataRefetch();
      } else {
        toast.error(<ErrorAlert />);
      }
    } catch (error) {}
  };

  // to crate new user
  const handleCreateNewUser = async (data) => {
    const getTeamIds = (teams) => {
      if (teams === "" || teams === undefined || teams === null) {
        return [];
      } else if (teams?.length === 1) {
        return [teams?.[0]?.value?.toString()];
      } else if (teams?.length > 1) {
        const teamIds = teams?.map((team) => team?.value);
        return teamIds;
      }
    };
    const transformedUserRole = data?.userRoles?.map((item) => ({
      division_id: item?.division ? item?.division?.value?.toString() : 0,
      role_id: item?.user_role ? item?.user_role?.value : 0,
      user_team_id: getTeamIds(item?.teams),
    }));
    let formData = new FormData();
    formData.append("organisation_id", organizationId);
    formData.append("first_name", data?.firstName);
    formData.append("last_name", data?.lastName);
    formData.append("email", data?.email);
    formData.append("designation", data?.title);
    formData.append("language_id", data?.language?.value);
    formData.append("isd_code", data?.isd_code);
    formData.append("contact_no", data?.phone);
    formData.append("company_name", data?.company);
    formData.append("work_area_id", 0);
    formData.append("system_id", "ETM");
    // formData.append("profile_image_file", uploadImgFile);
    formData.append(
      "profile_image_file",
      userDetails?.image_url && croppedImage?.includes("https")
        ? ""
        : uploadImgFile
    );
    formData.append("roles", JSON.stringify(transformedUserRole));

    try {
      const response = await postAddUserAsync(formData);
      if (response?.status === status?.s && response?.data?.bReturn) {
        toast.success(<SucessAlert />);
        GetPendingUsersDataRefetch();
        GetActiveUsersDataRefetch();
      } else {
        toast.error(<ErrorAlert />);
      }
    } catch (error) {}
  };
  const handleEditUser = async (data) => {
    const getTeamIds = (teams) => {
      if (teams === "" || teams === undefined || teams === null) {
        return [];
      } else if (teams?.length === 1) {
        return [teams?.[0]?.value?.toString()];
      } else if (teams?.length > 1) {
        const teamIds = teams?.map((team) => team?.value);
        return teamIds;
      }
    };
    const transformedUserRole = data?.userRoles?.map((item) => ({
      division_id: item?.division ? item?.division?.value?.toString() : 0,
      role_id: item?.user_role ? item?.user_role?.value : 0,
      user_team_id: getTeamIds(item?.teams),
    }));
    let formData = new FormData();
    formData?.append("user_id", userId);
    formData.append("first_name", data?.firstName);
    formData.append("last_name", data?.lastName);
    formData.append("designation", data?.title);
    formData.append("language_id", data?.language?.value);
    formData.append("isd_code", data?.isd_code);
    formData.append("contact_no", data?.phone);
    formData.append("company_name", data?.company);
    formData.append("work_area_id", 0);
    formData.append("profile_image_filename", uploadImgFile?.name);
    formData.append("profile_image_file", uploadImgFile);
    formData.append("system_id", "ETM");
    formData.append("roles", JSON.stringify(transformedUserRole));
    formData.append("new_user_id", newUserId);

    try {
      const response = await UpdateUserDetailsMutate(formData);
      if (response?.status === status?.s && response?.data?.bReturn) {
        toast.success(<SucessAlert />);
        GetPendingUsersDataRefetch();
        GetActiveUsersDataRefetch();
        userDetailsbyIdDataRefetch();
      } else {
        toast.error(<ErrorAlert />);
      }
    } catch (error) {}
  };

  const handleCommaSeparatedRoles = (row, type) => {
    let splittedRole;
    if (type === "roles") {
      splittedRole = row?.original?.roles?.split(",");
    } else if (type === "divisions") {
      splittedRole = row?.original?.division_details?.map((detail, index) => {
        return detail?.division_name;
      });
    }
    return (
      <div>
        <span>{splittedRole?.[0]}</span>
        {splittedRole?.length > 1 && (
          <CircleMsg>
            <span>{splittedRole?.length}</span>
          </CircleMsg>
        )}
      </div>
    );
  };
  // below code for table columns
  const columns = useMemo(
    () => [
      {
        accessorKey: "user_no",
        header: "No.",
        enableHiding: false,
        enableGlobalFilter: true,

        size: 30,
        Cell: ({ row }) => (
          <div>{row.original?.user_no ? row.original?.user_no : "-"}</div>
        ),
      },
      {
        accessorKey: "first_name",
        header: "First name",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div>{row.original?.first_name ? row.original?.first_name : "-"}</div>
        ),
      },
      {
        accessorKey: "last_name",
        header: "Last name",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div>{row.original?.last_name ? row.original?.last_name : "-"}</div>
        ),
      },
      {
        accessorKey: "roles",
        header: "Roles",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div className="light-font-text d-flex gap-2">
            <TagToolTip
              iconColor={"#888888"}
              size={"lg"}
              border="1px solid #F0F0F0"
              padding={"5px 10px"}
              boxShadow="2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
              text={
                <div style={{ width: "450px" }}>
                  <Row className="py-3 ps-4 pe-6 ">
                    <div className="form-label-title">
                      User roles for {row?.original?.first_name}{" "}
                      {row?.original?.last_name}
                    </div>
                  </Row>
                  <hr className="m-0"></hr>
                  <div
                    className="p-4 me-2"
                    style={{ height: "200px", overflowY: "scroll" }}
                  >
                    <Row className="mb-3">
                      <Col lg={5}>
                        <div className="form-label-title">Role Type</div>
                      </Col>
                      <Col>
                        <div className="form-label-title">Division</div>
                      </Col>
                      <Col>
                        <div className="form-label-title">Joined</div>
                      </Col>
                    </Row>

                    {row?.original?.division_details?.map((divisionDetail) => {
                      return (
                        <Row className="mb-3" key={divisionDetail?.division_id}>
                          <Col lg={5}>
                            <div className="light-font-text">
                              {divisionDetail?.role_name}
                            </div>
                          </Col>
                          <Col>
                            <div className="light-font-text">
                              {divisionDetail?.division_name}
                            </div>
                          </Col>
                          <Col>
                            <div className="light-font-text">
                              {divisionDetail?.assigned_on
                                ? moment(
                                    divisionDetail?.assigned_on,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ).format("DD MMM, YYYY")
                                : "-"}
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </div>
              }
            />
            <div>
              {row?.original?.roles?.includes(",")
                ? handleCommaSeparatedRoles(row, "roles")
                : row?.original?.roles}
            </div>
          </div>
        ),
      },
      {
        accessorKey: "status_name",
        header: "Status",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
        // Cell: ({ row }) => handleCommaSeparatedRoles(row, "divisions"),
        Cell: ({ row }) => {
          const { convertedDate: status_modified_on } = UTCtoLocalTimeZone({
            timestamp: row?.original?.status_modified_on,
            offset_mins: userDetails?.timezone_offset_mins,
            timeFormat: "DD MMM, YYYY [at] HH:mm:ss",
          }); // Get current Time zone

          return (
            <div className="light-font-text d-flex gap-2">
              {row?.original?.status_name}
              {String(row?.original?.status_id) === "3" && (
                <TagToolTip
                  iconColor={"#888888"}
                  size={"lg"}
                  border="1px solid #F0F0F0"
                  padding={"0px"}
                  boxShadow="2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                  text={
                    <div style={{ width: "500px" }}>
                      <div className="form-label-title py-3 px-4">
                        Details about blocked user
                      </div>

                      <hr className="m-0"></hr>

                      <div className="p-4" style={{ height: "100px" }}>
                        <Row style={{ paddingBottom: "11px" }}>
                          <Col>
                            <div className="form-label-title">User</div>
                          </Col>
                          <Col>
                            <div className="form-label-title">Blocked</div>
                          </Col>
                          <Col>
                            <div className="form-label-title">Blocked by</div>
                          </Col>
                        </Row>

                        <Row style={{ paddingBottom: "11px" }}>
                          <Col>
                            <div className="light-font-text">
                              {row?.original?.first_name}{" "}
                              {row?.original?.last_name}
                            </div>
                          </Col>
                          <Col>
                            <div className="light-font-text">
                              {status_modified_on}
                            </div>
                          </Col>
                          <Col>
                            <div className="light-font-text">
                              {row?.original?.modified_on_name}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  }
                />
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "joined_on",
        header: "Joined",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: false,

        Cell: ({ row }) => {
          const { convertedDate: joined_on } = UTCtoLocalTimeZone({
            timestamp: row?.original?.joined_on,
            offset_mins: useUserDetails?.timezone_offset_mins,
            timeFormat: "DD MMM, YYYY",
          }); // Get current Time zone

          return (
            <div className="light-font-text d-flex gap-2">{joined_on}</div>
          );
        },
      },
      {
        accessorKey: "last_login_on",
        header: "Last active",
        enableHiding: false,
        size: 180,
        enableGlobalFilter: false,

        Cell: ({ row }) => {
          const { convertedDate: last_login_on } = UTCtoLocalTimeZone({
            timestamp: row?.original?.last_login_on,
            offset_mins: useUserDetails?.timezone_offset_mins,
            timeFormat: "DD MMM, YYYY",
          }); // Get current Time zone

          return (
            <div className="light-font-text d-flex gap-2">{last_login_on}</div>
          );
        },
      },
    ],
    []
  );

  const renderEmptyRowsFallback = () => {
    if (activeUsersdata && activeUsersdata?.length === 0) {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-3">
          <>
            <div>You do not have any active users.</div>
          </>
        </div>
      );
    } else {
      return (
        <div
          className="d-flex justify-content-center w-100 grey-text py-3 radius-4"
          style={{ border: "1px solid #d1d1d1", backgroundColor: "#F9FAFA" }}
        >
          <>
            <div className="fs-14">
              Sorry! No result found. Please try again!
            </div>
          </>
        </div>
      );
    }
  };

  return (
    <div>
      {/* top bar */}
      <div className="pb-2 pt-2 px-4">
        <Row>
          <Col className="">
            <div className="d-flex align-items-center  gap-2">
              <H3>Active users</H3>
              <CustomToolTip size="sm" text="Active users" />
            </div>
            <div className="paragraph2-light  pt-3">
              Below is the list of all active users in the system. Give the user
              access to properties by assign them to the required teams.
            </div>
          </Col>
          <Col className="my-1 d-flex justify-content-end gap-3">
            <ETButton
              variant={"primary"}
              buttonText="Create user"
              width="fit-content"
              icon={true}
              onClick={() => {
                setUsedFor("CreateUser");
                setCroppedImage("");
                setCreateNewUserPopup(true);
              }}
            />
          </Col>
        </Row>
      </div>

      {/* left sections and table sections */}
      <div className="py-2 px-4">
        <Row>
          <Col lg={4} xl={3}>
            <div
              className=" border-bottom d-flex gap-4 align-items-center cursor-pointer"
              style={{ height: "54px" }}
              onClick={() => {
                setStepWizard("CreateNewTeam");
                setCreateNewTeamPopup(true);
              }}
            >
              <FontAwesomeIcon
                icon={"fa-solid  fa-plus"}
                size="lg"
                style={{ color: "#000000" }}
              />
              <H4 className="ps-1">New team</H4>
            </div>

            {/* All Users */}
            <div
              className="  d-flex align-items-center justify-content-between"
              style={{ height: "50px", cursor: "pointer" }}
              onClick={() => setTeamId(allUsersData?.[0]?.Id)}
            >
              <div className="d-flex gap-3 align-items-center">
                <FontAwesomeIcon
                  icon="fa-regular fa-users"
                  size="lg"
                  style={{ color: "#000000" }}
                />
                <H4 className="ps-1">{allUsersData?.[0]?.Text}</H4>
              </div>
              <UsersCount>
                <span>{allUsersData?.[0]?.DataValue1}</span>
              </UsersCount>
            </div>

            {/* users info */}
            {teamData?.map((item) => (
              <div
                key={item?.Id}
                className="d-flex  align-items-center justify-content-between"
                style={{ height: "50px" }}
                // onClick={setTeamId(item?.Id)}
              >
                <div
                  className="d-flex gap-3 align-items-center"
                  onClick={() => setTeamId(item?.Id)}
                  style={{ cursor: "pointer" }}
                >
                  {item?.DataValue2 ? (
                    <Image
                      src={item?.DataValue2}
                      roundedCircle
                      height={30}
                      width={30}
                      onError={(e) => (e.target.src = Avatar)}
                    />
                  ) : (
                    <TeamsImageContainer>
                      {item?.Text?.charAt(0)?.toUpperCase()}
                    </TeamsImageContainer>
                  )}

                  <H4>{item?.Text}</H4>
                </div>
                <UsersCount>
                  <span>{item?.DataValue1}</span>
                </UsersCount>
              </div>
            ))}
          </Col>

          <Col lg={8} xl={9}>
            <MRTTheme
              colorVariantsForTableRow={["gray"]}
              colorVariantsForTableHead={["white"]}
              table={
                <MaterialReactTable
                  columns={columns}
                  icons={{ ...SortIcon }}
                  data={activeUsersdata || []} //data is undefined on first render
                  renderEmptyRowsFallback={renderEmptyRowsFallback}
                  enableFilterMatchHighlighting={true}
                  enableGlobalFilter
                  globalFilterFn="contains"
                  enableColumnActions={false}
                  enableBottomToolbar={true}
                  muiSearchTextFieldProps={{
                    ...SearchStyle,

                    InputProps: {
                      ...SearchIcon,
                    },
                  }}
                  getRowId={(originalRow) => originalRow?.company_id}
                  //give each row a more useful id
                  enableSelectAll={false}
                  enableRowActions
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      header: "",
                      size: 20,
                      muiTableBodyCellProps: {
                        sx: {
                          paddingRight: "16px !important",
                        },
                      },
                    },
                    "mrt-row-select": {
                      header: "",
                    },
                  }}
                  muiTablePaperProps={{
                    sx: {
                      backgroundColor: "transparent",
                      table: {
                        borderCollapse: "separate",
                        borderSpacing: "0 5px",
                      },
                    },
                  }}
                  renderTopToolbar={({ table }) => (
                    <>
                      <Row className="d-flex justify-content-end">
                        <Col lg={3} className="d-flex justify-content-end">
                          <div className="pb-2 ">
                            <div className="mb-1">
                              <MRT_GlobalFilterTextField
                                placeholder="Search for a user..."
                                table={table}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                  muiTableContainerProps={{
                    elevation: 0,
                    sx: {
                      background: "white",
                      borderTop: "1px solid #d1d1d190",
                      padding: "0px !important",
                      borderRadius: "0px !important",
                    },
                  }}
                  positionActionsColumn="last"
                  initialState={{
                    showGlobalFilter: true,
                    showColumnFilters: false,
                  }}
                  muiPaginationProps={{
                    ...paginationoptions,
                  }}
                  renderRowActions={({ row, table }) => (
                    <>
                      <div className="d-flex justify-content-end">
                        <Menus
                          itemheight={24}
                          itemwidth={24}
                          iconborderradius="4px"
                          itemcontainerwidth="210px"
                          component={
                            <>
                              <MenuItem
                                key="user-details"
                                sx={fontStyle}
                                onClick={() => {
                                  setUsedFor("UserDetails");
                                  setUserId(row?.original?.user_id);
                                  setUpdateUserPopup(true);
                                }}
                              >
                                User details
                              </MenuItem>
                              <hr className="hr m-0"></hr>
                              <MenuItem
                                key="block-access"
                                sx={fontStyle}
                                onClick={() => {
                                  String(row?.original?.status_id) == "3"
                                    ? setReactivateAccess(true)
                                    : setShowBlockAccess(true);

                                  setUserId(row?.original?.user_id);
                                  setUserName(
                                    `${row?.original?.first_name} ${row?.original?.last_name}`
                                  );
                                }}
                              >
                                {String(row?.original?.status_id) == "3"
                                  ? "Reactivate access"
                                  : "Block access"}
                              </MenuItem>
                              <hr className="hr m-0"></hr>
                              <MenuItem
                                key="archive-user"
                                sx={fontStyle}
                                onClick={() => {
                                  setUserId(row?.original?.user_id);
                                  setDeleteUserPopup(true);
                                }}
                              >
                                Archive user
                              </MenuItem>
                            </>
                          }
                        />
                      </div>
                    </>
                  )}
                />
              }
            />
          </Col>
        </Row>
        <CreateNewTeamWizard
          show={createNewTeamPopup}
          setShow={setCreateNewTeamPopup}
          progress={progress}
          setProgress={setProgress}
          teamId={teamId}
          assignUsersToTeamData={assignUsersToTeamData}
          setAssignUsersToTeamData={setAssignUsersToTeamData}
          GetTeamsByLevelBasicDataRefetch={GetTeamsByLevelBasicDataRefetch}
          stepWizard={stepWizard}
          setStepWizard={setStepWizard}
          setStepOneTeamData={setStepOneTeamData}
          stepOneTeamsData={stepOneTeamsData}
        />
      </div>

      {/* Deactivate user? */}
      <CustomPopup
        open={deactivateUser}
        onHide={() => setDeactivateUser(false)}
        title="Deactivate user?"
        title_color="black"
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Create new question"}
      >
        <div className="p-4 light-font-text" style={{ width: "79%" }}>
          Deactivating the user will revoke all of the users application access.
          Are you sure you want to deactivate this user?
        </div>
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET?"
            onClick={() => {
              setDeactivateUser(false);
            }}
          />
          <ETButton variant={"primary"} buttonText="DEACTIVATE" />
        </div>
      </CustomPopup>

      {/* Create new user popup */}
      <CustomPopup
        open={createNewUserPopup}
        onHide={() => setCreateNewUserPopup(false)}
        title={"Create new user"}
        title_color="black"
        size={"xl"}
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Create new user"}
      >
        {/* <CreateNewUser setClosePopup={setCreateNewUserPopup} /> */}
        <UserInformation
          setClosePopup={setCreateNewUserPopup}
          userDetailsData={userDetailsData}
          userDetailsByIdDataRemove={userDetailsByIdDataRemove}
          usedFor={usedFor}
          divisionOptions={divisionOptions}
          setDivisionOptions={setDivisionOptions}
          userRolesOptions={userRolesOptions}
          setUserRoelsOptions={setUserRoelsOptions}
          // assignedToTeamsOptions={assignedToTeamsOptions}
          formSubmit={handleCreateNewUser}
          languageOptions={languageOptions}
          croppedImage={croppedImage}
          setCroppedImage={setCroppedImage}
          imageName={imageName}
          setImageName={setImageName}
          newUserId={newUserId}
          setNewUserId={setNewUserId}
          setUserId={setUserId}
        />
      </CustomPopup>
      {/* {to update user details} */}
      <CustomPopup
        open={updateUserPopup}
        onHide={() => setUpdateUserPopup(false)}
        title={"Edit user"}
        title_color="black"
        size={"xl"}
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Edit user"}
      >
        <UserInformation
          setClosePopup={setUpdateUserPopup}
          userDetailsData={userDetailsData}
          userDetailsByIdDataRemove={userDetailsByIdDataRemove}
          usedFor={usedFor}
          divisionOptions={divisionOptions}
          setDivisionOptions={setDivisionOptions}
          userRolesOptions={userRolesOptions}
          setUserRoelsOptions={setUserRoelsOptions}
          // assignedToTeamsOptions={assignedToTeamsOptions}
          formSubmit={handleEditUser}
          languageOptions={languageOptions}
          croppedImage={croppedImage}
          setCroppedImage={setCroppedImage}
          imageName={imageName}
          setImageName={setImageName}
          newUserId={newUserId}
          setNewUserId={setNewUserId}
        />
      </CustomPopup>

      {/* Block access */}
      <CustomPopup
        open={showBlockAccess}
        onHide={() => setShowBlockAccess(false)}
        title="Block access"
        title_color="black"
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Block access"}
      >
        <div className="p-4 light-font-text">
          <div className="mb-4">
            Do you want to block this users access to the system?
          </div>

          <div>User:</div>
          <div className="mb-4">{userName}</div>

          <div>Access can be reactivated later.</div>
        </div>

        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setShowBlockAccess(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="CONFIRM"
            onClick={() => {
              handleUpdateUserStatus("block", "");
              setShowBlockAccess(false);
            }}
          />
        </div>
      </CustomPopup>

      {/* Reactivate access */}
      <CustomPopup
        open={reactivateAccess}
        onHide={() => setReactivateAccess(false)}
        title="Reactivate access"
        title_color="black"
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Reactivate access"}
      >
        <div className="p-4 light-font-text">
          <div className="mb-4">
            Do you want to reactivate this users access to the system?
          </div>

          <div>User:</div>
          <div className="mb-4">{userName}</div>

          <div>By confirm, the user can log into the system again.</div>
        </div>

        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setReactivateAccess(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="CONFIRM"
            onClick={() => {
              handleUpdateUserStatus("unblock", "");
              setReactivateAccess(false);
            }}
          />
        </div>
      </CustomPopup>
      {/* Archive user */}
      {/*---------------- delete user popup --------------------*/}
      <CustomPopup
        open={deleteUserPopup}
        onHide={() => setDeleteUserPopup(false)}
        title="Delete user"
        title_color="black"
        size={"md"}
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Delete user"}
      >
        {" "}
        <div className="p-4 light-font-text my-2" style={{ width: "79%" }}>
          Are you sure you want to delete this user?
        </div>
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET?"
            onClick={() => {
              {
                setDeleteUserPopup(false);
              }
            }}
          />
          <ETButton
            variant={"primary"}
            buttonText="Delete"
            onClick={() => {
              handleArchiveUser(userId);
              setDeleteUserPopup(false);
            }}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default ActiveUsers;

const fontStyle = {
  fontSize: "13px",
  fontFamily: "Cerebri sans-light !important",
};
