import { Endpoint, Site } from "./API/Endpoints";
import { Get, Post, Put, Delete } from "./API/Method";

//Get Signer details
export const GetSignerDetails = (propertyId) => {
  propertyId =
    propertyId === "" || propertyId === null || propertyId === undefined
      ? 0
      : propertyId;
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Signing_Get_Signer_Details,
      null,
      propertyId + "/signing/signer"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Post Add signer
export const PostAddSigner = (addSignerPayload) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Signig_Post_Add_Signer, null, addSignerPayload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Delete signer
export const DeleteSigner = (userId) => {
  return new Promise((resolve, reject) => {
    Delete(Site.ET, Endpoint.ET.Signig_Delete_Signer, null, userId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Signer by signerId
export const GetSignerBySignerId = (propertyId, signerId) => {
  propertyId =
    propertyId === "" || propertyId === null || propertyId === undefined
      ? 0
      : propertyId;
  signerId =
    signerId === "" || signerId === null || signerId === undefined
      ? 0
      : signerId;
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Signing_Get_Signer_By_SignerId,
      null,
      propertyId + "/signing/" + signerId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Put Update signer
export const PutUpdateSigner = (updateSignerPayload) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Signing_Put_Update_Signer,
      null,
      updateSignerPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Signing setting by property Id
export const GetSigingSettingByPropertyId = (propertyId) => {
  propertyId =
    propertyId === "" || propertyId === null || propertyId === undefined
      ? 0
      : propertyId;

  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Signing_Get_SigningSetting_By_PropertyId,
      null,
      propertyId + "/signing/setting"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Put Update signing setting
export const PutUpdateSigningSetting = (updateSigningSetting) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Signing_Put_Update_SigningSetting,
      null,
      updateSigningSetting
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Signing order by contract Id
export const GetSigningOrderByContractId = (contract_id) => {
  contract_id =
    contract_id === "" || contract_id === null || contract_id === undefined
      ? 0
      : contract_id;

  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Signing_Get_SigningOrder_By_ContractId,
      null,
      contract_id
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Get Signing order detail by signingOrderId
export const GetSigningOrderDetailByOrderId = (signingOrderId) => {
  signingOrderId =
    signingOrderId === "" ||
      signingOrderId === null ||
      signingOrderId === undefined
      ? 0
      : signingOrderId;

  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Signing_Get_SigningDetail_By_SigningOrderId,
      null,
      signingOrderId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Post Cancel signing order
export const PostCancelSigningOrder = (cancelOrderPayload) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Signing_Post_Cancel_Signing_Order,
      null,
      cancelOrderPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Post Create signing order
export const PostCreateSigningOrder = (createSigningOrderPayload) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Signing_Post_Create_Signing_Order,
      null,
      createSigningOrderPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Put Update signing order
export const PutUpdateSigningOrder = (updateSigningorderPayload) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Signing_Put_Update_Signing_Order,
      null,
      updateSigningorderPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Put Update signing order
export const PostSigningSendToSigning = (sendToSigningPayload) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Signing_Post_SendToSigning,
      null,
      sendToSigningPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get contract overview by contract Id
export const GetContractOverviewByContractId = (contract_id) => {
  contract_id =
    contract_id === "" || contract_id === null || contract_id === undefined
      ? 0
      : contract_id;

  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Signing_Get_ContractOverview_By_ContractId,
      null,
      contract_id + "/overview"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Post Add extra signing order person
export const PostAddExtraSigningOrderPerson = (
  addSigningOrderPersonPayload
) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Signing_Post_AddExtraSigningOrderPerson,
      null,
      addSigningOrderPersonPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Signing type
export const GetSigningType = (property_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Signing_GetSigningType,
      null,
      property_id + "/packages/type"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

export const PutUpdateSigningQaApproveStatus = (
  updateSignerQaApprovePayload
) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Signing_Put_UpdateSigningQaApproveStatus,
      null,
      updateSignerQaApprovePayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Signing - UploadSigningOrderManualDocument
export const PostSigningOrderManualDocument = (formData) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Signing_PostSigningOrderManualDocument,
      { "Content-Type": "multipart/form-data" },
      formData
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Delete archive document
export const DeleteArchiveDocument = (
  contract_id,
  document_id,
  actor_id = 0,
  signing_order_id = 0
) => {
  return new Promise((resolve, reject) => {
    Delete(
      Site.ET,
      Endpoint.ET.Actor_DeleteArchiveDocument,
      null,
      contract_id +
      "/documents/" +
      document_id +
      "/actor/" +
      actor_id +
      "/signing/" +
      signing_order_id
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
