import React, { useState, useMemo, useEffect } from "react";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import { Checkedbox, UnCheckedbox } from "../../../../assets/icons/icons";
import { Col, Row } from "react-bootstrap";
import TagFilterDropDown from "../TagFilterDropDown";

import { InputAdornment } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";
import MRTTheme from "../../../../Components/table/MRTTheme";
import Menus from "../../../../Components/Menus/Menus";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../../Components/table/MRTGlobalProps";
import moment from "moment";
import { NavLink } from "react-router-dom";

const PropertyTagDummyTable = ({
  unitsTableData,
  setSelectedUnitsId,
  setUnitsTableData,
  tagUnitsTableData,
  dataContainer,
  rowSelection,
  setRowSelection,
  newData,
  originalTagData,
  setTagUnitsTableData,
  resetCondition,
  setResetCondition,
}) => {
  // console.log(">>", tagUnitsTableData);
  // const [rowSelection, setRowSelection] = useState([]);
  // const [resetCondition, setResetCondition] = useState(false);
  useEffect(() => {
    setSelectedUnitsId(Object.keys(rowSelection));
  }, [rowSelection]);

  // useEffect(() => {
  //   if (resetCondition == true) {
  //     setUnitsTableData(dataContainer);
  //   }
  // }, [resetCondition]);

  const handleSuffix = (floor) => {
    if (floor > 3 && floor < 21) return "th";
    switch (floor % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row?.calculated_unit_id}${row?.alt_unit_id}`,
        header: "Unit no.",
        enableHiding: false,
        size: 130,
        muiTableBodyCellProps: {},

        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <>
            <div>{row?.original?.calculated_unit_id}</div>
            <div>{row?.original?.alt_unit_id}</div>
          </>
        ),
      },
      {
        accessorFn: (row) =>
          `${row?.street_name}${row?.zipcode_id}${row?.house_number}${row?.zipcode_id}${row?.city}`,
        header: "Address",
        size: 180,
        enableGlobalFilter: true,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div style={{ cursor: "pointer" }}>
              {`${row?.original?.street_name},${row?.original?.house_number},${row?.original?.side_door}`}
            </div>
            <div>{`${row?.original?.zipcode_id} ${row?.original?.city}`}</div>
          </>
        ),
      },
      {
        accessorFn: (row) =>
          `${row?.no_of_rooms}${row?.floor_number}${row?.area}`,
        header: "Unit details",
        size: 180,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div className="d-flex justify-content-between">
              <div>{`${row?.original?.no_of_rooms}-room`}</div>{" "}
              <div>{`${row?.original?.floor_number}${handleSuffix(
                row?.original?.floor_number
              )} floor`}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-1">
                {row?.original?.area}{" "}
                <div
                  dangerouslySetInnerHTML={{
                    __html: row?.original?.square_measure_unit,
                  }}
                ></div>
              </div>

              <div
                style={{
                  width: "97px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  textAlign: "end",
                }}
              >
                {row?.original?.featured_facility == ""
                  ? "-"
                  : row?.original?.featured_facility}
              </div>
            </div>
          </>
        ),
      },
      {
        accessorFn: (row) => `${row?.rent}`,
        header: "Rent",
        size: 180,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
          sx: {},
        },
        Cell: ({ row }) => (
          <>
            <div className="d-flex justify-content-between">
              <div>
                Rent/
                {row?.original?.payment_frequency === 1
                  ? "mthly."
                  : row?.original?.payment_frequency === 3 && "qtrly."}
              </div>{" "}
              <div>{`${row?.original?.rent} ${row?.original?.currency_id}`}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                Rent/
                <div
                  dangerouslySetInnerHTML={{
                    __html: row?.original?.square_measure_unit,
                  }}
                ></div>
              </div>
              <div>
                {`${
                  row?.original?.area !== 0
                    ? Math.ceil(row?.original?.rent / row?.original?.area)
                    : 0
                } 
							${row?.original?.currency_id}`}
              </div>
            </div>
          </>
        ),
      },
      {
        accessorFn: (row) => `${row?.actor_name}${row?.date}`,
        header: "Contract",
        size: 240,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div>
              {row?.original?.actor_name == ""
                ? "-"
                : row?.original?.actor_name}
            </div>
            <div>
              {row?.original?.date == "" ? (
                "-"
              ) : (
                <div className="d-flex align-items-center">
                  <div>{row?.original?.date_text}</div>&nbsp;:&nbsp;
                  <div>{moment(row?.original?.date).format("D MMM, YYYY")}</div>
                </div>
              )}
            </div>
          </>
        ),
      },

      {
        accessorFn: (row) => `${row?.mainstatus_name}${row?.substatus_name}`,
        header: "Status",
        size: 100,
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            {/* <DropDown
							propertyId={propertyId}
							value={row?.original}
							getTableUnitsByPropertyId={getTableUnitsByPropertyId}
							setReservedToLeasedPage={setReservedToLeasedPage}
							setBecomeChampPage={setBecomeChampPage}
							setAvailableToReseverdPop={setAvailableToReseverdPop}
						/> */}
            <div
              style={{
                whiteSpace: "nowrap",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                fontFamily: "Cerebri sans-medium",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              <div
                className="fs-13"
                style={{
                  backgroundColor: `${row?.original?.mainstatus_background_color}`,
                  height: "18px",
                  padding: "0px 10px",
                  borderRadius: "4px 4px 0px 0px",
                  textTransform: "uppercase",
                  color: row?.original?.mainstatus_font_color,
                }}
              >
                {row?.original?.mainstatus_name}
              </div>
              <div
                className="fs-12"
                style={{
                  backgroundColor: row?.original?.substatus_background_color,
                  height: "18px",
                  padding: "0px 10px",
                  borderRadius: "0px 0px 4px 4px",
                  color: row?.original?.substatus_font_color,
                }}
              >
                {row?.original?.substatus_name}
              </div>
            </div>
          </>
        ),
      },
    ],
    []
  );

  const ChooseFilter = [
    { Id: "1", Text: "Unit No" },
    { Id: "2", Text: "Status" },
  ];

  const tags = [
    { Id: "1", Text: "Not active", parent: "2" },
    { Id: "2", Text: "Available", parent: "2" },
    { Id: "3", Text: "Reserved", parent: "2" },
    { Id: "4", Text: "In process", parent: "2" },
    { Id: "5", Text: "Completed", parent: "2" },
    { Id: "6", Text: "Available", parent: "2" },
    { Id: "7", Text: "Total", parent: "2" },
  ];

  const selectdata = [];
  const [filterDropdowon, setFilterDropdowon] = useState("");
  const [filterDropData, setFilterDropData] = useState("");
  const [selectedDropdownData, setSelectedDropDownData] = useState([]);
  const [show, setShow] = useState();
  const [status, setStatus] = useState(false);

  const dumnmyDropDownData = {
    "Unit No": "calculated_unit_id",
    Status: "tags",
  };
  const [propertag, setPropertytag] = useState("");
  const [filter, setFilter] = useState(null);

  const handleChange = (filter, ActionTypes) => {
    if (ActionTypes.action === "clear") {
      setFilter("");
    } else {
      setFilter(filter);
    }
    setPropertytag(true);
    setShow(false);

    let a = dumnmyDropDownData[filter?.label];
    let b = [...new Set(newData?.map((data) => data[a]))];
    setSelectedDropDownData(b);
    // setFilter(filter.label);
    setFilterDropdowon(ChooseFilter);
    const checkSelectedLabel = filter?.label;
    if (checkSelectedLabel == "Status") {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  const filteredTagdata = filter
    ? tags.filter((tag) => tag.parent === filter.value)
    : [];

  const filteredTag = selectedDropdownData;
  const [selecteddata, setSelectedData] = useState([]);
  const [closeDropdown, setCloseDropdown] = useState(false);

  const [filterKeys, setFilterKeys] = useState({
    calculated_unit_id: [],
    mainstatus_name: [],
  });

  // Function to push data into a specific filter key
  const pushDataToFilterKey = (key, value) => {
    if (filterKeys[key]?.includes(value)) {
    } else {
      setFilterKeys((prevFilterKeys) => ({
        ...prevFilterKeys,
        [key]: [...prevFilterKeys[key], value],
      }));
    }
  };

  const multiPropsFilter = (unitsTableData, filters) => {
    const filterKeys = Object?.keys(filters);
    const filterdata = originalTagData?.filter((product) => {
      return filterKeys?.every((key) => {
        if (!filters[key].length) return true;
        // Loops again if product[key] is an array (for material attribute).
        if (Array?.isArray(product[key])) {
          return product[key]?.some((keyEle) => filters[key]?.includes(keyEle));
        }
        return filters[key]?.includes(product[key]);
      });
    });
    // console.log(orignalData, filterdata, "orignalData1")
    setTagUnitsTableData(filterdata);
  };

  const handleFilterData = (i) => {
    const selectfilterdata = filter?.label;
    const parent = ChooseFilter.find(
      (filter) => filter.Id === selectfilterdata
    )?.parent;

    if (selectfilterdata === "Unit No") {
      const key = "calculated_unit_id";
      const value = i;
      pushDataToFilterKey(key, value);
    } else if (selectfilterdata === "Status") {
      const key = "mainstatus_name";
      const value = i;
      pushDataToFilterKey(key, value);
    } else {
    }

    setFilterDropData(i);

    selectdata.push({ lable: filterDropdowon, value: i });
    setSelectedData((prev) => [...prev, ...selectdata]);
  };

  // The forwardRef is important!!

  // Dropdown for filter
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      style={{
        backgroundColor: "#FFF",
        color: "#000",
        width: "100%",
        display: "flex",
        height: "41px",
        border: "1px solid #D1D1D1",
        fontWeight: "300",
        justifyContent: "space-between",
        alignItems: "center",
        textDecoration: "none",
        fontSize: "14px",
        borderRadius: "8px",
      }}
      className="px-2 py-2 dropdownInput "
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        setCloseDropdown(!closeDropdown);
      }}
    >
      <div className="d-flex justify-content-between w-100">
        <span> {children}</span>
        <span>
          <FontAwesomeIcon
            icon="fa-regular fa-chevron-down"
            size="sm"
            style={{ color: "#000000" }}
          />
        </span>
      </div>
    </a>
  ));
  // const renderEmptyRowsFallback = () => (
  //   <div className="d-flex justify-content-center w-100 grey-text py-4">
  //     <div>You do not have any unit(s) present.</div>
  //   </div>
  // );
  const renderEmptyRowsFallback = () => {
    if (tagUnitsTableData && tagUnitsTableData?.length === 0) {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div>You do not have any unit(s) present.</div>
          </>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div style={{ fontSize: "17px" }}>Sorry! No result found:(</div>
          </>
        </div>
      );
    }
  };

  return (
    <div>
      <MRTTheme
        colorVariantsForTableData={["odd", "white"]}
        table={
          <MaterialReactTable
            positionToolbarAlertBanner="bottom"
            selectAllMode="all"
            // muiTablePaginationProps={{
            //   rowsPerPageOptions: [10, 25, 50, 100, 250],
            // }}
            columns={columns}
            data={tagUnitsTableData ?? []}
            icons={{ ...SortIcon }}
            options={{
              rowStyle: { fontFamily: "Cerebri sans ", fontWeight: "300" },
            }}
            enableRowSelection
            renderEmptyRowsFallback={renderEmptyRowsFallback}
            getRowId={(row) => row.unit_id}
            onRowSelectionChange={setRowSelection}
            state={{ rowSelection }}
            renderRowActions={({ row }) => (
              <Menus
                itemheight={23}
                itemwidth={23}
                iconborderradius="4px"
                itemcontainerwidth="150px"
              />
            )}
            enableTopToolbar={true}
            enableColumnActions={false}
            positionActionsColumn="last"
            muiTableContainerProps={{
              sx: {
                padding: "10px 0px !important",
              },
            }}
            muiTablePaperProps={{
              elevation: 0,
              sx: {
                fontFamily: "Cerebri sans !important",
                borderRadius: "0",
                border: "none",
                background: "#FFFFFF",
                fontWeight: "300",
                ".css-1f2qhs8": {
                  minWidth: "auto",
                  marginBottom: "5px",
                },
                table: {
                  borderCollapse: "separate",
                  borderSpacing: "0 5px",
                },
                thead: {
                  // display: "none",
                },
                th: {
                  background: "#FFFFFF",
                  fontFamily: "Cerebri sans-medium !important",
                },
                td: {
                  fontFamily: "Cerebri sans-light !important",
                  fontSize: "14px",
                  borderRight: "1px solid #D1D1D1 !important",
                  borderImage:
                    "linear-gradient(to bottom, rgba(0,0,0,0) 25%,rgba(209,209,209) 25%,rgba(209,209,209) 75%,rgba(0,0,0,0) 75%) !important",
                  borderImageSlice: "1 !important",
                },
                tr: {
                  borderRadius: "4px",
                  background: "#F9FAFA",
                  margin: "4px",
                },
                "& td:nth-last-child(3)": {
                  borderRight: "none !important",
                },
                "& td:nth-last-child(2)": {
                  border: "none !important",
                  borderLeft: "1px solid #d1d1d1 !important",
                  backgroundColor: "#F0F0F0",
                },
                "& td:nth-last-child(1)": {
                  border: "none !important",
                },
              },
            }}
            initialState={{ showGlobalFilter: true }}
            muiSelectCheckboxProps={
              {
                // icon: <Unfalse
                // checkedIcon: <Checkedbox />,
                // color: "success",
              }
            }
            muiSearchTextFieldProps={{
              ...SearchStyle,

              InputProps: {
                ...SearchIcon,
              },
            }}
            muiTablePaginationProps={{
              ...paginationoptions,
            }}
            muiTableHeadRowProps={{
              sx: {
                ...TableRowsStyle,
              },
            }}
            renderTopToolbar={({ table }) => (
              <>
                <Row className=" py-2 px-2">
                  <Col xl={2} md={4} className="my-1 ps-1 pe-1 ">
                    <MRT_GlobalFilterTextField table={table} />
                  </Col>

                  <Col xl={2} md={4} className="my-1 ps-1 pe-1">
                    <Dropdown show={closeDropdown}>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-custom-components"
                      >
                        Set filters
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="my-drop-menu">
                        <TagFilterDropDown
                          propertag={propertag}
                          filteredTag={filteredTag}
                          filteredTagdata={filteredTagdata}
                          status={status}
                          setUnitsTableData={setUnitsTableData}
                          multiPropsFilter={multiPropsFilter}
                          selectedDropdownData={selectedDropdownData}
                          dumnmyDropDownData={dumnmyDropDownData}
                          filterDropdowon={filterDropdowon}
                          // handleDropData={handleDropData}
                          unitsTableData={unitsTableData}
                          filterDropData={filterDropData}
                          setFilterDropData={setFilterDropData}
                          ChooseFilter={ChooseFilter}
                          handleFilterData={handleFilterData}
                          filter={filter}
                          selecteddata={selecteddata}
                          setFilter={setFilter}
                          handleChange={handleChange}
                          selectdata={selectdata}
                          filterKeys={filterKeys}
                          setFilterKeys={setFilterKeys}
                          closeDropdown={closeDropdown}
                          setCloseDropdown={setCloseDropdown}
                          setResetCondition={setResetCondition}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </>
            )}
          />
        }
      />
    </div>
  );
};

export default PropertyTagDummyTable;
