import React, { useState } from "react";
import { H4, H5 } from "../../../../styles/Common.style";
import { Col, Row } from "react-bootstrap";
import StarRating from "../../../../Components/rating/StarRating";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import InputField from "../../../../Components/Textfield/InputField";
import TextAreaField from "../../../../Components/Textfield/TextAreaField";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import ETButton from "../../../../Components/Buttons/ETButton";
import { Controller, useForm } from "react-hook-form";

const FollowUpCustomer = ({
  followUpStatusDropDownData = [],
  followUpTaskCategoryDropDownData = [],
  handleCloseButton = () => {},
  handleSaveButton = () => {},
  disableButtonOnApiLoad = false,
}) => {
  //============= React hook form ===============//
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  //=================== states ==================//
  const [customerInterestRating, setCustomerInterestRating] = useState(0);
  const [customerConversionRating, setCustomerConversionRating] = useState(0);

  //============= handle close popup ============//
  const handleClose = () => {
    handleCloseButton();
  };

  //=============== handle save ================//
  const handleSave = (data) => {
    const newData = {
      ...data,
      rating_interest: customerInterestRating,
      rating_conversion: customerConversionRating,
    };
    handleSaveButton(newData);
  };
  return (
    <div>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="p-4 border-bottom">
          <H4>Follow up on the customer</H4>
          <div className="light-font-text c-black w-75 py-2">
            Here you can enter information about the customer that may be
            relevant to others or for later follow-up on the customer.
            <br /> The information you enter is visible to all other users in
            the system who have access rights to the property or unit.
          </div>
          <div className="light-font-text w-75">
            However, follow up date and note are only visible to you under My
            Activities.
          </div>
        </div>
        <div className="border-bottom p-4">
          <Row>
            <Col>
              <div>
                <H4 className="mb-2">Rating</H4>
                <div className="light-font-text mb-3 ">
                  Rate the customer's interest in the property or unit?
                  <StarRating
                    setFavoriteRating={setCustomerInterestRating}
                    favoriteRating={customerInterestRating}
                  />
                </div>
                <div className="light-font-text">
                  Rate potential customer conversions
                  <StarRating
                    setFavoriteRating={setCustomerConversionRating}
                    favoriteRating={customerConversionRating}
                  />
                </div>
              </div>
            </Col>
            <Col>
              <div>
                <H4 className="mb-2">Follow up status</H4>
                <div className="light-font-text mb-2">
                  Set a current status on this customer
                </div>
                <Row>
                  <Col lg={8}>
                    <Controller
                      control={control}
                      {...register("followUpStatus")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder="Current status"
                          options={followUpStatusDropDownData?.map((item) => ({
                            value: item?.Id,
                            label: item?.Text,
                            isDisabled: value?.value === item?.Id,
                          }))}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("followUpStatus");
                          }}
                          iserror={errors?.followUpStatus}
                          errorScope
                          clearable={false}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <div className="border-bottom p-4">
          {" "}
          <H4 className="mb-2">Follow up task</H4>
          <div className="light-font-text mb-2">
            Let us help you remember to follow up on this customer. The system
            create a personalised reminder and note in My Activity to you.
          </div>
          <Row>
            <Col sm={3} className="pe-0">
              <Controller
                control={control}
                {...register("followUpDate")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <InputField
                    id={"followUpDate"}
                    placeholder={"Follow up date"}
                    type={"date"}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("followUpDate");
                    }}
                    iserror={errors?.followUpDate}
                    errorscope
                  />
                )}
              />
            </Col>
            <Col sm={4} className="pe-0">
              <Controller
                control={control}
                {...register("followUpTaskCategory")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder={"Follow up task category"}
                    options={followUpTaskCategoryDropDownData?.map((item) => ({
                      value: item?.Id,
                      label: item?.Text,
                      isDisabled: value?.value === item?.Id,
                    }))}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("followUpTaskCategory");
                    }}
                    iserror={errors?.followUpTaskCategory}
                    errorScope
                    clearable={false}
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={7} className="mt-3 pe-0">
              <Controller
                control={control}
                {...register("followUpNote")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <TextAreaField
                    resize={"none"}
                    placeholder={"Follow up note"}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("followUpNote");
                    }}
                    iserror={errors?.followUpNote}
                    errorscope
                  />
                )}
              />
            </Col>
          </Row>
        </div>
        <div className="p-4">
          <H4 className="mb-2">Heard about the property </H4>
          <div className="light-font-text mb-4 w-50">
            To better track your marketing and ensure that the advertising used
            has the right targeting, you can specify here where the customer has
            heard about the project or unit.
          </div>
          <H5>Medias and campaigns</H5>
          <div className="w-75">
            <Row>
              <Col sm={3}>
                {" "}
                <CustomCheckBox label={"Social Media"} />{" "}
              </Col>
              <Col sm={3}>
                {" "}
                <CustomCheckBox label={"Outdoor marketing"} />{" "}
              </Col>
              <Col sm={2}>
                {" "}
                <CustomCheckBox label={"Website"} />{" "}
              </Col>
              <Col sm={2}>
                {" "}
                <CustomCheckBox label={"Newspaper"} />{" "}
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                {" "}
                <CustomCheckBox label={"Instagram"} />{" "}
              </Col>
              <Col sm={3}>
                {" "}
                <CustomCheckBox label={"Facebook"} />{" "}
              </Col>
              <Col sm={2}>
                {" "}
                <CustomCheckBox label={"Google Ads"} />{" "}
              </Col>
              <Col sm={2}>
                {" "}
                <CustomCheckBox label={"Newsletter"} />{" "}
              </Col>
            </Row>
          </div>
        </div>

        <div
          className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between
          justify-content-center footer-section-button-container"
        >
          <ETButton
            variant={"white"}
            buttonText="CLOSE"
            type="button"
            onClick={() => {
              handleClose();
            }}
            disabled={disableButtonOnApiLoad}
          />
          <ETButton
            type="submit"
            variant={"primary"}
            buttonText="SAVE"
            disabled={disableButtonOnApiLoad}
          />
        </div>
      </form>
    </div>
  );
};

export default FollowUpCustomer;
