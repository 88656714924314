import React from "react";
import { TimeSlotTab } from "../../../Components/Tabs/CustomersTabs.style";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../../Components/Buttons/ETButton";

const TimeSlot = () => {
  return (
    <div>
      <div className="pt-2 pb-3 px-4">
        <div className="   form-label-title">Time slot</div>
        <div className="light-font-text grey-text">
          If you want to change the participant's slot time, you can select a
          new one below. If you change the time, a confirmation email will be
          sent to the participant.
        </div>
      </div>
      <div className="pt-2 pb-3 px-4">
        <div className="   form-label-title">Max Wildersans</div>
        <Row>
          <Col lg={2} md={3} sm={4} className="py-1">
            <TimeSlotTab>10:15 - 10:25</TimeSlotTab>
          </Col>
          <Col lg={2} md={3} sm={4} className="py-1">
            <TimeSlotTab>10:15 - 10:25</TimeSlotTab>
          </Col>
        </Row>
      </div>

      <div className="pt-2 pb-3 px-4">
        <div className="   form-label-title">Julie Andersson</div>
        <Row>
          <Col lg={2} md={3} sm={4} className="py-1">
            <TimeSlotTab mode={true}>10:15 - 10:25</TimeSlotTab>
          </Col>
          <Col lg={2} md={3} sm={4} className="py-1">
            <TimeSlotTab>10:15 - 10:25</TimeSlotTab>
          </Col>
          <Col lg={2} md={3} sm={4} className="py-1">
            <TimeSlotTab>10:45 - 10:55</TimeSlotTab>
          </Col>
        </Row>
      </div>

      <div className="pt-2 pb-3 px-4">
        <div className="   form-label-title">Donald Junior McBeth</div>
        <Row>
          <Col lg={2} md={3} sm={4} className="py-1">
            <TimeSlotTab>10:15 - 10:25</TimeSlotTab>
          </Col>
          <Col lg={2} md={3} sm={4} className="py-1">
            <TimeSlotTab>10:15 - 10:25</TimeSlotTab>
          </Col>
          <Col lg={2} md={3} sm={4} className="py-1">
            <TimeSlotTab>10:45 - 10:55</TimeSlotTab>
          </Col>
        </Row>
      </div>
      <div className="pt-2 pb-3 px-4">
        <div className="   form-label-title">Adam Sandler</div>
        <Row>
          <Col lg={2} md={3} sm={4} className="py-1">
            <TimeSlotTab>10:15 - 10:25</TimeSlotTab>
          </Col>
          <Col lg={2} md={3} sm={4} className="py-1">
            <TimeSlotTab>10:15 - 10:25</TimeSlotTab>
          </Col>
          <Col lg={2} md={3} sm={4} className="py-1">
            <TimeSlotTab>10:30 - 10:40</TimeSlotTab>
          </Col>
          <Col lg={2} md={3} sm={4} className="py-1">
            <TimeSlotTab>10:45 - 10:55</TimeSlotTab>
          </Col>
          <Col lg={2} md={3} sm={4} className="py-1">
            <TimeSlotTab>10:45 - 10:55</TimeSlotTab>
          </Col>
          <Col lg={2} md={3} sm={4}>
            {" "}
            <TimeSlotTab className="py-1">10:45 - 10:55</TimeSlotTab>
          </Col>{" "}
          <Col lg={2} md={3} sm={4} className="py-1">
            <TimeSlotTab>10:45 - 10:55</TimeSlotTab>
          </Col>
        </Row>
      </div>

      <div className="mt-1 py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
        {" "}
        <ETButton
          variant={"white"}
          buttonText="CANCEL"
          // type={"button"}
          // onClick={() => setCancelPop(true)}
        />
        <div className=" d-flex ">
          {/* {hideBtn == false && ( */}
          <div className="px-2">
            {" "}
            <ETButton
              buttonText="DELETE PARTICIPANT"
              variant={"white"}
              icon={true}
              width="100%"
              type={"submit"}
            />
          </div>
          {/* )} */}

          <div className="px-1">
            {" "}
            <ETButton
              variant={"primary"}
              buttonText="CONFIRM"
              type={"submit"}
              // onClick={() => setHideBtn(true)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeSlot;
