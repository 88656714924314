import React from "react";
import { H3 } from "../../../../styles/Common.style";
import Accordion from "../../../../Components/Accordion/Accordion";
import { screeningMessage, welcomeMessage } from "../../StaticData";
import ETButton from "../../../../Components/Buttons/ETButton";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import SuperEditor from "@ckeditor/ckeditor5-build-classic";
import { Image } from "react-bootstrap";

const Text = () => {
  return (
    <div>
      {" "}
      <div className="p-4 pt-1 border-bottom">
        <H3 className="pb-4">Welcome message</H3>

        {welcomeMessage?.map((item, index) => (
          <div key={item?.id} className="pb-2">
            <Accordion
              bgColor={"#F7F8FA"}
              accordianHeight={"75px"}
              accordianBorder={false}
              alwaysOpen={index == 0 ? true : false}
              title={<div className="form-label-title">{item?.category}</div>}
              contentBody={
                <>
                  <div className="p-4">
                    <div className="d-flex justify-content-end px-4 ">
                      <ETButton
                        variant={"primary"}
                        buttonText="ADD LANGUAGE VERSION"
                        icon={true}
                        width="fit-content"
                      />
                    </div>
                    <div className="p-4">
                      <div className="light-font-text d-flex align-items-center gap-2 pb-3">
                        Danish
                        <Image
                          src={
                            "https://millicent-test.b-cdn.net/assets/flags/dk.svg"
                          }
                          width={"18px"}
                          height={"12px"}
                          style={{ borderRadius: "4px" }}
                        />{" "}
                      </div>
                      <div>
                        <CKEditor
                          id={item?.id}
                          // onReady={(editor) => {
                          //   // editor.focus();
                          //   editor.editing.view.focus();

                          //   // editor.setIdToScroll(data?.Id);
                          // }}
                          focusedElement={true}
                          editor={SuperEditor}
                          // data={data?.description}
                          // onChange={(event, editor) => {
                          //   handleChangeDescription(
                          //     index,
                          //     data,
                          //     editor?.getData()
                          //   );
                          // }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              }
            />
          </div>
        ))}
      </div>
      <div className="p-4 pt-3 border-bottom">
        <H3 className="pb-4">Screening message</H3>

        {screeningMessage?.map((item) => (
          <div key={item?.id} className="pb-2">
            <Accordion
              bgColor={"#F7F8FA"}
              accordianHeight={"75px"}
              accordianBorder={false}
              title={<div className="form-label-title">{item?.category}</div>}
              contentBody={
                <>
                  <div className="p-4">
                    <div className="d-flex justify-content-end px-4">
                      <ETButton
                        variant={"primary"}
                        buttonText="ADD LANGUAGE VERSION"
                        icon={true}
                        width="fit-content"
                      />
                    </div>
                    <div className="p-4">
                      <div className="light-font-text d-flex align-items-center gap-2 pb-3">
                        Danish
                        <Image
                          src={
                            "https://millicent-test.b-cdn.net/assets/flags/dk.svg"
                          }
                          width={"18px"}
                          height={"12px"}
                          style={{ borderRadius: "4px" }}
                        />{" "}
                      </div>
                      <div>
                        <CKEditor
                          id={item?.id}
                          // onReady={(editor) => {
                          //   // editor.focus();
                          //   editor.editing.view.focus();

                          //   // editor.setIdToScroll(data?.Id);
                          // }}
                          focusedElement={true}
                          editor={SuperEditor}
                          // data={data?.description}
                          // onChange={(event, editor) => {
                          //   handleChangeDescription(
                          //     index,
                          //     data,
                          //     editor?.getData()
                          //   );
                          // }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Text;

SuperEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};
