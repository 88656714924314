import React, { useEffect, useState } from "react";
import "./Sorting.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SortingFunction = ({
  data = [],
  setData,
  sortingByName = "media_package_name", //this props use for Ascending and Descending specific key
  sortById = "media_file_id", //this props use for Newly added and Oldest specific key used in f
}) => {
  const [filter, setFilter] = useState("Newly added");
  const ChooseFilter = [
    { Id: "1", Text: "Ascending", icon_name: "fa-regular fa-arrow-down-a-z" },
    { Id: "2", Text: "Descending", icon_name: "fa-regular fa-arrow-down-z-a" },
    { Id: "3", Text: "Newly added", icon_name: "fa-regular fa-arrow-down-9-1" },
    // { Id: "4", Text: "Oldest", icon_name: "fa-regular fa-arrow-down-1-9" },
    {
      Id: "5",
      Text: "Recently updated",
      icon_name: "fa-regular fa-arrow-down-9-1",
    },
    // {
    //   Id: "6",
    //   Text: "Oldest updated",
    //   icon_name: "fa-regular fa-arrow-down-1-9",
    // },
  ];
  const handleChange = (text) => {
    // console.log(text, "test");
    setFilter(text);
  };
  useEffect(() => {
    if (filter == "Ascending") {
      const ascending = [...data].sort((a, b) =>
        a[sortingByName] > b[sortingByName] ? 1 : -1
      );
      setData(ascending);
    }
    if (filter == "Descending") {
      const descending = [...data]?.sort((a, b) =>
        a[sortingByName] > b[sortingByName] ? -1 : 1
      );
      setData(descending);
    }
    if (filter == "Newly added") {
      const newest = [...data]?.sort((a, b) => b[sortById] - a[sortById]);
      setData(newest);
    }
    if (filter == "Oldest") {
      const oldest = [...data]?.sort((a, b) => a[sortById] - b[sortById]);
      setData(oldest);
    }
    if (filter == "Recently updated") {
      const res = [...data]?.sort(
        (a, b) =>
          b?.modified_on
            .split(" ")[0]
            ?.localeCompare(a?.modified_on.split(" ")[0]) ||
          b?.modified_on
            .split(" ")[1]
            ?.localeCompare(a?.modified_on.split(" ")[1])
      );
      setData(res);
      // console.log(res, "rgergrgr");
    }
    if (filter == "Oldest updated") {
      const res = [...data]?.sort(
        (a, b) =>
          a?.modified_on
            .split(" ")[0]
            ?.localeCompare(b?.modified_on.split(" ")[0]) ||
          a?.modified_on
            .split(" ")[1]
            ?.localeCompare(b?.modified_on.split(" ")[1])
      );
      setData(res);
      // console.log(res, "rgergrgr");
    }
  }, [filter]);
  // console.log(data, "datadatadata");
  return (
    <div className="d-flex justify-content-end fs-14">
      <div className="d-flex ">
        <div class="sorting-container">
          <div class="nav-items p-1">
            <div class="nav-item nav-item-dropdown">
              <div class="dropdown-trigger fs-14 d-flex justify-content-between align-items-center">
                <div className="light-font-text">
                  Sort by :
                  <span className="px-2 form-label-title">{filter}</span>
                </div>
                <FontAwesomeIcon
                  icon="fa-solid fa-chevron-down"
                  style={{ color: "#000000" }}
                />
              </div>
              <div class="dropdown-menu">
                <div class="dropdown-menu-item">
                  <div>
                    {ChooseFilter.map((e) => (
                      <div
                        class="fs-14 px-2 py-1 d-flex gap-2 align-items-center option-hover justify-content-between "
                        onClick={() => handleChange(e.Text)}
                      >
                        {e.Text}{" "}
                        <FontAwesomeIcon
                          icon={e?.icon_name}
                          style={{ color: "#000000" }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SortingFunction;
