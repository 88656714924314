// Created by : Suraj
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "./card.css";
import Menus from "../Menus/Menus";
import { Col, Image, Row } from "react-bootstrap";
import photoComingSoon from "../../assets/images/photo-coming-soon.jpg";
import moment from "moment";
import ShowTooltip from "../Tooltip/ShowTooltip";
import { H5, NormalNotification } from "../../styles/Common.style";

const GuideCard = ({
  cardData,
  handleEditGuide = () => {},
  handlePublishGuide = () => {},
  showArchiveGuideWithId = () => {},
}) => {
  console.log("🚀 ~ file: GuideCard.js:18 ~ cardData:", cardData);
  // // --------- all const and functions ----------------//
  const publishedConditionText = cardData?.published_condition_text;
  const contractStatusView = publishedConditionText.split(",");

  // console.log(contractStatusView, "contractStatusView");

  return (
    <>
      <CardsDiv>
        {/* white window */}
        <div style={cardSection}>
          <div style={detailOverviewContainer}>
            <Image
              alt="myGuidImage"
              style={{ width: "100%", height: "100%" }}
              src={
                cardData?.image_url !== ""
                  ? cardData?.image_url
                  : photoComingSoon
              }
            />
          </div>
        </div>

        {/*Screening name  */}
        <div style={cardSection}>
          <div className="form-label-title fs-16 pt-1">{cardData?.title}</div>
        </div>

        {/* Status section */}
        <div style={cardSection}>
          <div className="p-1 d-flex gap-3 align-items-center">
            <div className="form-label-title">Status</div>
            <div>
              <span
                className="fs-12 form-label-title white-text  radius-4"
                style={{
                  backgroundColor:
                    cardData?.status == "2" ? "#349032" : "#888888",
                  height: "19px",
                  padding: "2px 6px 2px 6px",
                  color: "#FFF",
                  textTransform: "uppercase",
                }}
              >
                {cardData?.status == "1"
                  ? "DRAFT"
                  : cardData?.status == "2"
                  ? "PUBLISHED"
                  : cardData?.status == "3"
                  ? "UNPUBLISHED"
                  : "ARCHIVED"}
              </span>
            </div>
          </div>
        </div>

        {/* Source */}
        <div className="fs-14 px-4 pt-3 ">
          <div className="form-label-title">Automatic publication</div>
          <div className="light-font-text ">
            <span className="grey-text">
              {cardData?.published_condition_type_text === "Date/time"
                ? "Date"
                : cardData?.published_condition_type_text}
              :
            </span>{" "}
            <span>
              {cardData?.published_condition_type == 1 &&
              cardData?.published_condition_text
                ? moment(cardData?.published_condition_text).format(
                    "DD MMM, YYYY "
                  )
                : // +
                  // " at " +
                  // moment(cardData?.automatic_publication_value).format(
                  //   "HH:mm:ss"
                  // )
                  "-"}
              {cardData?.published_condition_type == 2 && (
                <>
                  <span
                    className="ellipsis"
                    id="contractStatus"
                    style={{ width: "95%", paddingLeft: "2px" }}
                  >
                    {contractStatusView?.[0]}
                  </span>
                  {contractStatusView?.length > 1 && (
                    <ShowTooltip
                      tooltopWidth={300}
                      arrow={false}
                      tooltipLabel={` (+${contractStatusView?.length - 1})`}
                      tooltipContent={
                        <div>
                          <div className="py-2 px-3 border-bottom">
                            <H5>Contract status</H5>
                          </div>
                          <div className="p-3">
                            {" "}
                            {cardData?.published_condition_text}
                          </div>
                        </div>
                      }
                    />
                  )}
                </>
                // <YourComponent
                //   data={contractStatusView}
                //   id={cardData?.document_guide_id}
                // />
              )}
            </span>
          </div>
        </div>

        {/*Automatic removal  */}
        {/* <div className="fs-14 px-4 pt-3 ellipsis" style={{ width: "95%" }}>
        <div className="form-label-title">Automatic removal</div>
        <div className="light-font-text ellipsis">
          <span className="grey-text">Contract status:</span>{" "}
          <span> Screening approved (+2)</span>
        </div>
      </div> */}

        {/* tags */}
        <div
          className="fs-14 px-4 pt-3 ellipsis"
          style={{ width: "95%" }}
        >
          <div className="form-label-title">Tags in use</div>
          <div className="light-font-text ellipsis">
            {cardData?.tag_to_use?.map((item, index, array) => (
              <React.Fragment key={index}>
                {index <= 1 && (
                  <span>
                    {item?.name}
                    {index === array.length - 1 ? "" : ", "}
                  </span>
                )}
                {index == 2 && item?.name.slice(0, 4) + "..."}
              </React.Fragment>
            ))}
            {cardData?.tag_to_use?.length > 2 && (
              <ShowTooltip
                tooltopWidth={600}
                arrow={false}
                tooltipLabel={
                  <NormalNotification>
                    <span style={{ fontWeight: "bold" }}>
                      + {cardData?.tag_to_use?.length - 2}
                    </span>
                  </NormalNotification>
                }
                tooltipContent={
                  <TagsDetailCard
                    data={cardData?.tag_to_use}
                    lastUpdatedText={cardData?.modified_on}
                  />
                }
              />
            )}
          </div>
        </div>

        <div className="d-flex justify-content-between ps-4 pe-3 pt-3">
          {/* Published on */}
          <div className="fs-14 ">
            <div className="form-label-title">Published on</div>
            <div className="light-font-text">{cardData?.unit_count} units</div>
          </div>
        </div>

        {/* Menu options */}
        <div className="d-flex pb-3 px-4 justify-content-between pt-3">
          <div className="fs-12  pt-1 ">
            <div className="fs-12  form-label-title">Created </div>
            <div className="created-by">
              {cardData?.created_on
                ? moment(cardData?.created_on).format("DD MMM, YYYY ") +
                  " at " +
                  moment(cardData?.created_on).format("HH:mm:ss")
                : "-"}
            </div>
            <div className="fs-12 pt-1 form-label-title">Created by</div>
            <div className="created-by">{cardData?.created_by}</div>
          </div>

          <div className="d-flex align-items-end">
            <Menus
              component={
                <>
                  <div className="light-font-text">
                    <div
                      className="addpropContent px-3 py-2 "
                      onClick={() =>
                        handleEditGuide(cardData?.document_guide_id)
                      }
                    >
                      <span className="ms-1">Edit guide</span>
                    </div>
                    <hr className=" m-0"></hr>
                    <div
                      className="addpropContent px-3 py-2 "
                      onClick={() =>
                        handlePublishGuide(
                          cardData?.document_guide_id,
                          cardData?.status
                        )
                      }
                    >
                      <span className="ms-1">
                        {cardData?.status == "2"
                          ? "Unpublish guide"
                          : "Publish guide"}
                      </span>
                    </div>
                    <hr className="m-0"></hr>

                    <div
                      className="addpropContent px-3 pt-2 pb-2 "
                      onClick={() =>
                        showArchiveGuideWithId(cardData?.document_guide_id)
                      }
                    >
                      <span className="ms-1">Archive</span>
                    </div>
                  </div>
                </>
              }
            />
          </div>
        </div>
      </CardsDiv>
    </>
  );
};

export default GuideCard;

const TagsDetailCard = ({ data, lastUpdatedText }) => {
  console.log(data, "tagsDta");
  return (
    <div>
      <div className="p-3 border-bottom d-flex justify-content-between">
        <H5>Tags in use</H5>
        <div className="light-font-text fs-12">
          Last status change:{" "}
          {lastUpdatedText
            ? moment(lastUpdatedText).format("DD MMM, YYYY ") +
              " at " +
              moment(lastUpdatedText).format("HH:mm:ss")
            : "-"}
        </div>
      </div>
      <div className="p-3">
        <Row className="form-label-title fs-12">
          <Col xs={5}>
            <div>Tag</div>
          </Col>
          <Col xs={3}>
            <div>Property</div>
          </Col>
          <Col xs={4}>
            <div>Added</div>
          </Col>
        </Row>
        {data?.map((item, i) => {
          return (
            <Row
              key={i}
              className="light-font-text fs-12"
            >
              <Col xs={5}>
                <div>{item?.name}</div>
              </Col>
              <Col xs={3}>
                <div>{item?.property_name}</div>
              </Col>
              <Col xs={4}>
                <div>{item?.added}</div>
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
  );
};

const CardsDiv = styled.div`
  background-color: #f9fafa;
  border-color: #f0f0f0;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  ${(props) =>
    props.isActive &&
    `
  background-color:${props.theme.component.checkbox.primary.color + "11"};
   border-color: ${props.theme.component.checkbox.primary.color};
`}
`;
const cardSection = {
  padding: "20px",
  borderBottom: "1px solid #F0F0F0",
};

const detailOverviewContainer = {
  border: "1px solid #F0F0F0",
  borderRadius: "6px",
  fontSize: "13px",
  fontFamily: "Cerebri sans-light",
  background: "#FFFFFF",
  textAlign: "justify",
  height: "150px",
  lineHeight: "17px",
  position: "relative",
  width: "100%",
  overflow: "hidden",
};

const YourComponent = ({ data, id }) => {
  const [visibleItems, setVisibleItems] = useState([]);
  const [extraCount, setExtraCount] = useState(0);

  const calculateVisibleItems = () => {
    const containerWidth = document.getElementById(id)?.offsetWidth;
    const itemWidth = 120; // Adjust this based on your actual item width

    if (containerWidth && data.length > 0) {
      const maxVisibleItems = Math.floor(containerWidth / itemWidth);
      setVisibleItems(data.slice(0, maxVisibleItems));
      setExtraCount(Math.max(0, data.length - maxVisibleItems));
      // console.log(
      //   Math.max(0, data.length - maxVisibleItems),
      //   containerWidth,
      //   "extraCount"
      // );
    }
  };

  useEffect(() => {
    calculateVisibleItems();
    window.addEventListener("resize", calculateVisibleItems);

    return () => {
      window.removeEventListener("resize", calculateVisibleItems);
    };
  }, [data]);

  return (
    <div
      id={id}
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {Array.isArray(visibleItems) &&
        visibleItems.length > 0 &&
        visibleItems.join(", ")}
      {extraCount > 0 && `, +${extraCount} more`}
    </div>
  );
};
