// created by Suraj
import React, { useMemo, useState } from "react";
import ETButton from "../../../../../Components/Buttons/ETButton";
import MRTTheme from "../../../../../Components/table/MRTTheme";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import {
  SearchIcon,
  SearchStyle,
  paginationoptions,
  SortIcon,
} from "../../../../../Components/table/MRTGlobalProps";
import { H4 } from "../../../../../styles/Common.style";
import { useGetPropertiesByLevel } from "../../../../../hooks/queries/usePropertyQueries";
import { useOrganizationId } from "../../../../../hooks/stores/useOrganizationStore";
import { Form } from "react-bootstrap";
import { useDivisionId } from "../../../../../hooks/stores/useDivisionsStore";

const AssignProperties = ({
  setClosePopup,
  goBack,
  flag,
  usedFor = "users",
  assignUsersToTeamData,
  handleAssignPropertiesToTeam = () => { },
  handlePreviousPropertyScreens = () => { },
  handleCreateNewTeam = () => { },
  // handleCreateNewTeam,
  rowSelection,
  setRowSelection,
  addTeamLoading = false,
  updateTeamLoading = false,
  viewAssignedPropertyData,
  assignPropertiesToTeamsLoading = false
}) => {
  const [sorting, setSorting] = useState([]);
  //-----------------zustand-------------------------------//
  // const { divisionId } = useDivisionId((state) => state);

  //--------------------------react query-------------------//
  // const { data: GetPropertiesByLevelData } = useGetPropertiesByLevel(
  //   "d",
  //   divisionId,
  //   // 10001,
  //   "details",
  //   "all"
  // );

  const handleSucess = () => {
    const selectedPropertyId = Object?.keys(rowSelection);
    handleAssignPropertiesToTeam(rowSelection);
    handleCreateNewTeam(selectedPropertyId);
  };
  const handlePrevious = () => {
    handlePreviousPropertyScreens();
  };
  // below code for table columns
  const columns = useMemo(
    () => [
      {
        accessorKey: "property_name",
        header: "Property name",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div>
            {row.original?.property_name ? row.original?.property_name : "-"}
          </div>
        ),
        muiTableHeadCellProps: {
          sx: {
            paddingLeft:
              usedFor !== "viewAssignedProperties"
                ? "0px !important"
                : "16px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft:
              usedFor !== "viewAssignedProperties"
                ? "0px !important"
                : "16px !important",
          },
        },
      },
      {
        accessorFn: (row) => `${row.city_name} ${row.zipcode_id}`,
        // accessorKey: "address",
        header: "Address",
        enableHiding: false,
        size: 140,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div>
            {row.original?.city_name || row.original?.zipcode_id ? (
              <>
                {row.original?.city_name},{row.original?.zipcode_id}
              </>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        accessorKey: "property_type",
        header: "Listing type",
        enableHiding: false,
        size: 80,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>
            {row.original?.property_type ? row.original?.property_type : "-"}{" "}
          </div>
        ),
      },
      {
        accessorKey: "unit_count",
        header: "Number of units",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
        // muiTableHeadCellProps: {
        //   align: "right",
        // },
        // muiTableBodyCellProps: {
        //   align: "right",
        // },
        Cell: ({ row }) => (
          <div>
            {row?.original?.unit_count == 1 ? (
              <>{row.original?.unit_count} unit</>
            ) : row?.original?.unit_count == 0 ? (
              <>No units</>
            ) : (
              <>{row.original?.unit_count} units</>
            )}
          </div>
        ),
      },
      {
        accessorKey: "active_user",
        header: "Active users",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: false,
        // muiTableHeadCellProps: {
        //   align: "right",
        // },
        // muiTableBodyCellProps: {
        //   align: "right",
        // },
        Cell: ({ row }) => (
          <div>
            {row.original?.active_user ? row.original?.active_user : "-"}
            {/* {row.original?.created_on
                  ? moment(row.original?.created_on, "YYYY-MM-DD HH:mm:ss").format(
                      "DD MMM, YYYY"
                    )
                  : "0"} */}
          </div>
        ),
      },
    ],
    []
  );
  const renderEmptyRowsFallback = () => {
    if (assignUsersToTeamData && assignUsersToTeamData?.length === 0) {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div>You do not have any properties.</div>
          </>
        </div>
      );
    } else {
      return (
        <div
          className="d-flex justify-content-center w-100 grey-text py-3 radius-4"
          style={{ border: "1px solid #d1d1d1", backgroundColor: "#F9FAFA" }}
        >
          <>
            <div className="fs-14">
              Sorry! No result found. Please try again!
            </div>
          </>
        </div>
      );
    }
  };
  return (
    <div>
      <div
        style={{ height: "450px", overflowY: "scroll" }}
        className="scrollable-container-y  p-4 me-1"
      >
        {/* AssignProperties */}
        <div className="">
          <H4 className="form-label-title">
            {usedFor == "teams"
              ? "Which properties should be assigned to the team?"
              : usedFor == "viewAssignedProperties"
                ? "Assigned properties to the team"
                : "Which properties should the user have access to?"}
          </H4>
          {usedFor == "teams" ? (
            <div className="light-font-text pt-3">
              <div>
                Below is a list of all properties created in divisions the team
                is a part of. Select which properties you want the team to have
                access to.
              </div>
              <div>
                {" "}
                All users in the team will automatically have access to alle
                properties assign to the team.
              </div>
            </div>
          ) : usedFor == "viewAssignedProperties" ? (
            <div className="light-font-text pt-3">
              Below is a list of all properties assigned to the team. To select
              which properties you want the team to have access to, please go to
              Edit team.
            </div>
          ) : (
            <div className="light-font-text pt-3">
              Below is a list of all properties created in the selected
              divisions. Select which properties you want the user to have
              access to.
            </div>
          )}
        </div>

        {/* table sections */}
        <div className="px-0">
          <MRTTheme
            colorVariantsForTableRow={["gray"]}
            colorVariantsForTableHead={["white"]}
            table={
              <MaterialReactTable
                columns={columns}
                icons={{
                  ArrowDownwardIcon: () => SortIcon?.ArrowDownwardIcon(sorting),
                  SyncAltIcon: () => SortIcon?.SyncAltIcon(),
                }}
                data={usedFor == "viewAssignedProperties" ? viewAssignedPropertyData ?? [] : assignUsersToTeamData ?? []} //data is undefined on first render
                renderEmptyRowsFallback={renderEmptyRowsFallback}
                enableFilterMatchHighlighting={true}
                enableGlobalFilter
                globalFilterFn="contains"
                enableColumnActions={false}
                enableBottomToolbar={true}
                muiSearchTextFieldProps={{
                  ...SearchStyle,

                  InputProps: {
                    ...SearchIcon,
                  },
                }}
                getRowId={(originalRow) => originalRow?.property_id}
                onRowSelectionChange={setRowSelection}
                state={{ rowSelection, sorting: sorting }}
                onSortingChange={setSorting}
                //give each row a more useful id
                enableSelectAll={true}
                enableRowSelection={
                  usedFor == "viewAssignedProperties" ? false : true
                }
                enableRowActions={false}
                displayColumnDefOptions={{
                  "mrt-row-select": {
                    header: "",
                    size: 50,
                    muiTableHeadCellProps: {
                      sx: {
                        paddingRight: "0px !important",
                      },
                    },
                    muiTableBodyCellProps: {
                      sx: {
                        paddingRight: "0px !important",
                      },
                    },
                  },
                }}
                muiTablePaperProps={{
                  sx: {
                    backgroundColor: "transparent",
                    table: {
                      borderCollapse: "separate",
                      borderSpacing: "0 5px",
                    },
                  },
                }}
                renderTopToolbar={({ table }) => (
                  <>
                    <div className="pb-2">
                      <div className="mb-1 d-flex justify-content-end">
                        <MRT_GlobalFilterTextField table={table} />
                      </div>
                    </div>
                  </>
                )}
                muiTableContainerProps={{
                  elevation: 0,
                  sx: {
                    background: "white",
                    padding: "0px !important",
                    paddingTop: "0px",
                  },
                }}
                positionActionsColumn="last"
                initialState={{
                  showGlobalFilter: true,
                  showColumnFilters: false,
                  hoveredRow: false,
                }}
                muiPaginationProps={{
                  ...paginationoptions,
                }}
              />
            }
          />
        </div>
      </div>

      {/* footer section */}
      <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
        <div className="d-flex gap-3">
          {!goBack && (
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => {
                setClosePopup(false);
              }}
            />
          )}
          {goBack && (
            <ETButton
              variant={"white"}
              buttonText={usedFor == "teams" ? "BACK" : "CANCEL"}
              onClick={handlePrevious}
            />
          )}
        </div>

        {usedFor !== "viewAssignedProperties" && (
          <ETButton
            variant={"primary"}
            onClick={handleSucess}
            disabled={
              Object.keys(rowSelection).length == 0 ||
              addTeamLoading ||
              updateTeamLoading || assignPropertiesToTeamsLoading
            }
            buttonText={
              usedFor == "teams" && flag == false
                ? "CREATE TEAM"
                : usedFor == "teams" && flag == true
                  ? "SAVE"
                  : "SAVE"
            }
          />
        )}
      </div>
    </div>
  );
};

export default AssignProperties;
