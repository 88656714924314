import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import Toggle from "../../../../Components/Switch/Switch";
import LinkedUnits from "../screeningFormsComponent/LinkedUnits";
import ETButton from "../../../../Components/Buttons/ETButton";
import LanguagesSelection from "../../../../Components/FilterDropdownSelection/LanguagesSelection";
import { useNavigate } from "react-router-dom";
// import { Controller, useForm } from "react-hook-form";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";
import GroupButtons from "../../../../Components/GroupButton/GroupButton";
import { H2, H5 } from "../../../../styles/Common.style";
// import { H2 } from "../../../../styles/Common.style";
import MultiSelect from "../../../../Components/DropDown/MultiSelect";
// import { H2 } from "../../../../styles/Common.style";
import {
  useAddScreeningForm,
  useGetScreeningDeadlineAction,
  useGetScreeningFlagTriggerAction,
  useGetScreeningFormBasicDetails,
} from "../../../../hooks/queries/useScreeningQueries";
import { useGetEmailTemplateList } from "../../../../hooks/queries/useEmailTemplates";
import { useScreening } from "../../../../hooks/stores/useScreeningStore";

import CustomReactSelect from "../../../../Components/DropDown/CustomReactSelect";
import { adjustedMaxCustomerOptions } from "../../../../model/Constant";
import { usePropertyId } from "../../../../hooks/stores/usePropertyStore";
// import { useGetUserById } from "../../../../hooks/queries/useUserQueries";
import { toast } from "react-toastify";
import InputField from "../../../../Components/Textfield/InputField";
import LanguageSelectSearch from "../../../../Components/FilterDropdownSelection/LanguagesSelection";
import { useGetUnitsByPropertyIdUpdated } from "../../../../hooks/queries/useUnitQueries";

const ScreeningFormSettings = ({
  setStep1,
  setStoreScreeningPayLoad,
  setValue,
  getValues,
  errors,
  handleSubmit,
  control,
  unitsData,
  register,
  setUnitsData,
  handleAddScreening,
  useForm,
  Controller,
  onSubmit,
  setSettingsData,
  watch,
  clearErrors,
  setCategoryData,
  fields,
}) => {
  const {
    selectedLanguageId,
    formStatusId,
    linkedUnitId,
    setLinkedUnitId,
    screeningFormId,
    EditscreeningForm,
    setEditScreeningForm,
    setSaveScreeningForm,
    draft,
    parentFormId,
  } = useScreening((state) => state);

  //-------------------------------------state------------------------------------//
  const [toggleReminderOne, setToggleReminderOne] = useState(false);
  const [toggleReminderTwo, setToggleReminderTwo] = useState(false);
  const [ActivateDeadlineToggle, setActivateDeadlineToggle] = useState(false);
  const [numbersOfCustomersTab, setNumbersOfCustomersTab] = useState(0);
  // const [unitsData, setUnitsData] = useState([]);
  // const title = watch("title")

  // category name array
  // "category_name": [
  // 	{
  // 		"language_id": "da",
  // 		"name": "Information",
  // 		"description": "Udfyld venligst dine personlige oplysninger nedenfor"
  // 	},
  // 	{
  // 		"language_id": "en",
  // 		"name": "Information",
  // 		"description": "Please fill out your personal information below"
  // 	}
  // ]

  const navigate = useNavigate();
  //-------------------------------------react query------------------------------------//
  const { data: GetScreeningDeadlineActionData } =
    useGetScreeningDeadlineAction();

  const { data: GetScreeningFlagTriggerActionData } =
    useGetScreeningFlagTriggerAction();

  // const { data: GetScreeningFormBasicDetailsData } =
  // 	useGetScreeningFormBasicDetails(screeningFormId);
  // console.log("parentFormId", parentFormId);
  const { data: GetScreeningFormBasicDetailsData } =
    useGetScreeningFormBasicDetails(parentFormId);
  // const { data: userbyIdData } = useGetUserById();
  const { propertyId } = usePropertyId((state) => state);
  //   const deadlineActivated = watch("deadline_activated");
  // console.log("deadlineActivated", watch("deadline_activated"));
  const { data: GetEmailTemplateInvitationNewUserListData } =
    useGetEmailTemplateList(
      "p",
      propertyId,
      "SC",
      0,
      0
      // "p", propertyId, 4, 12, 32
    );

  const { data: GetEmailTemplateInvitationExisitingUserListData } =
    useGetEmailTemplateList(
      "p",
      propertyId,
      "SC",
      0,
      0
      // "p", propertyId, 4, 12, 33
    );
  const { data: GetEmailTemplateApprovedListData } = useGetEmailTemplateList(
    "p",
    propertyId,
    "SC",
    0,
    0
    // "p",
    // propertyId,
    // 4,
    // 12,
    // 33
  );
  const { data: GetEmailTemplateCompletedListData } = useGetEmailTemplateList(
    "p",
    propertyId,
    "SC",
    0,
    0
    // "p",
    // propertyId,
    // 4,
    // 12,
    // 33
  );
  const { data: GetEmailTemplateDeclinedListData } = useGetEmailTemplateList(
    "p",
    propertyId,
    "SC",
    0,
    0
    // "p",
    // propertyId,
    // 4,
    // 12,
    // 33
  );
  const { data: GetEmailTemplateCanceledListData } = useGetEmailTemplateList(
    "p",
    propertyId,
    "SC",
    0,
    0
    // "p",
    // propertyId,
    // 4,
    // 12,
    // 33
  );
  const { data: GetEmailTemplateRestartedListData } = useGetEmailTemplateList(
    "p",
    propertyId,
    "SC",
    0,
    0
    // "p",
    // propertyId,
    // 4,
    // 12,
    // 33
  );

  const {
    mutateAsync: addScreeningFormMuatate,
    isLoading: isAddScreeningLoading,
  } = useAddScreeningForm();
  const screeningFormBasicDetailsData = GetScreeningFormBasicDetailsData?.data;
  const handleGetAdjustedFixedLabel = (value) => {
    const selectedOption = adjustedMaxCustomerOptions?.filter(
      (opt) => opt.value == value
    );
    return selectedOption?.[0]?.label;
  };
  const { data: unitsByPropertyData } = useGetUnitsByPropertyIdUpdated(
    propertyId,
    0
  );
  const reminderTemplateOptions = [
    {
      value: "1",
      label: "Screening -  Reminder 2, Version 2, Luke Sky...",
    },
    {
      value: "2",
      label: "Screening -  Reminder 2, Version 3, Luke Sky...",
    },
    {
      value: "3",
      label: "Screening -  Reminder 2, Version 4, Luke Sky...",
    },
  ];

  // const chosenLanguages = selectedLanguageId?.map((lang) => ({
  // 	lang,
  // }));

  const handleGetReminderOneEmailTemplate = (value) => {
    const selectedOption = reminderTemplateOptions?.filter(
      (opt) => opt.value == value
    );
    return selectedOption?.[0]?.label;
  };
  const handleGetDeadlineExceedLable = (value) => {
    const selectedOption = screeningDeadlineActionData?.filter(
      (opt) => opt.Id == value
    );
    return selectedOption?.[0]?.Text;
  };
  const handleActivateDeadlineToggleChange = (e) => {
    const isChecked = e.target.checked;
    setActivateDeadlineToggle(isChecked);
  };
  const handleActiveReminderOneToggleChange = (e) => {
    const isChecked = e.target.checked;
    setToggleReminderOne(isChecked);
  };

  const handleActiveReminderTowToggleChange = (e) => {
    const isChecked = e.target.checked;
    setToggleReminderTwo(isChecked);
  };
  // const {
  // 	control,
  // 	register,
  // 	handleSubmit,
  // 	// setValue,
  // 	// getValues,
  // 	// watch,
  // 	formState: { errors },
  // } = useForm();
  useEffect(() => {
    const outerTableLinkedUnitId = unitsData
      ?.filter((unit) => unit.is_linked_with_screening != 1)
      ?.map((data) => data?.unit_id);
    setLinkedUnitId(outerTableLinkedUnitId);
  }, [unitsData]);

  useEffect(() => {
    if (EditscreeningForm) {
      // setUnitsData(screeningFormBasicDetailsData?.[0]?.unit_data)
      setValue(
        "title",
        screeningFormBasicDetailsData?.[0]?.screening_form_name
      );
      setValue(
        "days",

        {
          value: screeningFormBasicDetailsData?.[0]?.screening_deadline_days,
          label: screeningFormBasicDetailsData?.[0]?.screening_deadline_days,
        }
      );
      setValue("screeningHours", {
        value: screeningFormBasicDetailsData?.[0]?.screening_deadline_hours,
        label: screeningFormBasicDetailsData?.[0]?.screening_deadline_hours,
      });

      // setValue("deadlineAction", {
      // 	value:
      // 		screeningFormBasicDetailsData?.[0]
      // 			?.screening_deadline_exceed_condition_id,
      // 	label: handleGetDeadlineExceedLable(
      // 		screeningFormBasicDetailsData?.[0]
      // 			?.screening_deadline_exceed_condition_id
      // 	),
      // });
      screeningFormBasicDetailsData?.[0]
        ?.screening_deadline_exceed_condition_id == 0
        ? setValue("deadlineAction", "0")
        : setValue("deadlineAction", {
            value:
              screeningFormBasicDetailsData?.[0]
                ?.screening_deadline_exceed_condition_id,
            label: handleGetReminderOneEmailTemplate(
              screeningFormBasicDetailsData?.[0]
                ?.screening_deadline_exceed_condition_id
            ),
          });
      screeningFormBasicDetailsData?.[0]
        ?.screening_invitation_new_user_email_template_id == 0
        ? setValue("screeninginvitationNewUser", "0")
        : setValue("screeninginvitationNewUser", {
            value:
              screeningFormBasicDetailsData?.[0]
                ?.screening_invitation_new_user_email_template_id,
            label: handleGetReminderOneEmailTemplate(
              screeningFormBasicDetailsData?.[0]
                ?.screening_invitation_new_user_email_template_id
            ),
          });
      screeningFormBasicDetailsData?.[0]
        ?.screening_invitation_existing_user_email_template_id == 0
        ? setValue("screeninginvitationExistingUser", "0")
        : setValue("screeninginvitationExistingUser", {
            value:
              screeningFormBasicDetailsData?.[0]
                ?.screening_invitation_existing_user_email_template_id,
            label: handleGetReminderOneEmailTemplate(
              screeningFormBasicDetailsData?.[0]
                ?.screening_invitation_existing_user_email_template_id
            ),
          });
      screeningFormBasicDetailsData?.[0]
        ?.screening_approved_email_template_id == 0
        ? setValue("screeningapproved", "0")
        : setValue("screeningapproved", {
            value:
              screeningFormBasicDetailsData?.[0]
                ?.screening_approved_email_template_id,
            label: handleGetReminderOneEmailTemplate(
              screeningFormBasicDetailsData?.[0]
                ?.screening_approved_email_template_id
            ),
          });

      screeningFormBasicDetailsData?.[0]
        ?.screening_completed_email_template_id == 0
        ? setValue("screeningCompleted", "0")
        : setValue("screeningCompleted", {
            value:
              screeningFormBasicDetailsData?.[0]
                ?.screening_completed_email_template_id,
            label: handleGetReminderOneEmailTemplate(
              screeningFormBasicDetailsData?.[0]
                ?.screening_completed_email_template_id
            ),
          });

      screeningFormBasicDetailsData?.[0]
        ?.screening_declined_email_template_id == 0
        ? setValue("screeningDeclined", "0")
        : setValue("screeningDeclined", {
            value:
              screeningFormBasicDetailsData?.[0]
                ?.screening_declined_email_template_id,
            label: handleGetReminderOneEmailTemplate(
              screeningFormBasicDetailsData?.[0]
                ?.screening_declined_email_template_id
            ),
          });

      screeningFormBasicDetailsData?.[0]
        ?.screening_cancelled_email_template_id == 0
        ? setValue("screeningCanceled", "0")
        : setValue("screeningCanceled", {
            value:
              screeningFormBasicDetailsData?.[0]
                ?.screening_cancelled_email_template_id,
            label: handleGetReminderOneEmailTemplate(
              screeningFormBasicDetailsData?.[0]
                ?.screening_cancelled_email_template_id
            ),
          });

      screeningFormBasicDetailsData?.[0]
        ?.screening_restarted_email_template_id == 0
        ? setValue("screeningRestarted", "0")
        : setValue("screeningRestarted", {
            value:
              screeningFormBasicDetailsData?.[0]
                ?.screening_restarted_email_template_id,
            label: handleGetReminderOneEmailTemplate(
              screeningFormBasicDetailsData?.[0]
                ?.screening_restarted_email_template_id
            ),
          });

      setValue(
        "isReminder1activated",
        screeningFormBasicDetailsData?.[0]?.is_reminder1_activated
      );
      // NOTE: SINCE WE DON'T HAVE EMAIL TEMPLATES YET PASSING 0 IN POST AND SHOWING BLANK FOR EMAIL TEMPLATES
      screeningFormBasicDetailsData?.[0]
        ?.screening_reminder1_email_tempate_id == 0
        ? setValue("reminderOneEmailTemplate", "0")
        : setValue("reminderOneEmailTemplate", {
            value:
              screeningFormBasicDetailsData?.[0]
                ?.screening_reminder1_email_tempate_id,
            label: handleGetReminderOneEmailTemplate(
              screeningFormBasicDetailsData?.[0]
                ?.screening_reminder1_email_tempate_id
            ),
          });

      setValue("reminderInterval1", {
        value:
          screeningFormBasicDetailsData?.[0]?.screening_reminder1_interval_mins,
        label: handleMinsToHoursLabel(
          screeningFormBasicDetailsData?.[0]?.screening_reminder1_interval_mins
        ),
      });

      setValue(
        "isReminder2Activated",
        screeningFormBasicDetailsData?.[0]?.is_reminder2_activated
      );

      screeningFormBasicDetailsData?.[0]
        ?.screening_reminder2_email_template_id == 0
        ? setValue("reminderTwoEmailTemplate", "0")
        : setValue("reminderTwoEmailTemplate", {
            value:
              screeningFormBasicDetailsData?.[0]
                ?.screening_reminder2_email_template_id,
            label: handleGetReminderOneEmailTemplate(
              screeningFormBasicDetailsData?.[0]
                ?.screening_reminder2_email_template_id
            ),
          });
      setValue("reminderInterval2", {
        value:
          screeningFormBasicDetailsData?.[0]?.screening_reminder2_interval_mins,
        label: handleMinsToHoursLabel(
          screeningFormBasicDetailsData?.[0]?.screening_reminder2_interval_mins
        ),
      });

      screeningFormBasicDetailsData?.[0]
        ?.max_customer_allowed_on_contract_determinate_type == "adjusted"
        ? setNumbersOfCustomersTab(0)
        : setNumbersOfCustomersTab(1);
      screeningFormBasicDetailsData?.[0]
        ?.max_customer_allowed_on_contract_determinate_type == "adjusted"
        ? setValue("adjusted", {
            value:
              screeningFormBasicDetailsData?.[0]
                ?.max_number_of_customer_allowed,
            label: handleGetAdjustedFixedLabel(
              screeningFormBasicDetailsData?.[0]?.max_number_of_customer_allowed
            ),
          })
        : setValue("adjusted", "");

      screeningFormBasicDetailsData?.[0]
        ?.max_customer_allowed_on_contract_determinate_type == "adjusted"
        ? setValue("fixed", {
            value:
              screeningFormBasicDetailsData?.[0]
                ?.max_number_of_customer_allowed,
            label: handleGetAdjustedFixedLabel(
              screeningFormBasicDetailsData?.[0]?.max_number_of_customer_allowed
            ),
          })
        : setValue("fixed", "");
      // const editUnitId = screeningFormBasicDetailsData?.[0]?.unit_data?.map((data) => data?.unit_id)
      // const editData = unitsByPropertyData?.data?.filter((item) =>
      // 	editUnitId?.includes(item?.unit_id)
      // );
      // setUnitsData(editData);
      // setLinkedUnitId(
      // 	screeningFormBasicDetailsData?.[0]?.unit_data?.map(
      // 		(entry) => entry.unit_id
      // 	)
      // );
      // setUnitsData(
      // 	screeningFormBasicDetailsData?.[0]?.unit_data?.map(
      // 		(entry) => entry.unit_id
      // 	)
      // );
    }
  }, [GetScreeningFormBasicDetailsData]);
  const handleCancel = () => {
    setSaveScreeningForm(true);
    setEditScreeningForm(false);
    navigate("/property-units/settings/screening", {
      state: {
        Tab: "Settings",
      },
    });
  };

  const numbersOfDays = Array?.from({ length: 30 }, (_, i) => i + 0);
  const hours = Array?.from({ length: 24 }, (_, i) => i + 0);
  const options = numbersOfDays?.map((number) => ({
    value: number?.toString(),
    label: number?.toString(),
  }));
  const optionsOfHours = hours?.map((number) => ({
    value: number?.toString(),
    label: number?.toString(),
  }));
  const [inputValue, setInputValue] = useState("");
  const [selectLanguage, setSelectLanguage] = useState("");

  const screeningDeadlineActionData = GetScreeningDeadlineActionData?.data;

  const flagTriggerActionData = GetScreeningFlagTriggerActionData?.data;
  const handleMinsToHoursLabel = (mins) => {
    const hours = mins / 60;
    const label = `Every ${hours} hours`;
    return label;
  };

  const onSubmitSettings = async (data) => {
    // console.log("data onSubmitSettings", data);
    const settingPayload = {
      name: data?.title,
      choosen_language: selectedLanguageId,
      is_template: 0,
      // form_status_id: getValues("publishForm") ? 2 : 3,
      form_status_id: draft ? 1 : 2,
      parent_form_id: parentFormId,
      organization_id: 0,
      division_id: 0,
      company_id: 0,
      property_id: propertyId,
      units: linkedUnitId,
      // units: ["100489929779839274"],
      is_deadline_activated: data?.deadline_activated ? 1 : 0,
      deadline_days:
        data?.days?.value == "" || data?.days?.value == undefined
          ? 0
          : Number(data?.days?.value),
      deadline_hours:
        data?.screeningHours?.value == "" ||
        data?.screeningHours?.value == undefined
          ? 0
          : Number(data?.screeningHours?.value),
      screening_deadline_exceed_condition_id:
        data?.deadlineAction?.value == "" ||
        data?.deadlineAction?.value == undefined
          ? 0
          : data?.deadlineAction?.value,
      // screening_invitation_new_user_email_template_id: getValues(
      // 	"screeninginvitationNewUser.value"
      // )
      // 	? undefined
      // 	: "",
      // screening_invitation_existing_user_email_template_id: getValues(
      // 	"screeninginvitationExistingUser.value"
      // )
      // 	? undefined
      // 	: "",

      // approved_email_template_id: getValues("screeningapproved.value"),
      // completed_email_template_id: getValues("screeningapproved.value"),
      // declined_email_template_id: getValues("screeningDeclined.value"),
      // canceled_email_template_id: getValues("screeningCanceled.value"),
      // restarted_email_template_id: getValues("screeningCanceled.value"),
      screening_invitation_new_user_email_template_id: 0,
      screening_invitation_existing_user_email_template_id: 0,

      approved_email_template_id: 0,
      completed_email_template_id: 0,
      declined_email_template_id: 0,
      canceled_email_template_id: 0,
      restarted_email_template_id: 0,
      // is_reminder1_activated: getValues("isReminder1activated"),
      // is_reminder2_activated: getValues("isReminder2Activated"),

      is_reminder1_activated:
        data?.isReminder1activated == "" ||
        data?.isReminder1activated == undefined
          ? 0
          : data?.isReminder1activated,
      is_reminder2_activated:
        data?.isReminder2Activated == "" ||
        data?.isReminder2Activated == undefined
          ? 0
          : data?.isReminder2Activated,
      reminder1_email_template_id:
        data?.reminderOneEmailTemplate?.value == "" ||
        data?.reminderOneEmailTemplate?.value == undefined
          ? 0
          : Number(data?.reminderOneEmailTemplate?.value),
      screening_reminder1_interval_mins:
        data?.reminderInterval1?.value == "" ||
        data?.reminderInterval1?.value == undefined
          ? 0
          : data?.reminderInterval1?.value,
      // screening_is_reminder2_activated: isReminder2ActivatedForForm ? 1 : 0,
      screening_is_reminder2_activated: data?.isReminder2Activated ? 1 : 0,
      reminder2_email_template_id:
        data?.reminderTwoEmailTemplate?.value == "" ||
        data?.reminderTwoEmailTemplate?.value == undefined
          ? 0
          : Number(data?.reminderTwoEmailTemplate?.value),
      screening_reminder2_interval_mins:
        data?.reminderInterval2?.value == "" ||
        data?.reminderInterval2?.value == undefined
          ? 0
          : Number(data?.reminderInterval2?.value),
      max_customer_contract_determinate_type:
        numbersOfCustomersTab == 0 ? 1 : 2,
      max_no_of_customer_allowed:
        numbersOfCustomersTab == 0
          ? Number(data?.adjusted?.value)
          : Number(data?.fixed?.value),
    };
    // console.log("settingPayload", settingPayload);
    if (linkedUnitId.length == 0) {
      toast.warn("Please link at least one unit.");
    } else {
      setStep1(false);

      setSettingsData(settingPayload);

      const updatedCategory =
        GetScreeningFormBasicDetailsData?.data?.[0]?.category?.map(
          (categoryItem) => {
            // Filter category_name to include only selectedLanguageId
            const filteredCategoryName = categoryItem?.category_name?.filter(
              (langObj) => selectedLanguageId?.includes(langObj?.language_id)
            );

            // Add missing languages to category_name
            selectedLanguageId?.forEach((lang) => {
              if (
                !filteredCategoryName?.some(
                  (langObj) => langObj?.language_id === lang
                )
              ) {
                filteredCategoryName?.push({
                  language_id: lang,
                  name: "",
                  description: "",
                });
              }
            });

            return {
              ...categoryItem,
              category_name: filteredCategoryName,
            };
          }
        );
      setCategoryData(updatedCategory);
      const sections = GetScreeningFormBasicDetailsData?.data?.[0]?.sections;
      // console.log("sections", sections);
      const updateDefaultArray = sections?.map((section) => ({
        section_category_id: section?.screening_category_id,
        section_id: section?.screening_form_section_id,
        display_order_no: section?.display_order_no,
        section_headline: section?.name,
        section_description: section?.description,
        questionFields: section?.questions,
      }));
      // console.log("updateDefaultArray", updateDefaultArray);
      setValue("screeningSection", updateDefaultArray);
    }
  };
  // console.log("fields", fields);
  return (
    <div>
      <Form autoComplete="off" onSubmit={handleSubmit(onSubmitSettings)}>
        {/* title section */}
        <div className="px-3 pb-3 border-bottom">
          <div className="px-2 pt-3 d-flex">
            <div className="d-flex align-items-center  gap-2">
              <H2>Create new screening form 1/2</H2>
              <CustomToolTip size="sm" text="Create new screening form 1/2" />
            </div>
          </div>
        </div>

        {/*General screening settings  */}
        <div className="p-4 border-bottom">
          <div className="d-flex align-items-center form-label-title gap-2 ">
            <span className="fs-20">General screening settings</span>{" "}
            <CustomToolTip text="General screening settings" />
          </div>
          <div className="light-font-text">
            <Row>
              <Col xl={8} className="pe-xl-0">
                In this step you’ll configure the basic settings of your
                screening form. Once you’ve finished this part you’re ready for
                the final configurations.
              </Col>
            </Row>
          </div>
        </div>

        {/*Internal title  */}
        <div className="p-4 border-bottom">
          <div className="d-flex form-label-title align-items-center gap-2">
            Internal title
            <CustomToolTip height={12} text="Internal title" />
          </div>
          <div className="light-font-text pb-1">
            The title which will be shown on your screening form internal in the
            system.
          </div>

          <Row className="mt-2">
            <Col xl={4} lg={6} className="pb-1">
              <Controller
                name="title"
                control={control}
                {...register("title")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    {/* <input
											type="text"
											placeholder="title"
											id="title"
											value={value}
											onChange={(e) => {
												onChange(e);
											}}
											maxLength={30}
										/> */}
                    <InputField
                      id={"title"}
                      value={value}
                      type={"text"}
                      placeholder={"Title"}
                      errorscope
                      iserror={errors?.title}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                    {/* <label htmlFor="title">Title</label> */}
                  </div>
                )}
              />
              {/* {errors?.title && <p className="error-msg">{REQUIRED_ERR}</p>} */}
            </Col>
          </Row>
        </div>

        {/* Choose languages for form */}
        <div className="p-4 border-bottom">
          <div className="d-flex align-items-center form-label-title gap-2">
            Choose languages for form{" "}
            <CustomToolTip height={12} text="Choose languages for form" />
          </div>
          <div className="light-font-text">
            <Row>
              <Col xl={8} className="pe-xl-0 pb-1">
                Select which languages this screening form should be set up in.
                This allows you to define different languages for all questions
                in the screening form later in the flow.
              </Col>
            </Row>
          </div>

          <Row className="mt-2">
            {/* <Col xl={4} lg={6} className="pb-1">
							
							<div className="form-input">
								<LanguagesSelection />
							</div>
						</Col> */}
            <Col xl={4} lg={6} className="pb-1 ">
              {/* <MultiSelect /> */}
              <LanguageSelectSearch
                inputValue={selectLanguage}
                onInputChange={(val) => setSelectLanguage(val)}
                isSearchable={false}
                placeholder={"Languages"}
              />
            </Col>
          </Row>
        </div>

        {/* Publish form */}
        {/* <div className="p-4 border-bottom">
				<div className="d-flex align-items-center form-label-title gap-2">
					Publish form <CustomToolTip height={12} text="Publish form" />
				</div>
				<div className="light-font-text">
					<Row>
						<Col xl={8} className="pe-xl-0 pb-1">
							Select the toggle to publish whether the screening form is
							published or draft
						</Col>
					</Row>
				</div>
				<div>
					<Controller
						name="publishForm"
						control={control}
						{...register("publishForm")}
						rules={{ required: false }}
						render={({ field: { onChange, value } }) => (
							<div className="form-input ">
								<Toggle
									id="publishForm"
									checked={value}
									onChange={(e) => onChange(e.target.checked)}
								/>
							</div>
						)}
					/>
				</div>
			</div> */}

        {/* Linked units */}
        <div className="p-4 border-bottom">
          <LinkedUnits
            unitsData={unitsData}
            setUnitsData={setUnitsData}
            // EditscreeningForm={EditscreeningForm}
            linkedUnitsData={screeningFormBasicDetailsData?.[0]?.unit_data}
          />
        </div>

        {/* Deadline for screening */}
        <div className="p-4 border-bottom">
          <div className="d-flex align-items-center form-label-title gap-2">
            Deadline for screening{" "}
            <CustomToolTip height={12} text="Deadline for screening" />
          </div>
          <div className="light-font-text">
            <Row>
              <Col xl={8} className="pe-xl-0 pb-1">
                Activate an define the deadline for this screening. The deadline
                counts from when a screening is created on a customer. If
                deadline is set to not active, no deadline will be apply to the
                form.
              </Col>
            </Row>
          </div>
          <div className="pt-1">
            <H5 className="">Activate deadline</H5>

            <Controller
              name="deadline_activated"
              control={control}
              {...register("deadline_activated")}
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <div className="form-input ">
                  <Toggle
                    id="deadline_activated"
                    checked={value}
                    value={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                </div>
              )}
            />
          </div>

          <div className="py-3">
            <Row>
              <Col xl={2} lg={3} sm={6}>
                <Controller
                  control={control}
                  {...register("days")}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Days"}
                      iserror={errors?.days}
                      value={value}
                      //   disabled={!ActivateDeadlineToggle}
                      options={options}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
                {/* {errors?.days && <p className="error-msg">{REQUIRED_ERR}</p>} */}
              </Col>
              <Col xl={2} lg={3} sm={6}>
                <Controller
                  control={control}
                  {...register("screeningHours")}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Hours"}
                      value={value}
                      iserror={errors?.screeningHours}
                      options={optionsOfHours}
                      //   disabled={!ActivateDeadlineToggle}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
                {/* {errors?.screeningHours && (
									<p className="error-msg">{REQUIRED_ERR}</p>
								)} */}
              </Col>
            </Row>
          </div>
          <div className="light-font-text">
            If the screening exceed the deadline, what should happen?
          </div>
          <div className="pt-2 pb-2">
            <Row>
              <Col xl={3} lg={4} sm={8}>
                <Controller
                  control={control}
                  {...register("deadlineAction")}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Cancel screening"}
                      value={value}
                      iserror={errors?.deadlineAction}
                      //   disabled={!ActivateDeadlineToggle}
                      options={screeningDeadlineActionData?.map((opt) => ({
                        value: opt?.Id,
                        label: opt?.Text,
                      }))}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
                {/* {errors?.deadlineAction && (
									<p className="error-msg">{REQUIRED_ERR}</p>
								)} */}
              </Col>
            </Row>
          </div>
        </div>

        {/* Select email send out to template */}
        <div className="p-4 border-bottom">
          <Row className="d-flex align-items-center">
            <Col xl={8} lg={6} className="pe-0">
              <div className="d-flex align-items-center form-label-title gap-2">
                Select email send out to template{" "}
                <CustomToolTip
                  height={12}
                  text="Select email send out to template"
                />
              </div>
              <div className="light-font-text " style={{ width: "85%" }}>
                Below are the emails that the system needs to send out in the
                process of a screening. You need to define which email templates
                to use for the different actions.
                <div></div>
              </div>
            </Col>
            <Col xl={4} lg={6} className="ps-0 ">
              <div className="d-flex justify-content-end">
                <ETButton
                  variant={"primary"}
                  buttonText="Go to Email Template settings"
                  width="fit-content"
                />
              </div>
            </Col>
          </Row>

          {/*  Screening invitation new user*/}
          <div className="pt-3 pb-2">
            <Row className="d-flex align-items-center">
              <Col xl={3} lg={4} sm={6}>
                <div className="form-label-title w-100">
                  Screening invitation new user
                </div>
              </Col>
              <Col xl={4} lg={5} sm={6}>
                <Controller
                  control={control}
                  {...register("screeninginvitationNewUser")}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Screening invitation"}
                      innerLabel={false}
                      // options={GetEmailTemplateInvitationNewUserListData?.data?.[0]?.title?.map(
                      // 	(item, index) => ({
                      // 		value: item?.language_id,
                      // 		label: item?.text,
                      // 	})
                      // )}
                      options={GetEmailTemplateInvitationNewUserListData?.data?.map(
                        (template) => ({
                          value: template.email_template_id,
                          label: template.title.map((t) => t.text),
                        })
                      )}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("screeninginvitationNewUser");
                      }}
                    />
                    // <CustomReactSelect
                    // 	placeholder={"Screening invitation"}
                    // 	iserror={errors?.screeninginvitationNewUser}
                    // 	inputValue={inputValue}
                    // 	isSearchable={false}
                    // 	onInputChange={(val) => setInputValue(val)}
                    // 	onChange={(e) => {
                    // 		onChange(e);
                    // 	}}
                    // />
                  )}
                />
                {errors?.screeninginvitationNewUser && (
                  <p className="error-msg">{REQUIRED_ERR}</p>
                )}
              </Col>
            </Row>
          </div>
          {/* {Screening invitation exisiting user} */}
          <div className="pt-3 pb-2">
            <Row className="d-flex align-items-center">
              <Col xl={3} lg={4} sm={6}>
                <div className="form-label-title w-100">
                  Screening invitation existing user
                </div>
              </Col>
              <Col xl={4} lg={5} sm={6}>
                <Controller
                  control={control}
                  {...register("screeninginvitationExisitingUser")}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Screening invitation"}
                      innerLabel={false}
                      // options={GetEmailTemplateInvitationExisitingUserListData?.data?.title?.map(
                      // 	(item, index) => ({
                      // 		value: item?.language_id,
                      // 		label: item?.text,
                      // 	})
                      // )}
                      options={GetEmailTemplateInvitationExisitingUserListData?.data?.map(
                        (template) => ({
                          value: template.email_template_id,
                          label: template.title.map((t) => t.text),
                        })
                      )}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("screeninginvitationExisitingUser");
                      }}
                      // clearable={false}
                    />
                    // <CustomReactSelect
                    // 	placeholder={"Screening invitation"}
                    // 	iserror={errors?.screeninginvitationExisitingUser}
                    // 	inputValue={inputValue}
                    // 	isSearchable={false}
                    // 	onInputChange={(val) => setInputValue(val)}
                    // 	onChange={(e) => {
                    // 		onChange(e);
                    // 	}}
                    // />
                  )}
                />
                {errors?.screeninginvitationExisitingUser && (
                  <p className="error-msg">{REQUIRED_ERR}</p>
                )}
              </Col>
            </Row>
          </div>
          {/*  Screening approved */}
          <div className="py-2">
            <Row className="d-flex align-items-center">
              <Col xl={3} lg={4} sm={6}>
                <div className="form-label-title w-100">Screening approved</div>
              </Col>
              <Col xl={4} lg={5} sm={6}>
                <Controller
                  control={control}
                  {...register("screeningapproved")}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    // <CustomReactSelect
                    // 	placeholder={"Screening approved"}
                    // 	inputValue={inputValue}
                    // 	isSearchable={false}
                    // 	onInputChange={(val) => setInputValue(val)}
                    // 	onChange={(e) => {
                    // 		onChange(e);
                    // 	}}
                    // />
                    <ReactSelect
                      placeholder={"Screening approved"}
                      innerLabel={false}
                      options={GetEmailTemplateApprovedListData?.data?.map(
                        (template) => ({
                          value: template.email_template_id,
                          label: template.title.map((t) => t.text),
                        })
                      )}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("screeningapproved");
                      }}
                      // clearable={false}
                    />
                  )}
                />
                {errors?.screeningapproved && (
                  <p className="error-msg">{REQUIRED_ERR}</p>
                )}
              </Col>
            </Row>
          </div>

          {/*  Screening completed */}
          <div className="py-2">
            <Row className="d-flex align-items-center">
              <Col xl={3} lg={4} sm={6}>
                <div className="form-label-title w-100">
                  Screening completed
                </div>
              </Col>
              <Col xl={4} lg={5} sm={6}>
                <Controller
                  control={control}
                  {...register("screeningCompleted")}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Screening completed"}
                      innerLabel={false}
                      options={GetEmailTemplateCompletedListData?.data?.map(
                        (template) => ({
                          value: template.email_template_id,
                          label: template.title.map((t) => t.text),
                        })
                      )}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("screeninginvitationExisitingUser");
                      }}
                      // clearable={false}
                    />
                    // <CustomReactSelect
                    // 	placeholder={"Screening completed"}
                    // 	iserror={errors?.screeninginvitationExisitingUser}
                    // 	inputValue={inputValue}
                    // 	isSearchable={false}
                    // 	onInputChange={(val) => setInputValue(val)}
                    // 	onChange={(e) => {
                    // 		onChange(e);
                    // 	}}
                    // />
                  )}
                />

                {errors?.screeningCompleted && (
                  <p className="error-msg">{REQUIRED_ERR}</p>
                )}
              </Col>
            </Row>
          </div>

          {/*  Screening declined */}
          <div className="py-2">
            <Row className="d-flex align-items-center">
              <Col xl={3} lg={4} sm={6}>
                <div className="form-label-title w-100">Screening declined</div>
              </Col>
              <Col xl={4} lg={5} sm={6}>
                <Controller
                  control={control}
                  {...register("screeningDeclined")}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Screening declined"}
                      innerLabel={false}
                      options={GetEmailTemplateDeclinedListData?.data?.map(
                        (template) => ({
                          value: template.email_template_id,
                          label: template.title.map((t) => t.text),
                        })
                      )}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("screeningDeclined");
                      }}
                      // clearable={false}
                    />
                    // <CustomReactSelect
                    // 	placeholder={"Screening declined"}
                    // 	iserror={errors?.screeninginvitationExisitingUser}
                    // 	inputValue={inputValue}
                    // 	isSearchable={false}
                    // 	onInputChange={(val) => setInputValue(val)}
                    // 	onChange={(e) => {
                    // 		onChange(e);
                    // 	}}
                    // />
                  )}
                />

                {errors?.screeningDeclined && (
                  <p className="error-msg">{REQUIRED_ERR}</p>
                )}
              </Col>
            </Row>
          </div>

          {/*  Screening canceled */}
          <div className="py-2">
            <Row className="d-flex align-items-center">
              <Col xl={3} lg={4} sm={6}>
                <div className="form-label-title w-100">Screening canceled</div>
              </Col>
              <Col xl={4} lg={5} sm={6}>
                <Controller
                  control={control}
                  {...register("screeningCanceled")}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Screening canceled"}
                      innerLabel={false}
                      options={GetEmailTemplateCanceledListData?.data?.map(
                        (template) => ({
                          value: template.email_template_id,
                          label: template.title.map((t) => t.text),
                        })
                      )}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("screeningCanceled");
                      }}
                    />
                    // <CustomReactSelect
                    // 	placeholder={"Screening canceled"}
                    // 	iserror={errors?.screeninginvitationExisitingUser}
                    // 	inputValue={inputValue}
                    // 	isSearchable={false}
                    // 	onInputChange={(val) => setInputValue(val)}
                    // 	onChange={(e) => {
                    // 		onChange(e);
                    // 	}}
                    // />
                  )}
                />

                {errors?.screeningCanceled && (
                  <p className="error-msg">{REQUIRED_ERR}</p>
                )}
              </Col>
            </Row>
          </div>

          {/* Screening restarted*/}
          <div className="pt-2">
            <Row className="d-flex align-items-center">
              <Col xl={3} lg={4} sm={6}>
                <div className="form-label-title w-100">
                  Screening restarted
                </div>
              </Col>
              <Col xl={4} lg={5} sm={6}>
                <Controller
                  control={control}
                  {...register("screeningRestarted")}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Screening restarted"}
                      innerLabel={false}
                      options={GetEmailTemplateRestartedListData?.data?.map(
                        (template) => ({
                          value: template.email_template_id,
                          label: template.title.map((t) => t.text),
                        })
                      )}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("screeningRestarted");
                      }}
                      // clearable={false}
                    />
                    // <CustomReactSelect
                    // 	placeholder={"Screening restarted"}
                    // 	// iserror={errors?.screeninginvitationExisitingUser}
                    // 	inputValue={inputValue}
                    // 	isSearchable={false}
                    // 	onInputChange={(val) => setInputValue(val)}
                    // 	onChange={(e) => {
                    // 		onChange(e);
                    // 	}}
                    // />
                  )}
                />

                {errors?.screeningRestarted && (
                  <p className="error-msg">{REQUIRED_ERR}</p>
                )}
              </Col>
            </Row>
          </div>
        </div>

        {/*Reminder settings  */}
        <div className="p-4  border-bottom">
          <div className="d-flex align-items-center form-label-title gap-2">
            Reminder settings{" "}
            <CustomToolTip height={12} text="Reminder settings" />
          </div>
          <div className="light-font-text">
            <Row>
              <Col xl={8} className="pe-xl-0">
                Add your wanted reminder settings. Be aware if there are
                conflicts between Reminder 1 and Reminder 2, Reminder 2
                overwrites Reminder 1.
              </Col>
            </Row>
          </div>

          {/* Reminder 1*/}
          <div>
            <div className="py-4">
              <div className="d-flex align-items-center form-label-title gap-2">
                Reminder 1 <CustomToolTip height={12} text="Reminder 1" />
              </div>
              <div className="light-font-text">
                <Row>
                  <Col xl={8} className="pe-xl-0 ">
                    This reminder is sent to the customer during an ongoing
                    screening process. The reminder is sent out according to the
                    interval you choose.
                  </Col>
                </Row>
              </div>
              <div className="d-flex form-label-title pt-4">
                Activate reminder
              </div>
              <div>
                <Controller
                  name="isReminder1activated"
                  control={control}
                  {...register("isReminder1activated")}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <div className="form-input ">
                      <Toggle
                        id="isReminder1activated"
                        checked={value}
                        // checked={value}
                        // onChange={(e) => {
                        // 	onChange(e.target.checked);
                        // 	setToggleReminderOne(e.target.checked);
                        // }}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
            <Row>
              <Col lg={4}>
                {" "}
                <Controller
                  control={control}
                  {...register("reminderOneEmailTemplate")}
                  rules={{ required: toggleReminderOne ? true : false }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Reminder 1"}
                      //   disabled={!toggleReminderOne}
                      value={value}
                      options={reminderTemplateOptions}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
                {errors?.reminderOneEmailTemplate && (
                  <p className="error-msg">{REQUIRED_ERR}</p>
                )}
              </Col>
            </Row>
            <div
              className="d-flex form-label-title py-3"
              style={{ opacity: toggleReminderOne ? "1" : "0.4" }}
            >
              Reminder interval
            </div>
            <Row>
              <Col xl={3} lg={3}>
                <Controller
                  control={control}
                  {...register("reminderInterval1")}
                  rules={{ required: toggleReminderOne ? true : false }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Reminder interval"}
                      //   disabled={!toggleReminderOne}
                      value={value}
                      options={[
                        { value: "180", label: "Every 3 hours" },
                        { value: "360", label: "Every 6 hours" },
                        { value: "720", label: "Every 12 hours" },
                        { value: "1440", label: "Every 24 hours" },
                        { value: "2880", label: "Every 48 hours" },
                      ]}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
                {errors?.reminderInterval1 && (
                  <p className="error-msg">{REQUIRED_ERR}</p>
                )}
              </Col>
            </Row>
          </div>

          {/* Reminder 2*/}
          <div>
            <div className="pt-4">
              <div className="d-flex align-items-center form-label-title gap-2">
                Reminder 2 <CustomToolTip height={12} text="Reminder 2" />
              </div>
              <div className="light-font-text">
                <Row>
                  <Col xl={8} className="pe-xl-0 ">
                    This reminder is sent to the customer as a final reminder
                    when they are approaching the deadline for their ongoing
                    screening. The reminder is only sent out once. If the timing
                    of the reminder conflicts with the automatic flow used with
                    reminder 1, this reminder will override and the flow with
                    reminder 1 will then stop.
                  </Col>
                </Row>
              </div>
              <div className="d-flex form-label-title pt-4 ">
                Activate reminder
              </div>
              <div className="pb-3">
                <Controller
                  name="reminderTwo"
                  control={control}
                  {...register("isReminder2Activated")}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <div className="form-input ">
                      <Toggle
                        id="reminderTwo"
                        value={value}
                        // checked={value}
                        // onChange={(e) => {
                        // 	onChange(e.target.checked);
                        // 	setToggleReminderTwo(e.target.checked);
                        // }}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    </div>
                  )}
                />
              </div>
              <Row>
                <Col lg={4}>
                  <Controller
                    control={control}
                    {...register("reminderTwoEmailTemplate")}
                    rules={{ required: toggleReminderTwo ? true : false }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Choose reminder"}
                        // disabled={!toggleReminderTwo}
                        value={value}
                        options={reminderTemplateOptions}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors?.reminderTwoEmailTemplate && (
                    <p className="error-msg">{REQUIRED_ERR}</p>
                  )}
                </Col>
              </Row>
              <div
                className="d-flex form-label-title py-3"
                style={{ opacity: toggleReminderTwo ? "1" : "0.4" }}
              >
                Reminder interval
              </div>
              <Row>
                <Col xl={3} lg={3}>
                  <Controller
                    control={control}
                    {...register("reminderInterval2")}
                    rules={{ required: toggleReminderTwo ? true : false }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Reminder interval"}
                        // disabled={!toggleReminderTwo}
                        value={value}
                        options={[
                          { value: "60", label: "1 hour before" },
                          { value: "120", label: "2 hours before" },
                          { value: "180", label: "3 hours before" },
                          { value: "360", label: "6 hours before" },
                          { value: "720", label: "12 hours before" },
                        ]}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors?.reminderInterval2 && (
                    <p className="error-msg">{REQUIRED_ERR}</p>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>

        {/*Action in case of yellow or red flags  */}
        {/* {NOTE: THIS PART IS SHIFTED TO 2ND FORM PAGE} */}
        {/* <div className="px-4 pt-4 pb-3 ">
					<div className="d-flex align-items-center form-label-title gap-2">
						Action in case of yellow or red flags{" "}
						<CustomToolTip
							height={12}
							text="Action in case of yellow or red flags"
						/>
					</div>
					<div className="light-font-text">
						<Row>
							<Col xl={8} className="pe-xl-0">
								If a customer receives a yellow og red flag, you kan decide
								whether the screening should be continued or cancelled.
							</Col>
						</Row>
					</div>
				</div>

				<div className="px-4 pb-4 border-bottom">
					<div>
						<div className="d-flex align-items-center form-label-title gap-2">
							Yellow flag <CustomToolTip height={12} text="Yellow flag" />
						</div>
						<div className="light-font-text">
							<Row>
								<Col xl={8} className="pe-xl-0 pb-1">
									What should happen if a question triggers a yellow flag.
								</Col>
							</Row>
						</div>

						<Row className="mt-2">
							<Col xl={4} lg={6} className="pb-1">
								<Controller
									control={control}
									{...register("yellowFlagAction")}
									rules={{ required: false }}
									render={({ field: { onChange, value } }) => (
										<ReactSelect
											placeholder={"Yellow flag action"}
											value={value}
											options={flagTriggerActionData?.map((opt) => ({
												value: opt?.Id,
												label: opt?.Text,
											}))}
											onChange={(e) => {
												onChange(e);
											}}
										/>
									)}
								/>
								{errors?.yellowFlagAction && (
									<p className="error-msg">{REQUIRED_ERR}</p>
								)}
							</Col>
						</Row>
					</div>

					<div className="pt-3">
						<div className="d-flex align-items-center form-label-title gap-2">
							Red flag <CustomToolTip height={12} text="Red flag" />
						</div>
						<div className="light-font-text">
							<Row>
								<Col xl={8} className="pe-xl-0 pb-1">
									What should happen if a question triggers a red flag.
								</Col>
							</Row>
						</div>

						<Row className="mt-2">
							<Col xl={4} lg={6} className="pb-1">
								<Controller
									control={control}
									{...register("redFlag")}
									rules={{ required: false }}
									render={({ field: { onChange, value } }) => (
										<ReactSelect
											placeholder={"Red flag action"}
											value={value}
											options={flagTriggerActionData?.map((opt) => ({
												value: opt?.Id,
												label: opt?.Text,
											}))}
											onChange={(e) => {
												onChange(e);
											}}
										/>
									)}
								/>
								{errors?.redFlag && <p className="error-msg">{REQUIRED_ERR}</p>}
							</Col>
						</Row>
					</div>
				</div> */}

        {/* Restrict the number of customers on a contract */}
        <div className="p-4 ">
          <div className="d-flex align-items-center form-label-title gap-2">
            Restrict the number of customers on a contract{" "}
            <CustomToolTip
              height={12}
              text="Restrict the number of customers on a contract"
            />
          </div>
          <div className="light-font-text">
            <Row>
              <Col xl={8} className="pe-xl-0">
                <div className="pb-2">
                  {" "}
                  It is possible to choose whether there should be a maximum
                  number of customers on a contract. You can choose whether the
                  maximum number should be automatically adjusted based on the
                  number of rooms in the unit or whether there should be a fixed
                  maximum number of customers on the contract regardless of the
                  unit.
                </div>
                It is necessary to select one of these methods and both methods
                cannot be used at the same time.
              </Col>
            </Row>

            <Row>
              <Col className="py-3 " lg={8}>
                <GroupButtons
                  tabs={[
                    {
                      id: "1",
                      tabTitle: "Adjusted max number of customers",
                      // title: "Yes",
                      // content:
                      // "This page contains data related to Map milestone",
                    },
                    {
                      id: "2",
                      tabTitle: "Fixed max number of customers",
                      // title: "No",
                      // content:
                      // "This page contains data related to Chart milestone",
                    },
                  ]}
                  currentTab={numbersOfCustomersTab}
                  handleTabClick={(tab) => {
                    setNumbersOfCustomersTab(tab);

                    setValue("fixed", "");
                    setValue("adjusted", "");
                  }}
                />
              </Col>
            </Row>

            <Row className="pt-3">
              {numbersOfCustomersTab == 0 ? (
                <Col xl={4}>
                  <div className="d-flex align-items-center form-label-title gap-2 pb-3">
                    Adjusted max number of customers{" "}
                    <CustomToolTip
                      height={12}
                      text="Adjusted max number of customers"
                    />
                  </div>
                  <Controller
                    control={control}
                    {...register("adjusted")}
                    rules={{
                      required: numbersOfCustomersTab == 0 ? true : false,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Option"}
                        value={value}
                        options={adjustedMaxCustomerOptions}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors?.adjusted && (
                    <p className="error-msg">{REQUIRED_ERR}</p>
                  )}
                </Col>
              ) : (
                <Col xl={4}>
                  <div className="d-flex align-items-center form-label-title gap-2 pb-3">
                    Fixed max number of customers{" "}
                    <CustomToolTip
                      height={12}
                      text="Fixed max number of customers"
                    />
                  </div>
                  <Controller
                    control={control}
                    {...register("fixed")}
                    rules={{
                      required: numbersOfCustomersTab == 1 ? true : false,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Option"}
                        value={value}
                        options={[
                          {
                            value: "1",
                            label: "Maximum 1 customer on the contract",
                          },
                          {
                            value: "2",
                            label: "Maximum 2 customers on the contract",
                          },
                          {
                            value: "3",
                            label: "Maximum 3 customers on the contract",
                          },
                          {
                            value: "4",
                            label: "Maximum 4 customers on the contract",
                          },
                          {
                            value: "5",
                            label: "Maximum 5 customers on the contract",
                          },
                          {
                            value: "6",
                            label: "Maximum 6 customers on the contract",
                          },
                          {
                            value: "7",
                            label: "Maximum 7 customers on the contract",
                          },
                        ]}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors?.fixed && <p className="error-msg">{REQUIRED_ERR}</p>}
                </Col>
              )}
            </Row>
          </div>
        </div>

        {/* footer */}
        <div className="mt-4 py-2 px-3 d-flex flex-md-row flex-column justify-content-between footer-section-button-container gap-3">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => handleCancel()}
          />
          <div className="d-flex gap-3">
            <ETButton
              variant={"primary"}
              buttonText="Next"
              type={"submit"}
              //   onClick={() => onSubmitSettings()}
            />
          </div>
        </div>
        {/* footer */}
      </Form>
    </div>
  );
};

export default ScreeningFormSettings;
