import React, { useState } from "react";
import GroupButtons from "../../Components/GroupButton/GroupButton";
import { Col, Row } from "react-bootstrap";
import Toggle from "../../Components/Switch/Switch";

import GroupText from "../../Components/GroupText/GroupText";
import { Controller } from "react-hook-form";
import { REQUIRED_ERR } from "../../utils/validationConstants";
import InputField from "../../Components/Textfield/InputField";

const HandleYourBookings = ({
  intervalTimeDuration,
  setIntervalTimeDuration,
  breakTime,
  setBreakTime,
  schedule,
  numberOfParticipant,
  setNumberOfParticipant,
  maximumBooking,
  setMaximumBooking,
  numberOfBooking,
  setNumberOfBooking,
  register,
  control,
  clearErrors,
  errors,
}) => {
  const [handleYourBookingsTabs, setHandleYourBookingsTabs] = useState(0);

  const handlechange = (id) => {
    setHandleYourBookingsTabs(id);
  };
  const handleSlotChange = (index, value) => {
    const updatedSlot = [...numberOfParticipant];
    updatedSlot[index] = value;
    setNumberOfParticipant(updatedSlot);
  };

  const calculateTotalParticipants = () => {
    const totalParticipants = numberOfParticipant.reduce(
      (sum, value) => sum + parseInt(value || 0),
      0
    );
    return totalParticipants;
  };

  return (
    <div
      className="px-4 py-4"
      style={{
        borderTop: "1px solid #f0f0f0",
        borderBottom: "1px solid #f0f0f0",
      }}
    >
      <div className="header-semibold mb-2">
        {" "}
        How do you want to handle your bookings?{" "}
      </div>
      <div className=" light-font-text mb-2 grey-text">
        {" "}
        Handle your bookings by a maximum number of bookings or by manual time
        slots and breaks
      </div>
      <Row>
        <Col className="mt-3" lg={5}>
          <GroupButtons
            tabs={[
              {
                id: 1,
                tabTitle: (
                  <div className="nowrap light-font-text">Maximum bookings</div>
                ),
                title: "Maximum bookings",
              },
              {
                id: 2,
                tabTitle: (
                  <div className="nowrap light-font-text">
                    Time slots and breaks
                  </div>
                ),
                title: "Time slots and breaks",
              },
            ]}
            currentTab={handleYourBookingsTabs}
            handleTabClick={(tab) => {
              handlechange(tab);
            }}
          />
        </Col>
      </Row>

      {handleYourBookingsTabs === 1 ? (
        <div className="mt-3">
          <div className=" light-font-text grey-text">
            Create your time slots and breaks and decide the number of
            participants per slot{" "}
          </div>{" "}
          <Row className="mt-3">
            <Col lg={4} xxl={4} className="pe-lg-0">
              <Controller
                control={control}
                {...register("intervalTimeSlots")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <div className="form-input mb-2">
                    <InputField
                      type="number"
                      placeholder="Interval time slots"
                      id="Intervaltimeslots"
                      onWheel={(e) => e.target.blur()}
                      // onwheel="return false"
                      maxLength={30}
                      iserror={errors?.intervalTimeSlots}
                      value={intervalTimeDuration}
                      onChange={(e) => {
                        onChange(e);
                        setIntervalTimeDuration(e.target.value);
                        clearErrors("intervalTimeSlots");
                      }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                {...register("breakBetweenIntervals")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <div className="form-input">
                    <InputField
                      type="number"
                      placeholder="Break between intervals"
                      id="breakBetweenIntervals"
                      maxLength={30}
                      onWheel={(e) => e.target.blur()}
                      value={breakTime}
                      iserror={errors?.breakBetweenIntervals}
                      onChange={(e) => {
                        onChange(e);
                        setBreakTime(e.target.value);
                        clearErrors("breakBetweenIntervals");
                      }}
                    />
                  </div>
                )}
              />
            </Col>
            <Col
              lg={{ span: 7, offset: 1 }}
              xxl={{ span: 6, offset: 2 }}
              className="ps-lg-0"
            >
              <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                {schedule?.map((item, index) => {
                  return (
                    <div key={index} className="pb-2 pt-2">
                      <Controller
                        control={control}
                        {...register(`numberOfParticipant.${index}`)}
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <GroupText
                            lable="Number of participants per time slot"
                            type="Number"
                            onWheel={(e) => e.target.blur()}
                            id={`numberOfParticipant.${index}`}
                            dynamicGrayLabel={false}
                            setSlot={true}
                            graylabel={
                              <div className="d-flex gap-1 ">
                                <div className="normal-font-text fs-14 grey-text">
                                  {index + 1} time slot:{" "}
                                </div>
                                <div className="form-label-title">{item}</div>
                              </div>
                            }
                            textWidth="50%"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors(`numberOfParticipant.${index}`);
                              handleSlotChange(index, e.target.value);
                            }}
                          />
                        )}
                      />
                      {errors?.numberOfParticipant?.[index] &&
                        errors?.numberOfParticipant?.[index]?.type ===
                          "required" && (
                          <p className="error-msg ">{REQUIRED_ERR}</p>
                        )}
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="ps-lg-0 mt-2" lg={{ span: 6, offset: 6 }}>
              <div className="fs-14">
                <span className="form-label-title"> Total count: </span> This
                will give you{" "}
                <span className="form-label-title">
                  {" "}
                  {calculateTotalParticipants()} participants{" "}
                </span>{" "}
                for your event{" "}
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <>
          <div className="mt-3">
            <div className=" light-font-text grey-text">
              Select the maximum of participants that can book this event
            </div>{" "}
            <Row>
              {" "}
              <Col className="d-flex align-items-center" lg={3}>
                <div
                  className="form-input mt-3"
                  style={{ opacity: maximumBooking ? 0.5 : "" }}
                >
                  <input
                    disabled={maximumBooking ? true : false}
                    type="number"
                    placeholder="Number of booking"
                    id="NoofBookings"
                    value={numberOfBooking || ""}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => setNumberOfBooking(e.target.value)}
                  />
                  <label htmlFor="NoofBookings">Number of booking</label>
                </div>
              </Col>
              <Col lg={3} className="d-flex pt-3 gap-2 align-items-center">
                {" "}
                <div className="light-font-text">
                  No limit of bookings{" "}
                </div>{" "}
                <Toggle
                  id={22}
                  checked={maximumBooking}
                  onChange={(e) => {
                    setMaximumBooking(e.target.checked);
                    setNumberOfBooking("");
                  }}
                />{" "}
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};

export default HandleYourBookings;
