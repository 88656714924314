import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { REQUIRED_ERR } from "../../utils/validationConstants";
import { H5 } from "../../styles/Common.style";

const IconTextField = ({
  placeholder,
  id,
  value,
  onChange,
  Icons,
  type,
  maxlength,
  style,
  inputHeader,
  isError,
  errormessage = "",
  errorscope = true,
}) => {
  const [show, setShow] = useState(true);
  const handleClick = () => {
    if (type && "password" && value?.length) {
      setShow(!show);
    }
  };
  return (
    <>
      <H5 className="pb-2" style={{ color: "#fff" }}>
        {inputHeader}
      </H5>
      <div className="d-flex w-100">
        <div style={{ width: "100%" }}>
          <div className={` ${isError ? "icon-error-input" : "icon-input"}`}>
            <input
              autoComplete="off"
              style={style}
              type={
                type == "password" && value?.length
                  ? show == true
                    ? "password"
                    : "text"
                  : type
              }
              placeholder={placeholder}
              id={id}
              value={value}
              onChange={onChange}
              maxLength={maxlength}
            />
            <label htmlFor={id}>{placeholder}</label>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <FontAwesomeIcon
            onClick={handleClick}
            icon={
              type == "password" && value?.length
                ? show == true
                  ? "fa-regular fa-eye"
                  : "fa-regular fa-eye-slash"
                : Icons
            }
            style={{
              color: "#000",
              position: "absolute",
              top: "13px",
              right: "15px",
            }}
            className="cursor-pointer"
          />
        </div>
      </div>
      {errorscope && isError && (
        <p className="error-msg">
          {errormessage ? errormessage : REQUIRED_ERR}
        </p>
      )}
    </>
  );
};

export default IconTextField;

export const IconField = ({
  placeholder,
  id,
  value,
  onChange,
  maxlength,
  Icons,
  type,
  disabled,
  iserror,
  errormessage,
  errorscope = true,
  removeOpacity = false,
}) => {
  return (
    <>
      <div className="d-flex w-100">
        <div style={{ position: "relative", width: "100%" }}>
          <div
            className={` ${iserror ? "form-error-input" : "form-input"}`}
            style={{ opacity: disabled && !removeOpacity ? "0.7" : "1" }}
          >
            <input
              autoComplete="off"
              type={type}
              autoCapitalize="false"
              placeholder={placeholder}
              id={id}
              value={value}
              onChange={onChange}
              maxlength={maxlength}
              disabled={disabled}
            />
            <label htmlFor={id}>{placeholder}</label>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <FontAwesomeIcon
            icon={Icons}
            style={{
              color: "#000000",
              position: "absolute",
              top: "12px",
              right: "15px",
            }}
            className="cursor-pointer"
          />
        </div>
      </div>
      {errorscope && iserror && (
        <p className="error-msg">
          {errormessage ? errormessage : REQUIRED_ERR}
        </p>
      )}
    </>
  );
};
