import React from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../../../Components/CustomPopup";
import ReactSelect from "../../../../../../Components/DropDown/ReactSelect";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useGetFacilityAttributesByFacilityId } from "../../../../../../hooks/queries/usefacilityQueries";
import GroupText from "../../../../../../Components/GroupText/GroupText";

const ViewDetailsModal = ({
	propId,
	UnitId,
	unitFacilityId,
	facilityPackageId,
	facilityId,
	setDefaultAttributeFieldDetail,
	openViewDetailsModal,
	setOpenViewDetailsModal,
}) => {
	const {
		register,
		formState: { errors },

		control,

		reset,
	} = useForm({
		defaultValues: {
			attributeDetails: [],
		},
	});

	const { data: GetFacilityAttributesByFacilityIdData } =
		useGetFacilityAttributesByFacilityId(
			propId,
			unitFacilityId,
			facilityPackageId,
			facilityId
		);
	// console.log(GetFacilityAttributesByFacilityIdData?.data[0], "facilityData");
	useEffect(() => {
		let facilityData = GetFacilityAttributesByFacilityIdData?.data[0];

		onInitSetAttributeDetail(facilityData);
	}, [GetFacilityAttributesByFacilityIdData]);

	const onInitSetAttributeDetail = (data) => {
		//Default Attribute FieldDetail for append or default set when no record is there
		let attributeFieldDetail = data?.attribute_field_details;
		setDefaultAttributeFieldDetail(attributeFieldDetail);

		if (data?.attribute_data_details?.length !== 0) {
			let facilityAttributeData = [];
			data?.attribute_data_details?.forEach((row, rowIndex) => {
				let fieldsData = [];
				row.forEach((attribute, colIndex) => {
					let fieldAttribute = structuredClone(
						attributeFieldDetail.find(
							(x) => x.facility_attribute_id === attribute.facility_attribute_id
						)
					);

					fieldAttribute["attribute_value"] =
						fieldAttribute.attribute_type === "DROPDOWN"
							? {
									value: attribute.attribute_value,
									label: attribute.attribute_value,
							  }
							: attribute.attribute_value;
					fieldsData.push(fieldAttribute);
				});
				facilityAttributeData.push({
					row_id: Math.floor(Date.now() / 1000),
					fields: fieldsData,
				});
			});
			reset({
				attributeDetails: facilityAttributeData,
			});
		} else {
			reset({
				attributeDetails: [
					{
						row_id: Math.floor(Date.now() / 1000),
						fields: attributeFieldDetail,
					},
				],
			});
		}
	};

	const { fields } = useFieldArray({
		control,
		name: "attributeDetails",
	});
	const handleCancelDetails = () => {
		setOpenViewDetailsModal(false);
	};
	// console.log(fields[0].fields[0], "fieldsfields");
	return (
		<>
			<div>
				<CustomPopup
					style={{ position: "relative" }}
					size="md"
					open={openViewDetailsModal}
					onHide={() => setOpenViewDetailsModal(false)}
					title={<div className="form-label-title">View Details</div>}
					title_color="black"
					titleTooltipText="View Details"
					titleTooltip={true}
					enableCrossButton={false}
					onCloseAction={() => {
						setOpenViewDetailsModal(false);
						alert("Action Peformed Successfuly.");
					}}>
					{fields[0]?.fields?.length === 0 ||
					fields[0]?.fields === undefined ? (
						<div
							className="grey-text fs-12 fw-300 py-3"
							style={{ textAlign: "center" }}>
							No detail added yet.
						</div>
					) : (
						<div>
							<div
								className="ps-2 pe-3 ms-1 me-2 pt-2 pb-3 scrollable-container-y"
								style={{
									height: "263px",
									marginRight: "6px",
									// paddingBottom: "60px",
								}}>
								<>
									{fields?.map((feild, index) => {
										return (
											<Row
												style={{
													display: "flex",
													justifyContent: "flex-start",
													padding: "1rem",
												}}>
												{feild?.fields?.map((f, i) => {
													return (
														<>
															{f?.attribute_type === "TEXT" ||
																(f?.attribute_type === "DECIMAL" ||
																f?.attribute_type === "NUMBER" ? (
																	<Col md={6}>
																		<Controller
																			control={control}
																			{...register(
																				`attributeDetails[${index}].fields[${i}].attribute_value`
																			)}
																			rules={{ required: true }}
																			render={({
																				field: { onChange, value },
																			}) =>
																				f?.postfix !== "" ? (
																					<GroupText
																						lable={f?.attribute_name}
																						type={f?.attribute_type}
																						name={f?.attribute_name}
																						id="inputwithSuffix"
																						graylabel={f?.postfix}
																						disabled={true}
																						textWidth={
																							f?.postfix?.length >= 7
																								? "40%"
																								: "20%"
																						}
																						value={value}
																					/>
																				) : (
																					<>
																						<div className="form-input ">
																							<input
																								type={f?.attribute_type}
																								placeholder={f?.attribute_name}
																								id="long"
																								value={value}
																								disabled
																								error={errors.attribute_value}
																							/>
																							<label htmlFor="long">
																								{f?.attribute_name}
																							</label>
																						</div>
																					</>
																				)
																			}
																		/>
																	</Col>
																) : (
																	f?.attribute_type === "DROPDOWN" && (
																		<Col md={5}>
																			<Controller
																				control={control}
																				{...register(
																					`attributeDetails[${index}].fields[${i}].attribute_value`
																				)}
																				rules={{ required: true }}
																				render={({
																					field: { onChange, value },
																				}) => (
																					<ReactSelect
																						// name={"attribute_value"}
																						placeholder={f?.attribute_name}
																						value={value}
																						options={f?.possible_values?.map(
																							(item) => ({
																								value: item?.Id,
																								label: item?.Text,
																							})
																						)}
																						disabled={true}
																						isSearchable={false}
																						isClearable={false}
																					/>
																				)}
																			/>{" "}
																		</Col>
																	)
																))}
														</>
													);
												})}
											</Row>
										);
									})}
								</>
							</div>
						</div>
					)}

					<div
						className="py-2 px-3 d-flex justify-content-end footer-section-button-container"
						// style={{
						//   backgroundColor: "#F9FAFA",
						//   borderRadius: " 0px 0px 8px 8px",
						//   position: "absolute",
						//   width: "100%",
						//   bottom: "-52px",
						// }}
					>
						<ETButton
							variant={"primary"}
							buttonText="OK"
							onClick={() => handleCancelDetails()}
						/>
					</div>
				</CustomPopup>
			</div>
		</>
	);
};

export default ViewDetailsModal;
