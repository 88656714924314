import React, { useRef, useState, useMemo } from "react";
import CSVWidget from "../../../Components/DownloadWidget/CSVWidget";

import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { Row, Col, Image } from "react-bootstrap";
import Menus from "../../../Components/Menus/Menus";
import dummyproperty from "../../../assets/images/photo-coming-soon.jpg";
import { MaterialReactTable } from "material-react-table";
import { GreenDiv, H3 } from "../../../styles/Common.style";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import MRTTheme from "../../../Components/table/MRTTheme";
import { H2 } from "../../../styles/Common.style";
import { SortIcon } from "../../../Components/table/MRTGlobalProps";

const PopularUnits = () => {
  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "popular-units";

  const options = {
    keys: [
      { field: "address" },
      { field: "unit_no" },
      { field: "property" },
      { field: "views" },
      { field: "rooms" },
      { field: "metersq" },
      { field: "listing" },
    ],
  };
  const PopularUnitData = [
    {
      address: "Bygården 2, 1. 5",
      unit_no: "21",
      property: "Bygården",
      views: 0,
      rooms: 4,
      metersq: "117 m²",
      listing: "14.600 DKK",
    },
    {
      address: "Bygården 2, 1. 5",
      unit_no: "21",
      property: "Bygården",
      views: 0,
      rooms: 4,
      metersq: "117 m²",
      listing: "14.600 DKK",
    },
    {
      address: "Bygården 2, 1. 5",
      unit_no: "21",
      property: "Bygården",
      views: 0,
      rooms: 4,
      metersq: "117 m²",
      listing: "14.600 DKK",
    },
    {
      address: "Bygården 2, 1. 5",
      unit_no: "21",
      property: "Bygården",
      views: 0,
      rooms: 4,
      metersq: "117 m²",
      listing: "14.600 DKK",
    },
    {
      address: "Bygården 2, 1. 5",
      unit_no: "21",
      property: "Bygården",
      views: 0,
      rooms: 4,
      metersq: "117 m²",
      listing: "14.600 DKK",
    },
    {
      address: "Bygården 2, 1. 5",
      unit_no: "21",
      property: "Bygården",
      views: 0,
      rooms: 4,
      metersq: "117 m²",
      listing: "14.600 DKK",
    },
    {
      address: "Bygården 2, 1. 5",
      unit_no: "21",
      property: "Bygården",
      views: 0,
      rooms: 4,
      metersq: "117 m²",
      listing: "14.600 DKK",
    },
    {
      address: "Bygården 2, 1. 5",
      unit_no: "21",
      property: "Bygården",
      views: 0,
      rooms: 4,
      metersq: "117 m²",
      listing: "14.600 DKK",
    },
    {
      address: "Bygården 2, 1. 5",
      unit_no: "21",
      property: "Bygården",
      views: 0,
      rooms: 4,
      metersq: "117 m²",
      listing: "14.600 DKK",
    },
  ];

  const data = PopularUnitData;

  const CSVFunction = CSVWidget({ refObj, fileName, options, data });

  const columns = useMemo(() => [
    {
      header: "Address",
      accessorKey: "address",

      enableHiding: false,
      enableGlobalFilter: false,

      size: 180,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "unit_no",
      header: "Unit no",
      size: 20,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
    },
    {
      accessorKey: "property",
      header: "Property",
      size: 10,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "views",
      header: "Views",
      size: 5,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
    },
    {
      accessorKey: "rooms",
      header: "Rooms",
      size: 50,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
    },
    {
      accessorKey: "metersq",
      header: "m²",
      size: 50,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
    },
    {
      accessorKey: "listing",
      header: "Listing",
      size: 180,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
    },
  ]);

  return (
    <div className="card-body p-3" ref={myref}>
      <Row>
        <Col className="p-0" sm={8}>
          {" "}
          <div className="d-flex ps-3 align-items-center">
            <H3 className=" me-2">Popular units</H3>{" "}
            <CustomToolTip size="sm" text="Popular units" />
          </div>
        </Col>
        <Col sm={4} className="d-flex justify-content-end">
          {" "}
          <Menus
            itemheight={40}
            itemwidth={40}
            itemcontainerheight={200}
            itemcontainerwidth={200}
            component={
              <div className="fs-14">
                <div className="addpropContent px-3 py-2 ">
                  <span className="ms-1" onClick={() => CSVFunction()}>
                    Download widget as CSV
                  </span>
                </div>
              </div>
            }
          />
        </Col>
      </Row>

      {PopularUnitData.length == 0 ? (
        <>
          <Row>
            <Col className="p-0">
              <H3 className=" fs-13 pb-3 pt-4 ps-3">Top 3 units</H3>
            </Col>
          </Row>

          <Row
            style={{ backgroundColor: "#f4f5f5" }}
            className="py-3 px-3 mx-1 radius-4"
          >
            <Col lg={4} sm={12} className="pb-lg-0 pb-3">
              <div className="d-flex align-items-center gap-3">
                <div>
                  <Image
                    src={dummyproperty}
                    height="89px"
                    width="89px"
                    rounded
                  />
                </div>
                <div>
                  <div className="form-label-title fs-13">Vistihøjen 28,</div>
                  <div className="light-font-text fs-13">Lisbjerg MIAA</div>
                  <div className="light-font-text fs-13">22</div>
                  <GreenDiv className="form-label-title fs-13  py-1">
                    0 Views
                  </GreenDiv>
                </div>
              </div>
            </Col>
            <Col lg={4} sm={12} className="pb-lg-0 pb-3">
              <div className="d-flex align-items-center gap-3">
                <div>
                  <Image
                    src={dummyproperty}
                    height="89px"
                    width="89px"
                    rounded
                  />
                </div>
                <div>
                  <div className="form-label-title fs-13">Vistihøjen 28,</div>
                  <div className="light-font-text fs-13">Lisbjerg MIAA</div>
                  <div className="light-font-text fs-13">22</div>
                  <GreenDiv className="form-label-title fs-13  py-1">
                    0 Views
                  </GreenDiv>
                </div>
              </div>
            </Col>
            <Col lg={4} sm={12} className="pb-lg-0 pb-3">
              <div className="d-flex align-items-center gap-3">
                <div>
                  <Image
                    src={dummyproperty}
                    height="89px"
                    width="89px"
                    rounded
                  />
                </div>
                <div>
                  <div className="form-label-title fs-13">Vistihøjen 28,</div>
                  <div className="light-font-text fs-13">Lisbjerg MIAA</div>
                  <div className="light-font-text fs-13">22</div>
                  <GreenDiv className="form-label-title fs-13  py-1">
                    0 Views
                  </GreenDiv>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="p-0 mx-2">
              <MRTTheme
                colorVariantsForTableData={["odd"]}
                table={
                  <MaterialReactTable
                    columns={columns}
                    data={PopularUnitData}
                    icons={{ ...SortIcon }}
                    enableTopToolbar={false}
                    enableBottomToolbar={false}
                    enableSelectAll={false}
                    enableColumnActions={false}
                    enableColumnFilter={false}
                    enableResizing={false}
                    initialState={{ showGlobalFilter: true }}
                    // muiTableHeadCellProps={{
                    //   elevation: 0,
                    //   sx: {
                    //     fontWeight: "600",
                    //     fontSize: "13px",
                    //     fontFamily: "Cerebri sans !important",
                    //     boxShadow: "none",
                    //   },
                    // }}
                    // muiTableBodyRowProps={{
                    //   fontFamily: "Cerebri Sans",
                    //   fontSize: "13px",
                    // }}
                    // muiTableBodyProps={{
                    //   sx: {
                    //     fontFamily: "Cerebri Sans",
                    //     //stripe the rows, make odd rows a darker color
                    //     "& tr:nth-of-type(odd)": {
                    //       backgroundColor: "#F9FAFA",
                    //     },
                    //     td: {
                    //       fontFamily: "Cerebri Sans",
                    //       fontSize: "13px",
                    //       fontWeight: 300,
                    //     },
                    //   },
                    // }}
                    // muiTablePaperProps={{
                    //   elevation: 0, //change the mui box shadow
                    //   //customize paper styles
                    //   sx: {
                    //     borderRadius: "0",
                    //     // padding: "1rem",
                    //   },
                    // }}
                    // muiTableContainerProps={{
                    //   sx: {
                    //     paddingX: "3px",
                    //     paddingTop: "10px",
                    //   },
                    // }}
                    // muiTableHeadRowProps={{
                    //   sx: { boxShadow: "none" },
                    // }}
                    enableColumnFilterModes={false}
                  />
                }
              />
            </Col>
          </Row>
        </>
      ) : (
        <NoDataScreen height={"377px"} imgHeight={"150px"} />
      )}
    </div>
  );
};

export default PopularUnits;
