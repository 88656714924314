import React, { useEffect, useMemo, useState } from "react";
import { CircleMsg, H3 } from "../../../../styles/Common.style";
import MRTTheme from "../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { Col, Row } from "react-bootstrap";
import { NoRecordsToDisplay } from "../../../../utils/validationConstants";
import DateRangeFilter from "../../../../Components/DateRangePicker/DateRangeFilter";
import Menus from "../../../../Components/Menus/Menus";
import {
  SortIcon,
  SearchStyle,
  SearchIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../../Components/table/MRTGlobalProps";
import { useMediaQuery } from "react-responsive";
import EditSigningOrder from "../../../../Components/EditSigningOrder/EditSigningOrder";
import SigningOrderMouserOver from "../pages/signingOrderMouseOver/SigningOrderMouseOver";
import CancelWizard from "../../standardComponents/cancelSigningOrder/CancelWizard";
import { useGetUserByDivisionId } from "../../../../hooks/queries/useUserQueries";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";
import { useGetSigningOrderDetails } from "../../../../hooks/queries/useCustomerFunnel";
import moment from "moment";

const QualityControl = () => {
  /* ------------------ Zustand ----------------- */
  const { userDetails } = useUserDetails((state) => state);
  /* ------------------ State ----------------- */

  const [openCancelWizardModal, setOpenCancelWizardModal] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 1224 });
  const [openEditSigningOrder, setOpenEditSigningOrder] = useState(false);
  const [remindEditSigningOrder, setRemindEditSigningOrder] = useState(false);
  const [deleteEditSigningOrder, setDeleteEditSigningOrder] = useState(false);
  const [columnPinning, setColumnPinning] = useState({});
  const [responsibleAgentFilter, setResponsibleAgentFilter] = useState("");
  const [qualityControlData, setQualityControlData] = useState("");
  const [switchWizardScreen, setSwitchWizardScreen] = useState(
    "SelectReasonSigningOrder"
  );
  /* ------------------ React Query ----------------- */
  const { data: getResponsibleAgentDetailsData } = useGetUserByDivisionId(
    userDetails?.division_id
  );
  const { data: getSigningOrderDetails } = useGetSigningOrderDetails(
    userDetails?.division_id
  );

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.total_signed} ${row.total_signed}`,
        header: "Status",
        enableHiding: false,
        size: 90,
        enableGlobalFilter: true,
        Cell: ({ row }) => <SigningOrderMouserOver row={row} />,
        muiTableBodyCellProps: {
          sx: {
            backgroundColor: "#F0F0F0 !important",
            border: "none !important",
          },
        },
      },
      {
        accessorKey: "signer",
        header: "Signers",
        size: 200,
        enableGlobalFilter: true,
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
            backgroundColor: "#F0F0F0 !important",
            border: "none !important",
            borderRight: "1px solid #888888 !important",
            boxShadow: "none !important",
          },
        },
        Cell: ({ row }) => (
          <div className=" d-flex align-items-center gap-1">
            <div>
              {row?.original?.signer == ""
                ? "-"
                : row?.original?.signer?.split(",")?.[0]}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "22px",
                height: "22px",
                borderRadius: "100px",
                backgroundColor: "#FFFFFF",
                border: "1px solid #D1D1D1",
                alignItems: "center",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              +{row?.original?.signer?.split(",")?.length - 1}
            </div>
          </div>
        ),
      },
      {
        accessorKey: "unit",
        header: "Unit",
        enableHiding: false,
        size: 300,
        enableGlobalFilter: true,
        Cell: ({ row }) => <div>-</div>,
      },
      {
        accessorKey: "signing_created",
        header: "Signing created",
        enableHiding: false,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div>
            {row?.original?.signing_created == ""
              ? "-"
              : moment(row?.original?.signing_created).format(
                  "DD MMM, YYYY [at] hh:mm"
                )}
          </div>
        ),
      },
      {
        accessorKey: "Signing_expires",
        header: "Signing expires",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div>
            {row?.original?.Signing_expires == ""
              ? "-"
              : row?.original?.Signing_expires}
          </div>
        ),
      },
      {
        accessorKey: "responsible_name",
        header: "Responsible Agent",
        enableHiding: false,
        // size: 20,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>
            {row?.original?.responsible_name == ""
              ? "-"
              : row?.original?.responsible_name}
          </div>
        ),
        muiTableBodyCellProps: {
          sx: {
            border: "none !important",
          },
        },
      },
    ],
    []
  );
  //--------------------- useEffect ---------------//
  useEffect(() => {
    if (isDesktop) {
      setColumnPinning({
        left: ["Status", "signer"],
        right: ["mrt-row-actions"],
      });
    } else {
      setColumnPinning({
        right: ["mrt-row-actions"],
      });
    }
  }, [isDesktop]);

  useEffect(() => {
    setQualityControlData(getSigningOrderDetails?.data);
  }, [getSigningOrderDetails]);
  //---------------------handle Filter Responsible Agent---------------//
  const handleResponsibleAgentFilter = (selectOption) => {
    setResponsibleAgentFilter(selectOption);
    if (selectOption !== null) {
      const filterResponsibleAgent = getSigningOrderDetails?.data?.filter(
        (item) => item?.responsible_name === selectOption?.label
      );
      setQualityControlData(filterResponsibleAgent);
    } else {
      setQualityControlData(getSigningOrderDetails?.data);
    }
  };

  return (
    <div>
      <div className="d-flex w-100">
        <div className="p-4 mt-2 w-75">
          <div className="d-flex align-items-center">
            <H3> Waiting for quality control </H3>
            <CircleMsg>
              <span>3</span>
            </CircleMsg>
          </div>
          <div className="light-font-text mt-2">
            Below is a list of ongoing signing orders that are currently
            awaiting approval from quality control.
          </div>
        </div>
      </div>
      <div className="px-4 pb-5">
        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={columns}
              data={qualityControlData || []} //data is undefined on first render
              // data={[]}
              icons={{ ...SortIcon }}
              localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
              enableColumnActions={false}
              enableTopToolbar={true}
              enableRowSelection={false}
              enableRowActions
              enableSelectAll
              enablePinning
              onColumnPinningChange={setColumnPinning}
              state={{ columnPinning }}
              enableFilterMatchHighlighting={true}
              enableGlobalFilter
              globalFilterFn="contains"
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "",
                  size: 50,
                  muiTableBodyCellProps: {
                    sx: {
                      // padding: "0px !important",
                      backgroundColor: "#F0F0F0 !important",
                      border: "none !important",
                    },
                  },
                },
              }}
              positionActionsColumn="last"
              renderRowActions={({ row }) => (
                <Menus
                  itemheight={23}
                  itemwidth={23}
                  iconborderradius="4px"
                  itemcontainerwidth="150px"
                  component={
                    <div className="light-font-text">
                      <div
                        className="p-2 cursor-pointer"
                        onClick={() => setOpenEditSigningOrder(true)}
                      >
                        Edit signing order
                      </div>
                      <hr className="hr m-0"></hr>
                      <div
                        className="p-2 cursor-pointer"
                        onClick={() => setOpenCancelWizardModal(true)}
                      >
                        Cancel signing order
                      </div>
                      <hr className="hr m-0"></hr>
                      <div className="p-2 cursor-pointer">Go to contract</div>
                      <hr className="hr m-0"></hr>
                      <div className="p-2 cursor-pointer">
                        Go to customer card
                      </div>
                    </div>
                  }
                />
              )}
              renderTopToolbar={({ table }) => (
                <>
                  <div className="px-0 ">
                    <Row>
                      <Col xl={4} lg={6} className="pb-2">
                        <DateRangeFilter
                        // date={dateRange}
                        // setDate={setDateRange}
                        // handleDateChange={handleDateChange}
                        />
                      </Col>
                      <Col xl={3} lg={5} className="ps-lg-0 pb-2">
                        <ReactSelect
                          placeholder={"Responsible agent"}
                          value={responsibleAgentFilter}
                          options={getResponsibleAgentDetailsData?.data?.map(
                            (item) => ({
                              value: item?.Id,
                              label: item?.Text,
                            })
                          )}
                          onChange={handleResponsibleAgentFilter}
                        />
                      </Col>
                    </Row>
                  </div>
                </>
              )}
              muiTablePaperProps={{
                selected: false,
                elevation: 0,
                fontFamily: "Cerebri sans-light !important",
                sx: {
                  // backgroundColor: "#F9FAFA",
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                  "& td:nth-last-child(1)": {
                    borderLeft: "1px solid #888888 !important",
                  },
                },
              }}
              muiSearchTextFieldProps={{
                ...SearchStyle,
                InputProps: {
                  ...SearchIcon,
                },
              }}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
            />
          }
        />
      </div>
      {/* <CancelWizard
        openCancelWizardModal={openCancelWizardModal}
        setOpenCancelWizardModal={setOpenCancelWizardModal}
        switchWizardScreen={switchWizardScreen}
        setSwitchWizardScreen={setSwitchWizardScreen}
        nextWizardScreenText={"SelectReasonSigningOrder"}
        cancelReasonHeader="Select reason for cancellation"
      /> */}
      <CancelWizard
        title={"Cancel signing order"}
        titleTooltip
        unitSection
        customerSection
        customerInfo="Nikolas Karpermur, Rikke Violette Nielsen"
        unitInfo="Kaktus Towers, Dybbølsbro 3, 14., Door 4, 7-NW320, 112-003-002"
        Status={"The screening is moved to the Declined screenings tab."}
        titleTooltipText={"Cancel signing order"}
        openCancelWizardModal={openCancelWizardModal}
        setOpenCancelWizardModal={setOpenCancelWizardModal}
        switchWizardScreen={switchWizardScreen}
        setSwitchWizardScreen={setSwitchWizardScreen}
        nextWizardScreenText={"SelectReasonSigningOrder"}
        cancelReasonHeader={"Select reason for cancellation"}
        cancelSecondPageReasonHeader={"Select reason for cancellation"}
        cancelReasonText={
          "When a signing order is canceled, you must select one or more cancellation reasons below.If there is no suitable reason, the option Other can be used."
        }
        cancelReasonHeaderTooltip={"Select reason for cancellation"}
        cancelSecondPageReasonHeaderTooltip={"Select reason for cancellation"}
        cancelReasonSecondPageFooter="Send confirmation to:"
        cancelSecondPageReasonFooterTooltip={"Send confirmation to"}
        secondPageStatus="It’s only possible to send to MyPage if the customer has a active profile."
      />
      {/* ------------------ Edited Signing  orders ----------------- */}
      <EditSigningOrder
        openEditSigningOrder={openEditSigningOrder}
        setOpenEditSigningOrder={setOpenEditSigningOrder}
        remindEditSigningOrder={remindEditSigningOrder}
        setRemindEditSigningOrder={setRemindEditSigningOrder}
        deleteEditSigningOrder={deleteEditSigningOrder}
        setDeleteEditSigningOrder={setDeleteEditSigningOrder}
      />
    </div>
  );
};
export default QualityControl;
