import React, { useEffect, useMemo, useState } from "react";
import { H2, H5 } from "../../../styles/Common.style";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import MRTTheme from "../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";
import { Col, Row } from "react-bootstrap";
import DateRangeFilter from "../../../Components/DateRangePicker/DateRangeFilter";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import Menus from "../../../Components/Menus/Menus";
import {
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../Components/table/MRTGlobalProps";
import TagToolTip from "../../../Components/Tooltip/PropertyTagToolTip";
import ETButton from "../../../Components/Buttons/ETButton";
import { NoRecordsToDisplay } from "../../../utils/validationConstants";

import { useMediaQuery } from "react-responsive";
// import ShowTooltip from "../../../Components/Tooltip/ShowTooltip";

import AddResponsibleAgent from "../standardComponents/AddResponsibleAgent";
import {
  useGetLeadsByDivisionId,
  usePutUpdateFavouriteLead,
  usePutUpdateLeadAgent,
} from "../../../hooks/queries/useCustomerFunnel";
import { useUserDetails } from "../../../hooks/stores/useUserDetailsStore";
import moment from "moment";

import CustomPopup from "../../../Components/CustomPopup";
import { DeleteLead } from "../../../services/CustomerFunnel";
import { status } from "../../../model/Constant";
import { toast } from "react-toastify";
import CustomerDetailTooltip from "../standardComponents/CustomerDetailTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetUserByDivisionId } from "../../../hooks/queries/useUserQueries";
import CreateNewlead from "./createNewLead/CreateNewlead";
import Heart from "../../../Components/Star/Heart";
import FollowUp from "../standardComponents/follow up/FollowUp";
import { endOfDay, startOfMonth } from "date-fns";

const Leads = () => {
  // ================ zustand ============== //
  const { userDetails } = useUserDetails((state) => state);

  // below code is for give responsive design to fixed columns
  const isDesktop = useMediaQuery({ minWidth: 1224 });

  //================ States ================//
  const [showResponsibleAgentPopup, setShowResponsibleAgentPopup] =
    useState(false);
  const [archiveLeadPopup, setArchiveLeadPopup] = useState({
    lead_id: "",
    isPopupOpen: false,
    isArchiveApiLoading: false,
  });
  const [showResponsibleAgentWarnigPopup, setShowResponsibleAgentWarnigPopup] =
    useState({ isPopupOpen: false });
  const [columnPinning, setColumnPinning] = useState({});
  const [responsibleAgentFilter, setResponsibleAgentFilter] = useState("");
  const [leadDataTable, setLeadDataTable] = useState("");
  const [showNewLead, setShowNewLead] = useState(false);
  const [rowSelection, setRowSelection] = useState({}); // Initialize as an empty object
  const [responsibleAgentId, setResponsibleAgentId] = useState(""); //Id for filtering add responsible agent data
  const [leadId, setLeadId] = useState(""); //used for adding responsible agent & to check condition & to use for follow ups
  const [openCustomerCard, setOpenCustomerCard] = useState(false);
  const [selectedFollowUpIndex, setSelectedFollowUpIndex] = useState(0);
  const [showSendConfirmationEmail, setShowSendConfirmationEmail] =
    useState(false);

  const [dateFilter, setDateFilter] = useState("");

  // ================ react queries ============== //
  const {
    data: GetLeadsByDivisionIdData,
    refetch: GetLeadsByDivisionIdDataRefetch,
  } = useGetLeadsByDivisionId(userDetails?.division_id, dateFilter);

  const {
    mutateAsync: PutUpdateLeadAgent,
    isLoading: PutUpdateLeadAgentIsLoading,
  } = usePutUpdateLeadAgent();

  const { data: GetUserByDivisionIdData } = useGetUserByDivisionId(
    userDetails?.division_id
  );
  const { mutateAsync: PutUpdateFavouriteLead } = usePutUpdateFavouriteLead();

  //================ useEffect ================//

  useEffect(() => {
    setLeadDataTable(GetLeadsByDivisionIdData?.data);
  }, [GetLeadsByDivisionIdData]);

  useEffect(() => {
    if (isDesktop) {
      setColumnPinning({
        left: ["mrt-row-select", "Id", "Status", "Customer"],
        right: ["mrt-row-actions"],
      });
    } else {
      setColumnPinning({});
    }
  }, [isDesktop]);

  //================ handle Favourite Lead ================//
  const handleFavouriteLead = async (actor_id, is_favorite) => {
    const favouriteLeadPlayload = {
      actor_id: actor_id,
      is_favorite: is_favorite == 1 ? 0 : 1,
    };
    try {
      const result = await PutUpdateFavouriteLead(favouriteLeadPlayload);
      if (result?.status === status.s && result?.data?.bReturn) {
        toast.success(result?.data?.message);
        GetLeadsByDivisionIdDataRefetch();
      } else {
        toast.error(result?.data?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };
  const LeadsColumnData = useMemo(
    () => [
      {
        accessorKey: "Id",
        header: "",
        enableHiding: false,
        size: 40,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <>
            <Heart
              isFavorite={row?.original?.is_favorite}
              handleClick={() =>
                handleFavouriteLead(
                  row?.original?.actor_id,
                  row?.original?.is_favorite
                )
              }
            />
            {/* <FontAwesomeIcon
              icon={"fa-solid fa-heart"}
              size="lg"
              className="cursor-pointer"
              style={{
                stroke: "#AA1518",
                strokeWidth: "30px",
                color: `${
                  row?.original?.is_favorite == "1" ? "#AA1518" : "#AA151833"
                }`,
              }}
              onClick={() =>
                handleFavouriteLead(
                  row?.original?.actor_id,
                  row?.original?.is_favorite
                )
              }
            /> */}
          </>
        ),

        muiTableHeadCellProps: {
          sx: {
            paddingRight: "0px !important",
            paddingLeft: "2px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "0px !important",
            paddingLeft: "2px !important",
            backgroundColor: "#F0F0F0 !important",
          },
        },
      },
      {
        accessorFn: (row) => row?.lead_id,
        header: "Status",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                backgroundColor:
                  row?.original?.lead_status_id == 1
                    ? "rgba(170, 21, 24, 0.50)"
                    : row?.original?.lead_status_id == 0
                    ? "rgba(255, 223, 0, 0.50)"
                    : "#d1d1d1",
                width: "100%",
                textTransform: "uppercase",
              }}
              className="px-1 fs-12 radius-4 d-flex justify-content-center align-items-center"
            >
              {row?.original?.status == "" ? "-" : row?.original?.status}
            </div>
          </div>
        ),
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
            backgroundColor: "#F0F0F0 !important",
            border: "none !important",
          },
        },
      },
      {
        header: "Customer",
        enableHiding: false,
        // size: 100,
        enableGlobalFilter: true,
        Cell: ({ row }) => {
          return (
            <div className="d-flex align-items-center cursor-pointer gap-2">
              <div>
                <TagToolTip
                  iconColor={"#888888"}
                  size={"lg"}
                  border="1px solid #F0F0F0"
                  padding={"0px"}
                  boxShadow="2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                  text={
                    <div style={{ width: "700px" }}>
                      <CustomerDetailTooltip data={row?.original} />
                    </div>
                  }
                />
              </div>
              {row?.original?.customer == "" ? "-" : row?.original?.customer}
            </div>
          );
        },
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
            backgroundColor: "#F0F0F0 !important",
            border: "none !important",
            borderRight: "1px solid #888888 !important",
          },
        },
      },

      {
        accessorKey: "registration_date",
        header: "Registration date ",
        enableHiding: false,
        // size: 20,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>
            {row?.original?.registration_date == ""
              ? "-"
              : moment(row?.original?.registration_date).format(
                  "DD MMM, YYYY [at] hh:mm"
                )}
          </div>
        ),
      },
      {
        accessorFn: (row) => `${row?.Campaign_Id} ${row?.responsible_agent}`,
        header: "Campaign",
        enableHiding: false,
        size: 260,
        enableGlobalFilter: false,
        Cell: ({ row }) => {
          const campaign = row?.original?.campaign_details?.[0];
          return (
            <div className="d-flex gap-2">
              <div>
                <TagToolTip
                  iconColor={"#888888"}
                  size={"lg"}
                  border="1px solid #F0F0F0"
                  boxShadow="2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                  text={
                    <div className="p-2 light-font-text ">
                      <H5>Campaign</H5>
                      <div className="pb-3">
                        {campaign?.campaign_name == ""
                          ? "-"
                          : campaign?.campaign_name}
                      </div>
                      <H5>Media</H5>
                      <div className="pb-3">
                        {" "}
                        {campaign?.media == "" ? "-" : campaign?.media}
                      </div>
                      <H5>Original source</H5>
                      <div className="pb-3">
                        {campaign?.original_source == ""
                          ? "-"
                          : campaign?.original_source}
                      </div>
                      <H5>Campaign size</H5>
                      <div className="pb-3">
                        {campaign?.campaign_size == ""
                          ? "-"
                          : campaign?.campaign_size}
                      </div>
                      <H5>Campaign created</H5>
                      <div className="pb-3">
                        {campaign?.campaign_created == ""
                          ? "-"
                          : campaign?.campaign_created}
                      </div>
                      <H5>Campaign created by</H5>
                      <div className="pb-3">
                        {campaign?.campaign_created_by == ""
                          ? "-"
                          : campaign?.campaign_created_by}
                      </div>
                    </div>
                  }
                />
              </div>
              <div>
                {row?.original?.campaign == "" ? "-" : row?.original?.campaign}
              </div>
            </div>
          );
        },
      },

      {
        accessorFn: (row) => `${row?.responsible_agent}`,
        header: "Responsible agent",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>
            {row?.original?.responsible_agent_user_id === 0 ? (
              <span className="grey-text">Unassigned</span>
            ) : (
              row?.original?.responsible_agent
            )}{" "}
          </div>
        ),
      },
      {
        accessorFn: (row) => `${row?.follow_up_status}`,
        header: "Follow up status",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>
            {row?.original?.follow_up_status == ""
              ? "-"
              : row?.original?.follow_up_status}
          </div>
        ),
      },
    ],
    []
  );

  // const renderEmptyRowsFallback = () => (
  //   <div className="d-flex justify-content-center w-100 grey-text py-4 light-font-text">
  //     <div>No data available!!</div>
  //   </div>
  // );
  //================ handle Delete lead ======================//
  const handleDeleteLead = async () => {
    setArchiveLeadPopup((prev) => ({
      ...prev,
      isArchiveApiLoading: true,
    }));
    try {
      const response = await DeleteLead(archiveLeadPopup?.lead_id);

      if (response.status === status.s && response.data.bReturn) {
        toast.success(response?.data?.message);
        GetLeadsByDivisionIdDataRefetch();
        setArchiveLeadPopup((prev) => ({
          ...prev,
          isPopupOpen: false,
          lead_id: "",
          isArchiveApiLoading: false,
        }));
      } else {
        toast.error(response?.data?.message);
        setArchiveLeadPopup((prev) => ({
          ...prev,
          isArchiveApiLoading: false,
        }));
      }
    } catch (error) {
      toast.error(error?.message);
      setArchiveLeadPopup((prev) => ({
        ...prev,
        isArchiveApiLoading: false,
      }));
    }
  };
  //====================== handle Filter Responsible Agent ======================//
  const handleFilterResponsibleAgent = (selectOption) => {
    setResponsibleAgentFilter(selectOption);
    if (selectOption !== null) {
      const filterResponsibleAgent = GetLeadsByDivisionIdData?.data?.filter(
        (item) => item?.responsible_agent === selectOption?.label
      );
      setLeadDataTable(filterResponsibleAgent);
    } else {
      setLeadDataTable(GetLeadsByDivisionIdData?.data);
    }
  };

  //====================== handle update responsible agent ======================//
  const handleUpdateResponsibleAgent = async (responsible_agent_id) => {
    const updateResponsibleAgent = {
      lead_id: leadId ? [`${leadId}`] : Object?.keys(rowSelection),
      responsible_agent_user_id: `${responsible_agent_id}`,
    };

    try {
      const response = await PutUpdateLeadAgent(updateResponsibleAgent);

      if (response.status === status.s && response.data.bReturn) {
        toast.success(response?.data?.message);
        GetLeadsByDivisionIdDataRefetch();
        setShowResponsibleAgentPopup(false);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  //====================== handle add responsible agent ======================// (Universal add agent button)
  const handleAddResponsibleAgent = () => {
    setLeadId("");
    setResponsibleAgentId("");
    const leadIds = Object.keys(rowSelection);
    const filteredLeads = GetLeadsByDivisionIdData?.data?.filter((el) =>
      leadIds?.includes(el?.lead_id?.toString())
    );
    if (filteredLeads?.some((el) => el?.responsible_agent_user_id)) {
      setShowResponsibleAgentWarnigPopup((prev) => ({
        ...prev,
        isPopupOpen: true,
      }));
    } else {
      setShowResponsibleAgentPopup(true);
    }
  };

  //===================== open customer card handler =======================//
  const handleOpenCustomerCard = (tab_index, lead_id) => {
    setOpenCustomerCard(true);
    setSelectedFollowUpIndex(tab_index);
    setLeadId(lead_id);
  };

  return (
    <div className="p-lg-4 p-3 mb-4 containerStyle ">
      {" "}
      <div className="whiteContainerStyle">
        {/* Header section */}
        <div className="p-4 border-bottom">
          <div className=" d-flex align-items-center gap-2">
            <H2>Leads</H2>
            <CustomToolTip size="sm" text="Leads" />{" "}
          </div>
        </div>

        {/* body section */}
        <div className="p-4">
          <Row>
            <Col xxl={8} xl={7}>
              <div className="light-font-text">
                Below is a list of all leads in the system that have not been
                pushed to an opportunity, screening or signing.If you need to
                create a new lead, you can do so by clicking New lead.
              </div>
            </Col>
            <Col xxl={4} xl={5}>
              <Row>
                <Col xl={7}>
                  <ETButton
                    variant={"secondary"}
                    buttonText="Add responsible agent"
                    width={"100%"}
                    disabled={Object?.keys(rowSelection).length == 0}
                    onClick={() => {
                      // setShowPopUp({ ...showPopUp, create: true });
                      // setShowResponsibleAgentPopup(true);
                      // setLeadId("")
                      handleAddResponsibleAgent();
                    }}
                  />
                </Col>
                <Col xl={5}>
                  <ETButton
                    icon={true}
                    variant={"primary"}
                    buttonText="new lead"
                    width={"100%"}
                    onClick={() => setShowNewLead(true)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className="px-4 pt-2">
          <div>
            <MRTTheme
              colorVariantsForTableRow={["gray"]}
              colorVariantsForTableHead={["white"]}
              table={
                <MaterialReactTable
                  columns={LeadsColumnData}
                  data={leadDataTable || []} //data is undefined on first render
                  icons={{ ...SortIcon }}
                  localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                  enableColumnActions={false}
                  enableTopToolbar={true}
                  enableRowSelection={true}
                  enableRowActions
                  enableSelectAll
                  enablePinning
                  getRowId={(row) => row?.lead_id}
                  onColumnPinningChange={setColumnPinning}
                  onRowSelectionChange={setRowSelection}
                  state={{ columnPinning, rowSelection }}
                  enableFilterMatchHighlighting={true}
                  enableGlobalFilter
                  globalFilterFn="contains"
                  initialState={{
                    showGlobalFilter: true,
                    showColumnFilters: false,
                  }}
                  muiTableContainerProps={{
                    sx: {
                      padding: "0px !important",
                    },
                  }}
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      header: "",
                      size: 50,
                      muiTableBodyCellProps: {
                        sx: {
                          backgroundColor: "#F0F0F0 !important",
                          border: "none !important",
                          borderLeft: "1px solid #888888 !important",
                        },
                      },
                    },
                    "mrt-row-select": {
                      header: "",
                      size: 50,
                      muiTableBodyCellProps: {
                        sx: {
                          paddingRight: "0px !important",
                          backgroundColor: "#F0F0F0 !important",
                          border: "none !important",
                        },
                      },
                    },
                  }}
                  positionActionsColumn="last"
                  renderRowActions={({ row }) => (
                    <Menus
                      itemheight={23}
                      itemwidth={23}
                      iconborderradius="4px"
                      itemcontainerwidth="180px"
                      itemcontainerheight={"300px"}
                      component={
                        <div className="light-font-text">
                          {/* <div className="p-2 cursor-pointer">
                            Create appointment
                          </div>
                          <hr className="hr m-0"></hr>
                          <div className="p-2 cursor-pointer">Create deal</div>
                          <hr className="hr m-0"></hr> */}
                          {row?.original?.has_confirmed_terms !== 0 ? (
                            <div
                              className="p-2 cursor-pointer"
                              onClick={() => {}}
                            >
                              Create appointment
                            </div>
                          ) : (
                            <div
                              className="p-2 cursor-pointer"
                              onClick={() => setShowSendConfirmationEmail(true)}
                            >
                              Send confirmation email
                            </div>
                          )}
                          <hr className="hr m-0"></hr>
                          <div
                            className="p-2 cursor-pointer"
                            onClick={() => {
                              handleOpenCustomerCard(0, row?.original?.lead_id);
                            }}
                          >
                            Customer card
                          </div>
                          <hr className="hr m-0"></hr>
                          <div className="p-2 cursor-pointer">
                            Add customer as favourite
                          </div>
                          <hr className="hr m-0"></hr>
                          <div
                            className="p-2 cursor-pointer"
                            onClick={() => {
                              handleOpenCustomerCard(1, row?.original?.lead_id);
                            }}
                          >
                            Add a note
                          </div>
                          <hr className="hr m-0"></hr>
                          <div
                            className="p-2 cursor-pointer"
                            onClick={() => {
                              setOpenCustomerCard(true);
                              setSelectedFollowUpIndex(2);
                            }}
                          >
                            Go to contact card
                          </div>
                          <hr className="hr m-0"></hr>
                          {row.original.responsible_agent_user_id === 0 ? (
                            <div
                              className="p-2 cursor-pointer"
                              onClick={() => {
                                setShowResponsibleAgentPopup(true);
                                setResponsibleAgentId("");
                                setLeadId(row?.original?.lead_id);
                              }}
                            >
                              Add responsible agent
                            </div>
                          ) : (
                            <div
                              className="p-2 cursor-pointer"
                              onClick={() => {
                                setShowResponsibleAgentPopup(true);
                                setResponsibleAgentId(
                                  row?.original?.responsible_agent_user_id
                                );
                                setLeadId(row?.original?.lead_id);
                              }}
                            >
                              Change responsible agent
                            </div>
                          )}

                          <hr className="hr m-0"></hr>
                          <div
                            className="p-2 cursor-pointer"
                            onClick={() =>
                              setArchiveLeadPopup((prev) => ({
                                ...prev,
                                isPopupOpen: true,
                                lead_id: row?.original?.lead_id,
                              }))
                            }
                          >
                            Archive
                          </div>
                        </div>
                      }
                    />
                  )}
                  renderTopToolbar={({ table }) => (
                    <>
                      <div className="px-0 ">
                        <Row>
                          <Col xl={4} lg={6} className="pb-2">
                            <DateRangeFilter setSelectedDate={setDateFilter} />
                          </Col>
                          <Col xl={3} lg={5} className="ps-lg-0 pb">
                            <ReactSelect
                              placeholder={"Responsible agent"}
                              value={responsibleAgentFilter}
                              options={GetUserByDivisionIdData?.data?.map(
                                (item) => ({
                                  value: item?.Id,
                                  label: item?.Text,
                                })
                              )}
                              // onChange={(e) => setResponsibleAgentFilter(e)}
                              onChange={handleFilterResponsibleAgent}
                            />
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                  muiTablePaperProps={{
                    selected: false,
                    elevation: 0,
                    fontFamily: "Cerebri sans-light !important",
                    sx: {
                      borderRadius: "0",
                      border: "none",
                      // backgroundColor: "#F9FAFA",
                      table: {
                        borderCollapse: "separate",
                        borderSpacing: "0 5px",
                      },
                    },
                  }}
                  muiTablePaginationProps={{
                    ...paginationoptions,
                  }}
                  muiTableHeadRowProps={{
                    sx: {
                      ...TableRowsStyle,
                    },
                  }}
                />
              }
            />
          </div>

          <AddResponsibleAgent
            showResponsibleAgentPopup={showResponsibleAgentPopup}
            setShowResponsibleAgentPopup={setShowResponsibleAgentPopup}
            usedFor={responsibleAgentId ? "EDIT" : "ADD"}
            assignedAgentId={responsibleAgentId}
            handleConfirm={handleUpdateResponsibleAgent}
          />
        </div>
      </div>
      {/* ------------------ Archive lead ----------------- */}
      <CustomPopup
        open={archiveLeadPopup?.isPopupOpen}
        onHide={() =>
          setArchiveLeadPopup((prev) => ({
            ...prev,
            isPopupOpen: false,
            lead_id: "",
          }))
        }
        title="Archive lead"
        size="md"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        // subTitle={<div>xs</div>}
        titleTooltip={true}
        titleTooltipText={"Archive lead"}
      >
        <div className="px-4 py-4">
          <div className="light-font-text">
            Are you sure you want to Archive this lead?
          </div>
        </div>
        <div
          className="py-2 px-3 d-flex justify-content-between"
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            fontSize="14px"
            fontWeight="500"
            onClick={() =>
              setArchiveLeadPopup((prev) => ({
                ...prev,
                isPopupOpen: false,
                lead_id: "",
              }))
            }
          />
          <ETButton
            variant={"primary"}
            buttonText="Archive"
            disabled={archiveLeadPopup?.isArchiveApiLoading}
            fontSize="14px"
            fontWeight="500"
            onClick={() => handleDeleteLead()}
          />
        </div>
      </CustomPopup>
      {/* ------------------ Warning popup for adding responsible agent with already assigned agent ----------------- */}
      <CustomPopup
        open={showResponsibleAgentWarnigPopup?.isPopupOpen}
        onHide={() =>
          setShowResponsibleAgentWarnigPopup((prev) => ({
            ...prev,
            isPopupOpen: false,
          }))
        }
        title="Add responsible agent"
        size="md"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        // subTitle={<div>xs</div>}
        titleTooltip={true}
        titleTooltipText={"Add responsible agent"}
      >
        <div className="px-4 py-4">
          <div className="light-font-text">
            You are about to add a responsible agent to one or more customers
            who already have a responsible agent assigned. This will update the
            responsible agent of already assigned customers.
          </div>
        </div>
        <div
          className="py-2 px-3 d-flex justify-content-between"
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            fontSize="14px"
            fontWeight="500"
            onClick={() =>
              setShowResponsibleAgentWarnigPopup((prev) => ({
                ...prev,
                isPopupOpen: false,
              }))
            }
          />
          <ETButton
            variant={"primary"}
            buttonText="Continue"
            fontSize="14px"
            fontWeight="500"
            onClick={() => {
              setShowResponsibleAgentWarnigPopup((prev) => ({
                ...prev,
                isPopupOpen: false,
              }));
              setShowResponsibleAgentPopup(true);
            }}
          />
        </div>
      </CustomPopup>
      {/* ------------create new leads -----------------*/}
      <CreateNewlead
        showNewLead={showNewLead}
        setShowNewLead={setShowNewLead}
      />
      {/* ------------------- FollowUp --------------------- */}
      <FollowUp
        isCustomerCardPopupOpen={openCustomerCard}
        setIsCustomerCardPopupOpen={setOpenCustomerCard}
        selectedIndex={selectedFollowUpIndex}
        setSelectedIndex={setSelectedFollowUpIndex}
        leadId={leadId}
      />
      {/* ------------------- Send confirmation email  ----------------------------- */}
      <CustomPopup
        open={showSendConfirmationEmail}
        // onHide={() => setShowSendConfirmationEmail(false)}
        onClickCrossButton={() => setShowSendConfirmationEmail(false)}
        title="Send confirmation email"
        size="xl"
        title_color="black"
        enableCrossButton={true}
        // subTitle={<div>xs</div>}
        titleTooltip={true}
        titleTooltipText={"Send confirmation email"}
      >
        <div className="px-4 py-4">
          <div className="light-font-text">
            You are about to add a responsible agent to one or more customers
            who already have a responsible agent assigned. This will update the
            responsible agent of already assigned customers.
          </div>
        </div>
      </CustomPopup>
    </div>
  );
};

export default Leads;

// change logs
// Created by Suraj
// changed heart icon with heart component -- Suraj
