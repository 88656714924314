import React, { useState } from "react";
import Menus from "../Menus/Menus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import CustomPopup from "../CustomPopup";
import ETButton from "../Buttons/ETButton";
import CustomCheckBox from "../Checkbox/Checkbox";
import styled from "styled-components";
import { UTCtoLocalTimeZone } from "../../utils/UTCtoLocalTimeZone";
import { useUserDetails } from "../../hooks/stores/useUserDetailsStore";

const FacilityCard = ({
  data,
  editFacility,
  duplicateFacility,
  handleDeleteFacilityPackage,
  isTag,
  setDeletePopUp,
  checkValue,
  checkboxOnChange,
}) => {
  console.log("🚀 ~ file: FacilityCard.js:21 ~ data:", data)
  const [openMediaPackageDelete, setOpenMediaPackageDelete] = useState(false);
  // const [mediaStyle, setMediaStyle] = useState(false);
   //------------- zustand ------------------//
   const { userDetails } = useUserDetails((state) => state);
  const { convertedDate: createdBy } = UTCtoLocalTimeZone({timestamp: data?.created_on, offset_mins: userDetails?.timezone_offset_mins, timeFormat: "DD. MMM. YYYY | HH:mm:ss"})
  const { convertedDate: modifiedBy } = UTCtoLocalTimeZone({timestamp: data?.modified_on, offset_mins: userDetails?.timezone_offset_mins, timeFormat: "DD. MMM. YYYY | HH:mm:ss"})

  return (
    <CardsDiv
      isActive={checkValue?.length}
      // mediaStyle={mediaStyle}
      style={{
        minHeight: "426px",
        // width: "372px",
      }}
      className=" radius-8 mb-2 "
    >
      {isTag === "AddTagF" && (
        <div className="d-flex justify-content-end">
          <CustomCheckBox
            id={data?.facility_package_id}
            // value={checkValue}
            checked={checkValue?.length}
            onChange={checkboxOnChange}
            // onClick={() => {
            //   setMediaStyle(!mediaStyle);
            // }}
          />
        </div>
      )}
      <div
        className=" mx-3 radius-6 my-3 "
        style={{
          backgroundColor: "#ffffff",
          height: "150px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          border: "1px solid #f0f0f0",
        }}
      >
        {data?.icons?.map(
          (icon, index) =>
            index < 14 &&
            (icon ? (
              <>
                <FontAwesomeIcon
                  key={index}
                  icon={icon}
                  className="facility-icon"
                />
              </>
            ) : (
              <FontAwesomeIcon
                icon="fa-solid fa-circle"
                style={{
                  height: "20px",
                  width: "20px",
                  padding: "10px 16px ",
                }}
              />
            ))
        )}
        {data?.icons?.length > 14 && (
          <div
            className="px-2"
            style={{
              display: "inline-flex",
            }}
          >
            <div className="fs-10 fw-600  d-flex justify-content-center align-items-center">
              + {data?.icons?.length - 14} icons
            </div>
          </div>
        )}
      </div>
      <hr className=" mb-0" />
      <div className="px-3 py-2">
        {isTag && (
          <div className="fs-14">
            <div className="form-label-title ">Source</div>
            <div className=" light-font-text">Facility</div>
          </div>
        )}
        <div className="fs-14 py-2">
          <div className="form-label-title">Package title</div>
          <div className="light-font-text">{data?.title || "-"}</div>
        </div>
        <div className="fs-14 pb-2">
          <div className="form-label-title">Number of facilities</div>
          <div className="light-font-text">
            {data.facility_count || "-"} facilities
          </div>
        </div>
        {!isTag && (
          <div className="fs-14 pb-3 ellipsis" style={{ width: "95%" }}>
            <div className="form-label-title">Tag</div>
            <div className="light-font-text ellipsis">
              {data.tags ? data.tags : "-"}
            </div>
          </div>
        )}

        <div className="d-flex pb-2  justify-content-between">
          <div className="fs-12  pt-1 ">
            <div className="fs-12  form-label-title">Created by</div>
            <div className="created-by">
              {data?.created_by
                ? data?.created_by +
                  ", " +
                  createdBy
                : "-"}
            </div>
            <div className="fs-12 pt-1 form-label-title">Last updated by</div>
            <div className="created-by">
              {data?.modified_by
                ? data?.modified_by +
                  ", " +
                  modifiedBy
                : "-"}
            </div>
          </div>
          {isTag !== "AddTagF" && (
            <div className="d-flex align-items-end">
              <Menus
                key={data?.facility_package_id}
                component={
                  <>
                    {isTag !== "F" ? (
                      <div className="light-font-text">
                        <div
                          onClick={() => editFacility(data)}
                          className="addpropContent px-3 py-2 "
                        >
                          <span className="ms-1">Edit</span>
                        </div>
                        <hr className=" m-0"></hr>
                        <div
                          onClick={() => duplicateFacility(data)}
                          className="addpropContent px-3 py-2 "
                        >
                          <span className="ms-1">Duplicate</span>
                        </div>
                        <hr className="m-0"></hr>

                        <div
                          onClick={() => setOpenMediaPackageDelete(true)}
                          className="addpropContent px-3 pt-2 pb-2 "
                        >
                          <span className="ms-1">Delete</span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="addpropContent px-3 pt-2 pb-2 light-font-text"
                        onClick={() => setDeletePopUp(true)}
                      >
                        <span className="ms-1">Delete</span>
                      </div>
                    )}
                  </>
                }
              />
            </div>
          )}
          <CustomPopup
            open={openMediaPackageDelete}
            onHide={() => setOpenMediaPackageDelete(false)}
            title="Delete package"
            title_color="black"
            enableCrossButton={false}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={true}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
            deletePopUpStyle={true}
            titleTooltip={false}
          >
            <div className="p-3">
              <div className="px-2">
                <div
                  className="light-font-text my-1"
                  style={{ height: "60px" }}
                >
                  <p>Are you sure you want to delete the package?</p>
                  {/* <p>That will conflict with.....</p> */}
                </div>
              </div>
            </div>
            <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
              <ETButton
                variant={"white"}
                buttonText="COLD FEET"
                onClick={() => setOpenMediaPackageDelete(false)}
              />
              <ETButton
                variant={"primary"}
                buttonText="I'M SURE"
                onClick={() =>
                  handleDeleteFacilityPackage(data?.facility_package_id)
                }
              />
            </div>
          </CustomPopup>
        </div>
      </div>
    </CardsDiv>
  );
};

export default FacilityCard;

const CardsDiv = styled.div`
  background-color: #f9fafa;
  border-color: #f0f0f0;
  border-style: solid;
  border-width: 1px;
  ${(props) =>
    props.isActive &&
    `
  background-color:${props.theme.component.checkbox.primary.color + "11"};
   border-color: ${props.theme.component.checkbox.primary.color};
`}
`;
