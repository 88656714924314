import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../../Components/Buttons/ETButton";
import { useLocation } from "react-router-dom";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import Toggle from "../../../Components/Switch/Switch";
import { useFieldArray, useForm } from "react-hook-form";
import { useState } from "react";
import { toast } from "react-toastify";

const Button = () => {
  let location = useLocation();
  const { state } = location;
  let propId = state?.propId;

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      statusLabel: [{ btn_name: "", btn_url: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray(
    {
      control,
      name: "unit_btn",
    }
  );

  const [unitDrawerButtonList, SetUnitDrawerButtonList] = useState(0);

  const handleAddButton = () => {
    // SetUnitDrawerButtonList(unitDrawerButtonList + 1);
    append({ btn_name: "", btn_url: "" })
  };

  return (
    <div className="document-container p-4 my-2">
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          fontSize: "14px",
          border: "1px solid #F0F0F0",
        }}
      >
        <div
          className = "px-4 pb-3"
          style     = {{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div           style     = {{ fontFamily: "Cerebri Sans" }} className = "pt-3 d-flex">
          <div           className = "fw-600 fs-14">Button</div>
          <div           style     = {{ paddingLeft: "15px" }}>
          <CustomToolTip height    = {12} text = "Button" />
            </div>
          </div>
        </div>
        <div className="document-content py-3 px-4">
          <Row className="flex-wrap">
            <Col className="d-flex align-items-center">Select buttons to show in Ei.Do</Col>
            <Col className="d-flex justify-content-end">
              <ETButton
                    variant={"primary"}
                    icon={true}
                    buttonText="Add more buttons"
                    onClick={() => {
                    if(fields.length >= 2) {
                      toast.error("you cannot create buttons more then 2");
                    }else append({ btn_name: "", btn_url: "" });
                }}
              />
            </Col>
          </Row>
          <div>

            {console.log('fields', fields)}
          {fields?.map((item, index) => {
              return (
                <Row className="align-items-center justify-content-start my-3 gap-2 gap-lg-0" key={item?.id}>
                <Col lg={4}>
                <div className="position-relative">
                  <div className="form-input">
                        <input
                          style={{ margin: "0px" }}
                          type="text"
                          placeholder="Subject line"
                          {...register(`unit_btn.${index}.btn_name`, { required: true })}
                          // defaultValue={
                          //   contractByUnitIdData?.data?.[0]?.responsible_agent_name
                          // }
                        //   defaultValue={
                        //     contractByUnitIdData?.data?.filter(
                        //       (item) => item?.contract_id === contractId
                        //     )?.[0]?.responsible_agent_name
                        //   }
                        />
                        <label for={`unit_btn.${index}.btn_name`}>Button Name</label>
                      </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="position-relative">
                  <div className="form-input">
                        <input
                          style={{ margin: "0px" }}
                          type="text"
                          placeholder="Subject line"
                          {...register(`unit_btn.${index}.btn_url`, { required: true })}
                          // defaultValue={
                          //   contractByUnitIdData?.data?.[0]?.responsible_agent_name
                          // }
                        //   defaultValue={
                        //     contractByUnitIdData?.data?.filter(
                        //       (item) => item?.contract_id === contractId
                        //     )?.[0]?.responsible_agent_name
                        //   }
                        />
                        <label for={`unit_btn.${index}.btn_url`}>Button URL</label>
                      </div>
                  </div>
                </Col>
              </Row>
              );
            })}
            {/* <Row className="align-items-center justify-content-start my-3">
            <Col lg={4}>
            <div className="position-relative">
              <div className="form-input">
                    <input
                      style={{ margin: "0px" }}
                      type="text"
                      placeholder="Subject line"
                      // defaultValue={
                      //   contractByUnitIdData?.data?.[0]?.responsible_agent_name
                      // }
                    //   defaultValue={
                    //     contractByUnitIdData?.data?.filter(
                    //       (item) => item?.contract_id === contractId
                    //     )?.[0]?.responsible_agent_name
                    //   }
                    />
                    <label for="unit_drawer_button_name1">Button Name</label>
                  </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="position-relative">
              <div className="form-input">
                    <input
                      style={{ margin: "0px" }}
                      type="text"
                      placeholder="Subject line"
                      // defaultValue={
                      //   contractByUnitIdData?.data?.[0]?.responsible_agent_name
                      // }
                    //   defaultValue={
                    //     contractByUnitIdData?.data?.filter(
                    //       (item) => item?.contract_id === contractId
                    //     )?.[0]?.responsible_agent_name
                    //   }
                    />
                    <label for="unit_drawer_button_url">Button URL</label>
                  </div>
              </div>
            </Col>
          </Row> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Button;
