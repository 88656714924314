import React, { useEffect, useRef, useState } from "react";
import CSVWidget from "../../../Components/DownloadWidget/CSVWidget";
import Menus from "../../../Components/Menus/Menus";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import "../../../styles/css/control-center/overview.css";
import DropdownModalMui from "../../../Components/DropdownModal/DropdownModalMui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-bootstrap";
import CheckBox from "../../../Components/Checkbox/Checkbox";
import { Menu, MenuItem } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import { GreenDiv, H3 } from "../../../styles/Common.style";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import { useControlCenter } from "../../../hooks/stores/useControlCenterStore";
import { useGetNoteCategory } from "../../../hooks/queries/useControlCenterQueries";
import { useGetNotesCategory } from "../../../hooks/queries/useNotesQueries";
import { H2 } from "../../../styles/Common.style";
import { useUserDetails } from "../../../hooks/stores/useUserDetailsStore";
import { UTCtoLocalTimeZone } from "../../../utils/UTCtoLocalTimeZone";

const statusActivityData = [
  {
    name: "Tagkronen 11275",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Mar 2, 2023 | 17:57",
    status: "RESERVERET",
  },
  {
    name: "Tagkronen 11275",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Mar 2, 2023 | 17:57",
    status: "RESERVERET",
  },
  {
    name: "Tagkronen 11275",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Mar 2, 2023 | 17:57",
    status: "RESERVERET",
  },
  {
    name: "Tagkronen 11275",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Mar 2, 2023 | 17:57",
    status: "RESERVERET",
  },
  {
    name: "Tagkronen 11275",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Mar 2, 2023 | 17:57",
    status: "RESERVERET",
  },
  {
    name: "Tagkronen 11275",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Mar 2, 2023 | 17:57",
    status: "RESERVERET",
  },
  {
    name: "Tagkronen 11275",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Mar 2, 2023 | 17:57",
    status: "RESERVERET",
  },
];

const NotesActivity = () => {
  const {
    selectedDate,
    selectedProperties,
    selectedEndDate,
    setCategoryId,
    categoryId,
  } = useControlCenter((state) => state);
  const [allCategoryChecked, setAllCategoryChecked] = useState([]);
  const [noteCategoryData, setNoteCategoryData] = useState([]);
  const [dupNotes, setDupNotes] = useState([]);
  // console.log(noteCategoryData,"noteCategoryData")

  const { data: NoteCategoryData } = useGetNoteCategory(
    categoryId,
    selectedProperties,
    selectedDate,
    selectedEndDate
  );
  useEffect(() => {
    setNoteCategoryData(NoteCategoryData?.data);
    setDupNotes(NoteCategoryData?.data);
  }, [NoteCategoryData]);
  const { data: NotesCategory } = useGetNotesCategory();
  const [catogeryDropdownData, setCloseDropdownData] = useState([]);

  useEffect(() => {
    setCloseDropdownData(NotesCategory?.data);
  }, [NotesCategory]);

  const [user, setUser] = useState(null);
  console.log("useState", noteCategoryData);
  console.log("API", NoteCategoryData);

  //------------- zustand ------------------//
  const { userDetails } = useUserDetails((state) => state);
  const { convertedDate: createdOn } = UTCtoLocalTimeZone({
    timestamp: NoteCategoryData?.data[0]?.created_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });

  // All Category Dropdown

  // const [allCategory, setAllCategory] = useState([
  //   {
  //     id: "1",
  //     categoryName: "Option",
  //   },
  //   {
  //     id: "2",
  //     categoryName: "Economy",
  //   },
  //   {
  //     id: "3",
  //     categoryName: "Takeover",
  //   },
  //   {
  //     id: "4",
  //     categoryName: "Decor",
  //   },
  // ]);

  const handleCheckAllCategory = (e) => {
    if (e.target.checked) {
      const allCategoryTypes = catogeryDropdownData.map(
        (category) => category.Id
      );
      setAllCategoryChecked(allCategoryTypes);
      setCategoryId(allCategoryTypes);
      console.log(categoryId, "categoryId1");
    } else {
      setAllCategoryChecked([]);
      console.log(categoryId, "categoryId2");
    }
  };
  let filteredData;

  const handleCheckedCategory = (e, category) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setAllCategoryChecked([...allCategoryChecked, category?.Id]);
      setCategoryId([...allCategoryChecked, category?.Id]);
      filteredData = dupNotes?.filter((item) =>
        allCategoryChecked?.includes(item.category_name)
      );
      setNoteCategoryData(filteredData);
      console.log(categoryId, "categoryId3");
    } else {
      setAllCategoryChecked(
        allCategoryChecked.filter((item) => item != category.Id)
      );
      setCategoryId(allCategoryChecked.filter((item) => item != category.Id));
      console.log(categoryId, "categoryId4");
      // filteredData = dupNotes?.filter(item => allCategoryChecked?.includes(item.category_name));
      // setNoteCategoryData(filteredData);
    }
  };

  //  const open = Boolean(anchorEl);

  const handleClickUser = (event) => {
    setUser(event.currentTarget);
  };

  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "notes-category";

  const options = {
    keys: [
      { field: "username", title: "Name" },
      { field: "StatusId" },
      { field: "status" },
      { field: "name", title: "Property Name" },
      { field: "address" },
      { field: "time", title: "Created At" },
    ],
  };
  const [closeDropdown, setCloseDropdown] = useState(false);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        // setCloseDropdown(!closeDropdown)
      }}
    >
      {children}
    </a>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children)?.filter(
              (child) =>
                !value || child.props.children?.toLowerCase()?.startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  const data = statusActivityData;

  const CSVFunction = CSVWidget({ refObj, fileName, options, data });

  return (
    <div
      className="card-body"
      style={{
        minHeight: "555px",
      }}
    >
      <div>
        <div className="p-3">
          <Row className="d-flex align-items-center">
            <Col sm={5}>
              <div className="d-flex ">
                <H3 className=" me-2 d-flex align-items-center">
                  Notes activity
                </H3>{" "}
                <CustomToolTip
                  size="sm"
                  text="Notes activity"
                />
              </div>
            </Col>
            <Col sm={7}>
              <div className="d-flex justify-content-end gap-3">
                <div style={{ width: "60%" }}>
                  <DropdownModalMui
                    text="All categories"
                    component={
                      <div className="py-2 ps-2 pe-5">
                        <div className="d-flex align-items-center light-font-text fs-14 ">
                          <CheckBox
                            onChange={handleCheckAllCategory}
                            id={"handleCheckAllCategory"}
                            checked={
                              allCategoryChecked?.length ==
                              catogeryDropdownData?.length
                            }
                          />{" "}
                          All categories
                        </div>

                        {catogeryDropdownData?.map((category, i) => (
                          <div
                            key={i}
                            className="d-flex align-items-center light-font-text fs-14 ps-4 "
                          >
                            <CheckBox
                              id={category.Id}
                              // checked={allCategoryChecked?.includes(
                              //   category?.id)}
                              // onChange={() => handleCheckboxChange(category.id)}
                              checked={allCategoryChecked?.includes(
                                category?.Id
                              )}
                              onChange={(e) =>
                                handleCheckedCategory(e, category)
                              }
                            />{" "}
                            {category?.Text}
                          </div>
                        ))}
                      </div>
                    }
                  />
                </div>
                <div>
                  <Menus
                    itemheight={40}
                    itemwidth={40}
                    itemcontainerheight={200}
                    itemcontainerwidth={200}
                    component={
                      <div className="fs-14">
                        <div className="addpropContent px-3 py-2 ">
                          <span
                            className="ms-1"
                            onClick={() => CSVFunction()}
                          >
                            Download widget as CSV
                          </span>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>

        {noteCategoryData?.length !== 0 ? (
          <div
            className="scrollable-container-y me-2 px-4"
            style={{ height: "467px" }}
          >
            {noteCategoryData?.map((status, i) => {
              return (
                <Row
                  key={i}
                  className="card-inner-body p-3 my-2"
                >
                  <Col xs={7}>
                    <div className="form-label-title">
                      {status.category_name}
                    </div>

                    <div className="light-font-text fs-13">
                      {status.created_by}
                    </div>
                    <div className="light-font-text fs-10">{createdOn}</div>
                  </Col>

                  <Col className="ps-0">
                    <div className="form-label-title fs-12">
                      {status.subject}
                    </div>
                    <div className="d-flex fs-12 light-font-text">
                      <span>
                        {status?.message?.length > 17 ? (
                          <>{status?.message?.substring(0, 17)}</>
                        ) : (
                          <>{status?.message} </>
                        )}
                      </span>
                      {status.message ? (
                        <div>
                          <Dropdown
                          // show={closeDropdown}
                          >
                            <Dropdown.Toggle
                              as={CustomToggle}
                              id="dropdown-custom-components"
                            >
                              <GreenDiv className="light-font-text fs-12 nowrap ms-1">
                                (show more)
                              </GreenDiv>
                            </Dropdown.Toggle>

                            <Dropdown.Menu as={CustomMenu}>
                              <div className="px-3">
                                <div className=" form-label-title fs-12">
                                  {/* Nuværende lejer */}
                                </div>
                                <div className="light-font-text fs-12">
                                  {status.message}
                                </div>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>
              );
            })}
          </div>
        ) : (
          <NoDataScreen
            height={"377px"}
            imgHeight={"150px"}
          />
        )}
      </div>
    </div>
  );
};

export default NotesActivity;
