import React, {
  useMemo,
  useCallback,
  useState,
  useRef,
  useEffect,
} from "react";
import { Image, Col, Row, Button } from "react-bootstrap";
import photoComingSoon from "../../../assets/images/photo-coming-soon.jpg";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
// import MRTTheme from "../../../Components/table/MRTTheme";
import CustomPopup from "../../../Components/CustomPopup";
import { GreenSpan, H3, H2 } from "../../../styles/Common.style";
import { MaterialReactTable } from "material-react-table";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import {
  SortIcon,
  paginationoptions,
} from "../../../Components/table/MRTGlobalProps";
import moment from "moment";
import MRTTheme from "../../../Components/table/MRTTheme";
import { useUnitId, useUnitType } from "../../../hooks/stores/useUnitStore";
import { NavLink } from "react-router-dom";
import { NoRecordsToDisplay } from "../../../utils/validationConstants";
import { createStyles } from "@material-ui/core/styles";

const usestyles = createStyles((theme) => ({
  root: {
    "& .muipaper-root": {
      paddingBottom: "0px !important",
    },
  },
}));

export const StatusTable = ({
  statusOverviewData,
  setStatusId,
  subStatusData,
  propertyId,
  getAnalyasisdData,
  propertyOverview,
}) => {
  const { setUnitId } = useUnitId((state) => state);
  const [showQuantity, setShowQuantity] = useState(false);
  const [subStatus, setSubStatus] = useState();
  const [subStatusCount, setSubStatusCount] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5, //customize the default page size
  });
  useEffect(() => {
    //do something when the pagination state changes
  }, [pagination.pageIndex, pagination.pageSize]);

  const statusOverviewColumns = useMemo(() => [
    {
      accessorKey: "status_name",
      header: "Status",
      enableHiding: false,
      enableGlobalFilter: false,
      Footer: () => (
        <div className="pb-3">
          <span className="form-label-title fs-13">Total</span>
        </div>
      ),
      size: 180,
      Cell: ({ row }) => (
        <>
          <div className="d-flex">
            <div
              className="radius-4 me-3"
              style={{
                backgroundColor: row.original.background_color,
                width: "16px",
                height: "16px",
                borderRadius: "4px",
                // marginTop: "2px",
              }}
            ></div>
            <div className="light-font-text">{row.original.status_name}</div>
            {/* {row.originalSubRows.map((val) => (
              <div>{val.status_name}</div>
            ))} */}
            {/* {console.log("SR_VAL", row.originalSubRows[0].status_name)} */}
          </div>
        </>
      ),
      // center align the header and body cells
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "quantity",
      header: "Quantity",
      Footer: () => (
        <div className="pb-3 d-flex justify-content-end light-font-text">
          <span className="form-label-title fs-13">
            {propertyOverview ? (
              <>
                {
                  getAnalyasisdData?.filter(
                    (val) => val.status_name === "Total"
                  )?.[0]?.quantity
                }
              </>
            ) : (
              <>{total_quantity}</>
            )}
          </span>
        </div>
      ),
      size: 100,
      Cell: ({ row }) => (
        <>
          <span className="me-1 light-font-text">{row.original.quantity}</span>
          <span
            className="radius-4 cursor-pointer light-font-text"
            onClick={() => {
              setShowQuantity(true);
              setStatusId((prev) => ({
                ...prev,
                mainStatusId: row?.original?.mainstatus_id,
                subStatusId: row?.original?.substatus_id,
              }));
              handleSubStatus(row);
            }}
          >
            {row.original.quantity > 0 ? (
              <>
                (<GreenSpan>show</GreenSpan>)
              </>
            ) : (
              ""
            )}
          </span>
        </>
      ),
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
    },
    {
      accessorKey: "percentage",
      header: "Percentage",
      size: 80,
      Footer: () => (
        <div className="pb-3  d-flex justify-content-end light-font-text">
          <span className="form-label-title fs-13">
            {propertyOverview ? (
              <>
                {
                  getAnalyasisdData?.filter(
                    (val) => val.status_name === "Total"
                  )?.[0]?.percentage
                }{" "}
                {total_percentage ? "%" : ""}
              </>
            ) : (
              <>{total_percentage ? <>{total_percentage} %</> : ""}</>
            )}
          </span>
        </div>
      ),
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      Cell: ({ row }) => (
        <>
          <span className="me-1 light-font-text">
            <div>
              {row.original.percentage ? <>{row.original.percentage} %</> : ""}{" "}
            </div>
          </span>
        </>
      ),
    },
    {
      accessorKey: "total_amount",
      header: "Yearly rent",
      size: 100,
      Cell: ({ row }) => (
        <>
          <span className="me-1 light-font-text">
            {row.original.days_until_idleness}
            {row.original.total_amount} {row.original.currency_id}
          </span>
        </>
      ),
      Footer: () => (
        <div className="pb-3 d-flex justify-content-end light-font-text">
          <span className="form-label-title fs-13">
            {propertyOverview ? (
              <>
                {
                  getAnalyasisdData?.filter(
                    (val) => val.status_name === "Total"
                  )?.[0]?.total_amount
                }{" "}
                {
                  getAnalyasisdData?.filter(
                    (val) => val.status_name === "Total"
                  )?.[0]?.currency_id
                }{" "}
              </>
            ) : (
              <>
                {total_amount} {currency_id}
              </>
            )}
          </span>
        </div>
      ),
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
    },
  ]);

  const InnnerExpandableTable = useMemo(
    () => [
      {
        accessorKey: "status",
        Cell: ({ row }) => (
          <>
            <div className="d-flex align-items-center">
              <div
                className="bg-primary"
                style={{ height: "16px", width: "16px", borderRadius: "4px" }}
              ></div>
              <div className="ms-2">{row.original.status}</div>
            </div>
          </>
        ),
        header: " ",
        size: 180,
      },
      {
        accessorKey: "quantity",
        header: " ",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "percent",
        header: " ",
        size: 80,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "yearly_rent",
        header: " ",
        size: 100,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    []
  );

  const InnnerExpandableData = [
    {
      status: "Completed",
      quantity: 22,
      percent: "5%",
      yearly_rent: 0.0,
    },
    {
      status: "Completed",
      quantity: 22,
      percent: "5%",
      yearly_rent: 0.0,
    },
  ];

  const handleSubStatus = (row) => {
    setSubStatusCount(row?.original?.quantity);
    const statusParts = row.original.status_name.split(" - ");
    const subStatusData = statusParts[1];
    setSubStatus(subStatusData);
  };

  const total_amount = getAnalyasisdData
    ?.filter((item) => item.status_name == "Total")
    ?.map((data) => data.total_amount);
  const total_quantity = getAnalyasisdData
    ?.filter((item) => item.status_name === "Total")
    ?.map((data) => data.quantity);
  const total_percentage = getAnalyasisdData
    ?.filter((item) => item.status_name === "Total")
    ?.map((data) => data.percentage);
  const currency_id = getAnalyasisdData
    ?.filter((item) => item.status_name === "Total")
    ?.map((data) => data.currency_id);
  const subStatusColumns = useMemo(() => [
    {
      // accessorFn: (row) =>
      //   `${row.propertyName} ${row.zipCode} ${row.city}${row.portfolioName}`,
      header: "Project",
      enableHiding: false,
      size: 150,
      muiTableBodyCellProps: {
        sx: {
          paddingLeft: "0px!important",
        },
      },

      enableGlobalFilter: true,
      Cell: ({ row }) => (
        <div
          // onClick={() => handleEditProperty(row.original.property_id, "edit")}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            cursor: "pointer",
          }}
          className="px-3"
        >
          <Image
            className="rounded"
            src={
              row.original.image_link !== ""
                ? row.original.image_link
                : photoComingSoon
            }
            height={60}
            width={60}
          />
          <div>
            <div className="form-label-title fs-13">
              {row.original.property_name}
            </div>
            <div className="light-font-text fs-13 nowrap">
              {row.original.street_name} {row.original.floor_number}
            </div>
            <div className="light-font-text fs-13">
              {row?.original?.alt_unit_id} {row?.original?.house_number}
            </div>
          </div>
        </div>
      ),
    },

    {
      accessorKey: "name",
      size: 100,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "right",
        sx: {
          paddingLeft: "0px!important",
        },
      },

      Cell: ({ row }) => (
        <div
          // onClick={() => handleEditProperty(row.original.property_id, "edit")}
          style={{
            display: "flex",
            alignItems: "center",
          }}
          className=""
        >
          <div>
            <div className="light-font-text fs-13 nowrap">
              {/* {row.original.street_name} {row.original.floor_number} */}
              {row?.original?.actor_details?.map((item) => (
                <div> {item?.actor_name} </div>
              ))}
              <div>{row?.original?.actor_details?.length == 0 && "-"}</div>
            </div>
          </div>
        </div>
      ),
    },
    // {
    //   accessorKey: "name",
    //   header: "Percentage",
    //   size: 100,
    //   enableGlobalFilter: true,
    //   muiTableHeadCellProps: {
    //     align: "right",
    //   },
    //   muiTableBodyCellProps: {
    //     align: "right",
    //   },
    // },
  ]);

  const renderEmptyRowsFallback = () => (
    <div className="d-flex justify-content-center w-100 py-4 grey-text">
      <div>You do not have any unit yet.</div>
    </div>
  );

  const classes = usestyles();

  return (
    <>
      {statusOverviewData?.length != 0 ? (
        <Row>
          <Col>
            <div className="d-flex align-items-center my-3">
              <H3 className="me-2">Status overview</H3>
              <CustomToolTip text="Status overview" size="sm" />{" "}
            </div>

            <div className={classes.root}>
              <MRTTheme
                colorVariantsForTableRow={["gray"]}
                colorVariantsForTableHead={["white"]}
                table={
                  <MaterialReactTable
                    columns={statusOverviewColumns}
                    data={
                      getAnalyasisdData?.filter(
                        (data) => data.status_name !== "Total"
                      ) || []
                    }
                    icons={{ ...SortIcon }}
                    localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                    enableTopToolbar={false}
                    enablePagination={true}
                    enableSelectAll={false}
                    enableExpandAll={false}
                    enableExpanding
                    // onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                    // state={{ pagination }} //pass the pagination state to the table
                    enableColumnActions={false}
                    enableColumnFilter={false}
                    positionExpandColumn="last"
                    enableResizing={false}
                    renderDetailPanel={({ row }) => (
                      <MRTTheme
                        // colorVariantsForTableHead="white"
                        // colorVariantsForTableData={["gray"]}
                        // colorVariantsForTableHead="white"
                        // colorVariantsForTableRow="odd"
                        // colorVariantsForTableHead="white"
                        table={
                          <MaterialReactTable
                            // sx={{
                            //   ".css-3xs9pb-MuiTableCell-root": {
                            //     padding: "0px !important",
                            //     paddingTop: "0px !important",
                            //   },
                            // }}

                            columns={InnnerExpandableTable}
                            data={InnnerExpandableData}
                            icons={{ ...SortIcon }}
                            enableBottomToolbar={false}
                            enableSorting={false}
                            enableColumnActions={false}
                            enablePagination={false}
                            renderTopToolbar={false}
                            enableTableHead={false}
                            muiTableContainerProps={{
                              sx: {
                                padding: "0px !important",
                              },
                            }}
                            muiTablePaperProps={{
                              elevation: 0,
                              sx: {
                                padding: "0px !important",
                                // "& td:first-child": {
                                //   borderRadius: "4px 0 0 4px !important",
                                // },
                                // "& td:last-child": {
                                //   borderRadius: "0 4px 4px 0 !important",
                                // },
                                // ".css-3xs9pb-MuiTableCell-root": {
                                //   backgroundColor: "#fff",
                                // },
                                // fontFamily: "Cerebri sans !important",
                                // // borderRadius: "0px 0px 8px 8px ",
                                // border: "none",

                                // fontWeight: "300",
                                // ".css-1f2qhs8": {
                                //   minWidth: "auto",
                                //   marginRight: "10px",
                                // },
                                table: {
                                  display: "table",
                                  width: "100%",
                                  minWidth: "650px",
                                  // borderCollapse: "separate",
                                  borderSpacing: "0px 0px",
                                  // paddingLeft: "11px",
                                  height: "44px",
                                  // borderLeft: "1.5px solid rgb(244, 245, 245)",
                                  // paddingTop: "4px",
                                  // marginTop: "-12px",
                                  backgroundColor: "#F9FAFA",
                                  padding: "0px",
                                  paddingBottom: "0px",
                                  margin: "0px",
                                  marginBottom: "0px",
                                },
                                // th: {
                                //   // height: "50px",
                                //   // padding: "0.5rem",
                                //   background: "#FFFFFF",
                                //   paddingLeft: "1rem",
                                //   fontFamily: "Cerebri sans !important",
                                // },
                                // td: {
                                //   fontWeight: "300",
                                //   fontSize: "12px",
                                //   paddingLeft: "1rem",
                                //   fontFamily: "Cerebri sans !important",
                                // },
                                // tr: {
                                //   backgroundColor: "#f9fafa !important",
                                //   borderRadius: "4px",
                                //   border: "2px solid red !important",
                                //   fontFamily: "Cerebri sans !important",
                                //   position: "relative",
                                // },
                                // dispaly: "block",
                                // "MuiTableRow-root::before": {
                                //   // position: 'absolute',

                                //   // width: '40px',
                                //   // height: '40px',
                                //   // background: 'red'
                                //   boxSizing: "contentBox",
                                //   position: "absolute",
                                //   color: "blue",
                                //   content: '""',
                                //   width: "10px",
                                //   height: "0.07px",
                                //   background: "red",
                                //   // background: '#f4f5f5',
                                //   left: "-11px",
                                //   top: "50%",
                                // },
                                // ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
                                //   display: "none", // pagination space removing from page
                                // },
                              },
                            }}
                            //                     renderRowActions={({ row, table }) => (
                            //                       <div className="ps-4">
                            //                         <Badge
                            //                           overlap="circular"
                            //                           className="cursor-pointer"
                            //                           onClick={() => setOpenComment(true)}
                            //                           badgeContent={2}
                            //                           sx={{
                            //                             "& .MuiBadge-badge": {
                            //                               fontWidth: 700,
                            //                               lineHeight: "20px",
                            //                               fontSize: 9.5,
                            //                               height: "19px",
                            //                               width: " 12px",
                            //                               borderRadius: "100%",
                            //                               backgroundColor: "#619130",
                            //                               color: "#fff",
                            //                               zIndex: 0,
                            //                             },
                            //                           }}
                            //                           anchorOrigin={{
                            //                             vertical: "bottom",
                            //                             horizontal: "right",
                            //                           }}
                            //                         >
                            //                           <FontAwesomeIcon
                            //                             icon="fa-regular fa-comment"
                            //                             size="2xl"
                            //                           />
                            //                         </Badge>

                            //                         {/* for no comments the below icon will be use */}

                            //                         {/* <FontAwesomeIcon
                            //    icon="fa-regular fa-comment-plus"
                            //    size="2xl"
                            //  /> */}
                            //                       </div>
                            //                     )}
                            // positionActionsColumn="last"
                            // muiTableBodyProps={{
                            //   sx: {
                            //     fontFamily: "Cerebri sans !important",
                            //     // "& tr:nth-of-type(odd)": {
                            //     backgroundColor: "#F9FA",
                            //     // borderRadius: "8px",
                            //     // fontWeight: "300",
                            //     // },
                            //   },
                            // }}
                            // muiTableContainerProps={{
                            //   elevation: 0,
                            //   sx: {
                            //     maxHeight: "400px",
                            //     background: "#FFFFFF",
                            //     paddingLeft: "18px",
                            //     borderRadius: "0px 0px 8px 8px ",

                            //     boxShadow: "none",
                            //     ".css-vv34gr-MuiTableRow-root": {
                            //       boxShadow: "unset !important",
                            //     },
                            //   },
                            // }}
                            // muiTableHeadCellProps={{
                            //   //simple styling with the `sx` prop, works just like a style prop in this example
                            //   sx: {
                            //     fontWeight: "700",
                            //     fontSize: "12px",
                            //     fontFamily: "Cerebri sans !important",
                            //   },
                            // }}
                            // enableRowActions
                            displayColumnDefOptions={{
                              // "mrt-row-actions": {
                              //   header: "Comments",
                              // },
                              "mrt-row-select": {
                                header: "",
                              },
                            }}
                          />
                        }
                      />
                    )}
                    muiTablePaginationProps={{
                      ...paginationoptions,
                    }}
                    enableColumnFilterModes={false}
                    enableBottomToolbar={true}
                    initialState={{ showGlobalFilter: true }}
                    muiTableHeadCellProps={{
                      elevation: 0,
                      sx: {
                        fontWeight: "600",
                        fontSize: "13px",
                        fontFamily: "Cerebri sans !important",
                        boxShadow: "none",
                      },
                    }}
                    muiTableBodyRowProps={{
                      fontFamily: "Cerebri Sans",
                      fontSize: "13px",
                      hover: false,
                    }}
                    muiTableBodyProps={{
                      sx: {
                        fontFamily: "Cerebri Sans",
                        //stripe the rows, make odd rows a darker color
                        "& tr:nth-of-type(odd)": {
                          // backgroundColor: "#F9FAFA",
                        },
                        td: {
                          fontFamily: "Cerebri Sans",
                          fontSize: "13px",
                          fontWeight: 300,
                        },
                      },
                    }}
                    muiTablePaperProps={{
                      elevation: 0,
                      sx: {
                        fontFamily: "Cerebri sans !important",
                        borderRadius: "0",
                        border: "none",
                        background: "#FFFFFF",
                        table: {
                          padding: "0px !important",
                          paddingBottom: "16px !important",
                          borderCollapse: "separate",
                          borderSpacing: "0px 3px",
                        },
                        thead: {
                          // display: "none",
                        },
                        th: {
                          background: "#FFFFFF",
                          fontFamily: "Cerebri sans-medium !important",
                        },
                        td: {
                          fontFamily: "Cerebri sans-light !important",
                          fontSize: "14px",
                          borderRight: "none !important",
                          borderImage:
                            "linear-gradient(to bottom, rgba(0,0,0,0) 25%,rgba(209,209,209) 25%,rgba(209,209,209) 75%,rgba(0,0,0,0) 75%) !important",
                          borderImageSlice: "1 !important",
                        },
                        tr: {
                          borderRadius: "4px",
                          background: "#F9FAFA",
                          margin: "4px",
                        },
                        "& td:nth-last-of-type(3)": {
                          borderRight: "none !important",
                        },
                        "& td:nth-last-of-type(2)": {
                          border: "none !important",
                          borderLeft: "none !important",
                          backgroundColor: "transparent !important",
                        },
                        "& td:nth-last-of-type(2) : hover": {
                          backgroundColor: "#f9fafa !important",
                        },
                        "& tr:hover": {
                          backgroundColor: "#f9fafa !important",
                        },
                        "& td:nth-last-of-type(1)": {
                          border: "none !important",
                        },
                      },
                    }}
                    muiTableHeadRowProps={{
                      sx: { boxShadow: "none" },
                    }}
                    displayColumnDefOptions={{
                      "mrt-row-expand": {
                        header: "",
                        size: 50,
                        muiTableBodyCellProps: {
                          sx: {
                            paddingRight: "0px !important",
                          },
                        },
                      },
                    }}
                    muiTableContainerProps={{
                      sx: {
                        padding: "0px !important",
                      },
                    }}
                  />
                }
              />
            </div>
          </Col>
        </Row>
      ) : (
        <NoDataScreen height={"377px"} imgHeight={"150px"} />
      )}

      <CustomPopup
        open={showQuantity}
        onHide={() => setShowQuantity(false)}
        title={
          <>
            {subStatus} ({subStatusCount})
          </>
        }
        title_color="black"
        titleFont="14px"
        titleTooltip={true}
        enableCrossButton={true}
        titleTooltipText={`${subStatus} (${subStatusCount})`}
        size="xl"
        onCloseAction={() => {
          setShowQuantity(false);
          alert("Action Peformed Successfuly.");
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <MRTTheme
          colorVariantsForTableData={["odd"]}
          table={
            <MaterialReactTable
              // enableStickyHeader
              columns={subStatusColumns}
              data={data}
              icons={{ ...SortIcon }}
              enableBottomToolbar={true}
              enableSorting={false}
              enableColumnActions={false}
              enablePagination={true}
              enableRowActions
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  size: 10,
                  header: "",

                  muiTableBodyCellProps: {
                    align: "center",
                  },
                },
                "mrt-row-select": {
                  header: "",
                },
              }}
              renderRowActions={({ row, table }) => (
                <span>
                  <NavLink
                    state={{
                      unit_id: row?.original?.unit_id,
                      propId: propertyId,
                      unit_no: row?.original?.calculated_unit_id,
                      street_name: row?.original?.street_name,
                      house_number: row?.original?.house_number,
                      zip_code: row?.original?.zipcode_id,
                    }}
                    to="/property-units-edit/overview"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button
                      className="px-2 py-2 shadow-none"
                      style={{
                        border: "1px solid #F0F0F0",
                        width: "100px",
                        backgroundColor: "#fff",
                        color: "#000",
                        borderRadius: "8px",
                      }}
                      onClick={() => setUnitId(row?.original?.unit_id)}
                    >
                      <div className="form-label-title fs-12">GO TO UNIT</div>
                    </Button>
                  </NavLink>
                </span>
              )}
              positionActionsColumn="last"
              renderTopToolbar={false}
              muiTablePaperProps={{
                elevation: 0,
                sx: {
                  table: {
                    borderCollapse: "separate",
                    // borderSpacing: "0 5px",
                  },
                  // fontFamily: "Cerebri sans !important",
                  // borderRadius: "0px 0px 8px 8px ",
                  // border: "none",
                  // background: "#F9FAFA",
                  // fontWeight: "300",
                  // ".css-1f2qhs8": {
                  //   minWidth: "auto",
                  //   marginRight: "10px",
                  // },
                  // th: {
                  //   height: "50px",
                  //   padding: "0.5rem",
                  //   background: "#FFFFFF",
                  //   paddingLeft: "1rem",
                  //   fontFamily: "Cerebri sans !important",
                  // },
                  // td: {
                  //   height: "50px",
                  //   padding: "0.5rem 0",
                  //   fontWeight: "300",
                  //   fontSize: "13px",
                  //   paddingLeft: "16rem",
                  //   fontFamily: "Cerebri sans !important",
                  // },
                  // tr: {
                  //   borderRadius: "4px",
                  //   border: "2px solid red !important",
                  //   fontFamily: "Cerebri sans !important",
                  // },
                  // ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
                  //   display: "none", // pagination space removing from page
                  // },
                },
              }}
              muiTableBodyProps={{
                sx: {
                  fontFamily: "Cerebri sans !important",
                  "& tr:nth-of-type(odd)": {
                    backgroundColor: "#F9FAFA",
                    borderRadius: "8px",
                    fontWeight: "300",
                  },
                },
              }}
              muiTableContainerProps={{
                sx: {
                  maxHeight: "400px",
                },
              }}
              muiTableHeadCellProps={{
                //simple styling with the `sx` prop, works just like a style prop in this example
                sx: {
                  display: "none !important",
                },
              }}
            />
          }
        />
      </CustomPopup>
    </>
  );
};

const tableData = [
  {
    status: "Available",
    Quantity: "185",
    Percentage: "9%",
    Yearlyrent: "2.262.419 DKK",
    color: "#77C7A1",
  },
  {
    status: "Available - not idle",
    Quantity: "0",
    Percentage: "0%",
    Yearlyrent: "0 DKK",
    color: "#77C7A1",
  },
  {
    status: "Reserved",
    Quantity: "0",
    Percentage: "0%",
    Yearlyrent: "0 DKK",
    color: "#A2C8D0",
  },
  {
    status: "Tenancy agreement in process",
    Quantity: "70",
    Percentage: "3%",
    Yearlyrent: "804.150 DKK",
    color: "#A2C8D0",
  },
  {
    status: "Tenancy agreement signed by tenants	",
    Quantity: "0",
    Percentage: "0%",
    Yearlyrent: "0 DKK",
    color: "#F6E651",
  },
  {
    status: "Sold - moved in",
    Quantity: "0",
    Percentage: "0%",
    Yearlyrent: "0 DKK",
    color: "#F1664B",
  },
  {
    status: "Sold - not moved in",
    Quantity: "0",
    Percentage: "0%",
    Yearlyrent: "0 DKK",
    color: "#F1664B",
  },
  {
    status: "Leased - moved in",
    Quantity: "1896",
    Percentage: "88%",
    Yearlyrent: "23.465.446 DKK",
    color: "#BF87D2",
  },
  {
    status: "Leased - not moved in",
    Quantity: "0",
    Percentage: "0%",
    Yearlyrent: "0 DKK",
    color: "#BF87D2",
  },
];

const quantityData = [
  {
    propertyName: "Aarhus",
    address: "Gøteborg Allé 6 1.31",
    value: "03.03",
    name: "Christoffer Helleskov",
  },
  {
    propertyName: "Aarhus",
    address: "Gøteborg Allé 6 1.31",
    value: "03.03",
    name: "Christoffer Helleskov",
  },
  {
    propertyName: "Aarhus",
    address: "Gøteborg Allé 6 1.31",
    value: "03.03",
    name: "Christoffer Helleskov",
  },
  {
    propertyName: "Aarhus",
    address: "Gøteborg Allé 6 1.31",
    value: "03.03",
    name: "Christoffer Helleskov",
  },
  {
    propertyName: "Aarhus",
    address: "Gøteborg Allé 6 1.31",
    value: "03.03",
    name: "Christoffer Helleskov",
  },
];

export const data = [
  {
    status_name: "Dylan",
    quantity: "Murray",
    percentage: "261 Erdman Ford",
    total_amount: "East Daphne",
    subRows: [
      {
        status_name: (
          <div style={{ color: "red", marginLeft: "24px" }}>Ervin</div>
        ),
        quantity: "Reinger",
        percentage: "566 Brakus Inlet",
        total_amount: "South Linda",
      },
      {
        status_name: "asdb",
        quantity: "Reinger",
        percentage: "566 Brakus Inlet",
        total_amount: "South Linda",
      },
    ],
  },
  {
    status_name: "Raquel",
    quantity: "Kohler",
    percentage: "769 Dominic Grove",
    total_amount: "Columbus",
    subRows: [
      {
        status_name: "Branson",
        quantity: "Frami",
        percentage: "32188 Larkin Turnpike",
        total_amount: "Charleston",
      },
      {
        status_name: "Ervin",
        quantity: "Reinger",
        percentage: "566 Brakus Inlet",
        total_amount: "South Linda",
      },
    ],
  },
];
