import React from "react";

import { useState } from "react";
import LoadingBar from "react-top-loading-bar";

import AddPropertyAndEnergy from "./propertyTabs/AddOperationAndEnergy";
import UploadDocument from "./propertyTabs/energyLabels/UploadDocument";
import CustomPopup from "../../../../Components/CustomPopup";
import { StyledLoadingBar } from "../../../../styles/Common.style";
import { customproperties } from "../../../../styles/Theme";

const AddEnergyAndOperationWizard = ({
  openAddEnergyWizard,
  setOpenAddEnergyWizard,
  progress,
  setProgress,
}) => {
  const [stepWizard, setStepWizard] = useState("AddPropertyAndEnergy");
  const [nextState, setNextState] = useState(false);
  //handle  for energylabel
  const handleAddPropertyAndEnergyNext = () => {
    setStepWizard("UploadDocuments");
    setProgress(99.99);
    setNextState(true);
  };
  const handleAddPropertyAndEnergyCancel = () => {
    setOpenAddEnergyWizard(false);
    setProgress(50);
  };
  // handle for upload document
  const handleUploadDocumentsAdd = () => {
    setOpenAddEnergyWizard(false);
    setProgress(0);
  };
  const handleUploadDocumentsBack = () => {
    setStepWizard("AddPropertyAndEnergy");
    setNextState(false);
    setProgress(50);
  };

  const WizardsPages = {
    AddPropertyAndEnergy: (
      <AddPropertyAndEnergy
        isEnergy={true}
        handleNext={handleAddPropertyAndEnergyNext}
        handleCancel={handleAddPropertyAndEnergyCancel}
      />
    ),
    UploadDocuments: (
      <UploadDocument
        handleAdd={handleUploadDocumentsAdd}
        handleBack={handleUploadDocumentsBack}
      />
    ),
  };
  return (
    <div>
      <div>
        <CustomPopup
          open={openAddEnergyWizard}
          title={nextState ? "Upload Documents" : "Add Energy Label"}
          title_color="black"
          enableCrossButton={false}
          // onHide={() => setOpenAddEnergyWizard(false)}
          size={"xl"}
          titleTooltip={true}
          titleTooltipText={nextState ? "Upload Documents" : "Add Energy Label"}
          extraInfo={false}
          likeFeature={false}
          // extraInfoText={
          //   <div className="grey-light-font-text">Thomas Høgh Mogensen</div>
          // }
          // onClickCrossButton={() => setOpenAddEnergyWizard(false)}
        >
          <div>
            <StyledLoadingBar>
              <LoadingBar
                color={customproperties.primaryColor}
                progress={progress}
                containerStyle={{
                  position: "absolute",
                  height: "2.5px",
                }}
                shadow={false}
                // onLoaderFinished={() => setProgress(0)}
              />
            </StyledLoadingBar>
            {WizardsPages[stepWizard]}
          </div>
        </CustomPopup>
      </div>
    </div>
  );
};

export default AddEnergyAndOperationWizard;
