import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import ETButton from "../../../../../Components/Buttons/ETButton";
import CustomToolTip from "../../../../../Components/Tooltip/CustomTooltip";
import GroupText from "../../../../../Components/GroupText/GroupText";
import { Menu } from "@mui/material";
import { useState } from "react";
import CustomCheckBox from "../../../../../Components/Checkbox/Checkbox";
import { NavLink } from "react-router-dom";
import { GetUnitDetailByUnitId } from "../../../../../services/Units";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { GetPropertiesZipCode } from "../../../../../services/Properties";
import {
  useEditUnitDetail,
  useGetEditUnitsTextField,
  useGetUnitDetailsByUnitId,
  useGetUnitHousing,
} from "../../../../../hooks/queries/useUnitQueries";
import { useGetPropertyTypes } from "../../../../../hooks/queries/usePropertyQueries";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import ReactSelect from "../../../../../Components/DropDown/ReactSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useGetCoordinates,
  useGetStreetDetails,
} from "../../../../../hooks/queries/useGeographyQueries";
import moment from "moment";
import { status } from "../../../../../model/Constant";
import { GreenSpan, H2, H3 } from "../../../../../styles/Common.style";
import { TabsButtonIcon } from "../../../../../Components/Tabs/Tabs.style";
import { usePropertyOverview } from "../../../../../hooks/stores/usePropertyStore";
import { useUnitType } from "../../../../../hooks/stores/useUnitStore";
import { useBreadcrumb } from "../../../../../Components/BreadcrumbContext ";
import InputField from "../../../../../Components/Textfield/InputField";
import { useUserDetails } from "../../../../../hooks/stores/useUserDetailsStore";
import { UTCtoLocalTimeZone } from "../../../../../utils/UTCtoLocalTimeZone";

const squareMeasureData = [
  {
    Id: 1,
    Text: "squaremeters",
  },
  {
    Id: 2,
    Text: "squarefeet",
  },
  {
    Id: 3,
    Text: "hectare",
  },
  {
    Id: 4,
    Text: "acre",
  },
];

const UnitDetails = ({ UnitId, propId }) => {
  //States
  const [unitIdentity, setUnitIdentity] = useState("");
  const [unitType, setUnitType] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [countryId, setCountryId] = useState("");
  const [country, setCountry] = useState("");
  const [altUnitId, setAltUnitId] = useState("second");
  const [streetname, setStreetname] = useState("");
  const [housenum, setHousenum] = useState("");
  const [city, setCity] = useState("");
  const [showzip, setshowzip] = useState("");
  const [squareMeasure, setSquarMeasure] = useState("");

  const [unitIdentitfication, setunitIdentitfication] = useState([]);
  const { typeOfUnit } = useUnitType((state) => state);

  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    updateBreadcrumbs(["Unit details"]);
  }, []);

  //---------------------react query----------------------------//
  const {
    mutateAsync: PutEditUnitDetails,
    isLoading: PutEditUnitDetailsIsLoading,
  } = useEditUnitDetail();
  const { data: getUnitTextFields } = useGetEditUnitsTextField(
    propId,
    typeOfUnit
  );

  const propOverview = usePropertyOverview((state) => state.propOverview);
  //data filter for textFields //

  //---------------- unit identification variables //
  const unitDetailIdentitficationData = getUnitTextFields?.data?.filter(
    (x) => x.section_id == 201
  );
  // console.log(unitDetailIdentitficationData, "unitDetailIdentitficationData");
  const unitDetailIdentitficationDataType0 =
    unitDetailIdentitficationData?.filter(
      (x) =>
        x.is_displayed == 0 &&
        (x?.sale_type === propOverview?.[0]?.type_id || x?.sale_type === 0)
    );

  const unitDetailIdentitficationDataType1and2 =
    unitDetailIdentitficationData?.filter(
      (x) =>
        x.is_displayed == 1 &&
        (x?.sale_type == propOverview?.[0]?.type_id || x?.sale_type == 0)
    );
  const unitDetailIdentitficationDataSaleType2 =
    unitDetailIdentitficationData?.filter(
      (x) => x.is_displayed == 1 && x?.sale_type == 2
    );
  const unitDetailIdentitficationDataType1 =
    unitDetailIdentitficationData?.filter((x) => x.is_displayed == 1);
  const unitDetailIdentitficationDataType2 =
    unitDetailIdentitficationData?.filter((x) => x.is_displayed == 2);

  // console.log(
  //   unitDetailIdentitficationDataType2,
  //   "unitDetailIdentitficationDataType2"
  // );
  // filter logics for unit identification
  let dataForCheckboxLogic = unitDetailIdentitficationDataType1and2?.map(
    (x) => ({ ...x, checked: x.is_displayed == 1 ? true : false })
  );
  dataForCheckboxLogic = dataForCheckboxLogic?.sort(
    (a, b) => a?.is_displayed - b?.is_displayed
  );

  //----------------unit address variables  //
  const unitDetailUnitAddressData = getUnitTextFields?.data?.filter(
    (x) => x.section_id == 202
  );
  const unitAddressType2Data = unitDetailUnitAddressData?.filter(
    (x) =>
      x?.is_displayed == 2 &&
      (x?.sale_type === propOverview?.[0]?.type_id || x?.sale_type === 0)
  );
  const unitAddressCheckboxData = unitAddressType2Data?.map((x) => ({
    ...x,
    checked: x.is_displayed == 2 && false,
  }));

  //-------------- unit availability data variables //
  const unitDetailAvailabilityData = getUnitTextFields?.data?.filter(
    (x) => x.section_id == 203
  );
  const unitAvailabilityType1and2Data = unitDetailAvailabilityData?.filter(
    (x) =>
      x?.is_displayed !== 0 &&
      (x?.sale_type === propOverview?.[0]?.type_id || x?.sale_type === 0)
  );
  let unitAvailabilityCheckboxData = unitAvailabilityType1and2Data?.map(
    (x) => ({ ...x, checked: x.is_displayed == 1 ? true : false })
  );

  //---------------unit layout data variables //
  const unitDetailUnitLayoutData = getUnitTextFields?.data?.filter(
    (x) => x.section_id == 204
  );
  const unitLayoutType1and2Data = unitDetailUnitLayoutData?.filter(
    (x) =>
      x?.is_displayed !== 0 &&
      (x?.sale_type === propOverview?.[0]?.type_id || x?.sale_type === 0)
  );
  let unitLayoutType0Data = unitDetailUnitLayoutData?.filter(
    (x) =>
      x?.is_displayed == 0 &&
      (x?.sale_type === propOverview?.[0]?.type_id || x?.sale_type === 0)
  );
  // unitLayoutType0Data = unitLayoutType0Data?.sort(
  //   (a, b) => b?.suffix.length - a?.suffix.length
  // );

  let unitLayoutCheckboxData = unitLayoutType1and2Data?.map((x) => ({
    ...x,
    checked: x.is_displayed == 1 ? true : false,
  }));

  //react query
  const { data: propertyTypesData } = useGetPropertyTypes();
  const { data: propertyZipCode } = useQuery(
    ["QzipcodebycountryId", countryId],
    () => GetPropertiesZipCode(countryId),
    {
      enabled: !!countryId,
    }
  );

  const { data: unitHousing, isLoading } = useGetUnitHousing();

  useEffect(() => {
    UnitDetailByUnitId(UnitId);
    setUnitIdentitficationData(dataForCheckboxLogic);
    setUnitAddressData(unitAddressCheckboxData);
    setUnitAvailabilityData(unitAvailabilityCheckboxData);
    setUnitLayoutDATA(unitLayoutCheckboxData);
  }, [UnitId]);

  useEffect(() => {
    setTimeout(() => {
      setAltUnitId(unitDetail?.alt_unit_id);
    }, 350);
  }, [unitIdentitfication]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setDropdownOpen(false);
  };
  //------------------validation-------------------------//
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({});

  // unit identification section
  const [unitIdentitficationData, setUnitIdentitficationData] = useState([]);
  let unitIdentitficationFilterData = unitIdentitficationData?.filter(
    (x) => x?.checked == true
  );

  const { data: getCoordinatesByInfo } = useGetCoordinates(
    getValues("street_name") +
      " " +
      getValues("house_number") +
      "," +
      getValues("city") +
      " " +
      showzip +
      "," +
      country
  );
  const identificationClick = (e, i) => {
    const { id, checked, name } = e.currentTarget;
    let checkValue = [...unitIdentitficationData];
    // console.log("identificationClick checkValue", checkValue);
    if (checked) {
      // console.log(checked, "if checked");
      checkValue[i].checked = true;
      // console.log("if checked checkValue", checkValue);
    } else {
      checkValue[i].checked = false;
      // console.log("else checked checkValue", checkValue);
    }
    setUnitIdentitficationData(checkValue);
  };
  // console.log(unitIdentitficationData, "unitIdentitficationData");
  // unit address section //
  const [unitAddressData, setUnitAddressData] = useState([]);
  let unitAddressFilterData = unitAddressData?.filter(
    (x) => x?.checked == true
  );
  const addressClick = (e, i) => {
    const { id, checked, name } = e.currentTarget;
    let checkValue = [...unitAddressData];
    if (checked) {
      checkValue[i].checked = true;
    } else {
      checkValue[i].checked = false;
    }
    setUnitAddressData(checkValue);
  };

  // unit Availability section //
  const [unitAvailabilityData, setUnitAvailabilityData] = useState([]);
  let unitAvailabilityFilterData = unitAvailabilityData?.filter(
    (x) => x?.checked == true
  );
  const AvailabilityClick = (e, i) => {
    const { id, checked, name } = e.currentTarget;
    let checkValue = [...unitAvailabilityData];
    if (checked) {
      checkValue[i].checked = true;
    } else {
      checkValue[i].checked = false;
    }
    setUnitAvailabilityData(checkValue);
  };

  // unit layout section //
  const [unitLayoutDATA, setUnitLayoutDATA] = useState([]);
  let unitLayoutFilterData = unitLayoutDATA?.filter((x) => x?.checked == true);
  const unitLayoutClick = (e, i) => {
    const { id, checked, name } = e.currentTarget;
    let checkValue = [...unitLayoutDATA];
    if (checked) {
      checkValue[i].checked = true;
    } else {
      checkValue[i].checked = false;
    }
    setUnitLayoutDATA(checkValue);
  };

  //API Consumption Get Unit detail by unitId
  const [unitDetail, setUnitDetail] = useState([]);
  const [error, setError] = useState({
    error: true,
    status: "",
    message: "",
  });

  //   const modifiedOn = moment(unitDetail?.modified_on).format(
  //     "DD.MMMM.YYYY | HH:mm:ss"
  //   );
  //   const createdOn = moment(unitDetail?.created_on).format(
  //     "DD.MMMM.YYYY | HH:mm:ss"
  // 	);

  const { userDetails } = useUserDetails((state) => state);
  const { convertedDate: createdOn } = UTCtoLocalTimeZone({
    timestamp: unitDetail?.created_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });
  const { convertedDate: modifiedOn } = UTCtoLocalTimeZone({
    timestamp: unitDetail?.modified_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });

  //use for default value in react select //
  let options = {
    value: zipCode,
    label: zipCode,
  };
  // const handleZipCode = (e) => {
  // 	if (e?.value != "" || e?.value != "undefined") {
  // 		setZipCode(e.value);
  // 		setshowzip(e?.value);
  // 		// setInfoForCoord(e?.value);
  // 		const HandleCityName = propertyZipCode?.data?.filter(
  // 			(data) => data?.Id == e?.value
  // 		);
  // 		const CityData = HandleCityName[0].Text;
  // 		setCity(CityData);
  // 	}
  // };

  const handleZipCode = (e, ActionType) => {
    if (ActionType?.action === "clear") {
      setValue("city", "");
    } else {
      if (e?.value != "" || e?.value != "undefined") {
        // setshowzip(e?.value)
        const HandleCityName = propertyZipCode?.data?.filter(
          (data) => data?.Id == e?.value
        );
        const CityData = HandleCityName?.[0]?.Text;
        setValue("city", CityData);
      }
    }
  };
  const [allState, setAllState] = useState({});
  // console.log(allState, "unitsIdenti");
  const handleUnitIdentificationChange = (e, item) => {
    let { name, value } = e.target;
    let newValue = value;
    setAllState({
      ...allState,
      [name]: newValue,
    });
  };

  const handleSave = async (data) => {
    const mydata = {
      unit_id: UnitId,
      street_name: data?.street_name,
      status_id: data?.status_id.toString() || "0",
      no_of_rooms: data?.no_of_rooms.toString() || "0",
      side_door: data?.side_door,
      floor_number: data?.floor_number.toString() || "0",
      house_number: data?.house_number.toString(),
      zipcode_id: getValues("zipcode_id.value"),
      internal_floors: data?.internal_floors.toString() || "0",
      registered_area: data?.registered_area.toString() || "0",
      area: data?.area || "0",
      internal_area: data?.internal_area.toString() || "0",
      common_area: data?.common_area.toString() || "0",
      vacancy_date: data?.vacancy_date,
      //  dayjs(avaliableFrom).format("YYYY-MM-DD"),
      move_out_date: data?.move_out_date,
      //  dayjs(moveOutDate).format("YYYY-MM-DD"),
      stage: data?.stage,
      latitude: data?.latitude.toString(),
      longitude: data?.longitude.toString(),
      alt_unit_id: data?.alt_unit_id,
      housing_type_id: data?.housing_type_id?.value.toString(),
      square_measure: data?.square_measure?.value.toString(),
      address_add_info: data?.address_add_info,
      extra_id: data?.extra_id,
      plot_area: data?.plot_area || "0",
      move_in_date: data?.move_in_date,
      is_renovation_ongoing: data?.is_renovation_ongoing == false ? "0" : "1",
      unit_construction_year: data?.unit_construction_year,
      area_business_premises: data?.area_business_premises || "0",
    };
    // console.log(mydata,"mydata");
    const response = await PutEditUnitDetails(mydata);
    if (response.status == status.s) {
      toast.success(response.data.message);
    } else if (response.status == status.e) {
      toast.error(response.message);
    }
  };

  // const squarMeasureChange = (e, ActionTypes) => {
  //   if (ActionTypes.action === "clear") {
  //     setSquarMeasure("");
  //   } else {
  //     setSquarMeasure(e.value);
  //   }
  // };
  // const { data: useGetUnitDetailsByUnitIddata } = useGetUnitDetailsByUnitId(UnitId)
  // console.log(useGetUnitDetailsByUnitIddata?.data, "data")
  const zipcodewatch = watch("zipcode_id");
  const UnitDetailByUnitId = () => {
    try {
      GetUnitDetailByUnitId(UnitId)
        .then((data) => {
          // console.log(data.data[0], "apidata");
          if (data != null && data != undefined) {
            setUnitDetail(data.data[0]);
            // setUnitIdentity(data?.data[0]?.unit_id);
            // setValue("type_name", data?.data[0]?.type_name);
            setValue("alt_unit_id", data?.data[0]?.alt_unit_id);
            setValue("housing_type_id", {
              value: data?.data[0]?.housing_type_id,
              label: data?.data[0]?.housing_type_name,
            });
            setValue("stage", data?.data[0]?.stage);
            setValue(
              "unit_construction_year",
              data?.data[0]?.unit_construction_year
            );
            setValue("status_id", data?.data[0]?.status_id);
            setValue("extra_id", data?.data[0]?.extra_id);
            setValue("street_name", data?.data[0]?.street_name);
            setValue("house_number", data?.data[0]?.house_number);
            setValue("floor_number", data?.data[0]?.floor_number);
            setValue("side_door", data?.data[0]?.side_door);
            setValue("zipcode_id", {
              value: data?.data[0]?.zipcode_id,
              label: data?.data[0]?.zipcode_id,
            });
            setValue("city", data?.data[0]?.city_name);
            // setValue("latitude", data?.data[0]?.latitude);
            // setValue("longitude", data?.data[0]?.longitude);

            setValue("address_add_info", data?.data[0]?.address_add_info);
            setValue("vacancy_date", data?.data[0]?.vacancy_date);
            setValue("move_out_date", data?.data[0]?.move_out_date);
            setValue("move_in_date", data?.data[0]?.move_in_date);
            setValue(
              "is_renovation_ongoing",
              data?.data[0]?.is_renovation_ongoing
            );

            setUnitType(data?.data[0]?.unit_type_id);

            setValue("area", data?.data[0]?.area);
            setValue("registered_area", data?.data[0]?.registered_area);
            setValue("internal_area", data?.data[0]?.internal_area);
            setValue("common_area", data?.data[0]?.common_area);
            setValue("internal_floors", data?.data[0]?.internal_floors);
            setValue("no_of_rooms", data?.data[0]?.no_of_rooms);
            setValue("plot_area", data?.data[0]?.plot_area);

            setValue(
              "area_business_premises",
              data?.data[0]?.area_business_premises
            );
            // setZipCode(data?.data[0]?.zipcode_id);
            setshowzip(data?.data[0]?.zipcode_id);
            setCity(data?.data[0]?.city_name);
            setCountry(data?.data[0]?.country_name);
            setCountryId(data?.data[0]?.country_id);
            setStreetname(data?.data[0]?.street_name);
            setHousenum(data?.data[0]?.house_number);
            setSquarMeasure(
              unitDetailUnitLayoutData
                ?.filter((x) => x.column_name === "square_measure")?.[0]
                ?.possible_values?.filter(
                  (val) => val?.Id == data?.data[0]?.square_measure
                )?.[0]?.DataValue2
            );
            setValue("square_measure", {
              value: data?.data[0]?.square_measure,
              label: unitDetailUnitLayoutData
                ?.filter((x) => x.column_name === "square_measure")?.[0]
                ?.possible_values?.filter(
                  (val) => val?.Id == data?.data[0]?.square_measure
                )?.[0]?.Text,
            });
          } else {
            setUnitDetail([null]);
          }
        })
        .catch((error) => {
          setError({ error: error.message, status: error.status });
          setUnitDetail([null]);
        });
    } catch (e) {
      setUnitDetail([null]);
      setError({
        error: true,
        status: "",
        message: "Failed to retrieve data",
      });
    }
  };
  useEffect(() => {
    UnitDetailByUnitId();
  }, [unitIdentitficationData]);

  useEffect(() => {
    setValue("latitude", getCoordinatesByInfo?.data?.latitude);
    setValue("longitude", getCoordinatesByInfo?.data?.longitude);
  }, [getCoordinatesByInfo]);

  // console.log(unitIdentitficationFilterData, "unitIdentitficationFilterData");

  const [searchedVlue, setSearchedVlue] = useState("");
  const [searchedStreetName, setSearchedStreetName] = useState("");
  const [isSearchStreetVisible, setIsSearchStreetVisible] = useState(false);

  const { data: getStreetDetailsData } =
    useGetStreetDetails(searchedStreetName);
  // const watchFields = watch("country"); // you can also target specific fields by their names
  const handleInputChange = (event) => {
    setSearchedVlue(event.target.value);
    if (countryId == "DK" && searchedVlue?.length >= 4) {
      setIsSearchStreetVisible(true);
      setSearchedStreetName(event?.target?.value);
    } else {
      setIsSearchStreetVisible(false);
    }
  };

  const handleItemClick = (item, e) => {
    setSearchedVlue(item);
    // setFilteredStreetname([]);
    setValue(
      "zipcode_id",
      item?.zipcode_id == ""
        ? ""
        : {
            value: item?.zipcode,
            label: item?.zipcode,
          }
    );
    // Set the value directly
    setValue("city", item?.city === "" ? "" : item?.city);
    // setValue('addInfo', item?.address === "" ? "" : item?.address);
    setValue(
      "house_number",
      item?.house_number === "" ? "" : item?.house_number
    );
    setValue("street_name", item?.street_name === "" ? "" : item?.street_name);
  };
  return (
    <div className="bg-white radius-8 fs-14  mainborder">
      <div className="d-flex justify-content-between px-4  pt-3">
        <div className="d-flex flex-column ">
          <div className="d-flex align-items-center">
            <H2>Unit details</H2>
            <span className="ms-2 ">
              <CustomToolTip
                size="md"
                text="Unit details"
              />
            </span>
          </div>
          {unitDetail?.modified_by && unitDetail?.modified_on ? (
            <div className="fs-12 created-by">
              Last updated by &nbsp;{unitDetail?.modified_by},&nbsp;
              {modifiedOn}
            </div>
          ) : (
            <>
              {unitDetail?.created_by && unitDetail?.created_on ? (
                <div className="fs-12 created-by">
                  Created by {unitDetail?.created_by},&nbsp;
                  {createdOn}
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </div>

        <div>
          <ETButton
            buttonText="ADD FIELDS"
            variant={"secondary"}
            icon={true}
            width="fit-content"
            onClick={handleClick}
          />
          <Menu
            id="simple-menu"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            open={anchorEl}
            onClose={handleClose}
            elevation={0}
            HorizontalAlignment="left"
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              style: {
                borderRadius: "8px",
                marginTop: "6px",
                border: "0.5px solid #F0F0F0",
                boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.12)",
                paddingTop: "0px",
              },
            }}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.02)",
              "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                padding: 0,
                borderColor: "#F0F0F0",
              },
            }}
          >
            <div
              className="px-3 py-3  fs-14 radius-8"
              style={{
                width: "300px",
                height: "267px",
              }}
            >
              <div>
                <div className="form-label-title ps-2">
                  Unit identitfication
                </div>
                {unitIdentitficationData?.map((item, i) => {
                  return (
                    <div
                      className="d-flex py-1 "
                      key={item?.field_id}
                    >
                      <CustomCheckBox
                        height="16px"
                        id={item?.field_id}
                        name={item?.field_label}
                        checked={item?.checked}
                        onChange={(e) => identificationClick(e, i)}
                      />
                      <div className="ps-1 d-flex align-items-center light-font-text">
                        {item?.field_label}
                      </div>
                    </div>
                  );
                })}
                <div className="form-label-title ps-2 pt-3">Unit address</div>
                {unitAddressData?.map((item, i) => {
                  return (
                    <div
                      className="d-flex py-1"
                      key={item?.field_id}
                    >
                      <CustomCheckBox
                        height="16px"
                        id={item?.field_id}
                        name={item?.field_label}
                        checked={item?.checked}
                        onChange={(e) => addressClick(e, i)}
                      />
                      <div className="ps-1 d-flex align-items-center light-font-text">
                        {item?.field_label}
                      </div>
                    </div>
                  );
                })}

                <div className="form-label-title ps-2 pt-3">Availability</div>
                {unitAvailabilityData?.map((item, i) => {
                  return (
                    <div
                      className="d-flex  py-1"
                      key={item?.field_id}
                    >
                      <CustomCheckBox
                        height="16px"
                        id={item?.field_id}
                        name={item?.field_label}
                        checked={item?.checked}
                        onChange={(e) => AvailabilityClick(e, i)}
                      />
                      <div className="ps-1 d-flex align-items-center light-font-text">
                        {item?.field_label}
                      </div>
                    </div>
                  );
                })}

                <div className="form-label-title ps-2 pt-3">Unit layout</div>

                {unitLayoutDATA?.map((item, i) => {
                  return (
                    <div
                      className="d-flex py-1"
                      key={item?.field_id}
                    >
                      <CustomCheckBox
                        height="16px"
                        id={item?.field_id}
                        name={item?.field_label}
                        checked={item?.checked}
                        onChange={(e) => unitLayoutClick(e, i)}
                      />
                      <div className="ps-1 d-flex align-items-center light-font-text">
                        {item?.field_label}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Menu>
        </div>
      </div>

      <hr className="hr" />

      <Form onSubmit={handleSubmit(handleSave)}>
        <div className="mx-4 pe-2 pt-1">
          <div className="form-label-title mb-2">Unit identitfication</div>
          <Row>
            <Col
              lg={6}
              md={12}
            >
              <Row>
                <Col
                  lg={6}
                  className="my-2 pe-lg-0"
                >
                  <div className="form-input ">
                    <input
                      type="text"
                      disabled
                      placeholder="Unit ID"
                      id="unitID"
                      value={UnitId}
                    />
                    <label htmlFor="unitID">Unit ID</label>
                  </div>
                </Col>
                {unitDetailIdentitficationDataType0?.map((item, i) => {
                  return (
                    <Col
                      lg={6}
                      className="my-2 pe-lg-0"
                      key={i}
                    >
                      {item?.field_type === "Text" ? (
                        <Controller
                          control={control}
                          {...register(`${item?.column_name}`)}
                          rules={{
                            required: item?.is_mandatory == 0 ? false : true,
                          }}
                          render={({ field: { onChange, value } }) => (
                            <div className="form-input ">
                              <input
                                type={item?.field_type}
                                placeholder={item?.field_label}
                                id={item?.column_name}
                                name={item?.field_label}
                                disabled={
                                  item?.is_non_editable == 1 ? true : false
                                }
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  clearErrors(`${item?.column_name}`);
                                }}
                                // onChange={(e) => handleUnitIdentificationChange(e, item)}
                              />
                              <label htmlFor={item?.column_name}>
                                {item?.field_label}
                              </label>
                            </div>
                          )}
                        />
                      ) : (
                        <Controller
                          {...register(`${item?.column_name}`)}
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <ReactSelect
                              placeholder={item?.field_label}
                              value={value}
                              setPlaceholderPosition={true}
                              options={item?.possible_values.map((x) => ({
                                value: x?.Id,
                                label: x?.Text,
                              }))}
                              onChange={(e, ActionTypes) => {
                                onChange(e);
                              }}
                              clearable={false}
                            />
                          )}
                        />
                      )}
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
          <Row>
            {unitDetailIdentitficationDataSaleType2?.map((item, i) => {
              return (
                <>
                  {" "}
                  {item?.is_displayed == 1 && (
                    <Col
                      lg={3}
                      md={6}
                      className="my-2 pe-lg-0"
                      key={i}
                    >
                      <Controller
                        control={control}
                        {...register(`${item?.column_name}`)}
                        rules={{
                          required: item?.is_mandatory == 0 ? false : true,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <>
                            {item?.field_type === "Text" ? (
                              <div className="form-input ">
                                <input
                                  type={item?.field_type}
                                  placeholder={item?.field_label}
                                  id={item?.column_name}
                                  name={item?.field_label}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    clearErrors(`${item?.column_name}`);
                                  }}
                                  // onChange={(e) => handleUnitIdentificationChange(e, item)}
                                />
                                <label htmlFor={item?.column_name}>
                                  {item?.field_label}
                                </label>
                              </div>
                            ) : item?.field_type === "Date" ? (
                              <div className="form-input ">
                                <input
                                  type={item?.field_type}
                                  placeholder={item?.field_label}
                                  id={item?.column_name}
                                  name={item?.field_label}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    clearErrors(`${item?.column_name}`);
                                  }}
                                  // onChange={(e) => handleUnitIdentificationChange(e, item)}
                                />
                                <label htmlFor={item?.column_name}>
                                  {item?.field_label}
                                </label>
                              </div>
                            ) : (
                              <>
                                <ReactSelect
                                  placeholder={item?.field_label}
                                  value={value}
                                  setPlaceholderPosition={true}
                                  options={item?.possible_values.map((x) => ({
                                    value: x?.Id,
                                    label: x?.Text,
                                  }))}
                                  onChange={(e, ActionTypes) => {
                                    onChange(e);
                                  }}
                                  clearable={true}
                                />
                                {errors.housing_type_id && (
                                  <span className="error-msg">
                                    This field is required
                                  </span>
                                )}
                              </>
                            )}
                          </>
                        )}
                      />
                    </Col>
                  )}
                </>
              );
            })}
          </Row>
          <Row>
            {unitIdentitficationFilterData?.map((item, i) => {
              return (
                <>
                  {" "}
                  {item?.field_id == 23 && (
                    <Col
                      lg={3}
                      md={6}
                      className="my-2 pe-lg-0"
                      key={i}
                    >
                      <Controller
                        control={control}
                        {...register(`${item?.column_name}`)}
                        rules={{
                          required: item?.is_mandatory == 0 ? false : true,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <>
                            {item?.field_type === "Text" ? (
                              <div className="form-input ">
                                <input
                                  type={item?.field_type}
                                  placeholder={item?.field_label}
                                  id={item?.column_name}
                                  name={item?.field_label}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    clearErrors(`${item?.column_name}`);
                                  }}
                                  // onChange={(e) => handleUnitIdentificationChange(e, item)}
                                />
                                <label htmlFor={item?.column_name}>
                                  {item?.field_label}
                                </label>
                              </div>
                            ) : item?.field_type === "Date" ? (
                              <div className="form-input ">
                                <input
                                  type={item?.field_type}
                                  placeholder={item?.field_label}
                                  id={item?.column_name}
                                  name={item?.field_label}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    clearErrors(`${item?.column_name}`);
                                  }}
                                  // onChange={(e) => handleUnitIdentificationChange(e, item)}
                                />
                                <label htmlFor={item?.column_name}>
                                  {item?.field_label}
                                </label>
                              </div>
                            ) : (
                              <>
                                <ReactSelect
                                  placeholder={item?.field_label}
                                  value={value}
                                  setPlaceholderPosition={true}
                                  options={item?.possible_values.map((x) => ({
                                    value: x?.Id,
                                    label: x?.Text,
                                  }))}
                                  onChange={(e, ActionTypes) => {
                                    onChange(e);
                                  }}
                                  clearable={true}
                                />
                                {/* {errors.housing_type_id && (
                                  <span className="error-msg">
                                    This field is required
                                  </span>
                                )} */}
                              </>
                            )}
                          </>
                        )}
                      />
                    </Col>
                  )}
                </>
              );
            })}
          </Row>

          <Row>
            {unitIdentitficationFilterData?.map((item, i) => {
              return (
                <>
                  {item?.is_displayed == 2 && (
                    <Col
                      lg={3}
                      md={6}
                      className="my-2 pe-lg-0"
                      key={i}
                    >
                      <Controller
                        control={control}
                        {...register(`${item?.column_name}`)}
                        rules={{
                          required: item?.is_mandatory == 0 ? false : true,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <div className="form-input ">
                            <input
                              type={item?.field_type}
                              placeholder={item?.field_label}
                              id={item?.column_name}
                              name={item?.field_label}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors(`${item?.column_name}`);
                              }}
                              // onChange={(e) => handleUnitIdentificationChange(e, item)}
                            />
                            <label htmlFor={item?.column_name}>
                              {item?.field_label}
                            </label>
                          </div>
                        )}
                      />
                      {/* {`${errors.item?.column_name}`&& (
                <p className="red-text"
                >
                  This field is required
                </p>
              )} */}
                    </Col>
                  )}
                </>
              );
            })}
          </Row>
        </div>
        <hr className="hr" />

        <div className="mx-4 pe-2 pt-1">
          <div className="form-label-title mb-2">Unit address</div>
          <Row>
            <Col
              lg={3}
              md={6}
              className="my-2 pe-lg-0"
              style={{ position: "relative" }}
            >
              <Controller
                control={control}
                {...register("street_name")}
                rules={{
                  required:
                    unitDetailUnitAddressData?.filter(
                      (uD) => uD?.column_name === "street_name"
                    )[0]?.is_mandatory == 0
                      ? false
                      : true,
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    {/* <input
											type="text"
											placeholder="Street"
											id="Street"
											value={value}
											onChange={(e) => {
												onChange(e);
												handleInputChange(e);
												clearErrors("street_name");
											}}
										/> */}
                    <InputField
                      id={"Street"}
                      value={value}
                      type={"text"}
                      placeholder={"Street"}
                      iserror={errors.street_name}
                      onChange={(e) => {
                        onChange(e);
                        handleInputChange(e);
                        clearErrors("street_name");
                      }}
                    />
                    {/* <label htmlFor="Street">Street</label> */}
                  </div>
                )}
              />
              {/* {errors.street_name && (
								<span className="error-msg">This field is required</span>
							)} */}
              {searchedVlue?.length < 4 &&
              searchedVlue?.length > 0 &&
              countryId == "DK" ? (
                <div className="fs-12 fw-400 grey-text pt-1 ">
                  Type in minimum 4 characters to get a search result
                </div>
              ) : (
                <>
                  {/* {searchedVlue == filteredStreetname ? <> */}
                  {isSearchStreetVisible && countryId == "DK" && (
                    <div style={{}}>
                      <div
                        className="bg-white radius-8 pe-1 filtered-list"
                        style={{
                          border: "1px solid #D1D1D1",
                          position: "absolute",
                          // top: "50px",
                          // left: "14px",
                          zIndex: "10",
                          width: "96%",
                        }}
                      >
                        <>
                          {getStreetDetailsData?.data?.length > 0 ? (
                            <div>
                              {getStreetDetailsData?.data?.map(
                                (item, index) => (
                                  <div
                                    className="mt-1 px-2"
                                    key={index}
                                    onClick={() => {
                                      handleItemClick(item);
                                      setIsSearchStreetVisible(false);
                                    }}
                                  >
                                    {item?.city ||
                                    item?.zipcode ||
                                    item?.house_number ? (
                                      <> {item?.address} </>
                                    ) : (
                                      <> {item?.street_name}</>
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          ) : (
                            <div className="mt-1 px-2 fw-300 grey-text">
                              No data found
                            </div>
                          )}
                        </>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Col>

            <Col
              lg={2}
              md={6}
              className="my-2 pe-lg-0"
            >
              <Controller
                control={control}
                {...register("house_number")}
                rules={{
                  required:
                    unitDetailUnitAddressData?.filter(
                      (uD) => uD?.column_name === "house_number"
                    )[0]?.is_mandatory == 0
                      ? false
                      : true,
                }}
                render={({ field: { onChange, value } }) => (
                  <div className="form-input ">
                    {/* <input
											type="text"
											placeholder="No."
											id="No."
											value={value}
											onChange={(e) => {
												onChange(e);
												clearErrors("house_number");
											}}
										/> */}
                    <InputField
                      id={"No."}
                      value={value}
                      type={"text"}
                      placeholder={"No."}
                      iserror={errors.house_number}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("house_number");
                      }}
                    />
                    {/* <label htmlFor="No.">No.</label> */}
                  </div>
                )}
              />
              {/* {errors.house_number && (
								<span className="error-msg">This field is required</span>
							)} */}
            </Col>
            <Col
              lg={2}
              md={6}
              className="my-2 pe-lg-0"
            >
              <Controller
                control={control}
                {...register("floor_number")}
                rules={{
                  required:
                    unitDetailUnitAddressData?.filter(
                      (uD) => uD?.column_name === "floor_number"
                    )[0]?.is_mandatory == 0
                      ? false
                      : true,
                }}
                render={({ field: { onChange, value } }) => (
                  <div className="form-input ">
                    <input
                      type="text"
                      placeholder="Floor"
                      id="Floor"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("floor_number");
                      }}
                      maxLength={15}
                    />
                    <label htmlFor="Floor">Floor</label>
                  </div>
                )}
              />
            </Col>
            <Col
              lg={2}
              md={6}
              className="my-2 pe-lg-0"
            >
              <Controller
                control={control}
                {...register("side_door")}
                rules={{
                  required:
                    unitDetailUnitAddressData?.filter(
                      (uD) => uD?.column_name === "side_door"
                    )[0]?.is_mandatory == 0
                      ? false
                      : true,
                }}
                render={({ field: { onChange, value } }) => (
                  <div className="form-input ">
                    <input
                      type="text"
                      placeholder="Side / Door"
                      id="SideDoor"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        // setRent(e.target.value);
                        clearErrors("side_door");
                      }}
                    />
                    <label htmlFor="SideDoor">Side / Door</label>
                  </div>
                )}
              />
              {errors?.sidedoor && (
                <p
                  style={{
                    color: "red",
                  }}
                >
                  This field is required
                </p>
              )}
            </Col>
          </Row>

          <Row>
            <Col
              lg={3}
              md={6}
              className="my-2 pe-lg-0"
            >
              <Controller
                control={control}
                {...register("zipcode_id")}
                rules={{
                  required:
                    unitDetailUnitAddressData?.filter(
                      (uD) => uD?.column_name === "zipcode_id"
                    )[0]?.is_mandatory == 0
                      ? false
                      : true,
                }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder="Zip code"
                    // value={value}
                    clearable={false}
                    isSearchable
                    onChange={(e) => {
                      onChange(e);
                      handleZipCode(e);
                      clearErrors("zipcode_id");
                    }}
                    error={errors.zipcode_id}
                    options={propertyZipCode?.data?.map((zip) => ({
                      value: zip?.Id,
                      label: `${zip?.Id} ${zip?.Text}`,
                    }))}
                    value={
                      value?.value
                        ? {
                            value: value?.value,
                            label: value?.value,
                          }
                        : ""
                    }
                  />
                )}

                // render={({ field: { onChange, value } }) => (
                // 	<ReactSelect
                // 		placeholder={"Zip code"}
                // 		value={options}
                // 		onChange={(e) => {
                // 			handleZipCode(e);
                // 			onChange(e);
                // 			clearErrors("zipcode_id");
                // 		}}
                // 		options={propertyZipCode?.data?.map((zip) => ({
                // 			value: zip?.Id,
                // 			label: `${zip?.Id} ${zip?.Text}`,
                // 		}))}
                // 		clearable={false}
                // 	/>
                // )}
              />
              {errors.zipcode_id && (
                <p
                  style={{
                    color: "red",
                  }}
                >
                  This field is required
                </p>
              )}
            </Col>

            <Col
              lg={3}
              md={6}
              className="my-2 pe-lg-0"
            >
              <div className="form-input ">
                <Controller
                  name="city"
                  {...register("city")}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <div className="form-input ">
                      <input
                        type="text"
                        disabled
                        placeholder="City"
                        id="city"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("city");
                        }}
                      />
                      <label htmlFor="city">City</label>
                    </div>
                  )}
                />
                {/* <input
									type="text"
									disabled
									placeholder="City"
									id="city"
									value={city ? city : " "}
								/>
								<label htmlFor="city">City</label> */}
              </div>
            </Col>
            <Col
              lg={3}
              md={6}
              className="my-2 pe-lg-0"
            >
              <div className="form-input ">
                <input
                  type="text"
                  disabled
                  placeholder="Country"
                  id="Country"
                  value={country}
                />
                <label htmlFor="Country">Country</label>
              </div>
            </Col>
          </Row>

          <Row>
            <Col
              lg={3}
              md={6}
              className="my-2 pe-lg-0"
            >
              <Controller
                control={control}
                {...register("latitude")}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <div className="form-input ">
                    <input
                      type="text"
                      placeholder="Latitude"
                      id="Latitude"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        // setRent(e.target.value);
                        clearErrors("latitude");
                      }}
                    />
                    <label htmlFor="Latitude">Latitude</label>
                  </div>
                )}
              />
              {/* {errors.lat && (
                <p
                  style={{
                    color: "red",
                  }}
                >
                  This field is required
                </p>
              )} */}
            </Col>

            <Col
              lg={3}
              md={6}
              className="my-2 pe-lg-0"
            >
              <Controller
                control={control}
                {...register("longitude")}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <div className="form-input ">
                    <input
                      type="text"
                      placeholder="Longitude"
                      id="Longitude"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        // setRent(e.target.value);
                        clearErrors("longitude");
                      }}
                    />
                    <label htmlFor="Longitude">Longitude</label>
                  </div>
                )}
              />
              {/* {errors.long && (
                <p
                  style={{
                    color: "red",
                  }}
                >
                  This field is required
                </p>
              )} */}
            </Col>
            <Col
              lg={3}
              md={6}
              className="my-2 pe-lg-0 d-flex align-item-center"
            >
              <div className="py-2 px-2 d-flex ">
                <GreenSpan
                  className="ms-1 fs-12 cursor-pointer d-flex justify-content-between gap-1"
                  style={{ fontFamily: "Cerebri sans-medium" }}
                >
                  <TabsButtonIcon>
                    <FontAwesomeIcon
                      icon="fa-solid fa-location-dot"
                      size="sm"
                    />
                  </TabsButtonIcon>
                  Change coordinates
                </GreenSpan>
              </div>
            </Col>
          </Row>
          <Row>
            {unitAddressFilterData?.map((item, i) => {
              return (
                <Col
                  lg={3}
                  md={6}
                  className="mb-2 pe-lg-0"
                  key={i}
                >
                  <Controller
                    control={control}
                    {...register(`${item?.column_name}`)}
                    rules={{
                      required: item?.is_mandatory == 0 ? false : true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <div className="form-input ">
                        <input
                          type={item?.field_type}
                          placeholder={item?.field_label}
                          id={item?.column_name}
                          name={item?.field_label}
                          disabled={item?.is_non_editable == 1 ? true : false}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors(`${item?.column_name}`);
                          }}
                          // onChange={(e) => handleUnitIdentificationChange(e, item)}
                        />
                        <label htmlFor={item?.column_name}>
                          {item?.field_label}
                        </label>
                      </div>
                    )}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
        <hr className="hr" />

        <div className="mx-4 pe-2 pt-1">
          <div className="form-label-title mb-2">Availability</div>
          <Row>
            {unitAvailabilityFilterData?.map((item, i) => {
              return (
                <>
                  {item?.field_type == "Date" ? (
                    <Col
                      xl={3}
                      lg={4}
                      md={6}
                      className="my-2 pe-lg-0"
                      key={i}
                    >
                      <Controller
                        control={control}
                        {...register(`${item?.column_name}`)}
                        rules={{
                          required: item?.is_mandatory == 0 ? false : true,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <div className="form-input ">
                            <input
                              type={item?.field_type}
                              placeholder={item?.field_label}
                              id={item?.column_name}
                              name={item?.field_label}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors(`${item?.column_name}`);
                              }}
                              // onChange={(e) => handleUnitIdentificationChange(e, item)}
                            />
                            <label htmlFor={item?.column_name}>
                              {item?.field_label}
                            </label>
                          </div>
                        )}
                      />
                    </Col>
                  ) : (
                    <Col
                      xl={3}
                      lg={4}
                      md={6}
                      className="my-2 pe-lg-0"
                      key={item?.field_id}
                    >
                      <Controller
                        control={control}
                        {...register(`${item?.column_name}`)}
                        rules={{
                          required: item?.is_mandatory == 0 ? false : true,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <label className="d-flex py-1 ">
                            <CustomCheckBox
                              paddingleft={"2px"}
                              checked={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors(`${item?.column_name}`);
                              }}
                            />
                            <div
                              htmlFor={item?.column_name}
                              className="ps-1 d-flex align-items-center fs-14 "
                            >
                              {item?.field_label}
                            </div>
                          </label>
                        )}
                      />

                      {/* <CustomCheckBox
                        id={item?.column_name}
                        // name={item?.field_label}
                        // checked={item?.checked}
                        // onChange={(e) => addressClick(e, i)}
                      />
                      <div
                        htmlFor={item?.column_name}
                        className="ps-1 d-flex align-items-center fs-14 "
                      >
                        {item?.field_label}
                      </div> */}
                    </Col>
                  )}
                </>
              );
            })}
            {/* <Col xl={3} lg={4} md={6} className="my-2 pe-lg-0">
            <BasicDatePicker label="Move-in date" />
          </Col> */}
          </Row>
        </div>
        <hr className="hr" />

        <div className="mx-4 pe-2 mb-4 pb-3 pt-1">
          <div className="form-label-title mb-2">Unit layout</div>
          <Row>
            {unitLayoutType0Data?.map((item) => {
              return (
                <Col
                  lg={3}
                  md={6}
                  className={
                    item?.suffix !== ""
                      ? "my-2 pe-lg-0"
                      : "my-2 pe-lg-2 pe-sm-4"
                  }
                  key={item?.field_id}
                >
                  <Controller
                    control={control}
                    {...register(`${item?.column_name}`)}
                    rules={{
                      required: item?.is_mandatory == 0 ? false : true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        {item?.suffix !== "" ? (
                          <GroupText
                            lable={item?.field_label}
                            type={item?.field_type}
                            name={item?.field_label}
                            id={item?.column_name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              // setInternetFee(e.target.value);
                              clearErrors(`${item?.column_name}`);
                            }}
                            graylabel={squareMeasure}
                            // squarelabel="2"
                          />
                        ) : item?.field_type == "Dropdown" ? (
                          <>
                            <ReactSelect
                              placeholder={item?.field_label}
                              value={value}
                              setPlaceholderPosition={true}
                              options={item?.possible_values.map((x) => ({
                                value: x?.Id,
                                label: x?.Text,
                              }))}
                              onChange={(e) => {
                                onChange(e);
                                setSquarMeasure(
                                  item?.possible_values?.filter(
                                    (el) =>
                                      el?.Id === watch("square_measure")?.value
                                  )?.[0]?.DataValue2
                                );
                              }}
                              clearable={false}
                            />
                            {errors.square_measure && (
                              <span className="error-msg">
                                This field is required
                              </span>
                            )}
                          </>
                        ) : (
                          <div className="form-input ">
                            <input
                              type={item?.field_type}
                              placeholder={item?.field_label}
                              id={item?.column_name}
                              name={item?.field_label}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors(`${item?.column_name}`);
                              }}
                              // onChange={(e) => handleUnitIdentificationChange(e, item)}
                            />
                            <label htmlFor={item?.column_name}>
                              {item?.field_label}
                            </label>
                          </div>
                        )}
                      </>
                    )}
                  />
                </Col>
              );
            })}

            {unitLayoutFilterData?.map((item) => {
              return (
                <Col
                  key={item?.field_id}
                  lg={3}
                  md={6}
                  className={
                    item?.suffix !== ""
                      ? "my-2 pe-lg-0"
                      : "my-2 pe-lg-2 pe-sm-4"
                  }
                >
                  <Controller
                    control={control}
                    {...register(`${item?.column_name}`)}
                    rules={{
                      required: item?.is_mandatory == 0 ? false : true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        {item?.suffix !== "" ? (
                          <GroupText
                            lable={item?.field_label}
                            type={item?.field_type}
                            name={item?.field_label}
                            id={item?.column_name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              // setInternetFee(e.target.value);
                              clearErrors(`${item?.column_name}`);
                            }}
                            graylabel={squareMeasure}
                            // squarelabel="2"
                          />
                        ) : (
                          <div className="form-input ">
                            <input
                              type={item?.field_type}
                              placeholder={item?.field_label}
                              id={item?.column_name}
                              name={item?.field_label}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors(`${item?.column_name}`);
                              }}
                              // onChange={(e) => handleUnitIdentificationChange(e, item)}
                            />
                            <label htmlFor={item?.column_name}>
                              {item?.field_label}
                            </label>
                          </div>
                        )}
                      </>
                    )}
                  />
                </Col>
              );
            })}
          </Row>
        </div>

        <div className="py-2 px-4 mt-2 d-sm-flex justify-content-between footer-section-button-container ">
          <div className="my-1">
            <NavLink
              to="/property-units/units/residential"
              state={{ propId: propId, Tab: "Units" }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <ETButton
                variant={"white"}
                buttonText="BACK TO UNITS"
              />
            </NavLink>
          </div>
          <div className="m-1">
            <ETButton
              disabled={PutEditUnitDetailsIsLoading}
              variant={"primary"}
              buttonText="SAVE"
              type="submit"
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default UnitDetails;
