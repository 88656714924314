import React, { useRef, useState, useMemo, useEffect } from "react";

import Menus from "../../../Components/Menus/Menus";
import { Col, Row } from "react-bootstrap";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { MaterialReactTable } from "material-react-table";
import CSVWidget from "../../../Components/DownloadWidget/CSVWidget";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import MRTTheme from "../../../Components/table/MRTTheme";
import { usePropertyId } from "../../../hooks/stores/usePropertyStore";
import { useGetEconomyAvailabilityByRoom } from "../../../hooks/queries/useControlCenterQueries";
import { useControlCenter } from "../../../hooks/stores/useControlCenterStore";
import { NoRecordsToDisplay } from "../../../utils/validationConstants";
import { H2, H3 } from "../../../styles/Common.style";
import { SortIcon } from "../../../Components/table/MRTGlobalProps";

const AvailibilityByRooms = ({
  startDateFormate,
  endDateFormate,
  allProperties,
}) => {
  const transactionByRoomsColumns = useMemo(
    () => [
      {
        accessorKey: "no_of_rooms",
        header: "Number of rooms",
        size: 120,
      },
      {
        accessorKey: "quantity",
        header: "Quantity",
        size: 50,
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "percentage",
        header: "Percentage",
        size: 50,
        Cell: ({ row }) => (
          <>
            <span className="me-1">
              <div>
                {row.original.percentage ? (
                  <>{row.original.percentage} %</>
                ) : (
                  ""
                )}
              </div>
            </span>
          </>
        ),
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "YearlyRent",
        header: "Total annual rent",
        size: 50,
        Cell: ({ row }) => (
          <>
            <span className="me-1">
              {row.original.YearlyRent} {row.original.currency_id}
            </span>
          </>
        ),
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );
  const {
    selectedProperties,
    selectedDate,
    selectedEndDate,
    selectedInitialDate,
    selectedInitialEndEndDate,
  } = useControlCenter((state) => state);

  const { data: economyAvailabilityByRoomData } =
    useGetEconomyAvailabilityByRoom(
      selectedProperties,
      selectedDate,
      selectedEndDate,
      selectedInitialDate,
      selectedInitialEndEndDate
    );
  const [roomsData, setRoomsData] = useState([]);
  useEffect(() => {
    setRoomsData(economyAvailabilityByRoomData?.data);
  }, [economyAvailabilityByRoomData]);

  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "availibility-rooms";

  const options = {
    keys: [
      { field: "Numberofrooms", title: "No of rooms" },
      { field: "Quantity" },
      { field: "Percentage", title: "Percentage" },
      { field: "TotalAnnualRent", title: "Annual Rent" },
    ],
  };

  const data = roomsData;

  const CSVFunction = CSVWidget({ refObj, fileName, options, data });

  return (
    <div className="card-body p-3" ref={myref}>
      <Row>
        <Col className="d-flex align-items-center" sm={9}>
          {" "}
          <div className="d-flex" style={{ alignItems: "center" }}>
            <H3 className=" me-2">Availability divided by rooms</H3>{" "}
            <CustomToolTip
              size="sm"
              text="Availability divided by rooms
              "
            />
          </div>
        </Col>
        <Col sm={3} className="d-flex justify-content-end">
          {" "}
          <Menus
            itemheight={40}
            itemwidth={40}
            itemcontainerheight={200}
            itemcontainerwidth={200}
            component={
              <div className="fs-14">
                <div className="addpropContent px-3 py-2 ">
                  <span className="ms-1" onClick={() => CSVFunction()}>
                    Download widget as CSV
                  </span>
                </div>
              </div>
            }
          />
        </Col>
      </Row>
      {roomsData?.length != 0 ? (
        <Row className="mt-3">
          <Col>
            <MRTTheme
              colorVariantsForTableData={["odd"]}
              table={
                <MaterialReactTable
                  columns={transactionByRoomsColumns}
                  data={roomsData ?? []}
                  icons={{ ...SortIcon }}
                  localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                  enableBottomToolbar={false}
                  enableSorting={false}
                  enableColumnActions={false}
                  enablePagination={false}
                  renderTopToolbar={false}
                  muiTableContainerProps={{
                    sx: {
                      padding: "0px",
                    },
                  }}
                  // muiTablePaperProps={{
                  //   elevation: 0,
                  //   sx: {
                  //     fontFamily: "Cerebri sans !important",
                  //     borderRadius: "0px 0px 8px 8px ",
                  //     border: "none",
                  //     background: "#F9FAFA",
                  //     fontWeight: "300",
                  //     ".css-1f2qhs8": {
                  //       minWidth: "auto",
                  //       marginRight: "10px",
                  //     },
                  //     th: {
                  //       height: "50px",
                  //       padding: "8px 0px 8px 16px",
                  //       background: "#FFFFFF",
                  //       paddingLeft: "1rem",
                  //       fontFamily: "Cerebri sans !important",
                  //     },
                  //     td: {
                  //       height: "50px",
                  //       padding: "0.5rem",
                  //       fontWeight: "300",
                  //       fontSize: "13px",
                  //       paddingLeft: "1rem",
                  //       paddingRight: "1rem",
                  //       fontFamily: "Cerebri sans !important",
                  //     },
                  //     tr: {
                  //       borderRadius: "4px",
                  //       border: "2px solid red !important",
                  //       fontFamily: "Cerebri sans !important",
                  //     },
                  //     ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
                  //       display: "none", // pagination space removing from page
                  //     },
                  //   },
                  // }}
                  // muiTableBodyProps={{
                  //   sx: {
                  //     fontFamily: "Cerebri sans !important",
                  //     "& tr:nth-of-type(odd)": {
                  //       backgroundColor: "#F9FAFA",
                  //       borderRadius: "8px",
                  //       fontWeight: "300",
                  //     },
                  //   },
                  // }}
                  // muiTableContainerProps={{
                  //   elevation: 0,
                  //   sx: {
                  //     background: "#FFFFFF",
                  //     borderRadius: "0px 0px 8px 8px ",

                  //     boxShadow: "none",
                  //     ".css-vv34gr-MuiTableRow-root": {
                  //       boxShadow: "unset !important",
                  //     },
                  //   },
                  // }}
                  // muiTableHeadCellProps={{
                  //   //simple styling with the `sx` prop, works just like a style prop in this example
                  //   sx: {
                  //     fontWeight: "700",
                  //     fontSize: "13px",
                  //     fontFamily: "Cerebri sans !important",
                  //   },
                  // }}
                />
              }
            />
          </Col>
        </Row>
      ) : (
        <NoDataScreen height={"377px"} imgHeight={"150px"} />
      )}
    </div>
  );
};

export default AvailibilityByRooms;
