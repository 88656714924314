import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ErrorBoundary({ children }) {
	const [hasError, setHasError] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const handleError = () => {
			setHasError(true);
		};

		const handleUnhandledPromiseRejection = (event) => {
			event.preventDefault(); // Prevent the default unhandled promise rejection behavior
			setHasError(true);
		};

		// Add event listeners to catch uncaught errors globally
		window.onerror = handleError;
		window.addEventListener(
			"unhandledrejection",
			handleUnhandledPromiseRejection
		);

		return () => {
			// Remove event listeners when the component unmounts
			window.onerror = null;
			window.removeEventListener(
				"unhandledrejection",
				handleUnhandledPromiseRejection
			);
		};
	}, []);

	if (hasError) {
		// Handle the error here or navigate to an error page
		navigate("/error");
		return null; // Render nothing or an error message
	}

	return children;
}

export default ErrorBoundary;
