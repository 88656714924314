import React, { useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import CustomPopup from "../../../../../../Components/CustomPopup";
import ETButton from "../../../../../../Components/Buttons/ETButton";
import { H4, H5 } from "../../../../../../styles/Common.style";
import CustomToolTip from "../../../../../../Components/Tooltip/CustomTooltip";
import ReactSelect from "../../../../../../Components/DropDown/ReactSelect";
import LanguageSelectSearch from "../../../../../../Components/FilterDropdownSelection/LanguageSelectSearch";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SuperEditor from "@ckeditor/ckeditor5-build-classic";
import FilterComponent from "../../../../../../reactdataTableComponent/FilterComponent";

export const AddOperationManagerPopup = ({
  open,
  onHide,
  onCancel,
  onStart,
}) => {
  const [emailTemplateBody, setEmailTemplateBody] = useState("");

  //------------Constants-----------//
  const navigate = useNavigate();
  const { state } = useLocation();
  const isViewTemplate = state?.flag == "View" ? true : false;
  const editorRef = useRef();

  const editorConfiguration = {
    placeholder: "Dear Customer", // Set the placeholder text
    toolbar: {
      items: [
        "heading",
        "placeholder",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "indent",
        "outdent",
        "blockQuote",
        "undo",
        "redo",
        "image",
        "codeBlock",
        // "imageUpload",
        // "insertInlineWidget",
      ],
    },
    language: "en",
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
      ],
    },
    // Add more options and configurations here
  };

  return (
    <div>
      <CustomPopup
        open={open}
        onHide={onHide}
        title={"Contact message"}
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        size={"lg"}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        titleTooltipText={"Contact message"}
        secondTextShow={false}
        priorityShow={false}
      >
        <div className="light-font-text">
          <div className="px-4 py-3">
            <Row className="pb-2">
              <H5 className="pb-1">Contact message about operation manager</H5>
              <span className="light-font-text">
                If you need to display a message to your customers on how to
                contact an operation manager, this message can be entered below.
                The message will be promoted in predefined placeholders on the
                website, Ei.DO, MyPage and through APIs.
              </span>
            </Row>

            <Row className="pb-2">
              <Col lg={6}>
                <div className="d-flex align-items-center pb-2 gap-2">
                  <H5>Headline</H5>
                  <CustomToolTip text="Headline" />
                </div>
                <ReactSelect placeholder="Headline" />
              </Col>
              <Col></Col>
              <Col
                className="d-flex align-items-end"
                lg={4}
              >
                <div className="w-100">
                  <LanguageSelectSearch placeholder="Choose language" />
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={8}>
                <div className="d-flex form-label-title align-items-end gap-2 mb-2">
                  <H4> Message </H4>
                  <CustomToolTip text="Message" />
                </div>
                <div>
                  <div className="">
                    <CKEditor
                      editor={SuperEditor}
                      focusedElement={true}
                      onReady={(editor) => {
                        editor.editing.view.focus();
                        editorRef.current = editor;
                      }}
                      disabled={isViewTemplate ? true : false}
                      config={editorConfiguration} // Set the custom configuration here
                      data={emailTemplateBody || ""}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        // console.log(editor, "ck editor console");
                        setEmailTemplateBody(data);
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="d-flex form-label-title align-items-end gap-2 mb-2">
                  <H4> Insert merge field </H4>
                  <CustomToolTip text="Insert merge field" />
                </div>
                {/* search placeholder */}
                <div>
                  <FilterComponent placeholder={"Search for a tag…"} />
                </div>

                {/* Recently merge fields */}
                <div>
                  <div className="d-flex form-label-title align-items-end gap-2 my-2">
                    <H5>Recently merge fields</H5>
                    <CustomToolTip text="Recently merge fields" />
                  </div>
                  <div>
                    {GetEmailTemplateMergeFieldData?.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="pt-1"
                        >
                          <ETButton
                            // disabled={isViewTemplate}
                            buttonText={item.name}
                            icon={true}
                            variant={"secondary"}
                            // onClick={() =>
                            //   handleClickMergeFieldsButton(item?.value)
                            // }
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            buttonText="Cancel"
            variant={"white"}
            onClick={onCancel}
          />
          <div className="d-flex ">
            <div>
              <ETButton
                buttonText={"START"}
                variant={"primary"}
                onClick={onStart}
              />
            </div>
          </div>
        </div>
      </CustomPopup>
    </div>
  );
};

export default AddOperationManagerPopup;

const GetEmailTemplateMergeFieldData = [
  { id: 1, name: "abc" },
  { id: 2, name: "xyz" },
  { id: 3, name: "ppq" },
  { id: 4, name: "add" },
  { id: 5, name: "asd" },
];

// Created by --------------> Yash
