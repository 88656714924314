import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { H2, H3 } from "../../../styles/Common.style";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../../Components/Tabs/ReactTabs.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ETButton from "../../../Components/Buttons/ETButton";
import Planned from "./opportunitiesProcessTabs/Planned";
import Attended from "./opportunitiesProcessTabs/Attended";
import Proposed from "./opportunitiesProcessTabs/Proposed";
import Declined from "./opportunitiesProcessTabs/Declined";
import Inquiries from "./opportunitiesProcessTabs/Inquiries";
import Confirmed from "./opportunitiesProcessTabs/Confirmed";
import Interested from "./opportunitiesProcessTabs/Interested";
import NotInterested from "./opportunitiesProcessTabs/NotInterested";
import AttachedDocuments from "./CreateNewAppointments/AttachedDocuments";
import CreateNewAppointment from "./CreateNewAppointments/CreateNewAppointments";
import ExistingCustomer from "./ExistingCustomer";
import Menus from "../../../Components/Menus/Menus";
import {
  useGetOpportunityDetailsByDivision,
  useGetOpportunityOverviewByDivision,
} from "../../../hooks/queries/useCustomerFunnel";
import { useUserDetails } from "../../../hooks/stores/useUserDetailsStore";

const Opportunities = () => {
  //===================== Zustand ======================//
  const { userDetails } = useUserDetails((state) => state);

  //===================== States ======================//
  const [opportunitiesInProcessTabs, setOpportunitiesInProcessTabs] =
    useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [createNewAppointment, setCreateNewAppointment] = useState(false);

  const [progress, setProgress] = useState("");

  //===================== React query ======================//
  //Get opportunities by division
  const { data: GetOpportunityOverviewByDivisionData } =
    useGetOpportunityOverviewByDivision(userDetails?.division_id);

  //Get oppoutunities details by division
  const { data: GetOpportunityDetailsByDivisionData } =
    useGetOpportunityDetailsByDivision(userDetails?.division_id);

  const OpportunitiesProcessTabs = [
    {
      Id: "1",
      tab: "Inquiries",
      lead_lifecycle_sub_status_id: "1",
      component: <Inquiries />,
      Notification:
        GetOpportunityDetailsByDivisionData?.data?.filter(
          (x) => x?.lead_lifecycle_sub_status_id?.toString() === "1"
        )?.length || "0",
    },
    {
      Id: "2",
      tab: "Proposed",
      lead_lifecycle_sub_status_id: "2",
      component: <Proposed />,
      Notification:
        GetOpportunityDetailsByDivisionData?.data?.filter(
          (x) => x?.lead_lifecycle_sub_status_id?.toString() === "2"
        )?.length || "0",
    },
    {
      Id: "3",
      tab: "Declined",
      lead_lifecycle_sub_status_id: "3",
      component: <Declined />,
      Notification:
        GetOpportunityDetailsByDivisionData?.data?.filter(
          (x) => x?.lead_lifecycle_sub_status_id?.toString() === "3"
        )?.length || "0",
    },
    {
      Id: "4",
      tab: "Planned",
      lead_lifecycle_sub_status_id: "4",
      component: <Planned />,
      Notification:
        GetOpportunityDetailsByDivisionData?.data?.filter(
          (x) => x?.lead_lifecycle_sub_status_id?.toString() === "4"
        )?.length || "0",
    },
    {
      Id: "5",
      tab: "Confirmed",
      lead_lifecycle_sub_status_id: "5",
      component: <Confirmed />,
      Notification:
        GetOpportunityDetailsByDivisionData?.data?.filter(
          (x) => x?.lead_lifecycle_sub_status_id?.toString() === "5"
        )?.length || "0",
    },
    {
      Id: "6",
      tab: "Attended",
      lead_lifecycle_sub_status_id: "6",
      component: <Attended />,
      Notification:
        GetOpportunityDetailsByDivisionData?.data?.filter(
          (x) => x?.lead_lifecycle_sub_status_id?.toString() === "6"
        )?.length || "0",
    },
    {
      Id: "7",
      tab: "Interested",
      lead_lifecycle_sub_status_id: "7",
      component: <Interested />,
      Notification:
        GetOpportunityDetailsByDivisionData?.data?.filter(
          (x) => x?.lead_lifecycle_sub_status_id?.toString() === "7"
        )?.length || "0",
    },
    {
      Id: "8",
      tab: "Not interested",
      lead_lifecycle_sub_status_id: "8",
      component: <NotInterested />,
      Notification:
        GetOpportunityDetailsByDivisionData?.data?.filter(
          (x) => x?.lead_lifecycle_sub_status_id?.toString() === "8"
        )?.length || "0",
    },
  ];

  return (
    <div className="p-4 containerStyle">
      <div className="bg-white radius-8 fs-14 mainborder mb-4">
        <div
          className="px-4 py-3 "
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div className=" d-flex align-items-center">
            <H2>Overview</H2>
            <div className="ps-2">
              {" "}
              <CustomToolTip text="Overview" />{" "}
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="p-4 bgcolor-d-white">
            <div>
              <Row>
                {GetOpportunityOverviewByDivisionData?.data?.map(
                  (item, index) => (
                    <Col key={index} lg={4} sm={6} className="pe-2 mb-3">
                      <div
                        className="h-100 gap-4 ps-4 py-4 pe-2 d-flex align-items-center   radius-8  lite-grey-border"
                        style={{ backgroundColor: "#fff" }}
                      >
                        <div className="w-25">
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                              height: "48px",
                              width: "48px",
                              backgroundColor:
                                item?.icon_bg_color == ""
                                  ? "#e5e7e8"
                                  : `${item?.icon_bg_color}33`,
                              borderRadius: "100px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={
                                item?.icon_name == ""
                                  ? "fa-solid fa-circle-question"
                                  : item?.icon_name
                              }
                              size="xl"
                              style={{
                                color:
                                  item?.icon_color == ""
                                    ? "rgb(152 157 159)"
                                    : item?.icon_color,
                              }}
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          <div className="actors-section-title fs-24">
                            {item?.total_count} {item?.status_name}
                          </div>
                          <div className="font-light fs-18">
                            {item?.status_description}
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
                )}
              </Row>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white radius-8 fs-14 mainborder">
        <div
          className="px-4 py-3 d-flex justify-content-between align-items-center"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div>
            <div className=" d-flex align-items-center  mb-2 ">
              <H3>Opportunities in process</H3>
              <div className="ps-2">
                {" "}
                <CustomToolTip text="Opportunities in process" />{" "}
              </div>
            </div>

            <div className="caption-light">
              <span className="grey-text">
                Last updated by Jesper Thorkildsen, 19. Jan. 2023 | 16:21:03
              </span>
            </div>
          </div>

          <ETButton
            variant={"primary"}
            buttonText={"CREATE NEW APPOINTMENT"}
            onClick={() => {
              setCreateNewAppointment(true);
              setProgress(25);
            }}
          />
        </div>
        <Tabs
          selectedIndex={selectedIndex}
          className="mb-3 pb-1 d-flex flex-column"
          value={opportunitiesInProcessTabs}
        >
          <div
            className="d-flex ps-4 pe-4 py-3 align-items-center justify-content-between"
            style={{
              width: "100%",
              flexDirection: "row",
              flexWrap: "nowrap",
              borderBottom: "1px solid #F0F0F0",
            }}
          >
            <TabList className="light ps-0 d-flex align-items-center pe-2 ">
              {OpportunitiesProcessTabs?.map((page, index) => (
                <>
                  <Tab
                    key={index}
                    style={{ position: "relative" }}
                    onClick={() => {
                      setOpportunitiesInProcessTabs(page?.tab);
                      setSelectedIndex(index);
                    }}
                  >
                    <span className="me-1" style={{ position: "relative" }}>
                      {page?.tab}
                    </span>
                    (<span>{page?.Notification}</span>)
                  </Tab>
                </>
              ))}
            </TabList>
            <div className="ps-3">
              <Menus
                burgerIcon={true}
                itemcontainerwidth="210px"
                itemcontainerheight="350px"
                iconborderradius="8px"
                itemwidth={44}
                itemheight={28}
                component={
                  <>
                    <div className="light-font-text ">
                      <div
                        className="addpropContent px-3 py-2 "
                        onClick={() => setSelectedIndex(0)}
                      >
                        <FontAwesomeIcon
                          icon="fa-regular fa-calendar-star"
                          size="lg"
                          className="ms-1"
                          style={{ color: "#000000" }}
                        />
                        <span className="ms-1 ps-1">Inquiries</span>
                      </div>
                      <hr className=" m-0"></hr>
                      <div
                        className="addpropContent px-3 py-2 "
                        onClick={() => setSelectedIndex(1)}
                      >
                        <FontAwesomeIcon
                          icon="fa-regular fa-hourglass-start"
                          size="lg"
                          className="ms-1 "
                          style={{ color: "#000000" }}
                        />
                        <span className="ms-1 ps-1">Proposed</span>
                      </div>
                      <hr className="m-0"></hr>

                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => setSelectedIndex(2)}
                      >
                        <FontAwesomeIcon
                          icon="fa-regular fa-octagon-exclamation"
                          size="lg"
                          className="ms-1"
                          style={{ color: "#030303" }}
                        />
                        <span className="ms-1 ps-1">Declined</span>
                      </div>
                      <hr className="m-0"></hr>
                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => setSelectedIndex(3)}
                      >
                        <FontAwesomeIcon
                          icon="fa-regular fa-calendar-circle-user"
                          size="lg"
                          className="ms-1"
                          style={{ color: "#050505" }}
                        />
                        <span className="ms-1 ps-1">Planned</span>
                      </div>
                      <hr className="m-0"></hr>
                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => setSelectedIndex(4)}
                      >
                        <FontAwesomeIcon
                          icon="fa-solid fa-check"
                          size="lg"
                          className="ms-1"
                          style={{ color: "#050505" }}
                        />
                        <span className="ms-1 ps-1">Confirmed</span>
                      </div>
                      <hr className="m-0"></hr>
                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => setSelectedIndex(5)}
                      >
                        <FontAwesomeIcon
                          icon="fa-regular fa-house-circle-check"
                          size="lg"
                          className="ms-1"
                          style={{ color: "#050505" }}
                        />
                        <span className="ms-1 ps-1">Attended</span>
                      </div>
                      <hr className="m-0"></hr>

                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => setSelectedIndex(6)}
                      >
                        <FontAwesomeIcon
                          icon="fa-regular fa-arrow-right"
                          style={{ color: "#000000" }}
                          size="lg"
                          className="ms-1"
                        />
                        <span className="ms-1 ps-1">Interested</span>
                      </div>
                      <hr className="m-0"></hr>
                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => setSelectedIndex(7)}
                      >
                        <FontAwesomeIcon
                          icon="fa-regular fa-thumbs-down"
                          size="lg"
                          className="ms-1"
                          style={{ color: "#000000" }}
                        />
                        <span className="ms-1 ps-1">Not Interested</span>
                      </div>
                    </div>
                  </>
                }
              />
            </div>
          </div>
          <div className="bg-white" style={{ borderRadius: " 10px" }}>
            {OpportunitiesProcessTabs.map((page, index) => (
              <TabPanel key={page?.Id}>{page?.component}</TabPanel>
            ))}
          </div>
        </Tabs>
      </div>

      <CreateNewAppointment
        progress={progress}
        setProgress={setProgress}
        openNewAppointmentScreen={createNewAppointment}
        setOpenNewAppointmentScreen={setCreateNewAppointment}
      />
    </div>
  );
};

export default Opportunities;
