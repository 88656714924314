import { useMutation, useQuery } from "react-query";
import {
  GetTagByUnitId,
  GetTagsByPropertyId,
  PostTags,
  PutTagSetActive,
  PutTagUpdateTag,
} from "../../services/Tags";

//Get Tags by propertyID
export const useTagsByPropertyId = (propertyId, editflag) => {
  return useQuery(
    ["getTagsByPropertyId", propertyId],
    () => GetTagsByPropertyId(propertyId),
    { enabled: !!propertyId }
  );
};
//Get Tags by Unit Id
export const useGetTagByUnitId = (propertyId, unitId) => {
  return useQuery(
    "useGetTagByUnitId",
    () => GetTagByUnitId(propertyId, unitId),
    { enabled: !!propertyId && !!unitId }
  );
};

// set active tags
export const usePutTagsActive = () => {
  return useMutation(PutTagSetActive);
};

export const usePutTagUpdateTag = () => {
  return useMutation(
    PutTagUpdateTag

  );
};

export const useAddTag = () => {
  return useMutation(PostTags);
};