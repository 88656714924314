import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import ETButton from "../../../Components/Buttons/ETButton";
import Toggle from "../../../Components/Switch/Switch";
import StarRating from "../../../Components/rating/StarRating";

const FollowUp = ({ footer }) => {
  const [favorite, setFavorite] = useState(null);

  const localizer = momentLocalizer(moment);
  const calendarConfig = {
    components: {
      // event: CustomEvent,
      toolbar: CustomToolbar,
      // eventWrapper: CustomEventWrapper,
    },
  };
  // console.log(favorite, "favorite");
  return (
    <div>
      {" "}
      <div className="pt-2 pb-3 px-4">
        <div className="   form-label-title">Rating</div>
        <div className="light-font-text grey-text">
          How interested where the participant(s)?
        </div>
        <div>
          <StarRating
            setFavoriteRating={setFavorite}
            favoriteRating={favorite}
          />
        </div>
      </div>
      <hr className="my-2"></hr>
      <div className="pt-2 pb-4 px-4">
        <div className="   form-label-title">Follow up date</div>
        <div className="light-font-text grey-text">
          Let us help you remember to follow-up on this participant. The
          follow-up date will create a notification and will be notified in My
          Activities.
        </div>
        <div className="pt-3">
          <Row>
            <Col lg={3}>
              <div className="form-input ">
                <input
                  type="date"
                  placeholder="Follow up date"
                  id="Followupdate"
                  // value={value}
                  // onChange={(e) => {
                  // 	onChange(e);
                  // 	clearErrors("long");
                  // }}
                />
                <label htmlFor="Followupdate">Follow up date</label>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <hr className="my-0"></hr>
      <div className="pt-3 pb-4 px-4">
        <div className="   form-label-title">Follow-up viewing</div>
        <div className="light-font-text grey-text">
          If you already know that the participant want a follow up private
          viewing. You can book it here.
        </div>
        <div className="pt-3">
          <Row>
            <Col lg={6}>
              <Row>
                <Col className="pb-2 pe-lg-0">
                  <div className="form-input ">
                    <input
                      type="date"
                      placeholder="Date"
                      id="Date"
                      // value={value}
                      // onChange={(e) => {
                      // 	onChange(e);
                      // 	clearErrors("long");
                      // }}
                    />
                    <label htmlFor="Date">Date</label>
                  </div>
                </Col>
                <Col className="pb-2 pe-lg-0">
                  <ReactSelect placeholder={"Starting time"} />
                </Col>
                <Col className="pb-2 pe-lg-0">
                  <ReactSelect placeholder={"End time"} />
                </Col>
              </Row>
              <Row>
                <Col className="py-3 pe-lg-0">
                  <ReactSelect
                    placeholder={
                      "Responsible agent to handle the personal viewing"
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={10} className="pe-lg-0 mb-sm-3 mb-lg-0">
                  <div className="light-font-text d-flex align-items-center">
                    {" "}
                    Send booking confirmation to the participant{" "}
                    <Toggle id={"Id"} />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={{ span: 4, offset: 2 }}>
              <div
                className="p-2 radius-8"
                style={{ border: "1px solid #D1D1D1" }}
              >
                <Calendar
                  localizer={localizer}
                  // events={myEventsList}
                  toolbar={(toolbarProps) => (
                    <CustomToolbar {...toolbarProps} />
                  )}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 341 }}
                  components={calendarConfig.components}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <hr className="my-0"></hr>
      <div className="py-3 px-4">
        <div className="   form-label-title">Internal note</div>
        <div className="light-font-text grey-text">
          Add a note so it’s easier to follow up
        </div>

        <div className=" py-4">
          <div className="deposit-text-area">
            <div className="pe-2 pt-2">
              <div
                style={{ height: "219px ", overflowY: "scroll" }}
                className="scrollable-container-y "
              >
                {/* {getNotesDetailsData?.data?.filter(
                  (el) => el?.category_id === 2
                )?.length > 0 ? (
                  getNotesDetailsData?.data
                    ?.filter((el) => el?.category_id === 2)
                    ?.map((item, index) => ( */}
                <div className="card-div px-3 py-2 ms-3  mb-2">
                  <div className="d-flex justify-content-between">
                    <div
                      style={{
                        // backgroundColor: item?.bg_color || "#9370DB",
                        backgroundColor: "#9370DB",
                        color: "white",
                        borderRadius: "4px",
                        fontSize: "9px",
                        alignItems: "center",
                        textTransform: "uppercase",
                      }}
                      className="mt-1 px-2 font-semibold  d-flex"
                    >
                      {/* {item?.category_name} */}
                      EVENT
                    </div>
                    <div className="fs-10">
                      {/* {moment(item?.created_on)?.format(
                              "DD MMM, YYYY [at] hh:mm"
                            )} */}
                      18 Mar, 2023 at 09:10
                    </div>
                  </div>

                  <div className="mt-2 font-semibold fs-14">
                    {/* {item?.subject}
                     */}
                    Subject line
                  </div>

                  <div className="mt-1 light-font-text">
                    {/* {item?.message} */}
                    Ut aut reiciendis voluptatibus maiores alias consequatur aut
                    perferendis doloribus asperiores repellat. Ut aut reiciendis
                    voluptatibus maiores alias reiciendis voluptatibus maiores
                    alias consequatur aut.
                  </div>
                  <div className="mt-2 d-flex justify-content-between">
                    <div className="light-font-text fs-12">
                      by Max Wildersans Brentford
                      {/* {item?.created_by} */}
                    </div>
                    <div className="cursor-pointer">
                      <FontAwesomeIcon
                        icon="fa-regular fa-arrow-up-right-from-square"
                        style={{ color: "#000000" }}
                      />
                    </div>
                  </div>
                </div>
                {/* ))
                ) : (
                  <div
                    className="d-flex justify-content-center w-100 fs-12 mt-2"
                    style={{ color: "#888888" }}
                  >
                    No notes found
                  </div>
                )} */}
              </div>
            </div>
            <div className="text-area p-3">
              <InputGroup className="deposit-textfield">
                <Form.Control
                  placeholder="Subject line"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  // value={subjectLine}
                  // onChange={(e) => setSubjectLine(e.target.value)}
                />
              </InputGroup>

              <InputGroup className="deposit-textarea">
                <Form.Control
                  as="textarea"
                  placeholder="Messages"
                  aria-label="Large"
                  aria-describedby="inputGroup-sizing-sm"
                  // value={message}
                  // onChange={(e) => setMessage(e.target.value)}
                />
                <InputGroup.Text
                  className="deposit-textarea-icon"
                  id="inputGroup-sizing-lg"
                >
                  <button
                    type="button"
                    // disabled={
                    //   subjectLine?.length == 0 || (message?.length == 0 && true)
                    // }
                    // onClick={() => handleSendDepositNote()}
                    className="message-send-button"
                    // style={{
                    //   opacity:
                    //     subjectLine?.length == 0 || message?.length == 0
                    //       ? "50%"
                    //       : "inherit",
                    // }}
                  >
                    {
                      <FontAwesomeIcon
                        size="xs"
                        icon="fa-regular fa-paper-plane-top"
                        style={{ color: "#FFFFFF" }}
                      />
                    }
                  </button>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </div>
      </div>
      {footer === false ? (
        ""
      ) : (
        <div className="mt-1 py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          {" "}
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            // type={"button"}
            // onClick={() => setCancelPop(true)}
          />
          <div className=" d-flex ">
            {/* {hideBtn == false && ( */}
            <div className="px-2">
              {" "}
              <ETButton
                buttonText="DELETE PARTICIPANT"
                variant={"white"}
                icon={true}
                width="100%"
                type={"submit"}
              />
            </div>
            {/* )} */}

            <div className="px-1">
              {" "}
              <ETButton
                variant={"primary"}
                buttonText="CONFIRM"
                type={"submit"}
                // onClick={() => setHideBtn(true)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FollowUp;

const CustomToolbar = ({ label, date, onNavigate }) => {
  const handleNext = () => {
    // Custom logic for the next button
    // For example, you can navigate to the next month
    onNavigate("next", moment(date).add(1, "month"));
  };

  const handlePrev = () => {
    // Custom logic for the back button
    // For example, you can navigate to the previous month
    onNavigate("prev", moment(date).subtract(1, "month"));
  };

  return (
    <div className="rbc-toolbar ">
      <button onClick={handlePrev}>
        <FontAwesomeIcon
          icon="fa-regular fa-angle-left"
          style={{ color: "#000000" }}
        />
      </button>
      <span className="rbc-toolbar-label ">{label}</span>
      <button onClick={handleNext}>
        <FontAwesomeIcon
          icon="fa-regular fa-angle-right"
          style={{ color: "#000000" }}
        />
      </button>
    </div>
  );
};
