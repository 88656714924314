import React, { useMemo, useCallback, useState, useRef } from "react";
import PNGWidget from "../../../Components/DownloadWidget/PNGWidget";
import JPGWidget from "../../../Components/DownloadWidget/JPGWidget";
import SVGWidget from "../../../Components/DownloadWidget/SVGWidget";
import { Col, Row } from "react-bootstrap";
import Menus from "../../../Components/Menus/Menus";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { Bar } from "react-chartjs-2";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import { H2, H3 } from "../../../styles/Common.style";

const EventHistory = () => {
  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "event-history";

  const PNGFunction = PNGWidget({ refObj, fileName });
  const JPGFunction = JPGWidget({ refObj, fileName });
  const SVGFunction = SVGWidget({ refObj, fileName });

  const data = {
    labels: [
      "Sep 2, 2022",
      "Sep 3, 2022",
      "Sep 4, 2022",
      "Sep 5, 2022",
      "Sep 6, 2022",
      "Sep 7, 2022",
      "Sep 8, 2022",
    ],
    datasets: [
      {
        backgroundColor: "#619130",
        data: [60, 70, 80, 300, 100],

        stack: 1,
        maxBarThickness: 50,
        barThickness: 50,
        label: "Participated",
      },
      {
        backgroundColor: "#F6E651",
        data: [200, 300, 400, 50, 60],
        stack: 1,
        maxBarThickness: 50,
        barThickness: 50,
        barPercentage: 0.5,
        label: "Reserved",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,

    plugins: {
      tooltip: {
        backgroundColor: "#FFFFFF",
        borderWidth: 2,
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
      },
      legend: {
        display: true,
        position: "bottom",
        align: "center",

        labels: {
          boxWidth: 8,
          boxHeight: 8,
          padding: 20,
          font: { family: "Cerebri Sans-medium", size: "12px" },
        },
      },
    },
    scales: {
      y: {
        grid: {
          borderDash: [5, 10],
          color: "#F4F5F5",
        },
        stacked: false,
        ticks: {
          stepSize: 5,
          color: "#888888",
          font: {
            family: "Cerebri sans-light",
          },
        },
      },

      x: {
        grid: {
          borderDash: [5, 10],
          color: "#FFFF",
        },

        ticks: {
          minRotation: 20,
          color: "#888888",
          font: {
            family: "Cerebri sans-light",
            size: 11,
          },
        },
      },
    },
  };

  return (
    <div ref={myref} className="card-body p-3">
      {" "}
      <Row>
        <Col className="d-flex align-items-center" sm={10}>
          {" "}
          <div className="d-flex align-items-center">
            <H3 className=" me-2">Event history</H3>{" "}
            <CustomToolTip size="sm" text="Event history" />
          </div>
        </Col>
        <Col sm={2} className="d-flex justify-content-end">
          {" "}
          <Menus
            itemheight={40}
            itemwidth={40}
            itemcontainerheight={200}
            itemcontainerwidth={200}
            component={
              <div className="fs-14">
                <div className="addpropContent px-3 py-2 ">
                  <span className="ms-1" onClick={() => PNGFunction()}>
                    Download widget as PNG
                  </span>
                </div>
                <hr className="addpropertyHr m-0"></hr>
                <div
                  onClick={() => JPGFunction()}
                  className="addpropContent px-3 pt-2 pb-2 "
                >
                  <span className="ms-1">Download widget as JPG</span>
                </div>
                <hr className="addpropertyHr m-0"></hr>
                <div
                  onClick={() => SVGFunction()}
                  className="addpropContent px-3 pt-2 pb-2 "
                >
                  <span className="ms-1">Download widget as SVG</span>
                </div>
              </div>
            }
          />
        </Col>
      </Row>
      {data.length == 0 ? (
        <Row>
          <Col>
            <div style={{ height: "332px", width: "100%" }}>
              <Bar data={data} options={options} />
            </div>
          </Col>
        </Row>
      ) : (
        <NoDataScreen height={"377px"} imgHeight={"150px"} />
      )}
    </div>
  );
};

export default EventHistory;
