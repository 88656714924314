import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import GroupButtons from "../../../../Components/GroupButton/GroupButton";
import { H4, H5 } from "../../../../styles/Common.style";
import InputField from "../../../../Components/Textfield/InputField";
import MRTTheme from "../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TagToolTip from "../../../../Components/Tooltip/PropertyTagToolTip";
import {
  SortIcon,
  paginationoptions,
} from "../../../../Components/table/MRTGlobalProps";
import { appointmentData } from "../../../overview/StaticData";
import ETButton from "../../../../Components/Buttons/ETButton";
import Toggle from "../../../../Components/Switch/Switch";

// MCTAU = Move customer to another unit
const CreatingAppointment = ({
  // setCreateAppoinmentWizard,
  // setOpenNewAppointmentScreen,
  flag,
  setAddExtraCustomer = () => {},
  handleNext = () => {},
  handleCancel = () => {},
  switchForCreateNewAppointment,
  setSwitchForCreateNewAppointment = () => {},
  switchForCancelCurrentAppointment,
  setSwitchForCancelCurrentAppointment = () => {},
  GetResponsibleAgentData,
  customerDetailsData,
  customerInformationData,
}) => {
  // -----------------states ---------------------------------//
  const [exestingCustomer, setExestingCustomer] = useState(0);
  // -------------------- all const and functions ----------------//
  const handlechange = (id) => {
    setExestingCustomer(id);
  };

  const handleClickNext = (data) => {
    console.log(data);
    handleNext(data);
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.Event} ${row.Id}`,
        header: "Event",
        enableHiding: false,
        size: 250,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer ">
            {row.original.Event}
          </div>
        ),
      },

      {
        accessorKey: "Time",
        header: "Time",
        size: 180,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            {row.original.Time}
          </div>
        ),
      },

      {
        accessorKey: "Booking Required",
        header: "Booking Required",
        size: 120,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            {row.original.BookingRequired}
          </div>
        ),
      },
      {
        accessorKey: "Avaibility",
        header: "Avaibility",
        size: 200,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            <div
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor:
                  row.original.Availability === "Available" ? "green" : "red",
              }}
            ></div>
            {row.original.Availability}
          </div>
        ),
      },
      {
        accessorKey: "select",
        header: "",
        size: 60,
        Cell: ({ row }) => (
          <div className="">
            <ETButton
              buttonText={"Select Event"}
              variant={"white"}
              size={"sm"}
            />
          </div>
        ),
      },
    ],
    []
  );

  return (
    <div className="">
      <div className="scrollable-container-y me-1" style={{ height: "500px" }}>
        <div>
          {flag == "MCTAU" ? (
            <div className="light-font-text border-bottom p-4">
              <Row>
                <Col lg={8}>
                  <div>
                    If you want to create a new appointment for the customer,
                    you can do this below and choose whether to cancel and
                    delete the current appointment in the system. The system
                    will send a cancelation to the customer.
                  </div>
                  <div className="pt-2">
                    If the customer has a partner who should also be invited,
                    this can be added by clicking the icon below.
                  </div>
                  <div className="d-flex gap-4 pt-3">
                    {/* ----------------Use same address as primary customer------------------ */}
                    <div className="d-flex gap-2 align-items-center">
                      <div>
                        <Toggle
                          id={"cancelCurrentAppointment"}
                          checked={switchForCancelCurrentAppointment}
                          onChange={(e) =>
                            setSwitchForCancelCurrentAppointment(
                              !switchForCancelCurrentAppointment
                            )
                          }
                        />
                      </div>
                      <H5>Cancel current appointment</H5>
                    </div>

                    {/*-------------- Create new appointment ---------------------*/}
                    <div className="d-flex gap-2 align-items-center">
                      <div>
                        <Toggle
                          id={"createNewAppointment"}
                          checked={switchForCreateNewAppointment}
                          onChange={(e) =>
                            setSwitchForCreateNewAppointment(
                              !switchForCreateNewAppointment
                            )
                          }
                        />
                      </div>
                      <H5>Create new appointment</H5>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <Row className="p-4 pb-0">
              <Col lg={8}>
                <div className="light-font-text">
                  You are now creating a appointment with a customer. This
                  appointment can be scheduled as a personalised appointment or
                  attached to an existing event. Please indicate your preference
                  below.
                </div>{" "}
                <div className="light-font-text">
                  In case the customer would like to invite their partner, you
                  may click on the icon provided to add them.
                </div>
              </Col>
            </Row>
          )}{" "}
          {/*  */}
          <div
            className={
              switchForCreateNewAppointment == false && "disabled-container"
            }
          >
            <Row className="px-4 py-4">
              <Col>
                <div className="d-flex align-items-center gap-2 ">
                  <H4>Customer</H4>
                  <CustomToolTip size="sm" text="Customer" />
                </div>
                <div className="light-font-text pt-1 pb-3">
                  Selected Customer to this appointment.
                </div>
              </Col>

              <Row>
                <Col className=" " lg={5}>
                  <div className="position-relative w-100">
                    <div
                      className="bg-white position-absolute px-1 font-medium fs-8 grey-text"
                      style={{ left: "14px", top: "-5px" }}
                    >
                      To recipients
                    </div>
                    <div className="badge-input">
                      <span
                        className="   radius-4  bgcolor-d-white "
                        style={{
                          border: "0.5px solid #888",
                          width: "fit-content",
                          padding: "0px 8px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon="fa-regular fa-circle-xmark"
                          style={{ color: "#888888", cursor: "pointer" }}
                        />
                        <FontAwesomeIcon
                          icon="fa-regular fa-user-pen"
                          className="ps-2"
                          style={{ color: "#000000", cursor: "pointer" }}
                          onClick={() => {
                            setAddExtraCustomer(true);
                          }}
                        />
                        {/* <span className="ps-1">Thomas Høeg Mogensen</span> */}
                        <span className="ps-1">
                          {customerDetailsData?.customer}
                        </span>
                      </span>
                      <span
                        className="   radius-4  bgcolor-d-white "
                        style={{
                          border: "0.5px solid #888",
                          width: "fit-content",
                          padding: "0px 8px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon="fa-regular fa-circle-xmark"
                          style={{ color: "#888888", cursor: "pointer" }}
                        />
                        <FontAwesomeIcon
                          icon="fa-regular fa-user-pen"
                          className="ps-2"
                          style={{ color: "#000000", cursor: "pointer" }}
                          onClick={() => {
                            setAddExtraCustomer(true);
                          }}
                        />
                        {/* <span className="ps-1">Thomas Høeg Mogensen</span> */}
                        <span className="ps-1">
                          {customerInformationData?.firstname}{" "}
                          {customerInformationData?.lastname}
                        </span>
                      </span>
                    </div>
                  </div>
                </Col>
                <Col lg={1} className="ps-0 d-flex align-items-center  gap-3">
                  {" "}
                  <FontAwesomeIcon
                    icon="fa-regular fa-user-plus"
                    size="lg"
                    style={{ color: "#000000", cursor: "pointer" }}
                    onClick={() => {
                      setAddExtraCustomer(true);
                    }}
                  />
                </Col>
              </Row>
            </Row>
            <Row className="p-4 pt-0">
              <Col>
                <div className="d-flex align-items-center gap-2">
                  <H4>Responsible agent</H4>
                  <CustomToolTip size="sm" text="Responsible agent" />
                </div>
                <div className="light-font-text  pt-1 pb-3">
                  Select the responsible agent who will handel this customer
                </div>
              </Col>
              <Row>
                <Col lg={5}>
                  <ReactSelect
                    placeholder={"Responsible agent"}
                    options={GetResponsibleAgentData?.data?.map((item) => ({
                      value: item?.Id,
                      label: item?.Text,
                    }))}
                  />
                </Col>
              </Row>
            </Row>

            <hr className="hr m-0 p-0"></hr>
            <div className="pb-2 px-4 pt-4">
              <Row className="pb-4">
                <Col lg={6}>
                  <GroupButtons
                    tabs={[
                      {
                        id: 1,
                        tabTitle: "Select an existing event",
                        title: "Select an existing event",
                      },
                      {
                        id: 2,
                        tabTitle: "Create new appointment",
                        title: "Create new appointment",
                      },
                    ]}
                    currentTab={exestingCustomer}
                    handleTabClick={(tab) => handlechange(tab)}
                  />
                </Col>
              </Row>
            </div>
            {/*--tab switch part --*/}
            <div className="">
              {exestingCustomer === 1 ? (
                <div>
                  <div className=" px-4">
                    <Row>
                      <Col lg={7}>
                        <div className="light-font-text">
                          Fill in the fields below to create a new appointment
                          to the selected customer: It you need to create an
                          event that other customers can join, please create
                          this on the Event page.
                        </div>
                      </Col>
                    </Row>
                    <Row className="pt-4">
                      <Col>
                        <div className="d-flex align-items-center gap-2 pb-1">
                          <H4>Event Title</H4>
                          <CustomToolTip size="sm" text="Event Title" />
                        </div>
                        <div className="light-font-text">
                          Internal title which will be shown along with the
                          appointment in the system.
                        </div>
                      </Col>
                    </Row>
                    <Row className="pt-3">
                      <Col lg={6}>
                        <ReactSelect placeholder={"Title"} />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pt-4 ">
                        <div className="light-font-text pb-3">
                          External title which will be shown to the customer.
                        </div>
                      </Col>
                    </Row>
                    <Row className="pb-4">
                      <Col lg={6}>
                        <ReactSelect placeholder={"External event titel"} />
                      </Col>
                    </Row>
                  </div>
                  <hr className="hr m-0 p-0"></hr>
                  <div className="pb-2 px-4 pt-4">
                    <Row>
                      <Col>
                        <div className="d-flex align-items-center gap-2 pb-1">
                          <H4>Date & Time</H4>
                          <CustomToolTip size="sm" text="Date & Time" />
                        </div>

                        <div className="light-font-text">
                          Select date and time for the event
                        </div>
                      </Col>
                    </Row>

                    <Row className="pt-3 pb-4">
                      <Col lg={2} className="pe-lg-0">
                        <div>
                          <InputField type={"Date"} />
                        </div>
                      </Col>
                      <Col lg={2} className="pe-lg-0">
                        <div>
                          <ReactSelect placeholder={"Starting Time"} />
                        </div>
                      </Col>
                      <Col lg={2} className="pe-lg-0">
                        <div>
                          <ReactSelect placeholder={"End Time"} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : (
                <div>
                  <Row className="px-4">
                    <Col>
                      <p>
                        Below are the upcoming events that have been created on
                        selected property.
                      </p>

                      <MRTTheme
                        colorVariantsForTableRow={["gray"]}
                        colorVariantsForTableHead={["white"]}
                        table={
                          <MaterialReactTable
                            columns={columns}
                            enableTopToolbar={false}
                            enableColumnActions={false}
                            icons={{ ...SortIcon }}
                            // data={unitsData?.filter((data) => data.is_linked_with_screening !== 1) ?? []}
                            data={appointmentData}
                            enableRowActions={false}
                            muiTablePaperProps={{
                              sx: {
                                table: {
                                  borderCollapse: "separate",
                                  borderSpacing: "0 5px",
                                },
                              },
                            }}
                            muiTableContainerProps={{
                              sx: {
                                padding: "0px !important",
                              },
                            }}
                            muiTablePaginationProps={{
                              ...paginationoptions,
                            }}
                            positionGlobalFilter="right"
                            positionToolbarDropZone="bottom"
                            positionActionsColumn="last"
                          />
                        }
                      />
                    </Col>
                  </Row>
                </div>
              )}

              {/*--switch table--*/}
            </div>
          </div>
        </div>
      </div>
      <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-between footer-section-button-container">
        <ETButton
          variant={flag == "MCTAU" ? "secondary" : "white"}
          buttonText={flag == "MCTAU" ? "BACK" : "CLOSE"}
          onClick={handleCancel}
        />
        <ETButton
          variant={"primary"}
          buttonText="NEXT"
          type={"submit"}
          onClick={handleClickNext}
        />
      </div>
    </div>
  );
};

export default CreatingAppointment;
