import React, { useState } from "react";
import CustomPopup from "../../../../Components/CustomPopup";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../../../Components/Buttons/ETButton";
import Toggle from "../../../../Components/Switch/Switch";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import DateRangeFilter from "../../../../Components/DateRangePicker/DateRangeFilter";
import { H4, H5 } from "../../../../styles/Common.style";

// RS == Restart Screening
// SSOC == Start Screening - One Customer
// SSMC == Start Screening - Multiple Customer
export const RestartScreening = ({
  open,
  onHide,
  usedFor = "RS",
  onCancel,
  onStart,
  PrimaryClick,
  placeholder = "Exclusive priority - Thomas Høeg Mogensen & Jytte Nielsen",
}) => {
  return (
    <div>
      <CustomPopup
        open={open}
        onHide={onHide}
        title={usedFor == "RS" ? "Restart Screening" : "Start Screening"}
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        size={"md"}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        titleTooltipText={
          usedFor == "RS" ? "Restart Screening" : "Start Screening"
        }
        secondTextShow={false}
        priorityShow={false}
      >
        <div className="light-font-text">
          <div className="px-4 py-3">
            <Row className="d-flex flex-column">
              <Col className="d-flex flex-column gap-3">
                <div>
                  {usedFor == "SSOC"
                    ? "You are about to start a screening on a customer!"
                    : usedFor == "SSMC"
                    ? "You are about to start a screening for the customers!"
                    : "You are about to restart a screening on a customer!"}
                </div>

                <div>
                  {usedFor == "SSOC" ? (
                    "The system has set a deadline based on the setting selected on the published screening form. It is possible to change this deadline if necessary on this specific screening below."
                  ) : usedFor == "SSMC" ? (
                    "The system has set a deadline based on the setting selected on the published screening form. It is possible to change this deadline if necessary, but reflected on all the selected customers screenings"
                  ) : (
                    <div className="d-flex flex-column gap-3">
                      <div>
                        Please note that all previous answers made by the
                        customer can't be reused.
                      </div>
                      <div>
                        The system has set a deadline based on the setting
                        selected on the published screening form. It is possible
                        to change this deadline if necessary on this specific
                        screening below.
                      </div>
                    </div>
                  )}
                </div>

                <div className="w-75">
                  <DateRangeFilter />
                </div>

                <div>
                  {usedFor == "SSOC" ? (
                    "The system will also create a contract between the customer and the unit."
                  ) : usedFor == "SSMC" ? (
                    <div className="d-flex flex-column gap-3">
                      <div>
                        The system will also create a contract between the
                        customer and the unit.
                      </div>
                      <div>
                        Note that since multiple customers are selected at the
                        same time, it is not possible to add a priority on the
                        contracts.{" "}
                      </div>
                    </div>
                  ) : (
                    "The system will also create a contract between the customer and the unit."
                  )}
                </div>
              </Col>
            </Row>
          </div>

          {usedFor == "SSOC" || usedFor == "RS" ? (
            <div className="border-top">
              <Row className="py-4 px-4">
                <Col className=" d-flex flex-column gap-3">
                  <H4>Add a priority</H4>

                  <div>
                    {usedFor == "SSOC"
                      ? "If you want to prioritise this contact, you can do so here. The priority can be changed afterwards."
                      : usedFor == "SSMC"
                      ? ""
                      : "If you want to prioritise this contact, you can do so here. The priority can be changed afterwards."}
                  </div>

                  <ReactSelect placeholder={placeholder} />
                </Col>
              </Row>
            </div>
          ) : null}
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            buttonText="Cancel"
            variant={"white"}
            onClick={onCancel}
          />
          <div className="d-flex ">
            <div>
              <ETButton
                buttonText={"START"}
                variant={"primary"}
                onClick={onStart}
              />
            </div>
          </div>
        </div>
      </CustomPopup>
    </div>
  );
};

export default RestartScreening;

// Created by --------------> Yash
