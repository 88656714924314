import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import EstateloginLogo from "../../assets/images/et-logo-new-user.png";
import StandardSigning from "../../assets/images/StandardSignin.jpg";
import UserFormStepOne from "./newUserPages/UserFormStepOne";
import VerifyPhoneNumber from "./newUserPages/VerifyPhoneNumber";
import TellAboutYourself from "./newUserPages/TellAboutYourself";
import PrimaryWorkArea from "./newUserPages/PrimaryWorkArea";
import CreatePassword from "./newUserPages/CreatePassword";
import LetsCelebrate from "./newUserPages/LetsCelebrate";
import {
  useValidateUserSignUp,
  useUpdateUserSignup,
} from "../../hooks/queries/useUserQueries";
import { useSendOtp, useValidateOtp } from "../../hooks/queries/useSMSQueries";
import { status } from "../../model/Constant";
import { toast } from "react-toastify";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useGetCommonType } from "../../hooks/queries/useCommonTypeQueries";
import axios from "axios";

const NewUserSignup = () => {
  let inviteId = "";

  let customToken = {};
  const navigate = useNavigate();
  //----------Params
  const { search } = useLocation();
  const parameters = new URLSearchParams(search);
  if (parameters.get("invite_id")?.length) {
    inviteId = parameters.get("invite_id");
    customToken = {
      "auth-token": "",
      token: parameters.get("invite_id"),
    };
  }
  //------------states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompnayName] = useState("");
  const [designation, setDesignation] = useState("");
  const [languageId, setLanguageId] = useState("");
  const [stepWizard, setStepWizard] = useState("NewUserSignUpStepOne");
  const [referenceNo, setReferenceNo] = useState("");
  const [fpHash, setFpHash] = useState("");
  const [verificationProcess, setVerificationProcess] = useState("D");
  const [radioInput, setRadioInput] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    message: "",
    action: "",
    flag: "",
  });
  const [buttonText, setButtonText] = useState("");
  const [os, setOs] = useState("");
  const [ipAddress, setIPAddress] = useState("");
  const [browser, setBrowser] = useState("");
  // ----useEffect to get ip address
  useEffect(() => {
    const handleGetIPAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org/?format=json");
        setIPAddress(response?.data?.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    handleGetIPAddress();
  }, []);
  //-----useEffect to get visitorID
  useEffect(() => {
    const setFp = async () => {
      const fp = await FingerprintJS.load();
      const { visitorId } = await fp.get();
      setFpHash(visitorId);
    };
    setFp();
  }, []);
  // -------useEffect to get user's Operating system

  useEffect(() => {
    const detectOs = () => {
      const userAgent = window.navigator.userAgent;
      if (userAgent.indexOf("Win") !== -1) {
        setOs("Windows");
      } else if (userAgent.indexOf("Mac") !== -1) {
        setOs("MacOS");
      } else if (userAgent.indexOf("Linux") !== -1) {
        setOs("Linux");
      } else {
        setOs("Unknown");
      }
    };

    detectOs();
  }, []);
  // -----useEffect to get the user's browser
  useEffect(() => {
    const getBrowserName = () => {
      let browserInfo = navigator.userAgent;
      let browser;
      if (browserInfo.includes("Opera") || browserInfo.includes("Opr")) {
        setBrowser("Opera");
      } else if (browserInfo.includes("Edg")) {
        setBrowser("Edge");
      } else if (browserInfo.includes("Chrome")) {
        setBrowser("Chrome");
      } else if (browserInfo.includes("Safari")) {
        setBrowser("Safari");
      } else if (browserInfo.includes("Firefox")) {
        setBrowser("Firefox");
      } else {
        browser = "unknown";
      }
      return browser;
    };
    getBrowserName();
  }, []);

  //--------------react-query
  const {
    data: validateUserSignUpData,
    isError: isValidateUserSignUpError,
    error: validateSignUpError,
  } = useValidateUserSignUp(inviteId);
  const { data: GetCommonTypeData } = useGetCommonType(customToken, "USRWA");
  const { mutateAsync: sendOtpAsync, isLoading: isSendOtpLoading } =
    useSendOtp();
  const { mutateAsync: validateAsync, isLoading: isValidateOtpLoading } =
    useValidateOtp();
  const {
    mutateAsync: updateUserSingupAsync,
    isLoading: isUpdateSignupLoading,
  } = useUpdateUserSignup();
  let commontypeData = GetCommonTypeData?.data;

  // -------useEffect
  useEffect(() => {
    if (
      isValidateUserSignUpError &&
      String(validateSignUpError?.data?.code) === String(911)
    ) {
      navigate("/page-dosent-exist");
    } else if (
      isValidateUserSignUpError &&
      String(validateSignUpError?.data?.code) === String(912)
    ) {
      navigate("/sign-up-link-expired", {
        ownerEmail: validateSignUpError?.data?.data?.owner_email,
      });
    }
  }, [isValidateUserSignUpError, validateSignUpError]);
  // ---------step one ---------------//
  const handleUserSignUpStepOneNext = async (data) => {
    setFirstName(data?.first_name);
    setLastName(data?.last_name);
    const token = { token: inviteId };

    try {
      const result = await sendOtpAsync(token);
      if (result?.status?.toLowerCase() === status?.s?.toLowerCase()) {
        setReferenceNo(result?.data?.data?.ref_no);
        setStepWizard("NewUserSignUpStepTwo");
      } else {
        toast.error(result?.data?.message);
      }
    } catch (error) {}
  };
  // ---------step Two ---------------//
  const handleUserSignUpStepTwoNext = async (data) => {
    const otpValues = Object.values(data);
    const concatenatedString = otpValues?.join("");

    const validateOtpPayload = {
      otp_code: concatenatedString,
      ref_no: referenceNo,
      visitorId: fpHash,
      is_authorize: 1,
      ip: ipAddress,
      os,
      browser,
    };
    // const validateData = {
    //   token: inviteId,
    //   payload: validateOtpPayload,
    // };

    const validateData = {
      token: {
        token: inviteId,
      },
      payload: validateOtpPayload,
    };
    try {
      const result = await validateAsync(validateData);
      if (result?.status?.toLowerCase() === status?.s?.toLowerCase()) {
        setVerificationProcess("S");
        // setStepWizard("NewUserSignUpStepThree");
      }
    } catch (error) {
      if (error?.error && String(error?.data?.code) === String(911)) {
        setErrorMessage({
          message:
            "Oh no, something went wrong. The verification code was declined.",
          action: "Please go back and try again.",
          flag: "Invalid",
        });
        setButtonText("Try again");
        setVerificationProcess("E");
      } else if (error?.error && String(error?.data?.code) === String(912)) {
        setErrorMessage({
          message:
            "Oh no, something went wrong. The verification code is no longer valid or expired.",
          action: "Please click on resend to receive a new verification code.",
          flag: "Expired",
        });
        setButtonText("Resend code");
        setVerificationProcess("E");
      }
    }
  };

  // ----------- step three -----------
  const handleUserSignUpStepThreeNext = (data) => {
    setCompnayName(data?.Workplace);
    setDesignation(data?.job_title);
    setLanguageId(data?.selectLanguage?.value);
    setStepWizard("NewUserSignUpStepFour");
  };

  // ----------- step four -----------
  const handleUserSignUpStepFourNext = (data) => {
    setStepWizard("NewUserSignUpStepFive");
  };

  // ---------------- step five-----------
  const handleUserSignUpStepFiveNext = async (data) => {
    let updateUserSignupPayload = {
      first_name: firstName,
      last_name: lastName,
      company_name: companyName,
      designation,
      language_id: languageId,
      work_area_id: radioInput,
      password,
      visitorId: fpHash,
    };
    const updateUserSignupData = [
      {
        inviteId: inviteId,
        payload: updateUserSignupPayload,
      },
    ];
    try {
      const result = await updateUserSingupAsync(updateUserSignupData);
      if (result?.status?.toLowerCase() === status?.s?.toLowerCase()) {
        setStepWizard("NewUserSignUpStepSix");
        setVerificationProcess("D");
      } else {
        toast?.error(result?.data?.message);
      }
    } catch (error) {}
  };

  // ---------------- step six-----------
  const handleNavigateToLogin = async () => {
    navigate("/");
  };

  let userBasicData = validateUserSignUpData?.data;

  const WizardsPages = {
    NewUserSignUpStepOne: (
      <UserFormStepOne
        handleNext={handleUserSignUpStepOneNext}
        userBasicData={userBasicData}
        customToken={customToken}
        isSendOtpLoading={isSendOtpLoading}
      />
    ),
    NewUserSignUpStepTwo: (
      <VerifyPhoneNumber
        handleNext={handleUserSignUpStepTwoNext}
        refNo={referenceNo}
        verificationProcess={verificationProcess}
        setVerificationProcess={setVerificationProcess}
        handleUserSignUpStepOneNext={handleUserSignUpStepOneNext}
        errorMessage={errorMessage}
        buttonText={buttonText}
        setStepWizard={setStepWizard}
        isValidateOtpLoading={isValidateOtpLoading}
      />
    ),
    NewUserSignUpStepThree: (
      <TellAboutYourself
        handleNext={handleUserSignUpStepThreeNext}
        customToken={customToken}
      />
    ),
    NewUserSignUpStepFour: (
      <PrimaryWorkArea
        handleNext={handleUserSignUpStepFourNext}
        commontypeData={commontypeData}
        radioInput={radioInput}
        setRadioInput={setRadioInput}
      />
    ),
    NewUserSignUpStepFive: (
      <CreatePassword
        handleNext={handleUserSignUpStepFiveNext}
        password={password}
        setPassword={setPassword}
        isUpdateSignupLoading={isUpdateSignupLoading}
      />
    ),
    NewUserSignUpStepSix: (
      <LetsCelebrate handleNavigateToLogin={handleNavigateToLogin} />
    ),
  };
  return (
    <div
      className=" d-flex flex-column justify-content-center align-items-center "
      style={{
        position: "relative",
        background: "#2B3C49",
        minHeight: "100vh",
        height: "100% !important",
      }}
    >
      <div className="py-4">
        <Image
          width={"160px"}
          src={EstateloginLogo}
          alt="Brand-logo"
          className="d-block m-auto "
        />

        <div className="loginForm mt-4 mb-3 mx-4 d-flex">
          <div className="ps-2 pt-3 w-50  me-1">
            <div>{WizardsPages[stepWizard]}</div>
          </div>
          <div
            className="d-none d-sm-block w-50 pe-3 py-1"
            style={{ overflow: "hidden" }}
          >
            <Image
              fluid
              className="w-100 h-100"
              src={StandardSigning}
              alt="login-image-pattern"
              style={{ objectFit: "cover", borderRadius: "8px" }}
            />
          </div>
        </div>
      </div>
      <div className="light-font-text pb-4" style={{ color: "#FFF" }}>
        This solution is powered by{" "}
        <span className="form-label-title " style={{ color: "#FFF" }}>
          estatetool
        </span>{" "}
        | © 2023 - All rights reserved
      </div>
    </div>
  );
};

export default NewUserSignup;
