import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

const StarRating = ({ favoriteRating, setFavoriteRating }) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);

  useEffect(() => {
    setFavoriteRating(rating);
  }, [rating]);

  return (
    <div>
      {[...Array(5)].map((star, i) => {
        const ratingValue = i + 1;

        return (
          <label>
            <input
              type="radio"
              name="rating"
              value={ratingValue}
              // onClick={() => setRating(ratingValue)}
              onClick={() => {
                // Remove the star from 1st star if user wants 0 rating
                setRating((prevRating) => (prevRating == 1 ? 0 : ratingValue));
              }}
              checked={ratingValue == rating}
              style={{ display: "none" }}
            />
            <FontAwesomeIcon
              key={i}
              icon={
                ratingValue <= (hover || rating || favoriteRating)
                  ? "fa-solid fa-star"
                  : "fa-regular fa-star"
              }
              size="lg"
              style={{
                cursor:"pointer",
                color:
                  ratingValue <= (hover || rating || favoriteRating)
                    ? "#F6E651"
                    : "#EADA4A",
              }}
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(null)}
            />
          </label>
        );
      })}
      {/* <p>
        Rating is: {favoriteRating} -- {rating}
      </p> */}
    </div>
  );
};

export default StarRating;


// Change logs:

// Updated by viraj on 19/11/2023
// Fixed on click logic to reset the rating, earlier we cannot reset the rating (OnClick function logic modified).
// Added cursor pointer on star.