import CustomToolTip                                                  from "../../../Components/Tooltip/CustomTooltip";
import { useEffect, useState }                               from "react";
import { useLocation } from "react-router-dom";
import { useGetEiDoMediaPackage, useGetEiDoTextPackage } from './../../../hooks/queries/useEidoQueries';
import {   Tab,
  Tabs,
  TabList,
  TabPanel } from './../../../Components/Tabs/ReactTabs.style';
import EidoNotAssignedMedia from './EidoNotAssignedMedia';
import EidoAssignedMedia from './EidoAssignedMedia';

const Media = () => {
  let location = useLocation();
  const { state } = location;
  let propId = state?.propId;

  const {
    data: GetEiDoMediaPackageData,
    isLoading,
  } = useGetEiDoMediaPackage(propId);

// Filter arry for the text packgaes
  const [assignedMediaPackage, setAssignedMediaPackage] = useState([]);
  const [notAssignedMediaPackage, setNotAssignedMediaPackage] = useState([]);

  useEffect(()=> {
    setAssignedMediaPackage([])
    setNotAssignedMediaPackage([])

    if(GetEiDoMediaPackageData?.status === "success" && GetEiDoMediaPackageData?.data.length) {

      // Filter text package according to assign/not assign 
      GetEiDoMediaPackageData?.data.forEach((tpd => {
        if(tpd.Package_Name.length === 0){
          setNotAssignedMediaPackage((prev) => [...prev, {
            unit_no       : tpd.unit_calculated_number,
            unit_type     : tpd.alt_unit_id,
            package_group : tpd.Package_Name
          }])
        }else if(tpd.Package_Name.length > 0) {
          setAssignedMediaPackage((prev) => [...prev, {
            unit_no       : tpd.unit_calculated_number,
            unit_type     : tpd.alt_unit_id,
            package_group : tpd.Package_Name
          }])
        }
      }))
    }
  }, [GetEiDoMediaPackageData])

  const mediaTabs = [
    {
      Id: "1",
      tab: "Assigned",
      component: <EidoAssignedMedia assignedMediaPackageData={assignedMediaPackage} />,
      Notification: assignedMediaPackage.length,
    },
    {
      Id: "2",
      tab: "Not assigned",
      component: <EidoNotAssignedMedia notAssignedMediaPackageData={notAssignedMediaPackage} />,
      Notification: notAssignedMediaPackage.length,
    },
  ];

  return (
    <div className = "document-container p-4 my-2">
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius   : "8px",
          fontSize       : "14px",
          border         : "1px solid #F0F0F0",
        }}
      >
        <div
          className = "px-4 pb-3"
          style     = {{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div           style     = {{ fontFamily: "Cerebri Sans" }} className = "pt-3 d-flex">
          <div           className = "fw-600 fs-14">Text</div>
          <div           style     = {{ paddingLeft: "15px" }}>
          <CustomToolTip height    = {12} text                                  = "Text" />
            </div>
          </div>
        </div>
        <Tabs lassName="mb-3 pb-1 d-flex flex-column">
        <div
          className="d-flex ps-4 pe-3 py-3 justify-content-between"
          style={{
            width: "100%",
            flexDirection: "row",
            flexWrap: "wrap",
            borderBottom: "1px solid #F0F0F0",
          }}
        >
          <TabList className="light ps-0  ">
            {mediaTabs.map((page, index) => (
              <>
                <Tab
                  key={index}
                  style={{ position: "relative" }}
                  // onClick={() => setoverviewTabs(page?.tab)}
                >
                  <span className="me-1" style={{ position: "relative" }}>
                    {page?.tab}
                  </span>
                  (<span>{page?.Notification}</span>)
                </Tab>
              </>
            ))}
          </TabList>
        </div>
        <div className="bg-white" style={{ borderRadius: " 10px" }}>
          {mediaTabs.map((page, index) => (
            <TabPanel key={page?.Id}>{page?.component}</TabPanel>
          ))}
        </div>
      </Tabs>
      </div>
    </div>
  );
};

export default Media;
 