import { Get, Post } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";
import { GetSessionItem } from "../utils/SessionStorage";

// export const LogoutApi = () => {
//   return new Promise((resolve, reject) => {
//     Get(
//       Site.AUTH,
//       Endpoint.AUTH.Logout,
//       { Authorization: `Bearer ${GetSessionItem("et-rt")}` },
//       null
//     )
//       .then((response) => {
//         resolve(response);
//       })
//       .catch((error) => {
//         reject({ error: true, message: error.message });
//       });
//   });
// };
export const LogoutApi = () => {
  const logoutPayload = {
    user_login_log_id: GetSessionItem("user-log-id"),
  };
  return new Promise((resolve, reject) => {
    Post(
      Site.AUTH,
      Endpoint.AUTH.Logout,
      { Authorization: `Bearer ${GetSessionItem("et-rt")}` },
      logoutPayload
    )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
