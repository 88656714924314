import React, { useRef, useState } from "react";
import Menus from "../../../Components/Menus/Menus";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import "../../../styles/css/control-center/overview.css";
import DropdownModalMui from "../../../Components/DropdownModal/DropdownModalMui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckBox from "../../../Components/Checkbox/Checkbox";
import CSVWidget from "../../../Components/DownloadWidget/CSVWidget";
import { Row, Col } from "react-bootstrap";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { H2, H3 } from "../../../styles/Common.style";

const statusActivityData = [
  {
    name: "Tagkronen 11275",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Mar 2, 2023 | 17:57",
    status: "RESERVERET",
  },
  {
    name: "Tagkronen 11275",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Mar 2, 2023 | 17:57",
    status: "RESERVERET",
  },
  {
    name: "Tagkronen 11275",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Mar 2, 2023 | 17:57",
    status: "RESERVERET",
  },
  {
    name: "Tagkronen 11275",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Mar 2, 2023 | 17:57",
    status: "RESERVERET",
  },
  {
    name: "Tagkronen 11275",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Mar 2, 2023 | 17:57",
    status: "RESERVERET",
  },
  {
    name: "Tagkronen 11275",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Mar 2, 2023 | 17:57",
    status: "RESERVERET",
  },
  {
    name: "Tagkronen 11275",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Mar 2, 2023 | 17:57",
    status: "RESERVERET",
  },
];

const StatusActivity = () => {
  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "status-activity";

  const options = {
    keys: [
      { field: "username", title: "Name" },
      { field: "StatusId" },
      { field: "status" },
      { field: "name", title: "Property Name" },
      { field: "address" },
      { field: "time", title: "Created At" },
    ],
  };

  // All unitType Dropdown

  const [allActivity, setAllActivity] = useState([
    {
      id: "1",
      activityName: "Available",
    },
    {
      id: "2",
      activityName: "Reserved",
    },
    {
      id: "3",
      activityName: "Sold",
    },
    {
      id: "4",
      activityName: "Leased",
    },
  ]);

  const [allActivityChecked, setAllActivityChecked] = useState([]);

  const handleCheckAllActivity = (e) => {
    if (e.target.checked) {
      const allActivityTypes = allActivity.map(
        (activity) => activity.activityName
      );
      setAllActivityChecked(allActivityTypes);
    } else {
      setAllActivityChecked([]);
    }
  };
  const handleCheckedActivitiy = (e, activity) => {
    if (e.target.checked) {
      setAllActivityChecked([...allActivityChecked, activity.activityName]);
    } else {
      setAllActivityChecked(
        allActivityChecked.filter((item) => item !== activity.activityName)
      );
    }
  };

  const data = statusActivityData;

  const CSVFunction = CSVWidget({ refObj, fileName, options, data });

  return (
    <div
      className="card-body"
      style={{
        minHeight: "575px",
      }}
    >
      <div
      //  className="statusactivity-card-header"
      >
        <div className="p-3">
          <Row className="d-flex align-items-center">
            <Col sm={5}>
              <div className="d-flex align-items-center">
                <H3 className=" me-2">Status activity</H3>{" "}
                <CustomToolTip size="sm" text="Status Activities" />
              </div>
            </Col>
            <Col sm={7}>
              <div className="d-flex justify-content-end gap-3">
                <div style={{ width: "60%" }}>
                  {statusActivityData.length != 0 ?
                    <ReactSelect
                      placeholder="All activity"
                    />
                    :
                    <DropdownModalMui
                      text="All activity"
                      component={
                        <div className="py-2 ps-2 pe-5">
                          {/* <div className="d-flex align-items-center light-font-text ">
                          <CheckBox
                            onChange={handleCheckAllActivity}
                            id={"handleCheckAllActivity"}
                            checked={
                              allActivityChecked.length === allActivity.length
                            }
                          />{" "}
                          All activities
                        </div> */}

                          {/* {allActivity.map((activity, i) => (
                          <div
                            key={i}
                            className="d-flex align-items-center light-font-text ps-4 "
                          >
                            <CheckBox
                              id={activity.id}
                              checked={allActivityChecked.includes(
                                activity.activityName
                              )}
                              onChange={(e) =>
                                handleCheckedActivitiy(e, activity)
                              }
                            />{" "}
                            {activity.activityName}
                          </div>
                        ))} */}
                        </div>
                      }
                    />}

                </div>
                <div>
                  <Menus
                    itemheight={40}
                    itemwidth={40}
                    itemcontainerheight={200}
                    itemcontainerwidth={200}
                    component={
                      <div className="fs-14">
                        <div className="addpropContent px-3 py-2 ">
                          <span className="ms-1" onClick={() => CSVFunction()}>
                            Download widget as CSV
                          </span>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {statusActivityData.length == 0 ? (
          <div
            className="scrollable-container-y me-2 px-4"
            style={{ height: "487px" }}
          >
            {statusActivityData.map((status, i) => (
              <Row key={i} className="card-inner-body p-3 my-2">
                <Col xs={7}>
                  <div className="table-property-name">{status.name}</div>

                  <div className="light-font-text fs-13">{status.address}</div>
                  <div className="light-font-text fs-10">
                    {status.username} | {status.time}
                  </div>
                </Col>

                <Col
                  xs={5}
                  className="d-flex align-items-center  justify-content-end"
                >
                  <div
                    className="form-label-title fs-12 status-activity-label me-3"
                    style={{
                      backgroundColor: "#F6E651",
                    }}
                  >
                    {status.status}
                  </div>
                  <FontAwesomeIcon
                    icon="fa-regular fa-thumbs-up"
                    className="cursor-pointer"
                  />

                  {/* for dislike */}
                  {/* <FontAwesomeIcon icon="fa-solid fa-thumbs-up"  className="cursor-pointer" /> */}
                </Col>
              </Row>
            ))}
          </div>
        ) : (
          <NoDataScreen height={"377px"} imgHeight={"150px"} />
        )}
      </div>
    </div>
  );
};

export default StatusActivity;
