import React from "react";

import estatelogosystem from "../../../assets/images/et-logo-new-user.png";
import { Col, Container, Image, Row } from "react-bootstrap";
import { H2, H4, H5 } from "../../../styles/Common.style";
import "@dotlottie/player-component";

import Rocket from "../../../assets/animations/LoginPageDontExist.lottie";
import ETButton from "../../../Components/Buttons/ETButton";

const PageDosentExist = ({ EndDate = true }) => {
  return (
    <div className='Maindiv pt-4 d-flex flex-column container-fluid  justify-content-center align-items-center' style={{ backgroundColor: "#2B3C49",height:"100%" }}>
      <div className="pt-4">
        <div className="d-flex justify-content-center align-items-center pb-4">
          <Image
            width={"160px"}
            height={"auto"}
            src={estatelogosystem}
            alt="logo"
          />
        </div>
        <Container
        className='StandardForm  row   justify-content-md-center  mx-auto '
          style={{
            backgroundColor: "#FFFFFF",
            width: "1000px",
            height: "630px",
            borderRadius: "12px",
            boxShadow: " 0px 6px 18px 0px #0000000A",
          }}
        >
          <div className="pt-40 pb-40 d-flex justify-content-center align-items-center ">
            <Row>
              <Col>
                <H2>Huston we have a problem, maybe?</H2>
              </Col>
            </Row>
          </div>
          <div className=" pb-4 d-flex justify-content-center align-items-center text-align-center">
            <Row>
              <Col className="light-font-text">
                There is no estatetool solution with this name. If you believe
                this is an error, please contact
                <br />
                our support team.
              </Col>
            </Row>
          </div>

          <div className=" pb-40 d-flex justify-content-center align-items-center ">
            <Row>
              <Col className="light-font-text d-flex justify-content-center align-items-center text-align-center">
                If it's because you too would like to be part of the future of
                proptech and the market's best <br /> software for marketing and
                property administration, please contact our sales team.
              </Col>
            </Row>
          </div>

          <div className="pb-40 d-flex justify-content-center align-items-center">
            <ETButton variant={"primary"} buttonText="Contact Our Sales Team" />
          </div>
          <div className="pb-4 d-flex justify-content-center align-items-center">
           <Row>
            <Col>
            We look forward to hearing from you
            </Col>
           </Row>
          </div>
          <div className="d-flex justify-content-center align-items-center">
           <Row>
            <Col>
           <H4>estatetool teamet</H4>
            </Col>
           </Row>
          </div>

          {/* Lottie Animation */}
          <div
            style={{
              alignItems: "right",
              display: "flex",
              justifyContent: "right",
              position: "relative",
              height: "220px",
            }}
          >
            <div style={{ position: "absolute", bottom: "0" }}>
              <dotlottie-player
                src={Rocket}
                autoplay
                loop
                style={{ height: "450px", width: "100%" }}
              />
            </div>
          </div>
        </Container>
        <div
          className="light-font-text d-flex justify-content-center align-items-center pb-4 pt-40"
          style={{ color: "#FFF" }}
        >
          This solution is powered by{" "}
          <span
            className="form-label-title "
            style={{ color: "#FFF", margin: "0 5px" }}
          >
            estatetool
          </span>{" "}
          | © 2023 - All rights reserved
        </div>
      </div>
    </div>
  );
};

export default PageDosentExist;
