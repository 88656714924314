import React from "react";
import ConfirmCancelSigningOrder from "../../standardComponents/cancelSigningOrder/ConfirmCancelSigningOrder";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { H3, H4 } from "../../../../styles/Common.style";
import { Col, Row } from "react-bootstrap";
import InputField from "../../../../Components/Textfield/InputField";
import { Controller, useForm } from "react-hook-form";

const ConfirmMoving = ({
  handleBack,
  handleNext,
  switchForCancelCurrentAppointment,
  switchForCreateNewAppointment,
}) => {
  // ------------------------  react hooks forms--------------------//
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({});
  // ---------------this function used for giving overview to moving information ----------------------//
  const movingToThisOverview = (key, value) => {
    return (
      <div className="d-flex pb-1 light-font-text ">
        <div style={{ width: "80px" }}>{key}</div>
        <div className="">{value}</div>
      </div>
    );
  };
  return (
    <div>
      <div className="p-4 border-bottom">
        {/* ------ Confirmation that the customer is moved -------- */}
        <div className="d-flex align-items-center gap-2">
          <H3>Confirmation that the customer is moved</H3>
          <CustomToolTip text={"Confirmation that the customer is moved"} />
        </div>
        <div className="pt-3">
          <Row>
            <Col xl={8} className="light-font-text">
              As you have chosen to move the customer to another property or
              unit in the system, a confirmation will be sent to the customer
              regarding this. Below you can customise the confirmation that is
              sent to the customer.
            </Col>
          </Row>
        </div>

        {/* ---------------- Moving to this --------------- */}
        <div className="d-flex align-items-center gap-2 pt-3">
          <H4>Moving to this</H4>
          <CustomToolTip text={"Moving to this"} />
        </div>

        {/* ----------- Moving to this overview section  ----------------- */}
        <div className="pt-2">
          {movingToThisOverview("Property:", "Kaktus Towers")}
          {switchForCreateNewAppointment == true &&
            movingToThisOverview(
              "Address:",
              "Dybølsbro 3, 17., door 11, 2450 København SV"
            )}
          {movingToThisOverview("Unit ID:", "212-187")}
        </div>
      </div>
      {/* ------------- External title which will be shown to the customer  --------------------- */}
      <div className="p-4 border-bottom">
        <div className="pb-3 light-font-text">
          External title which will be shown to the customer{" "}
        </div>
        <Row>
          <Col lg={7} md={8}>
            <Controller
              control={control}
              {...register("ExternalEventTitle")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => {
                return (
                  <InputField
                    id={"ExternalEventTitle"}
                    value={value}
                    type={"text"}
                    placeholder={"External event title"}
                    iserror={errors.ExternalEventTitle}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("ExternalEventTitle");
                    }}
                  />
                );
              }}
            />
          </Col>
        </Row>
      </div>
      <ConfirmCancelSigningOrder
        handleBack={handleBack}
        handleCreate={handleNext}
        usedFor="MCTAU" //Move customer to another unit
        buttonText={
          switchForCancelCurrentAppointment == false &&
          switchForCreateNewAppointment == false
            ? "Confirm"
            : "NEXT"
        }
        // propertyId={propertyId}
        // unitId={unitId}
      />
    </div>
  );
};

export default ConfirmMoving;
