import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetUnitsByPropertyIdUpdated } from "../../../hooks/queries/useUnitQueries";
import { usePropertyId } from "../../../hooks/stores/usePropertyStore";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../../Components/Tabs/ReactTabs.style";
import { GreenSpan } from "../../../styles/Common.style";
import ETButton from "../../../Components/Buttons/ETButton";
import ResponsibleAgent from "../../../Components/ResponsibleAgent/ResponsibleAgent";
import ContactInfo from "../../properties/units/addUnits/editSingleUnit/overview/unitsOverview/requests/commonPage/ContactInfo";
import AdditionalInfo from "../../properties/units/addUnits/editSingleUnit/overview/unitsOverview/requests/commonPage/AdditionalInfo";
import AgentFollowUp from "./AgentFollowUp";
import Status from "./Status";

const AssignAgent = ({ showAddInfo, setShowAddInfo }) => {
  const propertyId = usePropertyId((state) => state.propertyId);

  const {
    data: GetUnitsByPropertyIdData,
    refetch: GetUnitsByPropertyIdDataRefetch,
    isLoading,
  } = useGetUnitsByPropertyIdUpdated(propertyId, 0);

  const [selectedTab, setSelectedTab] = useState(0);

  const [unitsTableData, setUnitsTableData] = useState(
    GetUnitsByPropertyIdData?.data
  );
  const [dataContainer, setDataContainer] = useState(
    GetUnitsByPropertyIdData?.data
  );
  const [selectedUnitsId, setSelectedUnitsId] = useState([]);
  const [openChangeAgentPopup, setOpenChangeAgentPopup] = useState(false);

  // console.log(unitsTableData, "unitsTableData");
  //  const pk=unitsTableData.unit_no
  //  const hp =pk.slice(-1)
  //  console.log(hp)
  const participantsInfo = [
    {
      Id: 1,
      tab: "Status",
      component: <Status />,
    },
    {
      Id: 2,
      tab: "Contact information",
      component: <ContactInfo />,
    },
    {
      Id: 3,
      tab: "Additional information",
      component: (
        <AdditionalInfo
          unitsTableData={unitsTableData}
          setUnitsTableData={setUnitsTableData}
          setSelectedUnitsId={setSelectedUnitsId}
          dataContainer={dataContainer}
        />
      ),
    },
    {
      Id: 4,
      tab: "Follow up",
      component: <AgentFollowUp />,
    },
  ];
  return (
    <div>
      <div>
        <Tabs selectedIndex={selectedTab} className=" pb-1 d-flex flex-column">
          <div
            className="px-4 my-2 pb-2 d-flex justify-content-between "
            style={{
              alignItems: "center",
              borderBottom: "1px solid #F0F0F0",
            }}
          >
            <TabList className="light ps-0 ">
              {participantsInfo.map((page, index) => (
                <Tab
                  style={{ position: "relative" }}
                  onClick={() => setSelectedTab(index)}
                >
                  <span style={{ position: "relative" }}>{page?.tab}</span>
                  {page.msg && (
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle"
                      size="lg"
                      style={{ color: "#D00200" }}
                      className="px-1 notification"
                    />
                  )}
                </Tab>
              ))}
            </TabList>
            <div className="light-font-text d-flex flex-column text-end">
              <span className="form-label-title">Responsible agent</span>
              <span>
                {/* {tenancyByUnitIdData?.data?.[0]?.responsible_agent_name} */}
                Max Wildersans Brentford
              </span>
              <div className="d-flex justify-content-end fs-12">
                <GreenSpan
                  onClick={() => setOpenChangeAgentPopup(true)}
                  className="cursor-pointer"
                >
                  Change agent
                </GreenSpan>
              </div>
            </div>
          </div>

          <div
            className="scrollable-container-y"
            style={{ height: "450px", overflowY: "scroll" }}
          >
            {participantsInfo.map((page, index) => (
              <TabPanel key={page?.Id}>{page?.component}</TabPanel>
            ))}
          </div>
          <div className=" py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            {" "}
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              // type={"button"}
              onClick={() => setShowAddInfo(false)}
            />
            <div className=" d-flex ">
              {/* {hideBtn == false && ( */}
              <div className="px-2">
                {" "}
                <ETButton
                  buttonText="DELETE CONTACT"
                  variant={"white"}
                  icon={true}
                  width="100%"
                  type={"submit"}
                />
              </div>
              {/* )} */}

              <div className="px-1">
                {" "}
                <ETButton
                  variant={"primary"}
                  buttonText="SAVE"
                  type={"submit"}
                  onClick={() => setShowAddInfo(false)}
                />
              </div>
            </div>
          </div>
        </Tabs>
        <ResponsibleAgent
          openChangeAgentPopup={openChangeAgentPopup}
          setOpenChangeAgentPopup={setOpenChangeAgentPopup}
        />
      </div>
    </div>
  );
};

export default AssignAgent;
