import React, { useState } from "react";
import SelectReasonCancelSigningOrder from "./SelectReasonCancelSigningOrder";
import { signingOrderData } from "../../../overview/StaticData";
import ConfirmCancelSigningOrder from "./ConfirmCancelSigningOrder";
import CustomPopup from "../../../../Components/CustomPopup";

const CancelWizard = ({
  openCancelWizardModal,
  setOpenCancelWizardModal,
  switchWizardScreen,
  setSwitchWizardScreen,
  nextWizardScreenText,
  title,
  titleTooltip,
  titleTooltipText,
  cancelReasonHeader,
  cancelReasonText,
  unitSection,
  customerSection,
  Status,
  unitInfo,
  customerInfo,
  cancelReasonHeaderTooltip,
  cancelSecondPageReasonHeader,
  cancelSecondPageReasonHeaderTooltip,
  cancelReasonFooterTooltip,
  cancelSecondPageReasonFooterTooltip,
  secondPageStatus,
  cancelReasonSecondPageFooter,
}) => {
  console.log("cancelSecondPageReasonFooterTooltip");
  //--------------- States ---------- //
  /*--first page --*/
  const CancelSigningOrderWizard = {
    SelectReasonSigningOrder: (
      <SelectReasonCancelSigningOrder
        setSwitchWizardScreen={setSwitchWizardScreen}
        setOpenCancelWizardModal={setOpenCancelWizardModal}
        cancelReasonHeader={cancelReasonHeader}
        cancelReasonHeaderTooltip={cancelReasonHeaderTooltip}
        cancelReasonText={cancelReasonText}
        customerSection={customerSection ? customerSection : ""}
        customerInfo={customerInfo}
        unitInfo={unitInfo}
        unitSection={unitSection ? unitSection : ""}
        CancelQuestionData={signingOrderData}
        Status={Status}
      />
    ),
    /*--Second page --*/
    ConfirmSigningOrder: (
      <ConfirmCancelSigningOrder
        cancelSecondPageReasonHeader={cancelSecondPageReasonHeader}
        cancelSecondPageReasonHeaderTooltip={
          cancelSecondPageReasonHeaderTooltip
        }
        setSwitchWizardScreen={setSwitchWizardScreen}
        setOpenCancelWizardModal={setOpenCancelWizardModal}
        nextWizardScreenText={nextWizardScreenText}
        cancelReasonFooterTooltip={cancelReasonFooterTooltip}
        cancelSecondPageReasonFooterTooltip={
          cancelSecondPageReasonFooterTooltip
        }
        secondPageStatus={secondPageStatus}
        cancelReasonSecondPageFooter={cancelReasonSecondPageFooter}
      />
    ),
  };

  return (
    <div>
      {" "}
      <CustomPopup
        open={openCancelWizardModal}
        onHide={() => {
          setOpenCancelWizardModal(false);
          setSwitchWizardScreen(nextWizardScreenText);
        }}
        title={title}
        title_color="black"
        enableCrossButton={true}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        extraInfo={true}
        extraInfoText={
          <div className="grey-text fs-14">Thomas Høeg Mogensen</div>
        }
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={titleTooltip ? titleTooltip : ""}
        titleTooltipText={titleTooltipText}
        size="xl"
      >
        <div>{CancelSigningOrderWizard[switchWizardScreen]}</div>
      </CustomPopup>
    </div>
  );
};

export default CancelWizard;
