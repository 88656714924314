import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Menus from "../../../Components/Menus/Menus";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { NavLink } from "react-router-dom";
import ETButton from "../../../Components/Buttons/ETButton";
import CustomPopup from "../../../Components/CustomPopup";
import CustomCheckBox from "../../../Components/Checkbox/Checkbox";
import { useForm } from "react-hook-form";
import FilterComponent from "../../../reactdataTableComponent/FilterComponent";
import dayjs from "dayjs";
import {
  DeleteDocumentPackageByPackageID,
  PostCloneDocumentPackage,
  PutUpdateDocumentDetails,
  PutUpdateSigningPackageStatus,
} from "../../../services/Documents";
import { toast } from "react-toastify";
import Duplicate from "../../properties/units/duplicate/Duplicate";
import moment from "moment";
import { useAddEditPackage } from "../../../hooks/stores/usePackageStore";
import { useRouting } from "../../../hooks/stores/useRoutingStore";
import { usePostCloneDocumentPackage } from "../../../hooks/queries/useDocumentsQueries";
import SigningCard from "../../../Components/card/SigningCard";
import { StyledCard } from "../../../styles/Common.style";
import { useGetSigningType } from "../../../hooks/queries/useSigningQueries";

const SigningPackages = ({
  // setShowSigningPackages,
  getDocumentPackagesByPropertyIdData,
  getDocumentPackagesByPropertyIdDataRefetch,
  // signingPackageId,
  setSigningPackageId,
  propertyId,
  // setIsEditSigningPackages,
  getAllpropertiesList,
  setConfigurationId,
  setSigningPackageType,
}) => {
  const {
    title,
    setTitle,
    active,
    setActive,
    tagRowData,
    setTagRowData,
    selected,
    setSelected,
    selectedData,
    setSelectedData,
    cancelPopup,
    setCancelPopup,
  } = useAddEditPackage((state) => state);
  const {
    packageType,
    setPackageType,
    showPackage,
    setShowPackage,
    editflag,
    setEditflag,
    packageId,
    setPackageId,
  } = useRouting((state) => state);

  //-----------------------react query------------------//
  const { data: getSigningType } = useGetSigningType(propertyId);

  useEffect(() => setCancelPopup(false), []);
  const handleAddNewSigningPackage = () => {
    // setIsEditSigningPackages(false);
    setEditflag(false);
    // setShowSigningPackages(true); // used prop drilling for showing <AddDocumentPackages /> component outside of document layout.
    setShowPackage(true);
    setCancelPopup(false);
    setTagRowData([]);
    setTitle("");
    setSelected([]);
    setSelectedData([]);
  };
  return (
    <div>
      {/*-------------------- Code for signing package filter ------------- */}

      {/* <Row className="mb-2">
        <Col lg={3} md={6}>
          <ReactSelect
            placeholder={"Signing package type"}
            clearable={true}
            onChange={(e, ActionTypes) =>
              ActionTypes.action === "clear"
                ? setSigningPackageType(0)
                : setSigningPackageType(e.value)
            }
            options={getSigningType?.data?.map((item) => ({
              value: item?.Id,
              label: `${item?.Text} `,
            }))}
          />
        </Col>
      </Row> */}
      {/*-------------------- Code for signing package filter ------------- */}

      <Row>
        {getDocumentPackagesByPropertyIdData?.data?.map((item, index) => (
          <SigningPackageCard
            setShowPackage={setShowPackage}
            setPackageId={setPackageId}
            packageId={packageId}
            setEditflag={setEditflag}
            key={index}
            data={item}
            index={index}
            setTitle={setTitle}
            setActive={setActive}
            setTagRowData={setTagRowData}
            setSelected={setSelected}
            setSelectedData={setSelectedData}
            setCancelPopup={setCancelPopup}
            // signingPackageId={signingPackageId}
            // setSigningPackageId={setSigningPackageId}
            getDocumentPackagesByPropertyIdDataRefetch={
              getDocumentPackagesByPropertyIdDataRefetch
            }
            propertyId={propertyId}
            // setShowSigningPackages={setShowSigningPackages}
            // setIsEditSigningPackages={setIsEditSigningPackages}
            getAllpropertiesList={getAllpropertiesList}
            setConfigurationId={setConfigurationId}
          />
        ))}

        {getDocumentPackagesByPropertyIdData?.data?.length === 0 && (
          <Col lg={6} xl={4} className="my-2">
            <StyledCard height="580px">
              <ETButton
                variant={"primary"}
                buttonText="ADD NEW PACKAGE"
                icon={true}
                width="fit-content"
                onClick={handleAddNewSigningPackage}
              />
            </StyledCard>
            {/* <div className="radius-8 d-flex align-items-center justify-content-center create-signing-package p-3"> */}

            {/* </div> */}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default SigningPackages;

//----------Signing package card-----------//
export const SigningPackageCard = ({
  setShowPackage,
  packageId,
  setPackageId,
  setEditflag,
  data,
  index,
  setTitle,
  setActive,
  setTagRowData,
  setSelected,
  setSelectedData,
  setCancelPopup,
  // signingPackageId,
  // setSigningPackageId,
  getDocumentPackagesByPropertyIdDataRefetch,
  propertyId,
  // setShowSigningPackages,
  // setIsEditSigningPackages,
  getAllpropertiesList,
  menus,
  disabled,
  setConfigurationId,
  status,
  signingPackage,
}) => {
  const StatusDropdownData = [
    {
      value: 0,
      label: "Draft",
    },
    {
      value: 1,
      label: "Published",
    },
  ];
  const [anchorEl, setAnchorEl] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [openDocumetpackageMenu, setOpenDocumetpackageMenu] = useState({
    delete: false,
    duplicate: false,
  });
  const [signingStatus, setSigningStatus] = useState("");
  const [allChecked, setAllChecked] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [disableDeleteAction, setDisableDeleteAction] = useState(false);

  const handleReset = () => {
    setAllChecked([]);
  };

  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      packageTitle: "",
      whatToDuplicate: "",
    },
  });
  const {
    mutateAsync: PostCloneDocumentPackageMutate,
    isLoading: PostCloneDocumentPackageIsLoading,
  } = usePostCloneDocumentPackage();

  useEffect(() => {
    setSigningStatus({
      value: data?.is_published,
      label: StatusDropdownData.filter(
        (el) => el.value === data?.is_published
      )?.[0]?.label,
    });

    return () => {};
  }, [data]);

  // ---------------------------handleDuplicate---------------//
  const handleDuplicate = async (data) => {
    const duplicatePackagePayload = {
      document_package_id: packageId?.toString(),
      title: data?.packageTitle,
      clone_type: String(data?.whatToDuplicate?.value),
      property_id: allChecked,
    };

    // const response = await PostCloneDocumentPackageMutate(
    //   duplicatePackagePayload
    // );
    let id;
    if (!PostCloneDocumentPackageIsLoading) {
      id = toast.loading("Copying package...");
    }
    PostCloneDocumentPackageMutate(duplicatePackagePayload)
      .then((response) => {
        if (response.data?.bReturn) {
          getDocumentPackagesByPropertyIdDataRefetch();
          setOpenDocumetpackageMenu({
            ...openDocumetpackageMenu,
            duplicate: false,
          });
          setOpenDuplicateModal(false);
          setAllChecked([]);
          setTitle("");
          setActive(false);
          setTagRowData([]);
          setSelected([]);
          setSelectedData([]);
          setCancelPopup(false);
          toast.update(id, {
            render: response?.data?.message,
            isLoading: false,
            type: "success",
            autoClose: 2000,
          });
        } else {
          toast.update(id, {
            render: response?.data?.message,
            isLoading: false,
            type: "error",
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        toast.update(id, {
          render: error.message,
          isLoading: false,
          type: "error",
          autoClose: 2000,
        });
      });
  };
  // ---------------------------handleDelete---------------//
  const handleDeletePackage = () => {
    setDisableDeleteAction(true);
    DeleteDocumentPackageByPackageID(propertyId, packageId).then((response) => {
      toast.success(response.data.message);
      setOpenDocumetpackageMenu({
        ...openDocumetpackageMenu,
        delete: false,
      });
      getDocumentPackagesByPropertyIdDataRefetch();
      setDisableDeleteAction(false);
    });
  };

  //----------------- checked  property for packages--------------------//
  const handleOnChecked = (e, index) => {
    const { id, checked } = e.currentTarget;
    if (checked) {
      let newArry = [...allChecked, id];
      setAllChecked(newArry);
    } else {
      let newArry = allChecked.filter((item) => item != String(id));
      if (newArry.length != 0) {
        setAllChecked(newArry);
      } else {
        setAllChecked([]);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDeletePopUp(false);
    // setDropdownOpen(false);
  };

  //-------------handle status change---------------//
  const handleStatusChange = (e, docPackageId) => {
    const documentupdatePayload = {
      property_id: String(propertyId),
      document_package_id: String(docPackageId),
      is_publish: String(e.value),
    };
    PutUpdateSigningPackageStatus(documentupdatePayload)
      .then((response) => {
        if (response.data.bReturn) {
          toast.success(response.data.message);
          setSigningStatus({
            value: e.value,
            label: StatusDropdownData.filter((el) => el.value === e?.value)?.[0]
              ?.label,
          });
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleEditPackage = (id) => {
    setPackageId(id);
    setShowPackage(true);
    setEditflag(true);
  };

  const handleDuplicateOpen = (id) => {
    setOpenDuplicateModal(true);
    setPackageId(id);
  };

  const handleDeletePopup = (id) => {
    setOpenDocumetpackageMenu({
      ...openDocumetpackageMenu,
      delete: true,
    });
    // setSigningPackageId(data?.document_package_id);
    setPackageId(id);
  };

  return (
    <>
      <Col className="my-2 pb-2" lg={6} xl={4}>
        <SigningCard
          data={data}
          status={status}
          index={index}
          signingPackage={signingPackage}
          StatusDropdownData={StatusDropdownData}
          signingStatus={signingStatus}
          handleStatusChange={handleStatusChange}
          disabled={disabled}
          menus={menus}
          handleEditPackage={handleEditPackage}
          setConfigurationId={setConfigurationId}
          handleDuplicateOpen={handleDuplicateOpen}
          handleDeletePopup={handleDeletePopup}
        />

        {/* Delete document property */}
        <CustomPopup
          open={openDocumetpackageMenu.delete}
          onHide={() =>
            setOpenDocumetpackageMenu({
              ...openDocumetpackageMenu,
              delete: false,
            })
          }
          title="Delete document?"
          title_color="black"
          enableCrossButton={false}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
          deletePopUpStyle={true}
          titleTooltip={false}
        >
          <div className="p-3">
            <div className="px-2">
              <div className="fw-300 fs-14 my-1" style={{ height: "100px" }}>
                <p>Are you sure you want to delete the package?</p>
                <p>That will conflict with.....</p>
              </div>
            </div>
          </div>
          <div
            className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
            style={{
              backgroundColor: "#F9FAFA",
              borderRadius: " 0px 0px 8px 8px",
              border: "1px solid #F0F0F0",
            }}
          >
            <ETButton
              variant={"white"}
              buttonText="COLD FEET"
              onClick={() => {
                setOpenDocumetpackageMenu({
                  ...openDocumetpackageMenu,
                  delete: false,
                });
              }}
            />
            <ETButton
              variant={"primary"}
              buttonText="I'M SURE"
              disabled={disableDeleteAction}
              onClick={() => {
                handleDeletePackage(data?.document_package_id);
              }}
            />
          </div>
        </CustomPopup>
        {/* Delete document property */}

        <Duplicate
          openDuplicate={openDuplicateModal}
          setOpenDuplicate={setOpenDuplicateModal}
          propertyData={getAllpropertiesList?.data?.filter(
            (el) => el?.property_id !== propertyId
          )}
          handleDuplicate={handleDuplicate}
          allChecked={allChecked}
          setAllChecked={setAllChecked}
          disableDuplicateButton={PostCloneDocumentPackageIsLoading}
        />
      </Col>
    </>
  );
};

SigningPackageCard.defaultProps = {
  menus: true,
  status: true,
  signingPackage: true,
};
