import React from "react";

const EmptyMessage = () => {
  return (
    <div className="m-0 d-flex justify-content-center w-100 grey-text py-4 fst-italic " style={{ fontSize: '16px', fontFamily:"Roboto, Helvetica, Arial, sans-serif",fontWeight:"400",color: "rgba(0, 0, 0, 0.6)" }}>
      <p>No data available!!</p>
    </div>
  );
};

export default EmptyMessage;
