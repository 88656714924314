import { Get } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

export const GetLanguages = (header = null) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Language_GetLanguage, header)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
