export const ContactUnassignedTableData = [
  {
    Id: 1,
    name: "Louise Birch Justesen",
    attended_viewing: "17 Jun, 2023 at 12:00",
    reservation_created: "3 Jun, 2023 at 11:52",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "NOT CRITICAL",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
  },
  {
    Id: 2,
    name: "Harris Navarro",
    attended_viewing: "26 Jun, 2023 at 12:00",
    reservation_created: "12 Jun, 2023 at 10:03",
    signing_started: "26 Jun, 2023 at 12:00",
    signing_deadline: "26 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "CRITICAL",
    phone: "+45 22 57 16 36",
    email: "",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Contact",
  },
  {
    Id: 3,
    name: "Donovan Cummings",
    attended_viewing: "28 May, 2023 at 08:05",
    reservation_created: "28 May, 2023 at 08:05",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "CRITICAL",
    phone: "+45 22 57 16 36",
    email: "",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Contact",
  },
  {
    Id: 4,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "NOT CRITICAL",
    phone: "+45 22 57 16 36",
    email: "",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Contact",
  },
  {
    Id: 5,
    name: "Alexandros Luna",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "NOT CRITICAL",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Contact",
  },
  {
    Id: 6,
    name: "Victor Padilla Hurst",
    attended_viewing: "26 Jun, 2023 at 12:00",
    reservation_created: "12 Jun, 2023 at 10:03",
    signing_started: "26 Jun, 2023 at 12:00",
    signing_deadline: "26 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "CRITICAL",
    phone: "+45 22 57 16 36",
    email: "",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Private viewing",
  },
  {
    Id: 7,
    name: "Martha Mccormick",
    attended_viewing: "28 May, 2023 at 08:05",
    reservation_created: "28 May, 2023 at 08:05",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "CRITICAL",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Private viewing",
  },
  {
    Id: 8,
    name: "Rachael Cunningham",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "NOT CRITICAL",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
  },
  {
    Id: 9,
    name: "Francesco Cherry",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "NOT CRITICAL",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Private viewing",
  },
  {
    Id: 10,
    name: "Abdirahman Mcclure",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "NOT CRITICAL",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
  },
  {
    Id: 11,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "NOT CRITICAL",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
  },
  {
    Id: 12,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "NOT CRITICAL",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
  },
  {
    Id: 13,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "NOT CRITICAL",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
  },
];
export const ContactInProcessTableData = [
  {
    Id: 1,
    name: "Louise Birch Justesen",
    attended_viewing: "17 Jun, 2023 at 12:00",
    reservation_created: "3 Jun, 2023 at 11:52",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Feb, 2021 (690 days)",
  },
  {
    Id: 2,
    name: "Harris Navarro",
    attended_viewing: "26 Jun, 2023 at 12:00",
    reservation_created: "12 Jun, 2023 at 10:03",
    signing_started: "26 Jun, 2023 at 12:00",
    signing_deadline: "26 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
    status_time: "08:45:53",
    status_name: "UNDEFINED",
    phone: "+45 22 57 16 36",
    email: "",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Contact",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "1",
    signing_status: "4",
    Creditworthiness: "RARELY PAYS ON TIME",
    Tenant_since: "2 May, 2022 (763 days)",
  },
  {
    Id: 3,
    name: "Donovan Cummings",
    attended_viewing: "28 May, 2023 at 08:05",
    reservation_created: "28 May, 2023 at 08:05",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Oscar Odling",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Contact",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "2",
    signing_status: "4",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Sep, 2019 (1253 days)",
  },
  {
    Id: 4,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Ella-Louise Meyer",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Contact",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "1",
    signing_status: "4",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "3 Aug, 2020 (1403 days)",
  },
  {
    Id: 5,
    name: "Alexandros Luna",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Oscar Odling",
    status_time: "08:45:53",
    status_name: "UNDEFINED",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Contact",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "2 May, 2023 (96 days)",
  },
  {
    Id: 6,
    name: "Victor Padilla Hurst",
    attended_viewing: "26 Jun, 2023 at 12:00",
    reservation_created: "12 Jun, 2023 at 10:03",
    signing_started: "26 Jun, 2023 at 12:00",
    signing_deadline: "26 Jun, 2023 at 12:00",
    responsible_agent: "Ella-Louise Meyer",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Private viewing",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "2",
    signing_status: "3",
    Creditworthiness: "OFTEN PAYS ON TIME",
    Tenant_since: "1 Sep, 2019 (1253 days)",
  },
  {
    Id: 7,
    name: "Martha Mccormick",
    attended_viewing: "28 May, 2023 at 08:05",
    reservation_created: "28 May, 2023 at 08:05",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Ella-Louise Meyer",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Private viewing",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "1",
    signing_status: "2",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Feb, 2021 (690 days)",
  },
  {
    Id: 8,
    name: "Rachael Cunningham",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Robert Zamora",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "2",
    signing_status: "4",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "3 Aug, 2020 (1403 days)",
  },
  {
    Id: 9,
    name: "Francesco Cherry",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Lillie Joseph",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Private viewing",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "OFTEN PAYS ON TIME",
    Tenant_since: "2 May, 2023 (96 days)",
  },
  {
    Id: 10,
    name: "Abdirahman Mcclure",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Dominic Bruce",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Sep, 2019 (1253 days)",
  },
  {
    Id: 11,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Feb, 2021 (690 days)",
  },
  {
    Id: 12,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Feb, 2021 (690 days)",
  },
  {
    Id: 13,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Feb, 2021 (690 days)",
  },
];

export const InProcessTableData = [
  {
    Id: 1,
    name: "Louise Birch Justesen",
    attended_viewing: "17 Jun, 2023 at 12:00",
    reservation_created: "3 Jun, 2023 at 11:52",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Feb, 2021 (690 days)",
    archiving_date: "30 Sep, 2023",
    automated_deletion: "30 Dec, 2023",
    rental_period: "",
    archived_status: "POTENTIAL TENANT",
  },
  {
    Id: 2,
    name: "Harris Navarro",
    attended_viewing: "26 Jun, 2023 at 12:00",
    reservation_created: "12 Jun, 2023 at 10:03",
    signing_started: "26 Jun, 2023 at 12:00",
    signing_deadline: "26 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
    status_time: "08:45:53",
    status_name: "UNDEFINED",
    phone: "+45 22 57 16 36",
    email: "",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Contact",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "1",
    signing_status: "4",
    Creditworthiness: "RARELY PAYS ON TIME",
    Tenant_since: "2 May, 2022 (763 days)",
    archiving_date: "30 Sep, 2023",
    automated_deletion: "30 Dec, 2023",
    rental_period: "",
    archived_status: "LEAD",
  },
  {
    Id: 3,
    name: "Donovan Cummings",
    attended_viewing: "28 May, 2023 at 08:05",
    reservation_created: "28 May, 2023 at 08:05",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Oscar Odling",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Contact",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "2",
    signing_status: "4",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Sep, 2019 (1253 days)",
    archiving_date: "30 Sep, 2023",
    automated_deletion: "30 Dec, 2023",
    rental_period: "",
    archived_status: "ONGOING RESERVATION",
  },
  {
    Id: 4,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Ella-Louise Meyer",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Contact",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "1",
    signing_status: "4",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "3 Aug, 2020 (1403 days)",
    archiving_date: "30 Sep, 2023",
    automated_deletion: "30 Dec, 2023",
    rental_period: "",
    archived_status: "PRESENT TENANT",
  },
  {
    Id: 5,
    name: "Alexandros Luna",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Oscar Odling",
    status_time: "08:45:53",
    status_name: "UNDEFINED",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Contact",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "2 May, 2023 (96 days)",
    archiving_date: "30 Sep, 2023",
    automated_deletion: "30 Dec, 2023",
    rental_period: "23 Oct, 2023 - ",
    archived_status: "ONGOING SIGNING",
  },
  {
    Id: 6,
    name: "Victor Padilla Hurst",
    attended_viewing: "26 Jun, 2023 at 12:00",
    reservation_created: "12 Jun, 2023 at 10:03",
    signing_started: "26 Jun, 2023 at 12:00",
    signing_deadline: "26 Jun, 2023 at 12:00",
    responsible_agent: "Ella-Louise Meyer",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Private viewing",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "2",
    signing_status: "3",
    Creditworthiness: "OFTEN PAYS ON TIME",
    Tenant_since: "1 Sep, 2019 (1253 days)",
    archiving_date: "30 Sep, 2023",
    automated_deletion: "30 Dec, 2023",
    rental_period: "",
    archived_status: "FORMER TENANT",
  },
  {
    Id: 7,
    name: "Martha Mccormick",
    attended_viewing: "28 May, 2023 at 08:05",
    reservation_created: "28 May, 2023 at 08:05",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Ella-Louise Meyer",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Private viewing",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "1",
    signing_status: "2",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Feb, 2021 (690 days)",
    archiving_date: "30 Sep, 2023",
    automated_deletion: "30 Dec, 2023",
    rental_period: "1 Feb, 2023 - 30 Apr, 2023",
    archived_status: "FUTURE TENANT",
  },
  {
    Id: 8,
    name: "Rachael Cunningham",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Robert Zamora",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "2",
    signing_status: "4",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "3 Aug, 2020 (1403 days)",
    archiving_date: "30 Sep, 2023",
    automated_deletion: "30 Dec, 2023",
    rental_period: "17 Sep, 2023 - ",
    archived_status: "ONGOING SCREENING",
  },
  {
    Id: 9,
    name: "Francesco Cherry",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Lillie Joseph",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Private viewing",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "OFTEN PAYS ON TIME",
    Tenant_since: "2 May, 2023 (96 days)",
    archiving_date: "30 Sep, 2023",
    automated_deletion: "30 Dec, 2023",
    rental_period: "",
    archived_status: "LEAD",
  },
  {
    Id: 10,
    name: "Abdirahman Mcclure",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Dominic Bruce",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Sep, 2019 (1253 days)",
    archiving_date: "30 Sep, 2023",
    automated_deletion: "30 Dec, 2023",
    rental_period: "",
    archived_status: "LEAD",
  },
  {
    Id: 11,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Feb, 2021 (690 days)",
    archiving_date: "30 Sep, 2023",
    automated_deletion: "30 Dec, 2023",
    rental_period: "",
    archived_status: "LEAD",
  },
  {
    Id: 12,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Feb, 2021 (690 days)",
    archiving_date: "30 Sep, 2023",
    automated_deletion: "30 Dec, 2023",
    rental_period: "",
    archived_status: "LEAD",
  },
  {
    Id: 13,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Feb, 2021 (690 days)",
    archiving_date: "30 Sep, 2023",
    automated_deletion: "30 Dec, 2023",
    rental_period: "",
    archived_status: "LEAD",
  },
];
