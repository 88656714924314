import React from "react";
import { H2 } from "../../../styles/Common.style";
import { IconField } from "../../../Components/GroupText/IconTextField";
import { Col, Form, Row } from "react-bootstrap";
import ETButton from "../../../Components/Buttons/ETButton";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { Controller, useForm } from "react-hook-form";
import { REQUIRED_ERR } from "../../../utils/validationConstants";
import { useGetLanguages } from "../../../hooks/queries/uselanguageQueries";
import CustomReactSelect from "../../../Components/DropDown/CustomLanguageReactSelect";

const TellAboutYourself = ({ handleNext, customToken }) => {
  // -----react-query
  const { data: GetLanguagesData } = useGetLanguages(customToken);

  const languageOption = GetLanguagesData?.data?.map((language, index) => ({
    value: language?.Id,
    label: language?.Text,
  }));
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    clearErrors,
    reset,
    resetField,
    getFieldState,
    formState: { errors, isDirty, dirtyFields, isValid },
  } = useForm({});

  const handleSubmitFormThree = (data) => {
    handleNext(data);
  };
  return (
    <Form onSubmit={handleSubmit(handleSubmitFormThree)} className="p-3">
      <H2 className="mb-1">Create user</H2>
      <div className="light-font-text fs-12 pb-40"> Step 3 out of 5</div>
      <div className="light-font-text fw-700 pb-4">
        Tell us more about yourself
      </div>

      <div className="light-font-text pb-4">
        Tell us which company you work for and what your job title is. <br />
        This information is used and visible in the system for other users
      </div>
      <Row className="mb-3">
        <Col sm={8}>
          <Controller
            control={control}
            {...register("Workplace")}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <IconField
                type="text"
                id="Workplace"
                value={value}
                errorscope={true}
                iserror={errors?.Workplace}
                onChange={(e) => {
                  onChange(e);
                  clearErrors("Workplace");
                }}
                Icons={"fa-regular fa-building"}
                placeholder={"Workplace"}
              />
            )}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={8}>
          <Controller
            control={control}
            {...register("job_title")}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <IconField
                type="text"
                id="job_title"
                value={value}
                errorscope={true}
                iserror={errors?.job_title}
                onChange={(e) => {
                  onChange(e);
                  clearErrors("job_title");
                }}
                Icons={"fa-regular fa-user-doctor"}
                placeholder={"Job title"}
              />
            )}
          />
        </Col>
      </Row>
      <div className="light-font-text mb-3">
        Select your preferred language for the system.
      </div>
      <Row className="pb-40">
        <Col sm={8}>
          <Controller
            {...register("selectLanguage")}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <CustomReactSelect
                placeholder={"Language"}
                // inputValue={value}
                iserror={errors?.language}
                isSearchable={false}
                searchBarHeader="Search language"
                isMulti={false}
                onChange={(e) => onChange(e)}
                value={value}
                options={GetLanguagesData?.data?.map((item) => ({
                  value: item?.Id,
                  text: item?.Text,
                  label: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={item?.DataValue1}
                        alt={`${item?.Text} flag`}
                        style={{
                          marginRight: "8px",
                          width: "18px",
                          height: "12px",
                        }}
                      />
                      <span className="normal-font-text fs-14">
                        {item?.Text}
                      </span>
                    </div>
                  ),
                }))}
              />
            )}
          />
          {errors?.selectLanguage && (
            <p className="error-msg ">{REQUIRED_ERR}</p>
          )}
        </Col>
      </Row>
      <ETButton buttonText={"NEXT"} variant={"primary"} type={"submit"} />
    </Form>
  );
};

export default TellAboutYourself;
