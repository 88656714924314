import { Col, Row } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "../actorcustomers.css";
import ETButton from "../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../Components/CustomPopup";
import { GreenSpan } from "../../../../styles/Common.style";
import ScreeningSection from "./ScreeningSection";

const screeningAccrodionData = [
  {
    type: "Information",
    taskDone: 10,
    totalTask: 10,
    status: "edit",
    icon: "fa-solid fa-user",
    accordionStatus: "",
    accordionStatusInfo: "Done",
    upadateIcon: "fa-regular fa-circle-check",
    updateIconColor: "#77C970",
    btnName: "",
  },
  {
    type: "RKI",
    taskDone: 1,
    totalTask: 1,
    status: "edit",
    icon: "fa-solid fa-shield-halved",
    accordionStatus: "Status",
    accordionStatusInfo: "Declined",
    upadateIcon: "fa-regular fa-circle-exclamation",
    updateIconColor: "#F1664B",
    btnName: "",
  },
  {
    type: "Occupation",
    taskDone: 3,
    totalTask: 6,
    status: "fill out",
    icon: "fa-solid fa-briefcase",
    accordionStatus: "Up Next",
    accordionStatusInfo: "Company",
    btnName: "",
  },
  {
    type: "Economy",
    taskDone: 2,
    totalTask: 4,
    status: "fill out",
    icon: "fa-solid fa-building-columns",
    accordionStatus: "Up Next",
    accordionStatusInfo: "Total annual household income",
    btnName: "",
  },
  {
    type: "Household",
    taskDone: 5,
    totalTask: 5,
    status: "edit",
    icon: "fa-solid fa-house-user",
    accordionStatus: "Status",
    accordionStatusInfo: "Deviation",
    upadateIcon: "fa-regular fa-circle-exclamation",
    updateIconColor: "#F6E651",
    btnName: "",
  },
  {
    type: "Identification",
    taskDone: 1,
    totalTask: 2,
    status: "fill out",
    icon: "fa-solid fa-passport",
    accordionStatus: "Up Next",
    accordionStatusInfo: "Upload health insurance",
    btnName: "validated",
    validate: true,
  },
  // {
  //   type: "Salary slip",
  //   taskDone: 2,
  //   totalTask: 3,
  //   status: "fill out",
  //   icon: "fa-regular fa-file-lines",
  //   accordionStatus: "Up Next",
  //   accordionStatusInfo: "3rd salary slip",
  //   btnName: "validate",
  // },
  {
    type: "Additional information",
    status: "confirm",
    icon: "fa-solid fa-file-lines",
    accordionStatus: "Up Next",
    accordionStatusInfo: "Annual Statement",
    btnName: "approve",
  },
  {
    type: "Additional documents",
    status: "confirm",
    icon: "fa-solid fa-file-lines",
    accordionStatus: "Up Next",
    accordionStatusInfo: "Annual Statement",
    btnName: "approve",
  },
];

const screeningPdfUploadData = [
  {
    uploaded: true,
    fontIcon: "fa-regular fa-file-lines",
    pdfName: "1-salary-slip-jesper-thorkildsen.pdf",
    number: "st",
  },
  {
    uploaded: true,
    fontIcon: "fa-regular fa-file-lines",
    pdfName: "2-salary-slip-jesper-thorkildsen.pdf",
    number: "nd",
  },
  {
    uploaded: false,
    fontIcon: "fa-regular fa-file-lines",
    pdfName: "3-salary-slip-jesper-thorkildsen.pdf",
    number: "rd",
  },
];



const ScreenAccordion = ({
  getScreeningTemplateQuestionAnswerData,
  getScreeningTemplateQuestionData,
  setScreeningActor,
  screeningActor,
  screening_question,
}) => {
  //------------------states-------------------//
  const [activeAccordion, setActiveAccordion] = useState({});
  const [deletePdfPopUp, setDeletePdfPopUp] = useState(false);
  const [openScreeningPdf, setOpenScreeningPdf] = useState(false);
  const [viewDocumentPopup, setViewDocumentPopup] = useState({
    documentUrl: "",
    documentName: "",
    isViewDocumentPopupOpen: false,
  });

  const [
    filteredScreeningTemplateQnAData,
    setFilteredScreeningTemplateQnAData,
  ] = useState([]);


  useEffect(() => {
    setScreeningActor(
      getScreeningTemplateQuestionAnswerData?.data?.[0]?.actor_id
    );

    return () => {};
  }, [getScreeningTemplateQuestionAnswerData]);

  useEffect(() => {
    setFilteredScreeningTemplateQnAData(
      getScreeningTemplateQuestionAnswerData?.data?.filter(
        (item, i) => item?.actor_id == screeningActor
      )
    );

    return () => {};
  }, [screeningActor]);

  return (
    <div className=" my-3 radius-8 customers-card-layout">
      {/* <EmptyMessage /> */}
      <div
        className="d-flex p-3 gap-2"
        style={{
          borderBottom: "1px solid #f0f0f0",
        }}
      >
        {getScreeningTemplateQuestionAnswerData?.data?.map((actor, index) => (
          <div
            key={index}
            onClick={() => setScreeningActor(actor?.actor_id)}
            className={`${
              screeningActor == actor?.actor_id ? "bg-white" : "bg-grey"
            } radius-8 d-flex px-2 py-2 gap-2 lite-grey-border cursor-pointer`}
          >
            <GreenSpan className="form-label-title mb-0 ">
              {actor?.actor_name}
            </GreenSpan>
            <span className="grey-text light-font-text">
              Status: {actor?.completion_percentage} %
            </span>
          </div>
        ))}
      </div>

      <Row className="p-3">
        <Col>
          {screeningAccrodionData?.map((data, index) => (
            <React.Fragment key={index?.toString()}>
              <ScreeningSection
                data={data}
                eventKey={index?.toString()}
                filteredScreeningTemplateQnAData={
                  filteredScreeningTemplateQnAData
                }
                getScreeningTemplateQuestionData={
                  getScreeningTemplateQuestionData
                }
                screeningActor={screeningActor}
                screening_question={screening_question}
              />
            </React.Fragment>
          ))}
        </Col>
      </Row>

      {/* popup for deleting screening uplaoded pdf */}
      <CustomPopup
        open={deletePdfPopUp}
        onHide={() => setDeletePdfPopUp(false)}
        title="Are you sure you want to delete?"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
      >
        <div className="p-4 light-font-text">
          <p>
            Did you know that by deleting this document it will affect the
            ongoing screening process. After deleting the tenant will get
            notified and asked for a new upload of the document.
          </p>
        </div>
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => setDeletePdfPopUp(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="I’M SURE"
            onClick={() => setDeletePdfPopUp(false)}
          />
        </div>
      </CustomPopup>
      {/* popup for deleting screening uplaoded pdf */}

      {/* popup for opening pdf screening uploaded pdf */}
      <CustomPopup
        open={openScreeningPdf}
        onHide={() => setOpenScreeningPdf(false)}
        headerRequired={false}
      >
        <div className="p-3" style={{ height: "550px" }}>
          <iframe
            src="https://therichpost.com/sample.pdf#toolbar=0&navpanes=0&scrollbar=0"
            height="100%"
            width="100%"
          ></iframe>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          {/* <ETButton
                variant = {"white"}
            buttonText="COLD FEET"
            onClick={() => setDeletePdfPopUp(false)}
          />
          <ETButton
             variant={"primary"}
            buttonText="I’M SURE"
            onClick={() => setDeletePdfPopUp(false)}
          /> */}
        </div>
      </CustomPopup>
      {/* popup for opening pdf screening uploaded pdf */}

      {/* View document popup*/}
      <CustomPopup
        open={viewDocumentPopup?.isViewDocumentPopupOpen}
        onHide={() =>
          setViewDocumentPopup((prev) => ({
            ...prev,
            isViewDocumentPopupOpen: false,
          }))
        }
        modalHeaderText={true}
        title={"Document"}
        subTitle={viewDocumentPopup?.documentName}
        size="lg"
        title_color="black"
        enableCrossButton={true}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div>
          <Row>
            <Col className="">
              <div style={{ height: "540px" }}>
                <iframe
                  src={viewDocumentPopup?.documentUrl}
                  height="100%"
                  width="100%"
                ></iframe>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          {/* <ETButton
      variant={"white"}
    
        buttonText="CANCEL"
   
        onClick={() => setOpenViewDocumentPopUp(false)}
      />
      <ETButton
        variant={"primary"} 
        buttonText="SAVE"
        onClick={() => setOpenViewDocumentPopUp(false)}
      /> */}
        </div>
      </CustomPopup>
      {/* View document popup */}
    </div>
  );
};

export default ScreenAccordion;
