import { useEffect, useState } from "react";
import { Form, Button, Col, Row, ButtonGroup } from "react-bootstrap";
import GroupText from "../../../../Components/GroupText/GroupText";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import ETButton from "../../../../Components/Buttons/ETButton";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import GroupButtons from "../../../../Components/GroupButton/GroupButton";
import NormalGroupText from "../../../../Components/GroupText/NormalGroupText";
import { Controller, useForm } from "react-hook-form";
import { useAppState } from "../AllState";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import { H4 } from "../../../../styles/Common.style";

const Paymentandrent = ({
  handleNext,
  submitRef,
  prevRef,
  activeTenancyForm,
  getConfigurationDetailsData,
  handleEditConfig,
  previewRef,
}) => {
  const [state, setState] = useAppState();
  const [buttonStyle, setButtonStyle] = useState(0);
  const [currentTab, setCurrentTab] = useState(0);
  const {
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({ defaultValues: state[activeTenancyForm], mode: "onSubmit" });

  useEffect(() => {
    setValue(
      "annual_rent",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "annual_rent"
      )[0]?.actual_value
    );
    setValue(
      "rent_per_month",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "rent_per_month"
      )[0]?.actual_value
    );

    setValue(
      "aconto_residents_society",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "aconto_residents_society"
      )[0]?.actual_value
    );

    setValue(
      "fee_internet",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "fee_internet"
      )[0]?.actual_value
    );
    setValue(
      "fee_antenna",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "fee_antenna"
      )[0]?.actual_value
    );
    setValue(
      "aconto_cooling",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "aconto_cooling"
      )[0]?.actual_value
    );
    setValue(
      "aconto_electricity",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "aconto_electricity"
      )[0]?.actual_value
    );
    setValue(
      "aconto_water",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "aconto_water"
      )[0]?.actual_value
    );
    setValue(
      "aconto_heat",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "aconto_heat"
      )[0]?.actual_value
    );

    setValue(
      "total_paid",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "total_paid"
      )[0]?.actual_value
    );
    // setValue("due_date", {
    //   value: getConfigurationDetailsData?.[0]?.configuration_values?.filter(
    //     (data) => data.column_name == "due_date"
    //   )[0]?.actual_value?.value,
    //   label: getConfigurationDetailsData?.[0]?.configuration_values?.filter(
    //     (data) => data.column_name == "due_date"
    //   )[0]?.actual_value?.label,
    // });

    setValue("due_date", {
      value:
        DueDateData[
          getConfigurationDetailsData?.[0]?.configuration_values?.filter(
            (data) => data.column_name == "due_date"
          )[0]?.actual_value - 1
        ]?.value,
      label:
        DueDateData[
          getConfigurationDetailsData?.[0]?.configuration_values?.filter(
            (data) => data.column_name == "due_date"
          )[0]?.actual_value - 1
        ]?.label,
    });

    setValue(
      "due_date_frequenncy",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "due_date_frequenncy"
      )[0]?.actual_value == 2
        ? 1
        : 0
    );

    setValue(
      "payment_place",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "payment_place"
      )[0]?.actual_value
    );

    setValue(
      "other_5",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "other_5"
      )[0]?.actual_value
    );

    setValue(
      "other_6",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "other_6"
      )[0]?.actual_value
    );
    setValue(
      "other_value_1",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "other_value_1"
      )[0]?.actual_value
    );
    setValue(
      "other_value_2",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "other_value_2"
      )[0]?.actual_value
    );
    setValue(
      "tax_date",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "tax_date"
      )[0]?.actual_value
    );
    setValue(
      "account_1",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "account_1"
      )[0]?.actual_value
    );
    setValue(
      "account_2",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "account_2"
      )[0]?.actual_value
    );
    setValue(
      "bank",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "bank"
      )[0]?.actual_value
    );
  }, [getConfigurationDetailsData]);

  const DueDateData = [
    {
      label: "The first day",
      value: "1",
    },
    {
      label: "The last day",
      value: "2",
    },
  ];

  const saveData = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 3, true);
    // handleNext(3)
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };
  const preview = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 3, false);
    // handleNext(1);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };
  // saving all field into context state
  const prevSubmit = () => {
    setState({ ...state, [activeTenancyForm]: { ...getValues() } });
  };

  const handlechange = (id) => {
    setButtonStyle(id);
  };

  return (
    <>
      <Form className="main-form">
        {/* -----------Top Header----------- */}
        <div style={{ borderBottom: "1px solid #F0F0F0" }}>
          <div className="px-4 py-3">
            <H4 className="actors-section-title">§3 Payment of rent </H4>
            <div className="light-font-text" style={{ color: "#888888" }}>
              Lorem ipsum dolor sit lorem ipsum dolor sit
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #F0F0F0" }}>
          <div className="px-4 py-4">
            <div className="d-flex align-items-center">
              <span className="me-2 form-label-title">Rental </span>{" "}
              <CustomToolTip size="sm" text="Rental" />
            </div>
            <span className="light-font-text">The annual rent is </span>
            <Row className="my-2">
              <Col lg={4} md={8} sm={12}>
                <NormalGroupText
                  inputheight={"40px"}
                  textheight={"40px"}
                  // lable="Merged data"
                  htmlFor="Merged data"
                  graylabel="DKK"
                  textWidth="30%"
                  type="text"
                  disabled={true}
                  value={getValues("annual_rent")}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #F0F0F0" }}>
          <div className="px-4 py-4">
            <div className="d-flex align-items-center">
              <span className="me-2 form-label-title"> Payment </span>{" "}
              <CustomToolTip size="sm" text="Payment" />
            </div>
            <span className="light-font-text">
              The rent, etc. shall fall due for payment on
            </span>
            <Row className="mt-3">
              <Col lg={6} className="mb-sm-3 mb-lg-0">
                <Controller
                  control={control}
                  name="due_date"
                  rules={{ required: true }}
                  {...register("due_date")}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <ReactSelect
                        id={"due_date"}
                        placeholder={"Due date"}
                        value={value?.value ? value : ""}
                        iserror={errors.due_date}
                        options={DueDateData?.map((item) => ({
                          value: item?.value,
                          label: `${item?.label}`,
                        }))}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("due_date");
                        }}
                      />
                    </div>
                  )}
                />
              </Col>

              <Col lg={4} className="ps-lg-0 d-flex">
                <span className="me-2 light-font-text pt-2 w-50">in every</span>
                <Controller
                  control={control}
                  {...register("due_date_frequenncy")}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <GroupButtons
                        tabs={[
                          {
                            id: "1",
                            tabTitle: "Month",
                            // title: "Yes",
                            // content:
                            // "This page contains data related to Map milestone",
                          },
                          {
                            id: "2",
                            tabTitle: "Quarter",
                            // title: "No",
                            // content:
                            // "This page contains data related to Chart milestone",
                          },
                        ]}
                        currentTab={value}
                        handleTabClick={(tab) => {
                          setCurrentTab(tab);
                          setValue("due_date_frequenncy", tab);
                        }}
                      />
                    );
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div>
          <div className="px-4 pt-4 pb-2">
            <div className="d-flex align-items-center">
              <span className="me-2 form-label-title">
                The rent per month/quarter amounts to{" "}
              </span>{" "}
              <CustomToolTip
                size="sm"
                text="  The rent per month/quarter amounts to"
              />
            </div>
            <Row className="mt-3">
              <Col lg={4}>
                <NormalGroupText
                  inputheight={"40px"}
                  textheight={"40px"}
                  // lable="Merged data"
                  graylabel="DKK"
                  textWidth="30%"
                  type="text"
                  disabled={true}
                  value={getValues("rent_per_month")}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div>
          <div className="px-4 py-4">
            <div className="d-flex align-items-center">
              <span className="me-2 form-label-title">
                Payments other than the rent constitute{" "}
              </span>{" "}
              <CustomToolTip
                size="sm"
                text=" Payments other than the rent constitute"
              />
            </div>
            <Row className="mt-3">
              <Col lg={10}>
                <Row className="mb-2">
                  <Col lg={8}>
                    <span className="fw-300 fs-14">
                      A conto heating contribution{" "}
                    </span>
                  </Col>
                  <Col lg={4}>
                    <NormalGroupText
                      inputheight={"40px"}
                      textheight={"40px"}
                      // lable="Merged data"
                      graylabel="DKK"
                      textWidth="30%"
                      type="text"
                      disabled={true}
                      value={getValues("aconto_heat")}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={8}>
                    {" "}
                    <span className="light-font-text ">
                      A conto water contribution
                    </span>
                  </Col>
                  <Col lg={4}>
                    <NormalGroupText
                      inputheight={"40px"}
                      textheight={"40px"}
                      // lable="Merged data"
                      graylabel="DKK"
                      textWidth="30%"
                      type="text"
                      disabled={true}
                      value={getValues("aconto_water")}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={8}>
                    <span className="light-font-text ">
                      A conto electricity contribution
                    </span>
                  </Col>
                  <Col lg={4}>
                    <NormalGroupText
                      inputheight={"40px"}
                      textheight={"40px"}
                      // lable="Merged data"
                      graylabel="DKK"
                      textWidth="30%"
                      type="text"
                      disabled={true}
                      value={getValues("aconto_electricity")}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={8}>
                    {" "}
                    <span className="light-font-text ">
                      A conto cooling contribution{" "}
                    </span>
                  </Col>
                  <Col lg={4}>
                    <NormalGroupText
                      inputheight={"40px"}
                      textheight={"40px"}
                      // lable="Merged data"
                      graylabel="DKK"
                      textWidth="30%"
                      type="text"
                      disabled={true}
                      value={getValues("aconto_cooling")}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={8}>
                    {" "}
                    <span className="light-font-text ">
                      Antenna contribution{" "}
                    </span>
                  </Col>
                  <Col lg={4}>
                    <NormalGroupText
                      inputheight={"40px"}
                      textheight={"40px"}
                      // lable="Merged data"
                      graylabel="DKK"
                      textWidth="30%"
                      type="text"
                      disabled={true}
                      value={getValues("fee_antenna")}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={8}>
                    {" "}
                    <span className="light-font-text ">
                      Internet contributions{" "}
                    </span>
                  </Col>
                  <Col lg={4}>
                    <NormalGroupText
                      inputheight={"40px"}
                      textheight={"40px"}
                      // lable="Merged data"
                      graylabel="DKK"
                      textWidth="30%"
                      type="text"
                      disabled={true}
                      value={getValues("fee_internet")}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={8}>
                    {" "}
                    <span className="light-font-text ">
                      Contribution to resident representation{" "}
                    </span>
                  </Col>
                  <Col lg={4} className="mb-sm-2 mb-lg-0">
                    <NormalGroupText
                      inputheight={"40px"}
                      textheight={"40px"}
                      // lable="0,00"
                      graylabel="DKK"
                      textWidth="30%"
                      type="text"
                      disabled={true}
                      value={getValues("aconto_residents_society")}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={8}>
                    <Row>
                      <Col lg={12} className="mb-sm-3 mb-lg-0 pe-sm-4 pe-lg-3">
                        <div className="d-flex">
                          <span className=" pt-2 me-2 light-font-text ">
                            {" "}
                            Other<sup>*</sup>
                          </span>
                          <div className="form-input">
                            <input
                              className="mx-sm-0"
                              type="text"
                              {...register("other_5")}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-sm-2 mb-lg-0" lg={4}>
                    <Controller
                      control={control}
                      {...register("other_value_1")}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <NormalGroupText
                            inputheight={"40px"}
                            textheight={"40px"}
                            lable="0,00"
                            graylabel="DKK"
                            textWidth="30%"
                            type="number"
                            onChange={(e) => onChange(e)}
                            value={value}
                          />
                        );
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={8}>
                    <Row>
                      <Col lg={12} className="mb-sm-3 mb-lg-0 pe-sm-4 pe-lg-3">
                        <div className="d-flex">
                          <span className=" pt-2 me-2 light-font-text ">
                            {" "}
                            Other<sup>*</sup>
                          </span>
                          <div className="form-input">
                            <input
                              className="mx-sm-0"
                              type="text"
                              {...register("other_6")}
                            />
                          </div>{" "}
                        </div>{" "}
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={4}>
                    <Controller
                      control={control}
                      {...register("other_value_2")}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <NormalGroupText
                            inputheight={"40px"}
                            textheight={"40px"}
                            lable="0,00"
                            graylabel="DKK"
                            textWidth="30%"
                            type="number"
                            onChange={(e) => onChange(e)}
                            value={value}
                          />
                        );
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={8} className="pt-2">
                    {" "}
                    <span className="light-font-text ">
                      In total paid per month/quarter{" "}
                    </span>
                  </Col>
                  <Col lg={4}>
                    <NormalGroupText
                      inputheight={"40px"}
                      textheight={"40px"}
                      // lable="Merged data"
                      graylabel="DKK"
                      textWidth="30%"
                      type="text"
                      disabled={true}
                      value={getValues("total_paid")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <span className="light-font-text grey-text">
                      *Note that the law is the only legal basis for charging
                      amounts in addition to the rent, otherwise the amount must
                      be included in the rent.{" "}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <div className="px-4 py-3" style={{ borderTop: "1px solid #F0F0F0" }}>
          <div className="d-flex align-items-center">
            <span className="me-2 form-label-title">Taxes and fees</span>
            <CustomToolTip size="sm" text="Taxes and fees" />
          </div>
          <span className="me-2 light-font-text ">
            Included in the rent are taxes and fees as on
          </span>
          <Controller
            control={control}
            {...register("tax_date")}
            render={({ field: { onChange, value } }) => {
              return (
                <input
                  className="mx-sm-0 p-1 light-font-text radius-8"
                  type="date"
                  style={{
                    height: "26px",
                    border: "1px solid #d1d1d1",
                    outline: "none",
                  }}
                  onChange={(e) => onChange(e)}
                  value={value}
                />
              );
            }}
          />
          <br />
          <span className="me-2 light-font-text ">
            This date will provide the basis of any future changes to taxes and
            fees.
          </span>
        </div>
        <hr className="my-1" />
        <div className="px-4 py-3">
          <div className="d-flex align-items-center">
            <span className="me-2 form-label-title">Place of payment</span>
            <CustomToolTip size="sm" text="Place of payment" />
          </div>
          <div>
            <div className="d-flex gap-2">
              <div>
                <CustomCheckBox
                  className="d-inline-block mt-1"
                  type="radio"
                  value={1}
                  {...register("payment_place")}
                />
              </div>
              <div>
                <span className="me-2 light-font-text">
                  The rent etc. is paid to the landlord's account No.
                </span>
                <input
                  className="mx-sm-0 p-1  light-font-text radius-8 my-1"
                  type="text"
                  style={{
                    height: "26px",
                    border: "1px solid #d1d1d1",
                    outline: "none",
                  }}
                  {...register("account_1")}
                />
                <span className="mx-1">-</span>
                <input
                  className="mx-sm-0 p-1 light-font-text radius-8 my-1"
                  type="text"
                  style={{
                    height: "26px",
                    border: "1px solid #d1d1d1",
                    outline: "none",
                  }}
                  {...register("account_2")}
                />
                <span className="mx-1 light-font-text">in</span>
                <input
                  className="mx-sm-0 p-1 light-font-text radius-8 my-1"
                  type="text"
                  style={{
                    height: "26px",
                    border: "1px solid #d1d1d1",
                    outline: "none",
                  }}
                  {...register("bank")}
                />
                <span className="mx-1 ">:</span>
                <br />
                <span className="mx-1 light-font-text">
                  Payments made to a bank are considered payment at the
                  specified place of payment.
                </span>
              </div>
            </div>

            <div className="d-flex gap-2">
              <div>
                <CustomCheckBox
                  className="d-inline-block"
                  type="radio"
                  value={2}
                  {...register("payment_place")}
                />
              </div>
              <div className=" d-flex align-items-center ">
                <span className="mx-1 light-font-text">
                  The rent etc. is paid by direct debit (Betalingsservice).
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-1" />
        <div className="px-4 pt-2 pb-4">
          <div className="d-flex align-items-center">
            <span className="me-2 form-label-title">Note</span>
            <CustomToolTip size="sm" text="Note" />
          </div>
          <span className="light-font-text ">
            Any specific conditions relating to the rent determination, cf. the
            instructions, must be specified in section 11 of the tenancy
            agreement.
          </span>
        </div>
        <button
          type="submit"
          onClick={handleSubmit(saveData)}
          ref={submitRef}
          style={{ display: "none" }}
        />
        <button
          type="submit"
          onClick={handleSubmit(preview)}
          ref={previewRef}
          style={{ display: "none" }}
        />
      </Form>
    </>
  );
};

export default Paymentandrent;
