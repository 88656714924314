export const data = [
  {
    division_id: 10000,
    name: "Department 4",
    smtp_server: "",
    smtp_user_name: "",
    smtp_user_password: "",
    PropertiesCount: 1,
    UserCount: 0,
    created_by: "claus peterson",
    created_on: "2023-03-23 11:24:14",
    modified_by: "",
    modified_on: "2023-03-23 11:24:14",
  },
  {
    division_id: 10001,
    name: "Division for User 4",
    smtp_server: "",
    smtp_user_name: "",
    smtp_user_password: "",
    PropertiesCount: 127,
    UserCount: 121,
    created_by: "claus peterson",
    created_on: "2023-03-23 11:24:14",
    modified_by: "",
    modified_on: "2023-03-23 11:24:14",
  },
  {
    division_id: 10006,
    name: "Div Ten",
    smtp_server: "",
    smtp_user_name: "",
    smtp_user_password: "",
    PropertiesCount: 1,
    UserCount: 0,
    created_by: "claus peterson",
    created_on: "2023-03-23 11:24:14",
    modified_by: "",
    modified_on: "2023-03-23 11:24:14",
  },
  {
    division_id: 10011,
    name: "San Francisco",
    smtp_server: "",
    smtp_user_name: "",
    smtp_user_password: "",
    PropertiesCount: 0,
    UserCount: 0,
    created_by: "claus peterson",
    created_on: "2023-03-23 11:24:14",
    modified_by: "",
    modified_on: "2023-03-23 11:24:14",
  },
  {
    division_id: 10012,
    name: "Chennai",
    smtp_server: "",
    smtp_user_name: "",
    smtp_user_password: "",
    PropertiesCount: 0,
    UserCount: 0,
    created_by: "claus peterson",
    created_on: "2023-03-23 11:24:14",
    modified_by: "",
    modified_on: "2023-03-23 11:24:14",
  },
  {
    division_id: 10013,
    name: "Goa",
    smtp_server: "",
    smtp_user_name: "",
    smtp_user_password: "",
    PropertiesCount: 0,
    UserCount: 0,
    created_by: "claus peterson",
    created_on: "2023-03-23 11:24:14",
    modified_by: "",
    modified_on: "2023-03-23 11:24:14",
  },
  {
    division_id: 10014,
    name: "Home Frederikssund",
    smtp_server: "",
    smtp_user_name: "",
    smtp_user_password: "",
    PropertiesCount: 0,
    UserCount: 0,
    created_by: "claus peterson",
    created_on: "2023-03-23 11:24:14",
    modified_by: "",
    modified_on: "2023-03-23 11:24:14",
  },
  {
    division_id: 10015,
    name: "San francisco",
    smtp_server: "",
    smtp_user_name: "",
    smtp_user_password: "",
    PropertiesCount: 0,
    UserCount: 0,
    created_by: "claus peterson",
    created_on: "2023-03-23 11:24:14",
    modified_by: "",
    modified_on: "2023-03-23 11:24:14",
  },
  {
    division_id: 10017,
    name: "Home Sønderborg",
    smtp_server: "",
    smtp_user_name: "",
    smtp_user_password: "",
    PropertiesCount: 0,
    UserCount: 0,
    created_by: "claus peterson",
    created_on: "2023-03-23 11:24:14",
    modified_by: "",
    modified_on: "2023-03-23 11:24:14",
  },
  {
    division_id: 10033,
    name: "Division 1 of Organization EstateSolutions",
    smtp_server: "",
    smtp_user_name: "",
    smtp_user_password: "",
    PropertiesCount: 1,
    UserCount: 0,
    created_by: "claus peterson",
    created_on: "2023-03-23 11:24:14",
    modified_by: "",
    modified_on: "2023-03-23 11:24:14",
  },
];

export const welcomeMessage = [
  { id: 1, category: "Lead" },
  { id: 2, category: "Potential customer" },
  { id: 3, category: "Current customer" },
  { id: 4, category: "Former customer" },
];

export const screeningMessage = [
  { id: 1, category: "Canceled" },
  { id: 2, category: "Declined" },
  { id: 3, category: "Completed" },
  { id: 4, category: "Approved" },
];
