import React, { useEffect, useMemo, useState } from "react";
import {
  DropZoneBorder,
  GreenSpan,
  H2,
  H4,
} from "../../../styles/Common.style";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import MRTTheme from "../../../Components/table/MRTTheme";
import {
  SearchStyle,
  SortIcon,
} from "../../../Components/table/MRTGlobalProps";
import { SearchIcon } from "../../../Components/table/MRTGlobalProps";
import { paginationoptions } from "../../../Components/table/MRTGlobalProps";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import { Col, Form, Row } from "react-bootstrap";
import ETButton from "../../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { data } from "./StaticData";
import ShowTooltip from "../../../Components/Tooltip/ShowTooltip";
import Menus from "../../../Components/Menus/Menus";
import Toggle from "../../../Components/Switch/Switch";
import EditDocumentDetails from "./documentspopups/EditDocumentDetails";
import DeleteDocument from "./documentspopups/DeleteDocument";
import { useDropzone } from "react-dropzone";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CustomPopup from "../../../Components/CustomPopup";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { useOrganizationId } from "../../../hooks/stores/useOrganizationStore";
import {
  useGetDocumentByLevelTypeAndLevelId,
  usePostAddDocument,
  usePutUpdateDocumentInherited,
} from "../../../hooks/queries/useDocumentsQueries";
import moment from "moment";
import {
  ErrorAlert,
  LoadingAlert,
  SucessAlert,
} from "../../../Components/alerts/AlertsAndMessages";
import { status } from "../../../model/Constant";
import { REQUIRED_ERR } from "../../../utils/validationConstants";
import { useGetCommonType } from "../../../hooks/queries/useCommonTypeQueries";
import DocViewer from "./DocViewer";

const Documents = () => {
  // ----------------------- states ----------------------------//
  const [editDocumentDetails, setEditDocumentDetails] = useState({
    showPopup: false,
    documentData: [],
  });
  const [deleteDocument, setDeleteDocument] = useState({
    showPopup: false,
    documentId: "",
  });
  const [uploadDocument, setUploadDocument] = useState(false);
  const [previewDocument, setPreviewDocument] = useState({
    url: "",
    showPopup: false,
    fileName: "",
  });
  const [sorting, setSorting] = useState([]);
  console.log(previewDocument, "previewDocument");
  // --------------- used for removing added file from popups ----------- //
  useEffect(() => {
    remove();
    return () => {};
  }, [uploadDocument]);

  //---------------------------------- zustand ---------------------------//
  const organisationId = useOrganizationId((state) => state.organizationId);

  // ---------------------- React queries --------------------/
  const { data: GetDocumentByLevel, refetch: GetDocumentByLevelRefetch } =
    useGetDocumentByLevelTypeAndLevelId("o", organisationId);

  const {
    mutateAsync: PutUpdateDocumentInherited,
    isLoading: PutUpdateDocumentInheritedIsLoading,
  } = usePutUpdateDocumentInherited();

  const { data: GetCommonType } = useGetCommonType(null, "DOCTY");

  const {
    mutateAsync: PostUploadDocument,
    isLoading: PostUploadDocumentIsLoading,
  } = usePostAddDocument();

  // console.log(GetDocumentByLevel?.data, "GetDocumentByLevel");

  // ------------ react hooks forms
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({});

  // ---------- manged fields array---------
  const { fields, append, remove } = useFieldArray({
    control,
    name: "droppedPdf", // This should match the name in your form data
  });

  //----------- dropzone ------------
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles, fileRejections) => {
      acceptedFiles.forEach((file) => {
        append({
          name: file.name,
          document_type: "",
          file: file,
          inheritDocument: 0,
        });
      });
      fileRejections.map(({ file, errors }) => {
        toast.error("Invalid file type " + file.name);
      });
    },
    disabled: PostUploadDocumentIsLoading,
  });

  // ------------ const and functions used ----------------------------------//

  // --------------------- switch change function   ----------------------//
  const handleDocumentInherited = async (e, documentAllData) => {
    let id;
    // Construct a payload for the document update
    const documentInheritedPayload = {
      document_package_document_id: String(
        documentAllData?.document_package_document_id
      ),
      name: documentAllData?.file_name,
      document_type_id: String(documentAllData?.document_type_id),
      is_inherited: e.target.checked ? "1" : "0",
      organisation_id: organisationId?.toString(),
      division_id: String(documentAllData?.division_id),
      company_id: String(documentAllData?.company_id),
      property_id: String(documentAllData?.property_id),
    };

    // Display a loading toast if the document update is not in progress
    if (!PutUpdateDocumentInheritedIsLoading) {
      id = toast.loading(<LoadingAlert />);
    }

    try {
      // Make an asynchronous request to update the document
      const response = await PutUpdateDocumentInherited(
        documentInheritedPayload
      );

      // Check the response status and update the toast accordingly
      if (response.status === status.s && response.data.bReturn) {
        toast.update(id, {
          render: <SucessAlert />,
          isLoading: false,
          type: status.s,
          autoClose: 2000,
        });
      } else {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } catch (error) {
      // Handle errors during the document update
      toast.update(id, {
        render: <ErrorAlert />,
        isLoading: false,
        type: status.e,
        autoClose: 2000,
      });
    }
  };

  // ---------------------- handle add document ------------------------//
  const handleAddDocument = async (data) => {
    // console.log(data, "form submit data");
    let files = [];
    let successCount = 0;
    let id;

    try {
      // Show loading message before the loop
      id = toast.loading(
        <LoadingAlert
          countMessage={
            <>
              Uploading <span className="header-semibold">1</span> out of{" "}
              <span className="header-semibold">
                {data?.droppedPdf?.length}{" "}
                {data?.droppedPdf?.length === 1 ? "file" : "files"}
              </span>
              to the system
            </>
          }
        />,
        { autoClose: false }
      );

      for (let i = 0; i < data?.droppedPdf?.length; i++) {
        let formData = new FormData();
        formData.append("organisation_id", organisationId?.toString());
        formData.append("division_id", "0");
        formData.append("company_id", "0");
        formData.append("property_id", "0");
        formData.append("package_id", "0");
        formData.append(
          "document_type_id",
          data?.droppedPdf?.[i]?.document_type?.value
        );
        formData.append("document_template_file_id", "0");
        formData.append("is_inherit", data?.droppedPdf?.[i]?.inheritDocument);
        formData.append("files", data?.droppedPdf?.[i]?.file);
        formData.append("file_name", data?.droppedPdf?.[i]?.name); //this parameter files name not added in API but Umang

        try {
          const response = await PostUploadDocument(formData);

          if (response.status === status.s && response?.data?.bReturn) {
            files[i] = response?.data?.data?.[0];
            successCount = successCount + 1; // Increment success count
            // Update the loading toast message with real-time count
            toast.update(id, {
              render: (
                <LoadingAlert
                  countMessage={
                    <>
                      Uploading <span className="header-semibold">{i + 2}</span>{" "}
                      out of{" "}
                      <span className="header-semibold">
                        {data?.droppedPdf?.length}{" "}
                        {data?.droppedPdf?.length === 1 ? "file " : "files "}
                      </span>
                      to the system
                    </>
                  }
                />
              ),
              autoClose: false,
            });
          } else {
            // Handle error case
            // console.log("error message 1");
            toast.update(id, {
              render: <ErrorAlert />,
              isLoading: false,
              type: status.e,
              autoClose: 2000,
            });
          }
        } catch (error) {
          // Handle error case
          // console.log("error message 2");
          toast.update(id, {
            render: (
              <ErrorAlert
                errorAlertMessage={
                  <>
                    <div className="pb-2">
                      <span className="label-regular fs-14">
                        {data?.droppedPdf?.[i]?.name}
                      </span>{" "}
                      file could not be uploaded for an unknown reason
                      {data?.droppedPdf?.length > 1 &&
                        "and other files uploaded successfully."}
                    </div>
                    <div className="light-font-text">Please try again.</div>
                  </>
                }
              />
            ),
            isLoading: false,
            type: status.e,
            autoClose: 5000,
          });
        }
      }

      // Display summary toast with the total count
      // console.log(
      //   successCount === data?.droppedPdf?.length,
      //   "condition changing"
      // );
      {
        successCount === data?.droppedPdf?.length &&
          toast.update(id, {
            render: (
              <SucessAlert
                successMessage={
                  <>
                    <div className="pb-2">
                      {successCount} out of {data?.droppedPdf?.length}{" "}
                      {data?.droppedPdf?.length === 1 ? "file " : "files "}
                      uploaded successfully
                    </div>
                    We have now successfully finished the requested action and
                    you’re now ready to continue.
                  </>
                }
              />
            ),
            isLoading: false,
            type: status.s,
            autoClose: 2000,
          });
      }
      remove();
    } catch (error) {
      // Handle error case
      // console.log("error message 3");
      toast.update(id, {
        render: <ErrorAlert />,
        isLoading: false,
        type: status.s,
        autoClose: 2000,
      });
    } finally {
      // Close the loading toast after the loop
      setUploadDocument(false);
      toast.dismiss(id);
    }
  };

  // ---------------------- table columns ---------------------------//
  const columns = useMemo(
    () => [
      {
        // accessorKey: "type",
        accessorFn: (row) => `${row?.type}`,
        header: "Type",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
        Cell: ({ row }) => (
          <div className="d-flex justify-content-center gap-2 ">
            <ShowTooltip
              tooltipLabel={
                <>
                  {/* <button className="document-property-table-btn d-flex justify-content-center align-items-center"> */}
                  <FontAwesomeIcon
                    icon="fa-regular fa-file-pdf"
                    size="xl"
                    onClick={() =>
                      setPreviewDocument({
                        url: row?.original?.file_url,
                        showPopup: true,
                        fileName: row?.original?.file_name,
                      })
                    }
                  />
                  {/* </button> */}
                </>
              }
              tooltopWidth={80}
              tooltipContent={<div className="p-2 fs-12">Preview</div>}
            />
          </div>
        ),
      },
      {
        // accessorKey: "file_name",
        accessorFn: (row) => `${row?.file_name}`,
        header: "Name",
        enableHiding: false,
        enableGlobalFilter: true,
        size: 180,
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "document_type_name",
        header: "Document type",
        size: 170,
      },
      {
        // accessorKey: "date_of_upload",
        accessorFn: (row) => `${row?.date_of_upload}`,
        header: "Date of upload",
        size: 100,
        Cell: ({ row }) => (
          <div className="d-flex justify-content-between align-items-center">
            {row.original?.date_of_upload
              ? moment(row.original?.date_of_upload).format("DD MMM, YYYY ")
              : "-"}
          </div>
        ),
      },
      {
        accessorKey: "uploaded_by_name",
        header: "Uploaded by",
        size: 80,
      },
      {
        // accessorKey: "is_inherited",
        accessorFn: (row) => `${row?.is_inherited}`,
        header: "Inherit to divisions",
        size: 180,
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "20px !important",
          },
        },
        Cell: ({ row }) => (
          <div>
            <Toggle
              id={row?.original?.document_package_document_id}
              checked={row?.original?.is_inherited == 1 ? true : false}
              onChange={(e) => handleDocumentInherited(e, row?.original)}
            />
          </div>
        ),
      },
      {
        // accessorKey: "table_menu", //normal accessorKey
        accessorFn: (row) => `${row?.date_of_upload}`,
        header: " ",
        size: 50,
        enableSorting: false,
        Cell: ({ row }) => (
          <div className="d-flex gap-2">
            <ShowTooltip
              tooltipLabel={
                <button
                  style={{ height: "23px", width: "23px" }}
                  className="document-property-table-btn d-flex justify-content-center align-items-center"
                  onClick={() =>
                    setPreviewDocument({
                      url: row?.original?.file_url,
                      showPopup: true,
                      fileName: row?.original?.file_name,
                    })
                  }
                >
                  <FontAwesomeIcon
                    icon="fa-regular fa-file-magnifying-glass"
                    size="md"
                  />
                </button>
              }
              tooltopWidth={80}
              tooltipContent={<div className="p-2 fs-12">Preview</div>}
            />

            <ShowTooltip
              tooltipLabel={
                <button
                  style={{ height: "23px", width: "23px" }}
                  className="document-property-table-btn d-flex justify-content-center align-items-center"
                  onClick={() =>
                    setDeleteDocument({
                      showPopup: true,
                      documentId: row?.original?.document_package_document_id,
                    })
                  }
                >
                  <FontAwesomeIcon icon="fa-regular fa-trash" size="md" />
                </button>
              }
              tooltopWidth={80}
              tooltipContent={<div className="p-2 fs-12">Delete</div>}
            />
            <Menus
              itemheight={23}
              itemwidth={23}
              iconborderradius="4px"
              itemcontainerwidth="172px"
              component={
                <div
                  className="addpropertyCountryHandle "
                  style={{ fontSize: "14px", width: "198px" }}
                >
                  <div
                    className="addpropContent px-3 py-2 "
                    onClick={() =>
                      setPreviewDocument({
                        url: row?.original?.file_url,
                        showPopup: true,
                        fileName: row?.original?.file_name,
                      })
                    }
                  >
                    <span className="ms-1">View document</span>
                  </div>
                  <hr className="m-0" />
                  <div
                    className="addpropContent px-3 py-2 "
                    onClick={() => {
                      setEditDocumentDetails({
                        showPopup: true,
                        documentData: row?.original,
                      });
                    }}
                  >
                    <span className="ms-1">Edit details</span>
                  </div>
                  <hr className="m-0" />
                  <div
                    className="addpropContent px-3 py-2"
                    onClick={() =>
                      setDeleteDocument({
                        showPopup: true,
                        documentId: row?.original?.document_package_document_id,
                      })
                    }
                  >
                    <span className="ms-1">Delete</span>
                  </div>
                </div>
              }
            />
          </div>
        ),
      },
    ],
    []
  );

  const renderEmptyRowsFallback = () => (
    <div className="d-flex justify-content-center w-100 grey-text py-4 light-font-text">
      <div>
        You do not have any Document yet. Click on “+ UPLOAD DOCUMENT” to create
        your first.
      </div>
    </div>
  );
  return (
    <div className="whiteContainerStyle pb-5">
      {" "}
      {/* title section */}
      <div className="p-4 d-flex align-items-center  gap-2 border-bottom">
        <H2>Documents</H2>
        <CustomToolTip size="sm" text="Documents" />
      </div>
      <div className="px-2">
        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={columns?.map((columns) => {
                return {
                  ...columns,
                  sortDescFirst: false,
                };
              })}
              data={GetDocumentByLevel?.data || []}
              icons={{
                ArrowDownwardIcon: () => SortIcon?.ArrowDownwardIcon(sorting),
                SyncAltIcon: () => SortIcon?.SyncAltIcon(),
              }}
              state={{
                sorting: sorting,
              }}
              onSortingChange={setSorting}
              enableFilterMatchHighlighting={true}
              enableGlobalFilter
              renderEmptyRowsFallback={renderEmptyRowsFallback}
              enableMultiRowSelection={false}
              enableRowSelection={false}
              // enableRowSelection
              // onRowSelectionChange={setRowSelection}// to add u
              globalFilterFn="contains" //turn off fuzzy matching and use simple contains filter function
              enableRowActions={false}
              enableColumnActions={false}
              muiTableContainerProps={{
                sx: {
                  paddingBottom: "8px !important",
                },
              }}
              muiSearchTextFieldProps={{
                ...SearchStyle,

                InputProps: {
                  ...SearchIcon,
                },
              }}
              muiTablePaperProps={{
                sx: {
                  backgroundColor: "transparent",
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                },
              }}
              renderRowActions={false}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              renderTopToolbar={({ table }) => (
                <>
                  <div className="px-3 pt-4">
                    {" "}
                    <Row>
                      <Col xl={6}>
                        <div className=" form-label-title">
                          Documents on the organisation{" "}
                        </div>
                        <div className="grey-light-font-text">
                          Below are the documents uploaded directly on this
                          organisation.
                        </div>
                      </Col>
                      <Col xl={3} md={6} className="pe-3">
                        <MRT_GlobalFilterTextField table={table} />
                      </Col>
                      <Col xl={3} md={6} className="ps-lg-0">
                        <ETButton
                          onClick={() => {
                            setUploadDocument(true);
                          }}
                          variant={"primary"}
                          buttonText="UPLOAD DOCUMENT"
                          icon={true}
                          width="100%"
                          // disabled={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </>
              )}
              positionGlobalFilter="left"
            />
          }
        />
      </div>
      {/* --------------- edit document ----------------- */}
      <EditDocumentDetails
        editDocumentDetails={editDocumentDetails.showPopup}
        setEditDocumentDetails={setEditDocumentDetails}
        documentData={editDocumentDetails.documentData}
      />
      {/* --------------- delete document ----------- */}
      <DeleteDocument
        setDeleteDocument={setDeleteDocument}
        deleteDocument={deleteDocument?.showPopup}
        documentId={deleteDocument?.documentId}
      />
      {/*-------------- upload document ----------------------- */}
      <CustomPopup
        open={uploadDocument}
        // onHide={() => {
        //   setUploadDocument(false);
        // }}
        title="Upload document"
        title_color="black"
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Upload document"}
        size="lg"
      >
        <Form onSubmit={handleSubmit(handleAddDocument)}>
          <div className="p-3">
            <div className="px-2">
              <div className="light-font-text my-1">
                Drag and drop documents directly from your computer. It is
                possible to change the name of the document and select a
                document type. Once you have selected and customized the
                documents, click add and they will be added.
              </div>
            </div>
            <Row className="mt-3 px-2 upload-manually-document-table">
              <Col md={{ span: 12 }}>
                <div className="pt-2 pb-4">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <DropZoneBorder className="py-4 d-flex flex-column justify-content-center align-items-center">
                      <div>
                        <FontAwesomeIcon
                          icon="fa-solid fa-file-pdf"
                          style={{ color: "#000000" }}
                        />
                      </div>
                      <div className="mt-2 mb-1 form-label-title">
                        Drop your file here, or&nbsp;
                        <GreenSpan
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={open}
                        >
                          browse your computer
                        </GreenSpan>
                      </div>
                      <div className="created-by">Supports only PDF</div>
                    </DropZoneBorder>
                  </div>
                </div>
                <div>
                  <div className="mb-4">
                    <Row className="m-0 p-0">
                      <Row className="m-0 p-0 mb-2">
                        <Col
                          className="d-flex align-items-center py-2  actors-section-title fs-13 ps-3"
                          xs={5}
                        >
                          Name
                        </Col>
                        <Col
                          className="d-flex align-items-center py-2 actors-section-title fs-13 ps-0 "
                          xs={4}
                        >
                          Document type
                        </Col>

                        <Col
                          className="d-flex align-items-center gap-2 justify-content-center py-2 actors-section-title fs-13"
                          xs={2}
                        >
                          Inherit
                          <CustomToolTip
                            text={
                              "Select if this document should be inherited to all levels below."
                            }
                          />
                        </Col>
                      </Row>

                      <div
                        className="p-0"
                        style={{
                          maxHeight: "200px",
                          overflow: "auto",
                        }}
                      >
                        {/* {console.log(fields, "datafields")} */}
                        {fields?.length > 0 ? (
                          <>
                            {fields?.map((data, i) => {
                              return (
                                <div key={data.id}>
                                  <Row
                                    className="m-0 p-0 mb-2"
                                    style={{
                                      borderRadius: "8px",
                                      backgroundColor: "#F9FAFA",
                                    }}
                                  >
                                    <Col
                                      className="d-flex align-items-start py-2 pe-4"
                                      xs={5}
                                    >
                                      <div
                                        className="bg-white radius-6 ms-md-1 w-100 ellipsis "
                                        style={{
                                          padding: "0.3rem 0.5rem",
                                          border: "1px solid #D1D1D1",
                                          marginTop: "2px",
                                          height: "38px",
                                        }}
                                      >
                                        <p className="mb-0 fs-300 fs-14">
                                          <Controller
                                            name={`droppedPdf[${i}].name`}
                                            control={control}
                                            defaultValue={data.name}
                                            render={({
                                              field: { onChange, value },
                                            }) => (
                                              <input
                                                // disabled
                                                className="standard-input"
                                                // {...field}
                                                value={value}
                                                onChange={(e) => onChange(e)}
                                              />
                                            )}
                                          />
                                        </p>
                                      </div>
                                    </Col>
                                    <Col
                                      className="d-flex align-items-start py-2 pe-4 ps-0"
                                      xs={4}
                                    >
                                      <div className="w-100 fs-300 fs-14">
                                        <Controller
                                          {...register(
                                            `droppedPdf[${i}].document_type`
                                          )}
                                          rules={{ required: true }}
                                          render={({
                                            field: { onChange, value },
                                          }) => (
                                            <ReactSelect
                                              placeholder=""
                                              value={value}
                                              clearable={false}
                                              isSearchable
                                              disabled={
                                                PostUploadDocumentIsLoading
                                              }
                                              onChange={(e) => {
                                                onChange(e);
                                                clearErrors(
                                                  `droppedPdf[${i}].document_type`
                                                );
                                              }}
                                              error={errors.document_type}
                                              options={GetCommonType?.data?.map(
                                                (item) => ({
                                                  value: item?.Id,
                                                  label: item?.Text,
                                                })
                                              )}
                                            />
                                          )}
                                          name={`droppedPdf[${i}].document_type`}
                                          control={control}
                                        />

                                        {errors?.droppedPdf?.[i]
                                          ?.document_type && (
                                          <p className="error-msg">
                                            {REQUIRED_ERR}
                                          </p>
                                        )}
                                      </div>
                                    </Col>
                                    <Col
                                      className="d-flex align-items-center gap-2 justify-content-center py-2 actors-section-title fs-13"
                                      xs={2}
                                    >
                                      {" "}
                                      <Controller
                                        {...register(
                                          `droppedPdf[${i}].inheritDocument`
                                        )}
                                        control={control}
                                        render={({
                                          field: {
                                            onChange,
                                            value = parseInt(
                                              getValues(
                                                `droppedPdf[${i}].inheritDocument`
                                              )
                                            ),
                                          },
                                        }) => (
                                          <Toggle
                                            id={`droppedPdf[${i}].inheritDocument`}
                                            checked={value}
                                            onChange={(e) => {
                                              setValue(
                                                `droppedPdf[${i}].inheritDocument`,
                                                e.target.checked ? 1 : 0
                                              );
                                            }}
                                          />
                                        )}
                                      />
                                    </Col>
                                    <Col
                                      className="d-flex align-items-start py-2 justify-content-end"
                                      xs={1}
                                    >
                                      <div>
                                        <button
                                          className="added-document-delete-btn"
                                          onClick={() => remove(i)}
                                          disabled={PostUploadDocumentIsLoading}
                                        >
                                          <FontAwesomeIcon
                                            icon="fa-regular fa-trash"
                                            className="fs-6"
                                          />
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div
                            className="d-flex justify-content-center w-100 grey-text p-4 fs-12 light-font-text radius-4"
                            style={{ backgroundColor: "#f0f0f0" }}
                          >
                            You do not have any Document yet. Click on “ browse
                            your computer” or drop files to add your first.
                          </div>
                        )}
                      </div>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => {
                setUploadDocument(false);
              }}
            />
            <ETButton
              variant={"primary"}
              buttonText="ADD"
              type="submit"
              disabled={PostUploadDocumentIsLoading || fields?.length === 0}
            />
          </div>
        </Form>
      </CustomPopup>
      {/* Popup for preview a pdf of document property */}
      <CustomPopup
        open={previewDocument.showPopup}
        onHide={() =>
          setPreviewDocument({ ...previewDocument, showPopup: false })
        }
        headerRequired={false}
        size="lg"
      >
        <div
          style={documentPreviewTitle}
          className="d-flex justify-content-center align-items-center"
        >
          <H4>{previewDocument?.fileName}</H4>
        </div>
        <div >
          {/* <iframe
            src={previewDocument?.url}
            height="100%"
            width="100%"
          ></iframe> */}
          <DocViewer url={previewDocument?.url} />
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="close"
            onClick={() => {
              setPreviewDocument({ ...previewDocument, showPopup: false });
            }}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default Documents;

const documentPreviewTitle = {
  height: "111px",
  // borderBottom: "1px solid #D9D9D9",
  backgroundColor: "#F9F9F9",
  borderRadius: "8px 8px 0px 0px",
};
