import React, { useState } from "react";
import Menus from "../Menus/Menus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import CustomCheckBox from "../Checkbox/Checkbox";
import { Col, Row } from "react-bootstrap";
import CustomPopup from "../CustomPopup";
import styled from "styled-components";
import { useUserDetails } from './../../hooks/stores/useUserDetailsStore';
import { UTCtoLocalTimeZone } from "../../utils/UTCtoLocalTimeZone";

const DocumentCard = ({
  data,
  handleEditDocument,
  duplicatePackage,
  deletePackage,
  isTag,
  setDeletePopUp,
  checkValue,
  checkboxOnChange,
  isUnitEdit,
}) => {
  // const [mediaStyle, setMediaStyle] = useState(false);
  
  const [addDocumentPackagePopup, setAddDocumentPackagePopup] = useState(false);

  //------------- zustand ------------------//
  const { userDetails } = useUserDetails((state) => state);
  const { convertedDate: createdBy } = UTCtoLocalTimeZone({timestamp: data?.created_on, offset_mins: userDetails?.timezone_offset_mins, timeFormat: "DD. MMM. YYYY | HH:mm:ss"})
  const { convertedDate: modifiedBy } = UTCtoLocalTimeZone({timestamp: data?.modified_on, offset_mins: userDetails?.timezone_offset_mins, timeFormat: "DD. MMM. YYYY | HH:mm:ss"})

  const handleDocumentPdf = () => {
    setAddDocumentPackagePopup(true);
  };
  return (
    <CardsDiv
      className=" radius-8 align-items-center w-100"
      // mediaStyle={mediaStyle}
      isActive={checkValue?.length}
    >
      {/* -----------add tag cheackbox logic ---------------- */}
      {isTag === "AddTagD" && (
        <div className="d-flex justify-content-end">
          <CustomCheckBox
            // value={checkValue}
            checked={checkValue?.length}
            onChange={checkboxOnChange}
            // onClick={() => {
            //   setMediaStyle(!mediaStyle);
            // }}
            id={data?.document_package_id}
          />
        </div>
      )}

      <div
        className="p-3 text-break"
        style={{ borderBottom: "1px solid #F0F0F0" }}
      >
        {/*--------------edit units condition ---------------------  */}
        {!isUnitEdit ? (
          <div
            className="bg-white radius-6 p-3 light-font-text"
            style={{ border: "1px solid #F0F0F0", minHeight: "150px" }}
          >
            {data?.doc_files?.map(
              (item, index) =>
                index < 4 && (
                  <div key={index} className="pb-1 d-flex gap-2">
                    <div style={{ width: "5%" }}>
                      <FontAwesomeIcon icon="fa-regular fa-file-pdf" />
                    </div>
                    <div className="ellipsis" style={{ width: "95%" }}>
                      <p className=" fs-13 mb-0 ellipsis">{item?.name}</p>
                    </div>
                  </div>
                )
            )}
            {data?.doc_files?.length > 4 && (
              <div>
                <span className="fw-600 fs-13 text-dark">
                  +{data?.doc_files?.length - 4} document
                </span>
              </div>
            )}
          </div>
        ) : (
          <div
            className="bg-white radius-6 p-3 light-font-text"
            style={{ border: "1px solid #F0F0F0", minHeight: "50px" }}
          >
            <div
              className="pb-1 d-flex gap-2 cursor-pointer"
              onClick={handleDocumentPdf}
            >
              <div style={{ width: "5%" }}>
                <FontAwesomeIcon icon="fa-regular fa-file-pdf" />
              </div>
              <div className="ellipsis" style={{ width: "95%" }}>
                <p className=" fs-13 mb-0 ellipsis">{data?.name}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="p-3 fs-14" style={{ position: "relative" }}>
        {isTag && (
          <div className="fs-14 pb-2">
            <div className="form-label-title ">Source</div>
            <div className=" light-font-text">Document</div>
          </div>
        )}

        <div className="mb-2 ellipsis">
          <p className="mb-0  form-label-title">Package title</p>
          <span className="light-font-text text-break ellipsis">
            {data?.document_package_name}
          </span>
        </div>

        {/* -----------edit units props condition--------------- */}
        {isUnitEdit && (
          <div className="mb-2 ellipsis">
            <p className="mb-0  form-label-title">Document type</p>
            <span className="light-font-text text-break ellipsis">
              {data?.document_type_name}
            </span>
          </div>
        )}
        {/* ------------package AND Tag condition ----------------- */}
        {!isTag && (
          <div className="mb-2 ellipsis">
            <p className="mb-0 form-label-title">Tag</p>
            <span
              className="light-font-text text-break ellipsis d-inline-block"
              style={{ width: "90%" }}
            >
              {data?.tags?.split(",")?.join(", ") || "-"}
            </span>
          </div>
        )}

        <div className="  d-flex justify-content-between ">
          <div className="fs-12  pt-1 ">
            <div className="fs-12 form-label-title">Created by</div>
            <div className="created-by">
            {data?.created_by
                ? data?.created_by +
                  ", " +
                  createdBy
                : "-"}
            </div>
            <div className="fs-12 pt-1 form-label-title">Last updated by</div>
            <div className="created-by">
            {data?.modified_by
                ? data?.modified_by +
                  ", " +
                  modifiedBy
                : "-"}
            </div>
          </div>
          {isTag !== "AddTagD" && (
            <div className="d-flex align-items-end">
              {!isUnitEdit ? (
                <Menus
                  itemheight={23}
                  itemwidth={23}
                  iconborderradius="4px"
                  component={
                    <>
                      {isTag !== "D" ? (
                        <div
                          className="sign-in-package-menu light-font-text"
                          style={{ fontSize: "14px", width: "198px" }}
                        >
                          <div
                            className="px-3 py-2"
                            style={{ borderBottom: "1px solid #F0F0F0" }}
                            onClick={() =>
                              handleEditDocument(data?.document_package_id)
                            }
                          >
                            <span
                              className="ms-1 fs-14"
                              style={{
                                alignItems: "center",
                              }}
                            >
                              Edit package details
                            </span>
                          </div>
                          <div
                            className="px-3 py-2"
                            style={{ borderBottom: "1px solid #F0F0F0" }}
                            onClick={() =>
                              duplicatePackage(data?.document_package_id)
                            }
                          >
                            <span
                              className="ms-1"
                              style={{
                                fontSize: "14px",
                                alignItems: "center",
                              }}
                            >
                              Duplicate package
                            </span>
                          </div>
                          <div
                            className="px-3 py-2 "
                            onClick={() =>
                              deletePackage(data?.document_package_id)
                            }
                          >
                            <span
                              className="ms-1"
                              style={{
                                fontSize: "14px",
                                alignItems: "center",
                              }}
                            >
                              Delete package
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="addpropContent px-3 pt-2 pb-2 light-font-text"
                          onClick={() => setDeletePopUp(true)}
                        >
                          <span className="ms-1">Delete</span>
                        </div>
                      )}
                    </>
                  }
                />
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        <CustomPopup
          open={addDocumentPackagePopup}
          onHide={() => setAddDocumentPackagePopup(false)}
          modalHeaderText={true}
          title={data?.name}
          size="xl"
          title_color="black"
          enableCrossButton={true}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
          deletePopUpStyle={true}
          // subTitle={allDocumentDetails?.documentName}
          titleTooltip={false}
          // titleTooltipText={allDocumentDetails?.allDocumentPackageFileName}
        >
          <div>
            <Row>
              <Col className="">
                <div style={{ height: "540px" }}>
                  <iframe
                    src={data?.file_url}
                    height="100%"
                    width="100%"
                  ></iframe>
                </div>
              </Col>
            </Row>
          </div>
          <div
            className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
            style={{
              backgroundColor: "#F9FAFA",
              borderRadius: " 0px 0px 8px 8px",
              border: "1px solid #F0F0F0",
            }}
          ></div>
        </CustomPopup>
      </div>
    </CardsDiv>
  );
};

export default DocumentCard;

const CardsDiv = styled.div`
  background-color: #f9fafa;
  border-color: #f0f0f0;
  border-style: solid;
  border-width: 1px;
  ${(props) =>
    props.isActive &&
    `
  background-color:${props.theme.component.checkbox.primary.color + "11"};
   border-color: ${props.theme.component.checkbox.primary.color};
`}
`;
