import React from "react";
import { Col, Row, Container, Form, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import IconTextField from "../../../Components/GroupText/IconTextField";
import ETButton from "../../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../GeneralLogin.css";
import email from "../../../assets/images/Email.png";
import "@dotlottie/player-component";
import sendingEmail from "../../../assets/animations/sendingEmail.lottie";
const ResetPasswordEmailConfirmation = ({
  data,
  handleForgotScreen = () => {},
  userEmail = "",
}) => {
  const handlePrevious = () => {
    handleForgotScreen();
  };
  return (
    <>
      {data?.map((data, id) => (
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col
              sm={12}
              md={7}
              lg={6}
              xl={5}
              xxl={4}
              className="px-xl-2 px-sm-0"
            >
              <div
                className="StandardForm row"
                style={{
                  backgroundColor: data?.ContainerBackground,
                }}
              >
                <div className="px-3" style={{ marginTop: "40px" }}>
                  <Row className="m-auto">
                    <div
                      className="d-flex justify-content-center align-items-center mb-5 p-1"
                      md={{ span: 6, offset: 3 }}
                      xs={{ span: 4, offset: 4 }}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <Image
                          width={"160px"}
                          height={"auto"}
                          src={data?.logo}
                          alt="logo"
                        />
                      </div>
                    </div>
                  </Row>

                  <div
                    className="d-flex justify-content-center flex-column align-items-center px-4 mx-1 light-font-text fs-16"
                    style={{ paddingBottom: "0px", color: data?.Biocolor }}
                  >
                    <span>If an account exists for:</span>
                    <span>{userEmail ? userEmail : "Users username"}</span>
                    <span
                      className=" px-4  mb-4"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      you will get an email with instructions on how to reset
                      your password.
                    </span>
                    <span
                      className=" px-4 "
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Need more help, please contact our support team in
                      estatetool.
                    </span>
                  </div>

                  <div className="d-flex justify-content-center">
                    <div>
                      <dotlottie-player
                        src={sendingEmail}
                        autoplay
                        loop
                        style={{ height: "265px", width: "100%" }}
                      />
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-start light-font-text"
                    style={{}}
                  >
                    <div className="cursor-pointer" onClick={handlePrevious}>
                      <span className="d-flex light-font-text align-items-center gap-1 mb-3">
                        <FontAwesomeIcon
                          icon="fa-regular fa-arrow-left-long"
                          style={{
                            color: "#f1664b",
                            paddingTop: "2px",
                          }}
                        />
                        <span
                          style={{
                            color: data?.FormLinkandTextboxCheckColor,
                          }}
                        >
                          Didn’t receive an email, try again
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      ))}
    </>
  );
};

export default ResetPasswordEmailConfirmation;
