import { useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "moment/locale/en-gb";
import moment from "moment/moment";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./events.css";
import FollowUpEventsPopup from "./followUpEvent/FollowUpEventPopup";
import FollowUpPrivateEventPopup from "./followUpEvent/FollowUpPrivateEventPopup";
import { usePropertyId } from "../../hooks/stores/usePropertyStore";
import { useUserDetails } from "../../hooks/stores/useUserDetailsStore";
import {
  useGetEventsByPropertyId,
  useGetEventsDetailByEventId,
} from "../../hooks/queries/useEventsQueries";
import { H1 } from "../../styles/Common.style";

import { DeleteEvents } from "./followUpEvent/DeleteEvents";

const localizer = momentLocalizer(moment);

moment.locale("es-es", {
  week: {
    dow: 1, //Monday is the first day of the week.
  },
});

const eventPropGetter = (event) => {
  if (event.isNew) {
    // Apply custom background for new events
    return {
      className: "new-event",
    };
  }
  return {}; // Return empty object for other events
};

const formats = {
  dateFormat: "D", // Used 'D' to display the date as a single digit
};

const EventCalendar = ({
  setShowOpenHousePropertyFollowUp,
  setViewSection,
  setShowPrivateEvent,
  getEventsTypeData,
  getEventAgentDetailsData,
  selectedEventType,
  setSelectedEventType,
  selectedFilter,
  setSelectedFilter,
}) => {
  const calendarRef = useRef("");

  //-------------------state--------------------------//

  const [visibleMonth, setVisibleMonth] = useState("");

  const [openFollowUpEvent, setOpenFollowUpEvent] = useState(false);
  const [openHouseEventData, setOpenHouseEventData] = useState({
    eventId: "",
    eventCalendarEntryId: "",
  });
  const [openPrivateFollowUpEvent, setOpenPrivateFollowUpEvent] =
    useState(false);

  const [selectedOptionTwo, setSelectedOptionTwo] = useState(false);
  const [deleteShowModal, setDeleteShowModal] = useState(false);

  //-------------------Zustand--------------------------//

  const propertyId = usePropertyId((state) => state.propertyId);
  const AgentuserDetails = useUserDetails((state) => state.userDetails);
  const AgentuserId = Number(AgentuserDetails?.user_id);
  const EventType = 0;

  //-------------------react query--------------------------//

  const { data: GetEventsByPropertyId, refetch: GetEventsByPropertyIdRefetch } =
    useGetEventsByPropertyId(propertyId, EventType, AgentuserId);
  const { data: GetEventsDetailByEventId } = useGetEventsDetailByEventId(
    propertyId,
    openHouseEventData?.eventId,
    openHouseEventData?.eventCalendarEntryId
  );

  function formatEventsForCalendar(GetEventsByPropertyId) {
    return GetEventsByPropertyId?.data?.map((event) => {
      const {
        event_date,
        time_start,
        time_end,
        event_type_name,
        event_type_id,
        event_calendar_entry_id,
        event_id,
        responsible_agent_name,
      } = event;

      // Split the startTime and endTime into hours and minutes
      const startTimeParts = time_start.split(":");
      const endTimeParts = time_end.split(":");

      // Parse the event date
      const eventDateParts = event_date.split("-");
      const year = parseInt(eventDateParts[0]);
      const month = parseInt(eventDateParts[1]) - 1; // Adjust for 0-based month
      const day = parseInt(eventDateParts[2]);

      // Create Date objects in the desired format
      const start = new Date(
        year,
        month,
        day,
        parseInt(startTimeParts[0]),
        parseInt(startTimeParts[1])
      );
      const end = new Date(
        year,
        month,
        day,
        parseInt(endTimeParts[0]),
        parseInt(endTimeParts[1])
      );
      const title = event_type_name;
      const eventTypeId = event_type_id;
      const eventCalendarEntryId = event_calendar_entry_id;
      const eventId = event_id;
      const responsibleAgentName = responsible_agent_name;

      return {
        title,
        start,
        end,
        eventTypeId,
        eventCalendarEntryId,
        eventId,
        responsibleAgentName,
      };
    });
  }

  const formattedEvents = formatEventsForCalendar(GetEventsByPropertyId);

  const handleNavigate = (date, view) => {
    if (view === "month") {
      setVisibleMonth(date);
    }
  };
  const CustomToolbar = ({ label, date, onNavigate, view, onView }) => {
    const handleNext = () => {
      // Custom logic for the next button
      // For example, you can navigate to the next month
      if (view === "month") {
        onNavigate("next", moment(date).add(1, "month"));
      } else if (view == "week") {
        onNavigate("next", moment(date).add(1, "week"));
      } else if (view == "day") {
        onNavigate("next", moment(date).add(1, "day"));
      }
    };

    const handlePrev = () => {
      // Custom logic for the back button
      // For example, you can navigate to the previous month
      if (view === "month") {
        onNavigate("prev", moment(date).subtract(1, "month"));
      } else if (view == "week") {
        onNavigate("prev", moment(date).subtract(1, "week"));
      } else if (view == "day") {
        onNavigate("prev", moment(date).subtract(1, "day"));
      }
    };

    const handleViewChange = (view) => {
      onView(view);
    };

    return (
      <div className="rbc-toolbar pt-1">
        <Row style={{ width: "320px" }}>
          <Col className="pe-lg-0">
            <ReactSelect
              placeholder={"Type of event"}
              value={selectedEventType}
              options={getEventsTypeData?.data?.map((item) => ({
                value: item?.Text,
                label: item?.Text,
              }))}
              onChange={(selectEvent) => setSelectedEventType(selectEvent)}
            />
          </Col>
          <Col className="pe-lg-0">
            <ReactSelect
              placeholder={"Filter by agent"}
              options={getEventAgentDetailsData?.data?.map((item) => ({
                value: item?.full_name,
                label: item?.full_name,
              }))}
              value={selectedFilter}
              onChange={(selectedOption) => setSelectedFilter(selectedOption)}
            />
          </Col>
        </Row>
        <div className="d-flex  ">
          <button className="rbc-btn rbc-btn-nav-prev" onClick={handlePrev}>
            <FontAwesomeIcon
              icon="fa-regular fa-angle-left"
              style={{ color: "#000000" }}
            />
          </button>
          <H1 className="rbc-toolbar-label fs-16 mx-3">{label}</H1>
          <button className="rbc-btn rbc-btn-nav-next" onClick={handleNext}>
            <FontAwesomeIcon
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />
          </button>
        </div>

        <div className=" d-flex gap-1 justify-content-center align-items-center pt-1">
          <div className="rbc-btn-group">
            <button className="rbc-btn" onClick={() => handleViewChange("day")}>
              Day
            </button>
            <button
              className="rbc-btn mx-2"
              onClick={() => handleViewChange("week")}
            >
              Week
            </button>
            <button
              className="rbc-btn "
              onClick={() => handleViewChange("month")}
            >
              Month
            </button>
          </div>{" "}
          <span className="light-font-text fs-12 ms-1"> View as cards </span>
          <div
            onClick={() => {
              setViewSection(true);
            }}
            className="radius-4  cursor-pointer"
            style={{
              background: "#f9fafa",
              border: "1px solid #d1d1d1",
              padding: "3px 8px",
            }}
          >
            <FontAwesomeIcon
              icon="fa-regular fa-address-card"
              style={{ color: "#000000" }}
            />
          </div>{" "}
        </div>
      </div>
    );
  };

  const CustomEvent = ({ event }) => {
    // Used to Customize the rendering of the event component
    const startTime = moment(event.start).format("HH:mm");

    const endTime = moment(event.end).format("HH:mm");
    return (
      <>
        <div
          className="d-flex align-items-center"
          onClick={() => {
            if (event.title === "Private view") {
              setOpenPrivateFollowUpEvent(true);
            } else {
              setOpenFollowUpEvent(true);
              setOpenHouseEventData((prev) => {
                return {
                  ...prev,
                  eventCalendarEntryId: event?.eventCalendarEntryId,
                  eventId: event?.eventId,
                };
              });
            }
          }}
        >
          <div className="light-font-text fs-12 me-1 ">
            {" "}
            {startTime} - {endTime}
          </div>

          <div className="light-font-text fs-13 me-1 ">{event.time}</div>

          <div className="event-section-title fs-13">{event.title}</div>
          {/* {
            <div className="ms-2">
              <FontAwesomeIcon
                icon="fa-regular fa-arrows-repeat"
                style={{ color: "#000000" }}
              />
            </div>
          } */}
        </div>
      </>
    );
  };

  const filteredAgent = selectedFilter // filter the agent form calender
    ? formattedEvents?.filter(
        (event) => event?.responsibleAgentName === selectedFilter?.value
      )
    : formattedEvents;

  const filteredEvents = selectedEventType // filter the event type form calender
    ? formattedEvents?.filter(
        (event) => event?.title === selectedEventType?.value
      )
    : formattedEvents;

  // const CustomEventWrapper = ({ event, children }) => {
  //   // Check if there are multiple events on the same date
  //   const isMultipleEvents = event.start === ComparedDate;

  //   // Apply specific styling or behavior for multiple events
  //   const wrapperStyle = isMultipleEvents ? { backgroundColor: "red" } : {};

  //   return (
  //     <div className="new" style={wrapperStyle}>
  //       {children}
  //     </div>
  //   );
  // };

  const calendarConfig = {
    components: {
      event: CustomEvent,
      toolbar: CustomToolbar,
      // eventWrapper: CustomEventWrapper,
    },
  };

  return (
    <>
      <div
        className="mx-2 my-2 my-main-event-calendar"
        style={{ height: "747px" }}
      >
        <Calendar
          ref={calendarRef}
          eventPropGetter={eventPropGetter}
          toolbar={(toolbarProps) => <CustomToolbar {...toolbarProps} />}
          showToday={false}
          defaultView="month"
          views={["month", "week", "day"]}
          events={filteredAgent && filteredEvents}
          formats={formats}
          localizer={localizer}
          components={calendarConfig.components}
          onNavigate={handleNavigate}
        />
      </div>

      <FollowUpEventsPopup
        setShowOpenHousePropertyFollowUp={setShowOpenHousePropertyFollowUp}
        setOpenFollowUpEvent={setOpenFollowUpEvent}
        openFollowUpEvent={openFollowUpEvent}
        GetEventsByPropertyId={GetEventsByPropertyId}
        setSelectedOptionTwo={setSelectedOptionTwo}
        setShowModal={setDeleteShowModal}
        GetEventsDetailByEventId={GetEventsDetailByEventId}
      />

      <FollowUpPrivateEventPopup
        setOpenPrivateFollowUpEvent={setOpenPrivateFollowUpEvent}
        openPrivateFollowUpEvent={openPrivateFollowUpEvent}
        setShowPrivateEvent={setShowPrivateEvent}
      />

      <DeleteEvents
        selectedOptionTwo={selectedOptionTwo}
        close={setDeleteShowModal}
        open={deleteShowModal}
        GetEventsDetailByEventId={GetEventsDetailByEventId}
        propertyId={propertyId}
        openHouseEventData={openHouseEventData}
        setOpenFollowUpEvent={setOpenFollowUpEvent}
        GetEventsByPropertyIdRefetch={GetEventsByPropertyIdRefetch}
      />
    </>
  );
};

export default EventCalendar;
