import React, { useState } from "react";
import styled from "styled-components";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Button = styled.button`
  border: ${(props) =>
    props.active
      ? `1px solid ${props.theme.component.button.primary.bgColor}`
      : `1px Solid #D1D1D1`};
  outline: none;
  background-color: ${(props) =>
    props.active
      ? props.theme.component.button.primary.bgColor + "22"
      : "#FFFFFF"};
  color: ${(props) =>
    props.active ? props.theme.component.button.primary.bgColor : "#000000"};
  height: 40px;
  width: 140px;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  font-family: ${(props) =>
    props.active ? "Cerebri sans-medium" : "Cerebri sans-light"};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
    background-color: white;
    border: 1px solid #d1d1d1;
    color: #000000;
    font-family: Cerebri sans-light;
  }
`;

const MultipleButton = ({ value, onChange, btnText1, btnText2, disabled }) => {
  const handleClick = (newValue) => {
    if (newValue !== value) {
      onChange(newValue);
      //   console.log(value);
    }
  };

  return (
    <ButtonWrapper>
      <Button
        active={value === 1 ? true : false}
        onClick={() => handleClick(1)}
        disabled={disabled}
      >
        {btnText1}
      </Button>
      <Button
        active={value === 2 ? true : false}
        onClick={() => handleClick(2)}
        disabled={disabled}
      >
        {btnText2}
      </Button>
      {/* <Button
        active={value === 3 ? true : false}
        onClick={() => handleClick(3)}
        disabled={disabled}
      >
        {btnText3}
      </Button> */}
    </ButtonWrapper>
  );
};

export default MultipleButton;
MultipleButton.defaultProps = {
  btnText1: "Yes",
  btnText2: "No",
  disabled: false,
};
