import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ETIcon } from "../../styles/Common.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./dropzone.css";

const DropZone = ({
  setMyFile = () => { },
  usedFor = "image",
  initialFileName,
  // setIsMyFileUploaded = () => { },
}) => {
  // ------------------------------ const used in dropzone  ------------------------------------//
  const [isReceivedFile, setReceivedFile] = useState(false);
  const [receivedFileName, setReceivedFileName] = useState(initialFileName);

  const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } =
    useDropzone({
      // Disable click and keydown behavior
      accept:
        usedFor === "image"
          ? {
            "image/jpeg": [],
            "image/png": [],
          }
          : {
            "application/pdf": [".pdf"],
          },
      noClick: true,
      noKeyboard: true,
      multiple: false,
      maxSize: 10485760,
    });

  const files = acceptedFiles.map((file) => (
    <li key={file?.path}>
      {file?.path} - {(file?.size / 1048576).toFixed(2)} MB
    </li>
  ));
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      <ul>
        {errors.map((e) => (
          <li key={e.code} className="error-msg">
            {e.code == "file-too-large" ? "File is larger than 5MB" : e.message}
          </li>
        ))}
      </ul>
    </li>
  ));
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setReceivedFile(true);
      // setIsMyFileUploaded(true)
      setReceivedFileName(acceptedFiles[0]?.path);
      setMyFile(acceptedFiles);
    } else {
      setReceivedFile(false);
      // setIsMyFileUploaded(false)
      setReceivedFileName(initialFileName);
    }
  }, [acceptedFiles, initialFileName]);

  // console.log(acceptedFiles, "acceptedFiles files");
  return (
    <div className="container px-0">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div className=" p-3 gap-3 d-flex align-items-center et-dropzone-container justify-content-between">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <ETIcon className="fileIcon ">
                <FontAwesomeIcon
                  icon="fa-solid fa-file-lines"
                  flip="horizontal"
                />
              </ETIcon>
            </div>
            <div className=" fs-12 ps-3">
              {isReceivedFile || initialFileName ? (
                <div className="w-100 d-flex align-items-center fs-14 form-label-title">
                  {receivedFileName}
                </div>
              ) : (
                <>
                  {usedFor == "pdf" ? (
                    <>
                      Drop your file here, or browse your computer Only PDF
                      files (Max. file size 10 MB)
                    </>
                  ) : (
                    <>
                      {" "}
                      Drop your file here, or browse your computer Only JPEG,
                      PNG files (Max. file size 10 MB)
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div>
            <button className="et-dropzon-button" onClick={open} type="button">
              Browse
            </button>
          </div>
        </div>
      </div>
      <aside>
        <ul style={{ listStyleType: "none", margin: "0px", padding: "0px" }}>
          {/* {files} */}
          {/* {receivedFileName} */}
          {fileRejectionItems}
        </ul>
      </aside>
    </div>
  );
};
export default DropZone;
