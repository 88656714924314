// This component use for create and edit multiple units.  UI--> Suraj | last updated by--> Suraj || Api--> Dastgir | last updated by -->    //
import React, { useEffect } from "react";
import { Col, Container, Form, Image, Row } from "react-bootstrap";
import ETButton from "../../../../Components/Buttons/ETButton";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import GroupButtons from "../../../../Components/GroupButton/GroupButton";
import { useState } from "react";
import CustomPopup from "../../../../Components/CustomPopup";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import Papa from "papaparse";
import BasicSelect from "../../../../Components/DropDown/DropDown";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import twoHandPng from "../../../../assets/images/both-hand.png";
import "./multiunit.css";
import { useGetUserById } from "../../../../hooks/queries/useUserQueries";
import moment from "moment/moment";
import { json2csv } from "json-2-csv";
import RedReactSelect from "../../../../Components/DropDown/RedReactSelect";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as XLSX from "xlsx";
import { H1, H2, H4 } from "../../../../styles/Common.style";

import {
  useAddMultipleUnits,
  useGetEditUnitsTextField,
  useGetParkingLotDetails,
  useGetStorageRoomDetails,
  useGetUnitsByPropertyIdUpdated,
  usePutMultipleUnits,
} from "../../../../hooks/queries/useUnitQueries.js";
import { useForm, Controller } from "react-hook-form";
import {
  DropZoneBorder,
  GreenDiv,
  GreenSpan,
} from "../../../../styles/Common.style";
import { status } from "../../../../model/Constant";
import { useUnitType } from "../../../../hooks/stores/useUnitStore";
import { useGetPropertyWithOverviewByPropertyId } from "../../../../hooks/queries/usePropertyQueries";
import HighFive from "../../../../assets/animations/HighFive.lottie";
import "@dotlottie/player-component";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";

const MultipleUnits = ({
  operationName,
  name,
  unitsTableChange,
  propertyId,
  editmMultipleShow,
}) => {
  const { userDetails } = useUserDetails((state) => state);
  const { typeOfUnit } = useUnitType((state) => state);

  const [currentTab, setCurrentTab] = useState(0);
  const [csvComparePage, setCsvComparePage] = useState(false);
  const [finalPage, setFinalPage] = useState(false);
  const [addMultiplePage, setAddMultiplePage] = useState(false);
  const [editMultiplePage, setEditMultiplePage] = useState(false);
  const [csvErrorIndex, setCsvErrorIndex] = useState({});
  const [csvData, setCsvData] = useState([]);
  const [dataForApi, setDataForApi] = useState([]);
  const [disabledFinalizeImport, setDisabledFinalizeImport] = useState(false);
  const [csvDroped, setCsvDroped] = useState(false);
  const [missingCols, setMissingCols] = useState(false);
  const [missingColumnErr, setMissingColumnErr] = useState("");
  const [blankUnits, setBlankUnits] = useState(false);
  const [blankUnitsErr, setBlankUnitsErr] = useState("");
  const [wb, setWB] = useState({ SheetNames: [] });
  const [jsonData, setJsonData] = useState([]);
  const [fileType, setFileType] = useState("");
  const [disableContinue, setDisableContinue] = useState("");
  //Edit multiple units Query
  const {
    mutateAsync: PutMultipleUnits,
    isLoading: editMulLoading,
    isSuccess: editMultipleSuccess,
  } = usePutMultipleUnits();
  const {
    mutateAsync: PostMultipleUnits,
    isLoading: addMulLoading,
    isSuccess: addMultipleSuccess,
    isError: addMultipleError,
  } = useAddMultipleUnits();
  const { refetch: GetUnitsByPropertyIdDataRefetch } =
    useGetUnitsByPropertyIdUpdated(propertyId, 0);
  const { refetch: GetParkingLotDetailsDataRefetch } = useGetParkingLotDetails(
    propertyId,
    0
  );
  const { refetch: GetStorageRoomDetailsDataRefetch } =
    useGetStorageRoomDetails(propertyId, 0);
  const { refetch: RefetchGetPropertyWithOverviewByPropertyIdData } =
    useGetPropertyWithOverviewByPropertyId(propertyId, typeOfUnit);
  // react hooks form validation  //
  const {
    // register,
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
  } = useForm({
    defaultValues: {
      status: "",
    },
  });
  const onFormSubmit = (data) => {
    setCsvComparePage(true);
  };

  // State for dropdown of status
  const [statusDropdownId, setStatusDropdownId] = useState("");

  //CSV const onDrop = useCallback((acceptedFiles, fileRejections) => {
  // 	acceptedFiles?.forEach((file) => {
  // 		const reader = new FileReader();

  // 		reader.onload = async ({ target }) => {
  // 			const csv = Papa.parse(target.result, {
  // 				encoding: "UTF-8",
  // 				header: true,
  // 				skipEmptyLines: true,
  // 				complete: ({ data }) => {},
  // 			});
  // 			const parsedData = csv?.data;

  // 			let metaFields = csv?.meta.fields;
  // 			setCsvData(csv?.meta.fields);
  // 			setDataForApi(parsedData);
  // 		};
  // 		reader.readAsText(file);
  // 		setCsvDroped(true);
  // 	});
  // 	fileRejections.map(({ file, errors }) => {
  // 		toast.error("Invalid file type " + file.name);
  // 	});
  // }, []);

  // below code is used for showing file name recived in dropzone
  //CSV const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
  // 	// Disable click and keydown behavior
  // 	onDrop,
  // 	noClick: true,
  // 	noKeyboard: true,
  // 	accept: {
  // 		"text/csv": [".csv"],
  // 	},
  // });

  //CSV const files = acceptedFiles.map((file) => (
  // 	<li key={file.path}>
  // 		{file.path} - {file.size} bytes
  // 	</li>
  // ));

  const handleFileChange = async (files) => {
    const file = files[0];
    if (file?.name?.endsWith(".xlsx")) {
      setFileType("XLSX");
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          const data = event?.target?.result;

          const workbook = XLSX?.read(data);
          const worksheet = workbook?.Sheets[workbook?.SheetNames[0]];
          const json = XLSX?.utils?.sheet_to_json(worksheet);
          setJsonData(json);
          const fields = Object?.keys(json[0]);

          setCsvData(fields);
          setDataForApi(json);
          setWB(workbook);
        } catch (error) {
          console.error("Error reading XLSX file:", error);
        }
      };
      // Handle any potential errors during the reading process
      reader.onerror = (event) => {
        console.error("Error reading file:", event.target.error);
      };
      reader.readAsArrayBuffer(file);
      setCsvDroped(true);
    } else if (file?.name?.endsWith(".csv")) {
      setFileType("CSV");

      const reader = new FileReader();

      reader.onload = async ({ target }) => {
        try {
          const csv = Papa.parse(target?.result, {
            encoding: "UTF-8",
            header: true,
            skipEmptyLines: true,
            complete: ({ data }) => {},
          });
          const parsedData = csv?.data;
          setCsvData(csv?.meta?.fields);
          setDataForApi(parsedData);
        } catch (error) {
          console.error("Error while parsing the csv file :", error);
        }
      };
      // Handle any potential errors during the reading process
      reader.onerror = (event) => {
        console.error("Error reading file:", event.target.error);
      };
      reader.readAsText(file);
      setCsvDroped(true);
    }
  };
  const handleDisableContinue = () => {
    if (csvDroped) {
      setDisableContinue(false);
    } else if (csvDroped == false) {
      setDisableContinue(true);
    } else if (addMulLoading) {
      setDisableContinue(true);
    } else if (editMulLoading) {
      setDisableContinue(true);
    }
  };
  useEffect(() => {
    handleDisableContinue();
  }, [csvDroped, addMulLoading, editMulLoading]);
  // dropzone for xlsx file
  const {
    getRootProps: getRootProps,
    getInputProps: getInputProps,
    open: open,
    acceptedFiles: acceptedFiles,
  } = useDropzone({
    // Disable click and keydown behavior
    // accept: ".xlsx",
    accept: {
      "text/xlsx": [".xlsx"],
      "text/csv": [".csv"],
    },
    onDrop: handleFileChange,
    noClick: true,
    noKeyboard: true,
  });
  const files = acceptedFiles?.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  //React query for the global fields for matching
  const { data: getUnitTextFields } = useGetEditUnitsTextField(
    propertyId,
    typeOfUnit
  );
  let ExtraFields =
    editmMultipleShow && typeOfUnit === 1
      ? [
          { column_name: "latitude", is_import: 1 },
          { column_name: "longitude", is_import: 1 },
          { column_name: "unit_id", is_import: 1 },
        ]
      : editmMultipleShow === false && typeOfUnit === 1
      ? [
          { column_name: "latitude", is_import: 1 },
          { column_name: "longitude", is_import: 1 },
          { column_name: "unit_id" },
        ]
      : editmMultipleShow && typeOfUnit === 2
      ? [{ column_name: "parking_lot_id", is_import: 1 }]
      : editmMultipleShow === false && typeOfUnit === 2
      ? [{ column_name: "parking_lot_id" }]
      : editmMultipleShow && typeOfUnit === 3
      ? [{ column_name: "storage_room_id", is_import: 1 }]
      : editmMultipleShow === false &&
        typeOfUnit === 3 && [{ column_name: "storage_room_id" }];
  let csvCompareDataFromApi = [];
  function removeQuotes(inputString) {
    let cleanedString = "";
    for (let i = 0; i < inputString?.length; i++) {
      if (inputString[i] !== '"' && inputString[i] !== "'") {
        cleanedString += inputString[i];
      }
    }
    return cleanedString;
  }

  csvCompareDataFromApi = getUnitTextFields?.data?.concat(ExtraFields);
  const csvCompareData = csvCompareDataFromApi?.map((unit) => {
    const mappedUnit = { ...unit };

    // Convert unit_id to string
    mappedUnit.unit_id = removeQuotes(Number(mappedUnit?.unit_id));

    // Convert date columns to strings
    mappedUnit.vacancy_date = new Date(mappedUnit?.vacancy_date);
    mappedUnit.move_in_date = new Date(mappedUnit?.move_in_date);
    mappedUnit.move_out_date = new Date(mappedUnit?.move_out_date);
    mappedUnit.unit_construction_year = new Date(
      mappedUnit?.unit_construction_year
    );

    return mappedUnit;
  });

  let FilteredCsv = csvCompareData?.filter((ele) => ele?.is_import === 1);

  // the below code is for compare fields
  const res = csvData?.map((el1) => ({
    name: el1,
    match: FilteredCsv?.some(
      (el2) =>
        el2?.column_name?.toUpperCase().trim() == el1?.toUpperCase()?.trim()
    ),
    text:
      el1?.toLowerCase()?.replace(/_/g, " ")?.charAt(0)?.toUpperCase() +
      el1?.toLowerCase()?.replace(/_/g, " ")?.slice(1),
  }));
  let [estatetoolResCsv, setEstatetoolResCsv] = useState(res);
  const [csvFileDetails, setCsvFileDetails] = useState({});

  useEffect(() => {
    const importFields = res?.length;
    const allFieldsBoolean = res?.map((e) => e.match);
    const countMatchedValue = allFieldsBoolean.filter(Boolean).length;
    const countnotMatchedFields = importFields - countMatchedValue;

    setEstatetoolResCsv(res);
    setCsvFileDetails({
      importFields: importFields,
      allFieldsBoolean: allFieldsBoolean,
      countMatchedValue: countMatchedValue,
      countnotMatchedFields: countnotMatchedFields,
    });
  }, [csvData]);
  let wrongFieldInCsv = res
    .filter((value) => value?.match === false)
    .map((val) => val.name); // getting all wrong fields from CSV

  //  below function is used for converting csv data into lowercase //
  function keysToLowerCase(obj) {
    if (obj instanceof Array) {
      for (var i in obj) {
        obj[i] = keysToLowerCase(obj[i]);
      }
    }
    if (
      !typeof obj === "object" ||
      typeof obj === "string" ||
      typeof obj === "number" ||
      typeof obj === "boolean"
    ) {
      return obj;
    }
    var keys = Object.keys(obj);
    var n = keys?.length;
    var lowKey;
    while (n--) {
      var key = keys[n];
      if (key === (lowKey = key?.toLowerCase())) continue;
      obj[lowKey] = keysToLowerCase(obj[key]);
      delete obj[key];
    }
    return obj;
  }
  // csvDataInLowercass is contain data in lowercase propertyId added in csv
  let csvDataInLowercase = keysToLowerCase(dataForApi);

  const handleselectedOptions = (e, index) => {
    let isFieldNameRepetitive = estatetoolResCsv?.some(
      (val, i) => i !== index && val.name === e.value
    );
    if (!isFieldNameRepetitive) {
      setEstatetoolResCsv((prev) => [
        ...prev.map((val, i) => {
          if (i === index) {
            val.match = true;
            val.text =
              e.value.toLowerCase().replace(/_/g, " ").charAt(0).toUpperCase() +
              e.value.toLowerCase().replace(/_/g, " ").slice(1);
            val.name = e.value;
            val.changed = true;

            setCsvErrorIndex({
              ...csvErrorIndex,
              [index]: false,
            });
          }
          return val;
        }),
      ]);
    } else {
      setEstatetoolResCsv((prev) => [
        ...prev.map((val, i) => {
          if (i === index) {
            val.text =
              e.value.toLowerCase().replace(/_/g, " ").charAt(0).toUpperCase() +
              e.value.toLowerCase().replace(/_/g, " ").slice(1);
            val.name = e.value;
            val.match = false;
            val.changed = true;
          }
          return val;
        }),
      ]);
      setCsvErrorIndex({
        ...csvErrorIndex,
        [index]: true,
      });
    }
  };
  let mandatoryFields, mandatoryColumnNames, missingMandatory;
  useEffect(() => {
    setDisabledFinalizeImport(
      estatetoolResCsv.some((value) => value.match === false)
    );

    mandatoryFields = csvCompareDataFromApi?.filter(
      (fields) => fields?.is_mandatory === 1
    );
    mandatoryColumnNames = mandatoryFields?.map((item) => item.column_name);

    const csvCols = estatetoolResCsv?.map((item) => item.name);
    missingMandatory = mandatoryColumnNames?.filter(
      (mandatoryField) => !csvCols?.includes(mandatoryField)
    );
    setMissingMandatoryFields(missingMandatory);
    const importFields = estatetoolResCsv?.length;
    const allFieldsBoolean = estatetoolResCsv?.map((e) => e.match);
    const countMatchedValue = allFieldsBoolean?.filter(Boolean)?.length;
    const countnotMatchedFields = importFields - countMatchedValue;

    setCsvFileDetails({
      ...csvFileDetails,
      importFields: importFields,
      allFieldsBoolean: allFieldsBoolean,
      countMatchedValue: countMatchedValue,
      countnotMatchedFields: countnotMatchedFields,
    });
  }, [estatetoolResCsv]);

  const [successUnits, setSuccessUnits] = useState(0);
  const [errorUnits, setErrorUnits] = useState(0);
  const [errorUnitsCollection, setErrorUnitsCollection] = useState([]);
  const [missingMandatoryFields, setMissingMandatoryFields] = useState([]);

  let success = 0;
  let fail = 0;

  const AddMultipleUnits = async () => {
    let updated = csvDataInLowercase.map((data) => {
      wrongFieldInCsv.forEach((val, i) => {
        if (data.hasOwnProperty(val)) {
          let index = res.findIndex((resVal, i) => {
            if (resVal.name === val) return resVal;
          });
          data[estatetoolResCsv[index].name] = data[res[index].name];
          delete data[res[index].name];
        }
      });
      return data;
    });
    csvDataInLowercase = [...updated];
    const cleanedData = csvDataInLowercase?.map((item) => {
      const cleanedItem = { ...item }; // Create a copy of the item

      // Remove  quotes from unit_id
      try {
        cleanedItem.vacancy_date =
          cleanedItem?.vacancy_date == undefined ||
          cleanedItem?.vacancy_date == "" ||
          /^\d{4}-\d{2}-\d{2}$/.test(cleanedItem?.vacancy_date)
            ? ""
            : cleanedItem?.vacancy_date?.replace(/['"]/g, "")?.toString();

        cleanedItem.move_in_date =
          cleanedItem?.move_in_date == undefined ||
          cleanedItem?.move_in_date == "" ||
          /^\d{4}-\d{2}-\d{2}$/.test(cleanedItem?.move_in_date)
            ? ""
            : cleanedItem?.move_in_date?.replace(/['"]/g, "")?.toString();

        cleanedItem.move_out_date =
          cleanedItem?.move_out_date == undefined ||
          cleanedItem?.move_out_date == "" ||
          /^\d{4}-\d{2}-\d{2}$/.test(cleanedItem?.move_out_date)
            ? ""
            : cleanedItem?.move_out_date?.replace(/['"]/g, "")?.toString();

        cleanedItem.unit_construction_year =
          cleanedItem?.unit_construction_year == undefined
            ? ""
            : cleanedItem?.unit_construction_year
                ?.replace(/['"]/g, "")
                ?.toString();
        return cleanedItem;
      } catch (error) {}
    });
    const addMultipleunitsCsvData = {
      property_id: propertyId,
      unit_type: typeOfUnit,
      default_status_id: statusDropdownId,
      data: cleanedData,
    };
    if (addMulLoading) {
      console.log("addMulLoading out");
      toast.loading("Adding units...");
    }
    try {
      const result = await PostMultipleUnits(addMultipleunitsCsvData);
      console.log("addMulLoading", addMulLoading);
      if (addMulLoading) {
        console.log("addMulLoading in");
        toast.loading("Adding units...");
      }
      if (result?.status === status?.s && result?.data?.bReturn) {
        toast.success(result?.data?.message);
        GetUnitsByPropertyIdDataRefetch();
        GetParkingLotDetailsDataRefetch();
        GetStorageRoomDetailsDataRefetch();
        RefetchGetPropertyWithOverviewByPropertyIdData();
        setAddMultiplePage(true);
        result?.data?.data?.forEach((val, i) => {
          if (val?.status == "1") success += 1;
          else if (val?.status == "0") {
            fail += 1;
          }
          delete val.status;
          delete val.user_id;

          val.vacancy_date = `'${val?.vacancy_date}'`;
          val.move_in_date = `'${val?.move_in_date}'`;
          val.move_out_date = `'${val?.move_out_date}'`;
          val.unit_construction_year = `'${val?.unit_construction_year}'`;

          setErrorUnitsCollection((prev) => [...prev, val]);
          setErrorUnits(fail);
          setSuccessUnits(success);
        });
      } else if (
        result?.status === status?.s &&
        result?.data?.bReturn === false
      ) {
        if (result?.data?.data !== "") {
          GetUnitsByPropertyIdDataRefetch();
          GetParkingLotDetailsDataRefetch();
          GetStorageRoomDetailsDataRefetch();
          RefetchGetPropertyWithOverviewByPropertyIdData();
          setAddMultiplePage(true);

          result?.data?.data?.forEach((val, i) => {
            if (val?.status == "1") success += 1;
            else if (val?.status == "0") {
              fail += 1;
            }
            delete val.status;
            delete val.user_id;
            val.vacancy_date = `'${val?.vacancy_date}'`;
            val.move_in_date = `'${val?.move_in_date}'`;
            val.move_out_date = `'${val?.move_out_date}'`;
            val.unit_construction_year = `'${val?.unit_construction_year}'`;
            setErrorUnitsCollection((prev) => [...prev, val]);
            setErrorUnits(fail);
            setSuccessUnits(success);
          });
        } else if (result?.data?.data === "") {
          if (result?.data?.message === "Missing Columns") {
            setMissingCols(true);
            setMissingColumnErr(result?.data?.data?.missing_columns);
            setAddMultiplePage(true);
          } else if (result?.data?.message === "No unit found to add") {
            setBlankUnits(true);
            setBlankUnitsErr(result?.data?.message);
            setAddMultiplePage(true);
          }
        }
      }
    } catch (error) {}
  };

  const EditMultipleUnits = async () => {
    let updated = csvDataInLowercase.map((data) => {
      wrongFieldInCsv.forEach((val, i) => {
        if (data.hasOwnProperty(val)) {
          let index = res.findIndex((resVal, i) => {
            if (resVal.name === val) return resVal;
          });
          data[estatetoolResCsv[index].name] = data[res[index].name];
          delete data[res[index].name];
        }
      });
      return data;
    });
    csvDataInLowercase = [...updated];

    const cleanedData = csvDataInLowercase?.map((item) => {
      const cleanedItem = { ...item }; // Create a copy of the item

      // Remove  quotes from unit_id
      if (cleanedItem?.unit_id) {
        cleanedItem.unit_id = cleanedItem?.unit_id
          ?.replace(/['"]/g, "")
          ?.toString();
        cleanedItem.vacancy_date = cleanedItem?.vacancy_date
          ?.replace(/['"]/g, "")
          ?.toString();
        cleanedItem.move_in_date = cleanedItem?.move_in_date
          ?.replace(/['"]/g, "")
          ?.toString();
        cleanedItem.move_out_date = cleanedItem?.move_out_date
          ?.replace(/['"]/g, "")
          ?.toString();
        cleanedItem.unit_construction_year = cleanedItem?.unit_construction_year
          ?.replace(/['"]/g, "")
          ?.toString();
      } else if (cleanedItem?.parking_lot_id) {
        cleanedItem.parking_lot_id = cleanedItem?.parking_lot_id
          ?.replace(/['"]/g, "")
          .toString();
      } else if (cleanedItem?.storage_room_id) {
        cleanedItem.storage_room_id = cleanedItem?.storage_room_id
          ?.replace(/['"]/g, "")
          .toString();
      }

      return cleanedItem;
    });

    const editMultipleunitsCsvData = {
      property_id: propertyId,
      unit_type: typeOfUnit,
      default_status_id: statusDropdownId,
      data: cleanedData,
    };
    try {
      const result = await PutMultipleUnits(editMultipleunitsCsvData);
      if (result.status === status.s && result.data.bReturn) {
        toast.success(result.data.message);
        GetUnitsByPropertyIdDataRefetch();
        GetParkingLotDetailsDataRefetch();
        GetStorageRoomDetailsDataRefetch();
        setEditMultiplePage(true);
        result?.data?.data.forEach((val, i) => {
          if (val.status == 1) success += 1;
          else if (val.status == 0) {
            fail += 1;
          }
          delete val.status;
          delete val.user_id;
          setErrorUnitsCollection((prev) => [...prev, val]);
          setErrorUnits(fail);
          setSuccessUnits(success);
        });
      } else if (
        result?.status === status?.s &&
        result?.data?.bReturn === false
      ) {
        if (result?.data?.data !== "") {
          GetUnitsByPropertyIdDataRefetch();
          GetParkingLotDetailsDataRefetch();
          GetStorageRoomDetailsDataRefetch();
          RefetchGetPropertyWithOverviewByPropertyIdData();
          setEditMultiplePage(true);

          result?.data?.data?.forEach((val, i) => {
            if (val?.status == "1") success += 1;
            else if (val?.status == "0") {
              fail += 1;
            }
            delete val.status;
            delete val.user_id;
            val.vacancy_date = `'${val?.vacancy_date}'`;
            val.move_in_date = `'${val?.move_in_date}'`;
            val.move_out_date = `'${val?.move_out_date}'`;
            val.unit_construction_year = `'${val?.unit_construction_year}'`;
            setErrorUnitsCollection((prev) => [...prev, val]);
            setErrorUnits(fail);
            setSuccessUnits(success);
          });
        } else if (result?.data?.data === "") {
          if (result?.data?.message === "No unit found to update") {
            setBlankUnits(true);
            setBlankUnitsErr(result?.data?.message);
            setEditMultiplePage(true);
            setErrorUnits(1);
          } else if (result?.data?.message === "Missing Columns") {
            setMissingCols(true);
            setMissingColumnErr(result?.data?.data?.missing_columns);
            setAddMultiplePage(true);
          }
        }
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {}
  };

  const finalizeImportClick = () => {
    if (missingMandatoryFields?.length !== 0) {
      setDisabledFinalizeImport(true);
      setMissingCols(true);
    } else {
      setFinalPage(true);
      setCsvComparePage(false);
      if (operationName === "addMultipleUnits") {
        AddMultipleUnits();
      } else {
        EditMultipleUnits();
      }
    }
  };

  // const { data: getUserById } = useGetUserById();
  // let date_moment= moment(currentItem.date,"ddd MMM DD YYYY HH:mm:ss ZZ")
  let current_time = moment().format("ll | HH:mm:ss");

  const csvClick = () => {
    let json2csvCallback = function (err, csv) {
      if (err) throw err;
      const downloadFile = ({ data, fileName, fileType }) => {
        // Create a blob with the data we want to download as a file
        const blob = new Blob([data], { type: fileType });
        // Create an anchor element and dispatch a click event on it
        // to trigger a download
        const a = document.createElement("a");
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
      };
      downloadFile({
        data: csv,
        fileName: `units_complete_report.csv`,
        fileType: "text/csv",
      });
      setErrorUnitsCollection([]); // removing all data from error state so every time form should have latest error
    };
    json2csv(errorUnitsCollection, json2csvCallback); // JSON to CSV converting
  };

  return (
    <Container
      fluid
      className="px-0"
      style={{
        backgroundColor: "#FFFFFF",
        borderRadius: " 0 0 8px 8px",
        border: "1px solid #F4F5F5",
      }}
    >
      {csvComparePage === false ? (
        <>
          <Form onSubmit={handleSubmit(onFormSubmit)}>
            {/* <Row className="mt-3">
							<Col
								lg={3}
								className="mt-1 pt-3 d-flex justify-content-md-start justify-content-center align-items-start"
							>
								<div className="ms-md-4">
									<span className="ms-md-1 form-label-title">
										Upload your CSV file
									</span>
								</div>
							</Col>

							<Col lg={9} className="mt-1 p-4">
								<div className="px-4">
									<div className="container">
										<div {...getRootProps({ className: "dropzone" })}>
											<input {...getInputProps()} />
											<DropZoneBorder className=" py-4 d-flex flex-column justify-content-center align-items-center ">
												<div>
													<FontAwesomeIcon
														size="xl"
														icon="fa-solid fa-file-csv"
														style={{ color: "#000000" }}
													/>
												</div>
												<div className="mt-2 form-label-title">
													Drop your file here, or&nbsp;
													<GreenSpan
														style={{
															textDecoration: "underline",
															cursor: "pointer",
														}}
														onClick={open}
													>
														browse your computer
													</GreenSpan>
												</div>
												<div className="created-by">Supports only CSV</div>
											</DropZoneBorder>
										</div>
										<aside>
											<ul>{files}</ul>
										</aside>
									</div>
								</div>
								<div className="d-flex px-4 w-75">
									<div className="ps-2 ms-1 w-50">
										<GroupButtons
											tabs={[
												{
													id: 1,
													tabTitle: "Semicolon",
													title: "Semicolon",
													content:
														"This page contains data reflated to Map milestone",
												},
												{
													id: 2,
													tabTitle: "Comma",
													title: "Comma",
													content:
														"This page contains data related to Chart milestone",
												},
											]}
											height={"40px"}
											currentTab={currentTab}
											handleTabClick={(tab) => {
												setCurrentTab(tab);
											}}
										/>
									</div>
								</div>
								<div className="p-4 mt-4">
									<div className="px-2 ms-1 ">
										<div className="form-label-title fs-32">
											Need help with import?
										</div>

										<div className="light-font-text">
											<a href="https://apiet50.millicent.estatetool.net/templates/units/import_unit_template-v1.0.xlsx">
												<GreenSpan
													className="cursor-pointer form-label-title"
													style={{
														textDecoration: "underline",
													}}
												>
													Download
												</GreenSpan>
											</a>
											&nbsp;the latest import template or contact our support
											team
										</div>
									</div>
								</div>
							</Col>
						</Row>

						<div className="py-2 px-3 d-sm-flex justify-content-end footer-section-button-container">
							<div className="m-1">
								<ETButton
									variant={"primary"}
									buttonText="CONTINUE"
									disabled={csvDroped == true ? false : true}
									type={"submit"}
								/>
							</div>
						</div> */}
            <Row className="p-4">
              <Col
                lg={6}
                className="d-flex justify-content-md-start justify-content-center align-items-start"
              >
                <div>
                  <div>
                    <H4 className="pb-3">Upload your {fileType} file.</H4>
                    <div>
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <DropZoneBorder
                          className="py-4 d-flex flex-column justify-content-center align-items-center "
                          style={{
                            backgroundColor: "#F9FAFA",
                            width: "609px",
                            height: "162px",
                            border: "1px dashed #D1D1D1",
                          }}
                        >
                          <div className="whiteContainerStyle p-2">
                            <FontAwesomeIcon
                              size="xl"
                              icon="fa-solid fa-file-csv"
                              style={{ color: "#000000" }}
                            />
                          </div>
                          <div className="mt-2 form-label-title">
                            Drop your file here, or&nbsp;
                            <GreenSpan
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={open}
                            >
                              browse your computer
                            </GreenSpan>
                          </div>
                          <div className="created-by mt-1">
                            Supports only Xlsx/Csv
                          </div>
                        </DropZoneBorder>
                      </div>
                      <aside>
                        <ul>{files}</ul>
                      </aside>
                    </div>
                  </div>
                  <div className="d-flex w-50">
                    <div className="w-100">
                      <GroupButtons
                        tabs={[
                          {
                            id: 1,
                            tabTitle: "Semicolon",
                            title: "Semicolon",
                            content:
                              "This page contains data reflated to Map milestone",
                          },
                          {
                            id: 2,
                            tabTitle: "Comma",
                            title: "Comma",
                            content:
                              "This page contains data related to Chart milestone",
                          },
                        ]}
                        height={"40px"}
                        currentTab={currentTab}
                        handleTabClick={(tab) => {
                          setCurrentTab(tab);
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-5">
                    <div>
                      <H2>Need help with import?</H2>

                      <div className="light-font-text pt-2">
                        <a href="https://apiet50.millicent.estatetool.net/templates/units/import_unit_template-v1.0.xlsx">
                          <GreenSpan
                            className="cursor-pointer form-label-title"
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            Download
                          </GreenSpan>
                        </a>
                        &nbsp;the latest import template or contact our support
                        team
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="py-2 px-3 d-sm-flex justify-content-end footer-section-button-container">
              <div className="m-1">
                <ETButton
                  variant={"primary"}
                  buttonText="CONTINUE"
                  disabled={disableContinue}
                  type={"submit"}
                />
              </div>
            </div>
          </Form>
        </>
      ) : (
        <>
          <div>
            <div className="mt-4 " style={{ color: "#f1664b" }}>
              {missingMandatoryFields?.length !== 0 && missingCols && (
                <p className="p-3">
                  {`You have missed these mandatory fields: ${missingMandatoryFields?.join(
                    ", "
                  )}`}
                </p>
              )}
            </div>
            <div className="mt-4 ">
              <Row className="d-flex ms-2 form-label-title grey-text">
                <Col md={4} className=" ">
                  Field from {fileType}
                </Col>
                <Col md={1} className="px-0"></Col>
                <Col md={4} className="ps-0">
                  {" "}
                  Field in estatetool
                </Col>
              </Row>

              <div
                className="scrollable-container-y me-1 pb-5"
                style={{
                  height: "400px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {estatetoolResCsv?.map((item, i) => {
                  return (
                    <Row
                      key={res[i].name}
                      className="d-flex ms-2"
                      style={{ alignItems: "center" }}
                    >
                      <Col md={4} className="pe-0 my-3">
                        <BasicSelect
                          label={res[i].name}
                          size="small"
                          bordercolor="green"
                          disabled={true}
                        />
                      </Col>
                      {item.match === true ? (
                        <Col md={1} className="px-0">
                          <div className="divider"></div>
                        </Col>
                      ) : (
                        <Col md={1} className="px-0 d-flex">
                          <div className="divider-red w-50"></div>
                          <div style={{ width: "40px" }} className="">
                            <CustomToolTip
                              height="12px"
                              text={
                                <div className="ps-2 pe-1 py-2">
                                  <div className="form-label-title">
                                    Oh no!{" "}
                                  </div>
                                  <div className="light-font-text">
                                    Unfortunately we can’t recognize the field
                                    from your CSV-file. That’s a problem because
                                    we can’t upload the date to estatetool. Try
                                    to search among our existing fields and see
                                    if you can find a fitted match.
                                  </div>
                                  <div className="form-label-title pt-2">
                                    Are you still stucked?{" "}
                                  </div>
                                  <div className="light-font-text">
                                    Our support team is ready to help you. Hit
                                    the chat button in the corner and let us
                                    connect.
                                  </div>
                                </div>
                              }
                              iconcolor="red"
                            />{" "}
                          </div>
                        </Col>
                      )}

                      <Col md={4} className="ps-0  my-3 position-relative">
                        {item.match === true ? (
                          item.changed ? (
                            <>
                              <RedReactSelect
                                placeholder={
                                  !csvErrorIndex[i] && !item?.changed
                                    ? "WE CAN’T REGONIZE THE FIELD"
                                    : ""
                                }
                                onChange={(e) => handleselectedOptions(e, i)}
                                defaultValue={{
                                  value: item.name,
                                  label: item.name,
                                }}
                                options={FilteredCsv?.map((option, i) => {
                                  return {
                                    value: option?.column_name,
                                    label: `${option?.column_name}`,
                                    match: item?.match,
                                    isDisabled: estatetoolResCsv.some(
                                      (estCsv) =>
                                        option?.column_name === estCsv.name
                                    ),
                                  };
                                })}
                                searchable={true}
                              />

                              {csvErrorIndex[i] && (
                                <div className="position-absolute">
                                  <p
                                    className="mb-0 fs-13"
                                    style={{ color: "#F1664B" }}
                                  >
                                    field already selected
                                  </p>
                                </div>
                              )}
                            </>
                          ) : (
                            <BasicSelect
                              label={item.name}
                              size="small"
                              bordercolor="green"
                              disabled={true}
                            />
                          )
                        ) : (
                          <>
                            <RedReactSelect
                              placeholder={
                                !csvErrorIndex[i]
                                  ? "WE CAN’T REGONIZE THE FIELD"
                                  : ""
                              }
                              onChange={(e) => handleselectedOptions(e, i)}
                              options={FilteredCsv?.map((option) => {
                                return {
                                  value: option?.column_name,
                                  label: `${option?.column_name}`,
                                  isDisabled: estatetoolResCsv.some(
                                    (estCsv) =>
                                      option?.column_name === estCsv.name
                                  ),
                                };
                              })}
                              searchable={true}
                            />
                            {csvErrorIndex[i] && (
                              <div className="position-absolute">
                                <p
                                  className="mb-0 fs-13"
                                  style={{ color: "#F1664B" }}
                                >
                                  field already selected
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </Col>
                      {item?.match && (
                        <Col md={3} className="d-flex pe-0">
                          <CustomCheckBox
                            id={item.id}
                            name={item.text}
                            checked={item.match}
                          />
                          <div className="d-flex form-label-title align-items-center">
                            {item.text}
                          </div>
                        </Col>
                      )}
                    </Row>
                  );
                })}
              </div>
            </div>

            <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
              {" "}
              <ETButton
                variant={"white"}
                buttonText="CANCEL"
                onClick={() => unitsTableChange(false)}
              />
              <div className="d-flex">
                <div className="d-flex px-2 light-font-text align-items-center">
                  {csvFileDetails?.countMatchedValue} fields will be imported.{" "}
                  {csvFileDetails?.countnotMatchedFields} field will not be
                  imported.
                </div>
                <ETButton
                  variant={"primary"}
                  buttonText="FINALIZE IMPORT"
                  onClick={finalizeImportClick}
                  disabled={disabledFinalizeImport}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {/* add multiple units popup */}

      <CustomPopup
        open={addMultiplePage}
        onHide={() => {
          unitsTableChange(false);
          setAddMultiplePage(false);
        }}
        title={"Add multiple units"}
        title_color="black"
        titleFont="14px"
        titleTooltip={true}
        titleTooltipText={"Add multiple units"}
        size="xl"
        enableCrossButton={false}
        onCloseAction={() => {
          setFinalPage(false);
          alert("Action Peformed Successfuly.");
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={false}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <Container
          fluid
          className="px-0"
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "0 0 8px 8px",
          }}
        >
          <Row style={{ textAlign: "center" }} className=" px-0">
            <Col md={{ span: 8, offset: 2 }}>
              {successUnits != 0 ? (
                <>
                  <h1 className="pt-5 form-label-title fs-32">
                    Nice! The family just got bigger
                  </h1>

                  <div className="mx-4 px-lg-4 pb-3 light-font-text">
                    <p>
                      The two of us are really good teammates and together we
                      have now imported the data, created the new units and
                      securely stored their data on our servers.
                    </p>
                  </div>
                </>
              ) : (
                <div style={{ height: "100px" }}> </div>
              )}

              {addMulLoading && (
                <div className="mt-3 mx-4 px-lg-4">
                  <div
                    className="py-1"
                    style={{
                      backgroundColor: "#F9FAFA",
                      textAlign: "center",
                      borderRadius: "8px",
                      border: "1px solid #F4F5F5",
                      position: "relative",
                    }}
                  >
                    <GreenDiv
                      className=" pt-3"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      <span>Adding Multiple Units...</span>
                    </GreenDiv>

                    {/* //........................ CSV to JSON Download ......................................// */}
                  </div>
                </div>
              )}
              {addMultipleSuccess && (
                <div
                  className="mt-3 mx-4 px-lg-4"
                  style={{
                    backgroundColor: "#F9FAFA",
                    textAlign: "center",
                    borderRadius: "8px",
                    border: "1px solid #F4F5F5",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <div
                    className="py-1"
                    style={{
                      textAlign: "center",

                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      zIndex: "2",
                    }}
                  >
                    {addMulLoading && toast.loading("Adding Units...")}
                    {successUnits != 0 && (
                      <>
                        <GreenDiv className=" pt-3 light-font-text fs-20 w-100">
                          <span style={{ width: "100%" }}>
                            Give a warm welcome to the new{" "}
                            <H1 className=" fs-20 ">
                              {successUnits}{" "}
                              {csvDataInLowercase?.length === 1
                                ? "Unit"
                                : "units"}
                            </H1>{" "}
                            in estatetool
                          </span>
                        </GreenDiv>
                        <p className="created-by">
                          {operationName === "addMultipleUnits" ? (
                            <span>
                              Uploaded {current_time} by{" "}
                              {userDetails?.first_name} {userDetails?.last_name}
                            </span>
                          ) : null}
                        </p>
                      </>
                    )}
                    {errorUnits != 0 && (
                      <>
                        <GreenDiv className=" pt-3 light-font-text fs-20 w-100">
                          <span style={{ width: "100%" }}>
                            <>
                              <H1 className=" fs-20 ">
                                {successUnits}{" "}
                                {csvDataInLowercase?.length === 1
                                  ? "Unit"
                                  : "units"}
                              </H1>{" "}
                              added in estatetool
                            </>
                          </span>
                        </GreenDiv>
                      </>
                    )}

                    {missingCols && (
                      <>
                        <GreenDiv className=" pt-3 light-font-text fs-20 w-100">
                          <span style={{ width: "100%", display: "block" }}>
                            <>
                              <H1 className=" fs-20 ">
                                {successUnits}{" "}
                                {csvDataInLowercase?.length === 1
                                  ? "Unit"
                                  : "units"}
                              </H1>{" "}
                              added in estatetool.
                            </>
                          </span>
                          <span style={{ width: "100%" }}>
                            <>
                              You've missed these mandatory columns:
                              <H1 className=" fs-20 ">{missingColumnErr}</H1>
                            </>
                          </span>
                        </GreenDiv>
                      </>
                    )}

                    {blankUnits && (
                      <>
                        <GreenDiv className=" pt-3 light-font-text fs-20 w-100">
                          <span style={{ width: "100%" }}>
                            <>
                              <H1 className=" fs-20 ">{blankUnitsErr}</H1>
                            </>
                          </span>
                        </GreenDiv>
                      </>
                    )}

                    {errorUnits == 1 && (
                      <p>
                        {errorUnits} unit had errors while attempting to upload.
                      </p>
                    )}
                    {errorUnits > 1 && (
                      <p>
                        {errorUnits} units had errors while attempting to
                        upload.
                      </p>
                    )}
                    {/* //........................ CSV to JSON Download ......................................// */}
                    {errorUnits != 0 && (
                      <span className="d-flex justify-content-center">
                        <button
                          className=" pb-1"
                          style={{
                            border: "none",
                            textDecoration: "underline",
                            background: "transparent",
                            width: "fit-content",
                          }}
                          // todo
                          data={errorUnitsCollection}
                          onClick={csvClick}
                        >
                          <GreenSpan className="form-label-title">
                            Download error report here
                          </GreenSpan>
                        </button>
                      </span>
                    )}

                    {/* //........................ CSV to JSON Download ......................................// */}
                  </div>
                </div>
              )}

              {/* //........................Image Part ......................................// */}
              {successUnits != 0 ? (
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    height: "300px",
                  }}
                >
                  {" "}
                  <div style={{ position: "absolute", bottom: "0" }}>
                    <dotlottie-player
                      src={HighFive}
                      autoplay
                      loop
                      style={{ height: "500px", width: "100%" }}
                    />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    height: "300px",
                  }}
                ></div>
              )}
            </Col>
          </Row>
          <div className="py-2 px-3 d-sm-flex justify-content-end footer-section-button-container">
            <div className="m-1">
              <ETButton
                variant={"primary"}
                buttonText="GO TO UNITS"
                disabled={addMulLoading}
                onClick={() => unitsTableChange(false)}
              />
            </div>
          </div>
        </Container>
      </CustomPopup>
      {/* add multiple loading popup */}
      <CustomPopup
        open={addMulLoading || editMulLoading}
        title={"Add multiple units"}
        title_color="black"
        titleFont="14px"
        titleTooltip={true}
        titleTooltipText={"Add multiple units"}
        size="xl" // Set the size to "xl" to match the second popup
        enableCrossButton={false}
        onCloseAction={() => {
          setFinalPage(false);
          alert("Action Performed Successfully.");
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={false}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <Container
          fluid
          className="px-0"
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "0 0 8px 8px",
          }}
        >
          <Row style={{ textAlign: "center" }} className="px-0">
            <Col md={{ span: 8, offset: 2 }}>
              <div style={{ height: "300px" }}> </div>
            </Col>
            <Col md={{ span: 8, offset: 2 }} style={{ textAlign: "center" }}>
              <div className="mt-3 mx-4 px-lg-4 d-flex align-items-center justify-content-center">
                <div
                  className="py-1 d-flex align-items-center justify-content-center"
                  style={{
                    backgroundColor: "#F9FAFA",
                    textAlign: "center",
                    borderRadius: "8px",
                    border: "1px solid #F4F5F5",
                    position: "relative",
                    padding: "1rem",
                  }}
                >
                  <GreenDiv
                    className="pt-3"
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    {addMulLoading && <>Adding Multiple Units...</>}
                    {editMulLoading && <>Editing Multiple Units...</>}
                  </GreenDiv>
                </div>
              </div>
            </Col>
            <Col md={{ span: 8, offset: 2 }}>
              <div style={{ height: "300px" }}> </div>
            </Col>
          </Row>
        </Container>
      </CustomPopup>

      {/* {EDIT MULTIPLE UNITS} */}
      <CustomPopup
        open={editMultiplePage}
        onHide={() => {
          unitsTableChange(false);
          setEditMultiplePage(false);
        }}
        title={"Edit multiple units"}
        title_color="black"
        titleFont="14px"
        titleTooltip={true}
        titleTooltipText={"Edit multiple units"}
        size="xl"
        enableCrossButton={false}
        onCloseAction={() => {
          setFinalPage(false);
          alert("Action Peformed Successfuly.");
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={false}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <Container
          fluid
          className="px-0"
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "0 0 8px 8px",
          }}
        >
          <Row style={{ textAlign: "center" }} className=" px-0">
            <Col md={{ span: 8, offset: 2 }}>
              {successUnits != 0 ? (
                <>
                  <h1 className="pt-5 form-label-title fs-32">
                    Nice! The family just got bigger
                  </h1>

                  <div className="mx-4 px-lg-4 pb-3 light-font-text">
                    <p>
                      The two of us are really good teammates and together we
                      have now imported the data, updated the units and securely
                      stored their data on our servers.
                    </p>
                  </div>
                </>
              ) : (
                <div style={{ height: "100px" }}></div>
              )}

              {editMulLoading && (
                <div className="mt-3 mx-4 px-lg-4">
                  <div
                    className="py-1"
                    style={{
                      backgroundColor: "#F9FAFA",
                      textAlign: "center",
                      borderRadius: "8px",
                      border: "1px solid #F4F5F5",
                      position: "relative",
                    }}
                  >
                    <GreenDiv
                      className=" pt-3"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      <span>Updating Multiple Units...</span>
                    </GreenDiv>

                    {/* //........................ CSV to JSON Download ......................................// */}
                  </div>
                </div>
              )}
              {/* ------------------data showing after creating Property---------------------------- */}
              {editMultipleSuccess && (
                <div
                  className="mt-3 mx-4 px-lg-4"
                  style={{
                    backgroundColor: "#F9FAFA",
                    textAlign: "center",
                    borderRadius: "8px",
                    border: "1px solid #F4F5F5",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <div
                    className="py-1"
                    style={{
                      textAlign: "center",

                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      zIndex: "2",
                    }}
                  >
                    {editMulLoading && toast.loading("Editing Units...")}
                    {successUnits != 0 && (
                      <>
                        <GreenDiv className=" pt-3 light-font-text fs-20 w-100">
                          <span style={{ width: "100%" }}>
                            Updated{" "}
                            <H1 className=" fs-20 ">
                              {successUnits}{" "}
                              {csvDataInLowercase?.length === 1
                                ? "Unit"
                                : "units"}
                            </H1>{" "}
                            in estatetool
                          </span>
                        </GreenDiv>
                        <p className="created-by">
                          {operationName === "editMultipleUnits" ? (
                            <span>
                              Uploaded {current_time} by{" "}
                              {userDetails?.first_name} {userDetails?.last_name}
                            </span>
                          ) : null}
                        </p>
                      </>
                    )}
                    {errorUnits != 0 && (
                      <>
                        <GreenDiv className=" pt-3 light-font-text fs-20 w-100">
                          <span style={{ width: "100%" }}>
                            <H1 className=" fs-20 ">
                              {successUnits}{" "}
                              {csvDataInLowercase?.length === 1
                                ? "Unit"
                                : "units"}
                            </H1>{" "}
                            updated in estatetool
                          </span>
                        </GreenDiv>
                      </>
                    )}
                    {missingCols && (
                      <>
                        <GreenDiv className=" pt-3 light-font-text fs-20 w-100">
                          <span style={{ width: "100%", display: "block" }}>
                            <>
                              <H1 className=" fs-20 ">
                                {successUnits}{" "}
                                {csvDataInLowercase?.length === 1
                                  ? "Unit"
                                  : "units"}
                              </H1>{" "}
                              added in estatetool.
                            </>
                          </span>
                          <span style={{ width: "100%" }}>
                            <>
                              You've missed these mandatory columns:
                              <H1 className=" fs-20 ">{missingColumnErr}</H1>
                            </>
                          </span>
                        </GreenDiv>
                      </>
                    )}

                    {errorUnits == 1 && (
                      <p>Following {errorUnits} unit has some errors </p>
                    )}
                    {errorUnits > 1 && (
                      <p>Following {errorUnits} units have some errors </p>
                    )}
                    {/* //........................ CSV to JSON Download ......................................// */}
                    {errorUnits != 0 && (
                      <span className="d-flex justify-content-center">
                        <button
                          className=" pb-1"
                          style={{
                            border: "none",
                            textDecoration: "underline",
                            background: "transparent",
                            width: "fit-content",
                          }}
                          // todo
                          data={errorUnitsCollection}
                          onClick={csvClick}
                        >
                          <GreenSpan className="form-label-title">
                            Download error report here
                          </GreenSpan>
                        </button>
                      </span>
                    )}

                    {/* //........................ CSV to JSON Download ......................................// */}
                  </div>
                </div>
              )}

              {/* //........................Image Part ......................................// */}
              {errorUnits == 0 ? (
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    height: "300px",
                  }}
                >
                  <div style={{ position: "absolute", bottom: "0" }}>
                    <dotlottie-player
                      src={HighFive}
                      autoplay
                      loop
                      style={{ height: "500px", width: "100%" }}
                    />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    height: "180px",
                  }}
                ></div>
              )}
            </Col>
          </Row>
          <div className="py-2 px-3 d-sm-flex justify-content-end footer-section-button-container">
            <div className="m-1">
              <ETButton
                variant={"primary"}
                buttonText="GO TO UNITS"
                disabled={editMulLoading}
                onClick={() => unitsTableChange(false)}
              />
            </div>
          </div>
        </Container>
      </CustomPopup>
    </Container>
  );
};

export default MultipleUnits;
