import React, { useState } from "react";
import SelectPropertyFromTable from "../CreateNewAppointments/SelectPropertyFromTable";
import ETButton from "../../../../Components/Buttons/ETButton";

const AddPropertyForUnits = ({ handleCancel, handleNext }) => {
  // ----------------------------------------- states ---------------------------------//
  const [rowSelection, setRowSelection] = useState({});

  //---------------------------- handle next button -------------------------------//
  const handleClickNext = (data) => {
    handleNext(data);
  };
  return (
    <div>
      <div className="Popups-inner-scrollable-container-height scrollable-container-y p-4">
        <div className="light-font-text pb-3">
          Select below which property you want to move the customer to.
        </div>

        <div>
          <SelectPropertyFromTable
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
          />
        </div>
      </div>
      <div className="py-2 px-3 d-flex flex-row justify-content-between  footer-section-button-container">
        <ETButton
          variant={"white"}
          buttonText="CANCEL"
          type={"button"}
          onClick={handleCancel}
        />

        <ETButton
          variant={"primary"}
          buttonText="NEXT"
          type={"button"}
          onClick={handleClickNext}
        />
      </div>
    </div>
  );
};

export default AddPropertyForUnits;
