import React, { useState } from "react";
import ETButton from "../../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../../Components/CustomPopup";
import { Col, Row } from "react-bootstrap";
import Toggle from "../../../../../Components/Switch/Switch";
import { H4 } from "../../../../../styles/Common.style";

const CreateAgreement = ({ setApproveAgreement, approveAgreement }) => {
  return (
    <div>
      <CustomPopup
        open={approveAgreement}
        onHide={() => setApproveAgreement(false)}
        title="Create Agreement to the Customer"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        size={"lg"}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="">
          <div className="px-4 pt-4">
            <Row className="mt-2 mb-4">
              <H4>Unit:</H4>
              <Col className="light-font-text ">
                {" "}
                Kaktus Towers
                <br />
                Dybbølsbrovej 13, 12, door 3 I 212-004
              </Col>
            </Row>

            <Row className="mt-2 mb-4 light-font-text">
              <H4>Customer you would like to create an agreement for:</H4>
              <Col>
                {" "}
                Nikolas Rasmussen, +45 44 31 66 12, nikolas.rasmussen@live.dk
              </Col>
            </Row>
          </div>
          <hr className="hr m-0 p-0"></hr>

          <div className="p-4">
            <Row className=" mb-4 light-font-text">
              <Col>
                The system will now create the agreement for the customer,
                please select if the agreement should be sent for signing
                immediatley.
              </Col>
              <span className="d-flex align-items-center p-0">
                <Toggle />
                <div className="ps-3">Send agreement to customer now</div>
              </span>
            </Row>

            <Row className="mb-5 light-font-text">
              <Col>
                You find the agreement and the signing order on the customers
                space.
              </Col>
            </Row>
          </div>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            buttonText="COLD FEET?"
            variant={"white"}
            onClick={() => setApproveAgreement(false)}
          />
          <div className="d-flex ">
            <div className="pe-2">
              <ETButton
                buttonText="Approve"
                variant={"secondary"}
                onClick={() => console.log("Approved")}
              />
            </div>
            <div>
              <ETButton
                buttonText="Go to contract"
                variant={"primary"}
                onClick={() => console.log("Go to contract")}
              />
            </div>
          </div>
        </div>
      </CustomPopup>
    </div>
  );
};

export default CreateAgreement;
