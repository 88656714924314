import React, { useRef, useState } from "react";
import CustomPopup from "../../../../../../Components/CustomPopup";
import ETButton from "../../../../../../Components/Buttons/ETButton";
import { H5 } from "../../../../../../styles/Common.style";
import ReactSelect from "../../../../../../Components/DropDown/ReactSelect";
import { Col, Row } from "react-bootstrap";
import InputField from "../../../../../../Components/Textfield/InputField";
import CustomToolTip from "../../../../../../Components/Tooltip/CustomTooltip";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import SuperEditor from "@ckeditor/ckeditor5-build-classic";
import { useLocation } from "react-router-dom";


const AddCertification = ({
  showAddCertification,
  setShowAddCertification,
}) => {
  const [emailTemplateBody, setEmailTemplateBody] = useState("");
//------------Constants-----------//

const { state } = useLocation();
const isViewTemplate = state?.flag == "View" ? true : false;
const editorRef = useRef();

const editorConfiguration = {
  placeholder: "Dear Customer", // Set the placeholder text
  toolbar: {
    items: [
      "heading",
      "placeholder",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "indent",
      "outdent",
      "blockQuote",
      "undo",
      "redo",
      "image",
      "codeBlock",
      // "imageUpload",
      // "insertInlineWidget",
    ],
  },
  language: "en",
  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
    ],
  },
  // Add more options and configurations here
};



  return (
    <div>
      {" "}
      <CustomPopup
        open={showAddCertification}
        size="xl"
        onHide={() => setShowAddCertification(false)}
        title={"Add certification"}
        title_color="black"
        titleTooltipText="Add certification"
        titleTooltip={true}
        enableCrossButton={true}
        onClickCrossButton={() => {
          setShowAddCertification(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4 border-bottom">
          <H5 className="mb-2">Details about the certification</H5>
          <div className="light-font-text mb-4">
            Fill in the information about the certification on the property
          </div>

          <H5 className="mb-2">Certification details</H5>
          <Row>
            <Col className="pe-lg-0 mb-sm-3 mb-lg-0" lg={3}>
              <InputField placeholder={"Certification type"} />
            </Col>
            <Col className="pe-lg-0 mb-sm-3 mb-lg-0" lg={3}>
              <InputField placeholder={"Certification name"} />
            </Col>
            <Col className="pe-lg-0" lg={3}>
              <InputField placeholder={"Certification level"} />
            </Col>
          </Row>
        </div>
        <div className="p-4">
          <H5 className="mb-2">Message about certification</H5>

          <div className="light-font-text mb-4 w-lg-75">
            If you need to display a message to your customers about the
            certification, this message can be entered below. The message will
            be promoted in predefined placeholders on the website, Ei.DO, MyPage
            and through APIs.
          </div>
          <Row className="mb-3">
            <Col lg={7} className="">
              <div className="d-flex gap-2 align-items-center mb-2">
                <H5 className="">Headline</H5>
                <CustomToolTip size="sm" text={"Headline"} />
              </div>
              <InputField placeholder={"Headline"} />
            </Col>
            <Col lg={4} className="d-flex align-items-end">
              <ReactSelect placeholder={"Choose language"} />
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <div className="d-flex gap-2 align-items-center mb-2">
                <H5 className="">Message</H5>
                <CustomToolTip size="sm" text={"Message"} />
              </div>
            </Col>
            
          </Row> */}

           {/* body and Insert merge field */}
           <div>
                    <Row className="">
                      <Col >
                        <div className="d-flex form-label-title align-items-end gap-2 mb-2">
                          <H5> Message </H5>
                          <CustomToolTip size="sm" text="Message" />
                        </div>
                        <div>
                          <div className="">
                            <CKEditor
                              editor={SuperEditor}
                              focusedElement={true}
                              onReady={(editor) => {
                                editor.editing.view.focus();
                                editorRef.current = editor;
                              }}
                              disabled={isViewTemplate ? true : false}
                              config={editorConfiguration} // Set the custom configuration here
                              data={emailTemplateBody || ""}
                              onChange={(event, editor) => {
                                const data = editor.getData();

                                // console.log(editor, "ck editor console");
                                setEmailTemplateBody(data);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                     
                    </Row>
                  </div>
        </div>
        <div
          className="py-2 px-3 d-flex justify-content-between"
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <ETButton
            variant={"white"}
            onClick={() => {
              setShowAddCertification(false);
            }}
            buttonText="CANCEL"
            fontSize="14px"
            fontWeight="500"
          />
          <ETButton
            variant={"primary"}
            buttonText="ADD"
            fontSize="14px"
            fontWeight="500"
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default AddCertification;
