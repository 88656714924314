import { useEffect, useMemo, useState } from "react";
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from "../../../Components/Tabs/ReactTabs.style";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { useLocation } from "react-router-dom";
import { Col, Placeholder, Row } from "react-bootstrap";
import MRTTheme from "../../../Components/table/MRTTheme";
import { MRT_GlobalFilterTextField, MaterialReactTable } from "material-react-table";
import { SearchIcon, SearchStyle, SortIcon, TableRowsStyle, paginationoptions } from "../../../Components/table/MRTGlobalProps";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import Toggle from "../../../Components/Switch/Switch";
import { useQuery } from "react-query";
import { GetDocumentByPropertyId } from "../../../services/Documents";
import CustomPopup from "../../../Components/CustomPopup";


const Documents = () => {
  let location = useLocation();
  const { state } = location;
  let propId = state?.propId;

  //------------------React query-------------------//
	const {
		data: getDocumentsByPropertyId,
		refetch: getDocumentsByPropertyIdRefetch,
		isLoading,
	} = useQuery(
		["getDocumentsByPropertyId", propId],
		() => GetDocumentByPropertyId(propId),
		{ enabled: !!propId }
	);

  const [textPackageData, setTextPackageData] = useState([]);
  const [documentUrl, setDocumentUrl] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [documentbypropertyid, setDocumentByPropertyId] = useState([]);
  const [documentPropertyMenu, setDocumentPropertyMenu] = useState({
		preview: false,
		copy: false,
		delete: false,
	});

  let eidoTextData = useMemo(() => ([
    {
    unit_no       : '100212241',
    package_group: 'package1, package2, package3'
    },
    {
      unit_no       : '100212242',
      package_group: ''
    },
    {
      unit_no       : '100212243',
      package_group: 'package1, package2, package3, package4, package5, package6'
    },
    {
      unit_no       : '100212244',
      package_group: 'package1'
    },
    {
      unit_no       : '100212245',
      package_group: 'package1, package2, package3'
    }
  ]))

  useEffect(()=> {
    setTextPackageData(eidoTextData)
  }, [])

  useEffect(() => {
		setDocumentByPropertyId(getDocumentsByPropertyId?.data);
	}, [getDocumentsByPropertyId]);


  const renderEmptyRowsFallback = () => (
    <div className = "d-flex justify-content-center w-100 grey-text py-4">
      <div>
        You do not have any text package assgined to unit.
      </div>
    </div>
  );

  const addedDocumentcolumns = useMemo(
		() => [
			{
				accessorKey: "type",
				header: "Type",
				size: 50,
				muiTableHeadCellProps: {
					align: "center",
				},
				muiTableBodyCellProps: {
					align: "center",
				},
				Cell: ({ cell }) => (
					<div className="d-flex gap-2 ps-3">
						{cell.getValue() === "-" && <span>-</span>}
						<button
							className="document-property-table-btn d-flex justify-content-center align-items-center"
							onClick={() => {
								setDocumentUrl(cell?.row?.original?.file_url);
								setDocumentPropertyMenu({
									...documentPropertyMenu,
									preview: true,
								});
							}}>
							<FontAwesomeIcon icon="fa-regular fa-file-pdf" className="fs-6" />
						</button>
					</div>
				),
			},
			{
				accessorKey: "name",
				header: "Name",
				size: 300,
				Cell: ({ cell }) => (
					<div
						className="cursor-pointer"
						onClick={() => {
							// handleDownloadFile(cell?.row?.original?.file_url);
							setDocumentUrl(cell?.row?.original?.file_url);
							setDocumentPropertyMenu({
								...documentPropertyMenu,
								preview: true,
							});
						}}>
						{cell.getValue()}
						{/* <FontAwesomeIcon icon="fa-regular fa-file-pdf" className="fs-6" /> */}
					</div>
				),
			},
			{
				accessorKey: "document_type_name",
				header: "Document type",
				size: 180,
				muiTableHeadCellProps: {
					align: "left",
				},
				muiTableBodyCellProps: {
					align: "left",
				},
			},
			{
				accessorKey: "created_on", //normal accessorKey
				header: "Date of upload",
				size: 50,
				Cell: ({ cell }) => (
					<div>{moment(cell.getValue()).format("DD MMM, YYYY")}</div>
				),
			},
			{
				accessorKey: "created_by", //normal accessorKey
				header: "Uploaded by",
				// size: 100,
			},
			{
				accessorKey: "table_menu", //normal accessorKey
				header: "",
				size: 50,
				enableSorting: false,
				Cell: ({ cell }) => (
					<div className="d-flex gap-2">
						<button
							className="document-property-table-btn d-flex justify-content-center align-items-center"
							onClick={() => {
								setDocumentUrl(cell?.row?.original?.file_url);
								setDocumentPropertyMenu({
									...documentPropertyMenu,
									preview: true,
								});
							}}>
							<FontAwesomeIcon
								icon="fa-regular fa-file-magnifying-glass"
								className="fs-6"
							/>
						</button>
					</div>
				),
			},
		],
		[]
	);

  return (
    <div className="document-container p-4 my-2">
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          fontSize: "14px",
          border: "1px solid #F0F0F0",
        }}
      >
        <div
          className="px-4 pb-3"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div style={{ fontFamily: "Cerebri Sans" }} className="pt-3 d-flex">
            <div className="fw-600 fs-14">Documents</div>
            <div style={{ paddingLeft: "15px" }}>
              <CustomToolTip size="sm" text="Documents" />
            </div>
          </div>
        </div>
             <div className="py-3 px-4">
        <div>
        <MRTTheme
					colorVariantsForTableData={["odd"]}
					table={
						<MaterialReactTable
							columns={addedDocumentcolumns}
							data={documentbypropertyid ?? []}
							icons={{...SortIcon}}
							state={{ isLoading: !documentbypropertyid }}
							renderEmptyRowsFallback={()=> (
                <div className="d-flex justify-content-center w-100 grey-text py-4 light-font-text">
                  <div>
                    You do not have any Document yet.
                  </div>
                </div>
              )}
							// enableRowOrdering
							// data={documentPropertyData}
							enableTopToolbar={false}
							enableColumnActions={false}
							enablePagination={true}
							muiTablePaginationProps={{
								...paginationoptions,
							}}
							muiTableContainerProps={{
								sx: {
									padding: "0px !important",
								},
							}}
							muiTableHeadRowProps={{
								sx: {
									...TableRowsStyle,
								},
							}}
							// muiTableBodyRowDragHandleProps={({ table }) => ({
							// 	onDragEnd: () => {
							// 		const { draggingRow, hoveredRow } = table.getState();
							// 		if (hoveredRow && draggingRow) {
							// 			documentbypropertyid.splice(
							// 				hoveredRow.index,
							// 				0,
							// 				documentbypropertyid.splice(draggingRow.index, 1)[0]
							// 			);
							// 			setDocumentByPropertyId([...documentbypropertyid]);
							// 		}
							// 	},
							// })}
						/>
					}
				/>
        {/* Popup for preview a pdf of document property */}
			<CustomPopup
				open={documentPropertyMenu.preview}
				onHide={() =>
					setDocumentPropertyMenu({ ...documentPropertyMenu, preview: false })
				}
				headerRequired={false}>
				<div className="p-3" style={{ height: "650px" }}>
					<iframe
           title="This is a Ei.DO documments"
						src={
							documentUrl
								? documentUrl
								: "https://therichpost.com/sample.pdf#toolbar=0&navpanes=0&scrollbar=0"
						}
						height="100%"
						width="100%"></iframe>
				</div>
				<div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container"></div>
			</CustomPopup>
			{/* Popup for preview a pdf of document property */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
