import { useMutation, useQuery } from "react-query";
import {
	GetEmailTemplateBranding,
	GetEmailTemplateById,
	GetEmailTemplateByTemplateId,
	GetEmailTemplateCategory,
	GetEmailTemplateMergeField,
	GetLanguageByOrganization,
	PostAddNewEmailTemplate,
	PutUpdateEmailTemplate,
	GetEmailTemplateList,
} from "../../services/EmailTemplates";

//Get email templates by Id
export const useGetEmailTemplateById = (type, id) => {
	//Type o = organization, id = organization_id
	//Type d = division, id = division_id
	//Type c = company, id = company_id
	//Type p = property, id = property_id
	return useQuery(
		["qGetEmailTemplateById", type, id],
		() => GetEmailTemplateById(type, id),
		{
			enabled: !!type && !!id,
		}
	);
};

//Get email template by template id
export const useGetEmailTemplateByTemplateId = (email_template_id) => {
	return useQuery(
		["qGetEmailTemplateByTemplateId", email_template_id],
		() => GetEmailTemplateByTemplateId(email_template_id),
		{
			enabled: !!email_template_id,
		}
	);
};

//Get email template category
export const useGetEmailTemplateCategory = () => {
	return useQuery(["qGetEmailTemplateCategory"], () =>
		GetEmailTemplateCategory()
	);
};

//Get email template branding by organization Id
export const useGetEmailTemplateBranding = (organization_id, language_id) => {
	return useQuery(
		["qGetEmailTemplateBranding", organization_id, language_id],
		() => GetEmailTemplateBranding(organization_id, language_id),
		{
			enabled: !!organization_id && !!language_id,
		}
	);
};

//Get email template language by organization Id
export const useGetEmailTemplateLanguageByOrganization = (organization_id) => {
	return useQuery(
		["qGetEmailTemplateLanguageByOrganization", organization_id],
		() => GetLanguageByOrganization(organization_id),
		{
			enabled: !!organization_id,
		}
	);
};

//Get email template merge field
export const useGetEmailTemplateMergeField = (
	email_template_subcategory_id
) => {
	return useQuery(
		["qGetEmailTemplateMergeField", email_template_subcategory_id],
		() => GetEmailTemplateMergeField(email_template_subcategory_id),
		{
			enabled: !!email_template_subcategory_id,
		}
	);
};

//Add new email template
export const usePostAddNewEmailTemplate = () => {
	return useMutation(PostAddNewEmailTemplate);
};
//Update email template
export const usePutUpdatEmailTemplate = () => {
	return useMutation(PutUpdateEmailTemplate);
};
// GetEmailTemplateList
export const useGetEmailTemplateList = (
	level,
	propertyId,
	categoryId,
	subcategoryId,
	type
) => {
	return useQuery(
		[
			"qGetEmailTemplateList",
			level,
			propertyId,
			categoryId,
			subcategoryId,
			type,
		],
		() =>
			GetEmailTemplateList(level, propertyId, categoryId, subcategoryId, type),
		{
			enabled: !!categoryId,
			enabled: !!subcategoryId,
		}
	);
};
