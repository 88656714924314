import React, { useEffect, useMemo, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";

import "./tags.css";

import { DeleteTags, PostTags } from "../../../services/Tags";

import Toggle from "../../../Components/Switch/Switch";
import Menus from "../../../Components/Menus/Menus";
import TagToolTip from "../../../Components/Tooltip/PropertyTagToolTip";
import ETButton from "../../../Components/Buttons/ETButton";
import CustomPopup from "../../../Components/CustomPopup";

import CreateNewTag from "./createTag/CreateNewTag";
import SelectTextPackage from "./createTag/SelectTextPackage";
import SelectFacilityPackage from "./createTag/SelectFacilityPackage";
import SelectUnits from "./createTag/SelectUnits";
import NewTagConformation from "./createTag/NewTagConformation";
import SelectMediaPackage from "./createTag/SelectMediaPackage";
import { toast } from "react-toastify";
import DropdownModalMui from "../../../Components/DropdownModal/DropdownModalMui";
import TagFilterDropDown from "./TagFilterDropDown";
import { Col, Placeholder, Row } from "react-bootstrap";
import {
  useAddTag,
  usePutTagsActive,
  useTagsByPropertyId,
} from "../../../hooks/queries/useTagsQueries";
import SelectDocumentPackage from "./createTag/SelectDocumentPackage";
import SelectSigningPackage from "./createTag/SelectSigningPackage";
import { GetDocumentPackagesByPropertyId } from "../../../services/Documents";
import { useQuery } from "react-query";
import { status } from "../../../model/Constant";
import MRTTheme from "../../../Components/table/MRTTheme";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../Components/table/MRTGlobalProps";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { StyledLoadingBar } from "../../../styles/Common.style";
import { customproperties } from "../../../styles/Theme";

const Tags = ({
  setShow,
  show,
  textFieldStyle,
  // setTextFieldStyle,
  progress,
  setProgress,
  setStepWizard,
  stepWizard,
  modalHeadingStyle,
  propertyId,
  // setDeleteTagPopUp,
  // filtertext,
  // propertyOverview,
  setShowEditTag,
  setTagId,
  tagId,
  handleCreateTag,
  setTabloading,
}) => {
  // Api for Get tags
  const {
    data: tagsByPropertyIdData,
    refetch: tagsByPropertyIdDataRefetch,
    isLoading: GetTagsByPropertyIdLoaging,
  } = useTagsByPropertyId(propertyId);
  console.log(tagsByPropertyIdData);
  const { mutateAsync: PostTags, isLoading: PostTagsIsLoading } = useAddTag();
  //Api to get document packages
  const {
    data: getDocumentPackagesByPropertyIdData,
    refetch: getDocumentPackagesByPropertyIdDataRefetch,
  } = useQuery(
    ["getDocumentPackageByPropertyId", propertyId, 0, 0, 0],
    () => GetDocumentPackagesByPropertyId(propertyId, 0, 0, 0),
    { enabled: !!propertyId }
  );

  // //Api to get signing packages
  // const {
  //   data: getSigningPackagesByPropertyIdData,
  //   refetch: getSigningPackagesByPropertyIdDataRefetch,
  // } = useQuery(
  //   ["getSigningPackageByPropertyId", propertyId, 0, 2],
  //   () => GetDocumentPackagesByPropertyId(propertyId, 0, 2),
  //   { enabled: !!propertyId }
  // );

  //----------states for Add Tags--------------//
  const [selectedUnitsId, setSelectedUnitsId] = useState([]);
  const [tagName, setTagName] = useState("");
  const [rowSelection, setRowSelection] = useState([]);
  const [mediaPackage, setMediaPackage] = useState([]);
  const [propertyText, setPropertyText] = useState([]);
  const [facilityPackage, setFacilityPackage] = useState([]);
  const [originalTagData, setOriginalTagData] = useState([]);
  const [tagfilter, setTagfilter] = useState([]);
  const [documentId, setDocumentId] = useState([]);
  const [signingPackageId, setSigningPackageId] = useState([]);
  const SourceCount =
    mediaPackage?.length +
    propertyText?.length +
    facilityPackage?.length +
    documentId?.length +
    signingPackageId.length;

  //------------API call and function--------//

  const handleAddTag = async () => {
    const data = {
      property_id: propertyId?.toString(),
      name: `#${tagName}`,
      media_package_id: mediaPackage?.map((el) => el?.media_package_id),
      facility_package_id: facilityPackage?.map(
        (el) => el?.facility_package_id
      ),
      property_text_id: propertyText?.map((el) => el?.property_text_id),
      document_package_id: documentId
        .concat(signingPackageId)
        ?.map((el) => el?.document_package_id),
      unit_id: selectedUnitsId,
    };
    try {
      const response = await PostTags(data);

      if (response?.status === status?.s && response?.data?.bReturn) {
        toast.success(response?.data?.message);

        tagsByPropertyIdDataRefetch();
        setTagName("");
        setPropertyText([]);
        setRowSelection([]);
        setMediaPackage([]);
        setFacilityPackage([]);
        setDocumentId([]);
        setSigningPackageId([]);
        // toast.success("Tag Created Succesfully!");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {}

    // const response = await usePostTags(data);
    // if (response.status == status.s) {
    //   tagsByPropertyIdDataRefetch();
    //   setTagName("");
    //   setPropertyText([]);
    //   setMediaPackage([]);
    //   setFacilityPackage([]);
    //   setDocumentId([]);
    //   setSigningPackageId([]);
    //   toast.success("Tag Created Succesfully!");
    // }
  };

  const WizardsPages = {
    CreateNewTag: (
      <CreateNewTag
        textFieldStyle={textFieldStyle}
        setShow={setShow}
        show={show}
        setStepWizard={setStepWizard}
        progress={progress}
        setProgress={setProgress}
        setTagName={setTagName}
        tagName={tagName}
        setMediaPackage={setMediaPackage}
        setPropertyText={setPropertyText}
        setFacilityPackage={setFacilityPackage}
        setDocumentId={setDocumentId}
        setSigningPackageId={setSigningPackageId}
        setRowSelection={setRowSelection}
      />
    ),

    SelectMediaPackage: (
      <SelectMediaPackage
        tagName={tagName}
        setStepWizard={setStepWizard}
        setShow={setShow}
        progress={progress}
        setProgress={setProgress}
        propertyId={propertyId}
        setMediaPackage={setMediaPackage}
        mediaPackage={mediaPackage}
      />
    ),

    SelectTextPackage: (
      <SelectTextPackage
        tagName={tagName}
        setStepWizard={setStepWizard}
        progress={progress}
        setProgress={setProgress}
        propertyId={propertyId}
        setPropertyText={setPropertyText}
        propertyText={propertyText}
        // propertyOverview={propertyOverview}
      />
    ),

    SelectFacilityPackage: (
      <SelectFacilityPackage
        tagName={tagName}
        setFacilityPackage={setFacilityPackage}
        facilityPackage={facilityPackage}
        setStepWizard={setStepWizard}
        progress={progress}
        setProgress={setProgress}
        propertyId={propertyId}
      />
    ),

    SelectDocumentPackage: (
      <SelectDocumentPackage
        tagName={tagName}
        setStepWizard={setStepWizard}
        progress={progress}
        setProgress={setProgress}
        documentId={documentId}
        setDocumentId={setDocumentId}
        getDocumentPackagesByPropertyIdData={getDocumentPackagesByPropertyIdData?.data.filter(
          (packagetype) => packagetype.is_signing_package === 0
        )}
      />
    ),
    SelectSigningPackage: (
      <SelectSigningPackage
        tagName={tagName}
        setStepWizard={setStepWizard}
        progress={progress}
        setProgress={setProgress}
        signingPackageId={signingPackageId}
        setSigningPackageId={setSigningPackageId}
        getSigningPackagesByPropertyIdData={getDocumentPackagesByPropertyIdData?.data.filter(
          (packagetype) => packagetype.is_signing_package === 1
        )}
      />
    ),

    SelectUnits: (
      <SelectUnits
        tagName={tagName}
        setStepWizard={setStepWizard}
        progress={progress}
        setProgress={setProgress}
        propertyId={propertyId}
        setSelectedUnitsId={setSelectedUnitsId}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        // selectedUnitsId = {selectedUnitsId}
      />
    ),

    NewTagConformation: (
      <NewTagConformation
        tagName={tagName}
        setStepWizard={setStepWizard}
        setShow={setShow}
        progress={progress}
        setProgress={setProgress}
        handleAddTag={handleAddTag}
        SourceCount={SourceCount}
        selectedUnitsId={selectedUnitsId}
        mediaPackage={mediaPackage}
        propertyText={propertyText}
        facilityPackage={facilityPackage}
        documentId={documentId}
        signingPackageId={signingPackageId}
        PostTagsIsLoading={PostTagsIsLoading}
      />
    ),
  };

  // For Table only
  const [anchorEl, setAnchorEl] = useState(false);

  const [deletePopUp, setDeletePopUp] = useState(false);
  const [tagActiveToggle, setTagActiveToggle] = useState(false);
  const [unitCount, setUnitCount] = useState("");
  const [disableDeleteAction, setDisableDeleteAction] = useState(false);

  //-------------query----------------
  const { mutateAsync: setActiveTags } = usePutTagsActive();

  useEffect(() => {
    setTabloading(GetTagsByPropertyIdLoaging);
  }, [GetTagsByPropertyIdLoaging]);

  const handleClose = () => {
    setAnchorEl(null);
    setDeletePopUp(false);
    // setDropdownOpen(false);
  };

  const handleIsActiveToggleChange = async (e) => {
    // const [name ,value]=e.target;
    let tagId = e.target.name;
    let activeToggle = e.target.value == 0 ? 1 : 0;
    let setActivePayload = {
      tag_id: tagId,
      is_active: activeToggle,
    };
    // console.log(setActivePayload, "toggelValue");
    try {
      const result = await setActiveTags(setActivePayload);
      if (result.status == status.s && result.data.bReturn) {
        toast.success(result.data.message);
        tagsByPropertyIdDataRefetch();
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {}
  };

  const handleDeleteTag = async () => {
    setDisableDeleteAction(true);
    const response = await DeleteTags(tagId);
    if (response.status == status.s) {
      setDeletePopUp(false);
      tagsByPropertyIdDataRefetch();
      toast.success(response?.data?.message);
      setDisableDeleteAction(false);
    }
  };

  const columns = useMemo(() => [
    {
      id: "Box",
      header: "",
      enableHiding: false,
      size: 50,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ row }) => (
        <>
          <TagToolTip text="Need Help About Tags" />
        </>
      ),
    },
    {
      accessorKey: "tag_id",
      header: "ID",
      size: 30,
      enableGlobalFilter: true,
    },
    {
      accessorKey: "name",
      header: "Name",
      size: 300,
      enableGlobalFilter: true,
    },
    {
      accessorKey: "group",
      header: "Group",
      // size: 50,
      enableGlobalFilter: true,
    },
    {
      accessorFn: (row) => `${row.unit_count}`,
      header: "In use",
      size: 50,
      enableGlobalFilter: true,
      Cell: ({ row }) => (
        <>
          <div>{row?.original?.unit_count} units</div>
        </>
      ),
    },
    {
      accessorFn: (row) => `${row.is_active}`,
      header: "Active",
      Header: (
        <span className="d-flex">
          <span className="me-1">
            <CustomToolTip text={"Active"} />
          </span>{" "}
          <span>Active</span>
        </span>
      ),
      enableGlobalFilter: true,
      size: 50,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ row }) => (
        <>
          <Toggle
            value={row?.original?.is_active}
            name={row?.original?.tag_id}
            checked={row?.original?.is_active == 1 ? true : false}
            onChange={handleIsActiveToggleChange}
            id={row?.original?.tag_id}
          />
        </>
      ),
    },
  ]);

  // filter

  const ChooseFilter = [
    { Id: "1", Text: "Group", parent: "1" },
    { Id: "2", Text: "Active", parent: "2" },
  ];

  const tags = [
    { Id: "1", Text: "Documents", parent: "1" },
    { Id: "2", Text: "Media", parent: "1" },
    { Id: "3", Text: "Facility", parent: "1" },
    { Id: "4", Text: "Text", parent: "1" },
    { Id: "5", Text: "Active", parent: "2" },
    { Id: "6", Text: "InActive", parent: "2" },
  ];
  const selectdata = [];
  const [filterDropdowon, setFilterDropdowon] = useState("");
  const [filterDropData, setFilterDropData] = useState("");
  const [selectedDropdownData, setSelectedDropDownData] = useState();
  const [closeDropdown, setCloseDropdown] = useState(false);
  //  console.log(closeDropdown,"closeDropdowncloseDropdown");

  const dumnmyDropDownData = {
    Group: "group",
    Active: "is_active",
  };
  const [menutag, setMenutag] = useState([]);
  const [filter, setFilter] = useState(null);
  const [drpdownclick, setDrpdownclick] = useState("");
  const [resetCondition, setResetCondition] = useState(false);

  const handleChange = (filter, ActionTypes) => {
    if (ActionTypes.action === "clear") {
      setFilter("");
    } else {
      setFilter(filter);
    }
    setDrpdownclick(true);
    // if (group == 'Active') {
    setIsactive(true);
    // }
  };
  const filteredTag = filter
    ? tags?.filter((tag) => tag?.parent === filter?.value)
    : [];
  const [selecteddata, setSelectedData] = useState([]);
  const [filterKeys, setFilterKeys] = useState({
    group: [],
    is_active: [],
  });

  // Function to push data into a specific filter key
  const pushDataToFilterKey = (key, value) => {
    // console.log(value, "valuesTags");
    if (filterKeys?.group?.includes(value)) {
      // console.log("you already selected");
    } else {
      setFilterKeys((prevFilterKeys) => ({
        ...prevFilterKeys,
        [key]: [...new Set([...prevFilterKeys[key], value])],
      }));
    }
  };

  const handleFilter = () => {
    const filteredTags = originalTagData?.filter((tag) => {
      const groups = tag.group?.split(",")?.map((group) => group?.trim());
      return (
        (filterKeys.group?.length === 0 ||
          filterKeys?.group?.some((filterGroup) =>
            groups.includes(filterGroup)
          )) &&
        (filterKeys.is_active?.length === 0 ||
          filterKeys.is_active?.includes(tag.is_active))
      );
    });
    setTagfilter(filteredTags);
  };

  const [isactive, setIsactive] = useState("");

  const handleFilterData = (i) => {
    const selectedId = filter.value;
    const parent = ChooseFilter?.find(
      (filter) => filter.Id === selectedId
    )?.parent;
    if (parent == 1) {
      const key = "group";
      const value = i;
      pushDataToFilterKey(key, value);
    } else {
    }
    if (i === "Active") {
      const updatedFilterKeys = {
        ...filterKeys,
        is_active: [...new Set([...filterKeys.is_active, 1])],
      };
      setFilterKeys(updatedFilterKeys);
    } else if (i === "InActive") {
      const updatedFilterKeys = {
        ...filterKeys,
        is_active: [...new Set([...filterKeys.is_active, 0])],
      };
      setFilterKeys(updatedFilterKeys);
    } else {
    }

    setFilterDropData(i);

    selectdata.push({ lable: filterDropdowon, value: i });
    setSelectedData((prev) => [...prev, ...selectdata]);
  };

  useEffect(() => {
    setMenutag(tags);
  }, []);

  useEffect(() => {
    setCloseDropdown(false);
  }, [closeDropdown]);

  useEffect(() => {
    if (resetCondition == true) {
      setTagfilter(tagsByPropertyIdData?.data);
    }
  }, [resetCondition]);

  useEffect(() => {
    setTagfilter(tagsByPropertyIdData?.data);
    setOriginalTagData(tagsByPropertyIdData?.data);
  }, [tagsByPropertyIdData]);

  // close filter //
  // const renderEmptyRowsFallback = () => (
  //   <div className="d-flex justify-content-center w-100 grey-text py-4">
  //     <div>
  //       You do not have any tag yet. Click on “+ CREATE TAG” to create your
  //       first.
  //     </div>
  //   </div>
  // );

  const renderEmptyRowsFallback = () => {
    // const tagfilterLength = tagfilter?.length || 0;
    const isEmpty = tagfilter && tagfilter?.length === 0;

    return (
      <div className="d-flex justify-content-center w-100 grey-text py-4">
        {isEmpty ? (
          <div>
            You do not have any tag yet. Click on “+ CREATE TAG” to create your
            very first tag.
          </div>
        ) : (
          <div style={{ fontSize: "14px" }}>
            Sorry! No result found. Please try again!
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      <CustomPopup
        modalHeadingStyle={modalHeadingStyle}
        style={{ position: "relative" }}
        size="xl"
        open={show}
        onHide={() => setShow(false)}
        title="Create tag"
        title_color="black"
        titleTooltipText="Need Help About Tags"
        titleTooltip={true}
        enableCrossButton={false}
        onCloseAction={() => {
          setShow(false);
          alert("Action Peformed Successfuly.");
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div>
          <StyledLoadingBar>
            <LoadingBar
              color={customproperties.primaryColor}
              progress={progress}
              containerStyle={{
                position: "absolute",
                height: "2.5px",
                // background: "#f0f0f0",
              }}
              shadow={false}
              onLoaderFinished={() => setProgress(0)}
            />
          </StyledLoadingBar>
        </div>
        <div
          className="scrollable-container-y me-1 pb-4"
          style={{ height: "600px" }}
        >
          {WizardsPages[stepWizard]}
        </div>
      </CustomPopup>

      <div className="px-3">
        <Placeholder animation="glow">
          {/* table  */}
          <MRTTheme
            colorVariantsForTableData={["odd"]}
            table={
              <MaterialReactTable
                columns={columns}
                data={tagfilter ?? []}
                icons={{ ...SortIcon }}
                state={{ isLoading: GetTagsByPropertyIdLoaging }}
                renderEmptyRowsFallback={renderEmptyRowsFallback}
                enableRowActions
                positionActionsColumn="last"
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "",
                  },
                  // "mrt-row-select": {
                  //   header: "",
                  // },
                }}
                renderRowActions={({ row }) => (
                  <Menus
                    itemheight={23}
                    itemwidth={23}
                    iconborderradius="4px"
                    itemcontainerwidth="120px"
                    component={
                      <>
                        <div style={{ fontSize: "14px" }}>
                          <div
                            onClick={() => {
                              setTagId(row.original?.tag_id);
                              setShowEditTag(true);
                            }}
                            className="addpropContent px-3 py-2 "
                          >
                            <span className="ms-1">Edit</span>
                          </div>
                          <hr className="addpropertyHr m-0"></hr>
                          <div
                            className="addpropContent px-3 pt-2 pb-2 "
                            onClick={() => {
                              setDeletePopUp(!deletePopUp);
                              setTagId(row.original?.tag_id);
                              setUnitCount(row.original?.unit_count);
                            }}
                          >
                            <span className="ms-1">Delete</span>
                          </div>
                        </div>
                      </>
                    }
                  />
                )}
                enableTopToolbar={true}
                enableSelectAll={false}
                enableColumnActions={false}
                enableColumnFilter={false}
                enableResizing={false}
                initialState={{ showGlobalFilter: true }}
                enableColumnFilterModes={false}
                muiSearchTextFieldProps={{
                  ...SearchStyle,

                  InputProps: {
                    ...SearchIcon,
                  },
                }}
                muiTablePaginationProps={{
                  ...paginationoptions,
                }}
                muiTableHeadRowProps={{
                  sx: {
                    ...TableRowsStyle,
                  },
                }}
                renderTopToolbar={({ table }) => (
                  <>
                    <Row className=" py-2 px-1">
                      <Col lg={3} className="px-md-3  pe-lg-1  my-1 ">
                        {GetTagsByPropertyIdLoaging ? (
                          <div className="">
                            <div className="placeholder col-12 radius-8 padding-20 bg-secondary"></div>
                          </div>
                        ) : (
                          <MRT_GlobalFilterTextField table={table} />
                        )}
                      </Col>

                      <Col lg={3} className=" px-md-2  pe-lg-1 my-1">
                        {/* filter  */}
                        {GetTagsByPropertyIdLoaging ? (
                          <div className="">
                            <div className="placeholder col-10 radius-8 padding-20 bg-secondary"></div>
                          </div>
                        ) : (
                          <DropdownModalMui
                            text="Set filters"
                            width="561px"
                            closeDropdown={closeDropdown}
                            component={
                              <TagFilterDropDown
                                selecteddata={selecteddata}
                                filteredTag={filteredTag}
                                isactive={isactive}
                                // isActiveLabel={isActiveLabel}
                                handleFilter={handleFilter}
                                tagsByPropertyIdData={tagsByPropertyIdData}
                                // updatedTags={updatedTags}
                                drpdownclick={drpdownclick}
                                tagfilter={tagfilter}
                                selectedDropdownData={selectedDropdownData}
                                dumnmyDropDownData={dumnmyDropDownData}
                                filterDropdowon={filterDropdowon}
                                // handleDropData={handleDropData}
                                // unitsTableData={unitsTableData}
                                filterDropData={filterDropData}
                                setFilterDropData={setFilterDropData}
                                ChooseFilter={ChooseFilter}
                                handleFilterData={handleFilterData}
                                filter={filter}
                                setFilter={setFilter}
                                handleChange={handleChange}
                                selectdata={selectdata}
                                filterKeys={filterKeys}
                                setFilterKeys={setFilterKeys}
                                setCloseDropdown={setCloseDropdown}
                                setResetCondition={setResetCondition}
                              />
                            }
                          />
                        )}
                      </Col>
                      <Col lg={3} className=" px-md-3  pe-lg-3 my-1"></Col>
                      <Col lg={3} className="  pe-lg-1 mb-2 ">
                        <div className="d-flex float-end pe-2 me-1">
                          {GetTagsByPropertyIdLoaging ? (
                            <div
                              className="placeholder col-10 radius-8 padding-20 bg-secondary"
                              style={{ width: "118px" }}
                            ></div>
                          ) : (
                            <ETButton
                              variant={"primary"}
                              buttonText="CREATE TAG"
                              icon={true}
                              width={"fit-content"}
                              onClick={() => {
                                handleCreateTag();
                              }}
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              />
            }
          />

          <CustomPopup
            // modalHeadingStyle={modalHeadingStyle}
            style={{ position: "relative" }}
            size="md    "
            open={deletePopUp}
            onHide={() => setDeletePopUp(false)}
            title="Are you carrying out the trash?"
            title_color="black"
            enableCrossButton={false}
            onCloseAction={() => {
              setDeletePopUp(false);
            }}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={true}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
          >
            <div className="p-4">
              <div className="fs-14 fw-300 text-left mb-3">
                Did you know that by deleting this tag it will affect{" "}
                {unitCount} {` `}units. It’s not always funny to be the bad guy
                doing the dirty work.
              </div>
            </div>

            <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
              <ETButton
                variant={"white"}
                buttonText="COLD FEET"
                onClick={() => setDeletePopUp(false)}
              />

              <ETButton
                variant={"primary"}
                disabled={disableDeleteAction}
                buttonText="I'M SURE"
                onClick={handleDeleteTag}
              />
            </div>
          </CustomPopup>
        </Placeholder>
      </div>
    </>
  );
};

export default Tags;
