import React, { useEffect, useMemo, useState } from "react";
import { H2, H5 } from "../../../styles/Common.style";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { InquiriesTableData } from "../../properties/units/addUnits/editSingleUnit/overview/unitsOverview/requests/page/data";
import MRTTheme from "../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";
import { Col, Row } from "react-bootstrap";
import DateRangeFilter from "../../../Components/DateRangePicker/DateRangeFilter";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import Menus from "../../../Components/Menus/Menus";
import {
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../Components/table/MRTGlobalProps";
import TagToolTip from "../../../Components/Tooltip/PropertyTagToolTip";
import ETButton from "../../../Components/Buttons/ETButton";
import { NoRecordsToDisplay } from "../../../utils/validationConstants";
import {
  InProcessTableData,
  SigningsTableData,
  Statusinfunnel,
} from "../../overview/StaticData";
import { useMediaQuery } from "react-responsive";
import ShowTooltip from "../../../Components/Tooltip/ShowTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddResponsibleAgent from "../standardComponents/AddResponsibleAgent";
import CustomerDetailTooltip from "../standardComponents/CustomerDetailTooltip";
import CreateNewlead from "../leads/createNewLead/CreateNewlead";
import { useUserDetails } from "../../../hooks/stores/useUserDetailsStore";
import {
  useGetAllCustomerByDivision,
  usePutUpdateFavouriteLead,
} from "../../../hooks/queries/useCustomerFunnel";
import moment from "moment";
import { status } from "../../../model/Constant";
import { toast } from "react-toastify";
import { useGetUserByDivisionId } from "../../../hooks/queries/useUserQueries";
import Heart from "../../../Components/Star/Heart";
import CreateNewAppointments from "../opportunities/CreateNewAppointments/CreateNewAppointments";
import RestartScreening from "../screenings/popups/RestartScreening";
import CreateNewContractWizard from "../opportunities/createNewContract/CreateNewContractWizard";
import FollowUp from "../standardComponents/follow up/FollowUp";
import { endOfDay, startOfMonth } from "date-fns";
const CustomerFunnelAll = () => {
  //------------------- Zustand --------------//
  const { userDetails } = useUserDetails((state) => state);
  // ---------------------- all states --------------------------//
  const [showPopUp, setShowPopUp] = useState({ create: false, edit: false });
  const [showNewLead, setShowNewLead] = useState(false);
  const [customerDetailsHover, setCustomerDetailsHover] = useState(false);
  const [customerDetailsHoverId, setCustomerDetailsHoverId] = useState(0);
  const [columnPinning, setColumnPinning] = useState({});
  const [allTableData, setAllTableData] = useState({});
  const [responsibleAgentFilter, setResponsibleAgentFilter] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(
    moment(startOfMonth(new Date())).format("YYYY-MM-DD")
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    moment(endOfDay(new Date())).format("YYYY-MM-DD")
  );
  const [openNewAppointmentScreen, setOpenNewAppointmentScreen] =
    useState(false);
  const [showStartScreening, setShowStartScreening] = useState(false);
  const [createNewLead, setCreateNewLead] = useState(false);
  const [followUp, setFollowUp] = useState(false);
  const [progress, setProgress] = useState("");
  const [customerDetailsData, setCustomerDetailsData] = useState("");

  // ---------------------- Created const ------------------------//
  const isDesktop = useMediaQuery({ minWidth: 1224 });

  //-------------------React query--------------//
  const {
    data: GetAllCustomerByDivision,
    refetch: GetAllCustomerByDivisionRefetch,
  } = useGetAllCustomerByDivision(
    userDetails?.division_id,
    `start-date/${selectedStartDate}/end-date/${selectedEndDate}`
  );
  const { mutateAsync: PutUpdateFavouriteAll } = usePutUpdateFavouriteLead();
  const { data: GetUserByDivisionIdData } = useGetUserByDivisionId(
    userDetails?.division_id
  );
  //-----------------handle Favourite All----------------//
  const handleFavouriteAll = async (actor_id, is_favorite) => {
    const favouriteAllPlayload = {
      actor_id: actor_id,
      is_favorite: is_favorite == 1 ? 0 : 1,
    };
    try {
      const result = await PutUpdateFavouriteAll(favouriteAllPlayload);
      if (result?.status === status.s && result?.data?.bReturn) {
        toast.success(result?.data?.message);
        GetAllCustomerByDivisionRefetch();
      } else {
        toast.error(result?.data?.message);
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  // Table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name} ${row.Id}`,
        header: "Customer",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-3 cursor-pointer ">
            <Heart
              isFavorite={row?.original?.is_favorite}
              handleClick={() =>
                handleFavouriteAll(
                  row?.original?.actor_id,
                  row?.original?.is_favorite
                )
              }
            />
            <TagToolTip
              iconColor={"#888888"}
              size={"lg"}
              border="1px solid #F0F0F0"
              padding={"0px"}
              boxShadow="2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
              text={
                <div style={{ width: "700px" }}>
                  <CustomerDetailTooltip data={row?.original} />
                </div>
              }
            />
            {row?.original?.customer == "" ? "-" : row?.original?.customer}
          </div>
        ),

        muiTableBodyCellProps: {
          sx: {
            backgroundColor: "#F0F0F0 !important",
            border: "none !important",
            borderRight: "1px solid #888888 !important",
          },
        },
      },

      {
        accessorKey: "registration_date",
        header: "Registration date ",
        enableHiding: false,
        // size: 20,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>
            {row?.original?.registration_date == ""
              ? "-"
              : moment(row?.original?.registration_date).format(
                  "DD MMM, YYYY [at] hh:mm"
                )}
          </div>
        ),
      },
      {
        accessorFn: (row) => `${row.funnelStatus} ${row.funnelStatus}`,
        header: "Status in the funnel",
        enableHiding: false,
        size: 260,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div className="d-flex gap-2">
            <div>
              <FontAwesomeIcon
                iconColor={"#000"}
                size="lg"
                icon="fa-regular fa-arrow-up-from-square"
              />
            </div>
            <div>
              {row?.original?.status == "" ? "-" : ` ${row?.original?.status}`}
              <span className="ps-1" style={{ color: "#888" }}>
                ({" "}
                {row?.original?.status_name == ""
                  ? "-"
                  : row?.original?.status_name}{" "}
                )
              </span>
            </div>
          </div>
        ),
      },

      {
        accessorFn: (row) => `${row.last_activity}`,
        header: "Last Activity",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>
            {row?.original?.last_activity == "" ? (
              <span className="grey-text">Unassigned</span>
            ) : (
              moment(row?.original?.last_activity).format(
                "DD MMM, YYYY [at] hh:mm"
              )
            )}{" "}
          </div>
        ),
      },
      {
        accessorFn: (row) => `${row.responsible_agent}`,
        header: "Responsible agent",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>
            {row?.original?.responsible_agent == "" ? (
              <span className="grey-text">Unassigned</span>
            ) : (
              row?.original?.responsible_agent
            )}{" "}
          </div>
        ),
      },
    ],
    []
  );
  //------------------- useEffect --------------//
  // below code is for give responsive design to fixed columns

  useEffect(() => {
    if (isDesktop) {
      setColumnPinning({
        left: ["mrt-row-select", "Status", "Customer"],
        right: ["mrt-row-actions"],
      });
    } else {
      setColumnPinning({});
    }
  }, [isDesktop]);
  useEffect(() => {
    setAllTableData(GetAllCustomerByDivision?.data);
  }, [GetAllCustomerByDivision]);

  //---------------------handle Filter Responsible Agent---------------//
  const handleFilterResponsibleAgent = (selectOption) => {
    setResponsibleAgentFilter(selectOption);
    if (selectOption !== null) {
      const filterResponsibleAgent = GetAllCustomerByDivision?.data?.filter(
        (item) => item?.responsible_agent === selectOption?.label
      );
      setAllTableData(filterResponsibleAgent);
    } else {
      setAllTableData(GetAllCustomerByDivision?.data);
    }
  };
  return (
    <div className="p-lg-4 p-3 mb-4 containerStyle ">
      {" "}
      <div className="whiteContainerStyle">
        {/* Header section */}
        <div className="p-4 border-bottom">
          <div className=" d-flex align-items-center gap-2">
            <H2> All</H2>
            <CustomToolTip size="sm" text="All" />{" "}
          </div>
        </div>

        {/* body section */}
        <div className="p-4  ">
          <Row className="d-flex justify-content-between">
            <Col xl={10} lg={10}>
              <div className="light-font-text ">
                Below is a list of all customers in the customer funnel right
                now and under Status in the funnel you can see their current
                status.<br></br>
                If you need to create a new lead, you can do so by clicking New
                lead
              </div>
            </Col>
            <Col xl={2} lg={5}>
              <ETButton
                icon={true}
                variant={"primary"}
                buttonText="new lead"
                width={"100%"}
                onClick={() => setShowNewLead(true)}
              />
            </Col>
          </Row>
        </div>

        <div className="px-4 pt-2 pb-5">
          <div>
            <MRTTheme
              colorVariantsForTableRow={["gray"]}
              colorVariantsForTableHead={["white"]}
              table={
                <MaterialReactTable
                  columns={columns}
                  data={allTableData || []} //data is undefined on first render
                  // data={[]}
                  icons={{ ...SortIcon }}
                  localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                  enableColumnActions={false}
                  enableTopToolbar={true}
                  enableRowSelection={false}
                  enableRowActions
                  enableSelectAll
                  enablePinning
                  onColumnPinningChange={setColumnPinning}
                  state={{ columnPinning }}
                  enableFilterMatchHighlighting={true}
                  enableGlobalFilter
                  globalFilterFn="contains"
                  initialState={{
                    showGlobalFilter: true,
                    showColumnFilters: false,
                  }}
                  muiTableContainerProps={{
                    sx: {
                      padding: "0px !important",
                    },
                  }}
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      header: "",
                      size: 50,
                      muiTableBodyCellProps: {
                        sx: {
                          // padding: "0px !important",
                          backgroundColor: "#F0F0F0 !important",
                          border: "none !important",
                          borderLeft: "1px solid #888888 !important",
                        },
                      },
                    },
                    "mrt-row-select": {
                      header: "",
                      size: 50,
                      muiTableBodyCellProps: {
                        sx: {
                          // padding: "0px !important",
                          backgroundColor: "#F0F0F0 !important",
                          border: "none !important",
                        },
                      },
                    },
                  }}
                  positionActionsColumn="last"
                  renderRowActions={({ row }) => (
                    <Menus
                      itemheight={23}
                      itemwidth={23}
                      iconborderradius="4px"
                      itemcontainerwidth="200px"
                      itemcontainerheight={"300px"}
                      component={
                        <div className="light-font-text">
                          <div
                            className="p-2 cursor-pointer"
                            onClick={() => {
                              setOpenNewAppointmentScreen(true);
                              setCustomerDetailsData(row?.original);
                            }}
                          >
                            Create appointment
                          </div>
                          <hr className="hr m-0"></hr>
                          <div
                            className="p-2 cursor-pointer"
                            onClick={() => {
                              setCreateNewLead(true);
                            }}
                          >
                            Create deal
                          </div>
                          <hr className="hr m-0"></hr>
                          <div
                            className="p-2 cursor-pointer"
                            onClick={() => setShowStartScreening(true)}
                          >
                            Start screening
                          </div>
                          <hr className="hr m-0"></hr>
                          <div
                            className="p-2 cursor-pointer"
                            onClick={() => {
                              setFollowUp(true);
                            }}
                          >
                            Add follow ups
                          </div>
                          <hr className="hr m-0"></hr>
                          <div className="p-2 cursor-pointer">
                            Add customer as favourite
                          </div>
                          <hr className="hr m-0"></hr>
                          <div className="p-2 cursor-pointer">Add a note</div>
                          <hr className="hr m-0"></hr>
                          <div className="p-2 cursor-pointer">
                            Go to contactCard
                          </div>
                          <hr className="hr m-0"></hr>
                          {row.original.responsible_agent == "-" ? (
                            <div
                              className="p-2 cursor-pointer"
                              onClick={() =>
                                setShowPopUp({ ...showPopUp, create: true })
                              }
                            >
                              Add responsible agent
                            </div>
                          ) : (
                            <div
                              className="p-2 cursor-pointer"
                              onClick={() =>
                                setShowPopUp({ ...showPopUp, edit: true })
                              }
                            >
                              Change responsible agent
                            </div>
                          )}
                          <hr className="hr m-0"></hr>
                          <div className="p-2 cursor-pointer">Archive</div>
                        </div>
                      }
                    />
                  )}
                  renderTopToolbar={({ table }) => (
                    <>
                      <div className="px-0 ">
                        <Row>
                          <Col xl={4} lg={6} className="pb-2 pe-lg-0">
                            <DateRangeFilter
                              setSelectedStartDate={setSelectedStartDate}
                              setSelectedEndDate={setSelectedEndDate}
                            />
                          </Col>
                          <Col xl={3} lg={5}>
                            <ReactSelect
                              placeholder={"Responsible agent"}
                              value={responsibleAgentFilter}
                              options={GetUserByDivisionIdData?.data?.map(
                                (item) => ({
                                  value: item?.Id,
                                  label: item?.Text,
                                })
                              )}
                              onChange={handleFilterResponsibleAgent}
                            />
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                  muiTablePaperProps={{
                    selected: false,
                    elevation: 0,
                    fontFamily: "Cerebri sans-light !important",
                    sx: {
                      borderRadius: "0",
                      border: "none",
                      // backgroundColor: "#F9FAFA",
                      table: {
                        borderCollapse: "separate",
                        borderSpacing: "0 5px",
                      },
                    },
                  }}
                  muiTablePaginationProps={{
                    ...paginationoptions,
                  }}
                  muiTableHeadRowProps={{
                    sx: {
                      ...TableRowsStyle,
                    },
                  }}
                />
              }
            />
          </div>

          <AddResponsibleAgent
            showPopUp={showPopUp.create}
            setShowPopup={setShowPopUp}
          />
          <AddResponsibleAgent
            showPopUp={showPopUp.edit}
            setShowPopup={setShowPopUp}
            usedFor="EDIT"
          />

          {/* ------------create new leads -----------------*/}
          <CreateNewlead
            showNewLead={showNewLead}
            setShowNewLead={setShowNewLead}
          />

          {/* ------------create appointment -----------------*/}
          <CreateNewAppointments
            openNewAppointmentScreen={openNewAppointmentScreen}
            setOpenNewAppointmentScreen={setOpenNewAppointmentScreen}
            setProgress={setProgress}
            progress={progress}
            GetResponsibleAgentData={GetUserByDivisionIdData}
            customerDetailsData={customerDetailsData}
          />

          {/* ------------start screening -----------------*/}
          <RestartScreening
            open={showStartScreening}
            onHide={() => setShowStartScreening(false)}
            onCancel={() => setShowStartScreening(false)}
            usedFor="SSOC"
          />

          {/* -------------create new contract wizard ------------ */}

          <CreateNewContractWizard
            creatingNewContract={createNewLead}
            setCreatingNewContract={setCreateNewLead}
          />
          {/* -------------create new contract wizard ------------ */}

          {/* -------------follow up wizard ------------ */}

          <FollowUp followUp={followUp} setFollowUp={setFollowUp} />

          {/* -------------follow up wizard ------------ */}
        </div>
      </div>
    </div>
  );
};
export default CustomerFunnelAll;

// created by Anmol
// last updated by Suraj-- added comments and add new leads popup  on clicking on button
// API integration --  Neha
