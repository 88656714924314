import React, { useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import GroupText from "../../../../Components/GroupText/GroupText";
import NormalGroupText from "../../../../Components/GroupText/NormalGroupText";
import { Controller, useForm } from "react-hook-form";
import { useAppState } from "../AllState";
import "../../documents.css";
import { H4 } from "../../../../styles/Common.style";
const DepositandPrepaidRent = ({
  submitRef,
  handleNext,
  prevRef,
  activeTenancyForm,
  getConfigurationDetailsData,
  handleEditConfig,
  previewRef,
}) => {
  const [state, setState] = useAppState();
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm({ defaultValues: state[activeTenancyForm] });
  const saveData = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 4, true);
    // handleNext(1);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  const preview = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 4, false);
    // handleNext(1);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  // saving all field into context state
  const prevSubmit = () => {
    // setState({ ...state, ...getValues() });
    setState({ ...state, [activeTenancyForm]: { ...getValues() } });
  };
  useEffect(() => {
    setValue(
      "deposit_date",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "deposit_date"
      )[0]?.actual_value
    );
    setValue(
      "deposit_amount",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "deposit_amount"
      )[0]?.actual_value
    );
    setValue(
      "prepaid_rent_amount",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "prepaid_rent_amount"
      )[0]?.actual_value
    );
    setValue(
      "total_amount",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "total_amount"
      )[0]?.actual_value
    );
    setValue(
      "prepaid_rent_date",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "prepaid_rent_date"
      )[0]?.actual_value
    );
    setValue(
      "prepaid_rent",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "prepaid_rent"
      )[0]?.actual_value
    );
    setValue(
      "rent_period_amount",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "rent_period_amount"
      )[0]?.actual_value
    );
    setValue(
      "deposit",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "deposit"
      )[0]?.actual_value
    );
    setValue(
      "payment_date",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "payment_date"
      )[0]?.actual_value
    );
    setValue(
      "move_in_date",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "move_in_date"
      )[0]?.actual_value
    );
    setValue(
      "rent_end_date",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "rent_end_date"
      )[0]?.actual_value
    );
    setValue(
      "payment_made_on",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "payment_made_on"
      )[0]?.actual_value
    );
    setValue(
      "total_deposit",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "total_deposit"
      )[0]?.actual_value
    );
    setValue(
      "deposit_month",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "deposit_month"
      )[0]?.actual_value
    );
    setValue(
      "prepaid_rent_month",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "prepaid_rent_month"
      )[0]?.actual_value
    );

    setValue(
      "deposit_1_text",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "deposit_1_text"
      )[0]?.actual_value
    );
    setValue(
      "deposit_2_text",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "deposit_2_text"
      )[0]?.actual_value
    );
    setValue(
      "deposit_3_text",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "deposit_3_text"
      )[0]?.actual_value
    );
    setValue(
      "deposit_value_1",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "deposit_value_1"
      )[0]?.actual_value
    );
    setValue(
      "deposit_value_2",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "deposit_value_2"
      )[0]?.actual_value
    );
    setValue(
      "deposit_value_3",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "deposit_value_3"
      )[0]?.actual_value
    );
    setValue("calculation_of_dates", {
      value: getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "calculation_of_dates"
      )[0]?.actual_value,

      label: CalculationsOfDatesData.filter(
        (el) =>
          el.Id ==
          getConfigurationDetailsData?.[0]?.configuration_values?.filter(
            (data) => data.column_name == "calculation_of_dates"
          )[0]?.actual_value
      )?.[0]?.Text,
    });

    setValue("upfront_payment", {
      value: getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "upfront_payment"
      )[0]?.actual_value,

      label: UpFrontPaymentData.filter(
        (el) =>
          el.Id ==
          getConfigurationDetailsData?.[0]?.configuration_values?.filter(
            (data) => data.column_name == "upfront_payment"
          )[0]?.actual_value
      )?.[0]?.Text,
    });
  }, [getConfigurationDetailsData]);

  const CalculationsOfDatesData = [
    {
      Id: "+0 days",
      Text: "+0 days",
    },
    {
      Id: "+1 days",
      Text: "+1 days",
    },
    {
      Id: "+2 days",
      Text: "+2 days",
    },
    {
      Id: "+3 days",
      Text: "+3 days",
    },
    {
      Id: "+4 days",
      Text: "+4 days",
    },
    {
      Id: "+5 days",
      Text: "+5 days",
    },
    {
      Id: "+6 days",
      Text: "+6 days",
    },
    {
      Id: "+7 days",
      Text: "+7 days",
    },
    {
      Id: "+8 days",
      Text: "+8 days",
    },
    {
      Id: "+9 days",
      Text: "+9 days",
    },
    {
      Id: "+10 days",
      Text: "+10 days",
    },
  ];
  const UpFrontPaymentData = [
    {
      Id: "0",
      Text: "No rent for the period",
    },
    {
      Id: "1",
      Text: "Exact days",
    },
    {
      Id: "2",
      Text: "Half price if id d 15",
    },
  ];

  return (
    <>
      <Form className="main-form">
        {/* -----------Top Header----------- */}
        <div style={{ borderBottom: "1px solid #F0F0F0" }}>
          <div className="px-4 py-3">
            <H4 className="actors-section-title">
              §4 Deposit and pre-paid rent{" "}
            </H4>
            <div className="light-font-text grey-text">
              Lorem ipsum dolor sit lorem ipsum dolor sit
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #F0F0F0" }}>
          <div className="px-4 py-3">
            <div className="global-presettings">
              <div className="d-flex align-items-center pb-1 pt-2">
                <span className="me-2 form-label-title">
                  Global pre-settings{" "}
                </span>{" "}
                <CustomToolTip size="sm" text="Global pre-settings" />
              </div>
              <div className="py-2">
                <Row className="mb-3">
                  <Col className="pe-lg-0 mb-sm-3 mb-lg-0" lg={6}>
                    <div className="pe-1 ">
                      {" "}
                      <Controller
                        name="calculation_of_dates"
                        {...register("calculation_of_dates")}
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <ReactSelect
                            options={CalculationsOfDatesData?.map((item) => ({
                              value: item?.Id,
                              label: `${item?.Text}`,
                            }))}
                            iserror={errors.calculation_of_dates}
                            value={value?.value ? value : ""}
                            placeholder={"Calculation of dates"}
                            onChange={(e) => onChange(e)}
                          />
                        )}
                      />
                      {/* {errors.calculation_of_dates && (
                        <label
                          className="red-text fs-14"
                          for="calculation_of_dates"
                        >
                          Calculation of dates is required
                        </label>
                      )} */}
                    </div>
                  </Col>
                  <Col className="pe-lg-0" lg={6}>
                    <div className="pe-lg-2 pe-sm-1">
                 
                      <Controller
                        {...register("upfront_payment")}
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <div>
                              <ReactSelect
                                placeholder={"Upfront Payment"}
                                options={UpFrontPaymentData?.map((item) => ({
                                  value: item?.Id,
                                  label: `${item?.Text}`,
                                }))}
                                iserror={errors.upfront_payment}
                                value={value?.value ? value : ""}
                                onChange={(e) => onChange(e)}
                              />
                            </div>
                          );
                        }}
                      />
                      {/* {errors.upfront_payment && (
                        <label className="red-text fs-14" for="upfront_payment">
                          Upfront Payment is required
                        </label>
                      )} */}
                    </div>
                  </Col>
                </Row>
                {/* <Row className="mb-3">
                  <Col className="pe-lg-0" lg={6}>
                    <div className="pe-1  mb-sm-3 mb-lg-0">
                      <Controller
                        control={control}
                        name="globalPreSettingDateOfPayment"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <ReactSelect
                              placeholder={"Date of Payment"}
                              options={DateOfPaymentData?.map((item) => ({
                                value: item?.value,
                                label: `${item?.label}`,
                              }))}
                              value={value}
                              onChange={(e) => onChange(e)}
                            />
                          );
                        }}
                      />
                    </div>
                  </Col>
                  <Col className="pe-lg-0" lg={6}>
                    <div className="pe-lg-2 pe-sm-1">
                      <Controller
                        control={control}
                        name="globalPreSettingDateOfFirstPayment"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <ReactSelect
                              placeholder={"Date of first Payment"}
                              options={DateOfFirstPaymentData?.map((item) => ({
                                value: item?.value,
                                label: `${item?.label}`,
                              }))}
                              value={value}
                              onChange={(e) => onChange(e)}
                            />
                          );
                        }}
                      />
                    </div>
                  </Col>
                </Row> */}
                {/* <Row className="mb-3">
                  <Col className="pe-lg-0" lg={6}>
                    <div className="pe-1  mb-sm-3 mb-lg-0">
                      <Controller
                        control={control}
                        name="globalPreSettingDeposit"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <ReactSelect
                              placeholder={"Deposit"}
                              options={DepositData?.map((item) => ({
                                value: item?.value,
                                label: `${item?.label}`,
                              }))}
                              value={value}
                              onChange={(e) => onChange(e)}
                            />
                          );
                        }}
                      />
                    </div>
                  </Col>
                  <Col className="pe-lg-0" lg={6}>
                    <div className="pe-lg-2 pe-sm-1">
                      <Controller
                        control={control}
                        name="globalPreSettingPrepaidRent"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <ReactSelect
                              placeholder={"Pre-paid rent"}
                              options={PrepaidRentData?.map((item) => ({
                                value: item?.value,
                                label: `${item?.label}`,
                              }))}
                              value={value}
                              onChange={(e) => onChange(e)}
                            />
                          );
                        }}
                      />
                    </div>
                  </Col>
                </Row> */}
              </div>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #F0F0F0" }}>
          <div className="px-4 py-4 light-font-text">
            <div className="d-flex align-items-center">
              <span className="me-2 form-label-title">Deposit</span>{" "}
              <CustomToolTip  text="Deposit" />
            </div>
            <div className=" mt-1 light-font-text">
              No later than&nbsp;
              <div
                style={{
                  // width: "194px",
                  position: "relative",
                  display: "inline-flex",
                }}
              >
                {/* <div className="form-input mb-2">
                  <input
                    style={{ height: "26px" }}
                    className="mx-sm-0"
                    value={"Merged data"}
                    type="date"
                    {...register("depositAndPrepaidRentDate", {
                      required: "date is required",
                    })}
                  />
                </div> */}
                <div className="form-input mb-2">
                  <Controller
                    control={control}
                    {...register("deposit_date")}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <input
                          className="mx-sm-0"
                          type="text"
                          placeholder="Merged data"
                          style={{ height: "26px" }}
                          disabled={true}
                          value={value}
                        />
                      );
                    }}
                  />
                  <label for="deposit_date">Date</label>
                </div>
                {errors.deposit_date && (
                  <div className="ms-2">
                    <p className="error-msg" style={{ width: "max-content" }}>
                      (date is required)
                    </p>
                  </div>
                )}
              </div>
              &nbsp; the tenant pays a deposit of&nbsp;
              <span
                style={{
                  width: "194px",
                  className: "my-1",
                  position: "relative",
                  display: "inline-flex",
                }}
              >
                <NormalGroupText
                  inputheight={"26px"}
                  textheight={"26px"}
                  // lable="Merged data"
                  graylabel="DKK"
                  textWidth="30%"
                  type="text"
                  disabled={true}
                  value={getValues("deposit_amount")}
                />
              </span>
              equivalent to&nbsp;
              <div
                className="me-1"
                style={{
                  position: "relative",
                  display: "inline-flex",
                }}
              >
                <input
                  disabled={true}
                  style={{ width: "78px" }}
                  className="normalTextstyle"
                  placeholder="0"
                  {...register("deposit_month")}
                />
                {errors.deposit_month && (
                  <div className="ms-2">
                    <p className="error-msg" style={{ width: "max-content" }}>
                      (months' rent is required)
                    </p>
                  </div>
                )}
              </div>
              months' rent (maximum 3 months' rent).
            </div>
          </div>
        </div>

        <div style={{ borderBottom: "1px solid #F0F0F0" }}>
          <div className="px-4 py-4">
            <div className="d-flex align-items-center">
              <span className="me-2  form-label-title">Pre-paid rent </span>{" "}
              <CustomToolTip size="sm" text="Pre-paid rent" />
            </div>
            <div className=" mt-1 light-font-text" style={{ flexWrap: "wrap" }}>
              No later than &nbsp;
              <div
                style={{
                  position: "relative",
                  display: "inline-flex",
                }}
              >
                {/* <div className="form-input mb-2">
                  <input
                    style={{ height: "26px" }}
                    className="mx-sm-0"
                    type="date"
                    placeholder="Merged data"
                    id="prepaid-rent-date"
                    {...register("depositAndPrepaidPrepaidRentDate", {
                      required: "date is required",
                    })}
                  />
                </div> */}
                <div className="form-input mb-2">
                  <Controller
                    control={control}
                    {...register("prepaid_rent_date")}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <input
                          className="mx-sm-0"
                          type="text"
                          placeholder="Merged data"
                          disabled={true}
                          value={value}
                          style={{ height: "26px" }}
                        />
                      );
                    }}
                  />
                  <label for="prepaid_rent_date">Date</label>
                </div>
              </div>
              the tenant shall also pay prepaid rent of&nbsp;
              <span
                className="mb-2"
                style={{
                  width: "194px",
                  position: "relative",
                  display: "inline-flex",
                }}
              >
                <NormalGroupText
                  inputheight={"26px"}
                  textheight={"26px"}
                  // lable="Merged data"
                  graylabel="DKK"
                  textWidth="30%"
                  type="text"
                  disabled={true}
                  value={getValues("prepaid_rent_amount")}
                />
              </span>
              equivalent to
              <div
                className="mx-2 mb-2"
                style={{
                  position: "relative",
                  display: "inline-flex",
                }}
              >
                <input
                  disabled={true}
                  style={{ width: "78px" }}
                  className="normalTextstyle"
                  placeholder="0"
                  {...register("prepaid_rent_month")}
                />
                {errors.depositAndPrepaidPrepaidMonthsRent && (
                  <div className="ms-2">
                    <p
                      className="red-text fs-14 mb-0"
                      style={{ width: "max-content" }}
                    >
                      (months' rent is required)
                    </p>
                  </div>
                )}
              </div>{" "}
              months' rent (not exceeding the rent payable for the period from
              the notice of termination given by the tenant until the end of the
              tenancy, up to a maximum of 3 months' rent).
            </div>
          </div>
        </div>

        <div style={{ borderBottom: "1px solid #F0F0F0" }} className="pb-2">
          <div className="px-4 py-4">
            <div className="d-flex align-items-center">
              <span className="me-2 form-label-title">Payment</span>{" "}
              <CustomToolTip  size="sm" text="Payment" />
            </div>
            <div className=" mt-1 light-font-text">
              No later than
              <div
                className="mb-2 mx-1"
                style={{
                  // width: "194px",
                  position: "relative",
                  display: "inline-flex",
                }}
              >
                {/* <div className="form-input">
                  <input
                    style={{ height: "26px" }}
                    className="mx-sm-0"
                    type="date"
                    {...register("depositAndPrepaidPaymentDate", {
                      required: "Date is required",
                    })}
                  />
                </div> */}
                <div className="form-input mb-2">
                  <Controller
                    control={control}
                    {...register("payment_date")}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <input
                          className="mx-sm-0"
                          type="text"
                          disabled={true}
                          value={value}
                          style={{ height: "26px" }}
                        />
                      );
                    }}
                  />
                </div>
                {errors.payment_date && (
                  <div className="ms-2">
                    <p
                      className="red-text fs-14 mb-0"
                      style={{ width: "max-content" }}
                    >
                      (Date is required)
                    </p>
                  </div>
                )}
              </div>
              the tenant pays a total of
              <span
                className="ms-1"
                style={{
                  width: "194px",
                  position: "relative",
                  display: "inline-flex",
                }}
              >
                <NormalGroupText
                  inputheight={"26px"}
                  textheight={"26px"}
                  // lable="Merged data"
                  graylabel="DKK"
                  textWidth="30%"
                  type="text"
                  disabled={true}
                  value={getValues("total_amount")}
                />
              </span>
              equivalent to:
            </div>
            <Row className="pt-3">
              <Col lg={10}>
                <Row>
                  <Col lg={8}>
                    <span className="light-font-text ">Pre-paid rent:</span>
                  </Col>
                  <Col lg={4}>
                    {" "}
                    <NormalGroupText
                      inputheight={"40px"}
                      textheight={"40px"}
                      // lable="Merged data"
                      graylabel="DKK"
                      textWidth="30%"
                      type="text"
                      disabled={true}
                      value={getValues("prepaid_rent")}
                    />
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col lg={8}>
                    <span className="light-font-text grey-text">
                      Rent etc. for the period:{" "}
                    </span>
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col className="pe-lg-2 pe-sm-4 mb-sm-3 mb-lg-0" lg={4}>
                    <div className="form-input">
                      {/* <input
                        className="mx-sm-0"
                        type="date"
                        id="rent-period-start-date"
                        {...register(
                          "depositAndPrepaidRentPeriodStartingDate",
                          {
                            required: "Date is required",
                          }
                        )}
                      /> */}
                      <div className="form-input mb-2">
                        <Controller
                          control={control}
                          {...register("move_in_date")}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <input
                                className="mx-sm-0"
                                type="text"
                                disabled={true}
                                value={value}
                              />
                            );
                          }}
                        />
                        <label for="move_in_date">Date</label>
                      </div>
                      {errors.move_in_date && (
                        <div className="ms-2">
                          <p
                            className="error-msg"
                            style={{ width: "max-content" }}
                          >
                            (Date is required)
                          </p>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col
                    className="d-flex ps-lg-0 pe-lg-3 pe-sm-4 mb-sm-3 mb-lg-0"
                    lg={4}
                  >
                    {" "}
                    <span className="light-font-text pt-2 me-2">to</span>
                    {/* <div className="form-input ">
                      <input
                        className="mx-sm-0"
                        type="date"
                        id="rent-period-end-date"
                        {...register("depositAndPrepaidRentPeriodEndDate", {
                          required: "Date is required",
                        })}
                      /> */}
                    <div className="form-input mb-2">
                      <Controller
                        control={control}
                        {...register("rent_end_date")}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <input
                              className="mx-sm-0"
                              type="text"
                              disabled={true}
                              value={value}
                            />
                          );
                        }}
                      />
                      <label for="rent_end_date">Date</label>
                      {errors.rent_end_date && (
                        <div className="ms-2">
                          <p
                            className="error-msg"
                            style={{ width: "max-content" }}
                          >
                            (Date is required)
                          </p>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={4}>
                    {" "}
                    <NormalGroupText
                      inputheight={"40px"}
                      textheight={"40px"}
                      // lable="Merged data"
                      graylabel="DKK"
                      textWidth="30%"
                      type="text"
                      disabled={true}
                      value={getValues("rent_period_amount")}
                    />
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col lg={8}>
                    <span className="light-font-text pt-2">Deposit</span>
                  </Col>
                  <Col className="mb-sm-2 mb-lg-0" lg={4}>
                    {" "}
                    <NormalGroupText
                      inputheight={"40px"}
                      textheight={"40px"}
                      // lable="Merged data"
                      graylabel="DKK"
                      textWidth="30%"
                      type="text"
                      disabled={true}
                      value={getValues("deposit")}
                    />
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col className="mb-sm-3 mb-lg-0 pe-sm-4 pe-lg-3" lg={8}>
                    <div className="form-input ">
                      <input
                        className="mx-sm-0"
                        type="text"
                        {...register("deposit_1_text")}
                      />
                    </div>
                  </Col>
                  <Col className="mb-sm-2 mb-lg-0" lg={4}>
                    <Controller
                      control={control}
                      {...register("deposit_value_1")}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <NormalGroupText
                            inputheight={"40px"}
                            textheight={"40px"}
                            lable="0,00"
                            graylabel="DKK"
                            textWidth="30%"
                            type="number"
                            onChange={(e) => onChange(e)}
                            value={value}
                          />
                        );
                      }}
                    />
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col className="mb-sm-3 pe-sm-4 pe-lg-3  mb-lg-0" lg={8}>
                    <div className="form-input ">
                      <input
                        className="mx-sm-0"
                        type="text"
                        {...register("deposit_2_text")}
                      />
                    </div>
                  </Col>
                  <Col className="mb-lg-0 mb-sm-2" lg={4}>
                    <Controller
                      control={control}
                      {...register("deposit_value_2")}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <NormalGroupText
                            inputheight={"40px"}
                            textheight={"40px"}
                            lable="0,00"
                            graylabel="DKK"
                            textWidth="30%"
                            type="number"
                            onChange={(e) => onChange(e)}
                            value={value}
                          />
                        );
                      }}
                    />
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col className="mb-sm-3 pe-sm-4 pe-lg-3  mb-lg-0" lg={8}>
                    <div className="form-input ">
                      <input
                        className="mx-sm-0"
                        type="text"
                        {...register("deposit_3_text")}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <Controller
                      control={control}
                      {...register("deposit_value_3")}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <NormalGroupText
                            inputheight={"40px"}
                            textheight={"40px"}
                            lable="0,00"
                            graylabel="DKK"
                            textWidth="30%"
                            type="number"
                            onChange={(e) => onChange(e)}
                            value={value}
                          />
                        );
                      }}
                    />
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col lg={8}>
                    <span className="light-font-text">In total</span>
                  </Col>
                  <Col lg={4}>
                    {" "}
                    <NormalGroupText
                      inputheight={"40px"}
                      textheight={"40px"}
                      // lable="Merged data"
                      graylabel="DKK"
                      textWidth="30%"
                      type="text"
                      disabled={true}
                      value={getValues("total_deposit")}
                    />
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col lg={8}>
                    <span className="light-font-text">
                      The first time rent is payable thereafter is on
                    </span>
                  </Col>
                  <Col className="" lg={4}>
                    <div style={{ paddingRight: "10px" }}>
                      {/* <div className="form-input">
                        <input
                          className="mx-sm-0"
                          type="date"
                          id="first-time-rent-payable-date"
                          {...register(
                            "depositAndPrepaidFirstTimeRentPayableDate",
                            {
                              required: "Date is required",
                            }
                          )}
                        />
                        {errors.depositAndPrepaidFirstTimeRentPayableDate && (
                          <div className="ms-2">
                            <p
                              className="red-text fs-14 mb-0"
                              style={{ width: "max-content" }}
                            >
                              (Date is required)
                            </p>
                          </div>
                        )}
                      </div> */}
                      <div className="form-input">
                        <Controller
                          control={control}
                          {...register("payment_made_on")}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <input
                                style={{ height: "26px" }}
                                className="mx-sm-0"
                                type="text"
                                placeholder="Merged data"
                                value={value}
                                disabled={true}
                              />
                            );
                          }}
                        />
                        <label for="payment_made_on">Date</label>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <button
          type="submit"
          onClick={handleSubmit(saveData)}
          ref={submitRef}
          style={{ display: "none" }}
        />
        <button
          type="submit"
          onClick={handleSubmit(preview)}
          ref={previewRef}
          style={{ display: "none" }}
        />
      </Form>
    </>
  );
};

export default DepositandPrepaidRent;
