import React, { useEffect, useMemo, useState } from "react";
import "../../actor.css";
import ETButton from "../../../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MaterialReactTable } from "material-react-table";
import CustomPopup from "../../../../Components/CustomPopup";
import MRTTheme from "../../../../Components/table/MRTTheme";
import {
  useGetDocumentDetailsByContractId,
  useUploadActorDocument,
} from "../../../../hooks/queries/useActorQueries";
import moment from "moment";
import FileSaver from "file-saver";
import JSZip from "jszip";
import AddDocument from "../signing/AddDocument";
import { toast } from "react-toastify";
import { status } from "../../../../model/Constant";
import { useDeleteArchiveDocuments } from "../../../../hooks/queries/useSigningQueries";
import { DeleteArchiveDocument } from "../../../../services/Signing";
import { Col, Form, InputGroup, Overlay, Row } from "react-bootstrap";
import ShowTooltip from "../../../../Components/Tooltip/ShowTooltip";
import { useBreadcrumb } from "../../../../Components/BreadcrumbContext ";
import { useForm, Controller } from "react-hook-form";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";
import { useShareDocuments } from "../../../../hooks/queries/useDocumentsQueries";
import { SortIcon } from "../../../../Components/table/MRTGlobalProps";

const Documents = ({
  contractId,
  actorByContractIdData,
  initialSelectedName,
  tanentNameTab,
  selectedName,
}) => {
  const zip = new JSZip();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      link: "",
      email: "",
      message: "",
    },
  });

  //----------------------states------------------------//
  const [openScreeningPdf, setOpenScreeningPdf] = useState(false);
  const [deletePdfPopUp, setDeletePdfPopUp] = useState({
    document_id: "",
    actor_id: "",
    isDeletePopupOpen: false,
  });
  const [viewDocumentPopup, setViewDocumentPopup] = useState({
    documentUrl: "",
    documentName: "",
    isViewDocumentPopupOpen: false,
  });
  const [shareDocumentPopup, setShareDocumentPopup] = useState({
    documentUrl: "",
    documentName: "",
    isShareDocumentPopupOpen: false,
  });
  const [openUploadDocumentManually, setOpenUploadDocumentManually] =
    useState(false);
  const [deleteArchiveApiIsLoading, setDeleteArchiveApiIsLoading] =
    useState(false);
  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    updateBreadcrumbs(["Contracts", initialSelectedName, "Documents"]);
  }, [initialSelectedName]);

  useEffect(() => {
    if (tanentNameTab) {
      updateBreadcrumbs(["Contracts", selectedName, "Documents"]);
    } else {
    }
  }, [selectedName]);
  //-----------------------react query------------------//
  const {
    data: getDocumentDetailsByContractId,
    refetch: getDocumentDetailsByContractIdRefetch,
  } = useGetDocumentDetailsByContractId(contractId, 0, 0);
  const {
    mutateAsync: PostUploadActorDocument,
    isLoading: PostUploadActorDocumentIsLoading,
  } = useUploadActorDocument();
  const {
    mutateAsync: PosShareDocuments,
    isLoading: PostShareDocumentsIsLoading,
  } = useShareDocuments();

  const SigningDocumentsColumns = useMemo(
    () => [
      {
        accessorKey: "title", //access nested data with dot notation
        header: "Document name",
        size: 180,
        muiTableBodyCellProps: {
          sx: { cursor: "pointer !important" },
        },
      },
      {
        accessorKey: "document_type_name",
        header: "Type",
        size: 180,
      },

      {
        accessorKey: "created_on", //normal accessorKey
        header: "Date of upload",
        size: 50,
        Cell: ({ cell }) => (
          <div>
            {cell.getValue() === ""
              ? "-"
              : moment(cell.getValue()).format("DD MMM, YYYY")}
          </div>
        ),
      },
      {
        accessorKey: "created_by",
        header: "Uploaded by",
        size: 180,
      },
      {
        accessorKey: "source",
        header: "Source",
        size: 50,
      },
    ],
    []
  );

  const manualDocumemntsColumns = useMemo(
    () => [
      {
        accessorKey: "title", //access nested data with dot notation
        header: "Document name",
        size: 200,
        muiTableBodyCellProps: {
          sx: { cursor: "pointer !important" },
        },
      },
      {
        accessorKey: "document_type_name",
        header: "Type",
        size: 120,
      },
      {
        accessorKey: "actor_name",
        header: "Actor",
        size: 150,
      },

      {
        accessorKey: "created_on", //normal accessorKey
        header: "Date of upload",
        size: 50,
        Cell: ({ cell }) => (
          <div>
            {cell.getValue() === ""
              ? "-"
              : moment(cell.getValue()).format("DD MMM, YYYY")}
          </div>
        ),
      },
      // {
      //   accessorKey: "seen_on", //normal accessorKey
      //   header: "Seen on",
      //   size: 50,
      //   Cell: ({ cell }) => (
      //     <div>
      //       {cell.getValue() === ""
      //         ? "-"
      //         : moment(cell.getValue()).format("DD MMM, YYYY")}
      //     </div>
      //   ),
      // },
      // {
      //   accessorKey: "read_on", //normal accessorKey
      //   header: "Read on",
      //   size: 50,
      //   Cell: ({ cell }) => (
      //     <div>
      //       {cell.getValue() === ""
      //         ? "-"
      //         : moment(cell.getValue()).format("DD MMM, YYYY")}
      //     </div>
      //   ),
      // },
      {
        accessorKey: "created_by",
        header: "Uploaded by",
        size: 150,
      },
      {
        accessorKey: "source",
        header: "Source",
        size: 50,
      },
    ],
    []
  );
  const renderEmptyRowsFallback = () => (
    <div className="d-flex justify-content-center w-100 py-4 grey-text">
      <div>You do not have any signing order yet.</div>
    </div>
  );
  //--------------download document------------//
  const handleDownloadDocument = (data) => {
    FileSaver.saveAs(data?.file_url, data?.title);
  };

  //--------------download all document as zip------------//
  const handleDownloadAllDocAsZip = async (is_signing) => {
    for (
      let i = 0;
      i <
      getDocumentDetailsByContractId?.data?.filter(
        (item) => item?.is_signing === is_signing
      )?.length;
      i++
    ) {
      const fileBlob = await fetch(
        getDocumentDetailsByContractId?.data?.filter(
          (item) => item?.is_signing === is_signing
        )?.[i]?.file_url
      ).then((response) => response.blob());
      const fileData = new File(
        [fileBlob],
        getDocumentDetailsByContractId?.data?.filter(
          (item) => item?.is_signing === is_signing
        )?.[i]?.title
      );
      zip.file(
        getDocumentDetailsByContractId?.data?.filter(
          (item) => item?.is_signing === is_signing
        )?.[i]?.title,
        fileData,
        {
          base64: true,
        }
      );
    }
    zip.generateAsync({ type: "blob" }).then(function (content) {
      // see FileSaver.js
      FileSaver.saveAs(
        content,
        is_signing === 1 ? "signing_documents.zip" : "tenancy_documents.zip"
      );
    });
  };

  //---------------------handle View document popup-----------------------------//
  const handleViewDocument = (row) => {
    setViewDocumentPopup((prev) => ({
      ...prev,
      isViewDocumentPopupOpen: true,
      documentUrl: row.original?.file_url,
      documentName: row.original?.title,
    }));
  };

  //-----------------------Delete manually added document---------------------//
  const handleDeleteDocument = async () => {
    setDeleteArchiveApiIsLoading(true);
    const response = await DeleteArchiveDocument(
      contractId,
      deletePdfPopUp?.document_id,
      deletePdfPopUp?.actor_id,
      0
    );
    try {
      if (response?.data?.bReturn && response.status == status.s) {
        toast.success(response?.data?.message);
        getDocumentDetailsByContractIdRefetch();
        setDeletePdfPopUp((prev) => ({
          ...prev,
          isDeletePopupOpen: false,
        }));
        setDeleteArchiveApiIsLoading(false);
      } else {
        toast.error(response?.data?.message);
        setDeleteArchiveApiIsLoading(false);
      }
    } catch (error) {
      toast.error(error?.message);
      setDeleteArchiveApiIsLoading(false);
    }
  };

  //----------------------Upload manual document----------------------//
  const handleAddDocumentFromComputer = (data) => {
    let files = [];
    for (let i = 0; i < data?.droppedPdf?.length; i++) {
      let id;
      if (!PostUploadActorDocumentIsLoading) {
        id = toast.loading("Uploading file");
      }
      let formData = new FormData();
      formData.append("files[]", data?.droppedPdf?.[i]?.file);
      formData.append("actor_id", data?.droppedPdf?.[i].actor?.value);
      formData.append("contract_id", contractId);
      formData.append(
        "document_type_id",
        data?.droppedPdf?.[i]?.document_type?.value
      );
      formData.append("is_identification_document", 1);
      PostUploadActorDocument(formData).then(
        (response) => {
          if (response?.data?.bReturn) {
            files[i] = response?.data?.data?.[0];
            toast.update(id, {
              render: response?.data?.message,
              isLoading: false,
              type: status.s,
              autoClose: 2000,
            });
            getDocumentDetailsByContractIdRefetch();
            setOpenUploadDocumentManually(false);
            // remove(); // clearing dropped pdf state so every time new pdf will be added in upload document modal
          } else {
            toast.update(id, {
              render: "Failed to upload",
              isLoading: false,
              type: status.e,
              autoClose: 2000,
            });
          }
        },
        (error) => {
          // setError(true);
          // Some task on failure
          toast.update(id, {
            render: "Failed to upload",
            isLoading: false,
            type: status.e,
            autoClose: 2000,
          });
        }
      );
    }
  };

  const handleSendDocument = async (data) => {
    const ShareDocumentsPayload = {
      recipient_email: data?.email,
      subject: data?.subject,
      message: data?.message,
      docs: [
        {
          doc_url: shareDocumentPopup?.documentUrl,
          doc_name: shareDocumentPopup?.documentName,
        },
      ],
    };
    try {
      const response = await PosShareDocuments(ShareDocumentsPayload);
      if (response?.status === "success" && response?.data?.bReturn) {
        toast.success(response?.data?.message);
        reset();
        setShareDocumentPopup((prev) => ({
          ...prev,
          isShareDocumentPopupOpen: false,
        }));
        //getDocumentDetailsByContractIdRefetch()
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(shareDocumentPopup?.documentUrl)
      .then(() => {
        toast.success("Link copied to clipboard!");
      })
      .catch((err) => {
        toast.warning("Failed to copy link to clipboard");
      });
  };
  return (
    <div className="px-4 py-3">
      <div className="mb-4 px-3 py-2 radius-8 customers-card-layout">
        <div className="fs-16 fw-600 mt-1 d-flex justify-content-between">
          <span className="me-2">Signings</span>

          <ShowTooltip
            tooltipLabel={
              <button
                disabled={
                  getDocumentDetailsByContractId?.data?.filter(
                    (item) => item?.is_signing === 1
                  )?.length <= 1 && true
                }
                className="icon-btn"
                onClick={() => handleDownloadAllDocAsZip(1)}
              >
                <FontAwesomeIcon icon="fa-regular fa-file-zipper" />
              </button>
            }
            tooltopWidth={100}
            tooltipContent={<div className="p-1 fs-12">Download as zip</div>}
          />
        </div>
        <div>
          <MRTTheme
            colorVariantsForTableData={["even"]}
            colorVariantsForTableHead="gray"
            table={
              <MaterialReactTable
                columns={SigningDocumentsColumns}
                muiTableBodyCellProps={({ cell, row }) => ({
                  onClick: (event) => {
                    if (cell.id.includes("title")) {
                      handleViewDocument(row);
                    }
                  },
                })}
                data={
                  getDocumentDetailsByContractId?.data?.filter(
                    (item) => item?.is_signing == 1
                  ) || []
                }
                icons={{ ...SortIcon }}
                renderEmptyRowsFallback={renderEmptyRowsFallback}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableSorting={false}
                // displayColumnDefOptions={{
                //   "mrt-row-expand": {
                //     header: "",
                //     muiTableHeadCellProps: {
                //       display: "",
                //     },
                //   },
                // }}
                paginateExpandedRows={false}
                enableBottomToolbar={false}
                enableTopToolbar={false}
                enableRowActions
                positionActionsColumn="last"
                // initialState={{
                //   columnVisibility: {
                //     "mrt-row-expand": true,
                //   },
                // }}
                enableExpandAll={false}
                getRowId={(row) => row.userId}
                getSubRows={(originalRow) => originalRow.subRows}
                renderRowActions={({ row }) => (
                  <div className="d-flex gap-2">
                    {/* {row.depth == 0 ? (
      <>
        <button className="icon-btn">
          <label for="files" class="button">
            <FontAwesomeIcon icon="fa-regular fa-folder-open" />
          </label>
          <input
            id="files"
            style={{ display: "none" }}
            type="file"
          />
        </button>

        <button
          className="icon-btn"
          // onClick={() => setDeletePdfPopUp(true)}
        >
          <FontAwesomeIcon icon="fa-regular fa-trash" />
        </button>
        <button className="icon-btn">
          <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-line" />
        </button>
      </>
    ) : ( */}
                    <>
                      <ShowTooltip
                        tooltipLabel={
                          <button className="icon-btn">
                            <FontAwesomeIcon icon="fa-regular fa-circle-info" />
                          </button>
                        }
                        tooltopWidth={180}
                        tooltipContent={
                          <>
                            <div className="p-1 fs-12">
                              {row?.original?.document_view_actor?.length ==
                              0 ? (
                                <div>No details found</div>
                              ) : (
                                row?.original?.document_view_actor?.map(
                                  (item, index) => (
                                    <div key={index} className="pb-1">
                                      <div className="form-label-title">
                                        {item?.full_name}
                                      </div>
                                      <div>
                                        <div>
                                          Seen on:{" "}
                                          {item?.seen_on == "" ||
                                          item?.seen_on == null
                                            ? "-"
                                            : moment(item?.seen_on)?.format(
                                                "DD MMM, YYYY"
                                              )}
                                        </div>
                                        <div>
                                          Read on:{" "}
                                          {item?.read_on == "" ||
                                          item?.read_on == null
                                            ? "-"
                                            : moment(item?.read_on)?.format(
                                                "DD MMM, YYYY"
                                              )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )
                              )}
                            </div>
                          </>
                        }
                      />
                      <ShowTooltip
                        tooltipLabel={
                          <button
                            className="icon-btn"
                            onClick={() =>
                              handleDownloadDocument(row?.original)
                            }
                          >
                            <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-line" />
                          </button>
                        }
                        tooltopWidth={80}
                        tooltipContent={
                          <div className="p-1 fs-12">Download</div>
                        }
                      />
                    </>
                    {/* )} */}
                  </div>
                )}
                // muiTablePaperProps={{
                //   elevation: 0,
                //   sx: {
                //     borderRadius: "0",
                //     border: "none",
                //     fontWeight: "300",
                //     backgroundColor: "#F9FAFA",
                //     td: {
                //       fontFamily: "Cerebri sans !important",
                //       height: "50px",
                //       fontWeight: "300",
                //       fontSize: "14px",
                //       border: " 1px solid #F0F0F0",
                //     },
                //   },
                // }}
                // muiTableBodyProps={{
                //   sx: {
                //     fontFamily: "Cerebri sans !important",
                //     borderLeft: "1px solid black",
                //     "& tr:nth-of-type(even)": {
                //       backgroundColor: "#F9FAFA",
                //       borderRadius: "8px",
                //       fontWeight: "300",
                //     },
                //   },
                // }}
                // muiTableHeadCellProps={{
                //   //simple styling with the `sx` prop, works just like a style prop in this example
                //   sx: {
                //     fontWeight: "600",
                //     fontSize: "12px",
                //     fontFamily: "Cerebri sans !important",
                //     backgroundColor: "#F9FAFA",
                //   },
                // }}
              />
            }
          />

          {/* popup for opening pdf screening uploaded pdf */}
        </div>
      </div>

      <div className=" my-3 px-3 py-2 radius-8 customers-card-layout">
        <div className="fs-16 fw-600 mt-1 d-flex justify-content-between">
          <span className="me-2">Documents</span>{" "}
          <div className="d-flex justify-content-between gap-4">
            <ETButton
              disabled={
                getDocumentDetailsByContractId?.data?.filter(
                  (item) => item?.is_signing == 0
                )?.length === 0 && true
              }
              variant={"secondary"}
              buttonText="DOWNLOAD ALL"
              rotate={true}
              onClick={() => handleDownloadAllDocAsZip(0)}
              width="max-content"
            />
            <ETButton
              variant={"secondary"}
              buttonText="ADD DOCUMENT"
              rotate={true}
              icon={true}
              onClick={() => setOpenUploadDocumentManually(true)}
              width="max-content"
            />
          </div>
        </div>
        <div>
          <MRTTheme
            colorVariantsForTableData={["even"]}
            colorVariantsForTableHead="gray"
            table={
              <MaterialReactTable
                columns={manualDocumemntsColumns}
                data={
                  getDocumentDetailsByContractId?.data?.filter(
                    (item) => item?.is_signing == 0
                  ) || []
                }
                icons={{ ...SortIcon }}
                renderEmptyRowsFallback={() => (
                  <div className="d-flex justify-content-center w-100 py-4 grey-text">
                    <div>You do not have any documents yet.</div>
                  </div>
                )}
                enableColumnActions={false}
                enableColumnFilters={false}
                // enablePagination={false}
                enableSorting={true}
                enableBottomToolbar={false}
                enableTopToolbar={false}
                muiTableBodyRowProps={{ hover: false }}
                enableRowActions
                positionActionsColumn="last"
                renderRowActions={({ cell, row, table }) => (
                  <div className="d-flex gap-2">
                    <ShowTooltip
                      tooltipLabel={
                        <button
                          className="icon-btn"
                          onClick={() => {
                            handleViewDocument(row);
                          }}
                        >
                          <FontAwesomeIcon
                            icon="fa-regular fa-file-magnifying-glass"
                            style={{ color: "#000000" }}
                          />
                        </button>
                      }
                      tooltopWidth={60}
                      tooltipContent={<div className="p-1 fs-12">View</div>}
                    />

                    <ShowTooltip
                      tooltipLabel={
                        <button className="icon-btn">
                          <FontAwesomeIcon icon="fa-regular fa-circle-info" />
                        </button>
                      }
                      tooltopWidth={180}
                      tooltipContent={
                        <>
                          <div className="p-1 fs-12">
                            {row?.original?.document_view_actor?.length == 0 ? (
                              <div>No details found</div>
                            ) : (
                              row?.original?.document_view_actor?.map(
                                (item, index) => (
                                  <div key={index} className="pb-1">
                                    <div className="form-label-title">
                                      {item?.full_name}
                                    </div>
                                    <div>
                                      <div>
                                        Seen on:{" "}
                                        {item?.seen_on == "" ||
                                        item?.seen_on == null
                                          ? "-"
                                          : moment(item?.seen_on)?.format(
                                              "DD MMM, YYYY"
                                            )}
                                      </div>
                                      <div>
                                        Read on:{" "}
                                        {item?.read_on == "" ||
                                        item?.read_on == null
                                          ? "-"
                                          : moment(item?.read_on)?.format(
                                              "DD MMM, YYYY"
                                            )}
                                      </div>
                                    </div>
                                  </div>
                                )
                              )
                            )}
                          </div>
                        </>
                      }
                    />
                    <ShowTooltip
                      tooltipLabel={
                        <button
                          className="icon-btn"
                          onClick={() =>
                            setShareDocumentPopup((prev) => ({
                              ...prev,
                              isShareDocumentPopupOpen: true,
                              documentUrl: cell.row.original?.file_url,
                              documentName: cell.row.original?.title,
                            }))
                          }
                        >
                          <FontAwesomeIcon icon="fa-regular fa-paper-plane" />
                        </button>
                      }
                      tooltopWidth={60}
                      tooltipContent={<div className="p-1 fs-12">Share</div>}
                    />
                    <ShowTooltip
                      tooltipLabel={
                        <button
                          className="icon-btn"
                          onClick={() =>
                            setDeletePdfPopUp((prev) => ({
                              ...prev,
                              isDeletePopupOpen: true,
                              document_id: cell.row.original?.document_id,
                              actor_id: cell.row.original?.actor_id,
                            }))
                          }
                        >
                          <FontAwesomeIcon icon="fa-regular fa-trash" />
                        </button>
                      }
                      tooltopWidth={70}
                      tooltipContent={<div className="p-1 fs-12">Delete</div>}
                    />
                    <ShowTooltip
                      tooltipLabel={
                        <button
                          className="icon-btn"
                          onClick={() =>
                            handleDownloadDocument(cell?.row?.original)
                          }
                        >
                          <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-line" />
                        </button>
                      }
                      tooltopWidth={80}
                      tooltipContent={<div className="p-1 fs-12">Download</div>}
                    />
                  </div>
                )}
                muiTableContainerProps={{
                  sx: {
                    padding: "0px !important",
                  },
                }}
                muiTableBodyCellProps={({ cell, row }) => ({
                  onClick: (event) => {
                    if (cell.id.includes("title")) {
                      handleViewDocument(row);
                    }
                  },
                })}

                // muiTablePaperProps={{
                //   elevation: 0,
                //   sx: {
                //     fontFamily: "Cerebri sans !important",
                //     borderRadius: "0",
                //     border: "none",
                //     fontWeight: "300",
                //     backgroundColor: "#F9FAFA",
                //     td: {
                //       fontFamily: "Cerebri sans !important",
                //       height: "50px",
                //       fontWeight: "300",
                //       fontSize: "14px",
                //       border: " 1px solid #F0F0F0",
                //     },
                //   },
                // }}
                // muiTableBodyCellProps={{
                //   sx: {
                //     fontFamily: "Cerebri sans !important",
                //     "& tr:nth-of-type(even)": {
                //       backgroundColor: "#F9FAFA !important",
                //       borderRadius: "8px",
                //       fontWeight: "300",
                //     },
                //   },
                // }}
              />
            }
          />

          {/* View document popup*/}
          <CustomPopup
            open={viewDocumentPopup?.isViewDocumentPopupOpen}
            onHide={() =>
              setViewDocumentPopup((prev) => ({
                ...prev,
                isViewDocumentPopupOpen: false,
              }))
            }
            modalHeaderText={true}
            title={"Document"}
            subTitle={viewDocumentPopup?.documentName}
            size="lg"
            title_color="black"
            enableCrossButton={true}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={true}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
            deletePopUpStyle={true}
            titleTooltip={false}
          >
            <div>
              <Row>
                <Col className="">
                  <div style={{ height: "540px" }}>
                    <iframe
                      src={viewDocumentPopup?.documentUrl}
                      height="100%"
                      width="100%"
                    ></iframe>
                  </div>
                </Col>
              </Row>
            </div>
            <div
              className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
              style={{
                backgroundColor: "#F9FAFA",
                borderRadius: " 0px 0px 8px 8px",
                border: "1px solid #F0F0F0",
              }}
            >
              {/* <ETButton
      variant={"white"}
    
        buttonText="CANCEL"
   
        onClick={() => setOpenViewDocumentPopUp(false)}
      />
      <ETButton
        variant={"primary"} 
        buttonText="SAVE"
        onClick={() => setOpenViewDocumentPopUp(false)}
      /> */}
            </div>
          </CustomPopup>
          {/* View document popup */}

          {/* popup for deleting screening uplaoded pdf */}
          <CustomPopup
            open={deletePdfPopUp?.isDeletePopupOpen}
            onHide={() =>
              setDeletePdfPopUp((prev) => ({
                ...prev,
                isDeletePopupOpen: false,
              }))
            }
            title="Are you sure you want to delete?"
            title_color="black"
            enableCrossButton={false}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={true}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
            deletePopUpStyle={true}
          >
            <div className="p-3">
              <p style={{ fontSize: "14px", fontWeight: "300" }}>
                Did you know that by deleting this document it will affect the
                ongoing screening process. After deleting the tenant will get
                notified and asked for a new upload of the document.
              </p>
            </div>
            <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
              <ETButton
                variant={"white"}
                buttonText="COLD FEET"
                onClick={() =>
                  setDeletePdfPopUp((prev) => ({
                    ...prev,
                    isDeletePopupOpen: false,
                  }))
                }
              />
              <ETButton
                disabled={deleteArchiveApiIsLoading}
                variant={"primary"}
                buttonText="I’M SURE"
                onClick={() => handleDeleteDocument()}
              />
            </div>
          </CustomPopup>
          {/* popup for deleting screening uplaoded pdf */}

          {/* ----------------------- Add document from computer Popup -------------------- */}
          <CustomPopup
            open={openUploadDocumentManually}
            onHide={() => setOpenUploadDocumentManually(false)}
            title="Add document from computer"
            title_color="black"
            enableCrossButton={false}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={true}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
            deletePopUpStyle={true}
            titleTooltip={true}
            size="xl"
          >
            <AddDocument
              subHeading={
                "Drag and drop documents directly from your computer. It is possible to change the name of the document and select a document type. Once you have selected and customized the documents, click add and they will be added."
              }
              openUploadDocumentManually={openUploadDocumentManually}
              setOpenUploadDocumentManually={setOpenUploadDocumentManually}
              handledSelectedPdf={handleAddDocumentFromComputer}
              type={"DOCAC"}
              isApiLoading={PostUploadActorDocumentIsLoading}
              is_identification_document={true}
              actorDropdownData={actorByContractIdData?.data}
            />
          </CustomPopup>

          {/* ---------------------------Share document popup-----------------*/}
          <CustomPopup
            open={shareDocumentPopup?.isShareDocumentPopupOpen}
            onHide={() => {
              setShareDocumentPopup((prev) => ({
                ...prev,
                isShareDocumentPopupOpen: false,
              }));
              reset();
            }}
            modalHeaderText={true}
            title={"Share document"}
            subTitle={shareDocumentPopup?.isShareDocumentPopupOpen}
            size="md"
            title_color="black"
            enableCrossButton={true}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={false}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
            deletePopUpStyle={true}
            titleTooltip={false}
          >
            <Form
              autoComplete="off"
              onSubmit={handleSubmit(handleSendDocument)}
            >
              <div>
                <div className="px-4 pt-4 pb-3">
                  <div className="form-label-title pb-2">
                    Share this document
                  </div>
                  <Row>
                    <Col>
                      <div className="d-flex gap-2">
                        <Controller
                          name="link"
                          control={control}
                          {...register("link")}
                          rules={{ required: false }}
                          render={({ field: { onChange, value } }) => (
                            <div className="form-input">
                              <input
                                placeholder="Link"
                                id="link"
                                value={shareDocumentPopup?.documentName}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                maxLength={30}
                              />
                              <label htmlFor="link">Link</label>
                            </div>
                          )}
                        />

                        <ETButton
                          variant={"primary"}
                          buttonText="Copy link"
                          onClick={handleCopyLink}
                        />
                      </div>
                      {errors?.link && (
                        <p className="error-msg"> {REQUIRED_ERR}</p>
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="px-4 pb-4">
                  <div className="form-label-title">
                    Send document by E-mail
                  </div>
                  <Row className="py-2">
                    <Col>
                      <Controller
                        name="email"
                        control={control}
                        {...register("email")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div className="form-input">
                            <input
                              type="email"
                              placeholder="Send e-mail to"
                              id="email"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              maxLength={30}
                            />
                            <label htmlFor={"email"}>Send e-mail to</label>
                          </div>
                        )}
                      />
                      {errors?.email && (
                        <p className="error-msg"> {REQUIRED_ERR}</p>
                      )}
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col>
                      <Controller
                        name="subject"
                        control={control}
                        {...register("subject")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div className="form-input">
                            <input
                              placeholder="Send e-mail to"
                              id="subject"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                            <label htmlFor="subject">Subject</label>
                          </div>
                        )}
                      />
                      {errors?.subject && (
                        <p className="error-msg"> {REQUIRED_ERR}</p>
                      )}
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col>
                      <Controller
                        name="message"
                        control={control}
                        {...register("message")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div className="form-float openhouse-des">
                            <textarea
                              style={{ resize: "none" }}
                              name="message"
                              className="inputText"
                              cols="20"
                              rows="3"
                              id="message"
                              placeholder=""
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                            <label
                              htmlFor="description"
                              className="floating-label"
                            >
                              Message
                            </label>
                          </div>
                        )}
                      />
                      {errors?.message && (
                        <p className="error-msg"> {REQUIRED_ERR}</p>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
              <div
                className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
                style={{
                  backgroundColor: "#F9FAFA",
                  borderRadius: " 0px 0px 8px 8px",
                  border: "1px solid #F0F0F0",
                }}
              >
                <ETButton
                  variant={"white"}
                  buttonText="CANCEL"
                  onClick={() =>
                    setShareDocumentPopup((prev) => ({
                      ...prev,
                      isShareDocumentPopupOpen: false,
                    }))
                  }
                />
                <ETButton
                  variant={"primary"}
                  buttonText="SEND DOCUMENT"
                  type={"submit"}
                  disabled={PostShareDocumentsIsLoading}
                  // onClick={() => setOpenViewDocumentPopUp(false)}
                />
              </div>
            </Form>
          </CustomPopup>
          {/* -------------------------Share document popup -----------------------*/}
        </div>
      </div>
    </div>
  );
};

export default Documents;
