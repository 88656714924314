import { Endpoint, Site } from "./API/Endpoints";
import { Delete, Get, Post, Put } from "./API/Method";

// Get media package by property Id
export const GetMediaPackageByPropertyId = (propertyId) => {
	propertyId =
		propertyId == "" || propertyId == null || propertyId == undefined
			? 0
			: propertyId;

	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.Get_MediaPackage_By_PropertyId,
			null,
			propertyId + "/packages"
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Delete media package by package ID
export const DeleteMediaPAckageByPackageID = (propertyId, packageId) => {
	return new Promise((resolve, reject) => {
		Delete(
			Site.ET,
			Endpoint.ET.Delete_MediaPackage,
			null,
			propertyId + "/packages/" + packageId
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Get media sort order
export const GetMediaSortOrder = () => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Get_Media_Sort_Order, null)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Get media category
export const GetMediaCategory = () => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Get_Media_Category, null)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Post Upload media
export const PostUploadMedia = (data) => {
	return new Promise((resolve, reject) => {
		Post(
			Site.ET,
			Endpoint.ET.Post_Upload_Media,
			{ "Content-Type": "multipart/form-data" },
			data
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Delete Media - Delete media file id media fileID
export const DeleteMediaFileByMediaFileId = (mediaFileId) => {
	return new Promise((resolve, reject) => {
		Delete(
			Site.ET,
			Endpoint.ET.Delete_MediaFile_ByMediaFileId,
			null,
			mediaFileId
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Post Add media package
export const PostAddMediaPAckage = (data) => {
	return new Promise((resolve, reject) => {
		Post(Site.ET, Endpoint.ET.Post_Add_MediaPackage, null, data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Put Update media file category
export const PutUpdateMediaFileCategory = (data) => {
	return new Promise((resolve, reject) => {
		Put(Site.ET, Endpoint.ET.Put_Update_MediaFile_Category, null, data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Put Update media details
export const PutUpdateMediaDetails = (data) => {
	return new Promise((resolve, reject) => {
		Put(Site.ET, Endpoint.ET.Put_Update_MediaFileDetails, null, data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Get media details by media Id
export const GetMediaDetailsByMediaId = (mediaId) => {
	mediaId =
		mediaId == "" || mediaId == null || mediaId == undefined ? 0 : mediaId;
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Get_MediaDetails_By_MediaId, null, mediaId)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};
//Get media package by package Id
export const GetMediaPackageByPackageId = (propertyId, packageId) => {
	propertyId =
		propertyId == "" || propertyId == null || propertyId == undefined
			? 0
			: propertyId;
	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.Get_MediaPackage_By_PackageId,
			null,
			propertyId + "/packages/" + packageId
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};
//Get media files by media package Id
export const GetMediaFilesByPackageId = (packageId) => {
	packageId =
		packageId == "" || packageId == null || packageId == undefined
			? 0
			: packageId;
	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.Get_MediaFiles_By_MediaPackageId,
			null,
			packageId + "/packages"
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Put Update media package basic details
export const PutUpdateMediaPackageBasicDetails = (data) => {
	return new Promise((resolve, reject) => {
		Put(Site.ET, Endpoint.ET.Put_Update_MediaPackage_BasicDetails, null, data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Put Update media package complete details
export const PutUpdateMediaPackageCompleteDetails = (data) => {
	return new Promise((resolve, reject) => {
		Put(
			Site.ET,
			Endpoint.ET.Put_Update_MediaPackage_CompleteDetails,
			null,
			data
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Post Clone media package
export const PostCloneMediaPackage = (clonePayload) => {
	return new Promise((resolve, reject) => {
		Post(Site.ET, Endpoint.ET.Post_Clone_MediaPackage, null, clonePayload)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Get media files by property Id
export const GetMediaFilesByPropertyId = (propertyId) => {
	propertyId =
		propertyId == "" || propertyId == null || propertyId == undefined
			? 0
			: propertyId;
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Get_MediaFiles_By_PropertyId, null, propertyId)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

// Get Media by Unit Id
export const GetMediaByUnitId = (propertyId, unitId) => {
	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.Get_Media_ByUnitId,
			null,
			propertyId + "/unit/" + unitId
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Put Update media publish details
export const UpdateMediaPublishDetails = (data) => {
	return new Promise((resolve, reject) => {
		Put(Site.ET, Endpoint.ET.Media_UpdateMediaPublishDetails, null, data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};
