import React from "react";
import "./Buttons/CustomButton.css";
import Modal from "react-bootstrap/Modal";
import CustomToolTip from "./Tooltip/CustomTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H3 } from "../styles/Common.style";
// import { external } from "jszip";
import Heart from "./Star/Heart";

export default function CustomPopup({
  size,
  enableCrossButton,
  open,
  onHide = () => {}, // default void function added by -- Suraj
  title,
  title_color,
  titleFont,
  titleTooltip,
  titleTooltipText,
  children,
  modalHeaderText,
  editImageDetails,
  headerRequired,
  subTitle,
  deadline,
  deadlineTime,
  extraInfo,
  extraInfoText,
  likeFeature,
  likeIconClickHandle = () => {}, //added by Suraj on 2023-11-09 for onclick on heart icon button
  isLiked, // added by Viraj to display liked heart
  onClickCrossButton = () => {}, //this used for closing pop up on clicking cross button and default void function added by -- Suraj
}) {
  return (
    <>
      <Modal show={open} onHide={onHide} size={size} centered>
        {headerRequired == true && (
          <Modal.Header
            style={{ backgroundColor: "#fff" }}
            className={enableCrossButton === true ? "py-3 px-4" : "p-4"}
          >
            <Modal.Title
              className="d-flex align-items-start "
              style={{
                fontSize: titleFont,
                color: title_color,
              }}
            >
              {modalHeaderText || (editImageDetails && true) ? (
                <div
                  className="d-flex align-items-center"
                  style={{ flexDirection: "column" }}
                >
                  <div>
                    <H3
                      className={
                        enableCrossButton === true
                          ? "me-2 d-inline"
                          : "mt-2 d-inline "
                      }
                    >
                      {title}
                    </H3>
                    {titleTooltip === true && (
                      <div
                        className={
                          enableCrossButton === true
                            ? "d-inline-block"
                            : "ms-2 mt-2 d-inline-block"
                        }
                      >
                        <CustomToolTip text={titleTooltipText} />
                      </div>
                    )}
                  </div>
                  <div className="created-by fs-12">{subTitle}</div>
                </div>
              ) : (
                <>
                  <div>
                    <H3
                      className={
                        enableCrossButton === true
                          ? "me-2 d-inline"
                          : "my-2 d-inline"
                      }
                    >
                      {title}
                    </H3>
                    {titleTooltip === true && (
                      <div
                        className={
                          enableCrossButton === true
                            ? "d-inline-block"
                            : "ms-2  d-inline-block"
                        }
                      >
                        <CustomToolTip text={titleTooltipText} />
                      </div>
                    )}
                  </div>
                </>
              )}
            </Modal.Title>

            <div>
              <div className="d-flex gap-3 align-items-center">
                {likeFeature === true && (
                  <>
                    {" "}
                    <Heart
                      isFavorite={"0"}
                      handleClick={() => likeIconClickHandle()}
                    />
                    {/* <FontAwesomeIcon
                      icon={"fa-solid fa-heart"}
                      size="lg"
                      className="cursor-pointer"
                      style={{
                        stroke: "#AA1518",
                        strokeWidth: "30px",
                        color: isLiked ? "#AA1518" : "#AA151833",
                      }}
                      onClick={() => likeIconClickHandle()}
                    /> */}
                  </>
                )}
                {extraInfo === true && (
                  <div className="light-font-text " style={{ color: "#888" }}>
                    {extraInfoText}
                  </div>
                )}

                {enableCrossButton === true && (
                  <button
                    style={{
                      height: "44px",
                      width: "44px",
                      borderRadius: "8px",
                      position: "relative",
                      backgroundColor: "#FFF",
                      border: "1px solid #D1D1D1",
                    }}
                    type="button"
                    className="close"
                    onClick={() => {
                      // onHide();
                      onClickCrossButton();
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span className="fs-20" aria-hidden="true">
                      <FontAwesomeIcon
                        icon="fa-regular fa-xmark-large"
                        style={{ color: "#000000" }}
                      />
                    </span>
                  </button>
                )}
              </div>
            </div>
            {deadline == true && (
              <div className="d-flex align-items-baseline">
                <span className="fw-300 fs-16">Deadline expires in:</span>
                <span className="px-1 fs-20 font-medium">{deadlineTime}</span>
              </div>
            )}
          </Modal.Header>
        )}

        <Modal.Body className="p-0" style={{}}>
          {children}
        </Modal.Body>
      </Modal>
    </>
  );
}

CustomPopup.defaultProps = {
  // title: "Are you sure you want to remind?",
  title_color: "black",
  titleFont: "14px",
  titleTooltip: false,
  deadline: false,
  action_button_label: "YES",
  action_button_label_color: "white",
  action_button_color: "#619130",
  cancel_button: true,
  cancel_button_bordercolor: "black",
  cancel_button_label: "NO",
  cancel_button_label_color: "black",
  cancel_button_color: "#fff",
  footer_color: "#F9FAFA",
  size: "md",
  headerRequired: true,
};
