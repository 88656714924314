import React, { useState } from "react";
import DistributionOfAllScreening from "./screeningPages/DistributionOfAllScreening";
import DistributionOfRejections from "./screeningPages/DistributionOfRejections";
import DividedOnAgents from "./screeningPages/DividedOnAgents";
import ActiveScreenings from "./screeningPages/ActiveScreenings";
import ActiveContract from "./screeningPages/ActiveContract";
// import CreateAgreement from "./screeningPages/createAgreement/CreateAgreement";
// import MultipleCreateAgreement from "./screeningPages/createAgreement/MultipleCreateAgreement";
// import { SelectCustomer } from "./popups/SelectCustomer";
// import RestartScreening from "./popups/RestartScreening";

const Screenings = ({}) => {
  // states
  // const [approveAgreement, setApproveAgreement] = useState(false);
  // const [contractDeadline, setContractDeadline] = useState(false);
  // const [multipleApproveScreening, setMultipleApproveScreening] =
  //   useState(false);
  // const [followUp, setFollowUp] = useState(false);
  // const [restart, setrestart] = useState(false);
  return (
    <div className="p-lg-4 p-3  containerStyle ">
      <div className="d-flex flex-column gap-4">
        <DistributionOfAllScreening />
        <DistributionOfRejections />
        <DividedOnAgents />
        <ActiveContract />
        <ActiveScreenings />
        {/* <button
          onClick={() => {
            setFollowUp(true);
          }}
        >
          CLICK
        </button>
        <button
          onClick={() => {
            setrestart(true);
          }}
        >
          CLICK
        </button>

        <FollowUp
          followUp={followUp}
          setFollowUp={setFollowUp}
        />

        <CreateAgreement
          setApproveAgreement={setApproveAgreement}
          approveAgreement={approveAgreement}
        />
        <SelectCustomer
          contractDeadline={contractDeadline}
          setContractDeadline={setContractDeadline}
        />
        <MultipleCreateAgreement
          setMultipleApproveScreening={setMultipleApproveScreening}
          multipleApproveScreening={multipleApproveScreening}
        />
        <RestartScreening
          setrestart={setrestart}
          restart={restart}
          priorityText={"Dummy data"}
        /> */}
      </div>
    </div>
  );
};

export default Screenings;
