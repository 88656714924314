import ETButton from "../../Components/Buttons/ETButton";
import CustomPopup from "../../Components/CustomPopup";
import { usePostAddEvent } from "../../hooks/queries/useEventsQueries";
import { useEventStore } from "../../hooks/stores/useEventStore";
import { usePropertyOverview } from "../../hooks/stores/usePropertyStore";
import { toast } from "react-toastify";
import moment from "moment";
import { status } from "../../model/Constant";
const Publishevent = ({
  publishEvents,
  setPublishEvents,
  showPrivateEventPage,
  getValues,
  // internalNote,
  endEventValue,
  website,
  eido,
  AssignAgentsData,
  schedule,
  numberOfParticipant,
  intervalTimeDuration,
  breakTime,
  setShowOpenHouseProperty,
  maximumBooking,
  numberOfBooking,
  cityName,
  selectedUnitsId,
}) => {
  //-------------------Zustand--------------------------//

  const { typeOfEvents, booking, prescreening, qrCodeGenerate } = useEventStore(
    (state) => state
  );
  const propOverview = usePropertyOverview((state) => state.propOverview);
  const { eventOn } = useEventStore((state) => state);

  //----------------react query----------------//
  const { mutateAsync: postAddEvent, isLoading: postAddEventIsLoading } =
    usePostAddEvent();

  const EventName = getValues("nameofEvent");
  const Streetname = getValues("streetName");
  const Number = getValues("no");
  const AddInfo = getValues("addInfo");
  const Country = getValues("country");
  const Zipcode = getValues("zipcode");
  const Latitude = getValues("latitude");
  const Longitude = getValues("longitude");
  const starttime = getValues("timeTo");
  const endTime = getValues("timeFrom");
  const EventStartingDate = getValues("eventStartingDate");
  const EventRepetition = getValues("events");
  const internalNote = getValues("internalNote");
  const eventEndDateRepetition = getValues("eventEndDate");
  const eventRepetitionDays = getValues("eventRepetitionDays");
  const detailedInfo = getValues("detailedInfo");
  const responsibleAgent = getValues("responsibleAgent");

  function convertTimeFormat(time, numberOfParticipants) {
    const [start, end] = time.split(" - ");
    return {
      time_from: `${start}:00`,
      time_to: `${end}:00`,
      nof_participants: numberOfParticipants,
    };
  }

  const formattedTimes = schedule?.map((time, index) =>
    convertTimeFormat(time, numberOfParticipant[index])
  );

  const handlePublish = async () => {
    const PublishEvent = {
      property_id: propOverview?.[0]?.property_id?.toString(),
      event_type_id: typeOfEvents?.toString(),
      time_start: moment(starttime, "hh:mm:ss").format("hh:mm:ss"),
      time_end: moment(endTime, "hh:mm:ss").format("hh:mm:ss"),
      responsible_agent_user_id: responsibleAgent?.value?.toString(),
      street_name: Streetname?.toString(),
      house_number: Number?.toString(),
      side_door: "1",
      zipcode_id: Zipcode?.value?.toString(),
      add_info: AddInfo?.toString(),
      name: EventName?.toString(),
      country_id: Country?.value?.toString(),
      latitude: Latitude?.toString(),
      longitude: Longitude?.toString(),
      internal_note: internalNote?.toString(),
      frequency: EventRepetition?.value ? EventRepetition?.value : "",
      end_date_for_repetition: eventEndDateRepetition
        ? eventEndDateRepetition
        : "",
      number_of_repetitions: eventRepetitionDays ? eventRepetitionDays : "",
      max_number_of_bookings: numberOfBooking ? numberOfBooking : "0",
      time_slot_duration: intervalTimeDuration,
      break_duration: breakTime,
      nof_participants_per_slot: "5",
      event_date: EventStartingDate?.toString(),
      is_booking_required: booking == 1 ? "1" : "0",
      has_prescreening: prescreening == 1 ? "1" : "0",
      generate_qr_code: qrCodeGenerate == 1 ? "1" : "0",
      pre_screening_url: "1",
      publish_on_website: website ? "1" : "0",
      publish_in_eido: eido ? "1" : "0",
      handle_bookings_by: "1",
      prescreening_template_id: "1",
      is_draft: "0",
      event_on: "2",
      detailed_info: detailedInfo,
      event_repetition_type: endEventValue?.toString(),
      timeslots: formattedTimes,
      agents: AssignAgentsData?.map((item) => {
        return {
          agent_user_id: item?.user_id,
        };
      }),
      event_unit:
        eventOn === 2
          ? selectedUnitsId?.map((item) => {
              return {
                unit_id: item?.toString(),
              };
            })
          : [
              {
                unit_id: "",
              },
              {
                unit_id: "",
              },
            ],
    };

    let id;
    try {
      if (!postAddEventIsLoading) {
        id = toast.loading("Event is being created....");
      }
      const response = await postAddEvent(PublishEvent);
      if (response.status === status.s && response.data.bReturn) {
        toast.update(id, {
          render: response?.data?.message,
          isLoading: false,
          type: status.s,
          autoClose: 2000,
        });
        setShowOpenHouseProperty(false);
      } else {
        toast.update(id, {
          render: response?.data?.message,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.update(id, {
        render: error?.message,
        isLoading: false,
        type: status.e,
        autoClose: 2000,
      });
    }
  };
  return (
    <div>
      <CustomPopup
        open={publishEvents}
        onHide={() => setPublishEvents(false)}
        title={showPrivateEventPage ? "Create event" : " Publish event"}
        title_color="black"
        size={"md"}
        titleTooltip={true}
        titleTooltipText={"Publish event"}
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        {" "}
        <>
          {showPrivateEventPage ? (
            <div className="px-4 py-3 c-black">
              <div className="light-font-text c-black mb-4">
                <p className="m-0">
                  Your event is about to be published. To make sure everything
                  is correct we have listed some general information about the
                  event.
                </p>
              </div>
              <div
                className="radius-8"
                style={{ border: "1px solid #F0F0F0", background: "#f9fafa" }}
              >
                <div className="w-100">
                  <div className="p-3">
                    <div className="fs-14">Name of event</div>
                    <div className="light-font-text mb-2">
                      Mads Johnson and Caroline Johnson - Private viewing
                    </div>
                    <div className="fs-14">Address</div>
                    <div className="light-font-text mb-2">
                      Dybbølsbro 3, 1, 2300 København S
                    </div>
                    <div className="fs-14">Unit</div>
                    <div className="light-font-text mb-2">
                      Dybbølsbro 3, 1, 2300 Køvenhavn S | 7-W260
                    </div>
                    <div className="fs-14">Date and time</div>
                    <div className="light-font-text mb-2">
                      Sunday 6 January, 2023 at 11:00 - 12:00
                    </div>

                    <div className="fs-14">Assign agents to the event</div>
                    <div className="light-font-text mb-2 ">
                      Assign agents to the event
                    </div>
                    <div className="fs-14">
                      Send booking confirmation to the participant
                    </div>
                    <div className="light-font-text mb-2">Yes</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="px-4 py-3 c-black">
              <div className="light-font-text c-black mb-4">
                <p className="m-0">
                  Your event is about to be published. To make sure everything
                  is correct we have listed some general information about the
                  event.
                </p>
              </div>
              <div
                className="radius-8"
                style={{ border: "1px solid #F0F0F0", background: "#f9fafa" }}
              >
                <div className="w-100 d-flex">
                  <div
                    className="p-3"
                    style={{ width: "65%", borderRight: "1px solid #f0f0f0" }}
                  >
                    <div className="fs-14">Name of event</div>
                    <div className="light-font-text mb-2">{EventName}</div>
                    <div className="fs-14">Address</div>
                    <div className="light-font-text mb-2">
                      {/* Blomstervej 14, 3. tv 2765 Smørum */}
                      {Streetname + " "}
                      {Number + ","} {Zipcode?.value + " "} {cityName}
                    </div>

                    <div className="fs-14">Date and time</div>
                    {/* <div className="light-font-text">
                      Repeated event every Sunday at 10:00 - 11:00.
                    </div> */}
                    <div className="light-font-text mb-2">
                      {" "}
                      {/* Starting at: 6 January 2023 - 27 Jan 2023 Starting at:{" "} */}
                      Starting at:{" "}
                      {moment(EventStartingDate, "YYYY-MM-DD").format(
                        "D MMMM YYYY"
                      )}
                      -
                      {moment(eventEndDateRepetition, "YYYY-MM-DD").format(
                        "D MMM YYYY"
                      )}
                    </div>

                    <div className="fs-14">Assign agents to the event</div>
                    <div className="light-font-text ">
                      {/* {AssignAgentsName + ", "} */}
                      {AssignAgentsData?.map((item) => {
                        return item?.full_name + ",";
                      })}
                    </div>
                  </div>
                  <div className="p-3" style={{ width: "35%" }}>
                    <div className="fs-14">Pre-screening</div>
                    <div className="light-font-text mb-2">
                      {prescreening == 1 ? "Yes" : "No"}
                    </div>

                    <div className="fs-14">Maximum booking</div>
                    <div className="light-font-text mb-2">
                      {maximumBooking == true ? "Yes" : "No"}
                    </div>

                    <div className="fs-14">QR-code</div>
                    <div className="light-font-text mb-2">
                      {qrCodeGenerate == 1 ? "Yes" : "No"}
                    </div>

                    <div className="fs-14">Publish on website</div>
                    <div className="light-font-text mb-2">
                      {website ? "Yes" : "No"}
                    </div>

                    <div className="fs-14">Publish on Ei.Do</div>
                    <div className="light-font-text mb-2">
                      {eido ? "Yes" : "No"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setPublishEvents(false)}
          />
          {showPrivateEventPage ? (
            <ETButton buttonText="CREATE" variant={"primary"} />
          ) : (
            <ETButton
              buttonText="PUBLISH"
              variant={"primary"}
              disabled={postAddEventIsLoading}
              onClick={() => handlePublish()}
            />
          )}
        </div>
      </CustomPopup>
    </div>
  );
};

export default Publishevent;
