import { create } from "zustand";

export const useEventStore = create((set) => ({
  typeOfEvents: 1,
  setTypeOfEvents: (value) => set({ typeOfEvents: value }),
  eventOn: 1,
  setEventOn: (value) => set({ eventOn: value }),
  booking: 1,
  setBooking: (value) => set({ booking: value }),
  prescreening: 1,
  setPrescreening: (value) => set({ prescreening: value }),
  qrCodeGenerate: 1,
  setQrCodeGenerate: (value) => set({ qrCodeGenerate: value }),
  website: 1,
  setWebsite: (value) => set({ website: value }),
  eido: 1,
  setEido: (value) => set({ eido: value }),
}));
