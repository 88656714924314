import React, { useState } from "react";
import { ThemeProvider } from "styled-components";

//  Unified UI primary color:-
const newPrimaryColor = "#F1664B";
/*---------------------------------*/
//  Unified tabs background color:-
const newTabsColor = "#2B3C49";
/*---------------------------------*/

export const customproperties = {
  id: "1",
  ContainerBackground: "rgba(48, 62, 75, 0.9)",
  Biocolor: "white",
  TextfieldLabelcolor: "#FFFFFF",
  // primary color used :-(buttons,checkbox,dropzone,loading bar,group-buttons,toggle/switch,tooltip,radiobutton,breadcrumbs,fonts,)
  primaryColor: newPrimaryColor,

  Textfieldcolor: "#FFFFFF",
  TextfieldBordercolor: "#FFFFFF",

  /*---------------Button variants------------------*/
  primarybtnBgColor: "#F1664B",
  primarybtnColor: "#FFFFFF",
  primarybtnBorderColor: "2.5px solid #F1664B",
  primarybtnHoverBgColor: "#E4583D",
  primarybtnFocusBgColor: "#E4583D",
  primarybtnHoverBorder: "2.5px solid #E4583D",
  primarybtnFocusBorder: "2.5px solid #FF907A",
  disabledprimarybtnBgColor: "#F1664B",
  /*---------------------------------*/
  secondarybtnBgColor: "#d1d1d1",
  secondarybtnColor: "#000000",
  secondarybtnBorderColor: "2.5px solid #d1d1d1",
  secondarybtnHoverBgColor: "#ABA5A5",
  secondarybtnFocusBgColor: "#D1D1D1",
  secondarybtnHoverBorder: "2.5px solid #ABA5A5",
  secondarybtnFocusBorder: "2.5px solid #888888",
  disabledsecondarybtnBgColor: "#D1D1D1",
  /*---------------------------------*/
  tertiarybtnBgColor: "#FFFFFF",
  tertiarybtnColor: "#000000",
  tertiarybtnBorderColor: "1px solid #D1D1D1",
  tertiarybtnHoverBgColor: "#F9FAFA",
  tertiarybtnFocusBgColor: "#F9FAFA",
  tertiarybtnHoverBorder: "1px solid #D1D1D1",
  tertiarybtnFocusBorder: "2.5px solid #D1D1D1",

  disabledtertiarybtnBgColor: "#FFFFFF",
  disabletertiarybtnBorderColor: "1px solid #D1D1D1",
  disablebtnColor: "#FFFFFF",
  /*---------------------------------*/

  transitionInUse: "all 0.2s ease-in",
  // tabs color used :-(tabs,menus,)
  tabsColor: newPrimaryColor,
  tabsBackgroundColor: newTabsColor,
  /*---------------------------------*/
  //  used for login pages:-
  FormLinkandTextboxCheckColor: "#FFFFFF",
  TextfieldDecoration: "1px solid #FFFFFF",
  /*---------------------------------*/
  date: "2020-09-12",
  Currency: 123456.49,
};
const Theme = ({ children }) => {
  //   const [mytheme, setMyTheme] = useState(customproperties);
  //   console.log(mytheme, "customproperties");
  // console.log(customproperties, "customproperties");
  const theme = {
    component: {
      button: {
        default: {
          bgColor: "#d1d1d1",
          color: "white",
          fontSize: "14px",
          border: "#F4F5F5",
        },
        primary: {
          bgColor: customproperties?.primarybtnBgColor
            ? customproperties?.primarybtnBgColor
            : "#F1664B",
          color: customproperties?.primarybtnColor
            ? customproperties?.primarybtnColor
            : "white",
          fontSize: "14px",
          borderColor: customproperties?.primarybtnBorderColor
            ? customproperties?.primarybtnBorderColor
            : "none",
          hoverBgColor: customproperties?.primarybtnHoverBgColor
            ? customproperties?.primarybtnHoverBgColor
            : "none",
          hoverBorder: customproperties?.primarybtnHoverBorder
            ? customproperties?.primarybtnHoverBorder
            : "none",
          focusBgColor: customproperties?.primarybtnFocusBgColor
            ? customproperties?.primarybtnFocusBgColor
            : "none",
          focusBorder: customproperties?.primarybtnFocusBorder
            ? customproperties?.primarybtnFocusBorder
            : "none",
          disabledBgColor: customproperties?.disabledprimarybtnBgColor
            ? customproperties?.disabledprimarybtnBgColor
            : "none",
        },
        secondary: {
          bgColor: customproperties?.secondarybtnBgColor
            ? customproperties?.secondarybtnBgColor
            : "#d1d1d1",
          color: customproperties?.secondarybtnColor
            ? customproperties?.secondarybtnColor
            : "black",
          fontSize: "14px",
          borderColor: customproperties?.secondarybtnBorderColor
            ? customproperties?.secondarybtnBorderColor
            : "none",
          hoverBgColor: customproperties?.secondarybtnHoverBgColor
            ? customproperties?.secondarybtnHoverBgColor
            : "none",
          focusBgColor: customproperties?.secondarybtnFocusBgColor
            ? customproperties?.secondarybtnFocusBgColor
            : "none",
          hoverBorder: customproperties?.secondarybtnHoverBorder
            ? customproperties?.secondarybtnHoverBorder
            : "none",
          focusBorder: customproperties?.secondarybtnFocusBorder
            ? customproperties?.secondarybtnFocusBorder
            : "none",
          disabledBgColor: customproperties?.disabledsecondarybtnBgColor
            ? customproperties?.disabledsecondarybtnBgColor
            : "none",
        },
        tertiary: {
          bgColor: customproperties?.tertiarybtnBgColor
            ? customproperties?.tertiarybtnBgColor
            : "#d1d1d1",
          color: customproperties?.tertiarybtnColor
            ? customproperties?.tertiarybtnColor
            : "black",
          fontSize: "14px",
          borderColor: customproperties?.tertiarybtnBorderColor
            ? customproperties?.tertiarybtnBorderColor
            : "none",
          hoverBgColor: customproperties?.tertiarybtnHoverBgColor
            ? customproperties?.tertiarybtnHoverBgColor
            : "none",
          focusBgColor: customproperties?.tertiarybtnFocusBgColor
            ? customproperties?.tertiarybtnFocusBgColor
            : "none",
          hoverBorder: customproperties?.tertiarybtnHoverBorder
            ? customproperties?.tertiarybtnHoverBorder
            : "none",
          focusBorder: customproperties?.tertiarybtnFocusBorder
            ? customproperties?.tertiarybtnFocusBorder
            : "none",
          disabledBgColor: customproperties?.disabledtertiarybtnBgColor
            ? customproperties?.disabledtertiarybtnBgColor
            : "none",
          disabledBorder: customproperties?.disabletertiarybtnBorderColor
            ? customproperties?.disabletertiarybtnBorderColor
            : "none",
        },
      },
      checkbox: {
        default: {
          color: "#619130",
        },
        primary: {
          color: customproperties?.primaryColor
            ? customproperties?.primaryColor
            : "#619130",
        },
      },
      tabs: {
        default: {
          color: "#619130",
        },
        primary: {
          color: customproperties?.tabsColor
            ? customproperties?.tabsColor
            : "#619130",
          backGroundColor: customproperties?.tabsBackgroundColor
            ? customproperties?.tabsBackgroundColor
            : "#619130",
        },
      },
    },

    colors: {
      red: "#F1664B",
      bgColor: customproperties?.Signinbuttonbgcolor
        ? customproperties?.Signinbuttonbgcolor
        : "#F1664B",
      lightBlue: "#AFDBD2",
      white: "#ffffff",
      primaryColor: customproperties?.primaryColor
        ? customproperties?.primaryColor
        : "#F1664B",
    },
    transition: {
      btnTransition: customproperties?.transitionInUse
        ? customproperties?.transitionInUse
        : "none",
    },
  };
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
