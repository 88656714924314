import React, { useState } from "react";
import { H2, H5 } from "../../../styles/Common.style";
import ETButton from "../../../Components/Buttons/ETButton";
import ETRadioButton from "../../../Components/RadioButton/ETRadioButton";

const PrimaryWorkArea = ({
  handleNext,
  commontypeData,
  radioInput,
  setRadioInput,
}) => {
  // handle radio input change
  const handleRadioChange = (e) => {
    setRadioInput(e.target.value);
  };

  return (
    <div className="whiteContainerStyle p-3">
      <div className="pb-40">
        <H2 className="mb-1">Create user</H2>
        <span className="caption-light">Step 4 out of 5</span>
      </div>

      <div className="pb-40">
        <div className="fs-14 fw-700 pb-4 ">
          Select your primary work area in the system
        </div>

        <div className="light-font-text pb-4">
          Choose which of the work areas below best describes your use of
          systems.
        </div>
        <div className="d-flex flex-column gap-2">
          {commontypeData?.map((type, index) => (
            <ETRadioButton
              name="primary_work"
              key={type?.Id}
              label={type?.Text}
              value={type?.Id}
              onChange={handleRadioChange}
            />
          ))}
        </div>
      </div>

      <ETButton
        variant={"primary"}
        buttonText="NEXT"
        type={"submit"}
        onClick={handleNext}
        disabled={!radioInput}
      />
    </div>
  );
};

export default PrimaryWorkArea;

// Created by ------> Yash
