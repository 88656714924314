import { ReactComponent as Textimage } from "../../../assets/icons/svg_icons/Loginusernameicon.svg";
import { ReactComponent as Passimage } from "../../../assets/icons/svg_icons/Loginusernameicon.svg";
import { Col, Row, Container, Form, Image } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import IconTextField from "../../../Components/GroupText/IconTextField";
import ETButton from "../../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../GeneralLogin.css";
import StandardImage from "../../../assets/images/StandardSignin.jpg";
import estatelogologin from "../../../assets/images/NewEstatetoolLogo.png";
import { H5 } from "../../../styles/Common.style";
import React, { useEffect, useState } from "react";
import {
  useGetValidateResetPassword,
  usePutChangeUserPassword,
} from "../../../hooks/queries/useUserQueries";
import { Controller, useForm } from "react-hook-form";
const SetNewPassword = () => {
  //================ React hook form =================//
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    clearErrors,
    reset,
    resetField,
    getFieldState,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  //================states=================//
  const [isDigit, setIsDight] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [password, setPassword] = useState("");
  const [atleastEightCharacter, setAtleastEightCharacter] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  // ?reset_id=SWc61ySelM6cP2q2XZB88ZoBxRE0rV2NTViogDE2NCwEF8chfhvjkHOWSrjchId6v34oa4K1g46MpXbkIvTGjA%3D%3D

  // const reset_id =
  //   "SWc61ySelM6cP2q2XZB88ZoBxRE0rV2NTViogDE2NCwEF8chfhvjkHOWSrjchId6v34oa4K1g46MpXbkIvTGjA%3D%3D1";

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const reset_id = searchParams.get("reset_id");

  //====================== React query ========================//

  const {
    data: GetValidateResetPasswordData,
    error: GetValidateResetPasswordError,
  } = useGetValidateResetPassword(reset_id);

  const {
    mutateAsync: PutChangeUserPassword,
    isLoading: PutChangeUserPasswordIsLoading,
  } = usePutChangeUserPassword();

  //================effect to handle password contains=================//

  useEffect(() => {
    setIsValid(
      password.length >= 8 &&
        specialChar &&
        isDigit &&
        isUpperCase &&
        isLowerCase
    );
  }, [password]);

  useEffect(() => {
    //Code 911 is for invalid token
    //Code 912 is for link expired
    if (
      GetValidateResetPasswordError?.code === 912 ||
      GetValidateResetPasswordError?.code === 911
    ) {
      navigate("/reset-password-link-expired", {
        state: { user_email: GetValidateResetPasswordError?.user_email },
      });
    }

    return () => {};
  }, [GetValidateResetPasswordError]);

  //===============Password handler ====================//

  const handleInputChange = (event) => {
    const value = event.target.value;
    setPassword(event.target.value);

    // Validate input value

    setIsDight(/\d/.test(value));
    setIsLowerCase(/[a-z]/.test(value));
    setIsUpperCase(/[A-Z]/.test(value));
    setAtleastEightCharacter(value.length >= 8);
    setSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(value));
    setIsValid(
      value.length >= 8 && specialChar && isDigit && isUpperCase && isLowerCase
    );
  };

  //===============Submit handler ====================//

  const handleResetPassword = async (data) => {
    setErrorMessage("");
    const payloadData = {
      changeUserPasswordPayload: {
        password: data?.password,
      },
      token: reset_id,
    };

    try {
      const response = await PutChangeUserPassword(payloadData);
      if (response?.data?.bReturn) {
        navigate("/");
      } else {
        setErrorMessage(response?.data?.message);
      }
    } catch (error) {
      setErrorMessage(error?.message);
    }
  };
  return (
    <>
      {customproperties?.map((data, id) => (
        <React.Fragment key={id}>
          <div
            key={id}
            className="Maindiv d-flex flex-column justify-content-center align-items-center pt-4 w-100 scrollable-container-y"
            style={{ backgroundImage: `url(${data?.backgroundimage})` }}
          >
            <Container>
              <Row className="d-flex justify-content-center align-items-center">
                <Col
                  md={7}
                  xxl={4}
                  xl={5}
                  lg={6}
                  sm={12}
                  className="px-xl-2 px-sm-0"
                >
                  <div
                    className="StandardForm row "
                    style={{
                      backgroundColor: data?.ContainerBackground,
                    }}
                  >
                    <div className="px-3" style={{ marginTop: "40px" }}>
                      <Row className="m-auto">
                        <div
                          className="d-flex justify-content-center align-items-center mb-5 p-1"
                          md={{ span: 6, offset: 3 }}
                          xs={{ span: 4, offset: 4 }}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <Image
                              width={"160px"}
                              height={"auto"}
                              src={data?.logo}
                              alt="logo"
                            />
                          </div>
                        </div>
                      </Row>

                      <div className="d-flex justify-content-center flex-column align-items-center mb-4 px-4">
                        <H5
                          className="mb-2"
                          style={{
                            color: data?.Biocolor,
                          }}
                        >
                          Reset your password
                        </H5>
                        <div
                          className="light-font-text px-3"
                          style={{
                            color: data?.Biocolor,
                            textAlign: "center",
                          }}
                        >
                          Enter a strong and secure password that will be used
                          to log in to the system.
                        </div>
                      </div>
                      <Form onSubmit={handleSubmit(handleResetPassword)}>
                        <div className="d-flex row  justify-content-center align-items-center ">
                          <Row>
                            <Col
                              className="pb-3 px-0"
                              xs={{ span: 8, offset: 2 }}
                            >
                              <H5
                                className="mb-2"
                                style={{
                                  color: data?.Biocolor,
                                }}
                              >
                                Password
                              </H5>
                              <div>
                                <Controller
                                  control={control}
                                  {...register("password")}
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <IconTextField
                                      id={"Password"}
                                      Icons={"fa-regular fa-lock-keyhole"}
                                      placeholder={"Password"}
                                      type="password"
                                      value={value}
                                      onChange={(e, ActionType) => {
                                        handleInputChange(e);
                                        onChange(e);
                                        clearErrors("password");
                                        setErrorMessage("");
                                      }}
                                      isError={errors?.password}
                                    />
                                  )}
                                />
                              </div>
                            </Col>
                            <Col
                              className="px-0 mb-3"
                              xs={{ span: 8, offset: 2 }}
                            >
                              <H5
                                className="mb-2"
                                style={{
                                  color: data?.Biocolor,
                                }}
                              >
                                Re-confirm password
                              </H5>
                              <div>
                                <Controller
                                  control={control}
                                  {...register("confirmPassword")}
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <IconTextField
                                      id={"Confirm password"}
                                      Icons={"fa-regular fa-lock-keyhole"}
                                      placeholder={"Confirm password"}
                                      type="password"
                                      onChange={(e, ActionType) => {
                                        onChange(e);
                                        clearErrors("confirmPassword");
                                        setErrorMessage("");
                                      }}
                                      isError={errors?.confirmPassword}
                                      value={value}
                                    />
                                  )}
                                />
                              </div>
                            </Col>
                          </Row>
                          {watch("password") !== watch("confirmPassword") &&
                            watch("confirmPassword") && (
                              <div className="error-msg font-medium fs-16 text-align-center mb-2">
                                Password does not match!
                              </div>
                            )}
                          {errorMessage && (
                            <div className="error-msg font-medium fs-16 text-align-center mb-2">
                              {errorMessage}
                            </div>
                          )}

                          <Col
                            className="pb-4 px-0"
                            xs={{ span: 8, offset: 1 }}
                          >
                            <div
                              style={{
                                color: data?.Biocolor,
                              }}
                              className="light-font-text mb-3"
                            >
                              Your password must contain:
                            </div>
                            <div className="d-flex flex-column gap-2">
                              <div className="d-flex align-items-center gap-2">
                                <FontAwesomeIcon
                                  icon={
                                    atleastEightCharacter
                                      ? "fa-solid fa-circle-check"
                                      : "fa-solid fa-circle-exclamation"
                                  }
                                  style={{
                                    color: atleastEightCharacter
                                      ? "#349032"
                                      : "#888888",
                                  }}
                                />
                                <span
                                  className="light-font-text fs-12"
                                  style={{
                                    color: data?.Biocolor,
                                  }}
                                >
                                  Eight or more characters
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <FontAwesomeIcon
                                  icon={
                                    specialChar
                                      ? "fa-solid fa-circle-check"
                                      : "fa-solid fa-circle-exclamation"
                                  }
                                  style={{
                                    color: specialChar ? "#349032" : "#888888",
                                  }}
                                />
                                <span
                                  className="light-font-text fs-12"
                                  style={{
                                    color: data?.Biocolor,
                                  }}
                                >
                                  At least one special character
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <FontAwesomeIcon
                                  icon={
                                    isLowerCase && isUpperCase
                                      ? "fa-solid fa-circle-check"
                                      : "fa-solid fa-circle-exclamation"
                                  }
                                  style={{
                                    color:
                                      isLowerCase && isUpperCase
                                        ? "#349032"
                                        : "#888888",
                                  }}
                                />
                                <span
                                  className="light-font-text fs-12"
                                  style={{
                                    color: data?.Biocolor,
                                  }}
                                >
                                  At least one upper and one lower case letter
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <FontAwesomeIcon
                                  icon={
                                    isDigit
                                      ? "fa-solid fa-circle-check"
                                      : "fa-solid fa-circle-exclamation"
                                  }
                                  style={{
                                    color: isDigit ? "#349032" : "#888888",
                                  }}
                                />

                                <span
                                  className="light-font-text fs-12"
                                  style={{
                                    color: data?.Biocolor,
                                  }}
                                >
                                  At least one number
                                </span>
                              </div>
                            </div>
                          </Col>

                          <div className="d-flex justify-content-center mb-4">
                            <ETButton
                              variant={"primary"}
                              buttonText="RESET PASSWORD"
                              type="submit"
                              disabled={
                                !isValid ||
                                watch("password") !==
                                  watch("confirmPassword") ||
                                PutChangeUserPasswordIsLoading
                              }
                            />
                          </div>
                        </div>

                        {/* <div
                          className="d-flex justify-content-start light-font-text"
                          style={{}}
                        >
                          <div>
                            <NavLink
                              to="/"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              <span className="d-flex light-font-text align-items-center gap-1 mb-3">
                                <FontAwesomeIcon
                                  icon="fa-regular fa-arrow-left-long"
                                  style={{
                                    color: "#f1664b",
                                    paddingTop: "2px",
                                  }}
                                />{" "}
                                <span
                                  style={{
                                    color: data?.FormLinkandTextboxCheckColor,
                                  }}
                                >
                                  {" "}
                                  Didn’t receive an email, try again
                                </span>
                              </span>
                            </NavLink>
                          </div>
                        </div> */}
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <div
              className="light-font-text my-4 px-3"
              style={{
                borderRadius: "1px",
                backgroundColor: "rgba(255, 255, 255, 0.50)",
                color: "#000",
              }}
            >
              This solution is powered by{" "}
              <span className="form-label-title " style={{ color: "#000" }}>
                estatetool
              </span>{" "}
              | © 2023 - All rights reserved
            </div>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default SetNewPassword;
const customproperties = [
  {
    id: "1",
    ContainerBackground: "#2B3C49F2",
    backgroundimage: StandardImage,
    logo: estatelogologin,
    Biocolor: "#ffffff",
    TextfieldLabelcolor: "#FFFFFF",
    TextfieldUsernameIcon: <Textimage />,
    TextfieldPassIcon: <Passimage />,
    Textfieldcolor: "#FFFFFF",
    TextfieldBordercolor: "#FFFFFF",
    TextfieldfontFamily: "Cerebri sans-light",
    Signinbuttonbgcolor: "#F1664B",
    FormLinkandTextboxCheckColor: "#FFFFFF",
    TextfieldDecoration: "1px solid #FFFFFF",
  },
];
