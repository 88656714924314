import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import Toggle from "../../../../../../../../../Components/Switch/Switch";
import ReactSelect from "../../../../../../../../../Components/DropDown/ReactSelect";
import Accordion from "../../../../../../../../../Components/Accordion/Accordion";
import StarRating from "../../../../../../../../../Components/rating/StarRating";

const FollowUpInfo = () => {
  const [favorite, setFavorite] = useState(null);

  const localizer = momentLocalizer(moment);
  const calendarConfig = {
    components: {
      // event: CustomEvent,
      toolbar: CustomToolbar,
      // eventWrapper: CustomEventWrapper,
    },
  };
  // console.log(favorite, "favorite");
  return (
    <div>
      {" "}
      <div className="pt-2 pb-3 px-4">
        <div className="   form-label-title">Rating</div>
        <div className="light-font-text grey-text">
          How interested where the participant(s)?
        </div>
        <div className="pt-1">
          <StarRating
            setFavoriteRating={setFavorite}
            favoriteRating={favorite}
          />
        </div>
      </div>
      <hr className="my-2"></hr>
      <div className="pt-2 pb-4 px-4">
        <div className="   form-label-title">Follow up date</div>
        <div className="light-font-text grey-text">
          Let us help you remember to follow up on this participant. The note is
          personal and only visible for you.
        </div>
        <div className="pt-3">
          <Row>
            <Col lg={3}>
              <div className="form-input ">
                <input
                  type="date"
                  placeholder="Follow up date"
                  id="Followupdate"
                  // value={value}
                  // onChange={(e) => {
                  // 	onChange(e);
                  // 	clearErrors("long");
                  // }}
                />
                <label htmlFor="Followupdate">Follow up date</label>
              </div>
            </Col>
          </Row>
        </div>
        <div className="pt-3">
          <div className="form-float openhouse-des">
            <textarea
              name=""
              class="inputText"
              cols="20"
              rows="2"
              placeholder=" "
              // value={value}
              // onChange={(e) => {
              //   onChange(e);
              //   clearErrors("altText");
              //   // setAltText(e.target.value);
              // }}
            ></textarea>
            <label className="floating-label">Follow up note</label>
          </div>
          <div className="light-font-text">31 out of 180 characters</div>
        </div>
      </div>
      <hr className="my-0"></hr>
      <div className="py-3 px-4">
        <div className="   form-label-title">Internal note</div>
        <div className="light-font-text grey-text">
          An internal note is saved on the customer and is visible to all other
          users in the system who have access to the property.
        </div>

        <div className=" py-4">
          <div className="deposit-text-area">
            <div className=" pt-2 mt-1">
              <div
                style={{ height: "219px ", overflowY: "scroll" }}
                className="scrollable-container-y px-4"
              >
                {/* {getNotesDetailsData?.data?.filter(
                  (el) => el?.category_id === 2
                )?.length > 0 ? (
                  getNotesDetailsData?.data
                    ?.filter((el) => el?.category_id === 2)
                    ?.map((item, index) => ( */}
                <div className="pb-2">
                  <Accordion
                    accordianIconAlignment="left"
                    accordianBorder={false}
                    bgColor={"#FFF599"}
                    accordianHeight={"40px"}
                    titleDate={
                      <div className="fs-10">
                        {/* {moment(item?.created_on)?.format(
                        "DD MMM, YYYY [at] hh:mm"
                      )} */}
                        20 May, 2023
                      </div>
                    }
                    title={
                      <div className="d-flex justify-content-between">
                        <div className="form-label-title ">
                          Blomstervangen 13, 2.tv, 2765 Smørum | 212-005-002
                        </div>
                      </div>
                    }
                    contentBody={
                      <div className="ps-5 pb-2">
                        <div className="d-flex gap-1 align-items-center">
                          <div
                            style={{
                              // backgroundColor: item?.bg_color || "#9370DB",
                              backgroundColor: "#9370DB",
                              color: "white",
                              borderRadius: "4px",
                              fontSize: "9px",
                              alignItems: "center",
                              textTransform: "uppercase",
                              height: "16px",
                            }}
                            className="px-2 font-semibold  d-flex"
                          >
                            {/* {item?.category_name} */}
                            EVENT
                          </div>
                          <div className=" font-semibold fs-14">
                            {/* {item?.subject}
                             */}
                            Subject line
                          </div>
                        </div>
                        <div className="mt-1 light-font-text pe-4">
                          {/* {item?.message} */}
                          Ut aut reiciendis voluptatibus maiores alias
                          consequatur aut perferendis doloribus asperiores
                          repellat. Ut aut reiciendis voluptatibus maiores alias
                          reiciendis voluptatibus maiores alias consequatur aut.
                        </div>
                        <div className="mt-2 d-flex justify-content-between">
                          <div className="light-font-text fs-12">
                            by Max Wildersans Brentford
                            {/* {item?.created_by} */}
                          </div>
                          <div className="cursor-pointer  pe-4">
                            <FontAwesomeIcon
                              icon="fa-regular fa-arrow-up-right-from-square"
                              style={{ color: "#000000" }}
                            />
                          </div>
                        </div>
                      </div>
                    }
                  />
                </div>
                <div className="pb-2">
                  <Accordion
                    accordianIconAlignment="left"
                    accordianBorder={false}
                    bgColor={"#FFF599"}
                    accordianHeight={"40px"}
                    titleDate={
                      <div className="fs-10">
                        {/* {moment(item?.created_on)?.format(
                        "DD MMM, YYYY [at] hh:mm"
                      )} */}
                        20 May, 2023
                      </div>
                    }
                    title={
                      <div className="d-flex justify-content-between">
                        <div className="form-label-title ">
                          Blomstervangen 13, 2.tv, 2765 Smørum | 212-005-002
                        </div>
                      </div>
                    }
                    contentBody={
                      <div className="ps-5 pb-2">
                        <div className="d-flex gap-1 align-items-center">
                          <div
                            style={{
                              // backgroundColor: item?.bg_color || "#9370DB",
                              backgroundColor: "#9370DB",
                              color: "white",
                              borderRadius: "4px",
                              fontSize: "9px",
                              alignItems: "center",
                              textTransform: "uppercase",
                              height: "16px",
                            }}
                            className="px-2 font-semibold  d-flex"
                          >
                            {/* {item?.category_name} */}
                            EVENT
                          </div>
                          <div className=" font-semibold fs-14">
                            {/* {item?.subject}
                             */}
                            Subject line
                          </div>
                        </div>
                        <div className="mt-1 light-font-text pe-4">
                          {/* {item?.message} */}
                          Ut aut reiciendis voluptatibus maiores alias
                          consequatur aut perferendis doloribus asperiores
                          repellat. Ut aut reiciendis voluptatibus maiores alias
                          reiciendis voluptatibus maiores alias consequatur aut.
                        </div>
                        <div className="mt-2 d-flex justify-content-between">
                          <div className="light-font-text fs-12">
                            by Max Wildersans Brentford
                            {/* {item?.created_by} */}
                          </div>
                          <div className="cursor-pointer  pe-4">
                            <FontAwesomeIcon
                              icon="fa-regular fa-arrow-up-right-from-square"
                              style={{ color: "#000000" }}
                            />
                          </div>
                        </div>
                      </div>
                    }
                  />
                </div>
                {/* ))
                ) : (
                  <div
                    className="d-flex justify-content-center w-100 fs-12 mt-2"
                    style={{ color: "#888888" }}
                  >
                    No notes found
                  </div>
                )} */}
              </div>
            </div>
            <div className="text-area p-3">
              <InputGroup className="deposit-textfield">
                <Form.Control
                  placeholder="Subject line"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  // value={subjectLine}
                  // onChange={(e) => setSubjectLine(e.target.value)}
                />
              </InputGroup>

              <InputGroup className="deposit-textarea">
                <Form.Control
                  as="textarea"
                  placeholder="Messages"
                  aria-label="Large"
                  aria-describedby="inputGroup-sizing-sm"
                  // value={message}
                  // onChange={(e) => setMessage(e.target.value)}
                />
                <InputGroup.Text
                  className="deposit-textarea-icon"
                  id="inputGroup-sizing-lg"
                >
                  <button
                    type="button"
                    // disabled={
                    //   subjectLine?.length == 0 || (message?.length == 0 && true)
                    // }
                    // onClick={() => handleSendDepositNote()}
                    className="message-send-button"
                    // style={{
                    //   opacity:
                    //     subjectLine?.length == 0 || message?.length == 0
                    //       ? "50%"
                    //       : "inherit",
                    // }}
                  >
                    {
                      <FontAwesomeIcon
                        size="xs"
                        icon="fa-regular fa-paper-plane-top"
                        style={{ color: "#FFFFFF" }}
                      />
                    }
                  </button>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FollowUpInfo;

const CustomToolbar = ({ label, date, onNavigate }) => {
  const handleNext = () => {
    // Custom logic for the next button
    // For example, you can navigate to the next month
    onNavigate("next", moment(date).add(1, "month"));
  };

  const handlePrev = () => {
    // Custom logic for the back button
    // For example, you can navigate to the previous month
    onNavigate("prev", moment(date).subtract(1, "month"));
  };

  return (
    <div className="rbc-toolbar ">
      <button onClick={handlePrev}>
        <FontAwesomeIcon
          icon="fa-regular fa-angle-left"
          style={{ color: "#000000" }}
        />
      </button>
      <span className="rbc-toolbar-label ">{label}</span>
      <button onClick={handleNext}>
        <FontAwesomeIcon
          icon="fa-regular fa-angle-right"
          style={{ color: "#000000" }}
        />
      </button>
    </div>
  );
};
