import { create } from "zustand";

export const useAddEditPackage = create((set, get) => ({
	title: "",
	setTitle: (data) => set({ title: data }),
	active: true,
	setActive: (data) => set({ active: data }),
	tagRowData: [],
	setTagRowData: (data) => set({ tagRowData: data }),
	selected: [],
	setSelected: (data) => set({ selected: data }),
	selectedData: [],
	setSelectedData: (data) => set({ selectedData: data }),
	cancelPopup: false,
	setCancelPopup: (data) => set({ cancelPopup: data }),
}));
