import { useMutation, useQuery } from "react-query";
import {
  GetContractStatus,
  GetGuideByGuideId,
  GetGuidesByDivisionId,
  PutGuideStatus,
  addNewGuide,
  updateGuide,
} from "../../services/Guides";

//------------- Get Guides data used on guid main page --------------//
export const useGetGuidesByDivisionId = (divisionId, statusId) => {
  // status id
  // 0 = All
  // 1 = Draft
  // 2 = Published
  // 3 = Not Published
  // 4 = Archived
  return useQuery(
    ["qGetGuidesByDivisionId", divisionId, statusId],
    () => GetGuidesByDivisionId(divisionId, statusId),
    {
      enabled: !!divisionId,
    },
    {
      enabled: !!statusId,
    }
  );
};

// ----------------Put guide status-----------------------//
export const usePutGuideStatus = () => {
  return useMutation(PutGuideStatus);
};

// -------------------- add new guide ------------------------//
export const useAddNewGuide = () => {
  return useMutation(addNewGuide);
};

// -------------------- Get contract status -----------------------//
export const useGetContractStatus = () => {
  return useQuery(["qGetContractStatus"], () => GetContractStatus());
};

//-------------------------- Get guide by guide Id ----------------------------------------//
export const useGetGuideByGuideId = (guideId) => {
  return useQuery(
    ["qGetGuideByGuideId", guideId],
    () => GetGuideByGuideId(guideId),
    {
      enabled: !!guideId,
    }
  );
};

// -------------------- add new guide ------------------------//
export const useUpdateGuide = () => {
  return useMutation(updateGuide);
};
