import { Col, Row } from "react-bootstrap";
import DocumentCard from "../../../../../../Components/card/DocumentCard";

const EditDocumentPackages = ({ documentData }) => {
    return (
        <Row className="py-2">
            {
                documentData?.map((item, index) =>
                    <Col xl={4} lg={6} key={index} className="pb-4">
                        <DocumentCard data={item} isUnitEdit={true} />
                    </Col>
                )
            }
        </Row>
    )
}

export default EditDocumentPackages;