import React from "react";
import CustomPopup from "../../../../../../Components/CustomPopup";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DropZoneBorder,
  GreenSpan,
  H5,
} from "../../../../../../styles/Common.style";
import CustomToolTip from "../../../../../../Components/Tooltip/CustomTooltip";
import ReactSelect from "../../../../../../Components/DropDown/ReactSelect";
import ETButton from "../../../../../../Components/Buttons/ETButton";
import Toggle from "../../../../../../Components/Switch/Switch";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { useFieldArray, useForm } from "react-hook-form";

const UploadDocument = ({ handleAdd = () => {}, handleBack = () => {} }) => {
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    control,

    clearErrors,
    formState: { errors },
  } = useForm({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: "droppedPdf", // This should match the name in your form data
  });

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles, fileRejections) => {
      acceptedFiles.forEach((file) => {
        append({ name: file.name, document_type: "", file: file });
      });
      fileRejections.map(({ file, errors }) => {
        toast.error("Invalid file type " + file.name);
      });
    },
  });
  const handleAddDocument = () => {
    handleAdd();
  };
  const handleBackScreen = () => {
    handleBack();
  };

  return (
    <div>
      <div className="p-3">
        <Row className="px-2">
          <Col lg={10} className="light-font-text my-1">
            <H5>Upload document about the energy label</H5>

            <div className="pt-2">
              Drag and drop documents directly from your computer. It is
              possible to change the name of the document and select a document
              type. Once you have selected and customized the documents, click
              add and they will be added to the energy label.
            </div>
          </Col>
        </Row>
        <Row className="mt-3 px-2 upload-manually-document-table">
          <Col md={{ span: 12 }}>
            <div className="pt-2 pb-4">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <DropZoneBorder className="py-4 d-flex flex-column justify-content-center align-items-center">
                  <div>
                    <FontAwesomeIcon
                      icon="fa-solid fa-file-pdf"
                      style={{ color: "#000000" }}
                    />
                  </div>
                  <div className="mt-2 mb-1 form-label-title">
                    Drop your file here, or&nbsp;
                    <GreenSpan
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={open}
                    >
                      browse your computer
                    </GreenSpan>
                  </div>
                  <div className="created-by">Supports only PDF</div>
                </DropZoneBorder>
              </div>
            </div>
            <div>
              <div className="mb-4">
                <Row className="m-0 p-0">
                  <Row className="m-0 p-0 mb-2">
                    <Col
                      className="d-flex align-items-center py-2  actors-section-title fs-13 ps-3"
                      xs={5}
                    >
                      Name
                    </Col>
                    <Col
                      className="d-flex align-items-center py-2 actors-section-title fs-13 ps-0 "
                      xs={4}
                    >
                      Document type
                    </Col>

                    <Col
                      className="d-flex align-items-center gap-2 justify-content-center py-2 actors-section-title fs-13"
                      xs={2}
                    >
                      Inherit to Units
                      <CustomToolTip
                        text={
                          "Select if this document should be inherited to all levels below."
                        }
                      />
                    </Col>
                  </Row>

                  <div
                    className="p-0"
                    style={{
                      maxHeight: "160px",
                      overflow: "auto",
                    }}
                  >
                    <div>
                      <Row
                        className="m-0 p-0 mb-2"
                        style={{
                          borderRadius: "8px",
                          backgroundColor: "#F9FAFA",
                        }}
                      >
                        <Col
                          className="d-flex align-items-start py-2 pe-4"
                          xs={5}
                        >
                          <div
                            className="bg-white radius-6 ms-md-1 w-100 ellipsis "
                            style={{
                              padding: "0.3rem 0.5rem",
                              border: "1px solid #D1D1D1",
                              marginTop: "2px",
                              height: "38px",
                            }}
                          >
                            <p className="mb-0 fs-300 fs-14">
                              <input className="standard-input" />
                            </p>
                          </div>
                        </Col>
                        <Col
                          className="d-flex align-items-center  py-2 pe-1 ps-0"
                          xs={4}
                        >
                          <div className="w-100 fs-300 fs-14">
                            <ReactSelect placeholder="" />
                          </div>
                        </Col>
                        <Col
                          xs={2}
                          className="ps-0 d-flex justify-content-center align-items-center"
                        >
                          <Toggle />
                        </Col>
                        <Col
                          className="d-flex align-items-start py-2 justify-content-end"
                          xs={1}
                        >
                          <div className="d-flex align-items-center">
                            <button className="added-document-delete-btn">
                              <FontAwesomeIcon
                                icon="fa-regular fa-trash"
                                className="fs-6"
                              />
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
        <ETButton
          variant={"white"}
          buttonText="Back"
          onClick={handleBackScreen}
        />
        <ETButton
          variant={"primary"}
          buttonText="ADD"
          onClick={handleAddDocument}
        />
      </div>
    </div>
  );
};

export default UploadDocument;
