import React, { useState, useCallback, useEffect, useMemo } from "react";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import ETButton from "../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Col, Form, Image, Row } from "react-bootstrap";
import Menus from "../../Components/Menus/Menus";
import { useDropzone } from "react-dropzone";
import photoComingSoon from "../../assets/images/photo-coming-soon.jpg";
import CustomPopup from "../../Components/CustomPopup";
import { toast } from "react-toastify";
import {
  useGetMediaCategory,
  useUpdateMediaDetails,
  useUploadMedia,
} from "../../hooks/queries/useMediaQueries";
import {
  DeleteMediaFileByMediaFileId,
  GetMediaDetailsByMediaId,
} from "../../services/Media";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import { useRouting } from "../../hooks/stores/useRoutingStore";
import { status } from "../../model/Constant";
import moment from "moment";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import {
  DropZoneBorder,
  GreenDiv,
  GreenSpan,
  H2,
} from "../../styles/Common.style";
import { useForm, Controller } from "react-hook-form";

const Upload_MediaPackages = ({
  handleCreateMediaPackage,
  PutUpdateMediaPackageCompleteDetailsIsLoading,
  propertyId,
  mediaFiles,
  setMediaFiles,
  getMediaPackageByPackageId,
  setAddMediaPage,
  getMediaFilesByPackageIdRefetch,
  setFlagUpdateMedia,
  modifiedData,
  setCancelPopup,
  getMediaPackageByPackageIdRefetch,
  publishFlag,
  setPublishFlag,
}) => {
  const { editflag, packageId } = useRouting((state) => state);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [deletePopUp, setDeletePopUp] = useState({
    mediaFileId: "",
    mediaFileIndex: "",
    isDeletePopUpOpen: false,
  });

  //-----------React query-----------//
  const { data: mediaCategoryData } = useGetMediaCategory();
  const { mutateAsync: PostUploadMedia, isLoading: PostUploadMediaIsLoading } =
    useUploadMedia();

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    uploadMedia(acceptedFiles);

    fileRejections.map(({ file, errors }) => {
      toast.error("Invalid file type " + file.name);
    });
  }, []);
  let filteredMediaCategory = mediaCategoryData?.data?.filter(
    (ele) => ele.Id != 1
  );
  const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } =
    useDropzone({
      // Disable click and keydown behavior
      onDrop,
      noClick: true,
      noKeyboard: true,
      accept: {
        "image/jpeg": [],
        "image/png": [],
      },
    });

  const uploadMedia = (acceptedFiles) => {
    for (let i = 0; i < acceptedFiles?.length; i++) {
      let id;
      if (!PostUploadMediaIsLoading) {
        id = toast.loading("Uploading file");
      }
      let formData = new FormData();
      formData.append("files[]", acceptedFiles[i]);
      formData.append("property_id", propertyId?.toString());
      formData.append("package_id", packageId?.toString());
      PostUploadMedia(formData)
        .then((response) => {
          if (editflag) {
            setMediaFiles((prev) => [...prev, ...response?.data?.data]);
            toast.update(id, {
              render: response?.data?.message,
              isLoading: false,
              type: status.s,
              autoClose: 2000,
            });
          } else {
            setMediaFiles((prev) => [...prev, ...response?.data?.data]);
            toast.update(id, {
              render: response?.data?.message,
              isLoading: false,
              type: status.s,
              autoClose: 2000,
            });
          }
        })
        .catch((error) => {
          toast.update(id, {
            render: "Failed to upload",
            isLoading: false,
            type: status.e,
            autoClose: 2000,
          });
        });
    }
  };

  const handleDrop = (droppedItem) => {
    //     // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const updatedList = [...mediaFiles];
    //     // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    //     // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    //     // Update State
    setMediaFiles(updatedList);
  };

  const handleDeleteCard = async () => {
    try {
      const response = await DeleteMediaFileByMediaFileId(
        deletePopUp?.mediaFileId
      );
      if (response?.data?.bReturn) {
        toast.success(response.data.message);
        let newFile = [...mediaFiles];
        const objWithIdIndex = newFile.findIndex(
          (obj) => obj.media_file_id == deletePopUp?.mediaFileId
        );
        if (objWithIdIndex > -1) {
          newFile.splice(objWithIdIndex, 1);
          setMediaFiles(newFile);
          setDeletePopUp((prev) => ({
            ...prev,
            isDeletePopUpOpen: false,
          }));

          getMediaPackageByPackageIdRefetch();
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const filteredCategoryDropdown = useMemo(() => {
    if (categoryFilter !== "") {
      return mediaFiles?.filter(
        (fData, i) => fData?.media_file_category_id == categoryFilter
      );
    } else {
      return mediaFiles;
    }
  }, [mediaFiles, categoryFilter]);

  const selectMediaLength = mediaFiles?.filter((item) => {
    if (categoryFilter == null || categoryFilter == "") {
      return item;
    } else if (item?.media_file_category_id == categoryFilter) {
      return item;
    }
  }).length;

  return (
    <div className="medias-container my-2 bg-white radius-8">
      <div className="media-sub-container ">
        <div className="gap-2 " style={{ borderBottom: "1px solid #F0F0F0" }}>
          <div className="mx-4 pt-3">
            <div
              style={{
                paddingTop: "2px",
              }}
              className="d-flex align-items-center"
            >
              {" "}
              <H2 className="me-2">
                {" "}
                2. {editflag ? "Edit" : "Add"} media{" "}
              </H2>{" "}
              <CustomToolTip size="sm" text="Media" />
            </div>
          </div>
          <div className="created-by pb-3">
            {modifiedData?.map((item, i) => (
              <>
                {item.modified_by && item.modified_on ? (
                  <div key={i} className=" fs-12  mx-4">
                    Last updated by {item.modified_by},{item.modifiedOn}
                  </div>
                ) : (
                  <>
                    {item.created_by && item.created_on ? (
                      <div className="fs-12 ">
                        Created by {item.created_by},{item.createdOn}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </>
            ))}
          </div>
        </div>

        <div className="mx-4 py-2">
          <div className="pt-2">
            <div className="form-label-title"> Upload media</div>
            <div className="light-font-text grey-text">
              Lorem ipsum dolor sit pluralis oct
            </div>
          </div>
        </div>

        <div className="sub-media-container">
          <Row>
            <Col md={{ span: 12 }}>
              <div className=" pt-3 pb-4">
                <div {...getRootProps({ className: "dropzone px-4" })}>
                  <input {...getInputProps()} />
                  <DropZoneBorder className=" py-5 d-flex flex-column justify-content-center align-items-center ">
                    <div>
                      {/* <File /> */}
                      <FontAwesomeIcon
                        icon="fa-solid fa-file-image"
                        style={{ color: "#000000" }}
                        size="xl"
                      />
                    </div>
                    <div className="mt-2 mb-1  form-label-title">
                      Drop your file here, or&nbsp;
                      <GreenSpan
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={open}
                      >
                        browse your computer
                      </GreenSpan>
                    </div>
                    <div className="fs-12 light-font-text grey-text">
                      Supports only JPG and PNG
                    </div>
                  </DropZoneBorder>
                </div>
                <aside></aside>
              </div>
            </Col>
          </Row>

          <hr className="hr  mt-1 mb-0" />
          <div className="px-4 pt-4">
            <div className="form-label-title">
              Uploaded media in the package
            </div>
            <div className="light-font-text grey-text">
              Lorem ipsum dolor sit pluralis oct
            </div>
          </div>
          <Row className="mt-4 px-4">
            <Col lg={3} md={6}>
              <div>
                {/* <BasicSelect
                  Data={mediaCategoryData?.data}
                  label={"Category"}
                  size="small"
                /> */}
                <ReactSelect
                  placeholder={"Category"}
                  clearable={true}
                  onChange={(e, ActionTypes) => {
                    if (ActionTypes?.action === "clear") {
                      setCategoryFilter("");
                    } else {
                      setCategoryFilter(e?.value);
                    }
                  }}
                  options={filteredMediaCategory?.map((item) => ({
                    value: item?.Id,
                    label: `${item?.Text} `,
                  }))}
                />
              </div>
            </Col>
            <Col md={6}>{/* <DateRangePicker /> */}</Col>
          </Row>
          <div className="px-4">
            {selectMediaLength == 0 ? (
              <div
                className="fs-12 py-4"
                style={{ textAlign: "center", color: "#888888" }}
              >
                No medias are added to the package . That’s a must before you
                can continue.{" "}
              </div>
            ) : (
              <>
                <DragDropContext onDragEnd={handleDrop}>
                  <Droppable
                    droppableId="image-container"
                    direction="horizontal"
                  >
                    {(provided) => (
                      <div
                        className="image-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {filteredCategoryDropdown?.map((item, index) => (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                id={index}
                                className="item-container"
                                ref={provided.innerRef}
                                // {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <ImageCard
                                  provided={provided}
                                  item={item}
                                  index={index}
                                  setMediaFiles={setMediaFiles}
                                  mediaFiles={mediaFiles}
                                  filteredMediaCategory={filteredMediaCategory}
                                  getMediaPackageByPackageId={
                                    getMediaPackageByPackageId
                                  }
                                  getMediaFilesByPackageIdRefetch={
                                    getMediaFilesByPackageIdRefetch
                                  }
                                  packageId={packageId}
                                  getMediaPackageByPackageIdRefetch={
                                    getMediaPackageByPackageIdRefetch
                                  }
                                  publishFlag={publishFlag}
                                  setPublishFlag={setPublishFlag}
                                  setDeletePopUp={setDeletePopUp}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            )}
          </div>
        </div>

        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-between footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setCancelPopup(true)}
          />
          <div className="d-flex gap-2">
            <ETButton
              variant={"white"}
              buttonText="PREVIOUS"
              onClick={() => {
                setAddMediaPage(false);
                setFlagUpdateMedia(true);
              }}
            />
            <ETButton
              disabled={
                PutUpdateMediaPackageCompleteDetailsIsLoading ||
                !mediaFiles?.every((el) => el.media_file_category_id !== "") ||
                mediaFiles?.length <= 0
                  ? true
                  : false
              }
              variant={"primary"}
              buttonText={editflag ? "SAVE" : "CREATE MEDIA PACKAGE"}
              width="fit-content"
              onClick={() => {
                handleCreateMediaPackage();
              }}
              // disabled={PutUpdateMediaPackageCompleteDetailsIsLoading}
            />
          </div>
        </div>
      </div>
      <CustomPopup
        // modalHeadingStyle={modalHeadingStyle}
        style={{ position: "relative" }}
        size="md    "
        open={deletePopUp?.isDeletePopUpOpen}
        onHide={() =>
          setDeletePopUp((prev) => ({
            ...prev,
            isDeletePopUpOpen: false,
          }))
        }
        title="Are you carrying out the trash?"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() =>
          setDeletePopUp((prev) => ({
            ...prev,
            isDeletePopUpOpen: false,
          }))
        }
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div className="fs-14 fw-300 text-left mb-3">
            <p>Are you sure you want to delete the media?</p>
            {/* <p>That will conflict with.....</p> */}
          </div>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() =>
              setDeletePopUp((prev) => ({
                ...prev,
                isDeletePopUpOpen: false,
              }))
            }
          />

          <ETButton
            variant={"primary"}
            buttonText="I'M SURE"
            onClick={() => handleDeleteCard()}
          />
        </div>
      </CustomPopup>
    </div>
  );
};
export default Upload_MediaPackages;

const ImageCard = ({
  provided,
  item,
  setMediaFiles,
  mediaFiles,
  filteredMediaCategory,
  getMediaPackageByPackageId,
  getMediaFilesByPackageIdRefetch,
  index,
  packageId,
  getMediaPackageByPackageIdRefetch,
  publishFlag,
  setPublishFlag,
  setDeletePopUp,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm();

  const [mediaCategory, setMediaCategory] = useState({
    value: item?.media_file_category_id,
    label: filteredMediaCategory?.filter(
      (el) => el.Id == item?.media_file_category_id
    )?.[0]?.Text,
  });
  const [editImageDetails, setEditImageDetails] = useState(false);
  const [imageTitle, setImageTitle] = useState("");
  const [altText, setAltText] = useState("");
  const [imageText, setImageText] = useState("");
  const [imageDescription, setImageDescription] = useState("");
  const [imageCategory, setImageCategory] = useState(
    parseInt(item?.media_file_category_id)
  );
  const [seoScoreText, setSeoScoreText] = useState("");
  const [mediaQualityText, setMediaQualityText] = useState("");
  const [editMedia, setEditMedia] = useState("");

  //-------React query----------//
  const { data: mediaFileDetails, refetch: mediaFileDetailsRefetch } = useQuery(
    ["mediaFileDetails", item?.media_file_id],
    () => GetMediaDetailsByMediaId(item?.media_file_id),
    { enabled: !!editImageDetails }
  );

  const {
    mutateAsync: PutUpdateMediaDetails,
    isLoading: PutUpdateMediaDetailsIsLoading,
  } = useUpdateMediaDetails();

  useEffect(() => {
    if (mediaFileDetails?.data === null) {
      setImageTitle("");
      setAltText("");
      setImageText("");
      setImageDescription("");
      reset();
    } else {
      setImageTitle(mediaFileDetails?.data[0]?.title);
      setAltText(mediaFileDetails?.data[0]?.alt_text);
      setImageText(mediaFileDetails?.data[0]?.image_text);
      setImageDescription(mediaFileDetails?.data[0]?.description);
      setSeoScoreText(mediaFileDetails?.data[0]?.seo_score_text);
      setMediaQualityText(mediaFileDetails?.data[0]?.media_quality_text);
      setValue("title", mediaFileDetails?.data[0]?.title);
      setValue("altText", mediaFileDetails?.data[0]?.alt_text);
      setValue("imageText", mediaFileDetails?.data[0]?.image_text);
      setValue("description", mediaFileDetails?.data[0]?.description);
    }
  }, [mediaFileDetails]);

  useEffect(() => {
    setMediaCategory({
      value: item?.media_file_category_id,
      label: filteredMediaCategory?.filter(
        (el) => el.Id == item?.media_file_category_id
      )?.[0]?.Text,
    });
  }, [item]);

  //-----------------handle category change---------------//
  const handleCardCategoryChange = (e, i) => {
    setImageCategory(e.value);
    setMediaCategory({
      value: e.value,
      label: filteredMediaCategory?.filter((el) => el.Id == e.value)?.[0]?.Text,
    });
    let newArray = [...mediaFiles];
    newArray[index]["media_file_category_id"] = e.value;
    newArray[index]["category_name"] = filteredMediaCategory?.filter(
      (el) => el.Id == e.value
    )?.[0]?.Text;
    setMediaFiles(newArray);
  };
  // ---------------handle publish ---------------------------------//
  const handlePublish = (index, publishFlag) => {
    // getMediaPackageByPackageId?.data?.[0]?.media_details?.forEach((element) => {
    mediaFiles?.forEach((element) => {
      if (
        element?.media_file_category_id == 3 &&
        element?.is_published == 1 &&
        mediaCategory?.value == 3
      ) {
        toast.warn("Only one floor plan can be published at a time.");
        return;
      } else {
        let newArray = [...mediaFiles];
        newArray[index]["is_published"] = publishFlag == 1 ? "0" : "1";
        setMediaFiles(newArray);
      }
    });
  };
  //---------------handle save image details----------------------//
  const handleSaveImageDetails = (data) => {
    const imageDetailsPayloadData = {
      media_package_id: packageId?.toString(),
      media_file_id: item?.media_file_id?.toString(),
      title: data?.title,
      media_category_id: imageCategory,
      alt_text: data?.altText,
      image_text: data?.imageText,
      description: data?.description,
    };

    PutUpdateMediaDetails(imageDetailsPayloadData)
      .then((response) => {
        if (response.data.bReturn) {
          toast.success(response.data.message);
          setEditImageDetails(false);
          mediaFileDetailsRefetch();
          getMediaFilesByPackageIdRefetch();
          getMediaPackageByPackageIdRefetch();
          reset();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  return (
    <>
      <div className="image-card">
        <div {...provided.dragHandleProps} className="dragIcon">
          {/* <FontAwesomeIcon size="xs" icon={faArrowsUpDownLeftRight} /> */}
          <FontAwesomeIcon
            icon="fa-regular fa-arrows-up-down-left-right"
            size="sm"
            style={{ color: "#000000" }}
          />
        </div>
        <div style={{ height: "120px" }}>
          <Image
            alt="property image"
            src={item?.media_files !== "" ? item?.media_files : photoComingSoon}
            height="100%"
            style={{ objectFit: "none" }}
            width="100%"
          />
        </div>
        <div className="card-bottom">
          <div className="my-1">
            <ReactSelect
              placeholder={""}
              value={mediaCategory}
              options={filteredMediaCategory?.map((option) => ({
                value: option?.Id,
                label: option?.Text,
              }))}
              onChange={(e) => {
                handleCardCategoryChange(e, index);
              }}
              searchable={false}
              clearable={false}
              height={26}
            />
          </div>

          <div className="py-3 fw-300">
            <div className="d-flex align-items-center">
              <span className="light-font-text me-2">SEO-score</span>
              <div
                style={{
                  background: item?.seo_score_text
                    ? item?.seo_score_text === "Bad"
                      ? "#F1664B"
                      : item?.seo_score_text == "Average"
                      ? "#F6E651"
                      : "#77C7A1"
                    : "#F1664B",
                  display: "flex",
                  borderRadius: "3px",
                }}
              >
                <span
                  className="normal-font-text"
                  style={{
                    fontSize: "9px",
                    textTransform: "uppercase",
                    padding: "1px 5px",
                  }}
                >
                  {item?.seo_score_text || "Bad"}
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <span className="light-font-text me-2">Image quality</span>
              <div
                style={{
                  background: item?.media_quality_text
                    ? item?.media_quality_text == "Bad"
                      ? "#F1664B"
                      : item?.media_quality_text == "Average"
                      ? "#F6E651"
                      : "#77C7A1"
                    : "#F1664B",
                  display: "flex",
                  borderRadius: "3px",
                }}
              >
                <span
                  className="normal-font-text"
                  style={{
                    fontSize: "9px",
                    textTransform: "uppercase",
                    padding: "1px 5px",
                  }}
                >
                  {item?.media_quality_text || "Bad"}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex  justify-content-between">
            <div className="fs-12  pt-1 ">
              <div className="fs-12 light-font-text ">Created by</div>
              <div className="created-by">
                {item?.created_by
                  ? item?.created_by +
                    ", " +
                    moment(item?.created_on).format("DD. MMM. YYYY | H:mm:ss")
                  : "-"}
              </div>
              <div className="fs-12 pt-1 light-font-text">Last updated by</div>
              <div className="created-by">
                {item?.modified_by
                  ? item?.modified_by +
                    ", " +
                    moment(item?.modified_on).format("DD. MMM. YYYY | HH:mm:ss")
                  : "-"}
              </div>
            </div>
            <div className="d-flex align-items-end">
              <Menus
                itemheight={20}
                itemwidth={20}
                iconborderradius="4px"
                component={
                  <div className="light-font-text">
                    <div
                      className="addpropContent px-3 py-2 "
                      onClick={() => {
                        item?.media_file_category_id === ""
                          ? toast.info("Please select media category")
                          : setEditImageDetails(true);
                      }}
                    >
                      <span className="ms-1">Edit image details</span>
                    </div>
                    <hr className="addpropertyHr m-0"></hr>

                    <div
                      onClick={() => {
                        // setPublishFlag(!publishFlag);
                        handlePublish(index, item.is_published);
                      }}
                      className="addpropContent px-3 pt-2 pb-2 "
                    >
                      <span className="ms-1">
                        {item?.is_published == 1
                          ? "Remove from published"
                          : "Publish"}
                      </span>
                    </div>
                    <hr className="addpropertyHr m-0"></hr>

                    <div
                      onClick={() => {
                        setDeletePopUp((prev) => ({
                          ...prev,
                          isDeletePopUpOpen: true,
                          mediaFileId: item?.media_file_id,
                          mediaFileIndex: index,
                        }));
                        setEditMedia(item?.media_file_id, index);
                      }}
                      className="addpropContent px-3 pt-2 pb-2 "
                    >
                      <span className="ms-1">Delete</span>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>

        <CustomPopup
          editImageDetails={editImageDetails}
          // modalHeadingStyle={modalHeadingStyle}
          style={{ position: "relative" }}
          title={<>Edit image details</>}
          subTitle={
            <div>
              {mediaFileDetails?.data[0]?.modified_by &&
              mediaFileDetails?.data[0]?.modified_on ? (
                <>
                  <div className="fs-12 created-by">
                    Last updated by {mediaFileDetails?.data?.[0]?.modified_by}
                    ,&nbsp;
                    {dayjs(mediaFileDetails?.data?.[0]?.modified_on).format(
                      "DD. MMM. YYYY | HH:mm:ss"
                    )}
                  </div>
                </>
              ) : (
                <>
                  {mediaFileDetails?.data?.[0]?.created_by &&
                  mediaFileDetails?.data?.[0]?.created_on ? (
                    <div className="fs-12 created-by">
                      Created by {mediaFileDetails?.data?.[0]?.created_by}
                      ,&nbsp;
                      {dayjs(mediaFileDetails?.data?.[0]?.created_on).format(
                        "DD. MMM. YYYY | HH:mm:ss"
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          }
          title_color="black"
          titleTooltipText="Edit image details"
          titleTooltip={true}
          size="lg"
          open={editImageDetails}
          onHide={() => {
            setEditImageDetails(false);
            reset();
          }}
          enableCrossButton={false}
          onCloseAction={() => {
            setEditImageDetails(false);
          }}
        >
          <Form onSubmit={handleSubmit(handleSaveImageDetails)}>
            <div>
              <div className="d-flex  py-4 ps-4 pe-2 mb-3">
                <Row style={{ width: "100%" }}>
                  <Col md={7} className="radius-8">
                    <div style={{ height: "360px" }}>
                      <Image
                        fluid
                        className="radius-8"
                        alt="propertyDetailImage"
                        src={
                          item?.media_files !== ""
                            ? item?.media_files
                            : photoComingSoon
                        }
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </Col>
                  <Col md={5} className="pt-lg-0 pt-2 pe-0">
                    <div>
                      <div className="pb-3">
                        <Controller
                          control={control}
                          {...register("title")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <div className="form-input ">
                              <input
                                type="text"
                                placeholder="Title"
                                id="propertyTitle"
                                // value={imageTitle}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  clearErrors("title");
                                  // setImageTitle(e.target.value);
                                }}
                              />
                              <label htmlFor="propertyTitle">Title</label>
                            </div>
                          )}
                        />
                        {errors.title && (
                          <p className="error-msg ">This field is required</p>
                        )}
                      </div>
                      <div className="pb-2">
                        <Controller
                          control={control}
                          {...register("altText")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <div className="form-float openhouse-des">
                              <textarea
                                name=""
                                class="inputText"
                                cols="20"
                                rows="2"
                                placeholder=" "
                                // value={altText}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  clearErrors("altText");
                                  // setAltText(e.target.value);
                                }}
                              ></textarea>
                              <label className="floating-label">Alt text</label>
                            </div>
                          )}
                        />
                        {errors.altText && (
                          <p className="error-msg ">This field is required</p>
                        )}
                      </div>
                      <div
                        className="fw-400 fs-10"
                        style={{
                          fontFamily: "Cerebri sans-light",
                          fontSize: "10px",
                          lineHeight: "14px",
                          color: "#000000",
                        }}
                      >
                        <GreenDiv
                          style={{
                            textDecorationLine: "underline",
                          }}
                        >
                          Learn to describe the purpose of the image.
                        </GreenDiv>{" "}
                        Leave blank if the image is purely decorative.
                      </div>
                      <div className="pt-3">
                        <Controller
                          control={control}
                          {...register("imageText")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <div className="form-float openhouse-des">
                              <textarea
                                name=""
                                class="inputText"
                                cols="20"
                                rows="2"
                                placeholder=" "
                                // value={altText}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  clearErrors("imageText");
                                  // setAltText(e.target.value);
                                }}
                              ></textarea>
                              <label className="floating-label">
                                Image text
                              </label>
                            </div>
                          )}
                        />
                        {errors.imageText && (
                          <p className="error-msg ">This field is required</p>
                        )}
                      </div>
                      <div className="pt-3">
                        <Controller
                          control={control}
                          {...register("description")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <div className="form-float openhouse-des">
                              <textarea
                                name=""
                                class="inputText"
                                cols="20"
                                rows="2"
                                placeholder=" "
                                // value={altText}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  clearErrors("description");
                                  // setAltText(e.target.value);
                                }}
                              ></textarea>
                              <label className="floating-label">
                                Description
                              </label>
                            </div>
                          )}
                        />
                        {errors.description && (
                          <p className="error-msg ">This field is required</p>
                        )}
                      </div>

                      <div className=" mt-2">
                        <div className="d-flex align-items-center my-1  ">
                          <span className="light-font-text">SEO-score</span>
                          <div
                            className="ms-2"
                            style={{
                              background:
                                seoScoreText === "Bad"
                                  ? "#F1664B"
                                  : seoScoreText == "Average"
                                  ? "#F6E651"
                                  : "#77C7A1",
                              display: "flex",
                              borderRadius: "3.7px",
                            }}
                          >
                            <span
                              className="normal-font-text"
                              style={{
                                fontSize: "9px",
                                padding: "1px 5px",
                                textTransform: "uppercase",
                              }}
                            >
                              {seoScoreText}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center my-1">
                          <span className="light-font-text">Image quality</span>
                          <div
                            className="ms-2"
                            style={{
                              background:
                                mediaQualityText == "Bad"
                                  ? "#F1664B"
                                  : mediaQualityText == "Average"
                                  ? "#F6E651"
                                  : "#77C7A1",
                              display: "flex",
                              borderRadius: "3.7px",
                            }}
                          >
                            <span
                              className="normal-font-text"
                              style={{
                                fontSize: "10px",
                                textTransform: "uppercase",
                                padding: "1px 5px",
                              }}
                            >
                              {mediaQualityText}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div
              className=" py-2 px-4 d-flex  justify-content-between  justify-content-center footer-section-button-container"
              style={{
                position: "absolute",
                width: "100%",
                bottom: "-52px",
              }}
            >
              <ETButton
                type={"button"}
                variant={"white"}
                buttonText="CANCEL"
                onClick={() => {
                  reset();
                  setEditImageDetails(false);
                }}
              />

              <ETButton
                disabled={PutUpdateMediaDetailsIsLoading}
                type="submit"
                variant={"primary"}
                buttonText="SAVE"
              />
            </div>
          </Form>
        </CustomPopup>
      </div>
    </>
  );
};
