import moment from "moment";
import { useGetUserById } from "../hooks/queries/useUserQueries";
import { useUserDetails } from "../hooks/stores/useUserDetailsStore";
import { UTCtoLocalTimeZone } from "./UTCtoLocalTimeZone";

export const AetAdjustedTime = (timestamp, offsetData) => {
  // const { data: GetUserByIdData } = useGetUserById();
  // let offsetData = GetUserByIdData?.data?.[0]?.timezone_offset_mins;
  const adjustedTime = moment(timestamp).add(offsetData, "minutes");
  return adjustedTime.format("DD.MMMM.YYYY | HH:mm:ss");
};

export const GenerateUpdateInfo = ({ data, flag }) => {
  //-------------------Zustand---------------//
  const { userDetails } = useUserDetails((state) => state);
  const offsetData = userDetails?.timezone_offset_mins;
  console.log(data);

  const { convertedDate: created_on } = UTCtoLocalTimeZone({
    timestamp: data?.created_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });

  const { convertedDate: modified_on } = UTCtoLocalTimeZone({
    timestamp: data?.modified_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });

  return (
    <>
      {flag ? (
        <div className="created-by">
          {data.modified_by && data.modified_on ? (
            <>
              Last updated by {data.modified_by}, {modified_on}
            </>
          ) : (
            data.created_by &&
            data.created_on && (
              <>
                Created by {data.created_by}, {created_on}
              </>
            )
          )}
        </div>
      ) : (
        <div className="fs-12">
          <div className="form-label-title  fs-12">Created by</div>
          <div className="created-by">
            {data?.created_by && data?.created_on ? (
              <>
                {data?.created_by}, {created_on}
              </>
            ) : (
              "-"
            )}
          </div>
          <div className=" fs-12 pt-1 form-label-title  ">Last updated by</div>
          <div className="created-by">
            {data?.modified_by && data?.modified_on ? (
              <>
                {data?.modified_by}, {modified_on}
              </>
            ) : (
              "-"
            )}
          </div>
        </div>
      )}
    </>
  );
};
