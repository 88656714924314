import { Get } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";
import { GetSessionItem } from "../utils/SessionStorage";

export const RefreshToken = () => {
	return new Promise((resolve, reject) => {
		Get(
			Site.AUTH,
			Endpoint.AUTH.RefreshToken,
			{
				Authorization: `Bearer ${GetSessionItem("et-rt")}`,
			},
			null
		)
			.then((response) => {
				resolve(response.data);
				// console.log("RefreshTokenValidation", response);
			})
			.catch((error) => {
				// console.log("rt error", error);
				if (error.response.data.error == "Invalid refresh token") {
					window.location.href = "/";
					console.log("invalid token log");
				}
				reject({ error: true, message: error.message });
				// console.log("rt api err", error.response.data.error);
			});
	});
};
