import React, { useRef, useState } from "react";
import ETButton from "../../../Components/Buttons/ETButton";
import OtpValidation from "../../../Components/GroupText/OtpValidation";
import { useForm } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import { GreenSpan, H2, H5 } from "../../../styles/Common.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Confetti from "../../../assets/animations/Confetti.lottie";
import Question from "../../../assets/animations/Question.lottie";
import Approved from "../../../assets/animations/Approved.lottie";
import "@dotlottie/player-component";

const VerifyPhoneNumber = ({
  handleNext,
  verificationProcess,
  setVerificationProcess,
  refNo,
  handleUserSignUpStepOneNext,
  errorMessage,
  buttonText,
  setStepWizard,
  isValidateOtpLoading,
}) => {
  // --------------- states managed -----------------
  // D - default || S - Success  ||  E - Error
  // const [verificationProcess, setVerificationProcess] = useState("D");
  const [emailSuccess, setEmailSuccess] = useState(null);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);

  // ----------------- all const managed here -------------
  const hasEmptyString = otpValues.every((value) => value !== "");
  const otpFieldsRef = useRef([]);

  // --------------- react hooks form ---------------
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const handleInput = (index, value) => {
    if (!value) return null;
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);
    setValue(`otp${index + 1}`, value, { shouldValidate: true });
    if (value.length === 1 && index < otpValues.length - 1) {
      otpFieldsRef.current[index + 1].focus();
    }
  };
  const handleBackspace = (index) => {
    if (otpValues[index] !== "") {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = "";
      setValue(`otp${index + 1}`, "", { shouldValidate: true });
      setOtpValues(newOtpValues);
    } else if (index > 0) {
      otpFieldsRef.current[index - 1].focus();
    }
  };
  const handleResend = async () => {
    if (errorMessage?.flag === "Expired") {
      await handleUserSignUpStepOneNext();
      setVerificationProcess("D");
    } else {
      setVerificationProcess("D");
    }
  };

  // ------------ form submit button clicked
  const onFormSubmit = (data) => {
    setEmailSuccess(true);
    handleNext(data);
    setOtpValues(["", "", "", "", "", ""]);
    reset();
  };
  return (
    <div className="p-3">
      <div className="pb-3">
        <H2>Create user</H2>
        <span className="caption-light">Step 2 out of 5</span>
      </div>
      <div className="pb-4 pt-3">
        <div className="font-bold fs-14 ">Verify your phone number</div>
      </div>

      {/* ------------------ type otp section ------------- */}
      {verificationProcess === "D" ? (
        <Form className="h-100" onSubmit={handleSubmit(onFormSubmit)}>
          <div className="light-font-text pb-2">
            We have send you a text message with a six digit verification code
          </div>
          <div className="light-font-text">
            Please fill out the code below to verify your phone number.
          </div>

          <div>
            <div className="font-bold fs-14 pt-4 pb-1">Verification code</div>
            <div className="pb-40">
              <Row>
                <Col className="">
                  <div
                    className="profile-otp-container gap-3 flex-wrap px-2 mt-1
                     "
                  >
                    {otpValues.map((value, index) => (
                      <div className="d-flex flex-column" key={index}>
                        <input
                          type="number"
                          maxLength="1"
                          className="profile-otp-input"
                          style={{ width: "40px", height: "40px" }}
                          value={value}
                          {...register(`otp${index + 1}`, {
                            required: true,
                          })}
                          onKeyDown={(e) => {
                            if (e.key === "Backspace") {
                              handleBackspace(index);
                            } else if (!/^\d$/.test(e.key)) {
                              // If the pressed key is not a digit, prevent its input
                              e.preventDefault();
                            } else {
                              handleInput(index, e.key);
                            }
                          }}
                          ref={(ref) => {
                            otpFieldsRef.current[index] = ref;
                          }}
                        />
                        <div
                          className="otp-border"
                          style={{
                            backgroundColor: value !== "" && "#f1664b",
                          }}
                        ></div>
                      </div>
                    ))}
                  </div>
                  {Boolean(Object.keys(errors).length) && (
                    <p className="error-msg">This OTP is required</p>
                  )}

                  <div className="d-flex align-items-center gap-1 fs-14 mt-2">
                    <span>Reference number:</span>
                    <span>{refNo}</span>
                  </div>

                  <div className="mt-3">
                    <span className="fs-14 me-1">
                      Didn't get a code? Try to click here
                    </span>
                    <GreenSpan
                      className="fs-14 cursor-pointer"
                      onClick={handleUserSignUpStepOneNext}
                      style={{ textDecoration: "underline" }}
                    >
                      Resend code
                    </GreenSpan>
                  </div>
                  <div className="light-font-text ">
                    If that doesn't work, contact your system administrator.
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <ETButton
            buttonText={"VERIFY"}
            variant={"primary"}
            type={"submit"}
            disabled={
              hasEmptyString === false && isValidateOtpLoading ? true : false
            }
          />
        </Form>
      ) : verificationProcess === "S" ? (
        <div>
          <div className="light-font-text pb-3">
            Wuhu, the code was correct.
          </div>{" "}
          <div className="light-font-text ">
            You are now ready for next step.
          </div>
          <div
            style={{ height: "240px" }}
            className="d-flex align-items-center justify-content-center"
          >
            {/* <FontAwesomeIcon
              icon="fa-solid fa-circle-check"
              style={{ color: "#349032" }}
              size="2xl"
            /> */}
            <dotlottie-player src={Approved} autoplay loop />
          </div>
          <ETButton
            buttonText={"next"}
            variant={"primary"}
            onClick={() => setStepWizard("NewUserSignUpStepThree")}
          />
        </div>
      ) : (
        <div>
          <div className="light-font-text pb-3">{errorMessage?.message}</div>{" "}
          <div className="light-font-text ">{errorMessage?.action}</div>
          <div
            style={{ height: "240px" }}
            className="d-flex align-items-center justify-content-center"
          >
            {/* <FontAwesomeIcon
              icon="fa-solid fa-circle-exclamation"
              size="2xl"
              style={{ color: "#f43e2a" }}
            /> */}
            <dotlottie-player src={Question} autoplay loop />
          </div>
          <ETButton
            buttonText={buttonText}
            variant={"primary"}
            type={"button"}
            onClick={handleResend}
          />
        </div>
      )}
    </div>
  );
};

export default VerifyPhoneNumber;
