import { useQuery } from "react-query";
import { GetBillingLevelDetails, GetCommonType } from "../../services/CommonType";

//Get actors by actorId
export const useGetCommonType = (customToken, Type) => {
  return useQuery(
    ["qGetCommonType", Type],
    () => GetCommonType(customToken, Type),
    {
      enabled: !!Type,
    }
  );
};
// //Get Common Type All
// export const useGetCommonTypeAll = (Type) => {
//   return useQuery(["qGetCommonTypeAll", Type], () => GetCommonTypeAll(Type), {
//     enabled: !!Type,
//   });
// };

//Get Billing level details
export const useGetBillingLevelDetails = (level, level_id) => {
  return useQuery(
    ["qGetBillingLevelDetails", level, level_id],
    () => GetBillingLevelDetails(level, level_id),
    {
      enabled: !!level && !!level_id,
    }
  );
};