import React, { useState, useEffect } from "react";
import ETButton from "../../Components/Buttons/ETButton";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import "./Texts.css";
import { Tab, Tabs, TabList } from "../../Components/Tabs/ReactTabs.style";
import { GetTextPackage } from "../../services/Texts";
import TextCard from "./TextCard";
import { Col, Container, Placeholder, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { useAllPropertyList } from "../../hooks/queries/usePropertyQueries";
import { usePropertyOverview } from "../../hooks/stores/usePropertyStore";
import { useRouting } from "../../hooks/stores/useRoutingStore";
import { useAddEditPackage } from "../../hooks/stores/usePackageStore";
import { useBreadcrumb } from "../../Components/BreadcrumbContext ";
import { H2 } from "../../styles/Common.style";

const Texts = ({
  setShowCreateNewTextField,
  setIsEditTextField,
  setTextPackageId,
  textPackageId,
}) => {
  //----------------states----------------//
  const [textCategory, setTextCategory] = useState(0);
  // zustand
  const propOverview = usePropertyOverview((state) => state.propOverview);
  const { setShowPackage, setPackageType, setEditflag } = useRouting(
    (state) => state
  );
  const { setCancelPopup } = useAddEditPackage((state) => state);
  //-------------react query-------------//
  const { data: textpackagesData, refetch: textpackagesDataRefetch } = useQuery(
    ["getTextPackage", propOverview?.[0]?.property_id, textCategory],
    () => GetTextPackage(propOverview?.[0]?.property_id, textCategory),
    { enabled: !!propOverview?.[0]?.property_id }
  );
  const { data: getAllpropertiesList, isLoading } = useAllPropertyList(0);


  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    if (textCategory == "0") {
      updateBreadcrumbs(["Texts", 'All']);

    } else if (textCategory == "1") {
      updateBreadcrumbs(["Texts", 'Tenancy agreement']);

    } else if (textCategory == "2") {
      updateBreadcrumbs(["Texts", 'Website']);

    } else if (textCategory == "3") {
      updateBreadcrumbs(["Texts", 'Ei.Do']);

    }
  }, [textCategory]);
  return (
    <>
      <Container
        fluid
       
        className="p-4 containerStyle"
      >
        <div className="fs-14 radius-8 bg-white mainborder pb-4">
          <div
            className="px-4 pt-3 pb-3 py-3"
            style={{ borderBottom: "1px solid #F0F0F0" }}
          >
            {isLoading ? (
              <div className="" style={{ width: "100px" }}>
                <div className="placeholder col-10 bg-secondary"></div>
              </div>
            ) : (
              <div className=" d-flex align-items-center">
                <H2>Texts</H2>
                <div className="ps-2">
                  {" "}
                  <CustomToolTip  text="Texts" />{" "}
                </div>
              </div>
            )}
          </div>

          <Tabs className="pb-1 d-flex flex-column">
            <Placeholder animation="glow">
              <div
                className="d-flex px-3 py-3 justify-content-between"
                style={{
                  width: "100%",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  borderBottom: "1px solid #F0F0F0",
                }}
              >
                <div>
                  <TabList
                    className=" d-flex light px-2"
                    style={{ gap: "7px", flexWrap: "nowrap" }}
                  >
                    {isLoading ? (
                      <div className="placeholder col-1 radius-8 padding-20 bg-secondary"></div>
                    ) : (
                      <Tab onClick={() => setTextCategory(0)}>All</Tab>
                    )}
                    {isLoading ? (
                      <div className="placeholder col-6 radius-8 padding-20 bg-secondary"></div>
                    ) : (
                      <Tab onClick={() => setTextCategory(1)}>
                        Tenancy agreement
                      </Tab>
                    )}
                    {isLoading ? (
                      <div className="placeholder col-4 radius-8 padding-20 bg-secondary"></div>
                    ) : (
                      <Tab onClick={() => setTextCategory(2)}>Website</Tab>
                    )}

                    {isLoading ? (
                      <div className="placeholder col-3 radius-8 padding-20 bg-secondary"></div>
                    ) : (
                      <Tab onClick={() => setTextCategory(3)}>Ei.Do</Tab>
                    )}
                  </TabList>
                </div>

                <div className="mt-2 pe-2">
                  {isLoading ? (
                    <div
                      className="placeholder col-10 radius-8 padding-20 bg-secondary"
                      style={{ width: "118px" }}
                    ></div>
                  ) : (
                    <ETButton
                      buttonText="ADD NEW PACKAGE"
                      variant={"primary"}
                      icon={true}
                      width="fit-content"
                      onClick={() => {
                        // setShowCreateNewTextField(true);
                        // setIsEditTextField(false);
                        setCancelPopup(false);
                        setShowPackage(true);
                        setPackageType("8");
                        setEditflag(false);
                      }}
                    />
                  )}
                </div>
              </div>
              {/* <div
                className="d-flex px-4"
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "100%",
                  gap: "20px",
                }}
              > */}
              <Row className="px-4 py-4">
                {textpackagesData?.data == 0 && textCategory == 3 ? (
                  <div className="d-flex justify-content-center w-100 created-by mt-1">
                    <div>You do not have any created  Ei.Do package yet.Click on “+ ADD NEW PACKAGE” to create your first.</div>
                  </div>
                ) : (
                  <>
                    {textpackagesData?.data == 0 && textCategory == 2 ? (
                      <div className="d-flex justify-content-center w-100 created-by mt-1">
                        <div>
                          You do not have any created  Website package yet.Click on “+ ADD NEW PACKAGE” to create your first.
                        </div>
                      </div>
                    ) : (
                      <>
                        {textpackagesData?.data == 0 && textCategory == 1 ? (
                          <div className="d-flex justify-content-center w-100 created-by mt-1">
                            <div>
                              You do not have any created  tenancy agreement package yet.Click on “+ ADD NEW PACKAGE” to create your first.
                              yet.{" "}
                            </div>{" "}
                          </div>
                        ) : (
                          <>
                            {textpackagesData?.data == 0 &&
                              textCategory == 0 ? (
                              <div className="d-flex justify-content-center w-100 created-by mt-1">
                                <div>
                                  You do not have any created text package yet.Click on “+ ADD NEW PACKAGE” to create your first.{" "}
                                </div>{" "}
                              </div>
                            ) : (
                              <>
                                {textpackagesData?.data?.map((data, index) => (
                                  <Col
                                    xl={4}
                                    lg={6}
                                    key={index}
                                    className="pb-4"
                                  >
                                    {isLoading ? (
                                      <div
                                        className="placeholder col-5 radius-8 padding-20 bg-secondary"
                                        style={{
                                          height: "265px",
                                          width: "100%",
                                        }}
                                      ></div>
                                    ) : (
                                      <TextCard
                                        textDetailsData={data}
                                        textpackagesDataRefetch={
                                          textpackagesDataRefetch
                                        }
                                        key={index}
                                        // setIsEditTextField={setIsEditTextField}
                                        // setShowCreateNewTextField={
                                        //   setShowCreateNewTextField
                                        // }
                                        // propertyId={
                                        //   propOverview?.[0]?.property_id
                                        // }
                                        // setTextPackageId={setTextPackageId}
                                        getAllpropertiesList={
                                          getAllpropertiesList
                                        }
                                        // textPackageId={textPackageId}
                                        setEditflag={setEditflag}
                                      />
                                    )}
                                  </Col>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                {/* </div> */}
              </Row>
            </Placeholder>
          </Tabs>
        </div>
      </Container>
    </>
  );
};

export default Texts;
