import React, { useEffect, useState } from "react";
import { H2, H5 } from "../../../styles/Common.style";
import { Col, Form, Row } from "react-bootstrap";
import ETButton from "../../../Components/Buttons/ETButton";
import PasswordField from "../../../Components/GroupText/PasswordField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CreatePassword = ({
  handleNext,
  password,
  setPassword,
  isUpdateSignupLoading,
}) => {
  //================states=================//
  const [isDigit, setIsDight] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [atleastEightCharacter, setAtleastEightCharacter] = useState(false);

  //================effect to handle password contains=================//

  useEffect(() => {
    setIsValid(
      password.length >= 8 &&
        specialChar &&
        isDigit &&
        isUpperCase &&
        isLowerCase
    );
  }, [password]);

  //================effect to handle confirm password=================//

  useEffect(() => {
    if (password === confirmPassword) {
      setPasswordsMatch(true);
    }
  }, [password, confirmPassword]);

  //===============Password handler ====================//

  const handleInputChange = (event) => {
    const value = event.target.value;
    setPassword(value);

    // Validate input value

    setIsDight(/\d/.test(value));
    setIsLowerCase(/[a-z]/.test(value));
    setIsUpperCase(/[A-Z]/.test(value));
    setAtleastEightCharacter(value.length >= 8);
    setSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(value));
    setIsValid(
      value.length >= 8 && specialChar && isDigit && isUpperCase && isLowerCase
    );
  };
  //===============Confirm Password handler ====================//
  const handleConfirmPassword = (event) => {
    const confirmValue = event.target.value;
    setConfirmPassword(confirmValue);
  };

  //===============Submit handler ====================//

  const handleSubmit = (event) => {
    event.preventDefault();

    if (password === confirmPassword) {
      // Passwords match, you can proceed with further actions
      handleNext(password);
      setPasswordsMatch(true);
    } else {
      // Passwords don't match, handle accordingly (e.g., show an error message)
      setPasswordsMatch(false);
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      <div className="p-3">
        <H2 className="mb-1">Create user</H2>
        <div className="light-font-text fs-12 pb-40"> Step 5 out of 5</div>

        <div className="font-bold fs-14 pb-4 ">Create your password</div>
        <div className="light-font-text mb-4">
          <Row>
            <Col sm={9}>
              Enter a strong and secure password that will be used to log in to
              the system.
            </Col>
          </Row>
        </div>
        <Row className="mb-3">
          <Col sm={9}>
            <PasswordField
              id={"Password"}
              placeholder={"Password"}
              maxlength={15}
              value={password}
              onChange={handleInputChange}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm={9}>
            <PasswordField
              iserror={!passwordsMatch && true}
              id={"confirmpassword"}
              placeholder={"Confirm password"}
              maxlength={15}
              value={confirmPassword}
              onChange={handleConfirmPassword}
            />
          </Col>
        </Row>
        <div className="header-semibold mb-3">Your password must contain:</div>
        <div className="d-flex flex-column gap-2 pb-40">
          <div className="d-flex align-items-center gap-2">
            <FontAwesomeIcon
              icon={
                atleastEightCharacter
                  ? "fa-solid fa-circle-check"
                  : "fa-solid fa-circle-exclamation"
              }
              style={{ color: atleastEightCharacter ? "#349032" : "#888888" }}
            />
            <span className="light-font-text fs-12">
              Eight or more characters
            </span>
          </div>
          <div className="d-flex align-items-center gap-2">
            <FontAwesomeIcon
              icon={
                specialChar
                  ? "fa-solid fa-circle-check"
                  : "fa-solid fa-circle-exclamation"
              }
              style={{ color: specialChar ? "#349032" : "#888888" }}
            />
            <span className="light-font-text fs-12">
              At least one special character
            </span>
          </div>
          <div className="d-flex align-items-center gap-2">
            <FontAwesomeIcon
              icon={
                isLowerCase && isUpperCase
                  ? "fa-solid fa-circle-check"
                  : "fa-solid fa-circle-exclamation"
              }
              style={{
                color: isLowerCase && isUpperCase ? "#349032" : "#888888",
              }}
            />
            <span className="light-font-text fs-12">
              At least one upper and one lower case letter
            </span>
          </div>
          <div className="d-flex align-items-center gap-2">
            <FontAwesomeIcon
              icon={
                isDigit
                  ? "fa-solid fa-circle-check"
                  : "fa-solid fa-circle-exclamation"
              }
              style={{ color: isDigit ? "#349032" : "#888888" }}
            />

            <span className="light-font-text fs-12">At least one number</span>
          </div>
        </div>
        {passwordsMatch}
        <ETButton
          type={"submit"}
          buttonText={"CREATE MY USER"}
          variant={"primary"}
          disabled={!isValid && isUpdateSignupLoading ? true : false}
        />
      </div>
    </Form>
  );
};

export default CreatePassword;

//Created by Abu
