import { useAppState } from "../AllState";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import "../../documents.css";
import { Controller, useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { useEffect } from "react";

const RentalsAndTermination = ({
  submitRef,
  handleNext,
  prevRef,
  activeTenancyForm,
  getConfigurationDetailsData,
  handleEditConfig,
  previewRef,
}) => {
  const [state, setState] = useAppState();
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: state[activeTenancyForm] });

  const saveData = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 2, true);
    // handleNext(2);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  const preview = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 2, false);
    // handleNext(1);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  useEffect(() => {
    setValue(
      "rental_start_date",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "rental_start_date"
      )[0]?.actual_value
    );
  }, [getConfigurationDetailsData]);
  return (
    <>
      <Form className="main-form" onSubmit={handleSubmit(saveData)}>
        <div style={{ borderBottom: "1px solid #F0F0F0" }}>
          <div className="px-4 py-3">
            <div className="actors-section-title">
              §2 The rentals beginning and termination
            </div>
            <div className="light-font-text grey-text">
              Lorem ipsum dolor sit lorem ipsum dolor sit
            </div>
          </div>
        </div>
        <div className="px-4 py-4">
          <div className="d-flex align-items-center">
            <span className="me-2 form-label-title">Beginning</span>{" "}
            <CustomToolTip size="sm" text="Beginning" />
          </div>

          <div className=" light-font-text mb-4">
            The rental starts the
            <div
              className="mx-1"
              style={{
                // width: "194px",
                position: "relative",
                display: "inline-flex",
              }}
            >
              <div className="form-input">
                <Controller
                  control={control}
                  {...register("rental_start_date")}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <input
                        style={{ height: "26px" }}
                        className="mx-sm-0"
                        type="text"
                        value={value}
                        disabled={true}
                      />
                    );
                  }}
                />
                <label for="date">Date</label>
              </div>
              {/* <div className="form-input">
                <input
                  className="mx-sm-0"
                  type="text"
                  placeholder="Merged data"
                  id="tenanacy_phone"
                  disabled={true}
                  style={{ height: "26px" }}
                  {...register(
                    "tenanacy_phoneNo"
                    // , {
                    //   required: "phone is required",
                    // }
                  )}
                />
                <label for="tenanacy_phone">Merged data</label>
              </div> */}
              {errors.date && (
                <div className="ms-2">
                  <p
                    className="red-text fs-14 mb-0"
                    style={{ width: "max-content" }}
                  >
                    (date is required)
                  </p>
                </div>
              )}
            </div>
            and shall continue until terminated, unless the tenancy is for a
            fixed term, cf. §11{" "}
          </div>

          {/* _______________________Termination_______________________ */}
          <div className="d-flex mt-2 align-items-center">
            <span className="me-2 form-label-title">Termination</span>{" "}
            <CustomToolTip size="sm" text="Termination" />
          </div>
          <div
            className="light-font-text pt-2"
            style={{ lineHeight: "17.5px", textAlign: "justify" }}
          >
            The notice of termination must be in writing from both parties.
            Unless otherwise agreed and stated in §11 of the contract, the
            tenant may terminate a tenancy agreement by giving 3 months' notice
            to the first working day of a month that is not the day before a
            public holiday. However, for ancillary single rooms, the notice
            period is 1 month. Notice of termination by the landlord may be
            given in accordance with sections 170 and 171 of the Rent Act.
          </div>
        </div>
        <button
          onClick={handleSubmit(saveData)}
          ref={submitRef}
          type="submit"
          style={{ display: "none" }}
        />
        <button
          onClick={handleSubmit(preview)}
          ref={previewRef}
          type="submit"
          style={{ display: "none" }}
        />
      </Form>
    </>
  );
};

export default RentalsAndTermination;
