import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import { REQUIRED_ERR } from "../../utils/validationConstants";
import CustomCheckBox from "../Checkbox/Checkbox";
// import { colourOptions } from './colourOptions';

const { MenuList, ValueContainer, Placeholder } = components;

// console.log("MenuList",MenuList)
// console.log("ValueContainer",ValueContainer)
// console.log("Placeholder",Placeholder)

const CustomMenuList = ({ selectProps, ...props }) => {
  const [search, setSearch] = useState("");

  const {
    onInputChange,
    inputValue,
    onMenuInputFocus,
    setInputValuea,
    searchBarHeader = "Search for",
  } = selectProps;

  return (
    // Menu search bar and dropdown value
    <div>
      <div className="pt-3 ps-3 fs-14 pb-2 fw-300 grey-text">
        {searchBarHeader}
      </div>

      <div
        className="d-flex justify-content-center pb-3"
        style={{ borderBottom: "1px solid #D1D1D1" }}
      >
        <div
          className="d-flex mx-3 p-2 mb-0 pe-3"
          style={{
            border: "1px solid #F0F0F0",
            borderRadius: "8px",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <input
              className=""
              style={{
                border: "none",
                outline: "none",
                color: "#202020",
                width: "100%",
              }}
              type="text"
              value={search}
              onKeyDown={(e) => {
                if (e.keyCode === 8) {
                  setSearch((search) => search.slice(0, search.length - 1));
                  setInputValuea((prev) => prev?.slice(0, prev?.length - 1));
                }
              }}
              onChange={(e) => {
                setInputValuea(e.currentTarget.value);
                setSearch(e.currentTarget.value);
                // onInputChange(e.currentTarget.value, {
                //   action: "input-change",
                // });
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
                e.target.focus();
              }}
              onTouchEnd={(e) => {
                e.stopPropagation();
                e.target.focus();
              }}
              onFocus={onMenuInputFocus}
              placeholder="Search..."
            />
          </div>

          <div className="ps-4 pt-1" style={{ flex: "none" }}>
            <FontAwesomeIcon
              icon="fa-regular fa-magnifying-glass"
              style={{ color: "#222222" }}
            />
          </div>
        </div>
      </div>

      <MenuList {...props} selectProps={selectProps} />
    </div>
  );
};

// Set custom `SingleValue` and `Placeholder` to keep them when searching
export const CustomValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue();

  let selectedItemsLength = 0;
  let toBeRendered = children;
  if (currentValues.length > 1) {
    toBeRendered = [children[0].slice(0, 2), children[1]];
    selectedItemsLength = currentValues.length - 2;
  }

  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap", // Ensures values stay in a single row
        }}
      >
        {toBeRendered}{" "}
        {selectedItemsLength
          ? (() => (
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  alignItems: "center",
                }}
              >
                <span>+</span> {selectedItemsLength}
              </div>
            ))()
          : ""}
        {/* {additionalItemCount > 0 && (
          <div className="ms-1 font-semibold d-flex justify-content-center align-items-center">
            +{additionalItemCount}
          </div>
        )} */}
      </div>
    </ValueContainer>
  );
};

export const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon
          icon="fa-solid fa-chevron-down"
          style={{ color: "#000000" }}
        />
      </components.DropdownIndicator>
    )
  );
};
export const ClearIndicator = (props) => {
  return (
    components.ClearIndicator && (
      <components.ClearIndicator {...props}>
        <FontAwesomeIcon
          icon="fa-solid fa-xmark"
          style={{ color: "#000000" }}
        />
      </components.ClearIndicator>
    )
  );
};

const FlagOption = (props) => {
  const { data, isSelected, selectProps, isMulti } = props;

  // console.log("isSelected-data",isSelected)
  // console.log("FlagOption-dataa",data)
  // console.log("selectProps",selectProps)

  const handleCheckboxChange = () => {
    const { onChange } = selectProps;
    onChange(data, {
      action: isSelected ? "deselect-option" : "select-option",
    });
  };

  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {isMulti ? <CustomCheckBox checked={isSelected} /> : ""}
        {data.label}
      </div>
    </components.Option>
  );
};

const CustomReactSelect = ({
  options,
  inputValue = " ",
  isSearchable,
  value,
  // onInputChange,
  placeholder,
  innerLabel,
  iserror,
  errorScope = true,
  errorMessage,
  searchBarHeader,
  isMulti,
  onChange,
  disabled,
}) => {
  // console.log(value, "valuevaluevaluevaluevalue");
  const containerRef = useRef(null);
  // console.log("containerRef", containerRef.current);
  const [isFocused, setIsFocused] = useState(false);
  const [selectedValues, setSelectedValues] = useState(value || []);
  const [inputValuea, setInputValuea] = useState(inputValue);

  // console.log(selectedValues, "selectedValues");
  const onDomClick = (e) => {
    let menu = containerRef.current.querySelector(".select__menu");

    if (
      !containerRef.current.contains(e.target) ||
      !menu ||
      !menu.contains(e.target)
    ) {
      setIsFocused(false);
      setInputValuea("");
    }
  };

  // const handleSelectChange = (selectedOptions) => {
  //   onInputChange(selectedOptions);
  //   setSelectedValues(selectedOptions);
  // };

  const customFilter = (o) => {
    if (!inputValuea?.length) return true;
    if (o?.label?.toLowerCase()?.includes(inputValuea?.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onDomClick);

    return () => {
      document.removeEventListener("mousedown", onDomClick);
    };
  }, []);

  return (
    <div ref={containerRef} className="w-100">
      <Select
        className="basic-single"
        styles={innerLabel === true ? colourStyles : normalInputStyle(iserror)}
        // classNamePrefix="select"
        name="color"
        isClearable={false}
        isDisabled={disabled}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        menuPlacement={"auto"}
        // options={colourOptions}
        options={options?.filter((o) => customFilter(o))}
        components={{
          Option: FlagOption,
          MenuList: CustomMenuList,
          ValueContainer: CustomValueContainer,
          DropdownIndicator,
          // ClearIndicator,
        }}
        // value={selectedValues}
        value={value}
        // inputValue={inputValuea}
        setInputValuea={setInputValuea}
        placeholder={placeholder}
        // isSearchable={false}
        isSearchable={isSearchable}
        onMenuInputFocus={() => setIsFocused(true)}
        // onChange={() => setIsFocused(false)}
        // onChange={onChange}
        onChange={(e, ActionType) => {
          onChange(e, ActionType);
          setIsFocused(false);
        }}
        searchBarHeader={searchBarHeader}
        hideSelectedOptions={false}
        // onInputChange={(val) => setInputValue(val)}
        onInputChange={value}
        {...{
          menuIsOpen: isFocused || undefined,
          isFocused: isFocused || undefined,
        }}
      />
      {errorScope && iserror && (
        <p className="error-msg ">
          <div className=" d-flex align-items-center gap-1">
            <FontAwesomeIcon
              icon="fa-solid fa-circle-exclamation "
              size="sm"
              style={{ color: "#e33716" }}
            />{" "}
            {errorMessage ? errorMessage : REQUIRED_ERR}
          </div>
        </p>
      )}
    </div>
  );
};

// const CustomReactSelect = ({
//   options,
//   inputValue,
//   isSearchable,
//   value,
//   onInputChange,
//   placeholder,
//   innerLabel,
//   iserror,
//   errorScope = true,
//   errorMessage,
//   searchBarHeader,
//   isMulti,
// }) => {
//   const containerRef = useRef(null);
//   const [isFocused, setIsFocused] = useState(false);
//   const [selectedValues, setSelectedValues] = useState(value || []);
//   const [inputValuea, setInputValuea] = useState([]);
//   console.log(selectedValues, "selectedValues");

//   const onDomClick = (e) => {
//     let menu = containerRef.current.querySelector(".select__menu");

//     if (
//       !containerRef.current.contains(e.target) ||
//       !menu ||
//       !menu.contains(e.target)
//     ) {
//       setIsFocused(false);
//     } else {
//       setIsFocused(true);
//     }
//   };

//   const handleSelectChange = (selectedOptions) => {
//     console.log("selectedOptions", selectedOptions);
//     onInputChange(selectedOptions);
//     setSelectedValues(selectedOptions);
//   };

//   const handleInputChange = (inputValue) => {
//     setInputValuea(inputValue);
//     onInputChange(inputValue);
//   };

//   useEffect(() => {
//     document.addEventListener("mousedown", onDomClick);

//     return () => {
//       document.removeEventListener("mousedown", onDomClick);
//     };
//   }, [isFocused]);

//   return (
//     <div ref={containerRef}>
//       <Select
//         className="basic-single"
//         styles={innerLabel === true ? colourStyles : normalInputStyle(iserror)}
//         name="color"
//         isMulti={isMulti}
//         closeMenuOnSelect={false}
//         options={options}
//         components={{
//           Option: FlagOption,
//           MenuList: CustomMenuList,
//           ValueContainer: CustomValueContainer,
//           DropdownIndicator,
//         }}
//         value={selectedValues}
//         inputValue={inputValuea}
//         placeholder={placeholder}
//         isSearchable={isSearchable}
//         onMenuInputFocus={() => setIsFocused(true)}
//         onChange={handleSelectChange}
//         onInputChange={handleInputChange}
//         searchBarHeader={searchBarHeader}
//         hideSelectedOptions={false}
//         menuIsOpen={isFocused || undefined}
//         isFocused={isFocused || undefined}
//       />
//       {errorScope && iserror && (
//         <p className="error-msg ">
//           {errorMessage ? errorMessage : REQUIRED_ERR}
//         </p>
//       )}
//     </div>
//   );
// };
CustomReactSelect.defaultProps = {
  searchable: true,
  clearable: true,
  disabled: false,
  noOptionsMessage: "No options",
};
export default CustomReactSelect;

// Created by Saurabh
// styling the input field of dropdown
export const normalInputStyle = (iserror) => ({
  container: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "auto",

    scrollbarWidth: "thin", // for Firefox
    scrollbarColor: "transparent transparent", // for Firefox
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent", // Set the thumb color to transparent
    },
    backgroundColor: "#ffffff",
    borderRadius: 8,
    fontFamily: "Cerebri sans-light !important",
    fontSize: 14,
    // fontWeight:300,
    marginLeft: "10px",
    padding: "0px",
    // paddingTop: "2px",
    position: "static !important",
    cursor: state?.isDisabled ? "not-allowed" : "pointer",
    flexWrap: "nowrap",
    display: "block",
  }),

  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#000000", // Custom colour
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
    padding: "10px 16px",
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    width: "0px",
  }),
  placeholder: (base, state) => ({
    ...base,
    position: "absolute",
    fontWeight: "300",
    background: "rgb(255,255,255)",

    borderRadius: 2,
    padding:
      state.hasValue || state.selectProps.inputValue ? "0px 4px" : "0px 1px",
    margin: "0px",
    // marginLeft: "10px",
    color: !state.hasValue
      ? "#000"
      : state.hasValue || state.selectProps.inputValue
      ? "#000000"
      : "#000",
    fontFamily:
      state.hasValue || state.selectProps.inputValue
        ? " Cerebri sans-medium !important"
        : "Cerebri sans-light !important",
    top:
      state.hasValue || state.selectProps.inputValue
        ? state?.isMulti
          ? "-20%"
          : "-15%"
        : "",
    transition: "top 0.2s, font-size 0.2s",
    fontSize: state.hasValue ? 8 : 14,
    fontWeight: 500,

    // (state.hasValue ||
    //   state.selectProps.inputValue ||
    //   state.selectProps.isFocused) &&
    // 14,
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    // paddingTop: "4px",
    fontSize: 14,
    fontWeight: 300,
    boxShadow: state.isFocused ? "none" : "none",
    borderColor:
      iserror && !state.hasValue
        ? "#e33716 !important"
        : state.isFocused
        ? "#000 !important"
        : "#d1d1d1 !important",
    borderRadius: "8px",
    backgroundColor: "#fff",
    "&:hover": {
      borderColor:
        iserror && !state.hasValue
          ? "#e33716 !important"
          : state.isFocused
          ? "#000 !important"
          : "#d1d1d1 !important",
      boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
    },
    pointerEvents: state.isDisabled && "auto",
    cursor: state.isDisabled ? "not-allowed " : "default",
    minHeight: "40px",
    // opacity: state.isDisabled ? "0.7" : "1",
  }),

  option: (base, { isDisabled, selectProps, ...state }) => {
    const getIndex = state?.options.findIndex(
      (s) => state?.data?.value === s?.value
    );
    return {
      ...base,
      backgroundColor: "#fff",
      fontSize: 14,
      fontFamily: "Cerebri sans-light !important",
      zIndex: 1,
      color: isDisabled ? "#888888" : "#000000",
      paddingLeft: "15px",
      padding: "10px 16px !important",
      minHeight: "40px",
      // borderBottom: "1px solid #F0F",
      borderBottom:
        state?.options?.length - 1 === getIndex ? "" : "1px solid #D1D1D1",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
  singleValue: (provided, { data }) => ({
    ...provided,
    marginLeft: "4px",
    // specify a fallback color here for those values not accounted for in the styleMap
  }),
  menu: (base) => ({
    ...base,
    zIndex: 3,
    fontSize: 14,
    fontWeight: 300,
    width: "max-content",
    minWidth: "100%",
    overflowX: "scroll",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 10000 }),
  menuList: (base) => ({
    ...base,
    maxHeight: "175px", // Adjust the value to your desired maximum height
    overflowY: "auto", // Add vertical scroll if needed
  }),
});

const colourStyles = {
  container: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    backgroundColor: "rgb(255,255,255)",
    fontFamily: "Cerebri sans-light !important",
    borderRadius: 8,
    marginLeft: "10px",
    fontSize: 14,
    paddingTop: "4px",
  }),
  placeholder: (base, state) => ({
    ...base,
    position: "absolute",
    background: "rgb(255,255,255)",

    fontFamily:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? " Cerebri sans-medium !important"
        : "Cerebri sans-light !important",
    background: "#ffffff",
    borderRadius: 2,
    paddingLeft: "4px",
    paddingRight: "4px",
    // marginLeft: "10px",
    top:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? "-75%"
        : "0%",
    transition: "top 0.2s, font-size 0.2s",
    fontSize: state.selectProps.isFocused || state.hasValue ? 8 : 14,
    // (state.hasValue ||
    //   state.selectProps.inputValue ||
    //   state.selectProps.isFocused) &&
    // 14,
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    // paddingTop: "4px",
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? "#d1d1d1 !important" : "#d1d1d1 !important",
    borderRadius: "8px",
    backgroundColor: "#fff",
    fontSize: 14,
    "&:hover": {
      borderColor: "#d1d1d1",
      boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
    },
    pointerEvents: state.isDisabled && "auto",
    cursor: state.isDisabled ? "not-allowed " : "default",
    minHeight: "40px",
    // opacity: state.isDisabled ? "0.6" : "1",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#000000", // Custom colour
    transition: "all .2s ease",
    transform: state.isFocused ? "rotate(180deg)" : null,
    padding: "10px 16px",
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    width: "0px",
  }),
  option: (base, { isDisabled }, state) => ({
    ...base,
    backgroundColor: "#fff",
    zIndex: 1,
    fontSize: 14,
    fontFamily: "Cerebri sans-light !important",
    color: isDisabled ? "#888888" : "#000000",
    paddingLeft: "15px",
    borderBottom: "1px solid #F0F",
    pending: "11px 16px 11px 16px !important",
    minHeight: "40px",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 3,
    fontSize: 14,
    width: "max-content",
    minWidth: "100%",
    overflowX: "scroll",
  }),
  singleValue: (provided, { data }) => ({
    ...provided,
    marginLeft: "4px",
    // specify a fallback color here for those values not accounted for in the styleMap
  }),
  menuPortal: (base) => ({ ...base, zIndex: 10000 }),
};
