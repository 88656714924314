import React, { useEffect, useState } from "react";
import ETButton from "../../../../../../Components/Buttons/ETButton";
import { NavLink, useLocation } from "react-router-dom";
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from "../../../../../../Components/Tabs/ReactTabs.style";
import Status from "./unitsOverview/status/Status";
import Requests from "./unitsOverview/requests/Requests";
import InProcess from "./unitsOverview/inprocess/InProcess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HotMsg } from "../../../../../../styles/Common.style";
import "./submenu.css";
import { useBreadcrumb } from "../../../../../../Components/BreadcrumbContext ";

const UnitsOverview = ({
  setOverviewMainTabs,
  setoverviewTabs,
  overviewTabs,
  overviewMainTabs,
}) => {
  const location = useLocation()
  const { state } = location;
  const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   // Simulate loading data
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 100);
  // }, []);
  const ActorsTabsWizards = [
    {
      Id: "1",
      tab: "Status",
      component: <Status />,
      Notification: "0",
    },
    {
      Id: "2",
      tab: "Requests",
      component: <Requests setoverviewTabs={setoverviewTabs} />,
      Notification: "0",
    },
    {
      Id: "3",
      tab: "In process",
      component: (
        <InProcess
          setoverviewTabs={setoverviewTabs}
          overviewTabs={overviewTabs}
        />
      ),
      Notification: "0",
    },
  ];

  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    updateBreadcrumbs(["Overview", "Status"]);
  }, [])

  return (
    <>
      <div
      className="containerStyleColor"
      >
        <Tabs lassName="mb-3 pb-1 d-flex flex-column">
          <TabList className="light ps-0  ">
            {ActorsTabsWizards.map((page, index) => (
              <>
                <Tab
                  key={index}
                  style={{ position: "relative" }}
                  onClick={() => setOverviewMainTabs(page?.tab)}
                >
                  <span style={{ position: "relative" }}>{page?.tab}</span>
                  {page?.Notification !== "0" && (
                    <HotMsg>
                      <span>{page?.Notification}</span>
                    </HotMsg>
                  )}
                </Tab>
              </>
            ))}
          </TabList>

          <div className="bg-white" style={{ borderRadius: " 10px" }}>
            {ActorsTabsWizards.map((page, index) => (
              <TabPanel key={page?.Id}>{page?.component}</TabPanel>
            ))}
          </div>
        </Tabs>
        {/* <div
          className="pb-2 px-3 d-flex  justify-content-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            position: "absolute",
            width: "70%",
            bottom: "0",
          }}
        >
          <NavLink
            to="/property-units/units"
            state={{ propertyId: propertyId, Tab: "Units" }}
          >
            <ETButton variant={"white"} buttonText="CANCEL" />
          </NavLink>
        </div> */}
      </div>
    </>
  );
};

export default UnitsOverview;
