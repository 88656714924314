import React, { useState } from "react";
import ETButton from "../../../../Components/Buttons/ETButton";
import { CustomerDetailsAfterFillingForm } from "./CreateNewlead";
import SelectUnitTable from "../../opportunities/CreateNewAppointments/SelectUnitTable";

const SelectUnitForLead = ({
  handleBack,
  handleNext,
  // rowSelectionValue,
  unitrowSelection,
  setUnitRowSelection,
  GetUnitsByPropertyIdUpdatedData,
  userDetails,
}) => {
  //   handle next button

  //---------------state--------------------//
  const [rowSelection, setRowSelection] = useState({});

  const handleClickNext = (data) => {
    handleNext(data);
  };

  //   handle Back button
  // const handleBack = () => {
  //   setProgress(60);
  //   setStepWizard("SelectPropertyForLead");
  // };
  return (
    <div>
      <div className="Popups-inner-scrollable-container-height scrollable-container-y">
        {" "}
        <div className="border-bottom">
          <CustomerDetailsAfterFillingForm data={userDetails} />
        </div>
        <SelectUnitTable
          // rowSelectionValue={rowSelectionValue}
          setRowSelection={setUnitRowSelection}
          rowSelection={unitrowSelection}
          GetUnitsByPropertyIdUpdatedData={GetUnitsByPropertyIdUpdatedData}
        />
      </div>

      <div className="py-2 px-3 d-flex flex-row justify-content-between  footer-section-button-container">
        <ETButton
          variant={"secondary"}
          buttonText="Back"
          type={"button"}
          onClick={handleBack}
        />

        <ETButton
          variant={"primary"}
          buttonText="NEXT"
          type={"button"}
          disabled={Object?.keys(unitrowSelection)?.length == 0}
          onClick={handleClickNext}
        />
      </div>
    </div>
  );
};

export default SelectUnitForLead;

// created by Suraj
