import React, { useEffect, useRef, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import GroupText from "../../../../Components/GroupText/GroupText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ETButton from "../../../../Components/Buttons/ETButton";
import "./Popups.css";
import moment from "moment";
import { PostAddNote } from "../../../../services/Notes";
import { toast } from "react-toastify";
import {
  useGetTenancyStatusList,
  useGetTerminationPopupDetails,
} from "../../../../hooks/queries/useActorQueries";
import { Controller, useForm } from "react-hook-form";
import { useUnitId } from "../../../../hooks/stores/useUnitStore";
import { PutUpdateTerminationDetails } from "../../../../services/Actors";
import InputField from "../../../../Components/Textfield/InputField";

// const ongoingRenovationDropdown = [{}]

const TerminationDetails = ({
  setTerminationPopup,
  getNotesDetailsData,
  contractId,
  getNotesDetailsDataRefetch,
  contractOverViewDataRefetch,
}) => {
  //------------------Zustand------------------//
  const { UnitId } = useUnitId((state) => state);

  //------------------States-------------------//
  const [subjectLine, setSubjectLine] = useState("");
  const [message, setMessage] = useState("");

  //----------------React query-------------//
  const { data: getContractTerminationPopupDetailsData } =
    useGetTerminationPopupDetails(contractId);

  //----------------Validation---------------//
  const {
    register,
    handleSubmit,
    clearErrors,
    getValues,
    formState: { errors },
    setValue,
    control,
    reset,
    setFocus,
  } = useForm();

  // const terminationRegistration = useRef(null);
  // const { ref, ...rest } = register('terminationRegistration');
  // useEffect(() => {
  //   const fieldError = Object.keys(errors).reduce((field, a) => {
  //     return !!errors[field] ? field : a;
  //   }, null);

  //   if (fieldError) {
  //     setFocus(fieldError);
  //   }
  // }, [errors, setFocus]);

  useEffect(() => {
    setValue(
      "terminationRegistration",
      getContractTerminationPopupDetailsData?.data?.[0]
        ?.termination_registration_date !== ""
        ? moment(
          getContractTerminationPopupDetailsData?.data?.[0]
            ?.termination_registration_date
        ).format("YYYY-MM-DD")
        : ""
    );
    setValue(
      "dateOfTermination",
      getContractTerminationPopupDetailsData?.data?.[0]?.termination_date !== ""
        ? moment(
          getContractTerminationPopupDetailsData?.data?.[0]?.termination_date
        ).format("YYYY-MM-DD")
        : ""
    );
    setValue(
      "movingOutDate",
      getContractTerminationPopupDetailsData?.data?.[0]?.moving_out_date !== ""
        ? moment(
          getContractTerminationPopupDetailsData?.data?.[0]?.moving_out_date
        ).format("YYYY-MM-DD")
        : ""
    );
    setValue(
      "availabilityDate",
      getContractTerminationPopupDetailsData?.data?.[0]?.date_of_availability !==
        ""
        ? moment(
          getContractTerminationPopupDetailsData?.data?.[0]
            ?.date_of_availability
        ).format("YYYY-MM-DD")
        : ""
    );
    setValue("ongoingRenovation", {
      value:
        getContractTerminationPopupDetailsData?.data?.[0]?.is_renovation_ongoing,
      label:
        getContractTerminationPopupDetailsData?.data?.[0]
          ?.is_renovation_ongoing === 0
          ? "No"
          : "Yes",
    });
    setValue(
      "baseRent",
      getContractTerminationPopupDetailsData?.data?.[0]?.base_rent
    );
    setValue(
      "calculatedDeposit",
      (
        (Number(getContractTerminationPopupDetailsData?.data?.[0]?.base_rent) /
          Number(
            getContractTerminationPopupDetailsData?.data?.[0]?.payment_frequency
          )) *
        getContractTerminationPopupDetailsData?.data?.[0]?.deposit_months
      )?.toFixed(2)
    );
    setValue(
      "calculatedRent",
      (
        (Number(getContractTerminationPopupDetailsData?.data?.[0]?.base_rent) /
          Number(
            getContractTerminationPopupDetailsData?.data?.[0]?.payment_frequency
          )) *
        getContractTerminationPopupDetailsData?.data?.[0]?.prepaid_months
      )?.toFixed(2)
    );
    return () => { };
  }, [getContractTerminationPopupDetailsData]);

  //----------Add note function-----------//
  const handleSendTerminationNote = () => {
    const sendNotePayload = {
      category_id: "3",
      subject: subjectLine,
      message: message,
      contract_id: String(contractId),
    };

    PostAddNote(sendNotePayload).then((response) => {
      if (response?.data?.bReturn) {
        toast.success(response.data.message);
        setMessage("");
        setSubjectLine("");
        getNotesDetailsDataRefetch();
      }
    });
  };
  //----------confirm termination detail function-----------//
  const terminationRegistrationRef = useRef(null);

  const handleConfirmTerminationDetail = async (data) => {
    const updateTerminationDetailPayload = {
      contract_id: String(contractId),
      termination_registration_date: data?.terminationRegistration,
      termination_date: data?.dateOfTermination,
      moving_out_date: data?.movingOutDate,
      is_renovation_ongoing: String(data?.ongoingRenovation?.value),
      date_of_availability: data?.availabilityDate,
      unit_status: "5",
      base_rent: data?.baseRent,
    };
    try {
      const response = await PutUpdateTerminationDetails(updateTerminationDetailPayload);
      if (response?.data?.bReturn) {
        toast.success(response.data.message);
        setTerminationPopup(false);
        contractOverViewDataRefetch();
      }
    } catch (error) {
      toast.error(error?.message);
    }
    // const emptyField = Object.keys(data).find((key) => !data[key]);
    // if (emptyField) {
    //   const elementToScrollTo = document.getElementById(emptyField);
    //   if (elementToScrollTo) {
    //     elementToScrollTo.scrollIntoView({ behavior: 'smooth' });
    //   }
    //   return; // Don't submit the form if there are empty fields
    // }
  };

  //////----------- previous code----confirm termination detail function---------- /////

  // const handleConfirmTerminationDetail =  (data) => {
  //   const updateTerminationDetailPayload = {
  //     contract_id: String(contractId),
  //     termination_registration_date: data?.terminationRegistration,
  //     termination_date: data?.dateOfTermination,
  //     moving_out_date: data?.movingOutDate,
  //     is_renovation_ongoing: String(data?.ongoingRenovation?.value),
  //     date_of_availability: data?.availabilityDate,
  //     unit_status: "5",
  //     base_rent: data?.baseRent,
  //   };

  //   PutUpdateTerminationDetails(updateTerminationDetailPayload)
  //     .then((response) => {
  //       if (response?.data?.bReturn) {
  //         toast.success(response.data.message);
  //         setTerminationPopup(false);
  //         contractOverViewDataRefetch();
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error?.message);
  //     });
  // };





  return (
    <div>
      <Form onSubmit={handleSubmit(handleConfirmTerminationDetail)}>
        <div
          className="px-3 py-4"
          style={{ borderBottom: "1px Solid #F4F5F5" }}
        >
          <Row className="">
            <Col lg={10}>
              <Row>
                <Col lg={4} className="my-md-3">
                  <Controller
                    control={control}
                    {...register("terminationRegistration", { required: true })}
                    render={({ field: { onChange, value } }) => (
                      <div >
                        {/* <input
                          type="date"
                          id="terminationRegistration"
                          placeholder="terminationRegistration"
                          value={field.value}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e); // Use field.onChange directly
                            clearErrors("terminationRegistration");
                          }}
                        /> */}
                        <InputField
                          id={"terminationRegistration"}
                          value={value}
                          type={"date"}

                          placeholder={"Date of termination registration"}

                          iserror={errors.terminationRegistration}
                          onChange={(e) => {

                            onChange(e);
                            clearErrors("terminationRegistration");
                          }}
                        />
                        {/* <label htmlFor="terminationRegistration">
                          Date of termination registration
                        </label> */}
                      </div>
                    )}
                  />

                  {/* {errors.terminationRegistration && (
                    <div className="error-msg">
                      This field is required
                    </div>
                  )} */}
                </Col>
                <Col lg={4} className="mt-md-3">
                  <Controller
                    control={control}
                    {...register("dateOfTermination")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div >
                        {/* <input
                          type="date"
                          id="dateOfTermination"
                          placeholder="dateOfTermination"
                          // value={value}
                          // onChange={(e) => {
                          //   onChange(e);
                          //   clearErrors("dateOfTermination");
                          // }}
                          {...field}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e);
                            clearErrors("terminationRegistration");
                          }}
                        // ref={(e) => {
                        //   field.ref(e);
                        //   terminationRegistrationRef.current = e;
                        // }}
                        /> */}
                        <InputField
                          id={"dateOfTermination"}
                          value={value}
                          type={"date"}

                          placeholder={"Termination date"}

                          iserror={errors.dateOfTermination}
                          onChange={(e) => {

                            onChange(e);
                            clearErrors("terminationRegistration");
                          }}
                        />
                        {/* <label htmlFor="DateofPayment">Termination date</label> */}
                      </div>
                    )}
                  />
                  {/* {errors.dateOfTermination && (
                    <div className="error-msg">
                      This field is required
                    </div>
                  )} */}
                </Col>
                <Col lg={4} className="mt-md-3">
                  <Controller
                    control={control}
                    {...register("movingOutDate")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div >
                        

                        {/* <input
                          type="date"
                          id="movingOutDate"
                          placeholder="movingOutDate"
                          {...field}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e);
                            clearErrors("movingOutDate");
                          }}
                        /> */}
                         <InputField
                          id={"dateOfTermination"}
                          value={value}
                          type={"date"}

                          placeholder={"Moving out date"}

                          iserror={errors.movingOutDate}
                          onChange={(e) => {

                            onChange(e);
                            clearErrors("movingOutDate");
                          }}
                        />
                        {/* <label htmlFor="movingOutDate">Moving out date</label> */}
                      </div>
                    )}
                  />
                  {/* {errors.movingOutDate && (
                    <div className="error-msg">
                      This field is required
                    </div>
                  )} */}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div
          className="px-3 py-4"
          style={{ borderBottom: "1px Solid #F4F5F5" }}
        >
          <div className="font-semibold fs-14" >Announcement details</div>
          <Row className="">
            <Col lg={10}>
              <Row>
                <Col lg={4} className="my-md-3">
                  <Controller
                    control={control}
                    {...register("ongoingRenovation")}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <ReactSelect
                        placeholder={"Ongoing renovation"}
                        options={[
                          { value: 1, label: "Yes" },
                          { value: 0, label: "No" },
                        ]}
                        clearable={false}
                        value={field.value}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors("ongoingRenovation");
                        }}
                      />
                    )}
                  />
                  {errors.ongoingRenovation && (
                    <div className="error-msg">
                      This field is required
                    </div>
                  )}
                </Col>
                <Col lg={4} className="mt-md-3">
                  <Controller
                    control={control}
                    {...register("availabilityDate")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        {/* <input
                          type="date"
                          id="availabilityDate"
                          placeholder="availabilityDate"
                          value={field.value}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            clearErrors("availabilityDate");
                          }}
                        /> */}
                          <InputField
                          id={"availabilityDate"}
                          value={value}
                          type={"date"}

                          placeholder={"Date of availibility"}

                          iserror={errors.availabilityDate}
                          onChange={(e) => {

                            onChange(e);
                            clearErrors("availabilityDate");
                          }}
                        />
                        {/* <label htmlFor="availabilityDate">
                          Date of availibility
                        </label> */}
                      </div>
                    )}
                  />
                  {/* {errors.availabilityDate && (
                    <div className="error-msg">
                      This field is required
                    </div>
                  )} */}
                </Col>
                <Col lg={4} className="mt-md-3">
                  <Controller
                    control={control}
                    {...register("unitStatus")}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <ReactSelect
                        placeholder={"Unit status"}
                        // options={[
                        //   { value: 1, label: "Yes" },
                        //   { value: 0, label: "No" },
                        // ]}
                        clearable={false}
                        value={field.value}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors("unitStatus");
                        }}
                      />
                    )}
                  />
                  {errors.unitStatus && (
                    <div className="error-msg">
                      This field is required
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="">
            <Col lg={10}>
              <Row>
                <Col lg={4} className="my-md-3 pe-0">
                  <Controller
                    control={control}
                    {...register("baseRent")}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <GroupText
                        lable="Base rent"
                        graylabel="DKK"
                        textWidth="20%"
                        type="number"
                        id={"baseRent"}
                        value={field.value}
                        field={field}
                        onChange={(e) => {
                          setValue(
                            "calculatedDeposit",
                            (
                              (Number(e.target.value) /
                                Number(
                                  getContractTerminationPopupDetailsData
                                    ?.data?.[0]?.payment_frequency
                                )) *
                              getContractTerminationPopupDetailsData?.data?.[0]
                                ?.deposit_months
                            )?.toFixed(2)
                          );
                          setValue(
                            "calculatedRent",
                            (
                              (Number(e.target.value) /
                                Number(
                                  getContractTerminationPopupDetailsData
                                    ?.data?.[0]?.payment_frequency
                                )) *
                              getContractTerminationPopupDetailsData?.data?.[0]
                                ?.prepaid_months
                            )?.toFixed(2)
                          );
                          field.onChange(e);
                          clearErrors("baseRent");
                        }}
                      />
                    )}
                  />
                  {errors.baseRent && (
                    <div className="error-msg">
                      This field is required
                    </div>
                  )}
                </Col>
                <Col lg={4} className="mt-md-3 pe-0">
                  <Controller
                    control={control}
                    {...register("calculatedDeposit")}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <GroupText
                        disabled={true}
                        lable="Calculated deposit"
                        graylabel="DKK"
                        textWidth="20%"
                        type="number"
                        id={"calculatedDeposit"}
                        value={field.value}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors("calculatedDeposit");
                        }}
                      />
                    )}
                  />
                </Col>
                <Col lg={4} className="mt-md-3 pe-0">
                  <Controller
                    control={control}
                    {...register("calculatedRent")}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <GroupText
                        disabled={true}
                        lable="Calculated pre-paid rent"
                        graylabel="DKK"
                        textWidth="20%"
                        type="number"
                        id={"CalculatedPrepaid"}
                        value={field.value}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors("calculatedRent");
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="px-3 py-4">
          <div
            className="mb-3 font-semibold fs-14"
          >
            Add a note
          </div>
          <div className="deposit-text-area">
            <div className="pe-2 pt-2">
              <div
                style={{ height: "219px ", overflowY: "scroll" }}
                className="scrollable-container-y "
              >
                {getNotesDetailsData?.data?.filter(
                  (el) => el?.category_id === 3
                )?.length > 0 ? (
                  getNotesDetailsData?.data
                    ?.filter((el) => el?.category_id === 3)
                    ?.map((item, index) => (
                      <div
                        key={index}
                        className="card-div px-3 py-2 ms-3  mb-2"
                      >
                        <div className="d-flex justify-content-between">
                          <div
                            style={{
                              backgroundColor: item?.bg_color || "#9370DB",
                              color: "white",
                              borderRadius: "4px",
                              fontSize: "9px",
                              alignItems: "center",
                              textTransform: "uppercase",
                            }}
                            className="mt-1 px-2 font-semibold  d-flex"
                          >
                            {item?.category_name}
                          </div>
                          <div className="fs-10"

                          >
                            {moment(item?.created_on)?.format(
                              "DD MMM, YYYY [at] hh:mm"
                            )}
                          </div>
                        </div>

                        <div
                          className="mt-2 font-semibold fs-14"
                        >
                          {item?.subject}
                        </div>

                        <div
                          className="mt-1 light-font-text"
                        >
                          {item?.message}
                        </div>
                        <div className="mt-2 d-flex justify-content-between">
                          <div className="light-font-text fs-12"
                          >
                            by {item?.created_by}
                          </div>
                          <div>
                            <FontAwesomeIcon
                              icon="fa-regular fa-arrow-up-right-from-square"
                              style={{ color: "#000000" }}
                            />
                          </div>
                        </div>
                      </div>
                    ))
                ) : (
                  <div
                    className="d-flex justify-content-center w-100 fs-12 mt-2"
                    style={{ color: "#888888" }}
                  >
                    No notes found
                  </div>
                )}
              </div>
            </div>
            <div className="text-area p-3">
              <InputGroup className="deposit-textfield">
                <Form.Control
                  placeholder="Subject line"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  value={subjectLine}
                  onChange={(e) => setSubjectLine(e.target.value)}
                />
              </InputGroup>
              <InputGroup className="deposit-textarea">
                <Form.Control
                  as="textarea"
                  placeholder="Messages"
                  aria-label="Large"
                  aria-describedby="inputGroup-sizing-sm"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <InputGroup.Text
                  className="deposit-textarea-icon"
                  id="inputGroup-sizing-lg"
                >
                  <button
                    type="button"
                    disabled={
                      subjectLine?.length == 0 || (message?.length == 0 && true)
                    }
                    onClick={() => handleSendTerminationNote()}
                    className="message-send-button"
                    style={{
                      opacity:
                        subjectLine?.length == 0 || message?.length == 0
                          ? "50%"
                          : "inherit",
                    }}
                  >
                    {
                      <FontAwesomeIcon
                        size="xs"
                        icon="fa-regular fa-paper-plane-top"
                        style={{ color: "#FFFFFF" }}
                      />
                    }
                  </button>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            borderTop: "1px solid #F4F5F5",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="CLOSE"
            onClick={() => setTerminationPopup(false)}
          />
          <ETButton
            type="submit"
            variant={"primary"}
            buttonText="CONFIRM"
          />
        </div>
      </Form>
    </div>
  );
};

export default TerminationDetails;
