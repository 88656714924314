import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from "../../Components/Tabs/ReactTabs.style";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import { useEffect, useState } from "react";
import "./documents.css";
import SigningPackages from "./signingPackages/SigningPackages";
import { DocumentPackages } from "./documentPackages/DocumentPackages";
import { DocumentProperty } from "./documentProperty/DocumentProperty";
import ETButton from "../../Components/Buttons/ETButton";
import { useQuery } from "react-query";
import { GetDocumentPackagesByPropertyId } from "../../services/Documents";
import { useAllPropertyList } from "../../hooks/queries/usePropertyQueries";
import { usePropertyOverview } from "../../hooks/stores/usePropertyStore";
import { useRouting } from "../../hooks/stores/useRoutingStore";
import { Placeholder } from "react-bootstrap";
import { useAddEditPackage } from "../../hooks/stores/usePackageStore";
import { useBreadcrumb } from "../../Components/BreadcrumbContext ";
import Guides from "./guides/Guides";
import { H2 } from "../../styles/Common.style";

export const Documents = ({
  showDocumentPackages,
  setShowDocumentPackages,
  showSigningPackages,
  setShowSigningPackages,
  propertyOverview,
  setDocumentPackageId,
  setIsEditDocumentPackages,
  documentPackageId,
  signingPackageId,
  setSigningPackageId,
  setIsEditSigningPackages,
  setConfigurationId,
}) => {
  const [activeTab, setActiveTab] = useState({
    tabName: "",
    tabState: "",
  });
  const [tabType, setTabType] = useState(0);
  const [signingPackageType, setSigningPackageType] = useState(0);

  // zustand
  const propOverview = usePropertyOverview((state) => state.propOverview);
  const {
    packageType,
    setPackageType,
    showPackage,
    setShowPackage,
    editflag,
    setEditflag,
    packageId,
    setPackageId,
    packageSubType,
    setPackageSubType,
  } = useRouting((state) => state);
  const { setCancelPopup } = useAddEditPackage((state) => state);
  //---------------------------React query-----------------//
  const { data: getAllpropertiesList, isLoading } = useAllPropertyList(0);
  const {
    data: getDocumentPackagesByPropertyIdData,
    refetch: getDocumentPackagesByPropertyIdDataRefetch,
  } = useQuery(
    [
      "getDocumentPackageByPropertyId",
      propOverview?.[0]?.property_id,
      0,
      tabType,
      signingPackageType,
    ],
    () =>
      GetDocumentPackagesByPropertyId(
        propOverview?.[0]?.property_id,
        0,
        tabType,
        signingPackageType
      ),
    { enabled: !!propOverview?.[0]?.property_id }
  );

  const handleDocumentTabs = (activeTabName = "", activeTabState = "") => {
    setActiveTab({
      tabName: activeTabName,
      tabState: activeTabState,
    });
  };

  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    if (tabType == "0") {
      updateBreadcrumbs(["Documents", "Documents"]);
    } else if (tabType == "1") {
      updateBreadcrumbs(["Documents", "Document packages"]);
    } else if (tabType == "2") {
      updateBreadcrumbs(["Documents", "Signing packages"]);
    } else if (tabType == "3") {
      updateBreadcrumbs(["Documents", "Guides"]);
    }
  }, [tabType]);
  return (
    <div className="containerStyle p-4 my-0">
      <Placeholder animation="glow">
        <div className="fs-14 radius-8 bg-white mainborder">
          <div
            className="px-4 pb-3"
            style={{ borderBottom: "1px solid #F0F0F0" }}
          >
            <div className="pt-3 d-flex align-items-center">
              <H2 >Documents</H2>
              <div className="ps-2">
                <CustomToolTip  text="Documents" />
              </div>
            </div>
          </div>
          <Tabs className="d-flex flex-column m-0">
            <div
              className="documents-tabs px-4 py-3  d-flex justify-content-between flex-wrap gap-2"
              style={{
                borderBottom: "1px solid #F0F0F0",
              }}
            >
              <TabList
                className=" d-flex  ps-0 m-0"
                style={{ gap: "7px", flexWrap: "nowrap" }}
              >
                {isLoading ? (
                  <>
                    <div className="placeholder col-4 radius-8 padding-20"></div>
                  </>
                ) : (
                  <Tab
                    onClick={() => {
                      handleDocumentTabs();
                      setTabType("0");
                    }}
                  >
                    Documents
                  </Tab>
                )}

                {isLoading ? (
                  <>
                    <div className="placeholder col-9 radius-8 padding-20"></div>
                  </>
                ) : (
                  <Tab
                    onClick={() => {
                      handleDocumentTabs(
                        "document-packages",
                        // setShowDocumentPackages
                        setShowPackage
                      );
                      setTabType(1);
                      setSigningPackageType(0);

                      // setPackageSubType("Documents");
                      setPackageType("Documents");
                    }}
                  >
                    Document packages
                  </Tab>
                )}

                {isLoading ? (
                  <>
                    <div className="placeholder col-9 radius-8 padding-20"></div>
                  </>
                ) : (
                  <Tab
                    onClick={() => {
                      handleDocumentTabs(
                        "signing-packages",
                        // setShowSigningPackages
                        setShowPackage
                      );
                      setTabType(2);
                      // setPackageSubType("Signing");

                      setPackageType("Signing");
                    }}
                  >
                    Signing packages
                  </Tab>
                )}
                {isLoading ? (
                  <>
                    <div className="placeholder col-4 radius-8 padding-20"></div>
                  </>
                ) : (
                  <Tab
                    onClick={() => {
                      handleDocumentTabs();
                      setTabType("3");
                    }}
                  >
                    Guides
                  </Tab>
                )}
              </TabList>
              {activeTab.tabName !== "" && (
                <div className="mt-2">
                  <ETButton
                    variant={"primary"}
                    buttonText="ADD NEW PACKAGE"
                    icon={true}
                    width="fit-content"
                    onClick={() => {
                      // setIsEditSigningPackages(false);

                      // setIsEditDocumentPackages(false);
                      setCancelPopup(false);
                      setEditflag(false);
                      setShowPackage(true);
                      if (activeTab.tabName === "document-packages") {
                        setPackageType("6");
                      } else {
                        setPackageType("Signing");
                      }
                      // activeTab.tabState(true); // used prop drilling for showing <AddDocumentPackages /> component outside of document layout.
                      setActiveTab({ ...activeTab, tabState: true });
                    }}
                  />
                </div>
              )}
            </div>
            <div className="document-content py-3 px-4">
              <TabPanel>
                <DocumentProperty
                  propertyId={propOverview?.[0]?.property_id}
                  getDocumentPackagesByPropertyIdData={
                    getDocumentPackagesByPropertyIdData
                  }
                  getDocumentPackagesByPropertyIdDataRefetch={
                    getDocumentPackagesByPropertyIdDataRefetch
                  }
                  setTabType={setTabType}
                />
              </TabPanel>
              <TabPanel>
                <DocumentPackages
                  // showDocumentPackages={showDocumentPackages}
                  // setShowDocumentPackages={setShowDocumentPackages}
                  // propertyOverview={propertyOverview}
                  setDocumentPackageId={setDocumentPackageId}
                  // setIsEditDocumentPackages={setIsEditDocumentPackages}
                  getDocumentPackagesByPropertyIdData={
                    getDocumentPackagesByPropertyIdData
                  }
                  getDocumentPackagesByPropertyIdDataRefetch={
                    getDocumentPackagesByPropertyIdDataRefetch
                  }
                  propertyOverview={propOverview?.[0]?.property_id}
                  // documentPackageId={documentPackageId}
                  getAllpropertiesList={getAllpropertiesList}
                />
              </TabPanel>
              <TabPanel>
                <SigningPackages
                  // setShowSigningPackages={setShowSigningPackages}
                  // showSigningPackages={showSigningPackages}
                  getDocumentPackagesByPropertyIdData={
                    getDocumentPackagesByPropertyIdData
                  }
                  getDocumentPackagesByPropertyIdDataRefetch={
                    getDocumentPackagesByPropertyIdDataRefetch
                  }
                  // signingPackageId={signingPackageId}
                  // setSigningPackageId={setSigningPackageId}
                  propertyId={propOverview?.[0]?.property_id}
                  // setIsEditSigningPackages={setIsEditSigningPackages}
                  getAllpropertiesList={getAllpropertiesList}
                  setConfigurationId={setConfigurationId}
                  setSigningPackageType={setSigningPackageType}
                />
              </TabPanel>
              <TabPanel>
                <Guides
                  setTabType={setTabType}
                  propertyId={propOverview?.[0]?.property_id}
                  getDocumentPackagesByPropertyIdData={
                    getDocumentPackagesByPropertyIdData
                  }
                  getDocumentPackagesByPropertyIdDataRefetch={
                    getDocumentPackagesByPropertyIdDataRefetch
                  }
                />
              </TabPanel>
            </div>
          </Tabs>
          {/* </div> */}
        </div>
      </Placeholder>
    </div>
  );
};
