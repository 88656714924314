import { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import MRTTheme from "../../../../Components/table/MRTTheme";
import { H2, H3 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { SortIcon } from "../../../../Components/table/MRTGlobalProps";

const DistributionOfRejections = () => {
	// table columns
	const columns = useMemo(
		() => [
			{
				accessorFn: (row) => `${row.status} `,
				id: "Reason for decline",
				header: "Reason for decline",
				enableGlobalFilter: false,
				enableSorting: false,
				size: 300,
				Cell: ({ row }) => (
					<div className="d-flex">
						<div
							className="radius-4 me-3"
							style={{
								backgroundColor: row.original.color,
								width: "16px",
								height: "16px",
								marginTop: "2px",
							}}
						></div>
						<div className="">{row.original.status}</div>
					</div>
				),
			},
			{
				accessorKey: "quantity",
				header: "Quantity",
				size: 150,
			},
			{
				accessorKey: "percentage", //normal accessorKey
				header: "Percentage",
				size: 100,
			},
		],
		[]
	);

	// options used in chart
	const options = {
		maintainAspectRatio: false,
		cutout: "80%",
		borderWidth: 2,
		plugins: {
			tooltip: {
				yAlign: "top",
				backgroundColor: "#FFFFFF",
				borderWidth: 2,
				borderDash: [2, 2],
				titleColor: "#000000",
				bodyColor: "#000000",
			},
			legend: {
				display: false,
			},
		},
	};

	return (
		<div className="whiteContainerStyle px-4 py-3">
			<div>
				<Row>
					<Col xl={8} lg={7}>
						<div className="d-flex gap-2 align-items-center">
							<H3>Distribution of rejections</H3>
							<CustomToolTip text={"Distribution of rejections"} size="sm" />
						</div>
					</Col>
					<Col xl={4} lg={5}>
						<ReactSelect
							placeholder={""}
							defaultValue={{
								value: 1,
								label: "Show only declined screenings",
							}}
							options={[
								{ value: 0, label: "Show all" },
								{ value: 1, label: "Show only declined screenings" },
								{ value: 2, label: "Show only canceled screenings" },
							]}
						/>{" "}
					</Col>
				</Row>
			</div>
			<Row className="d-flex justify-content-center align-items-center">
				<Col lg={3} className="">
					<ChartWrapper>
						<DoughnutInner>
							<div className="light-font-text">Number of decline</div>
							<H2 className=" fs-16">2</H2>
						</DoughnutInner>
						<Doughnut data={data} options={options} />
					</ChartWrapper>
				</Col>
				<Col lg={9}>
					<MRTTheme
						colorVariantsForTableRow={["gray"]}
						colorVariantsForTableHead={["white"]}
						table={
							<MaterialReactTable
								columns={columns}
								icons={{ ...SortIcon }}
								enableBottomToolbar={false}
								enableColumnActions={false}
								// data={tableData}
								data={[]}
								muiTablePaperProps={{
									sx: {
										table: {
											borderCollapse: "separate",
											borderSpacing: "0 5px",
										},
									},
								}}
								enableTopToolbar={false}
								muiTableContainerProps={{
									sx: {
										padding: "0px !important",
									},
								}}
								positionGlobalFilter="right"
								positionToolbarDropZone="bottom"
							/>
						}
					/>
				</Col>
			</Row>
		</div>
	);
};
export default DistributionOfRejections;

// styled used chart
const ChartWrapper = styled.div`
	height: 201px;
	position: relative;
`;

const DoughnutInner = styled.div`
	width: 100%;
	position: absolute;
	line-height: 20px;
	top: 50%;
	left: 0;
	margin-top: -22px;
	text-align: center;
	z-index: 0;
`;

// statics data

const data = {
	labels: [
		"Does not meet our requirements for device rental",
		"Cannot be financially approved",
		"Is registered in RKI / Does not fulfil credit rules",
		"Too many people in the household",
		"The unit is rented to another customer",
		"Other reason",
	],
	datasets: [
		{
			data: [20, 30, 15, 15, 10, 10],
			backgroundColor: [
				"#778FA8",
				"#2F3349",
				"#006A4E",
				"#503643",
				"#CFB53B",
				"#B24C42",
			],
		},
	],
};
const tableData = [
	{
		status: "Does not meet our requirements for device rental",
		quantity: 0,
		color: "#778FA8",
		quantity: "37",
		percentage: "37%",
	},
	{
		status: "Cannot be financially approved",
		quantity: 0,
		color: "#2F3349",
		quantity: "37",
		percentage: "37%",
	},
	{
		status: "Is registered in RKI / Does not fulfil credit rules",
		quantity: 0,
		color: "#006A4E",
		quantity: "37",
		percentage: "37%",
	},
	{
		status: "Too many people in the household",
		quantity: 0,
		color: "#503643",
		quantity: "37",
		percentage: "37%",
	},
	{
		status: "The unit is rented to another customer",
		quantity: 0,
		color: "#CFB53B",
		quantity: "37",
		percentage: "37%",
	},
	{
		status: "Other reason",
		quantity: 0,
		color: "#B24C42",
		quantity: "37",
		percentage: "37%",
	},
];

// created logs
// created by saurabh
// // changes in page structure related to code and move unused code below components --done by Suraj
