import { AxPost, AxGet, AxPut, AxDelete } from "../../utils/Axios";
import { GetDefaultHeader } from "./Header";
import { Url } from "./Endpoints";
//Get request
// const GetHeaders = (header) => {
// 	const commonHeader = {};
// 	return Object.assign(commonHeader, header);
// };

export const Post = async (site, endpoint, header, data) => {
  const defaultHeader = GetDefaultHeader(site);
  return await AxPost(
    Url[site],
    endpoint,
    header != undefined || null
      ? Object.assign(defaultHeader, header)
      : defaultHeader,
    data
  );
};

export const Get = async (site, endpoint, header, data) => {
  const defaultHeader = GetDefaultHeader(site);
  return await AxGet(
    Url[site],
    endpoint,
    header != undefined || null
      ? Object.assign(defaultHeader, header)
      : defaultHeader,
    data
  );
};

export const Put = async (site, endpoint, header, data) => {
  const defaultHeader = GetDefaultHeader(site);
  return await AxPut(
    Url[site],
    endpoint,
    header != undefined || null
      ? Object.assign(defaultHeader, header)
      : defaultHeader,
    data
  );
};

export const Delete = async (site, endpoint, header, data) => {
  const defaultHeader = GetDefaultHeader(site);
  return await AxDelete(
    Url[site],
    endpoint,
    header != undefined || null
      ? Object.assign(defaultHeader, header)
      : defaultHeader,
    data
  );
};
