import React, { useEffect, useState } from "react";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { Col, Form, Row } from "react-bootstrap";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import ETButton from "../../../Components/Buttons/ETButton";
import Toggle from "../../../Components/Switch/Switch";
import { Controller, useForm } from "react-hook-form";
// import { useGetSignerSettingByPropertyId } from "../../../hooks/queries/useSigningQueries";
import { usePropertyOverview } from "../../../hooks/stores/usePropertyStore";
import { PutUpdateSigningSetting } from "../../../services/Signing";
import { toast } from "react-toastify";
import useLoadingProgress from "../../../utils/GlobalProgressBar";
import { status } from "../../../model/Constant";
import {
  useGetSignerSettingByPropertyId,
  usePutUpdateSigning,
} from "../../../hooks/queries/useSigningQueries";
import moment from "moment";
import { H2 } from "../../../styles/Common.style";


const Days = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },

  {
    label: "4",
    value: 4,
  },

  {
    label: "5",
    value: 5,
  },

  {
    label: "6",
    value: 6,
  },

  {
    label: "7",
    value: 7,
  },

  {
    label: "8",
    value: 8,
  },

  {
    label: "9",
    value: 9,
  },

  {
    label: "10",
    value: 10,
  },

  {
    label: "11",
    value: 11,
  },

  {
    label: "12",
    value: 12,
  },

  {
    label: "13",
    value: 13,
  },

  {
    label: "14",
    value: 14,
  },

  {
    label: "15",
    value: 15,
  },
];

const NoforSigningRequired = [
  {
    label: "All",
    value: 0,
  },
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
];
const NoOfQaApprover = [
  {
    label: "No approver",
    value: 0,
  },
  {
    label: "1 approver",
    value: 1,
  },
  {
    label: "2 approvers",
    value: 2,
  },
  {
    label: "3 approvers",
    value: 3,
  },
];
const OrderOfSignings = [
  {
    value: "L",
    label: "Landlord first",
  },
  {
    value: "T",
    label: "Tenant first",
  },
  {
    value: "M",
    label: "Mixed",
  },
];

const Signing = () => {
  const [Sigingdata, setSigindData] = useState([]);
  const [isLoadingSaveBtn, setIsloadingSaveBtn] = useState(false);
  //-----------------Zuustand--------------------//
  const propertyOverview = usePropertyOverview((state) => state.propOverview);

  //--------------------React query------------------------//
  const { data: getSignerSettingByPropertyIdData } =
    useGetSignerSettingByPropertyId(propertyOverview?.[0]?.property_id);

  //--------------------States------------------------//
  const [signingBeforeDepositReceived, setSigningBeforeDepositReceived] =
    useState(true);
  const [signingManuallyApproved, setSigningManuallyApproved] = useState(true);

  //------------------validation-------------------------//
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      property_id: propertyOverview?.[0]?.property_id?.toString(),
      signing_setting_id:
        getSignerSettingByPropertyIdData?.data?.[0]?.signing_setting_id,
    },
  });

  const {
    isLoading,
    progress,
    showProgressBarOnInitialState,
    // showProgressBarOnSuccess,
    // showProgressBarOnFailure,
    showProgressBar,
    setProgress,
    setLoaderSpeed,
  } = useLoadingProgress();

  const {
    mutateAsync: editSigingMutate,
    isLoading: PutUpdateSigningIsLoading,
    isError: isErrorOnLoading,
  } = usePutUpdateSigning();

  useEffect(() => {
    if (getSignerSettingByPropertyIdData?.data?.length !== 0) {
      setSigindData(getSignerSettingByPropertyIdData?.data);
      setValue("tenantBuyerDuration", {
        value: Number(
          getSignerSettingByPropertyIdData?.data?.[0]
            ?.tenant_buyer_signing_duration
        ),
        label: Days.filter(
          (el) =>
            el.value ==
              getSignerSettingByPropertyIdData?.data?.[0]
                ?.tenant_buyer_signing_duration && el.label
        )[0]?.label,
      });
      setValue("landlordSellerDuration", {
        value: Number(
          getSignerSettingByPropertyIdData?.data?.[0]
            ?.landlord_seller_signing_duration
        ),
        label: Days.filter(
          (el) =>
            el.value ==
              getSignerSettingByPropertyIdData?.data?.[0]
                ?.landlord_seller_signing_duration && el.label
        )[0]?.label,
      });
      setValue("landlordSellerReminder", {
        value:
          getSignerSettingByPropertyIdData?.data?.[0]
            ?.landlord_seller_reminder_interval,
        label: Days.filter(
          (el) =>
            el.value ==
              getSignerSettingByPropertyIdData?.data?.[0]
                ?.landlord_seller_reminder_interval && el.label
        )[0]?.label,
      });
      setValue("tenantBuyerReminder", {
        value:
          getSignerSettingByPropertyIdData?.data?.[0]
            ?.tenant_buyer_reminder_interval,
        label: Days.filter(
          (el) =>
            el.value ==
              getSignerSettingByPropertyIdData?.data?.[0]
                ?.tenant_buyer_reminder_interval && el.label
        )[0]?.label,
      });
      setValue("landlordSellerRequierd", {
        value:
          getSignerSettingByPropertyIdData?.data?.[0]
            ?.nof_landlord_seller_signers_required,
        label: NoforSigningRequired.filter(
          (el) =>
            el.value ==
              getSignerSettingByPropertyIdData?.data?.[0]
                ?.nof_landlord_seller_signers_required && el.label
        )[0]?.label,
      });
      setValue("signingOrder", {
        value: getSignerSettingByPropertyIdData?.data?.[0]?.order_of_signings,

        label: OrderOfSignings.filter(
          (el) =>
            el.value ==
              getSignerSettingByPropertyIdData?.data?.[0]?.order_of_signings &&
            el.label
        )[0]?.label,
      });
      setValue("noOfQA", {
        value: Number(
          getSignerSettingByPropertyIdData?.data?.[0]?.nof_qa_approvers_required
        ),
        label: NoOfQaApprover.filter(
          (el) =>
            el.value ==
              getSignerSettingByPropertyIdData?.data?.[0]
                ?.nof_qa_approvers_required && el.label
        )[0]?.label,
      });

      setSigningBeforeDepositReceived(
        getSignerSettingByPropertyIdData?.data?.[0]
          ?.no_landlord_seller_signing_before_deposit_received == 1
          ? true
          : false
      );
      setSigningManuallyApproved(
        getSignerSettingByPropertyIdData?.data?.[0]
          ?.no_landlord_seller_signing_before_manual_approved == 1
          ? true
          : false
      );
    }
  }, [getSignerSettingByPropertyIdData]);

  //--------------------handle save signing setting-----------------//
  // const handleSave = async (data) => {
  //   showProgressBarOnInitialState(50, "", true, 50, 5000);
  //   const updateSignerSettingPayload = {
  //     property_id: String(data?.property_id),
  //     signing_setting_id: String(
  //       getSignerSettingByPropertyIdData?.data?.[0]?.signing_setting_id
  //     ),
  //     tenant_buyer_signing_duration: String(data?.tenantBuyerDuration?.value),
  //     landlord_seller_signing_duration: String(
  //       data?.landlordSellerDuration?.value
  //     ),
  //     landlord_seller_reminder_interval: String(
  //       data?.landlordSellerReminder?.value
  //     ),
  //     tenant_buyer_reminder_interval: String(data?.tenantBuyerReminder?.value),
  //     nof_landlord_seller_signers_required: String(
  //       data?.landlordSellerRequierd?.value
  //     ),
  //     no_landlord_seller_signing_before_manual_approved: signingManuallyApproved
  //       ? "1"
  //       : "0",
  //     no_landlord_seller_signing_before_deposit_received:
  //       signingBeforeDepositReceived ? "1" : "0",
  //     nof_qa_approvers_required: String(data?.noOfQA?.value),
  //     order_of_signings: String(data?.signingOrder?.value),
  //   };

  //   try {
  //     const result = await editSigingMutate(updateSignerSettingPayload);
  //     if (result.data.bReturn && result.status === status.s) {
  //       showProgressBar({ result: result }, 50, false, 80, 500);
  //     }
  //     // setTimeout(() => {
  //     setProgress(100);
  //     // }, 500);
  //   } catch (error) {
  //     showProgressBar({ error: error }, 50, false, 100);
  //   }
  // };

  const handleSave = async (data) => {
    setIsloadingSaveBtn(true);
    const updateSignerSettingPayload = {
      property_id: String(data?.property_id),
      signing_setting_id: String(
        getSignerSettingByPropertyIdData?.data?.[0]?.signing_setting_id
      ),
      tenant_buyer_signing_duration: String(data?.tenantBuyerDuration?.value),
      landlord_seller_signing_duration: String(
        data?.landlordSellerDuration?.value
      ),
      landlord_seller_reminder_interval: String(
        data?.landlordSellerReminder?.value
      ),
      tenant_buyer_reminder_interval: String(data?.tenantBuyerReminder?.value),
      nof_landlord_seller_signers_required: String(
        data?.landlordSellerRequierd?.value
      ),
      no_landlord_seller_signing_before_manual_approved: signingManuallyApproved
        ? "1"
        : "0",
      no_landlord_seller_signing_before_deposit_received:
        signingBeforeDepositReceived ? "1" : "0",
      nof_qa_approvers_required: String(data?.noOfQA?.value),
      order_of_signings: String(data?.signingOrder?.value),
    };

    try {
      const response = await PutUpdateSigningSetting(
        updateSignerSettingPayload
      );
      if (response.data.bReturn) {
        toast.success(response.data.message, {
          toastId:
            getSignerSettingByPropertyIdData?.data?.[0]?.signing_setting_id,
        });
        setIsloadingSaveBtn(false);
      } else {
        toast.error(response?.data?.message);
        setIsloadingSaveBtn(false);
      }
    } catch (error) {
      toast.error(error.message);
      setIsloadingSaveBtn(false);
    }

    // PutUpdateSigningSetting(updateSignerSettingPayload)
    //   .then((response) => {
    //     if (response.data.bReturn) {
    //       toast.success(response.data.message);
    //     }
    //   })
    //   .catch((error) => {
    //     toast.error(error.message);
    //   });
  };

  const modifiedData = Sigingdata?.map((item) => {
    return {
      ...item,
      modifiedOn: moment(item.modified_on).format("DD.MMMM.YYYY | HH:mm:ss"),
      createdOn: moment(item.modified_on).format("DD.MMMM.YYYY | HH:mm:ss"),
    };
  });
  return (
    <>
      <Form onSubmit={handleSubmit(handleSave)}>
        <div
          className="signing-conatiner radius-8"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div
            className="signing-headers px-4 py-3"
            style={{ borderBottom: "1px solid #F0F0F0" }}
          >
            <div className=" d-flex align-items-center">
              <H2 className="me-2">Signing</H2>{" "}
              <CustomToolTip size="sm" text="Signing" />
            </div>
            <div>
              {modifiedData?.map((data, i) => (
                <>
                  {data?.modified_by && data?.modified_on ? (
                    <div key={i} className="created-by">
                      Last updated by {data?.modified_by} ,{" "}
                      {moment(data?.modified_on).format(
                        "DD.MMM.YYYY | HH:mm:ss"
                      )}
                    </div>
                  ) : (
                    <>
                      {data?.created_by && data?.created_on ? (
                        <div className="created-by">
                          Last updated by {data?.created_by} ,{" "}
                          {moment(data?.createdOn).format(
                            "DD.MMM.YYYY | HH:mm:ss"
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              ))}
              {/* {Sigingdata.modified_by} */}
            </div>
          </div>
          <div className="signing-body p-4">
            <Row>
              <Col xl={6} className="mb-3">
                <Row className=" pb-1 d-flex align-items-center">
                  <Col className="pe-lg-0 form-label-title fs-14" lg={6}>
                    <div> Tenant/Buyer duration </div>
                  </Col>
                  <Col className="ps-lg-0" lg={6}>
                    <Controller
                      {...register(`tenantBuyerDuration`)}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          options={Days?.map((item) => ({
                            value: item.value,
                            label: item.label,
                          }))}
                          placeholder={"Days"}
                          value={value}
                          name={"tenantBuyerDuration"}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("tenantBuyerDuration");
                          }}
                          clearable={false}
                        />
                      )}
                    />
                    {errors.tenantBuyerDuration && (
                      <p className="error-msg">This field is required</p>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col className="mb-3" xl={6}>
                <Row className=" pb-1 d-flex align-items-center">
                  <Col className="pe-lg-0 form-label-title" lg={6}>
                    LandLord/Seller duration
                  </Col>
                  <Col className="ps-lg-0" lg={6}>
                    <Controller
                      {...register(`landlordSellerDuration`)}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          options={Days?.map((item) => ({
                            value: item.value,
                            label: item.label,
                          }))}
                          name={"landlordSellerDuration"}
                          placeholder={"Days"}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("landlordSellerDuration");
                          }}
                          clearable={false}
                        />
                      )}
                    />
                    {errors.landlordSellerDuration && (
                      <p className="error-msg">This field is required</p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xl={6} className="mb-3">
                <Row className=" pb-1 d-flex align-items-center">
                  <Col className="pe-lg-0  form-label-title" lg={6}>
                    Tenant/Buyer reminder interval
                  </Col>
                  <Col className="ps-lg-0" lg={6}>
                    <Controller
                      {...register(`tenantBuyerReminder`)}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          options={Days?.map((item) => ({
                            value: item.value,
                            label: item.label,
                          }))}
                          placeholder={"Days"}
                          name={"tenantBuyerReminder"}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("tenantBuyerReminder");
                          }}
                          clearable={false}
                        />
                      )}
                    />
                    {errors.tenantBuyerReminder && (
                      <p className="error-msg">This field is required</p>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col className="mb-3" xl={6}>
                <Row className="pb-1 d-flex align-items-center">
                  <Col className="pe-lg-0 form-label-title " lg={6}>
                    LandLord/Seller reminder interval
                  </Col>
                  <Col className="ps-lg-0" lg={6}>
                    <Controller
                      {...register(`landlordSellerReminder`)}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          options={Days?.map((item) => ({
                            value: item.value,
                            label: item.label,
                          }))}
                          placeholder={"Days"}
                          name={"landlordSellerReminder"}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("landlordSellerReminder");
                          }}
                          clearable={false}
                        />
                      )}
                    />
                    {errors.landlordSellerReminder && (
                      <p className="error-msg">This field is required</p>
                    )}
                  </Col>
                </Row>{" "}
              </Col>
            </Row>

            <Row>
              <Col xl={6} className="mb-3">
                <Row className="pb-1 d-flex align-items-center">
                  <Col className="pe-lg-0 form-label-title " lg={6}>
                    Order of signing
                  </Col>
                  <Col className="ps-lg-0" lg={6}>
                    <Controller
                      {...register(`signingOrder`)}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder={"Order of signing"}
                          options={OrderOfSignings?.map((item) => ({
                            value: item.value,
                            label: item.label,
                          }))}
                          value={value}
                          name={"signingOrder"}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("signingOrder");
                            if (e.value !== "T") {
                              setSigningBeforeDepositReceived(false);
                              setSigningManuallyApproved(false);
                            } else {
                              setSigningBeforeDepositReceived((prev) => prev);
                              setSigningManuallyApproved((prev) => prev);
                            }
                          }}
                          clearable={false}
                        />
                      )}
                    />
                    {errors.signingOrder && (
                      <p className="error-msg">This field is required</p>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col xl={6} className="mb-3">
                <Row className="pb-1 d-flex align-items-center">
                  <Col className="pe-lg-0 form-label-title" lg={6}>
                    No of LandLord/Seller signers required
                  </Col>
                  <Col className="ps-lg-0" lg={6}>
                    <Controller
                      {...register(`landlordSellerRequierd`)}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder={"No of signers"}
                          options={NoforSigningRequired?.map((item) => ({
                            value: item.value,
                            label: item.label,
                          }))}
                          value={value}
                          name={"landlordSellerRequierd"}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("landlordSellerReminder");
                          }}
                          clearable={false}
                        />
                      )}
                    />
                    {errors.landlordSellerReminder && (
                      <p className="error-msg">This field is required</p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xl={6} className="mb-3">
                <Row className=" pb-1 d-flex align-items-center">
                  <Col className="pe-lg-0  form-label-title" lg={6}>
                    No of QA approver required
                  </Col>
                  <Col className="ps-lg-0" lg={6}>
                    <Controller
                      {...register(`noOfQA`)}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder={"No of QA"}
                          options={NoOfQaApprover?.map((item) => ({
                            value: item.value,
                            label: item.label,
                          }))}
                          value={value}
                          name={"noOfQA"}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("noOfQA");
                          }}
                          clearable={false}
                        />
                      )}
                    />
                    {errors.noOfQA && (
                      <p className="error-msg">This field is required</p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xl={6} className="mb-3">
                <Row className="  pb-1 d-flex ">
                  <Col
                    className="pe-lg-0 form-label-title d-flex align-items-center"
                    lg={9}
                  >
                    LandLord/Seller signing manually approved
                  </Col>
                  <Col lg={3}>
                    <Toggle
                      disabled={
                        getValues("signingOrder")?.value !== "T" ? true : false
                      }
                      id={"LandLord_Seller"}
                      checked={signingManuallyApproved}
                      onChange={(e) =>
                        setSigningManuallyApproved(e.target.checked)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col xl={6} className="mb-3">
                <Row className=" pb-1 d-flex ">
                  <Col
                    className="pe-lg-0 form-label-title d-flex align-items-center"
                    lg={9}
                  >
                    LandLord/Seller signing before deposit recieved
                  </Col>
                  <Col lg={3}>
                    <Toggle
                      disabled={
                        getValues("signingOrder")?.value !== "T" ? true : false
                      }
                      id={"signing_before_deposit"}
                      checked={signingBeforeDepositReceived}
                      onChange={(e) =>
                        setSigningBeforeDepositReceived(e.target.checked)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-end  justify-content-end "
            style={{
              backgroundColor: "#F9FAFA",
              borderRadius: " 0px 0px 8px 8px",
            }}
          >
            <ETButton
              variant={"primary"}
              buttonText="SAVE"
              type="submit"
              disabled={isLoadingSaveBtn}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default Signing;
