import React from "react";
import { NavLink } from "../../../Components/Tabs/ReactTabs.style";
import { Outlet } from "react-router-dom";

const DivisionCommunication = () => {
  const divisionCommunicationTabs = [
    {
      Id: "1",
      tab: "Guides",
      link: "guides",
    },
    {
      Id: "2",
      tab: "Notice messages",
      link: "notice-messages",
    },
    {
      Id: "3",
      tab: "FAQ",
      link: "FAQ",
    },
  ];
  return (
    <div>
      <div className="py-md-3 px-4 p-1 mb-4 radius-8 containerStyle mt-1">
        <div className="d-flex flex-wrap">
          {divisionCommunicationTabs?.map((item, i) => (
            <NavLink
              key={i}
              //   state={{ propId: propertyId }}
              to={item.link}
              style={{ textDecoration: "none" }}
              // onClick={() => {
              //   updateBreadcrumbs([`${item.tab}`]);
              //   handelTag(item.tab);
              // }}
              className=" me-2"
            >
              {item?.tab}
            </NavLink>
          ))}
        </div>
        <div className="whiteContainerStyle mt-2">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DivisionCommunication;
