import React, { useMemo, useCallback, useState, useRef, useEffect } from "react";
import PNGWidget from "../../../Components/DownloadWidget/PNGWidget";
import JPGWidget from "../../../Components/DownloadWidget/JPGWidget";
import SVGWidget from "../../../Components/DownloadWidget/SVGWidget";
import { Col, Row } from "react-bootstrap";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import Menus from "../../../Components/Menus/Menus";
import DropdownModalMui from "../../../Components/DropdownModal/DropdownModalMui";
import CheckBox from "../../../Components/Checkbox/Checkbox";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { Line } from "react-chartjs-2";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import { useAllPropertyList } from "../../../hooks/queries/usePropertyQueries";
import { useControlCenter } from "../../../hooks/stores/useControlCenterStore";
import { H2, H3 } from "../../../styles/Common.style";

const ProgressOvertimeByStatus = () => {
  const {

    allProperties,
    selectedProperties,

  } = useControlCenter((state) => state);
  const fileName = "progress-over-time-status";
  const myref = useRef();
  const [refObj] = useState(myref);

  const PNGFunction = PNGWidget({ refObj, fileName });
  const JPGFunction = JPGWidget({ refObj, fileName });
  const SVGFunction = SVGWidget({ refObj, fileName });

  const StatusDropdownData = [
    {
      label: "Weekly",
      value: "Weekly",
    },
    {
      label: "Monthly",
      value: "Monthly",
    },
    {
      label: "Quarterly",
      value: "Quarterly",
    },
  ];
  const data = {
    labels: [
      "jan 2020",
      "Feb 2020",
      "Mar 2020",
      "Apr 2020",
      "May 2020",
      "Jun 2020",
      "Jul 2020",
      "Aug 2020",
      "Sep 2020",
      "Oct 2020",
      "Nov 2020",
      "Dec 2020",
      "Jan 2021",
      "Feb 2021",
      "Mar 2021",
    ],
    //  myData.map((x) =>
    //   value == 1
    //     ? x.YearMonth
    //     : new Date(x.YearMonth).toLocaleDateString("en-En", {
    //         year: "numeric",
    //         month: "short",
    //       })
    // ),

    datasets: [
      {
        label: "Available",
        fill: false,
        borderColor: "#77C7A1",
        fontfamily: "Cerebri sans-bold",
        backgroundColor: "#77C7A1",
        tension: 0.4,
        data: [0, 0, 0, 0, 0, 0, 130000, 1150000, 3000000, 1110, 6000000],
        unitStatus: [114, 558, 24, 88],
      },

      {
        label: "Leased",
        fill: false,
        borderColor: "#A2C8D0",
        backgroundColor: "#A2C8D0",

        tension: 0.4,
        data: [
          0, 0, 0, 0, 0, 0, 0, 150000, 170000, 15000000, 1111110, 10000000,
        ],
        unitStatus: [114, 558, 24, 88],
      },
      {
        label: "Reserved",
        fill: false,

        borderColor: "#F6E651",
        backgroundColor: "#F6E651",
        tension: 0.4,
        data: [0, 0, 0, 0, 0, 0, 0, 100000, 150000, 14500000, 32320, 9000000],
        unitStatus: [114, 558, 24, 88],
      },
      {
        label: "Not listed",
        fill: false,
        borderColor: "#888888",
        backgroundColor: "#888888",
        tension: 0.4,
        data: [0, 0, 0, 0, 0, 0, 0, 1730000, 150000, 11000000, 0, 8000000],
        unitStatus: [114, 558, 24, 88],
      },
    ],
  };
  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.style.background = "#fff";
      tooltipEl.style.borderRadius = "3px";
      tooltipEl.style.border = "1px solid #F4F5F5";
      tooltipEl.style.color = "black";
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";
      tooltipEl.style.zIndex = "10";

      const table = document.createElement("table");
      table.style.margin = "0px 10px";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map(
        (b) => `${b.before}, ${b.lines}, ${b.after}`
      );
      const tableHead = document.createElement("thead");

      titleLines.forEach((title) => {
        const tr = document.createElement("tr");
        tr.style.borderWidth = 0;

        const th = document.createElement("th");
        th.style.borderWidth = 0;
        const text = document.createTextNode(title);

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement("tbody");
      bodyLines.forEach((body, i) => {
        const colors = tooltip.labelColors[i];
        //  console.log(body.split(","),i,"tablebody")
        const span = document.createElement("span");
        // span.style.background = tr;
        // span.style.borderColor = colors.borderColor;
        span.style.borderWidth = "2px";
        span.style.marginRight = "10px";
        span.style.height = "10px";
        span.style.width = "10px";
        span.style.fontSize = "13px";
        span.style.display = "block";

        const tr = document.createElement("tr");
        tr.style.backgroundColor = "inherit";
        tr.style.borderWidth = 0;
        tr.style.fontSize = "13px";

        const tr2 = document.createElement("tr");
        tr2.style.backgroundColor = "inherit";
        tr2.style.borderWidth = 0;
        tr2.style.fontSize = "13px";
        tr2.style.fontFamily = " Cerebri sans-light";

        const tr3 = document.createElement("tr");
        tr3.style.backgroundColor = "inherit";
        tr3.style.borderWidth = 0;
        tr3.style.fontSize = "13px";
        tr3.style.color = "#888888";
        tr3.style.fontFamily = " Cerebri sans-light";

        const tr4 = document.createElement("tr");
        tr2.style.fontSize = "13px";

        const td = document.createElement("td");
        const br1 = document.createElement("br");
        const br2 = document.createElement("br");

        const allBody = body.split(",");
        const text1 = document.createTextNode(allBody[0]);
        // text1.style.fontSize="13px";
        const text2 = document.createTextNode(allBody[1]);
        const text3 = document.createTextNode(allBody[2]);
        const text4 = document.createTextNode("");

        td.appendChild(span);
        tr.appendChild(text1);
        // tr.appendChild(br1);
        tr2.appendChild(text2);
        // tr.appendChild(br2);
        tr3.appendChild(text3);
        tr4.appendChild(text4);

        tableBody.appendChild(td);
        tableBody.appendChild(tr);
        tableBody.appendChild(tr2);
        tableBody.appendChild(tr3);
        tableBody.appendChild(tr4);
      });

      const tableRoot = tooltipEl.querySelector("table");

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };
  const options = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        position: "average",
        external: externalTooltipHandler,
        callbacks: {
          title: function (tooltipItem, index) {
            let title = "";
            // title.push(tooltipItem.dataset.label);
            // console.log(tooltipItem[0].dataset.label,tooltipItem[1].dataset.label,tooltipItem[2].dataset.label,tooltipItem[3].dataset.label, "title")
            return title;
          },
          beforeLabel: function (tooltipItem, index) {
            let beforlabel = [];
            beforlabel.push(tooltipItem.dataset.label);
            let myhome = beforlabel[0];
            // console.log(beforlabel[0]);
            // tooltipItem[0].dataset.label
            // console.log(tooltipItem, "title");
            // console.log(tooltipItem.dataset.label, "title");
            return myhome;
          },
          label: function (context) {
            let lable = "";
            if (context.parsed.y !== null) {
              lable +=
                new Intl.NumberFormat("en-DK").format(context.parsed.y) +
                " DKK";
            }
            return lable;
          },
          afterLabel: function (tooltipItem, context) {
            let unit = tooltipItem.dataset.unitStatus[tooltipItem.dataIndex];
            let unitTitle = tooltipItem.dataset.label;
            //  console.log(tooltipItem.dataIndex);
            //  console.log(tooltipItem);
            // console.log(tooltipItem.dataset.unitStatus[tooltipItem.dataIndex]);
            return unit + " units " + unitTitle;
          },
        },
        // backgroundColor: "#FFFFFF",
        // borderDash: [2, 2],
        // titleColor: "#000000",
        // bodyColor: "#000000",
        // padding: 20,
        // cornerRadius: 8,
        // borderColor: "#F4F5F5",
        // borderWidth: 1,
        // bodySpacing:5,
        // displayColors:false,
        // titleSpacing:20,
      },
      legend: {
        display: true,
        position: "bottom",
        align: "center",

        labels: {
          boxWidth: 8,
          boxHeight: 8,
          padding: 20,
          color: "#00000",
          font: { family: "Cerebri sans-medium", size: "12px" },
        },
      },
    },
    scales: {
      x: {
        grid: {
          color: "rgba(0,0,0,0.0)",
        },
        ticks: {
          fontSize: 18,
          padding: 0,
          color: "#888888",
          font: {
            family: "Cerebri sans-light",
          },
        },
      },
      y: {
        grid: {
          color: "rgba(0,0,0,0.0375)",
          fontColor: "#fff",
        },
        ticks: {
          color: "#888888",
          font: {
            family: "Cerebri sans-light",
          },
        },
      },
    },
  };

  const [property, setproperty] = useState([
    {
      id: "1",
      propertyName: "Egedal I",
    },
    {
      id: "2",
      propertyName: "Egedal II",
    },
    {
      id: "3",
      propertyName: "Upcycle Studios",
    },
    {
      id: "4",
      propertyName: "Over Friheden",
    },
  ]);
  const [checked, setChecked] = useState([]);
  const handleCheckAllChange = (e) => {
    if (e.target.checked) {
      const allCountries = property.map((c) => c.property_id);
      setChecked(allCountries);
    } else {
      setChecked([]);
    }
  };
  const handleCountryChange = (e, c) => {
    if (e.target.checked) {
      setChecked([...checked, c.property_id]);
    } else {
      setChecked(checked.filter((item) => item !== c.property_id));
    }
  };

  const {
    data: allPropertiesData,
    // isLoading,
    // isError,
    refetch: allPropertyDataRefetch,
  } = useAllPropertyList(0);


  return (
    <div className="card-body p-4" ref={myref}>
      <Row>
        <Col className="d-flex align-items-center" sm={6}>
          {" "}
          <div className="d-flex align-items-center">
            <H3 className=" me-2">
              Progress over time divided by status
            </H3>{" "}
            <CustomToolTip
              size="sm"
              text="Progress over time divided by status"
            />
          </div>
        </Col>
        <Col sm={6} className="">
          <Row>
            {data.length == 0 ? <Col sm={6}>

              <ReactSelect
                placeholder={`All properties (${checked.length})`}
              // options={StatusDropdownData?.map((option) => ({
              //   value: option?.value,
              //   label: option?.label,
              // }))}
              // defaultValue={{ value: "Weekly", label: "Weekly" }}
              // searchable={false}
              // clearable={false}
              // disabled={disabled}
              // height={26}
              />


            </Col> :
              <Col sm={6}>
                <DropdownModalMui
                  text={`All properties (${checked.length})`}
                  component={
                    <div className="py-2 ps-2 pe-5" style={{ height: "212px" }}>
                      <div className="d-flex align-items-center light-font-text ">
                        <CheckBox
                          onChange={handleCheckAllChange}
                          id={"handleCheckAllChange"}
                          checked={checked.length === property.length}
                        />{" "}
                        All properties
                      </div>
                      <div className="ms-4">
                        {allPropertiesData?.data
                          ?.filter(item => allProperties?.includes(item?.property_id)) // Filter by property_id
                          ?.map((item, i) => (
                            <div className="d-flex align-items-center light-font-text" key={i}>
                              {/* Assuming you have a CheckBox component */}
                              <CheckBox
                                id={item?.property_id}  // Use property_id instead of id
                                checked={checked?.includes(item?.property_id)}
                                onChange={(e) => handleCountryChange(e, item)}
                              />{" "}
                              {item.propertyName}
                            </div>
                          ))}

                      </div>
                    </div>
                  }
                />
              </Col>}

            <Col className="px-lg-0 ps-sm-0 pe-sm-2 pe-lg-0" sm={4}>
              <ReactSelect
                placeholder="Weekly"
              // options={StatusDropdownData?.map((option) => ({
              //   value: option?.value,
              //   label: option?.label,
              // }))}
              // defaultValue={{ value: "Weekly", label: "Weekly" }}
              // searchable={false}
              // clearable={false}
              // disabled={disabled}
              // height={26}
              />
            </Col>
            <Col className="ps-0 d-flex justify-content-end" sm={2}>
              <Menus
                itemheight={40}
                itemwidth={40}
                itemcontainerheight={200}
                itemcontainerwidth={200}
                component={
                  <div className="fs-14">
                    <div className="addpropContent px-3 py-2 ">
                      <span className="ms-1" onClick={() => PNGFunction()}>
                        Download widget as PNG
                      </span>
                    </div>
                    <hr className="addpropertyHr m-0"></hr>
                    <div
                      onClick={() => JPGFunction()}
                      className="addpropContent px-3 pt-2 pb-2 "
                    >
                      <span className="ms-1">Download widget as JPG</span>
                    </div>
                    <hr className="addpropertyHr m-0"></hr>
                    <div
                      onClick={() => SVGFunction()}
                      className="addpropContent px-3 pt-2 pb-2 "
                    >
                      <span className="ms-1">Download widget as SVG</span>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {data.length == 0 ? (
        <Row className="mt-5">
          <Col>
            <div
              style={{
                height: "378px",
                zIndex: "1",
              }}
            >
              <Line data={data} options={options} />
            </div>{" "}
          </Col>
        </Row>
      ) : (
        <NoDataScreen height={"377px"} imgHeight={"150px"} />
      )}
    </div>
  );
};

export default ProgressOvertimeByStatus;
