import moment from "moment/moment";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { H5 } from "../../../styles/Common.style";

const CustomerDetailTooltip = ({ data }) => {
  const customer = data?.customer_details?.[0];
  return (
    <div>
      {" "}
      <div className="d-flex justify-content-between border-bottom p-3">
        <div className="form-label-title">Customer details</div>
        <div className="light-font-text fs-12">
          Last status change:{" "}
          {data?.registration_date == ""
            ? "-"
            : moment(data?.registration_date).format("DD MMM, YYYY [at] hh:mm")}
        </div>
      </div>
      {/* body section */}
      <div className="px-3 light-font-text fs-12 ">
        <Row>
          <Col sm={3} className=" pt-3">
            <H5 className="fs-12">Name</H5>
            <div className="pb-3">
              {customer?.firstname + " " + customer?.lastname == ""
                ? "-"
                : customer?.firstname + " " + customer?.lastname}
            </div>
            <H5 className="fs-12">Phone</H5>
            <div className="pb-3">
              {customer?.phone_calling_code_id + " " + customer?.mobilenumber ==
              ""
                ? "-"
                : customer?.phone_calling_code_id +
                  " " +
                  customer?.mobilenumber}
            </div>
            <H5 className="fs-12">Current address</H5>
            <div className="pb-3">
              {customer?.street_name + " " + customer?.house_number == ""
                ? "-"
                : customer?.street_name + " " + customer?.house_number}
            </div>
          </Col>
          <Col sm={5} className=" pt-3">
            <H5 className="fs-12">Age</H5>
            <div className="pb-3">
              {customer?.age == "" ? "-" : customer?.age}
            </div>
            <H5 className="fs-12">Email</H5>
            <div className="pb-3">
              {customer?.email == "" ? "-" : customer?.email}
            </div>
            <div className="d-flex gap-4">
              <div>
                {" "}
                <H5 className="fs-12">Zip code</H5>
                <div className="pb-3">
                  {customer?.zipcode_id == "" ? "-" : customer?.zipcode_id}
                </div>
              </div>
              <div>
                {" "}
                <H5 className="fs-12">City</H5>
                <div className="pb-3">
                  {customer?.city == "" ? "-" : customer?.city}
                </div>
              </div>
              <div>
                {" "}
                <H5 className="fs-12">Country</H5>
                <div className="pb-3">
                  {customer?.country == "" ? "-" : customer?.country}
                </div>
              </div>
            </div>
          </Col>
          <Col
            sm={4}
            className="d-flex flex-column justify-content-between pt-3"
            style={{
              borderLeft: "1px solid #D1D1D1",
            }}
          >
            <div>
              <H5 className="fs-12">Registration date</H5>
              <div className="pb-3">
                {customer?.registration_date == ""
                  ? "-"
                  : moment(customer?.registration_date).format(
                      "DD MMM, YYYY [at] hh:mm"
                    )}
              </div>
            </div>
            <div>
              <H5 className="fs-12">GDPR confirmation</H5>
              <div className="pb-3">
                {customer?.gdpr_confirmation == ""
                  ? "-"
                  : customer?.gdpr_confirmation}
              </div>
            </div>
            <div>
              <H5 className="fs-12">Preferred languages</H5>
              <div className="pb-3">
                {customer?.preferred_languages == ""
                  ? "-"
                  : customer?.preferred_languages}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CustomerDetailTooltip;
