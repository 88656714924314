import { FontAwesomeIcon }                     from "@fortawesome/react-fontawesome";
import { useState }                            from "react";
import { Outlet, useLocation }                 from "react-router-dom";
import { GetPropertyWithOverviewByPropertyId } from "../../services/Units";
import { useQuery }                            from "react-query";
// import "../properties/units/units.css";
import "./eido.css";
import { GreenSpan }                           from "../../styles/Common.style";
import { useUnitType }                         from "../../hooks/stores/useUnitStore";
import { NavLink }                             from "../../Components/Tabs/ReactTabs.style";
import { usePropertyOverview } from "../../hooks/stores/usePropertyStore";

const Eido = () => {
	//---------------states-----------------//
	const [selectedTab, setSelectedTab] = useState("");
	let location                        = useLocation();
	const { state }                     = location;
	let propertyId                      = state?.propId;
	let TabId                           = state?.Tab;
	const { typeOfUnit }                = useUnitType((state) => state);

	// Tabs Index Value
	const eidoTabs = [
		{
			Id: "1",
			tab: "Color",
			link: "color",
		},
		{
			Id: "2",
			tab: "Text",
			link: "text",
		},
		{
			Id: "3",
			tab: "Media",
			link: "Media",
		},
		{
			Id: "4",
			tab: "Documents",
			link: "documents",
		},
		{
			Id: "4",
			tab: "Filter",
			link: "filter",
		},
		{
			Id: "5",
			tab: "Button",
			link: "button",
		},
		{
			Id: "6",
			tab: "Pricelist",
			link: "pricelist",
		},
		{
			Id: "7",
			tab: "Settings",
			link: "settings",
		},
	];

	// Eido tabs/navlink handling
	const handleHeading = (tab) => {
		setSelectedTab(tab);
	};

	const propOverview = usePropertyOverview((state) => state.propOverview);
	// Api for Property overview in Unit header
	// const { data: GetPropertyWithOverviewByPropertyIdData } = useQuery(
	// 	["PropertyWithOverviewByPropertyId", propertyId, typeOfUnit],
	// 	() => GetPropertyWithOverviewByPropertyId(propertyId, typeOfUnit),
	// 	{ enabled: !!propertyId }
	// );
		// console.log(GetPropertyWithOverviewByPropertyIdData, 'GetPropertyWithOverviewByPropertyIdData')
	return (
		<>
			<div>
				<div className="pt-3">
					<div style={{ fontWeight: "600", fontSize: "24px" }}>
						{TabId ? TabId : selectedTab}
					</div>
					<div
						style={{
							fontFamily: " Cerebri sans-light",
							fontSize: "14px",
							cursor: "pointer",
						}}>
						<span className="me-3">Properties</span>
						<span className="me-3">
							<FontAwesomeIcon
								size="sm"
								icon="fa-regular fa-angle-right"
								style={{ color: "#000000" }}
							/>{" "}
						</span>
						<span
							className="me-3">
							{propOverview?.[0]?.propertyName
								? propOverview?.[0]?.propertyName
								: "Property name"}
						</span>

						<span className="me-3">
							<FontAwesomeIcon
								size="sm"
								icon="fa-regular fa-angle-right"
								style={{ color: "#000000" }}
							/>{" "}
						</span>
						<GreenSpan className="me-3">
							{TabId ? TabId : selectedTab}
						</GreenSpan>
					</div>
					<div className="d-flex pt-3 pb-2">
						{eidoTabs?.map((item, i) => (
							<NavLink
								key={i}
								state={{ propId: propertyId, propertyInfo: propOverview }}
								to={item.link}
								style={{ textDecoration: "none" }}
								onClick={() => handleHeading(item.tab)}
								className=" me-2">
								{item.tab}
							</NavLink>
						))}
					</div>
				</div>
			</div>
			<Outlet />
		</>
	);
};

export default Eido;
