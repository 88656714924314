import React from "react";
import { Image } from "react-bootstrap";
import EstateloginLogo from "../../assets/images/et-logo-new-user.png";
import StandardSigning from "../../assets/images/StandardSignin.jpg";
import ETButton from "../../Components/Buttons/ETButton";
import { H2 } from "../../styles/Common.style";
import { useLocation } from "react-router-dom";

const UserSignupLinkExpired = () => {
  const { state } = useLocation();
  let email = state?.ownerEmail;
  const handleMailTo = () => {
    // Open the default email client
    window.location.href = `mailto:${email}`;
  };

  return (
    <div
      className=" d-flex flex-column justify-content-center align-items-center "
      style={{
        position: "relative",
        background: "#2B3C49",
        minHeight: "100vh",
        height: "100% !important",
      }}
    >
      <div className="py-4">
        <Image
          width={"160px"}
          // height={"70px"}
          src={EstateloginLogo}
          alt="Brand-logo"
          className="d-block m-auto "
        />

        <div className="loginForm mt-4 mb-3 mx-4 d-flex">
          <div className="ps-2 pt-3 w-50  me-1">
            <div>
              {" "}
              <div className="whiteContainerStyle p-3">
                <div className="pb-40">
                  <H2>Oh no! Your link is expired</H2>
                </div>

                <div className="pb-40">
                  <div className="light-font-text">
                    The link you are trying to access is no longer active and
                    you need to request your system administrator or
                    organisation owner to send you a new link to create your
                    user.
                  </div>
                </div>

                <ETButton
                  variant={"primary"}
                  buttonText="CONTACT SYSTEM OWNER"
                  onClick={handleMailTo}
                />
              </div>
            </div>
          </div>
          <div
            className="d-none d-sm-block w-50 pe-3 py-1"
            style={{ overflow: "hidden" }}
          >
            <Image
              fluid
              className="w-100 h-100"
              src={StandardSigning}
              alt="login-image-pattern"
              style={{ objectFit: "cover", borderRadius: "8px" }}
            />
          </div>
        </div>
      </div>
      <div className="light-font-text pb-4" style={{ color: "#FFF" }}>
        This solution is powered by{" "}
        <span className="form-label-title " style={{ color: "#FFF" }}>
          estatetool
        </span>{" "}
        | © 2023 - All rights reserved
      </div>
    </div>
  );
};

export default UserSignupLinkExpired;
