import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  GetMediaByUnitId,
  GetMediaCategory,
  GetMediaFilesByPropertyId,
  GetMediaSortOrder,
  PostAddMediaPAckage,
  PostUploadMedia,
  PutUpdateMediaDetails,
  PutUpdateMediaFileCategory,
  PutUpdateMediaPackageBasicDetails,
  PutUpdateMediaPackageCompleteDetails,
  UpdateMediaPublishDetails,
} from "../../services/Media";

//Get media sort order
export const useGetMediaSortOrder = () => {
  return useQuery("getMediaSortOrder", () => GetMediaSortOrder());
};
//PostAddMediaPAckage

export const usePostAddMediaPAckage = () => {
  return useMutation(PostAddMediaPAckage);
};
// PutUpdateMediaPackageBasicDetails //
export const usePutUpdateMediaPackageBasicDetails = () => {
  return useMutation(PutUpdateMediaPackageBasicDetails);
};

export const useGetMediaCategory = () => {
  return useQuery("getMediaCategory", () => GetMediaCategory());
};

// Post Upload media
export const useUploadMedia = () => {
  return useMutation(PostUploadMedia);
};

// Get Media files by property Id
export const useGetMediaFilesByPropertyId = (propertyId) => {
  return useQuery("qMediaFilesByPropertyId", () =>
    GetMediaFilesByPropertyId(propertyId)
  );
};

//Get Media by Units Id
export const useGetMediaByUnitId = (propertyId, unitId) => {
  return useQuery(
    "useGetMediaByUnitId",
    () => GetMediaByUnitId(propertyId, unitId),
    { enabled: !!propertyId && !!unitId }
  );
};

// to update media category

export const usePutUpdateMediaFileCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(PutUpdateMediaFileCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("PropertyWithOverviewByPropertyId");
    },
  });
};

//Update media details
export const useUpdateMediaDetails = () => {
  return useMutation(PutUpdateMediaDetails);
};

//Update media package complete details
export const useUpdateMediaPackageCompleteDetails = () => {
  return useMutation(PutUpdateMediaPackageCompleteDetails);
};

// Update Media Publish Details

export const useUpdateMediaPublishDetails = () => {
  return useMutation(UpdateMediaPublishDetails);
};
