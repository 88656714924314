import { Endpoint, Site } from "./API/Endpoints";
import { Delete, Get, Post, Put } from "./API/Method";

//Get Leads by division Id
export const GetLeadsByDivisionId = (division_id, dateFilter) => {
  const newRoute = `start-date/${dateFilter?.startDate}/end-date/${dateFilter?.endDate}`
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Customer_Funnel_GetLeadsByDivisionId,
      null,
      division_id + "/lead/" + newRoute
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Distribution All Screening
export const GetDistributionAllScreening = (division_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Customer_Funnel_GetDistributionAllScreening,
      null,
      division_id + "/screening/distribution"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Screening Divided On Agent
export const GetScreeningDividedOnAgent = (division_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.CustomerFunnel_GetScreeningDividedOnAgent,
      null,
      division_id + "/screening/agent"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Get Active Contracts By Division Id
export const GetActiveContractsByDivisionId = (division_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Customer_Funnel_GetActiveContractsByDivisionId,
      null,
      division_id + "/contracts"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Add/Update lead agent
export const PutUpdateLeadAgent = (updateLeadAgent) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Customer_Funnel_UpdateLeadAgent,
      null,
      updateLeadAgent
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//  Delete Lead
export const DeleteLead = (lead_id) => {
  return new Promise((resolve, reject) => {
    Delete(Site.ET, Endpoint.ET.Customer_Funnel_DeleteLead, null, lead_id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Put Update Favourite Lead
export const PutUpdateFavouriteLead = (UpdateFavouriteLead) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Customer_Funnel_UpdateFavouriteLead,
      null,
      UpdateFavouriteLead
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Signing Order Overview
export const GetSigningOrderOverview = (division_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Customer_Funnel_GetSigningOrderOverview,
      null,
      division_id + "/signing/overview"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Get Signing Order Details
export const GetSigningOrderDetails = (division_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Customer_Funnel_GetSigningOrderDetails,
      null,
      division_id + "/signing"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Post Lead
export const PostAddLead = (addLeadData) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Customer_Funnel_AddLead, null, addLeadData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Lead Details
export const GetLeadDetails = (lead_id) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Customer_Funnel_GetLeadDetails, null, lead_id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Post Screening Response
export const PostAddScreeningResponse = (addScreeningResponseData) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Customer_Funnel_AddScreeningResponse,
      null,
      addScreeningResponseData
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Put Screening ActorStatus
export const PutUpdateScreeningActorStatus = (
  updateScreeningActorStatusData
) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Customer_Funnel_UpdateScreeningActorStatus,
      null,
      updateScreeningActorStatusData
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Get Screening Form
export const GetScreeningForm = (customer_id, form_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Customer_Funnel_GetScreeningForm,
      null,
      customer_id + "/screening/form/" + form_id
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get All Customer By Division
export const GetAllCustomerByDivision = (
  division_id,
  startDateEndDateFormate
) => {
  const dateFormateRoute =
    startDateEndDateFormate == "" ||
    startDateEndDateFormate == null ||
    startDateEndDateFormate == undefined
      ? "/all"
      : "/all/" + startDateEndDateFormate;
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Customer_Funnel_GetAllCustomerByDivision,
      null,
      division_id + dateFormateRoute
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//======================================== Follow ups (Contract card)====================================//
// Post Lead follow up
export const PostAddLeadfollowUp = (addLeadfollowUpPayload) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Customer_Funnel_AddLeadfollowup,
      null,
      addLeadfollowUpPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Get Follow up status
export const GetFollowUpStatus = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Customer_Funnel_GetFollowUpStatus, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Follow Up Task Category
export const GetFollowUpTaskCategory = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Customer_Funnel_GetFollowUpTaskCategory, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//======================================== Opportunities ====================================//
//Get Opportunity Overview By Division
export const GetOpportunityOverviewByDivision = (division_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Customer_Funnel_GetOpportunityOverviewByDivision,
      null,
      division_id + "/opportunities/overview"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Opportunity details By Division
export const GetOpportunityDetailsByDivision = (division_id, dateFilter) => {
  const dateFormateRoute =
    dateFilter == "" || dateFilter == null || dateFilter == undefined
      ? "/opportunities"
      : "/opportunities/" + dateFilter;
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Customer_Funnel_GetOpportunityDetailsByDivision,
      null,
      division_id + dateFormateRoute
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
