import React, { useEffect, useState, useRef } from "react";
import ETButton from "../../Components/Buttons/ETButton";
import {
	useGetFacilityAttributesByFacilityId,
	useGetFacilityByPropertyId,
	useGetFacilityPackageByPackageId,
} from "../../hooks/queries/usefacilityQueries";
import { MemoizedAddFacilityPopupTable } from "./AddFacilityPopupTable";
import { useFacility } from "../../hooks/stores/useFacilityStore";
import { GetFacilityPackageByPropertyId } from "../../services/Facility";
import { useRouting } from "../../hooks/stores/useRoutingStore";

function AddNewFacility({
	setAddFaciltyBtn,
	propertyId,
	checkedFacilitiesPackage,
	setCheckedFacilitiesPackage,
	checkedFacilities,
	setCheckedFacilities,
	rowSelection,
	setRowSelection,
	setFacilityTableData,
	outerRowSelection,
	setOuterRowSelection,
	editSelectedFacilities,
	facilityTableData,
}) {
	const [preSelectedRows, setPreSelectedRows] = useState(rowSelection);
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const {
		editflag,

		packageId,
	} = useRouting((state) => state);
	const hasMounted = useRef(false);

	const {
		data: GetFacilityByPropertyIdData,
		refetch: GetFacilityByPropertyIdRefetch,
	} = useGetFacilityByPropertyId(propertyId);

	const {
		data: GetFacilityPackageByPackageIdData,
		refetch: GetFacilityPackageByPackageIdDataRefetch,
	} = useGetFacilityPackageByPackageId(propertyId, packageId, editflag);

	const { outerFacilityTable, setOuterFacilityTable } = useFacility(
		(state) => state
	);
	// useEffect(() => {
	// 	if (hasMounted?.current) return;
	// 	
	// 	setFacilityTableData(outerFacilityTable);
	// 	hasMounted.current = true; // Set the ref value to true to indicate that the component has mounted
	// }, []);

	const handleCancelFacility = () => {
		setAddFaciltyBtn(false);
		setRowSelection(preSelectedRows);
	};
	const handleAddFacility = async () => {
		if (editflag) {
			// on add, merging already selected facilities and currently selected ones
			// let selectedFac = {  ...rowSelection };

			// setOuterFacilityTable(facilityTableData);
			let rowSelectionArray = Object.keys(rowSelection);

			rowSelectionArray = rowSelectionArray?.map((row) => parseInt(row));
			const outerFacilityIds = facilityTableData?.map(
				(fac) => fac?.facility_id
			);
			let difference = rowSelectionArray?.filter(
				(x) => !outerFacilityIds?.includes(x)
			);
			let newFacilities = GetFacilityByPropertyIdData?.data?.filter((fac) =>
				difference?.includes(fac?.facility_id)
			);
			// setOuterRowSelection(selectedFac);
			// Deep copy the facilities from outerFacilityTable
			const deepCopiedOuterFacilities = outerFacilityTable?.map((facility) => ({
				...facility,
			}));

			newFacilities?.forEach((facility) => (facility.setting = 0));
			let clubbed = [...deepCopiedOuterFacilities, ...newFacilities];

			setFacilityTableData(clubbed);
			setOuterFacilityTable(clubbed);
			setAddFaciltyBtn(false);
		} else {
			// on add, merging already selected facilities and currently selected ones

			let rowSelectionArray = Object.keys(rowSelection);

			rowSelectionArray = rowSelectionArray?.map((row) => parseInt(row));
			// setOuterRowSelection(selectedFac);
			let newFacilities = GetFacilityByPropertyIdData?.data?.filter((fac) =>
				rowSelectionArray?.includes(fac?.facility_id)
			);
			newFacilities?.forEach((facility) => (facility.setting = 0));
			// clubbed?.forEach((facility) => (facility.setting = 0));

			setFacilityTableData(newFacilities);
			setOuterFacilityTable(newFacilities);

			setAddFaciltyBtn(false);
		}
	};

	useEffect(() => {
		const outerFacilityIds = outerFacilityTable?.map((fac) => fac?.facility_id);

		if (Object.keys(rowSelection)?.length === 0) {
			setButtonDisabled(true);
		} else if (outerFacilityIds == Object.keys(rowSelection)) {
			setButtonDisabled(true);
		} else setButtonDisabled(false);
	}, [rowSelection]);

	return (
		<div>
			<div className="px-2">
				<MemoizedAddFacilityPopupTable
					propertyId={propertyId}
					rowSelection={rowSelection}
					setRowSelection={setRowSelection}
					checkedFacilities={checkedFacilities}
					setCheckedFacilities={setCheckedFacilities}
					checkedFacilitiesPackage={checkedFacilitiesPackage}
					setCheckedFacilitiesPackage={setCheckedFacilitiesPackage}
					setFacilityTableData={setFacilityTableData}
					facilityTableData={facilityTableData}
					flag={"packageLevel"}
					GetFacilityByPropertyIdData={GetFacilityByPropertyIdData}
					GetFacilityByPropertyIdRefetch={GetFacilityByPropertyIdRefetch}
				/>
			</div>
			<div
				className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
				style={{
					backgroundColor: "#F9FAFA",
					borderRadius: " 0px 0px 8px 8px",
					borderTop: "1px solid #F0F0F0",
				}}
			>
				{" "}
				<ETButton
					variant={"white"}
					buttonText="CANCEL"
					onClick={handleCancelFacility}
				// onClick={() => setAddFaciltyBtn(false)}
				/>
				<ETButton
					variant={"primary"}
					disabled={buttonDisabled}
					buttonText="ADD"
					onClick={handleAddFacility}
				/>
			</div>
		</div>
	);
}
export const MemoizedAddNewFacility = React.memo(AddNewFacility);
// export default AddNewFacility;
