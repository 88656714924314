import { Post } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

//Post Check if user enter details exist/right or not
export const PostCheckExists = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Common_CheckExists, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error?.response?.data?.message });
      });
  });
};
