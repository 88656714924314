import React, { useEffect, useRef, useState } from "react";
import {
  GreenSpan,
  H1,
  H2,
  H3,
  H4,
  H5,
} from "../../../../../styles/Common.style";
import CustomToolTip from "../../../../../Components/Tooltip/CustomTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Col, Form, Placeholder, Row } from "react-bootstrap";
import ETButton from "../../../../../Components/Buttons/ETButton";
import ReactSelect from "../../../../../Components/DropDown/ReactSelect";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import SuperEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import SuperEditor from "@ckeditor/ckeditor5-build-classic";
import { Menu } from "@mui/material";
import FilterComponent from "../../../../../reactdataTableComponent/FilterComponent";
import PublishTemplate from "./emailTemplates/PublishTemplate";
import EmailPreview from "./emailTemplates/EmailPreview";
import InputField from "../../../../../Components/Textfield/InputField";
import { Controller, useForm } from "react-hook-form";
import {
  useGetEmailTemplateBranding,
  useGetEmailTemplateByTemplateId,
  useGetEmailTemplateLanguageByOrganization,
  useGetEmailTemplateMergeField,
  usePostAddNewEmailTemplate,
  usePutUpdatEmailTemplate,
} from "../../../../../hooks/queries/useEmailTemplates";
import { useOrganizationId } from "../../../../../hooks/stores/useOrganizationStore";
import { async } from "q";
import { toast } from "react-toastify";

const CreateNewEmailTemplate = () => {
  //---------------React hook form-----------//
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    clearErrors,
    reset,
    resetField,
    formState: { errors },
  } = useForm({
    defaultValues: {
      category: "",
      sub_category: "",
      type: "",
      title: "",
      description: "",
      branding: "",
      signature: "",
    },
  });
  //---------------Zustand---------------//
  const { organizationId } = useOrganizationId((state) => state);

  //---------------States---------------//
  const [publishTemplateShow, setPublishTemplateShow] = useState(false);
  const [cancelShow, setCancelShow] = useState(false);
  const [previewShow, setPreviewShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [emailTemplateBody, setEmailTemplateBody] = useState({});
  const [searchText, setSearchText] = useState("");
  const [emailSubject, setEmailSubject] = useState({});
  const [previewHTML, setPreviewHTML] = useState("");
  const [languages, setLanguages] = useState([]);

  //------------Constants-----------//
  const navigate = useNavigate();
  const { state } = useLocation();
  const isViewTemplate = state?.flag == "View" ? true : false;
  const editorRef = useRef();

  //------------React queries----------//

  //Get email template by template id
  const {
    data: GetEmailTemplateByTemplateIdData,
    refetch: GetEmailTemplateByTemplateIdDataRefetch,
  } = useGetEmailTemplateByTemplateId(state?.email_template_id);

  //Get email template branding
  const { data: GetEmailTemplateBrandingData } = useGetEmailTemplateBranding(
    organizationId,
    watch("language.value")
  );

  //Get email template language by organization
  const { data: GetLanguageByOrganizationData } =
    useGetEmailTemplateLanguageByOrganization(organizationId);

  //Get email template merge field
  const { data: GetEmailTemplateMergeFieldData } =
    useGetEmailTemplateMergeField(state?.email_template_subcategory_id);
  //Post - Add new email template
  const {
    mutateAsync: PostAddNewEmailTemplate,
    isLoading: PostAddNewEmailTemplateIsLoading,
  } = usePostAddNewEmailTemplate();

  //Put - Update email template
  const {
    mutateAsync: PutUpdateEmailTemplate,
    isLoading: PutUpdateEmailTemplateIsLoading,
  } = usePutUpdatEmailTemplate();

  useEffect(() => {
    const emailTemplateData = GetEmailTemplateByTemplateIdData?.data?.[0];
    const allTemplateLang = emailTemplateData?.template_details?.filter(t => t.language_id)?.map( l => l?.language_id);
    const emailSubject = {};
    const emailTemplateBody = {};
    setLanguages(allTemplateLang);

    if(allTemplateLang?.length) {
      allTemplateLang?.forEach((lang) => {
        const templage = emailTemplateData?.template_details.filter((item) => item?.language_id === lang);
        emailSubject[lang] = templage?.[0]?.subject;
        emailTemplateBody[lang] = templage?.[0]?.body;
      })
    }
    
    setValue("category", emailTemplateData?.category);
    setValue("sub_category", emailTemplateData?.subcategory);
    setValue("type", emailTemplateData?.type);
    setValue("title", emailTemplateData?.template_details?.[0]?.title);
    setValue(
      "description",
      emailTemplateData?.template_details?.[0]?.internal_description
    );
    setValue("branding", {
      value: emailTemplateData?.template_details?.[0]?.branding,
      label: emailTemplateData?.template_details?.[0]?.branding_name,
    });
    setValue("language", {
      value: emailTemplateData?.template_details?.[0]?.language_id,
      label: emailTemplateData?.template_details?.[0]?.language,
    });

    // setEmailTemplateBody(emailTemplateData?.template_details?.[0]?.body);
    // setEmailSubject(emailTemplateData?.template_details?.[0]?.subject);
    setEmailTemplateBody(emailTemplateBody);
    setEmailSubject(emailSubject);
    return () => {};
  }, [GetEmailTemplateByTemplateIdData]);

  const handleAddPropertyDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePropertyDropDown = () => {
    setAnchorEl(null);
  };
  const editorConfiguration = {
    placeholder: "Dear Customer", // Set the placeholder text
    toolbar: {
      items: [
        "heading",
        "placeholder",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "indent",
        "outdent",
        "blockQuote",
        "undo",
        "redo",
        "image",
        "codeBlock",
        // "imageUpload",
        // "insertInlineWidget",
      ],
    },
    language: "en",
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
      ],
    },
    // Add more options and configurations here
  };

  // const createCustomImageUploadAdapter = (loader) => {
  //   return {
  //     upload() {
  //       return loader.file.then((file) => {
  //         // Implement your image upload logic here and return a Promise with the image URL.
  //         // You can use fetch or any library to make the actual upload request.

  //         return new Promise((resolve, reject) => {
  //           // Replace this with your actual upload logic (e.g., using a server endpoint).
  //           setTimeout(() => {
  //             const uploadedUrl = "https://example.com/uploaded-image-url.jpg"; // Replace with the actual URL of the uploaded image
  //             resolve({ default: uploadedUrl });
  //           }, 2000); // Simulate a 2-second upload delay
  //         });
  //       });
  //     },

  //     abort() {
  //       // Implement the logic to abort the upload if necessary.
  //     },
  //   };
  // };

  const displayItem = (selected) => {
    return {
      value: selected?.Id,
      label: (
        <div>
          <div className="fs-14 fw-300">
            {
              GetLanguageByOrganizationData?.data?.filter(
                (item, i) => item.Id === selected?.value
              )?.[0]?.Text
            }
          </div>
        </div>
      ),
    };
  };

  //------------------------------ handle publish template ------------------------------------------//
  const handlePublishTemplate = async (data) => {
    if (state?.flag == "Add") {
      const emailTemplateData = GetEmailTemplateByTemplateIdData?.data?.[0];
      const addTemplatePayload = {
        organisation_id: organizationId,
        division_id: 0, //Will be 0
        company_id: 0, //Will be 0
        property_id: 0, //Will be 0
        is_draft: 0, // Will be 0 when publishing a template
        email_template_category_id:
          emailTemplateData?.email_template_category_id,
        email_template_subcategory_id:
          emailTemplateData?.email_template_subcategory_id,
        email_template_type_id: emailTemplateData?.email_template_type_id,
        parent_email_template_id: emailTemplateData?.email_template_id,
        email_template_id: emailTemplateData?.email_template_id,
        email_template_branding_id: data?.branding?.value,
        template_details: languages?.map((item) => ({
          language_id: item,
          title: data?.title,
          internal_description: data?.description,
          subject: emailSubject?.[item] || "",
          body: emailTemplateBody?.[item] || "",
        })),
      };
      
      try {
        const response = await PostAddNewEmailTemplate(addTemplatePayload);
        if (response?.data?.bReturn) {
          setPublishTemplateShow(true);
          GetEmailTemplateByTemplateIdDataRefetch();
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    } else if (state?.flag == "Edit") {
      const updateEmailTemplatePayload = {
        email_template_id: state?.email_template_id,
        email_template_branding_id: data?.branding?.value,
        template_details: languages.map((item) => ({
          language_id: item,
          title: data?.title,
          internal_description: data?.description,
          subject: emailSubject?.[item] || "",
          body: emailTemplateBody?.[item] || "",
        })),
      };
      try {
        const response = await PutUpdateEmailTemplate(
          updateEmailTemplatePayload
        );
        if (response?.data?.bReturn) {
          toast.success(response?.data?.message);
          GetEmailTemplateByTemplateIdDataRefetch();
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  // ---------------------------- handle template save as draft ------------------------//
  const handleSaveTemplateAsDraft = async () => {
    const emailTemplateData = GetEmailTemplateByTemplateIdData?.data?.[0];
    const addTemplatePayload = {
      organisation_id: organizationId,
      division_id: 0, //Will be 0
      company_id: 0, //Will be 0
      property_id: 0, //Will be 0
      is_draft: 1,
      email_template_category_id: emailTemplateData?.email_template_category_id,
      email_template_subcategory_id:
        emailTemplateData?.email_template_subcategory_id,
      email_template_type_id: emailTemplateData?.email_template_type_id,
      parent_email_template_id: emailTemplateData?.email_template_id,
      email_template_id: emailTemplateData?.email_template_id,
      email_template_branding_id: getValues("branding.value") || "0",
      template_details: languages?.map((item) => ({
        language_id: item,
        title: getValues("title") || "",
        internal_description: getValues("description") || "",
        subject: emailSubject?.[item] || "",
        body: emailTemplateBody?.[item] || "",
      })),
    };
    try {
      const response = await PostAddNewEmailTemplate(addTemplatePayload);
      if (response?.data?.bReturn) {
        toast.success(response?.data?.message);
        navigate("/organization/settings/marketing");
        setCancelShow(false);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  // --------------------------- handle Click Recently Merge Fields Button --------------------- //
  // console.log("editor editorRef", editorRef);
  // console.log(emailTemplateBody, "emailTemplateBody");
  const handleClickMergeFieldsButton = (item) => {
    if (editorRef.current) {
      const newText = `${item}`;
      const editor = editorRef.current;
      // editor.conversion.for()
      editor.execute("insertText", {
        text: newText,
      });
    }
    setAnchorEl(null);
  };

  // --------------------------- handle Click to add merge field to subject --------------------- //
  const handleInsertText = (field_name) => {
    setEmailSubject(emailSubject?.[watch("language.value")] + field_name);
  };

  // --------------------------- handle preview email template ----------------------//

  const handlePreviewTemplate = () => {
    if (watch("branding.value")) {
      const displayHTML = GetEmailTemplateBrandingData?.data?.filter(
        (data) => data?.Id?.toString() === watch("branding.value")?.toString()
      )?.[0]?.DataValue1;

      const emailContent = `<div style=\"border-bottom: 2px solid #d1d1d1; margin: 24px 0px; padding-bottom: 24px; font-weight: bold\">${emailSubject?.[watch("language.value")]}</div>${emailTemplateBody?.[watch("language.value")]}`;

      const replacedHtmlString = displayHTML?.replace(
        "{{body_details}}",
        emailContent
      );

      setPreviewHTML(replacedHtmlString);
      setPreviewShow(true);
    } else {
      toast.warning("Please select the branding");
    }
  };

  const handleCkEditorChange = (data) => {
    setEmailTemplateBody({
      ...emailTemplateBody,
      [watch("language.value")] : data
    });
  }

  if(!Object.keys(emailTemplateBody ?? {}).length) return null

  return (
    <div>
      <div className="px-1">
        <div className="px-2  py-3">
          {/* top bar header and breadcrumbs */}
          <Placeholder animation="glow">
            <H1 className="fs-32 pt-3">
              {state?.flag == "View"
                ? "View"
                : state?.flag == "Edit"
                ? "Edit"
                : "Create new"}{" "}
              template
            </H1>
            <div
              style={{
                fontFamily: " Cerebri sans-light",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/organization/details"
              >
                <span className="me-3">Organisation</span>
              </Link>
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />{" "}
              </span>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/organization/settings/users"
              >
                <span className="me-3">Settings</span>
              </Link>
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />{" "}
              </span>
              <Link
                to="/organization/settings/marketing"
                style={{ textDecoration: "none", color: "black" }}
              >
                <span className="me-3">Marketing</span>
              </Link>
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />{" "}
              </span>
              {/* <Link style={{ textDecoration: "none", color: "black" }}> */}
              <span className="me-3">Email templates</span>
              {/* </Link> */}
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />{" "}
              </span>
              <GreenSpan>
                {state?.flag == "View"
                  ? "View"
                  : state?.flag == "Edit"
                  ? "Edit"
                  : "Create new"}{" "}
                template
              </GreenSpan>
            </div>
          </Placeholder>

          <div
            className="mt-4 p-4"
            style={{ backgroundColor: "#E4E7E8", borderRadius: "10px" }}
          >
            <div className="bg-white radius-8">
              <div className="px-3 pb-3 border-bottom">
                <div className="px-2 pt-3 d-flex">
                  <div className="d-flex align-items-center gap-2">
                    <H2>
                      {state?.flag == "View"
                        ? "View"
                        : state?.flag == "Edit"
                        ? "Edit"
                        : "Create new"}{" "}
                      template
                    </H2>
                    <CustomToolTip
                      size="xs"
                      text={`${
                        state?.flag == "View"
                          ? "View"
                          : state?.flag == "Edit"
                          ? "Edit"
                          : "Create new"
                      } template`}
                    />
                  </div>
                </div>
              </div>
              <Form onSubmit={handleSubmit(handlePublishTemplate)}>
                <div className="p-4 border-bottom">
                  <div className="d-flex align-items-end mb-4 gap-2">
                    <H3>General template information</H3>

                    <CustomToolTip size="xs" text="Create new template" />
                  </div>
                  <Row>
                    <Col lg={3} className="pe-lg-0">
                      <div className="d-flex form-label-title align-items-center gap-2">
                        <H5> Category </H5>
                        <CustomToolTip size="xs" text="Internal title" />
                      </div>

                      <Row className="mt-2">
                        <Col className="pb-1">
                          <Controller
                            control={control}
                            {...register("category")}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <InputField
                                id={"category"}
                                value={value}
                                type="text"
                                placeholder="Category"
                                errorscope
                                iserror={errors?.category}
                                onChange={(e) => {
                                  onChange(e);
                                  clearErrors("category");
                                }}
                                disabled={true}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={3} className="pe-lg-0">
                      <div className="d-flex form-label-title align-items-center gap-2">
                        <H5> Sub category </H5>
                        <CustomToolTip size="xs" text="Internal title" />
                      </div>

                      <Row className="mt-2">
                        <Col className="pb-1">
                          <Controller
                            control={control}
                            {...register("sub_category")}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <InputField
                                id={"sub_category"}
                                value={value}
                                type="text"
                                placeholder="Sub category"
                                errorscope
                                iserror={errors?.sub_category}
                                onChange={(e) => {
                                  onChange(e);
                                  clearErrors("sub_category");
                                }}
                                disabled={true}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={3} className="pe-lg-0">
                      <div className="d-flex form-label-title align-items-center gap-2">
                        <H5> Type </H5>
                        <CustomToolTip size="xs" text="Type" />
                      </div>

                      <Row className="mt-2">
                        <Col className="pb-1">
                          <Controller
                            control={control}
                            {...register("type")}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <InputField
                                id={"type"}
                                value={value}
                                type="text"
                                placeholder="Type"
                                errorscope
                                iserror={errors?.type}
                                onChange={(e) => {
                                  onChange(e);
                                  clearErrors("type");
                                }}
                                disabled={true}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>

                <div className="p-4 border-bottom">
                  <div className="mb-4">
                    {" "}
                    <div className="d-flex align-items-end mb-2 gap-2">
                      <H5>Title </H5>

                      <CustomToolTip size="xs" text="Title" />
                    </div>
                    <Row>
                      <Col lg={3} className="pe-lg-0">
                        <Controller
                          control={control}
                          {...register("title")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"title"}
                              value={value}
                              type="text"
                              placeholder="Title"
                              errorscope
                              iserror={errors?.title}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("title");
                              }}
                              disabled={isViewTemplate}
                              maxLength={100}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="mb-4">
                    <div className="d-flex align-items-end mb-2 gap-2">
                      <H5>Internal description </H5>

                      <CustomToolTip size="xs" text="Description" />
                    </div>
                    <Row>
                      <Col lg={6} className="pe-lg-0">
                        <Controller
                          control={control}
                          {...register("description")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"description"}
                              value={value}
                              type="text"
                              placeholder="Description"
                              errorscope
                              iserror={errors?.description}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("description");
                              }}
                              maxLength={150}
                              disabled={isViewTemplate}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-4">
                    <div className="d-flex align-items-end mb-2 gap-2">
                      <H5>Language </H5>

                      <CustomToolTip size="xs" text="Choose language" />
                    </div>
                    <Row>
                      <Col lg={3} className="pe-lg-0">
                        <Controller
                          control={control}
                          {...register("language")}
                          rules={{ required: false }}
                          render={({ field: { onChange, value } }) => (
                            <ReactSelect
                              placeholder={"Choose language"}
                              iserror={errors?.language}
                              options={GetLanguageByOrganizationData?.data?.map(
                                (item) => ({
                                  value: item?.Id,
                                  label: (
                                    <div
                                      className="d-flex align-items-center"
                                      style={{ fontFamily: "Cerebri sans" }}
                                    >
                                      <div className="radius-4 me-2">
                                        <img
                                          src={item?.DataValue1}
                                          style={{
                                            width: "20px",
                                            marginRight: "10px",
                                          }}
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <div className="fs-14 light-font-text ">
                                          {" "}
                                          {item?.Text}
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                })
                              )}
                              value={
                                value == undefined ? "" : displayItem(value)
                              }
                              onChange={(e) => {
                                onChange(e);
                                if(!languages.includes(e.value)) {
                                  setLanguages([...languages, e.value])
                                }
                                clearErrors("language");
                                resetField("branding");
                              }}
                              clearable={false}
                              disabled={isViewTemplate}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                  
                  <div className="">
                    <div className="d-flex align-items-end mb-2 gap-2">
                      <H5>Branding </H5>

                      <CustomToolTip size="xs" text="Branding" />
                    </div>
                    <Row>
                      <Col lg={3} className="pe-lg-0">
                        <Controller
                          control={control}
                          {...register("branding")}
                          rules={{ required: false }}
                          render={({ field: { onChange, value } }) => (
                            <ReactSelect
                              placeholder={"Branding"}
                              iserror={errors?.branding}
                              options={GetEmailTemplateBrandingData?.data?.map(
                                (item) => ({
                                  value: item?.Id,
                                  label: item?.Text,
                                })
                              )}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("branding");
                              }}
                              clearable={false}
                              disabled={isViewTemplate}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="p-4 border-bottom">
                  <div className="d-flex form-label-title align-items-end gap-2 mb-3">
                    <H5> Subject </H5>
                    <CustomToolTip size="xs" text="Subject" />
                  </div>
                  <div className="mb-4">
                    <Row>
                      <Col lg={7}>
                        <InputField
                          placeholder={"Headline"}
                          type={"text"}
                          value={emailSubject?.[watch("language.value")]}
                          onChange={(event) =>
                            setEmailSubject({
                              ...emailSubject,
                              [watch("language.value")]: event.target.value
                            })
                          }
                          maxLength={100}
                          disabled={isViewTemplate}
                        />
                        {/* <button type="button" onClick={handleInsertText}>
                          Insert Text
                        </button> */}
                      </Col>
                      <Col lg={2}>
                        <ETButton
                          variant={"primary"}
                          buttonText="MERGE FIELD"
                          icon={true}
                          onClick={handleAddPropertyDropDown}
                          width="auto"
                          disabled={isViewTemplate}
                        />

                        <Menu
                          anchorEl={anchorEl}
                          open={anchorEl}
                          onClose={handleClosePropertyDropDown}
                          PaperProps={{
                            style: {
                              borderRadius: "8px",
                              marginTop: "6px",
                              border: "0.5px solid #F0F0F0",
                              boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.12)",
                              paddingTop: "0px",
                            },
                          }}
                          sx={{
                            "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                              padding: 0,
                              borderColor: "##F0F0F0",
                            },
                          }}
                        >
                          <div className="addpropertyCountryHandle ">
                            <>
                              <div className="px-3 py-3">
                                <span
                                  className="label-regular mb-1"
                                  style={{ color: "#888888" }}
                                >
                                  Search for merge field{" "}
                                </span>
                                <FilterComponent
                                  filtertext={searchText}
                                  handleSearch={(e) =>
                                    setSearchText(e.target.value)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  width: "254px",
                                  height: "190px",
                                }}
                              >
                                <hr className="hr m-0"></hr>
                                {GetEmailTemplateMergeFieldData?.data
                                  ?.filter((item) => {
                                    if (searchText === "") {
                                      return item;
                                    } else if (
                                      item?.name
                                        .toLowerCase()
                                        .includes(searchText?.toLowerCase())
                                    ) {
                                      return item;
                                    }
                                  })
                                 ?.map((data, index) => (
                                    <React.Fragment
                                      key={data?.email_template_merge_field_id}
                                    >
                                      <div
                                        className="addpropContent p-3"
                                        onClick={() =>
                                          handleInsertText(data?.value)
                                        }
                                      >
                                        <span className="ms-1">
                                          {data?.name}
                                        </span>
                                      </div>
                                      <hr className="hr m-0"></hr>
                                    </React.Fragment>
                                  ))}
                              </div>
                            </>
                          </div>
                        </Menu>
                      </Col>
                    </Row>
                  </div>

                  {/* body and Insert merge field */}
                  <div>
                    <Row className="">
                      <Col lg={8}>
                        <div className="d-flex form-label-title align-items-end gap-2 mb-3">
                          <H4> Body </H4>
                          <CustomToolTip size="xs" text="Body" />
                        </div>
                        <div>
                          <div className="">
                            <CKEditor
                              editor={SuperEditor}
                              focusedElement={true}
                              onReady={(editor) => {
                                editor.editing.view.focus();
                                editorRef.current = editor;
                              }}
                              key={watch('language.value')}
                              disabled={isViewTemplate ? true : false}
                              config={editorConfiguration} // Set the custom configuration here
                              data={emailTemplateBody?.[watch('language.value')] || ""}
                              onChange={(event, editor)=> handleCkEditorChange(editor?.getData())}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="d-flex form-label-title align-items-end gap-2 mb-3">
                          <H4> Insert merge field </H4>
                          <CustomToolTip size="xs" text="Insert merge field" />
                        </div>
                        {/* search placeholder */}
                        <div>
                          <FilterComponent placeholder={"Search for a tag…"} />
                        </div>

                        {/* Recently merge fields */}
                        <div>
                          <div className="d-flex form-label-title align-items-end gap-2 my-3">
                            <H5>Recently merge fields</H5>
                            <CustomToolTip
                              size="xs"
                              text="Recently merge fields"
                            />
                          </div>
                          <div>
                            {GetEmailTemplateMergeFieldData?.data?.map(
                              (item, i) => {
                                return (
                                  <div
                                    key={item?.email_template_merge_field_id}
                                    className="pt-1"
                                  >
                                    <ETButton
                                      disabled={isViewTemplate}
                                      buttonText={item?.name}
                                      icon={true}
                                      variant={"secondary"}
                                      onClick={() =>
                                        handleClickMergeFieldsButton(
                                          item?.value
                                        )
                                      }
                                    />
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                {/* ============= uncomment once API is available ============== */}
                {/* <div className="p-4">
                  {" "}
                  <div className="d-flex align-items-end mb-2 gap-2">
                    <H5>Signature </H5>

                    <CustomToolTip size="xs" text="Title" />
                  </div>
                  <Row>
                    <Col lg={3} className="pe-lg-0">
                      <Controller
                        control={control}
                        {...register("signature")}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <ReactSelect
                            placeholder={"Branding"}
                            iserror={errors?.signature}
                            errorScope
                            options={[]}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("signature");
                            }}
                            clearable={false}
                            disabled={isViewTemplate}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </div> */}
                <div className="py-2 px-4 d-flex  justify-content-between  justify-content-center footer-section-button-container ">
                  <ETButton
                    variant={"white"}
                    buttonText={isViewTemplate ? "BACK" : "CANCEL"}
                    onClick={() => {
                      isViewTemplate
                        ? navigate("/organization/settings/marketing")
                        : setCancelShow(true);
                    }}
                  />
                  <div className=" d-flex gap-3">
                    <ETButton
                      variant={"secondary"}
                      onClick={() => handlePreviewTemplate()}
                      buttonText="PREVIEW"
                    />
                    <ETButton
                      disabled={
                        isViewTemplate ||
                        PostAddNewEmailTemplateIsLoading ||
                        PutUpdateEmailTemplateIsLoading
                      }
                      variant={"primary"}
                      buttonText="PUBLISH TEMPLATE"
                      type="submit"
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <PublishTemplate
        popupShow={publishTemplateShow}
        setPopupShow={setPublishTemplateShow}
        template_title={getValues("title")}
      />
      <PublishTemplate
        popupShow={cancelShow}
        setPopupShow={setCancelShow}
        usedFor={"C"}
        handleSaveAsDraft={handleSaveTemplateAsDraft}
      />
      <EmailPreview
        popupShow={previewShow}
        setPopupShow={setPreviewShow}
        displayHTML={previewHTML}
      />
    </div>
  );
};

export default CreateNewEmailTemplate;

// const used for recently merge fields
const recentlyMergeFields = [
  "Customer name",
  "Unit address",
  "Property name",
  "Move in date",
  "Move out date",
  "customer email",
];
