import { useEffect, useState } from "react";
import { MemoizedPropertiesList } from "./PropertiesList";
import loadable from "@loadable/component";

// import CreateNewPortfolio from "./createNewPortfolio/CreateNewPortfolio";
// import AddnewProperty from "./addNewProperty/AddnewProperty";
import {
  useGetGeographyCountry,
  useGetPropertyTypes,
} from "../../hooks/queries/usePropertyQueries";
import {
  useCountry,
  usePropertyType,
  useShowPage,
} from "../../hooks/stores/usePropertyStore";
import ErrorBoundary from "../../Components/ErrorBoundary/ErrorBoundary";
// const CreateNewPortfolio = lazy(() =>
// 	import("./createNewPortfolio/CreateNewPortfolio")
// );
// const AddnewProperty = lazy(() => import("./addNewProperty/AddnewProperty"));
const CreateNewPortfolio = loadable(() =>
  import("./createNewPortfolio/CreateNewPortfolio")
);
const AddnewProperty = loadable(() =>
  import("./addNewProperty/AddnewProperty")
);

function Property({ flag = "PL" }) {
  // zustand
  const { showPage, setShowPage } = useShowPage((state) => state);

  // const [showPage, setShowPage] = useState("PL");
  const [listType, setListType] = useState(0);
  const [Id, setId] = useState(0);
  const [show, setShow] = useState(false);
  const [countryId, setCountryId] = useState("");
  const [EditPropertyID, setEditPropertyID] = useState("");
  const [operationName, setOperationName] = useState("create");
  const [editPortfolioId, setEditPortfolioId] = useState("");
  const [buttonType, setButtonType] = useState("");
  const [activePortfoliTabs, setActivePortfolioTabs] = useState("");
  const [activeDefaultTabs, setActiveDefaultTabs] = useState("All Properties");

  const { data: propertyTypesData } = useGetPropertyTypes();
  const { data: countryData } = useGetGeographyCountry();
  const { setCountryStoreData } = useCountry((state) => state);
  const { setPropertyStoreData } = usePropertyType((state) => state);

  useEffect(() => {
    setCountryStoreData(countryData?.data);
  }, [countryData]);

  useEffect(() => {
    setPropertyStoreData(propertyTypesData?.data);
  }, [propertyTypesData]);
  return (
    <div>
      {showPage == "PL" && (
        <MemoizedPropertiesList
          setListType={setListType}
          setCountryId={setCountryId}
          setButtonType={setButtonType}
          countryId={countryId}
          setId={setId}
          setShowPage={setShowPage}
          EditPropertyID={EditPropertyID}
          setEditPropertyID={setEditPropertyID}
          setOperationName={setOperationName}
          setEditPortfolioId={setEditPortfolioId}
          activePortfoliTab={activePortfoliTabs}
          setActivePortfolioTabs={setActivePortfolioTabs}
          activeDefaultTabs={activeDefaultTabs}
          setActiveDefaultTabs={setActiveDefaultTabs}
          flag={flag}
        />
      )}
      {showPage === "P" && (
        <ErrorBoundary>
          <CreateNewPortfolio
            operationName={operationName}
            setOperationName={setOperationName}
            setShowPage={setShowPage}
            editPortfolioId={editPortfolioId}
            activePortfoliTab={activePortfoliTabs}
            setActivePortfolioTabs={setActivePortfolioTabs}
            activeDefaultTabs={activeDefaultTabs}
            setActiveDefaultTabs={setActiveDefaultTabs}
          />
        </ErrorBoundary>
      )}
      {showPage === "AP" && (
        <ErrorBoundary>
          <AddnewProperty
            propertyTypesData={propertyTypesData}
            EditPropertyID={EditPropertyID}
            setEditPropertyID={setEditPropertyID}
            countryId={countryId}
            buttonType={buttonType}
            setButtonType={setButtonType}
            setShow={setShow}
            setShowPage={setShowPage}
          />
        </ErrorBoundary>
      )}
    </div>
  );
}

export default Property;
