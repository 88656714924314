import React, { useState } from "react";
import Menus from "../Menus/Menus";
import moment from "moment";
import CustomCheckBox from "../Checkbox/Checkbox";
import ETButton from "../Buttons/ETButton";
import CustomPopup from "../CustomPopup";
import styled from "styled-components";
import { useUserDetails } from "../../hooks/stores/useUserDetailsStore";
import { UTCtoLocalTimeZone } from "../../utils/UTCtoLocalTimeZone";

const Card = ({
  textDetailsData,
  handleEditTextPackage,
  handleDuplicateClick,
  handleDeleteText,
  isTag,
  setDeletePopUp,
  checkValue,
  checkboxOnChange,
  openMediaPackageDelete,
  setOpenMediaPackageDelete,
  disableDeleteAction,
}) => {
  console.log("🚀 ~ file: TextCard.js:22 ~ textDetailsData:", textDetailsData);
  // const [mediaStyle, setMediaStyle] = useState(false);
  //------------- zustand ------------------//
  const { userDetails } = useUserDetails((state) => state);
  const { convertedDate: createdBy } = UTCtoLocalTimeZone({
    timestamp: textDetailsData?.created_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });
  const { convertedDate: modifiedBy } = UTCtoLocalTimeZone({
    timestamp: textDetailsData?.modified_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });
  console.log("TC", textDetailsData);

  return (
    <CardsDiv
      // mediaStyle={mediaStyle}
      isActive={checkValue?.length}
    >
      {isTag === "AddTagT" && (
        <div className="d-flex justify-content-end">
          <CustomCheckBox
            id={textDetailsData?.property_text_id}
            // value={checkValue}
            checked={checkValue?.length}
            onChange={checkboxOnChange}
            // onClick={() => {
            //   setMediaStyle(!mediaStyle);
            // }}
          />
        </div>
      )}
      <div style={{ padding: "20px", borderBottom: "1px solid #F0F0F0" }}>
        <div
          style={{
            border: "1px solid #F0F0F0",
            borderRadius: "6px",
            fontSize: "12px",
            fontFamily: "Cerebri sans-light",
            background: "#FFFFFF",
            textAlign: "justify",
            height: "150px",
            lineHeight: "17px",
            position: "relative",
            width: "100%",
          }}
        >
          <span
            style={{
              position: "absolute",
              height: "100%",
              padding: "16px",
              width: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: textDetailsData?.text_value,
              }}
            ></div>
          </span>
        </div>
      </div>
      <div
        className="d-flex flex-column "
        style={{ gap: "10px", padding: "20px" }}
      >
        {isTag && (
          <div className="fs-14">
            <div className="form-label-title ">Source</div>
            <div className=" light-font-text">Text</div>
          </div>
        )}

        <div className="fs-14">
          <div className="form-label-title ">Package title</div>
          <div className=" light-font-text">
            {textDetailsData?.internal_title}
          </div>
        </div>
        <div
          className="fs-14"
          style={{ width: "95%" }}
        >
          <div className="form-label-title ">Language</div>
          <div className="d-flex gap-2">
            <div
              className="d-flex"
              style={{ width: "100%" }}
            >
              <div className=" pt-1 me-1 light-font-text text-truncate  ">
                {textDetailsData?.language}
              </div>
            </div>
          </div>
        </div>

        <div className="fs-14">
          <div className="form-label-title">Category</div>

          <div className="light-font-text">
            {textDetailsData?.category || "-"}
          </div>
        </div>

        {!isTag && (
          <div className="fs-14">
            <div className="form-label-title">Tag</div>
            <div className=" light-font-text">
              <span
                className="text-break ellipsis d-inline-block "
                style={{ width: "90%", height: "20px" }}
              >
                {textDetailsData?.tags ? textDetailsData?.tags : "-"}
              </span>
            </div>
          </div>
        )}

        <div className="  d-flex justify-content-between ">
          <div className="fs-12 pt-1 ">
            <div className="fs-12  form-label-title">Created by</div>
            <div className="created-by">
              {textDetailsData?.created_by
                ? textDetailsData?.created_by + ", " + createdBy
                : "-"}
            </div>
            <div className="fs-12 pt-1 form-label-title">Last updated by</div>
            <div className="created-by">
              {textDetailsData?.modified_by
                ? textDetailsData?.modified_by + ", " + modifiedBy
                : "-"}
            </div>
          </div>
          {isTag !== "AddTagT" && (
            <div className="d-flex align-items-end">
              <Menus
                itemheight={23}
                itemwidth={23}
                iconborderradius="4px"
                component={
                  <>
                    {isTag !== "T" ? (
                      <div className="fs-14">
                        <div
                          className="addpropContent px-3 py-2 light-font-text"
                          onClick={() =>
                            handleEditTextPackage(
                              textDetailsData?.property_text_id
                            )
                          }
                        >
                          <span className="ms-1">Edit</span>
                        </div>
                        <hr className="m-0"></hr>
                        <div
                          className="addpropContent px-3 py-2"
                          onClick={() =>
                            handleDuplicateClick(
                              textDetailsData?.property_text_id
                            )
                          }
                        >
                          <span className="ms-1">Duplicate</span>
                        </div>
                        <hr className=" m-0"></hr>
                        <div
                          className="addpropContent px-3 pt-2 pb-2 "
                          onClick={() => setOpenMediaPackageDelete(true)}
                        >
                          <span className="ms-1">Delete</span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="addpropContent px-3 pt-2 pb-2 light-font-text"
                        onClick={() => setDeletePopUp(true)}
                      >
                        <span className="ms-1">Delete</span>
                      </div>
                    )}
                  </>
                }
              />
            </div>
          )}
        </div>
        <CustomPopup
          open={openMediaPackageDelete}
          onHide={() => setOpenMediaPackageDelete(false)}
          title="Delete package"
          title_color="black"
          enableCrossButton={false}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
          deletePopUpStyle={true}
          titleTooltip={false}
        >
          <div className="p-3">
            <div className="px-2">
              <div
                className="light-font-text my-1"
                style={{ height: "100px" }}
              >
                <p>Are you sure you want to delete the text package?</p>
                {/* <p>That will conflict with.....</p> */}
              </div>
            </div>
          </div>
          <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="COLD FEET"
              onClick={() => setOpenMediaPackageDelete(false)}
            />
            <ETButton
              variant={"primary"}
              buttonText="I'M SURE"
              disabled={disableDeleteAction}
              onClick={() => {
                handleDeleteText(textDetailsData?.property_text_id);
              }}
            />
          </div>
        </CustomPopup>
      </div>
    </CardsDiv>
  );
};

export default Card;

const CardsDiv = styled.div`
  background-color: #f9fafa;
  border-color: #f0f0f0;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  ${(props) =>
    props.isActive &&
    `
  background-color:${props.theme.component.checkbox.primary.color + "11"};
   border-color: ${props.theme.component.checkbox.primary.color};
`}
`;
