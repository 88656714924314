import React, { useCallback, useMemo, useState } from "react";
import ETButton from "../../../Components/Buttons/ETButton";
import { Col, Image, Placeholder, Row } from "react-bootstrap";
import Toggle from "../../../Components/Switch/Switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../Components/table/MRTGlobalProps";
import MRTTheme from "../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";

import CustomPopup from "../../../Components/CustomPopup";
import {
  DropZoneBorder,
  GreenSpan,
  PrimarySelectedTag,
} from "../../../styles/Common.style";
import { usePropertyId } from "../../../hooks/stores/usePropertyStore";
import {
  useAddGuideDocument,
  useCopyGuideToPacakge,
  useGetGuideDocumentByPropertyId,
} from "../../../hooks/queries/useDocumentsQueries";
import photoComingSoon from "../../../assets/images/photo-coming-soon.jpg";
import { Controller, useForm } from "react-hook-form";
import { REQUIRED_ERR } from "../../../utils/validationConstants";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { status } from "../../../model/Constant";
import {
  DeleteGuideDocument,
  PostCloneDocument,
  PutUpdateDocumentGuide,
} from "../../../services/Documents";
import { Tooltip, tooltipClasses } from "@mui/material";
import styled from "styled-components";
import ShowTooltip from "../../../Components/Tooltip/ShowTooltip";
import moment from "moment";
import { Form } from "react-bootstrap";
import FilterComponent from "../../../reactdataTableComponent/FilterComponent";
import CustomCheckBox from "../../../Components/Checkbox/Checkbox";
import InputField from "../../../Components/Textfield/InputField";

const Guides = ({
  setTabType,
  getDocumentPackagesByPropertyIdData,
  getDocumentPackagesByPropertyIdDataRefetch,
}) => {
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top-start" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme?.palette?.common?.white,
      border: "1px solid #d1d1d1",
      padding: "20px",
      borderRadius: "8px",
      width: "165px",
      height: "152px",
      boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
    },
  }));
  //---------------validation-------------------//
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  // ------------------------Zustand----------------------------//
  const propertyId = usePropertyId((state) => state.propertyId);

  //----------------------------States-------------------------//
  const [openDeleteDocumentPopup, setOpenDeleteDocumentPopup] = useState({
    document_id: "",
    isPopupOpen: false,
    isLoading: false,
  });
  const [openUploadGuideDocument, setOpenUploadGuideDocument] = useState(false);
  const [isGuideDocumentPublished, setIsGuideDocumentPublished] =
    useState(true);
  const [guideImage, setGuideImage] = useState([]);
  const [guideDocumentFile, setGuideDocumentFile] = useState([]);
  const [viewDocumentPopup, setViewDocumentPopup] = useState({
    documentUrl: "",
    documentName: "",
    isViewDocumentPopupOpen: false,
  });

  const [guidePropertyMenu, setGuidePropertyMenu] = useState({
    preview: false,
    copy: false,
    delete: false,
  });
  const [allChecked, setAllChecked] = useState([]);
  const [documentId, setDocumentId] = useState("");
  const [searchText, setSearchText] = useState("");
  //--------------------react query----------------------//
  const {
    data: getGuideDocumentByPropertyIdData,
    refetch: getGuideDocumentByPropertyIdDataRefetch,
  } = useGetGuideDocumentByPropertyId(propertyId);

  const {
    mutateAsync: PostAddGuideDocument,
    isLoading: PostAddGuideDocumentIsLoading,
  } = useAddGuideDocument();
  const {
    mutateAsync: PostCopyGuideToPacakge,
    isLoading: PostCopyGuideToPacakgeIsLoading,
  } = useCopyGuideToPacakge();

  const handleGuidesPublish = (e, guideId) => {
    let id;
    if (!PostCopyGuideToPacakgeIsLoading) {
      id = toast.loading("Uploading document");
    }
    const guideDetailsPayload = {
      document_guide_id: String(guideId),
      is_publish: e.target.checked ? "1" : "0",
    };

    PutUpdateDocumentGuide(guideDetailsPayload)
      .then((response) => {
        toast.success(response.data.message);
        getGuideDocumentByPropertyIdDataRefetch();
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  //----------------- checked  property for packages--------------------//
  const handleOnChecked = (e, index) => {
    const { id, checked } = e.currentTarget;
    if (checked) {
      let newArry = [...allChecked, id];
      setAllChecked(newArry);
    } else {
      let newArry = allChecked.filter((item) => item != String(id));
      if (newArry?.length != 0) {
        setAllChecked(newArry);
      } else {
        setAllChecked([]);
      }
    }
  };

  // ---------------------------handleDuplicate---------------//
  const handleDuplicate = (data) => {
    const cloneDocumentData = {
      document_guide_id: documentId,
      document_package_id: allChecked,
    };
    let id;
    if (!PostCopyGuideToPacakgeIsLoading) {
      id = toast.loading("Copy document...");
    }
    PostCopyGuideToPacakge(cloneDocumentData).then(
      (response) => {
        if (response.data.bReturn) {
          toast.update(id, {
            render: response?.data?.message,
            isLoading: false,
            type: status.s,
            autoClose: 2000,
          });
          setGuidePropertyMenu({
            ...guidePropertyMenu,
            copy: false,
          });
          getDocumentPackagesByPropertyIdDataRefetch();
          setAllChecked([]);
          reset();
          data["isChecked"] = allChecked;
        } else {
          toast.update(id, {
            render: response?.data?.message,
            isLoading: false,
            type: status.e,
            autoClose: 2000,
          });
        }
      },
      (error) => {
        // Some task on failure
        toast.update(id, {
          render: error?.message,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    );
  };
  const guideDocumentColumns = useMemo(
    () => [
      {
        header: "Thumbnail",
        size: 80,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          // sx: {
          //   // paddingLeft: "0px!important",
          // },
        },

        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Image
              className="rounded"
              src={
                row.original.image_url !== ""
                  ? row.original.image_url
                  : photoComingSoon
              }
              height={60}
              width={60}
            />
          </div>
        ),
      },
      {
        accessorKey: "type",
        header: "Type",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => (
          <div className="d-flex gap-2 ps-3">
            <ShowTooltip
              tooltipLabel={
                <button
                  className="document-property-table-btn d-flex justify-content-center align-items-center"
                  onClick={() => {
                    setViewDocumentPopup((prev) => ({
                      ...prev,
                      isViewDocumentPopupOpen: true,
                      documentUrl: cell?.row?.original?.guide_url,
                      documentName: cell?.row.original?.title,
                    }));
                  }}
                >
                  <FontAwesomeIcon
                    icon="fa-regular fa-file-pdf"
                    className="fs-6"
                  />
                </button>
              }
              tooltopWidth={80}
              tooltipContent={<div className="p-2 fs-12">Preview</div>}
            />
          </div>
        ),
      },
      {
        accessorKey: "title",
        header: "Name",
        size: 300,
        Cell: ({ cell }) => (
          <>
            <div>
              {cell.getValue()}
              {/* <FontAwesomeIcon icon="fa-regular fa-file-pdf" className="fs-6" /> */}
            </div>
            <div className="cursor-pointer">
              <ShowTooltip
                tooltipLabel={<GreenSpan>View description</GreenSpan>}
                tooltopWidth={200}
                tooltipContent={
                  <div className="p-2 fs-12">
                    {cell?.row?.original?.description == ""
                      ? "-"
                      : cell?.row?.original?.description}
                  </div>
                }
              />
            </div>
          </>
        ),
      },
      {
        accessorKey: "created_on", //normal accessorKey
        header: "Date of upload",
        size: 50,
        Cell: ({ cell }) => (
          <div>{moment(cell.getValue()).format("DD MMM, YYYY")}</div>
        ),
      },
      {
        accessorKey: "created_by", //normal accessorKey
        header: "Uploaded by",
        // size: 100,
      },
      {
        accessorKey: "is_published", //normal accessorKey
        header: "Published",
        size: 50,
        Cell: ({ cell }) => (
          <div>
            <Toggle
              id={cell?.row?.original?.document_guide_id}
              checked={cell?.row?.original?.is_published == 1 ? true : false}
              onChange={(e) =>
                handleGuidesPublish(e, cell?.row?.original?.document_guide_id)
              }
            />
          </div>
        ),
      },
      {
        accessorKey: "table_menu", //normal accessorKey
        header: "",
        size: 50,
        enableSorting: false,
        Cell: ({ cell }) => (
          <div className="d-flex gap-2">
            <ShowTooltip
              tooltipLabel={
                <button
                  className="document-property-table-btn d-flex justify-content-center align-items-center"
                  onClick={() =>
                    setViewDocumentPopup((prev) => ({
                      ...prev,
                      isViewDocumentPopupOpen: true,
                      documentUrl: cell?.row?.original?.guide_url,
                      documentName: cell?.row.original?.title,
                    }))
                  }
                >
                  <FontAwesomeIcon
                    icon="fa-regular fa-file-magnifying-glass"
                    className="fs-6"
                  />
                </button>
              }
              tooltopWidth={80}
              tooltipContent={<div className="p-2 fs-12">Preview</div>}
            />

            <ShowTooltip
              tooltipLabel={
                <button
                  className="document-property-table-btn d-flex justify-content-center align-items-center"
                  onClick={() => {
                    setDocumentId(cell?.row?.original?.document_guide_id);
                    setTabType(0);
                    setGuidePropertyMenu({
                      ...guidePropertyMenu,
                      copy: true,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon="fa-regular fa-arrow-up-right-from-square"
                    className="fs-6"
                  />
                </button>
              }
              tooltopWidth={150}
              tooltipContent={
                <div className="p-2 fs-12">Copy document to package</div>
              }
            />

            <ShowTooltip
              tooltipLabel={
                <button
                  className="document-property-table-btn d-flex justify-content-center align-items-center"
                  onClick={() => {
                    setOpenDeleteDocumentPopup((prev) => ({
                      ...prev,
                      document_id: cell?.row?.original?.document_guide_id,
                      isPopupOpen: true,
                    }));
                  }}
                >
                  <FontAwesomeIcon
                    icon="fa-regular fa-trash"
                    className="fs-6"
                  />
                </button>
              }
              tooltopWidth={70}
              tooltipContent={<div className="p-2 fs-12">Delete</div>}
            />
            {/* <FontAwesomeIcon icon="fa-regular fa-trash" className="fs-6" /> */}
          </div>
        ),
      },
    ],
    []
  );
  const renderEmptyRowsFallback = () => (
    <div className="d-flex justify-content-center w-100 grey-text py-4 light-font-text">
      <div>
        You do not have any guide(s) yet. Click on “+ UPLOAD DOCUMENT” to add
        your first.
      </div>
    </div>
  );
  //----------------upload guide image----------------//
  const { open: openThumbnail } = useDropzone({
    onDrop: useCallback((acceptedFiles, fileRejections) => {
      setGuideImage(acceptedFiles?.[0]);

      fileRejections.map(({ file, errors }) => {
        toast.error("Invalid file type " + file.name);
      });
    }, []),
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  //----------------upload guide document----------------//
  const { open: openGuideDocument } = useDropzone({
    onDrop: useCallback((acceptedFiles, fileRejections) => {
      setGuideDocumentFile(acceptedFiles?.[0]);

      fileRejections.map(({ file, errors }) => {
        toast.error("Invalid file type " + file.name);
      });
    }, []),
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  //-------------------handle delete guide document-----------------//
  const handleDeleteGuideDocument = async () => {
    setOpenDeleteDocumentPopup((prev) => ({ ...prev, isLoading: true }));
    try {
      const response = await DeleteGuideDocument(
        openDeleteDocumentPopup?.document_id
      );
      if (response?.data?.bReturn) {
        toast.success(response?.data?.message);
        getGuideDocumentByPropertyIdDataRefetch();
        setOpenDeleteDocumentPopup((prev) => ({
          ...prev,
          isPopupOpen: false,
          isLoading: false,
        }));
      } else {
        toast.error(response?.data?.message);
        setOpenDeleteDocumentPopup((prev) => ({ ...prev, isLoading: false }));
      }
    } catch (error) {
      toast.error(error?.message);
      setOpenDeleteDocumentPopup((prev) => ({ ...prev, isLoading: false }));
    }
  };

  //----------------handle add/upload guide document-----------------//
  const handleAddGuideDocument = async (data) => {
    let id;
    if (!PostAddGuideDocumentIsLoading) {
      id = toast.loading("Uploading document");
    }
    const guideDocumentPayload = new FormData();
    guideDocumentPayload.append("guide_image", guideImage);
    guideDocumentPayload.append("guide_document", guideDocumentFile);
    guideDocumentPayload.append("property_id", propertyId);
    guideDocumentPayload.append("title", data?.guideDocumentTitle);
    guideDocumentPayload.append(
      "description",
      data?.guideDocumentDescription || ""
    );
    guideDocumentPayload.append(
      "is_published",
      isGuideDocumentPublished ? "1" : "0"
    );

    try {
      const response = await PostAddGuideDocument(guideDocumentPayload);
      if (response?.data?.bReturn) {
        toast.update(id, {
          render: response?.data?.message,
          isLoading: false,
          type: status.s,
          autoClose: 2000,
        });
        getGuideDocumentByPropertyIdDataRefetch();
        setOpenUploadGuideDocument(false);
        reset();
        setGuideDocumentFile([]);
        setGuideImage([]);
      } else {
        toast.update(id, {
          render: response?.data?.message,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.update(id, {
        render: error?.message,
        isLoading: false,
        type: status.e,
        autoClose: 2000,
      });
    }
  };
  return (
    <div className="py-2">
      <Placeholder animation="glow">
        <div className="d-flex flex-wrap justify-content-between gap-2 align-items-center">
          <div className="d-flex flex-column" style={{ flex: "1 1 65%" }}>
            <p className="mb-0 form-label-title">Guides on property</p>

            <span className="light-font-text grey-text d-inline-block">
              Below are the guide documents uploaded directly on the property.
              <br />
              The documents can be displayed on the website and unit selector by
              setting Published to active.
            </span>
          </div>

          <div
            className="d-flex align-items-start justify-content-lg-end"
            style={{ flex: "1 1 30%" }}
          >
            <ETButton
              variant={"primary"}
              buttonText="UPLOAD DOCUMENT"
              icon={true}
              width="fit-content"
              onClick={() => {
                setOpenUploadGuideDocument(true);
              }}
            />
          </div>
        </div>
      </Placeholder>
      <div className="mt-4">
        <MRTTheme
          colorVariantsForTableData={["odd"]}
          table={
            <MaterialReactTable
              columns={guideDocumentColumns}
              data={getGuideDocumentByPropertyIdData?.data || []}
              icons={{ ...SortIcon }}
              //   state={{ isLoading: !documentbypropertyid }}
              renderEmptyRowsFallback={renderEmptyRowsFallback}
              // data={documentPropertyData}
              enableTopToolbar={false}
              enableColumnActions={false}
              enablePagination={true}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
            />
          }
        />
      </div>
      {/* Delete document property */}
      <CustomPopup
        open={openDeleteDocumentPopup?.isPopupOpen}
        onHide={() => {
          setOpenDeleteDocumentPopup((prev) => ({
            ...prev,
            isPopupOpen: false,
          }));
        }}
        title="Delete guide document"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        size={"md"}
      >
        <div className="p-3 my-4">
          <div className="px-2 my-3">
            <div className="fw-300 fs-14 my-1">
              Are you sure you want to delete the document?
            </div>
          </div>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => {
              setOpenDeleteDocumentPopup((prev) => ({
                ...prev,
                isPopupOpen: false,
              }));
            }}
          />
          <ETButton
            disabled={openDeleteDocumentPopup?.isLoading}
            variant={"primary"}
            buttonText="I'M SURE"
            onClick={() => {
              handleDeleteGuideDocument();
            }}
          />
        </div>
      </CustomPopup>
      {/* Delete document property */}

      {/* upload document manually */}
      <CustomPopup
        open={openUploadGuideDocument}
        onHide={() => {
          setOpenUploadGuideDocument(false);
        }}
        title="Upload guide document"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        size="lg"
      >
        <form onSubmit={handleSubmit(handleAddGuideDocument)}>
          <div
            style={{ height: "460px", overflow: "scroll", overflowX: "hidden" }}
          >
            <div className="">
              <Row className="py-1 mt-3 px-4">
                <Col xl={4} lg={4}>
                  <div className=" form-label-title">Title</div>
                </Col>
              </Row>
              <Row className="pt-2 pb-4 px-4">
                <Col xl={4} lg={5} className="pe-0">
                  <Controller
                    control={control}
                    {...register("guideDocumentTitle")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        {/* <input
                          type="text"
                          placeholder="Title"
                          id="title"
                          maxLength={30}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("guideDocumentTitle");
                          }}
                        /> */}
                        <InputField
                          id={"title"}
                          value={value}
                          type={"text"}
                          maxLength
                          placeholder={"Title"}
                          errorscope
                          iserror={errors?.guideDocumentTitle}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("guideDocumentTitle");
                          }}
                        />
                        {/* <label htmlFor="title">Title</label> */}
                      </div>
                    )}
                  />
                  {/* {errors?.guideDocumentTitle && (
                    <p className="error-msg ">{REQUIRED_ERR}</p>
                  )} */}
                </Col>
              </Row>
            </div>
            <hr className="hr m-0" />
            <div className="">
              <Row className="py-1 mt-3 px-4">
                <Col xl={4} lg={4}>
                  <div className=" form-label-title">Description</div>
                </Col>
              </Row>
              <Row className="pt-2 pb-4 px-4">
                <Col xl={9} lg={7} className="pe-lg-0">
                  <Controller
                    control={control}
                    {...register("guideDocumentDescription")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <div className="form-float openhouse-des">
                        <textarea
                          style={{ resize: "none" }}
                          name="internalNote"
                          className="inputText"
                          cols="20"
                          rows="3"
                          placeholder=""
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("guideDocumentDescription");
                          }}
                        />
                        <label className="floating-label">Description</label>
                      </div>
                    )}
                  />
                  {errors?.guideDocumentDescription && (
                    <p className="error-msg ">{REQUIRED_ERR}</p>
                  )}
                </Col>
              </Row>
            </div>

            <hr className="hr m-0" />
            <div className="p-4">
              <Row>
                <Col xl={4} className="">
                  <Row className="  pb-1 d-flex ">
                    <Col className=" fs-14 d-flex align-items-center" lg={3}>
                      Publish
                    </Col>
                    <Col lg={6}>
                      <Toggle
                        id={"isPublishedGuideDocument"}
                        checked={isGuideDocumentPublished}
                        onChange={(e) =>
                          setIsGuideDocumentPublished(e.target.checked)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <hr className="hr m-0" />

            <Row>
              <Col lg={6}>
                <Row className="py-1 ps-4 mt-3">
                  <Col lg={3}>
                    <div className="form-label-title">Thumbnail </div>
                  </Col>
                </Row>
                <Row className="pt-2 pb-4 px-4">
                  <Col className="my-2 my-lg-0 pe-0">
                    <div className="">
                      <div className="d-flex gap-2 align-items-center">
                        <ETButton
                          variant={"primary"}
                          buttonText={"Upload image"}
                          onClick={() => openThumbnail()}
                        />
                        <div>
                          {guideImage?.name
                            ? guideImage?.name
                            : "No file chosen"}
                        </div>
                        <div>
                          {guideImage?.name && (
                            <FontAwesomeIcon
                              className="ms-2 cursor-pointer"
                              icon="fa-regular fa-trash"
                              style={{ color: "#000000" }}
                              onClick={() => setGuideImage([])}
                            />
                          )}
                        </div>
                      </div>
                      <div className="fs-12 light-font-text grey-text mb-1">
                        Supports only JPG and PNG
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={6}>
                <Row className="py-1 ps-4 mt-3">
                  <Col lg={3}>
                    <div className="form-label-title">Document </div>
                  </Col>
                </Row>
                <Row className="pt-2 pb-4 px-4">
                  <Col className="my-2 my-lg-0 pe-0">
                    <div className="">
                      <div className="d-flex gap-2 align-items-center">
                        <ETButton
                          variant={"primary"}
                          buttonText={"Choose file"}
                          onClick={() => openGuideDocument()}
                        />
                        <div>
                          {guideDocumentFile?.name
                            ? guideDocumentFile?.name
                            : "No file chosen"}
                        </div>
                        <div>
                          {guideDocumentFile?.name && (
                            <FontAwesomeIcon
                              className="ms-2 cursor-pointer"
                              icon="fa-regular fa-trash"
                              style={{ color: "#000000" }}
                              onClick={() => setGuideDocumentFile([])}
                            />
                          )}
                        </div>
                      </div>
                      <div className="fs-12 light-font-text grey-text mb-1">
                        Supports only PDF
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => {
                reset();
                setOpenUploadGuideDocument(false);
              }}
            />
            <ETButton
              variant={"primary"}
              buttonText="ADD"
              type="submit"
              disabled={PostAddGuideDocumentIsLoading}
              // onClick={handleAddDocument}
            />
          </div>
        </form>
      </CustomPopup>
      {/* upload document manually */}

      {/* View document popup*/}
      <CustomPopup
        open={viewDocumentPopup?.isViewDocumentPopupOpen}
        onHide={() =>
          setViewDocumentPopup((prev) => ({
            ...prev,
            isViewDocumentPopupOpen: false,
          }))
        }
        modalHeaderText={true}
        title={"Guide document"}
        subTitle={viewDocumentPopup?.documentName}
        size="lg"
        title_color="black"
        enableCrossButton={true}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div>
          <Row>
            <Col className="">
              <div style={{ height: "540px" }}>
                <iframe
                  src={viewDocumentPopup?.documentUrl}
                  height="100%"
                  width="100%"
                ></iframe>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        ></div>
      </CustomPopup>
      {/* Popup for preview a pdf of document property */}

      {/* Popup for copy a document property */}
      <CustomPopup
        open={guidePropertyMenu?.copy}
        onHide={() => {
          setGuidePropertyMenu({ ...guidePropertyMenu, copy: false });
          setAllChecked([]);
          setSearchText("");
        }}
        title="Copy document to package"
        title_color="black"
        titleFont="14px"
        titleTooltip={true}
        enableCrossButton={false}
        titleTooltipText="Copy document to package"
        modalHeadingStyle
        onCloseAction={() => {
          setGuidePropertyMenu({ ...guidePropertyMenu, copy: false });
          setAllChecked([]);
          setSearchText("");
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <Form onSubmit={handleSubmit(handleDuplicate)}>
          <div>
            <div className="d-flex justify-content-between px-4 pt-3 pb-2">
              <div className="form-label-title">Select package</div>
              <div>
                <input
                  value={"Reset"}
                  type="button"
                  onClick={() => {
                    setAllChecked([]);
                    setSearchText("");
                  }}
                  className="fs-12 form-label-title"
                  style={{
                    height: "23px",
                    width: "56px",
                    borderRadius: "4px",
                    backgroundColor: "rgba(240, 240, 240, 1)",
                    border: "1px solid rgba(209, 209, 209, 1)",
                  }}
                />
              </div>
            </div>

            <div className="px-4 pb-3">
              <FilterComponent
                lableText={"Search"}
                filtertext={searchText}
                handleSearch={(e) => setSearchText(e.target.value)}
              />
            </div>

            <div
              className=" scrollable-container-y ps-4 pe-2 me-2"
              style={{
                height: "350px",
                overflowY: "scroll",
              }}
            >
              {getDocumentPackagesByPropertyIdData?.data
                .filter((item) => {
                  if (searchText === "") {
                    return item;
                  } else if (
                    item?.document_package_name
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  ) {
                    return item;
                  }
                })
                .map((item, index) => (
                  <PrimarySelectedTag
                    key={index}
                    mode={allChecked.includes(
                      String(item?.document_package_id)
                    )}
                    className="d-flex justify-content-between radius-8 ps-3 p-1 my-2 cursor-pointer"
                  >
                    <div
                      className="light-font-text d-flex "
                      style={{ alignItems: "center" }}
                    >
                      {item?.document_package_name}&nbsp;
                      <span className="grey-text">
                        {item?.is_signing_package === 1
                          ? "(Signing package)"
                          : "(Document package)"}
                      </span>
                    </div>
                    <CustomCheckBox
                      id={item?.document_package_id}
                      checked={allChecked.includes(
                        String(item?.document_package_id)
                      )}
                      value={item?.document_package_id}
                      onChange={(e) => handleOnChecked(e, index)}
                    />
                  </PrimarySelectedTag>
                ))}
            </div>
          </div>

          <div
            className="py-2 px-3 d-flex  justify-content-between  justify-content-center footer-section-button-container"
            style={{
              bottom: "0",
              zIndex: "5",
            }}
          >
            <input
              type="button"
              value="CANCEL"
              onClick={() => {
                setGuidePropertyMenu({
                  ...guidePropertyMenu,
                  copy: false,
                });
                reset();
                setAllChecked([]);
              }}
              style={{
                backgroundColor: "#fff",
                width: "fit-content",
                alignItems: "center",
                display: "flex",
                gap: "7px",
                fontFamily: "Cerebri sans",
                fontWeight: "500",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
                textAlign: "center",
                padding: "10px 15px ",
                fontSize: "14px",
              }}
            />
            <ETButton
              disabled={
                (allChecked?.length <= 0 && true) ||
                PostCopyGuideToPacakgeIsLoading
              }
              // disabled={allChecked?.length <= 0 && true}
              variant={"primary"}
              buttonText="COPY"
              type={"submit"}
            />
          </div>
        </Form>
      </CustomPopup>
      {/* Popup for copy a document property */}
    </div>
  );
};

export default Guides;
