// export const REQUIRED_ERR = "This field is required.";
// export const TERMS_ERR = "You must agree with the terms and conditions.";
// export const NoRecordsToDisplay = "No data available!!";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const REQUIRED_ERR = (
  <div className=" d-flex align-items-center gap-1">
    This field is required.
  </div>
);

export const PASS_REQUIRED_ERR = (
  <div className=" d-flex align-items-center gap-1">
    <FontAwesomeIcon
      icon="fa-solid fa-circle-exclamation "
      size="sm"
      style={{ color: "#e33716" }}
    />{" "}
    Passwords is not matching.
  </div>
);

export const TERMS_ERR = (
  <div className="error-message">
    You must agree with the terms and conditions.
  </div>
);

export const NoRecordsToDisplay = (
  <div className="no-data-message">No data available!!</div>
);
