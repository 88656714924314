import React from "react";
import { CheckboxWrapper } from "./ETSwitch.style";

const Toggle = ({ checked, onChange, value, name, disabled, size, id }) => {
  return (
    <div className="pt-2 ps-2">
      <CheckboxWrapper disabled={disabled}>
        <input
          type="checkbox"
          id={id}
          disabled={disabled}
          value={value}
          // defaultChecked
          size={size}
          name={name}
          checked={checked}
          onChange={onChange}
        />
        <label htmlFor={id} className="toggle">
          <span></span>
        </label>
      </CheckboxWrapper>
    </div>
  );
};

export default Toggle;
