import React, { useEffect, useState } from "react";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import GroupButtons from "../../../../Components/GroupButton/GroupButton";
import { Form } from "react-bootstrap";
import { useAppState } from "../AllState";
import { Controller, useForm } from "react-hook-form";
import { H4 } from "../../../../styles/Common.style";

const CommunalAntenna = ({
  submitRef,
  handleNext,
  prevRef,
  activeTenancyForm,
  getConfigurationDetailsData,
  handleEditConfig,
  previewRef,
}) => {
  const [state, setState] = useAppState();
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({ defaultValues: state[activeTenancyForm] });

  const saveData = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 6, true);
    // handleNext(1);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  const preview = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 6, false);
    // handleNext(1);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  // saving all field into context state
  // const prevSubmit = () => {
  //   // setState({ ...state, ...getValues() });
  //   setState({ ...state, [activeTenancyForm]: { ...getValues() } });
  // };

  useEffect(() => {
    setValue(
      "l_antenna_signal",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "l_antenna_signal"
      )[0]?.actual_value == 2
        ? 1
        : 0
    );
    setValue(
      "t_antenna",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "t_antenna"
      )[0]?.actual_value == 2
        ? 1
        : 0
    );
    setValue(
      "l_internet",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "l_internet"
      )[0]?.actual_value == 2
        ? 1
        : 0
    );
  }, [getConfigurationDetailsData]);

  return (
    <>
      <Form className="bg-white radius-8">
        <div
          className="py-3 px-4"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <H4 className="actors-section-title">
            §6 Communal antenna etc. and access to electronic communications
            services
          </H4>
          <div className="light-font-text grey-text">
            Lorem ipsum dolor sit lorem ipsum dolor sit
          </div>
        </div>
        <div
          className="px-4 py-4"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div className="d-flex align-items-center" style={{ alignItems: "center" }}>
            <span className="form-label-title me-2">Communal antenna</span>{" "}
            <CustomToolTip size="sm" text="Communal antenna" />
          </div>

          <div className="light-font-text me-2 pb-2 pt-1">
            The landlord provides a common signal supply for which the tenant
            has to pay a contribution?
          </div>
          <div className="w-25">
            <Controller
              control={control}
              {...register("l_antenna_signal")}
              render={({ field: { onChange, value } }) => {
                return (
                  <GroupButtons
                    tabs={[
                      {
                        id: 1,
                        tabTitle: "Yes",
                        title: "Yes",
                        content:
                          "This page contains data related to Map milestone",
                      },
                      {
                        id: 2,
                        tabTitle: "No",
                        title: "No",
                        content:
                          "This page contains data related to Chart milestone",
                      },
                    ]}
                    currentTab={value}
                    handleTabClick={(tab) => {
                      setValue("l_antenna_signal", tab);
                    }}
                  />
                );
              }}
            />
          </div>

          <div className="light-font-text me-2 pb-2 pt-3">
            The tenants' antenna association in the building provides a common
            signal supply?
          </div>
          <div className="w-25">
            <Controller
              control={control}
              {...register("t_antenna")}
              render={({ field: { onChange, value } }) => {
                return (
                  <GroupButtons
                    tabs={[
                      {
                        id: 1,
                        tabTitle: "Yes",
                        title: "Yes",
                        content:
                          "This page contains data related to Map milestone",
                      },
                      {
                        id: 2,
                        tabTitle: "No",
                        title: "No",
                        content:
                          "This page contains data related to Chart milestone",
                      },
                    ]}
                    currentTab={value}
                    handleTabClick={(tab) => {
                      setValue("t_antenna", tab);
                    }}
                  />
                );
              }}
            />
          </div>
        </div>

        <div className="py-4 px-4">
          <div className="d-flex align-items-center" style={{ alignItems: "center" }}>
            <span className="form-label-title me-2">Internet</span>{" "}
            <CustomToolTip size="sm" text="Internet" />
          </div>
          <div className="light-font-text  me-2 pb-2 pt-2">
            The landlord provides internet access (electronic communication
            services) for which the tenant has to pay a contribution?
          </div>
          <div className="w-25">
            <Controller
              control={control}
              {...register("l_internet")}
              render={({ field: { onChange, value } }) => {
                return (
                  <GroupButtons
                    tabs={[
                      {
                        id: 1,
                        tabTitle: "Yes",
                        title: "Yes",
                        content:
                          "This page contains data related to Map milestone",
                      },
                      {
                        id: 2,
                        tabTitle: "No",
                        title: "No",
                        content:
                          "This page contains data related to Chart milestone",
                      },
                    ]}
                    currentTab={value}
                    handleTabClick={(tab) => {
                      setValue("l_internet", tab);
                    }}
                  />
                );
              }}
            />
          </div>
        </div>
        <button
          type="submit"
          onClick={handleSubmit(saveData)}
          ref={submitRef}
          style={{ display: "none" }}
        />
        <button
          type="submit"
          onClick={handleSubmit(preview)}
          ref={previewRef}
          style={{ display: "none" }}
        />
      </Form>
      {/* <button ref={prevRef} onClick={prevSubmit} style={{ display: "none" }} /> */}
    </>
  );
};

export default CommunalAntenna;
