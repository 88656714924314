import React, { useRef, useState, useMemo, useEffect } from "react";
import CSVWidget from "../../../Components/DownloadWidget/CSVWidget";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_FullScreenToggleButton,
} from "material-react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Form } from "react-bootstrap";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import CustomPopup from "../../../Components/CustomPopup";
import Menus from "../../../Components/Menus/Menus";
import { Badge, Dialog, Paper } from "@mui/material";
import message from "../../../assets/images/boxopenfullregular.png";
import FilterComponent from "../../../reactdataTableComponent/FilterComponent";
import ETButton from "../../../Components/Buttons/ETButton";
import { useForm, Controller } from "react-hook-form";
import {
  NoRecordsToDisplay,
  REQUIRED_ERR,
} from "../../../utils/validationConstants";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import {
  SearchIcon,
  paginationoptions,
  SearchStyle,
  SortIcon,
} from "../../../Components/table/MRTGlobalProps";
import MRTTheme from "../../../Components/table/MRTTheme";
import { H2, H3 } from "../../../styles/Common.style";

const UnitProgressOverTime = () => {
  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "unit-progress-over-time";

  const options = {
    keys: [
      { field: "R", title: "reserved" },
      { field: "C", title: "Contract sent for signature" },
      { field: "L", title: "Leased" },
      { field: "S", title: "Sold" },
      { field: "address" },
      { field: "unitNo", title: "Unit NO" },
      { field: "property", title: "Property" },
      { field: "room", title: "Room" },
      { field: "msqt", title: "Meter sq." },
      { field: "Yrent", title: "Yearly Rent" },
    ],
  };
  const Expandtable = [
    {
      R: "1",
      C: "0",
      L: "0",
      S: "1",
      address: "Test Jensens Plads 1 1. th.",
      unitNo: "52.02.MF	",
      property: "Køhlers Have",
      room: "5",
      msqt: "115m²",
      Yrent: "220.800 DKK",
    },
    {
      R: "1",
      C: "0",
      L: "0",
      S: "1",
      address: "Fanny viraj supriya 1 1. th.",
      unitNo: "52.02.MF	",
      property: "Køhlers Have",
      room: "5",
      msqt: "115m²",
      Yrent: "220.800 DKK",
    },
    {
      R: "1",
      C: "0",
      L: "0",
      S: "1",
      address: "Fanny parmar Plads 1 1. th.",
      unitNo: "52.02.MF	",
      property: "Køhlers Have",
      room: "5",
      msqt: "115m²",
      Yrent: "220.800 DKK",
    },
    {
      R: "1",
      C: "0",
      L: "0",
      S: "1",
      address: "Fanny parmar Plads 1 1. th.",
      unitNo: "52.02.MF	",
      property: "Køhlers Have",
      room: "5",
      msqt: "115m²",
      Yrent: "220.800 DKK",
    },
    {
      R: "1",
      C: "0",
      L: "0",
      S: "1",
      address: "Fanny parmar Plads 1 1. th.",
      unitNo: "52.02.MF	",
      property: "Køhlers Have",
      room: "5",
      msqt: "115m²",
      Yrent: "220.800 DKK",
    },
    {
      R: "1",
      C: "0",
      L: "0",
      S: "1",
      address: "Fanny parmar Plads 1 1. th.",
      unitNo: "52.02.MF	",
      property: "Køhlers Have",
      room: "5",
      msqt: "115m²",
      Yrent: "220.800 DKK",
    },
    {
      R: "1",
      C: "0",
      L: "0",
      S: "1",
      address: "Fanny parmar Plads 1 1. th.",
      unitNo: "52.02.MF	",
      property: "Køhlers Have",
      room: "5",
      msqt: "115m²",
      Yrent: "220.800 DKK",
    },
    {
      R: "1",
      C: "0",
      L: "0",
      S: "1",
      address: "Fanny parmar Plads 1 1. th.",
      unitNo: "52.02.MF	",
      property: "Køhlers Have",
      room: "5",
      msqt: "115m²",
      Yrent: "220.800 DKK",
    },
    {
      R: "1",
      C: "0",
      L: "0",
      S: "1",
      address: "Fanny parmar Plads 1 1. th.",
      unitNo: "52.02.MF	",
      property: "Køhlers Have",
      room: "5",
      msqt: "115m²",
      Yrent: "220.800 DKK",
    },
    {
      R: "1",
      C: "0",
      L: "0",
      S: "1",
      address: "Fanny parmar Plads 1 1. th.",
      unitNo: "52.02.MF	",
      property: "Køhlers Have",
      room: "5",
      msqt: "115m²",
      Yrent: "220.800 DKK",
    },
  ];
  const data = Expandtable;
  const CSVFunction = CSVWidget({ refObj, fileName, options, data });

  const InnnerExpandableTable = useMemo(
    () => [
      {
        accessorKey: "date",
        Cell: ({ row }) => (
          <>
            <div className="d-flex gap-5 justify-content-between">
              <div className="ps-5">{row.original.date}</div>
              <div
                className="light fw-300 fs-13 "
                style={{
                  // backgroundColor:
                  //   row.ActivityType === 27
                  //     ? "rgba(119, 199, 161, 0.35)"
                  //     : row.ActivityType === 29
                  //     ? "rgba(246, 230, 81, 0.35)"
                  //     : row.ActivityType === 994
                  //     ? "rgba(162, 200, 208, 0.35)"
                  //     : row.ActivityType === 993
                  //     ? "rgba(241, 102, 75, 0.35)"
                  //     : "none",
                  height: "20px",
                  backgroundColor: "rgba(241, 102, 75, 0.35)",
                  position: "relative",
                  width: "20px",
                  borderRadius: "900px",
                  right: "29px",
                }}
              >
                <div className="semi-bold fw-600 fs-10  value">1</div>
              </div>
            </div>
          </>
        ),
        header: <div className="ps-5">Date</div>,
        size: 140,
      },
      {
        accessorKey: "activity",
        header: "Activity",
        size: 300,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "createdby",
        header: "Created by",
        size: 180,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    []
  );

  const InnnerExpandableData = [
    {
      date: "Jun 12, 2023",
      activity: "Reserveret - Reserveret",
      createdby: "Michala Frøslev",
    },
    {
      date: "Jun 12, 2023",
      activity: "Reserveret - Reserveret",
      createdby: "Michala Frøslev",
    },
    {
      date: "Jun 12, 2023",
      activity: "Reserveret - Reserveret",
      createdby: "Michala Frøslev",
    },
    {
      date: "Jun 12, 2023",
      activity: "Reserveret - Reserveret",
      createdby: "Michala Frøslev",
    },
    {
      date: "Jun 12, 2023",
      activity: "Reserveret - Reserveret",
      createdby: "Michala Frøslev",
    },
    {
      date: "Jun 12, 2023",
      activity: "Reserveret - Reserveret",
      createdby: "Michala Frøslev",
    },
  ];
  const Expandcolumns = useMemo(
    () => [
      {
        id: "id",
        enableSorting: false,
        // enableGlobalFilter: true,
        accessorFn: (row) => `${row.name} `, //access nested data with dot notation
        header: (
          <div className="d-flex gap-2">
            <div
              className=" py-0"
              style={{
                // height: "20px",
                // textAlign: "center",
                borderRadius: "4px",
                fontSize: "10px",
                padding: "0px 13.2px 0px 13.2px",
                // width : '20px',
                backgroundColor: "rgba(119, 199, 161, 0.35)",
              }}
            >
              R
            </div>
            {/* <span className="cursor-pointer">
              <FontAwesomeIcon icon="fa-solid fa-sort" color="#bfbfbf" />
            </span> */}
            <div
              className=" py-0"
              style={{
                // height: "20px",
                // textAlign: "center",
                borderRadius: "4px",
                fontSize: "10px",
                padding: "0px 13.2px 0px 13.2px",

                // width : '20px',
                backgroundColor: "rgba(246, 230, 81, 0.35)",
              }}
            >
              C
            </div>
            {/* <span className="cursor-pointer">
              <FontAwesomeIcon icon="fa-solid fa-sort" color="#bfbfbf" />
            </span> */}
            <div
              className=" py-0"
              style={{
                // height: "20px",
                // textAlign: "center",
                borderRadius: "4px",
                fontSize: "10px",
                padding: "0px 13.2px 0px 13.2px",

                // width : '20px',
                backgroundColor: "rgba(162, 200, 208, 0.35)",
              }}
            >
              L
            </div>
            {/* <span className="cursor-pointer">
              <FontAwesomeIcon icon="fa-solid fa-sort" color="#bfbfbf" />
            </span> */}
            <div
              className="py-0"
              style={{
                // height: "20px",
                // textAlign: "center",
                borderRadius: "4px",
                fontSize: "10px",
                padding: "0px 13.2px 0px 13.2px",

                // width : '20px',
                backgroundColor: "rgba(241, 102, 75, 0.35)",
              }}
            >
              S
            </div>
            {/* <span className="cursor-pointer">
              <FontAwesomeIcon icon="fa-solid fa-sort" color="#bfbfbf" />
            </span> */}
          </div>
        ),
        Cell: ({ row, header }) => (
          <>
            <div className="table-first-row-section">
              <div
                style={{ backgroundColor: "rgba(119, 199, 161, 0.35)" }}
                className="semi-bold fw-600 fs-10 column-body-section"
              >
                {row.original.R}
              </div>
              <div
                style={{ backgroundColor: "rgba(246, 230, 81, 0.35)" }}
                className="semi-bold fw-600 fs-10 column-body-section"
              >
                {row.original.C}
              </div>
              <div
                style={{ backgroundColor: "rgba(162, 200, 208, 0.35)" }}
                className="semi-bold fw-600 fs-10 column-body-section"
              >
                {row.original.L}
              </div>
              <div
                style={{ backgroundColor: "rgba(241, 102, 75, 0.35)" }}
                className="semi-bold fw-600 fs-10 column-body-section"
              >
                {row.original.S}
              </div>
            </div>
          </>
        ),
        size: 250,
      },

      {
        accessorKey: "address",
        enableGlobalFilter: true,
        header: "Address",
        size: 220,
      },

      {
        accessorKey: "unitNo", //normal accessorKey
        header: "Unit no.",
        enableGlobalFilter: true,
        size: 50,
      },
      {
        accessorKey: "property", //normal accessorKey
        header: "Property",
        size: 250,
        enableGlobalFilter: true,
      },
      {
        accessorKey: "room",
        header: "Room",
        enableGlobalFilter: true,
        size: 50,
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "msqt",
        header: "m²",
        size: 50,
      },
      {
        accessorKey: "Yrent",
        header: "Yearly rent",
        enableGlobalFilter: true,
        size: 150,
        muiTableHeadCellProps: {
          align: "center",
          sx: {
            paddingRight: "35px",
          },
        },
        muiTableBodyCellProps: {
          align: "right",
          sx: {
            paddingRight: "35px",
          },
        },
      },
    ],
    []
  );

  // const columns = useMemo(
  //   () => [
  //     {
  //       accessorKey: "id",
  //       header: "ID",
  //       size: 50,
  //     },
  //     {
  //       accessorKey: "firstName",
  //       header: "First Name",
  //     },
  //     {
  //       accessorKey: "middleName",
  //       header: "Middle Name",
  //     },
  //     {
  //       accessorKey: "lastName",
  //       header: "Last Name",
  //     },
  //   ],
  //   []
  // );

  //Handle search logic
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const handleSearch = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = Expandtable?.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput?.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(Expandtable);
    }
  };
  useEffect(() => {
    handleSearch(searchInput);
  }, [searchInput]);

  const [count, setCount] = useState(10);
  const [listState, setListState] = useState([]);

  useEffect(() => {
    setListState(filteredResults.slice(0, count));
  }, [filteredResults]);

  const renderEmptyRowsFallback = () => (
    <div className="d-flex justify-content-center w-100 grey-text py-4">
      <div>You do not have any unit yet.</div>
    </div>
  );

  // Add Comments

  const commentsColumns = useMemo(() => [
    {
      header: "Date",
      enableHiding: false,

      size: 50,
      accessorKey: "date",
      // Cell: ({ row }) => (
      //   <>
      //     <div className="d-flex">
      //       <div
      //         className="radius-4 me-3"
      //         style={{
      //           backgroundColor: "#000000",
      //           width: "13px",
      //           height: "13px",
      //           marginTop: "2px",
      //         }}
      //       ></div>
      //       <div>{row.original.status}</div>
      //     </div>
      //   </>
      // ),
      // center align the header and body cells
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "category",
      header: "Category",
      size: 50,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      // accessorKey: "Comments",
      header: "Comments",
      size: 20,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ row }) => (
        <>
          <div>
            <div>{row.original.commentsHead}</div>
            <div>{row.original.comment}</div>
          </div>
        </>
      ),
    },
    {
      accessorKey: "createdby",
      header: "Created by",
      size: 50,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
  ]);

  const CommentsTableData = [
    {
      date: "Jul 6, 2023",
      category: "Answer from Boligsupport",
      commentsHead: "my Comments head",
      comment: "this is dummy Comments",
      createdby: "Didde Duusgaard Espensen",
    },
    {
      date: "Jul 6, 2023",
      category: "Answer from Boligsupport",
      commentsHead: "my Comments head",
      comment: "this is dummy Comments",
      createdby: "Didde Duusgaard Espensen",
    },
    {
      date: "Jul 6, 2023",
      category: "Answer from Boligsupport",
      commentsHead: "my Comments head",
      comment: "this is dummy Comments",
      createdby: "Didde Duusgaard Espensen",
    },
    {
      date: "Jul 6, 2023",
      category: "Answer from Boligsupport",
      commentsHead: "my Comments head",
      comment: "this is dummy Comments",
      createdby: "Didde Duusgaard Espensen",
    },
    {
      date: "Jul 6, 2023",
      category: "Answer from Boligsupport",
      commentsHead: "my Comments head",
      comment: "this is dummy Comments",
      createdby: "Didde Duusgaard Espensen",
    },
    {
      date: "Jul 6, 2023",
      category: "Answer from Boligsupport",
      commentsHead: "my Comments head",
      comment: "this is dummy Comments",
      createdby: "Didde Duusgaard Espensen",
    },
    {
      date: "Jul 6, 2023",
      category: "Answer from Boligsupport",
      commentsHead: "my Comments head",
      comment: "this is dummy Comments",
      createdby: "Didde Duusgaard Espensen",
    },
    {
      date: "Jul 6, 2023",
      category: "Answer from Boligsupport",
      commentsHead: "my Comments head",
      comment: "this is dummy Comments",
      createdby: "Didde Duusgaard Espensen",
    },
    {
      date: "Jul 6, 2023",
      category: "Answer from Boligsupport",
      commentsHead: "my Comments head",
      comment: "this is dummy Comments",
      createdby: "Didde Duusgaard Espensen",
    },
    {
      date: "Jul 6, 2023",
      category: "Answer from Boligsupport",
      commentsHead: "my Comments head",
      comment: "this is dummy Comments",
      createdby: "Didde Duusgaard Espensen",
    },
    {
      date: "Jul 6, 2023",
      category: "Answer from Boligsupport",
      commentsHead: "my Comments head",
      comment: "this is dummy Comments",
      createdby: "Didde Duusgaard Espensen",
    },
    {
      date: "Jul 6, 2023",
      category: "Answer from Boligsupport",
      commentsHead: "my Comments head",
      comment: "this is dummy Comments",
      createdby: "Didde Duusgaard Espensen",
    },
    {
      date: "Jul 6, 2023",
      category: "Answer from Boligsupport",
      commentsHead: "my Comments head",
      comment: "this is dummy Comments",
      createdby: "Didde Duusgaard Espensen",
    },
    {
      date: "Jul 6, 2023",
      category: "Answer from Boligsupport",
      commentsHead: "my Comments head",
      comment: "this is dummy Comments",
      createdby: "Didde Duusgaard Espensen",
    },
    {
      date: "Jul 6, 2023",
      category: "Answer from Boligsupport",
      commentsHead: "my Comments head",
      comment: "this is dummy Comments",
      createdby: "Didde Duusgaard Espensen",
    },
    {
      date: "Jul 6, 2023",
      category: "Answer from Boligsupport",
      commentsHead: "my Comments head",
      comment: "this is dummy Comments",
      createdby: "Didde Duusgaard Espensen",
    },
  ];
  // Open the Comment
  const [openComment, setOpenComment] = useState(false);

  // Add the Comment
  const [addComment, setAddComment] = useState(false);

  const handleAddComments = (data) => {
    console.log(data);
  };

  //  Validation

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      category: "",
      title: "",
    },
  });

  return (
    <>
      {/* <div className="p-3  radius-8" style={{ background: "#FFFFFF" }}> */}
      <div className="card-body p-3">
        {/* <Row className="pb-4">
          <Col className="d-flex align-items-center" sm={8}>
            {" "}
            <div className="d-flex" style={{ alignItems: "center" }}>
              <span className="fs-14 container-title me-2">
                Unit progress over time
              </span>{" "}
              <CustomToolTip height="11px" text="Unit progress over time" />
            </div>
          </Col>
          <Col>
            <FilterComponent
              handleSearch={(e) => handleSearch(e.target.value)}
            />
          </Col>
          <Col sm={1} className="d-flex justify-content-around">
            {" "}
            <Menus
              itemheight={40}
              itemwidth={40}
              itemcontainerheight={200}
              itemcontainerwidth={200}
              component={
                <div className="fs-14">
                  <div className="addpropContent px-3 py-2 ">
                    <span className="ms-1" onClick={() => CSVFunction()}>
                      Download widget as CSV
                    </span>
                  </div>
                </div>
              }
            />
          </Col>
        </Row> */}
        {filteredResults.length != 0 ? (
          <Row>
            <Col className="px-4">
              <MRTTheme
                // colorVariantsForTableRow="odd"

                colorVariantsForTableHead="white"
                colorVariantsForTableData={["odd"]}
                table={
                  <MaterialReactTable
                    columns={Expandcolumns}
                    // icons={{
                    //   // SearchIcon: () => <FontAwesomeIcon icon="fa-regular fa-magnifying-glass" />,
                    //   ArrowDownwardIcon: (props) => (
                    //     <FontAwesomeIcon icon="fa-solid fa-sort" color="#bfbfbf" />
                    //   ),
                    //   ExpandMoreIcon: (props) => (
                    //     <FontAwesomeIcon
                    //       icon="fa-solid fa-angle-right"
                    //       {...props}
                    //       fontSize="20px"
                    //     />
                    //   ),
                    // }}
                    // data={Expandtable}
                    data={[]}
                    icons={{ ...SortIcon }}
                    localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                    // renderEmptyRowsFallback={renderEmptyRowsFallback}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enablePagination={false}
                    enableStickyHeader
                    initialState={{
                      showGlobalFilter: true,
                      showColumnFilters: false,
                    }}
                    enableSorting={true}
                    globalFilterFn="contains"
                    muiTableContainerProps={{
                      sx: {
                        padding: "0px !important",
                      },
                    }}
                    // muiTableBodyCellProps={{
                    //   sx: {
                    //     padding: "0px !important",
                    //   },
                    // }}
                    muiTablePaperProps={{
                      sx: {
                        table: {
                          borderCollapse: "separate",
                          borderSpacing: "0 10px",
                        },
                      },
                    }}
                    muiSearchTextFieldProps={{
                      ...SearchStyle,

                      InputProps: {
                        ...SearchIcon,
                      },
                    }}
                    displayColumnDefOptions={{
                      "mrt-row-expand": {
                        header: "",
                        muiTableHeadCellProps: {
                          display: "",
                        },
                      },
                    }}
                    paginateExpandedRows={false}
                    enableBottomToolbar={false}
                    // enableTopToolbar={false} --data
                    renderTopToolbar={({ table }) => (
                      <>
                        <Row
                          style={{ backgroundColor: "#fff" }}
                          className="pb-4"
                        >
                          <Col className="d-flex align-items-center" sm={8}>
                            {" "}
                            <div
                              className="d-flex"
                              style={{ alignItems: "center" }}
                            >
                              <H3 className=" me-2">Unit progress over time</H3>{" "}
                              <CustomToolTip
                                size="sm"
                                text="Unit progress over time"
                              />
                            </div>
                          </Col>
                          <Col>
                            <MRT_GlobalFilterTextField table={table} />
                            {/* <FilterComponent/> */}
                          </Col>
                          <Col sm={1} className="d-flex justify-content-around">
                            {" "}
                            <Menus
                              itemheight={40}
                              itemwidth={40}
                              itemcontainerheight={200}
                              itemcontainerwidth={200}
                              component={
                                <div className="fs-14">
                                  <div className="addpropContent px-3 py-2 ">
                                    <span
                                      className="ms-1"
                                      onClick={() => CSVFunction()}
                                    >
                                      Download widget as CSV
                                    </span>
                                  </div>
                                </div>
                              }
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                    enableExpanding
                    renderDetailPanel={({ row }) => (
                      <MRTTheme
                        // colorVariantsForTableHead="white"
                        // colorVariantsForTableData={["gray"]}
                        // colorVariantsForTableHead="white"
                        colorVariantsForTableRow="odd"
                        colorVariantsForTableHead="white"
                        table={
                          <MaterialReactTable
                            // sx={{
                            //   ".css-3xs9pb-MuiTableCell-root": {
                            //     padding: "0px !important",
                            //     paddingTop: "0px !important",
                            //   },
                            // }}

                            columns={InnnerExpandableTable}
                            data={InnnerExpandableData}
                            icons={{ ...SortIcon }}
                            enableBottomToolbar={false}
                            enableSorting={false}
                            enableColumnActions={false}
                            enablePagination={false}
                            renderTopToolbar={false}
                            muiTableContainerProps={{
                              sx: {
                                padding: "0px !important",
                              },
                            }}
                            muiTablePaperProps={{
                              elevation: 0,
                              sx: {
                                // "& td:first-child": {
                                //   borderRadius: "4px 0 0 4px !important",
                                // },
                                // "& td:last-child": {
                                //   borderRadius: "0 4px 4px 0 !important",
                                // },
                                // ".css-3xs9pb-MuiTableCell-root": {
                                //   backgroundColor: "#fff",
                                // },
                                // fontFamily: "Cerebri sans !important",
                                // // borderRadius: "0px 0px 8px 8px ",
                                // border: "none",

                                // fontWeight: "300",
                                // ".css-1f2qhs8": {
                                //   minWidth: "auto",
                                //   marginRight: "10px",
                                // },
                                table: {
                                  display: "table",
                                  width: "100%",
                                  minWidth: "650px",
                                  borderCollapse: "separate",
                                  borderSpacing: "0px 8px",
                                  paddingLeft: "11px",
                                  height: "44px",
                                  borderLeft: "1.5px solid rgb(244, 245, 245)",
                                  paddingTop: "0px",
                                  marginTop: "-12px",
                                },
                                // th: {
                                //   // height: "50px",
                                //   // padding: "0.5rem",
                                //   background: "#FFFFFF",
                                //   paddingLeft: "1rem",
                                //   fontFamily: "Cerebri sans !important",
                                // },
                                // td: {
                                //   fontWeight: "300",
                                //   fontSize: "12px",
                                //   paddingLeft: "1rem",
                                //   fontFamily: "Cerebri sans !important",
                                // },
                                // tr: {
                                //   backgroundColor: "#f9fafa !important",
                                //   borderRadius: "4px",
                                //   border: "2px solid red !important",
                                //   fontFamily: "Cerebri sans !important",
                                //   position: "relative",
                                // },
                                // dispaly: "block",
                                // "MuiTableRow-root::before": {
                                //   // position: 'absolute',

                                //   // width: '40px',
                                //   // height: '40px',
                                //   // background: 'red'
                                //   boxSizing: "contentBox",
                                //   position: "absolute",
                                //   color: "blue",
                                //   content: '""',
                                //   width: "10px",
                                //   height: "0.07px",
                                //   background: "red",
                                //   // background: '#f4f5f5',
                                //   left: "-11px",
                                //   top: "50%",
                                // },
                                // ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
                                //   display: "none", // pagination space removing from page
                                // },
                              },
                            }}
                            renderRowActions={({ row, table }) => (
                              <div className="ps-4">
                                <Badge
                                  overlap="circular"
                                  className="cursor-pointer"
                                  onClick={() => setOpenComment(true)}
                                  badgeContent={2}
                                  sx={{
                                    "& .MuiBadge-badge": {
                                      fontWidth: 700,
                                      lineHeight: "20px",
                                      fontSize: 9.5,
                                      height: "19px",
                                      width: " 12px",
                                      borderRadius: "100%",
                                      backgroundColor: "#619130",
                                      color: "#fff",
                                      zIndex: 0,
                                    },
                                  }}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon="fa-regular fa-comment"
                                    size="2xl"
                                  />
                                </Badge>

                                {/* for no comments the below icon will be use */}

                                {/* <FontAwesomeIcon
           icon="fa-regular fa-comment-plus"
           size="2xl"
         /> */}
                              </div>
                            )}
                            positionActionsColumn="last"
                            // muiTableBodyProps={{
                            //   sx: {
                            //     fontFamily: "Cerebri sans !important",
                            //     // "& tr:nth-of-type(odd)": {
                            //     backgroundColor: "#F9FA",
                            //     // borderRadius: "8px",
                            //     // fontWeight: "300",
                            //     // },
                            //   },
                            // }}
                            // muiTableContainerProps={{
                            //   elevation: 0,
                            //   sx: {
                            //     maxHeight: "400px",
                            //     background: "#FFFFFF",
                            //     paddingLeft: "18px",
                            //     borderRadius: "0px 0px 8px 8px ",

                            //     boxShadow: "none",
                            //     ".css-vv34gr-MuiTableRow-root": {
                            //       boxShadow: "unset !important",
                            //     },
                            //   },
                            // }}
                            // muiTableHeadCellProps={{
                            //   //simple styling with the `sx` prop, works just like a style prop in this example
                            //   sx: {
                            //     fontWeight: "700",
                            //     fontSize: "12px",
                            //     fontFamily: "Cerebri sans !important",
                            //   },
                            // }}
                            enableRowActions
                            displayColumnDefOptions={{
                              "mrt-row-actions": {
                                header: "Comments",
                              },
                              "mrt-row-select": {
                                header: "",
                              },
                            }}
                          />
                        }
                      />
                    )}
                    enableExpandAll={false}
                    getRowId={(row) => row.userId}
                    getSubRows={(originalRow) => originalRow.subRows}
                    // muiTablePaperProps={{
                    //   elevation: 0,
                    //   sx: {
                    //     // ".css-vv34gr-MuiTableRow-root": {
                    //     //   boxShadow: 'none !important',
                    //     // },
                    //     // "& .MuiOutlinedInput-root": {
                    //     //   height: 40,
                    //     //   background: "white !important",
                    //     //   borderRadius: "8px",
                    //     //   position: "end",
                    //     //   fontSize: 14,
                    //     //   fontWeight: 300,
                    //     //   fontFamily: "Cerebri Sans",
                    //     //   "& fieldset": {
                    //     //     fontSize: "12px",
                    //     //     borderRadius: "8px",
                    //     //     borderColor: "#D1D1D1",
                    //     //   },
                    //     //   "&:hover fieldset": {
                    //     //     borderColor: "#D1D1D1",
                    //     //     boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
                    //     //   },
                    //     //   "&.Mui-focused fieldset": {
                    //     //     border: "1px solid #D1D1D1",
                    //     //   },
                    //     // },
                    //     // "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
                    //     //   borderRadius: "8px !important",
                    //     // },

                    //     // "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused":
                    //     //   {
                    //     //     "& > fieldset": {
                    //     //       borderColor: "#f4f5f5",
                    //     //     },
                    //     //   },

                    //     // "& td:first-child": {
                    //     //   borderRadius: "8px 0 0 8px",
                    //     // },
                    //     // "& td:last-child": {
                    //     //   borderRadius: "0 8px 8px 0",
                    //     // },
                    //     // fontFamily: "Cerebri sans !important",
                    //     // borderRadius: "0px 0px 8px 8px ",
                    //     // border: "none",
                    //     // background: "#F9FAFA",
                    //     // fontWeight: "300",
                    //     ".css-1f2qhs8": {
                    //       minWidth: "auto",
                    //       marginRight: "10px",
                    //     },
                    //     // th: {
                    //     //   height: "50px",
                    //     //   padding: "0.5rem",
                    //     //   fontSize: "13px",
                    //     //   background: "#FFFFFF",
                    //     //   paddingLeft: "1rem",
                    //     //   fontFamily: "Cerebri sans !important",
                    //     // },
                    //     // td: {
                    //     //   height: "0px",
                    //     //   fontWeight: "300",
                    //     //   fontSize: "12px",
                    //     //   // backgroundColor: "#F9FAfa",
                    //     //   paddingLeft: "1rem",
                    //     //   fontFamily: "Cerebri sans !important",
                    //     // },
                    //     // table: {
                    //     //   backgroundColor: "#fff",
                    //     // },
                    //     // tr: {
                    //     //   borderRadius: "4px",
                    //     //   border: "2px solid red !important",
                    //     //   fontFamily: "Cerebri sans !important",
                    //     // },
                    //     // ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
                    //     //   display: "none", // pagination space removing from page
                    //     // },
                    //   },
                    // }}
                    muiTableBodyProps={{
                      sx: {
                        // "& tr:nth-child(4n+3)": {
                        //   backgroundColor: "#ffffff !important",
                        // },
                        // fontFamily: "Cerebri sans !important",
                        // "& tr:nth-of-type(odd)": {
                        //   backgroundColor: "#F9FAFA",
                        //   borderRadius: "8px",
                        //   "& td:first-child": {
                        //     borderRadius: "8px 0 0 8px",
                        //   },
                        //   "& td:last-child": {
                        //     borderRadius: "0 8px 8px 0",
                        //   },
                        // },
                      },
                    }}
                    // muiTableHeadCellProps={{
                    //   //simple styling with the `sx` prop, works just like a style prop in this example
                    //   sx: {
                    //     ".css-68rqdf": {
                    //       display: "flex !important",
                    //       gap: "6px !important",
                    //     },
                    //     fontWeight: "600",
                    //     fontSize: "12px",
                    //     fontFamily: "Cerebri sans !important",
                    //     backgroundColor: "#F9FAFA",
                    //   },
                    // }}
                  />
                }
              />
            </Col>
            <div className="unit-process-footer">
              <div className="footer-section">
                <div
                  className="footer-color-section"
                  style={{ backgroundColor: "#77C7A1" }}
                ></div>
                <div className=" table-property-name fs-12">Reservations</div>
              </div>
              <div className="footer-section">
                <div
                  className="footer-color-section"
                  style={{ backgroundColor: "#F6E651" }}
                ></div>
                <div className=" table-property-name fs-12">
                  Contract sent for signature
                </div>
              </div>
              <div className="footer-section">
                <div
                  className="footer-color-section"
                  style={{ backgroundColor: "#A2C8D0" }}
                ></div>
                <div className=" table-property-name fs-12">Leased</div>
              </div>
              <div className="footer-section">
                <div
                  className="footer-color-section"
                  style={{ backgroundColor: "#F1664B" }}
                ></div>
                <div className=" table-property-name fs-12">Sold</div>
              </div>
            </div>
          </Row>
        ) : (
          <NoDataScreen height={"377px"} imgHeight={"150px"} />
        )}

        <CustomPopup
          open={openComment}
          onHide={() => setOpenComment(false)}
          title={<div className="font-bold fs-14">Comments</div>}
          title_color="black"
          titleFont="14px"
          titleTooltip={true}
          enableCrossButton={true}
          titleTooltipText="Comments"
          size="xl"
          onCloseAction={() => {
            setOpenComment(false);
            alert("Action Peformed Successfuly.");
          }}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
        >
          <div className="p-4">
            <Row>
              <Col></Col>
              <Col lg={3}>
                <ETButton
                  variant={"primary"}
                  icon={true}
                  buttonText="ADD COMMENTS"
                  width={"100%"}
                  onClick={() => {
                    setAddComment(true);
                    setOpenComment(false);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <MaterialReactTable
                  columns={commentsColumns}
                  enableStickyHeader
                  muiTableContainerProps={{ sx: { maxHeight: "400px" } }}
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      header: "",
                    },
                    "mrt-row-select": {
                      header: "",
                    },
                  }}
                  data={CommentsTableData}
                  icons={{ ...SortIcon }}
                  enableTopToolbar={false}
                  enableBottomToolbar={false}
                  enableSelectAll={false}
                  enableColumnActions={false}
                  enableColumnFilter={false}
                  enableRowActions
                  renderRowActions={({ row }) => (
                    <div
                      className="d-flex justify-content-center pe-3"
                      style={{ width: "140%" }}
                    >
                      <Menus
                        itemheight={32}
                        itemwidth={32}
                        iconborderradius="4px"
                        itemcontainerwidth="180px"
                        component={
                          <>
                            <div style={{ fontSize: "14px" }}>
                              <div className="addpropContent px-3 pt-2 pb-2 ">
                                <span className="ms-1">Delete</span>
                              </div>
                            </div>
                          </>
                        }
                      />
                    </div>
                  )}
                  positionActionsColumn="last"
                  enableResizing={false}
                  initialState={{ showGlobalFilter: true }}
                  muiTableHeadCellProps={{
                    elevation: 0,
                    sx: {
                      fontWeight: "600",
                      fontSize: "13px",
                      fontFamily: "Cerebri sans !important",
                      boxShadow: "none",
                    },
                  }}
                  muiTableBodyRowProps={{
                    fontFamily: "Cerebri Sans",
                    fontSize: "13px",
                  }}
                  muiTableBodyProps={{
                    sx: {
                      fontFamily: "Cerebri Sans",
                      //stripe the rows, make odd rows a darker color
                      "& tr:nth-of-type(odd)": {
                        backgroundColor: "#F9FAFA",
                      },
                      td: {
                        fontFamily: "Cerebri Sans",
                        fontSize: "13px",
                        fontWeight: 300,
                      },
                    },
                  }}
                  muiTablePaperProps={{
                    elevation: 0, //change the mui box shadow
                    //customize paper styles
                    sx: {
                      borderRadius: "0",
                      // padding: "1rem",
                    },
                  }}
                  // muiTableContainerProps={{
                  //   sx: {
                  //     paddingX: "3px",
                  //     paddingTop: "10px",
                  //   },
                  // }}
                  muiTableHeadRowProps={{
                    sx: { boxShadow: "none" },
                  }}
                  enableColumnFilterModes={false}
                />
              </Col>
            </Row>
          </div>
        </CustomPopup>

        <CustomPopup
          open={addComment}
          onHide={() => setAddComment(false)}
          title={<div className="font-bold fs-14">Add Comment</div>}
          title_color="black"
          titleFont="14px"
          titleTooltip={false}
          enableCrossButton={true}
          size="xl"
          onCloseAction={() => {
            setAddComment(false);
            alert("Action Peformed Successfuly.");
          }}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
        >
          <Form onSubmit={handleSubmit(handleAddComments)}>
            <div className="p-4">
              <Row>
                <Col lg={2}>
                  <div className="Cerebri-sans-medium fs-14 pt-2">Category</div>
                </Col>
                <Col lg={5}>
                  <Controller
                    name="category"
                    {...register("category")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder="Category"
                        value={value}
                        clearable={false}
                        isSearchable
                        //   onChange={(e) => {
                        //     onChange(e);
                        //     handleZip(e);
                        //   }}
                        error={errors.zip}
                        //   options={zipcodeData?.data?.map((zip) => ({
                        //     value: zip?.Id,
                        //     label: `${zip?.Id} ${zip?.Text}`,
                        //   }))}
                      />
                    )}
                  />
                  {errors?.category && (
                    <p
                      className="error-msg"
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </Col>
              </Row>
              <Row className="pt-5">
                <Col lg={2}>
                  <div className="Cerebri-sans-medium fs-14 pt-2">Title</div>
                </Col>
                <Col lg={5}>
                  <Controller
                    name="title"
                    {...register("title")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div className="form-input ">
                        <input
                          type="text"
                          placeholder="title"
                          id="fName"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("fName");
                          }}
                        />
                        <label htmlFor="fName">Title</label>
                      </div>
                    )}
                  />
                  {errors?.title && (
                    <p
                      className="error-msg"
                      style={{
                        color: "red",
                      }}
                    >
                      {REQUIRED_ERR}
                    </p>
                  )}
                </Col>
              </Row>
              <Row className="pt-5 pb-5">
                <Col lg={2}>
                  <div className="Cerebri-sans-medium fs-14 pt-2">Comments</div>
                </Col>
                <Col lg={5}>
                  <div className="form-input ">
                    <textarea
                      className="actor-signing-package-textarea w-100 px-3 pt-2"
                      style={{
                        resize: "none",
                        border: "1px solid #D1d1d1",
                        borderRadius: "4px",
                        height: "200px",
                      }}
                      placeholder="Comments"
                    ></textarea>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <div
                className="d-flex justify-content-end px-4 py-2"
                style={{ backgroundColor: "#f4f5f5" }}
              >
                <div></div>
                <div>
                  <div>
                    <ETButton
                      variant={"primary"}
                      buttonText="ADD"
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </CustomPopup>
      </div>
    </>
  );
};

export default UnitProgressOverTime;
