import React from "react";
import ConfirmCancelSigningOrder from "../../standardComponents/cancelSigningOrder/ConfirmCancelSigningOrder";
import { H3, H5 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { Col, Row } from "react-bootstrap";
import InputField from "../../../../Components/Textfield/InputField";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";

const ConfirmCancellation = ({
  handleBack,
  handleNext,
  switchForCancelCurrentAppointment,
  switchForCreateNewAppointment,
}) => {
  // ------------------------  react hooks forms--------------------//
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({});

  // console.log(
  //   switchForCancelCurrentAppointment,
  //   "switchForCancelCurrentAppointment"
  // );
  // console.log(switchForCreateNewAppointment, "switchForCreateNewAppointment");
  return (
    <div>
      {/* ------ Cancel invitation to the customer -------- */}
      <div className="p-4 border-bottom">
        <div className="d-flex align-items-center gap-2">
          <H3>Cancel invitation to the customer</H3>
          <CustomToolTip text={"Cancel invitation to the customer"} />
        </div>
        <div className="pt-3">
          <Row>
            <Col xl={8} className="light-font-text">
              Since you have chosen to cancel the current appointment with the
              customer when moving the customer to another property or unit, the
              system will send a confirmation regarding this. Below you can
              customise the email that is sent to the customer.
            </Col>
          </Row>
        </div>
      </div>

      {/* ----------------- Details about the proposed event ---------------------- */}
      <div className="p-4 border-bottom">
        <H5>Details about the proposed event</H5>
        <div className="py-1">
          <Row>
            <Col xl={2} className="py-2 pe-0">
              {" "}
              <Controller
                control={control}
                {...register("eventDate")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <InputField
                      id={"eventDate"}
                      value={value}
                      type={"date"}
                      placeholder={"Date"}
                      iserror={errors.eventDate}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("eventDate");
                      }}
                    />
                  );
                }}
              />
            </Col>
            <Col xl={2} className="py-2 pe-0">
              {" "}
              <Controller
                control={control}
                {...register("startTime")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder={""}
                    iserror={errors?.startTime}
                    // options={countryData?.data?.map((item) => ({
                    //   value: item?.Id,
                    //   label: item?.DataValue2,
                    // }))}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("startTime");
                    }}
                  />
                )}
              />
            </Col>
            <Col xl={2} className="py-2 pe-0">
              {" "}
              <Controller
                control={control}
                {...register("endTime")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder={""}
                    iserror={errors?.endTime}
                    // options={countryData?.data?.map((item) => ({
                    //   value: item?.Id,
                    //   label: item?.DataValue2,
                    // }))}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("endTime");
                    }}
                  />
                )}
              />
            </Col>
          </Row>
        </div>

        <div className="pb-3 light-font-text">
          External title which will be shown to the customer{" "}
        </div>
        <Row>
          <Col lg={7} md={8}>
            <Controller
              control={control}
              {...register("ExternalEventTitle")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => {
                return (
                  <InputField
                    id={"ExternalEventTitle"}
                    value={value}
                    type={"text"}
                    placeholder={"External event title"}
                    iserror={errors.ExternalEventTitle}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("ExternalEventTitle");
                    }}
                  />
                );
              }}
            />
          </Col>
        </Row>
      </div>
      <ConfirmCancelSigningOrder
        handleBack={handleBack}
        handleCreate={handleNext}
        usedFor="MCTAU" //Move customer to another unit
        buttonText={
          switchForCancelCurrentAppointment == true &&
          switchForCreateNewAppointment == false
            ? "Confirm"
            : "NEXT"
        }
        // propertyId={propertyId}
        // unitId={unitId}
      />
    </div>
  );
};

export default ConfirmCancellation;
