import React, { useEffect, useState } from "react";
import CustomToolTip from "../../../../../../Components/Tooltip/CustomTooltip";
import ETButton from "../../../../../../Components/Buttons/ETButton";
import {
  Tab,
  TabList,
  Tabs,
} from "../../../../../../Components/Tabs/ReactTabs.style";
import moment from "moment";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import { usePropertyOverview } from "../../../../../../hooks/stores/usePropertyStore";
import { GetEditUnitText } from "../../../../../../services/Texts";
import SortingFunction from "../../../../../../Components/Sorting/SortingFunction";
import { useBreadcrumb } from "../../../../../../Components/BreadcrumbContext ";
import { H2, H3 } from "../../../../../../styles/Common.style";
import { useUserDetails } from "../../../../../../hooks/stores/useUserDetailsStore";
import { UTCtoLocalTimeZone } from "../../../../../../utils/UTCtoLocalTimeZone";

const EditTexts = ({ UnitId, propId }) => {
  const [textCategory, setTextCategory] = useState(0);
  console.log(
    "🚀 ~ file: EditTexts.js:20 ~ EditTexts ~ textCategory:",
    textCategory
  );
  const [textData, setTextData] = useState([]);
  const propOverview = usePropertyOverview((state) => state.propOverview);
  const { data: textpackagesData } = useQuery(
    ["getTextPackage", propOverview?.[0]?.property_id, textCategory, UnitId],
    () => GetEditUnitText(propOverview?.[0]?.property_id, textCategory, UnitId),
    { enabled: !!propOverview?.[0]?.property_id }
  );
  useEffect(() => {
    setTextData(textpackagesData?.data);
  }, [textpackagesData?.data]);
  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    if (textCategory == 0) {
      updateBreadcrumbs(["Texts", "All"]);
    } else if (textCategory == 1) {
      updateBreadcrumbs(["Texts", "Tenancy agreement"]);
    } else if (textCategory == 2) {
      updateBreadcrumbs(["Texts", "Website"]);
    } else if (textCategory == 3) {
      updateBreadcrumbs(["Texts", "Ei.Do"]);
    }
  }, [textCategory]);

  console.log("ET", textData);

  const { userDetails } = useUserDetails((state) => state);
  const { convertedDate: createdOn } = UTCtoLocalTimeZone({
    timestamp: textData?.[0]?.created_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });
  const { convertedDate: modifiedOn } = UTCtoLocalTimeZone({
    timestamp: textData?.[0]?.modified_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });

  return (
    <div className="bg-white radius-8 fs-14  mainborder">
      {" "}
      <div className="d-flex justify-content-between px-4 pt-3">
        <div className="d-flex flex-column ">
          <div className="d-flex  align-items-center blackColor ">
            <H2>Texts</H2>
            <span className="ms-2 ">
              <CustomToolTip
                size="md"
                text="Text"
              />
            </span>
          </div>
        </div>
      </div>
      <Tabs className="pb-1 d-flex flex-column">
        <div
          className="d-flex px-3 mt-3 py-3 justify-content-between"
          style={{
            width: "100%",
            flexDirection: "row",
            flexWrap: "wrap",
            borderBottom: "1px solid #F0F0F0",
            borderTop: "1px solid #F0F0F0",
          }}
        >
          <div>
            <TabList
              className=" d-flex light px-2"
              style={{ gap: "7px" }}
            >
              <Tab onClick={() => setTextCategory(0)}>All</Tab>
              <Tab onClick={() => setTextCategory(1)}>Tenancy agreement</Tab>
              <Tab onClick={() => setTextCategory(2)}>Website</Tab>
              <Tab onClick={() => setTextCategory(3)}>Ei.Do</Tab>
            </TabList>
          </div>
        </div>
        <div className="p-4 pb-0">
          <SortingFunction
            data={textpackagesData?.data}
            setData={setTextData}
            sortingByName={"internal_title"}
            sortById={"property_text_id"}
          />
        </div>
        <div
          className="d-flex px-4"
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            gap: "13px",
          }}
        >
          {/* {textpackagesData?.data?.map((data, index) => (
                <TextCard
                  textDetailsData={data}
                  textpackagesDataRefetch={textpackagesDataRefetch}
                  key={index}
                  setIsEditTextField={setIsEditTextField}
                  setShowCreateNewTextField={setShowCreateNewTextField}
                  propertyId={propertyOverview?.[0]?.property_id}
                  setTextPackageId={setTextPackageId}
                  getAllpropertiesList={getAllpropertiesList}
                  textPackageId={textPackageId}
                />
              ))} */}

          {textpackagesData?.data == 0 && textCategory == 3 ? (
            <div className="d-flex justify-content-center w-100 fs-12 mt-1 grey-text">
              <div>You do not have any created Ei.Do package yet. </div>{" "}
            </div>
          ) : (
            <>
              {textData?.length == 0 && textCategory == 2 ? (
                <div className="d-flex justify-content-center w-100 fs-12 mt-1 grey-text">
                  <div>You do not have any created Website package yet. </div>{" "}
                </div>
              ) : (
                <>
                  {" "}
                  {textData?.length == 0 && textCategory == 1 ? (
                    <div className="d-flex justify-content-center w-100 fs-12 mt-1 grey-text">
                      <div>
                        You do not have any created tenancy agreement package
                        yet. yet.{" "}
                      </div>{" "}
                    </div>
                  ) : (
                    <>
                      {" "}
                      {textData?.length == 0 && textCategory == 0 ? (
                        <div className="d-flex justify-content-center w-100 fs-12 mt-1 grey-text">
                          <div>
                            You do not have any created text package yet.{" "}
                          </div>{" "}
                        </div>
                      ) : (
                        <>
                          {" "}
                          {textData?.map((data, index) => (
                            <div>
                              <div
                                className="my-4"
                                style={{
                                  backgroundColor: "#F9FAFA",
                                  width: "370px",
                                  borderRadius: "6px",
                                  border: "1px solid #F0F0F0",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "20px",
                                    borderBottom: "1px solid #F0F0F0",
                                  }}
                                >
                                  <div
                                    style={{
                                      border: "1px solid #F0F0F0",
                                      borderRadius: "6px",
                                      fontSize: "12px",
                                      fontFamily: "Cerebri sans-light",
                                      background: "#FFFFFF",
                                      textAlign: "justify",
                                      height: "150px",
                                      lineHeight: "17px",
                                      position: "relative",
                                      width: "100%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        position: "absolute",
                                        height: "100%",
                                        padding: "16px",
                                        width: "100%",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                      }}
                                    >
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: data?.text_value,
                                        }}
                                      ></div>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="d-flex"
                                  style={{
                                    flexDirection: "column",
                                    gap: "10px",
                                    padding: "20px",
                                  }}
                                >
                                  <div>
                                    <div
                                      className="form-label-title "
                                      style={{ fontSize: "12px" }}
                                    >
                                      Internal title
                                    </div>
                                    <div
                                      style={{ fontSize: "12px" }}
                                      className=" light-font-text"
                                    >
                                      {data.internal_title}
                                    </div>
                                  </div>
                                  <div>
                                    <div
                                      className="form-label-title"
                                      style={{ fontSize: "12px" }}
                                    >
                                      Language
                                    </div>
                                    <div className="d-flex gap-2">
                                      <div className="d-flex">
                                        <div
                                          className=" light-font-text"
                                          style={{
                                            fontSize: "12px",
                                            paddingTop: "3px",
                                            marginRight: "5px",
                                          }}
                                        >
                                          {data.language}
                                          {/* {textDetailsData?.language?.split("|", 1)}&nbsp;
                    {textDetailsData?.language?.split("|").splice(1, 2)} */}
                                          {/* {data?.language.split("|").splice(1, 2)} */}
                                        </div>
                                        {/* {data?.language.split("|", 1) == "English" ? (
                        <div>
                          <DenmarkFlag />
                        </div>
                      ) : (
                        <div>
                          <Englandflag />
                        </div>
                      )} */}
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <div
                                      className="form-label-title"
                                      style={{ fontSize: "12px" }}
                                    >
                                      Category
                                    </div>

                                    <div
                                      className=" light-font-text"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {data.category_name}
                                    </div>
                                  </div>

                                  <div>
                                    <div
                                      className="form-label-title"
                                      style={{ fontSize: "12px" }}
                                    >
                                      Tag
                                    </div>

                                    <div
                                      className=" light-font-text"
                                      style={{ fontSize: "12px" }}
                                    >
                                      <span>
                                        {data?.source?.split("| ").join(", ")}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="mt-3 form-label-title justify-content-between ">
                                    {data?.created_by && data?.created_on ? (
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          paddingTop: "3px",
                                        }}
                                      >
                                        Created by
                                        <div className="created-by">
                                          {data?.created_by} , {createdOn}
                                        </div>
                                      </div>
                                    ) : (
                                      "-"
                                    )}
                                    {data?.modified_by && data?.modified_on ? (
                                      <div
                                        className="form-label-title"
                                        style={{
                                          fontSize: "12px",
                                          paddingTop: "3px",
                                        }}
                                      >
                                        Last updated by
                                        <div className="created-by">
                                          {data?.modified_by} , {modifiedOn}
                                        </div>
                                      </div>
                                    ) : (
                                      "-"
                                    )}

                                    {/* <div>
                <Menus
                  itemheight={23}
                  itemwidth={23}
                  iconborderradius="4px"
                  component={
                    <div style={{ fontSize: "14px" }}>
                      <div
                        className="addpropContent px-3 py-2"
                        
                      >
                        <span className="ms-1">Edit</span>
                      </div>
                      <hr className="addpropertyHr m-0"></hr>
                      <div
                        className="addpropContent px-3 py-2"
                        onClick={() => {
                          setTextPackageId(textDetailsData?.property_text_id);
                          setOpenDuplicatePackageModal(true);
                        }}
                      >
                        <span className="ms-1">Duplicate</span>
                      </div>
                      <hr className="addpropertyHr m-0"></hr>
                      <div
                        onClick={() => {
                          handleDeleteText(textDetailsData?.property_text_id);
                        }}
                        className="addpropContent px-3 pt-2 pb-2 "
                      >
                        <span className="ms-1">Delete</span>
                      </div>
                    </div>
                  }
                />
              </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Tabs>
      <div className="py-2 px-3 mt-2 d-sm-flex justify-content-start footer-section-button-container">
        <div className="m-1 ps-1">
          <NavLink
            to="/property-units/units/residential"
            state={{ propId: propId, Tab: "Units" }}
          >
            <ETButton
              variant={"white"}
              buttonText="BACK TO UNITS"
            />
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default EditTexts;
