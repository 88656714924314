import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

//for company page flag
export const useShowCompanyPage = create((set, get) => ({
  showCompanyPage: "CL",
  setShowCompanyPage: (data) => set({ showCompanyPage: data }),
}));

export const useCompanyId = create(
  persist(
    (set, get) => ({
      companiesId: "",
      setCompaniesId: (data) => set({ companiesId: data }),
    }),
    {
      name: "companiesId", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

//for company data
export const useCompany = create((set) => ({
  companyData: [],
  setCompanyData: (data) => set({ companyData: data }),
}));

//Used for edit company to set organization Id
export const useEditCompany = create(
  persist(
    (set, get) => ({
      organizationId: "",
      setOrganizationId: (data) => set({ organizationId: data }),
      companyId: "",
      setCompanyId: (data) => set({ companyId: data }),
    }),
    {
      name: "organizationId", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
      name: "companyId",
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
