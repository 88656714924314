import React, { useMemo, useState } from "react";
import ETButton from "../../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../../Components/CustomPopup";
import { Col, Row } from "react-bootstrap";
import Toggle from "../../../../../Components/Switch/Switch";
import MRTTheme from "../../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";

// import DateRangeFilter from '../../../../../Components/DateRangePicker/DateRangeFilter';
import {
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../../../Components/table/MRTGlobalProps";
import TagToolTip from "../../../../../Components/Tooltip/PropertyTagToolTip";
import { H4, H5 } from "../../../../../styles/Common.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSelect from "../../../../../Components/DropDown/ReactSelect";
import Menus from "../../../../../Components/Menus/Menus";
import { ApproveScreeningData } from "../../../../overview/StaticData";

const MultipleApproveScreening = ({
  setMultipleApproveScreening,
  multipleApproveScreening,
}) => {
  const columns = useMemo(() => [
    {
      accessorKey: "Name",
      header: "Name",
      enableHiding: false,
      // size: 20,
      enableGlobalFilter: true,
      Cell: ({ row }) => (
        <div className="d-flex align-items-center gap-2 cursor-pointer  justify-content-between">
          <TagToolTip
            iconColor={"#888888"}
            size={"lg"}
            border="1px solid #F0F0F0"
            padding={"0px"}
          />
          {row.original.name}
        </div>
      ),
    },

    {
      accessorKey: "Screening Started",
      header: "Screening Started ",
      enableHiding: false,
      size: 200,
      enableGlobalFilter: false,
      Cell: ({ row }) => (
        <div className="d-flex gap-2">
          <div>{row.original.screeningstarted}</div>
        </div>
      ),
    },
    {
      accessorFn: (row) => `${row.funnelStatus} ${row.funnelStatus}`,
      header: "Screening Deadline",
      enableHiding: false,
      // size: 260,
      enableGlobalFilter: false,
      Cell: ({ row }) => (
        <div className="d-flex gap-2">
          <div>{row.original.deadlinestarted}</div>
        </div>
      ),
    },

    {
      accessorFn: (row) => `${row.responsibleAgent}`,
      header: "Responsible agent",
      enableHiding: false,
      // size: 200,
      enableGlobalFilter: false,
      Cell: ({ row }) => <div>{row?.original?.responsibleAgent}</div>,
    },
    {
      accessorFn: (row) => `${row.status}`,
      header: "Status",
      enableHiding: false,
      // size: 200,
      enableGlobalFilter: false,
      Cell: ({ row }) => <div>{row?.original?.status}</div>,
    },
    {
      accessorFn: (row) => `${row.action}`,
      header: "Action",
      enableHiding: false,
      // size: 200,
      enableGlobalFilter: false,
      Cell: ({ row }) => (
        <div
        // className='d-flex justify-content-center align-items-center fs-12 btn btn-outline-secondary'
        // style={{ border: "1px solid #D1D1D1", borderRadius: "4px", padding: "4px" }}
        >
          <ETButton buttonText={"Decline Screening"} variant={"white"} />
          {/* <strong>{row.original?.action}</strong> */}
        </div>
      ),
    },
  ]);
  return (
    <div>
      <CustomPopup
        open={multipleApproveScreening}
        onHide={() => setMultipleApproveScreening(false)}
        title={"Approve Screening"}
        extraInfo={true}
        extraInfoText={
          <div className="light-font-text ">
            "Kaktus Towers <br />
            Dybbølsbrovej 13, 12, door 3 I 212-004"
          </div>
        }
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        size={"xl"}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="">
          <div className="px-4 pt-4">
            <Row className="mt-2 mb-4">
              <H4>Customer you whose screening you would like to approve:</H4>
              <Col className="light-font-text">
                {" "}
                Nikolas Rasmussen, +45 44 31 66 12, nikolas.rasmussen@live.dk
              </Col>
            </Row>
          </div>
          <hr className="hr m-0 p-0"></hr>

          <div className="p-4 light-font-text">
            <Row className="mt-2 mb-4 ">
              <Col>
                {" "}
                Once you approve this screening, you can choose to send the
                agreement to the customer immediately.
              </Col>
              <span className="d-flex align-items-center p-0">
                <Toggle />
                <div className="ps-3">Send agreement to customer now</div>
              </span>
            </Row>
            <Row className="light-font-text">
              <Col>
                You find the agreement and the signing order on the customer's
                contract space.
              </Col>
            </Row>
          </div>
        </div>
        <hr className="hr m-0 p-0"></hr>
        <div className="px-4 pt-4">
          <Row className="mt-2 mb-4">
            <H4>Pay Attention!</H4>
            <Col className="light-font-text">
              {" "}
              You are trying to approve a screening on a unit where there are
              other screenings in progress.
              <br />
              Select what to do with the other screenings before continuing, if
              you dont decline them they will continue in the stage they are in
              right now and you can move them to another unit afterwards.
            </Col>
          </Row>
        </div>
        <div className="px-4 pt-2 pb-5">
          <div>
            <MRTTheme
              colorVariantsForTableRow={["gray"]}
              colorVariantsForTableHead={["white"]}
              table={
                <MaterialReactTable
                  columns={columns}
                  // data={ApproveScreeningData} //data is undefined on first render

                  data={[]}
                  icons={{ ...SortIcon }}
                  //   localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                  enableColumnActions={false}
                  enableTopToolbar={true}
                  enableRowSelection={false}
                  enableRowActions
                  enableSelectAll
                  enablePinning
                  //   onColumnPinningChange={setColumnPinning}
                  //   state={{ columnPinning }}
                  enableFilterMatchHighlighting={true}
                  enableGlobalFilter
                  globalFilterFn="contains"
                  initialState={{
                    showGlobalFilter: true,
                    showColumnFilters: false,
                  }}
                  muiTableContainerProps={{
                    sx: {
                      padding: "0px !important",
                    },
                  }}
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      header: "",
                      size: 50,
                      // muiTableBodyCellProps: {
                      //   sx: {
                      //     // padding: "0px !important",
                      //     backgroundColor: "#F0F0F0 !important",
                      //     border: "none !important",
                      //     borderLeft: "1px solid #888888 !important",
                      //   },
                      // },
                    },
                    "mrt-row-select": {
                      header: "",
                      size: 50,
                      muiTableBodyCellProps: {
                        sx: {
                          // padding: "0px !important",
                          backgroundColor: "#F0F0F0 !important",
                          border: "none !important",
                        },
                      },
                    },
                  }}
                  // positionActionsColumn="last"
                  // renderRowActions={({ row }) => (
                  //   <Menus
                  //     itemheight={23}
                  //     itemwidth={23}
                  //     iconborderradius="4px"
                  //     itemcontainerwidth="200px"
                  //     itemcontainerheight={"300px"}
                  //     component={
                  //       <div className="light-font-text">
                  //         <div className="p-2 cursor-pointer">
                  //           Create appointment
                  //         </div>
                  //         <hr className="hr m-0"></hr>
                  //         <div className="p-2 cursor-pointer">Create deal</div>
                  //         <hr className="hr m-0"></hr>
                  //         <div className="p-2 cursor-pointer">
                  //           Start screening
                  //         </div>
                  //         <hr className="hr m-0"></hr>
                  //         <div className="p-2 cursor-pointer">
                  //           Add follow ups
                  //         </div>
                  //         <hr className="hr m-0"></hr>
                  //         <div className="p-2 cursor-pointer">
                  //           Add customer as favourite
                  //         </div>
                  //         <hr className="hr m-0"></hr>
                  //         <div className="p-2 cursor-pointer">Add a note</div>
                  //         <hr className="hr m-0"></hr>
                  //         <div className="p-2 cursor-pointer">
                  //           Go to contactCard
                  //         </div>
                  //         <hr className="hr m-0"></hr>
                  //         {row.original.responsible_agent == "-" ? (
                  //           <div

                  //           >
                  //             Add responsible agent
                  //           </div>
                  //         ) : (
                  //           <div
                  //             className="p-2 cursor-pointer"

                  //           >
                  //             Change responsible agent
                  //           </div>
                  //         )}
                  //         <hr className="hr m-0"></hr>
                  //         <div className="p-2 cursor-pointer">Archive</div>
                  //       </div>
                  //     }
                  //   />
                  // )}
                  renderTopToolbar={({ table }) => <></>}
                  muiTablePaperProps={{
                    selected: false,
                    elevation: 0,
                    fontFamily: "Cerebri sans-light !important",
                    sx: {
                      borderRadius: "0",
                      border: "none",
                      // backgroundColor: "#F9FAFA",
                      table: {
                        borderCollapse: "separate",
                        borderSpacing: "0 5px",
                      },
                    },
                  }}
                  muiTablePaginationProps={{
                    ...paginationoptions,
                  }}
                  muiTableHeadRowProps={{
                    sx: {
                      ...TableRowsStyle,
                    },
                  }}
                />
              }
            />
          </div>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            buttonText="COLD FEET?"
            variant={"white"}
            onClick={() => setMultipleApproveScreening(false)}
          />
          <div className="d-flex ">
            <div className="pe-2">
              <ETButton
                buttonText="Approve, Go to Contract"
                variant={"secondary"}
                onClick={() => console.log("Approved")}
              />
            </div>
            <div>
              <ETButton
                buttonText="Go to contract"
                variant={"primary"}
                onClick={() => console.log("Go to contract")}
              />
            </div>
          </div>
        </div>
      </CustomPopup>
    </div>
  );
};

export default MultipleApproveScreening;
