// UI created by Suraj , functionality added by Suraj
import React, { useEffect, useState } from "react";
import ETButton from "../../../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { TabsButtonIcon } from "../../../../Components/Tabs/Tabs.style";
import { Col, Form, Row } from "react-bootstrap";
import { H3, PlusIcon } from "../../../../styles/Common.style";
import { useScreening } from "../../../../hooks/stores/useScreeningStore";
import { Controller, useFieldArray, useForm } from "react-hook-form";

const EditChoices = ({
	setEditChoices,
	// choices,
	screeningData,
	screeningFieldId,
	applyEditChoices,
	cardData,
	fieldData,
}) => {
	const { register, control, handleSubmit, getValues, setValue } = useForm({
		defaultValues: {
			choices: fieldData[0].possible_values.map((pV) => ({
				id: Math.floor(Date.now() / 1000),
				choiceInput: pV?.value,
				isSelected: pV?.isSelected,
			})),
		},
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: "choices",
	});
	const { setPossibleValue, possibleValue } = useScreening((state) => state);
	//   console.log(fields, "fields");
	const [dropData, setDropData] = useState();
	useEffect(() => {
		setDropData(fields)
	}, [fields])

	//   console.log(field, "field");
	const predefineField = fieldData?.map((field) => field?.is_predefined_field);
	// console.log("fieldData", fieldData);
	const handleApplyChoices = () => {
		const choicesData = getValues("choices").map(
			({ possible_values, ...rest }) => rest
		);

		// fieldData[0].possible_values = choicesData;
		// debugger;
		const transformedChoicesData = choicesData?.map(
			({ id, choiceInput, isSelected }) => ({
				value: choiceInput,
				text: choiceInput,
				isSelected: isSelected,
			})
		);
		setPossibleValue(transformedChoicesData);
		// debugger;
		setEditChoices(false);
	};


	// const [possibleValue, setPossibleValue] = useState([])
	const matchingFields = screeningData
		?.filter((field) => field?.screening_field_id == screeningFieldId)
		?.map((data) => data?.possible_values);
	useEffect(() => {
		const matchingField = screeningData?.find(
			(field) => field?.screening_field_id == screeningFieldId
		);
		if (matchingField) {
			const possibleValues = matchingField?.possible_values;
			const value = possibleValues?.map((data) => data?.value);
			setPossibleValue(possibleValues);
		} else {
		}
	}, [setPossibleValue]);

	// below state used to get and set choices
	const [allChoices, updateAllChoices] = useState(fieldData?.[0]?.Option);
	//   console.log(fields, "fields");
	// // below function used for draggable functionality
	function handleOnDragEnd(result) {
		if (!result.destination) return;

		const items = dropData;
		// const items = Array.from(possibleValue);
		const [reorderedItem] = items?.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		updateAllChoices(items);
		setDropData(items);
	}

	// below function used for changing field name
	const editChoiceFieldsChange = (e, fieldId) => {
		// debugger;
		const updatedFieldsChanges = possibleValue?.map((item) => {
			if (item?.screening_field_id == fieldId) {
				return { ...item, label: e.target.value };
			} else {
				return item;
			}
		});
		updateAllChoices(updatedFieldsChanges);
	};
	const [checkedindex, setCheckedIndex] = useState("");
	// console.log(Object.keys(possibleValue, "object"));  // below code used for set default suggestion
	// const targetValue = checkedindex // The value you want to find
	// const index = possibleValue.findIndex(item => item.value === targetValue);

	// if (index !== -1) {
	//   console.log(`Index of the object with value '${targetValue}' is ${index}`);
	// } else {
	//   console.log(`Object with value '${targetValue}' not found in the array.`);
	// }

	// const setDefaultSuggestion = (fieldId) => {
	// 	const updatedFieldsChanges = possibleValue?.map((item, index) => {
	// 		if (index == fieldId) {
	// 		

	// 			return { ...item, defaultSuggestion: true };
	// 		} else {
	// 			return { ...item, defaultSuggestion: false };
	// 		}
	// 	});
	// 	updateAllChoices(updatedFieldsChanges);
	// };
	const handleAppendChoice = () => {
		append({
			id: Math.floor(Date.now() / 1000),
			choiceInput: "",
			isSelected: 0,
		}); // Adjust the field name as needed
	};
	const handleInputChange = (index, e) => {
		const newValue = e.target.value;
		// console.log("newValue", newValue);
		// debugger;
		// setValue(`choices.[${index}].choiceInput`, {
		// 	value: e.target.value,
		// 	text: e.target.value,
		// 	isSelected: 0,
		// });
		// setValue(`choices.[${index}].choiceInput`, e.target.value);
	};
	const handleCheckboxToggle = (index) => {
		const updatedData = [...dropData];
		updatedData[index].isSelected = updatedData[index]?.isSelected == 1 ? 0 : 1;
		setDropData(updatedData);
	};
	return (
		<div className="py-4 field-conditions-container">
			<div className="d-flex justify-content-between">
				<div
					className=" ps-4 pe-2 "
					style={{ paddingBottom: "5px", width: "80%" }}
				>
					<H3>Edit choices</H3>
					<div className="light-font-text ">
						Define the choices for this field. If the field type supports it you
						will also be able to select the default choice(s) to the left of the
						choice.
					</div>
				</div>
				<div className=" px-4 d-flex justify-content-end">
					<button
						type="button"
						className="close cross-btn"
						onClick={() => setEditChoices(false)}
						data-dismiss="modal"
						aria-label="Close"
					>
						<span className="fs-20" aria-hidden="true">
							<FontAwesomeIcon
								icon="fa-regular fa-chevron-right"
								style={{ color: "#000000" }}
							/>
						</span>
					</button>
				</div>
			</div>

			<hr className="accordionSidebar-hr mt-2 mb-0"></hr>

			<div className="px-4 py-4 light-font-text">
				<Form>
					<DragDropContext onDragEnd={handleOnDragEnd}>
						<Droppable droppableId="possibleValue">
							{(provided) => (
								<ul
									ref={provided.innerRef}
									{...provided.droppableProps}
									style={{
										listStyleType: "none",
										margin: "0px",
										padding: "0px",
									}}
								>
									{dropData?.map((item, index) => {
										return (
											<Draggable
												key={item.id}
												draggableId={item.id}
												index={index}
											>
												{(provided) => (
													<li
														ref={provided.innerRef}
														{...provided.draggableProps}
													>
														<div className="edit-choice-li px-4  d-flex gap-3 align-items-center w-100 mb-3">
															<div
																className="arrow-section d-flex gap-3 align-items-center pe-4 "
																style={{ height: "70px" }}
															>
																<div
																	className="DragHandle"
																	{...provided.dragHandleProps}
																>
																	{" "}
																	<div className="d-flex align-items-center ">
																		<FontAwesomeIcon
																			icon="fa-solid fa-up-down-left-right"
																			style={{ color: "#000000" }}
																		/>
																	</div>
																</div>
																<div
																	className="cursor-pointer"
																	onClick={() => {
																		// setDefaultSuggestion(index);
																		setCheckedIndex(index);
																		handleCheckboxToggle(index)

																	}}
																>
																	{item?.isSelected == 1 ? (
																		<TabsButtonIcon>

																			<FontAwesomeIcon
																				icon="fa-solid fa-circle-check"
																				size="lg"
																			/>
																		</TabsButtonIcon>
																	) : (
																		<FontAwesomeIcon
																			icon="fa-regular fa-circle"
																			size="lg"
																			style={{ color: "#888888" }}
																		/>
																	)}
																</div>
															</div>
															<Row className="w-100">
																<Col sm={10}>
																	<div className="form-input ">
																		<Controller
																			control={control}
																			{...register(
																				`choices[${index}].choiceInput`
																			)}
																			rules={{ required: true }}
																			render={({
																				field: { onChange, value },
																			}) => (
																				<input
																					value={value}
																					onChange={(e) => {
																						// console.log("e.target.value", e.target.value);
																						onChange(e);
																						// handleInputChange(index, e);
																					}}
																				/>
																			)}
																		/>
																		<label htmlFor="addinfo"></label>
																	</div>
																</Col>
																<Col lg={2}>
																	{predefineField == 0 && (
																		<div className="d-flex gap-2">
																			<div>
																				<FontAwesomeIcon
																					icon="plus-circle"
																					style={{
																						color: "#888888",
																						cursor: "pointer",
																					}}
																					size="lg"
																					onClick={handleAppendChoice}
																				/>
																			</div>
																			<div>
																				<FontAwesomeIcon
																					icon="minus-circle"
																					style={{
																						color: "#888888",
																						cursor: "pointer",
																					}}
																					size="lg"
																					onClick={() => {
																						const lastIndex =
																							fields?.length - 1;
																						if (lastIndex >= 0) {
																							remove(lastIndex);
																						}
																					}}
																				/>
																			</div>
																		</div>
																	)}
																</Col>
															</Row>
														</div>
													</li>
												)}
											</Draggable>
										);
									})}
								</ul>
							)}
						</Droppable>
					</DragDropContext>
					<div className="py-4 d-flex justify-content-end">
						<ETButton
							variant={"primary"}
							type={"button"}
							icon={true}
							buttonText={"Apply choices"}
							onClick={() => setEditChoices(false)}
						/>
					</div>
				</Form>
				<div>
					{/* {fields.map((field, index) =>
						renderInputFields(fieldData[index], index)
					)} */}
					{/* <Row className="mb-2">
						<Col lg={10}>
							<div className="form-input" style={{ display: "block" }}>
								{fields.map((field, index) => (
									<div key={index}>
										<Controller
											control={control}
											{...register(`choices[${index}].choiceInput`)}
											rules={{ required: true }}
											render={({ field: { onChange, value } }) => (
												<input
													value={value}
													onChange={(e) => {
														// console.log("e.target.value", e.target.value);
														onChange(e);
														// handleInputChange(index, e);
													}}
												/>
											)}
										/>
									</div>
								))}
							</div>
						</Col>
						<Col lg={2}>
							<div className="d-flex gap-2">
								<div>
									<FontAwesomeIcon
										icon="plus-circle"
										style={{ color: "#888888", cursor: "pointer" }}
										size="lg"
										onClick={handleAppendChoice}
									/>
								</div>
								<div>
									<FontAwesomeIcon
										icon="minus-circle"
										style={{ color: "#888888", cursor: "pointer" }}
										size="lg"
										onClick={() => {
											const lastIndex = fields?.length - 1;
											if (lastIndex >= 0) {
												remove(lastIndex);
											}
										}}
									/>
								</div>
							</div>
						</Col>
					</Row> */}
					{/* <div className="py-4 d-flex justify-content-end">
						<ETButton
							variant={"primary"}
							type={"button"}
							icon={true}
							buttonText={"Apply choices"}
							onClick={handleApplyChoices}
						/>
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default EditChoices;
