import React, { useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import InputField from "./InputField";
import { useGetStreetDetails } from "../../hooks/queries/useGeographyQueries";

const StreetInput = ({
  isSearchStreetVisible,
  setIsSearchStreetVisible,
  handleStreetFieldChange,
  handleStreetDetailClick,
  control,
  street_name,
  errors,
  clearErrors,
  country_name,
  watch,
  searchedStreetName,
  disabledValue,
  removeOpacity,
}) => {
  const suggestionRef = useRef(null);

  const { data: GetStreetDetailsData } =
    useGetStreetDetails(searchedStreetName);

  //   console.log("GetStreetDetailsData",GetStreetDetailsData?.data)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionRef.current &&
        !suggestionRef.current.contains(event.target)
      ) {
        setIsSearchStreetVisible(false);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Detach the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [suggestionRef]);

  return (
    <div>
      <Controller
        control={control}
        name={street_name}
        // {...register("street_name")}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <InputField
            id={street_name}
            value={value}
            disabled={disabledValue}
            removeOpacity={removeOpacity}
            type="text"
            placeholder="Street"
            errorscope
            maxLength={30}
            iserror={errors?.[street_name]}
            onChange={(e) => {
              onChange(e);
              handleStreetFieldChange(e);
              clearErrors(street_name);
              setIsSearchStreetVisible(
                watch(country_name)?.value === "DK" &&
                  e.target.value.length >= 4
              );
            }}
          />
        )}
      />
      {watch(street_name)?.length < 4 &&
      watch(street_name)?.length > 0 &&
      watch(country_name)?.value === "DK" ? (
        <div className="fs-12 fw-400 grey-text pt-1 ">
          Type in minimum 4 characters to get a search result
        </div>
      ) : (
        <>
          {isSearchStreetVisible && watch(country_name)?.value === "DK" && (
            <div style={{}} ref={suggestionRef}>
              <div
                className="bg-white radius-8 pe-1 filtered-list"
                style={{
                  border: "1px solid #D1D1D1",
                  position: "absolute",
                  zIndex: "10",
                  width: "calc(100% - 12px)",
                  marginTop: "5px",
                  overflow: "auto",
                  maxHeight: "150px",
                }}
              >
                <>
                  {GetStreetDetailsData?.data?.length > 0 ? (
                    <div>
                      {GetStreetDetailsData?.data?.map((item, index, array) => (
                        <React.Fragment key={index}>
                          <div
                            className="mt-1 px-2 light-font-text"
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#fff",
                              fontSize: "14",
                              padding: "2px 0",
                              cursor: "pointer",

                              borderBottom:
                                index === array.length - 1
                                  ? "none"
                                  : "1px solid #D1D1D1",
                            }}
                            key={index}
                            onClick={() => {
                              handleStreetDetailClick(item);
                              setIsSearchStreetVisible(false);
                            }}
                          >
                            {item?.city ||
                            item?.zipcode ||
                            item?.house_number ? (
                              <> {item?.address} </>
                            ) : (
                              <> {item?.street_name}</>
                            )}
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  ) : (
                    <div className="mt-1 px-2 light-font-text">
                      No data found
                    </div>
                  )}
                </>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StreetInput;
