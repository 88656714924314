import React from "react";
import styled from "styled-components";

const LoginButton = ({ buttonText, onClick, type, disabled }) => {
  return (
    // <Theme>
    <MyButton
      type={type ? type : "button"}
      onClick={onClick}
      disabled={disabled}
    >
      {buttonText}
    </MyButton>
    // </Theme>
  );
};

export default LoginButton;
const MyButton = styled.button`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  font-size: 14px;
  padding: 0.8em 1em;
  border-radius: 8px;
  font-family: Cerebri sans-semibold;
  font-weight: 500;
  width: fit-content;
  line-height: 1.5;
  cursor: ${(props) => (props.disabled ? "not-allowed" : " pointer")};
  text-transform: uppercase;
  /* Color the border and text with theme.main */
  background-color: ${(props) => props.theme.colors.bgColor};
  color: ${(props) => props.theme.colors.white};
  border: none;
`;
