import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment/moment";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ReactSelect from "../DropDown/ReactSelect";
import Menus from "../Menus/Menus";
import CustomCheckBox from "../Checkbox/Checkbox";
import CustomPopup from "../CustomPopup";
import styled from "styled-components";
import { useUserDetails } from "../../hooks/stores/useUserDetailsStore";
import { UTCtoLocalTimeZone } from "../../utils/UTCtoLocalTimeZone";

const SigningCard = ({
  data,
  status,
  index,
  StatusDropdownData,
  signingStatus,
  handleStatusChange,
  disabled,
  menus,
  handleEditPackage,
  setConfigurationId,
  handleDuplicateOpen,
  handleDeletePopup,
  isTag,
  setDeletePopUp,
  checkValue,
  checkboxOnChange,
  isUnitEdit,
}) => {
  // const [mediaStyle, setMediaStyle] = useState(false);
  const [addDocumentPackagePopup, setAddDocumentPackagePopup] = useState(false);
  const handleDocumentPdf = () => {
    setAddDocumentPackagePopup(true);
  };

   //------------- zustand ------------------//
   const { userDetails } = useUserDetails((state) => state);
   const { convertedDate: createdBy } = UTCtoLocalTimeZone({timestamp: data?.created_on, offset_mins: userDetails?.timezone_offset_mins, timeFormat: "DD. MMM. YYYY | HH:mm:ss"})
   const { convertedDate: modifiedBy } = UTCtoLocalTimeZone({timestamp: data?.modified_on, offset_mins: userDetails?.timezone_offset_mins, timeFormat: "DD. MMM. YYYY | HH:mm:ss"})
 
  return (
    <CardsDiv
      className="sigining-package-card radius-8 align-items-center"
      // mediaStyle={mediaStyle}
      isActive={checkValue?.length}
    >
      {isTag === "AddTagS" && (
        <div className="d-flex justify-content-end">
          <CustomCheckBox
            id={data?.document_package_id}
            // value={checkValue}
            checked={checkValue?.length}
            onChange={checkboxOnChange}
            // onClick={() => {
            //   setMediaStyle(!mediaStyle);
            // }}
          />
        </div>
      )}
      <div
        className="p-3 text-break light-font-text fs-13 "
        style={{ borderBottom: "1px solid #F0F0F0" }}
      >
        {/*--------------edit units condition ---------------------  */}
        {!isUnitEdit ? (
          <div
            className="bg-white radius-6 pe-1 "
            style={{ border: "1px solid #F0F0F0" }}
          >
            <div
              className=" p-3 radius-6 light-font-text fs-13"
              style={{ height: "140px" }}
            >
              {!data?.doc_file && data.name && (
                <div key={index} className="d-flex gap-2">
                  <span style={{ width: "5%" }}>
                    <FontAwesomeIcon icon="fa-regular fa-file-pdf" />
                  </span>
                  <span style={{ width: "95%" }} className="mb-0 ellipsis">
                    {data?.name}
                  </span>
                </div>
              )}
              {data?.doc_files?.map(
                (item, index) =>
                  index < 4 && (
                    <div key={index} className="d-flex gap-2">
                      <span style={{ width: "5%" }}>
                        {/* <FontAwesomeIcon
                          icon={`fa-regular ${
                            item?.name?.includes(".pdf")
                              ? "fa-file-pdf"
                              : "fa-file-certificate"
                          }`}
                        /> */}
                        <FontAwesomeIcon icon="fa-regular fa-file-pdf" />
                      </span>
                      <span style={{ width: "95%" }} className="mb-0 ellipsis">
                        {item?.name}
                      </span>
                    </div>
                  )
              )}
              {data?.doc_files?.length > 4 && (
                <div>
                  <span className="fw-600 fs-13 text-dark">
                    +{data?.doc_files?.length - 4} document
                  </span>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            className="bg-white radius-6 p-3 light-font-text"
            style={{ border: "1px solid #F0F0F0", minHeight: "50px" }}
          >
            <div
              className="pb-1 d-flex gap-2 cursor-pointer"
              onClick={handleDocumentPdf}
            >
              <div style={{ width: "5%" }}>
                <FontAwesomeIcon icon="fa-regular fa-file-pdf" />
              </div>
              <div className="ellipsis" style={{ width: "95%" }}>
                <p className=" fs-13 mb-0 ellipsis">{data?.name}</p>
              </div>
            </div>
          </div>
        )}
      </div>

      {isTag ? (
        <div className="px-3 pt-3 fs-14">
          <div className="form-label-title ">Source</div>
          <div className=" light-font-text">Signing</div>
        </div>
      ) : (
        <>
          {/* -----------edit units condition manage */}
          {!isUnitEdit ? (
            <div
              className="p-3 text-break"
              style={{ borderBottom: "1px solid #F0F0F0" }}
            >
              <div className="fs-16 form-label-title">
                Signing package #{data?.document_package_id}
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
      {status && (
        <div
          className="p-3 text-break"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <Row>
            <Col xl={3} xs={4} className="d-flex  align-items-center">
              <div className="form-label-title ">Status</div>
            </Col>
            <Col xs={5} className="ps-0">
              <div className="signingSmallSelect">
                <ReactSelect
                  placeholder={""}
                  disabled={data?.is_configuration_completed === 0 && true}
                  options={StatusDropdownData?.map((option) => ({
                    value: option?.value,
                    label: option?.label,
                  }))}
                  value={signingStatus}
                  onChange={(e) => {
                    handleStatusChange(e, data?.document_package_id);
                  }}
                  //   defaultValue={{ value: 0, label: "Draft" }}
                  searchable={false}
                  clearable={false}
                  height={26}
                />
              </div>
            </Col>
          </Row>
          <div className="mt-3 d-flex gap-3">
            <div className="form-label-title">Configuration</div>
            <div>
              <span
                className="fs-12 form-label-title white-text  radius-4"
                style={{
                  backgroundColor:
                    data?.is_configuration_completed === 1
                      ? "#77C7A1"
                      : "#F1664B",
                  height: "19px",
                  padding: "2px 6px 2px 6px",
                  textTransform: "uppercase",
                }}
              >
                {data?.is_configuration_completed === 1 ? "Ready" : "NOT READY"}
              </span>
            </div>
          </div>
        </div>
      )}
      <div className=""></div>
      <div className="p-3" style={{ position: "relative" }}>
        <div className="mb-2 ellipsis">
          <p className="mb-0 form-label-title">Package title</p>
          <span className="light-font-text text-break ellipsis d-inline-block">
            {data?.document_package_name}
          </span>
        </div>
        {/* -----------edit units props condition---------------
        {isUnitEdit && (
          <div className="mb-2 ellipsis">
            <p className="mb-0  form-label-title">Document type</p>
            <span className="light-font-text text-break ellipsis">
              {data?.document_type_name}
            </span>
          </div>
        )} */}

        {!menus && !isTag && (
          <>
            <div className="mb-2 ellipsis">
              <p className="mb-0 form-label-title">Document type</p>
              <span className="light-font-text text-break ellipsis d-inline-block">
                {data.document_type_name}
              </span>
            </div>
            <div className="mb-2 ellipsis">
              <p className="mb-0 form-label-title">Signing</p>
              <span className="light-font-text text-break ellipsis d-inline-block">
                {data.is_signing ? "Yes" : "No"}
              </span>
            </div>
          </>
        )}
        {/* {menus ? ( */}

        {/* ) : ( */}
        {/* <div className="mb-2 ellipsis">
          <p className="mb-0 fs-13 fw-600">Date</p>
          <span className="fw-300 fs-13 text-break ellipsis d-inline-block">
            {moment(data?.created_on).format("DD MMM, YYYY | HH:mm:ss")}
          </span>
        </div> */}
        {/* )} */}
        {!isTag && (
          <div className="mb-2 ellipsis">
            <p className="mb-0 form-label-title">Tag</p>
            <span
              className="light-font-text text-break ellipsis d-inline-block"
              style={{ width: "90%" }}
            >
              {data?.tags ? data?.tags?.split(",")?.join(", ") : "-"}
            </span>
          </div>
        )}

        <div className="d-flex  justify-content-between">
          <div>
            <div className="mb-2 ">
              <p className="mb-0 fs-12 form-label-title d-flex   ">
                Created by
              </p>
              <span className="created-by fs-12  ">
              {data?.created_by
                ? data?.created_by +
                  ", " +
                  createdBy
                : "-"}
              </span>
            </div>

            <div className="">
              <p className="mb-0 fs-12 form-label-title">Last updated by</p>
              <span className="created-by fs-12">
              {data?.modified_by
                ? data?.modified_by +
                  ", " +
                  modifiedBy
                : "-"}
              </span>
            </div>
          </div>
          {isTag !== "AddTagS" && (
            <div className="d-flex align-items-end">
              {!isUnitEdit ? (
                <Menus
                  itemheight={23}
                  itemwidth={23}
                  iconborderradius="4px"
                  component={
                    <>
                      {isTag !== "S" ? (
                        <div
                          className="sign-in-package-menu"
                          style={{ fontSize: "14px", width: "198px" }}
                        >
                          <div
                            className="px-3 py-2 sign-in-package-btn"
                            style={{ borderBottom: "1px solid #F0F0F0" }}
                            onClick={() =>
                              handleEditPackage(data?.document_package_id)
                            }
                          >
                            <span
                              className="ms-1 light-font-text"
                              style={{
                                alignItems: "center",
                              }}
                            >
                              Edit package details
                            </span>
                          </div>
                          {data?.doc_files?.findIndex(
                            (data) => data.document_template_file_id === "1"
                          ) > -1 && (
                            <NavLink
                              to="/signing-forms"
                              // state={{ unit_id: value?.unit_id, propertyId: editId }}
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <div
                                className="px-3 py-2 sign-in-package-btn"
                                style={{ borderBottom: "1px solid #F0F0F0" }}
                                onClick={() =>
                                  setConfigurationId(data?.document_package_id)
                                }
                              >
                                <span
                                  className="ms-1 light-font-text"
                                  style={{
                                    alignItems: "center",
                                  }}
                                >
                                  Configuration
                                </span>
                              </div>
                            </NavLink>
                          )}
                          <div
                            className="px-3 py-2 sign-in-package-btn"
                            style={{ borderBottom: "1px solid #F0F0F0" }}
                            onClick={() =>
                              handleDuplicateOpen(data?.document_package_id)
                            }
                          >
                            <span
                              className="ms-1 light-font-text"
                              style={{
                                alignItems: "center",
                              }}
                            >
                              Duplicate package
                            </span>
                          </div>

                          <div
                            className="px-3 py-2 sign-in-package-btn"
                            onClick={() =>
                              handleDeletePopup(data?.document_package_id)
                            }
                          >
                            <span
                              className="ms-1 light-font-text"
                              style={{
                                alignItems: "center",
                              }}
                            >
                              Delete package
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="addpropContent px-3 pt-2 pb-2 light-font-text"
                          onClick={() => setDeletePopUp(true)}
                        >
                          <span className="ms-1">Delete</span>
                        </div>
                      )}
                    </>
                  }
                />
              ) : (
                " "
              )}
            </div>
          )}
        </div>
        <CustomPopup
          open={addDocumentPackagePopup}
          onHide={() => setAddDocumentPackagePopup(false)}
          modalHeaderText={true}
          title={data?.name}
          size="xl"
          title_color="black"
          enableCrossButton={true}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
          deletePopUpStyle={true}
          // subTitle={allDocumentDetails?.documentName}
          titleTooltip={false}
          // titleTooltipText={allDocumentDetails?.allDocumentPackageFileName}
        >
          <div>
            <Row>
              <Col className="">
                <div style={{ height: "540px" }}>
                  <iframe
                    src={data?.file_url}
                    height="100%"
                    width="100%"
                  ></iframe>
                </div>
              </Col>
            </Row>
          </div>
          <div
            className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
            style={{
              backgroundColor: "#F9FAFA",
              borderRadius: " 0px 0px 8px 8px",
              border: "1px solid #F0F0F0",
            }}
          ></div>
        </CustomPopup>
      </div>
    </CardsDiv>
  );
};

export default SigningCard;

const CardsDiv = styled.div`
  background-color: #f9fafa;
  border-color: #f0f0f0;
  border-style: solid;
  border-width: 1px;
  ${(props) =>
    props.isActive &&
    `
  background-color:${props.theme.component.checkbox.primary.color + "11"};
   border-color: ${props.theme.component.checkbox.primary.color};
`}
`;
