import React from "react";
import { DropZoneBorder, GreenSpan } from "../../../../styles/Common.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MaterialReactTable } from "material-react-table";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../../../Components/Buttons/ETButton";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { useGetDocumentType } from "../../../../hooks/queries/useDocumentsQueries";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

const AddDocument = ({
  setOpenUploadDocumentManually,
  handledSelectedPdf,
  isApiLoading,
  type,
  subHeading,
  is_identification_document,
  actorDropdownData,
}) => {
  //------------Form validation---------//
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "droppedPdf", // This should match the name in your form data
  });

  const { data: documentTypeData } = useGetDocumentType(type);

  // dropzone
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles, fileRejections) => {
      acceptedFiles.forEach((file) => {
        // setDroppedPdf((prev) => [...prev, file]);
        is_identification_document
          ? append({
              name: file.name,
              actor: "",
              document_type: "",
              file: file,
            })
          : append({ name: file.name, document_type: "", file: file });
      });
      fileRejections.map(({ file, errors }) => {
        toast.error("Invalid file type " + file.name);
      });
    },
    // onDropRejected: (rejectedFiles) => console.log('rejectedFiles', rejectedFiles),
  });

  const deletePdf = (index) => {
    remove(index);
  };
  return (
    <div>
      <form onSubmit={handleSubmit(handledSelectedPdf)}>
        <div className="p-3">
          <div className="px-2">
            <div className=" my-1">{subHeading}</div>
          </div>
          <Row className="mt-3 px-2 actor-signing-document-from-computer">
            <Col md={{ span: 12 }}>
              <div className="pt-2 pb-4">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <DropZoneBorder className="py-4 d-flex flex-column justify-content-center align-items-center">
                    <div>
                      {/* <FontAwesomeIcon icon="fa-solid fa-file" /> */}
                      <FontAwesomeIcon
                        icon="fa-solid fa-file-pdf"
                        style={{ color: "#000000" }}
                      />
                    </div>
                    <div
                      className="my-2 "
                      style={{ fontSize: "14px", fontWeight: "500" }}
                    >
                      Drop your file here, or&nbsp;
                      <GreenSpan
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={open}
                      >
                        browse your computer
                      </GreenSpan>
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "300",
                        color: "#888",
                      }}
                    >
                      Supports only PDF
                    </div>
                  </DropZoneBorder>
                </div>
              </div>
              <div>
                <div className="mb-4">
                  <Row className="m-0 p-0">
                    {fields.length !== 0 && (
                      <Row className="m-0 p-0 mb-2">
                        <Col
                          className="d-flex align-items-center py-2  actors-section-title fs-13 ps-3"
                          xs={is_identification_document ? 4 : 7}
                        >
                          Name
                          {/* <FontAwesomeIcon
                          icon="fa-solid fa-sort"
                          className="ms-1"
                          style={{ color: "#bfbfbf" }}
                        /> */}
                        </Col>

                        <Col
                          className="d-flex align-items-center py-2 actors-section-title fs-13 ps-0 "
                          xs={4}
                        >
                          Document type
                          {/* <FontAwesomeIcon
                          icon="fa-solid fa-sort"
                          className="ms-1"
                          style={{ color: "#bfbfbf" }}
                        /> */}
                        </Col>
                        {is_identification_document && (
                          <Col
                            className="d-flex align-items-center py-2  actors-section-title fs-13 ps-0"
                            xs={3}
                          >
                            Actor
                            {/* <FontAwesomeIcon
                          icon="fa-solid fa-sort"
                          className="ms-1"
                          style={{ color: "#bfbfbf" }}
                        /> */}
                          </Col>
                        )}
                        <Col xs={2}></Col>
                      </Row>
                    )}

                    <div
                      className="p-0"
                      style={{
                        maxHeight: "160px",
                        overflow: "auto",
                      }}
                    >
                      {fields.map((data, i) => (
                        <div key={data.id}>
                          <Row
                            className="m-0 p-0 mb-2"
                            style={{
                              borderRadius: "8px",
                              backgroundColor: "#F9FAFA",
                            }}
                          >
                            <Col
                              className="d-flex align-items-start py-2 pe-4 flex-column "
                              xs={is_identification_document ? 4 : 7}
                            >
                              <div
                                className="bg-white radius-8 ms-md-1 w-100 ellipsis "
                                style={{
                                  padding: "0.3rem 0.5rem",
                                  border: "1px solid #D1D1D1",
                                  height: "40px",
                                }}
                              >
                                <p
                                  className="mb-0 fs-300 fs-14"
                                  style={{ marginTop: "1px" }}
                                >
                                  <Controller
                                    name={`droppedPdf[${i}].name`}
                                    control={control}
                                    defaultValue={data.name}
                                    // {...register(`droppedPdf[${i}].name`)}
                                    // rules={{ required: true }}
                                    render={({ field }) => (
                                      <input
                                        disabled
                                        className="standard-input"
                                        {...field}
                                        // value={value}
                                        // onChange={(e) => {
                                        //   onChange(e);
                                        //   clearErrors(`droppedPdf[${i}].name`);
                                        // }}
                                      />
                                    )}
                                  />
                                </p>
                              </div>
                              {/* {errors?.droppedPdf?.[i]?.name && (
                                  <p className="error-msg">{REQUIRED_ERR}</p>
                                )} */}
                            </Col>
                            <Col
                              className="d-flex align-items-start py-2 pe-4 ps-0"
                              xs={4}
                            >
                              <div className="w-100 fs-300 fs-14">
                                <Controller
                                  {...register(
                                    `droppedPdf[${i}].document_type`
                                  )}
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <ReactSelect
                                      placeholder=""
                                      value={value}
                                      clearable={false}
                                      isSearchable
                                      onChange={(e) => {
                                        onChange(e);
                                        clearErrors(
                                          `droppedPdf[${i}].document_type`
                                        );
                                        setValue(`droppedPdf[${i}].actor`, "");
                                      }}
                                      error={errors.document_type}
                                      options={documentTypeData?.data?.map(
                                        (item) => ({
                                          value: item?.Id,
                                          label: item?.Text,
                                          isDisabled: is_identification_document
                                            ? false
                                            : watch("droppedPdf")?.some(
                                                (val) =>
                                                  val.document_type?.value ==
                                                  item?.Id
                                              ),
                                        })
                                      )}
                                    />
                                  )}
                                  name={`droppedPdf[${i}].document_type`}
                                  control={control}
                                />

                                {errors?.droppedPdf?.[i]?.document_type && (
                                  <p className="error-msg">{REQUIRED_ERR}</p>
                                )}
                              </div>
                            </Col>
                            {is_identification_document && (
                              <Col
                                className="d-flex align-items-start py-2 pe-4 ps-0"
                                xs={3}
                              >
                                <div className="w-100 fs-300 fs-14">
                                  <Controller
                                    {...register(`droppedPdf[${i}].actor`)}
                                    rules={{
                                      required: is_identification_document,
                                    }}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <ReactSelect
                                        placeholder=""
                                        value={value}
                                        clearable={false}
                                        isSearchable
                                        onChange={(e) => {
                                          onChange(e);
                                          clearErrors(`droppedPdf[${i}].actor`);
                                        }}
                                        error={errors.document_type}
                                        options={actorDropdownData?.map(
                                          (item) => ({
                                            value: item?.actor_id,
                                            label: `${item?.first_name} ${item?.last_name}`,

                                            isDisabled: watch("droppedPdf")
                                              ?.filter(
                                                (val) =>
                                                  val?.document_type?.value ==
                                                  watch("droppedPdf")?.[i]
                                                    ?.document_type?.value
                                              )
                                              ?.some(
                                                (data) =>
                                                  data?.actor.value ==
                                                  item?.actor_id
                                              ),
                                          })
                                        )}
                                      />
                                    )}
                                    name={`droppedPdf[${i}].actor`}
                                    control={control}
                                  />

                                  {errors?.droppedPdf?.[i]?.actor && (
                                    <p className="error-msg">{REQUIRED_ERR}</p>
                                  )}
                                </div>
                              </Col>
                            )}

                            <Col
                              className="d-flex align-items-start py-2 justify-content-end"
                              xs={1}
                            >
                              <div>
                                <button
                                  className="added-document-delete-btn"
                                  onClick={() => deletePdf(i)}
                                >
                                  <FontAwesomeIcon
                                    icon="fa-regular fa-trash"
                                    className="fs-6"
                                  />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </div>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => {
              setOpenUploadDocumentManually(false);
            }}
          />
          <ETButton
            variant={"primary"}
            disabled={isApiLoading}
            buttonText="ADD"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default AddDocument;

AddDocument.defaultProps = {
  subHeading:
    " Drag and drop documents directly from your computer. It is possible to change the name of the document and select a document type. Once you have selected and customized the documents, click add and they will be added to the package.",
  is_identification_document: false,
  actorDropdownData: [],
};
