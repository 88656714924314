import React, { useEffect, useMemo, useState } from "react";
import { H2, H3, H4, H5 } from "../../../styles/Common.style";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import ETButton from "../../../Components/Buttons/ETButton";
import { Col, Row } from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import MRTTheme from "../../../Components/table/MRTTheme";
import {
  SortIcon,
  paginationoptions,
} from "../../../Components/table/MRTGlobalProps";
import CustomPopup from "../../../Components/CustomPopup";
import MaintenanceModePopup from "./MaintenanceModePopup";
import TagToolTip from "../../../Components/Tooltip/PropertyTagToolTip";
import {
  useDeleteOrganisationMaintenance,
  useGetOrganisationMaintenanceHistory,
  usePutOrganisationMaintenanceDetails,
} from "../../../hooks/queries/useOrganization";
import { useOrganizationId } from "../../../hooks/stores/useOrganizationStore";
import { UTCtoLocalTimeZone } from "../../../utils/UTCtoLocalTimeZone";
import { useUserDetails } from "../../../hooks/stores/useUserDetailsStore";
import { toast } from "react-toastify";
import {
  ErrorAlert,
  SucessAlert,
} from "../../../Components/alerts/AlertsAndMessages";
import moment from "moment";

const MaintenanceNew = () => {
  // states:-
  const [sorting, setSorting] = useState([]);
  const [deactivate, setDeactivate] = useState(false);
  const [showMaintenancePopup, setShowMaintenancePopup] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [currentMaintenanceModeData, setCurrentMaintenanceModeData] = useState(
    {}
  );
  const [editMode, setEditMode] = useState(false);
  const [isRemoveBtnTrue, setIsRemoveBtnTrue] = useState(false);

  // ---------------- zustand ----------------------//
  const organizationId = useOrganizationId((state) => state.organizationId);
  const { userDetails } = useUserDetails((state) => state);

  // React-query
  const {
    data: GetOrganisationMaintenanceHistoryData,
    refetch: GetOrganisationMaintenanceHistoryDataRefetch,
  } = useGetOrganisationMaintenanceHistory(organizationId);

  const {
    mutateAsync: DeleteOrganisationMaintenance,
    isLoading: deleteOrganisationMaintenancIsLoading,
  } = useDeleteOrganisationMaintenance();

  const {
    mutateAsync: UpdateOrganisationMaintenanceDetails,
  } = usePutOrganisationMaintenanceDetails();

  // UseEffect
  useEffect(() => {
    if (Object.keys(GetOrganisationMaintenanceHistoryData ?? {}).length) {
      const checkMaintenanceMode =
        GetOrganisationMaintenanceHistoryData?.data.filter(
          (d) => d?.is_maintenance_mode === 1
        );

      if (checkMaintenanceMode?.length === 1) {
        setCurrentMaintenanceModeData(checkMaintenanceMode[0]);
        setIsMaintenance(true);
        setEditMode(true);
        const isRemoveBtn = moment(checkMaintenanceMode?.[0]?.start_on).isAfter(
          moment.utc().format("DD MMM, YYYY HH:mm:ss")
        );
        setIsRemoveBtnTrue(isRemoveBtn);
      } else {
        setIsMaintenance(false);
        setEditMode(false);
        setCurrentMaintenanceModeData({});
      }
      setHistoryData(GetOrganisationMaintenanceHistoryData?.data);
    }
  }, [GetOrganisationMaintenanceHistoryData]);

  // console.log("H", historyData);

  const columns = useMemo(
    () => [
      {
        accessorKey: "startTime",
        header: "Start Time",
        size: 200,
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "24px !important",
          },
        },
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "24px !important",
          },
        },
        Cell: ({ row }) => {
          const { convertedDate: start_on } = UTCtoLocalTimeZone({
            timestamp: row.original.start_on,
            offset_mins: userDetails?.timezone_offset_mins,
            timeFormat: "DD MMM, YYYY [at] HH:mm:ss",
          });
          return (
            <>
              <div className="paragraph2-regular">{start_on}</div>
            </>
          );
        },
      },
      {
        accessorKey: "endTime",
        header: "End Time",
        size: 180,
        Cell: ({ row }) => {
          const { convertedDate: end_on } = UTCtoLocalTimeZone({
            timestamp: row.original.end_on,
            offset_mins: userDetails?.timezone_offset_mins,
            timeFormat: "DD MMM, YYYY [at] HH:mm:ss",
          });

          return (
            <>
              <div className="paragraph2-regular">{end_on}</div>
            </>
          );
        },
      },
      {
        accessorKey: "createdBy",
        header: "Created by",
        size: 180,
        Cell: ({ row }) => {
          return (
            <>
              <div className="paragraph2-regular">
                {row.original.created_by_name}
              </div>
            </>
          );
        },
      },
      {
        accessorFn: (row) => `${row.message}`,
        header: "Message",
        size: 380,
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "24px !important",
          },
        },
        Cell: ({ row }) => (
          <div className="light-font-text d-flex gap-2">
            <div
              style={{
                maxWidth: "250px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {row?.original?.message}
            </div>
            <TagToolTip
              iconColor={"#888888"}
              size={"lg"}
              border="1px solid #F0F0F0"
              padding={"5px 10px"}
              boxShadow="2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
              text={row?.original?.message}
            />
          </div>
        ),
      },
    ],
    []
  );

  // Functions
  const renderEmptyRowsFallback = () => {
    return (
      <div className="d-flex justify-content-center w-100 grey-text py-4">
        <>
          <div style={{ fontSize: "17px" }}>Sorry! No result found:</div>
        </>
      </div>
    );
  };

   // Functions
   const handleEditMaintenance = async (data) => {
    const { convertedDate: getStartDate } = UTCtoLocalTimeZone({
      timestamp: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
      offset_mins: userDetails?.timezone_offset_mins,
      timeFormat: "DD MMM, YYYY HH:mm:ss",
    }); // Get current Time zone
    
    const endDate = data?.maintenance_mode_toggle === true ? `${data?.end_date} ${moment(data?.end_time).format("HH:mm:ss")}` : `${moment(getStartDate).format('YYYY-MM-DD')} ${moment(getStartDate).format('HH:mm:ss')}`;

    const updateOrganisationDeactivateDetails = {
      "maintenance_id": currentMaintenanceModeData?.maintenance_id,
      "organisation_id": organizationId?.toString(),
      "end_on": endDate,
      "message": "Maintenance removed"
    };

    try {
      const response = await UpdateOrganisationMaintenanceDetails(
        updateOrganisationDeactivateDetails
      );
      if (response.status === "success" && response?.data?.bReturn) {
 
        setDeactivate(false);
        setCurrentMaintenanceModeData({});
        GetOrganisationMaintenanceHistoryDataRefetch();
        setEditMode(false)
        toast.success(
          <SucessAlert />
        );
      } else {
        toast.error(
          <ErrorAlert />
        );
      }
    } catch (error) {
      <ErrorAlert />;
    }
    
  };

  // Handle delete maintenance
  const handleEndMaintenanceMode = async () => {
    try {
      const response = await DeleteOrganisationMaintenance(
        currentMaintenanceModeData?.maintenance_id
      );
      if (response.status === "success" && response?.data?.bReturn) {
        setDeactivate(false);
        setCurrentMaintenanceModeData({});
        GetOrganisationMaintenanceHistoryDataRefetch();
        toast.success(<SucessAlert />);
      } else {
        toast.error(<ErrorAlert />);
      }
    } catch (error) {
      <ErrorAlert />;
    }
  };

  return (
    <>
      <div className="whiteContainerStyle">
        <div className="px-3 pb-3 border-bottom">
          <div className="px-2 pt-3 d-flex">
            <div className="d-flex align-items-center  gap-2">
              <H2>Maintenance</H2>
              <CustomToolTip size="sm" text="Maintenance" />
            </div>
          </div>
        </div>
        {/* ------------------------------ */}
        <div className="px-4 py-4 border-bottom">
          <div className="d-flex align-items-center gap-2 ">
            <H3>Maintenance mode on the organisation</H3>{" "}
            <CustomToolTip
              size="sm"
              text="Maintenance mode on the organisation"
            />
          </div>
          <div className="paragraph1-light mt-4" style={{ width: "90%" }}>
            In case it is necessary to block login for all users in the
            organisation, except the system administrators, set the system in
            Maintenance mode. It is possible to set a date and time when the
            system automatically opens for login again.
          </div>
          {isMaintenance ? (
            <>
              <div>
                <H4 className="my-4">Maintenance mode activated</H4>
                <div className="grey-light-font-text ">
                  Activated:{" "}
                  {
                    UTCtoLocalTimeZone({
                      timestamp: currentMaintenanceModeData?.start_on,
                      offset_mins: userDetails?.timezone_offset_mins,
                      timeFormat: "DD MMM, YYYY [at] HH:mm:ss",
                    })?.convertedDate
                  }
                </div>
                <div className="grey-light-font-text ">
                  Activated by: {currentMaintenanceModeData?.created_by_name}
                </div>
                <div className="grey-light-font-text ">
                  Type:{" "}
                  {currentMaintenanceModeData?.planning_type?.toLowerCase() ===
                  "immediately"
                    ? `${currentMaintenanceModeData?.planning_type} (Note: No automated end time)`
                    : currentMaintenanceModeData?.planning_type}
                </div>
              </div>
              <div className="paragraph1-light  mt-4" style={{ width: "90%" }}>
                If you want to deactivate maintenance mode click "End", or if
                you need to make corrections in the active maintenance mode
                click "Edit"
              </div>
              <div className="mt-4 d-flex align-items-center gap-3 ">
                <div>
                  <ETButton
                    variant="primary"
                    buttonText={
                      isRemoveBtnTrue
                        ? "REMOVE MAINTENANCE MODE"
                        : "END MAINTENANCE MODE"
                    }
                    onClick={() => {
                      if(!isRemoveBtnTrue) setEditMode(true);
                      setDeactivate(true);
                    }}
                  />
                </div>
                <div>
                  <ETButton
                    variant="secondary"
                    buttonText={"EDIT MAINTENANCE MODE"}
                    onClick={() => {
                      setEditMode(true);
                      setShowMaintenancePopup(true);
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="mt-4">
                <ETButton
                  variant="primary"
                  buttonText={"Go in maintenance mode"}
                  onClick={() => {
                    setEditMode(false);
                    setShowMaintenancePopup(true);
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="p-4">
          <div className="d-flex align-items-center mt-4 gap-2">
            <H4>History</H4>
            <CustomToolTip size="sm" text={"History"} />
          </div>
          <div>
            <MRTTheme
              colorVariantsForTableRow={["gray"]}
              colorVariantsForTableHead={["white"]}
              table={
                <MaterialReactTable
                  columns={columns}
                  data={historyData ?? []}
                  renderTopToolbar={false}
                  enableFilterMatchHighlighting={true}
                  enableGlobalFilter
                  icons={{
                    ArrowDownwardIcon: () =>
                      SortIcon?.ArrowDownwardIcon(sorting),
                    SyncAltIcon: () => SortIcon?.SyncAltIcon(),
                  }}
                  state={{
                    sorting: sorting,
                  }}
                  onSortingChange={setSorting}
                  muiTablePaperProps={{
                    sx: {
                      table: {
                        borderCollapse: "separate",
                        borderSpacing: "0 4px",
                      },
                    },
                  }}
                  muiTableContainerProps={{
                    sx: {
                      padding: "0px !important",
                    },
                  }}
                  renderEmptyRowsFallback={renderEmptyRowsFallback}
                  enableMultiRowSelection={false}
                  enableRowSelection={false}
                  // enableRowSelection
                  // onRowSelectionChange={setRowSelection}// to add u
                  globalFilterFn="contains" //turn off fuzzy matching and use simple contains filter function
                  enableRowActions={false}
                  enableColumnActions={false}
                  // muiSearchTextFieldProps={{
                  //   ...SearchStyle,

                  //   InputProps: {
                  //     ...SearchIcon,
                  //   },
                  // }}
                  renderRowActions={false}
                  muiPaginationProps={{
                    ...paginationoptions,
                  }}
                  initialState={{
                    showGlobalFilter: true,
                    showColumnFilters: false,
                  }}
                  positionGlobalFilter="left"
                />
              }
            />
          </div>
        </div>
        {/*---------------- delete user popup --------------------*/}
        <CustomPopup
          open={deactivate}
          onHide={() => setDeactivate(false)}
          title="Deactivate maintenance mode"
          title_color="black"
          size={"md"}
          enableCrossButton={false}
          titleTooltip={true}
          titleTooltipText={"Deactivate maintenance mode"}
        >
          {" "}
          <H5
            className="pt-4 pb-3 px-4 light-font-text "
            style={{ width: "79%" }}
          >
            Warning!
          </H5>
          <div className="paragraph2-light px-4 pb-4" style={{ width: "95%" }}>
            You are now exiting maintenance mode on the system, allowing users
            to log in again. Please confirm.
          </div>
          <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="Cancel"
              onClick={() => {
                setDeactivate(false);
              }}
            />
            <ETButton
              variant={"primary"}
              buttonText="DEACTIVATE"
              onClick={() => editMode ? handleEditMaintenance() : handleEndMaintenanceMode()}
            />
          </div>
        </CustomPopup>
      </div>

      {/* Maintenance mode popup */}
      <MaintenanceModePopup
        open={showMaintenancePopup}
        onHide={() => {
          setShowMaintenancePopup(false);
        }}
        onCancel={() => {
          setShowMaintenancePopup(false);
        }}
        historyTableRefetch={GetOrganisationMaintenanceHistoryDataRefetch}
        editMode={editMode}
        setEditMode={setEditMode}
        currentMaintenanceModeData={currentMaintenanceModeData}
        setCurrentMaintenanceModeData={setCurrentMaintenanceModeData}
      />
    </>
  );
};

export default MaintenanceNew;

// const historyData = [
//   {
//     id: 1,
//     startTime: "17 Dec, 2023 at 06:01:56",
//     endTime: "18 Dec, 2023 at 13:56:00",
//     createdBy: "Thomas Høeg Mogensen",
//     message: "We are updating many of our properties right now...",
//   },
//   {
//     id: 2,
//     startTime: "16 Dec, 2023 at 09:02:43",
//     endTime: "16 Dec, 2023 at 09:15:54",
//     createdBy: "Thomas Høeg Mogensen",
//     message: "We are updating many of our properties right now...",
//   },
// ];
