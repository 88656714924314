import React, { useState } from "react";
import ETButton from "../../../../Components/Buttons/ETButton";
import { CustomerDetailsAfterFillingForm } from "./CreateNewlead";
import SelectPropertyFromTable from "../../opportunities/CreateNewAppointments/SelectPropertyFromTable";
import { H4 } from "../../../../styles/Common.style";
import YesNoButton from "../../../../Components/Buttons/YesNoButton";

const SelectPropertyForLead = ({
  assignPropertyToLead,
  setAssignPropertyToLead,
  handleBack,
  handleNext,
  userDetails,
  rowSelection,
  setRowSelection,
  AllPropertyListData,
}) => {
  //   handle next button
  //---------------------states----------------------//

  // const [rowSelection, setRowSelection] = useState({})

  const handleClickNext = (data) => {
    handleNext(data);
  };
  return (
    <div>
      <div className="Popups-inner-scrollable-container-height scrollable-container-y">
        <div className="border-bottom">
          <CustomerDetailsAfterFillingForm data={userDetails} />
        </div>
        <div className="d-flex flex-column  border-bottom p-4">
          <H4>Do you want to assign the lead to a specific property?</H4>
          <span className="light-font-text pt-1 pb-3">
            You can create a lead with or without link to a specific property
            and or units.
          </span>
          <YesNoButton
            value={assignPropertyToLead}
            onChange={setAssignPropertyToLead}
          />
        </div>
        {assignPropertyToLead == 1 && (
          <div className="p-4">
            <H4>Select a property in your division</H4>
            <div className="light-font-text pt-1 pb-3">
              If you want to give the customer a relation to a property, you can
              choose from the properties you have access to below.
            </div>
            <SelectPropertyFromTable setRowSelection={setRowSelection} rowSelection={rowSelection} AllPropertyListData={AllPropertyListData} />
          </div>
        )}
      </div>

      <div className="py-2 px-3 d-flex flex-row justify-content-between  footer-section-button-container">
        <ETButton
          variant={"secondary"}
          buttonText="Back"
          type={"button"}
          onClick={handleBack}
        />

        <ETButton
          variant={"primary"}
          buttonText="NEXT"
          type={"button"}
          disabled={Object?.keys(rowSelection)?.length == 0 && assignPropertyToLead !== 0}
          onClick={()=>handleClickNext(assignPropertyToLead)}
        />
      </div>
    </div>
  );
};

export default SelectPropertyForLead;
