import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ExpandMoreRounded } from "@mui/icons-material";

export default function BasicSelect({
  value,
  label,
  size,
  data,
  IconComponent,
  onChange,
  Data,
  zipcode,
  bordercolor,
  disabled,
  height,
  name
}) {
  const divStyle = {
    color: "#888888",
    fontSize: "14px",
    Padding: "0px 3px",
  };

  const MenuProps = {
    PaperProps: {
      style: {
        boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
        borderColor: "1px solid #D1D1D1",
      },
    },
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel size="small" style={divStyle} id="demo-simple-select-label">
          {label}
        </InputLabel>
        <Select
          sx={{
            fontFamily: "Cerebri sans",
            borderRadius: "8px",
            backgroundColor:
              bordercolor == "green"
                ? "#F4FBF7"
                : bordercolor === "red"
                ? "#FEF3F1"
                : "#fff",
            padding: "0px 3px",

            "&.MuiOutlinedInput-root": {
              //   width: "200px",
              height: height,
              "& fieldset": {
                borderColor:
                  bordercolor == "green"
                    ? "#77C7A1"
                    : bordercolor === "red"
                    ? "#F1664B"
                    : "#D1D1D1",
              },
            },

            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor:
                bordercolor == "green"
                  ? "#77C7A1"
                  : bordercolor === "red"
                  ? "#F1664B"
                  : "#D1D1D1",
              boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor:
                bordercolor == "green"
                  ? "#77C7A1"
                  : bordercolor === "red"
                  ? "#F1664B"
                  : "#D1D1D1",
              boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
            },
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value || ""}
          label={label}
          onChange={onChange}
          MenuProps={MenuProps}
          IconComponent={IconComponent ? IconComponent : ExpandMoreRounded}
          size={size}
          disabled={disabled}
          name={name}
        >
          {Data?.map((option, i) => (
            <MenuItem key={i} value={option?.Id}>
              {zipcode ? option?.Id + " " + option?.Text : option?.Text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
