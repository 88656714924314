import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import ETButton from "../../../../Components/Buttons/ETButton";
import TagsSvg from "../../../../assets/icons/svg_icons/tags.svg";
import moment from "moment";
import { useGetUserById } from "../../../../hooks/queries/useUserQueries";
import { GreenSpan } from "../../../../styles/Common.style";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";

const NewTagConformation = ({
  setStepWizard,
  setShow,
  progress,
  setProgress,
  handleAddTag,
  tagName,
  SourceCount,
  selectedUnitsId,
  mediaPackage,
  propertyText,
  facilityPackage,
  documentId,
  signingPackageId,
  PostTagsIsLoading,
}) => {
  //-------------------Zustand---------------//
  const { userDetails } = useUserDetails((state) => state);
  // const { data: getUserById } = useGetUserById();
  const handleNinthScreen = () => {
    setShow(false);
    setProgress(100);
    handleAddTag();
  };
  const handleEightScreen = () => {
    setStepWizard("SelectUnits");
    setProgress(progress - 14);
  };

  console.log(mediaPackage, "mediaPackage");
  return (
    <div fluid className="px-0 bg-white  radius-8">
      <Row className="px-2 text-center">
        <Col md={{ span: 8, offset: 2 }}>
          <h1 className="pt-5 fs-32 form-label-title"> Let the magic begin!</h1>

          <div className="light-font-text">
            <div>
              You are almost there. Hit the create button and we do the rest of
              the magic.
            </div>
            <div>
              The new tag will be created on the property and added to the
              sources and units you have desired.
            </div>
          </div>

          {/* ------------------data showing after creating Property---------------------------- */}

          <div className="mt-4 pt-1 mx-4 px-lg-4">
            <div
              style={{
                padding: "15px",
                backgroundColor: "#F9FAFA",
                textAlign: "center",
                borderRadius: "8px",
              }}
            >
              <GreenSpan className="light-font-text fs-20 ">
                #{tagName}
              </GreenSpan>

              <div className="created-by">
                <Row>
                  <Col sm={{ span: 8, offset: 4 }}>
                    <div
                      className=""
                      // style={{
                      //   display: "flex",
                      //   flexDirection: "column",
                      //   margin: "auto",
                      //   justifyContent: "center",
                      //   alignItems: "center",
                      // }}
                    >
                      <div className="py-2">
                        <Row className="d-flex ">
                          <Col
                            lg={4}
                            className="form-label-title fs-12 d-flex ps-0  text-left"
                          >
                            Sources:
                          </Col>
                          <Col
                            lg={5}
                            className="d-flex justify-content-start ps-0  text-left"
                          >
                            {SourceCount}
                          </Col>
                        </Row>
                        {/* {mediaPackage?.length > 0 && ( */}
                        <Row className="d-flex ">
                          <Col
                            lg={4}
                            className="form-label-title fs-12  ps-0 d-flex  "
                          >
                            Media package:
                          </Col>
                          <Col
                            lg={6}
                            className="d-flex justify-content-start ps-0 text-left"
                          >
                            {mediaPackage?.length > 0
                              ? mediaPackage
                                  ?.map((item) => item?.media_package_name)
                                  .toString()
                                  ?.split(",")
                                  ?.join(", ")
                              : "-"}
                          </Col>
                        </Row>
                        {/* )} */}
                        {/* {propertyText?.length > 0 && ( */}
                        <Row className="d-flex ">
                          <Col
                            lg={4}
                            className="form-label-title fs-12 d-flex ps-0 text-left "
                          >
                            Text package:
                          </Col>
                          <Col
                            lg={6}
                            className="d-flex justify-content-start ps-0  text-left"
                          >
                            {propertyText?.length > 0
                              ? propertyText
                                  ?.map((item) => item?.internal_title)
                                  ?.toString()
                                  ?.split(",")
                                  ?.join(", ")
                              : "-"}
                          </Col>
                        </Row>
                        {/* )} */}
                        {/* {facilityPackage?.length > 0 && ( */}
                        <Row className="d-flex ">
                          <Col
                            lg={4}
                            className="form-label-title fs-12 d-flex ps-0  "
                          >
                            Facility package:
                          </Col>
                          <Col
                            lg={6}
                            className="d-flex justify-content-start ps-0  text-left"
                          >
                            {facilityPackage.length > 0
                              ? facilityPackage
                                  ?.map((item) => item?.title)
                                  ?.toString()
                                  ?.split(",")
                                  ?.join(", ")
                              : "-"}
                          </Col>
                        </Row>
                        {/* )} */}
                        {/* {documentId?.length > 0 && ( */}
                        <Row className="d-flex ">
                          <Col
                            lg={4}
                            className="form-label-title fs-12 d-flex ps-0  "
                          >
                            Document package:
                          </Col>
                          <Col
                            lg={6}
                            className="d-flex justify-content-start ps-0  text-left"
                          >
                            {documentId.length > 0
                              ? documentId
                                  ?.map((item) => item?.document_package_name)
                                  ?.toString()
                                  ?.split(",")
                                  ?.join(", ")
                              : "-"}
                          </Col>
                        </Row>
                        {/* )} */}
                        {/* {signingPackageId?.length > 0 && ( */}
                        <Row className="d-flex ">
                          <Col
                            lg={4}
                            className="form-label-title fs-12 d-flex ps-0  "
                          >
                            Signing package:
                          </Col>
                          <Col
                            lg={6}
                            className="d-flex justify-content-start ps-0  text-left"
                          >
                            {signingPackageId.length > 0
                              ? signingPackageId
                                  ?.map((item) => item?.document_package_name)
                                  ?.toString()
                                  ?.split(",")
                                  ?.join(", ")
                              : "-"}
                          </Col>
                        </Row>
                        {/* )} */}

                        <Row>
                          <Col
                            lg={4}
                            className="form-label-title fs-12 d-flex  ps-0 "
                          >
                            Units:
                          </Col>
                          <Col
                            lg={7}
                            className="d-flex justify-content-start ps-0  text-left"
                          >
                            {selectedUnitsId.length}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="d-flex ps-3 justify-content-center">
                  <div>
                    Created {moment().format("ll")} by {userDetails?.first_name}{" "}
                    {userDetails?.last_name}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //........................Image Part ......................................// */}

          <Image src={TagsSvg} responsive fluid style={{ height: "250px" }} />
        </Col>
      </Row>
      <div
        className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container"
        style={{
          position: "absolute",
          width: "100%",
          bottom: "0",
        }}
      >
        <ETButton
          variant={"white"}
          buttonText="PREVIOUS"
          onClick={() => handleEightScreen()}
        />

        <ETButton
          variant={"primary"}
          buttonText="CREATE"
          onClick={() => handleNinthScreen()}
          disabled={PostTagsIsLoading}
        />
      </div>
    </div>
  );
};

export default NewTagConformation;
