import { ET_URL, AUTH_URL, EIDO_URL } from "../../environment.js";
export const Site = {
  ET: "ET",
  AUTH: "AUTH",
  EIDO: "EIDO",
};

export const Url = {
  ET: ET_URL,
  AUTH: AUTH_URL,
};
export const Endpoint = {
  AUTH: {
    Login: "/login",
    RefreshToken: "/refresh",
    Logout: "/logout",
  },
  ET: {
    AuthToken: "/v1/test/0",
    Properties_Get_Portfolio: "/v1/portfolio/level",

    Geography_Get_Country: "/v1/location/country",
    Geography_GetRegionByCountryId: "/v1/location/regions",
    Geography_GetZipCodeByRegionId: "/v1/location/zipcodes",
    Get_Zipcode_By_Country: "/v1/location/zipcode",
    Get_UserProfileSystemLanguage: "/v1/language",
    Get_User_TimeZone: "/v1/timezone",

    Post_Property_Add_Portfolio: "/v1/portfolio",
    Put_Property_edit_Portfolio: "/v1/portfolio",
    Delete_property_portfolio: "/v1/portfolio",
    Delete_Portfolio: "/v1/portfolio",
    Get_Portfolio_By_PortfolioId: "/v1/portfolio",

    Get_Property_Types: "/v1/properties/sale-type",

    Post_Property_Add_List: "/v1/properties",
    Delete_property_Types: "/v1/properties",
    Put_PropertyList: "/v1/properties",
    Get_PropertyZipCode: "/v1/location/zipcode",
    Get_PropertyDetail_By_PropertyId: "/v1/properties",

    Get_All_Property_List: "/v1/properties/level",
    Get_property_by_portfolioId: "/v1/properties/portfolio",
    Get_property_For_PortfolioId: "/v1/portfolio/properties",
    post_set_favourite_property: "/v1/properties/favourite",
    Get_All_Properties_For_Porfolio: "/v1/portfolio/properties",
    Delete_Portfolio_Property: "/v1/portfolio",

    Get_Property_Overview_By_PropertyId: "/v1/property/overview",
    Get_PropertyBy_Id: "/v1/properties",
    Get_Units_By_PropertyId: "/v1/units/property",
    Properties_GetPropertiesByLevel: "/v1/properties/level",
    Get_Unit_Statuses: "/v1/unit/statuses",
    Put_Set_Unit_Status: "/v1/unit/status/update",
    Get_Unit_DetailBy_UnitId: "/v1/units",
    Get_Unit_Housing: "/v1/housing",
    Add_MultipleUnits: "/v1/unit/multiple/add",
    Put_Edit_Unit_Details: "/v1/units/details/update",
    // Get_UserById: "/v1/user/details",
    Get_Unit_EconomyDetailsBy_UnitId: "/v1/unit/economy",
    Get_Unit_Maintainer: "/v1/unit/maintainer",
    Put_Edit_Economy_Detail: "/v1/units/economy/update",
    Delete_Single_Units: "/v1/property",
    Get_UnitMarketingByUnitId: "/v1/unit/marketing",
    Put_Unitmarketing: "/v1/units/marketing/update",
    Units_GetStorageAndParkingHistory: "/v1/units",
    Units_SetUnitStatus: "/v1/unit/status/update",

    Get_Text_Details: "/v1/text/property",

    Get_Text_Category: "/v1/text/category",
    Post_Text_AddText: "/v1/text/packages",
    Delete_Text: "/v1/text/property",

    Get_Tags_By_PropertyId: "/v1/tags",
    Delete_Tags: "/v1/tags/delete",
    Put_Tags_SetActive: "/v1/tags/active",
    Put_Text_SetActive: "/v1/text/active",

    Get_Status_Label_By_PropertyId: "/v1/statuslabel",
    Get_Status_Label_Category: "/v1/status/category",
    Delete_Status_Label_By_StatusId: "/v1/statuslabel",
    Add_StatusLabel: "/v1/statuslabel",

    Get_Facility_By_PropertyId: "/v1/facilities/property",
    Get_Facility_Attributes_By_FacilityId: "/v1/attributes",
    Get_Facility_Package_By_PropertyId: "/v1/facilities/property",
    Get_Facility_Category: "/v1/facilities/category",
    Get_Facility_Group: "/v1/facilities/group",
    Delete_Facility_Package: "/v1/facilities",
    SwitchtToggle_Facility_Package: "/v1/facilities",
    Add_tags: "/v1/tags",
    Add_Facility_Package: "/v1/facilities/packages",
    Add_Facility_AttributeValue: "/v1/facilities/attributes",
    Clone_Facility_Package: "/v1/facilities/packages/clone",
    Edit_Facility_Package_AttributeValue: "/v1/facilities/attributes",
    Edit_Facility_Package: "/v1/facilities/packages",
    Set_Active_Facility_Package: "/v1/facilities/packages/active",
    Get_Facility_Package_ByFacilityId: "/v1/facilities/property",
    Get_Facility_Attributes_ByFacilityId: "/v1/facilities/property",
    Add_Final_Facility_Package: "/v1/facilities/packages/final",
    Edit_Facility_Package_Complete_Details: "/v1/facilities/packages",
    Edit_Facility_Package_Basic_Details: "/v1/facilities/packages/info",
    Get_Facility_Package_By_PackageId: "/v1/facilities/property",

    Get_MediaPackage_By_PropertyId: "/v1/media/property",
    Delete_MediaPackage: "/v1/media/property",
    Get_Media_Sort_Order: "/v1/media/sortorder",
    Get_Media_Category: "/v1/media/category",
    Post_Upload_Media: "/v1/media/upload",
    Delete_MediaFile_ByMediaFileId: "/v1/media",
    Post_Add_MediaPackage: "/v1/media/packages",
    Put_Update_MediaFile_Category: "/v1/media",
    Put_Update_MediaPackage_BasicDetails: "/v1/media/packages/info",
    Put_Update_MediaPackage_CompleteDetails: "/v1/media/packages",
    Get_MediaDetails_By_MediaId: "/v1/media",
    Get_MediaPackage_By_PackageId: "/v1/media/property",
    Get_MediaFiles_By_MediaPackageId: "/v1/media/property",
    Media_UpdateMediaPublishDetails: "/v1/media/publish",

    Get_Text_By_TextId: "/v1/text/property",
    Put_UpdateText_BasicDetails: "/v1/text/packages/info",
    Text_GetTextsubcategory: "/v1/text/subcategory",

    Get_StatusLabel_By_StatusId: "/v1/status",

    Get_Tag_By_TagId: "/v1/tag",
    Get_Source_By_TagId: "/v1/tags/source",
    Get_Units_By_TagId: "/v1/tag",
    Put_Tags_UpdateTag: "/v1/tags",

    Post_User_EditUser: "/v1/user/details",
    Put_UpdateText_CompleteDetails: "/v1/text/packages",

    Post_Documents_AddDocPackage: "/v1/documents/packages",
    Put_Documents_UpdateBasicDetails: "/v1/documents/packages/info",
    Put_Document_UpdateCompleteDetails: "/v1/documents/packages",
    Get_Document_Type: "/v1/documents/type",
    Post_Upload_Document: "/v1/documents/upload",
    Get_DocumentPackages_By_PropertyId: "/v1/documents/property",
    Delete_DocumentPackage: "/v1/documents/property",
    Get_Document_By_PropertyId: "/v1/documents/property",
    Delete_Document: "/v1/documents",
    Get_DocumentPackage_By_PackageId: "/v1/documents/property",
    Post_Clone_Documents: "/v1/documents/clone",
    Post_Clone_DocumentPackage: "/v1/documents/packages/clone",
    Documents_UpdateDocumentSortOrder: "/v1/documents/sortorder",
    Post_Cone_TextPackage: "/v1/text/packages/clone",

    Post_Clone_FacilityPackage: "/v1/facilities/packages/clone",

    Post_Clone_MediaPackage: "/v1/media/packages/clone",

    Signing_PostSigningOrderManualDocument: "/v1/documents/actor/manual",

    Put_Update_MediaFileDetails: "/v1/media/attributes",

    Get_MediaFiles_By_PropertyId: "/v1/media/property",

    Get_Template_Documents: "/v1/documents/templates",

    Put_Update_DocumentConfiguration: "/v1/documents/configuration",
    Get_Document_ConfigurationDetails: "/v1/documents",
    Get_EditUnit_Text_ByPropertyId_ByUnitId: "/v1/text/property",
    Get_Media_ByUnitId: "/v1/media/property",
    Get_Documents_ByUnitId: "/v1/documents/property",
    Get_Tag_ByUnitId: "/v1/tag/property",
    Delete_UnitTag: "/v1/delete/unit",
    Get_CurrencyRate: "/v1/currency",
    Get_CurrencyList: "/v1/currency/country",

    Get_Facility_By_UnitId: "/v1/facilities/property",
    Facility_Delete_UnitFacility: "/v1/facilities/property",
    Get_Edit_Unit_Text_Field: "/v1/unit/textfield",
    Get_Coordinates_By_Info: "/v1/location/coordinates",

    Get_EventsSource: "/v1/events/source",
    Get_EventsByPropertyId: "/v1/property",
    Get_EventsDetails: "/v1/events",
    Get_EventsParticipant: "/v1/events/participant",

    Signing_Get_Signer_Details: "/v1/properties",
    Signig_Post_Add_Signer: "/v1/signer",
    Signig_Delete_Signer: "/v1/signer",
    Signing_Get_Signer_By_SignerId: "/v1/properties",
    Signing_Put_Update_Signer: "/v1/signer",
    Signing_Get_SigningSetting_By_PropertyId: "/v1/properties",
    Signing_Put_Update_SigningSetting: "/v1/signing/setting",
    Signing_Put_UpdateSigningQaApproveStatus: "/v1/reservation/qaapprove",

    Get_ActorsBySearch: "/v1/actors/list",
    Get_ActorsByActorId: "/v1/actors",
    Get_ActorOccupationType: "/v1/actors/occupation",
    Post_Add_Actor: "/v1/actors",
    Put_Update_Actor: "/v1/actors",
    Post_Actor_Create_Reservation: "/v1/reservation",

    Get_User_By_DivisionId: "/v1/division",

    Signing_Get_SigningOrder_By_ContractId: "/v1/signing/contract",
    Signing_Get_SigningDetail_By_SigningOrderId: "/v1/signing/order",

    Actor_Get_Contracts_By_UnitId: "/v1/unit",

    Signing_Post_Cancel_Signing_Order: "/v1/signing/order/cancel",
    Signing_Post_Create_Signing_Order: "/v1/signing/order",

    Documents_UpdateDocumentDetails: "/v1/documents",
    Documents_UpdateSigningPackageStatus: "/v1/documents/packages/status",
    Signing_Put_Update_Signing_Order: "/v1/signing/order",
    Signing_Post_SendToSigning: "/v1/signing/order/sign",
    Signing_Get_ContractOverview_By_ContractId: "/v1/contract",

    Contract_Get_ContractType: "/v1/contracts/type",

    Actor_Get_Actor_By_ContractId: "/v1/contract",
    Actor_Update_Responsible_Agent: "/v1/reservation/agent",
    Users_Get_Users: "/v1/user/all",

    Units_GetUnitOverviewByUnitId: "/v1/unit",

    Notes_Get_Notes_Details: "/v1/reservation",
    Notes_Get_Notes_Category: "/v1/notes/category",
    Notes_Post_AddNote: "/v1/reservation/notes",
    Notes_DeleteNote: "/v1/reservation/notes",

    Actor_Update_Reservation_Details: "/v1/reservation/popup/reservation",
    Actor_Put_Update_Deposit_Details: "/v1/reservation/popup/deposit",
    Actor_Put_Update_Leased_Details: "/v1/reservation/popup/leased",
    Actor_Put_Update_Termination_Details: "/v1/reservation/popup/termination",
    Actor_Get_ContractDeposit_PopupDetail: "/v1/reservation",
    Actor_Get_ContractRental_PopupDetail: "/v1/reservation",
    Actor_Get_ContractLeased_PopupDetail: "/v1/reservation",
    Actor_Get_ContractTermination_PopupDetail: "/v1/reservation",
    Actor_Get_Contract_StatusList: "/v1/reservation/status",
    Actor_Get_DocumentDetailsByContractId: "/v1/reservation",

    Signing_Post_AddExtraSigningOrderPerson: "/v1/signing/order/signer",

    Put_MultipleUnits: "/v1/unit/multiple",

    //Promotion label
    PromotionLabel_GetMainStatus: "/v1/status",
    PromotionLabel_GetSubstatus: "/v1/status",
    PromotionLabel_GetPromotionLabelByPropertyId: "/v1/property",
    PromotionLabel_PostAddPromotionLabel: "/v1/promotionlabel",
    PromotionLabel_GetPromotionLabelByLabelId: "/v1/property",
    PromotionLabel_PutUpdatePromotionLabel: "/v1/promotionlabel",
    PromotionLabel_DeletePromotionLabel: "/v1/promotionlabel",
    PromotionLabel_PostAddPromotionLabelConfiguration:
      "/v1/promotionlabel/configuration",
    PromotionLabel_GetPromotionLabelConfigurationByPropertyId: "/v1/property",
    PromotionLabel_DeletePromotionLabelConfiguration: "/v1/status",

    // units by property Id (updated)
    Units_GetUnitByPropertyId_1: "/v1/units/property",
    Unit_SetFavouriteUnit: "/v1/units/favourite",
    Unit_UpdateUnitAnnounceStatus: "/v1/units/announce",

    // Properties
    Property_GetPropertySettingDetails: "/v1/properties",
    Property_UpdatePropertySettingDetails: "/v1/properties/setting",
    Porperty_GetStatusOverviewByPorpertyId: "/v1/properties",
    Porperty_GetStatusOverviewSubDetails: "/v1/properties",

    Signing_GetSigningType: "/v1/documents/property",

    Units_GetParkingLotDetails: "/v1/units/property",
    Units_GetStorageRoomDetails: "/v1/units/property",
    Units_GetStorageRoomById: "/v1/units/storage",
    Units_GetParkingLotById: "/v1/units/parking",
    Units_GetUnitTextField: "/v1/unit/textfield",
    EditStorageUnit: "/v1/units/storage/update",
    EditParkingUnit: "/v1/units/parking/update",
    Unit_DeleteSingleStorageUnit: "/v1/property",
    Unit_DeleteSingleParkingUnit: "/v1/property",
    Get_Parking_Storage_Payment_Type: "/v1/contract/paymenttype",
    Get_Parking_Storage_Payment_Frequency: "/v1/contract/paymentfrequency",
    Get_Signing_Order_By_PropertyId: "/v1/properties",
    Post_Parking_Reservation: "/v1/reservation/parking",
    Post_Storage_Reservation: "/v1/reservation/storage",

    //Location
    Location_GetStreetDetails: "/v1/location/street",

    //Event
    Event_GetEventsType: "/v1/events/type",
    Event_GetEventAgentDetails: "/v1/property",
    Event_PostAddEvent: "/v1/events",
    Event_Get_EventsDetailByEventId: "/v1/property",
    Event_Delete_Event: "/v1/property",

    //Actor
    // Actor_PostUploadActorDocument: "/v1/documents/actor",
    Actor_PostUploadActorDocument: "/v1/documents/actor",

    // Share Document

    Documents_ShareDocuments: "/v1/documents/share",

    // Control-center //

    GetEconomyAvailabilityBySqmtr: "/v1/control/economy/sqm/properties",
    GetEconomyAvailabilityByRoom: "/v1/control/economy/room/properties",
    Economy_GetUpcommingIdleness: "/v1/control/economy/idleness/properties",
    Economy_GetAnalyasis: "/v1/control/economy/analysis/properties",
    Economy_GetAnalyasisSubDeatils: "/v1/control/economy/analysis/properties",
    Overview_GetUnitStatus_ByPropertiesId:
      "/v1/control/overview/unitstatus/properties",
    Overview_GetUnitStatus: "/v1/control/overview/unitstatus/properties",
    Overview_GetNoteCategory: "/v1/control/overview/category",

    Customer_GetTransactionsDividedByRooms:
      "/v1/control/customer/room/properties",
    Customer_GetTransactionsDividedBySqm: "/v1/control/customer/sqm/properties",
    Customer_GetBreakDownByRegion: "/v1/control/customer/region/properties",
    Customer_GetTransactionsDividedByAge: "/v1/control/customer/age/properties",
    Customer_GetBreakDownByZipcode: "/v1/control/customer/zipcode",

    // company
    Company_GetCompanyByOrganisationId: "/v1/organisation",
    Company_AddCompany: "/v1/companies",
    Company_DeleteComapany: "/v1/companies",
    Company_GetPublicType: "/v1/public/type",
    Company_GetCompanyByCompanyId: "/v1/companies",
    Company_GetCompanyByLevel: "/v1/companies/level",
    Company_UpadateCompany: "/v1/companies",
    Company_Get_Company_Overview_By_CompanyId: "/v1/companies",
    //Actor
    Actor_DeleteArchiveDocument: "/v1/reservation",
    Contract_DeleteContractActor: "/v1/contract",

    // organization
    Organization_GetOrganizationList: "/v1/organisations",
    Organization_Get_PublicType: "/v1/public/type",
    Organization_Get_OrganizationById: "/v1/organisations",
    Organization_Edit_Organization: "/v1/organisations",
    Organization_GetOrganizationSideType: "/v1/organisations/side_type",
    Organization_GetOrganizationFloorType: "/v1/organisations/floor_type",
    Organization_GetOrganizationOverviewById: "/v1/organisations",
    Organisation_GetOrganisationMaintenanceDetails: "/v1/organisations",
    Organisation_UpdateOrganisationMaintenanceDetails: "/v1/maintenance",
    Organisation_AddMaintenance: "/v1/maintenance",
    Organisation_GetMaintenanceHistory: "/v1/maintenance/organsiation",
    Organisation_DeleteMaintenance: "/v1/maintenance",
    Organisation_Post_Update_Organisation_Customisation:
      "/v1/organisations/customisation",

    // Create new user flow
    Users_ValidateUserSignUp: `/v1/users/signup?invite_id=`,
    Users_UpdateUserSignup: "/v1/users/signup",
    Users_GetUserRoles: "/v1/users/roles",

    // SMS
    SendOtp: "/v1/otp/send",
    ValidateOtp: "/v1/otp/validate",

    // EIDO END POINTS
    // Color
    Unit_Eido_GetColor: "/v1/eido/color",
    Unit_Eido_PostColor: "/v1/eido/color",
    Unit_Eido_UpdateColor: "/v1/eido/color",
    Unit_Eido_ResetColor: "/v1/eido/color",

    //Documents
    Documents_GetGuideDocumentsByPropertyId: "/v1/properties",
    Documents_PostAddGuideDocument: "/v1/documents/guide",
    Documents_DeleteGuideDocument: "/v1/documents/guide",
    Documents_UpdateDocumentGuide: "/v1/documents/publish",
    Documents_CopyGuideToPacakge: "/v1/guide/documents/clone",

    //Contract -Screening
    Contract_GetScreeningTemplateQuestionAnswer:
      "/v1/screening/template/property",
    Contract_GetScreeningTemplateQuestion: "/v1/screening/template",

    // division in ET-Marketing

    Division_GetDivisionByUserId: "/v1/users/division",
    // Division_AddDivision: "/v1/division/add",
    Division_AddDivision: "/v1/divisions",
    Division_DeleteDivision: "/v1/divisions",
    Division_EditDivision: "/v1/divisions/update",
    Division_GetDivisionByOrganisationId: "/v1/divisions/organisation",
    Division_Get_Division_ByDivisionId: "/v1/divisions",

    // Screening Property Level

    Screening_GetScreeningByPropertyId: "/v1/screening/form/property",
    Screening_GetScreeningFlagTriggerAction: "/v1/screening/flags/status",
    Screening_GetScreeningDeadlineAction: "/v1/screening/deadline/status",
    Screening_UpdateScreeningFormStatus: "/v1/screening/form/status",
    Screening_GetScreeningFormBasicDetails: "/v1/screening/form",
    Screening_GetScreeningCategoryByformId: "/v1/screening/form",
    Screening_GetScreeningFormSectionDetails: "/v1/screening/form",
    Screening_GetScreening: "/v1/screening/form",
    Screening_GetScreeningFields: "/v1/screening/category",
    Screening_UpdateScreeningActorStaus: "/v1/customers/screening/status",
    Screening_UpdateScreeningFormUnits: "/v1/screening/form/units",
    Screening_AddScreeningForm: "/v1/screening/form",
    Screening_GetLinkedUnit: "/v1/screening/form",
    Screening_UpdateScreeningForm: "/v1/screening/form",
    Screening_GetScreeningTemplate: "/v1/screening/template",

    //Email templates

    EmailTemplates_GetEmailTemplatesById: "/v1/email/template",
    EmailTemplates_GetEmailTemplateByTemplateId: "/v1/email/template",
    EmailTemplates_GetEmailTemplateCategory: "/v1/email/template/category",
    EmailTemplates_GetEmailTemplateBranding: "/v1/email/template/organisation",
    EmailTemplates_GetLanguageByOrganization: "/v1/email/template/organisation",
    EmailTemplates_GetEmailTemplateMergeField: "/v1/email/template/subcategory",
    EmailTemplates_AddNewEmailTemplate: "/v1/email/template",
    EmailTemplates_UpdateEmailTemplate: "/v1/email/template",
    EmailTemplates_DeleteEmailTemplate: "/v1/email/template",
    EmailTemplates_GetEmailTemplateList: "/v1/email/template",

    //Customer funnel
    Customer_Funnel_AddLeadfollowup: "/v1/customers/followup",
    Customer_Funnel_AddLead: "/v1/customers/lead",
    Customer_Funnel_AddScreeningResponse: "/v1/customer/screening/response",
    Customer_Funnel_UpdateLeadAgent: "/v1/customers/lead/agent",
    Customer_Funnel_UpdateFavouriteLead: "/v1/customers/favorite",
    Customer_Funnel_UpdateScreeningActorStatus: "/v1/customer/screening/status",
    Customer_Funnel_DeleteLead: "/v1/customers/lead",
    Customer_Funnel_GetLeadsByDivisionId: "/v1/customers/division",
    Customer_Funnel_GetDistributionAllScreening: "/v1/customers/division",
    CustomerFunnel_GetScreeningDividedOnAgent: "/v1/customers/division",
    Customer_Funnel_GetSigningOrderOverview: "/v1/customers/division",
    Customer_Funnel_GetSigningOrderDetails: "/v1/customers/division",
    Customer_Funnel_GetFollowUpStatus: "/v1/customers/followup/status",
    Customer_Funnel_GetFollowUpTaskCategory: "/v1/customers/followup/category",
    Customer_Funnel_GetLeadDetails: "/v1/customers/lead",
    Customer_Funnel_GetScreeningForm: "/v1/customer",
    Customer_Funnel_GetActiveContractsByDivisionId: "/v1/customers/division",
    Customer_Funnel_GetAllCustomerByDivision: "/v1/customers/division",
    Customer_Funnel_GetOpportunityOverviewByDivision: "/v1/customers/division",
    Customer_Funnel_GetOpportunityDetailsByDivision: "/v1/customers/division",

    Language_GetLanguage: "/v1/language",

    // guides
    Guide_Get_GuideCard_By_DivisionId: "/v1/guides/division",
    Guide_Delete_GuideCard_By_GuideId: "/v1/guides",
    Guide_Put_Guide_Status: "/v1/guides/status",
    Guide_Post_Add_New_Guide: "/v1/guides",
    Guide_Get_Contract_Status: "/v1/contracts/status",
    Guide_Get_Guide_By_GuideId: "/v1/guides",
    Guide_Post_Update_Guide: "/v1/guides/update",

    //Common Type
    Common_GetCommonType: "/v1/common/type",
    // user
    Users_SendResetMail: "/v1/users/reset",
    Users_ValidateResetPassword: "/v1/users",
    Users_GetUserDetailsById: "/v1/users/details",
    Users_UpdateMobile: "/v1/users/mobile",

    // Teams
    Teams_GetTeamsByLevel: "/v1/teams/level",
    Teams_GetTeamByTeamId: "/v1/teams",
    Teams_AssignPropertiesToTeams: "/v1/teams/properties",
    Teams_UpdateTeamStatus: "/v1/teams/status",
    Teams_UpdateTeam: "/v1/teams/update",
    Teams_AddTeam: "/v1/teams",
    Teams_AssignUserToTeams: "/v1/teams/members",

    //Users
    Users_UpdateUserProfile: "/v1/users/profile",
    User_GetUserDetails: "/v1/users/details",
    User_PutChangeUserPassword: "/v1/users/password",
    Users_GetOwners: "/v1/users/owner/level",
    Users_GetUserByLevel: "/v1/users/level",
    Users_UpdateUserStatus: "/v1/users/status",
    Users_DeleteUser: "/v1/users",
    Users_AddUser: "/v1/users",
    Users_UpdateUserDetails: "/v1/users/details",
    Users_ResendInvitation: "/v1/users/invitation",
    Users_GetUserRoles: "/v1/users/roles",

    // Create new user flow

    // SMS
    SendOtp: "/v1/otp/send",
    ValidateOtp: "/v1/otp/validate",

    //Division
    Division_GetDivisionOverviewByDivisionId: "/v1/divisions",
    Division_Edit_Division: "/v1/divisions/update",
    Division_PostAddDivisionSession: "/v1/users/session/division",

    // property
    Properties_AddProperty: "/v1/properties",
    Properties_UpdateProperty: "/v1/properties",

    // Common
    Common_CheckExists: "/v1/common/exists",
    Common_GetBillingLevelDetails: "/v1/common/billing/level",

    // --------------- new document level ------------------//
    Documents_Get_Document_By_Level: "/v1/documents/level",
    Documents_Update_Documents_Inherited: "/v1/documents",
    Document_Delete_By_DocumentId: "/v1/documents",
    Documents_post_Add_Document: "/v1/documents",

    // Roles
    Roles_GetRolesBySystemId: "/v1/roles/system/ETM",

    //Units
    Units_GetMainSubStatus: "/v1/units/organisation",
    Units_PutUpdateMainSubStatus: "/v1/units/status",
  },
  EIDO: {
    // Text Packages
    Unit_Eido_GetTextPackage: "/v1/property",

    // Media Packages
    Unit_Eido_GetMediaPackage: "/v1/property",
  },
};
