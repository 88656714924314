import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Button } from "@mui/material";
import { MenuItem } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import ETButton from "../../Components/Buttons/ETButton";
import Calender from "../../assets/icons/svg_icons/Date-icon.svg";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import customproperties from "../../../src/styles/Theme";
import { GreenSpan, H2 } from "../../styles/Common.style";

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  format,
} from "date-fns";
import { ControlCenterOverview } from "./overview/Overview";
import DropdownModalMui from "./../../Components/DropdownModal/DropdownModalMui";
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from "../../Components/Tabs/ReactTabs.style";
import {
  useAllPropertiesData,
  useFavoritesData,
  usePropertyId,
} from "../../hooks/stores/usePropertyStore";
import Customers from "./customers/Customers";
import { Row, Col } from "react-bootstrap";
import Economy from "./economy/Economy";
import Traffic from "./traffic/Traffic";
import FilterComponent from "../../reactdataTableComponent/FilterComponent";
import CheckBox from "../../Components/Checkbox/Checkbox";
import Events from "./events/Events";
import Signings from "./signings/Signings";
import { addYears, endOfYear, startOfYear } from "date-fns";
import {
  useAllPropertyList,
  useGetFavoriteProperties,
  useGetPropertyTypes,
  useSetFavouriteProperty,
} from "../../hooks/queries/usePropertyQueries";
import { useControlCenter } from "../../hooks/stores/useControlCenterStore";
import { status, controlCenterTabs } from "../../model/Constant";
import { GreenDiv, H1 } from "../../styles/Common.style";
import GeographyDropdown from "../properties/geographyDropdown/GeographyDropdown";
import { Outlet } from "react-router-dom";
import { NavLink } from "../../Components/Tabs/ReactTabs.style";
import { useBreadcrumb } from "../../Components/BreadcrumbContext ";
import { useRouting } from "../../hooks/stores/useRoutingStore";
import { useGetBreakDownByRegion } from "../../hooks/queries/useControlCenterQueries";
import { useDivisionId } from "../../hooks/stores/useDivisionsStore";
// AllPropertiesData

const ControlCenter = ({
  Breadcrumbs,
  selectedPropertiesId,
  setSelectedPropertiesId,
}) => {
  // Query to get property types
  const { data: propertyTypesData } = useGetPropertyTypes();
  const {
    selectedDate,
    setSelectedDate,
    selectedProperties,
    setSelectedProperties,
    selectedEndDate,
    setSelectedEndDate,
    geographyFilterData,
    setGeographyFilterData,
    setSelectedCountryId,
    allProperties,
    setAllProperties,
  } = useControlCenter((state) => state);

  // const { data: breakDownByRegionData } = useGetBreakDownByRegion(
  //   selectedProperties,
  //   selectedDate,
  //   selectedEndDate
  // );
  const allPropertiesStoreData = useAllPropertiesData(
    (state) => state.allPropertiesStoreData
  );
  //Query to get all properties data
  const {
    data: allPropertiesData,
    isLoading,
    isError,
    refetch: allPropertyDataRefetch,
  } = useAllPropertyList(0);
  //Get favourite properties
  const { favoritePropertiesStoreData, setFavoritePropertiesStoreData } =
    useFavoritesData((state) => state);

  const [anchorCalendar, setanchorCalendar] = useState(false);

  const handleClickCalendar = (event) => {
    setanchorCalendar(event.currentTarget);
  };
  const [applyClose, setApplyClose] = useState(false);
  useEffect(() => {
    setApplyClose(false);
  }, [applyClose]);
  // const [selectedPropertiesId, setSelectedPropertiesId] = useState([])

  const handleApplySelectedProperties = () => {
    setSelectedProperties(allProperties); //zustand
    setApplyClose(true);
    setSelectedPropertiesId(allProperties);
  };

  const handleCloseCalendar = () => {
    setanchorCalendar(null);

    // setSelectedDate(date); //zustand
  };
  const [unitTypeChecked, setUnitTypeChecked] = useState([]);
  const handleCheckAllUnitTypes = (e) => {
    if (e.target.checked) {
      const allUnitTypes = propertyTypesData?.data?.map((item) => item.Id);
      setUnitTypeChecked(allUnitTypes);
    } else {
      setUnitTypeChecked([]);
    }
  };
  const handleCheckedUnitType = (e, item) => {
    if (e.target.checked) {
      setUnitTypeChecked([...unitTypeChecked, item.Id]);
    } else {
      setUnitTypeChecked(unitTypeChecked?.filter((el) => el !== item.Id));
    }
  };

  // All Properties

  // const [allProperties, setAllProperties] = useState([]);
  const handleAllProperties = (e) => {
    if (e.target.checked) {
      const allPropertiesTypes = allPropertiesData?.data?.map(
        (prop) => prop.property_id
      );
      setAllProperties(allPropertiesTypes);
    } else {
      setAllProperties([]);
    }
  };
  const handleProperties = (e, prop) => {
    if (e.target.checked) {
      setAllProperties([...allProperties, prop.property_id]);
    } else {
      setAllProperties(
        allProperties?.filter((item) => item !== prop.property_id)
      );
    }
  };
  useEffect(() => {
    setSelectedProperties(allProperties);
  }, []);
  const [date, setDate] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfDay(new Date()),
      key: "selection",
    },
  ]);

  const ResetFilter = () => {
    const formateCurrentDate = format(date?.[0]?.startDate, "yyyy-MM-dd");
    const formateCurrentEndDate = format(date?.[0]?.endDate, "yyyy-MM-dd");
    setSelectedDate(formateCurrentDate);
    setSelectedEndDate(formateCurrentEndDate);
    setDate([
      {
        startDate: startOfMonth(new Date()),
        endDate: endOfDay(new Date()),
        key: "selection",
      },
    ]);
  };

  useEffect(() => {
    const formateCurrentDate = format(date?.[0]?.startDate, "yyyy-MM-dd");
    const formateCurrentEndDate = format(date?.[0]?.endDate, "yyyy-MM-dd");
    setSelectedDate(formateCurrentDate);
    setSelectedEndDate(formateCurrentEndDate);
  }, []);
  const DateFilter = () => {
    setanchorCalendar(null);
    const { startDate, endDate } = date[0];
    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate = format(endDate, "yyyy-MM-dd");
    setSelectedDate(formattedStartDate);
    setSelectedEndDate(formattedEndDate);
  };

  const definedTimes = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),

    startOfLastThreeMonths: startOfMonth(addMonths(new Date(), -3)),
    endOfLastThreeMonths: endOfMonth(addMonths(new Date(), -1)),
    startOfLastYear: startOfYear(addYears(new Date(), -1)),
    endOfLastYear: endOfYear(addYears(new Date(), -1)),
    startOfLastSevenDays: startOfDay(addDays(new Date(), -1)),
    endOfLastSevenDays: startOfDay(addDays(new Date(), -7)),
    startOfLastThirtyDays: startOfDay(addDays(new Date(), -1)),
    endOfLastThirtyDays: startOfDay(addDays(new Date(), -30)),
    startOfAllTime: new Date(2001, 0, 1),
  };
  const staticRangeHandler = {
    range: {},
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  };

  function createStaticRanges(ranges) {
    return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
  }

  const staticRanges = createStaticRanges([
    {
      label: "Today",
      range: () => ({
        startDate: definedTimes.startOfToday,
        endDate: definedTimes.endOfToday,
      }),
    },
    {
      label: "Last 7 days",
      range: () => ({
        startDate: definedTimes.startOfLastSevenDays,
        endDate: definedTimes.endOfLastSevenDays,
      }),
    },
    {
      label: "Last 30 days",
      range: () => ({
        startDate: definedTimes.startOfLastThirtyDays,
        endDate: definedTimes.endOfLastThirtyDays,
      }),
    },
    {
      label: "Last Month",
      range: () => ({
        startDate: definedTimes.startOfLastMonth,
        endDate: definedTimes.endOfLastMonth,
      }),
    },
    {
      label: "This Month",
      range: () => ({
        startDate: definedTimes.startOfMonth,
        endDate: definedTimes.endOfToday,
      }),
    },
    {
      label: "Last 3 Month",
      range: () => ({
        startDate: definedTimes.startOfLastThreeMonths,
        endDate: definedTimes.endOfLastThreeMonths,
      }),
    },
    {
      label: "Last Year",
      range: () => ({
        startDate: definedTimes.startOfLastYear,
        endDate: definedTimes.endOfLastYear,
      }),
    },
    {
      label: "All time",
      range: () => ({
        startDate: definedTimes.startOfAllTime,
        endDate: definedTimes.endOfToday,
      }),
    },
  ]);

  const propertyId = usePropertyId((state) => state.propertyId);
  const { divisionId } = useDivisionId((state) => state);

  //Set favorite properties
  const { data: favouritePropertiesData, refetch: favouritePropertiesRefetch } =
    useGetFavoriteProperties("d", divisionId, "details");
  const { mutateAsync: setFavoriteMutate } = useSetFavouriteProperty();

  const setFavoriteHandler = async (property_id, isStar) => {
    const setPropertyPayload = {
      property_id,
      is_star: isStar === 1 ? 0 : 1,
    };
    try {
      const result = await setFavoriteMutate(setPropertyPayload);
      if (result.status == status.s && result.data.bReturn) {
        toast.success(result.data.message);
        allPropertyDataRefetch();
        favouritePropertiesRefetch();
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {}
  };

  const { t } = useTranslation(["ET-50"]);

  // Geography dropdown logic
  let filteredData;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [checkedCountry, setCheckedCountry] = useState([]);
  const [optionCheckedZipcode, setOptionCheckedZipcode] = useState([]);
  const [zipCode, setZipCode] = useState([]);
  const [selectedRegionID, setSelectedRegionID] = useState("");
  const [allCheckedRegions, setAllCheckedRegions] = useState(false);
  const [allCheckedZipcode, setAllCheckedZipcode] = useState(false);
  const [closeDropdown, setCloseDropdown] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);

  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    if (selectedTab === null) {
      updateBreadcrumbs(["Overview"]);
    } else if (selectedTab === "Economy") {
      updateBreadcrumbs(["Economy"]);
    } else if (selectedTab === "Traffic") {
      updateBreadcrumbs(["Traffic"]);
    } else if (selectedTab === "Events") {
      updateBreadcrumbs(["Events"]);
    } else if (selectedTab === "Customer") {
      updateBreadcrumbs(["Customer"]);
    } else if (selectedTab === "Signings") {
      updateBreadcrumbs(["Signings"]);
    } else if (selectedTab === "Overview") {
      updateBreadcrumbs(["Overview"]);
    }
  }, [selectedTab]);

  useEffect(() => {
    setCloseDropdown(false);
  }, [closeDropdown]);

  useEffect(() => {
    setGeographyFilterData(allPropertiesStoreData);
    // setActiveDefaultTabs("All Properties");
    // setActivePortfolioTabs(0);
    // setRowCount(allPropertiesStoreData?.length);
  }, [allPropertiesStoreData]);
  // useEffect(() => {
  //   setGeographyFilterData(breakDownByRegionData?.data);
  // }, [breakDownByRegionData]);

  const applyGeography = (optionCheckedZipcode) => {
    if (optionCheckedZipcode) {
      filteredData = allPropertiesStoreData?.filter((property) =>
        optionCheckedZipcode.includes(property.zipCode)
      );
      setGeographyFilterData(filteredData);
      //   noOfRows = filteredData?.length;
      //   setRowCount(noOfRows);
    }

    setSelectedOptions(allCheckedRegions);
    setSelectedCountryId(checkedCountry);
    setSelectedOptions(allCheckedZipcode);
    if (optionCheckedZipcode.length == 0) {
      setGeographyFilterData(allPropertiesStoreData);
    }
  };
  // Search properties function
  const [searchInput, setSearchInput] = useState("");

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  function filterData(data, searchValue, typeIds, zipCodes) {
    return data?.filter((item) => {
      if (
        searchValue &&
        !item?.propertyName?.toLowerCase()?.includes(searchValue?.toLowerCase())
      ) {
        return false;
      }

      if (typeIds?.length > 0 && !typeIds?.includes(item?.type_id)) {
        return false;
      }

      if (zipCodes?.length > 0 && !zipCodes?.includes(item?.zipCode)) {
        return false;
      }

      return true;
    });
  }

  const data = allPropertiesData?.data;
  const filteredProperties = filterData(
    data,
    searchInput,
    unitTypeChecked,
    optionCheckedZipcode
  );

  const ResetProperties = () => {
    setSearchInput("");
    setUnitTypeChecked([]);
    setOptionCheckedZipcode([]);
    setAllProperties([]);
    setSelectedPropertiesId([]);
    setSelectedProperties([]);
  };

  return (
    <Container fluid className="pb-2 pt-40 bgcolor-d-white">
      <H1 style={{ color: "#F1664B" }}>Dashboard</H1>
      <div style={{ lineHeight: "22px" }} className="light-font-text">
        Here we have gathered all the most important information and data about
        your properties, units and customer <br /> Select one or more properties
        and define the time period you need to view.
      </div>

      <hr
        style={{
          margin: "27.5px 0",
          width: "280px",
          border: " 1px solid #F4F5F5",
        }}
      />
      <div style={{ margin: "17px 0", gap: "16px", display: "flex" }}>
        <div style={{ width: "194px", cursor: "pointer" }}>
          <DropdownModalMui
            text={
              <div className="light-font-text">
                Selected properties ({selectedProperties?.length})
              </div>
            }
            applyClose={applyClose}
            initialCheckedValues={allProperties}
            component={
              <>
                <div style={{ minWidth: "750px" }}>
                  <Row
                    className="px-4 py-4"
                    style={{
                      borderBottom: "1px solid rgba(240, 240, 240, 1)",
                    }}
                  >
                    <Col>
                      <FilterComponent
                        filtertext={searchInput}
                        handleSearch={(e) => handleSearch(e)}
                      />
                    </Col>
                    <Col>
                      <DropdownModalMui
                        text={
                          <div className="light-font-text">
                            All unit types ({unitTypeChecked?.length})
                          </div>
                        }
                        component={
                          <div className="py-2 ps-2 pe-5">
                            <div className="d-flex align-items-center light-font-text">
                              <CheckBox
                                onChange={handleCheckAllUnitTypes}
                                id={"handleCheckAllUnitTypes"}
                                checked={
                                  unitTypeChecked?.length ===
                                  propertyTypesData?.data?.length
                                }
                              />{" "}
                              All types of unit
                            </div>
                            <div className="ms-4">
                              {propertyTypesData?.data?.map((item, i) => (
                                <div
                                  key={i}
                                  className="d-flex align-items-center fw-300 fs-14 "
                                >
                                  <CheckBox
                                    id={item.Id}
                                    checked={unitTypeChecked?.includes(item.Id)}
                                    onChange={(e) =>
                                      handleCheckedUnitType(e, item)
                                    }
                                  />{" "}
                                  {item.Text}
                                </div>
                              ))}
                            </div>
                          </div>
                        }
                      />
                    </Col>
                    <Col>
                      <DropdownModalMui
                        text={
                          <div className="light-font-text">
                            All Zipcodes ({optionCheckedZipcode?.length})
                          </div>
                        }
                        closeDropdown={closeDropdown}
                        width="700px"
                        component={
                          <GeographyDropdown
                            applyGeography={applyGeography}
                            setCloseDropdown={setCloseDropdown}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                            checkedCountry={checkedCountry}
                            setCheckedCountry={setCheckedCountry}
                            selectedZipcode={optionCheckedZipcode}
                            setSelectedZipcode={setOptionCheckedZipcode}
                            zipCode={zipCode}
                            setZipCode={setZipCode}
                            setAllCheckedZipcode={setAllCheckedZipcode}
                            allCheckedZipcode={allCheckedZipcode}
                            setAllCheckedRegions={setAllCheckedRegions}
                            allCheckedRegions={allCheckedRegions}
                            selectedRegionID={selectedRegionID}
                            setSelectedRegionID={setSelectedRegionID}
                            setTableData={setGeographyFilterData}
                            geographyDropDownData={allPropertiesStoreData}
                          />
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="ps-4 pe-2">
                    <Col
                      xs={4}
                      className="py-3"
                      style={{
                        borderRight: "1px solid rgba(240, 240, 240, 1)",
                      }}
                    >
                      <H2>Starred properties</H2>
                      <div
                        className="scrollable-container-y"
                        style={{ height: "250px" }}
                      >
                        {favoritePropertiesStoreData?.map((item, i) => (
                          <Row
                            key={i}
                            className="pt-3  d-flex align-items-center"
                          >
                            <Col className="cursor-pointer" xs={2}>
                              {item.isStar == "1" ? (
                                <FontAwesomeIcon
                                  icon="fa-solid fa-star"
                                  style={{ color: "#F6E651" }}
                                  onClick={() =>
                                    setFavoriteHandler(
                                      item.property_id,
                                      item.isStar
                                    )
                                  }
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon="fa-regular fa-star"
                                  style={{ color: "#F6E651" }}
                                  onClick={() =>
                                    setFavoriteHandler(
                                      item.property_id,
                                      item.isStar
                                    )
                                  }
                                />
                              )}
                            </Col>
                            <Col className="" style={{ lineHeight: "1.7" }}>
                              <div className="light-font-text">
                                {item.propertyName}
                              </div>
                              <div className="fs-12 light-font-text grey-text">
                                {item.zipCode} {item.city}
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </Col>

                    <Col className="pb-2 pt-3 pe-2">
                      <Row style={{ alignItems: "center" }}>
                        <Col className="light-font-text">
                          <div className="d-flex align-items-center">
                            <CheckBox
                              onChange={handleAllProperties}
                              checked={
                                allProperties?.length ===
                                allPropertiesData?.data?.length
                              }
                              id={"handleAllProperties"}
                            />{" "}
                            All properties
                          </div>
                        </Col>
                        <Col className="grey-text nowrap me-4 ps-0" xs={2}>
                          <div
                            className="cursor-pointer "
                            onClick={() => setAllProperties([])}
                          >
                            <i className="light-font-text fs-12">
                              Deselect all
                            </i>
                          </div>
                        </Col>
                      </Row>
                      <div
                        className="scrollable-container-y"
                        style={{ height: "250px" }}
                      >
                        {filteredProperties?.map((data, i) => (
                          <Row key={i} className="mb-2 py-2 align-items-center">
                            <Col xs={9}>
                              <div className="d-flex align-items-center ps-5">
                                <div>
                                  <CheckBox
                                    id={data.property_id}
                                    checked={allProperties?.includes(
                                      data.property_id
                                    )}
                                    onChange={(e) => handleProperties(e, data)}
                                  />
                                </div>
                                <div className="cursor-pointer">
                                  {data.isStar == "1" ? (
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-star"
                                      style={{ color: "#F6E651" }}
                                      onClick={() =>
                                        setFavoriteHandler(
                                          data.property_id,
                                          data.isStar
                                        )
                                      }
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon="fa-regular fa-star"
                                      style={{ color: "#F6E651" }}
                                      onClick={() =>
                                        setFavoriteHandler(
                                          data.property_id,
                                          data.isStar
                                        )
                                      }
                                    />
                                  )}
                                </div>
                                <div className="ps-3">
                                  <div className="fw-300 fs-14">
                                    {data.propertyName}
                                  </div>
                                  <div className="fw-400 fs-12 grey-text nowrap">
                                    {data.zipCode} {data.city}
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col className="pe-0 d-flex flex-row-reverse pe-4">
                              <div
                                className="status-box font-bold fs-12 py-1 px-2"
                                style={{ height: "24px" }}
                              >
                                {data.type}
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </div>
                      <div>
                        <Row className="pt-2">
                          <Col xs={7}></Col>
                          <Col className="ms-2 px-0">
                            <ETButton
                              buttonText="RESET"
                              onClick={ResetProperties}
                            />
                          </Col>
                          <Col className="px-0">
                            <ETButton
                              buttonText="APPLY"
                              variant={"primary"}
                              onClick={handleApplySelectedProperties}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            }
            closeDropdown={applyClose}
          />
        </div>
        <div>
          <Button
            size="small"
            id="basic-button-calendar"
            aria-controls={anchorCalendar ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={anchorCalendar ? "true" : undefined}
            onClick={handleClickCalendar}
            sx={{
              borderRadius: "8px",
              "&:hover": { backgroundColor: "transparent" },
              padding: "0px",
            }}
            style={{
              color: "transparent",
            }}
          >
            <div className="date-picker-filter">
              <div className="d-flex justify-content-between gap-3">
                <div className="light-font-text ">
                  <div className="light-font-text ">
                    {`${format(date[0]?.startDate, "dd. MMM. yyyy")} - ${format(
                      date[0]?.endDate,
                      "dd. MMM. yyyy"
                    )}`}
                  </div>
                </div>
                <div>
                  <img
                    className="img-cal"
                    height={15}
                    src={Calender}
                    alt="calendar"
                  />
                </div>
              </div>
            </div>
          </Button>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorCalendar}
          open={anchorCalendar}
          onClose={handleCloseCalendar}
          MenuListProps={{
            "aria-labelledby": "basic-button-calendar",
          }}
          sx={{
            marginTop: "8px",
            backgroundColor: "rgba(0, 0, 0, 0.02)",
          }}
        >
          <MenuItem
            style={{
              color: "transparent",
            }}
            sx={{
              "&:hover": { backgroundColor: "transparent" },
              borderRadius: "8px",
              padding: "0px",
              overflow: "hidden",
              border: "1px solid #F4F5F5",
            }}
          >
            <div className="date-picker-modal-popup">
              <div className="date-picker-modal-popup-position">
                <div
                  className="medium fs-14 justify-content-center"
                  style={{
                    color: "#000",
                    paddingTop: "26px",
                    position: "absolute",
                    textAlign: "center",
                    width: "180px",
                  }}
                >
                  <span className="font-semibold fs-14">Shortcuts</span>
                </div>
                <DateRangePicker
                  onChange={(item) => setDate([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  weekStartsOn={1}
                  ranges={date}
                  direction="horizontal"
                  preventSnapRefocus={true}
                  calendarFocus="backwards"
                  staticRanges={staticRanges}
                  rangeColors={["#619130"]}
                />
              </div>
            </div>
          </MenuItem>
          <div
            className="py-2 px-3"
            style={{
              display: "flex",
              justifyContent: "end",
              gap: "15px",
              backgroundColor: "#f9fafa",
            }}
          >
            <ETButton
              variant={"white"}
              buttonText="RESET"
              width="10%"
              onClick={() => ResetFilter()}
            />
            <ETButton
              variant={"primary"}
              buttonText="APPLY"
              width="10%"
              onClick={() => DateFilter()}
            />
          </div>
        </Menu>
      </div>

      {/* TODO:UNCOMMENT <div
				style={{
					margin: "20px 0",
					backgroundColor: "#F9FAFA",
				}}>
				<div
					className="app"
					style={{
						backgroundColor: "#F9FAFA",
					}}>
					<Tabs>
						<TabList className="light p-0 pb-3">
							<Tab>
								<div className="fs-14">Overview</div>
							</Tab>
							<Tab>
								<div className="fs-14">Economy</div>
							</Tab>
							<Tab>
								<div className="fs-14">Traffic</div>
							</Tab>
							<Tab>
								<div className="fs-14">Events</div>
							</Tab>
							<Tab>
								<div className="fs-14">Customers</div>
							</Tab>
							<Tab>
								<div className="fs-14">Signings</div>
							</Tab>
						</TabList>

						<TabPanel>
							<ControlCenterOverview allProperties={allProperties} />
						</TabPanel>
						<TabPanel>
							<Economy
								startDateFormate={startDateFormate}
								endDateFormate={endDateFormate}
								allProperties={allProperties}
							/>
						</TabPanel>
						<TabPanel>
							<Traffic />
						</TabPanel>
						<TabPanel>
							<Events />
						</TabPanel>
						<TabPanel>
							<Customers />
						</TabPanel>
						<TabPanel>
							<Signings />
						</TabPanel>
					</Tabs>
				</div>
			</div> */}
      <div
        style={{
          fontFamily: " Cerebri sans-light",
          fontSize: "14px",
          cursor: "pointer",
        }}
      >
        <span className="me-2 fs-16">Dashboard</span>
        <span className="me-2">
          <FontAwesomeIcon
            size="sm"
            icon="fa-regular fa-angle-right"
            style={{ color: "#000000" }}
          />{" "}
        </span>
        <GreenSpan className="me-2 fs-16 breadcrumbs">{Breadcrumbs}</GreenSpan>
      </div>

      <div className="d-flex pt-2 pb-3">
        {controlCenterTabs?.map((item, i) => (
          <NavLink
            key={i}
            state={{ propId: propertyId }}
            to={item.link}
            style={{ textDecoration: "none" }}
            onClick={() => {
              setSelectedTab(item.tab);
              // setSelectedSubTabType(["Overview"])
            }}
            className=" me-3 "
          >
            {item.tab}
          </NavLink>
        ))}
      </div>

      <Outlet />
    </Container>
  );
};

export default ControlCenter;
