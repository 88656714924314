import React, { useEffect, useMemo, useState } from "react";
import CustomToolTip from "../../../../../../../../../Components/Tooltip/CustomTooltip";
import ETButton from "../../../../../../../../../Components/Buttons/ETButton";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "../../../../../../../../../Components/DropDown/ReactSelect";
import MRTTheme from "../../../../../../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";
import { InterestedTableData } from "./data";

import {
	SortIcon,
	TableRowsStyle,
	paginationoptions,
} from "../../../../../../../../../Components/table/MRTGlobalProps";
import Menus from "../../../../../../../../../Components/Menus/Menus";

import DateRangeFilter from "../../../../../../../../../Components/DateRangePicker/DateRangeFilter";
import TagToolTip from "../../../../../../../../../Components/Tooltip/PropertyTagToolTip";
import {
	GreenDiv,
	H3,
	StyledLoadingBar,
} from "../../../../../../../../../styles/Common.style";
import CustomPopup from "../../../../../../../../../Components/CustomPopup";
import MoveAnotherUnit from "../commonPage/MoveAnotherUnit";
import DeleteCustomerAction from "../commonPage/DeleteCustomerAction";
import StartScreening from "../commonPage/StartScreening";
import ShowTooltip from "../../../../../../../../../Components/Tooltip/ShowTooltip";
import LoadingBar from "react-top-loading-bar";
import InvitationSend from "../createInvite/InvitationSend";
import AttachedDocuments from "../createInvite/AttachedDocuments";
import ExistingEvent from "../createInvite/ExistingEvent";
import { customproperties } from "../../../../../../../../../styles/Theme";
import { NoRecordsToDisplay } from "../../../../../../../../../utils/validationConstants";
import { useBreadcrumb } from "../../../../../../../../../Components/BreadcrumbContext ";

const Interested = () => {
	const [moveToAnotherUnitsShow, setMoveToAnotherUnitsShow] = useState(false);
	const [deleteShow, setDeleteShow] = useState(false);
	const [startScreeningShow, setStartScreeningShow] = useState(false);
	const [show, setShow] = useState(false);
	const [progress, setProgress] = useState(30);
	const [stepWizard, setStepWizard] = useState("ExistingEvent");
	const { updateBreadcrumbs } = useBreadcrumb();
	useEffect(() => {
		updateBreadcrumbs(["Overview", "Requests", "Interested"]);
	}, []);
	const WizardsCreateInvitePages = {
		ExistingEvent: (
			<ExistingEvent
				setShow={setShow}
				show={show}
				setStepWizard={setStepWizard}
				progress={progress}
				setProgress={setProgress}
			/>
		),

		AttachedDocuments: (
			<AttachedDocuments
				setShow={setShow}
				show={show}
				setStepWizard={setStepWizard}
				progress={progress}
				setProgress={setProgress}
			/>
		),

		InvitationSend: (
			<InvitationSend
				setShow={setShow}
				show={show}
				setStepWizard={setStepWizard}
				progress={progress}
				setProgress={setProgress}
			/>
		),
	};

	const columns = useMemo(
		() => [
			{
				accessorFn: (row) => `${row.name}  `,
				header: "Name",
				enableHiding: false,
				// size: 100,
				enableGlobalFilter: true,
				Cell: ({ row }) => (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "8px",
							cursor: "pointer",
						}}
					>
						<div>
							<TagToolTip bgcolor={"#88888"} text="Need Help About Tags" />
						</div>
						<div>{row.original.name}</div>
					</div>
				),
			},
			{
				accessorFn: ``,
				header: "Pre-screening",
				enableHiding: false,
				size: 100,
				enableGlobalFilter: true,
				Cell: ({ row }) => (
					<div>
						<GreenDiv>
							<span className="cursor-pointer"> Show </span>
						</GreenDiv>
					</div>
				),
			},
			{
				accessorFn: ``,
				header: "History",
				enableHiding: false,
				size: 100,
				enableGlobalFilter: true,
				Cell: ({ row }) => (
					<ShowTooltip
						tooltopWidth={200}
						tooltipContent={
							<div className="pt-2 fs-12">
								<ul>
									<li>Registration date</li> <li>First invite date</li>
									<li>First appointment date</li>
									<li>Second invite date</li>
									<li>Second appointment date</li>
								</ul>
							</div>
						}
					/>
				),
			},
			{
				accessorKey: "responsible_agent",
				header: "Responsible agent",
				enableHiding: false,
				size: 200,
				enableGlobalFilter: false,
			},
			{
				accessorKey: "Interest_confirmed",
				header: "Interest confirmed",
				enableHiding: false,
				// size: 200,
				enableGlobalFilter: false,
			},
			{
				accessorKey: "status",
				header: "Status",
				enableHiding: false,
				size: 240,
				enableGlobalFilter: false,
			},
		],
		[]
	);

	const renderEmptyRowsFallback = () => (
		<div className="d-flex justify-content-center w-100 grey-text py-4 light-font-text">
			<div>No data available!!</div>
		</div>
	);
	return (
		<div>
			{" "}
			<div>
				<div className="d-flex w-100">
					<div className="p-4 mt-2 w-75">
						<div className="form-label-title  d-flex align-items-center gap-2">
							<H3> Interested </H3>
							<CustomToolTip text="Interested" />
						</div>
						<div className="light-font-text">
							Below is a list of the confirmed interested on this unit. It is
							possible to start screening, make a new appointment, see details
							on invitations and appointments and move to another unit in the
							system.
						</div>
					</div>
					<div className=" w-25 mt-2 py-4 pe-4 d-flex justify-content-end">
						<ETButton variant={"primary"} buttonText="START SCREENING" />
					</div>
				</div>
				<div className="px-4 pt-2 pb-4">
					<MRTTheme
						colorVariantsForTableRow={["gray"]}
						colorVariantsForTableHead={["white"]}
						table={
							<MaterialReactTable
								columns={columns}
								// data={InterestedTableData} //data is undefined on first render
								data={[]}
								icons={{ ...SortIcon }}
								renderEmptyRowsFallback={renderEmptyRowsFallback}
								localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
								enableColumnActions={false}
								enableRowSelection
								enableRowActions
								enableSelectAll
								enableTopToolbar={true}
								enableGlobalFilter
								globalFilterFn="contains"
								initialState={{
									showGlobalFilter: true,
									showColumnFilters: false,
								}}
								renderTopToolbar={({ table }) => (
									<>
										<div className="px-0 ">
											<Row>
												<Col xl={4} lg={6} className="pb-2">
													<DateRangeFilter
													// date={dateRange}
													// setDate={setDateRange}
													// handleDateChange={handleDateChange}
													/>
												</Col>
												<Col xl={3} lg={5} className="ps-lg-0 pb-2">
													<ReactSelect placeholder={"Responsible agent"} />
												</Col>
											</Row>
										</div>
									</>
								)}
								muiTableContainerProps={{
									sx: {
										padding: "0px !important",
									},
								}}
								displayColumnDefOptions={{
									"mrt-row-select": {
										header: "",
									},
									"mrt-row-actions": {
										header: "",
									},
								}}
								positionActionsColumn="last"
								renderRowActions={({ row }) => (
									<Menus
										itemheight={23}
										itemwidth={23}
										iconborderradius="4px"
										itemcontainerwidth="200px"
										component={
											<div className="light-font-text">
												<div
													className="p-2 cursor-pointer"
													onClick={() => setStartScreeningShow(true)}
												>
													Start screening
												</div>
												<hr className="hr m-0"></hr>
												<div
													className="p-2 cursor-pointer"
													onClick={() => setShow(true)}
												>
													Create new appointment
												</div>
												<hr className="hr m-0"></hr>

												<div
													className="p-2 cursor-pointer"
													onClick={() => setMoveToAnotherUnitsShow(true)}
												>
													Move to another unit
												</div>

												<hr className="hr m-0"></hr>
												<div className="p-2 cursor-pointer">
													Go to contact card
												</div>
												<hr className="hr m-0"></hr>
												<div
													className="p-2 cursor-pointer"
													onClick={() => setDeleteShow(true)}
												>
													Delete
												</div>
											</div>
										}
									/>
								)}
								muiTablePaperProps={{
									elevation: 0,
									fontFamily: "Cerebri sans-light !important",
									sx: {
										borderRadius: "0",
										border: "none",
										// backgroundColor: "#F9FAFA",
										table: {
											borderCollapse: "separate",
											borderSpacing: "0 5px",
										},
									},
								}}
								muiTableBodyRowProps={{
									selected: false,
								}}
								muiTablePaginationProps={{
									...paginationoptions,
								}}
								muiTableHeadRowProps={{
									sx: {
										...TableRowsStyle,
									},
								}}
							/>
						}
					/>
				</div>
			</div>
			<CustomPopup
				open={moveToAnotherUnitsShow}
				onHide={() => setMoveToAnotherUnitsShow(false)}
				title="Move customer to another unit"
				titleTooltipText="Move customer to another unit"
				title_color="black"
				size={"xl"}
				enableCrossButton={false}
				action_button_label="YES"
				action_button_label_color="white"
				action_button_color="#619130"
				cancel_button={true}
				cancel_button_bordercolor="grey"
				cancel_button_label="NO"
				cancel_button_label_color="black"
				cancel_button_color="#fff"
				footer_color={"#F9FAFA"}
				deletePopUpStyle={true}
				titleTooltip={true}
			>
				<div>
					<MoveAnotherUnit
						moveToAnotherUnitsShow={moveToAnotherUnitsShow}
						setMoveToAnotherUnitsShow={setMoveToAnotherUnitsShow}
					/>
				</div>
			</CustomPopup>
			{/* Delete */}
			<DeleteCustomerAction
				deleteCustomerAction={deleteShow}
				setDeleteCustomerAction={setDeleteShow}
			/>
			{/* start screening */}
			<StartScreening
				startScreeningShow={startScreeningShow}
				setStartScreeningShow={setStartScreeningShow}
			/>
			{/* create new appointment */}
			<CustomPopup
				open={show}
				onHide={() => setShow(false)}
				title="Create invite for a viewing appointment"
				titleTooltipText="Create invite for a viewing appointment"
				title_color="black"
				size={"xl"}
				enableCrossButton={false}
				action_button_label="YES"
				action_button_label_color="white"
				action_button_color="#619130"
				cancel_button={true}
				cancel_button_bordercolor="grey"
				cancel_button_label="NO"
				cancel_button_label_color="black"
				cancel_button_color="#fff"
				footer_color={"#F9FAFA"}
				deletePopUpStyle={true}
				titleTooltip={true}
			>
				<div>
					<StyledLoadingBar>
						<LoadingBar
							color={customproperties.primaryColor}
							progress={progress}
							containerStyle={{
								position: "absolute",
								height: "2.5px",
								// background: "#f0f0f0",
							}}
							shadow={false}
							onLoaderFinished={() => setProgress(0)}
						/>
					</StyledLoadingBar>
				</div>
				<div
					className=" me-1"
					// style={{ height: "600px" }}
				>
					{WizardsCreateInvitePages[stepWizard]}
				</div>
			</CustomPopup>
		</div>
	);
};

export default Interested;
