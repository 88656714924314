import { Endpoint, Site } from "./API/Endpoints";
import { Delete, Get, Post, Put } from "./API/Method";

// Get Text packages
export const GetTextPackage = (propId, textCategoryId) => {
	propId =
		propId === "" || propId === null || propId === undefined ? 2 : propId;
	textCategoryId =
		textCategoryId === "" ||
		textCategoryId === null ||
		textCategoryId === undefined
			? 0
			: textCategoryId;

	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.Get_Text_Details,
			null,
			propId + "/packages/category/" + textCategoryId
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

// Get Text category
export const GetTextCategory = () => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Get_Text_Category, null)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};
// Get text sub category
export const GetTextSubCategory = () => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Text_GetTextsubcategory, null)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

// Post Add Text
export const PostAddText = (addTextData) => {
	return new Promise((resolve, reject) => {
		Post(Site.ET, Endpoint.ET.Post_Text_AddText, null, addTextData)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

// Delete Text
export const DeleteText = (propertyId, textId) => {
	return new Promise((resolve, reject) => {
		Delete(
			Site.ET,
			Endpoint.ET.Delete_Text,
			null,
			propertyId + "/packages/" + textId
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};
// Put Text setActive
export const PutTextSetActive = (data) => {
	return new Promise((resolve, reject) => {
		Put(Site.ET, Endpoint.ET.Put_Text_SetActive, null, data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Get text by text Id
export const GetTextByTextId = (propertyId, packageId) => {
	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.Get_Text_By_TextId,
			null,
			propertyId + "/packages/" + packageId
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

// Put Text UpdateText basic details
export const PutUpdateTextBasicDetails = (data) => {
	return new Promise((resolve, reject) => {
		Put(Site.ET, Endpoint.ET.Put_UpdateText_BasicDetails, null, data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};
// Put Text UpdateText complete details
export const PutUpdateTextCompleteDetails = (data) => {
	return new Promise((resolve, reject) => {
		Put(Site.ET, Endpoint.ET.Put_UpdateText_CompleteDetails, null, data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

// Post Clone Text Package
export const PostCloneTextPackage = (cloneTextData) => {
	return new Promise((resolve, reject) => {
		Post(Site.ET, Endpoint.ET.Post_Cone_TextPackage, null, cloneTextData)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Get edit Units Text by propertyId
export const GetEditUnitTextByPropertyIdByUnitId = (
	propertyId,
	unitId,
	categoryId = 0
) => {
	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.Get_EditUnit_Text_ByPropertyId_ByUnitId,
			null,
			propertyId + "/unit/" + unitId + "/category/" + categoryId
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

export const GetEditUnitText = (propertyId, textCategoryId, unitId) => {
	propertyId =
		propertyId === "" || propertyId === null || propertyId === undefined
			? 2
			: propertyId;
	textCategoryId =
		textCategoryId === "" ||
		textCategoryId === null ||
		textCategoryId === undefined
			? 0
			: textCategoryId;

	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.Get_Text_Details,
			null,
			propertyId + "/unit/" + unitId + "/category/" + textCategoryId
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};
