import React, { useEffect } from "react";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { Form, InputGroup } from "react-bootstrap";
import NormalGroupText from "../../../../Components/GroupText/NormalGroupText";
import { Controller, useForm } from "react-hook-form";
import { useAppState } from "../AllState";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import {
  RadioInput,
  RadioLabel,
  RadioWrapper,
} from "../../../../Components/RadioButton/ETRadioButton";
import { H4 } from "../../../../styles/Common.style";

const Maintenance = ({
  submitRef,
  handleNext,
  prevRef,
  activeTenancyForm,
  getConfigurationDetailsData,
  handleEditConfig,
  previewRef,
}) => {
  const [state, setState] = useAppState();
  const {
    handleSubmit,
    register,
    getValues,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: state[activeTenancyForm] });
  const saveData = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 8, true);
    // handleNext(1);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  const preview = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 8, false);
    // handleNext(1);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  // saving all field into context state
  // const prevSubmit = () => {
  //   // setState({ ...state, ...getValues() });
  //   setState({ ...state, [activeTenancyForm]: { ...getValues() } });
  // };
  useEffect(() => {
    setValue(
      "internal_maitainence",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "internal_maitainence"
      )[0]?.actual_value
    );
    setValue(
      "contract_creation_date",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "contract_creation_date"
      )[0]?.actual_value
    );
    setValue(
      "account_balance",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "account_balance"
      )[0]?.actual_value
    );
  }, [getConfigurationDetailsData]);
  return (
    <>
      <Form className="bg-white radius-8">
        <div
          className="px-4 py-3"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <H4 className="actors-section-title">§8 Maintenance</H4>
          <div className="light-font-text grey-text">
            Lorem ipsum dolor sit lorem ipsum dolor sit
          </div>
        </div>

        {/* ---------------------Duty------------------------------- */}
        <div
          className="px-4 py-4 light-font-text"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div className="d-flex align-items-center" style={{ alignItems: "center" }}>
            <span className="form-label-title me-2">Duty</span>{" "}
            <CustomToolTip  text="Duty" />
          </div>

          <div>
            <div className=" mb-2">
              The interior maintenance of the lease is for (This data will
              retrieve from unit):
            </div>

            <div
              className="d-flex mb-2 gap-2"
              style={{ alignItems: "flex-start" }}
            >
              {" "}
              <RadioWrapper>
                <RadioInput
                  className="cursor-not-allowed"
                  disabled={true}
                  name="group1"
                  type="radio"
                  value="1"
                  id="radio-1"
                  {...register("internal_maitainence")}
                />
                <RadioLabel>Landlord</RadioLabel>
              </RadioWrapper>
            </div>
            <div
              className="d-flex mb-2 gap-2"
              style={{ alignItems: "flex-start" }}
            >
              <RadioWrapper>
                <RadioInput
                  className="cursor-not-allowed"
                  disabled={true}
                  name="group1"
                  type="radio"
                  value="2"
                  id="radio-1"
                  {...register("internal_maitainence")}
                />
                <RadioLabel>Tenant</RadioLabel>
              </RadioWrapper>
            </div>
          </div>
        </div>

        {/* ---------------------Account------------------------------ */}
        <div className="p-4" style={{ borderBottom: "1px solid #F0F0F0" }}>
          <div className="d-flex align-items-center" style={{ alignItems: "center" }}>
            <span className="form-label-title me-2">Account</span>{" "}
            <CustomToolTip size="sm" text="Account" />
          </div>
          <div className="light-font-text me-4" style={{ lineHeight: "28px" }}>
            If the landlord has the interior maintenance obligation, an account
            for interior maintenance has been created. At the creation of the
            contract on
            <Controller
              control={control}
              {...register("contract_creation_date")}
              render={({ field: { onChange, value } }) => {
                return (
                  <input
                    type="date"
                    className="normalTextstyle mx-2"
                    onChange={(e) => onChange(e)}
                    value={value}
                  />
                );
              }}
            />
            {errors.accountContractDate && (
              <div className="mx-2 d-inline-block">
                <p
                  className="red-text fs-14 mb-0"
                  style={{ width: "max-content" }}
                >
                  (date is required)
                </p>
              </div>
            )}
            it says{" "}
            <span style={{ position: "relative", display: "inline-flex" }}>
              <Controller
                control={control}
                {...register("account_balance")}
                render={({ field: { onChange, value } }) => {
                  return (
                    <NormalGroupText
                      inputheight={"26px"}
                      textheight={"27px"}
                      graylabel="DKK"
                      textWidth="25%"
                      type="number"
                      lable="0,00"
                      onChange={(e) => onChange(e)}
                      value={value}
                    />
                  );
                }}
              />
            </span>
            on the account. The amount may have changed since the contract was
            drawn up as a result of the landlord's refurbishment of the
            property.
          </div>
          <div className="light-font-text"></div>
        </div>

        {/* ----------------------Please Note--------------- */}
        <div
          className="p-4 light-font-text"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div className="d-flex align-items-center" >
            <span className="form-label-title me-2">Please note</span>{" "}
            <CustomToolTip  text="Please note" />
          </div>
          <div style={{ lineHeight: "normal" }}>
            <div className="pb-2 me-4">
              Internal maintenance means the maintenance of the apartment,
              including whitewashing, painting, wallpapering and floor
              treatment.
            </div>
            <div className="pb-2 me-4">
              External maintenance means maintenance of all parts of the
              property and the rented premises that are not covered by the
              internal maintenance.
            </div>
            <div className="pb-4 me-4">
              The tenant is obliged to maintain locks and keys, unless otherwise
              agreed, in accordance with the Rent Act.
            </div>
          </div>
        </div>
        <button
          type="submit"
          onClick={handleSubmit(saveData)}
          ref={submitRef}
          style={{ display: "none" }}
        />
        <button
          type="submit"
          onClick={handleSubmit(preview)}
          ref={previewRef}
          style={{ display: "none" }}
        />
      </Form>
      {/* <button ref={prevRef} onClick={prevSubmit} style={{ display: "none" }} /> */}
    </>
  );
};

export default Maintenance;
