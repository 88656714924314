import React from "react";
import ETButton from "../../../../../../../../../Components/Buttons/ETButton";
import { Col, Row } from "react-bootstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSelect from "../../../../../../../../../Components/DropDown/ReactSelect";
import Toggle from "../../../../../../../../../Components/Switch/Switch";
import GroupText from "../../../../../../../../../Components/GroupText/GroupText";
import "./invite.css";
const ExistingEvent = ({
  setShow,
  show,
  setStepWizard,
  progress,
  setProgress,
}) => {
  const localizer = momentLocalizer(moment);

  const formats = {
    // dayFormat: "dd", // This will display only 2 letters of the week header (e.g., "Mo", "Tu", "We", etc.)
    dateFormat: "D", // Used 'D' to display the date as a single digit
  };

  const events = [
    {
      id: 1,
      start: "2023-08-05",
      end: "2023-08-05",
      title: "Open House",
      user: "Jesper Thorkildsen",
      time: "10:00 ",
      data: {
        type: "one",
      },
    },

    {
      id: 2,
      // start: moment("2023-05-05").toDate(),
      // end: moment("2023-05-05").toDate(),
      start: "2023-08-05",
      end: "2023-08-05",
      title: "Private view",
      user: "Jesper Thorkildsen",
      time: " 11:00",
      isNew: true,
    },
  ];
  const calendarConfig = {
    components: {
      // event: CustomEvent,
      toolbar: CustomToolbar,
      // eventWrapper: CustomEventWrapper,
    },
  };
  const handleSubmit = () => {
    setStepWizard("AttachedDocuments");
    setProgress(60);
  };
  const handleBack = () => {
    setShow(false);
    setProgress(30);
  };
  return (
    <div>
      <div className="Popups-inner-scrollable-container-height scrollable-container-y">
        <div className="light-font-text p-4">
          It is possible to invite the customer to an already scheduled event at
          the unit or the property, or to create a new appointment below.
        </div>
        <div className="px-4">
          <Row>
            <Col lg={7}>
              <div className="pb-4">
                <div className="form-label-title pb-3">
                  Select an existing event
                </div>
                <Row>
                  <Col lg={10} className="pe-lg-0">
                    <ReactSelect placeholder={"Event"} />
                  </Col>
                </Row>
              </div>

              <div className="pb-4">
                <div className="form-label-title pb-3">Time of appointment</div>
                <Row>
                  <Col lg={4} className="pe-lg-0">
                    <div className="form-input ">
                      <input
                        type="date"
                        placeholder="Date"
                        id="Date"
                        // value={value}
                        // onChange={(e) => {
                        // 	onChange(e);
                        // 	clearErrors("long");
                        // }}
                      />
                      <label htmlFor="Date">Date</label>
                    </div>
                  </Col>
                  <Col lg={3} className="pe-lg-0">
                    <div className="form-input-group ">
                      <input type="time" id="Timeone" placeholder="Time" />
                      <label htmlFor="Timeone">Time</label>
                    </div>
                  </Col>
                  <Col lg={3} className="pe-lg-0">
                    <div className="form-input-group ">
                      <input type="time" id="Timetwo" placeholder="Time" />
                      <label htmlFor="Timetwo">Time</label>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="pb-4">
                <div className="form-label-title pb-3">Responsible agent</div>
                <Row>
                  <Col lg={10} className="pe-lg-0">
                    <ReactSelect placeholder={"Select agent"} />
                  </Col>
                </Row>
              </div>

              <div className="light-font-text d-flex align-items-center gap-2">
                {" "}
                <Toggle id={"Id"} />
                Do you want to create time slots for the customer to choose
                between?
              </div>

              <Row className="pt-4 pb-3">
                <Col lg={10} className="pe-lg-0">
                  <ReactSelect placeholder={"Interval time slots"} />
                </Col>
              </Row>
              <Row className=" pb-4">
                <Col lg={10} className="pe-lg-0">
                  <ReactSelect placeholder={"Break between intervals"} />
                </Col>
              </Row>
              <div className="pt-1 light-font-text d-flex align-items-center gap-2">
                {" "}
                <Toggle id={"Id2"} />
                No limit on participants per time slots
              </div>

              <Row className="pt-4 ">
                <Col lg={10} className="pe-0">
                  <GroupText
                    lable={"Number of participants per time slot"}
                    type={"number"}
                    id={"Number of participants per time slot"}
                    // value={"0"}
                    textWidth={"50%"}
                    dynamicGrayLabel={false}
                    graylabel={
                      <div>
                        <span className="grey-text pe-2">1st time slot:</span>
                        <span>00:00 - 00:00</span>
                      </div>
                    }
                  />
                </Col>
              </Row>

              <div className="light-font-text pt-3 pb-4">
                <span className="form-label-title">Total count:</span> This will
                allow up to{" "}
                <span className="form-label-title">0 participants</span> to join
              </div>
            </Col>
            <Col lg={5}>
              <div className="invite-event-calendar">
                <Calendar
                  localizer={localizer}
                  views={["month"]}
                  events={events}
                  toolbar={(toolbarProps) => (
                    <CustomToolbar {...toolbarProps} />
                  )}
                  formats={formats}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 431 }}
                  components={calendarConfig.components}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="py-2 px-3 d-flex flex-row justify-content-between  footer-section-button-container">
        <div className="d-flex gap-2">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setShow(false)}
          />
          <ETButton variant={"white"} buttonText="BACK" onClick={handleBack} />
        </div>

        <ETButton
          variant={"primary"}
          buttonText="NEXT"
          type={"submit"}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default ExistingEvent;

const CustomToolbar = ({ label, date, onNavigate }) => {
  const handleNext = () => {
    // Custom logic for the next button
    // For example, you can navigate to the next month
    onNavigate("next", moment(date).add(1, "month"));
  };

  const handlePrev = () => {
    // Custom logic for the back button
    // For example, you can navigate to the previous month
    onNavigate("prev", moment(date).subtract(1, "month"));
  };

  return (
    <div className="rbc-toolbar ">
      <button onClick={handlePrev}>
        <FontAwesomeIcon
          icon="fa-regular fa-angle-left"
          style={{ color: "#000000" }}
        />
      </button>
      <span className="rbc-toolbar-label ">{label}</span>
      <button onClick={handleNext}>
        <FontAwesomeIcon
          icon="fa-regular fa-angle-right"
          style={{ color: "#000000" }}
        />
      </button>
    </div>
  );
};
