// ui created by Suraj
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import Toggle from "../../../../Components/Switch/Switch";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { Col, Row, Form } from "react-bootstrap";
import { H3, PlusIcon } from "../../../../styles/Common.style";
import ETButton from "../../../../Components/Buttons/ETButton";
import { useScreening } from "../../../../hooks/stores/useScreeningStore";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useGetScreeningDeadlineAction } from "../../../../hooks/queries/useScreeningQueries";
import InputField from "../../../../Components/Textfield/InputField";
import { toast } from "react-toastify";
// import { Form } from "react-router-dom";

const ScreeningConditions = ({
  setScreeningConditions,
  fieldsData,
  // setScreenigLogicType,
  screeningSectionData,
  screeningData,

  isRedFlagActivated,
  setIsRedFlagActivated,
  setIsYellowFlagMessageActivated,
  isYellowFlagMessageActivated,
  isRedFlagMessageActivated,
  setIsRedFlagMessageActivated,
  setYellowFlagMessage,
  setRedFlagMessage,
  handleYellowFlagMessageChange,
  handleRedFlagTextMessageChange,
}) => {
  // console.log("🚀 ~ file: ScreeningConditions.js:33 ~ isRedFlagMessageActivated:", isRedFlagMessageActivated)
  // console.log("🚀 ~ file: ScreeningConditions.js:33 ~ isYellowFlagMessageActivated:", isYellowFlagMessageActivated)
  const {
    setIsScreeningRadioChecked,
    languageId,
    // redFlagMessage,
    // setRedFlagMessage,
    setPossibleValue,
    possibleValue,
    screeningRules,
    isYellowFlagActivated,
    setIsYellowFlagActivated,
    setYellowScreeningConditions,
    redScreeningConditions,
    setRedScreeningConditions,
    screeningLogicType,
    setScreenigLogicType,
    screeeningConditionsStore,
    setScreeningConditionsStore,
  } = useScreening((state) => state);

  // console.log("fieldsData--||", fieldsData);
  // console.log("screeeningConditionsStore--||", screeeningConditionsStore);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    watch,
    reset,
    resetField,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      //   yellowscreeningConditions: screeeningConditionsStore?.filter(
      //     (x) => x?.screening_field_id === fieldsData?.[0]?.screening_field_id
      //   )?.[0]?.yellow_flag_condition,
      //   redScreeningConditions: screeeningConditionsStore?.filter(
      //     (x) => x?.screening_field_id === fieldsData?.[0]?.screening_field_id
      //   )?.[0]?.red_flag_condition,
      yellowscreeningConditions: [
        {
          fields: "",
          condition: "",
          optionValue: "",
        },
        // {
        //   row_id: Math.floor(Date.now() / 1000),
        //   yellowscreeningConditions: [],
        //   triggerAction: "",
        //   activateMessage: false,
        //   message: "",
        //   LogicType: "",
        // },
      ],
      redScreeningConditions: [
        {
          fields: "",
          condition: "",
          optionValue: "",
        },
      ],
    },
  });

  const {
    fields: redFields,
    append: appendRedConditions,
    remove: removeRedConditions,
  } = useFieldArray({
    control,
    name: "redScreeningConditions",
  });

  const {
    fields: yellowFields,
    append: appendYellowConditions,
    remove: removeYellowConditions,
  } = useFieldArray({
    control,
    name: "yellowscreeningConditions", // Adjust the name based on your data structure
  });

  useEffect(() => {
    const yellowscreeningConditions = screeeningConditionsStore?.filter(
      (x) => x?.screening_field_id === fieldsData?.[0]?.screening_field_id
    )?.[0]?.yellow_flag_condition?.[0]?.fields;
    const redScreeningConditions = screeeningConditionsStore?.filter(
      (x) => x?.screening_field_id === fieldsData?.[0]?.screening_field_id
    )?.[0]?.red_flag_condition?.[0]?.fields;

    reset(
      {
        redScreeningConditions: redScreeningConditions?.length
          ? redScreeningConditions?.map((item) => ({
              fields: item?.fields,
              condition: item?.condition,
              optionValue: item?.optionValue,
            }))
          : [
              {
                fields: "",
                condition: "",
                optionValue: "",
              },
            ],

        yellowscreeningConditions: yellowscreeningConditions?.length
          ? yellowscreeningConditions?.map((item) => ({
              fields: item?.fields,
              condition: item?.condition,
              optionValue: item?.optionValue,
            }))
          : [
              {
                fields: "",
                condition: "",
                optionValue: "",
              },
            ],
      },

      [reset]
    );

    const yellowFlagCondition = screeeningConditionsStore?.filter(
      (x) => x?.screening_field_id === fieldsData?.[0]?.screening_field_id
    )?.[0]?.yellow_flag_condition?.[0];
    const redFlagCondition = screeeningConditionsStore?.filter(
      (x) => x?.screening_field_id === fieldsData?.[0]?.screening_field_id
    )?.[0]?.red_flag_condition?.[0];

    setValue("YellowFlagLogicType", yellowFlagCondition?.LogicType);
    setValue("YellowFlagTriggerAction", yellowFlagCondition?.triggerAction);
    setValue("YellowFlagMessage", yellowFlagCondition?.message);
    setValue("RedFlagLogicType", redFlagCondition?.LogicType);
    setValue("RedFlagTriggerAction", redFlagCondition?.triggerAction);
    setValue("redFlagMessage", redFlagCondition?.message);

    setIsYellowFlagActivated(
      yellowFlagCondition?.is_yellow_flag_active === 0 ? false : true
    );
    setIsRedFlagActivated(
      redFlagCondition?.is_red_flag_active === 0 ? false : true
    );
    setIsRedFlagMessageActivated(
      redFlagCondition?.activateMessage === 0 ? false : true
    );
    setIsYellowFlagMessageActivated(
      yellowFlagCondition?.activateMessage === 0 ? false : true
    );

    return () => {};
  }, [screeeningConditionsStore]);

  const handleScreening = () => {
    // Reset the radio input when "back" is clicked
    setIsScreeningRadioChecked(false);
  };
  const handleScreeningFormSubmit = (data) => {
    // console.log(data, "form data");

    const updatedArray = screeeningConditionsStore?.map((item) => {
      if (
        item?.screening_field_id?.toString() ===
        fieldsData?.[0]?.screening_field_id?.toString()
      ) {
        return {
          ...item,
          yellow_flag_condition: item?.yellow_flag_condition.map(
            (condition) => {
              return isYellowFlagActivated
                ? {
                    ...condition,
                    is_yellow_flag_active: isYellowFlagActivated ? 1 : 0,
                    LogicType: data?.YellowFlagLogicType || "",
                    activateMessage: isYellowFlagMessageActivated ? 1 : 0,
                    message: data?.YellowFlagMessage || "",
                    triggerAction: data?.YellowFlagTriggerAction || "",
                    fields: data?.yellowscreeningConditions?.length
                      ? data?.yellowscreeningConditions
                      : [],
                  }
                : {
                    ...condition,
                    LogicType: "",
                    activateMessage: 0,
                    fields: [],
                    is_yellow_flag_active: 0,
                    message: "",
                    triggerAction: "",
                  };
            }
          ),
          red_flag_condition: item?.red_flag_condition?.map((condition) => {
            return isRedFlagActivated
              ? {
                  ...condition,
                  is_red_flag_active: isRedFlagActivated ? 1 : 0,
                  LogicType: data?.RedFlagLogicType || "",
                  activateMessage: isRedFlagMessageActivated ? 1 : 0,
                  message: data?.RedFlagMessage || "",
                  triggerAction: data?.RedFlagTriggerAction || "",
                  fields: data?.redScreeningConditions?.length
                    ? data?.redScreeningConditions
                    : [],
                }
              : {
                  ...condition,
                  LogicType: "",
                  activateMessage: 0,
                  fields: [],
                  is_red_flag_active: 0,
                  message: "",
                  triggerAction: "",
                };
          }),
        };
      } else {
        return item;
      }
    });

    setScreeningConditionsStore(updatedArray);
    toast.success("Conditions applied");
  };

  const { data: GetScreeningDeadlineActionData } =
    useGetScreeningDeadlineAction();
  const screeningDeadlineActionData = GetScreeningDeadlineActionData?.data;

  const handleFirstToggleChange = (e) => {
    const isChecked = e.target.checked;
    setIsYellowFlagActivated(isChecked);
    setIsYellowFlagMessageActivated(
      isChecked ? isYellowFlagMessageActivated : false
    );
    if (!e?.target?.checked) {
      // resetField("YellowFlagLogicType")
      // reset("yellowscreeningConditions")
    }
  };

  const handleYellowFlagMessageActivatedChange = (checked) => {
    setIsYellowFlagMessageActivated(checked);
  };

  const handleRedFlagMessageChange = (checked) => {
    setIsRedFlagMessageActivated(checked);
  };

  const handelthirdToggleChange = (e) => {
    const isChecked = e.target.checked;
    setIsRedFlagActivated(isChecked);
    setIsRedFlagMessageActivated(isChecked ? isRedFlagMessageActivated : false);
  };
  // const handelfourthToggleChange = (e) => {
  // 	setIsRedFlagMessageActivated(e.target.checked);
  // };
  const fieldOptions = [
    {
      value: fieldsData?.[0]?.screening_field_id,
      label: fieldsData?.[0]?.name,
    },
  ];
  const getOperatorFromLabel = (label) => {
    switch (label) {
      case "equal to":
        return "=";
      case "does not equal to":
        return "!=";
      case "greater than":
        return ">";
      case "no less than":
        return ">=";
      case "has a value":
        return "exists";
      case "has no value":
        return "not exists";
      default:
        return null;
    }
  };
  const handleScreeningConditionsSubmit = () => {
    setScreeningConditions(false);
    const yData = getValues("yellowscreeningConditions");
    setYellowScreeningConditions(yData);

    const rData = getValues("redScreeningConditions");
    setRedScreeningConditions(rData);
  };
  const handleAddYellowScreeningCondition = () => {
    const newScreeningCondition = {
      fields: "",
      condition: "",
      optionValue: "",
    };
    // const newScreeningCondition = {
    //   row_id: Math.floor(Date.now() / 1000) + fields.length, // Ensure uniqueness
    //   fields: [],
    // };
    appendYellowConditions(newScreeningCondition);
  };
  const handleAddRedScreeningCondition = () => {
    const newScreeningCondition = {
      fields: "",
      condition: "",
      optionValue: "",
    };
    // const newScreeningCondition = {
    //   row_id: Math.floor(Date.now() / 1000) + fields.length, // Ensure uniqueness
    //   fields: [],
    // };
    appendRedConditions(newScreeningCondition);
  };
  return (
    <div className="py-4 field-conditions-container scrollable-container-y">
      <Form onSubmit={handleSubmit(handleScreeningFormSubmit)}>
        <div className="d-flex justify-content-between">
          <div className="w-75 ps-4  pe-5 " style={{ paddingBottom: "5px" }}>
            <div className=" d-flex gap-2">
              <H3>Set screening conditions </H3>
              <CustomToolTip height={12} text="Set screening conditions" />
            </div>

            <div className="light-font-text pb-2">
              Conditional logic allows you to validate the input user gives in
              the screening process.
            </div>
          </div>

          <div className=" px-4 d-flex justify-content-end">
            <button
              type="button"
              className="close cross-btn"
              onClick={() => {
                setScreeningConditions(false);
                handleScreening();
              }}
              data-dismiss="modal"
              aria-label="Close"
            >
              <span className="fs-20" aria-hidden="true">
                <FontAwesomeIcon
                  icon="fa-regular fa-chevron-right"
                  style={{ color: "#000000" }}
                />
              </span>
            </button>
          </div>
        </div>

        <hr className="accordionSidebar-hr mt-2 mb-0"></hr>

        {/*------------------ Yellow flag conditions------------- */}
        <>
          <div className="px-4 gap-2 py-3">
            <div className="form-label-title">Yellow flag conditions</div>
            <Toggle
              id="yellowfFlagConditions"
              checked={isYellowFlagActivated}
              onChange={handleFirstToggleChange}
            />
          </div>
          <div
            className="px-4 d-flex align-items-center light-font-text pb-2"
            style={!isYellowFlagActivated ? { opacity: "0.5" } : {}}
          >
            <div className="pe-2 w-25">
              <div className="form-input">
                <input value={"Yellow flag"} disabled={true} />
              </div>
            </div>
            <div>this field if </div>
            <div className="ms-2 me-2 w-25">
              <Controller
                control={control}
                {...register(`YellowFlagLogicType`)}
                rules={{ required: isYellowFlagActivated }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder={"Logic Type"}
                    disabled={!isYellowFlagActivated}
                    value={value}
                    options={[
                      { value: "1", label: "All" },
                      { value: "2", label: "Any" },
                    ]}
                    onChange={(e) => {
                      // console.log("e----", e);
                      onChange(e);
                      setScreenigLogicType(e);
                      clearErrors("YellowFlagLogicType");
                    }}
                    errorScope
                    iserror={errors?.YellowFlagLogicType}
                  />
                )}
              />
            </div>
            of the following matches:
          </div>
          {/* Fields array */}
          {yellowFields?.map((field, index) => (
            <div
              className="d-flex align-items-center w-100"
              key={field.id}
              style={!isYellowFlagActivated ? { opacity: "0.5" } : {}}
            >
              <div
                className="ms-4 me-3 my-2 px-4 py-3 radius-8"
                style={{ backgroundColor: "#F0F0F0", width: "90%" }}
              >
                <Row>
                  <Col lg={4} className="pe-0">
                    <Controller
                      control={control}
                      {...register(`yellowscreeningConditions.${index}.fields`)}
                      rules={{ required: isYellowFlagActivated }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder="Fields"
                          options={fieldOptions}
                          value={value}
                          iserror={
                            errors?.yellowscreeningConditions?.[index]?.fields
                          }
                          errorScope
                          disabled={!isYellowFlagActivated}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col lg={3} className="pe-0">
                    <Controller
                      control={control}
                      {...register(
                        `yellowscreeningConditions.${index}.condition`
                      )}
                      rules={{ required: isYellowFlagActivated }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder={"Conditions"}
                          disabled={!isYellowFlagActivated}
                          value={value}
                          options={[
                            { value: "1", label: "equal to" },
                            { value: "2", label: "does not equal to" },
                            { value: "3", label: "greater than" },
                            { value: "4", label: "Noless than" },
                            { value: "5", label: "has a value" },
                            { value: "6", label: "has no value" },
                          ]}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors(
                              `yellowscreeningConditions.${index}.condition`
                            );
                          }}
                          iserror={
                            errors?.yellowscreeningConditions?.[index]
                              ?.condition
                          }
                          errorScope
                        />
                      )}
                    />
                  </Col>
                  <Col lg={5}>
                    <Controller
                      control={control}
                      {...register(
                        `yellowscreeningConditions.${index}.optionValue`
                      )}
                      rules={{ required: isYellowFlagActivated }}
                      render={({ field: { onChange, value } }) => {
                        const fieldName = watch(
                          `yellowscreeningConditions.${index}.fields`
                        );
                        const selectedFieldType = screeningData?.filter(
                          (sD) => sD?.screening_field_id == fieldName?.value
                        );
                        return selectedFieldType?.[0]?.field_type ===
                          "select" ||
                          selectedFieldType?.[0]?.field_type === "radio" ||
                          selectedFieldType?.[0]?.field_type === "checkbox" ? (
                          <ReactSelect
                            value={value}
                            options={possibleValue?.map((opt) => ({
                              value: opt?.Id,
                              label: opt?.value,
                            }))}
                            disabled={!isYellowFlagActivated}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors(
                                `yellowscreeningConditions.${index}.optionValue`
                              );
                            }}
                          />
                        ) : (
                          (selectedFieldType?.[0]?.field_type === "text" ||
                            selectedFieldType?.[0]?.field_type === "name" ||
                            selectedFieldType?.[0]?.field_type === "address" ||
                            selectedFieldType?.[0]?.field_type === "number" ||
                            selectedFieldType?.[0]?.field_type === "textarea" ||
                            selectedFieldType?.[0]?.field_type ===
                              "fileupload" ||
                            selectedFieldType?.[0]?.field_type === "date" ||
                            selectedFieldType?.[0]?.field_type === "url" ||
                            selectedFieldType?.[0]?.field_type === "Button" ||
                            selectedFieldType?.[0]?.field_type === "email") && (
                            <InputField
                              value={value}
                              type={
                                selectedFieldType?.[0]?.field_type === "Button"
                                  ? "text"
                                  : selectedFieldType?.[0]?.field_type
                              }
                              placeholder=""
                              errorscope
                              iserror={
                                errors?.yellowscreeningConditions?.[index]
                                  ?.optionValue
                              }
                              onChange={(e) => {
                                onChange(e.target.value);
                                clearErrors(
                                  `yellowscreeningConditions.${index}.optionValue`
                                );
                              }}
                              disabled={!isYellowFlagActivated}
                            />
                          )
                        );
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <div
                className="d-flex gap-2 "
                style={{ width: "10%" }}
                disabled={!isYellowFlagActivated}
              >
                <div
                  style={{
                    pointerEvents: isYellowFlagActivated ? "auto" : "none",
                  }}
                >
                  <PlusIcon>
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-plus"
                      style={{ color: "#888888" }}
                      size="lg"
                      type="button"
                      onClick={() => handleAddYellowScreeningCondition()}
                    />
                  </PlusIcon>
                </div>
                <div
                  style={{
                    pointerEvents: isYellowFlagActivated ? "auto" : "none",
                  }}
                >
                  <PlusIcon>
                    <FontAwesomeIcon
                      type="button"
                      icon="fa-solid fa-circle-minus"
                      style={{
                        color: "#888888",
                        opacity: yellowFields?.length === 1 && 0.5,
                      }}
                      size="lg"
                      onClick={() =>
                        yellowFields?.length > 1 &&
                        removeYellowConditions(index)
                      }
                    />
                  </PlusIcon>
                </div>
              </div>
            </div>
          ))}
          <div
            className="form-label-title px-4 gap-2 py-3"
            style={!isYellowFlagActivated ? { opacity: "0.5" } : {}}
          >
            What should happen this yellow flag is triggered.
          </div>
          <Row className="px-4 gap-2 py-3">
            <Col lg={5} sm={8}>
              <Controller
                control={control}
                {...register(`YellowFlagTriggerAction`)}
                rules={{ required: isYellowFlagActivated }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder={"Cancel screening"}
                    value={value}
                    options={screeningDeadlineActionData?.map((opt) => ({
                      value: opt?.Id,
                      label: opt?.Text,
                    }))}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors(`YellowFlagTriggerAction`);
                    }}
                    disabled={!isYellowFlagActivated}
                    iserror={errors?.YellowFlagTriggerAction}
                    errorScope
                  />
                )}
              />
            </Col>
          </Row>

          <div
            className="px-4 gap-2 py-3"
            style={!isYellowFlagActivated ? { opacity: "0.5" } : {}}
          >
            <div className="form-label-title">
              Activate message to customer in frontend if condition is triggered
            </div>

            <Controller
              control={control}
              {...register(`YellowFlagActivateMessage`)}
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <Toggle
                  id="yellowFlagActivateMessageConditions"
                  checked={isYellowFlagMessageActivated}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    handleYellowFlagMessageActivatedChange(e.target.checked);
                  }}
                  disabled={!isYellowFlagActivated}
                />
              )}
            />
          </div>

          <div
            className="px-4 gap-2 w-75  pb-3"
            style={!isYellowFlagActivated ? { opacity: "0.5" } : {}}
          >
            <div className="form-label-title pb-2">Message</div>
            <div className="form-float openhouse-des">
              <Controller
                control={control}
                {...register(`YellowFlagMessage`)}
                rules={{ required: isYellowFlagMessageActivated }}
                render={({ field: { onChange, value } }) => (
                  <textarea
                    name=""
                    class="inputText"
                    cols="20"
                    rows="3"
                    placeholder=" "
                    disabled={!isYellowFlagMessageActivated}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      //   handleYellowFlagMessageChange(e);
                      clearErrors(`YellowFlagMessage`);
                    }}
                  ></textarea>
                )}
              />
              <label className="floating-label">Message</label>
            </div>
          </div>
        </>
        <hr className="accordionSidebar-hr mt-2 mb-0"></hr>
        {/*------------------ Red flag conditions------------- */}
        <>
          <div className="px-4 gap-2 py-3">
            <div className="form-label-title">Red flag conditions</div>
            <Toggle
              id={"redFagConditions"}
              checked={isRedFlagActivated}
              onChange={handelthirdToggleChange}
              // checked={isRkiCheck}
              // onChange={(e) => setIsRkiCheck(e.target.checked)}
            />
          </div>
          <div
            className="px-4 d-flex align-items-center light-font-text pb-2"
            style={!isRedFlagActivated ? { opacity: "0.5" } : {}}
          >
            <div className="pe-2 w-25">
              <div className="form-input">
                <input value={"Red flag"} disabled={true} />
              </div>
            </div>
            this field if{" "}
            <div className="px-2 w-25">
              <Controller
                control={control}
                {...register(`RedFlagLogicType`)}
                rules={{ required: isRedFlagActivated }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder={"All "}
                    disabled={!isRedFlagActivated}
                    value={value}
                    options={[
                      { value: "1", label: "All" },
                      { value: "2", label: "Any" },
                    ]}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors(`RedFlagLogicType`);
                    }}
                    iserror={errors?.RedFlagLogicType}
                    errorScope
                  />
                )}
              />
            </div>
            of the following matches:
          </div>

          {/* Fields array  for red flag*/}
          {redFields?.map((field, index) => (
            <div
              className="d-flex align-items-center w-100"
              key={field.id}
              style={!isRedFlagActivated ? { opacity: "0.5" } : {}}
            >
              <div
                className="ms-4 me-3 my-2 px-4 py-3 radius-8"
                style={{ backgroundColor: "#F0F0F0", width: "90%" }}
              >
                <Row>
                  <Col lg={4} className="pe-0">
                    <Controller
                      control={control}
                      {...register(`redScreeningConditions.${index}.fields`)}
                      rules={{ required: isRedFlagActivated }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder="Fields"
                          options={fieldOptions}
                          value={value}
                          disabled={!isYellowFlagActivated}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors(
                              `redScreeningConditions.${index}.fields`
                            );
                          }}
                          errorScope
                          iserror={errors?.redScreeningConditions?.[index]?.fields}
                        />
                      )}
                    />
                  </Col>
                  <Col lg={3} className="pe-0">
                    <Controller
                      control={control}
                      {...register(`redScreeningConditions.${index}.condition`)}
                      rules={{ required: isRedFlagActivated }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder={"Is"}
                          disabled={!isRedFlagActivated}
                          value={value}
                          options={[
                            { value: "1", label: "equal to" },
                            { value: "2", label: "does not equal to" },
                            { value: "3", label: "greater than" },
                            { value: "4", label: "Noless than" },
                            { value: "5", label: "has a value" },
                            { value: "6", label: "has no value" },
                          ]}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors(
                              `redScreeningConditions.${index}.condition`
                            );
                          }}
                          errorScope
                          iserror={errors?.redScreeningConditions?.[index]?.condition}
                        />
                      )}
                    />
                  </Col>

                  <Col lg={5}>
                    {/* <div className="form-input"> */}
                    <Controller
                      {...register(
                        `redScreeningConditions.${index}.optionValue`
                      )}
                      control={control}
                      rules={{ required: isRedFlagActivated }}
                      render={({ field: { onChange, value } }) => {
                        const fieldName = watch(
                          `redScreeningConditions.${index}.fields`
                        );
                        const selectedFieldType = screeningData?.filter(
                          (sD) => sD?.screening_field_id == fieldName?.value
                        );
                        return selectedFieldType?.[0]?.field_type ===
                          "select" ||
                          selectedFieldType?.[0]?.field_type === "radio" ||
                          selectedFieldType?.[0]?.field_type === "checkbox" ? (
                          <ReactSelect
                            value={value}
                            options={possibleValue?.map((opt) => ({
                              value: opt?.Id,
                              label: opt?.value,
                            }))}
                            disabled={!isRedFlagActivated}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            errorScope
                          iserror={errors?.redScreeningConditions?.[index]?.optionValue}
                          />
                        ) : (
                          (selectedFieldType?.[0]?.field_type === "text" ||
                            selectedFieldType?.[0]?.field_type === "number" ||
                            selectedFieldType?.[0]?.field_type === "textarea" ||
                            selectedFieldType?.[0]?.field_type ===
                              "fileupload" ||
                            selectedFieldType?.[0]?.field_type === "date" ||
                            selectedFieldType?.[0]?.field_type === "url" ||
                            selectedFieldType?.[0]?.field_type === "Button" ||
                            selectedFieldType?.[0]?.field_type === "email") && (
                            <div className="form-input">
                              <input
                                type={
                                  selectedFieldType?.[0]?.field_type ===
                                  "Button"
                                    ? "text"
                                    : selectedFieldType?.[0]?.field_type
                                }
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                                disabled={!isRedFlagActivated}
                              />
                            </div>
                          )
                        );
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <div className="d-flex gap-2 " style={{ width: "10%" }}>
                <div
                  style={{
                    pointerEvents: isRedFlagActivated ? "auto" : "none",
                  }}
                >
                  <PlusIcon>
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-plus"
                      style={{ color: "#888888" }}
                      size="lg"
                      type="button"
                      disabled={!isRedFlagActivated}
                      onClick={handleAddRedScreeningCondition}
                    />
                  </PlusIcon>
                </div>
                <div
                  style={{
                    pointerEvents: isRedFlagActivated ? "auto" : "none",
                  }}
                >
                  <PlusIcon>
                    <FontAwesomeIcon
                      type="button"
                      icon="fa-solid fa-circle-minus"
                      style={{
                        color: "#888888",
                        opacity: redFields?.length === 1 && 0.5,
                      }}
                      size="lg"
                      disabled={!isRedFlagActivated}
                      onClick={() =>
                        redFields?.length > 1 && removeRedConditions(index)
                      }
                    />
                  </PlusIcon>
                </div>
              </div>
            </div>
          ))}
          <div
            className="form-label-title px-4 gap-2 py-3"
            style={!isRedFlagActivated ? { opacity: "0.5" } : {}}
          >
            What should happen this red flag is triggered.
          </div>
          <Row
            className="px-4 gap-2 py-3"
            style={!isRedFlagActivated ? { opacity: "0.5" } : {}}
          >
            <Col lg={5} sm={8}>
              <Controller
                control={control}
                {...register("RedFlagTriggerAction")}
                rules={{ required: isRedFlagActivated }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder={"Cancel screening"}
                    value={value}
                    disabled={!isRedFlagActivated}
                    // iserror={errors?.deadline}
                    options={screeningDeadlineActionData?.map((opt) => ({
                      value: opt?.Id,
                      label: opt?.Text,
                    }))}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    iserror={errors?.RedFlagTriggerAction}
                    errorScope
                  />
                )}
              />
              {/* {errors?.deadline && (
									<p className="error-msg">{REQUIRED_ERR}</p>
								)} */}
            </Col>
          </Row>

          {/*Activate message to customer in frontend if condition is triggered  */}
          <div
            className="px-4 gap-2 py-3"
            style={!isRedFlagActivated ? { opacity: "0.5" } : {}}
          >
            <div className="form-label-title">
              Activate message to customer in frontend if condition is triggered
            </div>
            <Controller
              control={control}
              {...register(`RedFlagActivateMessage`)}
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <Toggle
                  id={"redFlagActivateMessageConditions"}
                  checked={isRedFlagMessageActivated}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    handleRedFlagMessageChange(e.target.checked);
                  }}
                  disabled={!isRedFlagActivated}
                />
              )}
            />
          </div>

          <div
            className="px-4 gap-2 w-75  pb-3"
            style={!isRedFlagActivated ? { opacity: "0.5" } : {}}
          >
            <div className="form-label-title pb-2">Message</div>
            <div className="form-float openhouse-des">
              <Controller
                control={control}
                {...register(`RedFlagMessage`)}
                rules={{ required: isRedFlagMessageActivated }}
                render={({ field: { onChange, value } }) => (
                  <textarea
                    name=""
                    class="inputText"
                    cols="20"
                    rows="3"
                    placeholder=" "
                    disabled={!isRedFlagMessageActivated}
                    onChange={(e) => {
                      onChange(e);
                      handleRedFlagTextMessageChange(e);
                    }}
                  ></textarea>
                )}
              />
              <label className="floating-label">Message</label>
            </div>
          </div>
        </>
        <hr className="accordionSidebar-hr my-0"></hr>
        <div className="p-4 d-flex justify-content-end">
          <ETButton
            // type="button"
            type="submit"
            disabled={!isYellowFlagActivated && !isRedFlagActivated}
            variant={"primary"}
            buttonText={"Apply screening conditions"}
            // onClick={(e) => handleScreeningConditionsSubmit(e)}
          />
        </div>
      </Form>
    </div>
  );
};

export default ScreeningConditions;
