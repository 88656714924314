import { Get, Post, Put, Delete } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

// Get all protfolis
export const GetPortfolio = (level, level_id) => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Properties_Get_Portfolio, null, level +"/" + level_id + "/list")
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

// Add portfolio
export const PostPropertyAddPortfolio = async (data) => {
	return new Promise((resolve, reject) => {
		Post(Site.ET, Endpoint.ET.Post_Property_Add_Portfolio, null, data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Edit portfolio
export const PutPropertyPortfolio = (data) => {
	return new Promise((resolve, reject) => {
		Put(Site.ET, Endpoint.ET.Put_Property_edit_Portfolio, null, data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

// Delete portfolio
export const DeletePropertyPortfolio = (id) => {
	return new Promise((resolve, reject) => {
		Delete(Site.ET, Endpoint.ET.Delete_property_portfolio, null, id)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};
// delete portfolio Delete_Portfolio
export const DeletePortfolio = (id) => {
	console.log(id, "id DeletePortfolio");
	return new Promise((resolve, reject) => {
		Delete(Site.ET, Endpoint.ET.Delete_Portfolio, null, id)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};
// Get portfolio by Id
export const GetAllPropertyForPortfolioId = (id) => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Get_property_For_PortfolioId, null, id)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

// get all properties for portfolio id
export const GetAllPropertiesForPorfolio = (id) => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Get_All_Properties_For_Porfolio, null, id)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

// get portfolio by portfolio id
export const GetPortfolioByPortfolioId = (id) => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Get_Portfolio_By_PortfolioId, null, id)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

// delete properties from portfolio
export const DeletePortfolioProperty = (propertyId, portfolioId) => {

	return new Promise((resolve, reject) => {
		Delete(
			Site.ET,
			Endpoint.ET.Delete_Portfolio_Property,
			null,
			propertyId + "/" + portfolioId
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};
