import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container, Placeholder } from "react-bootstrap";
import { NavLink } from "../../Components/Tabs/ReactTabs.style";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  useCompanyId,
  useEditCompany,
} from "../../hooks/stores/useCompanyStore";
import { useGetCompanyByCompanyId } from "../../hooks/queries/useCompanyQueries";
import { GreenSpan, H1 } from "../../styles/Common.style";

const CompaniesTab = () => {
  let location = useLocation();
  const { state } = location;
  let TabId = state?.Tab;
  const CompanyTabs = [
    {
      Id: "1",
      tab: "Details",
      link: "details",
    },
    {
      Id: "2",
      tab: "Properties",
      link: "properties",
    },
    {
      Id: "3",
      tab: "Documents",
      link: "documents",
    },
    // {
    //   Id: "4",
    //   tab: "Settings",
    //   link: "settings",
    // },
  ];
  const handleHeading = (tab, Id) => {
    setSelectedTab(tab);
  };
  const { organizationId } = useEditCompany((state) => state);
  const { companyId } = useEditCompany((state) => state);

  const { data: GetCompanyByCompanyIdData } = useGetCompanyByCompanyId(
    organizationId,
    companyId
  );

  let company_name = GetCompanyByCompanyIdData?.data[0]?.company_name;
  const companyCrumb = useCompanyId((state) => state.companiesId);

  //---------------states-----------------//
  const [selectedTab, setSelectedTab] = useState(null);
  const navigate = useNavigate();
  const navigateToCompany = () => {
    navigate("/division/Companies");
  };
  useEffect(() => {
    const activePath = window.location.pathname.split("/")[2];
    const activeTab =
      activePath?.charAt(0)?.toUpperCase() + activePath?.slice(1);
    setSelectedTab(activeTab);
  }, []);

  return (
    <>
      <Container
        fluid
        style={{ backgroundColor: "#F9FAFA" }}
        className="units-tabs-main"
      >
        <>
          <>
            <div className="py-2">
              <div className="pt-4 ">
                <Placeholder animation="glow">
                  <H1>
                    {/* {TabId ? TabId : selectedTab} */}
                    Company
                  </H1>
                  <div className="light-font-text cursor-pointer pt-2">
                    <span className="me-2 fs-16"  onClick={navigateToCompany}>
                      Company
                    </span>
                    {/* <span className="me-2">
                      <FontAwesomeIcon
                        size="sm"
                        icon="fa-regular fa-angle-right"
                        style={{ color: "#000000" }}
                      />{" "}
                    </span>
                    <Link
                      to="/company/details"
                      state={{ Tab: "Details" }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <span className="me-2">
                        {company_name ? company_name : companyCrumb}
                      </span>
                    </Link> */}
                    <span className="me-2 ">
                      <FontAwesomeIcon
                        size="sm"
                        icon="fa-regular fa-angle-right"
                        style={{ color: "#000000" }}
                      />{" "}
                    </span>
                    <GreenSpan className="me-2 fs-16 breadcrumbs">
                      {TabId ? TabId : selectedTab}
                    </GreenSpan>
                  </div>
                  <div
                    className="d-flex pt-3 pb-1"
                    style={{ flexWrap: "wrap" }}
                  >
                    <>
                      {CompanyTabs?.map((item, i) => (
                        <NavLink
                          key={i}
                          //   state={{ propId: propertyId }}
                          to={item.link}
                          style={{ textDecoration: "none" }}
                          onClick={() => {
                            handleHeading(item?.tab);
                            // updateBreadcrumbs(['Residential']);
                            // setSelectedSubForSetting(" Configuration")
                          }}
                          // onClick={() => handleHeading(item.tab)}
                          className=" me-2"
                        >
                          {item?.tab}
                        </NavLink>
                      ))}
                    </>
                    {/* )} */}
                  </div>
                </Placeholder>
              </div>
            </div>
            <Outlet />
          </>
        </>
      </Container>
    </>
  );
};

export default CompaniesTab;
