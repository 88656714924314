import React from "react";
import { useState } from "react";
import { H4, StyledLoadingBar } from "../../../../styles/Common.style";
import LoadingBar from "react-top-loading-bar";
import { customproperties } from "../../../../styles/Theme";
import LeadCustomerInformation from "./LeadCustomerInformation";
import SelectPropertyForLead from "./SelectPropertyForLead";
import SelectUnitForLead from "./SelectUnitForLead";
import ConfirmCancelSigningOrder from "../../standardComponents/cancelSigningOrder/ConfirmCancelSigningOrder";
import CreateNewAppoinmentConfirmation from "../../opportunities/CreateNewAppointments/CreateNewAppoinmentConfirmation";
import ExistingCustomer from "../../opportunities/ExistingCustomer";
import CustomPopup from "../../../../Components/CustomPopup";
import { useAllPropertyList } from "../../../../hooks/queries/usePropertyQueries";
import { useGetUnitsByPropertyIdUpdated } from "../../../../hooks/queries/useUnitQueries";
import { usePostAddLead } from "../../../../hooks/queries/useCustomerFunnel";
import { toast } from "react-toastify";
import {
  usePostAddActor,
  usePutUpdateActor,
} from "../../../../hooks/queries/useActorQueries";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";
import { useGetUserByDivisionId } from "../../../../hooks/queries/useUserQueries";

const CreateNewlead = ({ showNewLead, setShowNewLead }) => {
  //----------------------------Zustand--------------------------//
  const { userDetails } = useUserDetails((state) => state);
  //----------------------------states--------------------------//
  const [progress, setProgress] = useState(20);
  const [stepWizard, setStepWizard] = useState("CustomerInformationPage");
  const [assignPropertyToLead, setAssignPropertyToLead] = useState(1);
  const [sendConfirmationToSms, setSendConfirmationToSms] = useState(false);
  const [sendConfirmationToMyPage, setSendConfirmationToMyPage] =
    useState(false);
  const [customerInformation, setCustomerInformation] = useState([]); //User entered customer information details
  const [existingActorDetails, setExistingActorDetails] = useState([]); //Existing customer information from database (API response)
  const [rowSelection, setRowSelection] = useState({});

  const [unitrowSelection, setUnitRowSelection] = useState({});

  // ------------- Constants ----------------------//
  const propertyId = Object.keys(rowSelection);
  const unitId = Object?.keys(unitrowSelection);

  //--------------------------react query----------------------//

  const { data: AllPropertyListData } = useAllPropertyList(1);

  const { mutateAsync: PostAddNewLead } = usePostAddLead();

  const { data: GetUnitsByPropertyIdUpdatedData } =
    useGetUnitsByPropertyIdUpdated(Object.keys(rowSelection)?.[0], 0);

  const { mutateAsync: PostAddActor, isLoading: PostAddActorIsLoading } =
    usePostAddActor();

  const { mutateAsync: PutUpdateActor, isLoading: PutUpdateActorIsLoading } =
    usePutUpdateActor();

  const { data: GetUserByDivisionIdData } = useGetUserByDivisionId(
    userDetails?.division_id
  );

  // icon click function
  const likeIconClickHandle = () => {
    if (customerInformation?.isFavouriteCustomer == 1) {
      setCustomerInformation((prev) => ({ ...prev, isFavouriteCustomer: 0 }));
    } else {
      setCustomerInformation((prev) => ({ ...prev, isFavouriteCustomer: 1 }));
    }
  };

  // --------------------------------------- Customer Information button handle -------------------------------------------------//
  const handleCustomerInformationNext = async (data) => {
    setCustomerInformation(data);
    const addActorPayload = {
      email: data?.email,
      firstname: data?.firstname,
      mobilenumber: data?.mobilenumber,
      lastname: data?.lastname,
      birthdate: data?.birthdate,
      country_id_nationality: data?.countryCode?.value,
      phone_calling_code_id: data?.countryCode?.label,
      street_name: data?.street_name,
      house_number: data?.house_number,
      side_door: data?.side,
      country_id: data?.country?.value,
      zipcode_id: data?.zipCode?.value,
      social_security_number: data?.social_security_number,
      floor_no: data?.floor_no,
      send_reservation: "0",
      screening_invitation: "0",
      is_mobilenumber_validated: "0",
      force_insert: "0",
      contract_id: "0",
      is_primary: "0",
      is_paying: "0",
      is_coresident: "0",
      is_subtenant: "0",
      is_guardian: "0",
      guardian_for_actor_id: "0",
      is_incompetent: "0",
      is_signing_contract: "0",
    };

    try {
      const response = await PostAddActor(addActorPayload);
      if (response?.data?.bReturn) {
        setExistingActorDetails(response?.data?.data?.actor_details);
        setProgress(40);
        setStepWizard("SelectPropertyForLead");
        toast.success(response?.data?.message);
      } else {
        toast.info(response?.data?.message);
        setStepWizard("ExistingCustomer");
        setExistingActorDetails(response?.data?.data?.actor_details);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleCustomerInformationCancel = () => {
    setShowNewLead(false);
    setStepWizard("CustomerInformationPage");
  };

  // ------------------------------------ Select property for lead button handle --------------------------------------------------//

  const handleSelectPropertyForLeadBack = () => {
    setProgress(20);
    setStepWizard("CustomerInformationPage");
  };

  const handleSelectPropertyForLeadNext = (data) => {
    setProgress(assignPropertyToLead == 1 ? 45 : 60);
    setStepWizard(
      assignPropertyToLead == 1 ? "SelectUnitForLead" : "ConfirmationSend"
    );
  };

  // ------------------------------------ Select units for lead button handle --------------------------------------------------//

  const handleSelectUnitForLeadNext = (data) => {
    setProgress(80);
    setStepWizard("ConfirmationSend");
  };

  const handleSelectUnitForLeadBack = () => {
    setProgress(40);
    setStepWizard("SelectPropertyForLead");
  };

  // ------------------------------------ Confirmation Send for lead button handle --------------------------------------------//

  const handleConfirmationSendCreate = async (data) => {
    const createNewLeadPayload = {
      actor_id: existingActorDetails?.actor_id,
      div_id: userDetails?.division_id,
      property_id: assignPropertyToLead === 1 ? propertyId?.[0] : "0",
      unit_details: assignPropertyToLead === 1 ? unitId : [],
      responsible_agent_user_id: "0",
      is_assigned_to_property: assignPropertyToLead,
      send_confirmation_to_email: "1",
      send_confirmation_to_sms: sendConfirmationToSms ? "1" : "0",
      send_confirmation_to_mypage: sendConfirmationToMyPage ? "1" : "0",
      other_agents: [],
      email_template_id: 0,
      email_subject: "",
      email_body: "",
    };

    try {
      const response = await PostAddNewLead(createNewLeadPayload);
      if (response.status === "success" && response?.data?.bReturn) {
        toast.success(response?.data?.message);
        setProgress(99.99);
        setStepWizard("ConfirmationForLeadCreated");
      } else {
        toast.info(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleConfirmationSendBack = () => {
    // setProgress(80);
    setProgress(assignPropertyToLead == 1 ? 60 : 45);
    setStepWizard(
      assignPropertyToLead == 1 ? "SelectUnitForLead" : "SelectPropertyForLead"
    );
  };
  // ------------------------handle Confirmation For Lead Created Close---------------------------------//

  const handleConfirmationForLeadCreatedClose = () => {
    setShowNewLead(false);
    setStepWizard("CustomerInformationPage")
  };

  //--------------------------handle close create lead popup ---------------------------------------//

  const handleCloseCreateNewLeadPopup = () => {
    setShowNewLead(false);
    setCustomerInformation([]);
    setStepWizard("CustomerInformationPage");
  };

  //============================Existing customer button handlers ===========================//
  const handleExistingCustomerBack = () => {
    setStepWizard("CustomerInformationPage");
  };

  const handleCreateAsNewCustomer = async () => {
    const addActorPayload = {
      email: customerInformation?.email,
      firstname: customerInformation?.firstname,
      mobilenumber: customerInformation?.mobilenumber,
      lastname: customerInformation?.lastname,
      birthdate: customerInformation?.birthdate,
      country_id_nationality: customerInformation?.countryCode?.value,
      phone_calling_code_id: customerInformation?.countryCode?.label,
      street_name: customerInformation?.street_name,
      house_number: customerInformation?.house_number,
      side_door: customerInformation?.side_door,
      country_id: customerInformation?.country?.value,
      zipcode_id: customerInformation?.zipCode?.value,
      social_security_number: customerInformation?.social_security_number,
      floor_no: customerInformation?.floor_no,
      send_reservation: "0",
      screening_invitation: "0",
      is_mobilenumber_validated: "0",
      force_insert: "1",
      contract_id: "0",
      is_primary: "0",
      is_paying: "0",
      is_coresident: "0",
      is_subtenant: "0",
      is_guardian: "0",
      guardian_for_actor_id: "0",
      is_incompetent: "0",
      is_incompetent: "0",
    };

    try {
      const response = await PostAddActor(addActorPayload);
      if (response?.data?.bReturn) {
        toast.success(response?.data?.message);
        setExistingActorDetails(response?.data?.data?.actor_details);
        setProgress(40);
        setStepWizard("SelectPropertyForLead");
      } else {
        toast.info(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleExistingCustomerUpdateAndUse = async () => {
    const updateActorPayload = {
      actor_id: existingActorDetails?.actor_id,
      email: customerInformation?.email,
      firstname: customerInformation?.firstname,
      mobilenumber: customerInformation?.mobilenumber,
      lastname: customerInformation?.lastname,
      birthdate: customerInformation?.birthdate,
      country_id_nationality: customerInformation?.countryCode?.value,
      phone_calling_code_id: customerInformation?.countryCode?.label,
      street_name: customerInformation?.street_name,
      house_number: customerInformation?.house_number,
      floor_no: customerInformation?.floor_no,
      side_door: customerInformation?.side_door,
      country_id: customerInformation?.country?.value,
      zipcode_id: customerInformation?.zipCode?.value,
      social_security_number: customerInformation?.social_security_number,
      send_reservation: "0",
      screening_invitation: "0",
      is_mobilenumber_validated: "0",
      contract_id: "0",
      is_primary: "0",
      is_paying: "0",
      is_coresident: "0",
      is_subtenant: "0",
      is_guardian: "0",
      guardian_for_actor_id: "0",
      is_incompetent: "0",
      is_signing_contract: "0",
    };

    try {
      const response = await PutUpdateActor(updateActorPayload);
      if (response?.data?.bReturn) {
        toast.success(response?.data?.message);
        setProgress(40);
        setStepWizard("SelectPropertyForLead");
      } else {
        toast.info(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  // ---------------------------- leads wizard------------------------------------------------------//
  const WizardsCreateNewLead = {
    CustomerInformationPage: (
      <LeadCustomerInformation
        handleNext={handleCustomerInformationNext}
        handleCancel={handleCustomerInformationCancel}
        customerInformationData={customerInformation}
      />
    ),

    SelectPropertyForLead: (
      <SelectPropertyForLead
        userDetails={customerInformation}
        // setStepWizard={setStepWizard}
        // setProgress={setProgress}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        AllPropertyListData={AllPropertyListData?.data}
        assignPropertyToLead={assignPropertyToLead}
        setAssignPropertyToLead={setAssignPropertyToLead}
        handleBack={handleSelectPropertyForLeadBack}
        handleNext={handleSelectPropertyForLeadNext}
      />
    ),

    SelectUnitForLead: (
      <SelectUnitForLead
        handleNext={handleSelectUnitForLeadNext}
        handleBack={handleSelectUnitForLeadBack}
        userDetails={customerInformation}
        // propertyId={propertyId}
        GetUnitsByPropertyIdUpdatedData={GetUnitsByPropertyIdUpdatedData?.data}
        setUnitRowSelection={setUnitRowSelection}
        unitrowSelection={unitrowSelection}
      />
    ),
    ConfirmationSend: (
      <ConfirmCancelSigningOrder
        handleBack={handleConfirmationSendBack}
        handleCreate={handleConfirmationSendCreate}
        customerName={`${customerInformation?.firstname} ${customerInformation?.lastname}`}
        notifyOtherAgentDropdown={GetUserByDivisionIdData?.data}
        setSendConfirmationToMyPage={setSendConfirmationToMyPage}
        sendConfirmationToMyPage={sendConfirmationToMyPage}
        setSendConfirmationToSms={setSendConfirmationToSms}
        sendConfirmationToSms={sendConfirmationToSms}
      />
    ),
    ConfirmationForLeadCreated: (
      <CreateNewAppoinmentConfirmation
        handleClose={handleConfirmationForLeadCreatedClose}
        showLeadsCustomersDetail={true}
        leadsCustomerDetailData={customerInformation}
        heading={"The lead is now created"}
        subHeading={
          "The new lead has now been created in the system and a confirmation has been sent to the customer by email. The customer needs to confirm the registration, before the lead is active in the system."
        }
      />
    ),
    ExistingCustomer: (
      <ExistingCustomer
        existingCustomerData={existingActorDetails}
        newDetails={customerInformation}
        handleBack={handleExistingCustomerBack}
        handleUpdateAndUse={handleExistingCustomerUpdateAndUse}
        handleCreateAsNewCustomer={handleCreateAsNewCustomer}
        disableButton={PostAddActorIsLoading || PutUpdateActorIsLoading}
      />
    ),
  };

  return (
    <div>
      <CustomPopup
        open={showNewLead}
        title="Create new lead"
        titleTooltipText="Create new lead"
        title_color="black"
        size={"xl"}
        enableCrossButton={true}
        deletePopUpStyle={true}
        titleTooltip={true}
        likeFeature={stepWizard == "CustomerInformationPage" ? false : true}
        likeIconClickHandle={likeIconClickHandle}
        extraInfo={stepWizard == "CustomerInformationPage" ? false : true}
        extraInfoText={`${
          customerInformation?.firstname + " " + customerInformation?.lastname
        }`}
        isLiked={customerInformation?.isFavouriteCustomer}
        onClickCrossButton={handleCloseCreateNewLeadPopup}
      >
        <div>
          <StyledLoadingBar>
            <LoadingBar
              color={customproperties.primaryColor}
              progress={progress}
              containerStyle={{
                position: "absolute",
                height: "2.5px",
              }}
              shadow={false}
              onLoaderFinished={() => setProgress(0)}
            />
          </StyledLoadingBar>
        </div>
        <div className=" me-1">{WizardsCreateNewLead[stepWizard]}</div>
      </CustomPopup>
    </div>
  );
};

export default CreateNewlead;

// this component used for showing detail for property and unit selection
export const CustomerDetailsAfterFillingForm = ({ data }) => {
  function renderDetail(title, value) {
    return (
      <div className="d-flex pb-1 light-font-text gap-4">
        <div className="">{title}</div>
        <div className="">{value}</div>
      </div>
    );
  }
  return (
    <div className="p-4">
      <H4>Customer details</H4>
      <div className="pt-2">
        {renderDetail("Full name :", `${data?.firstname} ${data?.lastname}`)}
        {renderDetail(
          "Address :",
          `${data?.street_name} ${data?.house_number}, ${data?.zipCode?.value} ${data?.city}`
        )}
        {renderDetail(
          "Phone :",
          `${data?.countryCode?.label + " " + data?.mobilenumber}`
        )}
        {renderDetail("Email :", `${data?.email}`)}
      </div>
    </div>
  );
};

// created by Suraj
//Updated by Viraj on 17-11-2023
