import React, { useEffect } from "react";
import { H3, H4, H5 } from "../styles/Common.style";
import CustomToolTip from "../Components/Tooltip/CustomTooltip";
import { Image } from "react-bootstrap";

export const TitleWithDescription = ({
  titleText,
  descriptionText,
  titleTextFont = "H4",
  tooltipText,
}) => {
  return (
    <>
      <div className="d-flex gap-2 align-items-center">
        {titleTextFont == "H3" ? (
          <H3>{titleText}</H3>
        ) : titleTextFont == "H5" ? (
          <H5>{titleText}</H5>
        ) : (
          <H4>{titleText}</H4>
        )}
        <CustomToolTip text={tooltipText ? tooltipText : titleText} />
      </div>
      <div className="light-font-text ">{descriptionText}</div>
    </>
  );
};

// used in tables for showing short name and image, it helps to overcome broken image.  created by Suraj
export const ImageComponent = ({
  imageUrl,
  altText,
  shortNameComponent,
  width = "60px",
  height = "60px",
  borderRadius = "8px",
}) => {
  const [imageError, setImageError] = React.useState(false);

  useEffect(() => {
    setImageError(false);
    return () => {};
  }, [imageUrl]);


  // const handleImageError = () => {
  //   setImageError(true);
  // };
  return (
    <div>
      {imageError ? (
        // Render the onErrorComponent if image source is broken
        shortNameComponent
      ) : (
        // Render the image if it's not broken

        <Image
          className="rounded"
          src={imageUrl}
          alt={altText}
          onError={(e) => setImageError(true)}
          style={{ width: width, height: height, borderRadius: borderRadius }}
        />
      )}
    </div>
  );
};

// used for showing short name  --created by Suraj
export const ShortNameComponent = ({
  backgroundColor,
  fontColor,
  shortNameText,
  width = "60px",
  height = "60px",
  fontSize = "20px",
  borderRadius = "8px",
}) => {
  return (
    <div
      className="radius-8 d-flex align-items-center justify-content-center"
      style={{
        width: width,
        height: height,
        backgroundColor: backgroundColor ? backgroundColor : "#d1d1d1",
        color: fontColor ? fontColor : "#000",
        fontFamily: "Cerebri sans",
        textTransform: "uppercase",
        fontSize: fontSize,
        borderRadius: borderRadius,
      }}
    >
      {shortNameText}
    </div>
  );
};
