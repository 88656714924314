import React from "react";
import { Col, Row } from "react-bootstrap";
import Analysis from "./Analysis";
import UpcomingIdleness from "./UpcomingIdleness";
import StatusChange from "./StatusChange";
import AvailibilityByRooms from "./AvailibilityByRooms";
import AvailibilityBySquareMeters from "./AvailibilityBySquareMeters";
import ProgressOvertimeByStatus from "./ProgressOvertimeByStatus";

const Economy = ({ startDateFormate, endDateFormate, allProperties }) => {
  return (
    <div  className=" radius-8 p-4 containerStyle">
      <Row className="mb-4">
        <Col>
          <Analysis />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <UpcomingIdleness />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <StatusChange />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <ProgressOvertimeByStatus />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={6} className="mb-sm-4 mb-lg-0">
          <AvailibilityByRooms
            startDateFormate={startDateFormate}
            endDateFormate={endDateFormate}
            allProperties={allProperties}
          />
        </Col>
        <Col lg={6}>
          <AvailibilityBySquareMeters
            startDateFormate={startDateFormate}
            endDateFormate={endDateFormate}
            allProperties={allProperties}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Economy;
