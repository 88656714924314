import React, { useEffect, useMemo, useState } from "react";
import MRTTheme from "../../Components/table/MRTTheme";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../Components/table/MRTGlobalProps";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";

import { Col, Row } from "react-bootstrap";

const AssignAgents = ({ rowSelection, setRowSelection, data }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "Name",
        header: "Name",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: true,
      },
      {
        accessorKey: "Division",
        header: "Division",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "Availibility",
        header: "Availability",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: false,
      },
    ],
    []
  );

  return (
    <div className="mt-5 px-4">
      <div className="header-semibold mb-2"> Assign agents to the event </div>
      <div className=" light-font-text mb-2">
        {" "}
        Search and select the agent(s) you want to add to your event.{" "}
      </div>
      <MRTTheme
        table={
          <MaterialReactTable
            columns={columns}
            data={data || []} //data is undefined on first render
            icons={{ ...SortIcon }}
            enableColumnActions={false}
            // state={{isLoading :!tableData}}
            enableRowSelection
            enableSelectAll
            onRowSelectionChange={setRowSelection}
            getRowId={(originalRow) => originalRow?.UserId}
            state={{ rowSelection }}
            muiTableContainerProps={{
              sx: {
                padding: "0px !important",
              },
            }}
            // muiTablePaperProps={{
            //   elevation: 0,
            //   fontFamily: "Cerebri sans-light !important",
            //   sx: {
            //     borderRadius: "0",
            //     border: "none",
            //     // backgroundColor: "#F9FAFA",
            //     td: {
            //       fontSize: "13px !important",
            //       fontFamily: "Cerebri sans-light !important",
            //     },
            //   },
            // }}
            displayColumnDefOptions={{
              "mrt-row-select": {
                header: "",
                size: 10,
              },
            }}
            globalFilterFn="contains"
            muiTableBodyRowProps={{
              selected: false,
            }}
            muiSearchTextFieldProps={{
              ...SearchStyle,
              InputProps: {
                ...SearchIcon,
              },
            }}
            muiTablePaginationProps={{
              ...paginationoptions,
            }}
            muiTableHeadRowProps={{
              sx: {
                ...TableRowsStyle,
              },
            }}
            initialState={{ showGlobalFilter: true }}
            renderTopToolbar={({ table }) => (
              <>
                <Row className="my-3">
                  <Col lg={3}>
                    <MRT_GlobalFilterTextField table={table} />
                  </Col>{" "}
                </Row>
              </>
            )}
          />
        }
      />
    </div>
  );
};

export default AssignAgents;
