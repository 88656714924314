// created by --Suraj -------------------  updated by --Suraj //
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { NavLink } from "../../Components/Tabs/ReactTabs.style";
import { Container } from "react-bootstrap";
import { useBreadcrumb } from "../../Components/BreadcrumbContext ";
import { useRouting } from "../../hooks/stores/useRoutingStore";

const MainSetting = () => {
  const settingTabs = [
    {
      Id: "1",
      tab: "Tag",
      link: "tag",
    },
    {
      Id: "2",
      tab: "Promotion label",
      link: "promotion-label",
    },
    {
      Id: "3",
      tab: "Integrations",
      link: "integrations",
    },
    {
      Id: "4",
      tab: "Screening",
      link: "screening",
    },
    {
      Id: "5",
      tab: "Signing",
      link: "signing",
    },
    {
      Id: "6",
      tab: "Configuration",
      link: "configuration",
    },
  ];
  // code for Breadcrumbs update
  const { updateBreadcrumbs } = useBreadcrumb();
  const { selectedSubForSetting } = useRouting((state) => state);

  // this code used for getting last path name
  const activePath = window.location.pathname.split("/")[3];
  const activeTab = activePath?.charAt(0)?.toUpperCase() + activePath?.slice(1);

  //   setting breadcrumbs
  useEffect(() => {
    updateBreadcrumbs(["Settings", `${activeTab}`]);
  }, [activeTab]);

  //   handling Promotion label inner tabs
  const handelTag = (activeTab) => {
    if (activeTab == "Promotion label") {
      if (selectedSubForSetting == "Labels") {
        updateBreadcrumbs(["Settings", "Promotion label", "Labels"]);
      } else {
        updateBreadcrumbs(["Settings", "Promotion label", "Configuration"]);
      }
    }
  };
  return (
    <Container
      fluid
     
      className="px-4 pb-4 mb-4 containerStyle"
    >
      <div className="d-flex pt-3 pb-2 " style={{ flexWrap: "wrap" }}>
        <>
          {settingTabs?.map((item, i) => (
            <NavLink
              key={i}
              //   state={{ propId: propertyId }}
              to={item.link}
              style={{ textDecoration: "none" }}
              onClick={() => {
                updateBreadcrumbs([`${item.tab}`]);
                handelTag(item.tab);
              }}
              className=" me-3"
            >
              {item?.tab}
            </NavLink>
          ))}
        </>
      </div>
      <Outlet />
    </Container>
  );
};

export default MainSetting;
