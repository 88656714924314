// import { Get, Post, Put, Delete } from ".././utils/Axios-old";
// import { endpoint } from ".././utils/Endpoints";
// import { GetHeader } from ".././utils/Header";
import { Delete, Get, Post, Put } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

// Get Properties With Overview By PropertyId
export const GetPropertyWithOverviewByPropertyId = (propertyId, unitType) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_Property_Overview_By_PropertyId,
      null,
      propertyId + "/" + unitType
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Get units by property Id
// export const GetUnitsByPropertyId = (propertyId, statusId) => {
//   statusId =
//     statusId === "" || statusId === null || statusId === undefined
//       ? 0
//       : statusId;
//   return new Promise((resolve, reject) => {
//     Get(
//       endpoint.Get_UnitsByPropertyId,
//       GetHeader(),
//       propertyId + "/" + statusId
//     )
//       .then((response) => {
//         resolve(response.data);
//       })
//       .catch((error) => {
//         reject({ error: true, message: error.message });
//       });
//   });
// };
// Get units by property Id
export const GetUnitsByPropertyId = (propertyId, statusId, exportFlag) => {
  statusId =
    statusId === "" || statusId === null || statusId === undefined
      ? 0
      : statusId;
  exportFlag =
    exportFlag === "" || exportFlag === null || exportFlag === undefined
      ? 0
      : exportFlag;
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_Units_By_PropertyId,
      null,
      propertyId + "/status/" + statusId + "/export/" + exportFlag
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Unit Status
export const GetUnitStatuses = (propertyId, currentStatusId) => {
  // propertyId =
  //   propertyId === "" || propertyId === null || propertyId === undefined
  //     ? 0
  //     : propertyId;
  // currentStatusId =
  //   currentStatusId === "" ||
  //   currentStatusId === null ||
  //   currentStatusId === undefined
  //     ? 0
  //     : currentStatusId;

  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_Unit_Statuses,
      null,
      propertyId + "/" + currentStatusId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Put setUnitStatus
export const PutSetUnitStatus = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Put_Set_Unit_Status, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Get Unit detail by unit-Id
export const GetUnitDetailByUnitId = (unitId) => {
  unitId =
    unitId === "" || unitId === null || unitId === undefined ? 0 : unitId;
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_Unit_DetailBy_UnitId, null, unitId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Housing
export const GetUnitHousing = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_Unit_Housing, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Put Edit Unit Details
export const PutEditUnitDetails = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Put_Edit_Unit_Details, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Get Unit Economy details by unit-Id
export const GetUnitEconomyDetailsByUnitId = (unitId) => {
  unitId =
    unitId === "" || unitId === null || unitId === undefined ? 0 : unitId;
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_Unit_EconomyDetailsBy_UnitId, null, unitId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Get Unit Economy details by unit-Id
export const GetUnitMaintainer = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_Unit_Maintainer, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Post Add multiple units
export const PostMultipleUnits = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Add_MultipleUnits, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Put Edit Unit Details
export const PutEditEconomyDetail = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Put_Edit_Economy_Detail, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Delete unit
export const DeleteSingleUnits = (propertyId, UnitId) => {
  return new Promise((resolve, reject) => {
    Delete(
      Site.ET,
      Endpoint.ET.Delete_Single_Units,
      null,
      propertyId + "/unit/residential/" + UnitId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Get Unit Marketing by unit-Id
export const GetUnitMarketingByUnitId = (unitId) => {
  unitId =
    unitId === "" || unitId === null || unitId === undefined ? 0 : unitId;
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_UnitMarketingByUnitId, null, unitId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Put Edit Unit Details
export const PutunitMarketing = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Put_Unitmarketing, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Get Units by tag Id
export const GetUnitsByTagId = (tagId) => {
  tagId = tagId === "" || tagId === null || tagId === undefined ? 0 : tagId;
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_Units_By_TagId, null, tagId + "/unit")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Delete Units by tag Id
export const DeleteUnitTag = (unitId, tagId) => {
  return new Promise((resolve, reject) => {
    Delete(Site.ET, Endpoint.ET.Delete_UnitTag, null, unitId + "/tag/" + tagId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Post Currency Converter settings-> economy
export const GetCurrencyRate = (currency, value) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_CurrencyRate,
      null,
      currency + "/value/" + value
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//------------- Get edit unit text field- -----------------------//
export const GetEditUnitTextField = (propertyId, type) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_Edit_Unit_Text_Field,
      null,
      propertyId + "/" + type
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//------------- Get edit unit text field- -----------------------//
export const GetTextByUnitId = (propertyId) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.Get_Text_Details, null, propertyId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//------------- Get unit overview by unitId- -----------------------//
export const GetUnitOverviewByUnitId = (uniId) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Units_GetUnitOverviewByUnitId,
      null,
      uniId + "/overview"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Put Multiple Units
export const PutMultipleUnits = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Put_MultipleUnits, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Get Units By PropertyId updated
export const GetUnitsByPropertyIdUpdated = (propertyId, exportFlag) => {
  exportFlag =
    exportFlag === "" || exportFlag === null || exportFlag === undefined
      ? 0
      : exportFlag;
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Units_GetUnitByPropertyId_1,
      null,
      propertyId + "/export/" + exportFlag
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Set Favourite Unit

export const SetFavouriteUnit = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Unit_SetFavouriteUnit, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// set update announce status

export const UpdateUnitAnnounceStatus = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Unit_UpdateUnitAnnounceStatus, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// edit parking lot

export const EditParkingUnit = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.EditParkingUnit, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// edit storage room

export const EditStorageUnit = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.EditStorageUnit, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// get parking lot details

export const GetParkingLotDetails = (properyId, exportFlag) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Units_GetParkingLotDetails,
      null,
      properyId + "/parking/export/" + exportFlag
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// get storage room details

export const GetStorageRoomDetails = (properyId, exportFlag) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Units_GetStorageRoomDetails,
      null,
      properyId + "/storage/export/" + exportFlag
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// get storage room by Id
export const GetStorageRoomById = (unitId) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Units_GetStorageRoomById, null, unitId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

export const GetParkingLotById = (unitId) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Units_GetParkingLotById, null, unitId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// get units textfields
export const GetUnitTextField = (propertyId, unitType) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Units_GetUnitTextField,
      null,
      propertyId + "/" + unitType
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Delete storge unit
export const deleteSingleStorage = (propertyId, unitId) => {
  return new Promise((resolve, reject) => {
    Delete(
      Site.ET,
      Endpoint.ET.Unit_DeleteSingleStorageUnit,
      null,
      propertyId + "/unit/storage/" + unitId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// delete parking unit

export const deleteSingleParking = (propertyId, unitId) => {
  return new Promise((resolve, reject) => {
    Delete(
      Site.ET,
      Endpoint.ET.Unit_DeleteSingleStorageUnit,
      null,
      propertyId + "/unit/parking/" + unitId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Parking Storage Payment Type
export const GetParkingStoragePaymentType = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_Parking_Storage_Payment_Type, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Get Parking Storage Payment Frequency
export const GetParkingStoragePaymentFrequency = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_Parking_Storage_Payment_Frequency, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//  Get Signing Order By PropertyId
export const GetSigningOrderByPropertyId = (propertyId) => {
  propertyId =
    propertyId === "" || propertyId === null || propertyId === undefined
      ? 0
      : propertyId;
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_Signing_Order_By_PropertyId,
      null,
      propertyId + "/signing"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Post create parking reservation
export const PostParkingReservation = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Post_Parking_Reservation, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Post create storage reservation
export const PostStorageReservation = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Post_Storage_Reservation, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Units_GetStorageAndParkingHistory

export const GetStorageAndParkingHistory = (unitId, typeOfUnit) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Units_GetStorageAndParkingHistory,
      null,
      unitId + "/type/" + typeOfUnit
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Put Unit Set Unit Status
export const PutUnitSetUnitStatus = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Units_SetUnitStatus, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};


// Get Main sub status
export const GetMainSubStatus = (organisation_id, type_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Units_GetMainSubStatus,
      null,
      organisation_id + "/status/type/" + type_id
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Put Update Main sub status
export const PutUpdateMainSubStatus = (payload) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Units_PutUpdateMainSubStatus,
      null,
      payload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};