import React, { useEffect } from "react";
import CustomToolTip from "../../../../../../../../Components/Tooltip/CustomTooltip";
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from "../../../../../../../../Components/Tabs/ReactTabs.style";
import ETButton from "../../../../../../../../Components/Buttons/ETButton";
import ReservationAndScreening from "../inprocess/page/ReservationAndScreening";
import Signings from "../inprocess/page/Signings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircleMsg ,H2 } from "../../../../../../../../styles/Common.style";
import { useBreadcrumb } from "../../../../../../../../Components/BreadcrumbContext ";

const InProcess = ({ setoverviewTabs, overviewTabs }) => {
  const ActorsTabsWizards = [
    {
      Id: "1",
      tab: "Reservations and screenings",
      component: <ReservationAndScreening />,
      Notification: "0",
    },
    {
      Id: "2",
      tab: "Signings",
      component: <Signings />,
      Notification: "0",
    },
  ];
  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    updateBreadcrumbs(["Overview", "In Process", "Reservations and screenings"]);
  }, [])
  return (
    <div>
      <div
        className="px-4 pt-3 pb-3 py-3"
        style={{ borderBottom: "1px solid #F0F0F0" }}
      >
        <div className=" d-flex align-items-center">
          <H2>In process</H2>
          <div className="ps-2">
            {" "}
            <CustomToolTip size="sm" text="In process" />{" "}
          </div>
        </div>
      </div>
      <Tabs lassName="mb-3 pb-1 d-flex flex-column">
        <div
          className="d-flex ps-4 pe-3 py-3 justify-content-between"
          style={{
            width: "100%",
            flexDirection: "row",
            flexWrap: "wrap",
            borderBottom: "1px solid #F0F0F0",
          }}
        >
          <TabList className="light ps-0  ">
            {ActorsTabsWizards.map((page, index) => (
              <>
                <Tab
                  key={index}
                  style={{ position: "relative" }}
                  onClick={() => setoverviewTabs(page?.tab)}
                >
                  <span style={{ position: "relative" }}>{page?.tab}</span>
                  <CircleMsg>
                    <span>{page?.Notification}</span>
                  </CircleMsg>
                </Tab>
              </>
            ))}
          </TabList>
          {overviewTabs === "Reservations and screenings" ? (
            <div className="mt-2 pe-2">
              <ETButton
                buttonText="CREATE RESERVATION"
                variant={"primary"}
                icon={true}
                width="fit-content"
                onClick={() => {
                  // setShowCreateNewTextField(true);
                  // setIsEditTextField(false);
                  // setCancelPopup(false);
                  // setShowPackage(true);
                  // setPackageType("8");
                  // setEditflag(false);
                }}
              />
            </div>
          ) : (
            <div className="mt-2 pe-2">
              <ETButton
                buttonText="CREATE SIGNING"
                variant={"primary"}
                icon={true}
                width="fit-content"
                onClick={() => {
                  // setShowCreateNewTextField(true);
                  // setIsEditTextField(false);
                  // setCancelPopup(false);
                  // setShowPackage(true);
                  // setPackageType("8");
                  // setEditflag(false);
                }}
              />
            </div>
          )}
        </div>
        <div className="bg-white" style={{ borderRadius: " 10px" }}>
          {ActorsTabsWizards.map((page, index) => (
            <TabPanel key={page?.Id}>{page?.component}</TabPanel>
          ))}
        </div>
      </Tabs>
    </div>
  );
};

export default InProcess;
