import React, { useState } from "react";
import Toggle from "../../Components/Switch/Switch";
import { GreenSpan } from "../../styles/Common.style";
import { useEventStore } from "../../hooks/stores/useEventStore";

const OptionsForEvents = ({ website, setWebsite, eido, setEido }) => {
  //-------------------Zustand--------------------------//

  const { prescreening, setPrescreening, qrCodeGenerate, setQrCodeGenerate } =
    useEventStore((state) => state);

  return (
    <div>
      <div className="optios-div light-font-text px-4 py-3 mt-lg-0 mt-4">
        <div className="form-label-title">Options</div>
        <div className="d-flex align-items-center justify-content-between">
          Pre-screening
          <Toggle
            id={"1"}
            checked={prescreening == 1 ? true : false}
            onChange={(e) => setPrescreening(e.target.checked)}
          />
        </div>

        <div className="d-flex align-items-center justify-content-between">
          <div>
            <div>QR-code</div>
          </div>{" "}
          <Toggle
            id={"3"}
            checked={qrCodeGenerate == 1 ? true : false}
            onChange={(e) => setQrCodeGenerate(e.target.checked)}
          />
        </div>
        <GreenSpan className="ms-1 fs-12 light-font-text">
          Download PNG
        </GreenSpan>
        <GreenSpan className="ms-1 fs-12 light-font-text">Copy link</GreenSpan>
        <div className="d-flex align-items-center justify-content-between">
          Publish on website{" "}
          <Toggle
            id={"4"}
            value={website}
            onChange={(e) => setWebsite(e.target.checked)}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between">
          Publish on Ei.DO{" "}
          <Toggle
            id={"5"}
            value={eido}
            onChange={(e) => setEido(e.target.checked)}
          />
        </div>
      </div>
    </div>
  );
};

export default OptionsForEvents;
