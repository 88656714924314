import React, { useCallback, useState, useRef } from "react";
import { IconButton, TeamsProfileContainer } from "../../styles/Common.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
// import { useDebounceEffect } from "./UserDebounceEffect";
import { useDebounceEffect } from "./useDebounceEffect";
import canvasPreview from "./CanvasPreview";
import Slider from "@mui/material/Slider";
import { customproperties } from "../../styles/Theme";
import Avatar from "../../assets/images/avatar.png";

const Profile = ({ croppedImage, setCroppedImage = () => {}, firstName }) => {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: "%", // Can be 'px' or '%'
    x: 50,
    y: 50,
    width: 50,
    height: 50,
    aspect: 1,
  });
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1);
  const [pageNumber, setPageNumber] = useState();
  const [numPages, setNumPages] = useState();
  const [pdf, setPdf] = useState();
  const [imgSrc, setImgSrc] = useState();
  const [prevImage, setPrevImage] = useState(null);

  const changeBtnRef = useRef(null);

  //handle select file
  function onSelectFile(e) {
    setPrevImage(croppedImage);

    if (e.target.files && e.target.files.length > 0) {
      setCroppedImage(null);
      setCrop(undefined); // Makes crop preview update between images.

      if (e.target.files[0].name.includes(".pdf")) {
        setPdf(e.target.files[0]);
      } else {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgSrc(reader.result?.toString() || "");
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  }
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );
  // get crop values of images
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  // display selected image
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  // save cropped image
  function saveCroppedImg() {
    var canvas = document.getElementById("mycanvas");
    var img = canvas?.toDataURL("image/jpeg");
    setCroppedImage(img);
  }
  return (
    <div className="d-flex align-items-center flex-column gap-3">
      <div className="d-flex flex-column align-items-center">
        {croppedImage ? (
          <div
            style={{ width: "200px" }}
            className="d-flex flex-column align-items-center"
          >
            <div className="ps-4">
              <img
                src={croppedImage}
                alt="pic"
                onError={(e) => (e.target.src = Avatar)}
                style={{
                  height: "200px",
                  width: "200px",
                  borderRadius: "100%",
                  // display: "block",
                }}
              />
            </div>
            <div className="d-flex w-100 gap-3 mt-3">
              <div>
                <IconButton type="button">
                  <label
                    htmlFor="fileInput"
                    className="cursor-pointer d-flex gap-2 align-items-center"
                  >
                    <FontAwesomeIcon icon="fa-regular fa-pen" />
                    Change
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    name="changeInput"
                    onChange={onSelectFile}
                    ref={changeBtnRef}
                    style={{ display: "none" }}
                  />
                </IconButton>
              </div>
              <IconButton
                onClick={() => {
                  setCroppedImage("");
                  setImgSrc("");
                }}
                className="gap-2"
              >
                <FontAwesomeIcon icon="fa-regular fa-trash" />
                Remove
              </IconButton>
            </div>
          </div>
        ) : imgSrc ? (
          <>
            <ReactCrop
              style={{ width: "200px", height: "200px" }}
              crop={crop}
              onChange={(pxCrop, percentCrop) => setCrop(pxCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              circularCrop={true}
              locked={true}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{
                  transform: `scale(${scale}) rotate(${rotate}deg)`,
                  width: "200px",
                  height: "200px",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </>
        ) : (
          <div className="ps-2 ms-1 ">
            <TeamsProfileContainer>
              {firstName?.slice(0, 1)}
            </TeamsProfileContainer>
          </div>
        )}

        {!croppedImage && !imgSrc && (
          <div className="mt-3 ms-1 ps-3">
            <IconButton type="button">
              <label
                for="files"
                className="cursor-pointer d-flex gap-2 align-items-center"
              >
                <FontAwesomeIcon icon="fa-regular fa-pen" />
                Upload
              </label>
              <input
                id="files"
                style={{ display: "none" }}
                type="file"
                onChange={onSelectFile}
              />
            </IconButton>
          </div>
        )}

        {!croppedImage && (
          <div>
            <div style={{ width: "200px" }}>
              {imgSrc && (
                <Slider
                  defaultValue={1}
                  step={0.05}
                  min={1}
                  max={2}
                  value={scale}
                  disabled={!imgSrc}
                  onChange={(e) => {
                    setScale(Number(e.target.value));
                  }}
                  sx={{
                    "& .MuiSlider-thumb": {
                      color: customproperties.primarybtnBgColor,
                    },
                    "& .MuiSlider-track": {
                      color: customproperties.primarybtnBgColor,
                    },
                    "& .MuiSlider-rail": {
                      color: customproperties.primarybtnBgColor,
                    },
                    "& .MuiSlider-active": {
                      color: customproperties.primarybtnBgColor,
                    },
                  }}
                />
              )}
            </div>
          </div>
        )}

        {!croppedImage && imgSrc && (
          <div className="d-flex gap-3">
            <IconButton
              type="button"
              onClick={() => {
                saveCroppedImg();
              }}
              disabled={!crop}
              className="gap-2"
            >
              <label
                style={{ padding: "0px 6px" }}
                className="cursor-pointer d-flex gap-2 align-items-center"
              >
                <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-line" />
                Save
              </label>
            </IconButton>

            <IconButton
              type="button"
              onClick={() => {
                setImgSrc("");
                setCroppedImage(prevImage);
              }}
              className="gap-2"
            >
              <FontAwesomeIcon icon="fa-regular fa-xmark" />
              Cancel
            </IconButton>
          </div>
        )}

        <div>
          {!!completedCrop && (
            <canvas
              id="mycanvas"
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
                display: "none",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
