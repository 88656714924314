import React, { useState } from "react";
import { CircleMsg, H4 } from "../../../../../styles/Common.style";
import ScreeningAccordionTable from "../../../standardComponents/ScreeningAccordionTable";
import RestartScreening from "../../popups/RestartScreening";
import CancelWizard from "../../../standardComponents/cancelSigningOrder/CancelWizard";
import { ActionsPopup } from "../../popups/ActionsPopup";
import MultipleApproveScreening from "../approveScreenings/MultipleApproveScreening";
import ApproveScreening from "../approveScreenings/ApproveScreening";
import FollowUp from "../../../standardComponents/follow up/FollowUp";
import AddResponsibleAgent from "../../../standardComponents/AddResponsibleAgent";
import MoveToAnotherUnit from "../../../opportunities/MoveToAnotherUnit";

const Declined = () => {
  // Handle the click event based on the item in common component
  const handleItemClick = (itemText) => {
    console.log(`Clicked on: ${itemText}`);
  };
 
  /*-- Restart Screening --*/
  const [showStartScreening, setShowStartScreening] = useState(false);
  /*-- move unit --*/
  const [moveToAnotherUnits, setMoveToAnotherUnit] = useState(false);
  const handleMoveToAnotherUnit = (item) => {
    setMoveToAnotherUnit(true);
  }
  /*-- cancel screening --*/
  const [cancelScreen, setCancelScreen] = useState(false);
  const [cancelScreenStepWizard, setCancelScreenStepWizard] = useState(
    "SelectReasonSigningOrder"
  );
  const cancelScreening = (item) => {
    setCancelScreen(true);
  };
  /*-- change agent --*/
  const [changeAgent, setChangeAgent] = useState(false);
  const handleChangeAgent = (item) => {
    setChangeAgent(true);
  }
  /*-- Archive screening --*/
  const [ArchiveScreen, setArchiveScreen] = useState(false);
  const archiveScreening = (item) => {
    setArchiveScreen(true);
  }
   /*-- Follow-up customer  --*/
   const [showFollowsUpWizard, setShowFollowsUpWizard] = useState(false);
   const [selectedFollowUpIndex, setSelectedFollowUpIndex] = useState(0);
   const handleCustomerDetails = (pageIndex = 0) => {
     // console.log(pageIndex, "i asssssssssssss");
     setShowFollowsUpWizard(true);
     setSelectedFollowUpIndex(pageIndex);
   };
  /*-- Aprrove Screening --*/
  const [multipleApproveScreening, setMultipleApproveScreening] =
    useState(false);
  const [approveScreening, setApproveScreening] = useState(false);
  const approveScreen = (item) => {
    item.length > 1
      ? setMultipleApproveScreening(true)
      : setApproveScreening(true);
  };
  // // customers context menu bar for declined With No Priority Set
  const DeclinedMenuItemsWithNoPrioritySet = [
    {
      text: "Restart screening",
      onClick: () => setShowStartScreening(true),
    },
    {
      text: "Approve screening",
      onClick: approveScreen,
    },
    {
      text: "Cancel screening",
      onClick: cancelScreening,
    },
    {
      text: "Add priority on Contract",
      onClick: () => handleItemClick,
    },
    {
      text: "Add follow ups",
      onClick: ()=> handleCustomerDetails(0),
    },
    {
      text: "Add customer as favorite",
      onClick: handleItemClick,
    },
    {
      text: "Go to contract",
      onClick: ()=> handleCustomerDetails(2),
    },
    {
      text: "Go to contact card",
      onClick: ()=> handleCustomerDetails(0),
    },
    {
      text: "Change responsible agent",
      onClick: handleChangeAgent,
    },
    {
      text: "Move to another unit",
      onClick: handleMoveToAnotherUnit,
    },
    {
      text: "Archive",
      onClick: archiveScreening,
    },
  ];

  // // customers context menu bar for declined With Priority Set
  const DeclinedMenuItemsWithPrioritySet = [
    {
      text: "Restart screening",
      onClick: handleItemClick,
    },
    {
      text: "Approve screening",
      onClick: handleItemClick,
    },
    {
      text: "Cancel screening",
      onClick: handleItemClick,
    },
    {
      text: "Change priority on Contract",
      onClick: () => handleItemClick,
    },
    {
      text: "Add follow ups",
      onClick:()=> handleCustomerDetails(0),
    },
    {
      text: "Add customer as favorite",
      onClick: handleItemClick,
    },
    {
      text: "Go to contract",
      onClick: handleItemClick,
    },
    {
      text: "Go to contact card",
      onClick: ()=> handleCustomerDetails(0),
    },
    {
      text: "Change responsible agent",
      onClick: handleItemClick,
    },
    {
      text: "Move to another unit",
      onClick: handleItemClick,
    },
    {
      text: "Archive",
      onClick: handleItemClick,
    },
  ];

  // main frame container for decline screen & Cancel screening
  const MainFrameMenuDS = [
    {
      text: "Archive all screenings",
      onClick: handleItemClick,
    },
    {
      text: "Add unit as favourite",
      onClick: handleItemClick,
    },
  ];

  return (
    <div>
      <div className="px-4">
        <div className="d-flex ">
          <div className="my-2">
            <div className="d-flex align-items-center pt-3 pb-2">
              <H4>Declined screenings</H4>
              <CircleMsg>
                <span>{DeclinedScreeningData?.length}</span>
              </CircleMsg>
            </div>
            <div className="light-font-text">
              Below is a list of the screenings that have been approved by a
              user in the system, but where an agreement has not yet been
              created yet.
            </div>
          </div>
        </div>
        {/* table section */}
        <div>

          <ScreeningAccordionTable
            data={DeclinedScreeningData}
            usedFor="DS"
            MainFrameMenu={MainFrameMenuDS}
            CustomersContextmenuItemsCondition1={
              DeclinedMenuItemsWithNoPrioritySet
            }
            CustomersContextmenuItemsCondition2={
              DeclinedMenuItemsWithPrioritySet
            }
          />
          <RestartScreening
            open={showStartScreening}
            onHide={() => setShowStartScreening(false)}
            onCancel={() => setShowStartScreening(false)}
            usedFor="RS"
          />
          <ActionsPopup
            open={ArchiveScreen}
            onHide={() => setArchiveScreen(false)}
            whiteClick={() => setArchiveScreen(false)}
            title={"Archive Customer"}
            primaryButton={"Archive"}
            titleTooltipText={"Archive Customer"}
            firstText={"Do you want to archive this customer?"}
            secondText={"The customer will be archived and moved to Archive tab."}
          />

          <CancelWizard
            title={"Cancel Screening"}
            titleTooltip
            titleTooltipText={"Cancel Screening"}
            cancelReasonHeader={"Select Reason for Cancellation"}
            cancelReasonText={
              "When a screening is canclled, you must select one or more cancellation reasons below.If there is no suitable reason, the option Other can be used."
            }
            cancelReasonHeaderTooltip={"Select Reason for Cancellation"}
            Status={"The screening is moved to the Declined screenings tab."}
            openCancelWizardModal={cancelScreen}
            nextWizardScreenText={"SelectReasonSigningOrder"}
            setOpenCancelWizardModal={setCancelScreen}
            switchWizardScreen={cancelScreenStepWizard}
            setSwitchWizardScreen={setCancelScreenStepWizard}
            secondPageStatus={
              "It’s only possible to send to MyPage if the customer has a active profile."
            }
            cancelSecondPageReasonHeader={"Select reason for cancellation"}
            cancelSecondPageReasonHeaderTooltip={
              "Select reason for cancellation"
            }
            cancelReasonSecondPageFooter={"Send confirmation to"}
            cancelSecondPageReasonFooterTooltip={"Send confirmation to"}
          />
          <AddResponsibleAgent
            showResponsibleAgentPopup={changeAgent}
            setShowResponsibleAgentPopup={setChangeAgent}
            usedFor="EDIT"
            data={""}
          // leadId={leadId}
          // createUpdateLeadAgentMutate={createUpdateLeadAgentMutate}
          // createUpdateLeadAgentLoading={createUpdateLeadAgentLoading}
          // leadsByDivisionIdDataRefetch={leadsByDivisionIdDataRefetch}
          />
          <ApproveScreening
            setApproveScreening={setApproveScreening}
            approveScreening={approveScreening}
          />
          <MultipleApproveScreening
            setMultipleApproveScreening={setMultipleApproveScreening}
            multipleApproveScreening={multipleApproveScreening}
          />
           <FollowUp
          setFollowUp={setShowFollowsUpWizard}
          followUp={showFollowsUpWizard}
          selectedIndex={selectedFollowUpIndex}
          setSelectedIndex={setSelectedFollowUpIndex}
        />
          <MoveToAnotherUnit
            showMoveToAnotherUnits={moveToAnotherUnits}
            setShowMoveToAnotherUnits={setMoveToAnotherUnit}
          />
          
        </div>
      </div>
    </div>
  );
};

export default Declined;

// static data
const DeclinedScreeningData = [
  {
    id: 1,
    unit: "Dybbølsbro 3, 1 | 212-004",
    property: "Kaktus Towers",
    deal: "No priority set",
    active_screening: "0",
    screening_started: "2 Jul, 2023 at 10:32",
    status: "ALERT",
    bg_color: "#AA1518",
    progress: 20,
    declined_reasons: "Is registered in RKI / Does not fulfil credit rules",
    iconData: [
      {
        id: 1,
        ico_name: "fa-solid fa-user",
        iconColor: "#AA1518",
        category: "Information",
      },
      {
        id: 2,
        ico_name: "fa-solid fa-briefcase",
        iconColor: "#D1D1D1",
        category: "Occupation",
      },
      {
        id: 3,
        ico_name: "fa-solid fa-building-columns",
        iconColor: "#349032",
        category: "Economy",
      },
      {
        id: 4,
        ico_name: "fa-solid fa-house-user",
        iconColor: "#D1D1D1",
        category: "Household",
      },
      {
        id: 5,
        ico_name: "fa-solid fa-passport",
        iconColor: "#D1D1D1",
        category: "Identification",
      },
      {
        id: 6,
        ico_name: "fa-solid fa-circle-check",
        iconColor: "#AA1518",
        category: "Confirmation",
      },

      {
        id: 7,
        ico_name: "fa-solid fa-circle-info",
        iconColor: "#D1D1D1",
        category: "Additional information",
      },
      {
        id: 8,
        ico_name: "fak fa-salary-slip-svg-test2",
        iconColor: "#D1D1D1",
        category: "Salary slip",
      },
    ],
  },
  {
    id: 4,
    unit: "Kalundborg Hovedvejen 1,| 049-200",
    property: "Munkesøhuse",
    deal: "No priority set",
    active_screening: "0",
    screening_started: "19 Aug, 2023 at 20:47",
    status: "ALERT",
    bg_color: "#AA1518",
    progress: 10,
    declined_reasons: "Other reason",
    iconData: [
      {
        id: 1,
        ico_name: "fa-solid fa-user",
        iconColor: "#349032",
        category: "Information",
      },
      {
        id: 2,
        ico_name: "fa-solid fa-briefcase",
        iconColor: "#D1D1D1",
        category: "Occupation",
      },
      {
        id: 3,
        ico_name: "fa-solid fa-building-columns",
        iconColor: "#D1D1D1",
        category: "Economy",
      },
      {
        id: 4,
        ico_name: "fa-solid fa-house-user",
        iconColor: "#D1D1D1",
        category: "Household",
      },
      {
        id: 5,
        ico_name: "fa-solid fa-passport",
        iconColor: "#AA1518",
        category: "Identification",
      },
      {
        id: 6,
        ico_name: "fa-solid fa-circle-check",
        iconColor: "#D1D1D1",
        category: "Confirmation",
      },

      {
        id: 7,
        ico_name: "fa-solid fa-circle-info",
        iconColor: "#D1D1D1",
        category: "Additional information",
      },
      {
        id: 8,
        ico_name: "fak fa-salary-slip-svg-test2",
        iconColor: "#D1D1D1",
        category: "Salary slip",
      },
    ],
  },
];

// change log and other info
// Created by Suraj
