import React, { useEffect } from "react";
import CustomToolTip from "../../../../../../../../Components/Tooltip/CustomTooltip";
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from "../../../../../../../../Components/Tabs/ReactTabs.style";
import ETButton from "../../../../../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../../../../../Components/CustomPopup";
import Inquiries from "../requests/page/Inquiries";
import Pending from "../requests/page/Pending";
import Declined from "../requests/page/Declined";
import Approved from "../requests/page/Approved";
import Interested from "../requests/page/Interested";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CircleMsg,
  StyledLoadingBar,
  H2
} from "../../../../../../../../styles/Common.style";
import { useState } from "react";
import LoadingBar from "react-top-loading-bar";
import ExistingEvent from "./createInvite/ExistingEvent";
import AttachedDocuments from "./createInvite/AttachedDocuments";
import InvitationSend from "./createInvite/InvitationSend";
import { customproperties } from "../../../../../../../../styles/Theme";
import ConfirmSuggestion from "./commonPage/ConfirmSuggestion";
import AddCustomerInfo from "./commonPage/AddCustomerInfo";
import { useBreadcrumb } from "../../../../../../../../Components/BreadcrumbContext ";

const Requests = ({ setoverviewTabs }) => {
  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(30);
  const [stepWizard, setStepWizard] = useState("ExistingEvent");

  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    updateBreadcrumbs(["Overview", "Requests", "Inquiries"]);
  }, [])
  const ActorsTabsWizards = [
    {
      Id: "1",
      tab: "Inquiries",
      component: <Inquiries />,
      Notification: "0",
    },
    {
      Id: "2",
      tab: "Pending",
      component: <Pending />,
      Notification: "0",
      // msg: ".",
    },
    {
      Id: "3",
      tab: "Declined",
      component: <Declined />,
      Notification: "0",
    },
    {
      Id: "4",
      tab: "Approved",
      component: <Approved />,
      Notification: "0",
    },
    {
      Id: "4",
      tab: "Interested",
      component: <Interested />,
      Notification: "0",
    },
  ];
  const WizardsCreateInvitePages = {
    ExistingEvent: (
      <ExistingEvent
        setShow={setShow}
        show={show}
        setStepWizard={setStepWizard}
        progress={progress}
        setProgress={setProgress}
      />
    ),

    AttachedDocuments: (
      <AttachedDocuments
        setShow={setShow}
        show={show}
        setStepWizard={setStepWizard}
        progress={progress}
        setProgress={setProgress}
      />
    ),

    InvitationSend: (
      <InvitationSend
        setShow={setShow}
        show={show}
        setStepWizard={setStepWizard}
        progress={progress}
        setProgress={setProgress}
      />
    ),
  };
  return (
    <div>
      <div
        className="px-4 pt-3 pb-3 py-3"
        style={{ borderBottom: "1px solid #F0F0F0" }}
      >
        <div className=" d-flex align-items-center">
          <H2>Requests</H2>
          <div className="ps-2">
            {" "}
            <CustomToolTip size="sm" text="Requests" />{" "}
          </div>
        </div>
      </div>
      <Tabs lassName="mb-3 pb-1 d-flex flex-column">
        <div
          className="d-flex ps-4 pe-3 py-3 justify-content-between"
          style={{
            width: "100%",
            flexDirection: "row",
            flexWrap: "wrap",
            borderBottom: "1px solid #F0F0F0",
          }}
        >
          <TabList className="light ps-0  ">
            {ActorsTabsWizards.map((page, index) => (
              <>
                <Tab
                  key={index}
                  style={{ position: "relative" }}
                  onClick={() => setoverviewTabs(page?.tab)}
                >
                  <span className="me-1" style={{ position: "relative" }}>
                    {page?.tab}
                  </span>
                  (<span>{page?.Notification}</span>)
                </Tab>
              </>
            ))}
          </TabList>
          <div className="mt-2 pe-2">
            <ETButton
              buttonText="CREATE NEW LEAD"
              variant={"primary"}
              icon={true}
              width="fit-content"
              onClick={() => {
                setShow(true);
                // setShowCreateNewTextField(true);
                // setIsEditTextField(false);
                // setCancelPopup(false);
                // setShowPackage(true);
                // setPackageType("8");
                // setEditflag(false);
              }}
            />
          </div>
        </div>
        <div className="bg-white" style={{ borderRadius: " 10px" }}>
          {ActorsTabsWizards.map((page, index) => (
            <TabPanel key={page?.Id}>{page?.component}</TabPanel>
          ))}
        </div>
      </Tabs>

      <CustomPopup
        open={show}
        onHide={() => setShow(false)}
        title="Create invite for a viewing appointment"
        titleTooltipText="Create invite for a viewing appointment"
        title_color="black"
        size={"xl"}
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
      >
        <div>
          <StyledLoadingBar>
            <LoadingBar
              color={customproperties.primaryColor}
              progress={progress}
              containerStyle={{
                position: "absolute",
                height: "2.5px",
                // background: "#f0f0f0",
              }}
              shadow={false}
              onLoaderFinished={() => setProgress(0)}
            />
          </StyledLoadingBar>
        </div>
        <div
          className=" me-1"
        // style={{ height: "600px" }}
        >
          {WizardsCreateInvitePages[stepWizard]}
        </div>
      </CustomPopup>

      {/* <ConfirmSuggestion suggestionType={"CS"} />
      <ConfirmSuggestion suggestionType={"CI"} /> */}
    </div>
  );
};

export default Requests;
