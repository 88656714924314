import React, { useEffect, useState } from "react";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { Col, Form, Row } from "react-bootstrap";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { REQUIRED_ERR } from "../../../utils/validationConstants";
import ETButton from "../../../Components/Buttons/ETButton";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useGetGeographyCountry } from "../../../hooks/queries/usePropertyQueries";
import {
  useGetOrganizationById,
  useGetOrganizationFloorType,
  useGetOrganizationSideType,
  usePutUpdateOrganization,
} from "../../../hooks/queries/useOrganization";
import {
  useGetStreetDetails,
  useGetZipcodeByCountry,
} from "../../../hooks/queries/useGeographyQueries";
import { useOrganizationId } from "../../../hooks/stores/useOrganizationStore";
import { status } from "../../../model/Constant";
import { toast } from "react-toastify";
import { H2, H3, H4 } from "../../../styles/Common.style";
import Toggle from "../../../Components/Switch/Switch";
import InputField from "../../../Components/Textfield/InputField";
import moment from "moment";
import {
  useGetOwners,
  useGetUserByDivisionId,
} from "../../../hooks/queries/useUserQueries";
import { useUserDetails } from "../../../hooks/stores/useUserDetailsStore";
import ChangeDomainPopup from "./ChangeDomainPopup";
import { SelectPhoneNumber } from "../../../Components/phoneNumberDropdown/SelectPhoneNumber";
import { billingDetailsDropdownData } from "../../../model/commonDropdownData";
import {
  ErrorAlert,
  LoadingAlert,
  SucessAlert,
} from "../../../Components/alerts/AlertsAndMessages";
import { capitalizeEachWord } from "../../../utils/TextTransform";
import { useGetBillingLevelDetails } from "../../../hooks/queries/useCommonTypeQueries";
import StreetInput from "../../../Components/Textfield/StreetInput";

const OrganizationDetail = () => {
  //------------- React hook form ----------------//
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    clearErrors,
    reset,
    resetField,
    getFieldState,
    formState: { errors, isDirty, dirtyFields, isValid },
  } = useForm({
    defaultValues: {
      organisation_name: "",
      organisation_owner: "",
      company: "",
      company_co: "",
      cvr_number: "",
      vat_number: "",
      gst_number: "",
      street_name: "",
      house_number: "",
      floor_number: "",
      side: "",
      door: "",
      country: "",
      zipCode: "",
      city: "",
      contact_person: "",
      contact_email: "",
      country_code: "",
      phone: "",
      company_website: "",
      primaryColor: "#000000",
      secondaryColor: "#000000",
      stroke: "#000000",
      progressBar: "#000000",
      isSameDetailAsAboveActive: 0,

      //-------billing info-------//
      billing_method: "",
      bInfo_company: "",
      bInfo_company_co: "",
      ean_number: "",
      bInfo_cvr_number: "",
      bInfo_vat_number: "",
      bInfo_gst_number: "",
      bInfo_house_number: "",
      bInfo_floor_number: "",
      bInfo_side: "",
      bInfo_door: "",
      bInfo_country: "",
      bInfo_zipCode: "",
      bInfo_street_name: "",
      bInfo_city: "",
      invoice_email: "",
      attention_person: "",
      domain_name: "",
    },
  });

  //------------- zustand ------------------//
  const organizationId = useOrganizationId((state) => state.organizationId);
  const { userDetails } = useUserDetails((state) => state);
  //------------- states ------------------//
  const [streetname, setStreetname] = useState("");
  const [searchedStreetName, setSearchedStreetName] = useState("");
  const [isSearchStreetVisible, setIsSearchStreetVisible] = useState(false);
  const [isSearchInfoStreetVisible, setIsSearchInfoStreetVisible] =
    useState(false);
  const [checked, setChecked] = useState(false);
  const [timeOutId, setTimeOutId] = useState(null);
  const [showChangeDomain, setShowChangeDomain] = useState(false);

  // ------------ state created for upload in file and image -----------//
  // const [myFileForUploadThumbnail, setMyFileForUploadThumbnail] = useState("");
  // const [imageNameUploaded, setImageNameUploaded] = useState("");
  // console.log(myFileForUploadThumbnail, imageNameUploaded, "tetttttt");
  //------------- React query ------------------//
  const { data: GetUserByDivisionIdData } = useGetUserByDivisionId(
    userDetails?.division_id
  );
  const { data: getOrganizationSideTypeData } = useGetOrganizationSideType();
  const { data: getOrganizationFloorTypeData } = useGetOrganizationFloorType();
  const {
    data: getOrganizationByOrganizationIdData,
    refetch: getOrganizationByOrganizationIdDataRefetch,
  } = useGetOrganizationById(organizationId);

  const { data: countryData } = useGetGeographyCountry();
  const {
    mutateAsync: PutUpdateOrganisation,
    isLoading: PutUpdateOrganisationIsLoading,
  } = usePutUpdateOrganization();
  const { data: getStreetDetailsData } =
    useGetStreetDetails(searchedStreetName);

  const { data: zipCodeByCountryIdData } = useGetZipcodeByCountry(
    getValues("country")?.value
  );
  const { data: zipCodeByCountryIdData2 } = useGetZipcodeByCountry(
    getValues("bInfo_country")?.value
  );
  const { data: GetOwnersData, refetch: GetOwnerDataRefetch } = useGetOwners(
    "O",
    organizationId
  );

  const { data: GetBillingLevelDetailsData } = useGetBillingLevelDetails(
    "o",
    organizationId
  );

  useEffect(() => {
    if (getOrganizationByOrganizationIdData?.data !== null) {
      const organizationByIdData =
        getOrganizationByOrganizationIdData?.data?.[0];
      setValue("organisation_name", organizationByIdData?.name);
      setValue("organisation_owner", {
        value: organizationByIdData?.owner_id?.toString(),
        label: organizationByIdData?.owner_name,
      });
      setValue("company", organizationByIdData?.company_name);
      setValue("company_co", organizationByIdData?.care_of);
      setValue("vat_number", organizationByIdData?.public_id1);
      setValue("gst_number", organizationByIdData?.public_id2);
      setValue("cvr_number", organizationByIdData?.public_id3);
      setValue("street_name", organizationByIdData?.street_name);
      setValue("house_number", organizationByIdData?.house_number);
      setValue("floor_number", {
        value: organizationByIdData?.floor_id,
        label: organizationByIdData?.floor_name,
      });
      setValue(
        "billing_method",
        organizationByIdData?.billing_method_applicable_id
          ? {
              value: organizationByIdData?.billing_method_applicable_id,
              label: billingDetailsDropdownData?.filter(
                (data) =>
                  data?.Id?.toString() ===
                  organizationByIdData?.billing_method_applicable_id?.toString()
              )?.[0]?.Text,
            }
          : ""
      );
      setValue("side", {
        value: organizationByIdData?.side_id,
        label: organizationByIdData?.side_name,
      });
      setValue("door", organizationByIdData?.door);
      setValue("country", {
        value: organizationByIdData?.country_id,
        label: organizationByIdData?.country,
      });
      setValue("zipCode", {
        value: organizationByIdData?.zipcode_id,
        label: organizationByIdData?.city,
      });
      setValue("city", organizationByIdData?.city);
      setValue("contact_person", organizationByIdData?.contact_person_name);
      setValue("contact_email", organizationByIdData?.contact_person_email);
      setValue("country_code", organizationByIdData?.contact_person_isd_code);
      setValue("phone", organizationByIdData?.contact_person_phone_no);
      setValue("company_website", organizationByIdData?.website_url);

      //billing info
      setValue(
        "bInfo_company",
        organizationByIdData?.billing_details?.[0]?.company_name
      );

      setValue(
        "bInfo_company_co",
        organizationByIdData?.billing_details?.[0]?.care_of
      );
      setValue("ean_number", organizationByIdData?.billing_details?.[0]?.ean);
      setValue(
        "bInfo_vat_number",
        organizationByIdData?.billing_details?.[0]?.public_id1
      );
      setValue(
        "bInfo_gst_number",
        organizationByIdData?.billing_details?.[0]?.public_id2
      );
      setValue(
        "bInfo_cvr_number",
        organizationByIdData?.billing_details?.[0]?.public_id3
      );

      setValue(
        "bInfo_street_name",
        organizationByIdData?.billing_details?.[0]?.street_name
      );
      setValue(
        "bInfo_house_number",
        organizationByIdData?.billing_details?.[0]?.house_number
      );
      setValue(
        "bInfo_floor_number",
        organizationByIdData?.billing_details?.[0]?.floor_id === ""
          ? ""
          : {
              value: organizationByIdData?.billing_details?.[0]?.floor_id,
              label: organizationByIdData?.billing_details?.[0]?.floor_name,
            }
      );
      setValue(
        "bInfo_side",
        organizationByIdData?.billing_details?.[0]?.side_id === ""
          ? ""
          : {
              value: organizationByIdData?.billing_details?.[0]?.side_id,
              label: organizationByIdData?.billing_details?.[0]?.side_name,
            }
      );
      setValue("bInfo_door", organizationByIdData?.billing_details?.[0]?.door);
      setValue(
        "bInfo_country",
        organizationByIdData?.billing_details?.[0]?.country_id === ""
          ? ""
          : {
              value: organizationByIdData?.billing_details?.[0]?.country_id,
              label: organizationByIdData?.billing_details?.[0]?.country,
            }
      );
      setValue(
        "bInfo_zipCode",
        organizationByIdData?.billing_details?.[0]?.zipcode_id === ""
          ? ""
          : {
              value: organizationByIdData?.billing_details?.[0]?.zipcode_id,
              label: organizationByIdData?.billing_details?.[0]?.city,
            }
      );
      setValue("bInfo_city", organizationByIdData?.billing_details?.[0]?.city);
      setValue(
        "invoice_email",
        organizationByIdData?.billing_details?.[0]?.invoice_email
      );
      setValue(
        "attention_person",
        organizationByIdData?.billing_details?.[0]?.attention_person_name
      );

      setValue("domain_name", organizationByIdData?.domain_name);
      setValue(
        "isSameDetailAsAboveActive",
        organizationByIdData?.is_same_detail_as_defined_general
      );
      setValue("short_name", organizationByIdData?.shortname);
      // setMyFileForUploadThumbnail({
      //   ...myFileForUploadThumbnail,
      //   name: organizationByIdData?.logo_thumb_filename,
      //   path: organizationByIdData?.logo_thumb_url,
      // });

      // setImageNameUploaded(organizationByIdData?.logo_thumb_filename);
    }
    return () => {};
  }, [getOrganizationByOrganizationIdData]);

  useEffect(() => {
    if (
      getValues("cvr_number")?.length ||
      getValues("vat_number")?.length ||
      getValues("gst_number")?.length
    ) {
      clearErrors(["cvr_number", "vat_number", "gst_number"]);
    }
    if (
      getValues("bInfo_cvr_number")?.length ||
      getValues("bInfo_vat_number")?.length ||
      getValues("bInfo_gst_number")?.length
    ) {
      clearErrors(["bInfo_cvr_number", "bInfo_vat_number", "bInfo_gst_number"]);
    }
  }, [
    watch("cvr_number"),
    watch("vat_number"),
    watch("gst_number"),
    watch("bInfo_cvr_number"),
    watch("bInfo_vat_number"),
    watch("bInfo_gst_number"),
  ]);

  // --------------------------handle zipcode change-----------------------//
  const handleZipCode = (e, ActionType) => {
    if (ActionType.action === "clear") {
      setValue("city", "");
    } else {
      if (e?.value != "" || e?.value != "undefined") {
        const HandleCityName = zipCodeByCountryIdData?.data?.filter(
          (data) => data?.Id == e?.value
        );
        const CityData = HandleCityName[0].Text;
        setValue("city", CityData);
      }
    }
  };
  // --------------------------handle billing zipcode change-----------------------//
  const handleBillingZipCode = (e, ActionType) => {
    if (ActionType.action === "clear") {
      setValue("bInfo_city", "");
    } else {
      if (e?.value != "" || e?.value != "undefined") {
        const HandleCityName = zipCodeByCountryIdData2?.data?.filter(
          (data) => data?.Id == e?.value
        );
        const CityData = HandleCityName[0].Text;
        setValue("bInfo_city", CityData);
      }
    }
  };

  const handleSetSameDetailAsAbove = (e) => {
    setValue("isSameDetailAsAboveActive", e.target.checked);
    handleInputChange(1000)
    if (e.target.checked) {
      setValue("bInfo_company", getValues("company"), { shouldValidate: true });

      setValue("bInfo_company_co", getValues("company_co"), {
        shouldValidate: true,
      });
      setValue("bInfo_cvr_number", getValues("cvr_number"), {
        shouldValidate: false,
      });
      setValue("bInfo_vat_number", getValues("vat_number"), {
        shouldValidate: false,
      });
      setValue("bInfo_gst_number", getValues("gst_number"), {
        shouldValidate: false,
      });
      setValue("bInfo_street_name", getValues("street_name"), {
        shouldValidate: true,
      });
      setValue("bInfo_house_number", getValues("house_number"), {
        shouldValidate: true,
      });
      setValue(
        "bInfo_floor_number",
        {
          value: getValues("floor_number")?.value,
          label: getValues("floor_number")?.label,
        },
        { shouldValidate: true }
      );
      setValue(
        "bInfo_side",
        {
          value: getValues("side")?.value,
          label: getValues("side")?.label,
        },
        { shouldValidate: true }
      );
      setValue("bInfo_door", getValues("door"), { shouldValidate: true });
      setValue(
        "bInfo_country",
        {
          value: getValues("country")?.value,
          label: getValues("country")?.label,
        },
        { shouldValidate: true }
      );
      setValue(
        "bInfo_zipCode",
        {
          value: getValues("zipCode")?.value,
          label: getValues("zipCode")?.label,
        },
        { shouldValidate: true }
      );
      setValue("bInfo_city", getValues("city"), { shouldValidate: true });
    } else {
      resetField("bInfo_company");
      resetField("bInfo_company_co");
      resetField("bInfo_cvr_number");
      resetField("bInfo_vat_number");
      resetField("bInfo_gst_number");
      resetField("bInfo_street_name");
      resetField("bInfo_house_number");
      resetField("bInfo_floor_number");
      resetField("bInfo_side");
      resetField("bInfo_door");
      resetField("bInfo_country");
      resetField("bInfo_zipCode");
      resetField("bInfo_city");
    }
  };

  const displayItem = (selected) => {
    return selected
      ? {
          value: selected?.Id,
          label: countryData?.data?.filter(
            (item, i) => item.Id === selected?.value
          )?.[0]?.Text,
        }
      : "";
  };

  //------Handle street field change--------//
  const handleStreetFieldChange = (event) => {
    if (watch("country")?.value == "DK" && event.target.value?.length >= 4) {
      setIsSearchStreetVisible(true);
      setSearchedStreetName(event?.target?.value);
    } else {
      setIsSearchStreetVisible(false);
    }
    handleInputChange();
  };

  //------Handle billing info street field change--------//
  const handleInfoStreetFieldChange = (event) => {
    if (
      watch("bInfo_country")?.value == "DK" &&
      event.target.value?.length >= 4
    ) {
      setIsSearchInfoStreetVisible(true);
      setSearchedStreetName(event?.target?.value);
    } else {
      setIsSearchInfoStreetVisible(false);
    }
    handleInputChange();
  };

  //-------Handle street detail click-------// (Dropdown will be visible only when selected country is Denmark)
  const handleStreetDetailClick = (item) => {
    setValue("street_name", item?.street_name);
    setValue("city", item?.city, { shouldValidate: item?.city !== "" && true });
    setValue("house_number", item?.house_number, {
      shouldValidate: item?.house_number !== "" && true,
    });
    setValue(
      "zipCode",
      item?.zipcode == ""
        ? ""
        : {
            value: item?.zipcode,
            label: zipCodeByCountryIdData?.data?.filter(
              (el) => el.Id == item?.zipcode
            )?.[0]?.Text,
          },
      { shouldValidate: item?.zipcode !== "" && true }
    );
    handleInputChange();
  };
  //-------Handle street detail click for billing information-------// (Dropdown will be visible only when selected country is Denmark)
  const handleInfoStreetDetailClick = (item) => {
    setIsSearchInfoStreetVisible(false);
    setValue("bInfo_street_name", item?.street_name);
    setValue("bInfo_city", item?.city, {
      shouldValidate: item?.city !== "" && true,
    });
    setValue("bInfo_house_number", item?.house_number, {
      shouldValidate: item?.house_number !== "" && true,
    });
    setValue(
      "bInfo_zipCode",
      item?.zipcode == ""
        ? ""
        : {
            value: item?.zipcode,
            label: zipCodeByCountryIdData2?.data?.filter(
              (el) => el.Id == item?.zipcode
            )?.[0]?.Text,
          },
      { shouldValidate: item?.zipcode !== "" && true }
    );
    handleInputChange();
  };

  // ------------------ Handle update organization ----------------- //
  const handleEditOrganization = async (data) => {
    let id;
    const UpdateOrganization = {
      organisation_id: organizationId?.toString(),
      name: capitalizeEachWord(data?.organisation_name),
      company_name: capitalizeEachWord(data?.company),
      shortname: data?.short_name?.toUpperCase(),
      owner_id: data?.organisation_owner.value,
      care_of: capitalizeEachWord(data?.company_co),
      public_type_id1: "1",
      public_id1: data?.vat_number,
      public_type_id2: "1",
      public_id2: data?.gst_number,
      public_type_id3: "1",
      public_id3: data?.cvr_number,
      street_name: data?.street_name,
      house_number: data?.house_number,
      floor_id: data?.floor_number?.value?.toString(),
      side_id: data?.side?.value?.toString(),
      door: data?.door,
      zipcode_id: data?.zipCode?.value,
      country_id: data?.country?.value,
      contact_person_name: capitalizeEachWord(data?.contact_person),
      contact_person_email: data?.contact_email?.toLowerCase(),
      contact_person_isd_code: data?.country_code,
      contact_person_phone_no: data?.phone,
      website_url: data?.company_website,
      domain: data?.domain_name,
      is_terminated: "0",
      termination_date: "",
      is_same_detail_as_defined_general: data?.isSameDetailAsAboveActive ? "1" : "0",
      billing_method_applicable_id: data?.billing_method?.value,

      billing_details:
        data?.billing_method?.value?.toString() === "O"
          ? {
              company_name: capitalizeEachWord(
                data?.isSameDetailAsAboveActive ? data?.company : data?.bInfo_company
              ),
              care_of: capitalizeEachWord(
                data?.isSameDetailAsAboveActive
                  ? data?.company_co
                  : data?.bInfo_company_co
              ),
              public_id1: data?.isSameDetailAsAboveActive
                ? data?.vat_number
                : data?.bInfo_vat_number,
              public_id2: data?.isSameDetailAsAboveActive
                ? data?.gst_number
                : data?.bInfo_gst_number,
              public_id3: data?.isSameDetailAsAboveActive
                ? data?.cvr_number
                : data?.bInfo_cvr_number,
              public_type_id1: "1",
              public_type_id2: "1",
              public_type_id3: "1",
              street_name: data?.isSameDetailAsAboveActive
                ? data?.street_name
                : data?.bInfo_street_name,
              house_number: data?.isSameDetailAsAboveActive
                ? data?.house_number
                : data?.bInfo_house_number,
              floor_id: data?.isSameDetailAsAboveActive
                ? data?.floor_number?.value?.toString()
                : data?.bInfo_floor_number?.value,
              side_id: data?.isSameDetailAsAboveActive
                ? data?.side?.value?.toString()
                : data?.bInfo_side?.value,
              door: data?.isSameDetailAsAboveActive ? data?.door : data?.bInfo_door,
              zipcode_id: data?.isSameDetailAsAboveActive
                ? data?.zipCode?.value
                : data?.bInfo_zipCode?.value,
              country_id: data?.isSameDetailAsAboveActive
                ? data?.country?.value
                : data?.bInfo_country?.value,
              invoice_email: data?.invoice_email?.toLowerCase(),
              attention_person: capitalizeEachWord(data?.attention_person),
              ean_number: data?.ean_number,
            }
          : {},
    };

    if (!PutUpdateOrganisationIsLoading) {
      id = toast.loading(<LoadingAlert />);
    }
    try {
      const response = await PutUpdateOrganisation(UpdateOrganization);
      if (response.status === status.s && response.data.bReturn) {
        toast.update(id, {
          render: <SucessAlert />,
          isLoading: false,
          type: status.s,
          autoClose: 2000,
        });
        getOrganizationByOrganizationIdDataRefetch();
      } else {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.update(id, {
        render: <ErrorAlert />,
        isLoading: false,
        type: status.e,
        autoClose: 2000,
      });
    }
  };

  const handelChangeDomainName = (data) => {
    setValue("domain_name", data?.domain);
    setShowChangeDomain(false);
    handleInputChange();
  };
  //==========================Form Auto Save functionality===========================//
  const handleAutoSave = () => {
    handleSubmit(handleEditOrganization)();
  };
  const handleInputChange = (duration = 3000) => {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    const saveTimeout = showChangeDomain ? 10 : duration;
    const newTimeOutId = setTimeout(() => {
      handleAutoSave();
    }, saveTimeout);
    setTimeOutId(newTimeOutId);
  };

  return (
    <div>
      <div className="whiteContainerStyle">
        <div className="d-flex align-items-center pt-3">
          <H2 className="  ps-4 pe-2 ">Details</H2>
          <div>
            <CustomToolTip size="sm" text="Organisation details" />
          </div>
        </div>
        <div></div>

        <hr className="hr" />
        <Form onSubmit={handleSubmit(handleEditOrganization)}>
          {/* Organisation name */}
          <Row className=" px-4 d-flex">
            <H4 className="pb-3">Organisation name</H4>
            <Col
              xl={3}
              lg={4}
              md={6}
              className="pe-sm-3 mb-lg-3 mb-sm-3 mt-lg-2"
            >
              <Controller
                control={control}
                {...register("organisation_name")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <InputField
                    id={"organisation_name"}
                    value={value}
                    type="text"
                    placeholder="Name"
                    textTransform="capitalize"
                    errorscope
                    iserror={errors?.organisation_name}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("organisation_name");
                      handleInputChange();
                    }}
                  />
                )}
              />
            </Col>
            <Col xl={3} lg={4} md={6} className="pe-3 ps-lg-0 mt-lg-2 mb-sm-3 ">
              <Controller
                control={control}
                {...register("short_name")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <InputField
                    id={"short_name"}
                    value={value}
                    type="text"
                    placeholder="Short name (3 letters max)"
                    textTransform="uppercase"
                    errorscope
                    iserror={errors?.short_name}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("short_name");
                      handleInputChange();
                    }}
                    maxLength={3}
                  />
                )}
              />
            </Col>
            <Col className="d-flex created-by gap-3 justify-content-end ">
              <div className="d-flex flex-column text-end">
                <div>Organisation created : </div>
                <div>Owner :</div>
              </div>

              <div>
                <div>
                  {getOrganizationByOrganizationIdData?.data?.[0]?.created_on ==
                  ""
                    ? "-"
                    : moment(
                        getOrganizationByOrganizationIdData?.data?.[0]
                          ?.created_on
                      ).format("DD MMM, YYYY")}
                </div>
                <div>
                  {getOrganizationByOrganizationIdData?.data?.[0]?.owner_name}
                </div>
              </div>
            </Col>
          </Row>

          <Row className="  pb-4 px-4">
            <Col
              xl={3}
              lg={4}
              md={6}
              className="pe-sm-3 pe-lg-3 mb-lg-0 mb-sm-3"
            >
              <Controller
                control={control}
                {...register("organisation_owner")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder={"Organisation owner"}
                    iserror={errors?.organisation_owner}
                    options={GetOwnersData?.data?.map((item) => ({
                      value: item?.Id,
                      label: item?.Text,
                      isDisabled: value?.value == item?.Id,
                    }))}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("organisation_owner");
                      handleInputChange();
                    }}
                    clearable={false}
                  />
                )}
              />
            </Col>
          </Row>
          <hr className="mb-4 mt-0" />

          {/* Organisation details */}
          <H4 className=" mb-lg-3 ps-4">Organisation details</H4>

          <div className="px-4">
            <Row>
              <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3 ">
                <Controller
                  control={control}
                  {...register("company")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      id={"company"}
                      value={value}
                      type="text"
                      placeholder="Company"
                      textTransform="capitalize"
                      errorscope
                      iserror={errors?.company}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("company");
                        handleInputChange();
                      }}
                    />
                  )}
                />
              </Col>

              <Col xl={3} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                <Controller
                  control={control}
                  {...register("company_co")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      id={"C/O"}
                      value={value}
                      type="text"
                      placeholder="C/O"
                      textTransform="capitalize"
                      errorscope
                      maxLength={30}
                      iserror={errors?.company_co}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("company_co");
                        handleInputChange();
                      }}
                    />
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
                <Controller
                  control={control}
                  {...register("cvr_number")}
                  rules={{
                    required: !(
                      watch("vat_number")?.length || watch("gst_number")?.length
                    ),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      id={"cvr_number"}
                      value={value}
                      type="text"
                      placeholder="CVR Number"
                      errorscope
                      maxLength={30}
                      iserror={errors?.cvr_number}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("cvr_number");
                        handleInputChange();
                      }}
                    />
                  )}
                />
              </Col>
              <Col xl={3} lg={4} md={6} className="pe-3 ps-lg-0 mb-sm-3">
                <Controller
                  control={control}
                  {...register("vat_number")}
                  rules={{
                    required: !(
                      watch("cvr_number")?.length || watch("gst_number")?.length
                    ),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      id={"vat_number"}
                      value={value}
                      type="text"
                      placeholder="VAT Number"
                      errorscope
                      maxLength={30}
                      iserror={errors?.vat_number}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("vat_number");
                        handleInputChange();
                      }}
                    />
                  )}
                />
              </Col>
              <Col xl={3} lg={4} md={6} className="pe-lg-3 ps-lg-0  mb-sm-3">
                <Controller
                  control={control}
                  {...register("gst_number")}
                  rules={{
                    required: !(
                      watch("vat_number")?.length || watch("cvr_number")?.length
                    ),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      id={"gst_number"}
                      value={value}
                      type="text"
                      placeholder="GST Number"
                      errorscope
                      maxLength={30}
                      iserror={errors?.gst_number}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("gst_number");
                        handleInputChange();
                      }}
                    />
                  )}
                />
              </Col>
            </Row>
          </div>
          <div className="px-4">
            <Row>
              <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-3 mb-sm-3">
                <Controller
                  control={control}
                  {...register("country")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder="Country"
                      clearable={false}
                      options={countryData?.data?.map((item) => ({
                        value: item?.Id,
                        label: (
                          <div
                            className="d-flex align-items-center"
                            style={{ fontFamily: "Cerebri sans" }}
                          >
                            <div className="radius-4 me-2">
                              <img
                                src={item?.DataValue1}
                                style={{
                                  width: "20px",
                                  marginRight: "10px",
                                }}
                                alt=""
                              />
                            </div>
                            <div>
                              <div className="fs-14 light-font-text ">
                                {" "}
                                {item?.Text}
                              </div>
                            </div>
                          </div>
                        ),
                      }))}
                      value={value == undefined ? "" : displayItem(value)}
                      onChange={(e, ActionType) => {
                        onChange(e);
                        resetField("city");
                        resetField("zipCode");
                        resetField("street_name");
                        clearErrors("country");
                        handleInputChange();
                      }}
                    />
                  )}
                />

                {errors?.country && <p className="error-msg">{REQUIRED_ERR}</p>}
              </Col>
            </Row>
          </div>
          <div className="px-4">
            <Row>
              <Col
                xl={3}
                lg={4}
                md={6}
                className="pe-sm-3 mb-lg-0 mb-sm-3  position-relative"
              >
                <StreetInput
                  isSearchStreetVisible={isSearchStreetVisible}
                  setIsSearchStreetVisible={setIsSearchStreetVisible}
                  handleStreetFieldChange={handleStreetFieldChange}
                  handleStreetDetailClick={handleStreetDetailClick}
                  control={control}
                  street_name={"street_name"}
                  errors={errors}
                  watch={watch}
                  searchedStreetName={searchedStreetName}
                  clearErrors={clearErrors}
                  country_name={"country"}
                />
                {/* <StreetInput
                  isSearchStreetVisible={isSearchStreetVisible}
                  setIsSearchStreetVisible={setIsSearchStreetVisible}
                  handleStreetFieldChange={handleStreetFieldChange}
                  handleStreetDetailClick={handleStreetDetailClick}
                  control={control}
                  // removeOpacity={
                  //   !isSameDetailAsAboveActive && flag !== "Property"
                  // }
                  street_name={"street_name"}
                  errors={errors}
                  watch={watch}
                  searchedStreetName={searchedStreetName}
                  clearErrors={clearErrors}
                  country_name={"country"}
                /> */}
              </Col>
              <Col xl={2} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                <Controller
                  control={control}
                  {...register("house_number")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      id={"house_number"}
                      value={value}
                      type="text"
                      placeholder="No."
                      errorscope
                      maxLength={30}
                      iserror={errors?.house_number}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("house_number");
                        handleInputChange();
                      }}
                    />
                  )}
                />
              </Col>
              <Col xl={2} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                <Controller
                  control={control}
                  {...register("floor_number")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Floor"}
                      iserror={errors?.floor_number}
                      options={getOrganizationFloorTypeData?.data?.map(
                        (item) => ({
                          value: item?.Id,
                          label: item?.Text,
                        })
                      )}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("floor_number");
                        handleInputChange();
                      }}
                      clearable={false}
                    />
                  )}
                />
              </Col>
              <Col
                xl={2}
                lg={4}
                md={6}
                className="pe-sm-3 ps-xl-0 mb-lg-0 mb-sm-3 "
              >
                <Controller
                  control={control}
                  {...register("side")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Side"}
                      iserror={errors?.side}
                      options={getOrganizationSideTypeData?.data?.map(
                        (item) => ({
                          value: item?.Id,
                          label: item?.Text,
                        })
                      )}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("side");
                        handleInputChange();
                      }}
                      clearable={false}
                    />
                  )}
                />
              </Col>
              <Col xl={2} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3  ">
                <Controller
                  control={control}
                  {...register("door")}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      id={"door"}
                      value={value}
                      type="text"
                      placeholder="Door"
                      errorscope
                      maxLength={30}
                      iserror={errors?.door}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("door");
                        handleInputChange();
                      }}
                    />
                  )}
                />
              </Col>
            </Row>
          </div>
          <div className="px-4 ">
            <Row>
              <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
                <Controller
                  control={control}
                  {...register("zipCode")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder="Zip code"
                      options={zipCodeByCountryIdData?.data?.map((zip) => ({
                        value: zip?.Id,
                        label: `${zip?.Id} ${zip?.Text}`,
                      }))}
                      clearable={false}
                      value={
                        value?.value
                          ? {
                              value: value?.value,
                              label: value?.value,
                            }
                          : ""
                      }
                      onChange={(e, ActionType) => {
                        handleZipCode(e, ActionType);
                        onChange(e);
                        clearErrors("zipcode");
                        handleInputChange();
                      }}
                    />
                  )}
                />
                {errors?.zipCode && <p className="error-msg">{REQUIRED_ERR}</p>}
              </Col>
              <Col xl={3} lg={4} md={6} className="pe-lg-3 ps-lg-0  mb-sm-3">
                <Controller
                  control={control}
                  {...register("city")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      id={"city"}
                      value={value}
                      type="text"
                      placeholder="City"
                      errorscope
                      maxLength={30}
                      iserror={errors?.city}
                      disabled
                      removeOpacity
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("city");
                        handleInputChange();
                      }}
                    />
                  )}
                />
              </Col>
            </Row>{" "}
          </div>

          <div className="px-4">
            <Row>
              <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
                <Controller
                  control={control}
                  {...register("contact_person")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      id={"contact_person"}
                      value={value}
                      type="text"
                      placeholder="Contact person"
                      textTransform="capitalize"
                      errorscope
                      maxLength={30}
                      iserror={errors?.contact_person}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("contact_person");
                        handleInputChange();
                      }}
                    />
                  )}
                />
              </Col>
              <Col xl={3} lg={4} md={6} className="pe-lg-3 ps-lg-0  mb-sm-3">
                <Controller
                  control={control}
                  {...register("contact_email")}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Please use a valid email address",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      id={"contact_email"}
                      value={value}
                      type="text"
                      placeholder="Contact e-mail"
                      textTransform="lowercase"
                      errorscope
                      errormessage={
                        errors?.email?.type === "pattern"
                          ? errors?.email.message
                          : false
                      }
                      maxLength={30}
                      iserror={errors?.contact_email}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("contact_email");
                        handleInputChange();
                      }}
                    />
                  )}
                />
              </Col>
              <Col xl={3} lg={4} md={6} className="pe-lg-3 ps-lg-0  mb-sm-3">
                <SelectPhoneNumber
                  errors={errors}
                  setValue={setValue}
                  getPhoneId={"country_code"}
                  getPhoneNumber={"phone"}
                  control={control}
                  placeholder={"Phone"}
                  onChangeFun={handleInputChange}
                />
              </Col>
              {/* <Col xl={3} lg={4} md={6} className="pe-lg-3 ps-lg-0  mb-sm-3">
                <SelectPhoneNumber
                  errors={errors}
                  // getValues={getValues}
                  setValue={setValue}
                  getPhoneId={"country_code"}
                  getPhoneNumber={"phone"}
                  control={control}
                  placeholder={"Phone"}
                  onChangeFun={handleInputChange}

                />
              </Col> */}
            </Row>
          </div>
          <div className="px-4 border-bottom pb-4">
            <Row>
              <Col xl={4} lg={4} md={6} className=" pe-sm-3 mb-lg-0 mb-sm-3">
                <Controller
                  control={control}
                  {...register("company_website")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      id={"company_website"}
                      value={value}
                      type="text"
                      placeholder="Website"
                      errorscope
                      maxLength={30}
                      iserror={errors?.company_website}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("company_website");
                        handleInputChange();
                      }}
                    />
                  )}
                />
              </Col>
            </Row>
          </div>

          {/*--------- Billing details ------------*/}
          <div className="px-4 py-4">
            <H4 className="mb-3">Billing details</H4>

            <Row className=" ">
              <Col xl={3} lg={4} md={6} className="pe-lg-0">
                <Controller
                  control={control}
                  {...register("billing_method")}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Billing method"}
                      iserror={errors?.billing_method}
                      clearable={false}
                      options={billingDetailsDropdownData
                        ?.filter((value) =>
                          GetBillingLevelDetailsData?.data?.[0]?.billing_level
                            ?.split(",")
                            ?.includes(value?.Id)
                        )
                        ?.map((item) => ({
                          value: item?.Id,
                          label: item?.Text,
                          isDisabled:
                            value?.value?.toString() === item?.Id?.toString(),
                        }))}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("billing_method");
                        handleInputChange();
                      }}
                    />
                  )}
                />
              </Col>
            </Row>
          </div>

          {/* Billing information */}
          <div className="px-4 pb-4">
            <H4 className="mb-3">Billing information</H4>
            <div className="pt-2 d-flex align-items-center gap-3">
              <Toggle
                id={"sameDetailAsAbove"}
                label={"Same details as above"}
                checked={watch("isSameDetailAsAboveActive")}
                onChange={(e) => handleSetSameDetailAsAbove(e)}
              />
              <span className="paragraph1-regular ">Same details as above</span>
            </div>
          </div>
          {/* Billing information fields*/}
          <div className="border-bottom pb-4">
            <div className="px-4">
              <Row>
                <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
                  <Controller
                    control={control}
                    {...register("bInfo_company")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"bInfo_company"}
                        value={value}
                        type="text"
                        placeholder="Company"
                        textTransform="capitalize"
                        errorscope
                        iserror={errors?.bInfo_company}
                        disabled={watch("isSameDetailAsAboveActive")}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("bInfo_company");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                  <Controller
                    control={control}
                    {...register("bInfo_company_co")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"bInfo_company_co"}
                        value={value}
                        type="text"
                        placeholder="C/O"
                        textTransform="capitalize"
                        errorscope
                        maxLength={30}
                        iserror={errors?.bInfo_company_co}
                        disabled={watch("isSameDetailAsAboveActive")}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("bInfo_company_co");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3 ">
                  <Controller
                    control={control}
                    {...register("ean_number")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"ean_number"}
                        value={value}
                        type="number"
                        placeholder="EAN number"
                        errorscope
                        maxLength={30}
                        iserror={errors?.ean_number}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("ean_number");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
                  <Controller
                    control={control}
                    {...register("bInfo_cvr_number")}
                    rules={{
                      required: !(
                        watch("bInfo_vat_number")?.length ||
                        watch("bInfo_gst_number")?.length
                      ),
                    }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"bInfo_cvr_number"}
                        value={value}
                        type="text"
                        placeholder="CVR Number"
                        errorscope
                        maxLength={30}
                        iserror={errors?.bInfo_cvr_number}
                        disabled={watch("isSameDetailAsAboveActive")}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("bInfo_cvr_number");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                  <Controller
                    control={control}
                    {...register("bInfo_vat_number")}
                    rules={{
                      required: !(
                        watch("bInfo_cvr_number")?.length ||
                        watch("bInfo_gst_number")?.length
                      ),
                    }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"bInfo_vat_number"}
                        value={value}
                        type="text"
                        placeholder="VAT Number"
                        errorscope
                        maxLength={30}
                        iserror={errors?.bInfo_vat_number}
                        disabled={watch("isSameDetailAsAboveActive")}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("bInfo_vat_number");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                  <Controller
                    control={control}
                    {...register("bInfo_gst_number")}
                    rules={{
                      required: !(
                        watch("bInfo_cvr_number")?.length ||
                        watch("bInfo_vat_number")?.length
                      ),
                    }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"bInfo_gst_number"}
                        value={value}
                        type="text"
                        placeholder="GST Number"
                        errorscope
                        maxLength={30}
                        iserror={errors?.bInfo_gst_number}
                        disabled={watch("isSameDetailAsAboveActive")}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("bInfo_gst_number");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
            </div>
            <div className="px-4  ">
              <Row>
                <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-3 mb-sm-3">
                  <Controller
                    control={control}
                    {...register("bInfo_country")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        disabled={watch("isSameDetailAsAboveActive")}
                        placeholder="Country"
                        clearable={false}
                        options={countryData?.data?.map((item) => ({
                          value: item?.Id,
                          label: (
                            <div
                              className="d-flex align-items-center"
                              style={{ fontFamily: "Cerebri sans" }}
                            >
                              <div className="radius-4 me-2">
                                <img
                                  src={item?.DataValue1}
                                  style={{
                                    width: "20px",
                                    marginRight: "10px",
                                  }}
                                  alt=""
                                />
                              </div>
                              <div>
                                <div className="fs-14 light-font-text ">
                                  {" "}
                                  {item?.Text}
                                </div>
                              </div>
                            </div>
                          ),
                        }))}
                        value={value == undefined ? "" : displayItem(value)}
                        onChange={(e, ActionType) => {
                          onChange(e);
                          resetField("bInfo_zipCode");
                          resetField("bInfo_city");
                          resetField("bInfo_street_name");
                          clearErrors("bInfo_country");
                          handleInputChange();
                        }}
                      />
                    )}
                  />

                  {errors?.country && (
                    <p className="error-msg">{REQUIRED_ERR}</p>
                  )}
                </Col>
              </Row>
            </div>
            <div className="px-4  ">
              <Row>
                <Col
                  xl={3}
                  lg={4}
                  md={6}
                  className="pe-sm-3 mb-lg-0 mb-sm-3  position-relative"
                >
                  <StreetInput
                    isSearchStreetVisible={isSearchInfoStreetVisible}
                    setIsSearchStreetVisible={setIsSearchInfoStreetVisible}
                    handleStreetFieldChange={handleInfoStreetFieldChange}
                    handleStreetDetailClick={handleInfoStreetDetailClick}
                    control={control}
                    disabledValue={watch("isSameDetailAsAboveActive")}
                    // removeOpacity={
                    //   !isSameDetailAsAboveActive && flag !== "Property"
                    // }
                    street_name={"bInfo_street_name"}
                    errors={errors}
                    watch={watch}
                    searchedStreetName={searchedStreetName}
                    clearErrors={clearErrors}
                    country_name={"bInfo_country"}
                  />

                  {/* <Controller
                    control={control}
                    {...register("bInfo_street_name")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        disabled={watch("isSameDetailAsAboveActive")}
                        id={"bInfo_street_name"}
                        value={value}
                        type="text"
                        placeholder="Street"
                        errorscope
                        maxLength={30}
                        iserror={errors?.bInfo_street_name}
                        onChange={(e) => {
                          onChange(e);
                          handleInfoStreetFieldChange(e);
                          clearErrors("bInfo_street_name");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                  {watch("bInfo_street_name")?.length < 4 &&
                  watch("bInfo_street_name")?.length > 0 &&
                  watch("bInfo_country")?.value == "DK" ? (
                    <div className="fs-12 fw-400 grey-text pt-1 ">
                      Type in minimum 4 characters to get a search result
                    </div>
                  ) : (
                    <>
                      {isSearchInfoStreetVisible &&
                        watch("bInfo_country")?.value == "DK" && (
                          <div style={{}}>
                            <div
                              className="bg-white radius-8 pe-1 filtered-list"
                              style={{
                                border: "1px solid #D1D1D1",
                                position: "absolute",
                                zIndex: "10",
                                width: "calc(100% - 12px)",
                              }}
                            >
                              <>
                                {getStreetDetailsData?.data?.length > 0 ? (
                                  <div>
                                    {getStreetDetailsData?.data?.map(
                                      (item, index) => (
                                        <div
                                          className="mt-1 px-2 light-font-text"
                                          key={index}
                                          onClick={() => {
                                            handleInfoStreetDetailClick(item);
                                            setIsSearchInfoStreetVisible(false);
                                          }}
                                        >
                                          {item?.city ||
                                          item?.zipcode ||
                                          item?.house_number ? (
                                            <> {item?.address} </>
                                          ) : (
                                            <> {item?.street_name}</>
                                          )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <div className="mt-1 px-2 light-font-text">
                                    No data found
                                  </div>
                                )}
                              </>
                            </div>
                          </div>
                        )}
                    </>
                  )} */}
                </Col>
                <Col xl={2} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                  <Controller
                    control={control}
                    {...register("bInfo_house_number")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"bInfo_house_number"}
                        value={value}
                        type="text"
                        placeholder="No."
                        errorscope
                        maxLength={30}
                        iserror={errors?.bInfo_house_number}
                        disabled={watch("isSameDetailAsAboveActive")}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("bInfo_house_number");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xl={2} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                  <Controller
                    control={control}
                    {...register("bInfo_floor_number")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Floor"}
                        iserror={errors?.bInfo_floor_number}
                        options={getOrganizationFloorTypeData?.data?.map(
                          (item) => ({
                            value: item?.Id,
                            label: item?.Text,
                          })
                        )}
                        disabled={watch("isSameDetailAsAboveActive")}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("bInfo_floor_number");
                          handleInputChange();
                        }}
                        clearable={false}
                      />
                    )}
                  />
                </Col>
                <Col
                  xl={2}
                  lg={4}
                  md={6}
                  className="pe-sm-3 ps-xl-0 mb-lg-0 mb-sm-3 "
                >
                  <Controller
                    control={control}
                    {...register("bInfo_side")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Side"}
                        iserror={errors?.bInfo_side}
                        disabled={watch("isSameDetailAsAboveActive")}
                        options={getOrganizationSideTypeData?.data?.map(
                          (item) => ({
                            value: item?.Id,
                            label: item?.Text,
                          })
                        )}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("bInfo_side");
                          handleInputChange();
                        }}
                        clearable={false}
                      />
                    )}
                  />
                </Col>
                <Col xl={2} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3 ">
                  <Controller
                    control={control}
                    {...register("bInfo_door")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"bInfo_door"}
                        value={value}
                        type="text"
                        placeholder="Door"
                        errorscope
                        maxLength={30}
                        iserror={errors?.bInfo_door}
                        disabled={watch("isSameDetailAsAboveActive")}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("bInfo_door");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
            </div>
            <div className="px-4 ">
              <Row>
                <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
                  <Controller
                    control={control}
                    {...register("bInfo_zipCode")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        disabled={watch("isSameDetailAsAboveActive")}
                        clearable={false}
                        placeholder="Zip code"
                        options={zipCodeByCountryIdData2?.data?.map((zip) => ({
                          value: zip?.Id,
                          label: `${zip?.Id} ${zip?.Text}`,
                        }))}
                        value={
                          value?.value
                            ? {
                                value: value?.value,
                                label: value?.value,
                              }
                            : ""
                        }
                        onChange={(e, ActionType) => {
                          handleBillingZipCode(e, ActionType);
                          onChange(e);
                          clearErrors("bInfo_zipCode");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                  {errors?.zipCode && (
                    <p className="error-msg">{REQUIRED_ERR}</p>
                  )}
                </Col>
                <Col
                  xl={3}
                  lg={4}
                  md={6}
                  className="pe-lg-3 ps-lg-0  mb-sm-3  "
                >
                  <Controller
                    control={control}
                    {...register("bInfo_city")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"bInfo_city"}
                        value={value}
                        type="text"
                        placeholder="City"
                        errorscope
                        maxLength={30}
                        iserror={errors?.bInfo_city}
                        disabled
                        removeOpacity={!watch("isSameDetailAsAboveActive")}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("bInfo_city");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>{" "}
            </div>
            <div className="px-4 ">
              <Row>
                <Col xl={4} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3 ">
                  <Controller
                    control={control}
                    {...register("invoice_email")}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Please use a valid email address",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"invoice_email"}
                        value={value}
                        type="email"
                        placeholder="Invoice e-mail (required)"
                        errorscope
                        errormessage={
                          errors?.email?.type === "pattern"
                            ? errors?.email.message
                            : false
                        }
                        maxLength={30}
                        iserror={errors?.invoice_email}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("invoice_email");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xl={4} lg={4} md={6} className="pe-lg-3 ps-lg-0  mb-sm-3">
                  <Controller
                    control={control}
                    {...register("attention_person")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"attention_person"}
                        value={value}
                        type="text"
                        placeholder="Attention person (required)"
                        errorscope
                        textTransform="capitalize"
                        maxLength={30}
                        iserror={errors?.attention_person}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("attention_person");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
            </div>
          </div>

          {/* Domain (Login page) */}
          <div className="px-4 py-4">
            <H4 className="mb-3">Domain (Login page)</H4>
            <div className="light-font-text">
              Define what the domain for the organisation is. The domain is used
              to gain access to the system. E.g. myorganisation.estatetool.ai
            </div>
          </div>

          <Row className="pb-4 px-4">
            <Col xl={4} lg={5} className="pe-sm-3 mb-lg-0 mb-sm-3 pe-lg-3">
              <Controller
                control={control}
                {...register("domain_name")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <InputField
                    id={"domain_name"}
                    value={value}
                    type="text"
                    placeholder="Domain"
                    errorscope
                    maxLength={30}
                    iserror={errors?.domain_name}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("domain_name");
                      handleInputChange();
                    }}
                  />
                )}
              />
            </Col>

            <Col lg={3} className="ps-lg-2 ">
              <ETButton
                variant="primary"
                buttonText="CHANGE DOMAIN"
                onClick={() => setShowChangeDomain(true)}
              />
            </Col>
          </Row>

          <div className="py-2 px-3 d-sm-flex justify-content-between footer-section-button-container">
            <div className="m-1"></div>

            <div className="m-1">
              {/* <ETButton
                disabled={PutUpdateOrganisationIsLoading}
                variant={"primary"}
                buttonText={"SAVE"}
                type={"submit"}
              /> */}
            </div>
          </div>
        </Form>
      </div>
      <ChangeDomainPopup
        open={showChangeDomain}
        onHide={() => setShowChangeDomain(false)}
        onCancel={() => setShowChangeDomain(false)}
        onStart={() => setShowChangeDomain(false)}
        domainName={watch("domain_name")}
        handelChangeDomainName={handelChangeDomainName}
      />
    </div>
  );
};

export default OrganizationDetail;

// ===================== old code of public details (useField Array)============================//

// const {
//   control,
//   register,
//   handleSubmit,
//   setValue,
//   getValues,
//   watch,
//   clearErrors,
//   reset,
//   formState: { errors },
// } = useForm({
//   defaultValues: {
//     addPublicType: getOrganizationById?.data?.[0]?.public_details
//       ?.filter((item, i) => item[`public_id${i + 1}_type`] !== "0")
//       ?.map((item, i) => {
//         if (item[`public_id${i + 1}_type`]) {
//           return {
//             publicType: {
//               value: item[`public_id${i + 1}_type`],
//               label: getGetOrganizationByPublicType?.data?.filter(
//                 (val) => val.Id == item[`public_id${i + 1}_type`]
//               )?.[0]?.Text,
//             },
//             publicId: item[`public_id${i + 1}`],
//           };
//         }
//       }),
//   },
// });

// useEffect(() => {
//   const updatedAddPublicType = getOrganizationById?.data?.[0]?.public_details
//     .filter((item, i) => item[`public_id${i + 1}_type`] !== "0")
//     .map((item, i) => {
//       return {
//         publicType: {
//           value: item[`public_id${i + 1}_type`],
//           label: getGetOrganizationByPublicType?.data?.find(
//             (val) => val.Id == item[`public_id${i + 1}_type`]
//           )?.Text,
//         },
//         publicId: item[`public_id${i + 1}`],
//       };
//     });
//   // Update the default values in the form
//   reset({
//     addPublicType: updatedAddPublicType,
//   });

//   if (Object.keys(getOrganizationById ?? {}).length) {
//     setValue("organizationName", getOrganizationById?.data?.[0]?.name);
//     setValue("street", getOrganizationById?.data?.[0]?.street_name);
//     setValue("houseNo", getOrganizationById?.data?.[0]?.house_number);
//     setValue("addInfo", getOrganizationById?.data?.[0]?.address_add_info);
//     setValue("country", {
//       value: getOrganizationById?.data?.[0]?.country_id,
//       label: countryData?.data?.filter(
//         (item) => item?.Id == getOrganizationById?.data?.[0]?.country_id
//       )?.[0]?.Text,
//     });
//     setValue("zipCode", {
//       value: getOrganizationById?.data?.[0]?.zipcode_id,
//       label: getOrganizationById?.data?.[0]?.zipcode_id,
//     });
//     setValue("city", getOrganizationById?.data?.[0]?.city);
//     setValue("description", getOrganizationById?.data?.[0]?.description);
//     setValue("domainName", getOrganizationById?.data?.[0]?.domain_name);
//   }
// }, [getOrganizationById, getGetOrganizationByPublicType, countryData]);

// const { fields, append, remove } = useFieldArray({
//   control,
//   name: "addPublicType",
// });

// const optionPublicId = getGetOrganizationByPublicType?.data?.map((item) => ({
//   value: item?.Id,
//   label: item?.Text,
//   isDisabled: watch("addPublicType")?.some(
//     (val) => item?.Id == val?.publicType?.value
//   ),
// }));

// const handleAddNewPublicDetails = () => {
//   if (getGetOrganizationByPublicType?.data?.length !== fields?.length) {
//     append({
//       publicType: "",
//       publicId: "",
//     });
//   }
// };

{
  /* <hr className="hr mt-0" />
          <Row className="py-1 ps-4">
            <Col>
              <div className="form-label-title">
                Public details(CVR & Taxation details)
              </div>
            </Col>
          </Row>
          {fields?.map((item, index) => (
            <Row key={item?.id} className="pt-3 pb-2 px-4">
              <Col xl={4} lg={5} className="my-2 my-lg-0 pe-0">
                <Controller
                  control={control}
                  {...register(`addPublicType.${index}.publicType`)}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <ReactSelect
                        placeholder="Public Type"
                        options={optionPublicId}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors(`addPublicType.${index}.publicType`);
                          setValue(`addPublicType.[${index}].publicId`, "");
                        }}
                      />
                    );
                  }}
                />
                {errors?.addPublicType?.[index]?.publicType &&
                  errors?.addPublicType?.[index]?.publicType?.type ===
                    "required" && <p className="error-msg ">{REQUIRED_ERR}</p>}
              </Col>
              <Col xl={4} lg={5} className="my-2 my-lg-0 pe-0">
                <Controller
                  control={control}
                  {...register(`addPublicType.[${index}].publicId`)}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <div className="form-input ">
                      <input
                        type="text"
                        placeholder="Public Id"
                        id={`addPublicType.[${index}].publicId`}
                        value={value || ""}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const regularExpression = inputValue.replace(
                            /[^\w\s]/gi,
                            ""
                          );
                          onChange(regularExpression);
                          clearErrors(`addPublicType.[${index}].publicId`);
                        }}
                      />

                      <label htmlFor={`addPublicType.[${index}].publicId`}>
                        Public Id {index + 1}
                      </label>
                    </div>
                  )}
                />
                {errors?.addPublicType?.[index]?.publicId &&
                  errors?.addPublicType?.[index]?.publicId?.type ===
                    "required" && <p className="error-msg ">{REQUIRED_ERR}</p>}
              </Col>
              <Col
                xl={1}
                lg={1}
                className="my-3 my-lg-0 px-0 d-flex align-items-center  justify-content-center"
              >
                <button
                  type="button"
                  className="icon-btn"
                  style={{
                    width: "30px",
                    height: "30px",
                    opacity: fields?.length === 1 ? "0.5" : "",
                  }}
                  onClick={() => {
                    if (fields.length !== 1) {
                      remove(index);
                    }
                  }}
                >
                  <FontAwesomeIcon icon="fa-regular fa-trash" />
                </button>
              </Col>
            </Row>
          ))}
          <Row className="mt-3 px-4">
            <Col className="pe-0" lg={8} md={12} sm={12}>
              <div
                className="add-config-btn"
                onClick={handleAddNewPublicDetails}
                style={{
                  opacity:
                    getGetOrganizationByPublicType?.data?.length ===
                    fields?.length
                      ? "0.5"
                      : "",
                }}
              >
                <FontAwesomeIcon
                  size="xs"
                  icon="fa-solid fa-plus"
                  style={{ color: "#F1664B" }}
                />{" "}
                <span className="form-label-title fs-12 ms-2">
                  {" "}
                  ADD PUBLIC DETAILS
                </span>
              </div>
            </Col>
          </Row> */
}
