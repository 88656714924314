import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

// unit Id
export const useUnitId = create(
	persist(
		(set, get) => ({
			UnitId: "",
			setUnitId: (data) => set({ UnitId: data }),
		}),
		{
			name: "unitId", // name of the item in the storage (must be unique)
			storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
		}
	)
);

// unit overview
export const useUnitOverview = create((set, get) => ({
	unitOverview: [],
	setUnitOverview: (data) => set({ unitOverview: data }),
}));

// units type
export const useUnitType = create((set, get) => ({
	typeOfUnit: 1,
	setTypeOfUnit: (data) => set({ typeOfUnit: data }),
}));

// units type
export const useUnitIdToEdit = create((set, get) => ({
	unitToEdit: "",
	setUnitToEdit: (data) => set({ unitToEdit: data }),
}));


export const useUnTabType = create((set, get) => ({
	unitTabType: false,
	setUnitTabType: (data) => set({ unitTabType: data }),
}));

