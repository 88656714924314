// ui created by Suraj
import React, { useEffect, useState } from "react";
import ETButton from "../../../../Components/Buttons/ETButton";
import { useNavigate } from "react-router-dom";
import "../screening.css";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import Accordion from "../../../../Components/Accordion/Accordion";
import Toggle from "../../../../Components/Switch/Switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Row } from "react-bootstrap";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { useForm, Controller } from "react-hook-form";
import { useGetLanguages } from "../../../../hooks/queries/uselanguageQueries";
// import { AccordionContent } from "../screeningFormsComponent/AccodrionContent";
import { AccordionContent } from "../screeningFormsComponent/AccordionContent1";
import { useLanguage } from "../../../../hooks/stores/useLanguageStore";
import { H2, H3, H5 } from "../../../../styles/Common.style";
import CustomPopup from "../../../../Components/CustomPopup";
import {
  useAddScreeningForm,
  useGetScreeningCategoryByformId,
  useGetScreeningFields,
  useGetScreeningFormBasicDetails,
} from "../../../../hooks/queries/useScreeningQueries";
import { useScreening } from "../../../../hooks/stores/useScreeningStore";
import { usePropertyId } from "../../../../hooks/stores/usePropertyStore";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";
import ETAccordion from "../../../../Components/Accordion/ETAccordion";

const ScreeningFormBuild = ({
  setStep1,
  storeScreeningPayLod,
  handleAddScreening,
  setValue,
  getValues,
  errors,
  register,
  handleSubmit,
  control,
  useForm,
  Controller,
  setBuildData,
  setYellowFlagMessage,
  setRedFlagMessage,
  categoryPayload,
  setCategoryPayload,
  formStatusId,
  screeningSectionData,
  setScreeningSectionData,
  setDraft,
  categoryData,
  setCategoryData,
  fields,
  append,
  remove,
  setError,
  clearErrors,
  trigger,
}) => {
  const {
    selectedLanguageId,
    setSelectedLanguageId,
    selectedLanguageText,
    setSelectedLanguageText,
    languageId,
    setLanguageId,
    setFormStatusId,
    setCategoryJsonData,
    setSaveScreeningForm,
    parentFormId,
  } = useScreening((state) => state);

  const [screeningCategoryId, setScreeningCategoryId] = useState("");
  const [activeToggles, setActiveToggles] = useState({});

  const { propertyId } = usePropertyId((state) => state);
  const { data: GetScreeningFormBasicDetailsData } =
    useGetScreeningFormBasicDetails(parentFormId);

  const [activeAccordion, setActiveAccordion] = useState(null); //this is used to show only one accordion at a time
  const { data: GetScreeningFieldsData } =
    useGetScreeningFields(screeningCategoryId);

  // state
  // console.log("languageId", languageId);

  // useEffect(() => {
  // 	// let screeningCategory =
  // 	// 	GetScreeningFormBasicDetailsData?.data?.[0]?.category;
  // 	// console.log("selectedLanguageId", selectedLanguageId);
  // 	// categoryData?.forEach((categoryItem) => {
  // 	// 	// Filter out language_ids that are not in the selectedLanguage
  // 	// 	categoryItem.category_name = categoryItem?.category_name?.filter(
  // 	// 		(langObj) => {
  // 	// 			return selectedLanguageId?.includes(langObj?.language_id);
  // 	// 		}
  // 	// 	);
  // 	// 	// Check if the selectedLanguage is not present in category_name
  // 	// 	const languageIds = categoryItem?.category_name?.map(
  // 	// 		(langObj) => langObj?.language_id
  // 	// 	);
  // 	// 	selectedLanguageId?.forEach((lang) => {
  // 	// 		if (!languageIds?.includes(lang)) {
  // 	// 			// Append the objToAppend to category_name
  // 	// 			categoryItem?.category_name?.push({
  // 	// 				language_id: lang,
  // 	// 				name: "",
  // 	// 				description: "",
  // 	// 			});
  // 	// 		}
  // 	// 	});
  // 	// });

  // 	// console.log("screeningCategory", screeningCategory);

  // 	// setCategoryData(filteredCat);

  // 	// console.log("filteredCategoryName", filteredCategoryName);
  // 	// const transFormed = categoryData?.map((item) => {
  // 	// 	const id = item?.screening_category_id;
  // 	// 	const name = item?.category_name?.map((lang) => ({
  // 	// 		language_id: lang?.language_id,
  // 	// 		text: lang?.name,
  // 	// 	}));
  // 	// 	const description = item?.category_name?.map((lang) => ({
  // 	// 		language_id: lang?.language_id,
  // 	// 		text: lang?.description,
  // 	// 	}));
  // 	// 	const display_order_no = item?.display_order_no;

  // 	// 	return {
  // 	// 		id,
  // 	// 		name,
  // 	// 		description,
  // 	// 		display_order_no,
  // 	// 	};
  // 	// });
  // 	// setCategoryPayload(transFormed);
  // }, [GetScreeningFormBasicDetailsData, categoryData, selectedLanguageId]);

  const [isEditableId, setIsEditableId] = useState("");
  const [editCategoryTitle, setEditCategoryTitle] = useState(false);
  const [editCategoryName, setEditCategoryName] = useState("");
  const [showEditCategoryName, setShowEditCategoryName] = useState("");

  const [isActive, setIsActive] = useState({});
  const [selectedId, setSelectedId] = useState(null);

  // const [categoryHeadline, setCategoryHeadline] = useState("");
  // const [categoryDescription, setCategoryDescription] = useState(
  // 	selectedLanguageId.map((lang) => ({
  // 		language_id: lang,
  // 		text: "",
  // 	}))
  // )

  const [categoryInformation, setCategoryInformation] = useState({
    name: "",
    description: "",
  });
  //below code is used to show only one accordion at a time with setting active screening category id
  const handleAccordionToggle = (accordionIndex) => {
    setActiveAccordion(
      activeAccordion === accordionIndex ? null : accordionIndex
    );
    setScreeningCategoryId(
      activeAccordion === accordionIndex ? "" : accordionIndex
    );
  };
  // to get name / description
  const handleGetNameDescription = (selectedId, languageId) => {
    let deepCopiedCategoryData = [...categoryData];
    deepCopiedCategoryData
      ?.filter((cD, index) => cD?.screening_category_id == selectedId)?.[0]
      ?.category_name?.map((cName, cNIdex) => {
        if (cName?.language_id == languageId) {
          return {
            cName,
          };
        }
      });
  };
  // const name = categoryData
  // 	?.map((cat, index) =>
  // 		cat.category_name.map((cN) => ({
  // 			language_id: cN.language_id,
  // 			text: cN.name,
  // 		}))
  // 	)
  // 	.flat();

  // const description = categoryData
  // 	?.map((cat, index) =>
  // 		cat.category_name.map((cN) => ({
  // 			language_id: cN.language_id,
  // 			text: cN.description,
  // 		}))
  // 	)
  // 	.flat();

  // const transFormed = categoryData?.map((cat, index) => ({
  // 	id: cat?.screening_category_id,
  // 	name: name,
  // 	description: description,
  // 	display_order_no: cat?.display_order_no,
  // }));
  // console.log("transFormed", transFormed);

  // console.log(
  // 	"🚀 ~ file: ScreeningFormBuild.js:143 ~ transFormed ~ transFormed:",
  // 	transFormed
  // );

  // const [categoryPayload, setCategoryPayload] = useState(transFormed);
  // console.log(
  // 	"🚀 ~ file: ScreeningFormBuild.js:149 ~ categoryPayload:",
  // 	categoryPayload
  // );

  // hooks forms
  const { watch } = useForm({
    defaultValues: {
      categoryName: GetScreeningFormBasicDetailsData?.data[0]?.category
        ?.filter((cat, index) => cat?.screening_category_id == selectedId)?.[0]
        ?.category_name?.filter(
          (cN, cNIndex) => cN?.language_id == languageId
        )?.[0]?.name,

      categoryDescription: GetScreeningFormBasicDetailsData?.data[0]?.category
        ?.filter((cat, index) => cat?.screening_category_id == selectedId)?.[0]
        ?.category_name?.filter(
          (cN, cNIndex) => cN?.language_id == languageId
        )?.[0]?.description,
    },
  });

  // console.log(
  // 	"🚀 ~ file: ScreeningFormBuild.js:168 ~ useEffect ~ categoryPayload:",
  // 	categoryPayload
  // );

  useEffect(() => {
    setCategoryJsonData(categoryPayload);
  }, [categoryPayload]);

  // query selector
  const { data: getlanguages } = useGetLanguages();
  // const {
  // 	mutateAsync: addScreeningFormMuatate,
  // 	isLoading: isAddScreeningLoading,
  // } = useAddScreeningForm();

  // const handleSecondScreeningForm = async (data) => {
  // 	try {
  // 		const response = await addScreeningFormMuatate(storeScreeningPayLod);

  // 		if (response?.status.s === "success" && response?.data?.beRturn) {
  // 			toast?.success(response?.data?.message);
  // 		}

  // 	} catch (error) { }
  // };

  useEffect(() => {
    // to select primary language as current
    setLanguageId(selectedLanguageId[0]);
  }, [selectedLanguageId]);

  // navigation react-router-dom
  const navigate = useNavigate();
  const handleSaveScreeningForm = async (data) => {
    // debugger;
    await handleAddScreening(data);
    navigate("/property-units/settings/screening", {
      state: {
        Tab: "Settings",
      },
    });
    setSelectedLanguageId([]);
    setSelectedLanguageText([]);
  };
  const handleChildClick = (event, id) => {
    setEditCategoryTitle(true);
    setSelectedId(id);

    // setCategoryInformation((prev) => ({
    // 	...prev,
    // 	name: handleGetNameDescription(selectedId, languageId)?.name,
    // 	description: handleGetNameDescription(selectedId, languageId)
    // 		?.description,
    // }));

    event.stopPropagation(); // Prevent the event from propagating to the parent
  };
  const handleCancel = () => {
    navigate("/property-units/settings/screening", {
      state: {
        Tab: "Settings",
      },
    });
  };
  const handleSaveCategoryDetails = (categoryId) => {
    // setShowEditCategoryName(editCategoryName);
    const updatedCategoryData = categoryData.map((category) => {
      if (category?.screening_category_id === categoryId) {
        return {
          ...category,
          category_name: category?.category_name?.map((name) => {
            if ((name?.language_id).toString() === languageId?.toString()) {
              return {
                ...name,
                name: categoryInformation?.name, // Update the name as needed
                description: categoryInformation?.description,
              };
            }
            return name;
          }),
        };
      }
      return category;
    });
    setCategoryData(updatedCategoryData);
  };
  const handleEditCategoryName = (e) => {
    setEditCategoryName(e.target.value);
  };
  const handleCategoryHeadlineChange = (e) => {
    setCategoryInformation((prev) => ({ ...prev, name: e?.target?.value }));
  };
  const handleCategoryDescriptionChange = (e) => {
    // Update the categoryDescription state
    // debugger;
    // setCategoryDescription((categoryDescription) => {
    // 	const updatedDescription = [...categoryDescription];

    // 	const index = updatedDescription?.findIndex(
    // 		(item) => item?.language_id === languageId
    // 	);

    // 	if (index !== -1) {
    // 		// Update the text if the language_id exists
    // 		updatedDescription[index] = {
    // 			language_id: languageId,
    // 			text: newDescriptionText,
    // 		};
    // 	} else {
    // 		// debugger;
    // 		// If the language_id is not found, add a new object
    // 		updatedDescription.push({
    // 			language_id: languageId,
    // 			text: newDescriptionText,
    // 		});
    // 	}

    // 	return updatedDescription;
    // });

    // // Update the categoryPayload state
    // setCategoryPayload((categoryPayload) => {
    // 	const existingCategory = categoryPayload?.find(
    // 		(category) => category?.id === categoryId
    // 	);

    // 	if (existingCategory) {
    // 		// debugger;
    // 		// Category exists, update the description array
    // 		const newPayload = categoryPayload?.map((category) => {
    // 			if (category.id === categoryId) {
    // 				return {
    // 					...category,
    // 					description: selectedLanguageId?.map((lang) => ({
    // 						language_id: lang,
    // 						text:
    // 							lang === languageId
    // 								? newDescriptionText
    // 								: category?.description?.find(
    // 										(desc) => desc?.language_id === lang
    // 								  )?.text || "",
    // 					})),
    // 				};
    // 			}
    // 			return category;
    // 		});
    // 		// console.log("newPayload", newPayload);
    // 		return newPayload;
    // 	}
    // 	// } else {
    // 	// 	// debugger;
    // 	// 	// Category does not exist, create a new category object
    // 	// 	const newCategory = {
    // 	// 		id: categoryId,
    // 	// 		name: selectedLanguageId?.map((lang) => ({
    // 	// 			language_id: lang,
    // 	// 			text: lang === languageId ? newDescriptionText : "",
    // 	// 		})),
    // 	// 		description: selectedLanguageId?.map((lang) => ({
    // 	// 			language_id: lang,
    // 	// 			text: lang === languageId ? newDescriptionText : "",
    // 	// 		})),
    // 	// 		display_order_no: categoryPayload?.length + 1, // Set display order based on the length of existing categories
    // 	// 	};
    // 	// 	// console.log("newCategory", newCategory);
    // 	// 	return [...categoryPayload, newCategory];
    // 	// }
    // });
    setCategoryInformation((prev) => ({
      ...prev,
      description: e?.target?.value,
    }));

    // setCategoryInformation({ description: e?.target?.value });
  };

  // const displayItem = (selected) => {
  // 	const countyName = getlanguages?.data?.filter(
  // 		(item, i) => item.Id === selected?.value
  // 	)?.[0]?.Text;
  // 	return {
  // 		value: selected?.Id,
  // 		label: getlanguages?.data?.filter(
  // 			(item, i) => item.Id === selected?.value
  // 		)?.[0]?.Text,
  // 	};
  // };
  const filteredOptions = getlanguages?.data
    ?.filter((language) => selectedLanguageId?.includes(language?.Id))
    ?.map((language, index) => ({
      value: language?.Id,
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={language?.DataValue1}
            alt={`Flag for ${language?.Text}`}
            style={{ marginRight: "8px", height: "12px", width: "18px" }}
          />
          <div className="d-flex justify-content-between w-100">
            <div className="fw-300 fs-14">{language?.Text}</div>
            {index === 0 && (
              <div className="px-2 w-300 fs-14" style={{ color: "#888888" }}>
                Primary
              </div>
            )}
          </div>
        </div>
      ),
    }));
  const handleSaveAsDraft = () => {
    setFormStatusId(1);
    handleAddScreening(1);
  };
  // const categoryLanguage = (data) => { };
  const handleBuildSubmit = async () => {
    setFormStatusId(2);
    await handleAddScreening(2);
  };

  const handleCategoryVisiblityToggle = (categoryId) => {
    setActiveToggles((prevToggles) => ({
      ...prevToggles,
      [categoryId]: !prevToggles[categoryId],
    }));
  };
  return (
    <div>
      {/* <Form onSubmit={handleSubmit(handleBuildSubmit)}> */}
      {/* all code start here */}
      {/* title section */}

      <div className="px-3 pb-3 border-bottom">
        <div className="px-2 pt-3 d-flex">
          <div>
            <div className="d-flex align-items-center  gap-2">
              <H2>Create new screening package 2/2 </H2>
              <CustomToolTip
                size="sm"
                text="Create new screening package 2/2"
              />
            </div>
          </div>
        </div>
      </div>

      {/*Configuration of screening  */}
      <div className="p-4 ">
        <div className="d-flex align-items-center form-label-title gap-2 ">
          <span className="fs-20">Configuration of screening</span>{" "}
          <CustomToolTip text="Configuration of screening" />
        </div>
        <div className="light-font-text">
          <Row>
            <Col xl={8} className="pe-xl-0">
              In this step you’ll configure questions for the screening form.
              Once you’ve finished this part you’ll be able to save the
              screening form.
            </Col>
            <Col>
              <Controller
                {...register("languageName")}
                control={control}
                defaultValue=""
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    clearable={false}
                    value={value ? value : filteredOptions?.[0]}
                    // menuIsOpen={true}
                    placeholder={"Choose language"}
                    options={filteredOptions}
                    onChange={(e) => {
                      onChange(e);
                      setLanguageId(e?.value);
                    }}
                  />
                )}
              />
            </Col>
          </Row>
        </div>
      </div>

      {/* Screening accordion */}
      <div className="px-4 ">
        {categoryData?.map((data, i) => (
          <>
            <ETAccordion
              key={data?.screening_category_id}
              contextMenuRequired={false}
              accordionIndex={data?.screening_category_id}
              activeAccordion={activeAccordion}
              onAccordionToggle={handleAccordionToggle}
              header={
                <div className="d-flex" style={{ height: "100%" }}>
                  <div className="pe-4 d-flex align-items-center switch-section">
                    <Toggle
                      id={`switch${data?.screening_category_id}`}
                      checked={
                        data?.screening_category_id == 1
                          ? true
                          : activeToggles[data?.screening_category_id]
                      }
                      disabled={data?.screening_category_id == 1 ? true : false}
                      onChange={() =>
                        handleCategoryVisiblityToggle(
                          data?.screening_category_id
                        )
                      }
                    />
                  </div>
                  <div className="d-flex px-4 align-items-center gap-3">
                    <div className="accordain-user-icon">
                      <FontAwesomeIcon
                        icon={data?.icon_name}
                        style={{ color: "#000000" }}
                      />
                    </div>
                    <div>
                      {isEditableId == data?.display_order_no ? (
                        <CategoryNameInput
                          allCategoryData={categoryData}
                          setAllCategoryData={setCategoryData}
                          data={data}
                        />
                      ) : (
                        // <>{showEditCategoryName?.length > 0 && data?.display_order_no == selectedId && editCategoryTitle == false ? showEditCategoryName :
                        <>
                          {
                            data?.category_name?.filter(
                              (cat) => cat?.language_id === languageId
                            )?.[0]?.name
                          }

                          {/* </>} */}
                        </>
                      )}
                    </div>
                    {activeAccordion === data?.screening_category_id && (
                      <div
                        className="fs-16 p-2 "
                        onClick={(event) =>
                          handleChildClick(event, data?.screening_category_id)
                        }
                      >
                        <FontAwesomeIcon
                          icon="fa-regular fa-pencil"
                          className="cursor-pointer"
                          style={{ color: "#888888" }}
                        // onClick={() => setIsEditableId(data?.id)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              }
              content={
                <AccordionContent
                  screeningCategoryId={data?.screening_category_id}
                  screeningData={GetScreeningFieldsData?.data}
                  // handleAddScreening={handleAddScreening}
                  setValue={setValue}
                  getValues={getValues}
                  register={register}
                  errors={errors}
                  control={control}
                  useForm={useForm}
                  Controller={Controller}
                  handleSubmit={handleSubmit}
                  selectedId={selectedId}
                  setYellowFlagMessage={setYellowFlagMessage}
                  setRedFlagMessage={setRedFlagMessage}
                  screeningSectionData={screeningSectionData}
                  setScreeningSectionData={setScreeningSectionData}
                  categoryData={categoryData}
                  fields={fields}
                  append={append}
                  remove={remove}
                />
              }
            />
            {/* <Accordion
              isActive={isActive}
              isSectionAddAllowed={data?.is_section_add_allowed}
              setIsActive={setIsActive}
              id={data?.screening_category_id}
              setScreeningCategoryId={setScreeningCategoryId}
              title={
                <div className="d-flex" style={{ height: "100%" }}>
                  <div className="pe-4 d-flex align-items-center switch-section">
                    <Toggle
                      id={`switch${data?.screening_category_id}`}
                      checked={true}
                      disabled={
                        data?.is_toggle_visibility_allowed == 0 ? true : false
                      }
                    />
                  </div>
                  <div className="d-flex px-4 align-items-center gap-3">
                    <div className="accordain-user-icon">
                      <FontAwesomeIcon
                        icon={data?.icon_name}
                        style={{ color: "#000000" }}
                      />
                    </div>
                    <div>
                      {isEditableId == data?.display_order_no ? (
                        <CategoryNameInput
                          allCategoryData={categoryData}
                          setAllCategoryData={setCategoryData}
                          data={data}
                        />
                      ) : (
                        <>
                          {
                            data?.category_name?.filter(
                              (cat) => cat?.language_id === languageId
                            )?.[0]?.name
                          }
                        </>
                      )}
                    </div>
                    {isActive[data?.display_order_no] && (
                      <div
                        className="fs-16 p-2 "
                        onClick={(event) =>
                          handleChildClick(event, data?.display_order_no)
                        }
                      >
                        <FontAwesomeIcon
                          icon="fa-regular fa-pencil"
                          style={{ color: "#888888" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              }
              contentBody={
                <>
                  <AccordionContent
                    screeningCategoryId={screeningCategoryId}
                    screeningData={GetScreeningFieldsData?.data}
                    setValue={setValue}
                    getValues={getValues}
                    register={register}
                    errors={errors}
                    control={control}
                    useForm={useForm}
                    Controller={Controller}
                    handleSubmit={handleSubmit}
                    selectedId={selectedId}
                    setYellowFlagMessage={setYellowFlagMessage}
                    setRedFlagMessage={setRedFlagMessage}
                  />
                </>
              }
            /> */}
          </>
        ))}
      </div>

      {/* footer start */}
      <div className="mt-4 py-2 px-3 d-flex flex-md-row flex-column justify-content-between footer-section-button-container gap-3">
        <div className="d-flex gap-2">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={handleCancel}
          // onClick={() => setStep1(false)}
          />
          <ETButton
            variant={"secondary"}
            buttonText="BACK"
            onClick={() => setStep1(true)}
          />
        </div>
        <div className="d-flex gap-2">
          <ETButton
            variant={"secondary"}
            buttonText="Save as draft"
            onClick={() => {
              setFormStatusId(1);
              handleSaveAsDraft(1);
            }}
          // type={"submit"}
          // onClick={() => setStep1(false)}
          />
          <ETButton
            variant={"primary"}
            type={"submit"}
            onClick={() => {
              setFormStatusId(2);
              handleBuildSubmit(2);
            }}
            buttonText="Save screening FORM AND PUBLISH"
          />
        </div>
      </div>
      {/* footer end */}
      {/* all code end here */}
      {/* </Form> */}
      {/* Edit category popup */}
      <CustomPopup
        open={editCategoryTitle}
        onHide={() => setEditCategoryTitle(false)}
        onClickCrossButton={() => setEditCategoryTitle(false)}
        modalHeaderText={true}
        title={"Edit category name"}
        size="md"
        title_color="black"
        enableCrossButton={true}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        titleTooltipText={"Edit category name"}
      >
        <Form>
          <div>
            <div className="p-4 light-font-text">
              A change of the category name will affect the content shown to the
              customer.
            </div>
            <div>
              <hr className="m-0" />
              <div className="p-4">
                <div className="d-flex align-items-center mb-2  gap-2">
                  <H5>
                    {selectedId &&
                      categoryData
                        ?.find(
                          (item) => item?.screening_category_id == selectedId
                        )
                        ?.category_name?.filter(
                          (cat) => cat?.language_id === languageId
                        )[0]?.name}
                  </H5>
                  <CustomToolTip
                    size="xs"
                    text={`${selectedId &&
                      categoryData?.find((item) => item?.id === selectedId)
                        ?.title
                      }`}
                  />
                </div>

                <div className="form-input">
                  <Controller
                    control={control}
                    {...register("categoryName")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <input
                          type="text"
                          placeholder="Subject line"
                          id="categoryName"
                          value={categoryInformation?.name}
                          //   value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleCategoryHeadlineChange(e);
                          }}
                        />
                        <label htmlFor="categoryName">Category</label>
                      </>
                    )}
                  />
                  {errors?.categoryName && (
                    <p className="error-msg">{REQUIRED_ERR}</p>
                  )}
                </div>

                {/* <div className="p-4"> */}
                <div className="form-float openhouse-des pt-3">
                  <Controller
                    control={control}
                    {...register("categoryDescription")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <textarea
                          name=""
                          class="inputText"
                          cols="20"
                          rows="3"
                          id="categoryDescription"
                          value={categoryInformation?.description}
                          onChange={(e) => {
                            onChange(e);
                            handleCategoryDescriptionChange(e);
                          }}
                        ></textarea>
                        <label htmlFor="categoryDescription">Description</label>
                      </>
                    )}
                  />
                  {errors?.categoryDescription && (
                    <p className="error-msg">{REQUIRED_ERR}</p>
                  )}
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => setEditCategoryTitle(false)}
            />
            <ETButton
              variant={"primary"}
              type="button"
              buttonText="SAVE"
              onClick={() => {
                // Trigger validation and get form values
                const formData = getValues();

                // Check if categoryName is empty
                if (!formData.categoryName) {
                  // Manually set an error message for categoryName
                  setError("categoryName");

                  return;
                }

                setEditCategoryTitle(false);
                handleSaveCategoryDetails(selectedId);
              }}
            //   onChange={(e) => {
            //     // Handle your category name change logic
            //     handleCategoryHeadlineChange(e);

            //     // Clear the error when the input has a value
            //     if (e.target.value.trim() !== "") {
            //       clearErrors("categoryName");
            //     }

            //     // Trigger validation when the input changes
            //     trigger("categoryName");
            //   }}
            />
          </div>
        </Form>
      </CustomPopup>
      {/* Edit category popup */}
    </div>
  );
};

export default ScreeningFormBuild;

const CategoryNameInput = ({ allCategoryData, setAllCategoryData, data }) => {
  // below code is used for changing category name
  const handleChangeCategoryName = (e, categoryId) => {
    const newCategoryData = allCategoryData?.map((item) => {
      if (item?.id == categoryId) {
        return { ...item, title: e.target.value };
      } else {
        return item;
      }
    });
    setAllCategoryData(newCategoryData);
  };
  return (
    <input
      value={data.title}
      className="standard-input"
      style={{ border: "none" }}
      onChange={(e) => handleChangeCategoryName(e, data?.id)}
    />
  );
};
