import React, { useState } from "react";
import CustomPopup from "../../../Components/CustomPopup";
import ETButton from "../../../Components/Buttons/ETButton";
import { Col, Form, Row } from "react-bootstrap";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import FilterComponent from "../../../reactdataTableComponent/FilterComponent";
import PasswordField from "../../../Components/GroupText/PasswordField";

const AddParticipantsPopup = ({ open, close }) => {
  return (
    <div>
      <CustomPopup
        open={open}
        onHide={() => close(false)}
        title="Add participants"
        title_color="black"
        titleTooltip={true}
        titleTooltipText={"Add participants"}
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        size={"xl"}
      >
        <div>
          <Row className="px-4 mt-3">
            <Col lg={7} className="fs-14 ">
              <div className=" form-label-title ">
                Search among existing customer
              </div>
              <div className="fw-300 grey-text">
                If you think the customer may already be registered in the
                system, maybe as a previous tenant, an visitor at a event or as
                a lead from a campaign, you can find the customer by searching
                below
              </div>
            </Col>
          </Row>

          <Row className="px-4 pt-3 pb-4">
            <Col
              lg={6}
              className="pe-0"
              style={{
                position: " relative",
              }}
            >
              <div
                style={{
                  position: " relative",
                }}
              >
                <FilterComponent
                  // filtertext={searchText}
                  placeholder="123"
                  lableText="E.g. name, email, phone, social security number"
                />
              </div>
            </Col>
          </Row>
          <hr className="hr mt-1 mb-0"></hr>
          <Form>
            <div className=" py-4 px-4 ms-1 form-label-title">
              Customer information
            </div>
            <Row className="mx-3">
              <Col xl={3} lg={4} md={6}>
                <div className="form-input ">
                  <input
                    type="text"
                    placeholder="First name (required)"
                    id="firstName"
                  />
                  <label for="firstName">First name </label>
                </div>
              </Col>
              <Col xl={3} lg={4} md={6} className="ps-0">
                <div className="form-input ">
                  <input type="text" placeholder="Last name" id="lastName" />
                  <label for="lastName">Last name</label>
                </div>
              </Col>
            </Row>

            <Row className="my-3 mx-3">
              <Col xl={6} lg={8} md={12}>
                <div className="form-input ">
                  <input
                    type="email"
                    placeholder="Email (required)"
                    id="Email"
                  />
                  <label for="Email">Email</label>
                </div>
              </Col>
            </Row>

            <Row className="mx-3">
              <Col xl={6} lg={8}>
                <Row>
                  <Col xl={4} lg={5} md={4} className="pe-0">
                    <ReactSelect placeholder={"Country code"} />
                  </Col>
                  <Col xl={8} lg={7} md={8}>
                    <div className="form-input ">
                      <input
                        type="number"
                        placeholder="Phone"
                        id="Phone"
                        maxlength={15}
                      />
                      <label for="Phone">Phone</label>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="my-3 mx-3">
              <Col xl={6} lg={8}>
                <>
                  <PasswordField
                    id={"ssn"}
                    placeholder={"Social Security Number"}
                    maxlength={15}
                  />
                </>
              </Col>
            </Row>

            <Row className="mx-3">
              <Col xl={6} lg={8}>
                <Row>
                  <Col xl={4} lg={4} md={6} className="mb-3 pe-0">
                    <div className="form-input ">
                      <input type="text" placeholder="Street" id="street" />
                      <label for="street">Street </label>
                    </div>
                  </Col>

                  <Col lg={2} md={6} className=" mb-3 pe-0">
                    <div className="form-input ">
                      <input type="text" placeholder="No." id="houseN0" />
                      <label for="houseN0">No. </label>
                    </div>
                  </Col>
                  <Col lg={2} md={6} className=" mb-3 pe-0">
                    <div className="form-input ">
                      <input type="number" placeholder="Floor" id="floor" />
                      <label for="floor">Floor </label>
                    </div>
                  </Col>
                  <Col lg={4} md={6} className="mb-3 ">
                    <div className="form-input ">
                      <input
                        type="text"
                        placeholder="Side / Door"
                        id="Side/Door"
                      />
                      <label for="Side/Door">Side / Door </label>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mx-3 mb-3">
              <Col xl={6} lg={8}>
                <Row>
                  <Col lg={4} md={6} className="mb-3 pe-0">
                    <ReactSelect placeholder={"Country"} />
                  </Col>
                  <Col lg={4} md={6} className="mb-3 pe-0">
                    <ReactSelect placeholder={"Zip code"} />
                  </Col>

                  <Col lg={4} md={6} className="mb-3">
                    <div className="form-input ">
                      <input
                        type="text"
                        disabled
                        placeholder="City"
                        id="city"
                      />
                      <label htmlFor="city">City</label>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            onClick={() => close(false)}
            variant={"white"}
            buttonText="COLD FEET"
          />
          <ETButton variant={"primary"} buttonText="ADD" />
        </div>
      </CustomPopup>
    </div>
  );
};

export default AddParticipantsPopup;
