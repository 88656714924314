import React, { useRef, useState, useMemo } from "react";
import CSVWidget from "../../../Components/DownloadWidget/CSVWidget";

import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { Row, Col, Image } from "react-bootstrap";
import Menus from "../../../Components/Menus/Menus";
import { MaterialReactTable } from "material-react-table";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import MRTTheme from "../../../Components/table/MRTTheme";
import { NoRecordsToDisplay } from "../../../utils/validationConstants";
import { H2, H3 } from "../../../styles/Common.style";
import { SortIcon } from "../../../Components/table/MRTGlobalProps";

const PopularFiltersData = [
  {
    status: "Price",
    quantity: 1.715,
  },
  {
    status: "Square meters",
    quantity: 10,
  },
  {
    status: "Floorplan",
    quantity: 20,
  },
  {
    status: "Orientation",
    quantity: 30,
  },
  {
    status: "Outdoor space",
    quantity: 30,
  },
  {
    status: "Status",
    quantity: 30,
  },
];

const PopularFilters = () => {
  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "popular-filters";

  const options = {
    keys: [{ field: "status" }, { field: "quantity" }],
  };

  const data = PopularFiltersData;

  const CSVFunction = CSVWidget({ refObj, fileName, options, data });

  const columns = useMemo(
    () => [
      {
        accessorKey: "status",
        header: "Filters",
        enableGlobalFilter: false,
        enableSorting: false,
        size: 100,
      },
      {
        accessorKey: "quantity",
        header: "Used",
        enableSorting: false,
        enableGlobalFilter: false,
        size: 100,
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );

  return (
    <div
      className="card-body p-3"
      style={{
        minHeight: "465px",
      }}
    >
      <Row className="pb-3">
        <Col className="p-0" sm={8}>
          {" "}
          <div className="d-flex ps-3 align-items-center">
            <H3 className="  me-2">Popular filters</H3>{" "}
            <CustomToolTip size="sm" text="Popular filters" />
          </div>
        </Col>
        <Col sm={4} className="d-flex justify-content-end">
          {" "}
          <Menus
            itemheight={40}
            itemwidth={40}
            itemcontainerheight={200}
            itemcontainerwidth={200}
            component={
              <div className="fs-14">
                <div className="addpropContent px-3 py-2 ">
                  <span className="ms-1" onClick={() => CSVFunction()}>
                    Download widget as CSV
                  </span>
                </div>
              </div>
            }
          />
        </Col>
      </Row>
      {PopularFiltersData.length != 0 ? (
        <MRTTheme
          colorVariantsForTableData={["odd"]}
          table={
            <MaterialReactTable
              columns={columns}
              // data={PopularFiltersData}
              data={[]}
              icons={{ ...SortIcon }}
              localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
              enableColumnActions={false}
              enableBottomToolbar={false}
              enableTopToolbar={false}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
            />
          }
        />
      ) : (
        <NoDataScreen height={"377px"} imgHeight={"150px"} />
      )}
    </div>
  );
};

export default PopularFilters;
