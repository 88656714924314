import React from "react";
import styled from "styled-components";

export const RadioWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const RadioInput = styled.input`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #d1d1d1;
  border-radius: 50%;
  margin-right: 8px;
  outline: none;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: ${(props) =>
      props.theme.component.checkbox.primary.color};
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:checked:before {
    opacity: 1;
  }
`;

export const RadioLabel = styled.span`
  font-size: 14px;
  font-family: Cerebri sans-light;
  color: #000000;
`;

const ETRadioButton = ({ label, onChange, name, value, id }) => {
  return (
    <RadioWrapper>
      <RadioInput
        type="radio"
        onChange={onChange}
        name={name}
        value={value}
        id={id}
      />
      <RadioLabel>{label}</RadioLabel>
    </RadioWrapper>
  );
};

export default ETRadioButton;
