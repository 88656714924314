import { createContext, useContext, useState } from "react";

export const AppStateContext = createContext();

export function AppProvider({ children }) {
  const value = useState({});
  // const [previewData, setPreviewData] = useState([{
  //   ['§1 The partners and the rented']: {
  //     type_of_rental: {
  //       filled: true,
  //       checkedRadio: 'An apartment',
  //       the_tenancy_is_a_sublease: true,
  //       cave: '',
  //       located: {
  //         address: '',
  //         city: ''
  //       }
  //     },
  //   }
  // }]) // have to delete if unnecessary
  
  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(AppStateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppProvider");
  }
  return context;
}