import React, { useEffect, useMemo, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";

import ETButton from "../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../Components/CustomPopup";
import TagFilterDropDown from "../TagFilterDropDown";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";

import { Checkedbox, UnCheckedbox } from "../../../../assets/icons/icons";
import { InputAdornment } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MRTTheme from "../../../../Components/table/MRTTheme";
import DropdownModalMui from "../../../../Components/DropdownModal/DropdownModalMui";
import { useGetUnitsByPropertyIdUpdated } from "../../../../hooks/queries/useUnitQueries";
import Menus from "../../../../Components/Menus/Menus";
import { NavLink } from "react-router-dom";

import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../../Components/table/MRTGlobalProps";
import moment from "moment";
import { useUnitId } from "../../../../hooks/stores/useUnitStore";
import { usePropertyId } from "../../../../hooks/stores/usePropertyStore";

const AddMoreUnits = ({
  addMoreUnits,
  setAddMoreUnits,
  styleEditModalTitle,
  unitsTableData,
  unitsData,
  setUnitsData,
  setUnitsTableData,
  // newData,
}) => {
  const [rowSelection, setRowSelection] = useState([]);
  const [selected, setSelected] = useState([]);

  const propertyId = usePropertyId((state) => state.propertyId);
  const { setUnitId } = useUnitId((state) => state);

  const {
    data: GetUnitsByPropertyIdData,
    refetch: GetUnitsByPropertyIdDataRefetch,
    isLoading,
  } = useGetUnitsByPropertyIdUpdated(propertyId, 0);

  GetUnitsByPropertyIdData?.data.filter((result) =>
    unitsData?.every((data) => data.unit_id !== result.unit_id)
  );

  // const addMoreUnitData = GetUnitsByPropertyIdData?.data.filter((result) =>
  //   unitsData.every((data) => data.unit_id !== result.unit_id)
  // );

  const handleAddUnits = () => {
    let unitIds = Object.keys(rowSelection);

    const filtered = GetUnitsByPropertyIdData?.data?.filter((item) =>
      unitIds.includes(item?.unit_id)
    );

    const updatedTagUnits = [...filtered, ...unitsData];
    setUnitsData(updatedTagUnits);
    setAddMoreUnits(false);
  };

  const handleSuffix = (floor) => {
    if (floor > 3 && floor < 21) return "th";
    switch (floor % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row?.calculated_unit_id}${row?.alt_unit_id}`,
        header: "Unit no.",
        enableHiding: false,
        size: 130,
        muiTableBodyCellProps: {},

        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <>
            <div>{row?.original?.calculated_unit_id}</div>
            <div>{row?.original?.alt_unit_id}</div>
          </>
        ),
      },
      {
        accessorFn: (row) =>
          `${row?.street_name}${row?.zipcode_id}${row?.house_number}${row?.zipcode_id}${row?.city}`,
        header: "Address",
        size: 180,
        enableGlobalFilter: true,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <NavLink
              state={{
                unit_id: row.original.unit_id,
                propId: propertyId,
                unit_no: row?.original?.calculated_unit_id,
                street_name: row?.original?.street_name,
                house_number: row?.original?.house_number,
                zip_code: row?.original?.zipcode_id,
              }}
              to="/property-units-edit"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setUnitId(row.original.unit_id)}
              >
                {`${row?.original?.street_name},${row?.original?.house_number},${row?.original?.side_door}`}
              </div>
              <div>{`${row?.original?.zipcode_id} ${row?.original?.city}`}</div>
            </NavLink>
          </>
        ),
      },
      {
        accessorFn: (row) =>
          `${row?.no_of_rooms}${row?.floor_number}${row?.area}`,
        header: "Unit details",
        size: 180,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div className="d-flex justify-content-between">
              <div>{`${row?.original?.no_of_rooms}-room`}</div>{" "}
              <div>{`${row?.original?.floor_number}${handleSuffix(
                row?.original?.floor_number
              )} floor`}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-1">
                {row?.original?.area}{" "}
                <div
                  dangerouslySetInnerHTML={{
                    __html: row?.original?.square_measure_unit,
                  }}
                ></div>
              </div>

              <div
                style={{
                  width: "97px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  textAlign: "end",
                }}
              >
                {row?.original?.featured_facility == ""
                  ? "-"
                  : row?.original?.featured_facility}
              </div>
            </div>
          </>
        ),
      },
      {
        accessorFn: (row) => `${row?.rent}`,
        header: "Rent",
        size: 180,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
          sx: {},
        },
        Cell: ({ row }) => (
          <>
            <div className="d-flex justify-content-between">
              <div>
                Rent/
                {row?.original?.payment_frequency === 1
                  ? "mthly."
                  : row?.original?.payment_frequency === 3 && "qtrly."}
              </div>{" "}
              <div>{`${row?.original?.rent} ${row?.original?.currency_id}`}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                Rent/
                <p
                  dangerouslySetInnerHTML={{
                    __html: row?.original?.square_measure_unit,
                  }}
                ></p>
              </div>
              <div>{`${Math.ceil(row?.original?.rent / row?.original?.area)} ${
                row?.original?.currency_id
              }`}</div>
            </div>
          </>
        ),
      },
      {
        accessorFn: (row) => `${row?.actor_name}${row?.date}`,
        header: "Contract",
        size: 240,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div>
              {row?.original?.actor_name == ""
                ? "-"
                : row?.original?.actor_name}
            </div>
            <div>
              {row?.original?.date == "" ? (
                "-"
              ) : (
                <div className="d-flex align-items-center">
                  <div>{row?.original?.date_text}</div>&nbsp;:&nbsp;
                  <div>{moment(row?.original?.date).format("D MMM, YYYY")}</div>
                </div>
              )}
            </div>
          </>
        ),
      },

      {
        accessorFn: (row) => `${row?.mainstatus_name}${row?.substatus_name}`,
        header: "Status",
        size: 100,
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            {/* <DropDown
							propertyId={propertyId}
							value={row?.original}
							getTableUnitsByPropertyId={getTableUnitsByPropertyId}
							setReservedToLeasedPage={setReservedToLeasedPage}
							setBecomeChampPage={setBecomeChampPage}
							setAvailableToReseverdPop={setAvailableToReseverdPop}
						/> */}
            <div
              style={{
                whiteSpace: "nowrap",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                fontFamily: "Cerebri sans-medium",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              <div
                className="fs-13"
                style={{
                  backgroundColor: `${row?.original?.mainstatus_background_color}`,
                  height: "18px",
                  padding: "0px 10px",
                  borderRadius: "4px 4px 0px 0px",
                  textTransform: "uppercase",
                  color: row?.original?.mainstatus_font_color,
                }}
              >
                {row?.original?.mainstatus_name}
              </div>
              <div
                className="fs-12"
                style={{
                  backgroundColor: row?.original?.substatus_background_color,
                  height: "18px",
                  padding: "0px 10px",
                  borderRadius: "0px 0px 4px 4px",
                  color: row?.original?.substatus_font_color,
                }}
              >
                {row?.original?.substatus_name}
              </div>
            </div>
          </>
        ),
      },
    ],
    []
  );

  const ChooseFilter = [
    { Id: "1", Text: "Unit No" },
    { Id: "2", Text: "Status" },
  ];
  const tags = [
    { Id: "1", Text: "Not active", parent: "2" },
    { Id: "2", Text: "Available", parent: "2" },
    { Id: "3", Text: "Reserved", parent: "2" },
    { Id: "4", Text: "In process", parent: "2" },
    { Id: "5", Text: "Completed", parent: "2" },
    { Id: "6", Text: "Available", parent: "2" },
    { Id: "7", Text: "Total", parent: "2" },
  ];
  const [resetCondition, setResetCondition] = useState(false);

  const selectdata = [];
  const [filterDropdowon, setFilterDropdowon] = useState("");
  const [filterDropData, setFilterDropData] = useState("");
  const [selectedDropdownData, setSelectedDropDownData] = useState([]);
  const [show, setShow] = useState();
  const [status, setStatus] = useState(false);

  const [newData, setNewData] = useState([]);
  const [originalTagData, setOriginalTagData] = useState([]);
  const [addMoreUnitData, setAddMoreUnitData] = useState([]);
  useEffect(() => {
    const addMoreUnit = GetUnitsByPropertyIdData?.data?.filter((result) =>
      unitsData?.every((data) => data.unit_id !== result.unit_id)
    );

    setOriginalTagData(addMoreUnit);
    setAddMoreUnitData(addMoreUnit);

    setNewData(
      GetUnitsByPropertyIdData?.data?.filter((result) =>
        unitsData?.every((data) => data.unit_id !== result.unit_id)
      )
    );

    if (resetCondition == true) {
      setAddMoreUnitData(addMoreUnit);
    }
  }, [GetUnitsByPropertyIdData, resetCondition]);

  const dumnmyDropDownData = {
    "Unit No": "calculated_unit_id",
    Status: "tags",
  };
  const [propertag, setPropertytag] = useState("");
  const [filter, setFilter] = useState(null);

  const handleChange = (filter, ActionTypes) => {
    if (ActionTypes.action === "clear") {
      setFilter("");
    } else {
      setFilter(filter);
    }
    setPropertytag(true);
    setShow(false);
    let a = dumnmyDropDownData[filter?.label];
    let b = [...new Set(newData?.map((data) => data[a]))];
    setSelectedDropDownData(b);
    // setFilter(filter.label);
    setFilterDropdowon(ChooseFilter);

    const checkSelectedLabel = filter?.label;
    if (checkSelectedLabel == "Status") {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };
  const filteredTagdata = filter
    ? tags.filter((tag) => tag.parent === filter.value)
    : [];

  const filteredTag = selectedDropdownData;
  const [selecteddata, setSelectedData] = useState([]);
  const [closeDropdown, setCloseDropdown] = useState(false);

  const [filterKeys, setFilterKeys] = useState({
    calculated_unit_id: [],
    mainstatus_name: [],
  });
  // Function to push data into a specific filter key
  const pushDataToFilterKey = (key, value) => {
    if (filterKeys[key]?.includes(value)) {
    } else {
      setFilterKeys((prevFilterKeys) => ({
        ...prevFilterKeys,
        [key]: [...prevFilterKeys[key], value],
      }));
    }
  };
  const multiPropsFilter = (unitsTableData, filters) => {
    const filterKeys = Object?.keys(filters);
    const filterdata = originalTagData?.filter((product) => {
      return filterKeys?.every((key) => {
        if (!filters[key].length) return true;
        // Loops again if product[key] is an array (for material attribute).
        if (Array?.isArray(product[key])) {
          return product[key]?.some((keyEle) => filters[key]?.includes(keyEle));
        }
        return filters[key]?.includes(product[key]);
      });
    });
    setAddMoreUnitData(filterdata);
  };

  const handleFilterData = (i) => {
    const selectfilterdata = filter?.label;
    const parent = ChooseFilter.find(
      (filter) => filter.Id === selectfilterdata
    )?.parent;

    if (selectfilterdata == "Unit No") {
      const key = "calculated_unit_id";
      const value = i;
      pushDataToFilterKey(key, value);
    } else if (selectfilterdata == "Status") {
      const key = "mainstatus_name";
      const value = i;
      pushDataToFilterKey(key, value);
    } else {
    }

    setFilterDropData(i);

    selectdata.push({ lable: filterDropdowon, value: i });
    setSelectedData((prev) => [...prev, ...selectdata]);
  };
  // Dropdown for filter
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      style={{
        backgroundColor: "#FFF",
        color: "#000",
        width: "100%",
        display: "flex",
        height: "41px",
        border: "1px solid #D1D1D1",
        fontWeight: "300",
        justifyContent: "space-between",
        alignItems: "center",
        textDecoration: "none",
        fontSize: "14px",
        borderRadius: "8px",
      }}
      className="px-2 py-2 dropdownInput "
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        setCloseDropdown(!closeDropdown);
      }}
    >
      <div className="d-flex justify-content-between w-100">
        <span> {children}</span>
        <span>
          <FontAwesomeIcon
            icon="fa-regular fa-chevron-down"
            size="sm"
            style={{ color: "#000000" }}
          />
        </span>
      </div>
    </a>
  ));
  // const renderEmptyRowsFallback = () => (
  //   <div className="d-flex justify-content-center w-100 grey-text py-4">
  //     <div>You do not have any unit yet.</div>
  //   </div>
  // );

  const renderEmptyRowsFallback = () => {
    if (addMoreUnitData && addMoreUnitData?.length === 0) {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div>You do not have any unit yet.</div>
          </>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div style={{ fontSize: "17px" }}>Sorry! No result found:(</div>
          </>
        </div>
      );
    }
  };

  return (
    <div>
      {" "}
      <CustomPopup
        styleEditModalTitle={styleEditModalTitle}
        size="xl"
        open={addMoreUnits}
        onHide={() => setAddMoreUnits(false)}
        title="Add more units"
        titleFont="14px"
        title_color="black"
        titleTooltipText="Need Help About Tags"
        titleTooltip={true}
        enableCrossButton={false}
        onCloseAction={() => {
          setAddMoreUnits(false);
          alert("Action Peformed Successfuly.");
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div>
          <div className="mb-1 fs-14 p-4">
            <div>
              <span className="form-label-title">Tag name:</span> 2-bedroom
              apartment
            </div>
            <div className="light-font-text grey-text">
              Add sources below from the list to your tags.
            </div>
            <div
              className="Card-flex"
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "16px",
              }}
            ></div>
            <div>
              <MRTTheme
                table={
                  <MaterialReactTable
                    columns={columns}
                    data={addMoreUnitData ?? []}
                    icons={{ ...SortIcon }}
                    enableFilterMatchHighlighting={true}
                    enableGlobalFilter
                    enableRowSelection
                    onRowSelectionChange={setRowSelection}
                    // globalFilterFn="contains" //turn off fuzzy matching and use simple contains filter function
                    renderEmptyRowsFallback={renderEmptyRowsFallback}
                    // state={{ isLoading: !addMoreUnitData,  }}
                    state={{ rowSelection }}
                    // enableRowNumbers={false}
                    enableRowActions
                    enableColumnActions={false}
                    displayColumnDefOptions={{
                      "mrt-row-actions": {
                        header: "",
                        size: 50,
                        muiTableBodyCellProps: {
                          sx: {
                            // padding: "0px !important",
                            backgroundColor: "#F0F0F0 !important",
                            border: "none !important",
                          },
                        },
                      },
                    }}
                    muiSearchTextFieldProps={{
                      ...SearchStyle,

                      InputProps: {
                        ...SearchIcon,
                      },
                    }}
                    getRowId={(row) => row?.unit_id}
                    positionActionsColumn="last"
                    muiTableHeadCellProps={{
                      // elevation: 0,
                      sx: {
                        // boxShadow: "none",
                      },
                    }}
                    muiTableBodyProps={{
                      zindex: "1",
                      sx: {
                        td: {
                          overflow: "visible",
                          backgroundColor: "#F9FAFA",
                        },
                      },
                    }}
                    muiTablePaperProps={{
                      elevation: 0,
                      sx: {
                        fontFamily: "Cerebri sans !important",
                        borderRadius: "0",
                        border: "none",
                        background: "#FFFFFF",
                        fontWeight: "300",
                        ".css-1f2qhs8": {
                          minWidth: "auto",
                          marginBottom: "5px",
                        },
                        table: {
                          borderCollapse: "separate",
                          borderSpacing: "0 5px",
                        },
                        thead: {
                          // display: "none",
                        },
                        th: {
                          background: "#FFFFFF",
                          fontFamily: "Cerebri sans-medium !important",
                        },
                        td: {
                          fontFamily: "Cerebri sans-light !important",
                          fontSize: "14px",
                          borderRight: "1px solid #D1D1D1 !important",
                          borderImage:
                            "linear-gradient(to bottom, rgba(0,0,0,0) 25%,rgba(209,209,209) 25%,rgba(209,209,209) 75%,rgba(0,0,0,0) 75%) !important",
                          borderImageSlice: "1 !important",
                        },
                        tr: {
                          borderRadius: "4px",
                          background: "#F9FAFA",
                          margin: "4px",
                        },
                        "& td:nth-last-child(3)": {
                          borderRight: "none !important",
                        },
                        "& td:nth-last-child(2)": {
                          border: "none !important",
                          borderLeft: "1px solid #d1d1d1 !important",
                          backgroundColor: "#F0F0F0",
                        },
                        "& td:nth-last-child(1)": {
                          border: "none !important",
                        },
                      },
                    }}
                    muiTableHeadRowProps={{
                      sx: {
                        // ...TableRowsStyle,
                        background: "#FFFFFF",
                      },
                    }}
                    muiTablePaginationProps={{
                      ...paginationoptions,
                    }}
                    initialState={{
                      showGlobalFilter: true,
                      showColumnFilters: false,
                    }}
                    renderTopToolbar={({ table }) => (
                      <>
                        <Row className=" py-2 px-2">
                          <Col xl={3} md={6} className="my-1 pe-1 ">
                            <MRT_GlobalFilterTextField table={table} />
                          </Col>

                          <Col xl={3} md={6} className="my-1 pe-1">
                            <Dropdown show={closeDropdown}>
                              <Dropdown.Toggle
                                as={CustomToggle}
                                id="dropdown-custom-components"
                              >
                                Set filters
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="my-drop-menu">
                                <TagFilterDropDown
                                  propertag={propertag}
                                  filteredTag={filteredTag}
                                  filteredTagdata={filteredTagdata}
                                  status={status}
                                  setUnitsTableData={setUnitsData}
                                  multiPropsFilter={multiPropsFilter}
                                  selectedDropdownData={selectedDropdownData}
                                  dumnmyDropDownData={dumnmyDropDownData}
                                  filterDropdowon={filterDropdowon}
                                  closeDropdown={closeDropdown}
                                  // handleDropData={handleDropData}
                                  unitsTableData={unitsData}
                                  filterDropData={filterDropData}
                                  setFilterDropData={setFilterDropData}
                                  ChooseFilter={ChooseFilter}
                                  handleFilterData={handleFilterData}
                                  filter={filter}
                                  selecteddata={selecteddata}
                                  setFilter={setFilter}
                                  handleChange={handleChange}
                                  selectdata={selectdata}
                                  filterKeys={filterKeys}
                                  setFilterKeys={setFilterKeys}
                                  setCloseDropdown={setCloseDropdown}
                                  setResetCondition={setResetCondition}
                                />
                              </Dropdown.Menu>
                            </Dropdown>
                          </Col>
                        </Row>
                      </>
                    )}
                    positionGlobalFilter="left"
                  />
                }
              />
            </div>
          </div>
          <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => {
                setAddMoreUnits(false);
              }}
            />

            <ETButton
              variant={"primary"}
              buttonText="ADD"
              onClick={() => {
                handleAddUnits();
              }}
            />
          </div>
        </div>
      </CustomPopup>
    </div>
  );
};

export default AddMoreUnits;
