import React, { useRef, useState } from "react";
import CSVWidget from "../../../Components/DownloadWidget/CSVWidget";
import Menus from "../../../Components/Menus/Menus";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import "../../../styles/css/control-center/overview.css";
import DropdownModalMui from "../../../Components/DropdownModal/DropdownModalMui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-bootstrap";
import CheckBox from "../../../Components/Checkbox/Checkbox";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { H2, H3 } from "../../../styles/Common.style";

const actionActivityData = [
  {
    name: "Bygården",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Deadline overdue by 517 days",
    status: "RESERVERET",
  },
  {
    name: "Bygården",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Deadline overdue by 517 days",
    status: "RESERVERET",
  },
  {
    name: "Bygården",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Deadline overdue by 517 days",
    status: "RESERVERET",
  },
  {
    name: "Bygården",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Deadline overdue by 517 days",
    status: "RESERVERET",
  },
  {
    name: "Bygården",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Deadline overdue by 517 days",
    status: "RESERVERET",
  },
  {
    name: "Bygården",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Deadline overdue by 517 days",
    status: "RESERVERET",
  },
  {
    name: "Bygården",
    address: "Vanløse Torv 19 2.",
    username: "Susanne Jensen",
    time: "Deadline overdue by 517 days",
    status: "RESERVERET",
  },
];

const ActionActivity = () => {
  const fileName = "action-activity";

  const options = {
    keys: [
      { field: "username", title: "Name" },
      { field: "StatusId" },
      { field: "status" },
      { field: "name", title: "Property Name" },
      { field: "address" },
      { field: "time", title: "Created At" },
    ],
  };
  const data = actionActivityData;
  const CSVFunction = CSVWidget({ fileName, options, data });

  // All DeadLines Dropdown

  const [allDeadLines, setAllDeadLines] = useState([
    {
      id: "1",
      deadLinesName: "Before deadlines",
    },
    {
      id: "2",
      deadLinesName: "After deadlines",
    },
    {
      id: "3",
      deadLinesName: "Ok and before or on deadlines",
    },
    {
      id: "4",
      deadLinesName: "Ok but after deadlines",
    },
    {
      id: "5",
      deadLinesName: "Declines",
    },
  ]);

  const [allDeadLinesChecked, setAllDeadLinesChecked] = useState([]);

  const handleCheckAllDeadLines = (e) => {
    if (e.target.checked) {
      const allDeadLinesTypes = allDeadLines.map(
        (deadline) => deadline.deadLinesName
      );
      setAllDeadLinesChecked(allDeadLinesTypes);
    } else {
      setAllDeadLinesChecked([]);
    }
  };
  const handleCheckedCategory = (e, deadline) => {
    if (e.target.checked) {
      setAllDeadLinesChecked([...allDeadLinesChecked, deadline.deadLinesName]);
    } else {
      setAllDeadLinesChecked(
        allDeadLinesChecked.filter((item) => item !== deadline.deadLinesName)
      );
    }
  };
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(true);

  return (
    <div
      className="card-body"
      style={{
        minHeight: "555px",
      }}
    >
      <div>
        <div className="p-3">
          <Row className="d-flex align-items-center">
            <Col sm={5}>
              <div className="d-flex align-items-center">
                <H3 className="me-2">
                  Action activity
                </H3>{" "}
                <CustomToolTip size="sm" text="Action activity" />
              </div>
            </Col>
            <Col sm={7}>
              <div className="d-flex justify-content-end gap-3">
                <div style={{ width: "60%" }} >
                  {actionActivityData.length != 0 ? <ReactSelect
                    placeholder="All deadlines"

                  /> : <DropdownModalMui
                    disabled={isDropdownDisabled} // Pass the disabled prop
                    text="All deadlines"
                    component={
                      <div className="py-2 ps-2 pe-5">
                        {/* <div className="d-flex align-items-center light-font-text  fs-14 ">
                        <CheckBox
                          onChange={handleCheckAllDeadLines}
                          id={"handleCheckAllDeadLines"}
                          checked={
                            allDeadLinesChecked.length === allDeadLines.length
                          }
                        />{" "}
                        All deadlines
                      </div> */}

                        {/* {allDeadLines.map((deadline, i) => (
                        <div
                          key={i}
                          className="d-flex align-items-center light-font-text  fs-14 ps-4 "
                        >
                          <CheckBox
                            id={deadline.id}
                            checked={allDeadLinesChecked.includes(
                              deadline.deadLinesName
                            )}
                            onChange={(e) =>
                              handleCheckedCategory(e, deadline)
                            }
                          />{" "}
                          {deadline.deadLinesName}
                        </div>
                      ))} */}
                      </div>
                    }
                  />}


                </div>
                <div>
                  <Menus
                    itemheight={40}
                    itemwidth={40}
                    itemcontainerheight={200}
                    itemcontainerwidth={200}
                    component={
                      <div className="fs-14">
                        <div className="addpropContent px-3 py-2 ">
                          <span className="ms-1" onClick={() => CSVFunction()}>
                            Download widget as CSV
                          </span>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>

        {actionActivityData.length == 0 ? (
          <div
            className="scrollable-container-y me-2 px-4"
            style={{ height: "467px" }}
          >
            {actionActivityData.map((status, i) => (
              <Row key={i} className="card-inner-body py-2 px-4 my-2">
                <Col className="p-0" xs={9}>
                  <div className="table-property-name pb-1  ps-1">
                    {status.name}
                  </div>

                  <div
                    className="table-property-name fs-10 status-activity-label px-2"
                    style={{
                      backgroundColor: "#F1664B",
                      color: "#fff",
                    }}
                  >
                    {status.status}
                  </div>
                  <span className="light-font-text fs-10 ps-1">
                    {status.time}
                  </span>
                </Col>

                <Col className="d-flex align-items-center justify-content-end flex-column">
                  <div className="table-property-name fs-12">
                    After deadline
                  </div>
                  <div className="fs-12 light-font-text ">Mar 6, 2023</div>
                </Col>
              </Row>
            ))}
          </div>
        ) : (
          <NoDataScreen height={"377px"} imgHeight={"150px"} />
        )}
      </div>
    </div>
  );
};

export default ActionActivity;
