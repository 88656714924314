import { Delete, Get, Post, Put } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

//Get actors by status list
export const GetContractStatusList = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Actor_Get_Contract_StatusList, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Get actors by search
export const GetActorsBySearch = (search) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_ActorsBySearch, null, search)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get actors by actorId
export const GetActorByActorId = (actorId) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_ActorsByActorId, null, actorId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get actors occupation type
export const GetActorOccupationType = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_ActorOccupationType, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Add actor
export const PostAddActor = (addActorPayload) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Post_Add_Actor, null, addActorPayload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Update actor
export const PutUpdateActor = (updateActorPayload) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Put_Update_Actor, null, updateActorPayload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Post Actor create reservation
export const PostActorCreateReservation = (createReservationPayload) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Post_Actor_Create_Reservation,
      null,
      createReservationPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Contract by UnitId
export const GetContractByUnitId = (unitId) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Actor_Get_Contracts_By_UnitId,
      null,
      unitId + "/reservation"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get actors by contract id
export const GetActorByContractId = (contract_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Actor_Get_Actor_By_ContractId,
      null,
      contract_id + "/actors"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Update actor
export const PutUpdateResponsibleAgent = (updateResponsibleAgentPayload) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Actor_Update_Responsible_Agent,
      null,
      updateResponsibleAgentPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Update reservation detail
export const PutUpdateReservationDetails = (updateReservationDetailPayload) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Actor_Update_Reservation_Details,
      null,
      updateReservationDetailPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Update deposit detail
export const PutUpdatDepositDetails = (updateDepositDetailPayload) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Actor_Put_Update_Deposit_Details,
      null,
      updateDepositDetailPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Update leased detail
export const PutUpdateLeasedDetails = (updateLeasedDetailPayload) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Actor_Put_Update_Leased_Details,
      null,
      updateLeasedDetailPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Update termination detail
export const PutUpdateTerminationDetails = (updateTerminationDetailPayload) => {
  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Actor_Put_Update_Termination_Details,
      null,
      updateTerminationDetailPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get contract deposit popup details
export const GetContractDepositPopupDetails = (contract_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Actor_Get_ContractDeposit_PopupDetail,
      null,
      contract_id + "/popup/deposit"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get contract rental popup details
export const GetContractRentalPopupDetails = (contract_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Actor_Get_ContractRental_PopupDetail,
      null,
      contract_id + "/popup/rental"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get contract leased popup details
export const GetContractLeasedPopupDetails = (contract_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Actor_Get_ContractLeased_PopupDetail,
      null,
      contract_id + "/popup/leased"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Get contract termination popup details
export const GetContractTerminationDetails = (contract_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Actor_Get_ContractTermination_PopupDetail,
      null,
      contract_id + "/popup/termination"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Actor Get document details by contract id
export const GetDocumentDetailsByContractId = (
  contract_id,
  actor_id,
  archive_type
) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Actor_Get_DocumentDetailsByContractId,
      null,
      contract_id + "/actor/" + actor_id + "/documents/" + archive_type
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Post upload actor document
export const PostUploadActorDocument = (formData) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Actor_PostUploadActorDocument,
      { "Content-Type": "multipart/form-data" },
      formData
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Delete contract actor
export const DeleteContractActor = (contract_id, actor_id) => {
  return new Promise((resolve, reject) => {
    Delete(
      Site.ET,
      Endpoint.ET.Contract_DeleteContractActor,
      null,
      contract_id + "/actor/" + actor_id
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//------------------Screening API's------------------//
//Actor Get screening template QnA
export const GetScreeningTemplateQuestionAnswer = (
  property_id,
  contract_id,
  actor_id
) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Contract_GetScreeningTemplateQuestionAnswer,
      null,
      property_id +
        "/contract/" +
        contract_id +
        "/actor/" +
        actor_id +
        "/answers"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Actor Get screening template question
export const GetScreeningTemplateQuestion = (template_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Contract_GetScreeningTemplateQuestion,
      null,
      template_id + "/questions"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

export const ShareDocuments = (ShareDocumentsPayload) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Documents_ShareDocuments,
      null,
      ShareDocumentsPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Contract Type using ecomomy
export const GetContractType = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Contract_Get_ContractType, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
