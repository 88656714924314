import {
  Button,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  styled,
  TextField,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { useState } from "react";
import { DateRange } from "react-date-range";
import Calender from "../../assets/icons/svg_icons/Date-icon.svg";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Box } from "@mui/system";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,

    fontSize: "14px",
    "& fieldset": {
      // borderColor: borderColor,
      fontSize: "14px",
    },
    "&:hover fieldset": {
      borderColor: "#D1D1D1",
      boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#888888",
    },
  },
});

const DateRangePicker = ({ label, onChange, value, milestone, setDate, date }) => {
  const [anchorCalendar, setanchorCalendar] = useState(false);
  const handleClickCalendar = (event) => {
    setanchorCalendar(event.currentTarget);
  };
  const handleCloseCalendar = () => {
    setanchorCalendar(null);
  };
  // const [date, setDate] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  // ]);
  return (
    <>
      <Button
        size="small"
        id="basic-button-calendar"
        aria-controls={anchorCalendar ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={anchorCalendar ? "true" : undefined}
        onClick={handleClickCalendar}
        sx={{
          "&:hover": { backgroundColor: "transparent" },
          padding: "0px",
        }}
      >
        <div className="date-picker-filter" style={{borderColor: "#D1D1D1"}}>
          <div className="d-flex justify-content-between gap-3">
            <div className="light fs-14 ">
              <div className="light-font-text">
                {`${format(date[0]?.startDate, "dd. MMM. yyyy")} - ${format(
                  date[0].endDate,
                  "dd. MMM. yyyy"
                )}`}
              </div>
            </div>
            <div>
              <img
                className={milestone ? "img-cal-milestone" : "img-cal"}
                height={15}
                src={Calender}
                alt="calendar"
              />
            </div>
          </div>
        </div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorCalendar}
        open={anchorCalendar}
        onClose={handleCloseCalendar}
        MenuListProps={{
          "aria-labelledby": "basic-button-calendar",
        }}
        sx={{
          marginTop: "8px",
          backgroundColor: "rgba(0, 0, 0, 0.02)",
        }}
      >
        <MenuItem
          style={{
            color: "transparent",
          }}
          sx={{
            "&:hover": { backgroundColor: "transparent" },
            borderRadius: "8px",
            padding: "0px",
            overflow: "hidden",
            border: "1px solid #F4F5F5",
          }}
        >
          <DateRange
            editableDateInputs={true}
            onChange={(item) => setDate([item.selection])}
            // onChange={onChange}
            moveRangeOnFirstSelection={false}
            ranges={date}
            rangeColors={["#619130"]}
          />
        </MenuItem>
      </Menu>
    </>
  );
};

export default DateRangePicker;
