import { create } from "zustand";

export const useScreening = create((set, get) => ({
  isRadioChecked: false,
  setIsRadioChecked: (data) => set({ isRadioChecked: data }),

  isScreeningRadioChecked: false,
  setIsScreeningRadioChecked: (data) => set({ isScreeningRadioChecked: data }),

  screeningFormId: 0,
  setScreeningFormId: (data) => set({ screeningFormId: data }),

  formStatusId: 1,
  setFormStatusId: (data) => set({ formStatusId: data }),

  selectedLanguageId: [],
  setSelectedLanguageId: (data) => set({ selectedLanguageId: data }),

  selectedLanguageText: [],
  setSelectedLanguageText: (data) => set({ selectedLanguageText: data }),

  selectedLanguageFlag: [],
  setSelectedLanguageFlag: (data) => set({ selectedLanguageFlag: data }),

  selectedLanguageData: [],
  setSelectedLanguageData: (data) => set({ selectedLanguageData: data }),

  linkedUnitId: [],
  setLinkedUnitId: (data) => set({ linkedUnitId: data }),

  languageId: "",
  setLanguageId: (data) => set({ languageId: data }),

  accordianId: "",
  setAccordianId: (data) => set({ accordianId: data }),

  isYellowFlagActivated: false,
  setIsYellowFlagActivated: (data) => set({ isYellowFlagActivated: data }),

  isYellowFlagMessageActivated: false,
  setIsYellowFlagMessageActivated: (data) =>
    set({ isYellowFlagMessageActivated: data }),

  yellowScreeningConditions: [],
  setYellowScreeningConditions: (data) =>
    set({ yellowScreeningConditions: data }),

  isRedFlagActivated: false,
  setIsRedFlagActivated: (data) => set({ isRedFlagActivated: data }),

  isRedFlagMessageActivated: false,
  setIsRedFlagMessageActivated: (data) =>
    set({ isRedFlagMessageActivated: data }),

  // redFlagMessage: [],
  // setRedFlagMessage: (data) => set({ redFlagMessage: data }),

  redScreeningConditions: [],
  setRedScreeningConditions: (data) => set({ redScreeningConditions: data }),

  possibleValue: [],
  setPossibleValue: (data) => set({ possibleValue: data }),

  sectionJsonData: [],
  setSectionJsonData: (data) => {
    set({ sectionJsonData: data });
  },

  categoryJsonData: [],
  setCategoryJsonData: (data) => set({ categoryJsonData: data }),

  questionJsonData: [],
  setQuestionJsonData: (data) => set({ questionJsonData: data }),

  isFieldConditionActivated: 0,
  setIsFieldConditionActivated: (data) =>
    set({ isFieldConditionActivated: data }),

  fieldConditionRules: [],
  setFieldConditionRules: (data) => set({ fieldConditionRules: data }),

  yellowScreeningRules: [],
  setYellowScreeningRules: (data) => set({ yellowScreeningRules: data }),

  redScreeningRules: [],
  setRedScreeningRules: (data) => set({ redScreeningRules: data }),

  yellowScreeningResults: [],
  setYellowScreeningResults: (data) => set({ yellowScreeningResults: data }),

  redScreeningResults: [],
  setRedScreeningResults: (data) => set({ redScreeningResults: data }),

  screeningLogicType: {
    value: 1,
    label: "Any",
  },
  setScreenigLogicType: (data) => set({ screeningLogicType: data }),

  screeningSectionStoreData: [],
  setScreeningSectionStoreData: (data) =>
    set({ screeningSectionStoreData: data }),

  actionType: { value: 1, label: "Show" },
  setActionType: (data) => set({ actionType: data }),

  logicType: { value: 1, label: "Any" },
  setLogicType: (data) => set({ logicType: data }),

  EditscreeningForm: false,
  setEditScreeningForm: (data) => set({ EditscreeningForm: data }),

  addSectionAllow: "",
  setAddSectionAllow: (data) => set({ addSectionAllow: data }),

  saveScreeningForm: false,
  setSaveScreeningForm: (data) => set({ saveScreeningForm: data }),
  draft: true,
  setDraft: (data) => set({ draft: data }),

  parentFormId: 0,
  setParentFormId: (data) => set({ parentFormId: Number(data) }),

  screeeningConditionsStore: [],
  setScreeningConditionsStore: (data) =>
    set({ screeeningConditionsStore: data }),

  fieldConditionsStore: [],
  setFieldConditionsStore: (data) => set({ fieldConditionsStore: data }),

  resetScreeningStore: () =>
    set({
      isRadioChecked: false,
      isScreeningRadioChecked: false,
      screeningFormId: "",
      formStatusId: 1,
      selectedLanguageId: [],
      selectedLanguageText: [],
      selectedLanguageFlag: [],
      selectedLanguageData: [],
      linkedUnitId: [],
      languageId: "",
      accordianId: "",
      isYellowFlagActivated: false,
      isYellowFlagMessageActivated: false,
      yellowScreeningConditions: [],
      isRedFlagActivated: false,
      isRedFlagMessageActivated: false,
      redScreeningConditions: [],
      possibleValue: [],
      sectionJsonData: [],
      questionJsonData: [],
      isFieldConditionActivated: 0,
      fieldConditionRules: [],
      yellowScreeningRules: [],
      redScreeningRules: [],
      yellowScreeningResults: [],
      redScreeningResults: [],
      screeningLogicType: {
        value: 1,
        label: "Any",
      },
      screeningSectionStoreData: [],
    }),
  actionType: { value: 1, label: "Show" },
  logicType: { value: 1, label: "Any" },
  EditscreeningForm: false,
  addSectionAllow: "",
  saveScreeningForm: false,
}));
