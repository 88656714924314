import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import FilterComponent from "../../reactdataTableComponent/FilterComponent";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import DateRangeFilter from "../../Components/DateRangePicker/DateRangeFilter";
import Accordion from "../../Components/Accordion/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notesData } from "./StaticData";
import EmptyMessage from "../../utils/EmptyMessage";

const Notes = () => {
  return (
    <div>
      <div className="pt-4 pb-2 px-4 form-label-title">All notes</div>
      <div className="px-4 pb-3">
        <Row>
          <Col xl={4} className="pb-2">
            <Row>
              <Col xl={8}>
                <FilterComponent />
              </Col>
            </Row>
          </Col>
          <Col xl={8}>
            <Row>
              <Col lg={3} className="pe-lg-0 pb-2">
                {" "}
                <ReactSelect placeholder={"Category"} />
              </Col>
              <Col lg={3} className="pe-lg-0 pb-2">
                {" "}
                <ReactSelect placeholder={"Created by"} />
              </Col>
              <Col lg={6} className="pb-2">
                <DateRangeFilter
                // date={dateRange}
                // setDate={setDateRange}
                // handleDateChange={handleDateChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <hr className="my-0"></hr>
      <div className="p-4">
        <EmptyMessage />
        {/* {notesData.map((item) => (
          <div key={item.id} className="pb-2">
            <Accordion
              accordianIconAlignment="left"
              accordianBorder={false}
              bgColor={"#F9FAFA"}
              accordianHeight={"40px"}
              title={
                <div className="d-flex justify-content-between">
                  <div className="form-label-title ">{item.title}</div>
                </div>
              }
              titleDate={<div className="fs-10">20 May, 2023</div>}
              titleDiscriptipon={
                <div className="pb-2 ps-5 pe-4">
                  <div className="d-flex gap-1 align-items-center">
                    <div
                      style={{
                        backgroundColor: item.subject_bg
                          ? item.subject_bg
                          : "#9370DB",
                        color: "white",
                        borderRadius: "4px",
                        fontSize: "9px",
                        alignItems: "center",
                        textTransform: "uppercase",
                        height: "16px",
                      }}
                      className="px-2 font-semibold  d-flex"
                    >
                      {item?.subjet}
                    </div>
                    <div className=" font-semibold fs-14">Subject line</div>
                  </div>
                  <div className="mt-1 light-font-text pe-4">{item?.msg}</div>
                  <div className="mt-2 d-flex justify-content-between">
                    <div className="light-font-text fs-12">
                      by
                      {item?.last_outbound_respond}
                    </div>
                    <div className="cursor-pointer">
                      <button className="icon-btn">
                        <FontAwesomeIcon icon="fa-regular fa-trash" />
                      </button>
                    </div>
                  </div>
                </div>
              }
              contentBody={
                <>
                  <div className="text-area py-3 px-4">
                    <div className="form-label-title pb-2">Add comment</div>
                    <InputGroup className="notes-textarea">
                      <Form.Control
                        as="textarea"
                        placeholder="Messages"
                        aria-label="Large"
                        aria-describedby="inputGroup-sizing-sm"
                      />
                      <InputGroup.Text
                        className="notes-textarea-icon"
                        id="inputGroup-sizing-lg"
                      >
                        <button type="button" className="message-send-button">
                          {
                            <FontAwesomeIcon
                              size="xs"
                              icon="fa-regular fa-paper-plane-top"
                              style={{ color: "#FFFFFF" }}
                            />
                          }
                        </button>
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                </>
              }
            />
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default Notes;
