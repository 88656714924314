import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import React, { useMemo, useState } from "react";
import MRTTheme from "../../../../Components/table/MRTTheme";
import {
  SearchIcon,
  SearchStyle,
  paginationoptions,
  SortIcon,
} from "../../../../Components/table/MRTGlobalProps";
import { Col, Form, Row } from "react-bootstrap";
import Menus from "../../../../Components/Menus/Menus";
import { MenuItem } from "@mui/material";
import moment from "moment";
import { CircleMsg, H3, H5 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import CustomPopup from "../../../../Components/CustomPopup";
import ETButton from "../../../../Components/Buttons/ETButton";
import ShowTooltip from "../../../../Components/Tooltip/ShowTooltip";
import InputField from "../../../../Components/Textfield/InputField";
import { useForm, Controller } from "react-hook-form";
import TagToolTip from "../../../../Components/Tooltip/PropertyTagToolTip";
import { UTCtoLocalTimeZone } from "../../../../utils/UTCtoLocalTimeZone";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";

const ArchivedUsers = ({
  archiveUsersData,
  handleUpdateUserStatus,
  setUserId,
  userId,
}) => {
  const [reactiveUser, setReactiveUser] = useState(false);
  const [sorting, setSorting] = useState([]);
  const [deleteUser, setDeleteUser] = useState(false);
  const [fullname, setFullname] = useState("");
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      deleteUserName: "",
    },
  });
  const deleteUserNameField = watch(`deleteUserName`);

  const handleCommaSeparatedRoles = (row, type) => {
    let splittedRole;
    if (type === "roles") {
      splittedRole = row?.original?.roles?.split(",");
    } else if (type === "divisions") {
      splittedRole = row?.original?.division_details?.map((detail, index) => {
        return detail?.division_name;
      });
    }

    return (
      <div>
        <span>{splittedRole?.[0]}</span>
        {splittedRole?.length > 1 && (
          <CircleMsg>
            <span>{splittedRole?.length}</span>
          </CircleMsg>
        )}
      </div>
    );
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "user_no",
        header: "No.",
        enableHiding: false,
        size: 30,
        enableGlobalFilter: true,

        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "16px !important",
          },
        },
        Cell: ({ row }) => (
          <div>{row.original?.user_no ? row.original?.user_no : "-"}</div>
        ),
      },
      {
        accessorKey: "first_name",
        header: "First name",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div>{row.original?.first_name ? row.original?.first_name : "-"}</div>
        ),
      },
      {
        accessorKey: "last_name",
        header: "Last name",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div>{row.original?.last_name ? row.original?.last_name : "-"}</div>
        ),
      },
      {
        accessorKey: "roles",
        header: "Roles",
        enableHiding: false,
        size: 150,
        enableGlobalFilter: true,

        Cell: ({ row }) => (
          <div className="light-font-text d-flex gap-2">
            <TagToolTip
              iconColor={"#888888"}
              size={"lg"}
              border="1px solid #F0F0F0"
              padding={"5px 10px"}
              boxShadow="2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
              text={
                <div style={{ width: "450px" }}>
                  <Row className="py-3 ps-4 pe-6 ">
                    <div className="form-label-title">
                      User roles for {row?.original?.first_name}{" "}
                      {row?.original?.last_name}
                    </div>
                  </Row>
                  <hr className="m-0"></hr>
                  <div
                    className="p-4 me-2"
                    style={{ height: "200px", overflowY: "scroll" }}
                  >
                    <Row className="mb-3">
                      <Col lg={5}>
                        <div className="form-label-title">Role Type</div>
                      </Col>
                      <Col>
                        <div className="form-label-title">Division</div>
                      </Col>
                      <Col>
                        <div className="form-label-title">Joined</div>
                      </Col>
                    </Row>

                    {row?.original?.division_details?.map((divisionDetail) => {
                      return (
                        <Row className="mb-3" key={divisionDetail?.division_id}>
                          <Col lg={5}>
                            <div className="light-font-text">
                              {divisionDetail?.role_name}
                            </div>
                          </Col>
                          <Col>
                            <div className="light-font-text">
                              {divisionDetail?.division_name}
                            </div>
                          </Col>
                          <Col>
                            <div className="light-font-text">
                              {divisionDetail?.assigned_on
                                ? moment(
                                    divisionDetail?.assigned_on,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ).format("DD MMM, YYYY")
                                : "-"}
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </div>
              }
            />
            <div>
              {row?.original?.roles?.includes(",")
                ? handleCommaSeparatedRoles(row, "roles")
                : row?.original?.roles}
            </div>
          </div>
        ),
      },

      {
        accessorKey: "status_name",
        header: "Status",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,
        Cell: ({ row }) => {
          const { convertedDate: status_modified_on } = UTCtoLocalTimeZone({
            timestamp: row?.original?.status_modified_on,
            offset_mins: useUserDetails?.timezone_offset_mins,
            timeFormat: "DD MMM, YYYY [at] HH:mm:ss",
          }); // Get current Time zone

          return (
            <div className="light-font-text d-flex gap-2">
              {row?.original?.status_name}
              {String(row?.original?.status_id) === "3" && (
                <TagToolTip
                  iconColor={"#888888"}
                  size={"lg"}
                  border="1px solid #F0F0F0"
                  padding={"0px"}
                  boxShadow="2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                  text={
                    <div style={{ width: "500px" }}>
                      <div className="form-label-title py-3 px-4">
                        Details about blocked user
                      </div>

                      <hr className="m-0"></hr>

                      <div className="p-4" style={{ height: "100px" }}>
                        <Row style={{ paddingBottom: "11px" }}>
                          <Col>
                            <div className="form-label-title">User</div>
                          </Col>
                          <Col>
                            <div className="form-label-title">Blocked</div>
                          </Col>
                          <Col>
                            <div className="form-label-title">Blocked by</div>
                          </Col>
                        </Row>

                        <Row style={{ paddingBottom: "11px" }}>
                          <Col>
                            <div className="light-font-text">
                              {row?.original?.first_name}{" "}
                              {row?.original?.last_name}
                            </div>
                          </Col>
                          <Col>
                            <div className="light-font-text">
                              {status_modified_on}
                            </div>
                          </Col>
                          <Col>
                            <div className="light-font-text">
                              {row?.original?.modified_on_name}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  }
                />
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "joined_on",
        header: "Joined",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,

        Cell: ({ row }) => {
          const { convertedDate: joined_on } = UTCtoLocalTimeZone({
            timestamp: row?.original?.joined_on,
            offset_mins: useUserDetails?.timezone_offset_mins,
            timeFormat: "DD MMM, YYYY",
          }); // Get current Time zone

          return (
            <div className="light-font-text d-flex gap-2">{joined_on}</div>
          );
        },
      },
      {
        accessorKey: "last_login_on",
        header: "Last active",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,

        Cell: ({ row }) => {
          const { convertedDate: last_login_on } = UTCtoLocalTimeZone({
            timestamp: row?.original?.last_login_on,
            offset_mins: useUserDetails?.timezone_offset_mins,
            timeFormat: "DD MMM, YYYY",
          }); // Get current Time zone

          return (
            <div className="light-font-text d-flex gap-2">{last_login_on}</div>
          );
        },
      },
      {
        accessorKey: "status_modified_on",
        header: "Archived date",
        enableHiding: false,
        size: 60,
        enableGlobalFilter: false,

        Cell: ({ row }) => {
          const { convertedDate: status_modified_on } = UTCtoLocalTimeZone({
            timestamp: row?.original?.status_modified_on,
            offset_mins: useUserDetails?.timezone_offset_mins,
            timeFormat: "DD MMM, YYYY",
          }); // Get current Time zone

          return (
            <div className="light-font-text d-flex gap-2">
              {status_modified_on}
            </div>
          );
        },
      },
    ],
    []
  );

  const renderEmptyRowsFallback = () => {
    return (
      <div
        className="d-flex justify-content-center w-100 grey-text py-3 radius-4"
        style={{ border: "1px solid #d1d1d1", backgroundColor: "#F9FAFA" }}
      >
        <>
          <div className="fs-14">Sorry! No result found. Please try again!</div>
        </>
      </div>
    );
  };
  return (
    <div className="p-4 pt-2">
      <MRTTheme
        colorVariantsForTableRow={["gray"]}
        colorVariantsForTableHead={["white"]}
        table={
          <MaterialReactTable
            columns={columns?.map((columns) => {
              return {
                ...columns,
                sortDescFirst: false,
              };
            })}
            data={archiveUsersData || []} //data is undefined on first render
            renderEmptyRowsFallback={renderEmptyRowsFallback}
            enableFilterMatchHighlighting={true}
            enableGlobalFilter
            globalFilterFn="contains"
            enableColumnActions={false}
            enableBottomToolbar={true}
            icons={{
              ArrowDownwardIcon: () => SortIcon?.ArrowDownwardIcon(sorting),
              SyncAltIcon: () => SortIcon?.SyncAltIcon(),
            }}
            state={{
              sorting: sorting,
            }}
            onSortingChange={setSorting}
            muiSearchTextFieldProps={{
              ...SearchStyle,

              InputProps: {
                ...SearchIcon,
              },
            }}
            getRowId={(originalRow) => originalRow?.company_id}
            //give each row a more useful id
            enableSelectAll={false}
            enableRowActions
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "",
                size: 20,
                muiTableBodyCellProps: {
                  sx: {
                    paddingRight: "16px !important",
                  },
                },
              },
              "mrt-row-select": {
                header: "",
              },
            }}
            muiTablePaperProps={{
              sx: {
                table: {
                  borderCollapse: "separate",
                  borderSpacing: "0 5px",
                },
              },
            }}
            renderTopToolbar={({ table }) => (
              <>
                <div className="">
                  <Row>
                    <Col md={8} lg={9} className="mb-sm-3 mb-lg-0 pe-lg-5">
                      <div className="d-flex align-items-center  mb-3 gap-2">
                        <H3>Archived users</H3>
                        <CustomToolTip size="sm" text="Inactive users" />
                      </div>
                      <div className="paragraph2-light">
                        Below is the list of all inactive users in the system.
                        Give the user access to the system again by clicking
                        reactivated in the menu.
                      </div>
                    </Col>
                    <Col
                      md={4}
                      lg={3}
                      className="d-flex justify-content-end"
                      // className="my-1 d-flex justify-content-end align-items-center gap-3"
                    >
                      <MRT_GlobalFilterTextField
                        placeholder="Search for a user..."
                        table={table}
                      />
                    </Col>
                  </Row>
                </div>
              </>
            )}
            muiTableContainerProps={{
              elevation: 0,
              sx: {
                background: "white",
                padding: "0px !important",
                paddingTop: "24px !important",
              },
            }}
            positionActionsColumn="last"
            initialState={{
              showGlobalFilter: true,
              showColumnFilters: false,
            }}
            muiTablePaginationProps={{
              ...paginationoptions,
            }}
            renderRowActions={({ row, table }) => (
              <>
                <div className="d-flex justify-content-end">
                  <Menus
                    itemheight={24}
                    itemwidth={24}
                    iconborderradius="4px"
                    itemcontainerwidth="150px"
                    component={
                      <>
                        <MenuItem
                          onClick={() => {
                            setReactiveUser(true);
                            setUserId(row?.original?.user_id);
                          }}
                          key="edit"
                          sx={{
                            fontSize: "13px",
                            fontFamily: "Cerebri sans-light !important",
                          }}
                        >
                          Restore user
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setFullname(
                              `${row?.original?.first_name} ${row?.original?.last_name}`
                            );
                            setDeleteUser(true);
                            setUserId(row?.original?.user_id);
                          }}
                          key="delete"
                          sx={{
                            fontSize: "13px",
                            fontFamily: "Cerebri sans-light !important",
                          }}
                        >
                          Delete user details
                        </MenuItem>
                      </>
                    }
                  />
                </div>
              </>
            )}
          />
        }
      />
      {/* reactivate user */}
      <CustomPopup
        open={reactiveUser}
        onHide={() => setReactiveUser(false)}
        title="Reactivate the user?"
        title_color="black"
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Reactivate the user"}
      >
        <div className="p-4 light-font-text" style={{ width: "80%" }}>
          Reactivating the user will restore all of the users application
          access. Are you sure you want to activate this user?
        </div>
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => {
              setReactiveUser(false);
            }}
          />
          <ETButton
            variant={"primary"}
            buttonText="ACTIVATE"
            onClick={() => {
              handleUpdateUserStatus("restore", "");
              setReactiveUser(false);
            }}
          />
        </div>
      </CustomPopup>
      {/* delete user details */}
      <CustomPopup
        size="md"
        open={deleteUser}
        onHide={() => setDeleteUser(false)}
        title="Delete user details"
        title_color="black"
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Delete user details"}
      >
        <div className="p-4 light-font-text">
          We will remove all of {fullname} personal details. {fullname} updates
          and generated content will remain available anonymously.
        </div>
        <div className="pb-3 px-4 light-font-text">
          {fullname} name will be presented from now on as:
        </div>
        <Form>
          <Row className="pb-3 px-4">
            <Col lg={8} xl={8}>
              {/* <InputField type={"text"} placeholder={"Deleted user name"} /> */}
              <Controller
                {...register(`deleteUserName`)}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <InputField
                      type={"text"}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("deleteUserName");
                      }}
                      errorscope={true}
                      iserror={errors?.deleteUserName}
                      placeholder={"Delete user name (required)"}
                      id={"deleteUserName123"}
                    />
                  </div>
                )}
              />
            </Col>
          </Row>
        </Form>

        <div className="mb-4 px-4 light-font-text">
          Attention: This action is irreversible! You might prefer to keep the
          user archived instead.
        </div>

        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => {
              setDeleteUser(false);
            }}
          />
          <ETButton
            variant={"primary"}
            buttonText="DELETE USER"
            type={"submit"}
            onClick={() => {
              handleUpdateUserStatus("delete", deleteUserNameField);
              setDeleteUser(false);
              reset();
            }}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default ArchivedUsers;

const inactiveUsersData = [
  {
    id: 1,
    no: "9999",
    fname: "Johannes Nymark",
    lname: "Svensson",
    roles: "Compliance Manager",
    division: "Juliliving",
    joined: "30 Sep, 2021",
    last_active: "08 Dec, 2023",
    deactivated_date: "12 Dec, 2023",
  },
  {
    id: 2,
    no: "999999",
    fname: "Nymark",
    lname: "Svensson",
    roles: "Compliance Manager Manager",
    division: "Juliliving Juliliving",
    joined: "20 Sep, 2021",
    last_active: "18 Dec, 2023",
    deactivated_date: "21 Dec, 2023",
  },
];
