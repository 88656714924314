import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import ETButton from "../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../Components/CustomPopup";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import FilterComponent from "../../../../reactdataTableComponent/FilterComponent";
import Toggle from "../../../../Components/Switch/Switch";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { useForm, Controller } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useGetActorsByActorId,
  useGetActorsBySearch,
  usePostAddActor,
  usePostCreatereservation,
  usePutUpdateActor,
} from "../../../../hooks/queries/useActorQueries";
import {
  useGetStreetDetails,
  useGetZipcodeByCountry,
} from "../../../../hooks/queries/useGeographyQueries";
import { useGetGeographyCountry } from "../../../../hooks/queries/usePropertyQueries";
import ReservationFormTwo from "./ReservationFormTwo";
import moment from "moment";
import { toast } from "react-toastify";
import PasswordField from "../../../../Components/GroupText/PasswordField";
import { useUnitOverview } from "../../../../hooks/stores/useUnitStore";
import { usePropertyOverview } from "../../../../hooks/stores/usePropertyStore";
import Menus from "../../../../Components/Menus/Menus";
import { H3, H5, StyledCard } from "../../../../styles/Common.style";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import InputField from "../../../../Components/Textfield/InputField";

const ReservationSearchableForm = ({
  setReservationWizard,
  propId,
  setTenantName,
  unitId,
}) => {
  //-------------validation---------------//
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({});

  //---------------------------states---------------------//
  const [cancelPopup, setCancelPopup] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [resultDiv, setResultDiv] = useState(false);
  const [searchdiv, setSearchDiv] = useState(true);
  const [addCustomer, setAddCustomer] = useState("");

  const [actorId, setActorId] = useState(""); //ActorId is only used for editing customer & managing condition for add customer popup
  const [isSearchStreetVisible, setIsSearchStreetVisible] = useState(false);
  const [searchedStreetName, setSearchedStreetName] = useState("");

  //-----------------toggle button states-----------------//
  const [sendReservInfoToCustomerActive, setSendReservInfoToCustomerActive] =
    useState(true);
  const [sendScreeningToCustomerActive, setSendScreeningToCustomerActive] =
    useState(true);
  const [is2FaEnabled, setIs2FaEnabled] = useState(true);

  //-------------------checkbox states------------------//
  const [isPrimary, setIsPrimary] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [isCoResident, setIsCoResident] = useState(false);
  const [isSubTenant, setIsSubTenant] = useState(false);
  const [isGuardian, setIsGuardian] = useState(false);
  const [isInCompetent, setIsInCompetent] = useState(false);
  const [isSigningContract, setIsSigningContract] = useState(false);

  const [reservationDetailPage, setReservationDetailPage] = useState(false);
  const [compareCustomer, setCompareCustomer] = useState(false);

  const [existingActorDetailsData, setExistingActorDetailsData] = useState([]);

  const [customerDetailsData, setCustomerDetailsData] = useState([]);

  const [isSsnExist, setIsSsnExist] = useState(false);

  const [customerCardData, setCustomerCardData] = useState([]);
  const [openDeleteActorPopup, setOpenDeleteActorPopup] = useState({
    actor_id: "",
    deleteActorPopup: false,
  });

  const [postAddActorIsLoading, setPostAddActorIsLoading] = useState(false);

  //-----------------------------Zustand---------------------------------//
  const unitOverview = useUnitOverview((state) => state.unitOverview);
  const propOverview = usePropertyOverview((state) => state.propOverview);

  //-----------------------------react query------------------------------//
  const { data: countryData } = useGetGeographyCountry();
  const { data: GetActorsBySearch } = useGetActorsBySearch(searchText);
  const { data: zipCodeByCountryIdData } = useGetZipcodeByCountry(
    watch("country")?.value
  );
  const {
    mutateAsync: PostActorCreateReservation,
    isLoading: PostActorCreateReservationIsLoading,
  } = usePostCreatereservation();
  const { data: getActorByActorIdData } = useGetActorsByActorId(actorId);
  const { mutateAsync: PostAddActor, isLoading: PostAddActorIsLoading } =
    usePostAddActor();
  const { mutateAsync: PutUpdateActor, isLoading: PutUpdateActorIsLoading } =
    usePutUpdateActor();
  const { data: getStreetDetailsData } =
    useGetStreetDetails(searchedStreetName);

  //--------------------handle select actor from search-------------------//
  const allSelectedData = (data) => {
    clearErrors();
    setSearchText("");
    setValue("firstname", data?.firstname);
    setValue("lastname", data?.lastname);
    setValue("email", data?.email);
    setValue("birthdate", data?.birthdate);
    setValue("mobilenumber", data?.phone_number);
    setValue("floor_no", data?.floor_no);
    setValue("house_number", data?.house_number);
    setValue("street", data?.street_name);
    setValue("side_door", data?.side_door);
    setValue("country", {
      value: data?.country_id,
      label: data?.country,
    });
    setValue("city", data?.city);
    setValue("countryCode", {
      value: data?.country_code,
      label: data?.country_code,
    });
    setValue("zipCode", {
      value: data?.zipCode,
      label: data?.city,
    });
    setValue("social_security_number", data?.social_security_number);
    setIs2FaEnabled(data?.is_mobilenumber_validated === 1 ? true : false);
  };

  //-------To alert user that data might be lost if he/she reloads the page----------//
  // Note for developer- If you make changes in file make sure to refresh the page to see the actual changes as the below code prevents page from reloading //
  window.onbeforeunload = (event) => {
    if (window.location.pathname?.includes("create-new-reservation")) {
      const e = event || window.event;
      // Cancel the event
      e.preventDefault();
      if (e) {
        e.returnValue = ""; // Legacy method for cross browser support
      }
      return ""; // Legacy method for cross browser support
    }
  };
  useEffect(() => {
    if (actorId !== "") {
      setValue("firstname", getActorByActorIdData?.data?.[0]?.firstname);
      setValue("lastname", getActorByActorIdData?.data?.[0]?.lastname);
      setValue("email", getActorByActorIdData?.data?.[0]?.email);
      setValue("birthdate", getActorByActorIdData?.data?.[0]?.birthdate);
      setValue("mobilenumber", getActorByActorIdData?.data?.[0]?.phone_number);
      setValue("floor_no", getActorByActorIdData?.data?.[0]?.floor_no);
      setValue("house_number", getActorByActorIdData?.data?.[0]?.house_number);
      setValue("street", getActorByActorIdData?.data?.[0]?.street_name);
      setValue("side_door", getActorByActorIdData?.data?.[0]?.side_door);
      setValue("country", {
        value: getActorByActorIdData?.data?.[0]?.country_id,
        label: getActorByActorIdData?.data?.[0]?.country,
      });
      setValue("city", getActorByActorIdData?.data?.[0]?.city);
      setValue("countryCode", {
        value: getActorByActorIdData?.data?.[0]?.country_code,
        label: getActorByActorIdData?.data?.[0]?.country_code,
      });
      setValue("zipCode", {
        value: getActorByActorIdData?.data?.[0]?.zipCode,
        label: getActorByActorIdData?.data?.[0]?.city,
      });
      setValue(
        "social_security_number",
        getActorByActorIdData?.data?.[0]?.social_security_number
      );
      setValue(
        "guardian",
        customerCardData?.filter((item) => item?.actor_id === actorId)?.[0]
          ?.guardian
      );
      setIs2FaEnabled(
        getActorByActorIdData?.data?.[0]?.is_mobilenumber_validated === 1
          ? true
          : false
      );
      setSendScreeningToCustomerActive(
        getActorByActorIdData?.data?.[0]
          ?.send_screening_invitation_to_customer === 1
          ? true
          : false
      );
      setSendReservInfoToCustomerActive(
        getActorByActorIdData?.data?.[0]?.send_reservation_to_customer === 1
          ? true
          : false
      );
      setIs2FaEnabled(
        getActorByActorIdData?.data?.[0]?.is_mobilenumber_validated === 1
          ? true
          : false
      );
      const editedActorData = customerCardData?.filter(
        (item) => item?.actor_id === actorId
      )?.[0];
      setIsPrimary(
        customerCardData?.filter((item) => item?.actor_id === actorId)?.[0]
          ?.is_primary === "1"
          ? true
          : false
      );
      setIsPaying(
        customerCardData?.filter((item) => item?.actor_id === actorId)?.[0]
          ?.is_paying === "1"
          ? true
          : false
      );
      setIsCoResident(
        customerCardData?.filter((item) => item?.actor_id === actorId)?.[0]
          ?.is_coresident === "1"
          ? true
          : false
      );
      setIsSubTenant(
        customerCardData?.filter((item) => item?.actor_id === actorId)?.[0]
          ?.is_subtenant === "1"
          ? true
          : false
      );
      setIsGuardian(
        customerCardData?.filter((item) => item?.actor_id === actorId)?.[0]
          ?.is_guardian === "1"
          ? true
          : false
      );
      setIsInCompetent(
        customerCardData?.filter((item) => item?.actor_id === actorId)?.[0]
          ?.is_incompetent === "1"
          ? true
          : false
      );
      setIsSigningContract(
        customerCardData?.filter((item) => item?.actor_id === actorId)?.[0]
          ?.is_signing_contract === "1"
          ? true
          : false
      );
      setCustomerDetailsData(
        customerCardData?.filter((item) => item?.actor_id === actorId)?.[0]
      );
    }
    return () => {};
  }, [getActorByActorIdData]);

  //--------------------handle add actor-----------------------//
  const handleAddActor = (data) => {
    // setPostAddActorIsLoading(true);
    if (actorId === "") {
      setCustomerDetailsData(data);
      const addActorPayload = {
        email: data?.email,
        firstname: data?.firstname,
        mobilenumber: data?.mobilenumber,
        lastname: data?.lastname,
        birthdate: data?.birthdate,
        country_id_nationality: data?.country?.value,
        phone_calling_code_id: data?.countryCode?.label,
        street_name: data?.street,
        house_number: data?.house_number,
        side_door: data?.side_door,
        country_id: data?.country?.value,
        zipcode_id: data?.zipCode?.value,
        social_security_number: data?.social_security_number,
        floor_no: data?.floor_no,
        send_reservation: sendReservInfoToCustomerActive ? "1" : "0",
        screening_invitation: sendScreeningToCustomerActive ? "1" : "0",
        is_mobilenumber_validated: is2FaEnabled ? "1" : "0",
        force_insert: "0",
        contract_id: "0",
        is_primary: isPrimary ? "1" : "0",
        is_paying: isPaying ? "1" : "0",
        is_coresident: isCoResident ? "1" : "0",
        is_subtenant: isSubTenant ? "1" : "0",
        is_guardian: isGuardian ? "1" : "0",
        guardian_for_actor_id: data?.guardian?.value || "0",
        is_incompetent: isInCompetent ? "1" : "0",
        is_signing_contract: isSigningContract ? "1" : "0",
        language_id: "en",
      };
      PostAddActor(addActorPayload)
        .then((response) => {
          if (response.data.bReturn) {
            // setReservationDetailPage(true);
            // setPostAddActorIsLoading(false);
            toast.success(response.data.message);
            setCustomerCardData([
              ...customerCardData,
              {
                ...data,
                actor_id: response?.data?.data?.actor_details?.actor_id,
                is_primary: isPrimary ? "1" : "0",
                is_paying: isPaying ? "1" : "0",
                is_coresident: isCoResident ? "1" : "0",
                is_subtenant: isSubTenant ? "1" : "0",
                is_guardian: isGuardian ? "1" : "0",
                guardian_for_actor_id: data?.guardian?.value || "0",
                is_incompetent: isInCompetent ? "1" : "0",
                is_signing_contract: isSigningContract ? "1" : "0",
              },
            ]);

            setAddCustomer(false);
            addCustomerFormReset();
          } else {
            // setPostAddActorIsLoading(false);

            if (response?.data?.ssn_exist === 1) {
              setIsSsnExist(true);
              // setPostAddActorIsLoading(false);
            } else {
              setIsSsnExist(false);
              // setPostAddActorIsLoading(false);
            }
            setAddCustomer(false);
            setCompareCustomer(true);
            setExistingActorDetailsData(response?.data?.data?.actor_details);
          }
        })
        .catch((error) => {
          toast.error(error.message);
          // setPostAddActorIsLoading(false);
        });
    } else {
      const updateActorPayload = {
        actor_id: actorId,
        email: data?.email,
        firstname: data?.firstname,
        mobilenumber: data?.mobilenumber,
        lastname: data?.lastname,
        birthdate: data?.birthdate,
        country_id_nationality: data?.country?.value,
        phone_calling_code_id: data?.countryCode?.label,
        street_name: data?.street,
        house_number: data?.house_number,
        floor_no: data?.floor_no,
        side_door: data?.side_door,
        country_id: data?.country?.value,
        zipcode_id: data?.zipCode?.value,
        social_security_number: data?.social_security_number,
        send_reservation: sendReservInfoToCustomerActive ? "1" : "0",
        screening_invitation: sendScreeningToCustomerActive ? "1" : "0",
        is_mobilenumber_validated: is2FaEnabled ? "1" : "0",
        contract_id: "0",
        is_primary: isPrimary ? "1" : "0",
        is_paying: isPaying ? "1" : "0",
        is_coresident: isCoResident ? "1" : "0",
        is_subtenant: isSubTenant ? "1" : "0",
        is_guardian: isGuardian ? "1" : "0",
        guardian_for_actor_id: data?.guardian?.value || "0",
        is_incompetent: isInCompetent ? "1" : "0",
        is_signing_contract: isSigningContract ? "1" : "0",
        language_id: "en",
      };
      PutUpdateActor(updateActorPayload)
        .then((response) => {
          if (response.data.bReturn) {
            toast.success(response.data.message);
            setCompareCustomer(false);
            // setCustomerCardData([
            //   ...customerCardData,
            //   {
            //     ...data,
            //     actor_id: actorId,
            //     is_primary: isPrimary ? "1" : "0",
            //     is_paying: isPaying ? "1" : "0",
            //     is_coresident: isCoResident ? "1" : "0",
            //     is_subtenant: isSubTenant ? "1" : "0",
            //     is_guardian: isGuardian ? "1" : "0",
            //     guardian_for_actor_id: "0",
            //     is_incompetent: isInCompetent ? "1" : "0",
            //     is_signing_contract: isSigningContract ? "1" : "0",
            //   },
            // ]);
            setCustomerCardData((prev) =>
              prev.map((val) => {
                if (val.actor_id === actorId) {
                  return {
                    ...val,
                    ...data,
                    is_primary: isPrimary ? "1" : "0",
                    is_paying: isPaying ? "1" : "0",
                    is_coresident: isCoResident ? "1" : "0",
                    is_subtenant: isSubTenant ? "1" : "0",
                    is_guardian: isGuardian ? "1" : "0",
                    guardian_for_actor_id: data?.guardian?.value || "0",
                    is_incompetent: isInCompetent ? "1" : "0",
                    is_signing_contract: isSigningContract ? "1" : "0",
                  };
                }

                return val;
              })
            );
            // setPostAddActorIsLoading(false);
            setAddCustomer(false);
            addCustomerFormReset();
          } else {
            toast.error(response.data.message);
            // setPostAddActorIsLoading(false);
          }
        })
        .catch((error) => {
          toast.error(error.message);
          // setPostAddActorIsLoading(false);
        });
    }
  };

  //--------------------handle do not use existing customer/ actor---------------------//
  const handleDoNotUseExistingCustomer = () => {
    // setPostAddActorIsLoading(true);
    const addActorPayload = {
      email: customerDetailsData?.email,
      firstname: customerDetailsData?.firstname,
      mobilenumber: customerDetailsData?.mobilenumber,
      lastname: customerDetailsData?.lastname,
      birthdate: customerDetailsData?.birthdate,
      country_id_nationality: customerDetailsData?.country?.value,
      phone_calling_code_id: customerDetailsData?.countryCode?.label,
      street_name: customerDetailsData?.street,
      house_number: customerDetailsData?.house_number,
      side_door: customerDetailsData?.side_door,
      country_id: customerDetailsData?.country?.value,
      zipcode_id: customerDetailsData?.zipCode?.value,
      social_security_number: customerDetailsData?.social_security_number,
      floor_no: customerDetailsData?.floor_no,
      send_reservation: sendReservInfoToCustomerActive ? "1" : "0",
      screening_invitation: sendScreeningToCustomerActive ? "1" : "0",
      is_mobilenumber_validated: is2FaEnabled ? "1" : "0",
      force_insert: "1",
      contract_id: "0",
      is_primary: isPrimary ? "1" : "0",
      is_paying: isPaying ? "1" : "0",
      is_coresident: isCoResident ? "1" : "0",
      is_subtenant: isSubTenant ? "1" : "0",
      is_guardian: isGuardian ? "1" : "0",
      guardian_for_actor_id: customerDetailsData?.guardian?.value || "0",
      is_incompetent: isInCompetent ? "1" : "0",
      is_incompetent: isSigningContract ? "1" : "0",
    };
    PostAddActor(addActorPayload)
      .then((response) => {
        if (response.data.bReturn) {
          toast.success(response.data.message);
          // setPostAddActorIsLoading(false);
          setCompareCustomer(false);
          setCustomerCardData([
            ...customerCardData,
            {
              ...customerDetailsData,
              actor_id: response?.data?.data?.actor_details?.actor_id,
              is_primary: isPrimary ? "1" : "0",
              is_paying: isPaying ? "1" : "0",
              is_coresident: isCoResident ? "1" : "0",
              is_subtenant: isSubTenant ? "1" : "0",
              is_guardian: isGuardian ? "1" : "0",
              guardian_for_actor_id:
                customerDetailsData?.guardian?.value || "0",
              is_incompetent: isInCompetent ? "1" : "0",
              is_signing_contract: isSigningContract ? "1" : "0",
            },
          ]);
          setAddCustomer(false);
          addCustomerFormReset();
        } else {
          toast.error(response.data.message);
          // setPostAddActorIsLoading(false);
        }
      })
      .catch((error) => toast.error(error.message));
  };

  //---------------------------handle update actor-------------------------//
  const handleUpdateAndUse = () => {
    // setPostAddActorIsLoading(true);

    const updateActorPayload = {
      actor_id:
        actorId === ""
          ? existingActorDetailsData?.actor_id?.toString()
          : actorId,
      email: customerDetailsData?.email,
      firstname: customerDetailsData?.firstname,
      mobilenumber: customerDetailsData?.mobilenumber,
      lastname: customerDetailsData?.lastname,
      birthdate: customerDetailsData?.birthdate,
      country_id_nationality: customerDetailsData?.country?.value,
      phone_calling_code_id: customerDetailsData?.countryCode?.label,
      street_name: customerDetailsData?.street,
      house_number: customerDetailsData?.house_number,
      floor_no: customerDetailsData?.floor_no,
      side_door: customerDetailsData?.side_door,
      country_id: customerDetailsData?.country?.value,
      zipcode_id: customerDetailsData?.zipCode?.value,
      social_security_number: customerDetailsData?.social_security_number,
      send_reservation: sendReservInfoToCustomerActive ? "1" : "0",
      screening_invitation: sendScreeningToCustomerActive ? "1" : "0",
      is_mobilenumber_validated: is2FaEnabled ? "1" : "0",
      contract_id: "0",
      is_primary: isPrimary ? "1" : "0",
      is_paying: isPaying ? "1" : "0",
      is_coresident: isCoResident ? "1" : "0",
      is_subtenant: isSubTenant ? "1" : "0",
      is_guardian: isGuardian ? "1" : "0",
      guardian_for_actor_id: customerDetailsData?.guardian?.value || "0",
      is_incompetent: isInCompetent ? "1" : "0",
      is_signing_contract: isSigningContract ? "1" : "0",
    };
    PutUpdateActor(updateActorPayload)
      .then((response) => {
        if (response.data.bReturn) {
          toast.success(response.data.message);
          setCompareCustomer(false);

          if (customerCardData?.length !== 0) {
            if (
              customerCardData?.filter(
                (item) =>
                  item?.actor_id ===
                  existingActorDetailsData?.actor_id?.toString()
              )?.length > 0
            ) {
              setCustomerCardData((prev) =>
                prev.map((val) => {
                  if (
                    val.actor_id ===
                    existingActorDetailsData?.actor_id?.toString()
                  ) {
                    return {
                      ...val,
                      ...customerDetailsData,
                      is_primary: isPrimary ? "1" : "0",
                      is_paying: isPaying ? "1" : "0",
                      is_coresident: isCoResident ? "1" : "0",
                      is_subtenant: isSubTenant ? "1" : "0",
                      is_guardian: isGuardian ? "1" : "0",
                      guardian_for_actor_id:
                        customerDetailsData?.guardian?.value || "0",
                      is_incompetent: isInCompetent ? "1" : "0",
                      is_signing_contract: isSigningContract ? "1" : "0",
                    };
                  }
                  return val;
                })
              );
            } else {
              setCustomerCardData([
                ...customerCardData,
                {
                  ...customerDetailsData,
                  actor_id: String(existingActorDetailsData?.actor_id),
                  is_primary: isPrimary ? "1" : "0",
                  is_paying: isPaying ? "1" : "0",
                  is_coresident: isCoResident ? "1" : "0",
                  is_subtenant: isSubTenant ? "1" : "0",
                  is_guardian: isGuardian ? "1" : "0",
                  guardian_for_actor_id:
                    customerDetailsData?.guardian?.value || "0",
                  is_incompetent: isInCompetent ? "1" : "0",
                  is_signing_contract: isSigningContract ? "1" : "0",
                },
              ]);
            }
          } else {
            setCustomerCardData([
              ...customerCardData,
              {
                ...customerDetailsData,
                actor_id: String(existingActorDetailsData?.actor_id),
                is_primary: isPrimary ? "1" : "0",
                is_paying: isPaying ? "1" : "0",
                is_coresident: isCoResident ? "1" : "0",
                is_subtenant: isSubTenant ? "1" : "0",
                is_guardian: isGuardian ? "1" : "0",
                guardian_for_actor_id:
                  customerDetailsData?.guardian?.value || "0",
                is_incompetent: isInCompetent ? "1" : "0",
                is_signing_contract: isSigningContract ? "1" : "0",
              },
            ]);
          }

          addCustomerFormReset();
        } else {
          setAddCustomer(true);
          setCompareCustomer(false);
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        // setPostAddActorIsLoading(false);
      });
  };

  //--------------------------handle create reservation-------------------------//
  const handleCreateReservation = (data) => {
    const createReservationPayload = {
      unit_id: String(unitId),
      // reservation_from: data?.reservationStartdate,
      reservation_to: data?.reservationExpireddate,
      responsible_agent: String(data?.managedBy?.value),
      moving_in_date: data?.moveIndate,
      offer_price: data?.listingPrice,
      payment_frequency: String(data?.paymentFrequency?.value),
      deal_type_id: data?.contractType?.value, // changes by neha
      termination_date: data?.expectedTerminationDate, // changes by neha
      ranking_no: "0", // changes by neha
      is_exclusive: "0", // changes by neha
      is_confirm_customer: "1", // changes by neha
      is_start_screening: "1", // changes by neha
      actors: customerCardData?.map((item) => ({
        actor_id: String(item?.actor_id),
        is_primary: item?.is_primary,
        is_paying: item?.is_paying,
        is_coresident: item?.is_coresident,
        is_subtenant: item?.is_subtenant,
        is_guardian: item?.is_guardian,
        guardian_for_actor_id: item?.guardian?.value || "0",
        is_incompetent: item?.is_incompetent,
        is_signing_contract: item?.is_signing_contract,
      })),
    };

    PostActorCreateReservation(createReservationPayload)
      .then((response) => {
        if (response.data.bReturn) {
          toast.success(response.data.message);
          setTenantName(customerCardData);
          setReservationWizard("ReservationConfirmation");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  //-----------------------handle actor search--------------------//
  const searchField = (e) => {
    setSearchText(e.target.value);
    if (searchText.length > 0) {
      setResultDiv(true);
      setSearchDiv(true);
    } else {
      setResultDiv(false);
    }
  };
  //-----------------------handle delete actor--------------------//
  const handleDeleteActor = () => {
    let newArray = [...customerCardData];
    const deleteIndex = customerCardData?.findIndex(
      (obj) => obj?.actor_id === openDeleteActorPopup?.actor_id
    );
    newArray?.splice(deleteIndex, 1);
    setCustomerCardData(newArray);
    setOpenDeleteActorPopup((prev) => ({ ...prev, deleteActorPopup: false }));
  };

  // --------------------------handle zipcode change-----------------------//
  const handleZipCode = (e, ActionType) => {
    if (ActionType.action === "clear") {
      setValue("city", "");
    } else {
      if (e?.value != "" || e?.value != "undefined") {
        const HandleCityName = zipCodeByCountryIdData?.data?.filter(
          (data) => data?.Id == e?.value
        );
        const CityData = HandleCityName[0].Text;
        setValue("city", CityData, { shouldValidate: true });
      }
    }
  };

  //---------------------add customer form reset function---------------------//
  function addCustomerFormReset() {
    reset();
    setIsPrimary(false);
    setIsPaying(false);
    setIsCoResident(false);
    setIsSubTenant(false);
    setIsGuardian(false);
    setIsInCompetent(false);
    setIsSigningContract(false);
    setSearchText("");
    setActorId("");
  }

  //--------------------------handle next (go to reservation)-----------------------//
  const handleGoToReservationPage = () => {
    if (customerCardData?.length === 1) {
      if (
        customerCardData?.some(
          (item) =>
            item?.is_primary === "1" &&
            item?.is_paying === "1" &&
            item?.is_signing_contract === "1"
        )
      ) {
        setReservationDetailPage(true);
      } else {
        toast.info("There should be atleast one primary & one paying customer");
      }
    } else {
      if (
        customerCardData?.some((item) => item?.is_primary === "1") &&
        customerCardData?.some((item) => item?.is_paying === "1")
      ) {
        setReservationDetailPage(true);
      } else {
        toast.info("There should be atleast one primary & paying customer");
      }
    }
  };

  const displayItem = (selected) => {
    const countyName = countryData?.data?.filter(
      (item, i) => item.Id === selected?.value
    )?.[0]?.Text;

    return {
      value: selected?.Id,
      label: (
        <div>
          <div className="fs-14 fw-300">
            {
              countryData?.data?.filter(
                (item, i) => item.Id === selected?.value
              )?.[0]?.Text
            }
          </div>
        </div>
      ),
    };
  };

  // For Filter Street Name
  const handleStreetDetailChange = (e) => {
    if (watch("country")?.value == "DK" && e.target.value?.length >= 4) {
      setIsSearchStreetVisible(true);
      setSearchedStreetName(e.target.value);
    } else {
      setIsSearchStreetVisible(false);
    }
  };

  //When user selects street details from dorpdown
  const handleStreetDetailClick = (item) => {
    setIsSearchStreetVisible(false);
    setValue("street", item?.street_name);
    setValue("city", item?.city, { shouldValidate: item?.city !== "" && true });
    setValue("house_number", item?.house_number, {
      shouldValidate: item?.house_number !== "" && true,
    });
    setValue(
      "zipCode",
      item?.zipcode == ""
        ? ""
        : {
            value: item?.zipcode,
            label: zipCodeByCountryIdData?.data?.filter(
              (el) => el.Id == item?.zipcode
            )?.[0]?.Text,
          },
      { shouldValidate: item?.zipcode !== "" && true }
    );
  };

  const CustomerCard = ({
    data,
    setOpenDeleteActorPopup,
    setActorId,
    setAddCustomer,
    zipCodeByCountryIdData,
  }) => {
    return (
      <>
        <div
          className="actor-customer-card radius-8 align-items-center"
          style={{ border: "1px solid #F0F0F0" }}
        >
          <div className="p-3 light-font-text" style={{ position: "relative" }}>
            <div className="mb-2">
              <span className="form-label-title text-break">
                #{data?.actor_id}
              </span>
            </div>
            <Row>
              <Col>
                <div className="mb-2">
                  <p className="mb-0 form-label-title">First name</p>
                  <span className=" text-break">{data?.firstname || "-"}</span>
                </div>
              </Col>
              <Col>
                <div className="mb-2">
                  <p className="mb-0 form-label-title">Last name</p>
                  <span className=" text-break">{data?.lastname || "-"}</span>
                </div>
              </Col>
            </Row>
            <div className="mb-2">
              <p className="mb-0 form-label-title">Email</p>
              <span className=" text-break">{data?.email || "-"}</span>
            </div>
            <div className="mb-2">
              <p className="mb-0 form-label-title">Phone</p>
              <span className=" text-break">
                {data?.countryCode?.label} {data?.mobilenumber}
              </span>
            </div>
            <div className="mb-2">
              <p className="mb-0 form-label-title">DOB</p>
              <span className=" text-break">
                {data?.birthdate === ""
                  ? "-"
                  : moment(data?.birthdate).format("DD MMM, YYYY")}
              </span>
            </div>
            <div className="mb-1">
              <p className="mb-0 form-label-title">Address</p>
              <div
                style={{
                  textOverflow: "ellipsis",
                  textWrap: "nowrap",
                  overflow: "hidden",
                }}
              >
                <span className=" text-break">
                  {data?.street} {data?.house_number}&nbsp;
                </span>
                <span className=" text-break">
                  {data?.zipCode?.value} {data?.city}
                  {/* {
                  zipCodeByCountryIdData?.data?.filter(
                    (el) => el.Id == data?.zipCode?.value
                    )?.[0]?.Text
                  } */}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="mb-2 ">
                <p className="mb-0 form-label-title">SSN</p>
                <span className=" text-break">
                  {data?.social_security_number === ""
                    ? "-"
                    : data?.social_security_number}
                </span>
              </div>
              <div className="d-flex align-items-center">
                <Menus
                  itemheight={24}
                  itemwidth={24}
                  iconborderradius="4px"
                  component={
                    <div style={{ fontSize: "14px" }}>
                      <div
                        className="addpropContent px-3 py-2 "
                        onClick={() => {
                          setActorId(data?.actor_id);
                          setAddCustomer(true);
                        }}
                      >
                        <span className="ms-1">Edit</span>
                      </div>
                      <hr className="m-0"></hr>
                      <div
                        className="addpropContent px-3 pt-2 pb-2"
                        onClick={() =>
                          setOpenDeleteActorPopup((prev) => ({
                            ...prev,
                            deleteActorPopup: true,
                            actor_id: data?.actor_id,
                          }))
                        }
                      >
                        <span className="ms-1">Delete</span>
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {/* {showNextForm == false?   */}
      {!reservationDetailPage ? (
        <div
          className="p-4 mt-4 radius-8 containerStyleColor"
          // style={{ backgroundColor: "#F1F5F0" }}
        >
          <div className="radius-8 " style={{ backgroundColor: "#FFFFFF" }}>
            <div
              className=" p-4 d-sm-flex justify-content-between "
              style={{ borderBottom: "1px solid #F4F5F5" }}
            >
              <div className="d-flex justify-content-center align-items-center">
                <H3 className="me-2 ">Customer details</H3>

                <CustomToolTip size="sm" text="Customer details" />
              </div>
              <div
                style={{ fontSize: "14px", fontWeight: "300", color: "#888" }}
              >
                {propOverview?.[0]?.propertyName} | {unitOverview?.[0]?.Address}
              </div>
            </div>
            <Row className="p-4">
              {/* signing order card component */}

              {customerCardData?.map((item, index) => (
                <Col className="mb-4" lg={6} xl={4} key={index}>
                  <CustomerCard
                    key={index}
                    data={item}
                    setOpenDeleteActorPopup={setOpenDeleteActorPopup}
                    setActorId={setActorId}
                    setAddCustomer={setAddCustomer}
                    zipCodeByCountryIdData={zipCodeByCountryIdData}
                  />
                </Col>
              ))}

              {/* actor-customer-create-package-card */}
              <Col lg={6} xl={4} className="mb-4">
                <StyledCard height="350px">
                  <ETButton
                    variant={"primary"}
                    buttonText="ADD CUSTOMER"
                    icon={true}
                    width="fit-content"
                    onClick={() => {
                      setAddCustomer(true);
                      addCustomerFormReset();
                    }}
                  />
                </StyledCard>
              </Col>
            </Row>
            <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-between footer-section-button-container">
              <ETButton
                variant={"white"}
                buttonText="CANCEL"
                onClick={() => {
                  setCancelPopup(true);
                }}
              />

              {customerCardData?.length !== 0 && (
                <ETButton
                  variant={"primary"}
                  buttonText="NEXT"
                  onClick={() => {
                    handleGoToReservationPage();
                    // if (
                    //   customerCardData?.some(
                    //     (item) =>
                    //       (item?.is_paying === "1" &&
                    //         item?.is_primary === "0") ||
                    //       (item?.is_paying === "0" &&
                    //         item?.is_primary === "1") ||
                    //       (item?.is_paying === "1" &&
                    //         item?.is_primary === "1" &&
                    //         item?.is_primary === "1")
                    //   )
                    // ) {
                    //   setReservationDetailPage(true);
                    // } else {
                    //   toast.info(
                    //     "Atleast one customer should be primary & paying"
                    //   );
                    // }
                  }}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <ReservationFormTwo
          handleCreateReservation={handleCreateReservation}
          propId={propId}
          unitId={unitId}
          setReservationWizard={setReservationWizard}
          setReservationDetailPage={setReservationDetailPage}
          PostActorCreateReservationIsLoading={
            PostActorCreateReservationIsLoading
          }
        />
      )}

      {/* ------------------------------------Add customer popup form---------------------------------- */}
      <CustomPopup
        open={addCustomer}
        onHide={() => {
          setAddCustomer(false);
          addCustomerFormReset();
        }}
        headerRequired={false}
        size="lg"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="px-0 bg-white radius-8 ">
          <div
            className="py-3 px-4 d-sm-flex justify-content-between "
            style={{ borderBottom: "1px solid #F4F5F5" }}
          >
            <div className="d-flex justify-content-center align-items-center">
              <H3 className="me-2">Customer details</H3>

              <CustomToolTip size="sm" text="Customer details" />
            </div>
            <div style={{ fontSize: "14px", fontWeight: "300", color: "#888" }}>
              {propOverview?.[0]?.propertyName} | {unitOverview?.[0]?.Address}
            </div>
          </div>
          {actorId === "" && ( //actorId is generated only when clicked on edit
            <>
              <Row className="px-4 mt-3">
                <Col lg={8} className="fs-14 ">
                  <div className=" form-label-title ">
                    Search among existing customer
                  </div>
                  <div className="fw-300 grey-text">
                    If you think the customer may already be registered in the
                    system, maybe as a previous tenant, an visitor at a event or
                    as a lead from a campaign, you can find the customer by
                    searching below
                  </div>
                </Col>
              </Row>

              <Row className="px-4 pt-3 pb-4">
                <Col
                  lg={8}
                  sm={12}
                  style={{
                    position: " relative",
                  }}
                >
                  <div
                    style={{
                      position: " relative",
                    }}
                  >
                    <FilterComponent
                      filtertext={searchText}
                      placeholder="123"
                      handleSearch={(e) => searchField(e)}
                      lableText="E.g. name, email, mobile number, social security number"
                    />
                  </div>

                  {searchText.length > 0 && searchdiv == true && (
                    <div style={{}}>
                      <div
                        className="bg-white radius-8 pe-1"
                        style={{
                          border: "1px solid #D1D1D1",
                          position: "absolute",
                          top: "50px",
                          left: "14px",
                          zIndex: "10",
                          width: "97%",
                        }}
                      >
                        {searchText.length < 2 && (
                          <div className="fs-14 fw-300 grey-text py-2 px-3">
                            Type in minimum 2 characters to get a search result
                          </div>
                        )}
                        {searchText.length >= 2 && (
                          <>
                            {GetActorsBySearch?.data?.length > 0 ? (
                              <div
                                className="scrollable-container-y"
                                style={{
                                  height: "400px",
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                }}
                              >
                                {GetActorsBySearch?.data?.map((data, index) => (
                                  <div key={index}>
                                    <Row
                                      className="fs-14 fw-300 pt-2 ps-3 pe-1 addpropContent"
                                      style={{
                                        borderBottom: "1px solid #D1D1D1",
                                      }}
                                      onClick={() => {
                                        allSelectedData(data);
                                        setSearchDiv(false);
                                      }}
                                    >
                                      <Col md={5}>
                                        <div className="d-flex flex-column my-2">
                                          <span className="fw-600">
                                            {data?.firstname} {data?.lastname}
                                          </span>{" "}
                                          {/* <span>{data?.social_security_number}</span> */}
                                        </div>
                                        <div className="d-flex flex-column my-2">
                                          <span>{data?.email}</span>{" "}
                                          <span>{data?.phone_number}</span>
                                        </div>
                                        <div className="d-flex flex-column my-2">
                                          <span>
                                            {data?.birthdate == ""
                                              ? "-"
                                              : moment(data?.birthdate).format(
                                                  "DD MMM, YYYY"
                                                )}
                                          </span>{" "}
                                          <span>
                                            {data?.social_security_number}
                                          </span>{" "}
                                        </div>
                                        <div className="d-flex flex-column my-2">
                                          <span>
                                            {data?.street_name +
                                              " " +
                                              data?.house_number}
                                          </span>
                                          <span>
                                            {data?.zipCode + " " + data?.city}
                                          </span>
                                        </div>
                                      </Col>
                                      <Col md={7}>
                                        <div className="fw-600 mt-2 ">
                                          {/* Known activities */}Lorem Ipsum
                                        </div>
                                        <div className="d-flex flex-column mb-3 fs-12">
                                          <span>
                                            {/* Private viewing at Terrasserne 26
                                          Apr, 2023 */}
                                            Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit,
                                          </span>
                                          <span>
                                            {/* Open House at Terrasserne 23 Apr,
                                          2023 */}
                                            Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit,
                                          </span>
                                          <span>
                                            {/* Open House at Blomsterbjerget 16
                                          Mar, 2023 */}
                                            Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit,
                                          </span>
                                          <span>Lead at 12 Mar, 2023</span>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className="fs-14 fw-300 py-2 px-3">
                                Sorry, it was not possible to find a match among
                                existing customers. Please fill in the form
                                below to create a new customer and complete the
                                reservation.
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <hr className="hr mt-1 mb-0"></hr>
            </>
          )}
          <Form
            autoComplete="off"
            onSubmit={handleSubmit(handleAddActor)}
            onClick={() => setIsSearchStreetVisible(false)}
          >
            <div
              className="scrollable-container-y"
              style={{ height: "400px", overflow: "scroll" }}
            >
              <div className="fs-14 py-4 px-4 ms-1  form-label-title">
                Customer information
              </div>
              <Row className="mx-3">
                <Col lg={5} md={6} className="pe-lg-0 pb-3">
                  <Controller
                    control={control}
                    {...register("firstname")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div className="form-input ">
                        {/* <input
                          type="text"
                          placeholder="First name (required)"
                          id="firstname"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("firstname");
                          }}
                        /> */}
                        <InputField
                          id={"firstname"}
                          value={value}
                          type={"text"}
                          placeholder={"First name (required)"}
                          errorscope
                          iserror={errors?.firstname}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("firstname");
                          }}
                        />
                        {/* <label for="firstname">First name (required)</label> */}
                      </div>
                    )}
                  />
                  {/* {errors?.firstname && (
                    <p className="error-msg">This field is required</p>
                  )} */}
                </Col>
                <Col lg={5} md={6} className="pe-lg-0 pb-3">
                  <Controller
                    control={control}
                    {...register("lastname")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <div className="form-input ">
                        <input
                          type="text"
                          placeholder="Last name"
                          id="lastname"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("lastname");
                          }}
                        />
                        <label for="lastname">Last name</label>
                      </div>
                    )}
                  />
                  {errors?.lastname && (
                    <p className="error-msg">This field is required</p>
                  )}
                </Col>
              </Row>

              <Row className="mx-3">
                <Col lg={5} md={6} className="pe-lg-0 pb-3">
                  <Controller
                    control={control}
                    {...register("email")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        {/* <input
                          type="email"
                          placeholder="Email (required)"
                          id="Email"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("email");
                          }}
                        /> */}
                        <InputField
                          id={"Email"}
                          value={value}
                          type={"email"}
                          placeholder={"Email (required)"}
                          errorscope
                          iserror={errors?.email}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("email");
                          }}
                        />
                        {/* <label for="Email">Email (required)</label> */}
                      </div>
                    )}
                  />
                  {/* {errors?.email && (
                    <p className="error-msg">This field is required</p>
                  )} */}
                </Col>
                <Col lg={5} md={6} className="pe-lg-0 pb-3">
                  <Controller
                    control={control}
                    {...register("birthdate")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <div className="form-input ">
                        <input
                          type="date"
                          placeholder="DOB"
                          id="birthdate"
                          value={value}
                          max={moment(new Date()).format("YYYY-MM-DD")}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("birthdate");
                          }}
                        />
                        <label for="birthdate">Date of birth</label>
                      </div>
                    )}
                  />
                  {errors?.birthdate && (
                    <p className="error-msg">This field is required</p>
                  )}
                </Col>
              </Row>

              <Row className="mx-3">
                <Col lg={10}>
                  <Row>
                    <Col md={6} lg={3} className="pe-lg-0 pb-3">
                      <Controller
                        control={control}
                        {...register("countryCode")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <ReactSelect
                            placeholder={"Country code"}
                            iserror={errors?.countryCode}
                            options={countryData?.data?.map((item) => ({
                              value: item?.Id,
                              label: item?.DataValue2,
                            }))}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("countryCode");
                            }}
                          />
                        )}
                      />
                      {/* {errors.countryCode && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                    </Col>
                    <Col md={6} lg={9} className="pe-lg-0 pb-3">
                      <Controller
                        control={control}
                        {...register("mobilenumber")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            {/* <input
                              type="number"
                              placeholder="Phone"
                              id="Phone"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("mobilenumber");
                              }}
                              maxlength={15}
                            /> */}
                            <InputField
                              id={"Phone"}
                              value={value}
                              maxLength={15}
                              type={"number"}
                              placeholder={"Phone"}
                              errorscope
                              iserror={errors?.mobilenumber}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("mobilenumber");
                              }}
                            />
                            {/* <label for="Phone">Phone</label> */}
                          </div>
                        )}
                      />
                      {/* {errors?.mobilenumber && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mx-3">
                <Col lg={10} className="pe-lg-0 pb-3">
                  <Controller
                    control={control}
                    {...register("social_security_number")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <PasswordField
                          id={"ssn"}
                          placeholder={"Social Security Number"}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("social_security_number");
                          }}
                          maxlength={15}
                        />
                      </>
                    )}
                  />
                  {errors?.social_security_number && (
                    <p className="error-msg">Too Many Characters</p>
                  )}
                </Col>
              </Row>

              <Row className="mx-3">
                <Col lg={10} className="pe-lg-0">
                  <Row style={{ position: "relative" }}>
                    <Col xl={4} lg={4} md={6} className="mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("street")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            {/* <input
                              type="text"
                              placeholder="Street"
                              id="street"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("street");
                                handleStreetDetailChange(e);
                              }}
                            /> */}
                            <InputField
                              id={"street"}
                              value={value}
                              maxLength={15}
                              type={"text"}
                              placeholder={"Street"}
                              errorscope
                              iserror={errors.street}
                              onChange={(e) => {
                                onChange(e);
                                handleStreetDetailChange(e);
                              }}
                            />
                            {/* <label for="street">Street </label> */}
                          </div>
                        )}
                      />
                      {/* {errors.street && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                      {watch("street")?.length < 4 &&
                      watch("street")?.length > 0 &&
                      watch("country")?.value == "DK" ? (
                        <div className="fs-12 fw-400 grey-text pt-1 ">
                          Type in minimum 4 characters to get a search result
                        </div>
                      ) : (
                        <>
                          {watch("country")?.value == "DK" &&
                            isSearchStreetVisible && (
                              <div>
                                <div
                                  className="bg-white radius-8 pe-1 filtered-list"
                                  style={{
                                    border: "1px solid #D1D1D1",
                                    position: "absolute",
                                    // top: "50px",
                                    // left: "14px",
                                    zIndex: "10",
                                    width: "31%",
                                  }}
                                >
                                  <>
                                    {getStreetDetailsData?.data?.length > 0 ? (
                                      <div>
                                        {getStreetDetailsData?.data?.map(
                                          (item, index) => (
                                            <React.Fragment key={index}>
                                              <div
                                                className="mt-1 fs-12"
                                                key={index}
                                                onClick={() => {
                                                  handleStreetDetailClick(item);
                                                }}
                                              >
                                                <span className="px-2">
                                                  {item?.city ||
                                                  item?.zipcode ||
                                                  item?.house_number ? (
                                                    <> {item?.address} </>
                                                  ) : (
                                                    <> {item?.street_name}</>
                                                  )}
                                                </span>
                                              </div>
                                              <hr className="hr mt-1 mb-0"></hr>
                                            </React.Fragment>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      <div className="mt-1 px-2 fw-300 grey-text fs-12">
                                        No data found
                                      </div>
                                    )}
                                  </>
                                </div>
                              </div>
                            )}
                        </>
                      )}
                    </Col>

                    <Col lg={2} md={6} className=" mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("house_number")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            {/* <input
                              type="text"
                              placeholder="No."
                              id="house_number"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("house_number");
                              }}
                            /> */}
                            <InputField
                              id={"house_number"}
                              value={value}
                              type={"text"}
                              placeholder={"No."}
                              errorscope
                              iserror={errors.house_number}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("house_number");
                              }}
                            />
                            {/* <label for="house_number">No. </label> */}
                          </div>
                        )}
                      />
                      {/* {errors.house_number && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                    </Col>
                    <Col lg={2} md={6} className=" mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("floor_no")}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <div className="form-input ">
                            <input
                              type="number"
                              placeholder="Floor"
                              id="floor"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                // setFloor(e.target.value);
                                clearErrors("floor_no");
                              }}
                            />
                            <label for="floor">Floor </label>
                          </div>
                        )}
                      />
                      {errors.floor_no && (
                        <p className="error-msg">This field is required</p>
                      )}
                    </Col>
                    <Col lg={4} md={6} className="mb-3">
                      <Controller
                        control={control}
                        {...register("side_door")}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <div className="form-input ">
                            <input
                              type="text"
                              placeholder="Side / Door"
                              id="Side/Door"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("side_door");
                              }}
                            />
                            <label for="Side/Door">Side / Door </label>
                          </div>
                        )}
                      />
                      {errors.side_door && (
                        <p className="error-msg">This field is required</p>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mx-3 ">
                <Col lg={10} className="pe-lg-0">
                  <Row>
                    <Col lg={4} className="mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("country")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <ReactSelect
                              placeholder={"Country"}
                              clearable={false}
                              iserror={errors.country}
                              options={countryData?.data?.map((item) => ({
                                value: item?.Id,
                                label: (
                                  <div
                                    className="d-flex align-items-center"
                                    style={{ fontFamily: "Cerebri sans" }}
                                  >
                                    <div className="radius-4 me-2">
                                      <img
                                        src={item?.DataValue1}
                                        style={{
                                          width: "20px",
                                          marginRight: "10px",
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <div className="fs-14 light-font-text ">
                                        {" "}
                                        {item?.Text}
                                      </div>
                                    </div>
                                  </div>
                                ),
                              }))}
                              value={
                                value === undefined ? "" : displayItem(value)
                              }
                              onChange={(e, ActionType) => {
                                onChange(e);
                                setValue("street", "");
                                setValue("house_number", "");
                                setValue("zipCode", "");
                                setValue("city", "");
                                clearErrors("country");
                              }}
                            />
                          );
                        }}
                      />
                      {/* 
                      {errors.country && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                    </Col>
                    <Col lg={4} md={6} className="mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("zipCode")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <ReactSelect
                              placeholder={"Zip code"}
                              iserror={errors.zipCode}
                              options={zipCodeByCountryIdData?.data?.map(
                                (zip) => ({
                                  value: zip?.Id,
                                  label: `${zip?.Id} ${zip?.Text}`,
                                })
                              )}
                              value={
                                value?.value
                                  ? {
                                      value: value?.value,
                                      label: value?.value,
                                    }
                                  : ""
                              }
                              onChange={(e, ActionType) => {
                                handleZipCode(e, ActionType);
                                onChange(e);
                                clearErrors("zipCode");
                              }}
                            />
                          );
                        }}
                      />

                      {/* {errors.zipCode && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                    </Col>

                    <Col lg={4} md={6} className="mb-3">
                      <Controller
                        control={control}
                        {...register("city")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            {/* <input
                              type="text"
                              disabled
                              placeholder="City"
                              id="city"
                              value={value}
                            /> */}
                            <InputField
                              id={"city"}
                              value={value}
                              type={"text"}
                              placeholder={"City"}
                              iserror={errors.city}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                            {/* <label htmlFor="city">City</label> */}
                          </div>
                        )}
                      />
                      {/* {errors.city && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr className="hr mt-1 mb-0"></hr>
              <div className="fs-14 py-4 px-4 ms-1  form-label-title">
                Customer role
              </div>
              <div className=" px-3" style={{ width: "80%" }}>
                <Row>
                  <Col lg={3} md={6} className="pe-lg-0">
                    <CustomCheckBox
                      label={"Primary"}
                      checked={isPrimary}
                      onChange={(e) => {
                        if (
                          customerCardData?.some((e) => e?.is_primary === "1")
                        ) {
                          toast.warn("There can only be one primary customer");
                        } else {
                          setIsPrimary(e.target.checked);
                          if (e.target.checked) {
                            setIsSigningContract(true);
                          }
                        }
                      }}
                    />
                  </Col>
                  <Col lg={3} md={6} className="px-lg-0">
                    <CustomCheckBox
                      label={"Paying"}
                      checked={isPaying}
                      onChange={(e) => setIsPaying(e.target.checked)}
                    />
                  </Col>
                  <Col lg={3} md={6} className="px-lg-0">
                    <CustomCheckBox
                      label={"Co-Resident"}
                      checked={isCoResident}
                      onChange={(e) => setIsCoResident(e.target.checked)}
                    />
                  </Col>
                  <Col lg={3} md={6} className="px-lg-0">
                    <CustomCheckBox
                      label={"Sub-Tenant"}
                      checked={isSubTenant}
                      onChange={(e) => setIsSubTenant(e.target.checked)}
                    />
                  </Col>
                </Row>
              </div>
              <div className="pb-3 px-3" style={{ width: "80%" }}>
                <Row>
                  <Col lg={3} md={6} className="pe-lg-0">
                    <CustomCheckBox
                      label={"Incompetent"}
                      checked={isInCompetent}
                      onChange={(e) => {
                        isGuardian
                          ? toast?.info("Guardian cannot be set as incompetent")
                          : setIsInCompetent(e.target.checked);
                      }}
                    />
                  </Col>
                  <Col lg={3} md={6} className="px-lg-0">
                    <CustomCheckBox
                      label={"Guardian"}
                      // disabled={
                      //   !customerCardData?.some(
                      //     (item) => item?.is_incompetent === "1"
                      //   )
                      // }
                      checked={isGuardian}
                      onChange={(e) => {
                        if (isInCompetent) {
                          toast.info(
                            "Cannot set incompetent customer as guardian"
                          );
                        } else {
                          if (customerCardData?.length < 1) {
                            toast.info(
                              "Single customer cannot be set as guardian"
                            );
                          } else {
                            if (
                              customerCardData?.some(
                                (item) => item?.is_incompetent == "1"
                              )
                            ) {
                              setIsGuardian(e.target.checked);
                              setValue("guardian", "");
                            } else {
                              toast.info(
                                "Atleast one customer should be incompetent"
                              );
                            }
                          }
                        }
                        // actorId !== ""
                        //   ? customerCardData?.length > 1
                        //     ? setIsGuardian(e.target.checked)
                        //     : toast.info(
                        //         "Single customer cannot be set as guardian"
                        //       )
                        //   : customerCardData?.length >= 1 &&
                        //     customerCardData?.some(
                        //       (item) => item?.is_incompetent === "1"
                        //     )
                        //   ? setIsGuardian(e.target.checked)
                        //   : toast.info(
                        //       "Atleast one customer should be incompetent"
                        //     );
                      }}
                    />
                  </Col>
                  {isGuardian && (
                    <Col lg={4} md={4} className="px-lg-0">
                      <Controller
                        control={control}
                        {...register("guardian")}
                        rules={{ required: isGuardian ? true : false }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <ReactSelect
                              height={"20px"}
                              placeholder={"Select customer"}
                              options={customerCardData
                                ?.filter((val) => val?.is_incompetent == "1")
                                ?.map((item) => ({
                                  value: item?.actor_id,
                                  label: `${item?.firstname} ${item?.lastname}`,
                                }))}
                              value={value}
                              onChange={(e, ActionType) => {
                                onChange(e);
                                clearErrors("guardian");
                              }}
                            />
                          );
                        }}
                      />

                      {errors.guardian && (
                        <p className="error-msg">This field is required</p>
                      )}
                    </Col>
                  )}
                </Row>
              </div>

              <hr className="hr mt-1 mb-0"></hr>
              <div className="fs-14 py-4 px-4 ms-1  form-label-title">
                Customer settings
              </div>
              <Row className="ms-3 mb-1 d-flex">
                <Col
                  md={8}
                  className=" d-flex"
                  style={{
                    fontWeight: "200",
                    fontSize: "14px",
                    alignItems: "center",
                  }}
                >
                  Will be signing contract?
                </Col>
                <Col xl={2} lg={2} md={2} className="px-0">
                  <Toggle
                    id={"isSigningContract"}
                    disabled={isPrimary}
                    label={"Sign contract"}
                    checked={isSigningContract}
                    onChange={(e) => setIsSigningContract(e.target.checked)}
                  />
                </Col>
              </Row>
              <Row className="ms-3 mb-1 d-flex">
                <Col
                  md={8}
                  className=" d-flex"
                  style={{
                    fontWeight: "200",
                    fontSize: "14px",
                    alignItems: "center",
                  }}
                >
                  Send reservation confirmation to the customer
                </Col>
                <Col xl={2} lg={2} md={2} className="px-0">
                  <Toggle
                    id={"sendReservInfoToCustomerActive"}
                    checked={sendReservInfoToCustomerActive}
                    onChange={(e) =>
                      setSendReservInfoToCustomerActive(e.target.checked)
                    }
                  />
                </Col>
              </Row>

              <Row className="ms-3 my-1  d-flex">
                <Col
                  md={8}
                  className="  d-flex"
                  style={{
                    fontWeight: "200",
                    fontSize: "14px",
                    alignItems: "center",
                  }}
                >
                  Send screening invitation to the customer
                </Col>
                <Col xl={2} lg={2} md={2} className="px-0">
                  <Toggle
                    id={"sendScreeningToCustomerActive"}
                    checked={sendScreeningToCustomerActive}
                    onChange={(e) =>
                      setSendScreeningToCustomerActive(e.target.checked)
                    }
                  />
                </Col>
              </Row>

              <Row className="ms-3 my-1  d-flex">
                <Col
                  md={8}
                  className="  d-flex"
                  style={{
                    fontWeight: "200",
                    fontSize: "14px",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <span className="fw-600 fs-14 ">2-factor</span>{" "}
                    <span className="fw-300 fs-14 ">
                      (buyer authorizes electronic documents by SMS to mobile
                      number)
                    </span>
                  </div>
                </Col>
                <Col md={2} className="px-0">
                  <Toggle
                    id={"twoFactor"}
                    checked={is2FaEnabled}
                    onChange={(e) => setIs2FaEnabled(e.target.checked)}
                  />
                </Col>
              </Row>
            </div>
            <div className="mt-2 py-2 px-4 gap-2 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
              {" "}
              <ETButton
                variant={"white"}
                buttonText="CANCEL"
                // type={"button"}
                onClick={() => {
                  setAddCustomer(false);
                  addCustomerFormReset();
                }}
              />
              <ETButton
                variant={"primary"}
                buttonText={actorId === "" ? "ADD" : "UPDATE"}
                type={"submit"}
                disabled={PostAddActorIsLoading || PutUpdateActorIsLoading}
                // onClick={() => setHideBtn(true)}
              />
            </div>
          </Form>
        </div>

        {/* <div className=" py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <div className="my-1">
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => setCompareCustomer(false)}
            />
          </div>
          <div className="d-flex gap-3 my-1">
            {!isSsnExist && (
              <ETButton
                variant={"white"}
                buttonText="DO NOT USE EXISTING CUSTOMER"
                onClick={handleDoNotUseExistingCustomer}
              />
            )}
            <ETButton
              variant={"primary"}
              buttonText="UPDATE AND USE"
              onClick={handleUpdateAndUse}
            />
          </div>
        </div> */}
      </CustomPopup>

      {/* -----------------------------------Comparing customer popup ---------------------------------- */}
      <CustomPopup
        open={compareCustomer}
        onHide={() => setCompareCustomer(false)}
        headerRequired={false}
        size="lg"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="pt-3 px-lg-5 px-3 d-flex justify-content-center text-center">
          <div className="px-lg-5 px-3 my-5 fs-14 fw-300">
            <div className="fs-32 fw-600">Existing customer?</div>
            <div className="py-4">
              Based on the information you have just filled in we found a match
              on an existing customer. You can now choose to add this
              reservation to the existing customer by clicking update and use or
              you can choose no to and you will create a new customer.
            </div>
            <div className="fw-600 pt-2 pb-3">
              We found a match on an existing customer:{" "}
              {isSsnExist && "with same SSN number"}
            </div>
            <div
              className="bg-grey w-100 radius-8"
              style={{ border: "1px solid #F0F0F0" }}
            >
              <div>
                <Row
                  className="fs-14 fw-300 text-start"
                  onClick={() => {
                    // allSelectedData(e);
                    setSearchDiv(false);
                  }}
                >
                  <Col
                    xs={5}
                    className="px-4 py-2 "
                    style={{ borderRight: "1px solid #F0F0F0" }}
                  >
                    <div className="px-2">
                      <div className=" d-flex flex-column my-2">
                        <span
                          className={`fw-500 ${
                            customerDetailsData?.firstname ==
                              existingActorDetailsData?.firstname && "red-text"
                          }`}
                        >
                          {existingActorDetailsData?.firstname}&nbsp;
                          {existingActorDetailsData?.lastname}
                        </span>
                        <span>{existingActorDetailsData?.actor_id}</span>
                      </div>
                      <div className="d-flex flex-column my-2">
                        <span
                          className={`fw-300 ${
                            customerDetailsData?.myemail ==
                              existingActorDetailsData?.email && "red-text"
                          }`}
                        >
                          {existingActorDetailsData?.email}
                        </span>
                        <span
                          className={`fw-300 ${
                            customerDetailsData?.phoneno ==
                              existingActorDetailsData?.mobilenumber &&
                            "red-text"
                          }`}
                        >
                          {existingActorDetailsData?.phone_calling_code_id}
                          &nbsp;{existingActorDetailsData?.mobilenumber}
                        </span>
                      </div>
                      <div className="d-flex flex-column my-2">
                        <span>
                          {existingActorDetailsData?.birthdate == ""
                            ? "-"
                            : moment(
                                existingActorDetailsData?.birthdate
                              ).format("DD MMM, YYYY")}
                        </span>
                        <span
                          className={`${
                            customerDetailsData?.social_security_number ==
                              existingActorDetailsData?.social_security_number &&
                            "red-text"
                          }`}
                        >
                          {existingActorDetailsData?.social_security_number}
                        </span>
                      </div>
                      <div className="d-flex flex-column my-2">
                        <span>
                          {existingActorDetailsData?.street_name}&nbsp;
                          {existingActorDetailsData?.house_number}
                        </span>
                        <span>
                          {existingActorDetailsData?.zipcode_id}&nbsp;
                          {existingActorDetailsData?.city}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={7} className="py-2">
                    <div className="px-2">
                      <div className="fw-600 mt-2 fs-11">Known activities</div>
                      <div className="d-flex flex-column mb-3 fs-12">
                        <span>
                          <FontAwesomeIcon
                            icon="fa-regular fa-house-user"
                            size="xs"
                            style={{ color: "#000000" }}
                          />{" "}
                          Private viewing at Terrasserne 26 Apr, 2023
                        </span>
                        <span>
                          <FontAwesomeIcon
                            icon="fa-regular fa-house"
                            size="xs"
                            style={{ color: "#000000" }}
                          />{" "}
                          Open House at Terrasserne 23 Apr, 2023
                        </span>
                        <span>
                          <FontAwesomeIcon
                            icon="fa-regular fa-house"
                            size="xs"
                            style={{ color: "#000000" }}
                          />{" "}
                          Open House at Blomsterbjerget 16 Mar, 2023
                        </span>
                        <span>
                          <FontAwesomeIcon
                            icon="fa-regular fa-user"
                            size="xs"
                            style={{ color: "#000000" }}
                          />{" "}
                          Lead at 12 Mar, 2023
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {/* ))} */}
            </div>
          </div>
        </div>
        <div className=" py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <div className="my-1">
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => {
                setCompareCustomer(false);
                setAddCustomer(true);
              }}
            />
          </div>
          <div className="d-flex gap-3 my-1">
            {!isSsnExist && (
              <ETButton
                variant={"white"}
                buttonText="DO NOT USE EXISTING CUSTOMER"
                onClick={handleDoNotUseExistingCustomer}
                disabled={PostAddActorIsLoading || PutUpdateActorIsLoading}
                // disabled={postAddActorIsLoading}
              />
            )}
            <ETButton
              variant={"primary"}
              buttonText="UPDATE AND USE"
              onClick={handleUpdateAndUse}
              disabled={PostAddActorIsLoading || PutUpdateActorIsLoading}
              // disabled={postAddActorIsLoading}
            />
          </div>
        </div>
      </CustomPopup>

      {/* -----------------------------------Cancel reservation popup ---------------------------------- */}
      <CustomPopup
        open={cancelPopup}
        onHide={() => setCancelPopup(false)}
        title="Cancel reservation?"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-3">
          <p style={{ fontSize: "14px", fontWeight: "300" }}>
            Ouch! Are you sure you want to cancel this reservation?
          </p>
          <p style={{ fontSize: "14px", fontWeight: "300" }}>
            If you click cancel, all the information you entered will be deleted
            and the Units will not change status
          </p>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => setCancelPopup(false)}
          />
          <NavLink
            to="/property-units/units/residential"
            state={{ propId: propId, Tab: "Units" }}
          >
            <ETButton variant={"primary"} buttonText="CONFIRM" />
          </NavLink>
        </div>
      </CustomPopup>

      {/* ----------------------------------------Delete actor popup ---------------------------------- */}
      <CustomPopup
        style={{ position: "relative" }}
        size="md"
        open={openDeleteActorPopup?.deleteActorPopup}
        onHide={() =>
          setOpenDeleteActorPopup((prev) => ({
            ...prev,
            deleteActorPopup: false,
          }))
        }
        title="Delete customer"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() =>
          setOpenDeleteActorPopup((prev) => ({
            ...prev,
            deleteActorPopup: false,
          }))
        }
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div
            style={{
              fontSize: "14px",
              fontFamily: "Cerebri Sans",
              fontWeight: "500",
              textAlign: "left",
              lineHeight: "17.5px",
            }}
          >
            Are you sure you to delete this customer?
          </div>
        </div>

        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="NO"
            onClick={() => setOpenDeleteActorPopup(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="YES"
            onClick={() => handleDeleteActor()}
          />
        </div>
      </CustomPopup>
    </>
  );
};

export default ReservationSearchableForm;
