import React, { useRef, useState, useMemo } from "react";
import CSVWidget from "../../../Components/DownloadWidget/CSVWidget";

import { Col, Row } from "react-bootstrap";
import Menus from "../../../Components/Menus/Menus";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import EventsStatsMap from "./EventsStatsMap";
import { MaterialReactTable } from "material-react-table";
import { borderRadius } from "@mui/system";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import MRTTheme from "../../../Components/table/MRTTheme";
import { NoRecordsToDisplay } from "../../../utils/validationConstants";
import { H2, H3 } from "../../../styles/Common.style";
import { SortIcon } from "../../../Components/table/MRTGlobalProps";

const breakDownOfCustomersData = [
  {
    Type: "Age",
    value: "25-30",
  },
  {
    Type: "Avg. age",
    value: "2",
  },
  {
    Type: "Owner-occupied residence",
    value: "5",
  },
  {
    Type: "Rental residence",
    value: "5",
  },
  {
    Type: "Other type of residence",
    value: "5",
  },
  {
    Type: "Married",
    value: "5",
  },
  {
    Type: "Single",
    value: "5",
  },
  {
    Type: "Divorced",
    value: "5",
  },
  {
    Type: "Cohabiting",
    value: "5",
  },
  {
    Type: "Male",
    value: "5",
  },
  {
    Type: "Female",
    value: "5",
  },
  {
    Type: "Other gender",
    value: "5",
  },
];

const StatisticsOfParticipantsatEvent = () => {
  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "statics-participants-events";

  const options = {
    keys: [{ field: "Type" }],
  };

  const data = breakDownOfCustomersData;

  const CSVFunction = CSVWidget({ refObj, fileName, options, data });

  const breakDownOfCustomersColumns = useMemo(
    () => [
      {
        accessorKey: "Type",
        header: "",
        size: 180,
      },
      {
        accessorKey: "value",
        header: "",
        size: 90,
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );

  return (
    <div className="card-body p-3" ref={myref}>
      {" "}
      <Row>
        <Col className="d-flex align-items-center" sm={10}>
          {" "}
          <div className="d-flex" style={{ alignItems: "center" }}>
            <H3 className=" me-2">Statistic of participants at events</H3>{" "}
            <CustomToolTip
              size="sm"
              text="Statistic of participants at events"
            />
          </div>
        </Col>
        <Col sm={2} className="d-flex justify-content-end">
          {" "}
          <Menus
            itemheight={40}
            itemwidth={40}
            itemcontainerheight={200}
            itemcontainerwidth={200}
            component={
              <div className="fs-14">
                <div className="addpropContent px-3 py-2 ">
                  <span className="ms-1" onClick={() => CSVFunction()}>
                    Download widget as CSV
                  </span>
                </div>
              </div>
            }
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="mb-sm-4 mb-lg-0" lg={8}>
          <div className="radius-8">
            <EventsStatsMap />
          </div>
        </Col>
        <Col lg={4}>
          {breakDownOfCustomersData.length != 0 ? (
            <>
              <span className="form-label-title fs-13 ps-3"> Participants</span>
              <MRTTheme
                colorVariantsForTableData={["odd"]}
                table={
                  <MaterialReactTable
                    columns={breakDownOfCustomersColumns}
                    // data={breakDownOfCustomersData}
                    data={[]}
                    icons={{ ...SortIcon }}
                    localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                    enableBottomToolbar={false}
                    enableSorting={false}
                    enableColumnActions={false}
                    enablePagination={false}
                    renderTopToolbar={false}
                  />
                }
              />
            </>
          ) : (
            <NoDataScreen height={"377px"} imgHeight={"150px"} />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default StatisticsOfParticipantsatEvent;
