import React, { useEffect, useState } from "react";
import CustomPopup from "../../../Components/CustomPopup";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import ETButton from "../../../Components/Buttons/ETButton";
import InputField from "../../../Components/Textfield/InputField";
import { H5 } from "../../../styles/Common.style";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { usePostCheckExists } from "../../../hooks/queries/useCheckExistsQueries";

import { status } from "../../../model/Constant";
import { toast } from "react-toastify";
import {
  ErrorAlert,
  SucessAlert,
} from "../../../Components/alerts/AlertsAndMessages";
import { useOrganizationId } from "../../../hooks/stores/useOrganizationStore";

const ChangeDomainPopup = ({
  open,
  onHide,
  usedFor = "DC",
  onCancel,
  onStart,
  domainName,
  handelChangeDomainName = () => {},
}) => {
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    reset,
    getValues,
    setValue,
    resetField,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      domain: " ",
    },
  });
  //------------- zustand ------------------//
  const organizationId = useOrganizationId((state) => state.organizationId);
  // ---------------------------- React query -----------------------
  const { mutateAsync: PostCheckExists, isLoading: isCheckExistLoading } =
    usePostCheckExists();
  useEffect(() => {
    const modifiedValue = domainName?.includes(".estatetool.io")
      ? domainName.replace(".estatetool.io", "")
      : domainName;
    setValue("domain", modifiedValue);
  }, [domainName, open]);

  const handelConfirm = async (data) => {
    const payload = {
      type: "domain",
      value: getValues("domain") + ".estatetool.io",
      id: organizationId,
    };
    try {
      const response = await PostCheckExists(payload);
      if (response?.status == status?.s) {
        handelChangeDomainName(data);
        clearErrors("domain", "");
      } else {
        return null;
      }
    } catch (error) {
      setError("domain", { type: "pattern", message: error?.message });
    }
  };

  return (
    <div>
      <CustomPopup
        open={open}
        onHide={() => {
          clearErrors("domain", "");
          onHide();
        }}
        title={"Change domain"}
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        size={"md"}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        titleTooltipText={"Change domain"}
        secondTextShow={false}
        priorityShow={false}
      >
        <div className="p-4 border-bottom">
          <H5 className="mb-2">Warning!</H5>
          <span className="light-font-text">
            If the domain for the entire organisation needs to be changed, this
            can be done here. Please note that there may be several consequences
            and it is not recommended without consulting estatetool.
          </span>
        </div>
        <Form onSubmit={handleSubmit(handelConfirm)}>
          <Row className="p-4">
            <Col lg={8}>
              <div className="d-flex align-items-center gap-2 mb-2">
                <H5>Domain</H5>
                <CustomToolTip text="Domain" size="sm" />
              </div>

              <Controller
                control={control}
                {...register("domain")}
                rules={{
                  required: true,
                  pattern: {
                    value: /^[a-z0-9-]+$/,
                    message: "Invalid domain name",
                  },
                }}
                render={({ field: { onChange, value, name } }) => {
                  const modifiedValue = value?.includes(".estatetool.io")
                    ? value.replace(".estatetool.io", "")
                    : value;
                  return (
                    <InputField
                      id={"domain"}
                      value={modifiedValue}
                      type="text"
                      placeholder=".estatetool.io"
                      textTransform="lowercase"
                      errorscope
                      // disabled={isSameDetailAsAboveActive}
                      iserror={errors?.domain}
                      errormessage={
                        errors?.domain?.type === "pattern"
                          ? errors?.domain.message
                          : false
                      }
                      subText={
                        errors?.domain
                          ? ""
                          : `Your domain name will be : ${modifiedValue?.toLowerCase()}.estatetool.io`
                      }
                      // onChange={(e) => {
                      //   onChange(e);
                      //   clearErrors("domain");
                      // }}
                      onChange={(e) => {
                        onChange(e?.target?.value?.toLowerCase());
                        clearErrors("domain");
                      }}
                    />
                  );
                }}
              />
            </Col>
          </Row>

          <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              buttonText="CANCEL"
              variant={"white"}
              onClick={() => {
                clearErrors("domain", "");
                onCancel();
              }}
            />
            <div className="d-flex ">
              <div>
                <ETButton
                  buttonText={"CONFIRM"}
                  disabled={
                    (Object?.keys(errors)?.length && errors?.domain) ||
                    isCheckExistLoading
                  }
                  variant={"primary"}
                  type="submit"
                  // onClick={onStart}
                />
              </div>
            </div>
          </div>
        </Form>
      </CustomPopup>
    </div>
  );
};

export default ChangeDomainPopup;

// Created by --------------> Yash
