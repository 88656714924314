import React, { useEffect, useState } from "react";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import GroupButtons from "../../../../Components/GroupButton/GroupButton";
import Toggle from "../../../../Components/Switch/Switch";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import SuperEditor from "@ckeditor/ckeditor5-build-classic";
import { useAppState } from "../AllState";
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { H4 } from "../../../../styles/Common.style";

const RentedProperty = ({
  submitRef,
  handleNext,
  prevRef,
  activeTenancyForm,
  getConfigurationDetailsData,
  handleEditConfig,
  previewRef,
}) => {
  const [state, setState] = useAppState();
  const {
    handleSubmit,
    register,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: state[activeTenancyForm] });

  const saveData = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 10, true);
    // handleNext(1);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  const preview = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 10, false);
    // handleNext(1);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  // saving all field into context state
  // const prevSubmit = () => {
  //   // setState({ ...state, ...getValues() });
  //   setState({ ...state, [activeTenancyForm]: { ...getValues() } });
  // };
  useEffect(() => {
    setValue(
      "other_information_1",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "other_information_1"
      )[0]?.actual_value
    );

    setValue(
      "resident_representation",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "resident_representation"
      )[0]?.actual_value == 2
        ? 1
        : 0
    );
    setValue(
      "pet_allowed",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "pet_allowed"
      )[0]?.actual_value == 2
        ? 1
        : 0
    );
    setValue(
      "house_regulation",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "house_regulation"
      )[0]?.actual_value == 2
        ? 1
        : 0
    );
    setValue(
      "pet_conditions",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "pet_conditions"
      )[0]?.actual_value
    );
    setValue(
      "retrieve_pet_data_from_unit",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "retrieve_pet_data_from_unit"
      )[0]?.actual_value
    );
  }, [getConfigurationDetailsData]);

  return (
    <>
      <Form className="main-form light-font-text">
        {/* -----------Top Header----------- */}
        <div style={{ borderBottom: "1px solid #F0F0F0" }}>
          <div className="px-4 py-3">
            <H4 className="actors-section-title">
              §10 Resident representative, animal husbandry, house rules and
              other information about the rented property{" "}
            </H4>
            <div className="light-font-text grey-text">
              Lorem ipsum dolor sit lorem ipsum dolor sit
            </div>
          </div>
        </div>
        <div
          className="px-4 py-4"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div className="d-flex align-items-center">
            <span className="me-2 form-label-title">
              Residents' representation{" "}
            </span>{" "}
            <CustomToolTip  text="Residents' representation" />
          </div>
          <p>
            Is resident representation established in the property at the time
            of the contract?
          </p>
          <div className="w-25">
            <Controller
              control={control}
              {...register("resident_representation")}
              render={({ field: { onChange, value } }) => {
                return (
                  <GroupButtons
                    tabs={[
                      {
                        id: 1,
                        tabTitle: "Yes",
                        title: "Yes",
                        content:
                          "This page contains data related to Map milestone",
                      },
                      {
                        id: 2,
                        tabTitle: "No",
                        title: "No",
                        content:
                          "This page contains data related to Chart milestone",
                      },
                    ]}
                    currentTab={value}
                    handleTabClick={(tab) => {
                      setValue("resident_representation", tab);
                    }}
                  />
                );
              }}
            />
          </div>
        </div>
        <div
          className="px-4 py-4"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div className="d-flex">
            <span className="me-2 form-label-title">Domestic animals </span>{" "}
            <CustomToolTip height="13px" text="Domestic animals" />
          </div>
          <div>Are pets allowed in the tenancy?</div>
          <div className="d-flex align-items-center mb-2 gap-3">
            <div>Retrieve data from unit</div>
             <Controller
              {...register("retrieve_pet_data_from_unit")}
              control={control}
              render={({
                field: { onChange, value = parseInt(getValues("retrieve_pet_data_from_unit")) },
              }) => (
                <Toggle
                  id={"Retrieve_data_from_unit"}
                  checked={value}
                  onChange={(e) =>
                    e.target.checked
                      ? setValue("retrieve_pet_data_from_unit", 1)
                      : setValue("retrieve_pet_data_from_unit", 0)
                  }
                />
              )}
            />
          </div>
          <div className="mb-2 w-25">
            <Controller
              control={control}
              {...register("pet_allowed")}
              render={({ field: { onChange, value } }) => {
                return (
                  <GroupButtons
                    tabs={[
                      {
                        id: 1,
                        tabTitle: "Yes",
                        title: "Yes",
                        content:
                          "This page contains data related to Map milestone",
                      },
                      {
                        id: 2,
                        tabTitle: "No",
                        title: "No",
                        content:
                          "This page contains data related to Chart milestone",
                      },
                    ]}
                    currentTab={value}
                    handleTabClick={(tab) => {
                      setValue("pet_allowed", tab);
                    }}
                  />
                );
              }}
            />
          </div>
          <div className="mt-3">
            <div>Specific conditions for the pet permit:</div>

            <textarea
              className="normalTextstyle fs-14 p-2"
              placeholder="Type in other information"
              style={{ width: "100%", minHeight: "74px" }}
              {...register("pet_conditions")}
            />
            {errors.pet_conditions && (
              <div className="ms-2">
                <p className="error-msg" style={{ width: "max-content" }}>
                  (Specific conditions for the pet permit is required)
                </p>
              </div>
            )}
          </div>
        </div>
        <div
          className="px-4 py-4"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div className="d-flex">
            <span className="me-2 form-label-title">House regulations </span>{" "}
            <CustomToolTip height="13px" text=" House regulations" />
          </div>
          <div className=" mb-3">
            Are there house rules for the property at the start of the tenancy?
          </div>

          <div className="mb-2 w-25">
            <Controller
              control={control}
              {...register("house_regulation")}
              render={({ field: { onChange, value } }) => {
                return (
                  <GroupButtons
                    tabs={[
                      {
                        id: 1,
                        tabTitle: "Yes",
                        title: "Yes",
                        content:
                          "This page contains data related to Map milestone",
                      },
                      {
                        id: 2,
                        tabTitle: "No",
                        title: "No",
                        content:
                          "This page contains data related to Chart milestone",
                      },
                    ]}
                    currentTab={value}
                    handleTabClick={(tab) => {
                      setValue("house_regulation", tab);
                    }}
                  />
                );
              }}
            />
          </div>
          <div>
            The house rules are attached if there are any for the property.
          </div>
        </div>
        <div className="px-4 py-4 ">
          <div className="d-flex">
            <span className="me-2 form-label-title">
              Other information about the rented property{" "}
            </span>{" "}
            <CustomToolTip
              height="13px"
              text="Other information about the rented property"
            />
          </div>
          <div className=" py-3">
            No deviations from or additions to the general rules of tenancy law
            and Articles 1 to 10 of the standard contract may be mentioned here.
          </div>
          <div>
            <CKEditor
              // style={{
              //   ".ck.ck-editor__main>.ck-editor__editable:not(.ck-focused)": {
              //     minHeight: "312px !important",
              //     backgroundColor : '#000 !important'
              //   },
              // }}
              onReady={(editor) => {
                // editor.focus();
                editor.editing.view.focus();

                // editor.setIdToScroll(data?.Id);
              }}
              data={getValues("other_information_1") || ""}
              onChange={(event, editor) => {
                const editorData = editor.getData();
                // handleChange(index, data, editorData);
                setValue("other_information_1", editorData);
              }}
              focusedElement={true}
              editor={SuperEditor}

              // data={data?.text_value}
              // onChange={(event, editor) => {
              //   const editorData = editor.getData();
              //   handleChange(index, data, editorData);
              // }}
            />
          </div>
        </div>
        <button
          type="submit"
          onClick={handleSubmit(saveData)}
          ref={submitRef}
          style={{ display: "none" }}
        />
        <button
          type="submit"
          onClick={handleSubmit(preview)}
          ref={previewRef}
          style={{ display: "none" }}
        />
      </Form>
      {/* <button ref={prevRef} onClick={prevSubmit} style={{ display: "none" }} /> */}
    </>
  );
};

export default RentedProperty;
