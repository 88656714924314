import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  GetActiveContractsByDivisionId,
  GetAllCustomerByDivision,
  GetDistributionAllScreening,
  GetFollowUpStatus,
  GetFollowUpTaskCategory,
  GetLeadDetails,
  GetLeadsByDivisionId,
  GetOpportunityDetailsByDivision,
  GetOpportunityOverviewByDivision,
  GetScreeningDividedOnAgent,
  GetScreeningForm,
  GetSigningOrderDetails,
  GetSigningOrderOverview,
  PostAddLead,
  PostAddLeadfollowUp,
  PostAddScreeningResponse,
  PutUpdateFavouriteLead,
  PutUpdateLeadAgent,
  PutUpdateScreeningActorStatus,
} from "../../services/CustomerFunnel";

// Get Leads by division Id
export const useGetLeadsByDivisionId = (
  division_id,
  dateFilter
) => {
  return useQuery(
    ["qGetLeadsByDivisionId", division_id, dateFilter],
    () => GetLeadsByDivisionId(division_id, dateFilter),
    {
      enabled: !!division_id,
    },
    {
      enabled: !!dateFilter,
    }
  );
};

//Get Distribution All Screening
export const useGetDistributionAllScreening = (division_id) => {
  return useQuery(
    ["qGetDistributionAllScreening", division_id],
    () => GetDistributionAllScreening(division_id),
    {
      enabled: !!division_id,
    }
  );
};
//Get Screening Divided On Agent
export const useGetScreeningDividedOnAgent = (property_id) => {
  return useQuery(
    ["qGetScreeningDividedOnAgent", property_id],
    () => GetScreeningDividedOnAgent(property_id),
    {
      enabled: !!property_id,
    }
  );
};
//Get Active Contracts By DivisionId
export const useGetActiveContractsByDivisionId = (division_id) => {
  return useQuery(
    ["qGetActiveContractsByDivisionId", division_id],
    () => GetActiveContractsByDivisionId(division_id),
    {
      enabled: !!division_id,
    }
  );
};

// Add/Update lead agent
export const usePutUpdateLeadAgent = () => {
  return useMutation(PutUpdateLeadAgent);
};

// Update Favourite Lead
export const usePutUpdateFavouriteLead = () => {
  return useMutation(PutUpdateFavouriteLead);
};

//Get Signing Order Overview
export const useGetSigningOrderOverview = (division_id) => {
  return useQuery(
    ["qGetSigningOrderOverview", division_id],
    () => GetSigningOrderOverview(division_id),
    {
      enabled: !!division_id,
    }
  );
};
//Get Signing Order Details
export const useGetSigningOrderDetails = (division_id) => {
  return useQuery(
    ["qGetSigningOrderDetails", division_id],
    () => GetSigningOrderDetails(division_id),
    {
      enabled: !!division_id,
    }
  );
};
// Add Lead follow up
export const usePostAddLeadfollowUp = () => {
  return useMutation(PostAddLeadfollowUp);
};
// Add Lead
export const usePostAddLead = () => {
  const queryClient = useQueryClient();
  return useMutation(PostAddLead, {
    onSuccess: () => {
      queryClient.invalidateQueries("qGetLeadsByDivisionId");
    },
  });
};
//Get Follow Up Status
export const useGetFollowUpStatus = () => {
  return useQuery(["qGetFollowUpStatus"], () => GetFollowUpStatus());
};
//Get Follow Up Task Category
export const useGetFollowUpTaskCategory = () => {
  return useQuery(["qGetFollowUpTaskCategory"], () =>
    GetFollowUpTaskCategory()
  );
};
//Get Lead Details
export const useGetLeadDetails = (lead_id) => {
  return useQuery(["qGetLeadDetails", lead_id], () => GetLeadDetails(lead_id), {
    enabled: !!lead_id,
  });
};

// Post Screening Response
export const usePostAddScreeningResponse = () => {
  return useMutation(PostAddScreeningResponse);
};
// Put Update Screening Actor Status
export const usePutUpdateScreeningActorStatus = () => {
  return useMutation(PutUpdateScreeningActorStatus);
};

//Get Screening Form
export const useGetScreeningForm = (customer_id, form_id) => {
  return useQuery(
    ["qGetScreeningForm", customer_id, form_id],
    () => GetScreeningForm(customer_id, form_id),
    {
      enabled: !!customer_id,
    },
    {
      enabled: !!form_id,
    }
  );
};

//Get All Customer By Division
export const useGetAllCustomerByDivision = (
  division_id,
  startDateEndDateFormate
) => {
  return useQuery(
    ["qGetAllCustomerByDivision", division_id, startDateEndDateFormate],
    () => GetAllCustomerByDivision(division_id, startDateEndDateFormate),
    {
      enabled: !!division_id,
    },
    {
      enabled: !!startDateEndDateFormate,
    }
  );
};
//Get Lead Details
export const useGetOpportunityOverviewByDivision = (lead_id) => {
  return useQuery(
    ["qGetOpportunityOverviewByDivision", lead_id],
    () => GetOpportunityOverviewByDivision(lead_id),
    {
      enabled: !!lead_id,
    }
  );
};

//======================================== Opportunities ====================================//
//Get Opportunity details By Division
export const useGetOpportunityDetailsByDivision = (
  division_id,
  startDateEndDateFormate
) => {
  return useQuery(
    ["qGetOpportunityDetailsByDivision", division_id, startDateEndDateFormate],
    () => GetOpportunityDetailsByDivision(division_id, startDateEndDateFormate),
    {
      enabled: !!division_id,
    },
    {
      enabled: !!startDateEndDateFormate,
    }
  );
};
