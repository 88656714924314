import React, { useEffect } from "react";
// import {
//   Tab,
//   TabList,
//   TabPanel,
//   Tabs,
// } from "../../../Components/Tabs/ReactTabs.style";
// import { Settings } from "../../../assets/icons/icons";
// import UnitsTable from "./UnitsTablePage/UnitsTable";
import { TabsButtonIcon } from "../../../Components/Tabs/Tabs.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import ParkingLot from "./parkingLot/ParkingLot";
import { useUnitType } from "../../../hooks/stores/useUnitStore";
// import StorageRoom from "./storageRoom/StorageRoom";
import {
  useGetParkingLotDetails,
  useGetStorageRoomDetails,
} from "../../../hooks/queries/useUnitQueries";
import { useRouting } from "../../../hooks/stores/useRoutingStore";
import { useBreadcrumb } from "../../../Components/BreadcrumbContext ";
import { unitsParentTab } from "../../../model/Constant";
import { NavLink } from "../../../Components/Tabs/ReactTabs.style";
import { Outlet } from "react-router-dom";

const UnitsParent = ({
  propertyOverviw,
  propertyId,
  RefetchGetPropertyWithOverviewByPropertyIdData,
}) => {
  const { refetch: storageRoomDetailsRefetch } = useGetStorageRoomDetails(
    propertyId,
    0
  );
  const { refetch: parkingLotDetailsRefetch } = useGetParkingLotDetails(
    propertyId,
    0
  );
  const { setTypeOfUnit } = useUnitType((state) => state);
  const { setSelectedSubTabType, setSelectedMainTab } = useRouting(
    (state) => state
  );
  const handleStorageClick = () => {
    setTypeOfUnit(3);
    storageRoomDetailsRefetch();
  };
  const handleParkinglotClick = () => {
    setTypeOfUnit(2);
    parkingLotDetailsRefetch();
  };

  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    updateBreadcrumbs(["Units", "Residential"]);
  }, []);

  return (
    <div className="mt-2  py-4 containerStyle">
      <div
        className="d-flex justify-content-between flex-wrap gap-2 px-4 pb-2 "
        // style={{ borderBottom: "1px solid #ffffff" }}
      >
        {/* <Tabs className="d-flex flex-column m-0">
					<div
						className=" d-flex justify-content-between flex-wrap gap-2 px-4 pb-3"
						style={{ borderBottom: "1px solid #ffffff" }}>
						<TabList
							className=" d-flex ps-2 m-0"
							style={{ gap: "7px", flexWrap: "nowrap" }}>
							<Tab
								onClick={() => {
									setTypeOfUnit(1)
									setSelectedSubTabType("Residential")
									updateBreadcrumbs(["Residential"])
								}}
							>Residential</Tab>
							<Tab
								onClick={() => {
									handleParkinglotClick()
									setSelectedSubTabType("Parking lot")
									updateBreadcrumbs(["Parking lot"])

								}}
							>Parking lot</Tab>

							<Tab
								onClick={() => {
									handleStorageClick()
									setSelectedSubTabType("Storage room")
									updateBreadcrumbs(["Storage room"])

								}}
							>Storage room</Tab>
						</TabList>
						<div className="mt-2 pe-2">
							<div
								className="d-flex "
								style={{
									backgroundColor: "#fff",
									border: "1px solid #D1D1D1",
									borderRadius: "8px",
									alignItems: "center",
									justifyContent: "center",
									width: "40px",
									height: "41px",
									cursor: "pointer",
								}}>
								<TabsButtonIcon>
									<FontAwesomeIcon icon="fa-solid fa-gear" />
								</TabsButtonIcon>
							</div>
						</div>
					</div>

					<div className="px-4 mt-2 mx-2">
						<TabPanel>
							{" "}
							<UnitsTable
								propertyOverviw={propertyOverviw}
								propertyId={propertyId}
								RefetchGetPropertyWithOverviewByPropertyIdData={
									RefetchGetPropertyWithOverviewByPropertyIdData
								}
							/>
						</TabPanel>
						<TabPanel>
							<ParkingLot
								propertyOverviw={propertyOverviw}
								propertyId={propertyId}
							/>
						</TabPanel>
						<TabPanel>
							<StorageRoom
								propertyOverviw={propertyOverviw}
								propertyId={propertyId}
							/>
						</TabPanel>
					</div>
				</Tabs> */}
        <div>
          {unitsParentTab?.map((page, i) => (
            <NavLink
              key={i}
              state={{ propId: propertyId, tab: page?.tab }}
              to={page.link}
              style={{ textDecoration: "none" }}
              onClick={() => {
                if (page?.tab == "Residential") {
                  setTypeOfUnit(1);
                  setSelectedMainTab(true);
                  updateBreadcrumbs(["Units", "Residential"]);
                } else if (page?.tab == "Parking lot") {
                  handleParkinglotClick();
                  setSelectedMainTab(true);
                  updateBreadcrumbs(["Units", "Parking lot"]);
                } else if (page?.tab == "Storage room") {
                  handleStorageClick();
                  setSelectedMainTab(true);
                  updateBreadcrumbs(["Units", "Storage room"]);
                }
              }}
              className=" me-2 "
            >
              {page?.tab}
            </NavLink>
          ))}
        </div>

        <div className="mt-2 ">
          <div
            className="d-flex "
            style={{
              backgroundColor: "#fff",
              border: "1px solid #D1D1D1",
              borderRadius: "8px",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              height: "41px",
              cursor: "pointer",
            }}
          >
            <TabsButtonIcon>
              <FontAwesomeIcon icon="fa-regular fa-gear" />
            </TabsButtonIcon>
          </div>
        </div>
      </div>
      <div
        className="p-4 pt-2 containerStyleColor"
        style={{ borderRadius: "10px" }}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default UnitsParent;
