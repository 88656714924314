import moment from "moment";
import React from "react";
import DataTable from "react-data-table-component";

const columns = [
  {
    name: "Status",
    selector: (row) => row.status || "-",
    width: "12%",
  },
  {
    name: "Email",
    selector: (row) => row.email || "-",
    width: "20%",
  },
  {
    name: "Actor",
    selector: (row) => row.actor || "-",
    width: "15%",
  },
  {
    name: "Recieved on",
    selector: (row) =>
      row.received_on != ""
        ? moment(row.received_on).format("DD MMM, YYYY [at] HH:mm")
        : "-",
    width: "20%",
  },
  {
    name: "Date of Signing",
    selector: (row) =>
      row.signed_on != ""
        ? moment(row.signed_on).format("DD MMM, YYYY [at] HH:mm")
        : "-",
    width: "20%",
  },
  {
    name: "Last Reminder",
    selector: (row) =>
      row.last_reminder_date != ""
        ? moment(row.last_reminder_date).format("DD MMM, YYYY [at] HH:mm")
        : "-",
    width: "13%",
  },
];

const customStyles = {
  headRow: {
    style: {
      borderRadius: "4px",
      borderBottom: "none",
    },
    denseStyle: {
      minHeight: "32px",
    },
  },
  rows: {
    style: {
      // minHeight: '72px', // override the row height
      borderRadius: "4px",
      "&:not(:last-of-type)": {
        borderBottom: "none",
      },
    },
  },
  headCells: {
    style: {
      // textAlign: "center",
      fontweight: "bold",
      // justifyContent: "left",
      paddingRight: "18px", // override the cell padding for head cells
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      // justifyContent: "left",
      paddingLeft: "18px", // override the cell padding for data cells
      paddingRight: "8px",
      fontSize: "13px",
      fontweight: "300",
    },
  },
};
const RentalContractTable = ({ tableData }) => {
  return (
    <DataTable
      columns={columns}
      data={tableData || []}
      customStyles={customStyles}
      striped
    />
  );
};

export default RentalContractTable;
