import React, { useMemo, useCallback, useState, useRef } from "react";
import PNGWidget from "../../../Components/DownloadWidget/PNGWidget";
import JPGWidget from "../../../Components/DownloadWidget/JPGWidget";
import SVGWidget from "../../../Components/DownloadWidget/SVGWidget";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import Switch from "../../../Components/Switch/Switch";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { Row, Col } from "react-bootstrap";
import Menus from "../../../Components/Menus/Menus";
import { Bar } from "react-chartjs-2";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import { H2, H3 } from "../../../styles/Common.style";

const TrafficDividedByPlatform = () => {
  const AllData = [
    {
      label: "all",
      value: "All",
    },
    {
      label: "Boligvælger",
      value: "Boligvælger",
    },
    {
      label: "Hjemmeside",
      value: "Hjemmeside",
    },
    {
      label: "Annoncering",
      value: "Annoncering",
    },
  ];

  // chart data
  const data = {
    labels: ["08-2022", "09-2022", "10-2022", "11-2022", "12-2022", "01-2022"],
    datasets: [
      {
        label: "Boligvælger",
        data: [12, 23, 32, 44, 22, 12],
        backgroundColor: "rgba(241, 102, 75, 1)",
        borderColor: ["rgba(241, 102, 75, 1)"],
        type: "line",
      },

      {
        label: "Hjemmeside",
        fill: false,
        type: "line",
        borderColor: "rgba(119, 199, 161, 1)",
        backgroundColor: "rgba(119, 199, 161, 1)",
        tension: 0.4,
        data: [12, 45, 67, 43, 23, 11],
      },

      {
        label: "Annoncering",
        data: [54, 41, 12, 21, 24, 44],
        backgroundColor: "rgba(162, 200, 208, 1)",
        fillColor: "rgba(162, 200, 208, 1)",
        borderColor: "rgba(162, 200, 208, 1)",
        borderWidth: 1,
      },
    ],
  };

  const config = {
    type: "bar",
    data,
    maintainAspectRatio: false,

    plugins: {
      tooltip: {
        callbacks: {
          title: function (tooltipItem, index) {
            let label = tooltipItem[0]?.dataset?.label;
            let title = label == "Annoncering" ? "Spend" : "Users";
            return title;
          },

          label: function (context) {
            let label = "";

            if (label) {
              label += "";
            }
            if (context.parsed.y !== null) {
              if (context.dataset.label === "Annoncering") {
                label +=
                  new Intl.NumberFormat("en-DK").format(context.parsed.y) +
                  " DKK";
              } else {
                label += context.parsed.y;
              }
            }

            return label;
          },
        },
        displayColors: false,
        padding: 10,
        backgroundColor: "#FFFFFF",
        borderWidth: 2,
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
        titleFont: {
          size: "14",
        },
        bodyFont: {
          size: "14",
          family: " Cerebri sans-light",
        },
      },
      legend: {
        display: true,
        position: "bottom",
        align: "center",
        // font: { size: '52px',fontFamily:"Cerebri sans-medium" },
        // title: {
        // font: { size: '52px',fontFamily:"Cerebri sans-medium" },
        // },
        labels: {
          boxWidth: 5,
          boxHeight: 5,
          padding: 20,
          font: { family: "Cerebri Sans-medium", size: "12px" },
        },
      },
    },
    scales: {
      y: {
        grid: {
          borderDash: [5, 10],
          color: "#F4F5F5",
        },
        beginAtZero: true,
        stacked: false,
        ticks: {
          stepSize: 1000,
          color: "#888888",
          font: {
            family: "Cerebri sans-light",
          },
        },
      },
      x: {
        grid: {
          borderDash: [5, 10],
          color: "#ffff",
        },
        ticks: {
          color: "#888888",
          font: {
            family: "Cerebri sans-light",
          },
        },
        beginAtZero: true,
      },
    },
  };

  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "traffic-divided-by-platform";

  const PNGFunction = PNGWidget({ refObj, fileName });
  const JPGFunction = JPGWidget({ refObj, fileName });
  const SVGFunction = SVGWidget({ refObj, fileName });

  return (
    <>
      {/* <div
        className="p-3 customers-region radius-8"
        style={{ background: "#FFFFFF" }}
        ref={myref}
      > */}
      <div className="card-body p-3" ref={myref}>
        <Row className="d-flex  align-items-center">
          <Col xl={6} lg={12}>
            <div className="d-flex  align-items-center">
              <H3 className=" me-2 ">
                Traffic over time divided by platform
              </H3>{" "}
              <CustomToolTip
                size="sm"
                text="Traffic over time divided by platform"
              />
            </div>
          </Col>
          <Col xl={6}>
            <div className="d-flex justify-content-between align-items-center gap-2">
              <div className=" light-font-text fs-14 me-2">Visitors</div>
              <Switch id={1} />{" "}
              <div className=" light-font-text fs-14 me-2">New visitors</div>
              <div className="w-50 d-flex justify-content-end gap-3">
                <div style={{ width: "100%" }}>
                  <ReactSelect
                    clearable={false}
                    isSearchable
                  // options={AllData?.map((zip) => ({
                  //   value: zip?.id,
                  //   label: `${zip?.value}`,
                  // }))}
                  />
                </div>
                <div>
                  <Menus
                    itemheight={40}
                    itemwidth={40}
                    itemcontainerheight={200}
                    itemcontainerwidth={200}
                    component={
                      <div className="fs-14">
                        <div className="addpropContent px-3 py-2 ">
                          <span className="ms-1" onClick={() => PNGFunction()}>
                            Download widget as PNG
                          </span>
                        </div>
                        <hr className="addpropertyHr m-0"></hr>
                        <div
                          onClick={() => JPGFunction()}
                          className="addpropContent px-3 pt-2 pb-2 "
                        >
                          <span className="ms-1">Download widget as JPG</span>
                        </div>
                        <hr className="addpropertyHr m-0"></hr>
                        <div
                          onClick={() => SVGFunction()}
                          className="addpropContent px-3 pt-2 pb-2 "
                        >
                          <span className="ms-1">Download widget as SVG</span>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {data.length == 0 ? (
          <div className="">
            <div style={{ height: "340px", width: "100%" }}>
              <Bar data={data} options={config} />
            </div>
          </div>
        ) : (
          <NoDataScreen height={"377px"} imgHeight={"150px"} />
        )}
      </div>
    </>
  );
};

export default TrafficDividedByPlatform;
