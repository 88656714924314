import React, { useState, useEffect } from "react";
import PropertyTagDummyTable from "./PropertyTagDummyTable";
import ETButton from "../../../../Components/Buttons/ETButton";
import { GetUnitsByPropertyId } from "../../../../services/Units";
import { useGetUnitsByPropertyIdUpdated } from "../../../../hooks/queries/useUnitQueries";

const SelectUnits = ({
  setStepWizard,
  progress,
  setProgress,
  propertyId,
  tagName,
  setSelectedUnitsId,
  rowSelection,
  setRowSelection,
}) => {
  const [unitsTableData, setUnitsTableData] = useState([]);
  const [dataContainer, setDataContainer] = useState([]);
  const [tagUnitsTableData, setTagUnitsTableData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [originalTagData, setOriginalTagData] = useState([]);
  const [resetCondition, setResetCondition] = useState(false);

  const {
    data: GetUnitsByPropertyIdData,
    refetch: GetUnitsByPropertyIdDataRefetch,
    isLoading,
  } = useGetUnitsByPropertyIdUpdated(propertyId, 0);

  useEffect(() => {
    setTagUnitsTableData(GetUnitsByPropertyIdData?.data);
    setOriginalTagData(GetUnitsByPropertyIdData?.data)
    setNewData(GetUnitsByPropertyIdData?.data)
    if (resetCondition == true) {
      setTagUnitsTableData(GetUnitsByPropertyIdData?.data);
    }

  }, [GetUnitsByPropertyIdData, resetCondition]);



  const handleSevethScreen = () => {
    setStepWizard("NewTagConformation");
    setProgress(99.99);
  };

  const handleSixthScreen = () => {
    setStepWizard("SelectSigningPackage");
    setProgress(progress - 12.5);
  };
  const getTableUnitsByPropertyIdonFacilities = () => {
    try {
      GetUnitsByPropertyId(propertyId, 0)
        .then((data) => {
          if (data != null && data != undefined) {
            setUnitsTableData(data.data);
            setDataContainer(data.data);
          } else {
            setUnitsTableData([null]);
            setDataContainer([null]);
          }
        })
        .catch((error) => {
          // setError({ error: error.message, status: error.status });
          setUnitsTableData([null]);
          setDataContainer([null]);
        });
    } catch (e) {
      setUnitsTableData([null]);
      setDataContainer([null]);
      // setError({
      // error: true,
      // status: "",
      // message: "Failed to retrieve data",
      // });
    }
  };
  //  const pk=unitsTableData.unit_no
  //  const hp =pk.slice(-1)
  //  console.log(hp)

  useEffect(() => {
    getTableUnitsByPropertyIdonFacilities();
    const numAscending4 = [...unitsTableData].sort((a, b) => a.floor - b.floor);
    setUnitsTableData(numAscending4);
  }, [propertyId]);

  return (
    <div>
      <div className="ps-4 pt-3">
        <div className="fs-14 fw-400">
          <span className="form-label-title">Tag name :&nbsp;</span>
          {tagName}
        </div>
        <div className="light-font-text grey-text pb-2 mb-4">
          You are almost finish. Do you already know which units that need the
          new tag. Search and select below from the list.
        </div>

        <div className="my-4">
          <PropertyTagDummyTable
            setSelectedUnitsId={setSelectedUnitsId}
            setStepWizard={setStepWizard}
            tagUnitsTableData={tagUnitsTableData}
            setTagUnitsTableData={setTagUnitsTableData}
            originalTagData={originalTagData}
            resetCondition={resetCondition}
            setResetCondition={setResetCondition}
            newData={newData}
            setUnitsTableData={setUnitsTableData}
            dataContainer={dataContainer}
            setRowSelection={setRowSelection}
            rowSelection={rowSelection}
          />
        </div>
      </div>

      <div
        className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container"
        style={{
          position: "absolute",
          width: "100%",
          bottom: "0",
          zIndex: "3",
        }}
      >
        <ETButton
          variant={"white"}
          buttonText="PREVIOUS"
          onClick={() => {
            handleSixthScreen();
          }}
          fontSize="14px"
        />

        <ETButton
          variant={"primary"}
          buttonText="NEXT"
          onClick={() => handleSevethScreen()}
        />
      </div>
    </div>
  );
};

export default SelectUnits;
