import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  SearchStyle,
  SearchIcon,
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../../Components/table/MRTGlobalProps";
import MRTTheme from "../../../../Components/table/MRTTheme";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import { NoRecordsToDisplay } from "../../../../utils/validationConstants";
import NewColorBox from "../../../../Components/ColorCombinationbox/NewColorBox";
import CustomReactSelect from "../../../../Components/DropDown/CustomReactSelect";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import DropdownModalMui from "../../../../Components/DropdownModal/DropdownModalMui";
// import { useAllPropertyList } from "../../../../hooks/queries/usePropertyQueries";

const SelectPropertyFromTable = ({
  rowSelection,
  setRowSelection,
  AllPropertyListData,
}) => {
  // table column
  // const { data: AllPropertyListData } = useAllPropertyList(1)

  const columns = useMemo(
    () => [
      {
        accessorKey: "propertyName",
        header: "Property",
        size: 120,
        enableGlobalFilter: true,
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
      },
      {
        accessorKey: "city",
        header: "City",
        size: 50,
        enableGlobalFilter: true,
      },
      {
        accessorKey: "type",
        header: "Type",
        size: 50,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "Units",
        header: "Unit",
        size: 50,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "Availability",
        accessorFn: (row) => `${row.available}${row.availablePercentage}`,
        header: "Availability",
        size: 100,
        Cell: ({ row }) => {
          return (
            <div className="d-flex align-items-center">
              {row.original.available}{" "}
              {row.original.available > 1 ? "units" : "unit"} |{" "}
              {row.original.availablePercentage}%
            </div>
          );
        },
      },
      {
        accessorFn: (row) => `${row.idleness}${row.idlenessPercentage}`,
        accessorKey: "Idealness",
        header: "Idealness",
        size: 100,
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "9px !important",
          },
        },
        enableGlobalFilter: false,
        Cell: ({ cell, row }) => {
          return (
            <div className="d-flex align-items-center">
              {row.original.idleness}{" "}
              {row.original.idleness > 1 ? "units" : "unit"} |{" "}
              {row.original.idlenessPercentage}%
            </div>
          );
        },
      },
      {
        accessorFn: (row) => `${row.portfolioName} `,
        accessorKey: "portfolioName",
        header: "Portfolio",
        size: 120,
        Cell: ({ row }) => (
          <div>
            {row.original.portfolioName ? row.original.portfolioName : "-"}
          </div>
        ),
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) =>
          `${row.not_active} ${row.available} ${row.reserved}${row.in_process}${row.completed}`,
        header: "Units overview",
        disableSortBy: true,
        enableGlobalFilter: false,
        size: 180,
        Cell: ({ row }) => (
          <NewColorBox
            unitsOverview={row?.original?.status}
            TotalUnits={row?.original?.Units}
          />
        ),
      },
    ],
    []
  );
  const renderEmptyRowsFallback = () => (
    <div className="d-flex justify-content-center w-100 grey-text py-4 light-font-text">
      <div>No data available!!</div>
    </div>
  );
  return (
    <div>
      {" "}
      <div>
        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={columns}
              // data={AllPropertyListData?.data || []} //data is undefined on first render
              data={AllPropertyListData || []} //data is undefined on first render
              // data={[]}
              icons={{ ...SortIcon }}
              renderEmptyRowsFallback={renderEmptyRowsFallback}
              localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
              enableColumnActions={false}
              enableRowSelection
              enableRowActions={false}
              onRowSelectionChange={setRowSelection}
              getRowId={(row) => row.property_id}
              state={{ rowSelection }}
              enableSelectAll
              enableMultiRowSelection={false}
              enableTopToolbar={true}
              enableGlobalFilter
              globalFilterFn="contains"
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              renderTopToolbar={({ table }) => (
                <>
                  <div className="px-0 ">
                    <Row>
                      <Col xl={3} lg={4} className="pb-2">
                        {" "}
                        <MRT_GlobalFilterTextField table={table} />
                      </Col>
                      <Col xl={3} lg={4} className="ps-lg-0 pb-2">
                        <CustomReactSelect
                          placeholder={"Portfolio"}
                          searchBarHeader={"Search portfolio"}
                        />
                      </Col>
                      <Col xl={3} lg={4} className="ps-lg-0 pb">
                        <div></div>
                        <DropdownModalMui
                          text="Type"
                          width="200px"
                          component={
                            <>
                              {propertyTypesStore?.map((type, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="d-flex"
                                    style={{ alignItems: "center" }}
                                  >
                                    <div>
                                      <CustomCheckBox
                                        id={type?.Id}
                                        key={type?.Id}
                                        color="success"
                                        value={type?.Text}
                                        //   onChange={(e) => handleTypeFilter(e)}
                                        //   checked={propertyTypeSelected.includes(
                                        //     Number(type?.Id)
                                        //   )}
                                      />
                                    </div>
                                    <div className="light-font-text ">
                                      {type?.Text}
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                </>
              )}
              muiSelectCheckboxProps={{
                sx: {
                  color: "#d1d1d1",
                  "& .MuiSvgIcon-root": {
                    fontSize: "1.2rem !important",
                  },
                },
              }}
              muiSearchTextFieldProps={{
                ...SearchStyle,

                InputProps: {
                  placeholder: "Search for a unit...",
                  ...SearchIcon,
                },
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              displayColumnDefOptions={{
                "mrt-row-select": {
                  header: "",
                  size: 50,
                  muiTableBodyCellProps: {
                    sx: {
                      paddingRight: "0px !important",
                    },
                  },
                },
              }}
              positionActionsColumn="last"
              muiTablePaperProps={{
                elevation: 0,
                fontFamily: "Cerebri sans-light !important",
                sx: {
                  borderRadius: "0",
                  border: "none",
                  // backgroundColor: "#F9FAFA",
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                },
              }}
              muiTableBodyRowProps={{
                selected: false,
              }}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
            />
          }
        />
      </div>
    </div>
  );
};

export default SelectPropertyFromTable;

const propertyTypesStore = [
  { ID: 1, Text: "Sale" },
  { ID: 2, Text: "Leasing" },
];

const propertyStaticData = [
  {
    property_id: 911,
    division_id: 10001,
    company_id: 72,
    company_name: "cipla",
    image_url: "",
    propertyName: "X Propertie's",
    zipCode: "2000",
    city: "Frederiksberg",
    country_id: "DK",
    country: "Denmark",
    type: "Sale",
    type_id: 1,
    isStar: 1,
    portfolioName: "",
    Units: 505,
    available: "160",
    availablePercentage: "32",
    idleness: "160",
    idlenessPercentage: "32",
    hidden: 0,
    rented: 0,
    sold: 0,
    status: [
      {
        mainstatus_id: 1,
        status_name: "Not active",
        background_color: "#d9d9d9",
        font_color: "#FFFFFF",
        units: 345,
      },
      {
        mainstatus_id: 2,
        status_name: "Available",
        background_color: "#89BD89",
        font_color: "#FFFFFF",
        units: 160,
      },
      {
        mainstatus_id: 4,
        status_name: "In process",
        background_color: "#6D8BE5",
        font_color: "#FFFFFF",
        units: 0,
      },
      {
        mainstatus_id: 5,
        status_name: "Completed",
        background_color: "#FC4747",
        font_color: "#FFFFFF",
        units: 0,
      },
    ],
  },
  {
    property_id: 934,
    division_id: 10001,
    company_id: 31,
    company_name: "Cactus",
    image_url:
      "https://millicent-test.b-cdn.net/media/properties/934/img_652a6acf71216.png",
    propertyName: "Cactus Realty 3",
    zipCode: "1253",
    city: "K\u00f8benhavn K",
    country_id: "DK",
    country: "Denmark",
    type: "Leasing",
    type_id: 2,
    isStar: 1,
    portfolioName: "Cactus Porfolio",
    Units: 504,
    available: "150",
    availablePercentage: "30",
    idleness: "150",
    idlenessPercentage: "30",
    hidden: 0,
    rented: 0,
    sold: 0,
    status: [
      {
        mainstatus_id: 1,
        status_name: "Not active",
        background_color: "#d9d9d9",
        font_color: "#FFFFFF",
        units: 354,
      },
      {
        mainstatus_id: 2,
        status_name: "Available",
        background_color: "#89BD89",
        font_color: "#FFFFFF",
        units: 150,
      },
      {
        mainstatus_id: 4,
        status_name: "In process",
        background_color: "#6D8BE5",
        font_color: "#FFFFFF",
        units: 0,
      },
      {
        mainstatus_id: 5,
        status_name: "Completed",
        background_color: "#FC4747",
        font_color: "#FFFFFF",
        units: 0,
      },
    ],
  },
  {
    property_id: 945,
    division_id: 10001,
    company_id: 61,
    company_name: "Company232",
    image_url:
      "https://millicent-test.b-cdn.net/media/properties/945/img_652f72f45fd51.png",
    propertyName: "Plan3431",
    zipCode: "4720",
    city: "Pr\u00e6st\u00f8",
    country_id: "DK",
    country: "Denmark",
    type: "Leasing",
    type_id: 2,
    isStar: 1,
    portfolioName: "Plan Portfolio",
    Units: 6,
    available: "5",
    availablePercentage: "83",
    idleness: "5",
    idlenessPercentage: "83",
    hidden: 0,
    rented: 0,
    sold: 0,
    status: [
      {
        mainstatus_id: 1,
        status_name: "Not active",
        background_color: "#d9d9d9",
        font_color: "#FFFFFF",
        units: 1,
      },
      {
        mainstatus_id: 2,
        status_name: "Available",
        background_color: "#89BD89",
        font_color: "#FFFFFF",
        units: 5,
      },
      {
        mainstatus_id: 4,
        status_name: "In process",
        background_color: "#6D8BE5",
        font_color: "#FFFFFF",
        units: 0,
      },
      {
        mainstatus_id: 5,
        status_name: "Completed",
        background_color: "#FC4747",
        font_color: "#FFFFFF",
        units: 0,
      },
    ],
  },
  {
    property_id: 959,
    division_id: 10001,
    company_id: 31,
    company_name: "Cactus",
    image_url:
      "https://millicent-test.b-cdn.net/media/properties/959/img_6538f0299d6ee.png",
    propertyName: "Godrej Bliss",
    zipCode: "3700",
    city: "R\u00f8nne",
    country_id: "DK",
    country: "Denmark",
    type: "Leasing",
    type_id: 2,
    isStar: 1,
    portfolioName: "Cactus Porfolio",
    Units: 1,
    available: "1",
    availablePercentage: "100",
    idleness: "1",
    idlenessPercentage: "100",
    hidden: 0,
    rented: 0,
    sold: 0,
    status: [
      {
        mainstatus_id: 1,
        status_name: "Not active",
        background_color: "#d9d9d9",
        font_color: "#FFFFFF",
        units: 0,
      },
      {
        mainstatus_id: 2,
        status_name: "Available",
        background_color: "#89BD89",
        font_color: "#FFFFFF",
        units: 1,
      },
      {
        mainstatus_id: 4,
        status_name: "In process",
        background_color: "#6D8BE5",
        font_color: "#FFFFFF",
        units: 0,
      },
      {
        mainstatus_id: 5,
        status_name: "Completed",
        background_color: "#FC4747",
        font_color: "#FFFFFF",
        units: 0,
      },
    ],
  },
  {
    property_id: 976,
    division_id: 10001,
    company_id: 67,
    company_name: "cipla",
    image_url: "",
    propertyName: "5star",
    zipCode: "6094",
    city: "Hejls",
    country_id: "DK",
    country: "Denmark",
    type: "Leasing",
    type_id: 2,
    isStar: 1,
    portfolioName: "",
    Units: 1,
    available: "1",
    availablePercentage: "100",
    idleness: "0",
    idlenessPercentage: "0",
    hidden: 0,
    rented: 0,
    sold: 0,
    status: [
      {
        mainstatus_id: 1,
        status_name: "Not active",
        background_color: "#d9d9d9",
        font_color: "#FFFFFF",
        units: 0,
      },
      {
        mainstatus_id: 2,
        status_name: "Available",
        background_color: "#89BD89",
        font_color: "#FFFFFF",
        units: 1,
      },
      {
        mainstatus_id: 4,
        status_name: "In process",
        background_color: "#6D8BE5",
        font_color: "#FFFFFF",
        units: 0,
      },
      {
        mainstatus_id: 5,
        status_name: "Completed",
        background_color: "#FC4747",
        font_color: "#FFFFFF",
        units: 0,
      },
    ],
  },
];
