import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { GreenSpan } from "../../styles/Common.style";
import Menus from "../../Components/Menus/Menus";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import PreScreening from "./PreScreening";
import AssigAgents from "./AssignAgents";
import HandleYourBookings from "./HandleYourBookings";
import Publishevent from "./Publishevent";
import ETButton from "../../Components/Buttons/ETButton";
import SaveAsTemplate from "./SaveAsTemplate";

import { H1, H2 } from "../../styles/Common.style";

import { useGetEventAgentDetails } from "../../hooks/queries/useEventsQueries";
import { usePropertyId } from "../../hooks/stores/usePropertyStore";
import { useEventStore } from "../../hooks/stores/useEventStore";
import moment from "moment";
import { Col, Form, Row } from "react-bootstrap";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import { TabsButtonIcon } from "../../Components/Tabs/Tabs.style";
import OptionsForEvents from "./OptionsForEvents";
import { useGetGeographyCountry } from "../../hooks/queries/usePropertyQueries";
import {
  useGetCoordinates,
  useGetStreetDetails,
  useGetZipcodeByCountry,
} from "../../hooks/queries/useGeographyQueries";
import { Controller, useForm } from "react-hook-form";
import { REQUIRED_ERR } from "../../utils/validationConstants";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import CustomPopup from "../../Components/CustomPopup";
import { useGetUserById } from "../../hooks/queries/useUserQueries";
import UnitsFilterTable from "../../Components/UnitFilterTable/UnitsFilterTable";
import InputField from "../../Components/Textfield/InputField";
import TextAreaField from "../../Components/Textfield/TextAreaField";

const CustomToolbar = ({ label, date, onNavigate }) => {
  const handleNext = () => {
    // Custom logic for the next button
    // For example, you can navigate to the next month
    onNavigate("next", moment(date).add(1, "month"));
  };

  const handlePrev = () => {
    // Custom logic for the back button
    // For example, you can navigate to the previous month
    onNavigate("prev", moment(date).subtract(1, "month"));
  };

  return (
    <div className="rbc-toolbar ">
      <button onClick={handlePrev} type="button">
        <FontAwesomeIcon
          icon="fa-regular fa-angle-left"
          style={{ color: "#000000" }}
        />
      </button>
      <span className="rbc-toolbar-label ">{label}</span>
      <button onClick={handleNext} type="button">
        <FontAwesomeIcon
          icon="fa-regular fa-angle-right"
          style={{ color: "#000000" }}
        />
      </button>
    </div>
  );
};
const dateCellPropGetter = (date, eventDates) => {
  const hasEvent = eventDates.includes(date.toDateString()); // Check if there's an event on this date

  const style = {
    backgroundColor: hasEvent ? "red" : "", // Change the background color if there's an event
  };

  return { style };
};

const PropertyOpenHouse = ({ setShowOpenHouseProperty, propertyOverview }) => {
  //-------------------state--------------------------//
  const [selectedUnitsId, setSelectedUnitsId] = useState(false);
  const [publishEvents, setPublishEvents] = useState(false);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [preScreening, setPreScreening] = useState(false);
  const [maximumBooking, setMaximumBooking] = useState(false);
  const [qrCode, setQrcode] = useState(false);
  const [website, setWebsite] = useState(false);
  const [eido, setEido] = useState(false);
  const [numberOfBooking, setNumberOfBooking] = useState(false);
  const [intervalTimeDuration, setIntervalTimeDuration] = useState("");
  const [breakTime, setBreakTime] = useState("");
  const [numberOfParticipant, setNumberOfParticipant] = useState([]);
  const [cityName, setCityName] = useState("");
  const [country, setCountry] = useState("");

  const [endEventValue, setEndEventValue] = useState("");
  const [showInput, setShowInput] = useState(false);

  const [cancelEvent, setCancelEvent] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [AssignAgentsTable, setAssignAgentsTable] = useState("");

  //-------------Street search-------------//
  const [streetname, setStreetname] = useState("");
  const [searchedStreetName, setSearchedStreetName] = useState("");
  const [isSearchStreetVisible, setIsSearchStreetVisible] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const [rowSelection, setRowSelection] = useState({});
  const [events, setEvents] = useState([]);
  const AssignAgentsName = Object.keys(rowSelection);
  const formRef = useRef(null);
  const localizer = momentLocalizer(moment);

  const formats = {
    dateFormat: "D", // Used 'D' to display the date as a single digit
  };
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    watch,
    clearErrors,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: {
      nameofEvent: "",
      streetName: "",
      no: "",
      addInfo: "",
      detailedInfo: "",
      latitude: "",
      longitude: "",

      timeTo: "",
      timeFrom: "",
      eventStartingDate: "",
      city: "",
      zipcode: "",
    },
  });
  //-------------------Zustand--------------------------//
  const { eventOn } = useEventStore((state) => state);
  const propertyId = usePropertyId((state) => state.propertyId);

  //----------------react query----------------//

  const { data: getEventAgentDetailsData } =
    useGetEventAgentDetails(propertyId);

  // const { data: GetUserByIdData } = useGetUserById();
  const { data: getStreetDetailsData } =
    useGetStreetDetails(searchedStreetName);
  const { data: countryData } = useGetGeographyCountry();

  const AssignAgentsData = getEventAgentDetailsData?.data?.filter((item) => {
    const name = item?.user_id;
    return AssignAgentsName.includes(name);
  });

  const { data: zipCodeByCountryIdData } = useGetZipcodeByCountry(
    getValues("country")?.value
  );
  const { data: getCoordinatesByInfo } = useGetCoordinates(
    country + " " + getValues("zipcode") + "," + getValues("city")
  );

  // Schedule function everytime interval values changes
  useEffect(() => {
    generateSchedule(intervalTimeDuration, breakTime);
    setNumberOfParticipant([]);
    return () => {};
  }, [intervalTimeDuration, breakTime, watch("timeTo"), watch("timeFrom")]);

  // re-calculating calender event when dependancy data changes
  useEffect(() => {
    const startingDate = moment(watch("eventStartingDate"), "YYYY-MM-DD"); // converting date to moment object
    const endDate = moment(watch("eventEndDate"), "YYYY-MM-DD"); // converting date to moment object
    const getEventStartTime = getValues("timeTo");
    const getEventEndTime = getValues("timeFrom");

    let nextDateAfterCurrentDate = startingDate; // copying starting date so we won't change its value and get the changes in dates without affecting startingDate variable

    // this are the condition used to changes moment inputs as per event is choose
    const eventCondition = {
      day: {
        skippedDay: 1,
        momentProperty: "day",
      },
      week: {
        skippedDay: 7,
        momentProperty: "days",
      },
      fortnight: {
        skippedDay: 14,
        momentProperty: "days",
      },
      month: {
        skippedDay: 1,
        momentProperty: "month",
      },
    };

    const eventsDates = []; // will push event dates here as the event state wasn't working properly in loop

    // Event dates calculation as per end envent option
    if (getValues("endEvent")?.label === "On") {
      setValue("eventRepetitionDays", ""); // resetting repetitionday field
      if (
        startingDate.isValid() &&
        endDate.isValid() &&
        getValues("events") &&
        getEventStartTime &&
        getEventEndTime
      ) {
        // if the starting date is valid and we have events type and start and end time then only this if condition work
        while (nextDateAfterCurrentDate.isSameOrBefore(endDate)) {
          // this while loop will run till start date is less than end date to get event dates
          eventsDates.push({
            id: nextDateAfterCurrentDate.format("YYYY-MM-DD"),
            start: nextDateAfterCurrentDate.format("YYYY-MM-DD"),
            end: nextDateAfterCurrentDate.format("YYYY-MM-DD"),
          });
          nextDateAfterCurrentDate.add(
            eventCondition[getValues("events")?.value]?.skippedDay,
            eventCondition[getValues("events")?.value]?.momentProperty
          ); // Increment the current date by choosen event days
        }
      }
    } else if (getValues("endEvent")?.label === "After") {
      setValue("eventEndDate", ""); // resetting event end date field

      if (
        startingDate.isValid() &&
        getValues("eventRepetitionDays") &&
        getValues("events") &&
        getEventStartTime &&
        getEventEndTime
      ) {
        // same here checking if starting date is valid and checking if all the necessary input are their to enter if condition
        let i = 0;
        while (i < getValues("eventRepetitionDays")) {
          eventsDates.push({
            id: nextDateAfterCurrentDate.format("YYYY-MM-DD"),
            start: nextDateAfterCurrentDate.format("YYYY-MM-DD"),
            end: nextDateAfterCurrentDate.format("YYYY-MM-DD"),
          });
          nextDateAfterCurrentDate.add(
            eventCondition[getValues("events")?.value]?.skippedDay,
            eventCondition[getValues("events")?.value]?.momentProperty
          ); // Increment the current date by choosen event days; Also i have defined eventCondition in upper codes in which i have specify on which event input which date should be increament and which moment propety should be in use
          i++;
        }
      }
    } else {
      // this else part run when user didn't select any event (i.e "On" or "After") on that time the event should be run only for 1 day
      if (startingDate.isValid() && getEventStartTime && getEventEndTime) {
        eventsDates.push({
          id: nextDateAfterCurrentDate.format("YYYY-MM-DD"),
          start: nextDateAfterCurrentDate.format("YYYY-MM-DD"),
          end: nextDateAfterCurrentDate.format("YYYY-MM-DD"),
        });
      }
    }
    setEvents(eventsDates); // pushing all the events into event state to use in calender
  }, [
    watch("eventStartingDate"),
    watch("eventEndDate"),
    watch("events"),
    watch("endEvent"),
    watch("eventRepetitionDays"),
    watch("timeTo"),
    watch("timeFrom"),
  ]);
  // useEffect(() => {
  //   setSelectedTimezone({
  //     value: GetUserByIdData?.data?.[0]?.firstName,
  //     label: GetUserByIdData?.data?.[0]?.lastName,
  //   });
  // }, [GetUserByIdData]);

  useEffect(() => {
    setValue("latitude", getCoordinatesByInfo?.data?.latitude);
    setValue("longitude", getCoordinatesByInfo?.data?.longitude);
  }, [getCoordinatesByInfo]);

  const generateSchedule = (intervalTimeDuration, breakTime) => {
    const schedule = [];
    const format = "HH:mm";
    if (
      moment(watch("timeTo"), format).isValid() &&
      moment(watch("timeFrom"), format).isValid() &&
      moment(watch("timeTo"), format).isBefore(
        moment(watch("timeFrom"), format)
      ) &&
      intervalTimeDuration &&
      breakTime
    ) {
      let currentTime = moment(watch("timeTo"), format);

      while (currentTime.isBefore(moment(watch("timeFrom"), format))) {
        const startTimeFormatted = currentTime.format("HH:mm");

        currentTime.add(intervalTimeDuration, "minutes");
        const endTimeFormatted = currentTime.format("HH:mm");
        const isTimeExceedFromGivenTime = currentTime.isBefore(
          moment(watch("timeFrom"), format)
        );

        schedule.push(
          `${startTimeFormatted} - ${
            isTimeExceedFromGivenTime ? endTimeFormatted : watch("timeFrom")
          }`
        );
        currentTime.add(breakTime, "minutes");
      }
    } else {
      console.log("Invalid start or end time.");
    }
    setSchedule(schedule);
    // }
  };

  useEffect(() => {
    const AssignAgentData = getEventAgentDetailsData?.data?.map((item) => ({
      UserId: item?.user_id,
      Name: item?.full_name,
      Division: item?.division_name ? item?.division_name : "-",
      Availibility: item?.status,
    }));

    if (AssignAgentData?.length) {
      setAssignAgentsTable(
        AssignAgentData?.filter(
          (item) => item?.Name !== getValues("responsibleAgent")?.label
        )
      );
    }
  }, [watch("responsibleAgent")]);
  // useEffect(() => {
  //   const userMatch = getEventAgentDetailsData?.data?.find((item) =>
  //     GetUserByIdData?.data?.some((user) => user.user_id === item.user_id)
  //   );

  //   if (userMatch) {
  //     setValue("responsibleAgent", {
  //       value: userMatch.user_id,
  //       label: userMatch.full_name,
  //     });
  //   }
  // }, [getEventAgentDetailsData, GetUserByIdData, setValue]);

  // --------------------------handle zipcode change-----------------------//

  const handleZipCode = (e, ActionType) => {
    if (ActionType?.action === "clear") {
      setValue("city", "");
    } else {
      if (e?.value != "" || e?.value != "undefined") {
        // setshowzip(e?.value)
        const HandleCityName = zipCodeByCountryIdData?.data?.filter(
          (data) => data?.Id == e?.value
        );
        const CityData = HandleCityName?.[0]?.Text;
        setValue("city", CityData);
      }
    }
  };

  const displayItem = (selected) => {
    if (
      selected == "" ||
      selected?.value == undefined ||
      selected?.value == ""
    ) {
      return selected;
    } else {
      return {
        value: selected?.Id,
        label: countryData?.data?.filter(
          (item, i) => item.Id === selected?.value
        )?.[0]?.Text,
      };
    }
  };

  const calendarConfig = {
    components: {
      // event: CustomEvent,
      toolbar: CustomToolbar,
      // eventWrapper: CustomEventWrapper,
    },
  };

  const handleSelectChange = (event) => {
    const value = event?.value;
    setEndEventValue(value);

    // Determine whether to show input based on selected value
    setShowInput(value === "1");
    // setValue("events", "");
  };
  const eventDates = events.map((event) => event.start.toString()); // Extract event dates

  const eventRepetitionOptions = [
    { value: "day", label: "Every day" },
    { value: "week", label: "Every week" },
    { value: "fortnight", label: "Every fortnight" },
    { value: "month", label: "Every month" },
  ];

  const onSaveAndPublishEvent = (data) => {
    setPublishEvents(true);
  };

  const onSaveAsDraft = () => {
    setSaveAsDraft(true);
    // trigger();
  };
  const eventEndAfterdata = [
    { Id: 1, Label: "On" },
    { Id: 2, Label: "After" },
  ];

  const onErrorSaveAsDraft = () => {
    if (
      Object.keys(errors).length !== 0 ||
      Object.keys(dirtyFields).length == 0
    ) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
      toast.error("Please fill all required fields");
    } else {
      setSaveAsDraft(true);
    }
  };
  const onErrorSaveAndPublishEvent = (data) => {
    if (
      Object.keys(errors).length !== 0 ||
      Object.keys(dirtyFields).length == 0
    ) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
      toast.error("Please fill all required fields");
    } else {
      setPublishEvents(true);
    }
  };

  const watchFields = watch("country")?.value; // you can also target specific fields by their names

  const handleInputChange = (event) => {
    setStreetname(event?.target?.value);
    if (watchFields == "DK" && streetname?.length >= 4) {
      setIsSearchStreetVisible(true);
      setSearchedStreetName(event?.target?.value);
    } else {
      setIsSearchStreetVisible(false);
    }
  };

  const handleItemClick = (item, e) => {
    setStreetname(item);

    setValue(
      "zipcode",
      item?.zipcode === ""
        ? ""
        : {
            value: item?.zipcode,
            label: item?.zipcode,
          }
    );
    // Set the value directly
    setValue("city", item?.city === "" ? "" : item?.city);
    setValue("streetName", item?.streetName === "" ? "" : item?.street_name);
    setValue("no", item?.house_number === "" ? "" : item?.house_number);
  };

  return (
    <Form
      ref={formRef}
      onKeyDown={(e) => e.code == "Enter" && e.preventDefault()}
    >
      <div>
        <div className="pt-3 ">
          <H1>Open House Blomsterbjerget</H1>
          <div
            className="light-font-text"
            style={{
              cursor: "pointer",
            }}
          >
            <Link
              to="/properties"
              style={{ textDecoration: "none", color: "black" }}
            >
              <span className="me-3">Property</span>
            </Link>
            <span className="me-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />{" "}
            </span>
            <Link
              to="/property-units/overview"
              state={{
                Tab: "Overview",
              }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <span className="me-3">
                {propertyOverview?.[0]?.propertyName
                  ? propertyOverview?.[0]?.propertyName
                  : "propertyName"}
              </span>
            </Link>
            <span className="me-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />{" "}
            </span>
            <span className="me-3">Events</span>

            <span className="me-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />{" "}
            </span>
            <GreenSpan className="me-3 ">
              Open House{" "}
              {propertyOverview?.[0]?.propertyName
                ? propertyOverview?.[0]?.propertyName
                : "propertyName"}
            </GreenSpan>
          </div>
        </div>{" "}
        <div className="open-house-container p-4 mt-4 mb-4 radius-8">
          <div className="bg-white radius-8">
            <div className="d-flex justify-content-between px-4 py-3">
              <div className="d-flex flex-column ">
                <div className="d-flex align-items-center">
                  <H2>Open House Blomsterbjerget</H2>
                  <span className="ms-2">
                    <CustomToolTip
                      text="Open House Blomsterbjerget"
                      size="sm"
                    />
                  </span>
                </div>
              </div>
              <Menus
                component={
                  <div style={{ fontSize: "14px" }}>
                    <div className="addpropContent px-3 pt-2 pb-2 ">
                      <span className="ms-1">Delete event</span>
                    </div>
                  </div>
                }
              />
            </div>
            <hr className="my-0"></hr>
            <div className="p-4">
              <div className="event-section-title">Details about the event</div>
              <div className="light-font-text py-2 grey-text">
                Add information about the event and remember to set the options
                if you need them
              </div>
              {/* <Form onSubmit={handleSubmit(onFormSubmit)}> */}
              <Row>
                <Col xl={9} lg={8} className="pt-2">
                  <Row className="pb-3">
                    <Col lg={6}>
                      <Controller
                        control={control}
                        {...register("nameofEvent")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            {/* <input
                              type="text"
                              placeholder="Name of event"
                              id="nameofEvent"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("nameofEvent");
                              }}
                              maxLength={30}
                            /> */}
                            <InputField
                              id={"nameofEvent"}
                              value={value}
                              type={"text"}
                              maxLength
                              placeholder={"Name of event"}
                              errorscope={false}
                              iserror={errors?.nameofEvent}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("nameofEvent");
                              }}
                            />
                            {/* <label htmlFor="nameofEvent">Name of event</label> */}
                          </div>
                        )}
                      />
                      {errors?.nameofEvent &&
                        errors?.nameofEvent?.type === "required" && (
                          <p className="error-msg ">{REQUIRED_ERR}</p>
                        )}
                    </Col>
                    <Col
                      lg={6}
                      xl={4}
                      className="my-2 my-lg-0 ps-lg-0 d-flex align-items-end"
                    >
                      <GreenSpan className="fs-12 light-font-text  font-medium">
                        Use address from previous event
                      </GreenSpan>
                    </Col>
                  </Row>

                  <Row className="pb-3" style={{ position: "relative" }}>
                    <Col lg={6}>
                      <Controller
                        control={control}
                        {...register("streetName")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            {/* <input
                              type="text"
                              placeholder="Street"
                              id="streetName"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                handleInputChange(e);
                                clearErrors("streetName");
                              }}
                              maxLength={30}
                            /> */}
                            <InputField
                              id={"streetName"}
                              value={value}
                              type={"text"}
                              maxLength
                              placeholder={"Street"}
                              errorscope={false}
                              iserror={errors?.streetName}
                              onChange={(e) => {
                                onChange(e);
                                handleInputChange(e);
                                clearErrors("propName");
                              }}
                            />
                            {/* <label htmlFor="streetName">Street</label> */}
                          </div>
                        )}
                      />
                      {errors?.streetName &&
                        errors?.streetName?.type === "required" && (
                          <p className="error-msg ">{REQUIRED_ERR}</p>
                        )}
                      {streetname?.length < 4 &&
                      streetname?.length > 0 &&
                      watchFields == "DK" ? (
                        <div className="fs-12 fw-400 grey-text pt-1 ">
                          Type in minimum 4 characters to get a search result
                        </div>
                      ) : (
                        <>
                          {isSearchStreetVisible && watchFields == "DK" && (
                            <div style={{}}>
                              <div
                                className="bg-white radius-8 pe-1 filtered-list"
                                style={{
                                  border: "1px solid #D1D1D1",
                                  position: "absolute",
                                  zIndex: "10",
                                  width: "47%",
                                }}
                              >
                                <>
                                  {getStreetDetailsData?.data?.length > 0 ? (
                                    <div>
                                      {getStreetDetailsData?.data?.map(
                                        (item, index) => (
                                          <div
                                            className="mt-1 px-2"
                                            key={index}
                                            onClick={() => {
                                              handleItemClick(item);
                                              setIsSearchStreetVisible(false);
                                            }}
                                          >
                                            {item?.city ||
                                            item?.zipcode ||
                                            item?.house_number ? (
                                              <> {item?.address} </>
                                            ) : (
                                              <> {item?.street_name}</>
                                            )}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ) : (
                                    <div className="mt-1 px-2 fw-300 grey-text">
                                      No data found
                                    </div>
                                  )}
                                </>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </Col>
                    <Col xl={4} lg={6}>
                      <Row>
                        <Col className="my-2 my-lg-0 ps-lg-0">
                          <Controller
                            control={control}
                            {...register("no")}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <div>
                                {/* <input
                                  type="text"
                                  placeholder="No"
                                  id="no"
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    clearErrors("no");
                                  }}
                                  maxLength={30}
                                /> */}
                                <InputField
                                  id={"no"}
                                  value={value}
                                  type={"text"}
                                  maxLength
                                  placeholder={"No"}
                                  errorscope={false}
                                  iserror={errors?.no}
                                  onChange={(e) => {
                                    onChange(e);
                                    handleInputChange(e);
                                    clearErrors("propName");
                                  }}
                                />
                                {/* <label htmlFor="no">No</label> */}
                              </div>
                            )}
                          />
                          {errors?.no && errors?.no?.type === "required" && (
                            <p className="error-msg ">{REQUIRED_ERR}</p>
                          )}
                        </Col>
                        <Col className="my-2 my-lg-0 ps-lg-0">
                          <Controller
                            control={control}
                            {...register("addInfo")}
                            // rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <div className="form-input ">
                                <input
                                  type="text"
                                  placeholder="Add Info"
                                  id="addInfo"
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    clearErrors("addInfo");
                                  }}
                                  maxLength={30}
                                />
                                <label htmlFor="addInfo">Add Info</label>
                              </div>
                            )}
                          />
                          {errors?.addInfo &&
                            errors?.addInfo?.type === "required" && (
                              <p className="error-msg ">{REQUIRED_ERR}</p>
                            )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="pb-3">
                    <Col xl={4} lg={4} className="my-2 my-lg-0">
                      {/* <div className="form-input "> */}
                      <Controller
                        control={control}
                        {...register("country")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <ReactSelect
                              placeholder="Country"
                              clearable={false}
                              // isSearchable
                              iserror={errors?.country}
                              errorScope={false}
                              options={countryData?.data?.map((item) => ({
                                value: item?.Id,
                                label: (
                                  <div
                                    className="d-flex align-items-center"
                                    style={{ fontFamily: "Cerebri sans" }}
                                  >
                                    <div className="radius-4 me-2">
                                      <img
                                        src={item.DataValue1}
                                        style={{
                                          width: "20px",
                                          marginRight: "10px",
                                        }}
                                        alt=""
                                      />
                                    </div>
                                    <div>
                                      <div className="fs-14 light-font-text ">
                                        {" "}
                                        {item?.Text}
                                      </div>
                                    </div>
                                  </div>
                                ),
                              }))}
                              value={displayItem(value)}
                              onChange={(e, ActionType) => {
                                onChange(e);
                                setCountry(country);
                                setValue("zipcode", "");
                                setCityName("");
                                clearErrors("country");
                                setValue("zipcode", "");
                                // Set the value directly
                                setValue("city", "");
                                setValue("streetName", "");
                                setValue("no", "");
                                setValue("lat", "");
                                setValue("long", "");
                              }}
                            />
                          );
                        }}
                      />
                      {errors?.country &&
                        errors?.country?.type === "required" && (
                          <p className="error-msg ">{REQUIRED_ERR}</p>
                        )}
                    </Col>
                    <Col xl={3} lg={4} className="my-2 my-lg-0 ps-0 ">
                      <Controller
                        control={control}
                        {...register("zipcode")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <ReactSelect
                              placeholder="Zip code"
                              iserror={errors?.zipcode}
                              errorScope={false}
                              options={zipCodeByCountryIdData?.data?.map(
                                (zip) => ({
                                  value: zip?.Id,
                                  label: `${zip?.Id} ${zip?.Text}`,
                                })
                              )}
                              value={
                                value?.value
                                  ? {
                                      value: value?.value,
                                      label: value?.value,
                                    }
                                  : ""
                              }
                              onChange={(e, ActionType) => {
                                handleZipCode(e, ActionType);
                                onChange(e);
                                clearErrors("zipcode");
                              }}
                            />
                          );
                        }}
                      />
                      {errors?.zipcode &&
                        errors?.zipcode?.type === "required" && (
                          <p className="error-msg ">{REQUIRED_ERR}</p>
                        )}
                    </Col>
                    <Col xl={3} lg={4} className="ps-lg-0 ps-lg-0">
                      <div className="form-input ">
                        <Controller
                          name="city"
                          {...register("city")}
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <div className="form-input ">
                              <input
                                type="text"
                                disabled
                                placeholder="City"
                                id="city"
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  clearErrors("city");
                                }}
                              />
                              <label htmlFor="city">City</label>
                            </div>
                          )}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="pb-3">
                    <Col xl={4} lg={4}>
                      <Controller
                        control={control}
                        {...register("latitude")}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <div className="form-input ">
                            <input
                              type="text"
                              placeholder="Latitude"
                              id="latitude"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("latitude");
                              }}
                            />
                            <label htmlFor="latitude">Latitude</label>
                          </div>
                        )}
                      />
                      {errors?.latitude &&
                        errors?.latitude?.type === "required" && (
                          <p className="error-msg ">{REQUIRED_ERR}</p>
                        )}
                    </Col>
                    <Col xl={3} lg={4} className="my-2 my-lg-0 ps-lg-0">
                      <Controller
                        control={control}
                        {...register("longitude")}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <div className="form-input ">
                            <input
                              type="text"
                              placeholder="Longitude"
                              id="longitude"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("longitude");
                              }}
                            />
                            <label htmlFor="longitude">Longitude</label>
                          </div>
                        )}
                      />
                      {errors?.longitude &&
                        errors?.longitude?.type === "required" && (
                          <p className="error-msg ">{REQUIRED_ERR}</p>
                        )}
                    </Col>
                    <Col xl={3} lg={4}>
                      <div className=" px-xl-2  my-2 ">
                        <span className="d-flex align-items-center">
                          <TabsButtonIcon>
                            <FontAwesomeIcon
                              size="sm"
                              icon="fa-solid fa-location-dot"
                              // onClick={() => getCoordinatesByInfoRefetch()}
                            />
                          </TabsButtonIcon>
                          <GreenSpan className="ms-1 fs-12 font-medium">
                            Change coordinates
                          </GreenSpan>
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row className="pb-3">
                    <Col xl={7} lg={8}>
                      <Controller
                        control={control}
                        {...register("detailedInfo")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            {/* <input
                              type="text"
                              placeholder="Detailed information about the event"
                              id="detailedInfo"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("detailedInfo");
                              }}
                              maxLength={30}
                            /> */}
                            <InputField
                              id={"detailedInfo"}
                              value={value}
                              type={"text"}
                              maxLength
                              placeholder={
                                "Detailed information about the event"
                              }
                              errorscope={false}
                              iserror={errors?.detailedInfo}
                              onChange={(e) => {
                                onChange(e);
                                handleInputChange(e);
                                clearErrors("propName");
                              }}
                            />
                            {/* <label htmlFor="detailedInfo">
                              Detailed information about the event
                            </label> */}
                          </div>
                        )}
                      />
                      {errors?.detailedInfo &&
                        errors?.detailedInfo?.type === "required" && (
                          <p className="error-msg ">{REQUIRED_ERR}</p>
                        )}
                    </Col>
                  </Row>

                  <Row>
                    <Col xl={7} lg={8}>
                      <Controller
                        control={control}
                        {...register("responsibleAgent")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <ReactSelect
                            placeholder="Responsible agent"
                            value={value}
                            options={getEventAgentDetailsData?.data?.map(
                              (item) => ({
                                value: item?.user_id,
                                label: item?.full_name,
                              })
                            )}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        )}
                      />
                      {errors?.responsibleAgent &&
                        errors?.responsibleAgent?.type === "required" && (
                          <p className="error-msg ">{REQUIRED_ERR}</p>
                        )}
                    </Col>
                  </Row>

                  {/* {showPrivateEventPage && ( */}
                  {/* <Row>
                    <Col lg={10} className="pe-lg-0 mt-2 mb-sm-3 mb-lg-0">
                      <div className="light-font-text d-flex align-items-center">
                        {" "}
                        Send booking confirmation to the participant{" "}
                        <Toggle id={"Id"} />
                      </div>
                    </Col>
                  </Row> */}
                  {/* )} */}
                </Col>
                <Col xl={3} lg={4}>
                  {/* {!showPrivateEventPage && ( */}
                  <OptionsForEvents
                    preScreening={preScreening}
                    setPreScreening={setPreScreening}
                    maximumBooking={maximumBooking}
                    setMaximumBooking={setMaximumBooking}
                    qrCode={qrCode}
                    setQrcode={setQrcode}
                    website={website}
                    setWebsite={setWebsite}
                    eido={eido}
                    setEido={setEido}
                  />
                  {/* )} */}
                </Col>
              </Row>
              {/* <input type="Submit" /> */}
              {/* </Form> */}
            </div>
            <hr></hr>

            <div className="px-4">
              <div className="event-section-title">Internal note</div>
              <div className="light-font-text py-2 grey-text">
                Add internal note only your colleagues can see
              </div>

              <div>
                <Controller
                  control={control}
                  {...register("internalNote")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      {/* <textarea
                        name="internalNote"
                        // class="inputText"
                        cols="20"
                        rows="4"
                        placeholder="Internal note"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          // setInternalNote(e.target.value);
                        }}
                      ></textarea> */}
                      <TextAreaField
                        placeholder={"Internal note"}
                        value={value}
                        errorscope={false}
                        iserror={errors?.internalNote}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                      {/* <label className="floating-label">Internal note</label> */}
                    </div>
                  )}
                />
                {errors?.internalNote &&
                  errors?.internalNote?.type === "required" && (
                    <p className="error-msg ">{REQUIRED_ERR}</p>
                  )}
              </div>
            </div>
            {eventOn == 2 && (
              <>
                {" "}
                <hr></hr>
                <div>
                  <div className="px-4">
                    {" "}
                    <div className="event-section-title">
                      Select the unit(s)
                    </div>
                    <div className="light-font-text py-2 grey-text">
                      Search and select the unit(s) you want to add to your
                      event from the list below.
                    </div>
                  </div>

                  <UnitsFilterTable
                    setSelectedUnitsId={setSelectedUnitsId}
                    selectedUnitsId={selectedUnitsId}
                  />
                </div>
              </>
            )}
            <hr className="mb-0"></hr>

            <PreScreening />
            <div
              className="px-4 py-4"
              style={{
                borderTop: "1px solid #f0f0f0",
              }}
            >
              <div className="header-semibold mb-2"> Time of event</div>
              <div className=" light-font-te`xt mb-2 grey-text">
                {" "}
                Please provide details you'd like to incorporate into the event
                to create a more personalized and engaging experience.
              </div>
              <Row className="mt-4">
                {" "}
                <Col lg={6}>
                  <div className="form-label-title grey-text">
                    {" "}
                    Date and time
                  </div>{" "}
                </Col>{" "}
                <Col className="ps-lg-5" lg={6}>
                  {" "}
                  <div className="form-label-title grey-text">
                    {" "}
                    Repetition
                  </div>{" "}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col lg={6} className="pe-lg-5">
                  <Row>
                    <Col lg={4} className="pe-lg-0">
                      {" "}
                      <Controller
                        name="eventStartingDate"
                        {...register("eventStartingDate")}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, name, value } }) => (
                          <div className="form-input-group ">
                            <InputField
                              id="eventStartingDate"
                              value={value}
                              type="date"
                              placeholder="Starting Date"
                              iserror={errors?.eventStartingDate}
                              onChange={(e) => {
                                const format = "YYYY-MM-DD";
                                if (!getValues("eventEndDate")) {
                                  onChange(e);
                                } else if (
                                  getValues("eventEndDate") &&
                                  moment(e.target.value, format).isSameOrBefore(
                                    moment(getValues("eventEndDate"), format)
                                  )
                                ) {
                                  onChange(e);
                                } else {
                                  toast.error("Please select valid date");
                                }
                                clearErrors("eventStartingDate");
                              }}
                            />
                            {/* <input
                              type="date"
                              id="eventStartingDate"
                              placeholder="eventStartingDate"
                              value={value}
                              min={new Date().toISOString().split("T")[0]}
                              onChange={(e) => {
                                const format = "YYYY-MM-DD";
                                if (!getValues("eventEndDate")) {
                                  onChange(e);
                                } else if (
                                  getValues("eventEndDate") &&
                                  moment(e.target.value, format).isSameOrBefore(
                                    moment(getValues("eventEndDate"), format)
                                  )
                                ) {
                                  onChange(e);
                                } else {
                                  toast.error("Please select valid date");
                                }
                                clearErrors("eventStartingDate");
                              }}
                            /> */}
                            {/* <label htmlFor="eventStartingDate">
                              Starting date
                            </label> */}
                          </div>
                        )}
                      />
                      {/* {errors?.eventStartingDate && (
                        <p
                          className="error-msg"
                          style={{
                            color: "red",
                          }}
                        >
                          {REQUIRED_ERR}
                        </p>
                      )} */}
                    </Col>
                    <Col lg={4} className="pe-lg-0">
                      <Controller
                        name="timeTo"
                        {...register("timeTo")}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            {/* <input
                              type="time"
                              id="timeTo"
                              placeholder="target"
                              // min={new Date()}
                              min="15:30"
                              value={value}
                              onChange={(e) => {
                                // here I am checking if the start time is correct or not
                                if (
                                  !getValues("eventStartingDate") &&
                                  moment(e.target.value, "HH:mm").isSameOrAfter(
                                    moment()
                                  )
                                ) {
                                  // if starting date isn't their and the selected time is greater than present time then it will go inside if condition. e.g 16-10-2023 7:36pm -> selcted date and present time 16-10-2023 5:36pm, how can event run if the time is already passed present time.
                                  if (
                                    getValues("timeFrom") &&
                                    moment(e.target.value, "HH:mm").isAfter(
                                      moment(getValues("timeFrom"), "HH:mm")
                                    )
                                  ) {
                                    // if user already selected end time and end time is lower than present time than it should be reset start time to choose again
                                    onChange(e);
                                    toast.warn("Please re-select end time");
                                    setValue("timeFrom", "");
                                  } else {
                                    onChange(e);
                                  }
                                } else if (getValues("eventStartingDate")) {
                                  // if their is starting date then it will go inside this loop
                                  const selectedDateTime = moment(
                                    `${getValues("eventStartingDate")} ${
                                      e.target.value
                                    }`,
                                    "YYYY-MM-DD HH:mm"
                                  );
                                  if (
                                    selectedDateTime.isSameOrAfter(moment())
                                  ) {
                                    onChange(e);
                                  } else {
                                    toast.warn("Please select a correct time");
                                  }
                                } else {
                                  toast.warn("Please select a correct time");
                                }
                              }}
                            /> */}
                            <InputField
                              id={"timeTo"}
                              value={value}
                              type={"time"}
                              placeholder={"Starting time"}
                              iserror={errors?.timeTo}
                              onChange={(e) => {
                                if (
                                  !getValues("eventStartingDate") &&
                                  moment(e.target.value, "HH:mm").isSameOrAfter(
                                    moment()
                                  )
                                ) {
                                  // if starting date isn't their and the selected time is greater than present time then it will go inside if condition. e.g 16-10-2023 7:36pm -> selcted date and present time 16-10-2023 5:36pm, how can event run if the time is already passed present time.
                                  if (
                                    getValues("timeFrom") &&
                                    moment(e.target.value, "HH:mm").isAfter(
                                      moment(getValues("timeFrom"), "HH:mm")
                                    )
                                  ) {
                                    // if user already selected end time and end time is lower than present time than it should be reset start time to choose again
                                    onChange(e);
                                    toast.warn("Please re-select end time");
                                    setValue("timeFrom", "");
                                  } else {
                                    onChange(e);
                                  }
                                } else if (getValues("eventStartingDate")) {
                                  // if their is starting date then it will go inside this loop
                                  const selectedDateTime = moment(
                                    `${getValues("eventStartingDate")} ${
                                      e.target.value
                                    }`,
                                    "YYYY-MM-DD HH:mm"
                                  );
                                  if (
                                    selectedDateTime.isSameOrAfter(moment())
                                  ) {
                                    onChange(e);
                                  } else {
                                    toast.warn("Please select a correct time");
                                  }
                                } else {
                                  toast.warn("Please select a correct time");
                                }
                              }}
                            />

                            {/* <label htmlFor="timeTo">Starting time</label> */}
                          </div>
                        )}
                      />
                      {/* {errors?.timeTo && (
                        <p
                          className="error-msg"
                          style={{
                            color: "red",
                          }}
                        >
                          {REQUIRED_ERR}
                        </p>
                      )} */}
                      {/* <ReactSelect placeholder={"Starting time"} /> */}
                    </Col>
                    <Col lg={4} className="pe-lg-0">
                      <Controller
                        name="timeFrom"
                        // {...register("timeFrom")}
                        control={control}
                        rules={{ required: true, min: "15:00" }}
                        render={({ field: { onChange, value } }) => (
                          <div className="form-input-group ">
                            {/* <input
                              type="time"
                              id="timeFrom"
                              placeholder="target"
                              value={value}
                              onChange={(e) => {
                                if (
                                  !getValues("eventStartingDate") &&
                                  moment(e.target.value, "HH:mm").isSameOrAfter(
                                    moment()
                                  )
                                ) {
                                  //if starting date isn't their and selected time is greater then present time
                                  if (
                                    getValues("timeTo") &&
                                    moment(e.target.value, "HH:mm").isAfter(
                                      moment(getValues("timeTo"), "HH:mm")
                                    )
                                  ) {
                                    // if user already selected start time then end time should be less than start time
                                    onChange(e);
                                  } else {
                                    onChange(e);
                                    toast.warn("Please re-select start time"); // else re select option will show
                                    setValue("timeTo", "");
                                  }
                                } else if (getValues("eventStartingDate")) {
                                  const selectedDateTime = moment(
                                    `${getValues("eventStartingDate")} ${e.target.value
                                    }`,
                                    "YYYY-MM-DD HH:mm"
                                  );
                                  if (
                                    selectedDateTime.isSameOrAfter(moment())
                                  ) {
                                    if (
                                      getValues("timeTo") &&
                                      moment(e.target.value, "HH:mm").isAfter(
                                        moment(getValues("timeTo"), "HH:mm")
                                      )
                                    ) {
                                      onChange(e);
                                    } else {
                                      toast.warn(
                                        "Please select a correct time"
                                      );
                                    }
                                  } else {
                                    toast.warn("Please select a correct time");
                                  }
                                } else {
                                  toast.warn("Please select a correct time");
                                }
                              }}
                            /> */}
                            <InputField
                              id={"timeFrom"}
                              value={value}
                              type={"time"}
                              placeholder={"End time"}
                              iserror={errors?.timeFrom}
                              onChange={(e) => {
                                if (
                                  !getValues("eventStartingDate") &&
                                  moment(e.target.value, "HH:mm").isSameOrAfter(
                                    moment()
                                  )
                                ) {
                                  //if starting date isn't their and selected time is greater then present time
                                  if (
                                    getValues("timeTo") &&
                                    moment(e.target.value, "HH:mm").isAfter(
                                      moment(getValues("timeTo"), "HH:mm")
                                    )
                                  ) {
                                    // if user already selected start time then end time should be less than start time
                                    onChange(e);
                                  } else {
                                    onChange(e);
                                    toast.warn("Please re-select start time"); // else re select option will show
                                    setValue("timeTo", "");
                                  }
                                } else if (getValues("eventStartingDate")) {
                                  const selectedDateTime = moment(
                                    `${getValues("eventStartingDate")} ${
                                      e.target.value
                                    }`,
                                    "YYYY-MM-DD HH:mm"
                                  );
                                  if (
                                    selectedDateTime.isSameOrAfter(moment())
                                  ) {
                                    if (
                                      getValues("timeTo") &&
                                      moment(e.target.value, "HH:mm").isAfter(
                                        moment(getValues("timeTo"), "HH:mm")
                                      )
                                    ) {
                                      onChange(e);
                                    } else {
                                      toast.warn(
                                        "Please select a correct time"
                                      );
                                    }
                                  } else {
                                    toast.warn("Please select a correct time");
                                  }
                                } else {
                                  toast.warn("Please select a correct time");
                                }
                              }}
                            />

                            {/* <label htmlFor="timeFrom">End time</label> */}
                          </div>
                        )}
                      />
                      {/* {errors?.timeFrom && (
                        <p
                          className="error-msg"
                          style={{
                            color: "red",
                          }}
                        >
                          {REQUIRED_ERR}
                        </p>
                      )} */}
                    </Col>
                  </Row>
                </Col>
                <Col className="ps-lg-5" lg={6}>
                  <Row>
                    <Col lg={4} className="pe-lg-0">
                      <Controller
                        name="events"
                        {...register("events")}
                        control={control}
                        // rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          // <div className="form-input-group ">
                          <ReactSelect
                            placeholder={"Events"}
                            options={eventRepetitionOptions}
                            // clearable={true}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("events");
                            }}
                          />
                        )}
                      />
                      {errors?.events && (
                        <p
                          className="error-msg"
                          style={{
                            color: "red",
                          }}
                        >
                          {REQUIRED_ERR}
                        </p>
                      )}
                    </Col>
                    <Col lg={4} className="pe-lg-0">
                      <Controller
                        name="endEvent"
                        {...register("endEvent")}
                        control={control}
                        // rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <ReactSelect
                            placeholder={"End event"}
                            options={eventEndAfterdata?.map((item) => ({
                              value: item?.Id,
                              label: item?.Label,
                            }))}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              handleSelectChange(e);
                              clearErrors("endEvent");
                            }}
                          />
                        )}
                      />
                      {errors?.endEvent && (
                        <p
                          className="error-msg"
                          style={{
                            color: "red",
                          }}
                        >
                          {REQUIRED_ERR}
                        </p>
                      )}
                    </Col>
                    {endEventValue && (
                      <Col lg={4} className="">
                        {endEventValue === 1 && (
                          <Controller
                            name="eventEndDate"
                            {...register("eventEndDate")}
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, name, value } }) => (
                              <div className="form-input-group ">
                                <input
                                  type="date"
                                  id="eventEndDate"
                                  placeholder="eventEndDate"
                                  value={value}
                                  min={new Date().toISOString().split("T")[0]}
                                  onChange={(e) => {
                                    const format = "YYYY-MM-DD";
                                    if (!getValues("eventStartingDate")) {
                                      // if the starting date isn't there then both start and end time should be reset
                                      onChange(e);
                                      setValue("timeTo", "");
                                      setValue("timeFrom", "");
                                    } else if (
                                      getValues("eventStartingDate") &&
                                      moment(
                                        e.target.value,
                                        format
                                      ).isSameOrAfter(
                                        moment(
                                          getValues("eventStartingDate"),
                                          format
                                        )
                                      )
                                    ) {
                                      onChange(e);
                                    } else {
                                      toast.error("Please select valid date");
                                    }
                                    clearErrors("eventEndDate");
                                  }}
                                />
                                <label htmlFor="eventEndDate">End date</label>
                              </div>
                            )}
                          />
                        )}
                        {endEventValue === 2 && (
                          <Controller
                            name="eventRepetitionDays"
                            {...register("eventRepetitionDays")}
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <div className="form-input-group ">
                                <input
                                  type="number"
                                  id="eventRepetitionDays"
                                  placeholder="eventRepetitionDays"
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    clearErrors("eventRepetitionDays");
                                  }}
                                />
                                <label htmlFor="eventRepetitionDays">
                                  End after
                                </label>
                              </div>
                            )}
                          />
                        )}
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className="py-4 open-house-event-calendar">
                  <div className="py-4  radius-8">
                    <Calendar
                      localizer={localizer}
                      views={["month"]}
                      toolbar={(toolbarProps) => (
                        <CustomToolbar {...toolbarProps} />
                      )}
                      events={events}
                      dayPropGetter={(date) =>
                        dateCellPropGetter(date, eventDates)
                      } // Apply the dateCellPropGetter function
                      // Add other props and events as needed
                      formats={formats}
                      startAccessor="start"
                      endAccessor="end"
                      style={{ height: 472 }}
                      components={calendarConfig.components}
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <HandleYourBookings
              intervalTimeDuration={intervalTimeDuration}
              setIntervalTimeDuration={setIntervalTimeDuration}
              breakTime={breakTime}
              setBreakTime={setBreakTime}
              schedule={schedule}
              numberOfParticipant={numberOfParticipant}
              setNumberOfParticipant={setNumberOfParticipant}
              maximumBooking={maximumBooking}
              setMaximumBooking={setMaximumBooking}
              numberOfBooking={numberOfBooking}
              setNumberOfBooking={setNumberOfBooking}
              register={register}
              control={control}
              clearErrors={clearErrors}
              errors={errors}
            />
            <AssigAgents
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              getEventAgentDetailsData={getEventAgentDetailsData}
              data={AssignAgentsTable}
            />

            <Publishevent
              publishEvents={publishEvents}
              setPublishEvents={setPublishEvents}
              preScreening={preScreening}
              qrCode={qrCode}
              website={website}
              eido={eido}
              getValues={getValues}
              endEventValue={endEventValue}
              // internalNote={internalNote}
              AssignAgentsData={AssignAgentsData}
              schedule={schedule}
              numberOfParticipant={numberOfParticipant}
              intervalTimeDuration={intervalTimeDuration}
              breakTime={breakTime}
              setShowOpenHouseProperty={setShowOpenHouseProperty}
              maximumBooking={maximumBooking}
              numberOfBooking={numberOfBooking}
              cityName={cityName}
              selectedUnitsId={selectedUnitsId}
            />

            <SaveAsTemplate
              saveAsTemplate={saveAsTemplate}
              setSaveAsTemplate={setSaveAsTemplate}
              saveAsDraft={saveAsDraft}
              setSaveAsDraft={setSaveAsDraft}
              publishEvents={publishEvents}
              setPublishEvents={setPublishEvents}
              preScreening={preScreening}
              maximumBooking={maximumBooking}
              qrCode={qrCode}
              website={website}
              eido={eido}
              getValues={getValues}
              endEventValue={endEventValue}
              // internalNote={internalNote}
              AssignAgentsData={AssignAgentsData}
              schedule={schedule}
              numberOfParticipant={numberOfParticipant}
              intervalTimeDuration={intervalTimeDuration}
              breakTime={breakTime}
              setShowOpenHouseProperty={setShowOpenHouseProperty}
              numberOfBooking={numberOfBooking}
              selectedUnitsId={selectedUnitsId}
            />

            <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
              <ETButton
                variant={"white"}
                buttonText="CANCEL"
                onClick={() => {
                  setCancelEvent(true);
                }}
              />
              <div className="d-flex gap-3">
                <ETButton
                  buttonText="SAVE AS DRAFT"
                  variant={"secondary"}
                  type="submit"
                  onClick={handleSubmit(onSaveAsDraft, onErrorSaveAsDraft)}
                />

                <ETButton
                  buttonText="SAVE AND PUBLISH EVENT"
                  variant={"primary"}
                  type="submit"
                  onClick={handleSubmit(
                    onSaveAndPublishEvent,
                    onErrorSaveAndPublishEvent
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------Close Event  popup----------------- */}
      <CustomPopup
        open={cancelEvent}
        onHide={() => setCancelEvent(false)}
        title="Cancel"
        size="md"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        // subTitle={<div>xs</div>}
        titleTooltip={true}
        titleTooltipText={"Cancel Event"}
      >
        <div className="px-4 py-4">
          <div className="form-label-title">
            Are you sure you want to cancel?
          </div>
          <div className="light-font-text">
            All unsaved changes will be lost
          </div>
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => {
              setCancelEvent(false);
            }}
          />
          <ETButton
            variant={"primary"}
            buttonText="OK"
            fontSize="14px"
            fontWeight="500"
            onClick={() => {
              setShowOpenHouseProperty(false);
            }}
          />
        </div>
      </CustomPopup>
    </Form>
  );
};

export default PropertyOpenHouse;
