import { useMemo, useState } from "react";
import MRTTheme from "../../../../../../Components/table/MRTTheme.js";
import { MaterialReactTable } from "material-react-table";
import {
  SortIcon,
  TableRowsStyle,
  SearchIcon,
  SearchStyle,
  paginationoptions,
} from "../../../../../../Components/table/MRTGlobalProps.js";
import ETButton from "../../../../../../Components/Buttons/ETButton.js";
import { Col, Row } from "react-bootstrap";
import { H4 } from "../../../../../../styles/Common.style.js";
import CustomToolTip from "../../../../../../Components/Tooltip/CustomTooltip.js";
import Menus from "../../../../../../Components/Menus/Menus.js";
import CustomPopup from "../../../../../../Components/CustomPopup.js";
import AddPropertyAndEnergy from "../AddOperationAndEnergy.js";
import AddOperationManagerPopup from "./AddOperationManagerPopup.js";
import AddEnergyAndOperationWizard from "../../AddEnergyAndOperationWizard.js";
import AddCertification from "../certifications/AddCertification.js";

const Operations = () => {
  const [operation, setOperation] = useState(false);
  const [showContactMessage, setShowContactMessage] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showAddEnergyLabel, setShowAddEnergyLabel] = useState(false);
  const [showAddCertification, setShowAddCertification] = useState(false);
  const [progress, setProgress] = useState(0);

  const operationManagersColumns = useMemo(
    () => [
      {
        accessorKey: "role",
        header: "Role",
        enableHiding: false,
        size: 180,
        enableGlobalFilter: true,
      },
      {
        accessorKey: "name",
        header: "Name",
        enableHiding: false,
        size: 300,
        enableGlobalFilter: false,
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "0px !important",
          },
        },
      },
      {
        accessorKey: "contactPerson",
        header: "Contact Person",
        enableHiding: false,
        size: 180,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "Assigned",
        header: "Assigned",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "Expired",
        header: "Expired",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,
      },
    ],
    []
  );

  const propertyCertificationsColumns = useMemo(
    () => [
      {
        accessorKey: "certifications_type",
        header: "Certifications type",
        enableHiding: false,
        size: 180,
        enableGlobalFilter: true,
      },
      {
        accessorKey: "certifications_name",
        header: "Certification name",
        enableHiding: false,
        size: 300,
        enableGlobalFilter: false,
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "0px !important",
          },
        },
      },
      {
        accessorKey: "certifications_level",
        header: "Certification level",
        enableHiding: false,
        size: 180,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "created",
        header: "Created",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "created_by",
        header: "Created by",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,
      },
    ],
    []
  );

  const energyLabellingColumns = useMemo(
    () => [
      {
        accessorKey: "energy_label",
        header: "Energy label",
        enableHiding: false,
        size: 180,
        enableGlobalFilter: true,
      },
      {
        accessorKey: "issued_date",
        header: "Issued date",
        enableHiding: false,
        size: 300,
        enableGlobalFilter: false,
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "0px !important",
          },
        },
      },
      {
        accessorKey: "valid_from",
        header: "Valid from",
        enableHiding: false,
        size: 180,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "valid_to",
        header: "Created",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "supplier",
        header: "Supplier",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "contact_person",
        header: "Contact person",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,
      },
    ],
    []
  );

  return (
    <>
      {/* operation managers */}
      <div className="p-4">
        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={operationManagersColumns}
              icons={{ ...SortIcon }}
              data={[]}
              enableFilterMatchHighlighting={true}
              enableGlobalFilter
              // renderEmptyRowsFallback={renderEmptyRowsFallback}
              enableMultiRowSelection={false}
              enableRowSelection={false}
              enableRowActions
              // onRowSelectionChange={setRowSelection}// to add u
              globalFilterFn="contains" //turn off fuzzy matching and use simple contains filter function
              enableColumnActions={false}
              muiSearchTextFieldProps={{
                ...SearchStyle,

                InputProps: {
                  ...SearchIcon,
                },
              }}
              positionActionsColumn="last"
              renderRowActions={({ row, table }) => (
                <Menus
                  itemheight={24}
                  itemwidth={24}
                  iconborderradius="4px"
                  itemcontainerwidth="210px"
                  itemcontainerheight="300px"
                  component={
                    <>
                      <div className="light-font-text">
                        <div
                          className="addpropContent px-3 py-2 "
                          onClick={() => setOperation(true)}
                        >
                          <span className="ms-1">Edit details</span>
                        </div>
                        <hr className=" m-0"></hr>
                        <div
                          className="addpropContent px-3 py-2 "
                          onClick={() => setShowContactMessage(true)}
                        >
                          <span className="ms-1">Contact message</span>
                        </div>
                        <hr className="m-0"></hr>

                        <div
                          className="addpropContent px-3 pt-2 pb-2 "
                          onClick={() => setShowDeletePopup(true)}
                        >
                          <span className="ms-1">Delete</span>
                        </div>
                      </div>
                    </>
                  }
                />
              )}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  size: 50,
                  header: "",
                },
              }}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              muiTablePaperProps={{
                sx: {
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                },
              }}
              renderTopToolbar={({ table }) => (
                <>
                  <div className="mb-4">
                    {" "}
                    <Row>
                      <Col xl={6}>
                        <div className="d-flex align-items-center gap-2 mb-2">
                          <H4>Operation managers</H4>
                          <CustomToolTip
                            size="sm"
                            text={"Operations manager"}
                          />
                        </div>
                        <div className="light-font-text">
                          Below is a list of the operation managers on the
                          property.
                        </div>
                      </Col>
                      <Col
                        xl={{ span: 4, offset: 2 }}
                        md={{ span: 6, offset: 6 }}
                        className="ps-lg-0 d-lg-flex justify-content-lg-end align-items-lg-end"
                      >
                        <ETButton
                          variant={"primary"}
                          buttonText="ADD OPERATION MANAGER"
                          icon={true}
                          // width="100%"
                          onClick={() => setOperation(true)}
                        />
                      </Col>
                    </Row>
                  </div>
                </>
              )}
              positionGlobalFilter="left"
            />
          }
        />
      </div>

      {/* property certifications */}
      {/* <div className="p-4 border-top">
        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={propertyCertificationsColumns}
              icons={{ ...SortIcon }}
              data={propertyCertificationsData ?? []}
              enableFilterMatchHighlighting={true}
              enableGlobalFilter
              // renderEmptyRowsFallback={renderEmptyRowsFallback}
              enableMultiRowSelection={false}
              enableRowSelection={false}
              enableRowActions
              // onRowSelectionChange={setRowSelection}// to add u
              globalFilterFn="contains" //turn off fuzzy matching and use simple contains filter function
              enableColumnActions={false}
              muiSearchTextFieldProps={{
                ...SearchStyle,

                InputProps: {
                  ...SearchIcon,
                },
              }}
              positionActionsColumn="last"
              renderRowActions={({ row, table }) => (
                <Menus
                  itemheight={24}
                  itemwidth={24}
                  iconborderradius="4px"
                  itemcontainerwidth="210px"
                  itemcontainerheight="300px"
                  component={
                    <>
                      <div className="light-font-text">
                        <div
                          className="addpropContent px-3 py-2 "
                          onClick={() => setShowAddCertification(true)}
                        >
                          <span className="ms-1">Edit details</span>
                        </div>
                        <hr className=" m-0"></hr>

                        <div
                          className="addpropContent px-3 pt-2 pb-2 "
                          onClick={() => setShowDeletePopup(true)}
                        >
                          <span className="ms-1">Delete</span>
                        </div>
                      </div>
                    </>
                  }
                />
              )}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  size: 50,
                  header: "",
                },
              }}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              muiTablePaperProps={{
                sx: {
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                },
              }}
              renderTopToolbar={({ table }) => (
                <>
                  <div className="mb-4">
                    {" "}
                    <Row>
                      <Col xl={6}>
                        <div className="d-flex align-items-center gap-2 mb-2">
                          <H4>Property certifications</H4>
                          <CustomToolTip
                            size="sm"
                            text={"Property certifications"}
                          />
                        </div>
                        <div className="light-font-text">
                          Below is a list of the certifications on the property.
                        </div>
                      </Col>
                      <Col
                        xl={{ span: 4, offset: 2 }}
                        md={{ span: 6, offset: 6 }}
                        className="ps-lg-0 d-lg-flex justify-content-lg-end align-items-lg-end"
                      >
                        <ETButton
                          variant={"primary"}
                          buttonText="ADD CERTIFICATION"
                          icon={true}
                          // width="100%"
                          onClick={() => setShowAddCertification(true)}
                        />
                      </Col>
                    </Row>
                  </div>
                </>
              )}
              positionGlobalFilter="left"
            />
          }
        />
      </div> */}

      {/* Energy labelling */}
      {/* <div className="p-4 border-top">
        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={energyLabellingColumns}
              icons={{ ...SortIcon }}
              data={energeyLabellingData ?? []}
              enableFilterMatchHighlighting={true}
              enableGlobalFilter
              // renderEmptyRowsFallback={renderEmptyRowsFallback}
              enableMultiRowSelection={false}
              enableRowSelection={false}
              enableRowActions
              // onRowSelectionChange={setRowSelection}// to add u
              globalFilterFn="contains" //turn off fuzzy matching and use simple contains filter function
              enableColumnActions={false}
              muiSearchTextFieldProps={{
                ...SearchStyle,

                InputProps: {
                  ...SearchIcon,
                },
              }}
              positionActionsColumn="last"
              renderRowActions={({ row, table }) => (
                <Menus
                  itemheight={24}
                  itemwidth={24}
                  iconborderradius="4px"
                  itemcontainerwidth="210px"
                  itemcontainerheight="300px"
                  component={
                    <>
                      <div className="light-font-text">
                        <div
                          className="addpropContent px-3 py-2 "
                          onClick={() => setShowAddEnergyLabel(true)}
                        >
                          <span className="ms-1">Edit details</span>
                        </div>
                        <hr className=" m-0"></hr>

                        <div
                          className="addpropContent px-3 pt-2 pb-2 "
                          onClick={() => setShowDeletePopup(true)}
                        >
                          <span className="ms-1">Delete</span>
                        </div>
                      </div>
                    </>
                  }
                />
              )}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  size: 50,
                  header: "",
                },
              }}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              muiTablePaperProps={{
                sx: {
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                },
              }}
              renderTopToolbar={({ table }) => (
                <>
                  <div className="mb-4">
                    {" "}
                    <Row>
                      <Col xl={6}>
                        <div className="d-flex align-items-center gap-2 mb-2">
                          <H4>Energy labelling</H4>
                          <CustomToolTip
                            size="sm"
                            text={"Energy labelling"}
                          />
                        </div>
                        <div className="light-font-text">
                          Below is a list of the energy labelling on the
                          property.
                        </div>
                      </Col>
                      <Col
                        xl={{ span: 4, offset: 2 }}
                        md={{ span: 6, offset: 6 }}
                        className="ps-lg-0 d-lg-flex justify-content-lg-end align-items-lg-end"
                      >
                        <ETButton
                          variant={"primary"}
                          buttonText="ADD ENERGY LABEL"
                          icon={true}
                          // width="100%"
                          onClick={() => {
                            setShowAddEnergyLabel(true);
                            setProgress(50);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </>
              )}
              positionGlobalFilter="left"
            />
          }
        />
      </div> */}

      {/* for adding operation manager */}
      <CustomPopup
        open={operation}
        onHide={() => setOperation(false)}
        title={"Add Operation Manager"}
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        size={"xl"}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        titleTooltipText={"Add Operation Manager"}
      >
        <AddPropertyAndEnergy
          setOperation={setOperation}
          operation={operation}
          isEnergy={false}
        />
      </CustomPopup>

      {/* Delete popup */}
      <CustomPopup
        // modalHeadingStyle={modalHeadingStyle}
        style={{ position: "relative" }}
        size="md"
        open={showDeletePopup}
        onHide={() => setShowDeletePopup(false)}
        title="Are you carrying out the trash?"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() => {
          setShowDeletePopup(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div className="fs-14 fw-300 text-left mb-3">
            Are you sure you want to delete this ?
          </div>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => setShowDeletePopup(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="I'M SURE"
            onClick={() => setShowDeletePopup(false)}
          />
        </div>
      </CustomPopup>

      {/* Contact message popup */}
      <AddOperationManagerPopup
        open={showContactMessage}
        onHide={() => setShowContactMessage(false)}
      />

      {/* Add certification */}
      <AddCertification
        showAddCertification={showAddCertification}
        setShowAddCertification={setShowAddCertification}
      />

      {/* Add energy label */}
      <AddEnergyAndOperationWizard
        openAddEnergyWizard={showAddEnergyLabel}
        setOpenAddEnergyWizard={setShowAddEnergyLabel}
        progress={progress}
        setProgress={setProgress}
      />
    </>
  );
};

export default Operations;

// const operationManagersData = [
//   {
//     role: "Property manager",
//     name: "Newsec Property Asset Management Denmark A/S",
//     contactPerson: "Julie Ølgaard",
//     Assigned: "23 Aug, 2023",
//     Expired: "-",
//   },
//   {
//     role: "Facility manager",
//     name: "DEAS Facility Services",
//     contactPerson: "Jesper Larsen Nielsen",
//     Assigned: "23 Aug, 2023",
//     Expired: "23 Aug, 2023",
//   },
//   {
//     role: "Facility manager",
//     name: "Newsec Facility Services team",
//     contactPerson: "Julie Ølgaard",
//     Assigned: "23 Aug, 2023",
//     Expired: "-",
//   },
// ];

// const propertyCertificationsData = [
//   {
//     certifications_type: "Property manager",
//     certifications_name: "Newsec Property Asset Management Denmark A/S",
//     certification_level: "Julie Ølgaard",
//     created: "23 Aug, 2023",
//     created_by: "-",
//   },
//   {
//     certifications_type: "Facility manager",
//     certifications_name: "DEAS Facility Services",
//     certification_level: "Jesper Larsen Nielsen",
//     created: "23 Aug, 2023",
//     created_by: "23 Aug, 2023",
//   },
// ];

// const energeyLabellingData = [
//   {
//     energy_label: "Property manager",
//     issued_date: "Newsec Property Asset Management Denmark A/S",
//     valid_from: "Julie Ølgaard",
//     valid_to: "23 Aug, 2023",
//     supplier: "-",
//     contact_person: "Cristiano",
//   },
//   {
//     energy_label: "Facility manager",
//     issued_date: "DEAS Facility Services",
//     valid_from: "Jesper Larsen Nielsen",
//     valid_to: "23 Aug, 2023",
//     supplier: "23 Aug, 2023",
//     contact_person: "Cristiano",
//   },
// ];
