import { useQuery } from "react-query";
import { GetNotesCategory, GetNotesDetails } from "../../services/Notes";

//Get Notes details
export const useGetNotesDetails = (tenancy_id, dateFilter) => {
  return useQuery(
    ["qGetNotesDetails", tenancy_id, dateFilter],
    () => GetNotesDetails(tenancy_id, dateFilter),
    {
      enabled: !!dateFilter,
      enabled: !!tenancy_id,
    }
  );
};
//Get Notes category
export const useGetNotesCategory = () => {
  return useQuery(["qGetNotesCategory"], () => GetNotesCategory());
};
