import CreateNewAppoinmentConfirmation from "./CreateNewAppoinmentConfirmation";
import CustomPopup from "../../../../Components/CustomPopup";
import CreatingAppointment from "./CreatingAppointment";
import ETButton from "../../../../Components/Buttons/ETButton";
import { customproperties } from "../../../../styles/Theme";
import { useState } from "react";
import { StyledLoadingBar } from "../../../../styles/Common.style";
import LoadingBar from "react-top-loading-bar";
import AttachedDocuments from "./AttachedDocuments";
import SelectReasonCancelSigningOrder from "../../standardComponents/cancelSigningOrder/SelectReasonCancelSigningOrder";
import LeadCustomerInformation from "../../leads/createNewLead/LeadCustomerInformation";
import AddCustomerToAppointment from "./AddCustomerToAppointment";
import ConfirmCancelSigningOrder from "../../standardComponents/cancelSigningOrder/ConfirmCancelSigningOrder";
import { useGetlanguages } from "../../../../hooks/queries/uselanguageQueries";
import { async } from "q";
import { toast } from "react-toastify";
import {
  usePostAddActor,
  usePutUpdateActor,
} from "../../../../hooks/queries/useActorQueries";
import ExistingCustomer from "../ExistingCustomer";
const CreateNewAppointments = ({
  openNewAppointmentScreen,
  setOpenNewAppointmentScreen,
  title,
  titleTooltipText,
  setShowNewLead,
  setStepWizard,
  progress,
  setProgress,
  GetResponsibleAgentData,
  customerDetailsData,
}) => {
  // --------------------sates -----------------------------------//
  const [createAppoinmentWizard, setCreateAppoinmentWizard] = useState(
    "CreatingAppointment"
  );
  const [addExtraCustomer, setAddExtraCustomer] = useState(false);
  const [customerInformation, setCustomerInformation] = useState([]);
  const [existingActorDetails, setExistingActorDetails] = useState([]); //Existing customer information from database (API response)
  const [actorId, setActorId] = useState([]);

  // --------------------React Query -----------------------------------//
  const { data: getlanguagesData } = useGetlanguages();
  const { mutateAsync: PostAddActor, isLoading: PostAddActorIsLoading } =
    usePostAddActor();
  const { mutateAsync: PutUpdateActor, isLoading: PutUpdateActorIsLoading } =
    usePutUpdateActor();
  // ----------const and functions used in wizard --------------//

  const handleCreatingAppointmentNext = (data) => {
    setCreateAppoinmentWizard("AttachedDocuments");
    setProgress(50);
  };
  const handleCreatingAppointmentCancel = () => {
    setOpenNewAppointmentScreen(false);
    setProgress(0);
  };

  //--------------- handle add new customer ----------------//
  console.log(actorId, "actorIdactorIdactorId");
  const handleNewAddedCustomer = async (data) => {
    setCustomerInformation(data);

    const addActorPayload = {
      email: data?.email,
      firstname: data?.firstname,
      mobilenumber: data?.mobilenumber,
      lastname: data?.lastname,
      birthdate: data?.birthdate,
      country_id_nationality: data?.countryCode?.value,
      phone_calling_code_id: data?.countryCode?.label,
      street_name: data?.street_name,
      house_number: data?.house_number,
      side_door: data?.side_door,
      country_id: data?.country?.value,
      zipcode_id: data?.zipCode?.value,
      social_security_number: data?.social_security_number,
      floor_no: data?.floor_no,
      send_reservation: "0",
      screening_invitation: "0",
      is_mobilenumber_validated: "0",
      force_insert: "0",
      contract_id: "0",
      is_primary: "0",
      is_paying: "0",
      is_coresident: "0",
      is_subtenant: "0",
      is_guardian: "0",
      guardian_for_actor_id: "0",
      is_incompetent: "0",
      is_signing_contract: "0",
    };

    try {
      const response = await PostAddActor(addActorPayload);
      if (response?.data?.bReturn) {
        toast.success(response?.data?.message);
        setAddExtraCustomer(false);
        setExistingActorDetails(response?.data?.data?.actor_details);
      } else {
        toast.info(response?.data?.message);
        setCreateAppoinmentWizard("ExistingCustomer");
        setExistingActorDetails(response?.data?.data?.actor_details);
      }
    } catch (error) {
      toast.error(error?.message);
    }
    // setAddExtraCustomer(false);
  };
  //============================Existing customer button handlers ===========================//
  const handleExistingCustomerBack = () => {
    setCreateAppoinmentWizard("CreatingAppointment");
    setActorId([]);
  };
  console.log(customerInformation, "customerInformation");
  const handleCreateAsNewCustomer = async () => {
    const addActorPayload = {
      email: customerInformation?.email,
      firstname: customerInformation?.firstname,
      mobilenumber: customerInformation?.mobilenumber,
      lastname: customerInformation?.lastname,
      birthdate: customerInformation?.birthdate,
      country_id_nationality: customerInformation?.countryCode?.value,
      phone_calling_code_id: customerInformation?.countryCode?.label,
      street_name: customerInformation?.street_name,
      house_number: customerInformation?.house_number,
      side_door: customerInformation?.side_door,
      country_id: customerInformation?.country?.value,
      zipcode_id: customerInformation?.zipCode?.value,
      social_security_number: customerInformation?.social_security_number,
      floor_no: customerInformation?.floor_no,
      send_reservation: "0",
      screening_invitation: "0",
      is_mobilenumber_validated: "0",
      force_insert: "1",
      contract_id: "0",
      is_primary: "0",
      is_paying: "0",
      is_coresident: "0",
      is_subtenant: "0",
      is_guardian: "0",
      guardian_for_actor_id: "0",
      is_incompetent: "0",
      is_incompetent: "0",
    };

    try {
      const response = await PostAddActor(addActorPayload);
      if (response?.data?.bReturn) {
        toast.success(response?.data?.message);
        setExistingActorDetails(response?.data?.data?.actor_details);
        setCreateAppoinmentWizard("CreatingAppointment");
        setAddExtraCustomer(false);
        setActorId((prev) => [...prev, response?.data?.data?.actor_details]);
      } else {
        toast.info(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleExistingCustomerUpdateAndUse = async () => {
    const updateActorPayload = {
      actor_id: existingActorDetails?.actor_id,
      email: customerInformation?.email,
      firstname: customerInformation?.firstname,
      mobilenumber: customerInformation?.mobilenumber,
      lastname: customerInformation?.lastname,
      birthdate: customerInformation?.birthdate,
      country_id_nationality: customerInformation?.countryCode?.value,
      phone_calling_code_id: customerInformation?.countryCode?.label,
      street_name: customerInformation?.street_name,
      house_number: customerInformation?.house_number,
      floor_no: customerInformation?.floor_no,
      side_door: customerInformation?.side_door,
      country_id: customerInformation?.country?.value,
      zipcode_id: customerInformation?.zipCode?.value,
      social_security_number: customerInformation?.social_security_number,
      send_reservation: "0",
      screening_invitation: "0",
      is_mobilenumber_validated: "0",
      contract_id: "0",
      is_primary: "0",
      is_paying: "0",
      is_coresident: "0",
      is_subtenant: "0",
      is_guardian: "0",
      guardian_for_actor_id: "0",
      is_incompetent: "0",
      is_signing_contract: "0",
    };

    try {
      const response = await PutUpdateActor(updateActorPayload);
      if (response?.data?.bReturn) {
        toast.success(response?.data?.message);
        setCreateAppoinmentWizard("CreatingAppointment");
        setAddExtraCustomer(false);
        setActorId(response?.data);
      } else {
        toast.info(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };
  const handleToCreateAppointment = () => {
    setAddExtraCustomer(false);
  };
  //*--------------- handle add extra customer ----------------*//

  //*--------------- handle add inivitation customer ----------------*//

  const handleInvitationScreen = () => {
    setCreateAppoinmentWizard("InvitationSendToCustomer");
    setProgress(75);
  };
  const handleCreateAppoinmentScreen = () => {
    setCreateAppoinmentWizard("CreatingAppointment");
    setProgress(25);
  };
  //*--------------- handle add inivitation customer ----------------*//

  //--------------- handle appoinmentConfirmation -------------//

  const handleAppoinmentConfirmation = () => {
    setCreateAppoinmentWizard("CreateNewAppoinmentConfirmation");
    setProgress(99.99);
  };

  const handleAttacedDocumentScreen = () => {
    setCreateAppoinmentWizard("AttachedDocuments");
    setProgress(50);
  };
  //*--------------- handle appoinmentConfirmation -------------*//

  // *-------------- handle create new appointment confirmation -------------*//
  const handleCreateNewAppoinmentConfirmationClose = () => {
    setOpenNewAppointmentScreen(false);
    setCreateAppoinmentWizard("CreatingAppointment");
  };
  // *-------------- handle Close Create New appointment Popup -------------*//
  const handleCloseCreateNewAppointmentPopup = () => {
    setOpenNewAppointmentScreen(false);
    setCreateAppoinmentWizard("CreatingAppointment");
    setProgress(0);
  };
  // -------------------- wizard page ------------------------------//
  const WizardsPages = {
    CreatingAppointment: addExtraCustomer ? (
      <AddCustomerToAppointment
        flag="create new appointment"
        handleNext={handleNewAddedCustomer}
        handleBack={handleToCreateAppointment}
        getlanguagesData={getlanguagesData}
        customerDetailsData={customerDetailsData}
        customerInformationData={customerInformation}
      />
    ) : (
      <CreatingAppointment
        setAddExtraCustomer={setAddExtraCustomer}
        handleNext={handleCreatingAppointmentNext}
        handleCancel={handleCreatingAppointmentCancel}
        GetResponsibleAgentData={GetResponsibleAgentData}
        customerDetailsData={customerDetailsData}
        customerInformationData={customerInformation}
      />
    ),
    AttachedDocuments: (
      <AttachedDocuments
        handleCancel={handleCreateAppoinmentScreen}
        handleNext={handleInvitationScreen}
      />
    ),
    InvitationSendToCustomer: (
      <ConfirmCancelSigningOrder
        handleCreate={handleAppoinmentConfirmation}
        handleBack={handleAttacedDocumentScreen}
        cancelSecondPageReasonHeader={"Invitation send to the customer"}
        cancelSecondPageReasonHeaderTooltip={"Invitation send to the customer"}
      />
    ),

    CreateNewAppoinmentConfirmation: (
      <CreateNewAppoinmentConfirmation
        setCreateAppoinmentWizard={setCreateAppoinmentWizard}
        heading={"The appointment is now created"}
        subHeading={
          "The appointment with the customer has now been created in the system and a confirmation has been sent to the customer by email. The appointment can be found under Events and in My Activity."
        }
        clientName={"Thomas Høgh Mogensen"}
        eventType={"Appointment -"}
        eventData={
          "Thomas Høeg Mogensen - Dybbølsbro 3, 4 I 7-N340-4 I 212-076"
        }
        createdBy={"Created Sep 1, 2020 by Max Wildersans Brentford"}
        renderAppointmentDetails={true}
        renderContractDetails={false}
        customerData={"Thomas Høgh Mogensen, Camille Josephine Høgh"}
        propertyData={"Kaktus Towers"}
        addressData={"Dybølsbro 3, 17., door 11, 2450 København SV"}
        unitIdData={"212-187-002"}
        newAppointment={
          "Thomas Høeg Mogensen - Dybbølsbro 3, 4 I 7-N340-4 I 212-076"
        }
        showNewAppointment={false}
        showContractStats={false}
        contractsInMonth={3}
        contractsInYear={35}
        handleClose={handleCreateNewAppoinmentConfirmationClose}
      />
    ),
    ExistingCustomer: (
      <ExistingCustomer
        existingCustomerData={existingActorDetails}
        newDetails={customerInformation}
        handleBack={handleExistingCustomerBack}
        handleUpdateAndUse={handleExistingCustomerUpdateAndUse}
        handleCreateAsNewCustomer={handleCreateAsNewCustomer}
        disableButton={PostAddActorIsLoading || PutUpdateActorIsLoading}
      />
    ),
  };
  return (
    <div>
      <div>
        <CustomPopup
          open={openNewAppointmentScreen}
          title={"Create new appointment"}
          title_color="black"
          enableCrossButton={true}
          size={"xl"}
          titleTooltip={true}
          titleTooltipText={"Create new appointment"}
          extraInfo={true}
          likeFeature={true}
          extraInfoText={
            <div className="grey-light-font-text">Thomas Høgh Mogensen</div>
          }
          onClickCrossButton={handleCloseCreateNewAppointmentPopup}
        >
          <div>
            <StyledLoadingBar>
              <LoadingBar
                color={customproperties.primaryColor}
                progress={progress}
                containerStyle={{
                  position: "absolute",
                  height: "2.5px",
                }}
                shadow={false}
                onLoaderFinished={() => setProgress(0)}
              />
            </StyledLoadingBar>
            {/* <CreatingAppointment 
             /> */}

            {WizardsPages[createAppoinmentWizard]}
          </div>
        </CustomPopup>
      </div>
    </div>
  );
};

export default CreateNewAppointments;
