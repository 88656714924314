import React, {
  useMemo,
  useCallback,
  useState,
  useRef,
  useEffect,
} from "react";
import Menus from "../../../Components/Menus/Menus";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import "../../../styles/css/control-center/overview.css";
import PNGWidget from "../../../Components/DownloadWidget/PNGWidget";
import JPGWidget from "../../../Components/DownloadWidget/JPGWidget";
import SVGWidget from "../../../Components/DownloadWidget/SVGWidget";
import { Row, Col } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import styled from "@emotion/styled";
import MRTTheme from "../../../Components/table/MRTTheme";
import { NoRecordsToDisplay } from "../../../utils/validationConstants";
import {
  useGetUnitStatus,
  useGetUnitStatusByPropertyId,
} from "../../../hooks/queries/useControlCenterQueries";
import { useControlCenter } from "../../../hooks/stores/useControlCenterStore";
import { GetUnitStatusByPropertyId } from "../../../services/ControlCenter";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import { H2, H3 } from "../../../styles/Common.style";
import { SortIcon } from "../../../Components/table/MRTGlobalProps";

const ChartWrapper = styled.div`
  height: 201px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  line-height: 20px;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;
const UnitStatus = () => {
  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "unit-status";

  const PNGFunction = PNGWidget({ refObj, fileName });
  const JPGFunction = JPGWidget({ refObj, fileName });
  const SVGFunction = SVGWidget({ refObj, fileName });

  const { selectedDate, selectedProperties, selectedEndDate } =
    useControlCenter((state) => state);

  const { data: getUnitStatusByPropertyId } = useGetUnitStatusByPropertyId(
    selectedProperties,
    selectedDate,
    selectedEndDate
  );
  // Chart Data

  const labels =
    getUnitStatusByPropertyId?.data
      ?.filter((data) => data?.status_name !== "Total")
      ?.map((data) => data?.status_name) || [];
  const quantities =
    getUnitStatusByPropertyId?.data
      ?.filter((data) => data?.status_name !== "Total")
      ?.map((data) => data?.quantity) || [];
  const backgroundColors =
    getUnitStatusByPropertyId?.data
      ?.filter((data) => data?.status_name !== "Total")
      ?.map((data) => data?.background_color) || [];

  const data = {
    labels: labels,
    datasets: [
      {
        data: quantities,
        backgroundColor: backgroundColors,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutout: "80%",
    borderWidth: 2,
    plugins: {
      tooltip: {
        yAlign: "top",
        backgroundColor: "#FFFFFF",
        borderWidth: 2,
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
      },
      legend: {
        display: false,
      },
    },
  };

  const columns = useMemo(
    () => [
      {
        // accessorKey: "status",
        accessorFn: (row) => `${row.status_name} `,
        id: "status",
        header: <div className="table-property-name">Status</div>,
        enableGlobalFilter: false,
        enableSorting: false,
        size: 100,
        Cell: ({ row }) => (
          <div className="d-flex">
            <div
              className="radius-4 me-3"
              style={{
                backgroundColor: row.original.background_color,
                width: "13px",
                height: "13px",
                marginTop: "2px",
              }}
            ></div>
            <div className="">
              {row.original.status_name == "Total" ? (
                <div className="table-property-name">Total</div>
              ) : (
                <>{row.original.status_name}</>
              )}
            </div>
          </div>
        ),
      },
      {
        accessorKey: "quantity",
        id: "quantity",
        header: <div className="table-property-name">Quantity</div>,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 100,
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => (
          <div className="d-flex">
            <div
              className="radius-4 me-3"
              style={{
                backgroundColor: row.original.color,
                width: "13px",
                height: "13px",
                marginTop: "2px",
              }}
            ></div>
            <div className="">
              {row.original.status_name == "Total" ? (
                <div className="table-property-name">
                  {row.original.quantity}
                </div>
              ) : (
                <>{row.original.quantity}</>
              )}
            </div>
          </div>
        ),
      },
    ],
    []
  );

  const tableData = [
    {
      status: "Not Listed",
      quantity: 0,
      color: "#888888",
    },
    {
      status: "Available",
      quantity: 0,
      color: "#77C7A1",
    },
    {
      status: "Reserved",
      quantity: 0,
      color: "#F6E651",
    },
    {
      status: "Sold",
      quantity: 0,
      color: "#F1664B",
    },
    {
      status: "Leased",
      quantity: 0,
      color: "#A2C8D0",
    },
  ];

  return (
    <div
      className="card-body p-3"
      ref={myref}
      style={{
        minHeight: "575px",
      }}
    >
      <Row className="pb-4 d-flex align-items-center">
        <Col className="d-flex " sm={10}>
          {" "}
          <div className="d-flex align-items-center">
            <H3 className=" me-2 ">Unit status</H3>{" "}
            <CustomToolTip size="sm" text="Unit status" />
          </div>
        </Col>
        <Col sm={2} className="d-flex justify-content-end">
          {" "}
          <Menus
            itemheight={40}
            itemwidth={40}
            itemcontainerheight={200}
            itemcontainerwidth={200}
            component={
              <div className="fs-14">
                <div className="addpropContent px-3 py-2 ">
                  <span className="ms-1" onClick={() => PNGFunction()}>
                    Download widget as PNG
                  </span>
                </div>
                <hr className="addpropertyHr m-0"></hr>
                <div
                  onClick={() => JPGFunction()}
                  className="addpropContent px-3 pt-2 pb-2 "
                >
                  <span className="ms-1">Download widget as JPG</span>
                </div>
                <hr className="addpropertyHr m-0"></hr>
                <div
                  onClick={() => SVGFunction()}
                  className="addpropContent px-3 pt-2 pb-2 "
                >
                  <span className="ms-1">Download widget as SVG</span>
                </div>
              </div>
            }
          />
        </Col>
      </Row>
      <Row className="px-3 pb-5 d-flex align-items-center justify-content-center">
        {getUnitStatusByPropertyId !== 0 ? (
          <>
            <Col lg={5} className="pt-3">
              <ChartWrapper>
                <DoughnutInner>
                  <div className="light-font-text">Total units</div>
                  <H2 className=" fs-16">
                    {getUnitStatusByPropertyId?.data
                      ?.filter((data) => data?.status_name == "Total")
                      ?.map((data) => data?.quantity)}
                  </H2>
                </DoughnutInner>

                <Doughnut data={data} options={options} />
              </ChartWrapper>
              {/* <div className="grey-text fs-14" style={{ justifyContent: "center", display: "flex" }}>{NoRecordsToDisplay}</div> */}
            </Col>
            <Col lg={7} className="justify-content-end">
              <div>
                <MRTTheme
                  colorVariantsForTableData={["odd"]}
                  table={
                    <MaterialReactTable
                      columns={columns}
                      data={getUnitStatusByPropertyId?.data || []}
                      icons={{ ...SortIcon }}
                      // data={[]}
                      localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                      enableColumnActions={false}
                      enableBottomToolbar={false}
                      enableTopToolbar={false}
                      muiTableContainerProps={{
                        sx: {
                          paddingX: "0px",
                        },
                      }}
                      // muiTablePaperProps={{
                      //   sx: {
                      //     fontWeight: "300",
                      //     backgroundColor: "#fff",
                      //   },
                      // }}
                      // muiTableHeadCellProps={{
                      //   elevation: 0,
                      //   sx: {
                      //     fontWeight: "600",
                      //     fontSize: "13px",
                      //     fontFamily: "Cerebri sans !important",
                      //     boxShadow: "none",
                      //   },
                      // }}
                      // muiTablePaperProps={{
                      //   elevation: 0,
                      //   sx: {
                      //     fontFamily: "Cerebri sans !important",
                      //     borderRadius: "0",
                      //     border: "none",
                      //     fontWeight: "300",
                      //     backgroundColor: "#fff",
                      //   },
                      // }}
                      // muiTableContainerProps={{
                      //   // elevation: 0,
                      //   sx: {
                      //     paddingX: "10px",
                      //     boxShadow: "none",
                      //     ".css-vv34gr-MuiTableRow-root": {
                      //       boxShadow: "unset !important",
                      //     },
                      //     ".css-y34huh": {
                      //       boxShadow: "unset !important",
                      //     },
                      //   },
                      // }}
                      // muiTableBodyProps={{
                      //   // elevation: 0,
                      //   sx: {
                      //     "& tr:nth-of-type(odd)": {
                      //       backgroundColor: "#F9FAFA",
                      //       borderRadius: "8px",
                      //       fontWeight: "300",
                      //     },
                      //     td: {
                      //       fontFamily: "Cerebri Sans",
                      //       fontSize: "13px",
                      //       fontWeight: 300,
                      //       height: "67px",
                      //     },
                      //   },
                      // }}
                      muiTableBodyProps={{
                        sx: {
                          td: {
                            height: "56px",
                          },
                        },
                      }}
                    />
                  }
                />

                <div className="d-flex justify-content-between p-3">
                  {/* <div className="table-property-name">Total</div> */}
                  {/* <div className="table-property-name">{getUnitStatusByPropertyId?.data?.filter((data) => data?.status_name == 'Total')?.map((data) => data?.quantity)}</div> */}
                </div>
              </div>
            </Col>
          </>
        ) : (
          <div className="">
            <NoDataScreen height={"377px"} imgHeight={"150px"} />
          </div>
        )}
      </Row>
    </div>
  );
};

export default UnitStatus;
