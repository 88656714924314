import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Image, Placeholder, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import Menus from "../../Components/Menus/Menus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  useGetMediaFilesByPropertyId,
  useUploadMedia,
  usePutUpdateMediaFileCategory,
  useUpdateMediaPublishDetails,
} from "../../hooks/queries/useMediaQueries";

import {
  DeleteMediaFileByMediaFileId,
  GetMediaDetailsByMediaId,
  PutUpdateMediaDetails,
} from "../../services/Media";
import { toast } from "react-toastify";
import CustomPopup from "../../Components/CustomPopup";
import ETButton from "../../Components/Buttons/ETButton";
import { useQuery } from "react-query";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import {
  usePropertyId,
  usePropertyOverview,
} from "../../hooks/stores/usePropertyStore";
import moment from "moment";
import { status } from "../../model/Constant";
import {
  DropZoneBorder,
  GreenDiv,
  GreenSpan,
  MediaThumbnail,
  H2,
} from "../../styles/Common.style";
import { useForm, Controller } from "react-hook-form";
import InputField from "../../Components/Textfield/InputField";
import TextAreaField from "../../Components/Textfield/TextAreaField";
import { useUserDetails } from "../../hooks/stores/useUserDetailsStore";
import { UTCtoLocalTimeZone } from "../../utils/UTCtoLocalTimeZone";

const Media = ({
  mediaCategoryData,
  RefetchGetPropertyWithOverviewByPropertyIdData,
}) => {
  const [mediaFilesData, setMediaFilesData] = useState([]);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [showDeleteOnPreview, setShowDeleteOnPreview] = useState("");

  // to remove floor plan from categories
  let filteredMediaCategory = mediaCategoryData?.data?.filter(
    (ele) => ele.Id != 3
  );
  const categoryDropdown = (e, ActionTypes) => {
    if (ActionTypes.action === "clear") {
      setCategoryFilter("");
      setMediaFilesData(mediaFilesByPropertyIdData?.data);
    } else {
      setCategoryFilter(e.value);
      let filteredData = mediaFilesData?.filter(
        (fData, i) => fData?.media_file_category_id == String(e?.value)
      );
      setMediaFilesData(filteredData);
    }
  };
  // zustand
  const propOverview = usePropertyOverview((state) => state.propOverview);

  //--------------react query-------------//

  const {
    data: mediaFilesByPropertyIdData,
    isLoading,
    refetch: mediaFilesByPropertyIdDataRefetch,
  } = useGetMediaFilesByPropertyId(propOverview?.[0]?.property_id);
  const { mutateAsync: PostUploadMedia, isLoading: PostUploadMediaIsLoading } =
    useUploadMedia();

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    // uploadMedia(acceptedFiles);
    setMediaFiles((prev) => [
      ...prev,
      ...acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    ]);

    fileRejections.map(({ file, errors }) => {
      toast.error("Invalid file type " + file.name);
    });
  }, []);
  const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } =
    useDropzone({
      // Disable click and keydown behavior
      onDrop,
      noClick: true,
      noKeyboard: true,
      accept: {
        "image/jpeg": [],
        "image/png": [],
      },
      // onDrop: (acceptedFiles) => {
      //   // const oldData = [...files];
      //   // const addedData = [acceptedFiles];
      //   // const newArray = [...oldData, acceptedFiles];
      //   // console.log(newArray, "newArraynewArray");
      //   setFiles(
      //     acceptedFiles.map((file) =>
      //       Object.assign(file, {
      //         preview: URL.createObjectURL(file),
      //       })
      //     )
      //   );
      // },
    });

  useEffect(() => {
    setMediaFilesData(mediaFilesByPropertyIdData?.data);
  }, [mediaFilesByPropertyIdData]);
  // useEffect(() => {
  // 	setMediaFilesData(mediaFilesByPropertyIdData?.data);
  // }, []);

  const handleDrop = (droppedItem) => {
    //     // Ignore drop outside droppable container
    if (!droppedItem.destination) return;

    const updatedList = [...mediaFilesData];
    // //     // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setMediaFilesData(updatedList);
  };

  const removePreviewFile = (i) => {
    const newArray = [...mediaFiles];
    newArray.splice(i, 1);
    setMediaFiles(newArray);
  };

  const uploadMedia = () => {
    for (let i = 0; i < mediaFiles?.length; i++) {
      let id;
      if (!PostUploadMediaIsLoading) {
        id = toast.loading("Uploading file");
      }
      let formData = new FormData();
      formData.append("files[]", mediaFiles[i]);
      formData.append(
        "property_id",
        propOverview?.[0]?.property_id?.toString()
      );
      formData.append("package_id", "0");
      PostUploadMedia(formData)
        .then((response) => {
          mediaFilesByPropertyIdDataRefetch();
          toast.update(id, {
            render: response?.data?.message,
            isLoading: false,
            type: status.s,
            autoClose: 2000,
          });
          if (i + 1 == mediaFiles?.length) {
            setMediaFiles([]);
          }
        })
        .catch((error) => {
          toast.update(id, {
            render: "Failed to upload",
            isLoading: false,
            type: status.e,
            autoClose: 2000,
          });
        });
    }
  };

  const lengthofmedia = mediaFilesData?.data?.filter((item) => {
    if (categoryFilter == null || categoryFilter == "") {
      return item;
    } else if (item?.media_file_category_id == categoryFilter.toString()) {
      return item;
    }
  }).length;

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      mediaFiles.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);
  return (
    <>
      <div className="sub-media-container  py-3">
        <Placeholder animation="glow">
          <div className="pt-2 pb-3 px-4">
            {isLoading ? (
              <div className="">
                <div className="placeholder col-2 bg-secondary"></div>
              </div>
            ) : (
              <div className="form-label-title">Upload media</div>
            )}

            {isLoading ? (
              <div className="">
                <div className="placeholder col-3 bg-secondary"></div>
              </div>
            ) : (
              <div className="light-font-text grey-text">
                Lorem ipsum dolor sit pluralis oct
              </div>
            )}
          </div>
          <Row>
            <Col
              md={{ span: 12 }}
              className="px-3"
            >
              {isLoading ? (
                <div className="px-3">
                  <div
                    className="placeholder col-12 bg-secondary  p-5"
                    style={{ borderRadius: "9px" }}
                  ></div>
                </div>
              ) : (
                <div className="pt-1 px-3 mx-1 pb-3">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <DropZoneBorder className=" py-5 d-flex flex-column justify-content-center align-items-center ">
                      <div>
                        {/* <File /> */}
                        <FontAwesomeIcon
                          size="xl"
                          icon="fa-solid fa-file-image"
                          style={{ color: "#000000" }}
                        />
                      </div>
                      <div className="mt-2 mb-1 form-label-title">
                        Drop your file here, or&nbsp;
                        <GreenSpan
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={open}
                        >
                          browse your computer
                        </GreenSpan>
                      </div>
                      <div className="fs-12 light-font-text grey-text">
                        Supports only JPG and PNG
                      </div>
                    </DropZoneBorder>
                  </div>
                  <div className="d-flex flex-row flex-wrap mt-4">
                    {mediaFiles?.map((file, index) => (
                      <MediaThumbnail key={index}>
                        <div
                          id={index?.toString()}
                          onClick={() => removePreviewFile(index)}
                          onMouseEnter={(e) =>
                            setShowDeleteOnPreview(e.currentTarget)
                          }
                          onMouseLeave={() => setShowDeleteOnPreview(null)}
                        >
                          {showDeleteOnPreview?.id == index?.toString() && (
                            <FontAwesomeIcon
                              icon="fa-solid fa-trash"
                              style={{
                                position: "absolute",
                                color: "white",
                                top: "40px",
                                left: "40px",
                                zIndex: "1",
                              }}
                            />
                          )}
                          <img
                            src={file?.preview}
                            // Revoke data uri after image is loaded
                            // onLoad={() => {
                            //   URL.revokeObjectURL(file?.preview);
                            // }}
                          />
                        </div>
                      </MediaThumbnail>
                    ))}
                  </div>
                  {mediaFiles?.length > 0 && (
                    <div className="d-flex justify-content-end">
                      <ETButton
                        disabled={PostUploadMediaIsLoading}
                        variant={"primary"}
                        buttonText={"UPLOAD"}
                        width="fit-content"
                        onClick={() => uploadMedia()}
                      />
                    </div>
                  )}
                </div>
              )}
            </Col>
          </Row>
          <hr className="hr " />
          <div className="px-4 pt-2">
            {isLoading ? (
              <div className="">
                <div className="placeholder col-2 bg-secondary"></div>
              </div>
            ) : (
              <div className="form-label-title">
                All medias on the property{" "}
              </div>
            )}

            {isLoading ? (
              <div className="">
                <div className="placeholder col-3 bg-secondary"></div>
              </div>
            ) : (
              <div className="light-font-text grey-text">
                Lorem ipsum dolor sit pluralis oct
              </div>
            )}
          </div>
          <Row className="mt-4 px-4">
            <Col
              lg={3}
              md={6}
            >
              {isLoading ? (
                <div
                  className=""
                  style={{ width: "250px" }}
                >
                  <Placeholder animation="glow">
                    <div
                      className="placeholder col-8 bg-secondary"
                      style={{ height: "40px", borderRadius: "12px" }}
                    ></div>
                  </Placeholder>
                </div>
              ) : (
                <ReactSelect
                  placeholder={"Category"}
                  clearable={true}
                  onChange={(e, ActionTypes) =>
                    categoryDropdown(e, ActionTypes)
                  }
                  options={filteredMediaCategory?.map((item) => ({
                    value: item?.Id,
                    label: `${item?.Text} `,
                  }))}
                />
              )}
            </Col>
          </Row>
          <div className="px-4">
            {lengthofmedia == 0 ? (
              <div
                className="fs-12 py-4 light-font-text grey-text"
                style={{ textAlign: "center" }}
              >
                No medias are added to the package . That’s a must before you
                can continue.
              </div>
            ) : (
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable
                  droppableId="image-container"
                  direction="horizontal"
                >
                  {(provided) => (
                    <div
                      className="image-container"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {mediaFilesData?.map((item, index) => (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="item-container"
                              ref={provided.innerRef}
                              // {...provided.dragHandleProps}
                              {...provided.draggableProps}
                            >
                              <ImageCard
                                provided={provided}
                                data={item}
                                mediaCategoryData={filteredMediaCategory}
                                mediaFilesByPropertyIdData={
                                  mediaFilesByPropertyIdData
                                }
                                mediaFilesByPropertyIdDataRefetch={
                                  mediaFilesByPropertyIdDataRefetch
                                }
                                RefetchGetPropertyWithOverviewByPropertyIdData={
                                  RefetchGetPropertyWithOverviewByPropertyIdData
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </div>
        </Placeholder>
      </div>
    </>
  );
};

export default Media;

const ImageCard = ({
  data,
  provided,
  mediaFilesByPropertyIdData,
  mediaCategoryData,
  mediaFilesByPropertyIdDataRefetch,
  RefetchGetPropertyWithOverviewByPropertyIdData,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm();
  const [mediaCategory, setMediaCategory] = useState({
    value: data?.media_file_category_id,
    // label: mediaCategoryData?.filter(
    // 	(el) => el.Id == data?.media_file_category_id
    // )?.[0]?.Text,
    label: data?.category_name,
  });
  const { mutateAsync: UpdateMediaPublishDetailsMutate } =
    useUpdateMediaPublishDetails();

  const [editImageDetails, setEditImageDetails] = useState(false);
  const [imageTitle, setImageTitle] = useState("");
  const [altText, setAltText] = useState("");
  const [imageText, setImageText] = useState("");
  const [imageDescription, setImageDescription] = useState("");
  const [upadatemedia, setUpdateMedia] = useState([]);
  //
  const [seoScoreText, setSeoScoreText] = useState("");
  const [mediaQualityText, setMediaQualityText] = useState("");
  const [openMediaPackageDelete, setOpenMediaPackageDelete] = useState(false);
  //-----------------react query----------------//
  const { data: mediaFileDetailsData, refetch: mediaFileDetailsDataRefetch } =
    useQuery(
      ["getMediaFileDetailsByMediaId", data?.media_file_id],
      () => GetMediaDetailsByMediaId(data?.media_file_id),
      { enabled: !!editImageDetails }
    );
  const { propertyId } = usePropertyId((state) => state);

  const { mutateAsync: PutUpdateMediaFileCategoryMutate } =
    usePutUpdateMediaFileCategory();
  //
  useEffect(() => {
    if (mediaFileDetailsData?.data === null) {
      reset();
    } else {
      setUpdateMedia(mediaFileDetailsData?.data);
      // setImageTitle(mediaFileDetailsData?.data?.[0]?.title);
      // setAltText(mediaFileDetailsData?.data?.[0]?.alt_text);
      // setImageText(mediaFileDetailsData?.data?.[0]?.image_text);
      // setImageDescription(mediaFileDetailsData?.data?.[0]?.image_text);
      setSeoScoreText(mediaFileDetailsData?.data?.[0]?.seo_score_text);
      setMediaQualityText(mediaFileDetailsData?.data?.[0]?.media_quality_text);
      setValue("title", mediaFileDetailsData?.data[0]?.title);
      setValue("altText", mediaFileDetailsData?.data[0]?.alt_text);
      setValue("imageText", mediaFileDetailsData?.data[0]?.image_text);
      setValue("description", mediaFileDetailsData?.data[0]?.description);
    }
  }, [mediaFileDetailsData, editImageDetails]);
  // const modifiedOn = upadatemedia.map(item => item.modified_on);
  //
  //-----------handle delete card----------//
  const handleDeleteCard = (mediaFileId) => {
    DeleteMediaFileByMediaFileId(mediaFileId).then((response) => {
      toast.success(response.data.message);
      mediaFilesByPropertyIdDataRefetch();
      setOpenMediaPackageDelete(false);
    });
  };

  //----------------handle category change--------//
  const handleCategoryChange = async (e, mediaFileId) => {
    RefetchGetPropertyWithOverviewByPropertyIdData();
    setMediaCategory(e.value);
    const categoryChangePayload = {
      media_file_id: mediaFileId?.toString(),
      media_file_category_id: e.value?.toString(),
    };
    const response = await PutUpdateMediaFileCategoryMutate(
      categoryChangePayload
    );
    try {
      if (response?.status === "success" && response?.data?.bReturn) {
        toast.success(response.data.message, { toastId: mediaFileId });
        mediaFilesByPropertyIdDataRefetch();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {}
    // PutUpdateMediaFileCategory(categoryChangePayload).then((response) => {
    // 	toast.success(response.data.message);
    // 	mediaFilesByPropertyIdDataRefetch();
    // 	// setMediaCategory(e.value); check later
    // });
  };
  const handlePublishMedia = async (data) => {
    const publishMediaPaylaod = {
      media_file_id: data?.media_file_id,
      property_id: propertyId,
      media_file_category_id: data?.media_file_category_id,
      is_publish: data?.is_publish === 0 ? "1" : "0",
    };
    const result = await UpdateMediaPublishDetailsMutate(publishMediaPaylaod);
    try {
      if (result?.status == "success" && result?.data?.bReturn) {
        toast.success(result?.data?.message);
        mediaFilesByPropertyIdDataRefetch();
      } else {
        toast.error(result?.data?.message);
      }
    } catch (error) {}
  };

  //---------handle save image details-----------//
  const handleSaveImageDetails = async (mydata) => {
    const imageDetailsPayloadData = {
      media_package_id: "0",
      media_file_id: data?.media_file_id?.toString(),
      title: mydata?.title,
      media_category_id: data?.media_file_category_id,
      alt_text: mydata?.altText,
      image_text: mydata?.imageText,
      description: mydata?.description,
    };
    PutUpdateMediaDetails(imageDetailsPayloadData)
      .then((response) => {
        if (response.data.bReturn) {
          toast.success(response.data.message);
          setEditImageDetails(false);
          mediaFilesByPropertyIdDataRefetch();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  //
  //

  const { userDetails } = useUserDetails((state) => state);
  const { convertedDate: createdOn } = UTCtoLocalTimeZone({
    timestamp: data?.created_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });
  const { convertedDate: modifiedOn } = UTCtoLocalTimeZone({
    timestamp: data?.modified_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });

  return (
    <>
      <div className="image-card">
        <div
          {...provided.dragHandleProps}
          className="dragIcon"
        >
          {/* <FontAwesomeIcon size="xs" icon={faArrowsUpDownLeftRight} /> */}
          <FontAwesomeIcon
            icon="fa-regular fa-arrows-up-down-left-right"
            size="xs"
            style={{ color: "#000000" }}
          />
        </div>
        <div style={{ height: "120px" }}>
          <Image
            fluid
            alt="property image"
            className=""
            src={data?.media_files}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className="card-bottom">
          <div className="my-1">
            <ReactSelect
              placeholder={""}
              value={{
                value: data?.media_file_category_id,
                label: data?.category_name,
              }}
              options={mediaCategoryData?.map((option) => ({
                value: option?.Id,
                label: option?.Text,
              }))}
              onChange={(e) => {
                handleCategoryChange(e, data?.media_file_id);
              }}
              //   defaultValue={{ value: 0, label: "Draft" }}
              searchable={false}
              clearable={false}
              height={26}
            />
            {/* <BasicSelect
              value={mediaCategory || data?.media_file_category_id}
              Data={mediaCategoryData}
              onChange={(e) => handleCategoryChange(e, data?.media_file_id)}
              size="small"
              height="32px"
            /> */}
          </div>
          <div className="py-3 fw-300">
            <div className="d-flex align-items-center ">
              <span className="light-font-text me-2">SEO-score</span>
              <div
                style={{
                  background:
                    data?.seo_score_text === "Bad"
                      ? "#F1664B"
                      : data?.seo_score_text == "Average"
                      ? "#F6E651"
                      : "#77C7A1",
                  display: "flex",
                  borderRadius: "3px",
                }}
              >
                <span
                  className="normal-font-text"
                  style={{
                    fontSize: "9px",
                    textTransform: "uppercase",
                    padding: "1px 5px",
                  }}
                >
                  {data?.seo_score_text}
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <span className="light-font-text me-2">Image quality</span>
              <div
                style={{
                  background:
                    data?.media_quality_text == "Bad"
                      ? "#F1664B"
                      : data?.media_quality_text == "Average"
                      ? "#F6E651"
                      : "#77C7A1",
                  display: "flex",
                  borderRadius: "3px",
                }}
              >
                <span
                  className="normal-font-text"
                  style={{
                    fontSize: "9px",
                    textTransform: "uppercase",
                    padding: "1px 5px",
                  }}
                >
                  {data?.media_quality_text}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex  justify-content-between">
            <div className="fs-12  pt-1 ">
              <div className="light-font-text fs-12">Created by</div>
              <div className="created-by">
                {data?.created_by ? data?.created_by + ", " + createdOn : "-"}
              </div>
              <div className="light-font-text fs-12 pt-1 ">Last updated by</div>
              <div className="created-by">
                {data?.modified_by
                  ? data?.modified_by + ", " + modifiedOn
                  : "-"}
              </div>
            </div>
            <div className="d-flex align-items-end">
              <Menus
                itemheight={20}
                itemwidth={20}
                iconborderradius="4px"
                component={
                  <div className="light-font-text">
                    <div
                      onClick={() => {
                        data?.media_file_category_id === ""
                          ? toast.info("Please select media category")
                          : setEditImageDetails(true);
                      }}
                      className="addpropContent px-3 py-2 "
                    >
                      <span className="ms-1">Edit image details</span>
                    </div>
                    <hr className="addpropertyHr m-0"></hr>
                    <div
                      className="addpropContent px-3 pt-2 pb-2 "
                      onClick={() => handlePublishMedia(data)}
                    >
                      <span className="ms-1">
                        {data?.is_publish == 0
                          ? "Publish"
                          : "Remove from published"}
                      </span>
                    </div>
                    <hr className="addpropertyHr m-0"></hr>
                    <div
                      onClick={() => setOpenMediaPackageDelete(true)}
                      className="addpropContent px-3 pt-2 pb-2 "
                    >
                      <span className="ms-1">Delete</span>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
      <CustomPopup
        open={openMediaPackageDelete}
        onHide={() => setOpenMediaPackageDelete(false)}
        title="Delete package"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="p-3">
          <div className="px-2">
            <div
              className="light-font-text my-1"
              style={{ height: "100px" }}
            >
              <p>Are you sure you want to delete the media?</p>
              {/* <p>That will conflict with.....</p> */}
            </div>
          </div>
        </div>
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => setOpenMediaPackageDelete(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="I'M SURE"
            onClick={() => handleDeleteCard(data?.media_file_id)}
          />
        </div>
      </CustomPopup>
      {/* Edit image details popup */}
      <CustomPopup
        editImageDetails={editImageDetails}
        // modalHeadingStyle={modalHeadingStyle}
        style={{ position: "relative" }}
        title={"Edit image details"}
        subTitle={
          <>
            {data?.modified_by && data?.modified_on ? (
              <div className="fs-12 created-by">
                Last updated by {data?.modified_by},&nbsp;
                {moment(data?.modified_on).format("DD. MMM. YYYY | HH:MM:SS")}
              </div>
            ) : (
              <>
                {data?.created_by && data?.created_on ? (
                  <div className="fs-12 created-by">
                    Created by {data?.created_by},&nbsp;
                    {moment(data?.created_on).format(
                      "DD. MMM. YYYY | HH:MM:SS"
                    )}
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        }
        title_color="black"
        titleTooltipText="Edit image details"
        titleTooltip={true}
        size="lg"
        open={editImageDetails}
        onHide={() => {
          reset();
          setEditImageDetails(false);
        }}
        enableCrossButton={false}
        onCloseAction={() => {
          reset();
          setEditImageDetails(false);
        }}
      >
        <Form onSubmit={handleSubmit(handleSaveImageDetails)}>
          <div>
            <div className="d-flex  py-4 ps-4 pe-2 mb-3">
              <Row style={{ width: "100%" }}>
                <Col
                  md={7}
                  className="radius-8"
                >
                  <div style={{ height: "360px" }}>
                    <Image
                      fluid
                      className="radius-8"
                      alt="propertyDetailImage"
                      src={data?.media_files}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </Col>
                <Col
                  md={5}
                  className="pt-lg-0 pt-2 pe-0"
                >
                  <div>
                    <div className="pb-3">
                      <Controller
                        control={control}
                        {...register("title")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            {/* <input
                              type="text"
                              placeholder="Title"
                              id="propertyTitle"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("title");
                                // setImageTitle(e.target.value);
                              }}
                            /> */}
                            <InputField
                              id={"propertyTitle"}
                              value={value}
                              type={"text"}
                              placeholder={"Title"}
                              iserror={errors.title}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("title");
                              }}
                            />

                            {/* <label htmlFor="propertyTitle">Title</label> */}
                          </div>
                        )}
                      />
                      {/* {errors.title && (
                        <p className="error-msg ">This field is required</p>
                      )} */}
                    </div>
                    <div className="pb-2">
                      <Controller
                        control={control}
                        {...register("altText")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            {/* <textarea
                              name=""
                              class="inputText"
                              cols="20"
                              rows="2"
                              placeholder=" "
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("altText");
                                // setAltText(e.target.value);
                              }}
                            ></textarea> */}
                            <TextAreaField
                              placeholder={"Alt Text"}
                              value={value}
                              rows={2}
                              iserror={errors.altText}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("altText");
                              }}
                            />

                            {/* <label className="floating-label">Alt Text</label> */}
                          </div>
                        )}
                      />
                      {/* {errors.altText && (
                        <p className="error-msg ">This field is required</p>
                      )} */}
                    </div>
                    <div
                      className="fw-400 fs-10"
                      style={{
                        fontFamily: "Cerebri sans-light",
                        fontSize: "10px",
                        lineHeight: "14px",
                        color: "#000000",
                      }}
                    >
                      <GreenDiv
                        style={{
                          textDecorationLine: "underline",
                        }}
                      >
                        Learn to describe the purpose of the image.
                      </GreenDiv>{" "}
                      Leave blank if the image is purely decorative.
                    </div>
                    <div className="pt-3">
                      <Controller
                        control={control}
                        {...register("imageText")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            {/* <textarea
                              name=""
                              class="inputText"
                              cols="20"
                              rows="2"
                              placeholder=" "
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("imageText");
                                // setAltText(e.target.value);
                              }}
                            ></textarea> */}
                            <TextAreaField
                              placeholder={"Image text"}
                              value={value}
                              rows={2}
                              iserror={errors.imageText}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("imageText");
                              }}
                            />
                            {/* <label className="floating-label">Image text</label> */}
                          </div>
                        )}
                      />
                      {/* {errors.imageText && (
                        <p className="error-msg ">This field is required</p>
                      )} */}
                    </div>
                    <div className="pt-3">
                      <Controller
                        control={control}
                        {...register("description")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            {/* <textarea
                              name=""
                              class="inputText"
                              cols="20"
                              rows="2"
                              placeholder=" "
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("description");
                                // setAltText(e.target.value);
                              }}
                            ></textarea> */}
                            <TextAreaField
                              placeholder={" Description"}
                              value={value}
                              rows={2}
                              iserror={errors.description}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("description");
                              }}
                            />
                            {/*                             
                            <label className="floating-label">
                              Description
                            </label> */}
                          </div>
                        )}
                      />
                      {/* {errors.description && (
                        <p className="error-msg ">This field is required</p>
                      )} */}
                    </div>

                    <div className="mt-2">
                      <div className="d-flex align-items-center my-1  ">
                        <span className="light-font-text">SEO-score</span>
                        <div
                          className="ms-2"
                          style={{
                            background:
                              seoScoreText === "Bad"
                                ? "#F1664B"
                                : seoScoreText == "Average"
                                ? "#F6E651"
                                : "#77C7A1",
                            display: "flex",
                            borderRadius: "3.7px",
                          }}
                        >
                          <span
                            className="normal-font-text"
                            style={{
                              fontSize: "9px",
                              padding: "1px 5px",
                              textTransform: "uppercase",
                            }}
                          >
                            {seoScoreText}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center my-1">
                        <span className="light-font-text">Image quality</span>
                        <div
                          className="ms-2"
                          style={{
                            background:
                              mediaQualityText == "Bad"
                                ? "#F1664B"
                                : mediaQualityText == "Average"
                                ? "#F6E651"
                                : "#77C7A1",
                            display: "flex",
                            borderRadius: "3.7px",
                          }}
                        >
                          <span
                            className="normal-font-text"
                            style={{
                              fontSize: "9px",
                              textTransform: "uppercase",
                              padding: "1px 5px",
                            }}
                          >
                            {mediaQualityText}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div
            className="py-2 px-4 d-flex  justify-content-between  justify-content-center footer-section-button-container"
            style={{
              position: "absolute",
              width: "100%",
              bottom: "-52px",
            }}
          >
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => {
                reset();
                setEditImageDetails(false);
              }}
            />

            <ETButton
              variant={"primary"}
              buttonText="SAVE"
              type="submit"
              // onClick={() => {
              //   handleSaveImageDetails(data?.media_file_id);
              // }}
            />
          </div>
        </Form>
      </CustomPopup>
    </>
  );
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 4,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  borderRadius: 4,
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
