import React, { useState, useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import MRTTheme from "../../../../../../Components/table/MRTTheme";
import {
  SortIcon,
  TableRowsStyle,
} from "../../../../../../Components/table/MRTGlobalProps";
import { Row, Col } from "react-bootstrap";
import Menus from "../../../../../../Components/Menus/Menus";
import Toggle from "../../../../../../Components/Switch/Switch";
import { H4 } from "../../../../../../styles/Common.style";
import CustomToolTip from "../../../../../../Components/Tooltip/CustomTooltip";
import ETButton from "../../../../../../Components/Buttons/ETButton";
import AreaGroupPopup from "./AreaGroupPopup";
import CustomPopup from "../../../../../../Components/CustomPopup";

const Areas = () => {
  const [showAreaGroupPopup, setShowAreaGroupPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [usedFor, setUsedFor] = useState("");

  const AreaGroupscolumns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name} ${row.notification} `,
        header: "In use",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <>
            <Toggle disabled={false} id={row.index} />
            {console.log("ROW", row)}
          </>
        ),
      },
      {
        accessorKey: "group",
        header: "Group",
        enableHiding: false,
        size: 150,
        enableGlobalFilter: false,
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
      },
      {
        accessorKey: "areas_in_the_group",
        header: "Areas in the group",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "created",
        header: "Created",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "created_by",
        header: "Created by",
        enableHiding: false,
        size: 150,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "level",
        header: "Level",
        enableHiding: false,
        size: 80,
        enableGlobalFilter: false,
      },
    ],
    []
  );

  return (
    <>
      <div className="px-4 pt-2 pb-3 border-bottom">
        <Row className="pb-2">
          <Col>
            <div className="d-flex gap-2 align-items-center">
              <H4>Area groups</H4>
              <CustomToolTip size="sm" text="Area groups" />
            </div>
            <span className="light-font-text">
              Here is a list of all groups created in the system
            </span>
          </Col>
          <Col>
            <div className="w-100 d-flex justify-content-end">
              <ETButton
                variant={"primary"}
                buttonText="CREATE AREA GROUP"
                onClick={() => {
                  setUsedFor("CAG");
                  setShowAreaGroupPopup(true);
                }}
              />
            </div>
          </Col>
        </Row>

        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={AreaGroupscolumns}
              data={AreasTableData} //data is undefined on first render
              icons={{ ...SortIcon }}
              enableColumnActions={false}
              enableRowSelection={false}
              enableRowActions
              enableSelectAll
              enableTopToolbar={false}
              enableGlobalFilter
              globalFilterFn="contains"
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              displayColumnDefOptions={{
                "mrt-row-select": {
                  header: "",
                },
                "mrt-row-actions": {
                  header: "",
                },
              }}
              renderRowActions={({ row }) => (
                <Menus
                  itemheight={23}
                  itemwidth={23}
                  iconborderradius="4px"
                  itemcontainerwidth="200px"
                  component={
                    <div className="light-font-text">
                      <div
                        className="p-2 cursor-pointer"
                        onClick={() => {
                          setUsedFor("EAG");
                          setShowAreaGroupPopup(true);
                        }}
                      >
                        Edit
                      </div>
                      <hr className="hr m-0"></hr>
                      <div
                        className="p-2 cursor-pointer"
                        onClick={() => setShowDeletePopup(true)}
                      >
                        Delete
                      </div>
                    </div>
                  }
                />
              )}
              positionActionsColumn="last"
              muiTablePaperProps={{
                elevation: 0,
                fontFamily: "Cerebri sans-light !important",
                sx: {
                  borderRadius: "0",
                  border: "none",
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                },
              }}
              muiTableBodyRowProps={{
                selected: false,
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
            />
          }
        />
      </div>

      <div className="px-4 pt-4 pb-5 ">
        <Row className="pb-2">
          <Col>
            <div className="d-flex gap-2 align-items-center">
              <H4>Areas</H4>
              <CustomToolTip size="sm" text="Areas" />
            </div>
            <span className="light-font-text">
              Here is a list of all fields created in the system
            </span>
          </Col>
          <Col>
            <div className="w-100 d-flex justify-content-end">
              <ETButton
                variant={"primary"}
                buttonText="CREATE NEW AREA"
                onClick={() => {
                  setUsedFor("CNA");
                  setShowAreaGroupPopup(true);
                }}
              />
            </div>
          </Col>
        </Row>

        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={AreaGroupscolumns}
              data={AreasTableData} //data is undefined on first render
              icons={{ ...SortIcon }}
              enableColumnActions={false}
              enableRowSelection={false}
              enableRowActions
              enableSelectAll
              enableTopToolbar={false}
              enableGlobalFilter
              globalFilterFn="contains"
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              displayColumnDefOptions={{
                "mrt-row-select": {
                  header: "",
                },
                "mrt-row-actions": {
                  header: "",
                },
              }}
              renderRowActions={({ row }) => (
                <Menus
                  itemheight={23}
                  itemwidth={23}
                  iconborderradius="4px"
                  itemcontainerwidth="200px"
                  component={
                    <div className="light-font-text">
                      <div
                        className="p-2 cursor-pointer"
                        onClick={() => {
                          setUsedFor("ENA");
                          setShowAreaGroupPopup(true);
                        }}
                      >
                        Edit
                      </div>
                      <hr className="hr m-0"></hr>
                      <div
                        className="p-2 cursor-pointer"
                        onClick={() => setShowDeletePopup(true)}
                      >
                        Delete
                      </div>
                    </div>
                  }
                />
              )}
              positionActionsColumn="last"
              muiTablePaperProps={{
                elevation: 0,
                fontFamily: "Cerebri sans-light !important",
                sx: {
                  borderRadius: "0",
                  border: "none",
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                },
              }}
              muiTableBodyRowProps={{
                selected: false,
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
            />
          }
        />
      </div>

      {/* Area group and new area popup */}
      <AreaGroupPopup
        open={showAreaGroupPopup}
        onHide={() => setShowAreaGroupPopup(false)}
        onCancel={() => setShowAreaGroupPopup(false)}
        usedFor={usedFor}
      />

      {/* Delete popup */}
      <CustomPopup
        // modalHeadingStyle={modalHeadingStyle}
        style={{ position: "relative" }}
        size="md"
        open={showDeletePopup}
        onHide={() => setShowDeletePopup(false)}
        title="Are you carrying out the trash?"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() => {
          setShowDeletePopup(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div className="fs-14 fw-300 text-left mb-3">
            Are you sure you want to delete this ?
          </div>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => setShowDeletePopup(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="I'M SURE"
            onClick={() => setShowDeletePopup(false)}
          />
        </div>
      </CustomPopup>
    </>
  );
};

export default Areas;

export const AreasTableData = [
  // {
  //   group: "Amager",
  //   areas_in_the_group: "-",
  //   created: "30 Sep, 2023",
  //   created_by: "Thomas Høeg Mogensen",
  //   level: "Organisation",
  // },
  // {
  //   group: "Nordsjælland",
  //   areas_in_the_group: "Storkøbenhavn",
  //   created: "30 Sep, 2023",
  //   created_by: "Thomas Høeg Mogensen",
  //   level: "Organisation",
  // },
  // {
  //   group: "Storkøbenhavn",
  //   areas_in_the_group: "Storkøbenhavn",
  //   created: "30 Sep, 2023",
  //   created_by: "Thomas Høeg Mogensen",
  //   level: "Organisation",
  // },
  // {
  //   group: "Sjælland",
  //   areas_in_the_group: "Storkøbenhavn",
  //   created: "30 Sep, 2023",
  //   created_by: "Thomas Høeg Mogensen",
  //   level: "Organisation",
  // },
  // {
  //   group: "Grønttorvet",
  //   areas_in_the_group: "Storkøbenhavn",
  //   created: "30 Sep, 2023",
  //   created_by: "Thomas Høeg Mogensen",
  //   level: "Organisation",
  // },
  // {
  //   group: "Jylland",
  //   areas_in_the_group: "Region Midtjylland",
  //   created: "30 Sep, 2023",
  //   created_by: "Thomas Høeg Mogensen",
  //   level: "Organisation",
  // },
];

// Created by ----> Yash
