import React, { useEffect, useMemo, useState } from "react";
import "../../actor.css";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import FilterComponent from "../../../../reactdataTableComponent/FilterComponent";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetNotesCategory } from "../../../../hooks/queries/useNotesQueries";
import { Controller, useForm } from "react-hook-form";
import {
  DeleteNote,
  GetNotesDetails,
  PostAddNote,
} from "../../../../services/Notes";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import moment from "moment";
import CustomPopup from "../../../../Components/CustomPopup";
import ETButton from "../../../../Components/Buttons/ETButton";
import DateRangePicker from "../../../../Components/DateRangePicker/DateRangePicker";
import { useBreadcrumb } from "../../../../Components/BreadcrumbContext ";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";
import { UTCtoLocalTimeZone } from "../../../../utils/UTCtoLocalTimeZone";

const Notes = ({
  contractId,
  usersByDivisionIdData,
  initialSelectedName,
  tanentNameTab,
  selectedName,
}) => {
  const date = new Date();
  //-------------------States----------------------//
  const [dateRange, setDateRange] = useState([
    {
      startDate: date.setMonth(date.getMonth() - 1),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [searchText, setSearchText] = useState("");
  const [categoryFilter, setCategoryFilter] = useState(0);
  const [createdByFilter, setCreatedByFilter] = useState(0);
  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    updateBreadcrumbs(["Contracts", initialSelectedName, "Notes"]);
  }, [initialSelectedName]);

  useEffect(() => {
    if (tanentNameTab) {
      updateBreadcrumbs(["Contracts", selectedName, "Notes"]);
    } else {
    }
  }, [selectedName]);
  //------------------React query------------------//
  const { data: getNotesCategoryData } = useGetNotesCategory();
  const { data: getNotesDetailsData, refetch: getNotesDetailsDataRefetch } =
    useQuery(
      [
        "getNotesDetails",
        contractId,
        `${moment(dateRange?.[0]?.startDate).format("YYYY-MM-DD")}/${moment(
          dateRange?.[0]?.endDate
        ).format("YYYY-MM-DD")}`,
        categoryFilter,
        createdByFilter,
      ],
      () =>
        GetNotesDetails(
          contractId,
          `${moment(dateRange?.[0]?.startDate).format("YYYY-MM-DD")}/${moment(
            dateRange?.[0]?.endDate
          ).format("YYYY-MM-DD")}`,
          categoryFilter,
          createdByFilter
        ),
      {
        enabled: !!`${moment(dateRange?.[0]?.startDate).format(
          "YYYY-MM-DD"
        )}/${moment(dateRange?.[0]?.endDate).format("YYYY-MM-DD")}`,
        // enabled: !!categoryFilter,
      }
    );

  //------------------validation-------------------------//
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    clearErrors,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      subjectLine: "",
      message: "",
      notesCategory: "",
    },
  });

  //----Handle send message (Post Add note)----//
  const handleSendMessage = (data) => {
    const sendMessagePayload = {
      category_id: String(data?.notesCategory?.value),
      subject: data?.subjectLine,
      message: data?.message,
      contract_id: String(contractId),
    };
    PostAddNote(sendMessagePayload)
      .then((response) => {
        if (response?.data?.bReturn) {
          toast.success(response?.data?.message);
          getNotesDetailsDataRefetch();
          reset();
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const filteredNotes = useMemo(() => {
    if (searchText) {
      return getNotesDetailsData?.data?.filter(
        (item) =>
          item?.subject.toLowerCase().includes(searchText.toLowerCase()) ||
          item?.message.toLowerCase().includes(searchText.toLowerCase()) ||
          item?.category_name
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item?.created_by.toLowerCase().includes(searchText.toLowerCase())
      );
    } else {
      return getNotesDetailsData?.data;
    }
  }, [searchText, getNotesDetailsData]);

  return (
    <div className="mx-4 my-3 py-3 notes-container ">
      <div style={{ borderBottom: "1px solid #F0F0F0" }}>
        <Form onSubmit={handleSubmit(handleSendMessage)}>
          <div className="px-1  mb-3">
            <Row>
              <Col lg={4}>
                <div className="pe-sm-3 pe-lg-0 ps-sm-2 ps-lg-2">
                  <Controller
                    control={control}
                    {...register("subjectLine")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div className="form-input">
                        <input
                          className="mx-sm-1"
                          type="text"
                          placeholder="Subject line"
                          id="subjectline"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("subjectLine");
                          }}
                        />
                        <label for="subjectline">Subject line</label>
                      </div>
                    )}
                  />
                  {errors.subjectLine && (
                    <p className="error-msg ">This field is required</p>
                  )}
                </div>
                <Row className="mt-3">
                  <Col className="ps-sm-2 ps-lg-3 pe-sm-3 pe-lg-2">
                    <div className="pe-sm-2 pe-lg-0 ps-sm-3 ps-lg-2">
                      <Controller
                        control={control}
                        {...register("notesCategory")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <ReactSelect
                            placeholder={"Category"}
                            options={getNotesCategoryData?.data?.map(
                              (item, index) => ({
                                value: item?.Id,
                                label: item?.Text,
                              })
                            )}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("notesCategory");
                            }}
                          />
                        )}
                      />
                      {errors.notesCategory && (
                        <p className="error-msg ">This field is required</p>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                lg={8}
                className="px-4 pt-sm-3 pt-lg-0"
              >
                <div className="">
                  <Controller
                    control={control}
                    {...register("message")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputGroup className="notes-textarea">
                        <Form.Control
                          rows={5}
                          as="textarea"
                          placeholder="Message"
                          aria-label="Large"
                          aria-describedby="inputGroup-sizing-sm"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("message");
                          }}
                        />
                        <InputGroup.Text className="notes-textarea-icon">
                          <button
                            type="submit"
                            className="message-send-button"
                          >
                            {
                              <FontAwesomeIcon
                                size="xs"
                                icon="fa-regular fa-paper-plane-top"
                                style={{ color: "#FFFFFF" }}
                              />
                            }
                          </button>
                        </InputGroup.Text>
                      </InputGroup>
                    )}
                  />
                  {errors.message && (
                    <p className="error-msg ">This field is required</p>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      <div className="px-3 mt-3">
        <Row>
          <Col
            className="pe-lg-0"
            lg={2}
          >
            <div className="mb-sm-3 mb-lg-0">
              <FilterComponent
                className={"grey-input-filter-component"}
                filtertext={searchText}
                handleSearch={(e) => {
                  setSearchText(e.target.value);
                  // setCategoryFilter("");
                }}
              />
            </div>
          </Col>
          <Col
            className="px-0"
            lg={1}
          ></Col>
          <Col
            className="pe-lg-0 mb-sm-3 mb-lg-0"
            lg={2}
          >
            <ReactSelect
              placeholder={"Category"}
              options={getNotesCategoryData?.data?.map((item, index) => ({
                value: item?.Id,
                label: item?.Text,
              }))}
              onChange={(e, ActionTypes) => {
                if (ActionTypes.action === "clear") {
                  setCategoryFilter(0);
                } else {
                  setCategoryFilter(e.value);
                }
              }}
            />
          </Col>
          <Col
            className="pe-lg-0 mb-sm-3 mb-lg-0"
            lg={3}
          >
            {/* <div className="pe-2   pe-sm-0 ps-sm-2 mb-sm-2"> */}
            <ReactSelect
              placeholder={"Created by"}
              options={usersByDivisionIdData?.data?.map((item, index) => ({
                value: item?.Id,
                label: item?.Text,
              }))}
              // onChange={(e) => setCreatedByFilter(e.value)}
              onChange={(e, ActionTypes) => {
                if (ActionTypes.action === "clear") {
                  setCreatedByFilter(0);
                } else {
                  setCreatedByFilter(e.value);
                }
              }}
            />
            {/* </div> */}
          </Col>
          <Col
            className="pe-lg-0"
            lg={4}
          >
            {/* <div className="pe-3"> */}
            {/* <div className="form-input-group ">
                <input
                  type="date"
                  id="DateofPayment"
                  placeholder="DateofPayment"
                />
                <label htmlFor="DateofPayment">Date of Payment</label>
              </div> */}
            <Row>
              <Col className="sm-12">
                <DateRangePicker
                  date={dateRange}
                  setDate={setDateRange}
                  // handleDateChange={handleDateChange}
                />
              </Col>
            </Row>
            {/* </div> */}
          </Col>
        </Row>
        <div className="notes-card-section scrollable-container-y mt-3">
          {filteredNotes?.length > 0 ? (
            // getNotesDetailsData?.data
            //   ?.filter((item) => {
            //     if (searchText === "") {
            //       return item;
            //     } else if (
            //       item?.subject
            //         .toLowerCase()
            //         .includes(searchText.toLowerCase()) ||
            //       item?.category_name
            //         .toLowerCase()
            //         .includes(searchText.toLowerCase()) ||
            //       item?.message.toLowerCase().includes(searchText.toLowerCase())
            //     ) {
            //       return item;
            //     }
            //   })
            filteredNotes?.map((item, index) => (
              <>
                <NotesCard
                  key={index}
                  data={item}
                  contractId={contractId}
                  getNotesDetailsDataRefetch={getNotesDetailsDataRefetch}
                />
              </>
            ))
          ) : (
            <div
              className="d-flex justify-content-center w-100 fs-12 mt-1"
              style={{ color: "#888888" }}
            >
              No notes found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notes;

const NotesCard = ({ data, contractId, getNotesDetailsDataRefetch }) => {
  //--------------------States---------------------//
  const [openDeleteNoteModal, setOpenDeleteNoteModal] = useState(false);
  const { userDetails } = useUserDetails((state) => state);

  const { convertedDate: createdOn } = UTCtoLocalTimeZone({
    timestamp: data?.created_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD MMM, YYYY [at] hh:mm",
  });

  //----Handle delete note (Delete note)----//
  const handleDeleteNote = () => {
    DeleteNote(data?.note_id, contractId)
      .then((response) => {
        if (response?.data?.bReturn) {
          toast.success(response?.data?.message);
          setOpenDeleteNoteModal(false);
          getNotesDetailsDataRefetch();
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };
  return (
    <>
      <div className="card-div px-3 py-2 mb-2">
        <div className="d-flex justify-content-between font-semibold">
          <div
            style={{
              backgroundColor: data?.bg_color || "#9370DB",
              color: "white",
              borderRadius: "4px",
              fontSize: "9px",
              alignItems: "center",
              textTransform: "uppercase",
            }}
            className="mt-1 px-2  d-flex"
          >
            {data?.category_name}
          </div>
          <div
            style={{
              fontSize: "10px",
              fontWeight: "400",
              fontFamily: "Cerebri Sans",
            }}
          >
            {createdOn}
          </div>
        </div>

        <div className="mt-2 fs-14 font-semibold">{data?.subject}</div>

        <div className="mt-1 light-font-text text-break">{data?.message}</div>
        <div className="mt-2 d-flex justify-content-between">
          <div className="light-font-text fs-12">by {data?.created_by}</div>
          <div
            onClick={() => setOpenDeleteNoteModal(true)}
            style={{
              height: "24px",
              width: "24px",
              backgroundColor: "#FFFFFF",
              border: "1px solid #D1D1D1",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon
              icon="fa-regular fa-trash"
              style={{
                color: "#000000",
                margin: "5px",
                fontSize: "14px",
              }}
            />
          </div>
        </div>
      </div>
      {/* Delete note*/}
      <CustomPopup
        open={openDeleteNoteModal}
        onHide={() => setOpenDeleteNoteModal(false)}
        title="Delete note"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
        size={"md"}
      >
        <div className="p-3">
          <div className="px-2">
            <div
              className="fw-300 fs-14 my-1"
              style={{ height: "100px" }}
            >
              Are you sure you want to delete the note?
            </div>
          </div>
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => {
              setOpenDeleteNoteModal(false);
            }}
          />
          <ETButton
            variant={"primary"}
            buttonText="I'M SURE"
            onClick={() => handleDeleteNote()}
          />
        </div>
      </CustomPopup>
    </>
  );
};
