import React, { useEffect } from "react";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Menu } from "@mui/material";
import "./Dropdown.css";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useControlCenter } from "../../hooks/stores/useControlCenterStore";

const DropdownModalMui = ({ text, component, width, closeDropdown, disabled, applyClose, initialCheckedValues }) => {
  const {

    allProperties,
    setAllProperties,
    selectedProperties
  } = useControlCenter((state) => state);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [checkedValues, setCheckedValues] = useState(); // Store checked values
  useEffect(() => {
    setCheckedValues(initialCheckedValues)
  }, [initialCheckedValues])

  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setDropdownOpen(true);
    setAllProperties(selectedProperties)
  };
  const handleClose = () => {
    setAnchorEl(null);
    setDropdownOpen(false);
    if (applyClose == false) {
      setAllProperties([])
    }


  };
  const handleMenuFocus = (event) => {
    event.target.style.outline = "none";
  };
  useEffect(() => {
    if (closeDropdown == true) {
      handleClose();
      // setCheckedValues([])


    }
  }, [closeDropdown]);

  return (
    <>
      <div className="dropBox" >

        <div
          id="basic-button"
          aria-controls={anchorEl ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={anchorEl ? "true" : undefined}
          onClick={(e) => handleClick(e)}
          className="dropdownInput ps-lg-2 ps-1 cursor-pointer"
        >
          <span className="ps-1" style={{ fontFamily: " Cerebri sans-light" }}>
            {text}
          </span>
          <span style={{ paddingRight: "12px" }}>
            {dropdownOpen === false ? (
              <FontAwesomeIcon
                icon="fa-regular fa-chevron-down"
                style={{ color: "#000000" }}
              />
            ) : (
              <FontAwesomeIcon
                icon="fa-regular fa-chevron-down"
                style={{ color: "#000000", transform: "rotate(180deg)" }}
              />
            )}
          </span>
        </div>
      </div>
      <div className="py-0">
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          elevation={0}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            onFocus: handleMenuFocus,
          }}
          PaperProps={{
            style: {
              borderRadius: "8px",
              marginTop: "6px",
              border: "0.5px solid #F0F0F0",
              boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.12)",

            },
          }}
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.02)",
            "& .css-6hp17o-MuiList-root-MuiMenu-list": {
              padding: 0,
              borderColor: "#F0F0F0",
            },
          }}
        >
          <div style={{ borderRadius: "8px", width: width }}>{component}</div>
        </Menu>
      </div>
    </>
  );
};

export default DropdownModalMui;
