import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from "../../Components/Tabs/ReactTabs.style";
import Overview from "./overview/Overview";
import Activities from "./activities/Activities";
import ActorsCustomers from "./customers/Customers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ViewSigningPackages } from "./customers/signing/ViewSigningPackage";
import { useSigningOrderStore } from "../../hooks/stores/useSigningStore";
import { useUnitId } from "../../hooks/stores/useUnitStore";
import { usePropertyOverview } from "../../hooks/stores/usePropertyStore";
import { useLocation } from "react-router-dom";
import { GreenSpan } from "../../styles/Common.style";
import { H1 } from "../../styles/Common.style";

const Actors = () => {
  let location = useLocation();
  const { state } = location;
  let unit_no = state?.unit_no;
  let street_name = state?.street_name;
  let house_number = state?.house_number;
  let zip_code = state?.zip_code;
  //------------------States-------------------//
  const [selectedTabName, setSelectedTabName] = useState("Overview");
  //-------------------Zustand------------------//
  const { isViewSigningOrder } = useSigningOrderStore((state) => state);
  const { unitId, setUnitId } = useUnitId((state) => state);

  // Api data for property overview
  const propOverview = usePropertyOverview((state) => state.propOverview);
  const [contractId, setContractId] = useState("");

  useEffect(() => {
    setUnitId(state?.unit_id);
  }, [state?.unit_id]);

  const [initialSelectedName, setInitialSelectedName] = useState(null);
  const [selectedName, setSelectedName] = useState(initialSelectedName);
  const [selectedTabPage, setSelectedTabPage] = useState("Overview");
  // const [ActorTabs, setActorTabs] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0);
  // const selectedTabForActor={
  //   selectedTab == 1?"":""
  // }
  useEffect(() => {
    setSelectedName(initialSelectedName);
  }, [initialSelectedName]);

  const handleClick = (name) => {
    setSelectedName(name);
    setSelectedTabPage("Overview");
  };

  // tbs names //
  const ActorsTabsWizards = [
    {
      Id: "1",
      tab: "Overview",
      component: <Overview />,
    },
    {
      Id: "2",
      tab: "Customers",
      component: (
        <ActorsCustomers
          contractId={contractId}
          setContractId={setContractId}
          setInitialSelectedName={setInitialSelectedName}
          handleClick={handleClick}
          selectedName={selectedName}
          // setActorTabs={setActorTabs}
          // handleClickTab={handleClickTab}
          setSelectedTabPage={setSelectedTabPage}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
        />
      ),
    },
    {
      Id: "3",
      tab: "Activities",
      component: <Activities />,
    },
  ];

  return (
    <>
      <Container fluid className="">
        {/* {isViewSigningOrder?.showSigningPage ? (
          <ViewSigningPackages unit_no={unit_no}
            street_name={street_name}
            house_number={house_number}
            zip_code={zip_code}
            selectedName={selectedName} />
        ) : ( */}
        <div>
          <div className=" pt-3">
            <H1>Overview</H1>
            <div className="light-font-text">
              <span className="me-3">Properties</span>
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />
              </span>
              <span className="me-3">{propOverview?.[0]?.propertyName}</span>
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />
              </span>
              <span className="me-3">
                {/* {settingTags ? "Tags" : "Units"} */}
                {/* {selectedTab} */}
                Units
              </span>
              {unit_no && street_name && house_number && zip_code ? (
                <>
                  <span className="me-3">
                    <FontAwesomeIcon
                      size="sm"
                      icon="fa-regular fa-angle-right"
                      style={{ color: "#000000" }}
                    />
                  </span>

                  <span className="me-3">
                    {unit_no},{street_name}
                    {house_number},{zip_code}
                  </span>
                </>
              ) : (
                ""
              )}

              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />
              </span>
              <span className="me-3">{selectedTabName}</span>
              {selectedTabName !== "Overview" &&
              selectedName?.length > 0 &&
              selectedTabName !== "Activities" ? (
                <>
                  <span className="me-3">
                    <FontAwesomeIcon
                      size="sm"
                      icon="fa-regular fa-angle-right"
                      style={{ color: "#000000" }}
                    />
                  </span>
                  <span className="me-3">{selectedName}</span>
                </>
              ) : (
                <>
                  {selectedTabName !== "Overview" &&
                  selectedTabName !== "Activities" ? (
                    <>
                      <span className="me-3">
                        <FontAwesomeIcon
                          size="sm"
                          icon="fa-regular fa-angle-right"
                          style={{ color: "#000000" }}
                        />
                      </span>
                      <span className="me-3">Tenant Name</span>
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
              {selectedTabName !== "Overview" &&
              selectedTabPage &&
              selectedTabName !== "Activities" ? (
                <>
                  <span className="me-3">
                    <FontAwesomeIcon
                      size="sm"
                      icon="fa-regular fa-angle-right"
                      style={{ color: "#000000" }}
                    />
                  </span>
                  <GreenSpan className="me-3">{selectedTabPage}</GreenSpan>
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          {/* top tabs  */}
          <div className=" me-3  ">
            <Tabs lassName="my-3 pb-1 d-flex flex-column">
              <TabList className="light ps-0 pt-3 p-2">
                {ActorsTabsWizards.map((page, index) => (
                  <>
                    <Tab
                      key={index}
                      style={{ position: "relative" }}
                      onClick={() => setSelectedTabName(page?.tab)}
                    >
                      <span style={{ position: "relative" }}>{page?.tab}</span>
                      {page.msg && (
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle"
                          size="lg"
                          style={{ color: "#D00200" }}
                          className="px-1 notification"
                        />
                        // <span className="px-1 notification" style={{color:"#fff"}} >
                        //   {page.msg}
                        //   </span>
                      )}
                    </Tab>
                  </>
                ))}
              </TabList>

              <div
                style={{ backgroundColor: "#F1F5F0", borderRadius: " 10px" }}
              >
                {ActorsTabsWizards.map((page, index) => (
                  <TabPanel key={page?.Id}>{page?.component}</TabPanel>
                ))}
              </div>
            </Tabs>
          </div>
        </div>
        {/* )} */}
      </Container>
    </>
  );
};

export default Actors;
