import React, { useEffect, useMemo, useState } from "react";
import ETButton from "../../Components/Buttons/ETButton";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import { MaterialReactTable } from "material-react-table";
import "./signing.css";
import Menus from "../../Components/Menus/Menus";
import { useGetSignersDetails } from "../../hooks/queries/useSigningQueries";
import { usePropertyOverview } from "../../hooks/stores/usePropertyStore";
import { DeleteSigner } from "../../services/Signing";
import { toast } from "react-toastify";
import { useRouting } from "../../hooks/stores/useRoutingStore";
import CustomPopup from "../../Components/CustomPopup";
import { status } from "../../model/Constant";
import MRTTheme from "../../Components/table/MRTTheme";
import {
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../Components/table/MRTGlobalProps";

import { useBreadcrumb } from "../../Components/BreadcrumbContext ";
import { H2 } from "../../styles/Common.style";
const Signer = () => {
  //----------------Zuustand--------------------//
  const propertyOverview = usePropertyOverview((state) => state.propOverview);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [userId, setUserId] = useState("");
  const [disableDeleteAction, setdisableDeleteAction] = useState(false);
  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    updateBreadcrumbs(["Signers"]);
  }, []);
  const {
    packageType,
    setPackageType,
    showPackage,
    setShowPackage,
    editflag,
    setEditflag,
    setPackageId,
  } = useRouting((state) => state);

  //----------------react query----------------//
  const {
    data: getSignerDetailsData,
    isLoading,
    refetch: getSignerDetailsDataRefetch,
  } = useGetSignersDetails(propertyOverview?.[0]?.property_id);

  const signersColumns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: "Title",
        size: 120,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 180,
        Cell: ({ row }) => (
          <>
            <div>
              {row?.original?.first_name}&nbsp;{row?.original?.last_name}
            </div>
          </>
        ),
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 120,
      },
      {
        accessorKey: "is_qa_approver",
        header: "QA approver",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div>{row?.original?.is_qa_approver === 1 ? "Yes" : "No"}</div>
          </>
        ),
      },
      {
        accessorKey: "is_landlord_seller_signer",
        header: "LandLord/Seller signer ",
        size: 180,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div>
              {row?.original?.is_landlord_seller_signer === 1 ? "Yes" : "No"}
            </div>
          </>
        ),
      },
      {
        accessorKey: "is_copy_recipient",
        header: "Copy recipient ",
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div>{row?.original?.is_copy_recipient === 1 ? "Yes" : "No"}</div>
          </>
        ),
      },
      {
        accessorKey: "use_two_factor_signing",
        header: "2FA",
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div>
              {row?.original?.use_two_factor_signing === 1 ? "Yes" : "No"}
            </div>
          </>
        ),
      },
      {
        accessorKey: "is_notification_recipient",
        header: "Notification recipient",
        size: 180,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div>
              {row?.original?.is_notification_recipient === 1 ? "Yes" : "No"}
            </div>
          </>
        ),
      },
    ],
    []
  );
  const handleDelete = async () => {
    setdisableDeleteAction(true);
    const response = await DeleteSigner(userId);
    if (response.status == status.s && response.data.bReturn) {
      setDeletePopUp(false);
      getSignerDetailsDataRefetch();
      toast.success(response.data.message);
      setdisableDeleteAction(false);
    }
  };
  // const handleDelete = (userId) => {
  //   DeleteSigner(userId).then((response) => {
  //     toast.success(response.data.message);
  //     getSignerDetailsDataRefetch();
  //     setDeletePopUp(false);
  //   });
  // };
  const renderEmptyRowsFallback = () => (
    <div className="d-flex justify-content-center w-100 py-4 grey-text">
      <div>
        You do not have any Signer yet. Click on “+ ADD SIGNER” to create your
        first.
      </div>
    </div>
  );
  return (
    <div className="p-4 radius-8 containerStyle">
      <div className="signers-container mainborder  radius-8">
        <div className="signers-body">
          <div
            className="px-4 py-3 d-flex justify-content-between"
            style={{ borderBottom: "1px solid #F0F0F0" }}
          >
            {isLoading ? (
              <div className=" " style={{ width: "100px" }}>
                <div className="placeholder col-10 bg-secondary"></div>
              </div>
            ) : (
              <div className="fs-14 fw-700 d-flex align-items-center ">
                <H2 className="me-2">Signers</H2>{" "}
                <div className="pt-1">
                  {" "}
                  <CustomToolTip text="Need Help For Signer" />{" "}
                </div>
              </div>
            )}

            <div>
              {isLoading ? (
                <div className="" style={{ width: "100px" }}>
                  <div
                    className="placeholder col-10 radius-8"
                    style={{ height: "35px" }}
                  ></div>
                </div>
              ) : (
                <ETButton
                  variant={"primary"}
                  buttonText="ADD SIGNER"
                  icon={true}
                  onClick={() => {
                    // setIsEditSigner(false);
                    setEditflag(false);
                    // setAddShowSigners(true);
                    setShowPackage(true);
                    setPackageType("9");
                  }}
                />
              )}
            </div>
          </div>

          <div className="signers-list">
            <MRTTheme
              colorVariantsForTableData={["odd"]}
              table={
                <MaterialReactTable
                  columns={signersColumns}
                  data={getSignerDetailsData?.data || []}
                  icons={{ ...SortIcon }}
                  state={{ isLoading: !getSignerDetailsData }}
                  renderEmptyRowsFallback={renderEmptyRowsFallback}
                  enableBottomToolbar={true}
                  enableColumnActions={false}
                  enablePagination={true}
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      header: "",
                      size: 50,
                    },
                  }}
                  muiTableHeadRowProps={{
                    sx: {
                      ...TableRowsStyle,
                    },
                  }}
                  muiTablePaginationProps={{
                    ...paginationoptions,
                  }}
                  enableRowActions
                  renderRowActions={({ row, table }) => (
                    <Menus
                      itemheight={23}
                      itemwidth={23}
                      iconborderradius="4px"
                      itemcontainerwidth="172px"
                      component={
                        <div
                          className="addpropertyCountryHandle "
                          style={{ fontSize: "14px", width: "198px" }}
                        >
                          <div
                            className="addpropContent px-3 py-2 "
                            onClick={() => {
                              setEditflag(true);
                              setShowPackage(true);
                              setPackageId(row?.original?.signing_person_id);
                              setPackageType("9");
                            }}
                          >
                            <span
                              className="ms-1"
                              style={{
                                fontFamily: "Cerebri sans",
                                fontSize: "14px",
                                fontweight: "300",
                                alignItems: "center",
                              }}
                            >
                              Edit
                            </span>
                          </div>
                          <hr className="m-0" />
                          <div
                            onClick={() => {
                              setDeletePopUp(!deletePopUp);
                              setUserId(row?.original?.user_id);
                            }}
                            className="addpropContent px-3 py-2 "
                          >
                            <span
                              className="ms-1"
                              style={{
                                fontFamily: "Cerebri sans",
                                fontSize: "14px",
                                fontweight: "300",
                                alignItems: "center",
                              }}
                            >
                              Delete
                            </span>
                          </div>
                        </div>
                      }
                    />
                  )}
                  positionActionsColumn="last"
                  renderTopToolbar={false}
                />
              }
            />

            <CustomPopup
              style={{ position: "relative" }}
              size="md    "
              open={deletePopUp}
              onHide={() => setDeletePopUp(false)}
              title="Are you carrying out the trash?"
              title_color="black"
              enableCrossButton={false}
              onCloseAction={() => {
                setDeletePopUp(false);
              }}
              action_button_label="YES"
              action_button_label_color="white"
              action_button_color="#619130"
              cancel_button={true}
              cancel_button_bordercolor="grey"
              cancel_button_label="NO"
              cancel_button_label_color="black"
              cancel_button_color="#fff"
              footer_color={"#F9FAFA"}
            >
              <div className="p-4">
                <div className="fs-14 fw-300 text-left mb-3">
                  <p>Are you sure you want to delete the signer?</p>
                </div>
              </div>

              <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
                <ETButton
                  variant={"white"}
                  buttonText="COLD FEET"
                  onClick={() => setDeletePopUp(false)}
                />

                <ETButton
                  variant={"primary"}
                  buttonText="I'M SURE"
                  disabled={disableDeleteAction}
                  onClick={handleDelete}
                />
              </div>
            </CustomPopup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signer;
