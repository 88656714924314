import React from "react";
import { H4 } from "../../../../../styles/Common.style";
import CustomToolTip from "../../../../../Components/Tooltip/CustomTooltip";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "../../../../../Components/DropDown/ReactSelect";
import Toggle from "../../../../../Components/Switch/Switch";
import ETButton from "../../../../../Components/Buttons/ETButton";
import { Controller, useForm } from "react-hook-form";
import InputField from "../../../../../Components/Textfield/InputField";
import { TextField } from "@mui/material";

const SoMeIntegrations = () => {
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      link: "",
      email: "",
      message: "",
    },
  });


  return (
    <div>
      <div className=" pb-4 px-4 border-bottom">
        <div className="d-flex align-items-center mb-2  gap-2 ">
          <H4>Google Map Key</H4>
          <CustomToolTip size ="sm" text="Google map key" />
        </div>
        <div className="light-font-text mb-3">
          Insert your Google Maps Key to be used to display maps in end user
          service products on e.g. MyPage, Ei.DO etc.
        </div>
        <Row className="mb-3">
          <Col lg={6}>
            {" "}
            <Controller 
                   {...register(``)}
                   control={control}
                   rules={{ required: true }}
                   render={({ field }) => (
                     <div >
                      <InputField 
                      type={"text"}
                      placeholder={"Google key"}
                      id={"googlekey"}
                      
                      
                      />
                       
                     </div>
                   )}
                 />
           
          </Col>
        </Row>
        <div className="d-flex justify-content-start align-items-center">
          <Toggle />{" "}
          <span className="light-font-text ms-3 ">
            {" "}
            Use different Google Keys on divisions{" "}
          </span>
        </div>
      </div>
      <div className=" p-4 border-bottom">
        <div className="d-flex align-items-center mb-2   gap-2 ">
          <H4>Google Token</H4>
          <CustomToolTip size ="sm"  text="Google Token" />
        </div>
        <div className="light-font-text mb-3">Insert your Google token</div>
        <Row className="mb-3">
          <Col lg={6}>
            {" "}
            
            <Controller 
                   {...register(``)}
                   control={control}
                   rules={{ required: true }}
                   render={({ field }) => (
                     <div >
                      <InputField 
                      type={"text"}
                      placeholder={"Google Token"}
                      id={"googletoken"}
                      
                      
                      />
                       
                     </div>
                   )}
                 />
            
          </Col>
        </Row>
        <div className="d-flex justify-content-start align-items-center">
          <Toggle />{" "}
          <span className="light-font-text ms-3 ">
            {" "}
            Use different Google Token on divisions{" "}
          </span>
        </div>
      </div>
      <div className=" p-4 border-bottom">
        <div className="d-flex align-items-center mb-2   gap-2 ">
          <H4>Google Business Manager Key</H4>
          <CustomToolTip size ="sm"  text="Google Token" />
        </div>
        <div className="light-font-text mb-3">
          Insert your Google Business Manager Key, so it’s possible to display
          data from Google in the system
        </div>
        <Row className="mb-3">
          <Col lg={6}>
            {" "}
            <div className="form-input">
              <input
                type="text"
                placeholder="googlemanagerkey"
                id="googlemanagerkey"
              />
              <label htmlFor="googlemanagerkey">
                Google Business Manager Key
              </label>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-start align-items-center">
          <Toggle />{" "}
          <span className="light-font-text ms-3 ">
            {" "}
            Use different Google Business Manager keys on divisions
          </span>
        </div>
      </div>
      <div className=" p-4 border-bottom">
        <div className="d-flex align-items-center mb-2   gap-2 ">
          <H4>META token</H4>
          <CustomToolTip size ="sm"  text="Inset your META Token" />
        </div>
        <div className="light-font-text mb-3">Insert your META Token</div>
        <Row className="mb-3">
          <Col lg={6}>
            {" "}
            <Controller 
                   {...register(``)}
                   control={control}
                   rules={{ required: true }}
                   render={({ field }) => (
                     <div >
                      <InputField 
                      type={"text"}
                      placeholder={"metatoken"}
                      id={"metatoken"}
                      
                      
                      />
                       
                     </div>
                   )}
                 />
           
          </Col>
        </Row>
        <div className="d-flex justify-content-start align-items-center">
          <Toggle />{" "}
          <span className="light-font-text ms-3 ">
            {" "}
            Use different META Tokens on divisions
          </span>
        </div>
      </div>
      <div className=" p-4 border-bottom">
        <div className="d-flex align-items-center mb-2   gap-2 ">
          <H4>META Business Manager Key</H4>
          <CustomToolTip size ="sm"  text="META Business Manager Key" />
        </div>
        <div className="light-font-text mb-3">
          Insert your Facebook Business Manager Key, so it’s possible to display
          data from Meta in the system
        </div>
        <Row className="mb-3">
          <Col lg={6}>
            {" "}
            <Controller 
                   {...register(``)}
                   control={control}
                   rules={{ required: true }}
                   render={({ field }) => (
                     <div >
                      <InputField 
                      type={"text"}
                      placeholder={"META Business Manager Key"}
                      id={"metabusinessmanagerkey"}
                      
                      
                      />
                       
                     </div>
                   )}
                 />
           
          </Col>
        </Row>
        <div className="d-flex justify-content-start align-items-center">
          <Toggle />{" "}
          <span className="light-font-text ms-3 ">
            {" "}
            Use different META Business Manager keys on divisions
          </span>
        </div>
      </div>
      <div className=" p-4 mb-3">
        <div className="d-flex align-items-center mb-2   gap-2 ">
          <H4>Heatmap Pixel Code</H4>
          <CustomToolTip size ="sm"  text="META Business Manager Key" />
        </div>
        <Row>
          {" "}
          <Col lg={6}>
            {" "}
            <div className="light-font-text mb-3">
              To make it possible to use estatetools' built-in heatmap function
              and read data about your website, for example, this code must be
              inserted in the header of the website.
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col lg={7}>
            {" "}
           
            <div className="form-float openhouse-des">
              <textarea
                name="pixelcode"
                className="inputText"
                cols="20"
                rows="4"
                id="pixelcode"
                placeholder=""
              />
              <label htmlFor="pixelcode" className="floating-label">
                Pixel Code
              </label>
            </div>
          </Col>
          <Col className="d-flex align-items-end">
            <span className="mb-2">
              <ETButton variant={"secondary"} buttonText={"COPY CODE"} />
            </span>
          </Col>
        </Row>
        <div className="d-flex justify-content-start align-items-center">
          <Toggle />{" "}
          <span className="light-font-text ms-3 ">
            {" "}
            Use different META Business Manager keys on divisions
          </span>
        </div>
      </div>
    </div>
  );
};

export default SoMeIntegrations;
