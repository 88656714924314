import React, { useState, useRef, useEffect } from "react";

import "./accordion.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useScreening } from "../../hooks/stores/useScreeningStore";

function Accordion({
  title,
  titleDate,
  contentBody,
  bgColor,
  accordianBorder = true,
  accordianHeight,
  accordianIconAlignment = "right",
  titleDiscriptipon,
  key,
  isActive,
  setIsActive,
  id,
  setScreeningCategoryId,
  isSectionAddAllowed,
  alwaysOpen = false,
}) {
  const { setAccordianId, addSectionAllow, setAddSectionAllow } = useScreening(
    (state) => state
  );
  const [setActive, setActiveState] = useState(alwaysOpen);
  const [setHeight, setHeightState] = useState(alwaysOpen ? "100%" : "0px");
  const [setRotate, setRotateState] = useState(
    alwaysOpen ? "accordion__icon rotate" : "accordion__icon"
  );
  // const [accordianId, setAccordianId] = useState('')
  // const [addSectionAllow, setAddSectionAllow] = useState('')
  const content = useRef(null);

  function toggleAccordion(id) {
    setAddSectionAllow(isSectionAddAllowed);
    setAccordianId(id);
    isActive &&
      setIsActive({
        ...isActive,
        [id]: !isActive[id],
      });
    if (setScreeningCategoryId !== undefined) {
      setScreeningCategoryId(id);
    }

    setActiveState(setActive === false ? true : false);
    setHeightState(
      // setActive === true ? "0px" : `${content.current.scrollHeight}px`
      setActive === true ? "0px" : `100%` // Max height is creating conflict with the current UI situation i.e (Left section of Add fields shoul be always sticked to top)
    );
    setRotateState(
      setActive === true ? "accordion__icon" : "accordion__icon rotate"
    );
  }
  // console.log(setHeight, "setHeightsetActive");
  // console.log(setActive , "setActive");
  useEffect(() => {
    // Update state when the `alwaysOpen` prop changes
    setActiveState(alwaysOpen);
    setHeightState(alwaysOpen ? "100%" : "0px");
    setRotateState(alwaysOpen ? "accordion__icon rotate" : "accordion__icon");
  }, [alwaysOpen]);
  return (
    <div
      key={key}
      className="accordion__section "
      style={{ border: accordianBorder ? "1px solid #d1d1d1" : "none" }}
    >
      <div
        className={`accordion-component ${setActive} justify-content-between ps-4 pe-4`}
        onClick={() => toggleAccordion(id)}
        style={{
          height: accordianHeight ? accordianHeight : "64px",
          borderBottom: accordianBorder ? "1px solid #d1d1d1" : "none",
          backgroundColor: bgColor ? bgColor : "#f9fafa",
        }}
      >
        <div className="d-flex ">
          {accordianIconAlignment == "left" && (
            <div className="pe-2">
              <FontAwesomeIcon
                icon="fa-solid fa-chevron-down"
                style={{ color: "#000000" }}
                className={`${setRotate}`}
              />
            </div>
          )}
          <div className="accordion__title actors-label-title fs-14">
            {title}
          </div>
        </div>

        {accordianIconAlignment == "right" && (
          <div>
            <FontAwesomeIcon
              icon="fa-solid fa-chevron-down"
              style={{ color: "#000000" }}
              className={`${setRotate}`}
            />
          </div>
        )}
        {accordianIconAlignment == "left" && titleDate && (
          <div>{titleDate}</div>
        )}
      </div>
      {titleDiscriptipon && (
        <div
          style={{
            backgroundColor: bgColor ? bgColor : "#f9fafa",
          }}
        >
          {titleDiscriptipon}
        </div>
      )}
      <div
        ref={content}
        style={{
          maxHeight: `${setHeight}`,
          backgroundColor: bgColor ? bgColor : "white",
        }}
        className="accordion__content "
      >
        {contentBody}
      </div>
    </div>
  );
}

export default Accordion;
