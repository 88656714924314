import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import MRTTheme from "../../../../Components/table/MRTTheme";
import { H3 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import ETButton from "../../../../Components/Buttons/ETButton";
import Menus from "../../../../Components/Menus/Menus";
import {
  paginationoptions,
  SortIcon,
} from "../../../../Components/table/MRTGlobalProps";
import CreateNewContractWizard from "../../opportunities/createNewContract/CreateNewContractWizard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TagToolTip from "../../../../Components/Tooltip/PropertyTagToolTip";
import CustomerDetailTooltip from "../../standardComponents/CustomerDetailTooltip";
import {
  useGetActiveContractsByDivisionId,
  usePutUpdateFavouriteLead,
} from "../../../../hooks/queries/useCustomerFunnel";
import moment from "moment";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";
import { status } from "../../../../model/Constant";
import { toast } from "react-toastify"
import  {useCustomerFunnelTopLoadingBar} from "../../../../hooks/stores/useCustomerFunnelStore"
const ActiveContract = () => {
  //------------------- Zustand --------------//
  const { userDetails } = useUserDetails((state) => state);
  //------------------- State --------------//
  const [activeContractTable, setActiveContractTable] = useState("");
  //-------------------React query--------------//
  const {
    data: GetActiveContractsByDivisionId,
    refetch: GetActiveContractsByDivisionIdRefetch,
  } = useGetActiveContractsByDivisionId(userDetails?.division_id);
  const { mutateAsync: PutUpdateFavouriteActiveContract } =
    usePutUpdateFavouriteLead();
  //-----------------handle Favourite Active Contract----------------//
  const handleFavouriteActiveContract = async (actor_id, is_favorite) => {
    const favouriteActiveContractPlayload = {
      actor_id: actor_id,
      is_favorite: is_favorite == 1 ? 0 : 1,
    };
    try {
      const result = await PutUpdateFavouriteActiveContract(
        favouriteActiveContractPlayload
      );
      if (result?.status === status.s && result?.data?.bReturn) {
        toast.success(result?.data?.message);
        GetActiveContractsByDivisionIdRefetch();
      } else {
        toast.error(result?.data?.message);
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };
  //should be memoized or stable
  /* create contract*/
 //----------Store to handle top loading bar----------//
 const { topLoadingProgressBar, setTopLoadingProgressBar } =
 useCustomerFunnelTopLoadingBar((state) => state);
  const [creatingNewContract, setCreatingNewContract] = useState(false);

  const columns = useMemo(
    () => [
      {
        accessorKey: "Id",
        header: "",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <FontAwesomeIcon
            icon={"fa-solid fa-heart"}
            size="lg"
            className="cursor-pointer"
            style={{
              stroke: "#AA1518",
              strokeWidth: "30px",
              color: `${row?.original?.is_favorite == "1" ? "#AA1518" : "#AA151833"
                }`,
            }}
            onClick={() =>
              handleFavouriteActiveContract(
                row?.original?.actor_id,
                row?.original?.is_favorite
              )
            }
          />
        ),
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "0px !important",
          },
        },
      },
      {
        accessorFn: (row) => `${row.Customer} ${row.Id}`,
        header: "Customer",
        enableHiding: false,
        // size: 100,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            <TagToolTip
              iconColor={"#888888"}
              size={"lg"}
              border="1px solid #F0F0F0"
              padding={"0px"}
              boxShadow="2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
              text={
                <div style={{ width: "700px" }}>
                  <CustomerDetailTooltip data={row?.original} />
                </div>
              }
            />
            {row?.original?.customer == "" ? "-" : row?.original?.customer}
          </div>
        ),
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
      },

      {
        accessorKey: "Property",
        header: "Property",
        size: 180,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            {row?.original?.property_name == ""
              ? "-"
              : row?.original?.property_name}
          </div>
        ),
      },
      {
        accessorKey: "Unit", //normal accessorKey
        header: "Unit",
        size: 200,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            {`${row?.original?.street_name} ${row?.original?.house_number}${row?.original?.floor_number}${row?.original?.alt_unit_id}` ==
              ""
              ? "-"
              : `${row?.original?.street_name} ${row?.original?.house_number
              } ${","}${row?.original?.floor_number} ${"|"} ${row?.original?.alt_unit_id
              }`}
          </div>
        ),
      },
      {
        accessorKey: "Contractcreated",
        header: "Contract created",
        size: 160,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            {row?.original?.deal_created == ""
              ? "-"
              : moment(row?.original?.deal_created).format(
                "DD MMM, YYYY [at] hh:mm"
              )}
          </div>
        ),
      },
      {
        accessorKey: "ContractDeadline",
        header: "Contract deadline",
        size: 80,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            {row?.original?.contract_dealline == ""
              ? "-"
              : moment(row?.original?.contract_dealline).format(
                "DD MMM, YYYY [at] hh:mm"
              )}
          </div>
        ),
      },
      {
        accessorFn: (row) => `${row.status} ${row.bg_color} `,
        header: " ",
        id: "Status",
        enableHiding: false,
        size: 240,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                color: "#FFF",
                backgroundColor:
                  row.original.Status === "SCREENING APPROVED"
                    ? "#349032"
                    : row.original.Status === "SCREENING IN PROCESS"
                      ? "#FFDF00"
                      : "#929692",
                width: "220px",
              }}
              className="px-1 fs-12 radius-4 d-flex justify-content-center align-items-center form-label-title"
            >
              {row.original.Status}
            </div>
          </div>
        ),
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
      },
    ],
    []
  );
  //-------------------useEffect--------------//
  useEffect(() => {
    setActiveContractTable(GetActiveContractsByDivisionId?.data);
  }, [GetActiveContractsByDivisionId]);

  return (
    <div className="whiteContainerStyle">
      <div className="d-flex justify-content-between align-items-center border-bottom px-4 py-3">
        <div>
          <div className="d-flex gap-2">
            {" "}
            <H3>Active Contract</H3>
            <CustomToolTip text={"Active Contract"} size="sm" />
          </div>
          {/* <div className="light-font-text my-1">
            Last updated by Jesper Thorkildsen, 19. Jan. 2023
          </div> */}
        </div>
        <ETButton
          variant={"primary"}
          buttonText={"CREATE NEW CONTRACT"}
          icon={true}
          iconAlign={"left"}
          onClick={() => {
            setCreatingNewContract(true);
            setTopLoadingProgressBar(33);
          }}
        />
      </div>

      <div className="px-4 py-3">
        <div className="light-font-text">
          Below is a list of active contracts that are in the system right now.
        </div>
        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={columns}
              enableTopToolbar={false}
              enableColumnActions={false}
              icons={{ ...SortIcon }}
              // data={unitsData?.filter((data) => data.is_linked_with_screening !== 1) ?? []}
              data={activeContractTable || []}
              enableRowActions={true}
              renderRowActions={({ row, table }) => (
                <Menus
                  itemheight={24}
                  itemwidth={24}
                  iconborderradius="4px"
                  itemcontainerwidth="150px"
                />
              )}
              muiTablePaperProps={{
                sx: {
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                },
              }}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "",
                  size: 50,
                },
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              positionGlobalFilter="right"
              positionToolbarDropZone="bottom"
              positionActionsColumn="last"
            />
          }
        />

       <CreateNewContractWizard
        creatingNewContract={creatingNewContract}
        setCreatingNewContract={setCreatingNewContract}
        setTopLoadingBar={setTopLoadingProgressBar}
        topLoadingBar={topLoadingProgressBar}
      />
      </div>
    </div>
  );
};

export default ActiveContract;

//nested data is ok, see accessorKeys in ColumnDef below
const data = [
  {
    Customer: "Cai Coffey",
    Property: "Kaktus Towers",
    Unit: "Dyblosoro 3, 1 | 212-404",
    Contractcreated: "12 Jul, 2023 at 10:32",
    ContractDeadline: "12 Jul, 2023 at 10:32",
    Status: "SCREENING APPROVED",
    customer_details: [
      {
        firstname: "Cai",
        lastname: "Coffey",
        email: "cai@millicent.in",
        phone_calling_code_id: "+45",
        mobilenumber: "",
        age: "45",
        street_name: "Dybbølsbro",
        house_number: "37",
        floor_no: "2",
        side_door: "B",
        country_id: "DK",
        country: "Denmark",
        zipcode_id: "2000",
        city: "Frederiksberg",
        registration_date: "2023-10-18 08:15:16",
        language_id: "en",
        preferred_languages: "English",
        gdpr_confirmation: 0,
      },
    ],
  },
  {
    Customer: "Jacqueline George",
    Property: "Blomsterbjerget",
    Unit: "Dyblosoro 3, 1 | 212-404",
    Contractcreated: "12 Jul, 2023 at 10:32",
    ContractDeadline: "12 Jul, 2023 at 10:32",
    Status: "SCREENING IN PROCESS",
    customer_details: [
      {
        firstname: "Jacqueline",
        lastname: "George",
        email: "george@millicent.in",
        phone_calling_code_id: "+45",
        mobilenumber: "",
        age: "35",
        street_name: "Toldbodgade",
        house_number: "37",
        floor_no: "2",
        side_door: "B",
        country_id: "DK",
        country: "Denmark",
        zipcode_id: "2000",
        city: "Frederiksberg",
        registration_date: "2023-10-18 08:15:16",
        language_id: "en",
        preferred_languages: "English",
        gdpr_confirmation: 0,
      },
    ],
  },
  {
    Customer: "Solomon Welch",
    Property: "Carolinelunden",
    Unit: "Dyblosoro 3, 1 | 212-404",
    Contractcreated: "12 Jul, 2023 at 10:32",
    ContractDeadline: "12 Jul, 2023 at 10:32",
    Status: "WAITING FOR SCREENING TO START",
    customer_details: [
      {
        firstname: "Solomon",
        lastname: "Welch",
        email: "solomon@millicent.in",
        phone_calling_code_id: "+45",
        mobilenumber: "",
        age: "29",
        street_name: "Toldbodgade",
        house_number: "37",
        floor_no: "2",
        side_door: "B",
        country_id: "DK",
        country: "Denmark",
        zipcode_id: "2000",
        city: "Frederiksberg",
        registration_date: "2023-10-18 08:15:16",
        language_id: "en",
        preferred_languages: "English",
        gdpr_confirmation: 0,
      },
    ],
  },
];

// created logs
// created by Yash
// // added heart component and tooltip component on mouse over section and commented and removed unwanted code-- Suraj
// API integration -- Neha
