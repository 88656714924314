import React, { useEffect, useMemo, useState } from "react";
import MRTTheme from "../../Components/table/MRTTheme";
import {
	MRT_GlobalFilterTextField,
	MaterialReactTable,
} from "material-react-table";

import Menus from "../../Components/Menus/Menus";
import {
	SearchIcon,
	SearchStyle,
	SortIcon,
	TableRowsStyle,
	paginationoptions,
} from "../../Components/table/MRTGlobalProps";
import { InProcessTableData } from "./StaticData";
import TagToolTip from "../../Components/Tooltip/PropertyTagToolTip";
import { GreenSpan } from "../../styles/Common.style";
import ETButton from "../../Components/Buttons/ETButton";
import { Col, Row } from "react-bootstrap";
import MultiColorSelect from "../../Components/DropDown/MultiColorSelect";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StarRating from "../../Components/rating/StarRating";
import { useMediaQuery } from "react-responsive";
import { NoRecordsToDisplay } from "../../utils/validationConstants";

const Customers = () => {
	const [favorite, setFavorite] = useState(null);
	const columns = useMemo(
		() => [
			{
				accessorKey: "Id",
				header: "",
				enableHiding: false,
				size: 50,
				enableGlobalFilter: true,
				Cell: ({ row }) => (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "8px",
							cursor: "pointer",
						}}
					>
						<div>
							<TagToolTip text="Need Help About Tags" border="1px solid #888" />
						</div>
					</div>
				),
				muiTableBodyCellProps: {
					sx: {
						// padding: "0px !important",
						backgroundColor: "#F0F0F0 !important",
						border: "none !important",
					},
				},
			},
			{
				accessorKey: "name",
				header: "Name",
				enableHiding: false,
				// size: 100,
				enableGlobalFilter: true,
				muiTableHeadCellProps: {
					sx: {
						paddingLeft: "0px !important",
					},
				},
				muiTableBodyCellProps: {
					sx: {
						paddingLeft: "0px !important",
						backgroundColor: "#F0F0F0 !important",
						border: "none !important",
						borderRight: "1px solid #888888 !important",
					},
				},
			},
			{
				accessorKey: "phone",
				header: "Phone",
				enableHiding: false,
				size: 140,
				enableGlobalFilter: true,
			},
			{
				accessorFn: (row) => `${row.email}`,
				header: "Email",
				enableHiding: false,
				// size: 100,
				enableGlobalFilter: true,
				Cell: ({ row }) => (
					<div>{row.original.email ? row.original.email : "-"}</div>
				),
			},
			{
				accessorKey: "age",
				header: "Age",
				enableHiding: false,
				size: 40,
				enableGlobalFilter: true,
			},
			{
				accessorFn: (row) => `${row.rating}`,
				header: "Rating",
				enableHiding: false,
				size: 130,
				enableGlobalFilter: true,
				Cell: ({ row }) => (
					<div>
						<StarRating
							setFavoriteRating={setFavorite}
							favoriteRating={favorite}
						/>
					</div>
				),
			},
			{
				accessorFn: (row) => `${row.Relation}`,
				header: "Relation",
				enableHiding: false,
				size: 300,
				enableGlobalFilter: true,
				Cell: ({ row }) => (
					<div>
						{row.original.Relation} (<GreenSpan>show</GreenSpan>)
					</div>
				),
			},
			{
				accessorKey: "responsible_agent",
				header: "Responsible agent",
				enableHiding: false,
				size: 200,
				enableGlobalFilter: false,
			},
			{
				accessorFn: (row) => `${row.Creditworthiness}`,
				header: "Creditworthiness",
				enableHiding: false,
				size: 180,
				enableGlobalFilter: true,
				Cell: ({ row }) => (
					<div
						className="radius-4  form-label-title fs-12 d-flex justify-content-center align-items-center"
						style={{
							backgroundColor:
								row.original.Creditworthiness == "PAYS ON TIME"
									? "#C9E9D9"
									: row.original.Creditworthiness == "OFTEN PAYS ON TIME"
									? "#FFEB80"
									: row.original.Creditworthiness == "RARELY PAYS ON TIME"
									? "#F8B3A5"
									: "#d1d1d1",
							padding: "2px 4px",
						}}
					>
						{row.original.Creditworthiness}
					</div>
				),
			},
			{
				accessorKey: "Tenant_since",
				header: "Tenant since",
				enableHiding: false,
				size: 200,
				enableGlobalFilter: false,
			},
		],
		[]
	);
	// below code is for give responsive design to fixed columns
	const isDesktop = useMediaQuery({ minWidth: 1224 });
	const [columnPinning, setColumnPinning] = useState({});
	useEffect(() => {
		if (isDesktop) {
			setColumnPinning({
				left: ["Id", "name"],
				right: ["mrt-row-actions"],
			});
		} else {
			setColumnPinning({
				right: ["mrt-row-actions"],
			});
		}
	}, [isDesktop]);
	return (
		<div>
			<div className="px-4 pt-4 pb-5">
				<MRTTheme
					colorVariantsForTableRow={["gray"]}
					colorVariantsForTableHead={["white"]}
					table={
						<MaterialReactTable
							columns={columns}
							// data={InProcessTableData} //data is undefined on first render
							data={[]}
							icons={{ ...SortIcon }}
							localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
							enableColumnActions={false}
							enableTopToolbar={true}
							enableRowSelection={false}
							enableRowActions
							enableSelectAll
							enablePinning
							onColumnPinningChange={setColumnPinning}
							state={{ columnPinning }}
							enableFilterMatchHighlighting={true}
							enableGlobalFilter
							globalFilterFn="contains"
							initialState={{
								showGlobalFilter: true,
								showColumnFilters: false,
							}}
							muiTableContainerProps={{
								sx: {
									padding: "0px !important",
								},
							}}
							displayColumnDefOptions={{
								"mrt-row-actions": {
									header: "",
									size: 50,
									muiTableBodyCellProps: {
										sx: {
											// padding: "0px !important",
											backgroundColor: "#F0F0F0 !important",
											borderLeft: "1px solid #888888 !important",
										},
									},
								},
							}}
							positionActionsColumn="last"
							renderRowActions={({ row }) => (
								<Menus
									itemheight={23}
									itemwidth={23}
									iconborderradius="4px"
									itemcontainerwidth="180px"
									component={
										<div className="light-font-text">
											<div className="p-2 cursor-pointer">Go to contract</div>
											<hr className="hr m-0"></hr>
											<div className="p-2 cursor-pointer">Contact card</div>
										</div>
									}
								/>
							)}
							renderTopToolbar={({ table }) => (
								<>
									<Row>
										<Col lg={3} className="pb-2 pe-lg-0">
											<MRT_GlobalFilterTextField table={table} />
										</Col>
										<Col lg={2} className="pb-2 pe-lg-0">
											<ReactSelect placeholder={"Filter"} />
										</Col>
										<Col lg={7} className="pb-2">
											<div className="d-flex justify-content-end">
												<ETButton
													buttonText={"EXPORT MULTIPLE"}
													variant={"primary"}
												/>
											</div>
										</Col>
									</Row>
								</>
							)}
							muiTablePaperProps={{
								selected: false,
								elevation: 0,
								fontFamily: "Cerebri sans-light !important",
								sx: {
									borderRadius: "0",
									border: "none",
									// backgroundColor: "#F9FAFA",
									table: {
										borderCollapse: "separate",
										borderSpacing: "0 5px",
									},
								},
							}}
							muiSearchTextFieldProps={{
								...SearchStyle,

								InputProps: {
									...SearchIcon,
								},
							}}
							muiTablePaginationProps={{
								...paginationoptions,
							}}
							muiTableHeadRowProps={{
								sx: {
									...TableRowsStyle,
								},
							}}
						/>
					}
				/>
			</div>
		</div>
	);
};

export default Customers;
