export const data = [
  // {
  //   name: "Tenancy agreement template",
  //   documentType: "Tenancy agreement",
  //   dateUploaded: "24 Apr, 2023",
  //   inheritfrom: "Organization: NREP",
  // },
  // {
  //   name: "Appendix §11 template",
  //   documentType: "Appendix",
  //   dateUploaded: "22 Apr, 2023",
  //   inheritfrom: "Organization: NREP",
  // },
  // {
  //   name: "house-regulation_23-03-2023_16032...pdf",
  //   documentType: "House regulation",
  //   dateUploaded: "22 Apr, 2023",
  //   inheritfrom: "Organization: NREP",
  // },
  // {
  //   name: "management-accounts.pdf",
  //   documentType: "Management accounts",
  //   dateUploaded: "22 Apr, 2023",
  //   inheritfrom: "Organization: NREP",
  // },
  // {
  //   name: "floor-plan-booklet-03-2023_16032....pdf",
  //   documentType: "Other",
  //   dateUploaded: "22 Apr, 2023",
  //   inheritfrom: "Organization: NREP",
  // },
];
