import React, { useMemo } from "react";
import {
  createTheme,
  InputAdornment,
  TablePagination,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customproperties } from "../../styles/Theme";

// /**
//  * This is datatable extended for theme or styling from Material React table for custom use
//  * @param {props} param0 Material react table as props only supported
//  * @returns table component
//  */
const TableBodycolor = {
  white: "#FFFFFF !important",
  gray: "#F9FAFA !important",
  odd: "#F9FAFA !important",
  even: "#F9FAFA !important",
  "4n+3": "#fff !important",
  "3n+3": "#F9FAFA !important",
};

// const colorMap = {
//   "odd": "red !important", // Set the background color for odd rows
//   "white": "blue !important",
// };

const MRTTheme = ({
  table,
  customWhiteRowBackgroundColor,
  colorVariantsForTableData,
  // TableBodycolors,
  colorVariantsForTableHead,
  colorVariantsForTableBody,
  colorVariantsForTableRow,
  // TableBodycolorsForBody,
}) => {
  const customRowBackgroundColor = TableBodycolor[colorVariantsForTableHead];
  // const customTableDataBackgroundColor = TableBodycolor[colorVariantsForTableData];
  const customTableRowBackgroundColor =
    TableBodycolor[colorVariantsForTableRow];

  // Default theme initialization
  const globalTheme = useTheme();

  //Override default theme for table
  const tableTheme = useMemo(
    () =>
      createTheme({
        // overrides: {},
        // palette: {
        //   // mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
        //   // primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
        //   // info: {
        //   //   main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
        //   // },
        //   // background: {
        //   //   // default:
        //   //   //   globalTheme.palette.mode === "light"
        //   //   //     ? "rgb(254,255,244)" //random light yellow color for the background in light mode
        //   //   //     : "#000", //pure black table in dark mode for fun
        //   // },
        // },

        palette: {
          primary: {
            main: customproperties?.primaryColor
              ? customproperties?.primaryColor
              : "#619130", //
          },
        },

        components: {
          MuiTableContainer: {
            styleOverrides: {
              root: {
                backgroundColor: customRowBackgroundColor,
                padding: "16px",
                borderRadius: "8px 8px 0px 0px !important",

                ".css-vv34gr-MuiTableRow-root": {
                  boxShadow: "unset !important",
                },
              },
            },
          },

          MuiPaginationItem: {
            styleOverrides: {
              root: {
                fontSize: "13px !important",
              },
            },
          },
          MuiTableRow: {
            styleOverrides: {
              root: {
                background: customTableRowBackgroundColor,
                boxShadow: "none !important",
                "&:hover": {
                  "& td": {
                    // backgroundColor: "#F8F9F9 !important",
                  },
                },
                "&:selected": {
                  "& hover": {
                    // backgroundColor: 'red !important',
                  },
                },
              },
            },
          },

          MuiTablePagination: {
            styleOverrides: {
              selectLabel: {
                fontFamily: "Cerebri sans !important ",
                color: "#000 !important",
              },
              select: {
                fontFamily: "Cerebri sans-light !important ",
                color: "#000 !important",
                border: "1px solid #F0F0F0",
                borderRadius: "8px",
                "& .MuiSelect-icon": {
                  // Add your custom icon or replace it with the new one
                  color: "#000 !important",
                },
              },
              displayedRows: {
                fontFamily: "Cerebri sans-light !important ",
                color: "#000 !important",
              },
              toolbar: {
                "& .MuiTablePagination-actions": {
                  color: "#000",

                  "& .MuiIconButton-root": {
                    border: "1px solid #F0F0F0",
                    borderRadius: "8px",
                    margin: "0 1px",
                  },
                },
              },
            },
          },

          MuiPaper: {
            styleOverrides: {
              root: {
                boxShadow: "none",
                borderRadius: "8px !important",
                // backgroundColor: "transparent",

                ".css-g2tram-MuiToolbar-root": {
                  borderRadius: "0px 0px 8px 8px !important",
                },
                "MuiToolbar-root MuiToolbar-gutters MuiToolbar-dense css-g2tram-MuiToolbar-root":
                  {
                    borderRadius: "8px !important",
                  },
                // ".css-pdct74-MuiTablePagination-selectLabel": {
                //   fontSize: "13px !important",
                //   paddingBottom: "2px !important",
                //   fontFamily: "Cerebri sans !important",
                // },
                ".css-levciy-MuiTablePagination-displayedRows": {
                  // fontSize: "13px !important",
                  // fontFamily: "Cerebri sans-light !important",
                },
                ".css-6vypie-MuiInputBase-root-MuiTablePagination-select": {
                  // fontSize: "13px !important",
                  // fontFamily: "Cerebri sans !important",
                },
                ".css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected":
                  {
                    // fontSize: "13px !important",
                    // display: "flex",
                    // fontFamily: "Cerebri sans-light !important",
                    // justifyContent: "center",
                  },
                ".css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem":
                  {
                    // fontSize: "13px !important",
                    // display: "flex",
                    // fontFamily: "Cerebri sans-light !important",
                    // justifyContent: "center",
                  },
              },
            },
          },

          MuiTableCell: {
            styleOverrides: {
              head: {
                fontFamily: "Cerebri sans-medium !important",
                fontWeight: "500 !important",
                fontSize: "13px !important",
                color: "black !important",
                backgroundColor: customRowBackgroundColor,
                boxShadow: "none !important",
                padding: "11px 11px 0px 11px  !important",
                "& td:nth-last-child(1)": {
                  padding: "16px  !important",
                },
              },
              body: {
                fontFamily: "Cerebri sans-light !important",
                fontSize: "13px !important",
                // padding: "11px !important",
                padding: " 11px !important",
                backgroundColor: customTableRowBackgroundColor,
              },
            },
          },
          MuiInputAdornment: {
            endAdornment: (
              <InputAdornment position="end">
                <FontAwesomeIcon
                  icon="fa-regular fa-magnifying-glass"
                  size="xs"
                  style={{ color: "#000000" }}
                />
              </InputAdornment>
            ),
          },
          MuiTextField: {
            styleOverrides: {
              root: {
                width: "100% !important",
                "& .MuiSvgIcon-root": {
                  display: "none !important",
                },
                "& MuiCollapse-wrapperInner MuiCollapse-horizontal css-1mziwkx":
                  {
                    width: "100% !important",
                  },
                "& .MuiOutlinedInput-root": {
                  height: 40,
                  width: "100% !important",
                  background: "white !important",
                  borderRadius: 8,
                  position: "end",
                  fontSize: 14,
                  fontWeight: 300,
                  fontFamily: "Cerebri Sans",
                  "& fieldset": {
                    fontSize: "14px",
                    borderRadius: 8,
                    borderColor: "#D1D1D1",
                  },
                  "&:hover fieldset": {
                    borderColor: "#D1D1D1",
                    boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid #000",
                  },
                  "&.Mui-disabled": {
                    // Adding disabled style here

                    Color: "#888888",
                  },
                },
              },
            },
          },

          MuiTableBody: {
            styleOverrides: {
              root: {
                boxShadow: "none !important",
                ...colorVariantsForTableData?.reduce((styles, variant) => {
                  const backgroundColor = TableBodycolor[variant];
                  if (backgroundColor) {
                    styles[`& tr:nth-of-type(${variant})`] = {
                      backgroundColor: `${backgroundColor}`,
                    };
                  }
                  return styles;
                }, {}),

                ...colorVariantsForTableData?.reduce((styles, variant) => {
                  const backgroundColor = TableBodycolor[variant];
                  if (backgroundColor) {
                    styles[`& tr.${variant}`] = {
                      backgroundColor: `${backgroundColor}`,
                    };
                  }
                  return styles;
                }, {}),
              },
            },
          },

          // MuiTableBody: {
          //   styleOverrides: {
          //     root: {
          //       boxShadow: "none !important",
          //       ...colorVariantsForTableData?.reduce((styles, variant) => {
          //         if (variant === "even") {
          //           styles["& tr:nth-of-type(even)"] = {
          //             backgroundColor: customTableDataBackgroundColor,
          //           };
          //         }
          //         else if (variant === "odd") {
          //           styles["& tr:nth-of-type(odd)"] = {
          //             backgroundColor: customTableDataBackgroundColor,
          //           };
          //         } else if (variant === "4n+3") {
          //           styles["& tr:nth-of-type(4n+3)"] = {
          //             backgroundColor: customTableDataBackgroundColor,
          //           };
          //         }
          //         else if (variant === "3n+3") {
          //           styles["& tr:nth-of-type(3n+3)"] = {
          //             backgroundColor: customTableDataBackgroundColor,
          //           };
          //         }
          //         else if (variant === "gray") {
          //           styles["& tr"] = {
          //             backgroundColor: customTableDataBackgroundColor,
          //           };
          //         }
          //         else if (variant === "white") {
          //           styles["& tr"] = {
          //             backgroundColor: customTableDataBackgroundColor,
          //           };

          //         }

          //         return styles;
          //       }, {}),
          //     },
          //   },
          // },

          // MuiTableBody: {
          //   styleOverrides: {
          //     root: {
          //       boxShadow: "none !important",
          //       "tr:nth-of-type(odd)": {
          //         backgroundColor: "#F9FAFA !important",
          //       },
          //     },
          //   },
          // },

          MuiCheckbox: {
            styleOverrides: {
              root: {
                outline: "none",
                color: "#d1d1d1",
                "&.Mui-checked": {
                  color: "primary",
                },
                "&.MuiCheckbox-indeterminate": {
                  color: "primary",
                },
                "& .MuiSvgIcon-root": {
                  fontSize: "1.4rem !important",
                },
              },
            },
          },
        },
      }),
    [globalTheme, colorVariantsForTableData, customWhiteRowBackgroundColor]
  );

  return <ThemeProvider theme={tableTheme}>{table}</ThemeProvider>;
};

export default MRTTheme;
