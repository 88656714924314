import React, { useMemo } from "react";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { H2, H4 } from "../../../../styles/Common.style";



const Integration = () => {
  return (
    <div className="whiteContainerStyle">
      <div className="px-3 pb-3 border-bottom">
        <div className="px-2 pt-3 d-flex">
          <div className="d-flex align-items-end  gap-2">
            <H2>Integrations</H2>
            <CustomToolTip size="sm" text="Integrations" />
          </div>
        </div>
      </div>
      <div className="p-4 border-bottom">
        <div className="d-flex align-items-center  gap-2 ">
          <H4>Activate various integrations</H4>
          <CustomToolTip size="sm" text="Decline for signing orders" />
        </div>
        <div className="light-font-text mb-3">
          Activate the accessibility to various integrations across your
          organisation. To enter your integration credentials you’ll have to go
          to your Division settings.
        </div>

       
       
       
      </div>
    </div>
  );
};

export default Integration;

// Created by ------> Anmol
