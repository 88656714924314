import React, { useEffect, useMemo, useState } from "react";
import TagToolTip from "../../Components/Tooltip/PropertyTagToolTip";
import { GreenSpan } from "../../styles/Common.style";
import ETButton from "../../Components/Buttons/ETButton";
import { useMediaQuery } from "react-responsive";
import {
  SearchIcon,
  SearchStyle,
  TableRowsStyle,
  paginationoptions,
  SortIcon
} from "../../Components/table/MRTGlobalProps";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import {
  MRT_GlobalFilterTextField,
  MaterialReactTable,
} from "material-react-table";
import Menus from "../../Components/Menus/Menus";
import MRTTheme from "../../Components/table/MRTTheme";
import { ContactUnassignedTableData } from "./StaticData";
import { Col, Row } from "react-bootstrap";
import { NoRecordsToDisplay } from "../../utils/validationConstants";

const UnassignedLeads = () => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "Id",
        header: "",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div
            style={{
              cursor: "pointer",
            }}
          >
            <div>
              <TagToolTip text="Need Help About Tags" border="1px solid #888" />
            </div>
          </div>
        ),
        muiTableBodyCellProps: {
          sx: {
            // padding: "0px !important",
            backgroundColor: "#F0F0F0 !important",
            border: "none !important",
          },
        },
      },
      {
        accessorFn: (row) => `${row.status_time}  ${row.status_name} `,
        header: "Status",
        enableHiding: false,
        // size: 180,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              cursor: "pointer",
            }}
          >
            <div>{row.original.status_time}</div>
            <div
              style={{
                backgroundColor:
                  row.original.status_name == "CRITICAL"
                    ? "#F8B3A5"
                    : row.original.status_name == "NOT CRITICAL"
                    ? "#C9E9D9"
                    : "#d1d1d1",
                width: "100%",
              }}
              className="px-1 radius-4 d-flex justify-content-center align-items-center"
            >
              {row.original.status_name}
            </div>
          </div>
        ),
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
            backgroundColor: "#F0F0F0 !important",
            border: "none !important",
          },
        },
      },
      {
        accessorKey: "name",
        header: "Name",
        enableHiding: false,
        // size: 180,
        enableGlobalFilter: true,
        muiTableBodyCellProps: {
          sx: {
            // padding: "0px !important",
            backgroundColor: "#F0F0F0 !important",
            border: "none !important",
            borderRight: "1px solid #888888 !important",
          },
        },
      },
      {
        accessorKey: "phone",
        header: "Phone",
        enableHiding: false,
        size: 140,
        enableGlobalFilter: true,
      },
      {
        accessorFn: (row) => `${row.email}`,
        header: "Email",
        enableHiding: false,
        // size: 100,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div>{row.original.email ? row.original.email : "-"}</div>
        ),
      },
      {
        accessorKey: "age",
        header: "Age",
        enableHiding: false,
        size: 40,
        enableGlobalFilter: true,
      },
      {
        accessorKey: "registration_date",
        header: "Registration date ",
        enableHiding: false,
        // size: 20,
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => `${row.Campaign_Id}`,
        header: "Campaign",
        enableHiding: false,
        // size: 180,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div className="cursor-pointer">
            Campaign #
            {row.original.Campaign_Id ? row.original.Campaign_Id : "-"} (
            <GreenSpan>show</GreenSpan>)
          </div>
        ),
      },
      {
        accessorKey: "category",
        header: "Category",
        enableHiding: false,
        size: 140,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "responsible_agent",
        header: "Responsible agent",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => `${row.name}  `,
        header: "Status ",
        size: 100,
        enableHiding: false,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div className="">
            <ETButton
              buttonText={"ASSIGN LEAD"}
              variant={"white"}
              size="sm"
              // onClick={() => setShowAddInfo(true)}
            />
          </div>
        ),
        muiTableBodyCellProps: {
          sx: {
            // padding: "0px !important",
            backgroundColor: "#F0F0F0 !important",
            border: "none !important",
            borderLeft: "1px solid #888888 !important",
          },
        },
      },
    ],
    []
  );
  const isDesktop = useMediaQuery({ minWidth: 1224 });
  const [columnPinning, setColumnPinning] = useState({});
  useEffect(() => {
    if (isDesktop) {
      setColumnPinning({
        left: ["Id", "Status", "name"],
        right: ["Status ", "mrt-row-actions"],
      });
    } else {
      setColumnPinning({});
    }
  }, [isDesktop]);
  return (
    <div>
      <div className="px-4 pt-4 pb-5">
        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={columns}
              // data={ContactUnassignedTableData} //data is undefined on first render
              data={[]}
              icons={{...SortIcon}}
              localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
              enableColumnActions={false}
              enableTopToolbar={true}
              enableRowSelection={false}
              enableRowActions
              enableSelectAll
              enableFilterMatchHighlighting={true}
              enableGlobalFilter
              enablePinning
              onColumnPinningChange={setColumnPinning}
              state={{ columnPinning }}
              globalFilterFn="contains"
              // layoutMode="grid"
              // //Disables the default flex-grow behavior of the table cells
              // muiTableHeadCellProps={{
              //   sx: {
              //     flex: "0 0 auto",
              //   },
              // }}
              // muiTableBodyCellProps={{
              //   sx: {
              //     flex: "0 0 auto",
              //   },
              // }}
              // //
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "",
                  size: 50,
                  muiTableBodyCellProps: {
                    sx: {
                      // padding: "0px !important",
                      backgroundColor: "#F0F0F0 !important",
                      border: "none !important",
                    },
                  },
                },
              }}
              positionActionsColumn="last"
              renderRowActions={({ row }) => (
                <Menus
                  itemheight={23}
                  itemwidth={23}
                  iconborderradius="4px"
                  itemcontainerwidth="180px"
                  component={
                    <div className="light-font-text">
                      <div className="p-2 cursor-pointer">Go to xxxx</div>
                      <hr className="hr m-0"></hr>
                      <div className="p-2 cursor-pointer">Contact card</div>
                      {/* <hr className="hr m-0"></hr>
                      <div className="p-2 cursor-pointer">Unassign</div>
                      <hr className="hr m-0"></hr>
                      <div className="p-2 cursor-pointer">Delete</div> */}
                    </div>
                  }
                />
              )}
              renderTopToolbar={({ table }) => (
                <>
                  <Row>
                    <Col lg={3} className="pb-2 pe-lg-0">
                      <MRT_GlobalFilterTextField table={table} />
                    </Col>
                    <Col lg={2} className="pb-2 pe-lg-0">
                      <ReactSelect placeholder={"Filter"} />
                    </Col>
                    <Col lg={7} className="pb-2">
                      <div className="d-flex justify-content-end">
                        <ETButton
                          buttonText={"EXPORT MULTIPLE"}
                          variant={"primary"}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              muiTablePaperProps={{
                selected: false,
                elevation: 0,
                fontFamily: "Cerebri sans-light !important",
                sx: {
                  borderRadius: "0",
                  border: "none",
                  // backgroundColor: "#F9FAFA",
                  table: {
                    borderCollapse: "separate !important",
                    borderSpacing: "0 5px !important",
                  },
                },
              }}
              muiSearchTextFieldProps={{
                ...SearchStyle,

                InputProps: {
                  ...SearchIcon,
                },
              }}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
            />
          }
        />
      </div>
    </div>
  );
};

export default UnassignedLeads;
