import React, { useRef, useState } from "react";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { REQUIRED_ERR } from "../../utils/validationConstants";

const InputTimePicker = ({
  className,
  onChange,
  value,
  disabled,
  placeholder,
  id,
  iserror,
  errormessage,
  errorscope = true,
  ...rest
}) => {
  //========================States & refs========================//
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);

  return (
    <div
      id={id}
      style={{
        position: "relative",
      }}
    >
      <TimePicker
        id={id}
        {...rest}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        className={`${className} ${
          iserror ? "my-error-time-picker" : "my-time-picker"
        } `}
        popupClassName={className}
        showSecond={false}
        disabled={disabled}
        onChange={onChange}
        hideDisabledOptions
        minuteStep={1}
        value={value}
        ref={inputRef}
        placeholder={placeholder}
        use12Hours
      />

      <FontAwesomeIcon
       onClick={(event) => {
        event.stopPropagation();
        !disabled && setIsOpen((prev) => !prev); // Toggle the picker's state
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }}
        icon="fa-regular fa-angle-down"
        size="lg"
        // flip={isOpen && "vertical"}
        style={{
          cursor: disabled ? "not-allowed" : "pointer",
          color: "#000000",
          opacity: disabled && 0.5,
          position: "absolute",
          right: 16.5,
          top: 12.5,
          zIndex: 1,
        }}
      />

      {errorscope && iserror && (
        <p className="error-msg">
            <div className=" d-flex align-items-center gap-1">
            <FontAwesomeIcon
              icon="fa-solid fa-circle-exclamation "
              size="sm"
              style={{ color: "#e33716" }}
            />{" "}
             {errormessage ? errormessage : REQUIRED_ERR}
          </div>
         
        </p>
      )}
      {value && (
        <label
          style={{
            color: disabled && "#888",
            fontFamily: disabled && "Cerebri sans-light",
          }}
          disabled={true}
          className="time-picker-label"
          id={id}
        >
          {placeholder ? placeholder : "Time"}
        </label>
      )}
    </div>
  );
};

// DeliTimePicker.propTypes = {
//   // className: PropTypes.string.isRequired,
//   onChange: PropTypes.func.isRequired,
//   value: PropTypes.instanceOf(moment),
// };

export default InputTimePicker;
