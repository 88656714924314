import { Col, Placeholder, Row } from "react-bootstrap";
import MRTTheme from "../../../Components/table/MRTTheme";
import { MRT_GlobalFilterTextField, MaterialReactTable } from "material-react-table";
import { useMemo } from "react";
import { SearchIcon, SearchStyle, SortIcon, TableRowsStyle, paginationoptions } from "../../../Components/table/MRTGlobalProps";


const EidoNotAssignedMedia = ({ notAssignedMediaPackageData }) => {
    const columns = useMemo(() => [
        {
          accessorKey       : "unit_no",
          header            : "Unit no.",
          size              : 120,
          enableGlobalFilter: true,
          Cell: ({ row }) => (
            <>
                <div>{row?.original?.unit_no}</div>
                <div>{row?.original?.unit_type}</div>
            </>
        ),
        },
        {
          accessorKey       : "package_group",
          header            : "Package group",
          size              : 20,
          enableGlobalFilter: true,
          Cell: ({ row }) => (
            <>
               { row?.original?.package_group.length ? <div>{row?.original?.package_group}</div> : <div> - </div> }
            </>
        ),
        },
      ], []);

    return (
        <div className = "document-content py-3 px-1">
          <div>
          <Placeholder animation = "glow">
              {/* table  */}
              <MRTTheme
                colorVariantsForTableData = {["odd"]}
                table                     = {
                  <MaterialReactTable
                    columns                 = {columns}
                    data                    = {notAssignedMediaPackageData ?? []}
                    icons={{...SortIcon}}
                    state                   = {{ isLoading: !notAssignedMediaPackageData }}
                    renderEmptyRowsFallback = {()=> (<div className = "d-flex justify-content-center w-100 grey-text py-4">
                    <div>
                      There is not any unit which has not assigned a media package.
                    </div>
                  </div>)}
                    enableRowActions
                    positionActionsColumn   = "last"
                    displayColumnDefOptions = {{
                      "mrt-row-actions": {
                        header: "",
                      },
                    }}
                    enableGlobalFilter
                    globalFilterFn="contains"
                    enableTopToolbar        = {true}
                    enableSelectAll         = {false}
                    enableColumnActions     = {false}
                    enableColumnFilter      = {false}
                    enableResizing          = {false}
                    initialState            = {{ showGlobalFilter: true }}
                    enableColumnFilterModes = {false}
                    muiSearchTextFieldProps = {{
                      ...SearchStyle
                      ,

                      InputProps: {
                        ...SearchIcon,
                      },
                    }}
                    muiTablePaginationProps={{
                      ...paginationoptions,
                    }}
                    muiTableHeadRowProps={{
                      sx: {
                        ...TableRowsStyle,
                      },
                    }}
                    renderTopToolbar={({ table }) => (
                      <>
                        <Row className = "py-2 px-3">
                        <Col lg        = {4} className = "my-1 ">
                            {/* {isLoading ? (
                              <div className = "">
                              <div className = "placeholder col-12 radius-8 padding-20 bg-secondary"></div>
                              </div>
                            ) : ( */}
                              <MRT_GlobalFilterTextField table = {table} />
                            {/* )} */}
                          </Col>

                          {/* <Col lg = {4} className = " px-lg-3 my-1">
                                <ReactSelect
                                  placeholder={"Set filter"}
                                  // disabled={data?.is_configuration_completed === 0 && true}
                                  options={fiilterDropdownOption}
                                  // value={signingStatus}
                                  onChange={(e) => {
                                    handleFilterOption(e.value);
                                  }}
                                    defaultValue={{ value: 2, label: "All" }}
                                  clearable={false}
                            
                                />
                          </Col> */}
                        </Row>
                      </>
                    )}
                  />
                }
              />
            </Placeholder>
          </div>
      </div>
    )
}

export default EidoNotAssignedMedia;