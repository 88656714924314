import { useQuery } from "react-query";
import {
  UserProfileSystemLanguage,
  UserTimeZone,
} from "../../services/UserProfile";
import { GetLanguages } from "../../services/Language";

//Get languages
export const useGetlanguages = () => {
  return useQuery("getlanguages", () => UserProfileSystemLanguage(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: 10800000,
  });
};
export const useGetTimeZone = () => {
  return useQuery("getusertime", () => UserTimeZone());
};

export const useGetLanguages = (customToken) => {
  return useQuery("qGetLanguages", () => GetLanguages(customToken), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: 10800000,
  });
};
