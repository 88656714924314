import React from "react";
import { Container } from "react-bootstrap";
import { NavLink } from "../../../../Components/Tabs/ReactTabs.style";
import { useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useGetEditUnitsTextField,
  useGetUnitOverviewByUnitId,
} from "../../../../hooks/queries/useUnitQueries";
import { useUnitId, useUnitType } from "../../../../hooks/stores/useUnitStore";
import {
  usePropertyId,
  usePropertyOverview,
} from "../../../../hooks/stores/usePropertyStore";
import { GreenSpan } from "../../../../styles/Common.style";
import { useRouting } from "../../../../hooks/stores/useRoutingStore";
import { useSigningOrderStore } from "../../../../hooks/stores/useSigningStore";
import { ViewSigningPackages } from "../../../actors/customers/signing/ViewSigningPackage";
import { editUnitTabs } from "../../../../model/Constant";
import { useBreadcrumb } from "../../../../Components/BreadcrumbContext ";
import { H1 } from "../../../../styles/Common.style";

const EditUnitTabs = ({ Breadcrumbs }) => {
  const { UnitId, setUnitId } = useUnitId((state) => state);
  const propertyId = usePropertyId((state) => state.propertyId);
  // const [contractId, setContractId] = useState("");
  const [initialSelectedName, setInitialSelectedName] = useState(null);
  // const [selectedTabPage, setSelectedTabPage] = useState("Overview");
  const [selectedTab, setSelectedTab] = useState("");
  const [selectedName, setSelectedName] = useState(initialSelectedName);
  // const [selectedTabActors, setSelectedTabActors] = useState(0);
  const [overviewMainTabs, setOverviewMainTabs] = useState("Status");
  const [overviewTabs, setoverviewTabs] = useState("");
  const [loading, setLoading] = useState(true);
  // const [unitNo, setUnitNo] = useState("");

  // const
  const { typeOfUnit } = useUnitType((state) => state);
  const propOverview = usePropertyOverview((state) => state.propOverview);
  const { isViewSigningOrder } = useSigningOrderStore((state) => state);
  const navigate = useNavigate();

  let location = useLocation();
  const { state } = location;
  let propId = state?.propId;
  // console.log(state, "unit_no2");
  // console.log(UnitId, "UnitId");
  // let UnitId = parseInt(state?.unit_id);
  // console.log(state?.unit_id, "state?.unit_id");
  //---------------React query---------------//
  const { data: getUnitTextFields } = useGetEditUnitsTextField(
    propId,
    typeOfUnit
  );
  const { packageType, setPackageType, showPackage } = useRouting(
    (state) => state
  );

  const { data: GetUnitOverviewByUnitId } = useGetUnitOverviewByUnitId(
    state?.unit_id
  );
  // const unitDetailTextFields =getUnitTextFields?.data.filter(x =>x.table_name =="unit_detail");

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: "#000",
      width: 220,
      fontFamily: "Cerebri sans",
      fontSize: "14px",
      border: "1px solid #F4F5F5",
      borderRadius: "8px",
    },
  }));


  useEffect(() => {
    const activePath = window.location.pathname;
    if (activePath === "/property-units-edit/unit-details") {
      setSelectedTab("Unit details");
    } else {
      // For other cases, you can capitalize the first letter of the last part of the path
      const pathParts = activePath.split("/");
      const lastPathPart = pathParts[pathParts.length - 1];
      setSelectedTab(lastPathPart.charAt(0).toUpperCase() + lastPathPart.slice(1));
    }
  }, []);

  // useEffect(() => {
  // setUnitId(state?.unit_id);
  // navigate("overview");
  // return () => {};
  // }, []);

  // const handleClick = (name) => {
  //   setSelectedName(name);
  //   setSelectedTabPage("Overview");
  // };
  // // Tabs Index Value
  // const TabsWizards = [
  //   {
  //     Id: "1",
  //     tab: "Overview",
  //     component: (
  //       <UnitsOverview
  //         UnitId={UnitId}
  //         propId={propId}
  //         setSelectedTabName={setSelectedTabPage}
  //         setOverviewMainTabs={setOverviewMainTabs}
  //         setoverviewTabs={setoverviewTabs}
  //         overviewTabs={overviewTabs}
  //       />
  //     ),
  //   },
  //   {
  //     Id: "2",
  //     tab: "Contracts",
  //     component: (
  //       <ActorsCustomers
  //         setInitialSelectedName={setInitialSelectedName}
  //         setSelectedTab={setSelectedTabActors}
  //         selectedTab={selectedTabActors}
  //         setSelectedTabPage={setSelectedTabPage}
  //         handleClick={handleClick}
  //       />
  //     ),
  //   },
  //   {
  //     Id: "3",
  //     tab: "Unit details",
  //     component: (
  //       <UnitDetails
  //         UnitId={UnitId}
  //         propId={propId}
  //         getUnitTextFields={getUnitTextFields?.data}
  //       />
  //     ),
  //   },
  //   {
  //     Id: "4",
  //     tab: "Economy",
  //     component: (
  //       <SingleUnitEconomy
  //         UnitId={UnitId}
  //         propId={propId}
  //         getUnitTextFields={getUnitTextFields?.data}
  //       />
  //     ),
  //   },
  //   {
  //     Id: "5",
  //     tab: "Facilities",
  //     // component: <Facilities UnitId={UnitId} propId={propId} />,
  //   },
  //   {
  //     Id: "6",
  //     tab: "Texts",
  //     component: (
  //       <EditTexts
  //         // propertyOverviw={propertyOverviw}
  //         UnitId={UnitId}
  //         propId={propId}
  //       />
  //     ),
  //   },
  //   {
  //     Id: "7",
  //     tab: "Medias",
  //     // component: <Media UnitId={UnitId} propId={propId} />,
  //   },
  //   {
  //     Id: "8",
  //     tab: "Marketing",
  //     component: (
  //       <Marketing
  //         UnitId={UnitId}
  //         propId={propId}
  //         getUnitTextFields={getUnitTextFields?.data}
  //       />
  //     ),
  //   },
  //   {
  //     Id: "9",
  //     tab: "Settings",
  //     // component: <Settings UnitId={UnitId} propId={propId} />,
  //   },
  //   {
  //     Id: "10",
  //     tab: "Documents",
  //     component: <EditDocuments UnitId={UnitId} propId={propId} />,
  //   },
  // ];

  const handleselectedTab = (tab) => {
    setSelectedTab(tab);
  };
  useEffect(() => {
    setUnitId(UnitId);
  }, [propId, UnitId]);

  useEffect(() => {
    setUnitId(state?.unit_id);
    // Simulate loading data
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);

  useEffect(() => {
    if (overviewMainTabs === "Status") {
      setoverviewTabs("Status");
    }
    if (overviewMainTabs === "Requests") {
      setoverviewTabs("Inquiries");
    }
    if (overviewMainTabs === "In process") {
      setoverviewTabs("Reservations and screenings");
    }
  }, [overviewMainTabs]);

  const { updateBreadcrumbs } = useBreadcrumb();
  // useEffect(() => {
  //   updateBreadcrumbs(["Overview", "Status"]);
  // }, []);

  return (
    <>
      <Container fluid style={{ backgroundColor: "#F9FAFA" }}>
        <div
          style={{
            display:
              isViewSigningOrder?.showSigningPage == true ? "contents" : "none",
          }}
        >
          <ViewSigningPackages
            unitOverviewData={GetUnitOverviewByUnitId?.data[0]}
            // unit_no={state?.unit_no}
            // street_name={state?.street_name}
            // house_number={state?.house_number}
            // zip_code={state?.zip_code}
            selectedName={selectedName}
            unitId={state?.unit_id}
          />
        </div>
        <div
          style={{
            display:
              isViewSigningOrder?.showSigningPage == false
                ? "contents"
                : "none",
          }}
        >
          {" "}
          <>
            <div className=" pt-3 ">
              <H1>
                {selectedTab == "Unit details"
                  ? selectedTab
                  : `Unit ${selectedTab.toLowerCase()}`}
              </H1>
              <div
                style={{ fontFamily: " Cerebri sans-light", fontSize: "14px" }}
              >
                <Link
                  to="/properties"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <span className="me-3">Properties</span>
                </Link>

                <span className="me-3">
                  <FontAwesomeIcon
                    size="sm"
                    icon="fa-regular fa-angle-right"
                    style={{ color: "#000000" }}
                  />{" "}
                </span>
                <Link
                  to="/property-units/overview"
                  state={{ Tab: "Units" }}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <span className="me-3">
                    {propOverview?.[0]?.propertyName}
                  </span>
                </Link>

                <span className="me-3">
                  <FontAwesomeIcon
                    size="sm"
                    icon="fa-regular fa-angle-right"
                    style={{ color: "#000000" }}
                  />{" "}
                </span>
                <Link
                  to="/property-units/units/residential"
                  state={{ Tab: "Units" }}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <span className="me-3 ">Units</span>
                </Link>

                <span className="me-3">
                  <FontAwesomeIcon
                    size="sm"
                    icon="fa-regular fa-angle-right"
                    style={{ color: "#000000" }}
                  />{" "}
                </span>
                {/* {GetUnitOverviewByUnitId?.data[0]?.unit_calculated_number || GetUnitOverviewByUnitId?.data[0]?.Address && */}
                <>
                  <Link
                    to="/property-units-edit/overview"
                    state={{ Tab: "Units", unit_id: state?.unit_id }}
                    style={{ textDecoration: "none", color: "black" }}
                  >

                    <span className="me-3">
                      {GetUnitOverviewByUnitId?.data[0]?.unit_calculated_number},{" "}
                      {GetUnitOverviewByUnitId?.data[0]?.Address?.slice(0, -6)}
                    </span>
                  </Link>
                  <span className="me-3">
                    <FontAwesomeIcon
                      size="sm"
                      icon="fa-regular fa-angle-right"
                      style={{ color: "#000000" }}
                    />{" "}
                  </span>
                </>
                {/* } */}


                <span className="me-3">{Breadcrumbs}</span>
                {/* <span className="me-3">
                  <FontAwesomeIcon
                    size="sm"
                    icon="fa-regular fa-angle-right"
                    style={{ color: "#000000" }}
                  />{" "}
                </span>
                {selectedTab === "Contracts" ? (
                  <>
                    <span className="me-3">{selectedTab}</span>
                    <span className="me-3">
                      <FontAwesomeIcon
                        size="sm"
                        icon="fa-regular fa-angle-right"
                        style={{ color: "#000000" }}
                      />
                    </span>
                    <span className="me-3">
                      {selectedName?.length > 0 ? selectedName : "Tenant Name"}
                    </span>
                    <span className="me-3">
                      <FontAwesomeIcon
                        size="sm"
                        icon="fa-regular fa-angle-right"
                        style={{ color: "#000000" }}
                      />
                    </span>
                    <GreenSpan className="me-3">{selectedTabPage}</GreenSpan>
                  </>
                ) : selectedTab === "Overview" ? (
                  <>
                    <span className="me-3">{selectedTab}</span>
                    <span className="me-3">
                      <FontAwesomeIcon
                        size="sm"
                        icon="fa-regular fa-angle-right"
                        style={{ color: "#000000" }}
                      />
                    </span>
                    <span className="me-3">{overviewMainTabs}</span>
                    {overviewTabs === "" ? (
                      ""
                    ) : (
                      <>
                        <span className="me-3">
                          <FontAwesomeIcon
                            size="sm"
                            icon="fa-regular fa-angle-right"
                            style={{ color: "#000000" }}
                          />
                        </span>
                        <GreenSpan className="me-3">{overviewTabs}</GreenSpan>
                      </>
                    )}
                  </>
                ) : (
                  <GreenSpan className="me-3 ">{selectedTab}</GreenSpan>
                )} */}
              </div>

              <div className="d-flex pt-3 pb-3" style={{ flexWrap: "wrap" }}>
                {editUnitTabs?.map((item, i) => (
                  <NavLink
                    key={i}
                    state={{ propId: propertyId, unit_id: state?.unit_id }}
                    to={item.link}
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      setSelectedTab(item.tab);
                      // setSelectedSubTabType(["Overview"])
                    }}
                    className=" me-2"
                  >
                    {item.tab}
                  </NavLink>
                ))}
              </div>
            </div>
            <div
              className="p-4 containerStyle"

            >
              <Outlet />
            </div>
          </>
        </div>
        {/* {isViewSigningOrder?.showSigningPage ? (
          <ViewSigningPackages
            unitOverviewData={GetUnitOverviewByUnitId?.data[0]}
            selectedName={selectedName}
            unitId={state?.unit_id}
          />
        ) : (
          <>
            <div className=" pt-3 ">
              <div className="header-main-title">
                {selectedTab == "Unit details"
                  ? selectedTab
                  : `Unit ${selectedTab.toLowerCase()}`}
              </div>
              <div
                style={{ fontFamily: " Cerebri sans-light", fontSize: "14px" }}
              >
                <Link
                  to="/properties"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <span className="me-3">Properties</span>
                </Link>

                <span className="me-3">
                  <FontAwesomeIcon
                    size="sm"
                    icon="fa-regular fa-angle-right"
                    style={{ color: "#000000" }}
                  />{" "}
                </span>
                <Link
                  to="/property-units/overview"
                  state={{ Tab: "Units" }}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <span className="me-3">
                    {propOverview?.[0]?.propertyName}
                  </span>
                </Link>

                <span className="me-3">
                  <FontAwesomeIcon
                    size="sm"
                    icon="fa-regular fa-angle-right"
                    style={{ color: "#000000" }}
                  />{" "}
                </span>
                <Link
                  to="/property-units/units/residential"
                  state={{ Tab: "Units" }}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <span className="me-3 ">Units</span>
                </Link>

                <span className="me-3">
                  <FontAwesomeIcon
                    size="sm"
                    icon="fa-regular fa-angle-right"
                    style={{ color: "#000000" }}
                  />{" "}
                </span>
                <Link
                  to="/property-units-edit/overview"
                  state={{ Tab: "Units", unit_id: state?.unit_id }}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <span className="me-3">
                    {GetUnitOverviewByUnitId?.data[0]?.unit_calculated_number},{" "}
                    {GetUnitOverviewByUnitId?.data[0]?.Address?.slice(0, -6)}
                  </span>
                </Link>
                <span className="me-3">
                  <FontAwesomeIcon
                    size="sm"
                    icon="fa-regular fa-angle-right"
                    style={{ color: "#000000" }}
                  />{" "}
                </span>

                <span className="me-3">{Breadcrumbs}</span>
              </div>

              <div className="d-flex pt-3 pb-3" style={{ flexWrap: "wrap" }}>
                {editUnitTabs?.map((item, i) => (
                  <NavLink
                    key={i}
                    state={{ propId: propertyId, unit_id: state?.unit_id }}
                    to={item.link}
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      setSelectedTab(item.tab);
                      // setSelectedSubTabType(["Overview"])
                    }}
                    className=" me-2"
                  >
                    {item.tab}
                  </NavLink>
                ))}
              </div>
            </div>
            <div
              className="p-4"
              style={{ backgroundColor: "#f1f5f0", borderRadius: "10px" }}
            >
              <Outlet />
            </div>
          </>
        )} */}
      </Container>
    </>
  );
};

export default EditUnitTabs;
