import {
	Tab,
	TabList,
	TabPanel,
	Tabs,
} from "../../Components/Tabs/ReactTabs.style";
import { useBreadcrumb } from "../../Components/BreadcrumbContext ";
import Reservation from "./ongoing/Reservation";
import Screening from "./ongoing/Screening";
import Signing from "./ongoing/Signing";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import { H2 } from "../../styles/Common.style";

const ContactsOngoing = () => {
	const { updateBreadcrumbs } = useBreadcrumb();

	return (
		<div style={{ backgroundColor: "#ffffff" }}>
			<div
				className="px-4  py-3 d-flex "
				style={{ borderBottom: "1px solid #F0F0F0" }}
			>
				<div className="d-flex align-items-center">
					<H2 >Ongoing</H2>
					<div className="ps-3">
						{" "}
						<CustomToolTip height={12} text="Ongoing" />{" "}
					</div>
				</div>
			</div>
			<Tabs lassName="mb-3 pb-1 d-flex flex-column">
				<div
					className="d-flex ps-4 pe-3 py-3 justify-content-between"
					style={{
						width: "100%",
						flexDirection: "row",
						flexWrap: "wrap",
						borderBottom: "1px solid #F0F0F0",
					}}
				>
					<TabList className="light ps-0  ">
						<Tab onClick={() => updateBreadcrumbs(["Ongoing", "Reservation"])}>
							Reservation
						</Tab>
						<Tab onClick={() => updateBreadcrumbs(["Ongoing", "Screening"])}>
							Screening
						</Tab>
						<Tab onClick={() => updateBreadcrumbs(["Ongoing", "Signing"])}>
							Signing
						</Tab>
					</TabList>
				</div>
				<div className="bg-white" style={{ borderRadius: " 10px" }}>
					<TabPanel>
						<Reservation />
					</TabPanel>
					<TabPanel>
						<Screening />
					</TabPanel>
					<TabPanel>
						<Signing />
					</TabPanel>
				</div>
			</Tabs>
		</div>
	);
};

export default ContactsOngoing;
