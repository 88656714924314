import React from "react";
import CustomPopup from "../../../../Components/CustomPopup";
import ETButton from "../../../../Components/Buttons/ETButton";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { Col, Row } from "react-bootstrap";

const ChangeForm = ({ setChangeForm, changeForm }) => {
  return (
    <div>
      {" "}
      <CustomPopup
        open={changeForm}
        onHide={() => setChangeForm(false)}
        title="Change an inherited form?"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="p-4">
          You have made changes to an inherited form and this means that a copy
          of this form will be created and used only on this property.
          <br /> Do you want this?
        </div>
        <div className="px-4 pb-4 mb-3">
          {" "}
          <Row>
            <Col sm={11}>
              <ReactSelect placeholder={"Internal title"} />
            </Col>
          </Row>
        </div>
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => {
              setChangeForm(false);
            }}
          />
          <ETButton variant={"primary"} buttonText="CONFIRM" />
        </div>
      </CustomPopup>
    </div>
  );
};

export default ChangeForm;
