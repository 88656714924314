import React, { useEffect, useMemo, useState } from "react";
import CustomPopup from "../../../../Components/CustomPopup";
import ETButton from "../../../../Components/Buttons/ETButton";
import TagToolTip from "../../../../Components/Tooltip/PropertyTagToolTip";
import MRTTheme from "../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";

import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../../Components/table/MRTGlobalProps.js";
import { useGetScreening } from "../../../../hooks/queries/useScreeningQueries";
import moment from "moment";
import { useScreening } from "../../../../hooks/stores/useScreeningStore";

const CancelScreeningProcess = ({
  setCancelActiveScreening,
  cancelActiveScreening,
  handleCancelScreening,
  handleCanelAllAndArchive,
  handleStatusChange,
  setFormStatusId,
}) => {
  const { screeningFormId } = useScreening((state) => state);
  // const data = [
  // 	{
  // 		name: "Joao Wienerberg Carl",
  // 		ScreeningDate: "12 Jul, 2023 at 12:00",
  // 		ScreeningDeadline: "11 Jul, 2023 at 16:12",
  // 		ResponsiblAgent: "Max Wildersans Brentford",
  // 		Status: "Ongoing (85%)",
  // 	},
  // ];
  const [formattedDateTime, setFormattedDatetime] = useState("");
  const [statusId, setStatusId] = useState("1,2");

  const convertDatetime = (inputDatetime) => {
    if (inputDatetime == "") return "-";
    try {
      const inputFormat = "YYYY-MM-DD HH:mm:ss";
      const inputDate = moment(inputDatetime, inputFormat);

      if (inputDate.isValid()) {
        const outputFormat = "DD MMM, YYYY [at] HH:mm";
        const formattedDate = inputDate
          .set({ hour: 12, minute: 0 })
          .format(outputFormat);
        return formattedDate;
      } else {
        setFormattedDatetime("Invalid input datetime format");
      }
    } catch (error) {
      setFormattedDatetime("An error occurred");
    }
  };

  const columns = useMemo(() => [
    {
      accessorKey: "full_name",
      header: "Name",
      size: 180,
      enableGlobalFilter: true,
      Cell: ({ row }) => (
        <>
          <div className="d-flex align-items-center gap-2">
            <TagToolTip size="md" text="Need Help About Tags" />
            <div> {row.original.full_name} </div>
          </div>
        </>
      ),
    },
    {
      accessorFn: (row) => convertDatetime(row?.screening_start_date),
      header: "Screening started",
      size: 180,
      enableGlobalFilter: true,
    },
    {
      accessorFn: (row) => convertDatetime(row?.screening_deadline),
      header: "Screening deadline",
      size: 120,
      enableGlobalFilter: true,
    },
    {
      accessorKey: "responsible_agent",
      header: "Responsible agent",
      size: 180,
      enableGlobalFilter: true,
    },
    {
      accessorFn: (row) =>
        `${row?.status_name} (${row?.completion_percentage} %)`,

      header: "Status",
      size: 120,
      enableGlobalFilter: true,
    },
  ]);

  const [data, setData] = useState([]);
  const { data: screeningData, refetch: getScreeningRefetch } =
    useGetScreening(screeningFormId);
  // console.log("screeningData", screeningData);

  const handleJustArchive = async () => {
    await setFormStatusId(3);
    await handleStatusChange();
    await getScreeningRefetch();
  };
  const renderEmptyRowsFallback = () => {
    // const tagfilterLength = tagfilter?.length || 0;
    const isEmpty = data && data?.length === 0;

    return (
      <div className="d-flex justify-content-center w-100 grey-text py-4">
        {isEmpty ? (
          <div>You do not have any data yet!.</div>
        ) : (
          <div className="fs-14">Sorry! No results found</div>
        )}
      </div>
    );
  };
  useEffect(() => {
    setData(screeningData?.data);
  }, [screeningData]);
  return (
    <div>
      {" "}
      <CustomPopup
        open={cancelActiveScreening}
        onHide={() => setCancelActiveScreening(false)}
        title={"Cancel screening with active screenings in process?"}
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        size={"xl"}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="p-4">
          <div className="light-font-text">
            Please note that there are active screenings in progress that use
            the form you wish to archive
          </div>
          <div className="light-font-text mb-3">
            Below you can see the ongoing screenings. Choose whether they should
            be canceled or can be completed in their current form. If cancel the
            screening is canceled in the system and the customer will receive a
            cancellation email, and you must start up a new screening.
          </div>
          <div className="light-font-text">
            Once a form is archived, no new screenings can be started on it, but
            ongoing screenings that you choose not to cancel can be completed.
          </div>
        </div>
        <MRTTheme
          colorVariantsForTableData={["odd"]}
          table={
            <MaterialReactTable
              columns={columns}
              data={data ?? []}
              icons={{ ...SortIcon }}
              renderEmptyRowsFallback={renderEmptyRowsFallback}
              enableRowActions
              positionActionsColumn="last"
              renderTopToolbar={false}
              renderRowActions={({ row }) => (
                <>
                  <ETButton
                    variant={"white"}
                    buttonText={"CANCEL SCREENING"}
                    onClick={() =>
                      handleCancelScreening(
                        row?.original?.actor_screening_id,
                        4
                      )
                    }
                  />
                </>
              )}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "Action",
                },
              }}
              enableTopToolbar={true}
              enableSelectAll={false}
              enableColumnActions={false}
              enableColumnFilter={false}
              enableResizing={false}
              initialState={{ showGlobalFilter: true }}
              enableColumnFilterModes={false}
              muiSearchTextFieldProps={{
                ...SearchStyle,

                InputProps: {
                  ...SearchIcon,
                },
              }}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
            />
          }
        />

        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => {
              setCancelActiveScreening(false);
            }}
          />
          <div className="d-flex gap-2">
            <ETButton
              variant={"secondary"}
              buttonText="CANCEL ALL, And Archive"
              onClick={() => handleCanelAllAndArchive(data)}
            />
            <ETButton
              variant={"primary"}
              buttonText="Just archive"
              onClick={handleJustArchive}
            />
          </div>
        </div>
      </CustomPopup>
    </div>
  );
};

export default CancelScreeningProcess;
