import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  useGetOrganizationFloorType,
  useGetOrganizationSideType,
} from "../../hooks/queries/useOrganization";
import { useGetGeographyCountry } from "../../hooks/queries/usePropertyQueries";
import {
  useGetStreetDetails,
  useGetZipcodeByCountry,
} from "../../hooks/queries/useGeographyQueries";
import { Col, Form, Row } from "react-bootstrap";
import { H4 } from "../../styles/Common.style";
import ReactSelect from "../DropDown/ReactSelect";
import Toggle from "../Switch/Switch";
import InputField from "../Textfield/InputField";
import StreetInput from "../Textfield/StreetInput";
import ETButton from "../Buttons/ETButton";
import { billingDetailsDropdownData } from "../../model/commonDropdownData";
import { useGetBillingLevelDetails } from "../../hooks/queries/useCommonTypeQueries";
import { useOrganizationId } from "../../hooks/stores/useOrganizationStore";

const CreateBillingDetails = ({
  setProgress,
  setStepWizard,

  setShow,
  handleDisable,
  progress,
  flag,
  handleAddDataFun,
  handlePrevious,
  isSameDetailAsAboveActive = () => {},
  setIsSameDetailAsAboveActive = () => {},
  formData = () => {},
  createButtonDisable = () => {},
  setFormData,
  flagEdit,
}) => {
  //------------- State ------------------//
  const [searchedStreetName, setSearchedStreetName] = useState("");
  const [isSearchStreetVisible, setIsSearchStreetVisible] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    reset,
    getValues,
    setValue,
    resetField,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      bInfo_company: "",
      bInfo_care_of: "",
      bInfo_cvr_number: "",
      bInfo_vat_number: "",
      bInfo_gst_number: "",
      bInfo_street: "",
      bInfo_house_number: "",
      bInfo_floor_number: "",
      bInfo_side: "",
      bInfo_door: "",
      bInfo_country: "",
      bInfo_zipCode: "",
      bInfo_city: "",
      invoice_email: "",
      attention_person: "",
      ean_number: "",
      billing_method: "",
    },
  });

  // ================== Zusatnd ============== //
  const organizationId = useOrganizationId((state) => state.organizationId);

  //------------- React Query ------------------//
  const { data: getOrganizationFloorTypeData } = useGetOrganizationFloorType();
  const { data: getOrganizationSideTypeData } = useGetOrganizationSideType();
  const { data: GetcountryData } = useGetGeographyCountry();
  const { data: GetzipCodeByCountryIdData } = useGetZipcodeByCountry(
    getValues("bInfo_country")?.value
  );

  const { data: GetBillingLevelDetailsData } = useGetBillingLevelDetails(
    flag === "Division"
      ? "d"
      : flag === "Company"
      ? "c"
      : flag === "Property"
      ? "p"
      : null,
    //2nd parameter
    flag === "Division"
      ? organizationId
      : flag === "Company"
      ? formData?.division_id?.value
      : flag === "Property"
      ? formData?.company_id?.value
      : null
  );

  console.log(formData, "dorm");
  // -------------------- handle Create New ---------------------//
  const handleCreateNew = (data) => {
    if (flag == "Division") {
      handleAddDataFun(data);
    } else {
      const billingData = { ...formData, ...data };
      setFormData({
        ...formData,
        ...data,
      });
      handleAddDataFun(billingData);
    }
  };

  // -------------------- handle Previous---------------------//
  const handlePreviousBillingInfo = () => {
    // setStepWizard("CreateNewInfo");
    setFormData({
      ...formData,
      ...getValues(),
    });
    handlePrevious();
  };

  //----------------------------Zipcode handler--------------------------//

  const handleZipCode = (e, ActionType) => {
    if (ActionType.action === "clear") {
      setValue("bInfo_city", "", { shouldValidate: true });
    } else {
      if (e?.value != "" || e?.value != "undefined") {
        const HandleCityName = GetzipCodeByCountryIdData?.data?.filter(
          (data) => data?.Id == e?.value
        );
        const CityData = HandleCityName[0].Text;
        setValue("bInfo_city", CityData, { shouldValidate: true });
      }
    }
  };
  // -------------------- handle Street Detail Click ---------------------//
  const handleStreetDetailClick = (item, e) => {
    setIsSearchStreetVisible(false);
    setValue("bInfo_street", item?.street_name);
    setValue("bInfo_city", item?.city, {
      shouldValidate: item?.city !== "" && true,
    });
    setValue("bInfo_house_number", item?.house_number, {
      shouldValidate: item?.house_number !== "" && true,
    });
    setValue(
      "bInfo_zipCode",
      item?.zipcode == ""
        ? ""
        : {
            value: item?.zipcode,
            label: GetzipCodeByCountryIdData?.data?.filter(
              (el) => el.Id == item?.zipcode
            )?.[0]?.Text,
          },
      { shouldValidate: item?.zipcode !== "" && true }
    );
  };

  //------------------country dropdown value--------------------------------//
  const displayItem = (selected) => {
    return selected
      ? {
          value: selected?.Id,
          label: GetcountryData?.data?.filter(
            (item, i) => item.Id === selected?.value
          )?.[0]?.Text,
        }
      : "";
  };

  //------Handle street field change--------//
  const handleStreetFieldChange = (event) => {
    if (
      watch("bInfo_country")?.value == "DK" &&
      event.target.value?.length >= 4
    ) {
      setIsSearchStreetVisible(true);
      setSearchedStreetName(event?.target?.value);
    } else {
      setIsSearchStreetVisible(false);
    }
  };

  // -------------------- useEffect ---------------------//
  useEffect(() => {
    if (formData) {
      setValue("bInfo_company", formData?.bInfo_company);
      setValue("bInfo_care_of", formData?.bInfo_care_of);

      if (formData?.bInfo_cvr_number)
        setValue("bInfo_cvr_number", formData?.bInfo_cvr_number);
      if (formData?.bInfo_vat_number)
        setValue("bInfo_vat_number", formData?.bInfo_vat_number);
      if (formData?.bInfo_gst_number)
        setValue("bInfo_gst_number", formData?.bInfo_gst_number);

      setValue("bInfo_street", formData?.bInfo_street);
      setValue("billing_method", formData?.billing_method);
      setValue("bInfo_house_number", formData?.bInfo_house_number);
      setValue("bInfo_floor_number", formData?.bInfo_floor_number);
      setValue("bInfo_side", formData?.bInfo_side);
      setValue("bInfo_door", formData?.bInfo_door);
      setValue("bInfo_country", formData?.bInfo_country);
      setValue("bInfo_zipCode", formData?.bInfo_zipCode);
      setValue("bInfo_city", formData?.bInfo_city);
      setValue("invoice_email", formData?.invoice_email);
      setValue("attention_person", formData?.attention_person);
      setValue("ean_number", formData?.ean_number);
    }
    if (isSameDetailAsAboveActive) {
      if (flag !== "Property") {
        setValue("bInfo_company", formData?.company);
        setValue("bInfo_care_of", formData?.care_of);
        setValue("bInfo_cvr_number", formData?.cvr_number);
        setValue("bInfo_vat_number", formData?.vat_number);
        setValue("bInfo_gst_number", formData?.gst_number);
        setValue("attention_person", formData?.contact_person_name);
      }

      setValue("bInfo_street", formData?.street);
      setValue("bInfo_house_number", formData?.house_number);
      setValue("bInfo_floor_number", formData?.floor_number);

      setValue("bInfo_side", {
        value: formData?.side?.value,
        label: formData?.side?.label,
        disabled: formData?.side?.isDisabled,
      });
      setValue("bInfo_door", formData?.door);
      setValue("bInfo_country", {
        value: formData?.country?.value,
        label: formData?.country?.label,
      });
      setValue("bInfo_zipCode", {
        value: formData?.zipcode_id?.value,
        label: formData?.zipcode_id?.label,
      });
      setValue("bInfo_city", formData?.city);
    }
  }, [formData]);

  useEffect(() => {
    if (
      getValues("bInfo_cvr_number")?.length ||
      getValues("bInfo_vat_number")?.length ||
      getValues("bInfo_gst_number")?.length
    ) {
      clearErrors(["bInfo_cvr_number", "bInfo_vat_number", "bInfo_gst_number"]);
    }
  }, [
    watch("bInfo_cvr_number"),
    watch("bInfo_vat_number"),
    watch("bInfo_gst_number"),
  ]);

  const handleformData = () => {
    clearErrors("");
    if (!isSameDetailAsAboveActive) {
      if (flag === "Property") {
        if (!flagEdit) setValue("bInfo_company", formData?.company);
        if (!flagEdit) setValue("bInfo_care_of", formData?.care_of);
        if (!flagEdit) setValue("bInfo_cvr_number", formData?.cvr_number);
        if (!flagEdit) setValue("bInfo_vat_number", formData?.vat_number);
        if (!flagEdit) setValue("bInfo_gst_number", formData?.gst_number);
        setValue("attention_person", formData?.contact_person_name);
        setValue("bInfo_street", formData?.street);
        setValue("bInfo_house_number", formData?.house_number);
        setValue("bInfo_floor_number", formData?.floor_number);
        setValue("bInfo_side", {
          value: formData?.side?.value,
          label: formData?.side?.label,
          disabled: formData?.side?.isDisabled,
        });
        setValue("bInfo_door", formData?.door);
        setValue("bInfo_country", {
          value: formData?.country?.value,
          label: formData?.country?.label,
        });
        setValue("bInfo_zipCode", {
          value: formData?.zipcode_id?.value,
          label: formData?.zipcode_id?.label,
        });
        setValue("bInfo_city", formData?.city);
      } else {
        setValue("bInfo_company", formData?.company);
        setValue("bInfo_care_of", formData?.care_of);
        setValue("bInfo_cvr_number", formData?.cvr_number);
        setValue("bInfo_vat_number", formData?.vat_number);
        setValue("bInfo_gst_number", formData?.gst_number);
        setValue("bInfo_street", formData?.street);
        setValue("bInfo_house_number", formData?.house_number);
        setValue("bInfo_floor_number", formData?.floor_number);
        setValue("bInfo_side", {
          value: formData?.side?.value,
          label: formData?.side?.label,
          disabled: formData?.side?.isDisabled,
        });
        setValue("bInfo_door", formData?.door);
        setValue("bInfo_country", {
          value: formData?.country?.value,
          label: formData?.country?.label,
        });
        setValue("bInfo_zipCode", {
          value: formData?.zipcode_id?.value,
          label: formData?.zipcode_id?.label,
        });
        setValue("bInfo_city", formData?.city);
      }
    } else {
      setValue("bInfo_company", "");
      setValue("bInfo_care_of", "");
      setValue("bInfo_cvr_number", "");
      setValue("bInfo_vat_number", "");
      setValue("bInfo_gst_number", "");
      setValue("bInfo_street", "");
      setValue("bInfo_house_number", "");
      setValue("bInfo_floor_number", "");
      setValue("bInfo_side", "");
      setValue("bInfo_door", "");
      setValue("bInfo_country", "");
      setValue("bInfo_zipCode", "");
      setValue("bInfo_city", "");
      if (flag === "Property") setValue("attention_person", "");
      if (flag === "Property") setValue("invoice_email", "");
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleCreateNew)}>
      <div className="scrollable-container-y" style={{ height: "450px" }}>
        <div className="p-4 pb-0">
          {" "}
          <Row>
            <Col xl={3} lg={4} md={6} className="pe-0 ">
              <H4 className="mb-3">Billing details</H4>
            </Col>
          </Row>
        </div>

        <Row className="px-4 pb-4">
          <Col xl={3} lg={4} md={6} className="pe-sm-3   ">
            <Controller
              control={control}
              {...register("billing_method")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <ReactSelect
                  placeholder={"Billing method"}
                  iserror={errors?.billing_method}
                  options={billingDetailsDropdownData
                    ?.filter((value) =>
                      GetBillingLevelDetailsData?.data?.[0]?.billing_level
                        ?.split(",")
                        ?.includes(value?.Id)
                    )
                    ?.map((item) => ({
                      value: item?.Id,
                      label: item?.Text,
                      isDisabled:
                        value?.value?.toString() === item?.Id?.toString(),
                    }))}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("billing_method");
                  }}
                  clearable={false}
                />
              )}
            />
          </Col>
        </Row>
        {(flag === "Division" && watch("billing_method")?.value === "D") ||
        (flag === "Company" && watch("billing_method")?.value === "C") ||
        (flag === "Property" && watch("billing_method")?.value === "P") ? (
          <>
            {/* Organisation details */}
            <H4 className=" pb-3 ps-4">Billing information</H4>

            <div className="d-flex align-items-center ps-4 mb-4">
              {" "}
              <Toggle
                id={1}
                checked={isSameDetailAsAboveActive}
                onChange={(e) => {
                  handleformData();
                  setIsSameDetailAsAboveActive(e.target.checked);
                }}
              />{" "}
              <span className="ms-3 light-font-text">
                {" "}
                Same details as in General
              </span>{" "}
            </div>
            <div>
              <div className="px-4">
                <Row>
                  <Col xl={3} md={4} className="pe-sm-3 mb-lg-0 mb-sm-3">
                    <Controller
                      control={control}
                      {...register("bInfo_company")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputField
                            id={"bInfo_company"}
                            value={value}
                            type="text"
                            placeholder="Company"
                            textTransform="capitalize"
                            errorscope
                            disabled={
                              isSameDetailAsAboveActive && flag !== "Property"
                            }
                            iserror={errors?.bInfo_company}
                            removeOpacity={
                              !isSameDetailAsAboveActive && flag !== "Property"
                            }
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("bInfo_company");
                            }}
                          />
                        );
                      }}
                    />
                  </Col>
                  <Col xl={3} md={4} className="pe-3 ps-lg-0  mb-sm-3">
                    <Controller
                      control={control}
                      {...register("bInfo_care_of")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          id={"C/O"}
                          value={value}
                          type="text"
                          textTransform="capitalize"
                          placeholder="C/O"
                          errorscope
                          maxLength={30}
                          disabled={
                            isSameDetailAsAboveActive && flag !== "Property"
                          }
                          removeOpacity={
                            !isSameDetailAsAboveActive && flag !== "Property"
                          }
                          iserror={errors?.bInfo_care_of}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("bInfo_care_of");
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col xl={3} md={4} className=" pe-3 ps-lg-0  mb-sm-3">
                    <Controller
                      control={control}
                      {...register("ean_number")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          id={"ean_number"}
                          value={value}
                          type="number"
                          placeholder="EAN number"
                          removeOpacity={!isSameDetailAsAboveActive}
                          errorscope
                          iserror={errors?.ean_number}
                          onChange={(e) => {
                            const inputValue = e.target.value.slice(0, 14);
                            onChange(inputValue);
                            clearErrors("ean_number");
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
                    <Controller
                      control={control}
                      {...register("bInfo_cvr_number")}
                      rules={{
                        required: !(
                          watch("bInfo_vat_number")?.length ||
                          watch("bInfo_gst_number")?.length
                        ),
                      }}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          id={"bInfo_cvr_number"}
                          value={value}
                          type="text"
                          placeholder="CVR Number"
                          errorscope
                          maxLength={30}
                          removeOpacity={
                            !isSameDetailAsAboveActive && flag !== "Property"
                          }
                          iserror={errors?.bInfo_cvr_number}
                          disabled={
                            isSameDetailAsAboveActive && flag !== "Property"
                          }
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("bInfo_cvr_number");
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col xl={3} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                    <Controller
                      control={control}
                      {...register("bInfo_vat_number")}
                      rules={{
                        required: !(
                          watch("bInfo_cvr_number")?.length ||
                          watch("bInfo_gst_number")?.length
                        ),
                      }}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          id={"bInfo_vat_number"}
                          value={value}
                          type="text"
                          placeholder="VAT Number"
                          errorscope
                          maxLength={30}
                          removeOpacity={
                            !isSameDetailAsAboveActive && flag !== "Property"
                          }
                          iserror={errors?.bInfo_vat_number}
                          disabled={
                            isSameDetailAsAboveActive && flag !== "Property"
                          }
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("bInfo_vat_number");
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col
                    xl={3}
                    lg={4}
                    md={6}
                    className="pe-lg-3 ps-lg-0  mb-sm-3"
                  >
                    <Controller
                      {...register(``)}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Controller
                          control={control}
                          {...register("bInfo_gst_number")}
                          rules={{
                            required: !(
                              watch("bInfo_cvr_number")?.length ||
                              watch("bInfo_vat_number")?.length
                            ),
                          }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"bInfo_gst_number"}
                              value={value}
                              type="text"
                              placeholder="GST Number"
                              errorscope
                              maxLength={30}
                              removeOpacity={
                                !isSameDetailAsAboveActive &&
                                flag !== "Property"
                              }
                              iserror={errors?.bInfo_gst_number}
                              disabled={
                                isSameDetailAsAboveActive && flag !== "Property"
                              }
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("bInfo_gst_number");
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </div>
              <div className="px-4">
                <Row>
                  <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-3 mb-sm-3">
                    <Controller
                      control={control}
                      {...register("bInfo_country")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder="Country"
                          clearable={false}
                          iserror={errors?.bInfo_country}
                          disabled={isSameDetailAsAboveActive}
                          options={GetcountryData?.data?.map((item) => ({
                            value: item?.Id,
                            label: (
                              <div
                                className="d-flex align-items-center"
                                style={{ fontFamily: "Cerebri sans" }}
                              >
                                <div className="radius-4 me-2">
                                  <img
                                    src={item?.DataValue1}
                                    style={{
                                      width: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                </div>
                                <div>
                                  <div className="fs-14 light-font-text ">
                                    {" "}
                                    {item?.Text}
                                  </div>
                                </div>
                              </div>
                            ),
                          }))}
                          value={value == undefined ? "" : displayItem(value)}
                          onChange={(e, ActionType) => {
                            onChange(e);
                            resetField("bInfo_zipCode");
                            resetField("bInfo_city");
                            resetField("bInfo_street");
                            clearErrors("bInfo_country");
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </div>
              <div className="px-4">
                <Row>
                  <Col
                    xl={3}
                    lg={4}
                    md={6}
                    className="pe-sm-3 mb-lg-0 mb-sm-3  position-relative"
                  >
                    <StreetInput
                      isSearchStreetVisible={isSearchStreetVisible}
                      setIsSearchStreetVisible={setIsSearchStreetVisible}
                      handleStreetFieldChange={handleStreetFieldChange}
                      handleStreetDetailClick={handleStreetDetailClick}
                      control={control}
                      disabledValue={isSameDetailAsAboveActive}
                      removeOpacity={
                        !isSameDetailAsAboveActive && flag !== "Property"
                      }
                      street_name={"bInfo_street"}
                      errors={errors}
                      watch={watch}
                      searchedStreetName={searchedStreetName}
                      clearErrors={clearErrors}
                      country_name={"bInfo_country"}
                    />
                  </Col>
                  <Col xl={2} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                    <Controller
                      control={control}
                      {...register("bInfo_house_number")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          id={"bInfo_house_number"}
                          value={value}
                          type="text"
                          placeholder="No."
                          errorscope
                          maxLength={30}
                          removeOpacity={!isSameDetailAsAboveActive}
                          iserror={errors?.bInfo_house_number}
                          disabled={isSameDetailAsAboveActive}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("bInfo_house_number");
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col xl={2} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                    <Controller
                      control={control}
                      {...register("bInfo_floor_number")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder={"Floor"}
                          iserror={errors?.bInfo_floor_number}
                          options={getOrganizationFloorTypeData?.data?.map(
                            (item) => ({
                              value: item?.Id,
                              label: item?.Text,
                            })
                          )}
                          disabled={isSameDetailAsAboveActive}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("bInfo_floor_number");
                          }}
                          clearable={false}
                        />
                      )}
                    />
                  </Col>
                  <Col
                    xl={2}
                    lg={4}
                    md={6}
                    className="pe-sm-3 ps-xl-0 mb-lg-0 mb-sm-3 "
                  >
                    <Controller
                      control={control}
                      {...register("bInfo_side")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder={"Side"}
                          iserror={errors?.bInfo_side}
                          disabled={isSameDetailAsAboveActive}
                          options={getOrganizationSideTypeData?.data?.map(
                            (item) => ({
                              value: item?.Id,
                              label: item?.Text,
                            })
                          )}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("bInfo_side");
                          }}
                          clearable={false}
                        />
                      )}
                    />
                  </Col>
                  <Col xl={2} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3 ">
                    <Controller
                      control={control}
                      {...register("bInfo_door")}
                      // rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          id={"bInfo_door"}
                          value={value}
                          type="text"
                          placeholder="Door"
                          errorscope
                          maxLength={30}
                          iserror={errors?.bInfo_door}
                          disabled={isSameDetailAsAboveActive}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("bInfo_door");
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </div>
              <div className="px-4 ">
                <Row>
                  <Col xl={2} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
                    <Controller
                      control={control}
                      {...register("bInfo_zipCode")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder="Zip code"
                          iserror={errors?.bInfo_zipCode}
                          disabled={isSameDetailAsAboveActive}
                          options={GetzipCodeByCountryIdData?.data?.map(
                            (zip) => ({
                              value: zip?.Id,
                              label: `${zip?.Id} ${zip?.Text}`,
                            })
                          )}
                          value={
                            value?.value
                              ? {
                                  value: value?.value,
                                  label: value?.value,
                                }
                              : ""
                          }
                          onChange={(e, ActionType) => {
                            handleZipCode(e, ActionType);
                            onChange(e);
                            clearErrors("bInfo_zipCode");
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col
                    xl={3}
                    lg={4}
                    md={6}
                    className="pe-lg-3 ps-lg-0  mb-sm-3 "
                  >
                    <Controller
                      control={control}
                      {...register("bInfo_city")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          id={"bInfo_city"}
                          value={value}
                          type="text"
                          placeholder="City"
                          errorscope
                          maxLength={30}
                          iserror={errors?.bInfo_city}
                          disabled
                          removeOpacity={!isSameDetailAsAboveActive}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("bInfo_city");
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>{" "}
              </div>

              <div className="px-4 pb-4">
                <Row>
                  <Col
                    xl={4}
                    lg={4}
                    md={6}
                    className="pe-sm-3 mb-lg-0 mb-sm-3 pe-lg-3"
                  >
                    <Controller
                      control={control}
                      {...register("invoice_email")}
                      rules={{
                        required: "Email is required",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Please use a valid email address",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          id={"invoice_email"}
                          value={value}
                          type="email"
                          placeholder="Invoice e-mail"
                          textTransform="lowercase"
                          errormessage={
                            errors?.email?.type === "pattern"
                              ? errors?.email.message
                              : false
                          }
                          errorscope
                          maxLength={30}
                          iserror={errors?.invoice_email}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("invoice_email");
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col
                    xl={4}
                    lg={4}
                    md={6}
                    className=" mb-lg-0 mb-sm-3 ps-lg-0"
                  >
                    <Controller
                      control={control}
                      {...register("attention_person")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          id={"attention_person"}
                          value={value}
                          type="text"
                          placeholder="Attention person"
                          textTransform="capitalize"
                          errorscope
                          maxLength={30}
                          removeOpacity={!isSameDetailAsAboveActive}
                          // disabled={isSameDetailAsAboveActive}
                          iserror={errors?.attention_person}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("attention_person");
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </>
        ) : (
          <div className="ps-4 light-font-text">
            {watch("billing_method")?.value ? (
              <>
                As the billing method is chosen to be at{" "}
                {watch("billing_method")?.value === "D"
                  ? "Division"
                  : watch("billing_method")?.value === "C"
                  ? "Company"
                  : watch("billing_method")?.value === "P"
                  ? "Property"
                  : "Organisation"}{" "}
                level, billing details must be filled in at{" "}
                {watch("billing_method")?.value === "D"
                  ? "Division"
                  : watch("billing_method")?.value === "C"
                  ? "Company"
                  : watch("billing_method")?.value === "P"
                  ? "Property"
                  : "Organisation"}{" "}
                level
              </>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
      <div>
        {" "}
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="BACK"
            onClick={handlePreviousBillingInfo}
            // type={"submit"}
          />
          <ETButton
            variant={"primary"}
            buttonText={
              (flag == "Division" && `${flagEdit ? "NEXT" : "NEXT"}`) ||
              (flag == "Company" &&
                `${flagEdit ? " EDIT COMPANY" : "CREATE COMPANY"}`) ||
              (flag == "Property" &&
                `${flagEdit ? " EDIT PROPERTY" : "CREATE PROPERTY"}`)
            }
            type={"submit"}
            disabled={createButtonDisable}
          />
        </div>
      </div>
    </Form>
  );
};

export default CreateBillingDetails;
