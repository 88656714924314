import React, { useState, useRef } from "react";
import { Col, Row, Form } from "react-bootstrap";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import Switch from "../../../../Components/Switch/Switch";
import Menus from "../../../../Components/Menus/Menus";
import ETButton from "../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../Components/CustomPopup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PNGWidget from "../../../../Components/DownloadWidget/PNGWidget";
import JPGWidget from "../../../../Components/DownloadWidget/JPGWidget";
import SVGWidget from "../../../../Components/DownloadWidget/SVGWidget";
import FunnelContainer from "./funnelContainer";
import { AddStepBorder } from "../../../../styles/Common.style";
import NoDataScreen from "../../../../Components/DisplayNoData/NoDataScreen";
import { H2, H3 } from "../../../../styles/Common.style";
import InputField from "../../../../Components/Textfield/InputField";

const Funnel = () => {
  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "unit-status";

  const PNGFunction = PNGWidget({ refObj, fileName });
  const JPGFunction = JPGWidget({ refObj, fileName });
  const SVGFunction = SVGWidget({ refObj, fileName });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      addStep: {
        funnelName: "",
        pageName: "",
        aliasOptional: "",
      },
    },
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "addStep",
    }
  );

  const handleAddStepButton = () => {
    console.log("object");
    append({
      funnelName: "",
      pageName: "",
      aliasOptional: "",
    });
  };

  const onHandleAddFunnel = (data) => {
    console.log("object", data);
  };

  // Open ADD FUNNEL

  const [openFunnel, setOpenFunnel] = useState(false);

  return (
    <div ref={myref} className="card-body p-3">
      <Row className="pb-5">
        <Col xl={7} lg={12}>
          <div className="d-flex align-items-center">
            <H3 className="  me-2">Funnels</H3>{" "}
            <CustomToolTip size="sm" text="Funnels" />
          </div>
        </Col>
        <Col xl={5}>
          <div className="d-flex justify-content-end align-items-center gap-2">
            {/* <div className="d-flex align-items-center gap-2">
              <div className="font-light fs-14 me-2">Ei.DO</div>
              <Switch /> <div className="font-light fs-14 me-4">Website</div>
            </div> */}
            <div className="w-50 d-flex justify-content-end gap-3">
              <div style={{ width: "100%" }}>
                <ETButton
                  variant={"primary"}
                  icon={true}
                  buttonText="CREATE FUNNEL"
                  width={"100%"}
                  onClick={() => setOpenFunnel(true)}
                />
              </div>
              <div>
                <Menus
                  itemheight={40}
                  itemwidth={40}
                  itemcontainerheight={200}
                  itemcontainerwidth={200}
                  component={
                    <div className="fs-14">
                      <div className="addpropContent px-3 py-2 ">
                        <span className="ms-1" onClick={() => PNGFunction()}>
                          Download widget as PNG
                        </span>
                      </div>
                      <hr className="addpropertyHr m-0"></hr>
                      <div
                        onClick={() => JPGFunction()}
                        className="addpropContent px-3 pt-2 pb-2 "
                      >
                        <span className="ms-1">Download widget as JPG</span>
                      </div>
                      <hr className="addpropertyHr m-0"></hr>
                      <div
                        onClick={() => SVGFunction()}
                        className="addpropContent px-3 pt-2 pb-2 "
                      >
                        <span className="ms-1">Download widget as SVG</span>
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="">
          <div>
            {/* <FunnelContainer /> */}
            <NoDataScreen height={"377px"} imgHeight={"150px"} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <CustomPopup
            open={openFunnel}
            onHide={() => setOpenFunnel(false)}
            title={"Create funnel "}
            title_color="black"
            titleFont="14px"
            titleTooltip={true}
            enableCrossButton={true}
            titleTooltipText="Create an event"
            size="lg"
            onCloseAction={() => {
              setOpenFunnel(false);
              alert("Action Peformed Successfuly.");
            }}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={true}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
          >
            <Form onSubmit={handleSubmit(onHandleAddFunnel)}>
              <div className="px-4 py-4">
                <Row className="pb-4">
                  <Col lg={9}>
                    <Controller
                      control={control}
                      {...register("funnelName")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div >
                          {/* <input
                            type="text"
                            placeholder="title"
                            id="funnelName"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("funnelName");
                            }}
                          /> */}
                          <InputField id={"funnelName"} value={value} type={"text"}  placeholder={"Name of funnel"} errorscope iserror={errors?.funnelName} onChange={(e) => {

                            onChange(e);
                            clearErrors("funnelName");
                          }} />
                          {/* <label htmlFor="fName">Name of funnel</label> */}
                        </div>
                      )}
                    />
                    {/* {errors?.funnelName && (
                      <p
                        className="error-msg"
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )} */}
                  </Col>
                  <Col></Col>
                </Row>
                {fields?.map((item, index) => (
                  <Row className="d-flex align-items-center pb-4" key={index}>
                    <Col lg={9}>
                      <div
                        className="d-flex align-items-center gap-3 px-3 radius-8"
                        style={{
                          backgroundColor: "#f4f5f5",
                          border: "1px solid #f4f5f5",
                          height: "80px",
                        }}
                      >
                        <div className="table-property-name fs-12">#1</div>
                        <div style={{ width: "60%" }}>
                          <Controller
                            // name={`addStep.${index}.pageName`}
                            // {...register("pageName")}
                            {...register(`addStep.${index}.pageName`)}
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <ReactSelect
                                value={value}
                                placeholder=" Name of Page"
                                clearable={false}
                                isSearchable
                                //   onChange={(e) => {
                                //     onChange(e);
                                //     handleZip(e);
                                //   }}
                                error={errors.zip}
                              //   options={zipcodeData?.data?.map((zip) => ({
                              //     value: zip?.Id,
                              //     label: `${zip?.Id} ${zip?.Text}`,
                              //   }))}
                              />
                            )}
                          />
                          {errors?.pageName && (
                            <p
                              className="error-msg m-0"
                              style={{
                                color: "red",
                              }}
                            >
                              {REQUIRED_ERR}
                            </p>
                          )}
                        </div>
                        <div style={{ width: "60%" }}>
                          <Controller
                            control={control}
                            // {...register("aliasOptional")}
                            {...register(`addStep.${index}.aliasOptional`)}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <div className="form-input ">
                                <input
                                  type="text"
                                  placeholder="title"
                                  id="aliasOptional"
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    clearErrors("aliasOptional");
                                  }}
                                />
                                <label htmlFor="fName">alias (optional)</label>
                              </div>
                            )}
                          />
                        </div>
                        <div>
                          <FontAwesomeIcon
                            icon="fa-regular fa-arrows-up-down-left-right"
                            size="lg"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div
                        className="light-font-text ps-3"
                        style={{ fontstyle: "italic", color: "#888888" }}
                        onClick={() => remove(index)}
                      >
                        <i className="cursor-pointer light-font-text">Remove</i>
                      </div>
                    </Col>
                  </Row>
                ))}

                <Row>
                  <Col lg={9}>
                    <AddStepBorder
                      className="d-flex justify-content-center align-items-center form-label-title cursor-pointer"
                      // style={{
                      //   border: "1px dashed #619130",
                      //   borderRadius: "4px",
                      //   minHeight: "44px",
                      //   color: "#619130",
                      // }}
                      onClick={handleAddStepButton}
                    >
                      {" "}
                      <FontAwesomeIcon icon="fa-solid fa-plus" /> &nbsp; ADD
                      STEP
                    </AddStepBorder>
                  </Col>
                </Row>
              </div>
              <div
                className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
                style={{
                  backgroundColor: "#F9FAFA",
                  borderRadius: " 0px 0px 8px 8px",
                  border: "1px solid #F0F0F0",
                }}
              >
                <ETButton
                  variant={"white"}
                  buttonText="CANCEL"
                  onClick={() => setOpenFunnel(false)}
                />
                <ETButton
                  variant={"primary"}
                  buttonText="SAVE"
                  type="submit"
                // onClick={() => setDeletePdfPopUp(false)}
                />
              </div>
            </Form>
          </CustomPopup>
        </Col>
      </Row>
    </div>
  );
};

export default Funnel;
