import React from "react";
import { Col, Row, Container, Form, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import IconTextField from "../../../Components/GroupText/IconTextField";
import ETButton from "../../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../GeneralLogin.css";
import { Controller, useForm } from "react-hook-form";

const ForgotPassword = ({
  data,
  handleSendInstruction = () => {},
  userEmail,
  errorMessage,
  setErrorMessage,
  disableButtonOnApiLoad = false,
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    clearErrors,
    resetField,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      forgetPasswordEmail: userEmail,
    },
  });
  const handleEmailSubmit = (data) => {
    handleSendInstruction(data);
  };
  return (
    <>
      {data?.map((data, id) => (
        <Container>
          <Form onSubmit={handleSubmit(handleEmailSubmit)}>
            <Row className="d-flex justify-content-center align-items-center">
              <Col
                md={7}
                xxl={4}
                xl={5}
                lg={6}
                sm={12}
                className="px-xl-2 px-sm-0"
              >
                <div
                  className="StandardForm row"
                  style={{
                    backgroundColor: data?.ContainerBackground,
                  }}
                >
                  <div className="px-3" style={{ marginTop: "40px" }}>
                    <Row className="m-auto">
                      <div
                        className="d-flex justify-content-center align-items-center mb-5 p-1"
                        md={{ span: 6, offset: 3 }}
                        xs={{ span: 4, offset: 4 }}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <Image
                            width={"160px"}
                            height={"auto"}
                            src={data?.logo}
                            alt="logo"
                          />
                        </div>
                      </div>
                    </Row>

                    <div className="d-flex justify-content-center flex-column align-items-center mb-4 px-4 mx-1">
                      <div
                        className="light-font-text fs-16"
                        style={{
                          color: data?.Biocolor,
                        }}
                      >
                        Forgot your password?
                      </div>
                      <div
                        className="light-font-text px-4 fs-16"
                        style={{
                          color: data?.Biocolor,
                          textAlign: "center",
                        }}
                      >
                        No worries! Just enter your email below and we'll send
                        you instructions to reset your password.
                      </div>
                    </div>

                    <div className="d-flex row  justify-content-center align-items-center ">
                      <Row>
                        <Col
                          className={errorMessage ? "pb-4" : "pb-40"}
                          xs={{ span: 8, offset: 2 }}
                        >
                          <div>
                            <Controller
                              control={control}
                              {...register("forgetPasswordEmail")}
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <IconTextField
                                  id={"username"}
                                  Icons={"fa-regular fa-envelope"}
                                  value={value?.toLowerCase()}
                                  placeholder={"Email"}
                                  type="email"
                                  isError={errors?.forgetPasswordEmail}
                                  onChange={(e) => {
                                    onChange(e);
                                    clearErrors("forgetPasswordEmail");
                                    setErrorMessage("");
                                  }}
                                />
                              )}
                            />
                          </div>
                        </Col>
                      </Row>
                      {errorMessage && (
                        <Row>
                          <Col
                            md={{ span: 9, offset: 1 }}
                            className="ps-sm-4 pe-0 d-flex align-items-center justify-content-center mb-4"
                          >
                            <div className="error-msg font-medium fs-16 text-align-center">
                              {errorMessage}
                            </div>
                          </Col>
                        </Row>
                      )}
                      <Row style={{ paddingBottom: "113px" }}>
                        <Col xs={{ span: 8, offset: 2 }}>
                          <div className="d-flex justify-content-center">
                            <ETButton
                              // onClick={handleSubmit}
                              disabled={disableButtonOnApiLoad}
                              variant={"primary"}
                              buttonText="SEND ME INSTRUCTIONS"
                              type="submit"
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div
                      className="d-flex justify-content-start light-font-text"
                      style={{}}
                    >
                      <div>
                        <NavLink
                          to="/"
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          <span className="d-flex light-font-text align-items-center gap-1 mb-3">
                            <FontAwesomeIcon
                              icon="fa-regular fa-arrow-left-long"
                              style={{
                                color: "#f1664b",
                                paddingTop: "2px",
                              }}
                            />{" "}
                            <span
                              style={{
                                color: data?.FormLinkandTextboxCheckColor,
                              }}
                            >
                              {" "}
                              Go back to login
                            </span>
                          </span>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      ))}
    </>
  );
};

export default ForgotPassword;
