import React from "react";
import { Navigate } from "react-router-dom";
import { GetSessionItem } from "../utils/SessionStorage";

const ProtectedRoute = ({ children }) => {
  if (!GetSessionItem("et-jwt")) {
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectedRoute;
