// the file name have .jsx extension because it contains jsx element 

export const displayLanguageFlag = (language, languageArr) => {
    const selectedLanguage = languageArr?.data?.filter((lang) => lang?.Id === language);
    if(!selectedLanguage?.length || !languageArr?.data?.length) return "";

    const renderFlag = {
      value: selectedLanguage[0]?.Id,
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={selectedLanguage[0]?.DataValue1}
            alt={`${selectedLanguage[0]?.Text} flag`}
            style={{
              marginRight: "8px",
              width: "18px",
              height: "12px",
            }}
          />
          <span className="normal-font-text fs-14">
            {selectedLanguage[0]?.Text}
          </span>
        </div>
      ),
    }
    return Object.keys(renderFlag ?? {}).length ? renderFlag : ""
  }