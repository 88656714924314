import { useMutation, useQuery, useQueryClient } from "react-query";
import { DeleteEidoColor, GetEiDoMediaPackage, GetEiDoTextPackage, GetEidoColor, PostEidoColor, UpdateEidoColor } from "../../services/Eido";


// Update property Eido color
export const usePostEidoColor = () => {
    return useMutation(PostEidoColor);
};

// Update property Eido color
export const useUpdateEidoColor = () => {
  return useMutation(UpdateEidoColor);
};

// Delete property Eido color
export const useDeleteEidoColor = () => {
  return useMutation(DeleteEidoColor);
};


//Get Color details
export const useGetEidoColor = (propertyID) => {
  return useQuery(
    ["qusePostEidoColor", propertyID],
    () => GetEidoColor(propertyID),
    {
      enabled: !!propertyID,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

//Get text package details
export const useGetEiDoTextPackage = (propertyID, categoryID) => {
  return useQuery(
    ["useEiDoTextPackage", propertyID, categoryID],
    () => GetEiDoTextPackage(propertyID, categoryID),
    {
      enabled: !!propertyID,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

//Get media package details
export const useGetEiDoMediaPackage = (propertyID) => {
  return useQuery(
    ["useEiDoMediaPackage", propertyID],
    () => GetEiDoMediaPackage(propertyID),
    {
      enabled: !!propertyID,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};