import React from "react";
import CustomPopup from "../../../../../../../../../Components/CustomPopup";
import ETButton from "../../../../../../../../../Components/Buttons/ETButton";

const StartScreening = ({ startScreeningShow, setStartScreeningShow }) => {
  return (
    <div>
      {" "}
      <CustomPopup
        open={startScreeningShow}
        onHide={() => setStartScreeningShow(false)}
        title="Start screening"
        title_color="black"
        size={"md"}
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="p-4 light-font-text">
          If you want to start an automatic screening on the customer, click the
          Start button.
        </div>
        <div className="px-4 light-font-text">
          The customer will receive an email with an invitation to create a My
          Page profile and can then start the screening. At the same time, a new
          contract is created on the unit where the screening can be followed.
        </div>
        <div className="py-2 mt-4 px-3 d-flex flex-row justify-content-between  footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setStartScreeningShow(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="START"
            type={"submit"}
            onClick={() => setStartScreeningShow(false)}
            //   onClick={handleDelete}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default StartScreening;
