import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import { Col, Form, Row } from "react-bootstrap";
import Toggle from "../../Components/Switch/Switch";
import ETButton from "../../Components/Buttons/ETButton";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  usePropertyId,
  usePropertyOverview,
} from "../../hooks/stores/usePropertyStore";
import { H1, H2 } from "../../styles/Common.style";
import { useGetSignerBySignerId } from "../../hooks/queries/useSigningQueries";
import { useRouting } from "../../hooks/stores/useRoutingStore";
import moment from "moment";
import PasswordField from "../../Components/GroupText/PasswordField";
import { GreenSpan } from "../../styles/Common.style";
import { GenerateUpdateInfo } from "../../utils/TimeZone";
import CustomPopup from "../../Components/CustomPopup";
import { Link } from "react-router-dom";
import {
  usePostAddSigner,
  usePutUpdateSigner,
} from "../../hooks/queries/useSignerQueries";
import InputField from "../../Components/Textfield/InputField";

const AddNewSigner = () => {
  //-----------------Zuustand--------------------//
  const propertyOverview = usePropertyOverview((state) => state.propOverview);

  const {
    packageType,
    setPackageType,
    showPackage,
    setShowPackage,
    editflag,
    setEditflag,
    packageId,
    setPackageId,
  } = useRouting((state) => state);
  //-----------------states------------------------------//
  const [isQaApproverActive, setIsQaApproverActive] = useState(true);
  const [isCopyRecipient, setIsCopyRecipient] = useState(true);
  const [isLandlordSellerSignerActive, setIsLandlordSellerSignerActive] =
    useState(true);
  const [isNotificationRecipientActive, setIsNotificationRecipientActive] =
    useState(true);
  const [istwoFactorActive, setIsTwoFactorActive] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const [showEmailPopUp, setShowEmailPopUp] = useState({
    showPopup: false,
    message: "",
  });
  // const [showEmailPopUp, setShowEmailPopUp] = useState(false);
  const [showEmailData, setShowEmailData] = useState({
    user_id: "0",
    is_exist: "0",
  });
  const [isUpdate, setIsUpdate] = useState(false);
  const [formData, setFormData] = useState("");
  const propertyId = usePropertyId((state) => state.propertyId);
  const [cancelSigner, setCancelSigner] = useState(false);

  //-----------------React query------------------------------//
  const { data: getSignerBySignerIdData } = useGetSignerBySignerId(
    propertyOverview?.[0]?.property_id,
    packageId,
    editflag
  );
  const { mutateAsync: PostAddSigner, isLoading: PostAddSignerIsLoading } =
    usePostAddSigner();
  const { mutateAsync: PutUpdateSigner, isLoading: PutUpdateSignerIsLoading } =
    usePutUpdateSigner();

  //------------------validation-------------------------//
  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      property_id: propertyOverview?.[0]?.property_id?.toString(),
    },
  });
  const [editsignerdata, setEditSignerData] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [isloadingPopup, setIsloadingPopup] = useState(false);
  //------------------Handle save button function-------------------------//
  useEffect(() => {
    if (editflag) {
      setValue("title", getSignerBySignerIdData?.data?.[0]?.title);
      setEditSignerData(getSignerBySignerIdData?.data[0]);
      setValue("firstname", getSignerBySignerIdData?.data?.[0]?.first_name);
      setValue("lastname", getSignerBySignerIdData?.data?.[0]?.last_name);
      setValue("email", getSignerBySignerIdData?.data?.[0]?.email);
      setValue("phone", getSignerBySignerIdData?.data?.[0]?.contact);
      setValue(
        "socialSecurityNumber",
        getSignerBySignerIdData?.data?.[0]?.ssn_cvr
      );
      setIsQaApproverActive(
        getSignerBySignerIdData?.data?.[0]?.is_qa_approver === 1 ? true : false
      );
      setIsLandlordSellerSignerActive(
        getSignerBySignerIdData?.data?.[0]?.is_landlord_seller_signer === 1
          ? true
          : false
      );
      setIsCopyRecipient(
        getSignerBySignerIdData?.data?.[0]?.is_copy_recipient === 1
          ? true
          : false
      );
      setIsNotificationRecipientActive(
        getSignerBySignerIdData?.data?.[0]?.is_notification_recipient === 1
          ? true
          : false
      );
      setIsTwoFactorActive(
        getSignerBySignerIdData?.data?.[0]?.use_two_factor_signing === 1
          ? true
          : false
      );
    }
  }, [getSignerBySignerIdData]);

  //------------------Handle save button function-------------------------//
  // const handleSave = (data) => {
  //   if (editflag) {
  //     const editSignerPayload = {
  //       u_id: getSignerBySignerIdData?.data?.[0]?.user_id?.toString(),
  //       first_name: data?.firstname,
  //       last_name: data?.lastname,
  //       title: data?.title,
  //       email: data?.email,
  //       ssn_cvr: data?.socialSecurityNumber,
  //       contact: data?.phone,
  //       is_qa_approver: isQaApproverActive ? "1" : "0",
  //       is_copy_recipient: isCopyRecipient ? "1" : "0",
  //       is_landlord_seller_signer: isLandlordSellerSignerActive ? "1" : "0",
  //       is_notification_recipient: isNotificationRecipientActive ? "1" : "0",
  //       use_two_factor_signing: istwoFactorActive ? "1" : "0",
  //     };
  //     PutUpdateSigner(editSignerPayload)
  //       .then((response) => {
  //         if (response.data.bReturn) {
  //           toast.success(response.data.message);
  //           // setAddShowSigners(false);
  //           setShowPackage(false);
  //         } else {
  //           toast.warn(response.data.message);
  //         }
  //       })
  //       .catch((error) => {
  //         toast.error(error.message);
  //       });
  //   } else {
  //     const addSignerPayload = {
  //       property_id: propertyOverview?.[0]?.property_id?.toString(),
  //       user_id:showEmailData?.user_id,
  //       first_name: data?.firstname,
  //       last_name: data?.lastname,
  //       title: data?.title,
  //       email: data?.email,
  //       ssn_cvr: data?.socialSecurityNumber,
  //       contact: data?.phone,
  //       is_qa_approver: isQaApproverActive ? "1" : "0",
  //       is_copy_recipient: isCopyRecipient ? "1" : "0",
  //       is_landlord_seller_signer: isLandlordSellerSignerActive ? "1" : "0",
  //       is_notification_recipient: isNotificationRecipientActive ? "1" : "0",
  //       use_two_factor_signing: istwoFactorActive ? "1" : "0",
  //       is_exist:showEmailData?.is_exist,
  //       is_update:isUpdate,
  //     };
  //     console.log(getSignerBySignerIdData, "addSignerPayload");
  //     PostAddSigner(addSignerPayload)
  //       .then((response) => {
  //         if (response.data.bReturn) {
  //           toast.success(response.data.message);
  //           // setAddShowSigners(false);
  //           setShowPackage(false);
  //         } else {
  //           // setShowEmailPopUp(true);
  //           // setShowEmailPopUp(response.data.message);
  //           setShowEmailPopUp((prev)=>( {...prev, showPopup : true, message : response.data.message}));
  //           setShowEmailData((prev)=>( {...prev, is_exist : response.data.data.is_exist, user_id:response.data.data.user_id }));
  //           //toast.warn(response.data.message);
  //         }
  //       })
  //       .catch((error) => {
  //         toast.error(error.message);
  //       });
  //   }
  // };
  const handleSave = (data) => {
    // setIsLoading(true);
    setFormData(data);
    if (editflag) {
      const editSignerPayload = {
        u_id: getSignerBySignerIdData?.data?.[0]?.user_id?.toString(),
        first_name: data?.firstname,
        last_name: data?.lastname,
        title: data?.title,
        email: data?.email,
        ssn_cvr: data?.socialSecurityNumber,
        contact: data?.phone,
        is_qa_approver: isQaApproverActive ? "1" : "0",
        is_copy_recipient: isCopyRecipient ? "1" : "0",
        is_landlord_seller_signer: isLandlordSellerSignerActive ? "1" : "0",
        is_notification_recipient: isNotificationRecipientActive ? "1" : "0",
        use_two_factor_signing: istwoFactorActive ? "1" : "0",
      };
      PutUpdateSigner(editSignerPayload)
        .then((response) => {
          if (response.data.bReturn) {
            toast.success(response.data.message);
            // setAddShowSigners(false);
            setShowPackage(false);
            // setIsLoading(false);
          } else {
            toast.warn(response.data.message);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      handleAddSigner(data);
      //   const addSignerPayload = {
      //     property_id: propertyOverview?.[0]?.property_id?.toString(),
      //     user_id:showEmailData?.user_id,
      //     first_name: data?.firstname,
      //     last_name: data?.lastname,
      //     title: data?.title,
      //     email: data?.email,
      //     ssn_cvr: data?.socialSecurityNumber,
      //     contact: data?.phone,
      //     is_qa_approver: isQaApproverActive ? "1" : "0",
      //     is_copy_recipient: isCopyRecipient ? "1" : "0",
      //     is_landlord_seller_signer: isLandlordSellerSignerActive ? "1" : "0",
      //     is_notification_recipient: isNotificationRecipientActive ? "1" : "0",
      //     use_two_factor_signing: istwoFactorActive ? "1" : "0",
      //     is_exist:showEmailData?.is_exist,
      //     is_update:ispdateValue,
      //   };
      //   console.log(getSignerBySignerIdData, "addSignerPayload");
      //   PostAddSigner(addSignerPayload)
      //     .then((response) => {
      //       if (response.data.bReturn) {
      //         toast.success(response.data.message);
      //         setIsUpdate(false)
      //         // setAddShowSigners(false);
      //         setShowPackage(false);
      //       } else {
      //         // setShowEmailPopUp(true);
      //         // setShowEmailPopUp(response.data.message);
      //         setShowEmailPopUp((prev)=>( {...prev, showPopup : true, message : response.data.message}));
      //         setShowEmailData((prev)=>( {...prev, is_exist : response.data.data.is_exist, user_id:response.data.data.user_id }));
      //         //toast.warn(response.data.message);
      //       }
      //     })
      //     .catch((error) => {
      //       toast.error(error.message);
      //     });
    }
  };

  const handleAddSigner = (data, isUpdateValue = "0") => {
    // setIsloadingPopup(false);
    const addSignerPayload = {
      property_id: propertyOverview?.[0]?.property_id?.toString(),
      user_id: showEmailData?.user_id,
      first_name: data?.firstname,
      last_name: data?.lastname,
      title: data?.title,
      email: data?.email,
      ssn_cvr: data?.socialSecurityNumber,
      contact: data?.phone,
      is_qa_approver: isQaApproverActive ? "1" : "0",
      is_copy_recipient: isCopyRecipient ? "1" : "0",
      is_landlord_seller_signer: isLandlordSellerSignerActive ? "1" : "0",
      is_notification_recipient: isNotificationRecipientActive ? "1" : "0",
      use_two_factor_signing: istwoFactorActive ? "1" : "0",
      is_exist: showEmailData?.is_exist,
      is_update: isUpdateValue,
    };
    PostAddSigner(addSignerPayload)
      .then((response) => {
        if (response.data.bReturn) {
          toast.success(response.data.message);
          setIsUpdate(false);
          // setIsloadingPopup(false);
          // setAddShowSigners(false);
          setShowPackage(false);
        } else {
          // setShowEmailPopUp(true);
          // setShowEmailPopUp(response.data.message);
          if (response.data.message !== "This user is already signer") {
            setShowEmailPopUp((prev) => ({
              ...prev,
              showPopup: true,
              message: response.data.message,
            }));
          } else {
            toast.info(response.data.message);
            setShowEmailPopUp((prev) => ({ ...prev, showPopup: false }));
            setShowPackage(false);
          }
          setShowEmailData((prev) => ({
            ...prev,
            is_exist: response.data.data.is_exist,
            user_id: response.data.data.user_id,
          }));
          //toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(handleSave)}>
        <div className="pt-3 ">
          <H1>{editflag ? "Edit signer" : "Add new signer"}</H1>
          <div
            className="light-font-text"
            style={{
              cursor: "pointer",
            }}
          >
            {" "}
            <Link
              to={"/properties"}
              style={{ textDecoration: "none", color: "black" }}
            >
              <span className="me-3">Property</span>
            </Link>
            <span className="me-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />{" "}
            </span>
            <Link
              to="/property-units/overview"
              state={{ Tab: "Overview", propId: propertyId }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <span className="me-3">
                {propertyOverview?.[0]?.propertyName}
              </span>
            </Link>
            <span className="me-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />{" "}
            </span>
            <span
              className="me-3 cursor-pointer"
              onClick={() => setShowPackage(false)}
            >
              Signer
            </span>
            <span className="me-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />{" "}
            </span>
            <GreenSpan className="me-3 ">
              {editflag ? "Edit signer" : "Add new signer"}
            </GreenSpan>
          </div>
        </div>
        <div className="containerStyle  mt-4 p-4">
          <div className="add-new-signer-header p-4">
            <div className=" d-flex justify-content-start">
              <div className=" d-flex align-items-center ">
                <H2 className="me-2 ">
                  {editflag ? <div>Edit signer</div> : "Add new signer"}
                </H2>{" "}
                <CustomToolTip size="sm" text="Need Help For Signer" />
              </div>
            </div>
            <div>
              {editsignerdata && (
                <div className="fs-12 created-by">
                  {editsignerdata.modified_by || editsignerdata.created_by ? (
                    <GenerateUpdateInfo data={editsignerdata} flag={true} />
                  ) : (
                    ""
                  )}
                </div>
              )}

              {/* {modifiedData?.map((item) => (
                <div className="created-by">
                  {item.modified_by && item.modified_on ? (
                    <div>
                      Laste updated by {item?.modified_by},{item?.modifiedOn}
                    </div>
                  ) : (
                    <>
                      {item?.created_by && item?.created_on ? (
                        <div>
                          Created by {item?.created_by},{item?.createdOn}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              ))} */}
            </div>
          </div>
          <div className="add-new-signer-body p-4">
            <div>
              <Row>
                <Col lg={8}>
                  <Row className="mb-3">
                    <Col lg={6}>
                      <Controller
                        control={control}
                        {...register("title")}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <div>
                            {/* <input
                              type="text"
                              placeholder="Title"
                              id="title"
                              name="title"
                              {...field}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors("title");
                              }}
                            /> */}
                            <InputField
                              id={"title"}
                              value={field.value}
                              type={"text"}
                              maxLength
                              placeholder={"Title"}
                              errorscope
                              iserror={errors.title}
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors("title");
                              }}
                            />
                            {/* <label htmlFor="title">Title</label> */}
                          </div>
                        )}
                      />
                      {/* {errors.title && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col lg={6} className="mb-sm-3 mb-lg-0">
                      <Controller
                        control={control}
                        {...register("firstname")}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <div>
                            {/* <input
                              type="text"
                              placeholder="firstname"
                              id="firstname"
                              name="firstname"
                              value={field.value}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors("firstname");
                              }}
                            /> */}
                            <InputField
                              id={"firstname"}
                              value={field.value}
                              type={"text"}
                              maxLength
                              placeholder={"firstname"}
                              errorscope
                              iserror={errors.firstname}
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors("firstname");
                              }}
                            />

                            {/* <label htmlFor="firstname">First name</label> */}
                          </div>
                        )}
                      />
                      {/* {errors.firstname && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                    </Col>
                    <Col lg={6}>
                      <Controller
                        control={control}
                        {...register("lastname")}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <div>
                            {/* <input
                              type="text"
                              placeholder="lastname"
                              id="lastname"
                              name="lastname"
                              value={field.value}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors("lastname");
                              }}
                            /> */}
                            <InputField
                              id={"lastname"}
                              value={field.value}
                              type={"text"}
                              maxLength
                              placeholder={"lastname"}
                              errorscope
                              iserror={errors.lastname}
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors("lastname");
                              }}
                            />
                            {/* <label htmlFor="lastname">Last name</label> */}
                          </div>
                        )}
                      />
                      {/* {errors.lastname && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col lg={6}>
                      <Controller
                        control={control}
                        {...register("email")}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <div>
                            {/* <input
                              type="text"
                              placeholder="email"
                              id="email"
                              name="email"
                              value={field.value}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors("email");
                              }}
                            /> */}
                            <InputField
                              id={"email"}
                              value={field.value}
                              type={"text"}
                              maxLength
                              placeholder={"email"}
                              errorscope
                              iserror={errors.email}
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors("email");
                              }}
                            />
                            {/* <label htmlFor="email">Email</label> */}
                          </div>
                        )}
                      />
                      {/* {errors.email && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                    </Col>
                    <Col lg={6}>
                      <Controller
                        control={control}
                        {...register("phone")}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <div>
                            {/* <input
                              type="number"
                              placeholder="Phone"
                              id="phone"
                              name="phone"
                              value={field.value}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors("phone");
                              }}
                            /> */}
                            <InputField
                              id={"phone"}
                              value={field.value}
                              type={"text"}
                              maxLength
                              placeholder={"Phone"}
                              errorscope
                              iserror={errors.phone}
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors("phone");
                              }}
                            />
                            {/* <label htmlFor="phone">Phone</label> */}
                          </div>
                        )}
                      />
                      {/* {errors.phone && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col lg={6}>
                      <Controller
                        control={control}
                        {...register("socialSecurityNumber")}
                        rules={{
                          required: false,
                        }}
                        render={({ field }) => (
                          <PasswordField
                            id={"SSN"}
                            placeholder={"SSN/CVR"}
                            value={field.value}
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors("socialSecurityNumber");
                            }}
                            maxlength={25}
                          />
                        )}
                      />
                      {errors.socialSecurityNumber && (
                        <p className="error-msg">This field is required</p>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={6}>
                      <Row className="d-flex align-items-center">
                        <Col className="fw-300 fs-14" lg={6}>
                          QA approver
                        </Col>
                        <Col className="ps-lg-0" lg={5}>
                          <Toggle
                            id={"qa_approv"}
                            checked={isQaApproverActive}
                            onChange={(e) =>
                              setIsQaApproverActive(e.target.checked)
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={6}>
                      <Row className="d-flex align-items-center">
                        <Col className="fw-300 fs-14" lg={6}>
                          LandLord/Seller
                        </Col>
                        <Col className="ps-lg-0" lg={5}>
                          <Toggle
                            id={"landLord_seller"}
                            checked={isLandlordSellerSignerActive}
                            onChange={(e) =>
                              setIsLandlordSellerSignerActive(e.target.checked)
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col sm={6}>
                      <Row className="d-flex align-items-center">
                        <Col className="fw-300 fs-14" lg={6}>
                          Copy recipient
                        </Col>
                        <Col className="ps-lg-0" lg={5}>
                          <Toggle
                            id={"copy_recipient"}
                            checked={isCopyRecipient}
                            onChange={(e) =>
                              setIsCopyRecipient(e.target.checked)
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={6}>
                      <Row className="d-flex align-items-center">
                        <Col className="fw-300 fs-14" lg={6}>
                          Notification
                        </Col>
                        <Col className="ps-lg-0" lg={5}>
                          <Toggle
                            id={"notification"}
                            checked={isNotificationRecipientActive}
                            onChange={(e) =>
                              setIsNotificationRecipientActive(e.target.checked)
                            }
                          />{" "}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={6}>
                      <Row className="d-flex align-items-center">
                        <Col className="fw-300 fs-14" lg={6}>
                          2FA
                        </Col>
                        <Col className="ps-lg-0" lg={5}>
                          <Toggle
                            id={"2FA"}
                            checked={istwoFactorActive}
                            onChange={(e) =>
                              setIsTwoFactorActive(e.target.checked)
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>

          <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => {
                setCancelSigner(true);
                // setAddShowSigners(false);
                // setShowPackage(false);
              }}
            />
            <ETButton
              type="submit"
              variant={"primary"}
              buttonText={editflag ? "SAVE" : "ADD"}
              disabled={PostAddSignerIsLoading || PutUpdateSignerIsLoading}
            />
          </div>
        </div>
        <CustomPopup
          open={showEmailPopUp?.showPopup}
          onHide={() =>
            setShowEmailPopUp((prev) => ({ ...prev, showPopup: false }))
          }
          headerRequired={true}
          title={"Exsiting user"}
          titleTooltip={true}
          titleTooltipText={"Exsiting user"}
          enableCrossButton={false}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
        >
          <div className="p-3">
            <div className="px-2">
              <div className="light-font-text my-1" style={{ height: "60px" }}>
                <p>{showEmailPopUp.message}</p>
                {/* <p>{showEmailPopUp}</p> */}
                {/* <p>That will conflict with.....</p> */}
              </div>
            </div>
          </div>
          <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="NO"
              onClick={() => {
                {
                  setIsUpdate(false);
                  handleAddSigner(formData, "0");
                }
              }}
            />
            <ETButton
              variant={"primary"}
              disabled={PostAddSignerIsLoading}
              buttonText="YES"
              onClick={() => {
                {
                  setIsUpdate(true);
                  handleAddSigner(formData, "1");
                }
              }}
              // disabled={isloadingPopup}
            />
          </div>
        </CustomPopup>

        {/* ------------------Close  signer  popup----------------- */}
        <CustomPopup
          open={cancelSigner}
          onHide={() => setCancelSigner(false)}
          title="Cancel"
          size="md"
          title_color="black"
          enableCrossButton={false}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
          deletePopUpStyle={true}
          // subTitle={<div>xs</div>}
          titleTooltip={true}
          titleTooltipText={"Cancel Signer"}
        >
          <div className="px-4 py-4">
            <div className="form-label-title">
              Are you sure you want to cancel?
            </div>
            <div className="light-font-text">
              All unsaved changes will be lost
            </div>
          </div>
          <div
            className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
            style={{
              backgroundColor: "#F9FAFA",
              borderRadius: " 0px 0px 8px 8px",
              border: "1px solid #F0F0F0",
            }}
          >
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => setCancelSigner(false)}
            />
            <ETButton
              variant={"primary"}
              buttonText="OK"
              fontSize="14px"
              fontWeight="500"
              onClick={() => {
                setShowPackage(false);
              }}
            />
          </div>
        </CustomPopup>
      </Form>
    </>
  );
};

export default AddNewSigner;
