// created by Suraj
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "../../Components/Tabs/ReactTabs.style";
import { Link, Outlet, useLocation } from "react-router-dom";
import { organizationTabs } from "../../model/Constant";
import { GreenSpan, H1 } from "../../styles/Common.style";
import {
  useOrganizationId,
  useOrganizationOverview,
  useOrganizationSettingsBreadcrumbsTabs,
} from "../../hooks/stores/useOrganizationStore";
import "./organisation.css";
import { useGetOrganizationOverviewById } from "../../hooks/queries/useOrganization";

const Organization = () => {
  //------------- zustand ------------------//
  const organizationId = useOrganizationId((state) => state.organizationId);
  const setOrganizationOverview = useOrganizationOverview(
    (state) => state.setOrganizationOverview
  );
  const organizationBreadcrumbsTabs = useOrganizationSettingsBreadcrumbsTabs(
    (state) => state.organizationBreadcrumbsTabs
  );
  const setOrganizationBreadcrumbsTabs = useOrganizationSettingsBreadcrumbsTabs(
    (state) => state.setOrganizationBreadcrumbsTabs
  );

  // navigation
  const location = useLocation();

  //------------- React query ------------------//
  const { data: GetOrganizationOverviewByIdData } =
    useGetOrganizationOverviewById(organizationId);

  //   Breadcrumbs
  const CapitalizedTextConverter = (text) => {
    const convertedText = text?.charAt(0)?.toUpperCase() + text?.slice(1);
    return convertedText;
  };
  const activePath = window.location.pathname?.split("/")[2];
  const activeSettingsSubPath = window.location.pathname?.split("/")[3];

  useEffect(() => {
    if (CapitalizedTextConverter(activeSettingsSubPath) == "Users") {
      setOrganizationBreadcrumbsTabs("Pending users");
    }
    if (CapitalizedTextConverter(activeSettingsSubPath) == "System-defaults") {
      setOrganizationBreadcrumbsTabs("Main- & Substatus");
    }
    if (CapitalizedTextConverter(activeSettingsSubPath) == "Marketing") {
      setOrganizationBreadcrumbsTabs("SoMe integrations ");
    }
    if (CapitalizedTextConverter(activeSettingsSubPath) == "Property") {
      setOrganizationBreadcrumbsTabs("Areas");
    }
    if (CapitalizedTextConverter(activeSettingsSubPath) == "Communication") {
      setOrganizationBreadcrumbsTabs("Sender settings");
    }
  }, [window.location.pathname]);

  useEffect(() => {
    setOrganizationOverview(GetOrganizationOverviewByIdData?.data);

    return () => {};
  }, [GetOrganizationOverviewByIdData]);

  return (
    <Container fluid>
      <div className="py-2">
        <div className="pt-4 pb-2">
          <H1>Organisation</H1>
          {/* <div className="light-font-text">Lorem ipsum dolor sit</div> */}
        </div>
        <div className="light-font-text cursor-pointer pb-2">
          <Link
            to="/organization/details"
            // state={{ Tab: "Overview" }}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span className="me-2 fs-16">Organisation</span>
          </Link>
          <span className="me-2">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <GreenSpan
            className={activeSettingsSubPath?"me-2 fs-16":"me-2 fs-16 breadcrumbs"}
            style={{ color: activeSettingsSubPath && "#000" }}
          >
            {CapitalizedTextConverter(activePath)}
          </GreenSpan>

          {activeSettingsSubPath && (
            <>
              <span className="me-2">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />{" "}
              </span>
              <GreenSpan
                style={{
                  color:
                    CapitalizedTextConverter(activeSettingsSubPath) ==
                      "Areas" ||
                    CapitalizedTextConverter(activeSettingsSubPath) ==
                      "Screening"
                      ? ""
                      : "#000",
                }}
                className={"me-2 fs-16"}
              >
                {CapitalizedTextConverter(activeSettingsSubPath) ===
                "System-defaults"
                  ? "System defaults"
                  : CapitalizedTextConverter(activeSettingsSubPath)}
              </GreenSpan>
              {CapitalizedTextConverter(activeSettingsSubPath) == "Users" && (
                <>
                  <span className="me-2">
                    <FontAwesomeIcon
                      size="sm"
                      icon="fa-regular fa-angle-right"
                      style={{ color: "#000000" }}
                    />{" "}
                  </span>
                  <GreenSpan className="me-2 fs-16 breadcrumbs">
                    {organizationBreadcrumbsTabs}
                  </GreenSpan>
                </>
              )}
              {CapitalizedTextConverter(activeSettingsSubPath) ==
                "System-defaults" && (
                <>
                  <span className="me-2">
                    <FontAwesomeIcon
                      size="sm"
                      icon="fa-regular fa-angle-right"
                      style={{ color: "#000000" }}
                    />{" "}
                  </span>
                  <GreenSpan className="me-2  fs-16 breadcrumbs">
                    {organizationBreadcrumbsTabs}
                  </GreenSpan>
                </>
              )}
              {CapitalizedTextConverter(activeSettingsSubPath) ==
                "Marketing" && (
                <>
                  <span className="me-2">
                    <FontAwesomeIcon
                      size="sm"
                      icon="fa-regular fa-angle-right"
                      style={{ color: "#000000" }}
                    />{" "}
                  </span>
                  <GreenSpan className="me-2  fs-16">
                    {organizationBreadcrumbsTabs}
                  </GreenSpan>
                </>
              )}
              {CapitalizedTextConverter(activeSettingsSubPath) ==
                "Property" && (
                <>
                  <span className="me-2">
                    <FontAwesomeIcon
                      size="sm"
                      icon="fa-regular fa-angle-right"
                      style={{ color: "#000000" }}
                    />{" "}
                  </span>
                  <GreenSpan className="me-2  fs-16">
                    {organizationBreadcrumbsTabs}
                  </GreenSpan>
                </>
              )}
              {CapitalizedTextConverter(activeSettingsSubPath) ==
                "Communication" && (
                <>
                  <span className="me-2">
                    <FontAwesomeIcon
                      size="sm"
                      icon="fa-regular fa-angle-right"
                      style={{ color: "#000000" }}
                    />{" "}
                  </span>
                  <GreenSpan className="me-2  fs-16">
                    {organizationBreadcrumbsTabs}
                  </GreenSpan>
                </>
              )}
            </>
          )}
        </div>

        <div
          className="d-flex mt-1 pb-2"
          style={{ flexWrap: "wrap" }}
        >
          <>
            {organizationTabs?.map((item, i) => (
              <NavLink
                key={i}
                //   state={{ propId: propertyId }}
                to={item.link}
                style={{ textDecoration: "none" }}
                // onClick={() => handleHeading(item.tab)}
                className=" me-3"
              >
                {item?.tab}
              </NavLink>
            ))}
          </>
          {/* )} */}
        </div>

        <div className="p-lg-4 p-3 mb-4 containerStyle mt-2">
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Organization;
