import { useMemo } from "react";
import CustomPopup from "../../Components/CustomPopup";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../Components/Buttons/ETButton";
import { BalanceCardData } from "./StaticData";
import MRTTheme from "../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";

import {
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../Components/table/MRTGlobalProps";
import GroupText from "../../Components/GroupText/GroupText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NoRecordsToDisplay } from "../../utils/validationConstants";

const BalanceCard = ({ setShowBalanceCard, showBalanceCard }) => {
  const OpenPositionsColumns = useMemo(
    () => [
      {
        accessorKey: "posted",
        header: "Posted",
        size: 120,
      },
      {
        accessorKey: "text",
        header: "Text",
        size: 140,
        Cell: ({ row }) => (
          <>
            <div>{row?.original?.text}</div>
          </>
        ),
      },
      {
        accessorKey: "due_Date",
        header: "Due date",
        size: 120,
      },
      {
        accessorKey: "period_From",
        header: "period from",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div>{row?.original?.period_From}</div>
          </>
        ),
      },
      {
        accessorKey: "period_To",
        header: "period to",
        size: 120,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div>{row?.original?.period_To}</div>
          </>
        ),
      },
      {
        accessorKey: "amount",
        header: "Amount ",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div
              style={{ color: Number(row?.original?.amount) < 0 ? "red" : "" }}
            >
              {row?.original?.amount}DKK
            </div>
          </>
        ),
      },
      {
        accessorKey: "balance",
        header: "Balance",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div
              style={{ color: Number(row?.original?.balance) < 0 ? "red" : "" }}
            >
              {row?.original?.balance}DKK
            </div>
          </>
        ),
      },
    ],
    []
  );
  return (
    <div>
      {" "}
      <CustomPopup
        open={showBalanceCard}
        onHide={() => setShowBalanceCard(false)}
        title="Balance card"
        title_color="black"
        size={"lg"}
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        titleTooltipText={"Balance card"}
      >
        <div className="p-4" style={{ borderBottom: "1px solid #f0f0f0" }}>
          <Row className="mb-3">
            <Col sm={3} className="pe-0">
              <div className="form-label-title mb-2">Total balance </div>

              <GroupText
                lable="Total balance"
                type="dsd"
                name="www"
                id={"Total balance"}
                textWidth="30%"
                dynamicGrayLabel={false}
                graylabel={<div className="form-label-title">DKK</div>}
              />
            </Col>{" "}
            <Col sm={3} className="px-0">
              <div className="form-label-title mb-2 d-flex gap-2 align-items-center">
                Balance due{" "}
                <FontAwesomeIcon
                  icon="fa-solid fa-triangle-exclamation"
                  style={{ color: "#ff9900" }}
                />{" "}
              </div>

              <GroupText
                lable="Balance due"
                type="dsd"
                name="www"
                id={"Balance due"}
                textWidth="30%"
                dynamicGrayLabel={false}
                graylabel={<div className="form-label-title">DKK</div>}
              />
            </Col>
          </Row>{" "}
          <Row>
            <Col sm={3} className="pe-0">
              <div className="form-label-title mb-2">Deposit </div>

              <GroupText
                lable="Deposit"
                type="dsd"
                name="www"
                id={"Deposit"}
                textWidth="30%"
                dynamicGrayLabel={false}
                graylabel={<div className="form-label-title">DKK</div>}
              />
            </Col>{" "}
            <Col sm={3} className="px-0">
              <div className="form-label-title mb-2 ">Prepaid rent</div>

              <GroupText
                lable="Prepaid rent"
                type="dsd"
                name="www"
                id={"Prepaid rent"}
                textWidth="30%"
                dynamicGrayLabel={false}
                graylabel={<div className="form-label-title">DKK</div>}
              />
            </Col>
            <Col sm={3} className="ps-0">
              <div className="form-label-title mb-2">Guaranteed</div>

              <GroupText
                lable="Guaranteed"
                type="dsd"
                name="www"
                id={"Guaranteed"}
                textWidth="30%"
                dynamicGrayLabel={false}
                graylabel={<div className="form-label-title">DKK</div>}
              />
            </Col>
          </Row>
        </div>
        <div className="p-4">
          <MRTTheme
            colorVariantsForTableData={["odd"]}
            table={
              <MaterialReactTable
                muiTablePaperProps={{
                  selected: false,
                  elevation: 0,
                  fontFamily: "Cerebri sans-light !important",
                  sx: {
                    borderRadius: "0",
                    border: "none",
                    table: {
                      borderCollapse: "separate",
                      borderSpacing: "0 5px",
                    },
                  },
                }}
                muiTableContainerProps={{
                  sx: {
                    padding: "0px !important",
                  },
                }}
                columns={OpenPositionsColumns}
                // data={BalanceCardData || []}
                data={[]}
                icons={{...SortIcon}}
                localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                enableBottomToolbar={true}
                enableColumnActions={false}
                enablePagination={true}
                muiTableHeadRowProps={{
                  sx: {
                    ...TableRowsStyle,
                  },
                }}
                muiTablePaginationProps={{
                  ...paginationoptions,
                }}
                positionActionsColumn="last"
                renderTopToolbar={({}) => (
                  <>
                    <Row>
                      <Col lg={3} className="pb-2 pe-0">
                        <div className="form-label-title">Posted from </div>
                        <div className="form-input mb-2">
                          <input type="date" id="contractStart" />
                          <label htmlFor="contractStart"></label>
                        </div>
                      </Col>
                      <Col lg={3} className="pb-2 pe-0">
                        <div className="form-label-title ">Posted to </div>

                        <div className="form-input mb-2 ">
                          <input type="date" id="contractStart" />
                          <label htmlFor="contractStart"></label>
                        </div>
                      </Col>
                      <Col
                        lg={6}
                        className="pb-2 d-flex justify-content-end align-items-center "
                      >
                        <div className="d-flex justify-content-end align-items-center pt-2">
                          <ETButton buttonText={"EXPORT"} variant={"primary"} />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              />
            }
          />
        </div>
        <div className="py-2 px-4 d-flex justify-content-end footer-section-button-container">
          <ETButton
            variant={"primary"}
            buttonText="CLOSE"
            onClick={() => setShowBalanceCard(false)}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default BalanceCard;
