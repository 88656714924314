import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { MaterialReactTable } from "material-react-table";
import { Menu, MenuItem } from "@mui/material";
import "../../../styles/css/control-center/economy.css";
import { GreenSpan, H2, H3 } from "../../../styles/Common.style";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import MRTTheme from "../../../Components/table/MRTTheme";
import { NoRecordsToDisplay } from "../../../utils/validationConstants";
import { usePropertyOverview } from "../../../hooks/stores/usePropertyStore";
import { useGetUpcommingIdleness } from "../../../hooks/queries/useControlCenterQueries";
import { useControlCenter } from "../../../hooks/stores/useControlCenterStore";
import { SortIcon } from "../../../Components/table/MRTGlobalProps";

const UpcomingIdleness = () => {
  const [upcomingData, setUpcomingData] = useState([]);
  const { selectedProperties, selectedDate, selectedEndDate } =
    useControlCenter((state) => state);
  const { data: UpcommingIdlenessData } = useGetUpcommingIdleness(
    selectedProperties,
    selectedDate,
    selectedEndDate
  );
  useEffect(() => {
    setUpcomingData(UpcommingIdlenessData?.data);
  }, [UpcommingIdlenessData]);

  const [user, setUser] = useState(false);

  const open = Boolean(user);

  const handleClickUser = (event) => {
    setUser(event.currentTarget);
  };
  const columns = useMemo(() => [
    {
      accessorKey: "address",
      header: "Address",
      size: 150,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "alt_unit_id",
      header: "Unit. no",
      size: 20,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
    },
    {
      accessorKey: "property_name",
      header: "Property",
      size: 120,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "no_of_rooms",
      header: "Room",
      size: 20,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "square_measure",
      header: "Sq. m",
      size: 20,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "rent_per_sq_m",
      header: "Rent per sq. m",
      size: 50,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
    },
    {
      accessorKey: "yearly_rent",
      header: "Yearly rent",
      size: 50,
      Cell: ({ row }) => (
        <>
          <div>
            {row.original.yearly_rent} {row.original.currency_id}
          </div>
        </>
      ),
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "days_until_idleness",
      header: "Days until idleness",
      enableHiding: false,
      enableGlobalFilter: false,
      //   center align the header and body cells
      size: 180,
      Cell: ({ row }) => (
        <>
          <span className="me-1">
            {row.original.days_until_idleness}
            {row.original.days_until_idleness == 1 || 0 ? "day" : "days"}
          </span>

          <span
            className="radius-4 me-3  cursor-pointer"
            // onClick={handleClickUser}
          >
            {row.original.days_until_idleness ? (
              <>
                {" "}
                (<GreenSpan>show more</GreenSpan>)
              </>
            ) : (
              ""
            )}
          </span>
        </>
      ),
      muiTableHeadCellProps: {
        align: "center",
        sx: {
          paddingLeft: "0px",
          fontWeight: "600",
          fontSize: "13px",
          fontFamily: "Cerebri sans",
        },
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
  ]);

  const tableData = [
    {
      Address: "Fuglebakkevej 88C 2.tv",
      Unit_no: "8-W210 - 1",
      Property: "Fuglebakkevej 88	",

      Room: "3",
      Sqm: "65m2",
      Rent_per_sqm: "1.175DKK",

      Yearlyrent: "76.375DKK",
      Days_until_idleness: "1days",
    },
    {
      Address: "Fuglebakkevej 88C 2.tv",
      Unit_no: "8-W210 - 1",
      Property: "Fuglebakkevej 88	",

      Room: "3",
      Sqm: "65m2",
      Rent_per_sqm: "1.175DKK",

      Yearlyrent: "76.375DKK",
      Days_until_idleness: "1days",
    },
    {
      Address: "Fuglebakkevej 88C 2.tv",
      Unit_no: "8-W210 - 1",
      Property: "Fuglebakkevej 88	",

      Room: "3",
      Sqm: "65m2",
      Rent_per_sqm: "1.175DKK",

      Yearlyrent: "76.375DKK",
      Days_until_idleness: "1days",
    },
    {
      Address: "Fuglebakkevej 88C 2.tv",
      Unit_no: "8-W210 - 1",
      Property: "Fuglebakkevej 88	",

      Room: "3",
      Sqm: "65m2",
      Rent_per_sqm: "1.175DKK",

      Yearlyrent: "76.375DKK",
      Days_until_idleness: "1days",
    },
    {
      Address: "Fuglebakkevej 88C 2.tv",
      Unit_no: "8-W210 - 1",
      Property: "Fuglebakkevej 88	",

      Room: "3",
      Sqm: "65m2",
      Rent_per_sqm: "1.175DKK",

      Yearlyrent: "76.375DKK",
      Days_until_idleness: "1days",
    },
    {
      Address: "Fuglebakkevej 88C 2.tv",
      Unit_no: "8-W210 - 1",
      Property: "Fuglebakkevej 88	",

      Room: "3",
      Sqm: "65m2",
      Rent_per_sqm: "1.175DKK",

      Yearlyrent: "76.375DKK",
      Days_until_idleness: "1days",
    },
  ];

  return (
    // <div className="p-4 radius-8" style={{ backgroundColor: "#FFFFFF" }}>
    <div className="card-body p-4">
      <Row>
        <Col className="d-flex align-items-center">
          {" "}
          <div className="d-flex align-items-center">
            <H3 className=" me-2">Upcoming idleness</H3>{" "}
            <CustomToolTip size="sm" text="Upcoming idleness" />
          </div>
        </Col>
      </Row>
      {tableData.length != 0 ? (
        <Row className="mt-2">
          <Col className="pe-lg-2">
            <MRTTheme
              colorVariantsForTableData={["odd"]}
              table={
                <MaterialReactTable
                  columns={columns}
                  // data={tableData}
                  data={upcomingData ?? []}
                  icons={{ ...SortIcon }}
                  localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                  enableTopToolbar={false}
                  enableBottomToolbar={false}
                  enableSelectAll={false}
                  enableColumnActions={false}
                  enableColumnFilter={false}
                  enableResizing={false}
                  initialState={{ showGlobalFilter: true }}
                  muiTableHeadCellProps={{
                    sx: {
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                  muiTableContainerProps={{
                    sx: {
                      padding: "0px !important",
                    },
                  }}
                  enableColumnFilterModes={false}
                />
              }
            />
          </Col>
        </Row>
      ) : (
        <NoDataScreen height={"377px"} imgHeight={"150px"} />
      )}

      <Menu
        id="basic-menu"
        anchorEl={user}
        open={user}
        onClose={() => setUser(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.02)",
          "& .MuiPaper-root": {
            borderRadius: "8px",
            width: "fit-content",
            marginTop: "8px",
            marginLeft: "-105px",
          },
        }}
      >
        <MenuItem
          sx={{
            "&:hover": { backgroundColor: "transparent" },
            borderRadius: "8px",
            padding: "0px",
            overflow: "hidden",
            border: "1px solid #F4F5F5",
          }}
        >
          <div className="showmore-card p-3">
            <div className="untilidleness-container">
              {/* <div className="light-font-text fs-13">
                <span className="table-property-name">Start of rental</span>:
                Aug 22, 2023
              </div>
              <div className="light-font-text fs-13">
                <span className="table-property-name">Termination date</span>:
                Oct 22, 2023
              </div>
              <div className="light-font-text fs-13">
                <span className="table-property-name">Move-out date</span>: Jan
                15, 2023
              </div>
              <div className="light-font-text fs-13">
                <span className="table-property-name">Idleness date</span>: Jan
                31, 2023
              </div> */}
            </div>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UpcomingIdleness;
