import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { H2, H3 } from "../../../styles/Common.style";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../../Components/Tabs/ReactTabs.style";
import QualityControl from "./pages/QualityControl";
import Customer from "./pages/Customer";
import Owner from "./pages/Owner";
import CancelledOrders from "./pages/CancelledOrders";
import { useGetSigningOrderOverview } from "../../../hooks/queries/useCustomerFunnel";
import { useUserDetails } from "../../../hooks/stores/useUserDetailsStore";

const Signings = () => {
  /* ------------------ State ----------------- */
  const [overviewMainTabs, setOverviewMainTabs] = useState("");
  const { userDetails } = useUserDetails((state) => state);
  const { data: getSigningOrderOverview } = useGetSigningOrderOverview(
    userDetails?.division_id
  );

  const OverviewTabsWizards = [
    {
      Id: "1",
      tab: "Waiting for quality control",
      component: <QualityControl />,
    },
    {
      Id: "2",
      tab: "Waiting for customer",
      component: <Customer />,
    },
    {
      Id: "3",
      tab: "Waiting for owner",
      component: <Owner />,
    },
    {
      Id: "4",
      tab: "Cancelled orders",
      component: <CancelledOrders />,
    },
  ];

  return (
    <div className="p-4 containerStyle">
      <div className="bg-white radius-8 fs-14 mainborder mb-4">
        <div
          className="px-4 py-3 "
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div className=" d-flex align-items-center">
            <H2>Overview</H2>
            <div className="ps-2">
              {" "}
              <CustomToolTip text="Overview" />{" "}
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="p-4 bgcolor-d-white">
            <Row>
              {getSigningOrderOverview?.data?.map((item) => (
                <Col lg={4} sm={6} className="pe-2">
                  <div
                    className="h-100 gap-4 p-4 d-flex align-items-center  radius-8  lite-grey-border"
                    style={{ backgroundColor: "#fff" }}
                  >
                    <div className="w-25">
                      <div
                        className=""
                        style={{
                          height: "48px",
                          width: "48px",
                          backgroundColor: "rgba(65, 105, 225, 0.50)",
                          borderRadius: "100px",
                        }}
                      ></div>
                    </div>
                    <div className="d-flex flex-column">
                      <div className="actors-section-title fs-24">
                        {item?.total_count} {""}signings
                      </div>
                      <div className="font-light fs-18">
                        {item?.contract_status_name}
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>

      <div className="bg-white radius-8 fs-14 mainborder">
        <div
          className="px-4 py-3"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div className=" d-flex align-items-center  mb-2 ">
            <H3>Signing orders in process</H3>
            <div className="ps-2">
              {" "}
              <CustomToolTip text="Signing orders in process" />{" "}
            </div>
          </div>
          <div className="caption-light">
            <span className="grey-text">
              Last updated by Jesper Thorkildsen, 19. Jan. 2023 | 16:21:03
            </span>
          </div>
        </div>
        <Tabs lassName="mb-3 pb-1 d-flex flex-column" value={overviewMainTabs}>
          <div
            className="d-flex ps-4 pe-3 py-3 justify-content-between"
            style={{
              width: "100%",
              flexDirection: "row",
              flexWrap: "wrap",
              borderBottom: "1px solid #F0F0F0",
            }}
          >
            <TabList className="light ps-0  ">
              {OverviewTabsWizards.map((page, index) => (
                <>
                  <Tab
                    key={index}
                    style={{ position: "relative" }}
                    onClick={() => setOverviewMainTabs(page?.tab)}
                  >
                    <span style={{ position: "relative" }}>{page?.tab}</span>
                  </Tab>
                </>
              ))}
            </TabList>
          </div>
          <div className="bg-white" style={{ borderRadius: " 10px" }}>
            {OverviewTabsWizards.map((page, index) => (
              <TabPanel key={page?.Id}>{page?.component}</TabPanel>
            ))}
          </div>
        </Tabs>
      </div>
    </div>
  );
};

export default Signings;
