import React, { useMemo } from "react";
import ShowTooltip from "../../../../../Components/Tooltip/ShowTooltip";
import MRTTheme from "../../../../../Components/table/MRTTheme";
import CustomToolTip from "../../../../../Components/Tooltip/CustomTooltip";
import { MaterialReactTable } from "material-react-table";
import { H3 } from "../../../../../styles/Common.style";
import { SortIcon } from "../../../../../Components/table/MRTGlobalProps";
import ETButton from "../../../../../Components/Buttons/ETButton";
const SigningOrderMouseOver = ({ row }) => {
  const SigningOrderPopupColumns = useMemo(
    () => [
      {
        accessorKey: "Status",
        header: "Status",
        size: 100,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 200,
      },
      {
        accessorKey: "actor",
        header: "Actor",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "signingdate",
        header: "Date of signing",
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "lastreminder",
        header: "Last reminder",
        size: 200,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "action",
        header: "Action",
        size: 80,
        Cell: ({ row }) => (
          <ETButton
            variant={"white"}
            buttonText={row?.original?.action}
            fontSize="14px"
            fontWeight="500"
            width="130px"
          />
        ),
      },
    ],
    []
  );
  const SigningsOrderPopupData = [
    {
      Status: "Done",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "-",
      action: "REMIND",
    },
    {
      Status: "Pending",
      name: "Klaus Bødker Udsen",
      actor: "Tenant",
      signingdate: "-",
      lastreminder: "26 Sep, 2023 at 12:00",
      action: "REMIND AGAIN",
    },
    {
      Status: "Pending",
      name: "Michael Rasmussen",
      actor: "Developer",
      signingdate: "-",
      lastreminder: "-",
      action: "REMIND",
    },
  ];

  return (
    <div>
      <ShowTooltip
        tooltipLabel={
          <div
            className="radius-4 form-label-title fs-12"
            style={{
              backgroundColor:
                (parseInt(row.original.total_signed) /
                  parseInt(row.original.total_signer)) *
                  100 <
                50
                  ? "#F1664B"
                  : (parseInt(row.original.total_signed) /
                      parseInt(row.original.total_signer)) *
                      100 ==
                    100
                  ? "#77C7A1"
                  : (parseInt(row.original.total_signed) /
                      parseInt(row.original.total_signer)) *
                      100 >=
                    50
                  ? "#F6E651"
                  : "#D1D1D1",
              cursor: "pointer",
              width: "fit-content",
              padding: "1px 10px",
            }}
          >
            {row.original.total_signed}/{row.original.total_signer}
            {/* The content you want to include */}
          </div>
        }
        tooltopWidth={1000}
        tooltipContent={
          <>
            <div style={{ width: "950px" }}>
              <div
                className="w-100 d-flex px-4 py-3"
                // style={{ borderBottom: "1px solid #F0F0F0" }}
              >
                <H3>Signing order</H3>

                <div className="ps-2">
                  {" "}
                  <CustomToolTip text="Signing order" />{" "}
                </div>
              </div>
              <hr className="hr m-0 " style={{ width: "990px" }}></hr>
              <div>
                <MRTTheme
                  colorVariantsForTableData={["odd"]}
                  table={
                    <MaterialReactTable
                      columns={SigningOrderPopupColumns}
                      data={SigningsOrderPopupData}
                      icons={{ ...SortIcon }}
                      enableBottomToolbar={true}
                      enableSorting={false}
                      enableStickyHeader
                      enableColumnActions={false}
                      enablePagination={false}
                      renderTopToolbar={false}
                      enableRowActions
                      positionActionsColumn="last"
                      displayColumnDefOptions={{
                        "mrt-row-actions": {
                          size: 10,
                          header: "",
                        },
                        "mrt-row-select": {
                          header: "",
                        },
                      }}
                      muiTableContainerProps={{
                        elevation: 0,
                        sx: {
                          padding: "0px",
                          maxHeight: "300px",
                        },
                      }}
                      muiTablePaperProps={{
                        sx: {
                          paddingLeft: "1rem",
                        },
                      }}
                    />
                  }
                />
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default SigningOrderMouseOver;
