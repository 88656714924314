import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../Components/Tabs/ReactTabs.style";
import ETButton from "../../Components/Buttons/ETButton";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import EventsSection from "./EventsSection";
import EventCalendar from "./EventCalendar";
import "./events.css";
import CustomPopup from "../../Components/CustomPopup";
import { useEffect, useState } from "react";
import YesNoButton from "../../Components/Buttons/YesNoButton";
import MultipleButton from "../../Components/Buttons/MultipleButton";
import ReactSelect from "../../Components/DropDown/ReactSelect";

import {
  useGetEventsType,
  useGetEventAgentDetails,
} from "../../hooks/queries/useEventsQueries";
import { usePropertyId } from "../../hooks/stores/usePropertyStore";
import { useEventStore } from "../../hooks/stores/useEventStore";
import { useBreadcrumb } from "../../Components/BreadcrumbContext ";
import { H2 } from "../../styles/Common.style";

export const Events = ({
  setShowOpenHouseProperty,
  setShowOpenHousePropertyFollowUp,
  setShowPrivateEvent,
  setShowCreatePrivateEvent,
}) => {
  //-------------------state--------------------------//

  const [addEventsPopUp, setAddEventsPopUp] = useState(false);

  const [viewSection, setViewSection] = useState(false);
  const [selectedEventType, setSelectedEventType] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [tabType, setTabType] = useState(0);
  const { updateBreadcrumbs } = useBreadcrumb();

  //-------------------Zustand--------------------------//

  const propertyId = usePropertyId((state) => state.propertyId);
  const {
    typeOfEvents,
    setTypeOfEvents,
    eventOn,
    setEventOn,
    booking,
    setBooking,
    prescreening,
    setPrescreening,
    qrCodeGenerate,
    setQrCodeGenerate,
  } = useEventStore((state) => state);

  //----------------react query----------------//

  const { data: getEventsTypeData } = useGetEventsType();
  const { data: getEventAgentDetailsData } =
    useGetEventAgentDetails(propertyId);

  const addEventhandle = () => {
    setAddEventsPopUp(false);
    if (typeOfEvents === 2) {
      // setShowPrivateEventPage(true);
      setShowCreatePrivateEvent(true);
    } else {
      setShowOpenHouseProperty(true);
    }
    setBooking(1);
    setPrescreening(1);
    setQrCodeGenerate(1);
  };

  useEffect(() => {
    if (tabType == "0") {
      updateBreadcrumbs(["Events", "Calendar"]);
    } else if (tabType == "1") {
      updateBreadcrumbs(["Events", "Events"]);
    } else if (tabType == "2") {
      updateBreadcrumbs(["Events", "Analysis"]);
    }
  }, [tabType]);
  const eventWithId1 = getEventsTypeData?.data?.find((item) => item?.Id === 1);
  const eventWithId2 = getEventsTypeData?.data?.find((item) => item?.Id === 2);
  // const eventWithId3 = getEventsTypeData?.data?.find((item) => item?.Id === 3);
  return (
    <>
      <div className="containerStyle p-4">
        <div className="events-body-container mainborder">
          <div className="events-headers px-4 py-3 d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center  ">
              <H2> Events </H2>
              <span className="ms-2">
                <CustomToolTip size="sm" text="Events" />
              </span>
            </div>

            <ETButton
              variant={"primary"}
              buttonText="ADD EVENT"
              icon={true}
              width="fit-content"
              onClick={() => setAddEventsPopUp(true)}
            />
          </div>
          <div className="events-tabs ">
            <Tabs>
              <div
                className="px-4 py-3"
                style={{ borderBottom: "1px solid #F0F0F0" }}
              >
                <TabList className="light m-0 ps-0">
                  <Tab className="me-3" onClick={() => setTabType(0)}> Calendar</Tab>
                  <Tab className="me-3"  onClick={() => setTabType(1)}>Events</Tab>
                  <Tab onClick={() => setTabType(2)}>Analysis</Tab>
                </TabList>
              </div>
              <div className="p-3">
                <TabPanel>
                  {!viewSection ? (
                    <EventCalendar
                      setViewSection={setViewSection}
                      setShowOpenHousePropertyFollowUp={
                        setShowOpenHousePropertyFollowUp
                      }
                      setShowPrivateEvent={setShowPrivateEvent}
                      getEventsTypeData={getEventsTypeData}
                      getEventAgentDetailsData={getEventAgentDetailsData}
                      selectedEventType={selectedEventType}
                      setSelectedEventType={setSelectedEventType}
                      selectedFilter={selectedFilter}
                      setSelectedFilter={setSelectedFilter}
                    />
                  ) : (
                    <EventsSection
                      setViewSection={setViewSection}
                      getEventsTypeData={getEventsTypeData}
                      getEventAgentDetailsData={getEventAgentDetailsData}
                      selectedEventType={selectedEventType}
                      setSelectedEventType={setSelectedEventType}
                      selectedFilter={selectedFilter}
                      setSelectedFilter={setSelectedFilter}
                    />
                  )}
                </TabPanel>

                <TabPanel>Events</TabPanel>

                <TabPanel>Analysis</TabPanel>
              </div>
            </Tabs>
          </div>
        </div>
      </div>
      <CustomPopup
        open={addEventsPopUp}
        onHide={() => setAddEventsPopUp(false)}
        onCloseAction={() => {
          setAddEventsPopUp(false);
        }}
        title="Create event"
        title_color="black"
        titleTooltip={true}
        titleTooltipText="Create event"
        enableCrossButton={false}
        size="lg"
      >
        <div
          className="fs-14 fw-300 scrollable-container-y"
          style={{ height: "500px", overflowY: "scroll" }}
        >
          <p className="p-3">
            Before we can start. Select what kind of event you want to create.
          </p>

          <div className="px-3">
            <div className="form-label-title pb-2 ">Event on a</div>
            <div className="pt-1">
              <YesNoButton
                value={eventOn}
                onChange={setEventOn}
                btnText1={"Property"}
                btnText2={"Unit"}
              />
            </div>
          </div>
          <hr className="my-4"></hr>

          <div className="px-3">
            <div className="form-label-title pb-2 ">Type of event</div>
            <div className="pt-1">
              <MultipleButton
                value={typeOfEvents}
                onChange={setTypeOfEvents}
                btnText1={eventWithId1 ? [eventWithId1.Text] : []}
                btnText2={eventWithId2 ? [eventWithId2.Text] : []}
                // btnText3={eventWithId3 ? [eventWithId3.Text] : []}
              />
            </div>
          </div>

          <hr className="my-4"></hr>
          <div className={typeOfEvents === 2 && "desabled"}>
            <div className="px-3">
              <div className="form-label-title pb-2 ">Booking required?</div>
              <div className="pt-1">
                <YesNoButton
                  value={booking}
                  onChange={setBooking}
                  disabled={typeOfEvents === 2 ? true : false}
                />
              </div>
            </div>
            <hr className="my-4"></hr>

            <div className="px-3 d-flex gap-4">
              <div>
                <div className="form-label-title pb-2 ">Pre-screening</div>
                <div className="pt-1">
                  <YesNoButton
                    value={prescreening}
                    onChange={setPrescreening}
                    disabled={typeOfEvents === 2 ? true : false}
                  />
                </div>
              </div>
              {typeOfEvents === 2 ||
                (prescreening === 1 && (
                  <div>
                    <div className="form-label-title pb-2 ">
                      Select a saved pre-screening template
                    </div>
                    <div className="pt-1">
                      <ReactSelect placeholder={"Pre-sceening templates"} />
                    </div>
                  </div>
                ))}
            </div>
            <hr className="my-4"></hr>

            <div className="px-3 pb-5">
              <div className="form-label-title pb-2 ">
                Generate QR-code for easy registrations
              </div>
              <div className="pt-1">
                <YesNoButton
                  value={qrCodeGenerate}
                  onChange={setQrCodeGenerate}
                  disabled={typeOfEvents === 2 ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setAddEventsPopUp(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="NEXT"
            onClick={addEventhandle}
          />
        </div>
      </CustomPopup>
    </>
  );
};

export default Events;
