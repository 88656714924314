import React, { useEffect, useState } from "react";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import Toggle from "../../../../Components/Switch/Switch";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import { Form } from "react-bootstrap";
import { useAppState } from "../AllState";
import { Controller, useForm } from "react-hook-form";
import { H4 } from "../../../../styles/Common.style";

const Inventory = ({
  submitRef,
  handleNext,
  prevRef,
  activeTenancyForm,
  getConfigurationDetailsData,
  handleEditConfig,
  previewRef,
}) => {
  const [inventory, setInventory] = useState({
    retrieveDataFromUnit: true,
    retrieveDataFromUnitCheckbox: {
      other1: false,
      other2: false,
      other3: false,
    },
  });

  const [state, setState] = useAppState();
  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: state[activeTenancyForm] });
  const saveData = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 9, true);
    // handleNext(1);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  const preview = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 9, false);
    // handleNext(1);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  // saving all field into context state
  const prevSubmit = () => {
    // setState({ ...state, ...getValues() });
    setState({ ...state, [activeTenancyForm]: { ...getValues() } });
  };
  useEffect(() => {
    setValue(
      "retrieve_inventory_data_from_unit",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "retrieve_inventory_data_from_unit"
      )[0]?.actual_value
    );
    setValue(
      "inventory_1",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "inventory_1"
      )[0]?.actual_value
    );

    setValue(
      "inventory_2",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "inventory_2"
      )[0]?.actual_value
    );
    setValue(
      "inventory_3",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "inventory_3"
      )[0]?.actual_value
    );
    setValue(
      "inventory_4",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "inventory_4"
      )[0]?.actual_value
    );
    setValue(
      "inventory_5",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "inventory_5"
      )[0]?.actual_value
    );
    setValue(
      "inventory_6",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "inventory_6"
      )[0]?.actual_value
    );
    setValue(
      "inventory_7",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "inventory_7"
      )[0]?.actual_value
    );
    setValue(
      "inventory_8",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "inventory_8"
      )[0]?.actual_value
    );
    setValue(
      "inventory_9",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "inventory_9"
      )[0]?.actual_value
    );
    setValue(
      "inventory_10",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "inventory_10"
      )[0]?.actual_value
    );

    setValue(
      "inventory_11",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "inventory_11"
      )[0]?.actual_value
    );

    setValue(
      "inventory_12",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "inventory_12"
      )[0]?.actual_value
    );

    setInventory({
      ...inventory,
      retrieveDataFromUnitCheckbox: {
        ...inventory.retrieveDataFromUnitCheckbox,
        other1: !getValues("inventory_10"),
        other2: !getValues("inventory_11"),
        other3: !getValues("inventory_12"),
      },
    });

    setValue(
      "inventory_other_text_1",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "inventory_other_text_1"
      )[0]?.actual_value
    );
    setValue(
      "inventory_other_text_2",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "inventory_other_text_2"
      )[0]?.actual_value
    );
    setValue(
      "inventory_other_text_3",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "inventory_other_text_3"
      )[0]?.actual_value
    );
  }, [getConfigurationDetailsData]);
  return (
    <>
      <Form className="main-form">
        {/* -----------Top Header----------- */}
        <div style={{ borderBottom: "1px solid #F0F0F0" }}>
          <div className="px-4 py-3">
            <H4 className="actors-section-title">§9 Inventory</H4>
            <div className="light-font-text" style={{ color: "#888888" }}>
              Lorem ipsum dolor sit lorem ipsum dolor sit
            </div>
          </div>
        </div>
        <div
          className="px-4 py-4 light-font-text"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div className="d-flex align-items-center">
            <span className="me-2 form-label-title">Inventory</span>
            <CustomToolTip  text="Inventory" />
          </div>
          <p>
            The following inventory of fixtures and fittings in the tenancy
            belongs to the landlord at the conclusion of the tenancy:
          </p>
          <div className="d-flex align-items-center mb-2 gap-3">
            <div>Retrieve data from unit</div>
            <Controller
              {...register("retrieve_inventory_data_from_unit")}
              control={control}
              render={({
                field: { onChange, value = parseInt(getValues("retrieve_inventory_data_from_unit")) },
              }) => (
                <Toggle
                  id={"retrieveData"}
                  checked={value}
                  onChange={(e) =>
                    e.target.checked
                      ? setValue("retrieve_inventory_data_from_unit", 1)
                      : setValue("retrieve_inventory_data_from_unit", 0)
                  }
                />
              )}
            />
          </div>
          <div className="d-flex " style={{ alignItems: "center" }}>
            <div className="me-1">
              <Controller
                {...register("inventory_1")}
                control={control}
                render={({
                  field: {
                    onChange,
                    value = parseInt(getValues("inventory_1")),
                  },
                }) => (
                  <CustomCheckBox
                    checked={value}
                    onChange={(e) => {
                      e.target.checked
                        ? setValue("inventory_1", 1)
                        : setValue("inventory_1", 0);
                    }}
                  />
                )}
              />
            </div>
            <div>Cooker</div>
          </div>
          <div className="d-flex " style={{ alignItems: "center" }}>
            <div className="me-1">
              <Controller
                {...register("inventory_2")}
                control={control}
                render={({
                  field: {
                    onChange,
                    value = parseInt(getValues("inventory_2")),
                  },
                }) => (
                  <CustomCheckBox
                    // {...(!inventory.retrieveDataFromUnit && {
                    //   disabled: true,
                    // })}
                    checked={value}
                    onChange={(e) => {
                      e.target.checked
                        ? setValue("inventory_2", 1)
                        : setValue("inventory_2", 0);
                    }}
                  />
                )}
              />
            </div>
            <div>Fridge/freezer</div>
          </div>
          <div className="d-flex " style={{ alignItems: "center" }}>
            <div className="me-1">
              <Controller
                {...register("inventory_3")}
                control={control}
                render={({
                  field: {
                    onChange,
                    value = parseInt(getValues("inventory_3")),
                  },
                }) => (
                  <CustomCheckBox
                    // {...(!inventory.retrieveDataFromUnit && {
                    //   disabled: true,
                    // })}
                    checked={value}
                    onChange={(e) => {
                      e.target.checked
                        ? setValue("inventory_3", 1)
                        : setValue("inventory_3", 0);
                    }}
                  />
                )}
              />
            </div>
            <div>Tumble dryer</div>
          </div>
          <div className="d-flex " style={{ alignItems: "center" }}>
            <div className="me-1">
              <Controller
                {...register("inventory_4")}
                control={control}
                render={({
                  field: {
                    onChange,
                    value = parseInt(getValues("inventory_4")),
                  },
                }) => (
                  <CustomCheckBox
                    // {...(!inventory.retrieveDataFromUnit && {
                    //   disabled: true,
                    // })}
                    checked={value}
                    onChange={(e) => {
                      e.target.checked
                        ? setValue("inventory_4", 1)
                        : setValue("inventory_4", 0);
                    }}
                  />
                )}
              />
            </div>
            <div>Refrigerator</div>
          </div>
          <div className="d-flex " style={{ alignItems: "center" }}>
            <div className="me-1">
              <Controller
                {...register("inventory_5")}
                control={control}
                render={({
                  field: {
                    onChange,
                    value = parseInt(getValues("inventory_5")),
                  },
                }) => (
                  <CustomCheckBox
                    // {...(!inventory.retrieveDataFromUnit && {
                    //   disabled: true,
                    // })}
                    checked={value}
                    onChange={(e) => {
                      e.target.checked
                        ? setValue("inventory_5", 1)
                        : setValue("inventory_5", 0);
                    }}
                  />
                )}
              />
            </div>
            <div>Dishwasher</div>
          </div>
          <div className="d-flex " style={{ alignItems: "center" }}>
            <div className="me-1">
              <Controller
                {...register("inventory_6")}
                control={control}
                render={({
                  field: {
                    onChange,
                    value = parseInt(getValues("inventory_6")),
                  },
                }) => (
                  <CustomCheckBox
                    // {...(!inventory.retrieveDataFromUnit && {
                    //   disabled: true,
                    // })}
                    checked={value}
                    onChange={(e) => {
                      e.target.checked
                        ? setValue("inventory_6", 1)
                        : setValue("inventory_6", 0);
                    }}
                  />
                )}
              />
            </div>
            <div>Extractor hood</div>
          </div>
          <div className="d-flex " style={{ alignItems: "center" }}>
            <div className="me-1">
              <Controller
                {...register("inventory_7")}
                control={control}
                render={({
                  field: {
                    onChange,
                    value = parseInt(getValues("inventory_7")),
                  },
                }) => (
                  <CustomCheckBox
                    // {...(!inventory.retrieveDataFromUnit && {
                    //   disabled: true,
                    // })}
                    checked={value}
                    onChange={(e) => {
                      e.target.checked
                        ? setValue("inventory_7", 1)
                        : setValue("inventory_7", 0);
                    }}
                  />
                )}
              />
            </div>
            <div>Freezer</div>
          </div>
          <div className="d-flex " style={{ alignItems: "center" }}>
            <div className="me-1">
              <Controller
                {...register("inventory_8")}
                control={control}
                render={({
                  field: {
                    onChange,
                    value = parseInt(getValues("inventory_8")),
                  },
                }) => (
                  <CustomCheckBox
                    // {...(!inventory.retrieveDataFromUnit && {
                    //   disabled: true,
                    // })}
                    checked={value}
                    onChange={(e) => {
                      e.target.checked
                        ? setValue("inventory_8", 1)
                        : setValue("inventory_8", 0);
                    }}
                  />
                )}
              />
            </div>
            <div>Washing machine</div>
          </div>
          <div className="d-flex " style={{ alignItems: "center" }}>
            <div className="me-1">
              <Controller
                {...register("inventory_9")}
                control={control}
                render={({
                  field: {
                    onChange,
                    value = parseInt(getValues("inventory_9")),
                  },
                }) => (
                  <CustomCheckBox
                    // {...(!inventory.retrieveDataFromUnit && {
                    //   disabled: true,
                    // })}
                    checked={value}
                    onChange={(e) => {
                      e.target.checked
                        ? setValue("inventory_9", 1)
                        : setValue("inventory_9", 0);
                    }}
                  />
                )}
              />
            </div>
            <div>Washing machine/dryer</div>
          </div>
          <div className="d-flex " style={{ alignItems: "center" }}>
            <div className="me-1">
              <Controller
                {...register("inventory_10")}
                control={control}
                render={({
                  field: {
                    onChange,
                    value = parseInt(getValues("inventory_10")),
                  },
                }) => (
                  <CustomCheckBox
                    // {...(!inventory.retrieveDataFromUnit && {
                    //   disabled: true,
                    // })}
                    checked={value}
                    onChange={(e) => {
                      e.target.checked
                        ? setValue("inventory_10", 1)
                        : setValue("inventory_10", 0);
                      setInventory({
                        ...inventory,
                        retrieveDataFromUnitCheckbox: {
                          ...inventory.retrieveDataFromUnitCheckbox,
                          other1: value,
                        },
                      });
                      if (!inventory.retrieveDataFromUnitCheckbox.other1) {
                        setValue("inventory_other_text_1", "");
                      }
                    }}
                  />
                )}
              />
            </div>
            <div className=" d-flex gap-2">
              Other
              <div className="form-input">
                <input
                  {...register("inventory_other_text_1")}
                  className="mx-sm-0"
                  type="text"
                  style={{ height: "25px" }}
                  disabled={inventory.retrieveDataFromUnitCheckbox.other1}
                />
              </div>
            </div>
          </div>
          <div className="d-flex " style={{ alignItems: "center" }}>
            <div className="me-1">
              <Controller
                {...register("inventory_11")}
                control={control}
                render={({
                  field: {
                    onChange,
                    value = parseInt(getValues("inventory_11")),
                  },
                }) => (
                  <CustomCheckBox
                    // {...(!inventory.retrieveDataFromUnit && {
                    //   disabled: true,
                    // })}
                    checked={value}
                    onChange={(e) => {
                      e.target.checked
                        ? setValue("inventory_11", 1)
                        : setValue("inventory_11", 0);
                      setInventory({
                        ...inventory,
                        retrieveDataFromUnitCheckbox: {
                          ...inventory.retrieveDataFromUnitCheckbox,
                          other2: value,
                        },
                      });
                      if (!inventory.retrieveDataFromUnitCheckbox.other2) {
                        setValue("inventory_other_text_2", "");
                      }
                    }}
                  />
                )}
              />
            </div>
            <div className=" d-flex gap-2">
              Other
              <div className="form-input">
                <input
                  {...register("inventory_other_text_2")}
                  className="mx-sm-0"
                  type="text"
                  style={{ height: "25px" }}
                  disabled={inventory.retrieveDataFromUnitCheckbox.other2}
                />
              </div>
            </div>
          </div>
          <div className="d-flex " style={{ alignItems: "center" }}>
            <div className="me-1">
              <Controller
                {...register("inventory_12")}
                control={control}
                render={({
                  field: {
                    onChange,
                    value = parseInt(getValues("inventory_12")),
                  },
                }) => (
                  <CustomCheckBox
                    // {...(!inventory.retrieveDataFromUnit && {
                    //   disabled: true,
                    // })}
                    checked={value}
                    onChange={(e) => {
                      e.target.checked
                        ? setValue("inventory_12", 1)
                        : setValue("inventory_12", 0);
                      setInventory({
                        ...inventory,
                        retrieveDataFromUnitCheckbox: {
                          ...inventory.retrieveDataFromUnitCheckbox,
                          other3: value,
                        },
                      });
                      if (!inventory.retrieveDataFromUnitCheckbox.other3) {
                        setValue("inventory_other_text_3", "");
                      }
                    }}
                  />
                )}
              />
            </div>
            <div className=" d-flex gap-2 ">
              Other
              <div className="form-input">
                <input
                  {...register("inventory_other_text_3")}
                  className="mx-sm-0"
                  type="text"
                  style={{ height: "25px" }}
                  disabled={inventory.retrieveDataFromUnitCheckbox.other3}
                />
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          onClick={handleSubmit(saveData)}
          ref={submitRef}
          style={{ display: "none" }}
        />
        <button
          type="submit"
          onClick={handleSubmit(preview)}
          ref={previewRef}
          style={{ display: "none" }}
        />
      </Form>
    </>
  );
};

export default Inventory;
