import React, { useEffect, useState } from "react";
import { H2, H3, H4, H5 } from "../../../styles/Common.style";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";

import GroupButtons from "../../../Components/GroupButton/GroupButton";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../../Components/Buttons/ETButton";
import { Controller, useForm } from "react-hook-form";
import { REQUIRED_ERR } from "../../../utils/validationConstants";
import ColorInput from "../../../Components/ColorInput/ColorInput";
import { useOrganizationId } from "../../../hooks/stores/useOrganizationStore";
import {
  useGetOrganizationById,
  usePostUpdateOrganisationCustomisation,
} from "../../../hooks/queries/useOrganization";
import { toast } from "react-toastify";
import {
  ErrorAlert,
  LoadingAlert,
  SucessAlert,
} from "../../../Components/alerts/AlertsAndMessages.js";
import { status } from "../../../model/Constant";
import EtImageUploader from "../../../Components/DropZone/EtImageUploader";
import { useQueryClient } from "react-query";

const Customization = () => {
  const { organizationId } = useOrganizationId((state) => state);
  // ---------------------------- states ---------------------------------
  const [thumbnailFile, setThumbnailFile] = useState("");
  const [thumbNailFilename, setThumbnailFileName] = useState({
    name: "",
    url: "",
    fileSize: "",
  });
  const [backgroundLoginPage, setBackgroundLoginPage] = useState(0);
  //------------- React query ------------------//
  const queryClient = useQueryClient();
  const {
    data: GetOrganizationByOrganizationIdData,
    refetch: GetOrganizationByOrganizationIdDataRefetch,
  } = useGetOrganizationById(organizationId);

  const {
    mutateAsync: PostUpdateOrganisationCustomisation,
    isLoading: PostUpdateOrganisationCustomisationLoading,
  } = usePostUpdateOrganisationCustomisation();

  // --------------------------------------------- const ----------------------------------------------------------------//

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    primaryColor: "",
    secondaryColor: "",
    stroke: "",
    progressBar: "",
    font: "",
    links: "",
  });

  const onThumbnailUploaded = async (acceptedFiles) => {
    // console.log(
    //   typeof thumbnailFile === "string",
    //   thumbNailFilename,
    //   thumbnailFile?.length,
    //   "thumbnailFilecccccccccc"
    // );
    console.log(acceptedFiles, "acceptedFiles on settings");
    let id;
    let uploadData = new FormData();
    uploadData.append("organisation_id", organizationId);
    uploadData.append(
      "logo_thumb_filename",
      typeof acceptedFiles !== "string"
        ? acceptedFiles?.[0]?.name
        : thumbNailFilename?.name
    );
    uploadData.append(
      "logo_thumb_file",
      typeof acceptedFiles !== "string" ? acceptedFiles?.[0] : ""
    );

    if (!PostUpdateOrganisationCustomisationLoading) {
      id = toast.loading(<LoadingAlert />);
    }
    try {
      // Make an asynchronous request to update the document
      const response = await PostUpdateOrganisationCustomisation(uploadData, {
        onSuccess: () => {
          queryClient.invalidateQueries("qGetOrganizationOverviewById");
        },
      });

      // Check the response status and update the toast accordingly
      if (response.status === status.s && response.data.bReturn) {
        toast.update(id, {
          render: <SucessAlert />,
          isLoading: false,
          type: status.s,
          autoClose: 2000,
        });
      } else {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } catch (error) {
      // Handle errors during the document update
      toast.update(id, {
        render: <ErrorAlert />,
        isLoading: false,
        type: status.e,
        autoClose: 2000,
      });
    } finally {
      GetOrganizationByOrganizationIdDataRefetch();
    }
  };
  // console.log(backgroundLoginPage, "backgroundLoginPage");

  useEffect(() => {
    setThumbnailFile(
      GetOrganizationByOrganizationIdData?.data?.[0]?.logo_thumb_url
    );
    setThumbnailFileName({
      name: GetOrganizationByOrganizationIdData?.data?.[0]?.logo_thumb_filename,
      url: GetOrganizationByOrganizationIdData?.data?.[0]?.logo_thumb_url,
      fileSize:
        GetOrganizationByOrganizationIdData?.data?.[0]?.logo_thumb_filesize,
    });
  }, [GetOrganizationByOrganizationIdData, organizationId]);

  return (
    <div className="whiteContainerStyle">
      {/* title section */}
      <div className="px-3 pb-3 border-bottom">
        <div className="px-2 pt-3 d-flex">
          <div className="d-flex align-items-center gap-2">
            <H2>Customization</H2>
            <CustomToolTip size="sm" text="Customization" />
          </div>
        </div>
      </div>

      {/*Visuals */}
      <div className="p-4 pb-0 border-bottom">
        <H3>Visuals</H3>

        {/*Thumbnail - Organisation  */}
        <div className="pt-3 pb-4">
          <div className="d-flex align-items-center  gap-2 ">
            <H4>Thumbnail - Organisation</H4>
            <CustomToolTip size="sm" text="Thumbnail - Organisation" />
          </div>
          <div className="paragraph1-light pt-3 pb-4">
            This logo is used on the organisation branding tag in the system.
          </div>

          <div className="w-50">
            <EtImageUploader
              myFile={thumbnailFile}
              setMyFile={setThumbnailFile}
              onFileUploaded={onThumbnailUploaded}
              initialFileName={thumbNailFilename?.name}
              initialFileUrl={thumbNailFilename?.url}
              initialFileSize={thumbNailFilename?.fileSize}
              setThumbnailFileName={setThumbnailFileName}
            />
          </div>
        </div>

        {/*Logo - Main menu  */}
        {/* <div className="pb-4">
          <div className="d-flex align-items-center  gap-2 ">
            <H4>Logo - Main menu</H4>
            <CustomToolTip size="sm" text="Logo - Main menu" />
          </div>
          <div className="paragraph1-light pt-2 pb-3">
            This logo is used in the left top corner in the system. The logo is
            used globally regardless of divisions.
          </div>
          <div className="light-font-text pb-1">Recommended logo size: </div>
          <div className="w-50">
            <DropZone />
          </div>
        </div> */}

        {/*Logo - Login page  */}
        {/* <div className="pb-4">
          <div className="d-flex align-items-center  gap-2 ">
            <H4>Logo - Login page</H4>
            <CustomToolTip size="sm" text="Logo - Login page" />
          </div>
          <div className="paragraph1-light pt-2 pb-3">
            This logo is used on the login page for the system. The logo is used
            globally for all users assigned to this organisation.
          </div>
          <div className="light-font-text pb-1">Recommended logo size: </div>
          <div className="w-50">
            <DropZone />
          </div>
        </div> */}

        {/*Background - Login page  */}
        {/* <div className="pb-4 ">
          <div className="d-flex align-items-center  gap-2 ">
            <H4>Background - Login page</H4>
            <CustomToolTip size="sm" text="Background - Login page" />
          </div>
          <div className="light-font-text pt-2 pb-3">
            Choose whether the background should be a solid color or an image.
          </div>

          <Row>
            <Col xl={3} lg={4} sm={6}>
              <GroupButtons
                tabs={[
                  {
                    id: 1,
                    tabTitle: "IMAGE",
                    iconName: "fa-regular fa-image",
                  },
                  {
                    id: 2,
                    tabTitle: "COLOR",
                    iconName: "fa-regular fa-droplet",
                  },
                ]}
                currentTab={backgroundLoginPage}
                handleTabClick={(tab) => {
                  setBackgroundLoginPage(tab);
                  console.log(tab, "tab");
                }}
              />
            </Col>
          </Row>

          <div className="light-font-text pt-3 pb-1">Recommended image size: </div>
          <div className="w-50">
            <DropZone />
          </div>
        </div> */}
        {/*Forgot - password animation  */}
        {/* <div className="pb-4  "> */}
        {/* <div className="d-flex align-items-center  gap-2 ">
              <H4>Forgot password animation</H4>
              <CustomToolTip size="sm" text="Forgot password animation" />
            </div>
            <div className="light-font-text pt-2 ">
              Upload the branded animation.
            </div> */}

        {/* <Row>
              <Col xl={3} lg={4} sm={6}>
                <GroupButtons
                  tabs={[
                    {
                      id: 1,
                      tabTitle: "IMAGE",
                      iconName: "fa-regular fa-image",
                    },
                    {
                      id: 2,
                      tabTitle: "COLOR",
                      iconName: "fa-regular fa-droplet",
                    },
                  ]}
                  currentTab={backgroundLoginPage}
                  handleTabClick={(tab) => {
                    setBackgroundLoginPage(tab);
                    console.log(tab, "tab");
                  }}
                />
              </Col>
            </Row> */}

        {/* <div className="light-font-text pt-3">Recommended image size: </div>
            <div className="w-50 ">
              <DropZone />
            </div> */}
        {/* </div> */}
      </div>
      {/*Colors */}
      {/* <div className="p-4">
          <H3>Colors</H3>
          <div className="light-font-text pt-2 pb-3">
            Below are the branding colors that can be changed in the system and
            are used across the entire organisation.
          </div>
          <Row>
            <Col xl={3} lg={4}>
              <div className="d-flex align-items-center  gap-2 pb-2">
                <H5>Primary color</H5>
                <CustomToolTip size="sm" text="Primary color" />
              </div>

              <Controller
                name="primaryColor"
                control={control}
                {...register("primaryColor")}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <ColorInput
                    label="Primary color"
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    // onChange={(e) => setPrimaryColor(e.target.value)}
                  />
                )}
              />
              {errors?.primaryColor && (
                <p className="error-msg"> {REQUIRED_ERR}</p>
              )}
            </Col>
            <Col xl={3} lg={4}>
              <div className="d-flex align-items-center  gap-2 pb-2">
                <H5>Secondary color</H5>
                <CustomToolTip size="sm" text="Secondary color" />
              </div>
              <Controller
                name="secondaryColor"
                control={control}
                {...register("secondaryColor")}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <ColorInput
                    label="Secondary color"
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    // onChange={(e) => setPrimaryColor(e.target.value)}
                  />
                )}
              />
              {errors?.secondaryColor && (
                <p className="error-msg"> {REQUIRED_ERR}</p>
              )}
            </Col>
          </Row>
          <Row className="pt-3">
            <Col xl={3} lg={4}>
              <div className="d-flex align-items-center  gap-2 pb-2">
                <H5>Stroke</H5>
                <CustomToolTip size="sm" text="Stroke" />
              </div>
              <Controller
                name="stroke"
                control={control}
                {...register("stroke")}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <ColorInput
                    label="stroke"
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    // onChange={(e) => setPrimaryColor(e.target.value)}
                  />
                )}
              />
              {errors?.stroke && <p className="error-msg"> {REQUIRED_ERR}</p>}
            </Col>
            <Col xl={3} lg={4}>
              <div className="d-flex align-items-center  gap-2 pb-2">
                <H5>Progress bar</H5>
                <CustomToolTip size="sm" text="Progress bar" />
              </div>
              <Controller
                name="progressBar"
                control={control}
                {...register("progressBar")}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <ColorInput
                    label="Progress Bar"
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    // onChange={(e) => setPrimaryColor(e.target.value)}
                  />
                )}
              />
              {errors?.progressBar && (
                <p className="error-msg"> {REQUIRED_ERR}</p>
              )}
            </Col>
          </Row>
          <Row className="pt-3">
            <Col xl={3} lg={4}>
              <div className="d-flex align-items-center  gap-2 pb-2">
                <H5>Font</H5>
                <CustomToolTip size="sm" text="Font" />
              </div>
              <Controller
                name="font"
                control={control}
                {...register("font")}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <ColorInput
                    label="Font"
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    // onChange={(e) => setPrimaryColor(e.target.value)}
                  />
                )}
              />
              {errors?.font && <p className="error-msg"> {REQUIRED_ERR}</p>}
            </Col>
            <Col xl={3} lg={4}>
              <div className="d-flex align-items-center  gap-2 pb-2">
                <H5>Links</H5>
                <CustomToolTip size="sm" text="Links" />
              </div>
              <Controller
                name="links"
                control={control}
                {...register("links")}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <ColorInput
                    label="Links"
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    // onChange={(e) => setPrimaryColor(e.target.value)}
                  />
                )}
              />
              {errors?.links && <p className="error-msg"> {REQUIRED_ERR}</p>}
            </Col>
          </Row>
        </div> */}
      {/* footer */}
      {/* <div className="mt-4 py-2 px-3 d-flex flex-md-row flex-column justify-content-end footer-section-button-container gap-3">
        <ETButton
          variant={"primary"}
          buttonText="SAVE"
          type={"submit"}
          // onClick={() => setStep1(false)}
        />
      </div> */}
      {/* footer */}
    </div>
  );
};

export default Customization;
