import React from "react";
import { H1, H3, H4, H5 } from "../../../styles/Common.style";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import ETButton from "../../../Components/Buttons/ETButton";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

const ExistingCustomer = ({
  existingCustomerData,
  newDetails,
  disableButton = false,
  handleBack = () => {},
  handleUpdateAndUse = () => {},
  handleCreateAsNewCustomer = () => {},
}) => {
  const handleBackButton = () => {
    handleBack();
  };

  const handleCreateAsNewCustomerButton = () => {
    handleCreateAsNewCustomer();
  };

  const handleUpdateAndUseButton = () => {
    handleUpdateAndUse();
  };

  return (
    <div className="whiteContainerStyle">
      <div className=" Popups-inner-scrollable-container-height scrollable-container-y">
        <div className="d-flex flex-column align-items-center">
          <H1 className="pt-4">Existing Customer?</H1>
          <div className="light-font-text pt-4 w-50 text-align-center">
            Based on the information you have just filled in we found a match on
            an existing customer. You can now choose to add this reservation to
            the existing customer by clicking update and use or you can choose
            no to and you will create a new customer.
          </div>
          <H4 className="py-4">We found a match on an existing customer:</H4>
        </div>

        <Row>
          <Col xxl={{ span: 8, offset: 2 }} lg={{ span: 10, offset: 1 }}>
            <div
              className="mt-3 mx-4 px-lg-5 py-3 d-flex flex-column radius-8 text-center position-relative"
              style={{
                backgroundColor: "#F9FAFA",
                border: "1px solid #F4F5F5",
              }}
            >
              <Row className="mb-2">
                <>
                  <Col lg={8} className="pe-lg-0">
                    <H5 className="text-start pb-2">
                      Current customer details in the system:
                    </H5>
                  </Col>
                  <Col lg={4} className="ps-lg-0">
                    <H5 className="text-start pb-2">New details:</H5>
                  </Col>
                </>
              </Row>

              <div>
                <Row className="mb-2">
                  <Col lg={8} className="pe-lg-0">
                    <Row>
                      <Col className="pe-lg-0">
                        <div className="d-flex justify-content-start light-font-text">
                          Customer name:{" "}
                        </div>
                      </Col>
                      <Col className="pe-lg-0">
                        <div
                          className="d-flex justify-content-start light-font-text"
                          style={{
                            color:
                              existingCustomerData?.firstname ==
                                newDetails?.firstname &&
                              existingCustomerData?.lastname ==
                                newDetails?.lastname &&
                              "#349032",
                          }}
                        >
                          {`${existingCustomerData?.firstname} ${existingCustomerData?.lastname}`}
                        </div>
                      </Col>{" "}
                    </Row>
                  </Col>
                  <Col lg={4} className="ps-lg-0">
                    <Row>
                      <Col className="">
                        <div className="d-flex justify-content-start red-light-font-text">
                          {existingCustomerData?.firstname ==
                            newDetails?.firstname &&
                          existingCustomerData?.lastname == newDetails?.lastname
                            ? ""
                            : `${newDetails?.firstname} ${newDetails?.lastname}`}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={8} className="pe-lg-0">
                    <Row>
                      <Col className="pe-lg-0">
                        <div className="d-flex justify-content-start light-font-text">
                          Customer no:{" "}
                        </div>
                      </Col>
                      <Col className="pe-lg-0">
                        <div className="d-flex justify-content-start light-font-text">
                          {existingCustomerData?.actor_id}
                        </div>
                      </Col>{" "}
                    </Row>
                  </Col>
                  <Col lg={4} className="ps-lg-0">
                    <Row>
                      <Col className="">
                        <div className="d-flex justify-content-start red-light-font-text"></div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={8} className="pe-lg-0">
                    <Row>
                      <Col className="pe-lg-0">
                        <div className="d-flex justify-content-start light-font-text">
                          Email:{" "}
                        </div>
                      </Col>
                      <Col className="pe-lg-0">
                        <div
                          className="d-flex justify-content-start light-font-text"
                          style={{
                            color:
                              existingCustomerData?.email ==
                                newDetails?.email && "#349032",
                          }}
                        >
                          {existingCustomerData?.email}
                        </div>
                      </Col>{" "}
                    </Row>
                  </Col>
                  <Col lg={4} className="ps-lg-0">
                    <Row>
                      <Col className="">
                        <div className="d-flex justify-content-start red-light-font-text">
                          {existingCustomerData?.email == newDetails?.email
                            ? ""
                            : newDetails?.email}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={8} className="pe-lg-0">
                    <Row>
                      <Col className="pe-lg-0">
                        <div className="d-flex justify-content-start light-font-text">
                          Phone:{" "}
                        </div>
                      </Col>
                      <Col className="pe-lg-0">
                        <div
                          className="d-flex justify-content-start light-font-text"
                          style={{
                            color:
                              existingCustomerData?.phone_calling_code_id ==
                                newDetails?.countryCode?.label &&
                              existingCustomerData?.mobilenumber ==
                                newDetails?.mobilenumber &&
                              "#349032",
                          }}
                        >{`${existingCustomerData?.phone_calling_code_id} ${existingCustomerData?.mobilenumber}`}</div>
                      </Col>{" "}
                    </Row>
                  </Col>
                  <Col lg={4} className="ps-lg-0">
                    <Row>
                      <Col className="">
                        <div className="d-flex justify-content-start red-light-font-text">
                          {existingCustomerData?.phone_calling_code_id ==
                            newDetails?.countryCode?.label &&
                          existingCustomerData?.mobilenumber ==
                            newDetails?.mobilenumber
                            ? ""
                            : `${newDetails?.countryCode?.label} ${existingCustomerData?.mobilenumber}`}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={8} className="pe-lg-0">
                    <Row>
                      <Col className="pe-lg-0">
                        <div className="d-flex justify-content-start light-font-text">
                          Birthday:{" "}
                        </div>
                      </Col>
                      <Col className="pe-lg-0">
                        <div
                          className="d-flex justify-content-start light-font-text"
                          style={{
                            color:
                              existingCustomerData?.birthdate ==
                                newDetails?.birthdate && "#349032",
                          }}
                        >
                          {existingCustomerData?.birthdate}
                        </div>
                      </Col>{" "}
                    </Row>
                  </Col>
                  <Col lg={4} className="ps-lg-0">
                    <Row>
                      <Col className="">
                        <div className="d-flex justify-content-start red-light-font-text">
                          {existingCustomerData?.birthdate ==
                          newDetails?.birthdate
                            ? ""
                            : moment(newDetails?.birthdate).format(
                                "DD MMM, YYYY"
                              )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={8} className="pe-lg-0">
                    <Row>
                      <Col className="pe-lg-0">
                        <div className="d-flex justify-content-start light-font-text">
                          Social security number:{" "}
                        </div>
                      </Col>
                      <Col className="pe-lg-0">
                        <div
                          className="d-flex justify-content-start light-font-text"
                          style={{
                            color:
                              existingCustomerData?.social_security_number ==
                                newDetails?.social_security_number && "#349032",
                          }}
                        >
                          {existingCustomerData?.social_security_number}
                        </div>
                      </Col>{" "}
                    </Row>
                  </Col>
                  <Col lg={4} className="ps-lg-0">
                    <Row>
                      <Col className="">
                        <div className="d-flex justify-content-start red-light-font-text">
                          {newDetails?.social_security_number ==
                          existingCustomerData?.social_security_number
                            ? ""
                            : `${newDetails?.social_security_number}`}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={8} className="pe-lg-0">
                    <Row>
                      <Col className="pe-lg-0">
                        <div className="d-flex justify-content-start light-font-text">
                          Address:{" "}
                        </div>
                      </Col>
                      <Col className="pe-lg-0">
                        <div
                          className="d-flex justify-content-start light-font-text"
                          style={{
                            color:
                              existingCustomerData?.street_name ==
                                newDetails?.street_name &&
                              existingCustomerData?.house_number ==
                                newDetails?.house_number &&
                              "#349032",
                          }}
                        >
                          {`${existingCustomerData?.street_name} ${existingCustomerData?.house_number}`}
                        </div>
                      </Col>{" "}
                    </Row>
                  </Col>
                  <Col lg={4} className="ps-lg-0">
                    <Row>
                      <Col className="">
                        <div className="d-flex justify-content-start red-light-font-text">
                          {existingCustomerData?.street_name ==
                            newDetails?.street_name &&
                          existingCustomerData?.house_number ==
                            newDetails?.house_number
                            ? ""
                            : `${newDetails?.street_name} ${newDetails?.house_number}`}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={8} className="pe-lg-0">
                    <Row>
                      <Col className="pe-lg-0">
                        <div className="d-flex justify-content-start light-font-text">
                          Zip code:{" "}
                        </div>
                      </Col>
                      <Col className="pe-lg-0">
                        <div
                          className="d-flex justify-content-start light-font-text"
                          style={{
                            color:
                              existingCustomerData?.zipcode_id ==
                                newDetails?.zipCode?.value && "#349032",
                          }}
                        >
                          {`${existingCustomerData?.zipcode_id}`}
                        </div>
                      </Col>{" "}
                    </Row>
                  </Col>
                  <Col lg={4} className="ps-lg-0">
                    <Row>
                      <Col className="">
                        <div className="d-flex justify-content-start red-light-font-text">
                          {existingCustomerData?.zipcode_id ==
                          newDetails?.zipCode?.value
                            ? ""
                            : `${newDetails?.zipCode?.value}`}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-2">
                    <Col lg={8} className="pe-lg-0">
                      <Row>
                        <Col className="pe-lg-0">
                          <div className="d-flex justify-content-start light-font-text">
                            City:{" "}
                          </div>
                        </Col>
                        <Col className="pe-lg-0">
                          <div className="d-flex justify-content-start light-font-text" style={{
                            color:
                              existingCustomerData?.city ==
                                newDetails?.city && "#349032",
                          }}>
                            {existingCustomerData?.city}
                          </div>
                        </Col>{" "}
                      </Row>
                    </Col>
                    <Col lg={4} className="ps-lg-0">
                      <Row>
                        <Col className="">
                          <div className="d-flex justify-content-start red-light-font-text">
                          {existingCustomerData?.city ==
                          newDetails?.city
                            ? ""
                            : `${newDetails?.city}`}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
              </div>

              <div className="d-flex flex-column gap-2 pt-3">
                <div className="d-flex gap-2 align-items-center">
                  <FontAwesomeIcon
                    icon="fa-solid fa-circle"
                    style={{ color: "#349032" }}
                    size="xs"
                  />
                  <span className="light-font-text fs-10">
                    Details is the same in the system as what you have entered
                  </span>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <FontAwesomeIcon
                    icon="fa-solid fa-circle"
                    style={{ color: "#AA1518" }}
                    size="xs"
                  />
                  <span className="light-font-text fs-10">
                    Details you have entered is not the same as what is in the
                    system right now
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xxl={{ span: 8, offset: 2 }} lg={{ span: 10, offset: 1 }}>
            <div
              className="my-3 mx-4 px-lg-4 py-3 d-flex flex-column radius-8 text-start position-relative"
              style={{
                backgroundColor: "#F9FAFA",
                border: "1px solid #F4F5F5",
              }}
            >
              <H4 className="pb-2">Known activities</H4>
              <div className="d-flex flex-column gap-2">
                <div className="d-flex gap-2 align-items-center">
                  <FontAwesomeIcon
                    icon="fa-regular fa-house-user"
                    size="sm"
                    style={{ color: "#000000" }}
                  />
                  <span className="light-font-text">
                    Attended at an Appointment at Terrasserne 26 Apr, 2023
                  </span>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <FontAwesomeIcon
                    icon="fa-regular fa-house"
                    style={{ color: "#000000" }}
                    size="sm"
                  />
                  <span className="light-font-text">
                    Attended at Open House at Terrasserne 23 Apr, 2023
                  </span>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <FontAwesomeIcon
                    icon="fa-regular fa-house"
                    style={{ color: "#000000" }}
                    size="sm"
                  />
                  <span className="light-font-text">
                    Attended at Open House at Blomsterbjerget 16 Mar, 2023
                  </span>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <FontAwesomeIcon
                    icon="fa-regular fa-user"
                    size="sm"
                    style={{ color: "#000000" }}
                  />
                  <span className="light-font-text">
                    Former customer on Carolinelunden, Ved Kajkanten 13, 2., tv
                    (12 Mar, 2023)
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="py-2 px-3 d-flex flex-row justify-content-between  footer-section-button-container">
        <ETButton
          variant={"white"}
          buttonText="BACK"
          type={"button"}
          disabled={disableButton}
          onClick={handleBackButton}
        />

        <div className="d-flex gap-2 align-items-center">
          <ETButton
            variant={"secondary"}
            buttonText="CREATE AS A NEW CUSTOMER"
            icon={true}
            type={"button"}
            disabled={disableButton}
            onClick={handleCreateAsNewCustomerButton}
          />

          <ETButton
            variant={"primary"}
            buttonText="UPDATE AND USE"
            onClick={handleUpdateAndUseButton}
            disabled={disableButton}
          />
        </div>
      </div>
    </div>
  );
};

export default ExistingCustomer;

// Created by --------------> Yash
