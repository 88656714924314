// created by Suraj
import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import photoComingSoon from "../../assets/images/photo-coming-soon.jpg";
import { HtmlTooltip } from "./PropertyLevelTopBar";
import { H4 } from "../../styles/Common.style";
import { ImageComponent, ShortNameComponent } from "../../widget/Widget";
import { useEditCompany } from "../../hooks/stores/useCompanyStore";
import { useGetCompanyOverviewById } from "../../hooks/queries/useCompanyQueries";
import NewColorBox from "../ColorCombinationbox/NewColorBox";

const CompanyLevelTopBar = () => {
  //---------------------------Zustand---------------------//
  const { companyId, setCompanyId } = useEditCompany((state) => state);

  //------------- React query ------------------//
  const { data: GetCompanyOverview } = useGetCompanyOverviewById(companyId);

  useEffect(() => {
    
  
    return () => {
      setCompanyId("")
    }
  }, [])
  

  return (
    <>
      {GetCompanyOverview?.data?.map((item, i) => (
        <Row
          key={i}
          className="ms-xxl-5 ms-lg-4 ms-3 pt-4 pb-3  px-xxl-1 px-1 align-items-center"
        >
          <Col
            xl={4}
            lg={5}
            md={5}
            className="px-md-2 d-flex justify-content-md-start justify-content-center truncated-text"
          >
            <div>
              <ShortNameComponent
                backgroundColor={item?.shortname_background_color}
                fontColor={item?.shortname_text_color}
                shortNameText={item?.shortname}
                width="100px"
                height="70px"
              />
            </div>

            <div className="my-md-0 my-1 ps-3 w-100">
              <H4 className="" style={{ lineHeight: "22px" }}>
                {item?.company_name}
              </H4>
              <div className="grey-info  fs-14 line-height-20  ">
                <div className="m-0 ellipsis line-height-20 ">
                  C/O {item?.care_of}
                </div>
                <div className="m-0 ">
                  <Row>
                    <Col xxl={11}>
                      {" "}
                      {item?.street_name}&nbsp;
                      {item?.house_number} |&nbsp;
                      {item?.zipcode_id}&nbsp;
                      {item?.city_name}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>

          <Col xl={5} lg={3} md={3}>
            <Row>
              <Col xl={4}>
                <div className="fs-14  line-height-20">
                  <span>Properties</span> :&nbsp;
                  <span className="grey-info">
                    {" "}
                    {item?.total_property?.toString() !== "0"
                      ? item?.total_property
                      : 0}
                  </span>
                </div>
              </Col>
              <Col xl={4}>
                <div className="d-block text-truncate fs-14  line-height-20 ">
                  <span>Units</span> :&nbsp;
                  <span className="text-capitalize grey-info">
                    {" "}
                    {item?.total_unit?.toString() !== "0"
                      ? item?.total_unit
                      : 0}
                  </span>
                </div>
              </Col>
              <Col xl={4}>
                <div className=" fs-14">
                  <span>Active users </span> :&nbsp;
                  <span className="grey-info">
                    {" "}
                    {item?.total_users?.toString() !== "0"
                      ? item?.total_users
                      : 0}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xl={3} lg={4} md={4} className="ps-xl-2 ps-lg-0">
            <Row>
              <Col className="d-lg-flex  align-items-center ">
                <div className="fs-14">Overall status: &nbsp;</div>

                <NewColorBox
                  unitsOverview={item?.overall_status}
                  TotalUnits={item?.total_unit}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      ))}
    </>
  );
};

export default CompanyLevelTopBar;
const item = {};

// Added Image component created for short name and image --- Updated by Suraj
