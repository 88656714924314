import React, { useState } from "react";
import CustomPopup from "../../../../Components/CustomPopup";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../../../Components/Tabs/ReactTabs.style";
import FollowUpCustomer from "./FollowUpCustomer";
import Notes from "./Notes";
import { CustomerDetails } from "./CustomerDetails";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { useResponsibleAgentDataStore } from "../../../../hooks/stores/useResponsibleAgentDataStore";
import {
  useGetFollowUpStatus,
  useGetFollowUpTaskCategory,
  useGetLeadDetails,
  usePostAddLeadfollowUp,
} from "../../../../hooks/queries/useCustomerFunnel";
import { toast } from "react-toastify";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";

const FollowUp = ({
  isCustomerCardPopupOpen,
  setIsCustomerCardPopupOpen = () => {},
  activeTab,
  setActiveTab = () => {},
  leadId,
}) => {
  //=================== zustand ========================//
  const { responsibleAgentData } = useResponsibleAgentDataStore(
    (state) => state
  );
  const { userDetails } = useUserDetails((state) => state);

  //==================== React queries ===================//
  const { data: GetLeadDetailData } = useGetLeadDetails(leadId);
  const { data: GetFollowUpStatusData } = useGetFollowUpStatus();
  const { data: GetFollowUpTaskCategoryData } = useGetFollowUpTaskCategory();
  const {
    mutateAsync: PostAddLeadfollowUp,
    isLoading: PostAddLeadfollowUpIsLoading,
  } = usePostAddLeadfollowUp();

  //======================= Follow up customer button handlers ===================//
  const handleSaveFollowUpOnCustomer = async (customerFollowUpData) => {
    const addLeadfollowUpPayload = {
      actor_id: leadId?.toString(),
      follow_up_date: customerFollowUpData?.followUpDate,
      follow_up_task_category_id: `${customerFollowUpData?.followUpTaskCategory?.value}`,
      follow_up_note: customerFollowUpData?.followUpNote,
    };

    try {
      const response = await PostAddLeadfollowUp(addLeadfollowUpPayload);
      if (response?.data?.bReturn) {
        toast.success(response?.data?.message);
      } else {
        toast.info(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const followUpCustomers = [
    {
      Id: "1",
      tab: "Follow up",
      component: (
        <FollowUpCustomer
          followUpStatusDropDownData={GetFollowUpStatusData?.data || []}
          followUpTaskCategoryDropDownData={
            GetFollowUpTaskCategoryData?.data || []
          }
          handleCloseButton={() => setIsCustomerCardPopupOpen(false)}
          handleSaveButton={handleSaveFollowUpOnCustomer}
          disableButtonOnApiLoad={PostAddLeadfollowUpIsLoading}
        />
      ),
    },
    {
      Id: "2",
      tab: "Notes",
      component: (
        <Notes handleCloseButton={() => setIsCustomerCardPopupOpen(false)} />
      ),
    },
    {
      Id: "3",
      tab: "Customer details",
      component: (
        <CustomerDetails
          handleCloseButton={() => setIsCustomerCardPopupOpen(false)}
        />
      ),
    },
  ];

  return (
    <div>
      {" "}
      <CustomPopup
        open={isCustomerCardPopupOpen}
        onHide={() => setIsCustomerCardPopupOpen(false)}
        title="Customer details"
        title_color="black"
        enableCrossButton={true}
        size={"xl"}
        titleTooltip={true}
        titleTooltipText={"Customer details"}
        onClickCrossButton={() => setIsCustomerCardPopupOpen(false)}
      >
        <div>
          <Tabs selectedIndex={activeTab}>
            <div className="px-4 py-3 d-flex justify-content-between align-items-center">
              <TabList className="">
                {followUpCustomers.map((page, index) => (
                  <Tab
                    onClick={() => {
                      setActiveTab(index);
                    }}
                  >
                    {page?.tab}
                  </Tab>
                ))}
              </TabList>
              <div className="w-25">
                <ReactSelect
                  defaultValue={{
                    value: userDetails?.user_id,
                    label: `${userDetails?.firstName} ${userDetails?.lastName}`,
                  }}
                  placeholder={"Responsible agent"}
                  options={responsibleAgentData?.map((item) => ({
                    value: item?.Id,
                    label: item?.Text,
                  }))}
                  clearable={false}
                />
              </div>
            </div>
            <hr className="my-0"></hr>
            <div className="">
              {followUpCustomers.map((page, index) => (
                <TabPanel Key={page?.Id}> {page?.component} </TabPanel>
              ))}
            </div>
          </Tabs>
        </div>
      </CustomPopup>
    </div>
  );
};

export default FollowUp;
