import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Col, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import ETButton from "../../../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../../../Components/CustomPopup";
import CustomToolTip from "../../../../../../Components/Tooltip/CustomTooltip";
import EditImageDetailsModal from "./EditImageDetailsModal";
import MediaCard from "./MediaCard";
import { useGetMediaByUnitId } from "../../../../../../hooks/queries/useMediaQueries";
import { NavLink } from "react-router-dom";
import SortingFunction from "../../../../../../Components/Sorting/SortingFunction";
import { useBreadcrumb } from "../../../../../../Components/BreadcrumbContext ";
import { H2, H3 } from "../../../../../../styles/Common.style";


export const Media = ({ UnitId, propId }) => {
  const [mediadata, setMedaiData] = useState([]);

  //react query

  const { data: GetMediaByUnitIdData } = useGetMediaByUnitId(propId, UnitId);

  useEffect(() => {
    setMedaiData(GetMediaByUnitIdData?.data);
  }, [GetMediaByUnitIdData?.data]);

  const [openEditImage, setOpenEditImage] = useState(false);

  const handleEditImage = () => {
    setOpenEditImage(true);
  };

  const [modalHeaderText, setModalHeaderText] = useState(false);
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  const handleDrop = (droppedItem) => {
    //     // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const updatedList = [...dummyAray];
    //     // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    //     // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    //     // Update State
    setDummyAray(updatedList);
  };

  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    updateBreadcrumbs(["Medias"]);
  }, []);

  const [dummyAray, setDummyAray] = useState([
    {
      Id: 1,
    },
    {
      Id: 2,
    },

    {
      Id: 3,
    },
    {
      Id: 4,
    },
    {
      Id: 3,
    },
    {
      Id: 5,
    },
    {
      Id: 6,
    },
  ]);
  return (
    <>
      <div className="bg-white radius-8 fs-14  mainborder">
        <div className="d-flex flex-column  px-4 pt-3">
          <div className="d-flex align-items-center">
            <H2> Media{" "} </H2>
            <span className="ms-2 ">
              <CustomToolTip text="Marketing" size="md" />
            </span>
          </div>
        </div>

        <hr className="hr" />

        <div className="px-4">
          {GetMediaByUnitIdData?.data.length != 0 && (
            <SortingFunction
              data={GetMediaByUnitIdData?.data}
              setData={setMedaiData}
              sortingByName="media_package_name"
              sortById="media_file_id"
            />
          )}

          <Row>
            <Col>
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="image-container" direction="horizontal">
                  {(provided) => (
                    <div
                      className="Edit-image-container"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <Row style={{ width: "100%" }}>
                        {mediadata == 0 ? (
                          <div className="d-flex justify-content-center w-100 fs-12 mt-1 grey-text">
                            <div>You do not have any media yet. </div>{" "}
                          </div>
                        ) : (
                          <>
                            {mediadata?.map((item, index) => (
                              <Draggable
                                key={index}
                                draggableId={index.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <Col
                                    lg={3}
                                    md={6}
                                    className="item-container"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                  >
                                    <MediaCard
                                      provided={provided}
                                      dragOnIcon={provided.dragHandleProps}
                                      handleEditImage={handleEditImage}
                                      openEditImage={openEditImage}
                                      setOpenEditImage={setOpenEditImage}
                                      setModalHeaderText={setModalHeaderText}
                                      item={item}
                                    />
                                  </Col>
                                )}
                              </Draggable>
                            ))}
                          </>
                        )}

                        {provided.placeholder}
                      </Row>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Col>
          </Row>
        </div>
        <CustomPopup
          modalHeaderText={modalHeaderText}
          style={{ position: "relative" }}
          title={<>Edit image details</>}
          title_color="black"
          titleTooltipText="Edit image details"
          titleTooltip={true}
          size="xl"
          open={openEditImage}
          onHide={() => setOpenEditImage(false)}
          enableCrossButton={false}
          onCloseAction={() => {
            setOpenEditImage(false);
            alert("Action Peformed Successfuly.");
          }}
        >
          <EditImageDetailsModal />
          <div className="py-2 px-3 d-flex  justify-content-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="BACK TO UNITS"
              onClick={() => setOpenEditImage(false)}
            />

            <ETButton
              variant={"primary"}
              buttonText="SAVE"
              onClick={() => { }}
            />
          </div>
        </CustomPopup>
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-between footer-section-button-container">
          <NavLink
            to="/property-units/units/residential"
            state={{ propId: propId, Tab: "Units" }}
          >
            <ETButton variant={"white"} buttonText="BACK TO UNITS" />
          </NavLink>

          {/* <CustomButto
            buttonText="SAVE"
                  variant={"primary"}
            width="fit-content"
          /> */}
        </div>
      </div>
    </>
  );
};
