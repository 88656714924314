import { Get, Post, Put, Delete } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

// Get properties 
//Old API with new parameters, should be replaced by GetPropertiesByLevel
export const GetAllPropertiesList = (level, divisionId, type) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_All_Property_List,
      null,
      level + "/" + divisionId + "/type/" + type + "/all"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Get properties by level
// New & updated version of Get All properties API, above API should be replaced with this. 
// type: details, basic
// sub_type: all, favorites
export const GetPropertiesByLevel = (level, level_id, type, sub_type = "all") => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Properties_GetPropertiesByLevel,
      null,
      level + "/" + level_id + "/type/" + type + "/" + sub_type
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Add property
export const PostPropertyList = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Post_Property_Add_List, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Edit property
export const PutProperty = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Put_PropertyList, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Delete property
export const DeleteProperty = (id) => {
  return new Promise((resolve, reject) => {
    Delete(Site.ET, Endpoint.ET.Delete_property_Types, null, id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Get property by Id
export const GetPropertyId = (id) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_PropertyBy_Id, null, id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Post favourite properties
export const PostSetFavouriteProperties = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.post_set_favourite_property, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Get properties by portfolio Id
export const GetPropertiesListByPortfolioId = (id) => {
  id = id == "" || id == null || id == undefined ? 0 : id;
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_property_by_portfolioId, null, id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Get property by Type
export const GetPropertiesTypes = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_Property_Types, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Get zipcode by country Id
export const GetPropertiesZipCode = (countryId) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_PropertyZipCode, null, countryId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// GetPropertyDetailByPropertyId

export const GetPropertyDetailByPropertyId = (propertyId) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_PropertyDetail_By_PropertyId, null, propertyId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

export const GetPropertiesByPortfolioId = (portfolioId) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_property_by_portfolioId, null, portfolioId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

export const GetCurrencyListByCountryId = (countryId) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_CurrencyList, null, countryId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Property - Get property setting details
export const GetPropertySettingDetails = (property_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Property_GetPropertySettingDetails,
      null,
      property_id + "/setting"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Put update property settings
export const PutUpdatePropertySettingsDetails = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Property_UpdatePropertySettingDetails, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Property - Get Status overview by propertyId
export const GetStatusOverviewByPorpertyId = (property_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Porperty_GetStatusOverviewByPorpertyId,
      null,
      property_id + "/status/overview"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Property - Get Status overview sub details
export const GetStatusOverviewSubDetails = (
  property_id,
  main_status_id,
  substatus_id
) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Porperty_GetStatusOverviewSubDetails,
      null,
      property_id +
        "/status/" +
        main_status_id +
        "/overview/" +
        substatus_id +
        "/details"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};


//Post add property
export const AddProperty = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Properties_AddProperty, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Post update property
export const UpdateProperty = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Properties_UpdateProperty, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
