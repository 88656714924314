import { useMutation, useQuery } from "react-query";
import {
  DeletePromotionLabel,
  Delete_PromotionLabel,
  GetMainStatus,
  GetPromotionLabelByLabelId,
  GetPromotionLabelByPropertyId,
  GetPromotionLabelConfigurationByPropertyId,
  GetSubStatus,
  PostAddPromotionLabel,
  PostAddPromotionLabelConfiguration,
  PutUpdatePromotionLabel,
} from "../../services/PromotionLabel";

//Promotion label - Get main status
export const useGetMainStatus = (unit_type) => {
  return useQuery(
    ["qGetMainStatus", unit_type],
    () => GetMainStatus(unit_type),
    { enabled: !!unit_type }
  );
};

//Promotion label - Get sub status
export const useGetSubStatus = (mainstatus_id) => {
  return useQuery(["qGetSubStatus", mainstatus_id], () =>
    GetSubStatus(mainstatus_id)
  );
};

//Promotion label - Get promotion label by property Id
export const useGetPromotionLabelByPropertyId = (property_id) => {
  return useQuery(
    ["qGetPromotionLabelByPropertyId", property_id],
    () => GetPromotionLabelByPropertyId(property_id),
    { enabled: !!property_id }
  );
};
//Promotion label - Get promotion label by property Id
export const useGetPromotionLabelByLabelId = (property_id, label_id) => {
  return useQuery(
    ["qGetPromotionLabelBylabelId", property_id, label_id],
    () => GetPromotionLabelByLabelId(property_id, label_id),
    { enabled: !!property_id && !!label_id }
  );
};
//Promotion label - Add promotion label
export const useAddPromotionLabel = () => {
  return useMutation(PostAddPromotionLabel);
};
//Promotion label - Update promotion label
export const usePutUpdatePromotionLabel = () => {
  return useMutation(PutUpdatePromotionLabel);
};
//Promotion label - Delete promotion label
export const useDeletePromotionLabel = (
  promotion_label_id,
  new_promotionLabel_id
) => {
  return useMutation(
    Delete_PromotionLabel(promotion_label_id, new_promotionLabel_id)
  );
};

//Promotion label - Add promotion label configuration
export const usePostAddPromotionLabelConfiguration = () => {
  return useMutation(PostAddPromotionLabelConfiguration);
};

//Promotion label - Get promotion label configuration by property Id
export const useGetPromotionLabelConfigurationByPropertyId = (property_id) => {
  return useQuery(
    ["qGetPromotionLabelConfigurationByPropertyId", property_id],
    () => GetPromotionLabelConfigurationByPropertyId(property_id),
    { enabled: !!property_id }
  );
};
