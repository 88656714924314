import React, { useState, useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { Row, Col } from "react-bootstrap";
// import AreaGroupPopup from "./AreaGroupPopup";
import MRTTheme from "../../../../../../Components/table/MRTTheme";
import {
  SortIcon,
  TableRowsStyle,
} from "../../../../../../Components/table/MRTGlobalProps";
import Menus from "../../../../../../Components/Menus/Menus";
import Toggle from "../../../../../../Components/Switch/Switch";
import CustomToolTip from "../../../../../../Components/Tooltip/CustomTooltip";
import ETButton from "../../../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../../../Components/CustomPopup";
import { H4, H3 } from "../../../../../../styles/Common.style";
import CreateNewLabelPopup from "./CreateNewLabelPopup";

const MarketingLabels = () => {
  const [showCreateLabelPopup, setShowCreateLabelPopup] = useState(false);

  const AreaGroupscolumns = useMemo(
    () => [
      {
        accessorKey: "internal_title",
        header: "Internal title",
        enableHiding: false,
        size: 150,
        enableGlobalFilter: false,
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
      },
      {
        accessorKey: "marketing_label",
        header: "Marketing label",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "tags",
        header: "Tags",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "created",
        header: "Created",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "created_by",
        header: "Created by",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "level",
        header: "Level",
        enableHiding: false,
        size: 80,
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => `${row.name} ${row.notification} `,
        header: "In use",
        enableHiding: false,
        size: 15,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <>
            <Toggle disabled={false} id={row.original._id} />
            {console.log("ROW", row)}
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="px-4 pt-2 pb-3 border-bottom">
        <Row className="mb-3">
          <div className="d-flex gap-2 align-items-center">
            <H3>Marketing labels</H3>
            <CustomToolTip text="Area groups" />
          </div>
          <span className="light-font-text">
            A marketing label is used to market a unit’s unique benefits or
            characteristics. The labels will be promoted in predefined
            placeholders on the website, Ei.DO, MyPage and through APIs. It is
            possible to create default configurations on higher levels and it is
            also possible to manually turn off a marketing label on the unit.
          </span>
        </Row>

        <Row className="pb-2">
          <Col lg={7}>
            <div className="d-flex gap-2 align-items-center">
              <H4>Marketing label no 1</H4>
              <CustomToolTip text="Area groups" />
            </div>
            <span className="light-font-text">
              Create a marketing label that can be used on units that are
              marketed on the website, Ei.DO, the API, etc. Assign the label to
              one or more tags to get it associated with units in the system.
            </span>
          </Col>
          <Col lg={5}>
            <div className="w-100 d-flex justify-content-end">
              <ETButton
                variant={"primary"}
                buttonText="CREATE NEW LABEL"
                icon={true}
                onClick={() => {
                  setShowCreateLabelPopup(true);
                }}
              />
            </div>
          </Col>
        </Row>

        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={AreaGroupscolumns}
              data={[]} //data is undefined on first render
              icons={{ ...SortIcon }}
              enableColumnActions={false}
              enableRowSelection={false}
              enableRowActions
              enableSelectAll
              enableTopToolbar={false}
              enableGlobalFilter
              globalFilterFn="contains"
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              displayColumnDefOptions={{
                "mrt-row-select": {
                  header: "",
                },
                "mrt-row-actions": {
                  header: "",
                },
              }}
              renderRowActions={({ row }) => (
                <Menus
                  itemheight={23}
                  itemwidth={23}
                  iconborderradius="4px"
                  itemcontainerwidth="200px"
                  component={
                    <div className="light-font-text">
                      <div
                        className="p-2 cursor-pointer"
                        // onClick={() => {
                        //   setUsedFor("EAG");
                        //   setShowAreaGroupPopup(true);
                        // }}
                      >
                        Edit
                      </div>
                      <hr className="hr m-0"></hr>
                      <div
                        className="p-2 cursor-pointer"
                        // onClick={() => setShowDeletePopup(true)}
                      >
                        Delete
                      </div>
                    </div>
                  }
                />
              )}
              positionActionsColumn="last"
              muiTablePaperProps={{
                elevation: 0,
                fontFamily: "Cerebri sans-light !important",
                sx: {
                  borderRadius: "0",
                  border: "none",
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                  "& td:nth-last-child(8)": {
                    padding: "16px !important",
                  },
                  "& th:nth-last-child(8)": {
                    padding: "16px !important",
                  },
                },
              }}
              muiTableBodyRowProps={{
                selected: false,
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
            />
          }
        />
      </div>

      <div className="px-4 pt-4 pb-5 border-bottom">
        <Row className="pb-2">
          <Col lg={7}>
            <div className="d-flex gap-2 align-items-center">
              <H4>Marketing label no 2</H4>
              <CustomToolTip text="Marketing label no 2" />
            </div>
            <span className="light-font-text">
              Create a marketing label that can be used on units that are
              marketed on the website, Ei.DO, the API, etc. Assign the label to
              one or more tags to get it associated with units in the system.
            </span>
          </Col>
          <Col lg={5}>
            <div className="w-100 d-flex justify-content-end">
              <ETButton
                variant={"primary"}
                buttonText="CREATE NEW LABEL"
                icon={true}
                onClick={() => {
                  setShowCreateLabelPopup(true);
                }}
              />
            </div>
          </Col>
        </Row>

        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={AreaGroupscolumns}
              data={[]} //data is undefined on first render
              icons={{ ...SortIcon }}
              enableColumnActions={false}
              enableRowSelection={false}
              enableRowActions
              enableSelectAll
              enableTopToolbar={false}
              enableGlobalFilter
              globalFilterFn="contains"
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              displayColumnDefOptions={{
                "mrt-row-select": {
                  header: "",
                },
                "mrt-row-actions": {
                  header: "",
                },
              }}
              renderRowActions={({ row }) => (
                <Menus
                  itemheight={23}
                  itemwidth={23}
                  iconborderradius="4px"
                  itemcontainerwidth="200px"
                  component={
                    <div className="light-font-text">
                      <div
                        className="p-2 cursor-pointer"
                        // onClick={() => {
                        //   setUsedFor("ENA");
                        //   setShowAreaGroupPopup(true);
                        // }}
                      >
                        Edit
                      </div>
                      <hr className="hr m-0"></hr>
                      <div
                        className="p-2 cursor-pointer"
                        // onClick={() => setShowDeletePopup(true)}
                      >
                        Delete
                      </div>
                    </div>
                  }
                />
              )}
              positionActionsColumn="last"
              muiTablePaperProps={{
                elevation: 0,
                fontFamily: "Cerebri sans-light !important",
                sx: {
                  borderRadius: "0",
                  border: "none",
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                  "& td:nth-last-child(8)": {
                    padding: "16px !important",
                  },
                  "& th:nth-last-child(8)": {
                    padding: "16px !important",
                  },
                },
              }}
              muiTableBodyRowProps={{
                selected: false,
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
            />
          }
        />
      </div>

      <div className="px-4 pt-4 pb-5">
        <Row className="pb-2">
          <Col lg={7}>
            <div className="d-flex gap-2 align-items-center">
              <H4>Marketing label no 3</H4>
              <CustomToolTip text="Marketing label no 2" />
            </div>
            <span className="light-font-text">
              Create a marketing label that can be used on units that are
              marketed on the website, Ei.DO, the API, etc. Assign the label to
              one or more tags to get it associated with units in the system.
            </span>
          </Col>
          <Col lg={5}>
            <div className="w-100 d-flex justify-content-end">
              <ETButton
                variant={"primary"}
                buttonText="CREATE NEW LABEL"
                icon={true}
                onClick={() => {
                  setShowCreateLabelPopup(true);
                }}
              />
            </div>
          </Col>
        </Row>

        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={AreaGroupscolumns}
              data={[]} //data is undefined on first render
              icons={{ ...SortIcon }}
              enableColumnActions={false}
              enableRowSelection={false}
              enableRowActions
              enableSelectAll
              enableTopToolbar={false}
              enableGlobalFilter
              globalFilterFn="contains"
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              displayColumnDefOptions={{
                "mrt-row-select": {
                  header: "",
                },
                "mrt-row-actions": {
                  header: "",
                },
              }}
              renderRowActions={({ row }) => (
                <Menus
                  itemheight={23}
                  itemwidth={23}
                  iconborderradius="4px"
                  itemcontainerwidth="200px"
                  component={
                    <div className="light-font-text">
                      <div
                        className="p-2 cursor-pointer"
                        // onClick={() => {
                        //   setUsedFor("ENA");
                        //   setShowAreaGroupPopup(true);
                        // }}
                      >
                        Edit
                      </div>
                      <hr className="hr m-0"></hr>
                      <div
                        className="p-2 cursor-pointer"
                        // onClick={() => setShowDeletePopup(true)}
                      >
                        Delete
                      </div>
                    </div>
                  }
                />
              )}
              positionActionsColumn="last"
              muiTablePaperProps={{
                elevation: 0,
                fontFamily: "Cerebri sans-light !important",
                sx: {
                  borderRadius: "0",
                  border: "none",
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                  "& td:nth-last-child(8)": {
                    padding: "16px !important",
                  },
                  "& th:nth-last-child(8)": {
                    padding: "16px !important",
                  },
                },
              }}
              muiTableBodyRowProps={{
                selected: false,
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
            />
          }
        />
      </div>

      <CreateNewLabelPopup
        open={showCreateLabelPopup}
        onHide={() => setShowCreateLabelPopup(false)}
      />
    </>
  );
};

export default MarketingLabels;

// export const AreasTableData1 = [
//   {
//     internal_title: "Campaign July",
//     marketing_label: "Move in tomorrow",
//     tags: "#2bedroom, #readytomovein",
//     created: "30 Sep, 2023",
//     created_by: "Thomas Høeg Mogensen",
//     level: "Organisation",
//     _id: Math.random(),
//   },
//   {
//     internal_title: "Campaign July",
//     marketing_label: "Move in tomorrow",
//     tags: "#2bedroom, #readytomovein",
//     created: "30 Sep, 2023",
//     created_by: "Thomas Høeg Mogensen",
//     level: "Organisation",
//     _id: Math.random(),
//   },
//   {
//     internal_title: "Campaign July",
//     marketing_label: "Move in tomorrow",
//     tags: "#2bedroom, #readytomovein",
//     created: "30 Sep, 2023",
//     created_by: "Thomas Høeg Mogensen",
//     level: "Organisation",
//     _id: Math.random(),
//   },
//   {
//     internal_title: "Campaign July",
//     marketing_label: "Move in tomorrow",
//     tags: "#2bedroom, #readytomovein",
//     created: "30 Sep, 2023",
//     created_by: "Thomas Høeg Mogensen",
//     level: "Organisation",
//     _id: Math.random(),
//   },
//   {
//     internal_title: "Campaign July",
//     marketing_label: "Move in tomorrow",
//     tags: "#2bedroom, #readytomovein",
//     created: "30 Sep, 2023",
//     created_by: "Thomas Høeg Mogensen",
//     level: "Organisation",
//     _id: Math.random(),
//   },
//   {
//     internal_title: "Campaign July",
//     marketing_label: "Move in tomorrow",
//     tags: "#2bedroom, #readytomovein",
//     created: "30 Sep, 2023",
//     created_by: "Thomas Høeg Mogensen",
//     level: "Organisation",
//     _id: Math.random(),
//   },
// ];

// export const AreasTableData2 = [
//   {
//     internal_title: "Campaign July",
//     marketing_label: "Move in tomorrow",
//     tags: "#2bedroom, #readytomovein",
//     created: "30 Sep, 2023",
//     created_by: "Thomas Høeg Mogensen",
//     level: "Organisation",
//     _id: Math.random(),
//   },
//   {
//     internal_title: "Campaign July",
//     marketing_label: "Move in tomorrow",
//     tags: "#2bedroom, #readytomovein",
//     created: "30 Sep, 2023",
//     created_by: "Thomas Høeg Mogensen",
//     level: "Organisation",
//     _id: Math.random(),
//   },
//   {
//     internal_title: "Campaign July",
//     marketing_label: "Move in tomorrow",
//     tags: "#2bedroom, #readytomovein",
//     created: "30 Sep, 2023",
//     created_by: "Thomas Høeg Mogensen",
//     level: "Organisation",
//     _id: Math.random(),
//   },
//   {
//     internal_title: "Campaign July",
//     marketing_label: "Move in tomorrow",
//     tags: "#2bedroom, #readytomovein",
//     created: "30 Sep, 2023",
//     created_by: "Thomas Høeg Mogensen",
//     level: "Organisation",
//     _id: Math.random(),
//   },
//   {
//     internal_title: "Campaign July",
//     marketing_label: "Move in tomorrow",
//     tags: "#2bedroom, #readytomovein",
//     created: "30 Sep, 2023",
//     created_by: "Thomas Høeg Mogensen",
//     level: "Organisation",
//     _id: Math.random(),
//   },
//   {
//     internal_title: "Campaign July",
//     marketing_label: "Move in tomorrow",
//     tags: "#2bedroom, #readytomovein",
//     created: "30 Sep, 2023",
//     created_by: "Thomas Høeg Mogensen",
//     level: "Organisation",
//     _id: Math.random(),
//   },
// ];

// export const AreasTableData3 = [
//   {
//     internal_title: "Campaign July",
//     marketing_label: "Move in tomorrow",
//     tags: "#2bedroom, #readytomovein",
//     created: "30 Sep, 2023",
//     created_by: "Thomas Høeg Mogensen",
//     level: "Organisation",
//     _id: Math.random(),
//   },
//   {
//     internal_title: "Campaign July",
//     marketing_label: "Move in tomorrow",
//     tags: "#2bedroom, #readytomovein",
//     created: "30 Sep, 2023",
//     created_by: "Thomas Høeg Mogensen",
//     level: "Organisation",
//     _id: Math.random(),
//   },
//   {
//     internal_title: "Campaign July",
//     marketing_label: "Move in tomorrow",
//     tags: "#2bedroom, #readytomovein",
//     created: "30 Sep, 2023",
//     created_by: "Thomas Høeg Mogensen",
//     level: "Organisation",
//     _id: Math.random(),
//   },
//   {
//     internal_title: "Campaign July",
//     marketing_label: "Move in tomorrow",
//     tags: "#2bedroom, #readytomovein",
//     created: "30 Sep, 2023",
//     created_by: "Thomas Høeg Mogensen",
//     level: "Organisation",
//     _id: Math.random(),
//   },
//   {
//     internal_title: "Campaign July",
//     marketing_label: "Move in tomorrow",
//     tags: "#2bedroom, #readytomovein",
//     created: "30 Sep, 2023",
//     created_by: "Thomas Høeg Mogensen",
//     level: "Organisation",
//     _id: Math.random(),
//   },
//   {
//     internal_title: "Campaign July",
//     marketing_label: "Move in tomorrow",
//     tags: "#2bedroom, #readytomovein",
//     created: "30 Sep, 2023",
//     created_by: "Thomas Høeg Mogensen",
//     level: "Organisation",
//     _id: Math.random(),
//   },
// ];

// Created by ----> Yash
