import { Post } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

export const Login = (loginPayload) => {
	return new Promise((resolve, reject) => {
		Post(Site.AUTH, Endpoint.AUTH.Login, null, loginPayload)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error?.response?.data });
			});
	});
};
