import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Row,
  Accordion,
  Card,
  ProgressBar,
  useAccordionButton,
  Form,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { CalcPercentage } from "../../../../utils/CalculatePercentage";
import EmptyMessage from "../../../../utils/EmptyMessage";
import FileSaver from "file-saver";
import ShowTooltip from "../../../../Components/Tooltip/ShowTooltip";

const ScreeningSection = ({
  filteredScreeningTemplateQnAData,
  getScreeningTemplateQuestionData,
  screeningActor,
  screening_question,
  eventKey,
  data,
}) => {
  //------------------validation-------------------------//
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({});

  //--------------------------States---------------------//

  const [taskDetails, setTaskDetails] = useState({ totalTask: 0, taskDone: 0 });
  const [openAccordion, setOpenAccordion] = useState(false);

  // custom button for screening accordion
  const [screeningAccordionToggle, setScreenAccorionToggle] = useState(true);
  function CustomToggle({ children, eventKey, status }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setScreenAccorionToggle((prev) => !prev);
    });

    return (
      <FontAwesomeIcon
        icon={`fa-regular ${
          screeningAccordionToggle ? "fa-angle-down" : "fa-angle-up"
        }`}
        className="d-block ms-auto pe-2"
        onClick={decoratedOnClick}
        size="xl"
        style={{ cursor: "pointer" }}
      />
    );
  }

  useEffect(() => {
    if (Object.keys(filteredScreeningTemplateQnAData ?? {}).length) {
      filteredScreeningTemplateQnAData?.[0]?.answers.forEach((item) => {
        // if (!fetchAnswerFromAPI[screening_question_id]) {
        //   fetchAnswerFromAPI[screening_question_id] = item?.answer_value;

        setValue(
          `${item?.screening_question_id}`,
          item?.option_value?.length == 0
            ? item?.answer_value
            : item?.answer_text
        );
      });
    }
  }, [filteredScreeningTemplateQnAData, screeningActor]);

  useEffect(() => {
    setTaskDetails((prev) => ({
      ...prev,
      taskDone: filteredScreeningTemplateQnAData?.[0]?.answers?.filter(
        (value) =>
          value?.category_name == data?.type && value?.answer_value !== ""
      )?.length,
      totalTask:
        screening_question?.steps?.[data?.type]?.questionBank?.length || 0,
    }));

    return () => {
      setTaskDetails((prev) => ({
        ...prev,
        taskDone: 0,
        totalTask: 0,
      }));
    };
  }, [screening_question, filteredScreeningTemplateQnAData]);

  //--------------download document------------//
  const handleDownloadDocument = (file_url, file_name) => {
    FileSaver.saveAs(file_url, file_name);
  };

  // useEffect(() => {
  //   if (Object.keys(getScreeningTemplateQuestionData ?? {}).length) {
  //     const formLabelSet = new Set(
  //       getScreeningTemplateQuestionData?.data.map((q) => q.category_name)
  //     ); // Getting all unique form labels

  //     const categorizedData = {};

  //     getScreeningTemplateQuestionData?.data
  //       ?.filter((value) => value.category_name == "Information")
  //       .forEach((item) => {
  //         const { category_name } = item;
  //         if (!categorizedData[category_name]) {
  //           categorizedData[category_name] = {
  //             questionBank: [],
  //           };
  //         }
  //         categorizedData[category_name]?.questionBank?.push(item);
  //       });
  //     setScreening_question({
  //       ...screening_question,
  //       steps: categorizedData?.Information?.questionBank,
  //     });
  //   }
  // }, [getScreeningTemplateQuestionData, screeningActor]);

  return (
    <div>
      <Accordion className="my-2 w-100" onSelect={() => setOpenAccordion(true)}>
        <Card className=" lite-grey-border">
          <Card.Header
            className={`${
              openAccordion ? "bg-white" : "bg-white"
            } my-2 border-bottom-0`}
          >
            <Row className="align-items-center ps-3 my-1 pe-4 gap-2 gap-sm-0">
              <Col
                className="d-flex px-0 align-items-center flex-wrap"
                sm={4}
                lg={4}
              >
                <div
                  className="d-flex justify-content-center align-items-center me-3 p-2 position-relative"
                  style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: "#d5d7e3",
                    borderRadius: "5px",
                  }}
                >
                  <FontAwesomeIcon size="lg" icon={data.icon} />

                  <FontAwesomeIcon
                    size="lg"
                    className="position-absolute"
                    icon={
                      CalcPercentage(
                        taskDetails?.totalTask,
                        taskDetails?.taskDone
                      ) == 100
                        ? "fa-solid fa-circle-check"
                        : ""
                    }
                    style={{
                      top: "-6px",
                      right: "-10px",
                      // color: data.updateIconColor,
                      color: "#54A64D",
                      background: "white",
                      borderRadius: "10px",
                      border: "2px solid #F7F8FA",
                      width: "19px",
                      height: "19px",
                    }}
                  />
                </div>
                <div className="lh-1">
                  <h4 className="mb-0 form-label-title fs-7">{data.type}</h4>
                  <span className="form-label-title fs-12">
                    {/* {data.accordionStatus !== ""
                      ? data.accordionStatus + ": "
                      : ""} */}
                  </span>
                  <span className="normal-font-text fs-12 grey-text">
                    {/* {data.accordionStatusInfo} */}
                  </span>
                </div>
              </Col>
              <Col className="px-0 pt-3" sm={8} lg={4}>
                <div>
                  <ProgressBar
                    className="lead-loader-color"
                    // now={(data.taskDone / data.totalTask) * 100}
                    now={CalcPercentage(
                      taskDetails?.totalTask,
                      taskDetails?.taskDone
                    )}
                  />
                  <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <div>
                      <p className="d-inline-block mb-0 me-2 fs-12">
                        <span className="form-label-title fs-12">
                          {taskDetails?.taskDone || 0}
                        </span>
                        <span style={{ color: "#999" }}> / </span>
                        <span className="form-label-title fs-12">
                          {taskDetails?.totalTask || 0}
                        </span>
                      </p>
                      <span
                        className="form-label-title fs-12"
                        style={{ color: "#999" }}
                      >
                        Total task
                      </span>
                    </div>
                    <div>
                      <p className="form-label-title fs-12 mb-0">
                        {CalcPercentage(
                          taskDetails?.totalTask,
                          taskDetails?.taskDone
                        )}
                        &nbsp;%
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="px-0" xs={8} lg={3}>
                {/* {data.btnName !== "" && (
                  // <LightTooltip
                  //   title={
                  //     data.validate
                  //       ? "Identification validated by Max Wildersans Brentford 18 Mar, 2023 at 16:42"
                  //       : ""
                  //   }
                  // >
                    <Button
                      className="d-block mx-auto text-uppercase shadow-none text-dark fw-bold p-0 fs-12"
                      style={{
                        background: data.validate ? "#ddf1e7" : "white",
                        border: data.validate
                          ? "1px solid #77C7A1"
                          : "1px solid #D1D1D1",
                        width: "90px",
                        height: "30px",
                      }}
                    >
                      {data.btnName}
                    </Button>
                  // </LightTooltip>
                )} */}
              </Col>
              <Col className="px-0" xs={4} lg={1}>
                <CustomToggle eventKey={eventKey} status={data.status}>
                  {data.status}
                </CustomToggle>
              </Col>
            </Row>
          </Card.Header>
          <Accordion.Collapse
            className={`${openAccordion ? "background-primary" : ""} mt-1 `}
            eventKey={eventKey}
          >
            <Card.Body
              className="px-4"
              style={{ borderTop: "1px solid #f0f0f0", borderRadius: "0px" }}
            >
              {screening_question?.steps?.[data?.type]?.questionBank?.length !==
                0 &&
              screening_question?.steps?.[data?.type]?.questionBank?.length !==
                undefined ? (
                <Form>
                  {screening_question?.steps?.[data?.type]?.questionBank?.map(
                    (data, i) => (
                      <Row
                        key={i}
                        className="d-flex align-items-center radius-6 py-2 my-2 mx-0 gap-3 gap-xl-0 "
                        style={{ backgroundColor: "#F9FAFA" }}
                      >
                        <Col lg={6}>{data?.label_name}:</Col>

                        <Col lg={6} className="py-2">
                          {data?.question_type_name !== "file" ? (
                            <Controller
                              control={control}
                              {...register(`${data?.screening_question_id}`)}
                              rules={{
                                required: false,
                              }}
                              render={({ field: { onChange, value } }) => (
                                <div className="form-input">
                                  <input
                                    disabled
                                    type="text"
                                    placeholder={data?.label_name}
                                    id={data?.screening_question_id?.toString()}
                                    value={value}
                                    onChange={(e) => {
                                      // field.onChange(e);
                                      // clearErrors("firstname");
                                    }}
                                  />
                                  <label
                                    htmlFor={data?.screening_question_id?.toString()}
                                  >
                                    {data?.label_name}
                                  </label>
                                </div>
                              )}
                            />
                          ) : (
                            <div className="d-flex justify-content-between">
                              <span>
                                {filteredScreeningTemplateQnAData?.[0]?.answers?.filter(
                                  (val) =>
                                    val?.screening_question_id ==
                                    data?.screening_question_id
                                )?.[0]?.answer_value !== ""
                                  ? filteredScreeningTemplateQnAData?.[0]?.answers?.filter(
                                      (val) =>
                                        val?.screening_question_id ==
                                        data?.screening_question_id
                                    )?.[0]?.answer_value
                                  : "-"}
                              </span>
                              {filteredScreeningTemplateQnAData?.[0]?.answers?.filter(
                                (val) =>
                                  val?.screening_question_id ==
                                  data?.screening_question_id
                              )?.[0]?.answer_value !== "" && (
                                <span className="d-flex flex-row gap-2">
                                  <ShowTooltip
                                    tooltipLabel={
                                      <Button
                                        className="shadow-none p-0 screening-accordion-icons"
                                        onClick={() =>
                                          handleDownloadDocument(
                                            filteredScreeningTemplateQnAData?.[0]?.answers?.filter(
                                              (val) =>
                                                val?.screening_question_id ==
                                                data?.screening_question_id
                                            )?.[0]?.file_url,
                                            filteredScreeningTemplateQnAData?.[0]?.answers?.filter(
                                              (val) =>
                                                val?.screening_question_id ==
                                                data?.screening_question_id
                                            )?.[0]?.answer_value
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-line" />
                                      </Button>
                                    }
                                    tooltopWidth={75}
                                    tooltipContent={
                                      <div className="fs-12">Download</div>
                                    }
                                  />
                                </span>
                              )}
                            </div>
                          )}
                        </Col>
                      </Row>
                    )
                  )}
                </Form>
              ) : (
                <EmptyMessage />
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

export default ScreeningSection;
