import React, { useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import ETButton from "../../../../../Components/Buttons/ETButton";
import CustomToolTip from "../../../../../Components/Tooltip/CustomTooltip";
import { H3, H5 } from "../../../../../styles/Common.style";
import {
  Data,
  cancelReasonsData,
  followUpStatusData,
  notIntrestedData,
} from "./StaticData";
import { DemographicsData } from "./StaticData";
import { SearchStyle } from "../../../../../Components/table/MRTGlobalProps";
import { SearchIcon } from "../../../../../Components/table/MRTGlobalProps";
import { paginationoptions } from "../../../../../Components/table/MRTGlobalProps";
import Toggle from "../../../../../Components/Switch/Switch";
import MRTTheme from "../../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";
import Menus from "../../../../../Components/Menus/Menus";
import CustomPopup from "../../../../../Components/CustomPopup";
import NormalTextField from "../../../../../Components/GroupText/NormalTextField";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Checkedbox } from "../../../../../assets/icons/icons";
import CustomCheckBox from "../../../../../Components/Checkbox/Checkbox";
import { REQUIRED_ERR } from "../../../../../utils/validationConstants";
const Customers = () => {
  const [createNewPropertyQuestion, setCreateNewPropertyQuestion] =
    useState(false);

  const [createNewDemoGraphicQuestion, setCreateNewDemographicQuestion] =
    useState(false);

  const [followUpStatus, setFollowUpStatus] = useState(false);
  const [notInterested, setNotInterest] = useState(false);

  const {
    register,
    getValues,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      addStep: [
        {
          mainStatus: "",
          subStatus: "",
          promotionLabel: "",
        },
      ],
    },
  });

  // CreateNewQuestion
  const {
    control: controlCreateNewQuestion,
    register: registerCreateNewQuestion,
    handleSubmit: handleSubmitCreateNewQuestion,
    clearErrors: clearErrorsCreateNewQuestion,
    getValues: getValuesCreateNewQuestion,
    reset: resetCreateNewQuestion,
    formState: { errors: errorsCreateNewQuestion },
  } = useForm({
    question: "",
  });

  // Not interest
  const {
    control: controlNotInterest,
    register: registerNotInterest,
    handleSubmit: handleSubmitNotInterest,
    clearErrors: clearErrorsNotInterest,
    getValues: getValuesNotInterest,
    reset: resetNotInterest,
    formState: { errors: errorsNotInterest },
  } = useForm({
    response: "",
  });
  // Follow up status
  const {
    control: controlFollowUpStatus,
    register: registerFollowUpStatus,
    handleSubmit: handleSubmitFollowUpStatus,
    clearErrors: clearErrorsFollowUpStatus,
    getValues: getValuesFollowUpStatus,
    reset: resetFollowUpStatus,
    formState: { errors: errorsFollowUpStatus },
  } = useForm({
    status: "",
  });
  // console.log("errorsCreateNewQuestion", errorsCreateNewQuestion);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "addStep",
  });
  const handleAddStepButton = () => {
    append({
      choices: "",
    });
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "InUse",
        header: "In use",
        size: 50,
        Cell: ({ row }) => (
          <div>
            <Toggle
            // id={row?.original?.InUse}
            // checked={row?.original?.InUse == 1 ? true : false}
            />
          </div>
        ),
      },

      {
        accessorKey: "Question",
        header: "Question",
        size: 120,
      },
      {
        accessorKey: "Type",
        header: "Type",
        size: 120,
      },
      {
        accessorKey: "Created",
        header: "Created",
        size: 100,
      },
      {
        accessorKey: "CreatedBy",
        header: "Created by",
        size: 80,
      },
    ],
    []
  );
  const Dempgraphicscolumns = useMemo(
    () => [
      {
        accessorKey: "InUse",
        header: "In use",
        size: 50,
        Cell: ({ row }) => (
          <div>
            <Toggle
            // id={row?.original?.InUse}
            // checked={row?.original?.InUse == 1 ? true : false}
            />
          </div>
        ),
      },

      {
        accessorKey: "Question",
        header: "Question",
        size: 120,
      },
      {
        accessorKey: "Choices",
        header: "Choices",
        size: 120,
      },

      {
        accessorKey: "Type",
        header: "Type",
        size: 120,
      },
      {
        accessorKey: "Created",
        header: "Created",
        size: 100,
      },
      {
        accessorKey: "CreatedBy",
        header: "Created by",
        size: 80,
      },
    ],
    []
  );
  const followUpStatuscolumns = useMemo(
    () => [
      {
        accessorKey: "inUse",
        header: "In use",
        size: 50,
        Cell: ({ row }) => (
          <div>
            <Toggle
            // id={row?.original?.inUse}
            // checked={row?.original?.inUse == 1 ? true : false}
            />
          </div>
        ),
      },

      {
        accessorKey: "status",
        header: "Status",
        size: 120,
      },
      {
        accessorKey: "type",
        header: "Type",
        size: 120,
      },

      {
        accessorKey: "created",
        header: "Created",
        size: 100,
      },
      {
        accessorKey: "created_by",
        header: "Created by",
        size: 80,
      },
      {
        accessorKey: "level",
        header: "Level",
        size: 80,
      },
    ],
    []
  );
  const notInterestedColumns = useMemo(
    () => [
      {
        accessorKey: "inUse",
        header: "In use",
        size: 50,
        Cell: ({ row }) => (
          <div>
            <Toggle
              id={row?.original?.inUse}
              checked={row?.original?.inUse == 1 ? true : false}
            />
          </div>
        ),
      },

      {
        accessorKey: "reason",
        header: "Reason",
        size: 180,
      },
      {
        accessorKey: "type",
        header: "Type",
        size: 120,
      },

      {
        accessorKey: "created",
        header: "Created",
        size: 150,
      },
      {
        accessorKey: "created_by",
        header: "Created by",
        size: 80,
      },
      {
        accessorKey: "level",
        header: "Level",
        size: 80,
      },
      {
        accessorKey: "public",
        header: "Public",
        size: 50,
        muiTableBodyCellProps: {
          // align: "center",
          sx: {
            paddingRight: "0px!important",
          },
        },
        Cell: ({ row }) => (
          <div className="p-0">
            <CustomCheckBox
            // id={row?.original?.public}
            // checked={row?.original?.public == 1 ? true : false}
            />
          </div>
        ),
      },
    ],
    []
  );
  const cancelReasonsColumns = useMemo(
    () => [
      {
        accessorKey: "inUse",
        header: "In use",
        size: 50,
        Cell: ({ row }) => (
          <div>
            <Toggle
            // id={row?.original?.inUse}
            // checked={row?.original?.inUse == 1 ? true : false}
            />
          </div>
        ),
      },

      {
        accessorKey: "reason",
        header: "Reason",
        size: 180,
      },
      {
        accessorKey: "type",
        header: "Type",
        size: 120,
      },

      {
        accessorKey: "created",
        header: "Created",
        size: 150,
      },
      {
        accessorKey: "created_by",
        header: "Created by",
        size: 80,
      },
      {
        accessorKey: "level",
        header: "Level",
        size: 80,
      },
      {
        accessorKey: "public",
        header: "Public",
        size: 50,
        Cell: ({ row }) => (
          <div className="p-0">
            <CustomCheckBox
            // id={row?.original?.public}
            // checked={row?.original?.public == 1 ? true : false}
            />
          </div>
        ),
        muiTableBodyCellProps: {
          // align: "center",
          sx: {
            paddingRight: "0px!important",
          },
        },
      },
    ],
    []
  );

  const handleQuestion = (data) => {
    console.log("question", data);
  };
  const handleFollowUpStatus = (data) => {
    console.log("handleFollowUpStatus", data);
  };
  const handleNotInterest = (data) => {
    console.log("handleNotInterest", data);
  };

  const renderEmptyRowsFallback = () => (
    <div className="d-flex justify-content-center w-100 grey-text py-4 light-font-text">
      <div>You do not have any data yet.</div>
    </div>
  );

  return (
    <div>
      <div className="p-4 border-bottom">
        <div className="">
          {" "}
          <Row>
            <Col xl={6}>
              <div className="d-flex jusify-content-start align-items-start gap-2 mb-4">
                <H3>Customers</H3>
                <div>
                  {" "}
                  <CustomToolTip size="sm" text="Customers" />
                </div>
              </div>
              <div className="d-flex jusify-content-start align-items-start gap-2">
                <H5>Heard about the property questions</H5>
                <div>
                  {" "}
                  <CustomToolTip
                    size="sm"
                    text="Heard about the property questions"
                  />
                </div>
              </div>
              <div className="light-font-text">
                Here is a list of all the questions that can be used in the
                "Heard about the project" category. The questions can be
                displayed in the Dashboard.
              </div>
            </Col>

            <Col
              xl={{ offset: 4, span: 2 }}
              md={6}
              className="ps-lg-0 d-flex justify-content-end align-items-center"
            >
              <ETButton
                variant={"primary"}
                buttonText="CREATE NEW"
                icon={true}
                onClick={() => {
                  setCreateNewPropertyQuestion(true);
                }}
              />
            </Col>
          </Row>
          <MRTTheme
            colorVariantsForTableRow={["gray"]}
            colorVariantsForTableHead={["white"]}
            table={
              <MaterialReactTable
                columns={columns}
                data={Data ?? []}
                enableFilterMatchHighlighting={true}
                enableGlobalFilter
                renderEmptyRowsFallback={renderEmptyRowsFallback}
                enableMultiRowSelection={false}
                enableRowSelection={false}
                globalFilterFn="contains"
                enableRowActions={true}
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "",
                    size: 50,
                  },
                }}
                renderRowActions={({ row, table }) => (
                  <Menus
                    itemheight={23}
                    itemwidth={23}
                    iconborderradius="4px"
                    itemcontainerwidth="172px"
                    component={
                      row.original.Type == "Manually added" ? (
                        <div
                          className="addpropertyCountryHandle "
                          style={{ fontSize: "14px", width: "198px" }}
                        >
                          <div className="addpropContent px-3 py-2 ">
                            <span
                              className="ms-1"
                              style={{
                                fontFamily: "Cerebri sans",
                                fontSize: "14px",
                                fontweight: "300",
                                alignItems: "center",
                              }}
                            >
                              deactivate
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="addpropertyCountryHandle "
                          style={{ fontSize: "14px", width: "198px" }}
                        >
                          <div className="addpropContent px-3 py-2 ">
                            <span
                              className="ms-1"
                              style={{
                                fontFamily: "Cerebri sans",
                                fontSize: "14px",
                                fontweight: "300",
                                alignItems: "center",
                              }}
                            >
                              deactivate
                            </span>
                          </div>
                          <hr className="m-0" />
                          <div className="addpropContent px-3 py-2 ">
                            <span
                              className="ms-1"
                              style={{
                                fontFamily: "Cerebri sans",
                                fontSize: "14px",
                                fontweight: "300",
                                alignItems: "center",
                              }}
                            >
                              Edit question
                            </span>
                          </div>
                          <hr className="m-0" />
                          <div className="addpropContent px-3 py-2 ">
                            <span
                              className="ms-1"
                              style={{
                                fontFamily: "Cerebri sans",
                                fontSize: "14px",
                                fontweight: "300",
                                alignItems: "center",
                              }}
                            >
                              Delete
                            </span>
                          </div>
                        </div>
                      )
                    }
                  />
                )}
                muiTablePaperProps={{
                  elevation: 0,
                  fontFamily: "Cerebri sans-light !important",
                  sx: {
                    borderRadius: "0",
                    border: "none",
                    table: {
                      borderCollapse: "separate",
                      borderSpacing: "0 5px",
                    },
                  },
                }}
                enableColumnActions={false}
                muiSearchTextFieldProps={{
                  ...SearchStyle,

                  InputProps: {
                    ...SearchIcon,
                  },
                }}
                muiTablePaginationProps={{
                  ...paginationoptions,
                }}
                muiTableContainerProps={{
                  sx: {
                    padding: "0px !important",
                  },
                }}
                initialState={{
                  showGlobalFilter: true,
                  showColumnFilters: false,
                }}
                renderTopToolbar={false}
                positionActionsColumn="last"
                positionGlobalFilter="left"
              />
            }
          />
        </div>
      </div>

      <div className="p-4 border-bottom">
        <div className="">
          {" "}
          <Row>
            <Col xl={6} className="pe-lg-0">
              <div className="d-flex jusify-content-start align-items-start gap-2">
                <H5>Customer demographics questions</H5>
                <div>
                  {" "}
                  <CustomToolTip
                    size="sm"
                    text="Customer demographics questions"
                  />
                </div>
              </div>
              <div className="light-font-text">
                Here is a list of all the questions that can be used in the
                "Customer demographics" category. The questions can be displayed
                in the Dashboard.
              </div>
            </Col>

            <Col
              xl={{ offset: 4, span: 2 }}
              md={6}
              className="ps-lg-0 d-flex justify-content-end align-items-center"
            >
              <ETButton
                variant={"primary"}
                buttonText="CREATE NEW"
                icon={true}
                onClick={() => {
                  setCreateNewDemographicQuestion(true);
                }}
              />
            </Col>
          </Row>
          <MRTTheme
            colorVariantsForTableRow={["gray"]}
            colorVariantsForTableHead={["white"]}
            table={
              <MaterialReactTable
                columns={Dempgraphicscolumns}
                data={DemographicsData ?? []}
                enableFilterMatchHighlighting={true}
                enableGlobalFilter
                renderEmptyRowsFallback={renderEmptyRowsFallback}
                enableMultiRowSelection={false}
                enableRowSelection={false}
                globalFilterFn="contains"
                enableRowActions={true}
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "",
                    size: 50,
                  },
                }}
                renderRowActions={({ row, table }) => (
                  <Menus
                    itemheight={23}
                    itemwidth={23}
                    iconborderradius="4px"
                    itemcontainerwidth="172px"
                    component={
                      row.original.Type == "Manually added" ? (
                        <div
                          className="addpropertyCountryHandle "
                          style={{ fontSize: "14px", width: "198px" }}
                        >
                          <div className="addpropContent px-3 py-2 ">
                            <span
                              className="ms-1"
                              style={{
                                fontFamily: "Cerebri sans",
                                fontSize: "14px",
                                fontweight: "300",
                                alignItems: "center",
                              }}
                            >
                              deactivate
                            </span>
                          </div>
                          <hr className="m-0" />
                          <div className="addpropContent px-3 py-2 ">
                            <span
                              className="ms-1"
                              style={{
                                fontFamily: "Cerebri sans",
                                fontSize: "14px",
                                fontweight: "300",
                                alignItems: "center",
                              }}
                            >
                              Edit question
                            </span>
                          </div>
                          <hr className="m-0" />
                          <div className="addpropContent px-3 py-2 ">
                            <span
                              className="ms-1"
                              style={{
                                fontFamily: "Cerebri sans",
                                fontSize: "14px",
                                fontweight: "300",
                                alignItems: "center",
                              }}
                            >
                              Delete
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="addpropertyCountryHandle "
                          style={{ fontSize: "14px", width: "198px" }}
                        >
                          <div className="addpropContent px-3 py-2 ">
                            <span
                              className="ms-1"
                              style={{
                                fontFamily: "Cerebri sans",
                                fontSize: "14px",
                                fontweight: "300",
                                alignItems: "center",
                              }}
                            >
                              deactivate
                            </span>
                          </div>
                        </div>
                      )
                    }
                  />
                )}
                muiTablePaperProps={{
                  elevation: 0,
                  fontFamily: "Cerebri sans-light !important",
                  sx: {
                    borderRadius: "0",
                    border: "none",
                    table: {
                      borderCollapse: "separate",
                      borderSpacing: "0 5px",
                    },
                  },
                }}
                enableColumnActions={false}
                muiSearchTextFieldProps={{
                  ...SearchStyle,

                  InputProps: {
                    ...SearchIcon,
                  },
                }}
                muiTablePaginationProps={{
                  ...paginationoptions,
                }}
                muiTableContainerProps={{
                  sx: {
                    padding: "0px !important",
                  },
                }}
                initialState={{
                  showGlobalFilter: true,
                  showColumnFilters: false,
                }}
                renderTopToolbar={false}
                positionActionsColumn="last"
                positionGlobalFilter="left"
              />
            }
          />
        </div>
      </div>
      {/* Follow up status */}
      <div className="p-4 border-bottom">
        <div className="">
          {" "}
          <Row>
            <Col xl={6} className="pe-lg-0">
              <div className="d-flex jusify-content-start align-items-start gap-2">
                <H5>Follow up status</H5>
                <div>
                  {" "}
                  <CustomToolTip size="sm" text="Follow up status" />
                </div>
              </div>
              <div className="light-font-text">
                Here is a list of all the statuses that can be used in the
                "Follow up status". The statuses can be chosen in the Follow up
                flow.
              </div>
            </Col>

            <Col
              xl={{ offset: 4, span: 2 }}
              md={6}
              className="ps-lg-0 d-flex justify-content-end align-items-center"
            >
              <ETButton
                variant={"primary"}
                buttonText="CREATE NEW"
                icon={true}
                onClick={() => {
                  setFollowUpStatus(true);
                }}
              />
            </Col>
          </Row>
          <MRTTheme
            colorVariantsForTableRow={["gray"]}
            colorVariantsForTableHead={["white"]}
            table={
              <MaterialReactTable
                columns={followUpStatuscolumns}
                data={followUpStatusData ?? []}
                enableFilterMatchHighlighting={true}
                enableGlobalFilter
                renderEmptyRowsFallback={renderEmptyRowsFallback}
                enableMultiRowSelection={false}
                enableRowSelection={false}
                globalFilterFn="contains"
                enableRowActions={true}
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "",
                    size: 50,
                  },
                }}
                renderRowActions={({ row, table }) => (
                  <Menus
                    itemheight={23}
                    itemwidth={23}
                    iconborderradius="4px"
                    itemcontainerwidth="172px"
                    component={
                      row.original.Type == "Manually added" ? (
                        <div
                          className="addpropertyCountryHandle "
                          style={{ fontSize: "14px", width: "198px" }}
                        >
                          <div className="addpropContent px-3 py-2 ">
                            <span
                              className="ms-1"
                              style={{
                                fontFamily: "Cerebri sans",
                                fontSize: "14px",
                                fontweight: "300",
                                alignItems: "center",
                              }}
                            >
                              deactivate
                            </span>
                          </div>
                          <hr className="m-0" />
                          <div className="addpropContent px-3 py-2 ">
                            <span
                              className="ms-1"
                              style={{
                                fontFamily: "Cerebri sans",
                                fontSize: "14px",
                                fontweight: "300",
                                alignItems: "center",
                              }}
                            >
                              Edit question
                            </span>
                          </div>
                          <hr className="m-0" />
                          <div className="addpropContent px-3 py-2 ">
                            <span
                              className="ms-1"
                              style={{
                                fontFamily: "Cerebri sans",
                                fontSize: "14px",
                                fontweight: "300",
                                alignItems: "center",
                              }}
                            >
                              Delete
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="addpropertyCountryHandle "
                          style={{ fontSize: "14px", width: "198px" }}
                        >
                          <div className="addpropContent px-3 py-2 ">
                            <span
                              className="ms-1"
                              style={{
                                fontFamily: "Cerebri sans",
                                fontSize: "14px",
                                fontweight: "300",
                                alignItems: "center",
                              }}
                            >
                              deactivate
                            </span>
                          </div>
                        </div>
                      )
                    }
                  />
                )}
                muiTablePaperProps={{
                  elevation: 0,
                  fontFamily: "Cerebri sans-light !important",
                  sx: {
                    borderRadius: "0",
                    border: "none",
                    table: {
                      borderCollapse: "separate",
                      borderSpacing: "0 5px",
                    },
                  },
                }}
                enableColumnActions={false}
                muiSearchTextFieldProps={{
                  ...SearchStyle,

                  InputProps: {
                    ...SearchIcon,
                  },
                }}
                muiTablePaginationProps={{
                  ...paginationoptions,
                }}
                muiTableContainerProps={{
                  sx: {
                    padding: "0px !important",
                  },
                }}
                initialState={{
                  showGlobalFilter: true,
                  showColumnFilters: false,
                }}
                renderTopToolbar={false}
                positionActionsColumn="last"
                positionGlobalFilter="left"
              />
            }
          />
        </div>
      </div>
      {/* Not Intrested */}
      <div className="p-4 border-bottom">
        <div className="">
          {" "}
          <Row>
            <Col xl={6} className="pe-lg-0">
              <div className="d-flex jusify-content-start align-items-start gap-2">
                <H5>Not interested</H5>
                <div>
                  {" "}
                  <CustomToolTip size="sm" text="Not interested" />
                </div>
              </div>
              <div className="light-font-text">
                Here is a list of all the Not interested rejection reasons that
                can be chosen by the customer.
              </div>
            </Col>

            <Col
              xl={{ offset: 4, span: 2 }}
              md={6}
              className="ps-lg-0 d-flex justify-content-end align-items-center"
            >
              <ETButton
                variant={"primary"}
                buttonText="CREATE NEW"
                icon={true}
                onClick={() => {
                  setNotInterest(true);
                }}
              />
            </Col>
          </Row>
          <MRTTheme
            colorVariantsForTableRow={["gray"]}
            colorVariantsForTableHead={["white"]}
            table={
              <MaterialReactTable
                columns={notInterestedColumns}
                data={notIntrestedData ?? []}
                enableFilterMatchHighlighting={true}
                enableGlobalFilter
                renderEmptyRowsFallback={renderEmptyRowsFallback}
                enableMultiRowSelection={false}
                enableRowSelection={false}
                globalFilterFn="contains"
                enableRowActions={true}
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "",
                    size: 50,
                    muiTableBodyCellProps: {
                      sx: {
                        paddingLeft: "0px!important",
                        marginRight: "10px !important",
                      },
                    },
                  },
                }}
                renderRowActions={({ row, table }) => (
                  <div>
                    <Menus
                      itemheight={23}
                      itemwidth={23}
                      iconborderradius="4px"
                      itemcontainerwidth="172px"
                    />
                  </div>
                )}
                muiTablePaperProps={{
                  elevation: 0,
                  fontFamily: "Cerebri sans-light !important",
                  sx: {
                    borderRadius: "0",
                    border: "none",
                    table: {
                      borderCollapse: "separate",
                      borderSpacing: "0 5px",
                    },
                  },
                }}
                enableColumnActions={false}
                muiSearchTextFieldProps={{
                  ...SearchStyle,

                  InputProps: {
                    ...SearchIcon,
                  },
                }}
                muiTablePaginationProps={{
                  ...paginationoptions,
                }}
                muiTableContainerProps={{
                  sx: {
                    padding: "0px !important",
                  },
                }}
                initialState={{
                  showGlobalFilter: true,
                  showColumnFilters: false,
                }}
                renderTopToolbar={false}
                positionActionsColumn="last"
                positionGlobalFilter="left"
              />
            }
          />
        </div>
      </div>
      {/* Cancel reasons */}
      <div className="p-4 ">
        <div className="">
          {" "}
          <Row>
            <Col xl={6} className="pe-lg-0">
              <div className="d-flex jusify-content-start align-items-start gap-2">
                <H5>Cancel reasons</H5>
                <div>
                  {" "}
                  <CustomToolTip size="sm" text="Cancel reasons" />
                </div>
              </div>
              <div className="light-font-text">
                Here is a list of all the cancel reasons that can be chosen by
                the customer.
              </div>
            </Col>

            <Col
              xl={{ offset: 4, span: 2 }}
              md={6}
              className="ps-lg-0 d-flex justify-content-end align-items-center"
            >
              <ETButton
                variant={"primary"}
                buttonText="CREATE NEW"
                icon={true}
                // onClick={() => {
                //   setCreateNewDemographicQuestion(true);
                // }}
              />
            </Col>
          </Row>
          <MRTTheme
            colorVariantsForTableRow={["gray"]}
            colorVariantsForTableHead={["white"]}
            table={
              <MaterialReactTable
                columns={cancelReasonsColumns}
                data={cancelReasonsData ?? []}
                enableFilterMatchHighlighting={true}
                enableGlobalFilter
                renderEmptyRowsFallback={renderEmptyRowsFallback}
                enableMultiRowSelection={false}
                enableRowSelection={false}
                globalFilterFn="contains"
                enableRowActions={true}
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "",
                    size: 50,
                    muiTableBodyCellProps: {
                      sx: {
                        paddingLeft: "0px!important",
                        marginRight: "10px !important",
                      },
                    },
                  },
                }}
                renderRowActions={({ row, table }) => (
                  <div>
                    <Menus
                      itemheight={23}
                      itemwidth={23}
                      iconborderradius="4px"
                      itemcontainerwidth="172px"
                    />
                  </div>
                )}
                muiTablePaperProps={{
                  elevation: 0,
                  fontFamily: "Cerebri sans-light !important",
                  sx: {
                    borderRadius: "0",
                    border: "none",
                    table: {
                      borderCollapse: "separate",
                      borderSpacing: "0 5px",
                    },
                  },
                }}
                enableColumnActions={false}
                muiSearchTextFieldProps={{
                  ...SearchStyle,

                  InputProps: {
                    ...SearchIcon,
                  },
                }}
                muiTablePaginationProps={{
                  ...paginationoptions,
                }}
                muiTableContainerProps={{
                  sx: {
                    padding: "0px !important",
                  },
                }}
                initialState={{
                  showGlobalFilter: true,
                  showColumnFilters: false,
                }}
                renderTopToolbar={false}
                positionActionsColumn="last"
                positionGlobalFilter="left"
              />
            }
          />
        </div>
      </div>
      {/* {CREATE NEW QUESTION POPUP} */}
      <CustomPopup
        open={createNewPropertyQuestion}
        onHide={() => setCreateNewPropertyQuestion(false)}
        title="Create new question"
        title_color="black"
        enableCrossButton={false}
        // action_button_label="YES"
        // action_button_label_color="white"
        // action_button_color="#619130"
        // cancel_button={true}
        // cancel_button_bordercolor="grey"
        // cancel_button_label="NO"
        // cancel_button_label_color="black"
        // cancel_button_color="#fff"
        // footer_color={"#F9FAFA"}
        // deletePopUpStyle={true}
        titleTooltip={true}
        titleTooltipText={"Create new question"}
      >
        <Form onSubmit={handleSubmitCreateNewQuestion(handleQuestion)}>
          <div className="p-4">
            <div className="py-2">
              <H5>Question</H5>
            </div>
            <Row>
              <Col lg={8}>
                <Controller
                  control={controlCreateNewQuestion}
                  {...registerCreateNewQuestion("question")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <div className="form-input">
                      <input
                        type="text"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        placeholder="Question (required)"
                        id="question"
                      />
                      <label htmlFor="Question">Question(required)</label>
                    </div>
                  )}
                />
              </Col>
              {errorsCreateNewQuestion?.question && (
                <p className="error-msg">{REQUIRED_ERR}</p>
              )}
            </Row>
            <div className="d-flex justify-content-start align-items-center">
              <Toggle />{" "}
              <span className="ms-3 light-font-text"> Activate </span>
            </div>
          </div>
          <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => {
                setCreateNewPropertyQuestion(false);
              }}
            />
            <ETButton variant={"primary"} buttonText="CREATE" type="submit" />
          </div>
        </Form>
      </CustomPopup>

      {/* {CREATE NEW QUESTION POPUP} */}
      {/* {CREATE NEW DEMOGRAPHIC POPUP} */}

      <CustomPopup
        open={createNewDemoGraphicQuestion}
        onHide={() => setCreateNewDemographicQuestion(false)}
        title="Create new demographic question"
        title_color="black"
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Create new question"}
      >
        <Form>
          <div className="p-4">
            <H5>Question</H5>
            <Row>
              <Col lg={8}>
                <div className="form-input my-3">
                  <input
                    type="text"
                    placeholder="Question (required)"
                    id="question"
                  />
                  <label htmlFor="Question">Question(required)</label>
                </div>
              </Col>
            </Row>
            <H5>Need choices?</H5>
            <div className=" mb-4 d-flex justify-content-start align-items-center">
              <Toggle />{" "}
              <span className="ms-3 light-font-text"> Activate </span>
            </div>
            <div className="d-flex justify-content-between align-items-end mb-1">
              <H5>Choices</H5>
              <ETButton
                buttonText={"ADD"}
                icon={true}
                variant={"primary"}
                onClick={handleAddStepButton}
              />
            </div>
            {fields?.map((item, index) => (
              <div className="mb-1">
                <Controller
                  {...register(`addStep.${index}.choices`)}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <NormalTextField
                      onClick={() => {
                        remove(index);
                      }}
                      Icons={"fa-regular fa-trash"}
                      placeholder={"Type in"}
                    />
                  )}
                />
              </div>
            ))}
          </div>
          <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => {
                setCreateNewDemographicQuestion(false);
              }}
            />
            <ETButton variant={"primary"} buttonText="CREATE" />
          </div>
        </Form>
      </CustomPopup>
      {/* {CREATE NEW DEMOGRAPHIC POPUP} */}
      {/* Create follow up status */}
      <CustomPopup
        open={followUpStatus}
        onHide={() => setFollowUpStatus(false)}
        title="Create follow up status"
        title_color="black"
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Create follow up status"}
      >
        <Form onSubmit={handleSubmitFollowUpStatus(handleFollowUpStatus)}>
          <div
            className="paragraph2-light p-4"
            style={{ borderBottom: "1px solid #F0F0F0" }}
          >
            Lorem ipsum dolor sit amet consectetur. Pretium varius faucibus
            malesuada et nec sit. Aenean faucibus feugiat nibh aliquet accumsan.
            Arcu ipsum in ut libero. Id cras sodales felis lorem nibh id
            suspendisse ac. Commodo eleifend lectus elementum dolor amet orci
            tristique viverra.
          </div>
          <div className="p-4">
            <Row>
              <div className="my-2">
                <H5>Follow up status</H5>
              </div>
              <Col lg={8}>
                <Controller
                  control={controlFollowUpStatus}
                  {...registerFollowUpStatus("status")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <div className="form-input my-1">
                      <input
                        type="text"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        placeholder="Follow up status"
                        id="Follow"
                      />
                      <label htmlFor="Status">Status</label>
                    </div>
                  )}
                />
              </Col>
              {errorsFollowUpStatus?.status && (
                <p className="error-msg">{REQUIRED_ERR}</p>
              )}
            </Row>
          </div>
          <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => {
                setFollowUpStatus(false);
              }}
            />
            <ETButton variant={"primary"} buttonText="CREATE" type="submit" />
          </div>
        </Form>
      </CustomPopup>
      {/* Create not interested response */}
      <CustomPopup
        open={notInterested}
        onHide={() => setNotInterest(false)}
        title="Create not interested response"
        title_color="black"
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Create not interested response"}
      >
        <Form onSubmit={handleSubmitNotInterest(handleNotInterest)}>
          <div
            className="paragraph2-light p-4"
            style={{ borderBottom: "1px solid #F0F0F0" }}
          >
            Lorem ipsum dolor sit amet consectetur. Pretium varius faucibus
            malesuada et nec sit. Aenean faucibus feugiat nibh aliquet accumsan.
            Arcu ipsum in ut libero. Id cras sodales felis lorem nibh id
            suspendisse ac. Commodo eleifend lectus elementum dolor amet orci
            tristique viverra.
          </div>

          {/* <div className="p-4"> */}
          <Row>
            <Col>
              <div
                className="p-4"
                style={{ borderBottom: "1px solid #D1D1D1" }}
              >
                <H5>Not interested response</H5>
                <Controller
                  control={controlNotInterest}
                  {...registerNotInterest("response")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <div className="form-input mt-3">
                      <input
                        type="text"
                        placeholder="Response"
                        id="Follow"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                      <label htmlFor="Question">Response</label>
                    </div>
                  )}
                />
                {errorsNotInterest?.response && (
                  <p className="error-msg">{REQUIRED_ERR}</p>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="p-4">
                <div className="pb-2">
                  <H5>Should this reason be public for users?</H5>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <div style={{ marginLeft: "-11px" }}>
                    <Toggle />
                  </div>
                  <div>Public reason</div>
                </div>
              </div>
            </Col>
          </Row>
          {/* </div> */}
          <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => {
                setNotInterest(false);
              }}
            />
            <ETButton variant={"primary"} buttonText="CREATE" type="submit  " />
          </div>
        </Form>
      </CustomPopup>
    </div>
  );
};

export default Customers;
