import React, { useMemo, useState } from "react";
import MRTTheme from "../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";
import { signingOrderData } from "../../overview/StaticData";
import Menus from "../../../Components/Menus/Menus";
import {
  TableRowsStyle,
  SortIcon,
} from "../../../Components/table/MRTGlobalProps";
import { Col, Row } from "react-bootstrap";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import ETButton from "../../../Components/Buttons/ETButton";
import ShowTooltip from "../../../Components/Tooltip/ShowTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ApproveScreening from "../screenings/screeningPages/approveScreenings/ApproveScreening";
import SelectReasonCancelSigningOrder from "./cancelSigningOrder/SelectReasonCancelSigningOrder";
import CustomPopup from "../../../Components/CustomPopup";
import ConfirmCancelSigningOrder from "./cancelSigningOrder/ConfirmCancelSigningOrder";
import TagToolTip from "../../../Components/Tooltip/PropertyTagToolTip";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { H3, H5 } from "../../../styles/Common.style";
import MultipleApproveScreening from "../screenings/screeningPages/approveScreenings/MultipleApproveScreening";
import CancelWizard from "../standardComponents/cancelSigningOrder/CancelWizard";
import Heart from "../../../Components/Star/Heart";

// OS = Ongoing screenings
// CS = Completed screenings
// DS = Declined screenings
// CANS = Canceled screenings
const ScreeningAccordionTable = ({
  data = [],
  usedFor = "OS",
  CustomersContextmenuItemsCondition1 = [],
  CustomersContextmenuItemsCondition2 = [],
  MainFrameMenu = [],
}) => {
  // columns used in below tables
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) =>
          `${row.unit} ${row.Id} ${row.age} ${row.phone} ${row.registration_date} ${row.email}`,
        header: "Unit",
        size: 280,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div
            className="d-flex align-items-center gap-2 ellipsis ps-1"
            style={{ width: "275px" }}
          >
            <Heart isFavorite="0" /> {row.original.unit}
          </div>
        ),
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "4px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "2px !important",
          },
        },
      },

      {
        accessorKey: "property",
        header: "Property",
        size: 140,
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => `${row.deal} ${row.responsible_agent}`,
        header: "Priority",
        size: 140,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div className="d-flex gap-2">
            <div
              className={row.original.deal == "No priority set" && "grey-text"}
            >
              {row.original.deal}
            </div>
          </div>
        ),
      },
      {
        accessorFn: (row) => `${row.active_screening}`,
        header: "Active screenings",
        size: 100,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>
            {row.original.active_screening == "0"
              ? "No"
              : row.original.active_screening}{" "}
            active
          </div>
        ),
      },
      {
        accessorKey: "screening_started",
        header: "Screening started",
        size: 200,
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => `${row.status} ${row.bg_color} `,
        header: "Status",
        size: 100,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <>
            <ShowTooltip
              arrow={false}
              tooltopWidth={500}
              padding={"0px"}
              tooltipLabel={
                <div className="d-flex align-items-center cursor-pointer gap-1">
                  <div
                    style={{
                      color: "#FFF",
                      backgroundColor: row.original.bg_color
                        ? row.original.bg_color
                        : "#d1d1d1",
                      width: "82px",
                    }}
                    className="px-1 fs-12 radius-4 d-flex justify-content-center align-items-center form-label-title"
                  >
                    {row.original.status}
                  </div>
                </div>
              }
              tooltipContent={
                <div style={{ width: "500px" }}>
                  <div className="w-100 d-flex p-3 border-bottom align-items-center justify-content-between">
                    <H5>Screening status</H5>
                    <div className="light-font-text fs-12">
                      {/* Last status change: 29 Oct, 2023 at 15:10:57 */}
                    </div>
                  </div>
                  <div className="p-3">
                    <Row>
                      <Col sm={3}>
                        <div className="form-label-title fs-12 ">Status</div>
                        <div className="d-flex align-items-center gap-2 light-font-text fs-12">
                          <div
                            style={{
                              width: "14px",
                              height: "14px",
                              borderRadius: "4px",
                              backgroundColor: row.original.bg_color
                                ? row.original.bg_color
                                : "#d1d1d1",
                            }}
                          ></div>
                          <div style={{ textTransform: "capitalize" }}>
                            {row.original.status.toLowerCase()}
                          </div>
                        </div>
                      </Col>
                      <Col sm={9}>
                        <div className="form-label-title fs-12">Reason</div>
                        <div className="light-font-text fs-12">
                          {row.original.status.toLowerCase() == "normal" ? (
                            <>
                              There is nothing about this screening at this time
                              that the system considers makes it unlikely that
                              the screening will be approved once it is
                              completed
                            </>
                          ) : row.original.status.toLowerCase() == "warning" ? (
                            <>
                              There are some things about this screening right
                              now that make the system believe that the
                              screening may not be approved when it is completed
                              or that it will not be completed within the
                              deadline.
                            </>
                          ) : (
                            <>
                              There is a critical issue with this screening
                              right now, which means that the system estimates
                              that the screening cannot be approved when it is
                              completed or that it will not be completed within
                              the timeframe.
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              }
            />
          </>
        ),
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
      },
    ],
    []
  );
  return (
    <div>
      {" "}
      <MRTTheme
        colorVariantsForTableRow={["gray"]}
        colorVariantsForTableHead={["white"]}
        table={
          <MaterialReactTable
            columns={columns}
            data={[]}
            // data={data} //data is undefined on first render
            // localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
            icons={{ ...SortIcon }}
            enableColumnActions={false}
            enableTopToolbar={false}
            enablePagination={false}
            enableRowSelection={false}
            enableRowActions
            enableFilterMatchHighlighting={true}
            enableGlobalFilter
            globalFilterFn="contains"
            initialState={{
              showGlobalFilter: true,
              showColumnFilters: false,
            }}
            enableExpandAll={false}
            positionActionsColumn="last"
            // main frame menu
            renderRowActions={({ row }) => (
              <Menus
                itemheight={23}
                itemwidth={23}
                iconborderradius="4px"
                itemcontainerwidth="180px"
                itemcontainerheight={"300px"}
                component={
                  <div className="light-font-text">
                    {MainFrameMenu?.map((item, index) => (
                      <>
                        <div
                          key={index}
                          className="p-2 cursor-pointer"
                          onClick={() => item.onClick(row?.original?.id)}
                        >
                          {item.text}
                        </div>
                        {index !== MainFrameMenu.length - 1 && (
                          <hr className="hr m-0"></hr>
                        )}
                      </>
                    ))}
                  </div>
                }
              />
            )}
            //accordion  panel section
            renderDetailPanel={({ row }) => (
              <>
                <div className="whiteContainerStyle px-4 py-3 radius-6 border ms-3 position-relative">
                  <AccordionSectionInTable
                    data={row?.original}
                    usedFor={usedFor}
                  />
                  <div
                    className="position-absolute "
                    style={{ top: "6px", right: " 6px" }}
                  >
                    <Menus
                      itemheight={23}
                      itemwidth={23}
                      iconborderradius="4px"
                      itemcontainerwidth="220px"
                      itemcontainerheight={"300px"}
                      component={
                        <div className="light-font-text">
                          {usedFor === "OS" ? (
                            <>
                              {" "}
                              {row?.original?.deal == "No priority set" ? (
                                <>
                                  {" "}
                                  {CustomersContextmenuItemsCondition1.map(
                                    (item, index) => (
                                      <>
                                        <div
                                          key={index}
                                          className="p-2 cursor-pointer"
                                          onClick={() =>
                                            item.onClick(row.original)
                                          }
                                        >
                                          {item.text}
                                        </div>
                                        {index !==
                                          CustomersContextmenuItemsCondition1.length -
                                            1 && <hr className="hr m-0"></hr>}
                                      </>
                                    )
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {CustomersContextmenuItemsCondition2.map(
                                    (item, index) => (
                                      <>
                                        <div
                                          key={index}
                                          className="p-2 cursor-pointer"
                                          onClick={() =>
                                            item.onClick(index, item)
                                          }
                                        >
                                          {item.text}
                                        </div>
                                        {index !==
                                          CustomersContextmenuItemsCondition2.length -
                                            1 && <hr className="hr m-0"></hr>}
                                      </>
                                    )
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {row?.original?.deal == "No priority set" ? (
                                <>
                                  {" "}
                                  {CustomersContextmenuItemsCondition1.map(
                                    (item, index) => (
                                      <>
                                        <div
                                          key={index}
                                          className="p-2 cursor-pointer"
                                          onClick={() => item.onClick(index)}
                                        >
                                          {item.text}
                                        </div>
                                        {index !==
                                          CustomersContextmenuItemsCondition1.length -
                                            1 && <hr className="hr m-0"></hr>}
                                      </>
                                    )
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {CustomersContextmenuItemsCondition2.map(
                                    (item, index) => (
                                      <>
                                        <div
                                          key={index}
                                          className="p-2 cursor-pointer"
                                          onClick={() => item.onClick(index)}
                                        >
                                          {item.text}
                                        </div>
                                        {index !==
                                          CustomersContextmenuItemsCondition2.length -
                                            1 && <hr className="hr m-0"></hr>}
                                      </>
                                    )
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      }
                    />
                  </div>
                </div>
              </>
            )}
            muiTableContainerProps={{
              sx: {
                padding: "0px !important",
              },
            }}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "",
                size: 50,
                muiTableBodyCellProps: {
                  sx: {
                    // padding: "0px !important",
                  },
                },
              },
              "mrt-row-expand": {
                header: "",
                size: 50,
                muiTableBodyCellProps: {
                  sx: {
                    paddingRight: "0px !important",
                  },
                },
              },
            }}
            muiTablePaperProps={{
              selected: false,
              elevation: 0,
              fontFamily: "Cerebri sans-light !important",
              sx: {
                borderRadius: "0",
                border: "none",
                // backgroundColor: "#F9FAFA",
                table: {
                  borderCollapse: "separate",
                  borderSpacing: "0 4px",
                },
              },
            }}
            muiTableHeadRowProps={{
              sx: {
                ...TableRowsStyle,
              },
            }}
          />
        }
      />
    </div>
  );
};

export default ScreeningAccordionTable;

// Accordion section in table section
const AccordionSectionInTable = ({ data, usedFor, item }) => {
  // states
  const [approveScreening, setApproveScreening] = useState(false);
  const [multipleApproveScreening, setMultipleApproveScreening] =
    useState(false);
  const [declineScreening, setDeclineScreening] = useState(false);
  const [declineStepWizard, setDeclineStepWizard] = useState(
    "SelectReasonSigningOrder"
  );
  const approveScreen = (item) => {
    item.length > 1
      ? setMultipleApproveScreening(true)
      : setApproveScreening(true);
  };

  // const DeclineScreeningOrderWizard = {
  //   SelectReasonDeclineOrder: (
  //     <SelectReasonCancelSigningOrder
  //       setStepWizard={setDeclineStepWizard}
  //       setCancelSigningPopup={setDeclineScreening}
  //       cancelReasonHeader="Select reason for decline"
  //       cancelReasonHeaderTooltip="Select reason for cancellation"
  //       cancelReasonText="When a screening is declined, you must select one or more decline reasons below. If there is no suitable reason, the option Other can be used."
  //       // customerSection={true}
  //       customerInfo="Nikolas Karpermur, Rikke Violette Nielsen"
  //       // unitSection={true}
  //       // unitInfo="Kaktus Towers, Dybbølsbro 3, 14., Door 4, 7-NW320, 112-003-002"
  //       CancelQuestionData={signingOrderData}
  //       Status="The screening is moved to the Declined screenings tab."
  //     />
  //   ),
  //   ConfirmSigningOrder: (
  //     <ConfirmCancelSigningOrder
  //       setStepWizard={setDeclineStepWizard}
  //       setCancelSigningPopup={setDeclineScreening}
  //       nextPage="SelectReasonDeclineOrder"
  //     />
  //   ),
  // };

  return (
    <Row>
      <Col>
        <Row>
          <Col xs={7}>
            <div className="d-flex ">
              <div style={{ width: "60px" }}></div>
              <div className="form-label-title fs-12 ps-3 pb-2">Customer </div>
            </div>
            <div className="d-flex ">
              <div style={{ width: "60px" }}>
                <CircularProgressbarWithChildren
                  styles={buildStyles({
                    pathColor: "#77C970",
                    trailColor: "#D9D9D9",
                    strokeLinecap: "round",
                  })}
                  value={
                    data?.progress

                    // parseInt(
                    //   getScreeningTemplateQuestionAnswerData?.data?.reduce(
                    //     (accumulator, item) =>
                    //       accumulator + item.completion_percentage,
                    //     0
                    //   ) / getScreeningTemplateQuestionAnswerData?.data?.length
                    // ) || 0
                  }
                >
                  <div
                    className="actors-section-title"
                    style={{ fontSize: 16, marginTop: -5 }}
                  >
                    <strong>
                      {/* {parseInt(
                        getScreeningTemplateQuestionAnswerData?.data?.reduce(
                          (accumulator, item) =>
                            accumulator + item?.completion_percentage,
                          0
                        ) / getScreeningTemplateQuestionAnswerData?.data?.length
                      ) || 0} */}
                      {data?.progress}%
                    </strong>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              <div className="ps-3 light-font-text">
                <div className="form-label-title">Julie Johansen</div>
                <div>+45 54 22 16 92</div>
                <div>juliejohansen@live.dk</div>
              </div>
            </div>
          </Col>
          <Col xs={5}>
            <div className="form-label-title fs-12 pb-2">Criterias</div>
            <div className="d-flex flex-wrap gap-2" style={{ width: "160px" }}>
              {data.iconData?.map((data, i) => (
                <div key={i}>
                  <CriteriaAllIcons data={data} />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Col>
      {/* space */}
      <Col>
        {usedFor == "DS" ? (
          <Row>
            <Col>
              <div className="form-label-title fs-12 pb-2">Overview </div>
              <>
                {/* Screening started: */}
                <DetailRow
                  title={"Screening started"}
                  value={data?.screening_started}
                />

                {/*Screening declined:  */}
                <DetailRow
                  title={"Screening declined"}
                  value={"6 Jul, 2023 at 15:12:32"}
                />

                {/*Declined by:  */}
                <DetailRow title={"Declined by"} value={"Frederikke Windig"} />

                {/* Decline reason: */}
                <DetailRow title={"Decline reason"}>
                  <div className="d-flex gap-2 cursor-pointer">
                    {data?.declined_reasons} {/* tooltip code hear */}
                    <TagToolTip
                      iconColor={"#888888"}
                      size={"sm"}
                      border="1px solid #F0F0F0"
                      padding={"0px"}
                      boxShadow="2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                      text={
                        <div style={{ width: "500px" }}>
                          {/* tooltip header */}
                          <div className="d-flex justify-content-between border-bottom p-3">
                            <div className="d-flex  gap-2">
                              <div className="form-label-title">
                                Decline reason
                              </div>{" "}
                              <CustomToolTip text={"Decline reason"} />
                            </div>
                            <div className="light-font-text fs-12">
                              Last status change: 29 Oct, 2023 at 15:10:57
                            </div>
                          </div>
                          {/* tooltip body */}
                          <div className="p-3">
                            {/*  Screening started: */}
                            <DetailRow
                              title={"Screening started"}
                              value={data?.screening_started}
                            />
                            {/* Screening deadline: */}
                            <DetailRow
                              title={"Screening deadline"}
                              value={"20 Jul, 2023 at 15:12:32"}
                            />
                            {/* Screening declined: */}
                            <DetailRow
                              title={"Screening declined"}
                              value={"6 Jul, 2023 at 15:12:32"}
                            />

                            {/*  Declined by: */}
                            <DetailRow
                              title={"Declined by"}
                              value={"Frederikke Windig"}
                            />
                            {/*  Decline reason: */}
                            <DetailRow
                              title={"Decline reason"}
                              value={data?.declined_reasons}
                            />
                          </div>

                          {/* detail reason section */}
                          <div className="p-3">
                            <div className="form-label-title fs-13">
                              Decline reason:
                            </div>
                            <div className="light-font-text  fs-13 d-flex align-items-center cursor-pointer gap-2 pt-2">
                              {data?.declined_reasons != "Other reason"
                                ? data?.declined_reasons
                                : "The customer had some unrealistic requests for the interior design of the home, which, unfortunately, we were not able to accommodate. Additionally, they requested a discount on the price, which I have, of course, declined."}
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </DetailRow>
              </>
            </Col>
          </Row>
        ) : usedFor == "CANS" ? (
          <Row>
            {" "}
            <Col>
              <div className="form-label-title fs-12 pb-2">Overview </div>
              {/* Screening started: */}
              <DetailRow
                title={"Screening started"}
                value={data?.screening_started}
              />

              {/* Screening canceled: */}
              <DetailRow
                title={"Screening canceled"}
                value={"6 Jul, 2023 at 15:12:32"}
              />

              {/*Canceled by:  */}
              <DetailRow title={"Canceled by"} value={"Frederikke Windig"} />

              {/* Cancellation reason: */}
              <DetailRow title={"Cancellation reason"}>
                <div className="light-font-text  fs-13 d-flex align-items-center cursor-pointer gap-2">
                  {data?.declined_reasons}

                  <TagToolTip
                    iconColor={"#888888"}
                    size={"sm"}
                    border="1px solid #F0F0F0"
                    padding={"0px"}
                    boxShadow="2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                    text={
                      <div style={{ width: "500px" }}>
                        {/* tooltip header */}
                        <div className="d-flex justify-content-between border-bottom p-3">
                          <div className="d-flex  gap-2">
                            <div className="form-label-title">
                              Decline reason
                            </div>{" "}
                            <CustomToolTip text={"Decline reason"} />
                          </div>
                          <div className="light-font-text fs-12">
                            Last status change: 29 Oct, 2023 at 15:10:57
                          </div>
                        </div>
                        {/* tooltip body */}
                        <div className="p-3">
                          {/*  Screening started: */}
                          <DetailRow
                            title={"Screening started"}
                            value={data?.screening_started}
                          />
                          {/* Screening deadline: */}
                          <DetailRow
                            title={"Screening deadline"}
                            value={"20 Jul, 2023 at 15:12:32"}
                          />
                          {/* Screening declined: */}
                          <DetailRow
                            title={"Screening declined"}
                            value={"6 Jul, 2023 at 15:12:32"}
                          />
                          {/* Canceled by*/}
                          <DetailRow
                            title={"Canceled by"}
                            value={"Frederikke Windig"}
                          />

                          {/* Cancellation reason */}
                          <DetailRow
                            title={"Cancellation reason"}
                            value={data?.declined_reasons}
                          />
                        </div>

                        {/* detail reason section */}
                        <div className="p-3">
                          <div className="form-label-title fs-13">
                            Decline reason:
                          </div>
                          <div className="light-font-text  fs-13 d-flex align-items-center cursor-pointer gap-2 pt-2">
                            {data?.declined_reasons != "Other reason"
                              ? data?.declined_reasons
                              : "The customer had some unrealistic requests for the interior design of the home, which, unfortunately, we were not able to accommodate. Additionally, they requested a discount on the price, which I have, of course, declined."}
                          </div>
                        </div>
                      </div>
                    }
                  />
                </div>
              </DetailRow>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={8}>
              <Overview data={data} />
            </Col>

            <Col xs={4}>
              <div className="form-label-title fs-12 pb-2">Action</div>
              <div className="d-flex flex-column gap-3">
                <ETButton
                  variant={"secondary"}
                  size={"sm"}
                  buttonText={"APPROVE SCREENING"}
                  onClick={() => approveScreen(true)}
                />
                <ETButton
                  variant={"white"}
                  size={"sm"}
                  buttonText={"DECLINE SCREENING"}
                  onClick={() => setDeclineScreening(true)}
                />
              </div>

              {/* used for declined wizard */}
              <CancelWizard
                title={"Decline Screening"}
                titleTooltip
                Status={
                  "The screening is moved to the Declined screenings tab."
                }
                titleTooltipText={"Decline Screening"}
                openCancelWizardModal={declineScreening}
                setOpenCancelWizardModal={setDeclineScreening}
                switchWizardScreen={declineStepWizard}
                setSwitchWizardScreen={setDeclineStepWizard}
                nextWizardScreenText={"SelectReasonSigningOrder"}
                cancelReasonHeader={"Select Reason for Decline"}
                cancelReasonText={
                  "When a screening is declined, you must select one or more decline reasons below.If there is no suitable reason, the option Other can be used."
                }
                cancelSecondPageReasonHeader={
                  "Confirmation that the screening is declined"
                }
                cancelSecondPageReasonHeaderTooltip={
                  "Confirmation that the screening is declined"
                }
                cancelReasonSecondPageFooter={"Send confirmation to"}
                cancelSecondPageReasonFooterTooltip={"Send confirmation to"}
                secondPageStatus={
                  "It’s only possible to send to MyPage if the customer has a active profile."
                }
              />
            </Col>
            <ApproveScreening
              setApproveScreening={setApproveScreening}
              approveScreening={approveScreening}
            />
            <MultipleApproveScreening
              setMultipleApproveScreening={setMultipleApproveScreening}
              multipleApproveScreening={multipleApproveScreening}
            />
          </Row>
        )}
      </Col>
    </Row>
  );
};

// used in above component to minimize code
function DetailRow({ title, value, children }) {
  return (
    <div className="d-flex">
      <div className="form-label-title fs-13" style={{ width: "36%" }}>
        {title}:
      </div>
      <div className="light-font-text fs-13">{value || children}</div>
    </div>
  );
}

// used in ongoing and completed screening overview
function Overview({ data }) {
  return (
    <div>
      <div className="form-label-title fs-12 pb-2">Overview</div>
      {renderDetail("Status", "Screening in process")}
      {renderDetail("Screening start", data?.screening_started)}
      {renderDetail("Screening deadline", "12 Jul, 2023 at 12:00")}
      {renderDetail("Responsible agent", "Max Wildersans Brentford")}
    </div>
  );

  function renderDetail(title, value) {
    return (
      <div className="d-flex">
        <div className="form-label-title w-50 fs-13">{title}</div>
        <div className="light-font-text fs-13">{value}</div>
      </div>
    );
  }
}

// criteria or category icon style container
const CriteriaAllIcons = ({ data }) => {
  return (
    <>
      <ShowTooltip
        arrow={false}
        tooltipLabel={
          <div
            style={{
              backgroundColor: `${data?.iconColor}44`,
              width: "32px",
              height: "32px",
            }}
            className="radius-4 d-flex align-items-center justify-content-center"
          >
            <FontAwesomeIcon
              icon={data?.ico_name}
              style={{ color: data?.iconColor }}
            />
          </div>
        }
        tooltipContent={
          <div>
            <div className="d-flex align-items-center p-2 border-bottom gap-2">
              <div
                style={{
                  backgroundColor: `${data?.iconColor}44`,
                  width: "32px",
                  height: "32px",
                }}
                className="radius-4 d-flex align-items-center justify-content-center"
              >
                <FontAwesomeIcon
                  icon={data?.ico_name}
                  style={{ color: data?.iconColor }}
                />{" "}
              </div>
              <span className="form-label-title">{data?.category}</span>
            </div>
            <div className="d-flex align-items-center w-100 px-2 py-3 gap-2 form-label-title fs-12">
              <div className="w-50">Question</div>
              <div className="w-50">Answer</div>
            </div>
          </div>
        }
      />
    </>
  );
};

// change log and other info
// Created by Suraj
// popups added by Anmol
