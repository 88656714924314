import React, { useEffect, useMemo, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import { InputAdornment, Menu } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkedbox, UnCheckedbox } from "../../assets/icons/icons";
import TagFilterDropDown from "../../views/settings/Tags/TagFilterDropDown";
import { usePropertyId } from "../../hooks/stores/usePropertyStore";
import { useGetUnitsByPropertyIdUpdated } from "../../hooks/queries/useUnitQueries";
import MRTTheme from "../table/MRTTheme";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../table/MRTGlobalProps";

const UnitsFilterTable = ({
  // setUnitsTableData,
  // unitsTableData,
  setSelectedUnitsId,
  // dataContainer,
}) => {
  const [rowSelection, setRowSelection] = useState([]);
  const [resetCondition, setResetCondition] = useState(false);
  const propertyId = usePropertyId((state) => state.propertyId);

  const {
    data: GetUnitsByPropertyIdData,
    refetch: GetUnitsByPropertyIdDataRefetch,
    isLoading,
  } = useGetUnitsByPropertyIdUpdated(propertyId, 0);
  const [unitsTableData, setUnitsTableData] = useState(
    GetUnitsByPropertyIdData?.data
  );
  const [dataContainer, setDataContainer] = useState(
    GetUnitsByPropertyIdData?.data
  );

  useEffect(() => {
    setSelectedUnitsId(Object.keys(rowSelection));
  }, [rowSelection]);

  useEffect(() => {
    if (resetCondition == true) {
      setUnitsTableData(dataContainer);
    }
  }, [resetCondition]);
  useEffect(() => {
    if (Object.keys(GetUnitsByPropertyIdData ?? {}).length) {
      setUnitsTableData(GetUnitsByPropertyIdData?.data);
    }
  }, [GetUnitsByPropertyIdData]);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "calculated_unit_id",
        header: "No.",
        size: 100,
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
      },
      {
        accessorFn: (row) =>
          `${row?.street_name} ${row?.house_number}, ${row?.side_door}`,
        header: "Address",
        size: 180,
      },
      {
        accessorKey: "housing_type_name", //normal accessorKey
        header: "Type",
        size: 100,
      },
      {
        accessorKey: "alt_unit_id", //normal accessorKey
        header: "Unit no.",
        size: 80,
      },
      {
        accessorKey: "floor_number",
        header: "Floor",
        size: 50,
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorFn: (row) => `${row.area} ${row.square_measure_unit}`,
        size: 80,
        // accessorKey: "available",
        header: "Area",
        Cell: ({ cell, row }) => {
          return (
            <div>
              {row.original.area}
              <span
                dangerouslySetInnerHTML={{
                  __html: row.original.square_measure_unit,
                }}
              ></span>
            </div>
          );
        },
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "no_of_rooms",
        header: "Room",
        size: 80,
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },

      {
        accessorFn: (row) => `${row.rent} ${row.currency_id}`,
        header: "Price",
        size: 110,
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorFn: (row) =>
          `${row.mainstatus_name} ${row.mainstatus_background_color} ${row.mainstatus_font_color}`,
        size: 120,
        // accessorKey: "available",

        muiTableHeadCellProps: {
          align: "center",
        },
        header: "Status",
        Cell: ({ cell, row }) => {
          return (
            <div
              style={{
                backgroundColor: row.original.mainstatus_background_color,
                textTransform: "uppercase",
                textColor: row.original.mainstatus_font_color,
                whiteSpace: "nowrap",
                fontFamily: "Cerebri sans-medium",
              }}
              className="px-1 fs-12 radius-4 d-flex justify-content-center align-item-center w-100"
            >
              {row.original.mainstatus_name}
            </div>
          );
        },
      },
    ],
    []
  );
  const [newData, setNewData] = useState(GetUnitsByPropertyIdData?.data);
  const [originalTagData, setOriginalTagData] = useState(
    GetUnitsByPropertyIdData?.data
  );
  const ChooseFilter = [
    { Id: "1", Text: "Unit No" },
    { Id: "2", Text: "Status" },
  ];

  const tags = [
    { Id: "1", Text: "Not active", parent: "2" },
    { Id: "2", Text: "Available", parent: "2" },
    { Id: "3", Text: "Reserved", parent: "2" },
    { Id: "4", Text: "In process", parent: "2" },
    { Id: "5", Text: "Completed", parent: "2" },
    { Id: "6", Text: "Available", parent: "2" },
    { Id: "7", Text: "Total", parent: "2" },
  ];

  const dumnmyDropDownData = {
    "Unit No": "calculated_unit_id",
    Status: "tags",
  };
  const [propertag, setPropertytag] = useState("");
  const [filter, setFilter] = useState(null);
  // const [resetCondition, setResetCondition] = useState(false);
  useEffect(() => {
    if (resetCondition == true) {
      setUnitsTableData(GetUnitsByPropertyIdData?.data);
    }
  }, [resetCondition]);
  const selectdata = [];
  const [filterDropdowon, setFilterDropdowon] = useState("");
  const [filterDropData, setFilterDropData] = useState("");
  const [selectedDropdownData, setSelectedDropDownData] = useState([]);
  const [show, setShow] = useState();
  const [status, setStatus] = useState(false);
  const handleChange = (filter, ActionTypes) => {
    if (ActionTypes.action === "clear") {
      setFilter("");
    } else {
      setFilter(filter);
    }
    setPropertytag(true);
    setShow(false);
    let a = dumnmyDropDownData[filter?.label];
    let b = [...new Set(newData?.map((data) => data[a]))];
    setSelectedDropDownData(b);
    // setFilter(filter.label);
    setFilterDropdowon(ChooseFilter);

    const checkSelectedLabel = filter?.label;
    if (checkSelectedLabel == "Status") {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };
  const test = filter;
  const filteredTagdata = filter
    ? tags.filter((tag) => tag.parent === filter.value)
    : [];
  const filteredTag = selectedDropdownData;
  const [selecteddata, setSelectedData] = useState([]);
  const [closeDropdown, setCloseDropdown] = useState(false);

  const [filterKeys, setFilterKeys] = useState({
    calculated_unit_id: [],
    mainstatus_name: [],
  });
  // Function to push data into a specific filter key
  const pushDataToFilterKey = (key, value) => {
    if (filterKeys[key]?.includes(value)) {
    } else {
      setFilterKeys((prevFilterKeys) => ({
        ...prevFilterKeys,
        [key]: [...prevFilterKeys[key], value],
      }));
    }
  };
  const multiPropsFilter = (unitsTableData, filters) => {
    const filterKeys = Object?.keys(filters);
    const filterdata = originalTagData?.filter((product) => {
      return filterKeys?.every((key) => {
        if (!filters[key].length) return true;
        // Loops again if product[key] is an array (for material attribute).
        if (Array?.isArray(product[key])) {
          return product[key]?.some((keyEle) => filters[key]?.includes(keyEle));
        }
        return filters[key]?.includes(product[key]);
      });
    });
    setUnitsTableData(filterdata);
  };

  const handleFilterData = (i) => {
    const selectfilterdata = filter?.label;
    const parent = ChooseFilter.find(
      (filter) => filter.Id === selectfilterdata
    )?.parent;

    console.log(selectfilterdata, "selectfilterdata");
    if (selectfilterdata == "Unit No") {
      const key = "calculated_unit_id";
      const value = i;
      pushDataToFilterKey(key, value);
    } else if (selectfilterdata === "Status") {
      const key = "mainstatus_name";
      const value = i;
      pushDataToFilterKey(key, value);
    } else {
    }

    setFilterDropData(i);

    selectdata.push({ lable: filterDropdowon, value: i });
    setSelectedData((prev) => [...prev, ...selectdata]);
  };

  // const renderEmptyRowsFallback = () => (
  //   <div className="d-flex justify-content-center w-100 grey-text py-4">
  //     <div>You do not have any package yet.</div>
  //   </div>
  // );
  const renderEmptyRowsFallback = () => {
    if (unitsTableData && unitsTableData?.length === 0) {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div>You do not have any package yet.</div>
          </>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div style={{ fontSize: "17px" }}>Sorry! No result found:(</div>
          </>
        </div>
      );
    }
  };
  // Dropdown for filter
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      style={{
        backgroundColor: "#FFF",
        color: "#000",
        width: "100%",
        display: "flex",
        height: "41px",
        border: "1px solid #D1D1D1",
        fontWeight: "300",
        justifyContent: "space-between",
        alignItems: "center",
        textDecoration: "none",
        fontSize: "14px",
        borderRadius: "8px",
      }}
      className="px-2 py-2 dropdownInput "
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        setCloseDropdown(!closeDropdown);
        // console.log(e, "Eeeeeeeeeeee");
      }}
    >
      <div className="d-flex justify-content-between w-100">
        <span> {children}</span>
        <span>
          <FontAwesomeIcon
            icon="fa-regular fa-chevron-down"
            size="sm"
            style={{ color: "#000000" }}
          />
        </span>
      </div>
    </a>
  ));
  return (
    <div>
      <div>
        <div>
          <MRTTheme
            colorVariantsForTableData={["odd"]}
            table={
              <MaterialReactTable
                positionToolbarAlertBanner="bottom"
                selectAllMode="all"
                // muiTablePaginationProps={{
                //   rowsPerPageOptions: [10, 25, 50, 100, 250],
                // }}

                columns={columns}
                data={unitsTableData ?? []}
                icons={{ ...SortIcon }}
                enableRowSelection
                renderEmptyRowsFallback={renderEmptyRowsFallback}
                getRowId={(row) => row.unit_id}
                onRowSelectionChange={setRowSelection}
                state={{ rowSelection }}
                enableTopToolbar={true}
                enableColumnActions={false}
                initialState={{
                  showGlobalFilter: true,
                }}
                displayColumnDefOptions={{
                  "mrt-row-select": {
                    header: "",
                    size: 50,
                    muiTableBodyCellProps: {
                      sx: {
                        paddingRight: "0px !important",
                        border: "none !important",
                      },
                    },
                  },
                }}
                muiTableHeadRowProps={{
                  sx: {
                    ...TableRowsStyle,
                  },
                }}
                muiTablePaginationProps={{
                  ...paginationoptions,
                }}
                muiSearchTextFieldProps={{
                  ...SearchStyle,

                  InputProps: {
                    ...SearchIcon,
                  },
                }}
                renderTopToolbar={({ table }) => (
                  <>
                    <Row className=" py-2 px-4">
                      <Col xl={2} md={4} className="my-1 pe-1 ">
                        <MRT_GlobalFilterTextField table={table} />
                      </Col>

                      <Col xl={2} md={4} className="my-1 pe-1">
                        <Dropdown show={closeDropdown}>
                          <Dropdown.Toggle
                            as={CustomToggle}
                            id="dropdown-custom-components"
                          >
                            Set filters
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="my-drop-menu">
                            <TagFilterDropDown
                              propertag={propertag}
                              filteredTag={filteredTag}
                              status={status}
                              filteredTagdata={filteredTagdata}
                              setUnitsTableData={setUnitsTableData}
                              multiPropsFilter={multiPropsFilter}
                              selectedDropdownData={selectedDropdownData}
                              dumnmyDropDownData={dumnmyDropDownData}
                              filterDropdowon={filterDropdowon}
                              // handleDropData={handleDropData}
                              unitsTableData={unitsTableData}
                              filterDropData={filterDropData}
                              setFilterDropData={setFilterDropData}
                              ChooseFilter={ChooseFilter}
                              handleFilterData={handleFilterData}
                              filter={filter}
                              selecteddata={selecteddata}
                              setFilter={setFilter}
                              handleChange={handleChange}
                              selectdata={selectdata}
                              filterKeys={filterKeys}
                              setFilterKeys={setFilterKeys}
                              closeDropdown={closeDropdown}
                              setCloseDropdown={setCloseDropdown}
                              setResetCondition={setResetCondition}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </>
                )}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default UnitsFilterTable;
