import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  GetConfigurationDetails,
  GetDocumentPackagesByPropertyId,
  GetDocumentType,
  GetDocumentsByUnitId,
  GetTemplateDocuments,
  PostUploadDocument,
  PostCloneDocumentPackage,
  PutUpdateDocumentConfiguration,
  PutUpdateDocumentSortOrder,
  GetGuideDocumentByPropertyId,
  PostAddGuideDocument,
  PostCopyGuideToPacakge,
  PutUpdateDocumentCompleteDetails,
  PostCloneDocument,
  PostAddDocumentPackage,
  PutUpdateDocumentBasicDetails,
  GetDocumentByLevel,
  PutUpdateDocumentInherited,
  DeleteDocumentByDocumentId,
  PostAddDocument,
} from "../../services/Documents";
import { ShareDocuments } from "../../services/Actors";

// Get Document Type
export const useGetDocumentType = (type) => {
  return useQuery(["getDocumentType", type], () => GetDocumentType(type), {
    enabled: !!type,
  });
};

// Post Upload document
export const useUploadDocument = () => {
  return useMutation(PostUploadDocument);
};

// Get Document Packages by property Id
export const useGetDocumentPackageByPropertyId = (propertyId, unitId, type) => {
  return useQuery(
    "qDocumentPackageByPropertyId",
    () => GetDocumentPackagesByPropertyId(propertyId, unitId, type),
    { enabled: !!propertyId && !!unitId && !!type }
  );
};

//Get Template Documents
export const useGetTemplateDocuments = (signing_type) => {
  return useQuery(
    ["qGetTemplateDocuments", signing_type],
    () => GetTemplateDocuments(signing_type),
    { enabled: !!signing_type }
  );
};

//Get Document configuration details
export const useGetDocumentConfigurationDetails = (docId) => {
  return useQuery(
    ["qGetDocumentConfigurationDetails", docId],
    () => GetConfigurationDetails(docId),
    { enabled: !!docId }
  );
};

//Get Document by Unit Id
export const useGetDocumentsByUnitId = (propertyId, unitId, type) => {
  return useQuery(
    ["useGetDocumentsByUnitId", propertyId, unitId, type],
    () => GetDocumentsByUnitId(propertyId, unitId, type),
    { enabled: !!propertyId && !!unitId && !!type }
  );
};

//clone document package
export const usePostCloneDocumentPackage = () => {
  return useMutation(PostCloneDocumentPackage);
};

// update Configuration in SigningPackage

export const useUpdateDocumentConfiguration = () => {
  return useMutation(PutUpdateDocumentConfiguration);
};

// document sort order

export const usePutUpdateDocumentSortOrder = () => {
  return useMutation(PutUpdateDocumentSortOrder);
};

//Get guide document by property Id
export const useGetGuideDocumentByPropertyId = (propertyId) => {
  return useQuery(
    ["qGetGuideDocumentByPropertyId", propertyId],
    () => GetGuideDocumentByPropertyId(propertyId),
    { enabled: !!propertyId }
  );
};

// Post Add guide document
export const useAddGuideDocument = () => {
  return useMutation(PostAddGuideDocument);
};
// Post Copy Guide To Pacakge
export const useCopyGuideToPacakge = () => {
  return useMutation(PostCopyGuideToPacakge);
};
// Put Update Document Complete Details

export const usePutUpdateDocumentCompleteDetails = () => {
  return useMutation(PutUpdateDocumentCompleteDetails);
};
export const useShareDocuments = () => {
  return useMutation(ShareDocuments);
};

//clone document to package
export const usePostCloneDocument = () => {
  return useMutation(PostCloneDocument);
};

//Post Add Document Package

export const usePostAddDocumentPackage = () => {
  return useMutation(PostAddDocumentPackage);
};
// Put Update Document Package Basic Details
export const usePutUpdateDocumentBasicDetails = () => {
  return useMutation(PutUpdateDocumentBasicDetails);
};

// ------------------------------- document new query-------------------//
//Get Document by Level type And Level Id
export const useGetDocumentByLevelTypeAndLevelId = (levelType, levelId) => {
  return useQuery(
    ["qGetDocumentByLevelTypeAndLevelId", levelType, levelId],
    () => GetDocumentByLevel(levelType, levelId),
    {
      enabled: !!levelType,
      enabled: !!levelId,
    }
  );
};

// Put Update Document Inherited
export const usePutUpdateDocumentInherited = () => {
  const queryClient = useQueryClient();
  return useMutation(PutUpdateDocumentInherited, {
    onSuccess: () => {
      queryClient.invalidateQueries("qGetDocumentByLevelTypeAndLevelId");
    },
  });
};

// Delete Document by document id
export const useDeleteDocumentByDocumentId = () => {
  const queryClient = useQueryClient();
  return useMutation(DeleteDocumentByDocumentId, {
    onSuccess: () => {
      queryClient.invalidateQueries("qGetDocumentByLevelTypeAndLevelId");
    },
  });
};

// Add new document
export const usePostAddDocument = () => {
  const queryClient = useQueryClient();
  return useMutation(PostAddDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries("qGetDocumentByLevelTypeAndLevelId");
    },
  });
};
