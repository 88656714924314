import React, { useState } from "react";
import { Container } from "react-bootstrap";
import ReservationConfirmation from "./reservations/ReservationConfirmation";
import ReservationSearchableForm from "./reservations/ReservationSearchableForm";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePropertyOverview } from "../../../hooks/stores/usePropertyStore";
import { GreenSpan, H2 } from "../../../styles/Common.style";

const NewReservation = () => {
  let location = useLocation();
  const { state } = location;
  let propId = state?.propId;
  const propOverview = usePropertyOverview((state) => state.propOverview);

  const [reservationWizard, setReservationWizard] = useState(
    "ReservationSeachableForm"
  );

  const [tenantName, setTenantName] = useState("");
  const ReservationWizardsPages = {
    ReservationSeachableForm: (
      <ReservationSearchableForm
        propId={propId}
        unitId={state?.unit_id}
        setReservationWizard={setReservationWizard}
        setTenantName={setTenantName}
      />
    ),
    ReservationConfirmation: (
      <ReservationConfirmation
        propId={propId}
        setReservationWizard={setReservationWizard}
        tenantName={tenantName}
      />
    ),
  };

  return (
    <>
      <Container fluid className="">
        <div className=" pt-3  fw-300 ">
          <H2 >
            {" "}
            Create new reservation
          </H2>
          <div style={{ fontFamily: " Cerebri sans", fontSize: "14px" }}>
            <NavLink
              to={"/properties"}
              className={'light-font-text'}
              style={{ textDecoration: "none", color: "#212529" }}
            >
              <span className="me-3 cursor-pointer">Properties</span>
            </NavLink>
            <span className="me-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <NavLink
              to={"/property-units/overview"}
              state={{
                propId: propOverview?.[0]?.property_id,
                Tab: "Overview",
              }}
              className={'light-font-text'}
              style={{ textDecoration: "none", color: "#212529" }}
            >
              <span className="me-3 cursor-pointer">
                {propOverview?.[0]?.propertyName}
              </span>
            </NavLink>
            <span className="me-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <NavLink
              to={"/property-units/units/residential"}
              state={{
                propId: propOverview?.[0]?.property_id,
                Tab: "Units",
              }}
              className={'light-font-text'}
              style={{ textDecoration: "none", color: "#212529" }}
            >
              <span className="me-3 cursor-pointer">Units</span>
            </NavLink>
            <span className="me-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <GreenSpan className="me-3 cursor-pointer">
              Create new reservation
            </GreenSpan>
          </div>
        </div>
        <div
          className="my-4 "
          // style={{ backgroundColor: "#F1F5F0", borderRadius: "10px" }}
        >
          {ReservationWizardsPages[reservationWizard]}
        </div>
      </Container>
    </>
  );
};

export default NewReservation;
