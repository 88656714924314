// last updated by -- Suraj
import React from "react";
import { PropertyTagToolTip } from "../../assets/icons/icons";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const defaultTheme = createTheme();
export default function TagToolTip({
  color,
  text,
  bgcolor,
  height,
  border,
  iconColor = "#d1d1d1",
  size = "sm",
  padding = "4px",
  boxShadow = "0px 1px 1px 0px rgba(0, 0, 0, 0.12)"
}) {
  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            color: color,
            backgroundColor: bgcolor,
            border: border,
            width: "auto",
            maxWidth: "700px !important",
            padding: padding,
            boxShadow: boxShadow,
            borderRadius:'8px',
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <div>
        <ThemeProvider theme={theme}>
          <Tooltip title={text}>
            <div sx={{ m: 1 }}>
              <FontAwesomeIcon
                icon="fa-solid fa-circle-exclamation"
                style={{ color: iconColor, transform: "rotate(180deg)", cursor: 'pointer' }}
                size={size}
              />
              {/* <PropertyTagToolTip height={height} /> */}
            </div>
          </Tooltip>
        </ThemeProvider>
      </div>
    </ThemeProvider>
  );
}

TagToolTip.defaultProps = {
  height: "16px",
  color: "#000000",
  bgcolor: "#ffff",
  border: "1px solid #f4f5f5 ",
};
