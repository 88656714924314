import React, { useState } from "react";
import ETButton from "../../../../Components/Buttons/ETButton";
import { H3 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../../../Components/Tabs/ReactTabs.style";
import Ongoing from "./activeScreeningPages/Ongoing";
import Completed from "./activeScreeningPages/Completed";
import Approved from "./activeScreeningPages/Approved";
import Declined from "./activeScreeningPages/Declined";
import Canceled from "./activeScreeningPages/Canceled";
import { ActionsPopup } from "../popups/ActionsPopup";

const ActiveScreenings = () => {
  // created states
  const [activeScreeningsMainTabs, setActiveScreeningsMainTabs] = useState("");

  // wizard used for mapping of tabs
  const ActiveScreeningsTabsWizards = [
    {
      Id: "1",
      tab: "Ongoing screenings",
      component: <Ongoing />,
    },
    {
      Id: "2",
      tab: "Completed screenings",
      component: <Completed />,
    },
    {
      Id: "3",
      tab: "Approved screenings",
      component: <Approved />,
    },
    {
      Id: "4",
      tab: "Declined screenings",
      component: <Declined />,
    },
    {
      Id: "5",
      tab: "Canceled screenings",
      component: <Canceled />,
    },
  ];
  return (
    <div className="whiteContainerStyle  ">
      <div className="d-flex align-items-center px-4 py-3 justify-content-between  border-bottom">
        <div className="d-flex gap-2">
          {" "}
          <H3>Active screenings</H3>
          <CustomToolTip size="sm" text={"Active screenings"} />
        </div>

        <ETButton
          buttonText={"CREATE NEW SCREENING"}
          icon={true}
          variant={"primary"}
          
        />
      </div>
      <Tabs
        lassName="mb-3 pb-1 d-flex flex-column"
        value={activeScreeningsMainTabs}
      >
        <div
          className="d-flex ps-4 pe-3 py-3 justify-content-between"
          style={{
            width: "100%",
            flexDirection: "row",
            flexWrap: "wrap",
            borderBottom: "1px solid #F0F0F0",
          }}
        >
          <TabList className="light ps-0  ">
            {ActiveScreeningsTabsWizards.map((page, index) => (
              <>
                <Tab
                  key={index}
                  style={{ position: "relative" }}
                  onClick={() => setActiveScreeningsMainTabs(page?.tab)}
                >
                  <span style={{ position: "relative" }}>{page?.tab}</span>
                </Tab>
              </>
            ))}
          </TabList>
        </div>
        <div className="bg-white" style={{ borderRadius: " 10px" }}>
          {ActiveScreeningsTabsWizards.map((page, index) => (
            <TabPanel key={page?.Id}>{page?.component}</TabPanel>
          ))}
        </div>
      </Tabs>
      
    
    
    </div>
  );
};


export default ActiveScreenings;

// change log and other info
// Created by Suraj
