import React, { useEffect } from "react";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../Components/Tabs/ReactTabs.style";
import { Col, Container, Placeholder, Row } from "react-bootstrap";

import { useState } from "react";
import { useQuery, useMutation } from "react-query";

import { toast } from "react-toastify";

import FacilityTable from "./FacilityTable";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import ETButton from "../../Components/Buttons/ETButton";
import Duplicate from "../properties/units/duplicate/Duplicate";
import {
  DeleteFacilityPackage,
  GetFacilityPackageByPropertyId,
  // PostCloneFacilityPackage,
} from "../../services/Facility";

import {
  useAllPropertyList,
  usePostCloneFacilityPackage,
} from "../../hooks/queries/usePropertyQueries";
import { useAddEditPackage } from "../../hooks/stores/usePackageStore";
import { useRouting } from "../../hooks/stores/useRoutingStore";
import FacilityCard from "../../Components/card/FacilityCard";
import { status } from "../../model/Constant";
import { useBreadcrumb } from "../../Components/BreadcrumbContext ";
import { H2 } from "../../styles/Common.style";

const Facilities = ({ propertyId }) => {
  const {
    // title,
    setTagRowData,
    setSelected,
    setSelectedData,
    setCancelPopup,
    setTitle,
  } = useAddEditPackage((state) => state);
  const {
    setShowPackage,
    setEditflag,
    packageId,
    setPackageId,
    setPackageType,
    // setSelectedTabType,
    selectedTabType,
  } = useRouting((state) => state);
  // const [facilityId, setFacilityId] = useState(undefined);
  const [addnewpackegeBtn, setAddnewpackegeBtn] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [clonePackageTitle, setClonePackageTitle] = useState("");
  const [nodataalignment, setNoDataAlignment] = useState("");
  const [checkedPropertyId, setCheckedPropertyId] = useState([]);
  const {
    data: GetFacilityPackageByPropertyIdData,
    refetch: RefetchGetFacilityPackageByPropertyIdData,
    isLoading,
  } = useQuery(["GetFacilityPackageByPropertyIdData", propertyId], () =>
    GetFacilityPackageByPropertyId(propertyId)
  );

  const { mutateAsync: deleteFacilityPackageMutate } = useMutation(
    "deleteFacilityPackage",
    (packageId) => DeleteFacilityPackage(packageId)
  );
  const { mutateAsync: postCloneFacilityPackage } =
    usePostCloneFacilityPackage();

  // const { data: GetFacilityPackageByFacilityIdData } =
  // 	useGetFacilityPackageByFacilityId(
  // 		isEditFacilityPackage,
  // 		propertyId,
  // 		facilityPackageId
  // 	);
  // const { data: GetFacilityPackageByFacilityIdData } =
  // 	useGetFacilityPackageByPackageId(propertyId, facilityPackageId);

  const { data: getAllpropertiesList } = useAllPropertyList(0);

  const handleDeleteFacilityPackage = async (facilityPackageID) => {
    const result = await deleteFacilityPackageMutate(facilityPackageID);
    try {
      if (result?.data.bReturn) {
        toast.success(result.data.message);
        RefetchGetFacilityPackageByPropertyIdData();
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
    // if (response.status == "success") {
    // 	toast.success(response.data.message + "!");
    // }
  };

  const handleAddPackageClick = () => {
    // setShowFacilityPackage(true);
    // setIsEditFacilityPackage(false);
    setShowPackage(true);
    // setPackageType("4");
    setPackageType("4");
    setEditflag(false);
    setCancelPopup(false);
    setTitle("");
    // setActive(false);
    setTagRowData([]);
    setSelected([]);
    setSelectedData([]);
  };

  //----------------handle duplicate package-------------//

  const handleClonePackage = async (data) => {
    const clonePayloadDetails = {
      facility_package_id: packageId?.toString(),
      title: data?.packageTitle,
      clone_type: "0",
      property_id: checkedPropertyId,
    };
    try {
      const response = await postCloneFacilityPackage(clonePayloadDetails);
      if (response.status === status.s && response?.data?.bReturn) {
        toast.success(response?.data?.message);
        RefetchGetFacilityPackageByPropertyIdData();
        setOpenDuplicateModal(false);
        setCheckedPropertyId([]);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) { }
  };

  // const handleClonePackage = () => {
  // 	const clonePayloadDetails = {
  // 		facility_package_id: packageId?.toString(),
  // 		title,
  // 		clone_type: "0",
  // 		property_id: checkedPropertyId,
  // 	};
  // 	PostCloneFacilityPackage(clonePayloadDetails)
  // 		.then((response) => {
  // 			toast.success(response?.data?.message);
  // 			RefetchGetFacilityPackageByPropertyIdData();
  // 			setOpenDuplicateModal(false);
  // 			setCheckedPropertyId([]);
  // 		})
  // 		.catch((error) => {});
  // };
  const handlealignment = () => {
    if (GetFacilityPackageByPropertyIdData?.data == 0) {
      setNoDataAlignment(true);
    }
  };

  const editFacility = (item) => {
    setShowPackage(true);
    setPackageId(item?.facility_package_id);
    setEditflag(true);
    setPackageType("4");
  };

  const duplicateFacility = (item) => {
    setPackageId(item?.facility_package_id);
    setOpenDuplicateModal(true);
  };
  const { updateBreadcrumbs } = useBreadcrumb();

  useEffect(() => {
    if (addnewpackegeBtn) {
      updateBreadcrumbs(["Facilities", "Packages"]);
    } else {
      updateBreadcrumbs(["Facilities", "Facilities"]);
    }
  }, [addnewpackegeBtn]);

  return (
    <>
      <div className="p-4 radius-8 containerStyle" >
        <Container
          fluid
          className="radius-8 px-0 mainborder "
          style={{ backgroundColor: "#ffffff" }}
        >
          <div
            className=" d-flex px-4 py-3"
          // style={{ borderBottom: "1px solid #F0F0F0" }}
          >
            <div className="">
              {isLoading ? (
                // <div className="my-4">
                <div className="" style={{ width: "100px" }}>
                  <Placeholder animation="glow">
                    <div className="placeholder col-10 bg-secondary"></div>
                  </Placeholder>
                </div>
              ) : (
                // </div>
                <div className=" d-flex align-items-center fs-14 fw-700">
                  <H2 className="me-2">Facilities</H2>
                  <CustomToolTip  text="Facilities" />
                </div>
              )}
            </div>
          </div>
          <hr className="hr my-0"></hr>
          <Tabs
          // selectedIndex={selectedTabType} onSelect={(index) => setSelectedTabType(index)}
          >
            <div className="d-flex justify-content-between">
              <TabList unmountOnExit={true} className=" ps-4 py-3">
                <div className="">
                  <Placeholder animation="glow" className="d-flex">
                    {isLoading ? (
                      <div className="" style={{ width: "100px" }}>
                        <div
                          className="placeholder col-10 radius-8 bg-secondary"
                          style={{ height: "35px" }}
                        ></div>
                      </div>
                    ) : (
                      <Tab
                      className="me-3"
                        eventKey="facilities"
                        onClick={() => {
                          setAddnewpackegeBtn(false);
                          // setSelectedSubTabType("Facilities")
                          // setActiveTab('Facilities')
                          // setSelectedSubTab(false)
                        }}
                      >
                        {/* onClick={() => setAddnewpackegeBtn(false)}> */}
                        Facilities
                      </Tab>
                    )}
                    {isLoading ? (
                      <div className="" style={{ width: "100px" }}>
                        <div
                          className="placeholder col-10 radius-8 bg-secondary"
                          style={{ height: "35px" }}
                        ></div>
                      </div>
                    ) : (
                      <Tab
                        onClick={() => {
                          setAddnewpackegeBtn(true);
                          // setSelectedSubTabType("Packages")
                          // setSelectedSubTab(false)
                          // setActiveTab('Packages')
                        }}
                      >
                        Packages
                      </Tab>
                    )}
                  </Placeholder>
                </div>
              </TabList>
              {addnewpackegeBtn && (
                <div className="pe-4 py-4">
                  {isLoading ? (
                    <div
                      className="placeholder col-10 radius-8 padding-20 bg-secondary"
                      style={{ width: "186px" }}
                    ></div>
                  ) : (
                    <ETButton
                      buttonText=" ADD NEW PACKAGE"
                      variant={"primary"}
                      icon={true}
                      width="fit-content"
                      onClick={() => handleAddPackageClick()}
                    />
                  )}
                </div>
              )}
            </div>
            <hr className="hr mt-0 mb-2"></hr>

            <TabPanel>
              <FacilityTable propertyId={propertyId} />
            </TabPanel>
            {/*------------------------------ Packages --------------------------------------------------*/}
            <TabPanel onClick={handlealignment}>
              <Row className="px-4 pt-2 pb-4">
                {GetFacilityPackageByPropertyIdData?.data == 0 ? (
                  <div
                    className="d-flex justify-content-center w-100 fs-12"
                    style={{ color: "#888888" }}
                  >
                    <div>
                      You do not have any facility package yet. Click on “+ ADD
                      NEW PACKAGE” to create your first.
                    </div>{" "}
                  </div>
                ) : (
                  <>
                    {GetFacilityPackageByPropertyIdData?.data.map((item) => (
                      <Col
                        key={item?.facility_package_id}
                        xl={4}
                        lg={6}
                        className="py-1"
                      >
                        <FacilityCard
                          data={item}
                          editFacility={editFacility}
                          duplicateFacility={duplicateFacility}
                          handleDeleteFacilityPackage={
                            handleDeleteFacilityPackage
                          }
                        />
                        {/* <div
                          style={{
                            backgroundColor: "#F9FAFA",
                            border: " 1px solid #F0F0F0",
                            minHeight: "426px",
                            // width: "372px",
                          }}
                          className=" radius-8  mt-2 mb-2 "
                        >
                          <div
                            className=" mx-3 radius-6 my-3 "
                            style={{
                              backgroundColor: "#ffffff",
                              height: "150px",
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              border: "1px solid #f0f0f0",
                            }}
                          >
                            {item?.icons?.map(
                              (icon, index) =>
                                index < 14 &&
                                (icon ? (
                                  <>
                                    <FontAwesomeIcon
                                      key={index}
                                      icon={icon}
                                      className="facility-icon"
                                    />
                                  </>
                                ) : (
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-circle"
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      padding: "10px 16px ",
                                    }}
                                  />
                                ))
                            )}
                            {item?.icons?.length > 14 && (
                              <div
                                className="px-2"
                                style={{
                                  display: "inline-flex",
                                }}
                              >
                                <div className="fs-10 fw-600  d-flex justify-content-center align-items-center">
                                  + {item?.icons?.length - 14} icons
                                </div>
                              </div>
                            )}
                          </div>
                          <hr className="hr mb-0" />
                          <div className="px-3 py-2">
                            <div className="fs-14 py-2">
                              <div className="form-label-title">
                                Package title
                              </div>
                              <div className="light-font-text">
                                {item?.title}
                              </div>
                            </div>
                            <div className="fs-14 pb-2">
                              <div className="form-label-title">
                                Number of facilities
                              </div>
                              <div className="light-font-text">
                                {item.facility_count} facilities
                              </div>
                            </div>
                            <div
                              className="fs-14 pb-3 ellipsis"
                              style={{ width: "95%" }}
                            >
                              <div className="form-label-title">Tag</div>
                              <div className="light-font-text ellipsis">
                                {item.tags ? item.tags : "-"}
                              </div>
                            </div>
                            <div className="d-flex pb-2  justify-content-between">
                              <div className="fs-12  pt-1 ">
                                <div className="fs-12  form-label-title">
                                  Created by
                                </div>
                                <div className="created-by">
                                  {item?.created_by
                                    ? item?.created_by +
                                      ", " +
                                      moment(item?.created_on).format(
                                        "DD. MMM. YYYY | HH:MM:SS"
                                      )
                                    : "-"}
                                </div>
                                <div className="fs-12 pt-1 form-label-title">
                                  Last updated by
                                </div>
                                <div className="created-by">
                                  {item?.modified_by
                                    ? item?.modified_by +
                                      ", " +
                                      moment(item?.modified_on).format(
                                        "DD. MMM. YYYY | HH:MM:SS"
                                      )
                                    : "-"}
                                </div>
                              </div>
                              <div className="d-flex align-items-end">
                                <Menus
                                  key={item?.facility_package_id}
                                  component={
                                    <div className="light-font-text">
                                      <div
                                        onClick={() => editFacility(item)  }
                                        className="addpropContent px-3 py-2 "
                                      >
                                        <span className="ms-1">Edit</span>
                                      </div>
                                      <hr className="addpropertyHr m-0"></hr>
                                      <div
                                        onClick={() =>duplicateFacility(item)}
                                        className="addpropContent px-3 py-2 "
                                      >
                                        <span className="ms-1">Duplicate</span>
                                      </div>
                                      <hr className="addpropertyHr m-0"></hr>

                                      <div
                                        onClick={() =>
                                          handleDeleteFacilityPackage(
                                            item?.facility_package_id
                                          )
                                        }
                                        className="addpropContent px-3 pt-2 pb-2 "
                                      >
                                        <span className="ms-1">Delete</span>
                                      </div>
                                    </div>
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </Col>
                    ))}
                  </>
                )}
              </Row>
            </TabPanel>
          </Tabs>
        </Container>
        <Duplicate
          openDuplicate={openDuplicateModal}
          setOpenDuplicate={setOpenDuplicateModal}
          allChecked={checkedPropertyId}
          setAllChecked={setCheckedPropertyId}
          setTitle={setClonePackageTitle}
          handleDuplicate={handleClonePackage}
          propertyData={getAllpropertiesList?.data?.filter(
            (el) => el?.property_id !== propertyId
          )}
        />
      </div>
    </>
  );
};

export default Facilities;
