import React from "react";
import CustomPopup from "../../../../Components/CustomPopup";
import ETButton from "../../../../Components/Buttons/ETButton";
import { useDeleteDocumentByDocumentId } from "../../../../hooks/queries/useDocumentsQueries";
import { toast } from "react-toastify";
import { status } from "../../../../model/Constant";
import {
  ErrorAlert,
  LoadingAlert,
  SucessAlert,
} from "../../../../Components/alerts/AlertsAndMessages";

const DeleteDocument = ({
  deleteDocument,
  setDeleteDocument = () => {},
  documentId,
}) => {
  // ------------------- react query ---------------------//
  const {
    mutateAsync: DeleteDocumentByDocumentId,
    isLoading: DeleteDocumentByDocumentIdLoading,
  } = useDeleteDocumentByDocumentId();

  // --------------- handle delete function ---------------//
  const handleDeleteDocument = async () => {
    let id;
    if (!DeleteDocumentByDocumentIdLoading) {
      id = toast.loading(<LoadingAlert />);
    }
    try {
      const response = await DeleteDocumentByDocumentId(documentId);

      if (response.status == status.s && response.data.bReturn) {
        toast.update(id, {
          render: <SucessAlert />,
          isLoading: false,
          type: status.s,
          autoClose: 2000,
        });
      } else {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.update(id, {
        render: <ErrorAlert />,
        isLoading: false,
        type: status.e,
        autoClose: 2000,
      });
    } finally {
      // Always refetch the document data, regardless of success or failure
      setDeleteDocument({ showPopup: false, documentId: "" });
    }
  };

  return (
    <CustomPopup
      open={deleteDocument}
      // onHide={() => setDeleteDocument(false)}
      title="Delete document?"
      title_color="black"
      enableCrossButton={false}
    >
      <div className="p-4">
        <div className="light-font-text mb-3">
          If you delete this document, it will be deleted everywhere in the
          system, including where it is inherited.
        </div>
        <div className="light-font-text mb-3">
          If the document is published, used in a signing package or used on a
          contract, it will then not be deleted there.
        </div>
        <div className="light-font-text">
          Are you sure you want to delete this document?
        </div>
      </div>
      <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
        <ETButton
          variant={"white"}
          buttonText="COLD FEET?"
          onClick={() => {
            setDeleteDocument({ showPopup: false, documentId: "" });
          }}
        />
        <ETButton
          variant={"primary"}
          buttonText="DELETE"
          onClick={handleDeleteDocument}
        />
      </div>
    </CustomPopup>
  );
};

export default DeleteDocument;
