import React, { useState } from "react";
import ETButton from "../../../../Components/Buttons/ETButton";
import SelectUnitTable from "../CreateNewAppointments/SelectUnitTable";

const SelectUnitsForMoveTo = ({ handleBack, handleNext }) => {
  // --------------------- states -----------------------------//
  const [rowSelection, setRowSelection] = useState({});
  const [switchForSameTypeOfUnit, setSwitchForSameTypeOfUnit] = useState(false);

  // -----------------  handle next button -------------------------//
  const handleClickNext = (data) => {
    handleNext(data);
  };
  return (
    <div>
      <div className="Popups-inner-scrollable-container-height scrollable-container-y">
        <SelectUnitTable
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          isTypeSwitchRequired={true}
          switchForSameTypeOfUnit={switchForSameTypeOfUnit}
          setSwitchForSameTypeOfUnit={setSwitchForSameTypeOfUnit}
          heading={""}
          subHeading={
            "Select below which unit you want to move the customer to."
          }
        />
      </div>

      <div className="py-2 px-3 d-flex flex-row justify-content-between  footer-section-button-container">
        <ETButton
          variant={"secondary"}
          buttonText="Back"
          type={"button"}
          onClick={handleBack}
        />

        <ETButton
          variant={"primary"}
          buttonText="NEXT"
          type={"button"}
          onClick={handleClickNext}
        />
      </div>
    </div>
  );
};

export default SelectUnitsForMoveTo;
