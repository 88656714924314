//This Screen is used for add private event and Follow-up private event.

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { GreenSpan , H2} from "../../../styles/Common.style";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import EventOverviewContainer from "../followUpEvent/EventOverviewContainer";
import ContactInformation from "../followUpEvent/ContactInformation";
import FollowUp from "../followUpEvent/FollowUp";
import AdditionalInfo from "../followUpEvent/AdditionalInfo";
import ETButton from "../../../Components/Buttons/ETButton";
import DetailAboutEvent from "../DetailAboutEvent";
import { usePrivateViewingStore } from "../../../hooks/stores/usePrivateViewingStore";
import Publishevent from "../Publishevent";
import EventsUnitsTable from "../EventsUnitsTable";
import { H1 } from "../../../styles/Common.style";

const PrivateEventCommonScreen = ({ setShowPrivateEvent }) => {
  const { setShowPrivateEventPage, showPrivateEventPage } =
    usePrivateViewingStore((state) => state);

  const [publishPrivateEvent, setPublishPrivateEvent] = useState(false);

  return (
    <div>
      {" "}
      <div className="pt-3 ">
        <H1>
          Mads Johnson and Caroline Johnson - Private viewing
        </H1>
        <div
          className="light-font-text"
          style={{
            cursor: "pointer",
          }}
        >
          <span className="me-3">Properties</span>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <span className="me-3">Blomsterbjerget</span>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <span className="me-3">Events</span>

          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <GreenSpan className="me-3 ">
            Mads Johnson and Caroline Johnson - Private viewing
          </GreenSpan>
        </div>
      </div>{" "}
      <div className="open-house-container p-4 mt-4 mb-4 radius-8">
        <div className="bg-white radius-8">
          <div className="d-flex justify-content-between px-4 py-3">
            <H2 className="d-flex flex-column ">
              <div className="d-flex">
                Mads Johnson and Caroline Johnson - Private viewing
                <span className="ms-2">
                  <CustomToolTip
                    text="Mads Johnson and Caroline Johnson - Private viewing"
                    height={12}
                  />
                </span>
              </div>
            </H2>
          </div>
          <hr className="my-0"></hr>

          <div className="p-4">
            <EventOverviewContainer
              showPrivateEventPage={showPrivateEventPage}
            />
            <div className="light-font-text pt-4 pe-4">
              Below you can add more information about the private viewing. The
              information you have previously entered is already displayed
              below. You have the option to correct and add new information. If
              you want to schedule a follow-up viewing now, you can also do so
              here.
            </div>
          </div>

          <hr className="my-0"></hr>
          <div>
            <ContactInformation footer={false} />
          </div>
          <hr></hr>
          <div>
            <AdditionalInfo footer={false} />
          </div>
          <hr className="mt-0"></hr>
          <div>
            <FollowUp footer={false} />
          </div>
          <div className="mt-5 py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            {" "}
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              // type={"button"}
              onClick={() => {
                setShowPrivateEvent(false);
                setShowPrivateEventPage(false);
              }}
            />
            <div className=" d-flex ">
              <div className="px-1">
                {" "}
                {showPrivateEventPage ? (
                  <ETButton
                    variant={"primary"}
                    buttonText="SAVE AND PUBLISH EVENT"
                    type={"submit"}
                    onClick={() => setPublishPrivateEvent(true)}
                  />
                ) : (
                  <ETButton
                    variant={"primary"}
                    buttonText="SAVE"
                    type={"submit"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Publishevent
        showPrivateEventPage={showPrivateEventPage}
        publishEvents={publishPrivateEvent}
        setPublishEvents={setPublishPrivateEvent}
      />
    </div>
  );
};

export default PrivateEventCommonScreen;
