import { useEffect, useState } from "react";
import "./addnewProperty.css";
import "../../../App.css";
import { Container, Row, Col, Image, Form } from "react-bootstrap";
import Trophy from "../../../assets/icons/svg_icons/trophywinner.svg";
import ETButton from "../../../Components/Buttons/ETButton";
import { REQUIRED_ERR } from "../../../utils/validationConstants";
import { useForm, Controller } from "react-hook-form";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import {
  useCreateProperty,
  useEditProperty,
  useGetPropertyDetailByPropertyId,
  useGetCurrencyByCountry,
  useAllPropertyList,
  useGetPropertyTypes,
  useGetPropertyWithOverviewByPropertyId,
  useGetFavoriteProperties,
} from "../../../hooks/queries/usePropertyQueries";
import {
  useGetCoordinates,
  useGetStreetDetails,
  useGetZipcodeByCountry,
} from "../../../hooks/queries/useGeographyQueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { toast } from "react-toastify";
import {
  useCountryName,
  useFavoritesData,
  usePropertyId,
  usePropertyOverview,
} from "../../../hooks/stores/usePropertyStore";
import { status } from "../../../model/Constant";
import moment from "moment";
import { GreenSpan } from "../../../styles/Common.style";
import { TabsButtonIcon } from "../../../Components/Tabs/Tabs.style";
import { useUserDetails } from "../../../hooks/stores/useUserDetailsStore";
import { useGetUserById } from "../../../hooks/queries/useUserQueries";
import {
  useGetCompanyByLevel,
  useGetCompanyByOrganizationId,
} from "../../../hooks/queries/useCompanyQueries";
import { useLocation, useNavigate } from "react-router-dom";
import { useBreadcrumb } from "../../../Components/BreadcrumbContext ";
import { H1, H2 } from "../../../styles/Common.style";
import InputField from "../../../Components/Textfield/InputField";
import { text } from "@fortawesome/fontawesome-svg-core";
import { UTCtoLocalTimeZone } from "../../../utils/UTCtoLocalTimeZone";
import { useDivisionId } from "../../../hooks/stores/useDivisionsStore";

const AddnewProperty = ({
  countryId,
  setShow,
  setShowPage,
  buttonType = "UPDATE",
  setButtonType,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
    watch,
  } = useForm({
    defaultValues: {
      propName: "",
      companyName: "",
      street: "",
      no: "",
      info: "",
      zip: "",
      lat: "",
      long: "",
      propertyType: { value: 2, label: "Leasing" },
      year: { value: "2023", label: "2023" },
      currency: "",
    },
  });
  // const properties = undefined;
  // console.log(properties.PropertyDataID);
  //--------------------------Zustand-------------------//
  //-------------------Zustand---------------//

  const { userDetails } = useUserDetails((state) => state);
  const [filteredStreetname, setFilteredStreetname] = useState([]);
  const [streetname, setStreetname] = useState("");
  const [searchedStreetName, setSearchedStreetName] = useState("");
  const [selectedStreet, setSelectedStreet] = useState(null);
  const [isSearchStreetVisible, setIsSearchStreetVisible] = useState(false);

  const [propertCreated, setPropertyCreated] = useState(false);
  const [propertyConstruction, setPropertyConstruction] = useState("");
  const [division_id, setdivision_id] = useState("");
  const [countryByName, setCountryByName] = useState("");
  const [error, setError] = useState([
    { error: false, status: "", message: "" },
  ]);
  const [zips, setZips] = useState([]);
  const [company, setCompany] = useState("");
  const [housenum, setHousenum] = useState("");
  const [city, setCity] = useState("");
  const [showzip, setshowzip] = useState("");
  const [PropertyDataID, setPropertyDataID] = useState("");
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [formSubmit, setFormSubmit] = useState(false);
  const [formSubmitData, setFormSubmitData] = useState([]);
  const [infoForCoord, setInfoForCoord] = useState("");
  const [propertydata, setPropertydata] = useState([]);
  const { countryNameStore, setCountryNameStore } = useCountryName(
    (state) => state
  );

  const [newCountryId, setNewCountryId] = useState("");
  const { propertyId } = usePropertyId((state) => state);
  const { divisionId } = useDivisionId((state) => state);

  const {
    data: GetPropertyWithOverviewByPropertyIdData,
    refetch: RefetchGetPropertyWithOverviewByPropertyIdData,
  } = useGetPropertyWithOverviewByPropertyId(propertyId, 1); // this code used to provide propertyid in this page
  const setPropertyId = usePropertyId((state) => state.setPropertyId);
  const { propOverview, setPropOverview } = usePropertyOverview(
    (state) => state
  );
  let location = useLocation();
  const { state } = location;
  let EditPropertyID =
    state?.editPropId == undefined ? propertyId : state?.editPropId;
  let CountryID = countryId
    ? countryId
    : GetPropertyWithOverviewByPropertyIdData?.data?.[0]?.country_id;
  //  Code end here

  const {
    mutateAsync: createpropertyMutate,
    isLoading: createpropertyMutateIsLoading,
  } = useCreateProperty();
  const {
    mutateAsync: editPropertyMutate,
    isLoading: editPropertyMutateIsLoading,
  } = useEditProperty();
  const { data: GetPropertyDetailByPropertyIdData } =
    useGetPropertyDetailByPropertyId(EditPropertyID);
  // console.log(GetPropertyDetailByPropertyIdData?.data, "GetPropertyDetailByPropertyIdData")
  const { refetch: allPropertyDataRefetch } = useAllPropertyList(0);
  const { data: zipcodeData } = useGetZipcodeByCountry(CountryID);
  const { data: getCurrencyByCountryData } = useGetCurrencyByCountry(CountryID);

  const { data: getCoordinatesByInfo, refetch: getCoordinatesByInfoRefetch } =
    useGetCoordinates(
      getValues("street") +
        " " +
        housenum +
        "," +
        city +
        " " +
        showzip +
        "," +
        countryNameStore
    );

  const { data: getStreetDetailsData } =
    useGetStreetDetails(searchedStreetName);
  // const { data: userData } = useGetUserById();
  const { data: GetCompanyByOrganizationIdData } =
    useGetCompanyByOrganizationId(userDetails?.organisation_id);
  const { data: GetCompanyByLevelData, refetch: GetCompanyByLevelDataRefetch } =
    useGetCompanyByLevel("o", userDetails?.organisation_id, "details");
  const { data: propertyTypesData } = useGetPropertyTypes();
  const { setFavoritePropertiesStoreData } = useFavoritesData((state) => state);
  const { data: favouritePropertiesData, refetch: favouritePropertiesRefetch } =
    useGetFavoriteProperties("d", divisionId, "details");
  const navigate = useNavigate();

  useEffect(() => {
    setFavoritePropertiesStoreData(favouritePropertiesData?.data);
  }, [favouritePropertiesRefetch]);
  useEffect(() => {
    setPropOverview(GetPropertyWithOverviewByPropertyIdData?.data);
  }, [GetPropertyWithOverviewByPropertyIdData]);
  useEffect(() => {
    if (buttonType == "UPDATE") {
      setValue("companyName", {
        value: GetPropertyDetailByPropertyIdData?.data[0]?.company_id,
        label: GetPropertyDetailByPropertyIdData?.data[0]?.company_name,
      });
      setValue(
        "propName",
        GetPropertyDetailByPropertyIdData?.data[0]?.propertyName
      );
      setValue(
        "street",
        GetPropertyDetailByPropertyIdData?.data[0]?.street_name
      );
      setPropertydata(GetPropertyDetailByPropertyIdData?.data);
      setCountryNameStore(GetPropertyDetailByPropertyIdData?.data[0]?.country);
      setValue("no", GetPropertyDetailByPropertyIdData?.data[0]?.house_number);
      setValue("info", GetPropertyDetailByPropertyIdData?.data[0]?.add_info);
      setValue("zip", {
        value: GetPropertyDetailByPropertyIdData?.data[0]?.zipCode,
        label: GetPropertyDetailByPropertyIdData?.data[0]?.zipCode,
      });
      setInfoForCoord(GetPropertyDetailByPropertyIdData?.data[0]?.zipCode);
      setValue("city", GetPropertyDetailByPropertyIdData?.data[0]?.city);
      setValue("lat", GetPropertyDetailByPropertyIdData?.data[0]?.latitude);
      setValue("long", GetPropertyDetailByPropertyIdData?.data[0]?.longitude);

      if (GetPropertyDetailByPropertyIdData?.data[0]?.type == 1) {
        let propertyTypeObj = propertyTypes?.data?.filter(
          (item) => item.Id == GetPropertyDetailByPropertyIdData?.data[0]?.type
        );
        setValue("propertyType", {
          value: GetPropertyDetailByPropertyIdData?.data[0]?.type,
          label: "Sale",
        });
      } else if (GetPropertyDetailByPropertyIdData?.data[0]?.type == 2) {
        setValue("propertyType", { value: 2, label: "Leasing" });
      }
      setValue("year", {
        value: GetPropertyDetailByPropertyIdData?.data[0]?.construction_year,
        label: GetPropertyDetailByPropertyIdData?.data[0]?.construction_year,
      });
      setValue("currency", {
        value: GetPropertyDetailByPropertyIdData?.data[0]?.currency_id,
        label: GetPropertyDetailByPropertyIdData?.data[0]?.currency_id,
      });
      setshowzip(GetPropertyDetailByPropertyIdData?.data[0]?.zipCode);
      setStreetname(GetPropertyDetailByPropertyIdData?.data[0]);
      setHousenum(GetPropertyDetailByPropertyIdData?.data[0]?.house_number);
      setCountryByName(GetPropertyDetailByPropertyIdData?.data[0]?.country);
    }
  }, [GetPropertyDetailByPropertyIdData, propertyId]);
  const d = new Date();
  let currentYear = d.getFullYear();
  let endYear = currentYear + 10;

  const constructionYear = [];
  for (let i = endYear; i >= 1750; i--) {
    constructionYear.push(i);
    // constructionYear.reverse();
  }

  const ReturnProperties = () => {
    setShowPage("PL");
    setShow(false);
    reset();
  };

  // console.log(PropertyDataID, "PropertyDataID");

  const handleGoToProperty = () => {
    // setEditPropertyID(PropertyDataID);
    setPropertyId(PropertyDataID);
    navigate("/property-units/overview", {
      state: {
        editPropId: PropertyDataID,

        Tab: "Overview",
      },
    });
    setShowPage("PL");
    // setPropertyCreated(!propertCreated);
    // setShowPage("AP");
    // setButtonType("UPDATE");
    setShow(false);
  };
  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    updateBreadcrumbs(["Details"]);
  }, []);
  const onFormSubmit = async (data) => {
    setFormSubmitData(data);
    if (buttonType === "UPDATE") {
      const editPropertyPaylaod = {
        property_type_id: String(getValues("propertyType.value")),
        zipcode_id: getValues("zip.value"),
        name: getValues("propName"),
        company_id: getValues("companyName.value"),
        street_name: getValues("street"),
        house_number: getValues("no"),
        // city: getValues("city"),
        address_add_info: getValues("info"),
        latitude: getValues("lat"),
        longitude: getValues("long"),
        construction_year: getValues("year.value"),
        property_id:
          EditPropertyID === "" ||
          EditPropertyID === undefined ||
          EditPropertyID === null
            ? PropertyDataID
            : EditPropertyID,
        currency_id: getValues("currency.value"),
      };
      try {
        const result = await editPropertyMutate(editPropertyPaylaod);
        if (result.status === status.s && result.data.bReturn) {
          toast.success(result.data.message);
          setShowPage("PL");
          setButtonType("ADD");
          allPropertyDataRefetch();
          RefetchGetPropertyWithOverviewByPropertyIdData();
          favouritePropertiesRefetch();

          reset();
        } else {
          toast.error(result.data.message);
          setShowPage("PL");
          setButtonType("ADD");
          reset();
        }
      } catch (error) {}
    } else if (buttonType === "ADD") {
      const createPropertyPayload = {
        division_id: Number(userDetails?.division_id),
        property_type_id: getValues("propertyType.value"),
        country_id: buttonType === "ADD" ? CountryID : CountryID,
        zipcode_id: String(getValues("zip.value")),
        name: data?.propName,
        company_id: data?.companyName?.value,
        street_name: data?.street,
        house_number: data?.no,
        address_add_info: data?.info,
        latitude: data?.lat,
        longitude: data?.long,
        construction_year: data?.year.value,
        currency_id: String(getValues("currency.value")),
      };
      setPropertyConstruction(createPropertyPayload?.construction_year);

      try {
        const result = await createpropertyMutate(createPropertyPayload);
        if (result.status === status.s && result?.data?.bReturn) {
          setPropertyDataID(result?.data?.data?.property_id);
          setNewCountryId(result?.data?.data?.country_id);
          allPropertyDataRefetch();
          RefetchGetPropertyWithOverviewByPropertyIdData();
          favouritePropertiesRefetch();
          setPropertyCreated(!propertCreated);
          reset();
        }
      } catch (error) {
        setError({ error: error.message, status: error.status });
      }
    }
  };

  const handleZip = (e, ActionType) => {
    if (ActionType.action === "clear") {
      setValue("city", "");
    } else {
      if (e?.value != "" || e?.value != "undefined") {
        // setshowzip(e?.value)
        const HandleCityName = zips?.filter((data) => data?.Id == e?.value);
        const CityData = HandleCityName?.[0]?.Text;
        setValue("city", CityData);
      }
    }
  };
  // const handleZip = (e) => {
  //   if (e?.value != "" || e?.value != "undefined") {
  //     setshowzip(e?.value)
  //     // setInfoForCoord(e?.value);
  //     const HandleCityName = zips?.filter((data) => data?.Id == e?.value);
  //     const CityData = HandleCityName[0].Text;
  //     setCity(CityData);
  //   }
  // };
  useEffect(() => {
    setValue("lat", getCoordinatesByInfo?.data?.latitude);
    setValue("long", getCoordinatesByInfo?.data?.longitude);
  }, [getCoordinatesByInfo]);

  useEffect(() => {
    setPropertyTypes(propertyTypesData?.data);
    setZips(zipcodeData?.data);
    setdivision_id("10001");
  }, [city, propertyTypesData?.data, zipcodeData?.data]);

  // For Filter Street Name
  const handleInputChange = (event) => {
    setStreetname(event.target.value);
    if (CountryID == "DK" && streetname?.length >= 4) {
      setIsSearchStreetVisible(true);
      setSearchedStreetName(event.target.value);
    } else {
      setIsSearchStreetVisible(false);
    }
  };
  const handleItemClick = (item, e) => {
    setStreetname(item);
    setFilteredStreetname([]);
    setValue(
      "zip",
      item?.zipcode === ""
        ? ""
        : {
            value: item?.zipcode,
            label: item?.zipcode,
          }
    );
    // Set the value directly
    setValue("city", item?.city, { shouldValidate: item?.city !== "" && true });
    setValue("street", item?.street === "" ? "" : item?.street_name);
    setValue("no", item?.house_number, {
      shouldValidate: item?.house_number !== "" && true,
    });
    setValue("lat", item?.latitude === "" ? "" : item?.latitude);
    setValue("long", item?.longitude === "" ? "" : item?.longitude);
  };

  const handleBackspace = (event) => {
    setSelectedStreet(null);
  };
  const modifiedData = propertydata?.map((item) => {
    return {
      ...item,
      modifiedOn: moment(item.modified_on).format("DD.MMMM.YYYY | HH:mm:ss"),
      createdOn: moment(item.created_on).format("DD.MMMM.YYYY | HH:mm:ss"),
    };
  });

  const { convertedDate: createdOn } = UTCtoLocalTimeZone({
    timestamp: propertydata?.[0]?.created_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });
  const { convertedDate: modifiedOn } = UTCtoLocalTimeZone({
    timestamp: propertydata?.[0]?.modified_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });

  return (
    <>
      {buttonType == "UPDATE" ? (
        <></>
      ) : (
        <div>
          <div className="pt-3">
            <H1 className="mb-0">
              {buttonType == "ADD" ? "Create new property" : "Edit property"}
            </H1>
          </div>
          <div
            className="mt-0 pb-4 light-font-text"
            style={{ cursor: "pointer" }}
          >
            <span className="mt-1 " onClick={ReturnProperties}>
              Properties
            </span>

            <span className="px-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <GreenSpan onClick={() => setFormSubmit(false)}>
              {buttonType === "ADD" ? "Create new property" : "Edit property"}
            </GreenSpan>
          </div>
        </div>
      )}

      <>
        {propertCreated === false ? (
          <div
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
              border: "1px solid #F4F5F5",
              fontSize: "14px",
            }}
          >
            <div className="d-flex align-items-center mt-3 ">
              <H2 className="  ps-4 pe-2 ">
                {buttonType === "ADD"
                  ? "Create new property"
                  : "Property details"}
              </H2>
              <div>
                <CustomToolTip
                  text={
                    buttonType === "ADD"
                      ? "Create new property"
                      : "Property details"
                  }
                />
              </div>
            </div>
            {buttonType === "ADD" ? (
              ""
            ) : (
              <>
                {modifiedData?.map((item) => (
                  <div className="created-by px-md-4 fs-12">
                    {item.modified_by && item.modified_on ? (
                      <div>
                        Last updated by {item?.modified_by},{modifiedOn}
                      </div>
                    ) : (
                      <>
                        {item?.created_by && item?.created_on ? (
                          <div>
                            Created by {item?.created_by},{createdOn}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                ))}
              </>
            )}

            <hr className="hr" />
            <Form
              onSubmit={handleSubmit(onFormSubmit)}
              onClick={() => setIsSearchStreetVisible(false)}
              onKeyDown={(e) => e.code == "Enter" && e.preventDefault()}
            >
              <Row className="py-1 px-4">
                <Col xl={4} lg={4}>
                  <div className=" form-label-title">Company name</div>
                </Col>
              </Row>
              <Row className="pt-3 pb-4 px-4">
                <Col xl={4} lg={5} className="my-2 my-lg-0 pe-0  ">
                  <Controller
                    {...register("companyName")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Company name"}
                        value={value}
                        clearable={false}
                        iserror={errors?.companyName}
                        options={GetCompanyByLevelData?.data?.map((ele) => ({
                          value: ele?.company_id,
                          label: ele?.company_name,
                        }))}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  {/* {errors?.companyName && (
                      <p className="error-msg ">{REQUIRED_ERR}</p>
                    )} */}
                </Col>
              </Row>
              <hr className="hr mt-0" />

              <Row className="py-1 px-4">
                <Col xl={4} lg={4}>
                  <div className=" form-label-title">Property name</div>
                </Col>
              </Row>
              <Row className="pt-3  pb-4 px-4">
                <Col xl={4} lg={5} className="pe-0">
                  <Controller
                    control={control}
                    {...register("propName")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        {/* <input
                            type="text"
                            placeholder="Property name"
                            id="propertyName"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("propName");
                            }}
                            maxLength={30}
                          /> */}
                        <InputField
                          id={"propertyName"}
                          value={value}
                          type={"text"}
                          placeholder={"Property name"}
                          errorscope={false}
                          iserror={errors?.propName}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("propName");
                          }}
                        />
                        {/* <label htmlFor="propertyName">Property name</label> */}
                      </div>
                    )}
                  />
                  {errors?.propName &&
                    errors?.propName?.type === "required" && (
                      <p className="error-msg ">{REQUIRED_ERR}</p>
                    )}
                </Col>
              </Row>
              <hr className="hr mt-0" />
              <Row className=" py-1 px-4">
                <Col lg={3}>
                  <div className="form-label-title"> Property location</div>
                </Col>
              </Row>
              <Row className="pt-3 pb-4 px-4">
                <Col xl={4} lg={5} className="pe-0">
                  <div className="form-input ">
                    <input
                      type="text"
                      disabled
                      placeholder="Property location"
                      id="PropertyLocation"
                      value={countryNameStore}
                    />
                    <label htmlFor="PropertyLocation">Property location</label>
                  </div>
                </Col>
              </Row>
              <hr className="hr mt-0" />

              <Row className="py-1 ps-4">
                <Col>
                  <div className="form-label-title">Property address</div>
                </Col>
              </Row>
              <Row
                className="pt-3 pb-2 ps-4  pe-lg-0 pe-4"
                style={{ position: "relative" }}
              >
                <Col xl={4} lg={5} className="my-2 my-lg-0">
                  <Controller
                    control={control}
                    {...register("street")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div className="form-input ">
                        <InputField
                          id={"street"}
                          value={value}
                          type={"text"}
                          maxLength={30}
                          placeholder={"Street"}
                          errorscope
                          iserror={errors?.street}
                          onChange={(event) => {
                            onChange(event);
                            handleInputChange(event);
                            // clearErrors("Street");
                          }}
                        />
                        {/* <input
                            type="text"
                            placeholder="Street"
                            id="street"
                            value={value}
                            onChange={(event) => {
                              onChange(event);
                              handleInputChange(event);
                              clearErrors("street");
                              // setStreetname(e.target.value);
                            }}
                            maxLength={30}
                          /> */}
                        {/* <label htmlFor="street">Street</label> */}
                      </div>
                    )}
                  />
                  {/* {errors?.street && errors?.street?.type === "required" && (
                      <p className="error-msg ">{REQUIRED_ERR}</p>
                    )} */}
                  {streetname?.length < 4 &&
                  streetname?.length > 0 &&
                  CountryID == "DK" ? (
                    <div className="fs-12 fw-400 grey-text pt-1 ">
                      Type in minimum 4 characters to get a search result
                    </div>
                  ) : (
                    <>
                      {/* {streetname == filteredStreetname ? <> */}
                      {isSearchStreetVisible && CountryID == "DK" && (
                        <div style={{}}>
                          <div
                            className="bg-white radius-8 pe-1 filtered-list"
                            style={{
                              border: "1px solid #D1D1D1",
                              position: "absolute",
                              // top: "50px",
                              // left: "14px",
                              zIndex: "10",
                              width: "30%",
                            }}
                          >
                            <>
                              {getStreetDetailsData?.data?.length > 0 ? (
                                <div>
                                  {getStreetDetailsData?.data?.map(
                                    (item, index) => (
                                      <div
                                        className="mt-1 px-2"
                                        key={index}
                                        onClick={() => {
                                          handleItemClick(item);
                                          setIsSearchStreetVisible(false);
                                        }}
                                      >
                                        {item?.city ||
                                        item?.zipcode ||
                                        item?.house_number ? (
                                          <> {item?.address} </>
                                        ) : (
                                          <> {item?.street_name}</>
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : (
                                <div className="mt-1 px-2 fw-300 grey-text">
                                  No data found
                                </div>
                              )}
                            </>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </Col>
                <Col xl={3} lg={2} className="my-2 my-lg-0 ps-lg-0">
                  <Controller
                    control={control}
                    {...register("no")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div className="form-input ">
                        <InputField
                          id={"no"}
                          value={value}
                          type={"text"}
                          maxLength={20}
                          placeholder={"No"}
                          errorscope={false}
                          iserror={errors?.no}
                          onChange={(e) => {
                            onChange(e);
                            setHousenum(e.target.value);
                            clearErrors("propName");
                          }}
                        />
                        {/* <input
                            type="text"
                            placeholder="No."
                            id="No"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("no");
                              setHousenum(e.target.value);
                            }}
                            maxLength={20}
                          /> */}
                        {/* <label htmlFor="No">No.</label> */}
                      </div>
                    )}
                  />
                  {errors?.no && errors?.no?.type === "required" && (
                    <p className="error-msg ">{REQUIRED_ERR}</p>
                  )}
                </Col>
                <Col
                  xl={4}
                  lg={5}
                  className="my-2 my-lg-0 pe-xl-0 pe-lg-4 ps-lg-0"
                >
                  <Controller
                    control={control}
                    {...register("info")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <div className="form-input ">
                        <input
                          type="text"
                          placeholder="Add. info"
                          id="addinfo"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("info");
                          }}
                          maxLength={30}
                        />
                        <label htmlFor="addinfo">Add. info</label>
                      </div>
                    )}
                  />
                </Col>
              </Row>

              <Row className="py-2 ps-4 pe-lg-0 pe-4">
                <Col xl={4} lg={5} className="my-2 my-lg-0">
                  <Controller
                    name="zip"
                    {...register("zip")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder="Zip code"
                        // value={value}
                        clearable={false}
                        isSearchable
                        iserror={errors?.zip}
                        onChange={(e, ActionType) => {
                          onChange(e);
                          handleZip(e, ActionType);
                        }}
                        error={errors.zip}
                        options={zipcodeData?.data?.map((zip) => ({
                          value: zip?.Id,
                          label: `${zip?.Id} ${zip?.Text}`,
                        }))}
                        value={
                          value?.value
                            ? {
                                value: value?.value,
                                label: value?.value,
                              }
                            : ""
                        }
                      />
                    )}
                  />
                  {/* {errors?.zip && (
                      <p className="error-msg ">{REQUIRED_ERR}</p>
                    )} */}
                </Col>
                <Col xl={3} lg={4} className="my-2 my-lg-0 ps-lg-0">
                  <div className="form-input ">
                    <Controller
                      name="city"
                      {...register("city")}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div className="form-input ">
                          <input
                            type="text"
                            disabled
                            placeholder="City"
                            id="city"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("city");
                            }}
                          />
                          <label htmlFor="city">City</label>
                        </div>
                      )}
                    />
                    {/* <input
                        type="text"
                        disabled
                        placeholder="City"
                        id="Propertycity"
                        value={streetname ? streetname?.city : city}
                      // value={streetname?.city}
                      />
                      <label htmlFor="Propertycity">City</label> */}
                  </div>
                </Col>
              </Row>

              <Row className="pt-2 pb-4 ps-4  pe-lg-0 pe-4">
                <Col xl={4} lg={4} className="my-2 my-lg-0">
                  <Controller
                    control={control}
                    {...register("lat")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        {/* <input
                            type="text"
                            placeholder="Latitude"
                            id="lat"
                            value={value}
                            // value={streetname ? streetname?.latitude : value}

                            onChange={(e) => {
                              onChange(e);
                              clearErrors("lat");
                            }}
                          /> */}
                        <InputField
                          id={"lat"}
                          value={value}
                          type={"text"}
                          maxLength
                          placeholder={"Latitude"}
                          errorscope
                          iserror={errors?.lat}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("lat");
                          }}
                        />
                        {/* <label htmlFor="lat">Latitude</label> */}
                      </div>
                    )}
                  />
                  {/* {errors?.lat && (
                      <p className="error-msg ">{REQUIRED_ERR}</p>
                    )} */}
                </Col>
                <Col xl={4} lg={4} className="my-2 my-lg-0 ps-lg-0">
                  <Controller
                    control={control}
                    {...register("long")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        {/* <input
                            type="text"
                            placeholder="Longitude"
                            id="long"
                            value={value}
                            // value={streetname ? streetname?.longitude : value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("long");
                            }}
                          /> */}
                        <InputField
                          id={"long"}
                          value={value}
                          type={"text"}
                          maxLength
                          placeholder={"Longitude"}
                          errorscope
                          iserror={errors?.long}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("long");
                          }}
                        />
                        {/* <label htmlFor="long">Longitude</label> */}
                      </div>
                    )}
                  />
                  {/* {errors?.long && (
                      <p className="error-msg">{REQUIRED_ERR}</p>
                    )} */}
                </Col>
                <Col xl={4} lg={4} className="ps-md-1">
                  <div className=" px-2  my-2 ">
                    <span className="d-flex">
                      <TabsButtonIcon>
                        <FontAwesomeIcon
                          size="sm"
                          icon="fa-solid fa-location-dot"
                          onClick={() => getCoordinatesByInfoRefetch()}
                        />
                      </TabsButtonIcon>
                      <GreenSpan className="ms-1 fs-12 font-medium">
                        Change coordinates
                      </GreenSpan>
                    </span>
                  </div>
                </Col>
              </Row>
              <hr className="hr mt-0" />
              <Row className="py-1 ps-4">
                <Col lg={3}>
                  <div className="form-label-title">Property type</div>
                </Col>
              </Row>
              <Row className="pt-3 pb-4 px-4">
                <Col xl={4} lg={5} className="my-2 my-lg-0 pe-0">
                  <Controller
                    {...register("propertyType")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Property type"}
                        value={value}
                        clearable={false}
                        options={propertyTypesData?.data?.map((type) => ({
                          value: type?.Id,
                          label: `${type?.Text}`,
                        }))}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors?.propertyType && (
                    <p className="error-msg ">{REQUIRED_ERR}</p>
                  )}
                </Col>
              </Row>
              <hr className="hr mt-0" />

              <Row className="py-1 ps-4">
                <Col lg={3}>
                  <div className="form-label-title"> Construction year </div>
                </Col>
              </Row>
              <Row className="pt-3 pb-4 px-4">
                <Col xl={4} lg={5} className="my-2 my-lg-0 pe-0">
                  <Controller
                    {...register("year")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Construction year"}
                        value={value}
                        clearable={false}
                        setPlaceholderPosition={true}
                        options={constructionYear.map((Year) => ({
                          value: Year,
                          label: Year,
                        }))}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors?.year && <p className="error-msg ">{REQUIRED_ERR}</p>}
                </Col>
              </Row>
              <hr className="hr mt-0" />
              <Row className="py-1 ps-4">
                <Col lg={3}>
                  <div className="form-label-title"> Currency </div>
                </Col>
              </Row>
              <Row className="pt-3 pb-4 px-4">
                <Col xl={4} lg={5} className="my-2 my-lg-0 pe-0">
                  <Controller
                    {...register("currency")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Currency"}
                        value={value}
                        isClearable={false}
                        iserror={errors?.currency}
                        options={getCurrencyByCountryData?.data?.map(
                          (currency) => ({
                            value: currency?.Id,
                            label: currency?.Text,
                          })
                        )}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  {/* {errors?.currency && (
                      <p className="error-msg ">{REQUIRED_ERR}</p>
                    )} */}
                </Col>
              </Row>
              <div className="mt-3 py-2 px-3 d-sm-flex justify-content-between footer-section-button-container">
                <div className="m-1">
                  {buttonType == "ADD" && (
                    <ETButton
                      variant={"white"}
                      buttonText="CANCEL"
                      onClick={ReturnProperties}
                    />
                  )}
                </div>

                <div className="m-1">
                  <ETButton
                    variant={"primary"}
                    disabled={
                      createpropertyMutateIsLoading ||
                      editPropertyMutateIsLoading
                    }
                    buttonText={
                      buttonType == "ADD" ? "CREATE PROPERTY" : "SAVE"
                    }
                    type={"submit"}
                  />
                </div>
              </div>
            </Form>
          </div>
        ) : (
          <div
            fluid
            className="px-0  bg-white radius-8"
            style={{
              border: "1px solid #F4F5F5",
            }}
          >
            <Row className="px-2 text-center">
              <Col md={{ span: 8, offset: 2 }}>
                <div style={{ position: "relative", top: "45px" }}>
                  <h1 className="pt-4 fs-32 form-label-title">
                    {" "}
                    Congratulations! {formSubmitData?.propName} has been
                    successfully created{" "}
                  </h1>

                  <div className=" light-font-text ">
                    <p>
                      {formSubmitData?.propName} has been successfully created
                      and you are now ready to add units, manage settings and
                      market/manage your property.
                    </p>
                  </div>
                </div>

                {/* ------------------data showing after creating Property---------------------------- */}

                <div
                  className=" mx-4 px-lg-4"
                  style={{ position: "relative", top: "90px", zIndex: "1" }}
                >
                  <div
                    className="py-1 radius-8 text-center"
                    style={{
                      backgroundColor: "#F9FAFA",
                    }}
                  >
                    <GreenSpan className=" pt-3 fs-20 light-font-text ">
                      {formSubmitData?.propName}
                    </GreenSpan>
                    <p className="grey-text fs-12 light-font-text">
                      {countryNameStore} | {formSubmitData?.street}{" "}
                      {formSubmitData?.no}, {showzip} {city} |{" "}
                      {formSubmitData?.propertyType?.label} |{" "}
                      {propertyConstruction}
                    </p>
                  </div>
                </div>

                {/* //........................Image Part ......................................// */}

                <div
                  style={{
                    alignItems: "center",
                    position: "relative",
                    top: "45px",
                  }}
                >
                  <Image src={Trophy} responsive fluid />
                </div>
              </Col>
            </Row>
            <div
              className="py-2 px-3 d-sm-flex justify-content-between footer-section-button-container"
              style={{
                zIndex: "1",
                position: "relative",
              }}
            >
              <div className="m-1">
                <ETButton
                  variant={"white"}
                  buttonText="RETURN TO PROPERTIES"
                  onClick={ReturnProperties}
                />
              </div>

              <div className="m-1">
                <ETButton
                  variant={"primary"}
                  buttonText="GO TO PROPERTY"
                  onClick={handleGoToProperty}
                />
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default AddnewProperty;
