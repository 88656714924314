import { useState } from "react";
import { useAppState } from "../AllState";
import { useForm, Controller } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
// import Toggle from "../../../../Components/Switch/Switch";
// import NormalGroupText from "../../../../Components/GroupText/NormalGroupText";
import "../../documents.css";
// import DropdownModalMui from "../../../../Components/DropdownModal/DropdownModalMui";
import { GetFacilityByPropertyId } from "../../../../services/Facility";
import { useQuery } from "react-query";
import {
  useGetGeographyCountry,
  useGetStreetDetails,
  useGetZipcodeByCountry,
} from "../../../../hooks/queries/useGeographyQueries";
import { useEffect } from "react";
// import { useGetDocumentConfigurationDetails } from "../../../../hooks/queries/useDocumentsQueries";
import {
  RadioInput,
  RadioLabel,
  RadioWrapper,
} from "../../../../Components/RadioButton/ETRadioButton";
import { usePropertyOverview } from "../../../../hooks/stores/usePropertyStore";
import InputField from "../../../../Components/Textfield/InputField";
// import styled from "styled-components";

const Partnersandrented = ({
  handleChange,
  values,
  submitRef,
  handleNext,
  activeTenancyForm,
  getConfigurationDetailsData,
  handleEditConfig,
  previewRef,
}) => {
  // Zustand
  const propOverview = usePropertyOverview((state) => state.propOverview);

  // Get country code
  const { data: countryData } = useGetGeographyCountry();
  // Get Zipcode by countryId
  const { data: zipcodeData } = useGetZipcodeByCountry(
    propOverview?.[0]?.country_id
  );
  console.log(propOverview, "propOverview")

  //Get facilities by propertyId for dropdown
  // const {
  //   data: GetFacilityByPropertyIdData,
  //   refetch: GetFacilityByPropertyIdRefetch,
  // } = useQuery(
  //   ["GetFacilityByPropertyId", propertyId],
  //   () => GetFacilityByPropertyId(propertyId),
  //   { enabled: !!propertyId }
  // );
  const [state, setState] = useAppState();
  // right of use to section form state
  const [rightOfUseTo, setRightOfUseTo] = useState({
    retrieveDataFromUnit: false,
    retrieveDataFromUnitCheckbox: {
      other1: false,
      other2: false,
      other3: false,
    },
  });
  const [partnersandrentedForm, setPartnersandrentedForm] = useState({
    typeOfRental: {
      other: false,
    },
  });

  const {
    handleSubmit,
    register,
    // watch,
    getValues,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...state[activeTenancyForm],
    },
  });

  const saveData = (data) => {
    // console.log(data, "dfghjkl;");
    handleEditConfig(data, getConfigurationDetailsData, 1, true);
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  const preview = (data) => {
    // console.log(data);
    handleEditConfig(data, getConfigurationDetailsData, 1, false);
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  useEffect(() => {
    setValue(
      "area",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "area"
      )[0]?.actual_value
    );
    setValue(
      "no_of_rooms",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "no_of_rooms"
      )[0]?.actual_value
    );
    setValue(
      "garage_no",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "garage_no"
      )[0]?.actual_value
    );

    setValue(
      "attic",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "attic"
      )[0]?.actual_value
    );

    setValue(
      "t_name",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "t_name"
      )[0]?.actual_value
    );

    setValue(
      "t_street",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "t_street"
      )[0]?.actual_value
    );
    setValue(
      "t_no",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "t_no"
      )[0]?.actual_value
    );

    setValue(
      "t_add_info",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "t_add_info"
      )[0]?.actual_value
    );

    setValue(
      "city",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "city"
      )[0]?.actual_value
    );

    setValue(
      "address",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "address"
      )[0]?.actual_value
    );

    setValue(
      "t_email",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "t_email"
      )[0]?.actual_value
    );

    setValue(
      "t_country_code",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "t_country_code"
      )[0]?.actual_value
    );
    setValue(
      "t_phone1",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "t_phone1"
      )[0]?.actual_value
    );
    setValue(
      "t_zipcode",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "t_zipcode"
      )[0]?.actual_value
    );
    setValue(
      "t_city",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "t_city"
      )[0]?.actual_value
    );

    setValue("l_zipcode", {
      value: getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "l_zipcode"
      )[0]?.actual_value?.value,
      label: getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "l_zipcode"
      )[0]?.actual_value?.label,
    });

    setValue("l_country_code", {
      value: getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "l_country_code"
      )[0]?.actual_value?.value,
      label: getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "l_country_code"
      )[0]?.actual_value?.label,
    });

    setValue(
      "l_city",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "l_city"
      )[0]?.actual_value
    );

    setValue(
      "rental_type",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "rental_type"
      )[0]?.actual_value
    );

    setValue(
      "fac1",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "fac1"
      )[0]?.actual_value
    );

    setValue(
      "fac2",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "fac2"
      )[0]?.actual_value
    );

    setValue(
      "fac3",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "fac3"
      )[0]?.actual_value
    );

    setValue(
      "fac4",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "fac4"
      )[0]?.actual_value
    );

    setValue(
      "fac5",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "fac5"
      )[0]?.actual_value
    );

    setValue(
      "fac6",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "fac6"
      )[0]?.actual_value
    );

    setValue(
      "fac7",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "fac7"
      )[0]?.actual_value
    );

    setValue(
      "fac8",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "fac8"
      )[0]?.actual_value
    );

    setRightOfUseTo({
      ...rightOfUseTo,
      retrieveDataFromUnitCheckbox: {
        ...rightOfUseTo.retrieveDataFromUnitCheckbox,
        other1: getValues("fac6") === "" ? false : getValues("fac6"),
        other2: getValues("fac7") === "" ? false : getValues("fac7"),
        other3: getValues("fac8") === "" ? false : getValues("fac8"),
      },
    });

    setValue(
      "other_1",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "other_1"
      )[0]?.actual_value
    );

    setValue(
      "ts",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "ts"
      )[0]?.actual_value
    );

    setValue(
      "l_name",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "l_name"
      )[0]?.actual_value
    );

    setValue(
      "l_email",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "l_email"
      )[0]?.actual_value
    );
    setValue(
      "l_cvr",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "l_cvr"
      )[0]?.actual_value
    );
    setValue(
      "l_street",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "l_street"
      )[0]?.actual_value
    );
    setValue(
      "l_no",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "l_no"
      )[0]?.actual_value
    );
    setValue(
      "l_add_info",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "l_add_info"
      )[0]?.actual_value
    );
    setValue(
      "l_phone1",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "l_phone1"
      )[0]?.actual_value
    );
    setValue(
      "area_business_premises",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "area_business_premises"
      )[0]?.actual_value
    );
    setValue(
      "other_2",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "other_2"
      )[0]?.actual_value
    );
    setValue(
      "other_3",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "other_3"
      )[0]?.actual_value
    );
    setValue(
      "other_4",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "other_4"
      )[0]?.actual_value
    );

    setValue(
      "utilization",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "utilization"
      )[0]?.actual_value
    );
  }, [getConfigurationDetailsData]);

  const changeHandler = (e) => {
    if (e === "5") {
      setPartnersandrentedForm({
        ...partnersandrentedForm,
        typeOfRental: {
          other: true,
        },
      });
    } else {
      setPartnersandrentedForm({
        ...partnersandrentedForm,
        typeOfRental: {
          other: false,
        },
      });
      setValue("other_1", "");
    }
  };

  const handleZipCode = (e, ActionType) => {
    if (ActionType.action === "clear") {
      setValue("l_city", "");
    } else {
      if (e?.value != "" || e?.value != "undefined") {
        const HandleCityName = zipcodeData?.data?.filter(
          (data) => data?.Id == e?.value
        );
        const CityData = HandleCityName[0].Text;
        setValue("l_city", CityData);
      }
    }
  };

  const [streetname, setStreetname] = useState("");
  const [searchedStreetName, setSearchedStreetName] = useState("");
  const [isSearchStreetVisible, setIsSearchStreetVisible] = useState(false);
  const { data: getStreetDetailsData } =
    useGetStreetDetails(searchedStreetName);
  const CountryId = propOverview?.map((data) => data?.country_id)
  const handleInputChange = (event) => {
    setStreetname(event.target.value);
    if (CountryId == "DK" && streetname?.length >= 4) {
      setIsSearchStreetVisible(true);
      setSearchedStreetName(event?.target?.value);
    } else {
      setIsSearchStreetVisible(false);
    }
  };

  const handleItemClick = (item, e) => {
    setStreetname(item);
    // setFilteredStreetname([]);
    setValue(
      "l_zipcode",
      item?.l_zipcode === ""
        ? ""
        : {
          value: item?.zipcode,
          label: item?.zipcode,
        }
    );
    // Set the value directly
    setValue("l_city", item?.l_city === "" ? "" : item?.city);
    // setValue('addInfo', item?.address === "" ? "" : item?.address);
    setValue("l_no", item?.l_no === "" ? "" : item?.house_number);
    setValue("l_street", item?.l_street === "" ? "" : item?.street_name);
  };
  return (
    <div>
      <Form>
        <div className="main-form">
          {/* -----------Top Header----------- */}
          <div style={{ borderBottom: "1px solid #e5e7e8" }}>
            <div className="px-4 py-3">
              <div className="actors-section-title">
                §1 The partners and the rented
              </div>
              <div className="light-font-text grey-text">
                Lorem ipsum dolor sit lorem ipsum dolor sit
              </div>
            </div>
          </div>

          {/* -------------Type of rental -------------------- */}
          <div
            className="ps-lg-1"
            style={{ borderBottom: "1px solid #e5e7e8" }}
          >
            <div className="px-3 py-4">
              <div className="d-flex px-lg-1 align-items-center">
                <span className="me-2 form-label-title">Type of rental</span>
                <CustomToolTip size="sm" text="Type of rental" />
              </div>

              <div className="light-font-text px-lg-1">The rental is:</div>

              {/* {["radio"].map((type) => ( */}
              <div className="mt-3 ms-sm-3 ms-lg-0">
                <Row>
                  <Col xl={11}>
                    <Row className="flex-wrap ">
                      <Col lg={4} className="pe-lg-0 ps-sm-0 ps-lg-3">
                        <RadioWrapper>
                          <RadioInput
                            label="An apartment"
                            type="radio"
                            name="group1"
                            value="1"
                            id="radio-1"
                            {...register("rental_type", {
                              required: "rental is required",
                              onChange: (e) => changeHandler(e.target.value),
                            })}
                          />
                          <RadioLabel>An apartment</RadioLabel>
                        </RadioWrapper>
                        {/* <ETRadioButton
                          label="An apartment"
                          name="group1"
                          value="1"
                          id="radio-1"
                          {...register("rental_type", {
                            required: "rental is required",
                            onChange: (e) => changeHandler(e.target.value),
                          })}
                        /> */}
                      </Col>

                      <Col className="px-0" lg={4}>
                        {" "}
                        <RadioWrapper>
                          <RadioInput
                            name="group1"
                            type="radio"
                            id="radio-2"
                            value="2"
                            {...register("rental_type", {
                              required: "rental is required",
                              onChange: (e) => changeHandler(e.target.value),
                            })}
                          />
                          <RadioLabel>A single room</RadioLabel>
                        </RadioWrapper>
                      </Col>
                      <Col className="ps-0" lg={4}>
                        {" "}
                        <RadioWrapper>
                          <RadioInput
                            name="group1"
                            type="radio"
                            id="radio-3"
                            value="3"
                            {...register("rental_type", {
                              required: "rental is required",
                              onChange: (e) => changeHandler(e.target.value),
                            })}
                          />{" "}
                          <RadioLabel>A cooperative housing</RadioLabel>
                        </RadioWrapper>
                      </Col>
                    </Row>
                    <Row className="flex-wrap">
                      <Col lg={4} className="pe-0 mt-lg-2 ps-0 ps-lg-3 ">
                        <RadioWrapper>
                          <RadioInput
                            name="group1"
                            type="radio"
                            id="radio-4"
                            value="4"
                            {...register("rental_type", {
                              required: "rental is required",
                              onChange: (e) => changeHandler(e.target.value),
                            })}
                          />{" "}
                          <RadioLabel>A condominium</RadioLabel>
                        </RadioWrapper>
                      </Col>
                      <Col className="px-0 mt-lg-2" lg={8}>
                        <div className="d-flex">
                          <div>
                            <Controller
                              {...register("rental_type")}
                              control={control}
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <>
                                    <div className="d-flex flex-wrap gap-1">
                                      <RadioWrapper>
                                        <RadioInput
                                          {...register("rental_type", {
                                            required: "rental is required",
                                          })}
                                          type="radio"
                                          id="radio-5"
                                          value="5"
                                        />{" "}
                                        <RadioLabel>other</RadioLabel>
                                      </RadioWrapper>
                                      <div className="ps-2 position-relative d-flex flex-wrap">
                                        <input
                                          {...register("other_1", {
                                            required:
                                              value === "5" ? true : false,
                                          })}
                                          style={{ height: "26px" }}
                                          className="normalTextstyle "
                                          label="other"
                                          disabled={value !== "5"}
                                        />
                                        {errors.other_1 && value === "5" && (
                                          <div className="position-relative">
                                            <p className="error-msg">
                                              other is required
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                );
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  {errors.rental_type && (
                    <div>
                      <p className="error-msg">rental is required</p>
                    </div>
                  )}
                </Row>
              </div>
              {/* ))} */}

              <div
                className="d-flex mt-2"
                style={{ position: "relative", left: "-2px" }}
              >
                <Controller
                  {...register("ts")}
                  control={control}
                  render={({
                    field: { onChange, value = parseInt(getValues("ts")) },
                  }) => (
                    <CustomCheckBox
                      // paddingleft={"2px"}
                      id={"ts"}
                      checked={value}
                      onChange={(e) => {
                        // onChange(e),
                        e.target.checked
                          ? setValue("ts", 1)
                          : setValue("ts", 0);
                      }}
                    />
                  )}
                />

                <span className="light-font-text" style={{ paddingTop: "5px" }}>
                  The tenancy is a sublease
                </span>
              </div>
              <div className="mt-3 ps-lg-2">
                <div className="fs-14 fw-300">Located in:</div>
                <Row className="pt-2">
                  <Col lg={6} xxl={4} className="ps-2 mb-sm-3 mb-lg-0">
                    <div className="form-input">
                      <Controller
                        name="address"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <input
                            className="mx-sm-0"
                            type="text"
                            placeholder="Merged data"
                            id="address"
                            {...register("address")}
                            // value={"Merged data"}
                            disabled={true}
                          />
                        )}
                      />
                      <label for="address">address</label>
                    </div>
                    {errors.address && (
                      <div>
                        <p className="error-msg">Address is required</p>
                      </div>
                    )}
                  </Col>
                  <Col className=" ps-lg-0 ps-sm-2 pe-lg-4" lg={6} xxl={4}>
                    <div className="form-input">
                      <Controller
                        name="city"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <input
                            className="mx-sm-0"
                            type="text"
                            placeholder="Merged data"
                            id="city"
                            {...register("city")}
                            // value={"Merged data"}
                            disabled={true}
                          />
                        )}
                      />
                      <label for="city">City</label>
                    </div>
                    {errors.city && (
                      <div>
                        <p className="error-msg">City is required</p>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          {/* ----------------Landlord---------------- */}
          <div
            className="ps-lg-1"
            style={{ borderBottom: "1px solid #e5e7e8" }}
          >
            <div className="px-4 py-4">
              <div className="d-flex align-items-center">
                <span className="me-2 form-label-title">Landlord</span>
                <CustomToolTip size="sm" text="Landlord" />
              </div>
              <Row className="pt-3">
                <Col xxl={8}>
                  <Row>
                    <Col lg={12} className="ps-sm-0 ps-lg-2">
                      <Controller control={control}
                        {...register("l_name")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (

                          <div>
                            {/* <input
                          className="mx-sm-0"
                          type="text"
                          placeholder="Name or Company name"
                          id="l_name"
                          {...register("l_name", {
                            required: "Name or company name is required",
                          })}
                        /> */}
                            <InputField
                              id={"l_name"}
                              value={value}
                              type={"text"}

                              placeholder={"Name or company name"}

                              iserror={errors.l_name}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("l_name");
                              }}
                            />

                            {/* <label for="l_name">Name or company name</label> */}

                          </div>
                        )} />
                      {/* {errors.l_name && (
                        <div>
                          <p className="error-msg">
                            Name or company name is required
                          </p>
                        </div>
                      )} */}

                    </Col>
                  </Row>
                  <Row className="pt-3">
                    <Col lg={12} className="ps-lg-2 ps-0 mb-sm-3 mb-lg-0">
                      <div className="form-input">
                        <input
                          className="mx-sm-0"
                          type="text"
                          placeholder="First Name"
                          id="l_cvr"
                          {...register("l_cvr", {})}
                        />
                        <label for="l_cvr">
                          CVR (company registration number)
                        </label>
                      </div>
                      {/* {errors.firstName && (
                        <div>
                          <p className="error-msg">First name is required</p>
                        </div>
                      )} */}
                    </Col>
                  </Row>
                  <Row className="pt-3">
                    <Col lg={6} className="ps-sm-0 ps-lg-2 mb-sm-3 mb-lg-0">
                      <Controller control={control}
                        {...register("l_street")}
                        rules={{ required: true }}
                        render={({ field: { onChange, values } }) => (
                          <div >
                            {/* <input
                          className="mx-sm-0"
                          type="text"
                          placeholder="Street"
                          id="l_street"
                          // value={values}

                          {...register("l_street", {
                            required: "Street is required",
                          })}
                          onChange={handleInputChange}
                        /> */}
                            <InputField
                              id={"l_street"}
                              // value={values}
                              type={"text"}

                              placeholder={"Street"}

                              iserror={errors.l_street}

                              onChange={(e) => {
                                onChange(e)
                                handleInputChange(e);
                                clearErrors("l_street");
                              }}

                            />
                            {/* <label for="l_street">Street</label> */}
                          </div>
                        )} />
                      {/* {errors.l_street && (
                        <div>
                          <p className="error-msg">Street is required</p>
                        </div>
                      )} */}
                      {streetname?.length < 4 &&
                        streetname?.length > 0 &&
                        CountryId == "DK" ? (
                        <div className="fs-12 fw-400 grey-text pt-1 ">
                          Type in minimum 4 characters to get a search result
                        </div>
                      ) : (
                        <>
                          {isSearchStreetVisible && CountryId == "DK" && (
                            <div style={{}}>
                              <div
                                className="bg-white radius-8 pe-1 filtered-list"
                                style={{
                                  border: "1px solid #D1D1D1",
                                  position: "absolute",
                                  zIndex: "10",
                                  width: "30%",
                                }}
                              >
                                <>
                                  {getStreetDetailsData?.data?.length > 0 ? (
                                    <div>
                                      {getStreetDetailsData?.data?.map(
                                        (item, index) => (
                                          <div
                                            className="mt-1 px-2"
                                            key={index}
                                            onClick={() => {
                                              handleItemClick(item);
                                              setIsSearchStreetVisible(false);
                                            }}
                                          >
                                            {item?.city ||
                                              item?.zipcode ||
                                              item?.house_number ? (
                                              <> {item?.address} </>
                                            ) : (
                                              <> {item?.street_name}</>
                                            )}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ) : (
                                    <div className="mt-1 px-2 fw-300 grey-text">
                                      No data found
                                    </div>
                                  )}
                                </>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </Col>

                    <Col className="ps-0 mb-sm-3 mb-lg-0" lg={3}>
                      <Controller control={control}
                        {...register("l_no")}
                        rules={{ required: true }}
                        render={({ field: { onChange, values } }) => (
                          <div >
                            {/* <input
                          className="mx-sm-0"
                          type="text"
                          placeholder="No."
                          id="no"
                          // value={values}
                          {...register("l_no", {
                            required: "Street no. is required",
                          })}
                        /> */}
                            <InputField
                              id={"no"}
                              value={values}
                              type={"text"}

                              placeholder={"No."}

                              iserror={errors.l_no}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("l_no");
                              }}
                            />
                            {/* <label for="no">No.</label> */}
                          </div>
                        )} />
                      {/* {errors.l_no && (
                        <div>
                          <p className="error-msg">No. is required</p>
                        </div>
                      )} */}
                    </Col>
                    <Col className="ps-0" lg={3}>
                      <div className="form-input">
                        <input
                          className="mx-sm-0"
                          type="text"
                          placeholder="Add. info"
                          id="Addinfo"
                          {...register("l_add_info")}
                        />
                        <label for="Addinfo">Add. info</label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-3">
                    <Col
                      lg={5}
                      xl={4}
                      className="ps-sm-0 ps-lg-2 mb-sm-3 mb-lg-0"
                    >
                      <Controller
                        control={control}
                        name="l_zipcode"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <div>
                              <ReactSelect
                                value={
                                  value?.value
                                    ? {
                                      value: value?.value,
                                      label: value?.value,
                                    }
                                    : ""
                                }
                                options={zipcodeData?.data?.map((item) => ({
                                  value: item?.Id,
                                  label: `${item?.Id} ${item?.Text}`,
                                }))}
                                onChange={(e, ActionType) => {
                                  handleZipCode(e, ActionType);
                                  onChange(e);
                                }}
                                placeholder={"Zip code"}
                              />
                            </div>
                          );
                        }}
                      />
                    </Col>
                    <Col className="ps-0" lg={7} xl={8}>
                      <div className="form-input">
                        <input
                          disabled
                          className="mx-sm-0"
                          type="text"
                          placeholder="City"
                          id="citylandlord"
                          {...register("l_city")}
                        />
                        <label for="citylandlord">City</label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-3">
                    <Col
                      lg={5}
                      xl={5}
                      className="ps-sm-0 ps-lg-2 mb-sm-3 mb-lg-0"
                    >
                      <Controller
                        control={control}
                        name="l_country_code"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <div>
                              <ReactSelect
                                value={value?.value ? value : ""}
                                options={countryData?.data?.map((item) => ({
                                  value: item?.DataValue2,
                                  label: item?.DataValue2,
                                }))}
                                placeholder={"Country code"}
                                onChange={(e) => {
                                  // handleCountryCodeChange(e);
                                  onChange(e);
                                }}
                              />
                            </div>
                          );
                        }}
                      />
                    </Col>
                    <Col className="ps-0" lg={7} xl={7}>
                      <Controller control={control}
                        {...register("l_phone1")}
                        rules={{ required: true }}
                        render={({ field: { onChange, values } }) => (
                          <div >
                            {/* <input
                          className="mx-sm-0"
                          type="number"
                          placeholder="Phone"
                          id="phone"
                          {...register("l_phone1", {
                            required: "phone is required",
                          })}
                          onKeyPress={(e) => {
                            if (e.key === "e" || e.key === "-") {
                              e.preventDefault();
                            }
                          }}
                        /> */}
                            <InputField
                              id={"phone"}

                              type={"number"}

                              placeholder={"Phone"}
                              onKeyPress={(e) => {
                                if (e.key === "e" || e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              iserror={errors.l_phone1}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("l_phone1");
                              }}
                            />
                          </div>
                        )} />
                      {/* <label for="phone">Phone</label> */}
                      {/* </div>
                      {errors.l_phone1 && (
                        <div>
                          <p className="error-msg">Phone is required</p>
                        </div>
                      )} */}
                    </Col>
                  </Row>
                  <Row className="pt-3">
                    <Col lg={12} className="ps-sm-0 ps-lg-2 ">
                      <Controller
                        control={control}
                        {...register("l_email")}
                        rules={{ required: true }}
                        render={({ field: { onChange, values } }) => (
                          <div >
                            {/* <input
                          className="mx-sm-0"
                          type="text"
                          placeholder="Email"
                          id="l_email"
                          {...register("l_email", {
                            required: "Email is required",
                          })}
                        /> */}
                            <InputField
                              id={"l_email"}

                              type={"text"}
                              value={values}
                              placeholder={"Email"}
                              // errorscope={false}
                              iserror={errors.l_email}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("l_email");
                              }}
                            />
                            {/* <label htmlFor="l_email">Email</label> */}
                          </div>
                        )} />
                      {/* {errors.l_email && (
                        <div>
                          <p className="error-msg">Email is required</p>
                        </div>
                      )} */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>

          {/* ---------------- Tenancy------------------- */}
          <div
            className="ps-lg-1"
            style={{ borderBottom: "1px solid #e5e7e8" }}
          >
            <div className="px-4 py-4">
              <div className="d-flex align-items-center">
                <span className="me-2 form-label-title">Tenancy</span>
                <CustomToolTip size="sm" text="Tenancy" />
              </div>
              <Row className="pt-3">
                <Col xxl={8}>
                  <Row>
                    <Col lg={12} className="ps-lg-2 ps-0 mb-sm-3 mb-lg-0">
                      <div className="form-input">
                        <input
                          className="mx-sm-0"
                          type="text"
                          placeholder="Merged data"
                          id="t_name"
                          disabled={true}
                          {...register("t_name")}
                        />
                        <label for="t_name">Full name</label>
                      </div>
                      {errors.t_name && (
                        <div>
                          <p className="error-msg">Full name is required</p>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className="pt-3">
                    <Col lg={6} className="ps-sm-0 ps-lg-2 mb-sm-3 mb-lg-0">
                      <div className="form-input">
                        <input
                          className="mx-sm-0"
                          type="text"
                          placeholder="Merged data"
                          id="tenanacy_street"
                          // value={"Merged data"}
                          disabled={true}
                          {...register("t_street")}
                        />
                        <label for="t_street">Street</label>
                      </div>
                      {errors.t_street && (
                        <div>
                          <p className="error-msg">Street is required</p>
                        </div>
                      )}
                    </Col>
                    <Col className="ps-0 mb-sm-3 mb-lg-0" lg={3}>
                      <div className="form-input">
                        <input
                          className="mx-sm-0"
                          type="text"
                          placeholder="Merged data"
                          id="tenanacy_no"
                          disabled={true}
                          // value={"Merged data"}
                          {...register(
                            "t_no"
                            // , {
                            //   required: "Street no. is required",
                            // }
                          )}
                        />
                        <label for="t_no">No.</label>
                      </div>
                      {errors.t_no && (
                        <div>
                          <p className="error-msg">Street no. is required</p>
                        </div>
                      )}
                    </Col>
                    <Col className="ps-0" lg={3}>
                      <div className="form-input">
                        <input
                          className="mx-sm-0"
                          type="text"
                          placeholder="Merged data"
                          id="tenanacy_Addinfo"
                          disabled={true}
                          // value={"Merged data"}
                          {...register(
                            "t_add_info"
                            // , {
                            //   required: "Add. info is required",
                            // }
                          )}
                        />
                        <label for="t_add_info">Add. info</label>
                      </div>
                      {errors.t_add_info && (
                        <div>
                          <p className="error-msg">Add. info is required</p>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className="pt-3">
                    <Col lg={4} className="ps-sm-0 ps-lg-2 mb-sm-3 mb-lg-0">
                      <Controller
                        control={control}
                        name="t_zipcode"
                        render={({ field: { onChange, value } }) => {
                          return (
                            // <div>
                            //   <ReactSelect
                            //     value={value}
                            //     disabled={true}
                            //     options={zipcodeData?.data?.map((item) => ({
                            //       value: item?.Id,
                            //       label: `${item?.Id} ${item?.Text}`,
                            //     }))}
                            //     onChange={(e) => {
                            //       handleTenancyZipcodeChage(e);
                            //       onChange(e);
                            //     }}
                            //     placeholder={"Zip code"}
                            //   />
                            // </div>
                            <div className="form-input">
                              <input
                                className="mx-sm-0"
                                type="text"
                                placeholder="Merged data"
                                disabled={true}
                                // value={"Merged data"}
                                {...register("t_zipcode")}
                              />
                              <label for="t_zipcode">Zip code</label>
                            </div>
                          );
                        }}
                      />
                    </Col>
                    <Col className="ps-0" lg={8}>
                      <div className="form-input">
                        <input
                          disabled={true}
                          className="mx-sm-0"
                          type="text"
                          placeholder="City"
                          id="t_city"
                          // value={"Merged data"}
                          {...register("t_city")}
                        />
                        <label for="t_city">City</label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-3">
                    <Col lg={4} className="ps-sm-0 ps-lg-2 mb-sm-3 mb-lg-0">
                      <Controller
                        control={control}
                        name="t_country_code"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <div>
                              {/* <ReactSelect
                                  // value={value}
                                value   = {"Merged data"}
                                options = {countryData?.data?.map((item) => ({
                                  value: item?.DataValue2,
                                  label: item?.DataValue2,
                                }))}
                                disabled    = {true}
                                placeholder = {"Country code"}
                                onChange    = {(e) => onChange(e)}
                              /> */}
                              <div className="form-input">
                                <input
                                  className="mx-sm-0"
                                  type="text"
                                  placeholder="Merged data"
                                  disabled={true}
                                  // value={"Merged data"}
                                  {...register("t_country_code")}
                                />
                                <label for="t_country_code">Country code</label>
                              </div>
                            </div>
                          );
                        }}
                      />
                    </Col>
                    <Col className="ps-0" lg={8}>
                      <div className="form-input">
                        <input
                          className="mx-sm-0"
                          type="text"
                          placeholder="Merged data"
                          id="t_phone1"
                          disabled={true}
                          // value={"Merged data"}
                          {...register("t_phone1")}
                        />
                        <label for="t_phone1">Phone</label>
                      </div>
                      {errors.t_phone1 && (
                        <div>
                          <p className="error-msg">Phone is required</p>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className="pt-3">
                    <Col lg={12} className="ps-sm-0 ps-lg-2 ">
                      <div className="form-input">
                        <input
                          className="mx-sm-0"
                          type="text"
                          placeholder="Merged data"
                          id="tenanacy_email"
                          disabled={true}
                          // value={"Merged data"}
                          {...register(
                            "t_email"
                            // , {
                            //   required: "Email is required",
                            // }
                          )}
                        />
                        <label for="t_email">Email</label>
                      </div>
                      {errors.t_email && (
                        <div>
                          <p className="error-msg">Email is required</p>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>

          {/* --------------Area Section---------------- */}
          <div style={{ borderBottom: "1px solid #e5e7e8" }}>
            <div className="px-4 py-4">
              <div className="d-flex align-items-center">
                <span className="me-2 form-label-title">Area</span>
                <CustomToolTip size="sm" text="Area Section" />
              </div>
              <div className="light-font-text">
                The total gross floor area of the lease is
                <span
                  className="mb-2 ms-1"
                  style={{ position: "relative", display: "inline-flex" }}
                >
                  {/* <Controller
                    control={control}
                    {...register("area")}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <NormalGroupText
                          inputheight={"26px"}
                          textheight={"26px"}
                          lable={value}
                          graylabel="m"
                          textWidth="30%"
                          type="number"
                          squarelabel="2"
                          disabled={true}
                          value={value}
                        />
                      );
                    }}
                  /> */}
                  <input
                    className="normalTextstyle "
                    disabled={true}
                    value={getValues("area")}
                  />
                </span>{" "}
                which consists of
                <span
                  className="mb-2 mx-1"
                  style={{ position: "relative", display: "inline-flex" }}
                >
                  <input
                    className="normalTextstyle "
                    disabled={true}
                    value={getValues("no_of_rooms")}
                  />
                </span>
                rooms. Business premises, etc. constitutes of this
                <span
                  className="ms-1"
                  style={{ position: "relative", display: "inline-flex" }}
                >
                  <input
                    className="normalTextstyle "
                    disabled={true}
                    value={getValues("area_business_premises")}
                  />
                  {/* <Controller
                    control={control}
                    {...register("area_business_premises")}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <NormalGroupText
                          inputheight={"26px"}
                          textheight={"26px"}
                          lable={value}
                          squarelabel="2"
                          graylabel="m"
                          textWidth="30%"
                          type="number"
                          disabled={true}
                          value={value}
                        />
                      );
                    }}
                  /> */}
                </span>
              </div>
            </div>
          </div>
          {/* --------------Right of Use---------------- */}
          <div style={{ borderBottom: "1px solid #e5e7e8" }}>
            <div className="px-4 py-4">
              <div className="d-flex align-items-center">
                <span className="me-2 form-label-title">Right of use to</span>
                <CustomToolTip size="sm" text="Right of Use to" />
              </div>
              <Row>
                <Col xxl={12}>
                  <div className="light-font-text">
                    After the agreement, the tenant also has, according to the
                    landlord's instructions, access and right of use to the
                    following premises:
                  </div>

                  {/* <div className="mt-2 d-flex">
                    <div className = "d-inline-block">
                      <CustomCheckBox
                        // checked={checked}
                        // onChange={(e) => onChange(!checked)}
                      />
                    </div>
                    <Controller
                      control = {control}
                      name    = "partnerAndRentedSelectFacility4"
                      render  = {({ field: { onChange, value } }) => {
                        return (
                          <div
                            className = "position-relative w-50"
                            style     = {{ maxWidth: "300px" }}
                          >
                            <ReactSelect
                              placeholder = {"Select Facility"}
                              value       = {value}
                              options     = {GetFacilityByPropertyIdData?.data?.map(
                                (item) => ({
                                  value: item?.facility_id,
                                  label: item?.facility_name,
                                })
                              )}
                              onChange   = {(e) => onChange(e)}
                              clearable  = {false}
                              searchable = {false}
                            />
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div className = "mt-2 d-flex">
                  <div className = "d-inline-block">
                      <CustomCheckBox
                        // checked={checked}
                        // onChange={(e) => onChange(!checked)}
                      />
                    </div>
                    <Controller
                      control = {control}
                      name    = "partnerAndRentedSelectFacility5"
                      render  = {({ field: { onChange, value } }) => {
                        return (
                          <div
                            className = "position-relative w-50"
                            style     = {{ maxWidth: "300px" }}
                          >
                            <ReactSelect
                              placeholder = {"Select Facility"}
                              value       = {value}
                              options     = {GetFacilityByPropertyIdData?.data?.map(
                                (item) => ({
                                  value: item?.facility_id,
                                  label: item?.facility_name,
                                })
                              )}
                              onChange   = {(e) => onChange(e)}
                              clearable  = {false}
                              searchable = {false}
                            />
                          </div>
                        );
                      }}
                    />
                  </div> */}

                  {/* <div className="mt-2 d-flex">
                    <div className = "d-inline-block">
                      <CustomCheckBox
                        // checked={checked}
                        // onChange={(e) => onChange(!checked)}
                      />
                    </div>
                    <Controller
                      control = {control}
                      name    = "partnerAndRentedSelectFacility6"
                      render  = {({ field: { onChange, value } }) => {
                        return (
                          <div
                            className = "position-relative w-50"
                            style     = {{ maxWidth: "300px" }}
                          >
                            <ReactSelect
                              placeholder = {"Select Facility"}
                              value       = {value}
                              options     = {GetFacilityByPropertyIdData?.data?.map(
                                (item) => ({
                                  value: item?.facility_id,
                                  label: item?.facility_name,
                                })
                              )}
                              onChange   = {(e) => onChange(e)}
                              clearable  = {false}
                              searchable = {false}
                            />
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div className = "mt-2 d-flex">
                  <div className = "d-inline-block">
                      <CustomCheckBox
                        // checked={checked}
                        // onChange={(e) => onChange(!checked)}
                      />
                    </div>
                    <Controller
                      control = {control}
                      name    = "partnerAndRentedSelectFacility7"
                      render  = {({ field: { onChange, value } }) => {
                        return (
                          <div
                            className = "position-relative w-50"
                            style     = {{ maxWidth: "300px" }}
                          >
                            <ReactSelect
                              placeholder = {"Select Facility"}
                              value       = {value}
                              options     = {GetFacilityByPropertyIdData?.data?.map(
                                (item) => ({
                                  value: item?.facility_id,
                                  label: item?.facility_name,
                                })
                              )}
                              onChange   = {(e) => onChange(e)}
                              clearable  = {false}
                              searchable = {false}
                            />
                          </div>
                        );
                      }}
                    />
                  </div>

                  <div className = "mt-2 d-flex">
                  <div className = "d-inline-block">
                      <CustomCheckBox
                        // checked={checked}
                        // onChange={(e) => onChange(!checked)}
                      />
                    </div>
                    <Controller
                      control = {control}
                      name    = "partnerAndRentedSelectFacility8"
                      render  = {({ field: { onChange, value } }) => {
                        return (
                          <div
                            className = "position-relative w-50"
                            style     = {{ maxWidth: "300px" }}
                          >
                            <ReactSelect
                              placeholder = {"Select Facility"}
                              value       = {value}
                              options     = {GetFacilityByPropertyIdData?.data?.map(
                                (item) => ({
                                  value: item?.facility_id,
                                  label: item?.facility_name,
                                })
                              )}
                              onChange   = {(e) => onChange(e)}
                              clearable  = {false}
                              searchable = {false}
                            />
                          </div>
                        );
                      }}
                    />
                  </div> */}
                  {/* <div className="d-flex">
                    <span className = "pt-2 fs-14 fw-300">
                      Retrieve data from unit
                    </span>
                    <Toggle
                      checked  = {rightOfUseTo.retrieveDataFromUnit}
                      onChange = {() =>
                        setRightOfUseTo({
                          ...rightOfUseTo,
                          retrieveDataFromUnit: 
                            !rightOfUseTo.retrieveDataFromUnit,
                        })
                      }
                    />
                  </div> */}
                  <div className="d-flex">
                    <Controller
                      {...register("fac1")}
                      control={control}
                      render={({
                        field: {
                          onChange,
                          value = parseInt(getValues("fac1")),
                        },
                      }) => (
                        <CustomCheckBox
                          checked={value}
                          onChange={(e) => {
                            // onChange(e),
                            e.target.checked
                              ? setValue("fac1", 1)
                              : setValue("fac1", 0);
                          }}
                        />
                      )}
                    />
                    <span
                      className="light-font-text "
                      style={{ paddingTop: "5px" }}
                    >
                      Shared laundry
                    </span>
                  </div>
                  <div className="d-flex">
                    <Controller
                      {...register("fac2")}
                      control={control}
                      render={({
                        field: {
                          onChange,
                          value = parseInt(getValues("fac2")),
                        },
                      }) => (
                        <CustomCheckBox
                          checked={value}
                          onChange={(e) => {
                            // onChange(e),
                            e.target.checked
                              ? setValue("fac2", 1)
                              : setValue("fac2", 0);
                          }}
                        />
                      )}
                    />
                    <span
                      className="light-font-text"
                      style={{ paddingTop: "5px" }}
                    >
                      Bicycle parking
                    </span>
                  </div>
                  <div className="d-flex">
                    <Controller
                      {...register("fac3")}
                      control={control}
                      render={({
                        field: {
                          onChange,
                          value = parseInt(getValues("fac3")),
                        },
                      }) => (
                        <CustomCheckBox
                          checked={value}
                          onChange={(e) => {
                            // onChange(e),
                            e.target.checked
                              ? setValue("fac3", 1)
                              : setValue("fac3", 0);
                          }}
                        />
                      )}
                    />
                    <span
                      className="light-font-text"
                      style={{ paddingTop: "5px" }}
                    >
                      Common courtyards
                    </span>
                  </div>
                  <div className="d-flex">
                    <Controller
                      {...register("fac4")}
                      control={control}
                      render={({
                        field: {
                          onChange,
                          value = parseInt(getValues("fac4")),
                        },
                      }) => (
                        <CustomCheckBox
                          checked={value}
                          onChange={(e) => {
                            // onChange(e),
                            e.target.checked
                              ? setValue("fac4", 1)
                              : setValue("fac4", 0);
                          }}
                        />
                      )}
                    />
                    <span
                      className="light-font-text"
                      style={{ paddingTop: "5px" }}
                    >
                      Garage no.:
                    </span>
                    <span className="ps-1 pt-1">
                      <input
                        className="normalTextstyle ps-2"
                        disabled={true}
                        value={getValues("garage_no")}
                      />
                    </span>
                  </div>
                  <div className="d-flex">
                    <Controller
                      {...register("fac5")}
                      control={control}
                      render={({
                        field: {
                          onChange,
                          value = parseInt(getValues("fac5")),
                        },
                      }) => (
                        <CustomCheckBox
                          checked={value}
                          onChange={(e) => {
                            // onChange(e),
                            e.target.checked
                              ? setValue("fac5", 1)
                              : setValue("fac5", 0);
                          }}
                        />
                      )}
                    />
                    <span
                      className="light-font-text"
                      style={{ paddingTop: "5px" }}
                    >
                      Attic/basement room no.:
                    </span>
                    <span className="ps-1 pt-1">
                      <input
                        className="normalTextstyle ps-2"
                        disabled={true}
                        value={getValues("attic")}
                      />
                    </span>
                  </div>

                  {/* <Row>
                    <Col xl        = {5} xxl = {12}>
                    <div className = "mt-2 d-flex">
                    <div className = "d-inline-block">
                      <CustomCheckBox
                          // checked={checked}
                          // onChange={(e) => onChange(!checked)}
                        {...(!rightOfUseTo.retrieveDataFromUnit && {
                          disabled: true,
                        })}
                      />
                    </div>
                    <Controller
                      control = {control}
                      name    = "partnerAndRentedSelectFacility1"
                      render  = {({ field: { onChange, value } }) => {
                        return (
                          <div
                            className = "position-relative w-100"
                            style     = {{ maxWidth: "300px" }}
                          >
                            <ReactSelect
                              placeholder = {"Select Facility"}
                              value       = {value}
                              options     = {GetFacilityByPropertyIdData?.data?.map(
                                (item) => ({
                                  value: item?.facility_id,
                                  label: item?.facility_name,
                                })
                              )}
                              onChange   = {(e) => onChange(e)}
                              clearable  = {false}
                              searchable = {false}
                              {...(!rightOfUseTo.retrieveDataFromUnit && {
                                disabled: true,
                              })}
                            />
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className = "mt-2 d-flex">
                  <div className = "d-inline-block">
                      <CustomCheckBox
                          // checked={checked}
                          // onChange={(e) => onChange(!checked)}
                        {...(!rightOfUseTo.retrieveDataFromUnit && {
                          disabled: true,
                        })}
                      />
                    </div>
                    <Controller
                      control = {control}
                      name    = "partnerAndRentedSelectFacility2"
                      render  = {({ field: { onChange, value } }) => {
                        return (
                          <div
                            className = "position-relative w-100"
                            style     = {{ maxWidth: "300px" }}
                          >
                            <ReactSelect
                              placeholder = {"Select Facility"}
                              value       = {value}
                              options     = {GetFacilityByPropertyIdData?.data?.map(
                                (item) => ({
                                  value: item?.facility_id,
                                  label: item?.facility_name,
                                })
                              )}
                              onChange   = {(e) => onChange(e)}
                              clearable  = {false}
                              searchable = {false}
                            />
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className = "mt-2 d-flex">
                  <div className = "d-inline-block">
                      <CustomCheckBox
                          // checked={checked}
                          // onChange={(e) => onChange(!checked)}
                        {...(!rightOfUseTo.retrieveDataFromUnit && {
                          disabled: true,
                        })}
                      />
                    </div>
                    <Controller
                      control = {control}
                      name    = "partnerAndRentedSelectFacility3"
                      render  = {({ field: { onChange, value } }) => {
                        return (
                          <div
                            className = "position-relative w-100"
                            style     = {{ maxWidth: "300px" }}
                          >
                            <ReactSelect
                              placeholder = {"Select Facility"}
                              value       = {value}
                              options     = {GetFacilityByPropertyIdData?.data?.map(
                                (item) => ({
                                  value: item?.facility_id,
                                  label: item?.facility_name,
                                })
                              )}
                              onChange   = {(e) => onChange(e)}
                              clearable  = {false}
                              searchable = {false}
                            />
                          </div>
                        );
                      }}
                    />
                  </div></Col>
                  </Row> */}

                  <div className="d-flex mt-1">
                    {/* <CustomCheckBox
                      checked  = {rightOfUseTo.retrieveDataFromUnitCheckbox.other1}
                      onChange = {() =>
                        setRightOfUseTo({
                          ...rightOfUseTo,
                          retrieveDataFromUnitCheckbox: {
                            ...rightOfUseTo.retrieveDataFromUnitCheckbox,
                            other1: 
                              !rightOfUseTo.retrieveDataFromUnitCheckbox.other1,
                          },
                        })
                      }
                    /> */}
                    <Controller
                      {...register("fac6")}
                      control={control}
                      render={({
                        field: {
                          onChange,
                          value = parseInt(getValues("fac6")),
                        },
                      }) => (
                        <CustomCheckBox
                          checked={value}
                          onChange={(e) => {
                            e.target.checked
                              ? setValue("fac6", 1)
                              : setValue("fac6", 0);
                            setValue("other_2", "");
                            setRightOfUseTo({
                              ...rightOfUseTo,
                              retrieveDataFromUnitCheckbox: {
                                ...rightOfUseTo.retrieveDataFromUnitCheckbox,
                                other1: !value,
                              },
                            });
                          }}
                        />
                      )}
                    />
                    <div
                      className="light-font-text d-flex gap-2"
                      style={{ paddingTop: "5px" }}
                    >
                      Other
                      <div className="form-input">
                        <Controller
                          name="other_2"
                          control={control}
                          render={({
                            field: { onChange, value = getValues("other_2") },
                          }) => (
                            <input
                              {...register("other_2")}
                              className="mx-sm-0"
                              type="text"
                              style={{ height: "25px" }}
                              disabled={
                                !rightOfUseTo.retrieveDataFromUnitCheckbox
                                  ?.other1
                              }
                              value={value}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex mt-1">
                    <Controller
                      {...register("fac7")}
                      control={control}
                      render={({
                        field: {
                          onChange,
                          value = parseInt(getValues("fac7")),
                        },
                      }) => (
                        <CustomCheckBox
                          checked={value}
                          onChange={(e) => {
                            e.target.checked
                              ? setValue("fac7", 1)
                              : setValue("fac7", 0);
                            setValue("other_3", "");
                            setRightOfUseTo({
                              ...rightOfUseTo,
                              retrieveDataFromUnitCheckbox: {
                                ...rightOfUseTo.retrieveDataFromUnitCheckbox,
                                other2: !value,
                              },
                            });
                          }}
                        />
                      )}
                    />
                    {/* <CustomCheckBox
                      checked  = {rightOfUseTo.retrieveDataFromUnitCheckbox.other2}
                      onChange = {() =>
                        setRightOfUseTo({
                          ...rightOfUseTo,
                          retrieveDataFromUnitCheckbox: {
                            ...rightOfUseTo.retrieveDataFromUnitCheckbox,
                            other2: 
                              !rightOfUseTo.retrieveDataFromUnitCheckbox.other2,
                          },
                        })
                      }
                    /> */}
                    <div
                      className="light-font-text d-flex gap-2"
                      style={{ paddingTop: "5px" }}
                    >
                      Other
                      <div className="form-input">
                        <Controller
                          name="other_3"
                          control={control}
                          render={({
                            field: { onChange, value = getValues("other_3") },
                          }) => (
                            <input
                              {...register("other_3")}
                              className="mx-sm-0"
                              type="text"
                              style={{ height: "25px" }}
                              disabled={
                                !rightOfUseTo.retrieveDataFromUnitCheckbox
                                  ?.other2
                              }
                              value={value}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex mt-1">
                    {/* <CustomCheckBox
                      checked  = {rightOfUseTo.retrieveDataFromUnitCheckbox.other3}
                      onChange = {() =>
                        setRightOfUseTo({
                          ...rightOfUseTo,
                          retrieveDataFromUnitCheckbox: {
                            ...rightOfUseTo.retrieveDataFromUnitCheckbox,
                            other3: 
                              !rightOfUseTo.retrieveDataFromUnitCheckbox.other3,
                          },
                        })
                      }
                    /> */}
                    <Controller
                      {...register("fac8")}
                      control={control}
                      render={({
                        field: {
                          onChange,
                          value = parseInt(getValues("fac8")),
                        },
                      }) => (
                        <CustomCheckBox
                          checked={value}
                          onChange={(e) => {
                            e.target.checked
                              ? setValue("fac8", 1)
                              : setValue("fac8", 0);
                            setValue("other_4", "");
                            setRightOfUseTo({
                              ...rightOfUseTo,
                              retrieveDataFromUnitCheckbox: {
                                ...rightOfUseTo.retrieveDataFromUnitCheckbox,
                                other3: !value,
                              },
                            });
                          }}
                        />
                      )}
                    />
                    <div
                      className="light-font-text d-flex gap-2"
                      style={{ paddingTop: "5px" }}
                    >
                      Other
                      <div className="form-input">
                        <Controller
                          name="other_4"
                          control={control}
                          render={({
                            field: { onChange, value = getValues("other_4") },
                          }) => (
                            <input
                              {...register("other_4")}
                              className="mx-sm-0"
                              type="text"
                              style={{ height: "25px" }}
                              disabled={
                                !rightOfUseTo.retrieveDataFromUnitCheckbox
                                  ?.other3
                              }
                              value={value}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {/* ------------------Utilization---------------- */}
          <div style={{ borderBottom: "1px solid #e5e7e8" }}>
            <div className="px-4 py-4">
              <div className="d-flex align-items-center">
                <span className="me-2 form-label-title">Utilization</span>
                <CustomToolTip size="sm" text="Utilization" />
              </div>

              <Row>
                <Col xxl={8}>
                  <div className="light-font-text">
                    The rented property may not be used for any purpose other
                    than that for which it was rented without the landlord's
                    written consent:
                  </div>
                  <div className="mt-2">
                    <div className="form-input">
                      <input
                        className="mx-sm-0"
                        type="text"
                        {...register("utilization")}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <button
          type="submit"
          onClick={handleSubmit(saveData)}
          ref={submitRef}
          style={{ display: "none" }}
        />
        <button
          type="submit"
          onClick={handleSubmit(preview)}
          ref={previewRef}
          style={{ display: "none" }}
        />
      </Form>
    </div>
  );
};

export default Partnersandrented;
