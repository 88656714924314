import React, { useCallback } from "react";
import { toPng, toJpeg, toSvg } from "html-to-image";

export default function SVGWidget({ refObj, fileName }) {
  // SVG

  const svgClick = useCallback(() => {
    setTimeout(function () {
      if (refObj.current === null) {
        return;
      }

      toSvg(refObj.current, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `${fileName}.svg`;
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    }, 500);
  }, [refObj]);

  return svgClick;
}
