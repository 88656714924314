import { useMutation, useQuery } from "react-query";
import {
  DeleteComapany,
  GetCompanyByCompanyId,
  GetCompanyByLevel,
  GetCompanyByOrganizationId,
  GetCompanyByPublicType,
  GetCompanyOverviewByCompanyId,
  GetCompanyPublicType,
  PostAddCompany,
  PutUpdateCompany,
  UpdateCompany,
} from "../../services/Company";

// Get Company By Organisation Id
export const useGetCompanyByOrganizationId = (Id) => {
  return useQuery(
    ["qGetCompanyByOrganizationId", Id],
    () => GetCompanyByOrganizationId(Id),
    {
      enabled: !!Id,
    }
  );
};

//Get Company By Level
export const useGetCompanyByLevel = (level, organisation_id, type) => {
  return useQuery(
    ["qGetCompanyByLevel", level, organisation_id, type],
    () => GetCompanyByLevel(level, organisation_id, type),
    {
      enabled: !!level,
      enabled: !!organisation_id,
      enabled: !!type,
    }
  );
};

// Get Company By public type
export const useGetCompanyByPublicType = () => {
  return useQuery(["qGetCompanyByPublicType"], () => GetCompanyByPublicType());
};

// Post Add Company
export const usePostAddCompany = () => {
  return useMutation(PostAddCompany);
};

// Delete Company
export const useDeleteCompany = () => {
  return useMutation(DeleteComapany);
};

// Company Get Company By CompanyId

export const useGetCompanyByCompanyId = (companyId) => {
  return useQuery(
    ["qGetCompanyByCompanyId", companyId],
    () => GetCompanyByCompanyId(companyId),
    {
      enabled: !!companyId,
    }
  );
};

// Upadate Company details
export const useUpdateCompany = () => {
  return useMutation(UpdateCompany);
};

//Get company overview by company Id
export const useGetCompanyOverviewById = (company_id) => {
  return useQuery(
    ["qGetCompanyOverviewByCompanyId", company_id],
    () => GetCompanyOverviewByCompanyId(company_id),
    {
      enabled: !!company_id,
    }
  );
};
