import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled, { css } from "styled-components";

const GroupButtons = ({ tabs, currentTab, handleTabClick, height }) => {
  return (
    <MyTabsWrapper>
      {tabs.map((tab, i) => (
        <Tab
          key={i}
          id={i}
          mode={String(i === currentTab)}
          className="py-1"
          style={{
            height: height,
            border: "1px solid #d1d1d1",
            borderRadius: `${i === 0 ? 8 : 0}px ${
              i + 1 === tabs?.length ? 8 : 0
            }px ${i + 1 === tabs?.length ? 8 : 0}px ${i === 0 ? 8 : 0}px`,
          }}
          onClick={() => handleTabClick(i)}
        >
          {tab.iconName != "" &&
            (String(i === currentTab) == "true" ? (
              <span
                style={{ width: "12px", height: "12px" }}
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon
                  icon={tab?.iconName}
                  style={{ color: "white" }}
                  size="sm"
                />
              </span>
            ) : (
              <span
                style={{ width: "12px", height: "12px" }}
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon
                  icon={tab?.iconName}
                  style={{ color: "black" }}
                  size="sm"
                />
              </span>
            ))}{" "}
          {tab.tabTitle}
        </Tab>
      ))}
    </MyTabsWrapper>
  );
};

GroupButtons.defaultProps = {
  tabs: [
    {
      id: 1,
      tabTitle: "Tab Title",
      title: "Title",
      content: "This page contains data related to tab",
      iconName: "",
    },
    {
      id: 2,
      tabTitle: "Tab Title",
      title: "Title",
      content: "This page contains data related to tab",
      iconName: "",
    },
  ],
  currentTab: 0,
  bordercolor: "#888888",
  height: "44px",
};

export default GroupButtons;

const MyTabsWrapper = styled.div`
  display: flex;
  justify-content: start;
  width: -webkit-fill-available;
`;

const Tab = styled.span(
  ({ mode }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${mode === "true"
      ? (props) => props.theme.component.checkbox.primary.color
      : "white"};
    border: 1px solid #d1d1d1;
    color: ${mode === "true" ? "#FFFFFF" : "#000000"};
    cursor: pointer;
    height: 44px;
    font-size: 14px;
    border-radius: 8px;
    font-family: Cerebri sans-medium;
    width: -webkit-fill-available;
    gap: 8px;
  `
);
