import { create } from "zustand";

export const useLanguage = create((set, get) => ({

    selectedLanguageId: ([]),
    setSelectedLanguageId: (data) => set({ selectedLanguageId: data }),

    selectedLanguageText: ([]),
    setSelectedLanguageText: (data) => set({ selectedLanguageText: data }),

    selectedLanguageFlag: ([]),
    setSelectedLanguageFlag: (data) => set({ selectedLanguageFlag: data }),

    selectedLanguageData: ([]),
    setSelectedLanguageData: (data) => set({ selectedLanguageData: data }),

}));
