import { useState } from "react";
import CustomPopup from "../../../../Components/CustomPopup";
import ETButton from "../../../../Components/Buttons/ETButton";
import Card from "../../../../Components/card/TextCard";
import MediaCard from "../../../../Components/card/MediaCard";
import FacilityCard from "../../../../Components/card/FacilityCard";
import DocumentCard from "../../../../Components/card/DocumentCard";
import SigningCard from "../../../../Components/card/SigningCard";

//-------Media Card--------//
export const Media = ({ data, mediaPackage, setMediaPackage }) => {
  const [mediaStyle, setMediaStyle] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);

  const handleMediaDelete = () => {
    let newArray = mediaPackage?.filter(
      (el) => el?.media_package_id !== data?.media_package_id
    );
    setMediaPackage(newArray);
  };
  return (
    <>
      <MediaCard data={data} isTag={"M"} setDeletePopUp={setDeletePopUp} />
      <CustomPopup
        style={{ position: "relative" }}
        size="md"
        open={deletePopUp}
        onHide={() => setDeletePopUp(false)}
        title="Are you sure you want to delete this media package?"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() => {
          setDeletePopUp(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div>
            <div className="form-label-title ">Are you sure you want to delete the Package</div>
            <div className="light-font-text ">{data?.package_name}</div>
          </div>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="NO"
            onClick={() => setDeletePopUp(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="YES"
            onClick={handleMediaDelete}
          />
        </div>
      </CustomPopup>
    </>
  );
};

//-------Text Card--------//
export const TextCard = ({ data, propertyText, setPropertyText }) => {
  const [mediaStyle, setMediaStyle] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);

  const handleTextDelete = () => {
    let newArray = propertyText?.filter(
      (el) => el?.property_text_id !== data?.property_text_id
    );
    setPropertyText(newArray);
  };
  return (
    <>
      <Card
        isTag={"T"}
        textDetailsData={data}
        setDeletePopUp={setDeletePopUp}
      />
      {/* <div
        style={{
          backgroundColor: mediaStyle ? "#ECF1E6" : "#F9FAFA",
          border: mediaStyle ? "1px solid #619130" : " 1px solid #F0F0F0",
          maxHeight: "fit-content",
          width: " 257px",
          padding: "16px",
          borderRadius: "8px",
          position: "relative",
        }}
      >
        <div className="Card-Name">
          <span
            style={{
              position: "absolute",
              right: "1px",
              top: "1px",
            }}
          >
          </span>

          <div className="form-label-title fs-12">Package name</div>
          <div className="fs-12 light-font-text">
            <Row>
              <Col md={12}> {data?.package_name} </Col>
            </Row>
          </div>
        </div>

        <div className="Source-Type">
          <div className="form-label-title fs-12">Category</div>
          <div className="fs-12 light-font-text">{data?.text_category}</div>
        </div>
        <div
          className="Media"
          style={{
            background: "#FFFFFF",
            border: "1px solid #F0F0F0",
            borderRadius: " 8px",
            padding: "10px",
            fontSize: "10px",
          }}
        >
          <span style={{ overflow: "hidden" }}>{data?.text_value}</span>
        </div>
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}
        >
          <Menus
            itemheight={23}
            itemwidth={23}
            iconborderradius="4px"
            component={
              <div className=" light-font-text">
                <div
                  className="addpropContent px-3 pt-2 pb-2"
                  onClick={() => setDeletePopUp(true)}
                >
                  <span className="ms-1">Delete</span>
                </div>
              </div>
            }
          />
        </div>
      </div>
       */}
      <CustomPopup
        style={{ position: "relative" }}
        size="md"
        open={deletePopUp}
        onHide={() => setDeletePopUp(false)}
        title="Are you sure you want to delete this text package?"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() => {
          setDeletePopUp(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div>
            <div className="form-label-title fs-12">Package name</div>
            <div className=" light-font-text">{data?.package_name}</div>
          </div>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="NO"
            onClick={() => setDeletePopUp(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="YES"
            onClick={handleTextDelete}
          />
        </div>
      </CustomPopup>
    </>
  );
};

//-------Facilities Card--------//
export const FacilitiesCard = ({
  data,
  facilityPackage,
  setFacilityPackage,
}) => {
  const [mediaStyle, setMediaStyle] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);

  const handleFacilityDelete = () => {
    let newArray = facilityPackage?.filter(
      (el) => el?.facility_package_id !== data?.facility_package_id
    );
    setFacilityPackage(newArray);
  };
  return (
    <>
      <FacilityCard data={data} setDeletePopUp={setDeletePopUp} isTag={"F"} />
      {/* <div
        style={{
          backgroundColor: mediaStyle ? "#ECF1E6" : "#F9FAFA",
          border: mediaStyle ? "1px solid #619130" : " 1px solid #F0F0F0",
          maxHeight: "fit-content",
          width: " 257px",
          padding: "16px",
          borderRadius: "8px",
          position: "relative",
        }}
      >
        <div className="Card-Name">
          <span
            style={{
              position: "absolute",
              right: "1px",
              top: "1px",
            }}
          >
            {" "}

          </span>
          <div className="form-label-title fs-12">Package name</div>
          <div className="light-font-text fs-12">
            <Row>
              <Col md={12}> {data?.title} </Col>
            </Row>
          </div>
        </div>

        <div className="Source-Type">
          <div className="form-label-title fs-12">Number of facilities</div>
          <div className="light-font-text fs-12">
            {data?.facility_count}{" "}
            {data?.facility_count <= 1 ? "facility" : "facilities"}
          </div>
        </div>
        <div
          className="Media"
          style={{
            background: "#FFFFFF",
            border: "1px solid #F0F0F0",
            borderRadius: " 8px",
            padding: "10px",
            fontSize: "10px",
          }}
        >
          {data?.icons}
        </div>
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}
        >
          <Menus
            itemheight={23}
            itemwidth={23}
            iconborderradius="4px"
            component={
              <div style={{ fontSize: "14px" }}>
                <div
                  className="addpropContent px-3 pt-2 pb-2"
                  onClick={() => setDeletePopUp(true)}
                >
                  <span className="ms-1">Delete</span>
                </div>
              </div>
            }
          />
        </div>
      </div> */}
      <CustomPopup
        style={{ position: "relative" }}
        size="md"
        open={deletePopUp}
        onHide={() => setDeletePopUp(false)}
        title="Are you sure you want to delete this facility package?"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() => {
          setDeletePopUp(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div>
            <div className="form-label-title">Package name</div>
            <div className="light-font-text">{data?.title}</div>
          </div>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="NO"
            onClick={() => setDeletePopUp(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="YES"
            onClick={handleFacilityDelete}
          />
        </div>
      </CustomPopup>
    </>
  );
};

//-------Document Card--------//Signing
export const Document = ({ data, documentPackage, setDocumentPackage }) => {
  const [deletePopUp, setDeletePopUp] = useState(false);

  const handleDocumentDelete = () => {
    let newArray = documentPackage?.filter(
      (el) => el?.document_package_name !== data?.document_package_name
    );
    setDocumentPackage(newArray);
  };
  return (
    <>
      <DocumentCard data={data} setDeletePopUp={setDeletePopUp} isTag={"D"} />
      <CustomPopup
        style={{ position: "relative" }}
        size="md"
        open={deletePopUp}
        onHide={() => setDeletePopUp(false)}
        title="Are you sure you want to delete this Document package?"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() => {
          setDeletePopUp(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div>
            <div className="form-label-title">Package name</div>
            <div className="light-font-text">{data?.document_package_name}</div>
          </div>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="NO"
            onClick={() => setDeletePopUp(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="YES"
            onClick={handleDocumentDelete}
          />
        </div>
      </CustomPopup>
    </>
  );
};

//-------Signing Card--------//
export const Signing = ({ data, signingPackage, setSigningPackage }) => {
  const [deletePopUp, setDeletePopUp] = useState(false);

  const handleSigningDelete = () => {
    let newArray = signingPackage?.filter(
      (el) => el?.document_package_name !== data?.document_package_name
    );
    setSigningPackage(newArray);
  };
  return (
    <>
      <SigningCard data={data} setDeletePopUp={setDeletePopUp} isTag={"S"} />
      <CustomPopup
        style={{ position: "relative" }}
        size="md"
        open={deletePopUp}
        onHide={() => setDeletePopUp(false)}
        title="Are you sure you want to delete this Signing package?"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() => {
          setDeletePopUp(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div>
            <div className="form-label-title">Package name</div>
            <div className="light-font-text">{data?.document_package_name}</div>
          </div>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="NO"
            onClick={() => setDeletePopUp(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="YES"
            onClick={handleSigningDelete}
          />
        </div>
      </CustomPopup>
    </>
  );
};
