import { Button, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import Calender from "../../assets/icons/svg_icons/Date-icon.svg";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  format,
  addYears,
  endOfYear,
  startOfYear,
} from "date-fns";
import ETButton from "../Buttons/ETButton";
import moment from "moment";

const DateRangeFilter = ({ setSelectedDate = () => {} }) => {
  // ========================= states & constants ==========================//
  const [anchorCalendar, setanchorCalendar] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfDay(new Date()),
      key: "selection",
    },
  ]);

  const startDate = moment(startOfMonth(new Date())).format("YYYY-MM-DD");
  const endDate = moment(endOfDay(new Date())).format("YYYY-MM-DD");

  const handleClickCalendar = (event) => {
    setanchorCalendar(event.currentTarget);
  };
  const handleCloseCalendar = () => {
    setanchorCalendar(null);
    // setSelectedDate(date); //zustand
  };

  useEffect(() => {
    setSelectedDate({ startDate: startDate, endDate: endDate });
    return () => {};
  }, []);

  // ------------------ -----------------------------//
  // const formattedDate = moment(originalDate).format("YYYY-MM-DD");

  // ---------------- reset -------------------------//
  const ResetFilter = () => {
    setDate([
      {
        startDate: startOfMonth(new Date()),
        endDate: endOfDay(new Date()),
        key: "selection",
      },
    ]);
  };

  const handleApplyDateFilter = () => {
    const startDate = moment(date[0].startDate).format("YYYY-MM-DD");
    const endDate = moment(date[0].endDate).format("YYYY-MM-DD");
    // console.log(startDate, "startDate");
    setSelectedDate({ startDate: startDate, endDate: endDate });
    setanchorCalendar(null);
  };

  const definedTimes = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),

    startOfLastThreeMonths: startOfMonth(addMonths(new Date(), -3)),
    endOfLastThreeMonths: endOfMonth(addMonths(new Date(), -1)),
    startOfLastYear: startOfYear(addYears(new Date(), -1)),
    endOfLastYear: endOfYear(addYears(new Date(), -1)),
    startOfLastSevenDays: startOfDay(addDays(new Date(), -1)),
    endOfLastSevenDays: startOfDay(addDays(new Date(), -7)),
    startOfLastThirtyDays: startOfDay(addDays(new Date(), -1)),
    endOfLastThirtyDays: startOfDay(addDays(new Date(), -30)),
    startOfAllTime: new Date(2001, 0, 1),
  };
  const staticRangeHandler = {
    range: {},
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  };

  function createStaticRanges(ranges) {
    return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
  }
  const staticRanges = createStaticRanges([
    {
      label: "Today",
      range: () => ({
        startDate: definedTimes.startOfToday,
        endDate: definedTimes.endOfToday,
      }),
    },
    {
      label: "Last 7 days",
      range: () => ({
        startDate: definedTimes.startOfLastSevenDays,
        endDate: definedTimes.endOfLastSevenDays,
      }),
    },
    {
      label: "Last 30 days",
      range: () => ({
        startDate: definedTimes.startOfLastThirtyDays,
        endDate: definedTimes.endOfLastThirtyDays,
      }),
    },
    {
      label: "Last Month",
      range: () => ({
        startDate: definedTimes.startOfLastMonth,
        endDate: definedTimes.endOfLastMonth,
      }),
    },
    {
      label: "This Month",
      range: () => ({
        startDate: definedTimes.startOfMonth,
        endDate: definedTimes.endOfToday,
      }),
    },
    {
      label: "Last 3 Month",
      range: () => ({
        startDate: definedTimes.startOfLastThreeMonths,
        endDate: definedTimes.endOfLastThreeMonths,
      }),
    },
    {
      label: "Last Year",
      range: () => ({
        startDate: definedTimes.startOfLastYear,
        endDate: definedTimes.endOfLastYear,
      }),
    },
    {
      label: "All time",
      range: () => ({
        startDate: definedTimes.startOfAllTime,
        endDate: definedTimes.endOfToday,
      }),
    },
  ]);

  return (
    <div className="w-100">
      {" "}
      <div className="w-100">
        <div
          size="small"
          id="basic-button-calendar"
          aria-controls={anchorCalendar ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={anchorCalendar ? "true" : undefined}
          onClick={handleClickCalendar}
          sx={{
            borderRadius: "8px",
            "&:hover": { backgroundColor: "transparent" },
            padding: "0px",
          }}
          style={{
            color: "transparent",
          }}
        >
          <div className="date-picker-filter-new w-100">
            <div className="d-flex justify-content-between gap-3 w-100 align-items-center">
              <div className="light-font-text w-100">
                <div className="light-font-text ">
                  {`${format(date[0]?.startDate, "dd. MMM. yyyy")} - ${format(
                    date[0]?.endDate,
                    "dd. MMM. yyyy"
                  )}`}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <img
                  className="img-cal"
                  height={15}
                  src={Calender}
                  alt="calendar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorCalendar}
        open={anchorCalendar}
        onClose={handleCloseCalendar}
        MenuListProps={{
          "aria-labelledby": "basic-button-calendar",
        }}
        sx={{
          marginTop: "8px",
          backgroundColor: "rgba(0, 0, 0, 0.02)",
        }}
      >
        <MenuItem
          style={{
            color: "transparent",
          }}
          sx={{
            "&:hover": { backgroundColor: "transparent" },
            borderRadius: "8px",
            padding: "0px",
            overflow: "hidden",
            border: "1px solid #F4F5F5",
          }}
        >
          <div
            className="date-picker-modal-popup"
            // style={{ height: "400px", overflow: "scroll" }}
          >
            <div className="date-picker-modal-popup-position">
              <div
                className="medium fs-14 justify-content-center"
                style={{
                  color: "#000",
                  paddingTop: "26px",
                  position: "absolute",
                  textAlign: "center",
                  width: "180px",
                }}
              >
                <span className="font-semibold fs-14">Shortcuts</span>
              </div>
              <DateRangePicker
                onChange={(item) => setDate([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                weekStartsOn={1}
                ranges={date}
                direction="horizontal"
                preventSnapRefocus={true}
                calendarFocus="backwards"
                staticRanges={staticRanges}
                rangeColors={["#619130"]}
              />
            </div>
          </div>
        </MenuItem>
        <div
          className="py-2 px-3"
          style={{
            display: "flex",
            justifyContent: "end",
            gap: "15px",
            backgroundColor: "#f9fafa",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="RESET"
            width="10%"
            onClick={() => ResetFilter()}
          />
          <ETButton
            variant={"primary"}
            buttonText="APPLY"
            width="10%"
            onClick={() => handleApplyDateFilter()}
          />
        </div>
      </Menu>
    </div>
  );
};

export default DateRangeFilter;

// created by Suraj

// Updated by Viraj on 20-11-2023
// Fixed props, now date filter components returns object of start date & end date
