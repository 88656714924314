import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { H4 } from "../../../../styles/Common.style";
import FilterComponent from "../../../../reactdataTableComponent/FilterComponent";
import moment from "moment";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import PasswordField from "../../../../Components/GroupText/PasswordField";
import ETButton from "../../../../Components/Buttons/ETButton";
import { Controller, useForm } from "react-hook-form";
import InputField from "../../../../Components/Textfield/InputField";
import {
  useGetGeographyCountry,
  useGetStreetDetails,
  useGetZipcodeByCountry,
} from "../../../../hooks/queries/useGeographyQueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toggle from "../../../../Components/Switch/Switch";
import {
  useGetActorsByActorId,
  useGetActorsBySearch,
} from "../../../../hooks/queries/useActorQueries";

const AddCustomerToAppointment = ({
  flag,
  handleNext = () => {},
  handleBack = () => {},
  getlanguagesData,
  customerDetailsData,
  customerInformationData,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchDiv, setSearchDiv] = useState(false);
  const [isSearchStreetVisible, setIsSearchStreetVisible] = useState(false);
  const [searchedStreetName, setSearchedStreetName] = useState("");
  const [useSameAddressAsPrimaryCustomer, setUseSameAddressAsPrimaryCustomer] =
    useState("");

  //   react hooks forms
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({});

  ////-----------------------------react query------------------------------//
  const { data: countryData } = useGetGeographyCountry();
  const { data: getStreetDetailsData } =
    useGetStreetDetails(searchedStreetName);
  const { data: zipCodeByCountryIdData } = useGetZipcodeByCountry(
    watch("country")?.value
  );
  const { data: GetActorsBySearch } = useGetActorsBySearch(searchText);

  // ---------------------------  used for searching  -------------------------//
  const handleSearch = (e) => {
    const text = e.target.value;
    setSearchText(text);
    setSearchDiv(text.length > 0);
  };

  // -------------------------  displayItem used for showing selected zipcode -----------//
  const displayItem = (selected) => {
    if (
      selected == "" ||
      selected?.value == undefined ||
      selected?.value == ""
    ) {
      return selected;
    } else {
      return {
        value: selected?.Id,
        label: (
          <div>
            <div className="fs-14 fw-300">
              {
                countryData?.data?.filter(
                  (item, i) => item.Id === selected?.value
                )?.[0]?.Text
              }
            </div>
          </div>
        ),
      };
    }
  };

  // ------------------------------const -----------------------------//
  const handleClickNext = (data) => {
    handleNext(data);
  };
  // --------------------------handle zipcode change-----------------------//
  const handleZipCode = (e, ActionType) => {
    if (ActionType.action === "clear") {
      setValue("city", "");
    } else {
      if (e?.value != "" || e?.value != "undefined") {
        const HandleCityName = zipCodeByCountryIdData?.data?.filter(
          (data) => data?.Id == e?.value
        );
        const CityData = HandleCityName[0].Text;
        setValue("city", CityData, { shouldValidate: true });
      }
    }
  };
  // --------------------------handle Search Selected Data-----------------------//
  const handleSearchSelectedData = (data) => {
    clearErrors();
    setValue("firstname", data?.firstname);
    setValue("lastname", data?.lastname);
    setValue("email", data?.email);
    setValue("birthdate", data?.birthdate);
    setValue("countryCode", {
      value: data?.country_id,
      label: data?.country_code,
    });
    setValue("mobilenumber", data?.phone_number);
    setValue("social_security_number", data?.social_security_number);
    setValue("street_name", data?.street_name);
    setValue("country", {
      value: data?.country_id,
      label: data?.country,
    });
    setValue("house_number", data?.house_number);
    setValue("floor_no", data?.floor_no);
    setValue("side_door", data?.side_door);
    setValue("zipCode", {
      value: data?.zipCode,
      label: data?.zipCode,
    });
    setValue("city", data?.city);
  };

  // ------------------------- useEffect ------------------------//

  useEffect(() => {
    if (useSameAddressAsPrimaryCustomer) {
      setValue(
        "street_name",
        customerDetailsData?.customer_details?.[0]?.street_name
      );
      setValue(
        "house_number",
        customerDetailsData?.customer_details?.[0]?.house_number
      );
      setValue(
        "floor_no",
        customerDetailsData?.customer_details?.[0]?.floor_no
      );
      setValue(
        "side_door",
        customerDetailsData?.customer_details?.[0]?.side_door
      );
      setValue("zipCode", {
        value: customerDetailsData?.customer_details?.[0]?.zipcode_id,
        label: customerDetailsData?.customer_details?.[0]?.zipcode_id,
      });
      setValue("city", customerDetailsData?.customer_details?.[0]?.city);
      setValue("country", {
        value: customerDetailsData?.customer_details?.[0]?.country_id,
        label: customerDetailsData?.customer_details?.[0]?.country,
      });
      setValue("door", customerDetailsData?.customer_details?.[0]?.door);
    } else {
      setValue("street_name", "");
      setValue("side_door", "");
      setValue("house_number", "");
      setValue("floor_no", "");
      setValue("side_door", "");
      setValue("zipCode", "");
      setValue("city", "");
      setValue("country", "");
      setValue("door", "");
    }
  }, [useSameAddressAsPrimaryCustomer, customerDetailsData]);

  useEffect(() => {
    if (customerInformationData?.length !== 0) {
      setValue("firstname", customerInformationData?.firstname);
      setValue("lastname", customerInformationData?.lastname);
      setValue("email", customerInformationData?.email);
      setValue("countryCode", {
        value: customerInformationData?.countryCode?.value,
        label: customerInformationData?.countryCode?.label,
      });
      setValue("mobilenumber", customerInformationData?.mobilenumber);
      setValue(
        "social_security_number",
        customerInformationData?.social_security_number
      );
      setValue("birthdate", customerInformationData?.birthdate);
      setValue("street_name", customerInformationData?.street_name);
      setValue("house_number", customerInformationData?.house_number);
      setValue("floor_no", customerInformationData?.floor_no);
      setValue("side_door", customerInformationData?.side_door);
      setValue("door", customerInformationData?.door);
      setValue("country", {
        value: customerInformationData?.country?.value,
        label: customerInformationData?.country?.label,
      });
      setValue("zipCode", {
        value: customerInformationData?.zipCode?.value,
        label: customerInformationData?.zipCode?.label,
      });
      setValue("city", customerInformationData?.city);
      // setValue(
      //   "isFavouriteCustomer",
      //   customerInformationData?.isFavouriteCustomer
      // );
    } else {
      reset();
    }
    return () => {};
  }, [customerInformationData]);
  //     setValue("firstname", customerInformationData?.firstname);
  //     setValue("lastname", customerInformationData?.lastname);
  //     setValue("email", customerInformationData?.email);
  //     setValue("countryCode", {
  //       value: customerInformationData?.countryCode?.value,
  //       label: customerInformationData?.countryCode?.label,
  //     });
  //     setValue("mobilenumber", customerInformationData?.mobilenumber);
  //     setValue(
  //       "social_security_number",
  //       customerInformationData?.social_security_number
  //     );
  //     setValue("birthdate", customerInformationData?.birthdate);
  //     setValue("street_name", customerInformationData?.street_name);
  //     setValue("house_number", customerInformationData?.house_number);
  //     setValue("floor_no", customerInformationData?.floor_no);
  //     setValue("side_door", customerInformationData?.side_door);
  //     setValue("door", customerInformationData?.door);
  //     setValue("country", {
  //       value: customerInformationData?.country?.value,
  //       label: customerInformationData?.country?.label,
  //     });
  //     setValue("zipCode", {
  //       value: customerInformationData?.zipCode?.value,
  //       label: customerInformationData?.zipCode?.label,
  //     });
  //     setValue("city", customerInformationData?.city);
  //     setValue(
  //       "isFavouriteCustomer",
  //       customerInformationData?.isFavouriteCustomer
  //     );
  //   } else {
  //     reset();
  //   }
  //   return () => {};
  // }, [customerInformationData]);
  return (
    <div className="">
      <Form onSubmit={handleSubmit(handleClickNext)}>
        <div className="Popups-inner-scrollable-container-height scrollable-container-y">
          <div className="p-4 pb-2 border-bottom ">
            <Row className="">
              <Col lg={8}>
                <H4>Add another customer to the contract</H4>
                <div className="light-font-text pt-1">
                  If you think the customer may already be registered in the
                  system, maybe as a previous tenant, an visitor at a event or
                  as a lead from a campaign, you can find the customer by
                  searching below
                </div>
              </Col>
            </Row>

            {/* search filed section */}
            <Row className=" py-4">
              <Col
                lg={6}
                sm={8}
                style={{ position: "relative" }}
                className="pe-0"
              >
                <div style={{ position: "relative" }}>
                  <FilterComponent
                    filtertext={searchText}
                    placeholder="123"
                    handleSearch={handleSearch}
                    // lableText="E.g. name, email, mobile number, social security number"
                  />
                </div>

                {searchText.length > 0 && searchDiv && (
                  <div
                    className="bg-white radius-8 pe-1"
                    style={{
                      border: "1px solid #D1D1D1",
                      position: "absolute",
                      top: "50px",
                      left: "14px",
                      zIndex: "10",
                      width: "97%",
                      boxShadow: "2px 5px 10px 0px rgba(0, 0, 0, 0.10)",
                    }}
                  >
                    {searchText.length < 2 && (
                      <div className="fs-14 fw-300 grey-text py-2 px-3">
                        Type in minimum 2 characters to get a search result
                      </div>
                    )}{" "}
                    {searchText.length >= 2 && (
                      <>
                        {GetActorsBySearch?.data?.length > 0 ? (
                          <div
                            className="scrollable-container-y"
                            style={{
                              height: "400px",
                              overflowY: "scroll",
                              overflowX: "hidden",
                            }}
                          >
                            {GetActorsBySearch?.data?.map((data, index) => (
                              <div key={index}>
                                <Row
                                  className="fs-14 fw-300 pt-2 ps-3 pe-1 addpropContent"
                                  style={{
                                    borderBottom: "1px solid #D1D1D1",
                                  }}
                                  onClick={() => {
                                    handleSearchSelectedData(data);
                                    setSearchDiv(false);
                                  }}
                                >
                                  <Col md={5}>
                                    <div className="d-flex flex-column my-2">
                                      <span className="fw-600">
                                        {data?.firstname} {data?.lastname}
                                      </span>{" "}
                                      {/* <span>{data?.social_security_number}</span> */}
                                    </div>
                                    <div className="d-flex flex-column my-2">
                                      <span>{data?.email}</span>{" "}
                                      <span>{data?.phone_number}</span>
                                    </div>
                                    <div className="d-flex flex-column my-2">
                                      <span>
                                        {data?.birthdate == ""
                                          ? "-"
                                          : moment(data?.birthdate).format(
                                              "DD MMM, YYYY"
                                            )}
                                      </span>{" "}
                                      <span>
                                        {data?.social_security_number}
                                      </span>{" "}
                                    </div>
                                    <div className="d-flex flex-column my-2">
                                      <span>
                                        {data?.street_name +
                                          " " +
                                          data?.house_number}
                                      </span>
                                      <span>
                                        {data?.zipCode + " " + data?.city}
                                      </span>
                                    </div>
                                  </Col>
                                  <Col md={7}>
                                    <div className="fw-600 mt-2 ">
                                      {/* Known activities */}Lorem Ipsum
                                    </div>
                                    <div className="d-flex flex-column mb-3 fs-12">
                                      <span>
                                        {/* Private viewing at Terrasserne 26
                            Apr, 2023 */}
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit,
                                      </span>
                                      <span>
                                        {/* Open House at Terrasserne 23 Apr,
                            2023 */}
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit,
                                      </span>
                                      <span>
                                        {/* Open House at Blomsterbjerget 16
                            Mar, 2023 */}
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit,
                                      </span>
                                      <span>Lead at 12 Mar, 2023</span>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="fs-14 fw-300 py-2 px-3">
                            Sorry, it was not possible to find a match among
                            existing customers. Please fill in the form below to
                            create a new customer and complete the reservation.
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </Col>
            </Row>

            {/* all form section  */}
            <div>
              <H4 className=" pt-2 pb-3">Customer information</H4>

              {/* first name and last name */}
              <Row>
                <Col xxl={3} lg={4} md={6}>
                  <Controller
                    control={control}
                    {...register("firstname")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"firstname"}
                        value={value}
                        type={"text"}
                        placeholder={"First name (required)"}
                        errorscope
                        iserror={errors?.firstname}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("firstname");
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xxl={3} lg={4} md={6} className="ps-0">
                  <Controller
                    control={control}
                    {...register("lastname")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"lastname"}
                        value={value}
                        type={"text"}
                        placeholder={"Last name (required)"}
                        errorscope
                        iserror={errors?.lastName}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("lastname");
                        }}
                      />
                    )}
                  />
                </Col>
                <Col
                  xxl={3}
                  lg={4}
                  md={6}
                  className="ps-0 d-flex align-items-center"
                >
                  <FontAwesomeIcon
                    icon={"fa-solid fa-heart"}
                    size="lg"
                    className="cursor-pointer "
                    style={{
                      stroke: "#AA1518",
                      strokeWidth: "30px",
                      color: "#AA151833",
                      marginLeft: "2px",
                    }}
                  />
                </Col>
              </Row>

              {/* Email  and date of birthdate  */}
              <Row className="my-3 ">
                <Col xxl={3} lg={4} md={6}>
                  <Controller
                    control={control}
                    {...register("email")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <InputField
                          id={"Email"}
                          value={value}
                          type={"email"}
                          placeholder={"Email (required)"}
                          errorscope
                          iserror={errors?.email}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("email");
                          }}
                        />
                      </div>
                    )}
                  />
                </Col>
                <Col xxl={3} lg={4} md={6} className="ps-0">
                  <Controller
                    control={control}
                    {...register("birthdate")}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <div className="form-input ">
                        <input
                          defaultValue=""
                          type="date"
                          placeholder="DOB"
                          id="birthdate"
                          value={field.value}
                          {...field}
                          max={moment(new Date()).format("YYYY-MM-DD")}
                          onChange={(e) => {
                            field.onChange(e);
                            clearErrors("birthdate");
                          }}
                        />
                        <label htmlFor="birthdate">Date of birth</label>
                      </div>
                    )}
                  />
                  {errors?.birthdate && (
                    <p className="error-msg">This field is required</p>
                  )}
                </Col>
              </Row>

              {/* Country code and phone */}
              <Row className="">
                <Col xxl={6} lg={8}>
                  <Row>
                    <Col xxl={4} lg={5} md={4} className="pe-0">
                      <Controller
                        control={control}
                        {...register("countryCode")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <ReactSelect
                            placeholder={"Country code"}
                            iserror={errors?.countryCode}
                            options={countryData?.data?.map((item) => ({
                              value: item?.Id,
                              label: item?.DataValue2,
                            }))}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("countryCode");
                            }}
                          />
                        )}
                      />
                    </Col>
                    <Col xxl={8} lg={7} md={8}>
                      <Controller
                        control={control}
                        {...register("mobilenumber")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            <InputField
                              id={"Phone"}
                              value={value}
                              maxLength={15}
                              type={"number"}
                              placeholder={"Phone"}
                              errorscope
                              iserror={errors?.mobilenumber}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("mobilenumber");
                              }}
                            />
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* Social security number  */}
              <Row className="my-3 ">
                <Col xxl={6} lg={8}>
                  <Controller
                    control={control}
                    {...register("social_security_number")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <PasswordField
                          id={"ssn"}
                          placeholder={"Social Security Number"}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("social_security_number");
                          }}
                          maxlength={15}
                        />
                      </>
                    )}
                  />
                  {errors?.social_security_number && (
                    <p className="error-msg">Too Many Characters</p>
                  )}
                </Col>
              </Row>

              {/* Street , no , floor , side_door / door */}
              <Row>
                <Col xxl={6} lg={8} className="">
                  <Row style={{ position: "relative" }}>
                    <Col xl={4} lg={4} md={6} className="mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("street_name")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            <InputField
                              id={"street_name"}
                              value={value}
                              maxLength={15}
                              type={"text"}
                              placeholder={"Street"}
                              errorscope
                              iserror={errors.street_name}
                              onChange={(e) => {
                                onChange(e);
                                //   handleStreetDetailChange(e);
                              }}
                            />
                          </div>
                        )}
                      />

                      {watch("street_name")?.length < 4 &&
                      watch("street_name")?.length > 0 &&
                      watch("country")?.value == "DK" ? (
                        <div className="fs-12 fw-400 grey-text pt-1 ">
                          Type in minimum 4 characters to get a search result
                        </div>
                      ) : (
                        <>
                          {watch("country")?.value == "DK" &&
                            isSearchStreetVisible && (
                              <div>
                                <div
                                  className="bg-white radius-8 pe-1 filtered-list"
                                  style={{
                                    border: "1px solid #D1D1D1",
                                    position: "absolute",
                                    // top: "50px",
                                    // left: "14px",
                                    zIndex: "10",
                                    width: "31%",
                                  }}
                                >
                                  <>
                                    {getStreetDetailsData?.data?.length > 0 ? (
                                      <div>
                                        {getStreetDetailsData?.data?.map(
                                          (item, index) => (
                                            <React.Fragment key={index}>
                                              <div
                                                className="mt-1 fs-12"
                                                key={index}
                                                onClick={() => {
                                                  // handleStreetDetailClick(item);
                                                }}
                                              >
                                                <span className="px-2">
                                                  {item?.city ||
                                                  item?.zipcode ||
                                                  item?.house_number ? (
                                                    <> {item?.address} </>
                                                  ) : (
                                                    <> {item?.street_name}</>
                                                  )}
                                                </span>
                                              </div>
                                              <hr className="hr mt-1 mb-0"></hr>
                                            </React.Fragment>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      <div className="mt-1 px-2 fw-300 grey-text fs-12">
                                        No data found
                                      </div>
                                    )}
                                  </>
                                </div>
                              </div>
                            )}
                        </>
                      )}
                    </Col>

                    <Col lg={2} md={6} className=" mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("house_number")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            <InputField
                              id={"house_number"}
                              value={value}
                              type={"text"}
                              placeholder={"No."}
                              errorscope
                              iserror={errors.house_number}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("house_number");
                              }}
                            />
                          </div>
                        )}
                      />
                    </Col>
                    <Col lg={2} md={6} className=" mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("floor_no")}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            id={"floor_no"}
                            value={value}
                            type={"text"}
                            placeholder={"Floor"}
                            errorscope
                            iserror={errors.floor_no}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("floor_no");
                            }}
                          />
                        )}
                      />
                      {errors.floor_no && (
                        <p className="error-msg">This field is required</p>
                      )}
                    </Col>
                    <Col lg={2} md={6} className="mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("side_door")}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            id={"side_door"}
                            value={value}
                            type={"text"}
                            placeholder={"Side"}
                            errorscope
                            iserror={errors.side_door}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("side_door");
                            }}
                          />
                        )}
                      />
                    </Col>
                    <Col lg={2} md={6} className="mb-3">
                      <Controller
                        control={control}
                        {...register("door")}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            id={"door"}
                            value={value}
                            type={"text"}
                            placeholder={"Door"}
                            errorscope
                            iserror={errors.door}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("door");
                            }}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xxl={6} lg={4} className="ps-0">
                  <div className="d-flex gap-2 align-items-center">
                    <div>
                      <Toggle
                        id={"UseSameAddressAsPrimaryCustomer"}
                        checked={useSameAddressAsPrimaryCustomer}
                        onChange={(e) =>
                          setUseSameAddressAsPrimaryCustomer(e.target.checked)
                        }
                      />
                    </div>
                    <div className="light-font-text">
                      Use same address as primary customer
                    </div>
                  </div>
                </Col>
              </Row>

              {/* zip code , city , country */}
              <Row className=" ">
                <Col xxl={6} lg={8}>
                  <Row>
                    <Col lg={4} className="mb-3 ">
                      <Controller
                        control={control}
                        {...register("country")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <ReactSelect
                              placeholder={"Country"}
                              clearable={false}
                              iserror={errors.country}
                              options={countryData?.data?.map((item) => ({
                                value: item?.Id,
                                label: (
                                  <div
                                    className="d-flex align-items-center"
                                    style={{ fontFamily: "Cerebri sans" }}
                                  >
                                    <div className="radius-4 me-2">
                                      <img
                                        src={item?.DataValue1}
                                        style={{
                                          width: "20px",
                                          marginRight: "10px",
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <div className="fs-14 light-font-text ">
                                        {" "}
                                        {item?.Text}
                                      </div>
                                    </div>
                                  </div>
                                ),
                              }))}
                              value={
                                value === undefined ? "" : displayItem(value)
                              }
                              onChange={(e, ActionType) => {
                                onChange(e);
                                setValue("street_name", "");
                                setValue("house_number", "");
                                setValue("zipCode", "");
                                setValue("city", "");
                                clearErrors("country");
                              }}
                            />
                          );
                        }}
                      />
                    </Col>
                    <Col lg={4} md={6} className="mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("zipCode")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <ReactSelect
                              placeholder={"Zip code"}
                              iserror={errors.zipCode}
                              options={zipCodeByCountryIdData?.data?.map(
                                (zip) => ({
                                  value: zip?.Id,
                                  label: `${zip?.Id} ${zip?.Text}`,
                                })
                              )}
                              value={
                                value?.value
                                  ? {
                                      value: value?.value,
                                      label: value?.value,
                                    }
                                  : ""
                              }
                              onChange={(e, ActionType) => {
                                handleZipCode(e, ActionType);
                                onChange(e);
                                clearErrors("zipCode");
                              }}
                            />
                          );
                        }}
                      />
                    </Col>

                    <Col lg={4} md={6} className="mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("city")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            <InputField
                              id={"city"}
                              value={value}
                              type={"text"}
                              placeholder={"City"}
                              iserror={errors.city}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>

          {/* Preferred languages */}
          <div className="p-4 border-bottom">
            <H4> Preferred languages</H4>
            <div className="light-font-text pt-1 pb-3">
              Select the preferred language in which the customer wants to
              communicate.
            </div>

            <Row>
              <Col lg={3}>
                <Controller
                  control={control}
                  {...register("selectLanguage")}
                  // rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Select language"}
                      value={value}
                      options={getlanguagesData?.data?.map((item) => ({
                        value: item?.Text,
                        label: item?.Text,
                      }))}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Col>
            </Row>
          </div>

          {/*------------------- Customer settings ----------------  */}
          <div className="p-4">
            <H4>Customer settings</H4>
            <div className="light-font-text pt-1 pb-3">
              Select if settings below are relevant for this customer
            </div>
            <div className="d-flex gap-2 align-items-center">
              <div>
                <Toggle
                  id={"UseOnly2factorSigning"}
                  // checked={sendReservInfoToCustomerActive}
                  // onChange={(e) =>
                  //   setSendReservInfoToCustomerActive(e.target.checked)
                  // }
                />
              </div>
              <div className="light-font-text">
                Use only 2-factor signing (e.g. if the customer does not have an
                electronic ID)
              </div>
            </div>
          </div>
        </div>
        <div className="py-2 px-3 d-flex flex-row justify-content-between  footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="BACK"
            type={"button"}
            onClick={handleBack}
          />

          <div className="d-flex gap-2 align-items-center">
            {flag == "create new appointment" ? (
              ""
            ) : (
              <ETButton
                variant={"secondary"}
                buttonText="Add extra customer"
                icon={true}
                // type={"button"}
              />
            )}
            <ETButton
              variant={"primary"}
              buttonText="NEXT"
              type="submit"
              // onClick={handleClickNext}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddCustomerToAppointment;

// this page created by Suraj
