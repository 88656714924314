import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ETButton from "../../../Components/Buttons/ETButton";
import CustomPopup from "../../../Components/CustomPopup";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { useEffect, useState } from "react";
import { DeleteEvents } from "./DeleteEvents";
import { usePrivateViewingStore } from "../../../hooks/stores/usePrivateViewingStore";
const FollowUpPrivateEventPopup = ({
  setOpenPrivateFollowUpEvent,
  openPrivateFollowUpEvent,
  setShowPrivateEvent,
}) => {
  //------------------- state --------------------------//

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionTwo, setSelectedOptionTwo] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const EditEventsOpt = [
    {
      value: 1,
      label: "Edit this specific event",
    },
    {
      value: 2,
      label: "Edit this series of event",
    },
  ];

  const DeleteEventsOpt = [
    {
      value: 1,
      label: "Delte this specific event",
    },
    {
      value: 2,
      label: "Delete this series of event",
    },
  ];

  const handleSelectChange = (selected) => {
    setSelectedOption(selected);

    if (selected?.value === 1) {
      setShowModal(true);
      //   setOpenFollowUpEvent(false);
    } else if (selected?.value === 2) {
      setShowModal(true);
      //   setOpenFollowUpEvent(false);
      setSelectedOptionTwo(true);
    }
  };
  const { setShowPrivateEventPage } = usePrivateViewingStore((state) => state);

  return (
    <div>
      {" "}
      <CustomPopup
        headerRequired={false}
        size={"md"}
        open={openPrivateFollowUpEvent}
        onHide={() => setOpenPrivateFollowUpEvent(false)}
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
      >
        <div style={{ borderBottom: "1px solid #F0F0F0" }} className="p-3">
          <div className="d-flex gap-2 align-items-start">
            <div
              className="radius-4 "
              style={{
                width: "17px",
                height: "17px",
                background: "#77C7A1",
                marginTop: "2px",
              }}
            ></div>
            <div className="d-flex flex-column ms-1 ">
              <div className="header-semibold fs-16">
                Mads Johnson and Caroline Johnson - Private viewing
              </div>
              <div className="light-font-text fs-12">
                Open House on Property
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ maxHeight: "420px", overflowY: "scroll" }}
          className="scrollable-container-y"
        >
          <div style={{ borderBottom: "1px solid #F0F0F0" }} className="p-3">
            <div className="d-flex gap-2 align-items-start">
              <FontAwesomeIcon
                icon="fa-regular fa-user"
                style={{ color: "#000000", marginTop: "2px" }}
              />
              <div className="d-flex flex-column ms-1 ">
                <div className="header-semibold fs-16">Agent</div>
                <div className="light-font-text fs-12">Jesper Thorkildsen</div>
              </div>
            </div>
          </div>

          <div style={{ borderBottom: "1px solid #F0F0F0" }} className="p-3">
            <div className="d-flex gap-2 align-items-start">
              <FontAwesomeIcon
                icon="fa-regular fa-clock"
                style={{ color: "#000000", marginTop: "2px" }}
              />

              <div className="d-flex flex-column ms-1 ">
                <div className="header-semibold fs-16 ">Date and time</div>
                <div className="actors-lite-text mt-2">
                  Sunday 19th February 2023
                </div>
                <div className="actors-lite-text">11:00 to 12:00 (1 hours)</div>
              </div>
            </div>
            <div className="d-flex gap-2 align-items-start">
              <FontAwesomeIcon
                icon="fa-regular fa-arrows-repeat"
                style={{ color: "#000000", marginTop: "2px" }}
              />

              <div className="actors-lite-text grey-text ms-1">
                Repeats every Sunday
              </div>
            </div>
          </div>

          <div style={{ borderBottom: "1px solid #F0F0F0" }} className="p-3">
            <div className="d-flex gap-2 align-items-start">
              <FontAwesomeIcon
                icon="fa-regular fa-location-dot"
                style={{ color: "#000000", marginTop: "2px" }}
              />

              <div className="d-flex flex-column ms-2 ">
                <div className="header-semibold fs-16 ">Unit address</div>
                <div className="header-semibold fs-16 mt-2">
                  Blomsterbjerget
                </div>
                <div className="actors-lite-text">
                  Blomsterbjerget Blomstervej 14, 3. tv
                </div>
                <div className="actors-lite-text"> 2765 Smørum </div>
                <div className="actors-lite-text">7-W260</div>
              </div>
            </div>
          </div>

          <div style={{ borderBottom: "1px solid #F0F0F0" }} className="p-3">
            <div className="d-flex gap-2 align-items-start">
              <FontAwesomeIcon
                icon="fa-regular fa-users"
                style={{ color: "#000000", marginTop: "2px" }}
              />
              <div className="d-flex flex-column ms-1 ">
                <div className="header-semibold fs-16">Bookings</div>
                <div className="light-font-text fs-12">
                  Maximum bookings:{" "}
                  <span className="table-property-name">No limit </span>
                </div>
              </div>
            </div>
          </div>

          <div style={{ borderBottom: "1px solid #F0F0F0" }} className="p-3">
            <div className="d-flex gap-2 align-items-start">
              <FontAwesomeIcon
                icon="fa-regular fa-comment"
                style={{ color: "#000000", marginTop: "2px" }}
              />
              <div className="d-flex flex-column ms-1 ">
                <div className="header-semibold fs-16">Comment</div>
                <div className="light-font-text fs-12">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #F0F0F0" }} className="p-3">
          <Row className="px-1">
            {" "}
            <Col>
              {" "}
              <ETButton
                onClick={() => {
                  // setOpenFollowUpEvent(false);
                  // setShowOpenHousePropertyFollowUp(true);
                  setShowPrivateEvent(true);
                  setShowPrivateEventPage(true);
                }}
                width={"100%"}
                variant={"primary"}
                buttonText="FOLLOW-UP"
              />
            </Col>
          </Row>
          <Row className="px-1 mt-2">
            <Col>
              <ReactSelect
                clearable={false}
                options={EditEventsOpt?.map((item) => ({
                  value: item.value,
                  label: item.label,
                }))}
                placeholder={"EDIT"}
              />
            </Col>
            <Col>
              <ReactSelect
                value={selectedOption}
                clearable={false}
                options={DeleteEventsOpt?.map((item) => ({
                  value: item.value,
                  label: item.label,
                }))}
                onChange={handleSelectChange}
                placeholder={"DELETE"}
              />
            </Col>
          </Row>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center">
          <div className="created-by">Created by Max Wilderness Johnsen </div>
        </div>
      </CustomPopup>
      <DeleteEvents
        selectedOptionTwo={selectedOptionTwo}
        close={setShowModal}
        open={showModal}
      />
    </div>
  );
};

export default FollowUpPrivateEventPopup;
