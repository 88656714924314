import React, { useState } from "react";
import { H2, H4, H5 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { Col, Row } from "react-bootstrap";
import Toggle from "../../../../Components/Switch/Switch";
import ETButton from "../../../../Components/Buttons/ETButton";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LanguageSelectSearch from "../../../../Components/FilterDropdownSelection/LanguageSelectSearch";
import ETRadioButton, {
  RadioInput,
  RadioLabel,
  RadioWrapper,
} from "../../../../Components/RadioButton/ETRadioButton";
import ColorInput from "../../../../Components/ColorInput/ColorInput";
import InputField from "../../../../Components/Textfield/InputField";
import DropZone from "../../../../Components/DropZone/DropZone";
import { useOrganizationSettingsBreadcrumbsTabs } from "../../../../hooks/stores/useOrganizationStore";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../../../Components/Tabs/ReactTabs.style";
import GeneralSystemDefaults from "./GeneralSystemDefaults";
import LanguageSystemDefaults from "./LanguageSystemDefaults";
import MainSubStatusSystemDefaults from "./MainSubStatusSystemDefaults";
import NotesSystemDefaults from "./NotesSystemDefaults";

const SystemDefaults = () => {
  const [addLanguage, setAddLanguage] = useState(false);
  const [addCurrency, setAddCurrency] = useState(false);
  const [assignAgentLead, setAssignAgentLead] = useState(false);
  const [assignDeals, setAssignDeals] = useState(false);
  const [assignInquiries, setAssignInquiries] = useState(false);

  const {
    register,
    getValues,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {},
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "addLanguage",
    name: "addCurrency",
  });

  // add language
  const handleAddLanguage = () => {
    append({
      selectLanguage: "",
    });
  };

  // add currency
  const handleAddCurrency = () => {
    append({
      selectCurrency: "",
    });
  };

  // zustand
  const setOrganizationBreadcrumbsTabs = useOrganizationSettingsBreadcrumbsTabs(
    (state) => state.setOrganizationBreadcrumbsTabs
  );

  return (
    <div>
      {" "}
      {/* title section */}
      <div className="px-3 pb-3 border-bottom">
        <div className="px-2 pt-3 d-flex">
          <div className="d-flex align-items-center  gap-2">
            <H2>System defaults</H2>
            <CustomToolTip
              size="sm"
              text="System defaults"
            />
          </div>
        </div>
      </div>
      {/*All tabs section  */}
      <div>
        <Tabs className="d-flex flex-column ">
          <div
            className="d-flex p-4 justify-content-between align-items-center"
            style={{
              width: "100%",
              flexDirection: "row",
              flexWrap: "wrap",
              borderBottom: "1px solid #F0F0F0",
            }}
          >
            <TabList
              className="d-flex light ps-0 light gap-2"
              // style={{ gap: "7px" }}
            >
              {/* <Tab onClick={() => setOrganizationBreadcrumbsTabs("General")}>
                General
              </Tab> */}
              {/* <Tab onClick={() => setOrganizationBreadcrumbsTabs("Language")}>
                Language
              </Tab> */}
              <Tab
                onClick={() =>
                  setOrganizationBreadcrumbsTabs("Main & Substatus")
                }
              >
                Main & Substatus
              </Tab>
              {/* <Tab onClick={() => setOrganizationBreadcrumbsTabs("Notes")}>
                Notes
              </Tab> */}
            </TabList>
          </div>

          <div>
            {/* <TabPanel>
              <GeneralSystemDefaults />
            </TabPanel> */}
            {/* <TabPanel>
              <LanguageSystemDefaults />
            </TabPanel> */}
            <TabPanel>
              <MainSubStatusSystemDefaults />
            </TabPanel>
            {/* <TabPanel>
              <NotesSystemDefaults />
            </TabPanel> */}
          </div>
        </Tabs>
      </div>
    </div>

    // <div className="whiteContainerStyle">
    //   <div className="px-3 pb-3 border-bottom">
    //     <div className="px-2 pt-3 d-flex">
    //       <div className="d-flex align-items-end  gap-2">
    //         <H2>System defaults</H2>
    //         <CustomToolTip
    //           size="sm"
    //           text="System defaults"
    //         />
    //       </div>
    //     </div>
    //   </div>

    //   {/* Timezone */}
    //   <div className="p-4 border-bottom">
    //     <div className="d-flex align-items-center  gap-2 ">
    //       <H4>Timezone</H4>
    //       <CustomToolTip
    //         size="sm"
    //         text="Timezone"
    //       />
    //     </div>
    //     <div className="light-font-text mb-3 mt-2 ">
    //       Select the timezone used in the system
    //     </div>
    //     <Row>
    //       <Col lg={4}>
    //         {" "}
    //         <ReactSelect placeholder={""} />
    //         <div className="light-font-text mt-2">
    //           Universal time is{" "}
    //           <span className="paragraph2-regular">2023-04-10 11:26:42</span>
    //         </div>
    //       </Col>
    //     </Row>
    //   </div>

    //   {/* Default language */}
    //   <div className="  p-4 border-bottom">
    //     <div className="d-flex align-items-center  gap-2 ">
    //       <H4>Default language</H4>
    //       <CustomToolTip
    //         size="sm"
    //         text="Default language"
    //       />
    //     </div>
    //     <div className="light-font-text mb-3 mt-2">
    //       Select the default language used in the system. The individual user
    //       can choose to change this in their profile.
    //     </div>
    //     <Row>
    //       <Col lg={4}>
    //         {" "}
    //         <LanguageSelectSearch placeholder="" />
    //       </Col>
    //     </Row>
    //   </div>

    //   {/* Default language in end users */}
    //   <div className="  p-4 border-bottom">
    //     <div className="d-flex align-items-center  gap-2 ">
    //       <H4>Default language in End User Services</H4>
    //       <CustomToolTip
    //         size="sm"
    //         text="Default language in End User Services"
    //       />
    //     </div>
    //     <div className="light-font-text mb-3 mt-2">
    //       Select the default language used in your End User Services. The
    //       individual user can choose to change this in their profile.
    //     </div>
    //     <Row>
    //       <Col lg={4}>
    //         {" "}
    //         <LanguageSelectSearch placeholder="" />
    //       </Col>
    //     </Row>
    //   </div>

    //   {/* Allow other languages in End User Services */}
    //   <div className="  p-4 border-bottom">
    //     <div className="d-flex align-items-center  gap-2 ">
    //       <H4>Allow other languages in End User Services</H4>
    //       <CustomToolTip
    //         size="sm"
    //         text="Allow other languages in End User Services"
    //       />
    //     </div>
    //     <div className="light-font-text mb-3 mt-2">
    //       Select the default language used in your End User Services. The
    //       individual user can choose to change this in their profile.
    //     </div>
    //     <div className="d-flex gap-2 align-items-center mb-3">
    //       <Toggle
    //         id="allow_other_languages"
    //         checked={addLanguage}
    //         onChange={() => setAddLanguage(!addLanguage)}
    //       />
    //       <span className="light-font-text">Allow other languages </span>
    //     </div>

    //     {fields.map((item, index) => (
    //       <Row
    //         key={index}
    //         className="mb-3"
    //       >
    //         <Col lg={4}>
    //           <div className="d-flex gap-2">
    //             <Controller
    //               control={control}
    //               {...register(`addLanguage.${index}.selectLanguage`)}
    //               rules={{ required: true }}
    //               render={({ field: { onChange, value } }) => (
    //                 <div className="d-flex align-items-center w-100">
    //                   <div className="w-100 pe-3">
    //                     <LanguageSelectSearch placeholder="Select languages" />
    //                   </div>

    //                   <div
    //                     onClick={() => remove(index)}
    //                     style={{
    //                       width: "25px",
    //                       border: "1px solid #D1D1D1",
    //                     }}
    //                     className="d-flex align-items-center justify-content-center p-1 rounded cursor-pointer"
    //                   >
    //                     <FontAwesomeIcon
    //                       icon="fa-regular fa-trash"
    //                       style={{ color: "#000000" }}
    //                       size="sm"
    //                     />
    //                   </div>
    //                 </div>
    //               )}
    //             />
    //           </div>
    //         </Col>
    //       </Row>
    //     ))}

    //     <ETButton
    //       buttonText="ADD LANGUAGE"
    //       icon={true}
    //       onClick={handleAddLanguage}
    //       variant="secondary"
    //       disabled={!addLanguage}
    //     />
    //   </div>

    //   {/* Fiscal year */}
    //   <div className=" p-4 border-bottom">
    //     <div className="d-flex align-items-center  gap-2 ">
    //       <H4>Fiscal year</H4>
    //       <CustomToolTip
    //         size="sm"
    //         text="Fiscal year"
    //       />
    //     </div>
    //     <div className="light-font-text mb-3 mt-2">
    //       Select the default fiscal year used in the system. It is possible to
    //       set different settings at company and property level if necessary.
    //     </div>
    //     <Row>
    //       <Col lg={4}>
    //         {" "}
    //         <ReactSelect placeholder={""} />
    //       </Col>
    //     </Row>
    //   </div>

    //   {/* Currency */}
    //   <div className=" p-4 border-bottom">
    //     <div className="d-flex align-items-center  gap-2 ">
    //       <H4>Currency</H4>
    //       <CustomToolTip
    //         size="sm"
    //         text="Currency"
    //       />
    //     </div>
    //     <div className="light-font-text mb-3 mt-2">
    //       Select the default currency used in the system
    //     </div>
    //     <Row>
    //       <Col lg={4}>
    //         {" "}
    //         <LanguageSelectSearch placeholder="" />
    //       </Col>
    //     </Row>
    //   </div>

    //   {/* Allow other currencies in End User Services */}
    //   <div className="p-4 border-bottom">
    //     <div className="d-flex align-items-center  gap-2 ">
    //       <H4>Allow other currencies in End User Services</H4>
    //       <CustomToolTip
    //         size="sm"
    //         text="Allow other currencies in End User Services"
    //       />
    //     </div>
    //     <div className="light-font-text mb-3 mt-2">
    //       Would you like to add the possibility for your customers to use
    //       another currency than the default.
    //     </div>
    //     <div className="d-flex gap-2 align-items-center mb-3">
    //       <Toggle
    //         id="allow_other_currencies"
    //         value={addCurrency}
    //         onChange={() => setAddCurrency(!addCurrency)}
    //       />
    //       <span className="light-font-text">Allow other currencies </span>
    //     </div>

    //     {fields?.map((item, index) => (
    //       <Row
    //         key={item.id}
    //         className="pb-3"
    //       >
    //         <Col lg={6}>
    //           <div className="d-flex gap-3">
    //             <div className="w-100">
    //               <Controller
    //                 control={control}
    //                 {...register(`addLanguage.${index}.currency`)}
    //                 rules={{ required: true }}
    //                 render={({ field: { onChange, value } }) => (
    //                   <LanguageSelectSearch placeholder="Select languages" />
    //                 )}
    //               />
    //             </div>

    //             <div className="d-flex gap-1 w-100">
    //               <Controller
    //                 control={control}
    //                 {...register(`addLanguage.${index}.exchangeRate`)}
    //                 rules={{ required: true }}
    //                 render={({ field: { onChange, value } }) => (
    //                   <div className="d-flex align-items-center w-100">
    //                     <div className="w-100 pe-3">
    //                       <InputField placeholder={"Exchange rate"} />
    //                     </div>

    //                     <div
    //                       onClick={() => remove(index)}
    //                       style={{
    //                         width: "25px",
    //                         border: "1px solid #D1D1D1",
    //                       }}
    //                       className="d-flex align-items-center justify-content-center p-1 cursor-pointer rounded"
    //                     >
    //                       <FontAwesomeIcon
    //                         icon="fa-regular fa-trash"
    //                         style={{ color: "#000000" }}
    //                         size="sm"
    //                       />
    //                     </div>
    //                   </div>
    //                 )}
    //               />
    //             </div>
    //           </div>
    //         </Col>
    //       </Row>
    //     ))}
    //     <ETButton
    //       buttonText="ADD CURRENCY"
    //       icon={true}
    //       onClick={handleAddCurrency}
    //       variant="secondary"
    //       disabled={!addCurrency}
    //     />
    //   </div>

    //   {/* Unit ID */}
    //   <div className=" p-4 border-bottom">
    //     <div className="d-flex align-items-center  gap-2 ">
    //       <H4>Unit ID</H4>
    //       <CustomToolTip
    //         size="sm"
    //         text="Unit ID"
    //       />
    //     </div>
    //     <div className="light-font-text mb-3 mt-2">
    //       Select the type of Unit ID that should be displayed throughout the
    //       system.
    //     </div>

    //     <Row className="mb-2">
    //       <Col
    //         className="d-flex"
    //         lg={3}
    //       >
    //         <div className="d-flex gap-2">
    //           <ETRadioButton
    //             id="default"
    //             label={"Default"}
    //           />
    //           <CustomToolTip
    //             text={"Default"}
    //             size="sm"
    //           />
    //         </div>
    //       </Col>
    //       <Col>
    //         <span className="grey-light-font-text">
    //           Ex. 1, 2, 3 or 001, 002, 003 (Field: unit_id)
    //         </span>
    //       </Col>
    //     </Row>

    //     <Row className="mb-2">
    //       <Col
    //         className="d-flex"
    //         lg={3}
    //       >
    //         <div className="d-flex gap-2">
    //           <ETRadioButton
    //             id="property_management"
    //             label={"Property Management"}
    //           />
    //           <CustomToolTip
    //             text={"Property Management"}
    //             size="sm"
    //           />
    //         </div>
    //       </Col>
    //       <Col>
    //         <span className="grey-light-font-text">
    //           Ex. 102-023-001, 102-024-001 (Field: unit_calculated_number)
    //         </span>
    //       </Col>
    //     </Row>

    //     <Row className="mb-2">
    //       <Col
    //         className="d-flex"
    //         lg={3}
    //       >
    //         <div className="d-flex gap-2">
    //           <ETRadioButton
    //             id="case"
    //             label={"Case"}
    //           />
    //           <CustomToolTip
    //             text={"Case"}
    //             size="sm"
    //           />
    //         </div>
    //       </Col>
    //       <Col>
    //         <span className="grey-light-font-text">
    //           Ex. A100 - B100 - C100 (Field: unit_case_id){" "}
    //         </span>
    //       </Col>
    //     </Row>

    //     <Row className="mb-2">
    //       <Col
    //         className="d-flex"
    //         lg={3}
    //       >
    //         <div className="d-flex gap-2">
    //           <ETRadioButton
    //             id="mindworking"
    //             label={"Mindworking"}
    //           />
    //           <CustomToolTip
    //             text={"Mindworking"}
    //             size="sm"
    //           />
    //         </div>
    //       </Col>
    //       <Col>
    //         <span className="grey-light-font-text">
    //           Ex. 12345678 (Field: unit_mw_case_id){" "}
    //         </span>
    //       </Col>
    //     </Row>
    //   </div>

    //   {/* Colours for main and sub status */}
    //   <div className=" p-4 border-bottom">
    //     <div className="d-flex align-items-center  gap-2 ">
    //       <H4>Colours for main and sub status</H4>
    //       <CustomToolTip
    //         size="sm"
    //         text="Colours for main and sub status"
    //       />
    //     </div>
    //     <div className="light-font-text mt-2">
    //       Here you have the opportunity to change the colours for the systems
    //       main and sub-statuses.
    //     </div>
    //     <Row className="mt-3">
    //       <Col>
    //         <div className="d-flex align-items-center gap-2 mb-2">
    //           <H4>Available</H4>
    //           <CustomToolTip
    //             size="sm"
    //             text="Available"
    //           />
    //         </div>
    //         <div className="d-flex gap-3">
    //           <div className="w-100">
    //             <H5 className="mb-2">Main status</H5>
    //             <ColorInput label="Main status" />
    //           </div>
    //           <div className="w-100">
    //             <H5 className="mb-2">Sub status</H5>
    //             <ColorInput label="Sub status" />
    //           </div>
    //         </div>
    //       </Col>
    //       <Col></Col>
    //     </Row>
    //     <Row className="mt-3">
    //       <Col>
    //         <div className="d-flex align-items-center gap-2 mb-2">
    //           <H4>In process</H4>
    //           <CustomToolTip
    //             size="sm"
    //             text="In process  "
    //           />
    //         </div>
    //         <div className="d-flex gap-3">
    //           <div className="w-100">
    //             <H5 className="mb-2">Main status</H5>
    //             <ColorInput label="Main status" />
    //           </div>
    //           <div className="w-100">
    //             <H5 className="mb-2">Sub status</H5>
    //             <ColorInput label="Sub status" />
    //           </div>
    //         </div>
    //       </Col>
    //       <Col></Col>
    //     </Row>
    //     <Row className="mt-3">
    //       <Col>
    //         <div className="d-flex align-items-center gap-2 mb-2">
    //           <H4>Completed</H4>
    //           <CustomToolTip
    //             size="sm"
    //             text="Completed"
    //           />
    //         </div>
    //         <div className="d-flex gap-3">
    //           <div className="w-100">
    //             <H5 className="mb-2">Main status</H5>
    //             <ColorInput label="Main status" />
    //           </div>
    //           <div className="w-100">
    //             <H5 className="mb-2">Sub status</H5>
    //             <ColorInput label="Sub status" />
    //           </div>
    //         </div>
    //       </Col>
    //       <Col></Col>
    //     </Row>
    //     <Row className="mt-3">
    //       <Col>
    //         <div className="d-flex align-items-center gap-2 mb-2">
    //           <H4>Not active</H4>
    //           <CustomToolTip
    //             size="sm"
    //             text="Not active"
    //           />
    //         </div>
    //         <div className="d-flex gap-3">
    //           <div className="w-100">
    //             <H5 className="mb-2">Main status</H5>
    //             <ColorInput label="Main status" />
    //           </div>
    //           <div className="w-100">
    //             <H5 className="mb-2">Sub status</H5>
    //             <ColorInput label="Sub status" />
    //           </div>
    //         </div>
    //       </Col>
    //       <Col></Col>
    //     </Row>
    //   </div>

    //   {/* QR code settings  */}
    //   <div className=" p-4 border-bottom">
    //     <div className="d-flex align-items-center  gap-2 ">
    //       <H4>QR code settings </H4>
    //       <CustomToolTip
    //         size="sm"
    //         text="QR code settings "
    //       />
    //     </div>
    //     <div className="light-font-text mb-3 mt-2">
    //       Add your own logo to your QR codes or change the colour it’s all up to
    //       you!
    //     </div>
    //     <Row>
    //       <Col lg={4}>
    //         <div className="d-flex align-items-center gap-2 ">
    //           <H5>Logo</H5>
    //           <CustomToolTip
    //             size="sm"
    //             text="QR code settings"
    //           />
    //         </div>
    //         <div className="mb-3">
    //           <span className="light-font-text">Recommended logo size: </span>
    //           <DropZone />
    //         </div>
    //         <div className="w-100">
    //           <div className="d-flex gap-2 align-items-center mb-2">
    //             <H5>Colour</H5>
    //             <CustomToolTip text={"Colour"} />
    //           </div>
    //           <ColorInput label="QR colour" />
    //         </div>
    //       </Col>
    //     </Row>
    //   </div>

    //   {/* Enable 2-factor authentication for user login */}
    //   <div className=" p-4 border-bottom">
    //     <div className="d-flex align-items-center  gap-2 ">
    //       <H4>Enable 2-factor authentication for user login</H4>
    //       <CustomToolTip
    //         size="sm"
    //         text="Enable 2-factor authentication for user login"
    //       />
    //     </div>
    //     <Row>
    //       <Col lg={6}>
    //         <div className="light-font-text mb-3 mt-2">
    //           Two-factor authentication (2FA) can be used to help protect the
    //           accounts from unauthorised access. This will require the user to
    //           enter a security code each time they sign in.
    //         </div>

    //         <div className="d-flex gap-2 align-items-center">
    //           <Toggle
    //             disabled={false}
    //             id="enable_2fa"
    //           />
    //           <span className="light-font-text">Enable 2FA</span>
    //         </div>
    //       </Col>
    //     </Row>
    //   </div>

    //   {/* Automatic 2FA activation */}
    //   <div className=" p-4 border-bottom">
    //     <div className="d-flex align-items-center  gap-2 ">
    //       <H4>Automatic 2FA activation</H4>
    //       <CustomToolTip
    //         size="sm"
    //         text="Automatic 2FA activation"
    //       />
    //     </div>
    //     <Row>
    //       <Col lg={6}>
    //         <div className="light-font-text mb-3 mt-2">
    //           Activate two-factor authentication (2FA) as a default.
    //         </div>

    //         <div className="d-flex gap-2 align-items-center">
    //           <Toggle id="automatic_2fa" />
    //           <span className="light-font-text">Automatic 2FA</span>
    //         </div>
    //       </Col>
    //     </Row>
    //   </div>

    //   {/* Auto assign agent on contract */}
    //   <div className=" p-4 border-bottom">
    //     <div className="d-flex align-items-center  gap-2 ">
    //       <H4>Auto assign agent on lead</H4>
    //       <CustomToolTip
    //         size="sm"
    //         text="Auto assign agent on lead"
    //       />
    //     </div>
    //     <Row>
    //       <Col lg={6}>
    //         <div className="light-font-text mb-3 mt-2">
    //           If you would like to assign all leads to a specific agent
    //           automatically as a default, you can here enable the auto assign
    //           function and choose the agent, who should have the leads assigned.
    //         </div>

    //         <div className="d-flex gap-2 align-items-center mb-3">
    //           <Toggle
    //             id="auto_assign_leads"
    //             checked={assignAgentLead}
    //             onChange={() => setAssignAgentLead(!assignAgentLead)}
    //           />
    //           <span className="light-font-text">Auto assign leads</span>
    //         </div>

    //         <ReactSelect
    //           placeholder="Responsible agent"
    //           disabled={!assignAgentLead}
    //         />
    //       </Col>
    //     </Row>
    //   </div>

    //   {/* Auto assign agent on lead */}
    //   <div className=" p-4 border-bottom">
    //     <div className="d-flex align-items-center  gap-2 ">
    //       <H4>Auto assign agent on contract</H4>
    //       <CustomToolTip
    //         size="sm"
    //         text="Auto assign agent on contract"
    //       />
    //     </div>
    //     <Row>
    //       <Col lg={6}>
    //         <div className="light-font-text mb-3 mt-2">
    //           If you would like to assign all leads to a specific agent
    //           automatically as a default, you can here enable the auto assign
    //           function and choose the agent, who should have the leads assigned.
    //         </div>

    //         <div className="d-flex gap-2 align-items-center mb-3">
    //           <Toggle
    //             id="auto_assign_deals"
    //             checked={assignDeals}
    //             onChange={() => setAssignDeals(!assignDeals)}
    //           />
    //           <span className="light-font-text">Auto assign deals</span>
    //         </div>

    //         <ReactSelect
    //           placeholder="Responsible agent"
    //           disabled={!assignDeals}
    //         />
    //       </Col>
    //     </Row>
    //   </div>

    //   {/* Auto assign agent on inquiries */}
    //   <div className=" p-4">
    //     <div className="d-flex align-items-center  gap-2 ">
    //       <H4>Auto assign agent to Inquiries</H4>
    //       <CustomToolTip
    //         size="sm"
    //         text="Auto assign agent to Inquiries"
    //       />
    //     </div>
    //     <Row>
    //       <Col lg={6}>
    //         <div className="light-font-text mb-3 mt-2">
    //           If you would like to assign all incoming inquiries to a specific
    //           agent automatically as a default, you can here enable the auto
    //           assign function and choose the agent, who should have the
    //           inquiries assigned
    //         </div>

    //         <div className="d-flex gap-2 align-items-center mb-3">
    //           <Toggle
    //             id="auto_assign_inquiries"
    //             checked={assignInquiries}
    //             onChange={() => setAssignInquiries(!assignInquiries)}
    //           />
    //           <span className="light-font-text">Auto assign inquiries</span>
    //         </div>

    //         <ReactSelect
    //           placeholder="Responsible agent"
    //           disabled={!assignInquiries}
    //         />
    //       </Col>
    //     </Row>
    //   </div>
    // </div>
  );
};

export default SystemDefaults;
