import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomToolTip from "../../../../../../../../../Components/Tooltip/CustomTooltip";
import ETButton from "../../../../../../../../../Components/Buttons/ETButton";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "../../../../../../../../../Components/DropDown/ReactSelect";
import MRTTheme from "../../../../../../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";

import { DeclinedTableData } from "./data";
import {
	SortIcon,
	TableRowsStyle,
	paginationoptions,
} from "../../../../../../../../../Components/table/MRTGlobalProps";
import Menus from "../../../../../../../../../Components/Menus/Menus";
import TagToolTip from "../../../../../../../../../Components/Tooltip/PropertyTagToolTip";
import { GreenDiv, H3 } from "../../../../../../../../../styles/Common.style";
import DateRangeFilter from "../../../../../../../../../Components/DateRangePicker/DateRangeFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment/moment";
import "moment/locale/en-gb";
import { Calendar, CalendarProps, momentLocalizer } from "react-big-calendar";

import "react-big-calendar/lib/css/react-big-calendar.css";
import CustomPopup from "../../../../../../../../../Components/CustomPopup";
import DeleteCustomerAction from "../commonPage/DeleteCustomerAction";
import AddCustomerInfo from "../commonPage/AddCustomerInfo";
import ConfirmSuggestion from "../commonPage/ConfirmSuggestion";
import { NoRecordsToDisplay } from "../../../../../../../../../utils/validationConstants";
import { useBreadcrumb } from "../../../../../../../../../Components/BreadcrumbContext ";
import { H1 } from "../../../../../../../../../styles/Common.style";

// import { DateRangePicker } from "react-date-range";
const localizer = momentLocalizer(moment);

moment.locale("es-es", {
	week: {
		dow: 1, //Monday is the first day of the week.
	},
});

const FilterByAgentDropDownValues = [
	{ id: 1, label: "Agent One", value: "Private view" },
	{
		id: 2,
		label: "Double Agent",
		value: "Open House",
	},
];

let ComparedDate = "2023-05-10";
const events = [
	{
		id: 1,
		start: "2023-09-05",
		end: "2023-09-05",
		title: "Open House",
		user: "Jesper Thorkildsen",
		time: "10:00 - 10:00",
		style: {
			backgroundColor: "red !important",
		},
		data: {
			type: "one",
		},
	},

	{
		id: 2,
		// start: moment("2023-05-05").toDate(),
		// end: moment("2023-05-05").toDate(),
		start: "2023-09-05",
		end: "2023-09-05",
		title: "Private view",
		user: "Jesper Thorkildsen",
		time: " 10:10 - 10:20",
		isNew: true,
	},
];

// const eventPropGetter = (event) => {
//   if (event.isNew) {
//     // Apply custom background for new events
//     return {
//       className: "new-event",
//     };
//   }
//   return {}; // Return empty object for other events
// };

const formats = {
	dateFormat: "D", // Used 'D' to display the date as a single digit
};

// const components = {
//   event: (props) => {
//     const eventType = props?.event?.data?.type;
//     switch (eventType) {
//       case "one":
//         return (
//           <div style={{ background: "red", color: "White" }}>
//             {props?.title}
//           </div>
//         );
//       case "two":
//         return (
//           <div style={{ background: "red", color: "White" }}>
//             {props?.title}
//           </div>
//         );
//     }
//   },
// };
const Declined = () => {
	const [showAddInfo, setShowAddInfo] = useState(false);
	const [deleteShow, setDeleteShow] = useState(false);
	const [confirmSuggestionShow, setConfirmSuggestionShow] = useState(false);
	const [cancelInvitationShow, setCancelInvitationShow] = useState(false);

	const { updateBreadcrumbs } = useBreadcrumb();
	useEffect(() => {
		updateBreadcrumbs(["Overview", "Requests", "Declined"]);
	}, []);
	const calendarRef = useRef("");

	const CustomToolbar = ({ label, date, onNavigate, onView }) => {
		const handleNext = () => {
			// Custom logic for the next button
			// For example, you can navigate to the next month
			onNavigate("next", moment(date).add(1, "month"));
		};

		const handlePrev = () => {
			// Custom logic for the back button
			// For example, you can navigate to the previous month
			onNavigate("prev", moment(date).subtract(1, "month"));
		};

		const handleViewChange = (view) => {
			onView(view);
		};

		return (
			<div className="rbc-toolbar">
				<div className="d-flex w-100 justify-content-between">
					<div style={{ width: "25%" }}></div>
					<div className="d-flex">
						<button className="rbc-btn rbc-btn-nav-prev" onClick={handlePrev}>
							<FontAwesomeIcon
								icon={["far", "angle-left"]}
								style={{ color: "#000000" }}
							/>
						</button>
						<H1 className="rbc-toolbar-label fs-16 mx-3">{label}</H1>
						<button className="rbc-btn rbc-btn-nav-next" onClick={handleNext}>
							<FontAwesomeIcon
								icon={["far", "angle-right"]}
								style={{ color: "#000000" }}
							/>
						</button>
					</div>
					<div className="rbc-btn-group">
						<button
							className="rbc-btn "
							onClick={() => handleViewChange("month")}
						>
							Month
						</button>
						<button
							className="rbc-btn mx-2"
							onClick={() => handleViewChange("week")}
						>
							Week
						</button>
						<button className="rbc-btn" onClick={() => handleViewChange("day")}>
							Day
						</button>
					</div>
				</div>
			</div>
		);
	};

	const CustomEvent = ({ event }) => {
		// Used to Customize the rendering of the event component

		return (
			<>
				<div className="p-0">
					<div className="light-font-text fs-12 me-1 ">{event.time}</div>

					<div className="event-section-title fs-12">{event.title}</div>
					{/* {
            <div className="ms-2">
              <FontAwesomeIcon
                icon="fa-regular fa-arrows-repeat"
                style={{ color: "#000000" }}
              />
            </div>
          } */}
				</div>
			</>
		);
	};

	const calendarConfig = {
		components: {
			event: CustomEvent,
			toolbar: CustomToolbar,
			// eventWrapper: CustomEventWrapper,
		},
	};

	const columns = useMemo(
		() => [
			{
				accessorFn: (row) => `${row.name} ${row.notification} `,
				header: "Name",
				enableHiding: false,
				// size: 100,
				enableGlobalFilter: true,
				Cell: ({ row }) => (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "8px",
							cursor: "pointer",
						}}
					>
						<div>
							<TagToolTip bgcolor={"#88888"} text="Need Help About Tags" />
						</div>
						<div>{row.original.name}</div>
					</div>
				),
			},
			{
				accessorFn: (row) => `${row.name} ${row.notification} `,
				header: "Pre-screening",
				enableHiding: false,
				// size: 100,
				enableGlobalFilter: true,
				Cell: ({ row }) => <GreenDiv>Show</GreenDiv>,
			},
			{
				accessorKey: "property",
				header: "Property",
				enableHiding: false,
				// size: 20,
				enableGlobalFilter: false,
			},
			{
				accessorKey: "unit",
				header: "Unit",
				enableHiding: false,
				size: 200,
				enableGlobalFilter: false,
			},
			{
				accessorKey: "registration_date",
				header: "Registration",
				enableHiding: false,
				// size: 20,
				enableGlobalFilter: false,
			},
			{
				accessorKey: "Invitation_sent",
				header: "Invitation sent",
				enableHiding: false,
				// size: 20,
				enableGlobalFilter: false,
			},
			{
				accessorKey: "Date",
				header: "Date",
				enableHiding: false,
				size: 120,
				enableGlobalFilter: false,
				muiTableHeadCellProps: {
					sx: {
						backgroundColor: "#E3E9FB !important",
						borderTopLeftRadius: "8px !important",
					},
				},
			},
			{
				accessorKey: "Time",
				header: "Time",
				enableHiding: false,
				size: 120,
				enableGlobalFilter: false,
				muiTableHeadCellProps: {
					sx: {
						backgroundColor: "#E3E9FB !important",
						borderTopRightRadius: "8px !important",
					},
				},
			},
			{
				accessorKey: "suggestionDate",
				header: "Date",
				enableHiding: false,
				size: 120,
				enableGlobalFilter: false,
				muiTableHeadCellProps: {
					sx: {
						backgroundColor: "#FBF4E3 !important",
						borderTopLeftRadius: "8px !important",
						borderLeft: "4px solid #ffffff !important",
					},
				},
			},
			{
				accessorKey: "suggestionTime",
				header: "Time",
				enableHiding: false,
				size: 120,
				enableGlobalFilter: false,
				muiTableHeadCellProps: {
					sx: {
						backgroundColor: "#FBF4E3 !important",
						borderTopRightRadius: "8px !important",
					},
				},
			},
		],
		[]
	);
	const renderEmptyRowsFallback = () => (
		<div className="d-flex justify-content-center w-100 grey-text py-4 light-font-text">
			<div>No data available!!</div>
		</div>
	);
	return (
		<div>
			{" "}
			<div>
				<div className="d-flex w-100">
					<div className="p-4 mt-2 w-75">
						<div className="form-label-title  d-flex align-items-center gap-2">
							<H3> Declined </H3>
							<CustomToolTip text="Declined" />
						</div>
						<div className="light-font-text">
							Below is a list of the invitations where the customer has
							suggested a new time. It is possible to accept the new time,
							suggest a new time back to the customer or decline the viewing
							with the customer completely.
						</div>
					</div>
					<div className=" w-25 mt-2 py-4 pe-4 d-flex justify-content-end">
						<ETButton
							disabled={true}
							variant={"primary"}
							buttonText="CONFIRM SUGGESTION"
							// onClick={() => handleUpdateResponsibleAgent()}
						/>
					</div>
				</div>

				<div className="px-4 pt-2 pb-4">
					<MRTTheme
						colorVariantsForTableRow={["gray"]}
						colorVariantsForTableHead={["white"]}
						table={
							<MaterialReactTable
								columns={columns}
								// data={DeclinedTableData} //data is undefined on first render
								data={[]}
								icons={{ ...SortIcon }}
								renderEmptyRowsFallback={renderEmptyRowsFallback}
								localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
								enableColumnActions={false}
								enableRowSelection
								enableRowActions
								enableSelectAll
								enableTopToolbar={true}
								enableGlobalFilter
								globalFilterFn="contains"
								initialState={{
									showGlobalFilter: true,
									showColumnFilters: false,
								}}
								renderTopToolbar={({ table }) => (
									<>
										<div className="px-0 ">
											<Row>
												<Col xl={4} lg={6} className=" pb-2">
													<DateRangeFilter
													// date={dateRange}
													// setDate={setDateRange}
													// handleDateChange={handleDateChange}
													/>
												</Col>
												<Col xl={3} lg={5} className="ps-lg-0 pb-2">
													<ReactSelect placeholder={"Responsible agent"} />
												</Col>
											</Row>
										</div>
									</>
								)}
								muiTableContainerProps={{
									sx: {
										padding: "0px !important",
									},
								}}
								displayColumnDefOptions={{
									"mrt-row-select": {
										header: "",
									},
									"mrt-row-actions": {
										header: "",
									},
								}}
								positionActionsColumn="last"
								renderRowActions={({ row }) => (
									<Menus
										itemheight={23}
										itemwidth={23}
										iconborderradius="4px"
										itemcontainerwidth="200px"
										component={
											<div className="light-font-text">
												<div className="p-2 cursor-pointer">
													Edit invitation
												</div>
												<hr className="hr m-0"></hr>
												<div
													className="p-2 cursor-pointer"
													onClick={() => setConfirmSuggestionShow(true)}
												>
													Confirm suggestion
												</div>
												<hr className="hr m-0"></hr>
												<div
													className="p-2 cursor-pointer"
													onClick={() => setCancelInvitationShow(true)}
												>
													Cancel invitation
												</div>
												<hr className="hr m-0"></hr>
												<div
													className="p-2 cursor-pointer"
													onClick={() => setShowAddInfo(true)}
												>
													Add contact information
												</div>
												<hr className="hr m-0"></hr>
												<div
													className="p-2 cursor-pointer"
													onClick={() => setDeleteShow(true)}
												>
													Delete
												</div>
											</div>
										}
									/>
								)}
								muiTablePaperProps={{
									elevation: 0,
									fontFamily: "Cerebri sans-light !important",
									sx: {
										borderRadius: "0",
										border: "none",
										// backgroundColor: "#F9FAFA",
										table: {
											borderCollapse: "separate",
											borderSpacing: "0 4px",
										},

										"& td:nth-last-child(2)": {
											border: "none !important",
											backgroundColor: "#FBF4E3",
										},
										"& td:nth-last-child(3)": {
											borderLeft: "4px solid #ffffff !important",
											backgroundColor: "#FBF4E3",
										},
										"& td:nth-last-child(4)": {
											border: "none !important",
											backgroundColor: "#DEE5F7",
										},
										"& td:nth-last-child(5)": {
											border: "none !important",
											backgroundColor: "#DEE5F7",
										},
									},
								}}
								muiTableBodyRowProps={{
									selected: false,
								}}
								muiTablePaginationProps={{
									...paginationoptions,
								}}
								muiTableHeadRowProps={{
									sx: {
										...TableRowsStyle,
									},
								}}
							/>
						}
					/>
				</div>
				<div className="px-4 pb-4">
					<div style={{ background: "#f9fafa" }} className=" radius-8 p-4">
						{" "}
						<div className="form-label-title  d-flex alig-items-center gap-2">
							Calendar <CustomToolTip height={12} text="Calemdar" />
						</div>
						<div
							style={{ height: "747px" }}
							className="units-overview-calendar"
						>
							<Calendar
								ref={calendarRef}
								// eventPropGetter={eventPropGetter}
								toolbar={(toolbarProps) => <CustomToolbar {...toolbarProps} />}
								showToday={false}
								defaultView="month"
								views={["month", "week", "day"]}
								events={events}
								formats={formats}
								localizer={localizer}
								components={calendarConfig.components}
							/>
						</div>
					</div>
				</div>
			</div>
			{/* Add contact information */}
			<CustomPopup
				open={showAddInfo}
				onHide={() => setShowAddInfo(false)}
				title="Add information about the customer"
				titleTooltipText="Add information about the customer"
				title_color="black"
				size={"xl"}
				enableCrossButton={false}
				action_button_label="YES"
				action_button_label_color="white"
				action_button_color="#619130"
				cancel_button={true}
				cancel_button_bordercolor="grey"
				cancel_button_label="NO"
				cancel_button_label_color="black"
				cancel_button_color="#fff"
				footer_color={"#F9FAFA"}
				deletePopUpStyle={true}
				titleTooltip={true}
			>
				<div>
					<AddCustomerInfo
						showAddInfo={showAddInfo}
						setShowAddInfo={setShowAddInfo}
					/>
				</div>
			</CustomPopup>
			{/* Delete */}
			<DeleteCustomerAction
				deleteCustomerAction={deleteShow}
				setDeleteCustomerAction={setDeleteShow}
			/>
			{/*Confirm suggestion  */}
			<CustomPopup
				open={confirmSuggestionShow}
				onHide={() => setConfirmSuggestionShow(false)}
				title="Confirm suggestion"
				titleTooltipText="Confirm suggestion"
				title_color="black"
				size={"xl"}
				enableCrossButton={false}
				action_button_label="YES"
				action_button_label_color="white"
				action_button_color="#619130"
				cancel_button={true}
				cancel_button_bordercolor="grey"
				cancel_button_label="NO"
				cancel_button_label_color="black"
				cancel_button_color="#fff"
				footer_color={"#F9FAFA"}
				deletePopUpStyle={true}
				titleTooltip={true}
			>
				<div>
					<ConfirmSuggestion
						suggestionType={"CS"}
						setConfirmSuggestionShow={setConfirmSuggestionShow}
					/>
				</div>
			</CustomPopup>
			{/* Cancel Invitation Show */}
			<CustomPopup
				open={cancelInvitationShow}
				onHide={() => setCancelInvitationShow(false)}
				title="Cancel invitation"
				titleTooltipText="Cancel invitation"
				title_color="black"
				size={"xl"}
				enableCrossButton={false}
				action_button_label="YES"
				action_button_label_color="white"
				action_button_color="#619130"
				cancel_button={true}
				cancel_button_bordercolor="grey"
				cancel_button_label="NO"
				cancel_button_label_color="black"
				cancel_button_color="#fff"
				footer_color={"#F9FAFA"}
				deletePopUpStyle={true}
				titleTooltip={true}
			>
				<div>
					<ConfirmSuggestion
						suggestionType={"CI"}
						setConfirmSuggestionShow={setCancelInvitationShow}
					/>
				</div>
			</CustomPopup>
		</div>
	);
};

export default Declined;
