import ConfirmCancelSigningOrder from "../../standardComponents/cancelSigningOrder/ConfirmCancelSigningOrder";
import React from "react";
import { H2, H3, H5 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import ETButton from "../../../../Components/Buttons/ETButton";

const SelectReasonCancelSigningOrder = ({
  setOpenCancelWizardModal,
  cancelReasonHeader,
  cancelReasonHeaderTooltip,
  cancelReasonText,
  customerSection,
  customerInfo,
  unitSection,
  unitInfo,
  CancelQuestionData,
  Status,
  setSwitchWizardScreen,
}) => {
  return (
    <div>
      <div className="scrollable-container-y me-1" style={{ height: "395px" }}>
        <div className="px-4 py-4">
          <div>
            <div className=" d-flex align-items-center">
              <H3>{cancelReasonHeader}</H3>
              <div className="ps-2 cursor-pointer">
                {" "}
                <CustomToolTip size="sm" text={cancelReasonHeaderTooltip} />{" "}
              </div>
            </div>
          </div>
          <div className="paragraph2-light mt-4 w-50">{cancelReasonText}</div>
          {customerSection && (
            <div className="mt-4">
              <H5>Customer</H5>
              <div className="paragraph2-light mt-1">{customerInfo}</div>
            </div>
          )}
          {unitSection && (
            <div className="mt-4 ">
              <H5>Unit</H5>
              <div className="paragraph2-light mt-1">{unitInfo} </div>
            </div>
          )}
        </div>
        <div style={{ borderTop: "1px solid #F0F0F0" }} className="p-4">
          {/* <div className="px-3 py-1 radius-4 d-flex align-items-center gap-4 bgcolor-d-white mb-1 ">
          <div>
            <CustomCheckBox />
          </div>
          <div>Does not meet our requirements for device rental</div>
        </div> */}
          {/* <div className="px-3 py-1 radius-4 d-flex align-items-center gap-4 bgcolor-d-white mb-1 ">
          <div>
            <CustomCheckBox />
          </div>
          <div>Does not meet our requirements for device rental</div>
        </div> */}
          {CancelQuestionData?.map((data) => (
            <div className="px-3 py-1 radius-4 d-flex align-items-center gap-4 bgcolor-d-white mb-1 ">
              <div>
                <CustomCheckBox />
              </div>
              <div>{data.text}</div>
            </div>
          ))}
          <div>
            {/* <textarea
            // style={{ resize: "none" }}
            name="message"
            className="inputText"
            cols="20"
            rows="3"
            id="message"
            placeholder=""
            // value={value}
            // onChange={(e) => {
            //   onChange(e);
            // }}
          /> */}
            <div className="w-50 py-4">
              <textarea
                className="actor-signing-package-textarea w-100 px-3 pt-2"
                style={{
                  border: "1px solid #D1D1D1",
                  borderRadius: "4px",
                  height: "84px",
                }}
                placeholder="Please specify other reason"
              ></textarea>
            </div>
            <div className="paragraph2-light mb-5">{Status}</div>
          </div>
        </div>
      </div>
      <div className="py-2 px-3 d-flex flex-row justify-content-between  footer-section-button-container">
        <ETButton
          variant={"white"}
          buttonText="CANCEL"
          onClick={() => setOpenCancelWizardModal(false)}
        />
        <ETButton
          variant={"primary"}
          buttonText="NEXT"
          onClick={() => setSwitchWizardScreen("ConfirmSigningOrder")}
        />
      </div>
    </div>
  );
};
export default SelectReasonCancelSigningOrder;
