import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../Components/Tabs/ReactTabs.style";
import ContactsOverview from "./ContactsOverview";
import ContactsLeads from "./ContactsLeads";
import ContactsOngoing from "./ContactsOngoing";
import ContactsCustomers from "./ContactsCustomers";
import ContactsArchive from "./ContactsArchive";
import ETButton from "../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useBreadcrumb } from "../../Components/BreadcrumbContext ";
import { contactsTabsWizards } from "../../model/Constant";
import { NavLink } from "../../Components/Tabs/ReactTabs.style";
import { Link, Outlet } from "react-router-dom";
import { usePropertyId } from "../../hooks/stores/usePropertyStore";
import { H1 } from "../../styles/Common.style";

const Contacts = ({ Breadcrumbs }) => {
  const [contactsMainTabs, setContactsMainTabs] = useState("Overview");
  const { propertyId } = usePropertyId((state) => state);

  // const contactsTabsWizards = [
  //   {
  //     Id: "1",
  //     tab: "Overview",
  //     component: <ContactsOverview />,
  //   },
  //   {
  //     Id: "2",
  //     tab: "Leads",
  //     component: <ContactsLeads />,
  //   },
  //   {
  //     Id: "3",
  //     tab: "Ongoing",
  //     component: <ContactsOngoing />,
  //   },
  //   {
  //     Id: "4",
  //     tab: "Customers",
  //     component: <ContactsCustomers />,
  //   },
  //   {
  //     Id: "5",
  //     tab: "Archive",
  //     component: <ContactsArchive />,
  //   },
  // ];
  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    // if (contactsMainTabs === "Customers") {
    // 	updateBreadcrumbs(["Customers"]);
    // } else if (contactsMainTabs === "Archive") {
    // 	updateBreadcrumbs(["Leads", "Unassigned leads"]);
    // } else if (contactsMainTabs === "Ongoing") {
    // 	updateBreadcrumbs(["Ongoing", "Reservation"]);
    // } else
    if (contactsMainTabs === "Customers") {
      updateBreadcrumbs(["Customers"]);
    } else if (contactsMainTabs === "Archive") {
      updateBreadcrumbs(["Archive"]);
    }
  }, [contactsMainTabs]);

  return (
    <Container fluid>
      <div className="mt-4 ">
        <H1>Customers</H1>
        <div
          style={{
            fontFamily: " Cerebri sans-light",
            fontSize: "14px",
            cursor: "pointer",
          }}
        >
          <Link
            to="/customers/customers"
            state={{ Tab: "Overview" }}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span className="me-2 fs-16">Customers</span>
          </Link>
          <span className="me-2">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <span className="me-2 fs-16">{Breadcrumbs}</span>
        </div>
      </div>
      <div>
        {/* <Tabs lassName="pb-1 d-flex flex-column">
					<div
						className="d-flex py-3 justify-content-between align-items-center"
						style={{
							width: "100%",
							flexDirection: "row",
							flexWrap: "wrap",
						}}
					>
						<TabList className="light ps-0  ">
              {contactsTabsWizards.map((page, index) => (
                <>
                  <Tab
                    key={index}
                    style={{ position: "relative" }}
                    onClick={() => setContactsMainTabs(page?.tab)}
                  >
                    <span style={{ position: "relative" }}>{page?.tab}</span>
                  </Tab>
                </>
              ))}
            </TabList>

						<div>
							<ETButton
								icon={true}
								iconAlign={"left"}
								iconName="fa-solid fa-magnifying-glass"
								variant={"primary"}
								buttonText={"FIND CONTACT"}
							/>
						</div>
					</div>
					<div
						className="p-lg-4 p-3"
						style={{
							backgroundColor: "rgba(97, 145, 48, 0.05)",
							//   minHeight: "460px",
							borderRadius: "10px",
						}}
					>
						<div className="bg-white" style={{ borderRadius: " 10px" }}>
							{contactsTabsWizards.map((page, index) => (
								<TabPanel key={page?.Id}>{page?.component}</TabPanel>
							))}
						</div>
					</div>
				</Tabs> */}

        <div>
          <div className="d-flex flex-wrap justify-content-between align-items-center pb-3">
            <div className="d-flex">
              {contactsTabsWizards?.map((page, i) => (
                <NavLink
                  key={i}
                  state={{ propId: propertyId }}
                  to={page.link}
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    setContactsMainTabs(page?.tab);
                    // setSelectedSubTabType(["Overview"])
                  }}
                  className="me-3"
                >
                  {page?.tab}
                </NavLink>
              ))}
            </div>

            {/* <div>
              <ETButton
                icon={true}
                iconAlign={"left"}
                iconName="fa-solid fa-magnifying-glass"
                variant={"primary"}
                buttonText={"FIND CONTACT"}
              />
            </div> */}
          </div>
          <div
            className="p-4 containerStyle"
            // style={{ backgroundColor: "#f1f5f0", borderRadius: "10px" }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Contacts;
