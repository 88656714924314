import { create } from "zustand";

export const useRouting = create((set, get) => ({
  packageType: "",
  setPackageType: (data) => set({ packageType: data }),
  packageSubType: "",
  setPackageSubType: (data) => set({ packageSubType: data }),
  showPackage: false,
  setShowPackage: (data) => set({ showPackage: data }),
  editflag: false,
  setEditflag: (data) => set({ editflag: data }),
  packageId: "",
  setPackageId: (data) => set({ packageId: data }),
  addSettingPage: false,
  setAddSettingPage: (data) => set({ addSettingPage: data }),

  selectedTabType: 0,
  setSelectedTabType: (data) => set({ selectedTabType: data }),
  selectedMainTab: false,
  setSelectedMainTab: (data) => set({ selectedMainTab: data }),

  ////// For sub-Tab :- Created by @rupa //////
  selectedSubTab: "",
  setSelectedSubTab: (data) => set({ selectedSubTab: data }),

  selectedSubTabType: "",
  setSelectedSubTabType: (data) => set({
    selectedSubTabType: data,
  }),

  selectedSubForSetting: "Configuration",
  setSelectedSubForSetting: (data) => set({
    selectedSubForSetting: data,

  }),

}));
