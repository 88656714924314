import React, { useMemo } from "react";
import ETButton from "../Buttons/ETButton";
import CustomPopup from "../CustomPopup";
import { Col, Row } from "react-bootstrap";
import MRTTheme from "../table/MRTTheme";
import { MaterialReactTable } from "material-react-table";
import { SortIcon, SearchStyle, SearchIcon } from "../table/MRTGlobalProps";
import Menus from "../Menus/Menus";

const EditSigningOrder = ({
  openEditSigningOrder,
  setOpenEditSigningOrder,
  remindEditSigningOrder,
  setRemindEditSigningOrder,
  deleteEditSigningOrder,
  setDeleteEditSigningOrder,
}) => {
  const EditSigningColumns = useMemo(() => [
    {
      accessorKey: "status",
      header: "Status",
      // enableSorting: false,
      enableGlobalFilter: true,
      size: 50,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      accessorFn: (row) => `${row.signers} `,

      header: "Signers",
      size: 150,
      // enableSorting: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorFn: (row) => `${row.status} `,

      Cell: ({ row, header }) => (
        <>
          <div className="form-input ">
            <input type="text" placeholder="Name" id="email" />
            <label htmlFor="fName">Email</label>
          </div>
        </>
      ),
      header: "Email",
      size: 150,

      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorFn: (row) => `${row.role} `,

      header: "Role",
      size: 150,

      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "recievedOn",
      header: "Received on",
      size: 50,

      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "dateOfSignin",
      header: "Signing date",
      size: 50,

      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "lastReminder",
      header: "Last reminder",
      size: 50,

      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
  ]);
  const EditSigningData = [
    {
      status: "Done",
      signers: "Stig Werner Isaksen",
      role: "Coustomer",
      recievedOn: "Sep 22, 09:12",
      dateOfSignin: "Sep 23, 10:14",
      lastReminder: "-",
    },
    {
      status: "Pending",
      signers: "Klaus Bødker Udsen",
      role: "Coustomer",
      recievedOn: "-",
      dateOfSignin: "-",
      lastReminder: "Sep 26, 12:05",
    },
    {
      status: "Pending",
      signers: "Michael Rasmussen",
      role: " Owner",
      recievedOn: "-",
      dateOfSignin: "-",
      lastReminder: "-",
    },
  ];
  return (
    <>
      <CustomPopup
        title="Edit signing order"
        titleTooltipText="Edit signing order"
        title_color="black"
        titleFont="14px"
        titleTooltip={true}
        enableCrossButton={false}
        size="xl"
        open={openEditSigningOrder}
        onHide={() => setOpenEditSigningOrder(false)}
        onCloseAction={() => {
          setOpenEditSigningOrder(false);
        }}
      >
        <div className="p-4">
          <div
            className="px-4 py-3 radius-4"
            style={{
              backgroundColor: "rgba(97, 145, 48, 0.08)",
              border: "1px solid rgba(97, 145, 48, 0.16)",
            }}
          >
            <Row>
              <Col>
                <div>
                  <div className="form-label-title fs-12 grey-text pb-2 ps-2">
                    Status
                  </div>
                  <div
                    className="radius-4 px-3 py-1 font-medium fs-12"
                    style={{
                      backgroundColor: "rgba(246, 230, 81, 1)",
                      height: "24px",
                      width: "57px",
                    }}
                  >
                    1/3
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="form-label-title fs-12 grey-text pb-2">
                    Created
                  </div>
                  <div className="light-font-text fs-13">Mar 8, 2022</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="form-label-title fs-12 grey-text pb-2">
                    Expires
                  </div>
                  <div className="light-font-text fs-13">Apr 8, 2022</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="form-label-title fs-12 grey-text pb-2">
                    Created by
                  </div>
                  <div className="light-font-text fs-13">Thomas Johannesen</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="form-label-title fs-12 grey-text pb-2">
                    Reminder
                  </div>
                  <div className="light-font-text fs-13">Mar 28, 2022</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="form-label-title fs-12 grey-text pb-2">
                    Interval
                  </div>
                  <div className="light-font-text fs-13">3 days</div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="pt-4">
            <MRTTheme
              colorVariantsForTableRow={["gray"]}
              colorVariantsForTableHead={["white"]}
              table={
                <MaterialReactTable
                  columns={EditSigningColumns}
                  data={EditSigningData}
                  icons={{ ...SortIcon }}
                  enableStickyHeader
                  enableBottomToolbar={false}
                  enableSorting={true}
                  enableColumnActions={false}
                  enablePagination={false}
                  renderTopToolbar={false}
                  enableRowActions
                  positionActionsColumn="last"
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      size: 10,
                      header: "",
                    },
                    "mrt-row-select": {
                      header: "",
                    },
                  }}
                  muiSearchTextFieldProps={{
                    ...SearchStyle,
                    InputProps: {
                      ...SearchIcon,
                    },
                  }}
                  // muiTableContainerProps={{
                  //   sx: {
                  //     maxHeight: "262px",
                  //   },
                  // }}
                  renderRowActions={({ row }) => (
                    <div className="d-flex justify-content-end pe-3">
                      <Menus
                        itemheight={30}
                        itemwidth={30}
                        iconborderradius="4px"
                        itemcontainerwidth="180px"
                        component={
                          <>
                            <div style={{ fontSize: "14px" }}>
                              <div
                                className="addpropContent px-3 py-2 "
                                onClick={() => {
                                  setRemindEditSigningOrder(true);
                                }}
                              >
                                <span className="ms-1">Remind</span>
                              </div>
                              <hr className="addpropertyHr m-0"></hr>
                              <div className="addpropContent px-3 pt-2 pb-2">
                                <span className="ms-1">
                                  Copy link to clipboard
                                </span>
                              </div>
                              <hr className="addpropertyHr m-0"></hr>
                              <div
                                className="addpropContent px-3 pt-2 pb-2"
                                onClick={() => setDeleteEditSigningOrder(true)}
                              >
                                <span className="ms-1">Delete</span>
                              </div>
                            </div>
                          </>
                        }
                      />
                    </div>
                  )}
                  muiTableContainerProps={{
                    sx: {
                      padding: "0px !important",
                    },
                  }}
                />
              }
            />
          </div>
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <div></div>
          <div className="d-flex gap-3">
            <ETButton
              variant={"primary"}
              onClick={() => setOpenEditSigningOrder(false)}
              buttonText="save"
            />
          </div>
        </div>
      </CustomPopup>

      {/* ------------------ Remind Signing  orders ----------------- */}
      <CustomPopup
        open={remindEditSigningOrder}
        onHide={() => setRemindEditSigningOrder(false)}
        title=" Remind Signing  orders"
        size="md"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        // subTitle={<div>xs</div>}
        titleTooltip={true}
        titleTooltipText={" Remind Signing  orders"}
      >
        <div className="px-4 py-4">
          <div className="light-font-text">
            Are you sure that you will remind ?
          </div>
        </div>
        <div
          className="py-2 px-3 d-flex justify-content-between"
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="Cancel"
            fontSize="14px"
            fontWeight="500"
            onClick={() => setRemindEditSigningOrder(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="Remind"
            fontSize="14px"
            fontWeight="500"
            onClick={() => setRemindEditSigningOrder(false)}
          />
        </div>
      </CustomPopup>

      {/* ------------------ Delete Signing  orders ----------------- */}
      <CustomPopup
        open={deleteEditSigningOrder}
        onHide={() => setDeleteEditSigningOrder(false)}
        title=" Delete Signing  orders"
        size="md"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        // subTitle={<div>xs</div>}
        titleTooltip={true}
        titleTooltipText={" Delete Signing  orders"}
      >
        <div className="px-4 py-4">
          <div className="light-font-text">
            Are you sure you want to delete signing order?
          </div>
        </div>
        <div
          className="py-2 px-3 d-flex justify-content-between"
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="Cancle"
            fontSize="14px"
            fontWeight="500"
            onClick={() => setDeleteEditSigningOrder(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="Delete"
            fontSize="14px"
            fontWeight="500"
            onClick={() => setDeleteEditSigningOrder(false)}
          />
        </div>
      </CustomPopup>
    </>
  );
};

export default EditSigningOrder;
