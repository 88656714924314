import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ETButton from "../../../Components/Buttons/ETButton";
import "../../../index.css";
import CustomCheckBox from "../../../Components/Checkbox/Checkbox";
import {
  useGetGeographyCountry,
  useGetZipcodeByRegionId,
  useGetRegionByCountryId,
} from "../../../hooks/queries/useGeographyQueries";
import { useAllPropertiesData, useCountry } from "../../../hooks/stores/usePropertyStore";
import { H2, H3 } from "../../../styles/Common.style";
import { useAllPropertyList } from "../../../hooks/queries/usePropertyQueries";


const GeographyDropdown = ({
  applyGeography,
  ResetAll,
  checkedCountry,
  setCheckedCountry,
  selectedRegionID,
  setSelectedRegionID,
  optionCheckedZipcode,
  setOptionCheckedZipcode,

}) => {

  //--------------------------------------------state-------------------------------//
  const [allCheckedRegions, setAllCheckedRegions] = useState(false);
  const [allCheckedZipcode, setAllCheckedZipcode] = useState(false);
  const [country, setCountry] = useState([]);
  const [region, setRegion] = useState([]);
  const [zipCode, setZipCode] = useState([]);

  const { countryStoreData, setCountryStoreData } = useCountry(
    (state) => state
  );

  const { data: GetRegionByCountryIdData, refetch: getRegionByCountryId } =
    useGetRegionByCountryId(checkedCountry);
  const { data: GetGeographyCountryData } = useGetGeographyCountry();

  const { data: GetZipcodeByRegionIdData } =
    useGetZipcodeByRegionId(selectedRegionID);

  useEffect(() => {
    setRegion(GetRegionByCountryIdData?.data);
  }, [GetRegionByCountryIdData]);

  useEffect(() => {
    setZipCode(GetZipcodeByRegionIdData?.data);
  }, [GetZipcodeByRegionIdData]);

  useEffect(() => {
    setCountryStoreData(GetGeographyCountryData?.data);
  }, [GetGeographyCountryData]);



  //Checkbox logic for All Regions
  const OnClickedRegions = (e, isCheckAll) => {
    const { id, checked } = e.currentTarget;
    if (isCheckAll) {
      if (checked) {
        let checkValue = region.map((list) => list.Id);

        // setOptionCheckedRegions(checkValue);
        setAllCheckedRegions(true);
        setSelectedRegionID(checkValue);
      } else {
        // setOptionCheckedRegions([]);
        setAllCheckedRegions(false);
        setSelectedRegionID([]);
        setOptionCheckedZipcode([]);
        setAllCheckedZipcode(false);
      }
    } else {
      if (checked) {
        let checkValue = [...selectedRegionID, Number(id)];
        setSelectedRegionID(checkValue);

        if (checkValue.length === region.length) {
          setAllCheckedRegions(true);
        }
        setSelectedRegionID(checkValue);
      } else {
        let checkValue = selectedRegionID.filter((item) => item !== Number(id));
        setSelectedRegionID(checkValue);
        setAllCheckedRegions(false);
        setSelectedRegionID(checkValue);

        // Clear the zip codes of the unchecked region
        let updatedOptionCheckedZipcode = optionCheckedZipcode.filter(
          (zipcodeId) => {
            const zipcode = GetZipcodeByRegionIdData?.data.find(
              (item) => item.Id == zipcodeId
            );
            return !zipcode || zipcode.DataValue1 != id;
          }
        );
        setOptionCheckedZipcode(updatedOptionCheckedZipcode);
      }
      setAllCheckedZipcode(false);
    }
  };

  const onClickedZipcode = (e, isCheckAll) => {
    const { id, checked } = e.currentTarget;

    if (isCheckAll) {
      if (checked) {
        let checkZipValue = zipCode.map((list) => list.Id);

        setOptionCheckedZipcode(checkZipValue);
        setAllCheckedZipcode(true);
        // setSelectedGeographyzips(checkZipValue);
      } else {
        setAllCheckedZipcode(false);
        setOptionCheckedZipcode([]);
      }
    } else {
      if (checked) {
        let checkZipValue = [...optionCheckedZipcode, id];
        setOptionCheckedZipcode(checkZipValue);

        if (checkZipValue?.length == zipCode?.length) {
          setAllCheckedZipcode(true);
        }
        // setSelectedGeographyzips(checkZipValue);
      } else {
        let checkZipValue = optionCheckedZipcode.filter((item) => item != id);
        if (checkZipValue?.length !== 0) {
          setOptionCheckedZipcode(checkZipValue);
          setAllCheckedZipcode(false);
          // setSelectedGeographyzips(checkZipValue);
        } else {
          setAllCheckedZipcode(false);
          setOptionCheckedZipcode([]);
          // setSelectedGeographyzips([]);
        }
      }
    }
  };

  const CountrySelected = (e, index) => {
    const { id, checked } = e.currentTarget;
    if (checked) {
      let checkcountryValue = [...checkedCountry, id];
      setCheckedCountry(checkcountryValue);
      setRegion(GetRegionByCountryIdData?.data);
      setZipCode(GetZipcodeByRegionIdData?.data);
    } else {
      let checkcountryValue = checkedCountry.filter((item) => item != id);
      setCheckedCountry(checkcountryValue);
      // Clear the regions of the unchecked country
      let updatedOptionCheckedRegions = selectedRegionID.filter((regionId) => {
        const region = GetRegionByCountryIdData?.data.find(
          (item) => item.Id == regionId
        );
        return !region || region.DataValue1 != id;
      });
      // setOptionCheckedRegions(updatedOptionCheckedRegions);
      setSelectedRegionID(updatedOptionCheckedRegions);
      // Clear the zip code of the unchecked country
      let updatedOptionCheckedZipcodes = optionCheckedZipcode.filter((zipId) => {
        const zip = GetZipcodeByRegionIdData?.data.find(
          (item) => item.Id == zipId && item.DataValue3 == id
        );
        return !zip;
      });

      setOptionCheckedZipcode(updatedOptionCheckedZipcodes);
    }
    // setZipCode([]);
    setAllCheckedZipcode(false);
    setAllCheckedRegions(false);
    // setTableData(allPropertiesData?.data)
  };

  // const ResetAll = () => {
  //   setCheckedCountry([]);
  //   // setOptionCheckedRegions([]);
  //   setOptionCheckedZipcode([]);
  //   setSelectedRegionID("");
  //   setRegion([]);
  //   setZipCode([]);
  //   setAllCheckedZipcode(false);
  //   setAllCheckedRegions(false);
  //   setTableData(allPropertiesData?.data);
  // };

  // useEffect(() => {
  // 	setCountry(countryStoreData);
  // }, [countryData]);


  return (
    <Container className="px-0 light-font-text">
      <div className="py-3 px-4 d-flex justify-content-between light-font-text">
        <div className="grey-text">
          Select a country followed by a region and/or zip code
        </div>
        <div className="normal-font-text ">
          {" "}
          <span className="light-font-text">
            <b>{optionCheckedZipcode.length}</b> zipcode selected
          </span>
        </div>
      </div>

      <Row className="mx-0 ">
        <Col
          sm={3}
          className="ps-2"
          style={{ backgroundColor: "#F9FAFA", border: "1px solid #F4F5F5" }}
        >
          <H3 className="ps-2 py-2 ms-1 ">Country</H3>

          {countryStoreData?.map((item, index) => {
            return (
              <div
                key={index}
                className="d-flex ms-1"
                style={{ alignItems: "center" }}
              >
                <div>
                  <CustomCheckBox
                    id={item.Id}
                    checked={checkedCountry?.includes(item.Id)}
                    value={item.Id}
                    onChange={(e) => CountrySelected(e, index)}
                  />
                </div>

                <div
                  className="ms-1 me-2 pb-1"
                  style={{ alignContent: "center" }}
                >
                  <img src={item.DataValue1} width="17px" height="12px" />
                </div>
                <div>{item.Text}</div>
              </div>
            );
          })}
        </Col>

        <Col
          sm={5}
          className="ps-2"
          style={{
            border: "1px solid #F4F5F5",
          }}
        >
          <H3 className="ps-2 py-2 ms-2 ">Region</H3>
          {checkedCountry?.length == 0 || region?.length == 0 ? (
            <div className="d-flex ms-3 py-1" style={{ alignItems: "center" }}>
              <div className="pt-1 grey-text">
                {checkedCountry?.length == 0
                  ? "No country is selected"
                  : "No region available for selected country"}
              </div>
            </div>
          ) : (
            <div className="d-flex ms-1" style={{ alignItems: "center" }}>
              <div>
                <CustomCheckBox
                  key="0"
                  id={"allCheckedRegions"}
                  checked={allCheckedRegions}
                  onChange={(e) => OnClickedRegions(e, true)}
                />
              </div>
              <div>All regions</div>
            </div>
          )}

          <div style={{ height: "310px" }} className="scrollable-container-y">
            {region?.map((item) => {
              return (
                <div
                  key={item?.Id}
                  className="d-flex ms-1"
                  style={{ alignItems: "center" }}
                >
                  <div>
                    <CustomCheckBox
                      id={item?.Id}
                      key={item?.Id}
                      checked={selectedRegionID?.includes(item?.Id)}
                      onChange={(e) => OnClickedRegions(e, false)}
                      value={item?.Id}
                    />
                  </div>
                  <div>{item?.Text}</div>
                </div>
              );
            })}
          </div>
        </Col>

        <Col
          sm={4}
          className="ps-2"
          style={{
            border: "1px solid #F4F5F5",
          }}
        >
          <H3 className="ps-2 py-2 ms-2 ">Zip code</H3>
          {selectedRegionID?.length == 0 || zipCode?.length == 0 ? (
            <div className="d-flex ms-3 py-1 " style={{ alignItems: "center" }}>
              <div className="pt-1 grey-text">
                {selectedRegionID?.length == 0
                  ? "No region is selected"
                  : " No zipcode available for selected region"}
              </div>
            </div>
          ) : (
            <div className="d-flex ms-1" style={{ alignItems: "center" }}>
              <div>
                <CustomCheckBox
                  key="0"
                  id={"allCheckedZipcode"}
                  checked={allCheckedZipcode}
                  onChange={(e) => onClickedZipcode(e, true)}
                />
              </div>
              <div>All zip codes </div>
            </div>
          )}

          <div
            style={{ height: "310px", overflowY: "scroll" }}
            className="scrollable-container-y"
          >
            {zipCode?.map((item) => {
              return (
                <div
                  key={item?.Id}
                  className="d-flex ms-1"
                  style={{ alignItems: "center" }}
                >
                  <div>
                    <CustomCheckBox
                      id={item?.Id}
                      key={item?.Id}
                      checked={optionCheckedZipcode?.includes(item?.Id)}
                      onChange={(e) => onClickedZipcode(e, false)}
                      value={item?.Id}
                    />
                  </div>
                  <div>{item?.Text}</div>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
      <div
        className="py-2 px-3 d-flex justify-content-between"
        style={{
          backgroundColor: "#F9FAFA",
          borderRadius: " 0px 0px 8px 8px",
        }}
      >
        <ETButton variant={"white"} buttonText="RESET" onClick={ResetAll} />
        <ETButton
          variant={"primary"}
          buttonText="APPLY"
          onClick={() => {
            applyGeography(optionCheckedZipcode);
          }}
        />
      </div>
    </Container>
  );
};

export default GeographyDropdown;
