import React from "react";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { H2 } from "../../../../styles/Common.style";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../../../Components/Tabs/ReactTabs.style";
import SoMeIntegrations from "./marketingTabs/SoMeIntegrations";
import Customers from "./marketingTabs/Customers";
import {
  useOrganizationId,
  useOrganizationSettingsBreadcrumbsTabs,
} from "../../../../hooks/stores/useOrganizationStore";
import EmailTemplates from "./marketingTabs/EmailTemplates";
import MarketingLabels from "./marketingTabs/marketingLabels/MarketingLabels";

const Marketing = () => {
  // zustand
  const setOrganizationBreadcrumbsTabs = useOrganizationSettingsBreadcrumbsTabs(
    (state) => state.setOrganizationBreadcrumbsTabs
  );
  const { organizationId } = useOrganizationId((state) => state);

  return (
    <div className="whiteContainerStyle">
      <div className="px-3 pb-3 border-bottom">
        <div className="px-2 pt-3 d-flex">
          <div className="d-flex align-items-end  gap-2">
            <H2>Marketing</H2>
            <CustomToolTip
              size="sm"
              text="Marketing"
            />
          </div>
        </div>
      </div>
      {/*All tabs section  */}

      <div className=" border-bottom">
        <div>
          <Tabs className="d-flex flex-column ">
            <div
              className="d-flex px-4 py-3 mb-3 justify-content-between align-items-center"
              style={{
                width: "100%",
                flexDirection: "row",
                flexWrap: "wrap",
                borderBottom: "1px solid #F0F0F0",
              }}
            >
              <TabList
                className=" d-flex light ps-0 light"
                style={{ gap: "7px" }}
              >
                <Tab
                  onClick={() =>
                    setOrganizationBreadcrumbsTabs("SoMe integrations")
                  }
                >
                  SoMe integrations
                </Tab>
                <Tab
                  onClick={() => setOrganizationBreadcrumbsTabs("Customers")}
                >
                  Customers
                </Tab>
                <Tab
                  onClick={() =>
                    setOrganizationBreadcrumbsTabs("Communication templates")
                  }
                >
                  Communication templates
                </Tab>
                <Tab
                  onClick={() =>
                    setOrganizationBreadcrumbsTabs("Marketing labels")
                  }
                >
                  Marketing labels
                </Tab>
                <Tab onClick={() => setOrganizationBreadcrumbsTabs("POI")}>
                  POI
                </Tab>
              </TabList>
            </div>
            <div>
              <TabPanel>
                <SoMeIntegrations />
              </TabPanel>
              <TabPanel>
                <Customers />
              </TabPanel>
              <TabPanel>
                <EmailTemplates
                  type={"o"}
                  id={organizationId}
                />
              </TabPanel>
              <TabPanel>
                <MarketingLabels />
              </TabPanel>
              <TabPanel>
                <div>POI</div>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Marketing;
