import React, { useEffect, useMemo } from "react";
import ETButton from "../../../../../../Components/Buttons/ETButton";
import CustomToolTip from "../../../../../../Components/Tooltip/CustomTooltip";
import Menus from "../../../../../../Components/Menus/Menus";
import { useState } from "react";
import AddTagModal from "./AddTagModal";
import { NavLink } from "react-router-dom";
import TagToolTip from "../../../../../../Components/Tooltip/PropertyTagToolTip";
import { MaterialReactTable } from "material-react-table";

import {
  TabList,
  TabPanel,
  Tab,
  Tabs,
} from "../../../../../../Components/Tabs/ReactTabs.style";
import { useGetTagByUnitId } from "../../../../../../hooks/queries/useTagsQueries";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { status } from "../../../../../../model/Constant";
import moment from "moment";
import CustomPopup from "../../../../../../Components/CustomPopup";
import { DeleteUnitTag } from "../../../../../../services/Units";
import MRTTheme from "../../../../../../Components/table/MRTTheme";
import { useBreadcrumb } from "../../../../../../Components/BreadcrumbContext ";
import { H2, H3 } from "../../../../../../styles/Common.style";
import { SortIcon } from "../../../../../../Components/table/MRTGlobalProps";

export const Settings = ({ UnitId, propId }) => {
  const [openPropertyTag, setOpenPropertyTag] = useState(false);
  const [modalHeadingStyle, setModalHeadingStyle] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [tagId, setTagId] = useState("");
  const [tabType, setTabType] = useState(0);
  const [deletePopUp, setDeletePopUp] = useState(false);
  //---------------------React query-------------------//
  const { data: GetTagByUnitIdData, refetch: GetTagByUnitIdDataRefetch } =
    useGetTagByUnitId(propId, UnitId);

  // const { mutateAsync: DeleteUnitTagMutate } = useMutation(
  //   "DeleteUnitTagMutate",
  //   (tagId) => DeleteUnitTag(UnitId, tagId)
  // );

  const handleOpenAddTag = () => {
    setOpenPropertyTag(true);
    setModalHeadingStyle(true);
  };
  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    if (tabType == 0) {
      updateBreadcrumbs(["Settings", "Tags"]);
    } else if (tabType == 1) {
      updateBreadcrumbs(["Settings", "Status labels"]);
    } else if (tabType == 2) {
      updateBreadcrumbs(["Settings", "Integrations"]);
    } else {
    }
  }, [tabType]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [settingdata, setSettingData] = useState([]);
  useEffect(() => {
    setSettingData(GetTagByUnitIdData?.data);
  }, [GetTagByUnitIdData]);

  const modifiedData = settingdata?.map((item) => {
    return {
      ...item,
      modifiedOn: moment(item.modified_on).format("DD.MMMM.YYYY | HH:mm:ss"),
      createdOn: moment(item.modified_on).format("DD.MMMM.YYYY | HH:mm:ss"),
    };
  });

  const handleTagByUnitId = async () => {
    // const result = await DeleteUnitTagMutate(UnitId,tagId);
    DeleteUnitTag(UnitId, tagId)
      .then((response) => {
        if (response?.status === status.s && response?.data?.bReturn) {
          toast.success(response?.data?.message);
          GetTagByUnitIdDataRefetch();
          setDeletePopUp(false);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      });

    //   // if (response.status == "success") {
    //   // 	toast.success(response.data.message + "!");
    //   // }
  };

  const tagColumnData = [
    {
      info: "",
      tag_id: "",
      name: "-",
      group: "-",
      menu: "",
    },
  ];
  const tagColumn = useMemo(
    () => [
      {
        accessorKey: "info",
        header: "",
        size: 0,
        enableSorting: false,
        Cell: ({ cell }) => {
          return cell?.row?.original.tag_id ? (
            <TagToolTip text="Need Help About Tags" />
          ) : (
            <div></div>
          );
        },
      },
      {
        accessorKey: "tag_id",
        header: "ID",
        size: 0,
        Cell: ({ cell }) => {
          return cell?.row?.original.tag_id ? (
            <div>{cell.getValue()}</div>
          ) : (
            <div>-</div>
          );
        },
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
        Cell: ({ cell }) => {
          return cell?.row?.original.tag_id ? (
            <div>{cell.getValue()}</div>
          ) : (
            <div>-</div>
          );
        },
      },
      {
        accessorKey: "group", //normal accessorKey
        header: "Group",
        size: 300,
        Cell: ({ cell }) => {
          return cell?.row?.original.tag_id ? (
            <div>{cell.getValue().split(",").join(", ")}</div>
          ) : (
            <div>-</div>
          );
        },
      },
      {
        accessorKey: "menu", //normal accessorKey
        header: "",
        size: 0,
        enableSorting: false,
        Cell: ({ cell }) => {
          return cell?.row?.original.tag_id ? (
            <div>
              <Menus
                anchorEl={anchorEl}
                open={anchorEl}
                elevation={0}
                onClose={handleClose}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                itemheight={23}
                itemwidth={23}
                iconborderradius="4px"
                component={
                  <div style={{ fontSize: "14px", width: "198px" }}>
                    <div
                      className="px-3 py-2 cursor-pointer"
                      onClick={() => {
                        setDeletePopUp(!deletePopUp);
                        setTagId(cell?.row?.original?.tag_id);
                      }}
                    >
                      <span
                        className="ms-1"
                        style={{
                          fontFamily: "Cerebri sans",
                          fontSize: "14px",
                          fontweight: "300",
                          alignItems: "center",
                        }}
                      >
                        Remove
                      </span>
                    </div>
                  </div>
                }
              />
            </div>
          ) : (
            <div></div>
          );
        },
      },
    ],
    []
  );
  const renderEmptyRowsFallback = () => (
    <div className="d-flex justify-content-center w-100 grey-text py-4">
      <div>You do not have any tag yet. </div>
    </div>
  );
  return (
    <div className="bg-white radius-8 fs-14  mainborder">
      <div className="d-flex justify-content-between px-4  pt-3">
        <div className="d-flex flex-column ">
          <div className="d-flex align-items-center">
            <H2>Settings </H2>
            <span className="ms-2 ">
              <CustomToolTip text="Settings" />
            </span>
          </div>
          <div className="fs-12 created-by">
            {/* {modifiedData?.map((data)=>(
                <>{data.modified_by && data.modified_on ?  
                <div>Last updated by &nbsp;{data.modified_by},{data.modifiedOn}</div>
                :
                
                <div>Created by {data.created_by},{data.createdOn}</div>}</>
               
              ))} */}
          </div>
        </div>
      </div>

      <hr className="hr m-0 mt-3" />

      <div>
        <Tabs className="d-flex flex-column m-0">
          <div
            className=" px-4 d-flex justify-content-between flex-wrap gap-2 py-3"
            style={{ borderBottom: "1px solid #F0F0F0" }}
          >
            <TabList
              className=" d-flex  ps-0 mx-0 flex-wrap"
              style={{ gap: "7px" }}
            >
              <Tab onClick={() => setTabType(0)}>Tags</Tab>
              {/* <Tab onClick={() => setTabType(1)}>Status labels</Tab>
              <Tab onClick={() => setTabType(2)}>Integrations</Tab> */}
              {/* <Tab>Signing</Tab> */}
            </TabList>
          </div>
          <div className="document-content">
            <TabPanel>
              <div className="px-4">
                <div className="py-4">
                  <div className="form-label-title ">
                    Tags linked to this unit
                  </div>
                  <div className="created-by">
                    Below you can see the tags that are linked to the unit. You
                    can add a new tag or delete one.
                  </div>
                </div>
                <div style={{ minHeight: "349px" }}>
                  <div
                    // className="d-flex justify-content-between align-items-center"
                    style={{
                      // backgroundColor: "rgba(240, 240, 240, 1)",
                      borderRadius: "4px",
                    }}
                  >
                    {/* <div className="fw-300 fs-20">#2-bedroom</div> */}

                    <div>
                      <MRTTheme
                        colorVariantsForTableData={["odd"]}
                        table={
                          <MaterialReactTable
                            columns={tagColumn}
                            renderEmptyRowsFallback={renderEmptyRowsFallback}
                            data={
                              GetTagByUnitIdData?.data.length > 0
                                ? GetTagByUnitIdData?.data
                                : tagColumnData
                            }
                            icons={{ ...SortIcon }}
                            enableTopToolbar={false}
                            enableColumnActions={false}
                            enablePagination={false}
                            enableBottomToolbar={false}
                            // muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
                            // icons={{
                            //   //change sort icon, connect internal props so that it gets styled correctly
                            //   // ArrowDownwardIcon: (props) =>  <FontAwesomeIcon icon={faSortDown} {...props} />,
                            //   // SearchIcon: () => <FontAwesomeIcon icon={faSearch} />,
                            //   ArrowDownwardIcon: () => (
                            //     <FontAwesomeIcon
                            //       icon="fa-regular fa-sort"
                            //       style={{ color: "#bfbfbf" }}
                            //     />
                            //   ), //best practice
                            // }}
                            muiTableContainerProps={{
                              sx: {
                                padding: "0px !important",
                              },
                            }}
                          />
                        }
                      />

                      <CustomPopup
                        // modalHeadingStyle={modalHeadingStyle}
                        style={{ position: "relative" }}
                        size="md    "
                        open={deletePopUp}
                        onHide={() => setDeletePopUp(false)}
                        title="Are you carrying out the trash?"
                        title_color="black"
                        enableCrossButton={false}
                        onCloseAction={() => {
                          setDeletePopUp(false);
                        }}
                        action_button_label="YES"
                        action_button_label_color="white"
                        action_button_color="#619130"
                        cancel_button={true}
                        cancel_button_bordercolor="grey"
                        cancel_button_label="NO"
                        cancel_button_label_color="black"
                        cancel_button_color="#fff"
                        footer_color={"#F9FAFA"}
                      >
                        <div className="p-4">
                          <div className="fs-14 fw-300 text-left mb-3">
                            <p>Are you sure you want to delete the tag?</p>
                            {/* <p>That will conflict with.....</p> */}
                          </div>
                        </div>

                        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
                          <ETButton
                            variant={"white"}
                            buttonText="COLD FEET"
                            onClick={() => setDeletePopUp(false)}
                          />

                          <ETButton
                            variant={"primary"}
                            buttonText="I'M SURE"
                            onClick={() => handleTagByUnitId()}
                          />
                        </div>
                      </CustomPopup>
                    </div>
                    {/* <div>
              <Menus />
            </div> */}
                  </div>
                </div>
              </div>
            </TabPanel>
            {/* <TabPanel></TabPanel>
            <TabPanel></TabPanel>
            <TabPanel></TabPanel> */}
          </div>
        </Tabs>
      </div>

      <div className="py-2 px-3 mt-2 d-sm-flex justify-content-between footer-section-button-container">
        <div className="m-1">
          <NavLink
            to="/property-units/units/residential"
            state={{ propId: propId, Tab: "Units" }}
          >
            <ETButton
              variant={"white"}
              buttonText="BACK TO UNITS"
              // onClick={}
            />
          </NavLink>
        </div>
        {/* <div className="m-1">
          <ETButton
                     variant={"primary"}
            buttonText="SAVE"
          />
        </div> */}
      </div>
      <AddTagModal
        openPropertyTag={openPropertyTag}
        modalHeadingStyle={modalHeadingStyle}
        setOpenPropertyTag={setOpenPropertyTag}
      />
    </div>
  );
};
