import React, { useMemo, useState } from "react";
import { CircleMsg, H4 } from "../../../../styles/Common.style";
import ETButton from "../../../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TagToolTip from "../../../../Components/Tooltip/PropertyTagToolTip";
import MRTTheme from "../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";
import Menus from "../../../../Components/Menus/Menus";
import {
  paginationoptions,
  SortIcon,
} from "../../../../Components/table/MRTGlobalProps";
import DateRangeFilter from "../../../../Components/DateRangePicker/DateRangeFilter";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { Col, Row } from "react-bootstrap";
import CustomPopup from "../../../../Components/CustomPopup";
import AddResponsibleAgent from "../../standardComponents/AddResponsibleAgent";
import CustomerDetailTooltip from "../../standardComponents/CustomerDetailTooltip";
import { ActionsPopup } from "../../screenings/popups/ActionsPopup";
import RestartScreening from "../../screenings/popups/RestartScreening";
import MoveToAnotherUnit from "../MoveToAnotherUnit";
import Heart from "../../../../Components/Star/Heart";
import { useCustomerFunnelTopLoadingBar } from "../../../../hooks/stores/useCustomerFunnelStore";
import CreateNewContractWizard from "../createNewContract/CreateNewContractWizard";
import FollowUp from "../../standardComponents/follow up/FollowUp";
import CreateNewAppointments from "../CreateNewAppointments/CreateNewAppointments";

const Attended = () => {
  //----------Store to handle top loading bar----------//
  const { topLoadingProgressBar, setTopLoadingProgressBar } =
    useCustomerFunnelTopLoadingBar((state) => state);
  //----------Store to handle top loading bar----------//

  const [confirmedSelectedInterest, setConfirmSelectedInterest] =
    useState(false);
  const [rowSelection, setRowSelection] = useState({}); // Initialize as an empty object
  const [showPopUp, setShowPopUp] = useState({ create: false, edit: false });
  const [agentDetails, setAgentDetails] = useState("");
  const [showMoveToAnotherUnits, setShowMoveToAnotherUnits] = useState(false);
  const [creatingNewContract, setCreatingNewContract] = useState(false);
  const [openNewAppointmentScreen, setOpenNewAppointmentScreen] =
    useState(false);
  /*Archive-popup*/
  const [ArchiveScreen, setArchiveScreen] = useState(false);
  const [showStartScreening, setShowStartScreening] = useState(false);
  const archiveScreening = (item) => {
    setArchiveScreen(true);
  };
  /*-- Follow-up customer  --*/
  const [showFollowsUpWizard, setShowFollowsUpWizard] = useState(false);
  const [selectedFollowUpIndex, setSelectedFollowUpIndex] = useState(0);
  const handleCustomerDetails = (pageIndex = 0) => {
    // console.log(pageIndex, "i asssssssssssss");
    setShowFollowsUpWizard(true);
    setSelectedFollowUpIndex(pageIndex);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "Id",
        header: "",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: true,
        Cell: ({ row }) => <Heart isFavorite="0" />,
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "0px !important",
            paddingLeft: "3px !important",
          },
        },
      },
      {
        accessorFn: (row) => `${row.Customer} ${row.Id}`,
        header: "Name",
        enableHiding: false,
        // size: 100,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            <TagToolTip
              iconColor={"#888888"}
              size={"lg"}
              border="1px solid #F0F0F0"
              padding={"0px"}
              boxShadow="2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
              text={
                <div style={{ width: "700px" }}>
                  <CustomerDetailTooltip data={row?.original} />
                </div>
              }
            />
            {row.original.Customer}
          </div>
        ),
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
      },

      {
        accessorKey: "Property",
        header: "Property",
        size: 100,
      },
      {
        accessorKey: "Unit", //normal accessorKey
        header: "Unit",
        size: 180,
      },
      {
        accessorFn: (row) => `${row.EventType},${row.Event}`,
        header: "Event",
        enableHiding: false,
        size: 360,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center  text-align-left">
            <TagToolTip
              iconColor={"#888888"}
              size={"lg"}
              border="1px solid #F0F0F0"
              padding={"0px"}
              boxShadow="2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
              text={""}
            />
            <span className="grey-text fs-14 ms-2 ">
              {" "}
              {row.original.EventType} -
            </span>
            <span className="light-font-text ms-1"> {row.original.Event}</span>
          </div>
        ),
      },
      {
        accessorKey: "Registration",
        header: "Time",
        size: 180,
      },

      {
        accessorFn: (row) => `${row.ResponsibleAgent}`,
        header: "Responsible agent",
        enableHiding: false,
        size: 80,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>
            {row.original.ResponsibleAgent == "-" ? (
              <span className="grey-text">Unassigned</span>
            ) : (
              row.original.ResponsibleAgent
            )}{" "}
          </div>
        ),
      },
    ],
    []
  );
  return (
    <div className="p-4">
      {" "}
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div className="">
          <div className="d-flex align-items-center  pb-2">
            <H4> Attended </H4>
            <CircleMsg>
              <span>{data?.length}</span>
            </CircleMsg>
          </div>
          <div className="light-font-text w-75">
            Below is a list of the viewing inquiry that have been made on this
            unit. It is possible to send an invitation to one or more customers
            with a proposed viewing day and slot time.
          </div>
        </div>

        <ETButton
          variant={"primary"}
          buttonText={"CONFIRM INTEREST"}
          disabled={Object?.keys(rowSelection).length == 0}
          onClick={() => {
            setConfirmSelectedInterest(true);
          }}
        />
      </div>
      <MRTTheme
        colorVariantsForTableRow={["gray"]}
        colorVariantsForTableHead={["white"]}
        table={
          <MaterialReactTable
            // data={data}

            data={[]}
            columns={columns}
            enableRowSelection={true}
            enableColumnActions={false}
            icons={{ ...SortIcon }}
            enableRowActions={true}
            onRowSelectionChange={setRowSelection}
            state={{ rowSelection }}
            renderTopToolbar={({ table }) => (
              <>
                <div className="px-0 ">
                  <Row>
                    <Col xl={4} lg={5} className="pe-lg-0">
                      <DateRangeFilter />
                    </Col>
                    <Col xl={3} lg={3} className="pe-lg-0">
                      <ReactSelect placeholder={"Responsible agent"} />
                    </Col>
                    <Col xl={2} lg={3}>
                      <ReactSelect placeholder={"Event type"} />
                    </Col>
                  </Row>
                </div>
              </>
            )}
            renderRowActions={({ row, table }) => (
              <Menus
                itemheight={24}
                itemwidth={24}
                iconborderradius="4px"
                itemcontainerwidth="210px"
                itemcontainerheight="300px"
                component={
                  <>
                    <div className="light-font-text">
                      <div
                        className="addpropContent px-3 py-2 "
                        onClick={() => {
                          setOpenNewAppointmentScreen(true);
                          setTopLoadingProgressBar(25);
                        }}
                      >
                        <span className="ms-1">Create appointment</span>
                      </div>
                      <hr className=" m-0"></hr>
                      <div
                        className="addpropContent px-3 py-2 "
                        onClick={() => {
                          setCreatingNewContract(true);
                          setTopLoadingProgressBar(33);
                        }}
                      >
                        <span className="ms-1">Create contract</span>
                      </div>
                      <hr className="m-0"></hr>

                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => setShowStartScreening(true)}
                      >
                        <span className="ms-1">Start screening</span>
                      </div>
                      <hr className="m-0"></hr>
                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => handleCustomerDetails(2)}
                      >
                        <span className="ms-1">Open customer card</span>
                      </div>
                      <hr className="m-0"></hr>
                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => setShowMoveToAnotherUnits(true)}
                      >
                        <span className="ms-1">Move to another unit</span>
                      </div>
                      <hr className="m-0"></hr>
                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => handleCustomerDetails(0)}
                      >
                        <span className="ms-1">Go to customer space</span>
                      </div>
                      <hr className="m-0"></hr>
                      {row.original.ResponsibleAgent == "-" ? (
                        <div
                          className="addpropContent px-3 pt-2 pb-2 "
                          onClick={() =>
                            setShowPopUp({ ...showPopUp, create: true })
                          }
                        >
                          <span className="ms-1">Add responsible agent</span>
                        </div>
                      ) : (
                        <div
                          className="addpropContent px-3 pt-2 pb-2 "
                          onClick={() =>
                            setShowPopUp({ ...showPopUp, edit: true })
                          }
                        >
                          <span className="ms-1">Change responsible agent</span>
                        </div>
                      )}
                      <hr className="m-0"></hr>
                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => handleCustomerDetails(1)}
                      >
                        <span className="ms-1">Add a note</span>
                      </div>
                      <hr className="m-0"></hr>
                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => archiveScreening()}
                      >
                        <span className="ms-1">Archive</span>
                      </div>
                    </div>
                  </>
                }
              />
            )}
            muiTablePaperProps={{
              sx: {
                table: {
                  borderCollapse: "separate",
                  borderSpacing: "0 5px",
                },
                "& td:nth-last-child(1)": {
                  borderLeft: "1px solid #888888 !important",
                  backgroundColor: "#F0F0F0 !important",
                  boxShadow: "none !important",
                },
                "& td:nth-last-child(8)": {
                  backgroundColor: "#F0F0F0 !important",
                },
                "& td:nth-last-child(9)": {
                  backgroundColor: "#F0F0F0 !important",
                },
                "& td:nth-last-child(7)": {
                  backgroundColor: "#F0F0F0 !important",
                  boxShadow: "none !important",
                  borderRight: "1px solid #888888 !important",
                },
              },
            }}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "",
                size: 50,
              },
              "mrt-row-select": {
                // size: 50,
                muiTableBodyCellProps: {
                  sx: {
                    padding: "0px 0px 0px 16px !important",
                  },
                },
                muiTableHeadCellProps: {
                  sx: {
                    padding: "12px 0px 0px 16px !important",
                  },
                },
              },
            }}
            muiTableContainerProps={{
              sx: {
                padding: "0px !important",
              },
            }}
            muiTablePaginationProps={{
              ...paginationoptions,
            }}
            initialState={{
              showGlobalFilter: true,
              showColumnFilters: false,
              columnPinning: {
                left: ["mrt-row-select", "Id", "Name"],
                right: ["mrt-row-actions"],
              },
            }}
            positionGlobalFilter="right"
            positionToolbarDropZone="bottom"
            positionActionsColumn="last"
          />
        }
      />
      <CustomPopup
        open={confirmedSelectedInterest}
        onHide={() => setConfirmSelectedInterest(false)}
        title="Confirm all selected interest"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        titleTooltipText={"Confirm all selected interest"}
      >
        <div className="p-4">
          <div className="light-font-text">
            Are you sure you want confirm all the selected customers interest?
            By doing so you’re moving the customer from Attended to Interested.
          </div>
        </div>

        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setConfirmSelectedInterest(false)}
          />
          <ETButton variant={"primary"} buttonText="YES, CONFIRM" />
        </div>
      </CustomPopup>
      <ActionsPopup
        open={ArchiveScreen}
        onHide={() => setArchiveScreen(false)}
        whiteClick={() => setArchiveScreen(false)}
        title={"Archive Customer"}
        primaryButton={"Archive"}
        titleTooltipText={"Archive Customer"}
        firstText={"Do you want to archive this customer?"}
        secondText={"The customer will be archived and moved to Archive tab."}
      />
      <AddResponsibleAgent
        showResponsibleAgentPopup={showPopUp.create}
        setShowResponsibleAgentPopup={setShowPopUp}
        // leadId={leadId}
        // createUpdateLeadAgentMutate={createUpdateLeadAgentMutate}
        // createUpdateLeadAgentLoading={createUpdateLeadAgentLoading}
        data={agentDetails}
        // leadsByDivisionIdDataRefetch={leadsByDivisionIdDataRefetch}
      />
      <AddResponsibleAgent
        showResponsibleAgentPopup={showPopUp.edit}
        setShowResponsibleAgentPopup={setShowPopUp}
        usedFor="EDIT"
        data={agentDetails}
        // leadId={leadId}
        // createUpdateLeadAgentMutate={createUpdateLeadAgentMutate}
        // createUpdateLeadAgentLoading={createUpdateLeadAgentLoading}
        // leadsByDivisionIdDataRefetch={leadsByDivisionIdDataRefetch}
      />
      <RestartScreening
        open={showStartScreening}
        onHide={() => setShowStartScreening(false)}
        onCancel={() => setShowStartScreening(false)}
        usedFor="SSOC"
      />
      {/*-------------------------- move to another units ------------------------------*/}
      <MoveToAnotherUnit
        showMoveToAnotherUnits={showMoveToAnotherUnits}
        setShowMoveToAnotherUnits={setShowMoveToAnotherUnits}
      />
      {/*-------------------------- Create contract ------------------------------*/}
      <CreateNewContractWizard
        creatingNewContract={creatingNewContract}
        setCreatingNewContract={setCreatingNewContract}
        setTopLoadingBar={setTopLoadingProgressBar}
        topLoadingBar={topLoadingProgressBar}
      />
      <FollowUp
        setFollowUp={setShowFollowsUpWizard}
        followUp={showFollowsUpWizard}
        selectedIndex={selectedFollowUpIndex}
        setSelectedIndex={setSelectedFollowUpIndex}
      />
      {/*-------------------------- Create appointment popup ------------------------------*/}
      <CreateNewAppointments
        openNewAppointmentScreen={openNewAppointmentScreen}
        setOpenNewAppointmentScreen={setOpenNewAppointmentScreen}
        setProgress={setTopLoadingProgressBar}
        progress={topLoadingProgressBar}
      />
    </div>
  );
};

export default Attended;

const data = [
  {
    Customer: "Cai Coffey",
    Property: "Kaktus Towers",
    Unit: "Dyblosoro 3, 1 | 212-404",
    Registration: "12 Jul, 2023 at 10:32",
    EventType: "Private viewing",
    Event: "Private viewing - Carolinelunden",
    ResponsibleAgent: "-",
    customer_details: [
      {
        firstname: "Cai",
        lastname: "Coffey",
        email: "cai@millicent.in",
        phone_calling_code_id: "+45",
        mobilenumber: "",
        age: "45",
        street_name: "Dybbølsbro",
        house_number: "37",
        floor_no: "2",
        side_door: "B",
        country_id: "DK",
        country: "Denmark",
        zipcode_id: "2000",
        city: "Frederiksberg",
        registration_date: "2023-10-18 08:15:16",
        language_id: "en",
        preferred_languages: "English",
        gdpr_confirmation: 0,
      },
    ],
  },
  {
    Customer: "Jacqueline George",
    Property: "Blomsterbjerget",
    Unit: "Dyblosoro 3, 1 | 212-404",
    Registration: "12 Jul, 2023 at 10:32",
    EventType: "Public event",
    Event: "Public event - Open House ",
    ResponsibleAgent: "Allan schmith",
    customer_details: [
      {
        firstname: "Jacqueline",
        lastname: "George",
        email: "george@millicent.in",
        phone_calling_code_id: "+45",
        mobilenumber: "",
        age: "35",
        street_name: "Toldbodgade",
        house_number: "37",
        floor_no: "2",
        side_door: "B",
        country_id: "DK",
        country: "Denmark",
        zipcode_id: "2000",
        city: "Frederiksberg",
        registration_date: "2023-10-18 08:15:16",
        language_id: "en",
        preferred_languages: "English",
        gdpr_confirmation: 0,
      },
    ],
  },
  {
    Customer: "Solomon Welch",
    Property: "Carolinelunden",
    Unit: "Dyblosoro 3, 1 | 212-404",
    Registration: "12 Jul, 2023 at 10:32",
    EventType: "Private event",
    Event: "Privtae viewing - Kaktus Towers",
    ResponsibleAgent: "Claus petersen",
    customer_details: [
      {
        firstname: "Solomon",
        lastname: "Welch",
        email: "solomon@millicent.in",
        phone_calling_code_id: "+45",
        mobilenumber: "",
        age: "29",
        street_name: "Toldbodgade",
        house_number: "37",
        floor_no: "2",
        side_door: "B",
        country_id: "DK",
        country: "Denmark",
        zipcode_id: "2000",
        city: "Frederiksberg",
        registration_date: "2023-10-18 08:15:16",
        language_id: "en",
        preferred_languages: "English",
        gdpr_confirmation: 0,
      },
    ],
  },
];
