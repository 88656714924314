import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import StatusChange from "../controlCenter/economy/StatusChange";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import { GreenDiv ,H2, H3} from "../../styles/Common.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Line, Pie } from "react-chartjs-2";
import { MaterialReactTable } from "material-react-table";

import MRTTheme from "../../Components/table/MRTTheme";
import { SortIcon, paginationoptions } from "../../Components/table/MRTGlobalProps";
import { NoRecordsToDisplay } from "../../utils/validationConstants";
import NoDataScreen from "../../Components/DisplayNoData/NoDataScreen";

const Status = () => {
  const [user, setUser] = useState(false);

  const data = {
    labels: [
      "Oct",
      "Nov",
      "Dec",
      "jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
    ],
    //  myData.map((x) =>
    //   value == 1
    //     ? x.YearMonth
    //     : new Date(x.YearMonth).toLocaleDateString("en-En", {
    //         year: "numeric",
    //         month: "short",
    //       })
    // ),

    datasets: [
      // {
      //   label: "Available",
      //   fill: false,
      //   borderColor: "#77C7A1",
      //   fontfamily: "Cerebri sans-bold",
      //   backgroundColor: "#77C7A1",
      //   tension: 0.4,
      //   data: [0, 0, 0, 0, 0, 0, 130000, 1150000, 3000000, 1110, 6000000],
      //   unitStatus: [114, 558, 24, 88],
      // },

      {
        label: "Economy",
        fill: false,
        borderColor: "#A2C8D0",
        backgroundColor: "#A2C8D0",

        tension: 0.4,
        data: [
          1000, 1110, 1500, 1600, 1550, 1060, 1360, 2220, 600, 4500, 2220, 4000,
        ],
        unitStatus: [0, 0, 0, 0],
      },
      {
        label: "Percentage",
        fill: false,

        borderColor: "#F6E651",
        backgroundColor: "#F6E651",
        tension: 0.4,
        data: [
          4000, 2220, 4550, 600, 2220, 1360, 1000, 1500, 1700, 1500, 1110, 1000,
        ],
        unitStatus: [0, 0, 0, 0],
      },
    ],
  };
  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.style.background = "#fff";
      tooltipEl.style.borderRadius = "3px";
      tooltipEl.style.border = "1px solid #F4F5F5";
      tooltipEl.style.color = "black";
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";
      tooltipEl.style.zIndex = "10";

      const table = document.createElement("table");
      table.style.margin = "0px 10px";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      // console.log(tooltip.body,"body");
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map(
        (b) => `${b.before}, ${b.lines}, ${b.after}`
      );
      //  console.log(bodyLines,"bodylines");
      const tableHead = document.createElement("thead");

      titleLines.forEach((title) => {
        const tr = document.createElement("tr");
        tr.style.borderWidth = 0;

        const th = document.createElement("th");
        th.style.borderWidth = 0;
        const text = document.createTextNode(title);

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement("tbody");
      bodyLines.forEach((body, i) => {
        const colors = tooltip.labelColors[i];
        //  console.log(body.split(","),i,"tablebody")
        const span = document.createElement("span");
        // span.style.background = tr;
        // span.style.borderColor = colors.borderColor;
        span.style.borderWidth = "2px";
        span.style.marginRight = "10px";
        span.style.height = "10px";
        span.style.width = "10px";
        span.style.fontSize = "13px";
        span.style.display = "block";

        const tr = document.createElement("tr");
        tr.style.backgroundColor = "inherit";
        tr.style.borderWidth = 0;
        tr.style.fontSize = "13px";

        const tr2 = document.createElement("tr");
        tr2.style.backgroundColor = "inherit";
        tr2.style.borderWidth = 0;
        tr2.style.fontSize = "13px";
        tr2.style.fontFamily = " Cerebri sans-light";

        const tr3 = document.createElement("tr");
        tr3.style.backgroundColor = "inherit";
        tr3.style.borderWidth = 0;
        tr3.style.fontSize = "13px";
        tr3.style.color = "#888888";
        tr3.style.fontFamily = " Cerebri sans-light";

        const tr4 = document.createElement("tr");
        tr2.style.fontSize = "13px";

        const td = document.createElement("td");
        const br1 = document.createElement("br");
        const br2 = document.createElement("br");

        const allBody = body.split(",");
        const text1 = document.createTextNode(allBody[0]);
        // text1.style.fontSize="13px";
        const text2 = document.createTextNode(allBody[1]);
        const text3 = document.createTextNode(allBody[2]);
        const text4 = document.createTextNode("");

        td.appendChild(span);
        tr.appendChild(text1);
        // tr.appendChild(br1);
        tr2.appendChild(text2);
        // tr.appendChild(br2);
        tr3.appendChild(text3);
        tr4.appendChild(text4);

        tableBody.appendChild(td);
        tableBody.appendChild(tr);
        tableBody.appendChild(tr2);
        tableBody.appendChild(tr3);
        tableBody.appendChild(tr4);
      });

      const tableRoot = tooltipEl.querySelector("table");

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };
  const options = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
        position: "average",
        external: externalTooltipHandler,
        callbacks: {
          title: function (tooltipItem, index) {
            let title = "";
            // title.push(tooltipItem.dataset.label);
            // console.log(tooltipItem[0].dataset.label,tooltipItem[1].dataset.label,tooltipItem[2].dataset.label,tooltipItem[3].dataset.label, "title")
            return title;
          },
          beforeLabel: function (tooltipItem, index) {
            let beforlabel = [];
            beforlabel.push(tooltipItem.dataset.label);
            let myhome = beforlabel[0];
            // console.log(beforlabel[0]);
            // tooltipItem[0].dataset.label
            // console.log(tooltipItem, "title");
            // console.log(tooltipItem.dataset.label, "title");
            return myhome;
          },
          label: function (context) {
            let lable = "";
            if (context.parsed.y !== null) {
              lable +=
                new Intl.NumberFormat("en-DK").format(context.parsed.y) +
                " DKK";
            }
            return lable;
          },
          afterLabel: function (tooltipItem, context) {
            let unit = tooltipItem.dataset.unitStatus[tooltipItem.dataIndex];
            let unitTitle = tooltipItem.dataset.label;
            //  console.log(tooltipItem.dataIndex);
            //  console.log(tooltipItem);
            // console.log(tooltipItem.dataset.unitStatus[tooltipItem.dataIndex]);
            return unit + " units " + unitTitle;
          },
        },
        // backgroundColor: "#FFFFFF",
        // borderDash: [2, 2],
        // titleColor: "#000000",
        // bodyColor: "#000000",
        // padding: 20,
        // cornerRadius: 8,
        // borderColor: "#F4F5F5",
        // borderWidth: 1,
        // bodySpacing:5,
        // displayColors:false,
        // titleSpacing:20,
      },
      legend: {
        display: true,
        position: "bottom",
        align: "center",

        labels: {
          boxWidth: 8,
          boxHeight: 8,
          padding: 20,
          color: "#00000",
          font: { family: "Cerebri sans-medium", size: "12px" },
        },
      },
    },
    scales: {
      x: {
        grid: {
          color: "rgba(0,0,0,0.0)",
        },
        ticks: {
          fontSize: 18,
          padding: 0,
          color: "#888888",
          font: {
            family: "Cerebri sans-light",
          },
        },
      },

      // x: [
      //   {
      //     grid: {
      //       color: "rgba(0,0,0,0.0)",
      //     },
      //     ticks: {
      //       fontSize: 18,
      //       padding: 0,
      //       color: "#888888",
      //       font: {
      //         family: "Cerebri sans-light",
      //       },
      //     },
      //   },
      //   {
      //     position: "right", // Position the new x-axis on top
      //     grid: {
      //       color: "rgba(0,0,0,0.0)",
      //     },
      //     ticks: {
      //       fontSize: 18,
      //       padding: 0,
      //       color: "#888888",
      //       font: {
      //         family: "Cerebri sans-light",
      //       },
      //     },
      //   },
      // ],
      y: {
        grid: {
          color: "rgba(0,0,0,0.0375)",
          fontColor: "#fff",
        },
        ticks: {
          color: "#888888",
          font: {
            family: "Cerebri sans-light",
          },
        },
      },
    },
  };

  const columns = useMemo(() => [
    {
      header: "Status",
      enableHiding: false,
      enableGlobalFilter: false,
      // Footer: () => (
      //   <div>
      //     {" "}
      //     <span className="form-label-title ps-4 fs-13 d-flex justify-content-start">
      //       {" "}
      //       Total{" "}
      //     </span>
      //   </div>
      // ),
      size: 230,
      Cell: ({ row, header }) => (
        <>
          <div className="d-flex">
            <div
              className="radius-4 me-3"
              style={{
                backgroundColor: row.original.color,
                width: "13px",
                height: "13px",
                marginTop: "2px",
              }}
            ></div>
            <div>{row.original.status}</div>
          </div>
        </>
      ),
      // center align the header and body cells
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "Quantity",
      header: (
        <div>
          <div>Last period</div>
        </div>
      ),
      // Footer: () => (
      //   <span className="form-label-title d-flex justify-content-center  fs-13">
      //     137
      //   </span>
      // ),
      size: 20,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "Percentage",
      header: (
        <div>
          <div>Percentage</div>
        </div>
      ),
      // Footer: () => (
      //   <span className="d-flex justify-content-center form-label-title fs-13">
      //     100 %
      //   </span>
      // ),
      size: 20,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "Yearlyrent",
      header: (
        <div>
          <div>Year rent</div>
        </div>
      ),
      // Footer: () => (
      //   <span className="form-label-title fs-13 d-flex justify-content-center">
      //     15.131.650 DKK
      //   </span>
      // ),

      size: 20,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
  ]);

  const pieData = {
    labels: ["Udeljet:3.619", "Ledig :465", "Reserveret:71", "Skjult:1,061"],
    datasets: [
      {
        data: [12, 3, 17, 78],
        backgroundColor: ["#77C7A1", "#F6E651", "#888888", "#A2C8D0"],
        borderColor: "transparent",
      },
    ],
  };
  const pieOptions = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        yAlign: "top",
        backgroundColor: "#FFFFFF",
        borderWidth: 2,
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
      },
      legend: {
        display: false,
      },
    },
  };

  const plugins = [
    {
      afterDraw: function (chart) {
        if (chart.data.datasets[0].data.every((item) => item === 0)) {
          let ctx = chart.ctx;
          let width = chart.width;
          let height = chart.height;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "25px Cerebri sans";
          ctx.fillText("No data to display", width / 2, height / 2);
          ctx.restore();
        }
      },
    },
  ];
  return (
    <div className="p-4">
      <Row className="mb-3">
        <Col lg={4} sm={6} className="pe-2">
          <div className=" bgcolor-d-white radius-8 p-4 d-flex flex-column align-items-center justify-content-center lite-grey-border ">
            {" "}
            <div className="actors-section-title fs-24">0 units</div>
            <div className="font-light fs-18">Idleness</div>
          </div>
        </Col>{" "}
        <Col lg={4} sm={6} className="px-2">
          <div className=" bgcolor-d-white radius-8 p-4 d-flex flex-column align-items-center justify-content-center lite-grey-border ">
            {" "}
            <div className="actors-section-title fs-24">0 reservations</div>
            <div className="font-light fs-18">Ongoing</div>
          </div>
        </Col>{" "}
        <Col lg={4} sm={6} className="ps-2">
          <div className=" bgcolor-d-white radius-8 p-4 d-flex flex-column align-items-center justify-content-center lite-grey-border ">
            {" "}
            <div className="actors-section-title fs-24 ">0 leads</div>
            <div className="font-light fs-18">Unprocessed</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={4} sm={6} className="pe-2">
          {" "}
          <div className=" bgcolor-d-white radius-8 p-4 d-flex flex-column align-items-center justify-content-center lite-grey-border ">
            {" "}
            <div className="actors-section-title fs-24">0 messages</div>
            <div className="font-light fs-18">Unanswered</div>
          </div>
        </Col>
        <Col lg={4} sm={6} className="px-2">
          {" "}
          <div className=" bgcolor-d-white radius-8 p-4 d-flex flex-column align-items-center justify-content-center lite-grey-border ">
            {" "}
            <div className="actors-section-title fs-24">0 payments</div>
            <div className="font-light fs-18">Outstanding</div>
          </div>
        </Col>
        <Col lg={4} sm={6} className="ps-2">
          {" "}
          <div className=" bgcolor-d-white radius-8 p-4 d-flex flex-column align-items-center justify-content-center lite-grey-border ">
            {" "}
            <div className="actors-section-title fs-24">0 task</div>
            <div className="font-light fs-18">Ongoing</div>
          </div>
        </Col>
      </Row>

      <div className="bgcolor-d-white radius-8 mt-3 p-4 lite-grey-border">
        <div className=" d-flex align-items-center bgcolor-d-white  fs-14 ">
          <H3 className="me-2 ">Status</H3>
          <CustomToolTip  text="Status" />
        </div>
        <div className="created-by fs-13">
          Lorem ipsum dolor sit lorem ipsum dolor sit
        </div>
        <div className="">
          <Row>
            <Col className="" lg={4}>
              <div
                style={{ background: "#F9FAFA" }}
                className="radius-8 d-flex align-items-center justify-content-center"
              >
                {pieData?.length == 0 ? (
                  <div className="mt-5">
                    <div style={{ height: "135px" }}>
                      <Pie
                        data={pieData}
                        options={pieOptions}
                        plugins={plugins}
                      />
                    </div>
                    <div className="mt-4 d-flex justify-content-center align-items-center">
                      <div className="d-flex justify-content-center gap-4">
                        {" "}
                        <div className="">
                          <div className="fs-13 light-font-text">
                            Total units
                          </div>
                          <div className="table-property-name fs-16">0</div>
                        </div>
                        <div className="">
                          <div className=" fs-13 light-font-text">
                            Total yearly rent
                          </div>
                          <div className="table-property-name  fs-16">
                            0 DKK
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <div className="d-flex flex-column">
                        {" "}
                        <div className="d-flex gap-2 align-items-center ">
                          <div
                            style={{
                              background: "#888888",
                              height: "12px",
                              width: "12px",
                              borderRadius: "2px",
                            }}
                          ></div>
                          <div style={{ paddingTop: "1px" }}>Not active</div>
                        </div>
                        <div className="d-flex gap-2 align-items-center ">
                          <div
                            style={{
                              background: "#77C7A1",
                              height: "12px",
                              width: "12px",
                              borderRadius: "2px",
                            }}
                          ></div>
                          <div style={{ paddingTop: "1px" }}>Active</div>
                        </div>
                        <div className="d-flex gap-2 align-items-center ">
                          <div
                            style={{
                              background: "#F6E651",
                              height: "12px",
                              width: "12px",
                              borderRadius: "2px",
                            }}
                          ></div>
                          <div style={{ paddingTop: "1px" }}>Reserved</div>
                        </div>
                        <div className="d-flex gap-2 align-items-center ">
                          <div
                            style={{
                              background: "#BF87D2",
                              height: "12px",
                              width: "12px",
                              borderRadius: "2px",
                            }}
                          ></div>
                          <div style={{ paddingTop: "1px" }}>In process</div>
                        </div>
                        <div className="d-flex gap-2 align-items-center ">
                          <div
                            style={{
                              background: "#F1664B",
                              height: "12px",
                              width: "12px",
                              borderRadius: "2px",
                            }}
                          ></div>
                          <div style={{ paddingTop: "1px" }}>Completed</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <NoDataScreen height={"377px"} imgHeight={"150px"} />
                )}
              </div>
            </Col>
            <Col lg={8}>
              <MRTTheme
                colorVariantsForTableData={["odd"]}
                table={
                  <MaterialReactTable
                    columns={columns}
                    data={[]}
                    icons={{...SortIcon}}
                    localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                    enableTopToolbar={false}
                    enableBottomToolbar={false}
                    enableSelectAll={false}
                    enableColumnActions={false}
                    enableColumnFilter={false}
                    enableResizing={false}
                    initialState={{ showGlobalFilter: true }}
                    enablePagination={false}
                    muiTableBodyProps={{
                      sx: {
                        //stripe the rows, make odd rows a darker color
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: "#f0f0f0 !important",
                        },
                      },
                    }}
                    // muiTablePaginationProps={{
                    //     ...paginationoptions,
                    //   }}
                    muiTablePaperProps={{
                      elevation: 0, //change the mui box shadow
                      //customize paper styles
                      sx: {
                        background: "#f9fafa",
                        th: {
                          background:
                            window.location.pathname == "#f9fafa !important",
                        },
                        tr: {
                          background: "#f9fafa !important",
                        },
                      },
                    }}
                    muiTableContainerProps={{
                      sx: {
                        padding: "10px 0px 0px 0px",
                      },
                    }}
                    // muiTableHeadRowProps={{
                    //   sx: { boxShadow: "none" },
                    // }}
                    enableColumnFilterModes={false}
                  />
                }
              />
            </Col>
          </Row>
        </div>
      </div>

      <div className="bgcolor-d-white radius-8 mt-3 p-4 lite-grey-border">
        <div className=" d-flex align-items-center bgcolor-d-white  fs-14 ">
          <H3 className="me-2 ">Idleness</H3>
          <CustomToolTip  text="Idleness" />
        </div>
        <div className="created-by fs-13">
          Lorem ipsum dolor sit lorem ipsum dolor sit
        </div>
        <Row className="mt-4">
          <Col lg={4} className="pe-lg-0">
            <div
              style={{
                background: "#ffffff",
                height: "289px",
                borderBottomLeftRadius: "8px",
                borderTopLeftRadius: "8px",
                border: "1px solid #f0f0f0",
                borderRight: "none",
              }}
              className="p-3 d-flex align-items-center justify-content-center "
            >
              <div className="d-flex justify-content-center align-items-center">
                <div>
                  <div className="form-label-title ps-2">
                    Current idleness rate
                  </div>
                  <div className="d-flex justify-content-center align-items-center gap-4">
                    <H3
                      
                      style={{ fontSize: "75px" }}
                    >
                      0%
                    </H3>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "17px",
                        background: "#DFF0E8",
                        padding: "3px 20px",
                        color: "#77C7A1",
                        borderRadius: "4px",
                      }}
                      className="form-label-title"
                    >
                      <FontAwesomeIcon
                        icon="fa-regular fa-arrow-up"
                        className="me-1"
                      />
                      0
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={8} className="ps-lg-0">
            <div
              className="p-4   "
              style={{
                height: "289px",
                background: "#ffffff",
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                border: "1px solid #f0f0f0",
                borderLeft: "none",
              }}
            >
              {/* <Line data={data} options={options} /> */}
              <div className="pt-3">
                <NoDataScreen height={"200px"} imgHeight={"150px"} />
              </div>
            </div>{" "}
          </Col>
        </Row>
      </div>

      <Row>
        <div className=" mt-3">
          <StatusChange />
        </div>
      </Row>
    </div>
  );
};

export default Status;

const tableData = [
  {
    status: "Archived",
    Quantity: "50",
    Percentage: "34%",
    Yearlyrent: "5.522.500 DKK",
    color: "#888888",
  },
  {
    status: "Not listed",
    Quantity: "6",
    Percentage: "4%",
    Yearlyrent: "662.700 DKK",
    color: "#888888",
  },
  {
    status: "In idleness",
    Quantity: "4",
    Percentage: "3%",
    Yearlyrent: "441.800 DKK",
    color: "#77C7A1",
  },
  {
    status: "Not in idleness",
    Quantity: "4",
    Percentage: "3%",
    Yearlyrent: "331.350 DKK",
    color: "#77C7A1",
  },
  {
    status: "Ready for sale",
    Quantity: "3",
    Percentage: "2%",
    Yearlyrent: "1.104.500 DKK",
    color: "#77C7A1",
  },
  {
    status: "Under termination",
    Quantity: "10",
    Percentage: "7%",
    Yearlyrent: "5.522.500 DKK",
    color: "#77C7A1",
  },
  {
    status: "Reserved",
    Quantity: "60",
    Percentage: "41%",
    Yearlyrent: "6.627.000 DKK",
    color: "#F6E651",
  },
  {
    status: "Ongoing screening",
    Quantity: "10",
    Percentage: "7%",
    Yearlyrent: "1.104.500 DKK",

    color: "#F6E651",
  },
  {
    status: "Pending approval",
    Quantity: "3",
    Percentage: "2%",
    Yearlyrent: "331.350 DKK",

    color: "#F6E651",
  },
  {
    status: "Contract in process",
    Quantity: "10",
    Percentage: "7%",
    Yearlyrent: "6.627.000 DKK",

    color: "#BF87D2",
  },
  {
    status: "Contract waiting for QA",
    Quantity: "60",
    Percentage: "41%",
    Yearlyrent: "1.104.500 DKK",

    color: "#BF87D2",
  },
  {
    status: "Contract waiting for tenant",
    Quantity: "10",
    Percentage: "7%",
    Yearlyrent: "331.350 DKK",

    color: "#BF87D2",
  },
  {
    status: "Contract waiting for landlord",
    Quantity: "3",
    Percentage: "2%",
    Yearlyrent: "1.104.500 DKK",

    color: "#BF87D2",
  },
  {
    status: "Contract waiting for release",
    Quantity: "10",
    Percentage: "41%",
    Yearlyrent: "6.627.000 DKK",

    color: "#BF87D2",
  },
  {
    status: "Pending initial payment",
    Quantity: "60",
    Percentage: "7%",
    Yearlyrent: "1.104.500 DKK",

    color: "#BF87D2",
  },
  {
    status: "Pending move-in",
    Quantity: "10",
    Percentage: "2%",
    Yearlyrent: "1.104.500 DKK",

    color: "#F1664B",
  },
  {
    status: "Leased",
    Quantity: "60",
    Percentage: "7%",
    Yearlyrent: "6.627.000 DKK",

    color: "#F1664B",
  },
];
