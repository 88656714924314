// Created by --> Suraj
import React, { useEffect } from "react";
import { useState } from "react";
import DropdownModalMui from "../DropdownModal/DropdownModalMui";
import { Col, Row } from "react-bootstrap";
import CustomCheckBox from "../Checkbox/Checkbox";
import { useGetLanguages } from "../../hooks/queries/uselanguageQueries";
import { useScreening } from "../../hooks/stores/useScreeningStore";

import { useGetScreeningFormBasicDetails } from "../../hooks/queries/useScreeningQueries";
import { usePropertyId } from "../../hooks/stores/usePropertyStore";
import { useUserDetails } from "../../hooks/stores/useUserDetailsStore";

const LanguagesSelection = ({ onChange }) => {
  const {
    selectedLanguageId,
    setSelectedLanguageId,
    selectedLanguageText,
    setSelectedLanguageText,
    saveScreeningForm,
    EditscreeningForm,
  } = useScreening((state) => state);
  //-------------------Zustand---------------//
  const { userDetails } = useUserDetails((state) => state);
  const { propertyId } = usePropertyId((state) => state);
  // console.log("🚀 ~ file: LanguagesSelection.js:21 ~ LanguagesSelection ~ EditscreeningForm:", EditscreeningForm)
  // console.log("🚀 ~ file: LanguagesSelection.js:17 ~ LanguagesSelection ~ selectedLanguageId:", selectedLanguageId)

  const { data: getlanguages } = useGetLanguages();

  const { data: GetScreeningFormBasicDetailsData } =
    useGetScreeningFormBasicDetails(propertyId);
  const [categoryData, setCategoryData] = useState(
    GetScreeningFormBasicDetailsData?.data?.[0]?.category
  );

  useEffect(() => {
    setCategoryData(GetScreeningFormBasicDetailsData?.data?.[0]?.category);
  }, [GetScreeningFormBasicDetailsData]);
  const primaryLanguage = {
    Id: userDetails?.language_id,
    Text: userDetails?.language_name,
  };
  // to pre-select the default language
  // const [primaryLng, setPrimaryLang] = useState([])
  // const [primaryLangId, setPrimaryLangId] = useState([])
  // console.log("🚀 ~ file: LanguagesSelection.js:28 ~ LanguagesSelection ~ promaryLangId:", primaryLangId, primaryLng, saveScreeningForm)
  useEffect(() => {
    // setPrimaryLang(primaryLanguage?.Text)
    // setPrimaryLangId(primaryLanguage?.Id)
    if (saveScreeningForm) {
      setSelectedLanguageText([primaryLanguage?.Text]);
      setSelectedLanguageId([primaryLanguage?.Id]);
    }
  }, [userDetails]);
  // useEffect(() => {
  // 	const categoryLanguageIds = new Set(
  // 		categoryData?.flatMap((data) =>
  // 			data?.category_name?.map((category) => category?.language_id)
  // 		)
  // 	);
  // 	const uniqueLanguageIds = Array.from(categoryLanguageIds);
  // 	if (EditscreeningForm) {
  // 		setSelectedLanguageId([uniqueLanguageIds])
  // 		// setSelectedLanguageText([primaryLanguage?.Text])
  // 	}
  // }, [userDetails])

  useEffect(() => {
    const indexOfPrimaryLanguageText = selectedLanguageText?.indexOf(
      primaryLanguage?.Text
    );
    const indexOfPrimaryLanguageId = selectedLanguageId?.indexOf(
      primaryLanguage?.Id
    );

    if (indexOfPrimaryLanguageText === -1) {
      setSelectedLanguageText([primaryLanguage?.Text, ...selectedLanguageText]);
    } else if (indexOfPrimaryLanguageText !== 0) {
      selectedLanguageText.splice(primaryLanguage?.Text, 1); // Remove from its current position
      selectedLanguageText.unshift(primaryLanguage?.Text); // Add at the 0th index
      setSelectedLanguageText(selectedLanguageText); // Update the state
    }
    if (indexOfPrimaryLanguageId === -1) {
      // setSelectedLanguageId([primaryLanguage?.Id, ...selectedLanguageId]);
      setSelectedLanguageId([primaryLanguage?.Id]);
    } else if (indexOfPrimaryLanguageId !== 0) {
      selectedLanguageId?.splice(primaryLanguage?.Id, 1);
      selectedLanguageId?.unshift(primaryLanguage?.Id);
      setSelectedLanguageId(selectedLanguageId);
    }
  }, [userDetails]);

  useEffect(() => {
    const primaryLanguageIndex = getlanguages?.data?.findIndex(
      (data) => data.Id == primaryLanguage?.Id
    );

    if (primaryLanguageIndex !== -1) {
      const primaryLanguageShiftData = getlanguages?.data?.splice(
        primaryLanguageIndex,
        1
      )[0];
      getlanguages?.data?.unshift(primaryLanguageShiftData);
    }
  }, [primaryLanguage]);
  //  Used for all language  functionality.
  const handleCheckAllLanguages = (e) => {
    if (e.target.checked) {
      const allLanguages = getlanguages?.data?.map((item) => item?.Text);
      const allLanguageId = getlanguages?.data?.map((item) => item?.Id);

      setSelectedLanguageId(allLanguageId);
      setSelectedLanguageText(allLanguages);
    } else {
      setSelectedLanguageText([]);
      setSelectedLanguageId([]);
    }
  };

  //  Used for single language  functionality.
  const handleCheckedLanguage = (e, data) => {
    if (e.target.checked) {
      setSelectedLanguageId([...selectedLanguageId, data?.Id]);
      setSelectedLanguageText([...selectedLanguageText, data?.Text]);
    } else {
      setSelectedLanguageId(
        selectedLanguageId?.filter((item) => item !== data?.Id)
      );
      setSelectedLanguageText(
        selectedLanguageText?.filter((item) => item !== data?.Text)
      );
    }
  };
  // console.log("selectedLanguageId", selectedLanguageId);

  return (
    <div style={{ width: "250px", cursor: "pointer", position: "relative" }}>
      {" "}
      {selectedLanguageText?.length !== 0 && (
        <div
          className="fs-8 px-1"
          style={{
            position: "absolute",
            top: "-6px",
            backgroundColor: "#FFF",
            color: "#888",
            left: "8px",
          }}
        >
          Languages
        </div>
      )}
      <DropdownModalMui
        text={
          // managing inner field placeholder
          selectedLanguageText?.length !== 0 ? (
            <>
              {selectedLanguageText?.map(
                (item, index) =>
                  index < 3 && (
                    <>
                      <span className="py-1 " style={{ paddingLeft: "2px" }}>
                        {item}
                        {index < selectedLanguageText?.length - 1 && (
                          <span>,</span>
                        )}{" "}
                      </span>
                    </>
                  )
              )}
              {selectedLanguageText?.length > 3 && (
                <span className="py-1" style={{ paddingLeft: "2px" }}>
                  {" "}
                  ...
                </span>
              )}
            </>
          ) : (
            <div className="light-font-text ps-1">Languages</div>
          )
        }
        component={
          <>
            <div style={{ minWidth: "250px" }}>
              <Row>
                <Col className="pb-2 pt-2 pe-2">
                  <div className="border-bottom px-2 pb-2">
                    <Row style={{ alignItems: "center" }}>
                      <Col className="light-font-text">
                        <div className="d-flex align-items-center">
                          <CustomCheckBox
                            onChange={(e) => {
                              handleCheckAllLanguages(e);
                            }}
                            id={"handleCheckAllDivision"}
                            checked={
                              selectedLanguageText?.length ===
                              getlanguages?.data?.length
                            }
                          />{" "}
                          All languages
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="scrollable-container-y px-1 pt-2"
                    style={{ height: "115px" }}
                  >
                    {getlanguages?.data?.map((data, i) => (
                      <Row
                        key={i}
                        className={`${
                          i == getlanguages.data.length - 1
                            ? ""
                            : "border-bottom"
                        } align-items-center`}
                      >
                        <Col>
                          <div className="d-flex align-items-center px-4">
                            <div>
                              <CustomCheckBox
                                id={data?.id}
                                checked={selectedLanguageId?.includes(data?.Id)}
                                onChange={(e) => {
                                  handleCheckedLanguage(e, data);
                                }}
                              />
                            </div>
                            <div>
                              <div className="light-font-text d-flex align-items-center gap-2">
                                <img
                                  src={data?.DataValue1}
                                  width={"18px"}
                                  height={"12px"}
                                />{" "}
                                {data?.Text}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Col>
              </Row>
            </div>
          </>
        }
      />
    </div>
  );
};

export default LanguagesSelection;

export const languageList = [
  {
    id: "da",
    Text: "Danish",
    DataValue1: "https://millicent-test.b-cdn.net/assets/flags/dk.svg",
  },
  {
    id: "en",
    Text: "English",
    DataValue1: "https://millicent-test.b-cdn.net/assets/flags/gb.svg",
  },
  {
    id: "sv",
    Text: "Swedish",
    DataValue1: "https://millicent-test.b-cdn.net/assets/flags/se.svg",
  },
];
