// Fields placement and logic change ==> last updated by-->Suraj
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { IconField } from "../../../../Components/GroupText/IconTextField";
import NormalGroupText from "../../../../Components/GroupText/NormalGroupText";
import GroupText from "../../../../Components/GroupText/GroupText";
import MRTTheme from "../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";

import ETButton from "../../../../Components/Buttons/ETButton";
import {
  useUnitIdToEdit,
  useUnitType,
} from "../../../../hooks/stores/useUnitStore";
import {
  useGetStorageRoomById,
  useGetUnitTextField,
  useGetEditUnitsTextField,
  useEditStorageRoom,
  useGetParkingLotById,
  useEditParkingLot,
  useGetStorageRoomDetails,
  useGetParkingLotDetails,
  useGetStorageAndParkingHistory,
} from "../../../../hooks/queries/useUnitQueries";
import { useForm, Controller } from "react-hook-form";
import { usePropertyId } from "../../../../hooks/stores/usePropertyStore";
import { toast } from "react-toastify";
import { NoRecordsToDisplay } from "../../../../utils/validationConstants";
import { SortIcon } from "../../../../Components/table/MRTGlobalProps";

const EditParkingLot = ({ setShowEditParking }) => {
  const [paymentFrequency, setPaymentFrequency] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [historyData, setHistoryData] = useState([]);
  const { typeOfUnit, setTypeOfUnit } = useUnitType((state) => state);
  const { unitToEdit, setUnitToEdit } = useUnitIdToEdit((state) => state);
  const { propertyId } = usePropertyId((state) => state);
  const {
    data: GetStorageRoomByIdData,
    refetch: GetStorageRoomByIdDataRefetch,
  } = useGetStorageRoomById(unitToEdit);

  const { data: GetParkingLotByIdData, refetch: GetParkingLotByIdDataRefetch } =
    useGetParkingLotById(unitToEdit);

  const { data: GetUnitTextFieldData } = useGetEditUnitsTextField(
    propertyId,
    typeOfUnit
  );
  const { refetch: GetStorageRoomDetailsDataRefetch } =
    useGetStorageRoomDetails(propertyId, 0);
  const { mutateAsync: EditStorageRoomMutate, isLoading } =
    useEditStorageRoom();
  const { mutateAsync: EditParkingLotMutate } = useEditParkingLot();
  const { refetch: GetParkingLotDetailsDataRefetch } = useGetParkingLotDetails(
    propertyId,
    0
  );
  const { data: GetStorageAndParkingHistoryData } =
    useGetStorageAndParkingHistory(unitToEdit, typeOfUnit); //TODO: test for parking and storage when actual data is available

  // type field

  useEffect(() => {
    setHistoryData(GetStorageAndParkingHistoryData?.data);
  }, [GetStorageAndParkingHistoryData]);

  const typeField = GetUnitTextFieldData?.data?.filter(
    (textField) => textField.column_name === "type_id"
  );

  const typeOptions = typeField[0]?.possible_values;
  const handleGetLabel = (id) => {
    let type = typeOptions?.filter((ele) => ele.Id == id);
    return type[0]?.Text;
  };
  // decimal field
  const dimensionField = GetUnitTextFieldData?.data?.filter(
    (ele) => ele.column_name === "uom_id"
  );
  const dimensionOptions = dimensionField[0]?.possible_values;

  const handleUnitChange = (selectedOption) => {
    setSelectedUnit(selectedOption.DataValue1);
  };

  // const filterItem = (curcat) => {
  // 	const newItem = dimensionOptions.filter((newVal) => {
  // 		return newVal.DataValue1 == curcat;
  // 		// comparing category for displaying data
  // 	});

  const handleGetDimensionLabel = (id) => {
    let dimension = dimensionOptions?.filter((ele) => ele.Id == id);
    return dimension[0]?.Text;
  };

  const handleToUpperCase = (text) => {
    let upperText = text?.toLowerCase();
    return upperText;
  };
  // cubic field

  const cubicField = GetUnitTextFieldData?.data?.filter(
    (ele) => ele.column_name === "cubic_uom_id"
  );
  const cubicOptions = cubicField[0]?.possible_values;

  const handleGetCubicLabel = (id) => {
    let CubicLabel = cubicOptions?.filter((ele) => ele.Id == id);
    return CubicLabel[0]?.Text;
  };
  const [selectedCubic, setSelectedCubic] = useState("");

  const handleCubicChange = (selectedCubic) => {
    setSelectedCubic(selectedCubic.DataValue2);
  };
  // notice_period_id
  const noticeField = GetUnitTextFieldData?.data?.filter(
    (ele) => ele.column_name === "notice_period_id"
  );
  const noticeOptions = noticeField[0]?.possible_values;
  const handleGetNoticePeriodLabel = (id) => {
    let noticeLabel = noticeOptions?.filter((ele) => ele.Id == id);
    return noticeLabel[0]?.Text;
  };
  // status_id
  const statusField = GetUnitTextFieldData?.data?.filter(
    (ele) => ele.column_name === "status_id"
  );
  const statusOptions = statusField[0]?.possible_values;
  const handleGetStatusLabel = (id) => {
    let statusLabel = statusOptions?.filter((ele) => ele.Id == id);
    return statusLabel[0]?.Text;
  };
  const paymentTypeField = GetUnitTextFieldData?.data?.filter(
    (ele) => ele.column_name === "payment_type_id"
  );
  const paymentTypeOptions = paymentTypeField[0]?.possible_values;

  const handleGetPaymentLabel = (id) => {
    let paymentLabel = paymentTypeOptions?.filter((ele) => ele.Id == id);
    return paymentLabel[0]?.Text;
  };

  const [selectedPayment, setSelectedPayment] = useState(
    paymentTypeOptions[0]?.Text
  );

  const handlePaymentChange = (selectedPayment) => {
    setSelectedPayment(selectedPayment?.Text);
  };
  const paymentFreqField = GetUnitTextFieldData?.data?.filter(
    (ele) => ele.column_name === "payment_frequency_id"
  );
  const paymentFreqOptions = paymentFreqField[0]?.possible_values;
  const handleGetPaymentFreqLabel = (id) => {
    let paymentFreqLabel = paymentFreqOptions?.filter((ele) => ele.Id == id);
    return paymentFreqLabel[0]?.Text;
  };
  const ChargingField = GetUnitTextFieldData?.data?.filter(
    (ele) => ele.column_name === "charging_available"
  );
  const ChargingOptions = ChargingField[0]?.possible_values;

  const handleGetChargingLabel = (id) => {
    let getChargingLabel = ChargingOptions?.filter((ele) => ele.Id == id);
    return getChargingLabel[0]?.Text;
  };
  const weightField = GetUnitTextFieldData?.data?.filter(
    (ele) => ele.column_name === "weight_uom_id"
  );
  const weightOptions = weightField[0]?.possible_values;

  const handleGetweightLabel = (id) => {
    let weightLabel = weightOptions?.filter((ele) => ele.Id == id);
    return weightLabel[0]?.Text;
  };
  const [selectedWeight, setSelectedWeight] = useState(
    weightOptions?.[0]?.DataValue1
  );

  const handleWeightChange = (selectedWeight) => {
    setSelectedWeight(selectedWeight.DataValue1);
  };

  const paymentFrequencyChange = (e) => {
    setPaymentFrequency(e.DataValue1);
  };

  useEffect(() => {
    GetStorageRoomByIdDataRefetch();
    GetParkingLotByIdDataRefetch();
  }, []);
  useEffect(() => {
    if (typeOfUnit === 3) {
      setValue(
        "storage_room_id",
        GetStorageRoomByIdData?.data[0]?.storage_room_id
      );
      setValue("unit_no", GetStorageRoomByIdData?.data[0]?.storage_room_number);

      setValue("status_id", {
        value: GetStorageRoomByIdData?.data[0]?.status_id,
        label: GetStorageRoomByIdData?.data[0]?.status_name,
      });
      setValue("contract_no", GetStorageRoomByIdData?.data[0]?.contract_no);
      setValue("type_id", {
        value: GetStorageRoomByIdData?.data[0]?.type_id,
        label: handleGetLabel(GetStorageRoomByIdData?.data[0]?.type_id),
      });
      setValue("payment_type_id", {
        value: GetStorageRoomByIdData?.data[0]?.payment_type_id,
        label: handleGetPaymentLabel(
          GetStorageRoomByIdData?.data[0]?.payment_type_id
        ),
      });
      setValue(
        "primary_customer",
        GetStorageRoomByIdData?.data[0].primary_customer
      );
      setValue("location", GetStorageRoomByIdData?.data[0]?.location);
      setValue(
        "dimension_width",
        GetStorageRoomByIdData?.data[0]?.dimension_width
      );
      setValue(
        "dimension_length",
        GetStorageRoomByIdData?.data[0]?.dimension_length
      );
      setValue(
        "dimension_height",
        GetStorageRoomByIdData?.data[0]?.dimension_height
      );

      setValue("uom_id", {
        value: GetStorageRoomByIdData?.data[0]?.uom_id,

        label: handleGetDimensionLabel(GetStorageRoomByIdData?.data[0]?.uom_id),
      });
      setSelectedUnit(GetStorageRoomByIdData?.data[0]?.uom_unit_name);
      setValue("notice", GetStorageRoomByIdData?.data[0]?.notice);
      setValue("notice_period_id", {
        value: GetStorageRoomByIdData?.data[0]?.notice_period_id,
        label: handleGetNoticePeriodLabel(
          GetStorageRoomByIdData?.data[0]?.notice_period_id
        ),
      });
      setValue("cubic_uom_id", {
        value: GetStorageRoomByIdData?.data[0]?.cubic_uom_id,
        label: handleGetCubicLabel(
          GetStorageRoomByIdData?.data[0]?.cubic_uom_id
        ),
      });
      setValue("cubic_size", GetStorageRoomByIdData?.data[0]?.cubic_size);
      setSelectedCubic(GetStorageRoomByIdData?.data[0]?.cubic_uom_name);
      setValue("price", GetStorageRoomByIdData?.data[0]?.price);
      setValue("payment_frequency_id", {
        value: GetStorageRoomByIdData?.data[0]?.payment_frequency_id,
        label: handleGetPaymentFreqLabel(
          GetStorageRoomByIdData?.data[0]?.payment_frequency_id
        ),
      });
      setPaymentFrequency(
        GetStorageRoomByIdData?.data[0]?.payment_frequency_name
      );
    }
    if (typeOfUnit === 2) {
      setValue("unit_no", GetParkingLotByIdData?.data[0]?.parking_lot_number);
      setValue("status_id", {
        value: GetParkingLotByIdData?.data[0]?.status_id,
        label: GetParkingLotByIdData?.data[0]?.status_name,
      });
      setValue("contract_no", GetParkingLotByIdData?.data[0]?.contract_no);
      setValue("type_id", {
        value: GetParkingLotByIdData?.data[0]?.type_id,
        label: handleGetLabel(GetParkingLotByIdData?.data[0]?.type_id),
      });
      setValue("payment_type_id", {
        value: GetParkingLotByIdData?.data[0]?.payment_type_id,
        label: handleGetPaymentLabel(
          GetParkingLotByIdData?.data[0]?.payment_type_id
        ),
      });
      setValue(
        "primary_customer",
        GetParkingLotByIdData?.data[0]?.primary_customer
      );
      setValue("location", GetParkingLotByIdData?.data[0]?.location);
      setValue(
        "dimension_width",
        GetParkingLotByIdData?.data[0]?.dimension_width
      );
      setValue(
        "dimension_length",
        GetParkingLotByIdData?.data[0]?.dimension_length
      );
      setValue(
        "dimension_height",
        GetParkingLotByIdData?.data[0]?.dimension_height
      );
      setValue(
        "dimension_max_height",
        GetParkingLotByIdData?.data[0]?.dimension_max_height
      );
      setValue("uom_id", {
        value: GetParkingLotByIdData?.data[0]?.uom_id,
        label: handleGetDimensionLabel(GetParkingLotByIdData?.data[0]?.uom_id),
      });
      setSelectedUnit(GetParkingLotByIdData?.data[0]?.uom_unit_name);
      setValue("notice", GetParkingLotByIdData?.data[0]?.notice);
      setValue("notice_period_id", {
        value: GetParkingLotByIdData?.data[0]?.notice_period_id,
        label: handleGetNoticePeriodLabel(
          GetParkingLotByIdData?.data[0]?.notice_period_id
        ),
      });
      setValue("price", GetParkingLotByIdData?.data[0]?.price);
      setValue("payment_frequency_id", {
        value: GetParkingLotByIdData?.data[0]?.payment_frequency_id,
        label: handleGetPaymentFreqLabel(
          GetParkingLotByIdData?.data[0]?.payment_frequency_id
        ),
      });
      setValue("charging_available", {
        value: GetParkingLotByIdData?.data[0]?.charging_available,
        label: handleGetChargingLabel(
          GetParkingLotByIdData?.data[0]?.charging_available
        ),
      });
      setValue("weight", GetParkingLotByIdData?.data[0]?.weight);

      setValue("weight_uom_id", {
        value: GetParkingLotByIdData?.data[0]?.weight_uom_id,
        label: handleGetweightLabel(
          GetParkingLotByIdData?.data[0]?.weight_uom_id
        ),
      });
      setPaymentFrequency(
        GetParkingLotByIdData?.data[0]?.payment_frequency_name
      );
    }
  }, [GetParkingLotByIdData, GetStorageRoomByIdData]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      unit_no: "",
      status_id: "",
      contract_no: "",
      type_id: "",
      // currency_id: "",
      payment_type_id: "",
      payment_frequency_id: "",
      price: "",
      notice_period_id: "",
      location: "",
      dimension_width: "",
      dimension_length: "",
      uom_id: "",
      dimension_height: "",
      cubic_uom_id: "",
      cubic_size: "",
      notice: "",
      charging_available: "",
      weight: "",
      weight_uom_id: "",
    },
  });
  const onFormSubmit = async (data) => {
    if (typeOfUnit === 3) {
      const editStorageUnitPayload = {
        storage_room_id: GetStorageRoomByIdData?.data[0]?.storage_room_id,
        storage_room_number:
          GetStorageRoomByIdData?.data[0]?.storage_room_number,
        type_id: getValues("type_id.value"),
        payment_type_id: String(getValues("payment_type_id.value")),
        payment_frequency_id: String(getValues("payment_frequency_id.value")),
        price: getValues("price"),
        notice_period_id: String(getValues("notice_period_id.value")),
        location: getValues("location"),
        dimension_width: getValues("dimension_width"),
        dimension_length: getValues("dimension_length"),
        dimension_height: getValues("dimension_height"),
        cubic_uom_id: String(getValues("cubic_uom_id.value")),
        cubic_size: getValues("cubic_size"),
        notice: getValues("notice"),
        uom_id: getValues("uom_id.value"),
      };
      try {
        const result = await EditStorageRoomMutate(editStorageUnitPayload);

        if (result?.status === "success" && result?.data?.bReturn) {
          toast.success(result?.data?.message);
          GetStorageRoomDetailsDataRefetch();
        } else {
          toast.error(result?.data?.message);
        }
      } catch (error) {}
    } else if (typeOfUnit === 2) {
      const editParkingUnitPayload = {
        parking_lot_id: GetParkingLotByIdData?.data[0]?.parking_lot_id,
        parking_lot_number: GetParkingLotByIdData?.data[0]?.parking_lot_number,
        type_id: String(getValues("type_id.value")),
        payment_type_id: String(getValues("payment_type_id.value")),
        payment_frequency_id: String(getValues("payment_frequency_id.value")),
        price: getValues("price"),
        charging_available: String(getValues("charging_available.value")),
        notice_period_id: String(getValues("notice_period_id.value")),
        location: getValues("location"),
        dimension_width: getValues("dimension_width"),
        dimension_length: getValues("dimension_length"),
        dimension_height: getValues("dimension_height"),
        notice: getValues("notice"),
        uom_id: getValues("uom_id.value"),
        weight: getValues("weight"),
        weight_uom_id: getValues("weight_uom_id.value"),
        dimension_max_height: getValues("dimension_max_height"),
      };
      try {
        const result = await EditParkingLotMutate(editParkingUnitPayload);
        if (result?.status === "success" && result?.data?.bReturn) {
          toast.success(result?.data?.message);
          GetParkingLotDetailsDataRefetch();
        } else {
          toast.error(result?.data?.message);
        }
      } catch (error) {}
    }
  };
  const columns = useMemo(() => [
    {
      accessorKey: "posted",
      header: "Posted",
      enableHiding: false,
      enableGlobalFilter: false,

      // center align the header and body cells
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "period_from",
      header: "Period from",
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "period_to",
      header: "Period to",
      // size: 20,
      // enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "monthly_price",
      header: "Monthly price",
      // size: 50,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
    },
    {
      accessorKey: "total_price",
      header: "Total price",
      // size: 50,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
    },
  ]);
  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div>
        <div className="pt-3  px-4 ms-1">
          <div className="  form-label-title">Unit no.</div>
          <Row className="pt-2">
            <Col lg={3}>
              <div className="form-input ">
                <Controller
                  control={control}
                  {...register("unit_no")}
                  render={({ field: { onChange, value } }) => (
                    <div className="form-input ">
                      <input
                        type="text"
                        placeholder="Unit no."
                        id="unit_no"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("unit_no");
                        }}
                        maxLength={30}
                      />
                      <label htmlFor="unit_no">Unit no.</label>
                    </div>
                  )}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="pt-2  px-4 ms-1">
          <Row className=" d-flex align-items-end">
            <Col lg={3} className="pt-2">
              <div className="  form-label-title pb-2">Status</div>
              <Controller
                name="status_id"
                {...register("status_id")}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder="Status"
                    value={value}
                    clearable={false}
                    isSearchable
                    disabled={true}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("status_id");
                    }}
                    error={errors.status_id}
                    options={dimensionOptions?.map((item) => ({
                      value: item?.Id,
                      label: item?.Text,
                    }))}
                  />
                )}
              />
            </Col>
            <Col lg={3} className="pt-2">
              <div className="  form-label-title pb-2">Contract no.</div>
              <Controller
                name="contract_no"
                {...register("contract_no")}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IconField
                    id={"ContractNo."}
                    Icons={"fa-solid fa-arrow-up-right-from-square"}
                    disabled={true}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    placeholder={"Contract no."}
                    type="number"
                  />
                )}
              />
            </Col>
            <Col lg={3} className="pt-2">
              <Controller
                name="Primary customer"
                {...register("primary_customer")}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <IconField
                    id={"primary_customer"}
                    Icons={"fa-solid fa-arrow-up-right-from-square"}
                    disabled={true}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    placeholder={"Primary customer"}
                    type="text"
                  />
                )}
              />
            </Col>
          </Row>
        </div>

        <div className="pt-2  px-4 ms-1">
          <Row className="d-flex align-items-end">
            <Col lg={3} className="pt-2">
              <div className="  form-label-title pb-2">Location</div>
              <div className="form-input ">
                <Controller
                  control={control}
                  {...register("location")}
                  render={({ field: { onChange, value } }) => (
                    <div className="form-input ">
                      <input
                        type="text"
                        placeholder="Location"
                        id="location"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("location");
                        }}
                        maxLength={30}
                      />
                      <label htmlFor="location">Location</label>
                    </div>
                  )}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="pt-2  px-4 ms-1">
          <Row className="d-flex align-items-end">
            <Col lg={3} className=" pt-2">
              <div className="  form-label-title pb-2">Dimensions</div>

              <Controller
                name="uom_id"
                {...register("uom_id")}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder="Dimensions"
                    value={value}
                    clearable={false}
                    isSearchable
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("uom_id");
                      handleUnitChange(e); // Call the handleUnitChange function e.g. cm,ft
                      // setSelectedUnit(e); // Change to e.label to set the selected DataValue1
                    }}
                    error={errors.uom_id}
                    options={dimensionOptions?.map((item) => ({
                      value: item?.Id,
                      label: item?.Text,
                      DataValue1: item?.DataValue1, // Add DataValue1 to the option
                    }))}
                  />
                )}
              />
            </Col>
            <Col lg={2} className="pe-0 pt-2">
              <Controller
                control={control}
                {...register("dimension_width")}
                render={({ field: { onChange, value } }) => (
                  <GroupText
                    lable={"Width"}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    graylabel={
                      typeOfUnit === 3
                        ? handleToUpperCase(selectedUnit)
                        : handleToUpperCase(selectedUnit)
                    }
                    id={"width"}
                    textWidth={"30%"}
                  />
                )}
              />
            </Col>
            <Col lg={2} className="pe-0 pt-2">
              <Controller
                control={control}
                {...register("dimension_length")}
                render={({ field: { onChange, value } }) => (
                  <GroupText
                    lable={"Length"}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    graylabel={typeOfUnit === 3 ? selectedUnit : selectedUnit}
                    id={"length"}
                    textWidth={"30%"}
                  />
                )}
              />
            </Col>
            {typeOfUnit === 2 && (
              <Col lg={2} className="pe-0 pt-2">
                <Controller
                  control={control}
                  {...register("dimension_max_height")}
                  render={({ field: { onChange, value } }) => (
                    <GroupText
                      lable={"Height"}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      graylabel={typeOfUnit === 3 ? selectedUnit : selectedUnit}
                      id={"dimension_max_height"}
                      textWidth={"30%"}
                    />
                  )}
                />
              </Col>
            )}

            {typeOfUnit === 3 && (
              <Col lg={2} className="pe-0 pt-2">
                <Controller
                  control={control}
                  {...register("dimension_height")}
                  render={({ field: { onChange, value } }) => (
                    <GroupText
                      lable={"Height"}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      graylabel={
                        typeOfUnit === 3
                          ? handleToUpperCase(selectedUnit)
                          : handleToUpperCase(selectedUnit)
                      }
                      id={"height"}
                      textWidth={"30%"}
                    />
                  )}
                />
              </Col>
            )}
          </Row>
        </div>
        {typeOfUnit === 2 && (
          <div className="pt-2  mb-2 px-4 ms-1">
            <Row className=" d-flex align-items-end">
              <Col lg={3} className="pt-2">
                <div className="  form-label-title pb-2">Weight</div>

                <Controller
                  name="weight_uom_id"
                  {...register("weight_uom_id")}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder="Weight"
                      value={value}
                      clearable={false}
                      isSearchable
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("weight_uom_id");
                        handleWeightChange(e);
                      }}
                      error={errors.weight_uom_id}
                      options={weightOptions?.map((item) => ({
                        value: item?.Id,
                        label: item?.Text,
                        DataValue1: item?.DataValue1, // Add DataValue1 to the option
                      }))}
                    />
                  )}
                />
              </Col>

              <Col lg={2} className="pe-0 pt-2">
                <Controller
                  control={control}
                  {...register("weight")}
                  render={({ field: { onChange, value } }) => (
                    <GroupText
                      lable={"Weight"}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      graylabel={selectedWeight}
                      id={"Weight"}
                      textWidth={"30%"}
                    />
                  )}
                />
              </Col>
            </Row>
          </div>
        )}
        {typeOfUnit === 3 && (
          <>
            <div className="pt-2  mb-2 px-4 ms-1">
              <Row className=" d-flex align-items-end">
                <Col lg={3} className="pt-2">
                  <div className="  form-label-title pb-2">Cubic </div>
                  <Controller
                    name="cubic_uom_id"
                    {...register("cubic_uom_id")}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder="Cubic"
                        value={value}
                        clearable={false}
                        isSearchable
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("cubic_uom_id");
                          handleCubicChange(e);
                        }}
                        error={errors.cubic_uom_id}
                        options={cubicOptions?.map((item) => ({
                          value: item?.Id,
                          label: item?.Text,
                          DataValue2: item.DataValue2,
                        }))}
                      />
                    )}
                  />
                </Col>
                <Col lg={2} className="pe-0 pt-2">
                  <Controller
                    control={control}
                    {...register("cubic_size")}
                    render={({ field: { onChange, value } }) => (
                      <GroupText
                        lable={"Cubic size"}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        graylabel={handleToUpperCase(selectedCubic)}
                        id={"cubic_size"}
                        textWidth={"30%"}
                      />
                    )}
                  />
                </Col>
              </Row>
            </div>
          </>
        )}
        <div className="pt-2 px-4 ms-1">
          <Row className=" d-flex align-items-end">
            <Col lg={3} className="pt-2">
              <div className="  form-label-title pb-2">Type</div>

              <Controller
                name="type_id"
                {...register("type_id")}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder="Type"
                    value={value}
                    clearable={false}
                    isSearchable
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("type_id");
                    }}
                    error={errors.type_id}
                    options={typeOptions?.map((item) => ({
                      value: item?.Id,
                      label: item?.Text,
                    }))}
                  />
                )}
              />
            </Col>
            <Col lg={3} className="pt-2">
              <div className="  form-label-title pb-2">Notice</div>

              <Controller
                name="notice_period_id"
                {...register("notice_period_id")}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder="Notice"
                    value={value}
                    clearable={false}
                    isSearchable
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("notice_period_id");
                    }}
                    error={errors.notice_period_id}
                    options={noticeOptions?.map((item) => ({
                      value: item?.Id,
                      label: item?.Text,
                    }))}
                  />
                )}
              />
            </Col>
            {typeOfUnit === 2 && (
              <Col lg={3} className="pt-2">
                <div className="form-label-title pb-2">Charging</div>
                <Controller
                  name="charging_available"
                  {...register("charging_available")}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder="Charging"
                      value={value}
                      clearable={false}
                      isSearchable
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("charging_available");
                      }}
                      error={errors.charging_available}
                      options={ChargingOptions?.map((item) => ({
                        value: item?.Id,
                        label: item?.Text,
                      }))}
                    />
                  )}
                />
              </Col>
            )}
          </Row>
        </div>

        <div className="pt-2 pb-4  px-4 ms-1">
          <Row className=" d-flex align-items-end">
            <Col lg={3} className="pt-2">
              <div className="  form-label-title pb-2">Payment type</div>
              <Controller
                name="payment_type_id"
                {...register("payment_type_id")}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder="Payment Type"
                    value={value}
                    clearable={false}
                    isSearchable
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("payment_type_id");
                    }}
                    error={errors.payment_type_id}
                    options={paymentTypeOptions?.map((item) => ({
                      value: item?.Id,
                      label: item?.Text,
                      Text: item?.Text, // Add DataValue1 to the option
                    }))}
                  />
                )}
              />
            </Col>
            <Col lg={3} className="pt-2">
              <div className="  form-label-title pb-2">Payment frequency</div>

              <Controller
                name="payment_frequency_id"
                {...register("payment_frequency_id")}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder="Payment frequency"
                    value={value}
                    clearable={false}
                    isSearchable
                    onChange={(e) => {
                      onChange(e);
                      paymentFrequencyChange(e);
                      clearErrors("payment_frequency_id");
                    }}
                    error={errors.payment_frequency_id}
                    options={paymentFreqOptions?.map((item) => ({
                      value: item?.Id,
                      label: item?.Text,
                      DataValue1: item?.DataValue1,
                    }))}
                  />
                )}
              />
            </Col>
            <Col lg={3} className="pt-2 pe-0">
              <div className="  form-label-title pb-2">Price</div>
              <Controller
                name="Price"
                {...register("price")}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <GroupText
                    lable={"Price"}
                    // graylabel={
                    //   typeOfUnit === 3 ? selectedPayment : selectedPayment
                    // }
                    graylabel={`DKK${
                      paymentFrequency === "One-time payment"
                        ? ""
                        : `/${paymentFrequency}`
                    }`}
                    textWidth={paymentTypeOptions?.length > 9 ? "58%" : "40%"}
                    id={"price"}
                    // textWidth={"40%"}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("price");
                    }}
                  />
                )}
              />
            </Col>
          </Row>
        </div>
        {/* <div className="pt-2 pb-4 mb-2 px-4 ms-1">
          <Row className=" d-flex align-items-end">
          
          </Row>
        </div> */}

        <hr className="my-0"></hr>

        <div className="pt-3 pb-2 mt-2 px-4 ms-1">
          <div className="  form-label-title">History</div>
          <div>
            <MRTTheme
              colorVariants={["odd"]}
              table={
                <MaterialReactTable
                  columns={columns}
                  // data={historyData}\
                  data={historyData || []}
                  icons={{ ...SortIcon }}
                  localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                  enableTopToolbar={false}
                  enableBottomToolbar={true}
                  enableSelectAll={false}
                  enableColumnActions={false}
                  enableColumnFilter={false}
                  enableResizing={false}
                  initialState={{ showGlobalFilter: true }}
                  // renderBottomToolbar={({ table }) => (
                  //   <>
                  //     <div>hi</div>
                  //   </>
                  // )}
                  render
                  muiTableContainerProps={{
                    sx: {
                      paddingX: "3px",
                      paddingTop: "10px",
                    },
                  }}
                  enableColumnFilterModes={false}
                  renderBottomToolbarCustomActions={({ table }) => {
                    const handleDeactivate = () => {
                      table.getSelectedRowModel().flatRows.map((row) => {
                        alert("deactivating " + row.original.firstName);
                      });
                    };
                    const handleActivate = () => {
                      table.getSelectedRowModel().flatRows.map((row) => {
                        alert("activating " + row.original.firstName);
                      });
                    };
                    const handleContact = () => {
                      table.getSelectedRowModel().flatRows.map((row) => {
                        alert("contact " + row.original.firstName);
                      });
                    };
                    return (
                      <div
                        style={{
                          display: "block",
                        }}
                      >
                        <div className="d-flex justify-content-between   gap-2">
                          <div>Total</div>
                          <div>0 DKK</div>
                        </div>
                      </div>
                    );
                  }}
                />
              }
            />
          </div>
        </div>

        <div className="mt-5 py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          {" "}
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            // type={"button"}
            onClick={() => setShowEditParking(false)}
          />
          <div className=" d-flex ">
            <div className="px-1">
              {" "}
              <ETButton
                variant={"primary"}
                buttonText="SAVE"
                type={"submit"}
                onClick={() => setShowEditParking(false)}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditParkingLot;

const historyData = [
  {
    id: 1,
    posted: "Leased",
    period_from: "1 Dec, 2022",
    period_to: "-",
    monthly_price: "1.800",
    total_price: "16.200",
  },
  {
    id: 2,
    posted: "Available",
    period_from: "1 Aug, 2022",
    period_to: "1 Dec, 2022",
    monthly_price: "1.800",
    total_price: "-9.000",
  },
  {
    id: 3,
    posted: "Leased",
    period_from: "1 Aug, 2022",
    period_to: "1 Dec, 2022",
    monthly_price: "1.800",
    total_price: "7.200",
  },
];
