import React from "react";
import ConfirmCancelSigningOrder from "../../standardComponents/cancelSigningOrder/ConfirmCancelSigningOrder";
import { H3 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { Col, Row } from "react-bootstrap";

const ConfirmNewInvite = ({ handleBack, handleNext }) => {
  return (
    <div>
      {" "}
      {/* ------ Cancel invitation to the customer -------- */}
      <div className="p-4 pb-0">
        <div className="d-flex align-items-center gap-2">
          <H3>Invitation send to the customer</H3>
          <CustomToolTip text={"Invitation send to the customer"} />
        </div>
        <div className="pt-3">
          <Row>
            <Col xl={8} className="light-font-text">
              Since you have chosen to create a new appointment with the
              customer when moving the customer to another property or unit in
              the system, the system will send a confirmation regarding this new
              appointment. Below you can customise the email that is sent to the
              customer.
            </Col>
          </Row>
        </div>
      </div>
      <ConfirmCancelSigningOrder
        handleBack={handleBack}
        handleCreate={handleNext}
        usedFor="MCTAU" //Move customer to another unit
        buttonText={"Confirm"}
        // propertyId={propertyId}
        // unitId={unitId}
      />
    </div>
  );
};

export default ConfirmNewInvite;
