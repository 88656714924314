import React from "react";
import CustomPopup from "../../../../Components/CustomPopup";
import ETButton from "../../../../Components/Buttons/ETButton";
import CancelScreeningProcess from "./CancelScreeningProcess";
const ArchivedForm = ({
	setArchive,
	archive,
	handleStatusChange,
	cancelActiveScreening,
	setCancelActiveScreening,
}) => {
	return (
		<div>
			{" "}
			<CustomPopup
				open={archive}
				onHide={() => setArchive(false)}
				title="Are you sure you want to archive this screening template?"
				title_color="black"
				enableCrossButton={false}
				action_button_label="YES"
				action_button_label_color="white"
				action_button_color="#619130"
				cancel_button={true}
				cancel_button_bordercolor="grey"
				cancel_button_label="NO"
				cancel_button_label_color="black"
				cancel_button_color="#fff"
				footer_color={"#F9FAFA"}
				deletePopUpStyle={true}
				titleTooltip={true}
			>
				<div className="p-4">
					<p className="fs-14">
						Did you know that once you archive this screening form, it can no
						longer be used on your property.
					</p>

					<p className="fs-14">
						{" "}
						If you haven't already linked a new form to your units, it will not
						be possible to carry out screenings on the units.
					</p>
				</div>

				<div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
					<ETButton
						variant={"white"}
						buttonText="CANCEL"
						onClick={() => {
							setArchive(false);
						}}
					/>
					<ETButton
						variant={"primary"}
						buttonText="CONFIRM"
						onClick={handleStatusChange}
					/>
				</div>
			</CustomPopup>
		</div>
	);
};

export default ArchivedForm;
