import React, { useMemo, useState } from "react";
import CustomPopup from "../../../Components/CustomPopup";
import ETButton from "../../../Components/Buttons/ETButton";

import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import MRTTheme from "../../../Components/table/MRTTheme";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  paginationoptions,
} from "../../../Components/table/MRTGlobalProps.js";
import { Col, Row } from "react-bootstrap";
import { useResponsibleAgentDataStore } from "../../../hooks/stores/useResponsibleAgentDataStore.js";

const AddResponsibleAgent = ({
  usedFor = "ADD",
  showResponsibleAgentPopup,
  setShowResponsibleAgentPopup,
  assignedAgentId = null,
  disableConfirmButton = false,
  handleConfirm,
}) => {
  //================== zustand ===============//
  const { responsibleAgentData } = useResponsibleAgentDataStore(
    (state) => state
  );

  //==================== States =====================//
  const [rowSelection, setRowSelection] = useState("");

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row?.Text,
        header: "Agent name",
      },
      {
        accessorFn: (row) => row?.DataValue1,
        header: "Division",
      },
    ],
    []
  );

  return (
    <div>
      <CustomPopup
        open={showResponsibleAgentPopup}
        onHide={() => {
          setShowResponsibleAgentPopup(false);
          setRowSelection("");
        }}
        title={
          usedFor === "EDIT"
            ? "Change responsible agent"
            : "Add responsible agent"
        }
        titleTooltipText={
          usedFor === "EDIT"
            ? "Change responsible agent"
            : "Add responsible agent"
        }
        title_color="black"
        enableCrossButton={false}
        deletePopUpStyle={true}
        titleTooltip={true}
      >
        <div className="p-4">
          {usedFor === "EDIT" ? (
            <div className="light-font-text">
              There is already a responsible agent for this customer. If you
              change it to another, this agent will take over responsibility for
              the customer in the system.
            </div>
          ) : (
            <div className="light-font-text">
              There is no responsible agent for this customer right now.
              <br></br>Select an agent below.
            </div>
          )}

          <div className="pt-4">
            <MRTTheme
              colorVariantsForTableRow={["gray"]}
              colorVariantsForTableHead={["white"]}
              table={
                <MaterialReactTable
                  columns={columns}
                  // data={unitsData?.filter((data) => data.is_linked_with_screening !== 1) ?? []}
                  data={
                    assignedAgentId
                      ? responsibleAgentData?.filter(
                          (x) =>
                            x?.Id?.toString() !== assignedAgentId?.toString()
                        )
                      : responsibleAgentData ?? []
                  }
                  icons={{ ...SortIcon }}
                  onRowSelectionChange={setRowSelection}
                  state={{ rowSelection }}
                  enableFilterMatchHighlighting={true}
                  enableGlobalFilter
                  getRowId={(originalRow) => originalRow?.Id}
                  //   renderEmptyRowsFallback={renderEmptyRowsFallback}
                  enableMultiRowSelection={false}
                  enableRowNumbers={false}
                  enableRowSelection
                  globalFilterFn="contains" //turn off fuzzy matching and use simple contains filter function
                  enableRowActions={false}
                  enableColumnActions={false}
                  displayColumnDefOptions={{
                    "mrt-row-select": {
                      header: " ",
                      size: 50,
                    },
                  }}
                  muiSelectCheckboxProps={{
                    sx: {
                      color: "#d1d1d1",
                      "& .MuiSvgIcon-root": {
                        fontSize: "1.2rem !important",
                      },
                    },
                  }}
                  muiSearchTextFieldProps={{
                    ...SearchStyle,

                    InputProps: {
                      placeholder: "Search for agent...",
                      ...SearchIcon,
                    },
                  }}
                  // getRowId={(row) => row?.unit_id}
                  muiTableContainerProps={{
                    sx: {
                      padding: "0px !important",
                    },
                  }}
                  muiTablePaginationProps={{
                    ...paginationoptions,
                  }}
                  initialState={{
                    showGlobalFilter: true,
                    showColumnFilters: false,
                  }}
                  muiTablePaperProps={{
                    selected: false,
                    elevation: 0,
                    fontFamily: "Cerebri sans-light !important",
                    sx: {
                      borderRadius: "0",
                      border: "none",
                      // backgroundColor: "#F9FAFA",
                      table: {
                        borderCollapse: "separate !important",
                        borderSpacing: "0 5px !important",
                      },
                    },
                  }}
                  renderTopToolbar={({ table }) => (
                    <>
                      <Row>
                        <Col className="pb-2">
                          <MRT_GlobalFilterTextField table={table} />
                        </Col>
                      </Row>
                    </>
                  )}
                  positionGlobalFilter="left"
                />
              }
            />
          </div>
        </div>
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => {
              setShowResponsibleAgentPopup(false);
              setRowSelection("");
            }}
          />
          <ETButton
            variant={"primary"}
            buttonText="CONFIRM"
            disabled={
              (rowSelection?.length <= 0 && true) || disableConfirmButton
            }
            // disabled={
            //   responsibleAgentIdDisable?.length == 0 ||
            //   createUpdateLeadAgentLoading
            // }
            onClick={() => {
              handleConfirm(Object?.keys(rowSelection)?.[0]);
            }}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default AddResponsibleAgent;
