import React, { useEffect, useState } from "react";
import AddResponsibleAgent from "./standardComponents/AddResponsibleAgent";
import { Button, Col, Container } from "react-bootstrap";
import ETButton from "../../Components/Buttons/ETButton";
import FollowUp from "./standardComponents/follow up/FollowUp";
import CustomerDetail from "./standardComponents/CustomerDetail";
import CustomPopup from "../../Components/CustomPopup";
import Signings from "./signings/Signings";
import { GreenSpan, H1 } from "../../styles/Common.style";
import { Link, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomerFunnelTabsWizards } from "../../model/Constant";
import { NavLink } from "../../Components/Tabs/ReactTabs.style";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import DropdownModalMui from "../../Components/DropdownModal/DropdownModalMui";
import { useUserDetails } from "../../hooks/stores/useUserDetailsStore";
import { useGetUserByDivisionId } from "../../hooks/queries/useUserQueries";
import { useResponsibleAgentDataStore } from "../../hooks/stores/useResponsibleAgentDataStore";

const CustomerFunnelMain = () => {
	const [followUp, setFollowUp] = useState(false);
	const [showPopUp, setShowPopUp] = useState({ create: false, edit: false });

	//   Breadcrumbs
	const CapitalizedTextConverter = (text) => {
		const convertedText = text?.charAt(0)?.toUpperCase() + text?.slice(1);
		return convertedText;
	};
	const activePath = window.location.pathname?.split("/")[2];
	const activeSettingsSubPath = window.location.pathname?.split("/")[3];
	// console.log(activePath, "activePath");
	// console.log(activeSettingsSubPath, "activeSettingsSubPath");
	// console.log(organizationBreadcrumbsTabs, "organizationBreadcrumbsTabs");

	//========================== Zustand ======================//
	const { userDetails } = useUserDetails((state) => state);
	const { setResponsibleAgentData } = useResponsibleAgentDataStore(
		(state) => state
	);
	//======================== React queries ======================//
	const { data: GetUserByDivisionIdData } = useGetUserByDivisionId(
		userDetails?.division_id
	);

	useEffect(() => {
		setResponsibleAgentData(GetUserByDivisionIdData?.data);
		return () => {
			setResponsibleAgentData([]);
		};
	}, [GetUserByDivisionIdData]);

	return (
		<div>
			<Container fluid>
				<div className="mt-4 ">
					<H1>Customer funnel</H1>
					<div className="paragraph1-light cursor-pointer py-2">
						<Link
							to="/customer-funnel/all"
							// state={{ Tab: "Overview" }}
							style={{ textDecoration: "none", color: "black" }}
						>
							<span className="me-3">Customer funnel</span>
						</Link>
						<span className="me-3">
							<FontAwesomeIcon
								size="sm"
								icon="fa-regular fa-angle-right"
								style={{ color: "#000000" }}
							/>{" "}
						</span>
						<GreenSpan className="me-3 ">
							{" "}
							{CapitalizedTextConverter(
								activePath === "follow-up-later"
									? "Follow up later"
									: activePath
							)}
						</GreenSpan>
					</div>
				</div>
				<div>
					<div>
						<div className="d-flex flex-wrap justify-content-between align-items-center pt-1 pb-3">
							<div>
								{CustomerFunnelTabsWizards?.map((page, i) => (
									<NavLink
										key={i}
										to={page.link}
										style={{ textDecoration: "none" }}
										className=" me-2"
									>
										{page?.tab}
									</NavLink>
								))}
							</div>

							<div className="d-flex gap-2">
								<DropdownModalMui
									text={
										<div className="light-font-text me-2">
											Selected properties ({0})
										</div>
									}
								/>

								<div style={{ width: "180px" }}>
									<ReactSelect
										name={"Responsible Agent"}
										placeholder={"Responsible Agent"}
									/>
								</div>
							</div>
						</div>
						<div>
							<Outlet />
						</div>
					</div>
				</div>
			</Container>

			{/* <FollowUp followUp={followUp} setFollowUp={setFollowUp} />
      <ETButton
        variant={"primary"}
        buttonText={"CLICK"}
        onClick={() => {
          setFollowUp(true);
        }}
      />

      <CustomPopup
        open={showPopUp.edit}
        onHide={() => setShowPopUp({ create: false, edit: false })}
        title={"Create new appointment"}
        titleTooltipText={"Create new appointment"}
        title_color="black"
        enableCrossButton={true}
        deletePopUpStyle={true}
        titleTooltip={true}
        size={"xl"}
      >
        {" "}
        <CustomerDetail />
      </CustomPopup> */}
		</div>
	);
};

export default CustomerFunnelMain;
