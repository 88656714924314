import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import ETButton from "../../../Components/Buttons/ETButton";

const ScreeningFilterDropdown = ({
  setCloseDropdown,
  propertag,
  filteredTag,
  handleChange,
  handleFilter,
  drpdownclick,
  dumnmyDropDownData,
  setReset,
  setFilterKeys,
  filterKeys,
  unitsTableData,
  multiPropsFilter,
  filter,
  ChooseFilter,
  handleFilterData,
  setFilter,
  setResetCondition,
  closeDropdown,
  status,
  filteredTagdata,
  matchingUnits,
  selectedValues,
  applyBtn,
  setApplyBtn,
  screeningSection,
  matchingUnitsCount,
  filterDataLength,
}) => {
  // css

  // const handleChange = (e, ActionTypes) => {
  //   console.log("handleChange - dd", e)
  //   if (ActionTypes.action === "clear") {
  //     setFilter("");
  //   } else {
  //     setFilter(e.value);
  //   }
  //   // setFilter(event.target.value);
  // };

  const handleDelete = (key, index) => {
    setFilterKeys((prevState) => {
      // Create a copy of the filterKeys object
      const updatedFilterKeys = { ...prevState };
      // console.log(updatedFilterKeys,"updatedFilterKeys");
      // Remove the item at the specified index in the corresponding array
      updatedFilterKeys[key]?.splice(index, 1);
      return updatedFilterKeys;
    });
    setResetCondition(true);
  };
  const handleDeleteall = () => {
    if (drpdownclick) {
      setFilterKeys({
        group: [],
        is_active: [],
      });
    } else {
      setFilterKeys({
        calculated_unit_id: [],
        mainstatus_name: [],
        contract_no: [],
        unit_no: [],
        rent: [],
        no_of_rooms: [],
      });
    }

    setResetCondition(true);
    setFilter("");
  };
  return (
    <Container className="px-0">
      <Row className="pt-4 pb-3 px-4 d-flex justify-content-between align-items-center fs-14 fw-300">
        <Col style={{ overflow: "visible" }}>
          <ReactSelect
            placeholder={"Choose filter"}
            value={filter}
            onChange={handleChange}
            options={ChooseFilter?.map((filter) => ({
              value: filter?.Id,
              label: filter?.Text,
            }))}
          />
        </Col>
        <Col>
          <div className=" d-flex justify-content-end fs-14 fw-300">
            <span className="fw-600 fs-14 ">
              {selectedValues.length > 0 ? (
                <>{filterDataLength?.length}</>
              ) : (
                "0"
              )}
            </span>{" "}
            <span>
              {filterDataLength?.length <= 1}{" "}
              <span className="ms-1"> matching units</span>{" "}
            </span>
          </div>
        </Col>
      </Row>
      <hr className="mt-1 mb-0" style={{ color: "#F0F0F0" }} />

      <Row className="px-3">
        <Col className="px-0 me-1" style={{ borderRight: "1px solid #f0f0f0" }}>
          <div
            className="scrollable-container-y  d-flex flex-column me-1 pt-2 fs-14 fw-300"
            style={{ height: "255px", overflowY: "scroll" }}
          >
            {/* {console.log(selectedDropdownData, "selectedDropdownData7")} */}
            {propertag ? (
              <>
                {status ? (
                  <>
                    {filteredTagdata?.map((item, i) => (
                      <div
                        className="ps-4 py-1 light-font-text w-100  addpropContent rounded"
                        key={i}
                        onClick={() => handleFilterData(item.Text)}
                        value={item}
                      >
                        <div className="w-100">
                          {filter == "" ? "" : <div>{item.Text}</div>}
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {" "}
                    {filteredTag?.map((item, i) => (
                      <div
                        className="ps-4 py-1 light-font-text w-100  addpropContent rounded"
                        key={i}
                        onClick={() => handleFilterData(item)}
                        value={item}
                      >
                        <div className="w-100">
                          {filter == "" ? "" : <div>{item}</div>}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            ) : (
              <>
                {filteredTag?.map((item, i) => (
                  <div
                    className="ps-4 py-1 light-font-text w-100 addpropContent rounded"
                    key={i}
                    onClick={() => handleFilterData(item?.Text)}
                    value={item}
                  >
                    <div className="w-100">
                      {filter == "" ? "" : <div>{item?.Text}</div>}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </Col>
        <Col>
          <Col
            style={{
              position: "relative",
              height: "255px",
            }}
          >
            <div
              className=" scrollable-container-y me-1"
              style={{
                height: "255px",
              }}
            >
              {/* {console.log(selecteddata, filterKeys, "selecteddata2")} */}
              <div className="ps-4 pe-4 my-1">
                {Object.entries(filterKeys).map(([key, values], i) => (
                  <div key={key} className="">
                    {values.length !== 0 && (
                      <div className="fs-12 form-label-title my-2 pt-1">
                        {Object.keys(dumnmyDropDownData)[i]}
                      </div>
                    )}

                    <div>
                      {/* {console.log(values, "values")} */}
                      {values?.map((value, index) => (
                        <div
                          className="mb-2 py-1  d-flex justify-content-between fs-13 radius-4 light-font-text"
                          key={index}
                          style={{
                            backgroundColor: screeningSection
                              ? "#F9FAFA"
                              : "#ECF2E6",
                            alignItems: "center",
                            // whiteSpace: "nowrap",
                          }}
                        >
                          <div className="ms-4 ">
                            {drpdownclick
                              ? value == 1
                                ? "Active"
                                : value == 0
                                ? "InActive"
                                : value
                              : value}
                          </div>
                          <FontAwesomeIcon
                            onClick={() => {
                              // setApplyBtn(false);
                              handleDelete(key, index);
                            }}
                            className=" mx-3"
                            icon="fa-solid fa-circle-minus"
                            style={{ color: "#888888", cursor: "pointer" }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div></div>
          </Col>
        </Col>
      </Row>
      {screeningSection ? (
        <>
          <div
            className="py-2 px-3 d-flex 
        flex-md-row flex-column 
        justify-content-md-end 
        justify-content-center footer-section-button-container"
          >
            <ETButton
              variant={"primary"}
              buttonText="APPLY FILTERS"
              onClick={() => {
                multiPropsFilter(unitsTableData, filterKeys);
                setResetCondition(false);
                // setApplyBtn(true);
                setCloseDropdown(!closeDropdown);
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div
            className="py-2 px-3 d-flex 
        flex-md-row flex-column 
        justify-content-md-between  
        justify-content-center footer-section-button-container"
          >
            <ETButton
              variant={"white"}
              buttonText="RESET"
              onClick={handleDeleteall}
            />
            {drpdownclick ? (
              <ETButton
                variant={"primary"}
                buttonText="APPLY"
                onClick={() => {
                  handleFilter(filterKeys);
                  setCloseDropdown(true);
                  setResetCondition(false);
                }}
              />
            ) : (
              <ETButton
                variant={"primary"}
                buttonText="APPLY"
                onClick={() => {
                  multiPropsFilter(unitsTableData, filterKeys);
                  setResetCondition(false);
                  // setApplyBtn(true);
                  setCloseDropdown(!closeDropdown);
                }}
              />
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export default ScreeningFilterDropdown;
