import React, { useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { DraggableDiv } from "../../../../styles/Common.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSelect from "react-select";
import ETButton from "../../../../Components/Buttons/ETButton";
import {
  RadioInput,
  RadioLabel,
  RadioWrapper,
} from "../../../../Components/RadioButton/ETRadioButton";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import { Button } from "react-bootstrap";
import { useGetScreeningFormBasicDetails } from "../../../../hooks/queries/useScreeningQueries";
import { useScreening } from "../../../../hooks/stores/useScreeningStore";

export default function ScreeningSection({
  screeningCategoryId,
  screeningData,
  item,
  setValue,
  activeTabId,
  handleOnDragClick,
  setshowDelete,
  nestIndex,
  setQuestionIndex,
  control,
  register,
  placeholderProps,
  setShowClone,
  setCardSettingIcon,
  CardSettingIcon,
  setAddFieldsSection,
  addFieldsSection,
  setScreeningFieldId,
  screeningFieldId,
  setIsGroup,
  cardData,
  setCardData,
  headline,
  fields,
  headlineArray,
  selectedLanguageId,
}) {
  const { screeningFormId, screeeningConditionsStore } = useScreening(
    (state) => state
  );

  // const[redFlag,setRedFlag]=useState('')
  // const[yellowFlag,setYellowFlag]=useState('')
  const headlineValue = headlineArray?.map((item) => item?.text);
  // console.log("🚀 ~ file: ScreeningSection.js:50 ~ headlineValue:", headlineValue)
  const { data: GetScreeningFormBasicDetails } =
    useGetScreeningFormBasicDetails(screeningFormId);

  // const redFlag = GetScreeningFormBasicDetails?.data?.map((data) => data.sections?.map((data) => data?.questions)?.map((flag) => flag?.screening_red_flag_condition_id))

  return (
    <div>
      <Droppable droppableId={`questionFields_${nestIndex}`}>
        {(provided, snapshot) => (
          <ul
            style={{
              listStyleType: "none",
              padding: "10px 10px 5px 10px",
              margin: "0px",
              minHeight: "40px",
              position: "relative",
              // height: "500px",
              // overflowY: "scroll",
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {item?.questionFields?.map((queF, qFIndex) => {
              const isYellowFlagActive =
                screeeningConditionsStore?.filter(
                  (data) =>
                    data?.screening_field_id?.toString() ===
                    queF?.screening_field_id?.toString()
                )?.[0]?.yellow_flag_condition?.[0]?.is_yellow_flag_active || 0;
              const isRedFlagActive =
                screeeningConditionsStore?.filter(
                  (data) =>
                    data?.screening_field_id?.toString() ===
                    queF?.screening_field_id?.toString()
                )?.[0]?.red_flag_condition?.[0]?.is_red_flag_active || 0;

              
              return (
                <Draggable
                  draggableId={`questions_${qFIndex}`}
                  index={qFIndex}
                  key={`questions_${qFIndex}`}
                >
                  {(provided, snapshot) => (
                    <DraggableDiv
                      className="d-flex justify-content-between radius-8"
                      mode={activeTabId === qFIndex ? true : false}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        margin: "0px 0px 10px 0px",
                        boxShadow: snapshot.isDragging
                          ? "2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                          : "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
                      }}
                      onClick={() => handleOnDragClick(queF)}
                    >
                      <div className="d-flex w-100">
                        <div className="py-2 px-4 d-flex align-items-center arrow-section">
                          <FontAwesomeIcon
                            icon="fa-regular fa-grip-dots-vertical"
                            style={{ color: "#000000" }}
                          />
                        </div>
                        <div className="pt-3 pb-2 w-100">
                          <div className="px-4 d-flex justify-content-between">
                            <div className="form-label-title">
                              {headlineValue != "" ? (
                                <>{headlineValue}</>
                              ) : (
                                <>{queF?.name}</>
                              )}
                              {/* <b>{queF?.name || headlineValue}</b> */}
                            </div>
                            <div className="d-flex align-items-center gap-2">
                              <button
                                className="icon-btn"
                                type={"button"}
                                // disabled={queF?.is_group_field == 1}

                                onClick={() => {
                                  setAddFieldsSection(false);
                                  setScreeningFieldId(queF?.screening_field_id);
                                  setCardData(screeningData);
                                  // setIsGroup(queF?.screening_field_id)
                                }}
                              >
                                <FontAwesomeIcon
                                  icon="fa-regular fa-gear"
                                  size="xs"
                                  style={{ color: "#000000" }}
                                />
                              </button>
                              <button
                                className="icon-btn"
                                type={"button"}
                                disabled={screeningCategoryId === 1}
                                onClick={() => {
                                  setShowClone({
                                    fieldName: queF?.section_headline,
                                    fieldPopup: true,
                                  });
                                  setQuestionIndex((prev) => ({
                                    ...prev,
                                    screeningIndex: nestIndex,
                                    questionIndex: qFIndex,
                                  }));
                                }}
                              >
                                <FontAwesomeIcon
                                  icon="fa-regular fa-copy"
                                  size="xs"
                                  style={{
                                    color:
                                      screeningCategoryId === 1
                                        ? "#2B3C491A"
                                        : "#000000",
                                  }}
                                />
                              </button>{" "}
                              <button
                                className="icon-btn"
                                type={"button"}
                                disabled={screeningCategoryId == 1}
                                onClick={() => {
                                  setshowDelete({
                                    fieldName: queF?.section_headline,
                                    fieldPopup: true,
                                  });
                                  setQuestionIndex((prev) => ({
                                    ...prev,
                                    screeningIndex: nestIndex,
                                    questionIndex: qFIndex,
                                  }));
                                }}
                              >
                                <FontAwesomeIcon
                                  icon="fa-regular fa-trash"
                                  size="xs"
                                  style={{
                                    color:
                                      queF?.is_required == 0
                                        ? "#000000"
                                        : "#2B3C491A",
                                  }}
                                />
                              </button>
                            </div>
                          </div>
                          <div className="d-flex light-font-text px-4 gap-3 pb-3">
                            <span>
                              Required:{" "}
                              {queF?.is_required == 1 ? (
                                <FontAwesomeIcon
                                  icon="fa-regular fa-check"
                                  style={{
                                    color: "#000000",
                                  }}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon="fa-regular fa-xmark"
                                  style={{
                                    color: "#000000",
                                  }}
                                />
                              )}
                            </span>
                            <span>
                              Pre-screening:{" "}
                              {queF?.is_allowed_for_prescreening == 1 ? (
                                <FontAwesomeIcon
                                  icon="fa-regular fa-check"
                                  style={{
                                    color: "#000000",
                                  }}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon="fa-regular fa-xmark"
                                  style={{
                                    color: "#000000",
                                  }}
                                />
                              )}
                            </span>
                            <span>
                              Flag:{" "}
                              {/* {queF?.is_yellow_flag === 1 && (
                              <FontAwesomeIcon
                                icon="fa-solid fa-flag-pennant"
                                style={{ color: "#F6E651" }}
                              />
                            )} */}
                              {isYellowFlagActive === 1 && (
                                <FontAwesomeIcon
                                  icon="fa-solid fa-flag-pennant"
                                  style={{ color: "#F6E651" }}
                                />
                              )}
                              {isRedFlagActive === 1 && (
                                <FontAwesomeIcon
                                  icon="fa-solid fa-flag-pennant"
                                  style={{ color: " #E33716" }}
                                />
                              )}
                              {isYellowFlagActive === 0 && isRedFlagActive === 0 && (
                                <FontAwesomeIcon
                                  icon="fa-regular fa-xmark"
                                  style={{
                                    color: "#000000",
                                  }}
                                />
                              )}
                            </span>
                          </div>

                          {queF?.is_group_field === 1 && (
                            <div>
                              {queF?.field_list?.map((fL, fLindex) => (
                                <div key={fL?.screening_field_id}>
                                  {/* Your code for rendering question fields here */}
                                  {/* {qF[index]?.field_list?.map(
																		(field, fieldIndex) => ( */}
                                  <div key={fLindex}>
                                    {/* Render fields for group field's children */}
                                    {fL?.field_type === "text" && (
                                      <div className="form-input mb-2 mx-4 w-50">
                                        <div className="form-label-title">
                                          {fL?.label}
                                        </div>

                                        <div>
                                          <input
                                            type="text"
                                            placeholder={fL?.placeholder}
                                            id={`field_${fL?.screening_field_id}`}
                                            disabled={true}
                                          />
                                          <label
                                            htmlFor={`field_${fL?.screening_field_id}`}
                                          >
                                            {fL?.placeholder}
                                          </label>
                                        </div>
                                      </div>
                                    )}

                                    {fL?.field_type === "number" && (
                                      <div className="form-input mb-2 mx-4 w-50">
                                        <div className="form-label-title">
                                          {fL?.label}
                                        </div>

                                        <div>
                                          <input
                                            type="number"
                                            placeholder={fL?.placeholder}
                                            id={`field_${fL?.screening_field_id}`}
                                            disabled={true}
                                          />
                                          <label
                                            htmlFor={`field_${fL?.screening_field_id}`}
                                          >
                                            {fL?.placeholder}
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                    {fL?.field_type === "email" && (
                                      <div className="form-input mb-2 mx-4 w-50">
                                        <div className="form-label-title">
                                          {fL?.label}
                                        </div>

                                        <div>
                                          <input
                                            type="email"
                                            placeholder={fL?.placeholder}
                                            id={`field_${fL?.screening_field_id}`}
                                            disabled={true}
                                          />
                                          <label
                                            htmlFor={`field_${fL?.screening_field_id}`}
                                          >
                                            {fL?.placeholder}
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                    {fL?.field_type === "radio" &&
                                      fL?.possible_values.map((item, index) => (
                                        <div className="pb-1 px-4" key={index}>
                                          <div className="form-label-title">
                                            {fL?.label}
                                          </div>
                                          <RadioWrapper>
                                            <RadioInput
                                              type="radio"
                                              name={`${index.text}`}
                                              id={index}
                                              style={{
                                                backgroundColor: "#ffffff",
                                              }}
                                            />
                                            <RadioLabel>{item.text}</RadioLabel>
                                          </RadioWrapper>
                                        </div>
                                      ))}

                                    {fL?.field_type === "select" && (
                                      <div
                                        className="mb-2 w-75 px-4 "
                                        style={{ zIndex: "1" }}
                                      >
                                        <div className="form-label-title">
                                          {fL?.label}
                                        </div>
                                        <ReactSelect
                                          placeholder={`${fL?.placeholder}`}
                                          disabled={true}
                                        />
                                      </div>
                                    )}
                                    {fL?.field_type === "date" && (
                                      <div className="px-4">
                                        <div className="form-label-title">
                                          {fL?.label}
                                        </div>
                                        <div className="form-input  w-50">
                                          <input
                                            type="date"
                                            placeholder={fL?.placeholder}
                                            id={`date-${fL?.placeholder}`}
                                            disabled={true}
                                          />
                                          <label
                                            htmlFor={`date-${fL?.placeholder}`}
                                          >
                                            {fL?.placeholder}
                                          </label>
                                        </div>
                                      </div>
                                    )}

                                    {fL?.field_type === "phone" && (
                                      <div className="w-100 d-flex mx-4 gap-2 ">
                                        <div style={{ width: "30%" }}>
                                          <div className="form-label-title">
                                            {fL?.label}
                                          </div>
                                          <ReactSelect
                                            placeholder={`Country code`}
                                            disabled={true}
                                          />
                                        </div>
                                        <div className="form-input mb-2  w-50">
                                          <input
                                            type="number"
                                            placeholder={fL?.placeholder}
                                            id={`phone-${fL?.placeholder}`}
                                          />
                                          <label
                                            htmlFor={`phone-${fL?.placeholder}`}
                                          >
                                            {fL?.placeholder}
                                          </label>
                                        </div>
                                      </div>
                                    )}

                                    {fL?.field_type === "textarea" && (
                                      <div
                                        className="mb-2 w-75 px-4 "
                                        style={{ zIndex: "1" }}
                                      >
                                        <div className="form-float openhouse-des">
                                          <textarea
                                            name=""
                                            class="inputText"
                                            cols="20"
                                            rows="3"
                                            placeholder=""
                                            disabled={true}
                                          ></textarea>
                                          <label className="floating-label">
                                            {fL?.placeholder}
                                          </label>
                                        </div>
                                      </div>
                                    )}

                                    {fL?.field_type === "Button" && (
                                      <div
                                        className="mb-2 w-75 px-4 "
                                        style={{ zIndex: "1" }}
                                      >
                                        <ETButton
                                          variant={"white"}
                                          buttonText={fL?.placeholder}
                                        />
                                      </div>
                                    )}
                                  </div>
                                  {/* )} */}
                                </div>
                              ))}
                            </div>
                          )}

                          {queF?.is_group_field == 0 && (
                            <div>
                              {queF?.field_type === "text" && (
                                <div className="form-input mb-2 mx-4 w-50">
                                  <div className="form-label-title">
                                    {queF?.label}
                                  </div>

                                  <div>
                                    <input
                                      type="text"
                                      placeholder={queF?.placeholder}
                                      id={`field_${queF?.screening_field_id}`}
                                      disabled={true}
                                    />
                                    <label
                                      htmlFor={`field_${queF?.screening_field_id}`}
                                    >
                                      {queF?.placeholder}
                                    </label>
                                  </div>
                                </div>
                              )}
                              {queF?.field_type === "number" && (
                                <div className="form-input mb-2 mx-4 w-50">
                                  <div className="form-label-title">
                                    {queF?.label}
                                  </div>

                                  <div>
                                    <input
                                      type="number"
                                      placeholder={queF?.placeholder}
                                      id={`field_${queF?.screening_field_id}`}
                                      disabled={true}
                                    />
                                    <label
                                      htmlFor={`field_${queF?.screening_field_id}`}
                                    >
                                      {queF?.placeholder}
                                    </label>
                                  </div>
                                </div>
                              )}
                              {queF?.field_type === "email" && (
                                <div className="form-input mb-2 mx-4 w-50">
                                  <div className="form-label-title">
                                    {queF?.label}
                                  </div>

                                  <div>
                                    <input
                                      type="email"
                                      placeholder={queF?.placeholder}
                                      id={`field_${queF?.screening_field_id}`}
                                      disabled={true}
                                    />
                                    <label
                                      htmlFor={`field_${queF?.screening_field_id}`}
                                    >
                                      {queF?.placeholder}
                                    </label>
                                  </div>
                                </div>
                              )}

                              {queF?.field_type === "radio" &&
                                queF.possible_values.map((item, index) => (
                                  <div className="pb-1 px-4" key={index}>
                                    <div className="form-label-title">
                                      {queF?.label}
                                    </div>
                                    <RadioWrapper>
                                      <RadioInput
                                        type="radio"
                                        name={`${index.text}`}
                                        id={index}
                                        style={{
                                          backgroundColor: "#ffffff",
                                        }}
                                        disabled={true}
                                      />
                                      <RadioLabel>{item.text}</RadioLabel>
                                    </RadioWrapper>
                                  </div>
                                ))}

                              {queF?.field_type === "select" && (
                                <div
                                  className="mb-2 w-75 px-4 "
                                  style={{ zIndex: "1" }}
                                >
                                  <div className="form-label-title">
                                    {queF?.label}
                                  </div>
                                  <ReactSelect
                                    placeholder={`${queF?.placeholder}`}
                                    disabled={true}
                                  />
                                </div>
                              )}

                              {queF?.field_type === "date" && (
                                <div className="px-4">
                                  <div className="form-label-title">
                                    {queF?.label}
                                  </div>
                                  <div className="form-input   w-50">
                                    <input
                                      type="date"
                                      placeholder={queF?.placeholder}
                                      id={`date-${queF?.placeholder}`}
                                      disabled={true}
                                    />
                                    <label
                                      htmlFor={`date-${queF?.placeholder}`}
                                    >
                                      {queF?.placeholder}
                                    </label>
                                  </div>
                                </div>
                              )}

                              {queF?.field_type === "phone" && (
                                <div className="w-100 d-flex mx-4 gap-2 ">
                                  <div style={{ width: "30%" }}>
                                    <div className="form-label-title">
                                      {queF?.label}
                                    </div>
                                    <ReactSelect
                                      placeholder={`Country code`}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="form-input mb-2  w-50">
                                    <input
                                      type="number"
                                      placeholder={queF?.placeholder}
                                      id={`phone-${queF?.placeholder}`}
                                      disabled={true}
                                    />
                                    <label
                                      htmlFor={`phone-${queF?.placeholder}`}
                                    >
                                      {queF?.placeholder}
                                    </label>
                                  </div>
                                </div>
                              )}

                              {queF?.field_type === "textarea" && (
                                <div
                                  className="mb-2 w-75 px-4 "
                                  style={{ zIndex: "1" }}
                                >
                                  <div className="form-float openhouse-des">
                                    <textarea
                                      name=""
                                      class="inputText"
                                      cols="20"
                                      rows="3"
                                      placeholder=""
                                      disabled={true}
                                    ></textarea>
                                    <label className="floating-label">
                                      {queF?.placeholder}
                                    </label>
                                  </div>
                                </div>
                              )}

                              {queF?.field_type === "Button" && (
                                <>
                                  <div
                                    className="mb-2 w-75 px-4 "
                                    style={{ zIndex: "1" }}
                                  >
                                    <ETButton
                                      variant={"white"}
                                      buttonText={queF?.placeholder}
                                      disabled={true}
                                    />
                                  </div>
                                </>
                              )}

                              {queF?.field_type === "fileupload" && (
                                <div
                                  className="bg-white radius-8 mb-2 mx-4  px-3 d-flex align-items-center justify-content-between "
                                  style={{
                                    height: "40px",
                                    width: "70%",
                                    border: "1px solid #d1d1d1",
                                  }}
                                >
                                  <input
                                    type="file"
                                    placeholder={queF?.placeholder}
                                    id={`file-${queF?.placeholder}`}
                                    style={{
                                      display: "none",
                                    }}
                                    disabled={true}
                                  />
                                  <label
                                    htmlFor={`file-${queF?.placeholder}`}
                                    className="w-100 d-flex align-items-center justify-content-between"
                                  >
                                    <span className="light-font-text">
                                      Supports JPG, PNG, PDF, DOCX, XLSX & more
                                    </span>
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-file"
                                      style={{
                                        color: "#000000",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </label>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </DraggableDiv>
                  )}
                </Draggable>
              );
            })}

            {provided.placeholder}
            {placeholderProps !== null && snapshot.isDraggingOver && (
              <div
                className="dnd-placeholder"
                style={{
                  // top:
                  //   placeholderProps.clientY +
                  //   placeholderProps.clientHeight / 2,
                  top: placeholderProps.clientY,
                  // left: placeholderProps.clientX,
                  height: placeholderProps.height,
                  // width: placeholderProps.clientWidth,
                  width: "calc(100% - 20px)",
                }}
              />
            )}
          </ul>
        )}
      </Droppable>
    </div>
  );
}
