import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Select, { components } from "react-select";

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.key !== "placeholder" ? child : null
      )}
    </ValueContainer>
  );
};
//---------------------------------------- ------------------------
const colourStyles = {
  container: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    backgroundColor: "rgb(255,255,255)",
    fontFamily: "Cerebri sans-light !important",
    borderRadius: 8,
    marginLeft: "10px",
    fontSize: 14,
    paddingTop: "4px",
  }),
  placeholder: (base, state) => ({
    ...base,
    position: "absolute",
    fontWeight: "400",
    color:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? "#888888 !important"
        : "#000000 !important",
    background: "rgb(255,255,255)",
    fontFamily:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? " Cerebri sans-medium !important"
        : "Cerebri sans-light !important",
    background:
      "linear-gradient(0deg, rgba(255,255,255,1) 55%, rgba(247,247,247,0) 45%)",
    borderRadius: 2,
    paddingLeft: "4px",
    paddingRight: "4px",
    // marginLeft: "10px",
    top:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? "-115%"
        : "0%",
    transition: "top 0.2s, font-size 0.2s",
    fontSize: state.selectProps.isFocused || state.hasValue ? 8 : 14,

    fontWeight: 300,
    // (state.hasValue ||
    //   state.selectProps.inputValue ||
    //   state.selectProps.isFocused) &&
    // 14,
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    // paddingTop: "4px",
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? "#d1d1d1 !important" : "#d1d1d1 !important",
    borderRadius: "8px",
    backgroundColor: "#fff",
    fontSize: 14,
    fontWeight: 300,
    "&:hover": {
      borderColor: "#d1d1d1",
      boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
    },
    pointerEvents: state.isDisabled && "auto",
    cursor: state.isDisabled ? "not-allowed " : "default",
    // opacity: state.isDisabled ? "0.5" : "1",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#000000", // Custom colour
    transition: "all .2s ease",
    transform: state.isFocused ? "rotate(180deg)" : null,
    padding: "10px 16px",
  }),
  option: (base, { isDisabled }, state) => ({
    ...base,
    backgroundColor: "#fff",
    zIndex: 1,
    fontSize: 14,
    fontFamily: "Cerebri sans-light !important",
    color: isDisabled ? "#888888" : "#000000",
    paddingLeft: "15px",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 3,
    fontSize: 14,
    fontWeight: 300,
    width: "max-content",
    minWidth: "100%",
    overflowX: "scroll",
  }),
  singleValue: (provided, { data }) => ({
    ...provided,
    marginLeft: "4px",
    // specify a fallback color here for those values not accounted for in the styleMap
  }),
  menuPortal: (base) => ({ ...base, zIndex: 10000 }),
};

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon
          icon="fa-solid fa-chevron-down"
          style={{ color: "#000000" }}
        />
      </components.DropdownIndicator>
    )
  );
};
const ClearIndicator = (props) => {
  return (
    components.ClearIndicator && (
      <components.ClearIndicator {...props}>
        <FontAwesomeIcon
          icon="fa-solid fa-xmark"
          style={{ color: "#000000" }}
        />
      </components.ClearIndicator>
    )
  );
};

const BigReactSelect = ({
  name,
  placeholder,
  onChange,
  options,
  value,
  defaultValue,
  searchable,
  clearable,
  disabled,
  innerLabel,
  height,
  components,
  formatGroupLabel,
  noOptionsMessage,
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <Select
      name={name}
      menuPortalTarget={document.body}
      styles={colourStyles}
      placeholder={placeholder}
      isSearchable={searchable}
      isClearable={clearable}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
      isDisabled={disabled}
      formatGroupLabel={formatGroupLabel}
      menuPosition={"fixed"}
      autosize={true}
      components={{
        ValueContainer: CustomValueContainer,
        DropdownIndicator,
        ClearIndicator,
      }}
      noOptionsMessage={() => `${noOptionsMessage}`}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      isFocused={focused}
      theme={(theme) => ({
        ...theme,
        backgroundColor: "#ffffff",
        // borderRadius: 0,
        spacing: {
          ...theme.spacing,
          baseUnit: 2,
          controlHeight: height ? height : 55,
          menuGutter: 8,
        },
      })}
    />
  );
};

BigReactSelect.defaultProps = {
  searchable: true,
  clearable: true,
  disabled: false,
  noOptionsMessage: "No options",
};

export default BigReactSelect;
