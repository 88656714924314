import styled, { css } from "styled-components";

// ---------actors customer tabs------ //
export const CustomersTab = styled.button`
	white-space: nowrap;
	height: 44px;
	align-items: center;
	justify-content: center;
	display: flex;
	font-size: 16px;
	font-family: ${({ mode }) =>
		mode ? "Cerebri sans-medium" : "Cerebri sans-light"};
	border-radius: 8px;
	border: 1px solid #f0f0f0;
	cursor: pointer;
	text-align: center;
	padding: 11px 16px;
	width: 100%;
	background-color: ${({ mode }) =>
		mode
			? (props) => props.theme.component.tabs.primary.backGroundColor
			: "#FFFFFF"};
	color: ${({ mode }) => (mode ? "#FFFFFF" : "#000000")};
`;

export const TimeSlotTab = styled.button`
	white-space: nowrap;
	height: 44px;
	align-items: center;
	justify-content: center;
	display: flex;
	font-size: 13px;
	font-family: ${({ mode }) =>
		mode ? "Cerebri sans-medium" : "Cerebri sans-light"};
	border-radius: 8px;
	border-color: ${({ mode }) =>
		mode === true
			? (props) => props.theme.component.checkbox.primary.color
			: "#d1d1d1"};
	border-style: solid;
	border-width: 1px;
	cursor: pointer;
	text-align: center;
	padding: 11px 7px;
	width: 100%;
	background-color: ${({ mode }) =>
		mode === true
			? (props) => props.theme.component.checkbox.primary.color + "22"
			: "white"};
	color: ${({ mode }) =>
		mode === true
			? (props) => props.theme.component.checkbox.primary.color
			: "#000000"};
`;
