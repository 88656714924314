import React, { useEffect, useMemo, useState } from "react";
import CustomToolTip from "../../../../../../../../../Components/Tooltip/CustomTooltip";
import ETButton from "../../../../../../../../../Components/Buttons/ETButton";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "../../../../../../../../../Components/DropDown/ReactSelect";
import MRTTheme from "../../../../../../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";

import { InquiriesTableData } from "./data";
import {
	SortIcon,
	TableRowsStyle,
	paginationoptions,
} from "../../../../../../../../../Components/table/MRTGlobalProps";
import Menus from "../../../../../../../../../Components/Menus/Menus";
import TagToolTip from "../../../../../../../../../Components/Tooltip/PropertyTagToolTip";
import {
	GreenDiv,
	H3,
	H5,
	NormalNotification,
} from "../../../../../../../../../styles/Common.style";
import DateRangeFilter from "../../../../../../../../../Components/DateRangePicker/DateRangeFilter";
import CustomPopup from "../../../../../../../../../Components/CustomPopup";
import MoveAnotherUnit from "../commonPage/MoveAnotherUnit";
import DeleteCustomerAction from "../commonPage/DeleteCustomerAction";
import AddCustomerInfo from "../commonPage/AddCustomerInfo";
import { NoRecordsToDisplay } from "../../../../../../../../../utils/validationConstants";
import { useBreadcrumb } from "../../../../../../../../../Components/BreadcrumbContext ";

const Inquiries = () => {
	const [moveToAnotherUnitsShow, setMoveToAnotherUnitsShow] = useState(false);
	const [showAddInfo, setShowAddInfo] = useState(false);
	const [deleteShow, setDeleteShow] = useState(false);
	const { updateBreadcrumbs } = useBreadcrumb();
	useEffect(() => {
		updateBreadcrumbs(["Overview", "Requests", "Inquiries"]);
	}, []);
	const columns = useMemo(
		() => [
			{
				accessorFn: (row) => `${row.name} ${row.notification} `,
				header: "Name",
				enableHiding: false,
				// size: 100,
				enableGlobalFilter: true,
				Cell: ({ row }) => (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "8px",
							cursor: "pointer",
						}}
					>
						<div>
							<TagToolTip text="Need Help About Tags" />
						</div>
						<div>{row.original.name}</div>
						<div>
							{row.original.notification != "0" && (
								<NormalNotification>
									<span> +{row.original.notification}</span>
								</NormalNotification>
							)}
						</div>
					</div>
				),
			},
			{
				accessorFn: (row) => `${row.name} ${row.notification} `,
				header: "Pre-screening",
				enableHiding: false,
				// size: 100,
				enableGlobalFilter: true,
				Cell: ({ row }) => <GreenDiv>Show</GreenDiv>,
			},
			{
				accessorKey: "property",
				header: "Property",
				enableHiding: false,
				// size: 20,
				enableGlobalFilter: false,
			},
			{
				accessorKey: "unit",
				header: "Unit",
				enableHiding: false,
				size: 200,
				enableGlobalFilter: false,
			},
			{
				accessorKey: "registration_date",
				header: "Registration",
				enableHiding: false,
				// size: 20,
				enableGlobalFilter: false,
			},
		],
		[]
	);
	const renderEmptyRowsFallback = () => (
		<div className="d-flex justify-content-center w-100 grey-text py-4 light-font-text">
			<div>No data available!!</div>
		</div>
	);

	return (
		<div>
			<div className="d-flex w-100">
				<div className="p-4 mt-2 w-75">
					<div className="form-label-title  d-flex align-items-center gap-2">
						<H3> Inquiries </H3>
						<CustomToolTip text="Inquiries" />
					</div>
					<div className="light-font-text">
						Below is a list of the viewing inquiry that have been made on this
						unit. It is possible to send an invitation to one or more customers
						with a proposed viewing day and slot time.
					</div>
				</div>
				<div className=" w-25 mt-2 py-4 pe-4 d-flex justify-content-end">
					<ETButton
						disabled={true}
						variant={"primary"}
						buttonText="SEND INVITATION"
						// onClick={() => handleUpdateResponsibleAgent()}
					/>
				</div>
			</div>

			<div className="px-4 pt-2 pb-5">
				<MRTTheme
					colorVariantsForTableRow={["gray"]}
					colorVariantsForTableHead={["white"]}
					table={
						<MaterialReactTable
							columns={columns}
							// data={InquiriesTableData} //data is undefined on first render
							data={[]}
							icons={{ ...SortIcon }}
							renderEmptyRowsFallback={renderEmptyRowsFallback}
							localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
							enableColumnActions={false}
							enableRowSelection
							enableRowActions
							enableSelectAll
							enableTopToolbar={true}
							enableGlobalFilter
							globalFilterFn="contains"
							initialState={{
								showGlobalFilter: true,
								showColumnFilters: false,
							}}
							renderTopToolbar={({ table }) => (
								<>
									<div className="px-0 ">
										<Row>
											<Col xl={4} lg={6} className="pb-2">
												<DateRangeFilter
												// date={dateRange}
												// setDate={setDateRange}
												// handleDateChange={handleDateChange}
												/>
											</Col>
											<Col xl={3} lg={5} className="ps-lg-0 pb">
												<ReactSelect placeholder={"Responsible agent"} />
											</Col>
										</Row>
									</div>
								</>
							)}
							muiTableContainerProps={{
								sx: {
									padding: "0px !important",
								},
							}}
							displayColumnDefOptions={{
								"mrt-row-select": {
									header: "",
								},
								"mrt-row-actions": {
									header: "",
								},
							}}
							positionActionsColumn="last"
							renderRowActions={({ row }) => (
								<Menus
									itemheight={23}
									itemwidth={23}
									iconborderradius="4px"
									itemcontainerwidth="200px"
									component={
										<div className="light-font-text">
											<div className="p-2 cursor-pointer">Send invitation</div>
											<hr className="hr m-0"></hr>
											<div
												className="p-2 cursor-pointer"
												onClick={() => setMoveToAnotherUnitsShow(true)}
											>
												Move to another unit
											</div>
											<hr className="hr m-0"></hr>
											<div
												className="p-2 cursor-pointer"
												onClick={() => setShowAddInfo(true)}
											>
												Add contact information
											</div>
											<hr className="hr m-0"></hr>
											<div
												className="p-2 cursor-pointer"
												onClick={() => setDeleteShow(true)}
											>
												Delete
											</div>
										</div>
									}
								/>
							)}
							muiTablePaperProps={{
								elevation: 0,
								fontFamily: "Cerebri sans-light !important",
								sx: {
									borderRadius: "0",
									border: "none",
									// backgroundColor: "#F9FAFA",
									table: {
										borderCollapse: "separate",
										borderSpacing: "0 5px",
									},
								},
							}}
							muiTableBodyRowProps={{
								selected: false,
							}}
							muiTablePaginationProps={{
								...paginationoptions,
							}}
							muiTableHeadRowProps={{
								sx: {
									...TableRowsStyle,
								},
							}}
						/>
					}
				/>
			</div>
			{/* Add contact information */}
			<CustomPopup
				open={showAddInfo}
				onHide={() => setShowAddInfo(false)}
				title="Add information about the customer"
				titleTooltipText="Add information about the customer"
				title_color="black"
				size={"xl"}
				enableCrossButton={false}
				action_button_label="YES"
				action_button_label_color="white"
				action_button_color="#619130"
				cancel_button={true}
				cancel_button_bordercolor="grey"
				cancel_button_label="NO"
				cancel_button_label_color="black"
				cancel_button_color="#fff"
				footer_color={"#F9FAFA"}
				deletePopUpStyle={true}
				titleTooltip={true}
			>
				<div>
					<AddCustomerInfo
						showAddInfo={showAddInfo}
						setShowAddInfo={setShowAddInfo}
					/>
				</div>
			</CustomPopup>

			{/* Delete */}
			<DeleteCustomerAction
				deleteCustomerAction={deleteShow}
				setDeleteCustomerAction={setDeleteShow}
			/>

			{/* Move to another unit */}
			<CustomPopup
				open={moveToAnotherUnitsShow}
				onHide={() => setMoveToAnotherUnitsShow(false)}
				title="Move customer to another unit"
				titleTooltipText="Move customer to another unit"
				title_color="black"
				size={"xl"}
				enableCrossButton={false}
				action_button_label="YES"
				action_button_label_color="white"
				action_button_color="#619130"
				cancel_button={true}
				cancel_button_bordercolor="grey"
				cancel_button_label="NO"
				cancel_button_label_color="black"
				cancel_button_color="#fff"
				footer_color={"#F9FAFA"}
				deletePopUpStyle={true}
				titleTooltip={true}
			>
				<div>
					<MoveAnotherUnit
						moveToAnotherUnitsShow={moveToAnotherUnitsShow}
						setMoveToAnotherUnitsShow={setMoveToAnotherUnitsShow}
					/>
				</div>
			</CustomPopup>
		</div>
	);
};

export default Inquiries;
