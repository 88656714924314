import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menus from "../../Components/Menus/Menus";
import Collapse from "react-bootstrap/Collapse";
import Card from "react-bootstrap/Card";
import moment from "moment";

const ExpandMore = ({ onClick, expand }) => {
  return (
    <>
      <div className="events-card-button">
        <button
          className=" d-flex justify-content-center align-items-center pt-1"
          style={{
            transform: expand ? "rotate(180deg)" : "rotate(0deg)",
          }}
          onClick={onClick}
        >
          {
            <FontAwesomeIcon
              icon="fa-regular fa-angle-down"
              style={{ color: "#000000" }}
            />
          }
        </button>
      </div>
    </>
  );
};

export default function EventsCard({ data, id }) {
  //-------------------state--------------------------//
  const [expanded, setExpanded] = useState(false);
  const [viewingProperties, setViewingProperties] = useState("recurring");

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div>
        <Card
          style={{
            position: "relative",
            backgroundColor:
              data.event_happening && data?.is_draft == 0
                ? "#E9F3EE"
                : "#F9FAFA",
            boxShadow: "none",
            overflow: "inherit",
            height: "fit-content",
            borderRadius: "8px",
            border:
              data?.event_happening && data?.is_draft == 0
                ? "1px solid #77C7A1"
                : "1px solid #F0F0F0",
            opacity: data?.is_draft == 1 ? 0.5 : "",
          }}
        >
          <div className="card-open-section px-3 pt-3">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <div
                  className="fw-600 fs-10 "
                  style={{
                    background:
                      data.event_type_id == 1 && data.is_draft == 0
                        ? "#CCE5F6"
                        : "" ||
                          (data?.event_type_id == 2 && data?.is_draft == 0)
                        ? "#B7E0CD"
                        : "" ||
                          data.event_type_id == 1 ||
                          2 ||
                          (3 && data.is_draft == 1)
                        ? "#888888"
                        : "none",
                    padding: "4px 11px",
                    borderRadius: "4px",
                  }}
                >
                  {data.event_type_name
                    ? data.event_type_name?.toUpperCase()
                    : "-"}
                </div>
                {data?.is_draft == 1 && (
                  <div
                    className="light-font-text ms-2"
                    style={{
                      fontStyle: "italic",
                    }}
                  >
                    Draft
                  </div>
                )}
              </div>
              <div className="d-flex ">
                <div>
                  {" "}
                  {data.event_happening && data?.is_draft == 0 ? (
                    <div className="d-flex">
                      <div
                        className="fw-600 fs-10 "
                        style={{
                          background: "#F7BFBC",
                          padding: "4px 11px",
                          borderRadius: "4px",
                          boxShadow: "1px 1px 10px #F7BFBC",
                        }}
                      >
                        {data.event_happening}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="ms-2">
                  {data?.is_recurring == 1 && (
                    <FontAwesomeIcon
                      className="mt-1"
                      size="xs"
                      icon="fa-regular fa-arrows-repeat"
                      style={{ color: "#000000" }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="fw-700 fs-14 mt-2"> {data.name}</div>
            <div className="d-flex align-items-center mt-2">
              {" "}
              <FontAwesomeIcon
                size="xs"
                icon="fa-solid fa-calendar-days"
                style={{ color: "#000000" }}
              />{" "}
              <span className="fw-300 fs-13 ms-2">
                {" "}
                {moment(data.event_date ? data.event_date : "-").format(
                  "MMM DD,YYYY"
                )}
                | {moment(data?.time_start, "HH:mm").format("hh:mm")}
                &nbsp;-&nbsp;
                {moment(data?.time_end, "HH:mm").format("HH:mm")}
              </span>
            </div>
            <div className="d-flex align-items-center mt-2">
              {" "}
              <FontAwesomeIcon
                icon="fa-regular fa-house"
                size="xs"
                style={{ color: "#000000" }}
              />
              <span className="fw-300 fs-13 ms-2">
                {" "}
                {data.property_name ? data.property_name : "-"}
              </span>
            </div>
          </div>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div style={{ margin: 0, padding: 0 }}>
              {viewingProperties == "happening now" ? (
                <div className="d-flex p-3">
                  <FontAwesomeIcon
                    className="mt-1"
                    size="xs"
                    icon="fa-regular fa-user"
                    style={{ color: "#000000" }}
                  />
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <div className="fw-600 fs-13 ms-2">Participant(s)</div>
                    <div style={{ lineHeight: "16px" }} className="mt-1">
                      <div className="fw-600 fs-13 ms-2 ">Monica Boes</div>
                      <div className="fw-300 fs-13 ms-2">+ 45 23 45 76 87</div>
                      <div className="fw-300 fs-13 ms-2">
                        Monicaboes@gmail.com{" "}
                      </div>
                    </div>
                    <div style={{ lineHeight: "16px" }} className="mt-1">
                      <div className="fw-600 fs-13 ms-2">Monica Boes</div>
                      <div className="fw-300 fs-13 ms-2">+ 45 23 45 76 87</div>
                      <div className="fw-300 fs-13 ms-2">
                        Monicaboes@gmail.com{" "}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {viewingProperties == "recurring" ? (
                <div>
                  <div className="d-flex p-3">
                    <FontAwesomeIcon
                      className="mt-1"
                      icon="fa-regular fa-house"
                      size="xs"
                      style={{ color: "#000000" }}
                    />
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <div className="fw-600 fs-13 ms-2">
                        Units(s) for showroom
                      </div>
                      <div style={{ lineHeight: "16px" }} className="mt-1">
                        {data?.unit_for_showroom
                          ? data?.unit_for_showroom?.map((data) => (
                              <div className="fw-300 fs-13 ms-2">
                                {data?.street_name ? data?.street_name : "-"}{" "}
                                {data?.house_number ? data?.house_number : "-"}{" "}
                                {data?.floor_number ? data?.floor_number : "-"}{" "}
                                | {data?.alt_unit_id ? data?.alt_unit_id : "-"}
                              </div>
                            ))
                          : "-"}
                        {/* <div className="fw-300 fs-13 ms-2">
                          Blomstervej 14, 3. tv | 7-W260
                        </div>
                        <div className="fw-300 fs-13 ms-2">
                          Blomstervej 15, 1. th | 2-W130
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex px-3">
                    <FontAwesomeIcon
                      className="pt-1"
                      icon="fa-regular fa-users"
                      size="xs"
                      style={{ color: "#000000" }}
                    />
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <div className="fw-600 fs-13 ms-2">
                        Number of bookings
                      </div>
                      <div style={{ lineHeight: "16px" }} className="mt-1">
                        <div className="fw-300 fs-13 ms-2">
                          Confirmed bookings:{" "}
                          <span className="fw-600">
                            {" "}
                            {data?.confirmed_booking}{" "}
                          </span>
                        </div>
                        <div className="fw-300 fs-13 ms-2">
                          Available spots:{" "}
                          <span className="fw-600">
                            {" "}
                            {data?.available_spots}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Collapse>
          <div className="d-flex justify-content-between align-items-end px-3 pb-2">
            <div className="fw-300 fs-13 grey-text">
              {data.created_by ? data.created_by : "-"}
            </div>
            <div className="mt-4">
              <Menus
                component={
                  <div style={{ fontSize: "14px" }}>
                    <div className="addpropContent px-3 py-2 ">
                      <span className="ms-1">Delete</span>
                    </div>
                    <hr className="addpropertyHr m-0"></hr>
                    <div className="addpropContent px-3 pt-2 pb-2 ">
                      <span className="ms-1">Show</span>
                    </div>
                    <hr className="addpropertyHr m-0"></hr>
                    <div className="addpropContent px-3 pt-2 pb-2 ">
                      <span className="ms-1">Edit series</span>
                    </div>
                    <hr className="addpropertyHr m-0"></hr>
                    <div className="addpropContent px-3 pt-2 pb-2 ">
                      <span className="ms-1">Edit single event</span>
                    </div>
                    <hr className="addpropertyHr m-0"></hr>
                    <div className="addpropContent px-3 pt-2 pb-2 ">
                      <span className="ms-1">Duplicate</span>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
          <div className="d-flex justify-content-center p-0 m-0">
            <div
              style={{
                position: "absolute",

                bottom: "-10px",
              }}
            >
              <ExpandMore expand={expanded} onClick={handleExpandClick} />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}
