import React, { useEffect, useState } from "react";
import { Row, Container, Placeholder, Col } from "react-bootstrap";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../../../Components/Tabs/ReactTabs.style";
import "../tags.css";
import { H1, H2 } from "../../../../styles/Common.style";

import EditTagName from "./EditTagName";
import ETButton from "../../../../Components/Buttons/ETButton";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import EditPropertyUnitsTable from "./EditPropertyUnitsTable";

import { Document, FacilitiesCard, Media, Signing, TextCard } from "./Cards";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "react-query";
import {
  GetSourceByTagId,
  GetTagByTagId,
  PutTagUpdateTag,
} from "../../../../services/Tags";
import {
  GetUnitsByPropertyId,
  GetUnitsByTagId,
} from "../../../../services/Units";
import { toast } from "react-toastify";
import { usePropertyOverview } from "../../../../hooks/stores/usePropertyStore";
import moment from "moment";
import { status } from "../../../../model/Constant";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { GreenSpan } from "../../../../styles/Common.style";
import { usePutTagUpdateTag } from "../../../../hooks/queries/useTagsQueries";
import { Link } from "react-router-dom";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";
import { UTCtoLocalTimeZone } from "../../../../utils/UTCtoLocalTimeZone";

const EditTag = ({
  setStyleEditModalTitle,
  propertyOverview,
  setShowEditTag,
  tagId,
  propertyId,
}) => {
  // zustand
  const propOverview = usePropertyOverview((state) => state.propOverview);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [addMoreUnits, setAddMoreUnits] = useState(false);

  //---------States for Update tage---------//
  const [tagName, setTagName] = useState("");
  const [tagdata, setTagdata] = useState([]);
  const [mediaPackage, setMediaPackage] = useState([]);
  const [facilityPackage, setFacilityPackage] = useState([]);
  const [propertyText, setPropertyText] = useState([]);
  const [unitsData, setUnitsData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [originalTagData, setOriginalTagData] = useState([]);
  const [documentPackage, setDocumentPackage] = useState([]);
  const [signingPackage, setSigningPackage] = useState([]);
  const [sourceFilter, setSourceFilter] = useState("All");

  //---------React query-------------//
  const { data: getTagByTagId } = useQuery(
    ["getTagByTagId", tagId],
    () => GetTagByTagId(tagId),
    {
      enabled: !!tagId,
    }
  );

  const { mutateAsync: PutTagUpdateTag, isLoading: PutTagUpdateTagIsLoading } =
    usePutTagUpdateTag();
  const { data: getSourceByTagId, isLoading } = useQuery(
    ["getSourceByTagId", tagId],
    () => GetSourceByTagId(tagId),
    {
      enabled: !!tagId,
    }
  );

  // GET UNITS BY TAG ID QUERY
  const { data: getUnitsBytagId } = useQuery(
    ["getUnitsByTagId", tagId, 0],
    () => GetUnitsByTagId(tagId, 0),
    {
      enabled: !!tagId,
    }
  );

  const { data: getUnitsByPropertyid } = useQuery(
    ["getUnitsByPropertyId", propertyId, 0],
    () => GetUnitsByPropertyId(propertyId, 0),
    {
      enabled: !!propertyId,
    }
  );
  const modifiedData = tagdata?.map((item) => {
    return {
      ...item,
      modifiedOn: moment(item.modified_on).format("DD.MMMM.YYYY | HH:mm:ss"),
      createdOn: moment(item.created_on).format("DD.MMMM.YYYY | HH:mm:ss"),
    };
  });

  useEffect(() => {
    setTagName(getTagByTagId?.data[0]?.name?.substr(1));
    setTagdata(getTagByTagId?.data);
    setMediaPackage(getSourceByTagId?.data?.media);
    setFacilityPackage(getSourceByTagId?.data?.facility);
    setPropertyText(getSourceByTagId?.data?.text);
    setUnitsData(getUnitsBytagId?.data);
    setNewData(getUnitsBytagId?.data);
    setOriginalTagData(getUnitsBytagId?.data);

    setDocumentPackage(getSourceByTagId?.data?.document);
    setSigningPackage(getSourceByTagId?.data?.signing);
  }, [getTagByTagId, getSourceByTagId, getUnitsBytagId]);

  const handleUpdateTag = async () => {
    const updatePayloadData = {
      tag_id: tagId.toString(),
      name: `#${tagName}`,
      media_package_id: mediaPackage?.map((item) => item?.media_package_id),
      facility_package_id: facilityPackage?.map(
        (item) => item?.facility_package_id
      ),
      property_text_id: propertyText?.map((item) => item?.property_text_id),
      document_package_id: documentPackage
        .concat(signingPackage)
        ?.map((item) => item?.document_package_id),
      unit_id: unitsData?.map((item) => item?.unit_id),
    };
    let id;
    if (!PutTagUpdateTagIsLoading) {
      id = toast.loading("Tag Upadted...");
    }
    try {
      const response = await PutTagUpdateTag(updatePayloadData);

      if (response.status == status.s && response.data.bReturn) {
        toast.update(id, {
          render: response?.data?.message,
          isLoading: false,
          type: status.s,
          autoClose: 2000,
        });
        setShowEditTag(false);
      } else {
        toast.update(id, {
          render: response?.data?.message,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.update(id, {
        render: error?.message,
        isLoading: false,
        type: status.e,
        autoClose: 2000,
      });
    }

    // const response = await PutTagUpdateTag(updatePayloadData);
    // if (response.status == status.s) {
    //   toast.success(response.data.message);
    //   setShowEditTag(false);
    // }
  };

  const SourceData = [
    {
      Id: 0,
      Text: "All",
      length: 100,
    },
    {
      Id: 1,
      Text: "Media",
      length: mediaPackage?.length,
    },
    {
      Id: 2,
      Text: "Facility",
      length: facilityPackage?.length,
    },
    {
      Id: 3,
      Text: "Text",
      length: propertyText?.length,
    },
    {
      Id: 4,
      Text: "Document",
      length: documentPackage?.length,
    },
    {
      Id: 5,
      Text: "Signing",
      length: signingPackage?.length,
    },
  ];
  const SourceChange = (e) => {
    setSourceFilter(e.label);
  };
  //------Sources Page---------//
  const Sources = () => {
    return (
      <>
        <div className="px-4 my-2 form-label-title">
          {isLoading ? (
            <div
              className="placeholder col-3"
              style={{ height: "40px" }}
            ></div>
          ) : (
            "Sources"
          )}
        </div>

        <div className="px-4 py-2">
          <Row>
            <Col
              xl={4}
              lg={5}
            >
              <ReactSelect
                placeholder={"Sources"}
                clearable={false}
                value={{ value: 10, label: sourceFilter }}
                onChange={(e) => SourceChange(e)}
                options={SourceData?.map((item) => ({
                  value: item?.Id,
                  label: item?.Text,
                  isDisabled: SourceData.some((i) => item?.length === 0),
                }))}
              />
            </Col>
          </Row>
        </div>

        <div className="pt-2 pb-5">
          {mediaPackage?.length !== 0 &&
            (sourceFilter === "Media" || sourceFilter === "All") && (
              <>
                <hr className="mb-2" />
                <div className="form-label-title px-4 pt-2 pb-3">Media</div>
              </>
            )}

          {mediaPackage?.length !== 0 &&
            (sourceFilter === "Media" || sourceFilter === "All") && (
              <Row className="px-4 ">
                {mediaPackage?.map((data, index) => (
                  <>
                    {isLoading ? (
                      <div
                        className="placeholder col-3 radius-8 ms-5"
                        style={{ height: "275px" }}
                      ></div>
                    ) : (
                      <Col
                        lg={4}
                        key={index}
                        className="pb-4"
                      >
                        <Media
                          data={data}
                          setMediaPackage={setMediaPackage}
                          mediaPackage={mediaPackage}
                        />
                      </Col>
                    )}
                  </>
                ))}
              </Row>
            )}

          {facilityPackage?.length !== 0 &&
            (sourceFilter === "Facility" || sourceFilter === "All") && (
              <>
                <hr className="mb-2" />
                <div className="form-label-title px-4 py-2">Facilities</div>
              </>
            )}

          {facilityPackage?.length !== 0 &&
            (sourceFilter === "Facility" || sourceFilter === "All") && (
              <Row className="px-4 ">
                {facilityPackage?.map((data, index) => (
                  <>
                    {isLoading ? (
                      <div
                        className="placeholder col-3 radius-8 ms-5"
                        style={{ height: "275px" }}
                      ></div>
                    ) : (
                      <Col
                        lg={4}
                        key={index}
                        className="pb-4"
                      >
                        <FacilitiesCard
                          data={data}
                          setFacilityPackage={setFacilityPackage}
                          facilityPackage={facilityPackage}
                        />
                      </Col>
                    )}
                  </>
                ))}
              </Row>
            )}

          {propertyText?.length !== 0 &&
            (sourceFilter === "Text" || sourceFilter === "All") && (
              <>
                <hr className="mb-2" />
                <div className="form-label-title px-4 pt-2 pb-3">Text</div>
              </>
            )}
          {propertyText?.length !== 0 &&
            (sourceFilter === "Text" || sourceFilter === "All") && (
              <Row className="px-4 ">
                {propertyText?.map((data, index) => (
                  <>
                    {isLoading ? (
                      <div
                        className="placeholder col-3 radius-8 ms-5 "
                        style={{ height: "275px" }}
                      ></div>
                    ) : (
                      <Col
                        lg={4}
                        key={index}
                        className="pb-4"
                      >
                        <TextCard
                          data={data}
                          setPropertyText={setPropertyText}
                          propertyText={propertyText}
                        />
                      </Col>
                    )}
                  </>
                ))}
              </Row>
            )}

          {documentPackage?.length !== 0 &&
            (sourceFilter === "Document" || sourceFilter === "All") && (
              <>
                <hr className="mb-2" />
                <div className="form-label-title px-4 pt-2 pb-3">Document</div>
              </>
            )}

          {documentPackage?.length !== 0 &&
            (sourceFilter === "Document" || sourceFilter === "All") && (
              <Row className="px-4 ">
                {documentPackage?.map((data, index) => (
                  <>
                    {isLoading ? (
                      <div
                        className="placeholder col-3 radius-8 ms-5 "
                        style={{ height: "275px" }}
                      ></div>
                    ) : (
                      <Col
                        lg={4}
                        key={index}
                        className="pb-4"
                      >
                        <Document
                          data={data}
                          documentPackage={documentPackage}
                          setDocumentPackage={setDocumentPackage}
                        />
                      </Col>
                    )}
                  </>
                ))}
              </Row>
            )}

          {signingPackage?.length !== 0 &&
            (sourceFilter === "Signing" || sourceFilter === "All") && (
              <>
                <hr className="mb-2" />
                <div className="form-label-title px-4 pt-2 pb-3">Signing</div>
              </>
            )}
          {signingPackage?.length !== 0 &&
            (sourceFilter === "Signing" || sourceFilter === "All") && (
              <Row className="px-4 ">
                {signingPackage?.map((data, index) => (
                  <>
                    {isLoading ? (
                      <div
                        className="placeholder col-3 radius-8 ms-5 "
                        style={{ height: "275px" }}
                      ></div>
                    ) : (
                      <Col
                        lg={4}
                        key={index}
                        className="pb-4"
                      >
                        <Signing
                          data={data}
                          signingPackage={signingPackage}
                          setSigningPackage={setSigningPackage}
                        />
                      </Col>
                    )}
                  </>
                ))}
              </Row>
            )}
        </div>
        {/* </> */}
        {/* // ) : (
          //   ""
          // )} */}

        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          {isLoading ? (
            <div
              className="placeholder col-10 radius-8"
              style={{ height: "35px", width: "100px" }}
            ></div>
          ) : (
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => setShowEditTag(false)}
            />
          )}
          {isLoading ? (
            <div
              className="placeholder col-10 radius-8"
              style={{ height: "35px", width: "100px" }}
            ></div>
          ) : (
            <ETButton
              variant={"primary"}
              buttonText="NEXT"
              onClick={() => {
                setSelectedIndex(1);
              }}
            />
          )}
        </div>
      </>
    );
  };

  const EditPages = [
    {
      Id: "1",
      tab: "Sources",
      component: (
        <Sources
          setSelectedIndex={setSelectedIndex}
          mediaPackage={mediaPackage}
        />
      ),
    },
    {
      Id: "2",
      tab: "Units",
      component: (
        <EditPropertyUnitsTable
          isLoading={isLoading}
          addMoreUnits={addMoreUnits}
          setAddMoreUnits={setAddMoreUnits}
          setSelectedIndex={setSelectedIndex}
          unitsData={unitsData}
          setUnitsData={setUnitsData}
          getUnitsByPropertyid={getUnitsByPropertyid?.data}
          newData={newData}
          originalTagData={originalTagData}
        />
      ),
    },
    {
      Id: "3",
      tab: "Tag name",
      component: (
        <EditTagName
          isLoading={isLoading}
          setSelectedIndex={setSelectedIndex}
          tagName={tagName}
          setTagName={setTagName}
          handleUpdateTag={handleUpdateTag}
          disableDuplicateButton={PutTagUpdateTagIsLoading}
        />
      ),
    },
  ];

  console.log("TD", tagdata);
  const { userDetails } = useUserDetails((state) => state);
  const { convertedDate: createdOn } = UTCtoLocalTimeZone({
    timestamp: tagdata?.[0]?.created_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });
  const { convertedDate: modifiedOn } = UTCtoLocalTimeZone({
    timestamp: tagdata?.[0]?.modified_on,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD. MMM. YYYY | HH:mm:ss",
  });

  return (
    <>
      <Container
        fluid
        style={{ backgroundColor: "#F9FAFA" }}
        className="px-0 pt-3 "
      >
        <H1>Settings</H1>
        <div className="mb-4 light-font-text">
          <Placeholder animation="glow">
            <Link
              to={"/properties"}
              style={{ textDecoration: "none", color: "black" }}
            >
              <span>Property</span>
            </Link>
            <span className="mx-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <Link
              to="/property-units/overview"
              state={{ Tab: "Overview", propId: propertyId }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <span>{propOverview[0]?.propertyName}</span>
            </Link>

            <span className="mx-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <span
              className="cursor-pointer"
              onClick={() => setShowEditTag(false)}
            >
              Settings
            </span>
            <span className="mx-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <span>Tags</span>
            <span className="mx-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <GreenSpan>Edit tag</GreenSpan>
          </Placeholder>
        </div>
        <div className="p-4 containerStyle">
          <Row
            className="m-1 "
            style={{ backgroundColor: "#fff", borderRadius: "8px" }}
          >
            <Placeholder
              animation="glow"
              className="px-0"
            >
              <div className="ps-4 pt-3 d-flex">
                {isLoading ? (
                  <div
                    className="placeholder col-2 mb-1 "
                    style={{ height: "25px" }}
                  ></div>
                ) : (
                  <div className=" d-flex align-items-center">
                    <H2 className="me-2">Edit tag</H2>
                    <CustomToolTip
                      size="sm"
                      text="Facilities"
                    />
                  </div>
                )}
              </div>

              <div className="ps-4  created-by">
                {modifiedData?.map((item) => (
                  <div>
                    {item.modified_by && item.modified_on ? (
                      <div>
                        Last updated by {item?.modified_by},{modifiedOn}
                      </div>
                    ) : (
                      <>
                        {item?.created_by && item?.created_on ? (
                          <div>
                            Created by {item?.created_by},{createdOn}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>

              <div className="px-0">
                <Tabs
                  selectedIndex={selectedIndex}
                  className=" d-flex flex-column  mt-3  "
                >
                  <TabList
                    className=" d-flex  ps-0 light"
                    style={{ gap: "7px" }}
                  >
                    <div
                      className="d-flex justify-content-between px-4 py-3 mb-1"
                      style={{
                        width: "100%",
                        borderTop: "1px solid #F0F0F0",
                        borderBottom: "1px solid #F0F0F0",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        className="d-flex"
                        style={{ gap: "8px" }}
                      >
                        {isLoading ? (
                          <div className="d-flex gap-1">
                            <div
                              className="placeholder col-10 radius-8"
                              style={{ height: "35px", width: "100px" }}
                            ></div>
                            <div
                              className="placeholder col-10 radius-8"
                              style={{ height: "35px", width: "100px" }}
                            ></div>
                            <div
                              className="placeholder col-10 radius-8"
                              style={{ height: "35px", width: "100px" }}
                            ></div>
                          </div>
                        ) : (
                          <>
                            {EditPages.map((page, index) => (
                              <Tab
                                onClick={() => {
                                  setSelectedIndex(index);
                                }}
                              >
                                {page?.tab}
                              </Tab>
                            ))}
                          </>
                        )}
                      </div>
                      <div>
                        {selectedIndex === 1 && (
                          <ETButton
                            buttonText="ADD MORE UNITS"
                            variant={"primary"}
                            icon={true}
                            width="fit-content"
                            onClick={() => {
                              setAddMoreUnits(true);
                            }}
                          />

                          // <ETButton
                          //   buttonText="ADD MORE SOURCES"
                          // variant={"primary"}
                          //   icon={true}
                          //   width="fit-content"
                          //   onClick={handleClick}
                          // />
                        )}
                      </div>
                    </div>
                  </TabList>
                  {EditPages.map((page, index) => (
                    <TabPanel Key={page?.Id}> {page?.component} </TabPanel>
                  ))}
                </Tabs>
              </div>
            </Placeholder>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default EditTag;
