import { useDropzone } from "react-dropzone";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { DropZoneBorder, GreenSpan } from "../../../styles/Common.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MRTTheme from "../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";

import Menus from "../../../Components/Menus/Menus";
import Toggle from "../../../Components/Switch/Switch";
import { SortIcon } from "../../../Components/table/MRTGlobalProps";

const Pricelist = () => {

  const [documentData, setDocumentData] = useState([])
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    uploadDocumentData(acceptedFiles);
    fileRejections.map(({ file, errors }) => {
      toast.error("Invalid file type " + file.name);
    });
  }, []);
  
  const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } =
  useDropzone({
    // Disable click and keydown behavior
    onDrop,
    noClick: true,
    noKeyboard: true,
    // accept: {
    //   "image/jpeg": [],
    //   "image/png": [],
    // },
    // onDrop: (acceptedFiles) => {
    //   // const oldData = [...files];
    //   // const addedData = [acceptedFiles];
    //   // const newArray = [...oldData, acceptedFiles];
    //   // console.log(newArray, "newArraynewArray");
    //   setFiles(
    //     acceptedFiles.map((file) =>
    //       Object.assign(file, {
    //         preview: URL.createObjectURL(file),
    //       })
    //     )
    //   );
    // },
  });

  const columns = useMemo(() => [
    {
      id: 'no.',
      header: 'index',
      size: 180,
      accessorKey: "index",
      enableHiding: false,
      // size: 20,
      Cell: ({ row }) => (
        <>
        <span>{row?.index + 1}</span>
        </>
      )
    },
    {
      accessorKey: "name",
      size: 300,
      header: "File name",
      enableGlobalFilter: true,
      Cell: ({ renderedCellValue, row }) => {
        let clicked = false;
        return (
          <>
          {
            !clicked ? <span>{ renderedCellValue }</span> 
            : <input className="border border-0 bg-transparent border-0-focus" type="text" defaultValue={ renderedCellValue }></input>
          }
          </>
        )
      }
    },
    {
      accessorKey: "publish_visible",
      // size: 350,
      header: "Publish",
      enableGlobalFilter: true,
      Cell: ({row}) => (
        <span><Toggle id={`eido-project-pricelist-publish-${row?.index + 1}`} /></span>
      )
    }
  ]);

  const uploadDocumentData = (acceptedFiles) => {
    // console.log(acceptedFiles, 'acceptedFiles')
    acceptedFiles.forEach((file) => {
      setDocumentData((prev) => [...prev, file]);
    })
    // acceptedFiles.map(file => ())
    // for (let i = 0; i < acceptedFiles?.length; i++) {
    //   let a = [...documentData, ...acceptedFiles[i]]
    //   console.log(a, 'aaaa')
    //   setDocumentData([...documentData, ...a])
    // }
  }

  return (
    <div className="document-container p-4 my-2">
      {/* {console.log(documentData, 'name')} */}
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          fontSize: "14px",
          border: "1px solid #F0F0F0",
        }}
      >
        <div
          className="px-4 pb-3"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div style={{ fontFamily: "Cerebri Sans" }} className="pt-3 d-flex">
            <div className="fw-600 fs-14">Pricelist</div>
            <div style={{ paddingLeft: "15px" }}>
              <CustomToolTip height={12} text="Price" />
            </div>
          </div>
        </div>
        {  console.log(documentData, 'file')}
        <div className="document-content py-3 px-4">
        <div className="pt-1 px-3 mx-1 pb-3">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <DropZoneBorder className=" py-5 d-flex flex-column justify-content-center align-items-center ">
                <div>
                  {/* <File /> */}
                  <FontAwesomeIcon
                    size="xl"
                    icon="fa-solid fa-file-image"
                    style={{ color: "#000000" }}
                  />
                </div>
                <div className="mt-2 mb-1 form-label-title">
                  Drop your file here, or&nbsp;
                  <GreenSpan
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={open}
                  >
                    browse your computer
                  </GreenSpan>
                </div>
                {/* <div className="fs-12 light-font-text grey-text">
                  Supports only JPG and PNG
                </div> */}
              </DropZoneBorder>
            </div>
            {/* <aside style={thumbsContainer}>{thumbs}</aside> */}
          </div>
        </div>
      </div>
      <div className="mt-3 pt-1">
        <MRTTheme
          colorVariantsForTableData={["odd"]}
          table={
            <MaterialReactTable
              columns={columns}
              data={documentData}
              icons={{...SortIcon}}
              enableRowActions
              positionActionsColumn="last"
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "",
                },
                // "mrt-row-select": {
                //   header: "",
                // },
              }}
              // renderRowActions={({ row }) => (
              //   <Menus
              //     itemheight={23}
              //     itemwidth={23}
              //     iconborderradius="4px"
              //     itemcontainerwidth="120px"
              //     anchorOrigin={{
              //       vertical: "bottom",
              //       horizontal: "right",
              //     }}
              //     transformOrigin={{
              //       vertical: "top",
              //       horizontal: "right",
              //     }}
              //     component={
              //       <>
              //         {/* <div style={{ fontSize: "14px" }}>
              //           <div
              //             onClick={() =>
              //               handleEditPromotionLabel(
              //                 row?.original?.promotion_label_id
              //               )
              //             }
              //             className="addpropContent px-3 py-2 "
              //           >
              //             <span className="ms-1">Edit</span>
              //           </div>
              //           <hr className="addpropertyHr m-0"></hr>
              //           <div
              //             className="addpropContent px-3 pt-2 pb-2 "
              //             onClick={() =>
              //               handleDelete(
              //                 row.original?.promotion_label_id,
              //                 row.original?.unit_count,
              //                 row?.original?.promotion_label_configuration_count
              //               )
              //             }
              //           >
              //             <span className="ms-1">Delete</span>
              //           </div>
              //         </div> */}
              //       </>
              //     }
              //   />
              // )}
              // getRowId={(originalRow) => originalRow?.facility_id}
              // onRowSelectionChange={setRowSelection}
              // state={{ rowSelection }}
              enableTopToolbar={false}
              enableSelectAll={false}
              enableColumnActions={false}
              enableColumnFilter={false}
              enableResizing={false}
              initialState={{ showGlobalFilter: true }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },

              }}
              // muiTableHeadRowProps={{
              //   sx: {
              //     ...TableRowsStyle,
              //   },
              // }}
              enableColumnFilterModes={false}
            />
          }
        />
      </div>
    </div>
  );
};

export default Pricelist;
