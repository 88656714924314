import React, { useState, useEffect } from "react";
import { Container, Placeholder } from "react-bootstrap";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateMediaPackages from "../../media/CreateMediaPackages";
import "./units.css";
import CreateNewTextField from "../../text/CreateNewTextField";
import EditTag from "../../settings/Tags/editTag/EditTag";
import { AddDocumentPackages } from "../../documents/documentPackages/AddDocumentPackages";
import { MemoizedAddNewPackage } from "../../facilities/AddNewPackage1";
import { AddSigningPackages } from "../../documents/signingPackages/AddSigningPackages";
import { useRouting } from "../../../hooks/stores/useRoutingStore";
import AddNewSigner from "../../signing/AddNewSigner";
import { NavLink } from "../../../Components/Tabs/ReactTabs.style";
import PropertyOpenHouse from "../../events/PropertyOpenHouse";
import FollowUpPropertyOpenHouse from "../../events/followUpEvent/FollowUpPropertyOpenHouse";
import PrivateEventCommonScreen from "../../events/privateEvent/PrivateEventCommonScreen";
import CreateNewPrivateEvent from "../../events/privateEvent/CreateNewPrivateEvent";
import { PropertyTabs } from "../../../../src/model/Constant";
import { GreenSpan, H1 } from "../../../styles/Common.style";

const Units = ({
  propertyOverview,
  tagId,
  showEditTag, // Tags -> edit tag state
  setShowEditTag, // Tags -> edit tag state
  showOpenHouseProperty,
  setShowOpenHousePropertyFollowUp,
  showOpenHousePropertyFollowUp,
  showPrivateEvent,
  setShowPrivateEvent,
  setShowOpenHouseProperty,
  showCreateNewPrivateEvent,
  setShowCreatePrivateEvent,
  Breadcrumbs,
}) => {
  // zustand
  const {
    packageType,
    setPackageType,
    showPackage,
    setSelectedSubTab,
    selectedMainTab,
    setSelectedMainTab,
  } = useRouting((state) => state);

  let location = useLocation();
  const { state } = location;
  let propertyId = state?.propId;
  let TabId = state?.Tab;
  // Get the current pathname from the location object
  // Split the pathname using '/' as a delimiter and get the last part

  //---------------states-----------------//
  const [selectedTab, setSelectedTab] = useState(null);

  useEffect(() => {
    const activePath = window.location.pathname;
    if (
      activePath === "/property-units/units/residential" ||
      activePath === "/property-units/units/parking-lot" ||
      activePath === "/property-units/units/storage-room"
    ) {
      setSelectedTab("Units");
    } else if (
      activePath === "/property-units/settings/tag" ||
      activePath === "/property-units/settings/promotion-label" ||
      activePath === "/property-units/settings/integrations" ||
      activePath === "/property-units/settings/screening" ||
      activePath === "/property-units/settings/signing" ||
      activePath === "/property-units/settings/configuration"
    ) {
      setSelectedTab("Settings");
    } else {
      // For other cases, you can capitalize the first letter of the last part of the path
      const pathParts = activePath.split("/");
      const lastPathPart = pathParts[pathParts.length - 1];
      setSelectedTab(
        lastPathPart.charAt(0).toUpperCase() + lastPathPart.slice(1)
      );
    }
  }, []);

  useEffect(() => {}, [state?.propId, TabId]);

  const handleHeading = (tab, Id) => {
    setSelectedTab(tab);
    setPackageType(Id); //zustand
    // selectTab(true)
    setSelectedSubTab(false);
    setSelectedMainTab(false);
  };
  useEffect(() => {
    if (selectedMainTab === true) {
      setSelectedTab("Units");
    }
  }, [selectedMainTab]);

  // const location = useLocation();
  //  this code used to transfer property unit page to property page
  const navigate = useNavigate();
  const navigateToProperty = () => {
    navigate("/properties");
  };

  return (
    <>
      <Container
        fluid
        style={{ backgroundColor: "#F9FAFA" }}
        className="units-tabs-main"
      >
        <>
          {showPackage && packageType === "5" ? (
            <CreateMediaPackages />
          ) : showPackage && packageType === "8" ? (
            <CreateNewTextField />
          ) : // <Package packageType={packageType} />
          showPackage && packageType === "6" ? (
            <>
              <AddDocumentPackages />
            </>
          ) : showPackage && packageType === "Signing" ? (
            <>
              <AddSigningPackages propertyOverview={propertyOverview} />
            </>
          ) : showEditTag ? (
            <EditTag
              setShowEditTag={setShowEditTag}
              tagId={tagId}
              propertyId={propertyId}
            />
          ) : showPackage && packageType === "4" ? (
            <MemoizedAddNewPackage />
          ) : showCreateNewPrivateEvent ? (
            <CreateNewPrivateEvent
              setShowCreatePrivateEvent={setShowCreatePrivateEvent}
              propertyOverview={propertyOverview}
            />
          ) : showPrivateEvent ? (
            <PrivateEventCommonScreen
              setShowPrivateEvent={setShowPrivateEvent}
            />
          ) : showOpenHousePropertyFollowUp ? (
            <FollowUpPropertyOpenHouse
              propertyOverview={propertyOverview}
              propertyId={propertyId}
              setShowOpenHousePropertyFollowUp={
                setShowOpenHousePropertyFollowUp
              }
            />
          ) : showOpenHouseProperty ? (
            <PropertyOpenHouse
              propertyOverview={propertyOverview}
              setShowOpenHouseProperty={setShowOpenHouseProperty}
              propertyId={propertyId}
            />
          ) : showPackage && packageType === "9" ? (
            <AddNewSigner />
          ) : (
            <>
              <div>
                <div className="pt-40 ">
                  {/* below code used for showing create screening form */}

                  {!window.location.pathname?.includes(
                    "create-screening-form"
                  ) && (
                    <Placeholder animation="glow">
                      <H1>{/* {TabId ? TabId : selectedTab} */}Property</H1>
                      <div
                        style={{
                          fontFamily: " Cerebri sans-light",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        className="pt-2"
                      >
                        <span className="me-2 " onClick={navigateToProperty}>
                          Property
                        </span>
                        <span className="me-2">
                          <FontAwesomeIcon
                            size="sm"
                            icon="fa-regular fa-angle-right"
                            style={{ color: "#000000" }}
                          />{" "}
                        </span>
                        <Link
                          to="/property-units/overview"
                          state={{ Tab: "Overview", propId: state?.propId }}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <span className="me-2">
                            {propertyOverview?.[0]?.propertyName
                              ? propertyOverview?.[0]?.propertyName
                              : "propertyName"}
                          </span>
                        </Link>
                        <span className="me-2">
                          <FontAwesomeIcon
                            size="sm"
                            icon="fa-regular fa-angle-right"
                            style={{ color: "#000000" }}
                          />{" "}
                        </span>
                        <GreenSpan>{Breadcrumbs}</GreenSpan>
                      </div>
                      <div className="d-flex py-3" style={{ flexWrap: "wrap" }}>
                        <>
                          {PropertyTabs?.map((item, i) => (
                            <NavLink
                              key={i}
                              state={{ propId: propertyId }}
                              to={item.link}
                              style={{ textDecoration: "none" }}
                              onClick={() => {
                                handleHeading(item?.tab);
                              }}
                              className=" me-3"
                            >
                              {item?.tab}
                            </NavLink>
                          ))}
                        </>
                        {/* )} */}
                      </div>
                    </Placeholder>
                  )}
                </div>
              </div>
              <Outlet />
            </>
          )}
        </>
      </Container>
    </>
  );
};

export default Units;

// Tabs Index Value

// const PropertyTabs = [
//   {
//     Id: "1",
//     tab: "Overview",
//     link: "overview",
//   },
//   {
//     Id: "2",
//     tab: "Units",
//     link: "units",
//   },
//   {
//     Id: "3",
//     tab: "Details",
//     link: "details",
//   },
//   {
//     Id: "4",
//     tab: "Facilities",
//     link: "facilities",
//   },
//   {
//     Id: "5",
//     tab: "Media",
//     link: "media",
//   },
//   {
//     Id: "6",
//     tab: "Documents",
//     link: "documents",
//   },
//   {
//     Id: "7",
//     tab: "Events",
//     link: "events",
//   },
//   {
//     Id: "8",
//     tab: "Texts",

//     link: "texts",
//   },
//   {
//     Id: "9",
//     tab: "Signers",
//     link: "signers",
//   },
//   {
//     Id: "10",
//     tab: "Settings",
//     link: "settings",
//   },
// ];
