// Created by : Suraj
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import "./card.css";
import Menus from "../Menus/Menus";
import ETButton from "../Buttons/ETButton";
import MRTTheme from "../table/MRTTheme";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import { Col, Dropdown, Row } from "react-bootstrap";
import { H2 } from "../../styles/Common.style";
import CustomPopup from "../CustomPopup";
import {
  SearchIcon,
  SearchStyle,
  TableRowsStyle,
  paginationoptions,
  SortIcon,
} from "../table/MRTGlobalProps";
import ScreeningFilterDropdown from "../../views/settings/screening/ScreeningFilterDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetUnitsByPropertyIdUpdated } from "../../hooks/queries/useUnitQueries";
import { usePropertyId } from "../../hooks/stores/usePropertyStore";
import {
  useGetScreeningLinkedUnit,
  useUpdateScreeningFormUnits,
} from "../../hooks/queries/useScreeningQueries";
import { useScreening } from "../../hooks/stores/useScreeningStore";
import { toast } from "react-toastify";
import { status } from "../../model/Constant";
import { useNavigate } from "react-router-dom";
const ScreeningFormsCard = ({
  data,
  setChangeForm,
  setArchive,
  setPublish,
  setScreeningFormId,
  setFormStatusId,
  setCancelActiveScreening,
  setLinkedUnitData,
  LinkedUnitData,
}) => {
  const propertyId = usePropertyId((state) => state.propertyId);
  const {
    screeningFormId,
    formStatusId,
    setEditScreeningForm,
    EditscreeningForm,
  } = useScreening((state) => state);
  const {
    data: getScreeningLinkedUnitData,
    refetch: getScreeningLinkedUnitDataDataRefetch,
  } = useGetScreeningLinkedUnit(screeningFormId, propertyId);

  const { mutateAsync: updateScreeningFormUnitseMutate } =
    useUpdateScreeningFormUnits(screeningFormId, formStatusId);
  const [linkedUnits, setLinkedUnits] = useState(false);
  const [closeDropdown, setCloseDropdown] = useState(false);
  // const [LinkedUnitData, setLinkedUnitData] = useState([]);
  const [dlinkUnitId, setDlinkUnitId] = useState("");

  useEffect(() => {
    // setLinkedUnitData(getScreeningLinkedUnitData?.data);
    const unitId = getScreeningLinkedUnitData?.data?.map(
      (data) => data?.unit_id
    );
    setDlinkUnitId(unitId);
  }, [getScreeningLinkedUnitData]);

  const handlePopupOpen = () => {
    getScreeningLinkedUnitDataDataRefetch();
  };
  const navigate = useNavigate();

  const handleStartScreeningForm = () => {
    // setEditScreeningForm(true)
    // if (EditscreeningForm) {
    navigate("/property-units/create-screening-form");
    // }
  };
  const handleDelinkUnits = async () => {
    if (getScreeningLinkedUnitData?.data?.length == 0) {
      toast.warning("No units to delink.");
      return;
    }
    const DelinkUnits = {
      screening_form_id: screeningFormId,
      units: dlinkUnitId,
    };
    try {
      const response = await updateScreeningFormUnitseMutate(DelinkUnits);
      if (response?.status === "success" && response?.data?.bReturn) {
        toast.success(response?.data?.message);
        getScreeningLinkedUnitDataDataRefetch();
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const renderEmptyRowsFallback = () => {
    if (LinkedUnitData && LinkedUnitData?.length === 0) {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div>You do not have any linked unit yet.</div>
          </>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div style={{ fontSize: "17px" }}>Sorry! No result found:(</div>
          </>
        </div>
      );
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "unit_id",
        header: "No.",
        size: 100,
        // muiTableHeadCellProps: {
        // 	sx: {
        // 		paddingLeft: "0px !important",
        // 	},
        // },
        // muiTableBodyCellProps: {
        // 	sx: {
        // 		paddingLeft: "0px !important",
        // 	},
        // },
        // You can set the "Cell" property to customize the rendering of this column
        // Cell: ({ row }) => {
        // 	// "row" contains the data for the current row
        // 	return row.index + 1; // Adding 1 to start numbering from 1
        // },
      },
      {
        accessorKey: "address",
        header: "Address",
        size: 180,
      },
      {
        accessorKey: "housing_type_name", //normal accessorKey
        header: "Type",
        size: 100,
      },
      {
        accessorKey: "alt_unit_id", //normal accessorKey
        header: "Unit no.",
        size: 80,
      },
      {
        accessorKey: "floor_number",
        header: "Floor",
        size: 50,
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorFn: (row) => `${row.area} ${row.square_measure_unit}`,
        size: 80,
        // accessorKey: "available",
        header: "m2",
        Cell: ({ cell, row }) => {
          return (
            <div>
              {row.original.area}
              <span
                dangerouslySetInnerHTML={{
                  __html: row.original.square_measure_unit,
                }}
              ></span>
            </div>
          );
        },
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "no_of_rooms",
        header: "Room",
        size: 80,
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },

      {
        accessorFn: (row) => `${row.rent} ${row.currency_id}`,
        header: "Price",
        size: 110,
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      style={{
        backgroundColor: "#FFF",
        color: "#000",
        width: "100%",
        display: "flex",
        height: "41px",
        border: "1px solid #D1D1D1",
        fontWeight: "300",
        justifyContent: "space-between",
        alignItems: "center",
        textDecoration: "none",
        fontSize: "14px",
        borderRadius: "8px",
      }}
      className="px-2 py-2 dropdownInput "
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        setCloseDropdown(!closeDropdown);
        // console.log(e, "Eeeeeeeeeeee");
      }}
    >
      <div className="d-flex justify-content-between w-100">
        <span> {children}</span>
        <span>
          <FontAwesomeIcon
            icon="fa-regular fa-chevron-down"
            size="sm"
            style={{ color: "#000000" }}
          />
        </span>
      </div>
    </a>
  ));

  return (
    <CardsDiv>
      {/* white window */}
      <div style={cardSection}>
        <div style={detailOverviewContainer} className="p-3">
          {/*Approved section  */}
          <div className="d-flex align-items-center gap-2 pb-3">
            <div
              className="screening-card-status-color"
              style={{
                backgroundColor: "#228B22",
              }}
            ></div>
            <div className="light-font-text fs-13">
              {data?.approved} - Approved
            </div>
          </div>

          {/*Completed section  */}
          <div className="d-flex align-items-center gap-2 pb-3">
            <div
              className="screening-card-status-color"
              style={{
                backgroundColor: "#778FA8",
              }}
            ></div>
            <div className="light-font-text fs-13">
              {data?.completed} - Completed
            </div>
          </div>

          {/*Declined section  */}
          <div className="d-flex align-items-center gap-2 pb-3">
            <div
              className="screening-card-status-color"
              style={{
                backgroundColor: "#FFDF00",
              }}
            ></div>
            <div className="light-font-text fs-13">
              {data?.declined} - Declined
            </div>
          </div>

          {/*Cancelled section  */}
          <div className="d-flex align-items-center gap-2 pb-3">
            <div
              className="screening-card-status-color"
              style={{
                backgroundColor: "#AA1518",
              }}
            ></div>
            <div className="light-font-text fs-13">
              {data?.cancelled} - Cancelled
            </div>
          </div>
        </div>
      </div>

      {/*Screening name  */}
      <div style={cardSection}>
        <div className="form-label-title fs-16 pt-1">
          Screening - {data?.screening_name}
        </div>
      </div>

      {/* Status section */}
      <div style={cardSection}>
        <div className="p-1 d-flex gap-3 align-items-center">
          <div className="form-label-title">Status</div>
          <div>
            <span
              className="fs-12 form-label-title white-text  radius-4"
              style={{
                backgroundColor:
                  data.form_status_name == "Published"
                    ? "#349032"
                    : data?.form_status_name == "Not Published"
                    ? "#AA1518"
                    : "#888888",
                height: "19px",
                padding: "2px 6px 2px 6px",
                color: "#FFF",
                textTransform: "uppercase",
              }}
            >
              {data?.form_status_name}
            </span>
          </div>
        </div>
      </div>

      {/* Source */}
      <div className="fs-14  px-4 pt-3 gap-3">
        <div className="form-label-title">Source</div>
        <div className="light-font-text">{data?.source}</div>
      </div>

      <div className=" ps-4 pe-3 py-3 ">
        {/* Amount of units */}
        <div className="fs-14 pb-1">
          <div className="form-label-title">Amount of units</div>
          <div className="light-font-text">
            {data?.total_unit}
            {data?.total_unit == 1 || data?.total_unit == 0 ? "unit" : "units"}
          </div>
        </div>

        {/* Menu options */}
        <div className="d-flex align-items-end justify-content-end">
          <Menus
            itemheight={23}
            itemwidth={23}
            iconborderradius="4px"
            itemcontainerheight="100%"
            component={
              <>
                <div className="fs-14">
                  <div className="addpropContent px-3 py-2 light-font-text">
                    <span className="ms-1">Preview screening form</span>
                  </div>
                  {data?.form_status_name !== "Published" && (
                    <>
                      <hr className="m-0"></hr>
                      <div
                        className="addpropContent px-3 py-2"
                        onClick={() => {
                          setPublish(true);
                          setScreeningFormId(data?.screening_form_id);
                          setFormStatusId(2);
                        }}
                      >
                        <span className="ms-1">Publish screening form</span>
                      </div>
                    </>
                  )}

                  <hr className=" m-0"></hr>
                  <div className="addpropContent px-3 pt-2 pb-2 ">
                    <span
                      className="ms-1"
                      onClick={() => {
                        handleStartScreeningForm();
                        setEditScreeningForm(true);
                        setScreeningFormId(data?.screening_form_id);
                      }}
                    >
                      Edit screening form
                    </span>
                  </div>
                  <hr className="m-0"></hr>
                  <div
                    className="addpropContent px-3 pt-2 pb-2 "
                    onClick={() => {
                      setLinkedUnits(true);
                      handlePopupOpen();
                      setScreeningFormId(data?.screening_form_id);
                    }}
                  >
                    <span className="ms-1">See linked units</span>
                  </div>
                  {data?.status !== "Archived" && (
                    <>
                      <hr className="m-0"></hr>
                      <div
                        className="addpropContent px-3 pt-2 pb-2 "
                        onClick={() => {
                          data?.on_going > 0 || data?.not_yet_started > 0
                            ? setCancelActiveScreening(true)
                            : setArchive(true);

                          setScreeningFormId(data?.screening_form_id);
                          setFormStatusId(4);
                        }}
                      >
                        <span className="ms-1">Archive screening form</span>
                      </div>
                    </>
                  )}
                </div>
              </>
            }
          />
        </div>
        <div>
          <CustomPopup
            size="xl"
            open={linkedUnits}
            onHide={() => setLinkedUnits(false)}
            title="Link units"
            titleFont="14px"
            title_color="black"
            titleTooltipText="Link units"
            titleTooltip={true}
            enableCrossButton={false}
            onCloseAction={() => {
              setLinkedUnits(false);
              alert("Action Peformed Successfuly.");
            }}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={true}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
          >
            <div>
              <div className="px-4 pt-3 d-flex align-items-center">
                <H2 className=" fs-14 pt-2 ">
                  {" "}
                  Units linked to the screening form
                </H2>{" "}
              </div>
              <div className="px-4 pt-3 light-font-text grey-text">
                Below are the units that are currently linked to this screening
                form. To remove or add units, click edit screening form.
              </div>
              <MRTTheme
                colorVariantsForTableData={["odd"]}
                table={
                  <MaterialReactTable
                    positionToolbarAlertBanner="bottom"
                    selectAllMode="all"
                    columns={columns}
                    data={LinkedUnitData ?? []}
                    icons={{ ...SortIcon }}
                    // enableRowSelection={row => row.original.is_linked_with_screening == 0}
                    // enableRowSelection
                    renderEmptyRowsFallback={renderEmptyRowsFallback}
                    getRowId={(row) => row.unit_id}
                    //   onRowSelectionChange={setRowSelection}
                    //   state={{ rowSelection }}
                    enableTopToolbar={true}
                    enableColumnActions={false}
                    initialState={{
                      showGlobalFilter: true,
                    }}
                    displayColumnDefOptions={{
                      "mrt-row-select": {
                        header: "",
                        size: 50,
                        muiTableBodyCellProps: {
                          sx: {
                            paddingRight: "0px !important",
                            border: "none !important",
                          },
                        },
                      },
                    }}
                    muiTableHeadRowProps={{
                      sx: {
                        ...TableRowsStyle,
                      },
                    }}
                    muiTablePaginationProps={{
                      ...paginationoptions,
                    }}
                    muiSearchTextFieldProps={{
                      ...SearchStyle,

                      InputProps: {
                        ...SearchIcon,
                      },
                    }}
                    renderTopToolbar={({ table }) => (
                      <>
                        <Row className=" py-2 px-4 align-items-center ">
                          <Col xl={2} md={4} className="my-1 pe-1 ">
                            <MRT_GlobalFilterTextField table={table} />
                          </Col>

                          <Col xl={2} md={4} className="my-1 pe-1">
                            <Dropdown show={closeDropdown}>
                              <Dropdown.Toggle
                                as={CustomToggle}
                                id="dropdown-custom-components"
                              >
                                Set filters
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="my-drop-menu">
                                <ScreeningFilterDropdown
                                  // propertag={propertag}
                                  // filteredTag={filteredTag}
                                  // setApplyBtn={setApplyBtn}
                                  // applyBtn={applyBtn}
                                  // matchingUnitsCount={matchingUnitsCount}
                                  // filterDataLength={filterDataLength}
                                  // // status={status}
                                  // // filteredTagdata={filteredTagdata}
                                  // matchingUnits={matchingUnits}
                                  // selectedValues={selectedValues}
                                  // screeningSection={screeningSection}
                                  // setUnitsTableData={setUnitsTableData}
                                  // multiPropsFilter={multiPropsFilter}
                                  // selectedDropdownData={selectedDropdownData}
                                  // dumnmyDropDownData={dumnmyDropDownData}
                                  // filterDropdowon={filterDropdowon}
                                  // // handleDropData={handleDropData}
                                  // unitsTableData={unitsTableData}
                                  // filterDropData={filterDropData}
                                  // setFilterDropData={setFilterDropData}
                                  // ChooseFilter={ChooseFilter}
                                  // handleFilterData={handleFilterData}
                                  // filter={filter}
                                  // selecteddata={selecteddata}
                                  // setFilter={setFilter}
                                  // handleChange={handleChange}
                                  // selectdata={selectdata}
                                  // filterKeys={filterKeys}
                                  // setFilterKeys={setFilterKeys}
                                  // closeDropdown={closeDropdown}
                                  // setCloseDropdown={setCloseDropdown}
                                  // setResetCondition={setResetCondition}
                                  className="my-drop-menu"
                                />
                              </Dropdown.Menu>
                            </Dropdown>
                          </Col>
                          {/* {selectedValues.length > 0 && (
                          <>
                            <Col className="fs-14 fw-300">
                              <div className="d-flex">
                                <div style={{ color: "#888888" }}>
                                  {selectedValues > 0
                                    ? "Selected filter:"
                                    : "Selected filters:"}{" "}
                                  <span className="fs-14">
                                    {selectedValues}
                                  </span>
                                </div>

                                <div
                                  className="ms-4"
                                  style={{ color: "#888888" }}
                                >
                                  Clear filter{" "}
                                  <FontAwesomeIcon
                                    onClick={() => {
                                      handleClearFilter();
                                      // setApplyBtn(false)
                                    }}
                                    className=" mx-1"
                                    icon="fa-solid fa-circle-minus"
                                    style={{
                                      color: "#888888",
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                           
                          </>
                        )} */}

                          {/* <Row>
                          <Col>
                            Selected filters:
                          </Col>
                          <Col>
                            Clear filter
                          </Col>
                        </Row> */}
                        </Row>
                      </>
                    )}
                  />
                }
              />
            </div>
            <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
              <ETButton
                variant={"white"}
                buttonText="Close"
                onClick={() => {
                  setLinkedUnits(false);
                }}
              />

              <ETButton
                variant={"secondary"}
                buttonText="Delink all units"
                onClick={() => {
                  handleDelinkUnits();
                }}
              />
            </div>
          </CustomPopup>
        </div>
      </div>
    </CardsDiv>
  );
};

export default ScreeningFormsCard;

const CardsDiv = styled.div`
  background-color: #f9fafa;
  border-color: #f0f0f0;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  ${(props) =>
    props.isActive &&
    `
  background-color:${props.theme.component.checkbox.primary.color + "11"};
   border-color: ${props.theme.component.checkbox.primary.color};
`}
`;
const cardSection = {
  padding: "20px",
  borderBottom: "1px solid #F0F0F0",
};

const detailOverviewContainer = {
  border: "1px solid #F0F0F0",
  borderRadius: "6px",
  fontSize: "13px",
  fontFamily: "Cerebri sans-light",
  background: "#FFFFFF",
  textAlign: "justify",
  height: "150px",
  lineHeight: "17px",
  position: "relative",
  width: "100%",
};
