// ui created by : Suraj and api done by : Suraj
import React, { useEffect, useState } from "react";
import ETButton from "../../../../Components/Buttons/ETButton";
import { Col, Form, Row } from "react-bootstrap";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import GroupText from "../../../../Components/GroupText/GroupText";
import SelectContractTable from "./SelectContractTable";
import { useUnitType } from "../../../../hooks/stores/useUnitStore";
import { useForm, Controller } from "react-hook-form";
import {
  useCreateParkingReservation,
  useCreateStorageReservation,
  useGetParkingStoragePaymentFrequency,
  useGetParkingStoragePaymentType,
  useGetSigningOrderByPropertyId,
} from "../../../../hooks/queries/useUnitQueries";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";
import { usePropertyId } from "../../../../hooks/stores/usePropertyStore";
import DropdownModalMui from "../../../../Components/DropdownModal/DropdownModalMui";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import { useGetActorByContractId } from "../../../../hooks/queries/useActorQueries";
import { toast } from "react-toastify";
import { status } from "../../../../model/Constant";
import { GetDocumentPackagesByPropertyId } from "../../../../services/Documents";
import { useQuery } from "react-query";
import { usePostCreateSigningOrder } from "../../../../hooks/queries/useSigningQueries";
import SigningPackageCard from "../../../actors/customers/signing/SigningPackageCard";
import { H2 } from "../../../../styles/Common.style";

const AddToContract = ({ setShowAddToContract, selectedContractData }) => {
  const [paymentFrequency, setPaymentFrequency] = useState("");
  const [allActivityChecked, setAllActivityChecked] = useState([]);
  const [checkedActorsName, setCheckedActorsName] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [signingOrderPage, setSigningOrderPage] = useState(false);
  const [selectedSigningPackageId, setSelectedSigningPackageId] = useState("");
  const [selectedUnitId, setSelectedUnitId] = useState("");

  const [monthlyPrice, setMonthlyPrice] = useState(0);

  //------------------------ state Zustand ----------------------------//
  const { typeOfUnit, setTypeOfUnit } = useUnitType((state) => state);
  const { propertyId } = usePropertyId((state) => state);

  //-------------------------- react queries --------------------------//

  // api for dropdown
  const { data: GetParkingStoragePaymentType } =
    useGetParkingStoragePaymentType();

  const { data: GetParkingStoragePaymentFrequency } =
    useGetParkingStoragePaymentFrequency();

  // api for select contract table
  const { data: GetSigningOrderByPropertyId } =
    useGetSigningOrderByPropertyId(propertyId);

  // api for Actors data
  const { data: actorByContractIdData } = useGetActorByContractId(
    Object.keys(rowSelection)
  );
  // post api for parking and storage
  const {
    mutateAsync: createParkingReservationMutate,
    isLoading: createParkingReservationLoading,
  } = useCreateParkingReservation();
  const {
    mutateAsync: createStorageReservationMutate,
    isLoading: createStorageReservationLoading,
  } = useCreateStorageReservation();

  // console.log(GetSigningOrderByPropertyId?.data, "GetSigningOrderByPropertyId");

  // signign api
  const {
    data: getDocumentPackagesByPropertyIdData,
    refetch: getDocumentPackagesByPropertyIdDataRefetch,
  } = useQuery(
    ["getDocumentPackageByPropertyId", propertyId, 2],
    () =>
      GetDocumentPackagesByPropertyId(
        propertyId,
        selectedUnitId,
        2,
        typeOfUnit
      ),
    {
      enabled: !!rowSelection,
      enabled: !!selectedUnitId,
    }
  );
  // console.log(selectedUnitId, "selectedUnitId");
  // console.log(rowSelection, "rowSelection");
  const {
    mutateAsync: PostCreateSigningOrder,
    isLoading: PostCreateSigningOrderIsLoading,
  } = usePostCreateSigningOrder();

  //----------------------- react hooks forms  ----------------------//
  const {
    register,
    handleSubmit,
    setValue,
    // getValues,
    control,
    watch,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({});

  // -------------------   on form submit   --------------------//
  const handleContractSave = async (data) => {
    // ------------ for parking reservation -------------//
    if (typeOfUnit == 2) {
      // console.log(data, "data");
      const createParkingReservation = {
        property_id: String(propertyId),
        contract_id: String(Object.keys(rowSelection)),
        actors: String(allActivityChecked),
        parking_lot_id: selectedContractData.parking_lot_id,
        car_registration_number: data?.car_registration,
        start_lease: data?.contract_start,
        end_lease: data?.contract_end,
        payment_frequency_id: String(data?.payment_frequency.value),
        payment_type_id: String(data?.payment_type.value),
        price: data?.price,
        unit_id: selectedUnitId,
      };
      // console.log(createParkingReservation, "createParkingReservation");
      try {
        const result = await createParkingReservationMutate(
          createParkingReservation
        );
        if (result.status === status.s && result.data.bReturn === true) {
          toast.success(result.data.message);
          if (getDocumentPackagesByPropertyIdData?.data?.length === 1) {
            const createSigningOrderPayload = {
              property_id: String(propertyId),
              contract_id: String(Object.keys(rowSelection)),
              document_package_id: String(
                getDocumentPackagesByPropertyIdData?.data?.[0]
                  .document_package_id
              ),
            };
            let id;
            if (!PostCreateSigningOrderIsLoading) {
              id = toast.loading("Creating signing order");
            }
            PostCreateSigningOrder(createSigningOrderPayload)
              .then((response) => {
                if (response.data.bReturn) {
                  toast.update(id, {
                    render: response?.data?.message,
                    isLoading: false,
                    type: "success",
                    autoClose: 2000,
                  });
                  // getSigningOrderByTenancyIdRefetch();
                  // setNewSigningOrderPopup(false);
                  setShowAddToContract(false);
                } else {
                  toast.update(id, {
                    render: response?.data?.message,
                    isLoading: false,
                    type: "error",
                    autoClose: 5000,
                  });
                }
              })
              .catch((error) => {
                toast.update(id, {
                  render: "Failed to create signing order",
                  isLoading: false,
                  type: "error",
                  autoClose: 2000,
                });
              });
          } else {
            setSigningOrderPage(true);
            reset();
          }
        } else {
          toast.error(result.data.message, { autoClose: 5000 });
          setSigningOrderPage(false);
          // setShowAddToContract(false);
          // reset();
        }
      } catch (error) {
        toast.error(error.message);
      }
    }

    //---------------- for storage reservation ---------------//
    if (typeOfUnit == 3) {
      // console.log(data, "data");
      const createStorageReservation = {
        property_id: String(propertyId),
        contract_id: String(Object.keys(rowSelection)),
        actors: String(allActivityChecked),
        storage_room_id: selectedContractData.storage_room_id,
        start_lease: data?.contract_start,
        end_lease: data?.contract_end,
        payment_frequency_id: String(data?.payment_frequency.value),
        payment_type_id: String(data?.payment_type.value),
        price: data?.price,
        unit_id: selectedUnitId,
      };
      // console.log(createStorageReservation, "createParkingReservation");
      try {
        const result = await createStorageReservationMutate(
          createStorageReservation
        );
        if (result.status === status.s && result.data.bReturn === true) {
          toast.success(result.data.message);
          if (getDocumentPackagesByPropertyIdData?.data?.length === 1) {
            const createSigningOrderPayload = {
              property_id: String(propertyId),
              contract_id: String(Object.keys(rowSelection)),
              document_package_id: String(
                getDocumentPackagesByPropertyIdData?.data?.[0]
                  .document_package_id
              ),
            };
            let id;
            if (!PostCreateSigningOrderIsLoading) {
              id = toast.loading("Creating signing order");
            }
            PostCreateSigningOrder(createSigningOrderPayload)
              .then((response) => {
                if (response.data.bReturn) {
                  toast.update(id, {
                    render: response?.data?.message,
                    isLoading: false,
                    type: "success",
                    autoClose: 2000,
                  });
                  // getSigningOrderByTenancyIdRefetch();
                  // setNewSigningOrderPopup(false);
                  setShowAddToContract(false);
                } else {
                  toast.update(id, {
                    render: response?.data?.message,
                    isLoading: false,
                    type: "error",
                    autoClose: 5000,
                  });
                }
              })
              .catch((error) => {
                toast.update(id, {
                  render: "Failed to create signing order",
                  isLoading: false,
                  type: "error",
                  autoClose: 2000,
                });
              });
          } else {
            setSigningOrderPage(true);
            reset();
          }
        } else {
          toast.error(result.data.message, { autoClose: 5000 });
          setSigningOrderPage(false);
          // setShowAddToContract(false);
          // reset();
        }
      } catch (error) {}
    }
  };

  const handleCreateSigningOrder = () => {
    const createSigningOrderPayload = {
      property_id: String(propertyId),
      contract_id: String(Object.keys(rowSelection)),
      document_package_id:
        typeOfUnit == 2
          ? (selectedSigningPackageId?.parking_package_id)?.toString()
          : (selectedSigningPackageId?.storage_package_id)?.toString(),
    };
    let id;
    if (!PostCreateSigningOrderIsLoading) {
      id = toast.loading("Creating signing order");
    }
    PostCreateSigningOrder(createSigningOrderPayload)
      .then((response) => {
        if (response.data.bReturn) {
          toast.update(id, {
            render: response?.data?.message,
            isLoading: false,
            type: "success",
            autoClose: 2000,
          });
          // getSigningOrderByTenancyIdRefetch();
          // setNewSigningOrderPopup(false);
          setShowAddToContract(false);
        } else {
          toast.update(id, {
            render: response?.data?.message,
            isLoading: false,
            type: "error",
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        toast.update(id, {
          render: "Failed to create signing order",
          isLoading: false,
          type: "error",
          autoClose: 2000,
        });
      });
  };

  useEffect(() => {
    if (selectedContractData?.payment_frequency_id == 1) {
      setMonthlyPrice(Number(selectedContractData?.price)?.toFixed(2));
    } else if (selectedContractData?.payment_frequency_id == 2) {
      setMonthlyPrice(
        Number(Number(selectedContractData?.price) / 3).toFixed(2)
      );
    } else {
      setMonthlyPrice(
        Number(Number(selectedContractData?.priceF) / 12).toFixed(2)
      );
    }
    return () => {};
  }, [selectedContractData]);

  //payment frequency //
  const paymentFrequencyChange = (e) => {
    setPaymentFrequency(e.DataValue1);
    setValue(
      "price",
      e.value == 1
        ? Number(monthlyPrice)?.toFixed(2)
        : e.value == 3
        ? Number(monthlyPrice * 12)?.toFixed(2)
        : (Number(monthlyPrice) * 3)?.toFixed(2)
    );
  };

  //handle price change
  const handlePriceChange = (e) => {
    setMonthlyPrice(Number(e.target.value));
    setValue("price", e.value);
    setPaymentFrequency(
      GetParkingStoragePaymentFrequency?.data.filter((item) => item.Id == 1)[0]
        ?.DataValue1
    );
    setValue(
      "payment_frequency",
      {
        value: 1,
        label: GetParkingStoragePaymentFrequency?.data.filter(
          (item) => item.Id == 1
        )[0]?.Text,
      },
      { shouldValidate: true }
    );
  };

  // const handleCheckAllActivity = (e) => {
  //   if (e.target.checked) {
  //     const allActivityTypes = allActivity.map(
  //       (activity) => activity.activityName
  //     );
  //     setAllActivityChecked(allActivityTypes);
  //   } else {
  //     setAllActivityChecked([]);
  //   }
  // };
  const handleCheckedActivitiy = (e, activity) => {
    // console.log(activity, "activity");
    if (e.target.checked) {
      setAllActivityChecked([...allActivityChecked, activity.actor_id]);
      setCheckedActorsName([
        ...checkedActorsName,
        activity.first_name + " " + activity.last_name,
      ]);
    } else {
      setAllActivityChecked(
        allActivityChecked.filter((item) => item !== activity.actor_id)
      );
      setCheckedActorsName(
        checkedActorsName.filter(
          (item) => item !== activity.first_name + " " + activity.last_name
        )
      );
    }
  };
  // console.log(selectedContractData, "selectedContractData");

  useEffect(() => {
    setPaymentFrequency(`${selectedContractData?.payment_frequency_name}`);
    setValue("price", selectedContractData?.price);

    // logics related to date
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    setValue("contract_start", today);
    setValue("contract_end", today);
  }, []);

  // payment frequency change //
  useEffect(() => {
    setValue("payment_frequency", {
      value: selectedContractData?.payment_frequency_id,
      label: GetParkingStoragePaymentFrequency?.data.filter(
        (item) =>
          item.DataValue1 == selectedContractData?.payment_frequency_name
      )[0]?.Text,
    });
  }, [GetParkingStoragePaymentFrequency]);
  useEffect(() => {
    setAllActivityChecked([]);
    setCheckedActorsName([]);
    if (Object.keys(rowSelection)?.length != 0) {
      const unitId = GetSigningOrderByPropertyId?.data?.filter(
        (item) => String(item.contract_id) == String(Object.keys(rowSelection))
      )?.[0]?.unit_id;
      // console.log(unitId, "xjxjxjjx");
      // console.log(
      //   String(Object.keys(rowSelection)),
      //   "String(Object.keys(rowSelection)"
      // );
      setSelectedUnitId(unitId);
    }
  }, [rowSelection]);

  return (
    <div>
      {signingOrderPage == false ? (
        <Form onSubmit={handleSubmit(handleContractSave)}>
          <div
            style={{
              maxHeight: "440px",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <div className="px-4 py-4 mb-1">
              <H2 className=" pb-2">Selected unit</H2>
              <Row>
                <Col xl={3} lg={4} className="pt-2">
                  <div className="  form-label-title pb-2">Unit no.</div>
                  <div className="form-input ">
                    <input
                      type="number"
                      placeholder="Unit no."
                      id="Unit-no."
                      disabled
                      value={selectedContractData?.unit_no}
                    />
                    <label htmlFor="Unit-no.">Unit no.</label>
                  </div>
                </Col>
                <Col xl={3} lg={4} className="pt-2">
                  <div className="form-label-title pb-2">Payment type</div>
                  <Controller
                    {...register("payment_type")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Payment type"}
                        options={GetParkingStoragePaymentType?.data?.map(
                          (item) => ({
                            value: item?.Id,
                            label: item?.Text,
                          })
                        )}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("payment_type");
                        }}
                      />
                    )}
                  />
                  {errors?.payment_type && (
                    <p className="error-msg ">{REQUIRED_ERR}</p>
                  )}
                </Col>
              </Row>
              <div className="pt-2 ">
                <Row className="d-flex ">
                  <Col xl={3} lg={4} className="pt-2">
                    <div className="  form-label-title pb-2">
                      Payment frequency
                    </div>
                    <Controller
                      {...register("payment_frequency")}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder="Payment frequency"
                          clearable={false}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            paymentFrequencyChange(e);
                          }}
                          options={GetParkingStoragePaymentFrequency?.data.map(
                            (x) => ({
                              value: x?.Id,
                              label: x?.Text,
                              DataValue1: x?.DataValue1,
                              isDisabled: value?.value == x?.Id,
                            })
                          )}
                        />
                      )}
                    />
                    {errors?.payment_frequency && (
                      <p className="error-msg ">{REQUIRED_ERR}</p>
                    )}
                  </Col>

                  <Col lg={4} className="pt-2 pe-0" xl={3}>
                    <div className="form-label-title pb-2">Price</div>
                    <Controller
                      control={control}
                      {...register("price")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <GroupText
                          lable={"Price"}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            handlePriceChange(e);
                            clearErrors(`price`);
                          }}
                          graylabel={`DKK${
                            paymentFrequency == "" ? "" : `/${paymentFrequency}`
                          }`}
                          id={"price"}
                          textWidth={
                            paymentFrequency?.length > 9 ? "50%" : "40%"
                          }
                        />
                      )}
                    />
                    {errors?.price && (
                      <p className="error-msg ">{REQUIRED_ERR}</p>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="pt-2 ">
                <Row className="d-flex ">
                  <Col xl={3} lg={4} className="pt-2">
                    <div className="  form-label-title pb-2">
                      Contract start
                    </div>
                    <Controller
                      control={control}
                      {...register("contract_start")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div className="form-input ">
                          <input
                            type="date"
                            placeholder="Contract start"
                            id="contractStart"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("contract_start");
                            }}
                          />
                          <label htmlFor="contractStart">Contract start</label>
                        </div>
                      )}
                    />
                    {errors?.contract_start && (
                      <p className="error-msg ">{REQUIRED_ERR}</p>
                    )}
                  </Col>

                  <Col xl={3} lg={4} className=" pt-2">
                    <div className="  form-label-title pb-2">Contract end</div>
                    <Controller
                      control={control}
                      {...register("contract_end")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div className="form-input ">
                          <input
                            type="date"
                            placeholder="Contract end"
                            id="contractEnd"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("contract_end");
                            }}
                          />
                          <label htmlFor="contractEnd">Contract end</label>
                        </div>
                      )}
                    />
                    {errors?.contract_end && (
                      <p className="error-msg ">{REQUIRED_ERR}</p>
                    )}
                  </Col>
                </Row>
              </div>
              {typeOfUnit == 2 && (
                <div className="pt-2 ">
                  <Row className="d-flex ">
                    <Col lg={3} className="pt-2">
                      <div className="  form-label-title pb-2">
                        Car registration no.
                      </div>
                      <Controller
                        control={control}
                        {...register("car_registration")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div className="form-input ">
                            <input
                              type="text"
                              placeholder="Car registration no."
                              id="car_registration"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("car_registration");
                              }}
                            />
                            <label htmlFor="car_registration">
                              Car registration no.
                            </label>
                          </div>
                        )}
                      />
                      {errors?.car_registration && (
                        <p className="error-msg ">{REQUIRED_ERR}</p>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
            </div>
            <hr className="my-0"></hr>

            <div className="px-4 pt-4 mb-1">
              <div className="form-label-title pb-2">Select contract</div>
              {typeOfUnit == 3 ? (
                <div className="light-font-text">
                  Select which contract the selected storage room unit should be
                  linked to. When a storage room unit is linked to a contract,
                  the system will automatically create a storage room lease for
                  the signing order if a valid storage room contract is set up
                  in the signing package under the property.
                </div>
              ) : (
                typeOfUnit == 2 && (
                  <div className="light-font-text">
                    Select which contract the selected parking unit should be
                    linked to. When a parking unit is linked to a contract, the
                    system will automatically create a parking lease for the
                    signing order if a valid parking contract is set up in the
                    signing package under the property.
                  </div>
                )
              )}

              <div className="pt-4">
                <SelectContractTable
                  typeOfUnit={typeOfUnit}
                  // tableData={GetSigningOrderByPropertyId?.data}
                  setRowSelection={setRowSelection}
                  rowSelection={rowSelection}
                />
              </div>
            </div>
            {Object.keys(rowSelection)?.length != 0 && (
              <>
                <hr className="my-0"></hr>
                <div className="px-4 pt-4 mb-1 pb-5">
                  <div className="form-label-title pb-2">Select actor</div>
                  <div className="d-flex">
                    <div style={{ width: "300px" }}>
                      <DropdownModalMui
                        text={
                          checkedActorsName?.length !== 0 ? (
                            <>
                              {" "}
                              {checkedActorsName?.map(
                                (item, index) =>
                                  index < 2 && (
                                    <>
                                      <span className="py-1 px-2 mx-1 bg-dark-grey radius-4 border">
                                        {item}
                                      </span>
                                    </>
                                  )
                              )}
                              {checkedActorsName?.length > 2 && (
                                <>
                                  <span className="py-1 px-2 mx-1 bg-dark-grey radius-4 border">
                                    + {checkedActorsName?.length - 2}
                                  </span>
                                </>
                              )}
                            </>
                          ) : (
                            "Select actor"
                          )
                        }
                        width={"300px"}
                        component={
                          <div className="py-2  px-2 w-100">
                            {/* <div className="d-flex align-items-center light-font-text ">
                          <CheckBox
                            onChange={handleCheckAllActivity}
                            id={"handleCheckAllActivity"}
                            checked={
                              allActivityChecked.length === allActivity.length
                            }
                          />{" "}
                          All activities
                        </div> */}

                            {actorByContractIdData?.data.map((activity, i) => (
                              <div
                                key={i}
                                className="d-flex align-items-center light-font-text  "
                              >
                                <CustomCheckBox
                                  id={activity.actor_id}
                                  checked={allActivityChecked.includes(
                                    activity.actor_id
                                  )}
                                  onChange={(e) =>
                                    handleCheckedActivitiy(e, activity)
                                  }
                                />{" "}
                                {activity.first_name + " " + activity.last_name}
                              </div>
                            ))}
                          </div>
                        }
                      />
                    </div>
                    {/* <div className="d-flex align-items-center light-font-text  px-4">
                      {allActivityChecked?.length} actors{" "}
                      {allActivityChecked?.length > 1 ? " are " : " is "}{" "}
                      selected.
                    </div> */}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="mt-2 py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            {" "}
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              // type={"button"}
              onClick={() => setShowAddToContract(false)}
            />
            <div className=" d-flex ">
              <div className="px-1">
                {" "}
                <ETButton
                  variant={"primary"}
                  disabled={
                    allActivityChecked?.length == 0 ||
                    createParkingReservationLoading ||
                    createStorageReservationLoading
                      ? true
                      : false
                  }
                  buttonText="SAVE"
                  type={"submit"}
                  // onClick={() => setShowAddToContract(false)}
                />
              </div>
            </div>
          </div>
        </Form>
      ) : (
        <div>
          <div
            className="px-4"
            style={{
              maxHeight: "440px",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <Row className="py-3">
              {getDocumentPackagesByPropertyIdData?.data?.length > 0 ? (
                getDocumentPackagesByPropertyIdData?.data?.map(
                  (item, index) => (
                    <Col key={index} className="my-2" lg={6} xl={4}>
                      <SigningPackageCard
                        data={item}
                        index={index}
                        setSelectedSigningPackageId={
                          setSelectedSigningPackageId
                        }
                        selectedSigningPackageId={selectedSigningPackageId}
                      />
                    </Col>
                  )
                )
              ) : (
                <div className="d-flex justify-content-center w-100 fs-14 grey-text mt-1 py-4">
                  {`Oops ! It seems that you don't have any ${
                    typeOfUnit == 2
                      ? " parking "
                      : typeOfUnit === 3
                      ? " storage "
                      : ""
                  } signing package yet.
                  Please create  ${
                    typeOfUnit == 2
                      ? " parking "
                      : typeOfUnit === 3
                      ? " storage "
                      : ""
                  } signing package and than create contract.
                  `}
                </div>
              )}
            </Row>
          </div>
          <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-end  justify-content-end footer-section-button-container gap-3">
            {/* <ETButton
            variant = {"white"}
          buttonText="GO TO UNITS"
          fontSize="14px"
          // onClick={()=>setRentailContractPopup(false)}
        /> */}
            {getDocumentPackagesByPropertyIdData?.data.length != 0 ? (
              <ETButton
                disabled={
                  selectedSigningPackageId === "" ||
                  PostCreateSigningOrderIsLoading
                    ? true
                    : false
                }
                variant={"primary"}
                buttonText="CREATE SIGNING ORDER"
                onClick={() => handleCreateSigningOrder()}
              />
            ) : (
              <ETButton
                variant={"primary"}
                buttonText="OKAY"
                onClick={() => setShowAddToContract(false)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddToContract;

//================================ History ================================//

// Viraj (22-10-2023)
// 1. Fixed storage message where storage was capitalized. 2. Removed unwanted zustand was created for displaying message for storage & parking.
