import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import "./Popups.css";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import ETButton from "../../../../Components/Buttons/ETButton";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { PutUpdateReservationDetails } from "../../../../services/Actors";
import { useUpdateReservationDetails } from "../../../../hooks/queries/useActorQueries";
import { toast } from "react-toastify";

const ReservatationDetails = ({
  setReservationPopup,
  contractOverViewData,
  actorByContractIdData,
  usersByDivisionIdData,
  contractByUnitIdData,
  contractOverViewDataRefetch,
  contractByUnitIdDataRefetch,
}) => {
  //-------------React query---------------//
  const {
    mutateAsync: PutUpdateReservationDetails,
    isLoading: PutUpdateReservationDetailsIsLoading,
  } = useUpdateReservationDetails();

  const {
    register,
    handleSubmit,
    clearErrors,
    getValues,
    formState: { errors },
    setValue,
    control,
    reset,
  } = useForm();

  useEffect(() => {
    setValue(
      "dateOfDeadline",
      moment(contractOverViewData?.data?.[0]?.reservation_deadline).format(
        "YYYY-MM-DD"
      )
    );
    setValue("responsibleAgent", {
      value: contractByUnitIdData?.data?.[0]?.responsible_agent,
      label: contractByUnitIdData?.data?.[0]?.responsible_agent_name,
    });

    return () => { };
  }, [contractOverViewData, actorByContractIdData]);

  const handleSaveReservationDetail = (data) => {
    const updateReservationDetailPopup = {
      contract_id: String(contractOverViewData?.data?.[0]?.contract_id),
      reservation_to: data?.dateOfDeadline,
      responsible_agent: String(data?.responsibleAgent?.value),
    };
    PutUpdateReservationDetails(updateReservationDetailPopup).then(
      (response) => {
        if (response?.data?.bReturn) {
          toast.success(response?.data?.message);
          setReservationPopup(false);
          contractOverViewDataRefetch();
          contractByUnitIdDataRefetch();
        }
      }
    );
  };

  return (
    <Form onSubmit={handleSubmit(handleSaveReservationDetail)}>
      <div>
        <div
          className="px-3 py-4"
          style={{ borderBottom: "1px Solid #F4F5F5" }}
        >
          <div className="Reservation-data px-3 py-2 d-flex justify-content-between">
            <div

              className="fs-14 "
            >
              Reservation created
            </div>
            <div
              className="light-font-text"
            >
              {contractOverViewData?.data?.[0]?.reservation_created !== ""
                ? moment(
                  contractOverViewData?.data?.[0]?.reservation_created
                ).format("DD MMM, YYYY [at] HH:mm:ss")
                : "-"}
            </div>
          </div>
          <div className="Reservation-data mt-2 px-3 py-2 d-flex justify-content-between">
            <div
              className="fs-14 "
            >
              Reservation created by
            </div>
            <div
              className="light-font-text"
            >
              {actorByContractIdData?.data?.[0]?.first_name}&nbsp;
              {actorByContractIdData?.data?.[0]?.last_name}
            </div>
          </div>
        </div>
        {/* -----------------------DropDown And Datepickers----------------- */}
        <div className="px-3 py-4">
          <div className="light-font-text"
          >
            Below you can see the reservation deadline. It is possible to
            correct the deadline and the responsible agent.
          </div>

          <Row className="mt-2">
            <Col lg={10}>
              <Row>
                <Col lg={4} className="mt-md-3">
                  <Controller
                    control={control}
                    {...register("dateOfDeadline")}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <div className="form-input-group ">
                        <input
                          type="date"
                          id="DateofDeadline"
                          placeholder="DateofDeadline"
                          value={field.value}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            clearErrors("dateOfDeadline");
                          }}
                        />
                        <label htmlFor="DateofDeadline">Date of deadline</label>
                      </div>
                    )}
                  />
                  {errors.dateOfDeadline && (
                    <div className="error-msg">
                      This field is required
                    </div>
                  )}
                </Col>
                {/* <Col lg={4} className="my-md-3">
                  <Controller
                    control={control}
                    {...register("timeOfDeadline")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <div className="form-input-group ">
                        <input
                          type="time"
                          id="TimeofDeadline"
                          placeholder="TimeofDeadline"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("timeOfDeadline");
                          }}
                        />
                        <label htmlFor="TimeofDeadline">Time of deadline</label>
                      </div>
                    )}
                  />
                  {errors.timeOfDeadline && (
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      This field is required
                    </p>
                  )}
                </Col> */}
                <Col lg={4} className="my-md-3">
                  <Controller
                    control={control}
                    {...register("responsibleAgent")}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <ReactSelect
                        placeholder={"Responsible agent"}
                        innerLabel={false}
                        options={usersByDivisionIdData?.data?.map(
                          (item, index) => ({
                            value: item?.Id,
                            label: item?.Text,
                          })
                        )}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors("responsibleAgent");
                        }}
                        clearable={false}
                      />
                    )}
                  />
                  {errors.responsibleAgent && (
                    <div className="error-msg">
                      This field is required
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            borderTop: "1px solid #F4F5F5",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setReservationPopup(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="SAVE"
            type={"submit"}
          />
        </div>
      </div>
    </Form>
  );
};

export default ReservatationDetails;
