import { Get } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";
import { RefreshToken } from "./RefreshToken";

export const AuthTokenValidation = () => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.AuthToken)
			.then((response) => {
				resolve(response.data);
				// console.log("AuthTokenValidation", response);
			})
			.catch((error) => {
				// console.log("err AuthTokenValidation", error);
				if (error.response.status == 401) {
					if (error.response.data.code == 401) {
						console.log("inside 401");
						RefreshToken();
					}
				}

				reject({
					error: true,
					message: error.response.data.message,
					code: error.response.data.code,
				});

				// console.log("jwt authentiation api err", error.message);
			});
	});
};
