import React from "react";
import { H2 } from "../../../../styles/Common.style";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../../../Components/Tabs/ReactTabs.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import Styling from "./Styling";
import Text from "./Text";
import Communication from "./Communication";
import Options from "./Options";
import { useDivisionSettingsBreadcrumbsTabs } from "../../../../hooks/stores/useDivisionsStore";

const MyPage = () => {
  const setDivisionBreadcrumbsTabs = useDivisionSettingsBreadcrumbsTabs(
    (state) => state.setDivisionBreadcrumbsTabs
  );
  return (
    <div>
      {" "}
      {/* title section */}
      <div className="px-3 pb-3 border-bottom">
        <div className="px-2 pt-3 d-flex">
          <div className="d-flex align-items-center  gap-2">
            <H2>MyPage </H2>
            <CustomToolTip text="MyPage " />
          </div>
        </div>
      </div>
      {/*All tabs section  */}
      <div>
        <Tabs className="d-flex flex-column ">
          <div
            className="d-flex px-4 py-3 mb-3 justify-content-between align-items-center"
            style={{
              width: "100%",
              flexDirection: "row",
              flexWrap: "wrap",
              borderBottom: "1px solid #F0F0F0",
            }}
          >
            <TabList
              className=" d-flex light ps-0 light"
              style={{ gap: "7px" }}
            >
              <Tab onClick={() => setDivisionBreadcrumbsTabs("Styling")}>
                Styling
              </Tab>
              <Tab onClick={() => setDivisionBreadcrumbsTabs("Text")}>Text</Tab>
              <Tab onClick={() => setDivisionBreadcrumbsTabs("Communication")}>
                Communication
              </Tab>
              <Tab onClick={() => setDivisionBreadcrumbsTabs("Options")}>
                Options
              </Tab>
            </TabList>
          </div>
          <div>
            <TabPanel>
              <Styling />
            </TabPanel>
            <TabPanel>
              <Text />
            </TabPanel>
            <TabPanel>
              <Communication />
            </TabPanel>
            <TabPanel>
              <Options />
            </TabPanel>
          </div>
        </Tabs>
      </div>
    </div>
  );
};

export default MyPage;
