export const Statusinfunnel = [
  {
    Id: 1,
    name: "Louise Birch Justesen",
    attended_viewing: "17 Jun, 2023 at 12:00",
    reservation_created: "3 Jun, 2023 at 11:52",
    last_activity : "3 Jun, 2023 at 11:52",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Thomas Høeg Mogensen",
    status_time: "08:45:53",
    status_name: "(Attending an event)",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "21 - Students in Aarhus",
    category: "Open House",
    follow_up_status: "Ongoing doing",
    funnelStatus : "Opportunity",
  },
  {
    Id: 2,
    name: "Harris Navarro",
    attended_viewing: "26 Jun, 2023 at 12:00",
    reservation_created: "12 Jun, 2023 at 10:03",
    signing_started: "26 Jun, 2023 at 12:00",
    signing_deadline: "26 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "(Not CRITICAL)",
    phone: "+45 22 57 16 36",
    email: "",
    age: "47",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "#89 - Meta - October campaign",
    category: "Contact",
    follow_up_status: "",
    last_activity : "3 Jun, 2023 at 11:52",
    funnelStatus : "Lead",
  },
  {
    Id: 3,
    name: "Donovan Cummings",
    attended_viewing: "28 May, 2023 at 08:05",
    reservation_created: "28 May, 2023 at 08:05",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "(Proposed)",
    phone: "+45 22 57 16 36",
    email: "",
    age: "34",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "Referral",
    category: "Contact",
    follow_up_status: "",
    last_activity : "3 Jun, 2023 at 11:52",
    funnelStatus : "Opportunity",
  },
  {
    Id: 4,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Thomas Høeg Mogensen",
    status_time: "08:45:53",
    status_name: "(ongoing,67%)",
    phone: "+45 22 57 16 36",
    email: "",
    age: "29",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "21 - Students in Aarhus",
    category: "Contact",
    follow_up_status: "Follow up later",
    last_activity : "3 Jun, 2023 at 11:52",
    funnelStatus : "Screening",
  },
  {
    Id: 5,
    name: "Alexandros Luna",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
    status_time: "08:45:53",
    status_name: "(Ongoing dialog)",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "60",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "#89 - Meta - October campaign",
    category: "Contact",
    follow_up_status: "Ongoing dialog",
    last_activity : "3 Jun, 2023 at 11:52",
    funnelStatus : "Followuplater",
  },
  {
    Id: 6,
    name: "Victor Padilla Hurst",
    attended_viewing: "26 Jun, 2023 at 12:00",
    reservation_created: "12 Jun, 2023 at 10:03",
    signing_started: "26 Jun, 2023 at 12:00",
    signing_deadline: "26 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
    status_time: "08:45:53",
    status_name: "(approved,100%)",
    phone: "+45 22 57 16 36",
    email: "",
    age: "45",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "Website",
    category: "Private viewing",
    follow_up_status: "Not ready to product",
    last_activity : "3 Jun, 2023 at 11:52",
    funnelStatus : "Screeing ",
  },
  {
    Id: 7,
    name: "Martha Mccormick",
    attended_viewing: "28 May, 2023 at 08:05",
    reservation_created: "28 May, 2023 at 08:05",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Thomas Høeg Mogensen",
    status_time: "08:45:53",
    status_name: "(completed,100%)",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "56",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "#89 - Instagram - Spring campaign",
    category: "Private viewing",
    follow_up_status: "No contact",
    last_activity : "3 Jun, 2023 at 11:52",
    funnelStatus : "Screeing ",
  },
  {
    Id: 8,
    name: "Rachael Cunningham",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
    status_time: "08:45:53",
    status_name: "(Cancelled,45%)",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "47",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "Referral",
    category: "Open House",
    follow_up_status: "Not ready to product",
    last_activity : "3 Jun, 2023 at 11:52",
    funnelStatus : "Screeing ",
  },
  {
    Id: 9,
    name: "Francesco Cherry",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "(1 out of 3 signed)",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "27",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "Website",
    category: "Private viewing",
    follow_up_status: "Not ready to product",
    last_activity : "3 Jun, 2023 at 11:52",
    funnelStatus : "Signing",
  },
  {
    Id: 10,
    name: "Abdirahman Mcclure",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "(CRITICAL)",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "30",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "#89 - Meta - October campaign",
    category: "Open House",
    follow_up_status: "Not ready to product",
    last_activity : "3 Jun, 2023 at 11:52",
    funnelStatus : "Lead",
  },
  
  
 
];
export const SigningsTableData = [
  {
    Id: 1,
    name: "Louise Birch Justesen",
    attended_viewing: "17 Jun, 2023 at 12:00",
    reservation_created: "3 Jun, 2023 at 11:52",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Thomas Høeg Mogensen",
    status_time: "08:45:53",
    status_name: "NOT CRITICAL",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "21 - Students in Aarhus",
    category: "Open House",
    follow_up_status: "Ongoing doing",
  },
  {
    Id: 2,
    name: "Harris Navarro",
    attended_viewing: "26 Jun, 2023 at 12:00",
    reservation_created: "12 Jun, 2023 at 10:03",
    signing_started: "26 Jun, 2023 at 12:00",
    signing_deadline: "26 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "CRITICAL",
    phone: "+45 22 57 16 36",
    email: "",
    age: "47",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "#89 - Meta - October campaign",
    category: "Contact",
    follow_up_status: "",
  },
  {
    Id: 3,
    name: "Donovan Cummings",
    attended_viewing: "28 May, 2023 at 08:05",
    reservation_created: "28 May, 2023 at 08:05",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "CRITICAL",
    phone: "+45 22 57 16 36",
    email: "",
    age: "34",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "Referral",
    category: "Contact",
    follow_up_status: "",
  },
  {
    Id: 4,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Thomas Høeg Mogensen",
    status_time: "08:45:53",
    status_name: "NOT CRITICAL",
    phone: "+45 22 57 16 36",
    email: "",
    age: "29",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "21 - Students in Aarhus",
    category: "Contact",
    follow_up_status: "Follow up later",
  },
  {
    Id: 5,
    name: "Alexandros Luna",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
    status_time: "08:45:53",
    status_name: "NOT CRITICAL",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "60",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "#89 - Meta - October campaign",
    category: "Contact",
    follow_up_status: "Ongoing dialog",
  },
  {
    Id: 6,
    name: "Victor Padilla Hurst",
    attended_viewing: "26 Jun, 2023 at 12:00",
    reservation_created: "12 Jun, 2023 at 10:03",
    signing_started: "26 Jun, 2023 at 12:00",
    signing_deadline: "26 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
    status_time: "08:45:53",
    status_name: "CRITICAL",
    phone: "+45 22 57 16 36",
    email: "",
    age: "45",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "Website",
    category: "Private viewing",
    follow_up_status: "Not ready to product",
  },
  {
    Id: 7,
    name: "Martha Mccormick",
    attended_viewing: "28 May, 2023 at 08:05",
    reservation_created: "28 May, 2023 at 08:05",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Thomas Høeg Mogensen",
    status_time: "08:45:53",
    status_name: "CRITICAL",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "56",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "#89 - Instagram - Spring campaign",
    category: "Private viewing",
    follow_up_status: "No contact",
  },
  {
    Id: 8,
    name: "Rachael Cunningham",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
    status_time: "08:45:53",
    status_name: "NOT CRITICAL",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "47",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "Referral",
    category: "Open House",
    follow_up_status: "Not ready to product",
  },
  {
    Id: 9,
    name: "Francesco Cherry",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "NOT CRITICAL",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "27",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "Website",
    category: "Private viewing",
    follow_up_status: "Not ready to product",
  },
  {
    Id: 10,
    name: "Abdirahman Mcclure",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "NOT CRITICAL",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "30",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "#89 - Meta - October campaign",
    category: "Open House",
    follow_up_status: "Not ready to product",
  },
  {
    Id: 11,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "NOT CRITICAL",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "21 - Students in Aarhus",
    category: "Open House",
    follow_up_status: "Not ready to product",
  },
  {
    Id: 12,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "NOT CRITICAL",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "21 - Students in Aarhus",
    category: "Open House",
    follow_up_status: "",
  },
  {
    Id: 13,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "NOT CRITICAL",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "21 - Students in Aarhus",
    category: "Open House",
    follow_up_status: "",
  },
];
export const InProcessTableData = [
  {
    Id: 1,
    name: "Louise Birch Justesen",
    attended_viewing: "17 Jun, 2023 at 12:00",
    reservation_created: "3 Jun, 2023 at 11:52",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Feb, 2021 (690 days)",
  },
  {
    Id: 2,
    name: "Harris Navarro",
    attended_viewing: "26 Jun, 2023 at 12:00",
    reservation_created: "12 Jun, 2023 at 10:03",
    signing_started: "26 Jun, 2023 at 12:00",
    signing_deadline: "26 Jun, 2023 at 12:00",
    responsible_agent: "Max Wildersans Brentford",
    status_time: "08:45:53",
    status_name: "UNDEFINED",
    phone: "+45 22 57 16 36",
    email: "",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Contact",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "1",
    signing_status: "4",
    Creditworthiness: "RARELY PAYS ON TIME",
    Tenant_since: "2 May, 2022 (763 days)",
  },
  {
    Id: 3,
    name: "Donovan Cummings",
    attended_viewing: "28 May, 2023 at 08:05",
    reservation_created: "28 May, 2023 at 08:05",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Oscar Odling",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Contact",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "2",
    signing_status: "4",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Sep, 2019 (1253 days)",
  },
  {
    Id: 4,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Ella-Louise Meyer",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Contact",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "1",
    signing_status: "4",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "3 Aug, 2020 (1403 days)",
  },
  {
    Id: 5,
    name: "Alexandros Luna",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Oscar Odling",
    status_time: "08:45:53",
    status_name: "UNDEFINED",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Contact",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "2 May, 2023 (96 days)",
  },
  {
    Id: 6,
    name: "Victor Padilla Hurst",
    attended_viewing: "26 Jun, 2023 at 12:00",
    reservation_created: "12 Jun, 2023 at 10:03",
    signing_started: "26 Jun, 2023 at 12:00",
    signing_deadline: "26 Jun, 2023 at 12:00",
    responsible_agent: "Ella-Louise Meyer",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Private viewing",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "2",
    signing_status: "3",
    Creditworthiness: "OFTEN PAYS ON TIME",
    Tenant_since: "1 Sep, 2019 (1253 days)",
  },
  {
    Id: 7,
    name: "Martha Mccormick",
    attended_viewing: "28 May, 2023 at 08:05",
    reservation_created: "28 May, 2023 at 08:05",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Ella-Louise Meyer",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Private viewing",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "1",
    signing_status: "2",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Feb, 2021 (690 days)",
  },
  {
    Id: 8,
    name: "Rachael Cunningham",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Robert Zamora",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "2",
    signing_status: "4",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "3 Aug, 2020 (1403 days)",
  },
  {
    Id: 9,
    name: "Francesco Cherry",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Lillie Joseph",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Private viewing",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "OFTEN PAYS ON TIME",
    Tenant_since: "2 May, 2023 (96 days)",
  },
  {
    Id: 10,
    name: "Abdirahman Mcclure",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "Dominic Bruce",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Sep, 2019 (1253 days)",
  },
  {
    Id: 11,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Feb, 2021 (690 days)",
  },
  {
    Id: 12,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Feb, 2021 (690 days)",
  },
  {
    Id: 13,
    name: "Garfield Schwartz",
    attended_viewing: "24 May, 2023 at 14:11",
    reservation_created: "24 May, 2023 at 14:11",
    signing_started: "17 Jun, 2023 at 12:00",
    signing_deadline: "17 Jun, 2023 at 12:00",
    responsible_agent: "-",
    status_time: "08:45:53",
    status_name: "IN DIALOGUE",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    age: "37",
    registration_date: "22 Jun, 2023 at 18:32",
    Campaign_Id: "49",
    category: "Open House",
    last_contact: "25 Jun, 2023",
    rating: "3",
    Relation: "Blomstervangen 12, 1.tv, 2765 Smørum",
    completed_signing: "3",
    signing_status: "3",
    Creditworthiness: "PAYS ON TIME",
    Tenant_since: "1 Feb, 2021 (690 days)",
  },
];

export const CommunicationData = [
  {
    id: 1,
    head: "MISSING INFORMATION",
    name: "Jesper Thorkildsen",
    date: "Feb 23, 2023 at 12:19",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ipsum dolor sit amet, consectetur adipiscing elit...",
    subjet: "ECONOMY",
    notification: 1,
    tenant_status: "Primary tenant",
    last_outbound_respond: "Max Wildersans Brentford",
  },
  {
    id: 2,
    head: "DATE OF MOVING IN",
    name: "Max Wildersans Brentford",
    date: "Feb 23, 2023 at 12:19",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
    tenant_status: "Secondary tenant",
    last_outbound_respond: "Max Wildersans Brentford",
    notification: 0,
  },
  {
    id: 3,
    head: "DATE OF MOVING IN",
    name: "Max Wildersans Brentford",
    date: "Feb 23, 2023 at 12:19",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
    tenant_status: "Secondary tenant",
    last_outbound_respond: "Max Wildersans Brentford",
    notification: 0,
  },
  {
    id: 4,
    head: "DATE OF MOVING IN",
    name: "Max Wildersans Brentford",
    date: "Feb 23, 2023 at 12:19",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
    tenant_status: "Secondary tenant",
    last_outbound_respond: "Max Wildersans Brentford",
    notification: 0,
  },

  {
    id: 5,
    head: "DATE OF MOVING IN",
    name: "Max Wildersans Brentford",
    date: "Feb 23, 2023 at 12:19",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
    tenant_status: "Secondary tenant",
    last_outbound_respond: "Max Wildersans Brentford",
    notification: 0,
  },
  {
    id: 6,
    head: "MISSING INFORMATION",
    name: "Jesper Thorkildsen",
    date: "Feb 23, 2023 at 12:19",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ipsum dolor sit amet, consectetur adipiscing elit...",
    subjet: "ECONOMY",
    tenant_status: "Secondary tenant",
    last_outbound_respond: "Max Wildersans Brentford",
    notification: 0,
  },
  {
    id: 7,
    head: "DATE OF MOVING IN",
    name: "Max Wildersans Brentford",
    date: "Feb 23, 2023 at 12:19",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
    tenant_status: "Secondary tenant",
    last_outbound_respond: "Max Wildersans Brentford",
    notification: 0,
  },
  {
    id: 8,
    head: "DATE OF MOVING IN",
    name: "Max Wildersans Brentford",
    date: "Feb 23, 2023 at 12:19",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
    tenant_status: "Secondary tenant",
    last_outbound_respond: "Max Wildersans Brentford",
    notification: 0,
  },
  {
    id: 9,
    head: "DATE OF MOVING IN",
    name: "Max Wildersans Brentford",
    date: "Feb 23, 2023 at 12:19",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
    tenant_status: "Secondary tenant",
    last_outbound_respond: "Max Wildersans Brentford",
    notification: 0,
  },

  {
    id: 10,
    head: "DATE OF MOVING IN",
    name: "Max Wildersans Brentford",
    date: "Feb 23, 2023 at 12:19",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
    tenant_status: "Secondary tenant",
    last_outbound_respond: "Max Wildersans Brentford",
    notification: 0,
  },
  {
    id: 11,
    head: "MISSING INFORMATION",
    name: "Jesper Thorkildsen",
    date: "Feb 23, 2023 at 12:19",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ipsum dolor sit amet, consectetur adipiscing elit...",
    subjet: "ECONOMY",
    tenant_status: "Secondary tenant",
    last_outbound_respond: "Max Wildersans Brentford",
    notification: 0,
  },
];
export const notesData = [
  {
    id: 1,
    head: "MISSING INFORMATION",
    name: "Jesper Thorkildsen",
    date: "20 May, 2023",
    msg: "Ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Ut aut reiciendis.",
    subjet: "DEPOSIT",
    notification: 1,
    tenant_status: "Primary tenant",
    last_outbound_respond: "Max Wildersans Brentford",
    title: "Blomstervangen 12, 1.tv, 2765 Smørum | 212-004-002",
    subject_bg: "#9370DB",
  },
  {
    id: 2,
    head: "DATE OF MOVING IN",
    name: "Max Wildersans Brentford",
    date: "20 May, 2023",
    msg: "Ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Ut aut reiciendis.",
    subjet: "TERMINATION",
    tenant_status: "Secondary tenant",
    last_outbound_respond: "Max Wildersans Brentford",
    notification: 0,
    title: "Blomstervangen 12, 1.tv, 2765 Smørum | 212-004-002",
    subject_bg: "#F1664B",
  },
  {
    id: 3,
    head: "DATE OF MOVING IN",
    name: "Max Wildersans Brentford",
    date: "20 May, 2023",
    msg: "Ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Ut aut reiciendis.",
    subjet: "MOVING-IN",
    tenant_status: "Secondary tenant",
    last_outbound_respond: "Max Wildersans Brentford",
    notification: 0,
    title: "Blomstervangen 12, 1.tv, 2765 Smørum | 212-004-002",
    subject_bg: "#4169E1",
  },
  {
    id: 4,
    head: "DATE OF MOVING IN",
    name: "Max Wildersans Brentford",
    date: "20 May, 2023",
    msg: "Ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Ut aut reiciendis.",
    subjet: "DEPOSIT",
    tenant_status: "Secondary tenant",
    last_outbound_respond: "Max Wildersans Brentford",
    notification: 0,
    title: "Blomstervangen 12, 1.tv, 2765 Smørum | 212-004-002",
    subject_bg: "#9370DB",
  },
  {
    id: 5,
    head: "DATE OF MOVING IN",
    name: "Max Wildersans Brentford",
    date: "20 May, 2023",
    msg: "Ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Ut aut reiciendis.",
    subjet: "MOVING-IN",
    tenant_status: "Secondary tenant",
    last_outbound_respond: "Max Wildersans Brentford",
    notification: 0,
    title: "Blomstervangen 12, 1.tv, 2765 Smørum | 212-004-002",
    subject_bg: "#4169E1",
  },
];

export const assignAgentTable = [
  {
    id: 1,
    name: "Jesper Thorkildsen",
    department: "LokalBolig Rødovre",
  },
  {
    id: 2,
    name: "Max Stanson",
    department: "LokalBolig Rødovre",
  },
  {
    id: 3,
    name: "Julie Thompson",
    department: "LokalBolig Rødovre",
  },
  {
    id: 4,
    name: "Joey Dawson",
    department: "LokalBolig Rødovre",
  },

  {
    id: 5,
    name: "Sandra Tullock",
    department: "LokalBolig Rødovre",
  },

  {
    id: 6,
    name: "Maibritt Jensen",
    department: "LokalBolig Rødovre",
  },
  {
    id: 7,
    name: "Max Wilderness Johnson",
    department: "LokalBolig Rødovre",
  },
  {
    id: 8,
    name: "Heidi Bernhard Pagh",
    department: "LokalBolig Rødovre",
  },
  {
    id: 9,
    name: "Jordan Michael Ingst",
    department: "LokalBolig Rødovre",
  },

  {
    id: 10,
    name: "Charlotte Yankees",
    department: "LokalBolig Rødovre",
  },
  {
    id: 11,
    name: "Charlotte Yankees",
    department: "LokalBolig Rødovre",
  },
  {
    id: 12,
    name: "Charlotte Yankees",
    department: "LokalBolig Rødovre",
  },
];

export const PaymentsData = [
  {
    id: 1,
    name: "Blomstervangen 12, 1.tv, 2765 Smørum",
    Unit_no: "212-004-002",
    contract: "Louise Birch Justesen",
    phone: "+45 22 57 16 36",
    email: "louise-birch@gmail.com",
    last_payment: "6 Feb 2023",
    balance: "-10.500 DKK",
  },
  {
    id: 2,
    name: "Blomstervangen 2, 3.tv, 2765 Smørum",
    Unit_no: "212-005-002",
    contract: "Harris Navarro",
    phone: "+45 22 57 16 36",
    email: "-",
    last_payment: "6 Feb 2023",
    balance: "7.500 DKK",
  },
  {
    id: 3,
    name: "Blomstervangen 19, 2.th, 2765 Smørum",
    Unit_no: "212-006-002",
    contract: "Donovan Cummings",
    phone: "+45 22 57 16 36",
    email: "-",
    last_payment: "6 Feb 2023",
    balance: "20.200 DKK",
  },
  {
    id: 4,
    name: "Blomstervangen 6, 4.tv, 2765 Smørum",
    Unit_no: "212-007-002",
    contract: "Garfield Schwartz",
    phone: "+45 22 57 16 36",
    email: "-",
    last_payment: "6 Feb 2023",
    balance: "-10.500 DKK",
  },

  {
    id: 5,
    name: "Blomstervangen 10, st.tv, 2765 Smørum",
    Unit_no: "212-008-002",
    contract: "Alexandros Luna",
    phone: "+45 22 57 16 36",
    email: "-",
    last_payment: "6 Feb 2023",
    balance: "-10.500 DKK",
  },
  {
    id: 6,
    name: "Blomstervangen 11, 1.th, 2765 Smørum",
    Unit_no: "212-009-002",
    contract: "Victor Padilla Hurst",
    phone: "+45 22 57 16 36",
    email: "Victor@gmail.com",
    last_payment: "6 Feb 2023",
    balance: "-10.500 DKK",
  },
  {
    id: 7,
    name: "Blomstervangen 10, th, 2765 Smørum",
    Unit_no: "212-010-002",
    contract: "Martha Mccormick",
    phone: "+45 22 57 16 36",
    email: "Martha@mails.com",
    last_payment: "6 Feb 2023",
    balance: "-10.500 DKK",
  },
  {
    id: 8,
    name: "Blomstervangen 4, 4th, 2765 Smørum",
    Unit_no: "212-011-002",
    contract: "Rachael Cunningham",
    phone: "+45 22 57 16 36",
    email: "Rachael@outlook.com",
    last_payment: "6 Feb 2023",
    balance: "-10.500 DKK",
  },
  {
    id: 9,
    name: "Blomstervangen 5, st.tv, 2765 Smørum",
    Unit_no: "212-012-002",
    contract: "Francesco Cherry",
    phone: "+45 22 57 16 36",
    email: "Francesco@gamil.com",
    last_payment: "6 Feb 2023",
    balance: "-10.500 DKK",
  },

  {
    id: 10,
    name: "Blomstervangen 14, 6.tv, 2765 Smørum",
    Unit_no: "212-013-002",
    contract: "Abdirahman Mcclure",
    phone: "+45 22 57 16 36",
    email: "Abdirahman@gmail.com",
    last_payment: "6 Feb 2023",
    balance: "-10.500 DKK",
  },
];

export const OpenPositionsData = [
  {
    id: 1,
    posted: "24 Apr, 2023",
    text: "Deposit",
    due_Date: "24 Apr, 2023",
    period_From: "24 Apr, 2023",
    period_To: "24 Apr, 2023",
    amount: "3.000 DKK",
    balance: "3.000 DKK",
  },
  {
    id: 2,
    posted: "24 Apr, 2023",
    text: "Deposit",
    due_Date: "24 Apr, 2023",
    period_From: "24 Apr, 2023",
    period_To: "24 Apr, 2023",
    amount: "3.000 DKK",
    balance: "3.000 DKK",
  },
  {
    id: 3,
    posted: "24 Apr, 2023",
    text: "Deposit",
    due_Date: "24 Apr, 2023",
    period_From: "24 Apr, 2023",
    period_To: "24 Apr, 2023",
    amount: "3.000 DKK",
    balance: "3.000 DKK",
  },
  {
    id: 4,
    posted: "24 Apr, 2023",
    text: "Deposit",
    due_Date: "24 Apr, 2023",
    period_From: "24 Apr, 2023",
    period_To: "24 Apr, 2023",
    amount: "3.000 DKK",
    balance: "3.000 DKK",
  },

  {
    id: 5,
    posted: "24 Apr, 2023",
    text: "Deposit",
    due_Date: "24 Apr, 2023",
    period_From: "24 Apr, 2023",
    period_To: "24 Apr, 2023",
    amount: "3.000 DKK",
    balance: "3.000 DKK",
  },
];

export const BalanceCardData = [
  {
    id: 1,
    posted: "24 Apr, 2023",
    text: "Deposit",
    due_Date: "24 Apr, 2023",
    period_From: "24 Apr, 2023",
    period_To: "24 Apr, 2023",
    amount: "-5.000 ",
    balance: "-5.000 ",
  },
  {
    id: 2,
    posted: "24 Apr, 2023",
    text: "Prepaid rent",
    due_Date: "24 Apr, 2023",
    period_From: "24 Apr, 2023",
    period_To: "24 Apr, 2023",
    amount: "3.000 ",
    balance: "3.000 ",
  },
  {
    id: 3,
    posted: "24 Apr, 2023",
    text: "Deposit",
    due_Date: "24 Apr, 2023",
    period_From: "24 Apr, 2023",
    period_To: "24 Apr, 2023",
    amount: "40.000 ",
    balance: "40.000 ",
  },
  {
    id: 4,
    posted: "24 Apr, 2023",
    text: "Prepaid rent",
    due_Date: "24 Apr, 2023",
    period_From: "24 Apr, 2023",
    period_To: "24 Apr, 2023",
    amount: "3.000 ",
    balance: "3.000 ",
  },

  {
    id: 5,
    posted: "24 Apr, 2023",
    text: "Deposit",
    due_Date: "24 Apr, 2023",
    period_From: "24 Apr, 2023",
    period_To: "24 Apr, 2023",
    amount: "-5.000 ",
    balance: "-5.000 ",
  },
  {
    id: 6,
    posted: "24 Apr, 2023",
    text: "Prepaid rent",
    due_Date: "24 Apr, 2023",
    period_From: "24 Apr, 2023",
    period_To: "24 Apr, 2023",
    amount: "3.000 ",
    balance: "3.000 ",
  },
  {
    id: 7,
    posted: "24 Apr, 2023",
    text: "Deposit",
    due_Date: "24 Apr, 2023",
    period_From: "24 Apr, 2023",
    period_To: "24 Apr, 2023",
    amount: "40.000 ",
    balance: "3.000 ",
  },
  {
    id: 8,
    posted: "24 Apr, 2023",
    text: "Prepaid rent",
    due_Date: "24 Apr, 2023",
    period_From: "24 Apr, 2023",
    period_To: "24 Apr, 2023",
    amount: "-5.000 ",
    balance: "-5.000 ",
  },
  {
    id: 9,
    posted: "24 Apr, 2023",
    text: "Deposit",
    due_Date: "24 Apr, 2023",
    period_From: "24 Apr, 2023",
    period_To: "24 Apr, 2023",
    amount: "3.000 ",
    balance: "3.000 ",
  },
  {
    id: 10,
    posted: "24 Apr, 2023",
    text: "Prepaid rent",
    due_Date: "24 Apr, 2023",
    period_From: "24 Apr, 2023",
    period_To: "24 Apr, 2023",
    amount: "3.000 ",
    balance: "3.000",
  },
];

export const CompanyData = [
  {
    id: 1,
    company_Name: "Kolding Sky 1 ApS",
    company_Zipcode: "6000",
    company_City: "Kolding",
    Properties: "8",
    Type: "Leasing",
    Units: "1500",
    Availability: "150 units",
    Availability_percentage: "10%",
    Idleness: "55 units",
    Idleness_percentage: "8%",
    Not_active: 10,
    active: 40,
    reserved_unit: 22,
    inProcess_Unit: 38,
    completed_Unit: 88,
  },
  {
    id: 2,
    company_Name: "Retreal 1 Holte ApS",
    company_Zipcode: "2150 ",
    company_City: "Nordhavn",
    Properties: "4",
    Type: "Leasing",
    Units: "300",
    Availability: "300 units",
    units_percentage: "18%",
    Idleness: "17 units",
    Idleness_percentage: "15%",
    Not_active: "10",
    active: "40",
    reserved_unit: "22",
    inProcess_Unit: "38",
    completed_Unit: "88",
  },
  {
    id: 3,
    company_Name: "AP Ejendomme",
    company_Zipcode: "2100",
    company_City: "København ",
    Properties: "6",
    Type: "Leasing",
    Units: "721",
    Availability: "721 units",
    Availability_percentage: "10%",
    Idleness: "55 units",
    Idleness_percentage: "8%",
    Not_active: "10",
    active: "40",
    reserved_unit: "22",
    inProcess_Unit: "38",
    completed_Unit: "88",
  },
];

export const QualityControlData = [
  {
    id: 1,
    signers: "Stig Werner Isaksen",
    unit: "Blomstervangen 12, 1.tv, 2765 Smørum",
    signingCreated: "3 Jun, 2023 at 11:52 ",
    signingExpires: "17 Jun, 2023 at 12:00",
    responsibleAgent: "Thomas Møgensen",
    completed_signing: "0",
    signing_status: "3",
  },
  {
    id: 2,
    signers: "Johanne Madsen Larsen",
    unit: "Kaktus Towers 2, 17., mf, 2450 København SV",
    signingCreated: "3 Jun, 2023 at 12:52 ",
    signingExpires: "14 Jun, 2023 at 1:00",
    responsibleAgent: "Thomas Møgensen",
    completed_signing: "2",
    signing_status: "3",
  },
  {
    id: 3,
    signers: "Camilla Skæring Svendsen",
    unit: "Blomstervangen 12, 1.tv, 2765 Smørum",
    signingCreated: "3 Jun, 2023 at 11:52 ",
    signingExpires: "17 Jun, 2023 at 12:00",
    responsibleAgent: "Thomas Møgensen",
    completed_signing: "3",
    signing_status: "3",
  },
];
export const ApproveScreeningData = [
  {
    id: 1,
    name: "Stig Werner Isaksen",
    screeningstarted: "3 Jun, 2023 at 11:52 ",
    deadlinestarted: "17 Jun, 2023 at 12:00",
    responsibleAgent: "Thomas Møgensen",
    status: "Ongoing(85%)",
    action: "DECLINE SCREENING",
  },
  {
    id: 2,
    name: "Stig Werner Isaksen",
    screeningstarted: "3 Jun, 2023 at 11:52 ",
    deadlinestarted: "17 Jun, 2023 at 12:00",
    responsibleAgent: "Marianne Brostberg",
    status: "Completed(100%)",
    action: "DECLINE SCREENING",
  },
  {
    id: 3,
    name: "Stig Werner Isaksen",
    screeningstarted: "3 Jun, 2023 at 11:52 ",
    deadlinestarted: "17 Jun, 2023 at 12:00",
    responsibleAgent: "Lucas Marco Nurnberg",
    status: "Approved(100%)",
    action: "DECLINE SCREENING",
  },
  
];
export const signingOrderData = [
  {
    id: 1,
    text: "Does not meet our requirements for device rental",
  },
  {
    id: 2,
    text: "Cannot be financially approved",
  },
  {
    id: 3,
    text: "Is registered in RKI / Does not fulfil credit rules",
  },
  {
    id: 4,
    text: "Too many people in the household",
  },
  {
    id: 5,
    text: "The unit is rented to another customer",
  },
  {
    id: 6,
    text: "Other reason",
  },
];
export const appointmentData =[
  {
    Id: 1,
    Event: "Event - Public event I Triers Hus, Sunday 13....",
    Time: "3 Jun, 2023 at 11:52 - 12:55 ",
    Availability: "Available",
    BookingRequired: "No",
   
  },
  {
    Id: 2,
    Event: "Event - Public event I Triers Hus, Sunday 13....",
    Time: "3 Jun, 2023 at 11:52 - 12:55",
    Availability: "Available",
    BookingRequired: "yes,no limit",
   
  },
  {
    Id: 3,
    Event: "Event - Public event I Triers Hus, Sunday 13....",
    Time: "3 Jun, 2023 at 11:52 - 12:55 ",
    Availability: "Available (4 slots remaining)",
    BookingRequired: "yes,with limit",
   
  },
  {
    Id: 4,
    Event: "Event - Public event I Triers Hus, Sunday 13....",
    Time: "3 Jun, 2023 at 11:52 - 12:55",
    Availability: "Available",
    BookingRequired: "timeslot",
   
  },
  {
    Id: 5,
    Event: "Event - Public event I Triers Hus, Sunday 13....",
    Time: "3 Jun, 2023 at 11:52 - 12:55 ",
    Availability: "Occupied",
    BookingRequired: "timeslot",
   
  },
]

