import axios from "axios";

export const AxGet = async (url, endpoint, headers, data) => {
  // console.log("AxGet data", data == undefined || null || "" ? "" : "/" + data);
  const config = {
    headers: headers,
  };
  return await axios.get(
    `${url}${endpoint}${data == undefined || null || "" ? "" : "/" + data}`,
    config
  );
};

//Put request
export const AxPut = async (url, endpoint, headers, data) => {
  const config = {
    headers: headers,
  };
  return await axios.put(`${url}${endpoint}`, data, config);
};

//Post request
export const AxPost = async (url, endpoint, headers, data) => {
  const config = {
    headers: headers,
  };
  // console.log("axios", url, endpoint, headers, data);
  return await axios.post(`${url}${endpoint}`, data, config);
};

// export const Post = async (url, endpoint, header, data) => {
// 	const config = {
// 		headers: GetHeaders(header),
// 	};
// 	return await axios.post(`${url}${endpoint}`, data, config);
// };
//Delete request
export const AxDelete = async (url, endpoint, headers, id) => {
  const config = {
    headers: headers,
  };
  return await axios.delete(`${url}${endpoint}/${id}`, config);
};
