import { useMemo } from "react";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import MRTTheme from "../../../../Components/table/MRTTheme";
import { H4, H5 } from "../../../../styles/Common.style";
import {
  paginationoptions,
  SortIcon,
  SearchStyle,
  SearchIcon,
  TableRowsStyle,
} from "../../../../Components/table/MRTGlobalProps";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { Col, Row } from "react-bootstrap";
import Toggle from "../../../../Components/Switch/Switch";

const SelectUnitTable = ({
  heading,
  subHeading,
  // rowSelectionValue,
  rowSelection,
  setRowSelection,
  GetUnitsByPropertyIdUpdatedData,
  isTypeSwitchRequired = false, //created by Suraj for managing filter used in move to another units
  switchForSameTypeOfUnit,
  setSwitchForSameTypeOfUnit = () => {},
}) => {
  //---------------------state---------------------//
  // const [rowSelection, setRowSelection] = useState({});

  //--------------react query------------------------//
  // const { data: GetUnitsByPropertyIdUpdatedData } = useGetUnitsByPropertyIdUpdated(rowSelectionValue, 0)
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "calculated_unit_id",
        header: "No.",
        size: 100,
      },

      {
        accessorFn: (row) =>
          `${row?.street_name}${row?.zipcode_id}${row?.house_number}${row?.zipcode_id}${row?.city}`,
        header: "Address",
        size: 150,
        Cell: ({ row }) => (
          <>
            <div>{`${row?.original?.zipcode_id} ${row?.original?.city}`}</div>
          </>
        ),
      },
      {
        accessorKey: "housing_type_name", //normal accessorKey
        header: "Type",
        size: 50,
      },
      {
        accessorKey: "alt_unit_id",
        header: "Unit no.",
        size: 80,
      },
      {
        accessorKey: "floor_number",
        header: "Floor",
        size: 50,
      },
      {
        accessorKey: "area",
        header: "m2",
        Header: (
          <span>
            m<sup>2</sup>
          </span>
        ),
        size: 60,
      },
      {
        accessorKey: "no_of_rooms",
        header: "Room",
        size: 50,
      },
      {
        accessorKey: "balcony",
        header: "Balcony",
        size: 50,
      },
      {
        accessorKey: "rent",
        header: "Price",
        size: 100,
      },
      {
        accessorFn: (row) => `${row.status} ${row.bg_color} `,
        header: "Status",
        id: "Status",
        enableHiding: false,
        size: 150,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div className="d-flex flex-row gap-2">
            <div
              style={{
                color: "#FFF",
                backgroundColor:
                  row.original.Status === "Vacant"
                    ? "#349032"
                    : row.original.Status === "Ongoing screening"
                    ? "#FFDF00"
                    : "#929692",
              }}
              className="p-2 radius-4"
            ></div>
            {row.original.Status}
          </div>
        ),
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
      },
    ],
    []
  );

  return (
    <div className="whiteContainerStyle">
      <div className="px-4 py-3">
        <div className="d-flex gap-2 flex-column py-2">
          <H4>{heading}</H4>
          <div className="light-font-text">{subHeading}</div>
        </div>
        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              muiSearchTextFieldProps={{
                ...SearchStyle,

                InputProps: {
                  placeholder: "Search for a unit...",
                  ...SearchIcon,
                },
              }}
              columns={columns}
              icons={{ ...SortIcon }}
              // data={unitsData?.filter((data) => data.is_linked_with_screening !== 1) ?? []}
              data={GetUnitsByPropertyIdUpdatedData || []}
              onRowSelectionChange={setRowSelection}
              getRowId={(row) => row.unit_id}
              state={{ rowSelection }}
              enableColumnActions={false}
              enableTopToolbar={true}
              enableSelectAll={true}
              enableRowActions={false}
              enableRowSelection
              enableGlobalFilter
              renderTopToolbar={({ table }) => (
                <Row>
                  <Col lg={3}>
                    <MRT_GlobalFilterTextField table={table} />
                  </Col>
                  <Col lg={3}>
                    <ReactSelect />
                  </Col>
                  {isTypeSwitchRequired == true && (
                    <Col>
                      <div className="d-flex gap-2 align-items-center">
                        <div>
                          <Toggle
                            id={"showOnlyTheSameTypeOfUnit"}
                            checked={switchForSameTypeOfUnit}
                            onChange={(e) =>
                              setSwitchForSameTypeOfUnit(
                                !switchForSameTypeOfUnit
                              )
                            }
                          />
                        </div>
                        <H5>Show only the same type of unit</H5>
                      </div>
                    </Col>
                  )}
                </Row>
              )}
              globalFilterFn="contains"
              //   renderRowActions={({ row, table }) => (
              //     <Menus
              //       itemheight={24}
              //       itemwidth={24}
              //       iconborderradius="4px"
              //       itemcontainerwidth="150px"
              //     />
              //   )}
              muiTablePaperProps={{
                sx: {
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                },
              }}
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "",
                  size: 50,
                },
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              positionGlobalFilter="right"
              positionToolbarDropZone="bottom"
              positionActionsColumn="start"
            />
          }
        />
      </div>
    </div>
  );
};

SelectUnitTable.defaultProps = {
  heading: "Select one or more units",
  subHeading:
    "If you want to give the customer a relation to one or more units in the selected property, you can choose from the list below.",
};

export default SelectUnitTable;

//nested data is ok, see accessorKeys in ColumnDef below
// const data = [
//   {
//     No: "212-001",
//     Address: "Dybbølsbro 3, 1",
//     Type: "C2",
//     UnitNo: "7-W260",
//     Floor: "7",
//     m2: "53 m2",
//     Room: "2",
//     Balcony: "17 m2",
//     Price: "12.750,00",
//     Status: "Vacant",
//   },
//   {
//     No: "212-001",
//     Address: "Dybbølsbro 3, 1",
//     Type: "C2",
//     UnitNo: "7-W260",
//     Floor: "7",
//     m2: "53 m2",
//     Room: "2",
//     Balcony: "17 m2",
//     Price: "12.750,00",
//     Status: "Ongoing screening",
//   },
//   {
//     No: "212-001",
//     Address: "Dybbølsbro 3, 1",
//     Type: "C2",
//     UnitNo: "7-W260",
//     Floor: "7",
//     m2: "53 m2",
//     Room: "2",
//     Balcony: "17 m2",
//     Price: "12.750,00",
//     Status: "Not listed",
//   },
//   {
//     No: "212-001",
//     Address: "Dybbølsbro 3, 1",
//     Type: "C2",
//     UnitNo: "7-W260",
//     Floor: "7",
//     m2: "53 m2",
//     Room: "2",
//     Balcony: "17 m2",
//     Price: "12.750,00",
//     Status: "Vacant",
//   },
//   {
//     No: "212-001",
//     Address: "Dybbølsbro 3, 1",
//     Type: "C2",
//     UnitNo: "7-W260",
//     Floor: "7",
//     m2: "53 m2",
//     Room: "2",
//     Balcony: "17 m2",
//     Price: "12.750,00",
//     Status: "Ongoing screening",
//   },
//   {
//     No: "212-001",
//     Address: "Dybbølsbro 3, 1",
//     Type: "C2",
//     UnitNo: "7-W260",
//     Floor: "7",
//     m2: "53 m2",
//     Room: "2",
//     Balcony: "17 m2",
//     Price: "12.750,00",
//     Status: "Not listed",
//   },
//   {
//     No: "212-001",
//     Address: "Dybbølsbro 3, 1",
//     Type: "C2",
//     UnitNo: "7-W260",
//     Floor: "7",
//     m2: "53 m2",
//     Room: "2",
//     Balcony: "17 m2",
//     Price: "12.750,00",
//     Status: "Vacant",
//   },
//   {
//     No: "212-001",
//     Address: "Dybbølsbro 3, 1",
//     Type: "C2",
//     UnitNo: "7-W260",
//     Floor: "7",
//     m2: "53 m2",
//     Room: "2",
//     Balcony: "17 m2",
//     Price: "12.750,00",
//     Status: "Ongoing screening",
//   },
//   {
//     No: "212-001",
//     Address: "Dybbølsbro 3, 1",
//     Type: "C2",
//     UnitNo: "7-W260",
//     Floor: "7",
//     m2: "53 m2",
//     Room: "2",
//     Balcony: "17 m2",
//     Price: "12.750,00",
//     Status: "Not listed",
//   },
// ];

// created logs
// created by Yash
// // added heart component and tooltip component on mouse over section and commented and removed unwanted code-- Suraj
