import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  GetTextCategory,
  GetTextDetails,
  GetTextPackage,
} from "../../services/Texts";
import {
  GetUnitHousing,
  GetUnitMaintainer,
  GetUnitsByPropertyId,
  GetUnitsByPropertyIdUpdated,
  DeleteSingleUnits,
  DeleteUnitTag,
  GetCurrencyRate,
  GetEditUnitTextField,
  GetUnitEconomyDetailsByUnitId,
  GetUnitOverviewByUnitId,
  PutMultipleUnits,
  PostMultipleUnits,
  SetFavouriteUnit,
  UpdateUnitAnnounceStatus,
  EditParkingUnit,
  EditStorageUnit,
  GetParkingLotDetails,
  GetStorageRoomDetails,
  PutEditEconomyDetail,
  PutunitMarketing,
  GetStorageRoomById,
  GetUnitTextField,
  GetParkingLotById,
  PutEditUnitDetails,
  deleteSingleStorage,
  deleteSingleParking,
  GetParkingStoragePaymentType,
  GetParkingStoragePaymentFrequency,
  GetSigningOrderByPropertyId,
  PostParkingReservation,
  PostStorageReservation,
  GetStorageAndParkingHistory,
  PutUnitSetUnitStatus,
  GetUnitDetailByUnitId,
  GetMainSubStatus,
  PutUpdateMainSubStatus,
} from "../../services/Units";

// Get units by property id
export const useGetUnitsByPropertyId = (propertyId, statusId, exportId) => {
  return useQuery(
    ["qGetUnitsByPropertyId", propertyId, statusId, exportId],
    () => GetUnitsByPropertyId(propertyId, statusId, exportId),
    { refetchOnWindowFocus: true }
  );
};
//Get units by property id updated

export const useGetUnitsByPropertyIdUpdated = (propertyId, exportId) => {
  return useQuery(
    ["qGetUnitsByPropertyIdV1", propertyId, exportId],
    () => GetUnitsByPropertyIdUpdated(propertyId, exportId),
      {
        enabled: !!propertyId,
      }
    // { refetchInterval: 10000 }
    // { refetchOnWindowFocus: true }
  );
};

//Get Units Housing
export const useGetUnitHousing = () => {
  return useQuery("getUnitHousing", () => GetUnitHousing());
};

//Get Unit Maintainer
export const useGetUnitMaintainer = () => {
  return useQuery("getUnitMaintainer", () => GetUnitMaintainer());
};

//Get Unit Text details
export const useGetTextDetails = () => {
  return useQuery("getTextDetails", () => GetTextPackage());
};

//Get Unit Text category
export const useGetTextCategory = () => {
  return useQuery("getTextCategory", () => GetTextCategory(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: 10800000,
  });
};

// delete unit
export const useDeleteSingleUnit = (propertyId, UnitId) => {
  return useMutation(
    ["useDeleteSingleUnit"],
    () => DeleteSingleUnits(propertyId, UnitId),
    {
      enabled: !!UnitId,
    }
  );
};

// delete unit By Tag Id
export const useDeleteUnitTag = (unitId, tagId) => {
  return useMutation(
    ["useDeleteUnitTag"],
    (unitId, tagId) => DeleteUnitTag(unitId, tagId),
    {
      enabled: !!unitId && !!tagId,
    }
  );
};

//Get Currency Rate
export const useGetCurrencyRate = (currency, value) => {
  return useQuery(
    ["useGetCurrencyRate", value],
    () => GetCurrencyRate(currency, value),
    { enabled: !!value }
  );
};

//Get units  text field
export const useGetEditUnitsTextField = (propertyId, type) => {
  return useQuery(
    ["useGetEditUnitsTextField", propertyId, type],
    () => GetEditUnitTextField(propertyId, type),
    { enabled: !!propertyId }
  );
};
//Get units  EconomyDetails
export const useGetUnitEconomyDetailsByUnitId = (unitId) => {
  return useQuery(
    ["useGetUnitEconomyDetailsByUnitId", unitId],
    () => GetUnitEconomyDetailsByUnitId(unitId),
    { enabled: !!unitId }
  );
};
export const useGetUnitOverviewByUnitId = (unitId) => {
  return useQuery(
    ["useGetUnitOverviewByUnitIdData", unitId],
    () => GetUnitOverviewByUnitId(unitId),
    { enabled: !!unitId }
  );
};

// Add multiple units

export const useAddMultipleUnits = () => {
  const queryClient = useQueryClient();

  return useMutation(PostMultipleUnits, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        "useGetParkingLotDetails",
        "qGetUnitsByPropertyIdV1",
        "useGetStorgeRoomDetails",
        "PropertyWithOverviewByPropertyId",
      ]);
    },
  });
};

//Edit multiple units
export const usePutMultipleUnits = () => {
  return useMutation(PutMultipleUnits);
};

// set favourite units
export const useSetFavoriteUnits = () => {
  const queryClient = useQueryClient();

  return useMutation(SetFavouriteUnit, {
    onSuccess: () => {
      queryClient.invalidateQueries("qGetUnitsByPropertyIdV1");
    },
  });
};

// Update Unit Announce Status

export const useUpdateUnitAnnounceStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(UpdateUnitAnnounceStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries("qGetUnitsByPropertyIdV1");
    },
  });
};

// get parking lot details

export const useGetParkingLotDetails = (propertyId, exportFlag) => {
  return useQuery(["useGetParkingLotDetails", propertyId, exportFlag], () =>
    GetParkingLotDetails(propertyId, exportFlag)
  );
};

//Put - Edit economy details
// export const useEditEconomyDetail = () => {
//   return useMutation(PutEditEconomyDetail);
// };
export const useEditEconomyDetail = () => {
  const queryClient = useQueryClient();
  return useMutation(PutEditEconomyDetail, {
    onSuccess: () => {
      queryClient.invalidateQueries("useGetUnitOverviewByUnitIdData");
    },
  });
};
//Put - Edit unit marketing details
export const useEditUnitMarketingDetail = () => {
  return useMutation(PutunitMarketing);
};
//Put - Edit unit details

export const useEditUnitDetail = () => {
  const queryClient = useQueryClient();
  return useMutation(PutEditUnitDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries("useGetUnitOverviewByUnitIdData");
    },
  });
};
// export const useEditUnitDetail = () => {
//   return useMutation(PutEditUnitDetails);
// };
// get storage room details

export const useGetStorageRoomDetails = (propertyId, exportFlag) => {
  return useQuery(["useGetStorgeRoomDetails", propertyId, exportFlag], () =>
    GetStorageRoomDetails(propertyId, exportFlag)
  );
};
// get storage room by Id
export const useGetStorageRoomById = (unitId) => {
  return useQuery(["qUseGetStorgeRoomById", unitId], () =>
    GetStorageRoomById(unitId)
  );
};
// get parking lot by id
export const useGetParkingLotById = (unitId) => {
  return useQuery(["quseGetParkingLotById", unitId], () =>
    GetParkingLotById(unitId)
  );
};
// textfields

export const useGetUnitTextField = (propertyId, unittype) => {
  return useQuery(["qUseGetUnitTextField", propertyId, unittype], () =>
    GetStorageRoomById(propertyId, unittype)
  );
};
//edit parking lot

export const useEditParkingLot = () => {
  const queryClient = useQueryClient();

  return useMutation(EditParkingUnit, {
    onSuccess: () => {
      queryClient.invalidateQueries("quseGetParkingLotById");
    },
  });
};

// edit storage room

export const useEditStorageRoom = () => {
  const queryClient = useQueryClient();

  return useMutation(EditStorageUnit, {
    onSuccess: () => {
      queryClient.invalidateQueries("qUseGetStorgeRoomById");
    },
  });
};

// delete storage

export const useDeleteSingleStorage = (propertyId, unitId) => {
  return useMutation(
    ["useDeleteSingleStorage"],
    () => deleteSingleStorage(propertyId, unitId),
    {
      enabled: !!unitId,
    }
  );
};

// delete storage

export const useDeleteSingleParking = (propertyId, unitId) => {
  return useMutation(
    ["useDeleteSingleParking"],
    () => deleteSingleParking(propertyId, unitId),
    {
      enabled: !!unitId,
    }
  );
};

//Get Parking Storage Payment Type
export const useGetParkingStoragePaymentType = () => {
  return useQuery(["useGetParkingStoragePaymentType"], () =>
    GetParkingStoragePaymentType()
  );
};

// Get Parking Storage Payment Frequency
export const useGetParkingStoragePaymentFrequency = () => {
  return useQuery(["useGetParkingStoragePaymentFrequency"], () =>
    GetParkingStoragePaymentFrequency()
  );
};

//  Get Signing Order By PropertyId
export const useGetSigningOrderByPropertyId = (propertyId) => {
  return useQuery(
    ["GetSigningOrderByPropertyId", propertyId],
    () => GetSigningOrderByPropertyId(propertyId),
    {
      enabled: !!propertyId,
    }
  );
};

// post create parking reservation
export const useCreateParkingReservation = () => {
  const queryClient = useQueryClient();
  return useMutation(PostParkingReservation, {
    onSuccess: () => {
      queryClient.invalidateQueries("useGetParkingLotDetails");
    },
  });
};

// post create storage reservation
export const useCreateStorageReservation = () => {
  const queryClient = useQueryClient();
  return useMutation(PostStorageReservation, {
    onSuccess: () => {
      queryClient.invalidateQueries("useGetStorgeRoomDetails");
    },
  });
};

// GetStorageAndParkingHistory
export const useGetStorageAndParkingHistory = (unitId, typeOfUnit) => {
  return useQuery(
    ["qGetStorageAndParkingHistory", unitId, typeOfUnit],
    () => GetStorageAndParkingHistory(unitId, typeOfUnit),
    { enabled: !!unitId }
  );
};

//Put Unit Set Uni tStatus
export const usePutUnitSetUnitStatus = () => {
  return useMutation(PutUnitSetUnitStatus);
};

// Get unit details by unit_id
export const useGetUnitDetailsByUnitId = (unit_id) => {
  return useQuery(
    ["qGetDetailsByUnitId", unit_id],
    () => GetUnitDetailByUnitId(unit_id),
    { enabled: !!unit_id }
  );
};


//Units - Get Main sub status
//Type Id:-
//Residential: 1
//Parking: 2
//Storage: 3
export const useGetMainSubStatus = (organisation_id, type_id) => {
  return useQuery(
    ["qGetMainSubStatus", organisation_id, type_id],
    () => GetMainSubStatus(organisation_id, type_id),
    {
      enabled: !!organisation_id && !!type_id,
    }
  );
};

//Units - Put update main sub status
export const usePutUpdateMainSubStatus = () => {
  return useMutation(PutUpdateMainSubStatus);
};