import React, { useMemo, useCallback, useState, useRef } from "react";
import PNGWidget from "../../../Components/DownloadWidget/PNGWidget";
import JPGWidget from "../../../Components/DownloadWidget/JPGWidget";
import SVGWidget from "../../../Components/DownloadWidget/SVGWidget";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { Row, Col } from "react-bootstrap";
import Menus from "../../../Components/Menus/Menus";
import { Doughnut } from "react-chartjs-2";
import styled from "@emotion/styled";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import { H2, H3 } from "../../../styles/Common.style";

const TrafficOverview = () => {
  const myref = useRef();
  const [refObj] = useState(myref);
  const fileName = "traffic-overview";

  const PNGFunction = PNGWidget({ refObj, fileName });
  const JPGFunction = JPGWidget({ refObj, fileName });
  const SVGFunction = SVGWidget({ refObj, fileName });

  const ChartWrapper = styled.div`
    height: 201px;
    position: relative;
  `;
  const DoughnutInner = styled.div`
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -22px;
    text-align: center;
    z-index: 0;
  `;

  // chart data

  const data = {
    labels: ["Desktop", "Tablet", "Mobile"],
    datasets: [
      {
        data: [14, 58, 28],
        backgroundColor: ["#77C7A1", "#A2C8D0", "#F6E651"],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutout: "80%",
    borderWidth: 3,
    plugins: {
      tooltip: {
        yAlign: "top",
        backgroundColor: "#FFFFFF",
        borderWidth: 2,
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
      },
      legend: {
        display: false,
      },
    },
  };

  const plugins = [
    {
      afterDraw: function (chart) {
        if (chart.data.datasets[0].data.every((item) => item == "0")) {
          let ctx = chart.ctx;
          let width = chart.width;
          let height = chart.height;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "25px Cerebri sans";
          ctx.fillText("No data to display", width / 2, height / 2);
          ctx.restore();
        }
      },
    },
  ];

  return (
    <>
      {/* <div
        className="p-3 customers-region radius-8"
        style={{ background: "#FFFFFF" }}
        ref={myref}
      > */}
      <div className="card-body p-3" ref={myref}>
        <Row>
          <Col className="p-0" sm={8}>
            {" "}
            <div className="d-flex ps-3 align-items-center">
              <H3 className=" me-2">Traffic overview</H3>{" "}
              <CustomToolTip size="sm" text="Traffic overview" />
            </div>
          </Col>
          <Col sm={4} className="d-flex justify-content-end">
            {" "}
            <Menus
              itemheight={40}
              itemwidth={40}
              itemcontainerheight={200}
              itemcontainerwidth={200}
              component={
                <div className="fs-14">
                  <div className="addpropContent px-3 py-2 ">
                    <span className="ms-1" onClick={() => PNGFunction()}>
                      Download widget as PNG
                    </span>
                  </div>
                  <hr className="addpropertyHr m-0"></hr>
                  <div
                    onClick={() => JPGFunction()}
                    className="addpropContent px-3 pt-2 pb-2 "
                  >
                    <span className="ms-1">Download widget as JPG</span>
                  </div>
                  <hr className="addpropertyHr m-0"></hr>
                  <div
                    onClick={() => SVGFunction()}
                    className="addpropContent px-3 pt-2 pb-2 "
                  >
                    <span className="ms-1">Download widget as SVG</span>
                  </div>
                </div>
              }
            />
          </Col>
        </Row>
        {data.length == 0 ? (
          <Row className="py-4 px-3">
            <Col lg={3} sm={12}>
              <ChartWrapper>
                <DoughnutInner>
                  <div className="light-font-text fs-13">
                    Users type of
                    <br /> device
                  </div>
                </DoughnutInner>

                <Doughnut data={data} options={options} plugins={plugins} />
              </ChartWrapper>
              {/* </div> */}
            </Col>
            <Col lg={9} sm={12} className="px-3">
              <Row className="d-flex justify-content-center align-items-center">
                <Col lg={4} xs={12} className="pb-sm-3">
                  <div>
                    <div className="d-flex justify-content-between pb-3 px-3">
                      <div className="table-property-name">Source</div>
                      <div>%</div>
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-between p-3 radius-4"
                    style={{ backgroundColor: "#f4f5f5" }}
                  >
                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          borderRadius: "4px",
                          display: "inline-block",
                          marginRight: "1em",
                          height: "1em",
                          minWidth: "1em",
                          backgroundColor: "#77C7A1",
                        }}
                      ></div>
                      <div className="fs-13 light-font-text ">Desktop</div>
                    </div>
                    <div className="fs-13 form-label-title">47%</div>
                  </div>
                  <div
                    className="d-flex justify-content-between p-3 radius-4"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          borderRadius: "4px",
                          display: "inline-block",
                          marginRight: "1em",
                          height: "1em",
                          minWidth: "1em",
                          backgroundColor: "#A2C8D0",
                        }}
                      ></div>
                      <div className="fs-13 light-font-text ">Tablet</div>
                    </div>
                    <div className="fs-13 form-label-title">14%</div>
                  </div>
                  <div
                    className="d-flex justify-content-between p-3 radius-4"
                    style={{ backgroundColor: "#f4f5f5" }}
                  >
                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          borderRadius: "4px",
                          display: "inline-block",
                          marginRight: "1em",
                          height: "1em",
                          minWidth: "1em",
                          backgroundColor: "#F6E651",
                        }}
                      ></div>
                      <div className="fs-13 light-font-text">Mobile</div>
                    </div>
                    <div className="fs-13 form-label-title">28%</div>
                  </div>
                </Col>
                <Col lg={4} xs={12} className="pb-sm-3">
                  <div>
                    <div className="d-flex justify-content-between pb-3 px-3">
                      <div className="table-property-name">Visitor stats</div>
                      <div></div>
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-between p-3 radius-4"
                    style={{ backgroundColor: "#f4f5f5" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="fs-13 light-font-text ">Recordings</div>
                    </div>
                    <div className="fs-13 form-label-title">17349</div>
                  </div>
                  <div
                    className="d-flex justify-content-between p-3 radius-4"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="fs-13 light-font-text ">Users</div>
                    </div>
                    <div className="fs-13 form-label-title">0</div>
                  </div>
                  <div
                    className="d-flex justify-content-between p-3 radius-4"
                    style={{ backgroundColor: "#f4f5f5" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="fs-13 light-font-text ">Pageviews</div>
                    </div>
                    <div className="fs-13 form-label-title">30198</div>
                  </div>
                </Col>
                <Col lg={4} xs={12} className="pb-sm-3">
                  <div>
                    <div className="d-flex justify-content-between pb-3 px-3">
                      <div className="table-property-name">Benchmarks</div>
                      <div></div>
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-between p-3 radius-4"
                    style={{ backgroundColor: "#f4f5f5" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="fs-13 light-font-text ">
                        Avg. pages per visit
                      </div>
                    </div>
                    <div className="fs-13 form-label-title">1.74</div>
                  </div>
                  <div
                    className="d-flex justify-content-between p-3 radius-4"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="fs-13 light-font-text ">
                        Avg. visit duration
                      </div>
                    </div>
                    <div className="fs-13 form-label-title">2m 49s</div>
                  </div>
                  <div
                    className="d-flex justify-content-between p-3 radius-4"
                    style={{ backgroundColor: "#f4f5f5" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="fs-13 light-font-text">
                        Avg. friction score
                      </div>
                    </div>
                    <div className="fs-13 form-label-title">0.11</div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <NoDataScreen height={"377px"} imgHeight={"150px"} />
        )}
      </div>
    </>
  );
};

export default TrafficOverview;
