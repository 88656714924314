// created by -- Suraj
import React from "react";

const GroupText = ({
  onChange,
  lable,
  value,
  type,
  graylabel,
  textWidth,
  disabled,
  error,
  inputheight,
  textheight,
  squarelabel,
  name,
  id,
  dynamicGrayLabel = true,
  field,
  onWheel,
}) => {
  let divWidth = 100 - parseInt(textWidth);
  return (
    <div className="d-flex ">
      <div style={{ width: `${divWidth}%` }}>
        <div className="form-input-group ">
          <input
            style={{ height: inputheight }}
            type={type}
            placeholder={lable}
            id={id}
            value={value}
            onChange={onChange}
            disabled={disabled}
            error={error}
            name={name}
            field={field}
            onWheel={onWheel}
          />
          <label htmlFor={id}>{lable}</label>
        </div>
      </div>
      <div
        className="form-input-group-gray "
        style={{ width: textWidth, height: textheight }}
      >
        <span
          style={{
            fontSize: "14px",
          }}
        >
          {dynamicGrayLabel === true ? (
            <div dangerouslySetInnerHTML={{ __html: graylabel }}></div>
          ) : (
            graylabel
          )}
          <sup>{squarelabel}</sup>
        </span>
      </div>
    </div>
  );
};

export default GroupText;

GroupText.defaultProps = {
  type: "text",
  textWidth: "20%",
  inputheight: "40px",
  textheight: "40px",
  field: "",
};
