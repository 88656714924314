import React, { useEffect, useMemo, useState } from "react";
import MRTTheme from "../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomPopup from "../../../../Components/CustomPopup";
import { Col, Row } from "react-bootstrap";
import FilterComponent from "../../../../reactdataTableComponent/FilterComponent";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import { H4, H5, PrimarySelectedTag } from "../../../../styles/Common.style";
import ETButton from "../../../../Components/Buttons/ETButton";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import TagToolTip from "../../../../Components/Tooltip/PropertyTagToolTip";
import { useRouting } from "../../../../hooks/stores/useRoutingStore";
import { SortIcon } from "../../../../Components/table/MRTGlobalProps";
import { GetTagsByPropertyId } from "../../../../services/Tags";
import { useQuery } from "react-query";
import CustomReactSelect from "../../../../Components/DropDown/CustomReactSelect";
import { Controller, useForm } from "react-hook-form";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";
import { useAllPropertyList } from "../../../../hooks/queries/usePropertyQueries";

const SelectTag = ({
  setTagRowData = () => {},
  tagRowData,
  selectedPropertyId,
  setSelectedPropertyId = () => {},
}) => {
  //-------------------------------- all states -----------------------------//
  // const [tagRowData, setTagRowData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [openPropertyTag, setOpenPropertyTag] = useState(false);
  const [previouslySelectedData, setPreviouslySelectedData] = useState([]);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [previouslySelected, setPreviouslySelected] = useState([]);
  const [mediaStyle, setMediaStyle] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [selectedTagIdForDelete, setSelectedTagIdForDelete] = useState("");
  const { editflag } = useRouting((state) => state);

  // ------------------------------ react query --------------------------------------//
  const { data: AllPropertyListData } = useAllPropertyList(0);
  const propertyId = selectedPropertyId?.map((item) => item?.value);

  const {
    data: TagsByPropertyIdData,
    isLoading: TagsByPropertyIdDataIsLoading,
  } = useQuery(
    ["propertyId", propertyId],
    () => GetTagsByPropertyId(propertyId),
    {
      enabled: !!propertyId,
    }
  );

  // --------------------------------react hooks form -------------------------------//
  const {
    register,
    formState: { errors },
    control,
  } = useForm({});
  // console.log(getValues("selectedProperty"), "getValuesvalue");
  const columns = useMemo(
    () => [
      {
        accessorKey: "icon",
        header: "",
        enableHiding: false,
        size: 10,
        muiTableBodyCellProps: {},
        enableGlobalFilter: true,
        Cell: ({ cell }) => (
          <>
            <div>
              {cell.getValue() === "-" ? (
                <span>-</span>
              ) : (
                <TagToolTip size="lg" text="Need Help About Tags" />
              )}
            </div>
          </>
        ),
      },
      {
        accessorKey: "tag_id",
        header: "ID",
        enableHiding: false,
        size: 20,
        muiTableBodyCellProps: {},
        enableGlobalFilter: true,
      },

      {
        accessorKey: "name",
        header: "Name",
        enableHiding: false,
        size: 180,
        muiTableBodyCellProps: {},
        enableGlobalFilter: true,
      },

      {
        accessorKey: "unit_count",
        header: "In use",
        enableHiding: false,
        size: 130,
        muiTableBodyCellProps: {},
        enableGlobalFilter: true,
      },
    ],
    [tagRowData]
  );

  // --------------add tag table state and logics -----------//

  const handleClose = () => {
    setDeletePopUp(false);
  };

  // const [deletePopUp, setDeletePopUp] = useState(false);
  const handleDelete = () => {
    let newArry = tagRowData?.filter(
      (item) => item?.tag_id != selectedTagIdForDelete
    );
    setTagRowData(newArry);
    let newIdformodal = selected?.filter(
      (item) => item != selectedTagIdForDelete
    );
    setSelected(newIdformodal);
    let newDataforModal = selectedData?.filter(
      (item) => item?.tag_id != selectedTagIdForDelete
    );
    setSelectedData(newDataforModal);
    handleClose();
    setDeletePopUp(false);
  };

  const handleClick = (e, data) => {
    const tagId = e.target.value;

    // const tags = data?.map((data) => data?.tagId)
    // console.log("🚀 ~ file: AddEditPackage.js:263 ~ handleClick ~ tags:", tags)
    // setSelectedId()
    if (selected?.includes(tagId)) {
      setSelected(selected?.filter((id) => id != tagId));
      setSelectedData(
        selectedData?.filter((tag) => tag?.tag_id != parseInt(tagId))
      );
    } else {
      setSelected([...selected, tagId]);

      const isDataAlreadySelected = selectedData?.some(
        (tag) => tag?.tag_id == data?.tag_id
      );
      if (!isDataAlreadySelected) {
        setSelectedData([...selectedData, data]);
      }
    }
  };

  const handleCancelTag = () => {
    setSelected(previouslySelected);
    setSelectedData(previouslySelectedData);
    setOpenPropertyTag(false);

    if (editflag !== true) {
      setMediaStyle(false);
      setSelectedData([]);
    }
  };

  const HandleTagReset = () => {
    setMediaStyle(false);
    setSelected([]);
    setSelectedData([]);
  };
  // ------------------------------ after submit const ----------------------------------//
  const handleSubmitTag = () => {
    // used to go back in guide card page
    setPreviouslySelected(selected);
    setPreviouslySelectedData(selectedData);
    setTagRowData(selectedData);
    setOpenPropertyTag(false);
  };

  // if there is no data for table rendering table will use this data for showing '-' (hyphens) in empty cells
  const dummyData = [
    {
      tag_id: "-",
      unit_count: "-",
      name: "-",
    },
  ];

  const searchData = TagsByPropertyIdData?.data?.filter((item) => {
    if (searchText === "") {
      return item;
    } else if (item?.name?.toLowerCase()?.includes(searchText.toLowerCase())) {
      return item;
    }
  }).length;

  useEffect(() => {
    if (tagRowData?.length == 0) {
      setSelected([]);
    } else {
      const selectedTags = tagRowData?.map((item) => `${item?.tag_id}`);
      setSelected(selectedTags);
      setSelectedData(tagRowData);
    }
  }, [tagRowData]);

  // console.log(tagRowData, "tagRowData");
  // console.log(AllPropertyListData?.data, "AllPropertyListData");
  // console.log(selectedData, "selectedDataselectedData");
  // console.log(propertyId, "propertyIdpropertyId");
  // console.log(selectedPropertyId, "selectedPropertyId");
  // console.log(selected, "selected");
  return (
    <div>
      <div className=" d-flex justify-content-between ">
        <div className="d-flex form-label-title align-items-center mb-3 gap-2">
          <H4>Select tags</H4>
          <CustomToolTip text="Select tags" />
        </div>
        <div>
          <ETButton
            variant={"primary"}
            buttonText="ADD TAGS"
            icon={true}
            onClick={() => setOpenPropertyTag(true)}
          />
        </div>
      </div>
      <div className="mt-3 mb-4 pb-2 px-2">
        <MRTTheme
          colorVariantsForTableData={["odd"]}
          table={
            <MaterialReactTable
              data={tagRowData?.length ? tagRowData : dummyData}
              icons={{ ...SortIcon }}
              columns={columns}
              state={{ TagsByPropertyIdDataIsLoading: !tagRowData }}
              // data={data}
              // customStyles={customStyles}
              enableRowActions={true}
              enableTopToolbar={false}
              enableColumnActions={false}
              enableBottomToolbar={false}
              hideSortIcon={false}
              positionActionsColumn="last"
              // enableSorting={false}
              renderRowActions={({ row }) => (
                <div>
                  {row?.original?.tag_id &&
                  row?.original?.name &&
                  row?.original?.unit_count !== "-" ? (
                    <button
                      type="button"
                      className="added-document-delete-btn"
                      onClick={() => {
                        setDeletePopUp(!deletePopUp);
                        setSelectedTagIdForDelete(row?.original?.tag_id);
                      }}
                    >
                      <FontAwesomeIcon
                        icon="fa-regular fa-trash"
                        className="fs-6"
                      />
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              )}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "",
                  size: 50,
                },
              }}
              // striped
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
            />
          }
        />

        {/* </MRTTheme> */}
      </div>
      {tagRowData?.length == 0 && (
        <div className="d-flex justify-content-center w-100 fs-12  grey-text">
          <div>
            No tags are added to the guide. That’s a must before you can
            continue.
          </div>{" "}
        </div>
      )}
      <CustomPopup
        // modalHeadingStyle={modalHeadingStyle}
        // style={{ position: "relative" }}
        size="md"
        open={openPropertyTag}
        onHide={() => setOpenPropertyTag(false)}
        title="Add tag"
        title_color="black"
        titleTooltipText="Need Help About Tags"
        titleTooltip={true}
        enableCrossButton={false}
        onCloseAction={() => {
          setOpenPropertyTag(false);
          alert("Action Peformed Successfuly.");
        }}
      >
        <div>
          <div className="p-4 border-bottom">
            <H5 className="pb-3">
              Select one or more properties in the division{" "}
            </H5>
            <Controller
              control={control}
              {...register("selectedProperty")}
              rules={{
                required: false,
              }}
              render={({ field: { onChange, value } }) => (
                <CustomReactSelect
                  placeholder={"Select properties"}
                  // inputValue={value}
                  isSearchable={false}
                  isMulti={true}
                  options={AllPropertyListData?.data?.map((item) => ({
                    value: item?.property_id,
                    label: item?.propertyName,
                  }))}
                  value={selectedPropertyId}
                  // onInputChange={handleInputChange}
                  // setValue={setValue}
                  onChange={(e) => {
                    setSelectedPropertyId(e);
                  }}
                />
              )}
            />
            {errors?.contractStatusPublish && (
              <p className="error-msg">{REQUIRED_ERR}</p>
            )}
          </div>

          <Row className="p-4">
            <Col>
              <FilterComponent
                filtertext={searchText}
                handleSearch={(e) => setSearchText(e.target.value)}
              />
            </Col>
          </Row>
          <hr className="p-0 m-0 hr" />

          <div className="d-flex justify-content-between px-4 pt-3 pb-2 mb-1 ">
            <div className="form-label-title fs-14 ">
              {TagsByPropertyIdData?.data?.length}{" "}
              {TagsByPropertyIdData?.data?.length == 1 ? "tag" : "tags"}{" "}
              available on selected properties
            </div>
            <div>
              <button
                onClick={HandleTagReset}
                type="button"
                className=" fs-12 form-label-title"
                style={{
                  height: "23px",
                  width: "56px",
                  borderRadius: "4px",
                  backgroundColor: "rgba(240, 240, 240, 1)",
                  border: "1px solid rgba(209, 209, 209, 1)",
                }}
              >
                Reset
              </button>
            </div>
          </div>
          <div
            className="ps-3 py-2 pe-2 scrollable-container-y"
            style={{
              height: "320px",
              marginRight: "4px",
            }}
          >
            {searchData == 0 ? (
              <div
                className="fs-12 light-font-text grey-text"
                style={{ textAlign: "center" }}
              >
                You do not have any tag yet.
              </div>
            ) : (
              <>
                {TagsByPropertyIdData?.data
                  ?.filter((item) => {
                    if (searchText === "") {
                      return item;
                    } else if (
                      item?.name
                        ?.toLowerCase()
                        ?.includes(searchText?.toLowerCase())
                    ) {
                      return item;
                    }
                  })

                  ?.map((data, index) => (
                    <PrimarySelectedTag
                      mode={selected?.includes(String(data?.tag_id))}
                      id={index}
                      key={index}
                      className="d-flex mb-2 justify-content-between align-items-center light-font-text  ps-3 pe-2  cursor-pointer mx-2"
                    >
                      <div>{data?.name}</div>
                      <div>
                        <CustomCheckBox
                          id={data?.tag_id?.toString()}
                          checked={selected?.includes(String(data?.tag_id))}
                          value={data?.tag_id}
                          onChange={(e) => {
                            handleClick(e, data);
                            setSelectedId(data?.tag_id);
                          }}
                        />
                      </div>
                    </PrimarySelectedTag>
                  ))}
              </>
            )}
          </div>
        </div>
        <div
          className="py-2 px-3 d-flex  justify-content-between  justify-content-center footer-section-button-container"
          style={{
            zIndex: "5",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            type="button"
            onClick={() => handleCancelTag()}
          />

          <ETButton
            variant={"primary"}
            buttonText="ADD"
            type={"button"}
            onClick={handleSubmitTag}
            disabled={
              selected?.length == 0 && selectedData?.length == 0 ? true : false
            }
          />
        </div>
      </CustomPopup>

      {/* -------------------- Delete ------------------------ */}
      <CustomPopup
        // modalHeadingStyle={modalHeadingStyle}
        style={{ position: "relative" }}
        size="md    "
        open={deletePopUp}
        onHide={() => setDeletePopUp(false)}
        title="Are you carrying out the trash?"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() => {
          setDeletePopUp(false);
        }}
      >
        <div className="p-4">
          <div className="fs-14 fw-300 text-left mb-3">
            <p>Are you sure you want to delete the tag?</p>
            {/* <p>That will conflict with.....</p> */}
          </div>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => setDeletePopUp(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="I'M SURE"
            onClick={() => handleDelete()}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default SelectTag;

// Created by Suraj
