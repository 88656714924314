import React, { useEffect, useMemo, useState } from "react";
import { CircleMsg, H3 } from "../../../../styles/Common.style";
import MRTTheme from "../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { Col, Row } from "react-bootstrap";
import { NoRecordsToDisplay } from "../../../../utils/validationConstants";
import DateRangeFilter from "../../../../Components/DateRangePicker/DateRangeFilter";
import Menus from "../../../../Components/Menus/Menus";
import {
  SortIcon,
  SearchStyle,
  SearchIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../../Components/table/MRTGlobalProps";
import { useMediaQuery } from "react-responsive";
import { QualityControlData } from "../../../overview/StaticData";
import CustomPopup from "../../../../Components/CustomPopup";
import ETButton from "../../../../Components/Buttons/ETButton";
import SigningOrderMouserOver from "../pages/signingOrderMouseOver/SigningOrderMouseOver";
import FollowUp from "../../standardComponents/follow up/FollowUp";
const CancelledOrders = () => {
  /* ------------------ State ----------------- */

  const isDesktop = useMediaQuery({ minWidth: 1224 });
  const [columnPinning, setColumnPinning] = useState({});
  const [removeCancelledOrders, setRemoveCancelledOrders] = useState(false);
  /*-- Follow-up customer  --*/
  const [showFollowsUpWizard, setShowFollowsUpWizard] = useState(false);
  const [selectedFollowUpIndex, setSelectedFollowUpIndex] = useState(0);
  const handleCustomerDetails = (pageIndex = 0) => {
    // console.log(pageIndex, "i asssssssssssss");
    setShowFollowsUpWizard(true);
    setSelectedFollowUpIndex(pageIndex);
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) =>
          `${row.total_Signing_status} ${row.signing_status}`,
        header: "Status",
        enableHiding: false,
        size: 90,
        enableGlobalFilter: true,
        Cell: ({ row }) => <SigningOrderMouserOver row={row} />,
        muiTableBodyCellProps: {
          sx: {
            backgroundColor: "#F0F0F0 !important",
            border: "none !important",
          },
        },
      },
      {
        accessorKey: "signers",
        header: "Signers",
        size: 200,
        enableGlobalFilter: true,
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
            backgroundColor: "#F0F0F0 !important",
            border: "none !important",
            borderRight: "1px solid #888888 !important",
            boxShadow: "none !important",
          },
        },
        Cell: ({ row }) => (
          <div className=" d-flex align-items-center gap-1">
            <div>{row?.original?.signers}</div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "22px",
                height: "22px",
                borderRadius: "100px",
                backgroundColor: "#FFFFFF",
                border: "1px solid #D1D1D1",
                alignItems: "center",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              +3
            </div>
          </div>
        ),
      },
      {
        accessorKey: "unit",
        header: "Unit",
        enableHiding: false,
        size: 300,
        enableGlobalFilter: true,
      },
      {
        accessorKey: "signingCreated",
        header: "Signing created",
        enableHiding: false,
        enableGlobalFilter: true,
      },
      {
        accessorKey: "signingExpires",
        header: "Signing expires",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: true,
      },
      {
        accessorKey: "responsibleAgent",
        header: "Responsible Agent",
        enableHiding: false,
        // size: 20,
        enableGlobalFilter: false,
        muiTableBodyCellProps: {
          sx: {
            border: "none !important",
          },
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (isDesktop) {
      setColumnPinning({
        left: ["Status", "signers"],
        right: ["mrt-row-actions"],
      });
    } else {
      setColumnPinning({
        right: ["mrt-row-actions"],
      });
    }
  }, [isDesktop]);
  return (
    <div>
      <div className="d-flex w-100">
        <div className="p-4 mt-2 w-75">
          <div className="d-flex align-items-center">
            <H3>Cancelled orders</H3>
            <CircleMsg>
              <span>3</span>
            </CircleMsg>
          </div>
          <div className="light-font-text mt-2">
            Below is a list of cancelled signing orders
          </div>
        </div>
      </div>
      <div className="px-4 pb-5">
        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={columns}
              data={QualityControlData} //data is undefined on first render
              // data={[]}
              icons={{ ...SortIcon }}
              localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
              enableColumnActions={false}
              enableTopToolbar={true}
              enableRowSelection={false}
              enableRowActions
              enableSelectAll
              enablePinning
              onColumnPinningChange={setColumnPinning}
              state={{ columnPinning }}
              enableFilterMatchHighlighting={true}
              enableGlobalFilter
              globalFilterFn="contains"
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "",
                  size: 50,
                  muiTableBodyCellProps: {
                    sx: {
                      // padding: "0px !important",
                      backgroundColor: "#F0F0F0 !important",
                      border: "none !important",
                    },
                  },
                },
              }}
              positionActionsColumn="last"
              renderRowActions={({ row }) => (
                <Menus
                  itemheight={23}
                  itemwidth={23}
                  iconborderradius="4px"
                  itemcontainerwidth="150px"
                  component={
                    <div className="light-font-text">
                      <div
                        className="p-2 cursor-pointer"
                        onClick={() => setRemoveCancelledOrders(true)}
                      >
                        Remove
                      </div>
                      <hr className="hr m-0"></hr>
                      <div className="p-2 cursor-pointer">Go to contract</div>
                      <hr className="hr m-0"></hr>
                      <div
                        className="p-2 cursor-pointer"
                        onClick={() => handleCustomerDetails(2)}
                      >
                        Go to customer card
                      </div>
                    </div>
                  }
                />
              )}
              renderTopToolbar={({ table }) => (
                <>
                  <div className="px-0 ">
                    <Row>
                      <Col xl={4} lg={6} className="pb-2">
                        <DateRangeFilter
                        // date={dateRange}
                        // setDate={setDateRange}
                        // handleDateChange={handleDateChange}
                        />
                      </Col>
                      <Col xl={3} lg={5} className="ps-lg-0 pb-2">
                        <ReactSelect placeholder={"Responsible agent"} />
                      </Col>
                    </Row>
                  </div>
                </>
              )}
              muiTablePaperProps={{
                selected: false,
                elevation: 0,
                fontFamily: "Cerebri sans-light !important",
                sx: {
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                  "& td:nth-last-child(1)": {
                    borderLeft: "1px solid #888888 !important",
                  },
                },
              }}
              muiSearchTextFieldProps={{
                ...SearchStyle,
                InputProps: {
                  ...SearchIcon,
                },
              }}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
            />
          }
        />
      </div>
      {/* ------------------ Remove Cancelled orders ----------------- */}
      <CustomPopup
        open={removeCancelledOrders}
        onHide={() => setRemoveCancelledOrders(false)}
        title="Remove signing order"
        size="md"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        // subTitle={<div>xs</div>}
        titleTooltip={true}
        titleTooltipText={"Remove signing order"}
      >
        <div className="px-4 py-4">
          <div className="light-font-text">
            By removing a cancelled signing order, the entire signing order is
            removed from the system, including the documents it contains.
          </div>
        </div>
        <div
          className="py-2 px-3 d-flex justify-content-end"
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <ETButton
            variant={"primary"}
            buttonText="Remove"
            fontSize="14px"
            fontWeight="500"
            onClick={() => setRemoveCancelledOrders(false)}
          />
        </div>
      </CustomPopup>
      <FollowUp
        setFollowUp={setShowFollowsUpWizard}
        followUp={showFollowsUpWizard}
        selectedIndex={selectedFollowUpIndex}
        setSelectedIndex={setSelectedFollowUpIndex}
      />
    </div>
  );
};
export default CancelledOrders;
