import React from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../../../../../Components/Buttons/ETButton";
import CustomCheckBox from "../../../../../../Components/Checkbox/Checkbox";
import CustomPopup from "../../../../../../Components/CustomPopup";
import FilterComponent from "../../../../../../reactdataTableComponent/FilterComponent";
import { useQuery } from "react-query";
import { GetTagsByPropertyId } from "../../../../../../services/Tags";

const AddTagModal = ({
  openPropertyTag,
  modalHeadingStyle,
  setOpenPropertyTag,
}) => {
    // dummy property ID has to remove after api integration
    let propertyId = 6;
    const [selected, setSelected] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
  //------------------react query-----------------------------//
  const { data: tagsByPropertyIdData } = useQuery(
    ["propertyId", propertyId],
    () => GetTagsByPropertyId(propertyId),
    {
      enabled: !!propertyId,
    }
  );

  const TagData = [
    { Id: "1", Text: "#2-bedroom apartments" },
    { Id: "2", Text: "#rooftop" },
    { Id: "3", Text: "#groundfloor apartments" },
    { Id: "4", Text: "#basement" },
    { Id: "5", Text: "#balcony 3-bedroom " },
    { Id: "6", Text: "#3-bedroom apartments" },
    { Id: "7", Text: "Top apartments" },
    { Id: "8", Text: "roofbottom" },
    { Id: "9", Text: "#parking" },
  ];

  const [mediaStyle, setMediaStyle] = useState(false);
  const [checked, setChecked] = useState("");
  const [searchText, setSearchText] = useState("");

  const handleCancelTag = () => {
    setOpenPropertyTag(false);
  };

  const handleAddTags = () => {
    setOpenPropertyTag(false);
  };

  const HandleTagReset = () => {
    setMediaStyle(false);
    setSelected([]);
    setSelectedData([]);
  };

  const handleCheckTag = (e, index) => {
    console.log("event", e.target.id);
    console.log("index", index);

    // if(e.target.id === index){

    setMediaStyle(!mediaStyle);
    setChecked(e);
    // }
  };

    //------------------------- checkbox logic-------------------- /
    const handleClick = (e, data) => {
      const { id, checked } = e.currentTarget;
      if (checked) {
        let newArry = [...selected, id];
        let newData = [...selectedData, data];
        setSelected(newArry);
        setSelectedData(newData);
      } else {
        let newArry = selected.filter((item) => item != String(id));
        let newData = selectedData.filter((item) => item.tag_id != String(id));
        if (newArry.length != 0) {
          setSelected(newArry);
        } else {
          setSelected([]);
        }
        if (selectedData.length != 0) {
          setSelectedData(newData);
        } else {
          setSelectedData([]);
        }
      }
    };

  return (
    <>
      <div>
        <CustomPopup
          modalHeadingStyle={modalHeadingStyle}
          style={{ position: "relative" }}
          size="md"
          open={openPropertyTag}
          onHide={() => setOpenPropertyTag(false)}
          title="Add tag"
          title_color="black"
          titleTooltipText="Need Help About Tags"
          titleTooltip={true}
          enableCrossButton={false}
          onCloseAction={() => {
            setOpenPropertyTag(false);
            alert("Action Peformed Successfuly.");
          }}
        >
          {/* <div>
            <Row className="p-4">
              <Col>
                <FilterComponent
                  filtertext={searchText}
                  handleSearch={(e) => setSearchText(e.target.value)}
                />
              </Col>
            </Row>
            <hr
              className="p-0 m-0"
              style={{ color: "rgba(240, 240, 240, 1)" }}
            />
            <div className="d-flex justify-content-between px-4 pt-3 pb-2 ">
              <div className="fw-600 fs-14 ">
                {tagsByPropertyIdData?.data?.length} Tags
              </div>
              <div>
                <button
                  onClick={HandleTagReset}
                  className="fw-600 fs-12"
                  style={{
                    height: "23px",
                    width: "56px",
                    borderRadius: "4px",
                    backgroundColor: "rgba(240, 240, 240, 1)",
                    border: "1px solid rgba(209, 209, 209, 1)",
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
            <div
              className="ps-3 pt-2 pe-2 scrollable-container-y"
              style={{
                height: "320px",
                marginRight: "6px",
                paddingBottom: "60px",
              }}
            >
              {TagData?.map((data, index) => (
                <div
                  id={index}
                  key={index}
                  onClick={(e) => handleCheckTag(e, index)}
                  className="d-flex justify-content-between align-items-center  ps-3 pe-2 py-2 cursor-pointer"
                  style={{
                    backgroundColor: mediaStyle ? "#ECF1E6" : "#F9FAFA",
                    border: mediaStyle
                      ? "1px solid #619130"
                      : " 1px solid #F0F0F0",
                    margin: "5px",
                    borderRadius: "8px",
                  }}
                >
                  <div>{data?.Text}</div>
                  <div>
                    <CustomCheckBox
                      checked={mediaStyle}
                      onClick={() => {
                        setMediaStyle(!mediaStyle);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div> */}
          <div>
            <Row className="p-4">
              <Col>
                <FilterComponent
                  filtertext={searchText}
                  handleSearch={(e) => setSearchText(e.target.value)}
                />
              </Col>
            </Row>
            <hr
              className="p-0 m-0"
              style={{ color: "rgba(240, 240, 240, 1)" }}
            />
            <div className="d-flex justify-content-between px-4 pt-3 pb-2 ">
              <div className="fw-600 fs-14 ">
                {tagsByPropertyIdData?.data?.length} Tags
              </div>
              <div>
                <button
                  onClick={HandleTagReset}
                  className="fw-600 fs-12"
                  style={{
                    height: "23px",
                    width: "56px",
                    borderRadius: "4px",
                    backgroundColor: "rgba(240, 240, 240, 1)",
                    border: "1px solid rgba(209, 209, 209, 1)",
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
            <div
              className="ps-3 pt-2 pe-2 scrollable-container-y"
              style={{
                height: "320px",
                marginRight: "6px",
                paddingBottom: "60px",
              }}
            >
              {tagsByPropertyIdData?.data
                ?.filter((item) => {
                  if (searchText === "") {
                    return item;
                  } else if (
                    item.name.toLowerCase().includes(searchText.toLowerCase())
                  ) {
                    return item;
                  }
                })
                .map((data, index) => (
                  <div
                    style={{
                      backgroundColor:
                        selected?.includes(String(data?.tag_id)) &&
                        "rgba(97, 145, 48, 0.08)",
                      border: selected?.includes(String(data?.tag_id))
                        ? "1px solid #619130"
                        : "1px solid #D1D1D1",
                      margin: "5px",
                      borderRadius: "8px",
                    }}
                    id={index}
                    key={index}
                    className="d-flex justify-content-between align-items-center  ps-3 pe-2 py-2 cursor-pointer"
                  >
                    <div>{data?.name}</div>
                    <div>
                      <CustomCheckBox
                        // checked={tagsByPropertyIdData[data?.tag_id]}
                        id={data?.tag_id}
                        checked={
                          // isEditPackage
                          //   ? editCheckedId?.includes(
                          //       String(data?.tag_id)
                          //     ) || selected?.includes(String(data?.tag_id)
                          //     )
                          //   :
                          selected?.includes(String(data?.tag_id))
                        }
                        value={data?.tag_id}
                        onChange={(e) => {
                          handleClick(e, data);
                          // setMediaStyle(!mediaStyle);
                          // setChecked(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div
            className="py-2 px-3 d-flex  justify-content-between  justify-content-center "
            style={{
              backgroundColor: "#F9FAFA",
              borderRadius: " 0px 0px 8px 8px",
              position: "absolute",
              width: "100%",
              bottom: "0",
            }}
          >
            <ETButton
                variant = {"white"}
              buttonText="CANCEL"
              onClick={() => handleCancelTag()}
            />

            <ETButton
              variant={"primary"}
              buttonText="ADD"
              onClick={handleAddTags}
              // onClick={() => {
                // handleThirdScreen();
              // }}
            />
          </div>
        </CustomPopup>
      </div>
    </>
  );
};

export default AddTagModal;
