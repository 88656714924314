import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../../Components/Tabs/ReactTabs.style";
import ETButton from "../../../Components/Buttons/ETButton";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import Configuration from "./Configuration";
import Labels from "./labels/Labels";
import { useEffect, useState } from "react";
import { usePropertyId } from "../../../hooks/stores/usePropertyStore";
import { useGetPromotionLabelByPropertyId } from "../../../hooks/queries/usePromotionLabelQueries";
import { useRouting } from "../../../hooks/stores/useRoutingStore";
import { useBreadcrumb } from "../../../Components/BreadcrumbContext ";
import { H2 } from "../../../styles/Common.style";
const PromotionLabel = () => {
  //--------------zustand----------//
  const propertyId = usePropertyId((state) => state.propertyId);
  //---------------------states----------------------//
  const [activeAddPromotionLabel, setActiveAddPromotionLabel] = useState(false);
  const [showPromotionLabelPopup, setShowPromotionLabelPopup] = useState(false);

  const [stepperState, setStepperState] = useState({
    internal_label: "",
    property_id: "",
    is_price_visible: "",
    font_color: "",
    background_color: "",
    language: [
      {
        language_id: "",
        external_label: "",
        price_text_label: "",
        language_text: null,
      },
    ],
  });
  const [promotionLabelFlow, setPromotionLabelFlow] = useState(
    "CreateNewPromotionLabel"
  );
  const [statusProgress, setStatusProgress] = useState(0);
  const [isPromotionLabelEdit, setIsPromotionLabelEdit] = useState(false);
  //----------------React query---------------//
  const {
    data: getPromotionLabelByPropertyIdData,
    refetch: getPromotionLabelByPropertyIdDataRefetch,
  } = useGetPromotionLabelByPropertyId(propertyId);
  const {
    setSelectedSubTabType,
    setSelectedSubForSetting,
    selectedSubForSetting,
    setSelectedSubTab,
    selectedSubTabType,
  } = useRouting((state) => state);
  //----------------handle add promotion label----------------//
  const handleAddPromotionLabel = () => {
    setStepperState((prevState) => {
      return {
        ...prevState,
        internal_label: "",
        property_id: propertyId,
        is_price_visible: "",
        font_color: "#000000",
        background_color: "#000000",
        language: [
          {
            language_id: "",
            external_label: "",
            price_text: "",
            language_text: null,
          },
        ],
      };
    });
    setShowPromotionLabelPopup(true);
    setPromotionLabelFlow("CreateNewPromotionLabel");
    setStatusProgress(33.33);
    setIsPromotionLabelEdit(false);
  };
  const { updateBreadcrumbs } = useBreadcrumb();

  // const { updateBreadcrumbs } = useBreadcrumb();
  // useEffect(() => {
  //   if (selectedSubTabType === "Promotion label") {
  //     updateBreadcrumbs(['Package1234']);
  //   } else {
  //     // updateBreadcrumbs(['Facilities']);
  //   }
  // }, [selectedSubTabType]);
  return (
    <div className="radius-8 bg-white">
      <div className="px-4 py-3 " style={{ borderBottom: "1px solid #F0F0F0" }}>
        <div className=" d-flex align-items-center">
          <H2 >Promotion label</H2>
          <div className="ps-2 ">
            <CustomToolTip size="sm" text="Promotion label" />
          </div>
        </div>
      </div>
      <Tabs className="d-flex flex-column m-0">
        <div
          className="documents-tabs px-4 py-3  d-flex justify-content-between flex-wrap gap-2"
          style={{
            borderBottom: "1px solid #F0F0F0",
          }}
        >
          <TabList className=" d-flex  ps-0 m-0 " style={{ gap: "7px" }}>
            <Tab
              onClick={() => {
                setActiveAddPromotionLabel(false);
                setSelectedSubForSetting(" Configuration");
                updateBreadcrumbs(['Settings', "Promotion label", "Configuration"]);
              }}
            >
              Configuration
            </Tab>
            <Tab
              onClick={() => {
                setActiveAddPromotionLabel(true);
                setSelectedSubForSetting("Labels");
                updateBreadcrumbs(['Settings', "Promotion label", "Labels"]);
              }}
            >
              Labels
            </Tab>
          </TabList>

          {activeAddPromotionLabel && (
            <div className="mt-2">
              <ETButton
                variant={"primary"}
                buttonText="ADD PROMOTION LABEL"
                icon={true}
                width="fit-content"
                onClick={() => handleAddPromotionLabel()}
              />
            </div>
          )}
        </div>
        <div>
          <TabPanel>
            <Configuration
              getPromotionLabelByPropertyIdData={
                getPromotionLabelByPropertyIdData
              }
              handleAddPromotionLabel={handleAddPromotionLabel}
            />
          </TabPanel>
          <TabPanel>
            <Labels
              getPromotionLabelByPropertyIdData={
                getPromotionLabelByPropertyIdData
              }
              getPromotionLabelByPropertyIdDataRefetch={
                getPromotionLabelByPropertyIdDataRefetch
              }
              stepperState={stepperState}
              setStepperState={setStepperState}
              showPromotionLabelPopup={showPromotionLabelPopup}
              setShowPromotionLabelPopup={setShowPromotionLabelPopup}
              promotionLabelFlow={promotionLabelFlow}
              setPromotionLabelFlow={setPromotionLabelFlow}
              statusProgress={statusProgress}
              setStatusProgress={setStatusProgress}
              isPromotionLabelEdit={isPromotionLabelEdit}
              setIsPromotionLabelEdit={setIsPromotionLabelEdit}
            />
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
};

export default PromotionLabel;
