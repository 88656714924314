import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import ETButton from "../../../../Components/Buttons/ETButton";
import SigningCard from "../../../../Components/card/SigningCard";

export const TextCard = ({ data, onChange, value }) => {
  const [documentCardStyle, setDocumentCardStyle] = useState(false);

  return (
    <div
      className="MainDiv"
      style={{
        backgroundColor: documentCardStyle ? "#ECF1E6" : "#F9FAFA",
        border: documentCardStyle ? "1px solid #619130" : " 1px solid #F0F0F0",
      }}
    >
      <div className="Card-Name">
        <span style={{ position: "absolute", right: "1px", top: "1px" }}>
          {" "}
          <CustomCheckBox
            value={value}
            onChange={onChange}
            onClick={() => {
              setDocumentCardStyle(!documentCardStyle);
            }}
          />{" "}
        </span>
        <div className="form-label-title fs-12">Package Name</div>
        <div>
          <Row>
            <Col className="light-font-text fs-12" md={12}>
              {data?.document_package_name}
            </Col>
          </Row>
        </div>
      </div>

      <div
        className="Media"
        style={{ position: "relative", marginTop: "53px" }}
      >
        <div
          style={{
            padding: "10px 11px 10px 11px",
            backgroundColor: "White",
            position: "absolute",
            fontSize: "10px",
            fontFamily: "Cerebri sans-light",
            borderRadius: "8px",
            width: "223px",
            height: "150px",
            border: "1px solid #f0f0f0",
          }}
        >
          <div
            className="pt-2"
            style={{
              fontSize: "10px",
              fontFamily: "Cerebri sans-light",
              textAlign: "justify",
            }}
          >
            {data?.doc_files?.map(
              (item, index) =>
                index < 4 && (
                  <div key={index} className="d-flex gap-2">
                    <div style={{ width: "5%" }} className=" fs-12">
                      <FontAwesomeIcon icon="fa-regular fa-file-pdf" />
                    </div>
                    <div className="ellipsis " style={{ width: "95%" }}>
                      <p className="light-font-text fs-12 mb-0 ellipsis">
                        {item?.name}
                      </p>
                    </div>
                  </div>
                )
            )}
            {data?.doc_files?.length > 4 && (
              <div>
                <span className="fw-600 fs-13 text-dark">
                  +{data?.doc_files?.length - 4} document
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SelectSigningPackage = ({
  tagName,
  setStepWizard,
  progress,
  setProgress,
  getSigningPackagesByPropertyIdData,
  signingPackageId,
  setSigningPackageId,
}) => {
  const handleSixthScreen = () => {
    setStepWizard("SelectUnits");
    setProgress(progress + 12.5);
  };
  const handleFifthScreen = () => {
    setStepWizard("SelectDocumentPackage");
    setProgress(progress - 12.5);
  };

  const CheckedDocumentsCards = (event, item) => {
    if (event.target.checked) {
      setSigningPackageId((value) => [...value, item]);
    } else {
      setSigningPackageId((value) =>
        value.filter(
          (it) => it?.document_package_id !== item?.document_package_id
        )
      );
    }
  };
  // console.log(signingPackageId, "signingPackageId");
  // console.log(getSigningPackagesByPropertyIdData,"Filtered signing data");

  return (
    <div>
      {" "}
      <div className="mb-5 ps-4 pt-3">
        <div className="fs-14 ">
          <span className="form-label-title">Tag name : </span>
          {tagName}
        </div>
        <div className="light-font-text grey-text mb-4  pb-2">
          Do you already have a plan for your new tag. It’s here possible to
          already put the tag on one of the signing packages created on the
          property.
        </div>
        <div>
          {getSigningPackagesByPropertyIdData == 0 ? (
            <div
              className="grey-text w-100 light-font-text"
              style={{ textAlign: "center" }}
            >
              You do not have any package yet.{" "}
            </div>
          ) : (
            <>
              <Row>
                {getSigningPackagesByPropertyIdData?.map((item, i) => (
                  <Col xl={4} lg={6} className="pb-4" key={i}>
                    <SigningCard
                      data={item}
                      isTag={"AddTagS"}
                      checkboxOnChange={(e) => CheckedDocumentsCards(e, item)}
                      checkValue={signingPackageId?.filter(
                        (x) =>
                          x.document_package_id === item?.document_package_id
                      )}
                    />
                  </Col>
                ))}
              </Row>
            </>
          )}
        </div>
      </div>
      <div
        className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container"
        style={{
          position: "absolute",
          width: "100%",
          bottom: "0",
        }}
      >
        <ETButton
          variant={"white"}
          buttonText="PREVIOUS"
          onClick={() => handleFifthScreen()}
        />

        <ETButton
          variant={"primary"}
          buttonText="NEXT"
          onClick={() => {
            handleSixthScreen();
          }}
        />
      </div>
    </div>
  );
};

export default SelectSigningPackage;
