import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Accordion, Card, useAccordionButton } from "react-bootstrap";
import "./accordion.css";

const ETAccordion = ({
  header,
  content,
  contextMenuRequired = false,
  menusContent,
  leftMenusContent,
  buttonToggle,
  activeAccordion,
  accordionIndex,
  onAccordionToggle,
}) => {
  // const [openAccordion, setOpenAccordion] = useState(false);
  // // custom button for screening accordion
  // const [screeningAccordionToggle, setScreenAccorionToggle] = useState(true);
  // console.log(
  //   screeningAccordionToggle,
  //   `screeningAccordionToggle${accordionIndex}`
  // );
  // function CustomToggle({ children, eventKey, status }) {
  //   const decoratedOnClick = useAccordionButton(eventKey, () => {
  //     setScreenAccorionToggle((prev) => !prev);
  //   });
  const isOpen = activeAccordion === accordionIndex;

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      onAccordionToggle(accordionIndex);
    });

    return (
      <>
        {buttonToggle === true ? (
          <button
            className="document-property-table-btn d-flex justify-content-center align-items-center"
            onClick={decoratedOnClick}
          >
            <FontAwesomeIcon
              icon={`fa-regular ${isOpen ? "fa-angle-up" : "fa-angle-down"}`}
              style={{ color: "#000000" }}
            />
          </button>
        ) : (
          <FontAwesomeIcon
            icon={`fa-regular ${isOpen ? "fa-angle-up" : "fa-angle-down"}`}
            className="d-block pe-2"
            onClick={decoratedOnClick}
            size="xl"
            style={{ cursor: "pointer" }}
          />
        )}
      </>
    );
  }
  return (
    <>
      <Accordion
        className="my-2 w-100 radius-8 et-accordion"
        // onSelect={() => setOpenAccordion(true)}
        activeKey={isOpen ? "1" : ""}
      >
        <Card
          className="radius-8 "
          style={{
            border: " 1px solid  #D1D1D1",
          }}
        >
          <Card.Header className="border-bottom-0 p-0 radius-8">
            <Row className=" d-flex align-items-start ps-3  pe-4 gap-2 gap-sm-0">
              <Col className="d-flex px-0 align-items-center flex-wrap" xs={9}>
                {header}
              </Col>

              <Col className="p-4" xs={3} style={{ height: "100%" }}>
                <div className="d-flex justify-content-end  gap-2">
                  {contextMenuRequired === true && leftMenusContent}

                  <CustomToggle eventKey={"1"} status={"status"} />
                  {contextMenuRequired === true && menusContent}
                </div>
              </Col>
            </Row>
          </Card.Header>
          <Accordion.Collapse
            className={`${isOpen ? "background-primary" : ""} `}
            eventKey={"1"}
          >
            <Card.Body
              className="p-0"
              style={{ borderTop: "1px solid #d1d1d1", overflow: "hidden" }}
            >
              {content}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

export default ETAccordion;
