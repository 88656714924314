// UI created by Suraj
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import Toggle from "../../../../Components/Switch/Switch";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { Col, Form, Row } from "react-bootstrap";
import { H3, PlusIcon } from "../../../../styles/Common.style";
import ETButton from "../../../../Components/Buttons/ETButton";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useScreening } from "../../../../hooks/stores/useScreeningStore";
import { data } from "jquery";
import { toast } from "react-toastify";

const FieldConditions = ({
  setFieldCondition,
  fieldsData,
  // actionType,
  // setActionType,
  // logicType,
  // setLogicType,
  cardData,
  screeningSectionData,
  screeningData,
  handleFieldConditionSubmit,
  // handleAddScreening,
  // setValue,
  // getValues,
  //   errors,
  // register,
  // handleSubmit,
  // control,
  // useForm,
  Controller,
  screeningCategoryId,
}) => {
  // console.log("fieldsData", fieldsData);
  // console.log("screeningSectionData", screeningSectionData);
  // console.log("🚀 ~ file: FieldConditions.js:14 ~ FieldConditions ~ fieldsData:", fieldsData)
  const {
    setIsRadioChecked,
    isRadioChecked,
    setPossibleValue,
    possibleValue,
    fieldConditionRules,
    setFieldConditionRules,
    actionType,
    setActionType,
    logicType,
    setLogicType,
    // setFieldConditions,
    // screeningSectionData,
    // setScreeningSectionData,
    fieldConditionsStore,
    setFieldConditionsStore,
  } = useScreening((state) => state);


  const [isFirstToggleChecked, setIsFirstToggleChecked] = useState(false);

  const handleBackClick = () => {
    // Reset the radio input when "back" is clicked
    setIsRadioChecked(false);
  };
  const {
    register,
    control,
    handleSubmit,
    getValues,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      //   fieldConditions: [{ row_id: Math.floor(Date.now() / 1000), fields: [] }],
      fieldConditions: [
        {
          row_id: Math.floor(Date.now() / 1000),
          fields: "",
          condition: "",
          optionValue: "",
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "fieldConditions", // Adjust the name based on your data structure
  });
  const [fieldId, setFieldId] = useState("");

  useEffect(() => {
    const fieldConditionsData = fieldConditionsStore?.filter(
      (x) => x?.screening_field_id === fieldsData?.[0]?.screening_field_id
    )?.[0]?.field_conditions;

    setActionType(
      fieldConditionsData?.[0]?.field_visibility
        ? fieldConditionsData?.[0]?.field_visibility
        : actionType
    );
    setValue(
      "All",
      fieldConditionsData?.[0]?.field_action
        ? fieldConditionsData?.[0]?.field_action
        : ""
    );
    setIsFirstToggleChecked(
      fieldConditionsData?.[0]?.is_field_condition_active === 1 ? true : false
    );

    reset(
      {
        fieldConditions: fieldConditionsData?.[0]?.fields?.length
          ? fieldConditionsData?.[0]?.fields?.map((item) => ({
              fields: item?.fields,
              condition: item?.condition,
              optionValue: item?.optionValue,
            }))
          : [
              {
                fields: "",
                condition: "",
                optionValue: "",
              },
            ],
      },

      [reset]
    );

    // const yellowFlagCondition = fieldConditionsStore?.filter(
    //   (x) => x?.screening_field_id === fieldsData?.[0]?.screening_field_id
    // )?.[0]?.yellow_flag_condition?.[0];

    return () => {};
  }, [fieldConditionsStore]);

  const handleFieldSubmit = (data) => {
    // debugger;
    // e.preventDefault();
    // debugger;
    // console.log("handleFieldSubmit data", getValues("fieldConditions"));
    const formData = getValues("fieldConditions");
    // setFieldConditions({ id: fieldsData?.screening_field_id, ...data });
    // debugger;

    // console.log("data", data);
    // const mapping = {
    //   screening_question_id: (field) => field?.fields?.value,
    //   operator: (field) => {
    //     const conditionLabel = field?.condition?.label?.toLowerCase();

    //     switch (conditionLabel) {
    //       case "equal to":
    //         return "=";
    //       case "does not equal to":
    //         return "!=";
    //       case "greater than":
    //         return ">";
    //       case "no less than":
    //         return ">=";
    //       case "has a value":
    //         return "exists";
    //       case "has no value":
    //         return "not exists";
    //     }
    //   },

    //   // field?.condition?.label === "does not equal to" ? "!=" : "=",
    //   value: (field) => field?.optionValue,
    // };

    // // Map fieldConditions to rules
    // const rules = data?.map((fieldCondition) => {
    //   // debugger;
    //   const rule = {
    //     screening_question_id: mapping.screening_question_id(fieldCondition),
    //     operator: mapping.operator(fieldCondition),
    //     value: mapping.value(fieldCondition),
    //   };
    //   return rule;
    // });
    // // debugger;
    // fieldConditionRules?.length != 0
    //   ? setFieldConditionRules(...fieldConditionRules, rules)
    //   : setFieldCondition(rules);
    // // console.log("data", data);
    setFieldCondition(false);
    handleBackClick();

    const updatedArray = fieldConditionsStore?.map((item) => {
      if (
        item?.screening_field_id?.toString() ===
        fieldsData?.[0]?.screening_field_id?.toString()
      ) {
        return {
          ...item,
          field_conditions: item?.field_conditions.map(
            (condition) => {
              return isFirstToggleChecked
                ? {
                    ...condition,
                   

					  is_field_condition_active: isFirstToggleChecked ? 1 : 0,
                    field_visibility: data?.Show || "",
                    field_action: data?.All || "",
                    fields: data?.fieldConditions?.length ? data?.fieldConditions : [],
                  }
                : {
                    ...condition,
                    is_field_condition_active: 0,
                    field_visibility: "",
                    field_action: "",
                    fields: [],
                  };
            }
          ),
        };
      } else {
        return item;
      }
    });

    setFieldConditionsStore(updatedArray);
    toast.success("Field conditions applied");
  };

  const options = screeningSectionData
    ?.filter((data) => data?.section_category_id == screeningCategoryId)?.[0]
    .questionFields?.map((questionField) => ({
      value: questionField?.screening_field_id,
      label: questionField?.name,
      isDisabled: fieldsData?.some(
        (field) =>
          field?.screening_field_id === questionField?.screening_field_id
      ),
    }));

  // const handleFormSubmit = (data) => {
  // 	// Your existing form submission logic goes here
  // 	// console.log("Form data submitted:", data);
  // };
  const handleFirstToggleChange = (e) => {
    const isChecked = e.target.checked;
    setIsFirstToggleChecked(isChecked);
  };
  // to append new field condition
  const handleAddNewFieldCondition = () => {
    // const newFieldCondition = {
    //   row_id: Math.floor(Date.now() / 1000) + fields.length, // Ensure uniqueness
    //   fields: [],
    // };
    const newFieldCondition = {
      fields: "",
      condition: "",
      optionValue: "",
    };
    append(newFieldCondition);
  };

  return (
    <div className="py-4 field-conditions-container">
      <Form onSubmit={handleSubmit(handleFieldSubmit)}>
        <div className="d-flex justify-content-between">
          <div className="w-75 ps-4  pe-5 " style={{ paddingBottom: "5px" }}>
            <div className="d-flex gap-2">
              <H3>Set field conditions </H3>
              <CustomToolTip height={12} text="Set field conditions" />
            </div>

            <div className="light-font-text ">
              Conditional logic allows you to change what the user sees
              depending on the fields they select.
            </div>
          </div>
          <div className=" px-4 d-flex justify-content-end">
            <button
              type="button"
              className="close cross-btn"
              onClick={() => {
                setFieldCondition(false);
                handleBackClick();
              }}
              data-dismiss="modal"
              aria-label="Close"
            >
              <span className="fs-20" aria-hidden="true">
                <FontAwesomeIcon
                  icon="fa-regular fa-chevron-right"
                  style={{ color: "#000000" }}
                />
              </span>
            </button>
          </div>
        </div>

        <hr className="accordionSidebar-hr mt-2 mb-0"></hr>
        <div className="px-4 gap-2 py-3">
          <div className=" form-label-title">Field conditions</div>
          <Toggle
            id={"fieldconditions21"}
            checked={isFirstToggleChecked}
            onChange={handleFirstToggleChange}
          />
        </div>
        <div
          className="px-4 d-flex align-items-center light-font-text"
          style={!isFirstToggleChecked ? { opacity: "0.5" } : {}}
        >
          <div className="pe-2 w-25">
            <Controller
              control={control}
              {...register(`Show`)}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <ReactSelect
                  defaultValue={{ value: 0, label: "Show" }}
                  placeholder={"Show "}
                  disabled={!isFirstToggleChecked}
                  value={value}
                  errorScope
                  iserror={errors?.Show}
                  options={[{ value: 0, label: "Show" }]}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  clearable={false}
                />
              )}
            />
          </div>
          this field if{" "}
          <div className="px-2 w-25">
            <Controller
              control={control}
              {...register(`All`)}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <ReactSelect
                  placeholder={"All "}
                  disabled={!isFirstToggleChecked}
                  value={value}
                  errorScope
                  iserror={errors?.All}
                  options={[
                    { value: "1", label: "All" },
                    { value: "2", label: "Any" },
                  ]}
                  onChange={(e) => {
                    // console.log("logicType onChange e", e);
                    setLogicType(e);
                    onChange(e);
                  }}
                />
              )}
            />
          </div>
          of the following matches:
        </div>
        <div className="px-4 pt-2 pb-4">
          <Row className="pb-3">
            <Col lg={5} className="pe-0"></Col>

            <Col lg={2} className="pe-0"></Col>
            <Col lg={4} className="pe-0"></Col>
            <Col lg={1} className=" d-flex gap-2 align-items-center ps-2">
              <div></div>
              <div></div>
            </Col>
          </Row>

          {fields?.map((field, index) => (
            <Row key={field.id} className="pb-3">
              <Col lg={5} className="pe-0">
                <Controller
                  control={control}
                  {...register(`fieldConditions.${index}.fields`)}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder="Fields"
                      options={options}
                      value={value}
                      disabled={!isFirstToggleChecked}
                      onChange={(e) => {
                        onChange(e);
                        setFieldId(e?.value);
                      }}
                      iserror={errors?.fieldConditions?.[index]?.fields}
                      errorScope
                    />
                  )}
                />
              </Col>

              <Col lg={2} className="pe-0">
                <Controller
                  control={control}
                  {...register(`fieldConditions.${index}.condition`)}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder="Conditions"
                      value={value}
                      disabled={!isFirstToggleChecked}
                      options={[
                        { value: "1", label: "equal to" },
                        { value: "2", label: "does not equal to" },
                        { value: "3", label: "greater than" },
                        { value: "4", label: "No less than" },
                        { value: "5", label: "has a value" },
                        { value: "6", label: "has no value" },
                      ]}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      iserror={errors?.fieldConditions?.[index]?.condition}
                      errorScope
                    />
                  )}
                />
              </Col>

              <Col lg={4} className="pe-0">
                <Controller
                  control={control}
                  {...register(`fieldConditions.${index}.optionValue`)}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => {
                    const fieldName = watch(`fieldConditions.${index}.fields`);
                    const selectedFieldType = screeningData?.filter(
                      (sD) => sD?.screening_field_id == fieldName?.value
                    );
                    return selectedFieldType?.[0]?.field_type === "select" ||
                      selectedFieldType?.[0]?.field_type === "radio" ||
                      selectedFieldType?.[0]?.field_type === "checkbox" ? (
                      <ReactSelect
                        value={value}
                        options={possibleValue?.map((opt) => ({
                          value: opt?.Id,
                          label: opt?.value,
                        }))}
                        disabled={!isFirstToggleChecked}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        iserror={errors?.fieldConditions?.[index]?.optionValue}
                        errorScope
                      />
                    ) : (
                      (selectedFieldType?.[0]?.field_type === "text" ||
                        selectedFieldType?.[0]?.field_type === "number" ||
                        selectedFieldType?.[0]?.field_type === "textarea" ||
                        selectedFieldType?.[0]?.field_type === "fileupload" ||
                        selectedFieldType?.[0]?.field_type === "date" ||
                        selectedFieldType?.[0]?.field_type === "url" ||
                        selectedFieldType?.[0]?.field_type === "Button" ||
                        selectedFieldType?.[0]?.field_type === "name") && (
                        <div className="form-input">
                          <input
                            type={
                              selectedFieldType?.[0]?.field_type === "Button"
                                ? "text"
                                : selectedFieldType?.[0]?.field_type === "email"
                                ? "email"
                                : selectedFieldType?.[0]?.field_type
                            }
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            disabled={!isFirstToggleChecked}
                          />
                        </div>
                      )
                    );
                  }}
                />
              </Col>

              <Col lg={1} className="d-flex gap-2 align-items-center">
                <div>
                  <PlusIcon>
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-plus"
                      size="lg"
                      type="button"
                      onClick={() => handleAddNewFieldCondition()}
                    />
                  </PlusIcon>
                </div>
                <div>
                  <PlusIcon>
                    <FontAwesomeIcon
                      type="button"
                      icon="fa-solid fa-circle-minus"
                      size="lg"
                      style={{
                        opacity: fields?.length === 1 && 0.5,
                      }}
                      onClick={() => fields?.length > 1 && remove(index)}
                    />
                  </PlusIcon>
                </div>
              </Col>
            </Row>
          ))}
        </div>
        <hr className="accordionSidebar-hr my-0"></hr>
        <div className="p-4 d-flex justify-content-end">
          <ETButton
            disabled={!isFirstToggleChecked}
            //   type="button"
            type="submit"
            variant={"primary"}
            buttonText={"Apply Field conditions"}
            //   onClick={(e) => handleFieldSubmit(data, e)}
          />
        </div>
      </Form>
    </div>
  );
};

export default FieldConditions;
