import React, { useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import photoComingSoon from "../../assets/images/photo-coming-soon.jpg";
import { GreenSpan, H4 } from "../../styles/Common.style";
const UnitLevelTopBar = ({ unitData }) => {
  const [onPropertyNameHover, setPropertyNameHover] = useState(false);

  const backgroundColor = unitData?.map?.((data) => data?.background_color);
  const cardStyle = {
    backgroundColor: backgroundColor,
    borderRadius: "6px",
    width: "fit-content",
    // padding: '20px',
    // borderRadius: '10px',
    // Add more styles as needed
  };
  return (
    <div>
      <div style={{ minHeight: "120px", borderBottom: "1px solid #F0F0F0" }}>
        {unitData?.map((item, i) => (
          <Row
            key={i}
            className="ms-xxl-5 ms-lg-4 ms-3 pt-4 pb-3  px-xxl-1 px-1 align-items-end"
          >
            <Col
              xl={4}
              lg={4}
              md={5}
              className="px-md-2 d-flex justify-content-md-start justify-content-center ellipsis"
            >
              <div>
                <Image
                  src={item?.image_url ? item?.image_url : photoComingSoon}
                  alt="propertImage"
                  width="100px"
                  height="70px"
                  rounded
                />
              </div>

              <div className="d-flex align-items-center ps-3">
                <div>
                  <H4
                    onMouseOver={() => setPropertyNameHover(true)}
                    onMouseLeave={() => setPropertyNameHover(false)}
                  >
                    {item?.property_name?.length > 22
                      ? `${item?.property_name.substring(0, 22)}...`
                      : item?.property_name}
                  </H4>
                  {onPropertyNameHover && item?.property_name?.length > 22 && (
                    <div
                      style={{ position: "absolute", width: "200px" }}
                      className="fs-14 grey-info bg-white radius-8 border p-1 text-wrap text-capitalize"
                    >
                      {item?.property_name}
                    </div>
                  )}
                  <div className="grey-info  fs-14 line-height-20">
                    <div className="m-0">
                      {item?.street_name} {item?.Address}
                    </div>
                    <div className="m-0">
                      {item?.zipCode} {item?.unit_calculated_number}
                    </div>
                    {/* <div className="m-0">{item?.country}</div> */}
                  </div>
                </div>
              </div>
            </Col>

            <Col xl={6} lg={6} md={4}>
              <Row>
                <Col xl={3} lg={2} md={4} className="pt-sm-2 pt-0 pb-3 ps-0 ">
                  <div className="fs-14  line-height-20">
                    <div className="pb-1">
                      <span>Area</span> :&nbsp;
                      <span className="grey-info">
                        {item?.area} {""} {item?.square_measure_shortname}
                      </span>
                    </div>
                    <div className="d-block text-truncate">
                      <span>No.rooms</span> :&nbsp;
                      <span className="text-capitalize grey-info">
                        {item?.no_of_rooms}
                      </span>
                      {/* {onHover && item?.portfolioName?.length > 10 && (
                                          <div
                                              style={{ position: "absolute", width: "200px" }}
                                              className="bg-white radius-8 border p-1 text-wrap text-capitalize"
                                          >
                                              {item?.portfolioName?.split(",")?.join(",  ")}
                                          </div>
                                      )} */}
                    </div>
                  </div>
                </Col>
                <Col xl={4} lg={2} md={4} className="pt-sm-2 pt-0 pb-3 ps-0">
                  <div className="fs-14  line-height-20">
                    <div className="pb-1">
                      <span>Yearly rental</span> :&nbsp;
                      <span className="grey-info">
                        {item?.yearly_rently} {}
                        {item?.currency_id}
                      </span>
                    </div>
                    <div className="d-block text-truncate">
                      <span>Monthly rental</span> :&nbsp;
                      <span className="text-capitalize grey-info">
                        {item?.monthly_rently} {}
                        {item?.currency_id}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col xl={5} lg={2} md={3} className="pt-sm-2 pb-3  ps-0">
                  <div className="fs-14  line-height-20">
                    <div className="pb-1">
                      <span>Status</span> :&nbsp;
                      <span className="grey-info">
                        {item?.status}(
                        {item?.type == 1 || item?.type == 0 ? (
                          <>
                            <span>{item?.type}day </span>
                          </>
                        ) : (
                          <>
                            <span>{item?.type}days </span>
                          </>
                        )}
                        )
                      </span>
                    </div>
                    <div>
                      <span>Previous rentals</span> :&nbsp;
                      <span className="grey-info">
                        {item?.previous_rently}
                        {/* {item?.previous_rently > 0 && ( */}
                        <GreenSpan className="fs-14  line-height-20 ps-2  cursor-pointer">
                          (show)
                        </GreenSpan>
                        {/* )} */}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xl={2} lg={2} md={3} className="pb-3">
              <div
                className="fs-14  line-height-20 px-4 py-1"
                style={cardStyle}
              >
                <div>{item?.status}</div>
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

export default UnitLevelTopBar;
