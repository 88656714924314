import React, { useEffect, useMemo, useState } from "react";
import { H2, H3 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import Toggle from "../../../../Components/Switch/Switch";
import { Col, Row } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import styled from "@emotion/styled";
import "chart.js/auto";
import MRTTheme from "../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";
import { SortIcon } from "../../../../Components/table/MRTGlobalProps";
import { useGetDistributionAllScreening } from "../../../../hooks/queries/useCustomerFunnel";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";

const DistributionOfAllScreening = () => {
  //------------------- Zustand --------------//
  const { userDetails } = useUserDetails((state) => state);
  //------------------- State --------------//
  const [activeScreeningSwitch, setActiveScreeningSwitch] = useState(false);
  const [
    distributionAllScreeningTableData,
    setDistributionAllScreeningTableData,
  ] = useState("");

  //-------------------React query--------------//
  const { data: GetDistributionAllScreening } = useGetDistributionAllScreening(
    userDetails?.division_id
  );

  // chart code

  const data = {
    labels: ["Declined", "Ongoing", "Completed", "Approved", "Canceled"],
    datasets: [
      {
        data: [20, 30, 15, 35, 10],
        backgroundColor: [
          "#F4B4C4",
          "#955251",
          "#CFB53B",
          "#6C8E68",
          "#0B5369",
        ],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutout: "80%",
    borderWidth: 2,
    plugins: {
      tooltip: {
        yAlign: "top",
        backgroundColor: "#FFFFFF",
        borderWidth: 2,
        borderDash: [2, 2],
        titleColor: "#000000",
        bodyColor: "#000000",
      },
      legend: {
        display: false,
      },
    },
  };

  // Status Column Table Data

  const columnsStatus = useMemo(
    () => [
      {
        accessorKey: "Status",
        // accessorFn: (row) => `${row.status} `,
        id: "status",
        header: <div className="table-property-name">Status</div>,
        enableGlobalFilter: false,
        enableSorting: false,
        size: 100,
        Cell: ({ row }) => (
          <div className="d-flex">
            <div
              className="radius-4 me-3"
              style={{
                backgroundColor: row?.original?.color,
                width: "16px",
                height: "16px",
                marginTop: "2px",
              }}
            ></div>
            <div>
              {row?.original?.status == "" ? "-" : row?.original?.status}
            </div>
          </div>
        ),
      },
      {
        accessorKey: "quantity",
        id: "quantity",
        header: <div className="table-property-name">Quantity</div>,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 100,
        Cell: ({ row }) => (
          <div>
            <div>
              {row?.original?.total_quantity == ""
                ? "-"
                : row?.original?.total_quantity}
            </div>
          </div>
        ),
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );

  // Rooms Column Table Data

  const columnsRooms = useMemo(
    () => [
      {
        // accessorKey: "status",
        accessorFn: (row) => `${row.status} `,
        id: "status",
        header: <div className="table-property-name">No. of rooms</div>,
        enableGlobalFilter: false,
        enableSorting: false,
        size: 100,
        Cell: ({ row }) => (
          <div>
            {row?.original?.no_of_rooms == ""
              ? "-"
              : row?.original?.no_of_rooms}
          </div>
        ),
      },
      {
        accessorKey: "quantity",
        id: "quantity",
        header: <div className="table-property-name">Quantity</div>,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 100,
        Cell: ({ row }) => (
          <div>
            {row?.original?.quantity == "" ? "-" : row?.original?.quantity}
          </div>
        ),
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );

  // Square Meter Column Table Data

  const columnsSquareMeter = useMemo(
    () => [
      {
        // accessorKey: "status",
        accessorFn: (row) => `${row.status} `,
        id: "status",
        header: <div className="table-property-name">Square meters</div>,
        enableGlobalFilter: false,
        enableSorting: false,
        size: 100,
        Cell: ({ row }) => (
          <div>{row?.original?.sqrmtr == "" ? "-" : row?.original?.sqrmtr}</div>
        ),
      },
      {
        accessorKey: "quantity",
        id: "quantity",
        header: <div className="table-property-name">Quantity</div>,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 100,
        Cell: ({ row }) => (
          <div>
            {row?.original?.quantity == "" ? "-" : row?.original?.quantity}
          </div>
        ),
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );

  // Customer Age Column Table Data

  const columnsCustomerAge = useMemo(
    () => [
      {
        // accessorKey: "status",
        accessorFn: (row) => `${row.status} `,
        id: "status",
        header: <div className="table-property-name">Customer age</div>,
        enableGlobalFilter: false,
        enableSorting: false,
        size: 100,
        Cell: ({ row }) => (
          <div>
            {row?.original?.Customer_age == ""
              ? "-"
              : row?.original?.Customer_age}
          </div>
        ),
      },
      {
        accessorKey: "quantity",
        id: "quantity",
        header: <div className="table-property-name">Quantity</div>,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 100,
        Cell: ({ row }) => (
          <div>
            {row?.original?.Quantity == "" ? "-" : row?.original?.Quantity}
          </div>
        ),
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );

  //------------------- useEffect --------------//
  useEffect(() => {
    setDistributionAllScreeningTableData(GetDistributionAllScreening?.data);
  }, [GetDistributionAllScreening]);

  return (
    <div>
      <div className="whiteContainerStyle px-4 py-3" style={{ zIndex: 2 }}>
        <div className="d-flex align-items-center justify-content-between py-1">
          <div className="d-flex gap-2">
            {" "}
            <H3>Distribution of all screenings</H3>
            <CustomToolTip size="sm" text={"Distribution of all screenings"} />
          </div>

          {/* Switch logic and ui */}
          <div className="d-flex align-items-center">
            <div
              className=" fs-14 me-2"
              style={{
                color: activeScreeningSwitch === false ? "#000" : "#888",
              }}
            >
              Active screenings
            </div>
            <Toggle
              id={1}
              checked={activeScreeningSwitch}
              onChange={(e) => setActiveScreeningSwitch(e.target.checked)}
            />{" "}
            <div
              className="ps-3  fs-14 me-2"
              style={{
                color: activeScreeningSwitch === true ? "#000" : "#888",
              }}
            >
              All screenings
            </div>
          </div>
        </div>
        <Row>
          <Col lg={3} className="pt-5 px-0">
            <ChartWrapper>
              <DoughnutInner>
                <div className="light-font-text">Total units</div>
                <H2 className=" fs-16">32</H2>
              </DoughnutInner>
              <Doughnut data={data} options={options} />
            </ChartWrapper>
          </Col>
          <Col lg={9}>
            <div
              className="d-flex gap-3 scrollable-container-x"
              style={{ width: "100%", zIndex: 2 }}
            >
              {/* Status MUI  */}
              <div>
                <MRTTheme
                  colorVariantsForTableRow={["gray"]}
                  colorVariantsForTableHead={["white"]}
                  table={
                    <MaterialReactTable
                      columns={columnsStatus}
                      data={distributionAllScreeningTableData?.status || []}
                      icons={{ ...SortIcon }}
                      // data={[]}
                      // localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                      enableColumnActions={false}
                      enableBottomToolbar={false}
                      enableTopToolbar={false}
                      muiTableContainerProps={{
                        sx: {
                          paddingX: "0px",
                        },
                      }}
                      muiTableBodyProps={{
                        sx: {
                          td: {
                            height: "56px",
                          },
                        },
                      }}
                      muiTablePaperProps={{
                        selected: false,
                        elevation: 0,
                        fontFamily: "Cerebri sans-light !important",
                        sx: {
                          borderRadius: "0",
                          border: "none",
                          // backgroundColor: "#F9FAFA",
                          table: {
                            borderCollapse: "separate",
                            borderSpacing: "0 5px",
                          },
                        },
                      }}
                    />
                  }
                />
              </div>
              {/* no of rooms MUI  */}
              <div>
                <MRTTheme
                  colorVariantsForTableRow={["gray"]}
                  colorVariantsForTableHead={["white"]}
                  table={
                    <MaterialReactTable
                      columns={columnsRooms}
                      data={
                        distributionAllScreeningTableData?.no_of_rooms || []
                      }
                      icons={{ ...SortIcon }}
                      // data={[]}
                      // localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                      enableColumnActions={false}
                      enableBottomToolbar={false}
                      enableTopToolbar={false}
                      muiTableContainerProps={{
                        sx: {
                          paddingX: "0px",
                        },
                      }}
                      muiTableBodyProps={{
                        sx: {
                          td: {
                            height: "56px",
                          },
                        },
                      }}
                      muiTablePaperProps={{
                        selected: false,
                        elevation: 0,
                        fontFamily: "Cerebri sans-light !important",
                        sx: {
                          borderRadius: "0",
                          border: "none",
                          // backgroundColor: "#F9FAFA",
                          table: {
                            borderCollapse: "separate",
                            borderSpacing: "0 5px",
                          },
                        },
                      }}
                    />
                  }
                />
              </div>
              {/* Square mtr MUI  */}
              <div>
                <MRTTheme
                  colorVariantsForTableRow={["gray"]}
                  colorVariantsForTableHead={["white"]}
                  table={
                    <MaterialReactTable
                      columns={columnsSquareMeter}
                      data={distributionAllScreeningTableData?.square_mtr || []}
                      icons={{ ...SortIcon }}
                      // data={[]}
                      // localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                      enableColumnActions={false}
                      enableBottomToolbar={false}
                      enableTopToolbar={false}
                      muiTableContainerProps={{
                        sx: {
                          paddingX: "0px",
                        },
                      }}
                      muiTableBodyProps={{
                        sx: {
                          td: {
                            height: "56px",
                          },
                        },
                      }}
                      muiTablePaperProps={{
                        selected: false,
                        elevation: 0,
                        fontFamily: "Cerebri sans-light !important",
                        sx: {
                          borderRadius: "0",
                          border: "none",
                          // backgroundColor: "#F9FAFA",
                          table: {
                            borderCollapse: "separate",
                            borderSpacing: "0 5px",
                          },
                        },
                      }}
                    />
                  }
                />
              </div>
              {/* customer age MUI  */}
              <div>
                <MRTTheme
                  colorVariantsForTableRow={["gray"]}
                  colorVariantsForTableHead={["white"]}
                  table={
                    <MaterialReactTable
                      columns={columnsCustomerAge}
                      data={
                        distributionAllScreeningTableData?.customer_age || []
                      }
                      icons={{ ...SortIcon }}
                      // data={[]}
                      // localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                      enableColumnActions={false}
                      enableBottomToolbar={false}
                      enableTopToolbar={false}
                      muiTableContainerProps={{
                        sx: {
                          paddingX: "0px",
                        },
                      }}
                      muiTableBodyProps={{
                        sx: {
                          td: {
                            height: "56px",
                          },
                        },
                      }}
                      muiTablePaperProps={{
                        selected: false,
                        elevation: 0,
                        fontFamily: "Cerebri sans-light !important",
                        sx: {
                          borderRadius: "0",
                          border: "none",
                          // backgroundColor: "#F9FAFA",
                          table: {
                            borderCollapse: "separate",
                            borderSpacing: "0 5px",
                          },
                        },
                      }}
                    />
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DistributionOfAllScreening;

// styled using styled component
const ChartWrapper = styled.div`
  height: 201px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  line-height: 20px;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

// static data
const tableDataStatus = [
  {
    status: "Declined",
    quantity: 10,
    color: "#F4B4C4",
  },
  {
    status: "Ongoing",
    quantity: 5,
    color: "#955251",
  },
  {
    status: "Completed",
    quantity: 10,
    color: "#CFB53B",
  },
  {
    status: "Approved",
    quantity: 8,
    color: "#6C8E68",
  },
  {
    status: "Canceled",
    quantity: 4,
    color: "#0B5369",
  },
];

const tableDataRooms = [
  {
    status: "1 room",
    quantity: 0,
  },
  {
    status: "2 rooms",
    quantity: 0,
  },
  {
    status: "3 rooms",
    quantity: 0,
  },
  {
    status: "4 rooms",
    quantity: 0,
  },
  {
    status: "5 rooms",
    quantity: 0,
  },
  {
    status: "6 rooms",
    quantity: 0,
  },
];

const tableDataSqMeter = [
  {
    status: "Under 50",
    quantity: 0,
  },
  {
    status: "50 - 74",
    quantity: 0,
  },
  {
    status: "75 - 119",
    quantity: 0,
  },
  {
    status: "120 - 149",
    quantity: 0,
  },
  {
    status: "150 - 199",
    quantity: 0,
  },
  {
    status: "Over 200",
    quantity: 0,
  },
];

const tableDataCustomerAge = [
  {
    status: "18 - 24",
    quantity: 0,
  },
  {
    status: "25 - 34",
    quantity: 0,
  },
  {
    status: "35 - 44",
    quantity: 0,
  },
  {
    status: "45 - 54",
    quantity: 0,
  },
  {
    status: "55 - 64",
    quantity: 0,
  },
  {
    status: "Over 200",
    quantity: 0,
  },
];

// created logs
// created by saurabh
// changes in page structure related to code and move unused code below components --done by Suraj
// API integration -- Neha
