// created by Suraj
import React, { useEffect, useState } from "react";
import { H2 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../../../Components/Tabs/ReactTabs.style";
import ActiveUsers from "./ActiveUsers";
import ArchivedUsers from "./ArchivedUsers";
import Teams from "./Teams";
import {
  useOrganizationId,
  useOrganizationSettingsBreadcrumbsTabs,
} from "../../../../hooks/stores/useOrganizationStore";
import PendingUsers from "./PendingUsers";
import {
  useDeleteUser,
  useGetRolesBySystemId,
  useGetUserByLevel,
  useGetUserDetailsById,
  useGetUserRoles,
  usePostAddUser,
  useResendInvitation,
  useUpdateUserDetails,
  useUpdateUserStatus,
} from "../../../../hooks/queries/useUserQueries";
import { useGetTeamsByLevel } from "../../../../hooks/queries/useTeam";
import { useGetDivisionByOrganisationId } from "../../../../hooks/queries/useDivisionQueries";
import { useGetLanguages } from "../../../../hooks/queries/uselanguageQueries";
import { toast } from "react-toastify";
import {
  ErrorAlert,
  SucessAlert,
} from "../../../../Components/alerts/AlertsAndMessages";
import { status } from "../../../../model/Constant";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";
// import { useLocation } from "react-router-dom";

const Users = () => {
  // let location = useLocation();

  // =============== States =====================//
  const [userId, setUserId] = useState("");
  const [userType, setUserType] = useState("1");
  const [statusType, setStatusType] = useState("");

  const [teamId, setTeamId] = useState(0);
  const [divisionId, setDivisionId] = useState("");
  const [usedFor, setUsedFor] = useState("");
  const [croppedImage, setCroppedImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [uploadImgFile, setUploadImgFile] = useState("");
  const [newUserId, setNewUserId] = useState(0);

  // ================ zustand ================== //
  const setOrganizationBreadcrumbsTabs = useOrganizationSettingsBreadcrumbsTabs(
    (state) => state.setOrganizationBreadcrumbsTabs
  );
  const { organizationId } = useOrganizationId((state) => state);
  const { userDetails } = useUserDetails((state) => state);

  // =============== React queries ================ //
  const { data: GetPendingUsersData, refetch: GetPendingUsersDataRefetch } =
    useGetUserByLevel("o", organizationId, 0, 1, "details");

  const {
    data: GetArchiveUsersByOrganisationDetailsData,
    refetch: GetArchiveUserDataRefetch,
  } = useGetUserByLevel("o", organizationId, 0, 4, "details");
  const {
    data: GetTeamsByLevelBasicData,
    refetch: GetTeamsByLevelBasicDataRefetch,
  } = useGetTeamsByLevel("o", organizationId, "basic");
  const {
    data: GetActiveUsersData,
    refetch: GetActiveUsersDataRefetch,
    isLoading,
  } = useGetUserByLevel("o", organizationId, teamId, [2, 3], "details");
  const {
    data: GetDivisionByOrganisationIdData,
    refetch: GetDivisionByOrganisationIdRefetch,
  } = useGetDivisionByOrganisationId(organizationId, "details");
  const {
    data: userDetailsByIdData,
    refetch: userDetailsbyIdDataRefetch,
    remove: userDetailsByIdDataRemove,
  } = useGetUserDetailsById(userId);
  const { data: userRolesData } = useGetRolesBySystemId();
  const { data: GetTeamsByLevelData, refetch: GetTeamsByLevelRefetch } =
    useGetTeamsByLevel("o", organizationId, "details");
  const { data: GetLanguagesData } = useGetLanguages();
  const { mutateAsync: deleteUserStatusMutate } = useDeleteUser();
  const { mutateAsync: resendInvitationMutate } = useResendInvitation();
  const { mutateAsync: updateUserStatusMutate } = useUpdateUserStatus();
  const { mutateAsync: postAddUserStatusMutate } = usePostAddUser();

  const startTime = React.useRef();

  useEffect(() => {
    GetPendingUsersDataRefetch();
    GetArchiveUserDataRefetch();
    GetActiveUsersDataRefetch();
  }, []);

  React.useEffect(() => {
    if (isLoading) {
      // Set the start time when the loading state begins
      startTime.current = Date.now();
    } else if (!isLoading && startTime.current) {
      // Calculate the duration if the loading state ends
      const durationInMilliseconds = Date.now() - startTime.current;
    }
  }, [isLoading]);

  const handleUserTabClick = (userTypeData) => {
    setUserType(userTypeData);
  };
  // to resend invitation
  const handleResendInvitation = async (userId) => {
    const resendInvitationPayload = {
      user_id: userId,
      system_id: "ETM",
    };
    try {
      const response = await resendInvitationMutate(resendInvitationPayload);
      if (response?.status === status?.s && response?.data?.bReturn) {
        toast.success(<SucessAlert />);
        GetPendingUsersDataRefetch();
      } else {
        toast.error(<ErrorAlert />);
      }
    } catch (error) {}
  };

  // to delete user
  const handleArchiveUser = async (userId) => {
    try {
      const response = await deleteUserStatusMutate(userId);
      if (response?.status === status?.s && response?.data?.bReturn) {
        toast.success(<SucessAlert />);
        GetPendingUsersDataRefetch();
        GetActiveUsersDataRefetch();
        GetArchiveUserDataRefetch();
      } else {
        toast.error(<ErrorAlert />);
      }
    } catch (error) {}
  };
  // to update user status
  const handleUpdateUserStatus = async (type, name) => {
    const updateUserStatusPaylaod = {
      user_id: userId,
      type,
      name,
    };
    try {
      const response = await updateUserStatusMutate(updateUserStatusPaylaod);
      if (response?.data?.bReturn && response?.status === status?.s) {
        toast.success(<SucessAlert />);
        GetActiveUsersDataRefetch();
        GetPendingUsersDataRefetch();
        GetArchiveUserDataRefetch();
      } else {
        toast.error(<ErrorAlert />);
      }
    } catch (error) {}
  };

  // to create/update user
  const handleCreateUser = async (data) => {
    const getTeamIds = (teams) => {
      if (teams === "" || teams === undefined || teams === null) {
        return [];
      } else if (teams?.length === 1) {
        return [teams?.[0]?.value?.toString()];
      } else if (teams?.length > 1) {
        const teamIds = teams?.map((team) => team?.value);
        return teamIds;
      }
    };
    if (usedFor === "CreateUser") {
      const transformedUserRole = data?.userRoles?.map((item) => ({
        division_id: item?.division ? item?.division?.value?.toString() : 0,
        role_id: item?.user_role ? item?.user_role?.value : 0,
        user_team_id: getTeamIds(item?.teams),
      }));
      let formData = new FormData();
      formData.append("organisation_id", organizationId);
      formData.append("first_name", data?.firstName);
      formData.append("last_name", data?.lastName);
      formData.append("email", data?.email);
      formData.append("designation", data?.title);
      formData.append("language_id", data?.language?.value);
      formData.append("isd_code", data?.isd_code);
      formData.append("contact_no", data?.phone);
      formData.append("company_name", data?.company);
      formData.append("work_area_id", 0);
      formData.append("system_id", "ETM");
      formData.append(
        "profile_image_file",
        userDetails?.image_url && croppedImage?.includes("https")
          ? ""
          : uploadImgFile
      );
      formData.append("roles", JSON.stringify(transformedUserRole));
      formData.append("new_user_id", newUserId);

      try {
        const response = await postAddUserStatusMutate(formData);
        if (response?.status === status?.s && response?.data?.bReturn) {
          toast.success(<SucessAlert />);
          GetPendingUsersDataRefetch();
          userDetailsbyIdDataRefetch();
          setCroppedImage("");
        } else {
          toast.error(<ErrorAlert />);
        }
      } catch (error) {}
    }
  };
  return (
    <div>
      {" "}
      {/* title section */}
      <div className="px-3 pb-3 border-bottom">
        <div className="px-2 pt-3 d-flex">
          <div className="d-flex align-items-center  gap-2">
            <H2>Users</H2>
            <CustomToolTip size="sm" text="Users" />
          </div>
        </div>
      </div>
      {/*All tabs section  */}
      <div>
        <Tabs className="d-flex flex-column ">
          <div
            className="d-flex px-4 py-3 mb-3 justify-content-between align-items-center"
            style={{
              width: "100%",
              flexDirection: "row",
              flexWrap: "wrap",
              borderBottom: "1px solid #F0F0F0",
            }}
          >
            <TabList
              className=" d-flex light ps-0 light"
              style={{ gap: "7px" }}
            >
              <Tab
                onClick={() => {
                  setOrganizationBreadcrumbsTabs("Pending users");
                  handleUserTabClick("1");
                }}
              >
                Pending users
              </Tab>
              <Tab
                onClick={() => {
                  setOrganizationBreadcrumbsTabs("Active users");
                  handleUserTabClick("2");
                }}
              >
                Active users
              </Tab>
              <Tab
                onClick={() => {
                  setOrganizationBreadcrumbsTabs("Archived users");
                  handleUserTabClick("3");
                }}
              >
                Archived users
              </Tab>
              <Tab onClick={() => setOrganizationBreadcrumbsTabs("Teams")}>
                Teams
              </Tab>
            </TabList>
          </div>
          <div>
            <TabPanel>
              <PendingUsers
                pendingUsersData={GetPendingUsersData?.data}
                GetPendingUsersDataRefetch={GetPendingUsersDataRefetch}
                GetDivisionByOrganisationIdData={
                  GetDivisionByOrganisationIdData
                }
                userDetailsByIdData={userDetailsByIdData}
                userDetailsByIdDataRemove={userDetailsByIdDataRemove}
                userRolesData={userRolesData}
                GetTeamsByLevelData={GetTeamsByLevelData}
                GetLanguagesData={GetLanguagesData}
                userId={userId}
                setUserId={setUserId}
                teamId={teamId}
                setTeamId={setTeamId}
                usedFor={usedFor}
                setUsedFor={setUsedFor}
                // handleUpdateUser={handleUpdateUser}
                handleArchiveUser={handleArchiveUser}
                handleResendInvitation={handleResendInvitation}
                croppedImage={croppedImage}
                setCroppedImage={setCroppedImage}
                imageName={imageName}
                setImageName={setImageName}
                uploadImgFile={uploadImgFile}
                setUploadImgFile={setUploadImgFile}
                userDetailsbyIdDataRefetch={userDetailsbyIdDataRefetch}
                newUserId={newUserId}
                setNewUserId={setNewUserId}
              />
            </TabPanel>
            <TabPanel>
              <ActiveUsers
                teamsData={GetTeamsByLevelBasicData?.data}
                GetTeamsByLevelBasicDataRefetch={
                  GetTeamsByLevelBasicDataRefetch
                }
                GetActiveUsersData={GetActiveUsersData}
                GetActiveUsersDataRefetch={GetActiveUsersDataRefetch}
                GetPendingUsersDataRefetch={GetPendingUsersDataRefetch}
                GetArchiveUserDataRefetch={GetArchiveUserDataRefetch}
                GetDivisionByOrganisationIdData={
                  GetDivisionByOrganisationIdData
                }
                userDetailsByIdData={userDetailsByIdData}
                userDetailsByIdDataRemove={userDetailsByIdDataRemove}
                userRolesData={userRolesData}
                GetTeamsByLevelData={GetTeamsByLevelData}
                GetLanguagesData={GetLanguagesData}
                userId={userId}
                setUserId={setUserId}
                teamId={teamId}
                setTeamId={setTeamId}
                usedFor={usedFor}
                setUsedFor={setUsedFor}
                handleArchiveUser={handleArchiveUser}
                // handleUpdateUser={handleUpdateUser}
                formSubmit={handleCreateUser}
                setStatusType={setStatusType}
                handleUpdateUserStatus={handleUpdateUserStatus}
                croppedImage={croppedImage}
                setCroppedImage={setCroppedImage}
                imageName={imageName}
                setImageName={setImageName}
                uploadImgFile={uploadImgFile}
                setUploadImgFile={setUploadImgFile}
                userDetailsbyIdDataRefetch={userDetailsbyIdDataRefetch}
                divisionId={divisionId}
                setDivisionId={setDivisionId}
                newUserId={newUserId}
                setNewUserId={setNewUserId}
              />
            </TabPanel>
            <TabPanel>
              <ArchivedUsers
                userId={userId}
                setUserId={setUserId}
                archiveUsersData={
                  GetArchiveUsersByOrganisationDetailsData?.data
                }
                handleUpdateUserStatus={handleUpdateUserStatus}
              />
            </TabPanel>
            <TabPanel>
              <Teams />
            </TabPanel>
          </div>
        </Tabs>
      </div>
    </div>
  );
};

export default Users;
