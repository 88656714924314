import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FilterComponent from "../../../../reactdataTableComponent/FilterComponent";
import ETButton from "../../../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { MaterialReactTable } from "material-react-table";
import CustomPopup from "../../../../Components/CustomPopup";
import Select from "react-select";
import MRTTheme from "../../../../Components/table/MRTTheme";
import EmptyMessage from "../../../../utils/EmptyMessage";
import { NoRecordsToDisplay } from "../../../../utils/validationConstants";
import { useBreadcrumb } from "../../../../Components/BreadcrumbContext ";
import { H2 } from "../../../../styles/Common.style";
import { SortIcon } from "../../../../Components/table/MRTGlobalProps";

const CommunicationData = [
  {
    id: "1",
    head: "MISSING INFORMATION",
    name: "Jesper Thorkildsen",
    date: "2 Dec at 10:12",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ipsum dolor sit amet, consectetur adipiscing elit...",
    subjet: "ECONOMY",
    notification: 1,
    codename: "JT",
  },
  {
    id: "2",
    head: "DATE OF MOVING IN",
    name: "Max Wildersans Brentford",
    date: "27 Nov at 9:06",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
    codename: "MW",
  },
];
const ChatBoxData = [
  {
    user_id: "2",
    name: "Max Wildersans Brentford",
    codename: "MW",
    head: "DATE OF MOVING IN",
    date: "Nov 24 at 19:12",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
  },
  {
    user_id: "1",
    name: "Jesper Thorkildsen",
    head: "DATE OF MOVING IN",
    date: "Nov 25 at 8:23",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
    codename: "JT",
  },
  {
    user_id: "2",
    name: "Max Wildersans Brentford",
    head: "DATE OF MOVING IN",
    date: "Nov 26 at 19:12",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
    codename: "MW",
  },
  {
    user_id: "1",
    name: "Jesper Thorkildsen",
    head: "DATE OF MOVING IN",
    date: "Nov 27 at 8:23",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
    codename: "JT",
  },
  {
    user_id: "2",
    name: "Max Wildersans Brentford",
    head: "DATE OF MOVING IN",
    date: "Nov 28 at 19:12",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
    codename: "MW",
  },
  {
    user_id: "1",
    name: "Jesper Thorkildsen",
    head: "DATE OF MOVING IN",
    date: "Nov 29 at 8:23",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
    codename: "JT",
  },
];
const CommunicationTable = [
  {
    name: "Tenancy-agreeement-blomste...Tenancy-agreeement-blomste...",
    type: "Tenancy agreement",
    upload_date: "23 Jan, 2023",
    uploaded_by: "Landlord Lisa",
    Source: "Communication",
  },
  {
    name: "Tenancy-agreeement-blomste",
    type: "Tenancy agreement",
    upload_date: "23 Jan, 2023",
    uploaded_by: "Landlord Lisa",
    Source: "Communication",
  },
  {
    name: "Tenancy-agreeement-blomste",
    type: "Tenancy agreement",
    upload_date: "23 Jan, 2023",
    uploaded_by: "Landlord Lisa",
    Source: "Communication",
  },
  {
    name: "Tenancy-agreeement-blomste",
    type: "Tenancy agreement",
    upload_date: "23 Jan, 2023",
    uploaded_by: "Landlord Lisa",
    Source: "Communication",
  },
];
const Communicaton = ({ initialSelectedName, tanentNameTab, selectedName }) => {
  const [newMsg, setNewMsg] = useState(false);
  const [openScreeningPdf, setOpenScreeningPdf] = useState(false);
  const [deletePdfPopUp, setDeletePdfPopUp] = useState(false);
  const [msgSelected, setMsgSelected] = useState("");
  const [changeChatSection, setChangeChatSection] = useState(true);
  const [msgSelectedDataTitle, setMsgSelectedDataTitle] = useState("");
  const [msgSelectedDataName, setMsgSelectedDataName] = useState("");
  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    updateBreadcrumbs(["Contracts", initialSelectedName, "Communication"]);
  }, [initialSelectedName]);

  useEffect(() => {
    if (tanentNameTab) {
      updateBreadcrumbs(["Contracts", selectedName, "Communication"]);
    } else {
    }
  }, [selectedName]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name", //access nested data with dot notation
        header: "Document name",
        size: 200,
      },
      {
        accessorKey: "type",
        header: "Type",
        size: 160,
      },
      {
        accessorKey: "upload_date", //normal accessorKey
        header: "Date of upload",
        size: 100,
      },
      {
        accessorKey: "uploaded_by",
        header: "Uploaded by",
        size: 100,
      },
      {
        accessorKey: "Source",
        header: "Source",
        size: 100,
      },
    ],
    []
  );

  const handelClickMassage = (i, item) => {
    setMsgSelected(i);
    setMsgSelectedDataTitle(item.head);
    setMsgSelectedDataName(item.name);
    setChangeChatSection(false);
    setNewMsg(false);
  };

  // react select options and styles

  const options = [
    { label: "option1", value: 1 },
    { label: "option2", value: 2 },
    { label: "option3", value: 3 },
  ];
  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: "none",
      width: "100%",
      fontSize: "13px",
      fontWeight: "300",
      fontFamily: "Cerebri sans",
    }),
  };

  return (
    <Container fluid className="px-4">
      <div className=" my-3 py-2 radius-8 customers-card-layout">
        <Row>
          <Col lg={5}>
            <div className="ps-3 py-3 ">
              <FilterComponent
                className={"grey-input-filter-component"}
                lableText="Search  in messages"
                placeholder={"Search  in messages"}
              />
            </div>
          </Col>
          <Col lg={7}>
            <div className="px-3 py-3 d-flex justify-content-end w-100">
              <ETButton
                variant={"primary"}
                buttonText="NEW MESSAGE"
                icon={true}
                onClick={() => setNewMsg(!newMsg)}
              />
            </div>
          </Col>
        </Row>
        <hr className="m-0"></hr>
        <Row>
          <Col lg={5}>
            <div className="ps-3 py-3">
              {newMsg == true && (
                <div
                  className="button d-flex  bg-white px-2 py-3 me-1 radius-8 my-2"
                  style={{ border: "1px solid #619130" }}
                  onClick={() => setChangeChatSection(true)}
                >
                  <div
                    className="  d-flex justify-content-center "
                    style={{
                      borderRadius: "100%",
                      backgroundColor: "#F1664B",
                      alignItems: "center",
                      width: "44px",
                      height: "44px",
                      position: "relative",
                    }}
                  >
                    <FontAwesomeIcon
                      icon="fa-regular fa-circle-user"
                      size="lg"
                      style={{ color: "#ffffff" }}
                    />
                  </div>
                  <div
                    className="px-2 form-label-title d-flex"
                    style={{ alignItems: " center" }}
                  >
                    New message
                  </div>
                </div>
              )}

              <EmptyMessage />
              {/* {CommunicationData.map((item, i) => (
                <div
                  key={item.id}
                  className="d-flex  bg-white p-2 me-1 radius-8 my-2 active-chat"
                  style={{
                    border:
                      msgSelected == i
                        ? "1px solid #619130"
                        : "1px solid #F0F0F0",
                    alignItems: "center",
                    // borderLeft: item.notification
                    //   ? "5px solid #619130 "
                    //   : "1px solid #F0F0F0 ",
                  }}
                  onClick={() => handelClickMassage(i, item)}
                >
                  <div
                    className="  d-flex justify-content-center "
                    style={{
                      borderRadius: "100%",
                      backgroundColor:
                        item.codename == "JT" ? "#A2C8D0" : "#CAA2D0",
                      alignItems: "center",
                      width: "44px",
                      height: "44px",
                      position: "relative",
                    }}
                  >
                    <span
                      className="white-text fs-11 form-label-title"
                      style={{ position: "relative" }}
                    >
                      {" "}
                      {item.notification && (
                        <span
                          className="px-1"
                          style={{
                            backgroundColor: " #D00200",
                            borderRadius: "100%",
                            position: "absolute",
                            right: "15px",
                            bottom: "15px",
                          }}
                        >
                          {item.notification}
                        </span>
                      )}{" "}
                      {item.codename}
                    </span>
                  </div>
                  <div className="fs-13 fw-300 px-2 w-100">
                    <div className="d-flex justify-content-between">
                      <div className="fs-9 form-label-title">{item.head}</div>
                      <div className="fs-10 form-label-title grey-text">
                        {item.date}
                      </div>
                    </div>
                    <div className="fs-14 form-label-title">{item.name}</div>
                    <div
                      className="actors-lite-text fs-13 mb-3"
                      style={{ color: msgSelected == i ? "#888" : "#000" }}
                    >
                      {item.msg}
                    </div>
                  </div>
                </div>
              ))} */}
            </div>
          </Col>
          <Col lg={7}>
            <div className="bg-white  radius-8 my-4 mx-3 lite-grey-border">
              {changeChatSection == true ? (
                <>
                  <div className="d-flex actors-lite-text">
                    <div className="ps-3 py-2 actors-label-title">To:</div>{" "}
                    <Select
                      options={options}
                      styles={style}
                      placeholder={"Type name of receiver"}
                    />
                  </div>
                  {/* <div className="msg-input p-2">
                    <label className="px-2 fs-13 fw-500">To:</label>
                    <input
                      className=""
                      type="text"
                      placeholder="Type name of receiver"
                    />
                  </div> */}
                  <hr className="m-0 "></hr>
                  <div className="msg-input p-2 actors-lite-text">
                    <label className="px-2 actors-label-title">Subject:</label>
                    <input
                      className=""
                      type="text"
                      placeholder="Give your message a subject to remember"
                    />
                  </div>
                  <hr className="m-0 hr"></hr>
                </>
              ) : (
                <>
                  <div className="fs-13 fw-500 py-2 px-3">
                    {msgSelectedDataTitle}
                  </div>
                  <hr
                    className="m-0"
                    style={{ border: "1px solid #d1d1d1" }}
                  ></hr>
                </>
              )}
              <div
                style={{ height: "450px", overflowY: "scroll" }}
                className="scrollable-container-y"
              >
                {/* msgSelectedDataName */}
                {changeChatSection == false && (
                  <div className="p-3">
                    {ChatBoxData.map((item, i) => (
                      <div
                        key={i}
                        className="mx-2 my-3 d-flex "
                        style={{
                          alignItems: "center",
                          flexDirection:
                            msgSelectedDataName !== item.name && "row-reverse",
                        }}
                      >
                        <div
                          className={
                            msgSelectedDataName !== item.name
                              ? " ms-2 mt-3  d-flex justify-content-center "
                              : " me-2 mt-3  d-flex justify-content-center "
                          }
                          style={{
                            borderRadius: "100%",
                            backgroundColor:
                              item.codename == "JT" ? "#A2C8D0" : "#CAA2D0",
                            alignItems: "center",
                            width: "60px",
                            height: "44px",
                          }}
                        >
                          <span className="white-text fs-11">
                            {item.codename}
                          </span>
                        </div>

                        <div>
                          <div
                            style={{
                              textAlign:
                                msgSelectedDataName !== item.name
                                  ? "start"
                                  : "end",
                            }}
                          >
                            <span className="fs-10 fw-500 grey-text ">
                              {item.date}
                            </span>
                          </div>
                          <div
                            style={{
                              background:
                                msgSelectedDataName !== item.name
                                  ? "#619130"
                                  : "#F0F0F0",
                              borderRadius:
                                msgSelectedDataName !== item.name
                                  ? " 8px 2px 8px 8px"
                                  : " 2px 8px 8px 8px",
                              color:
                                msgSelectedDataName !== item.name && "#fff",
                            }}
                            className="fs-13 fw-300 px-3 py-2"
                          >
                            {item.msg}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <hr className="m-0"></hr>
              <div className="d-flex justify-content-between p-3">
                <div className="d-flex">
                  <div className="w-20">
                    <label for="files" class="button">
                      <FontAwesomeIcon
                        icon="fa-regular fa-paperclip"
                        size="xl"
                        style={{ color: "#888888" }}
                      />
                    </label>
                    <input id="files" style={{ display: "none" }} type="file" />
                  </div>
                  <div className="msg-input ps-2">
                    <input
                      className="light-font-text fs-14"
                      type="text"
                      placeholder="Write you message"
                    />
                  </div>
                </div>
                <div
                  className="button radius-4  d-flex justify-content-center align-center"
                  style={{
                    height: "28px",
                    width: "28px",
                    background: "#619130",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    size="xs"
                    icon="fa-regular fa-paper-plane-top"
                    style={{ color: "#FFFFFF" }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      {/*--------------Documents found in communication table ---------------------*/}
      <div className=" my-4 px-3 py-2 radius-8 customers-card-layout">
        <H2 className=" d-flex">
          <span className="me-2 ">Documents found in communication</span>{" "}
          <CustomToolTip
            height="12px"
            text="Documents found in communication"
          />{" "}
        </H2>
        <div>
          <MRTTheme
            colorVariantsForTableData={["even"]}
            table={
              <MaterialReactTable
                columns={columns}
                // data={CommunicationTable}
                data={[]}
                icons={{ ...SortIcon }}
                localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                enableColumnActions={false}
                enableColumnFilters={false}
                // enablePagination={false}
                enableSorting={false}
                enableBottomToolbar={false}
                enableTopToolbar={false}
                muiTableBodyRowProps={{ hover: false }}
                enableRowActions
                positionActionsColumn="last"
                renderRowActions={({ row, table }) => (
                  <div className="d-flex gap-2">
                    <button
                      className="icon-btn"
                      onClick={() => setOpenScreeningPdf(true)}
                    >
                      <FontAwesomeIcon
                        icon="fa-regular fa-file-magnifying-glass"
                        style={{ color: "#000000" }}
                      />
                    </button>
                    <button className="icon-btn">
                      <label for="files" class="button">
                        <FontAwesomeIcon icon="fa-regular fa-upload" />
                      </label>
                      <input
                        id="files"
                        style={{ display: "none" }}
                        type="file"
                      />
                    </button>
                    <button className="icon-btn">
                      <FontAwesomeIcon icon="fa-regular fa-paper-plane" />
                    </button>
                    <button
                      className="icon-btn"
                      onClick={() => setDeletePdfPopUp(true)}
                    >
                      <FontAwesomeIcon icon="fa-regular fa-trash" />
                    </button>
                    <button className="icon-btn">
                      <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-line" />
                    </button>
                  </div>
                )}
                muiTableContainerProps={{
                  sx: {
                    padding: "0px !important",
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    backgroundColor: "#F9FAFA",
                  },
                }}
              />
            }
          />

          {/* popup for opening pdf screening uploaded pdf */}
          <CustomPopup
            open={openScreeningPdf}
            onHide={() => setOpenScreeningPdf(false)}
            headerRequired={false}
          >
            <div className="p-3" style={{ height: "550px" }}>
              <iframe
                src="https://therichpost.com/sample.pdf#toolbar=0&navpanes=0&scrollbar=0"
                height="100%"
                width="100%"
              ></iframe>
            </div>
            <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
              {/* <ETButton
                variant = {"white"}
            buttonText="COLD FEET"
            onClick={() => setDeletePdfPopUp(false)}
          />
          <ETButton
           variant={"primary"}
            buttonText="I’M SURE"
            fontSize="14px"
            fontWeight="500"
            onClick={() => setDeletePdfPopUp(false)}
          /> */}
            </div>
          </CustomPopup>
          {/* popup for opening pdf screening uploaded pdf */}

          {/* popup for deleting screening uplaoded pdf */}
          <CustomPopup
            open={deletePdfPopUp}
            onHide={() => setDeletePdfPopUp(false)}
            title="Are you sure you want to delete?"
            title_color="black"
            enableCrossButton={false}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={true}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
            deletePopUpStyle={true}
          >
            <div className="p-3">
              <p className="light-font-text">
                Did you know that by deleting this document it will affect the
                ongoing screening process. After deleting the tenant will get
                notified and asked for a new upload of the document.
              </p>
            </div>
            <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
              <ETButton
                variant={"white"}
                buttonText="COLD FEET"
                onClick={() => setDeletePdfPopUp(false)}
              />
              <ETButton
                variant={"primary"}
                buttonText="I’M SURE"
                onClick={() => setDeletePdfPopUp(false)}
              />
            </div>
          </CustomPopup>
          {/* popup for deleting screening uplaoded pdf */}
        </div>
      </div>
    </Container>
  );
};

export default Communicaton;
