import React from "react";
import { H4 } from "../../../styles/Common.style";

const Notifications = () => {
  return (
    <div>
      <div className="px-4 pt-2">
        <H4>Notifications</H4>
        <div className="light-font-text pb-4 pt-2">
          Set how you want notifications sent from the system to be delivered to
          you. It is not recommended to switch off all notifications.
        </div>
      </div>
    </div>
  );
};

export default Notifications;
