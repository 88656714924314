import React from "react";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { H2 } from "../../../../styles/Common.style";
import {
  useOrganizationId,
  useOrganizationSettingsBreadcrumbsTabs,
} from "../../../../hooks/stores/useOrganizationStore";

import {
  TabList,
  TabPanel,
  Tabs,
  Tab,
} from "../../../../Components/Tabs/ReactTabs.style";
import OrganizationConfiguration from "./configuration/OrganizationConfiguration";
import SenderSetting from "./SenderSetting";
const Communication = () => {
  // zustand
  const setOrganizationBreadcrumbsTabs = useOrganizationSettingsBreadcrumbsTabs(
    (state) => state.setOrganizationBreadcrumbsTabs
  );

  return (
    <div className="whiteContainerStyle">
      <div className="px-3 pb-3 border-bottom">
        <div className="px-2 pt-3 d-flex">
          <div className="d-flex align-items-end  gap-2">
            <H2>Communication</H2>
            <CustomToolTip size="sm" text="Communication" />
          </div>
        </div>
      </div>

      {/*All tabs section  */}

      <div className=" border-bottom">
        <div>
          <Tabs className="d-flex flex-column ">
            <div
              className="d-flex px-4 py-3 mb-3 justify-content-between align-items-center"
              style={{
                width: "100%",
                flexDirection: "row",
                flexWrap: "wrap",
                borderBottom: "1px solid #F0F0F0",
              }}
            >
              <TabList
                className=" d-flex light ps-0 light"
                style={{ gap: "7px" }}
              >
                <Tab
                  onClick={() =>
                    setOrganizationBreadcrumbsTabs("Sender settings")
                  }
                >
                  Sender settings
                </Tab>
                <Tab
                  onClick={() => setOrganizationBreadcrumbsTabs("Templates")}
                >
                  Templates
                </Tab>
                <Tab
                  onClick={() =>
                    setOrganizationBreadcrumbsTabs("Configuration")
                  }
                >
                  Configuration
                </Tab>
              </TabList>
            </div>
            <div>
              <TabPanel>
                <SenderSetting />
              </TabPanel>
              <TabPanel>
                <div>Templates</div>
              </TabPanel>
              <TabPanel>
                <OrganizationConfiguration />
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Communication;

// Created by ------> Yash
