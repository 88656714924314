import React, { useEffect, useMemo, useState } from "react";
import TagToolTip from "../../Components/Tooltip/PropertyTagToolTip";
import StarRating from "../../Components/rating/StarRating";
import { GreenSpan } from "../../styles/Common.style";
import { useMediaQuery } from "react-responsive";
import {
  SearchIcon,
  SearchStyle,
  TableRowsStyle,
  paginationoptions,
  SortIcon
} from "../../Components/table/MRTGlobalProps";
import { Col, Row } from "react-bootstrap";
import {
  MRT_GlobalFilterTextField,
  MaterialReactTable,
} from "material-react-table";
import ETButton from "../../Components/Buttons/ETButton";
import Menus from "../../Components/Menus/Menus";
import { InProcessTableData } from "./StaticData";
import MRTTheme from "../../Components/table/MRTTheme";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import { NoRecordsToDisplay } from "../../utils/validationConstants";
import { useBreadcrumb } from "../../Components/BreadcrumbContext ";
import { H2 } from "../../styles/Common.style";

const ContactsArchive = () => {
  const [favorite, setFavorite] = useState(null);
  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    updateBreadcrumbs(["Archive"]);
  }, []);
  const columns = useMemo(
    () => [
      {
        accessorKey: "Id",
        header: "",
        size: 50,
        minSize: 50,
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              cursor: "pointer",
            }}
          >
            <div>
              <TagToolTip text="Need Help About Tags" border="1px solid #888" />
            </div>
          </div>
        ),
        muiTableBodyCellProps: {
          sx: {
            backgroundColor: "#F0F0F0 !important",
            border: "none !important",
          },
        },
      },
      {
        accessorKey: "name",
        header: "Name",
        minSize: 180,
        size: 160,
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
            backgroundColor: "#F0F0F0 !important",
            border: "none !important",
            borderRight: "1px solid #888888 !important",
          },
        },
      },
      // {
      // 	accessorKey: "phone",
      // 	header: "Phone",
      // 	size: 140,
      // },
      // {
      // 	accessorFn: (row) => `${row.email}`,
      // 	header: "Email",
      // 	// size: 100,
      // 	Cell: ({ row }) => (
      // 		<div>{row.original.email ? row.original.email : "-"}</div>
      // 	),
      // },
      {
        accessorKey: "age",
        header: "Age",
        size: 40,
      },
      {
        accessorFn: (row) => `${row.rating}`,
        header: "Rating",
        size: 130,
        Cell: ({ row }) => (
          <div>
            <StarRating
              setFavoriteRating={setFavorite}
              favoriteRating={favorite}
            />
          </div>
        ),
      },
      {
        accessorFn: (row) => `${row.Relation}`,
        header: "Relation",
        size: 300,
        Cell: ({ row }) => (
          <div>
            {row.original.Relation} (<GreenSpan>show</GreenSpan>)
          </div>
        ),
      },
      {
        accessorKey: "Lifecycle stage",
        header: "Lifecycle stage",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "responsible_agent",
        header: "Responsible agent",
        size: 200,
      },
      // {
      // 	accessorFn: (row) => `${row.archived_status}`,
      // 	header: "Status",
      // 	size: 200,
      // 	Cell: ({ row }) => (
      // 		<div
      // 			className="radius-4  form-label-title fs-12 d-flex justify-content-center align-items-center"
      // 			style={{
      // 				backgroundColor: "#d1d1d1",
      // 				padding: "2px 4px",
      // 			}}
      // 		>
      // 			{row.original.archived_status}
      // 		</div>
      // 	),
      // },
      // {
      // 	accessorFn: (row) => `${row.rental_period}`,
      // 	header: "Rental period",
      // 	size: 200,
      // 	Cell: ({ row }) => (
      // 		<div>
      // 			{row.original.rental_period ? row.original.rental_period : "-"}
      // 		</div>
      // 	),
      // },
      // {
      // 	accessorKey: "archiving_date",
      // 	header: "Dato of archiving",
      // 	size: 165,
      // 	muiTableBodyCellProps: {
      // 		sx: {
      // 			// padding: "0px !important",
      // 			backgroundColor: "#F0F0F0 !important",
      // 			border: "none !important",
      // 			borderLeft: "1px solid #888888 !important",
      // 		},
      // 	},
      // },
      {
        accessorKey: "automated_deletion",
        header: "Automated deletion",
        size: 165,
        muiTableBodyCellProps: {
          sx: {
            // padding: "0px !important",
            backgroundColor: "#F0F0F0 !important",
          },
        },
      },
    ],
    []
  );
  // below code is for give responsive design to fixed columns
  const isDesktop = useMediaQuery({ minWidth: 1224 });
  const [columnPinning, setColumnPinning] = useState({});
  useEffect(() => {
    if (isDesktop) {
      setColumnPinning({
        left: ["Id", "name"],
        right: ["archiving_date", "automated_deletion", "mrt-row-actions"],
      });
    } else {
      setColumnPinning({
        right: ["mrt-row-actions"],
      });
    }
  }, [isDesktop]);
  return (
    <div style={{ backgroundColor: "#ffffff" }}>
      {" "}
      <div
        className="px-4 pt-3 pb-3 py-3 d-flex justify-content-between"
        style={{ borderBottom: "1px solid #F0F0F0" }}
      >
        <div className=" d-flex">
          <H2 >Archive</H2>
          <div className="ps-3">
            {" "}
            <CustomToolTip height={12} text="Archive" />{" "}
          </div>
        </div>
      </div>
      <div className="px-4 pt-4 pb-5" style={{ width: "100% !important" }}>
        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={columns}
              data={[]}
              icons={{...SortIcon}}
              localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
              enableColumnActions={false}
              enableTopToolbar={true}
              enableRowSelection={false}
              enableRowActions
              enableSelectAll
              enablePinning
              onColumnPinningChange={setColumnPinning}
              state={{ columnPinning }}
              enableFilterMatchHighlighting={true}
              enableGlobalFilter
              globalFilterFn="contains"
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "",
                  size: 60,
                  muiTableBodyCellProps: {
                    sx: {
                      // padding: "0px !important",
                      backgroundColor: "#F0F0F0 !important",
                      // borderLeft: "1px solid #888888 !important",
                    },
                  },
                },
              }}
              positionActionsColumn="last"
              renderRowActions={({ row }) => (
                <Menus
                  itemheight={23}
                  itemwidth={23}
                  iconborderradius="4px"
                  itemcontainerwidth="180px"
                  component={
                    <div className="light-font-text">
                      <div className="p-2 cursor-pointer">Contact card</div>
                      <hr className="hr m-0"></hr>
                      <div className="p-2 cursor-pointer">Unarchive</div>
                      <hr className="hr m-0"></hr>
                      <div className="p-2 cursor-pointer">Delete</div>
                    </div>
                  }
                />
              )}
              renderTopToolbar={({ table }) => (
                <>
                  <Row>
                    <Col lg={3} className="pb-2 pe-lg-0">
                      <MRT_GlobalFilterTextField table={table} />
                    </Col>
                    <Col lg={2} className="pb-2 pe-lg-0">
                      <ReactSelect placeholder={"Filter"} />
                    </Col>
                    <Col lg={7} className="pb-2">
                      <div className="d-flex justify-content-end">
                        <ETButton
                          buttonText={"EXPORT MULTIPLE"}
                          variant={"primary"}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              muiTablePaperProps={{
                selected: false,
                elevation: 0,
                fontFamily: "Cerebri sans-light !important",
                sx: {
                  borderRadius: "0",
                  border: "none",
                  // backgroundColor: "#F9FAFA",
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                },
              }}
              muiSearchTextFieldProps={{
                ...SearchStyle,

                InputProps: {
                  ...SearchIcon,
                },
              }}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
            />
          }
        />
      </div>
    </div>
  );
};

export default ContactsArchive;
