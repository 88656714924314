import React from "react";
import styled from "styled-components";
// import "./checkbox.scss";

const CustomCheckBox = ({
  onClick,
  value,
  onChange,
  id,
  name,
  checked,
  key,
  disabled,
  label,
  labelAlign,
}) => {
  return (
    <CheckboxWrapper>
      {label && labelAlign === "left" && (
        <CheckboxLabel className="me-2 ">{label}</CheckboxLabel>
      )}
      <CheckboxInput
        // className="CheckboxInput"
        type="checkbox"
        checked={checked}
        onChange={onChange}
        id={id}
        key={key}
        name={name}
        value={value}
        onClick={onClick}
        disabled={disabled}
      />
      {label && labelAlign === "right" && (
        <CheckboxLabel className="ms-2 ">{label}</CheckboxLabel>
      )}
    </CheckboxWrapper>
  );
};

export default CustomCheckBox;

CustomCheckBox.defaultProps = {
  labelAlign: "right",
};

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 8px;
`;

const CheckboxInput = styled.input`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  margin: 0;
  width: 17px;
  height: 17px;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  &:checked {
    background-color: ${(props) =>
      props.disabled
        ? props.theme.component.checkbox.primary.color // Define the desired disabled background color here
        : props.theme.component.checkbox.primary.color};
    border-color: ${(props) =>
      props.disabled
        ? props.theme.component.checkbox.primary.color // Define the desired disabled border color here
        : props.theme.component.checkbox.primary.color};
        
    &::before {
      content: "\\2713";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 11px;
    }
  }

  // &:hover {
  //   border-color: ${(props) => props.theme.component.checkbox.primary.color};
  // }
  &:hover {
    border-color: ${(props) =>
      !props.disabled
        ? props.theme.component.checkbox.primary.color
        : "#ccc"}; /* Change the border color when not disabled */
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")}; /* Change the cursor when disabled */
  }
`;

const CheckboxLabel = styled.label`
  font-size: 14px;
`;
