import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Upload_MediaPackages from "./Upload_MediaPackages";
import {
  GetMediaFilesByPackageId,
  GetMediaPackageByPackageId,
} from "../../services/Media";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { GetTagsByPropertyId } from "../../services/Tags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddEditPackage from "../../views/properties/units/AddEditPackage/AddEditPackage";
import {
  useGetMediaSortOrder,
  usePostAddMediaPAckage,
  usePutUpdateMediaPackageBasicDetails,
  useUpdateMediaPackageCompleteDetails,
} from "../../hooks/queries/useMediaQueries";
import CustomPopup from "../../Components/CustomPopup";
import ETButton from "../../Components/Buttons/ETButton";
import { usePropertyOverview } from "../../hooks/stores/usePropertyStore";
import { useAddEditPackage } from "../../hooks/stores/usePackageStore";
import { useRouting } from "../../hooks/stores/useRoutingStore";
import { status } from "../../model/Constant";
import moment from "moment";
import "../facilities/Facilitiescss.css";
import { PrimaryBreadcrums } from "../../styles/Common.style";
import { CustomersTab } from "../../Components/Tabs/CustomersTabs.style";
import { Link } from "react-router-dom";
import { H1 } from "../../styles/Common.style";

const CreateMediaPackages = ({
  propertyOverview,
  // setShowCreateMediaPackage,
  isEditMediaPackage,
  mediaPackageId,
  setMediaPackageId,
}) => {
  //------- zustand
  const propOverview = usePropertyOverview((state) => state.propOverview);
  const {
    showPackage,
    setShowPackage,
    editflag,
    packageId,
    setPackageId,
    packageType,
  } = useRouting((state) => state);
  const {
    title,
    setTitle,
    active,
    setActive,
    tagRowData,
    setTagRowData,
    selected,
    setSelected,
    selectedData,
    setSelectedData,
    cancelPopup,
    setCancelPopup,
  } = useAddEditPackage((state) => state);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(
    `${editflag ? "Settings" : "1. Settings"}`
  );
  // const [tagRowData, setTagRowData] = useState([]);
  // const [cancelPopup, setCancelPopup] = useState(false);

  //----------States for creating media packages----------//
  const [mediaPackageName, setMediaPackageName] = useState("");
  const [sortOrderId, setSortOrderId] = useState("");
  const [mediaFiles, setMediaFiles] = useState([]);
  const [addMediaPage, setAddMediaPage] = useState(false);
  // const [active, setActive] = useState(true);
  const [flagUpdateMedia, setFlagUpdateMedia] = useState(false);

  // state used in addEditPackage checkbox logic //
  // const [selected, setSelected] = useState([]);
  // const [selectedData, setSelectedData] = useState([]);

  //---------------React query-------------//
  const {
    data: getMediaPackageByPackageId,
    refetch: getMediaPackageByPackageIdRefetch,
  } = useQuery(
    ["getMediaPackageByPackageId", propOverview?.[0]?.property_id, packageId],
    () => GetMediaPackageByPackageId(propOverview?.[0]?.property_id, packageId),
    { enabled: !!editflag }
  );

  const { data: tagsByPropertyIdData } = useQuery(
    ["propertyId", propOverview?.[0]?.property_id],
    () => GetTagsByPropertyId(propOverview?.[0]?.property_id),
    {
      enabled: !!propOverview?.[0]?.property_id,
    }
  );
  const {
    data: getMediaFilesByPackageId,
    refetch: getMediaFilesByPackageIdRefetch,
  } = useQuery(
    ["mediaFilesByPackageId", packageId],
    () => GetMediaFilesByPackageId(packageId),
    { enabled: !!editflag }
  );
  const { data: mediaSortOrder } = useGetMediaSortOrder();
  const {
    mutateAsync: PutUpdateMediaPackageCompleteDetails,
    isLoading: PutUpdateMediaPackageCompleteDetailsIsLoading,
  } = useUpdateMediaPackageCompleteDetails();
  const {
    mutateAsync: PutUpdateMediaPackageBasicDetails,
    isLoading: PutUpdateMediaPackageBasicDetailsIsLoading,
  } = usePutUpdateMediaPackageBasicDetails();
  const {
    mutateAsync: PostAddMediaPackage,
    isLoading: PostAddMediaPAckageIsLoading,
  } = usePostAddMediaPAckage();

  useEffect(() => {
    if (editflag) {
      setMediaPackageById(getMediaPackageByPackageId?.data);
      // setMediaPackageName(getMediaPackageByPackageId?.data[0]?.package_name);
      setTitle(getMediaPackageByPackageId?.data[0]?.package_name); //zustand state
      setSortOrderId(getMediaPackageByPackageId?.data[0]?.sort_order_id);

      const filteredData =
        getMediaPackageByPackageId?.data[0]?.tag_ids?.split(",");
      const tagData = tagsByPropertyIdData?.data?.filter((el) =>
        filteredData?.includes(el.tag_id?.toString())
      );
      setTagRowData(tagData);
      setSelected(filteredData);
      setSelectedData(tagData);
      setMediaFiles(getMediaPackageByPackageId?.data?.[0]?.media_details);
      setCancelPopup(false);
    } else {
      setActive(true);
    }
  }, [getMediaPackageByPackageId, tagsByPropertyIdData]);

  const handleAddBasicDetails = async () => {
    if (editflag || flagUpdateMedia) {
      const putEditMediaBasicDetails = {
        media_package_id: packageId?.toString(),
        tags: tagRowData?.map((item) => {
          return item?.tag_id;
        }),
        sort_order_id: sortOrderId?.toString(),
        media_package_name: title,
        is_active: active ? "1" : "0",
      };
      try {
        const response = await PutUpdateMediaPackageBasicDetails(
          putEditMediaBasicDetails
        );
        if (response.status == status.s) {
          toast.success(response.data.message);
          setFlagUpdateMedia(false);
          setAddMediaPage(true);
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    } else {
      setCancelPopup(false);

      const postAddMediaPackage = {
        property_id: propOverview?.[0]?.property_id?.toString(),
        tags: tagRowData?.map((item) => {
          return item?.tag_id;
        }),
        sort_order_id: sortOrderId?.toString(),
        media_package_name: title?.toString(),
      };
      try {
        const response = await PostAddMediaPackage(postAddMediaPackage);
        if (response.status == status.s) {
          toast.success(response.data.message);
          setPackageId(response?.data?.data?.media_package_id);
          setAddMediaPage(true);
          setCancelPopup(false);
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };
  const handleCreateMediaPackage = async () => {
    setCancelPopup(false);

    const payloadCompleteMediaDetails = {
      media_package_id: packageId?.toString(),
      tags: tagRowData?.map((item) => {
        return item?.tag_id;
      }),
      sort_order_id: sortOrderId?.toString(),
      media_package_name: title,
      is_active: active ? "1" : "0",
      property_id: propOverview?.[0]?.property_id?.toString(),
      media_files: mediaFiles?.map((el) => {
        return {
          media_file_id: el?.media_file_id?.toString(),
          media_file_category_id: el?.media_file_category_id?.toString(),
          is_published: el?.is_published || 0,
        };
      }),
    };
    try {
      const response = await PutUpdateMediaPackageCompleteDetails(
        payloadCompleteMediaDetails
      );
      if (response?.data?.bReturn) {
        toast.success(response.data.message);
        setShowPackage(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const [mediapackagebyid, setMediaPackageById] = useState([]);

  const modifiedData = mediapackagebyid?.map((item) => {
    return {
      ...item,
      modifiedOn: moment(item.modified_on).format("DD.MMMM.YYYY | HH:mm:ss"),
      createdOn: moment(item.created_on).format("DD.MMMM.YYYY | HH:mm:ss"),
    };
  });
  return (
    <>
      <Container
        fluid
        style={{ backgroundColor: "#F9FAFA" }}
        className=" pt-3 px-0 "
      >
        <H1>{editflag ? "Edit" : "Create new"} package</H1>
        <div className="light-font-text">
          {/* {editflag && ( */}
          <>
            <Link
              to="/properties"
              style={{ textDecoration: "none", color: "black" }}
            >
              <span className="me-3">Property</span>
            </Link>
            <span className="me-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <Link
              to="/property-units/overview"
              state={{
                Tab: "Overview",
              }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <span className="me-3">{propOverview?.[0]?.propertyName}</span>
            </Link>
            <span className="me-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
          </>
          {/* )} */}
          <span
            className="me-3 cursor-pointer"
            // onClick={() => setShowCreateMediaPackage(false)}>
            onClick={() => setShowPackage(false)}
          >
            Media
          </span>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />
          </span>
          <span className="me-3 cursor-pointer">Packages</span>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />
          </span>
          <span className="me-3">
            {editflag ? "Edit" : "Create new"} package
          </span>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />
          </span>
          <PrimaryBreadcrums mode={!addMediaPage} className="me-3">
            {/* {selectedTab} */}
            1. Settings
          </PrimaryBreadcrums>
          {addMediaPage && (
            <>
              <span className="mx-1">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />{" "}
              </span>
              <PrimaryBreadcrums mode={addMediaPage} className="mx-1">
                2. {editflag ? "Edit" : "Add"} media
              </PrimaryBreadcrums>
            </>
          )}
        </div>
        <div
          className=" my-3 px-4 py-3 radius-8"
          style={{ backgroundColor: "#F1F5F0" }}
        >
          <div
            className="d-flex gap-2 pb-2"
            style={{ alignItems: "center", textAlign: "center" }}
          >
            <div>
              <CustomersTab
                mode={!addMediaPage}
                // className={
                //   !addMediaPage
                //     ? "px-2 facility-tabs-active"
                //     : "facility-tabs px-2"
                // }
              >
                {addMediaPage && (
                  <FontAwesomeIcon
                    icon="fa-solid fa-circle-check"
                    className="me-2"
                    style={{ color: "#77C7A1", fontSize: "0.9rem" }}
                  />
                )}
                1. Settings
              </CustomersTab>
            </div>
            <div style={{ alignItems: "center", textAlign: "center" }}>
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </div>
            <div>
              <CustomersTab
                mode={addMediaPage}
                // className={
                //   addMediaPage == true
                //     ? "px-2 facility-tabs-active"
                //     : "facility-tabs px-2"
                // }
              >
                2. {editflag ? "Edit" : "Add"} media
              </CustomersTab>
            </div>
          </div>
          <div className="mb-2 bg-white radius-8 ">
            {!addMediaPage ? (
              <AddEditPackage
                modifiedData={modifiedData}
                packageType={packageType}
                // title={mediaPackageName}
                // setTitle={setMediaPackageName}
                showDropdown={true}
                dropDownData={mediaSortOrder?.data}
                dropDownValue={sortOrderId}
                setDropDownValue={setSortOrderId}
                dropdownHeading={"Sort media"}
                dropDownSubHeading={"Choose how the medias should be shown"}
                dropDownLabel={"Sort order"}
                showActiveInactive={true}
                setActive={setActive}
                active={active}
                propertyId={propOverview?.[0]?.property_id}
                tagRowData={tagRowData}
                setTagRowData={setTagRowData}
                handleNext={handleAddBasicDetails}
                selected={selected}
                setSelected={setSelected}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                cancelPackage={setCancelPopup}
                disableNextButton={
                  PutUpdateMediaPackageBasicDetailsIsLoading ||
                  PostAddMediaPAckageIsLoading
                }
              />
            ) : (
              <Upload_MediaPackages
                modifiedData={modifiedData}
                propertyId={propOverview?.[0]?.property_id}
                handleCreateMediaPackage={handleCreateMediaPackage}
                PutUpdateMediaPackageCompleteDetailsIsLoading={
                  PutUpdateMediaPackageCompleteDetailsIsLoading
                }
                getMediaPackageByPackageId={getMediaPackageByPackageId}
                mediaFiles={mediaFiles}
                setMediaFiles={setMediaFiles}
                // isEditMediaPackage={isEditMediaPackage}
                setAddMediaPage={setAddMediaPage}
                getMediaFilesByPackageIdRefetch={
                  getMediaFilesByPackageIdRefetch
                }
                setFlagUpdateMedia={setFlagUpdateMedia}
                // mediaPackageId={mediaPackageId}
                setCancelPopup={setCancelPopup}
                getMediaPackageByPackageIdRefetch={
                  getMediaPackageByPackageIdRefetch
                }
              />
            )}
          </div>
        </div>
      </Container>

      {/* Cancel popup */}
      <CustomPopup
        style={{ position: "relative" }}
        size="md"
        open={cancelPopup}
        onHide={() => setCancelPopup(false)}
        title="Are you sure you want to cancel?"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() => {
          setCancelPopup(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div>
            <div
              style={{
                fontSize: "14px",
                fontFamily: "Cerebri Sans",
                fontWeight: "500",
                textAlign: "left",
                lineHeight: "17.5px",
              }}
            >
              Cancelling will undo all your changes
            </div>
          </div>
        </div>

        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            //   position: "absolute",
            //   width: "100%",
            //   bottom: "0",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="NO"
            onClick={() => setCancelPopup(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="YES"
            // onClick={() => setShowCreateMediaPackage(false)}
            onClick={() => setShowPackage(false)}
          />
        </div>
      </CustomPopup>
    </>
  );
};

export default CreateMediaPackages;
