import React from "react";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../Components/Tabs/ReactTabs.style";
import Reservation from "./Reservation";
import Screening from "./Screening";
import Signing from "./Signing";
import { useBreadcrumb } from "../../Components/BreadcrumbContext ";

const Ongoing = () => {
  const { updateBreadcrumbs } = useBreadcrumb();
  return (
    <div>
      <Tabs lassName="mb-3 pb-1 d-flex flex-column">
        <div
          className="d-flex ps-4 pe-3 py-3 justify-content-between"
          style={{
            width: "100%",
            flexDirection: "row",
            flexWrap: "wrap",
            borderBottom: "1px solid #F0F0F0",
          }}
        >
          <TabList className="light ps-0  ">
            <Tab onClick={() => updateBreadcrumbs(["Overview", "Ongoing", "Reservation"])}>Reservation</Tab>
            <Tab onClick={() => updateBreadcrumbs(["Overview", "Ongoing", "Screening"])}>Screening</Tab>
            <Tab onClick={() => updateBreadcrumbs(["Overview", "Ongoing", "Signing"])}>Signing</Tab>
          </TabList>
        </div>
        <div className="bg-white" style={{ borderRadius: " 10px" }}>
          <TabPanel>
            <Reservation />
          </TabPanel>
          <TabPanel>
            <Screening />
          </TabPanel>
          <TabPanel>
            <Signing />
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
};

export default Ongoing;
