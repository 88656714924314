import React, { useRef, useState, useMemo } from "react";
import CSVWidget from "../../../Components/DownloadWidget/CSVWidget";
import { Col, Row, Form } from "react-bootstrap";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import Menus from "../../../Components/Menus/Menus";
import { MaterialReactTable } from "material-react-table";
import CustomPopup from "../../../Components/CustomPopup";
import ETButton from "../../../Components/Buttons/ETButton";
import { useForm, Controller } from "react-hook-form";
import {
  NoRecordsToDisplay,
  REQUIRED_ERR,
} from "../../../utils/validationConstants";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import Checkbox from "../../../Components/Checkbox/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GreenDiv, H3 } from "../../../styles/Common.style";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import MRTTheme from "../../../Components/table/MRTTheme";
import { H1, H2 } from "../../../styles/Common.style";
import { SortIcon } from "../../../Components/table/MRTGlobalProps";

const CompleteEventTableData = [
  {
    Blank: "Ørestad Have",
    By: "by Desirée Mogensen",
    EventDate: "Event date: 2021-08-08-12:30 - 13:00",
    Registrations: "0",
    Participated: "0",
    NoofUnitsReversed: "0",
  },
  {
    Blank: "Ørestad Have",
    By: "by Desirée Mogensen",
    EventDate: "Event date: 2021-08-08-12:30 - 13:00",
    Registrations: "0",
    Participated: "0",
    NoofUnitsReversed: "0",
  },
  {
    Blank: "Ørestad Have",
    By: "by Desirée Mogensen",
    EventDate: "Event date: 2021-08-08-12:30 - 13:00",
    Registrations: "0",
    Participated: "0",
    NoofUnitsReversed: "0",
  },
  {
    Blank: "Ørestad Have",
    By: "by Desirée Mogensen",
    EventDate: "Event date: 2021-08-08-12:30 - 13:00",
    Registrations: "0",
    Participated: "0",
    NoofUnitsReversed: "0",
  },
];

const CompleteEvents = () => {
  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "completed-events";

  const options = {
    keys: [
      { field: "Blank", title: "Blank" },
      { field: "By" },
      { field: "EventDate" },
      { field: "Registrations" },
      { field: "Participated" },
      { field: "NoofUnitsReversed" },
    ],
  };

  const data = CompleteEventTableData;

  const CSVFunction = CSVWidget({ refObj, fileName, options, data });

  // Add information  > add participants table

  const AddParticipants = ({ status, number }) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            fontFamily: "Cerebri sans",
            fontSize: "13px",
          }}
        >
          <div>
            <div
              className="ms-2 me-3 form-label-title fs-16"
              style={{ width: "max-content" }}
            >
              {status}
            </div>

            <div
              className="ms-2 fs-12 light-font-text d-flex"
              style={{ width: "max-content" }}
            >
              {number}
            </div>
          </div>
        </div>
      </>
    );
  };

  const AddParticipantValue = ({ status, number, sideBorder }) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            fontFamily: "Cerebri sans",
            fontSize: "13px",
          }}
        >
          <div>
            <div
              className="ms-2 me-3 normal-font-text fs-13"
              style={{ width: "max-content" }}
            >
              {status}
            </div>

            <H1 className="ms-2 d-flex ">{number}</H1>
          </div>
        </div>
      </>
    );
  };

  // Add information Modal

  const [openAddInfo, setOpenAddInfo] = useState(false);

  // Add information about event > Add participants button modal

  const [openAddParticipants, setOpenAddParticipants] = useState(false);

  const AddInformationAboutEventColumn = useMemo(() => [
    {
      header: "Name",
      enableHiding: false,

      size: 220,

      Cell: ({ row }) => (
        <>
          <div>
            <div>
              <div className="fw-600 fs-14">{row.original.name}</div>
            </div>
          </div>
        </>
      ),
    },
    {
      // muiTableBodyCellProps: {
      //   align: "center",
      // },
      // muiTableHeadCellProps: {
      //   align: "center",
      // },
      header: "Quantity",
      size: 50,

      Cell: ({ row }) => (
        <>
          <div>
            <div className="fw-300 fs-13">{row.original.mail}</div>
            <div className="fw-300 fs-14">{row.original.contact}</div>
          </div>
        </>
      ),
    },

    {
      header: "Percentage",
      muiTableBodyCellProps: {
        align: "center",
        sx: {
          paddingLeft: "0px !important",
        },
      },
      size: 180,
      Cell: ({ row }) => (
        <>
          <div className="fw-300 fs-14 pe-5 me-4">{row.original.assign}</div>
        </>
      ),
    },
  ]);

  const CompleteEventTableColumns = useMemo(() => [
    {
      header: "",
      id: "event info",

      size: 120,

      Cell: ({ row }) => (
        <>
          <div>
            <div className="ps-3">
              <div className="fw-600 fs-14">{row.original.Blank}</div>
              <div className="fs-13">{row.original.By}</div>
              <div className="">{row.original.EventDate}</div>
            </div>
          </div>
        </>
      ),
    },

    {
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableHeadCellProps: {
        align: "center",
      },
      header: "Registered",
      size: 10,

      Cell: ({ row }) => (
        <>
          <div className="d-flex justify-content-center">
            <div className="fw-600 fs-20 pe-2">
              {row.original.Registrations}
            </div>
            <div style={{ color: "#77C7A1" }}>
              <FontAwesomeIcon icon="fa-regular fa-arrow-up" />1
            </div>
          </div>
        </>
      ),
    },

    {
      header: "Participated",
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableHeadCellProps: {
        align: "center",
      },
      size: 30,
      Cell: ({ row }) => (
        <>
          <div className="d-flex justify-content-center">
            <div className=" fw-600 fs-20 pe-2">
              {row.original.Participated}
            </div>
            <div style={{ color: "#F1664B" }}>80%</div>

            <div></div>
          </div>
        </>
      ),
    },

    {
      header: "No. of units reserved",
      size: 20,
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableHeadCellProps: {
        align: "center",
      },
      Cell: ({ row }) => (
        <>
          <div className="d-flex justify-content-center align-items-baseline">
            <div className="fw-600 fs-20 pe-2">
              {row.original.NoofUnitsReversed}
            </div>
            <div className="d-flex cursor-pointer">
              (<GreenDiv>show</GreenDiv>)
            </div>
          </div>
        </>
      ),
    },
  ]);

  const AddInformationAboutEvent = [
    {
      name: "Peter Stanford Mikkelsen",
      mail: "peterstanfordmikkelsen@gmail.com",
      contact: "+45 12345678",
      assign: "Johannes Schimdt Nielsen",
    },
    {
      name: "Peter Stanford Mikkelsen",
      mail: "peterstanfordmikkelsen@gmail.com",
      contact: "+45 12345678",
      assign: "Johannes Schimdt Nielsen",
    },
    {
      name: "Peter Stanford Mikkelsen",
      mail: "peterstanfordmikkelsen@gmail.com",
      contact: "+45 12345678",
      assign: "Johannes Schimdt Nielsen",
    },
    {
      name: "Peter Stanford Mikkelsen",
      mail: "peterstanfordmikkelsen@gmail.com",
      contact: "+45 12345678",
      assign: "Johannes Schimdt Nielsen",
    },
    {
      name: "Peter Stanford Mikkelsen",
      mail: "peterstanfordmikkelsen@gmail.com",
      contact: "+45 12345678",
      assign: "Johannes Schimdt Nielsen",
    },
    {
      name: "Peter Stanford Mikkelsen",
      mail: "peterstanfordmikkelsen@gmail.com",
      contact: "+45 12345678",
      assign: "Johannes Schimdt Nielsen",
    },
    {
      name: "Peter Stanford Mikkelsen",
      mail: "peterstanfordmikkelsen@gmail.com",
      contact: "+45 12345678",
      assign: "Johannes Schimdt Nielsen",
    },
    {
      name: "Peter Stanford Mikkelsen",
      mail: "peterstanfordmikkelsen@gmail.com",
      contact: "+45 12345678",
      assign: "Johannes Schimdt Nielsen",
    },
    {
      name: "Peter Stanford Mikkelsen",
      mail: "peterstanfordmikkelsen@gmail.com",
      contact: "+45 12345678",
      assign: "Johannes Schimdt Nielsen",
    },
    {
      name: "Peter Stanford Mikkelsen",
      mail: "peterstanfordmikkelsen@gmail.com",
      contact: "+45 12345678",
      assign: "Johannes Schimdt Nielsen",
    },
    {
      name: "Peter Stanford Mikkelsen",
      mail: "peterstanfordmikkelsen@gmail.com",
      contact: "+45 12345678",
      assign: "Johannes Schimdt Nielsen",
    },
  ];

  //  Validation

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      fName: "",
      lName: "",
      streetName: "",
      houseNo: "",
      floor: "",
      sideFloor: "",
      zipCode: "",
      city: "",
      country: "",
      email: "",
      phone: "",
      DOB: "",
      currentHousingType: "",
      civilType: "",
      gender: "",
      preferredUnit: "",
    },
  });

  const CurrentHousingType = [
    {
      id: 1,
      type: "Owner",
    },
    {
      id: 2,
      type: "Renting",
    },
    {
      id: 3,
      type: "Other",
    },
  ];

  const CivilType = [
    {
      id: 1,
      civil: "Married",
    },
    {
      id: 2,
      civil: "Single",
    },
    {
      id: 3,
      civil: "Divorced",
    },
    {
      id: 4,
      civil: "Cohabiting",
    },
  ];

  const Gender = [
    {
      id: 1,
      gender: "Male",
    },
    {
      id: 2,
      gender: "Female",
    },
    {
      id: 3,
      gender: "Other",
    },
  ];

  const onHandleParticipants = (data) => {
    console.log("object", data);
  };

  return (
    <div ref={myref} className="card-body p-3">
      <Row>
        <Col className="d-flex align-items-center" sm={10}>
          {" "}
          <div className="d-flex align-items-center">
            <H3 className=" me-2">Completed events</H3>{" "}
            <CustomToolTip size="sm" text="Completed events" />
          </div>
        </Col>
        <Col sm={2} className="d-flex justify-content-end">
          {" "}
          <Menus
            itemheight={40}
            itemwidth={40}
            itemcontainerheight={200}
            itemcontainerwidth={200}
            component={
              <div className="fs-14">
                <div className="addpropContent px-3 py-2 ">
                  <span className="ms-1" onClick={() => CSVFunction()}>
                    Download widget as CSV
                  </span>
                </div>
              </div>
            }
          />
        </Col>
      </Row>
      <Row>
        <Row>
          <Col>
            {" "}
            {/* <div style={{ height: "242px", overflow: "scroll" }}> */}
            {CompleteEventTableData.length != 0 ? (
              <MRTTheme
                // colorVariantsForTableData={["gray"]}
                // colorVariantsForTableHead="white"
                colorVariantsForTableHead="white"
                colorVariantsForTableRow={["gray"]}
                table={
                  <MaterialReactTable
                    enableStickyHeader
                    columns={CompleteEventTableColumns}
                    // data={CompleteEventTableData}
                    data={[]}
                    icons={{ ...SortIcon }}
                    localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                    enableBottomToolbar={true}
                    enableSorting={false}
                    enableColumnActions={false}
                    enablePagination={false}
                    renderTopToolbar={false}
                    muiTableContainerProps={{
                      sx: {
                        padding: "0px !important",
                        maxHeight: "262px",
                      },
                    }}
                    muiTablePaperProps={{
                      sx: {
                        table: {
                          borderCollapse: "separate",
                          borderSpacing: "0 10px",
                        },
                      },
                    }}
                    // muiTablePaperProps={{
                    //   elevation: 0,
                    //   sx: {
                    //     "& td:first-child": {
                    //       borderRadius: "8px 0 0 8px",
                    //     },
                    //     "& td:last-child": {
                    //       borderRadius: "0 8px 8px 0",
                    //     },
                    //     fontFamily: "Cerebri sans !important",
                    //     borderRadius: "0px 0px 8px 8px ",
                    //     border: "none",
                    //     background: "#F9FAFA",
                    //     fontWeight: "300",
                    //     ".css-1f2qhs8": {
                    //       minWidth: "auto",
                    //       marginRight: "10px",
                    //     },
                    //     th: {
                    //       height: "50px",
                    //       padding: "0.5rem",
                    //       background: "#FFFFFF",
                    //       paddingLeft: "1rem",
                    //       fontFamily: "Cerebri sans !important",
                    //     },
                    //     table: {
                    //       borderCollapse: "separate",
                    //       borderSpacing: "0 10px",
                    //     },
                    //     td: {
                    //       height: "50px",
                    //       paddingY: "15px !important",
                    //       fontWeight: "300",
                    //       fontSize: "13px",
                    //       paddingLeft: "1rem",
                    //       fontFamily: "Cerebri sans !important",
                    //     },
                    //     tr: {
                    //       borderRadius: "4px",
                    //       border: "2px solid red !important",
                    //       fontFamily: "Cerebri sans !important",
                    //     },
                    //     ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
                    //       display: "none", // pagination space removing from page
                    //     },
                    //   },
                    // }}
                    // muiTableBodyProps={{
                    //   sx: {
                    //     fontFamily: "Cerebri sans !important",
                    //     "& tr": {
                    //       backgroundColor: "#F9FAFA",
                    //       borderRadius: "8px",
                    //       fontWeight: "300",
                    //     },
                    //   },
                    // }}
                    // muiTableContainerProps={{
                    //   elevation: 0,
                    //   sx: {
                    //     background: "#FFFFFF",
                    //     maxHeight: "262px",
                    //     // paddingX: "22px",
                    //     // paddingY: "22px",
                    //     borderRadius: "0px 0px 8px 8px ",

                    //     boxShadow: "none",
                    //     ".css-vv34gr-MuiTableRow-root": {
                    //       boxShadow: "unset !important",
                    //     },
                    //   },
                    // }}
                    // muiTableHeadCellProps={{
                    //   //simple styling with the `sx` prop, works just like a style prop in this example
                    //   sx: {
                    //     fontWeight: "700",
                    //     fontSize: "13px",
                    //     fontFamily: "Cerebri sans !important",
                    //   },
                    // }}
                    enableRowActions
                    renderRowActions={({ row }) => (
                      <div
                        className="d-flex justify-content-center"
                        style={{ width: "140%" }}
                      >
                        <Menus
                          itemheight={40}
                          itemwidth={40}
                          iconborderradius="4px"
                          itemcontainerwidth="180px"
                          component={
                            <>
                              <div style={{ fontSize: "14px" }}>
                                <div className="addpropContent px-3 py-2 ">
                                  <span
                                    className="ms-1"
                                    onClick={() => setOpenAddInfo(true)}
                                  >
                                    Add information
                                  </span>
                                </div>
                                <hr className="addpropertyHr m-0"></hr>
                                <div className="addpropContent px-3 pt-2 pb-2 ">
                                  <span className="ms-1">Delete event</span>
                                </div>
                              </div>
                            </>
                          }
                        />
                      </div>
                    )}
                    positionActionsColumn="last"
                  />
                }
              />
            ) : (
              <NoDataScreen height={"377px"} imgHeight={"150px"} />
            )}
            {/* </div> */}
          </Col>
        </Row>
      </Row>

      <div>
        <CustomPopup
          open={openAddInfo}
          onHide={() => setOpenAddInfo(false)}
          title={<H3 className=" ps-2">Add information about event</H3>}
          title_color="black"
          titleFont="14px"
          titleTooltip={true}
          enableCrossButton={true}
          titleTooltipText="Add information about event"
          size="xl"
          onCloseAction={() => {
            setOpenAddInfo(false);
            alert("Action Peformed Successfuly.");
          }}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
        >
          <Row
            className="p-3  d-flex justify-content-center align-items-center"
            style={{ minHeight: "130px" }}
          >
            <Col
              lg={2}
              sm={12}
              className="d-flex align-items-center ps-md-2  ps-lg-3 ms-md-0 pe-0 mb-sm-2"
              style={{ lineHeight: "1.8" }}
            >
              <AddParticipants
                status="Fuglebakkevej 88"
                number="Sep 28, 2022 | 10:00 - 12:00"
              />
            </Col>
            <Col
              lg={2}
              sm={12}
              className="d-flex align-items-center ps-lg-5 ps-md-2 ms-lg-5 ms-md-0 pe-0"
            >
              <AddParticipantValue
                status="Registered"
                number={8}
                sideBorder="1px solid #000000"
              />
            </Col>

            <Col className="d-flex  align-items-center">
              <div className="cardBorderLeft"></div>
            </Col>
            <Col lg={1} sm={12} className="d-flex  align-items-center">
              <AddParticipantValue
                status="Participated"
                number={4}
                sideBorder="1px solid #000000"
              />
            </Col>

            <Col className="d-flex justify-content-center align-items-center ">
              <div className="cardBorderLeft"></div>
            </Col>
            <Col lg={2} sm={12} className="mb-sm-2">
              <AddParticipantValue
                status="No. of units reserved"
                number={1}
                sideBorder="1px solid #000000"
              />
            </Col>
            <Col>
              <div className="px-lg-5">
                <ETButton
                  variant={"primary"}
                  icon={true}
                  buttonText="ADD PARTICIPANTS"
                  type="submit"
                  onClick={() => {
                    setOpenAddParticipants(true);
                    setOpenAddInfo(false);
                  }}
                  fontSize="14px"
                  fontWeight="500"
                />
              </div>
            </Col>
          </Row>
          <hr className="mt-0" style={{ border: "1px solid #F4F5F5" }}></hr>
          <Row>
            <Col className="pb-4">
              {" "}
              {/* <div
                style={{ height: "242px", overflow: "scroll" }}
                className="ps-4"
              > */}
              <MRTTheme
                // colorVariantsForTableData={["gray"]}
                // colorVariantsForTableHead="white"

                colorVariantsForTableHead="white"
                colorVariantsForTableRow={["gray"]}
                table={
                  <MaterialReactTable
                    columns={AddInformationAboutEventColumn}
                    data={AddInformationAboutEvent}
                    icons={{ ...SortIcon }}
                    enableBottomToolbar={true}
                    enableSorting={false}
                    enableColumnActions={false}
                    enablePagination={false}
                    renderTopToolbar={false}
                    enableStickyHeader
                    // muiTablePaperProps={{
                    //   sx: {
                    //     padding: "19px !important",
                    //   }
                    // }}
                    muiTablePaperProps={{
                      sx: {
                        table: {
                          borderCollapse: "separate",
                          borderSpacing: "0 10px",
                          padding: "19px !important",
                        },
                      },
                    }}
                    // muiTablePaperProps={{
                    //   elevation: 0,
                    //   sx: {
                    //     "& td:first-child": {
                    //       borderRadius: "8px 0 0 8px",
                    //     },
                    //     "& td:last-child": {
                    //       borderRadius: "0 8px 8px 0",
                    //     },
                    //     fontFamily: "Cerebri sans !important",
                    //     borderRadius: "0px 0px 8px 8px ",
                    //     border: "none",
                    //     background: "#F9FAFA",
                    //     fontWeight: "300",
                    //     ".css-1f2qhs8": {
                    //       minWidth: "auto",
                    //       marginRight: "10px",
                    //     },
                    //     table: {
                    //       borderCollapse: "separate",
                    //       borderSpacing: "0 10px",
                    //     },
                    //     th: {
                    //       height: "50px",
                    //       padding: "0.5rem",
                    //       background: "#FFFFFF",
                    //       paddingLeft: "1rem",
                    //       fontFamily: "Cerebri sans !important",
                    //     },
                    //     td: {
                    //       height: "50px",
                    //       paddingY: "24px !important",
                    //       fontWeight: "300",
                    //       fontSize: "13px",
                    //       paddingLeft: "1rem",
                    //       fontFamily: "Cerebri sans !important",
                    //     },
                    //     tr: {
                    //       borderRadius: "4px",
                    //       PaddingY: "50px !important",
                    //       border: "2px solid red !important",
                    //       fontFamily: "Cerebri sans !important",
                    //     },
                    //     ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
                    //       display: "none", // pagination space removing from page
                    //     },
                    //   },
                    // }}
                    // muiTableBodyProps={{
                    //   sx: {
                    //     "& td:first-child": {
                    //       borderRadius: "8px 0 0 8px",
                    //     },
                    //     "& td:last-child": {
                    //       borderRadius: "0 8px 8px 0",
                    //     },
                    //     fontFamily: "Cerebri sans !important",
                    //     "& tr": {
                    //       backgroundColor: "#F9FAFA",
                    //       borderRadius: "8px",
                    //       fontWeight: "300",
                    //     },
                    //   },
                    // }}
                    // muiTableContainerProps={{
                    //   elevation: 0,
                    //   sx: {
                    //     maxHeight: "460px",
                    //     background: "#FFFFFF",
                    //     paddingX: "22px",
                    //     paddingBottom: "22px",
                    //     borderRadius: "0px 0px 8px 8px ",

                    //     boxShadow: "none",
                    //     ".css-vv34gr-MuiTableRow-root": {
                    //       boxShadow: "unset !important",
                    //     },
                    //   },
                    // }}
                    // muiTableHeadCellProps={{
                    //   //simple styling with the `sx` prop, works just like a style prop in this example
                    //   sx: {
                    //     fontWeight: "700",
                    //     fontSize: "13px",
                    //     fontFamily: "Cerebri sans !important",
                    //   },
                    // }}
                    enableRowActions
                    muiTableContainerProps={{
                      sx: {
                        padding: "0px",
                      },
                    }}
                    displayColumnDefOptions={{
                      "mrt-row-actions": {
                        header: "",
                      },
                      "mrt-row-select": {
                        header: "",
                      },
                    }}
                    renderRowActions={({ row }) => (
                      <div
                        className="d-flex justify-content-center pe-3"
                        style={{ width: "140%" }}
                      >
                        <Menus
                          itemheight={32}
                          itemwidth={32}
                          iconborderradius="4px"
                          itemcontainerwidth="180px"
                          component={
                            <>
                              <div style={{ fontSize: "14px" }}>
                                <div className="addpropContent px-3 py-2 ">
                                  <span
                                    className="ms-1"
                                    onClick={() => setOpenAddInfo(true)}
                                  >
                                    Edit
                                  </span>
                                </div>
                                <hr className="addpropertyHr m-0"></hr>
                                <div className="addpropContent px-3 pt-2 pb-2 ">
                                  <span className="ms-1">Delete</span>
                                </div>
                              </div>
                            </>
                          }
                        />
                      </div>
                    )}
                    positionActionsColumn="last"
                  />
                }
              />
              {/* </div> */}
            </Col>
          </Row>
          {/* </Row> */}
        </CustomPopup>
        <CustomPopup
          open={openAddParticipants}
          onHide={() => setOpenAddParticipants(false)}
          titleTooltip={false}
          headerRequired={false}
          size="xl"
        >
          <Form onSubmit={handleSubmit(onHandleParticipants)}>
            <div className="scrollable-container-y" style={{ height: "500px" }}>
              <div className="px-4 pt-4" style={{ backgroundColor: "#f9fafa" }}>
                <Row>
                  <Col>
                    <div className="form-label-title">Information</div>
                  </Col>
                </Row>
                <Row className="pt-4 pb-4">
                  <Col lg={3}>
                    <Controller
                      control={control}
                      {...register("fName")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div className="form-input ">
                          <input
                            type="text"
                            placeholder="First Name"
                            id="fName"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("fName");
                            }}
                          />
                          <label htmlFor="fName">First Name</label>
                        </div>
                      )}
                    />
                    {errors?.fName && (
                      <p
                        className="error-msg"
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )}
                  </Col>
                  <Col lg={3}>
                    <Controller
                      control={control}
                      {...register("lName")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div className="form-input ">
                          <input
                            type="text"
                            placeholder="name"
                            id="lName"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("lName");
                            }}
                          />
                          <label htmlFor="lName">Last Name</label>
                        </div>
                      )}
                    />
                    {errors?.lName && (
                      <p
                        className="error-msg"
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )}
                  </Col>

                  <Col></Col>
                </Row>

                <Row>
                  <Col lg={3}>
                    <Controller
                      control={control}
                      {...register("streetName")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div className="form-input ">
                          <input
                            type="text"
                            placeholder="First Name"
                            id="streetName"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("streetName");
                            }}
                          />
                          <label htmlFor="streetName">Street Name</label>
                        </div>
                      )}
                    />
                    {errors?.streetName && (
                      <p
                        className="error-msg"
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )}
                  </Col>
                  <Col lg={2}>
                    <Controller
                      control={control}
                      {...register("houseNo")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div className="form-input ">
                          <input
                            type="text"
                            placeholder="name"
                            id="houseNo"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("houseNo");
                            }}
                          />
                          <label htmlFor="houseNo">House No.</label>
                        </div>
                      )}
                    />
                    {errors?.houseNo && (
                      <p
                        className="error-msg"
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )}
                  </Col>
                  <Col lg={2}>
                    <Controller
                      control={control}
                      {...register("floor")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div className="form-input ">
                          <input
                            type="text"
                            placeholder="floor"
                            id="floor"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("floor");
                            }}
                          />
                          <label htmlFor="floor">Floor</label>
                        </div>
                      )}
                    />
                    {errors?.floor && (
                      <p
                        className="error-msg"
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )}
                  </Col>
                  <Col lg={2}>
                    <Controller
                      control={control}
                      {...register("sideFloor")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div className="form-input ">
                          <input
                            type="text"
                            placeholder="sideFloor"
                            id="sideFloor"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("sideFloor");
                            }}
                          />
                          <label htmlFor="sideFloor">Side Floor</label>
                        </div>
                      )}
                    />
                    {errors?.sideFloor && (
                      <p
                        className="error-msg"
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )}
                  </Col>
                  <Col></Col>
                </Row>

                <Row className="pt-4 pb-4">
                  <Col lg={3}>
                    <Controller
                      control={control}
                      {...register("zipCode")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div className="form-input ">
                          <input
                            type="text"
                            placeholder="ZipCode"
                            id="zipCode"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("zipCode");
                            }}
                          />
                          <label htmlFor="zipCode">ZipCode</label>
                        </div>
                      )}
                    />
                    {errors?.zipCode && (
                      <p
                        className="error-msg"
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )}
                  </Col>
                  <Col lg={3}>
                    <Controller
                      control={control}
                      {...register("city")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div className="form-input ">
                          <input
                            type="text"
                            placeholder="city"
                            id="city"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("city");
                            }}
                          />
                          <label htmlFor="city">City</label>
                        </div>
                      )}
                    />
                    {errors?.city && (
                      <p
                        className="error-msg"
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )}
                  </Col>

                  <Col>
                    <Controller
                      name=" country"
                      {...register("country")}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder="Country"
                          value={value}
                          clearable={false}
                          isSearchable
                          //   onChange={(e) => {
                          //     onChange(e);
                          //     handleZip(e);
                          //   }}
                          error={errors.zip}
                          //   options={zipcodeData?.data?.map((zip) => ({
                          //     value: zip?.Id,
                          //     label: `${zip?.Id} ${zip?.Text}`,
                          //   }))}
                        />
                      )}
                    />
                    {errors?.country && (
                      <p
                        className="error-msg"
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )}
                  </Col>
                  <Col></Col>
                </Row>

                <Row className="pb-4">
                  <Col lg={3}>
                    <Controller
                      control={control}
                      {...register("email")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div className="form-input ">
                          <input
                            type="text"
                            placeholder="Email"
                            id="email"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("email");
                            }}
                          />
                          <label htmlFor="email">Email</label>
                        </div>
                      )}
                    />
                    {errors?.email && (
                      <p
                        className="error-msg"
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )}
                  </Col>
                  <Col lg={3}>
                    <Controller
                      control={control}
                      {...register("phone")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div className="form-input ">
                          <input
                            type="text"
                            placeholder="name"
                            id="phone"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("phone");
                            }}
                          />
                          <label htmlFor="phone">Phone</label>
                        </div>
                      )}
                    />
                    {errors?.phone && (
                      <p
                        className="error-msg"
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )}
                  </Col>

                  <Col></Col>
                </Row>

                <Row>
                  <Col>
                    <Controller
                      name="DOB"
                      {...register("DOB")}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div className="form-input-group ">
                          <input
                            type="date"
                            id="targetDate"
                            placeholder="Date of Birth"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("DOB");
                            }}
                          />
                          <label htmlFor="DOB">Date of Birth</label>
                        </div>
                      )}
                    />
                    {errors?.DOB && (
                      <p
                        className="error-msg"
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )}
                  </Col>

                  <Col>
                    <Controller
                      name="currentHousingType"
                      {...register("currentHousingType")}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder="Current Housing Type"
                          value={value}
                          clearable={false}
                          isSearchable
                          onChange={(e) => {
                            onChange(e);
                          }}
                          error={errors.zip}
                          options={CurrentHousingType?.map((zip) => ({
                            value: zip?.id,
                            label: `${zip?.type}`,
                          }))}
                        />
                      )}
                    />
                    {errors?.currentHousingType && (
                      <p
                        className="error-msg"
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )}
                  </Col>
                  <Col>
                    <Controller
                      name="civilType"
                      {...register("civilType")}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder="Civil Type"
                          value={value}
                          clearable={false}
                          isSearchable
                          onChange={(e) => {
                            onChange(e);
                          }}
                          error={errors.zip}
                          options={CivilType?.map((civ) => ({
                            value: civ?.id,
                            label: `${civ?.civil}`,
                          }))}
                        />
                      )}
                    />
                    {errors?.civilType && (
                      <p
                        className="error-msg"
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )}
                  </Col>
                  <Col className="pb-4">
                    <Controller
                      name="gender"
                      {...register("gender")}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder="Gender"
                          value={value}
                          clearable={false}
                          isSearchable
                          onChange={(e) => {
                            onChange(e);
                          }}
                          error={errors.gen}
                          options={Gender?.map((gen) => ({
                            value: gen?.id,
                            label: `${gen?.gender}`,
                          }))}
                        />
                      )}
                    />
                    {errors?.gender && (
                      <p
                        className="error-msg"
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )}
                  </Col>
                </Row>
                <Row className="pb-5">
                  <Col>
                    <ETButton
                      variant={"primary"}
                      buttonText="ADD EXTRA CONTACT"
                      icon={true}
                      width={"100%"}
                    />
                  </Col>
                  <Col></Col>
                  <Col></Col>
                  <Col></Col>
                </Row>

                <Row>
                  <Col>
                    <div className="form-label-title pb-4">Preferred unit</div>
                  </Col>
                </Row>

                <Row className="pb-5">
                  <Col>
                    <Controller
                      name="preferredUnit"
                      {...register("preferredUnit")}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          // placeholder="Select Property"
                          value={value}
                          clearable={false}
                          isSearchable
                          onChange={(e) => {
                            onChange(e);
                          }}
                          error={errors.zip}
                          //   options={zipcodeData?.data?.map((zip) => ({
                          //     value: zip?.Id,
                          //     label: `${zip?.Id} ${zip?.Text}`,
                          //   }))}
                        />
                      )}
                    />
                    {errors?.preferredUnit && (
                      <p
                        className="error-msg"
                        style={{
                          color: "red",
                        }}
                      >
                        {REQUIRED_ERR}
                      </p>
                    )}
                  </Col>
                  <Col lg={9}></Col>
                </Row>
                <Row className="pb-4">
                  <Col>
                    <div className="form-label-title">
                      Heard about the property
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="p-0">
                    <div className="d-flex  align-items-center gap-1">
                      <div>
                        <Checkbox />
                      </div>
                      <div className="light-font-text">Social Media</div>
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex  align-items-center gap-1">
                      <div>
                        <Checkbox />
                      </div>
                      <div className="light-font-text">Outdoor marketing</div>
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex  align-items-center gap-1">
                      <div>
                        <Checkbox />
                      </div>
                      <div className="light-font-text">Website</div>
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex  align-items-center gap-1">
                      <div>
                        <Checkbox />
                      </div>
                      <div className="light-font-text">Newspaper</div>
                    </div>
                  </Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
                <Row className="pb-5">
                  <Col className="p-0">
                    <div className="d-flex align-items-center gap-1">
                      <div>
                        <Checkbox />
                      </div>
                      <div className="light-font-text">Instagram</div>
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex  align-items-center gap-1">
                      <div>
                        <Checkbox />
                      </div>
                      <div className="light-font-text">Facebook</div>
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex align-items-center gap-1">
                      <div>
                        <Checkbox />
                      </div>
                      <div className="light-font-text">Google Ads</div>
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex  align-items-center gap-1">
                      <div>
                        <Checkbox />
                      </div>
                      <div className="light-font-text">Newsletter</div>
                    </div>
                  </Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
              </div>
            </div>

            <div
              className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
              style={{
                backgroundColor: "#F9FAFA",
                borderRadius: " 0px 0px 8px 8px",
                border: "1px solid #F0F0F0",
              }}
            >
              <ETButton
                variant={"white"}
                buttonText="CANCEL"
                onClick={() => setOpenAddParticipants(false)}
              />
              <div className="d-flex gap-3">
                <ETButton
                  variant={"primary"}
                  buttonText="ADD TO EVENT"
                  type="submit"
                />
              </div>
            </div>
          </Form>
        </CustomPopup>
      </div>
    </div>
  );
};

export default CompleteEvents;
