import React, { useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import ETButton from "../../../../Components/Buttons/ETButton";
import { H1, H4, H3 } from "../../../../styles/Common.style";
import { GreenDiv } from "../../../../styles/Common.style";
import HighFive from "../../../../assets/animations/HighFive.lottie";
import "@dotlottie/player-component";
import { CustomerDetailsAfterFillingForm } from "../../leads/createNewLead/CreateNewlead";

const CreateNewAppoinmentConfirmation = ({
  heading,
  subHeading,
  clientName,
  eventType,
  eventData,
  createdBy,
  renderAppointmentDetails,
  renderContractDetails,
  customerData,
  propertyData,
  addressData,
  unitIdData,
  newAppointment,
  showNewAppointment,
  showContractStats,
  contractsInMonth,
  contractsInYear,
  handleClose = () => {}, // created by Suraj
  showLeadsCustomersDetail = false, //created by Suraj
  leadsCustomerDetailData = [], //created by Suraj
}) => {
  //-------------------------------------------- handle close button ---------------------------------------//
  const handleClickClose = () => {
    handleClose();
  };

  // ---------------this function used for giving overview to moving information ----------------------//
  const movingToThisOverview = (key, value) => {
    return (
      <div className="d-flex pb-1 light-font-text ">
        <div style={{ width: "80px" }}>{key}</div>
        <div className="">{value}</div>
      </div>
    );
  };

  return (
    <Container
      fluid
      className="px-0"
      style={{
        backgroundColor: "#FFFFFF",
        borderRadius: "0 0 8px 8px",
      }}
    >
      <div className="scrollable-container-y me-1 Popups-inner-scrollable-container-height">
        <Row style={{ textAlign: "center" }} className=" px-0">
          <Col md={{ span: 8, offset: 2 }}>
            <>
              <H1 className="pt-5">{heading}</H1>

              <div className="mx-4 px-lg-4 pb-3 mt-4 light-font-text">
                <p>{subHeading}</p>
              </div>
            </>

            {renderAppointmentDetails && (
              <div
                className="mt-3 mx-4 px-lg-4 py-2"
                style={{
                  backgroundColor: "#F9FAFA",
                  textAlign: "center",
                  borderRadius: "8px",
                  border: "1px solid #F4F5F5",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    zIndex: "2",
                  }}
                  className="d-flex flex-column justify-content-around align-items-center gap-3"
                >
                  <GreenDiv mediumFontWeight={true}>
                    <span className="fs-20">{clientName}</span>
                  </GreenDiv>

                  <p className="fs-14 mb-0 light-font-text">
                    <span className="grey-text">{eventType}</span> {eventData}
                  </p>

                  <p className="fs-14 mb-0 light-font-text">{createdBy}</p>
                </div>
              </div>
            )}

            {renderContractDetails && (
              <div
                className="mt-3 mx-4 px-lg-4 py-2"
                style={{
                  backgroundColor: "#F9FAFA",
                  borderRadius: "8px",
                  border: "1px solid #D1D1D1",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    zIndex: "2",
                    textAlign: "start",
                  }}
                  className="d-flex flex-column align-items-start gap-3"
                >
                  <div className="d-flex flex-column gap-2 light-font-text">
                    <GreenDiv>
                      <span className="fs-20 actors-section-title">
                        Contract details
                      </span>
                    </GreenDiv>
                    <div className="gap-1">
                      {movingToThisOverview("Customer:", customerData)}
                      {movingToThisOverview("Property:", propertyData)}
                      {movingToThisOverview("Address:", addressData)}
                      {movingToThisOverview("Unit ID:", unitIdData)}
                    </div>
                  </div>
                  {showNewAppointment && (
                    <div className="d-flex flex-column align-items-start gap-1 light-font-text">
                      <H4>New Appointment</H4>
                      <div>
                        <span className="grey-text">Private viewing -</span>{" "}
                        {newAppointment}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {showContractStats && (
              <div className="mt-4 light-font-text">
                <span className="form-label-title">Did you know?</span>
                <br />
                <div>
                  This is the{" "}
                  <span className="form-label-title">{contractsInMonth}</span>{" "}
                  time this month you create a contract for a customer and{" "}
                  <span className="form-label-title">{contractsInYear}</span>{" "}
                  time this year...
                </div>
                <br />
                <span>Well done!</span>
              </div>
            )}
            {showLeadsCustomersDetail == true && (
              <div
                className="radius-8"
                style={{
                  backgroundColor: "#F9FAFA",
                  border: "1px solid #D1D1D1",
                }}
              >
                <CustomerDetailsAfterFillingForm
                  data={leadsCustomerDetailData}
                />
              </div>
            )}
            {/* //........................Image Part ......................................// */}

            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                position: "relative",
                height: "300px",
              }}
            >
              <div style={{ position: "absolute", bottom: "0" }}>
                <dotlottie-player
                  src={HighFive}
                  autoplay
                  loop
                  style={{ height: "500px", width: "100%" }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* ------------------------ footer with button ----------------------------------- */}
      <div className="py-2 px-3 d-flex flex-row justify-content-end footer-section-button-container">
        <ETButton
          variant={"primary"}
          buttonText="Close"
          type={"button"}
          onClick={handleClickClose}
        />
      </div>
    </Container>
  );
};

CreateNewAppoinmentConfirmation.defaultProps = {
  heading: "The appointment is now created",
  subHeading:
    "The appointment with the customer has now been created in the system and a confirmation has been sent to the customer by email. The appointment can be found under Events and in My Activity.",
  clientName: "Thomas Høgh Mogensen",
  eventType: "Appointed -",
  eventData: "Thomas Høeg Mogensen - Dybbølsbro 3, 4 I 7-N340-4 I 212-076",
  createdBy: "Created Sep 1, 2020 by Max Wildersans Brentford",
};

export default CreateNewAppoinmentConfirmation;

// changes done by Suraj -- added footer section with button and function created for that
