import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../../../Components/Buttons/ETButton";
import moment from "moment";
import CustomPopup from "../../../../Components/CustomPopup";
import Menus from "../../../../Components/Menus/Menus";
import { useQuery } from "react-query";
import { GetDocumentPackagesByPropertyId } from "../../../../services/Documents";
import { toast } from "react-toastify";
import {
  useCancelSigningOrder,
  useGetSigningOrderByContractId,
  usePostCreateSigningOrder,
} from "../../../../hooks/queries/useSigningQueries";
import { usePropertyOverview } from "../../../../hooks/stores/usePropertyStore";
import { useSigningOrderStore } from "../../../../hooks/stores/useSigningStore";
import { useUnitId } from "../../../../hooks/stores/useUnitStore";
import { StyledCard } from "../../../../styles/Common.style";
import { useBreadcrumb } from "../../../../Components/BreadcrumbContext ";
import ResidentialSigningPackage from "./CreateSigningOrderPopups/ResidentialSigningPackage";
import ParkingSigningPackage from "./CreateSigningOrderPopups/ParkingSigningPackage";
import StorageSigningPackage from "./CreateSigningOrderPopups/StorageSigningPackage";

const Signing = ({
  contractId,
  contractOverViewData,
  initialSelectedName,
  tanentNameTab,
  selectedName,
  contractOverViewDataRefetch,
}) => {
  //---------------------Zustand----------------------//
  const propOverview = usePropertyOverview((state) => state.propOverview);
  const { UnitId } = useUnitId((state) => state);
  const { isViewSigningOrder } = useSigningOrderStore((state) => state);

  //----------------------States----------------------//
  const [selectedSigningPackageId, setSelectedSigningPackageId] = useState({
    residential_package_id: "",
    parking_package_id: "",
    storage_package_id: "",
  });
  const [isActiveSigningPopupOpen, setIsActiveSigningPopupOpen] =
    useState(false);
  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    updateBreadcrumbs(["Contracts", initialSelectedName, "Signing"]);
  }, [initialSelectedName]);
  const [signingOrderWizardPage, setSigningOrderWizardPage] = useState({
    isCreateSigningOrderPopupOpen: false,
    signingOrderWizardPage: "",
  });

  useEffect(() => {
    if (tanentNameTab) {
      updateBreadcrumbs(["Contracts", selectedName, "Signing"]);
    } else {
    }
  }, [selectedName]);
  //---------------------React query------------------//
  const {
    data: getSigningOrderByContractIdData,
    refetch: getSigningOrderByContractIdRefetch,
  } = useGetSigningOrderByContractId(contractId);

  // Refetch is called only when view signing order page is closed
  useEffect(() => {
    getSigningOrderByContractIdRefetch();
  }, [isViewSigningOrder?.showSigningPage]);

  const {
    data: getDocumentPackagesByPropertyIdData,
    refetch: getDocumentPackagesByPropertyIdDataRefetch,
  } = useQuery(
    ["getDocumentPackageByPropertyId", propOverview?.[0]?.property_id, 2],
    () =>
      GetDocumentPackagesByPropertyId(
        propOverview?.[0]?.property_id,
        UnitId,
        2,
        0
      ),
    {
      enabled: !!signingOrderWizardPage?.isCreateSigningOrderPopupOpen,
    }
  );
  const {
    mutateAsync: PostCreateSigningOrder,
    isLoading: PostCreateSigningOrderIsLoading,
  } = usePostCreateSigningOrder();

  //-------------------Create signing order----------------------//
  const handleCreateSigningOrder = () => {
    if (
      (contractOverViewData?.data?.[0]?.pending_orders?.[0]?.is_residential ===
        1 &&
        selectedSigningPackageId?.residential_package_id !== "") ||
      selectedSigningPackageId?.parking_package_id !== "" ||
      selectedSigningPackageId?.storage_package_id !== ""
    ) {
      const createSigningOrderPayload = {
        property_id: String(propOverview?.[0]?.property_id),
        contract_id: String(contractId),
        document_package_id: Object?.values(selectedSigningPackageId)
          ?.map((value) => (value === "" ? "" : value))
          ?.filter((value) => value !== "")
          ?.join(","),
      };
      let id;
      if (!PostCreateSigningOrderIsLoading) {
        id = toast.loading("Creating signing order");
      }
      PostCreateSigningOrder(createSigningOrderPayload)
        .then((response) => {
          if (response.data.bReturn) {
            toast.update(id, {
              render: response?.data?.message,
              isLoading: false,
              type: "success",
              autoClose: 2000,
            });
            getSigningOrderByContractIdRefetch();
            setSigningOrderWizardPage((prev) => ({
              ...prev,
              isCreateSigningOrderPopupOpen: false,
            }));
            setSelectedSigningPackageId((prev) => ({
              ...prev,
              residential_package_id: "",
              parking_package_id: "",
              storage_package_id: "",
            }));
            contractOverViewDataRefetch();
          } else {
            toast.update(id, {
              render: response?.data?.message,
              isLoading: false,
              type: "error",
              autoClose: 2000,
            });
          }
        })
        .catch((error) => {
          toast.update(id, {
            render: "Failed to create signing order",
            isLoading: false,
            type: "error",
            autoClose: 2000,
          });
        });
    } else {
      toast.error("Select signing package");
    }
  };

  //---------------------------handle next ------------------//
  const handleSigningOrderWizardNext = () => {
    if (
      signingOrderWizardPage?.signingOrderWizardPage ==
        "ResidentialSigningPackage" &&
      contractOverViewData?.data?.[0]?.pending_orders?.[0]?.is_parking == 1
    ) {
      setSigningOrderWizardPage((prev) => ({
        ...prev,
        signingOrderWizardPage: "ParkingSigningPackage",
      }));
    } else if (
      signingOrderWizardPage?.signingOrderWizardPage ==
        "ResidentialSigningPackage" &&
      contractOverViewData?.data?.[0]?.pending_orders?.[0]?.is_storage == 1
    ) {
      setSigningOrderWizardPage((prev) => ({
        ...prev,
        signingOrderWizardPage: "StorageSigningPackage",
      }));
    } else if (
      signingOrderWizardPage?.signingOrderWizardPage ==
        "ParkingSigningPackage" &&
      contractOverViewData?.data?.[0]?.pending_orders?.[0]?.is_storage == 1
    ) {
      setSigningOrderWizardPage((prev) => ({
        ...prev,
        signingOrderWizardPage: "StorageSigningPackage",
      }));
    } else {
      handleCreateSigningOrder();
    }
  };
  //---------------------------handle previous ------------------//
  const handleSigningOrderWizardPrevious = () => {
    if (
      signingOrderWizardPage?.signingOrderWizardPage ==
        "StorageSigningPackage" &&
      contractOverViewData?.data?.[0]?.pending_orders?.[0]?.is_parking == 1
    ) {
      setSigningOrderWizardPage((prev) => ({
        ...prev,
        signingOrderWizardPage: "ParkingSigningPackage",
      }));
    } else if (
      signingOrderWizardPage?.signingOrderWizardPage ==
        "ParkingSigningPackage" &&
      contractOverViewData?.data?.[0]?.pending_orders?.[0]?.is_residential == 1
    ) {
      setSigningOrderWizardPage((prev) => ({
        ...prev,
        signingOrderWizardPage: "ResidentialSigningPackage",
      }));
    }
  };

  //Check whether any signing order is Active before creating new signing order
  const handleNewSigningOrderPopup = () => {
    if (
      contractOverViewData?.data?.[0]?.pending_orders?.[0]?.is_residential ==
        0 &&
      contractOverViewData?.data?.[0]?.pending_orders?.[0]?.is_parking == 0 &&
      contractOverViewData?.data?.[0]?.pending_orders?.[0]?.is_storage == 0
    ) {
      setIsActiveSigningPopupOpen(true);
    } else {
      if (
        contractOverViewData?.data?.[0]?.pending_orders?.[0]?.is_residential ==
        1
      ) {
        setSigningOrderWizardPage((prev) => ({
          ...prev,
          isCreateSigningOrderPopupOpen: true,
          signingOrderWizardPage: "ResidentialSigningPackage",
        }));
      } else if (
        contractOverViewData?.data?.[0]?.pending_orders?.[0]?.is_parking == 1
      ) {
        setSigningOrderWizardPage((prev) => ({
          ...prev,
          isCreateSigningOrderPopupOpen: true,
          signingOrderWizardPage: "ParkingSigningPackage",
        }));
      } else if (
        contractOverViewData?.data?.[0]?.pending_orders?.[0]?.is_storage == 1
      ) {
        setSigningOrderWizardPage((prev) => ({
          ...prev,
          isCreateSigningOrderPopupOpen: true,
          signingOrderWizardPage: "StorageSigningPackage",
        }));
      }
    }
  };

  //---------------Wizard for signing order flow-------------//
  const CreateSigningOrderWizard = {
    ResidentialSigningPackage: (
      <ResidentialSigningPackage
        setSelectedSigningPackageId={setSelectedSigningPackageId}
        selectedSigningPackageId={selectedSigningPackageId}
        signingPackageData={getDocumentPackagesByPropertyIdData?.data?.filter(
          (val) => val?.signing_package_type == 1
        )}
      />
    ),
    ParkingSigningPackage: (
      <ParkingSigningPackage
        setSelectedSigningPackageId={setSelectedSigningPackageId}
        selectedSigningPackageId={selectedSigningPackageId}
        signingPackageData={getDocumentPackagesByPropertyIdData?.data?.filter(
          (val) => val?.signing_package_type == 2
        )}
      />
    ),
    StorageSigningPackage: (
      <StorageSigningPackage
        setSelectedSigningPackageId={setSelectedSigningPackageId}
        selectedSigningPackageId={selectedSigningPackageId}
        signingPackageData={getDocumentPackagesByPropertyIdData?.data?.filter(
          (val) => val?.signing_package_type == 3
        )}
      />
    ),
  };

  return (
    <div>
      <Row className="pt-4 pb-4 px-4">
        {/* signing order card component */}
        {getSigningOrderByContractIdData?.data?.map((item, index) => (
          <Col key={index} className="mb-4" lg={6} xl={4}>
            <SigningOrderCard
              data={item}
              index={index}
              getSigningOrderByContractIdRefetch={
                getSigningOrderByContractIdRefetch
              }
              contractOverViewDataRefetch={contractOverViewDataRefetch}
            />
          </Col>
        ))}
        {/* {contractOverViewData?.data?.[0]?.status_name === "Reserved" && ( */}
        <Col lg={6} xl={4} className="mb-4">
          <StyledCard height="21rem">
            <ETButton
              variant={"primary"}
              buttonText="CREATE NEW SIGNING ORDER"
              icon={true}
              width="fit-content"
              onClick={() => {
                contractOverViewData?.data?.[0]?.status_name !== "Reserved"
                  ? toast.info(
                      `Cannot create signing order as contract status is ${contractOverViewData?.data?.[0]?.status_name}`
                    )
                  : handleNewSigningOrderPopup();
                // setViewSigningPackage(true);  // used prop drilling for showing <AddDocumentPackages /> component outside of document layout.
              }}
            />
          </StyledCard>
        </Col>
        {/* )} */}
      </Row>

      {/* CREATE NEW SIGNING ORDER popup */}
      <CustomPopup
        open={signingOrderWizardPage?.isCreateSigningOrderPopupOpen}
        onHide={() => {
          setSigningOrderWizardPage((prev) => ({
            ...prev,
            isCreateSigningOrderPopupOpen: false,
          }));
          setSelectedSigningPackageId((prev) => ({
            ...prev,
            residential_package_id: "",
            parking_package_id: "",
            storage_package_id: "",
          }));
        }}
        title="Create new signing order"
        size="xl"
        title_color="black"
        enableCrossButton={true}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        titleTooltipText={"Create new signing order"}
      >
        <div>
          {
            CreateSigningOrderWizard[
              signingOrderWizardPage?.signingOrderWizardPage
            ]
          }
          <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-end  justify-content-end footer-section-button-container gap-3">
            {/* <ETButton
              disabled={
                selectedSigningPackageId === "" ||
                PostCreateSigningOrderIsLoading
              }
              variant={"primary"}
              buttonText="CREATE SIGNING ORDER"
              onClick={() => handleCreateSigningOrder()}
            /> */}

            <div
              style={{
                display:
                  signingOrderWizardPage?.signingOrderWizardPage ==
                  "ResidentialSigningPackage"
                    ? "none"
                    : signingOrderWizardPage?.signingOrderWizardPage ==
                        "ParkingSigningPackage" &&
                      contractOverViewData?.data?.[0]?.pending_orders?.[0]
                        ?.is_residential == 0
                    ? "none"
                    : signingOrderWizardPage?.signingOrderWizardPage ==
                        "StorageSigningPackage" &&
                      contractOverViewData?.data?.[0]?.pending_orders?.[0]
                        ?.is_parking == 0
                    ? "none"
                    : "",
              }}
            >
              <ETButton
                disabled={
                  signingOrderWizardPage?.signingOrderWizardPage ==
                    "StorageSigningPackage" &&
                  contractOverViewData?.data?.[0]?.pending_orders?.[0]
                    ?.is_parking == 1
                    ? false
                    : signingOrderWizardPage?.signingOrderWizardPage ==
                        "ParkingSigningPackage" &&
                      contractOverViewData?.data?.[0]?.pending_orders?.[0]
                        ?.is_residential == 1
                    ? false
                    : true
                }
                variant={"white"}
                buttonText="PREVIOUS"
                onClick={() => handleSigningOrderWizardPrevious()}
              />
            </div>
            <ETButton
              // disabled={
              //   signingOrderWizardPage?.signingOrderWizardPage ==
              //     "ResidentialSigningPackage" &&
              //   selectedSigningPackageId?.residential_package_id == ""
              //     ? true
              //     : signingOrderWizardPage?.signingOrderWizardPage ==
              //         "ParkingSigningPackage" &&
              //       selectedSigningPackageId?.parking_package_id == ""
              //     ? true
              //     : signingOrderWizardPage?.signingOrderWizardPage ==
              //         "StorageSigningPackage" &&
              //       selectedSigningPackageId?.storage_package_id == ""
              //     ? true
              //     : PostCreateSigningOrderIsLoading
              //     ? true
              //     : false
              // }
              disabled={
                signingOrderWizardPage?.signingOrderWizardPage ==
                  "ResidentialSigningPackage" &&
                selectedSigningPackageId?.residential_package_id == ""
                  ? true
                  : // : signingOrderWizardPage?.signingOrderWizardPage ==
                  //     "ParkingSigningPackage" &&
                  //   selectedSigningPackageId?.parking_package_id == ""
                  // ? true
                  // : signingOrderWizardPage?.signingOrderWizardPage ==
                  //     "StorageSigningPackage" &&
                  //   selectedSigningPackageId?.storage_package_id == ""
                  // ? true
                  PostCreateSigningOrderIsLoading
                  ? true
                  : false
              }
              variant={"primary"}
              buttonText={
                signingOrderWizardPage?.signingOrderWizardPage ==
                  "ResidentialSigningPackage" &&
                contractOverViewData?.data?.[0]?.pending_orders?.[0]
                  ?.is_parking == 1
                  ? "NEXT"
                  : signingOrderWizardPage?.signingOrderWizardPage ==
                      "ResidentialSigningPackage" &&
                    contractOverViewData?.data?.[0]?.pending_orders?.[0]
                      ?.is_storage == 1
                  ? "NEXT"
                  : signingOrderWizardPage?.signingOrderWizardPage ==
                      "ParkingSigningPackage" &&
                    contractOverViewData?.data?.[0]?.pending_orders?.[0]
                      ?.is_storage == 1
                  ? "NEXT"
                  : "CREATE SIGNING ORDER"
              }
              onClick={() => handleSigningOrderWizardNext()}
            />
          </div>
        </div>
      </CustomPopup>

      {/* Active signing order popup */}
      <CustomPopup
        open={isActiveSigningPopupOpen}
        onHide={() => setIsActiveSigningPopupOpen(false)}
        title="Signing order"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="p-3">
          <div className="px-2">
            <div className="fw-300 fs-14 my-1">
              {/* <p>You have one signing order active.</p> */}
              <p>You have active signing order</p>
              <p>Please cancel the active signing order to create a new one.</p>
            </div>
          </div>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-end  justify-content-center footer-section-button-container">
          {/* <ETButton
                    variant = {"white"}
            buttonText="COLD FEET"
            onClick={() => {
              setIsActiveSigningPopupOpen(false);
            }}
          /> */}
          <ETButton
            variant={"primary"}
            buttonText="OKAY"
            onClick={() => {
              setIsActiveSigningPopupOpen(false);
            }}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default Signing;

const TruncatedSpan = ({ text, maxLength }) => {
  const truncatedText =
    text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  return (
    <span className="light-font-text fs-14 text-break">{truncatedText}</span>
  );
};

const SigningOrderCard = ({
  data,
  index,
  getSigningOrderByContractIdRefetch,
  contractOverViewDataRefetch,
}) => {
  //--------------------States-------------------------//
  const [openCancelOrderPopup, setOpenCancelOrderPopup] = useState(false);

  //-------------------Zustand-------------------------//
  const { setViewSigningOrder, setSigningOrderId } = useSigningOrderStore(
    (state) => state
  );

  //----------------------react query----------------------------//
  const {
    mutateAsync: PostCancelSigningOrder,
    isLoading: PostCancelSigningOrderIsLoading,
  } = useCancelSigningOrder();

  //-------------------Cancel signing order----------------------//

  const handleCancelSigningOrder = (signingOrderId) => {
    const cancelSigningOrderPayload = {
      signing_order_id: String(signingOrderId),
    };
    PostCancelSigningOrder(cancelSigningOrderPayload)
      .then((response) => {
        if (response.data.bReturn) {
          toast.success(response.data.message);
          getSigningOrderByContractIdRefetch();
          contractOverViewDataRefetch();
          setOpenCancelOrderPopup(false);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => toast.error(error.message));
  };

  const maxLength = 28; // Maximum length before truncating
  return (
    <>
      <div
        className="actor-signing-card radius-8 align-items-center"
        // style={{
        //   border: `1px solid ${data?.is_active == 1 ? "#619130" : "#F0F0F0"}`,
        // }}
        style={{
          // border: "1px solid #F0F0F0",
          border: `1px solid ${data?.is_active == 1 ? "#619130" : "#F0F0F0"}`,
          opacity: data?.is_active !== 1 ? "60%" : "inherit",
        }}
      >
        {/* <div
          className="p-3 text-break light-font-text"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div
            className="bg-white radius-6 pe-1 "
            style={{ border: "1px solid #F0F0F0" }}
          >
            <div
              className=" p-3 radius-6 scrollable-container-y light-font-text"
              style={{ height: "140px", overflowY: "scroll" }}
            >
              {data?.documents?.split(",")?.map(
                (item, index) =>
                  index < 4 && (
                    <div className="d-flex gap-2 fs-13">
                      <span style={{ width: "5%" }}>
                        <FontAwesomeIcon
                          icon="fa-regular fa-file-pdf"
                          style={{ color: "#000000" }}
                        />
                      </span>
                      <span style={{ width: "95%" }} className=" mb-0 ellipsis">
                        {item}
                      </span>
                    </div>
                  )
              )}

              {data?.documents?.split(",")?.length > 4 && (
                <div>
                  <span className="form-label-title text-dark">
                    +{data?.documents?.split(",")?.length - 4}{" "}
                    {data?.documents?.split(",")?.length - 4 === 1
                      ? "document"
                      : "documents"}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div> */}

        <div
          className="p-3 text-break"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div className="fs-16 form-label-title">{data?.order_name}</div>
        </div>

        <div className="p-3" style={{ position: "relative" }}>
          <div className="mb-2">
            <p className="mb-0 form-label-title"> Package title</p>
            <span className="light-font-text fs-14 text-break">
              {/* {data?.package_name || "-"} */}
              <TruncatedSpan
                text={data?.package_name || "-"}
                maxLength={maxLength}
              />
            </span>
          </div>
          <div className="mb-2">
            <p className="mb-0 form-label-title">Signing order type</p>
            <span className="light-font-text fs-14 text-break">
              {data?.signing_order_type_id === 1
                ? "Residential"
                : data?.signing_order_type_id === 2
                ? "Parking lot"
                : data?.signing_order_type_id === 3
                ? "Storage room"
                : data?.signing_order_type_id === 4 && "Mix"}
            </span>
          </div>

          <div className="mb-2  " style={{ width: "80%" }}>
            <p className="mb-0 form-label-title">Tag</p>
            <div className="light-font-text fs-14 text-break text-truncate">
              {data?.source?.split(",")?.join(", ") ||
                data?.source?.split(",")?.join(", ") ||
                "-"}
            </div>
          </div>

          <div className="mb-2">
            <p className="mb-0 fs-12 form-label-title">Created by</p>
            {/* <span className="">{data?.created_by}</span> */}
            <div className="created-by fs-12 text-break">
              {data?.created_by
                ? data?.created_by +
                  ", " +
                  moment(data?.created_on).format("DD. MMM. YYYY | HH:mm:ss")
                : "-"}
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div className="mb-2">
              <p className="mb-0 fs-12 form-label-title ">Updated by</p>
              {/* <span className="fw-300 fs-13 text-break">
                {moment(data?.created_on).format("DD MMM, YYYY [at] HH:mm")}
              </span> */}

              <div className="created-by fs-12 text-break">
                {/* {data?.modified_by
                  ? data?.modified_by +
                    ", " +
                    moment(data?.modified_on).format("DD. MMM. YYYY | HH:mm:ss")
                  : "-"} */}
                <TruncatedSpan
                  text={
                    data?.modified_by
                      ? data?.modified_by +
                        ", " +
                        moment(data?.modified_on).format(
                          "DD. MMM. YYYY | HH:mm:ss"
                        )
                      : "-"
                  }
                  maxLength={maxLength}
                />
              </div>
            </div>

            <div className="d-flex align-items-center">
              <Menus
                itemheight={24}
                itemwidth={24}
                iconborderradius="4px"
                component={
                  <div className="light-font-text">
                    <div
                      onClick={() => {
                        setViewSigningOrder({
                          showSigningPage: true,
                          signingOrderData: data,
                        });
                        setSigningOrderId(data?.signing_order_id);
                      }}
                      className="addpropContent px-3 py-2 "
                    >
                      <span className="ms-1">View</span>
                    </div>
                    <hr className=" m-0"></hr>
                    {data?.is_active === 1 ? (
                      <div
                        onClick={() => setOpenCancelOrderPopup(true)}
                        className="addpropContent px-3 pt-2 pb-2 "
                      >
                        <span className="ms-1">Cancel</span>
                      </div>
                    ) : (
                      <div
                        className="light-font-text addpropContent px-3 pt-2 pb-2"
                        style={{ opacity: "50%", cursor: "not-allowed" }}
                      >
                        <span className="ms-1">Cancel</span>
                      </div>
                    )}
                  </div>
                }
              />
            </div>
          </div>
        </div>
        {/* <div className="px-3">

            <ETButton
              variant={"primary"}
              buttonText="VIEW SIGNING ORDER"
              icon={false}
              width="100%"
              onClick={() => {
                setViewSigningOrder(true);
                setSigningOrderId(data?.signing_order_id);
              }}
            />
          </div> */}
      </div>
      {/* ------------------Cancel order popup -------------------*/}
      <CustomPopup
        open={openCancelOrderPopup}
        onHide={() => setOpenCancelOrderPopup(false)}
        title="Cancel signing order"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
        size={"md"}
      >
        <div className="p-3">
          <div className="px-2">
            <div className="light-font-text my-1" style={{ height: "100px" }}>
              Are you sure you want to cancel this signing order?
            </div>
          </div>
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => {
              setOpenCancelOrderPopup(false);
            }}
          />
          <ETButton
            disabled={PostCancelSigningOrderIsLoading}
            variant={"primary"}
            buttonText="I'M SURE"
            onClick={() => handleCancelSigningOrder(data?.signing_order_id)}
          />
        </div>
      </CustomPopup>
    </>
  );
};
