import React, { useMemo, useState } from "react";
import ETButton from "../../../../../../../../../Components/Buttons/ETButton";
import MRTTheme from "../../../../../../../../../Components/table/MRTTheme";
import {
  MRT_GlobalFilterTextField,
  MaterialReactTable,
} from "material-react-table";
import Menus from "../../../../../../../../../Components/Menus/Menus";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../../../../../../../Components/table/MRTGlobalProps";

import { attachedDocuments } from "../page/data";
import { Col, Row } from "react-bootstrap";
import { CustomersTab } from "../../../../../../../../../Components/Tabs/CustomersTabs.style";
import { NoRecordsToDisplay } from "../../../../../../../../../utils/validationConstants";

const AttachedDocuments = ({
  setShow,
  show,
  setStepWizard,
  progress,
  setProgress,
}) => {
  const [customersTabs, setCustomersTabs] = useState(0);
  const attachedDocumentsTabs = [
    {
      id: 0,
      text: "All",
    },
    {
      id: 1,
      text: "From company",
    },
    {
      id: 2,
      text: "From property",
    },
    {
      id: 3,
      text: "From unit",
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: "file_name",
        header: "File name",
        enableHiding: false,
        size: 300,
        enableGlobalFilter: true,
      },
      {
        accessorKey: "file_type",
        header: "File type",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: true,
      },
      {
        accessorKey: "type",
        header: "Type",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "date_of_upload",
        header: "Date of upload",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "uploaded_by",
        header: "Uploaded by",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "source",
        header: "Source",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
      },
    ],
    []
  );
  const handleSubmit = () => {
    setStepWizard("InvitationSend");
    setProgress(95);
  };
  const handleBack = () => {
    setStepWizard("ExistingEvent");
    setProgress(30);
  };
  return (
    <div>
      <div className="Popups-inner-scrollable-container-height scrollable-container-y">
        <div className="px-4 pt-4  ">
          <div className="form-label-title  d-flex gap-2">
            Attached documents
          </div>
          <div className="light-font-text">
            If you want to add documents to the invitation, you can select them
            below. The documents will be sent together with the invitation to
            the customer and can be downloaded via a link.
          </div>
        </div>
        <div
          className="d-flex gap-2 px-4 py-3 my-4"
          style={{
            borderTop: "1px solid #F0F0F0",
            borderBottom: "1px solid #F0F0F0",
          }}
        >
          {attachedDocumentsTabs?.map((item, i) => (
            <>
              <CustomersTab
                key={i}
                style={{ position: "relative", width: "auto" }}
                mode={i == customersTabs}
                className="mb-1"
                // className={
                //   i == customersTabs
                //     ? "px-2 customers-tabs-active mb-1"
                //     : "customers-tabs px-2 mb-1"
                // }
                onClick={() => {
                  // handleClickTab();
                  setCustomersTabs(i);
                  // setTenancyId(item?.tenancy_id);
                  // handleClick(item.name);
                }}
              >
                <span style={{ position: "relative" }}>{item?.text}</span>
              </CustomersTab>
            </>
          ))}
        </div>
        <div className="px-4 pb-5">
          <MRTTheme
            colorVariantsForTableRow={["gray"]}
            colorVariantsForTableHead={["white"]}
            table={
              <MaterialReactTable
                columns={columns}
                // data={attachedDocuments} //data is undefined on first render
                data={[]}
                icons={{ ...SortIcon }}
                localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                enableColumnActions={false}
                enableTopToolbar={true}
                enableSelectAll={false}
                enableRowActions={false}
                enableRowSelection
                globalFilterFn="contains"
                initialState={{
                  showGlobalFilter: true,
                  showColumnFilters: false,
                }}
                muiSearchTextFieldProps={{
                  ...SearchStyle,

                  InputProps: {
                    ...SearchIcon,
                  },
                }}
                muiTableContainerProps={{
                  sx: {
                    padding: "0px !important",
                  },
                }}
                displayColumnDefOptions={{
                  "mrt-row-select": {
                    header: "",
                  },
                  "mrt-row-actions": {
                    header: "",
                  },
                }}
                muiTablePaperProps={{
                  elevation: 0,
                  fontFamily: "Cerebri sans-light !important",
                  sx: {
                    borderRadius: "0",
                    border: "none",
                    // backgroundColor: "#F9FAFA",
                    table: {
                      borderCollapse: "separate",
                      borderSpacing: "0 5px",
                    },
                  },
                }}
                muiTableBodyRowProps={{
                  selected: false,
                }}
                muiTablePaginationProps={{
                  ...paginationoptions,
                }}
                muiTableHeadRowProps={{
                  sx: {
                    ...TableRowsStyle,
                  },
                }}
                renderTopToolbar={({ table }) => (
                  <>
                    <Row>
                      <Col lg={5}>
                        <MRT_GlobalFilterTextField table={table} />
                      </Col>
                    </Row>
                  </>
                )}
                // muiTableBodyProps={{
                //   sx: {
                //     //stripe the rows, make odd rows a darker color
                //     "& tr:nth-of-type(odd)": {
                //       backgroundColor: "#F9FAFA",
                //       borderRadius: "8px",
                //     },
                //   },
                // }}
                // muiTableHeadCellProps={{
                //   //simple styling with the `sx` prop, works just like a style prop in this example
                //   sx: {
                //     fill: "#d1d1d1",
                //     fontSize: "13px",
                //     fontWeight: 500,
                //     fontFamily: "Cerebri sans-medium !important",
                //     "& .Mui-checked": { color: "#619130 !important" },
                //     "& .MuiCheckbox-indeterminate": {
                //       color: "#619130 !important",
                //     },
                //   },
                // }}
                // muiSelectAllCheckboxProps={{
                //   indeterminate: false,
                //   icon: <UnCheckedbox />,
                //   checkedIcon: <Checkedbox />,
                //   sx: {
                //     outline: "none !importants",
                //     borderRadius: 1,

                //     color: "#d1d1d1",
                //     "& .Mui-checked": { color: "#619130 !important" },
                //     "& .MuiCheckbox-indeterminate": {
                //       color: "#619130 !important",
                //     },
                //   },
                // }}
                // muiSelectCheckboxProps={{
                //   icon: <UnCheckedbox />,
                //   checkedIcon: <Checkedbox />,

                //   color: "success",
                // }}
              />
            }
          />
        </div>
      </div>
      <div className="py-2 px-3 d-flex flex-row justify-content-between  footer-section-button-container">
        <div className="d-flex gap-2">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setShow(false)}
          />
          <ETButton variant={"white"} buttonText="BACK" onClick={handleBack} />
        </div>

        <ETButton
          variant={"primary"}
          buttonText="NEXT"
          type={"submit"}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default AttachedDocuments;

// this page created by Suraj
