import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ETButton from "../../../Components/Buttons/ETButton";
import CustomPopup from "../../../Components/CustomPopup";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { useState } from "react";

import moment from "moment";

const FollowUpEventPopup = ({
  setOpenFollowUpEvent,
  openFollowUpEvent,
  setShowOpenHousePropertyFollowUp,
  setSelectedOptionTwo,
  setShowModal,
  GetEventsDetailByEventId,
}) => {
  //------------------- state --------------------------//
  const EditEventsOpt = [
    {
      value: 1,
      label: "Edit this specific event",
    },
    {
      value: 2,
      label: "Edit this series of event",
    },
  ];

  const DeleteEventsOpt = [
    {
      value: 1,
      label: "Delete this specific event",
    },
    {
      value: 2,
      label: "Delete this series of event",
    },
  ];

  const [selectedOption, setSelectedOption] = useState("");

  const startTime = moment(
    GetEventsDetailByEventId?.data?.[0]?.time_start,
    "HH:mm:ss"
  ).format("HH:mm");
  const endTime = moment(
    GetEventsDetailByEventId?.data?.[0]?.time_end,
    "HH:mm:ss"
  ).format("HH:mm");

  const startTimeMoment = moment(startTime, "HH:mm");
  const endTimeMoment = moment(endTime, "HH:mm");

  const duration = moment.duration(endTimeMoment.diff(startTimeMoment));
  const hours = Math.floor(duration.asHours());
  const minutes = duration?.minutes();

  const formattedTimeDifference = `${hours} hour${
    //  format the time 1 hour,20 minute
    hours > 1 ? "s" : ""
  }, ${minutes} minute${minutes > 1 ? "s" : ""}`;

  const eventDate = moment(
    GetEventsDetailByEventId?.data?.[0]?.event_date,
    "YYYY-MM-DD"
  ).format("Do MMMM YYYY");

  const eventDay = moment(eventDate).format("dddd");

  const handleSelectChange = (selected) => {
    setSelectedOption(selected);

    if (selected?.value === 1) {
      setShowModal(true);
      setOpenFollowUpEvent(false);
    } else if (selected?.value === 2) {
      setShowModal(true);
      setOpenFollowUpEvent(false);
      setSelectedOptionTwo(true);
    }
    setSelectedOption("");
  };

  return (
    <div>
      {" "}
      <CustomPopup
        headerRequired={false}
        size={"md"}
        open={openFollowUpEvent}
        onHide={() => setOpenFollowUpEvent(false)}
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
      >
        <div style={{ borderBottom: "1px solid #F0F0F0" }} className="p-3">
          <div className="d-flex gap-2 align-items-start">
            <div
              className="radius-4 "
              style={{
                width: "17px",
                height: "17px",
                background: "#CCE5F6",
                marginTop: "2px",
              }}
            ></div>
            <div className="d-flex flex-column ms-1 ">
              <div className="header-semibold fs-16">
                Open House Blomsterbjerget
              </div>
              <div className="light-font-text fs-12">
                Open House on Property
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ height: "420px", overflowY: "scroll" }}
          className="scrollable-container-y"
        >
          <div style={{ borderBottom: "1px solid #F0F0F0" }} className="p-3">
            <div className="d-flex gap-2 align-items-start">
              <FontAwesomeIcon
                icon="fa-regular fa-user"
                style={{ color: "#000000", marginTop: "2px" }}
              />
              <div className="d-flex flex-column ms-1 ">
                <div className="header-semibold fs-16">Agent</div>
                <div className="light-font-text fs-12">
                  {GetEventsDetailByEventId?.data?.[0]?.agent_name}
                </div>
              </div>
            </div>
          </div>

          <div style={{ borderBottom: "1px solid #F0F0F0" }} className="p-3">
            <div className="d-flex gap-2 align-items-start">
              <FontAwesomeIcon
                icon="fa-regular fa-clock"
                style={{ color: "#000000", marginTop: "2px" }}
              />

              <div className="d-flex flex-column ms-1 ">
                <div className="header-semibold fs-16 ">Date and time</div>
                <div className="actors-lite-text mt-2">
                  {/* Sunday 19th February 2023 */}
                  {eventDay} {"  " + eventDate}
                </div>
                {/* <div className="actors-lite-text">11:00 to 12:00 (1 hours)</div> */}
                <div className="actors-lite-text">
                  {startTime} to {endTime} ({formattedTimeDifference})
                </div>
              </div>
            </div>
            {/* <div className="d-flex gap-2 align-items-start">
              <FontAwesomeIcon
                icon="fa-regular fa-arrows-repeat"
                style={{ color: "#000000", marginTop: "2px" }}
              />

              <div className="actors-lite-text grey-text ms-1">
                Repeats every Sunday
              </div>
            </div> */}
          </div>

          <div style={{ borderBottom: "1px solid #F0F0F0" }} className="p-3">
            <div className="d-flex gap-2 align-items-start">
              <FontAwesomeIcon
                icon="fa-regular fa-location-dot"
                style={{ color: "#000000", marginTop: "2px" }}
              />

              <div className="d-flex flex-column ms-2 ">
                <div className="header-semibold fs-16 ">Unit address</div>
                {/* <div className="header-semibold fs-16 mt-2">
                  Blomsterbjerget
                </div> */}
                <div className="actors-lite-text">
                  {/* Blomsterbjerget Blomstervej 14, 3. tv */}
                  {GetEventsDetailByEventId?.data?.[0]?.street_name} {""}
                  {GetEventsDetailByEventId?.data?.[0]?.house_number}
                </div>
                <div className="actors-lite-text">
                  {" "}
                  {GetEventsDetailByEventId?.data?.[0]?.zipcode_id} {""}{" "}
                  {GetEventsDetailByEventId?.data?.[0]?.city}{" "}
                </div>
                {/* <div className="actors-lite-text">7-W260</div> */}
              </div>
            </div>
          </div>

          <div style={{ borderBottom: "1px solid #F0F0F0" }} className="p-3">
            <div className="d-flex gap-2 align-items-start">
              <FontAwesomeIcon
                icon="fa-regular fa-users"
                style={{ color: "#000000", marginTop: "2px" }}
              />
              <div className="d-flex flex-column ms-1 ">
                <div className="header-semibold fs-16">Bookings</div>
                <div className="light-font-text fs-12">
                  Maximum bookings:{" "}
                  <span className="table-property-name">
                    {GetEventsDetailByEventId?.data?.[0]?.max_number_of_bookings
                      ? GetEventsDetailByEventId?.data?.[0]
                          ?.max_number_of_bookings
                      : "0"}{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div style={{ borderBottom: "1px solid #F0F0F0" }} className="p-3">
            <div className="d-flex gap-2 align-items-start">
              <FontAwesomeIcon
                icon="fa-regular fa-comment"
                style={{ color: "#000000", marginTop: "2px" }}
              />
              <div className="d-flex flex-column ms-1 ">
                <div className="header-semibold fs-16">Comment</div>
                <div className="light-font-text fs-12">
                  {GetEventsDetailByEventId?.data?.[0]?.detailed_info}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #F0F0F0" }} className="p-3">
          <Row className="px-1">
            {" "}
            <Col>
              {" "}
              <ETButton
                onClick={() => {
                  setOpenFollowUpEvent(false);
                  setShowOpenHousePropertyFollowUp(true);
                }}
                width={"100%"}
                variant={"primary"}
                buttonText="FOLLOW-UP"
              />
            </Col>
          </Row>
          <Row className="px-1 mt-2">
            <Col>
              <ReactSelect
                clearable={false}
                options={EditEventsOpt?.map((item) => ({
                  value: item.value,
                  label: item.label,
                }))}
                placeholder={"EDIT"}
              />
            </Col>
            <Col>
              <ReactSelect
                value={selectedOption}
                clearable={false}
                options={DeleteEventsOpt?.map((item) => ({
                  value: item.value,
                  label: item.label,
                }))}
                onChange={handleSelectChange}
                placeholder={"DELETE"}
              />
            </Col>
          </Row>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center">
          <div className="created-by">Created by Max Wilderness Johnsen </div>
        </div>
      </CustomPopup>
      {/* <DeleteEvents
        selectedOptionTwo={selectedOptionTwo}
        close={setShowModal}
        open={showModal}
      /> */}
    </div>
  );
};

export default FollowUpEventPopup;
