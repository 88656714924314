import React, { useState, useRef, useEffect } from "react";
import Select, { components } from "react-select";
import { normalInputStyle } from "../DropDown/CustomReactSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomCheckBox from "../Checkbox/Checkbox";

const options = [
  {
    value: "Denmark",
    label: "Denmark",
    logo: "https://millicent-test.b-cdn.net/assets/flags/dk.svg",
  },
  {
    value: "English",
    label: "English",
    logo: "https://millicent-test.b-cdn.net/assets/flags/gb.svg",
  },
  {
    value: "Swedish",
    label: "Swedish",
    logo: "https://millicent-test.b-cdn.net/assets/flags/se.svg",
  },
  // Add more options as needed
];

const { MenuList, ValueContainer, Placeholder } = components;

const CustomMenuList = ({ selectProps, ...props }) => {
  const {
    onInputChange,
    inputValue,
    onMenuInputFocus,
    setInputValuea,
    data,
    searchBarHeader = "Search for",
  } = selectProps;

  // const [searchQuery, setSearchQuery] = useState("");
  // const [filteredData, setFilteredData] = useState(options);

  // const handleSearch = (e) => {
  //   const query = e.target.value;
  //   setSearchQuery(query);

  //   const filtered = data.filter((item) =>
  //     item.toLowerCase().includes(query.toLowerCase())
  //   );

  //   setFilteredData(filtered);
  // };

  return (
    <div>
      <div className="pt-3 ps-3 fs-14 pb-2 fw-300 grey-text">
        {searchBarHeader}
      </div>
      <div
        className="d-flex justify-content-center pb-2"
        style={{ borderBottom: "1px solid #D1D1D1" }}
      >
        <div
          className="d-flex mx-3  p-2 pe-3"
          style={{
            border: "1px solid #F0F0F0",
            borderRadius: "8px",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <input
              className=""
              style={{
                border: "none",
                outline: "none",
                color: "#202020",
                width: "100%",
              }}
              type="text"
              // value={searchQuery}
              value={inputValue}
              setInputValuea
              onChange={(e) => {
                setInputValuea(e.currentTarget.value);
                onInputChange(e.currentTarget.value, {
                  action: "input-change",
                });
              }}
              // onChange={handleSearch}
              onMouseDown={(e) => {
                e.stopPropagation();
                e.target.focus();
              }}
              onTouchEnd={(e) => {
                e.stopPropagation();
                e.target.focus();
              }}
              onFocus={onMenuInputFocus}
              placeholder="Search..."
            />
          </div>
        </div>
      </div>

      <MenuList
        {...props}
        selectProps={selectProps}
      />
    </div>
  );
};

const FlagOption = (props) => (
  <components.Option {...props}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <CustomCheckBox />
      <img
        src={props.data.logo}
        alt={props.data.label}
        style={{ width: "24px", marginRight: "10px", marginLeft: "10px" }}
      />
      {props.data.label}
    </div>
  </components.Option>
);

export const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder
        {...props}
        isFocused={props.isFocused}
      >
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.key !== "placeholder" ? child : null
      )}
    </ValueContainer>
  );
};

export const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon
          icon="fa-solid fa-chevron-down"
          style={{ color: "#000000" }}
        />
      </components.DropdownIndicator>
    )
  );
};

export const ClearIndicator = (props) => {
  return (
    components.ClearIndicator && (
      <components.ClearIndicator {...props}>
        <FontAwesomeIcon
          icon="fa-solid fa-xmark"
          style={{ color: "#000000" }}
        />
      </components.ClearIndicator>
    )
  );
};

const LanguageSelectSearch = ({
  inputValue,
  onInputChange,
  isSearchable,
  placeholder,
  searchBarHeader,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const containerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValuea, setInputValuea] = useState(inputValue);
  const onDomClick = (e) => {
    let menu = containerRef.current.querySelector(".select__menu");

    if (
      !containerRef.current.contains(e.target) ||
      !menu ||
      !menu.contains(e.target)
    ) {
      setIsFocused(false);
      setInputValuea("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onDomClick);

    return () => {
      document.removeEventListener("mousedown", onDomClick);
    };
  }, []);

  const handleMultiSelectChange = (selectedItems) => {
    setSelectedOptions(selectedItems);
  };

  return (
    <div ref={containerRef}>
      <Select
        isMulti
        options={options}
        data={options}
        styles={colourStyles}
        value={selectedOptions}
        onChange={handleMultiSelectChange}
        components={{
          Option: FlagOption,
          MenuList: CustomMenuList,
          ValueContainer: CustomValueContainer,
          DropdownIndicator,
          ClearIndicator,
        }}
        placeholder={placeholder}
        isSearchable={false}
        {...{
          menuIsOpen: isFocused || undefined,
          isFocused: isFocused || undefined,
        }}
        closeMenuOnSelect={false}
        inputValue={inputValuea}
        setInputValuea={setInputValuea}
        onInputChange={onInputChange}
        onMenuInputFocus={() => setIsFocused(true)}
        hideSelectedOptions={false}
        searchBarHeader={searchBarHeader}
        classNamePrefix="react-select"
      />
    </div>
  );
};

export default LanguageSelectSearch;

export const myInputStyle = {
  container: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    position: "relative",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    backgroundColor: "#ffffff",
    borderRadius: 8,
    fontFamily: "Cerebri sans-light !important",
    fontSize: 14,
    // fontWeight:300,
    marginLeft: "10px",
    padding: "0px",
    paddingTop: "2px",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#000000", // Custom colour
    transition: "all .2s ease",
    transform: state.isFocused ? "rotate(180deg)" : null,
    padding: "10px 16px",
  }),
  placeholder: (base, state) => ({
    ...base,
    position: "absolute",
    fontWeight: "300",
    background: "rgb(255,255,255)",
    background:
      "linear-gradient(0deg, rgba(255,255,255,1) 55%, rgba(247,247,247,0) 45%)",
    borderRadius: 2,
    padding:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? "0px 4px"
        : "0px 1px",
    margin: "0px",
    // marginLeft: "10px",
    color:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? "#888888"
        : "#000000",
    fontFamily:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? " Cerebri sans-medium !important"
        : "Cerebri sans-light !important",
    top:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? "-58%"
        : "0%",
    transition: "top 0.2s, font-size 0.2s",
    fontSize: state.selectProps.isFocused || state.hasValue ? 8 : 14,
    fontWeight: 300,
    // (state.hasValue ||
    //   state.selectProps.inputValue ||
    //   state.selectProps.isFocused) &&
    // 14,
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    // paddingTop: "4px",
    fontSize: 14,
    fontWeight: 300,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? "#d1d1d1 !important" : "#d1d1d1 !important",
    borderRadius: "8px",
    backgroundColor: "#fff",
    "&:hover": {
      borderColor: "#d1d1d1",
      boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
    },
    pointerEvents: state.isDisabled && "auto",
    cursor: state.isDisabled ? "not-allowed " : "default",
    minHeight: "40px",
    // opacity: state.isDisabled ? "0.5" : "1",
  }),
  option: (base, { isDisabled }, state) => ({
    ...base,
    backgroundColor: "#fff",
    fontSize: 14,
    fontFamily: "Cerebri sans-light !important",
    zIndex: 1,
    color: isDisabled ? "#888888" : "#000000",
    paddingLeft: "15px",
    minHeight: "40px",
  }),
  singleValue: (provided, { data }) => ({
    ...provided,
    marginLeft: "4px",
    // specify a fallback color here for those values not accounted for in the styleMap
  }),
  menu: (base) => ({
    ...base,
    zIndex: 3,
    fontSize: 14,
    fontWeight: 300,
    width: "max-content",
    minWidth: "100%",
    overflowX: "scroll",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 10000 }),
};

const colourStyles = {
  container: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    backgroundColor: "rgb(255,255,255)",
    fontFamily: "Cerebri sans-light !important",
    borderRadius: 8,
    marginLeft: "10px",
    fontSize: 14,
    paddingTop: "4px",
  }),
  placeholder: (base, state) => ({
    ...base,
    position: "absolute",
    background: "rgb(255,255,255)",
    fontFamily:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? " Cerebri sans-medium !important"
        : "Cerebri sans-light !important",
    background: "#ffffff",
    borderRadius: 2,
    paddingLeft: "4px",
    paddingRight: "4px",
    // marginLeft: "10px",
    top: state.hasValue ? "-35%" : state.selectProps.isFocused ? "-75%" : "0%",
    transition: "top 0.2s, font-size 0.2s",
    fontSize: state.selectProps.isFocused || state.hasValue ? 8 : 14,
    // (state.hasValue ||
    //   state.selectProps.inputValue ||
    //   state.selectProps.isFocused) &&
    // 14,
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    // paddingTop: "4px",
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? "#d1d1d1 !important" : "#d1d1d1 !important",
    borderRadius: "8px",
    backgroundColor: "#fff",
    fontSize: 14,
    "&:hover": {
      borderColor: "#d1d1d1",
      boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
    },
    pointerEvents: state.isDisabled && "auto",
    cursor: state.isDisabled ? "not-allowed " : "default",
    minHeight: "40px",
    // opacity: state.isDisabled ? "0.6" : "1",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#000000", // Custom colour
    transition: "all .2s ease",
    transform: state.isFocused ? "rotate(180deg)" : null,
    padding: "10px 16px",
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    width: "0px",
  }),
  option: (
    base,
    { isDisabled, isFocused, isSelected, selectProps, ...state }
  ) => {
    const getIndex = state?.options.findIndex(
      (s) => state?.data?.value === s?.value
    );
    return {
      ...base,
      backgroundColor: "#fff",
      zIndex: 1,
      fontSize: 14,
      fontFamily: "Cerebri sans-light !important",
      color: isDisabled ? "#888888" : "#000000",
      paddingLeft: "15px",
      borderBottom:
        state?.options?.length - 1 === getIndex ? "" : "1px solid #D1D1D1",
      pending: "11px 16px 11px 16px !important",
      multiValue: (styles, state, { data }) => {
        return {
          ...styles,
          backgroundColor: state.isSelected ? "red" : "#fff",
        };
      },
    };
  },
  singleValue: (provided, state) => ({
    ...provided,
    backgroundColor: "red", // Change the background color of the single selected value
  }),
  menu: (base) => ({
    ...base,
    zIndex: 3,
    fontSize: 14,
    width: "max-content",
    minWidth: "100%",
    overflowX: "scroll",
  }),
  singleValue: (provided, { data }, state) => ({
    ...provided,
    marginLeft: "4px",
    backgroundColor: state.isSelected ? "red" : "",
    // specify a fallback color here for those values not accounted for in the styleMap
  }),
  menuPortal: (base) => ({ ...base, zIndex: 10000 }),
};

// Created by --------------> Yash
