import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

//for property overview
export const usePropertyOverview = create((set, get) => ({
	propOverview: [],
	setPropOverview: (data) => set({ propOverview: data }),
}));

export const usePropertyId = create(
	persist(
		(set, get) => ({
			propertyId: "",
			setPropertyId: (data) => set({ propertyId: data }),
		}),
		{
			name: "propertyId", // name of the item in the storage (must be unique)
			storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
		}
	)
);

//for all properties data
export const useAllPropertiesData = create((set, get) => ({
	allPropertiesStoreData: [],
	setAllPropertiesStoreData: (data) => set({ allPropertiesStoreData: data }),
}));
// for favoruite data
export const useFavoritesData = create((set, get) => ({
	favoritePropertiesStoreData: [],
	setFavoritePropertiesStoreData: (data) =>
		set({ favoritePropertiesStoreData: data }),
}));
//for country data
export const useCountry = create((set, get) => ({
	countryStoreData: [],
	setCountryStoreData: (data) => set({ countryStoreData: data }),
}));

//for property types
export const usePropertyType = create((set, get) => ({
	propertyTypesStore: [],
	setPropertyStoreData: (data) => set({ propertyTypesStore: data }),
}));

//for show page flag
export const useShowPage = create((set, get) => ({
	showPage: "PL",
	setShowPage: (data) => set({ showPage: data }),
}));

//for country name in add Property
export const useCountryName = create((set, get) => ({
	countryNameStore: "",
	setCountryNameStore: (data) => set({ countryNameStore: data }),
}));

//for portfolio type and portfolio id
export const usePorfolioTypeID = create((set, get) => ({
	portfolioIdStore: "",
	portfoliotypeStore: "D",
	setPortfolioIdStore: (data) => set({ portfolioIdStore: data }),
	setPortfoliotypeStore: (data) => set({ portfoliotypeStore: data }),
}));
