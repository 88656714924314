import { IconButton, TextField } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({
  filtertext,
  handleSearch,
  lableText,
  maxlength,
  placeholder,
  height,
  className,
}) => (
  <>
    <div className="d-flex w-100">
      <div style={{ position: "relative", width: "100%" }}>
        <div className={className ? className : "filter-component-input"}>
          <input
            style={{
              height: height,
            }}
            placeholder={placeholder ? placeholder : "Search "}
            id="filter-input"
            value={filtertext}
            onChange={handleSearch}
            maxLength={maxlength}
          />
          <label htmlFor={"filter-input"}>
            {lableText ? lableText : "Search..."}
          </label>
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <FontAwesomeIcon
          icon="fa-regular fa-magnifying-glass"
          style={{
            color: "#000000",
            position: "absolute",
            top: "12px",
            right: "15px",
          }}
        />
      </div>
    </div>
  </>
);

export default FilterComponent;

FilterComponent.defaultProps = {
  height: 40,
};
