import React, { useState } from "react";
import CustomPopup from "../../../Components/CustomPopup";
import ETButton from "../../../Components/Buttons/ETButton";
import moment from "moment";

import { status } from "../../../model/Constant";
import { toast } from "react-toastify";
import { DeleteEvent } from "../../../services/Events";
export const DeleteEvents = ({
  open,
  close,
  selectedOptionTwo,
  GetEventsDetailByEventId,
  propertyId,
  openHouseEventData,
  GetEventsByPropertyIdRefetch,
}) => {
  //------------------- State --------------------------//
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);
  const startTime = moment(
    GetEventsDetailByEventId?.data?.[0]?.time_start,
    "HH:mm:ss"
  ).format("HH:mm");
  const endTime = moment(
    GetEventsDetailByEventId?.data?.[0]?.time_end,
    "HH:mm:ss"
  ).format("HH:mm");

  //------------------- Delete specific  --------------------------//
  const handleDeletespecificEvents = async () => {
    setDisableDeleteButton(true);
    const deleteType = selectedOptionTwo ? 0 : 1;
    const eventCalendarEntryId = openHouseEventData?.eventCalendarEntryId;
    const eventId = openHouseEventData?.eventId;

    try {
      const response = await DeleteEvent(
        propertyId,
        eventCalendarEntryId,
        deleteType,
        eventId
      );
      if (response.status === status?.s && response.data.bReturn) {
        toast.success(response?.data?.message);
        close(false);
        GetEventsByPropertyIdRefetch();
        setDisableDeleteButton(false);
      } else {
        toast.error(response?.data?.message);
        setDisableDeleteButton(false);
      }
    } catch (error) {
      toast.error(error?.message);
      setDisableDeleteButton(false);
    }
  };
  return (
    <div>
      <CustomPopup
        open={open}
        onHide={() => close(false)}
        title={
          selectedOptionTwo
            ? "Delete series of events"
            : "Delete specific event"
        }
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="p-3">
          <div className="px-2 mb-5">
            <div className="light-font-text my-1">
              Are you sure you want to delete{" "}
              {selectedOptionTwo
                ? "the series of events"
                : "this specific event"}
              :{" "}
              <span className="form-label-title">
                {" "}
                {/* Blomsterbjerget, Sunday 19th February 2023 at 11:00 to 12:00{" "} */}
                {moment(
                  GetEventsDetailByEventId?.data?.[0]?.event_date,
                  "YYYY-MM-DD"
                ).format("Do MMMM YYYY")}{" "}
                at {startTime} to {endTime}.
              </span>
            </div>
          </div>
        </div>

        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => close(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="DELETE"
            disabled={disableDeleteButton}
            onClick={handleDeletespecificEvents}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export const DeleteRegistration = ({ open, close, setOpenFollowUpEvent }) => {
  return (
    <div>
      <CustomPopup
        open={open}
        onHide={() => close(false)}
        title="Delete package"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="p-3">
          <div className="px-2 mb-5 light-font-text">
            <div className=" my-1">
              Are you sure you want to delete this registration. It can’t be
              undone.
            </div>
            <div className="">
              {" "}
              A confirmation email will be sent to the registrant.
            </div>
          </div>
        </div>
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => close(false)}
          />
          <ETButton variant={"primary"} buttonText="DELETE" />
        </div>
      </CustomPopup>
    </div>
  );
};
