import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Col, Image, Row } from "react-bootstrap";
import photoComingSoon from "../../assets/images/photo-coming-soon.jpg";
import { H4 } from "../../styles/Common.style";
import NewColorBox from "../ColorCombinationbox/NewColorBox";

//   code for tooltip
export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "#000",
    width: 220,
    fontFamily: "Cerebri sans",
    fontSize: "14px",
    border: "1px solid #F4F5F5",
    borderRadius: "8px",
  },
}));
const PropertyLevelTopBar = ({ propertyData }) => {
  const [onHover, setOnHover] = useState(false);
  const [onPropertyNameHover, setPropertyNameHover] = useState(false);
  //   function used for get bg detail
  const getStatusBGDetails = (status_name) => {
    let status = propertyData?.[0]?.status_color?.filter(
      (ele) => ele.status_name === status_name
    );
    return status?.[0]?.background_color;
  };
  console.log(propertyData, "propertyData");
  return (
    <div>
      {" "}
      {propertyData?.map((item, i) => (
        <Row
          key={i}
          className="ms-xxl-5 ms-lg-4 ms-3 pt-4 pb-3  px-xxl-1 px-1 align-items-end"
        >
          <Col
            // xl={3}
            lg={4}
            md={5}
            className="px-md-2 d-flex justify-content-md-start justify-content-center ellipsis"
          >
            <div>
              <Image
                src={item?.image_url ? item?.image_url : photoComingSoon}
                alt="propertImage"
                width="100px"
                height="70px"
                rounded
              />
            </div>

            <div className="d-flex align-items-center ps-3">
              <div>
                <H4
                  className="ellipsis"
                  onMouseOver={() => setPropertyNameHover(true)}
                  onMouseLeave={() => setPropertyNameHover(false)}
                >
                  {item?.property_name?.length > 22
                    ? `${item?.property_name.substring(0, 22)}...`
                    : item?.property_name}
                </H4>
                {onPropertyNameHover && item?.property_name?.length > 22 && (
                  <div
                    style={{ position: "absolute", width: "200px" }}
                    className="fs-14  grey-info bg-white radius-8 border p-1 text-wrap text-capitalize line-height-20"
                  >
                    {item?.property_name}
                  </div>
                )}
                <div className="grey-info  fs-14 line-height-20">
                  <div className="m-0 line-height-20">
                    {item?.street_name} {item?.house_number} |{" "}
                    {item?.zipcode_id} {item?.city_name}
                  </div>

                  <div className="m-0 line-height-20">{item?.country_name}</div>
                </div>
              </div>
            </div>
          </Col>

          <Col xl={2} lg={2} md={3}>
            <div className="fs-14  line-height-20 pb-1">
              <div className="pb-1">
                <span>Project type</span> :&nbsp;
                <span className="grey-info">{item?.property_type}</span>
              </div>
              <div>
                <span>Units</span> :&nbsp;
                <span className="grey-info">{item?.total_unit}</span>
              </div>
            </div>
          </Col>

          <Col xl={2} lg={2} md={4} className="pt-sm-2 pt-0 ">
            <div className="fs-14  line-height-20 pb-1">
              <div className="pb-1">
                <span>Construction year</span> :&nbsp;
                <span className="grey-info">{item?.construction_year}</span>
              </div>
              <div className="d-block text-truncate">
                <span>Portfolio</span> :&nbsp;
                <span
                  className="text-capitalize grey-info"
                  onMouseOver={() => setOnHover(true)}
                  onMouseLeave={() => setOnHover(false)}
                >
                  {item?.portfolio_name
                    ? item?.portfolio_name?.split(",")?.join(",  ")
                    : "-"}
                </span>
                {onHover && item?.portfolio_name?.length > 10 && (
                  <div
                    style={{ position: "absolute", width: "200px" }}
                    className="bg-white radius-8 border p-1 text-wrap text-capitalize"
                  >
                    {item?.portfolio_name?.split(",")?.join(",  ")}
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col
            // xl={{ span: 5, offset: 0 }}
            lg={{ span: 4, offset: 0 }}
            md={{ span: 6, offset: 5 }}
            className="d-flex align-items-end pb-1"
          >
            <div className="fs-14  line-height-20">Current status :&nbsp;</div>
            <NewColorBox
              unitsOverview={item?.overall_status}
              TotalUnits={item?.total_unit}
            />
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default PropertyLevelTopBar;
