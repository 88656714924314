// UI creted By Suraj
import React, { useEffect, useState } from "react";
import ETButton from "../../../../Components/Buttons/ETButton";
import { Col, Form, Row } from "react-bootstrap";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import Profile from "../../../../Components/Profile/Profile";
import { useForm, Controller } from "react-hook-form";
import InputField from "../../../../Components/Textfield/InputField";
import { H4 } from "../../../../styles/Common.style";
import { useOrganizationId } from "../../../../hooks/stores/useOrganizationStore";
import { useGetDivisionByOrganisationId } from "../../../../hooks/queries/useDivisionQueries";
import {
  useGetUserByLevel,
  useGetUserDetails,
} from "../../../../hooks/queries/useUserQueries";
import { useDivisionId } from "../../../../hooks/stores/useDivisionsStore";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";
import CustomReactSelect from "../../../../Components/DropDown/CustomReactSelect";

const CreateNewTeam = ({
  handleNextCreateScreen = () => {},
  handleCloseCreateScreen = () => {},
  teamData,
  DivisionOption,
  // userByLevel,
  EditTeamData,
  flag,
  // setSelectedDivisionId,
  setSelectedTeamDataValue,
  selectedTeamDataValue,
  croppedImage,
  setCroppedImage,
  uploadImgFile,
  setUploadImgFile,
  formData,
  setFormData,
  selectedTeamManager,
  // GetUserBylevelData,
}) => {
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    getValues,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      division: "",
      teamName: "",
      teamManager: "",
    },
  });

  let teamNameChar = watch("teamName")?.toUpperCase();
  //====================================Zustand==========================//
  const organizationId = useOrganizationId((state) => state.organizationId);

  // const { divisionId } = useDivisionId((state) => state);

  //=====================================React query=============================//
  const { data: GetDivisionByOrganisationId } = useGetDivisionByOrganisationId(
    organizationId,
    "basic"
  );
  const { data: GetUserDetailsData } = useGetUserDetails();

  const { data: GetUserBylevelData } = useGetUserByLevel(
    "d",
    selectedTeamDataValue?.division,
    "0",
    "1,2",
    "basic"
  );
  const changeManager = GetUserBylevelData?.data?.filter(
    (data) => data?.Id == Object.keys(selectedTeamManager)
  );

  //=========================handler===========================================//
  const handleCloseScreen = () => {
    reset();
    setCroppedImage();
    // setSelectedTeamDataValue({
    //   ...selectedTeamDataValue,
    //   division: "",
    //   teamName: "",
    //   manager: "",
    // });
    handleCloseCreateScreen();
  };
  const check = 1;
  const seletedDivisionOption = GetDivisionByOrganisationId?.data?.map(
    (text, index) => ({
      value: " text?.Id",
      label: "text?.Text",
      isDisabled: selectedTeamDataValue?.division == text?.Id,
    })
  );

  const handleAcessNextScreen = (data) => {
    handleNextCreateScreen(data, uploadImgFile, teamNameChar);
    setFormData({
      ...formData,
      ...data,
    });
  };

  // useEffect(() => {
  //   if (teamData?.length != 0) {
  //     setValue("division", {
  //       value: teamData?.division?.value,
  //       label: teamData?.division?.label,
  //     });
  //     setValue("teamName", teamData?.teamName);
  //     setValue("teamManager", {
  //       value: teamData?.teamManager?.value,
  //       label: teamData?.teamManager?.label,
  //     });
  //     setCroppedImage(croppedImage);
  //   }
  // }, [teamData]);

  // const teamProfileUrl = EditTeamData?.[0]?.team_image_filename_url;
  // const teamManager = EditTeamData?.[0]?.members?.filter((data) => data?.is_team_manager == 1)
  // useEffect(() => {
  //   if (flag) {
  //     setValue("division", {
  //       value: EditTeamData?.[0]?.division_id,
  //       label: EditTeamData?.[0]?.division_name,
  //     });

  //     setValue("teamName", String(EditTeamData?.map((data) => data?.name)));
  //     setValue("teamManager", {
  //       value: teamManager?.map?.((data) => data?.user_id),
  //       label: teamManager?.map?.((data) => data?.manager_name),
  //     })
  //     const divisionId = watch("division.value");
  //     const teamName = watch("teamName");
  //     const managerId = watch("teamManager.value");
  //     setSelectedTeamDataValue({
  //       ...selectedTeamDataValue,
  //       division: divisionId,
  //       teamName: teamName,
  //       manager: managerId,
  //     });
  //     if (teamProfileUrl != "") {
  //       setCroppedImage(teamProfileUrl);
  //     }
  //   }

  //   else {
  //     setValue("teamManager", {
  //       value: GetUserDetailsData?.data?.map((data) => data?.user_id),
  //       label: GetUserDetailsData?.data?.map(
  //         (data) => data?.first_name + " " + data?.last_name
  //       ),
  //     });
  //     const managerValue = watch("teamManager.value");
  //     setSelectedTeamDataValue({
  //       ...selectedTeamDataValue,
  //       manager: managerValue,
  //     });

  //   }

  // },
  //   [flag, EditTeamData]

  // );

  // const managerWatch = watch("teamManager.value")
  // console.log("🚀 ~ file: CreateNewTeam.js:172 ~ managerWatch:", managerWatch)

  useEffect(() => {
    if (formData?.division) {
      setValue("division", formData?.division);
    }

    if (formData?.teamName) {
      setValue("teamName", formData?.teamName);
    }
    if (formData?.teamManager) {
      setValue("teamManager", formData?.teamManager);
    }
    // else if (changeManager?.length > 0) {
    //   setValue("teamManager", { value: changeManager?.[0]?.Id, label: changeManager?.[0]?.Text });
    // }
    else {
      setValue("teamManager", {
        value: GetUserDetailsData?.data?.map((data) => data?.user_id),
        label: GetUserDetailsData?.data?.map(
          (data) => data?.first_name + " " + data?.last_name
        ),
      });
      const managerValue = watch("teamManager.value");
      setSelectedTeamDataValue({
        ...selectedTeamDataValue,
        manager: managerValue,
      });
    }
    const divisionId = watch("division.value");
    const teamName = watch("teamName");
    const managerId = watch("teamManager.value");
    setSelectedTeamDataValue({
      ...selectedTeamDataValue,
      division: divisionId,
      teamName: teamName,
      manager: managerId,
    });
  }, [formData, GetUserDetailsData]);

  useEffect(() => {
    const fetchImageAndSetFile = async () => {
      try {
        if (croppedImage) {
          const response = await fetch(croppedImage);
          const blob = await response.blob();
          const file = new File([blob], `${teamNameChar}.png`, {
            type: blob.type,
          });
          setUploadImgFile(file);
        } else {
          // Handle case when croppedImage is empty
          setUploadImgFile("");
        }
      } catch (error) {
        setUploadImgFile(error);
      }
    };

    fetchImageAndSetFile();
  }, [croppedImage]);

  return (
    <div>
      {/* CreateNewTeam */}
      <Form onSubmit={handleSubmit(handleAcessNextScreen)}>
        <div
          style={{ height: "450px", overflowY: "scroll" }}
          className="scrollable-container-y me-1"
        >
          <Row>
            <Col
              className="pe-0"
              xl={8}
              lg={7}
            >
              <div
                className=""
                style={{ borderRight: "1px solid #f0f0f0" }}
              >
                {/* Team information */}
                <div className="p-4 border-bottom">
                  <H4 className="mb-3">
                    {flag ? "Edit existing team" : "Create a new team"}
                  </H4>
                  <div className="light-font-text mb-3">
                    A team belongs to a specific division and the team is
                    assigned to specific properties.
                    <br /> System users gains access to one or more properties
                    by being a member of one or more teams.
                  </div>

                  {/* Division */}
                  <H4 className="mb-2">Division</H4>
                  <div className="light-font-text mb-4 w-75">
                    Select the division under which the team should be created.
                    Note that you can only see the divisions that you have
                    access to.
                  </div>
                  <Row>
                    <Col
                      sm={5}
                      className="pe-0"
                      style={{ cursor: "pointer" }}
                    >
                      <Controller
                        {...register(`division`)}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                          <CustomReactSelect
                            placeholder={"Select a division (required)"}
                            value={value}
                            options={GetDivisionByOrganisationId?.data?.map(
                              (text, index) => ({
                                value: text?.Id,
                                label: text?.Text,
                                isDisabled: value.value == text?.Id,
                              })
                            )}
                            iserror={errors?.division}
                            onChange={(e) => {
                              onChange(e);
                              // setSelectedDivisionId(e?.value)
                              setSelectedTeamDataValue({
                                ...selectedTeamDataValue,
                                division: e?.value,
                              });
                            }}
                            isSearchable={false}
                            searchBarHeader={"Search for division"}
                            isMulti={false}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </div>

                {/* Team information */}
                <div className="p-4 border-bottom">
                  <H4 className="mb-2">Team information</H4>
                  <div className="light-font-text mb-4">
                    Give the team a meaningful name. The name is used everywhere
                    in the system.
                  </div>
                  <Row>
                    <Col
                      sm={5}
                      className="pe-0"
                    >
                      <Controller
                        {...register(`teamName`)}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                          <InputField
                            type={"text"}
                            placeholder={"Team name (required)"}
                            id={"teamName123"}
                            value={value}
                            textTransform={"capitalize"}
                            iserror={errors?.teamName}
                            onChange={(e) => {
                              onChange(e);
                              setSelectedTeamDataValue({
                                ...selectedTeamDataValue,
                                teamName: e.target.value,
                              });
                            }}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </div>
                {/* Team manager */}
                <div className="p-4">
                  <H4 className="mb-2">Team manager </H4>
                  <div className="mb-4 light-font-text">
                    A team must always have a team manager, who is the manager
                    of the team and can make changes to the team afterwards. By
                    default, the system user who created the team is selected,
                    but it is possible to change this below or by editing the
                    team afterwards.
                  </div>
                  <Row>
                    <Col
                      sm={5}
                      className="pe-0 cursor-pointer"
                    >
                      <Controller
                        {...register(`teamManager`)}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                          <CustomReactSelect
                            placeholder={"Select team manager (required)"}
                            value={value}
                            // value={changeManager?.length > 0 ? [{
                            //   value: changeManager?.[0]?.Id,
                            //   label: changeManager?.[0]?.Text,
                            // }] : value}
                            options={
                              // changeManager?.length > 0
                              //   ? [
                              //     {
                              //       value: changeManager[0]?.Id,
                              //       label: changeManager[0]?.Text,
                              //       isDisabled: value.value == changeManager[0]?.Id,
                              //     },
                              //   ]
                              //   :
                              GetUserBylevelData?.data?.map((text, index) => ({
                                value: text?.Id,
                                label: text?.Text,
                                isDisabled: value.value == text?.Id,
                              }))
                            }
                            iserror={errors?.teamManager}
                            onChange={(e) => {
                              onChange(e);
                              // setSelectedDivisionId(e?.value)
                              setSelectedTeamDataValue({
                                ...selectedTeamDataValue,
                                manager: e?.value,
                              });
                            }}
                            isSearchable={false}
                            searchBarHeader={"Search for team manager"}
                            isMulti={false}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col
              xl={4}
              lg={5}
              className="pe-0 ps-0"
            >
              <div className="p-4">
                <H4 className="mb-2">Team image</H4>
                <div className="light-font-text pe-4">
                  Give the team a recognisable look by uploading a picture of
                  the team. If not, the team's first letter is used.
                </div>
              </div>
              <Profile
                firstName={teamNameChar}
                croppedImage={croppedImage}
                setCroppedImage={setCroppedImage}
              />
            </Col>
          </Row>
        </div>
        {/* user footer */}
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            type="button"
            buttonText="CANCEL"
            onClick={() => {
              handleCloseScreen();
            }}
          />
          <ETButton
            variant={"primary"}
            buttonText="Next"
            type="submit"
            // onClick={handleAcessNextScreen}
          />
        </div>
      </Form>
    </div>
  );
};

export default CreateNewTeam;
