import React, { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { NavLink } from "../../../Components/Tabs/ReactTabs.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menus from "../../../Components/Menus/Menus";
import { MenuItem } from "@mui/material";

const OrgSetting = () => {
  const [showShadow, setShowShadow] = useState(false);
  const [scrollValue, setScrollValue] = useState(0);
  const elementRef = useRef(null);
  const navigate = useNavigate();

  const organisationSettingTabs = [
    {
      Id: "1",
      tab: "Users",
      link: "users",
    },
    {
      Id: "2",
      tab: "System defaults",
      link: "system-defaults",
    },
    // {
    //   Id: "3",
    //   tab: "Marketing",
    //   link: "marketing",
    // },
    // {
    //   Id: "4",
    //   tab: "Property",
    //   link: "property",
    // },
    // {
    //   Id: "5",
    //   tab: "Screening",
    //   link: "screening",
    // },
    // {
    //   Id: "6",
    //   tab: "Communication",
    //   link: "communication",
    // },
    // {
    //   Id: "7",
    //   tab: "Integrations",
    //   link: "integrations",
    // },
    // {
    //   Id: "8",
    //   tab: "Signing",
    //   link: "signing",
    // },
    // {
    //   Id: "9",
    //   tab: "Customer funnel",
    //   link: "customer-funnel",
    // },
    // {
    //   Id: "10",
    //   tab: "Customer page",
    //   link: "customer-page",
    // },
  ];

  const handleNav = (direction) => {
    // if (direction === "Left") {
    //   elementRef.current.scrollLeft -= 75;
    // } else {
    //   elementRef.current.scrollLeft += 75;
    // }

    // code changed for adding smoothness....
    const container = elementRef.current;

    if (direction === "Left") {
      container.scrollBy({
        left: -75,
        behavior: "smooth",
      });
    } else {
      container.scrollBy({
        left: 75,
        behavior: "smooth",
      });
    }
  };

  const handleMenuNavigation = (link) => {
    navigate(link);
  };

  useEffect(() => {
    const handleScroll = () => {
      const container = elementRef.current;

      // Check if the user has scrolled to the end
      const isAtEnd =
        Math.floor(container?.scrollLeft) + container.clientWidth + 5 >=
        container.scrollWidth;
      setShowShadow(isAtEnd);
    };

    const container = elementRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const tabContainerScroll = () => {
    const container = elementRef?.current;
    setScrollValue(container?.scrollLeft);
  };

  return (
    <>
      <div
        className="d-flex align-items-center"
        style={{ position: "relative" }}
      >
        {/* left arrow button */}
        {/* <button
          onClick={() => handleNav("Left")}
          className="d-flex align-items-center justify-content-center"
          style={{
            width: "44px",
            height: "44px",
            padding: "11px",
            marginRight: "10px",
            flexShrink: "0",
            borderRadius: "8px",
            border: "1px solid #D1D1D1",
            background: scrollValue > 0 ? "#FFF" : "rgba(255,255,255,0.5)",
            boxShadow:
              scrollValue > 0 ? "25px 0px 40px 5px rgb(229,231,232)" : "",
            zIndex: 100,
          }}
        >
          <FontAwesomeIcon
            icon="fa-regular fa-chevron-left"
            size="sm"
            color="#000"
          />
        </button> */}

        <div
          className="d-flex organistaion-tabs"
          style={{
            width: "100%",
            flexDirection: "row",
            overflowX: "scroll",
            overflowY: "hidden",
          }}
          ref={elementRef}
          onScroll={tabContainerScroll}
        >
          {organisationSettingTabs?.map((item, i) => (
            <NavLink
              key={i}
              to={item.link}
              style={{
                textDecoration: "none",
                whiteSpace: "nowrap",
              }}
              className={organisationSettingTabs.length - 1 == i ? "" : "me-3"}
            >
              {item?.tab}
            </NavLink>
          ))}
        </div>

        {/* right arrow button */}
        {/* <button
          onClick={() => handleNav("Right")}
          className="d-flex align-items-center justify-content-center"
          style={{
            width: "44px",
            height: "44px",
            padding: "11px",
            marginLeft: "10px",
            marginRight: "16px",
            flexShrink: "0",
            borderRadius: "8px",
            border: "1px solid #D1D1D1",
            background: !showShadow ? "#FFF" : "rgba(255,255,255,0.5)",
            boxShadow: !showShadow ? "-25px 0px 40px 5px rgb(229,231,232)" : "",
          }}
        >
          <FontAwesomeIcon
            icon="fa-regular fa-chevron-right"
            size="sm"
            color="#000"
          />
        </button> */}

        {/* menu button */}
        {/* <Menus
          itemheight={"auto"}
          itemwidth={44}
          iconborderradius="8px"
          itemcontainerwidth="150px"
          iconbordercolor={"#D1D1D1"}
          burgerIcon={true}
          itemcontainerheight={"auto"}
          component={
            <>
              {organisationSettingTabs?.map((item, i) => (
                <>
                  <MenuItem
                    key={i}
                    sx={{
                      fontSize: "14px !important",
                      fontFamily: "Cerebri sans-light !important",
                      paddingY: "8px !important",
                      fontWeight: "300 !important",
                    }}
                    onClick={() => handleMenuNavigation(item?.link)}
                  >
                    {item?.tab}
                  </MenuItem>
                  {!(organisationSettingTabs.length - 1 == i) && (
                    <hr className="hr m-0"></hr>
                  )}
                </>
              ))}
            </>
          }
        /> */}
      </div>

      <div className="whiteContainerStyle mt-2">
        <Outlet />
      </div>
    </>
  );
};

export default OrgSetting;
