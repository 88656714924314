import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "@emotion/styled";
import ETButton from "../../../../Components/Buttons/ETButton";
import GoogleMapReact from "google-map-react";
import CustomPopup from "../../../../Components/CustomPopup";
import { Menu } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AutomaticMileStone from "./AutomaticMileStone";
import ManualMileStone from "./ManualMileStone";
import Menus from "../../../../Components/Menus/Menus";
import Switch from "../../../../Components/Switch/Switch";
import Form from "react-bootstrap/Form";
import { GreenDiv, GreenSpan } from "../../../../styles/Common.style";
import { GoogleMapReactWrapper } from "../../../../styles/Common.style";
import { StyledCard , H2 } from "../../../../styles/Common.style";
import EmptyMessage from "../../../../utils/EmptyMessage";

const MileStoneCardData = [
  {
    head: "Milestone #1",
    type: "Automatic",
    targetDate: "Ongoing target",
    status: "idleness",
    parameter: "Percentage",
    color: "#B23977",
    colorName: "Pink",
    visibility: "Shared",
    useAs: "Chart milestone",
    createAt: "Created Sep 11, 2022 by John Doe",
  },
  {
    head: "Milestone #1",
    type: "Automatic",
    targetDate: "Ongoing target",
    status: "idleness",
    parameter: "Percentage",
    color: "#B23977",
    colorName: "Pink",
    visibility: "Shared",
    useAs: "Chart milestone",
    createAt: "Created Sep 11, 2022 by John Doe",
  },
  {
    head: "Milestone #1",
    type: "Automatic",
    targetDate: "Ongoing target",
    status: "idleness",
    parameter: "Percentage",
    color: "#B23977",
    colorName: "Pink",
    visibility: "Shared",
    useAs: "Chart milestone",
    createAt: "Created Sep 11, 2022 by John Doe",
  },
  {
    head: "Milestone #1",
    type: "Automatic",
    targetDate: "Ongoing target",
    status: "idleness",
    parameter: "Percentage",
    color: "#B23977",
    colorName: "Pink",
    visibility: "Shared",
    useAs: "Chart milestone",
    createAt: "Created Sep 11, 2022 by John Doe",
  },
  {
    head: "Milestone #1",
    type: "Automatic",
    targetDate: "Ongoing target",
    status: "idleness",
    parameter: "Percentage",
    color: "#B23977",
    colorName: "Pink",
    visibility: "Shared",
    useAs: "Chart milestone",
    createAt: "Created Sep 11, 2022 by John Doe",
  },
  {
    head: "Milestone #1",
    type: "Automatic",
    targetDate: "Ongoing target",
    status: "idleness",
    parameter: "Percentage",
    color: "#B23977",
    colorName: "Pink",
    visibility: "Shared",
    useAs: "Chart milestone",
    createAt: "Created Sep 11, 2022 by John Doe",
  },
  {
    head: "Milestone #1",
    type: "Automatic",
    targetDate: "Ongoing target",
    status: "idleness",
    parameter: "Percentage",
    color: "#B23977",
    colorName: "Pink",
    visibility: "Shared",
    useAs: "Chart milestone",
    createAt: "Created Sep 11, 2022 by John Doe",
  },
  {
    head: "Milestone #1",
    type: "Automatic",
    targetDate: "Ongoing target",
    status: "idleness",
    parameter: "Percentage",
    color: "#B23977",
    colorName: "Pink",
    visibility: "Shared",
    useAs: "Chart milestone",
    createAt: "Created Sep 11, 2022 by John Doe",
  },
];

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

const Map = () => {
  // const GoogleMapReactWrapper = styled.div`
  //   height: 600px;
  //   width: 100%;
  //   overflow: hidden;
  //   border-radius: 8px;
  //   border: 1px solid #f4f5f5;
  // `;

  // Open Milestone Map

  const [openMap, setOpenMap] = useState(false);
  const [openMarkerPopup, setOpenMarkerPopup] = useState(false);

  const [showMilestone, setShowMilestone] = useState(false);

  const [automaticMileStone, setAutomaticMileStone] = useState(false);
  const [manualMileStone, setManualMileStone] = useState(false);

  const getMapOptions = (maps) => {
    return {
      fullscreenControl: false,
      mapTypeControl: false,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      scrollwheel: true,
      streetViewControl: true,
    };
  };

  const options = {
    center: {
      lat: 55.694821,
      lng: 12.530963,
    },
    zoom: 14,
  };
  return (
    <>
      <div
        style={{
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",

          backgroundColor: "white",
          //   cursor: "pointer",
        }}
        // onClick={() => setOpenMap(!openMap)}
      >
        {/* {openMap === false ? "SHOW MAP" : "HIDE MAP"} */}
        {/* </div> */}
        {openMap && (
          <div
            className="px-4 pt-4"
            style={{
              backgroundColor: "white",
              //   padding: "30px 35px  0px 35px",
              position: "relative",
            }}
            onClick={() => setOpenMarkerPopup(false)}
          >
            <GoogleMapReactWrapper>
              {/* <div className="d-flex justify-content-between"> */}
              <Row
                className="py-3 px-3"
                style={{ position: "absolute", zIndex: 2, width: "88%" }}
              >
                <Col xs={6}>
                  <ETButton
                    variant={"primary"}
                    icon={true}
                    fontSize="14px"
                    buttonText="CREATE MILESTONE"
                    onClick={() => {
                      setShowMilestone(true);
                    }}
                  />
                </Col>
                <Col xs={6} className="d-flex justify-content-lg-center">
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                    }}
                    className="d-flex flex-lg-row flex-column"
                  >
                    <div
                      className="d-flex align-items-center radius-4 p-1"
                      style={{
                        background: "#f9fafa",
                        border: "1px solid #f4f5f5",
                        height: "24px",
                      }}
                    >
                      <div
                        style={{
                          width: "8px",
                          height: "8px",
                          background: "#F1664B",
                          borderRadius: "2px",
                          marginRight: "8px",
                        }}
                      ></div>
                      <span
                        className="table-property-name fs-12"
                        style={{ lineHeight: "2px" }}
                      >
                        Over target
                      </span>
                    </div>

                    <div
                      className="d-flex align-items-center radius-4 p-1"
                      style={{
                        background: "#f9fafa",
                        border: "1px solid #f4f5f5",
                        height: "24px",
                      }}
                    >
                      <div
                        style={{
                          width: "8px",
                          height: "8px",
                          background: "#F6E651",
                          borderRadius: "2px",
                          marginRight: "8px",
                        }}
                      ></div>
                      <span
                        className="table-property-name fs-12"
                        style={{ lineHeight: "2px" }}
                      >
                        On target
                      </span>
                    </div>
                    <div
                      className="d-flex align-items-center radius-4 p-1"
                      style={{
                        background: "#f9fafa",
                        border: "1px solid #f4f5f5",
                        height: "24px",
                      }}
                    >
                      <div
                        style={{
                          width: "8px",
                          height: "8px",
                          background: "#77C7A1",
                          borderRadius: "2px",
                          marginRight: "8px",
                        }}
                      ></div>
                      <span
                        className="table-property-name fs-12"
                        style={{ lineHeight: "2px" }}
                      >
                        Under target
                      </span>
                    </div>
                  </div>
                </Col>
                <div>
                  <CustomPopup
                    open={showMilestone}
                    onHide={() => setShowMilestone(false)}
                    title={
                      <>MileStone</>
                    }
                    title_color="black"
                    titleFont="14px"
                    titleTooltip={true}
                    enableCrossButton={true}
                    titleTooltipText="Milestone"
                    size="xl"
                    onCloseAction={() => {
                      setShowMilestone(false);
                      alert("Action Peformed Successfuly.");
                    }}
                    action_button_label="YES"
                    action_button_label_color="white"
                    action_button_color="#619130"
                    cancel_button={true}
                    cancel_button_bordercolor="grey"
                    cancel_button_label="NO"
                    cancel_button_label_color="black"
                    cancel_button_color="#fff"
                    footer_color={"#F9FAFA"}
                  >
                    <div className="p-4">
                      <div className="light-font-text mb-3">
                        Set up one or more milestones. It is possible to set up
                        both automatic and manual milestones as well as choose
                        whether they are some that only you must see, or
                        everyone with access to the property must be able to
                        see.
                      </div>
                      <div
                        className="scrollable-container-y"
                        style={{ height: "500px" }}
                      >
                        {}
                        <EmptyMessage />
                        {/* <Row className="px-2 py-5">
                          {MileStoneCardData.map((data, i) => (
                            <Col lg={3} md={12} className="p-0 me-3 mb-md-3">
                              <div
                                className="ps-4 pe-3 pt-3"
                                style={{
                                  border: "1px solid #f4f5f5",
                                  backgroundColor: "#f9fafa",
                                  borderRadius: "8px",
                                }}
                              >
                                <Row className="pb-1">
                                  <Col
                                    className="d-flex align-items-center"
                                    sm={10}
                                  >
                                    {" "}
                                    <div
                                      className="d-flex"
                                      style={{ alignItems: "center" }}
                                    >
                                      <span className="fs-18 table-property-name">
                                        {data.head}
                                      </span>{" "}
                                    </div>
                                  </Col>
                                  <Col
                                    sm={2}
                                    className="d-flex justify-content-end"
                                  >
                                    {" "}
                                    <Menus
                                      itemheight={32}
                                      itemwidth={32}
                                      itemcontainerheight={150}
                                      itemcontainerwidth={150}
                                      component={
                                        <div className="light-font-text">
                                          <div className="addpropContent px-3 py-2 ">
                                            <span className="ms-1 fs-14">
                                              Edit
                                            </span>
                                          </div>
                                          <hr className="addpropertyHr m-0"></hr>
                                          <div className="addpropContent px-3 pt-2 pb-2 ">
                                            <span className="ms-1 fs-14">
                                              Delete
                                            </span>
                                          </div>
                                        </div>
                                      }
                                    />
                                  </Col>
                                </Row>
                                <Row className="pb-3">
                                  <Col>
                                    <div className="d-flex align-items-center gap-3 pt-2">
                                      <div>
                                        <Switch size="small" id={i} />
                                      </div>

                                      <div className="fs-12 light-font-text">
                                        Active
                                      </div>
                                    </div>
                                  </Col>
                                  <Col></Col>
                                </Row>
                                <Row className="pb-2">
                                  <Col>
                                    <div className="light-font-text grey-text">
                                      Type
                                    </div>
                                  </Col>
                                  <Col xs={7}>
                                    <div className="fs-14 c-black">
                                      {data.type}
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="pb-2">
                                  <Col>
                                    <div className="light-font-text grey-text ">
                                      Target date
                                    </div>
                                  </Col>
                                  <Col xs={7}>
                                    <div className="fs-14 c-black">
                                      {data.targetDate}
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="pb-2">
                                  <Col>
                                    <div className="light-font-text grey-text  ">
                                      Status
                                    </div>
                                  </Col>
                                  <Col xs={7}>
                                    <div className="fs-14 c-black">
                                      {data.status}
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="pb-2">
                                  <Col>
                                    <div className="light-font-text grey-text ">
                                      Parameter{" "}
                                    </div>
                                  </Col>
                                  <Col xs={7}>
                                    <div className="fs-14 c-black">
                                      {data.parameter}
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="pb-2">
                                  <Col>
                                    <div className="light-font-text grey-text ">
                                      Color{" "}
                                    </div>
                                  </Col>
                                  <Col xs={7}>
                                    <div className="d-flex align-items-center  gap-2">
                                      <div
                                        className="radius-4"
                                        style={{
                                          backgroundColor: data.color,
                                          width: "16px",
                                          height: "16px",
                                        }}
                                      ></div>
                                      <div className="fs-14 c-black">
                                        {data.colorName}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="pb-2">
                                  <Col>
                                    <div className="light-font-text grey-text ">
                                      Visibility{" "}
                                    </div>
                                  </Col>
                                  <Col xs={7}>
                                    <div className="d-flex align-items-center  gap-1">
                                      <div className="fs-14 c-black">
                                        {data.visibility}
                                      </div>

                                      <div>
                                        <Dropdown>
                                          <Dropdown.Toggle
                                            as={CustomToggle}
                                            id="dropdown-custom-components"
                                          >
                                            <GreenSpan className="fs-14 nowrap cursor-pointer ">
                                              (show more)
                                            </GreenSpan>
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu as={CustomMenu}>
                                            <div className="px-3">
                                              <div className="table-property-name fs-12">
                                                Shared with
                                              </div>
                                              <div className="fs-12 light-font-text">
                                                Joey Dawson
                                              </div>
                                            </div>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="pb-4">
                                  <Col>
                                    <div className="light-font-text grey-text ">
                                      Use as{" "}
                                    </div>
                                  </Col>
                                  <Col xs={7}>
                                    <div className="fs-14 c-black">
                                      {data.useAs}
                                    </div>
                                  </Col>
                                </Row>
                                <div className="light-font-text pb-3 fs-12 c-black">
                                  {data.createAt}
                                </div>
                              </div>
                            </Col>
                          ))}

                          <Col lg={3} md={12} className="p-0">
                            <StyledCard height="365px">
                              <div>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "none",
                                    }}
                                  >
                                    <ETButton
                                      variant={"primary"}
                                      buttonText="CREATE MILESTONE"
                                      icon={true}
                                      width="auto"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu style={{ marginLeft: "20px" }}>
                                    <Dropdown.Item>
                                      <div
                                        onClick={() => {
                                          setShowMilestone(false);
                                          setAutomaticMileStone(true);
                                        }}
                                        className=" fs-13"
                                      >
                                        Automatic
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <div
                                        onClick={() => {
                                          setShowMilestone(false);
                                          setManualMileStone(true);
                                        }}
                                        className=" fs-13"
                                      >
                                        Manual
                                      </div>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </StyledCard>
                          </Col>
                        </Row> */}
                      </div>
                    </div>
                  </CustomPopup>
                </div>

                {/* automatic milestone */}
                <CustomPopup
                  open={automaticMileStone}
                  onHide={() => setAutomaticMileStone(false)}
                  title={
                    <H2 className=" fs-16">
                      Create automatic milestone
                    </H2>
                  }
                  title_color="black"
                  titleFont="16px"
                  titleTooltip={true}
                  enableCrossButton={true}
                  titleTooltipText="create automatic milestone"
                  size="xl"
                  onCloseAction={() => {
                    setAutomaticMileStone(false);
                    alert("Action Peformed Successfuly.");
                  }}
                  action_button_label="YES"
                  action_button_label_color="white"
                  action_button_color="#619130"
                  cancel_button={true}
                  cancel_button_bordercolor="grey"
                  cancel_button_label="NO"
                  cancel_button_label_color="black"
                  cancel_button_color="#fff"
                  footer_color={"#F9FAFA"}
                >
                  <AutomaticMileStone
                    setAutomaticMileStone={setAutomaticMileStone}
                  />
                </CustomPopup>

                {/* Manual milestone */}
                <CustomPopup
                  open={manualMileStone}
                  onHide={() => setManualMileStone(false)}
                  title={
                    <div className="form-label-title">
                      Create manual milestone
                    </div>
                  }
                  title_color="black"
                  titleFont="16px"
                  titleTooltip={true}
                  enableCrossButton={true}
                  titleTooltipText="create manual milestone"
                  size="xl"
                  onCloseAction={() => {
                    setManualMileStone(false);
                    alert("Action Peformed Successfuly.");
                  }}
                  action_button_label="YES"
                  action_button_label_color="white"
                  action_button_color="#619130"
                  cancel_button={true}
                  cancel_button_bordercolor="grey"
                  cancel_button_label="NO"
                  cancel_button_label_color="black"
                  cancel_button_color="#fff"
                  footer_color={"#F9FAFA"}
                >
                  <ManualMileStone setManualMileStone={setManualMileStone} />
                </CustomPopup>
              </Row>
              <GoogleMapReact
                options={getMapOptions}
                bootstrapURLKeys={{
                  key: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
                }}
                defaultCenter={options.center}
                defaultZoom={options.zoom}
                // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
              >
                {/* {milestoneProperty?.map((item, i) => (
                  <Marker
                    key={i}
                    lat={item?.Coordinates?.split(",", 1)}
                    lng={item?.Coordinates?.split(",").splice(1)}
                    id={i}
                    data={item}
                    $hover
                  />
                ))} */}
              </GoogleMapReact>

              {/* </div> */}
            </GoogleMapReactWrapper>
          </div>
        )}
        <div
          className=" d-flex align-items-center justify-content-center"
          style={{ width: "100%" }}
        >
          <GreenDiv
            onClick={() => setOpenMap(!openMap)}
            className="px-1 py-2 container-title  fs-12  d-flex justify-content-center align-items-center cursor-pointer"
          >
            {openMap === false ? "SHOW MAP" : "HIDE MAP"}
          </GreenDiv>
        </div>
        {/* <GreenDiv
          onClick={() => setOpenMap(!openMap)}
          className="px-1 py-2 container-title  fs-12  d-flex justify-content-center align-items-center cursor-pointer"
        >
          {openMap === false ? "SHOW MAP" : "HIDE MAP"}
        </GreenDiv> */}
      </div>
    </>
  );
};

export default Map;
