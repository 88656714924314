import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import ETButton from "../../../../Components/Buttons/ETButton";
import Toggle from "../../../../Components/Switch/Switch";
import FilterComponent from "../../../../reactdataTableComponent/FilterComponent";
import CustomPopup from "../../../../Components/CustomPopup";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import DataTable from "react-data-table-component";
import TagToolTip from "../../../../Components/Tooltip/PropertyTagToolTip";
import { Controller, useForm } from "react-hook-form";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";
import { GetTagsByPropertyId } from "../../../../services/Tags";
import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAddEditPackage } from "../../../../hooks/stores/usePackageStore";
import { useRouting } from "../../../../hooks/stores/useRoutingStore";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { PrimarySelectedTag } from "../../../../styles/Common.style";
import MRTTheme from "../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";
import { H2 } from "../../../../styles/Common.style";
import InputField from "../../../../Components/Textfield/InputField";
import { SortIcon } from "../../../../Components/table/MRTGlobalProps";

const AddEditPackage = ({
  dropDownData,
  dropDownValue,
  subDropDownData,
  setDropDownValue,
  packageType,
  handleNext,
  showActiveInactive,
  showDropdown,
  subHeading,
  tagSubHeading,
  propertyId,
  saveAsDraft,
  dropdownHeading,
  dropDownSubHeading,
  dropDownLabel,
  modifiedData,
  disableNextButton,
  subDropDownValue,
  setSubDropDownValue,
  storeTemplateDocumentsDataId,
  disabledDropDownValue,
}) => {
  // -----------zustand--------------------------------------//
  const {
    title,
    setTitle,
    active,
    setActive,
    tagRowData,
    setTagRowData,
    selected,
    setSelected,
    selectedData,
    setSelectedData,
    setCancelPopup,
  } = useAddEditPackage((state) => state);
  const { editflag } = useRouting((state) => state);

  const [openPropertyTag, setOpenPropertyTag] = useState(false);
  // {
  // }
  const [prevSelected, setPrevSelected] = useState([]);
  const [preSelectedData, setPreSelectedData] = useState([]);
  const [previouslySelectedData, setPreviouslySelectedData] = useState([]);
  const [emptyRowMessage, setEmptyRowMessage] = useState(true);
  const [mediaStyle, setMediaStyle] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [previouslySelected, setPreviouslySelected] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [editSelectId, setEditSelectedId] = useState([]);
  //------------------react query-----------------------------//
  const {
    data: tagsByPropertyIdData,
    isLoading: tagsByPropertyIdDataIsLoading,
  } = useQuery(
    ["propertyId", propertyId],
    () => GetTagsByPropertyId(propertyId),
    {
      enabled: !!propertyId,
    }
  );
  // const handleOpenAddTag = () => {
  //   setOpenPropertyTag(true);
  //   setPreSelectedData([...selected])
  //   setPreviouslySelectedData([...selectedData]);
  // };

  // ----------------form validation ------------------------//
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      packageTitle: "",
      sortMedia: "",
      textSubCategory: "",
    },
  });

  // useEffect(() => {
  //   if (tagRowData?.length == 0) {
  //     setTagRowData([
  //       {
  //         tag_id: "-",
  //         name: "-",
  //         unit_count: "-",
  //       },
  //     ]);

  //   }

  // }, [tagRowData]);

  useEffect(() => {
    if (editflag) {
      setValue("packageTitle", title);
      setValue("sortMedia", {
        value: dropDownValue,
        label: dropDownData?.filter(
          (el) => el?.Id == dropDownValue && el?.Text
        )[0]?.Text,
      });

      setValue("textSubCategory", {
        value: subDropDownValue,
        label: subDropDownData?.filter(
          (el) => el?.Id == subDropDownValue && el?.Text
        )[0]?.Text,
      });
    } else {
      setValue("packageTitle", title);
      setValue(
        "sortMedia",
        dropDownValue == ""
          ? ""
          : {
              value: dropDownValue,
              label: dropDownData?.filter(
                (el) => el?.Id == dropDownValue && el?.Text
              )[0]?.Text,
              isDisabled: true,
            }
      );

      setValue(
        "textSubCategory",
        subDropDownValue == ""
          ? ""
          : {
              value: subDropDownValue,
              label: subDropDownData?.filter(
                (el) => el?.Id == subDropDownValue && el?.Text
              )[0]?.Text,
            }
      );
    }
  }, [editflag, title, dropDownValue, subDropDownValue, dropDownData]);

  // if there is no data for table rendering table will use this data for showing '-' (hyphens) in empty cells
  const dummyData = [
    {
      tag_id: "-",
      unit_count: "-",
      name: "-",
    },
  ];
  useEffect(() => {
    if (tagRowData?.length == 0) {
      setSelected([]);
    }
  }, [tagRowData]);
  const handleTitleChange = (e) => {
    setValue("packageTitle", e?.target.value);
    setTitle(e.target.value);
  };

  // add tag component part //

  let editCheckedId = tagRowData?.map((item) => {
    return String(item.tag_id);
  });
  // const [prevselected, setPrevSlected] = useState([])
  // console.log(selected, selectedData, prevselected, "testing2")

  // const handleCancelTag = () => {
  //   const tagIds = prevselected.map(item => item.tag_id);
  //   console.log(tagIds, "tagIds")
  //   setSelected(tagIds)
  //   setSelectedData(tagIds)
  //   console.log(selected, selectedData, prevselected, "testing")
  //   setOpenPropertyTag(false);
  //   if (editflag !== true) {
  //     setMediaStyle(false);
  //     setSelected([]);
  //     setSelectedData([]);
  //   }
  // };

  // // -------------edit tag--------------//

  // //------------------------- checkbox logic-------------------- /
  // const handleClick = (e, data) => {
  //   const { id, checked } = e.currentTarget;

  //   if (e.target.checked) {
  //     // let newArry = [...selected, id];
  //     // let newData = [...selectedData, data];
  //     let newArry = [...selected, e.target.id];
  //     let newData = [...selectedData, data];
  //     setSelected(newArry);
  //     setSelectedData(newData);
  //     setPrevSlected(newArry); // Update previousSelected state
  //   } else {
  //     let newArry = selected?.filter((item) => item != String(e.target.id));
  //     let newData = selectedData.filter(
  //       (item) => item.tag_id != String(e.target.id)
  //     );
  //     setPrevSlected(newArry);
  //     if (newArry.length != 0) {
  //       setSelected(newArry);
  //     } else {
  //       setSelected([]);
  //     }
  //     if (selectedData.length != 0) {
  //       setSelectedData(newData);
  //     } else {
  //       setSelectedData([]);
  //     }
  //   }
  // };

  // console.log(prevselected, selectedData, selected, "prevselected")

  // const handleAddTags = () => {

  //   // Update tagRowData with the selected tags from tagData.
  //   const newTagRowData = selectedData?.map((selectedTag) =>
  //     selectedData?.find((tag) => tag.tag_id == selectedTag.tag_id)
  //   );
  //   console.log(newTagRowData, "newTagRowData")
  //   setSelectedData(newTagRowData)
  //   setPrevSlected(newTagRowData)
  //   setTagRowData(newTagRowData);
  //   console.log(newTagRowData, "newTagRowData")

  //   setTagRowData(selectedData);
  //   setOpenPropertyTag(false);
  // };

  // const [previouslySelectedData, setPreviouslySelectedData] = useState([]);

  const handleClick = (e, data) => {
    const tagId = e.target.value;

    // const tags = data?.map((data) => data?.tagId)
    // console.log("🚀 ~ file: AddEditPackage.js:263 ~ handleClick ~ tags:", tags)
    // setSelectedId()
    if (selected?.includes(tagId)) {
      setSelected(selected?.filter((id) => id != tagId));
      setSelectedData(
        selectedData?.filter((tag) => tag?.tag_id != parseInt(tagId))
      );
    } else {
      setSelected([...selected, tagId]);

      const isDataAlreadySelected = selectedData?.some(
        (tag) => tag?.tag_id == data?.tag_id
      );
      if (!isDataAlreadySelected) {
        setSelectedData([...selectedData, data]);
      }
    }
  };

  const handleOpenAddTag = () => {
    setOpenPropertyTag(true);
    setPreviouslySelected([...selected]);
    setPreviouslySelectedData([...selectedData]);
  };

  const handleCancelTag = () => {
    setSelected(previouslySelected);
    setSelectedData(previouslySelectedData);
    setOpenPropertyTag(false);

    if (editflag !== true) {
      setMediaStyle(false);
      setSelectedData([]);
    }
  };

  const handleAddTags = () => {
    setPreviouslySelected(selected);
    setPreviouslySelectedData(selectedData);
    setTagRowData(selectedData);
    setOpenPropertyTag(false);
  };

  // ...rest of your code

  //   const [prevSelected, setPrevSelected] = useState([]);

  //   const handleCancelTag = () => {
  //     setSelected(prevSelected);
  //     setOpenPropertyTag(false);
  //     if (editflag !== true) {
  //       setMediaStyle(false);
  //       setSelectedData([]);
  //     }
  //   };

  //   const handleAddTags = () => {
  //     const updatedSelectedData = selectedData.filter(tag => selected.includes(tag.tag_id));
  //     setPrevSelected(selected);
  //     setTagRowData(updatedSelectedData);
  //     setOpenPropertyTag(false);
  //   };
  //   console.log(selectedData, "selectedData56")
  //   const handleClick = (e, data) => {
  //   const { id, checked } = e.currentTarget;

  //   if (e.target.checked) {
  //     const newSelected = [...selected, e.target.id];
  //     const newData = [...selectedData, data];
  //     setSelected(newSelected);
  //     setSelectedData(newData);
  //   } else {
  //     const newSelected = selected.filter((item) => item !== String(e.target.id));
  //     const newData = selectedData.filter((item) => item.tag_id !== String(e.target.id));
  //     setSelected(newSelected);
  //     setSelectedData(newData);
  //   }
  // };

  const HandleTagReset = () => {
    setMediaStyle(false);
    setSelected([]);
    setSelectedData([]);
  };

  // --------------add tag table state and logics -----------//

  const handleClose = () => {
    setAnchorEl(null);
    setDeletePopUp(false);
  };
  const [editFacilityId, setEditFacilityId] = useState("");
  // const [deletePopUp, setDeletePopUp] = useState(false);
  const handleDelete = (tagId) => {
    let newArry = tagRowData?.filter((item) => item.tag_id != tagId);
    setTagRowData(newArry);
    let newIdformodal = selected?.filter((item) => item != tagId);
    setSelected(newIdformodal);
    let newDataforModal = selectedData?.filter((item) => item.tag_id != tagId);
    setSelectedData(newDataforModal);
    handleClose();
    setDeletePopUp(false);
  };

  const customSortIconRenderer = ({ sorted, isSortedDesc }) => (
    <div className="flex-column align-items-center d-flex">
      <FontAwesomeIcon
        icon="fa-solid fa-triangle"
        style={{ color: "#b9bcc0" }}
      />

      <FontAwesomeIcon
        icon="fa-solid fa-triangle"
        flip="vertical"
        style={{ color: "#b9bcc0" }}
      />
    </div>
  );
  const columns = useMemo(
    () => [
      {
        accessorKey: "icon",
        header: "",
        enableHiding: false,
        size: 10,
        muiTableBodyCellProps: {},

        enableGlobalFilter: true,
        Cell: ({ cell }) => (
          <>
            <div>
              {cell.getValue() === "-" ? (
                <span>-</span>
              ) : (
                <TagToolTip size="lg" text="Need Help About Tags" />
              )}
            </div>
          </>
        ),
      },
      {
        accessorKey: "tag_id",
        header: "ID",
        enableHiding: false,
        size: 20,
        muiTableBodyCellProps: {},

        enableGlobalFilter: true,
        // Cell: ({ cell }) => (
        //   <>
        //     <div>
        //       {cell.getValue() === "-" ? (
        //         <span>-</span>
        //       ) : (
        //         <div><>{cell?.original?.tag_id}</></div>
        //       )}
        //     </div>
        //   </>
        // ),
      },

      {
        accessorKey: "name",
        header: "Name",
        enableHiding: false,
        size: 180,
        muiTableBodyCellProps: {},

        enableGlobalFilter: true,
        // Cell: ({ cell }) => (
        //   <>
        //     <div>
        //       {cell.getValue() === "-" ? (
        //         <span>-</span>
        //       ) : (
        //         <div><>{cell?.original?.name}</></div>
        //       )}
        //     </div>
        //   </>
        // ),
      },

      {
        accessorKey: "unit_count",
        header: "Unit_count",
        enableHiding: false,
        size: 130,
        muiTableBodyCellProps: {},

        enableGlobalFilter: true,
        // Cell: ({ cell }) => (
        //   <>
        //     <div>
        //       {cell.getValue() === "-" ? (
        //         <span>-</span>
        //       ) : (
        //         <div><>{cell?.original?.unit_count}</></div>
        //       )}
        //     </div>
        //   </>
        // ),
      },
    ],
    [tagRowData]
  );

  const NoDataPresentscolumns = [
    {
      name: "",
      selector: (row) => row.tag_id,
      width: "25%",
      center: "true",
    },
    {
      name: "",
      selector: (row) => row.unit_count,
      width: "5%",
      center: "true",
    },
    {
      name: "",
      selector: (row) => row.name,
      width: "70%",
      center: "true",
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        borderRadius: "4px",
        borderBottom: "none",
      },
      denseStyle: {
        minHeight: "32px",
      },
    },
    rows: {
      style: {
        // minHeight: '72px', // override the row height
        borderRadius: "4px",
        "&:not(:last-of-type)": {
          borderBottom: "none",
        },
      },
    },
    headCells: {
      style: {
        // textAlign: "center",
        fontweight: "bold",
        // justifyContent: "left",
        paddingRight: "18px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        // justifyContent: "left",
        paddingLeft: "18px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "13px",
        fontweight: "300",
      },
    },
  };

  const searchData = tagsByPropertyIdData?.data?.filter((item) => {
    if (searchText === "") {
      return item;
    } else if (item.name.toLowerCase().includes(searchText.toLowerCase())) {
      return item;
    }
  }).length;
  return (
    <div>
      {" "}
      <Form onSubmit={handleSubmit(handleNext)}>
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            fontSize: "14px",
            border: " 1px solid #F4F5F5",
            // paddingBottom: "66px",
          }}
        >
          {/* {addSettingPage == false ? ( */}
          <div>
            <div
              className="px-3 pb-3"
              style={{ borderBottom: "1px solid #F0F0F0" }}
            >
              <div className="px-2 pt-3 d-flex">
                <div>
                  <div className="d-flex align-items-center">
                    <H2 className="mb-0 ">1. Settings</H2>
                    <div className="ps-2">
                      {" "}
                      <CustomToolTip text="Settings" />{" "}
                    </div>
                  </div>

                  <div className="created-by">
                    {modifiedData?.map((item, i) => (
                      <>
                        {item.modified_by && item.modified_on ? (
                          <div key={i} className=" fs-12 created-by">
                            Last updated by {item.modified_by},{item.modifiedOn}
                          </div>
                        ) : (
                          <>
                            {item.created_by && item.created_on ? (
                              <div className="created-by">
                                Created by {item.created_by},{item.createdOn}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </>
                    ))}
                  </div>
                  {subHeading && (
                    <span className="created-by">{subHeading}</span>
                  )}
                </div>
              </div>

              <div className="px-2 created-by"></div>
            </div>
            <div className="px-3 pt-3">
              <div className="px-2 pt-2 d-flex form-label-title">
                <div>Package title</div>
              </div>

              <div className="px-2 mt-1 light-font-text grey-text">
                Name an Package title that can be used to recognize the package
              </div>

              <Row className="px-2 mt-4">
                <Col lg={7} className="pb-1">
                  <div>
                    <Controller
                      name="packageTitle"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange } }) => (
                        // <input
                        //   value={title}
                        //   type="text"
                        //   placeholder="Package title"
                        //   id="packageTitleId"
                        //   {...register("packageTitle", {
                        //     required: true,
                        //   })}
                        //   className="ms-0"
                        //   onChange={(e) => {
                        //     onChange(e);
                        //     setValue("packageTitle", e?.target.value);
                        //     handleTitleChange(e);
                        //   }}
                        // />
                        <InputField
                          id={"packageTitleId"}
                          value={title}
                          type={"text"}
                          placeholder={"Package title"}
                          errorscope={false}
                          iserror={errors?.packageTitle}
                          onChange={(e) => {
                            onChange(e);
                            setValue("packageTitle", e?.target.value);
                            handleTitleChange(e);
                          }}
                        />
                      )}
                    />
                    {/* <label htmlFor="packageTitleId">Package title</label> */}
                  </div>
                  {errors?.packageTitle?.type === "required" && (
                    <p className="error-msg">{REQUIRED_ERR}</p>
                  )}
                </Col>
              </Row>
            </div>
            <hr className="mt-4 hr"></hr>
            {showDropdown && (
              <>
                <div className="px-3">
                  <div className="px-2 pt-2 d-flex form-label-title">
                    <div>{dropdownHeading}</div>
                  </div>

                  <div className="px-2 mt-1  light-font-text grey-text">
                    {dropDownSubHeading}
                  </div>

                  <Row className="px-2 pb-1 mt-3">
                    <Col lg={5}>
                      <Controller
                        {...register("sortMedia")}
                        control={control}
                        rules={{ required: showDropdown ? true : false }}
                        render={({ field: { value, onChange } }) => (
                          <ReactSelect
                            placeholder={dropDownLabel}
                            options={dropDownData?.map((e) => ({
                              value: e?.Id,
                              label: e?.Text,
                            }))}
                            disabled={disabledDropDownValue}
                            value={value}
                            // {...field}
                            // isDisabled: editflag == true,
                            clearable={false}
                            onChange={(e) => {
                              onChange(e);
                              setDropDownValue(e?.value);
                              clearErrors("sortMedia");
                            }}

                            // error={errors.zip}
                          />
                        )}
                      />
                      {errors?.sortMedia?.type === "required" && (
                        <p className="error-msg">{REQUIRED_ERR}</p>
                      )}
                    </Col>
                  </Row>
                </div>
                <hr className="mt-4 hr" />
              </>
            )}
            {dropDownValue == 3 && packageType === "8" && (
              <>
                <div className="px-3">
                  <div className="px-2 pt-2 d-flex form-label-title">
                    <div>Sub Category</div>
                  </div>

                  <div className="px-2 mt-1  light-font-text grey-text">
                    Choose which sub category the text field should be
                    connecting to.
                  </div>

                  <Row className="px-2 pb-1 mt-3">
                    <Col lg={5}>
                      <Controller
                        {...register("textSubCategory")}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <ReactSelect
                            placeholder="Sub category"
                            iserror={errors?.textSubCategory}
                            errorScope={false}
                            options={subDropDownData?.map((e) => ({
                              value: e.Id,
                              label: e.Text,
                            }))}
                            value={field.value}
                            {...field}
                            clearable={false}
                            onChange={(e) => {
                              field.onChange(e);
                              setSubDropDownValue(e?.value);
                            }}

                            // error={errors.zip}
                          />
                        )}
                      />
                      {errors?.textSubCategory &&
                        errors?.textSubCategory?.type === "required" && (
                          <p className="error-msg">{REQUIRED_ERR}</p>
                        )}
                    </Col>
                  </Row>
                </div>
                <hr className="mt-4 hr" />
              </>
            )}
            <div className="px-3 pt-3">
              <div className=" d-flex justify-content-between ">
                <div className="px-2  d-flex  flex-column">
                  <p className="mb-0 form-label-title">
                    Tags in use on package
                  </p>
                  {tagSubHeading && (
                    <span className="light-font-text fs-12 grey-text w-75">
                      {tagSubHeading}
                    </span>
                  )}
                </div>
                <div>
                  <ETButton
                    variant={"primary"}
                    buttonText="ADD TAG"
                    icon={true}
                    onClick={handleOpenAddTag}
                  />
                </div>
              </div>
              {/* AddTag Popup */}
              <div>
                <CustomPopup
                  // modalHeadingStyle={modalHeadingStyle}
                  style={{ position: "relative" }}
                  size="md    "
                  open={deletePopUp}
                  onHide={() => setDeletePopUp(false)}
                  title="Are you carrying out the trash?"
                  title_color="black"
                  enableCrossButton={false}
                  onCloseAction={() => {
                    setDeletePopUp(false);
                  }}
                  action_button_label="YES"
                  action_button_label_color="white"
                  action_button_color="#619130"
                  cancel_button={true}
                  cancel_button_bordercolor="grey"
                  cancel_button_label="NO"
                  cancel_button_label_color="black"
                  cancel_button_color="#fff"
                  footer_color={"#F9FAFA"}
                >
                  {packageType === "6" ? (
                    <div className="p-4">
                      <div className="fs-14 fw-300 text-left mb-3">
                        <p>Are you sure you want to delete the tag?</p>
                        {/* <p>That will conflict with.....</p> */}
                      </div>
                    </div>
                  ) : (
                    <>
                      {packageType === "Signing" ? (
                        <div className="p-4">
                          <div className="fs-14 fw-300 text-left mb-3">
                            <p>Are you sure you want to delete the tag?</p>
                            {/* <p>That will conflict with.....</p> */}
                          </div>
                        </div>
                      ) : (
                        <>
                          {packageType === "5" ? (
                            <div className="p-4">
                              <div className="fs-14 fw-300 text-left mb-3">
                                <p>Are you sure you want to delete the tag?</p>
                                {/* <p>That will conflict with.....</p> */}
                              </div>
                            </div>
                          ) : (
                            <>
                              {packageType === "8" ? (
                                <div className="p-4">
                                  <div className="fs-14 fw-300 text-left mb-3">
                                    <p>
                                      Are you sure you want to delete the tag?
                                    </p>
                                    {/* <p>That will conflict with.....</p> */}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {packageType === "4" ? (
                    <div className="p-4">
                      <div className="fs-14 fw-300 text-left mb-3">
                        <p>Are you sure you want to delete the tag?</p>
                        {/* <p>That will conflict with.....</p> */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
                    <ETButton
                      variant={"white"}
                      buttonText="COLD FEET"
                      onClick={() => setDeletePopUp(false)}
                    />

                    <ETButton
                      variant={"primary"}
                      buttonText="I'M SURE"
                      onClick={() => handleDelete(editFacilityId)}
                    />
                  </div>
                </CustomPopup>
                <CustomPopup
                  // modalHeadingStyle={modalHeadingStyle}
                  // style={{ position: "relative" }}
                  size="md"
                  open={openPropertyTag}
                  onHide={() => setOpenPropertyTag(false)}
                  title="Add tag"
                  title_color="black"
                  titleTooltipText="Need Help About Tags"
                  titleTooltip={true}
                  enableCrossButton={false}
                  onCloseAction={() => {
                    setOpenPropertyTag(false);
                    alert("Action Peformed Successfuly.");
                  }}
                >
                  <div>
                    <Row className="p-4">
                      <Col>
                        <FilterComponent
                          filtertext={searchText}
                          handleSearch={(e) => setSearchText(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <hr className="p-0 m-0 hr" />
                    <div className="d-flex justify-content-between px-4 pt-3 pb-2 mb-1 ">
                      <div className="form-label-title fs-14 ">
                        {tagsByPropertyIdData?.data?.length} Tags
                      </div>
                      <div>
                        <button
                          onClick={HandleTagReset}
                          className=" fs-12 form-label-title"
                          style={{
                            height: "23px",
                            width: "56px",
                            borderRadius: "4px",
                            backgroundColor: "rgba(240, 240, 240, 1)",
                            border: "1px solid rgba(209, 209, 209, 1)",
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                    <div
                      className="ps-3 py-2 pe-2 scrollable-container-y"
                      style={{
                        height: "320px",
                        marginRight: "4px",
                      }}
                    >
                      {searchData == 0 ? (
                        <div
                          className="fs-12 light-font-text grey-text"
                          style={{ textAlign: "center" }}
                        >
                          You do not have any tag yet.
                        </div>
                      ) : (
                        <>
                          {tagsByPropertyIdData?.data
                            ?.filter((item) => {
                              if (searchText === "") {
                                return item;
                              } else if (
                                item.name
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              ) {
                                return item;
                              }
                            })

                            ?.map((data, index) => (
                              <PrimarySelectedTag
                                mode={selected?.includes(String(data?.tag_id))}
                                style={
                                  {
                                    // backgroundColor:
                                    //   selected?.includes(String(data?.tag_id)) &&
                                    //   "rgba(97, 145, 48, 0.08)",
                                    // border: selected?.includes(
                                    //   String(data?.tag_id)
                                    // )
                                    //   ? "1px solid #619130"
                                    //   : "1px solid #D1D1D1",
                                    // borderRadius: "8px",
                                    // height: "44px",
                                  }
                                }
                                id={index}
                                key={index}
                                className="d-flex mb-2 justify-content-between align-items-center light-font-text  ps-3 pe-2  cursor-pointer mx-2"
                              >
                                <div>{data?.name}</div>
                                <div>
                                  {/* {console.log(selected?.includes(
                                    String(data?.tag_id)
                                  ), "checked")} */}
                                  <CustomCheckBox
                                    // checked={tagsByPropertyIdData[data?.tag_id]}
                                    id={data?.tag_id?.toString()}
                                    checked={selected?.includes(
                                      String(data?.tag_id)
                                    )}
                                    value={data?.tag_id}
                                    onChange={(e) => {
                                      handleClick(e, data);
                                      setSelectedId(data?.tag_id);
                                      // setMediaStyle(!mediaStyle);
                                      // setChecked(e.target.value);
                                    }}
                                  />
                                </div>
                              </PrimarySelectedTag>
                            ))}
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className="py-2 px-3 d-flex  justify-content-between  justify-content-center footer-section-button-container"
                    style={{
                      zIndex: "5",
                    }}
                  >
                    <ETButton
                      variant={"white"}
                      buttonText="CANCEL"
                      onClick={() => handleCancelTag()}
                    />

                    <ETButton
                      variant={"primary"}
                      buttonText="ADD"
                      onClick={handleAddTags}
                      disabled={
                        selected?.length == 0 && selectedData?.length == 0
                          ? true
                          : false
                      }
                    />
                  </div>
                </CustomPopup>
              </div>

              <div className="mt-3 mb-4 pb-2 px-2">
                <MRTTheme
                  colorVariantsForTableData={["odd"]}
                  table={
                    <MaterialReactTable
                      data={tagRowData?.length ? tagRowData : dummyData}
                      icons={{ ...SortIcon }}
                      columns={columns}
                      state={{ tagsByPropertyIdDataIsLoading: !tagRowData }}
                      // data={data}
                      // customStyles={customStyles}
                      enableRowActions={true}
                      enableTopToolbar={false}
                      enableColumnActions={false}
                      enableBottomToolbar={false}
                      hideSortIcon={false}
                      positionActionsColumn="last"
                      // enableSorting={false}
                      renderRowActions={({ row }) => (
                        <div>
                          {row?.original?.tag_id &&
                          row?.original?.name &&
                          row?.original?.unit_count !== "-" ? (
                            <button
                              type="button"
                              className="added-document-delete-btn"
                              onClick={() => {
                                setDeletePopUp(!deletePopUp);
                                setEditFacilityId(row.original.tag_id);
                              }}
                            >
                              <FontAwesomeIcon
                                icon="fa-regular fa-trash"
                                className="fs-6"
                              />
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      displayColumnDefOptions={{
                        "mrt-row-actions": {
                          header: "",
                          size: 50,
                        },
                      }}
                      // striped
                      muiTableContainerProps={{
                        sx: {
                          padding: "0px !important",
                        },
                      }}
                    />
                  }
                />

                {/* </MRTTheme> */}
              </div>
              {tagRowData?.length == 0 && (
                <div className="d-flex justify-content-center w-100 fs-12 mt-1 grey-text">
                  <div>
                    No tags are added to the package. That’s a must before you
                    can continue.{" "}
                  </div>{" "}
                </div>
              )}
            </div>

            {showActiveInactive && (
              <>
                <hr className="mt-4 hr" />
                <div className="px-3">
                  <div className=" d-flex justify-content-between">
                    <div className="px-2 pt-2 d-flex fw-600">
                      Active / Inactive
                    </div>
                  </div>
                  <div
                    className="px-2 mt-1 light-font-text  grey-text "
                    style={{
                      lineHeight: "22px",
                    }}
                  >
                    Select the toggle to active/inactive whether the package is
                    shown in the system
                  </div>

                  <div className="d-flex ps-2 mt-2 mb-5 gap-2">
                    <div style={{ paddingTop: "6px" }}>Status</div>
                    <div>
                      <Toggle
                        checked={active}
                        onChange={(e) => setActive(e.target.checked)}
                        id={"addeditpackage1"}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div
              className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
              style={{
                backgroundColor: "#F9FAFA",
                borderRadius: " 0px 0px 8px 8px",
                borderTop: "1px solid #F0F0F0",
              }}
            >
              <ETButton
                variant={"white"}
                buttonText="CANCEL"
                onClick={() => setCancelPopup(true)}
              />
              {/* <input
                type="button"
                value="CANCEL"
                onClick={() => }
                className="fw-500"
                style={{
                  backgroundColor: "#fff",
                  width: "fit-content",
                  alignItems: "center",
                  display: "flex",
                  gap: "7px",
                  fontFamily: "Cerebri sans",
                  fontWeight: "600",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                  textAlign: "center",
                  padding: "10px 15px ",
                  fontSize: "14px",
                  color: "black",
                }}
              /> */}

              <div className="d-flex gap-2">
                {saveAsDraft && (
                  <Button className="border text-dark px-3 py-1 bg-white shadow-none rounded-5 fs-14 fw-600">
                    SAVE AS DRAFT
                  </Button>
                )}
                <ETButton
                  disabled={tagRowData?.length == 0 || disableNextButton}
                  variant={"primary"}
                  buttonText={"NEXT"}
                  width="fit-content"
                  type={"submit"}
                  // onClick={() => {
                  // 	handleNext();
                  // }}
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddEditPackage;

AddEditPackage.defaultProps = {
  showActiveInactive: true,
  showDropdown: false,
  saveAsDraft: false,
  dropdownHeading: "Category",
  subDropDownHeading: "Sub Category",

  disabled: false,

  dropDownSubHeading:
    "Choose which category the text field should be connecting to.",
  subDropDownSubHeading:
    "Choose which sub category the text field should be connecting to.",

  dropDownLabel: "Category",
  subDropDownLabel: "Sub Category",
};
