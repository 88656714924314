import React from "react";
import ReservatationDetails from "../customers/processpopups/ReservatationDetails";
import ScreeningProcessDetails from "../customers/processpopups/ScreeningProcessDetails";
import RentalContract from "../customers/processpopups/RentalContract";
import DepositDetails from "../customers/processpopups/DepositDetails";
import LeasedDetails from "../customers/processpopups/LeasedDetails";
import MovingInDetails from "../customers/processpopups/MovingInDetails";
import TerminationDetails from "../customers/processpopups/TerminationDetails";
import MovingOutDetails from "../customers/processpopups/MovingOutDetails";
const ActorsOverview = () => {
  return (
    <>
      <div>ActorsOverview</div>

      {/* <div
        className="my-3"
        style={{ background: "white", borderRadius: "8px" }}
      >
        <ReservatationDetails />
      </div>
      <div
        className="my-3"
        style={{ background: "white", borderRadius: "8px" }}
      >
        <ScreeningProcessDetails />
      </div>
      <div
        className="my-3"
        style={{ background: "white", borderRadius: "8px" }}
      >
        <RentalContract />
      </div>
      <div
        className="my-3"
        style={{ background: "white", borderRadius: "8px" }}
      >
        <DepositDetails />
      </div>
      <div
        className="my-3"
        style={{ background: "white", borderRadius: "8px" }}
      >
        <LeasedDetails />
      </div>
      <div
        className="my-3"
        style={{ background: "white", borderRadius: "8px" }}
      >
        <MovingInDetails />
      </div>
      <div
        className="my-3"
        style={{ background: "white", borderRadius: "8px" }}
      >
        <TerminationDetails/>
      </div>
      <div
        className="my-3"
        style={{ background: "white", borderRadius: "8px" }}
      >
        <MovingOutDetails/>
      </div> */}
    </>
  );
};

export default ActorsOverview;
