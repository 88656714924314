export const Data = [
  // {
  //   id: "1",
  //   InUse: "1",
  //   Question: "Google",
  //   Type: "System default",
  //   Created: "-",
  //   CreatedBy: "System default",
  // },
  // {
  //   id: "2",
  //   InUse: "1",
  //   Question: "Facebook",
  //   Type: "System default",
  //   Created: "-",
  //   CreatedBy: "System default",
  // },
  // {
  //   id: "3",
  //   InUse: "1",
  //   Question: "Instagram",
  //   Type: "System default",
  //   Created: "-",
  //   CreatedBy: "System default",
  // },
  // {
  //   id: "4",
  //   InUse: "",
  //   Question: "Newsletter",
  //   Type: "System default",
  //   Created: "-",
  //   CreatedBy: "System default",
  // },
  // {
  //   id: "5",
  //   InUse: "1",
  //   Question: "Walked by",
  //   Type: "System default",
  //   Created: "-",
  //   CreatedBy: "System default",
  // },
  // {
  //   id: "6",
  //   InUse: "1",
  //   Question: "Studio newletter, week 14",
  //   Type: "Manually added",
  //   Created: "30 Sep, 2023",
  //   CreatedBy: "Thomas Hoeg Mogensen",
  // },
];

export const DemographicsData = [
  // {
  //   id: "1",
  //   InUse: "1",
  //   Question: "Civil status",
  //   Choices: "Yes",
  //   Type: "System default",
  //   Created: "-",
  //   CreatedBy: "System default",
  // },
  // {
  //   id: "2",
  //   InUse: "1",
  //   Question: "Age",
  //   Choices: "Yes",
  //   Type: "System default",
  //   Created: "-",
  //   CreatedBy: "System default",
  // },
  // {
  //   id: "3",
  //   InUse: "1",
  //   Question: "Occupation",
  //   Choices: "Yes",
  //   Type: "System default",
  //   Created: "-",
  //   CreatedBy: "System default",
  // },
  // {
  //   id: "4",
  //   InUse: "",
  //   Question: "Household size",
  //   Choices: "Yes",
  //   Type: "System default",
  //   Created: "-",
  //   CreatedBy: "System default",
  // },
  // {
  //   id: "5",
  //   InUse: "1",
  //   Question: "Student",
  //   Choices: "Yes",
  //   Type: "Manually added",
  //   Created: "30 Sep, 2021",
  //   CreatedBy: "Thomas Hoeg Mongensen",
  // },
];

export const followUpStatusData = [
  // {
  //   id : 1,
  //   inUse : "0",
  //   status : "In dialog",
  //   type : "System default",
  //   created : "-",
  //   created_by : "-",
  //   level : "-"
  // },
  // {
  //   id : 2,
  //   inUse : "0",
  //   status : "Waiting for information",
  //   type : "System default",
  //   created : "-",
  //   created_by : "-",
  //   level : "-"
  // },
  // {
  //   id : 3,
  //   inUse : "1",
  //   status : "Contact",
  //   type : "System default",
  //   created : "-",
  //   created_by : "-",
  //   level : "-"
  // },
  // {
  //   id : 4,
  //   inUse : "0",
  //   status : "Status 4",
  //   type : "System default",
  //   created : "-",
  //   created_by : "-",
  //   level : "-"
  // },
  // {
  //   id : 5,
  //   inUse : "1",
  //   status : "Call soon",
  //   type : "Manually added",
  //   created : "30 Sep, 2021",
  //   created_by : "Thomas Høeg Mogensen",
  //   level : "Organisation"
  // },
];

export const notIntrestedData = [
  // {
  //   id : 1,
  //   inUse : "1",
  //   reason : "Too expensive",
  //   type: "System default",
  //   created : "-",
  //   created_by : "-",
  //   level : "-",
  //   public : "1"
  // },
  // {
  //   id : 2,
  //   inUse : "0",
  //   reason : "Not enough space",
  //   type: "System default",
  //   created : "-",
  //   created_by : "-",
  //   level : "-",
  //   public : "0"
  // },
  // {
  //   id : 3,
  //   inUse : "0",
  //   reason : "Not the right neighbourhood",
  //   type: "Manually added",
  //   created_by : "30 Sep, 2023",
  //   created : "Thomas Høeg Mogensen",
  //   level : "Organisation",
  //   public : "0"
  // }
];
export const cancelReasonsData = [
  // {
  //   id : 1,
  //   inUse : "1",
  //   reason : "Too expensive",
  //   type: "System default",
  //   created : "-",
  //   created_by : "-",
  //   level : "-",
  //   public : "1"
  // },
  // {
  //   id : 2,
  //   inUse : "0",
  //   reason : "Not interested anyway",
  //   type: "System default",
  //   created : "-",
  //   created_by : "-",
  //   level : "-",
  //   public : "0"
  // },
  // {
  //   id : 3,
  //   inUse : "0",
  //   reason : "I’m unavailable at that time",
  //   type: "Manually added",
  //   created_by : "30 Sep, 2023",
  //   created : "Thomas Høeg Mogensen",
  //   level : "Organisation",
  //   public : "0"
  // }
];
