import React from "react";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import Toggle from "../../../../Components/Switch/Switch";
import ETButton from "../../../../Components/Buttons/ETButton";

const CustomizeColumns = ({ setCustomizeColumnsShow }) => {
  return (
    <div>
      <div className="px-3 pt-3 pb-4">
        <CustomCheckBox label={"Unit no."} />
        <div className="px-4">
          <Row className="px-2">
            <Col>
              <ReactSelect placeholder="unit_id" />
            </Col>
            <Col>
              <ReactSelect placeholder="Unit extra ID" />
            </Col>
          </Row>
        </div>
      </div>
      <hr className="my-0"></hr>
      <div className="px-3 pt-3 pb-2">
        <CustomCheckBox label={"Address"} />
        <div className="px-4 pb-3">
          <Row className="px-2">
            <Col>
              <ReactSelect placeholder="Street" />
            </Col>
            <Col>
              <ReactSelect placeholder="House no." />
            </Col>
          </Row>
        </div>
        <div className="px-4 pb-3">
          <Row className="px-2">
            <Col>
              <ReactSelect placeholder="Floor" />
            </Col>
            <Col>
              <ReactSelect placeholder="Side/door" />
            </Col>
          </Row>
        </div>
        <div className="px-4 pb-3">
          <Row className="px-2">
            <Col>
              <ReactSelect placeholder="Zip code" />
            </Col>
            <Col>
              <ReactSelect placeholder="City" />
            </Col>
          </Row>
        </div>
      </div>
      <hr className="my-0"></hr>
      <div className="px-3 pt-3 pb-2">
        <CustomCheckBox label={"Unit details"} />
        <div className="px-4 pb-2">
          <Row className="px-2">
            <Col>
              <ReactSelect placeholder="No. of rooms" />
            </Col>
            <Col>
              <ReactSelect placeholder="Floor" />
            </Col>
          </Row>
        </div>
        <div className="px-4 pb-3">
          <Row className="px-2">
            <Col>
              <ReactSelect placeholder="Area" />
            </Col>
            <Col>
              <div className="pb-2 d-flex gap-2 align-items-center">
                <Toggle id={"334"} />
                <div className="light-font-text">Show outdoor facilities</div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <hr className="my-0"></hr>
      <div className="px-3 pt-3 pb-4">
        <CustomCheckBox label={"Rent"} />
        <div className="px-4">
          <Row className="px-2">
            <Col>
              <ReactSelect placeholder="Rent/mthl." />
            </Col>
            <Col>
              <ReactSelect placeholder="Rent/m2" />
            </Col>
          </Row>
        </div>
      </div>
      <hr className="my-0"></hr>

      <div className="px-3 pt-3 pb-4">
        <CustomCheckBox label={"Contract"} />
        <div className="px-4">
          <Row className="">
            <Col>
              <div className="pb-2 d-flex gap-2 align-items-center">
                <Toggle id={"335"} />
                <div className="light-font-text">Show primary tenant</div>
              </div>
            </Col>
            <Col>
              <div className="pb-2 d-flex gap-2 align-items-center">
                <Toggle id={"336"} />
                <div className="light-font-text">Show takeover date</div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
        <ETButton
          variant={"white"}
          buttonText="CANCEL"
          onClick={() => setCustomizeColumnsShow(false)}
        />
        <ETButton
          variant={"primary"}
          buttonText="SAVE"
          onClick={() => setCustomizeColumnsShow(false)}
        />
      </div>
    </div>
  );
};

export default CustomizeColumns;
