import React from "react";
import { Col, Row } from "react-bootstrap";
import Toggle from "../../../../Components/Switch/Switch";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { H4 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";

const Options = () => {
  return (
    <div>
      {/* Domain (Login page) */}
      <div className="px-4 pt-1 pb-5 border-bottom">
        <div className="form-label-title">Domain (Login page)</div>
        <div className="pt-2 d-flex align-items-center gap-2">
          <Toggle /> <span className="paragraph1-light">Use own domain</span>
        </div>
        <div className="light-font-text pt-3 pb-4 ">
          <Row>
            <Col xl={7} lg={8}>
              <div>
                Define what the domain for the MyPage is. The domain is used for
                login to Mypage.
              </div>
              E.g. mypage.mydomain.com
            </Col>
          </Row>
        </div>
        <Row className="pb-5">
          <Col lg={4} md={6}>
            <div className="form-input ">
              <input
                type="text"
                placeholder="Domain"
                id="Domain_address"
                maxLength={30}
              />
              <label htmlFor="Domain_address">Domain</label>
            </div>
          </Col>
        </Row>
      </div>

      {/*Desire to move function  */}
      <div className="p-4 border-bottom">
        <div className="form-label-title py-2 ">Desire to move function</div>
        <Row>
          <Col xl={3} lg={4} md={6}>
            <ReactSelect placeholder={"Desire to move function"} />
          </Col>
        </Row>

        <div className="form-label-title pt-4 pb-2 ">
          Report a problem function
        </div>
        <Row>
          <Col xl={3} lg={4} md={6}>
            <ReactSelect placeholder={"Report a problem function"} />
          </Col>
        </Row>
      </div>

      {/* Choose languages for form */}
      <div className="px-4 pt-3 pb-5">
        <div className="d-flex align-items-center gap-2 py-2">
          <H4>Choose languages for form </H4>
          <CustomToolTip text="Choose languages for form" />
        </div>
        <div className="light-font-text  pb-3 ">
          <Row>
            <Col xl={7} lg={8}>
              Select which languages this screening form should be set up in.
              This allows you to define different languages for all questions in
              the screening form later in the flow.
            </Col>
          </Row>
        </div>
        <Row className="pb-5">
          <Col lg={4} md={6}>
            <ReactSelect placeholder={"Languages"} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Options;
