import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import ETButton from "../../../../Components/Buttons/ETButton";
import GroupText from "../../../../Components/GroupText/GroupText";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import {
  useGetUnitDetailsByUnitId,
  useGetUnitEconomyDetailsByUnitId,
} from "../../../../hooks/queries/useUnitQueries";
import {
  useUnitId,
  useUnitOverview,
} from "../../../../hooks/stores/useUnitStore";
import { useGetUserByDivisionId } from "../../../../hooks/queries/useUserQueries";
import { usePropertyOverview } from "../../../../hooks/stores/usePropertyStore";
import { useReservationStore } from "../../../../hooks/stores/useReservationStore";
import CustomPopup from "../../../../Components/CustomPopup";
import {
  useGetPropertySettingDetails,
  useGetPropertyTypes,
} from "../../../../hooks/queries/usePropertyQueries";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";
import { useGetContractType } from "../../../../hooks/queries/useActorQueries";

const paymentFrequencyDropdownData = [
  {
    value: 1,
    label: "Monthly",
    shortName: "mthly",
  },
  {
    value: 3,
    label: "Quarterly",
    shortName: "qtrly",
  },
];

const ReservationFormTwo = ({
  setReservationWizard,
  propId,
  setReservationDetailPage,
  actorId,
  unitId,
  handleCreateReservation,
  PostActorCreateReservationIsLoading,
}) => {
  const navigate = useNavigate();

  //--------------Constants---------------//
  const todaysDate = new Date();
  //----------------States---------------//
  const [minMoveInDate, setMinMoveInDate] = useState(
    moment(todaysDate).add(1, "d").format("YYYY-MM-DD")
  );
  const [paymentFrequency, setPaymentFrequency] = useState("");
  const [currencyId, setCurrencyId] = useState("");
  const [cancelPopup, setCancelPopup] = useState(false);

  //---------------zuzstand--------------//
  const { UnitId } = useUnitId((state) => state);
  const unitOverview = useUnitOverview((state) => state.unitOverview);
  const propOverview = usePropertyOverview((state) => state.propOverview);
  const { statusIdsForReservation } = useReservationStore((state) => state);
  const { userDetails } = useUserDetails((state) => state);
  //-------------------react query-----------------------//
  const { data: getEconomyDetailsByUnitIdData } =
    useGetUnitEconomyDetailsByUnitId(unitId);
  const { data: getPropertySettingsData } =
    useGetPropertySettingDetails(propId);
  const { data: getUnitDetailsByUnitIdData } =
    useGetUnitDetailsByUnitId(unitId);
  const { data: GetContractTypeData } = useGetContractType();

  //---------------------------react query----------------------//
  const { data: UserByDivisionIdData } = useGetUserByDivisionId(
    userDetails?.division_id
  );

  //------------------validation-------------------------//
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    clearErrors,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      listingPrice: "",
      calculatedDeposit: "",
      customPrepaidRent: "",
      moveIndate: "",
      reservationStartdate: "",
      reservationExpireddate: "",
    },
    criteriaMode: "all",
  });

  useEffect(() => {
    setValue("managedBy", {
      value: userDetails?.user_id,
      label: UserByDivisionIdData?.data?.filter(
        (val) => val?.Id == userDetails?.user_id
      )?.[0]?.Text,
    });
    return () => {};
  }, [UserByDivisionIdData]);

  useEffect(() => {
    setValue(
      "listingPrice",
      Number(getEconomyDetailsByUnitIdData?.data?.[0]?.rent)?.toFixed(2)
    );

    setValue(
      "calculatedDeposit",
      (
        (Number(getEconomyDetailsByUnitIdData?.data?.[0]?.rent) /
          Number(getEconomyDetailsByUnitIdData?.data?.[0]?.prepaid_months)) *
        Number(getEconomyDetailsByUnitIdData?.data?.[0]?.deposit_months)
      )?.toFixed(2) || 0
    );
    setValue(
      "customPrepaidRent",
      (
        (Number(getEconomyDetailsByUnitIdData?.data?.[0]?.rent) /
          Number(getEconomyDetailsByUnitIdData?.data?.[0]?.prepaid_months)) *
        Number(getEconomyDetailsByUnitIdData?.data?.[0]?.prepaid_months)
      )?.toFixed(2) || 0
    );
    setValue("paymentFrequency", {
      value: getEconomyDetailsByUnitIdData?.data?.[0]?.payment_frequency,
      label: paymentFrequencyDropdownData.filter(
        (x) =>
          x.value ==
            getEconomyDetailsByUnitIdData?.data?.[0]?.payment_frequency &&
          x?.label
      )?.[0]?.label,
    });
    setPaymentFrequency(
      paymentFrequencyDropdownData.filter(
        (x) =>
          x.value ==
            getEconomyDetailsByUnitIdData?.data?.[0]?.payment_frequency &&
          x?.label
      )?.[0]?.shortName
    );
    setCurrencyId(getEconomyDetailsByUnitIdData?.data?.[0]?.currency_id);
    setValue("contractType", {
      value: getEconomyDetailsByUnitIdData?.data?.[0]?.default_deal_type_id,
      label: GetContractTypeData?.data?.filter(
        (val) =>
          val?.Id ==
          getEconomyDetailsByUnitIdData?.data?.[0]?.default_deal_type_id
      )?.[0]?.Text,
    });
    setValue(
      "contratCreated",
      getEconomyDetailsByUnitIdData?.data?.[0]?.current_date
    );
    setValue(
      "expectedTerminationDate",
      getEconomyDetailsByUnitIdData?.data?.[0]?.expected_termination_date
    );
  }, [getEconomyDetailsByUnitIdData, GetContractTypeData]);

  useEffect(() => {
    // calculating reservation expiry date based on property default reservation duration //
    if (getPropertySettingsData?.data) {
      const expiryDuration =
        getPropertySettingsData?.data?.[0]?.reservation_duration_default;
      const defaultExpiryDate = moment()
        .add(expiryDuration, "d")
        .format("YYYY-MM-DD");
      setValue("reservationExpireddate", defaultExpiryDate);
    }

    // calculating reservation moving in  date based on unit's default vacancy date //
    if (getUnitDetailsByUnitIdData?.data) {
      setMinMoveInDate(getUnitDetailsByUnitIdData?.data?.[0]?.vacancy_date);
      if (
        getUnitDetailsByUnitIdData?.data?.[0]?.vacancy_date <=
        moment(todaysDate)?.format("YYYY-MM-DD")
      ) {
        const defaultMovingInDate = moment()
          .add(1, "M")
          .startOf("month")
          .format("YYYY-MM-DD");
        setValue("moveIndate", defaultMovingInDate);
      } else {
        const defaultMovingInDate = moment(
          getUnitDetailsByUnitIdData?.data?.[0]?.vacancy_date
        )
          .add(1, "M")
          .startOf("month")
          .format("YYYY-MM-DD");
        setValue("moveIndate", defaultMovingInDate);
      }
    }

    return () => {};
  }, [getPropertySettingsData, getUnitDetailsByUnitIdData]);

  useEffect(() => {
    if (getValues("moveIndate") == moment(todaysDate).format("YYYY-MM-DD")) {
      setError("moveIndate", {
        message: "Move in date cannot be same as today's date",
      });
    }
    // else if (getValues("reservationStartdate") >= getValues("moveIndate")) {
    //   setError("moveIndate", {
    //     message: "Move in date should be greater than Reservation start date",
    //   });
    // }
    else {
      clearErrors("moveIndate");
    }
  }, [getValues("moveIndate"), getValues("reservationExpireddate")]);

  //------------------handling listing price change---------------//
  const handleListingPriceChange = (e) => {
    setValue(
      "calculatedDeposit",
      (
        (Number(e.target.value) / Number(watch("paymentFrequency")?.value)) *
        Number(getEconomyDetailsByUnitIdData?.data?.[0]?.deposit_months)
      )?.toFixed(2)
    );
    setValue(
      "customPrepaidRent",
      (
        (Number(e.target.value) / Number(watch("paymentFrequency")?.value)) *
        Number(getEconomyDetailsByUnitIdData?.data?.[0]?.prepaid_months)
      )?.toFixed(2)
    );
  };

  //-----------------handling payment frequency drop down--------------------//
  const handlePaymentFrequencyChange = (e) => {
    setValue(
      "listingPrice",
      e.value == 1
        ? (Number(watch("listingPrice")) / 3)?.toFixed(2)
        : (Number(watch("listingPrice")) * 3)?.toFixed(2)
    );
    setValue(
      "calculatedDeposit",
      (
        (watch("listingPrice") / Number(e.value)) *
        Number(getEconomyDetailsByUnitIdData?.data?.[0]?.deposit_months)
      )?.toFixed(2) || 0
    );
    setValue(
      "customPrepaidRent",
      (
        (watch("listingPrice") / Number(e.value)) *
        Number(getEconomyDetailsByUnitIdData?.data?.[0]?.prepaid_months)
      )?.toFixed(2) || 0
    );
    setPaymentFrequency(e.shortName);
  };

  return (
    <div className="p-4 mt-4 radius-8 containerStyleColor">
      <div
        className="px-0"
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          border: "1px solid #F4F5F5",
        }}
      >
        {" "}
        <div className="pt-4 pb-3 px-4 d-sm-flex justify-content-between ">
          <div
            className="d-flex justify-content-center form-label-title"
            style={{
              alignItems: "center",
            }}
          >
            <div className="d-flex gap-2">
              <div>Reservation details </div>
              <CustomToolTip height="12px" text="Reservation details" />
            </div>
          </div>

          <div>
            <div style={{ fontSize: "14px", fontWeight: "300", color: "#888" }}>
              {propOverview?.[0]?.propertyName} | {unitOverview?.[0]?.Address}
            </div>
          </div>
        </div>
        <hr className="hr mt-0 mb-0" />
        <Form onSubmit={handleSubmit(handleCreateReservation)}>
          <div className="mx-4 mt-4 mb-1 form-label-title">Economy</div>
          <div className="px-4">
            <Row>
              <Col>
                <div className="mt-3 mb-1 form-label-title">Contract type</div>
                <div className="">
                  <Row className=" mt-2 mb-3">
                    <Col xl={3} lg={4} md={6} className="my-2">
                      <Controller
                        control={control}
                        {...register("contractType")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <ReactSelect
                            placeholder={"Contract type"}
                            // innerLabel={false}
                            options={GetContractTypeData?.data?.map((el) => ({
                              value: el?.Id,
                              label: el?.Text,
                              isDisabled: value?.value == el?.Id,
                            }))}
                            value={value}
                            onChange={(e) => {
                              // handleZipCode(e);
                              onChange(e);
                              clearErrors("contractType");
                            }}
                            clearable={false}
                          />
                        )}
                      />
                      {errors?.contractType && (
                        <p className="error-msg">This field is required</p>
                      )}
                    </Col>
                  </Row>
                </div>
                <Row className=" mt-2 mb-3">
                  <Col xl={3} lg={4} md={6} className="my-2 ">
                    <Controller
                      control={control}
                      {...register("paymentFrequency")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <ReactSelect
                          placeholder={"Payment frequency"}
                          // innerLabel={false}
                          options={paymentFrequencyDropdownData?.map((x) => ({
                            value: x?.value,
                            label: x?.label,
                            shortName: x?.shortName,
                            isDisabled: value?.value == x?.value,
                          }))}
                          value={value}
                          onChange={(e) => {
                            handlePaymentFrequencyChange(e);
                            onChange(e);
                            clearErrors("paymentFrequency");
                          }}
                          clearable={false}
                        />
                      )}
                    />
                    {errors.paymentFrequency && (
                      <p className="error-msg">This field is required</p>
                    )}
                  </Col>
                  <Col xl={3} lg={4} md={6} className="my-2 pe-lg-0">
                    <Controller
                      control={control}
                      {...register("listingPrice")}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <GroupText
                          disabled={false}
                          lable="Listing price"
                          graylabel={`${currencyId}/${paymentFrequency}`}
                          textWidth="35%"
                          type="number"
                          value={value}
                          id={"Listing"}
                          onChange={(e) => {
                            handleListingPriceChange(e);
                            onChange(e);
                            clearErrors("listingPrice");
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className=" my-2">
                  <Col xl={3} lg={4} md={6} className="my-2 pe-lg-0">
                    <Controller
                      control={control}
                      {...register("calculatedDeposit")}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <GroupText
                          disabled={true}
                          lable="Calculated deposit"
                          graylabel={currencyId}
                          textWidth="35%"
                          type="number"
                          id={"Calculated deposit"}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("calculatedDeposit");
                          }}
                        />
                      )}
                    />
                  </Col>

                  <Col xl={3} lg={4} md={6} className="my-2  pe-lg-0">
                    <Controller
                      control={control}
                      {...register("customPrepaidRent")}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <GroupText
                          disabled={true}
                          lable="Custom prepaid rent"
                          graylabel={currencyId}
                          textWidth="35%"
                          type="number"
                          id="Custom prepaid rent"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("customPrepaidRent");
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <hr className="hr my-0" />
          <div className="px-4">
            <Row className=" my-2">
              <Col xl={3} lg={4} md={6} className="my-2">
                <div className=" pt-1 mb-3 form-label-title">Move in date</div>
                <Controller
                  control={control}
                  {...register("moveIndate")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <div className="form-input-group ">
                      <input
                        type="date"
                        id="Move-indate"
                        placeholder="Move-in date"
                        min={minMoveInDate}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          // handleMoveInDate(e);
                          clearErrors("moveIndate");
                        }}
                      />
                      <label htmlFor="Move-indate">Move-in date</label>
                    </div>
                  )}
                />
                {errors.moveIndate?.message && (
                  <p className="error-msg">{errors?.moveIndate?.message}</p>
                )}
                {/* <BasicDatePicker label="Move-in date" /> */}
              </Col>

              <Col xl={3} lg={4} md={6} className="my-2 ">
                <div className=" pt-1 mb-3 form-label-title">
                  Expected termination date
                </div>
                <Controller
                  control={control}
                  {...register("expectedTerminationDate")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <div className="form-input-group ">
                      <input
                        type="date"
                        id="expectedTerminationDate"
                        placeholder="Expected termination date"
                        min={moment(todaysDate).format("YYYY-MM-DD")}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("expectedTerminationDate");
                        }}
                      />
                      <label htmlFor="expectedTerminationDate">
                        Expected termination date
                      </label>
                    </div>
                  )}
                />
                {errors?.expectedTerminationDate && (
                  <p className="error-msg">This field is required</p>
                )}

                {/* <BasicDatePicker label="Reservation expired" /> */}
              </Col>
            </Row>
          </div>
          {/* </Col>
        </Row> */}{" "}
          <hr className="hr my-0" />
          <div className="px-4">
            <Row className=" my-2">
              <Col xl={3} lg={4} md={6} className="my-2">
                <div className=" pt-1 mb-3 form-label-title">
                  Contract created
                </div>
                <Controller
                  control={control}
                  {...register("contratCreated")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <div className="form-input-group ">
                      <input
                        type="date"
                        id="contratCreated"
                        placeholder="Contract created"
                        min={moment(todaysDate).format("YYYY-MM-DD")}
                        value={value}
                        disabled
                        onChange={(e) => {
                          onChange(e);
                          // handleMoveInDate(e);
                          clearErrors("contratCreated");
                        }}
                      />
                      <label htmlFor="contratCreated"> Contract created</label>
                    </div>
                  )}
                />
                {errors?.contratCreated?.message && (
                  <p className="error-msg">{errors?.contratCreated?.message}</p>
                )}
                {/* <BasicDatePicker label="Move-in date" /> */}
              </Col>

              <Col xl={3} lg={4} md={6} className="my-2 ">
                <div className=" pt-1 mb-3 form-label-title">
                  Deadline for the contract
                </div>
                <Controller
                  control={control}
                  {...register("reservationExpireddate")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <div className="form-input-group ">
                      <input
                        type="date"
                        id="reservationExpireddate"
                        placeholder="Deadline for the contract"
                        min={moment(todaysDate).format("YYYY-MM-DD")}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("reservationExpireddate");
                        }}
                      />
                      <label htmlFor="reservationExpireddate">
                        Contract created
                      </label>
                    </div>
                  )}
                />
                {errors?.deadlineForTheContract && (
                  <p className="error-msg">This field is required</p>
                )}

                {/* <BasicDatePicker label="Reservation expired" /> */}
              </Col>
            </Row>
          </div>
          <hr className="hr" />
          <div className="mx-4 mt-3 mb-1 form-label-title">
            Responsible agent
          </div>
          <div className="px-4">
            <Row className=" mt-2 mb-5">
              <Col xl={3} lg={4} md={6} className="my-2">
                <Controller
                  control={control}
                  {...register("managedBy")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Managed by"}
                      // innerLabel={false}
                      options={UserByDivisionIdData?.data?.map((el) => ({
                        value: el?.Id,
                        label: el?.Text,
                        isDisabled: value?.value == el?.Id,
                      }))}
                      value={value}
                      onChange={(e) => {
                        // handleZipCode(e);
                        onChange(e);
                        clearErrors("managedBy");
                      }}
                      clearable={false}
                    />
                  )}
                />
                {errors.managedBy && (
                  <p className="error-msg">This field is required</p>
                )}
              </Col>
            </Row>
          </div>
          <div className="mt-5 py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            {" "}
            <ETButton
              variant={"white"}
              buttonText="BACK"
              onClick={() => setReservationDetailPage(false)}
            />
            <div className=" d-flex ">
              <div className="px-2">
                {" "}
                {/* <NavLink
                  to="/property-units/units"
                  state={{ propId: propId, Tab: "Units" }}
                  // style={{ textDecoration: "none", color: "black" }}
                > */}
                <ETButton
                  variant={"white"}
                  buttonText="CANCEL"
                  onClick={() => {
                    setCancelPopup(true);
                  }}
                />
                {/* <input
                type="button"
                value="CANCEL"
               
                style={{
                  backgroundColor: "#fff",
                  width: "fit-content",
                  alignItems: "center",
                  display: "flex",
                  gap: "7px",
                  fontFamily: "Cerebri sans",
                  fontWeight: "500",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                  textAlign: "center",
                  padding: "10px 15px ",
                  fontSize: "14px",
                }}
              /> */}
                {/* </NavLink> */}
              </div>
              <div className="px-1">
                {" "}
                <ETButton
                  disabled={PostActorCreateReservationIsLoading}
                  variant={"primary"}
                  buttonText="CREATE RESERVATION"
                  type={"submit"}
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
      {/* -----------------------------------Cancel reservation popup ---------------------------------- */}
      <CustomPopup
        open={cancelPopup}
        onHide={() => setCancelPopup(false)}
        title="Cancel reservation?"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-3">
          <p style={{ fontSize: "14px", fontWeight: "300" }}>
            Ouch! Are you sure you want to cancel this reservation?
          </p>
          <p style={{ fontSize: "14px", fontWeight: "300" }}>
            If you click cancel, all the information you entered will be deleted
            and the Units will not change status
          </p>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => setCancelPopup(false)}
          />
          <NavLink
            to="/property-units/units"
            state={{ propId: propId, Tab: "Units" }}
          >
            <ETButton variant={"primary"} buttonText="CONFIRM" />
          </NavLink>
        </div>
      </CustomPopup>
    </div>
  );
};

export default ReservationFormTwo;
