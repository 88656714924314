import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import UnitStatus from "./UnitStatus";
import StatusActivity from "./StatusActivity";
import NotesActivity from "./NotesActivity";
import ActionActivity from "./ActionActivity";
import UnitProgressOvertime from "./UnitProgressOvertime";
import "../../../styles/css/control-center/overview.css";
import TooltipComponent from "./../../../Components/Tooltip/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Map from "./milestone/Map";
import { TabsButtonIcon } from "../../../Components/Tabs/Tabs.style";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { useGetUnitStatus } from "../../../hooks/queries/useControlCenterQueries";
import { useControlCenter } from "../../../hooks/stores/useControlCenterStore";
import { H3 } from "../../../styles/Common.style";

export const ControlCenterOverview = ({ allProperties, selectedPropertiesId, }) => {
	const {
		selectedDate,
		selectedProperties,
		selectedEndDate,
	} = useControlCenter((state) => state);

	const [unitStatusData, setUnitStatusData] = useState([])
	const { data: unitsatusdata } =
		useGetUnitStatus(
			selectedProperties,
		);

	useEffect(() => {
		setUnitStatusData(unitsatusdata?.data)
	}, [unitsatusdata])

	const available = unitStatusData?.filter((data) => data.status_name == 'Available')?.map((data) => data.quantity)
	const reserved = unitStatusData?.filter((data) => data.status_name == 'Reserved')?.map((data) => data.quantity)
	const inprocess = unitStatusData?.filter((data) => data.status_name == 'In process')?.map((data) => data.quantity)
	const completed = unitStatusData?.filter((data) => data.status_name == 'Completed')?.map((data) => data.quantity)
	const total = unitStatusData?.filter((data) => data.status_name == 'Total')?.map((data) => data.quantity)

	const PropertyData = ({
		status,
		number,
		change,
		color,
		sideBorder,
		arrow,
	}) => {
		return (
			<>
				<div
					className="p-4"
					style={{
						display: "flex",
						fontFamily: "Cerebri sans",
						fontSize: "13px",
					}}>
					<div>
						<div
							className="ms-2 me-3 light-font-text fs-13"
							style={{ width: "max-content" }}>
							{status}
						</div>

						<div className="ms-2  d-flex font-bold fs-18">
							{number}

							<div
								className="ps-1 ps-lg-2"
								style={{
									color: color,
									fontSize: "11px",
									display: "flex",
									fontWeight: "500",
								}}>
								{arrow}
								{change}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};
	return (
		<div className="containerStyle py-3 px-4">
			<Row>
				<Col>
					<div className="px-1 containerStyleColor" >
						<div
							className="pb-4 pt-3  px-2 containerStyleColor"
						>
							<Row
								className="py-4 px-xl-4  px-2 align-items-center"
								style={{
									backgroundColor: "#fff",
									borderRadius: "8px 8px 0 0",
									borderBottom: "1px solid #f4f5f5",
								}}>
								<Col lg={4} md={5}>
									<Row>
										<Col className="ps-xl-0  ps-md-2 ps-4 d-flex justify-content-md-start justify-content-center">
											<Row className=" d-flex justify-content-center align-items-center">
												<Col xs={1} className="">
													<TabsButtonIcon>
														<FontAwesomeIcon icon="fa-solid fa-house-building" />
													</TabsButtonIcon>
												</Col>

												<Col className="d-flex container-title gap-2 justify-content-center align-items-center">
													<H3>
														<div>
															Selected properties (
															{selectedProperties?.length === 0
																? 0
																: selectedProperties?.length}
															)
														</div>
													</H3>

													<div>
														<CustomToolTip
															text="Selected properties"
															paragraph="Selected properties"
														/>
													</div>
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>
								{/* {unitStatusData?.map((data) => ( */}
								<Col
									lg={8}
									md={7}
									className="d-flex flex-sm-row flex-column justify-content-between align-items-center ">
									<Row className="d-flex justify-content-md-start justify-content-center">
										<Col lg={2} md={3} className="d-flex ">

											<PropertyData
												status="Available"
												number={available}
												sideBorder="1px solid #000000"
												// change="3"
												color="#77C7A1"
											//   "#F1664B"
											// arrow={<DeltaArrow component="3" />}
											/>


										</Col>

										<Col className="d-flex" style={{ alignItems: "center" }}>
											<div className="cardBorderLeft"></div>
										</Col>

										<Col lg={2} md={3}>
											<PropertyData
												status="Reserved"
												number={reserved}
												sideBorder="1px solid #000000"
												// change="9"
												color="#F1664B"
											//   "#F1664B"
											// arrow={<DeltaArrow component="-9" />}
											/>
										</Col>
										<Col className="d-flex" style={{ alignItems: "center" }}>
											<div className="cardBorderLeft"></div>
										</Col>
										<Col lg={2} md={3}>
											<PropertyData
												status="Inprocess"
												number={inprocess}
												sideBorder="1px solid #000000"
												// change="6"
												color="#77C7A1"
											//   "#F1664B"
											// arrow={<DeltaArrow component="6" />}
											/>
										</Col>
										<Col className="d-flex" style={{ alignItems: "center" }}>
											{" "}
											<div className="cardBorderLeft"></div>
										</Col>
										<Col lg={2} md={3}>
											<PropertyData
												status="Completed"
												number={completed}
												sideBorder="1px solid #000000"
												// change="3"
												color="#77C7A1"
											//   "#F1664B"
											// arrow={<DeltaArrow component="3" />}
											/>
										</Col>

										<Col className="d-flex" style={{ alignItems: "center" }}>
											<div className="cardBorderLeft"></div>
										</Col>

										<Col lg={2} md={6} className="">
											<PropertyData status="Total units" number={total} />
										</Col>
									</Row>
								</Col>

								{/* ))} */}

							</Row>
							<Row>
								<Col className="p-0">
									<Map />
								</Col>
							</Row>
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col xl={6} className="mb-4">
					<UnitStatus />
				</Col>
				<Col xl={6} className="mb-4">
					<StatusActivity />
				</Col>
			</Row>
			<Row>
				<Col xl={6} className="mb-4">
					<NotesActivity />
				</Col>
				<Col xl={6} className="mb-4">
					<ActionActivity />
				</Col>
			</Row>
			<Row>
				<Col>
					<UnitProgressOvertime />
				</Col>
			</Row>
		</div>
	);
};
