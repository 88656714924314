import React, { useEffect, useMemo, useState } from "react";
import { CircleMsg, H4 } from "../../../../../styles/Common.style";
import {
  TableRowsStyle,
  paginationoptions,
  SortIcon,
} from "../../../../../Components/table/MRTGlobalProps";
import Menus from "../../../../../Components/Menus/Menus";
import { MaterialReactTable } from "material-react-table";
import MRTTheme from "../../../../../Components/table/MRTTheme";
import TagToolTip from "../../../../../Components/Tooltip/PropertyTagToolTip";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomerDetailTooltip from "../../../standardComponents/CustomerDetailTooltip";
import Heart from "../../../../../Components/Star/Heart";

const Approved = () => {
  // states and const used
  const isDesktop = useMediaQuery({ minWidth: 1224 });
  const [columnPinning, setColumnPinning] = useState({});

  // columns used in tables
  const columns = useMemo(
    () => [
      {
        accessorKey: "Id",
        header: "",
        enableHiding: false,
        size: 70,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 ">
            <div>
              <Heart isFavorite="0" />
            </div>
            <div>
              <TagToolTip
                iconColor={"#888888"}
                size={"lg"}
                border="1px solid #F0F0F0"
                padding={"0px"}
                boxShadow="2px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                text={
                  <div style={{ width: "700px" }}>
                    <CustomerDetailTooltip data={row?.original} />
                  </div>
                }
              />
            </div>
          </div>
        ),
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "4px !important",
            backgroundColor: "#F0F0F0 !important",
            border: "none !important",
          },
        },
      },
      {
        accessorKey: "name",
        header: "Name",
        enableHiding: false,
        size: 140,
        enableGlobalFilter: true,
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
            backgroundColor: "#F0F0F0 !important",
            border: "none !important",
            borderRight: "1px solid #888888 !important",
          },
        },
      },
      {
        accessorKey: "unit",
        header: "Unit",
        enableHiding: false,
        size: 240,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "property",
        header: "Property",
        enableHiding: false,
        size: 140,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "contract_deadline",
        header: "Contract deadline",
        enableHiding: false,
        size: 180,
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => `${row.date_of_approval}`,
        header: "Date of approval",
        enableHiding: false,
        size: 180,
        enableGlobalFilter: false,
        Cell: ({ row }) => <div>{row.original.date_of_approval} </div>,
      },
      {
        accessorKey: "approved_by",
        header: "Approved by",
        enableHiding: false,
        size: 200,
        enableGlobalFilter: false,
      },
    ],
    []
  );

  // below code is for give responsive design to fixed columns
  useEffect(() => {
    if (isDesktop) {
      setColumnPinning({
        left: ["Id", "name"],
        right: ["mrt-row-actions"],
      });
    } else {
      setColumnPinning({
        right: ["mrt-row-actions"],
      });
    }
  }, [isDesktop]);
  return (
    <div className="px-4">
      <div className="d-flex ">
        <div className="my-2">
          <div className="d-flex align-items-center pt-3 pb-2">
            <H4> Approved screenings </H4>
            <CircleMsg>
              <span>{ApprovedScreeningData?.length}</span>
            </CircleMsg>
          </div>
          <div className="light-font-text">
            Below is a list of the screenings that have been approved by a user
            in the system, but where an agreement has not yet been created yet.
          </div>
        </div>
      </div>
      <div>
        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={columns}
              data={ApprovedScreeningData} //data is undefined on first render
              // data={[]}
              icons={{ ...SortIcon }}
              enableColumnActions={false}
              enableTopToolbar={false}
              enableRowSelection={false}
              enableRowActions
              enableSelectAll
              enablePinning
              onColumnPinningChange={setColumnPinning}
              state={{ columnPinning }}
              enableFilterMatchHighlighting={true}
              enableGlobalFilter
              globalFilterFn="contains"
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "",
                  size: 50,
                  muiTableBodyCellProps: {
                    sx: {
                      // padding: "0px !important",
                      backgroundColor: "#F0F0F0 !important",
                      borderLeft: "1px solid #888888 !important",
                    },
                  },
                },
              }}
              positionActionsColumn="last"
              renderRowActions={({ row }) => (
                <Menus
                  itemheight={23}
                  itemwidth={23}
                  iconborderradius="4px"
                  itemcontainerwidth="150px"
                  component={
                    <div className="light-font-text">
                      <div className="p-2 cursor-pointer">Go to contract</div>
                      <hr className="hr m-0"></hr>
                      <div className="p-2 cursor-pointer">Contact card</div>
                    </div>
                  }
                />
              )}
              muiTablePaperProps={{
                selected: false,
                elevation: 0,
                fontFamily: "Cerebri sans-light !important",
                sx: {
                  borderRadius: "0",
                  border: "none",
                  // backgroundColor: "#F9FAFA",
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                },
              }}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
            />
          }
        />
      </div>
    </div>
  );
};

export default Approved;

// static data
const ApprovedScreeningData = [
  {
    id: 1,
    unit: "Dybbølsbro 3, 1 | 212-004",
    property: "Kaktus Towers",
    deal: "No priority set",
    active_screening: "1",
    screening_started: "2 Jul, 2023 at 10:32",
    status: "NORMAL",
    bg_color: "#F6E651",
    progress: 100,
    name: "Cai Coffey",
    contract_deadline: "12 Jul, 2023 at 12:00",
    date_of_approval: "11 Jul, 2023 at 16:12",
    approved_by: "Max Wildersans Brentford",
    customer_details: [
      {
        firstname: "Cai",
        lastname: "Coffey",
        email: "cai@millicent.in",
        phone_calling_code_id: "+45",
        mobilenumber: "",
        age: "45",
        street_name: "Dybbølsbro",
        house_number: "37",
        floor_no: "2",
        side_door: "B",
        country_id: "DK",
        country: "Denmark",
        zipcode_id: "2000",
        city: "Frederiksberg",
        registration_date: "2023-10-18 08:15:16",
        language_id: "en",
        preferred_languages: "English",
        gdpr_confirmation: 0,
      },
    ],
  },
  {
    id: 4,
    unit: "Blomsterbjerget 14, 1.tv | 742-017",
    property: "Munkesøhuse",
    deal: "No priority set",
    active_screening: "1",
    screening_started: "19 Aug, 2023 at 20:47",
    status: "NORMAL",
    bg_color: "#349032 ",
    progress: 100,
    name: "Jacqueline George",
    contract_deadline: "28 May, 2023 at 12:00",
    date_of_approval: "10 Jul, 2023 at 10:05",
    approved_by: "System",
    customer_details: [
      {
        firstname: "Jacqueline",
        lastname: "George",
        email: "george@millicent.in",
        phone_calling_code_id: "+45",
        mobilenumber: "",
        age: "35",
        street_name: "Toldbodgade",
        house_number: "37",
        floor_no: "2",
        side_door: "B",
        country_id: "DK",
        country: "Denmark",
        zipcode_id: "2000",
        city: "Frederiksberg",
        registration_date: "2023-10-18 08:15:16",
        language_id: "en",
        preferred_languages: "English",
        gdpr_confirmation: 0,
      },
    ],
  },
  {
    id: 5,
    unit: "Blomsterbjerget 8, 3.tv | 742-024",
    property: "Kolding Sky",
    deal: "John Doe",
    active_screening: "1",
    screening_started: "16 Oct, 2023 at 16:21",
    status: "NORMAL",
    bg_color: "#349032 ",
    progress: 100,
    name: "Solomon Welch",
    contract_deadline: "13 Jul, 2023 at 12:00",
    date_of_approval: "9 Jul, 2023 at 21:45",
    approved_by: "Max Wildersans Brentford",
    customer_details: [
      {
        firstname: "Solomon",
        lastname: "Welch",
        email: "solomon@millicent.in",
        phone_calling_code_id: "+45",
        mobilenumber: "",
        age: "29",
        street_name: "Toldbodgade",
        house_number: "37",
        floor_no: "2",
        side_door: "B",
        country_id: "DK",
        country: "Denmark",
        zipcode_id: "2000",
        city: "Frederiksberg",
        registration_date: "2023-10-18 08:15:16",
        language_id: "en",
        preferred_languages: "English",
        gdpr_confirmation: 0,
      },
    ],
  },
];

// change log and other info
// Created by Suraj
