import React, { useState } from "react";
import ResendLink from "./ResendLink";
import ResentLinkConfirmedSend from "./ResentLinkConfirmedSend";
import StandardImage from "../../../../assets/images/StandardSignin.jpg";
import estatelogologin from "../../../../assets/images/NewEstatetoolLogo.png";
import { ReactComponent as Textimage } from "../../../../assets/icons/svg_icons/Loginusernameicon.svg";
import { ReactComponent as Passimage } from "../../../../assets/icons/svg_icons/Loginusernameicon.svg";
import { useLocation } from "react-router-dom";
import { usePostSendResetMail } from "../../../../hooks/queries/useUserQueries";
const PasswordResentLinkExpiredWizard = () => {
  const { state } = useLocation();

  //====================== States ===================//
  const [sendNewResetPasswordLink, setSendNewResetPasswordLink] =
    useState("StepOne");
    const [errorMessage, setErrorMessage] = useState("")


  //====================== React queries ===================//
  const {
    mutateAsync: PostSendResetMail,
    isLoading: PostSendResetMailIsLoading,
  } = usePostSendResetMail();

  //====================== Handlers ===================//
  const handleSendNewLink = async () => {
    setErrorMessage("")
    const sendNewLinkPayload = {
      user_email: state?.user_email,
      system_id: "ETM",
    };

    try {
      const response = await PostSendResetMail(sendNewLinkPayload)
      if(response?.data?.code === 200){
        setSendNewResetPasswordLink("StepTwo");
      }
    } catch (error) {
      setErrorMessage(error?.data?.message)
    }
  };

  //====================== Reset screens ===================//

  const ResetPasswordExpiredLinkWizard = {
    StepOne: (
      <ResendLink
        data={customproperties}
        handleSendNewLink={handleSendNewLink}
        disableButtonOnApiLoad={PostSendResetMailIsLoading}
        errorMessage={errorMessage}
      />
    ),
    StepTwo: (
      <ResentLinkConfirmedSend
        data={customproperties}
        userEmail={state?.user_email}
      />
    ),
  };

  return (
    <div>
      {customproperties?.map((data, id) => (
        <React.Fragment key={id}>
          <div
            key={id}
            className="Maindiv d-flex flex-column justify-content-center align-items-center pt-4 w-100 scrollable-container-y"
            style={{ backgroundImage: `url(${data?.backgroundimage})` }}
          >
            {ResetPasswordExpiredLinkWizard[sendNewResetPasswordLink]}
            <div
              className="light-font-text my-4 px-3"
              style={{
                borderRadius: "1px",
                backgroundColor: "rgba(255, 255, 255, 0.50)",
                color: "#000",
              }}
            >
              This solution is powered by{" "}
              <span className="form-label-title " style={{ color: "#000" }}>
                estatetool
              </span>{" "}
              | © 2023 - All rights reserved
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default PasswordResentLinkExpiredWizard;

const customproperties = [
  {
    id: "1",
    ContainerBackground: "#2B3C49F2",
    backgroundimage: StandardImage,
    logo: estatelogologin,
    Biocolor: "white",
    TextfieldLabelcolor: "#FFFFFF",
    TextfieldUsernameIcon: <Textimage />,
    TextfieldPassIcon: <Passimage />,
    Textfieldcolor: "#FFFFFF",
    TextfieldBordercolor: "#FFFFFF",
    TextfieldfontFamily: "Cerebri sans-light",
    Signinbuttonbgcolor: "#F1664B",
    FormLinkandTextboxCheckColor: "#FFFFFF",
    TextfieldDecoration: "1px solid #FFFFFF",
  },
];
