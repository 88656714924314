import React, { useEffect, useMemo, useState } from "react";
import ETButton from "../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../Components/CustomPopup";
import AddToContract from "../parkingLot/AddToContract";
import EditParkingLot from "../parkingLot/EditParkingLot";
import PropertyOverview from "../UnitsTablePage/PropertyOverview";
import AddUnits from "../UnitsTablePage/AddUnits";
import { Col, Placeholder, Row } from "react-bootstrap";
import {
  MRT_GlobalFilterTextField,
  MaterialReactTable,
} from "material-react-table";

import Menus from "../../../../Components/Menus/Menus";
import MRTTheme from "../../../../Components/table/MRTTheme";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  paginationoptions,
} from "../../../../Components/table/MRTGlobalProps";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import {
  useUnTabType,
  useUnitIdToEdit,
  useUnitType,
} from "../../../../hooks/stores/useUnitStore";
import {
  useDeleteSingleParking,
  useDeleteSingleStorage,
  useGetStorageRoomDetails,
} from "../../../../hooks/queries/useUnitQueries";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetPropertyWithOverviewByPropertyId } from "../../../../hooks/queries/usePropertyQueries";
import DropdownModalMui from "../../../../Components/DropdownModal/DropdownModalMui";
import TagFilterDropDown from "../../../settings/Tags/TagFilterDropDown";

const StorageRoom = ({ propertyOverviw, propertyId }) => {
  const [showEditParking, setShowEditParking] = useState(false);
  const [showAddToContract, setShowAddToContract] = useState(false);
  const [showDelete, setshowDelete] = useState(false);
  const [exportFlag, setExportFlag] = useState(0);
  const [unitsTableData, setUnitsTableData] = useState([]);
  const [selectedContractData, setSelectedContractData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [originalTagData, setOriginalTagData] = useState([]);
  const { typeOfUnit, setTypeOfUnit } = useUnitType((state) => state);
  const { unitToEdit, setUnitToEdit } = useUnitIdToEdit((state) => state);
  const { unitTabType, setUnitTabType } = useUnTabType((state) => state);

  const {
    data: GetStorageRoomDetailsData,
    refetch: GetStorageRoomDetailsDataRefetch,
    isLoading,
  } = useGetStorageRoomDetails(propertyId, exportFlag);
  const { refetch: RefetchGetPropertyWithOverviewByPropertyIdData } =
    useGetPropertyWithOverviewByPropertyId(propertyId, typeOfUnit);
  const {
    mutateAsync: deleteStorageMutate,
    isLoading: deleteStorageMutateIsLoading,
  } = useDeleteSingleStorage(propertyId, unitToEdit);

  useEffect(() => {
    setUnitsTableData(GetStorageRoomDetailsData?.data);
    setNewData(GetStorageRoomDetailsData?.data);
    setOriginalTagData(GetStorageRoomDetailsData?.data);
  }, [GetStorageRoomDetailsData]);

  const handleEditUnit = (id) => {
    setShowEditParking(true);
    setUnitToEdit(id);
  };
  const handleDeletePopup = (id) => {
    // console.log("handleDeletePopup id", id);
    setshowDelete(true);
    setUnitToEdit(id);
  };
  const handleDeleteStorage = async () => {
    try {
      const result = await deleteStorageMutate(propertyId, unitToEdit);
      // console.log("handleDeleteStorage unitToEdit", unitToEdit);
      if (result?.status === "success" && result?.data?.bReturn) {
        toast.success(result?.data?.message);
        GetStorageRoomDetailsDataRefetch();
        RefetchGetPropertyWithOverviewByPropertyIdData();
        setshowDelete(false);
      } else {
        toast.error(result?.data?.message);
        setshowDelete(false);
      }
    } catch (error) { }
  };
  const ChooseFilter = [
    // { Id: "1", Text: "Unit No" },
    { Id: "2", Text: "Status" },
  ];

  const tags = [
    { Id: "1", Text: "Not active", parent: "2" },
    { Id: "2", Text: "Available", parent: "2" },
    { Id: "3", Text: "Reserved", parent: "2" },
    { Id: "4", Text: "In process", parent: "2" },
    { Id: "5", Text: "Completed", parent: "2" },
    { Id: "6", Text: "Available", parent: "2" },
    { Id: "7", Text: "Total", parent: "2" },
  ];

  const dumnmyDropDownData = {
    "Unit No": "calculated_unit_id",
    Status: "tags",
  };
  const [propertag, setPropertytag] = useState("");
  const [filter, setFilter] = useState(null);
  const [resetCondition, setResetCondition] = useState(false);
  useEffect(() => {
    if (resetCondition == true) {
      setUnitsTableData(GetStorageRoomDetailsData?.data);
    }
  }, [resetCondition]);
  const selectdata = [];
  const [filterDropdowon, setFilterDropdowon] = useState("");
  const [filterDropData, setFilterDropData] = useState("");
  const [selectedDropdownData, setSelectedDropDownData] = useState([]);
  const [show, setShow] = useState();
  const [status, setStatus] = useState(false);
  const handleChange = (filter, ActionTypes) => {
    if (ActionTypes.action === "clear") {
      setFilter("");
    } else {
      setFilter(filter);
    }
    setPropertytag(true);
    setShow(false);
    let a = dumnmyDropDownData[filter?.label];
    let b = [...new Set(newData?.map((data) => data[a]))];
    setSelectedDropDownData(b);
    // setFilter(filter.label);
    setFilterDropdowon(ChooseFilter);

    const checkSelectedLabel = filter?.label;
    if (checkSelectedLabel === "Status") {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };
  // const test = filter;
  const filteredTagdata = filter
    ? tags.filter((tag) => tag.parent === filter.value)
    : [];
  const filteredTag = selectedDropdownData;
  const [selecteddata, setSelectedData] = useState([]);
  const [closeDropdown, setCloseDropdown] = useState(false);

  const [filterKeys, setFilterKeys] = useState({
    calculated_unit_id: [],
    status_name: [],
  });
  // Function to push data into a specific filter key
  const pushDataToFilterKey = (key, value) => {
    if (filterKeys[key]?.includes(value)) {
    } else {
      setFilterKeys((prevFilterKeys) => ({
        ...prevFilterKeys,
        [key]: [...prevFilterKeys[key], value],
      }));
    }
  };
  const multiPropsFilter = (unitsTableData, filters) => {
    const filterKeys = Object?.keys(filters);
    const filterdata = originalTagData?.filter((product) => {
      return filterKeys?.every((key) => {
        if (!filters[key].length) return true;
        // Loops again if product[key] is an array (for material attribute).
        if (Array?.isArray(product[key])) {
          return product[key]?.some((keyEle) => filters[key]?.includes(keyEle));
        }
        return filters[key]?.includes(product[key]);
      });
    });
    setUnitsTableData(filterdata);
  };

  const handleFilterData = (i) => {
    const selectfilterdata = filter?.label;
    const parent = ChooseFilter.find(
      (filter) => filter.Id === selectfilterdata
    )?.parent;

    if (selectfilterdata == "Unit No") {
      const key = "calculated_unit_id";
      const value = i;
      pushDataToFilterKey(key, value);
    } else if (selectfilterdata === "Status") {
      const key = "status_name";
      const value = i;
      pushDataToFilterKey(key, value);
    } else {
    }

    setFilterDropData(i);

    selectdata.push({ lable: filterDropdowon, value: i });
    setSelectedData((prev) => [...prev, ...selectdata]);
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "unit_no",
        header: "Unit no.",
        size: 40,
      },
      {
        accessorKey: "location",
        header: "Location",
        size: 50,
        Cell: ({ row }) => {
          // console.log("row", row);
          return (
            <div className="ellipsis" style={{ width: "60px" }}>
              {row.original.location}
            </div>
          );
        },
      },
      {
        accessorFn: (row) =>
          `W${row.dimension_width} X L${row.dimension_length} ${row.uom_unit_name}`,
        header: "Dimension",
        size: 220,
      },
      {
        accessorKey: "type_name",
        header: "Type",
        size: 80,
      },
      {
        accessorFn: (row) => `${row.notice}`,
        header: "Notice",
        size: 50,
        Cell: ({ row }) => (
          <>
            <div>
              {row?.original?.notice !== "" ? row?.original?.notice : "-"}
            </div>
          </>
        ),
      },
      {
        accessorFn: (row) => `${row.contract_no}`,
        header: "Contract no.",
        size: 50,
        Cell: ({ row }) => (
          <>
            <div>
              {row?.original?.contract_no !== ""
                ? row?.original?.contract_no
                : "-"}
            </div>
          </>
        ),
      },
      {
        accessorFn: (row) => `${row?.price}`,
        header: "Price",
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
          sx: {},
        },
        Cell: ({ row }) => (
          <>
            <div className="d-flex justify-content-between">
              <div>
                {row?.original.price} {row.original.currency_id}/
                {row.original.payment_frequency_name}
              </div>
            </div>
          </>
        ),
      },
      {
        accessorFn: (row) => `${row?.status_name}`,
        header: "Status",
        size: 130,
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div
              style={{
                whiteSpace: "nowrap",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                fontFamily: "Cerebri sans-medium",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              <div
                className="fs-13"
                style={{
                  backgroundColor: `${row?.original?.status_background}`,
                  height: "18px",
                  padding: "0px 10px",
                  borderRadius: "4px ",
                  textTransform: "uppercase",
                  color: row?.original?.status_font_color,
                }}
              >
                {row?.original?.status_name}
              </div>
            </div>
          </>
        ),
      },
    ],
    []
  );
  const renderEmptyRowsFallback = () => {
    if (unitsTableData && unitsTableData?.length === 0) {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div>
              You do not have any Units added yet. Click on “+ ADD UNITS to create your first.
            </div>
          </>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div style={{ fontSize: '17px' }}>
              Sorry! No result found:(
            </div>
          </>
        </div>
      );
    }
  }

  // useEffect(() => {
  //   setUnitTabType(true)
  // }, [])

  return (
    <div>
      {" "}
      <MRTTheme
        table={
          <MaterialReactTable
            columns={columns}
            data={unitsTableData ?? []}
            icons={{ ...SortIcon }}
            enableFilterMatchHighlighting={true}
            enableGlobalFilter
            // globalFilterFn="contains" //turn off fuzzy matching and use simple contains filter function
            renderEmptyRowsFallback={renderEmptyRowsFallback}
            // state={{ isLoading: !tableData }}
            // enableRowNumbers={false}
            enableRowActions
            enablePinning
            enableColumnActions={false}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "",
                size: 50,
                muiTableBodyCellProps: {
                  sx: {
                    // padding: "0px !important",
                    backgroundColor: "#F0F0F0 !important",
                    border: "none !important",
                  },
                },
              },
            }}
            muiTableContainerProps={{
              sx: {
                padding: "0px !important",
              },
            }}
            muiSearchTextFieldProps={{
              ...SearchStyle,

              InputProps: {
                ...SearchIcon,
              },
            }}
            getRowId={(row) => row?.unit_id}
            renderRowActions={({ row }) => (
              <Menus
                itemheight={23}
                itemwidth={23}
                iconborderradius="4px"
                itemcontainerwidth="150px"
                component={
                  <div className="light-font-text cursor-pointer">
                    {row?.original?.status_name === "Available" ||
                      row?.original?.status_id === 9 ? (
                      <div
                        key="overview"
                        onClick={() => {
                          setShowAddToContract(true);
                          setSelectedContractData(row?.original);
                          setUnitTabType(true);

                        }}
                        className="p-2"
                      >
                        Add contract{" "}
                        <FontAwesomeIcon
                          className="ms-2"
                          icon="fa-regular fa-user-plus"
                          size="sm"
                          style={{ color: "#000000" }}
                        />
                      </div>
                    ) : (
                      <div
                        key="overview"
                        // onClick={() => {
                        //   setShowAddToContract(true);
                        //   setSelectedContractData(row?.original);
                        // }}
                        className="p-2"
                      >
                        See contract{" "}
                      </div>
                    )}

                    <hr className="hr m-0"></hr>

                    <div
                      onClick={() => {
                        handleEditUnit(row?.original?.storage_room_id)
                        // setUnitTabType(true)
                      }

                      }
                      key="edit"
                      className="p-2"
                    >
                      Edit unit{" "}
                    </div>

                    <hr className="hr m-0"></hr>
                    <div
                      key="delete"
                      // onClick={() => {
                      //   setDeletePopUp(!deletePopUp);
                      //   setRowId(row.id);
                      // }}
                      onClick={() =>
                        handleDeletePopup(row?.original?.storage_room_id)
                      }
                      style={{ cursor: "pointer" }}
                      className="p-2"
                    >
                      Delete
                    </div>
                  </div>
                }
              />
            )}
            positionActionsColumn="last"
            muiTableHeadCellProps={{
              // elevation: 0,
              sx: {
                // boxShadow: "none",
              },
            }}
            muiTableBodyProps={{
              zindex: "1",
              sx: {
                td: {
                  overflow: "visible",
                  backgroundColor: "#F9FAFA",
                },
              },
            }}
            muiTablePaperProps={{
              elevation: 0,
              sx: {
                fontFamily: "Cerebri sans !important",
                borderRadius: "0",
                border: "none",
                background: "#FFFFFF",
                fontWeight: "300",
                ".css-1f2qhs8": {
                  minWidth: "auto",
                  marginBottom: "5px",
                },
                table: {
                  borderCollapse: "separate",
                  borderSpacing: "0 5px",
                  padding: "16px",
                },
                thead: {
                  // display: "none",
                },
                th: {
                  background: "#FFFFFF",
                  fontFamily: "Cerebri sans-medium !important",
                },
                td: {
                  fontFamily: "Cerebri sans-light !important",
                  fontSize: "14px",
                },
                tr: {
                  borderRadius: "4px",
                  background: "#F9FAFA",
                  margin: "4px",
                },
                "& td:nth-last-child(3)": {
                  border: "none !important",
                  borderLeft: "1px solid #d1d1d1 !important",
                  backgroundColor: "#F0F0F0",
                },
                "& td:nth-last-child(2)": {
                  border: "none !important",
                  backgroundColor: "#F0F0F0",
                },
                "& td:nth-last-child(1)": {
                  border: "none !important",
                },
              },
            }}
            muiTableHeadRowProps={{
              sx: {
                // ...TableRowsStyle,
                background: "#FFFFFF",
              },
            }}
            muiTablePaginationProps={{
              ...paginationoptions,
            }}
            initialState={{
              showGlobalFilter: true,
              showColumnFilters: false,
              columnPinning: {
                // left: ["id", "Status"],
                right: ["Price", "Status", "mrt-row-actions"],
              },
            }}
            renderTopToolbar={({ table }) => (
              <>
                <Row className="pt-3 containerStyleColor">
                  <Col xl={2} md={3} className="pe-1">
                    {/* {isLoading ? (
                <div className="">
                  <Placeholder animation="glow">
                    <div className="placeholder col-10 radius-8 padding-20 bg-secondary"></div>
                  </Placeholder>
                </div>
              ) : ( */}
                    <MRT_GlobalFilterTextField table={table} />
                    {/* )} */}
                  </Col>

                  <Col xl={2} md={3} className="cursor-pointer pe-1">
                    {isLoading ? (
                      <div className="">
                        <Placeholder animation="glow">
                          <div className="placeholder col-10  radius-8 padding-20 bg-secondary"></div>
                        </Placeholder>
                      </div>
                    ) : (
                      <div
                        className="fs-14"
                        style={{ color: "#D3D3D3 !important" }}
                      >
                        <DropdownModalMui
                          text="Set filters"
                          width="561px"
                          closeDropdown={closeDropdown}
                          component={
                            <TagFilterDropDown
                              propertag={propertag}
                              filteredTag={filteredTag}
                              filteredTagdata={filteredTagdata}
                              status={status}
                              setUnitsTableData={setUnitsTableData}
                              multiPropsFilter={multiPropsFilter}
                              selectedDropdownData={selectedDropdownData}
                              dumnmyDropDownData={dumnmyDropDownData}
                              filterDropdowon={filterDropdowon}
                              closeDropdown={closeDropdown}
                              // handleDropData={handleDropData}
                              filterDropData={filterDropData}
                              setFilterDropData={setFilterDropData}
                              ChooseFilter={ChooseFilter}
                              handleFilterData={handleFilterData}
                              filter={filter}
                              selecteddata={selecteddata}
                              setFilter={setFilter}
                              handleChange={handleChange}
                              selectdata={selectdata}
                              filterKeys={filterKeys}
                              setFilterKeys={setFilterKeys}
                              setCloseDropdown={setCloseDropdown}
                              setResetCondition={setResetCondition}
                            />
                          }
                        />
                      </div>
                    )}
                  </Col>

                  <Col></Col>

                  <Col xl={3} md={4}>
                    <div className="d-flex  float-end">
                      <AddUnits
                        // isLoading={isLoading}
                        propertyId={propertyId}
                        propertyOverviw={propertyOverviw}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="px-1 containerStyleColor" >
                  <PropertyOverview
                    propertyOverviw={propertyOverviw}
                  // isLoading={isLoading}
                  />
                </div>
              </>
            )}
            positionGlobalFilter="left"
          />
        }
      />
      <CustomPopup
        open={showEditParking}
        onHide={() => setShowEditParking(false)}
        title="Edit storage room"
        title_color="black"
        titleFont="14px"
        titleTooltip={true}
        titleTooltipText="Edit storage room"
        size="xl"
        enableCrossButton={false}
        onCloseAction={() => {
          setShowEditParking(false);
          alert("Action Peformed Successfuly.");
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={false}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <EditParkingLot setShowEditParking={setShowEditParking} />
      </CustomPopup>
      {/* ------------------ Add To Contract ------------- */}
      <CustomPopup
        open={showAddToContract}
        onHide={() => setShowAddToContract(false)}
        title="Add to a contract"
        title_color="black"
        titleFont="14px"
        titleTooltip={true}
        titleTooltipText="Add to a contract"
        size="xl"
        enableCrossButton={false}
        onCloseAction={() => {
          setShowAddToContract(false);
          alert("Action Peformed Successfuly.");
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={false}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <AddToContract
          setShowAddToContract={setShowAddToContract}
          selectedContractData={selectedContractData}
        />
      </CustomPopup>
      {/* Delete  popup */}
      <CustomPopup
        open={showDelete}
        onHide={() => setshowDelete(false)}
        title="Delete unit"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="p-3">
          <div className="px-2 mb-5">
            <div className="light-font-text my-1">
              Are you sure you want to delete this unit{" "}
            </div>
          </div>
        </div>
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setshowDelete(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="DELETE"
            disabled={deleteStorageMutateIsLoading}
            // onClick={() => setshowDelete(false)}
            onClick={() => handleDeleteStorage()}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default StorageRoom;

const unitsTableData = [
  {
    id: 1,
    location: "xxxxxxx",
    dimension: "W250xL500 cm",
    type: "construction",
    notice: "Disability",
    charging: "no",
    contact_no: "-",
    price: "2.200",
    status: "available",
    bg_color: "#B4DBC9",
    color: "#000000",
  },
  {
    id: 2,
    location: "xxxxxxx",
    dimension: "W250xL500 cm",
    type: "construction",
    notice: "limit",
    charging: "no",
    contact_no: "222-223-222",
    price: "2.200",
    status: "in process",
    bg_color: "#C2B0E6",
    color: "#000000",
  },
  {
    id: 3,
    location: "xxxxxxx",
    dimension: "W250xL500 cm",
    type: "Terrain",
    notice: "-",
    charging: "Yes",
    contact_no: "222-222-290",
    price: "1.800",
    status: "leased",
    bg_color: "#F1AB9E",
    color: "#000000",
  },
];
