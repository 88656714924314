import { useEffect, useState } from "react";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../Components/Tabs/ReactTabs.style";
import ETButton from "../../Components/Buttons/ETButton";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import Media from "./Media";
import "./Media.css";
import MediaPackages from "./MediaPackages";
import { useGetMediaCategory } from "../../hooks/queries/useMediaQueries";
import { useAddEditPackage } from "../../hooks/stores/usePackageStore";
import { useRouting } from "../../hooks/stores/useRoutingStore";
import { Placeholder } from "react-bootstrap";
import { useBreadcrumb } from "../../Components/BreadcrumbContext ";
import { H2 } from "../../styles/Common.style";

const MediaMain = ({
  RefetchGetPropertyWithOverviewByPropertyIdData,
}) => {
  const [showAddPackageBtn, setShowAddPackageBtn] = useState(false);
  //--------zustand
  const {
    setTitle,
    setActive,
    setTagRowData,
    setSelected,
    setSelectedData,
    setCancelPopup,
  } = useAddEditPackage((state) => state);
  const { setShowPackage, setEditflag, setPackageType, selectedSubTabType,
    setSelectedSubTabType,
    setSelectedSubTab, } =
    useRouting((state) => state);

  //-----------React query-----------//
  const { data: mediaCategoryData, isLoading } = useGetMediaCategory();

  const handleAddMediaPackage = () => {
    // console.log("handleAddMediaPackage clicked");
    // navigate("/create-media-package");
    setCancelPopup(false);
    setTitle("");
    setActive(false);
    setTagRowData([]);
    setSelected("");
    setSelectedData([]);
    // setShowCreateMediaPackage((prev) => !prev);
    // setShowPackage((prev) => !prev);
    // setIsEditMediaPackage(false);
    setEditflag(false);
    setPackageType("5");
    setShowPackage(true);
  };
  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    if (showAddPackageBtn) {
      updateBreadcrumbs(["Media", 'Packages']);

    } else {
      updateBreadcrumbs(["Media", 'Media']);

    }
  }, [showAddPackageBtn]);
  return (
    <>
      {
        <Placeholder animation="glow">
          <div className=" p-4 my-0 containerStyle">
            <div className="media-sub-container mainborder">
              <div
                className="media-header px-4 py-3"
                style={{ flexDirection: "column" }}
              >
                <div className="d-flex gap-2 align-item-center">
                  {isLoading ? (
                    <div className=" " style={{ width: "100px" }}>
                      <div className="placeholder col-10 bg-secondary"></div>
                    </div>
                  ) : (
                    <div className=" d-flex align-items-center">
                      <H2 className="me-2">Media</H2>
                      <CustomToolTip size="sm"  text="Media" />
                    </div>
                  )}
                </div>
              </div>

              <Tabs>
                <div
                  className=" px-4 py-3 d-flex justify-content-between flex-wrap gap-2"
                  style={{
                    borderBottom: "1px solid #F0F0F0",
                  }}
                >
                  <TabList
                    className=" d-flex light ps-0 m-0 "
                    style={{ gap: "7px" }}
                  >
                    {isLoading ? (
                      <div className="" style={{ width: "100px" }}>
                        <div
                          className="placeholder col-10 bg-secondary"
                          style={{
                            height: "35px",
                            borderRadius: "6px",
                          }}
                        ></div>
                      </div>
                    ) : (
                      <Tab
                        onClick={() => {

                          setShowAddPackageBtn(false);
                          // setSelectedSubTabType("  Media")
                          setSelectedSubTab(false)

                        }}
                      // onClick={() => setShowAddPackageBtn(false)}
                      // className="react-media-tab"

                      >
                        Media
                      </Tab>
                    )}
                    {isLoading ? (
                      <div className="" style={{ width: "100px" }}>
                        <div
                          className="placeholder col-10 bg-secondary"
                          style={{
                            height: "35px",
                            borderRadius: "6px",
                          }}
                        ></div>
                      </div>
                    ) : (
                      <Tab
                        // onClick={() => setShowAddPackageBtn(true)}
                        onClick={() => {

                          setShowAddPackageBtn(true)
                          // setSelectedSubTabType(" Packages")
                          setSelectedSubTab(false)

                        }}
                      // className="react-media-tab"
                      >
                        Packages
                      </Tab>
                    )}
                  </TabList>
                  {showAddPackageBtn && (
                    <div className=" d-flex justify-content-center mt-2">
                      {isLoading ? (
                        <div className="" style={{ width: "139px" }}>
                          <div
                            className="placeholder col-10 bg-secondary"
                            style={{
                              height: "35px",
                              borderRadius: "6px",
                            }}
                          ></div>
                        </div>
                      ) : (
                        <>
                          <ETButton
                            onClick={handleAddMediaPackage}
                            variant={"primary"}
                            buttonText="ADD MEDIA PACKAGE"
                            // rotate={rotate}
                            icon={true}
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>

                <div>
                  <TabPanel>
                    <Media
                      RefetchGetPropertyWithOverviewByPropertyIdData={
                        RefetchGetPropertyWithOverviewByPropertyIdData
                      }
                      mediaCategoryData={mediaCategoryData}
                    />
                  </TabPanel>
                  <TabPanel>
                    <MediaPackages
                    // setShowCreateMediaPackage={setShowCreateMediaPackage}
                    // setIsEditMediaPackage={setIsEditMediaPackage}
                    // setMediaPackageId={setMediaPackageId}
                    // mediaCategoryData={mediaCategoryData}
                    />
                  </TabPanel>
                </div>
              </Tabs>
            </div>
          </div>
        </Placeholder>
      }
    </>
  );
};

export default MediaMain;
