import moment from "moment";
import React from "react";
import ETButton from "../../../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SigningPackageCard = ({
  data,
  index,
  setSelectedSigningPackageId,
  selectedSigningPackageId,
}) => {
  const handleSelectPackage = () => {
    if (data?.signing_package_type == 1) {
      selectedSigningPackageId?.residential_package_id ==
      data?.document_package_id
        ? setSelectedSigningPackageId((prev) => ({
            ...prev,
            residential_package_id: "",
          }))
        : setSelectedSigningPackageId((prev) => ({
            ...prev,
            residential_package_id: data?.document_package_id,
          }));
    } else if (data?.signing_package_type == 2) {
      selectedSigningPackageId?.parking_package_id ==
      data?.document_package_id
        ? setSelectedSigningPackageId((prev) => ({
            ...prev,
            parking_package_id: "",
          }))
        : setSelectedSigningPackageId((prev) => ({
            ...prev,
            parking_package_id: data?.document_package_id,
          }));
    } else {
      selectedSigningPackageId?.storage_package_id ==
      data?.document_package_id
        ? setSelectedSigningPackageId((prev) => ({
            ...prev,
            storage_package_id: "",
          }))
        : setSelectedSigningPackageId((prev) => ({
            ...prev,
            storage_package_id: data?.document_package_id,
          }));
    }
  };

  return (
    <div
      className="actor-signing-order-card radius-8 align-items-center"
      style={{
        backgroundColor:
          selectedSigningPackageId?.residential_package_id ==
            data?.document_package_id ||
          selectedSigningPackageId?.parking_package_id ==
            data?.document_package_id ||
          selectedSigningPackageId?.storage_package_id ==
            data?.document_package_id
            ? "#ECF1E6"
            : "#F9FAFA",
        border:
          selectedSigningPackageId?.residential_package_id ==
            data?.document_package_id ||
          selectedSigningPackageId?.parking_package_id ==
            data?.document_package_id ||
          selectedSigningPackageId?.storage_package_id ==
            data?.document_package_id
            ? "1px solid #619130"
            : " 1px solid #F0F0F0",
      }}
    >
      <div
        className="p-3 text-break fw-300 fs-13 "
        style={{ borderBottom: "1px solid #F0F0F0" }}
      >
        <div
          className="bg-white radius-6 pe-1 "
          style={{ border: "1px solid #F0F0F0" }}
        >
          <div
            className=" p-3 radius-6 light-font-text fs-13"
            style={{ height: "140px" }}
          >
            {data?.doc_files?.map(
              (item, index) =>
                index < 4 && (
                  <div key={index} className="d-flex gap-2">
                    <span style={{ width: "5%" }}>
                      <FontAwesomeIcon
                        icon={`fa-regular ${
                          item?.name?.includes(".pdf")
                            ? "fa-file-pdf"
                            : "fa-file-certificate"
                        }`}
                      />
                    </span>
                    <span style={{ width: "95%" }} className="mb-0 ellipsis">
                      {item?.name}
                    </span>
                  </div>
                )
            )}
            {data?.doc_files?.length > 4 && (
              <div>
                <span className="form-label-title text-dark">
                  +{data?.doc_files?.length - 4} document
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="px-3 py-3 text-break"
        style={{ borderBottom: "1px solid #F0F0F0" }}
      >
        <div className="fs-16 form-label-title">
          Signing package {index + 1}
        </div>
      </div>

      <div className="p-3" style={{ position: "relative" }}>
        <div className="mb-2">
          <p className="mb-0 form-label-title">Package title </p>
          <span className="light-font-text text-break">
            {data?.document_package_name}
          </span>
        </div>
        <div className="mb-2 ellipsis" style={{ width: "300px" }}>
          <p className="mb-0 form-label-title">Tag</p>
          <span className="light-font-text   ">
            {data?.tags?.split(",")?.join(", ") ||
              data?.tag?.split(",")?.join(", ") ||
              "No tag to display"}
          </span>
        </div>
        {/* <div className="mb-2">
      <p className="mb-0 fs-13  fw-600">Created by</p>
      <span className="fw-300 fs-13 text-break">{data?.created_by}</span>
    </div> */}
        <div className="mb-2">
          <p className="mb-0 fs-12 form-label-title">Created by</p>
          {/* <span className="">{data?.created_by}</span> */}
          <div className="created-by  text-break">
            {data?.created_by
              ? data?.created_by +
                ", " +
                moment(data?.created_on).format("DD. MMM. YYYY | HH:MM:SS")
              : "-"}
          </div>
        </div>

        <div className="mb-2">
          <p className="mb-0 fs-12  form-label-title ">Updated by</p>
          {/* <span className="fw-300 fs-13 text-break">
            {moment(data?.created_on).format("DD MMM, YYYY [at] HH:mm")}
          </span> */}

          <div className="created-by  text-break">
            {data?.modified_by
              ? data?.modified_by +
                ", " +
                moment(data?.modified_on).format("DD. MMM. YYYY | HH:MM:SS")
              : "-"}
          </div>
        </div>

        {/* <div className="mb-2">
      <p className="mb-0 fs-13  fw-600">Date</p>
      <span className="fw-300 fs-13 text-break">
        {moment(data?.created_on).format("DD MMM, YYYY [at] HH:mm:ss")}
      </span>
    </div> */}
      </div>
      <div className="px-3 mb-3">
        <ETButton
          // backgroundColor={
          // 	selectedSigningPackageId === data?.document_package_id
          // 		? "gray"
          // 		: "green"
          // }
          // buttonText={
          // 	selectedSigningPackageId === data?.document_package_id
          // 		? "SELECTED"
          // 		: "SELECT"
          // }
          // textColor={
          // 	selectedSigningPackageId === data?.document_package_id
          // 		? "black"
          // 		: "white"
          // }

          variant={
            selectedSigningPackageId?.residential_package_id == data?.document_package_id || selectedSigningPackageId?.parking_package_id == data?.document_package_id || selectedSigningPackageId?.storage_package_id == data?.document_package_id
              ? "primary"
              : "secondary"
          }
          buttonText={
            selectedSigningPackageId?.residential_package_id == data?.document_package_id || selectedSigningPackageId?.parking_package_id == data?.document_package_id || selectedSigningPackageId?.storage_package_id == data?.document_package_id
              ? "SELECTED"
              : "SELECT"
          }
          borderColor="outline"
          icon={false}
          fontSize="14px"
          width="100%"
          onClick={() => {
            handleSelectPackage();
          }}
        />
      </div>
    </div>
  );
};

export default SigningPackageCard;
