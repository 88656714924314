import React, { useEffect } from "react";
import { H2 } from "../../../styles/Common.style";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";

import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../../Components/Tabs/ReactTabs.style";
import NormalDocuments from "./NormalDocuments";
import InheritedDocuments from "./InheritedDocuments";
import { useDivisionDocumentBreadcrumbsTabs } from "../../../hooks/stores/useDivisionsStore";
const DivisionDocuments = () => {
  // const setOrganizationBreadcrumbsTabs = useOrganizationSettingsBreadcrumbsTabs(
  //   (state) => state.setOrganizationBreadcrumbsTabs
  // );
  const setDivisionDocumentBreadcrumbsTabs = useDivisionDocumentBreadcrumbsTabs(
    (state) => state?.setDivisionDocumentBreadcrumbsTabs
  );

  useEffect(() => {
    setDivisionDocumentBreadcrumbsTabs("Documents")
  }, [])
  return (
    <div className="p-4 containerStyle mt-1">
      {" "}
      {/* title section */}
      <div
        className="px-3 pb-3  whiteContainerStyle  border-bottom"
        style={{
          borderRadius: "8px 8px 0px 0px ",
        }}
      >
        <div className="px-2 pt-3">
          <div className="d-flex align-items-end  gap-2">
            <H2>Documents</H2>
            <CustomToolTip size="sm" text="Documents" />
          </div>
        </div>
      </div>
      <div className=" border-bottom">
        <div>
          <Tabs className="d-flex flex-column ">
            <div
              className="d-flex px-4 py-3 justify-content-between align-items-center"
              style={{
                width: "100%",
                flexDirection: "row",
                flexWrap: "wrap",
                borderBottom: "1px solid #F0F0F0",
                background: "#ffffff",
              }}
            >
              <TabList
                className=" d-flex light ps-0 light"
                style={{ gap: "7px" }}
              >
                <Tab
                  onClick={() =>
                    setDivisionDocumentBreadcrumbsTabs("Documents")
                  }
                >
                  Documents
                </Tab>
                <Tab
                  onClick={() =>
                    setDivisionDocumentBreadcrumbsTabs("Inherited documents")
                  }
                >
                  Inherited documents
                </Tab>
              </TabList>
            </div>
            <div>
              <TabPanel>
                <NormalDocuments />
              </TabPanel>
              <TabPanel>
                <InheritedDocuments />
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default DivisionDocuments;
