import { Delete, Get, Post, Put } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

//Get Events source
export const GetEventsSource = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_EventsSource, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get events type
export const GetEventsType = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Event_GetEventsType, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Get events Agent Details
export const GetEventAgentDetails = (propId) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Event_GetEventAgentDetails,
      null,
      propId + "/events/agent"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get events By PropertyId
export const GetEventsByPropertyId = (propertyId, eventType, agentuserId) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_EventsByPropertyId,
      null,

      propertyId + "/events/" + "type/" + eventType + "/agent/" + agentuserId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get events details
export const GetEventsDetails = (eventsId) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_EventsDetails, null, eventsId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get events participant
export const GetEventsParticipant = (eventsParticipantId) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_EventsParticipant, null, eventsParticipantId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Post Add events
export const PostAddEvent = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Event_PostAddEvent, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get events Detail By EventId
export const GetEventsDetailByEventId = (
  propertyId,
  eventId,
  eventCalendarEntryId
) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Event_Get_EventsDetailByEventId,
      null,
      propertyId + "/event/" + eventId + "/" + eventCalendarEntryId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Delete Event
export const DeleteEvent = (
  propertyId,
  eventCalendarEntryId,
  deleteType,
  eventId
) => {
  return new Promise((resolve, reject) => {
    Delete(
      Site.ET,
      Endpoint.ET.Event_Delete_Event,
      null,
      propertyId +
        "/events/" +
        eventCalendarEntryId +
        "/" +
        deleteType +
        "/" +
        eventId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
