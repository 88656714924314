import React from "react";
import CustomPopup from "../../../../../../../../../Components/CustomPopup";
import ETButton from "../../../../../../../../../Components/Buttons/ETButton";

const Resend = ({ reSendShow, setReSendShow }) => {
  return (
    <div>
      {" "}
      <CustomPopup
        open={reSendShow}
        onHide={() => setReSendShow(false)}
        title="Re-send invitation"
        title_color="black"
        size={"sm"}
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="p-4 light-font-text">
          We will re-send the invitation to:
        </div>
        <div className="form-label-title px-4"> Louise Birch Justesen</div>
        <div className="px-4 light-font-text"> louise.b@gmail.com</div>
        <div className="py-2 mt-4 px-3 d-flex flex-row justify-content-between  footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setReSendShow(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="SEND"
            type={"submit"}
            onClick={() => setReSendShow(false)}
            //   onClick={handleDelete}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default Resend;
