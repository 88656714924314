import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import ETButton from "../../../../Components/Buttons/ETButton";
import FilterComponent from "../../../../reactdataTableComponent/FilterComponent";
import ScreeningFilterTable from "../../../../Components/UnitFilterTable/ScreeningFilterTable";
import MRTTheme from "../../../../Components/table/MRTTheme";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";

import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../../Components/table/MRTGlobalProps.js";
import Menus from "../../../../Components/Menus/Menus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetUnitsByPropertyIdUpdated } from "../../../../hooks/queries/useUnitQueries";
import { usePropertyId } from "../../../../hooks/stores/usePropertyStore";
import CustomPopup from "../../../../Components/CustomPopup";
import { useScreening } from "../../../../hooks/stores/useScreeningStore";
const LinkedUnits = ({ linkedUnitsData, unitsData, setUnitsData }) => {
  // console.log("🚀 ~ file: LinkedUnits.js:26 ~ LinkedUnits ~ unitsData:", unitsData)

  //-------------------zustant-----------------------------//
  const { propertyId } = usePropertyId();

  //-------------------state-----------------------------//

  const [selelectUnitsID, setSelelectUnitsID] = useState([]);
  // const [unitsData, setUnitsData] = useState([]);
  const [addMoreUnits, setAddMoreUnits] = useState(false);
  const [deletedUnit, setDeletedUnit] = useState("");
  // console.log("🚀 ~ file: LinkedUnits.js:37 ~ LinkedUnits ~ delectedUnit:", delectedUnit)
  const [linkedUnitPopup, setLinkedUnitPopup] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowSelection, setRowSelection] = useState([]);
  console.log(
    "🚀 ~ file: LinkedUnits.js:41 ~ LinkedUnits ~ rowSelection1:",
    rowSelection
  );

  // console.log("🚀 ~ file: LinkedUnits.js:40 ~ LinkedUnits ~ rows:", rows)
  //------------------------react query----------------------//
  const { data: unitsByPropertyData, refetch: PropertyDataRefetch } =
    useGetUnitsByPropertyIdUpdated(propertyId, 0);
  const { EditscreeningForm } = useScreening((state) => state);

  //------------------------useEffect----------------------//

  useEffect(() => {
    if (EditscreeningForm) {
      const editUnitId = linkedUnitsData?.map((data) => data?.unit_id);
      const editData = unitsByPropertyData?.data?.filter((item) =>
        editUnitId?.includes(item?.unit_id)
      );
      setUnitsData(editData);
    }
    // else {
    //   const filteredUnitsData = unitsByPropertyData?.data?.filter((unit) => {
    //     // Check if the unit_id exists in linkedData
    //     return linkedUnitsData?.some(
    //       (linkedUnit) => linkedUnit?.unit_id == unit?.unit_id
    //     );
    //   });
    //   setUnitsData(filteredUnitsData);
    //   // setEditUnitData([])
    // }
  }, [linkedUnitsData]);

  //------------------------handle for remove linked units form outer table ----------------------//

  const handleRemoveLinkedUnits = () => {
    const updatedUnitsData = unitsData?.filter(
      (item) => item?.unit_id !== deletedUnit
    );

    // Remove the deleted unit_id from rowSelection
    const updatedRowSelection = { ...rowSelection };
    delete updatedRowSelection[deletedUnit];

    setUnitsData(updatedUnitsData);
    setRowSelection(updatedRowSelection);
    setLinkedUnitPopup(false);
  };

  const renderEmptyRowsFallback = () => {
    if (unitsData && unitsData?.length === 0) {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div>
              You do not have any linked units yet. Click on “+ LINK UNITS TO
              FORM ” to create your first.
              {/* There is no linked units to this form right now. Please click on
              “Link units to form” and add linked units. */}
            </div>
          </>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div className="fs-14">Sorry! No result found</div>
          </>
        </div>
      );
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "unit_icon", //normal accessorKey
        header: "",
        enableSorting: false,
        size: 50,
        Cell: ({ cell }) => (
          <div>
            {cell?.row?.original?.is_active_screening == 1 ? (
              <FontAwesomeIcon
                icon="fa-regular fa-lock"
                style={{ color: "#000000" }}
              />
            ) : (
              ""
            )}
          </div>
        ),
      },

      {
        accessorFn: (row) =>
          `${row?.street_name} ${row?.house_number}, ${row?.side_door}`,
        header: "Address",
        size: 180,
      },
      {
        accessorKey: "housing_type_name", //normal accessorKey
        header: "Type",
        size: 100,
      },
      {
        accessorKey: "alt_unit_id", //normal accessorKey
        header: "Unit no.",
        size: 80,
      },
      {
        accessorKey: "floor_number",
        header: "Floor",
        size: 50,
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorFn: (row) => `${row?.area} ${row?.square_measure_unit}`,
        size: 80,
        // accessorKey: "available",
        header: "Area",
        Cell: ({ cell, row }) => {
          return (
            <div>
              {row.original.area}
              <span
                dangerouslySetInnerHTML={{
                  __html: row.original.square_measure_unit,
                }}
              ></span>
            </div>
          );
        },
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "no_of_rooms",
        header: "Room",
        size: 80,
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },

      {
        accessorFn: (row) => `${row.rent} ${row.currency_id}`,
        header: "Price",
        size: 110,
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );

  return (
    <div>
      {/* <Row>
        <Col xl={5}>
          <div className="d-flex align-items-center form-label-title gap-2">
            Linked units <CustomToolTip height={12} text="Linked units" />
          </div>
          <div className="light-font-text">
            Below is the list of all units linked to this form.
          </div>
          <div className="light-font-text">
            A unit can only be linked to one screening package at the time.
          </div>
        </Col>
        <Col xl={4} md={6}>
          <FilterComponent />
        </Col>
        <Col xl={3} md={6}>
          <ETButton
            variant={"primary"}
            buttonText="LINK UNITS TO FORM"
            icon={true}
            width="100%"
            onClick={() => {
              setAddMoreUnits(true);
            }}
          />
        </Col>
      </Row> */}
      <MRTTheme
        colorVariantsForTableData={["odd"]}
        table={
          <MaterialReactTable
            columns={columns}
            // data={unitsData?.filter((data) => data.is_linked_with_screening !== 1) ?? []}
            data={unitsData ?? []}
            icons={{ ...SortIcon }}
            enableFilterMatchHighlighting={true}
            enableGlobalFilter
            renderEmptyRowsFallback={renderEmptyRowsFallback}
            enableMultiRowSelection={false}
            enableRowSelection={false}
            enableRowNumbers={true}
            // enableRowSelection
            // onRowSelectionChange={setRowSelection}// to add u
            globalFilterFn="contains" //turn off fuzzy matching and use simple contains filter function
            // renderEmptyRowsFallback={renderEmptyRowsFallback}
            // state={{ isLoading: !unitsData }}
            // enableRowNumbers={false}
            enableRowActions={true}
            enableColumnActions={false}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "",
                size: 50,
              },
              "mrt-row-numbers": {
                header: "No.",
                size: 50,
              },
            }}
            muiSearchTextFieldProps={{
              ...SearchStyle,

              InputProps: {
                placeholder: "Search for a unit...",
                ...SearchIcon,
              },
            }}
            getRowId={(row) => row?.unit_id}
            renderRowActions={({ row }) => (
              <button
                type="button"
                className="added-document-delete-btn"
                onClick={() => {
                  setDeletedUnit(row?.original?.unit_id);
                  setLinkedUnitPopup(true);
                }}
              >
                <FontAwesomeIcon icon="fa-regular fa-trash" className="fs-6" />
              </button>
            )}
            positionActionsColumn="last"
            muiTableContainerProps={{
              sx: {
                padding: "0px !important",
              },
            }}
            muiTablePaginationProps={{
              ...paginationoptions,
            }}
            initialState={{
              showGlobalFilter: true,
              showColumnFilters: false,
            }}
            renderTopToolbar={({ table }) => (
              <>
                <Row>
                  <Col xl={6}>
                    <div className="d-flex align-items-center form-label-title gap-2">
                      Linked units{" "}
                      <CustomToolTip height={12} text="Linked units" />
                    </div>
                    <div className="light-font-text">
                      Below is the list of all units linked to this form.
                    </div>
                    <div className="light-font-text">
                      A unit can only be linked to one screening package at the
                      time.
                    </div>
                  </Col>
                  <Col xl={3} md={6}>
                    <MRT_GlobalFilterTextField table={table} />
                  </Col>
                  <Col xl={3} md={6} className="ps-lg-0">
                    <ETButton
                      variant={"primary"}
                      buttonText="LINK UNITS TO FORM"
                      icon={true}
                      width="100%"
                      onClick={() => {
                        setAddMoreUnits(true);
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            positionGlobalFilter="left"
          />
        }
      />
      <CustomPopup
        open={linkedUnitPopup}
        onHide={() => linkedUnitPopup}
        title="Are you sure you want to remove?"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
      >
        <div className="p-3">
          <p style={{ fontSize: "14px", fontWeight: "300" }}>
            Are you sure you want to remove the unit.
          </p>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => setLinkedUnitPopup(false)}
          />
          <ETButton
            // disabled={deleteArchiveApiIsLoading}
            variant={"primary"}
            buttonText="I’M SURE"
            onClick={() => handleRemoveLinkedUnits()}
          />
        </div>
      </CustomPopup>
      <ScreeningFilterTable
        addMoreUnits={addMoreUnits}
        EditscreeningForm={EditscreeningForm}
        unitsData={unitsData}
        linkedUnitsData={linkedUnitsData}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        setUnitsData={setUnitsData}
        setAddMoreUnits={setAddMoreUnits}
        setSelectedUnitsId={setSelelectUnitsID}
        deletedUnit={deletedUnit}
        rows={rows}
      />
    </div>
  );
};

export default LinkedUnits;
