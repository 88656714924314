import React from "react";
import { Col, Row, Container, Form, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import IconTextField from "../../../../Components/GroupText/IconTextField";
import ETButton from "../../../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../GeneralLogin.css";

const ResendLink = ({
  data,
  handleSendNewLink = () => {},
  disableButtonOnApiLoad = false,
  errorMessage = "",
}) => {
  const handleSubmit = () => {
    handleSendNewLink();
  };
  return (
    <>
      {data?.map((data, id) => (
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col
              md={7}
              xxl={4}
              xl={5}
              lg={6}
              sm={12}
              className="px-xl-2 px-sm-0"
            >
              <div
                className="StandardForm row"
                style={{
                  backgroundColor: data?.ContainerBackground,
                }}
              >
                <div className="px-3" style={{ marginTop: "40px" }}>
                  <Row className="m-auto">
                    <div
                      className="d-flex justify-content-center align-items-center mb-5 p-1"
                      md={{ span: 6, offset: 3 }}
                      xs={{ span: 4, offset: 4 }}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <Image
                          width={"160px"}
                          height={"auto"}
                          src={data?.logo}
                          alt="logo"
                        />
                      </div>
                    </div>
                  </Row>

                  <div className="d-flex justify-content-center flex-column align-items-center mb-4 px-4 mx-1">
                    <div
                      className="light-font-text px-3 fs-16"
                      style={{
                        color: data?.Biocolor,
                        textAlign: "center",
                      }}
                    >
                      The link you are trying to access is no longer active. If
                      you still need to reset your password, request a new link.
                    </div>
                  </div>
                  <Form>
                    <div className="d-flex row  justify-content-center align-items-center">
                      {errorMessage && (
                        <div className="error-msg font-medium fs-16 text-align-center mb-4">
                          {errorMessage}
                        </div>
                      )}
                      <Row style={{ paddingBottom: "245px" }}>
                        <Col xs={{ span: 8, offset: 2 }}>
                          <div className="d-flex justify-content-center">
                            <ETButton
                              onClick={handleSubmit}
                              variant={"primary"}
                              buttonText="SEND NEW LINK"
                              disabled={disableButtonOnApiLoad}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div
                      className="d-flex justify-content-start light-font-text"
                      style={{}}
                    >
                      <div>
                        <NavLink
                          to="/"
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          <span className="d-flex light-font-text align-items-center gap-1 mb-3">
                            <FontAwesomeIcon
                              icon="fa-regular fa-arrow-left-long"
                              style={{
                                color: "#f1664b",
                                paddingTop: "2px",
                              }}
                            />{" "}
                            <span
                              style={{
                                color: data?.FormLinkandTextboxCheckColor,
                              }}
                            >
                              {" "}
                              Go to login
                            </span>
                          </span>
                        </NavLink>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      ))}
    </>
  );
};

export default ResendLink;
