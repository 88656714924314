import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import ETButton from "../../../../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import ReactSelect from "../../../../../Components/DropDown/ReactSelect";
import PromoToolTip from "../../../../../Components/Tooltip/PromoToolTip";

const NameYourPromotionLabel = ({
  setPromotionLabelFlow,
  setStatusProgress,
  getlanguages,
  isPromotionLabelEdit,
  stepperState,
  setStepperState,
  handleAddPromotionLabel,
  disableAddActionPost,
  disableAddActionPut,
}) => {
  const [langData, setLangData] = useState([]);
  //-----------validation and useForm-----------//
  const {
    register,
    clearErrors,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      promotionLabel:
        stepperState.language?.[0]?.language_text === null
          ? [
              {
                language_id: "",
                external_label: "",
                price_text_label:
                  stepperState?.is_price_visible === 0 ? "" : "-",
                language_text: null,
              },
            ]
          : stepperState?.language?.map((item, i) => ({
              language_id: item?.language_id,
              external_label: item?.external_label,
              price_text_label: item?.price_text,
              language_text: {
                value: item?.language_id,
                label: getlanguages?.data?.filter(
                  (el) => el.Id == item?.language_id
                )?.[0]?.Text,
              },
            })),
    },
  });

  //--------------use field array---------------//
  const { fields, append, remove } = useFieldArray({
    control,
    name: "promotionLabel",
  });

  useEffect(() => {
    setLangData(stepperState?.language);
    return () => {};
  }, [stepperState]);

  //--------------handle add language------------//
  const handleAddButton = () => {
    append({
      language_id: "",
      external_label: "",
      price_text_label: stepperState?.is_price_visible === 0 ? "" : "-",
      language_text: "",
    });
  };

  //--------------handle delete language-------------//
  const deleteLanguage = (i) => {
    remove(i);
    const newlanguageData = stepperState?.language?.filter(
      (x, index) => index !== i
    );
    setStepperState({
      ...stepperState,
      language: newlanguageData,
    });
  };

  //------------------handle selected language--------------//
  const selectedLanguage = (e, index) => {
    if (langData?.filter((el) => el == e.value).length > 0) {
      toast.error("Language already exist!");
    } else {
      if (fields.length - 1 == langData.length) {
        setLangData([...langData, e.value]);
      } else {
        langData[fields.length - 1] = e.value;
      }
    }
  };

  //-------------------handle previous click---------------//
  const onPrev = (data) => {
    setStepperState({
      ...stepperState,
      language: data?.promotionLabel?.map((item) => ({
        language_id: item?.language_text?.value,
        external_label: item?.external_label,
        price_text: item?.price_text_label,
        language_text: item?.language_text?.label,
      })),
    });
    setPromotionLabelFlow("CreateNewPromotionLabel");
    setStatusProgress(30);
  };

  //---------------handle next click--------------//
  const onNext = async (data) => {
    (async () => {
      setStepperState({
        ...stepperState,
        language: data?.promotionLabel?.map((item) => ({
          language_id: item?.language_text?.value,
          external_label: item?.external_label,
          price_text: item?.price_text_label,
          language_text: item?.language_text?.label,
        })),
      });
    })();

    const languageData = data?.promotionLabel?.map((item) => ({
      language_id: item?.language_text?.value,
      external_label: item?.external_label,
      price_text: item?.price_text_label,
      language_text: item?.language_text?.label,
    }));

    handleAddPromotionLabel(languageData);
  };

  const displayItem = (selected) => {
    const countyName = getlanguages?.data?.filter(
      (item, i) => item.Id === selected?.value
    )?.[0]?.Text;

    return {
      value: selected?.Id,
      label: (
        <div>
          <div className="fs-14 fw-300">
            {
              getlanguages?.data?.filter(
                (item, i) => item.Id === selected?.value
              )?.[0]?.Text
            }
          </div>
        </div>
      ),
    };
  };

  return (
    <div>
      <Form>
        <div
          style={{ height: "500px" }}
          className="scrollable-container-y pe-0 me-2"
        >
          <>
            <div
              className="fs-32 form-label-title mt-5 pt-3"
              style={{ textAlign: "center" }}
            >
              {isPromotionLabelEdit ? "Edit" : "Name"} your promotion label
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                className="light-font-text mt-3 px-2 "
                style={{ textAlign: "center", width: "495px" }}
              >
                You need to choose what the preferred language is on the new
                status label. In a moment it will be possible to add more
                languages if you need them.
              </div>
            </div>
            <div className="add-new-status-label d-flex justify-content-end py-2 mt-3 pe-3">
              <div>
                {" "}
                <ETButton
                  //   disabled={
                  //     language?.length == getlanguages?.data?.length + 1
                  //       ? true
                  //       : false
                  //   }
                  variant={"primary"}
                  buttonText="ADD ADDITIONAL LANGUAGE"
                  icon={true}
                  onClick={handleAddButton}
                />
              </div>
            </div>
            {fields?.map((item, index) => {
              return (
                <li
                  key={item.id}
                  style={{ listStyleType: "none" }}
                  className="my-3 ms-4 me-2"
                >
                  <Row key={index}>
                    <Col sm={11} className="pe-0">
                      <Row>
                        <Col className="pe-5">
                          <Controller
                            {...register(
                              `promotionLabel.${index}.language_text`
                            )}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              console.log("first", value),
                              (
                                <ReactSelect
                                  placeholder="Language"
                                  value={
                                    value === null ? "" : displayItem(value)
                                  }
                                  clearable={false}
                                  isSearchable
                                  onChange={(e, ActionTypes) => {
                                    onChange(e);
                                    setValue(
                                      `promotionLabel.${index}.language_id`,
                                      e?.value
                                    );
                                    clearErrors(
                                      `promotionLabel.${index}.language_text`
                                    );
                                    selectedLanguage(e, index);
                                  }}
                                  error={errors.language_id}
                                  options={getlanguages?.data?.map(
                                    (item, i) => ({
                                      value: item?.Id,
                                      label: (
                                        <div
                                          className="d-flex align-items-center"
                                          style={{ fontFamily: "Cerebri sans" }}
                                        >
                                          <div className="radius-4 me-2">
                                            <img
                                              src={item.DataValue1}
                                              style={{
                                                width: "20px",
                                                marginRight: "10px",
                                              }}
                                            />
                                          </div>
                                          <div>
                                            <div className="fs-14 light-font-text ">
                                              {" "}
                                              {item?.Text}
                                            </div>
                                          </div>
                                        </div>
                                      ),

                                      isDisabled: langData?.some(
                                        (lang) =>
                                          item.Id === lang ||
                                          item.Id === lang?.language_id
                                      ),
                                    })
                                  )}
                                />
                              )
                            )}
                            name={`promotionLabel.${index}.language_text`}
                            control={control}
                          />
                          {errors?.promotionLabel?.[index]?.language_text && (
                            <p className="error-msg">This is required</p>
                          )}
                        </Col>
                        <Col className="pe-5">
                          <div
                            className="w-100"
                            style={{ position: "relative" }}
                          >
                            <div className="d-flex align-items-center">
                              <div
                                style={{ position: "absolute", left: "-22px" }}
                              >
                                <PromoToolTip
                                  text={
                                    <div
                                      style={{ width: "207px" }}
                                      className="p-2"
                                    >
                                      <div className="actors-label-title">
                                        Promotion label
                                      </div>
                                      <div className="actors-lite-text">
                                        Is the label that will be visible in the
                                        estatetool, e.g. on the unit list and
                                        dashboard.
                                      </div>
                                    </div>
                                  }
                                />
                              </div>
                              <Controller
                                {...register(
                                  `promotionLabel.${index}.external_label`
                                )}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                  <div className="form-input ">
                                    <input
                                      type="text"
                                      placeholder="Promotion label"
                                      id={`promotionLabel.${index}.external_label`}
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        clearErrors(
                                          `promotionLabel.${index}.external_label`
                                        );
                                      }}
                                    />
                                    <label
                                      htmlFor={`promotionLabel.${index}.external_label`}
                                    >
                                      Promotion label
                                    </label>
                                  </div>
                                )}
                                name={`promotionLabel.${index}.external_label`}
                                control={control}
                              />
                            </div>
                          </div>
                          {errors?.promotionLabel?.[index]?.external_label && (
                            <p className="error-msg">This is required</p>
                          )}
                        </Col>
                        <Col className="pe-5">
                          <div
                            className="w-100 "
                            style={{ position: "relative" }}
                          >
                            <div className="d-flex align-items-center">
                              <div
                                style={{ position: "absolute", left: "-22px" }}
                              >
                                <PromoToolTip
                                  text={
                                    <div
                                      style={{ width: "207px" }}
                                      className="p-2"
                                    >
                                      <div className="actors-label-title">
                                        Price text label
                                      </div>
                                      <div className="actors-lite-text">
                                        If you have chosen to hide the price,
                                        please enter the text that will be
                                        visible instead of the price. E.g. Call
                                        for price. It is also possible not to
                                        enter any price text label and a hyphen
                                        will be shown instead.
                                      </div>
                                    </div>
                                  }
                                />
                              </div>
                              <Controller
                                {...register(
                                  `promotionLabel.${index}.price_text_label`
                                )}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                  <div className="form-input">
                                    <input
                                      type="text"
                                      placeholder="Price text label"
                                      id={`promotionLabel.${index}.price_text_label`}
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        clearErrors(
                                          `promotionLabel.${index}.price_text_label`
                                        );
                                      }}
                                    />
                                    <label
                                      htmlFor={`promotionLabel.${index}.price_text_label`}
                                    >
                                      Price text label
                                    </label>
                                  </div>
                                )}
                                name={`promotionLabel.${index}.price_text_label`}
                                control={control}
                              />
                            </div>
                          </div>
                          {errors?.promotionLabel?.[index]
                            ?.price_text_label && (
                            <p className="error-msg">This is required</p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col className="d-flex justify-content-center align-items-start mt-1 pe-0">
                      <button
                        disabled={fields.length === 1 && true}
                        type="button"
                        onClick={() => {
                          deleteLanguage(index);
                        }}
                        className=" d-flex justify-content-center align-items-center  py-2"
                        style={{
                          border: "1px solid #F0F0F0",
                          backgroundColor: "#F9FAFA",
                          borderRadius: "8px",
                          cursor: "pointer",
                          width: "40px",
                        }}
                      >
                        <FontAwesomeIcon icon="fa-regular fa-trash" />
                      </button>
                    </Col>
                  </Row>
                </li>
              );
            })}
          </>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="PREVIOUS"
            onClick={handleSubmit(onPrev)}
            type={"submit"}
          />
          <ETButton
            disabled={
              (fields.length === 0 && true) ||
              disableAddActionPost ||
              disableAddActionPut
            }
            variant={"primary"}
            buttonText="NEXT"
            type={"submit"}
            onClick={handleSubmit(onNext)}
          />
        </div>
      </Form>
    </div>
  );
};

export default NameYourPromotionLabel;
