import { useMutation, useQuery } from "react-query";
import {
  GetEventsByPropertyId,
  GetEventsDetails,
  GetEventsParticipant,
  GetEventsSource,
  GetEventsType,
  GetEventAgentDetails,
  PostAddEvent,
  GetEventsDetailByEventId,
} from "../../services/Events";

// Get events source
export const useGetEventsSource = () => {
  return useQuery(["qGetEventsSource"], () => GetEventsSource());
};

// Get events type
export const useGetEventsType = () => {
  return useQuery(["qGetEventsType"], () => GetEventsType());
};
// Get events agent Details
export const useGetEventAgentDetails = (propId) => {
  return useQuery(
    ["qGetEventsAgentDetails", propId],
    () => GetEventAgentDetails(propId),
    {
      enabled: !!propId,
    }
  );
};
// Post Add event
export const usePostAddEvent = () => {
  return useMutation(PostAddEvent);
};

// Get events by propertyId
export const useGetEventsByPropertyId = (
  propertyId,
  eventType,
  agentuserId
) => {
  return useQuery(
    ["qGetEventsByPropertyId", propertyId, eventType, agentuserId],
    () => GetEventsByPropertyId(propertyId, eventType, agentuserId),
    {
      enabled: !!propertyId,
    },
    {
      enabled: !!eventType,
    },
    {
      enabled: !!agentuserId,
    }
  );
};

//Get events details
export const useGetEventsDetails = (eventsId) => {
  return useQuery(
    ["qGetEventsDetails", eventsId],
    () => GetEventsDetails(eventsId),
    {
      enabled: !!eventsId,
    }
  );
};

//Get events participant
export const useGetEventsParticipant = (eventsParticipantId) => {
  return useQuery(
    ["qGetEventsParticipant", eventsParticipantId],
    () => GetEventsParticipant(eventsParticipantId),
    {
      enabled: !!eventsParticipantId,
    }
  );
};

//Get events Detail By EventId
export const useGetEventsDetailByEventId = (
  property_id,
  event_id,
  eventCalendarEntry_id
) => {
  return useQuery(
    ["qGetEventsDetailByEventId", property_id, event_id, eventCalendarEntry_id],
    () =>
      GetEventsDetailByEventId(property_id, event_id, eventCalendarEntry_id),
    {
      enabled: !!property_id && !!event_id && !!eventCalendarEntry_id,
    }
    // {
    //   enabled: !!event_id,
    // },
    // {
    //   enabled: !!eventCalendarEntry_id,
    // }
  );
};

// Delete Events
// export const useDeleteEvents = () => {
//   return useMutation(DeleteEvents);
// };
