import React, { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import { useGetlanguages } from "../../../../hooks/queries/uselanguageQueries";
import {
  useEditStatusLabel,
  useGetStatusLabelByStatusId,
  useGetStatusLabelCategory,
} from "../../../../hooks/queries/useStatusLabelQueries";
import Menus from "../../../../Components/Menus/Menus";
import { DeleteStatusLabelUsingStatusId } from "../../../../services/StatusLabel";
import { DeletePromotionLabel } from "./DeletePromotionLabel";
import { MaterialReactTable } from "material-react-table";

import CustomPopup from "../../../../Components/CustomPopup";
import NameYourPromotionLabel from "./addPromotionLabel/NameYourPromotionLabel";

import HideAndSeek from "./addPromotionLabel/HideAndSeek";
import AddColorStatus from "./addPromotionLabel/AddColorStatus";

import FinalPromotionLabelPage from "./addPromotionLabel/FinalPromotionLabelPage";
import { status } from "../../../../model/Constant";
import moment from "moment";
import { usePropertyId } from "../../../../hooks/stores/usePropertyStore";
import { Col, Row } from "react-bootstrap";
import MRTTheme from "../../../../Components/table/MRTTheme";
import {
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../../Components/table/MRTGlobalProps";
import { Tooltip, styled, tooltipClasses } from "@mui/material";
import {
  useAddPromotionLabel,
  useGetPromotionLabelByLabelId,
  useGetPromotionLabelByPropertyId,
  usePutUpdatePromotionLabel,
} from "../../../../hooks/queries/usePromotionLabelQueries";
import CreateNewPromotionLabel from "./addPromotionLabel/CreateNewPromotionLabel";
import "./statusLabels.css";
import { Delete_PromotionLabel } from "../../../../services/PromotionLabel";

const Labels = ({
  stepperState,
  setStepperState,
  showPromotionLabelPopup,
  setShowPromotionLabelPopup,
  promotionLabelFlow,
  setPromotionLabelFlow,
  statusProgress,
  setStatusProgress,
  isPromotionLabelEdit,
  setIsPromotionLabelEdit,
  getPromotionLabelByPropertyIdData,
  getPromotionLabelByPropertyIdDataRefetch,
}) => {
  //--------------zustand----------//
  const propertyId = usePropertyId((state) => state.propertyId);
  //----------States --------------//

  const [data, setData] = useState();
  const [deletePopupToggle, setDeletePopupToggle] = useState(false);
  const [statIdUnitCount, setStatIdUnitCount] = useState({
    promotionLabelId: "",
    unitCount: "",
    configurationCount: "",
  });
  const [tableData, setTableData] = useState([]);
  const [disableDeleteAction, setDisableDeleteAction] = useState(false);

  //-------States for Edit status label---------//
  const [promotionLabelId, setPromotionLabelId] = useState("");
  const onSave = (data) => {
    setData({ ...data });
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top-start" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme?.palette?.common.white,
      border: "1px solid #d1d1d1",
      padding: "20px",
      borderRadius: "8px",
      width: "165px",
      height: "152px",
      boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
    },
  }));

  //-----------------------react query------------------//
  const { data: getlanguages, isLoading } = useGetlanguages();
  const {
    data: getPromotionLabelByLabelIdData,
    refetch: getPromotionLabelByLabelIdDataRefetch,
  } = useGetPromotionLabelByLabelId(propertyId, promotionLabelId);

  const {
    mutateAsync: PutUpdatePromotionLabel,
    isLoading: PutUpdatePromotionLabelIsLoading,
  } = usePutUpdatePromotionLabel();
  const {
    mutateAsync: PostAddPromotionLabel,
    isLoading: PostAddPromotionLabelIsLoading,
  } = useAddPromotionLabel();

  useEffect(() => {
    setTableData(getPromotionLabelByPropertyIdData?.data);
  }, [getPromotionLabelByPropertyIdData]);
  const handleDelete = (id, unitCount, configurationCount) => {
    setStatIdUnitCount({
      promotionLabelId: id,
      unitCount: unitCount,
      configurationCount: configurationCount,
    });
    setDeletePopupToggle(true);
  };

  //-----------------------handle delete promotion label------------------//
  const handleDeletePromotionLabel = async (newPromotionLabelId = 0) => {
    setDisableDeleteAction(true);
    Delete_PromotionLabel(
      statIdUnitCount?.promotionLabelId,
      newPromotionLabelId
    )
      .then((response) => {
        if (response.data.bReturn) {
          toast.success(response.data.message);
          setDeletePopupToggle(false);
          getPromotionLabelByPropertyIdDataRefetch();
          setDisableDeleteAction(false);
        } else {
          toast.error(response.data.message);
          setDisableDeleteAction(false);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setDisableDeleteAction(false);
      });
  };

  //-----------------------handle edit promotion label------------------//
  const handleEditPromotionLabel = (label_id) => {
    setPromotionLabelId(label_id);
    setStatusProgress(33.33);
    setIsPromotionLabelEdit(true);
    setShowPromotionLabelPopup(true);
    setPromotionLabelFlow("CreateNewPromotionLabel");
  };

  const columns = useMemo(() => [
    {
      id: "Box",
      header: "",
      enableHiding: false,
      size: 20,
      Cell: ({ row }) => (
        <>
          <LightTooltip
            title={
              <>
                <div className="form-label-title c-black mb-1">
                  Background color
                </div>
                <div className="d-flex gap-2 mb-4  align-items-center">
                  <div
                    style={{
                      height: "17px",
                      width: "17px",
                      borderRadius: "4px",
                      background: row?.original?.background_color,
                    }}
                  >
                    {" "}
                  </div>{" "}
                  <div className="light-font-text c-black">
                    {row?.original?.background_color}
                  </div>{" "}
                </div>
                <div className="form-label-title c-black mb-1">Font color</div>
                <div className="d-flex gap-2 align-items-center ">
                  <div
                    style={{
                      height: "17px",
                      width: "17px",
                      borderRadius: "4px",
                      backgroundColor: row?.original?.font_color
                        ? row?.original?.font_color
                        : "#000000",
                    }}
                  ></div>{" "}
                  <div className="light-font-text c-black">
                    {row?.original?.font_color}
                  </div>{" "}
                </div>
              </>
            }
          >
            <div
              className="radius-4 ms-2 cursor-pointer"
              style={{
                background: `linear-gradient(135deg, ${
                  row?.original?.background_color
                    ? row?.original?.background_color
                    : "#619130"
                } 50%, ${"#000000"} 50%)`,

                backgroundColor: row?.original?.font_color
                  ? row?.original?.font_color
                  : "#000000",
                width: "17px",
                height: "17px",
              }}
            ></div>
          </LightTooltip>
        </>
      ),
      //center align the header and body cells
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
    },

    {
      accessorKey: "internal_label",
      header: "Internal label",
      // size: 50,
      enableGlobalFilter: true,
    },

    {
      accessorFn: (row) => `${row.is_price_visible}`,
      header: "Price visible",
      enableGlobalFilter: true,
      size: 80,
      Cell: ({ row }) => (
        <>
          <div>{row?.original?.is_price_visible == 1 ? "Yes" : "No"}</div>
        </>
      ),
    },
    {
      accessorFn: (row) => `${row.is_price_visible}`,
      size: 80,

      header: "Language",
      Cell: ({ row }) => (
        <>
          <div>{row?.original?.language_count}</div>
        </>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorFn: (row) => `${row.unit_count}`,
      header: "Used on",
      size: 80,
      Cell: ({ row }) => (
        <>
          <div>{row?.original?.unit_count}</div>
        </>
      ),
      enableGlobalFilter: true,
    },
  ]);

  useEffect(() => {
    if (isPromotionLabelEdit) {
      setStepperState((prevState) => {
        return {
          ...prevState,
          internal_label:
            getPromotionLabelByLabelIdData?.data?.[0]?.internal_label,
          property_id: propertyId,
          is_price_visible:
            getPromotionLabelByLabelIdData?.data?.[0]?.is_price_visible,
          font_color: getPromotionLabelByLabelIdData?.data?.[0]?.font_color,
          background_color:
            getPromotionLabelByLabelIdData?.data?.[0]?.background_color,
          language:
            getPromotionLabelByLabelIdData?.data?.[0]
              ?.promotion_label_language_json,
        };
      });
    }
  }, [getPromotionLabelByLabelIdData, isPromotionLabelEdit]);

  const renderEmptyRowsFallback = () => (
    <div className="d-flex justify-content-center w-100 py-4 grey-text">
      <div>
        You do not have any status label yet. Click on “+ ADD STATUS LABEL” to
        create your first.
      </div>
    </div>
  );

  //------------------handle add/update promotion label----------------//
  const handleAddPromotionLabel = async (data) => {
    let id;
    if (isPromotionLabelEdit) {
      const editPromotionLabelPayload = {
        promotion_label_id: String(promotionLabelId),
        internal_label: stepperState?.internal_label,
        is_price_visible: String(stepperState?.is_price_visible),
        font_color: stepperState?.font_color,
        background_color: stepperState?.background_color,
        language: data?.map((item) => ({
          language_id: item?.language_id,
          external_label: item?.external_label,
          price_text: item?.price_text,
        })),
      };
      PutUpdatePromotionLabel(editPromotionLabelPayload)
        .then((response) => {
          if (response.status === status.s && response.data.bReturn) {
            toast.success(response.data.message);
            getPromotionLabelByPropertyIdDataRefetch();
            setPromotionLabelFlow("FinalPromotionLabelPage");
            setStatusProgress(99.99);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      const addPromotionLabelPayload = {
        internal_label: stepperState?.internal_label,
        property_id: String(stepperState?.property_id),
        is_price_visible: String(stepperState?.is_price_visible),
        font_color: stepperState?.font_color,
        background_color: stepperState?.background_color,
        language: data?.map((item) => ({
          language_id: item?.language_id,
          external_label: item?.external_label,
          price_text: item?.price_text,
        })),
      };
      if (!PostAddPromotionLabelIsLoading) {
        id = toast.loading("Promotion Label Added...");
      }
      PostAddPromotionLabel(addPromotionLabelPayload)
        .then((response) => {
          if (response?.data?.bReturn) {
            toast.update(id, {
              render: response?.data?.message,
              isLoading: false,
              type: status.s,
              autoClose: 2000,
            });
            getPromotionLabelByPropertyIdDataRefetch();
            setPromotionLabelFlow("FinalPromotionLabelPage");
            setStatusProgress(99.99);
          } else {
            toast.update(id, {
              render: response?.data?.message,
              isLoading: false,
              type: status.e,
              autoClose: 2000,
            });
          }
        })
        .catch((error) => {
          toast.update(id, {
            render: error?.message,
            isLoading: false,
            type: status.e,
            autoClose: 2000,
          });
        });
    }
  };

  const StatusLabelWizardsPages = {
    CreateNewPromotionLabel: (
      <CreateNewPromotionLabel
        setPromotionLabelFlow={setPromotionLabelFlow}
        setStatusProgress={setStatusProgress}
        setShowPromotionLabelPopup={setShowPromotionLabelPopup}
        isPromotionLabelEdit={isPromotionLabelEdit}
        stepperState={stepperState}
        setStepperState={setStepperState}
      />
    ),

    NameYourPromotionLabel: (
      <NameYourPromotionLabel
        getlanguages={getlanguages}
        setPromotionLabelFlow={setPromotionLabelFlow}
        setStatusProgress={setStatusProgress}
        isPromotionLabelEdit={isPromotionLabelEdit}
        stepperState={stepperState}
        setStepperState={setStepperState}
        onSave={onSave}
        handleAddPromotionLabel={handleAddPromotionLabel}
        disableAddActionPost={PostAddPromotionLabelIsLoading}
        disableAddActionPut={PutUpdatePromotionLabelIsLoading}
      />
    ),

    FinalPromotionLabelPage: (
      <FinalPromotionLabelPage
        setShowPromotionLabelPopup={setShowPromotionLabelPopup}
        isPromotionLabelEdit={isPromotionLabelEdit}
        stepperState={stepperState}
        getlanguages={getlanguages}
      />
    ),
  };
  return (
    <div className="py-3 px-4">
      <Row>
        <Col className="pe-lg-0" lg={11}>
          <div
            className="light-font-text"
            style={{
              textAlign: "justify",
            }}
          >
            A promotion label is the status label that a unit will be promoted
            with on a website, house selector, my page etc. if estatetools APIs
            are used. A promotion label is dependent on the two system statuses;
            Main status and Sub status. It is possible to create individual
            setups per property and it is also possible to manually change a
            promotion status on a device.
          </div>
        </Col>
      </Row>
      <CustomPopup
        style={{ position: "relative" }}
        size="xl"
        open={showPromotionLabelPopup}
        editImageDetails={true}
        onHide={() => {
          setShowPromotionLabelPopup(false);
        }}
        title={
          isPromotionLabelEdit
            ? "Edit promotion label"
            : "Create promotion label"
        }
        title_color="black"
        titleTooltipText={
          isPromotionLabelEdit
            ? "Edit promotion label"
            : "Create promotion label"
        }
        titleTooltip={true}
        enableCrossButton={false}
        onCloseAction={() => {
          setShowPromotionLabelPopup(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div>
          <LoadingBar
            color="#619130"
            progress={statusProgress}
            containerStyle={{
              position: "absolute",
              height: "2.5px",
              background: "#f0f0f0",
            }}
            shadow={false}
            onLoaderFinished={() => setStatusProgress(0)}
          />
        </div>
        <div>{StatusLabelWizardsPages[promotionLabelFlow]}</div>
      </CustomPopup>

      <div className="mt-3 pt-1">
        <MRTTheme
          colorVariantsForTableData={["odd"]}
          table={
            <MaterialReactTable
              columns={columns}
              data={tableData ?? []}
              icons={{ ...SortIcon }}
              renderEmptyRowsFallback={renderEmptyRowsFallback}
              enableRowActions
              positionActionsColumn="last"
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "",
                },
                // "mrt-row-select": {
                //   header: "",
                // },
              }}
              renderRowActions={({ row }) => (
                <Menus
                  itemheight={23}
                  itemwidth={23}
                  iconborderradius="4px"
                  itemcontainerwidth="120px"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  component={
                    <>
                      <div style={{ fontSize: "14px" }}>
                        <div
                          onClick={() =>
                            handleEditPromotionLabel(
                              row?.original?.promotion_label_id
                            )
                          }
                          className="addpropContent px-3 py-2 "
                        >
                          <span className="ms-1">Edit</span>
                        </div>
                        <hr className="addpropertyHr m-0"></hr>
                        <div
                          className="addpropContent px-3 pt-2 pb-2 "
                          onClick={() =>
                            handleDelete(
                              row.original?.promotion_label_id,
                              row.original?.unit_count,
                              row?.original?.promotion_label_configuration_count
                            )
                          }
                        >
                          <span className="ms-1">Delete</span>
                        </div>
                      </div>
                    </>
                  }
                />
              )}
              // getRowId={(originalRow) => originalRow?.facility_id}
              // onRowSelectionChange={setRowSelection}
              // state={{ rowSelection }}
              enableTopToolbar={false}
              enableSelectAll={false}
              enableColumnActions={false}
              enableColumnFilter={false}
              enableResizing={false}
              initialState={{ showGlobalFilter: true }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
              enableColumnFilterModes={false}
            />
          }
        />
      </div>
      <DeletePromotionLabel
        getPromotionLabelByPropertyIdData={getPromotionLabelByPropertyIdData}
        handleDeletePromotionLabel={handleDeletePromotionLabel}
        disableDeleteAction={disableDeleteAction}
        statIdUnitCount={statIdUnitCount}
        deletePopupToggle={deletePopupToggle}
        setDeletePopupToggle={setDeletePopupToggle}
        getPromotionLabelByPropertyIdDataRefetch={
          getPromotionLabelByPropertyIdDataRefetch
        }
      />
    </div>
  );
};

export default Labels;
