// created by Suraj
import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import photoComingSoon from "../../assets/images/photo-coming-soon.jpg";
import { H4 } from "../../styles/Common.style";
import { useDivisionId } from "../../hooks/stores/useDivisionsStore";
import { useGetDivisionOverviewByDivisionId } from "../../hooks/queries/useDivisionQueries";
import NewColorBox from "../ColorCombinationbox/NewColorBox";
import { ImageComponent, ShortNameComponent } from "../../widget/Widget";

const DivisionLevelTopBar = () => {
  const { divisionId } = useDivisionId((state) => state);
  // const division_name = useDivisionName((state) => state.divisionName);

  // query
  const { data: GetDivisionOverviewByDivisionIdData } =
    useGetDivisionOverviewByDivisionId(divisionId);

  // console.log("DATA", GetDivisionOverviewByDivisionIdData);

  return (
    <div>
      {GetDivisionOverviewByDivisionIdData?.data?.map((item, i) => (
        <Row
          key={i}
          className="ms-xxl-5 ms-lg-4 ms-3 pt-4 pb-3  px-xxl-1 px-1 align-items-center"
        >
          <Col
            xl={3}
            lg={5}
            md={5}
            className="px-md-2 d-flex justify-content-md-start justify-content-center ellipsis"
          >
            <ImageComponent
              imageUrl={item?.logo_thumb_url}
              altText={"division image"}
              width="100px"
              height="70px"
              shortNameComponent={
                <ShortNameComponent
                  width="100px"
                  height="70px"
                  backgroundColor={item?.shortname_background_color}
                  fontColor={item?.shortname_text_color}
                  shortNameText={item?.shortname}
                />
              }
            />
            <div className="my-md-0 my-1 ps-3 d-flex  align-items-center ">
              <div>
                <H4 className="ellipsis" style={{ lineHeight: "22px" }}>
                  {item?.division_name}
                </H4>
                <div className="grey-info  fs-14 line-height-20">
                  <div className="m-0 ellipsis line-height-20">
                    Owner: {item?.owner_name ? item?.owner_name : "-"}
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col xl={6} lg={3} md={3}>
            <Row>
              <Col xl={3}>
                <div className="fs-14  line-height-20">
                  <span>Companies</span> :&nbsp;
                  <span className="grey-info">
                    {item?.companies ? item?.companies : 0}
                  </span>
                </div>
              </Col>
              <Col xl={3}>
                <div className="fs-14  line-height-20">
                  <span>Properties</span> :&nbsp;
                  <span className="grey-info">
                    {item?.properties_count ? item?.properties_count : 0}
                  </span>
                </div>
              </Col>
              <Col xl={3}>
                <div className="d-block text-truncate fs-14  line-height-20 ">
                  <span>Units</span> :&nbsp;
                  <span className="text-capitalize grey-info">
                    {item?.total_unit ? item?.total_unit : 0}
                  </span>
                </div>
              </Col>
              <Col xl={3}>
                <div className=" fs-14">
                  <span>Active users </span> :&nbsp;
                  <span className="grey-info">
                    {item?.active_users ? item?.active_users : 0}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xl={3} lg={4} md={4} className="ps-xl-2 ps-lg-0">
            <Row>
              <Col className="d-lg-flex  align-items-center ">
                <div className="fs-14">Overall status:&nbsp;</div>
                <NewColorBox
                  unitsOverview={item?.overall_status}
                  TotalUnits={item?.total_unit}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default DivisionLevelTopBar;
const item = {};
// Added Image component created for short name and image --- Updated by Suraj
