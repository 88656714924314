export function capitalizeEachWord(inputString) {
  // Check if the input is a non-empty string
  if (typeof inputString !== "string" || inputString.length === 0) {
    return inputString; // Return unchanged input for invalid or empty strings
  }

  // Split the string into words, capitalize the first letter of each word, and join them back
  return inputString?.split(" ")?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))?.join(" ");
}

//   // Example usage:
//   const inputString = "hello world";
//   const capitalizedString = capitalizeEachWord(inputString);
//   console.log(capitalizedString); // Output: "Hello World"
