import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Partnersandrented from "./Partnersandrented";
import RentalsAndTermination from "./RentalsAndTermination";
import Paymentandrent from "./Paymentandrent";
import SpecialCondition from "./SpecialCondition";
import HeatingAndCooling from "./HeatingAndCooling";
import DepositandPrepaidRent from "./DepositandPrepaidRent";
import RentedProperty from "./RentedProperty";
import Inventory from "./Inventory";
import CommunalAntenna from "./CommunalAntenna";
import ConditionPremises from "./ConditionPremises";
import Maintenance from "./Maintenance";
import "../../documents.css";
import { AppProvider } from "../AllState";
import ETButton from "../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../Components/CustomPopup";
import {
  useGetDocumentConfigurationDetails,
  useUpdateDocumentConfiguration,
} from "../../../../hooks/queries/useDocumentsQueries";
import { GetDocumentPackageByPackageId } from "../../../../services/Documents";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { Link, NavLink } from "react-router-dom";
// import { Navigate } from "react-router-dom";
import {
  usePropertyId,
  usePropertyOverview,
} from "../../../../hooks/stores/usePropertyStore";
import { GreenSpan } from "../../../../styles/Common.style";
import { CircularProgress } from "@mui/material";
import { status } from "../../../../model/Constant";
import { H1 ,H2} from "../../../../styles/Common.style";

const Configuration = ({ configurationId }) => {
  // const propertyId = useSelector((state) => state.propertyId.filter); //REPLACED WITH ZUSTAND
  const propertyId = usePropertyId((state) => state.propertyId);
  // console.log(propertyId, "propertyIdpropertyIdpropertyId");

  const propOverview = usePropertyOverview((state) => state.propOverview);
  const [previewForm, setPreviewForm] = useState(false);

  const [url, setUrl] = useState("");
  const [buttonStyle, setButtonStyle] = useState(0);
  const [activeTenancyAgreementForm, setActiveAgreementForm] = useState("");
  const [tenancyAgreementIdData, setTenancyAgreementIdData] = useState([]);
  const [currentTemplateIndex, setCurrentTempleteIndex] = useState(0);
  const [docId, setDocId] = useState("");
  const [currentConfigurationSection, setCurrentConfigurationSection] =
    useState([]);
  const [preview, setPreview] = useState(false);
  const [
    currentConfigurationSectionIndex,
    setCurrentConfigurationSectionIndex,
  ] = useState(0);
  const currentID = 1;

  const { data: getDocumentPackageByPackageId } = useQuery(
    ["getDocumentPackageByPackageId", propertyId, configurationId],
    () => GetDocumentPackageByPackageId(propertyId, configurationId)
  );

  const {
    mutateAsync: PutUpdateDocumentConfiguration,
    isLoading: PutUpdateDocumentConfigurationIsLoading,
  } = useUpdateDocumentConfiguration();

  useEffect(() => {
    setActiveAgreementForm(
      tenancyAgreementIdData?.[currentTemplateIndex]?.document_type_name
    );
    setDocId(
      tenancyAgreementIdData?.[currentTemplateIndex]?.doc_file_id.toString()
    );
  }, [currentTemplateIndex, tenancyAgreementIdData]);

  //API for configuration detials//
  const {
    data: getConfigurationDetailsData,
    refetch: getConfigurationDetailsDataRefetch,
  } = useGetDocumentConfigurationDetails(docId);

  // console.log(getConfigurationDetailsData, "getConfigurationDetailsData");

  const handleEditConfig = async (data, apiData, i, isNext) => {
    const updateConfigurationPayload = {
      property_id: propertyId,
      document_type_id: "1",
      doc_id: apiData[0]?.document_package_document_id,
      section_details: [
        {
          section_id: 1,
          section_text: "§1 The partners and the rented",
          is_completed:
            apiData[0]?.configuration_section_details?.filter(
              (data) => data.section_id === 1
            )[0]?.section_id === i
              ? 1
              : apiData[0]?.configuration_section_details?.filter(
                  (data) => data.section_id === 1
                )[0]?.is_completed,
        },
        {
          section_id: 2,
          section_text: "§2 The rentals beginning and termination",
          is_completed:
            apiData[0]?.configuration_section_details?.filter(
              (data) => data.section_id === 2
            )[0]?.section_id === i
              ? 1
              : apiData[0]?.configuration_section_details?.filter(
                  (data) => data.section_id === 2
                )[0]?.is_completed,
        },
        {
          section_id: 3,
          section_text: "§3 Payment of rent",
          is_completed:
            apiData[0]?.configuration_section_details?.filter(
              (data) => data.section_id === 3
            )[0]?.section_id === i
              ? 1
              : apiData[0]?.configuration_section_details?.filter(
                  (data) => data.section_id === 3
                )[0]?.is_completed,
        },
        {
          section_id: 4,
          section_text: "§4 Deposit and pre-paid rent",
          is_completed:
            apiData[0]?.configuration_section_details?.filter(
              (data) => data.section_id === 4
            )[0]?.section_id === i
              ? 1
              : apiData[0]?.configuration_section_details?.filter(
                  (data) => data.section_id === 4
                )[0]?.is_completed,
        },
        {
          section_id: 5,
          section_text: "§5 Heating, cooling, water and electricity",
          is_completed:
            apiData[0]?.configuration_section_details?.filter(
              (data) => data.section_id === 5
            )[0]?.section_id === i
              ? 1
              : apiData[0]?.configuration_section_details?.filter(
                  (data) => data.section_id === 5
                )[0]?.is_completed,
        },
        {
          section_id: 6,
          section_text:
            "§6 Communal antenna etc. and access to electronic communications services",
          is_completed:
            apiData[0]?.configuration_section_details?.filter(
              (data) => data.section_id === 6
            )[0]?.section_id === i
              ? 1
              : apiData[0]?.configuration_section_details?.filter(
                  (data) => data.section_id === 6
                )[0]?.is_completed,
        },
        {
          section_id: 7,
          section_text:
            "§7 The condition of the premises when moving in and out",
          is_completed:
            apiData[0]?.configuration_section_details?.filter(
              (data) => data.section_id === 7
            )[0]?.section_id === i
              ? 1
              : apiData[0]?.configuration_section_details?.filter(
                  (data) => data.section_id === 7
                )[0]?.is_completed,
        },
        {
          section_id: 8,
          section_text: "§8 Maintenance",
          is_completed:
            apiData[0]?.configuration_section_details?.filter(
              (data) => data.section_id === 8
            )[0]?.section_id === i
              ? 1
              : apiData[0]?.configuration_section_details?.filter(
                  (data) => data.section_id === 8
                )[0]?.is_completed,
        },
        {
          section_id: 9,
          section_text: "§9 Inventory",
          is_completed:
            apiData[0]?.configuration_section_details?.filter(
              (data) => data.section_id === 9
            )[0]?.section_id === i
              ? 1
              : apiData[0]?.configuration_section_details?.filter(
                  (data) => data.section_id === 9
                )[0]?.is_completed,
        },
        {
          section_id: 10,
          section_text:
            "§10 Resident representative, animal husbandry, house rules and other information about the rented property",
          is_completed:
            apiData[0]?.configuration_section_details?.filter(
              (data) => data.section_id === 10
            )[0]?.section_id === i
              ? 1
              : apiData[0]?.configuration_section_details?.filter(
                  (data) => data.section_id === 10
                )[0]?.is_completed,
        },
        {
          section_id: 11,
          section_text: "§11 Special conditions",
          is_completed:
            apiData[0]?.configuration_section_details?.filter(
              (data) => data.section_id === 11
            )[0]?.section_id === i
              ? 1
              : apiData[0]?.configuration_section_details?.filter(
                  (data) => data.section_id === 11
                )[0]?.is_completed,
        },
      ],

      attribute_values: [
        {
          section_id: 1,
          actual_column_name: "Unit Id",
          column_name: "alt_unit_id",
          column_type: "text",
          possible_values: "",
          actual_value: "",
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "alt_unit_id"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "alt_unit_id"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Type of Rental",
          column_name: "rental_type",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "An apartment",
            },
            {
              Id: 2,
              Text: "A single room",
            },
            {
              Id: 3,
              Text: "A Cooperative housing",
            },
            {
              Id: 4,
              Text: "A condominium",
            },
            {
              Id: 5,
              Text: "Other",
            },
          ],
          actual_value:
            data?.rental_type === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "rental_type"
                )[0]?.actual_value
              : data?.rental_type,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rental_type"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rental_type"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Other",
          column_name: "other_1",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.other_1 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "other_1"
                )[0]?.actual_value
              : data?.other_1,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_1"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_1"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "The tenancy is a sublease",
          column_name: "ts",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.ts === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "ts"
                )[0]?.actual_value
              : data?.ts,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "ts"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "ts"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Address",
          column_name: "address",
          column_type: "text",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "address"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "address"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "address"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "City",
          column_name: "city",
          column_type: "text",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "city"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "city"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "city"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Landlord Name",
          column_name: "l_name",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.l_name === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_name"
                )[0]?.actual_value
              : data?.l_name,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_name"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_name"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "CVR (Company Registration Number)",
          column_name: "l_cvr",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.l_cvr === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_cvr"
                )[0]?.actual_value
              : data?.l_cvr,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_cvr"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_cvr"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Street",
          column_name: "l_street",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.l_street === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_street"
                )[0]?.actual_value
              : data?.l_street,
          is_merge: 0,
          is_mandatory: "",
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_street"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_street"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "No",
          column_name: "l_no",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.l_no === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_no"
                )[0]?.actual_value
              : data?.l_no,
          is_merge: 0,
          is_mandatory: "",
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_no"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_no"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Add. Info",
          column_name: "l_add_info",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.l_add_info === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_add_info"
                )[0]?.actual_value
              : data?.l_add_info,
          is_merge: 0,
          is_mandatory: "",
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_add_info"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_add_info"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Zipcode",
          column_name: "l_zipcode",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.l_zipcode === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_zipcode"
                )[0]?.actual_value
              : data?.l_zipcode,
          is_merge: 0,
          is_mandatory: "",
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_zipcode"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_zipcode"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "City",
          column_name: "l_city",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.l_city === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_city"
                )[0]?.actual_value
              : data?.l_city,
          is_merge: 0,
          is_mandatory: "",
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_city"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_city"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name:
            "Address (Street housenumber, add. Info and zipcode)",
          column_name: "l_address",
          column_type: "text",
          possible_values: "",
          actual_value: `${
            data?.l_street === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_street"
                )[0]?.actual_value
              : data?.l_street
          },${
            data?.l_no === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_no"
                )[0]?.actual_value
              : data?.l_no
          },${
            data?.l_add_info === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_add_info"
                )[0]?.actual_value
              : data?.l_add_info
          },${
            data?.l_city === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_city"
                )[0]?.actual_value
              : data?.l_city
          } ${
            data?.l_zipcode?.value === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_zipcode"
                )[0]?.actual_value.value
              : data?.l_zipcode?.value
          }`,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_address"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_address"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "country code",
          column_name: "l_country_code",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.l_country_code === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_country_code"
                )[0]?.actual_value
              : data?.l_country_code,
          is_merge: 0,
          is_mandatory: "",
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_country_code"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_country_code"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Phone",
          column_name: "l_phone1",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.l_phone1 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_phone1"
                )[0]?.actual_value
              : data?.l_phone1,
          is_merge: 0,
          is_mandatory: "",
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_phone1"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_phone1"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Phone (country code and phone)",
          column_name: "l_phone",
          column_type: "text",
          possible_values: "",
          actual_value: `${
            data?.l_country_code?.value === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_country_code"
                )[0]?.actual_value.value
              : data?.l_country_code?.value
          } ${
            data?.l_phone1 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_phone1"
                )[0]?.actual_value
              : data?.l_phone1
          }`,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_phone"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_phone"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Email",
          column_name: "l_email",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.l_email === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_email"
                )[0]?.actual_value
              : data?.l_email,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_email"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_email"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Tenant Name",
          column_name: "t_name",
          column_type: "text",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_name"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_name"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_name"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Tenant Street",
          column_name: "t_street",
          column_type: "text",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_street"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_street"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_street"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Tenant No",
          column_name: "t_no",
          column_type: "text",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_no"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_no"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_no"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Tenant Add. Info",
          column_name: "t_add_info",
          column_type: "text",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_add_info"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_add_info"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_add_info"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Tenant  Zipcode",
          column_name: "t_zipcode",
          column_type: "text",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_zipcode"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_zipcode"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_zipcode"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Tenant  City",
          column_name: "t_city",
          column_type: "text",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_city"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_city"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_city"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Tenant Address",
          column_name: "t_address",
          column_type: "text",
          possible_values: "",
          actual_value: `${
            apiData[0]?.configuration_values?.filter(
              (data) => data?.column_name == "t_street"
            )[0]?.actual_value
          }, ${
            apiData[0]?.configuration_values?.filter(
              (data) => data?.column_name == "t_no"
            )[0]?.actual_value
          }, ${
            apiData[0]?.configuration_values?.filter(
              (data) => data?.column_name == "t_add_info"
            )[0]?.actual_value
          }, ${
            apiData[0]?.configuration_values?.filter(
              (data) => data?.column_name == "t_city"
            )[0]?.actual_value
          }, ${
            apiData[0]?.configuration_values?.filter(
              (data) => data?.column_name == "t_zipcode"
            )[0]?.actual_value
          } `,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_address"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_address"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "country code",
          column_name: "t_country_code",
          column_type: "text",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_country_code"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_country_code"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_country_code"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Phone",
          column_name: "t_phone1",
          column_type: "text",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_phone1"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_phone1"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_phone1"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Tenant Phone",
          column_name: "t_phone",
          column_type: "text",
          possible_values: "",
          actual_value: `${
            apiData[0]?.configuration_values?.filter(
              (data) => data?.column_name == "t_country_code"
            )[0]?.actual_value
          } ${
            apiData[0]?.configuration_values?.filter(
              (data) => data?.column_name == "t_phone1"
            )[0]?.actual_value
          }`,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_phone"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_phone"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Tenant Email",
          column_name: "t_email",
          column_type: "text",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_email"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_email"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_email"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Gross Area",
          column_name: "area",
          column_type: "text",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "area"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "area"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "area"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Number of Rooms",
          column_name: "no_of_rooms",
          column_type: "text",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "no_of_rooms"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "no_of_rooms"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "no_of_rooms"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Business area",
          column_name: "area_business_premises",
          column_type: "text",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "area_business_premises"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "area_business_premises"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "area_business_premises"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Shared laundry",
          column_name: "fac1",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.fac1 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "fac1"
                )[0]?.actual_value
              : data?.fac1,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fac1"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fac1"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Bicycle parking",
          column_name: "fac2",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.fac2 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "fac2"
                )[0]?.actual_value
              : data?.fac2,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fac2"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fac2"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Common courtyards",
          column_name: "fac3",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.fac3 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "fac3"
                )[0]?.actual_value
              : data?.fac3,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fac3"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fac3"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Garage no.",
          column_name: "fac4",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.fac4 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "fac4"
                )[0]?.actual_value
              : data?.fac4,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fac4"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fac4"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Attic/basement room no.",
          column_name: "fac5",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.fac5 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "fac5"
                )[0]?.actual_value
              : data?.fac5,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fac5"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fac5"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Other",
          column_name: "fac6",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.fac6 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "fac6"
                )[0]?.actual_value
              : data?.fac6,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fac6"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fac6"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Other",
          column_name: "fac7",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.fac7 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "fac7"
                )[0]?.actual_value
              : data?.fac7,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fac7"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fac7"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Other",
          column_name: "fac8",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.fac8 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "fac8"
                )[0]?.actual_value
              : data?.fac8,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fac8"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fac8"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Garage No.",
          column_name: "garage_no",
          column_type: "text",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "garage_no"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "garage_no"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "garage_no"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Attic/Basement Room No.",
          column_name: "attic",
          column_type: "text",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "attic"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "attic"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "attic"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Other",
          column_name: "other_2",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.other_2 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "other_2"
                )[0]?.actual_value
              : data?.other_2,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_2"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_2"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Other",
          column_name: "other_3",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.other_3 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "other_3"
                )[0]?.actual_value
              : data?.other_3,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_3"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_3"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Other",
          column_name: "other_4",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.other_4 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "other_4"
                )[0]?.actual_value
              : data?.other_4,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_4"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_4"
          )[0]?.add_data,
        },
        {
          section_id: 1,
          actual_column_name: "Utilization",
          column_name: "utilization",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.utilization === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "utilization"
                )[0]?.actual_value
              : data?.utilization,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "utilization"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "utilization"
          )[0]?.add_data,
        },
        {
          section_id: 2,
          actual_column_name: "Start Date",
          column_name: "rental_start_date",
          column_type: "date",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data.column_name == "rental_start_date"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rental_start_date"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rental_start_date"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "Annual Rent",
          column_name: "annual_rent",
          column_type: "number",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data.column_name == "annual_rent"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "annual_rent"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "annual_rent"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "Due Date",
          column_name: "due_date",
          column_type: "dropdown",
          possible_values: [
            {
              Id: 1,
              Text: "The first day",
            },
            {
              Id: 2,
              Text: "The last day",
            },
          ],
          actual_value:
            data?.due_date?.label === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "due_date"
                )[0]?.actual_value
              : data?.due_date?.value,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "due_date"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "due_date"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "Due Data Frequency",
          column_name: "due_date_frequenncy",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Month",
            },
            {
              Id: 2,
              Text: "Quarter",
            },
          ],
          actual_value:
            data?.due_date_frequenncy === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "due_date_frequenncy"
                )[0]?.actual_value
              : data?.due_date_frequenncy == 1
              ? 2
              : 1,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "due_date_frequenncy"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "due_date_frequenncy"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "The monthly/quarterly rent",
          column_name: "rent_per_month",
          column_type: "number",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rent_per_month"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rent_per_month"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rent_per_month"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "A conto heating contribution",
          column_name: "aconto_heat",
          column_type: "number",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "aconto_heat"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "aconto_heat"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "aconto_heat"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "A conto water contribution",
          column_name: "aconto_water",
          column_type: "number",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "aconto_water"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "aconto_water"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "aconto_water"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "Aconto electricity contribution",
          column_name: "aconto_electricity",
          column_type: "number",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "aconto_electricity"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "aconto_electricity"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "aconto_electricity"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "Aconto cooling contribution",
          column_name: "aconto_cooling",
          column_type: "number",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "aconto_cooling"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "aconto_cooling"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "aconto_cooling"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "Antenna contribution",
          column_name: "fee_antenna",
          column_type: "number",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fee_antenna"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fee_antenna"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fee_antenna"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "Internet contribution",
          column_name: "fee_internet",
          column_type: "number",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fee_internet"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fee_internet"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "fee_internet"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "Contribution to resident representation",
          column_name: "aconto_residents_society",
          column_type: "number",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "aconto_residents_society"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "aconto_residents_society"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "aconto_residents_society"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "Other Text",
          column_name: "other_5",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.other_5 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "other_5"
                )[0]?.actual_value
              : data?.other_5,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_5"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_5"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "Other Text",
          column_name: "other_6",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.other_6 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "other_6"
                )[0]?.actual_value
              : data?.other_6,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_6"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_6"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "Other Value",
          column_name: "other_value_1",
          column_type: "number",
          possible_values: "",
          actual_value:
            data?.other_value_1 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "other_value_1"
                )[0]?.actual_value
              : data?.other_value_1,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_value_1"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_value_1"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "Other Value",
          column_name: "other_value_2",
          column_type: "number",
          possible_values: "",
          actual_value:
            data?.other_value_2 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "other_value_2"
                )[0]?.actual_value
              : data?.other_value_2,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_value_2"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_value_2"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "Total paid per month/quater",
          column_name: "total_paid",
          column_type: "number",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "total_paid"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "total_paid"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "total_paid"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "Not in Figma (Taxes)",
          column_name: "tax_date",
          column_type: "date",
          possible_values: "",
          actual_value:
            data?.tax_date === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "tax_date"
                )[0]?.actual_value
              : data?.tax_date,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "tax_date"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "tax_date"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "Not in Figma (Place of payment)",
          column_name: "payment_place",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "The rent etc. is paid to the landlord account No. (X) - (X) in (bank): Payments made to a bank are considered payment at the specified place of payment",
            },
            {
              Id: 2,
              Text: "The rent etc. is paid by direct debit (Betalingsservice)",
            },
          ],
          actual_value:
            data?.payment_place === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "payment_place"
                )[0]?.actual_value
              : data?.payment_place,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "payment_place"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "payment_place"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "Not in Figma (the landlord)",
          column_name: "account_1",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.account_1 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "account_1"
                )[0]?.actual_value
              : data?.account_1,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "account_1"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "account_1"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "Not in Figma",
          column_name: "account_2",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.account_2 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "account_2"
                )[0]?.actual_value
              : data?.account_2,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "account_2"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "account_2"
          )[0]?.add_data,
        },
        {
          section_id: 3,
          actual_column_name: "Not in Figma (financial institution)",
          column_name: "bank",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.bank === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "bank"
                )[0]?.actual_value
              : data?.bank,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "bank"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "bank"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Calculation of Dates",
          column_name: "calculation_of_dates",
          column_type: "dropdown",
          possible_values: [
            {
              Id: "+0 days",
              Text: "+0 days",
            },
            {
              Id: "+1 days",
              Text: "+1 days",
            },
            {
              Id: "+2 days",
              Text: "+2 days",
            },
            {
              Id: "+3 days",
              Text: "+3 days",
            },
            {
              Id: "+4 days",
              Text: "+4 days",
            },
            {
              Id: "+5 days",
              Text: "+5 days",
            },
            {
              Id: "+6 days",
              Text: "+6 days",
            },
            {
              Id: "+7 days",
              Text: "+7 days",
            },
            {
              Id: "+8 days",
              Text: "+8 days",
            },
            {
              Id: "+9 days",
              Text: "+9 days",
            },
            {
              Id: "+10 days",
              Text: "+10 days",
            },
          ],
          actual_value:
            data?.calculation_of_dates?.value === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "calculation_of_dates"
                )[0]?.actual_value
              : data?.calculation_of_dates?.value,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "calculation_of_dates"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "calculation_of_dates"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Upfront Payment",
          column_name: "upfront_payment",
          column_type: "dropdown",
          possible_values: [
            {
              Id: 0,
              Text: "No rent for the period",
            },
            {
              Id: 1,
              Text: "Exact days",
            },
            {
              Id: 2,
              Text: "Half price if id d 15",
            },
          ],
          actual_value:
            data?.upfront_payment?.value === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "upfront_payment"
                )[0]?.actual_value
              : data?.upfront_payment?.value,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "upfront_payment"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "upfront_payment"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Deposit Date",
          column_name: "deposit_date",
          column_type: "date",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_date"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_date"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_date"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Deposit Amount",
          column_name: "deposit_amount",
          column_type: "text",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_amount"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_amount"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_amount"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Deposit Month",
          column_name: "deposit_month",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.deposit_month === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "deposit_month"
                )[0]?.actual_value
              : data?.deposit_month,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_month"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_month"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Prepaid Rent Date",
          column_name: "prepaid_rent_date",
          column_type: "date",
          possible_values: "",
          actual_value:
            data?.prepaid_rent_date === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "prepaid_rent_date"
                )[0]?.actual_value
              : data?.prepaid_rent_date,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 1,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "prepaid_rent_date"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "prepaid_rent_date"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Prepaid Rent Amount",
          column_name: "prepaid_rent_amount",
          column_type: "number",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "prepaid_rent_amount"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "prepaid_rent_amount"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "prepaid_rent_amount"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Equivalent to",
          column_name: "prepaid_rent_month",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.prepaid_rent_month === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "prepaid_rent_month"
                )[0]?.actual_value
              : data?.prepaid_rent_month,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "prepaid_rent_month"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "prepaid_rent_month"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "No later than",
          column_name: "payment_date",
          column_type: "date",
          possible_values: "",
          actual_value:
            data?.payment_date === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "payment_date"
                )[0]?.actual_value
              : data?.payment_date,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 1,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "payment_date"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "payment_date"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Tenant in total",
          column_name: "total_amount",
          column_type: "number",
          possible_values: "",
          actual_value:
            data?.total_amount === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "total_amount"
                )[0]?.actual_value
              : data?.total_amount,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 1,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "total_amount"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "total_amount"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Prepaid rent",
          column_name: "prepaid_rent",
          column_type: "number",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "prepaid_rent"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "prepaid_rent"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "prepaid_rent"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Rent etc. for the period (Start Date)",
          column_name: "move_in_date",
          column_type: "date",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "move_in_date"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "move_in_date"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "move_in_date"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Rent etc. for the period (End Date)",
          column_name: "rent_end_date",
          column_type: "date",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rent_end_date"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rent_end_date"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rent_end_date"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Rent Period Value",
          column_name: "rent_period_amount",
          column_type: "number",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rent_period_amount"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rent_period_amount"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rent_period_amount"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Deposit",
          column_name: "deposit",
          column_type: "number",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Deposit 1",
          column_name: "deposit_1_text",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.deposit_1_text === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "deposit_1_text"
                )[0]?.actual_value
              : data?.deposit_1_text,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_1_text"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_1_text"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Deposit 2",
          column_name: "deposit_2_text",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.deposit_2_text === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "deposit_2_text"
                )[0]?.actual_value
              : data?.deposit_2_text,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_2_text"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_2_text"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Deposit 3",
          column_name: "deposit_3_text",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.deposit_3_text === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "deposit_3_text"
                )[0]?.actual_value
              : data?.deposit_3_text,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_3_text"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_3_text"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Deposit 1 Value",
          column_name: "deposit_value_1",
          column_type: "number",
          possible_values: "",
          actual_value:
            data?.deposit_value_1 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "deposit_value_1"
                )[0]?.actual_value
              : data?.deposit_value_1,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_value_1"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_value_1"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Deposit 2 Value",
          column_name: "deposit_value_2",
          column_type: "number",
          possible_values: "",
          actual_value:
            data?.deposit_value_2 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "deposit_value_2"
                )[0]?.actual_value
              : data?.deposit_value_2,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_value_2"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_value_2"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Deposit 3 Value",
          column_name: "deposit_value_3",
          column_type: "number",
          possible_values: "",
          actual_value:
            data?.deposit_value_3 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "deposit_value_3"
                )[0]?.actual_value
              : data?.deposit_value_3,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_value_3"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "deposit_value_3"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "Total Deposit",
          column_name: "total_deposit",
          column_type: "number",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "total_deposit"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "total_deposit"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "total_deposit"
          )[0]?.add_data,
        },
        {
          section_id: 4,
          actual_column_name: "first time rent must be paid thereafter is that",
          column_name: "payment_made_on",
          column_type: "date",
          possible_values: "",
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "payment_made_on"
          )[0]?.actual_value,
          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "payment_made_on"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "payment_made_on"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name:
            "The landlord supplies heat to the rental property",
          column_name: "heat_supply",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Yes",
            },
            {
              Id: 2,
              Text: "No",
            },
          ],
          actual_value:
            data?.heat_supply === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "heat_supply"
                )[0]?.actual_value
              : data?.heat_supply == 1
              ? 2
              : 1,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_supply"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_supply"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name: "District heating/natural gas",
          column_name: "heat_supply_1",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.heat_supply_1 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "heat_supply_1"
                )[0]?.actual_value
              : data?.heat_supply_1,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_supply_1"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_supply_1"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name: "Central heating with oil",
          column_name: "heat_supply_2",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.heat_supply_2 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "heat_supply_2"
                )[0]?.actual_value
              : data?.heat_supply_2,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_supply_2"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_supply_2"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name: "Electricity for heating",
          column_name: "heat_supply_3",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.heat_supply_3 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "heat_supply_3"
                )[0]?.actual_value
              : data?.heat_supply_3,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_supply_3"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_supply_3"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name: "Other",
          column_name: "heat_supply_4",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.heat_supply_4 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "heat_supply_4"
                )[0]?.actual_value
              : data?.heat_supply_4,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_supply_4"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_supply_4"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name:
            "The cost of heating is charged in addition to the rent, cf. Section 65(1) of the Rent Act.",
          column_name: "heat_supply_5",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.heat_supply_5 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "heat_supply_5"
                )[0]?.actual_value
              : data?.heat_supply_5,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_supply_5"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_supply_5"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name:
            "The cost of heating is included in the rent, cf. Section 65, subsection of the Tenancy Act. 2 (single rooms, etc.)",
          column_name: "heat_supply_6",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.heat_supply_6 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "heat_supply_6"
                )[0]?.actual_value
              : data?.heat_supply_6,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_supply_6"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_supply_6"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name: "Other Value",
          column_name: "other_supply_value",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.other_supply_value === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "other_supply_value"
                )[0]?.actual_value
              : data?.other_supply_value,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_supply_value"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_supply_value"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name: "The heating accounting begins on",
          column_name: "heat_measurement_date",
          column_type: "date",
          possible_values: "",
          actual_value:
            data?.heat_measurement_date === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "heat_measurement_date"
                )[0]?.actual_value
              : data?.heat_measurement_date,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_measurement_date"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_measurement_date"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name:
            "The tenant is responsible for heating the premises?",
          column_name: "tenent_heat_reponsibility",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Yes",
            },
            {
              Id: 2,
              Text: "No",
            },
          ],
          actual_value:
            data?.tenent_heat_reponsibility === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "tenent_heat_reponsibility"
                )[0]?.actual_value
              : data?.tenent_heat_reponsibility == 1
              ? 2
              : 1,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "tenent_heat_reponsibility"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "tenent_heat_reponsibility"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name: "Electricity",
          column_name: "heat_source_1",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.heat_source_1 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "heat_source_1"
                )[0]?.actual_value
              : data?.heat_source_1,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_source_1"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_source_1"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name: "Gas",
          column_name: "heat_source_2",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.heat_source_2 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "heat_source_2"
                )[0]?.actual_value
              : data?.heat_source_2,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_source_2"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_source_2"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name: "Oil/petroleum",
          column_name: "heat_source_3",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.heat_source_3 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "heat_source_3"
                )[0]?.actual_value
              : data?.heat_source_3,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_source_3"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_source_3"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name: "District heating/natural gas",
          column_name: "heat_source_4",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.heat_source_4 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "heat_source_4"
                )[0]?.actual_value
              : data?.heat_source_4,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_source_4"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_source_4"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name: "Other",
          column_name: "heat_source_5",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.heat_source_5 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "heat_source_5"
                )[0]?.actual_value
              : data?.heat_source_5,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_source_5"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "heat_source_5"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name: "Other Text",
          column_name: "other_source",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.other_source === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "other_source"
                )[0]?.actual_value
              : data?.other_source,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_source"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_source"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name:
            "The landlord supplies water to the rental property?",
          column_name: "water_supply",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Yes",
            },
            {
              Id: 2,
              Text: "No",
            },
          ],
          actual_value:
            data?.water_supply === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "water_supply"
                )[0]?.actual_value
              : data?.water_supply == 1
              ? 2
              : 1,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "water_supply"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "water_supply"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name: "Water Supply Yes detail",
          column_name: "water_supply_detail",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "The cost of water is distributed on the basis of individual consumption meters and charged",
            },
            {
              Id: 2,
              Text: "he cost of water is not distributed on the basis of individual consumption meters andis therefore included in the rent",
            },
          ],
          actual_value:
            data?.water_supply_detail === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "water_supply_detail"
                )[0]?.actual_value
              : data?.water_supply_detail,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "water_supply_detail"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "water_supply_detail"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name: "The water accounting year begins",
          column_name: "water_supply_date",
          column_type: "date",
          possible_values: "",
          actual_value:
            data?.water_supply_date === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "water_supply_date"
                )[0]?.actual_value
              : data?.water_supply_date,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "water_supply_date"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "water_supply_date"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name:
            "Does the landlord provied electricity other than heating for the rental property?",
          column_name: "elec_supply",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Yes",
            },
            {
              Id: 2,
              Text: "No",
            },
          ],
          actual_value:
            data?.elec_supply === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "elec_supply"
                )[0]?.actual_value
              : data?.elec_supply == 1
              ? 2
              : 1,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "elec_supply"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "elec_supply"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name: "Electricity Supply Yes detail",
          column_name: "elec_supply_detail",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "The cost of electricity is allocated on the basis of individual consumption meters and is charged in addition to the rent",
            },
            {
              Id: 2,
              Text: "The cost of electricity is not allocated on the basis of individual consumption meters and is therefore included in the rent",
            },
          ],
          actual_value:
            data?.elec_supply_detail === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "elec_supply_detail"
                )[0]?.actual_value
              : data?.elec_supply_detail,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "elec_supply_detail"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "elec_supply_detail"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name: "The electricity accounting year begins on",
          column_name: "elec_supply_date",
          column_type: "date",
          possible_values: "",
          actual_value:
            data?.elec_supply_date === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "elec_supply_date"
                )[0]?.actual_value
              : data?.elec_supply_date,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "elec_supply_date"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "elec_supply_date"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name:
            "The landlord provides cooling to the rental property?",
          column_name: "cooling_supply",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Yes",
            },
            {
              Id: 2,
              Text: "No",
            },
          ],
          actual_value:
            data?.cooling_supply === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "cooling_supply"
                )[0]?.actual_value
              : data?.cooling_supply == 1
              ? 2
              : 1,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "cooling_supply"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "cooling_supply"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name:
            "If Yes, is the cost of cooling allocated on the basis of individual consumption meters?",
          column_name: "cooling_cost_allocation",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Yes",
            },
            {
              Id: 2,
              Text: "No",
            },
          ],
          actual_value:
            data?.cooling_cost_allocation === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "cooling_cost_allocation"
                )[0]?.actual_value
              : data?.cooling_cost_allocation == 1
              ? 2
              : 1,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "cooling_cost_allocation"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "cooling_cost_allocation"
          )[0]?.add_data,
        },
        {
          section_id: 5,
          actual_column_name: "The cooling accounting year begins",
          column_name: "cooling_supply_date",
          column_type: "date",
          possible_values: "",
          actual_value:
            data?.cooling_supply_date === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "cooling_supply_date"
                )[0]?.actual_value
              : data?.cooling_supply_date,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "cooling_supply_date"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "cooling_supply_date"
          )[0]?.add_data,
        },
        {
          section_id: 6,
          actual_column_name: "Landlord provide a common signal supply",
          column_name: "l_antenna_signal",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Yes",
            },
            {
              Id: 2,
              Text: "No",
            },
          ],
          actual_value:
            data?.l_antenna_signal === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_antenna_signal"
                )[0]?.actual_value
              : data?.l_antenna_signal == 1
              ? 2
              : 1,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_antenna_signal"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_antenna_signal"
          )[0]?.add_data,
        },
        {
          section_id: 6,
          actual_column_name: "Tenant antenna association in the building",
          column_name: "t_antenna",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Yes",
            },
            {
              Id: 2,
              Text: "No",
            },
          ],
          actual_value:
            data?.t_antenna === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "t_antenna"
                )[0]?.actual_value
              : data?.t_antenna == 1
              ? 2
              : 1,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_antenna"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_antenna"
          )[0]?.add_data,
        },
        {
          section_id: 6,
          actual_column_name: "Landlord provides internet access",
          column_name: "l_internet",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Yes",
            },
            {
              Id: 2,
              Text: "No",
            },
          ],
          actual_value:
            data?.l_internet === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_internet"
                )[0]?.actual_value
              : data?.l_internet == 1
              ? 2
              : 1,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_internet"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_internet"
          )[0]?.add_data,
        },
        {
          section_id: 7,
          actual_column_name:
            "Does the landlord rent out more than one residential apartment?",
          column_name: "l_rent_more",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Yes",
            },
            {
              Id: 2,
              Text: "No",
            },
          ],
          actual_value:
            data?.l_rent_more === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "l_rent_more"
                )[0]?.actual_value
              : data?.l_rent_more == 1
              ? 2
              : 1,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_rent_more"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "l_rent_more"
          )[0]?.add_data,
        },
        {
          section_id: 7,
          actual_column_name:
            "Is/will  the condition of the tenancy be ascertained during a move-in inspection?",
          column_name: "t_move_in",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Yes",
            },
            {
              Id: 2,
              Text: "No",
            },
          ],
          actual_value:
            data?.t_move_in === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "t_move_in"
                )[0]?.actual_value
              : data?.t_move_in == 1
              ? 2
              : 1,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_move_in"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_move_in"
          )[0]?.add_data,
        },
        {
          section_id: 7,
          actual_column_name:
            "Is the condition of the tenancy ascertained during a vacancy inspection?",
          column_name: "t_vacancy",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Yes",
            },
            {
              Id: 2,
              Text: "No",
            },
          ],
          actual_value:
            data?.t_vacancy === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "t_vacancy"
                )[0]?.actual_value
              : data?.t_vacancy == 1
              ? 2
              : 1,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_vacancy"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "t_vacancy"
          )[0]?.add_data,
        },
        {
          section_id: 8,
          actual_column_name:
            "The internal maintenance of the tenancywill be the responsibility of",
          column_name: "internal_maitainence",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Yes",
            },
            {
              Id: 2,
              Text: "No",
            },
          ],
          actual_value: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "internal_maitainence"
          )[0]?.actual_value,

          is_merge: 1,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "internal_maitainence"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "internal_maitainence"
          )[0]?.add_data,
        },
        {
          section_id: 8,
          actual_column_name: "Contarct Creation Date",
          column_name: "contract_creation_date",
          column_type: "date",
          possible_values: "",
          actual_value:
            data?.contract_creation_date === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "contract_creation_date"
                )[0]?.actual_value
              : data?.contract_creation_date,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "contract_creation_date"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "contract_creation_date"
          )[0]?.add_data,
        },
        {
          section_id: 8,
          actual_column_name: "Balance Amount",
          column_name: "account_balance",
          column_type: "number",
          possible_values: "",
          actual_value:
            data?.account_balance === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "account_balance"
                )[0]?.actual_value
              : data?.account_balance,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "account_balance"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "account_balance"
          )[0]?.add_data,
        },
        {
          section_id: 9,
          actual_column_name: "Retrieve data from unit",
          column_name: "retrieve_inventory_data_from_unit",
          column_type: "switch",
          possible_values: [
            {
              Id: 0,
              Text: "No",
            },
            {
              Id: 1,
              Text: "Yes",
            },
          ],
          actual_value:
            data?.retrieve_inventory_data_from_unit === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) =>
                    data?.column_name == "retrieve_inventory_data_from_unit"
                )[0]?.actual_value
              : data?.retrieve_inventory_data_from_unit,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "retrieve_inventory_data_from_unit"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "retrieve_inventory_data_from_unit"
          )[0]?.add_data,
        },
        {
          section_id: 9,
          actual_column_name: "Cooker",
          column_name: "inventory_1",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.inventory_1 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "inventory_1"
                )[0]?.actual_value
              : data?.inventory_1,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_1"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_1"
          )[0]?.add_data,
        },
        {
          section_id: 9,
          actual_column_name: "Fridge/freezer",
          column_name: "inventory_2",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.inventory_2 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "inventory_2"
                )[0]?.actual_value
              : data?.inventory_2,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_2"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_2"
          )[0]?.add_data,
        },
        {
          section_id: 9,
          actual_column_name: "Tumble dryer",
          column_name: "inventory_3",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.inventory_3 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "inventory_3"
                )[0]?.actual_value
              : data?.inventory_3,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_3"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_3"
          )[0]?.add_data,
        },
        {
          section_id: 9,
          actual_column_name: "Refrigerator",
          column_name: "inventory_4",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.inventory_4 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "inventory_4"
                )[0]?.actual_value
              : data?.inventory_4,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_4"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_4"
          )[0]?.add_data,
        },
        {
          section_id: 9,
          actual_column_name: "Dishwasher",
          column_name: "inventory_5",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.inventory_5 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "inventory_5"
                )[0]?.actual_value
              : data?.inventory_5,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_5"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_5"
          )[0]?.add_data,
        },
        {
          section_id: 9,
          actual_column_name: "Extractor hood",
          column_name: "inventory_6",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.inventory_6 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "inventory_6"
                )[0]?.actual_value
              : data?.inventory_6,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_6"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_6"
          )[0]?.add_data,
        },
        {
          section_id: 9,
          actual_column_name: "Freezer",
          column_name: "inventory_7",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.inventory_7 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "inventory_7"
                )[0]?.actual_value
              : data?.inventory_7,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_7"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_7"
          )[0]?.add_data,
        },
        {
          section_id: 9,
          actual_column_name: "Washing machine",
          column_name: "inventory_8",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.inventory_8 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "inventory_8"
                )[0]?.actual_value
              : data?.inventory_8,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_8"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_8"
          )[0]?.add_data,
        },
        {
          section_id: 9,
          actual_column_name: "Washing machine/dryer",
          column_name: "inventory_9",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.inventory_9 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "inventory_9"
                )[0]?.actual_value
              : data?.inventory_9,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_9"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_9"
          )[0]?.add_data,
        },
        {
          section_id: 9,
          actual_column_name: "Other",
          column_name: "inventory_10",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.inventory_10 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "inventory_10"
                )[0]?.actual_value
              : data?.inventory_10,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_10"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_10"
          )[0]?.add_data,
        },
        {
          section_id: 9,
          actual_column_name: "Other",
          column_name: "inventory_11",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.inventory_11 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "inventory_11"
                )[0]?.actual_value
              : data?.inventory_11,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_11"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_11"
          )[0]?.add_data,
        },
        {
          section_id: 9,
          actual_column_name: "Other",
          column_name: "inventory_12",
          column_type: "checkbox",
          possible_values: "",
          actual_value:
            data?.inventory_12 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "inventory_12"
                )[0]?.actual_value
              : data?.inventory_12,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_12"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_12"
          )[0]?.add_data,
        },
        {
          section_id: 9,
          actual_column_name: "Other Text",
          column_name: "inventory_other_text_1",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.inventory_other_text_1 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "inventory_other_text_1"
                )[0]?.actual_value
              : data?.inventory_other_text_1,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_other_text_1"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_other_text_1"
          )[0]?.add_data,
        },
        {
          section_id: 9,
          actual_column_name: "Other Text",
          column_name: "inventory_other_text_2",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.inventory_other_text_2 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "inventory_other_text_2"
                )[0]?.actual_value
              : data?.inventory_other_text_2,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_other_text_2"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_other_text_2"
          )[0]?.add_data,
        },
        {
          section_id: 9,
          actual_column_name: "Other Text",
          column_name: "inventory_other_text_3",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.inventory_other_text_3 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "inventory_other_text_3"
                )[0]?.actual_value
              : data?.inventory_other_text_3,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_other_text_3"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "inventory_other_text_3"
          )[0]?.add_data,
        },
        {
          section_id: 10,
          actual_column_name: "Resident represention",
          column_name: "resident_representation",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Yes",
            },
            {
              Id: 2,
              Text: "No",
            },
          ],
          actual_value:
            data?.resident_representation === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "resident_representation"
                )[0]?.actual_value
              : data?.resident_representation == 1
              ? 2
              : 1,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "resident_representation"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "resident_representation"
          )[0]?.add_data,
        },
        {
          section_id: 10,
          actual_column_name: "Retrieve data from unit",
          column_name: "retrieve_pet_data_from_unit",
          column_type: "switch",
          possible_values: [
            {
              Id: 0,
              Text: "No",
            },
            {
              Id: 1,
              Text: "Yes",
            },
          ],
          actual_value:
            data?.retrieve_pet_data_from_unit === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "retrieve_pet_data_from_unit"
                )[0]?.actual_value
              : data?.retrieve_pet_data_from_unit,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "retrieve_pet_data_from_unit"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "retrieve_pet_data_from_unit"
          )[0]?.add_data,
        },
        {
          section_id: 10,
          actual_column_name: "are pet allowed in teancy",
          column_name: "pet_allowed",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Yes",
            },
            {
              Id: 2,
              Text: "No",
            },
          ],
          actual_value:
            data?.pet_allowed === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "pet_allowed"
                )[0]?.actual_value
              : data?.pet_allowed == 1
              ? 2
              : 1,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "pet_allowed"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "pet_allowed"
          )[0]?.add_data,
        },
        {
          section_id: 10,
          actual_column_name: "Specific conditions for the pet permit",
          column_name: "pet_conditions",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.pet_conditions === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "pet_conditions"
                )[0]?.actual_value
              : data?.pet_conditions,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "pet_conditions"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "pet_conditions"
          )[0]?.add_data,
        },
        {
          section_id: 10,
          actual_column_name:
            "Are there house rules for the property at the start of the tenancy?",
          column_name: "house_regulation",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Yes",
            },
            {
              Id: 2,
              Text: "No",
            },
          ],
          actual_value:
            data?.house_regulation === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "house_regulation"
                )[0]?.actual_value
              : data?.house_regulation == 1
              ? 2
              : 1,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "house_regulation"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "house_regulation"
          )[0]?.add_data,
        },
        {
          section_id: 10,
          actual_column_name: "Other information about the rented property_1",
          column_name: "other_information_1",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.other_information_1 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "other_information_1"
                )[0]?.actual_value
              : data?.other_information_1
                  .replace(/<[^>]*>/g, "")
                  .replace(/&nbsp;/g, " "),
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_information_1"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_information_1"
          )[0]?.add_data,
        },
        {
          section_id: 10,
          actual_column_name: "Other information about the rented property_1",
          column_name: "other_information_2",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.other_information_2 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "other_information_2"
                )[0]?.actual_value
              : data?.other_information_2
                  .replace(/<[^>]*>/g, "")
                  .replace(/&nbsp;/g, " "),
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_information_2"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_information_2"
          )[0]?.add_data,
        },
        {
          section_id: 11,
          actual_column_name: "Is the tanency coverd by the rules on free rend",
          column_name: "rent_determination",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Yes",
            },
            {
              Id: 2,
              Text: "No",
            },
          ],
          actual_value:
            data?.rent_determination === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "rent_determination"
                )[0]?.actual_value
              : data?.rent_determination == 1
              ? 2
              : 1,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rent_determination"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rent_determination"
          )[0]?.add_data,
        },
        {
          section_id: 11,
          actual_column_name: "If yes, what is the reason",
          column_name: "rent_reason",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.rent_reason === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "rent_reason"
                )[0]?.actual_value
              : data?.rent_reason
                  .replace(/<[^>]*>/g, "")
                  .replace(/&nbsp;/g, " "),
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rent_reason"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rent_reason"
          )[0]?.add_data,
        },
        {
          section_id: 11,
          actual_column_name:
            "The rent is adjusted once a year according to the development of Statistics Denmark Net Price Index. Rent increase can only be implemented by written notice from the landlord to the tenant",
          column_name: "rent_regulation",
          column_type: "radio",
          possible_values: [
            {
              Id: 1,
              Text: "Yes",
            },
            {
              Id: 2,
              Text: "No",
            },
          ],
          actual_value:
            data?.rent_regulation === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "rent_regulation"
                )[0]?.actual_value
              : data?.rent_regulation == 1
              ? 2
              : 1,
          is_merge: 0,
          is_mandatory: 1,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rent_regulation"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rent_regulation"
          )[0]?.add_data,
        },
        {
          section_id: 11,
          actual_column_name: "Every",
          column_name: "rent_adjust_date",
          column_type: "date",
          possible_values: "",
          actual_value:
            data?.rent_adjust_date === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "rent_adjust_date"
                )[0]?.actual_value
              : data?.rent_adjust_date,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rent_adjust_date"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "rent_adjust_date"
          )[0]?.add_data,
        },
        {
          section_id: 11,
          actual_column_name: "Net price Index",
          column_name: "price_index",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.price_index === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "price_index"
                )[0]?.actual_value
              : data?.price_index,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "price_index"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "price_index"
          )[0]?.add_data,
        },
        {
          section_id: 11,
          actual_column_name: "Month of the previous year",
          column_name: "month",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.month === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "month"
                )[0]?.actual_value
              : data?.month,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "month"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "month"
          )[0]?.add_data,
        },
        {
          section_id: 11,
          actual_column_name: "The rent is first adjusted on",
          column_name: "adjust_first_date",
          column_type: "date",
          possible_values: "",
          actual_value:
            data?.adjust_first_date === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "adjust_first_date"
                )[0]?.actual_value
              : data?.adjust_first_date,
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "adjust_first_date"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "adjust_first_date"
          )[0]?.add_data,
        },
        {
          section_id: 11,
          actual_column_name: "Other derogatinos",
          column_name: "other_derogations_1",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.other_derogations_1 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "other_derogations_1"
                )[0]?.actual_value
              : data?.other_derogations_1
                  .replace(/<[^>]*>/g, "")
                  .replace(/&nbsp;/g, " "),
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_derogations_1"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_derogations_1"
          )[0]?.add_data,
        },
        {
          section_id: 11,
          actual_column_name: "Not in Figma(Other deviations – continued)",
          column_name: "other_derogations_2",
          column_type: "text",
          possible_values: "",
          actual_value:
            data?.other_derogations_2 === undefined
              ? apiData[0]?.configuration_values?.filter(
                  (data) => data?.column_name == "other_derogations_2"
                )[0]?.actual_value
              : data?.other_derogations_2
                  .replace(/<[^>]*>/g, "")
                  .replace(/&nbsp;/g, " "),
          is_merge: 0,
          is_mandatory: 0,
          is_calculated: 0,
          add_data_type: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_derogations_2"
          )[0]?.add_data_type,
          add_data: apiData[0]?.configuration_values?.filter(
            (data) => data?.column_name == "other_derogations_2"
          )[0]?.add_data,
        },
      ],
    };

    // const response = await PutUpdateDocumentConfiguration(updateConfigurationPayload);
    // console.log("response",response)
    //   if (response.status === status.s && response.data.bReturn) {
    //     getConfigurationDetailsDataRefetch();
    //     setUrl(response?.data?.data?.url);
    //     toast.success(response.data.message);
    //     if (isNext) handleNext(i);
    //     else setPreview(true);
    //     // setFlagUpdateText(false);
    //     // setAddSettingPage(false);
    //     // setShowSetting(false);
    //   } else {
    //     toast.info(response.data.message);
    //   }

    try {
      const response = await PutUpdateDocumentConfiguration(
        updateConfigurationPayload
      );
      // console.log("response", response);

      if (response.status === status.s && response.data.bReturn) {
        getConfigurationDetailsDataRefetch();
        setUrl(response?.data?.data?.url);
        toast.success(response.data.message);

        if (isNext) {
          handleNext(i);
        } else {
          setPreview(true);
        }

        // setFlagUpdateText(false);
        // setAddSettingPage(false);
        // setShowSetting(false);
      } else {
        toast.info(response.data.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const partnersandrented = (
    activeTenancyAgreementForm,
    getConfigurationDetailsData
  ) => {
    return (
      <>
        <Partnersandrented
          submitRef={submitRef}
          // handleNext={handleNext}
          getConfigurationDetailsData={getConfigurationDetailsData}
          activeTenancyForm={activeTenancyAgreementForm}
          handleEditConfig={handleEditConfig}
          previewRef={previewRef}
        />
      </>
    );
  };
  const rentalsAndTermination = (
    activeTenancyAgreementForm,
    getConfigurationDetailsData
  ) => {
    return (
      <>
        <RentalsAndTermination
          submitRef={submitRef}
          prevRef={prevRef}
          handleNext={handleNext}
          activeTenancyForm={activeTenancyAgreementForm}
          getConfigurationDetailsData={getConfigurationDetailsData}
          handleEditConfig={handleEditConfig}
          previewRef={previewRef}
        />
      </>
    );
  };
  const paymentandrent = (
    activeTenancyAgreementForm,
    getConfigurationDetailsData
  ) => {
    return (
      <>
        <Paymentandrent
          submitRef={submitRef}
          prevRef={prevRef}
          handleEditConfig={handleEditConfig}
          // handleNext={handleNext}
          activeTenancyForm={activeTenancyAgreementForm}
          getConfigurationDetailsData={getConfigurationDetailsData}
          previewRef={previewRef}
        />
      </>
    );
  };
  const depositandPrepaidRent = (
    activeTenancyAgreementForm,
    getConfigurationDetailsData
  ) => {
    return (
      <>
        <DepositandPrepaidRent
          submitRef={submitRef}
          prevRef={prevRef}
          // handleNext={handleNext}
          activeTenancyForm={activeTenancyAgreementForm}
          getConfigurationDetailsData={getConfigurationDetailsData}
          handleEditConfig={handleEditConfig}
          previewRef={previewRef}
        />
      </>
    );
  };
  const heatingAndCooling = (
    activeTenancyAgreementForm,
    getConfigurationDetailsData
  ) => {
    return (
      <>
        <HeatingAndCooling
          submitRef={submitRef}
          prevRef={prevRef}
          // handleNext={handleNext}
          activeTenancyForm={activeTenancyAgreementForm}
          getConfigurationDetailsData={getConfigurationDetailsData}
          handleEditConfig={handleEditConfig}
          previewRef={previewRef}
        />
      </>
    );
  };
  const communalAntenna = (
    activeTenancyAgreementForm,
    getConfigurationDetailsData
  ) => {
    return (
      <>
        <CommunalAntenna
          submitRef={submitRef}
          prevRef={prevRef}
          // handleNext={handleNext}
          activeTenancyForm={activeTenancyAgreementForm}
          getConfigurationDetailsData={getConfigurationDetailsData}
          handleEditConfig={handleEditConfig}
          previewRef={previewRef}
        />
      </>
    );
  };
  const conditionPremises = (
    activeTenancyAgreementForm,
    getConfigurationDetailsData
  ) => {
    return (
      <>
        <ConditionPremises
          submitRef={submitRef}
          prevRef={prevRef}
          // handleNext={handleNext}
          activeTenancyForm={activeTenancyAgreementForm}
          getConfigurationDetailsData={getConfigurationDetailsData}
          handleEditConfig={handleEditConfig}
          previewRef={previewRef}
        />
      </>
    );
  };
  const maintenance = (
    activeTenancyAgreementForm,
    getConfigurationDetailsData
  ) => {
    return (
      <>
        <Maintenance
          submitRef={submitRef}
          prevRef={prevRef}
          // handleNext={handleNext}
          activeTenancyForm={activeTenancyAgreementForm}
          getConfigurationDetailsData={getConfigurationDetailsData}
          handleEditConfig={handleEditConfig}
          previewRef={previewRef}
        />
      </>
    );
  };
  const inventory = (
    activeTenancyAgreementForm,
    getConfigurationDetailsData
  ) => {
    return (
      <>
        <Inventory
          submitRef={submitRef}
          prevRef={prevRef}
          // handleNext={handleNext}
          activeTenancyForm={activeTenancyAgreementForm}
          getConfigurationDetailsData={getConfigurationDetailsData}
          handleEditConfig={handleEditConfig}
          previewRef={previewRef}
        />
      </>
    );
  };
  const rentedProperty = (
    activeTenancyAgreementForm,
    getConfigurationDetailsData
  ) => {
    return (
      <>
        <RentedProperty
          submitRef={submitRef}
          prevRef={prevRef}
          // handleNext={handleNext}
          activeTenancyForm={activeTenancyAgreementForm}
          getConfigurationDetailsData={getConfigurationDetailsData}
          handleEditConfig={handleEditConfig}
          previewRef={previewRef}
        />
      </>
    );
  };
  const specialCondition = (
    activeTenancyAgreementForm,
    getConfigurationDetailsData
  ) => {
    return (
      <>
        <SpecialCondition
          submitRef={submitRef}
          prevRef={prevRef}
          // handleNext={handleNext}
          activeTenancyForm={activeTenancyAgreementForm}
          getConfigurationDetailsData={getConfigurationDetailsData}
          handleEditConfig={handleEditConfig}
          previewRef={previewRef}
        />
      </>
    );
  };

  const [configurationSteps, setConfigurationSteps] = useState([
    {
      Id: "1",
      tab: "§1 The partners and the rented",
      isDone: true,
      component: partnersandrented,
    },
    {
      Id: "2",
      tab: "§2 The rentals beginning and termination",
      component: rentalsAndTermination,
      isDone: false,
    },
    {
      Id: "3",
      tab: "§3 Payment of rent",
      component: paymentandrent,
      isDone: false,
    },
    {
      Id: "4",
      tab: "§4 Deposit and pre-paid rent",
      component: depositandPrepaidRent,
      isDone: false,
    },
    {
      Id: "5",
      tab: "§5 Heating, cooling, water and electricity",
      component: heatingAndCooling,
      isDone: false,
    },
    {
      Id: "6",
      tab: "§6 Communal antenna etc. and access to electronic communications services",
      component: communalAntenna,
      isDone: false,
    },
    {
      Id: "7",
      tab: "§7 The condition of the premises when moving in and out",
      component: conditionPremises,
      isDone: false,
    },
    {
      Id: "8",
      tab: "§8 Maintenance",
      component: maintenance,
      isDone: false,
    },
    {
      Id: "9",
      tab: "§9 Inventory",
      component: inventory,
      isDone: false,
    },
    {
      Id: "10",
      tab: "§10 Resident representative, animal  husbandry, house rules and other information about the rented property",
      component: rentedProperty,
      isDone: false,
    },
    {
      Id: "11",
      tab: "§11 Special conditions",
      component: specialCondition,
      isDone: false,
    },
  ]);
  const [activeStep, setActiveStep] = useState(configurationSteps[0]);

  // getting all document Type on getDocumentPackageByPackageId API Call
  useEffect(() => {
    setTenancyAgreementIdData(
      getDocumentPackageByPackageId?.data[
        currentTemplateIndex
      ]?.document_details?.filter(
        (data) => String(data?.document_template_file_id) === String(currentID)
      )
    );
  }, [getDocumentPackageByPackageId, docId]);

  // getting Which form is active of Tenancy Agreement
  useEffect(() => {
    setActiveAgreementForm(
      tenancyAgreementIdData?.[currentTemplateIndex]?.document_type_name
    );
    // setCurrentDocFileId(
    // 	tenancyAgreementIdData?.[currentTemplateIndex]?.doc_file_id
    // );
  }, [currentTemplateIndex, tenancyAgreementIdData]);

  // current active tenancy form saving in state
  useEffect(() => {
    if (activeTenancyAgreementForm) {
      setCurrentTempleteIndex(
        tenancyAgreementIdData?.findIndex(
          (data) => data?.document_type_name === activeTenancyAgreementForm
        )
      );
    }
  }, [activeTenancyAgreementForm]);

  // getting all current template configuration section
  useEffect(() => {
    setCurrentConfigurationSection(
      getConfigurationDetailsData?.data[0]?.configuration_section_details
    );
  }, [getConfigurationDetailsData]);

  const handlechange = (id) => {
    setButtonStyle(id);
  };

  const handleNext = (nextIndex) => {
    if (
      // configurationSteps[configurationSteps.length - 1].Id === activeStep.Id
      configurationSteps[configurationSteps.length - 1].Id === String(nextIndex)
    ) {
      alert("You have completed all steps.");
      return;
    }
    const index = configurationSteps.findIndex((x) => x.Id === activeStep.Id);
    setConfigurationSteps((prevConfigurationSteps) =>
      prevConfigurationSteps.map((x) => {
        // if (x.Id === activeStep.Id) x.isDone = true;
        if (x.Id === String(nextIndex)) x.isDone = true;
        return x;
      })
    );
    // if (buttonStyle == 0) {
    // }
    setActiveStep(configurationSteps[nextIndex]);
    setCurrentConfigurationSectionIndex(nextIndex);
  };
  // useref for  trigger submit button from parent to child
  const submitRef = useRef();
  const prevRef = useRef();
  const previewRef = useRef();

  const handleBack = () => {
    const index = configurationSteps.findIndex((x) => x.Id === activeStep.Id);
    if (index === 0) return;

    setConfigurationSteps((prevConfigurationSteps) =>
      prevConfigurationSteps.map((x) => {
        if (x?.Id === activeStep?.Id) x.isDone = false;
        return x;
      })
    );
    setActiveStep(configurationSteps[index - 1]);
    setCurrentConfigurationSectionIndex(index - 1);
  };

  const handleNextTemplate = () => {
    setCurrentTempleteIndex(currentTemplateIndex + 1);
  };

  const handlePrevTemplate = () => {
    setCurrentTempleteIndex(currentTemplateIndex - 1);
  };
  return (
    <>
      <AppProvider>
        {/*------------- Breadcrumbs-------------- */}
        <div className=" pt-3 ">
          <H1>Configuration</H1>
          <div
            className="light-font-text"
            style={{
              cursor: "pointer",
            }}
          >
            <Link
              to={"/properties"}
              style={{ textDecoration: "none", color: "black" }}
            >
              <span className="me-3">Properties</span>
            </Link>
            <span className="me-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <Link
              to="/property-units/overview"
              state={{ Tab: "Overview", propId: propertyId }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <span className="me-3">{propOverview?.[0]?.propertyName}</span>
            </Link>
            <span className="me-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <Link
              to="/property-units/documents"
              state={{ propId: propertyId, Tab: "Documents" }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <span className="me-3">Documents</span>
            </Link>
            <span className="me-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <span className="me-3">Signing Packages</span>

            <span className="me-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <GreenSpan className="me-1 ">Configuration</GreenSpan>
          </div>
        </div>

        <div
          
          className=" mt-4 px-4 pt-3 containerStyle"
        >
          <div className="d-flex justify-content-between pt-1">
            <div>
              <div
                className="d-flex gap-3  pb-3"
                style={{ alignItems: "center", textAlign: "center" }}
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  {tenancyAgreementIdData?.map((item, i) =>
                    item.document_template_file_id == "1" ? (
                      <button
                        key={i}
                        className={"px-2 facility-tabs-active px-2"}
                        style={{ alignItems: "flex-start" }}
                      >
                        {getConfigurationDetailsData?.data?.[0]
                          ?.progress_percentage === 100 ? (
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-check"
                            style={{ color: "#77c7a1" }}
                            className="pt-1"
                          />
                        ) : (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <CircularProgress
                              variant="determinate"
                              size={14}
                              value={
                                getConfigurationDetailsData?.data?.[0]
                                  ?.progress_percentage
                              }
                              thickness={5}
                              style={{
                                color: "#77C7A1",
                                margin: "4px -13.7px 0 0", // Adjust the spacing between the two components
                              }}
                            />
                            <FontAwesomeIcon
                              icon={["far", "circle"]}
                              style={{ color: "#d1d1d1" }}
                              className="pt-1"
                            />
                          </div>
                        )}

                        {/* <span>
                          {i == currentTemplateIndex ? (
                            <FontAwesomeIcon
                              icon="fa-regular fa-circle-notch"
                              style={{ color: "#77c7a1" }}
                              className="pt-1"
                            />
                          ) : i < currentTemplateIndex ? (
                            <FontAwesomeIcon
                              icon="fa-solid fa-circle-check"
                              style={{ color: "#77c7a1" }}
                              className="pt-1"
                            />
                          ) : i > currentTemplateIndex ? (
                            <FontAwesomeIcon
                              icon="fa-regular fa-circle"
                              style={{ color: "#888888" }}
                              className="pt-1"
                            />
                          ) : null}
                        </span> */}

                        <GreenSpan className="ps-2">
                          {item.document_type_name}
                        </GreenSpan>
                      </button>
                    ) : (
                      ""
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="pb-2">
              {/* <NavLink
                to="property-units/documents"
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              > */}
              <NavLink
                to="/property-units/documents"
                state={{ propId: propertyId, Tab: "Documents" }}
              >
                <ETButton variant={"white"} buttonText="SAVE AND CLOSE" />
              </NavLink>
              {/* </NavLink> */}
            </div>
          </div>
          <div style={{ backgroundColor: "#FFF" }} className="radius-8">
            <div className="signingsbutton-header d-flex justify-content-between px-4">
              <div className="actors-section-title pt-2">
                Tenancy agreement template{" "}
              </div>
              <div className="d-flex gap-2">
                <div>
                  <ETButton
                    variant={"white"}
                    buttonText="PREVIOUS TEMPLATE"
                    onClick={handlePrevTemplate}
                    disabled={!currentTemplateIndex}
                  />
                </div>
                <div>
                  <ETButton
                    variant={"primary"}
                    buttonText="NEXT TEMPLATE"
                    onClick={handleNextTemplate}
                    disabled={
                      currentTemplateIndex > tenancyAgreementIdData?.length - 2
                    }
                  />
                </div>
              </div>
            </div>

            <div className="fw-300 d-flex flex-column configuration-pages position-relative">
              <Row className="m-0">
                <Col lg={3}>
                  {!preview && (
                    <div
                      className="configuration-pages-sidebar me-sm-4 me-lg-0 py-3"
                      style={{
                        position: "-webkit-sticky",
                        position: "sticky",
                        top: "0",
                      }}
                    >
                      <div className="light">
                        {currentConfigurationSection?.map((page, index) => (
                          <div
                            key={index}
                            className={
                              index === currentConfigurationSectionIndex
                                ? "sidebarbtn_active mb-1 "
                                : "sidebarbtn mb-1 "
                            }
                          >
                            {getConfigurationDetailsData?.data?.[0]?.configuration_section_details.filter(
                              (val, i) => i === index
                            )[0].is_completed ? (
                              <FontAwesomeIcon
                                icon="fa-solid fa-circle-check"
                                style={{ color: "#77c7a1" }}
                                className="pt-1"
                              />
                            ) : index === currentConfigurationSectionIndex ? (
                              <FontAwesomeIcon
                                icon="fa-regular fa-circle-notch"
                                style={{ color: "#77c7a1" }}
                                className="pt-1"
                              />
                            ) : index < currentConfigurationSectionIndex ? (
                              <FontAwesomeIcon
                                icon="fa-solid fa-circle-check"
                                style={{ color: "#77c7a1" }}
                                className="pt-1"
                              />
                            ) : index > currentConfigurationSectionIndex ? (
                              <FontAwesomeIcon
                                icon="fa-regular fa-circle"
                                style={{ color: "#888888" }}
                                className="pt-1"
                              />
                            ) : null}

                            {page?.section_text}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </Col>
                <Col lg={9} className="p-0">
                  <div style={{ backgroundColor: "#f0f0f0", height: "100%" }}>
                    <div className="form-section pb-4 px-5">
                      <div className="d-flex py-3 justify-content-center">
                        <ButtonGroup
                          aria-label="Basic example"
                          className="fillin "
                        >
                          <Button
                            onClick={() => {
                              handlechange(0);
                              setPreview(false);
                            }}
                            className="btnone px-4 py-2"
                            style={{
                              backgroundColor:
                                buttonStyle == 0 ? "#D7E3CB" : "white",
                              color: buttonStyle == 0 ? "#619130" : "#888888",
                              fontWeight: buttonStyle == 0 ? 600 : 300,
                            }}
                          >
                            <FontAwesomeIcon
                              className="me-1"
                              icon="fa-solid fa-pencil"
                            />
                            FILL IN
                          </Button>
                          <Button
                            onClick={() => {
                              handlechange(1);
                              previewRef?.current?.click();
                            }}
                            className="btntwo px-3 py-2"
                            style={{
                              backgroundColor:
                                buttonStyle == 1 ? "#D7E3CB" : "white",
                              color: buttonStyle == 1 ? "#619130" : "#888888",
                              fontWeight: buttonStyle == 1 ? 600 : 300,
                            }}
                          >
                            <FontAwesomeIcon
                              className="me-1"
                              icon="fa-regular fa-eye"
                            />
                            PREVIEW
                          </Button>
                        </ButtonGroup>
                      </div>
                      {!preview && (
                        <div>
                          {activeStep?.component(
                            activeTenancyAgreementForm,
                            getConfigurationDetailsData?.data
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="py-2 px-3 d-flex justify-content-between justify-content-center"
                style={{
                  position: "sticky",
                  backgroundColor: "#F9FAFA",
                  width: "100%",
                  border: "1px solid #F0F0F0",
                }}
              >
                <input
                  type="button"
                  value="PREVIOUS"
                  onClick={() => {
                    if (prevRef.current) {
                      prevRef.current.click();
                    }
                    handleBack();
                  }}
                  disabled={configurationSteps[0]?.Id === activeStep?.Id}
                  style={{
                    // backgroundColor: "#fff",
                    width: "fit-content",
                    alignItems: "center",
                    display: "flex",
                    gap: "7px",
                    fontFamily: "Cerebri sans-medium",
                    fontWeight: "500",
                    border: "none",
                    borderRadius: "8px",
                    cursor: "pointer",
                    textAlign: "center",
                    padding: "10px 15px ",
                    fontSize: "14px",
                  }}
                />
                <ETButton
                  variant={"primary"}
                  buttonText="NEXT"
                  disabled={PutUpdateDocumentConfigurationIsLoading}
                  onClick={() => {
                    submitRef?.current?.click();
                  }}
                  type={"submit"}
                />
                {/* handleNext() */}
              </div>
              {preview && (
                <div
                  className="position-absolute w-100"
                  style={{ height: "100vh", top: "4.2rem" }}
                >
                  <iframe src={url} height="100%" width="100%"></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
      </AppProvider>

      <CustomPopup
        open={previewForm}
        onHide={() => setPreviewForm(false)}
        headerRequired={false}
        size="lg"
      >
        <div className="p-2" style={{ height: "100vh" }}>
          <iframe src={url} height="100%" width="100%"></iframe>
        </div>
        {/* <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        ></div> */}
      </CustomPopup>
    </>
  );
};

export default Configuration;
