import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import GroupText from "../../../../Components/GroupText/GroupText";
import ETButton from "../../../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Popups.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import moment from "moment";
import { PostAddNote } from "../../../../services/Notes";
import { toast } from "react-toastify";
import { useGetDepositPopupDetails } from "../../../../hooks/queries/useActorQueries";
import { Controller, useForm } from "react-hook-form";
import { PutUpdatDepositDetails } from "../../../../services/Actors";
import InputField from "../../../../Components/Textfield/InputField";

const depositStatusDropdown = [
  { value: 0, label: "Not received" },
  { value: 1, label: "Received" },
];
const DepositDetails = ({
  setDepositPopup,
  getNotesDetailsData,
  contractId,
  getNotesDetailsDataRefetch,
  contractOverViewDataRefetch,
}) => {
  //------------------States-------------------//
  const [subjectLine, setSubjectLine] = useState("");
  const [message, setMessage] = useState("");

  //-----------------react query---------------//
  const { data: getContractDepositPopupDetailsData } =
    useGetDepositPopupDetails(contractId);

  //----------------Validation---------------//
  const {
    register,
    handleSubmit,
    clearErrors,
    getValues,
    formState: { errors },
    setValue,
    control,
    reset,
  } = useForm();

  useEffect(() => {
    setValue("depositStatus", {
      value: getContractDepositPopupDetailsData?.data?.[0]?.deposit_status,
      label: depositStatusDropdown?.filter(
        (el) =>
          el.value ==
          getContractDepositPopupDetailsData?.data?.[0]?.deposit_status
      )?.[0]?.label,
    });
    setValue(
      "dateOfPayment",
      getContractDepositPopupDetailsData?.data?.[0]?.deposit_payment_date !== ""
        ? moment(
          getContractDepositPopupDetailsData?.data?.[0]?.deposit_payment_date
        ).format("YYYY-MM-DD")
        : ""
    );

    setValue(
      "totalAmount",
      getContractDepositPopupDetailsData?.data?.[0]?.total_amount
    );
    setValue(
      "paymentReceived",
      getContractDepositPopupDetailsData?.data?.[0]
        ?.deposit_payment_amount_received
    );
    return () => { };
  }, [getContractDepositPopupDetailsData]);



  const handleSendDepositNote = () => {
    const sendNotePayload = {
      category_id: "2",
      subject: subjectLine,
      message: message,
      contract_id: String(contractId),
    };

    PostAddNote(sendNotePayload).then((response) => {
      if (response?.data?.bReturn) {
        toast.success(response.data.message);
        setMessage("");
        setSubjectLine("");
        getNotesDetailsDataRefetch();
      }
    });
  };

  const handleConfirmDepositDetail = (data) => {
    const updateDepositDetailPayload = {
      contract_id: String(contractId),
      deposit_payment_date: data?.dateOfPayment,
      deposit_status: String(data?.depositStatus?.value),
      deposit_payment_amount_received: data?.paymentReceived,
    };
    PutUpdatDepositDetails(updateDepositDetailPayload)
      .then((response) => {
        if (response?.data?.bReturn) {
          toast.success(response.data.message);
          setDepositPopup(false);
          contractOverViewDataRefetch();
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(handleConfirmDepositDetail)}>
        <div
          className="px-3 py-4"
          style={{ borderBottom: "1px Solid #F4F5F5" }}
        >
          <Row className="">
            <Col lg={3} className="my-md-3">
              <Controller
                control={control}
                {...register("depositStatus")}
                rules={{ required: true }}
                render={({ field }) => (
                  <ReactSelect
                    placeholder={"Deposit status"}
                    value={field.value}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      clearErrors("depositStatus");
                    }}
                    options={depositStatusDropdown?.map((item, index) => ({
                      value: item?.value,
                      label: item?.label,
                    }))}
                    clearable={false}
                  />
                )}
              />
              {errors.depositStatus && (
                <div className="error-msg">
                  This field is required
                </div>
              )}
            </Col>
            <Col lg={3} className="mt-md-3">
              <Controller
                control={control}
                {...register("dateOfPayment")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <div >
                    {/* <input
                      type="date"
                      id="DateofPayment"
                      placeholder="DateofPayment"
                      value={field.value}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors("dateOfPayment");
                      }}
                      
                    /> */}
                      <InputField
                          id={"DateofPayment"}
                          value={value}
                          type={"date"}

                          placeholder={"Date of Payment"}

                          iserror={errors.dateOfPayment}
                          onChange={(e) => {

                            onChange(e);
                            clearErrors("dateOfPayment");
                          }}
                        />
                    {/* <label htmlFor="DateofPayment">Date of Payment</label> */}
                  </div>
                )}
              />
              {/* {errors.dateOfPayment && (
                <div className="error-msg">
                  This field is required
                </div>
              )} */}
            </Col>
            <Col lg={3}> </Col>
          </Row>
          <Row className="">
            <Col lg={3} className="my-md-3 pe-0">
              <Controller
                control={control}
                {...register("totalAmount")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <GroupText
                    disabled={true}
                    lable="Total Amount"
                    graylabel="DKK"
                    textWidth="20%"
                    type="number"
                    value={value}
                    id={"totalAmount"}
                  />
                )}
              />
              {errors.totalAmount && (
                <div className="error-msg">
                  This field is required
                </div>
              )}
            </Col>
            <Col lg={3} className="mt-md-3 pe-0">
              <Controller
                control={control}
                {...register("paymentReceived")}
                rules={{ required: true }}
                render={({ field }) => (
                  <GroupText
                    lable="Payment Recieved"
                    graylabel="DKK"
                    textWidth="20%"
                    type="number"
                    value={field.value}
                    {...field}
                    id={"paymentReceived"}
                    onChange={(e) => {
                      field.onChange(e);
                      clearErrors("paymentReceived");
                    }}
                  />
                )}
              />
              {errors.paymentReceived && (
                <div className="error-msg">
                  This field is required
                </div>
              )}
            </Col>
          </Row>
        </div>
        <div className="px-3 py-4">
          <div className="mb-3 font-semibold fs-14">Add a note</div>
          <div className="deposit-text-area">
            <div className="pe-2 pt-2">
              <div
                style={{ height: "219px ", overflowY: "scroll" }}
                className="scrollable-container-y "
              >
                {getNotesDetailsData?.data?.filter(
                  (el) => el?.category_id === 2
                )?.length > 0 ? (
                  getNotesDetailsData?.data
                    ?.filter((el) => el?.category_id === 2)
                    ?.map((item, index) => (
                      <div
                        key={index}
                        className="card-div px-3 py-2 ms-3  mb-2"
                      >
                        <div className="d-flex justify-content-between">
                          <div
                            style={{
                              backgroundColor: item?.bg_color || "#9370DB",
                              color: "white",
                              borderRadius: "4px",
                              fontSize: "9px",
                              alignItems: "center",
                              textTransform: "uppercase",
                            }}
                            className="mt-1 px-2 font-semibold  d-flex"
                          >
                            {item?.category_name}
                          </div>
                          <div className="fs-10">
                            {moment(item?.created_on)?.format(
                              "DD MMM, YYYY [at] hh:mm"
                            )}
                          </div>
                        </div>

                        <div className="mt-2 font-semibold fs-14">
                          {item?.subject}
                        </div>

                        <div className="mt-1 light-font-text">
                          {item?.message}
                        </div>
                        <div className="mt-2 d-flex justify-content-between">
                          <div className="light-font-text fs-12">
                            by {item?.created_by}
                          </div>
                          <div className="cursor-pointer">
                            <FontAwesomeIcon
                              icon="fa-regular fa-arrow-up-right-from-square"
                              style={{ color: "#000000" }}
                            />
                          </div>
                        </div>
                      </div>
                    ))
                ) : (
                  <div
                    className="d-flex justify-content-center w-100 fs-12 mt-2"
                    style={{ color: "#888888" }}
                  >
                    No notes found
                  </div>
                )}
              </div>
            </div>
            <div className="text-area p-3">
              <InputGroup className="deposit-textfield">
                <Form.Control
                  placeholder="Subject line"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  value={subjectLine}
                  onChange={(e) => setSubjectLine(e.target.value)}
                />
              </InputGroup>

              <InputGroup className="deposit-textarea">
                <Form.Control
                  as="textarea"
                  placeholder="Messages"
                  aria-label="Large"
                  aria-describedby="inputGroup-sizing-sm"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <InputGroup.Text
                  className="deposit-textarea-icon"
                  id="inputGroup-sizing-lg"
                >
                  <button
                    type="button"
                    disabled={
                      subjectLine?.length == 0 || (message?.length == 0 && true)
                    }
                    onClick={() => handleSendDepositNote()}
                    className="message-send-button"
                    style={{
                      opacity:
                        subjectLine?.length == 0 || message?.length == 0
                          ? "50%"
                          : "inherit",
                    }}
                  >
                    {
                      <FontAwesomeIcon
                        size="xs"
                        icon="fa-regular fa-paper-plane-top"
                        style={{ color: "#FFFFFF" }}
                      />
                    }
                  </button>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            borderTop: "1px solid #F4F5F5",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="CLOSE"
            onClick={() => setDepositPopup(false)}
          />
          <ETButton type="submit" variant={"primary"} buttonText="CONFIRM" />
        </div>
      </Form>
    </div>
  );
};

export default DepositDetails;
