import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../../../../../Components/Buttons/ETButton";
import CustomToolTip from "../../../../../../Components/Tooltip/CustomTooltip";
import FilterComponent from "../../../../../../reactdataTableComponent/FilterComponent";
import ViewDetailsModal from "./ViewDetailsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./EditFacilities.css";
import Menus from "../../../../../../Components/Menus/Menus";
import CustomPopup from "../../../../../../Components/CustomPopup";
import { MemoizedAddFacilityPopupTable } from "../../../../../facilities/AddFacilityPopupTable";
import {
  useGetFacilityByUnitId,
  useEditUnitFacilityAttributeValue,
  useGetFacilityAttributesByFacilityId,
  useGetFacilityCategory,
  useGetFacilityGroup,
  useGetFacilityByPropertyId,
  useEditFacilityDetail,
  useGetFacilityByPropertyIdUnit,
} from "../../../../../../hooks/queries/usefacilityQueries";
import {
  DeleteUnitFacility,
  EditFacilityDetail,
  GetFacilityByPropertyId,
} from "../../../../../../services/Facility";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import ReactSelect from "../../../../../../Components/DropDown/ReactSelect";
import { useFieldArray, useForm } from "react-hook-form";
import moment from "moment";
import { status } from "../../../../../../model/Constant";
import { useRouting } from "../../../../../../hooks/stores/useRoutingStore";
import AddDetailPopup from "../../../../../facilities/AddDetailPopup";
import { useQuery } from "react-query";
import { GenerateUpdateInfo } from "../../../../../../utils/TimeZone";
import { useUnitId } from "../../../../../../hooks/stores/useUnitStore";
import SortingFunction from "../../../../../../Components/Sorting/SortingFunction";
import { useBreadcrumb } from "../../../../../../Components/BreadcrumbContext ";
import { H2, H3 } from "../../../../../../styles/Common.style";


export const Facilities = ({ propId }) => {
  const [openAddFacilityModal, setOpenAddFacilityModal] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [unitFacilityId, setUnitFacilityId] = useState("");
  const [facilityId, setFacilityId] = useState("");
  const [toggleYes, setToggleYes] = useState(true);
  const [addDetail, setAddDetail] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [groupFilter, setGroupFilter] = useState("");
  const [checkedFacilitiesPackage, setCheckedFacilitiesPackage] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [preSelectedRows, setPreSelectedRows] = useState({});

  const [searchMessage, setSearchMessage] = useState("");
  const [defaultAttributeFieldDetail, setDefaultAttributeFieldDetail] =
    useState([]);
  const [facilityCardData, setFacilityCardData] = useState([]);
  console.log("🚀 ~ file: Facilities.js:61 ~ Facilities ~ facilityCardData:", facilityCardData)
  const [facilityPackageId, setFacilityPackageId] = useState("");
  const [filterData, setFilterData] = useState([])
  const [filterKeys, setFilterKeys] = useState({
    category_name: [],
    group_name: [],
  });
  console.log("🚀 ~ file: Facilities.js:68 ~ Facilities ~ filterKeys:", filterKeys)
  const { packageId } = useRouting((state) => state);
  const { UnitId } = useUnitId((state) => state);
  const {
    mutateAsync: EditFacilityMutate,
    isLoading: EditFacilityMutateLoading,
  } = useEditFacilityDetail();

  const { data: getFacilityByUnitId, refetch: getFacilityByUnitIdRefetch } =
    useGetFacilityByUnitId(propId, UnitId);

  const { mutateAsync: EditUnitFacilityAttributeValueMutate } =
    useEditUnitFacilityAttributeValue();
  const {
    data: GetFacilityAttributesByFacilityIdData,
    refetch: GetFacilityAttributesByFacilityIdDataRefetch,
  } = useGetFacilityAttributesByFacilityId(propId, UnitId, 0, facilityId);
  const { data: qGetFacilityCategory } = useGetFacilityCategory();
  const { data: qGetFacilityGroup } = useGetFacilityGroup();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    defaultValues: {
      attributeDetails: defaultAttributeFieldDetail,
    },
  });
  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    updateBreadcrumbs(["Facilities"]);
  }, []);
  const {
    data: GetFacilityByPropertyIdData,
    refetch: GetFacilityByPropertyIdRefetch,
  } = useGetFacilityByPropertyIdUnit(propId, 1);

  useEffect(() => {
    GetFacilityByPropertyIdRefetch();
  }, []);

  useEffect(() => {
    setFacilityCardData(getFacilityByUnitId?.data);
    setFilterData(getFacilityByUnitId?.data)
    const addedFacilities = getFacilityByUnitId?.data?.map(
      (ele, i) => ele.facility_id
    );
    setCheckedFacilitiesPackage(addedFacilities);
  }, [getFacilityByUnitId]);

  useEffect(() => {
    let facilityData = GetFacilityAttributesByFacilityIdData?.data[0];
    onInitSetAttributeDetail(facilityData);
  }, [GetFacilityAttributesByFacilityIdData]);

  const handleSearch = (e) => {
    setSearchMessage(true);
    setSearchText(e.target.value);
    let filteredData = getFacilityByUnitId?.data?.filter((fData, i) =>
      fData?.name?.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFacilityCardData(filteredData);
  };

  const onInitSetAttributeDetail = (data) => {
    //Default Attribute FieldDetail for append or default set when no record is there
    let attributeFieldDetail = data?.attribute_field_details;
    let attributeDataDetail = data?.attribute_data_details;
    setDefaultAttributeFieldDetail(attributeFieldDetail);

    if (attributeDataDetail?.length !== 0) {
      let facilityAttributeData = [];
      attributeDataDetail?.forEach((row, rowIndex) => {
        let fieldsData = [];
        row?.forEach((attribute, colIndex) => {
          let fieldAttribute = structuredClone(
            attributeFieldDetail.find(
              (x) => x.facility_attribute_id === attribute.facility_attribute_id
            )
          );

          fieldAttribute["attribute_value"] =
            fieldAttribute.attribute_type === "DROPDOWN"
              ? {
                value: attribute.attribute_value,
                label: attribute.attribute_value,
              }
              : attribute.attribute_value;
          fieldsData.push(fieldAttribute);
        });

        facilityAttributeData.push({
          row_id: Math.floor(Date.now() / 1000),
          fields: fieldsData,
        });
      });
      reset(
        {
          attributeDetails: facilityAttributeData,
        },
        [reset]
      );
    } else {
      reset(
        {
          attributeDetails: [
            {
              row_id: Math.floor(Date.now() / 1000),
              fields: attributeFieldDetail,
            },
          ],
        },
        [reset]
      );
    }
  };

  const handleOpenAddFacility = () => {
    GetFacilityByPropertyIdRefetch();
    setOpenAddFacilityModal(true);
  };

  const handleAttributeValueChange = (value, index, i) => {
    fields[index].fields[i]["attribute_value"] = value;
  };
  const handleAddFacility = () => {
    const facilityIds = Object.keys(rowSelection);
    facilityIds?.forEach((Id, i) => {
      const editFacilityDetailPayload = {
        unit_id: UnitId?.toString(),
        property_id: propId?.toString(),
        facility_id: Id,
        is_set: 1,
        is_active: 1,
      };

      EditFacilityMutate(editFacilityDetailPayload).then((response) => {
        toast.success(response.data.message, { toastId: UnitId });

        setOpenAddFacilityModal(false);
        getFacilityByUnitIdRefetch();
      });
      setPreSelectedRows(Object.keys(rowSelection));
    });
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "attributeDetails",
  });
  const handleAdd = () => {
    append({
      row_id: Math.floor(Date.now() / 1000),
      fields: defaultAttributeFieldDetail,
    });
  };

  const handleDelete = (i) => {
    remove(i);
  };
  // const categoryDropdown = (e, ActionTypes) => {
  //   if (ActionTypes.action === "clear") {
  //     setCategoryFilter("");
  //     setFacilityCardData(getFacilityByUnitId?.data);
  //   } else {
  //     setCategoryFilter(e?.value);
  //     let filteredData = getFacilityByUnitId?.data?.filter(
  //       (fData, i) => fData?.category_id === e?.value
  //     );
  //     setFacilityCardData(filteredData);
  //   }
  // };

  // const groupDropdown = (e, ActionTypes) => {
  //   if (ActionTypes.action === "clear") {
  //     setGroupFilter("");
  //     setFacilityCardData(getFacilityByUnitId?.data);
  //   } else {
  //     setGroupFilter(e.value);
  //     let filteredData = getFacilityByUnitId?.data?.filter(
  //       (fData, i) => fData?.group_id === e?.value
  //     );
  //     setFacilityCardData(filteredData);
  //   }
  // };
  const handleSaveFacilityAttributes = async (data) => {
    let details = [];

    data?.attributeDetails?.map((item, index) => {
      let attr = [];
      item?.fields?.map((attribute, i) => {
        const { facility_attribute_id, attribute_value, attribute_type } =
          attribute;
        if (attribute_type === "DROPDOWN") {
          attr.push({
            facility_attribute_id: facility_attribute_id,
            attribute_value: attribute_value?.value,
          });
        } else {
          attr.push({
            facility_attribute_id: facility_attribute_id,
            attribute_value: attribute_value,
          });
        }
      });
      details.push(attr);
    });

    const editAttributeDetailPayload = {
      facility_package_id: packageId,
      facility_id: getFacilityByUnitId?.data
        ?.filter((el) => el.unit_facility_id === unitFacilityId)?.[0]
        ?.facility_id?.toString(),
      unit_facility_id: unitFacilityId?.toString(),
      is_set: "1",
      details,
    };
    try {
      const response = await EditUnitFacilityAttributeValueMutate(
        editAttributeDetailPayload
      );
      if (response.status === status.s && response?.data?.bReturn) {
        toast.success(response?.data?.message);
        setAddDetail(false);
        remove();
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (
      Object.keys(preSelectedRows).length === Object.keys(rowSelection).length
    ) {
      setButtonDisabled(true);
    } else if (Object.keys(rowSelection).length === 0) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [openAddFacilityModal, rowSelection]);

  const pushDataToFilterKey = (key, value) => {
    setFilterKeys((prevFilterKeys) => ({
      ...prevFilterKeys,
      [key]: [value],
    }));
  };

  const handleFilter = (value, selected, ActionTypes) => {
    if (ActionTypes.action === "clear") {
      // Clear only the 'category' key
      if (value === "category") {
        setFilterKeys((prevFilterKeys) => ({
          ...prevFilterKeys,
          category_name: [],
        }));
      } else if (value === "group") {
        setFilterKeys((prevFilterKeys) => ({
          ...prevFilterKeys,
          group_name: [],
        }));
      }
      else if (value === "active") {
        setFilterKeys((prevFilterKeys) => ({
          ...prevFilterKeys,
          active_on_property: [],
        }));
      }
    } else if (value === "category") {
      const key = "category_name";
      const selectedValue = selected?.value;
      pushDataToFilterKey(key, selectedValue);
    } else if (value === "group") {
      const key = "group_name";
      const selectedValue = selected?.value;
      pushDataToFilterKey(key, selectedValue);
    }
  };

  useEffect(() => {
    const filteredData = filterData?.filter(item => {
      if (
        filterKeys.category_name?.length > 0 &&
        !filterKeys.category_name?.includes(item.category_id)
      ) {
        return false;
      }

      if (
        filterKeys?.group_name?.length > 0 &&
        !filterKeys?.group_name?.includes(item.group_id)
      ) {
        return false;
      }

      return true;
    });

    setFacilityCardData(filteredData);
  }, [filterKeys, filterData]);
  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: "8px",
        fontSize: "14px",
      }}
    >
      <div className="d-flex justify-content-between px-4 pt-3">
        <div className="d-flex flex-column ">
          <div className="d-flex align-items-center blackColor ">
            <H2>Facilities{" "}</H2>
            <span className="ms-2 ">
              <CustomToolTip text="Facilities" size="md" />
            </span>
          </div>
          {/* <div style={{ fontSize: "12px", color: "#888", fontWeight: "300" }}>
						Last updated by Jesper Thorkildsen, 19. Jan. 2023 | 16:21:03
					</div> */}
        </div>
      </div>

      <hr className="mb-2 hr" />

      <div className="ps-4 pe-4">
        <div className="form-label-title mt-3 mb-2 blackColor ">Facilities</div>
        <div>
          <Row>
            <Col xl={2} lg={4} md={4} className="my-1 pe-lg-0 ">
              <FilterComponent
                filtertext={searchText}
                handleSearch={handleSearch}
              />
            </Col>
            <Col xl={2} lg={4} md={4} className="my-1 pe-lg-0 ">
              <ReactSelect
                clearable={true}
                placeholder={"Category"}
                // onChange={(e, ActionTypes) => categoryDropdown(e, ActionTypes)}
                options={qGetFacilityCategory?.data?.map((item) => ({
                  value: item?.Id,
                  label: `${item?.Text} `,
                }))}
                onChange={(value, ActionTypes) => {
                  handleFilter("category", value, ActionTypes);
                }}
              />
            </Col>

            <Col xl={2} lg={4} md={4} className="my-1 pe-lg-0 ">
              <ReactSelect
                placeholder={"Group"}
                // onChange={(e, ActionTypes) => groupDropdown(e, ActionTypes)}
                options={qGetFacilityGroup?.data?.map((item) => ({
                  value: item?.Id,
                  label: `${item?.Text} `,
                }))}
                onChange={(value, ActionTypes) => {
                  handleFilter("group", value, ActionTypes);
                }}
              />
            </Col>
            <Col xl={4} lg={5} md={6} className="px-lg-0 ">
              {facilityCardData?.length != 0 && (
                <div className="my-1 ">
                  <SortingFunction
                    data={getFacilityByUnitId?.data}
                    setData={setFacilityCardData}
                    sortingByName={"package_name"}
                    sortById={"facility_package_id"}
                  />
                </div>
              )}
            </Col>
            <Col xl={2} lg={7} md={6} className="my-md-1 my-2 px-lg-2  pe-lg-0">
              <ETButton
                variant={"primary"}
                width="100%"
                buttonText="ADD FACILITY"
                onClick={handleOpenAddFacility}
                icon={true}
              />
            </Col>
          </Row>
        </div>
        <Row className=" pt-3 pb-5 gap-2">
          <Col className="pe-0 pb-1 ps-2 ">
            <div
              className="d-flex ps-1"
              style={{ gap: "13px", flexDirection: "row", flexWrap: "wrap" }}
            >
              {facilityCardData?.length == 0 && searchMessage ? (
                <div className="d-flex justify-content-center w-100 fs-12 mt-1 grey-text">
                  <div> Your search didn't match any facility detail. </div>{" "}
                </div>
              ) : (
                <>
                  {getFacilityByUnitId?.data?.length == 0 ? (
                    <div className="d-flex justify-content-center w-100 fs-12 mt-1 grey-text">
                      <div>You do not have any Facilitie yet. </div>{" "}
                    </div>
                  ) : (
                    <>
                      {facilityCardData?.map((item, index) => (
                        <React.Fragment key={index}>
                          <FacilityCard
                            key={index}
                            fields={fields}
                            UnitId={UnitId}
                            packageId={packageId}
                            facilityPackageId={facilityPackageId}
                            unitFacilityId={unitFacilityId}
                            setFacilityPackageId={setFacilityPackageId}
                            setUnitFacilityId={setUnitFacilityId}
                            facilityId={facilityId}
                            setDefaultAttributeFieldDetail={
                              setDefaultAttributeFieldDetail
                            }
                            setFacilityId={setFacilityId}
                            data={item}
                            index={index}
                            unitId={UnitId}
                            propId={propId}
                            getFacilityByUnitIdRefetch={
                              getFacilityByUnitIdRefetch
                            }
                            setAddDetail={setAddDetail}
                          />
                        </React.Fragment>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>

      <>
        <CustomPopup
          style={{ position: "relative" }}
          size="lg"
          open={openAddFacilityModal}
          onHide={() => setOpenAddFacilityModal(false)}
          title={"Add Facility"}
          title_color="black"
          titleTooltipText="Need Help About Tags"
          titleTooltip={true}
          enableCrossButton={false}
          onCloseAction={() => {
            setOpenAddFacilityModal(false);
            alert("Action Peformed Successfuly.");
          }}
        >
          <MemoizedAddFacilityPopupTable
            GetFacilityByPropertyIdRefetch={GetFacilityByPropertyIdRefetch}
            GetFacilityByPropertyIdData={GetFacilityByPropertyIdData}
            propertyId={propId}
            setRowSelection={setRowSelection}
            rowSelection={rowSelection}
            type={1}
            checkedFacilitiesPackage={checkedFacilitiesPackage}
            setCheckedFacilitiesPackage={setCheckedFacilitiesPackage}
            flag={"packageLevel"}
          />
          <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            {" "}
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => {
                setOpenAddFacilityModal(false);
                // setRowSelection({});
              }}
            />
            <ETButton
              disabled={buttonDisabled}
              variant={"primary"}
              buttonText="ADD"
              onClick={handleAddFacility}
            />
          </div>
        </CustomPopup>

        {/* edit facility */}
        <CustomPopup
          modalHeadingStyle={true}
          style={{ position: "relative" }}
          size="lg"
          open={addDetail}
          onHide={() => setAddDetail(false)}
          title="Add details"
          title_color="black"
          titleTooltipText="Need Help About Tags"
          titleTooltip={true}
          enableCrossButton={false}
          onCloseAction={() => {
            setAddDetail(false);
          }}
        >
          <AddDetailPopup
            setAddDetail={setAddDetail}
            facilityId={facilityId}
            propertyId={propId}
            packageId={packageId}
            UnitId={UnitId}
            facilityLevel={"units"}
            // toggleYes={toggleYes}
            // setToggleYes={setToggleYes}
            GetFacilityAttributesByFacilityIdData={
              GetFacilityAttributesByFacilityIdData
            }
            GetFacilityAttributesByFacilityIdDataRefetch={
              GetFacilityAttributesByFacilityIdDataRefetch
            }
            GetFacilityByPropertyIdRefetch={GetFacilityByPropertyIdRefetch}
            GetFacilityByPropertyIdData={GetFacilityByPropertyIdData}
            refetch={getFacilityByUnitIdRefetch}
            getFacilityByUnitIdRefetch={getFacilityByUnitIdRefetch}
          />
        </CustomPopup>
      </>
      <div className="py-2 px-3 mt-2 d-sm-flex justify-content-between footer-section-button-container">
        <div className="m-1">
          <NavLink
            to="/property-units/units/residential"
            state={{ propId: propId, Tab: "Units" }}
          >
            <ETButton variant={"white"} buttonText="BACK TO UNITS" />
          </NavLink>
        </div>
      </div>
    </div>
  );
};

const FacilityCard = ({
  fields,
  UnitId,
  packageId,
  facilityPackageId,
  facilityId,
  setFacilityId,
  unitFacilityId,
  setFacilityPackageId,
  setDefaultAttributeFieldDetail,
  setEditFlag,
  editFlag,
  data,
  index,
  propId,
  unitId,
  getFacilityByUnitIdRefetch,
  setAddDetail,
  defaultAttributeDetail,
  setDefaultAttributeDetail,
  setUnitFacilityId,
}) => {
  const [openViewDetailsModal, setOpenViewDetailsModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  // const [editFlag, setEditFlag] = useState(false)

  const handleOpenViewDetails = (data) => {
    // console.log("data handle view", data);
    if (data?.unit_facility_id !== 0 && data?.facility_package_id == "") {
      setUnitFacilityId(UnitId);
      setFacilityPackageId(0);
    } else if (
      data?.unit_facility_id == 0 &&
      data?.facility_package_id !== ""
    ) {
      setUnitFacilityId(0);
      setFacilityPackageId(Number(data?.facility_package_id));
    }

    setFacilityId(data?.facility_id);

    setOpenViewDetailsModal(true);
  };

  const handleDeleteFacility = () => {
    DeleteUnitFacility(propId, unitId, data?.unit_facility_id).then(
      (response) => {
        toast.success(response.data.message);
        setOpenDeleteModal(false);
        getFacilityByUnitIdRefetch();
      }
    );
  };
  const modifiedOn = moment(data?.modified_on).format(
    "DD.MMMM.YYYY | HH:mm:ss"
  );
  const createdOn = moment(data?.created_on).format("DD.MMMM.YYYY | HH:mm:ss");
  return (
    <>
      <div key={index} className="Facility-card-body my-2">
        <div
          className="d-flex p-3"
          style={{
            height: "100px",
            borderBottom: "1px solid #F0F0F0",
          }}
        >
          <div
            className="p-2 d-flex justify-content-center align-items-center"
            style={{
              background: "#FFFFFF",
              height: "68px",
              width: "68px",
              borderRadius: "8px",
              border: "1px Solid #D1D1D1",
            }}
          >
            <FontAwesomeIcon
              icon={data?.icon_name}
              style={{ color: "#000000", height: "34px", width: "39px" }}
            />
          </div>
          <div className="ms-3" style={{ lineHeight: "15px", width: "63%" }}>
            <div className="form-label-title fs-12">Facility name</div>
            <div className="light-font-text fs-12">{data?.name}</div>
            <div className="mt-2 form-label-title fs-12">Tag</div>
            <div className="light-font-text ellipsis fs-12">
              {data.source ? data.source : "-"}
            </div>
          </div>
        </div>
        <div className="d-flex px-3 pt-3 ">
          <div>
            <div className="form-label-title fs-12">Category</div>
            <div className="light-font-text fs-12">{data?.category_name}</div>
          </div>
          <div className="ms-5">
            <div className="form-label-title fs-12">Group</div>
            <div className="light-font-text fs-12">{data?.group_name}</div>
          </div>
        </div>
        <div className="d-flex ps-3 py-2 justify-content-between">
          <div className="fs-12 pt-1 ">
            <GenerateUpdateInfo data={data} flag={false} />
          </div>
          <div className="d-flex mb-1 mx-2 align-items-end">
            <Menus
              elevation={0}
              // anchorOrigin={{
              // 	vertical: "bottom",
              // 	horizontal: "left",
              // }}
              // transformOrigin={{
              // 	vertical: "top",
              // 	horizontal: "left",
              // }}
              component={
                <>
                  {data?.is_individual_facility === 0 && (
                    <div
                      style={{
                        fontSize: "14px",
                      }}
                      onClick={() => handleOpenViewDetails(data)}
                    >
                      <div className="addpropContent px-3 py-2 ">
                        <span className="ms-1">View details</span>
                      </div>
                    </div>
                  )}

                  {/* {data.attribute_field_details.length !==0 && data.is_individual_facility == 1} */}

                  {data?.is_individual_facility === 1 && (
                    <>
                      <div
                        style={{
                          fontSize: "14px",
                          borderBottom: "1px solid #F0F0F0",
                        }}
                        onClick={() => handleOpenViewDetails(data)}
                      >
                        <div className="addpropContent px-3 py-2 ">
                          <span className="ms-1">View details</span>
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          borderBottom: "1px solid #F0F0F0",
                        }}
                      >
                        <div
                          onClick={() => {
                            setAddDetail(true);
                            setUnitFacilityId(data?.unit_facility_id);
                            setFacilityId(data?.facility_id);
                          }}
                          className="addpropContent px-3 py-2 "
                        >
                          <span className="ms-1">Edit</span>
                        </div>
                      </div>
                      <div style={{ fontSize: "14px" }}>
                        <div
                          onClick={() => setOpenDeleteModal(true)}
                          className="addpropContent px-3 py-2 "
                        >
                          <span className="ms-1">Delete</span>
                        </div>
                      </div>
                    </>
                  )}
                  {/* {data?.is_individual_facility === 1 && (
										<div style={{ fontSize: "14px" }}>
											<div
												onClick={() => setOpenDeleteModal(true)}
												className="addpropContent px-3 py-2 ">
												<span className="ms-1">Delete</span>
											</div>
										</div>
									)} */}
                </>
              }
            />
          </div>
        </div>

        <div className="d-flex justify-content-end me-3"></div>
      </div>
      <ViewDetailsModal
        propId={propId}
        UnitId={UnitId}
        unitFacilityId={unitFacilityId}
        facilityPackageId={facilityPackageId}
        facilityId={facilityId}
        setDefaultAttributeFieldDetail={setDefaultAttributeFieldDetail}
        openViewDetailsModal={openViewDetailsModal}
        setOpenViewDetailsModal={setOpenViewDetailsModal}
      />
      {/* Delete facility */}
      <CustomPopup
        open={openDeleteModal}
        onHide={() => setOpenDeleteModal(false)}
        title="Delete facility"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
        size={"md"}
      >
        <div className="p-3">
          <div className="px-2">
            <div className="fw-300 fs-14 my-1" style={{ height: "100px" }}>
              Are you sure you want to delete the facility?
            </div>
          </div>
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => {
              setOpenDeleteModal(false);
            }}
          />
          <ETButton
            variant={"primary"}
            buttonText="I'M SURE"
            onClick={() => handleDeleteFacility()}
          />
        </div>
      </CustomPopup>
      {/* Delete document property */}
    </>
  );
};
