import React, { useMemo } from "react";
import CustomPopup from "../../Components/CustomPopup";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../Components/Buttons/ETButton";
import { OpenPositionsData } from "./StaticData";
import MRTTheme from "../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";

import {
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../Components/table/MRTGlobalProps";
import { NoRecordsToDisplay } from "../../utils/validationConstants";

const OpenPositions = ({ showOpenPositions, setShowOpenPositions }) => {
  const OpenPositionsColumns = useMemo(
    () => [
      {
        accessorKey: "posted",
        header: "Posted",
        size: 120,
      },
      {
        accessorKey: "text",
        header: "Text",
        size: 140,
        Cell: ({ row }) => (
          <>
            <div>{row?.original?.text}</div>
          </>
        ),
      },
      {
        accessorKey: "due_Date",
        header: "Due date",
        size: 120,
      },
      {
        accessorKey: "period_From",
        header: "period from",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div>{row?.original?.period_From}</div>
          </>
        ),
      },
      {
        accessorKey: "period_To",
        header: "period to",
        size: 120,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div>{row?.original?.period_To}</div>
          </>
        ),
      },
      {
        accessorKey: "amount",
        header: "Amount ",
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div>{row?.original?.amount}</div>
          </>
        ),
      },
      {
        accessorKey: "balance",
        header: "Balance",
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div>{row?.original?.balance}</div>
          </>
        ),
      },
    ],
    []
  );
  return (
    <div>
      {" "}
      <CustomPopup
        open={showOpenPositions}
        onHide={() => setShowOpenPositions(false)}
        title="Open positions"
        title_color="black"
        size={"lg"}
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        titleTooltipText={"Open positions"}
      >
        <div className="p-4">
          <div className="d-flex justify-content-end mb-2 ">
            {" "}
            <ETButton variant={"primary"} buttonText="EXPORT" />{" "}
          </div>

          <MRTTheme
            colorVariantsForTableData={["odd"]}
            table={
              <MaterialReactTable
                muiTablePaperProps={{
                  selected: false,
                  elevation: 0,
                  fontFamily: "Cerebri sans-light !important",
                  sx: {
                    borderRadius: "0",
                    border: "none",
                    table: {
                      borderCollapse: "separate",
                      borderSpacing: "0 5px",
                    },
                  },
                }}
                muiTableContainerProps={{
                  sx: {
                    padding: "0px !important",
                  },
                }}
                columns={OpenPositionsColumns}
                // data={OpenPositionsData || []}
                data={[]}
                icons={{...SortIcon}}
                localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                enableBottomToolbar={true}
                enableColumnActions={false}
                enablePagination={true}
                muiTableHeadRowProps={{
                  sx: {
                    ...TableRowsStyle,
                  },
                }}
                muiTablePaginationProps={{
                  ...paginationoptions,
                }}
                positionActionsColumn="last"
                renderTopToolbar={false}
              />
            }
          />
        </div>
        <div className="py-2 px-4 d-flex justify-content-end footer-section-button-container">
          <ETButton
            variant={"primary"}
            buttonText="CLOSE"
            onClick={() => setShowOpenPositions(false)}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default OpenPositions;
