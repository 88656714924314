import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  DeleteOrganisationMaintenance,
  GetOrganisationMaintenanceDetails,
  GetOrganisationMaintenanceHistory,
  GetOrganizationById,
  GetOrganizationByPublicType,
  GetOrganizationFloorType,
  GetOrganizationList,
  GetOrganizationOverviewById,
  GetOrganizationSideType,
  PostOrganisationMaintenanceDetails,
  PostUpdateOrganisationCustomisation,
  PutOrganisationMaintenanceDetails,
  PutUpdateOrganization,
} from "../../services/Organization";

//Get Organization
export const useGetOrganization = () => {
  return useQuery(["qGetOrganization"], () => GetOrganizationList());
};

//Get Organization by id
export const useGetOrganizationById = (organizationId) => {
  return useQuery(
    ["qGetOrganizationById", organizationId],
    () => GetOrganizationById(organizationId),
    {
      enabled: !!organizationId,
    }
  );
};

// Get Organization  By public type
export const useGetOrganizationByPublicType = () => {
  return useQuery(["qGetOrganizationByPublicType"], () =>
    GetOrganizationByPublicType()
  );
};

// Put Update Organization
export const usePutUpdateOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation(PutUpdateOrganization, {
    onSuccess: () => {
      queryClient.invalidateQueries("getUserById");
      queryClient.invalidateQueries("qGetOrganizationOverviewById");
    },
  });
};

// Get Organization side type
export const useGetOrganizationSideType = () => {
  return useQuery(["qGetOrganizationBySideType"], () =>
    GetOrganizationSideType()
  );
};
// Get Organization floor type
export const useGetOrganizationFloorType = () => {
  return useQuery(["qGetOrganizationByFloorType"], () =>
    GetOrganizationFloorType()
  );
};

//Get Organization overview by organization Id
export const useGetOrganizationOverviewById = (organization_id) => {
  return useQuery(
    ["qGetOrganizationOverviewById", organization_id],
    () => GetOrganizationOverviewById(organization_id),
    {
      enabled: !!organization_id,
    }
  );
};

// Get Organisation Maintenance Details
export const useGetOrganisationMaintenanceDetails = (organizationId) => {
  return useQuery(
    ["qGetOrganisationMaintenanceDetails", organizationId],
    () => GetOrganisationMaintenanceDetails(organizationId),
    {
      enabled: !!organizationId,
    }
  );
};

//Put Organisation Maintenance Details
export const usePutOrganisationMaintenanceDetails = () => {
  return useMutation(PutOrganisationMaintenanceDetails);
};

// Post Organisation MaintenanceDetails
export const usePostOrganisationMaintenanceDetails = () => {
  const queryClient = useQueryClient();
  return useMutation(PostOrganisationMaintenanceDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries("qGetOrganisationMaintenanceDetails");
    },
  });
};

//Get Organization mainatenance history
export const useGetOrganisationMaintenanceHistory = (organizationId) => {
  return useQuery(
    ["qGetOrganisationMaintenanceHistory", organizationId],
    () => GetOrganisationMaintenanceHistory(organizationId),
    {
      enabled: !!organizationId,
    }
  );
};

// Delete Maintenance date
export const useDeleteOrganisationMaintenance = () => {
  return useMutation(DeleteOrganisationMaintenance);
};

// Add Organisation Deactivate Details
export const usePostUpdateOrganisationCustomisation = () => {
  const queryClient = useQueryClient();
  return useMutation(PostUpdateOrganisationCustomisation, {
    onSuccess: () => {
      queryClient.invalidateQueries("qPostUpdateOrganisationCustomisation");
    },
  });
};
