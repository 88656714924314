import { useState } from "react";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../../../../../Components/Tabs/ReactTabs.style";
import CustomToolTip from "../../../../../../Components/Tooltip/CustomTooltip";
import EditDocumentPackages from "./EditDocumentPackages";
import EditSigningPackages from "./EditSigningPackages";
import { useGetDocumentsByUnitId } from "../../../../../../hooks/queries/useDocumentsQueries";
import { useEffect } from "react";
import SortingFunction from "../../../../../../Components/Sorting/SortingFunction";
import { useBreadcrumb } from "../../../../../../Components/BreadcrumbContext ";
import { H2, H3 } from "../../../../../../styles/Common.style";


const EditDocuments = ({ UnitId, propId }) => {
  const [tabType, setTabType] = useState(0);
  const [allData, setAllData] = useState([]);
  //---------------------React query-------------------//
  const {
    data: GetDocumentsByUnitIdData,
    refetch: GetDocumentsByUnitIdDatarefetch,
  } = useGetDocumentsByUnitId(propId, UnitId, tabType);

  const [activeTab, setActiveTab] = useState({
    tabName: "",
  });
  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    if (tabType == 0) {
      updateBreadcrumbs(["Documents", "Documents"]);

    } else if (tabType == 1) {
      updateBreadcrumbs(["Documents", "Signing"]);
    } else { }
  }, [tabType])

  useEffect(() => {
    GetDocumentsByUnitIdDatarefetch();
    setAllData(GetDocumentsByUnitIdData?.data);
  }, [tabType]);
  useEffect(() => {
    setAllData(GetDocumentsByUnitIdData?.data);
  }, [GetDocumentsByUnitIdData?.data]);

  // console.log(GetDocumentsByUnitIdData?.data, "allData");
  return (
    <div className="">
      <div className="bg-white radius-8 fs-14  mainborder">
        <div
          className="px-4 pb-3"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div style={{ fontFamily: "Cerebri Sans" }} className="pt-3 d-flex align-items-center">
            <H2 >Documents</H2>
            <span className="ms-2 ">
              <CustomToolTip  text="Documents" />
            </span>
          </div>
        </div>
        <Tabs className="d-flex flex-column m-0">
          <div
            className="px-4 py-3 d-flex justify-content-between flex-wrap gap-2"
            style={{ borderBottom: "1px solid #F0F0F0" }}
          >
            <TabList
              className=" d-flex ps-0 m-0 flex-wrap"
              style={{ gap: "7px" }}
            >
              <Tab
                onClick={() => {
                  setTabType(0);
                }}
              >
                Document
              </Tab>
              <Tab
                onClick={() => {
                  setTabType(1);
                }}
              >
                Signing
              </Tab>
            </TabList>
          </div>
          <div className="document-content py-3 px-4">
            <TabPanel>
              {allData?.length != 0 && (
                <div className="p-2 pb-4">
                  <SortingFunction
                    data={GetDocumentsByUnitIdData?.data}
                    setData={setAllData}
                    sortingByName={"document_package_name"}
                    sortById={"document_package_id"}
                  />
                </div>
              )}
              {allData?.length == 0 ? (
                <div className="d-flex justify-content-center w-100 fs-12 mt-1 grey-text">
                  <div>You do not have any document yet. </div>{" "}
                </div>
              ) : (
                <EditDocumentPackages documentData={allData} />
              )}
            </TabPanel>
            <TabPanel>
              {allData?.length != 0 && (
                <div className="p-2 pb-4">
                  <SortingFunction
                    data={GetDocumentsByUnitIdData?.data}
                    setData={setAllData}
                    sortingByName={"document_package_name"}
                    sortById={"document_package_id"}
                  />
                </div>
              )}
              {allData?.length == 0 ? (
                <div className="d-flex justify-content-center w-100 fs-12 mt-1 grey-text">
                  <div>You do not have any Signing yet. </div>{" "}
                </div>
              ) : (
                <EditSigningPackages documentData={allData} />
              )}
            </TabPanel>
          </div>
        </Tabs>
        <div></div>
      </div>
    </div>
  );
};

export default EditDocuments;
