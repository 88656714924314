import { useMutation, useQuery } from "react-query";
import {
  GetActorsBySearch,
  GetActorByActorId,
  GetActorOccupationType,
  PutUpdateReservationDetails,
  PostActorCreateReservation,
  PostAddActor,
  PostUploadActorDocument,
  GetContractByUnitId,
  GetDocumentDetailsByContractId,
  GetActorByContractId,
  GetContractRentalPopupDetails,
  GetContractDepositPopupDetails,
  GetContractLeasedPopupDetails,
  GetContractStatusList,
  GetContractTerminationDetails,
  DeleteContractActor,
  PutUpdateActor,
  GetScreeningTemplateQuestionAnswer,
  GetScreeningTemplateQuestion,
  GetContractType,
} from "../../services/Actors";

//Get actors by search
export const useGetActorsBySearch = (search) => {
  return useQuery(
    ["qGetActorsBySearch", search],
    () => GetActorsBySearch(search),
    {
      enabled: !!search,
    }
  );
};

//Get actors by actorId
export const useGetActorsByActorId = (actorId) => {
  return useQuery(
    ["qGetActorByActorId", actorId],
    () => GetActorByActorId(actorId),
    {
      enabled: !!actorId,
    }
  );
};

//Get actors occupation type
export const useGetActorOccupationType = () => {
  return useQuery(["qGetActorOccupationType"], () => GetActorOccupationType());
};
//Get Actor - Contract by UnitId
export const useGetContractByUnitId = (unitId) => {
  return useQuery(
    ["qGetContractByUnitId", unitId],
    () => GetContractByUnitId(unitId),
    {
      enabled: !!unitId,
    }
  );
};
//Get Actor - Actor by contract id
export const useGetActorByContractId = (contract_id) => {
  return useQuery(
    ["qGetActorByContractId", contract_id],
    () => GetActorByContractId(contract_id),
    {
      enabled: !!contract_id,
    }
  );
};

// Put Update reservation details
export const useUpdateReservationDetails = () => {
  return useMutation(PutUpdateReservationDetails);
};
// Put Update deposit details
export const useUpdateDepositDetails = () => {
  return useMutation(PutUpdateReservationDetails);
};

// Get contract deposit popup details
export const useGetDepositPopupDetails = (contract_id) => {
  return useQuery(
    ["qGetContractDepositPopupDetails", contract_id],
    () => GetContractDepositPopupDetails(contract_id),
    {
      enabled: !!contract_id,
    }
  );
};

// Get Tenancy rental popup details
export const useGetRentalPopupDetails = (contract_id) => {
  return useQuery(
    ["qGetContractRentalPopupDetails", contract_id],
    () => GetContractRentalPopupDetails(contract_id),
    {
      enabled: !!contract_id,
    }
  );
};
// Get contract leased popup details
export const useGetleasedPopupDetails = (contract_id) => {
  return useQuery(
    ["qGetContractLeasedPopupDetails", contract_id],
    () => GetContractLeasedPopupDetails(contract_id),
    {
      enabled: !!contract_id,
    }
  );
};
// Get contract termination popup details
export const useGetTerminationPopupDetails = (contract_id) => {
  return useQuery(
    ["qGetContractTerminationPopupDetails", contract_id],
    () => GetContractTerminationDetails(contract_id),
    {
      enabled: !!contract_id,
    }
  );
};
// Get contract status list
export const useGetContractStatusList = () => {
  return useQuery(["qGetContractStatusList"], () => GetContractStatusList());
};

//Get document details by contract Id
export const useGetDocumentDetailsByContractId = (
  contract_id,
  actor_id,
  archive_type
) => {
  return useQuery(
    ["qGetDocumentDetailsByContractyId", contract_id, actor_id, archive_type],
    () => GetDocumentDetailsByContractId(contract_id, actor_id, archive_type),
    {
      enabled: !!contract_id,
    },
    {
      enabled: !!actor_id,
    },
    {
      enabled: !!archive_type,
    }
  );
};

// Get Tenancy status list
export const usePostCreatereservation = () => {
  return useMutation(PostActorCreateReservation);
};

// Post add actor
export const usePostAddActor = () => {
  return useMutation(PostAddActor);
};
// Put update actor
export const usePutUpdateActor = () => {
  return useMutation(PutUpdateActor);
};
// Delete contract actor
export const useDeleteContractActor = (contract_id, actor_id) => {
  return useMutation(DeleteContractActor(contract_id, actor_id));
};

// Post Upload actor document
export const useUploadActorDocument = () => {
  return useMutation(PostUploadActorDocument);
};

//Get Screening template question answer
export const useGetScreeningTemplateQuestionAnswer = (
  property_id,
  contract_id,
  actor_id
) => {
  return useQuery(
    ["qGetScreeningTemplateQuestionAnswer", property_id, contract_id, actor_id],
    () =>
      GetScreeningTemplateQuestionAnswer(property_id, contract_id, actor_id),
    {
      enabled: !!property_id,
    },
    {
      enabled: !!contract_id,
    },
    {
      enabled: !!actor_id,
    }
  );
};
//Get Screening template question
export const useGetScreeningTemplateQuestion = (template_id) => {
  return useQuery(
    ["qGetScreeningTemplateQuestion", template_id],
    () => GetScreeningTemplateQuestion(template_id),
    {
      enabled: !!template_id,
    }
  );
};

//Get Contract Type
export const useGetContractType = () => {
  return useQuery(["qGetContractType"], () => GetContractType());
};
