import React, {
  useMemo,
  useCallback,
  useState,
  useRef,
  useEffect,
} from "react";
import PNGWidget from "../../../Components/DownloadWidget/PNGWidget";
import JPGWidget from "../../../Components/DownloadWidget/JPGWidget";
import SVGWidget from "../../../Components/DownloadWidget/SVGWidget";
import { Col, Row } from "react-bootstrap";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import Toggle from "../../../Components/Switch/Switch";
import Menus from "../../../Components/Menus/Menus";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import { H2, H3 } from "../../../styles/Common.style";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const CustomerJourney = () => {
  const myref = useRef();
  const [refObj] = useState(myref);

  const fileName = "customer-journey";

  const PNGFunction = PNGWidget({ refObj, fileName });
  const JPGFunction = JPGWidget({ refObj, fileName });
  const SVGFunction = SVGWidget({ refObj, fileName });

  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState({});
  useEffect(() => {
    setChartData({
      labels: [
        "Registrations",
        "Participants",
        "Reservations",
        "Orderd tenant agreements",
        "Transactions",
      ],
      datasets: [
        {
          data: [0, 0, 4012, 3000, 2000],
          borderColor: ["#000000"],
          backgroundColor: ["#619130"],
          anchor: "end",
          align: "top",
          barPercentage: 0.99,
          categoryPercentage: 0.999,
          borderWidth: {
            top: 3.5,
            right: 1.5,
            bottom: 0,
            left: 0,
          },
        },
      ],
    });
    setChartOptions({
      maintainAspectRatio: false,

      plugins: {
        tooltip: {
          backgroundColor: "#FFFFFF",
          borderWidth: 2,
          borderDash: [2, 2],
          titleColor: "#000000",
          bodyColor: "#000000",
        },
        datalabels: {
          display: true,
          color: "black",
          anchor: "end",
          align: "top",

          font: {
            weight: "bold",
            size: 16,
            family: "Cerebri Sans",
          },
        },
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          grid: {
            borderDash: [5, 10],
            color: "#F4F5F5",
          },
          stacked: false,
          ticks: {
            stepSize: 50,
          },
        },

        x: {
          stacked: false,
          grid: {
            color: "transparent",
          },
        },
      },
    });
  }, []);

  return (
    <div className="card-body p-3" ref={myref}>
      <Row>
        <Col className="d-flex align-items-center" sm={6}>
          {" "}
          <div className="d-flex align-items-center">
            <H3 className=" me-2">Customer journey</H3>{" "}
            <CustomToolTip size="sm" text="Transactions divided by age" />
          </div>
        </Col>
        <Col sm={6} className="ps-lg-0">
          <Row>
            <Col
              sm={10}
              className="d-flex align-items-center justify-content-end"
            >
              <span className="light-font-text me-2">Compare with data</span>
              <Toggle id={1} />{" "}
            </Col>
            <Col className="d-flex justify-content-end" sm={2}>
              <Menus
                itemheight={40}
                itemwidth={40}
                itemcontainerheight={200}
                itemcontainerwidth={200}
                component={
                  <div className="fs-14">
                    <div className="addpropContent px-3 py-2 ">
                      <span className="ms-1" onClick={() => PNGFunction()}>
                        Download widget as PNG
                      </span>
                    </div>
                    <hr className="addpropertyHr m-0"></hr>
                    <div
                      onClick={() => JPGFunction()}
                      className="addpropContent px-3 pt-2 pb-2 "
                    >
                      <span className="ms-1">Download widget as JPG</span>
                    </div>
                    <hr className="addpropertyHr m-0"></hr>
                    <div
                      onClick={() => SVGFunction()}
                      className="addpropContent px-3 pt-2 pb-2 "
                    >
                      <span className="ms-1">Download widget as SVG</span>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {chartData.length == 0 ? (
        <Row>
          <Col className="d-flex justify-content-center">
            <div
              style={{
                height: "300px",
                width: "100%",
                padding: "10px 25px",
                height: "300px",
                width: "100%",
                padding: "10px 25px",
              }}
            >
              <Bar
                data={chartData}
                plugins={[ChartDataLabels]}
                options={chartOptions}
                height="80px"
              />
            </div>
          </Col>
        </Row>
      ) : (
        <NoDataScreen height={"377px"} imgHeight={"150px"} />
      )}
    </div>
  );
};

export default CustomerJourney;
