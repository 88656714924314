import { Endpoint, Site } from "./API/Endpoints";
import { Delete, Get, Post, Put } from "./API/Method";

//Email templates - Get email template by id
export const GetEmailTemplateById = (type, id) => {
	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.EmailTemplates_GetEmailTemplatesById,
			null,
			type + "/" + id
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Email templates - Get email template by template id
export const GetEmailTemplateByTemplateId = (email_template_id) => {
	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.EmailTemplates_GetEmailTemplateByTemplateId,
			null,
			email_template_id
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};
//Email templates - Get email template category
export const GetEmailTemplateCategory = () => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.EmailTemplates_GetEmailTemplateCategory, null)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};
//Email templates - Get email template branding
export const GetEmailTemplateBranding = (organization_id, language_id) => {
	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.EmailTemplates_GetEmailTemplateBranding,
			null,
			organization_id + "/branding/language/" + language_id
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};
//Email templates - Get language by organization id
export const GetLanguageByOrganization = (organization_id) => {
	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.EmailTemplates_GetLanguageByOrganization,
			null,
			organization_id + "/language"
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Email templates - Get language by organization id
export const GetEmailTemplateMergeField = (email_template_subcategory_id) => {
	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.EmailTemplates_GetEmailTemplateMergeField,
			null,
			email_template_subcategory_id + "/fields"
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Email templates - Add new template
export const PostAddNewEmailTemplate = (addNewEmailTemplatePayload) => {
	return new Promise((resolve, reject) => {
		Post(
			Site.ET,
			Endpoint.ET.EmailTemplates_AddNewEmailTemplate,
			null,
			addNewEmailTemplatePayload
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Email templates - Update email template
export const PutUpdateEmailTemplate = (updateEmailTemplatePayload) => {
	return new Promise((resolve, reject) => {
		Put(
			Site.ET,
			Endpoint.ET.EmailTemplates_UpdateEmailTemplate,
			null,
			updateEmailTemplatePayload
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//Email templates - Delete email template
export const DeleteEmailTemplate = (email_template_id) => {
	return new Promise((resolve, reject) => {
		Delete(
			Site.ET,
			Endpoint.ET.EmailTemplates_DeleteEmailTemplate,
			null,
			email_template_id
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

// Email Templates Get Email Template List
export const GetEmailTemplateList = (
	level,
	propertyId,
	categoryId,
	subcategoryId,
	type
) => {
	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.EmailTemplates_GetEmailTemplateList,
			null,
			level +
				"/" +
				propertyId +
				"/category/" +
				categoryId +
				"/subcategory/" +
				subcategoryId +
				"/type/" +
				type
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};
