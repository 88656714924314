import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ETButton from "../../../Components/Buttons/ETButton";
import Trophy from "../../../assets/icons/svg_icons/trophywinner.svg";
import { GreenSpan } from "../../../styles/Common.style";

const AddPropertyCreated = ({ formSubmit }) => {
  const navigate = useNavigate();

  const ReturnProperties = () => {
    navigate("/properties");
  };

  return (
    <div
      fluid
      className="px-0"
      style={{
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #F4F5F5",
      }}
    >
      <Row style={{ textAlign: "center" }} className="px-2">
        <Col md={{ span: 8, offset: 2 }}>
          <h1
            className="pt-5 "
            style={{ fontFamily: "Cerebri sans-medium", fontSize: "32px" }}
          >
            {" "}
            Lorem ipsum dolor sit amet
          </h1>

          <div style={{ fontSize: "14px" }}>
            <p>
              Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat.
            </p>
          </div>

          {/* ------------------data showing after creating Property---------------------------- */}

          <div className="mt-3 mx-4 px-lg-4">
            <div
              className="py-1"
              style={{
                backgroundColor: "#F9FAFA",
                textAlign: "center",
                borderRadius: "8px",
              }}
            >
              <GreenSpan
                className=" pt-3"
                style={{ color: "#619130", fontSize: "20px" }}
              >
                Blomsterbjerget
              </GreenSpan>
              <p style={{ color: "#888888", fontSize: "12px" }}>
                Denmark | Blomstervangen 14, 2765 Smørum | Leasing | 2019
              </p>
            </div>
          </div>

          {/* //........................Image Part ......................................// */}

          <div style={{ alignItems: "center" }}>
            <Image src={Trophy} responsive fluid />
          </div>
        </Col>
      </Row>
      <div
        className="py-3 px-3 d-sm-flex justify-content-between "
        style={{ backgroundColor: "#F9FAFA", borderRadius: " 0px 0px 8px 8px" }}
      >
        <div className="m-1">
          <ETButton
            variant={"white"}
            buttonText="RETURN TO PROPERTIES"
            onClick={ReturnProperties}
          />
        </div>

        <div className="m-1">
          <ETButton variant={"primary"} buttonText="EDIT PROPERTY" />
        </div>
      </div>
    </div>
  );
};

export default AddPropertyCreated;
