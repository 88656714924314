import React, { useState } from "react";
import Select, { components } from "react-select";

const MultiColorSelect = ({
  placeholder,
  onChange,
  options,
  value,
  defaultValue,
  searchable,
  bgColor,
  color,
  height,
}) => {
  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.key !== "placeholder" ? child : null
        )}
      </ValueContainer>
    );
  };
  const colourStyles = {
    container: (provided, state) => {
      let choosenFieldCorrect = state.options.some(
        (val, i) => val?.value === state?.getValue()[0]?.value && val?.match
      );
      return {
        ...provided,
        borderRadius: "8px",
        fontSize: 14,
        fontWeight: 300,
        color: color ? color : " #000000",
        backgroundColor: bgColor ? bgColor : " #FFFFFF",
      };
    },
    valueContainer: (provided, state) => {
      let choosenFieldCorrect = state.options.some(
        (val, i) => val?.value === state?.getValue()[0]?.value && val?.match
      );
      return {
        ...provided,
        overflow: "visible",
        fontSize: 14,
        fontWeight: 300,
        borderRadius: "8px",
        backgroundColor: bgColor ? bgColor : " #FFFFFF",
        color: color ? color : "#000000",
      };
    },
    placeholder: (base, state) => ({
      ...base,
      position: "absolute",
      color: color ? color : " #000000",
      backgroundColor:
        state.hasValue ||
        state.selectProps.inputValue ||
        state.selectProps.isFocused
          ? "#fff"
          : state.hasValue
          ? color
          : " #FEF3F1",
      background:
        state.hasValue ||
        state.selectProps.inputValue ||
        state.selectProps.isFocused
          ? "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(247,247,247,0) 40%)"
          : "#FEF3F1",
      top:
        state.hasValue ||
        state.selectProps.inputValue ||
        state.selectProps.isFocused
          ? "-60%"
          : "0%",
      transition: "top 0.2s, font-size 0.2s",
      fontSize: state.selectProps.isFocused || state.hasValue ? 10 : 14,
      fontWeight: 300,
      // padding:0,
      // (state.hasValue ||
      //   state.selectProps.inputValue ||
      //   state.selectProps.isFocused) &&
      // 14,
    }),
    control: (baseStyles, state) => {
      let choosenFieldCorrect = state.options.some(
        (val, i) => val?.value === state?.getValue()[0]?.value && val?.match
      );

      return {
        ...baseStyles,
        boxShadow: state.isFocused ? 0 : 0,
        fontSize: 14,
        fontWeight: 300,
        borderColor: "transparent",
        borderRadius: "8px",
        backgroundColor: bgColor ? bgColor : " #FFFFFF",
        "&:hover": {
          border: "none",
          boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
        },
        pointerEvents: state.isDisabled && "auto",
        cursor: state.isDisabled ? "not-allowed " : "default",
        opacity: state.isDisabled ? "0.5" : "1",
      };
    },
    option: (base, { isDisabled }) => {
      return {
        ...base,
        // borderColor:  data.match ? "#77C7A1 !important" : "#F1664B !important",
        backgroundColor: " #FFFFFF",
        // backgroundColor:  data.match ?"#F4FBF7":" #FEF3F1",
        zIndex: 1,
        fontSize: 14,
        fontWeight: 300,
        color: " #000000",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        pointerEvents: isDisabled && "auto",
        cursor: isDisabled ? "not-allowed " : "default",
        // cursor: isDisabled ? "not-allowed" : "pointer",
      };
    },
    dropdownIndicator: (base) => ({
      ...base,
      paddingRight: "8px",
      color: "#000", // Custom colour
    }),
    menu: (base) => ({
      ...base,
      zIndex: 3,
      fontSize: 14,
      fontWeight: 300,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 10000 }),
  };
  const [focused, setFocused] = useState(false);
  return (
    <Select
      menuPortalTarget={document.body}
      styles={colourStyles}
      placeholder={placeholder}
      isSearchable={searchable}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
      menuPosition={"fixed"}
      menuPlacement={"auto"}
      components={{
        ValueContainer: CustomValueContainer,
      }}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      isFocused={focused}
      theme={(theme) => ({
        ...theme,
        // backgroundColor: "#F1664B",
        borderRadius: 8,
        spacing: {
          ...theme.spacing,
          baseUnit: 2,
          controlHeight: height ? height : 40,
          menuGutter: 8,
        },
      })}
    />
  );
};

export default MultiColorSelect;
