import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import ETButton from "../../../../Components/Buttons/ETButton";
import {
  useGetContractStatusList,
  useGetleasedPopupDetails,
} from "../../../../hooks/queries/useActorQueries";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { PutUpdateLeasedDetails } from "../../../../services/Actors";
import { toast } from "react-toastify";

const LeasedDetails = ({
  setLeasedPopup,
  contractId,
  contractOverViewDataRefetch,
}) => {
  //----------------React query-------------//
  const { data: getContractLeasedPopupDetailsData } =
    useGetleasedPopupDetails(contractId);
  const { data: getContractStatusListData } = useGetContractStatusList();

  //----------------Validation---------------//
  const {
    register,
    handleSubmit,
    clearErrors,
    getValues,
    formState: { errors },
    setValue,
    control,
    reset,
  } = useForm();

  useEffect(() => {
    setValue("currentStatus", {
      value: getContractLeasedPopupDetailsData?.data?.[0]?.current_status,
      label: getContractStatusListData?.data?.filter(
        (el) =>
          el.Id == getContractLeasedPopupDetailsData?.data?.[0]?.current_status
      )?.[0]?.Text,
    });
    setValue(
      "dateOfRental",
      getContractLeasedPopupDetailsData?.data?.[0]?.date_of_rental !== ""
        ? moment(
          getContractLeasedPopupDetailsData?.data?.[0]?.date_of_rental
        ).format("YYYY-MM-DD")
        : ""
    );
    setValue(
      "movingInDate",
      getContractLeasedPopupDetailsData?.data?.[0]?.moving_in_date !== ""
        ? moment(
          getContractLeasedPopupDetailsData?.data?.[0]?.moving_in_date
        ).format("YYYY-MM-DD")
        : ""
    );
    return () => { };
  }, [getContractLeasedPopupDetailsData,getContractStatusListData]);

  const handleConfirmLeasedDetail = (data) => {
    const updateLeasedDetailPayload = {
      contract_id: String(contractId),
      status_type_id: String(data?.currentStatus?.value),
      date_of_rental: data?.dateOfRental,
      moving_in_date: data?.movingInDate,
    };

    PutUpdateLeasedDetails(updateLeasedDetailPayload)
      .then((response) => {
        if (response?.data?.bReturn) {
          toast.success(response.data.message);
          setLeasedPopup(false);
          contractOverViewDataRefetch();
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(handleConfirmLeasedDetail)}>
        <div
          className="px-3 py-4"
          style={{ borderBottom: "1px Solid #F4F5F5" }}
        >
          <div className="Reservation-data px-3 py-2 d-flex justify-content-between">
            <div
              className="fs-14"
            >
              Signing order is signed and completed
            </div>
            <div
              className="light-font-text"
            >
              {getContractLeasedPopupDetailsData?.data?.[0]
                ?.order_completed_on !== ""
                ? moment(
                  getContractLeasedPopupDetailsData?.data?.[0]
                    ?.order_completed_on
                ).format("DD MMM, YYYY [at] HH:mm:ss")
                : "-"}
            </div>
          </div>
          <div className="Reservation-data mt-2 px-3 py-2 d-flex justify-content-between">
            <div
              className="fs-14"
            >
              Deadline for reservation
            </div>
            <div
              className="light-font-text"
            >
              {getContractLeasedPopupDetailsData?.data?.[0]
                ?.reservation_deadline !== ""
                ? moment(
                  getContractLeasedPopupDetailsData?.data?.[0]
                    ?.reservation_deadline
                ).format("DD MMM, YYYY [at] HH:mm:ss")
                : "-"}
            </div>
          </div>
        </div>
        {/* -----------------------DropDown And Datepickers----------------- */}
        <div className="px-3 py-4">
          <div className="font-semibold fs-14"
          >
            Confirm the rental
          </div>
          <Row className="mt-2">
            <Col lg={3} className="mt-md-3">
              <Controller
                control={control}
                {...register("currentStatus")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    placeholder={"Current Status"}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("currentStatus");
                    }}
                    options={getContractStatusListData?.data?.map(
                      (item, index) => ({
                        value: item?.Id,
                        label: item?.Text,
                      })
                    )}
                    clearable={false}
                  />
                )}
              />
              {errors.currentStatus && (
                <div className="error-msg">
                  This field is required
                </div>
              )}
            </Col>
            <Col lg={3} className="my-md-3">
              <Controller
                control={control}
                {...register("dateOfRental")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <div className="form-input-group ">
                    <input
                      type="date"
                      id="Dateofrental"
                      placeholder="Dateofrental"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("dateOfRental");
                      }}
                    />
                    <label htmlFor="Dateofrental">Date of rental</label>
                  </div>
                )}
              />
              {errors.dateOfRental && (
                <div className="error-msg">
                  This field is required
                </div>
              )}
            </Col>
            <Col lg={3} className="my-md-3"></Col>
            <div
              className="font-semibold fs-14 mt-1"
            >
              Upcoming dates
            </div>
          </Row>
          <Row className="mt-2">
            <Col lg={3} className="mt-md-3">
              <Controller
                control={control}
                {...register("movingInDate")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <div className="form-input-group ">
                    <input
                      type="date"
                      id="Movingindate"
                      placeholder="Movingindate"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("movingInDate");
                      }}
                    />
                    <label htmlFor="Movingindate">Moving in date</label>
                  </div>
                )}
              />
              {errors.movingInDate && (
                <div className="error-msg">
                  This field is required
                </div>
              )}
            </Col>
            <Col lg={3} className="my-md-3"></Col>
            <Col lg={3} className="my-md-3"></Col>
          </Row>
        </div>
        <div
          className="py-2 mt-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            borderTop: "1px solid #F4F5F5",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            fontSize="14px"
            onClick={() => setLeasedPopup(false)}
          />
          <ETButton
            type="submit"
            variant={"primary"}
            buttonText="CONFIRM"
          />
        </div>
      </Form>
    </div>
  );
};

export default LeasedDetails;
