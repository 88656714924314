import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import TooltipComponent from "../../../../Components/Tooltip/CustomTooltip";
import Switch from "../../../../Components/Switch/Switch";
import GroupButtons from "../../../../Components/GroupButton/GroupButton";
import ETButton from "../../../../Components/Buttons/ETButton";
import CheckBox from "../../../../Components/Checkbox/Checkbox";
import DropdownModalMui from "../../../../Components/DropdownModal/DropdownModalMui";

const AutomaticMileStone = ({ setAutomaticMileStone }) => {
  const Status = [
    {
      value: "Available",
      name: "available",
      id: "10",
    },
    {
      value: "Reserved",
      name: "reserved",
      id: "50",
    },
    {
      value: "Sold",
      name: "sold",
      id: "80",
    },
    {
      value: "Leased",
      name: "leased",
      id: "90",
    },
    {
      value: "idleness",
      name: "idleness",
      id: "11",
    },
  ];

  const Comparision = [
    {
      value: "is",
      name: "is",
      id: "1",
    },
    {
      value: "over",
      name: "over",
      id: "2",
    },
    {
      value: "under",
      name: "under",
      id: "3",
    },
  ];

  const Parameter = [
    {
      value: "Units",
      name: "Units",
      id: "1",
    },
    {
      value: "Percentage",
      name: "percentage",
      id: "2",
    },
  ];

  const Colors = [
    {
      id: 1,
      value: "Pink",
      color: "#B23977",
    },
    {
      id: 2,
      value: "Black",
      color: "#000000",
    },
    {
      id: 3,
      value: "Blue",
      color: "#A2C8D0",
    },
    {
      id: 4,
      value: "Green",
      color: "#AAC992",
    },
  ];

  const shareWith = [
    {
      id: "1",
      value: "Max Stanson",
      check: <CheckBox />,
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      milestoneName: "",
      selectedProp: "",
      targetDate: "",
      statusValue: "",
      is: "",
      value: "",
      parameter: "",
      color: "",
      visibility: "",
      useAs: "",
      sharedWith: "",
    },
  });

  const Colorss = [
    "#A9A9A9",
    "#228B22",
    "#FFD700",
    "#4169E1",
    "#FF0000",
    "#9370DB",
    "#4169E1",
  ];

  const displayItem = (selected) => {

    return {
      value: selected.value,
      label: (
        <div>
          <div className="fs-14 fw-300">{selected.label}</div>
        </div>
      ),
    };
  };

  const onHandleMileStone = (data) => {
    console.log("object", data);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onHandleMileStone)}>
        <div className="scrollable-container-y" style={{ height: "500px" }}>
          <div className="p-4">
            <Row className="pb-4">
              <Col xs={12} lg={3}>
                <div className="form-label-title"> Name of milestone</div>
              </Col>
              <Col xs={12} lg={3}>
                <Controller
                  control={control}
                  {...register("milestoneName")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <div className="form-input ">
                      <input
                        type="text"
                        placeholder="name"
                        id="MileStoneName"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("milestoneName");
                        }}
                      />
                      <label htmlFor="milestoneName">Name</label>
                    </div>
                  )}
                />
                {errors?.milestoneName && (
                  <p
                    className="error-msg"
                    style={{
                      color: "red",
                    }}
                  >
                    {REQUIRED_ERR}
                  </p>
                )}
              </Col>
              <Col></Col>
            </Row>
            <Row className="pb-4">
              <Col xs={12} lg={3}>
                <div className="form-label-title">Select property</div>
              </Col>
              <Col xs={12} lg={3}>
                <Controller
                  name="selectedProp"
                  {...register("selectedProp")}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder="Select Property"
                      value={value}
                      clearable={false}
                      isSearchable
                      //   onChange={(e) => {
                      //     onChange(e);
                      //     handleZip(e);
                      //   }}
                      error={errors.zip}
                      //   options={zipcodeData?.data?.map((zip) => ({
                      //     value: zip?.Id,
                      //     label: `${zip?.Id} ${zip?.Text}`,
                      //   }))}
                    />
                  )}
                />
                {errors?.selectedProp && (
                  <p
                    className="error-msg"
                    style={{
                      color: "red",
                    }}
                  >
                    {REQUIRED_ERR}
                  </p>
                )}
              </Col>
              <Col></Col>
            </Row>

            <Row className="pb-4">
              <Col xs={12} lg={3}>
                <div className="form-label-title d-flex gap-3 ">
                  Target date{" "}
                  <span>
                    <TooltipComponent text="target date" height="11px" />
                  </span>
                </div>
              </Col>
              <Col xs={12} lg={3}>
                <Controller
                  name="targetDate"
                  {...register("targetDate")}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <div className="form-input-group ">
                      <input
                        type="date"
                        id="targetDate"
                        placeholder="target"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("targetDate");
                        }}
                      />
                      <label htmlFor="targetDate">Target Date</label>
                    </div>
                  )}
                />
                {errors?.targetDate && (
                  <p
                    className="error-msg"
                    style={{
                      color: "red",
                    }}
                  >
                    {REQUIRED_ERR}
                  </p>
                )}
              </Col>
              <Col className="mt-2">
                <div className="light-font-text d-flex align-items-center gap-3">
                  <div>Ongoing target</div>
                  <div>
                    <Switch id={"1"} />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="pb-4">
              <Col xs={12} lg={3}>
                <div className="form-label-title">
                  Status, value and parameter
                </div>
              </Col>
              <Col xs={12} lg={3} className="mb-2">
                <Controller
                  name="statusValue"
                  {...register("statusValue")}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder="Status"
                      value={value}
                      clearable={false}
                      isSearchable
                      onChange={(e) => {
                        onChange(e);
                      }}
                      error={errors.statusValue}
                      options={Status?.map((stat) => ({
                        value: stat?.id,
                        label: `${stat?.value}`,
                      }))}
                    />
                  )}
                />
                {errors?.statusValue && (
                  <p
                    className="error-msg"
                    style={{
                      color: "red",
                    }}
                  >
                    {REQUIRED_ERR}
                  </p>
                )}
              </Col>

              <Col xs={12} lg={2} className="mb-2">
                <Controller
                  name="is"
                  {...register("is")}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      value={value}
                      clearable={false}
                      isSearchable
                      onChange={(e) => {
                        onChange(e);
                      }}
                      error={errors.is}
                      options={Comparision?.map((zip) => ({
                        value: zip?.id,
                        label: `${zip?.value}`,
                      }))}
                    />
                  )}
                />
                {errors?.is && (
                  <p
                    className="error-msg"
                    style={{
                      color: "red",
                    }}
                  >
                    {REQUIRED_ERR}
                  </p>
                )}
              </Col>
              <Col xs={12} lg={1} className="mb-2">
                <Controller
                  control={control}
                  {...register("value")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <div className="form-input ">
                      <input
                        type="text"
                        placeholder="value"
                        id="value"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("value");
                        }}
                      />
                      <label htmlFor="is">value</label>
                    </div>
                  )}
                />
                {errors?.value && (
                  <p
                    className="error-msg"
                    style={{
                      color: "red",
                    }}
                  >
                    {REQUIRED_ERR}
                  </p>
                )}
              </Col>
              <Col xs={12} lg={3} className="mb-2">
                <Controller
                  name="Parameter"
                  {...register("parameter")}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder="parameter"
                      value={value}
                      clearable={false}
                      isSearchable
                      onChange={(e) => {
                        onChange(e);
                      }}
                      error={errors.parameter}
                      options={Parameter?.map((zip) => ({
                        value: zip?.Id,
                        label: `${zip?.value}`,
                      }))}
                    />
                  )}
                />
                {errors?.parameter && (
                  <p
                    className="error-msg"
                    style={{
                      color: "red",
                    }}
                  >
                    {REQUIRED_ERR}
                  </p>
                )}
              </Col>
            </Row>

            <Row className="pb-4">
              <Col xs={12} lg={3}>
                <div className="form-label-title">Color</div>
              </Col>
              <Col xs={12} lg={3}>
                <Controller
                  name="color"
                  {...register("color")}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder="Color"
                      value={
                        value === "" ? "" : displayItem(value)
                      }
                      onChange={(e) => {
                        onChange(e);
                      }}
                      clearable={false}
                      isSearchable
                      error={errors.color}
                      options={Colors?.map((zip) => ({
                        value: zip?.id,
                        label: (
                          <div
                            className="d-flex align-items-center"
                            style={{ fontFamily: "Cerebri sans" }}
                          >
                            <div
                              className="radius-4 me-2"
                              style={{
                                backgroundColor: zip.color,
                                width: "16px",
                                height: "16px",
                              }}
                            ></div>
                            <div>
                              <div className="fs-14 light-font-text ">
                                {" "}
                                {zip?.value}
                              </div>
                            </div>
                          </div>
                        ),
                      }))}
                    />
                  )}
                />
                {errors?.color && (
                  <p
                    className="error-msg"
                    style={{
                      color: "red",
                    }}
                  >
                    {REQUIRED_ERR}
                  </p>
                )}
              </Col>
              <Col></Col>
            </Row>

            <Row className="pb-4">
              <Col xs={12} lg={3}>
                <div className="form-label-title d-flex gap-3 ">
                  Visibility{" "}
                  <span>
                    <TooltipComponent text="Visibility" height="11px" />
                  </span>
                </div>
              </Col>
              <Col xs={12} lg={3}>
                <Controller
                  control={control}
                  {...register("visibility")}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <GroupButtons
                        tabs={[
                          {
                            id: 1,
                            tabTitle: (
                              <div className="p-0 light-font-text">Private</div>
                            ),
                            title: "Private",
                          },
                          {
                            id: 2,
                            tabTitle: (
                              <div className="p-0 light-font-text">Public</div>
                            ),
                            title: "Public",
                          },
                          {
                            id: 3,
                            tabTitle: (
                              <div className="p-0 light-font-text">Shared</div>
                            ),
                            title: "Shared",
                          },
                        ]}
                        currentTab={value}
                        handleTabClick={(tab) => {
                          onChange(tab);
                          setValue("visibility", tab);
                        }}
                      />
                    );
                  }}
                />
                {errors?.visibility && (
                  <p
                    className="error-msg"
                    style={{
                      color: "red",
                    }}
                  >
                    {REQUIRED_ERR}
                  </p>
                )}
              </Col>
              <Col lg={5} md={12} sm={12}>
                <div className="d-flex align-items-center gap-2">
                  <div className="light-font-text">Shared it with</div>
                  <div className="w-25">
                    <Controller
                      name="sharedWith"
                      {...register("sharedWith")}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <DropdownModalMui
                          text="User(0)"
                          component={
                            <div className="py-2 ps-2 pe-5">
                              <div className="d-flex align-items-center light-font-text fs-14 ">
                                <CheckBox
                                // onChange={handleCheckAllCategory}
                                // checked={
                                //   allCategoryChecked.length === allCategory.length
                                // }
                                />{" "}
                                Share with
                              </div>
                            </div>
                          }
                        />
                        // <ReactSelect
                        //   value={value}
                        //   clearable={false}
                        //   isSearchable
                        //   onChange={(e) => {
                        //     onChange(e);
                        //   }}
                        //   error={errors.zip}
                        //   options={shareWith?.map((zip) => ({
                        //     value: zip?.id,
                        //     label: (
                        //       <div className="d-flex align-items-center light-font-text">
                        //         <div
                        //           className="radius-4 m-2"

                        //         ><CheckBox/></div>
                        //         <div>
                        //           <div className="fs-14 fw-400 light-font-text">
                        //             {" "}
                        //             {zip?.value}
                        //           </div>
                        //         </div>
                        //       </div>
                        //     )
                        //   }))}
                        // />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="pb-4">
              <Col xs={12} lg={3}>
                <div className="fs-14 form-label-title d-flex gap-3 ">
                  Use as{" "}
                  <span>
                    <TooltipComponent text="Use as" height="11px" />
                  </span>
                </div>
              </Col>
              <Col xs={12} lg={3}>
                <Controller
                  control={control}
                  {...register("useAs")}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <GroupButtons
                        tabs={[
                          {
                            id: 1,
                            tabTitle: (
                              <div className="nowrap light-font-text">
                                Map Milestone
                              </div>
                            ),
                            title: "Map Milestone",
                          },
                          {
                            id: 2,
                            tabTitle: (
                              <div className="nowrap light-font-text">
                                Chart Milestone
                              </div>
                            ),
                            title: "Chart Milestone",
                          },
                        ]}
                        currentTab={value}
                        handleTabClick={(tab) => {
                          setValue("useAs", tab);
                        }}
                      />
                    );
                  }}
                />
              </Col>
              <Col></Col>
            </Row>
          </div>
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            fontSize="14px"
            onClick={() => setAutomaticMileStone(false)}
          />
          <div className="d-flex gap-3">
            <ETButton
              variant={"white"}
              buttonText="RESET SETTING"
              fontSize="14px"
              onClick={{}}
            />
            <ETButton
              variant={"primary"}
              buttonText="SAVE"
              fontSize="14px"
              type="submit"
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AutomaticMileStone;
