import * as React from "react";
import facilitiesicon from "../../../../../../assets/images/dot.jpg";
import { Col, Image, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../../media/Media.css";
import moment from "moment";

const FacilityDummyData = [
  {
    icon: <img alt="" src={facilitiesicon} height={45} width={45} />,

    Facility_name: "Washing machine",
    Source: "#2-bedroom",
    Category: "#2-bedroom",
    Group: "Images for showing apartment for 2-bedrooms",
    Quantity: "10",
    Producent: "Not defined",
    Model_no: "XYZ",
    Details: "Details",
  },
];

export default function MediaCard({ dragOnIcon, item }) {
  return (
    <>
      <div
        className="Edit-image-container radius-8 d-block"
        style={{ border: "1px solid #F0F0F0" }}
      >
        {/* {FacilityDummyData.map((data, i) => ( */}
        <div
          style={{
            position: "relative",
            backgroundColor: "#f9fafa",
            boxShadow: "none",
            overflow: "inherit",
            height: "fit-content",
            borderRadius: "8px",
            // width: '100%'
          }}
        >
          <div {...dragOnIcon} className="Edit-dragIcon">
            {/* <FontAwesomeIcon size="xs" icon={faArrowsUpDownLeftRight} /> */}
            <FontAwesomeIcon
              icon="fa-regular fa-arrows-up-down-left-right"
              size="sm"
              style={{ color: "#000000" }}
            />
          </div>
          <div style={{ height: "144px" }}>
            <Image
              fluid
              alt="myPropertyImage"
              className=" image-box "
              style={{ width: "100%", height: "100%" }}
              src={item.media_files}
            />
          </div>
          <div className="Edit-card-bottom">
            <div className="d-flex align-items-center">
              <span style={{ fontSize: "12px", width: "80px" }}>SEO-score</span>
              {item?.seo_score_text && (
                <div
                  style={{
                    background:
                      item?.seo_score_text === "Bad"
                        ? "#F1664B"
                        : item?.seo_score_text == "Average"
                        ? "#F6E651"
                        : "#77C7A1",
                    display: "flex",
                    borderRadius: "3px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "9px",
                      textTransform: "uppercase",
                      padding: "1px 5px",
                    }}
                  >
                    {item?.seo_score_text}
                  </span>
                </div>
              )}
            </div>
            <div className="d-flex align-items-center">
              <span style={{ fontSize: "12px", width: "80px" }}>
                Image quality
              </span>
              {item?.media_quality_text && (
                <div
                  style={{
                    background:
                      item?.media_quality_text == "Bad"
                        ? "#F1664B"
                        : item?.media_quality_text == "Average"
                        ? "#F6E651"
                        : "#77C7A1",
                    display: "flex",
                    borderRadius: "3px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "9px",
                      textTransform: "uppercase",
                      padding: "1px 5px",
                    }}
                  >
                    {item?.media_quality_text}
                  </span>
                </div>
              )}
            </div>
          </div>

          <hr className="hr mx-0 mb-0 mt-1" />
          <div>
            <div>
              <Row className="px-4 pb-1 pt-3">
                <Col className="px-lg-0">
                  <div>
                    <div className="blackColor form-label-title fs-12">Tag</div>
                    <div className="light-font-text fs-12 blackColor">
                      {item?.source?.split(",").join(", ")}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="px-4 pt-2">
                <Col className="px-lg-0">
                  <div>
                    <div className="blackColor form-label-title fs-12">
                      Media package
                    </div>
                    <div className="light-font-text fs-12 blackColor">
                      {item?.media_package_name}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className=" align-items-baseline  px-2 ms-1 py-3">
            <div className="blackColor form-label-title fs-12">
              Created by{" "}
              {item?.created_by && item?.created_on ? (
                <div className="created-by fs-12 ">
                  {item?.created_by},{" "}
                  {moment(item?.created_on).format("DD. MMM. YYYY | HH:mm:ss")}
                </div>
              ) : (
                "-"
              )}
            </div>
            <div className="blackColor form-label-title fs-12">
              Last updated by
              {item.modified_by && item.modified_on ? (
                <div className="created-by fs-12 ">
                  {item.modified_by},{" "}
                  {moment(item?.modified_on).format("DD. MMM. YYYY | HH:mm:ss")}
                </div>
              ) : (
                "-"
              )}
            </div>
            <div></div>
          </div>
        </div>
        {/* ))} */}
      </div>
    </>
  );
}
