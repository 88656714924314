import React from "react";
import CustomPopup from "../../../../Components/CustomPopup";
import ETButton from "../../../../Components/Buttons/ETButton";

const PublishForm = ({ publish, setPublish, handleStatusChange }) => {
	return (
		<div>
			{" "}
			<CustomPopup
				open={publish}
				onHide={() => setPublish(false)}
				title={<span className="">Ready to publish? </span>}
				title_color="black"
				enableCrossButton={false}
				action_button_label="YES"
				action_button_label_color="white"
				action_button_color="#619130"
				cancel_button={true}
				cancel_button_bordercolor="grey"
				cancel_button_label="NO"
				cancel_button_label_color="black"
				cancel_button_color="#fff"
				footer_color={"#F9FAFA"}
				deletePopUpStyle={true}
				titleTooltip={true}
			>
				<div className="p-4 fs-14">
					Make sure that the screening form is ready to be used by your
					customers
				</div>

				<div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
					<ETButton
						variant={"white"}
						buttonText="Cancel"
						onClick={() => {
							setPublish(false);
						}}
					/>
					<ETButton
						variant={"primary"}
						buttonText="PUBLISH"
						onClick={handleStatusChange}
					/>
				</div>
			</CustomPopup>
		</div>
	);
};

export default PublishForm;
