import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

//for Organization id flag
export const useOrganizationId = create(
  persist(
    (set, get) => ({
      organizationId: "",
      setOrganizationId: (data) => set({ organizationId: data }),
    }),
    {
      name: "organizationId", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

//For organization overview
export const useOrganizationOverview = create((set, get) => ({
	organizationOverview: [],
	setOrganizationOverview: (data) => set({ organizationOverview: data }),
}));

//used for Organization breadcrumbs of setting
export const useOrganizationSettingsBreadcrumbsTabs = create((set) => ({
  organizationBreadcrumbsTabs: "",
  setOrganizationBreadcrumbsTabs: (data) =>
    set({ organizationBreadcrumbsTabs: data }),
}));
