import React, { useEffect, useMemo, useState } from "react";
import CustomToolTip from "../../../../../../../../../Components/Tooltip/CustomTooltip";
import { Col, Row } from "react-bootstrap";
import DateRangeFilter from "../../../../../../../../../Components/DateRangePicker/DateRangeFilter";
import ReactSelect from "../../../../../../../../../Components/DropDown/ReactSelect";
import TagToolTip from "../../../../../../../../../Components/Tooltip/PropertyTagToolTip";
import MRTTheme from "../../../../../../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";
import Menus from "../../../../../../../../../Components/Menus/Menus";
import {
	SortIcon,
	TableRowsStyle,
	paginationoptions,
} from "../../../../../../../../../Components/table/MRTGlobalProps";
import { ScreeningsTableData } from "../../requests/page/data";
import {
	CircleMsg,
	H3,
	NormalNotification,
} from "../../../../../../../../../styles/Common.style";

import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularLoader from "../../../../../../../../../Components/CircularLoader/CircularLoader";
import CustomPopup from "../../../../../../../../../Components/CustomPopup";
import MoveAnotherUnit from "../../requests/commonPage/MoveAnotherUnit";
import { NoRecordsToDisplay } from "../../../../../../../../../utils/validationConstants";
import { useBreadcrumb } from "../../../../../../../../../Components/BreadcrumbContext ";

const ReservationAndScreening = () => {
	const [moveToAnotherUnitsShow, setMoveToAnotherUnitsShow] = useState(false);
	const { updateBreadcrumbs } = useBreadcrumb();
	useEffect(() => {
		updateBreadcrumbs([
			"Overview",
			"In Process",
			"Reservations and screenings",
		]);
	}, []);
	const columns = useMemo(
		() => [
			{
				accessorFn: (row) => `${row.name}  `,
				header: "Name",
				enableHiding: false,
				size: 250,
				enableGlobalFilter: true,
				Cell: ({ row }) => (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "8px",
							cursor: "pointer",
						}}
					>
						<div>
							<TagToolTip text="Need Help About Tags" />
						</div>
						<div>{row.original.name}</div>
						<div>
							{row.original.responsible_agent_count > 1 && (
								<NormalNotification>
									<span> +{row.original.subRows?.length} </span>
								</NormalNotification>
							)}
						</div>
					</div>
				),
			},
			{
				accessorKey: "attended_viewing",
				header: "Attended a viewing",
				enableHiding: false,
				// size: 100,
				enableGlobalFilter: true,
			},
			{
				accessorKey: "reservation_created",
				header: "Reservation created",
				enableHiding: false,
				// size: 20,
				enableGlobalFilter: false,
			},
			{
				accessorKey: "Screening_started",
				header: "Screening started",
				enableHiding: false,
				// size: 180,
				enableGlobalFilter: false,
			},
			{
				accessorKey: "Screening_deadline",
				header: "Screening deadline",
				enableHiding: false,
				// size: 20,
				enableGlobalFilter: false,
			},
			{
				accessorFn: (row) => `${row.name}  `,
				header: "Screening status",
				enableHiding: false,
				size: 80,
				enableGlobalFilter: false,
				Cell: ({ row }) => (
					<div className="px-2 radius-4 d-flex gap-2 fs-12">
						<div>
							<CircularLoader value={76} />
							<div>76%</div>
						</div>
					</div>
				),
			},
			{
				accessorKey: "responsible_agent",
				header: "Responsible agent",
				enableHiding: false,
				size: 200,
				enableGlobalFilter: false,
			},
		],
		[]
	);
	return (
		<div>
			<div className="d-flex w-100">
				<div className="p-4 mt-2 w-75">
					<div className="d-flex align-items-center">
						<span className="form-label-title ">
							<H3> Reservations and screenings</H3>{" "}
						</span>
						<CircleMsg>
							<span>0</span>
						</CircleMsg>
					</div>

					<div className="light-font-text">
						Below is a list of the current reservations and screenings on this
						unit. It is possible to start and stop a screening on a customer as
						well as move the customer to another unit
					</div>
				</div>
			</div>

			<div className="px-4  pb-5">
				<MRTTheme
					colorVariantsForTableRow={["gray"]}
					colorVariantsForTableHead={["white"]}
					table={
						<MaterialReactTable
							columns={columns}
							// data={ScreeningsTableData} //data is undefined on first render
							data={[]}
							icons={{ ...SortIcon }}
							localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
							enableColumnActions={false}
							enableRowSelection={false}
							enableRowActions
							enableSelectAll
							enableExpanding
							enableExpandAll
							enableTopToolbar={true}
							enableGlobalFilter
							globalFilterFn="contains"
							initialState={{
								showGlobalFilter: true,
								showColumnFilters: false,
								expanded: true,
							}}
							renderTopToolbar={({ table }) => (
								<>
									<div className="px-0 ">
										<Row>
											<Col xl={4} lg={6} className="pb-2">
												<DateRangeFilter
												// date={dateRange}
												// setDate={setDateRange}
												// handleDateChange={handleDateChange}
												/>
											</Col>
											<Col xl={3} lg={5} className="ps-lg-0 pb-2">
												<ReactSelect placeholder={"Responsible agent"} />
											</Col>
										</Row>
									</div>
								</>
							)}
							muiTableContainerProps={{
								sx: {
									padding: "0px !important",
								},
							}}
							displayColumnDefOptions={{
								"mrt-row-actions": {
									header: "",
								},
							}}
							positionActionsColumn="last"
							renderRowActions={({ row }) => (
								<Menus
									itemheight={23}
									itemwidth={23}
									iconborderradius="4px"
									itemcontainerwidth="200px"
									component={
										<div className="light-font-text">
											<div className="p-2 cursor-pointer">Go to screening</div>
											<hr className="hr my-1"></hr>
											<div className="p-2 cursor-pointer">
												Go to contact card
											</div>
											<hr className="hr my-1"></hr>
											<div
												className="p-2 cursor-pointer"
												onClick={() => setMoveToAnotherUnitsShow(true)}
											>
												Move to another unit
											</div>
											<hr className="hr my-1"></hr>
											<div className="p-2 cursor-pointer">Delete</div>
										</div>
									}
								/>
							)}
							muiTablePaperProps={{
								elevation: 0,
								fontFamily: "Cerebri sans-light !important",
								sx: {
									borderRadius: "0",
									border: "none",
									// backgroundColor: "#F9FAFA",
									table: {
										borderCollapse: "separate",
										borderSpacing: "0 5px",
									},
								},
							}}
							muiTablePaginationProps={{
								...paginationoptions,
							}}
							muiTableBodyRowProps={({ row }) => ({
								hover: false,
								sx: {
									...TableRowsStyle,
									backgroundColor:
										row.original?.screenings_agent_count > 1
											? "#F0F0F0 !important"
											: "#F9FAFA",
								},
							})}
						/>
					}
				/>
			</div>
			<CustomPopup
				open={moveToAnotherUnitsShow}
				onHide={() => setMoveToAnotherUnitsShow(false)}
				title="Move customer to another unit"
				titleTooltipText="Move customer to another unit"
				title_color="black"
				size={"xl"}
				enableCrossButton={false}
				action_button_label="YES"
				action_button_label_color="white"
				action_button_color="#619130"
				cancel_button={true}
				cancel_button_bordercolor="grey"
				cancel_button_label="NO"
				cancel_button_label_color="black"
				cancel_button_color="#fff"
				footer_color={"#F9FAFA"}
				deletePopUpStyle={true}
				titleTooltip={true}
			>
				<div>
					<MoveAnotherUnit
						moveToAnotherUnitsShow={moveToAnotherUnitsShow}
						setMoveToAnotherUnitsShow={setMoveToAnotherUnitsShow}
					/>
				</div>
			</CustomPopup>
		</div>
	);
};

export default ReservationAndScreening;
