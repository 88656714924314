// created by Suraj
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import MRTTheme from "../../../../Components/table/MRTTheme";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  paginationoptions,
} from "../../../../Components/table/MRTGlobalProps";
import { Col, Image, Row } from "react-bootstrap";
import Menus from "../../../../Components/Menus/Menus";
import { MenuItem } from "@mui/material";
import moment from "moment";
import {
  CircleMsg,
  H3,
  H4,
  StyledLoadingBar,
  TeamsImageContainer,
} from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import ETButton from "../../../../Components/Buttons/ETButton";
import AssignProperties from "./usersComponents/AssignProperties";
import AssignUserToTeams from "./usersComponents/AssignUserToTeams";
import CustomPopup from "../../../../Components/CustomPopup";
import CreateNewTeam from "./CreateNewTeam";
import CreateNewTeamWizard from "./usersComponents/CreateNewTeamWizard";
import AssignLeaderToTeams from "./usersComponents/AssignLeaderToTeams";
import {
  useAddTeam,
  useAssignUserToTeams,
  useGetTeamByTeamId,
  useGetTeamsByLevel,
  useGetTeamsByLevelDetails,
  useUpdateAssignPropertiesToTeams,
  useUpdateTeam,
  useUpdateTeamStatus,
} from "../../../../hooks/queries/useTeam";
import { useOrganizationId } from "../../../../hooks/stores/useOrganizationStore";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import { status } from "../../../../model/Constant";
import { useGetUserByLevel } from "../../../../hooks/queries/useUserQueries";
import { useDivisionId } from "../../../../hooks/stores/useDivisionsStore";
import {
  ErrorAlert,
  SucessAlert,
} from "../../../../Components/alerts/AlertsAndMessages";
import { useGetPropertiesByLevel } from "../../../../hooks/queries/usePropertyQueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "../../../../assets/images/avatar.png";
import { useGetDivisionByOrganisationId } from "../../../../hooks/queries/useDivisionQueries";
import LoadingBar from "react-top-loading-bar";
import { customproperties } from "../../../../styles/Theme";

const Teams = () => {
  // state
  const [createNewTeamPopup, setCreateNewTeamPopup] = useState(false);
  const [editNewTeamPopup, setEditNewTeamPopup] = useState(false);
  const [assignPropertyPopup, setAssignPropertyPopup] = useState(false);
  const [assignUserToTeamsPopup, setAssignUserToTeams] = useState(false);
  const [assignTeamleadersToTeamsPopup, setAssignTeamleadersToTeams] =
    useState(false);
  const [deactivateteamPopup, setDeactivateteamPopup] = useState(false);
  const [progress, setProgress] = useState(0);
  const [editFlag, setEditFlag] = useState(false);
  const [teamId, setTeamId] = useState("");
  const [userType, setUserType] = useState("2");
  const [assignUsersToTeamData, setAssignUsersToTeamData] = useState([]);
  const [isActivatedId, setIsActivatedId] = useState("");
  const [sorting, setSorting] = useState([]);
  const [assignedPropertyToTeam, setAssignedPropertyToTeam] = useState([]);
  const [assignPropertiesUsedFor, setAssignPropertiesUsedFor] = useState("");
  const [viewAssignedPropertiesData, setViewAssignedPropertiesData] = useState(
    []
  );
  const [stepWizard, setStepWizard] = useState("CreateNewTeam");
  const [stepOneTeamsData, setStepOneTeamData] = useState({});
  const [assignToTeamsTableData, setAssignToTeamsTableData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [members, setMembers] = useState([]);
  // const [uploadImgFile, setUploadImgFile] = useState("");
  // const [croppedImage, setCroppedImage] = useState();
  //---------------------------------------zustand-----------------------------------------//
  const organizationId = useOrganizationId((state) => state.organizationId);
  const { setDivisionId, divisionId } = useDivisionId((state) => state);

  //---------------------------------------React query--------------------------------------//
  const { data: GetTeamsByLevelData, refetch: GetTeamsByLevelRefetch } =
    useGetTeamsByLevelDetails("o", organizationId, "details");
  const {
    mutateAsync: PutUpdateAssignPropertiesToTeams,
    isLoading: UpdateAssignPropertiesToTeamsLoading,
  } = useUpdateAssignPropertiesToTeams();

  const {
    mutateAsync: PutUpdateTeamStatus,
    isLoading: UpdateTeamStatusLoading,
  } = useUpdateTeamStatus();
  const { data: GetPropertiesByLevelData } = useGetPropertiesByLevel(
    "d",
    // 10001,
    divisionId,
    "details",
    "all"
  );

  const { data: GetUserBylevelData } = useGetUserByLevel(
    "d",
    divisionId,
    "0",
    "1,2",
    "details"
  );
  const {
    mutateAsync: PostAssignUserToTeamsMutate,
    isLoading: PostAssignUserToTeamsLoading,
  } = useAssignUserToTeams();

  const { data: GetTeamByTeamIdData, refetch: GetTeamByTeamIdRefetch } =
    useGetTeamByTeamId(teamId);

  useEffect(() => {
    const usersToHide = GetTeamByTeamIdData?.data?.[0]?.members?.map(
      (member) => member?.user_id
    );
    const filteredData = GetUserBylevelData?.data?.filter(
      (item) => !usersToHide?.includes(item?.user_id)
    );
    setAssignToTeamsTableData(filteredData);
  }, [assignUserToTeamsPopup, GetUserBylevelData, GetTeamByTeamIdData]);

  useEffect(() => {
    setAssignUsersToTeamData(GetPropertiesByLevelData?.data);
  }, [GetPropertiesByLevelData]);

  useEffect(() => {
    setMembers(Object.keys(rowSelection));
  }, [rowSelection]);

  const viewAssignedPropertyData = GetPropertiesByLevelData?.data?.filter(
    (viewData) => {
      const matchingProperty = viewAssignedPropertiesData?.find(
        (getData) => getData.property_id == viewData.property_id
      );
      return matchingProperty !== undefined;
    }
  );

  //-----------------------------------handler---------------------------//
  const handleAssignPropertiesToTeam = async (property_id) => {
    const AssignPropertiesToTeamsPayload = {
      user_team_id: teamId,
      properties: Object.keys(property_id),
    };
    try {
      const result = await PutUpdateAssignPropertiesToTeams(
        AssignPropertiesToTeamsPayload
      );
      if (result.status === status.s && result.data.bReturn) {
        toast.success(<SucessAlert />);
        GetTeamsByLevelRefetch();
        setAssignPropertyPopup(false);
      } else {
        toast.error(<ErrorAlert />);
      }
    } catch (error) {
      toast.error(<ErrorAlert />);
    }
  };

  const handleuseUpdateTeamStatus = async () => {
    const UpdateTeamStatusPayload = {
      is_active: isActivatedId?.toString(),
      user_team_id: teamId?.toString(),
    };
    try {
      const result = await PutUpdateTeamStatus(UpdateTeamStatusPayload);
      if (result.status === status.s && result.data.bReturn) {
        toast.success(<SucessAlert />);
        GetTeamsByLevelRefetch();
        setDeactivateteamPopup(false);
      } else {
        toast.error(<ErrorAlert />);
      }
    } catch (error) {
      toast.error(<ErrorAlert />);
    }
  };

  const handleAssignUserToTeams = async () => {
    const assignUserToTeamPayload = {
      user_team_id: teamId,
      members,
    };
    try {
      const response = await PostAssignUserToTeamsMutate(
        assignUserToTeamPayload
      );
      if (response?.status === status?.s && response?.data?.bReturn) {
        toast.success(<SucessAlert />);
        GetTeamsByLevelRefetch();
        GetTeamByTeamIdRefetch();
        setRowSelection({});
        setAssignUserToTeams(false);
      } else {
        toast.error(<ErrorAlert />);
        setAssignUserToTeams(false);
      }
    } catch (error) {}
  };

  // const [propertyScreenHandler, setPropertyScreenHandler] = useState(false);
  // const [teamLeader, setTeamLeader] = useState([]);
  // const [uploadImgFile, setUploadImgFile] = useState("");
  // const [croppedImage, setCroppedImage] = useState();
  // const [teamProfile, setTeamProfile] = useState();
  // const [teamMember, setTeamMember] = useState([]);
  // const [UserName, setUserName] = useState("");
  // const [rowSelection, setRowSelection] = useState({});
  // const [proviousRowSelection, setPreviousRowSelection] = useState({});
  // const [teamData, setTeamData] = useState([]);
  // const [selectedTeamManager, setSelectedTeamManager] = useState([]);
  // const [previousSelectedTeamManager, setPreviousSelectedTeamManager] = useState({});
  // const [selectedTeamleader, setSelectedTeamleader] = useState([]);
  // console.log("🚀 ~ file: Teams.js:166 ~ Teams ~ selectedTeamleader:", selectedTeamleader)
  // const [previousSelectedTeamleader, setPreviousSelectedTeamleader] = useState({});
  // console.log("🚀 ~ file: Teams.js:167 ~ Teams ~ previousSelectedTeamleader:", previousSelectedTeamleader)
  // // const [assignedPropertyToTeam, setAssignedPropertyToTeam] = useState([]);
  // const [teamDataOnChange, setTeamDataOnChange] = useState({
  //   division: "",
  //   teamName: "",
  //   manager: "",
  // });
  // console.log("🚀 ~ file: Teams.js:173 ~ Teams ~ teamDataOnChange:", teamDataOnChange)

  // //====================================ZUSTAND========================//
  // // const organizationId = useOrganizationId((state) => state.organizationId);

  // //====================================REACT QUERY==========================//
  // const { data: GetTeamByTeamIdData } = useGetTeamByTeamId(teamId);
  // const EditTeamData = GetTeamByTeamIdData?.data

  // const { data: GetUserBylevelData } = useGetUserByLevel(
  //   "d",
  //   teamDataOnChange?.division,
  //   "0",
  //   "1,2",
  //   "basic"
  // );

  // const {
  //   data: GetDivisionByOrganisationId,
  // } = useGetDivisionByOrganisationId(organizationId, "basic");

  // const { data: GetUserByDetailslevelData } = useGetUserByLevel(
  //   "d",
  //   teamDataOnChange?.division,
  //   "0",
  //   "1,2",
  //   "details"
  // );
  // // const { data: GetPropertiesByLevelData } = useGetPropertiesByLevel(
  // //   "d",
  // //   teamDataOnChange?.division,
  // //   "details",
  // //   "all"
  // // );

  // const { mutateAsync: PostAddTeam, isLoading: addTeamLoading } = useAddTeam();
  // const { mutateAsync: PutUpdateTeam, isLoading: updateTeamLoading } =
  //   useUpdateTeam();

  // //===========================Division dropdown=====================//
  // const seletedDivisionOption = GetDivisionByOrganisationId?.data?.map(
  //   (text, index) => ({
  //     value: text?.Id,
  //     label: text?.Text,
  //     isDisabled: teamDataOnChange?.division == text?.Id,
  //   })
  // );

  // useEffect(() => {
  //   setAssignUsersToTeamData(GetPropertiesByLevelData?.data);
  // }, [GetPropertiesByLevelData]);

  // //=============================Form submit============================================//
  // const handleCreateNewTeam = async (propertyId) => {
  //   let createNewTeamPayload = new FormData();
  //   const uploadedTeamFile = EditTeamData?.[0]?.team_image_filename
  //   if (editFlag) {
  //     createNewTeamPayload.append("user_team_id", teamId);
  //     createNewTeamPayload.append("name", teamDataOnChange?.teamName);
  //     createNewTeamPayload.append(
  //       "team_image_filename",
  //       uploadImgFile ? uploadImgFile?.name : uploadedTeamFile
  //     );
  //     createNewTeamPayload.append(
  //       "team_image_file",
  //       uploadImgFile ? uploadImgFile : ""
  //     );
  //     createNewTeamPayload.append("division_id", teamDataOnChange?.division);
  //     createNewTeamPayload.append("members", JSON.stringify(teamMember)); // Convert to JSON string when you pass array of object in FormData
  //     createNewTeamPayload.append("properties", JSON.stringify(propertyId)); // Convert to JSON string
  //   } else {
  //     createNewTeamPayload.append("name", teamDataOnChange?.teamName);
  //     createNewTeamPayload.append(
  //       "team_image_file",
  //       uploadImgFile ? uploadImgFile : teamDataOnChange?.teamName
  //     );
  //     createNewTeamPayload.append("division_id", teamDataOnChange?.division);
  //     createNewTeamPayload.append("members", JSON.stringify(teamMember)); // Convert to JSON string when you pass array of object in FormData
  //     createNewTeamPayload.append("properties", JSON.stringify(propertyId)); // Convert to JSON string
  //   }
  //   if (editFlag) {
  //     try {
  //       const result = await PutUpdateTeam(createNewTeamPayload);

  //       if (result.status === status.s && result.data.bReturn) {
  //         toast.success(<SucessAlert />);
  //         setProgress(0);
  //         setCreateNewTeamPopup(false);
  //         setTeamDataOnChange({
  //           division: "",
  //           teamName: "",
  //           manager: "",
  //         });
  //         setUploadImgFile("");
  //         setCroppedImage();
  //         setPreviousRowSelection({})
  //         setPreviousSelectedTeamManager({})
  //         setPreviousSelectedTeamleader({})
  //         setSelectedTeamleader([])
  //         setAssignedPropertyToTeam([])
  //         // setStepWizard("CreateNewTeam");

  //       } else {
  //         toast.error(<ErrorAlert />);
  //       }
  //     } catch (error) {
  //       toast.error(<ErrorAlert />);
  //     }
  //   } else {
  //     try {
  //       const result = await PostAddTeam(createNewTeamPayload);

  //       if (result.status === status.s && result.data.bReturn) {
  //         toast.success(<SucessAlert />);
  //         setProgress(0);
  //         setCreateNewTeamPopup(false);
  //         setPreviousRowSelection({})
  //         setPreviousSelectedTeamManager({})
  //         setPreviousSelectedTeamleader({})
  //         setTeamDataOnChange({});
  //         setSelectedTeamleader([])
  //         setAssignedPropertyToTeam([])
  //         // setStepWizard("CreateNewTeam");

  //       } else {
  //         toast.error(<ErrorAlert />);
  //       }
  //     } catch (error) {
  //       toast.error(<ErrorAlert />);
  //     }
  //   }
  // };

  // //=======================Create team screen======================//
  // const handleNextCreateScreen = (data, userProfile, username) => {
  //   setStepWizard("AssignUserToTeam");
  //   setProgress(50);
  //   setTeamData(data);
  //   setUserName(username);
  //   setTeamProfile(userProfile);
  //   setTeamDataOnChange({
  //     ...teamDataOnChange,
  //     division: data?.division?.value,
  //     teamName: data?.teamName,
  //     manager: data?.teamManager?.value,
  //   });
  //   // const divisionId = data?.division?.value;
  //   // const teamName = data?.teamName;
  //   // const teamManager = data?.teamManager?.value;
  //   const members = EditTeamData?.[0]?.members;
  //   const memberUserIds = members?.map((member) => member?.user_id);
  //   const userExistsMap = { ...proviousRowSelection };
  //   // setTeamName(teamName);
  //   if (editFlag) {
  //     GetUserByDetailslevelData?.data?.forEach((user) => {
  //       const userIdString = user.user_id.toString(); // Convert user_id to string for comparison
  //       if (
  //         memberUserIds?.includes(userIdString) ||
  //         userIdString == teamDataOnChange?.manager
  //       ) {
  //         userExistsMap[userIdString] = true;
  //       }
  //     });

  //     setRowSelection(userExistsMap);
  //   } else {
  //     GetUserByDetailslevelData?.data?.forEach((user) => {
  //       // Add default selection logic here
  //       if (user.user_id == data?.teamManager?.value) {
  //         userExistsMap[user.user_id] = true;
  //       }
  //     });
  //     setRowSelection(userExistsMap);
  //   }
  // };

  // const handleCloseCreateScreen = () => {
  //   console.log("edit time1")
  //   setCreateNewTeamPopup(false);
  //   setStepWizard("CreateNewTeam");
  //   setProgress(0);
  //   setTeamData([]);
  //   setCroppedImage()
  //   setTeamDataOnChange({
  //     division: "",
  //     teamName: "",
  //     manager: "",
  //   });
  //   setCroppedImage();
  //   setPreviousRowSelection({})
  //   setPreviousSelectedTeamManager({})
  //   setPreviousSelectedTeamleader({})
  // };

  // //=======================Add users screen=======================//
  // const handleUserScreen = (user_id) => {
  //   const teamLeaderData = GetUserByDetailslevelData?.data?.filter((item) =>
  //     user_id?.includes(String(item.user_id))
  //   );
  //   const isMembers = EditTeamData?.map((data) => data?.members)?.[0]
  //     ?.filter((data) => data?.is_team_manager == 1)
  //     ?.map((user) => user?.user_id);
  //   const isLeader = EditTeamData?.map((data) => data?.members)?.[0]
  //     ?.filter((data) => data?.is_team_leader == 1)
  //     ?.map((user) => user?.user_id);
  //   const memberlist = EditTeamData?.map((data) => data?.members)?.flat();
  //   setTeamLeader(teamLeaderData);
  //   const defaultManagerSelection = {};
  //   teamLeaderData?.forEach((user) => {
  //     const userIdString = user.user_id; // Convert user_id to string for comparison
  //     if (teamDataOnChange?.manager?.includes(userIdString)) {
  //       defaultManagerSelection[userIdString] = true;
  //     }
  //   });
  //   setSelectedTeamManager(defaultManagerSelection);

  //   if (editFlag) {
  //     const defaultSelection = {};
  //     if (isMembers && isMembers.length > 0) {
  //       memberlist.forEach((item) => {
  //         if (isMembers.includes(item.user_id)) {
  //           defaultSelection[item.user_id] = true;
  //         }
  //       });
  //     }
  //     setSelectedTeamManager(defaultSelection);

  //     const defaultTeamLeaderSelection = { ...previousSelectedTeamleader };
  //     if (isLeader && isLeader.length > 0) {
  //       memberlist.forEach((item) => {
  //         if (isLeader.includes(item.user_id)) {
  //           defaultTeamLeaderSelection[item.user_id] = true;
  //         }
  //       });
  //     }
  //     setSelectedTeamleader(defaultTeamLeaderSelection);
  //   }
  //   setStepWizard("AssignLeadersToTeam");
  //   setPropertyScreenHandler(true);
  //   setProgress(75);
  // };
  // const handleBackOnUserScreen = () => {
  //   setStepWizard("CreateNewTeam");
  //   setProgress(25);
  // };

  // //=======================Add leader screen======================//
  // const handleLeaderScreen = (teamMemberData) => {
  //   setTeamMember(teamMemberData);
  //   if (editFlag) {
  //     const propertiesString = EditTeamData?.[0]?.properties || [];
  //     const userExistsMap = {};
  //     assignUsersToTeamData?.forEach((user) => {
  //       const userIdString = user.property_id; // Convert user_id to string for comparison
  //       if (propertiesString?.includes(userIdString)) {
  //         userExistsMap[userIdString] = true;
  //       }
  //     });
  //     setAssignedPropertyToTeam(userExistsMap);
  //   }
  //   setStepWizard("AssignProperties");
  //   setProgress(99.99);
  // };
  // const handleBackOnLeaderScreen = () => {
  //   setStepWizard("AssignUserToTeam");
  //   setProgress(50);
  // };
  // //=======================Add property screen====================//
  // // const handlePropertyScreens = () => {
  // //   setStepWizard("CreateNewTeam");
  // //   setProgress(0);
  // //   setShow(false);
  // // };
  // const handlePreviousPropertyScreens = () => {
  //   setStepWizard("AssignLeadersToTeam");
  //   setProgress(75);
  // };

  // const WizardsPages = {
  //   CreateNewTeam: (
  //     <CreateNewTeam
  //       handleNextCreateScreen={handleNextCreateScreen}
  //       handleCloseCreateScreen={handleCloseCreateScreen}
  //       GetTeamByTeamIdData={GetTeamByTeamIdData}
  //       // DivisionOption={seletedDivisionOption}
  //       // userByLevel={userByLevel}
  //       teamData={teamData}
  //       flag={editFlag}
  //       // setSelectedDivisionId={setSelectedDivisionId}
  //       EditTeamData={EditTeamData}
  //       setTeamDataOnChange={setTeamDataOnChange}
  //       teamDataOnChange={teamDataOnChange}
  //       croppedImage={croppedImage}
  //       setCroppedImage={setCroppedImage}
  //       setUploadImgFile={setUploadImgFile}
  //       uploadImgFile={uploadImgFile}
  //       GetUserBylevelData={GetUserBylevelData}
  //     />
  //   ),
  //   AssignUserToTeam: (
  //     <AssignUserToTeams
  //       handleUserScreen={handleUserScreen}
  //       handleBackOnUserScreen={handleBackOnUserScreen}
  //       usedFor="teams"
  //       GetUserByDetailslevelData={GetUserByDetailslevelData?.data}
  //       setRowSelection={setRowSelection}
  //       rowSelection={rowSelection}
  //       teamData={teamData}
  //       setPreviousRowSelection={setPreviousRowSelection}
  //     />
  //   ),

  //   AssignLeadersToTeam: (
  //     <AssignLeaderToTeams
  //       handleBackOnLeaderScreen={handleBackOnLeaderScreen}
  //       handleLeaderScreen={handleLeaderScreen}
  //       usedFor="teams"
  //       teamLeaderData={teamLeader}
  //       setSelectedTeamleader={setSelectedTeamleader}
  //       selectedTeamleader={selectedTeamleader}
  //       rowSelection={selectedTeamManager}
  //       setRowSelection={setSelectedTeamManager}
  //       setPreviousSelectedTeamManager={setPreviousSelectedTeamManager}
  //       setPreviousSelectedTeamleader={setPreviousSelectedTeamleader}
  //     // customeCheckBoxHandler={customeCheckBoxHandler}
  //     />
  //   ),

  //   AssignProperties: (
  //     <AssignProperties
  //       goBack={propertyScreenHandler}
  //       handlePreviousPropertyScreens={handlePreviousPropertyScreens}
  //       // handlePropertyScreens={handlePropertyScreens}
  //       usedFor="teams"
  //       flag={editFlag}
  //       assignUsersToTeamData={GetPropertiesByLevelData?.data}
  //       // assignUsersToTeamData={assignUsersToTeamData}
  //       rowSelection={assignedPropertyToTeam}
  //       setRowSelection={setAssignedPropertyToTeam}
  //       handleCreateNewTeam={handleCreateNewTeam}
  //       addTeamLoading={addTeamLoading}
  //       updateTeamLoading={updateTeamLoading}
  //     />
  //   ),
  // };
  // table column
  const columns = useMemo(
    () => [
      {
        accessorKey: "team_image_fileurl",
        header: "",
        enableHiding: false,
        size: 40,
        enableSorting: false,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div style={{ width: "40px" }}>
            {row.original.team_image_fileurl ? (
              <Image
                src={row.original.team_image_fileurl}
                roundedCircle
                height={30}
                width={30}
                onError={(e) => (e.target.src = Avatar)}
              />
            ) : (
              <TeamsImageContainer>
                {row.original?.team_name?.charAt(0)?.toUpperCase()}
              </TeamsImageContainer>
            )}
          </div>
        ),
        muiTableBodyCellProps: {
          sx: {
            paddingRight: "0px!important",
            paddingLeft: "16px !important",
          },
        },
        muiTableHeadCellProps: {
          sx: {
            paddingRight: "0px!important",
          },
        },
      },
      {
        accessorKey: "team_name",
        header: "Team name",
        enableHiding: false,
        size: 80,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>{row.original?.team_name ? row.original?.team_name : "-"}</div>
        ),
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px!important",
          },
        },
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px!important",
          },
        },
      },
      {
        accessorKey: "team_manager_name",
        header: "Team Manager",
        enableHiding: false,
        // size: 50,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>
            {row.original?.team_manager_name
              ? row.original?.team_manager_name
              : "-"}
          </div>
        ),
      },
      {
        accessorKey: "team_leaders",
        header: "Team Leaders",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>
            {row.original?.team_leaders
              ? row.original?.team_leaders
              : row.original?.team_leaders == "0" && "None"}{" "}
          </div>
        ),
      },
      {
        accessorKey: "team_members",
        header: "Members",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>
            {row.original?.team_members ? row.original?.team_members : "-"}{" "}
          </div>
        ),
      },
      {
        accessorKey: "assigned_properties",
        header: "Assigned properties",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div className="d-flex">
            <FontAwesomeIcon
              icon="fa-regular fa-square-arrow-up-right"
              style={{ color: "#888888" }}
              size="lg"
              className="cursor-pointer pe-1"
              onClick={() => {
                setTeamId(row?.original?.user_team_id);
                setAssignPropertyPopup(true);
                setDivisionId(row?.original?.division_id);
                setAssignPropertiesUsedFor("viewAssignedProperties");
                setViewAssignedPropertiesData(
                  row.original?.assigned_properties
                );
              }}
            />
            {row.original?.assigned_properties?.map((item, index, array) => (
              <React.Fragment key={index}>
                {index == 0 && (
                  <span>
                    {item?.property_name}
                    {index === array?.length - 1 ? "" : ", "}
                  </span>
                )}
                {index == 1 && item?.property_name?.slice(0, 4) + "..."}
              </React.Fragment>
            ))}
            &nbsp; ({row.original?.assigned_properties?.length})
            {/* <div
              className="truncated-text d-flex gap-2 align-items-center"
              // style={{ width: "120px" }}
            >
              <FontAwesomeIcon
                icon="fa-regular fa-square-arrow-up-right"
                style={{ color: "#888888" }}
                size="lg"
                onClick={() => {
                  setAssignPropertyPopup(true);
                  setAssignPropertiesUsedFor("viewAssignedProperties");
                }}
                className="cursor-pointer"
                onClick={() => {
                  setTeamId(row?.original?.user_team_id);
                  setAssignPropertyPopup(true);
                  setDivisionId(row?.original?.division_id);
                }}
              />
              {row.original?.assigned_properties[0]?.property_name || "-"}
            </div>

            <div style={{ cursor: "pointer" }}>
              {row.original?.assigned_properties.length - 1 == 0 ? (
                <CircleMsg>
                  <span>
                    {row.original?.assigned_properties.length - 1 || 0}
                  </span>
                </CircleMsg>
              ) : (
                <>
                  <Tooltip
                    title={
                      row.original?.assigned_properties
                        .slice(1) // Get elements from index 1 to the end
                        .map((property) => property.property_name)
                        .join(", ") || "-"
                    }
                  >
                    <CircleMsg>
                      <span>
                        {row.original?.assigned_properties.length - 1 || 0}
                      </span>
                    </CircleMsg>
                  </Tooltip>
                </>
              )}
            </div> */}
          </div>
        ),
      },

      {
        accessorKey: "created_on",
        header: "Created",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,
        // muiTableHeadCellProps: {
        //   align: "right",
        // },
        // muiTableBodyCellProps: {
        //   align: "right",
        // },
        Cell: ({ row }) => (
          <div>
            {/* {row.original?.created_on ? row.original?.created_on : "-"} */}
            {row.original?.created_on
              ? moment(row.original?.created_on, "YYYY-MM-DD HH:mm:ss").format(
                  "DD MMM, YYYY"
                )
              : "-"}
          </div>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        enableHiding: false,
        size: 60,
        enableGlobalFilter: false,
        // muiTableHeadCellProps: {
        //   align: "right",
        // },
        // muiTableBodyCellProps: {
        //   align: "right",
        // },
        Cell: ({ row }) => (
          <div>
            {row.original?.status ? row.original?.status : "-"}
            {/* {row.original?.deactivated_date
                  ? moment(
                      row.original?.deactivated_date,
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("DD MMM, YYYY")
                  : "0"} */}
          </div>
        ),
      },
    ],
    []
  );

  const renderEmptyRowsFallback = () => {
    if (GetTeamsByLevelData?.data && GetTeamsByLevelData?.data?.length === 0) {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-3 radius-4">
          <>
            <div>
              You do not have any team added yet. Click on "+ CREATE TEAM" to
              create your first.
            </div>
          </>
        </div>
      );
    } else {
      return (
        <div
          className="d-flex justify-content-center w-100 grey-text py-3 radius-4"
          style={{ border: "1px solid #d1d1d1", backgroundColor: "#F9FAFA" }}
        >
          <>
            <div className="fs-14">
              Sorry! No result found. Please try again!
            </div>
          </>
        </div>
      );
    }
  };

  return (
    <div className="px-4 pt-2 pb-4">
      <MRTTheme
        colorVariantsForTableRow={["gray"]}
        colorVariantsForTableHead={["white"]}
        table={
          <MaterialReactTable
            columns={columns?.map((columns) => {
              return {
                ...columns,
                sortDescFirst: false,
              };
            })}
            autoResetPageIndex={false}
            icons={{
              ArrowDownwardIcon: () => SortIcon?.ArrowDownwardIcon(sorting),
              SyncAltIcon: () => SortIcon?.SyncAltIcon(),
            }}
            state={{
              sorting: sorting,
            }}
            onSortingChange={setSorting}
            data={GetTeamsByLevelData?.data || []}
            renderEmptyRowsFallback={renderEmptyRowsFallback}
            enableFilterMatchHighlighting={true}
            enableGlobalFilter
            globalFilterFn="contains"
            enableColumnActions={false}
            enableBottomToolbar={true}
            muiSearchTextFieldProps={{
              ...SearchStyle,
              placeholder: "Search for a team...",
              InputProps: {
                ...SearchIcon,
              },
            }}
            getRowId={(originalRow) => originalRow?.company_id}
            //give each row a more useful id
            enableSelectAll={false}
            enableRowActions
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "",
                size: 20,
                muiTableBodyCellProps: {
                  sx: {
                    paddingRight: "16px !important",
                  },
                },
              },
              "mrt-row-select": {
                header: "",
              },
            }}
            muiTablePaperProps={{
              sx: {
                backgroundColor: "transparent",
                table: {
                  borderCollapse: "separate",
                  borderSpacing: "0 5px",
                },
              },
            }}
            renderTopToolbar={({ table }) => (
              <>
                <div>
                  <Row>
                    <Col md={6} lg={8}>
                      <div className="d-flex align-items-center gap-2">
                        <H3>Teams</H3>
                        <CustomToolTip size="sm" text="Teams" />
                      </div>
                      <div className="paragraph2-light pt-3">
                        Below is a list of all teams in this division. Create
                        new teams, add users and properties to teams using the
                        menu.
                      </div>
                    </Col>
                    <Col
                      md={6}
                      lg={4}
                      className="my-1 d-flex justify-content-end gap-3"
                    >
                      <div className="w-100">
                        <MRT_GlobalFilterTextField table={table} />
                      </div>
                      <ETButton
                        variant={"primary"}
                        buttonText="CREATE TEAM"
                        width="fit-content"
                        icon={true}
                        onClick={() => {
                          setCreateNewTeamPopup(true);
                          setProgress(25);
                          setEditFlag(false);
                          setStepWizard("CreateNewTeam");
                          setStepOneTeamData({});
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </>
            )}
            muiTableContainerProps={{
              elevation: 0,
              sx: {
                background: "white",
                padding: "0px !important",
                paddingTop: "24px !important",
              },
            }}
            positionActionsColumn="last"
            initialState={{
              showGlobalFilter: true,
              showColumnFilters: false,
            }}
            muiPaginationProps={{
              ...paginationoptions,
            }}
            renderRowActions={({ row, table }) => (
              <>
                <div className="d-flex justify-content-end">
                  <Menus
                    itemheight={24}
                    itemwidth={24}
                    iconborderradius="4px"
                    itemcontainerwidth="200px"
                    component={
                      <>
                        <MenuItem
                          key="edit"
                          sx={{
                            fontSize: "13px",
                            fontFamily: "Cerebri sans-light !important",
                          }}
                          onClick={() => {
                            setTeamId(row?.original?.user_team_id);
                            setCreateNewTeamPopup(true);
                            setProgress(25);
                            setEditFlag(true);
                            setStepWizard("CreateNewTeam");
                          }}
                        >
                          Edit team
                        </MenuItem>
                        <hr className="hr m-0"></hr>
                        <MenuItem
                          key="AssignProperty"
                          sx={{
                            fontSize: "13px",
                            fontFamily: "Cerebri sans-light !important",
                          }}
                          onClick={() => {
                            setTeamId(row?.original?.user_team_id);
                            setAssignPropertyPopup(true);
                            setAssignPropertiesUsedFor("addExtra");
                            setDivisionId(row?.original?.division_id);
                          }}
                        >
                          Assign properties to team
                        </MenuItem>
                        <hr className="hr m-0"></hr>
                        <MenuItem
                          key="AssignUser"
                          sx={{
                            fontSize: "13px",
                            fontFamily: "Cerebri sans-light !important",
                          }}
                          onClick={() => {
                            setTeamId(row?.original?.user_team_id);
                            setDivisionId(row?.original?.division_id);
                            setRowSelection({});
                            setAssignUserToTeams(true);
                          }}
                        >
                          Assign user to teams
                        </MenuItem>
                        {/* <hr className="hr m-0"></hr>
                        <MenuItem
                          key="Assignteamleaders"
                          sx={{
                            fontSize: "13px",
                            fontFamily: "Cerebri sans-light !important",
                          }}
                          onClick={() => setAssignTeamleadersToTeams(true)}
                        >
                          Assign teamleaders
                        </MenuItem> */}
                        <hr className="hr m-0"></hr>
                        {row.original?.status == "Active" ? (
                          <>
                            <MenuItem
                              key="Deactivate"
                              sx={{
                                fontSize: "13px",
                                fontFamily: "Cerebri sans-light !important",
                              }}
                              onClick={() => {
                                setDeactivateteamPopup(true);
                                setIsActivatedId(0);
                                setTeamId(row?.original?.user_team_id);
                              }}
                            >
                              Deactivate team
                            </MenuItem>
                          </>
                        ) : (
                          <MenuItem
                            key="Reactivate"
                            sx={{
                              fontSize: "13px",
                              fontFamily: "Cerebri sans-light !important",
                            }}
                            onClick={() => {
                              setIsActivatedId(1);
                              setDeactivateteamPopup(true);
                              setTeamId(row?.original?.user_team_id);
                            }}
                          >
                            Reactivate team
                          </MenuItem>
                        )}
                      </>
                    }
                  />
                </div>
              </>
            )}
          />
        }
      />

      {/* Create new user popup */}
      {/* <CustomPopup
        open={createNewTeamPopup}
        onHide={() => setCreateNewTeamPopup(false)}
        title="Create a team"
        title_color="black"
        size={"xl"}
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Create a team"}
      >
        <CreateNewTeam setClosePopup={setCreateNewTeamPopup} />
      </CustomPopup> */}

      <div>
        {" "}
        {/* <CustomPopup
          size="xl"
          open={createNewTeamPopup}
          // onHide={() => setShow(false)}
          title={!editFlag ? "Create a team" : "Edit a team"}
          title_color="black"
          titleTooltipText={"Create a team"}
          titleTooltip={true}
          // enableCrossButton={true}
          // onClickCrossButton={() => {
          //   setShow(false);
          //   setStepWizard("CreateNewTeam");
          // }}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
        >
          <div>
            <StyledLoadingBar>
              <LoadingBar
                color={customproperties.primaryColor}
                progress={progress}
                containerStyle={{
                  position: "absolute",
                  height: "2.5px",
                }}
                shadow={false}
                onLoaderFinished={() => setProgress(0)}
              />
            </StyledLoadingBar>
          </div>
          <div>{WizardsPages[stepWizard]}</div>
        </CustomPopup> */}
      </div>

      <CreateNewTeamWizard
        show={createNewTeamPopup}
        setShow={setCreateNewTeamPopup}
        progress={progress}
        setProgress={setProgress}
        flag={editFlag}
        teamId={teamId}
        // EditTeamData={teamData}
        assignUsersToTeamData={assignUsersToTeamData}
        setAssignUsersToTeamData={setAssignUsersToTeamData}
        stepWizard={stepWizard}
        setStepWizard={setStepWizard}
        setStepOneTeamData={setStepOneTeamData}
        stepOneTeamsData={stepOneTeamsData}
        // croppedImage={croppedImage}
        // setCroppedImage={setCroppedImage}
        // setUploadImgFile={setUploadImgFile}
        // uploadImgFile={uploadImgFile}
        // GetTeamByTeamIdData={GetTeamByTeamIdData?.data}
      />
      {/*edit user popup*/}
      {/* <CustomPopup
        open={editNewTeamPopup}
        onHide={() => setEditNewTeamPopup(false)}
        title="Edit a team"
        title_color="black"
        size={"xl"}
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Edit a team"}
      >
        <CreateNewTeam setClosePopup={setEditNewTeamPopup} />
      </CustomPopup> */}

      {/*assign user to properties*/}
      <CustomPopup
        open={assignPropertyPopup}
        onHide={() => setAssignPropertyPopup(false)}
        title={
          assignPropertiesUsedFor == "viewAssignedProperties"
            ? "Assigned properties"
            : "Assign properties to team"
        }
        title_color="black"
        size={"xl"}
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={
          assignPropertiesUsedFor == "viewAssignedProperties"
            ? "Assigned properties"
            : "Assign properties to team"
        }
      >
        <AssignProperties
          setClosePopup={setAssignPropertyPopup}
          assignUsersToTeamData={assignUsersToTeamData}
          viewAssignedPropertyData={viewAssignedPropertyData}
          usedFor={assignPropertiesUsedFor}
          handleAssignPropertiesToTeam={handleAssignPropertiesToTeam}
          setRowSelection={setAssignedPropertyToTeam}
          rowSelection={assignedPropertyToTeam}
          assignPropertiesToTeamsLoading={UpdateAssignPropertiesToTeamsLoading}
          handlePreviousPropertyScreens={() => {
            setAssignPropertyPopup(false);
          }}
        />
      </CustomPopup>

      {/*assign user to teams*/}
      <CustomPopup
        open={assignUserToTeamsPopup}
        onHide={() => setAssignUserToTeams(false)}
        title="Assign users to the team"
        title_color="black"
        size={"xl"}
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Assign users to the team"}
      >
        <AssignUserToTeams
          setClosePopup={setAssignUserToTeams}
          GetUserByDetailslevelData={assignToTeamsTableData}
          usedFor="assingUsers"
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          handleAssignUserToTeams={handleAssignUserToTeams}
          isLoading={PostAssignUserToTeamsLoading}
        />
      </CustomPopup>

      {/*assign user to teams*/}
      <CustomPopup
        open={assignTeamleadersToTeamsPopup}
        onHide={() => setAssignTeamleadersToTeams(false)}
        title="Assign leaders to the team"
        title_color="black"
        size={"xl"}
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Assign teamleaders to the team"}
      >
        <AssignLeaderToTeams
          setClosePopup={setAssignTeamleadersToTeams}
          usedFor="addExtra"
          handlePropertyScreens={() => {
            setAssignTeamleadersToTeams(false);
          }}
          handleBackOnLeaderScreen={() => {
            setAssignTeamleadersToTeams(false);
          }}
        />
      </CustomPopup>

      {/* Deactivate user? */}
      <CustomPopup
        open={deactivateteamPopup}
        onHide={() => setDeactivateteamPopup(false)}
        title={GetTeamsByLevelData?.data
          ?.filter((data) => data?.user_team_id == teamId)
          ?.map((data) =>
            data?.status == "Active"
              ? "Deactivate the team?"
              : "Activate the team?"
          )}
        title_color="black"
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Deactivate the team?"}
      >
        <div className="p-4 light-font-text">
          <div>
            {GetTeamsByLevelData?.data
              ?.filter((data) => data?.user_team_id == teamId)
              ?.map((data) =>
                data?.status == "Active"
                  ? " Deactivating the team will revoke all of the users access to theteams properties."
                  : "Reactivating the team will give all of the users in the team access to the teams properties."
              )}{" "}
          </div>

          <div className="pt-2">
            {GetTeamsByLevelData?.data
              ?.filter((data) => data?.user_team_id == teamId)
              ?.map((data) =>
                data?.status == "Active" ? (
                  <>
                    If you want user to keep there access to the properties
                    assign them to other teams or directly grant them access to
                    one or more properties.
                  </>
                ) : (
                  ""
                )
              )}
          </div>
          <div className="pt-2">
            {GetTeamsByLevelData?.data
              ?.filter((data) => data?.user_team_id == teamId)
              ?.map((data) =>
                data?.status == "Active" ? (
                  <>Are you sure you want to deactivate this team?</>
                ) : (
                  "Are you sure you want to activate this team?"
                )
              )}
          </div>
        </div>
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET?"
            onClick={() => {
              setDeactivateteamPopup(false);
            }}
          />
          <ETButton
            variant={"primary"}
            buttonText={GetTeamsByLevelData?.data
              ?.filter((data) => data?.user_team_id == teamId)
              ?.map((data) =>
                data?.status == "Active" ? "DEACTIVATE" : "ACTIVATE"
              )}
            disabled={UpdateTeamStatusLoading}
            onClick={() => {
              handleuseUpdateTeamStatus();
            }}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default Teams;

export const inactiveTeamsData = [
  {
    id: 1,
    no: "9999",
    team_name: "Frontoffice",
    team_manager_name: "Julie Johansen",
    team_leaders: "Julie Johansen",
    team_members: "20",
    joined: "30 Sep, 2021",
    assigned_properties: "Carolinelunden, Koldingsky, Mumbai",
    created_on: "2023-12-16 06:45:54",
    status: "Active",
    roles: "Compliance Manager",
    division: "Juliliving",
    last_active: "12 Dec, 2023",
    fname: "Johannes Nymark",
    lname: "Svensson",
  },
  {
    id: 2,
    no: "9999",
    team_name: "Backoffice",
    team_manager_name: "Thomas Mogensen",
    team_leaders: "Jesper Sylvester",
    team_members: "3",
    joined: "30 Sep, 2021",
    assigned_properties: "Ravnsborg Enge",
    created_on: "2023-12-16 06:45:54",
    status: "Active",
    roles: "Viewer",
    division: "UNITY Living",
    last_active: "7 Sep, 2023",
    fname: "Camille ",
    lname: "Larsen",
  },
];
