import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import ETButton from "../../../../Components/Buttons/ETButton";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
// import twoHandPng from "../../../../assets/images/both-hand.png";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";
import {
  useUnitId,
  useUnitOverview,
} from "../../../../hooks/stores/useUnitStore";
import { usePropertyOverview } from "../../../../hooks/stores/usePropertyStore";
import { GreenSpan, H4 } from "../../../../styles/Common.style";
import HighFive from "../../../../assets/animations/HighFive.lottie";
import "@dotlottie/player-component";

const ReservationConfirmation = ({
  setReservationWizard,
  propId,
  tenantName,
}) => {
  const data = new Date();
  //--------------------------Zustand-------------------//
  const { userDetails } = useUserDetails((state) => state);
  const unitOverview = useUnitOverview((state) => state.unitOverview);
  const propOverview = usePropertyOverview((state) => state.propOverview);
  const { unitId, setUnitId } = useUnitId((state) => state);

  return (
    <div>
      <Container fluid className="p-md-4 p-1 mb-4 containerStyle">
      <div
        className="px-0"
        style={{ 
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          border: "1px solid #F4F5F5",
        }}
      >
        <div
          className="pt-4 pb-3 px-3 d-sm-flex justify-content-between "
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div
            className="d-flex justify-content-center"
            style={{
              fontFamily: " Cerebri sans",
              fontSize: "14px",
              fontWeight: "700",
              alignItems: "center",
            }}
          >
            <div className="d-flex align-items-center gap-2">
              <H4>Confirmation</H4>
              <CustomToolTip  text="Confirmation" />
            </div>
          </div>

          <div>
            <div style={{ fontSize: "14px", fontWeight: "300", color: "#888" }}>
              {propOverview?.[0]?.propertyName} | {unitOverview?.[0]?.Address}
            </div>
          </div>
        </div>
        <Row style={{ textAlign: "center" }} className=" px-0">
          <Col md={{ span: 8, offset: 2 }}>
            <h1
              className="pt-5 "
              style={{ fontFamily: "Cerebri sans-medium", fontSize: "32px" }}
            >
              Nice job! You are the champion of the day...
            </h1>

            <div
              style={{ fontSize: "14px", fontWeight: "300" }}
              className="mx-4 px-lg-1 pb-3"
            >
              The unit has now been reserved for the customer and the automatic
              screening process has now started. You can follow the ongoing
              screening by clicking here. The unit has also changed its status
              to reserved.
              <p></p>
            </div>

            {/* ------------------data showing after creating Property---------------------------- */}

            <div className="mt-1 ">
              <div
                className="py-1"
                style={{
                  backgroundColor: "#F9FAFA",
                  textAlign: "center",
                  borderRadius: "8px",
                  border: " 1px solid #F0F0F0",
                }}
              >
                <div
                  className=" pt-3"
                  style={{
                    fontSize: "20px",
                  }}
                >
                  {tenantName?.map((item, index) => (
                    <div id={index}>
                      <GreenSpan
                        style={{ fontSize: "20px", fontWeight: "300" }}
                      >
                        {item?.firstname} {item?.lastname}
                      </GreenSpan>
                    </div>
                  ))}
                </div>
                <p
                  style={{
                    color: "#888888",
                    fontSize: "12px",
                    fontWeight: "300",
                  }}
                >
                  <span>
                    Created {moment(data).format("MMM DD, YYYY")} by&nbsp;
                    {userDetails?.firstName} {userDetails?.lastName}
                  </span>
                </p>
              </div>
            </div>

            {/* //........................Image Part ......................................// */}

            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                position: "relative",
                height: "300px",
              }}
            >
              <div style={{ position: "absolute", bottom: "0" }}>
                <dotlottie-player
                  src={HighFive}
                  autoplay
                  loop
                  style={{ height: "400px", width: "100%" }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div
          className="py-2 px-3 d-sm-flex justify-content-end"
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <div className="m-1">
            <NavLink
              to="/property-units/units"
              state={{ propId: propId, Tab: "Units" }}
              // style={{ textDecoration: "none", color: "black" }}
            >
              <ETButton variant={"white"} buttonText="GO BACK TO UNITS" />
            </NavLink>
          </div>
          <div className="m-1">
            <NavLink
              to="/property-units-edit/contracts"
              state={{
                propId: propId,
                Tab: "Overview",
                unit_id: unitOverview?.[0]?.unit_id,
                unit_no: unitOverview?.[0]?.calculated_unit_id,
                street_name: unitOverview?.[0]?.street_name,
                house_number: unitOverview?.[0]?.house_number,
                zip_code: unitOverview?.[0]?.zipcode_id,
              }}
              // style={{ textDecoration: "none", color: "black" }}
            >
              <ETButton variant={"primary"} buttonText="GO TO OVERVIEW" />
            </NavLink>
          </div>
        </div>
      </div>
      </Container>
    </div>
  );
};

export default ReservationConfirmation;
