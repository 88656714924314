import CreateNewAppoinmentConfirmation from "../../CreateNewAppointments/CreateNewAppoinmentConfirmation";

import CustomPopup from "../../../../../Components/CustomPopup";

import CreatingAppointment from "../../CreateNewAppointments/CreatingAppointment";

import { customproperties } from "../../../../../styles/Theme";
import { useEffect, useState } from "react";

import { StyledLoadingBar } from "../../../../../styles/Common.style";
import LoadingBar from "react-top-loading-bar";

import AttachedDocuments from "../../CreateNewAppointments/AttachedDocuments";
import CreatingInvitation from "../createNewInvitation/CreatingInvitation";

import AddCustomerToAppointment from "../../CreateNewAppointments/AddCustomerToAppointment";
import ConfirmCancelSigningOrder from "../../../standardComponents/cancelSigningOrder/ConfirmCancelSigningOrder";
import AddPropertyForUnits from "../../moveToAnother/AddPropertyForUnits";
const CreateNewInvitation = ({
  openNewInvitationScreen,
  setOpenNewInvitationScreen,
  setConfirmCancelSigningOrder,
  title,
  titleTooltipText,
  setShowNewLead,
  setStepWizard,
  isPropertyHasRelation
  
}) => {
  const [createInvitationWizard, setCreateInvitationWizard] = useState(
    ""
  );
  const [progress, setProgress] = useState(15);

  const [addExtraCustomer, setAddExtraCustomer] = useState(false);

  const handleSelectProperty = () => {
    
    setCreateInvitationWizard("CreatingAppointment")
  }

  const handleAcessCreatingInvitation = () => {
    setCreateInvitationWizard("AttachedDocuments");
  };

  const handleAcessAttachedDocumentPage = () => {
    setCreateInvitationWizard("ConfirmCancelSigningOrder");
  };
  const handleAcessConfirmationPage = () => {
    setCreateInvitationWizard("CreateNewInvitationConfirmation");
  };

  const WizardsPages = {
    SelectProperty: (
      <AddPropertyForUnits
        handleNext={() => {
          handleSelectProperty()
          setProgress(25);
        }}
        handleCancel={()=>{
          setOpenNewInvitationScreen(false);
          setProgress(15);
        }}
      />
    ),

    CreatingAppointment: (
      <CreatingInvitation
      isPropertyHasRelation={isPropertyHasRelation}
        handleNext={() => {
          handleAcessCreatingInvitation();
          setProgress(50);
        }}
        handleCancel={() => {
         if(isPropertyHasRelation)  
          {  setCreateInvitationWizard("SelectProperty")
          setProgress(15)}
          
        
          else{ setOpenNewInvitationScreen(false)}
       
         
        }}
        setCreateInvitationWizard={setCreateInvitationWizard}
      />
    ),
    AttachedDocuments: (
      <AttachedDocuments
        // handleCancel={}
        handleNext={() => {
          handleAcessAttachedDocumentPage();
          setProgress(75);
        }}
        setCreateInvitationWizard={setCreateInvitationWizard}
        handleCancel={() => {
          setCreateInvitationWizard("CreatingAppointment");
          setProgress(25);
        }}
      />
    ),
    ConfirmCancelSigningOrder: (
      <ConfirmCancelSigningOrder
        setConfirmCancelSigningOrder={setConfirmCancelSigningOrder}
        handleBack={() => {
          handleAcessCreatingInvitation();
          setProgress(50);
        }}
        handleCreate={() => {
          handleAcessConfirmationPage();
          setProgress(99.9);
        }}
      />
    ),

    CreateNewInvitationConfirmation: (
      <CreateNewAppoinmentConfirmation
        handleClose={() => {
          setOpenNewInvitationScreen(false);
          setCreateInvitationWizard("CreatingAppointment");
          setProgress(25);
        }}
        setCreateInvitationWizard={setCreateInvitationWizard}
        heading={"The invitation is now send to the customers!"}
        subHeading={
          "The invitations to the event is now send to all of the selected customers and the customers are now moved to the Pending tab. The event can be found under Events and in My Activity."
        }
        clientName={
          "Thomas Høgh Mogensen,Frederikke Windig, Charlotte Nielsen, Svend Svendsen, Asbjørn Markussen, Claus Petersen"
        }
        eventType={"Event - Open House |"}
        eventData={"Triers Hus, Sunday 28, 2023 12 Jul, 2023 at 15:30 - 16:30"}
        createdBy={"Created Sep 1, 2020 by Max Wildersans Brentford"}
        renderAppointmentDetails={true}
        renderContractDetails={false}
        customerData={"Thomas Høgh Mogensen, Camille Josephine Høgh"}
        propertyData={"Kaktus Towers"}
        addressData={"Dybølsbro 3, 17., door 11, 2450 København SV"}
        unitIdData={"212-187-002"}
        newAppointment={
          "Thomas Høeg Mogensen - Dybbølsbro 3, 4 I 7-N340-4 I 212-076"
        }
        showNewAppointment={false}
        showContractStats={false}
        contractsInMonth={3}
        contractsInYear={35}
      />
    ),
  };

  useEffect(()=>{
    if(isPropertyHasRelation){
      setCreateInvitationWizard("SelectProperty")
      setProgress(15)
    }else{
      setCreateInvitationWizard("CreatingAppointment") 
      setProgress(25)
    };
    
  },[openNewInvitationScreen])
  return (
    <div>
      <div>
        <CustomPopup
          open={openNewInvitationScreen}
          onHide={() => {
            setOpenNewInvitationScreen(false);
            
          }}
          title={title}
          title_color="black"
          enableCrossButton={true}
          size={"xl"}
          titleTooltip={true}
          titleTooltipText={titleTooltipText}
          extraInfo={true}
          likeFeature={true}
          extraInfoText={
            <div className="grey-light-font-text">Thomas Høgh Mogensen</div>
          }
        >
          <div>
            <StyledLoadingBar>
              <LoadingBar
                color={customproperties.primaryColor}
                progress={progress}
                containerStyle={{
                  position: "absolute",
                  height: "2.5px",
                }}
                shadow={false}
                onLoaderFinished={() => setProgress(0)}
              />
            </StyledLoadingBar>
            {/* <CreatingAppointment 
             /> */}

            {WizardsPages[createInvitationWizard]}
          </div>
        </CustomPopup>
      </div>
    </div>
  );
};

export default CreateNewInvitation;
