import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Select, { components } from "react-select";
import { REQUIRED_ERR } from "../../utils/validationConstants";
// import { iserror } from "react-query";

export const { ValueContainer, Placeholder } = components;
export const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.key !== "placeholder" ? child : null
      )}
    </ValueContainer>
  );
};
//---------------------------------------- units main page select------------------------
const colourStyles = {
  container: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    backgroundColor: "rgb(255,255,255)",
    fontFamily: "Cerebri sans-light !important",
    borderRadius: 8,
    marginLeft: "10px",

    fontSize: 14,
    paddingTop: "4px",
  }),
  placeholder: (base, state) => ({
    ...base,
    position: "absolute",
    background: "rgb(255,255,255)",

    fontFamily:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? " Cerebri sans-medium !important"
        : "Cerebri sans-light !important",
    background: "#ffffff",
    borderRadius: 2,
    paddingLeft: "4px",
    paddingRight: "4px",
    // marginLeft: "10px",
    top:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? "-75%"
        : "0%",
    transition: "top 0.2s, font-size 0.2s",
    fontSize: state.selectProps.isFocused || state.hasValue ? 8 : 14,
    // (state.hasValue ||
    //   state.selectProps.inputValue ||
    //   state.selectProps.isFocused) &&
    // 14,
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    // paddingTop: "4px",

    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? "#d1d1d1 !important" : "#d1d1d1 !important",

    borderRadius: "8px",
    backgroundColor: "#fff",
    fontSize: 14,
    "&:hover": {
      borderColor: "#d1d1d1",
      boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
    },
    pointerEvents: state.isDisabled && "auto",
    cursor: state.isDisabled ? "not-allowed " : "default",
    // opacity: state.isDisabled ? "0.6" : "1",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#000000", // Custom colour
    transition: "all .2s ease",
    transform: state.isFocused ? "rotate(180deg)" : null,
    padding: "10px 16px",
    cursor: state?.isDisabled ? 'not-allowed' :'pointer',
  }),
  option: (base, a, { isDisabled, selectProps, ...state }) => {
    // console.log(a, 'aaaaas')
    // console.log(state.options);
    const getIndex = state?.options.findIndex(
      (s) => state?.data?.value === s?.value
    );
    return {
      ...base,
      backgroundColor: "#fff",
      zIndex: 1,
      fontSize: 14,
      fontFamily: "Cerebri sans-light !important",
      color: isDisabled ? "#888888" : "#000000",
      paddingLeft: "15px",
      // borderBottom: "1px solid #D1D1D1",
      borderBottom:
        state?.options?.length - 1 === getIndex ? "" : "1px solid #F0F0F0",
    };
  },
  menu: (base) => ({
    ...base,
    zIndex: 3,
    fontSize: 14,
    width: "max-content",
    minWidth: "100%",
    overflowX: "scroll",
  }),
  singleValue: (provided, { data }) => ({
    ...provided,
    marginLeft: "4px",
    // specify a fallback color here for those values not accounted for in the styleMap
  }),
  menuPortal: (base) => ({ ...base, zIndex: 10000 }),
};

// ---------------------------------------------Normal react select----------------------------------------------------------------------//

export const normalInputStyle = (iserror) => ({
  container: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    opacity: state.isDisabled ? "0.7" : "1",
    width: '100%'
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    backgroundColor: "#ffffff",
    borderRadius: 8,
    fontFamily: "Cerebri sans-light !important",
    fontSize: 14,
    // fontWeight:300,
    color: "red !important",
    marginLeft: "10px",
    padding: "0px",
    paddingTop: "2px",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#f4f", // Custom colour
    transition: "all .2s ease",
    transform: state.isFocused ? "rotate(180deg)" : null,
    padding: "10px 16px",
    cursor: state?.isDisabled ? 'not-allowed' :'pointer',
  }),
  placeholder: (base, state) => ({
    ...base,
    position: "absolute",
    fontWeight: "300",
    background: "rgb(255,255,255)",
    background:
      "linear-gradient(0deg, rgba(255,255,255,1) 55%, rgba(247,247,247,0) 45%)",
    borderRadius: 2,
    padding:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? "0px 4px"
        : "0px 1px",
    margin: "0px",
    // marginLeft: "10px",
    color: state.isDisabled
      ? "#000"
      : state.hasValue ||
        state.selectProps.inputValue ||
        state.selectProps.isFocused
      ? "#000000"
      : "#000",

    fontFamily:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? " Cerebri sans-medium !important"
        : "Cerebri sans-light !important",
    top:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? "-58%"
        : "0%",
    transition: "top 0.2s, font-size 0.2s",
    fontSize: state.selectProps.isFocused || state.hasValue ? 8 : 14,
    fontWeight: 500,
    opacity: state.isDisabled ? "0.7" : "1",
    color: iserror && state.selectProps.isFocused ? "#888" : "#000",

    // (state.hasValue ||
    //   state.selectProps.inputValue ||
    //   state.selectProps.isFocused) &&
    // 14,
  }),
  control: (baseStyles, state, props) => ({
    ...baseStyles,
    // paddingTop: "4px",
    fontSize: 14,
    fontWeight: 300,
    boxShadow: state.isFocused ? "none" : "none",
    borderColor:
      iserror && !state.hasValue
        ? "#e33716 !important"
        : state.isFocused
        ? "#000 !important"
        : "#d1d1d1 !important",
    // borderColor: state.isFocused ? "#000 !important" : "#d1d1d1 !important",

    borderRadius: "8px",
    backgroundColor: "#fff",
    "&:hover": {
      borderColor:
        iserror && !state.hasValue
          ? "#e33716 !important"
          : state.isFocused
          ? "#000 !important"
          : "#d1d1d1 !important",
      boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
    },
    pointerEvents: state.isDisabled && "auto",
    cursor: state.isDisabled ? "not-allowed " : "default",
    //  opacity: state.isDisabled ? "0.7" : "1",
  }),
  option: (base, { isDisabled, selectProps, ...state }) => {
    const getIndex = state?.options.findIndex(
      (s) => state?.data?.value === s?.value
    );
    return {
      ...base,
      backgroundColor: "#fff",
      fontSize: 14,
      fontFamily: "Cerebri sans-light !important",
      zIndex: 1,
      color: isDisabled ? "#888888" : "#000000",
      paddingLeft: "15px",
      cursor: "pointer",
      // borderBottom: "1px solid #D1D1D1",
      borderBottom:
        state?.options?.length - 1 === getIndex ? "" : "1px solid #D1D1D1",
    };
  },
  singleValue: (provided, state) => ({
    ...provided,
    marginLeft: "4px",
    color: state.isDisabled ? "#888888" : "#000",
    // specify a fallback color here for those values not accounted for in the styleMap
  }),
  menu: (base) => ({
    ...base,
    zIndex: 3,
    fontSize: 14,
    fontWeight: 300,
    width: "max-content",
    minWidth: "100%",
    overflowX: "scroll",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 10000 }),
});

export const DropdownIndicator = (props) => {
  const { isDisabled } = props.selectProps;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon
          icon="fa-solid fa-chevron-down"
          style={{ color: isDisabled ? "#888" : "#000" }}
        />
      </components.DropdownIndicator>
    )
  );
};
export const ClearIndicator = (props) => {
  return (
    components.ClearIndicator && (
      <components.ClearIndicator {...props}>
        <FontAwesomeIcon
          icon="fa-solid fa-xmark"
          style={{ color: "#000000" }}
        />
      </components.ClearIndicator>
    )
  );
};
const ReactSelect = ({
  name,
  placeholder,
  iserror,
  errorScope = true,
  errorMessage,
  onChange,
  options,
  value,
  defaultValue,
  searchable,
  clearable,
  disabled,
  innerLabel, //used for custome placeholder changes
  height,
  components,
  formatGroupLabel,
  noOptionsMessage,
  menuIsOpen,
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <>
      <Select
        name={name}
        menuPortalTarget={document.body}
        styles={innerLabel === true ? colourStyles : normalInputStyle(iserror)}
        placeholder={placeholder}
        isSearchable={searchable}
        isClearable={clearable}
        // menuIsOpen={true}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        options={options}
        isDisabled={disabled}
        formatGroupLabel={formatGroupLabel}
        menuPosition={"fixed"}
        autosize={true}
        components={{
          ValueContainer: CustomValueContainer,
          DropdownIndicator,
          ClearIndicator,
        }}
        noOptionsMessage={() => `${noOptionsMessage}`}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        isFocused={focused}
        theme={(theme) => ({
          ...theme,
          backgroundColor: "#ffffff",

          // borderRadius: 0,
          spacing: {
            ...theme.spacing,
            baseUnit: 2,
            controlHeight: height ? height : 40,
            menuGutter: 8,
          },
        })}
      />
      {errorScope && iserror && (
        <p className="error-msg ">
           <div className=" d-flex align-items-center gap-1">
              <FontAwesomeIcon
                icon="fa-solid fa-circle-exclamation "
                size="sm"
                style={{ color: "#e33716" }}
              />{" "}
               {errorMessage ? errorMessage : REQUIRED_ERR}
            </div>
          {/* {errorMessage ? errorMessage : REQUIRED_ERR} */}
        </p>
      )}
    </>
  );
};

ReactSelect.defaultProps = {
  searchable: true,
  clearable: true,
  disabled: false,
  noOptionsMessage: "No options",
};

export default ReactSelect;
