import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AddMoreUnits from "./AddMoreUnits";
import ETButton from "../../../../Components/Buttons/ETButton";
import DropdownModalMui from "../../../../Components/DropdownModal/DropdownModalMui";
import TagFilterDropDown from "../TagFilterDropDown";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";

import { Checkedbox, UnCheckedbox } from "../../../../assets/icons/icons";
import { InputAdornment } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MRTTheme from "../../../../Components/table/MRTTheme";
import Menus from "../../../../Components/Menus/Menus";
import { NavLink } from "react-router-dom";
import { useGetUnitsByPropertyIdUpdated } from "../../../../hooks/queries/useUnitQueries";
import { useUnitId } from "../../../../hooks/stores/useUnitStore";
import { usePropertyId } from "../../../../hooks/stores/usePropertyStore";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../../Components/table/MRTGlobalProps";
import moment from "moment";
import CustomPopup from "../../../../Components/CustomPopup";

const EditPropertyUnitsTable = ({
  styleEditModalTitle,
  setStyleEditModalTitle,
  setSelectedIndex,
  addMoreUnits,
  setAddMoreUnits,
  getUnitsByPropertyid,
  unitsData,
  setUnitsData,
  originalTagData,
  //   isLoading,
  newData,
  unitbyproperty,
}) => {
  const propertyId = usePropertyId((state) => state.propertyId);
  const { setUnitId } = useUnitId((state) => state);
  const {
    data: GetUnitsByPropertyIdData,
    refetch: GetUnitsByPropertyIdDataRefetch,
    isLoading,
  } = useGetUnitsByPropertyIdUpdated(propertyId, 0);

  const [resetCondition, setResetCondition] = useState(false);
  const [unitByPropertyId, setUnitByPropertyId] = useState([]);

  const [editUnitRemovePopup, setUnitRemovePopup] = useState(false);
  const [editUnitRowID, setEditUnitRowID] = useState("");

  const handleClick = () => {
    setAddMoreUnits(true);
    setStyleEditModalTitle(true);
  };

  const handlefirst = () => {
    setSelectedIndex(0);
  };
  const handlethird = () => {
    setSelectedIndex(2);
  };

  const handleSuffix = (floor) => {
    if (floor > 3 && floor < 21) return "th";
    switch (floor % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row?.calculated_unit_id}${row?.alt_unit_id}`,
        header: "Unit no.",
        enableHiding: false,
        size: 130,
        muiTableBodyCellProps: {},

        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <>
            <div>{row?.original?.calculated_unit_id}</div>
            <div>{row?.original?.alt_unit_id}</div>
          </>
        ),
      },
      {
        accessorFn: (row) =>
          `${row?.street_name}${row?.zipcode_id}${row?.house_number}${row?.zipcode_id}${row?.city}`,
        header: "Address",
        size: 180,
        enableGlobalFilter: true,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <NavLink
              state={{
                unit_id: row.original.unit_id,
                propId: propertyId,
                unit_no: row?.original?.calculated_unit_id,
                street_name: row?.original?.street_name,
                house_number: row?.original?.house_number,
                zip_code: row?.original?.zipcode_id,
              }}
              to="/property-units-edit"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setUnitId(row.original.unit_id)}
              >
                {`${row?.original?.street_name},${row?.original?.house_number},${row?.original?.side_door}`}
              </div>
              <div>{`${row?.original?.zipcode_id} ${row?.original?.city}`}</div>
            </NavLink>
          </>
        ),
      },
      {
        accessorFn: (row) =>
          `${row?.no_of_rooms}${row?.floor_number}${row?.area}`,
        header: "Unit details",
        size: 180,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div className="d-flex justify-content-between">
              <div>{`${row?.original?.no_of_rooms}-room`}</div>{" "}
              <div>{`${row?.original?.floor_number}${handleSuffix(
                row?.original?.floor_number
              )} floor`}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-1">
                {row?.original?.area}{" "}
                <div
                  dangerouslySetInnerHTML={{
                    __html: row?.original?.square_measure_unit,
                  }}
                ></div>
              </div>

              <div
                style={{
                  width: "97px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  textAlign: "end",
                }}
              >
                {row?.original?.featured_facility == ""
                  ? "-"
                  : row?.original?.featured_facility}
              </div>
            </div>
          </>
        ),
      },
      {
        accessorFn: (row) => `${row?.rent}`,
        header: "Rent",
        size: 180,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
          sx: {},
        },
        Cell: ({ row }) => (
          <>
            <div className="d-flex justify-content-between">
              <div>
                Rent/
                {row?.original?.payment_frequency === 1
                  ? "mthly."
                  : row?.original?.payment_frequency === 3 && "qtrly."}
              </div>{" "}
              <div>{`${row?.original?.rent} ${row?.original?.currency_id}`}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                Rent/
                <div
                  dangerouslySetInnerHTML={{
                    __html: row?.original?.square_measure_unit,
                  }}
                ></div>
              </div>
              <div>{`${Math.ceil(row?.original?.rent / row?.original?.area)} ${
                row?.original?.currency_id
              }`}</div>
            </div>
          </>
        ),
      },
      {
        accessorFn: (row) => `${row?.actor_name}${row?.date}`,
        header: "Contract",
        size: 240,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div>
              {row?.original?.actor_name == ""
                ? "-"
                : row?.original?.actor_name}
            </div>
            <div>
              {row?.original?.date == "" ? (
                "-"
              ) : (
                <div className="d-flex align-items-center">
                  <div>{row?.original?.date_text}</div>&nbsp;:&nbsp;
                  <div>{moment(row?.original?.date).format("D MMM, YYYY")}</div>
                </div>
              )}
            </div>
          </>
        ),
      },

      {
        accessorFn: (row) => `${row?.mainstatus_name}${row?.substatus_name}`,
        header: "Status",
        size: 100,
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <>
            <div
              style={{
                whiteSpace: "nowrap",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                fontFamily: "Cerebri sans-medium",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              <div
                className="fs-13"
                style={{
                  backgroundColor: `${row?.original?.mainstatus_background_color}`,
                  height: "18px",
                  padding: "0px 10px",
                  borderRadius: "4px 4px 0px 0px",
                  textTransform: "uppercase",
                  color: row?.original?.mainstatus_font_color,
                }}
              >
                {row?.original?.mainstatus_name}
              </div>
              <div
                className="fs-12"
                style={{
                  backgroundColor: row?.original?.substatus_background_color,
                  height: "18px",
                  padding: "0px 10px",
                  borderRadius: "0px 0px 4px 4px",
                  color: row?.original?.substatus_font_color,
                }}
              >
                {row?.original?.substatus_name}
              </div>
            </div>
          </>
        ),
      },
    ],
    []
  );

  const ChooseFilter = [
    { Id: "1", Text: "Unit No" },
    { Id: "2", Text: "Status" },
  ];
  const tags = [
    { Id: "1", Text: "Not active", parent: "2" },
    { Id: "2", Text: "Available", parent: "2" },
    { Id: "3", Text: "Reserved", parent: "2" },
    { Id: "4", Text: "In process", parent: "2" },
    { Id: "5", Text: "Completed", parent: "2" },
    { Id: "6", Text: "Available", parent: "2" },
    { Id: "7", Text: "Total", parent: "2" },
  ];
  const selectdata = [];
  const [filterDropdowon, setFilterDropdowon] = useState("");
  const [filterDropData, setFilterDropData] = useState("");
  const [selectedDropdownData, setSelectedDropDownData] = useState([]);
  const [show, setShow] = useState();
  const [status, setStatus] = useState(false);

  const dumnmyDropDownData = {
    "Unit No": "calculated_unit_id",
    Status: "tags",
  };
  const [propertag, setPropertytag] = useState("");
  const [filter, setFilter] = useState();

  const handleChange = (filter, ActionTypes) => {
    if (ActionTypes.action === "clear") {
      setFilter("");
    } else {
      setFilter(filter);
    }
    setPropertytag(true);
    setShow(false);
    let a = dumnmyDropDownData[filter?.label];
    let b = [...new Set(newData?.map((data) => data[a]))];
    setSelectedDropDownData(b);
    // setFilter(filter.label);
    setFilterDropdowon(ChooseFilter);
    const checkSelectedLabel = filter?.label;
    if (checkSelectedLabel == "Status") {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  const filteredTagdata = filter
    ? tags.filter((tag) => tag.parent === filter.value)
    : [];

  // const handleChange = (event) => {
  //   setPropertytag(true);
  //   setShow(false);
  //   let a = dumnmyDropDownData[event?.label];
  //   let b = [...new Set(newData?.map((data) => data[a]))];
  //   setSelectedDropDownData(b);
  //   // setFilter(event?.label);
  //   setFilterDropdowon(ChooseFilter);
  // };

  const filteredTag = selectedDropdownData;
  const [selecteddata, setSelectedData] = useState([]);
  const [closeDropdown, setCloseDropdown] = useState(false);

  const [filterKeys, setFilterKeys] = useState({
    calculated_unit_id: [],
    mainstatus_name: [],
  });

  // Function to push data into a specific filter key
  const pushDataToFilterKey = (key, value) => {
    if (filterKeys[key]?.includes(value)) {
    } else {
      setFilterKeys((prevFilterKeys) => ({
        ...prevFilterKeys,
        [key]: [...prevFilterKeys[key], value],
      }));
    }
  };
  const multiPropsFilter = (newData, filters) => {
    const filterKeys = Object?.keys(filters);
    const filterdata = originalTagData?.filter((product) => {
      return filterKeys?.every((key) => {
        if (!filters[key].length) return true;
        // Loops again if product[key] is an array (for material attribute).
        if (Array?.isArray(product[key])) {
          return product[key]?.some((keyEle) => filters[key]?.includes(keyEle));
        }
        return filters[key]?.includes(product[key]);
      });
    });
    setUnitsData(filterdata);
  };
  useEffect(() => {
    if (resetCondition == true) {
      setUnitsData(originalTagData);
    }
  }, [resetCondition]);
  const handleFilterData = (i) => {
    const selectfilterdata = filter?.label;
    const parent = ChooseFilter.find(
      (filter) => filter.Id === selectfilterdata
    )?.parent;

    if (selectfilterdata === "Unit No") {
      const key = "calculated_unit_id";
      const value = i;
      pushDataToFilterKey(key, value);
    } else if (selectfilterdata === "Status") {
      const key = "mainstatus_name";
      const value = i;
      pushDataToFilterKey(key, value);
    } else {
    }

    setFilterDropData(i);

    selectdata.push({ lable: filterDropdowon, value: i });
    setSelectedData((prev) => [...prev, ...selectdata]);
  };
  useEffect(() => {
    setCloseDropdown(false);
  }, [closeDropdown]);

  const handleRemoveEditTagUnit = () => {
    const removeEditTagUnit = unitsData.filter(
      (data) => data?.unit_id !== editUnitRowID
    );
    setUnitsData(removeEditTagUnit);
    setUnitRemovePopup(false);
  };

  // const renderEmptyRowsFallback = () => (
  //   <div
  //     className="d-flex justify-content-center w-100 py-4 grey-text"
  //     style={{ color: "#888888" }}
  //   >
  //     <div>
  //       You do not have any unit yet. Click on “+ ADD MORE UNITS ” to create
  //       your first.
  //     </div>
  //   </div>
  // );
  const renderEmptyRowsFallback = () => {
    if (unitsData && unitsData?.length === 0) {
      return (
        <div
          className="d-flex justify-content-center w-100 py-4 grey-text"
          style={{ color: "#888888" }}
        >
          <>
            <div>
              You do not have any unit yet. Click on “+ ADD MORE UNITS ” to
              create your first.
            </div>
          </>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div style={{ fontSize: "17px" }}>Sorry! No result found:(</div>
          </>
        </div>
      );
    }
  };

  return (
    <>
      <div>
        <div className="px-4 mt-2 form-label-title">Selected units</div>
        <div className="mb-5 mt-4 px-4">
          <MRTTheme
            table={
              <MaterialReactTable
                columns={columns}
                data={unitsData ?? []}
                icons={{ ...SortIcon }}
                enableFilterMatchHighlighting={true}
                enableGlobalFilter
                // globalFilterFn="contains" //turn off fuzzy matching and use simple contains filter function
                renderEmptyRowsFallback={renderEmptyRowsFallback}
                state={{ isLoading: !unitsData }}
                // enableRowNumbers={false}
                enableRowActions
                enableColumnActions={false}
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "",
                    size: 50,
                    muiTableBodyCellProps: {
                      sx: {
                        // padding: "0px !important",
                        backgroundColor: "#F0F0F0 !important",
                        border: "none !important",
                      },
                    },
                  },
                }}
                muiSearchTextFieldProps={{
                  ...SearchStyle,

                  InputProps: {
                    ...SearchIcon,
                  },
                }}
                getRowId={(row) => row?.unit_id}
                renderRowActions={({ row }) => (
                  <Menus
                    itemheight={23}
                    itemwidth={23}
                    iconborderradius="4px"
                    itemcontainerwidth="150px"
                    component={
                      <div className="light-font-text cursor-pointer">
                        <div
                          onClick={() => {
                            setEditUnitRowID(row?.id);
                            setUnitRemovePopup(true);
                          }}
                          // key="edit"
                          className="p-2"
                        >
                          Remove{" "}
                        </div>
                      </div>
                    }
                  />
                )}
                positionActionsColumn="last"
                muiTableHeadCellProps={{
                  // elevation: 0,
                  sx: {
                    // boxShadow: "none",
                  },
                }}
                muiTableBodyProps={{
                  zindex: "1",
                  sx: {
                    td: {
                      overflow: "visible",
                      backgroundColor: "#F9FAFA",
                    },
                  },
                }}
                muiTablePaperProps={{
                  elevation: 0,
                  sx: {
                    fontFamily: "Cerebri sans !important",
                    borderRadius: "0",
                    border: "none",
                    background: "#FFFFFF",
                    fontWeight: "300",
                    ".css-1f2qhs8": {
                      minWidth: "auto",
                      marginBottom: "5px",
                    },
                    table: {
                      borderCollapse: "separate",
                      borderSpacing: "0 5px",
                    },
                    thead: {
                      // display: "none",
                    },
                    th: {
                      background: "#FFFFFF",
                      fontFamily: "Cerebri sans-medium !important",
                    },
                    td: {
                      fontFamily: "Cerebri sans-light !important",
                      fontSize: "14px",
                      borderRight: "1px solid #D1D1D1 !important",
                      borderImage:
                        "linear-gradient(to bottom, rgba(0,0,0,0) 25%,rgba(209,209,209) 25%,rgba(209,209,209) 75%,rgba(0,0,0,0) 75%) !important",
                      borderImageSlice: "1 !important",
                    },
                    tr: {
                      borderRadius: "4px",
                      background: "#F9FAFA",
                      margin: "4px",
                    },
                    "& td:nth-last-child(3)": {
                      borderRight: "none !important",
                    },
                    "& td:nth-last-child(2)": {
                      border: "none !important",
                      borderLeft: "1px solid #d1d1d1 !important",
                      backgroundColor: "#F0F0F0",
                    },
                    "& td:nth-last-child(1)": {
                      border: "none !important",
                    },
                  },
                }}
                muiTableHeadRowProps={{
                  sx: {
                    // ...TableRowsStyle,
                    background: "#FFFFFF",
                  },
                }}
                muiTablePaginationProps={{
                  ...paginationoptions,
                }}
                initialState={{
                  showGlobalFilter: true,
                  showColumnFilters: false,
                }}
                renderTopToolbar={({ table }) => (
                  <>
                    <Row className=" py-2 px-2">
                      <Col xl={3} md={6} className="my-1 pe-1 ">
                        <MRT_GlobalFilterTextField table={table} />
                      </Col>

                      <Col xl={3} md={6} className="my-1 pe-1">
                        <DropdownModalMui
                          text="Set filters"
                          width="560px"
                          closeDropdown={closeDropdown}
                          component={
                            <TagFilterDropDown
                              propertag={propertag}
                              filteredTag={filteredTag}
                              filteredTagdata={filteredTagdata}
                              status={status}
                              setUnitsTableData={setUnitsData}
                              multiPropsFilter={multiPropsFilter}
                              selectedDropdownData={selectedDropdownData}
                              dumnmyDropDownData={dumnmyDropDownData}
                              filterDropdowon={filterDropdowon}
                              // handleDropData={handleDropData}
                              unitsTableData={unitsData}
                              filterDropData={filterDropData}
                              setFilterDropData={setFilterDropData}
                              ChooseFilter={ChooseFilter}
                              handleFilterData={handleFilterData}
                              filter={filter}
                              selecteddata={selecteddata}
                              setFilter={setFilter}
                              handleChange={handleChange}
                              selectdata={selectdata}
                              filterKeys={filterKeys}
                              newData={newData}
                              setFilterKeys={setFilterKeys}
                              setCloseDropdown={setCloseDropdown}
                              setResetCondition={setResetCondition}
                            />
                          }
                        />
                      </Col>
                    </Row>
                  </>
                )}
                positionGlobalFilter="left"
              />
            }
          />
        </div>
        <AddMoreUnits
          unitbyproperty={unitbyproperty}
          styleEditModalTitle={styleEditModalTitle}
          addMoreUnits={addMoreUnits}
          setAddMoreUnits={setAddMoreUnits}
          unitsTableData={unitByPropertyId}
          unitsData={unitsData}
          setUnitsTableData={setUnitByPropertyId}
          newData={newData}
          setUnitsData={setUnitsData}
        />
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            width: "100%",
          }}
        >
          {isLoading ? (
            <div
              className="placeholder col-10 radius-8"
              style={{ height: "35px", width: "100px" }}
            ></div>
          ) : (
            <>
              {" "}
              <ETButton
                variant={"white"}
                buttonText="PREVIOUS"
                onClick={() => {
                  handlefirst();
                }}
              />
            </>
          )}

          {isLoading ? (
            <div
              className="placeholder col-10 radius-8"
              style={{ height: "35px", width: "100px" }}
            ></div>
          ) : (
            <>
              <ETButton
                variant={"primary"}
                buttonText="NEXT"
                onClick={() => {
                  handlethird();
                }}
              />
            </>
          )}
        </div>
      </div>
      <CustomPopup
        // modalHeadingStyle={modalHeadingStyle}
        style={{ position: "relative" }}
        size="md    "
        open={editUnitRemovePopup}
        onHide={() => setUnitRemovePopup(false)}
        title="Are you carrying out the trash?"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() => {
          setUnitRemovePopup(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div className="fs-12 fw-300 text-left mb-3">
            ARE YOU SURE WANT TO REMOVE THE UNIT FROM TAG ?
          </div>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => setUnitRemovePopup(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="I'M SURE"
            onClick={handleRemoveEditTagUnit}
          />
        </div>
      </CustomPopup>
    </>
  );
};

export default EditPropertyUnitsTable;
