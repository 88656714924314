import React from "react";
import { useState } from "react";
import AddCustomerInformation from "./AddCustomerInformation";
import CustomPopup from "../../../../Components/CustomPopup";
import { StyledLoadingBar } from "../../../../styles/Common.style";
import LoadingBar from "react-top-loading-bar";
import { customproperties } from "../../../../styles/Theme";
import ExistingCustomer from "../ExistingCustomer";
import EconomyAndPriorities from "./EconomyAndPriorities";
import CreateNewAppoinmentConfirmation from "../CreateNewAppointments/CreateNewAppoinmentConfirmation";
const CreateNewContractWizard = ({
  creatingNewContract,
  setCreatingNewContract,
  topLoadingBar,
  setTopLoadingBar,
}) => {
  const [createNewContract, setCreateNewContract] = useState(
    "AddCustomerInformation"
  );
  const [addExtraCustomer, setAddExtraCustomer] = useState(false);

  // Adding customer details handler //

  const handleExistingScreen = () => {
    setCreateNewContract("EconomyAndPriorities");
    setTopLoadingBar(66);
  };

  const handleCloseCreateContract = () => {
    setCreatingNewContract(false);
    setCreateNewContract("AddCustomerInformation");
    setTopLoadingBar(0);
  };

  //* Adding existing customer handler *//
  const handleEconomyAndPrioritiesScreen = () => {
    setCreateNewContract("EconomyAndPriorities");
  };

  const handleCustomerInformationScreen = () => {
    setCreateNewContract("AddCustomerInformation");
  };

  //* Adding existing customer handler *//

  // Adding economy and priority screen handler //
  const handleConfirmationScreen = () => {
    setCreateNewContract("createNewContractConfirmationScreen");
    setTopLoadingBar(99.99);
  };

  const handleBackToCustomerInformationScreen = () => {
    setTopLoadingBar(33);
    setCreateNewContract("AddCustomerInformation");
  };
  //* Adding economy and priority screen handler *//

  // Adding confirmation screen handler //

  const handleCloseConfirmationScreen = () => {
    setCreatingNewContract(false);
    setCreateNewContract("AddCustomerInformation");
  };

  //* Adding confirmation screen handler *//

  // Handle to add extra customer //

  const handleAddExtraCustomer = () => {
    setAddExtraCustomer(true);
  };

  //* Handle to add extra customer *//

  //* Create new contract wizard *//

  const WizardsPages = {
    AddCustomerInformation: (
      <AddCustomerInformation
        handlenext={handleExistingScreen}
        handleClose={handleCloseCreateContract}
      />
    ),
    ExistingCustomer: (
      <ExistingCustomer
        handleNext={handleEconomyAndPrioritiesScreen}
        handleBack={handleCustomerInformationScreen}
      />
    ),

    EconomyAndPriorities: addExtraCustomer ? (
      <AddCustomerInformation />
    ) : (
      <EconomyAndPriorities
        handleConfirm={handleConfirmationScreen}
        handleCancel={handleBackToCustomerInformationScreen}
        handleAddExtraCustomer={handleAddExtraCustomer}
      />
    ),

    createNewContractConfirmationScreen: (
      <CreateNewAppoinmentConfirmation
        handleClose={handleCloseConfirmationScreen}
        heading={"Nice job! You are the champion of the day..."}
        subHeading={
          "A contract has been created for the customer on the selected unit. You can access the contract under 'Customers' on the unit, and a confirmation has been sent to the customer."
        }
        renderContractDetails={true}
        customerData="Thomas Høgh Mogensen, Camille Josephine Høgh"
        propertyData="Kaktus Towers"
        addressData="Dybølsbro 3, 17., door 11, 2450 København SV"
        unitIdData="212-187-002"
        showContractStats={true}
        contractsInMonth={"3rd"}
        contractsInYear={"35th"}
      />
    ),
  };
  return (
    <div>
      <CustomPopup
        open={creatingNewContract}
        onHide={() => {
          setCreatingNewContract(false);
          setCreateNewContract("AddCustomerInformation");
          setTopLoadingBar(0);
        }}
        title={"Create new contract"}
        title_color="black"
        enableCrossButton={true}
        size={"xl"}
        titleTooltip={true}
        extraInfo={true}
        likeFeature={true}
        extraInfoText={
          <div className="grey-light-font-text">Thomas Høgh Mogensen</div>
        }
      >
        <div>
          <StyledLoadingBar>
            <LoadingBar
              color={customproperties.primaryColor}
              progress={topLoadingBar}
              containerStyle={{
                position: "absolute",
                height: "2.5px",
              }}
              shadow={false}
              onLoaderFinished={() => setTopLoadingBar(0)}
            />
          </StyledLoadingBar>

          {WizardsPages[createNewContract]}
        </div>
      </CustomPopup>
    </div>
  );
};

export default CreateNewContractWizard;

//Page created by Abu//
