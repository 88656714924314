import { Endpoint, Site } from "./API/Endpoints";
import { Get } from "./API/Method";

//Get Common Type
export const GetCommonType = (header = null, Type) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Common_GetCommonType, header, Type)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// //Get Common Type All
// export const GetCommonTypeAll = (Type) => {
//   return new Promise((resolve, reject) => {
//     Get(Site.ET, Endpoint.ET.Common_GetCommonType, null, Type)
//       .then((response) => {
//         resolve(response.data);
//       })
//       .catch((error) => {
//         reject({ error: true, message: error.message });
//       });
//   });
// };

//Get Billing level details
export const GetBillingLevelDetails = (level, level_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Common_GetBillingLevelDetails,
      null,
      level + "/" + level_id
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};