import { useState, useEffect, useRef } from "react";
import { Col, Row, Form, Image, Container } from "react-bootstrap";
import StandardImage from "../../assets/images/StandardSignin.jpg";
import { Snackbar } from "@mui/material";
import "./GeneralLogin.css";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import estatelogologin from "../../assets/images/et-logo-new-user.png";
import { ReactComponent as Textimage } from "../../assets/icons/svg_icons/Loginusernameicon.svg";
import { ReactComponent as Passimage } from "../../assets/icons/svg_icons/Loginpasswordicon.svg";
import { NavLink, useNavigate } from "react-router-dom";
import Check from "../../Components/Checkbox/Check";
import "../../Components/Checkbox/check.css";
import { Controller, useForm } from "react-hook-form";
import { useLogin } from "../../hooks/queries/useLoginQueries";
import { REQUIRED_ERR, TERMS_ERR } from "../../utils/validationConstants";
import { SetSessionItem } from "../../utils/SessionStorage";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import LoginButton from "./DemoTest";

import IconTextField from "../../Components/GroupText/IconTextField";
import axios from "axios";
import { GreenSpan, H5 } from "../../styles/Common.style";
import ETButton from "../../Components/Buttons/ETButton";
import { useSendOtp, useValidateOtp } from "../../hooks/queries/useSMSQueries";
import { status } from "../../model/Constant";

const Login = () => {
  //--------------------- state -----------------//

  const [hostname, sethostName] = useState("");
  const [terms, setTerms] = useState(false);
  const [remember, setRemember] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [fpHash, setFpHash] = useState("");
  const [ipAddress, setIPAddress] = useState("");
  const [browser, setBrowser] = useState("");
  const [OS, setOS] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [showVerifyOtpPage, setShowVerifyOtpPage] = useState(false);

  const [refNo, setRefNo] = useState("");
  const [token, setToken] = useState({ authToken: "", rt: "", user_logId: "" });
  const [phoneLastThreeDigits, setPhoneLastThreeDigits] = useState("");

  // ------------------- form two const----------------------------//
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);

  // ----------------- all const managed here for form 2  -------------//
  const hasEmptyString = otpValues.every((value) => value !== "");
  const otpFieldsRef = useRef([]);

  // ----------------- const--------------------------------
  const { mutateAsync: loginMutate, isLoading: isLoginApiLoading } = useLogin();
  const { mutateAsync: PostSendOTP, isLoading: isSendOtpLoading } =
    useSendOtp();
  const { mutateAsync: PostValidateOTP, isLoading: isValidateOtpLoading } =
    useValidateOtp();
  const navigate = useNavigate();

  const HomePage = () => {
    navigate("/control-center");
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      // Terms: "",
    },
  });

  const handleRememberMe = (remember, username) => {
    if (remember) {
      localStorage?.setItem("user_name", username);
    } else {
      localStorage?.removeItem("user_name");
    }
  };

  const handleSendOtp = async (jwt) => {
    const auth = { "auth-token": jwt };
    try {
      const response = await PostSendOTP(auth);
      if (response?.data?.bReturn) {
        setRefNo(response?.data?.data?.ref_no);
        setShowVerifyOtpPage(true);
      }
    } catch (error) {}
  };

  const handleLogin = async (data) => {
    const loginPayload = {
      username: data?.username,
      password: data?.password,
      visitorId: fpHash,
      website_type: "ETM",
      aud: hostname,
      ip: ipAddress,
    };

    try {
      const result = await loginMutate(loginPayload);

      // Handle the result
      if (result?.jwt) {
        if (result?.is_device_validate === 1) {
          handleSendOtp(result?.jwt);
          setToken((prev) => ({
            ...prev,
            authToken: result?.jwt,
            rt: result?.rt,
            user_logId: result?.user_login_log_id,
          }));
        } else {
          SetSessionItem("et-jwt", result?.jwt);
          SetSessionItem("et-rt", result?.rt);
          SetSessionItem("user-log-id", result?.user_login_log_id);
          HomePage();
          setErrorMessage("");
          handleRememberMe(data?.remember, data?.username);
        }
      } else {
        setErrorMessage(result?.message);
      }
    } catch (error) {
      // Handle different error scenarios
      if (error?.message?.code === 908) {
        navigate("maintenance", {
          state: {
            message: error?.message?.message,
            end_date: error?.message?.data?.maintenance_end_date,
          },
        });
      } else if (error?.message?.code === 909) {
        navigate("deactivated", {
          state: {
            message: error?.message?.message,
            end_date: error?.message?.data?.deactivate_end_date,
          },
        });
      } else if (error?.message?.code === 907) {
        navigate("page-doesnt-exist");
      } else if (error?.message?.code === 910) {
        navigate("401-d");
      } else {
        setErrorMessage(error?.message?.message);
      }
    }
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleCloseSnack}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  // const loginHandler = async (loginPayload) => {
  // 	try {
  // 		const result = await loginMutate(loginPayload);

  // 		if (!result.jwt == "") {
  // 			SetSessionItem("et-jwt", result.jwt);
  // 			SetSessionItem("et-rt", result.rt);

  // 			HomePage();
  // 		}
  // 	} catch (error) {
  // 		handleClickSnackbar();
  // 	}
  // };

  // const handleClickShowPassword = () => setShowPassword((show) => !show);
  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };
  // const inputProps = {};
  // const label = { inputProps: { "aria-label": "Checkbox demo" } };
  // const BootstrapButton = styled(Button)({});
  const customproperties = [
    {
      id: "1",
      ContainerBackground: "#2B3C49F2",

      backgroundimage: StandardImage,
      logo: estatelogologin,
      Biocolor: "white",
      TextfieldLabelcolor: "#FFFFFF",
      TextfieldUsernameIcon: <Textimage />,
      TextfieldPassIcon: <Passimage />,
      Textfieldcolor: "#FFFFFF",
      TextfieldBordercolor: "#FFFFFF",
      TextfieldfontFamily: "Cerebri sans-light",
      Signinbuttonbgcolor: "#F1664B",
      FormLinkandTextboxCheckColor: "#FFFFFF",
      TextfieldDecoration: "1px solid #FFFFFF",
      date: "2020-09-12",
      Currency: 123456.49,
    },
  ];

  useEffect(() => {
    //remember me functionality
    const user_name = localStorage.getItem("user_name");
    if (user_name) {
      setValue("username", user_name);
      setValue("remember", true);
    }

    //Get Ip Address
    const handleGetIPAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org/?format=json");
        setIPAddress(response?.data?.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    //Get visitor Id
    const setFp = async () => {
      const fp = await FingerprintJS.load();
      const { visitorId } = await fp.get();
      setFpHash(visitorId);
    };

    //Detect OS
    const detectOs = () => {
      const userAgent = window.navigator.userAgent;
      if (userAgent.indexOf("Win") !== -1) {
        setOS("Windows");
      } else if (userAgent.indexOf("Mac") !== -1) {
        setOS("MacOS");
      } else if (userAgent.indexOf("Linux") !== -1) {
        setOS("Linux");
      } else {
        setOS("Unknown");
      }
    };

    // Get browser
    const getBrowserName = () => {
      let browserInfo = navigator.userAgent;
      let browser;
      if (browserInfo.includes("Opera") || browserInfo.includes("Opr")) {
        setBrowser("Opera");
      } else if (browserInfo.includes("Edg")) {
        setBrowser("Edge");
      } else if (browserInfo.includes("Chrome")) {
        setBrowser("Chrome");
      } else if (browserInfo.includes("Safari")) {
        setBrowser("Safari");
      } else if (browserInfo.includes("Firefox")) {
        setBrowser("Firefox");
      } else {
        browser = "unknown";
      }
      return browser;
    };

    getBrowserName();
    detectOs();
    setFp();
    handleGetIPAddress();
    sethostName(window.location.origin);
  }, []);

  // ------------------- form two const ----------

  const handleInput = (index, value) => {
    if (!value) return null;
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);
    setValue(`otp${index + 1}`, value, { shouldValidate: true });
    if (value.length === 1 && index < otpValues.length - 1) {
      otpFieldsRef.current[index + 1].focus();
    }
  };
  const handleBackspace = (index) => {
    if (otpValues[index] !== "") {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = "";
      setValue(`otp${index + 1}`, "", { shouldValidate: true });
      setOtpValues(newOtpValues);
    } else if (index > 0) {
      otpFieldsRef.current[index - 1].focus();
    }
  };
  // const handleResend = async () => {
  //   if (errorMessage?.flag === "Expired") {
  //     await handleUserSignUpStepOneNext();
  //     setVerificationProcess("D");
  //   } else {
  //     setVerificationProcess("D");
  //   }
  // };

  // ------------ form submit button clicked
  const handleVerifyOtp = async (data) => {
    const otpData = Object.values(otpValues);
    const otp = otpData?.join("");
    const validateOtpPayload = {
      otp_code: otp,
      ref_no: refNo,
      visitorId: fpHash,
      is_authorize: data?.authoriseDevice ? "1" : "0",
      ip: ipAddress,
      os: OS,
      browser: browser,
    };
    const validateData = {
      token: {
        "auth-token": token?.authToken,
      },
      payload: validateOtpPayload,
    };

    try {
      const response = await PostValidateOTP(validateData);
      if (response?.status === status.s) {
        SetSessionItem("et-jwt", token?.authToken);
        SetSessionItem("et-rt", token?.rt);
        SetSessionItem("user-log-id", token?.user_logId);
        HomePage();
        setErrorMessage("");
        handleRememberMe(data?.remember, data?.username);
        setOtpValues(["", "", "", "", "", ""]);
        reset();
        setRefNo();
        setToken({ authToken: "", rt: "", user_logId: "" });
      }
    } catch (error) {}
  };

  return (
    <div>
      {customproperties?.map((data, id) => (
        <div
          key={id}
          className="Maindiv pt-4 d-flex flex-column justify-content-center align-items-center w-100 scrollable-container-y"
          style={{ backgroundImage: `url(${data.backgroundimage})` }}
        >
          <Container>
            <Row className="d-flex justify-content-center align-items-center">
              <Col
                md={7}
                xxl={4}
                xl={5}
                lg={6}
                sm={12}
                className="px-xl-2 px-sm-0"
              >
                {/* <div className="mainLoginPageForm"> */}
                <div
                  className="StandardForm row"
                  style={{
                    backgroundColor: data.ContainerBackground,
                  }}
                >
                  <div className="pt-40">
                    <div className="d-flex justify-content-center align-items-center pb-5">
                      <Image
                        width={"160px"}
                        height={"auto"}
                        src={data.logo}
                        alt="logo"
                      />
                    </div>

                    {!showVerifyOtpPage ? (
                      <div>
                        <div
                          className=" d-flex flex-column align-items-center justify-content-center text-center light-font-text pb-4"
                          style={{
                            lineHeight: "22px",
                            color: data.Biocolor,
                          }}
                        >
                          {" "}
                          <Row>
                            <Col
                              lg={{ span: 8, offset: 2 }}
                              md={{ span: 8, offset: 2 }}
                            >
                              Before you can access the future of proptech
                              solutions, you need to fill in your username and
                              password
                            </Col>
                          </Row>
                        </div>

                        <Form onSubmit={handleSubmit(handleLogin)}>
                          <div className="d-flex row  justify-content-center align-items-center pb-5">
                            <Row>
                              <Col
                                lg={{ span: 8, offset: 2 }}
                                md={{ span: 8, offset: 2 }}
                                className="pb-3"
                              >
                                <Controller
                                  control={control}
                                  {...register("username")}
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <>
                                      <IconTextField
                                        inputHeader={"Username"}
                                        style={{
                                          textTransform: "lowercase",
                                          paddingRight: "40px",
                                        }}
                                        id={"username"}
                                        Icons={"fa-regular fa-user"}
                                        value={value}
                                        errorscope={true}
                                        onChange={(e) => {
                                          onChange(e);
                                          setErrorMessage("");
                                        }}
                                        maxlength={50}
                                        placeholder={"Your email"}
                                        type="email"
                                        isError={errors?.username}
                                      />
                                    </>
                                  )}
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col
                                className="pb-3"
                                lg={{ span: 8, offset: 2 }}
                                md={{ span: 8, offset: 2 }}
                              >
                                <Controller
                                  control={control}
                                  {...register("password")}
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <>
                                      <IconTextField
                                        inputHeader={"Password"}
                                        style={{ paddingRight: "40px" }}
                                        id={"password"}
                                        Icons={"fa-regular fa-lock-keyhole"}
                                        value={value}
                                        onChange={(e) => {
                                          onChange(e);
                                          setErrorMessage("");
                                        }}
                                        maxlength={50}
                                        placeholder={"Your password"}
                                        type="password"
                                        isError={errors?.password}
                                      />
                                    </>
                                  )}
                                />
                                <div className="d-flex justify-content-end fs-12">
                                  <NavLink
                                    to="forgot-password"
                                    style={{
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <span
                                      className="light-font-text fs-12"
                                      style={{
                                        color:
                                          data.FormLinkandTextboxCheckColor,
                                      }}
                                    >
                                      Forgot password?
                                    </span>
                                  </NavLink>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col
                                className="mb-3"
                                lg={{ span: 10, offset: 2 }}
                                md={{ span: 10, offset: 2 }}
                              >
                                <div className="d-flex">
                                  <div
                                    style={{
                                      lineHeight: "18px",
                                      color: data.FormLinkandTextboxCheckColor,
                                    }}
                                    className="small d-flex"
                                  >
                                    <div>
                                      <Controller
                                        name="remember"
                                        control={control}
                                        defaultValue={false}
                                        render={({ field }) => (
                                          <Check
                                            checked={field.value}
                                            id={field.name}
                                            value={field.name}
                                            name={field.name}
                                            onChange={(e) => {
                                              field.onChange(e);
                                            }}
                                          />
                                        )}
                                      />
                                    </div>
                                    <div
                                      className=" font-light white-text fs-14"
                                      style={{ paddingLeft: "12px" }}
                                    >
                                      Save my username for next time
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            {errorMessage && (
                              <Row>
                                <Col
                                  md={{ span: 9, offset: 1 }}
                                  className="ps-sm-4 pe-0 d-flex align-items-center justify-content-center mb-4"
                                >
                                  <div className="error-msg font-medium fs-16 text-align-center">
                                    {errorMessage}
                                  </div>
                                </Col>
                              </Row>
                            )}
                            <Row>
                              <Col
                                lg={{ span: 8, offset: 2 }}
                                md={{ span: 8, offset: 2 }}
                                className="d-flex align-items-center justify-content-center"
                              >
                                <LoginButton
                                  type="submit"
                                  buttonText={"LOGIN"}
                                  disabled={
                                    isLoginApiLoading || isSendOtpLoading
                                  }
                                />

                                <Snackbar
                                  open={openSnack}
                                  autoHideDuration={6000}
                                  onClose={handleCloseSnack}
                                  message="Wrong username or password"
                                  action={action}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      </div>
                    ) : (
                      <div className="d-flex flex-column align-items-center justify-content-center text-center">
                        <div
                          className=" w-75 d-flex flex-column align-items-center justify-content-center text-center light-font-text pb-4"
                          style={{
                            lineHeight: "22px",
                            color: data.Biocolor,
                          }}
                        >
                          {" "}
                          We have sent an SMS with a 6-digit confirmation code
                          to your phone number ending with{" "}
                          {`{{last 3 digits in user phone}}`}
                        </div>
                        <Form
                          className="h-100"
                          onSubmit={handleSubmit(handleVerifyOtp)}
                        >
                          <Row>
                            <Col
                              className="mb-3"
                              style={{ color: data.Biocolor }}
                            >
                              <div>
                                <div className="font-bold fs-14 pt-3 pb-1 d-flex ">
                                  Verification code
                                </div>
                                <div className="pb-40">
                                  <Row>
                                    <Col className="">
                                      <div className="profile-otp-container gap-3 flex-wrap px-2 ">
                                        {otpValues.map((value, index) => (
                                          <div
                                            className="d-flex flex-column"
                                            key={index}
                                          >
                                            <input
                                              type="number"
                                              maxLength="1"
                                              className="profile-otp-input"
                                              style={{
                                                width: "40px",
                                                height: "40px",
                                                color: data.Biocolor,
                                              }}
                                              value={value}
                                              {...register(`otp${index + 1}`, {
                                                required: true,
                                              })}
                                              onKeyDown={(e) => {
                                                if (e.key === "Backspace") {
                                                  handleBackspace(index);
                                                } else if (
                                                  !/^\d$/.test(e.key)
                                                ) {
                                                  // If the pressed key is not a digit, prevent its input
                                                  e.preventDefault();
                                                } else {
                                                  handleInput(index, e.key);
                                                }
                                              }}
                                              ref={(ref) => {
                                                otpFieldsRef.current[index] =
                                                  ref;
                                              }}
                                            />
                                            <div
                                              className="otp-border"
                                              style={{
                                                backgroundColor:
                                                  value !== "" && "#F1664B",
                                              }}
                                            ></div>
                                          </div>
                                        ))}
                                      </div>

                                      <div className="d-flex align-items-center gap-1 fs-14 my-3">
                                        <span>Reference number:</span>
                                        <span>{refNo}</span>
                                      </div>
                                      {Boolean(Object.keys(errors).length) && (
                                        <div className="d-flex light-font-login">
                                          {REQUIRED_ERR}
                                        </div>
                                      )}

                                      <div className="mt-3 d-flex light-font-login">
                                        <span className="fs-14 me-1">
                                          Didn't get a code? Try to click here
                                        </span>
                                        <GreenSpan
                                          className="fs-14 cursor-pointer font-medium "
                                          onClick={() =>
                                            handleSendOtp(token?.authToken)
                                          }
                                          style={{
                                            textDecoration: "underline",
                                          }}
                                        >
                                          Resend code
                                        </GreenSpan>
                                      </div>
                                      <div
                                        className="light-font-login "
                                        style={{ color: data.Biocolor }}
                                      >
                                        If that doesn't work, contact your
                                        system administrator.
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                              <div className="d-flex">
                                <div
                                  style={{
                                    lineHeight: "18px",
                                    color: data.FormLinkandTextboxCheckColor,
                                  }}
                                  className="small d-flex"
                                >
                                  <div>
                                    <Controller
                                      name="authoriseDevice"
                                      control={control}
                                      defaultValue={false}
                                      render={({ field }) => (
                                        <Check
                                          checked={field.value}
                                          id={field.name}
                                          value={field.name}
                                          name={field.name}
                                          onChange={(e) => {
                                            field.onChange(e);
                                          }}
                                        />
                                      )}
                                    />
                                  </div>
                                  <div
                                    className=" light-font-login white-text fs-14"
                                    style={{ paddingLeft: "12px" }}
                                  >
                                    Authorise this device for 14 days
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-center pt-1 pb-5">
                                <ETButton
                                  buttonText={"VERIFY"}
                                  variant={"primary"}
                                  type={"submit"}
                                  disabled={
                                    isValidateOtpLoading || !hasEmptyString
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    )}
                  </div>
                </div>
                {/* </div> */}
              </Col>
            </Row>
          </Container>
          <div
            className="light-font-text my-4 px-3"
            style={{
              borderRadius: "1px",
              backgroundColor: "rgba(255, 255, 255, 0.50)",
              color: "#000",
            }}
          >
            This solution is powered by{" "}
            <span className="form-label-title " style={{ color: "#000" }}>
              estatetool
            </span>{" "}
            | © 2023 - All rights reserved
          </div>
        </div>
      ))}
    </div>
  );
};

export default Login;
