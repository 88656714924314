import "./App.css";

import "./styles/fonts/fonts.css";
import "./styles/common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { QueryClient, QueryClientProvider } from "react-query";

import {
  BrowserRouter,
  Routes, //replaces "Switch" used till v5
  Route,
  useParams,
  useNavigate,
} from "react-router-dom";
import Main from "./navigation/Main";
import { ReactQueryDevtools } from "react-query/devtools";
import Login from "./views/GeneralLogin/Login";
import ForgotPasswordWizard from "./views/GeneralLogin/resetPassword/ForgotPasswordWizard";
import Theme from "./styles/Theme";
import { Dev_Var } from "./environment.js";
import { environments } from "./model/Constant";
import Error from "../src/Components/ErrorBoundary/Error";
import NewUserSignup from "./views/newUserSignup/NewUserSignup";
import SystemMaintenance from "./views/systemMaintenanceAndOthers/systemMaintenance/SystemMaintenance";
import Deactivated from "./views/systemMaintenanceAndOthers/deactivated/Deactivated";
import PageDosentExist from "./views/systemMaintenanceAndOthers/loginPageDontExist/PageDosentExist";
import UserSignupLinkExpired from "./views/newUserSignup/UserSignupLinkExpired.js";
import SetNewPassword from "./views/GeneralLogin/resetPassword/SetNewPassword.js";
import PasswordResentLinkExpiredWizard from "./views/GeneralLogin/resetPassword/resetPasswordLinkExpired/PasswordResentLinkExpiredWizard";
import ProtectedRoute from "./navigation/ProtectedRoute"
import NoDivision from "./views/systemMaintenanceAndOthers/noDivision/NoDivision"
function App() {
  // NOTE: Do not remove the following commented code of unload and beforeUnload event listeners
  // useEffect(() => {
  //   const handleUnload = () => {
  //
  //     // Perform actions before the component unloads
  //     window.alert("User closed the window");
  //   };
  //   window.addEventListener("unload", handleUnload);
  //   return () => {
  //     window.removeEventListener("unload", handleUnload);
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //   };
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus:
          Dev_Var === environments?.development ? false : true,
      },
    },
  });

  return (
    <>
      <Theme>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />

          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="maintenance" element={<SystemMaintenance />} />
              <Route path="deactivated" element={<Deactivated />} />
              <Route path="page-doesnt-exist" element={<PageDosentExist />} />
              <Route path="401-d" element={<NoDivision />} />
              <Route
                path="forgot-password"
                element={<ForgotPasswordWizard />}
              />
              <Route path="reset-password" element={<SetNewPassword />} />
              <Route
                path="sign-up-link-expired"
                element={<UserSignupLinkExpired />}
              />
              <Route
                path="reset-password-link-expired"
                element={<PasswordResentLinkExpiredWizard />}
              />
              {/* <Route path="*" element={<Main />} /> */}
              <Route
                path="*"
                element={
                  <ProtectedRoute>
                    <Main />
                  </ProtectedRoute>
                }
              />
              <Route path="/error" element={<Error />} />
              <Route path="create-new-user" element={<NewUserSignup />} />
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </Theme>
    </>
  );
}

export default App;
