import { useEffect, useMemo, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { MaterialReactTable } from "material-react-table";
import { Col, Form, Row } from "react-bootstrap";
import { Menu } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomPopup from "../../../../Components/CustomPopup";
import Menus from "../../../../Components/Menus/Menus";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import ETButton from "../../../../Components/Buttons/ETButton";
import Toggle from "../../../../Components/Switch/Switch";
import SigningPackageSendtoSigning from "./SigningPackageSendtoSigning";

import { useSigningOrderStore } from "../../../../hooks/stores/useSigningStore";
import {
  useCancelSigningOrder,
  useGetSigningOrderDetailBySigningOrderId,
  usePutUpdateSigningOrder,
  useSendToSigning,
  useUploadSigningOrderManualDocument,
} from "../../../../hooks/queries/useSigningQueries";
import { Checkedbox, UnCheckedbox } from "../../../../assets/icons/icons";
import moment from "moment";
import {
  DeleteArchiveDocument,
  PutUpdateSigningQaApproveStatus,
} from "../../../../services/Signing";
import { toast } from "react-toastify";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";
import { usePropertyId } from "../../../../hooks/stores/usePropertyStore";
import AddExtraSigningPerson from "./AddExtraSigningPerson";
import MRTTheme from "../../../../Components/table/MRTTheme";
import { usePropertyOverview } from "../../../../hooks/stores/usePropertyStore";
import AddDocument from "./AddDocument";
import { status } from "../../../../model/Constant";
import { GreenSpan } from "../../../../styles/Common.style";
import { Link } from "react-router-dom";
import { H1, H2 } from "../../../../styles/Common.style";
import { SortIcon } from "../../../../Components/table/MRTGlobalProps";

const Days = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },

  {
    label: "4",
    value: 4,
  },

  {
    label: "5",
    value: 5,
  },

  {
    label: "6",
    value: 6,
  },

  {
    label: "7",
    value: 7,
  },

  {
    label: "8",
    value: 8,
  },

  {
    label: "9",
    value: 9,
  },

  {
    label: "10",
    value: 10,
  },

  {
    label: "11",
    value: 11,
  },

  {
    label: "12",
    value: 12,
  },

  {
    label: "13",
    value: 13,
  },

  {
    label: "14",
    value: 14,
  },

  {
    label: "15",
    value: 15,
  },
];

const NoforSigningRequired = [
  {
    value: 0,
    label: "All",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
];

const OrderOfSignings = [
  {
    value: "L",
    label: "Landlord first",
  },
  {
    value: "T",
    label: "Tenant first",
  },
  {
    value: "M",
    label: "Mixed",
  },
];

const NoOfQaApprover = [
  {
    label: "No approver",
    value: 0,
  },
  {
    label: "1 approver",
    value: 1,
  },
  {
    label: "2 approvers",
    value: 2,
  },
  {
    label: "3 approvers",
    value: 3,
  },
];
export const ViewSigningPackages = ({
  unitOverviewData,
  unitId,
  // street_name,
  // house_number,
  // zip_code,
  selectedName,
}) => {
  //-------------------Zustand-------------------------//
  const propOverview = usePropertyOverview((state) => state.propOverview);
  const { setViewSigningOrder, signingOrderId, isViewSigningOrder } =
    useSigningOrderStore((state) => state);
  const { userDetails } = useUserDetails((state) => state);

  //----------------------States-------------------------//
  const [anchorEl, setAnchorEl] = useState(false);
  const [openUploadDocumentManually, setOpenUploadDocumentManually] =
    useState(false);
  const [droppedPdf, setDroppedPdf] = useState([]);
  const [addedDocument, setAddedDocument] = useState([
    {
      type: "-",
      name: "-",
      document_type: "-",
      delete_document: "-",
    },
  ]);
  const [isSendToSigningClicked, setIsSendToSigningClicked] = useState(false);

  const [rotateAddIcon, setRotateAddIcon] = useState(false);
  const [dropdown, setDropdownOpen] = useState(false);
  const rotate = rotateAddIcon ? "rotate(45deg)" : "rotate(0deg)";
  const [openViewDocumentPopUp, setOpenViewDocumentPopUp] = useState(false);

  const [documentFromPackage, setDocumentFromPackage] = useState(false);
  const [documentFromUnit, setDocumentFromUnit] = useState(false);
  const [documentFromCase, setDocumentFromCase] = useState(false);
  const [addExtraPerson, setAddExtraPerson] = useState(false);
  const [cancelOrder, setCancelOrder] = useState(false);
  const [openCancelOrderPopup, setOpenCancelOrderPopup] = useState(false);
  const [documentDetails, setDocumentDetails] = useState({
    documentUrl: "",
    documentName: "",
  });
  const [deleteManualDocument, setDeleteManualDocument] = useState({
    openDeletePopup: false,
    signing_order_id: "",
    document_id: "",
  });

  const [isSigningManuallyApprovedActive, setIsSigningManuallyApprovedActive] =
    useState(true);
  const [
    isSigningBeforeDepositApprovedActive,
    setIsSigningBeforeDepositApprovedActive,
  ] = useState(true);

  //----------------------react query----------------------//
  const {
    data: getSigningOrderDetailData,
    refetch: getSigningOrderDetailDataRefetch,
    isLoading: getSigningOrderDetailIsLoading,
  } = useGetSigningOrderDetailBySigningOrderId(signingOrderId);
  const {
    mutateAsync: PostSigningSendToSigning,
    isLoading: PostSigningSendToSigningIsLoading,
  } = useSendToSigning();
  const {
    mutateAsync: PostUploadSigningOrderDocument,
    isLoading: PostUploadSigningOrderDocumentIsLoading,
  } = useUploadSigningOrderManualDocument();
  const {
    mutateAsync: PutUpdateSigningOrder,
    isLoading: PutUpdateSigningOrderIsLoading,
  } = usePutUpdateSigningOrder();
  const {
    mutateAsync: PostCancelSigningOrder,
    isLoading: PostCancelSigningOrderIsLoading,
  } = useCancelSigningOrder();

  const propertyId = usePropertyId((state) => state.propertyId);

  //----------------------Validation----------------------//
  const {
    register,
    handleSubmit,
    clearErrors,
    getValues,
    formState: { errors },
    setValue,
    control,
    reset,
  } = useForm();
  const [contractId, setContractId] = useState("");
  const [signingOrder, setSigningOrder] = useState("");

  useEffect(() => {
    setContractId(getSigningOrderDetailData?.data?.contract_id);
  }, [getSigningOrderDetailData]);

  const handleDeleteDocument = async () => {
    const response = await DeleteArchiveDocument(
      contractId,
      deleteManualDocument?.document_id,
      0,
      deleteManualDocument?.signing_order_id
    );
    try {
      if (response?.data?.bReturn && response.status == status.s) {
        toast.success(response?.data?.message);
        getSigningOrderDetailDataRefetch();
        setDeleteManualDocument((prev) => ({
          ...prev,
          openDeletePopup: false,
        }));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    setAddedDocument(getSigningOrderDetailData?.data?.documents);
    setIsSigningManuallyApprovedActive(
      getSigningOrderDetailData?.data?.signing_setting?.[0]
        ?.no_landlord_seller_signing_before_manual_approved === 1
        ? true
        : false
    );
    setIsSigningBeforeDepositApprovedActive(
      getSigningOrderDetailData?.data?.signing_setting?.[0]
        ?.no_landlord_seller_signing_before_deposit_received === 1
        ? true
        : false
    );
    setValue("tenantBuyerDuration", {
      value:
        getSigningOrderDetailData?.data?.signing_setting?.[0]
          ?.tenant_buyer_signing_duration,
      label: Days.filter(
        (el) =>
          el.value ==
            getSigningOrderDetailData?.data?.signing_setting?.[0]
              ?.tenant_buyer_signing_duration && el.label
      )[0]?.label,
    });

    setValue("landLordSellerDuration", {
      value:
        getSigningOrderDetailData?.data?.signing_setting?.[0]
          ?.landlord_seller_signing_duration,
      label: Days.filter(
        (el) =>
          el.value ==
            getSigningOrderDetailData?.data?.signing_setting?.[0]
              ?.landlord_seller_signing_duration && el.label
      )[0]?.label,
    });
    setValue("tenantBuyerReminder", {
      value:
        getSigningOrderDetailData?.data?.signing_setting?.[0]
          ?.tenant_buyer_reminder_interval,
      label: Days.filter(
        (el) =>
          el.value ==
            getSigningOrderDetailData?.data?.signing_setting?.[0]
              ?.tenant_buyer_reminder_interval && el.label
      )[0]?.label,
    });
    setValue("landLordSellerReminder", {
      value:
        getSigningOrderDetailData?.data?.signing_setting?.[0]
          ?.landlord_seller_reminder_interval,
      label: Days.filter(
        (el) =>
          el.value ==
            getSigningOrderDetailData?.data?.signing_setting?.[0]
              ?.landlord_seller_reminder_interval && el.label
      )[0]?.label,
    });
    setValue("orderOfSigning", {
      value:
        getSigningOrderDetailData?.data?.signing_setting?.[0]
          ?.order_of_signings,
      label: OrderOfSignings.filter(
        (el) =>
          el.value ==
            getSigningOrderDetailData?.data?.signing_setting?.[0]
              ?.order_of_signings && el.label
      )[0]?.label,
    });
    setValue("signerRequired", {
      value:
        getSigningOrderDetailData?.data?.signing_setting?.[0]
          ?.nof_landlord_seller_signers_required,
      label: NoforSigningRequired.filter(
        (el) =>
          el.value ==
            getSigningOrderDetailData?.data?.signing_setting?.[0]
              ?.nof_landlord_seller_signers_required && el.label
      )[0]?.label,
    });
    setValue("noOfQa", {
      value:
        getSigningOrderDetailData?.data?.signing_setting?.[0]
          ?.nof_qa_approvers_required,
      label: NoOfQaApprover.filter(
        (el) =>
          el.value ==
            getSigningOrderDetailData?.data?.signing_setting?.[0]
              ?.nof_qa_approvers_required && el.label
      )[0]?.label,
    });
    setValue(
      "reminderStarts",
      getSigningOrderDetailData?.data?.reminder_start_date
    );
    setValue("reminderInterval", {
      value: getSigningOrderDetailData?.data?.reminder_interval,
      label: Days.filter(
        (el) =>
          el.value == getSigningOrderDetailData?.data?.reminder_interval &&
          el.label
      )[0]?.label,
    });
    setValue("commonNote", getSigningOrderDetailData?.data?.common_note);
  }, [getSigningOrderDetailData]);

  const handleAddPropertyDropDown = (event) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(true);
    setRotateAddIcon(!rotateAddIcon);
  };

  // dropzone
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) {
        setDroppedPdf((prev) => [
          ...prev,
          {
            name: acceptedFiles[0]?.name,
            document_type: "Agreement",
            type: "Signing document",
          },
        ]);
      }
    },
    // onDropRejected: (rejectedFiles) => console.log('rejectedFiles', rejectedFiles),
  });

  const OpenPopUpDocumentfromComputerPackage = () => {
    setOpenUploadDocumentManually(true);
    setRotateAddIcon("");
    setAnchorEl(null);
  };
  const [openSendToSigning, setOpenSendToSigning] = useState(false);

  const OpenPopUpDocumentPackage = () => {
    setDocumentFromPackage(true);
    setRotateAddIcon("");
    setAnchorEl(null);
  };
  const OpenPopUpDocumentUnit = () => {
    setDocumentFromUnit(true);
    setRotateAddIcon("");
    setAnchorEl(null);
  };
  const OpenPopUpDocumentCase = () => {
    setDocumentFromCase(true);
    setAnchorEl(null);
    setRotateAddIcon("");
  };
  const OpenPopUpAddExtraPerson = () => {
    setAddExtraPerson(true);
    setRotateAddIcon("");
    setAnchorEl(null);
  };
  const handleClosePropertyDropDown = () => {
    setAnchorEl(null);
    setDropdownOpen(false);
    setRotateAddIcon("");
  };

  const qaApproverColumns = useMemo(() => [
    {
      accessorKey: "name",
      header: "Name",
      size: 240,
      enableSorting: false,
      Cell: ({ row }) => (
        <>
          <div>
            {row?.original?.first_name}&nbsp;{row?.original?.last_name}
          </div>
        </>
      ),
    },
    {
      accessorKey: "email",
      header: "Email",
      enableSorting: false,
      size: 220,
    },
    {
      accessorKey: "title",
      header: "Title",
      size: 120,
      enableSorting: false,
    },
    // {
    //   accessorKey: "Qaapprover",
    //   header: "Approved",
    //   size: 120,
    //   enableSorting: false,
    //   muiTableHeadCellProps: {
    //     align: "center",
    //   },
    //   muiTableBodyCellProps: {
    //     align: "center",
    //   },
    //   Cell: ({ row }) => (
    //     <>
    //       <div>{row?.original?.is_qa_approver === 1 ? "Yes" : "No"}</div>
    //     </>
    //   ),
    // },
    {
      accessorKey: "approved_on",
      header: "Approved on",
      size: 150,
      enableSorting: false,
      // muiTableHeadCellProps: {
      //   align: "center",
      // },
      // muiTableBodyCellProps: {
      //   align: "center",
      // },
      Cell: ({ row }) => (
        <>
          <div>
            {row?.original?.approved_on !== ""
              ? moment(row?.original?.approved_on).format(
                  "DD MM, YYYY [at] HH:mm"
                )
              : "-"}
          </div>
        </>
      ),
    },
  ]);

  const signerColumns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 180,
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <div>
              {row?.original?.first_name}&nbsp;{row?.original?.last_name}
            </div>
          </>
        ),
      },

      {
        accessorKey: "email",
        header: "Email",
        enableSorting: false,
        size: 300,
      },
      {
        accessorKey: "title",
        header: "Title",
        size: 150,
        enableSorting: false,
      },
      // {
      //   accessorKey: "Qaapprover",
      //   header: "QA Approver",
      //   size: 150,
      //   enableSorting: false,
      //   muiTableHeadCellProps: {
      //     align: "center",
      //   },
      //   muiTableBodyCellProps: {
      //     align: "center",
      //   },
      //   Cell: ({ row }) => (
      //     <>
      //       <div>{row?.original?.is_qa_approver === 1 ? "Yes" : "No"}</div>
      //     </>
      //   ),
      // },
      // {
      //   accessorKey: "landlord_sellersigner",
      //   header: "LandLord/Seller Signer",
      //   size: 180,
      //   muiTableHeadCellProps: {
      //     align: "center",
      //   },
      //   muiTableBodyCellProps: {
      //     align: "center",
      //   },
      //   enableSorting: false,
      //   Cell: ({ row }) => (
      //     <>
      //       <div>
      //         {row?.original?.is_landlord_seller_signer === 1 ? "Yes" : "No"}
      //       </div>
      //     </>
      //   ),
      // },
      {
        accessorKey: "copy_recipient",
        header: "Copy Recipient",
        size: 150,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <div>{row?.original?.is_copy_recipient === 1 ? "Yes" : "No"}</div>
          </>
        ),
      },
      {
        accessorKey: "TwoFA",
        header: "2FA",
        size: 150,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <div>
              {row?.original?.use_two_factor_signing === 1 ? "Yes" : "No"}
            </div>
          </>
        ),
      },
      {
        accessorKey: "required",
        header: "",
        enableSorting: false,
        size: 240,
        Cell: ({ cell }) =>
          cell.getValue("ssn_cvr") !== "" ? (
            <div className="d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon="fa-regular fa-shield-check"
                className="me-2 fs-6"
                style={{ color: "#000" }}
              />
              <p className="mb-0">Identification is required</p>
            </div>
          ) : (
            ""
          ),
      },
    ],
    []
  );

  const signerCustomerColumns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 180,
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <div>
              {row?.original?.first_name}&nbsp;{row?.original?.last_name}
            </div>
          </>
        ),
      },

      {
        accessorKey: "email",
        header: "Email",
        enableSorting: false,
        size: 300,
        Cell: ({ row }) => (
          <>
            <div>{row?.original?.email || "-"}</div>
          </>
        ),
      },
      {
        accessorKey: "title",
        header: "Title",
        size: 150,
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <div>{row?.original?.title || "-"}</div>
          </>
        ),
      },
      // {
      //   accessorKey: "Qaapprover",
      //   header: "QA Approver",
      //   size: 150,
      //   enableSorting: false,
      //   muiTableHeadCellProps: {
      //     align: "center",
      //   },
      //   muiTableBodyCellProps: {
      //     align: "center",
      //   },
      //   Cell: ({ row }) => (
      //     <>
      //       <div>{row?.original?.is_qa_approver === 1 ? "Yes" : "No"}</div>
      //     </>
      //   ),
      // },
      // {
      //   accessorKey: "landlord_sellersigner",
      //   header: "LandLord/Seller Signer",
      //   size: 180,
      //   muiTableHeadCellProps: {
      //     align: "center",
      //   },
      //   muiTableBodyCellProps: {
      //     align: "center",
      //   },
      //   enableSorting: false,
      //   Cell: ({ row }) => (
      //     <>
      //       <div>
      //         {row?.original?.is_landlord_seller_signer === 1 ? "Yes" : "No"}
      //       </div>
      //     </>
      //   ),
      // },
      {
        accessorKey: "copy_recipient",
        header: "Copy Recipient",
        size: 150,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <div>{row?.original?.is_copy_recipient === 1 ? "Yes" : "No"}</div>
          </>
        ),
      },
      {
        accessorKey: "TwoFA",
        header: "2FA",
        size: 150,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <div>
              {row?.original?.use_two_factor_signing === 1 ? "Yes" : "No"}
            </div>
          </>
        ),
      },
      {
        accessorKey: "required",
        header: "",
        enableSorting: false,
        size: 240,
        Cell: ({ cell }) =>
          cell.getValue("ssn_cvr") !== "" ? (
            <div className="d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon="fa-regular fa-shield-check"
                className="me-2 fs-6"
                style={{ color: "#000" }}
              />
              <p className="mb-0">Identification is required</p>
            </div>
          ) : (
            ""
          ),
      },
    ],
    []
  );

  const addDocumentFromDocumentPackagedata = [
    {
      name: "pets-allowed-agreement.pdf",
      type: "Signing document",
      document_type: "Agreement",
    },
    {
      name: "moving-in-information-letter.pdf",
      type: "Appendix document",
      document_type: "Information",
    },
  ];

  const addDocumentFromUnitdata = [
    {
      document_name: "floorplan-type-A1.pdf",
      type: "Floorplan",
      date_of_upload: "23 Jan, 2023",
      uploaded_by: "Max Wildersans Brentford",
    },
  ];

  const addDocumentFromCasedata = [
    {
      document_name: "Jytte_salary-slip-2.pdf",
      type: "Salary slip",
      date_of_upload: "23 Jan, 2023",
      uploaded_by: "Max Wildersans Brentford",
    },
    {
      document_name: "Jytte-driver-license.jpg",
      type: "Identification",
      date_of_upload: "23 Jan, 2023",
      uploaded_by: "Tenant 2",
    },
    {
      document_name: "Lars-driver-license.jpg",
      type: "Identification",
      date_of_upload: "23 Jan, 2023",
      uploaded_by: "Tenant 1",
    },
    {
      document_name: "Jytte_passport.jpg",
      type: "Identification",
      date_of_upload: "23 Jan, 2023",
      uploaded_by: "Max Wildersans Brentford",
    },
  ];

  const addedDocumentcolumns = useMemo(
    () => [
      {
        accessorKey: "type",
        header: "Type",
        size: 50,
        Cell: ({ cell }) => (
          <>
            {/* {cell.getValue() === "edit" && (
              <FontAwesomeIcon
                icon="fa-regular fa-file-pen"
                className="fs-6 cursor-pointer"
                onClick={() => setOpenViewDocumentPopUp(true)}
              />
            )} */}
            {/* {cell.getValue() === "pin-pdf" && ( */}
            <FontAwesomeIcon icon="fa-regular fa-paperclip" className="fs-6" />
            {/* )} */}
          </>
        ),
      },
      {
        accessorKey: "title",
        header: "Name",
        size: 300,
        muiTableBodyCellProps: {
          sx: { cursor: "pointer !important" },
        },
      },
      {
        accessorKey: "document_type_name",
        header: "Document type",
      },
      {
        accessorKey: "source",
        header: "Source",
      },
      {
        accessorKey: "delete_document", //normal accessorKey
        header: "",
        enableSorting: false,
        size: 50,
        Cell: ({ cell }) => (
          <div>
            <Menus
              anchorEl={anchorEl}
              open={anchorEl}
              elevation={0}
              // anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              // transformOrigin={{ horizontal: "right", vertical: "top" }}
              itemheight={23}
              itemwidth={23}
              iconborderradius="4px"
              component={
                <>
                  <div className="light-font-text ">
                    <div
                      className="p-2 cursor-pointer addpropContent"
                      onClick={(event) => {
                        setOpenViewDocumentPopUp(true);
                        setDocumentDetails({
                          documentUrl: cell.row.original?.file_url,
                          documentName: cell.row.original?.title,
                        });
                      }}
                    >
                      View document
                    </div>
                    <hr className="hr m-0"></hr>

                    <div
                      onClick={() => {
                        // setDeleteDocument(cell.row.original?.is_manual);
                        // setDocumentId(cell.row.original?.document_id)
                        // setSigningOrder(cell.row.original?.signing_order_id)
                        if (cell.row.original?.is_manual !== 0) {
                          setDeleteManualDocument((prev) => ({
                            ...prev,
                            openDeletePopup: true,
                            signing_order_id:
                              cell.row.original?.signing_order_id,
                            document_id: cell.row.original?.document_id,
                          }));
                        }
                      }}
                      className={`p-2 ${
                        cell.row.original?.is_manual !== 0
                          ? "cursor-pointer"
                          : "cursor-not-allowed opacity-50"
                      }  addpropContent`}
                    >
                      Delete
                    </div>
                  </div>
                </>
              }
            />
          </div>
        ),
      },
    ],
    []
  );

  // Upload document manually Columns
  const uploadDocumentManuallyColumn = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
        Cell: ({ cell }) => {
          return (
            <div
              className="bg-white radius-6 d-flex align-items-center px-3"
              style={{ border: "1px solid #D1D1D1", height: "40px" }}
            >
              {cell.getValue()}
            </div>
          );
        },
      },
      // {
      //   accessorKey: "type",
      //   header: "Type",
      //   size: 180,

      //   Cell: ({ cell }) => {
      //     return (
      //       <ReactSelect
      //         placeholder=""
      //         defaultValue={{
      //           value: cell.getValue(),
      //           label: cell.getValue(),
      //         }}
      //         searchable={false}
      //         clearable={false}
      //       />
      //     );
      //   },
      // },
      {
        accessorKey: "document_type",
        header: "Document type",
        minSize: 100, //min size enforced during resizing
        maxSize: 200, //max size enforced during resizing
        size: 180, //medium column
        Cell: ({ cell }) => {
          return (
            <ReactSelect
              placeholder=""
              defaultValue={{
                value: cell.getValue(),
                label: cell.getValue(),
              }}
              searchable={false}
              clearable={false}
            />
          );
        },
      },
      {
        accessorKey: "delete_document",
        header: "",
        enableSorting: false,
        size: 180,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell }) => (
          <button
            className="added-document-delete-btn"
            onClick={() => deletePdf(cell.row.index, "droppedPdf")}
          >
            <FontAwesomeIcon icon="fa-regular fa-trash" className="fs-6" />
          </button>
        ),
      },
    ],
    []
  );

  const addDocumentForSigningColumn = useMemo(
    () => [
      {
        accessorKey: "document_name",
        header: "Document name",
        size: 180,
      },
      {
        accessorKey: "type",
        header: "Type",
      },
      {
        accessorKey: "date_of_upload",
        header: "Date of upload",
      },
      {
        accessorKey: "uploaded_by",
        header: "Uploaded by",
      },
    ],
    []
  );

  const addDocumentFromPackageColumns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 360,
      },
      {
        accessorKey: "document_type",
        header: "Document type",
        size: 360,
      },
    ],
    []
  );

  // deleting selected pdf from upload document manually modal
  const deletePdf = (deletePdfIndex, tableType) => {
    if (tableType === "droppedPdf") {
      setDroppedPdf((prev) => prev.filter((data, i) => i !== deletePdfIndex));
    } else if (tableType === "documentAddedPdf") {
      setAddedDocument((prev) =>
        prev.filter((data, i) => i !== deletePdfIndex)
      );
    }
  };

  // passing all selected pdf to Document tab
  const handleAddDocumentFromComputer = (data) => {
    let files = [];
    for (let i = 0; i < data?.droppedPdf?.length; i++) {
      let id;
      if (!PostUploadSigningOrderDocumentIsLoading) {
        id = toast.loading("Uploading file");
      }
      let formData = new FormData();
      formData.append("files[]", data?.droppedPdf?.[i]?.file);
      formData.append(
        "signing_order_id",
        isViewSigningOrder?.signingOrderData?.signing_order_id
      );
      formData.append(
        "contract_id",
        getSigningOrderDetailData?.data?.contract_id
      );
      formData.append(
        "document_type_id",
        data?.droppedPdf?.[i]?.document_type?.value
      );
      formData.append("property_id", propertyId);

      PostUploadSigningOrderDocument(formData).then(
        (response) => {
          if (response?.data?.bReturn) {
            files[i] = response?.data?.data?.[0];
            toast.update(id, {
              render: response?.data?.message,
              isLoading: false,
              type: status.s,
              autoClose: 2000,
            });
            getSigningOrderDetailDataRefetch();
            setOpenUploadDocumentManually(false);
            // remove(); // clearing dropped pdf state so every time new pdf will be added in upload document modal
          } else {
            toast.update(id, {
              render: "Failed to upload",
              isLoading: false,
              type: status.e,
              autoClose: 2000,
            });
          }
        },
        (error) => {
          // setError(true);
          // Some task on failure
          toast.update(id, {
            render: "Failed to upload",
            isLoading: false,
            type: status.e,
            autoClose: 2000,
          });
        }
      );
    }
  };

  const handleOrderOfSigningChange = (e) => {
    if (e.value !== "T") {
      setIsSigningBeforeDepositApprovedActive(false);
      setIsSigningManuallyApprovedActive(false);
    } else {
      setAddedDocument(getSigningOrderDetailData?.data?.documents);
      setIsSigningManuallyApprovedActive(
        getSigningOrderDetailData?.data?.signing_setting?.[0]
          ?.no_landlord_seller_signing_before_manual_approved === 1
          ? true
          : false
      );
    }
  };

  //-------------------Cancel signing order----------------------//
  const handleCancelSigningOrder = async (signingOrderId) => {
    const cancelSigningOrderPayload = {
      signing_order_id: String(signingOrderId),
    };
    try {
      const response = await PostCancelSigningOrder(cancelSigningOrderPayload);
      if (response.data.bReturn) {
        toast.success(response.data.message);
        setOpenCancelOrderPopup(false);
        getSigningOrderDetailDataRefetch();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  //-----------------------------------updateSignerQaApprovePayload------------------//
  const handleUpdateSigningQaApprove = (signing_order_person_id) => {
    const UpdateSigningQaApprove = {
      signing_order_person_id: String(signing_order_person_id),
      signing_order_id: String(
        isViewSigningOrder?.signingOrderData?.signing_order_id
      ),
    };
    PutUpdateSigningQaApproveStatus(UpdateSigningQaApprove)
      .then((response) => {
        if (response.data.bReturn) {
          getSigningOrderDetailDataRefetch();
          toast.success(response.data.message);
          getSigningOrderDetailDataRefetch();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => toast.error(error.message));
  };

  const handleSaveAndSendToSigning = (data) => {
    const updateSigningOrderPayload = {
      signing_order_id: String(
        isViewSigningOrder?.signingOrderData?.signing_order_id
      ),
      nof_landlord_seller_signers_required: String(data?.signerRequired?.value),
      no_landlord_seller_signing_before_deposit_received:
        isSigningBeforeDepositApprovedActive ? "1" : "0",
      no_landlord_seller_signing_before_manual_approved:
        isSigningManuallyApprovedActive ? "1" : "0",
      tenant_buyer_reminder_interval: String(data?.tenantBuyerReminder?.value),
      landlord_seller_reminder_interval: String(
        data?.landLordSellerReminder?.value
      ),
      tenant_buyer_signing_duration: String(data?.tenantBuyerDuration?.value),
      landlord_seller_signing_duration: String(
        data?.landLordSellerDuration?.value
      ),
      nof_qa_approvers_required: String(data?.noOfQa?.value),
      order_of_signings: String(data?.orderOfSigning?.value),
      reminder_start_date: data?.reminderStarts,
      reminder_interval: String(data?.reminderInterval?.value),
      common_note: data?.commonNote,
    };

    const sendToSigningPayload = {
      signing_order_id: String(
        isViewSigningOrder?.signingOrderData?.signing_order_id
      ),
    };
    PutUpdateSigningOrder(updateSigningOrderPayload).then((response) => {
      if (response.data.bReturn) {
        if (!isSendToSigningClicked) {
          toast.success(response.data.message);
          getSigningOrderDetailDataRefetch();
          // setViewSigningOrder({
          //   showSigningPage: false,
          //   signingOrderData: "",
          // });
        }
        if (isSendToSigningClicked) {
          let id;
          if (!PostSigningSendToSigningIsLoading) {
            id = toast.loading("Sending signing order");
          }
          //API for send to signing after saving data
          PostSigningSendToSigning(sendToSigningPayload)
            .then((response) => {
              if (response.data.bReturn) {
                setOpenSendToSigning(true);
                toast.update(id, {
                  render: response?.data?.message,
                  isLoading: false,
                  type: "success",
                  autoClose: 2000,
                });
              } else {
                toast.update(id, {
                  render: response?.data?.message,
                  isLoading: false,
                  type: "error",
                  autoClose: 2000,
                });
              }
            })
            .catch((error) => {
              toast.update(id, {
                render: "Failed to send",
                isLoading: false,
                type: "error",
                autoClose: 2000,
              });
            });
        }
      }
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(handleSaveAndSendToSigning)}>
        <div
          style={{ backgroundColor: "#F9FAFA" }}
          className="signing-view_signing-page pt-3 light-font-text"
        >
          <H1>Signing</H1>
          <div className="mb-3 pb-2">
            {/* {isEditMediaPackage && ( */}
            <div>
              <Link
                to="/properties"
                style={{ textDecoration: "none", color: "black" }}
              >
                <span className="me-3">Properties</span>
              </Link>
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />
              </span>
              <Link
                to="/property-units/overview"
                state={{ Tab: "Units" }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <span className="me-3">{propOverview?.[0]?.propertyName}</span>
              </Link>
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />
              </span>

              {/* )} */}
              <Link
                to="/property-units/units/residential"
                state={{ Tab: "Units" }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <span className="me-3">Units</span>
              </Link>
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />
              </span>
              <Link
                to="/property-units-edit/overview"
                state={{ Tab: "Units", unit_id: unitId }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <span className="me-3">
                  {" "}
                  {unitOverviewData?.unit_calculated_number},{" "}
                  {unitOverviewData?.Address?.slice(0, -6)}
                </span>
              </Link>
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />
              </span>
              <Link
                to="/property-units-edit/contracts"
                state={{ Tab: "Units", unit_id: unitId }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <span className="me-3">Contracts</span>
              </Link>
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />
              </span>

              {/* <span className="me-3">Jesper og Lotte</span>
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />
              </span> */}
              <span
                className="me-3 cursor-pointer"
                onClick={() =>
                  setViewSigningOrder({
                    showSigningPage: false,
                    signingOrderData: "",
                  })
                }
              >
                Signing
              </span>
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />
              </span>
              <span className="me-3">
                {getSigningOrderDetailData?.data
                  ? getSigningOrderDetailData?.data?.contract_name
                  : "Tanent Name"}
              </span>
              <span className="me-3">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />
              </span>
              <GreenSpan className="me-3">Tenancy agreement v1.1</GreenSpan>
            </div>
          </div>
          <>
            {!openSendToSigning ? (
              <div
                className="pb-2 radius-8 containerStyle"
                // style={{ backgroundColor: "#F1F5F0" }}
              >
                <div
                  className="d-flex gap-2 px-4"
                  style={{ alignItems: "center", textAlign: "center" }}
                ></div>
                <div
                  className="m-4 overflow-hidden bg-white radius-8 fs-14"
                  style={{
                    border: "1px solid #F0F0F0",
                  }}
                >
                  <div
                    className="px-4 py-3 d-flex flex-wrap gap-2 align-items-center justify-content-between"
                    style={{ borderBottom: "1px solid #F0F0F0" }}
                  >
                    <div>
                      <div className="d-flex align-items-center">
                        <H2>
                          {isViewSigningOrder?.signingOrderData?.order_name}
                        </H2>
                        <div className="ps-2">
                          <CustomToolTip
                            size=""
                            text={
                              isViewSigningOrder?.signingOrderData?.order_name
                            }
                          />
                        </div>
                      </div>

                      <div
                        className="created-by fs-12"
                        style={{ color: "#000000" }}
                      >
                        Esignatur order id&nbsp;
                        {getSigningOrderDetailData?.data?.esignatur_order_id
                          ? `# ${getSigningOrderDetailData?.data?.esignatur_order_id}`
                          : "- Not sent for signing"}
                      </div>
                      <div className="created-by">
                        {isViewSigningOrder?.signingOrderData?.modified_by &&
                        isViewSigningOrder?.signingOrderData?.modified_on ? (
                          <>
                            {" "}
                            Last updated by{" "}
                            {
                              isViewSigningOrder?.signingOrderData?.modified_by
                            },{" "}
                            {moment(
                              isViewSigningOrder?.signingOrderData?.modified_on
                            ).format("DD. MMM. YYYY [|] HH:mm:ss")}
                          </>
                        ) : (
                          <>
                            {isViewSigningOrder?.signingOrderData?.created_by &&
                            isViewSigningOrder?.signingOrderData?.created_on ? (
                              <>
                                Created by{" "}
                                {
                                  isViewSigningOrder?.signingOrderData
                                    ?.created_by
                                }
                                ,{" "}
                                {moment(
                                  isViewSigningOrder?.signingOrderData
                                    ?.created_on
                                ).format("DD. MMM. YYYY [|] HH:mm:ss")}
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                      {/* <Link
                    target={"_blank"}
                    to="https://millicent-test.b-cdn.net/doc/units/100173453893767318/ta_647092eabc451.pdf"
                  >
                    <ETButton
           variant={"primary"}
                      buttonText="TENANCY AGREEMENT"

                      icon={false}
   
                      width="fit-content"

                      onClick={() => {
                        //    setOpenUploadDocumentManually(true);
                      }}
                    />
                  </Link> */}

                      {getSigningOrderDetailData?.data?.is_cancelled === 0 && (
                        <>
                          <ETButton
                            disabled={PostCancelSigningOrderIsLoading}
                            variant={"secondary"}
                            buttonText="CANCEL ORDER"
                            width="100%"
                            onClick={() => {
                              setOpenCancelOrderPopup(true);
                            }}
                          />

                          <ETButton
                            buttonText="SAVE"
                            disabled={
                              getSigningOrderDetailData?.data
                                ?.esignatur_order_id !== "" ||
                              PutUpdateSigningOrderIsLoading ||
                              PostSigningSendToSigningIsLoading
                            }
                            variant={"primary"}
                            icon={false}
                            width="fit-content"
                            type="submit"
                            onClick={() => setIsSendToSigningClicked(false)}
                          />

                          <ETButton
                            disabled={
                              getSigningOrderDetailData?.data?.approved_on ==
                                "" ||
                              getSigningOrderDetailData?.data
                                ?.esignatur_order_id !== "" ||
                              PostSigningSendToSigningIsLoading
                            }
                            variant={"primary"}
                            buttonText="SEND TO SIGNING"
                            icon={false}
                            width="fit-content"
                            type="submit"
                            onClick={() => setIsSendToSigningClicked(true)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="p-4">
                    <div
                      className="radius-8"
                      style={{
                        border: "1px solid #F0F0F0",
                        background: "#F9FAFA",
                      }}
                    >
                      <div
                        className="d-flex justify-content-between align-items-center py-3 px-4"
                        style={{ borderBottom: "1px solid #F0F0F0" }}
                      >
                        <p className="mb-0 actors-section-title">Documents</p>

                        {/* document menu */}

                        <ETButton
                          disabled={
                            getSigningOrderDetailData?.data?.approved_on !==
                              "" &&
                            getSigningOrderDetailData?.data
                              ?.esignatur_order_id !== ""
                          }
                          variant={"secondary"}
                          buttonText="ADD DOCUMENT"
                          rotate={rotate}
                          icon={true}
                          onClick={handleAddPropertyDropDown}
                          width="max-content"
                        />

                        <Menu
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          anchorEl={anchorEl}
                          open={anchorEl}
                          onClose={handleClosePropertyDropDown}
                          PaperProps={{
                            style: {
                              borderRadius: "8px",
                              marginTop: "6px",
                              border: "0.5px solid #F0F0F0",
                              boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.12)",
                              paddingTop: "0px",
                            },
                          }}
                          sx={{
                            "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                              padding: 0,
                              borderColor: "##F0F0F0",
                            },
                          }}
                        >
                          <div
                            className="addpropertyCountryHandle "
                            style={{ fontSize: "14px", width: "198px" }}
                          >
                            <div
                              className="addpropContent px-3 py-2"
                              onClick={OpenPopUpDocumentfromComputerPackage}
                            >
                              <span
                                className="ms-1"
                                style={{
                                  fontFamily: "Cerebri sans",
                                  fontSize: "14px",
                                  fontweight: "300",
                                  alignItems: "center",
                                }}
                              >
                                From computer
                              </span>
                            </div>
                            <div
                              className="addpropContent px-3 py-2"
                              onClick={OpenPopUpDocumentPackage}
                            >
                              <span
                                className="ms-1"
                                style={{
                                  fontFamily: "Cerebri sans",
                                  fontSize: "14px",
                                  fontweight: "300",
                                  alignItems: "center",
                                }}
                              >
                                From document package
                              </span>
                            </div>
                            {/* <div
                          className="addpropContent px-3 py-2"
                          onClick={OpenPopUpDocumentUnit}
                        >
                          <span
                            className="ms-1"
                            style={{
                              fontFamily: "Cerebri sans",
                              fontSize: "14px",
                              fontweight: "300",
                              alignItems: "center",
                            }}
                          >
                            From unit
                          </span>
                        </div>
                        <div
                          className="addpropContent px-3 py-2"
                          onClick={OpenPopUpDocumentCase}
                        >
                          <span
                            className="ms-1"
                            style={{
                              fontFamily: "Cerebri sans",
                              fontSize: "14px",
                              fontweight: "300",
                              alignItems: "center",
                            }}
                          >
                            From case
                          </span>
                        </div> */}
                          </div>
                        </Menu>
                      </div>
                      <div className="py-3  px-4">
                        <MRTTheme
                          colorVariantsForTableHead="gray"
                          colorVariantsForTableData={["white"]}
                          table={
                            <MaterialReactTable
                              columns={addedDocumentcolumns}
                              data={addedDocument || []}
                              icons={{ ...SortIcon }}
                              muiTableBodyRowProps={({ row }) => ({
                                sx: {
                                  borderRadius: "8px !important",
                                  border: "1px solid black !important",
                                },
                              })}
                              muiTableBodyCellProps={({ cell, row }) => ({
                                onClick: (event) => {
                                  if (cell.id.includes("title")) {
                                    setOpenViewDocumentPopUp(true);
                                    setDocumentDetails({
                                      documentUrl: row.original?.file_url,
                                      documentName: row.original?.title,
                                    });
                                  }
                                },
                              })}
                              enableTopToolbar={false}
                              enableColumnActions={false}
                              enablePagination={false}
                              enableBottomToolbar={false}
                              muiTableContainerProps={{
                                sx: {
                                  padding: "0px !important",
                                },
                              }}
                              muiTablePaperProps={{
                                sx: {
                                  table: {
                                    borderCollapse: "separate",
                                    borderSpacing: "0 5px",
                                  },
                                },
                              }}
                              // muiTablePaperProps={{
                              //   elevation: 0,
                              //   sx: {
                              //     fontFamily: "Cerebri sans !important",
                              //     borderRadius: "0",
                              //     border: "none",
                              //     background: "#F9FAFA",
                              //     fontWeight: "300",
                              //     ".css-1f2qhs8": {
                              //       minWidth: "auto",
                              //       marginRight: "10px",
                              //     },
                              //     th: {
                              //       height: "50px",
                              //       padding: "12px",
                              //       background: "#F9FAFA",
                              //       paddingLeft: "1rem",
                              //     },
                              //     td: {
                              //       height: "50px",
                              //       fontFamily: "Cerebri sans !important",
                              //       padding: "12px",
                              //       fontWeight: "300",
                              //       fontSize: "14px",
                              //     },
                              //     tr: {
                              //       borderRadius: "8px",
                              //     },
                              //     ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
                              //       display: "none", // pagination space removing from page
                              //     },
                              //   },
                              // }}
                              // muiTableBodyProps={{
                              //   sx: {
                              //     "& tr:nth-of-type(even)": {
                              //       backgroundColor: "#F9FAFA",
                              //       borderRadius: "8px",
                              //       fontWeight: "300",
                              //     },
                              //   },
                              // }}
                              // muiTableHeadCellProps={{
                              //   //simple styling with the `sx` prop, works just like a style prop in this example
                              //   sx: {
                              //     fontWeight: "600",
                              //     fontSize: "12px",
                              //     fontFamily: "Cerebri sans !important",
                              //   },
                              // }}
                              displayColumnDefOptions={{
                                "mrt-row-actions": {
                                  header: "",
                                },
                                // "mrt-row-select": {
                                //   header: "",
                                // },
                              }}
                              // muiTableContainerProps={{
                              //   elevation: 0,
                              //   sx: {
                              //     background: "#F9FAFA !important",

                              //     borderRadius: "0px 0px 8px 8px ",

                              //     boxShadow: "none",
                              //     "MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium css-1gbcdvd-MuiTableCell-root":
                              //     {
                              //       fontWeight: "700 !important",
                              //     },
                              //     ".css-vv34gr-MuiTableRow-root": {
                              //       boxShadow: "unset !important",
                              //     },
                              //   },
                              // }}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 pb-4">
                    <div
                      className="radius-8"
                      style={{
                        border: "1px solid #F0F0F0",
                        background: "#F9FAFA",
                      }}
                    >
                      <div
                        className="d-flex justify-content-between align-items-center py-3 px-4"
                        style={{ borderBottom: "1px solid #F0F0F0" }}
                      >
                        <p className="mb-0 actors-section-title">Settings</p>
                      </div>
                      <div className="p-4">
                        <Row>
                          <Col xl={6} className="mb-3">
                            <Row className=" pb-1 d-flex ">
                              <Col
                                className="pe-lg-0 form-label-title  pt-2"
                                lg={6}
                              >
                                <div>Tenant/Buyer Duration</div>
                              </Col>
                              <Col className="ps-lg-0" lg={6}>
                                <Controller
                                  {...register(`tenantBuyerDuration`)}
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <ReactSelect
                                      disabled={
                                        getSigningOrderDetailData?.data
                                          ?.is_cancelled === 1 ||
                                        (getSigningOrderDetailData?.data
                                          ?.esignatur_order_id !== "" &&
                                          true)
                                      }
                                      options={Days?.map((item) => ({
                                        value: item.value,
                                        label: item.label,
                                      }))}
                                      placeholder={"Days"}
                                      name={"tenantBuyerDuration"}
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        clearErrors("tenantBuyerDuration");
                                      }}
                                      clearable={false}
                                    />
                                  )}
                                />
                                {errors.tenantBuyerDuration && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col className="mb-3" xl={6}>
                            <Row className="pb-1 d-flex align-items-center">
                              <Col className="pe-lg-0  form-label-title" lg={6}>
                                LandLord/Seller Duration
                              </Col>
                              <Col className="ps-lg-0" lg={6}>
                                <Controller
                                  {...register(`landLordSellerDuration`)}
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <ReactSelect
                                      disabled={
                                        getSigningOrderDetailData?.data
                                          ?.is_cancelled === 1 ||
                                        (getSigningOrderDetailData?.data
                                          ?.esignatur_order_id !== "" &&
                                          true)
                                      }
                                      // innerLabel={true}
                                      options={Days?.map((item) => ({
                                        value: item.value,
                                        label: item.label,
                                      }))}
                                      placeholder={"Days"}
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        clearErrors("landLordSellerDuration");
                                      }}
                                      clearable={false}
                                    />
                                  )}
                                />
                                {errors.landLordSellerDuration && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={6} className="mb-3">
                            <Row className="pb-1 d-flex ">
                              <Col
                                className="pe-lg-0 form-label-title pt-2"
                                lg={6}
                              >
                                Tenant/Buyer Reminder Interval
                              </Col>
                              <Col className="ps-lg-0" lg={6}>
                                <Controller
                                  {...register(`tenantBuyerReminder`)}
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <ReactSelect
                                      disabled={
                                        getSigningOrderDetailData?.data
                                          ?.is_cancelled === 1 ||
                                        (getSigningOrderDetailData?.data
                                          ?.esignatur_order_id !== "" &&
                                          true)
                                      }
                                      options={Days?.map((item) => ({
                                        value: item.value,
                                        label: item.label,
                                      }))}
                                      // innerLabel={true}
                                      placeholder={"Days"}
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        clearErrors("tenantBuyerReminder");
                                      }}
                                      clearable={false}
                                    />
                                  )}
                                />
                                {errors.tenantBuyerReminder && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col className="mb-3" xl={6}>
                            <Row className="pb-1 d-flex align-items-center">
                              <Col className="pe-lg-0 form-label-title" lg={6}>
                                LandLord/Seller Reminder Interval
                              </Col>
                              <Col className="ps-lg-0" lg={6}>
                                <Controller
                                  {...register(`landLordSellerReminder`)}
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <ReactSelect
                                      disabled={
                                        getSigningOrderDetailData?.data
                                          ?.is_cancelled === 1 ||
                                        (getSigningOrderDetailData?.data
                                          ?.esignatur_order_id !== "" &&
                                          true)
                                      }
                                      // innerLabel={true}
                                      placeholder={"No of Signers"}
                                      options={Days?.map((item) => ({
                                        value: item.value,
                                        label: item.label,
                                      }))}
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        clearErrors("landLordSellerReminder");
                                      }}
                                      clearable={false}
                                    />
                                  )}
                                />
                                {errors.landLordSellerReminder && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                            </Row>{" "}
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={6} className="mb-3">
                            <Row className="pb-1 d-flex ">
                              <Col
                                className="pe-lg-0 form-label-title pt-2"
                                lg={6}
                              >
                                Order of Signing
                              </Col>
                              <Col className="ps-lg-0" lg={6}>
                                <Controller
                                  {...register(`orderOfSigning`)}
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <ReactSelect
                                      disabled={
                                        getSigningOrderDetailData?.data
                                          ?.is_cancelled === 1 ||
                                        (getSigningOrderDetailData?.data
                                          ?.esignatur_order_id !== "" &&
                                          true)
                                      }
                                      // innerLabel={true}
                                      placeholder={"Order of Signing"}
                                      value={value}
                                      options={OrderOfSignings?.map((item) => ({
                                        value: item.value,
                                        label: item.label,
                                      }))}
                                      onChange={(e) => {
                                        onChange(e);
                                        handleOrderOfSigningChange(e);
                                        clearErrors("orderOfSigning");
                                      }}
                                      clearable={false}
                                    />
                                  )}
                                />
                                {errors.orderOfSigning && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                            </Row>{" "}
                          </Col>
                          <Col className="mb-3" xl={6}>
                            <Row className=" pb-1 d-flex align-items-center">
                              <Col className="pe-lg-0 form-label-title" lg={6}>
                                No of LandLord/Seller Signers Required
                              </Col>
                              <Col className="ps-lg-0" lg={6}>
                                <Controller
                                  {...register(`signerRequired`)}
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <ReactSelect
                                      disabled={
                                        getSigningOrderDetailData?.data
                                          ?.is_cancelled === 1 ||
                                        (getSigningOrderDetailData?.data
                                          ?.esignatur_order_id !== "" &&
                                          true)
                                      }
                                      // innerLabel={true}
                                      placeholder={"No of Signers"}
                                      options={NoforSigningRequired?.map(
                                        (item) => ({
                                          value: item.value,
                                          label: item.label,
                                        })
                                      )}
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        clearErrors("signerRequired");
                                      }}
                                      clearable={false}
                                    />
                                  )}
                                />
                                {errors.signerRequired && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={6} className="mb-3">
                            <Row className=" pb-1 d-flex align-items-center">
                              <Col className="pe-lg-0  form-label-title" lg={6}>
                                No of QA approver required
                              </Col>
                              <Col className="ps-lg-0" lg={6}>
                                <Controller
                                  {...register("noOfQa")}
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <ReactSelect
                                      disabled={
                                        getSigningOrderDetailData?.data
                                          ?.is_cancelled === 1 ||
                                        (getSigningOrderDetailData?.data
                                          ?.esignatur_order_id !== "" &&
                                          true)
                                      }
                                      placeholder={"No of QA"}
                                      options={NoOfQaApprover?.map((item) => ({
                                        value: item.value,
                                        label: item.label,
                                      }))}
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        clearErrors("noOfQa");
                                      }}
                                      clearable={false}
                                    />
                                  )}
                                />
                                {errors.noOfQa && (
                                  <p className="error-msg">
                                    This field is required
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={6} className="mb-3">
                            <Row className="pb-1 d-flex ">
                              <Col
                                className="pe-lg-0 form-label-title d-flex align-items-center"
                                sm={9}
                              >
                                LandLord/Seller Signing Manually Approved
                              </Col>
                              <Col lg={3}>
                                <Toggle
                                  id={"tenancy_landLord_seller"}
                                  disabled={
                                    getValues("orderOfSigning")?.value !==
                                      "T" ||
                                    getSigningOrderDetailData?.data
                                      ?.esignatur_order_id !== ""
                                      ? true
                                      : false
                                  }
                                  checked={isSigningManuallyApprovedActive}
                                  onChange={(e) =>
                                    setIsSigningManuallyApprovedActive(
                                      e.target.checked
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={6} className="mb-3">
                            <Row className="pb-1 d-flex ">
                              <Col
                                className="pe-lg-0 form-label-title d-flex align-items-center"
                                sm={9}
                              >
                                LandLord/Seller Signing Before Deposit Recieved
                              </Col>
                              <Col lg={3}>
                                <Toggle
                                  id={"tenancy_signing_before_deposit"}
                                  disabled={
                                    getValues("orderOfSigning")?.value !==
                                      "T" ||
                                    getSigningOrderDetailData?.data
                                      ?.esignatur_order_id !== ""
                                      ? true
                                      : false
                                  }
                                  checked={isSigningBeforeDepositApprovedActive}
                                  onChange={(e) =>
                                    setIsSigningBeforeDepositApprovedActive(
                                      e.target.checked
                                    )
                                  }
                                />
                              </Col>
                            </Row>{" "}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div className="px-4 pb-4">
                    <div
                      className="radius-8"
                      style={{
                        border: "1px solid #F0F0F0",
                        background: "#F9FAFA",
                      }}
                    >
                      <div
                        className="d-flex justify-content-between align-items-center py-3 px-4"
                        style={{ borderBottom: "1px solid #F0F0F0" }}
                      >
                        <p className="mb-0  actors-section-title">Signers</p>

                        {getSigningOrderDetailData?.data?.is_cancelled !==
                          1 && (
                          <ETButton
                            disabled={
                              getSigningOrderDetailData?.data?.approved_on !==
                                "" &&
                              getSigningOrderDetailData?.data
                                ?.esignatur_order_id !== ""
                            }
                            buttonText="ADD EXTRA PERSON"
                            variant={"secondary"}
                            width="max-content"
                            icon={true}
                            onClick={OpenPopUpAddExtraPerson}
                          />
                        )}
                      </div>
                      <div className="p-4">
                        <div className="signing-page-customers-table">
                          <div className="mb-2">
                            <div className="d-flex flex-wrap gap-2">
                              <p className="mb-0 form-label-title">
                                QA approver
                              </p>

                              <span
                                className="form-label-title fs-12 radius-4 px-2 d-flex align-items-center"
                                style={{ background: "#D1D1D1" }}
                              >
                                {getValues("noOfQa")?.value === 0
                                  ? "NO APPROVER REQUIRED"
                                  : `${
                                      getValues("noOfQa")?.value
                                    } PEOPLE NEED TO APPROVE`}
                              </span>
                            </div>
                          </div>
                          <div className="mt-2">
                            <MRTTheme
                              colorVariantsForTableHead="gray"
                              colorVariantsForTableData={["white"]}
                              table={
                                <MaterialReactTable
                                  columns={qaApproverColumns}
                                  data={
                                    getSigningOrderDetailData?.data?.signing_order_person?.filter(
                                      (el) => el?.is_qa_approver == 1
                                    ) || []
                                  }
                                  icons={{ ...SortIcon }}
                                  enableTopToolbar={false}
                                  enableColumnActions={false}
                                  enablePagination={false}
                                  enableBottomToolbar={false}
                                  enableRowActions
                                  positionActionsColumn="last"
                                  // enableRowSelection={false}
                                  // enableSelectAll={false}
                                  displayColumnDefOptions={{
                                    "mrt-row-actions": {
                                      header: "",
                                    },
                                    "mrt-row-select": {
                                      header: "",
                                    },
                                  }}
                                  renderRowActions={({ row }) => (
                                    <>
                                      {row?.original?.user_id ==
                                      userDetails?.user_id ? (
                                        <div className="d-flex gap-2">
                                          <button
                                            type="button"
                                            className="qa-icon-btn  gap-2"
                                            style={{
                                              background: "#619130",
                                              opacity:
                                                row?.original?.approved_on ===
                                                ""
                                                  ? "inherit"
                                                  : 0.5,
                                            }}
                                            disabled={
                                              row?.original?.approved_on !==
                                                "" && true
                                            }
                                            onClick={() =>
                                              handleUpdateSigningQaApprove(
                                                row?.original
                                                  ?.signing_order_person_id
                                              )
                                            }
                                          >
                                            <label for="files" class="button">
                                              <FontAwesomeIcon
                                                size="sm"
                                                icon="fa-regular fa-circle-check"
                                                style={{ color: "#ffffff" }}
                                              />
                                            </label>
                                            <span className="fs-13 ">
                                              <span className="pt-1">
                                                {row?.original?.approved_on ===
                                                ""
                                                  ? "APPROVE"
                                                  : "APPROVED"}
                                              </span>
                                            </span>
                                          </button>
                                        </div>
                                      ) : (
                                        " "
                                      )}
                                    </>
                                  )}
                                  // muiTableHeadCellProps={{
                                  //   sx: {
                                  //     backgroundColor: "red",
                                  //   },
                                  // }}

                                  muiTableContainerProps={{
                                    sx: {
                                      padding: "0px !important",
                                    },
                                  }}
                                  // muiTablePaperProps={{
                                  //   elevation: 0,
                                  //   sx: {
                                  //     fontFamily: "Cerebri sans !important",
                                  //     borderRadius: "0",
                                  //     border: "none",
                                  //     background: "#F9FAFA",
                                  //     fontWeight: "300",
                                  //     ".css-1f2qhs8": {
                                  //       minWidth: "auto",
                                  //       marginRight: "10px",
                                  //     },
                                  //     thead: {
                                  //       // display: "none",
                                  //     },
                                  //     th: {
                                  //       height: "30px",
                                  //       padding: "0.5rem",
                                  //       background: "#F9FAFA",
                                  //       fontFamily: "Cerebri sans !important",
                                  //       // boxShadow: "none",

                                  //       // display: "none",
                                  //     },
                                  //     td: {
                                  //       fontFamily: "Cerebri sans !important",
                                  //       height: "50px",
                                  //       padding: "0.5rem",
                                  //       fontWeight: "300",
                                  //       fontSize: "14px",
                                  //       border: " 1px solid #F0F0F0",
                                  //     },
                                  //     tr: {
                                  //       borderRadius: "4px",
                                  //     },
                                  //     ".MuiToolbar-dense.css-g2tram-MuiToolbar-root":
                                  //     {
                                  //       display: "none", // pagination space removing from page
                                  //     },
                                  //   },
                                  // }}
                                  // muiTableBodyProps={{
                                  //   sx: {
                                  //     // maxHeight: "300px",
                                  //     fontFamily: "Cerebri sans !important",
                                  //     // "& tr:nth-of-type(even)": {
                                  //     //   backgroundColor: "#F9FAFA",
                                  //     //   border: "1px solid #F0F0F0 !important",
                                  //     // },
                                  //   },
                                  // }}
                                  // muiTableContainerProps={{
                                  //   elevation: 0,
                                  //   sx: {
                                  //     background: "#F9FAFA !important",

                                  //     borderRadius: "0px 0px 8px 8px ",

                                  //     boxShadow: "none",
                                  //     "MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium css-1gbcdvd-MuiTableCell-root":
                                  //     {
                                  //       fontWeight: "700 !important",
                                  //     },
                                  //     ".css-vv34gr-MuiTableRow-root": {
                                  //       boxShadow: "unset !important",
                                  //     },
                                  //   },
                                  // }}
                                  // muiTableHeadCellProps={{
                                  //   //simple styling with the `sx` prop, works just like a style prop in this example
                                  //   sx: {
                                  //     fontWeight: "700",
                                  //     fontSize: "12px",
                                  //     fontFamily: "Cerebri sans !important",
                                  //   },
                                  // }}
                                />
                              }
                            />
                          </div>
                        </div>
                        <div className="mt-4 signing-page-customers-table">
                          <div className="mb-2">
                            <div className="d-flex flex-wrap gap-2">
                              <p className="mb-0 form-label-title">
                                Landlord/Seller
                              </p>

                              <span
                                className="form-label-title fs-12 radius-4 px-2 d-flex align-items-center"
                                style={{ background: "#D1D1D1" }}
                              >
                                {getValues("signerRequired")?.label === "All"
                                  ? "EVERYONE NEED TO SIGN"
                                  : `${
                                      getValues("signerRequired")?.label
                                    } PEOPLE NEED TO SIGN`}
                              </span>
                            </div>
                          </div>
                          <div className="mt-2">
                            <MRTTheme
                              colorVariantsForTableHead="gray"
                              colorVariantsForTableData={["white"]}
                              table={
                                <MaterialReactTable
                                  columns={signerColumns}
                                  data={
                                    getSigningOrderDetailData?.data?.signing_order_person?.filter(
                                      (el) => el?.user_type == "L"
                                    ) || []
                                  }
                                  icons={{ ...SortIcon }}
                                  enableTopToolbar={false}
                                  enableColumnActions={false}
                                  enablePagination={false}
                                  enableBottomToolbar={false}
                                  // enableRowSelection={false}
                                  // enableSelectAll={false}
                                  displayColumnDefOptions={{
                                    "mrt-row-actions": {
                                      header: "",
                                    },
                                    "mrt-row-select": {
                                      header: "",
                                    },
                                  }}
                                  muiTableContainerProps={{
                                    sx: {
                                      padding: "0px !important",
                                    },
                                  }}
                                  // muiTablePaperProps={{
                                  //   elevation: 0,
                                  //   sx: {
                                  //     fontFamily: "Cerebri sans !important",
                                  //     borderRadius: "0",
                                  //     border: "none",
                                  //     background: "#F9FAFA",
                                  //     fontWeight: "300",
                                  //     ".css-1f2qhs8": {
                                  //       minWidth: "auto",
                                  //       marginRight: "10px",
                                  //     },
                                  //     thead: {
                                  //       // display: "none",
                                  //     },
                                  //     th: {
                                  //       height: "30px",
                                  //       padding: "0.5rem",
                                  //       background: "#F9FAFA",
                                  //       fontFamily: "Cerebri sans !important",
                                  //       // boxShadow: "none",

                                  //       // display: "none",
                                  //     },
                                  //     td: {
                                  //       fontFamily: "Cerebri sans !important",
                                  //       height: "50px",
                                  //       padding: "0.5rem",
                                  //       fontWeight: "300",
                                  //       fontSize: "14px",
                                  //       border: " 1px solid #F0F0F0",
                                  //     },
                                  //     tr: {
                                  //       borderRadius: "4px",
                                  //     },
                                  //     ".MuiToolbar-dense.css-g2tram-MuiToolbar-root":
                                  //     {
                                  //       display: "none", // pagination space removing from page
                                  //     },
                                  //   },
                                  // }}
                                  // muiTableBodyProps={{
                                  //   sx: {
                                  //     // maxHeight: "300px",
                                  //     fontFamily: "Cerebri sans !important",
                                  //     // "& tr:nth-of-type(even)": {
                                  //     //   backgroundColor: "#F9FAFA",
                                  //     //   border: "1px solid #F0F0F0 !important",
                                  //     // },
                                  //   },
                                  // }}
                                  // muiTableContainerProps={{
                                  //   elevation: 0,
                                  //   sx: {
                                  //     background: "#F9FAFA !important",

                                  //     borderRadius: "0px 0px 8px 8px ",

                                  //     boxShadow: "none",
                                  //     "MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium css-1gbcdvd-MuiTableCell-root":
                                  //     {
                                  //       fontWeight: "700 !important",
                                  //     },
                                  //     ".css-vv34gr-MuiTableRow-root": {
                                  //       boxShadow: "unset !important",
                                  //     },
                                  //   },
                                  // }}
                                  // muiTableHeadCellProps={{
                                  //   //simple styling with the `sx` prop, works just like a style prop in this example
                                  //   sx: {
                                  //     fontWeight: "700",
                                  //     fontSize: "12px",
                                  //     fontFamily: "Cerebri sans !important",
                                  //   },
                                  // }}
                                />
                              }
                            />
                          </div>
                        </div>
                        <div className="mt-4 signing-page-customers-table">
                          <div className="mb-1">
                            <div className="d-flex flex-wrap gap-2">
                              <p className="mb-0 form-label-title">
                                Customer/Buyer/Tenant
                              </p>
                              <span
                                className="form-label-title fs-12 radius-4 d-flex align-items-center px-2"
                                style={{ background: "#D1D1D1" }}
                              >
                                EVERYONE NEED TO SIGN
                              </span>
                            </div>
                          </div>
                          <div>
                            <MRTTheme
                              colorVariantsForTableHead="gray"
                              colorVariantsForTableData={["white"]}
                              table={
                                <MaterialReactTable
                                  columns={signerCustomerColumns}
                                  data={
                                    getSigningOrderDetailData?.data?.signing_order_person?.filter(
                                      (el) => el?.user_type == "T"
                                    ) || []
                                  }
                                  icons={{ ...SortIcon }}
                                  enableTopToolbar={false}
                                  enableColumnActions={false}
                                  enablePagination={false}
                                  enableBottomToolbar={false}
                                  // enableRowSelection={true}
                                  // enableSelectAll={false}
                                  // muiTableContainerProps={{
                                  //   sx: { maxHeight: "300px" },
                                  // }}

                                  muiTableContainerProps={{
                                    sx: {
                                      padding: "0px !important",
                                    },
                                  }}
                                  // muiTablePaperProps={{
                                  //   elevation: 0,
                                  //   sx: {
                                  //     fontFamily: "Cerebri sans !important",
                                  //     borderRadius: "0",
                                  //     border: "none",
                                  //     background: "#F9FAFA",
                                  //     fontWeight: "300",
                                  //     ".css-1f2qhs8": {
                                  //       minWidth: "auto",
                                  //       marginRight: "10px",
                                  //     },
                                  //     th: {
                                  //       height: "30px",
                                  //       padding: "0.5rem",
                                  //       background: "#F9FAFA",
                                  //     },

                                  //     td: {
                                  //       height: "50px",
                                  //       padding: "12px",
                                  //       fontWeight: "300",
                                  //       fontSize: "14px",
                                  //       fontFamily: "Cerebri sans !important",
                                  //     },
                                  //     tr: {
                                  //       borderRadius: "8px",
                                  //       border: "1px solid red !important",
                                  //     },
                                  //     ".MuiToolbar-dense.css-g2tram-MuiToolbar-root":
                                  //     {
                                  //       display: "none", // pagination space removing from page
                                  //     },
                                  //   },
                                  // }}
                                  // muiTableBodyRowProps={{
                                  //   sx: {
                                  //     border: "1px solid red !important",
                                  //   },
                                  // }}
                                  // muiTableBodyProps={{
                                  //   sx: {
                                  //     fontFamily: "Cerebri sans !important",
                                  //   },
                                  // }}
                                  // muiTableHeadCellProps={{
                                  //   //simple styling with the `sx` prop, works just like a style prop in this example
                                  //   sx: {
                                  //     fontWeight: "700",
                                  //     fontSize: "12px",
                                  //     fontFamily: "Cerebri sans !important",
                                  //   },
                                  // }}
                                  // muiTableContainerProps={{
                                  //   elevation: 0,
                                  //   sx: {
                                  //     background: "#F9FAFA !important",

                                  //     borderRadius: "0px 0px 8px 8px ",

                                  //     boxShadow: "none",
                                  //     "MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium css-1gbcdvd-MuiTableCell-root":
                                  //     {
                                  //       fontWeight: "700 !important",
                                  //     },
                                  //     ".css-vv34gr-MuiTableRow-root": {
                                  //       boxShadow: "unset !important",
                                  //     },
                                  //   },
                                  // }}
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-1 mb-5 px-4">
                    <div className="details-signing-package">
                      <div
                        className="px-4 py-3 form-label-title"
                        style={{ borderBottom: "1px solid #F0F0F0" }}
                      >
                        Details of the signing package
                      </div>
                      <Row className="px-4 pt-4 pb-3">
                        <Col lg={10}>
                          <Row>
                            <Col lg={4}>
                              <Controller
                                {...register(`reminderStarts`)}
                                control={control}
                                rules={{ required: false }}
                                render={({ field: { onChange, value } }) => (
                                  <div className="form-input-group ">
                                    <input
                                      disabled={
                                        getSigningOrderDetailData?.data
                                          ?.is_cancelled === 1 ||
                                        (getValues("orderOfSigning")?.value !==
                                          "M" &&
                                          true)
                                      }
                                      type="date"
                                      id="reminderStarts"
                                      placeholder="reminderStarts"
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                    />
                                    <label htmlFor="reminderStarts">
                                      Reminder starts
                                    </label>
                                  </div>
                                )}
                              />
                            </Col>
                            <Col lg={4}>
                              <Controller
                                {...register(`reminderInterval`)}
                                control={control}
                                rules={{ required: false }}
                                render={({ field: { onChange, value } }) => (
                                  <ReactSelect
                                    disabled={
                                      getSigningOrderDetailData?.data
                                        ?.is_cancelled === 1 ||
                                      (getValues("orderOfSigning")?.value !==
                                        "M" &&
                                        true)
                                    }
                                    options={Days?.map((item) => ({
                                      value: item.value,
                                      label: item.label,
                                    }))}
                                    // innerLabel={true}
                                    placeholder={"Reminder interval"}
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                      clearErrors("reminderInterval");
                                    }}
                                    clearable={false}
                                  />
                                )}
                              />
                            </Col>
                            {/* <Col lg={4}>
                          <ReactSelect placeholder={"Reminder expires after"} innerLabel={true} />
                        </Col> */}
                          </Row>
                        </Col>
                      </Row>

                      <div className="px-4 my-2">
                        <div
                          className="radius-8 py-1"
                          style={{
                            border: "1px solid #d1d1d1",
                            background: "#ffffff",
                          }}
                        >
                          {/* <div className="px-3 grey-text">Dear [name]</div> */}
                          <Controller
                            {...register(`commonNote`)}
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value } }) => (
                              <textarea
                                disabled={
                                  getSigningOrderDetailData?.data
                                    ?.is_cancelled === 1 ||
                                  (getSigningOrderDetailData?.data
                                    ?.esignatur_order_id !== "" &&
                                    true)
                                }
                                className="actor-signing-package-textarea w-100 px-3 py-3 light-font-text "
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                style={{
                                  resize: "none",
                                  border: "none",
                                  height: "200px",
                                  background: "#fff",
                                }}
                                placeholder="Documents for signature are hereby forwarded"
                              ></textarea>
                            )}
                          />
                        </div>
                      </div>

                      <div className="px-4 pb-3">
                        <div className="form-label-title">IMPORTANT</div>
                        <div>
                          The message is included in the email from esignatur to
                          only people i.e. customer / buyer / tenant.
                        </div>
                        {/* <div>
                      The text Dear [name] cannot be changed and [name] will be
                      replaced with the personal name of the person concerned.
                    </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap justify-content-between py-2 px-3 footer-section-button-container ">
                    <ETButton
                      variant={"white"}
                      buttonText="CANCEL"
                      icon={false}
                      width="fit-content"
                      onClick={() => {
                        setCancelOrder(true);
                      }}
                    />

                    {getSigningOrderDetailData?.data?.is_cancelled !== 1 && (
                      <ETButton
                        disabled={
                          getSigningOrderDetailData?.data?.approved_on == "" ||
                          getSigningOrderDetailData?.data
                            ?.esignatur_order_id !== "" ||
                          PostSigningSendToSigningIsLoading
                        }
                        variant={"primary"}
                        buttonText="SEND TO SIGNING"
                        icon={false}
                        width="fit-content"
                        type="submit"
                        onClick={() => setIsSendToSigningClicked(true)}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <SigningPackageSendtoSigning />
            )}
          </>
        </div>
      </Form>
      {/* ------------------------ Siging document preview Pop Up ----------------------- */}
      <CustomPopup
        open={openViewDocumentPopUp}
        onHide={() => setOpenViewDocumentPopUp(false)}
        modalHeaderText={true}
        title={isViewSigningOrder?.signingOrderData?.order_name}
        size="xl"
        title_color="black"
        enableCrossButton={true}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        subTitle={documentDetails?.documentName}
        titleTooltip={true}
        titleTooltipText={isViewSigningOrder?.signingOrderData?.order_name}
      >
        <div>
          <Row>
            <Col className="">
              <div style={{ height: "540px" }}>
                <iframe
                  src={documentDetails?.documentUrl}
                  height="100%"
                  width="100%"
                ></iframe>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          {/* <ETButton
      variant={"white"}
    
        buttonText="CANCEL"
   
        onClick={() => setOpenViewDocumentPopUp(false)}
      />
      <ETButton
        variant={"primary"} 
        buttonText="SAVE"
        onClick={() => setOpenViewDocumentPopUp(false)}
      /> */}
        </div>
      </CustomPopup>

      {/* ----------------------- Add document from computer Popup -------------------- */}
      <CustomPopup
        open={openUploadDocumentManually}
        onHide={() => setOpenUploadDocumentManually(false)}
        title="Add document from computer"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        size="xl"
      >
        <AddDocument
          subHeading={
            "Drag and drop documents directly from your computer. It is possible to change the name of the document and select a document type. Once you have selected and customized the documents, click add and they will be added to the signing order."
          }
          openUploadDocumentManually={openUploadDocumentManually}
          setOpenUploadDocumentManually={setOpenUploadDocumentManually}
          handledSelectedPdf={handleAddDocumentFromComputer}
          isApiLoading={PostUploadSigningOrderDocumentIsLoading}
          type="DOCTY"
        />
      </CustomPopup>
      {/* ----------------------- Add document from Document Package Popup -------------------- */}
      <CustomPopup
        open={documentFromPackage}
        onHide={() => setDocumentFromPackage(false)}
        title="Add document from document package"
        size="xl"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        titleTooltipText={"Add document from document package"}
      >
        <div className="px-4 py-3">
          <div className="light-font-text ">
            Select which document package from the property to use. Then select
            whether the documents should be signed or used as an appendix
            document. It is also possible to change the name of the document and
            select a document type. Once you have selected and customized the
            documents, click add and they will be added to the signing order.
          </div>
          <Row>
            <Col lg={4}>
              <div className="pt-4 pb-3">
                <ReactSelect placeholder={"Document Package"} />
              </div>
            </Col>
          </Row>
        </div>

        <div
          className="px-4 signing-page-add-document-package-table"
          style={{ height: "300px" }}
        >
          <MaterialReactTable
            columns={addDocumentFromPackageColumns}
            data={addDocumentFromDocumentPackagedata}
            icons={{ ...SortIcon }}
            enableTopToolbar={false}
            enableColumnActions={false}
            enablePagination={false}
            enableRowSelection={true}
            enableSelectAll={true}
            muiSelectAllCheckboxProps={{
              icon: <UnCheckedbox />,
              checkedIcon: <Checkedbox />,
              color: "success",
            }}
            muiSelectCheckboxProps={{
              icon: <UnCheckedbox />,
              checkedIcon: <Checkedbox />,
              color: "success",
            }}
            muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
            muiTablePaperProps={{
              elevation: 0,
              sx: {
                fontFamily: "Cerebri sans !important",
                borderRadius: "0",
                border: "none",
                fontWeight: "300",
                ".css-1f2qhs8": {
                  minWidth: "auto",
                  marginRight: "10px",
                },
                th: {
                  height: "10px",
                  padding: "12px 16px",
                },
                td: {
                  height: "50px",
                  fontWeight: "300",
                  fontSize: "12px",
                  padding: "12px 16px",
                  overflow: "visible",
                  fontFamily: "Cerebri sans !important",
                },
                ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
                  display: "none", // pagination space removing from page
                },
                ".css-1bplw64-MuiTableCell-root": {
                  paddingRight: "0",
                },
                "& tr": {
                  boxShadow: "none !important",
                },
              },
            }}
            muiTableBodyProps={{
              sx: {
                fontFamily: "Cerebri sans !important",
                "& tr": {
                  backgroundColor: "#F9FAFA !important",
                },
              },
            }}
            muiTableHeadCellProps={{
              elevation: 0,
              //simple styling with the `sx` prop, works just like a style prop in this example
              sx: {
                fontWeight: "600",
                fontSize: "12px",
                fontFamily: "Cerebri sans !important",
              },
            }}
          />
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setDocumentFromPackage(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="ADD"
            onClick={() => setDocumentFromPackage(false)}
          />
        </div>
      </CustomPopup>
      {/* ----------------------- Add document from Unit Popup -------------------- */}

      <CustomPopup
        open={documentFromUnit}
        onHide={() => setDocumentFromUnit(false)}
        title="Add document from unit"
        size="xl"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        titleTooltipText={"Add document from unit"}
      >
        <div className="p-4">
          <div className="light-font-text ">
            Select which documents from the device should be used. Then select
            whether the documents should be signed or used as an appendix
            document. It is also possible to change the name of the document and
            select a document type. Once you have selected and customized the
            documents, click add and they will be added to the signature order.
          </div>
        </div>
        <div
          className="px-4 actor-signing-package-from-unit"
          style={{ height: "300px" }}
        >
          <MaterialReactTable
            columns={addDocumentForSigningColumn}
            data={addDocumentFromUnitdata}
            // icons={{...SortIcon}}
            enableTopToolbar={false}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "",
              },
              // "mrt-row-select": {
              //   header: "",
              // },
            }}
            enableColumnActions={false}
            enablePagination={false}
            enableRowSelection={true}
            muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
            icons={{
              //change sort icon, connect internal props so that it gets styled correctly
              // ArrowDownwardIcon: (props) =>  <FontAwesomeIcon icon={faSortDown} {...props} />,
              // SearchIcon: () => <FontAwesomeIcon icon={faSearch} />,
              ArrowDownwardIcon: () => (
                <FontAwesomeIcon
                  icon="fa-regular fa-sort"
                  style={{ color: "#bfbfbf" }}
                />
              ), //best practice
            }}
            muiTablePaperProps={{
              elevation: 0,
              sx: {
                fontFamily: "Cerebri sans !important",
                borderRadius: "0",
                border: "none",
                background: "#F9FAFA",
                fontWeight: "300",
                ".css-1f2qhs8": {
                  minWidth: "auto",
                  marginRight: "10px",
                },
                th: {
                  height: "50px",
                  padding: "0.5rem",
                },
                td: {
                  height: "50px",
                  padding: "0.5rem",
                  fontWeight: "300",
                  fontSize: "12px",
                },
                tr: {
                  borderRadius: "4px",
                },
                ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
                  display: "none", // pagination space removing from page
                },
              },
            }}
            muiTableBodyProps={{
              sx: {
                fontFamily: "Cerebri sans !important",
                "& tr:nth-of-type(odd)": {
                  backgroundColor: "#F9FAFA",
                },
              },
            }}
            muiTableHeadCellProps={{
              //simple styling with the `sx` prop, works just like a style prop in this example
              sx: {
                fontWeight: "500",
                fontSize: "12px",
                fontFamily: "Cerebri sans !important",
              },
            }}
          />
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setDocumentFromUnit(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="ADD"
            onClick={() => setDocumentFromUnit(false)}
          />
        </div>
      </CustomPopup>
      {/* ----------------------- Add document from Case -------------------- */}

      <CustomPopup
        open={documentFromCase}
        onHide={() => setDocumentFromCase(false)}
        title="Add document from case"
        size="xl"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        titleTooltipText={"Add document from case"}
      >
        <div className="p-4">
          <div className="light-font-text ">
            Select which documents from the device should be used. Then select
            whether the documents should be signed or used as an appendix
            document. It is also possible to change the name of the document and
            select a document type. Once you have selected and customized the
            documents, click add and they will be added to the signature order.
          </div>
        </div>
        <div
          className="px-4 actor-signing-package-from-case"
          style={{ height: "300px" }}
        >
          <MaterialReactTable
            columns={addDocumentForSigningColumn}
            data={addDocumentFromCasedata}
            // icons={{...SortIcon}}
            enableTopToolbar={false}
            enableColumnActions={false}
            enablePagination={false}
            enableRowSelection={true}
            muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
            icons={{
              //change sort icon, connect internal props so that it gets styled correctly
              // ArrowDownwardIcon: (props) =>  <FontAwesomeIcon icon={faSortDown} {...props} />,
              // SearchIcon: () => <FontAwesomeIcon icon={faSearch} />,
              ArrowDownwardIcon: () => (
                <FontAwesomeIcon
                  icon="fa-regular fa-sort"
                  style={{ color: "#bfbfbf" }}
                />
              ), //best practice
            }}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "",
              },
              // "mrt-row-select": {
              //   header: "",
              // },
            }}
            muiTablePaperProps={{
              elevation: 0,
              sx: {
                fontFamily: "Cerebri sans !important",
                borderRadius: "0",
                border: "none",
                background: "#F9FAFA",
                fontWeight: "300",
                ".css-1f2qhs8": {
                  minWidth: "auto",
                  marginRight: "10px",
                },
                th: {
                  height: "50px",
                  padding: "0.5rem",
                },
                td: {
                  height: "50px",
                  padding: "0.5rem",
                  fontWeight: "300",
                  fontSize: "12px",
                },
                tr: {
                  borderRadius: "4px",
                },
                ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
                  display: "none", // pagination space removing from page
                },
              },
            }}
            muiTableBodyProps={{
              sx: {
                fontFamily: "Cerebri sans !important",
                "& tr:nth-of-type(odd)": {
                  backgroundColor: "#F9FAFA",
                },
              },
            }}
            muiTableHeadCellProps={{
              //simple styling with the `sx` prop, works just like a style prop in this example
              sx: {
                fontWeight: "500",
                fontSize: "12px",
                fontFamily: "Cerebri sans !important",
              },
            }}
          />
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setDocumentFromCase(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="ADD"
            onClick={() => setDocumentFromCase(false)}
          />
        </div>
      </CustomPopup>
      {/* -------------------------- Add Extra Person Popup -------------------------- */}
      <CustomPopup
        open={addExtraPerson}
        onHide={() => setAddExtraPerson(false)}
        title="Add an extra person"
        size="md"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        subTitle={<div>xs</div>}
        titleTooltip={true}
        titleTooltipText={"Signing package 1"}
      >
        <AddExtraSigningPerson
          setAddExtraPerson={setAddExtraPerson}
          getSigningOrderDetailDataRefetch={getSigningOrderDetailDataRefetch}
          signingOrderId={signingOrderId}
        />
      </CustomPopup>

      {/* ------------------Close signing order popup----------------- */}
      <CustomPopup
        open={cancelOrder}
        onHide={() => setCancelOrder(false)}
        title="Cancel?"
        size="md"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        // subTitle={<div>xs</div>}
        titleTooltip={true}
        titleTooltipText={"Cancel Signing Order"}
      >
        <div className="p-3 pt-2">
          <p className="form-label-title">Are you sure you want to cancel?</p>
          <p className="light-font-text">All unsaved changes will be lost</p>
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container"
          // style={{
          //   backgroundColor: "#F9FAFA",
          //   borderRadius: " 0px 0px 8px 8px",
          //   border: "1px solid #F0F0F0",
          // }}
        >
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setCancelOrder(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="OK"
            fontSize="14px"
            fontWeight="500"
            onClick={() => {
              setCancelOrder(false);
              setViewSigningOrder({
                showSigningPage: false,
                signingOrderData: "",
              });
            }}
          />
        </div>
      </CustomPopup>

      {/* ------------------Cancel order popup -------------------*/}
      <CustomPopup
        open={openCancelOrderPopup}
        onHide={() => setOpenCancelOrderPopup(false)}
        title="Cancel signing order?"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
        size={"md"}
      >
        <div className="p-3">
          <div className="px-2">
            <div className="light-font-text my-1" style={{ height: "100px" }}>
              Are you sure you want to cancel this signing order?
            </div>
          </div>
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => {
              setOpenCancelOrderPopup(false);
            }}
          />
          <ETButton
            disabled={PostCancelSigningOrderIsLoading}
            variant={"primary"}
            buttonText="I'M SURE"
            onClick={() =>
              handleCancelSigningOrder(
                isViewSigningOrder?.signingOrderData?.signing_order_id
              )
            }
          />
        </div>
      </CustomPopup>

      {/* ------------------Delete manual document-------------------*/}
      <CustomPopup
        open={deleteManualDocument?.openDeletePopup}
        onHide={() =>
          setDeleteManualDocument((prev) => ({
            ...prev,
            openDeletePopup: false,
          }))
        }
        title="Delete document"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
        size={"md"}
      >
        <div className="p-3">
          <div className="px-2">
            <div className="light-font-text my-1" style={{ height: "100px" }}>
              Are you sure you want to delete this document?
            </div>
          </div>
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            border: "1px solid #F0F0F0",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() =>
              setDeleteManualDocument((prev) => ({
                ...prev,
                openDeletePopup: false,
              }))
            }
          />
          <ETButton
            variant={"primary"}
            buttonText="I'M SURE"
            onClick={() => handleDeleteDocument()}
          />
        </div>
      </CustomPopup>
    </>
  );
};
