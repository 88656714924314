import React, { useCallback } from "react";
import { toPng, toJpeg, toSvg } from "html-to-image";
const converter = require("json-2-csv");

export default function CSVWidget({ refObj, fileName, data, options }) {
	// JPG
	const csvClick = () => {
		let json2csvCallback = function (err, csv) {
			if (err) throw err;
			const downloadFile = ({ data, fileName, fileType }) => {
				// Create a blob with the data we want to download as a file
				const blob = new Blob([data], { type: fileType });
				// Create an anchor element and dispatch a click event on it
				// to trigger a download
				const a = document.createElement("a");
				a.download = fileName;
				a.href = window.URL.createObjectURL(blob);
				const clickEvt = new MouseEvent("click", {
					view: window,
					bubbles: true,
					cancelable: true,
				});
				a.dispatchEvent(clickEvt);
				a.remove();
			};
			downloadFile({
				data: csv,
				fileName: `${fileName}.csv`,
				fileType: "text/csv",
			});
		};
		converter.json2csv(data, json2csvCallback, options);
	};

	return csvClick;
}
