import React, { useEffect, useMemo, useState } from "react";
import MRTTheme from "../../../../Components/table/MRTTheme";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";

import { Checkedbox, UnCheckedbox } from "../../../../assets/icons/icons";
import { Col, Dropdown, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
} from "../../../../Components/table/MRTGlobalProps";
import ETButton from "../../../../Components/Buttons/ETButton";
import { useGetSigningOrderByPropertyId } from "../../../../hooks/queries/useUnitQueries";
import { usePropertyId } from "../../../../hooks/stores/usePropertyStore";
import TagFilterDropDown from "../../../settings/Tags/TagFilterDropDown";
import DropdownModalMui from "../../../../Components/DropdownModal/DropdownModalMui";

const SelectContractTable = ({ typeOfUnit, setRowSelection, rowSelection }) => {
  const [closeDropdown, setCloseDropdown] = useState(false);
  const { propertyId } = usePropertyId((state) => state);
  const { data: GetSigningOrderByPropertyId } =
    useGetSigningOrderByPropertyId(propertyId);

  const columns = useMemo(() => [
    {
      accessorKey: "contract_no",
      header: "Contract no.",
      enableHiding: false,
      enableGlobalFilter: false,

      size: 120,
      // Cell: ({ row }) => (
      //   <>
      //     <div className="d-flex">
      //       <div
      //         className="radius-4 me-3"
      //         style={{
      //           backgroundColor: row.original.color,
      //           width: "13px",
      //           height: "13px",
      //           marginTop: "2px",
      //         }}
      //       ></div>
      //       <div>{row.original.status}</div>
      //     </div>
      //   </>
      // ),
      // center align the header and body cells
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "primary_customer",
      header: "Primary customer",
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "street_name",
      header: "Address",
      size: 180,
      // enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "unit_no",
      header: "Unit no.",
      size: 50,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "created_date",
      header: "Creation date",
      size: 180,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 100,
      enableGlobalFilter: true,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
  ]);

  // Dropdown for filter
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      style={{
        backgroundColor: "#FFF",
        color: "#000",
        width: "100%",
        display: "flex",
        height: "41px",
        border: "1px solid #D1D1D1",
        fontWeight: "300",
        justifyContent: "space-between",
        alignItems: "center",
        textDecoration: "none",
        fontSize: "14px",
        borderRadius: "8px",
      }}
      className="px-2 py-2 dropdownInput "
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        setCloseDropdown(!closeDropdown);
        // console.log(e, "Eeeeeeeeeeee");
      }}
    >
      <div className="d-flex justify-content-between w-100">
        <span> {children}</span>
        <span>
          <FontAwesomeIcon
            icon="fa-regular fa-chevron-down"
            size="sm"
            style={{ color: "#000000" }}
          />
        </span>
      </div>
    </a>
  ));

  const [contractData, setContractData] = useState(
    GetSigningOrderByPropertyId?.data
  );
  const [newData, setNewData] = useState([]);
  const [originalTagData, setOriginalTagData] = useState([]);
  useEffect(() => {
    let filteredData = [];
    if (typeOfUnit == 2) {
      filteredData = GetSigningOrderByPropertyId?.data?.filter(
        (val) => val?.parking_count == 0
      );
      setContractData(filteredData);
      setNewData(filteredData);
      setOriginalTagData(filteredData);
    } else if (typeOfUnit == 3) {
      filteredData = GetSigningOrderByPropertyId?.data?.filter(
        (val) => val?.storage_count == 0
      );
      setContractData(filteredData);
      setNewData(filteredData);
      setOriginalTagData(filteredData);
    }
  }, [GetSigningOrderByPropertyId]);

  const ChooseFilter = [
    { Id: "1", Text: "Unit No" },
    { Id: "2", Text: "Status" },
    { Id: "2", Text: "Contract No" },
  ];

  const tags = [
    { Id: "1", Text: "Not active", parent: "2" },
    { Id: "2", Text: "Available", parent: "2" },
    { Id: "3", Text: "Reserved", parent: "2" },
    { Id: "4", Text: "In process", parent: "2" },
    { Id: "5", Text: "Completed", parent: "2" },
    { Id: "6", Text: "Available", parent: "2" },
    { Id: "7", Text: "Total", parent: "2" },
  ];

  const dumnmyDropDownData = {
    "Unit No": "unit_no",
    Status: "tags",
    "Contract No": "contract_no",
  };
  const [propertag, setPropertytag] = useState("");
  const [filter, setFilter] = useState(null);
  const [resetCondition, setResetCondition] = useState(false);
  useEffect(() => {
    if (resetCondition == true) {
      setContractData(GetSigningOrderByPropertyId?.data);
    }
  }, [resetCondition]);
  const selectdata = [];
  const [filterDropdowon, setFilterDropdowon] = useState("");
  const [filterDropData, setFilterDropData] = useState("");
  const [selectedDropdownData, setSelectedDropDownData] = useState([]);
  const [show, setShow] = useState();
  const [status, setStatus] = useState(false);
  const handleChange = (filter, ActionTypes) => {
    if (ActionTypes.action === "clear") {
      setFilter("");
    } else {
      setFilter(filter);
    }
    setPropertytag(true);
    setShow(false);
    let a = dumnmyDropDownData[filter?.label];
    let b = [...new Set(newData?.map((data) => data[a]))];
    setSelectedDropDownData(b);
    // setFilter(filter.label);
    setFilterDropdowon(ChooseFilter);

    const checkSelectedLabel = filter?.label;
    if (checkSelectedLabel === "Status") {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };
  // const test = filter;
  const filteredTagdata = filter
    ? tags.filter((tag) => tag.parent === filter.value)
    : [];
  const filteredTag = selectedDropdownData;
  const [selecteddata, setSelectedData] = useState([]);
  // const [closeDropdown, setCloseDropdown] = useState(false);

  const [filterKeys, setFilterKeys] = useState({
    unit_no: [],
    mainstatus_name: [],
    contract_no: [],
  });
  // Function to push data into a specific filter key
  const pushDataToFilterKey = (key, value) => {
    if (filterKeys[key]?.includes(value)) {
    } else {
      setFilterKeys((prevFilterKeys) => ({
        ...prevFilterKeys,
        [key]: [...prevFilterKeys[key], value],
      }));
    }
  };
  const multiPropsFilter = (unitsTableData, filters) => {
    const filterKeys = Object?.keys(filters);
    const filterdata = originalTagData?.filter((product) => {
      return filterKeys?.every((key) => {
        if (!filters[key].length) return true;
        // Loops again if product[key] is an array (for material attribute).
        if (Array?.isArray(product[key])) {
          return product[key]?.some((keyEle) => filters[key]?.includes(keyEle));
        }
        return filters[key]?.includes(product[key]);
      });
    });
    setContractData(filterdata);
  };

  const handleFilterData = (i) => {
    const selectfilterdata = filter?.label;
    const parent = ChooseFilter.find(
      (filter) => filter.Id === selectfilterdata
    )?.parent;

    if (selectfilterdata === "Unit No") {
      const key = "unit_no";
      const value = i;
      pushDataToFilterKey(key, value);
    } else if (selectfilterdata === "Status") {
      const key = "mainstatus_name";
      const value = i;
      pushDataToFilterKey(key, value);
    } else if (selectfilterdata === "Contract No") {
      const key = "contract_no";
      const value = i;
      pushDataToFilterKey(key, value);
    } else {
    }
    setFilterDropData(i);
    selectdata.push({ lable: filterDropdowon, value: i });
    setSelectedData((prev) => [...prev, ...selectdata]);
  };

  return (
    <div>
      <MRTTheme
        colorVariantsForTableData={["odd"]}
        table={
          <MaterialReactTable
            columns={columns}
            data={contractData || []}
            icons={{ ...SortIcon }}
            enableTopToolbar={true}
            enableBottomToolbar={true}
            enableSelectAll={false}
            enableColumnActions={false}
            enableColumnFilter={false}
            enableResizing={false}
            enableRowSelection
            initialState={{ showGlobalFilter: true }}
            getRowId={(originalRow) => originalRow?.contract_id}
            onRowSelectionChange={setRowSelection}
            state={{ rowSelection }}
            // enableRowSelection={(row) => Object.keys(rowSelection)?.length >= 1}
            enableMultiRowSelection={false}
            muiTableContainerProps={{
              sx: {
                paddingX: "3px",
                paddingTop: "10px",
              },
            }}
            muiSelectCheckboxProps={{
              sx: {
                color: "#d1d1d1",
                "& .MuiSvgIcon-root": {
                  fontSize: "1.2rem !important",
                },
              },
            }}
            muiSearchTextFieldProps={{
              ...SearchStyle,

              InputProps: {
                ...SearchIcon,
              },
            }}
            enableColumnFilterModes={false}
            renderTopToolbar={({ table }) => (
              <>
                <Row className=" py-2 px-2">
                  <Col xl={2} md={4} className="my-1 pe-1 ">
                    <MRT_GlobalFilterTextField table={table} />
                  </Col>

                  <Col xl={2} md={4} className="my-1 pe-1">
                    <Dropdown show={closeDropdown}>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-custom-components"
                      >
                        Set filters
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="my-drop-menu">
                        <TagFilterDropDown
                          propertag={propertag}
                          filteredTag={filteredTag}
                          filteredTagdata={filteredTagdata}
                          status={status}
                          setUnitsTableData={setContractData}
                          multiPropsFilter={multiPropsFilter}
                          selectedDropdownData={selectedDropdownData}
                          dumnmyDropDownData={dumnmyDropDownData}
                          filterDropdowon={filterDropdowon}
                          // handleDropData={handleDropData}
                          unitsTableData={contractData}
                          filterDropData={filterDropData}
                          setFilterDropData={setFilterDropData}
                          ChooseFilter={ChooseFilter}
                          handleFilterData={handleFilterData}
                          filter={filter}
                          selecteddata={selecteddata}
                          setFilter={setFilter}
                          handleChange={handleChange}
                          selectdata={selectdata}
                          filterKeys={filterKeys}
                          setFilterKeys={setFilterKeys}
                          closeDropdown={closeDropdown}
                          setCloseDropdown={setCloseDropdown}
                          setResetCondition={setResetCondition}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </>
            )}
          />
        }
      />
    </div>
  );
};

export default SelectContractTable;

const ParkingLotFilter = ({ setCloseDropdown }) => {
  return (
    <div>
      <div className="mt-5 py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
        {" "}
        <ETButton
          variant={"white"}
          buttonText="CANCEL"
          // type={"button"}
          onClick={() => setCloseDropdown(false)}
        />
        <div className=" d-flex ">
          <div className="px-1">
            {" "}
            <ETButton
              variant={"primary"}
              buttonText="SAVE"
              //   type={"submit"}
              onClick={() => setCloseDropdown(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const contractTableData = [
  {
    id: 1,
    contract_no: "212-004-004",
    primary_customer: "Jesper Thorkildsen",
    address: "Dybbølsbro 3, 1",
    unit_no: "7-W220",
    creation_date: "5 May, 2023",
    status_name: "in process",
  },
  {
    id: 2,
    contract_no: "212-005-004",
    primary_customer: "Susanne Larsen",
    address: "Dybbølsbro 3, 2",
    unit_no: "7-W320",
    creation_date: "17 Apr, 2023",
    status_name: "in process",
  },

  {
    id: 3,
    contract_no: "212-006-004",
    primary_customer: "René Dif",
    address: "Dybbølsbro 3, 3",
    unit_no: "7-W240",
    creation_date: "26 Jan, 2023",
    status_name: "Completed",
  },
  {
    id: 4,
    contract_no: "212-007-004",
    primary_customer: "Nikolas Garbanoz",
    address: "Dybbølsbro 3, 4",
    unit_no: "7-W250",
    creation_date: "19 Dec, 2022",
    status_name: "in process",
  },
];

//================================ History ================================//

// Viraj (22-10-2023)
// 1. Table will only show signing orders with storage_count: 0 for storage contract & parking_count 0 for parking contract (API: GetSigningOrderByPropertyID).
