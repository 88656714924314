import "./tabs.css";
import { useState } from "react";
import { Menu } from "@mui/material";
import CustomPopup from "../CustomPopup";
import ETButton from "../Buttons/ETButton";
import {
  GetPortfolio,
  DeletePropertyPortfolio,
} from "../../services/Portfolios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenusIcon, TabsButton } from "./Tabs.style";

//This component use only for property portfolio  //

const Tabs = ({
  text,
  id,
  onClick,
  GetPortofolioList,
  GetAllPropertiesListForRefresh,
  setShowPage,
  handleEditPortfolio,
  handleDeletePortfolioFromTab,
  activeTabs,
  disableDeleteAction,
}) => {
  // console.log("Tabs id", id);
  const [show, setShow] = useState(false);
  const [operationName, setOperationName] = useState("");
  //
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [portfolioData, setPortfolioData] = useState([]);

  const GetAllPortofolioList = () => {
    try {
      GetPortfolio()
        .then((data) => {
          if (data != null && data != undefined) {
            setPortfolioData(data.data);
          } else {
            setPortfolioData([null]);
          }
        })
        .catch((error) => {});
    } catch (e) {
      setPortfolioData([null]);
    }
  };

  const DeletePropertybyPortfolio = () => {
    try {
      DeletePropertyPortfolio(id)
        .then((response) => {
          GetPortofolioList();
          GetAllPropertiesListForRefresh();
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setDropdownOpen(false);
  };

  const canceleButton = () => {
    setShow(false);
  };

  const deleteConformation = (id) => {
    handleDeletePortfolioFromTab(id);
    GetAllPortofolioList();
    setShow(false);
  };
  const modalPoppup = () => {
    setAnchorEl(null);
    setDropdownOpen(false);
    setShow(true);
  };

  return (
    <>
      <TabsButton onClick={onClick} mode={activeTabs.toString()}>
        {text}
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleClick(e);
          }}
          className="d-flex justify-content-center align-items-center"
          style={{
            color: "#619130",
            backgroundColor: "#FFFFFF",
            border: `1px solid #D1D1D1`,
            borderRadius: "4px",
            width: "24px",
            height: "24px",
            fontSize: "14px",
          }}
        >
          {dropdownOpen === true ? (
            <MenusIcon>
              <FontAwesomeIcon icon="fa-regular fa-ellipsis" />
            </MenusIcon>
          ) : (
            <MenusIcon>
              <FontAwesomeIcon icon="fa-regular fa-ellipsis-vertical" />
            </MenusIcon>
          )}
        </div>
      </TabsButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={handleClose}
        elevation={0}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            borderRadius: "8px",
            marginTop: "6px",
            border: "0.5px solid #F0F0F0",
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.12)",
            paddingTop: "0px",
          },
        }}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.02)",
          "& .css-6hp17o-MuiList-root-MuiMenu-list": {
            padding: 0,
            borderColor: "#F0F0F0",
          },
        }}
      >
        <div style={{ borderRadius: "8px" }}>
          <div
            className="addpropertyCountryHandle fs-14"
            style={{ width: "150px" }}
          >
            <div
              className="addpropContent px-3 py-2 "
              onClick={() => handleEditPortfolio(id)}
            >
              {/* state={{ editId: id, edittext: text }}
								style={{ textDecoration: "none", color: "black" }}> */}
              <span className="ms-1">Edit portfolio</span>
            </div>
            <hr className="hr m-0"></hr>
            <div
              className="addpropContent px-3 pt-2 pb-2 "
              onClick={modalPoppup}
            >
              <span className="ms-1">Delete portfolio</span>
            </div>
          </div>
        </div>
      </Menu>
      <CustomPopup
        open={show}
        onHide={() => setShow(false)}
        title="Delete portfolio"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="px-4 py-3 light-font-text">
          <p>
            You are deleting this portfolio. Remember you are not deleting your
            properties but only the group of them.
          </p>
          <p>Are you sure you want to delete?</p>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={canceleButton}
          />
          <ETButton
            buttonText="DELETE"
            disabled={disableDeleteAction}
            onClick={() => deleteConformation(id)}
            variant={"primary"}
          />
        </div>
      </CustomPopup>
    </>
  );
};

export default Tabs;
