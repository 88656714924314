import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import { useMemo, useState, useEffect } from "react";
import { GetFacilityByPropertyId } from "../../services/Facility";
import { useQuery } from "react-query";
import { Col, Placeholder, Row } from "react-bootstrap";
import ReactSelect from "../../Components/DropDown/ReactSelect";

import {
  useGetFacilityCategory,
  useGetFacilityGroup,
  useEditFacilityDetail,
  useGetFacilityAttributesByFacilityId,
} from "../../hooks/queries/usefacilityQueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputAdornment } from "@mui/material";
import { toast } from "react-toastify";
import AddDetailPopup from "./AddDetailPopup";
import CustomPopup from "../../Components/CustomPopup";
import { status } from "../../model/Constant";
import Toggle from "../../Components/Switch/Switch";
import MRTTheme from "../../Components/table/MRTTheme";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../Components/table/MRTGlobalProps";

function FacilityTable({ propertyId }) {
  const {
    data: GetFacilityByPropertyIdData,
    refetch: GetFacilityByPropertyIdRefetch,
    isLoading,
  } = useQuery(
    ["GetFacilityByPropertyId", propertyId],
    () => GetFacilityByPropertyId(propertyId),
    { enabled: !!propertyId }
  );
  const { data: GetFacilityCategoryData } = useGetFacilityCategory();
  const { data: GetFacilityGroupData } = useGetFacilityGroup();
  const activityOptions = [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 0 },
  ];
  let packId = 0;
  let UnitId = 0;
  const [facilityId, setFacilityId] = useState("");
  // const { packageId, setPackageId } = useRouting((state) => state);
  const {
    data: GetFacilityAttributesByFacilityIdData,
    refetch: GetFacilityAttributesByFacilityIdDataRefetch,
  } = useGetFacilityAttributesByFacilityId(
    propertyId,
    UnitId,
    packId,
    facilityId
  );
  let filteredData;
  const [tableData, setTableData] = useState([]);
  const [addDetail, setAddDetail] = useState(false);
  const [modalHeadingStyle, setModalHeadingStyle] = useState(false);
  const [toggleYes, setToggleYes] = useState(undefined);
  const [activeSwitch, setActiveSwitch] = useState(undefined);
  const [row, setRow] = useState();
  const [group, setGroup] = useState("");
  const [category, setCategory] = useState("");
  const [toggleSetting, setToggleSetting] = useState("");
  const [selectedFacility, setSelectedFacility] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterKeys, setFilterKeys] = useState({
    category: [],
    group: [],
    active_on_property: [],
  });

  const pushDataToFilterKey = (key, value) => {
    setFilterKeys((prevFilterKeys) => ({
      ...prevFilterKeys,
      [key]: [value],
    }));
  };
  let enableCheck = false;

  useEffect(() => {
    setTableData(GetFacilityByPropertyIdData?.data);
    setFilterData(GetFacilityByPropertyIdData?.data);
  }, [GetFacilityByPropertyIdData, addDetail]);
  const handleSwitch = async (row, e) => {
    setActiveSwitch(e.target.checked);
    // setRow(row);
    selectedRow = row;
    // console.log(active, "active handleSwitch");
    // active == 1 ? setActiveSwitch(true) : setActiveSwitch(false);

    const EditFacilityDetailPayload = {
      unit_id: "0",
      property_id: propertyId,
      facility_id: row?.facility_id,
      // is_set: set == "1" ? "0" : "1",
      is_set: row?.setting,
      is_active: e?.target.checked ? "1" : "0",
    };
    const response = await EditFacilityDetailMutate(EditFacilityDetailPayload);
    setFacilityId(facilityId);
    if (response.status == status.s && response.data.breturn) {
      toast.success(response.data.message);
      setActiveSwitch(e.target.checked);
      GetFacilityAttributesByFacilityIdDataRefetch();
      GetFacilityByPropertyIdRefetch();
    } else {
      setActiveSwitch(!e.target.checked);
    }
  };
  useEffect(() => {
    let facilityData = GetFacilityAttributesByFacilityIdData?.data[0];
    // to get the value of setting param--start
    var facility = GetFacilityByPropertyIdData?.data?.filter(
      (fac) => fac?.property_facility_id == facilityData?.facility_id
    );
    setSelectedFacility(facility);
    if (facility?.[0]?.active_on_property == 0) {
      setActiveSwitch(false);
    } else if (facility?.[0]?.active_on_property == 1) {
      setActiveSwitch(true);
    }
    if (facility?.[0]?.setting == 0) {
      setToggleYes(false);
    } else if (facility?.[0]?.setting == 1) {
      setToggleYes(true);
    }
    // to get the value of setting param--end
  }, [GetFacilityAttributesByFacilityIdData, facilityId]);

  const handleFilter = (value, selected, ActionTypes) => {
    if (ActionTypes.action === "clear") {
      // Clear only the 'category' key
      if (value === "category") {
        setFilterKeys((prevFilterKeys) => ({
          ...prevFilterKeys,
          category: [],
        }));
      } else if (value === "group") {
        setFilterKeys((prevFilterKeys) => ({
          ...prevFilterKeys,
          group: [],
        }));
      } else if (value === "active") {
        setFilterKeys((prevFilterKeys) => ({
          ...prevFilterKeys,
          active_on_property: [],
        }));
      }
    } else if (value === "category") {
      const key = "category";
      const selectedValue = selected?.value;
      pushDataToFilterKey(key, selectedValue);
    } else if (value === "group") {
      const key = "group";
      const selectedValue = selected?.value;
      pushDataToFilterKey(key, selectedValue);
    } else if (value === "active") {
      const key = "active_on_property";
      const selectedValue = selected?.value;
      pushDataToFilterKey(key, selectedValue);
    }
  };

  useEffect(() => {
    const filteredData = filterData?.filter((item) => {
      if (
        filterKeys.category?.length > 0 &&
        !filterKeys.category?.includes(item.category_id)
      ) {
        return false;
      }

      if (
        filterKeys?.group?.length > 0 &&
        !filterKeys?.group?.includes(item.group_id)
      ) {
        return false;
      }

      if (
        filterKeys?.active_on_property?.length > 0 &&
        !filterKeys?.active_on_property?.includes(
          parseInt(item.active_on_property)
        )
      ) {
        return false;
      }

      return true;
    });

    setTableData(filteredData);
  }, [filterKeys, filterData]);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.icon_name} ${row.facility_name}`,
        header: "Facility Name",
        size: 220,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {row.original.icon_name !== "" ? (
              <FontAwesomeIcon
                icon={row.original.icon_name}
                style={{ fontSize: "1rem" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={"fa-solid fa-circle"}
                style={{ fontSize: "1rem" }}
              />
            )}

            <div>{row.original.facility_name}</div>
          </div>
        ),
      },
      {
        accessorKey: "category",
        header: "Category",
        enableHiding: false,
        size: 180,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "group",
        header: "Group",
        enableHiding: false,
        size: 180,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "Setting",
        // accessorFn: (row) => `${row.unit_count}`,
        header: "Setting",
        size: 100,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          //

          <span>{row.original.setting == 1 ? "Yes" : "No"}</span>
        ),
      },
      {
        accessorKey: "active_on_property",
        header: "Active on property",
        size: 100,
        enableGlobalFilter: true,
        // Cell: ({ row }) => <Switch checked={parseInt(row.original.active_on_property)}
        // 	onChange={()=>handleSwitch (row.original.facility_id)}/>,
        Cell: ({ row }) => (
          // <Switch
          // 	checked={row.original.active_on_property == 1 ? true : false}
          // 	onChange={() => {
          // 		setActiveSwitch(row.original.active_on_property);
          // 		handleSwitch(
          // 			row.original.facility_id,

          // 			row.original.active_on_property
          // 		);
          // 	}}
          // />
          <Toggle
            checked={row.original.active_on_property == 1 ? true : false}
            // disabled={true}
            onChange={(e) => handleSwitch(row.original, e)}
            id={row.original.facility_id}
          />
        ),
        //center align the header and body cells
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    [handleSwitch]
  );

  const { mutateAsync: EditFacilityDetailMutate } = useEditFacilityDetail();
  let selectedRow;

  const handleEditFacilityAttribute = async (facility_id) => {
    GetFacilityAttributesByFacilityIdDataRefetch();

    setFacilityId(facility_id);

    setAddDetail(true);
  };
  const customColor = "#619130";
  // const renderEmptyRowsFallback = () => (
  // 	<div className="d-flex justify-content-center w-100 py-4 grey-text">
  // 		<div>You do not have any facility yet.</div>
  // 	</div>
  // );

  const renderEmptyRowsFallback = () => {
    if (tableData && tableData?.length === 0) {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div>You do not have any facility yet.</div>
          </>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div style={{ fontSize: "14px" }}>
              Sorry! No result found. Please try again!
            </div>
          </>
        </div>
      );
    }
  };
  //
  return (
    <div className="px-2 mt-1">
      <Placeholder animation="glow">
        <MRTTheme
          colorVariantsForTableData={["odd"]}
          table={
            <MaterialReactTable
              columns={columns}
              data={tableData ?? []}
              icons={{ ...SortIcon }}
              state={{ isLoading: !tableData }}
              renderEmptyRowsFallback={renderEmptyRowsFallback}
              enableRowActions
              globalFilterFn="contains"
              positionActionsColumn="last"
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "",
                },
                // "mrt-row-select": {
                //   header: "",
                // },
              }}
              renderRowActions={({ row }) => (
                <button
                  className="icon-btn"
                  style={{
                    height: "30px",
                    cursor:
                      row.original.active_on_property == 0
                        ? "not-allowed"
                        : "pointer",
                  }}
                  disabled={row.original.active_on_property == 0 ? true : false}
                  onClick={() =>
                    handleEditFacilityAttribute(row?.original?.facility_id)
                  }
                >
                  <FontAwesomeIcon
                    icon="fa-regular fa-pen-to-square"
                    size="lg"
                    style={{
                      color:
                        row.original.active_on_property == 0
                          ? "#d1d1d1"
                          : "#000000",
                    }}
                  />
                </button>
              )}
              getRowId={(originalRow) => originalRow?.facility_id}
              enableSelectAll={false}
              enableColumnActions={false}
              enableColumnFilter={false}
              enableResizing={false}
              initialState={{ showGlobalFilter: true }}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
              enableColumnFilterModes={false}
              renderTopToolbar={({ table }) => (
                <Row className="pb-3 ms-2 me-sm-4 me-lg-2 mt-3">
                  <Col lg={10} xl={9}>
                    <Row>
                      <Col lg={3} md={4} className="pe-0  pb-3">
                        {isLoading ? (
                          <div className="placeholder col-12 radius-8 padding-20 bg-secondary"></div>
                        ) : (
                          <MRT_GlobalFilterTextField table={table} />
                        )}
                      </Col>
                      <Col lg={3} md={4} className="pe-0 pb-3">
                        {isLoading ? (
                          <div className="placeholder col-12 radius-8 padding-20 bg-secondary"></div>
                        ) : (
                          <ReactSelect
                            placeholder={"Category"}
                            options={GetFacilityCategoryData?.data?.map(
                              (item) => ({
                                value: item?.Id,
                                label: `${item?.Text}`,
                              })
                            )}
                            onChange={(value, ActionTypes) => {
                              handleFilter("category", value, ActionTypes);
                            }}
                          />
                        )}
                      </Col>
                      <Col lg={3} md={4} className="pe-0 pb-3">
                        {isLoading ? (
                          <div className="placeholder col-12 radius-8 padding-20 bg-secondary"></div>
                        ) : (
                          <ReactSelect
                            placeholder={"Group"}
                            options={GetFacilityGroupData?.data?.map(
                              (item) => ({
                                value: item?.Id,
                                label: `${item?.Text}`,
                              })
                            )}
                            onChange={(value, ActionTypes) => {
                              handleFilter("group", value, ActionTypes);
                            }}
                          />
                        )}
                      </Col>

                      <Col lg={3} md={4} className="pe-0 ">
                        {isLoading ? (
                          <div className="placeholder col-12 radius-8 padding-20 bg-secondary"></div>
                        ) : (
                          <ReactSelect
                            placeholder={"Active"}
                            options={activityOptions.map((item) => ({
                              value: item?.value,
                              label: `${item?.label}`,
                            }))}
                            onChange={(value, ActionTypes) => {
                              handleFilter("active", value, ActionTypes);
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              // globalFilterFn="contains"
              positionGlobalFilter="left"
              muiSearchTextFieldProps={{
                ...SearchStyle,
                InputProps: {
                  ...SearchIcon,
                },
              }}
            />
          }
        />
        <CustomPopup
          modalHeadingStyle={modalHeadingStyle}
          style={{ position: "relative" }}
          size="lg"
          open={addDetail}
          onHide={() => setAddDetail(false)}
          title="Add details"
          title_color="black"
          titleTooltipText="Need Help About Tags"
          titleTooltip={true}
          enableCrossButton={false}
          onCloseAction={() => {
            setAddDetail(false);
            alert("Action Peformed Successfuly.");
          }}
        >
          <AddDetailPopup
            setAddDetail={setAddDetail}
            facilityId={facilityId}
            propertyId={propertyId}
            UnitId={0}
            facilityLevel={"property"}
            // active={active}
            // toggleYes={toggleYes}
            // setToggleYes={setToggleYes}
            activeSwitch={activeSwitch}
            setActiveSwitch={setActiveSwitch}
            handleSwitch={handleSwitch}
            selectedRow={selectedRow}
            GetFacilityAttributesByFacilityIdData={
              GetFacilityAttributesByFacilityIdData
            }
            GetFacilityAttributesByFacilityIdDataRefetch={
              GetFacilityAttributesByFacilityIdDataRefetch
            }
            refetch={GetFacilityByPropertyIdRefetch}
          />
        </CustomPopup>
      </Placeholder>
    </div>
  );
}

export default FacilityTable;
