import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  Button as MUIButton,
  Menu as MUIMenu,
  MenuItem as MUIMenuItem,
} from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import SuperEditor from "@ckeditor/ckeditor5-build-classic";

import { DenmarkFlag, Englandflag } from "../../../../../../assets/icons/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomToolTip from "../../../../../../Components/Tooltip/CustomTooltip";
import CustomTextfield from "../../../../../../Components/Textfield/Textfield";
import BasicSelect from "../../../../../../Components/DropDown/DropDown";
import ETButton from "../../../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../../../Components/CustomPopup";
import { GreenSpan } from "../../../../../../styles/Common.style";

const AddNewTextField = () => {
  const location = useLocation();
  const { state } = location;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectLanguageEnglishVersion, setSelectLanguageEnglishVersion] =
    useState(true);
  const [selectLanguageDanishVersion, setSelectLanguageDanishVersion] =
    useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [deleteEditor, setDeleteEditor] = useState(false);

  const handleDeleteLanguageField = () => {
    if (deleteId == "en") {
      setSelectLanguageEnglishVersion(false);
    } else if (deleteId == "de") {
      setSelectLanguageDanishVersion(false);
    }
    setDeleteEditor(false);
  };

  const [deleteId, setDeleteId] = useState("");

  return (
    <>
      {/* <UnitHeader propertyOverview={state?.propertyOverviw} /> */}
      <Container
        fluid
        style={{ backgroundColor: "#F9FAFA" }}
        className=" pt-3 px-5"
      >
        <div style={{ fontWeight: "600", fontSize: "24px" }}>
          Create new text field
        </div>
        <div
          style={{
            fontFamily: " Cerebri sans-light",
            fontSize: "14px",
            cursor: "pointer",
          }}
        >
          <span className="me-1">Properties</span>
          <span className="mx-1">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <span className="mx-1">
            {state?.propertyOverviw[0]?.propertyName}{" "}
          </span>
          <span className="mx-1">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <span className="mx-1">Texts</span>
          <span className="mx-1">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <GreenSpan className="mx-1">Create new text field</GreenSpan>
        </div>
      </Container>
      <div
        className="mt-3 mx-5 d-flex"
        style={{ backgroundColor: "#f1f5f0", borderRadius: "8px" }}
      >
        <div
          className="m-4"
          style={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            fontSize: "14px",
            width: "100%",
            // paddingBottom: "66px",
          }}
        >
          <div
            className="px-3 pb-3"
            style={{ borderBottom: "1px solid #F0F0F0" }}
          >
            <div
              style={{ fontFamily: " Cerebri sans-bold " }}
              className="px-2 pt-3 d-flex"
            >
              <div>Create new text field</div>
              <div style={{ paddingLeft: "15px" }}>
                {" "}
                <CustomToolTip height={12} text="Texts" />{" "}
              </div>
            </div>

            <div
              className="px-2 "
              style={{
                fontFamily: "Cerebri sans-light",
                fontSize: "12px",
                fontWeight: 300,
                color: "#888888",
              }}
            >
              Last updated by Jesper Thorkildsen, 19. Jan. 2023 | 16:21:03
            </div>
          </div>

          <div className="px-3">
            <div className="px-2 pt-2 d-flex fw-600">
              <div>Text field title</div>
            </div>

            <div
              className="px-2 mt-1 "
              style={{
                fontFamily: "Cerebri sans-light",
                fontSize: "12px",
                fontWeight: 300,
                color: "#888888",
              }}
            >
              Name an internal title that can be used to recognize the text
              field
            </div>

            <Row className="px-2 mt-4">
              <Col lg={7}>
                <CustomTextfield size="small" label="Text field title" />
              </Col>
            </Row>
          </div>
          <hr className="mt-4" style={{ border: "1px solid #F0F0F0" }}></hr>
          <div className="px-3">
            <div className="px-2 pt-2 d-flex fw-600">
              <div>Category</div>
            </div>

            <div
              className="px-2 mt-1 "
              style={{
                fontFamily: "Cerebri sans-light",
                fontSize: "12px",
                fontWeight: 300,
                color: "#888888",
              }}
            >
              Choose which category the text field should be connecting to.
            </div>

            <Row className="px-2 mt-3">
              <Col lg={5}>
                <BasicSelect label="Category" size="small" />
              </Col>
            </Row>
          </div>
          <hr className="mt-4" style={{ border: "1px solid #F0F0F0" }}></hr>

          <div className="px-3">
            <div className="d-flex justify-content-between mb-3">
              <div className="px-2 pt-2 d-flex fw-600">Text fields</div>
              <div>
                <MUIButton
                  sx={{
                    color: "#000000",
                    background: "#F0F0F0",
                    width: "227px",
                    height: "44px",
                    borderRadius: "8px",
                    fontWeight: "600",
                    fontFamily: "Cerebri Sans",
                    fontSize: "14px",
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                      boxShadow: "none",
                    },
                  }}
                  //   transformOrigin={{ horizontal: "right", vertical: "top" }}
                  //   anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  startIcon={
                    <FontAwesomeIcon
                      icon="fa-regular fa-plus"
                      style={{ color: "#000000" }}
                    />
                  }
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  ADD Language VERSION
                  {/* <Greenvector /> */}
                </MUIButton>
                <MUIMenu
                  sx={{
                    paddingTop: "0px !important",
                    marginTop: "5px",

                    "& .MuiPaper-root": {
                      border: "1px solid #F0F0F0",
                      borderRadius: "8px",
                      width: "227px",
                      backgroundColor: "#FFFFFF",
                    },

                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: "white",
                    },
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "#f9fafa",
                    },
                    "& .MuiMenuItem-root.Mui-selected:hover": {
                      backgroundColor: "#f9fafa",
                    },
                  }}
                  elevation={0}
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <MUIMenuItem
                    onClick={() => {
                      handleClose();
                      setSelectLanguageEnglishVersion(true);
                    }}
                  >
                    <div className="d-flex">
                      {" "}
                      <div>{<Englandflag />}</div>{" "}
                      <div
                        style={{
                          marginLeft: "10px",
                          paddingTop: "1px",
                          fontSize: "14px",
                          fontWeight: "300",
                          fontFamily: "Cerebri Sans",
                        }}
                      >
                        English
                      </div>
                    </div>
                  </MUIMenuItem>
                  <MUIMenuItem
                    onClick={() => {
                      handleClose();
                      setSelectLanguageDanishVersion(true);
                    }}
                  >
                    <div className="d-flex">
                      <div>{<DenmarkFlag />}</div>{" "}
                      <div
                        style={{
                          marginLeft: "10px",
                          paddingTop: "1px",
                          fontSize: "14px",
                          fontWeight: "300",
                          fontFamily: "Cerebri Sans",
                        }}
                      >
                        Danish
                      </div>
                    </div>
                  </MUIMenuItem>
                </MUIMenu>{" "}
              </div>{" "}
            </div>

            {selectLanguageEnglishVersion && (
              <Row className="my-5">
                {/* <div className="my-4 "> */}
                <Col lg={2}>
                  <div className="d-flex ps-2">
                    <div className="me-2">English</div>
                    <div>
                      <Englandflag />
                    </div>
                  </div>
                  <div
                    className="d-flex ps-2 "
                    style={{ minWidth: "max-content", marginTop: "8px" }}
                  >
                    <div
                      onClick={() => {
                        setDeleteId("en");
                        setDeleteEditor(true);
                      }}
                      className="cursor-pointer"
                    >
                      <FontAwesomeIcon
                        icon="fa-regular fa-trash"
                        style={{ color: "#000000" }}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={10}>
                  <div>
                    <CKEditor editor={SuperEditor} data="" />
                  </div>
                </Col>
              </Row>
            )}
            {selectLanguageDanishVersion && (
              <Row className="my-5">
                {/* <div className="my-4 "> */}
                <Col lg={2}>
                  <div className="d-flex ps-2">
                    <div className="me-2">Danish</div>
                    <div>
                      <DenmarkFlag />
                    </div>
                  </div>
                  <div
                    className="d-flex ps-2 "
                    style={{ minWidth: "max-content", marginTop: "8px" }}
                  >
                    <div
                      onClick={() => {
                        setDeleteId("de");
                        setDeleteEditor(true);
                      }}
                      style={{
                        background: "white",
                        border: "1px solid #D1D1D1",
                        padding: "1px 7px 2px 7px",
                      }}
                      className="cursor-pointer  radius-4"
                    >
                      <FontAwesomeIcon
                        icon="fa-regular fa-trash"
                        style={{ color: "#000000" }}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={10}>
                  <div>
                    <CKEditor
                      editor={SuperEditor}
                    // data=""
                    />
                  </div>
                </Col>
                {/* </div> */}
              </Row>
            )}
          </div>

          {/* AddTag Popup */}

          <CustomPopup
            // modalHeadingStyle={modalHeadingStyle}
            style={{ position: "relative" }}
            size="md"
            open={deleteEditor}
            onHide={() => setDeleteEditor(false)}
            title="Delete text field"
            title_color="black"
            // titleTooltipText="Need Help About Tags"
            // titleTooltip={true}
            enableCrossButton={false}
            onCloseAction={() => {
              setDeleteEditor(false);
            }}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={true}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
          >
            <div className="p-4">
              <div
                style={{
                  fontSize: "14px",
                  fontFamily: "Cerebri Sans",
                  fontWeight: "300",
                  textAlign: "left",
                  lineHeight: "17.5px",
                  height: "54px",
                  //   margin: "24px 64px 24px 24px"
                }}
              >
                <div>Are you sure you want to delete this text field? </div>
              </div>
            </div>

            <div
              className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
              style={{
                backgroundColor: "#F9FAFA",
                borderRadius: " 0px 0px 8px 8px",
                //   position: "absolute",
                //   width: "100%",
                //   bottom: "0",
              }}
            >
              <ETButton
                variant={"white"}
                buttonText="CANCEL"
                onClick={() => setDeleteEditor(false)}
              />

              <ETButton
                variant={"primary"}
                buttonText="DELETE"
                onClick={() => {
                  handleDeleteLanguageField();
                }}
              />
            </div>
          </CustomPopup>

          <div
            className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
            style={{
              backgroundColor: "#F9FAFA",
              borderRadius: " 0px 0px 8px 8px",
            }}
          >
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              width="fit-content"
            />

            <ETButton
              //   disabled={
              //     (selectLanguageDanishVersion && editorDataDanish.length > 0) ||
              //     (selectLanguageEnglishVersion &&
              //       editorDataEnglish.length > 0) ||
              //     (selectLanguageDanishVersion &&
              //       editorDataDanish.length > 0 &&
              //       selectLanguageEnglishVersion &&
              //       editorDataEnglish.length > 0)
              //       ? false
              //       : true
              //   }
              variant={"primary"}
              buttonText="CREATE TEXT FIELD"
              width="fit-content"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewTextField;
