import React from "react";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../Components/Tabs/ReactTabs.style";
import Unassigned from "./Unassigned";
import Inprocess from "./Inprocess";
import { useBreadcrumb } from "../../Components/BreadcrumbContext ";

const Leads = () => {
  const { updateBreadcrumbs } = useBreadcrumb();

  return (
    <div>
      <Tabs lassName="mb-3 pb-1 d-flex flex-column">
        <div
          className="d-flex ps-4 pe-3 py-3 justify-content-between"
          style={{
            width: "100%",
            flexDirection: "row",
            flexWrap: "wrap",
            borderBottom: "1px solid #F0F0F0",
          }}
        >
          <TabList className="light ps-0  ">
            <Tab onClick={() => updateBreadcrumbs(["Overview", "Leads", "Unassigned"])}>Unassigned</Tab>
            <Tab onClick={() => updateBreadcrumbs(["Overview", "Leads", "In process"])}>In process</Tab>
          </TabList>
        </div>
        <div className="bg-white" style={{ borderRadius: " 10px" }}>
          <TabPanel>
            <Unassigned />{" "}
          </TabPanel>
          <TabPanel>
            <Inprocess />{" "}
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
};

export default Leads;
