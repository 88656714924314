import { useQuery } from "react-query";
import {
	GetCordinationByInfo,
	GetGeographyCountry,
	GetRegionByCountryId,
	GetStreetDetails,
	GetZipCodeByRegionId,
	GetZipcodeByCountry,
} from "../../services/GeographyDropdown";

// GetGeographyCountry

export const useGetGeographyCountry = (token) => {
	return useQuery("qGetGeographyCountry", () => GetGeographyCountry(token), {
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		cacheTime: 10800000,
	});
};
// GetRegionByCountryId
export const useGetRegionByCountryId = (id) => {
	return useQuery(
		["qGetRegionByCountryId", id],
		() => GetRegionByCountryId(id),
		{ enabled: !!id },
		{ refetchOnWindowFocus: false }
	);
};

// to get zipcode by region id
export const useGetZipcodeByRegionId = (multicount) => {
	return useQuery(
		["qGetZipCodeByRegionId", multicount],
		() => GetZipCodeByRegionId(multicount),
		{ enabled: !!multicount },
		{ refetchOnWindowFocus: false }
	);
};

export const useGetZipcodeByCountry = (countryId) => {
	return useQuery(
		["qGetZipcodeByCountry", countryId],
		() => GetZipcodeByCountry(countryId),
		{ enabled: !!countryId },
		{ refetchOnWindowFocus: false }
	);
};

// -------get coordination ------///
export const useGetCoordinates = (info) => {
	return useQuery(
		["qGetCoordinationByInfo", info],
		() => GetCordinationByInfo(info),
		{ enabled: !!info },
		{ refetchOnWindowFocus: false }
	);
};

//Location - Get street details
export const useGetStreetDetails = (street_name) => {
	return useQuery(
		["qGetStreetDetails", street_name],
		() => GetStreetDetails(street_name),
		{ enabled: !!street_name },
		{ refetchOnWindowFocus: false }
	);
};
