import styled, { css } from "styled-components";

// this component is used in Tabs.js for showing portfolio
export const TabsButton = styled.button`
  white-space: nowrap;
  height: 44px;
  align-items: center;
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-family: ${({ mode }) =>
    mode == "true" ? "Cerebri sans-medium" : "Cerebri sans-light"};
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  text-align: center;
  background-color: ${({ mode }) =>
    mode == "true"
      ? (props) => props.theme.component.tabs.primary.backGroundColor
      : "#F9FAFA"};
  padding: ${({ usedFor }) =>
    usedFor == "SingleTabs" ? "11px 16px" : "11px 10px 11px 16px"};
  color: ${({ mode }) => (mode == "true" ? "#FFF" : "#000000")};
`;

//----------- this component helpful for changing  icons color as per them --------//
export const TabsButtonIcon = styled.div`
  color: ${(props) => props.theme.component.tabs.primary.color};
  cursor: pointer;
`;
export const MenusIcon = styled.div`
  color: ${(props) => props.theme.component.tabs.primary.backGroundColor};
  padding-left: 1px;
`;

// not used any where
// export const Tab = styled.button`
//   box-sizing: border-box;
//   display: inline-block;
//   border: 1px solid transparent;
//   margin-right: 8px;
//   margin-top: 6px;
//   list-style: none;
//   padding: 10px 16px;
//   cursor: pointer;
//   border-radius: 8px;
//   font-size: 13px;
//   height: 44px;
//   font-family: Cerebri sans-light;
//   border-radius: 8px;
//   border: 1px solid #f0f0f0;
//   text-align: center;
//   background-color: #f9fafa;
//   :focus {
//     background: #fff;
//     color: ${(props) => props.theme.component.tabs.primary.color};
//     /* height: 44px;  */
//     font-family: Cerebri sans-medium;
//   }
//   :active {
//     background: #fff;
//     color: ${(props) => props.theme.component.tabs.primary.color};
//     /* height: 44px;  */
//     font-family: Cerebri sans-medium;
//   }
// `;
