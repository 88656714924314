import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../../Components/Buttons/ETButton";
import { useLocation } from "react-router-dom";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import Toggle from "../../../Components/Switch/Switch";
import { useEffect } from "react";

const Export = () => {
  let location = useLocation();
  const { state } = location;
  let propId = state?.propId;
  let propInfo = state?.propertyInfo;

  const propertyFilterType = [
    {
        value: 0,
        label: "Sales",
    },
    {
        value: 1,
        label: "Rent",
    }
  ]

  // useEffect(()=> {}, [propInfo?.data?.[0]?.type])
  return (
    <div className="document-container p-4 my-2">
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          fontSize: "14px",
          border: "1px solid #F0F0F0",
        }}
      >
        <div
          className = "px-4 pb-3"
          style     = {{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div           style     = {{ fontFamily: "Cerebri Sans" }} className = "pt-3 d-flex">
          <div           className = "fw-600 fs-14">Filter</div>
          <div           style     = {{ paddingLeft: "15px" }}>
          <CustomToolTip height    = {12} text = "Filter" />
            </div>
          </div>
        </div>
        <div className="document-content py-3 px-4">
          <Row className="align-items-center justify-content-start my-3">
            <Col>
              <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">Property filter type</p>
              </div>
            </Col>
            <Col className="align-item-center">
              <div className="position-relative">
                <p className="m-0 fw-600">{(propInfo?.[0]?.type)?.toUpperCase() ?? ''}</p>
              {/* <ReactSelect
                  placeholder={"Select"}
                  options={propertyFilterType}
                //   value={signingStatus}
                //   onChange={(e) => {
                //     handleStatusChange(e, data?.document_package_id);
                //   }}
                  //   defaultValue={{ value: 0, label: "Draft" }}
                  searchable={false}
                  clearable={false}
                //   height={26}
                /> */}
                
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-start my-3">
            <Col className="mt-4">
              <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">Filter to show into Eido from this property</p>
              </div>
            </Col>
          </Row>
          { (propInfo?.[0]?.type)?.toUpperCase() === "LEASING" && <Row className="align-items-center justify-content-start my-3">
            <Col lg={4}>
            <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">Leasing price</p>
            </div>
            </Col>
            <Col lg={4}>
            <Toggle
                // checked={row.original.active_on_property == 1 ? true : false}
                // onChange={(e) => handleSwitch(row.original, e)}
                id={'filter_rent_price'}
                />
            </Col>
          </Row>}
          { (propInfo?.[0]?.type)?.toUpperCase() === "SALE" && <Row className="align-items-center justify-content-start my-3">
            <Col lg={4}>
            <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">Sales price</p>
            </div>
            </Col>
            <Col lg={4}>
            <Toggle
                // checked={row.original.active_on_property == 1 ? true : false}
                // onChange={(e) => handleSwitch(row.original, e)}
                id={'filter_sales_price'}
                />
            </Col>
          </Row>}
          <Row className="align-items-center justify-content-start my-3">
            <Col lg={4}>
            <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">Size</p>
            </div>
            </Col>
            <Col lg={4}>
            <Toggle
                // checked={row.original.active_on_property == 1 ? true : false}
                // onChange={(e) => handleSwitch(row.original, e)}
                id={'filter_unit_size'}
                />
            </Col>
          </Row>
          <Row className="align-items-center justify-content-start my-3">
            <Col lg={4}>
            <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">Floor</p>
            </div>
            </Col>
            <Col lg={4}>
            <Toggle
                // checked={row.original.active_on_property == 1 ? true : false}
                // onChange={(e) => handleSwitch(row.original, e)}
                id={'filter_unit_floor'}
                />
            </Col>
          </Row>
          <Row className="align-items-center justify-content-start my-3">
            <Col lg={4}>
            <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">Status</p>
            </div>
            </Col>
            <Col lg={4}>
            <Toggle
                // checked={row.original.active_on_property == 1 ? true : false}
                // onChange={(e) => handleSwitch(row.original, e)}
                id={'filter_property_status'}
                />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Export;
