import React, { useMemo } from "react";
import MRTTheme from "../../Components/table/MRTTheme";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../Components/table/MRTGlobalProps";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";

import { Col, Row } from "react-bootstrap";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EventsUnitsTable = () => {
  const data = [
    {
      Address: "Dybbølsbro 3, 1",
      Type: "C2",
      Unit_no: "7-W260",
      Floor: "7",
      m2: "53 m2",
      Room: "2",
      Balcony: "17 m2",
      Price: "12.750,00	",
    },
    {
      Address: "Dybbølsbro 3, 1",
      Type: "C2",
      Unit_no: "7-W260",
      Floor: "7",
      m2: "53 m2",
      Room: "2",
      Balcony: "17 m2",
      Price: "12.750,00	",
    },
    {
      Address: "Dybbølsbro 3, 1",
      Type: "C2",
      Unit_no: "7-W260",
      Floor: "7",
      m2: "53 m2",
      Room: "2",
      Balcony: "17 m2",
      Price: "12.750,00	",
    },
    {
      Address: "Dybbølsbro 3, 1",
      Type: "C2",
      Unit_no: "7-W260",
      Floor: "7",
      m2: "53 m2",
      Room: "2",
      Balcony: "17 m2",
      Price: "12.750,00	",
    },
    {
      Address: "Dybbølsbro 3, 2",
      Type: "D1",
      Unit_no: "7-W2400",
      Floor: "7",
      m2: "33 m2",
      Room: "1",
      Balcony: "15 m2",
      Price: "8.50,00	",
    },
  ];
  const columns = useMemo(
    () => [
      {
        accessorKey: "Address",
        header: "Address",
        enableHiding: false,
        size: 150,
        enableGlobalFilter: true,
      },
      {
        accessorKey: "Type",
        header: "Type",
        enableHiding: false,
        size: 80,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "Unit_no",
        header: "Unit_no",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "Floor",
        header: "Floor",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "m2",
        header: "m2",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "Room",
        header: "Room",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "Balcony",
        header: "Balcony",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "Price",
        header: "Price",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: false,
      },
    ],
    []
  );
  return (
    <div className="mt-5 px-4">
      <div className="header-semibold mb-2"> Select the unit(s) </div>
      <div className=" light-font-text mb-2">
        {" "}
        Search and select the unit(s) you want to add to your event from the
        list below.{" "}
      </div>
      <MRTTheme
        colorVariantsForTableData={["odd"]}
        table={
          <MaterialReactTable
            columns={columns}
            data={data} //data is undefined on first render
            icons={{ ...SortIcon }}
            enableColumnActions={false}
            enableRowSelection
            enableSelectAll
            enableRowNumbers
            enableRowActions
            positionActionsColumn="last"
            renderRowActions={({ row, table }) => (
              <div className="d-flex gap-2">
                <button className="icon-btn">
                  <FontAwesomeIcon
                    icon="fa-regular fa-upload"
                    style={{ color: "#000000" }}
                  />
                </button>
              </div>
            )}
            muiTableContainerProps={{
              sx: {
                padding: "0px !important",
              },
            }}
            displayColumnDefOptions={{
              "mrt-row-select": {
                header: "",
                size: 50,
              },
              "mrt-row-actions": {
                size: 50,
                header: "",
              },
              "mrt-row-numbers": {
                header: "No.",
                size: 50,
              },
            }}
            globalFilterFn="contains"
            muiTableBodyRowProps={{
              selected: false,
            }}
            muiSearchTextFieldProps={{
              ...SearchStyle,
              InputProps: {
                ...SearchIcon,
              },
            }}
            muiTablePaginationProps={{
              ...paginationoptions,
            }}
            muiTableHeadRowProps={{
              sx: {
                ...TableRowsStyle,
              },
            }}
            initialState={{ showGlobalFilter: true }}
            renderTopToolbar={({ table }) => (
              <>
                <Row className="my-3">
                  <Col lg={3}>
                    <MRT_GlobalFilterTextField table={table} />
                  </Col>{" "}
                  <Col lg={3}>
                    <ReactSelect placeholder={"Set filters"} />
                  </Col>{" "}
                </Row>
              </>
            )}
          />
        }
      />
    </div>
  );
};

export default EventsUnitsTable;
