import { Col, Row } from "react-bootstrap";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import Toggle from "../../../../Components/Switch/Switch";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";

const projectType = () => {

    return (
    <Row
        className="ms-3 me-3  mb-3 pt-2 pb-4  "
        style={{ backgroundColor: "#fff", borderRadius: "8px" }}
      >
        <div className="mt-1 px-3 d-flex">
          <div className="fw-700">Netlify</div>
          <div className="ps-2">
            <CustomToolTip height={12} text="Netlify" />
          </div>
        </div>
        <hr className="mt-2" style={{ color: "#F0F0F0" }}></hr>
        <div className="ps-3">
        <Row className="align-items-center gap-md-3">
          <Col sm={1}>Rent</Col>
          <Col sm={4} className="d-flex align-items-center">
            <span>Yes</span>
            <span><Toggle id="eido-project-type-rent" /></span>
            <span className="ms-2">No</span>
          </Col>
        </Row>
        <Row className="align-items-center gap-md-3">
          <Col sm={1}>Sale</Col>
          <Col sm={4} className="d-flex align-items-center">
            <span>Yes</span>
            <span><Toggle id="eido-project-type-sale" /></span>
            <span className="ms-2">No</span>
          </Col>
        </Row>
        <Row className="align-items-center gap-md-3">
          <Col sm={1}>Stages</Col>
          <Col sm={4} className="d-flex align-items-center">
            <span>Yes</span>
            <span><Toggle id="eido-project-type-stages" /></span>
            <span className="ms-2">No</span>
          </Col>
        </Row>

        <div className="mt-5">
          <Row className="mb-3 align-items-center flex-wrap gap-2 gap-lg-0">
            <Col lg={1}>
                <div className="d-flex justify-content-start align-item-center">
                  <p className="mb-0 fs-16">Title 1</p>
                </div>
              </Col>
              <Col lg={4}>
              <div className="form-input ">
                  <input
                    type="text"
                    placeholder="Title 1"
                    maxLength={30}
                  />
                </div>
              </Col>
          </Row>
          <Row className="mb-3 align-items-center flex-wrap gap-2 gap-lg-0">
            <Col lg={1}>
                <div className="d-flex justify-content-start align-item-center">
                  <p className="mb-0 fs-16">Title 2</p>
                </div>
              </Col>
              <Col lg={4}>
              <div className="form-input ">
                  <input
                    type="text"
                    placeholder="Title 2"
                    maxLength={30}
                  />
                </div>
              </Col>
          </Row>

          <div className="mt-5 mb-3">
            <span>Assign a stage to the Property</span>

            
      <Row className="align-items-center justify-content-start my-3">
            <Col lg={2}>
              <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">Staging Title 1</p>
              </div>
            </Col>
            <Col lg={4}>
              <ReactSelect
                    // placeholder={"Property type"}
                    // value={value}
                    clearable={false}
                    // options={propertyTypesData?.data?.map((type) => ({
                    //     value: type?.Id,
                    //     label: `${type?.Text}`,
                    // }))}
                    // onChange={(e) => {
                    //     onChange(e);
                    // }}
                />
                
            </Col>
            </Row>
          </div>
        </div>
      </div>
    </Row>
    )
}

export default projectType;