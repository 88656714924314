import { Delete, Get, Post, Put } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

export const GetFacilityByPropertyId = (id, type = 0) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_Facility_By_PropertyId,
      null,
      id + "/type/" + type
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

export const GetFacilityByPropertyIdUnit = (id, type) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_Facility_By_PropertyId,
      null,
      id + "/type/" + type
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Get_Facility_Package_ByFacilityId
// export const GetFacilityPackageByFacilityId = (proertyId, packageId) => {
// 	return new Promise((resolve, reject) => {
// 		Get(
// 			Site.ET,
// 			Endpoint.ET.Get_Facility_Package_ByFacilityId,
// 			null,
// 			proertyId + "/packages/" + packageId
// 		)
// 			.then((response) => {
// 				resolve(response.data);
// 			})
// 			.catch((error) => {
// 				reject({ error: true, message: error.message });
// 			});
// 	});
// };
// // GetFacilityAttributesByFacilityId
// export const GetFacilityAttributesByFacilityId = (id) => {
// 	return new Promise((resolve, reject) => {
// 		Get(Site.ET, Endpoint.ET.Get_Facility_Package_ByFacilityId, null, id)
// 			.then((response) => {
// 				resolve(response.data);
// 			})
// 			.catch((error) => {
// 				reject({ error: true, message: error.message });
// 			});
// 	});
// };
// get facility package by facility package id
export const GetFacilityPackageByPackageId = (propertyId, packageId) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_Facility_Package_By_PackageId,
      null,
      propertyId + "/packages/" + packageId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Get facility package by property id for
export const GetFacilityPackageByPropertyId = (propertyId) => {
  // id = id === "" || id === null || id === undefined ? 2 : id;
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_Facility_Package_By_PropertyId,
      null,
      propertyId + "/packages"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//get facility Category
export const GetFacilityCategory = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_Facility_Category, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//get facility Group
export const GetFacilityGroup = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_Facility_Group, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// add facility package

export const AddFacilityPackage = (body) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Add_Facility_Package, null, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Add_Facility_AttributeValue
export const AddFacilityAttributeValue = (body) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Add_Facility_AttributeValue, null, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Clone_Facility_Package
export const CloneFacilityPackage = (body) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Clone_Facility_Package, null, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Edit_Facility_Package_AttributeValue
export const EditFacilityPackageAttributeValue = (body) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Edit_Facility_Package_AttributeValue, null, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Edit_Facility_Package
export const EditFacilityPackage = (body) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Edit_Facility_Package, null, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Set_Active_Facility_Package
export const SetActiveFacilityPackage = (body) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Edit_Facility_Package, null, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Delete facility package
export const DeleteFacilityPackage = (facilityPackageId) => {
  return new Promise((resolve, reject) => {
    Delete(
      Site.ET,
      Endpoint.ET.Delete_Facility_Package,
      null,
      facilityPackageId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Add_Final_Facility_Package
export const AddFinalFacilityPackage = (body) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Add_Final_Facility_Package, null, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Edit_Facility_Package_Complete_Details

export const EditFinalFacilityPackage = (body) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Edit_Facility_Package_Complete_Details, null, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Edit_Facility_Package_Basic_Details

export const EditFacilityPackageBasicDetails = (body) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Edit_Facility_Package_Basic_Details, null, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// GetFacilityAttributesByFacilityId

export const GetFacilityAttributesByFacilityId = (
  propertyId,
  unitId,
  packageId,
  facilityId
) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_Facility_Attributes_ByFacilityId,
      null,
      propertyId +
        "/unit/" +
        unitId +
        "/packages/" +
        packageId +
        "/facility/" +
        facilityId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Post Clone Facility Package
export const PostCloneFacilityPackage = (clonePayload) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Post_Clone_FacilityPackage, null, clonePayload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Edit facility detail
export const EditFacilityDetail = (body) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.SwitchtToggle_Facility_Package, null, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//=======================Edit units facilities=========================//

// Facility - GetFacilityByUnitId
export const GetFacilityByUnitId = (propertyId, unitId) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_Facility_By_UnitId,
      null,
      propertyId + "/unit/" + unitId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Facility - delete unit facility
export const DeleteUnitFacility = (propertyId, unitId, facilityId) => {
  return new Promise((resolve, reject) => {
    Delete(
      Site.ET,
      Endpoint.ET.Facility_Delete_UnitFacility,
      null,
      propertyId + "/unit/" + unitId + "/" + facilityId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Edit_Unit_Facility_Package_AttributeValue
export const EditUnitFacilityAttributeValue = (body) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Edit_Facility_Package_AttributeValue, null, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
