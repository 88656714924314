import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import DateRangeFilter from "../../Components/DateRangePicker/DateRangeFilter";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import FilterComponent from "../../reactdataTableComponent/FilterComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CommunicationData } from "./StaticData";
import { CustomersTab } from "../../Components/Tabs/CustomersTabs.style";
import EmptyMessage from "../../utils/EmptyMessage";
import {
  MRT_GlobalFilterTextField,
  MaterialReactTable,
} from "material-react-table";

function Communication() {
  const [messageTab, setMessageTab] = useState(0);
  return (
    <div>
      <div className="pt-4 pb-2 px-4 form-label-title">All Communication</div>
      <div className="px-4 pb-3">
        <Row>
          <Col xl={6} className="pb-2">
            <Row>
              <Col xl={6}>
                <FilterComponent />
              </Col>
            </Row>
          </Col>
          <Col xl={6}>
            <Row>
              <Col lg={4} className="pe-lg-0 pb-2">
                {" "}
                <ReactSelect placeholder={"Created by"} />
              </Col>
              <Col lg={8} className="pb-2">
                <DateRangeFilter
                // date={dateRange}
                // setDate={setDateRange}
                // handleDateChange={handleDateChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <hr className="my-0"></hr>
      <div>
        <div className="radius-8 mt-4 ps-4 pe-2">
          <Row>
            <Col className="d-flex align-items-center ">
              <div className="form-label-title ">All messages</div>{" "}
            </Col>
            <Col className="pe-4">
              <div className="d-flex justify-content-end gap-2">
                <CustomersTab
                  style={{ position: "relative", width: "auto" }}
                  mode={0 == messageTab}
                  className="mb-1"
                  onClick={() => {
                    setMessageTab(0);
                  }}
                >
                  <span>All messages</span>
                </CustomersTab>
                <CustomersTab
                  style={{ position: "relative", width: "auto" }}
                  mode={1 == messageTab}
                  className="mb-1"
                  onClick={() => {
                    setMessageTab(1);
                  }}
                >
                  <span>Unread messages (0)</span>
                </CustomersTab>
              </div>
            </Col>
          </Row>
          <div
            style={{ height: "510px", overflowY: "scroll" }}
            className="my-2 scrollable-container-y"
          >
            <EmptyMessage />
            {/* {CommunicationData.map((item, i) => (
              <div
                key={item.id}
                className="p-3 me-1 radius-8 my-2 messages-contaniner "
                style={{
                  borderLeft:
                    item.notification == 1
                      ? "5px solid #619130"
                      : "1px solid #F0F0F0",
                }}
              >
                <div
                  className="  d-flex justify-content-center "
                  style={{
                    borderRadius: "100%",
                    backgroundColor: "#A2C8D0",
                    alignItems: "center",
                    width: "44px",
                    height: "44px",
                    position: "relative",
                  }}
                >
                  <span
                    className="white-text fs-14"
                    style={{ position: "relative" }}
                  >
                    {" "}
                    {item.notification == 1 && (
                      <span
                        className="px-1 "
                        style={{
                          backgroundColor: " #D00200",
                          borderRadius: "100%",
                          position: "absolute",
                          right: "15px",
                          bottom: "15px",
                        }}
                      >
                        {item.notification}
                      </span>
                    )}{" "}
                    JT
                  </span>
                </div>
                <div className="d-flex w-100 ">
                  <div className="ps-4" style={{ width: "84%" }}>
                    <div className="d-flex justify-content-between">
                      <div className="form-label-title">
                        {item.name}{" "}
                        <span className="light-font-text ">
                          ({item.tenant_status})
                        </span>
                      </div>
                    </div>
                    <div
                      className="form-label-title"
                      style={{
                        color: item.notification == 1 ? "#619130" : "#888888",
                      }}
                    >
                      {item.head}
                    </div>
                    <div className="light-font-text">{item.msg}</div>
                  </div>
                  <div
                    style={{ width: "16%" }}
                    className="d-flex justify-content-end grey-text fs-12"
                  >
                    <div>
                      <div className="fs-12 light-font-text pb-1">
                        {item.date}
                      </div>
                      <div>Last outbound respond</div>
                      <div className="fs-12  light-font-text ">
                        {item.last_outbound_respond}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Communication;
