import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FilterComponent from "../../../../reactdataTableComponent/FilterComponent";
import { H4 } from "../../../../styles/Common.style";
import InputField from "../../../../Components/Textfield/InputField";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import ETButton from "../../../../Components/Buttons/ETButton";
import Toggle from "../../../../Components/Switch/Switch";
import PasswordField from "../../../../Components/GroupText/PasswordField";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";

const AddCustomerInformation = ({
  handlenext = () => {},
  handleClose = () => {},
}) => {
  const handleAccessNextScreen = () => {
    handlenext();
  };

  const handleCloseScreen = () => {
    handleClose();
  };

  return (
    <div className="">
      <Form>
        <div className="Popups-inner-scrollable-container-height scrollable-container-y">
          <div className="p-4  border-bottom">
            <div className="light-font-text">
              This contract creates a relationship between the customer and the
              selected unit.
            </div>
            <div className="light-font-text">
              Once the contract is created, a screening can be started and a
              agreement can be made.
            </div>
          </div>
          <div className="p-4  border-bottom">
            <Row className="">
              <Col lg={8}>
                <H4 className="mb-2">Units details</H4>
                <Row className="mb-2">
                  <Col className="pe-0" sm={2}>
                    <div className="light-font-text">Property:</div>
                  </Col>
                  <Col className="ps-0">
                    <div className="light-font-text">Kaktus Towers</div>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col sm={2} className="pe-0">
                    <div className="light-font-text">Address:</div>
                  </Col>
                  <Col className="ps-0">
                    <div className="light-font-text">
                      Dybølsbro 3, 17., door 11, 2450 København SV
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col sm={2} className="pe-0">
                    <div className="light-font-text">Unit ID:</div>
                  </Col>
                  <Col className="ps-0">
                    <div className="light-font-text">212-187-002</div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="p-4 pb-2 border-bottom">
            {/* all form section  */}
            <div>
              <H4 className=" pt-2 pb-3">Customer information</H4>

              {/* first name and last name */}
              <Row>
                <Col xxl={3} lg={4} md={6}>
                  <InputField
                    id={"firstname"}
                    type={"text"}
                    placeholder={"First name (required)"}
                  />
                </Col>
                <Col xxl={3} lg={4} md={6} className="ps-0">
                  <InputField
                    id={"lastName"}
                    type={"text"}
                    placeholder={"Last name (required)"}
                  />
                </Col>
                <Col
                  xxl={3}
                  lg={4}
                  md={6}
                  className="ps-0 d-flex align-items-center"
                >
                  <FontAwesomeIcon
                    icon={"fa-solid fa-heart"}
                    size="lg"
                    className="cursor-pointer "
                    style={{
                      stroke: "#AA1518",
                      strokeWidth: "30px",
                      color: "#AA151833",
                      marginLeft: "2px",
                    }}
                  />
                </Col>
              </Row>

              {/* Email section */}
              <Row className="my-3 ">
                <Col xxl={6} lg={8} md={12}>
                  <div>
                    <InputField
                      id={"Email"}
                      type={"email"}
                      placeholder={"Email (required)"}
                    />
                  </div>
                </Col>
              </Row>

              {/* Country code and phone */}
              <Row className="">
                <Col xxl={6} lg={8}>
                  <Row>
                    <Col xxl={4} lg={5} md={4} className="pe-0">
                      <ReactSelect placeholder={"Country code"} />
                    </Col>
                    <Col xxl={8} lg={7} md={8}>
                      <div>
                        <InputField
                          id={"Phone"}
                          maxLength={15}
                          type={"number"}
                          placeholder={"Phone"}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* Social security number  */}
              <Row className="my-3 ">
                <Col xxl={6} lg={8}>
                  <PasswordField
                    id={"ssn"}
                    placeholder={"Social Security Number"}
                    maxlength={15}
                  />
                </Col>
              </Row>

              {/* Street , no , floor , side / door */}
              <Row>
                <Col xxl={6} lg={8} className="">
                  <Row style={{ position: "relative" }}>
                    <Col xl={4} lg={4} md={6} className="mb-3 pe-lg-0">
                      <div>
                        <InputField
                          id={"street"}
                          maxLength={15}
                          type={"text"}
                          placeholder={"Street"}
                        />
                      </div>

                      {/* {watch("street")?.length < 4 &&
                      watch("street")?.length > 0 &&
                      watch("country")?.value == "DK" ? (
                        <div className="fs-12 fw-400 grey-text pt-1 ">
                          Type in minimum 4 characters to get a search result
                        </div>
                      ) : (
                        <>
                          {watch("country")?.value == "DK" &&
                            isSearchStreetVisible && (
                              <div>
                                <div
                                  className="bg-white radius-8 pe-1 filtered-list"
                                  style={{
                                    border: "1px solid #D1D1D1",
                                    position: "absolute",
                                    // top: "50px",
                                    // left: "14px",
                                    zIndex: "10",
                                    width: "31%",
                                  }}
                                >
                                  <>
                                    {getStreetDetailsData?.data?.length > 0 ? (
                                      <div>
                                        {getStreetDetailsData?.data?.map(
                                          (item, index) => (
                                            <React.Fragment key={index}>
                                              <div
                                                className="mt-1 fs-12"
                                                key={index}
                                                onClick={() => {
                                                  // handleStreetDetailClick(item);
                                                }}
                                              >
                                                <span className="px-2">
                                                  {item?.city ||
                                                  item?.zipcode ||
                                                  item?.house_number ? (
                                                    <> {item?.address} </>
                                                  ) : (
                                                    <> {item?.street_name}</>
                                                  )}
                                                </span>
                                              </div>
                                              <hr className="hr mt-1 mb-0"></hr>
                                            </React.Fragment>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      <div className="mt-1 px-2 fw-300 grey-text fs-12">
                                        No data found
                                      </div>
                                    )}
                                  </>
                                </div>
                              </div>
                            )}
                        </>
                      )} */}
                    </Col>

                    <Col lg={2} md={6} className=" mb-3 pe-lg-0">
                      <div>
                        <InputField
                          id={"house_number"}
                          type={"text"}
                          placeholder={"No."}
                        />
                      </div>
                    </Col>
                    <Col lg={2} md={6} className=" mb-3 pe-lg-0">
                      <InputField
                        id={"floor_no"}
                        type={"text"}
                        placeholder={"Floor"}
                      />
                    </Col>
                    <Col lg={2} md={6} className="mb-3 pe-lg-0">
                      <InputField
                        id={"side"}
                        type={"text"}
                        placeholder={"Side"}
                      />
                    </Col>
                    <Col lg={2} md={6} className="mb-3">
                      <InputField
                        id={"door"}
                        type={"text"}
                        placeholder={"Door"}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* zip code , city , country */}
              <Row className=" ">
                <Col xxl={6} lg={8}>
                  <Row>
                    <Col lg={4} md={6} className="mb-3 pe-lg-0">
                      <ReactSelect placeholder={"Zip code"} />
                    </Col>

                    <Col lg={4} md={6} className="mb-3 pe-lg-0">
                      <div>
                        <InputField
                          id={"city"}
                          type={"text"}
                          placeholder={"City"}
                        />
                      </div>
                    </Col>
                    <Col lg={4} className="mb-3 ">
                      <ReactSelect placeholder={"Country"} clearable={false} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>

          {/* Preferred languages */}
          <div className="p-4 border-bottom">
            <H4> Preferred languages</H4>
            <div className="light-font-text pt-1 pb-3">
              Select the preferred language in which the customer wants to
              communicate.
            </div>

            <Row>
              <Col lg={3}>
                <ReactSelect placeholder={"Select language"} />
              </Col>
            </Row>
          </div>

          <div className="p-4 border-bottom">
            <H4>Customer role</H4>
            <div className="light-font-text pt-1 pb-3">
              Select the customers role on this contract (Can be changed
              afterwards)
            </div>

            <div className="d-flex gap-4">
              <CustomCheckBox label={"Primary customer"} />
              <CustomCheckBox label={"Responsible for payment"} />
              <CustomCheckBox label={"Co-resident"} />
              <CustomCheckBox label={"Co-customer"} />
              <CustomCheckBox label={"Legal incapacitated"} />
              <CustomCheckBox label={"Guardian"} />
            </div>
          </div>
          {/*------------------- Customer settings ----------------  */}
          <div className="p-4">
            <H4>Customer settings</H4>
            <div className="light-font-text pt-1 pb-3">
              Select if settings below are relevant for this customer
            </div>
            <div className="d-flex gap-2 align-items-center">
              <div>
                <Toggle
                  id={"UseOnly2factorSigning"}
                  // checked={sendReservInfoToCustomerActive}
                  // onChange={(e) =>
                  //   setSendReservInfoToCustomerActive(e.target.checked)
                  // }
                />
              </div>
              <div className="light-font-text">
                Use only 2-factor signing (e.g. if the customer does not have an
                electronic ID)
              </div>
            </div>
          </div>
        </div>
        <div className="py-2 px-3 d-flex flex-row justify-content-between  footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="BACK"
            type={"button"}
            onClick={handleCloseScreen}
          />

          <ETButton
            variant={"primary"}
            buttonText="NEXT"
            onClick={handleAccessNextScreen}
          />
        </div>
      </Form>
    </div>
  );
};

export default AddCustomerInformation;

//Page created by Abu//
