// created by Suraj
import React, { useState } from "react";
import { H2, H3, H4, H5 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { Col, Row } from "react-bootstrap";
import { TabsButton } from "../../../../Components/Tabs/Tabs.style";
import Toggle from "../../../../Components/Switch/Switch";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import ETButton from "../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../Components/CustomPopup";
import CreateNewCategory from "./CreateNewCategory";
import ETAccordion from "../../../../Components/Accordion/ETAccordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import SuperEditor from "@ckeditor/ckeditor5-build-classic";
import Menus from "../../../../Components/Menus/Menus";

const FAQTabs = [
  {
    id: 1,
    category: "Lead",
  },
  {
    id: 2,
    category: "Potential customer",
  },
  {
    id: 3,
    category: "Current customer",
  },
  {
    id: 4,
    category: "Former customer",
  },
  {
    id: 5,
    category: "Need help",
  },
];

const FAQ = () => {
  const [activeFAQCategory, setActiveFAQCategory] = useState(1);

  return (
    <div>
      {/* title section */}
      <div className="px-3 pb-3 border-bottom">
        <div className="px-2 pt-3 d-flex">
          <div className="d-flex align-items-center  gap-2">
            <H2>FAQ </H2>
            <CustomToolTip text="FAQ " />
          </div>
        </div>
      </div>

      {/*All tabs section  */}
      <div>
        <div className="d-flex flex-column ">
          <div
            className="d-flex px-4 py-3  justify-content-between align-items-center border-bottom"
            style={{
              width: "100%",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <div className=" d-flex light ps-0 light" style={{ gap: "7px" }}>
              {FAQTabs?.map((item) => (
                <React.Fragment key={item?.id}>
                  <TabsButton
                    mode={activeFAQCategory == item?.id && "true"}
                    usedFor={"SingleTabs"}
                    onClick={() => setActiveFAQCategory(item?.id)}
                  >
                    {item?.category}
                  </TabsButton>
                </React.Fragment>
              ))}
            </div>
          </div>

          <div>
            <FAQTabPanel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;

const FAQTabPanel = () => {
  const [newCategoryPopup, setNewCategoryPopup] = useState({
    create: false,
    edit: false,
  });
  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleAccordionToggle = (accordionIndex) => {
    setActiveAccordion(
      activeAccordion === accordionIndex ? null : accordionIndex
    );
  };
  // console.log(newCategoryPopup, "newCategoryPopup");
  return (
    <div>
      {/* Configuration of FAQ */}
      <div className="p-4 border-bottom">
        <div className="d-flex align-items-center gap-2 ">
          <H3>Configuration of FAQ</H3>
          <CustomToolTip text="Configuration of FAQ" />
        </div>
        <div className="light-font-text ">
          <Row>
            <Col xl={7} lg={8}>
              Below you can configure the FAQ for customers who are in customer
              stage lead. The FAQ can be displayed on MyPage and can be updated
              continuously.
            </Col>
          </Row>
        </div>
      </div>

      {/* Publish FAQ */}
      <div className="p-4 border-bottom">
        <div className="d-flex align-items-center gap-2 ">
          <H4>Publish FAQ</H4>
          <CustomToolTip text="Publish FAQ" />
        </div>
        <div className="light-font-text">
          <Row>
            <Col xl={7} lg={8}>
              Select the toggle to select whether the FAQ is published or not.
            </Col>
          </Row>
        </div>
        <div className="pt-2 d-flex align-items-center gap-2">
          <span className="form-label-title">Publish</span>
          <Toggle />
        </div>
      </div>

      {/*Build FAQ  */}
      <div className="p-4">
        {/* top bar */}
        <Row>
          <Col>
            <div className="d-flex align-items-center gap-2 ">
              <H4>Build FAQ</H4>
              <CustomToolTip text="Build FAQ" />
            </div>
            <div className="light-font-text  pb-3">
              <Row>
                <Col lg={6}>
                  Select the toggle to select whether the FAQ is published or
                  not.
                </Col>
                <Col lg={3}>
                  <ReactSelect placeholder={"Choose language"} />
                </Col>
                <Col lg={3}>
                  <ETButton
                    variant={"primary"}
                    buttonText="add NEW category"
                    icon={true}
                    width="100%"
                    onClick={() =>
                      setNewCategoryPopup({ ...newCategoryPopup, create: true })
                    }
                  />
                </Col>
              </Row>
            </div>
            {accordionData?.map((item, i) => (
              <div key={i}>
                <ETAccordion
                  contextMenuRequired={true}
                  accordionIndex={i}
                  activeAccordion={activeAccordion}
                  onAccordionToggle={handleAccordionToggle}
                  menusContent={
                    <>
                      <Menus
                        menusBackgroundColor="transparent"
                        iconbordercolor="transparent"
                        itemcontainerwidth={100}
                        component={
                          <div className="fs-14">
                            <div
                              className="addpropContent px-3 py-2 "
                              onClick={() =>
                                setNewCategoryPopup({
                                  ...newCategoryPopup,
                                  edit: true,
                                })
                              }
                            >
                              Edit
                            </div>
                            <hr className="addpropertyHr m-0"></hr>
                            <div className="addpropContent px-3 py-2 ">
                              <span className="ms-1">Delete</span>
                            </div>
                          </div>
                        }
                      />
                    </>
                  }
                  header={
                    <div
                      className="d-flex radius-8"
                      style={{
                        height: "100%",
                      }}
                    >
                      <div className="d-flex align-items-center switch-section px-3">
                        <FontAwesomeIcon
                          icon="fa-regular fa-grip-dots-vertical"
                          style={{ color: "#000000" }}
                          size="lg"
                        />
                      </div>
                      <div className="ps-1 pe-3 d-flex align-items-center switch-section">
                        {" "}
                        <Toggle
                          id={`1`}
                          // checked={data.isShare}
                          // onChange={(e) => setIsRkiCheck(e.target.checked)}
                        />
                      </div>
                      <div className="d-flex px-4 align-items-center gap-3">
                        <div className="accordain-user-icon">
                          <FontAwesomeIcon
                            icon="fa-solid fa-user"
                            style={{ color: "#000000" }}
                          />
                        </div>
                        <div className="form-label-title">{item?.name}</div>
                      </div>
                    </div>
                  }
                  content={
                    <div>
                      <div className="border-bottom">
                        <Row>
                          <Col>
                            <div className="d-flex w-100">
                              {/* draggable icon section */}
                              <div
                                style={{
                                  backgroundColor: "#F9FAFA",
                                  width: "126px",
                                  borderRight: "1px solid #d1d1d1",
                                }}
                                className="d-flex align-items-center  justify-content-center px-3"
                              >
                                <FontAwesomeIcon
                                  icon="fa-regular fa-grip-dots-vertical"
                                  style={{ color: "#000000" }}
                                  size="lg"
                                />
                              </div>

                              {/* right section ui */}
                              <div className="p-4 w-100">
                                <div className="d-flex justify-content-between ">
                                  <div className="d-flex align-items-center gap-2 pb-2">
                                    <H5>Question topic</H5>
                                    <CustomToolTip text="Question topic" />
                                  </div>
                                  {/*delete button  */}
                                  <div>
                                    <button className="icon-button">
                                      {" "}
                                      <FontAwesomeIcon
                                        icon="fa-regular fa-trash"
                                        size="sm"
                                        style={{
                                          color: "#000000",
                                          // opacity: languageData?.length === 1 ? "50%" : "inherit",
                                        }}
                                      />
                                    </button>
                                  </div>
                                </div>
                                <Row>
                                  <Col md={6}>
                                    <div className="form-input ">
                                      <input
                                        type="text"
                                        placeholder="Question topic"
                                        id="Question topic"
                                        maxLength={30}
                                      />
                                      <label htmlFor="Question topic">
                                        Question topic
                                      </label>
                                    </div>
                                  </Col>
                                </Row>

                                <div className="d-flex align-items-center gap-2 pb-2 pt-4">
                                  <H5>Answer to topic</H5>
                                  <CustomToolTip text="Answer to topic" />
                                </div>
                                <CKEditor
                                  id={"1"}
                                  // onReady={(editor) => {
                                  //   // editor.focus();
                                  //   editor.editing.view.focus();

                                  //   // editor.setIdToScroll(data?.Id);
                                  // }}
                                  focusedElement={true}
                                  editor={SuperEditor}
                                  // data={data?.description}
                                  // onChange={(event, editor) => {
                                  //   handleChangeDescription(
                                  //     index,
                                  //     data,
                                  //     editor?.getData()
                                  //   );
                                  // }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="d-flex justify-content-end p-4 ">
                        <ETButton
                          variant={"primary"}
                          buttonText="add NEW TOPIC"
                          icon={true}
                          width="fit-content"
                        />
                      </div>
                    </div>
                  }
                />
              </div>
            ))}
          </Col>
        </Row>
      </div>

      {/*  */}
      {/* Create new user popup */}
      <CustomPopup
        open={newCategoryPopup.create}
        onHide={() =>
          setNewCategoryPopup({ ...newCategoryPopup, create: false })
        }
        title="Create new category"
        title_color="black"
        size={"lg"}
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Create new category"}
      >
        <CreateNewCategory
          newCategoryPopup={newCategoryPopup}
          setNewCategoryPopup={setNewCategoryPopup}
        />
      </CustomPopup>

      {/*edit user popup*/}
      <CustomPopup
        open={newCategoryPopup.edit}
        onHide={() => setNewCategoryPopup({ ...newCategoryPopup, edit: false })}
        title="Edit category"
        title_color="black"
        size={"lg"}
        enableCrossButton={false}
        titleTooltip={true}
        titleTooltipText={"Edit category"}
      >
        <CreateNewCategory
          newCategoryPopup={newCategoryPopup}
          setNewCategoryPopup={setNewCategoryPopup}
          isEditCategory={true}
        />
      </CustomPopup>
    </div>
  );
};

SuperEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};

const accordionData = [
  {
    id: 1,
    name: "Document",
  },
  {
    id: 2,
    name: "Home",
  },
  {
    id: 3,
    name: "Occupation",
  },
  {
    id: 4,
    name: "Place",
  },
];
