import React from "react";
import { H4, H5 } from "../../../styles/Common.style";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { useGetUserRoles } from "../../../hooks/queries/useUserQueries";
import CustomReactSelect from "../../../Components/DropDown/CustomReactSelect";

const UserRoles = () => {
  const { data: userRoleData } = useGetUserRoles();
  const userRoles = userRoleData?.data;
  return (
    <div>
      {userRoles?.map((userRole, index) => {
        return (
          <div className="px-4 pt-4">
            <H4>User Role no. {index + 1}</H4>
            <div className="light-font-text pb-4 pt-3">
              A user can have several different user roles in the system, but
              only one per division.
            </div>
            <Row>
              <Col lg={4} className="mb-lg-0 mb-sm-3">
                <H5>User Role</H5>
                <div className="pt-2">
                  <ReactSelect
                    disabled={true}
                    value={{
                      value: userRole?.role_id,
                      label: userRole?.role_name,
                    }}
                  />
                </div>
              </Col>
              <Col lg={4} className="mb-lg-0 mb-sm-3">
                <H5>Division</H5>
                <div className="pt-2">
                  <ReactSelect
                    disabled={true}
                    value={{
                      value: userRole?.division_id,
                      label: userRole?.division_name,
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-lg-3 pt-sm-0">
              <Col lg={4}>
                <H5>Teams</H5>
                <div className="pt-2">
                  <CustomReactSelect
                    placeholder={"Assign to teams"}
                    // inputValue={value}
                    disabled={true}
                    isSearchable={false}
                    searchBarHeader="Search for team"
                    isMulti={true}
                    value={
                      userRole?.team_details?.length > 0
                        ? userRole?.team_details?.map((team) => {
                            return {
                              value: team?.user_team_id,
                              label: team?.name,
                            };
                          })
                        : ""
                    }
                  />
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
      <div className="light-font-text pt-4 pb-4 px-4">
        Note: Changes to your role, division or team has to be done by a System
        Admin with access to Organisation level
      </div>
    </div>
  );
};

export default UserRoles;
