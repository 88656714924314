import EventOverviewContainer from "./EventOverviewContainer";
import RegisterTable from "./RegisterTable";
import { DeleteRegistration } from "./DeleteEvents";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
import CustomPopup from "../../../Components/CustomPopup";
import ETButton from "../../../Components/Buttons/ETButton";

const Registration = () => {
  const [startnewIndex, setStartIndex] = useState(0);
  const [highlightIndex, setHighlightIndex] = useState(-1);
  const [changeSlotTime, setChangeSlotTime] = useState(false);
  const [deleteRegistration, setDeleteRegistration] = useState(false);

  const dataArray = [
    {
      id: 1,
      date: "19 Feb, 2023",
    },

    {
      id: 2,
      date: "23 Feb, 2023",
    },
    {
      id: 3,
      date: "26 Feb, 2023",
    },
    {
      id: 4,
      date: "28 Feb, 2023",
    },
    {
      id: 5,
      date: "1 Mar, 2023",
    },
    {
      id: 6,
      date: "17 Mar, 2023",
    },
    {
      id: 7,
      date: "25 Mar, 2023",
    },
    {
      id: 8,
      date: "28 Mar, 2023",
    },
    {
      id: 9,
      date: "1 Apr, 2023",
    },
    {
      id: 10,
      date: "19 Apr, 2023",
    },
    {
      id: 11,
      date: "23 Apr, 2023",
    },
    {
      id: 12,
      date: "26 Apr, 2023",
    },
  ];

  const agentList = [
    {
      id: 1,
      agent: "Max Wildersans",
      customers: [],
    },

    {
      id: 2,
      agent: "Julie Andersson",
      customers: [],
    },
    {
      id: 3,
      agent: "Donald Junior McBeth",
      customers: [],
    },
    {
      id: 4,
      agent: "Adam Sandler",
      customers: [],
    },
    {
      id: 5,
      agent: "Aurthur morgan",
      customers: [],
    },
    {
      id: 6,
      agent: "John marston",
      customers: [],
    },
  ];
  const showData = dataArray.slice(startnewIndex, startnewIndex + 4);

  const handleNext = () => {
    if (startnewIndex + 4 < dataArray.length) {
      setStartIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (startnewIndex > 0) {
      setStartIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleHighlight = (id) => {
    // if (highlightIndex === id) {
    //   // Clear the highlight if the same index is clicked again
    //   setHighlightIndex(-1);
    // } else {
    //   setHighlightIndex(id);
    // }
    setHighlightIndex(id);
  };
  return (
    <div>
      <div className="mx-4 mb-3">
        {" "}
        <div className="d-flex ">
          <button
            style={{
              borderRight: "0px",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              cursor: startnewIndex === 0 && "not-allowed",
            }}
            className="calendar-button"
            onClick={handlePrevious}
            disabled={startnewIndex === 0}
          >
            <FontAwesomeIcon
              icon="fa-regular fa-chevron-left"
              style={{ color: "#000000" }}
            />
          </button>

          <Row
            className="w-100"
            style={{
              overflow: "hidden",
              margin: "0px",
              borderTop: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            {showData.map((data, i) => (
              <Col className="p-0" sm={3}>
                <div
                  onClick={() => handleHighlight(data.id)}
                  style={{
                    backgroundColor:
                      highlightIndex === data.id ? "#77C7A1" : "#ffffff",
                    borderLeft: "1px solid #f0f0f0",

                    borderRight:
                      // i === showData.length - 1
                      //   ? "1px solid #f0f0f0"
                      //   :
                      "0px solid #f0f0f0",
                    color: highlightIndex === data.id ? "#FFFFFF" : "#888888",
                  }}
                  className="cursor-pointer fs-18  py-2 d-flex justify-content-center align-items-center"
                  key={data.id}
                >
                  {data.date}
                </div>
              </Col>
            ))}
          </Row>

          <button
            style={{
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              cursor: startnewIndex + 4 >= dataArray.length && "not-allowed",
            }}
            className="calendar-button"
            onClick={handleNext}
            disabled={startnewIndex + 4 >= dataArray.length}
          >
            <FontAwesomeIcon
              icon="fa-regular fa-chevron-right"
              style={{ color: "#000000" }}
            />
          </button>
        </div>{" "}
      </div>
      <div className="mx-4">
        <EventOverviewContainer />
      </div>
      {/* <div className="mx-4 mb-3">
        {" "}
        <div className="d-flex ">
          <button
            style={{
              borderRight: "0px",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              cursor: startnewIndex === 0 && "not-allowed",
            }}
            className="calendar-button"
            onClick={handlePrevious}
            disabled={startnewIndex === 0}
          >
            <FontAwesomeIcon
              icon="fa-regular fa-chevron-left"
              style={{ color: "#000000" }}
            />
          </button>

          <Row
            className="w-100"
            style={{
              overflow: "hidden",
              margin: "0px",
              borderTop: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            {showData.map((data, i) => (
              <Col className="p-0" sm={3}>
                <div
                  onClick={() => handleHighlight(data.id)}
                  style={{
                    backgroundColor:
                      highlightIndex === data.id ? "#77C7A1" : "#ffffff",
                    borderLeft: "1px solid #f0f0f0",

                    borderRight:
                      // i === showData.length - 1
                      //   ? "1px solid #f0f0f0"
                      //   :
                      "0px solid #f0f0f0",
                    color: highlightIndex === data.id ? "#FFFFFF" : "#888888",
                  }}
                  className="cursor-pointer fs-18  py-2 d-flex justify-content-center align-items-center"
                  key={data.id}
                >
                  {data.date}
                </div>
              </Col>
            ))}
          </Row>

          <button
            style={{
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              cursor: startnewIndex + 4 >= dataArray.length && "not-allowed",
            }}
            className="calendar-button"
            onClick={handleNext}
            disabled={startnewIndex + 4 >= dataArray.length}
          >
            <FontAwesomeIcon
              icon="fa-regular fa-chevron-right"
              style={{ color: "#000000" }}
            />
          </button>
        </div>{" "}
      </div> */}
      <div>
        <RegisterTable setDeleteRegistration={setDeleteRegistration} />
      </div>
      <div>
        {" "}
        <DeleteRegistration
          open={deleteRegistration}
          close={setDeleteRegistration}
        />
        <CustomPopup
          open={changeSlotTime}
          onHide={() => {
            setChangeSlotTime(false);
          }}
          title="Delete package"
          title_color="black"
          enableCrossButton={false}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
          deletePopUpStyle={true}
          titleTooltip={false}
        >
          <div className="p-3">
            <div className="px-2">
              <div className="light-font-text my-1" style={{ height: "60px" }}>
                <div>
                  Are you sure you want to change the slot time for Maria Louise
                  Vangsgaard from 10:10 - 10:20 to 10:45 - 10:55.
                </div>

                <div>
                  {" "}
                  A new confirmation email will be sent to the participant.
                </div>
              </div>
            </div>
          </div>
          <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
            <ETButton
              variant={"white"}
              buttonText="COLD FEET"
              onClick={() => {
                setChangeSlotTime(false);
              }}
            />
            <ETButton variant={"primary"} buttonText="I'M SURE" />
          </div>
        </CustomPopup>
      </div>
    </div>
  );
};

export default Registration;
