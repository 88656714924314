import { Delete, Get, Post, Put } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

//Get Company By Organisation Id
export const GetCompanyByOrganizationId = (Id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Company_GetCompanyByOrganisationId,
      null,
      Id + "/company"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Get Company By Level
export const GetCompanyByLevel = (level, organisation_id, type) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Company_GetCompanyByLevel,
      null,
      level + "/" + organisation_id + "/type/" + type
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Get Company By Public type
export const GetCompanyByPublicType = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Company_GetPublicType, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Post Company - Add Company

export const PostAddCompany = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Company_AddCompany, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Delete Company
export const DeleteComapany = (companyId) => {
  return new Promise((resolve, reject) => {
    Delete(Site.ET, Endpoint.ET.Company_DeleteComapany, null, companyId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Company Get Company By CompanyId
export const GetCompanyByCompanyId = (companyId) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Company_GetCompanyByCompanyId, null, companyId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Update company
export const UpdateCompany = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Company_UpadateCompany, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

////Get company overview by company Id
export const GetCompanyOverviewByCompanyId = (company_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Company_Get_Company_Overview_By_CompanyId,
      null,
      company_id + "/overview"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
