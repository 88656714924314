import React, { useEffect, useState } from "react";
import { Col, Row, Toast } from "react-bootstrap";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import Menus from "../../../../Components/Menus/Menus";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  PredefinedFields,
  ScreeningFieldsTabs,
} from "../../../../styles/Common.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  RadioInput,
  RadioLabel,
  RadioWrapper,
} from "../../../../Components/RadioButton/ETRadioButton";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import ETButton from "../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../Components/CustomPopup";
import Accordion from "../../../../Components/Accordion/Accordion";
import YesNoButton from "../../../../Components/Buttons/YesNoButton";
import Toggle from "../../../../Components/Switch/Switch";
import FieldConditions from "../screeningFormsComponent/FieldConditions";
import ScreeningConditions from "../screeningFormsComponent/ScreeningConditions";
import TagToolTip from "../../../../Components/Tooltip/PropertyTagToolTip";
import EditChoices from "../screeningFormsComponent/EditChoices";
import {
  CategoryfieldsDetailSection,
  PredefinedScreeningFieldsData,
} from "../StaticData";
import { useFieldArray } from "react-hook-form";
import { useScreening } from "../../../../hooks/stores/useScreeningStore";
import ScreeningSection from "./ScreeningSection";
import { toast } from "react-toastify";
import { useGetlanguages } from "../../../../hooks/queries/uselanguageQueries";
import { event } from "jquery";
import { useGetScreeningFormBasicDetails } from "../../../../hooks/queries/useScreeningQueries";

export const AccordionContent = ({
  screeningCategoryId,
  screeningData,
  handleAddScreening,
  setValue,
  getValues,
  errors,
  register,
  handleSubmit,
  control,
  useForm,
  Controller,
  selectedId,
  setYellowFlagMessage,
  setRedFlagMessage,
  screeningSectionData,
  setScreeningSectionData,
  fields,
  append,
  remove,
}) => {
  const [fieldCondition, setFieldCondition] = useState(false);
  const [screeningConditions, setScreeningConditions] = useState(false);
  const [editChoices, setEditChoices] = useState(false);
  const [showDelete, setshowDelete] = useState({});
  const [showClone, setShowClone] = useState({});
  const [activeTabId, setActiveTabId] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [addFieldsSection, setAddFieldsSection] = useState(true);
  const [ShowOnPrescreening, setShowOnPrescreening] = useState(1);
  const [screeningFieldId, setScreeningFieldId] = useState("");
  const [cardData, setCardData] = useState([]);
  const [questionIndex, setQuestionIndex] = useState({
    screeningIndex: 0,
    questionIndex: 0,
  });

  // const [actionType, setActionType] = useState({ value: 1, label: "Show" });
  // const [logicType, setLogicType] = useState({ value: 1, label: "Any" });
  // const [screeningSectionData, setScreeningSectionData] = useState([]); // Initialize state
  const headlineArray =
    screeningSectionData?.questionFields?.headlineArray || [];

  const {
    isRadioChecked,
    setIsRadioChecked,
    isScreeningRadioChecked,
    setIsScreeningRadioChecked,
    categoryJsonData,
    setCategoryJsonData,
    sectionJsonData,
    setSectionJsonData,
    languageId,
    selectedLanguageId,
    isFieldConditionActivated,
    setIsFieldConditionActivated,
    fieldConditionRules,
    // redFlagMessage,
    // yellowFlagMessage,
    isYellowFlagMessageActivated,
    setIsYellowFlagMessageActivated,
    isRedFlagMessageActivated,
    setIsRedFlagMessageActivated,
    isYellowFlagActivated,
    setIsYellowFlagActivated,
    isRedFlagActivated,
    setIsRedFlagActivated,
    yellowScreeningConditions,
    redScreeningConditions,
    redScreeningCondition,
    yellowScreeningRules,
    setYellowScreeningRules,
    possibleValue,
    questionJsonData,
    setQuestionJsonData,
    screeningLogicType,
    setScreenigLogicType,
    // setScreeningSectionStoreData,
    addSectionAllow,
    EditscreeningForm,
    screeeningConditionsStore,
    setScreeningConditionsStore,
    fieldConditionsStore,
    setFieldConditionsStore,
  } = useScreening((state) => state);
  // TODO: REMOVE HARDCODED VALUE 0 AND ADD screeningFormId
  const { data: GetScreeningFormBasicDetailsData } =
    useGetScreeningFormBasicDetails(0);
  const [categoryHeadline, setCategoryHeadline] = useState(
    selectedLanguageId.map((lang) => ({
      language_id: lang,
      text: "",
    }))
  );
  const [categoryDescription, setCategoryDescription] = useState(
    selectedLanguageId.map((lang) => ({
      language_id: lang,
      text: "",
    }))
  );
  // const [yellowFlagMessage, setYellowFlagMessage] = useState(
  // 	selectedLanguageId.map((lang) => ({
  // 		language_id: lang,
  // 		text: "",
  // 	}))
  // );
  const [sectionHeadline, setSectionHeadline] = useState(
    selectedLanguageId.map((lang) => ({
      language_id: lang,
      text: "",
    }))
  );
  // console.log("headline--", headline);
  const [sectionDescription, setSectionDescription] = useState(
    selectedLanguageId.map((lang) => ({
      language_id: lang,
      text: "",
    }))
  );
  // console.log("yellowFlagMessage", yellowFlagMessage);
  // console.log("selectedLanguageId", selectedLanguageId);
  const [headline, setHeadline] = useState(
    selectedLanguageId.map((lang) => ({
      language_id: lang,
      text: "",
    }))
  );
  // console.log("🚀 ~ file: AccordionContent1.js:157 ~ headline:", headline)
  // console.log("headline--", headline);
  const [description, setDescription] = useState(
    selectedLanguageId.map((lang) => ({
      language_id: lang,
      text: "",
    }))
  );
  // console.log("description", description);
  const [generalToggle, setGeneralToggle] = useState("");
  const [placeholder, setPlaceholder] = useState(
    selectedLanguageId.map((lang) => ({
      language_id: lang,
      text: "",
    }))
  );
  const [label, setLabel] = useState(
    selectedLanguageId.map((lang) => ({
      language_id: lang,
      text: "",
    }))
  );
  // console.log("🚀 ~ file: AccordionContent1.js:179 ~ label:", label)
  const [headlineVal, setHeadlineVal] = useState("");
  const [inputFieldValues, setInputFieldValues] = useState({});
  const [placeholderChange, setPlaceholderChange] = useState("");
  const [groupPlaceHolder, setGroupPlaceHolder] = useState("");
  const [groupLabel, setGroupLabel] = useState("");
  const totalQuestionFieldsCount = screeningSectionData?.reduce(
    (acc, data) => acc + data?.questionFields?.length,
    0
  );
  const { data: getlanguages } = useGetlanguages();

  // const { watch, reset } = useForm({
  // 	defaultValues: {
  // 		screeningSection: [
  // 			{
  // 				screening_category_id: "",
  // 				section_id: Math.floor(Date.now() / 1000),
  // 				section_headline: selectedLanguageId?.map((lang) => ({
  // 					language_id: lang,
  // 					text: "",
  // 				})),
  // 				section_description: selectedLanguageId?.map((lang) => ({
  // 					language_id: lang,
  // 					text: "",
  // 				})),
  // 				display_order_no: "",
  // 				questionFields: [],
  // 			},
  // 		],
  // 	},
  // });
  // const {
  // 	fields: fields,
  // 	append,
  // 	prepend,
  // 	remove,
  // 	swap,
  // 	move,
  // 	insert,
  // 	update,
  // } = useFieldArray({
  // 	control,
  // 	name: "screeningSection",
  // });
  // console.log("fields----", fields);
  useEffect(() => {
    setScreeningSectionData(fields);
  }, [fields]);

  // useEffect(() => {
  // 	if (EditscreeningForm) {
  // 		// debugger;
  // 		let initScreeningSectionData =
  // 			GetScreeningFormBasicDetailsData?.data?.[0]?.sections?.map(
  // 				(section, secIndex) => {
  // 					const section_headline = section?.name;
  // 					const section_description = section?.description;
  // 					const questionFields = section?.questions;

  // 					return {
  // 						section_headline,
  // 						section_description,
  // 						questionFields,
  // 					};
  // 				}
  // 			);
  // 		setValue("screeningSection", initScreeningSectionData);
  // 		setScreeningSectionData(initScreeningSectionData);
  // 		// console.log("initScreeningSectionData:", initScreeningSectionData);
  // 	}
  // }, [EditscreeningForm, GetScreeningFormBasicDetailsData]);

  // useEffect(() => {
  // 	setScreeningSectionStoreData(screeningSectionData);
  // }, [screeningSectionData]);
  const handleRadioChange = () => {
    setIsRadioChecked(!isRadioChecked);
  };
  const handleScreeningRadioChange = () => {
    setIsScreeningRadioChecked(!isScreeningRadioChecked);
  };
  const handleAddSectionButton = (categoryId) => {
    append({
      section_category_id: categoryId,
      section_id: Math.floor(Date.now() / 1000),
      section_headline: selectedLanguageId?.map((lang) => ({
        language_id: lang,
        text: "",
      })),
      section_description: selectedLanguageId?.map((lang) => ({
        language_id: lang,
        text: "",
      })),
      display_order_no: "",
      questionFields: [],
    });
  };
  const handleHeadlineChange = (sectionId, screeningFieldId, event) => {
    setHeadline((headline) => {
      // Create a copy of the current headline state
      const updatedHeadline = [...headline];

      // Find the index of the object with the specified language_id
      const index = updatedHeadline?.findIndex(
        (item) => item?.language_id === languageId
      );

      if (index !== -1) {
        // Update the text if the language_id exists
        updatedHeadline[index] = {
          language_id: languageId,
          text: event.target.value,
        };
      } else {
        // If the language_id is not found, add a new object
        updatedHeadline.push({
          language_id: languageId,
          text: event.target.value,
        });
      }

      return updatedHeadline;
    });

    setScreeningSectionData((prevData) => {
      const newData = prevData.map((section) => {
        if (section.id === sectionId) {
          const updatedQuestionFields = section?.questionFields?.map(
            (qfield) => {
              if (qfield?.screening_field_id == screeningFieldId) {
                const updatedHeadlineArray = qfield?.headlineArray?.map(
                  (lab) => ({
                    language_id: lab.language_id,
                    text:
                      lab.language_id === languageId
                        ? event.target.value
                        : qfield?.headlineArray?.find(
                            (h) => h.language_id === lab.language_id
                          )?.text,
                  })
                );
                // console.log("updatedHeadlineArray", updatedHeadlineArray);
                return {
                  ...qfield,
                  headlineArray: updatedHeadlineArray,
                };
              }
              return qfield;
            }
          );

          return {
            ...section,
            questionFields: updatedQuestionFields,
          };
        }
        return section;
      });
      // console.log("newData updatedHeadlineArray", newData);

      return newData;
    });
  };
  // console.log(
  // 	"after handleHeadlineChange screeningSectionData",
  // 	screeningSectionData
  // );
  const handleInputChange = (fieldId, screeningFieldId, value) => {
    // setStoreFieldId(fieldId);
    setInputFieldValues((prevValues) => ({
      ...prevValues,
      [`${fieldId}-${screeningFieldId}`]: value,
    }));

    // Find the corresponding field and inputField
    const updatedFields = fields?.map((field) => {
      if (field?.id == fieldId) {
        field?.questionFields?.forEach((questionField) => {
          questionField?.field_list?.forEach((inputField) => {
            if (inputField?.screening_field_id == screeningFieldId) {
              inputField.label = value;
            }
          });
        });
      }
      return field;
    });
    // Update the state with the modified fields
    // setFields(updatedFields);
  };
  const handlePlaceHolderChange = (fieldId, screeningFieldId, value) => {
    setPlaceholderChange((prevValues) => ({
      ...prevValues,
      [`${fieldId}-${screeningFieldId}`]: value,
    }));

    const updatedPlaceHolderFields = fields?.map((field) => {
      if (field.id == fieldId) {
        field.questionFields?.forEach((questionField) => {
          questionField.field_list?.forEach((inputField) => {
            if (inputField?.screening_field_id == screeningFieldId) {
              inputField.placeholder = value;
            }
          });
        });
      }
      return field;
    });
  };

  const handleGroupPlaceChange = (fieldId, screeningFieldId, value) => {
    setGroupPlaceHolder((prevValues) => ({
      ...prevValues,
      [`${fieldId}-${screeningFieldId}`]: value,
    }));

    const updatedFields = fields?.map((field) => {
      if (field.id === fieldId) {
        field.questionFields?.forEach((questionField) => {
          if (questionField.screening_field_id === screeningFieldId) {
            questionField.placeholder = value;
          }
        });
      }
      return field;
    });

    // Now, you can set the updatedFields back to your state or wherever you store it.
    // For example, if fields is a state variable, you can do:
    // setFields(updatedFields);
  };
  //----------------------------------------solution for array data----------------------------//
  // const [modifyData, setModifyData] = useState([])
  // console.log("🚀 ~ file: AccordionContent1.js:1596 ~ modifyData:", modifyData)
  // const deepClone = (obj) => {
  // 	if (obj === null || typeof obj !== 'object') {
  // 		return obj;
  // 	}

  // 	if (Array.isArray(obj)) {
  // 		const newArr = [];
  // 		for (let i = 0; i < obj.length; i++) {
  // 			newArr[i] = deepClone(obj[i]);
  // 		}
  // 		return newArr;
  // 	}

  // 	const newObj = {};
  // 	for (const key in obj) {
  // 		if (obj.hasOwnProperty(key)) {
  // 			newObj[key] = deepClone(obj[key]);
  // 		}
  // 	}
  // 	return newObj;
  // };
  // // screeningSectionData
  // const [duplicateData, setDuplicateData] = useState();
  // console.log("🚀 ~ file: AccordionContent1.js:1620 ~ duplicateData:", duplicateData)

  // useEffect(() => {
  // 	setDuplicateData(deepClone(screeningSectionData))
  // }, [screeningSectionData])
  //----------------------------------------solution for array data end----------------------------//

  const handleHeadLinChange = (fieldId, screeningFieldId, value) => {
    setHeadlineVal((prevValues) => ({
      ...prevValues,
      [`${fieldId}-${screeningFieldId}`]: value,
    }));
    // setHeadline((headline) => {
    // 	// Create a copy of the current headline state
    // 	const updatedHeadline = [...headline];

    // 	// Find the index of the object with the specified language_id
    // 	const index = updatedHeadline?.findIndex(
    // 		(item) => item?.language_id === languageId
    // 	);

    // 	if (index !== -1) {
    // 		// Update the text if the language_id exists
    // 		updatedHeadline[index] = {
    // 			language_id: languageId,
    // 			text: value,
    // 		};
    // 	} else {
    // 		// If the language_id is not found, add a new object
    // 		updatedHeadline?.push({
    // 			language_id: languageId,
    // 			text: value,
    // 		});
    // 	}

    // 	return updatedHeadline;
    // });
    const updatedPlaceHolderFields = fields?.map((field) => {
      if (field.id === fieldId) {
        field.questionFields?.forEach((questionField) => {
          if (questionField.screening_field_id === screeningFieldId) {
            questionField.name = value;
          }
        });
      }
      return field;
    });
  };
  const handlegrupuLabelChange = (fieldId, screeningFieldId, value) => {
    setGroupLabel((prevValues) => ({
      ...prevValues,
      [`${fieldId}-${screeningFieldId}`]: value,
    }));

    const updatedPlaceHolderFields = fields?.map((field) => {
      if (field.id === fieldId) {
        field.questionFields?.forEach((questionField) => {
          if (questionField.screening_field_id === screeningFieldId) {
            questionField.label = value;
          }
        });
      }
      return field;
    });
  };
  const handleDescriptionChange = (sectionId, screeningFieldId, e) => {
    setScreeningSectionData((prevData) => {
      const newData = prevData.map((section) => {
        if (section.id === sectionId) {
          const updatedQuestionFields = section?.questionFields?.map(
            (qfield) => {
              if (qfield?.screening_field_id === screeningFieldId) {
                const updatedDescriptionArray = qfield?.headlineArray?.map(
                  (lab) => ({
                    language_id: lab.language_id,
                    text:
                      lab.language_id === languageId
                        ? event.target.value
                        : qfield?.headlineArray?.find(
                            (h) => h.language_id === lab.language_id
                          )?.text,
                  })
                );
                return {
                  ...qfield,
                  descriptionArray: updatedDescriptionArray,
                };
              }
              return qfield;
            }
          );

          return {
            ...section,
            questionFields: updatedQuestionFields,
          };
        }
        return section;
      });
      //console.log("newData", newData);

      return newData;
    });
    setDescription((description) => {
      // Create a copy of the current headline state
      const updatedDescription = [...description];

      // Find the index of the object with the specified language_id
      const index = updatedDescription?.findIndex(
        (item) => item?.language_id === languageId
      );

      if (index !== -1) {
        // Update the text if the language_id exists
        updatedDescription[index] = {
          language_id: languageId,
          text: e.target.value,
        };
      } else {
        // If the language_id is not found, add a new object
        updatedDescription?.push({
          language_id: languageId,
          text: e.target.value,
        });
      }

      return updatedDescription;
    });
  };
  const handleLabelChange = (sectionId, screeningFieldId, newText) => {
    // setScreeningSectionData((prevData) => {
    // 	const newData = prevData.map((section) => {
    // 		if (section.id === sectionId) {
    // 			debugger;
    // 			const updatedQuestionFields = section?.questionFields?.map(
    // 				(qfield) => {
    // 					if (qfield?.screening_field_id === screeningFieldId) {
    // 						return {
    // 							...qfield,
    // 							labelArray: qfield?.labelArray?.filter((lab) => ({
    // 								language_id: lab.language_id,
    // 								text:
    // 									lab.language_id === languageId
    // 										? newText
    // 										: qfield?.labelArray?.find(
    // 												(h) => h.language_id == lab.language_id
    // 										  )?.text || "",
    // 							})),
    // 							// labelArray:
    // 							// 	qfield?.labelArray?.filter(
    // 							// 		(lArr, index) => lArr?.language_id == languageId
    // 							// 	)[0]?.text == newText,
    // 						};
    // 					}
    // 					return qfield;
    // 				}
    // 			);

    // 			return {
    // 				...section,
    // 				questionFields: updatedQuestionFields,
    // 			};
    // 		}
    // 		return section;
    // 	});
    // 	//console.log("newData", newData);

    // 	return newData;
    // });

    setScreeningSectionData((prevData) => {
      const newData = prevData.map((section) => {
        if (section.id == sectionId) {
          const updatedQuestionFields = section?.questionFields?.map(
            (qfield) => {
              if (qfield?.screening_field_id == screeningFieldId) {
                const updatedLabelArray = qfield?.labelArray?.map((lab) => ({
                  language_id: lab.language_id,
                  text:
                    lab.language_id === languageId
                      ? newText
                      : qfield?.labelArray?.find(
                          (h) => h.language_id === lab.language_id
                        )?.text,
                }));

                return {
                  ...qfield,
                  labelArray: updatedLabelArray,
                };
              }
              return qfield;
            }
          );

          return {
            ...section,
            questionFields: updatedQuestionFields,
          };
        }
        return section;
      });
      // console.log("newData", newData);
      return newData;
    });

    setLabel((label) => {
      // Create a copy of the current label state
      const updatedLabel = [...label];

      // Find the index of the object with the specified language_id
      const index = updatedLabel?.findIndex(
        (item) => item?.language_id === languageId
      );

      if (index !== -1) {
        // Update the text if the language_id exists
        updatedLabel[index] = {
          language_id: languageId,
          text: newText,
        };
      } else {
        // If the language_id is not found, add a new object
        updatedLabel.push({
          language_id: languageId,
          text: newText,
        });
      }

      return updatedLabel;
    });
  };
  // console.log("screeningSectionData", screeningSectionData);
  const handleYellowFlagMessageChange = (event) => {
    setYellowFlagMessage((yellowFlagMessage) => {
      // Create a copy of the current headline state
      const updatedFlagMessage = [...yellowFlagMessage];

      // Find the index of the object with the specified language_id
      const index = updatedFlagMessage?.findIndex(
        (item) => item?.language_id == languageId
      );

      if (index !== -1) {
        // Update the text if the language_id exists
        updatedFlagMessage[index] = {
          language_id: languageId,
          text: event.target.value,
        };
      } else {
        // If the language_id is not found, add a new object
        updatedFlagMessage?.push({
          language_id: languageId,
          text: event.target.value,
        });
      }

      return updatedFlagMessage;
    });
  };

  const handleRedFlagTextMessageChange = (event) => {
    setRedFlagMessage((redFlagMessage) => {
      // Create a copy of the current headline state
      const updatedFlagMessage = [...redFlagMessage];

      // Find the index of the object with the specified language_id
      const index = updatedFlagMessage?.findIndex(
        (item) => item?.language_id == languageId
      );

      if (index !== -1) {
        // Update the text if the language_id exists
        updatedFlagMessage[index] = {
          language_id: languageId,
          text: event.target.value,
        };
      } else {
        // If the language_id is not found, add a new object
        updatedFlagMessage?.push({
          language_id: languageId,
          text: event.target.value,
        });
      }

      return updatedFlagMessage;
    });
  };
  const possibleValueFields = ["select", "radio", "checkbox"];
  const handlePlaceholderChange = (sectionId, screeningFieldId, newText) => {
    setScreeningSectionData((prevData) => {
      const newData = prevData.map((section) => {
        if (section.id === sectionId) {
          const updatedQuestionFields = section?.questionFields?.map(
            (qfield) => {
              if (qfield?.screening_field_id === screeningFieldId) {
                // return {
                // 	...qfield,
                // 	placeholderArray: selectedLanguageId?.map((lang) => ({
                // 		language_id: lang,
                // 		text:
                // 			lang === languageId
                // 				? newText
                // 				: qfield?.placeholderArray?.find(
                // 						(h) => h.language_id === lang
                // 				  )?.text || "",
                // 	})),
                // };
                const updatedPlaceholderArray = qfield?.placeholderArray?.map(
                  (lab) => ({
                    language_id: lab.language_id,
                    text:
                      lab.language_id === languageId
                        ? event.target.value
                        : qfield?.placeholderArray?.find(
                            (h) => h.language_id === lab.language_id
                          )?.text,
                  })
                );
                return {
                  ...qfield,
                  placeholderArray: updatedPlaceholderArray,
                };
              }
              return qfield;
            }
          );

          return {
            ...section,
            questionFields: updatedQuestionFields,
          };
        }
        return section;
      });
      //console.log("newData", newData);

      return newData;
    });
    setPlaceholder((placeholder) => {
      // Create a copy of the current placeholder state
      const updatedPlaceholder = [...placeholder];

      // Find the index of the object with the specified language_id
      const index = updatedPlaceholder?.findIndex(
        (item) => item?.language_id === languageId
      );

      if (index !== -1) {
        // Update the text if the language_id exists
        updatedPlaceholder[index] = {
          language_id: languageId,
          text: newText,
        };
      } else {
        // If the language_id is not found, add a new object
        updatedPlaceholder.push({
          language_id: languageId,
          text: newText,
        });
      }

      return updatedPlaceholder;
    });
  };

  // console.log("redScreeningResults", redScreeningResults);
  // const redScreeningResults = redScreeningConditions.map((condition) =>
  // 	mapScreeningCondition(condition, 2)
  // );
  // console.log("yellowScreeningResults", yellowScreeningResults);
  // useEffect(() => {
  // 	setCategoryJsonData([
  // 		{
  // 			name: selectedLanguageId.map((lang) => ({
  // 				language_id: lang,
  // 				text: "",
  // 			})),
  // 			description: selectedLanguageId.map((lang) => ({
  // 				language_id: lang,
  // 				text: "",
  // 			})),
  // 		},
  // 	]);
  // 	setSectionJsonData([
  // 		{
  // 			name: selectedLanguageId.map((lang) => ({
  // 				language_id: lang,
  // 				text: "",
  // 			})),
  // 			description: selectedLanguageId.map((lang) => ({
  // 				language_id: lang,
  // 				text: "",
  // 			})),
  // 			category_id: "",
  // 			display_order_no: "",
  // 			question: [
  // 				{
  // 					screening_question_id: "",
  // 					screening_field_id: "",
  // 					headline,
  // 					description,
  // 					placeholder,
  // 					label,
  // 					possible_values: [
  // 						{
  // 							text: "",
  // 							value: selectedLanguageId.map((lang) => ({
  // 								language_id: lang,
  // 								text: "",
  // 							})),
  // 							isSelected: "",
  // 						},
  // 					],
  // 					is_required: generalToggle,
  // 					field_rules: [],
  // 					is_prescreening_question: ShowOnPrescreening,
  // 					display_order_no: "",
  // 					is_field_condition_activated: fieldCondition ? 1 : 0,
  // 					field_condition: {
  // 						action_type: actionType,
  // 						logic_type: logicType,
  // 						rules: fieldRules,
  // 					},
  // 					is_screening_condition_activated: screeningConditions ? 1 : 0,
  // 					screening_condition: [
  // 						...yellowScreeningResults,
  // 						...redScreeningResults,
  // 					],
  // 					yellow_flag_message: yellowFlagMessage,
  // 					red_flag_message: selectedLanguageId.map((lang) => ({
  // 						language_id: lang,
  // 						text: "",
  // 					})),
  // 					screening_yellow_flag_condition_id:
  // 						yellowScreeningConditions?.triggerAction?.value,
  // 					screening_red_flag_condition_id:
  // 						redScreeningConditions?.triggerAction?.value,
  // 				},
  // 			],
  // 		},
  // 	]);
  // }, [
  // 	headline,
  // 	placeholder,
  // 	label,
  // 	description,
  // 	actionType,
  // 	logicType,
  // 	fieldRules,
  // 	isYellowFlagActivated,
  // 	isYellowFlagMessageActivated,
  // 	yellowFlagMessage,
  // 	yellowScreeningConditions,
  // 	redScreeningConditions,
  // 	redFlagMessage,
  // ]);
  // useEffect(() => {
  // 	const section = screeningSectionData?.map((sectionDataItem, index) => ({
  // 		name: selectedLanguageId.map((lang) => ({
  // 			language_id: lang,
  // 			text: "",
  // 		})),
  // 		description: selectedLanguageId.map((lang) => ({
  // 			language_id: lang,
  // 			text: "",
  // 		})),
  // 		category_id: "1",
  // 		display_order_no: index,
  // 		question: [
  // 			{
  // 				screening_question_id: "1",
  // 				screening_field_id: "1",
  // 				headline,
  // 				description,
  // 				placeholder,
  // 				label,
  // 				possible_values: possibleValue,
  // 				is_required: generalToggle,
  // 				field_rules: [],
  // 				is_prescreening_question: ShowOnPrescreening,
  // 				display_order_no: "",
  // 				is_field_condition_activated: fieldCondition ? 1 : 0,
  // 				field_condition: {
  // 					action_type: actionType,
  // 					logic_type: logicType,
  // 					rules: fieldRules,
  // 				},
  // 				is_screening_condition_activated: screeningConditions ? 1 : 0,
  // 				screening_condition: [
  // 					...yellowScreeningResults,
  // 					...redScreeningResults,
  // 				],
  // 				yellow_flag_message: yellowFlagMessage,
  // 				red_flag_message: selectedLanguageId.map((lang) => ({
  // 					language_id: lang,
  // 					text: "",
  // 				})),
  // 				screening_yellow_flag_condition_id:
  // 					yellowScreeningConditions?.triggerAction?.value,
  // 				screening_red_flag_condition_id:
  // 					redScreeningConditions?.triggerAction?.value,
  // 			},
  // 		],
  // 	}));
  // 	setSectionJsonData(section);
  // }, [screeningSectionData]);

  // console.log("sectionJsonData", sectionJsonData);
  useEffect(() => {
    setCategoryJsonData([
      {
        name: selectedLanguageId.map((lang) => ({
          language_id: lang,
          text: "",
        })),
        description: selectedLanguageId.map((lang) => ({
          language_id: lang,
          text: "",
        })),
      },
    ]);
  }, [selectedLanguageId]);
  // useEffect(() => {
  // 	setSectionJsonData([
  // 		{
  // 			name: selectedLanguageId.map((lang) => ({
  // 				language_id: lang,
  // 				text: "aBc",
  // 			})),
  // 			description: selectedLanguageId.map((lang) => ({
  // 				language_id: lang,
  // 				text: "pqr",
  // 			})),
  // 			category_id: "1",
  // 			display_order_no: "1",
  // 			question: [
  // 				{
  // 					screening_question_id: "1",
  // 					screening_field_id: "1",
  // 					headline,
  // 					description,
  // 					placeholder,
  // 					label,
  // 					possible_values: possibleValue,
  // 					is_required: generalToggle,
  // 					field_rules: [],
  // 					is_prescreening_question: ShowOnPrescreening,
  // 					display_order_no: "",
  // 					is_field_condition_activated: fieldCondition ? 1 : 0,
  // 					field_condition: {
  // 						action_type: actionType,
  // 						logic_type: logicType,
  // 						rules: fieldRules,
  // 					},
  // 					is_screening_condition_activated: screeningConditions ? 1 : 0,
  // 					screening_condition: [
  // 						...yellowScreeningResults,
  // 						...redScreeningResults,
  // 					],
  // 					yellow_flag_message: yellowFlagMessage,
  // 					red_flag_message: selectedLanguageId.map((lang) => ({
  // 						language_id: lang,
  // 						text: "",
  // 					})),
  // 					screening_yellow_flag_condition_id:
  // 						yellowScreeningConditions?.triggerAction?.value,
  // 					screening_red_flag_condition_id:
  // 						redScreeningConditions?.triggerAction?.value,
  // 				},
  // 			],
  // 		},
  // 	]);
  // }, [
  // 	setHeadline,
  // 	setDescription,
  // 	setPlaceholder,
  // 	setLabel,
  // 	selectedLanguageId,
  // 	possibleValue,
  // 	redScreeningConditions,

  // 	ShowOnPrescreening,
  // 	generalToggle,
  // 	possibleValue,
  // ]);

  // useEffect(() => {
  // 	setSectionJsonData((prevData) => ({
  // 		...prevData,
  // 		name: selectedLanguageId.map((lang) => ({
  // 			language_id: lang,
  // 			text: "",
  // 		})),
  // 		description: selectedLanguageId.map((lang) => ({
  // 			language_id: lang,
  // 			text: "",
  // 		})),
  // 	}));
  // }, [selectedLanguageId]);

  // useEffect(() => {
  // 	setSectionJsonData((prevData) => ({
  // 		...prevData,
  // 		question: [
  // 			...prevData.question,
  // 			{
  // 				headline,
  // 				description,
  // 				placeholder,
  // 				label,
  // 			},
  // 		],
  // 	}));
  // }, [headline, placeholder, description, label]);

  // useEffect(() => {
  // 	// Update state based on other dependencies
  // }, [actionType, logicType, fieldRules /* other dependencies */]);

  const queryAttr = "data-rbd-drag-handle-draggable-id";
  const queryAttrDroppable = "data-rbd-droppable-id";
  const [placeholderProps, setPlaceholderProps] = useState({});
  const getDraggedDom = (draggableId) => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);
    return draggedDOM;
  };
  const getDraggedDomDroppable = (droppableId) => {
    const domQuery = `[${queryAttrDroppable}='${droppableId}']`;
    const draggedDOM = document.querySelector(domQuery);
    return draggedDOM;
  };

  //---------------handle drag and drop-------------// (Work in progress)
  let fieldIndex = "";
  const handleOnDragEnd = (result) => {
    // debugger;
    setPlaceholderProps({});
    const { source, destination } = result;
    fieldIndex = result?.draggableId?.split("_")?.[1];
    const sectionIndex = parseInt(destination?.droppableId?.split("_")?.[1]);

    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;

    // Set start and end droppable Ids
    const start = source.droppableId;
    const end = destination.droppableId;

    // If start is the same as end, we're in the same column
    if (start === end) {
      // Move the item within the list
      const newList = [...fields];
      const [movedItem] = newList?.[sectionIndex]?.questionFields?.splice(
        source.index,
        1
      );

      newList?.[sectionIndex]?.questionFields?.splice(
        destination.index,
        0,
        movedItem
      );
      // Update the field array
      setValue("screeningSection", newList);
      return null;
    } else {
      if (
        source.droppableId === "predefinedScreeningFields" &&
        screeningCategoryId == 1
      ) {
        // Check if the dragged field is a group field
        if (screeningData[fieldIndex - 1]?.is_group_field == 1) {
          const groupField = {
            field_list: [],
            ...screeningData[fieldIndex - 1],
          };

          // Find children fields for the group field
          const childrenFields = screeningData?.filter(
            (sD) => sD?.parent_screening_field_id == fieldIndex
          );

          // Populate the group field's field_list with the children fields
          groupField.field_list = childrenFields;

          const updatedSections = [...fields];
          const section = updatedSections[sectionIndex];
          section.questionFields = Array.isArray(section?.questionFields)
            ? section.questionFields
            : [];

          // Insert the group field into the section's questionFields
          section.questionFields.splice(destination.index, 0, groupField);

          // Update the section headline
          // section.section_headline = [];
          // section.section_description = [];

          setValue("screeningSection", updatedSections);
        } else {
          const singleField = screeningData?.[fieldIndex - 1];
          const updatedSections = [...fields];
          const section = updatedSections[sectionIndex];
          section.questionFields = Array.isArray(section?.questionFields)
            ? section.questionFields
            : [];

          // Insert the group field into the section's questionFields
          section.questionFields.splice(destination.index, 0, singleField);
          // section.section_headline = [];
          // section.section_description = [];
        }
      } else if (
        source.droppableId === "predefinedScreeningFields" &&
        screeningCategoryId != 1
      ) {
        let fieldData = screeningData?.filter(
          (sD) => sD?.screening_field_id == fieldIndex
        )[0];
        if (fieldData?.is_group_field == 1) {
          const groupField = {
            field_list: [],
            ...fieldData,
          };

          // Find children fields for the group field
          const childrenFields = screeningData?.filter(
            (sD) => sD?.parent_screening_field_id == fieldIndex
          );

          // Populate the group field's field_list with the children fields
          groupField.field_list = childrenFields;

          const updatedSections = [...fields];
          const section = updatedSections[sectionIndex];
          section.questionFields = Array.isArray(section?.questionFields)
            ? section.questionFields
            : [];

          // Insert the group field into the section's questionFields
          section.questionFields.splice(destination.index, 0, groupField);

          // Update the section headline
          // section.section_headline = [];
          // section.section_description = [];

          setValue("screeningSection", updatedSections);
        } else {
          const singleField = fieldData;
          const updatedSections = [...fields];
          const section = updatedSections[sectionIndex];
          section.questionFields = Array.isArray(section?.questionFields)
            ? section.questionFields
            : [];

          // Insert the group field into the section's questionFields
          section.questionFields.splice(destination.index, 0, singleField);
          // section.section_headline = "";
          // section.section_description = "";
        }
      } else if (source.droppableId === "additionalFieldSection") {
        let fieldData = screeningData?.filter(
          (sD) => sD?.screening_field_id == fieldIndex
        )[0];
        const singleField = fieldData;
        const updatedSections = [...fields];
        const section = updatedSections[sectionIndex];
        section.questionFields = Array.isArray(section?.questionFields)
          ? section.questionFields
          : [];

        // Insert the group field into the section's questionFields
        section.questionFields.splice(destination.index, 0, singleField);
      }
    }
    const updatedSections = [...fields];
    const section = updatedSections[sectionIndex];
    // section.screening_category_id = screeningCategoryId;
    section.sectionHeadlineArray = selectedLanguageId?.map((lang) => ({
      language_id: lang,
      text: "",
    }));
    section.sectionDescriptionArray = selectedLanguageId?.map((lang) => ({
      language_id: lang,
      text: "",
    }));
    // section.questionFields = section?.questionFields?.map((qfield) => {
    // 	qfield.labelArray = selectedLanguageId?.map(
    // 		(lang) => ({
    // 			language_id: lang,
    // 			text: "",
    // 		}),
    // 		(qfield.headlineArray = selectedLanguageId?.map((lang) => ({
    // 			language_id: lang,
    // 			text: "",
    // 		})))

    // 	);
    // 	return qfield; // Make sure to return the modified qfield
    // });
    section.questionFields = section?.questionFields?.map((qfield) => {
      qfield.labelArray = selectedLanguageId?.map((lang) => ({
        language_id: lang,
        text: "",
      }));

      qfield.headlineArray = selectedLanguageId?.map((lang) => ({
        language_id: lang,
        text: "",
      }));
      qfield.placeholderArray = selectedLanguageId?.map((lang) => ({
        language_id: lang,
        text: "",
      }));
      qfield.descriptionArray = selectedLanguageId?.map((lang) => ({
        language_id: lang,
        text: "",
      }));
      qfield.is_required = "";
      qfield.is_prescreening_question = "";
      qfield.is_field_condition_activated = "";
      qfield.is_yellow_flag = 0;
      qfield.is_red_flag = 0;
      return qfield; // Make sure to return the modified qfield
    });

    //Screening condition
    const screeningConditions = section?.questionFields?.map((qfield) => ({
      screening_field_id: qfield?.screening_field_id,
      yellow_flag_condition: [
        {
          is_yellow_flag_active: 0,
          fields: [],
          triggerAction: "",
          activateMessage: 0,
          message: "",
          LogicType: "",
        },
      ],
      red_flag_condition: [
        {
          is_red_flag_active: 0,
          fields: [],
          triggerAction: "",
          activateMessage: 0,
          message: "",
          LogicType: "",
        },
      ],
    }));

    const newScreeningConditions = screeningConditions?.filter(
      (newCondition) => {
        return !screeeningConditionsStore?.some(
          (existingCondition) =>
            existingCondition?.screening_field_id?.toString() ===
            newCondition?.screening_field_id?.toString()
        );
      }
    );
    const screeningConditionArray = [
      ...screeeningConditionsStore,
      ...newScreeningConditions,
    ];
    setScreeningConditionsStore(screeningConditionArray);

    //Field condition
    const fieldConditions = section?.questionFields?.map((qfield) => ({
      screening_field_id: qfield?.screening_field_id,
      field_conditions: [
        {
          is_field_condition_active: 0,
          field_visibility: "",
          field_action: "",
          fields: [],
        },
      ],
    }));

    const newFieldConditions = fieldConditions?.filter((newCondition) => {
      return !fieldConditionsStore?.some(
        (existingCondition) =>
          existingCondition?.screening_field_id?.toString() ===
          newCondition?.screening_field_id?.toString()
      );
    });
    const fieldConditionsArray = [
      ...fieldConditionsStore,
      ...newFieldConditions,
    ];
    setFieldConditionsStore(fieldConditionsArray);

    setValue("screeningSection", updatedSections);

    // const questionObjs = screeningSectionData?.map((sD, sectionIndex) => {
    // 	return sD.questionFields.map((element, questionIndex) => ({
    // 		sectionId: sectionIndex,
    // 		screening_question_id: questionIndex,
    // 		screening_field_id: element.screening_field_id,
    // 		headline: [],
    // 		description: [],
    // 		placeholder: [],
    // 		label: [],
    // 		possible_values: [],
    // 		is_required: "",
    // 		field_rules: [],
    // 		is_prescreening_question: "",
    // 		display_order_no: "",
    // 		is_field_condition_activated: "",
    // 		field_condition: {},
    // 		is_screening_condition_activated: "",
    // 		screening_condition: [],
    // 		yellow_flag_message: [],
    // 		red_flag_message: [],
    // 		screening_yellow_flag_condition_id: "",
    // 		screening_red_flag_condition_id: "",
    // 	}));
    // });

    // const flattenedQuestionObjs = questionObjs?.flat();

    // console.log("flattenedQuestionObjs", flattenedQuestionObjs);
    // setQuestionJsonData(flattenedQuestionObjs);
  };
  // console.log("screeningSection defaultValues", fields);
  // console.log("screeningCategoryId", screeningCategoryId);
  const currentCategorySections = fields?.filter(
    (field) => field?.section_category_id == screeningCategoryId
  );
  // console.log(screeningSectionData, "handleOnDragEnd screeningSectionData");
  // console.log("questionJsonData", questionJsonData);
  const matchingQuestionField = fields?.find((field) =>
    field?.questionFields?.find(
      (questionField) => questionField?.screening_field_id == screeningFieldId
    )
  );
  const isGroupField = matchingQuestionField
    ? matchingQuestionField?.questionFields?.find(
        (questionField) => questionField?.screening_field_id == screeningFieldId
      )?.is_group_field
    : false;
  const isPrepopulatedFromDb = matchingQuestionField
    ? matchingQuestionField?.questionFields?.find(
        (questionField) => questionField?.screening_field_id == screeningFieldId
      )?.is_prepopulated_from_db
    : false;
  // console.log(
  // 	"🚀 ~ file: AccordionContent1.js:1325 ~ isPrepopulatedFromDb:",
  // 	isPrepopulatedFromDb
  // );

  const handleDragOver = (event) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDom(event.draggableId);
    const draggedDomDroppable = getDraggedDomDroppable(
      event.destination.droppableId
    );

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    let height = clientHeight;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;

    const droppableArray = [...draggedDomDroppable?.children];
    const arr = [...draggedDOM.parentNode.children];
    if (sourceIndex < destinationIndex) {
      arr.splice(sourceIndex, 1);
    }

    let clientY;
    if (event.source.droppableId?.includes("questionFields")) {
      height = "10px";
      clientY =
        parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
        arr.slice(0, destinationIndex).reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);
      clientY = clientY + clientHeight / 2;
    } else {
      height = "10px";
      clientY =
        parseFloat(window.getComputedStyle(draggedDomDroppable).paddingTop) +
        droppableArray.slice(0, destinationIndex).reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);
      clientY = clientY + clientHeight / 2;
    }

    setPlaceholderProps({
      height,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    });
  };

  const handleDragStart = (event) => {
    const draggedDOM = getDraggedDom(event.draggableId);
    const draggedDomDroppable = getDraggedDomDroppable();

    if (!draggedDOM) {
      return;
    }

    // if(!event.source.droppableId?.includes("questionFields")){
    // 	console.log("does not have que fields")
    // return;
    // }
    const { clientHeight, clientWidth } = draggedDOM;
    let height = clientHeight;
    const sourceIndex = event.source.index;
    let clientY;
    if (event.source.droppableId?.includes("questionFields")) {
      clientY =
        parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
        [...draggedDOM.parentNode.children]
          .slice(0, sourceIndex)
          .reduce((total, curr) => {
            const style = curr.currentStyle || window.getComputedStyle(curr);
            const marginBottom = parseFloat(style.marginBottom);
            return total + curr.clientHeight + marginBottom;
          }, 0);
    } else {
      height = "10px";
    }

    setPlaceholderProps({
      height,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    });
  };
  const handleOnDragClick = (data) => {
    setActiveTabId(data?.id);
    setSelectedData([data]);
  };
  const handleRemoveQuestionField = () => {
    const updatedSections = [...fields];

    // Removing screening condition from screening condtion store when question field is removed.
    const screeningFieldId =
      updatedSections?.[questionIndex?.screeningIndex]?.questionFields?.[
        questionIndex?.questionIndex
      ]?.screening_field_id;
    const updatedScreeningConditions = [...screeeningConditionsStore];
    const indexOfScreeningField = updatedScreeningConditions?.findIndex(
      (object) => object?.screening_field_id === screeningFieldId
    );
    if (indexOfScreeningField >= 0) {
      updatedScreeningConditions?.splice(indexOfScreeningField, 1);
    }

    updatedSections?.[questionIndex?.screeningIndex]?.questionFields?.splice(
      questionIndex?.questionIndex,
      1
    );
    // Removing field condition from field condtion store when question field is removed.
    const updatedFieldConditions = [...fieldConditionsStore];
    const indexOfFieldCondition = updatedFieldConditions?.findIndex(
      (object) => object?.screening_field_id === screeningFieldId
    );
    if (indexOfFieldCondition >= 0) {
      updatedFieldConditions?.splice(indexOfFieldCondition, 1);
    }

    setFieldConditionsStore(updatedFieldConditions);
    setValue("screeningSection", updatedSections);
  };

  const handleCloneQuestionField = () => {
    const updatedSections = [...fields];
    const questionToClone =
      updatedSections?.[questionIndex?.screeningIndex]?.questionFields[
        questionIndex?.questionIndex
      ];

    if (questionToClone) {
      const clonedQuestion = structuredClone(questionToClone);

      updatedSections?.[questionIndex?.screeningIndex]?.questionFields?.push(
        clonedQuestion
      );
    }

    setValue("screeningSection", updatedSections);
  };
  const handleMoveSectionUp = (selectedIndex) => {
    if (selectedIndex <= 0 || selectedIndex >= fields?.length) {
      toast.warn(
        "This section is already at the top, can't move it further up"
      ); // Can't move the object up if it's already at the top or bottom.
    }
    const sections = [...fields];

    const selectedObject = sections?.splice(selectedIndex, 1)[0]; // Remove the selected object from the array.
    sections.splice(selectedIndex - 1, 0, selectedObject); // Insert the object one index up.
    setValue("screeningSection", sections);
  };

  const handleMoveSectionDown = (selectedIndex) => {
    if (selectedIndex < 0 || selectedIndex >= fields?.length - 1) {
      // Can't move the object down if it's already at the bottom or out of bounds.
      toast.warn(
        "This section is already at the bottom, can't move it further down"
      );
    }

    const sections = [...fields];
    const selectedObject = sections.splice(selectedIndex, 1)[0]; // Remove the selected object from the array.
    sections.splice(selectedIndex + 1, 0, selectedObject); // Insert the object one index down.
    setValue("screeningSection", sections);
  };
  const filteredObjects = screeningData?.filter(
    (obj) =>
      obj?.is_predefined_field == 1 &&
      (obj?.is_group_field == 1 || obj?.parent_screening_field_id == 0)
  );
  // console.log(
  // 	"🚀 ~ file: AccordionContent1.js:1440 ~ filteredObjects:",
  // 	filteredObjects
  // );
  const handleFieldConditionSubmit = (data, e) => {
    e.preventDefault();
    // console.log("handleFieldConditionSubmit data", data);
  };
  // below code used for apply all
  // const applyEditChoices = () => {
  // 	setEditChoices(false);
  // };

  const handleSectionHeadlineChange = (sectionId, newHeadlineText) => {
    // debugger;
    setSectionHeadline((sectionHeadline) => {
      // Create a copy of the current headline state
      const updatedHeadline = [...sectionHeadline];

      // Find the index of the object with the specified language_id
      const index = updatedHeadline?.findIndex(
        (item) => item?.language_id === languageId
      );

      if (index !== -1) {
        // Update the text if the language_id exists
        updatedHeadline[index] = {
          language_id: languageId,
          text: newHeadlineText,
        };
      } else {
        // If the language_id is not found, add a new object
        updatedHeadline.push({
          language_id: languageId,
          text: newHeadlineText,
        });
      }

      return updatedHeadline;
    });

    // Update the screeningSectionData state
    setScreeningSectionData((prevData) => {
      // console.log("newHeadlineText", newHeadlineText);
      // debugger;
      return prevData.map((section) => {
        // console.log("section?.section_id", section?.section_id);
        // console.log("sectionId", sectionId);
        if (section?.section_id == sectionId) {
          // debugger;
          const headline = selectedLanguageId?.map((lang) => ({
            language_id: lang,
            text:
              lang == languageId
                ? newHeadlineText
                : section?.section_headline?.find((h) => h?.language_id == lang)
                    ?.text,
          }));
          // console.log("headline---", headline);
          return {
            ...section,

            section_headline: headline,
          };
        }
        // debugger;

        return section;
      });
      // console.log("newData handleSectionHeadlineChange", newData);
      // return newData;
    });
  };

  const handleSectionDescriptionChange = (sectionId, newHeadlineText) => {
    // debugger;
    setSectionDescription((sectionDescription) => {
      // Create a copy of the current headline state
      const updateDescription = [...sectionDescription];

      // Find the index of the object with the specified language_id
      const index = updateDescription?.findIndex(
        (item) => item?.language_id === languageId
      );

      if (index !== -1) {
        // Update the text if the language_id exists
        updateDescription[index] = {
          language_id: languageId,
          text: newHeadlineText,
        };
      } else {
        // If the language_id is not found, add a new object
        updateDescription?.push({
          language_id: languageId,
          text: newHeadlineText,
        });
      }

      return updateDescription;
    });

    // Update the screeningSectionData state
    setScreeningSectionData((prevData) => {
      return prevData.map((section) => {
        if (section?.section_id == sectionId) {
          // debugger;
          const description = selectedLanguageId?.map((lang) => ({
            language_id: lang,
            text:
              lang === languageId
                ? newHeadlineText
                : section?.section_description?.find(
                    (h) => h?.language_id === lang
                  )?.text,
          }));
          return {
            ...section,
            section_description: description,
          };
        }
        return section;
      });
      // console.log("newData", newData);
      // return newData;
    });
  };
  // console.log("screeningSectionData", screeningSectionData);
  // const requiredToggle = fields?.map((data) => {
  // 	return data.questionFields?.map((field) => {
  // 		// Check if screening_field_id matches screeningFieldId
  // 		if (field.screening_field_id === screeningFieldId) {
  // 			// If matched, return the is_required value
  // 			return field.is_required;
  // 		}
  // 		return null; // If not matched, you can handle it accordingly
  // 	});
  // });

  const requiredToggle = fields
    ?.map((data) =>
      data.questionFields
        ?.filter((field) => field.screening_field_id == screeningFieldId)
        .map((matchedField) => ({
          is_required: matchedField.is_required,
          is_required_option_editable: matchedField.is_required_option_editable,
        }))
    )
    .flat(); // Use flat() to flatten the nested arrays into a single array

  const firstMatchingField = requiredToggle?.[0];

  const screeningFieldIdDta = fields
    ?.map((data) =>
      data?.questionFields?.map(
        (matchedField) => matchedField.screening_field_id
      )
    )
    ?.flat();

  // console.log("🚀 ~ file: AccordionContent1.js:1620 ~ screeningFieldIdDta:", screeningFieldIdDta)

  return (
    <div style={{ overflowX: "hidden" }}>
      <DragDropContext
        onDragEnd={handleOnDragEnd}
        onDragStart={handleDragStart}
        onDragUpdate={handleDragOver}
      >
        <Row>
          <Col
            lg={8}
            className="pe-0 position-relative scrollable-container-y d-flex flex-column"
          >
            <div style={{ overflowY: "scroll", flexBasis: 0, flexGrow: 1 }}>
              {/* section started */}
              <div>
                {fields?.map(
                  (item, index) =>
                    item?.section_category_id?.toString() ===
                      screeningCategoryId?.toString() && (
                      <React.Fragment key={index}>
                        <div className="p-4">
                          <div className="d-flex ps-2">
                            <div style={{ width: "90%" }}>
                              <Row>
                                <Col md={6}>
                                  {/* section headings  */}
                                  <div className="fs-14 d-flex gap-2">
                                    Section headline{" "}
                                    <CustomToolTip text="Section headline" />
                                  </div>
                                  <div className="pt-2 pb-4">
                                    <Controller
                                      {...register(
                                        `screeningSection.${index}.section_headline`
                                      )}
                                      control={control}
                                      rules={{ required: false }}
                                      render={({ field }) => (
                                        <div className="form-input position-relative">
                                          <input
                                            type="text"
                                            placeholder="Section headline "
                                            id="section_headline"
                                            defaultValue={
                                              item?.section_headline?.filter(
                                                (fl) =>
                                                  fl?.language_id == languageId
                                              )?.[0]?.text
                                            }
                                            value={
                                              sectionHeadline?.filter(
                                                (hL, index) =>
                                                  hL?.language_id == languageId
                                              )[0]?.text
                                            }
                                            onChange={(e) =>
                                              handleSectionHeadlineChange(
                                                item?.section_id,
                                                e?.target?.value
                                              )
                                            }
                                          />
                                          <label for="section_headline">
                                            Section headline{" "}
                                          </label>
                                        </div>
                                      )}
                                    />
                                    {errors?.screeningSection?.[index]
                                      ?.sectionHeadline && (
                                      <p className="error-msg">
                                        This field is required
                                      </p>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={8}>
                                  {/*Section description  */}
                                  <div className="fs-14 d-flex gap-2">
                                    Section description{" "}
                                    <CustomToolTip
                                      height={12}
                                      text="Section description"
                                    />
                                  </div>
                                  <div className="pt-2 pb-3">
                                    <Controller
                                      {...register(
                                        `screeningSection.${index}.section_description`
                                      )}
                                      control={control}
                                      rules={{ required: false }}
                                      render={({ field }) => (
                                        <div className="form-float openhouse-des">
                                          <textarea
                                            name=""
                                            class="inputText"
                                            cols="20"
                                            rows="3"
                                            value={
                                              sectionDescription?.filter(
                                                (hL, index) =>
                                                  hL?.language_id == languageId
                                              )[0]?.text
                                            }
                                            onChange={(e) =>
                                              handleSectionDescriptionChange(
                                                item?.section_id,
                                                e?.target?.value
                                              )
                                            }
                                          ></textarea>
                                          <label className="floating-label">
                                            Description
                                          </label>
                                        </div>
                                      )}
                                    />
                                    {errors?.screeningSection?.[index]
                                      ?.sectionDescription && (
                                      <p className="error-msg">
                                        This field is required
                                      </p>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            {/* menus button */}
                            <div
                              style={{ width: "10%" }}
                              className="d-flex justify-content-end"
                            >
                              <Menus
                                itemcontainerwidth={"90px"}
                                component={
                                  <>
                                    <div className="addpropContent px-3 pt-2 pb-2 light-font-text">
                                      <span
                                        className="ms-1"
                                        onClick={() =>
                                          handleMoveSectionUp(index)
                                        }
                                      >
                                        Move up
                                      </span>
                                    </div>
                                    <hr className="accordionSidebar-hr my-0"></hr>
                                    <div className="addpropContent px-3 pt-2 pb-2 light-font-text">
                                      <span
                                        className="ms-1"
                                        onClick={() =>
                                          handleMoveSectionDown(index)
                                        }
                                      >
                                        Move down
                                      </span>
                                    </div>
                                    <hr className="accordionSidebar-hr my-0"></hr>
                                    <div
                                      className="addpropContent px-3 pt-2 pb-2 light-font-text"
                                      onClick={() => {
                                        remove(index);
                                      }}
                                    >
                                      <span className="ms-1">Delete</span>
                                    </div>
                                  </>
                                }
                              />
                            </div>
                          </div>
                          {/* screening questions cards */}{" "}
                          <ScreeningSection
                            screeningCategoryId={screeningCategoryId}
                            screeningData={screeningData}
                            item={item}
                            headlineArray={headlineArray}
                            setValue={setValue}
                            setActiveTabId={setActiveTabId}
                            handleOnDragClick={handleOnDragClick}
                            setshowDelete={setshowDelete}
                            nestIndex={index}
                            control={control}
                            register={register}
                            setQuestionIndex={setQuestionIndex}
                            placeholderProps={placeholderProps}
                            setCardData={setCardData}
                            addFieldsSection={addFieldsSection}
                            setAddFieldsSection={setAddFieldsSection}
                            // inputFieldValues={inputFieldValues}
                            // handleInputChange={handleInputChange}
                            screeningFieldId={screeningFieldId}
                            setScreeningFieldId={setScreeningFieldId}
                            setShowClone={setShowClone}
                            headline={headline}
                            fields={fields}
                          />
                        </div>
                        <hr className="accordionSidebar-hr my-0"></hr>
                      </React.Fragment>
                    )
                )}
                <div className="p-4 d-flex justify-content-end">
                  {/* {addSectionAllow == 1 && */}
                  <ETButton
                    variant={"primary"}
                    onClick={(e) => handleAddSectionButton(screeningCategoryId)}
                    icon={true}
                    buttonText={"add section"}
                  />
                  {/* } */}
                </div>
              </div>
              {/* section ends */}
              {/* {console.log("selectedData", selectedData)} */}
              {fieldCondition === true && (
                <div className="condition-popup-container">
                  <FieldConditions
                    setFieldCondition={setFieldCondition}
                    fieldsData={selectedData}
                    screeningCategoryId={screeningCategoryId}
                    // actionType={actionType}
                    // setActionType={setActionType}
                    // logicType={logicType}
                    // setLogicType={setLogicType}
                    // applyEditChoices={applyEditChoices}
                    cardData={cardData}
                    screeningSectionData={screeningSectionData}
                    screeningData={screeningData}
                    handleFieldConditionSubmit={handleFieldConditionSubmit}
                    handleAddScreening={handleAddScreening}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    register={register}
                    handleSubmit={handleSubmit}
                    control={control}
                    useForm={control}
                    Controller={Controller}
                  />
                </div>
              )}
              {screeningConditions === true && (
                <div className="condition-popup-container">
                  <ScreeningConditions
                    setScreeningConditions={setScreeningConditions}
                    fieldsData={selectedData}
                    screeningSectionData={screeningSectionData}
                    screeningData={screeningData}
                    handleFieldConditionSubmit={handleFieldConditionSubmit}
                    isYellowFlagActivated={isYellowFlagActivated}
                    setIsYellowFlagActivated={setIsYellowFlagActivated}
                    isYellowFlagMessageActivated={isYellowFlagMessageActivated}
                    setIsYellowFlagMessageActivated={
                      setIsYellowFlagMessageActivated
                    }
                    isRedFlagActivated={isRedFlagActivated}
                    setIsRedFlagActivated={setIsRedFlagActivated}
                    isRedFlagMessageActivated={isRedFlagMessageActivated}
                    setIsRedFlagMessageActivated={setIsRedFlagMessageActivated}
                    // setYellowScreeningConditions={setYellowScreeningConditions}
                    // setRedScreeningConditions={setRedScreeningConditions}
                    // yellowScreeningCondition={yellowScreeningCondition}
                    // redScreeningCondition={redScreeningCondition}
                    // setLogicType={setLogicType}
                    setYellowFlagMessage={setYellowFlagMessage}
                    handleYellowFlagMessageChange={
                      handleYellowFlagMessageChange
                    }
                    handleRedFlagTextMessageChange={
                      handleRedFlagTextMessageChange
                    }
                  />
                </div>
              )}
              {editChoices && (
                <div className="condition-popup-container">
                  <EditChoices
                    setEditChoices={setEditChoices}
                    // choices={selectedData}
                    fieldData={selectedData}
                    screeningData={screeningData}
                    screeningFieldId={screeningFieldId}
                    categoryHeadline={categoryHeadline}
                    setCategoryHeadline={setCategoryHeadline}
                    categoryDescription={categoryDescription}
                    setCategoryDescription={setCategoryDescription}
                  />
                </div>
              )}
            </div>
          </Col>
          <Col lg={4} className="accordionSidebar ps-0">
            <div>
              {/* justify-content-between fs-14 px-5 pt-4 pb-3  */}
              <div className="d-flex ">
                <ScreeningFieldsTabs
                  mode={addFieldsSection == true && true}
                  onClick={() => setAddFieldsSection(!addFieldsSection)}
                >
                  Add fields
                </ScreeningFieldsTabs>

                <ScreeningFieldsTabs
                  mode={addFieldsSection == false && true}
                  onClick={() => setAddFieldsSection(!addFieldsSection)}
                >
                  Field settings
                </ScreeningFieldsTabs>
              </div>
              <hr className="accordionSidebar-hr my-0"></hr>
              {/* ----------------------------------------------------------------------------------------------------------- */}
              {/* Add fields start */}
              <div style={{ display: addFieldsSection == false && "none" }}>
                {/* fields setting */}
                <div className="px-4 py-3">
                  <div className="form-label-title fs-16">
                    {screeningData?.title}
                  </div>
                  <div className="light-font-text">
                    Drag a field to the left to start building your form and
                    then start configuring it
                  </div>
                </div>
                <hr className="accordionSidebar-hr my-0"></hr>

                <div className="py-3">
                  <Accordion
                    title={
                      <div className="d-flex  gap-1 form-label-title">
                        Predefined screening fields
                        <CustomToolTip text="Predefined screening fields" />
                      </div>
                    }
                    // isActive={true}
                    alwaysOpen={true}
                    accordianBorder={false}
                    accordianHeight={"100%"}
                    contentBody={
                      <div
                        className="py-2 px-4"
                        style={{ backgroundColor: "#F9FAFA " }}
                      >
                        <div className="light-font-text">
                          <Row>
                            <Col className=" pb-1">
                              Below are pre-defined screening questions that you
                              can simply click and paste into your form. By
                              using these fields, it is also possible to pull
                              statistics across your properties.
                            </Col>
                          </Row>
                        </div>
                        {screeningData?.is_question_add_allowed == 0 ? (
                          "Not allowed to add questions."
                        ) : (
                          <Droppable
                            droppableId="predefinedScreeningFields"
                            isDropDisabled={true}
                          >
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {filteredObjects?.map((data, index) => (
                                  <Draggable
                                    draggableId={`${data?.name}_${data?.screening_field_id}`}
                                    key={`${data?.name}_${data?.screening_field_id}`}
                                    index={index}
                                    isDragDisabled={
                                      screeningFieldIdDta?.includes(
                                        data.screening_field_id
                                      )
                                        ? true
                                        : false
                                    }
                                  >
                                    {(provided) => (
                                      <PredefinedFields
                                        key={`${data?.name}${index}`}
                                        className="mt-2 px-3 gap-2"
                                        mode={
                                          screeningFieldIdDta?.includes(
                                            data.screening_field_id
                                          )
                                            ? false
                                            : true
                                        }
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <FontAwesomeIcon
                                          icon="fa-regular fa-grip-dots-vertical"
                                          style={{ color: "#000000" }}
                                        />
                                        {data?.name}

                                        <TagToolTip
                                          text={data?.name}
                                          iconColor={"#888888"}
                                        />
                                      </PredefinedFields>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        )}
                      </div>
                    }
                  />
                </div>
                {screeningCategoryId != 1 && (
                  <hr className="accordionSidebar-hr mt-0 mb-0"></hr>
                )}

                {/*Additional fields  */}
                <div className="py-3">
                  {screeningCategoryId != 1 && (
                    <Accordion
                      title={
                        <div className="d-flex align-items-center form-label-title gap-2">
                          Additional fields{" "}
                          <CustomToolTip height={12} text="Additional fields" />
                        </div>
                      }
                      accordianBorder={false}
                      accordianHeight={"100%"}
                      contentBody={
                        <div
                          className="py-2 px-4"
                          style={{ backgroundColor: "#F9FAFA " }}
                        >
                          <div className="light-font-text">
                            <Row>
                              <Col className=" pb-1">
                                If you need a question for your screening, you
                                can create a field below. Select the type of
                                field you need and customize it as you want.
                              </Col>
                            </Row>
                          </div>

                          <Droppable
                            droppableId="additionalFieldSection"
                            isDropDisabled={true}
                          >
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {screeningData?.map(
                                  (data, index) =>
                                    data?.is_predefined_field == 0 && (
                                      <Draggable
                                        draggableId={`${data?.name}_${data?.screening_field_id}`}
                                        key={`${data?.name}${data?.screening_field_id}`}
                                        index={index}
                                      >
                                        {(provided) => (
                                          <PredefinedFields
                                            key={`additionalFields_${data?.name}${data?.screening_field_id}`}
                                            className="mt-2 px-3 gap-2"
                                            mode={true}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <FontAwesomeIcon
                                              icon="fa-regular fa-grip-dots-vertical"
                                              style={{ color: "#000000" }}
                                            />
                                            {data?.name}
                                            <TagToolTip
                                              text={data?.name}
                                              iconColor={"#888888"}
                                            />
                                          </PredefinedFields>
                                        )}
                                      </Draggable>
                                    )
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      }
                    />
                  )}
                </div>
              </div>
              {/* Add fields end */}
              {/* ------------------------------------------------------------------------------------------------------------- */}
              {/*Field settings start  */}
              <div style={{ display: addFieldsSection == true && "none" }}>
                {selectedData?.map((data) => (
                  <div key={data?.id}>
                    {/* top section category title and Description */}
                    <div className="px-4 py-3">
                      <div className="form-label-title fs-16">
                        {screeningData?.title}
                      </div>
                      <div className="light-font-text">
                        Pre-defined screening field
                      </div>
                      <div className="light-font-text">
                        Original field name: {screeningData?.title}
                      </div>
                    </div>
                    <hr className="accordionSidebar-hr my-0"></hr>

                    {/*General  */}
                    <div className="py-3">
                      <Accordion
                        title={"General"}
                        alwaysOpen={true}
                        accordianBorder={false}
                        accordianHeight={"100%"}
                        contentBody={
                          <div
                            className="pt-2"
                            style={{ backgroundColor: "#F9FAFA " }}
                          >
                            <div className="px-4 pt-2">
                              <div className="fs-14 d-flex gap-2">
                                Headline{" "}
                                <CustomToolTip height={12} text="Headline" />
                              </div>
                              <div className="pt-2">
                                <div className="form-input">
                                  {fields?.map((field) => (
                                    <div key={field.id} className="">
                                      {field?.questionFields?.map(
                                        (questionField, index) => {
                                          if (
                                            questionField.screening_field_id ==
                                            screeningFieldId
                                          ) {
                                            return (
                                              <>
                                                <div
                                                  key={index}
                                                  className="pb-3"
                                                >
                                                  <div className="form-input">
                                                    <input
                                                      type="text"
                                                      id="generalHeadline"
                                                      disabled={
                                                        questionField?.is_headline_text_allowed ==
                                                        0
                                                      }
                                                      placeholder={"Headline"}
                                                      maxLength={
                                                        questionField.maxLength
                                                      }
                                                      // value={
                                                      // 	headline?.filter(
                                                      // 		(hL, index) =>
                                                      // 			hL?.language_id ==
                                                      // 			languageId
                                                      // 	)[0]?.text
                                                      // } // Bind the input value to the state variable
                                                      onChange={(e) => {
                                                        handleHeadlineChange(
                                                          field?.id,
                                                          questionField?.screening_field_id,
                                                          e
                                                        );
                                                        handleHeadLinChange(
                                                          field.id,
                                                          questionField.screening_field_id,
                                                          e.target.value
                                                        );
                                                      }}
                                                    />
                                                    <label>
                                                      Headline
                                                      {/* {questionField.placeholder} */}
                                                    </label>
                                                  </div>
                                                </div>
                                                <div key={index} className="">
                                                  <div>
                                                    <div className="fs-14 d-flex gap-2">
                                                      Description{" "}
                                                      <CustomToolTip
                                                        height={12}
                                                        text="Description"
                                                      />
                                                    </div>
                                                    <div className="py-2">
                                                      <div className="form-float openhouse-des">
                                                        <textarea
                                                          name=""
                                                          class="inputText"
                                                          disabled={
                                                            questionField?.is_headline_text_allowed ==
                                                            0
                                                          }
                                                          cols="20"
                                                          rows="3"
                                                          placeholder=" "
                                                          value={
                                                            description?.filter(
                                                              (des, index) =>
                                                                des?.language_id ==
                                                                languageId
                                                            )[0]?.text
                                                          }
                                                          onChange={(e) =>
                                                            handleDescriptionChange(
                                                              field.id,
                                                              questionField.screening_field_id,
                                                              e
                                                            )
                                                          }
                                                        />
                                                        <label className="floating-label">
                                                          Description
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          } else {
                                            return null;
                                          }
                                        }
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            {/* {screeningData} */}
                            <div className="px-4 pb-1 d-flex align-items-center gap-2">
                              <div className="fs-14">Required</div>
                              {/* <Toggle
																id={"rkiCheck21"}
																checked={generalToggle}
																onChange={(e) =>
																	setGeneralToggle(e.target.checked)
																}
															/> */}

                              <Toggle
                                id={"rkiCheck21"}
                                checked={firstMatchingField?.is_required == 1}
                                disabled={
                                  firstMatchingField?.is_required_option_editable ==
                                  0
                                }
                                onChange={(e) => {
                                  // Check if the toggle is editable before updating the state
                                  if (
                                    firstMatchingField?.is_required_option_editable !=
                                    0
                                  ) {
                                    setGeneralToggle(e.target.checked);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        }
                      />
                    </div>
                    <hr className="accordionSidebar-hr mt-0 mb-0"></hr>

                    {/* View */}

                    <div className="pb-3">
                      <div className="py-3">
                        <Accordion
                          title={"View"}
                          accordianBorder={false}
                          accordianHeight={"100%"}
                          contentBody={
                            <div
                              className="py-2"
                              style={{ backgroundColor: "#F9FAFA " }}
                            >
                              {/*List type  */}
                              <div className="px-4 pt-2">
                                {fields?.some((field) =>
                                  field?.questionFields?.some((questionField) =>
                                    questionField?.field_list?.some(
                                      (inputField) =>
                                        [
                                          "checkbox",
                                          "radio",
                                          "select",
                                        ].includes(inputField.field_type) &&
                                        inputField.parent_screening_field_id ==
                                          screeningFieldId
                                    )
                                  )
                                ) && (
                                  <>
                                    <div className="fs-14 d-flex gap-2">
                                      List type{" "}
                                      <CustomToolTip
                                        height={12}
                                        text="List type "
                                      />
                                    </div>
                                    <div className="py-2">
                                      <ReactSelect placeholder={"List type"} />
                                    </div>
                                  </>
                                )}
                              </div>
                              {/* Label */}
                              <div className="px-4 pt-2 ">
                                <div className="fs-14 d-flex gap-2">
                                  Label{" "}
                                  <CustomToolTip height={12} text="Headline" />
                                </div>
                                <div className="py-2">
                                  <div className="form-input">
                                    {/* <input
																			type="text"
																			placeholder="Label"
																			id="viewLabel"
																			value={
																				label?.filter(
																					(lb, index) =>
																						lb?.language_id == languageId
																				)[0]?.text
																			} // Bind the input value to the state variable
																			onChange={()=>{
																				handleLabelChange()
																				handleInputChange(
																					field?.id,
																					inputField?.screening_field_id,
																					e.target.value
																				)}} // Add the onChange handler
																		/> */}
                                    {/* {fields?.map((field) => (
																			<div key={field.id} className="">
																				{field?.questionFields?.map(
																					(questionField, index) => (
																						<div key={index} className="">
																							{questionField?.field_list
																								?.filter(
																									(inputField) =>
																										inputField?.parent_screening_field_id ==
																										screeningFieldId
																								)
																								?.map((inputField) => (
																									<div
																										key={
																											inputField?.screening_field_id
																										}
																										className="form-input pb-3"
																									>
																										<input
																											type="text"
																											placeholder={
																												inputField?.label
																											}
																											maxLength={
																												inputField?.maxLength
																											}
																											value={
																												label?.filter(
																													(lb, index) =>
																														lb?.language_id == languageId
																												)[0]?.text
																											} // Bind the input value to the state variable
																											onChange={(e) => {
																												handleLabelChange(e)
																												handleInputChange(
																													field?.id,
																													inputField?.screening_field_id,
																													e.target.value
																												)
																											}

																											}
																										/>
																										<label>
																											{inputField?.label ||
																												inputField?.name}
																										</label>
																									</div>
																								))}
																						</div>
																					)
																				)}
																			</div>
																		))} */}
                                    {isGroupField == 1 ? (
                                      <>
                                        {fields?.map((field) => (
                                          <div key={field.id} className="">
                                            {field?.questionFields?.map(
                                              (questionField, index) => (
                                                <div
                                                  key={index}
                                                  className="pb-3"
                                                >
                                                  {questionField?.field_list
                                                    ?.filter(
                                                      (inputField) =>
                                                        inputField?.parent_screening_field_id ==
                                                        screeningFieldId
                                                    )
                                                    ?.map((inputField) => (
                                                      <div
                                                        key={
                                                          inputField?.screening_field_id
                                                        }
                                                        className="pb-3"
                                                      >
                                                        <div className="form-input">
                                                          <input
                                                            type="text"
                                                            id="viewLabel"
                                                            // type={inputField?.field_type}
                                                            placeholder={
                                                              inputField?.label
                                                            }
                                                            maxLength={
                                                              inputField?.maxLength
                                                            }
                                                            // value={
                                                            // 	label?.filter(
                                                            // 		(lb, index) =>
                                                            // 			lb?.language_id ==
                                                            // 			languageId
                                                            // 	)[0]?.text
                                                            // } // Bind the input value to the state variable
                                                            onChange={(e) => {
                                                              handleLabelChange(
                                                                field.id,
                                                                questionField.screening_field_id,
                                                                e.target.value
                                                              );
                                                              handleInputChange(
                                                                field?.id,
                                                                inputField?.screening_field_id,
                                                                e.target.value
                                                              );
                                                            }}
                                                          />
                                                          <label>
                                                            {inputField?.name}
                                                            {/* {inputField?.label ||
																														inputField?.name} */}
                                                          </label>
                                                        </div>
                                                      </div>
                                                    ))}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        ))}
                                      </>
                                    ) : (
                                      <>
                                        <div>
                                          {fields?.map((field) => (
                                            <div key={field.id} className="">
                                              {field?.questionFields?.map(
                                                (questionField, index) => {
                                                  if (
                                                    questionField.screening_field_id ==
                                                    screeningFieldId
                                                  ) {
                                                    return (
                                                      <div
                                                        key={index}
                                                        className="pb-3"
                                                      >
                                                        <div className="form-input">
                                                          <input
                                                            type="text"
                                                            id="viewLabel"
                                                            placeholder={
                                                              questionField.label
                                                            }
                                                            maxLength={
                                                              questionField.maxLength
                                                            }
                                                            // value={
                                                            // 	label?.filter(
                                                            // 		(lb, index) =>
                                                            // 			lb?.language_id ==
                                                            // 			languageId
                                                            // 	)[0]?.text
                                                            // } // Bind the input value to the state variable
                                                            onChange={(e) => {
                                                              // handleLabelChange(
                                                              // 	field.id,
                                                              // 	questionField.screening_field_id,
                                                              // 	e.target.value
                                                              // );
                                                              // handlegrupuLabelChange(
                                                              // 	field.id,
                                                              // 	questionField.screening_field_id,
                                                              // 	e.target.value
                                                              // );
                                                            }}
                                                          />
                                                          <label>
                                                            {
                                                              questionField?.name
                                                            }
                                                            {/* {questionField.label ||
																															questionField.name} */}
                                                          </label>
                                                        </div>
                                                      </div>
                                                    );
                                                  } else {
                                                    return null;
                                                  }
                                                }
                                              )}
                                            </div>
                                          ))}
                                        </div>
                                      </>
                                    )}
                                    {/* <label for="viewLabel">Label</label> */}
                                  </div>
                                </div>
                              </div>
                              {/* Placeholder */}
                              <div className="px-4 pt-2">
                                <div className="fs-14 d-flex gap-2">
                                  Placeholder{" "}
                                  <CustomToolTip
                                    height={12}
                                    text="Placeholder"
                                  />
                                </div>
                                <div className="py-2">
                                  <div className="form-input">
                                    {/* <input
																			type="text"
																			placeholder="Placeholder"
																			id="viewPlaceholder"
																			value={
																				placeholder?.filter(
																					(pH, index) =>
																						pH?.language_id == languageId
																				)[0]?.text
																			} // Bind the input value to the state variable
																			onChange={handlePlaceholderChange} // Add the onChange handler
																		/>
																		<label for="viewPlaceholder">
																			Placeholder
																		</label> */}
                                    {isGroupField == 1 ? (
                                      <>
                                        {fields?.map((field) => (
                                          <div key={field?.id} className="">
                                            {field?.questionFields?.map(
                                              (questionField, index) => (
                                                <div key={index} className="">
                                                  {questionField?.field_list
                                                    ?.filter(
                                                      (inputField) =>
                                                        inputField?.parent_screening_field_id ==
                                                        screeningFieldId
                                                    )
                                                    ?.map((inputField) => (
                                                      <div className="pb-3">
                                                        <div
                                                          key={
                                                            inputField.screening_field_id
                                                          }
                                                          className="form-input "
                                                        >
                                                          <input
                                                            type="text"
                                                            id="viewPlaceholder"
                                                            placeholder={
                                                              inputField?.placeholder
                                                            }
                                                            maxLength={
                                                              inputField?.maxLength
                                                            }
                                                            // value={
                                                            // 	placeholder?.filter(
                                                            // 		(pH, index) =>
                                                            // 			pH?.language_id ==
                                                            // 			languageId
                                                            // 	)[0]?.text
                                                            // } // Bind the input value to the state variable
                                                            onChange={(e) => {
                                                              handlePlaceholderChange(
                                                                field?.id,
                                                                inputField?.screening_field_id,
                                                                e.target.value
                                                              );
                                                              handlePlaceHolderChange(
                                                                field?.id,
                                                                inputField?.screening_field_id,
                                                                e.target.value
                                                              );
                                                            }}
                                                          />
                                                          <label>
                                                            {inputField?.name}
                                                            {/* {inputField?.placeholder ||
																														inputField?.name} */}
                                                          </label>
                                                        </div>
                                                      </div>
                                                    ))}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        ))}
                                      </>
                                    ) : (
                                      <>
                                        <div>
                                          {fields?.map((field) => (
                                            <div key={field?.id} className="">
                                              {field?.questionFields?.map(
                                                (questionField, index) => {
                                                  if (
                                                    questionField?.screening_field_id ==
                                                    screeningFieldId
                                                  ) {
                                                    return (
                                                      <div
                                                        key={index}
                                                        className="pb-3"
                                                      >
                                                        <div className="form-input ">
                                                          <input
                                                            type="text"
                                                            id="viewPlaceholder"
                                                            placeholder={
                                                              questionField?.placeholder
                                                            }
                                                            maxLength={
                                                              questionField?.maxLength
                                                            }
                                                            // value={
                                                            // 	placeholder?.filter(
                                                            // 		(pH, index) =>
                                                            // 			pH?.language_id ==
                                                            // 			languageId
                                                            // 	)[0]?.text
                                                            // } // Bind the input value to the state variable
                                                            onChange={(e) => {
                                                              handlePlaceholderChange(
                                                                field?.id,
                                                                questionField?.screening_field_id,
                                                                e.target.value
                                                              );
                                                              handleGroupPlaceChange(
                                                                field?.id,
                                                                questionField?.screening_field_id,
                                                                e.target.value
                                                              );
                                                            }}
                                                          />
                                                          <label>
                                                            {
                                                              questionField?.name
                                                            }
                                                            {/* {questionField?.placeholder ||
																															questionField?.name} */}
                                                          </label>
                                                        </div>
                                                      </div>
                                                    );
                                                  } else {
                                                    return null;
                                                  }
                                                }
                                              )}
                                            </div>
                                          ))}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* Choices*/}

                              {possibleValueFields?.includes(
                                data?.field_type
                              ) && (
                                <div className="px-4 pt-2">
                                  <div className="fs-14 d-flex gap-2 pb-2">
                                    Choices{" "}
                                    <CustomToolTip height={12} text="Choices" />
                                  </div>
                                  <div
                                    className="accordion-radio-btn form-label-title cursor-pointer"
                                    onClick={() => {
                                      // Check if isPrepopulatedFromDb is not equal to 1 before allowing the click
                                      if (isPrepopulatedFromDb !== 1) {
                                        setEditChoices(true);
                                      }
                                    }}
                                    // Disable the element if isPrepopulatedFromDb is equal to 1
                                    disabled={isPrepopulatedFromDb === 1}
                                  >
                                    <FontAwesomeIcon
                                      icon="fa-regular fa-gear"
                                      style={{ color: "#000000" }}
                                    />
                                    Edit CHOICES
                                  </div>
                                </div>
                              )}
                            </div>
                          }
                        />
                      </div>
                      <hr className="accordionSidebar-hr mt-0 mb-0"></hr>

                      {/* prescreening */}
                      <div className="py-3">
                        <Accordion
                          title={
                            <div className="fs-14 d-flex gap-2">
                              Prescreening{" "}
                              <CustomToolTip height={12} text="Prescreening" />
                            </div>
                          }
                          accordianBorder={false}
                          accordianHeight={"100%"}
                          contentBody={
                            <div
                              className="py-2"
                              style={{ backgroundColor: "#F9FAFA " }}
                            >
                              <div className="px-4 ">
                                <div className="fs-14 d-flex gap-2 light-font-text pb-3">
                                  Would you like this field to be used in the
                                  prescreening of the customers?
                                </div>

                                <YesNoButton
                                  value={ShowOnPrescreening}
                                  setShowOnPrescreening={setShowOnPrescreening}
                                  onChange={setShowOnPrescreening}
                                />
                              </div>
                            </div>
                          }
                        />
                      </div>
                      <hr className="accordionSidebar-hr mt-0 mb-0"></hr>

                      {/* Conditions */}

                      <div className="py-3">
                        <>
                          <Accordion
                            title={
                              <div className="fs-14 d-flex gap-2">
                                Conditions{" "}
                                <CustomToolTip height={12} text="Conditions" />
                              </div>
                            }
                            accordianBorder={false}
                            accordianHeight={"100%"}
                            contentBody={
                              <div
                                className="py-2"
                                style={{ backgroundColor: "#F9FAFA " }}
                              >
                                <div className="px-4 ">
                                  <div className="fs-14 d-flex gap-2 light-font-text">
                                    {totalQuestionFieldsCount > 1 &&
                                    isGroupField == 0 ? (
                                      <>
                                        Setting up a condition on fields means
                                        that fields will only appear if a
                                        certain value in another field is true.
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="py-2">
                                    {totalQuestionFieldsCount > 1 &&
                                    isGroupField == 0 ? (
                                      <div
                                        className="accordion-radio-btn"
                                        onClick={() => setFieldCondition(true)}
                                      >
                                        <div className="d-flex ">
                                          <div>
                                            <RadioWrapper>
                                              <RadioInput
                                                type="radio"
                                                checked={isRadioChecked}
                                                onChange={handleRadioChange}
                                                name={"fieldCondition"}
                                                id={"FC1"}
                                                style={{
                                                  backgroundColor: "#ffffff",
                                                }}
                                              />
                                              <RadioLabel>
                                                <div className="form-label-title">
                                                  FIELD CONDITIONS
                                                </div>
                                              </RadioLabel>
                                            </RadioWrapper>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div className="fs-14 d-flex gap-2 light-font-text py-2">
                                    Set up a screening condition means that a
                                    specific entered value must match the match
                                    value set up for the field.
                                  </div>
                                  <div className="py-2">
                                    <div
                                      className="accordion-radio-btn "
                                      onClick={() =>
                                        setScreeningConditions(true)
                                      }
                                    >
                                      <div className="d-flex ">
                                        <div>
                                          <RadioWrapper>
                                            <RadioInput
                                              type="radio"
                                              checked={isScreeningRadioChecked}
                                              onChange={
                                                handleScreeningRadioChange
                                              }
                                              name={"screeningCondition"}
                                              id={"FC1"}
                                              style={{
                                                backgroundColor: "#ffffff",
                                              }}
                                            />
                                            <RadioLabel>
                                              <div className="form-label-title">
                                                SCREENING CONDITIONS
                                              </div>
                                            </RadioLabel>
                                          </RadioWrapper>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          />
                        </>
                      </div>
                    </div>
                  </div>
                ))}

                {/*Field settings end  */}
              </div>
            </div>
          </Col>
        </Row>
      </DragDropContext>
      {/* Delete  popup */}
      <CustomPopup
        open={showDelete.fieldPopup}
        onHide={() =>
          setshowDelete({
            ...showDelete,
            fieldPopup: false,
          })
        }
        title="Delete question field"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="p-3">
          <div className="px-2 mb-5">
            <div className="light-font-text my-1">
              Are you sure you want to delete this field{" "}
            </div>
          </div>
        </div>
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container gap-1">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() =>
              setshowDelete({
                ...showDelete,
                fieldPopup: false,
              })
            }
          />
          <ETButton
            variant={"primary"}
            buttonText="DELETE"
            onClick={() => {
              handleRemoveQuestionField();
              setshowDelete({
                ...showDelete,
                fieldPopup: false,
              });
            }}
          />
        </div>
      </CustomPopup>
      {/* {Clone popup} */}
      <CustomPopup
        open={showClone.fieldPopup}
        onHide={() =>
          setShowClone({
            ...showClone,
            fieldPopup: false,
          })
        }
        title="Clone question field"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={false}
      >
        <div className="p-3">
          <div className="px-2 mb-5">
            <div className="light-font-text my-1">
              You are cloning this question.do you want to go ahead?
            </div>
          </div>
        </div>
        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container gap-1">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() =>
              setShowClone({
                ...showClone,
                fieldPopup: false,
              })
            }
          />
          <ETButton
            variant={"primary"}
            buttonText="CLONE"
            onClick={() => {
              handleCloneQuestionField();
              setShowClone({
                ...showDelete,
                fieldPopup: false,
              });
            }}
          />
        </div>
      </CustomPopup>
    </div>
  );
};
