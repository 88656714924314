import React from "react";
import { Col, Container, Placeholder, Row } from "react-bootstrap";
import ETButton from "../../../../Components/Buttons/ETButton";

const EditTagName = ({
  setSelectedIndex,
  tagName,
  setTagName,
  handleUpdateTag,
  isLoading,
  disableDuplicateButton,
}) => {
  const handleSecondScreen = () => {
    setSelectedIndex(1);
  };
  return (
    <Container fluid className="px-0 ">
      <Placeholder animation="glow">
        <div style={{ backgroundColor: "#fff", borderRadius: "8px" }}>
          <div
            className="ps-4 mt-3"
            style={{ fontWeight: "700", fontSize: "14px", lineHeight: "22px" }}
          >
            {isLoading ? (
              <div
                className="placeholder col-5 "
                style={{ height: "35px", width: "100px" }}
              ></div>
            ) : (
              "Tag name"
            )}
          </div>

          <Row className="ps-3 mt-3" style={{ marginBottom: "337px" }}>
            <Col lg={5} className=" px-md-4  pe-lg-2  my-1 ">
              {
                isLoading ? (
                  <div
                    className="placeholder col-10 radius-8"
                    style={{ height: "42px" }}
                  ></div>
                ) : (
                  <div className="d-flex position-relative">
                    <span
                      className=" fs-14"
                      style={{
                        position: "relative",
                        top: "11px",
                        left: "18px",
                        zIndex: "1",
                      }}
                    >
                      #
                    </span>
                    <div className="tag-input">
                      <input
                        type="text"
                        placeholder="Tag Name"
                        id="edit_tag_Name"
                        value={tagName}
                        onChange={(e) => setTagName(e.target.value)}
                        maxlength={15}
                      />
                      <label htmlFor="edit_tag_Name">Tag name</label>
                    </div>
                  </div>
                )
                // <CustomTextfield
                //   label="Tag name"
                //   size="small"
                //   value={tagName}
                //   onChange={(e) => setTagName(e.target.value)}
                //   inputAdornment={"#"}
                // />
              }
            </Col>
            <Col lg={7}></Col>
          </Row>
          <div
            className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
            style={{
              backgroundColor: "#F9FAFA",
              borderRadius: " 0px 0px 8px 8px",
              width: "100%",
            }}
          >
            {isLoading ? (
              <div
                className="placeholder col-10 radius-8"
                style={{ height: "35px", width: "100px" }}
              ></div>
            ) : (
              <ETButton
                variant={"white"}
                buttonText="PREVIOUS"
                onClick={handleSecondScreen}
              />
            )}
            {isLoading ? (
              <div
                className="placeholder col-10 radius-8"
                style={{ height: "35px", width: "100px" }}
              ></div>
            ) : (
              <ETButton
                variant={"primary"}
                buttonText="SAVE"
                onClick={() => {
                  handleUpdateTag();
                }}
                disabled={isLoading || disableDuplicateButton}
              />
            )}
          </div>
        </div>
      </Placeholder>
    </Container>
  );
};

export default EditTagName;
