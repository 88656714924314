import React from "react";
import ETButton from "../../../../../../../../../Components/Buttons/ETButton";
import Toggle from "../../../../../../../../../Components/Switch/Switch";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSelect from "../../../../../../../../../Components/DropDown/ReactSelect";

const ConfirmSuggestion = ({ suggestionType, setConfirmSuggestionShow }) => {
  return (
    <div>
      <div className="Popups-inner-scrollable-container-height scrollable-container-y">
        {suggestionType == "CI" && (
          <div className="px-4 pt-4 pb-1 light-font-text">
            If you want to cancel the scheduled appointment with the customer,
            you can cancel and send a cancellation email to the customer here.
          </div>
        )}
        {suggestionType == "CS" && (
          <div className="px-4 pt-4 pb-1 ">
            <div className="light-font-text">
              The customer has suggested a new time, which can be seen below.
              Click the Send button if you want to confirm the suggested time
              and send a new confirmation to the customer.
            </div>
            <div>
              <div className="form-label-title pt-2">New suggestion:</div>
              <div className="light-font-text">
                10 Jul, 2023 from 17:30 - 17:50
              </div>
            </div>
          </div>
        )}
        <div className="form-label-title p-4">
          Invitation send to the customer
        </div>
        <div className="px-4">
          <Row className="pb-3">
            <Col>
              {/* <div className="form-input ">
            <input
              type="text"
              placeholder="To recipients"
              id="recipients"
              // value={value}
              // onChange={(e) => {
              // 	onChange(e);
              // 	clearErrors("long");
              // }}
            />
            <label htmlFor="recipients">To recipients</label>
          </div> */}

              <div className="position-relative">
                <div
                  className="bg-white position-absolute px-1  font-medium fs-8 grey-text"
                  style={{ left: "14px", top: "-5px" }}
                >
                  To recipients
                </div>
                <div className="badge-input">
                  <span
                    className="py-1 px-2 mx-1  radius-4 border "
                    style={{
                      backgroundColor: "rgba(119, 199, 161, 0.25)",
                      width: "fit-content",
                    }}
                  >
                    <FontAwesomeIcon
                      icon="fa-regular fa-circle-xmark"
                      style={{ color: "#000000", cursor: "pointer" }}
                    />
                    <span className="ps-2"> Louise Birch Justesen </span>
                  </span>

                  <span
                    className="py-1 px-2 mx-1  radius-4 border "
                    style={{
                      backgroundColor: "rgba(119, 199, 161, 0.25)",
                      width: "fit-content",
                    }}
                  >
                    <FontAwesomeIcon
                      icon="fa-regular fa-circle-xmark"
                      style={{ color: "#000000", cursor: "pointer" }}
                    />
                    <span className="ps-2"> Marc Robinson </span>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="pb-3">
            <Col>
              <div className="form-input ">
                <input
                  type="text"
                  placeholder="Cc recipients (email address, customer or system user)"
                  id="Ccrecipients"
                  // value={value}
                  // onChange={(e) => {
                  // 	onChange(e);
                  // 	clearErrors("long");
                  // }}
                />
                <label htmlFor="Ccrecipients">
                  Cc recipients (email address, customer or system user)
                </label>
              </div>
            </Col>
          </Row>
          <Row className="pb-3">
            <Col>
              <ReactSelect placeholder={"Template"} />
            </Col>
          </Row>
          <Row className="pb-3">
            <Col>
              <div className="form-input ">
                <input
                  type="text"
                  placeholder="Subject"
                  id="subject"
                  // value={value}
                  // onChange={(e) => {
                  // 	onChange(e);
                  // 	clearErrors("long");
                  // }}
                />
                <label htmlFor="subject">Subject</label>
              </div>
            </Col>
          </Row>
          <Row className="pb-3">
            <Col>
              <div className="form-float openhouse-des">
                <textarea
                  name=""
                  class="inputText"
                  cols="20"
                  rows="2"
                  placeholder=" "
                  // value={value}
                  // onChange={(e) => {
                  //   onChange(e);
                  //   clearErrors("altText");
                  //   // setAltText(e.target.value);
                  // }}
                ></textarea>
                <label className="floating-label">Message</label>
              </div>
            </Col>
          </Row>
          <div className="form-label-title pb-3">Send confirmation to :</div>
          <div className="light-font-text d-flex align-items-center gap-2 pb-3">
            {" "}
            <Toggle id={"Email"} />
            Email
          </div>
          <div className="light-font-text d-flex align-items-center gap-2 pb-3">
            {" "}
            <Toggle id={"active_users"} />
            My Page (it’s only possible to send to alreade active users)
          </div>
          <div className="light-font-text d-flex align-items-center gap-2 pb-3">
            {" "}
            <Toggle id={"SMS"} />
            SMS
          </div>
        </div>
      </div>
      <div className="py-2 px-3 d-flex flex-row justify-content-between  footer-section-button-container">
        <ETButton
          variant={"white"}
          buttonText="CANCEL"
          onClick={() => setConfirmSuggestionShow(false)}
        />

        <ETButton
          variant={"primary"}
          buttonText="NEXT"
          type={"submit"}
          onClick={() => setConfirmSuggestionShow(false)}
        />
      </div>
    </div>
  );
};

export default ConfirmSuggestion;
