import React, { useMemo } from "react";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { H2, H4 } from "../../../../styles/Common.style";

import { Col, Row } from "react-bootstrap";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import Menus from "../../../../Components/Menus/Menus";
import { MaterialReactTable } from "material-react-table";
import MRTTheme from "../../../../Components/table/MRTTheme";
import ETButton from "../../../../Components/Buttons/ETButton";
import {
  SortIcon,
  TableRowsStyle,
} from "../../../../Components/table/MRTGlobalProps";
import Toggle from "../../../../Components/Switch/Switch";
import { CheckBox } from "@mui/icons-material";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";

const OrganizationSettingSigning = () => {
  const DeclineSigningcolumns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name} ${row.notification} `,
        header: "In use",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <>
            <Toggle disabled={false} id={row.index} />
            {console.log("ROW", row)}
          </>
        ),
      },
      {
        accessorKey: "Reason",
        header: "Reason",
        enableHiding: false,
        size: 150,
        enableGlobalFilter: false,
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
      },
      {
        accessorKey: "Type",
        header: "Type",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "created",
        header: "Created",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "created_by",
        header: "Created by",
        enableHiding: false,
        size: 150,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "level",
        header: "Level",
        enableHiding: false,
        size: 80,
        enableGlobalFilter: false,
      },
    ],
    []
  );

  const CancelSigningcolumns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name} ${row.notification} `,
        header: "In use",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <>
            <Toggle disabled={false} id={row.index} />
            {console.log("ROW", row)}
          </>
        ),
      },
      {
        accessorKey: "Reason",
        header: "Reason",
        enableHiding: false,
        size: 150,
        enableGlobalFilter: false,
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
      },
      {
        accessorKey: "Type",
        header: "Type",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "created",
        header: "Created",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "created_by",
        header: "Created by",
        enableHiding: false,
        size: 150,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "level",
        header: "Level",
        enableHiding: false,
        size: 80,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "MultiCheck",
        header: "Public",
        enableHiding: false,
        size: 80,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <>
            <CustomCheckBox />
          </>
        ),
      },
    ],
    []
  );

  return (
    <div className="whiteContainerStyle">
      <div className="px-3 pb-3 border-bottom">
        <div className="px-2 pt-3 d-flex">
          <div className="d-flex align-items-end  gap-2">
            <H2>Signing</H2>
            <CustomToolTip size="sm" text="Areas" />
          </div>
        </div>
      </div>
      <div className="p-4 border-bottom">
        <div className="d-flex align-items-center  gap-2 ">
          <H4>Deadline for signing orders</H4>
          <CustomToolTip size="sm" text="Decline for signing orders" />
        </div>
        <div className="light-font-text mb-3">
          Define the deadline for this screening. The deadline counts from when
          a signing order is created in the system.
        </div>

        <Row className="mb-3">
          <Col lg={2} className="pe-0">
            <ReactSelect placeholder={"Days"} />
          </Col>
          <Col lg={2}>
            <ReactSelect placeholder={"Hours"} />
          </Col>
        </Row>
        <div className="light-font-text mb-3">
          If the screening exceed the deadline, what should happen?
        </div>
        <Row className="mb-3 ">
          <Col lg={4} className="pe-0">
            <ReactSelect placeholder={"Cancel Signing order"} />
          </Col>
        </Row>
      </div>
      <div className="px-4 pt-4 pb-3 border-bottom">
        <Row className="pb-2">
          <Col lg={8}>
            <div className="d-flex gap-2 align-items-center">
              <H4>Decline signing order reasons</H4>
              <CustomToolTip size="sm" text="Area groups" />
            </div>
            <span className="light-font-text lg-w-75">
              Here is a list of all the decline signing order reasons that can
              be used in the system and by the customer.
            </span>
          </Col>
          <Col>
            <div className="w-100 d-flex justify-content-end">
              <ETButton
                variant={"primary"}
                buttonText="CREATE New"
                onClick={() => {
                  "";
                }}
              />
            </div>
          </Col>
        </Row>

        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={DeclineSigningcolumns}
              data={DeclineSigningData} //data is undefined on first render
              icons={{ ...SortIcon }}
              enableColumnActions={false}
              enableRowSelection={false}
              enableRowActions
              enableSelectAll
              enableTopToolbar={false}
              enableGlobalFilter
              globalFilterFn="contains"
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              displayColumnDefOptions={{
                "mrt-row-select": {
                  header: "",
                },
                "mrt-row-actions": {
                  header: "",
                },
              }}
              renderRowActions={({ row }) => (
                <Menus
                  itemheight={23}
                  itemwidth={23}
                  iconborderradius="4px"
                  itemcontainerwidth="200px"
                  component={
                    <div className="light-font-text">
                      <div
                        className="p-2 cursor-pointer"
                        onClick={() => {
                          "";
                        }}
                      >
                        Edit
                      </div>
                      <hr className="hr m-0"></hr>
                      <div className="p-2 cursor-pointer" onClick={""}>
                        Delete
                      </div>
                    </div>
                  }
                />
              )}
              positionActionsColumn="last"
              muiTablePaperProps={{
                elevation: 0,
                fontFamily: "Cerebri sans-light !important",
                sx: {
                  borderRadius: "0",
                  border: "none",
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                },
              }}
              muiTableBodyRowProps={{
                selected: false,
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
            />
          }
        />
      </div>

      <div className="px-4 pt-4 pb-3 border-bottom">
        <Row className="pb-2">
          <Col lg={8}>
            <div className="d-flex gap-2 align-items-center">
              <H4>Cancel signing order reasons</H4>
              <CustomToolTip size="sm" text="Area groups" />
            </div>
            <span className="light-font-text lg-w-75">
              Here is a list of all the cancel signing order reasons that can be
              used in the system and by the customer.
            </span>
          </Col>
          <Col>
            <div className="w-100 d-flex justify-content-end">
              <ETButton
                variant={"primary"}
                buttonText="CREATE New"
                onClick={() => {
                  "";
                }}
              />
            </div>
          </Col>
        </Row>

        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={CancelSigningcolumns}
              data={DeclineSigningData} //data is undefined on first render
              icons={{ ...SortIcon }}
              enableColumnActions={false}
              enableRowSelection={false}
              enableRowActions
              enableSelectAll
              enableTopToolbar={false}
              enableGlobalFilter
              globalFilterFn="contains"
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              displayColumnDefOptions={{
                "mrt-row-select": {
                  header: "",
                },
                "mrt-row-actions": {
                  header: "",
                },
              }}
              renderRowActions={({ row }) => (
                <Menus
                  itemheight={23}
                  itemwidth={23}
                  iconborderradius="4px"
                  itemcontainerwidth="200px"
                  component={
                    <div className="light-font-text">
                      <div
                        className="p-2 cursor-pointer"
                        onClick={() => {
                          "";
                        }}
                      >
                        Edit
                      </div>
                      <hr className="hr m-0"></hr>
                      <div className="p-2 cursor-pointer" onClick={""}>
                        Delete
                      </div>
                    </div>
                  }
                />
              )}
              positionActionsColumn="last"
              muiTablePaperProps={{
                elevation: 0,
                fontFamily: "Cerebri sans-light !important",
                sx: {
                  borderRadius: "0",
                  border: "none",
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                },
              }}
              muiTableBodyRowProps={{
                selected: false,
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
            />
          }
        />
      </div>
    </div>
  );
};

export default OrganizationSettingSigning;

export const DeclineSigningData = [
  // {
  //   Reason: "Low income",
  //   Type: "System default",
  //   created: "30 Sep, 2023",
  //   created_by: "Thomas Høeg Mogensen",
  //   level: "Organisation",
  //   MultiCheck: "",
  // },
  // {
  //   Reason: "Low income",
  //   Type: "System default",
  //   created: "30 Sep, 2023",
  //   created_by: "Thomas Høeg Mogensen",
  //   level: "Organisation",
  //   MultiCheck: "",
  // },
  // {
  //   Reason: "Low income",
  //   Type: "System default",
  //   created: "30 Sep, 2023",
  //   created_by: "Thomas Høeg Mogensen",
  //   level: "Organisation",
  //   MultiCheck: "",
  // },
  // {
  //   Reason: "Low income",
  //   Type: "System default",
  //   created: "30 Sep, 2023",
  //   created_by: "Thomas Høeg Mogensen",
  //   level: "Organisation",
  //   MultiCheck: "",
  // },
  // {
  //   Reason: "Low income",
  //   Type: "System default",
  //   created: "30 Sep, 2023",
  //   created_by: "Thomas Høeg Mogensen",
  //   level: "Organisation",
  //   MultiCheck: "",
  // },
  // {
  //   Reason: "Low income",
  //   Type: "System default",
  //   created: "30 Sep, 2023",
  //   created_by: "Thomas Høeg Mogensen",
  //   level: "Organisation",
  //   MultiCheck: "",
  // },
];

// Created by ------> Anmol
