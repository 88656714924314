import React, { useEffect, useState } from "react";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../../Components/Tabs/ReactTabs.style";
import { GreenSpan } from "../../../styles/Common.style";
import ContactInformation from "./ContactInformation";
import AdditionalInfo from "./AdditionalInfo";
import FollowUp from "./FollowUp";
import TimeSlot from "./TimeSlot";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetUnitsByPropertyId } from "../../../services/Units";
import { usePropertyId } from "../../../hooks/stores/usePropertyStore";
import ResponsibleAgent from "../../../Components/ResponsibleAgent/ResponsibleAgent";
import { useGetUnitsByPropertyIdUpdated } from "../../../hooks/queries/useUnitQueries";

const ConfirmAndAddParticipant = () => {
  const propertyId = usePropertyId((state) => state.propertyId);

  const {
    data: GetUnitsByPropertyIdData,
    refetch: GetUnitsByPropertyIdDataRefetch,
    isLoading,
  } = useGetUnitsByPropertyIdUpdated(propertyId, 0);

  const [selectedTab, setSelectedTab] = useState(0);

  const [unitsTableData, setUnitsTableData] = useState(
    GetUnitsByPropertyIdData?.data
  );
  const [dataContainer, setDataContainer] = useState(
    GetUnitsByPropertyIdData?.data
  );
  const [selectedUnitsId, setSelectedUnitsId] = useState([]);
  const [openChangeAgentPopup, setOpenChangeAgentPopup] = useState(false);

  // console.log(unitsTableData, "unitsTableData");
  //  const pk=unitsTableData.unit_no
  //  const hp =pk.slice(-1)
  //  console.log(hp)
  const participantsInfo = [
    {
      Id: "1",
      tab: "Contact information",
      component: <ContactInformation />,
    },
    {
      Id: "2",
      tab: "Additional information",
      component: (
        <AdditionalInfo
          unitsTableData={unitsTableData}
          setUnitsTableData={setUnitsTableData}
          setSelectedUnitsId={setSelectedUnitsId}
          dataContainer={dataContainer}
        />
      ),
    },
    {
      Id: "3",
      tab: "Follow up",
      component: <FollowUp />,
    },
    {
      Id: "4",
      tab: "Time slot",
      component: <TimeSlot />,
      // msg: ".",
    },
  ];

  // useEffect(() => {
  // 	const numAscending4 = [...unitsTableData].sort((a, b) => a.floor - b.floor);
  // 	setUnitsTableData(numAscending4);
  // }, [propertyId]);
  return (
    <div>
      <div>
        <Tabs selectedIndex={selectedTab} className=" pb-1 d-flex flex-column">
          <div
            className="px-4 my-2 pb-2 d-flex justify-content-between "
            style={{
              alignItems: "center",
              borderBottom: "1px solid #F0F0F0",
            }}
          >
            <TabList className="light ps-0 ">
              {participantsInfo.map((page, index) => (
                <Tab
                  style={{ position: "relative" }}
                  onClick={() => setSelectedTab(index)}
                >
                  <span style={{ position: "relative" }}>{page?.tab}</span>
                  {page.msg && (
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle"
                      size="lg"
                      style={{ color: "#D00200" }}
                      className="px-1 notification"
                    />
                  )}
                </Tab>
              ))}
            </TabList>
            <div className="light-font-text d-flex flex-column text-end">
              <span className="form-label-title">Responsible agent</span>
              <span>
                {/* {tenancyByUnitIdData?.data?.[0]?.responsible_agent_name} */}
                Max Wildersans Brentford
              </span>
              <div className="d-flex justify-content-end fs-12">
                <GreenSpan
                  onClick={() => setOpenChangeAgentPopup(true)}
                  className="cursor-pointer"
                >
                  Change agent
                </GreenSpan>
              </div>
            </div>
          </div>

          <div className="">
            {participantsInfo.map((page, index) => (
              <TabPanel key={page?.Id}>{page?.component}</TabPanel>
            ))}
          </div>
        </Tabs>
        <ResponsibleAgent
          openChangeAgentPopup={openChangeAgentPopup}
          setOpenChangeAgentPopup={setOpenChangeAgentPopup}
        />
      </div>
    </div>
  );
};

export default ConfirmAndAddParticipant;
