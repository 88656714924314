import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  GetTeamByTeamId,
  GetTeamsByLevel,
  PostAddTeam,
  PostUpdateTeam,
  UpdateAssignPropertiesToTeams,
  UpdateTeamStatus,
  PostAssignUserToTeams,
} from "../../services/Team";
// Get Teams By Level
export const useGetTeamsByLevel = (level_id, id, type_id) => {
  return useQuery(
    ["qGetTeamsByLevel", level_id, id, type_id],
    () => GetTeamsByLevel(level_id, id, type_id),

    {
      enabled: !!id,
    }
  );
};

export const useGetTeamsByLevelDetails = (level_id, id, type_id) => {
  return useQuery(
    ["qGetTeamsByLevelDetails", level_id, id, type_id],
    () => GetTeamsByLevel(level_id, id, type_id),
    {
      enabled: !!level_id,
    },
    {
      enabled: !!id,
    },
    {
      enabled: !!type_id,
    }
  );
};
//Teams_GetTeamByTeamId
export const useGetTeamByTeamId = (team_id) => {
  return useQuery(
    ["qGetTeamByTeamId", team_id],
    () => GetTeamByTeamId(team_id),
    {
      enabled: !!team_id,
    }
  );
};

// Put Assign Properties To Teams
export const useUpdateAssignPropertiesToTeams = () => {
  const queryClient = useQueryClient();
  return useMutation(UpdateAssignPropertiesToTeams, {
    onSuccess: () => {
      queryClient.invalidateQueries("qGetTeamsByLevel");
    },
  });
};

// Update Team Status
export const useUpdateTeamStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(UpdateTeamStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries("qGetTeamsByLevel");
    },
  });
};

// Add Team
export const useAddTeam = () => {
  const queryClient = useQueryClient();
  return useMutation(PostAddTeam, {
    onSuccess: () => {
      // queryClient.invalidateQueries("qGetTeamsByLevel");
    },
  });
};

// Update Team
export const useUpdateTeam = () => {
  const queryClient = useQueryClient();
  return useMutation(PostUpdateTeam, {
    onSuccess: () => {
      queryClient.invalidateQueries("qGetTeamsByLevel");
      queryClient.invalidateQueries("qGetTeamByTeamId");
    },
  });
};
// AssignUserToTeams
export const useAssignUserToTeams = () => {
  const queryClient = useQueryClient();
  return useMutation(PostAssignUserToTeams, {
    onSuccess: () => {
      queryClient.invalidateQueries("qGetTeamsByLevel");
      queryClient.invalidateQueries("qGetTeamByTeamId");
    },
  });
};
