import { useMemo, useState } from "react";
import MRTTheme from "../../../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";
import {
  SortIcon,
  TableRowsStyle,
  SearchIcon,
  SearchStyle,
  paginationoptions,
} from "../../../../../../Components/table/MRTGlobalProps";
import ETButton from "../../../../../../Components/Buttons/ETButton.js";
import { Col, Row } from "react-bootstrap";
import { H4 } from "../../../../../../styles/Common.style";
import CustomToolTip from "../../../../../../Components/Tooltip/CustomTooltip";
import Menus from "../../../../../../Components/Menus/Menus";
import AddCertification from "./AddCertification";
import CustomPopup from "../../../../../../Components/CustomPopup";

const Certifications = () => {
  //================== States ====================//
  const [showAddCertification, setShowAddCertification] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const columns = useMemo(
    () => [
      {
        accessorKey: "certificationsType",
        header: "Certifications type",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: true,
      },
      {
        accessorKey: "certificationName",
        header: "Certifications name",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "certificationLevel",
        header: "Certification level",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "created",
        header: "Created",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: false,
      },
      {
        accessorKey: "createdBy",
        header: "Created by",
        enableHiding: false,
        size: 240,
        muiTableBodyProps: {
          sx: {
            paddingRight: "0px !important",
          },
        },
        enableGlobalFilter: false,
      },
    ],
    []
  );
  return (
    <div className="p-4">
      <MRTTheme
        colorVariantsForTableRow={["gray"]}
        colorVariantsForTableHead={["white"]}
        table={
          <MaterialReactTable
            columns={columns}
            icons={{ ...SortIcon }}
            data={data ?? []}
            enableFilterMatchHighlighting={true}
            enableGlobalFilter
            // renderEmptyRowsFallback={renderEmptyRowsFallback}
            enableMultiRowSelection={false}
            enableRowSelection={false}
            enableRowActions
            // onRowSelectionChange={setRowSelection}// to add u
            globalFilterFn="contains" //turn off fuzzy matching and use simple contains filter function
            enableColumnActions={false}
            muiSearchTextFieldProps={{
              ...SearchStyle,

              InputProps: {
                ...SearchIcon,
              },
            }}
            positionActionsColumn="last"
            renderRowActions={({ row, table }) => (
              <Menus
                itemheight={24}
                itemwidth={24}
                iconborderradius="4px"
                itemcontainerwidth="210px"
                itemcontainerheight="300px"
                component={
                  <>
                    <div className="light-font-text">
                      <div
                        className="addpropContent px-3 py-2 "
                        onClick={() => {
                          setShowAddCertification(true);
                        }}
                      >
                        <span className="ms-1">Edit details</span>
                      </div>
                      <hr className=" m-0"></hr>
                      <div
                        className="addpropContent px-3 py-2 "
                        onClick={() => setShowDeletePopup(true)}
                      >
                        <span className="ms-1">Delete</span>
                      </div>
                    </div>
                  </>
                }
              />
            )}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                size: 50,
                header: "",
              },
            }}
            muiTablePaginationProps={{
              ...paginationoptions,
            }}
            initialState={{
              showGlobalFilter: true,
              showColumnFilters: false,
            }}
            muiTableContainerProps={{
              sx: {
                padding: "0px !important",
              },
            }}
            muiTablePaperProps={{
              sx: {
                table: {
                  borderCollapse: "separate",
                  borderSpacing: "0 5px",
                },
              },
            }}
            renderTopToolbar={({ table }) => (
              <>
                <div className="mb-4">
                  {" "}
                  <Row>
                    <Col xl={6}>
                      <div className="d-flex align-items-center gap-2 mb-2">
                        <H4>Property certifications</H4>
                        <CustomToolTip
                          size="sm"
                          text={"Property certifications"}
                        />
                      </div>
                      <div className="light-font-text">
                        Below is a list of the certifications on the property.
                      </div>
                    </Col>
                    <Col
                      xl={{ span: 4, offset: 2 }}
                      md={{ span: 6, offset: 6 }}
                      className="ps-lg-0 d-lg-flex justify-content-lg-end align-items-lg-end"
                    >
                      <ETButton
                        variant={"primary"}
                        buttonText="ADD CERTIFICATION"
                        icon={true}
                        onClick={() => {
                          setShowAddCertification(true);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </>
            )}
            positionGlobalFilter="left"
          />
        }
      />
      {/* Delete popup */}
      <CustomPopup
        // modalHeadingStyle={modalHeadingStyle}
        style={{ position: "relative" }}
        size="md"
        open={showDeletePopup}
        onHide={() => setShowDeletePopup(false)}
        title="Are you carrying out the trash?"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() => {
          setShowDeletePopup(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div className="fs-14 fw-300 text-left mb-3">
            Are you sure you want to delete this ?
          </div>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="COLD FEET"
            onClick={() => setShowDeletePopup(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="I'M SURE"
            onClick={() => setShowDeletePopup(false)}
          />
        </div>
      </CustomPopup>
      <AddCertification
        showAddCertification={showAddCertification}
        setShowAddCertification={setShowAddCertification}
      />
    </div>
  );
};

export default Certifications;

const data = [
  // {
  //   certificationsType: "Nordic Ecolabelling",
  //   certificationName: "Svanemærket",
  //   certificationLevel: "-",
  //   created: "23 Aug, 2023",
  //   createdBy: "Frederikke Windig",
  // },
  // {
  //   certificationsType: "Nordic Ecolabelling",
  //   certificationName: "DNGB",
  //   certificationLevel: "Guld",
  //   created: "21 Aug, 2023",
  //   createdBy: "Sofie Espersen",
  // },
];
