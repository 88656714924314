import React, { useEffect, useState } from "react";
import CustomPopup from "../../../../Components/CustomPopup";
import ETButton from "../../../../Components/Buttons/ETButton";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { Col, Row } from "react-bootstrap";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import YesNoButton from "../../../../Components/Buttons/YesNoButton";
import { useNavigate } from "react-router-dom";
import { H2, H3, H5 } from "../../../../styles/Common.style";
import { useScreening } from "../../../../hooks/stores/useScreeningStore";
import { useGetScreeningTemplate } from "../../../../hooks/queries/useScreeningQueries";

const ExistingScreeningTemplate = ({
	existingScreeningTemplate,
	setExistingScreeningTemplate,
	screening,
	setScreening,
	setShowOnPrescreening,
	ShowOnPrescreening,
}) => {
	const navigate = useNavigate();
	const {
		screeningFormId,
		setScreeningFormId,
		setEditScreeningForm,
		setParentFormId,
	} = useScreening((state) => state);
	const { data: existingTemplateData } = useGetScreeningTemplate(
		ShowOnPrescreening?.toString()
	);
	// If ShowOnPrescreening Is 0 by defaul show first value in React Select
	useEffect(() => {
		if (ShowOnPrescreening === 0 && existingTemplateData?.data?.length > 0) {
			const defaultOption = {
				value: existingTemplateData?.data[0]?.Id,
				label: existingTemplateData?.data[0]?.Text,
				isDisabled: false,
			};
			setSelectedTemp(defaultOption);
		}
	}, [ShowOnPrescreening, existingTemplateData]);
	// const { data: sysScreeningFormData } = useGetScreeningTemplate(0);
	const [selectedTemp, setSelectedTemp] = useState(null);
	const existingTemplateOptions = existingTemplateData?.data?.map(
		(exTemp, index) => ({
			value: exTemp?.Id,
			label: exTemp?.Text,
		})
	);
	// const sysScreeningFormOptions = sysScreeningFormData?.data?.map(
	// 	(sysTemp, index) => ({
	// 		value: sysTemp?.Id,
	// 		label: sysTemp?.Text,
	// 	})
	// );
	const handleParentFormId = (selectedVal) => {
		setSelectedTemp({ value: selectedVal?.value, label: selectedVal?.label });
		// setParentFormId(selectedVal?.value);
	};

	const handleStartScreening = () => {
		setEditScreeningForm(false);
		if (ShowOnPrescreening == 0) {
			navigate("/property-units/create-screening-form", {
				state: { screeningParentFormId: selectedTemp?.value },
			});
			setExistingScreeningTemplate(false);
			setParentFormId(selectedTemp?.value);
			// setScreeningFormId(0);
		} else if (ShowOnPrescreening === 1) {
			navigate("/property-units/settings/screening");
			setExistingScreeningTemplate(false);
		}
	};

	return (
		<CustomPopup
			open={existingScreeningTemplate}
			onHide={() => setExistingScreeningTemplate(false)}
			title={" Would you like to use an existing screening template?"}
			title_color="black"
			titleTooltipText={"like to use an existing screening template?"}
			enableCrossButton={false}
			action_button_label="YES"
			action_button_label_color="white"
			action_button_color="#619130"
			cancel_button={true}
			cancel_button_bordercolor="grey"
			cancel_button_label="NO"
			cancel_button_label_color="black"
			cancel_button_color="#fff"
			footer_color={"#F9FAFA"}
			deletePopUpStyle={true}
			titleTooltip={true}
		>
			<div className="p-4 light-font-text blackColor ">
				If you want an existing screening template to be used, you can select it
				below. <br />{" "}
				<div className="mt-3">
					{" "}
					If you can’t see it, it may be because you don’t have the necessary
					permissions.
				</div>
			</div>

			<div className="px-4 ">
				<YesNoButton
					value={ShowOnPrescreening}
					width={"50%"}
					onChange={(value) => {
						setShowOnPrescreening(value);
						setSelectedTemp(null);
					}}
					// setShowOnPrescreening={setShowOnPrescreening}
					btnText1={"Yes"}
					btnText2={"No"}
				/>
			</div>
			<hr className="hr my-4" />
			<div className="px-4 pb-4 mb-3">
				{" "}
				<div>
					<Row>
						<Col sm={8}>
							<div className="mb-3">
								<div className=" d-flex align-items-start">
									<H5>
										<span className="me-2">
											Choose existing screening template
										</span>{" "}
									</H5>
									<CustomToolTip
										size="sm"
										text={
											ShowOnPrescreening == 1 &&
											"Choose existing screening template"
										}
									/>
								</div>
							</div>
							<ReactSelect
								// disabled={ShowOnPrescreening == 0 && true}
								value={selectedTemp}
								placeholder={"Templates"}
								// options={
								// 	ShowOnPrescreening == 1
								// 		? existingTemplateOptions
								// 		: sysScreeningFormOptions
								// }
								// value={ShowOnPrescreening == 0 ? existingTemplateData?.data?.map((data) => data?.[0]?.Text) : selectedTemp}
								options={existingTemplateData?.data?.map((exTemp, index) => ({
									value: exTemp?.Id,
									label: exTemp?.Text,
									isDisabled: selectedTemp?.value === exTemp?.Id,
								}))}
								onChange={(selected) => handleParentFormId(selected)}
								clearable={false}
							/>
						</Col>
					</Row>
				</div>
			</div>
			<div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
				<ETButton
					variant={"white"}
					buttonText="CANCEL"
					onClick={() => {
						setExistingScreeningTemplate(false);
					}}
				/>
				<ETButton
					variant={"primary"}
					buttonText="CONFIRM"
					onClick={() => {
						handleStartScreening();
					}}
				/>
			</div>
		</CustomPopup>
	);
};

export default ExistingScreeningTemplate;
