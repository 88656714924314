import React, { useEffect, useState } from "react";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import { Col, Row } from "react-bootstrap";

import EventsCard from "./EventsCard";
import { useGetEventsByPropertyId } from "../../hooks/queries/useEventsQueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// import { addYears, endOfYear, startOfYear } from "date-fns";
// import {
//   addDays,
//   endOfDay,
//   startOfDay,
//   startOfMonth,
//   endOfMonth,
//   addMonths,
//   startOfWeek,
//   endOfWeek,
//   isSameDay,
//   format,
// } from "date-fns";

import { usePropertyId } from "../../hooks/stores/usePropertyStore";
import { useUserDetails } from "../../hooks/stores/useUserDetailsStore";

import { H1, H3 } from "../../styles/Common.style";
const EventsSection = ({
  setViewSection,
  getEventsTypeData,
  getEventAgentDetailsData,
  selectedEventType,
  setSelectedEventType,
  selectedFilter,
  setSelectedFilter,
}) => {
  //------------------- state --------------------------//

  // const [selectedMonth, setSelectedMonth] = useState("");
  // const [selectedYear, setSelectedYear] = useState("");
  // const [selectedRange, setSelectedRange] = useState({
  //   startDate: new Date(2001, 0, 1),
  //   endDate: endOfDay(new Date()),
  //   key: "selection",
  // });
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(today);
  const [filteredData, setFilteredData] = useState([]);

  //-------------------Zustand--------------------------//

  const propertyId = usePropertyId((state) => state.propertyId);
  const AgentuserDetails = useUserDetails((state) => state.userDetails);

  const AgentuserId = Number(AgentuserDetails?.user_id);
  const EventType = 0;
  //-------------------react query--------------------------//
  const { data: GetEventsByPropertyId } = useGetEventsByPropertyId(
    propertyId,
    EventType,
    AgentuserId
  );

  // const handleYearChange = (event) => {
  //   setSelectedYear(event.value);
  // };

  // const filteredItems = GetEventsByPropertyId?.data?.filter((item) => {
  //   if (!selectedMonth || !selectedYear) {
  //     return true; // No filter applied
  //   }

  //   const [itemYear, itemMonth] = item.event_date.split("-");

  //   return itemYear === selectedYear && itemMonth === selectedMonth;
  // });

  const [anchorCalendar, setanchorCalendar] = useState(false);

  // const handleClickCalendar = (event) => {
  //   setanchorCalendar(event.currentTarget);
  // };

  // const handleCloseCalendar = () => {
  //   setanchorCalendar(null);
  //   // setSelectedDate(date); //zustand
  // };

  // const handleRangeChange = (ranges) => {
  //   setSelectedRange(ranges.selection);
  // };
  // const filteredItems = GetEventsByPropertyId?.data?.filter((item) => {
  //   const itemDate = new Date(item.event_date);

  //   if (
  //     itemDate >= selectedRange.startDate &&
  //     itemDate <= selectedRange.endDate
  //   ) {
  //     return true;
  //   }

  //   return false;
  // });

  // const definedTimes = {
  //   startOfWeek: startOfWeek(new Date()),
  //   endOfWeek: endOfWeek(new Date()),
  //   startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  //   endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  //   startOfToday: startOfDay(new Date()),
  //   endOfToday: endOfDay(new Date()),
  //   startOfYesterday: startOfDay(addDays(new Date(), -1)),
  //   endOfYesterday: endOfDay(addDays(new Date(), -1)),
  //   startOfMonth: startOfMonth(new Date()),
  //   endOfMonth: endOfMonth(new Date()),
  //   startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  //   endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),

  //   startOfLastThreeMonths: startOfMonth(addMonths(new Date(), -3)),
  //   endOfLastThreeMonths: endOfMonth(addMonths(new Date(), -1)),
  //   startOfLastYear: startOfYear(addYears(new Date(), -1)),
  //   endOfLastYear: endOfYear(addYears(new Date(), -1)),
  //   startOfLastSevenDays: startOfDay(addDays(new Date(), -1)),
  //   endOfLastSevenDays: startOfDay(addDays(new Date(), -7)),
  //   startOfLastThirtyDays: startOfDay(addDays(new Date(), -1)),
  //   endOfLastThirtyDays: startOfDay(addDays(new Date(), -30)),
  //   startOfAllTime: new Date(2001, 0, 1),
  // };

  // const staticRangeHandler = {
  //   range: {},
  //   isSelected(range) {
  //     const definedRange = this.range();
  //     return (
  //       isSameDay(range.startDate, definedRange.startDate) &&
  //       isSameDay(range.endDate, definedRange.endDate)
  //     );
  //   },
  // };

  // function createStaticRanges(ranges) {
  //   return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
  // }
  // const staticRanges = createStaticRanges([
  //   {
  //     label: "Today",
  //     range: () => ({
  //       startDate: definedTimes.startOfToday,
  //       endDate: definedTimes.endOfToday,
  //     }),
  //   },
  //   {
  //     label: "Last 7 days",
  //     range: () => ({
  //       startDate: definedTimes.endOfLastSevenDays,
  //       endDate: definedTimes.startOfLastSevenDays,
  //     }),
  //   },
  //   {
  //     label: "Last 30 days",
  //     range: () => ({
  //       startDate: definedTimes.startOfLastThirtyDays,
  //       endDate: definedTimes.endOfLastThirtyDays,
  //     }),
  //   },
  //   {
  //     label: "Last Month",
  //     range: () => ({
  //       startDate: definedTimes.startOfLastMonth,
  //       endDate: definedTimes.endOfLastMonth,
  //     }),
  //   },
  //   {
  //     label: "This Month",
  //     range: () => ({
  //       startDate: definedTimes.startOfMonth,
  //       endDate: definedTimes.endOfToday,
  //     }),
  //   },
  //   {
  //     label: "Last 3 Month",
  //     range: () => ({
  //       startDate: definedTimes.startOfLastThreeMonths,
  //       endDate: definedTimes.endOfLastThreeMonths,
  //     }),
  //   },
  //   {
  //     label: "Last Year",
  //     range: () => ({
  //       startDate: definedTimes.startOfLastYear,
  //       endDate: definedTimes.endOfLastYear,
  //     }),
  //   },
  //   {
  //     label: "All time",
  //     range: () => ({
  //       startDate: definedTimes.startOfAllTime,
  //       endDate: new Date(2099, 0, 1),
  //     }),
  //   },
  // ]);

  const handlePreviousMonth = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setSelectedDate(newDate);
  };

  const handleNextMonth = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(newDate.getMonth() + 1);
    setSelectedDate(newDate);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleFilterTypeEvent = (selectEvent) => {
    setSelectedEventType(selectEvent);
    if (selectEvent !== null) {
      const filterEvent = GetEventsByPropertyId?.data?.filter(
        (item) => item?.event_type_name === selectEvent?.value
      );
      setFilteredData(filterEvent);
    } else {
      setFilteredData(GetEventsByPropertyId?.data);
    }
  };

  const handleFilterByAgent = (selectedOption) => {
    setSelectedFilter(selectedOption);
    if (selectedOption !== null) {
      const filterAgent = GetEventsByPropertyId?.data?.filter(
        (item) => item?.responsible_agent_name === selectedOption?.value
      );
      setFilteredData(filterAgent);
    } else {
      setFilteredData(GetEventsByPropertyId?.data);
    }
  };

  // Filter data based on the selected month
  useEffect(() => {
    const filtered = GetEventsByPropertyId?.data?.filter((event) => {
      const eventDate = new Date(event.event_date);
      return (
        eventDate.getMonth() === selectedDate.getMonth() &&
        eventDate.getFullYear() === selectedDate.getFullYear()
      );
    });
    setFilteredData(filtered);
  }, [selectedDate]);

  return (
    <div className="events-tab-container px-2">
      <div className="events-tab-header ">
        <div>
          {/* <button className="rbc-btn " onClick={handlePreviousMonth}>
            <FontAwesomeIcon
              icon="fa-regular fa-angle-left"
              style={{ color: "#000000" }}
            />
          </button> */}

          {/* Render the filtered data here */}
          {/* <ul>
            {filteredData.map((event) => (
              <li key={event.event_calendar_entry_id}>
                {event.name} - {event.event_date}
              </li>
            ))}
          </ul> */}
        </div>
        <Row className="py-2 d-flex justify-content-center align-items-center">
          <Col className="pe-lg-0" lg={5}>
            <Row style={{ width: "320px" }}>
              <Col className="pe-lg-0">
                <ReactSelect
                  placeholder={"Type of event"}
                  value={selectedEventType}
                  options={getEventsTypeData?.data?.map((item) => ({
                    value: item?.Text,
                    label: item?.Text,
                  }))}
                  onChange={handleFilterTypeEvent}
                />
              </Col>
              <Col className="pe-lg-0">
                <ReactSelect
                  placeholder={"Filter by agent"}
                  options={getEventAgentDetailsData?.data?.map((item) => ({
                    value: item?.full_name,
                    label: item?.full_name,
                  }))}
                  value={selectedFilter}
                  onChange={handleFilterByAgent}
                />
              </Col>
            </Row>
          </Col>
          <Col className="ps-lg-0">
            <div className="month-filter align-items-center my-main-event-calendar d-flex">
              <button
                className="rbc-btn rbc-btn-nav-prev "
                onClick={handlePreviousMonth}
              >
                <FontAwesomeIcon
                  icon="fa-regular fa-angle-left"
                  style={{ color: "#000000" }}
                />
              </button>
              <div className="month-name">
                <H1 className="rbc-toolbar-label fs-16 mb-0 mx-3">
                  {`${
                    months[selectedDate.getMonth()]
                  } ${selectedDate.getFullYear()}`}
                </H1>
              </div>
              <button
                className="rbc-btn rbc-btn-nav-next"
                onClick={handleNextMonth}
              >
                <FontAwesomeIcon
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />
              </button>
              {/* <button onClick={handleNextMonth}>Next</button> */}
            </div>
          </Col>
          {/* <Col className="pe-lg-0" lg={3}>
       
            <Button
              size="small"
              id="basic-button-calendar"
              aria-controls={anchorCalendar ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={anchorCalendar ? "true" : undefined}
              onClick={handleClickCalendar}
              sx={{
                borderRadius: "8px",
                "&:hover": { backgroundColor: "transparent" },
                padding: "0px",
              }}
              style={{
                color: "transparent",
              }}
            >
              <div className="date-picker-filter">
                <div className="d-flex justify-content-between gap-3">
                  <div className="light-font-text ">
                    <div className="light-font-text ">
                      {`${format(
                        selectedRange?.startDate,
                        "dd. MMM. yyyy"
                      )} - ${format(selectedRange?.endDate, "dd. MMM. yyyy")}`}
                    </div>
                  </div>
                  <div>
                    <img
                      className="img-cal"
                      height={15}
                      src={Calendar}
                      alt="calendar"
                    />
                  </div>
                </div>
              </div>
            </Button>

       

            <Menu
              id="basic-menu"
              anchorEl={anchorCalendar}
              open={anchorCalendar}
              onClose={handleCloseCalendar}
              MenuListProps={{
                "aria-labelledby": "basic-button-calendar",
              }}
              sx={{
                marginTop: "8px",
                backgroundColor: "rgba(0, 0, 0, 0.02)",
              }}
            >
              <MenuItem
                style={{
                  color: "transparent",
                }}
                sx={{
                  "&:hover": { backgroundColor: "transparent" },
                  borderRadius: "8px",
                  padding: "0px",
                  overflow: "hidden",
                  border: "1px solid #F4F5F5",
                }}
              >
                <div
                  className="date-picker-modal-popup"
                  // style={{ height: "400px", overflow: "scroll" }}
                >
                  <div className="date-picker-modal-popup-position">
                    <div
                      className="medium fs-14 justify-content-center"
                      style={{
                        color: "#000",
                        paddingTop: "26px",
                        position: "absolute",
                        textAlign: "center",
                        width: "180px",
                      }}
                    >
                      <span className="font-semibold fs-14">Shortcuts</span>
                    </div>
                    <DateRangePicker
                      onChange={handleRangeChange}
                      ranges={[selectedRange]}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      weekStartsOn={1}
                      direction="horizontal"
                      preventSnapRefocus={true}
                      calendarFocus="backwards"
                      staticRanges={staticRanges}
                      rangeColors={[customproperties.primaryColor]}
                    />
                  </div>
                </div>
              </MenuItem>
              <div
                className="py-2 px-3"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  gap: "15px",
                  backgroundColor: "#f9fafa",
                }}
              >
                <ETButton
                  variant={"white"}
                  buttonText="RESET"
                  width="10%"
                  // onClick={() => ResetFilter()}
                />
                <ETButton
                  variant={"primary"}
                  buttonText="APPLY"
                  width="10%"
                  // onClick={() => DateFilter()}
                />
              </div>
            </Menu>
          </Col> */}
          <Col className="d-flex justify-content-end  align-items-center">
            {" "}
            <div
              onClick={() => {
                setViewSection(false);
              }}
              className=" d-flex gap-1 justify-content-end align-items-center"
            >
              {" "}
              <span className="light-font-text fs-12"> View as calendar </span>
              <div
                className="radius-4  cursor-pointer"
                style={{
                  background: "#f9fafa",
                  border: "1px solid #d1d1d1",
                  padding: "3px 10px",
                }}
              >
                <FontAwesomeIcon
                  icon="fa-regular fa-calendar-days"
                  style={{ color: "#000000" }}
                />
              </div>{" "}
            </div>
          </Col>
        </Row>
      </div>
      <div className="events-tab-body">
        <Row className="pt-4 pb-5 ">
          {/* {GetEventsByPropertyId?.data?.map((data, index) => (
            <Col sm={6} lg={4} className="mb-3 pb-2">
              <EventsCard key={index} data={data} />
            </Col>
          ))} */}
          {filteredData.length > 0 ? (
            <>
              {" "}
              {filteredData?.map((data, index) => (
                <Col sm={6} lg={4} className="mb-3 pb-2">
                  <EventsCard key={index} data={data} />
                </Col>
              ))}
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <H3>No events present for the selected month</H3>
            </div>
          )}
        </Row>
      </div>
    </div>
  );
};

export default EventsSection;
