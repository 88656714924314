import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import ETButton from "../../../../Components/Buttons/ETButton";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";

const CreateNewTag = ({
  setStepWizard,
  setShow,
  setProgress,
  progress,
  setTagName,
  tagName,
  setMediaPackage,
  setPropertyText,
  setFacilityPackage,
  setDocumentId,
  setSigningPackageId,
  setRowSelection,
}) => {
  const handleCancelPropertyTag = () => {
    setShow(false);
    setProgress(0);
    setTagName("");
    setMediaPackage([]);
    setPropertyText([]);
    setFacilityPackage([]);
    setDocumentId([]);
    setSigningPackageId([]);
    setRowSelection([]);
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tagname: " ",
    },
  });
  const onSubmit = (data) => {
    // console.log(data);
    setTagName(data?.tagname);
    setStepWizard("SelectMediaPackage");
    setProgress(progress + 12.5);
  };
  useEffect(() => {
    setValue("tagname", tagName);
  }, []);

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-3" style={{ position: "relative" }}>
          <p className="fs-32 form-label-title text-center">
            <Row className="pt-5 pb-1">
              <Col lg={2}></Col>
              <Col lg={8}>So you are a tag kind of person? We love it!</Col>
              <Col lg={2}></Col>
            </Row>
          </p>
          <div
            className="light-font-text text-center"
            // style={{
            //   lineHeight: "12px",
            // }}
          >
            <div>
              Make your everyday life so much easier for you and your
              colleagues.
            </div>
            <div>
              {" "}
              By using smart tags in estatetool, you can link images, texts and
              facilities to units.
            </div>{" "}
            <div style={{ paddingTop: "20px" }}>
              <span className="form-label-title">Example:</span> when you later
              update og shifts an image in an image package,
            </div>{" "}
            all units with the same tag will also get the new image. We know,
            it’s pure magic!
          </div>
          <Row className="pt-5 pb-5 px-5">
            <Col lg={2}></Col>
            <Col lg={8}>
              <Controller
                control={control}
                {...register("tagname")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <div className="d-flex position-relative">
                      <span
                        className="big-green-input-span fs-20"
                        style={{
                          position: "absolute",
                          top: "16px",
                          left: "8px",
                        }}
                      >
                        #
                      </span>
                      <div className={` ${errors?.tagname ? "big-error-input" : "big-green-input"}`}>
                        <input
                          type="text"
                          placeholder="Tag name"
                          id="tag_Name"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            // clearErrors("tagname");
                          }}
                          maxlength={15}
                        />
                        <label htmlFor="tag_Name">Tag name</label>
                      </div>
                    </div>
                  </>
                )}
              />
              {errors.tagname && (
                <p className="error-msg ">{REQUIRED_ERR}</p>
              )}
            </Col>
            <Col lg={2}></Col>
          </Row>
        </div>
        <div
          className="py-2 px-3 d-flex flex-row justify-content-between  footer-section-button-container"
          style={{
            position: "absolute",
            width: "100%",
            bottom: "0",
            // height : "550px",
            // overflow : 'auto',
          }}
        >
          {/* <ETButton
                variant = {"white"}
            buttonText="PREVIOUS"
            onClick={()=>handlePreviousScreen()}
            fontSize="14px"
          /> */}
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={handleCancelPropertyTag}
          />

          <ETButton variant={"primary"} buttonText="NEXT" type={"submit"} />
        </div>
      </Form>
    </div>
  );
};

export default CreateNewTag;
