import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  DeleteDivisionByDivisionId,
  GetDivisionByDivisionId,
  GetDivisionByOrganisationId,
  GetDivisionByUserId,
  GetDivisionOverviewByDivisionId,
  PostAddDivision,
  PostAddDivisionSession,
  PostEditDivision,
  PutEditDivision,
} from "../../services/Division";

//Get Division by user Id
export const useGetDivisionByUserId = () => {
  return useQuery(["qGetDivisionByUserId"], () => GetDivisionByUserId());
};

//Get Division By DivisionId
export const useGetDivisionByDivisionId = (divisionId) => {
  return useQuery(
    ["qGetDivisionByDivisionId", divisionId],
    () => GetDivisionByDivisionId(divisionId),
    {
      enabled: !!divisionId,
    }
  );
};

// Add division
export const usePostAddDivision = () => {
  return useMutation(PostAddDivision);
};

// Delete division
export const useDeleteDivision = () => {
  const queryClient = useQueryClient();
  return useMutation(DeleteDivisionByDivisionId);
};

//Edit division
export const usePutEditDivision = () => {
  const queryClient = useQueryClient();
  return useMutation(PutEditDivision, {
    onSuccess: () => {
      queryClient.invalidateQueries("qGetDivisionByDivisionId");
      queryClient.invalidateQueries("qGetDivisionByUserId");
      queryClient.invalidateQueries("qGetDivisionByOrganisationId");
      queryClient.invalidateQueries("qGetDivisionOverviewByDivisionId");
    },
  });
};

//Division by orginazation id
export const useGetDivisionByOrganisationId = (OrganizationId, data_type) => {
  return useQuery(
    ["qGetDivisionByOrganisationId", OrganizationId, data_type],
    () => GetDivisionByOrganisationId(OrganizationId, data_type),
    {
      enabled: !!OrganizationId,
      enabled: !!data_type,
    }
  );
};
//Division overview by division id
export const useGetDivisionOverviewByDivisionId = (division_id) => {
  return useQuery(
    ["qGetDivisionOverviewByDivisionId", division_id],
    () => GetDivisionOverviewByDivisionId(division_id),
    {
      enabled: !!division_id,
    }
  );
};

// Post Edit Division
export const usePostEditDivision = () => {
  const queryClient = useQueryClient();
  return useMutation(PostEditDivision, {
    onSuccess: () => {
      queryClient.invalidateQueries("qGetDivisionByDivisionId");
      queryClient.invalidateQueries("qGetDivisionByUserId");
      queryClient.invalidateQueries("qGetDivisionByOrganisationId");
      queryClient.invalidateQueries("qGetDivisionOverviewByDivisionId");
    },
  });
};
// Post Add Division session
export const usePostAddDivisionSession = () => {
  return useMutation(PostAddDivisionSession);
};
