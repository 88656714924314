import { useQuery } from "react-query";
import {
    GetAnalyasis, GetAnalyasisSubDeatils, GetBreakDownByRegion, GetBreakDownByZipcode,
    GetEconomyAvailabilityByRoom, GetEconomyAvailabilityBySqmtr, GetNoteCategory,
    GetTransactionsDividedByAge,
    GetTransactionsDividedByRooms, GetTransactionsDividedBySqm, GetUnitStatus,
    GetUnitStatusByPropertyId, GetUpcommingIdleness
} from "../../services/ControlCenter";

export const useGetEconomyAvailabilityByRoom = (allProperties, startDateFormate, endDateFormate) => {
    return useQuery(
        ["qEconomyAvailabilityByRoom", allProperties, startDateFormate, endDateFormate],
        () => GetEconomyAvailabilityByRoom(allProperties, startDateFormate, endDateFormate),
        { enabled: !!allProperties && !!startDateFormate && !!endDateFormate }
    );
};

export const useGetEconomyAvailabilityBySqmtr = (allProperties, startDateFormate, endDateFormate) => {
    return useQuery(
        ["qEconomyAvailabilityBySqmtr", allProperties, startDateFormate, endDateFormate],
        () => GetEconomyAvailabilityBySqmtr(allProperties, startDateFormate, endDateFormate),
        { enabled: !!allProperties && !!startDateFormate && !!endDateFormate }
    );
};

export const useGetUnitStatus = (allProperties) => {
    return useQuery(
        ["qUnitStatus", allProperties],
        () => GetUnitStatus(allProperties),
        { enabled: !!allProperties }
    );
};

export const useGetUpcommingIdleness = (allProperties,) => {
    return useQuery(
        ["qUpcommingIdleness", allProperties],
        () => GetUpcommingIdleness(allProperties),
        { enabled: !!allProperties }
    );
};

export const useGetNoteCategory = (categoryId, allProperties, startDateFormate, endDateFormate) => {
    return useQuery(
        ["qGetNoteCategory", categoryId, allProperties, startDateFormate, endDateFormate],
        () => GetNoteCategory(categoryId, allProperties, startDateFormate, endDateFormate),
        { enabled: !!allProperties && !!startDateFormate && !!endDateFormate }
    );
};

export const useGetTransactionsDividedByRooms = (allProperties, startDateFormate, endDateFormate) => {
    return useQuery(
        ["qGetTransactionsDividedByRooms", allProperties, startDateFormate, endDateFormate],
        () => GetTransactionsDividedByRooms(allProperties, startDateFormate, endDateFormate),
        { enabled: !!allProperties && !!startDateFormate && !!endDateFormate }
    );
};

export const useGetTransactionsDividedBySqm = (allProperties, startDateFormate, endDateFormate) => {
    return useQuery(
        ["qGetTransactionsDividedBySqm", allProperties, startDateFormate, endDateFormate],
        () => GetTransactionsDividedBySqm(allProperties, startDateFormate, endDateFormate),
        { enabled: !!allProperties && !!startDateFormate && !!endDateFormate }
    );
};

export const useGetBreakDownByRegion = (selectedCountryId, startDateFormate, endDateFormate) => {
    return useQuery(
        ["qGetBreakDownByRegion", selectedCountryId, startDateFormate, endDateFormate],
        () => GetBreakDownByRegion(selectedCountryId, startDateFormate, endDateFormate),
        { enabled: !!selectedCountryId && !!startDateFormate && !!endDateFormate }
    );
};

export const useGetAnalyasis = (allProperties, startDateFormate, endDateFormate) => {
    return useQuery(
        ["qGetAnalyasis", allProperties, startDateFormate, endDateFormate],
        () => GetAnalyasis(allProperties, startDateFormate, endDateFormate),
        { enabled: !!allProperties && !!startDateFormate && !!endDateFormate }
    );
};

export const useGetBreakDownByZipcode = (selectedRowRegionId, startDateFormate, endDateFormate) => {
    return useQuery(
        ["qGetBreakDownByZipcode", selectedRowRegionId, startDateFormate, endDateFormate],
        () => GetBreakDownByZipcode(selectedRowRegionId, startDateFormate, endDateFormate),
        { enabled: !!selectedRowRegionId && !!startDateFormate && !!endDateFormate }
    );
};


export const useGetUnitStatusByPropertyId = (allProperties, startDateFormate, endDateFormate) => {
    return useQuery(
        ["qGetUnitStatusByPropertyId", allProperties, startDateFormate, endDateFormate],
        () => GetUnitStatusByPropertyId(allProperties, startDateFormate, endDateFormate),
        { enabled: !!allProperties && !!startDateFormate && !!endDateFormate }
    );
};

export const useGetAnalyasisSubDeatils = (
    selectedProperties,
    main_status_id,
    substatus_id,
    startDateFormate,
    endDateFormate,
) => {
    return useQuery(
        ["qGetAnalyasisSubDeatils", selectedProperties, main_status_id, substatus_id, startDateFormate, endDateFormate],
        () =>
            GetAnalyasisSubDeatils(selectedProperties, main_status_id, substatus_id, startDateFormate, endDateFormate),
        { enabled: !!selectedProperties && !!main_status_id && !!substatus_id && !!startDateFormate && !!endDateFormate }
    );
};

export const useGetTransactionsDividedByAge = (allProperties, startDateFormate, endDateFormate) => {
    return useQuery(
        ["qGetTransactionsDividedByAge", allProperties, startDateFormate, endDateFormate],
        () => GetTransactionsDividedByAge(allProperties, startDateFormate, endDateFormate),
        { enabled: !!allProperties && !!startDateFormate && !!endDateFormate }
    );
};