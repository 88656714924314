import React, { useState } from "react";
import { DeleteText, PostCloneTextPackage } from "../../services/Texts";
import { toast } from "react-toastify";
import Duplicate from "../properties/units/duplicate/Duplicate";
import { useRouting } from "../../hooks/stores/useRoutingStore";
import { Col, Row } from "react-bootstrap";
import Card from "../../Components/card/TextCard";
import { status } from "../../model/Constant";
import { usePropertyId } from "../../hooks/stores/usePropertyStore";
const TextCard = ({
  textDetailsData,
  textpackagesDataRefetch,
  // propertyId,
  getAllpropertiesList,
  setEditflag,
}) => {
  const { setPackageType, setShowPackage, setPackageId } = useRouting(
    (state) => state
  );
  const { propertyId } = usePropertyId((state) => state);
  const [openMediaPackageDelete, setOpenMediaPackageDelete] = useState(false);
  const [checkedPropertyId, setCheckedPropertyId] = useState([]);
  const [openDuplicatePackageModal, setOpenDuplicatePackageModal] =
    useState(false);
  const [clonePackageTitle, setClonePackageTitle] = useState("");
  const [disableDeleteAction, setDisableDeleteAction] = useState(false);
  // const [isActive, setIsActive] = useState(
  //   textDetailsData?.is_active == 1 ? true : false
  // );

  const handleDeleteText = async (text_id) => {
    setDisableDeleteAction(true);
    const response = await DeleteText(propertyId, text_id);
    if (response.status === status.s) {
      textpackagesDataRefetch();
      setDisableDeleteAction(false);
    }
    toast.success(response.data.message);
    setOpenMediaPackageDelete(false);
  };

  const handleDuplicatePackage = (data) => {
    setDisableDeleteAction(true);
    const duplicatePackagePayload = {
      property_text_id: textDetailsData?.property_text_id?.toString(),
      title: data?.packageTitle,
      clone_type: data?.whatToDuplicate?.value?.toString(),
      property_id: checkedPropertyId,
    };
    PostCloneTextPackage(duplicatePackagePayload)
      .then((response) => {
        toast.success(response?.data?.message);
        textpackagesDataRefetch();
        setOpenDuplicatePackageModal(false);
        setCheckedPropertyId([]);
        setDisableDeleteAction(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setDisableDeleteAction(false);
      });
  };

  const handleEditTextPackage = (id) => {
    setPackageType("8");
    setPackageId(id);
    setShowPackage(true);
    setEditflag(true);
  };

  const handleDuplicateClick = (id) => {
    setPackageId(id);
    setOpenDuplicatePackageModal(true);
  };

  return (
    <div>
      <div>
        <Row>
          <Col>
            <Card
              textDetailsData={textDetailsData}
              handleEditTextPackage={handleEditTextPackage}
              handleDuplicateClick={handleDuplicateClick}
              handleDeleteText={handleDeleteText}
              setOpenMediaPackageDelete={setOpenMediaPackageDelete}
              openMediaPackageDelete={openMediaPackageDelete}
              disableDeleteAction={disableDeleteAction}
            />
          </Col>
        </Row>
      </div>
      <Duplicate
        openDuplicate={openDuplicatePackageModal}
        setOpenDuplicate={setOpenDuplicatePackageModal}
        propertyData={getAllpropertiesList?.data?.filter(
          (el) => el?.property_id !== propertyId
        )}
        allChecked={checkedPropertyId}
        setAllChecked={setCheckedPropertyId}
        handleDuplicate={handleDuplicatePackage}
        setTitle={setClonePackageTitle}
        duplicateDataName={"text"}
        disableDuplicateButton={disableDeleteAction}
      />
    </div>
  );
};

export default TextCard;
