import React, { useState } from "react";
import {
	Tab,
	TabList,
	TabPanel,
	Tabs,
} from "../../Components/Tabs/ReactTabs.style";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import ETButton from "../../Components/Buttons/ETButton";
import UnassignedLeads from "./UnassignedLeads";
import InProcessLeads from "./InProcessLeads";
import CustomPopup from "../../Components/CustomPopup";
import AssignAgent from "../overview/assignAgent/AssignAgent";
import { useBreadcrumb } from "../../Components/BreadcrumbContext ";
import { H2 } from "../../styles/Common.style";

const ContactsLeads = () => {
	const [showAddInfo, setShowAddInfo] = useState(false);
	const { updateBreadcrumbs } = useBreadcrumb();

	return (
		<div style={{ backgroundColor: "#ffffff" }}>
			<div
				className="px-4 pt-3 pb-3 py-3 d-flex justify-content-between"
				style={{ borderBottom: "1px solid #F0F0F0" }}
			>
				<div className="d-flex align-items-center">
					<H2 >Leads</H2>
					<div className="ps-3">
						{" "}
						<CustomToolTip height={12} text="Leads" />{" "}
					</div>
				</div>
				<ETButton
					variant={"primary"}
					buttonText={"ADD LEAD"}
					icon={true}
					iconAlign={"left"}
					onClick={() => setShowAddInfo(true)}
				/>
			</div>
			<div>
				<Tabs lassName="mb-3 pb-1 d-flex flex-column">
					<div
						className="d-flex ps-4 pe-3 py-3 justify-content-between"
						style={{
							width: "100%",
							flexDirection: "row",
							flexWrap: "wrap",
							borderBottom: "1px solid #F0F0F0",
						}}
					>
						<TabList className="light ps-0  ">
							<Tab
								onClick={() => updateBreadcrumbs(["Leads", "Unassigned leads"])}
							>
								Unassigned leads
							</Tab>
							<Tab
								onClick={() =>
									updateBreadcrumbs(["Leads", "In process / working leads"])
								}
							>
								In process / working leads
							</Tab>
						</TabList>
					</div>
					<div className="bg-white" style={{ borderRadius: " 10px" }}>
						<TabPanel>
							<UnassignedLeads />{" "}
						</TabPanel>
						<TabPanel>
							<InProcessLeads />{" "}
						</TabPanel>
					</div>
				</Tabs>
				<CustomPopup
					open={showAddInfo}
					onHide={() => setShowAddInfo(false)}
					title="Contact details"
					titleTooltipText="Contact details"
					title_color="black"
					size={"xl"}
					enableCrossButton={false}
					action_button_label="YES"
					action_button_label_color="white"
					action_button_color="#619130"
					cancel_button={true}
					cancel_button_bordercolor="grey"
					cancel_button_label="NO"
					cancel_button_label_color="black"
					cancel_button_color="#fff"
					footer_color={"#F9FAFA"}
					deletePopUpStyle={true}
					titleTooltip={true}
				>
					<div>
						<AssignAgent
							showAddInfo={showAddInfo}
							setShowAddInfo={setShowAddInfo}
						/>
					</div>
				</CustomPopup>
			</div>
		</div>
	);
};

export default ContactsLeads;
