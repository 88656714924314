import React, { useEffect, useMemo, useState } from "react";
import MRTTheme from "../../Components/table/MRTTheme";
import {
	MRT_GlobalFilterTextField,
	MaterialReactTable,
} from "material-react-table";

import Menus from "../../Components/Menus/Menus";
import {
	SearchIcon,
	SearchStyle,
	SortIcon,
	TableRowsStyle,
	paginationoptions,
} from "../../Components/table/MRTGlobalProps";
import { PaymentsData } from "./StaticData";
import TagToolTip from "../../Components/Tooltip/PropertyTagToolTip";
import { GreenDiv, GreenSpan } from "../../styles/Common.style";
import ETButton from "../../Components/Buttons/ETButton";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OpenPositions from "./OpenPositions";
import BalanceCard from "./BalanceCard";
import { useMediaQuery } from "react-responsive";
import { NoRecordsToDisplay } from "../../utils/validationConstants";

const Payments = () => {
	const [showOpenPositions, setShowOpenPositions] = useState(false);
	const [showBalanceCard, setShowBalanceCard] = useState(false);
	const columns = useMemo(
		() => [
			{
				accessorKey: "id",
				header: "",
				enableHiding: false,
				size: 50,
				enableGlobalFilter: true,
				Cell: ({ row }) => (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "8px",
							cursor: "pointer",
						}}
					>
						<div>
							<TagToolTip
								text="Need Help About Payments"
								border="1px solid #888"
							/>
						</div>
					</div>
				),

				muiTableBodyCellProps: {
					sx: {
						// padding: "0px !important",
						backgroundColor: "#F0F0F0 !important",
						border: "none !important",
					},
				},
			},
			{
				accessorFn: (row) => `${row.name}`,
				header: "Status",
				enableHiding: false,
				size: 310,
				enableGlobalFilter: true,
				Cell: ({ row }) => (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "8px",
							cursor: "pointer",
						}}
					>
						<div>{row.original.name}</div>
						<GreenSpan>(show)</GreenSpan>
					</div>
				),
				muiTableHeadCellProps: {
					sx: {
						paddingLeft: "0px !important",
					},
				},
				muiTableBodyCellProps: {
					sx: {
						paddingLeft: "0px !important",
						backgroundColor: "#F0F0F0 !important",
						border: "none !important",
						borderRight: "1px solid #888888 !important",
					},
				},
			},
			{
				accessorKey: "Unit_no",
				header: "Unit no",
				enableHiding: false,
				size: 150,
				enableGlobalFilter: true,
				// muiTableBodyCellProps: {
				//   sx: {
				//     // padding: "0px !important",
				//     backgroundColor: "#F0F0F0 !important",
				//     border: "none !important",
				//   },
				// },
			},
			{
				accessorKey: "contract",
				header: "Contract",
				enableHiding: false,
				size: 180,
				enableGlobalFilter: true,
			},
			{
				accessorFn: (row) => `${row.phone}`,
				header: "Phone",
				enableHiding: false,
				size: 150,
				enableGlobalFilter: true,
				Cell: ({ row }) => <div>{row.original.phone}</div>,
			},
			{
				accessorKey: "email",
				header: "Email",
				enableHiding: false,
				size: 40,
				enableGlobalFilter: true,
			},

			{
				accessorFn: (row) => `${row.last_payment}  `,
				header: "Last payment",
				enableHiding: false,
				enableGlobalFilter: false,
				size: 50,

				Cell: ({ row }) => <div>{row.original.last_payment}</div>,
				muiTableBodyCellProps: {
					sx: {
						// padding: "0px !important",
						backgroundColor: "#F0F0F0 !important",
						border: "none !important",
						borderLeft: "1px solid #888888 !important",
					},
				},
			},
			{
				accessorFn: (row) => `${row.balance}  `,
				header: "Balance",
				enableHiding: false,
				enableGlobalFilter: false,
				size: 120,

				Cell: ({ row }) => <div>{row.original.balance}</div>,
				muiTableBodyCellProps: {
					sx: {
						// padding: "0px !important",
						backgroundColor: "#F0F0F0 !important",
						border: "none !important",
						// borderLeft: "1px solid #888888 !important",
					},
				},
			},
		],
		[]
	);

	// below code is for give responsive design to fixed columns
	const isDesktop = useMediaQuery({ minWidth: 1224 });
	const [columnPinning, setColumnPinning] = useState({});
	useEffect(() => {
		if (isDesktop) {
			setColumnPinning({
				left: ["id", "Status"],
				right: ["Last payment", "Balance", "mrt-row-actions"],
			});
		} else {
			setColumnPinning({
				right: ["mrt-row-actions"],
			});
		}
	}, [isDesktop]);
	return (
		<div>
			<div className="px-4 pt-4 pb-5">
				<MRTTheme
					colorVariantsForTableRow={["gray"]}
					colorVariantsForTableHead={["white"]}
					table={
						<MaterialReactTable
							columns={columns}
							// data={PaymentsData} //data is undefined on first render
							data={[]}
							icons={{ ...SortIcon }}
							localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
							enableColumnActions={false}
							enableTopToolbar={true}
							enableRowSelection={false}
							enableRowActions
							enableSelectAll
							enablePinning
							onColumnPinningChange={setColumnPinning}
							state={{ columnPinning }}
							enableFilterMatchHighlighting={true}
							enableGlobalFilter
							globalFilterFn="contains"
							initialState={{
								showGlobalFilter: true,
								showColumnFilters: false,
							}}
							muiTableContainerProps={{
								sx: {
									padding: "0px !important",
								},
							}}
							displayColumnDefOptions={{
								"mrt-row-actions": {
									header: "",
									size: 100,
									muiTableBodyCellProps: {
										sx: {
											// padding: "0px !important",
											backgroundColor: "#F0F0F0 !important",
											border: "none !important",
										},
									},
								},
							}}
							positionActionsColumn="last"
							renderRowActions={({ row }) => (
								<div className="d-flex gap-3 align-items-center">
									<div
										className="cursor-pointer"
										onClick={() => {
											setShowOpenPositions(true);
										}}
									>
										<FontAwesomeIcon
											icon="fa-regular fa-pen-field"
											size="xl"
											style={{ color: "#000000" }}
										/>
									</div>
									<div
										className="cursor-pointer"
										onClick={() => {
											setShowBalanceCard(true);
										}}
									>
										<FontAwesomeIcon
											icon="fa-regular fa-list-dropdown"
											size="xl"
											style={{ color: "#000000", paddingTop: "2px" }}
										/>
									</div>
								</div>
							)}
							renderTopToolbar={({ table }) => (
								<>
									<Row>
										<Col lg={3} className="pb-2 pe-0">
											<MRT_GlobalFilterTextField table={table} />
										</Col>
										<Col lg={2} className="pb-2 pe-0">
											<ReactSelect placeholder={"Filter"} />
										</Col>
										<Col lg={7} className="pb-2">
											<div className="d-flex justify-content-end">
												<ETButton
													buttonText={"EXPORT MULTIPLE"}
													variant={"primary"}
												/>
											</div>
										</Col>
									</Row>
								</>
							)}
							muiTablePaperProps={{
								selected: false,
								elevation: 0,
								fontFamily: "Cerebri sans-light !important",
								sx: {
									borderRadius: "0",
									border: "none",
									// backgroundColor: "#F9FAFA",
									table: {
										borderCollapse: "separate",
										borderSpacing: "0 5px",
									},
								},
							}}
							muiSearchTextFieldProps={{
								...SearchStyle,

								InputProps: {
									...SearchIcon,
								},
							}}
							muiTablePaginationProps={{
								...paginationoptions,
							}}
							muiTableHeadRowProps={{
								sx: {
									...TableRowsStyle,
								},
							}}
						/>
					}
				/>
			</div>

			<OpenPositions
				showOpenPositions={showOpenPositions}
				setShowOpenPositions={setShowOpenPositions}
			/>

			<BalanceCard
				setShowBalanceCard={setShowBalanceCard}
				showBalanceCard={showBalanceCard}
			/>
		</div>
	);
};

export default Payments;
