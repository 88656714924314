// created by Suraj
import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import photoComingSoon from "../../assets/images/photo-coming-soon.jpg";
import { HtmlTooltip } from "./PropertyLevelTopBar";
import { H4 } from "../../styles/Common.style";
import { useOrganizationOverview } from "../../hooks/stores/useOrganizationStore";
import NewColorBox from "../ColorCombinationbox/NewColorBox";
import { ImageComponent, ShortNameComponent } from "../../widget/Widget";

const OrganisationLevelTopBar = () => {
  //-----------------------zustand--------------------//
  const organizationOverview = useOrganizationOverview(
    (state) => state.organizationOverview
  );

  console.log("OrgOverview", organizationOverview);

  return (
    <div>
      <Row
        // key={i}
        className="ms-xxl-5 ms-lg-4 ms-3 pt-4 pb-3  px-xxl-1 px-1 align-items-end"
      >
        <Col
          xl={4}
          lg={6}
          md={5}
          className="px-md-2 d-flex justify-content-md-start justify-content-center truncated-text"
        >
          <div className="d-flex align-items-center">
            <ImageComponent
              imageUrl={organizationOverview?.[0]?.logo_url}
              altText={"image"}
              width="100px"
              height="70px"
              shortNameComponent={
                <ShortNameComponent
                  backgroundColor={
                    organizationOverview?.[0]?.shortname_background_color
                  }
                  fontColor={organizationOverview?.[0]?.shortname_text_color}
                  shortNameText={organizationOverview?.[0]?.shortname}
                  width="100px"
                  height="70px"
                />
              }
            />
          </div>

          <div className="my-md-0 my-1 ps-3">
            <div>
              <H4 className="ellipsis" style={{ lineHeight: "22px" }}>
                {organizationOverview?.[0]?.organisation_name}
              </H4>
              <div className="grey-info  fs-14 line-height-20">
                <div className="m-0 ellipsis line-height-20">
                  <Row>
                    <Col xxl={11}>
                      {" "}
                      {organizationOverview?.[0]?.street_name}&nbsp;
                      {organizationOverview?.[0]?.house_number} |&nbsp;
                      {organizationOverview?.[0]?.zipcode_id}&nbsp;
                      {organizationOverview?.[0]?.city_name}
                    </Col>
                  </Row>{" "}
                </div>
                <div className="m-0">
                  {organizationOverview?.[0]?.country_name || ""}
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col xl={3} lg={2} md={3}>
          <Row>
            <Col xl={6}>
              <div className="fs-14  line-height-20">
                <div className="pb-xl-2">
                  <span>Divisions</span> :&nbsp;
                  <span className="grey-info">
                    {organizationOverview?.[0]?.total_division !== ""
                      ? organizationOverview?.[0]?.total_division
                      : 0}
                  </span>
                </div>
                <div>
                  <span>Companies</span> :&nbsp;
                  <span className="grey-info">
                    {organizationOverview?.[0]?.total_company !== ""
                      ? organizationOverview?.[0]?.total_company
                      : 0}
                  </span>
                </div>
              </div>
            </Col>
            <Col xl={6}>
              <div className="fs-14  line-height-20">
                <div className="pb-xl-2">
                  <span>Properties</span> :&nbsp;
                  <span className="grey-info">
                    {organizationOverview?.[0]?.total_property !== ""
                      ? organizationOverview?.[0]?.total_property
                      : 0}
                  </span>
                </div>
                <div className="d-block text-truncate  ">
                  <span>Units</span> :&nbsp;
                  <span className="text-capitalize grey-info">
                    {organizationOverview?.[0]?.total_unit
                      ? organizationOverview?.[0]?.total_unit
                      : 0}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xl={5} lg={4} md={4} className="ps-xl-2 ps-lg-0">
          <Row>
            <Col xl={4}>
              <div className=" fs-14" style={{ paddingTop: "3px" }}>
                <span>Active users </span> :&nbsp;
                <span className="grey-info">
                  {organizationOverview?.[0]?.total_user !== ""
                    ? organizationOverview?.[0]?.total_user
                    : 0}
                </span>
              </div>
            </Col>
            <Col xl={8} className="d-lg-flex  align-items-center ">
              <div className="fs-14">Overall status:&nbsp;</div>

              <NewColorBox
                unitsOverview={organizationOverview?.[0]?.overall_status}
                TotalUnits={organizationOverview?.[0]?.total_unit}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default OrganisationLevelTopBar;
const item = {};

// Added Image component created for short name and image --- Updated by Suraj
