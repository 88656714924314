import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Menus from "../../../Components/Menus/Menus";

const EventOverviewContainer = ({ showPrivateEventPage }) => {
  return (
    <div
      className="open-house-headers p-4 radius-8 "
      style={{ border: "1px solid #f0f0f0", backgroundColor: "#F9fafa" }}
    >
      <div className="d-flex justify-content-between">
        <div
          className=" fs-10 fw-600 d-flex align-items-center  px-4  radius-4 "
          style={{ background: showPrivateEventPage ? "#77C7A1" : "#E5F1FA" }}
        >
          {showPrivateEventPage ? "PRIVATE VIEWING" : " OPEN HOUSE"}
        </div>
        <Menus
          itemcontainerwidth={"240px"}
          component={
            <div style={{ fontSize: "14px" }}>
              <div className="addpropContent px-3 py-2 ">
                <span className="ms-1">Edit this specific event</span>
              </div>
              <hr className="addpropertyHr m-0"></hr>
              <div className="addpropContent px-3 pt-2 pb-2 ">
                <span className="ms-1">Edit the whole series of events</span>
              </div>
            </div>
          }
        />
      </div>
      <div className="mt-2 fw-600 fs-24">
        {" "}
        {showPrivateEventPage
          ? "Mads Johnson and Caroline Johnson - Private viewing"
          : "Open House Blomsterbjerget"}{" "}
      </div>
      <Row className="pt-3">
        <Col lg={6}>
          <div
            className="d-flex "
            style={{ flexDirection: "column", gap: "12px" }}
          >
            <div className="d-flex">
              {showPrivateEventPage ? (
                <FontAwesomeIcon
                  className="pt-1"
                  icon="fa-regular fa-location-dot"
                  style={{ color: "#000000" }}
                />
              ) : (
                <FontAwesomeIcon
                  className="pt-1"
                  icon="fa-regular fa-house-building"
                  style={{ color: "#000000" }}
                />
              )}

              <span className="ms-2 light-font-text fs-16 ">
                {showPrivateEventPage
                  ? "Dybbølsbro 3, 1, 2300 København S | 7-W260"
                  : "Blomstervangen 14 3.tv | 2765 Smørum | 7-W260"}
              </span>
            </div>
            <div className="d-flex">
              <FontAwesomeIcon
                className="pt-1"
                icon="fa-regular fa-calendar-days"
                style={{ color: "#000000" }}
              />
              <span className="ms-2 light-font-text fs-16 ">
                Sunday 19th February 2023
              </span>
            </div>
          </div>
        </Col>
        <Col lg={3}>
          <div
            className="d-flex "
            style={{ flexDirection: "column", gap: "12px" }}
          >
            <div className="d-flex">
              <FontAwesomeIcon className="pt-1" icon="fa-regular fa-clock" />
              <span className="ms-2 light-font-text fs-16 ">
                11:00 - 12:00 (1 hour)
              </span>
            </div>
            {showPrivateEventPage ? (
              <div className="d-flex">
                <FontAwesomeIcon
                  className="pt-1"
                  icon="fa-regular fa-user"
                  style={{ color: "#000000" }}
                />
                <span className="ms-2 light-font-text fs-16 ">
                  Jesper Thorkildsen
                </span>
              </div>
            ) : (
              <div className="d-flex">
                <FontAwesomeIcon
                  className="pt-1"
                  icon="fa-regular fa-arrows-repeat"
                  style={{ color: "#000000" }}
                />
                <span className="ms-2 light-font-text fs-16 ">
                  Repeated every Sunday
                </span>
              </div>
            )}
          </div>
        </Col>

        {showPrivateEventPage ? (
          ""
        ) : (
          <Col lg={3}>
            <div
              className="d-flex "
              style={{ flexDirection: "column", gap: "12px" }}
            >
              <div className="d-flex">
                <FontAwesomeIcon
                  className="pt-1"
                  icon="fa-regular fa-user"
                  style={{ color: "#000000" }}
                />
                <span className="ms-2 light-font-text fs-16 ">
                  Jesper Thorkildsen
                </span>
              </div>
              <div className="d-flex">
                <FontAwesomeIcon
                  className="pt-1"
                  icon="fa-regular fa-users"
                  style={{ color: "#000000" }}
                />
                <span className="ms-2 light-font-text fs-16 ">No limit</span>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default EventOverviewContainer;
