import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Menus from "../../../Components/Menus/Menus";
import ETButton from "../../../Components/Buttons/ETButton";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";

import { InputAdornment } from "@mui/material";
import MRTTheme from "../../../Components/table/MRTTheme";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
} from "../../../Components/table/MRTGlobalProps";
import { PropertyTagToolTip } from "../../../assets/icons/icons";
import CustomPopup from "../../../Components/CustomPopup";
import ConfirmAndAddParticipant from "./ConfirmAndAddParticipant";
import EventOverviewContainer from "./EventOverviewContainer";

const Participants = () => {
  const [confirmAndAddPopup, setConfirmAndAddPopup] = useState(false);

  const [favorite, setFavorite] = useState([
    { id: 1, status: false },
    { id: 2, status: false },
    { id: 3, status: false },
    { id: 4, status: false },
    { id: 4, status: false },
  ]);
  const handleFavorite = (index, status) => {
    let newArray = [...favorite];
    newArray[index].status = !status;
    // console.log(newArray, "newArray");
  };
  const addDocumentData = [
    {
      name: "Mads Johnson",
      phone_no: "32 16 32 16",
      email: "hello@estatetool.dk",
      rating: "",
      responsible_agent: "Max Wildersans Brentford",
      Reg_date: "24.Feb, 2023",
      Follow_up: "24 Feb,",
      participated: "CONFIRMED",
    },
    {
      name: "Caroline johnson",
      phone_no: "32 16 32 16",
      email: "hello@estatetool.dk",
      rating: "",
      responsible_agent: "Max Wildersans Brentford",
      Reg_date: "24.Feb, 2023",
      Follow_up: "24 Feb,",
      participated: "CONFIRMED",
    },
  ];

  const RegistrationData = [
    {
      name: "Mads Johnson",
      phone_no: "32 16 32 16",
      email: "hello@estatetool.dk",
      rating: "",
      responsible_agent: "Max Wildersans Brentford",
      Reg_date: "24.Feb, 2023",
      Follow_up: "24 Feb,",
      participated: "CONFIRM",
    },
    {
      name: "Caroline johnson",
      phone_no: "32 16 32 16",
      email: "hello@estatetool.dk",
      rating: "",
      responsible_agent: "Max Wildersans Brentford",
      Reg_date: "24.Feb, 2023",
      Follow_up: "24 Feb,",
      participated: "CONFIRM",
    },
  ];

  const addedDocumentcolumns = useMemo(
    () => [
      {
        accessorKey: "type",
        header: "",
        size: 20,
        enableSorting: false,
        Cell: ({ cell }) => (
          <>
            <PropertyTagToolTip />
          </>
        ),
      },

      {
        accessorKey: "name",
        header: "Name",
        size: 120,
      },
      {
        accessorKey: "phone_no",
        header: "Phone",
        size: 100,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 50,
      },
      {
        accessorKey: "rating",
        header: "Rating",
        Cell: ({ row }) => (
          <>
            {favorite?.map((item, index) => (
              <FontAwesomeIcon
                key={index - 1}
                icon={
                  item.status === true
                    ? "fa-solid fa-star"
                    : "fa-regular fa-star"
                }
                size="lg"
                style={{ color: "#F6E651" }}
                onClick={() => handleFavorite(index - 1, item.status)}
              />
            ))}
          </>
        ),
        size: 120,
      },
      {
        accessorKey: "responsible_agent",
        header: "Responsible agent",
        size: 180,
      },

      {
        accessorKey: "Reg_date",
        header: "Reg.date",
        size: 100,
      },
      {
        accessorKey: "Follow_up",
        header: "Follow_up",
        size: 50,
      },

      {
        accessorKey: "participated",
        header: "Participated",
        size: 20,
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <div
              className=" cursor-pointer radius-4 px-1 py-1 d-flex justify-content-center align-items-center"
              style={{ background: "#77C7A1", height: "22px", width: "78px" }}
            >
              <span className="white-text fs-11">
                {" "}
                {row?.original?.participated}{" "}
              </span>
            </div>
          </>
        ),
      },
    ],
    []
  );

  const Registrationcolumns = useMemo(
    () => [
      {
        accessorKey: "type",
        header: "",
        size: 20,
        enableSorting: false,
        Cell: ({ cell }) => (
          <>
            <PropertyTagToolTip />
          </>
        ),
      },

      {
        accessorKey: "name",
        header: "Name",
        size: 120,
      },
      {
        accessorKey: "phone_no",
        header: "Phone",
        size: 100,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 50,
      },
      {
        accessorKey: "rating",
        header: "Rating",
        Cell: ({ row }) => (
          <>
            {favorite?.map((item, index) => (
              <FontAwesomeIcon
                key={index - 1}
                icon={
                  item.status === true
                    ? "fa-solid fa-star"
                    : "fa-regular fa-star"
                }
                size="lg"
                style={{ color: "#F6E651" }}
                onClick={() => handleFavorite(index - 1, item.status)}
              />
            ))}
          </>
        ),
        size: 120,
      },
      {
        accessorKey: "responsible_agent",
        header: "Responsible agent",
        size: 180,
      },

      {
        accessorKey: "Reg_date",
        header: "Reg.date",
        size: 100,
      },
      {
        accessorKey: "Follow_up",
        header: "Follow_up",
        size: 50,
      },

      {
        accessorKey: "participated",
        header: "Participated",
        size: 20,
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <div
              onClick={() => {
                setConfirmAndAddPopup(true);
              }}
              className=" cursor-pointer radius-4   py-2 px-1 d-flex justify-content-center align-items-center"
              style={{
                background: "#F0F0F0",
                height: "22px",
                width: "78px",
                border: "1px solid #d1d1d1",
              }}
            >
              <span className="c-black fs-11">
                {" "}
                {row?.original?.participated}{" "}
              </span>
            </div>
          </>
        ),
      },
    ],
    []
  );

  return (
    <div>
      {" "}
      <div className="mx-4">
        <EventOverviewContainer />
      </div>
      <div className="p-4 radius-8 mt-3 mx-4" style={{ background: "#619130" }}>
        <div className="form-label-title fs-18 white-text d-flex justify-content-center">
          Quickly add a participant
        </div>
        <div className=" d-flex justify-content-center light-font-text white-text">
          Type in the basic of the participant. You can afterwards add more
          information.
        </div>
        <div className="px-3 py-2 radius-8 my-3">
          <Row>
            {" "}
            <Col className="ps-lg-1 " lg={6}>
              <Row>
                <Col className="pe-lg-0 mb-sm-2 mb-lg-0" lg={6}>
                  {" "}
                  <div className="openHouseinput">
                    <input
                      className="normalTextstyle"
                      style={{ height: "40px", width: "100%" }}
                      placeholder="Name(required)"
                    />
                  </div>
                </Col>
                <Col className="pe-lg-0 mb-sm-2 mb-lg-0" lg={3}>
                  <div>
                    <ReactSelect placeholder={"Age"} />
                  </div>
                </Col>
                <Col className="pe-lg-0 mb-sm-2 mb-lg-0" lg={3}>
                  {" "}
                  <div className="openHouseinput">
                    <input
                      className="normalTextstyle"
                      style={{ height: "40px", width: "100%" }}
                      placeholder="Zip code"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              {" "}
              <Row>
                <Col className="pe-lg-0 mb-sm-2 mb-lg-0" lg={3}>
                  {" "}
                  <div className="openHouseinput">
                    <input
                      className="normalTextstyle"
                      style={{ height: "40px", width: "100%" }}
                      placeholder="Phone"
                    />
                  </div>
                </Col>
                <Col className="pe-lg-0 mb-sm-2 mb-lg-0" lg={6}>
                  <div className="openHouseinput">
                    <input
                      className="normalTextstyle"
                      style={{ height: "40px", width: "100%" }}
                      placeholder="Email(required)"
                    />
                  </div>
                </Col>
                <Col className="pe-lg-1 mb-sm-2 mb-lg-0" lg={3}>
                  <div
                    style={{
                      height: "40px",
                      overflow: "hidden",
                      borderRadius: "8px",
                    }}
                  >
                    <ETButton buttonText="ADD" width="100%" />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <MRTTheme
        table={
          <MaterialReactTable
            columns={addedDocumentcolumns}
            data={addDocumentData}
            icons={{ ...SortIcon }}
            state={{ isLoading: !addDocumentData }}
            // enableBottomToolbar={true}
            enableColumnActions={false}
            enablePagination={true}
            initialState={{ showGlobalFilter: true, showColumnFilters: false }}
            globalFilterFn="contains"
            muiSearchTextFieldProps={{
              ...SearchStyle,

              InputProps: {
                ...SearchIcon,
              },
            }}
            renderTopToolbar={({ table }) => (
              <>
                {" "}
                <div>
                  <div className="open-house-body mt-3">
                    <div className="py-4 mx-4">
                      <div className="form-label-title">
                        List of registrations and participants
                      </div>
                      <div className="light-font-text">
                        An overview of all registrations and current
                        participants of the event.
                      </div>
                      <Row className="mt-4">
                        <Col className="pe-lg-4" lg={3}>
                          <MRT_GlobalFilterTextField table={table} />
                        </Col>
                        <Col className="ps-lg-4" lg={{ span: 3, offset: 6 }}>
                          <ETButton
                            buttonText="ADD PARTICIPANT"
                            icon={true}
                            width={"100%"}
                            variant={"primary"}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <div
                        style={{
                          borderTop: "1px solid #F0F0F0",
                          position: "relative",
                        }}
                      >
                        <div
                          className="py-1 px-5 radius-6 "
                          style={{
                            background: "#F0F0F0",
                            position: "absolute",
                            top: "-18px",
                            left: "40%",
                          }}
                        >
                          Participants
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            enableRowActions
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "",
              },
              "mrt-row-select": {
                header: "",
              },
            }}
            renderRowActions={({ row, table }) => (
              <Menus
                itemheight={23}
                itemwidth={23}
                iconborderradius="4px"
                itemcontainerwidth="172px"
                component={
                  <div
                    className="addpropertyCountryHandle "
                    style={{ fontSize: "14px", width: "198px" }}
                  >
                    <div className="addpropContent px-3 py-2 ">
                      <span
                        className="ms-1"
                        style={{
                          fontFamily: "Cerebri sans",
                          fontSize: "14px",
                          fontweight: "300",
                          alignItems: "center",
                        }}
                      >
                        Add/edit information
                      </span>
                    </div>

                    <div className="addpropContent px-3 py-2 ">
                      <span
                        className="ms-1"
                        style={{
                          fontFamily: "Cerebri sans",
                          fontSize: "14px",
                          fontweight: "300",
                          alignItems: "center",
                        }}
                      >
                        Delete
                      </span>
                    </div>
                  </div>
                }
              />
            )}
            positionActionsColumn="last"
          />
        }
      />
      <div className="mt-4"> </div>
      <MRTTheme
        table={
          <MaterialReactTable
            columns={Registrationcolumns}
            data={RegistrationData}
            icons={{ ...SortIcon }}
            enableBottomToolbar={true}
            enableColumnActions={false}
            enablePagination={true}
            enableRowActions
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "",
              },
              "mrt-row-select": {
                header: "",
              },
            }}
            renderRowActions={({ row, table }) => (
              <Menus
                itemheight={23}
                itemwidth={23}
                iconborderradius="4px"
                itemcontainerwidth="172px"
                component={
                  <div
                    className="addpropertyCountryHandle "
                    style={{ fontSize: "14px", width: "198px" }}
                  >
                    <div className="addpropContent px-3 py-2 ">
                      <span
                        className="ms-1"
                        style={{
                          fontFamily: "Cerebri sans",
                          fontSize: "14px",
                          fontweight: "300",
                          alignItems: "center",
                        }}
                      >
                        Add/edit information
                      </span>
                    </div>

                    <div className="addpropContent px-3 py-2 ">
                      <span
                        className="ms-1"
                        style={{
                          fontFamily: "Cerebri sans",
                          fontSize: "14px",
                          fontweight: "300",
                          alignItems: "center",
                        }}
                      >
                        Delete
                      </span>
                    </div>
                  </div>
                }
              />
            )}
            positionActionsColumn="last"
            // muiTablePaperProps={{
            //   elevation: 0,
            //   sx: {
            //     fontFamily: "Cerebri sans !important",
            //     borderRadius: "0px 0px 8px 8px ",
            //     border: "none",
            //     background: "#F9FAFA",
            //     fontWeight: "300",
            //     ".css-1f2qhs8": {
            //       minWidth: "auto",
            //       marginRight: "10px",
            //     },
            //     th: {
            //       height: "50px",
            //       padding: "0.5rem",
            //       background: "#FFFFFF",
            //       paddingLeft: "1rem",
            //     },
            //     td: {
            //       height: "50px",
            //       padding: "0.5rem",
            //       fontWeight: "300",
            //       fontSize: "12px",
            //       paddingLeft: "1rem",
            //     },
            //     tr: {
            //       borderRadius: "4px",
            //       border: "2px solid red !important",
            //     },
            //     ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
            //       display: "none", // pagination space removing from page
            //     },
            //   },
            // }}
            // muiTableBodyProps={{
            //   sx: {
            //     fontFamily: "Cerebri sans !important",
            //     "& tr:nth-of-type(odd)": {
            //       backgroundColor: "#F9FAFA",
            //       borderRadius: "8px",
            //       fontWeight: "300",
            //     },
            //   },
            // }}
            // muiTableContainerProps={{
            //   elevation: 0,
            //   sx: {
            //     background: "#FFFFFF",
            //     paddingX: "24px",
            //     paddingY: "24px",
            //     borderRadius: "0px 0px 8px 8px ",

            //     boxShadow: "none",
            //     ".css-vv34gr-MuiTableRow-root": {
            //       boxShadow: "unset !important",
            //     },
            //   },
            // }}
            // muiTableHeadCellProps={{
            //   //simple styling with the `sx` prop, works just like a style prop in this example
            //   sx: {
            //     fontWeight: "700",
            //     fontSize: "12px",
            //     fontFamily: "Cerebri sans !important",
            //   },
            // }}
            renderTopToolbar={({ table }) => (
              <>
                {" "}
                <div>
                  <div
                    className=""
                    style={{
                      borderTop: "1px solid #F0F0F0",
                      position: "relative",
                    }}
                  >
                    <div
                      className="py-1 px-5 radius-6 "
                      style={{
                        background: "#F0F0F0",
                        position: "absolute",
                        top: "-18px",
                        left: "40%",
                      }}
                    >
                      Registration
                    </div>
                  </div>
                </div>
                <div className="mt-3 mx-4">
                  <Row className="mt-1">
                    <Col lg={{ span: 3, offset: 9 }}>
                      <ReactSelect placeholder={"Filter by slot times"} />
                    </Col>
                  </Row>
                </div>
                <CustomPopup
                  open={confirmAndAddPopup}
                  onHide={() => setConfirmAndAddPopup(false)}
                  title="Confirm and add information about the participant"
                  titleTooltipText="Confirm and add information about the participant"
                  title_color="black"
                  size={"xl"}
                  enableCrossButton={false}
                  action_button_label="YES"
                  action_button_label_color="white"
                  action_button_color="#619130"
                  cancel_button={true}
                  cancel_button_bordercolor="grey"
                  cancel_button_label="NO"
                  cancel_button_label_color="black"
                  cancel_button_color="#fff"
                  footer_color={"#F9FAFA"}
                  deletePopUpStyle={true}
                  titleTooltip={true}
                >
                  <div>
                    <ConfirmAndAddParticipant />
                  </div>
                </CustomPopup>
              </>
            )}
          />
        }
      />
    </div>
  );
};

export default Participants;
