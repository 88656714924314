import { Col, Row } from "react-bootstrap";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import Toggle from "../../../../Components/Switch/Switch";
import ReactSelect from './../../../../Components/DropDown/ReactSelect';
import PasswordField from "../../../../Components/GroupText/PasswordField";

const DnsSettings = () => {
    return (
        <Row
      className="ms-3 me-3  mb-3 pt-2 pb-4  "
      style={{ backgroundColor: "#fff", borderRadius: "8px" }}
    >
      <div className="mt-1 px-3 d-flex">
        <div className="fw-700">DNS settings</div>
        <div className="ps-2">
          <CustomToolTip height={12} text="DNS settings" />
        </div>
      </div>
      <hr className="mt-2" style={{ color: "#F0F0F0" }}></hr>
      <div className="ps-3">
      <div className="d-flex gap-5 align-items-center">
        <div>Set DNS Auto or manually</div>
        <div className="d-flex align-items-center">
            <span>Yes</span>
            <span><Toggle id="eido-project-DNS-type" /></span>
            <span className="ms-2">No</span>
        </div>
      </div>

      <Row className="align-items-center justify-content-start my-3">
            <Col lg={3}>
              <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">DNS name provider</p>
              </div>
            </Col>
            <Col lg={4}>
            {/* <div className="form-input ">
                <input
                  type="text"
                  placeholder="DNS name provider"
                  maxLength={30}
                />
              </div> */}
              <ReactSelect
                    // placeholder={"Property type"}
                    // value={value}
                    clearable={false}
                    // options={propertyTypesData?.data?.map((type) => ({
                    //     value: type?.Id,
                    //     label: `${type?.Text}`,
                    // }))}
                    // onChange={(e) => {
                    //     onChange(e);
                    // }}
                />
                
            </Col>
      </Row>
      <Row className="align-items-center justify-content-start my-3">
            <Col lg={3}>
              <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">DNS URL</p>
              </div>
            </Col>
            <Col lg={4}>
            <div className="form-input">
                <input
                  type="text"
                  placeholder="DNS URL"
                  maxLength={30}
                />
              </div>
            </Col>
      </Row>
      <Row className="align-items-center justify-content-start my-3">
            <Col lg={3}>
              <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">DNS User</p>
              </div>
            </Col>
            <Col lg={4}>
            <div className="form-input">
                <input
                  type="text"
                  placeholder="DNS User"
                  maxLength={30}
                />
              </div>
            </Col>
      </Row>
      <Row className="align-items-center justify-content-start my-3">
            <Col lg={3}>
              <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">DNS API token</p>
              </div>
            </Col>
            <Col lg={4}>
            <div className="form-input">
                <input
                  type="text"
                  placeholder="DNS API token"
                  maxLength={30}
                />
              </div>
            </Col>
      </Row>
      <Row className="align-items-center justify-content-start my-3">
            <Col lg={3}>
              <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">DNS Password</p>
              </div>
            </Col>
            <Col lg={4}>
            <PasswordField
                placeholder={"Password"}
                // value={value}
                // onChange={(e) => {
                //     onChange(e);
                //     clearErrors("socialsecuritynumber");
                // }}
                maxlength={15}
            />
            </Col>
      </Row>
      <Row className="align-items-center justify-content-start my-3">
            <Col lg={3}>
              <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">DNS Name</p>
              </div>
            </Col>
            <Col lg={4}>
            <div className="form-input">
                <input
                  type="text"
                  placeholder="DNS Name"
                  maxLength={30}
                />
              </div>
            </Col>
      </Row>
    </div>
    </Row>
    )
}

export default DnsSettings;