import styled from "styled-components";

/*----- styled elements for H1 to H5 -----*/

export const H1 = styled.h1`
  font-size: 32px;
  font-family: Cerebri sans-bold;
  //  line-height: 48px;
  letter-spacing: 0;
  color: #252525;
  margin-bottom: 0px;
`;
export const H2 = styled.h2`
  font-size: 24px;
  font-family: Cerebri sans-bold;
  //line-height: 30px;
  //letter-spacing: 0;
  color: #252525;
  margin-bottom: 0px;
`;
export const H3 = styled.h3`
  font-size: 20px;
  font-family: Cerebri sans-medium;
  //line-height: 24px;
  letter-spacing: 0;
  color: #252525;
  margin-bottom: 0px;
`;
export const H4 = styled.h4`
  font-size: 16px;
  font-family: Cerebri sans-medium;
  //line-height: 22px;
  letter-spacing: 0;
  color: #252525;
  margin-bottom: 0px;
`;
export const H5 = styled.h5`
  font-size: 14px;
  font-family: Cerebri sans-medium;
  line-height: 20px;
  letter-spacing: 0;
  color: #252525;
  margin-bottom: 0px;
`;

export const ETIcon = styled.div`
  color: ${(props) => props.theme.component.tabs.primary.color};
`;

export const GreenDiv = styled.div`
  color: ${(props) => props.theme.colors.primaryColor};
  width: fit-content;
  font-family: Cerebri sans-light;
  ${(props) =>
    props.mediumFontWeight &&
    `
 font-family: Cerebri sans-medium;
`};
`;
export const addConfigButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f1664b;
  padding-top: 13px;
  padding-bottom: 13px;
  border: 1px dashed #f0f0f0;
  background-color: #f9fafa;
  border-radius: 8px;
  cursor: pointer;
`;

export const GreenSpan = styled.span`
  color: ${(props) => props.theme.colors.primaryColor};
`;

export const DropZoneBorder = styled.div`
  border-color: ${(props) => props.theme.colors.primaryColor};
  border-radius: 8px;
  border-width: 1px;
  border-style: dashed;
`;


// created for add step button in control center > funnel > add funnel popup

export const AddStepBorder = styled.div`
  border-color: ${(props) => props.theme.colors.primaryColor};
  border: 1px dashed #619130;
  border-radius: 4px;
  min-height: 44px;
  color: #619130;
`;

// create for control center map style

export const GoogleMapReactWrapper = styled.div`
  height: 600px;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #f4f5f5;
`;

//  For Border change styled.label according to condation
export const PrimaryBorderColor = styled.label`
  border-color: ${(props) => props.theme.colors.primaryColor};
`;

// Add edit package add tag //
export const PrimarySelectedTag = styled.label`
  background-color: #ffffff;
  border-color: #d1d1d1;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  height: 44px;
  ${(props) =>
    props.mode &&
    `
 background-color:${props.theme.component.checkbox.primary.color + "11"};
 border-color: ${props.theme.component.checkbox.primary.color};
`}
`;

// Add edit package breadcrums //
export const PrimaryBreadcrums = styled.label`
  color: #000000;
  ${(props) =>
    props.mode &&
    `
 color: ${props.theme.component.checkbox.primary.color};
`}
`;

export const StyledCard = styled.div`
  height: ${(props) => props.height};
  ${(props) =>
    `
    background-color:${props.theme.component.checkbox.primary.color + "11"};
    border-color: ${props.theme.component.checkbox.primary.color};
`}

  border-style: dashed;
  border-width: 1px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyleTagInput = styled.input`
  height: ${(props) => props.height};
  ${(props) =>
    `
    background-color:${props.theme.component.checkbox.primary.color + "11"};
    border-color: ${props.theme.component.checkbox.primary.color};
`}

  border-style: solid;
  border-width: 1px;
  width: 100% !important;
  font-family: Cerebri sans-light !important;
  height: 60px !important;
  font-size: 20px !important;
  letter-spacing: 0.3px;
  padding: 15px 29px;
  outline: none;
  box-sizing: border-box;
  border-radius: 8px !important;
`;

export const StyledLoadingBar = styled.div`
  position: absolute;
  width: 100%;
  height: 2.5px;
  background-color: #f0f0f0;
  color: black;
`;
// background-color: ${props => props.theme.primaryColor};
// Card Structure

// export const CardWrapper = styled.div`
//   height: ${props => props.height};
//   background-color:${props.theme.component.checkbox.primary.color + "11"};
//   border-color: ${props.theme.component.checkbox.primary.color};
//   border-style: solid;
//   border-width: 1px;
//   border-radius: 8px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// export const CardWrapper = styled.div`
//   border-color: #619130;
//   border-style: solid;
//   border-width: 1px;
//   border-radius: 8px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   ${(props) =>
//     props.height &&
//     `
//   height: ${(props) => props.height}px;
//   background-color:${props.theme.component.checkbox.primary.color + "11"};
//    border-color: ${props.theme.component.checkbox.primary.color}
// `}
// `;

// export const Card = ({ height, children }) => {
//   return <CardWrapper height={height}>{children}</CardWrapper>;
// };
export const PlusIcon = styled.div`
  color: ${(props) => props.theme.component.checkbox.primary.color};
  display: flex;
  align-items: center;
  height: 100%;
`;

// screening dragable ui //
export const DraggableDiv = styled.label`
  background-color: #f0f0f0;
  ${(props) =>
    props.mode &&
    `

//  background-color: ${props.theme.component.checkbox.primary.color + "11"};
 border-style: solid;
 border-width: 1px;
 border-color: ${props.theme.component.checkbox.primary.color};
`}
`;
export const CircleMsg = styled.span`
  display: inline-block;
  color: #fff;
  cursor: pointer;
  text-align: center;
  line-height: 0px;
  border-radius: 50%;
  font-size: 10px;
  margin-left: 5px;
  min-width: 16px;
  min-height: 16px;
  background-color: ${(props) => props.theme.colors.primaryColor};
  span {
    display: inline-block;
    padding-top: 50%;
    padding-bottom: 50%;
    margin-left: 1px;
    margin-right: 1px;
  }
`;
export const HotMsg = styled.span`
  display: inline-block;
  color: #fff;
  text-align: center;
  line-height: 0px;
  border-radius: 50%;
  font-size: 10px;
  margin-left: 5px;
  min-width: 16px;
  min-height: 16px;
  background-color: #d00200;
  position: absolute;
  bottom: 32px;
  // transform: rotate(45deg);
  // animation: animateHeart 1.2s infinite;
  span {
    display: inline-block;
    padding-top: 50%;
    padding-bottom: 50%;
    margin-left: 1px;
    margin-right: 1px;
  }
`;

export const NormalNotification = styled.span`
  display: inline-block;
  color: #000;
  text-align: center;
  line-height: 0px;
  border-radius: 50%;
  font-size: 10px;
  margin-left: 5px;
  min-width: 16px;
  min-height: 16px;
  background-color: #fff;
  border: 1px solid #d1d1d1;

  span {
    display: inline-block;
    padding-top: 50%;
    padding-bottom: 50%;
    margin-left: 1px;
    margin-right: 1px;
  }
`;

export const MediaThumbnail = styled.div`
  display: inline-flex;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
  position: relative;
  div {
    display: flex;
    min-width: 0;
    overflow: hidden;
    border-radius: 4px;
    img {
      display: block;
      width: auto;
      height: 100%;
    }
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
`;

// screening add fields top navigator tabs //
export const ScreeningFieldsTabs = styled.div`
  color: #000000;
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Cerebri sans-light;
  font-size: 14px;
  ${(props) =>
    props.mode &&
    `
 font-family: Cerebri sans-medium;
 color: ${props.theme.component.checkbox.primary.color};
 border-style: solid;
 border-width: 2px;
 border-bottom-color: ${props.theme.component.checkbox.primary.color};
 border-top: none;
 border-left: none;
 border-right: none;
`}
`;
// Predefined screening fields button  //

export const PredefinedFields = styled.div`
  color: #000000;
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Cerebri sans-light;
  font-size: 14px;
  border-radius: 8px;
  border-style: solid;
  border-width: 2.5px;
  border-color: #d1d1d1;
  background-color: #d1d1d1;
  opacity: 0.7;
  ${(props) =>
    props.mode &&
    `
  opacity: 1;
  border-color: #888888;
  `}
`;

// teams table name container
export const TeamsImageContainer = styled.div`
  color: #ffffff;
  background-color: ${(props) => props.theme.colors.primaryColor};
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Cerebri sans-medium;
  font-size: 20px;
  border-radius: 100%;
`;
// user counts
export const UsersCount = styled.span`
  display: inline-block;
  color: #fff;
  cursor: pointer;
  text-align: center;
  line-height: 0px;
  border-radius: 35px;
  font-size: 12px;
  min-width: 29px;
  height: 21px;
  background-color: ${(props) => props.theme.colors.primaryColor};
  span {
    display: inline-block;
    // padding-top: 50%;
    // padding-bottom: 50%;
    line-height: 21px;
    margin-left: 1px;
    margin-right: 1px;
  }
`;

// teams table name container
export const TeamsProfileContainer = styled.div`
  color: #ffffff;
  background-color: ${(props) => props.theme.colors.primaryColor};
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Cerebri sans-medium;
  font-size: 120px;
  border-radius: 100%;
`;
// team icon button with icon
export const IconButton = styled.button`
  color: ${(props) => props.theme.colors.primaryColor};
  border: ${(props) => props.theme.component.button.tertiary.borderColor};
  border-radius: 6px;
  height: 34px;
  padding: 6px 16px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Cerebri sans-medium;
  &:hover {
    background-color: ${(props) =>
      props.theme.component.button.tertiary.hoverBgColor};
  }
`;

// divisions FAQ category icon box
export const CategoryIconBox = styled.div`
  color: #888888;
  background-color: white;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  width: 84px;
  height: 86px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  margin: 4px;
  cursor: pointer;
  font-family: Cerebri sans-light;
  padding-top: 20px;
  ${(props) =>
    props.mode &&
    `
    background-color: ${props.theme.colors.primaryColor}11;
    border-color:${props.theme.colors.primaryColor};
`}
`;
