import { Get, Post, Put } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

export const SendOtp = (customHeader = null) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.SendOtp, customHeader, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Post Send Otp With Payload
export const SendOtpWithPayload = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.SendOtp, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

export const ValidateOtp = (validateData) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.ValidateOtp,
      validateData?.token,
      validateData?.payload
    )
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject({
          error: true,
          message: error?.message,
          data: error?.response?.data,
        });
      });
  });
};
