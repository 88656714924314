import React, { useState } from "react";
import StandardImage from "../../../assets/images/StandardSignin.jpg";
import estatelogologin from "../../../assets/images/NewEstatetoolLogo.png";
import { ReactComponent as Textimage } from "../../../assets/icons/svg_icons/Loginusernameicon.svg";
import { ReactComponent as Passimage } from "../../../assets/icons/svg_icons/Loginusernameicon.svg";
import ForgotPassword from "./ForgotPassword";
import ResetPasswordEmailConfirmation from "./ResetPasswordEmailConfirmation";
import "../GeneralLogin.css";
import { usePostSendResetMail } from "../../../hooks/queries/useUserQueries";
import { status } from "../../../model/Constant";
import { toast } from "react-toastify";

const ForgotPasswordWizard = () => {
  //====================== States ===================//
  const [resetPassword, setResetPassword] = useState("StepOne");
  const [userEmail, setUserEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  //====================== Handlers ===================//
  //----------------------------React query--------------------//

  const { mutateAsync: PostSendResetMail, isLoading: PostSendResetMailIsLoading } = usePostSendResetMail();


  const handleSendInstruction = async (data) => {
    setUserEmail(data?.forgetPasswordEmail)
    const userData = {
      user_email: data?.forgetPasswordEmail,
      system_id: "ETM",
    };
    try {
      const response = await PostSendResetMail(userData);
      if (response.status === status.s) {
        setResetPassword("StepTwo");
        setErrorMessage(response?.data?.message)
      }
    } catch (error) {
      setErrorMessage(error?.data?.message)

    }
  };

  const handleForgotScreen = () => {
    setResetPassword("StepOne");
  };

  //====================== Reset screens ===================//

  const ForgotPasswordWizard = {
    StepOne: (
      <ForgotPassword
        data={customproperties}
        handleSendInstruction={handleSendInstruction}
        userEmail={userEmail}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        disableButtonOnApiLoad={PostSendResetMailIsLoading}

      />
    ),
    StepTwo: (
      <ResetPasswordEmailConfirmation
        handleForgotScreen={handleForgotScreen}
        data={customproperties}
        userEmail={userEmail}
      />
    ),
  };

  return (
    <div>
      {customproperties?.map((data, id) => (
        <React.Fragment>
          <div
            key={id}
            className="Maindiv d-flex flex-column justify-content-center align-items-center pt-4 w-100 scrollable-container-y"
            style={{ backgroundImage: `url(${data?.backgroundimage})` }}
          >
            {ForgotPasswordWizard[resetPassword]}
            <div
              className="light-font-text my-4 px-3"
              style={{
                borderRadius: "1px",
                backgroundColor: "rgba(255, 255, 255, 0.50)",
                color: "#000",
              }}
            >
              This solution is powered by{" "}
              <span className="form-label-title " style={{ color: "#000" }}>
                estatetool
              </span>{" "}
              | © 2023 - All rights reserved
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ForgotPasswordWizard;

//================ Dummy data for forgot password screen =================//
const customproperties = [
  {
    id: "1",
    ContainerBackground: "#2B3C49F2",
    backgroundimage: StandardImage,
    logo: estatelogologin,
    Biocolor: "white",
    TextfieldLabelcolor: "#FFFFFF",
    TextfieldUsernameIcon: <Textimage />,
    TextfieldPassIcon: <Passimage />,
    Textfieldcolor: "#FFFFFF",
    TextfieldBordercolor: "#FFFFFF",
    TextfieldfontFamily: "Cerebri sans-light",
    Signinbuttonbgcolor: "#F1664B",
    FormLinkandTextboxCheckColor: "#FFFFFF",
    TextfieldDecoration: "1px solid #FFFFFF",
  },
];
