import { Delete, Get, Post, Put } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

//Get Company By Organisation Id
export const GetOrganizationList = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Organization_GetOrganizationList, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Organisation By Public type
export const GetOrganizationByPublicType = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Organization_Get_PublicType, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Get Organisation By Id
export const GetOrganizationById = (OrganizationId) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Organization_Get_OrganizationById,
      null,
      OrganizationId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Update Organisation
export const PutUpdateOrganization = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Organization_Edit_Organization, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Organisation side type
export const GetOrganizationSideType = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Organization_GetOrganizationSideType, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Get Organisation side type
export const GetOrganizationFloorType = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Organization_GetOrganizationFloorType, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Get Organisation overview by organization Id
export const GetOrganizationOverviewById = (organization_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Organization_GetOrganizationOverviewById,
      null,
      organization_id + "/overview"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Get Organisation Maintenance Details
export const GetOrganisationMaintenanceDetails = (OrganizationId) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Organisation_GetOrganisationMaintenanceDetails,
      null,
      OrganizationId + "/maintenance"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Update OrganisationMaintenanceDetails
export const PutOrganisationMaintenanceDetails = (data) => {

  return new Promise((resolve, reject) => {
    Put(
      Site.ET,
      Endpoint.ET.Organisation_UpdateOrganisationMaintenanceDetails,
      null,
      data
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// AddOrganisation MaintenanceDetails
export const PostOrganisationMaintenanceDetails = (data) => {

  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Organisation_AddMaintenance,
      null,
      data
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};



// Get Organisation Maintenance History
export const GetOrganisationMaintenanceHistory = (OrganizationId) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Organisation_GetMaintenanceHistory,
      null,
      OrganizationId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Delete/Remove maintenance date
export const DeleteOrganisationMaintenance = (maintenanceId) => {
  return new Promise((resolve, reject) => {
    Delete(
      Site.ET,
      Endpoint.ET.Organisation_DeleteMaintenance,
      null,
      maintenanceId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Add Organisation Customisation
export const PostUpdateOrganisationCustomisation = (data) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Organisation_Post_Update_Organisation_Customisation,
      null,
      data
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};