import React, { useEffect } from "react";
import { NoRecordsToDisplay } from "../../../../../../../../utils/validationConstants";
import { useBreadcrumb } from "../../../../../../../../Components/BreadcrumbContext ";

const Status = () => {
  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    updateBreadcrumbs(["Overview", "Status"]);
  }, [])

  return (
    <div className="p-4">
      Status
      {/* <div className="d-flex justify-content-center align-items-center grey-text">
        {NoRecordsToDisplay}
      </div> */}
    </div>
  );
};

export default Status;
