import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import Toggle from "../../../../Components/Switch/Switch";
import ETButton from "../../../../Components/Buttons/ETButton";
import { H3, H5 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { Controller, useForm } from "react-hook-form";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";
import { toast } from "react-toastify";
import { usePostAddLead } from "../../../../hooks/queries/useCustomerFunnel";
import InputField from "../../../../Components/Textfield/InputField";
import CustomReactSelect from "../../../../Components/DropDown/CustomReactSelect";
import LanguageSelectSearch from "../../../../Components/FilterDropdownSelection/LanguageSelectSearch";

const ConfirmCancelSigningOrder = ({
  nextWizardScreenText,
  cancelSecondPageReasonHeader,
  cancelSecondPageReasonHeaderTooltip,
  cancelReasonSecondPageFooter,
  cancelSecondPageReasonFooterTooltip,
  secondPageStatus,
  // setStepWizard, //used in create new leads
  // setProgress, //used in create new leads
  // assignPropertyToLead, //used in create new leads
  handleBack = () => {},
  handleCreate = () => {},
  // unitrowSelectionValue,
  // rowSelectionValue
  usedFor = "All",
  buttonText = "CREATE",
  customerName,
  notifyOtherAgentDropdown,
  sendConfirmationToSms,
  sendConfirmationToMyPage,
  setSendConfirmationToSms = () => {},
  setSendConfirmationToMyPage = () => {},
}) => {
  // console.log("cancelReasonSecondPageFooter", cancelReasonSecondPageFooter);
  /* ------------------ State ----------------- */
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
    watch,
  } = useForm({
    defaultValues: {
      toRecipents: "",
      notifyAgent: "",
      emailTemplate: "",
      subject: "",
      message: "",
    },
  });

  // Handle Submit Cancel Signing Form

  const handleSubmitCancelSigningForm = async (submitCancelSigningData) => {
    handleCreate(submitCancelSigningData);
  };

  const handleInputChange = (value, meta) => {
    // Handle the input change logic here
    console.log("Input Value:", value);
    console.log("Meta:", meta);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(handleSubmitCancelSigningForm)}>
        <div className="me-1">
          {usedFor == "All" ? (
            <div className="d-flex align-items-center p-4">
              <H3>{cancelSecondPageReasonHeader}</H3>
              <div className="ps-2 cursor-pointer">
                {" "}
                <CustomToolTip
                  size="sm"
                  text={cancelSecondPageReasonHeaderTooltip}
                />{" "}
              </div>
            </div>
          ) : (
            <div className="pt-3 pb-2"></div>
          )}
          <div className="border-bottom">
            <div className="px-4">
              <Row className="pb-3">
                <Col lg={7}>
                  <Controller
                    control={control}
                    {...register("toRecipents")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <div className="position-relative">
                        <div
                          className="bg-white position-absolute px-1  font-medium fs-8 grey-text"
                          style={{ left: "14px", top: "-5px" }}
                        >
                          To recipients
                        </div>
                        <div className="badge-input">
                          <span
                            className="py-1 px-2 mx-1  radius-4  bgcolor-d-white "
                            style={{
                              border: "0.5px solid #888",
                              width: "fit-content",
                            }}
                          >
                            <FontAwesomeIcon
                              icon="fa-regular fa-circle-xmark"
                              style={{ color: "#888888", cursor: "pointer" }}
                            />
                            <span className="ps-2">{customerName}</span>
                          </span>

                          {/* <span
                            className="py-1 px-2 mx-1  radius-4   bgcolor-d-white  "
                            style={{
                              border: "0.5px solid #888",
                              width: "fit-content",
                            }}
                          >
                            <FontAwesomeIcon
                              icon="fa-regular fa-circle-xmark"
                              style={{ color: "#888888", cursor: "pointer" }}
                            />
                            <span className="ps-2">Rikke Violette Nielsen</span>
                          </span> */}
                        </div>
                      </div>
                    )}
                  />
                  {errors?.toRecipents && (
                    <p className="error-msg ">{REQUIRED_ERR}</p>
                  )}
                </Col>
              </Row>
              <Row className="pb-3">
                <Col lg={7}>
                  <Controller
                    name="notifyAgent"
                    {...register("notifyAgent")}
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      // <ReactSelect
                      //   placeholder="Notify other agents in the system"
                      //   iserror={errors?.notifyAgent}
                      //   options={notifyOtherAgentDropdown?.map((item) => ({
                      //     value: item?.Id,
                      //     label: item?.Text,
                      //   }))}
                      //   value={value}
                      //   onChange={(e) => {
                      //     onChange(e);
                      //     clearErrors("notifyAgent");
                      //   }}
                      // />

                      <CustomReactSelect
                        placeholder={"Notify other agents in the system"}
                        iserror={errors?.notifyAgent}
                        options={notifyOtherAgentDropdown?.map((item) => ({
                          value: item?.Id,
                          label: item?.Text,
                        }))}
                        inputValue={value}
                        isSearchable={false}
                        isMulti={true}
                        // onInputChange={(val) => setInputValue(val)}
                        // onChange={(e) => {
                        //   onChange(e);
                        // }}
                        // name={field.name}
                        onInputChange={handleInputChange}
                        setValue={setValue}
                      />

                      // <LanguageSelectSearch
                      //   options={notifyOtherAgentDropdown?.map((item) => ({
                      //     value: item?.Id,
                      //     label: item?.Text,
                      //   }))}
                      //   inputValue={value}
                      //   // onInputChange={(val) => setSelectLanguage(val)}
                      //   isSearchable={false}
                      //   placeholder={"Notify other agents in the system"}
                      //   searchBarHeader={"Search languages"}
                      // />
                    )}
                  />
                </Col>
              </Row>
              <Row className="pb-4">
                <Col lg={7}>
                  <Controller
                    name="emailTemplate"
                    {...register("emailTemplate")}
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect placeholder={"Email template"} />
                    )}
                  />
                  {errors?.emailTemplate && (
                    <p className="error-msg ">{REQUIRED_ERR}</p>
                  )}
                </Col>
              </Row>
            </div>
          </div>

          <div className="">
            <div className="p-4 border-bottom">
              <Row className="pb-3">
                <Col lg={7}>
                  <Controller
                    control={control}
                    {...register("subject")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <InputField
                          id={"subject"}
                          value={value}
                          type={"text"}
                          placeholder={"Subject"}
                          iserror={errors.subject}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("subject");
                          }}
                        />
                      );
                    }}
                  />
                </Col>
              </Row>

              <Row className="">
                <Col lg={7}>
                  <Controller
                    name="message"
                    {...register("message")}
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <div className="form-float openhouse-des">
                        <textarea
                          name="message"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          class="inputText"
                          cols="20"
                          rows="2"
                          placeholder=" "
                          // value={value}
                          // onChange={(e) => {
                          //   onChange(e);
                          //   clearErrors("altText");
                          //   // setAltText(e.target.value);
                          // }}
                        ></textarea>
                        <label className="floating-label">Message</label>
                      </div>
                    )}
                  />
                  {errors?.message && (
                    <p className="error-msg ">{REQUIRED_ERR}</p>
                  )}
                </Col>
              </Row>
            </div>

            <Row>
              <Col>
                <div className="mb-3">
                  <div className="d-flex align-items-center px-4 py-3">
                    <H3>{cancelReasonSecondPageFooter}</H3>
                    <div className="ps-2 cursor-pointer">
                      {" "}
                      <CustomToolTip
                        size="sm"
                        text={cancelSecondPageReasonFooterTooltip}
                      />{" "}
                    </div>
                  </div>

                  <div className="d-flex align-items-center ps-3 pe-3 gap-3">
                    <div className="d-flex align-items-center gap-2 pb-2">
                      {" "}
                      <Toggle id={"Email"} disabled checked={true} />
                      <H5>Email</H5>
                    </div>
                    <div className="light-font-text d-flex align-items-center gap-2 pb-2">
                      {" "}
                      <Toggle
                        id={"SMS"}
                        checked={sendConfirmationToSms}
                        onChange={(e) =>
                          setSendConfirmationToSms(e.target.checked)
                        }
                      />
                      <H5>SMS</H5>
                    </div>
                    <div className="light-font-text d-flex align-items-center gap-2 pb-2">
                      {" "}
                      <Toggle
                        id={"active_users"}
                        checked={sendConfirmationToMyPage}
                        onChange={(e) =>
                          setSendConfirmationToMyPage(e.target.checked)
                        }
                      />
                      <H5>My Page</H5>
                    </div>
                  </div>
                  <div className="px-4 py-2 light-font-text ">
                    {secondPageStatus}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="py-2 px-3 d-flex flex-row justify-content-between  footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="Back"
            type={"button"}
            onClick={handleBack}
          />

          <ETButton
            variant={"primary"}
            buttonText={buttonText}
            type={"submit"}
            // onClick={handleClickCreate}
            // onClick={"handleClickNext"}
          />
        </div>
      </Form>
    </>
  );
};

ConfirmCancelSigningOrder.defaultProps = {
  cancelSecondPageReasonHeader: "Confirmation send to the customer",
  cancelSecondPageReasonHeaderTooltip: "Confirmation send to the customer",
  cancelReasonSecondPageFooter: "Send confirmation to",
  cancelSecondPageReasonFooterTooltip: "Send confirmation to",
  secondPageStatus:
    "It’s only possible to send an confirmation to MyPage if the customer has a active profile.",
};

export default ConfirmCancelSigningOrder;

// modified by Suraj -- added functionality related to MCTAU = Move customer to another unit
