import React, { useRef } from "react";
import { Col, Image, Placeholder, Row } from "react-bootstrap";
import photoComingSoon from "../../../../assets/images/photo-coming-soon.jpg";
import { useState } from "react";
import { GreenDiv, H2 } from "../../../../styles/Common.style";
import { StatusTable } from "../../../controlCenter/economy/StatusTable";
import {
  useGetPropertyWithOverviewByPropertyId,
  useGetStatusOverviewByPropertyId,
  useGetStatusOverviewSubDetails,
} from "../../../../hooks/queries/usePropertyQueries";
import { usePropertyId } from "../../../../hooks/stores/usePropertyStore";
import { useUnitType } from "../../../../hooks/stores/useUnitStore";
import { useControlCenter } from "../../../../hooks/stores/useControlCenterStore";
import { useGetAnalyasisSubDeatils } from "../../../../hooks/queries/useControlCenterQueries";

const PropertyOverview = ({ propertyOverviw, isLoading, isResidentalPage }) => {
  //----------------------zuustand-----------------------------//
  const propertyId = usePropertyId((state) => state.propertyId);

  const { typeOfUnit } = useUnitType((state) => state);
  //-----------------------states------------------------------//
  const [onPropertyNameHover, setPropertyNameHover] = useState(false);
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const content = useRef(null);

  const [statusId, setStatusId] = useState({
    mainStatusId: "",
    subStatusId: "",
  });

  //-----------------------react queries------------------------------//
  const { data: getStatusOverviewByPropertyIdData } =
    useGetStatusOverviewByPropertyId(propertyId);
  const { data: getStatusOverviewSubDetails } = useGetStatusOverviewSubDetails(
    propertyId,
    statusId?.mainStatusId,
    statusId?.subStatusId
  );

  const { data: getPropertyWithOverviewData } =
    useGetPropertyWithOverviewByPropertyId(propertyId, typeOfUnit);
  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(setActive === "active" ? "0px" : `100%`);
  }
  console.log(propertyOverviw, "propertyOverviw");
  const [propertyOverview, setPropertyOverview] = useState(true);
  return (
    <div style={{ backgroundColor: "#F0F0F0" }} className="p-4 rounded">
      <Placeholder animation="glow">
        <div>
          <Row
            className="py-4 px-2 m-0 mainborder"
            style={{
              backgroundColor: "#fff",
              borderRadius:
                isResidentalPage === true ? "8px 8px 0px 0px" : "8px",
            }}
          >
            <Col xl={4} lg={4} md={5}>
              {propertyOverviw?.map((item) => (
                <div
                  key={item?.property_id}
                  className="d-flex flex-xl-row  flex-column"
                >
                  <div className="px-md-2 ps-4 d-flex justify-content-md-start justify-content-center">
                    <div>
                      {isLoading ? (
                        <div className="" style={{ width: "119px" }}>
                          {/* <Placeholder animation="glow" > */}
                          <div
                            className="placeholder col-10 bg-secondary"
                            style={{ height: "88px" }}
                          ></div>
                          {/* </Placeholder> */}
                        </div>
                      ) : (
                        <Image
                          src={
                            item?.image_url ? item?.image_url : photoComingSoon
                          }
                          alt="propertImage"
                          width="100px"
                          height="70px"
                          rounded
                        />
                      )}
                    </div>
                  </div>
                  <div className=" ps-xl-2 ps-0 ellipsis d-flex justify-content-md-start justify-content-center">
                    {isLoading ? (
                      <div className="" style={{ width: "119px" }}>
                        {/* <Placeholder animation="glow" > */}
                        <div
                          className="placeholder col-10 bg-secondary"
                          style={{ height: "88px" }}
                        ></div>
                        {/* </Placeholder> */}
                      </div>
                    ) : (
                      <div
                        className="my-md-0 my-1 ps-xl-0 ps-2  py-xl-0 py-2 ellipsis"
                        // style={{
                        //   minWidth: "117px",
                        //   width: "80%",
                        // }}
                      >
                        <p
                          className="m-0 topbar-header fs-14  "
                          onMouseOver={() => setPropertyNameHover(true)}
                          onMouseLeave={() => setPropertyNameHover(false)}
                        >
                          {item?.property_name}
                        </p>
                        {onPropertyNameHover &&
                          item?.property_name.length > 25 && (
                            <div
                              style={{
                                position: "absolute",
                                // width: "200px",
                              }}
                              className="fs-12 ellipsis grey-info bg-white radius-8 border p-1 text-wrap text-capitalize"
                            >
                              {item?.property_name}
                            </div>
                          )}
                        <div
                          className="fs-12 created-by ellipsis "
                          style={{
                            lineHeight: "15px",
                          }}
                        >
                          <p className="m-0">
                            {item?.street_name} {item?.house_number}
                          </p>
                          <p className="m-0 d-block w-100 ellipsis">
                            {item?.zipcode_id} {item?.city_name}
                          </p>
                          <p className="m-0">{item?.country_name}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </Col>

            {propertyOverviw?.map((item) => (
              <Col
                key={item?.property_id}
                xl={8}
                lg={7}
                md={7}
                className="d-flex flex-sm-row flex-column "
                style={{
                  justifyContent: " space-between",
                  alignItems: "center",
                }}
              >
                {isLoading ? (
                  <div className="d-flex">
                    <div className="" style={{ width: "119px" }}>
                      <Placeholder
                        xs={8}
                        style={{ borderRadius: "2px" }}
                        className="bg-secondary"
                      />

                      <Placeholder
                        xs={8}
                        style={{ borderRadius: "2px" }}
                        className="bg-secondary"
                      />
                    </div>
                    <div className="" style={{ width: "119px" }}>
                      <Placeholder
                        xs={8}
                        style={{ borderRadius: "2px" }}
                        className="bg-secondary"
                      />{" "}
                      {""}{" "}
                      <Placeholder
                        xs={8}
                        style={{ borderRadius: "2px" }}
                        className="bg-secondary"
                      />
                    </div>
                    <div className="" style={{ width: "119px" }}>
                      <Placeholder
                        xs={8}
                        style={{ borderRadius: "2px" }}
                        className="bg-secondary"
                      />{" "}
                      {""}{" "}
                      <Placeholder
                        xs={8}
                        style={{ borderRadius: "2px" }}
                        className="bg-secondary"
                      />
                    </div>
                    <div className="" style={{ width: "119px" }}>
                      <Placeholder
                        xs={8}
                        style={{ borderRadius: "2px" }}
                        className="bg-secondary"
                      />{" "}
                      {""}{" "}
                      <Placeholder
                        xs={8}
                        style={{ borderRadius: "2px" }}
                        className="bg-secondary"
                      />
                    </div>
                    <div className="" style={{ width: "119px" }}>
                      <Placeholder
                        xs={8}
                        style={{ borderRadius: "2px" }}
                        className="bg-secondary"
                      />{" "}
                      {""}{" "}
                      <Placeholder
                        xs={8}
                        style={{ borderRadius: "2px" }}
                        className="bg-secondary"
                      />
                    </div>
                  </div>
                ) : (
                  <Row className="d-flex justify-content-md-start justify-content-center ">
                    {item?.overall_status?.map((statusData, i) => (
                      <React.Fragment key={i}>
                        <Col xl={2} lg={3} md={3} className="d-flex ">
                          <PropertyData
                            status={statusData?.status_name}
                            number={statusData?.units}
                            sideBorder="1px solid #000000"
                            // change="3"
                            color="#77C7A1"
                            //   "#F1664B"
                            // arrow={<DeltaArrow component="3" />}
                          />
                        </Col>

                        <Col
                          className="d-flex"
                          style={{ alignItems: "center" }}
                        >
                          <div className="cardBorderLeft"></div>
                        </Col>
                      </React.Fragment>
                    ))}
                    <Col xl={2} lg={3} md={6} className=" ps-md-0 ps-5">
                      <PropertyData
                        status="Total units"
                        number={item?.total_unit}
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            ))}
          </Row>
          {typeOfUnit === 1 && (
            <Row className="m-0">
              <Col className="px-0 ">
                <div
                  ref={content}
                  style={{ maxHeight: `${setHeight}` }}
                  className="accordion__content px-4"
                >
                  <StatusTable
                    getAnalyasisdData={getStatusOverviewByPropertyIdData?.data}
                    subStatusData={getStatusOverviewSubDetails?.data}
                    setStatusId={setStatusId}
                    statusId={statusId}
                    propertyId={propertyId}
                    propertyOverview={propertyOverview}
                  />
                </div>
                <div
                  className={`status_overview ${setActive} py-2`}
                  onClick={toggleAccordion}
                >
                  <GreenDiv>
                    {setActive === "active" ? "HIDE" : "SHOW"} STATUS OVERVIEW
                  </GreenDiv>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Placeholder>
    </div>
  );
};
// {
//   typeOfUnit !== 1 && (
//     <Col xl={2} lg={3} md={3}>
//       <PropertyData
//         status="In process"
//         // number={item?.rented}
//         number={0}
//         sideBorder="1px solid #000000"
//         // change="6"
//         color="#77C7A1"
//         //   "#F1664B"
//         // arrow={<DeltaArrow component="6" />}
//       />
//     </Col>
//   );
// }

export default PropertyOverview;

const PropertyData = ({ status, number, change, color, sideBorder, arrow }) => {
  return (
    <>
      <div className="p-2 d-flex ">
        <div>
          <div
            className="ms-2 me-3 light-font-text fs-13"
            style={{ width: "max-content" }}
          >
            {status}
          </div>

          <H2 className="ms-2 fs-18  d-flex ">
            {number}

            <div
              className="ps-1"
              style={{
                color: color,
                fontSize: "11px",
                display: "flex",
                fontWeight: "500",
              }}
            >
              {arrow}
              {change}
            </div>
          </H2>
        </div>
        {/* <div
              className=""
              style={{
                borderLeft: sideBorder,
                height:"30px"
              }}
            ></div> */}
      </div>
    </>
  );
};
