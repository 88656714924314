import { Col, Row } from "react-bootstrap";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import Toggle from "../../../../Components/Switch/Switch";

const Netlify = () => {
  return (
    <Row
      className="ms-3 me-3  mb-3 pt-2 pb-4  "
      style={{ backgroundColor: "#fff", borderRadius: "8px" }}
    >
      <div className="mt-1 px-3 d-flex">
        <div className="fw-700">Netlify</div>
        <div className="ps-2">
          <CustomToolTip height={12} text="Netlify" />
        </div>
      </div>
      <hr className="mt-2" style={{ color: "#F0F0F0" }}></hr>
      <div className="ps-3">
      <div className="d-flex gap-5 align-items-center">
        <div>Domain hosted by ET</div>
        <div className="d-flex align-items-center">
            <span>Yes</span>
            <span><Toggle id="eido-domain-hosted" /></span>
            <span className="ms-2">No</span>
        </div>
      </div>
      <Row className="align-items-center justify-content-start my-3">
            <Col lg={3}>
              <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">Project name</p>
              </div>
            </Col>
            <Col lg={4}>
            <div className="form-input ">
                <input
                  type="text"
                  placeholder="Project name"
                  maxLength={30}
                />
              </div>
            </Col>
      </Row>
      <Row className="align-items-center justify-content-start my-3">
            <Col lg={3}>
              <div className="d-flex justify-content-start align-item-center">
                <p className="mb-0 fs-16">Project domain</p>
              </div>
            </Col>
            <Col lg={4}>
            <div className="form-input ">
                <input
                  type="text"
                  placeholder="Project domain"
                  maxLength={30}
                />
              </div>
            </Col>
      </Row>
    </div>
    </Row>
  );
};

export default Netlify;
