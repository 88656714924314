import React from "react";
import { NavLink } from "../../../Components/Tabs/ReactTabs.style";
import { Outlet } from "react-router-dom";

const DivisionsSettings = () => {
  const divisionSettingTabs = [
    {
      Id: "1",
      tab: "MyPage",
      link: "my-page",
    },
    {
      Id: "2",
      tab: "Screening",
      link: "screening",
    },
    {
      Id: "3",
      tab: "FAQ",
      link: "FAQ",
    },
    {
      Id: "4",
      tab: "Guides",
      link: "guides",
    },
    // {
    //   Id: "5",
    //   tab: "Screening",
    //   link: "screening",
    // },
  ];
  return (
    <>
      <div className="py-md-3 px-4 p-1 mt-1 mb-4 radius-8 containerStyle ">
        <div className="d-flex" style={{ flexWrap: "wrap" }}>
          {divisionSettingTabs?.map((item, i) => (
            <NavLink
              key={i}
              //   state={{ propId: propertyId }}
              to={item.link}
              style={{ textDecoration: "none" }}
              // onClick={() => {
              //   updateBreadcrumbs([`${item.tab}`]);
              //   handelTag(item.tab);
              // }}
              className=" me-2"
            >
              {item?.tab}
            </NavLink>
          ))}
        </div>
        <div className="whiteContainerStyle mt-2">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default DivisionsSettings;
