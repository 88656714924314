import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";

import CustomToolTip from "../../../../../Components/Tooltip/CustomTooltip";
import ReactSelect from "../../../../../Components/DropDown/ReactSelect";

import GroupButtons from "../../../../../Components/GroupButton/GroupButton";

import { H4, H5 } from "../../../../../styles/Common.style";
import InputField from "../../../../../Components/Textfield/InputField";

import MRTTheme from "../../../../../Components/table/MRTTheme";

import { MaterialReactTable } from "material-react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  SortIcon,
  paginationoptions,
} from "../../../../../Components/table/MRTGlobalProps";

import { appointmentData } from "../../../../overview/StaticData";

import ETButton from "../../../../../Components/Buttons/ETButton";

const CreatingInvitation = ({
  setCreateAppoinmentWizard,
  setOpenNewAppointmentScreen,
  openNewInvitationScreen,
  setOpenNewInvitationScreen,
  setAddExtraCustomer,
  handleNext = () => {},
  handleCancel,
  isPropertyHasRelation,
}) => {
  const [exestingCustomer, setExestingCustomer] = useState(0);
  const handlechange = (id) => {
    setExestingCustomer(id);
  };

  // const handleNext = () => {
  //   setCreateAppoinmentWizard("AttachedDocuments");
  // };
  // const handleCancel = () => {
  //   setOpenNewAppointmentScreen(false);
  // };

  const handleSubmitAttacedDocument = () => {
    handleNext();
  };

  const handleCancelSubmitAttacedDocument = () => {
    // setCreateAppoinmentWizard("CreatingAppointment");
    handleCancel();
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.Event} ${row.Id}`,
        header: "Event",
        enableHiding: false,
        size: 250,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer ">
            {row.original.Event}
          </div>
        ),
      },

      {
        accessorKey: "Time",
        header: "Time",
        size: 180,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            {row.original.Time}
          </div>
        ),
      },

      {
        accessorKey: "Booking Required",
        header: "Booking Required",
        size: 120,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            {row.original.BookingRequired}
          </div>
        ),
      },
      {
        accessorKey: "Avaibility",
        header: "Avaibility",
        size: 200,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2 text-align-left cursor-pointer">
            <div
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor:
                  row.original.Availability === "Available" ? "green" : "red",
              }}
            ></div>
            {row.original.Availability}
          </div>
        ),
      },
      {
        accessorKey: "select",
        header: "",
        size: 60,
        Cell: ({ row }) => (
          <div className="">
            <ETButton
              buttonText={"Select Event"}
              variant={"white"}
              size={"sm"}
            />
          </div>
        ),
      },
    ],
    []
  );
  return (
    <div className="">
      <div className="scrollable-container-y me-1" style={{ height: "500px" }}>
        <div className="p-4">
          <Row className="pb-3">
            <Col lg={7}>
              <p>
                You are now sending invitation to several customers. It’s only
                possible to send an invitation to an existing event.
              </p>{" "}
              <div className="d-flex  gap-2">
                <p>
                  If you need to create a new event, please take the shortcut to
                  Event.
                </p>
                <FontAwesomeIcon
                  icon="fa-regular fa-person-running"
                  size="lg"
                  style={{ color: "#000" }}
                />
              </div>
            </Col>
          </Row>
          <Row className="pb-4">
            <Col>
              <div className="d-flex align-items-center gap-2 pb-2">
                <H4>Customer</H4>
                <CustomToolTip size="sm" text="Customer" />
              </div>
              <p>Selected Customer to this appointment.</p>
              <div></div>
            </Col>

            <Row>
              <Col className=" " lg={5}>
                <div className="position-relative w-100">
                  <div
                    className="bg-white position-absolute px-1 font-medium fs-8 grey-text"
                    style={{ left: "14px", top: "-5px" }}
                  >
                    To recipients
                  </div>
                  <div className="badge-input">
                    <span
                      className="py-1 px-2 me-1 mb-1  radius-4  bgcolor-d-white "
                      style={{
                        border: "0.5px solid #888",
                        width: "fit-content",
                      }}
                    >
                      <FontAwesomeIcon
                        icon="fa-regular fa-circle-xmark"
                        style={{ color: "#888888", cursor: "pointer" }}
                      />
                      <FontAwesomeIcon
                        icon="fa-regular fa-user-pen"
                        className="ps-2"
                        style={{ color: "#000000", cursor: "pointer" }}
                        onClick={() => {
                          setAddExtraCustomer(true);
                        }}
                      />
                      <span className="ps-1">Thomas Høeg Mogensen</span>
                    </span>
                  </div>
                </div>
              </Col>
              <Col lg={1} className="ps-0 d-flex align-items-center  gap-3">
                {" "}
              </Col>
            </Row>
          </Row>

          <Row className="pb-4">
            <Col>
              <div className="d-flex align-items-center gap-2 pb-2">
                <H4>Responsible Agent</H4>
                <CustomToolTip size="sm" text="Responsible Agent" />
              </div>
              <p>
                Select the responsible agent who will handle these customers.
              </p>
              <div></div>
            </Col>
            <Row>
              <Col lg={5} className=" d-flex align-items-center gap-3">
                <ReactSelect placeholder={"Max Wildersans Brentford"} />
              </Col>
            </Row>
          </Row>
        </div>
        <hr className="hr m-0 p-0"></hr>
        <div className="pb-2 px-4 pt-4">
          {/* <Row className="pb-4">
            <Col lg={6}>
              <GroupButtons
                tabs={[
                  {
                    id: 1,
                    tabTitle: "Select an existing event",
                    title: "Select an existing event",
                  },
                  {
                    id: 2,
                    tabTitle: "Create new appointment",
                    title: "Create new appointment",
                  },
                ]}
                currentTab={exestingCustomer}
                handleTabClick={(tab) => handlechange(tab)}
              />
            </Col>
          </Row> */}
        </div>

        {/*--tab switch part --*/}
        <div className="">
          {exestingCustomer === 1 ? (
            <div>
              <div className=" px-4">
                <Row>
                  <Col lg={7}>
                    <p>
                      Fill in the fields below to create a new appointment to
                      the selected customer: It you need to create an event that
                      other customers can join, please create this on the Event
                      page.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex align-items-center gap-2 pb-2">
                      <H4>Event Title</H4>
                      <CustomToolTip size="sm" text="Event Title" />
                    </div>
                    <p>
                      Internal title which will be shown along with the
                      appointment in the system.
                    </p>
                    <div></div>
                  </Col>
                </Row>
                <Row className="">
                  <Col lg={6}>
                    <ReactSelect
                      placeholder={
                        "Exclusive property - Thomas Hoeg Mogensen & Jyte Niel..."
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="pt-4 ">
                    <div>
                      <p>External title which will be shown to the customer.</p>
                    </div>
                  </Col>
                </Row>
                <Row className="pb-4">
                  <Col lg={6}>
                    <ReactSelect
                      placeholder={
                        "Exclusive property - Thomas Hoeg Mogensen & Jyte Niel..."
                      }
                    />
                  </Col>
                </Row>
              </div>
              <hr className="hr m-0 p-0"></hr>
              <div className="pb-2 px-4 pt-4">
                <Row>
                  <Col>
                    <div className="d-flex align-items-center gap-2 pb-2">
                      <H4>Date & Time</H4>
                      <CustomToolTip size="sm" text="Date & Time" />
                    </div>
                    <p>Select date and time for the event</p>
                    <div></div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={2}>
                    <div className="d-flex  gap-2 pb-2">
                      <H5>Date & Time</H5>
                      <CustomToolTip size="sm" text="Date & Time" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={2} className="pe-lg-0">
                    <div>
                      <InputField type={"Date"} />
                    </div>
                  </Col>
                  <Col lg={2} className="pe-lg-0">
                    <div>
                      <ReactSelect placeholder={"Starting Time"} />
                    </div>
                  </Col>
                  <Col lg={2} className="pe-lg-0">
                    <div>
                      <ReactSelect placeholder={"End Time"} />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            <div>
              <Row className="px-4">
                <Col>
                  <p>
                    Below are the upcoming events that have been created on
                    selected property.
                  </p>

                  <MRTTheme
                    colorVariantsForTableRow={["gray"]}
                    colorVariantsForTableHead={["white"]}
                    table={
                      <MaterialReactTable
                        columns={columns}
                        enableTopToolbar={false}
                        enableColumnActions={false}
                        icons={{ ...SortIcon }}
                        // data={unitsData?.filter((data) => data.is_linked_with_screening !== 1) ?? []}
                        data={appointmentData}
                        enableRowActions={false}
                        muiTablePaperProps={{
                          sx: {
                            table: {
                              borderCollapse: "separate",
                              borderSpacing: "0 5px",
                            },
                          },
                        }}
                        muiTableContainerProps={{
                          sx: {
                            padding: "0px !important",
                          },
                        }}
                        muiTablePaginationProps={{
                          ...paginationoptions,
                        }}
                        positionGlobalFilter="right"
                        positionToolbarDropZone="bottom"
                        positionActionsColumn="last"
                      />
                    }
                  />
                </Col>
              </Row>
            </div>
          )}

          {/*--switch table--*/}
        </div>
      </div>
      <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-between footer-section-button-container">
        <ETButton
          variant={"white"}
          buttonText={isPropertyHasRelation ? "Back" : "Close"}
          onClick={handleCancel}
        />
        <ETButton variant={"primary"} buttonText="NEXT" onClick={handleNext} />
      </div>
    </div>
  );
};

export default CreatingInvitation;
