import React, { useEffect, useState } from "react";
import { Col, Row, useAccordionButton } from "react-bootstrap";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select, { components } from "react-select";
import CustomCheckBox from "../../Components/Checkbox/Checkbox";
import ETButton from "../../Components/Buttons/ETButton";
import {
  RadioInput,
  RadioLabel,
  RadioWrapper,
} from "../../Components/RadioButton/ETRadioButton";
import Accordion from "react-bootstrap/Accordion";

const preScreeningoptions = [
  { label: "Information, full name, email, phone, choice list", value: 1 },
];
const inputData = [
  { id: 1, labelName: "Full name" },
  { id: 2, labelName: "Email" },
  { id: 3, labelName: "Phone" },
  { id: 4, labelName: "Choice list" },
];
const PreScreening = () => {
  //------------------- state --------------------------//
  const [selectdTemplates, setSelectedTemplates] = useState(0);
  const [info, setInfo] = useState("");
  const [choiceListChange, setChoiceListChange] = useState("");
  const [choiceListData, setChoiceListData] = useState([]);
  const [inputList, setinputList] = useState(inputData);

  const preScreeningoptionChange = (e, ActionTypes) => {
    if (ActionTypes.action === "clear") {
      setSelectedTemplates("");
    } else {
      setSelectedTemplates(e.value);
    }
  };

  // function handleOnDragEnd(result) {
  //   if (!result.destination) return;

  //   const items = Array.from(inputList);
  //   const [reorderedItem] = items.splice(result.source.index, 1);
  //   items.splice(result.destination.index, 0, reorderedItem);

  //   setinputList(items);
  // }

  return (
    <div>
      <Row>
        <Col xl={8} lg={7}>
          <div className="p-4">
            <div className="event-section-title">Pre-screening</div>
            <Row>
              <Col lg={8}>
                <div className="light-font-text grey-text">
                  If you want to know more about the registrations to the event
                  you can create your own pre-screening here or select from
                  saved templates.
                </div>
              </Col>
              <Col lg={4}>
                <ReactSelect
                  placeholder="pre-screening"
                  //   value={value}
                  clearable={true}
                  isSearchable
                  onChange={(e, ActionTypes) => {
                    preScreeningoptionChange(e, ActionTypes);
                  }}
                  // error={errors.zip}
                  options={preScreeningoptions}
                  // options={zipcodeData?.data?.map((zip) => ({
                  // 	value: zip?.Id,
                  // 	label: `${zip?.Id} ${zip?.Text}`,
                  // }))}
                />
              </Col>
            </Row>

            <div className="pt-3">
              <FieldComponent
                labelName="Information"
                inputValue={info}
                inputOnChange={setInfo}
              />
            </div>
            {selectdTemplates === 1 && (
              <>
                {/* <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="inputList">
                    {(provided) => (
                      <ul
                        className="characters"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {inputList.map(({ id, labelName }, index) => {
                          console.log(id, "didid");
                          return (
                            <Draggable key={id} draggableId={id} index={index}>
                              {(provided) => (
                                <li
                                  className="pt-3"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <FieldComponent
                                    labelName={labelName}
                                    id={id}
                                    inputValue={
                                      labelName === "Choice list" &&
                                      choiceListChange
                                    }
                                    inputOnChange={
                                      labelName === "Choice list" &&
                                      setChoiceListChange
                                    }
                                    setChoiceListData={
                                      labelName === "Choice list" &&
                                      setChoiceListData
                                    }
                                  />
                                </li>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext> */}
                <div className="pt-3">
                  <FieldComponent labelName="Full name" id={1} />
                </div>
                <div className="pt-3">
                  <FieldComponent labelName="Email" id={2} />
                </div>
                <div className="pt-3">
                  <FieldComponent labelName="Phone" id={3} />
                </div>
                <div className="pt-3">
                  <FieldComponent
                    labelName="Choice list"
                    id={4}
                    inputValue={choiceListChange}
                    inputOnChange={setChoiceListChange}
                    setChoiceListData={setChoiceListData}
                  />
                </div>
              </>
            )}
            <div
              className="py-2 d-sm-flex justify-content-between "
              style={{
                zIndex: "1",
                position: "relative",
              }}
            >
              <div className="m-1">
                <ETButton
                  variant={"primary"}
                  buttonText="ADD ELEMENT"
                  icon={true}
                  // onClick={ReturnProperties}
                />
              </div>

              <div className="m-1 d-flex gap-2">
                <ETButton variant={"white"} buttonText="Preview" />
                <ETButton
                  variant={"secondary"}
                  buttonText="SAVE AS TEMPLATE"
                  // onClick={ReturnEditProperties}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col xl={4} lg={5}>
          <div
            className="p-4"
            style={{ backgroundColor: "#D1D1D1", height: "100%" }}
          >
            <div className="event-section-title pb-3">
              Preview of the pre-screening
            </div>
            <div className="bg-white radius-8 p-4 light-font-text">
              <div className="light-font-text">
                {info
                  ? info
                  : "Before you can sign up for the event we need to know a bit more about you. Please fill in below."}
              </div>

              {selectdTemplates === 1 && (
                <>
                  <div className="pt-3">
                    <div className="form-input ">
                      <input
                        type="text"
                        placeholder="Full Name"
                        id={`field-1`}
                        // value={inputValue}
                        // onChange={(e) => inputOnChange(e.target.value)}
                      />
                      <label htmlFor={`field-1`}>Full Name</label>
                    </div>
                  </div>

                  <div className="pt-3">
                    <div className="form-input ">
                      <input
                        type="email"
                        placeholder="Email"
                        id={`field-2`}
                        // value={inputValue}
                        // onChange={(e) => inputOnChange(e.target.value)}
                      />
                      <label htmlFor={`field-2`}>Email</label>
                    </div>
                  </div>
                  <div className="pt-3">
                    <div className="form-input ">
                      <input
                        type="number"
                        placeholder="Phone"
                        id={`field-3`}
                        // value={inputValue}
                        // onChange={(e) => inputOnChange(e.target.value)}
                      />
                      <label htmlFor={`field-3`}>Phone</label>
                    </div>
                  </div>
                  <div className="py-3">
                    {choiceListChange !== "" && (
                      <div>
                        <div>{choiceListChange}</div>
                        <div>
                          {choiceListData?.map((item) => (
                            <>
                              <RadioWrapper>
                                <RadioInput
                                  type="radio"
                                  // onChange={onChange}
                                  name={"radio"}
                                  // value={value}
                                  id={"2"}
                                />
                                <RadioLabel>{item.inputName}</RadioLabel>
                              </RadioWrapper>
                            </>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PreScreening;

//------- Component for Pre-screening -----------
const options = [
  { label: "All", value: 0 },
  { label: "Information", value: 1 },
  { label: "Full name", value: 2 },
  { label: "Email", value: 3 },
  { label: "Phone", value: 4 },
  { label: "Choice list", value: 5 },
];
const FieldComponent = ({
  type,
  id,
  labelName,
  inputValue,
  inputOnChange,
  setChoiceListData,
  isRequired,
}) => {
  // const [focused, setFocused] = useState(false);
  // const [selectedOption, setSelectedOption] = useState("");
  const [count, setCount] = useState(1);
  const [choiceListOption, setChoiceListOption] = useState([{ inputName: "" }]);

  // const onselectChange = (e) => {
  //   setSelectedOption(e.label);
  // };
  const handleChangeInput = (index, event) => {
    const values = [...choiceListOption];
    values[index][event.target.name] = event.target.value;
    setChoiceListOption(values);
  };
  const handleAdd = () => {
    setCount(count + 1);
    let newArray = [...choiceListOption, { inputName: "" }];
    setChoiceListOption(newArray);
    setChoiceListData(newArray);
  };
  const handleRenove = (id) => {
    let newArray = choiceListOption.filter((item, index) => item !== id);
    setChoiceListOption(newArray);
    setChoiceListData(newArray);
  };

  //bootstrap Accordian
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log("totally custom!")
    );

    return (
      <span
        type="button"
        className="d-flex align-items-center gap-2"
        onClick={decoratedOnClick}
      >
        {children}
      </span>
    );
  }

  // console.log(choiceListOption, "choiceListOption");
  // console.log(count, "ccount");
  return (
    <Accordion className="field-component px-3 py-2" defaultActiveKey="0">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-2">
          <div>
            <FontAwesomeIcon
              icon="fa-solid fa-grip-dots-vertical"
              style={{ color: "#000000" }}
              size="lg"
            />
          </div>
          <CustomToggle eventKey="0">
            <FontAwesomeIcon
              icon="fa-solid fa-chevron-down"
              style={{ color: "#000000" }}
            />{" "}
            {labelName}
          </CustomToggle>
          {/* <div>
            <Select
              options={options}
              styles={style}
              defaultValue={{ label: `${labelName}`, value: 0 }}
              isDisabled={true}
              isSearchable={false}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              isFocused={focused}
              components={{
                DropdownIndicator,
              }}
              onChange={(e) => onselectChange(e)}
            />
          </div> */}
        </div>

        <div className="d-flex justify-content-between align-items-center gap-3">
          {labelName !== "Information" && (
            <CustomCheckBox
              // checked={data?.is_default === 1 ? true : false}
              // onChange={(e) => {
              //   handleMakeDefault(data, index);
              // }}

              label={"Required"}
            />
          )}

          <div className="d-flex ">
            <button
              // onClick={() => {
              //   setDeleteEditor(true);
              //   setDeleteId(data?.lang_id);
              // }}
              // disabled={languageData?.length === 1 && true}

              className="icon-button"
            >
              <FontAwesomeIcon
                icon="fa-regular fa-trash"
                size="sm"
                style={{
                  color: "#000000",
                  // opacity: languageData?.length === 1 ? "50%" : "inherit",
                }}
              />
            </button>
          </div>
        </div>
      </div>
      {/* type info of fields */}
      <Accordion.Collapse className="py-2" eventKey="0">
        {labelName === "Information" ? (
          <div className="form-float openhouse-des">
            <textarea
              name=""
              class="inputText"
              cols="20"
              rows="4"
              placeholder=" "
              value={inputValue}
              onChange={(e) => inputOnChange(e.target.value)}

              //   onChange={(e) => {
              //     setAltText(e.target.value);
              //   }}
            ></textarea>
          </div>
        ) : labelName === "Choice list" ? (
          <>
            <div className="form-input ">
              <input
                type={type}
                placeholder="Label"
                id={`field-${id}`}
                value={inputValue}
                onChange={(e) => inputOnChange(e.target.value)}
              />
              <label htmlFor={`field-${id}`}>Label</label>
            </div>
            {choiceListOption?.map((field, index) => (
              <div
                className="expand-list ms-3 pt-4  d-flex align-items-center"
                key={index}
              >
                <div className="form-input ">
                  <input
                    name="inputName"
                    type={type}
                    placeholder={`Choice #${index + 1}`}
                    id={`fieldChoice-${index}`}
                    value={field.inputName}
                    onChange={(e) => {
                      handleChangeInput(index, e);
                    }}
                  />
                  <label htmlFor={`fieldChoice-${index}`}>{`Choice # ${
                    index + 1
                  }`}</label>
                </div>
                <div className="px-2" onClick={() => handleRenove(field)}>
                  <FontAwesomeIcon
                    icon="fa-regular fa-xmark"
                    style={{ color: "#d1d1d1" }}
                  />
                </div>
              </div>
            ))}

            <div className="px-4 ms-2 pt-3 pb-2">
              <ETButton
                variant={"secondary"}
                buttonText={"ADD ANSWER"}
                icon={true}
                onClick={handleAdd}
              />
            </div>
          </>
        ) : (
          <div className="form-input ">
            <input
              type={type}
              placeholder="Label"
              id={`field-${id}`}
              value={labelName}
            />
            <label htmlFor={`field-${id}`}>Label</label>
          </div>
        )}
      </Accordion.Collapse>
    </Accordion>
  );
};

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    flexDirection: "row-reverse",
    // This line disable the blue border
    boxShadow: "none",
    fontSize: "13px",
    fontFamily: "Cerebri sans-light",
    backgroundColor: "transperant",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    fontFamily: "Cerebri sans-medium !important",
    borderRadius: 8,
    marginLeft: "0px",
    fontSize: 14,
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#000000", // Custom colour
    transition: "all .2s ease",
    transform: state.isFocused ? "rotate(180deg)" : null,
    padding: state.isFocused ? "10px 10px 10px 0px" : "10px 0px 10px 10px",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: "#fff",
    zIndex: 1,
    color: "#000",
    fontSize: "13px",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 3,
    width: 150,
  }),
  indicatorSeparator: () => {},
};
const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon
          icon="fa-solid fa-chevron-down"
          style={{ color: "#000000" }}
        />
      </components.DropdownIndicator>
    )
  );
};
