import React, { useEffect, useState } from "react";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import GroupText from "../../../../Components/GroupText/GroupText";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import SuperEditor from "@ckeditor/ckeditor5-build-classic";
import GroupButtons from "../../../../Components/GroupButton/GroupButton";
import { useAppState } from "../AllState";
import { Controller, useForm } from "react-hook-form";
import { H4 } from "../../../../styles/Common.style";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";

const SpecialCondition = ({
  submitRef,
  handleNext,
  prevRef,
  activeTenancyForm,
  getConfigurationDetailsData,
  handleEditConfig,
  previewRef,
}) => {
  const [state, setState] = useAppState();
  const {
    handleSubmit,
    register,
    watch,
    getValues,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ defaultValues: state[activeTenancyForm], mode: "onSubmit" });

  const saveData = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 11, false);
    // handleNext(1);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };

  const preview = (data) => {
    handleEditConfig(data, getConfigurationDetailsData, 11, false);
    // handleNext(1);
    // setState({ ...state, ...data });
    setState({ ...state, [activeTenancyForm]: { ...data } });
  };
  // saving all field into context state
  const prevSubmit = () => {
    // setState({ ...state, ...getValues() });
    setState({ ...state, [activeTenancyForm]: { ...getValues() } });
  };
  useEffect(() => {
    setValue(
      "rent_determination",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "rent_determination"
      )[0]?.actual_value == 2
        ? 1
        : 0
    );
    setValue(
      "rent_reason",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "rent_reason"
      )[0]?.actual_value
    );
    setValue(
      "rent_regulation",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "rent_regulation"
      )[0]?.actual_value == 2
        ? 1
        : 0
    );
    setValue(
      "rent_adjust_date",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "rent_adjust_date"
      )[0]?.actual_value
    );
    setValue(
      "price_index",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "price_index"
      )[0]?.actual_value
    );
    setValue(
      "month",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "month"
      )[0]?.actual_value
    );
    setValue(
      "adjust_first_date",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "adjust_first_date"
      )[0]?.actual_value
    );
    setValue(
      "other_derogations_1",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "other_derogations_1"
      )[0]?.actual_value
    );
    setValue(
      "other_derogations_2",
      getConfigurationDetailsData?.[0]?.configuration_values?.filter(
        (data) => data.column_name == "other_derogations_2"
      )[0]?.actual_value
    );
  }, [getConfigurationDetailsData]);

  console.log(watch("rent_determination"), "watchrent_determination");
  return (
    <>
      <Form className="main-form light-font-text">
        {/* -----------Top Header----------- */}
        <div style={{ borderBottom: "1px solid #F0F0F0" }}>
          <div className="px-4 py-3">
            <H4 className="actors-section-title">§11 Special conditions </H4>
            <div style={{ color: "#888888" }}>
              Lorem ipsum dolor sit lorem ipsum dolor sit
            </div>
          </div>
        </div>
        <div
          className="px-4 pt-4 pb-3"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div className="d-flex">
            <span className="me-2 form-label-title">Deviations </span>{" "}
            <CustomToolTip height="13px" text="Deviations" />
          </div>
          <p className="pe-4 ">
            Here, agreed deviations from and additions to the general rules of
            the tenancy legislation and sections 1-10 of the standard contract
            are listed. Such deviations may result in the tenant obtaining fewer
            rights or being subject to greater obligations than under the
            general provisions of the tenancy legislation.
            <br />
            <br /> Terms which in their entirety already follow from the general
            terms and conditions of the tenancy legislation or from house rules
            must not be stated here. Other information about the rented property
            shall be stated in § 10 of the contract.
          </p>
        </div>
        <div
          className="px-4 py-4"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div className="d-flex">
            <span className="me-2 form-label-title">Please note </span>{" "}
            <CustomToolTip height="13px" text="Please note" />
          </div>
          <p className="me-4" style={{ lineHeight: "18px" }}>
            § 11 may, inter alia, state whether special conditions apply to the
            rent determination, which must be stated in the lease, including,
            for example, in relation to return (§ 11(4) of the Rent Act),
            adjustment according to net price index (§ 11(5), § 53(2) or § 54(3)
            of the Rent Act), private urban renewal and housing improvement (§
            12 of the Rent Act) and free rent determination (§ 54 of the Rent
            Act).
          </p>
          <div className="form-label-title">
            The following constitute derogations from the basic tenancy law:
          </div>
        </div>
        <div
          className="px-4 py-4"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div className="d-flex">
            <span className="me-2 form-label-title">Rent determination </span>{" "}
            <CustomToolTip height="13px" text="Rent determination" />
          </div>
          <p>Is the tenancy covered by the rules on free rent?</p>
          <div className="w-25">
            <Controller
              control={control}
              {...register("rent_determination")}
              render={({ field: { onChange, value } }) => {
                return (
                  <GroupButtons
                    tabs={[
                      {
                        id: 1,
                        tabTitle: "Yes",
                        title: "Yes",
                      },
                      {
                        id: 2,
                        tabTitle: "No",
                        title: "No",
                      },
                    ]}
                    currentTab={value}
                    handleTabClick={(tab) => {
                      setValue("rent_determination", tab);
                      clearErrors("rent_reason");
                    }}
                  />
                );
              }}
            />
          </div>

          <div className="pt-2 pb-3">
            If yes, what is the reason, cf. section 54 of the Rent Act (for
            further details, see the guidelines):
          </div>
          <div>
            <Controller
              name="rent_reason"
              control={control}
              defaultValue=""
              rules={{
                required: watch("rent_determination") == 0 ? true : false,
              }}
              render={({ field }) => (
                <CKEditor
                  onReady={(editor) => {
                    // editor.focus();
                    editor.editing.view.focus();
                    // editor.setIdToScroll(data?.Id);
                  }}
                  disabled={watch("rent_determination") == 0 ? false : true}
                  data={field.value}
                  // data={getValues("rent_reason") || ""}
                  onChange={(event, editor) => {
                    const editorData = editor.getData();
                    field.onChange(editorData);
                    setValue("rent_reason", editorData);
                    clearErrors("rent_reason");
                  }}
                  focusedElement={true}
                  editor={SuperEditor}
                />
              )}
            />
            {errors?.rent_reason && <p className="error-msg">{REQUIRED_ERR}</p>}
          </div>
        </div>
        <div
          className="px-4 py-4"
          style={{ borderBottom: "1px solid #F0F0F0" }}
        >
          <div className="d-flex">
            <span className="me-2 form-label-title">Rent regulation </span>{" "}
            <CustomToolTip height="13px" text="Rent regulation" />
          </div>
          <div>
            The rent is adjusted once a year according to the development of
            Statistics Denmark's net price index.
          </div>
          <div>
            {" "}
            Rent increases can only be implemented by the landlord's written
            notice to the tenant.
          </div>
          <div className="w-25 pt-3">
            <Controller
              control={control}
              {...register("rent_regulation")}
              render={({ field: { onChange, value } }) => {
                return (
                  <GroupButtons
                    tabs={[
                      {
                        id: 1,
                        tabTitle: "Yes",
                        title: "Yes",
                      },
                      {
                        id: 2,
                        tabTitle: "No",
                        title: "No",
                      },
                    ]}
                    currentTab={value}
                    handleTabClick={(tab) => {
                      setValue("rent_regulation", tab);
                      setValue("rent_adjust_date", "");
                      setValue("price_index", "");
                      setValue("month", "");
                      setValue("adjust_first_date", "");
                      clearErrors("rent_adjust_date");
                      clearErrors("price_index");
                      clearErrors("month");
                      clearErrors("adjust_first_date");
                    }}
                  />
                );
              }}
            />
          </div>
          <div className="pt-3 pb-2" style={{ lineHeight: "16px" }}>
            <div>
              Every{" "}
              <Controller
                name="rent_adjust_date"
                control={control}
                rules={{
                  required: watch("rent_regulation") == 0 ? true : false,
                }}
                render={({ field: { onChange, value } }) => (
                  <input
                    type="date"
                    value={value}
                    disabled={watch("rent_regulation") == 0 ? false : true}
                    // required={watch("rent_adjust_date") == 0 ? true : false}
                    className="normalTextstyle mt-2 mx-1"
                    // {...register("rent_adjust_date")}
                    style={{ width: "100px" }}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("rent_adjust_date");
                    }}
                  />
                )}
              />
              {errors?.rent_adjust_date && (
                <p className="error-msg">{REQUIRED_ERR}</p>
              )}
              the applicable rent is adjusted by the increase in the net price
              index from
              <Controller
                name="price_index"
                control={control}
                rules={{
                  required: watch("rent_regulation") == 0 ? true : false,
                }}
                render={({ field: { onChange, value } }) => (
                  <input
                    value={value}
                    disabled={watch("rent_regulation") == 0 ? false : true}
                    className="normalTextstyle mt-2 mb-2 mx-2"
                    // {...register("price_index")}
                    style={{ width: "90px" }}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("price_index");
                    }}
                  />
                )}
              />
              {errors?.price_index && (
                <p className="error-msg">{REQUIRED_ERR}</p>
              )}
              month of the previous year to the
              <Controller
                name="month"
                control={control}
                rules={{
                  required: watch("rent_regulation") == 0 ? true : false,
                }}
                render={({ field: { onChange, value } }) => (
                  <input
                    className="normalTextstyle mt-2 mb-2 mx-2"
                    // {...register("month")}
                    value={value}
                    disabled={watch("rent_regulation") == 0 ? false : true}
                    style={{ width: "86px" }}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("month");
                    }}
                  />
                )}
              />
              {errors?.month && <p className="error-msg">{REQUIRED_ERR}</p>}
              month preceding the adjustment date.
            </div>
            <div>
              The rent is first adjusted on
              <Controller
                name="adjust_first_date"
                control={control}
                rules={{
                  required: watch("rent_regulation") == 0 ? true : false,
                }}
                render={({ field: { onChange, value } }) => (
                  <input
                    type="date"
                    value={value}
                    className="normalTextstyle mt-2 mx-2"
                    disabled={watch("rent_regulation") == 0 ? false : true}
                    // {...register("adjust_first_date")}
                    style={{ width: "100px" }}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("adjust_first_date");
                    }}
                  />
                )}
              />
              {errors?.adjust_first_date && (
                <p className="error-msg">{REQUIRED_ERR}</p>
              )}
            </div>
          </div>
        </div>
        <div className="px-4 pt-4 pb-4">
          <div className="d-flex pb-3">
            <span className="me-2 form-label-title ">Other derogations </span>{" "}
            <CustomToolTip height="13px" text="Other derogations" />
          </div>
          <div>
            <CKEditor
              onReady={(editor) => {
                // editor.focus();
                editor.editing.view.focus();

                // editor.setIdToScroll(data?.Id);
              }}
              data={getValues("other_derogations_1") || ""}
              onChange={(event, editor) => {
                const editorData = editor.getData();
                // handleChange(index, data, editorData);
                setValue("other_derogations_1", editorData);
              }}
              focusedElement={true}
              editor={SuperEditor}

              // data={data?.text_value}
              // onChange={(event, editor) => {
              //   const editorData = editor.getData();
              //   handleChange(index, data, editorData);
              // }}
            />
          </div>
        </div>
        <button
          type="submit"
          onClick={handleSubmit(saveData)}
          ref={submitRef}
          style={{ display: "none" }}
        />
        <button
          type="submit"
          onClick={handleSubmit(preview)}
          ref={previewRef}
          style={{ display: "none" }}
        />
      </Form>
    </>
  );
};

export default SpecialCondition;
