import React, { useState, useEffect } from "react";

import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";

import { GreenSpan, H1, H2 } from "../../../styles/Common.style";

import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../../Components/Tabs/ReactTabs.style";

import { useOrganizationSettingsBreadcrumbsTabs } from "../../../hooks/stores/useOrganizationStore";

import UserDetails from "./UserDetails";
import UserRoles from "./UserRoles";
import Notifications from "./Notifications";
import { Container } from "react-bootstrap";
import { useBreadcrumb } from "../../../Components/BreadcrumbContext ";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MyProfile = ({ Breadcrumbs }) => {
  const [selectedTab, setSelectedTab] = useState("User detatils");

  const { updateBreadcrumbs } = useBreadcrumb();

  useEffect(() => {
    updateBreadcrumbs([selectedTab]);
  }, [selectedTab]);

  return (
    <div>
      <Container fluid className="">
        <div className="py-2">
          <div className="pt-4">
            <div className="px-2 pt-3 d-flex">
              <div className="d-flex align-items-end  gap-2">
                <H1>My Profile</H1>
              </div>
            </div>
          </div>
        </div>

        {/* Breadcrumbs */}
        <div
          style={{
            fontFamily: " Cerebri sans-light",
            fontSize: "14px",
            cursor: "pointer",
          }}
        >
          <span className="me-2 fs-16">Dashboard</span>
          <span className="me-2">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <GreenSpan className="me-2 fs-16 breadcrumbs">
            {Breadcrumbs}
          </GreenSpan>
        </div>

        <div className="p-lg-4 p-3 mb-4 containerStyle mt-2">
          {/*All tabs section  */}
          <div
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
              border: "1px solid #F4F5F5",
              fontSize: "14px",
              minHeight: "95vh",
              position: "relative",
            }}
          >
            <div className="px-3 pb-3 border-bottom">
              <div className="px-2 pt-3 d-flex">
                <div className="d-flex align-items-center  gap-2">
                  <H2>My Profile</H2>
                  <CustomToolTip size="sm" text="My Profile" />
                </div>
              </div>
            </div>

            <div className=" ">
              <div>
                <Tabs className="d-flex flex-column ">
                  <div
                    className="d-flex px-4 py-3 mb-3 justify-content-between align-items-center"
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      borderBottom: "1px solid #F0F0F0",
                    }}
                  >
                    <TabList
                      className=" d-flex light ps-0 light"
                      style={{ gap: "7px" }}
                    >
                      <Tab
                        onClick={() => {
                          setSelectedTab("User Details");
                        }}
                      >
                        User details
                      </Tab>
                      <Tab
                        onClick={() => {
                          setSelectedTab("User Roles");
                        }}
                      >
                        User roles
                      </Tab>
                      {/* <Tab
                        onClick={() => {
                          setSelectedTab("Notifications");
                        }}
                      >
                        Notifications
                      </Tab> */}
                    </TabList>
                  </div>
                  <div>
                    <TabPanel>
                      <UserDetails />
                    </TabPanel>
                    <TabPanel>
                      <UserRoles />
                    </TabPanel>
                    <TabPanel>
                      <Notifications />
                    </TabPanel>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MyProfile;

// Created by ------> Yash
