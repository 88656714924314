import { Endpoint, Site } from "./API/Endpoints";
import { Delete, Get, Post, Put } from "./API/Method";

// Get Tags by propertyId
export const GetTagsByPropertyId = (property_id) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_Tags_By_PropertyId, null, property_id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Delete Tags
export const DeleteTags = (tagId) => {
  return new Promise((resolve, reject) => {
    Delete(Site.ET, Endpoint.ET.Delete_Tags, null, tagId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
// Put  Tags SetActive
export const PutTagSetActive = (body) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Put_Tags_SetActive, null, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Add Tags
export const PostTags = async (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Add_tags, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Get Tag by tag Id
export const GetTagByTagId = (tagId) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_Tag_By_TagId, null, tagId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Get Source by tag Id
export const GetSourceByTagId = (tagId) => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Get_Source_By_TagId, null, tagId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Put  Tags UpdateTag
export const PutTagUpdateTag = (data) => {
  return new Promise((resolve, reject) => {
    Put(Site.ET, Endpoint.ET.Put_Tags_UpdateTag, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

export const GetTagByUnitId = (propertyId, unitId) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Get_Tag_ByUnitId,
      null,
      propertyId + "/unit/" + unitId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
