import React, { useState } from "react";
import CustomToolTip from "../../../Components/Tooltip/CustomTooltip";
import { GreenSpan, H2 } from "../../../styles/Common.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "../../../Components/Tabs/ReactTabs.style";
import Participants from "./Participants";
import CustomPopup from "../../../Components/CustomPopup";
import ConfirmAndAddParticipant from "./ConfirmAndAddParticipant";
import Registration from "./Registration";
import ETButton from "../../../Components/Buttons/ETButton";
import { Link } from "react-router-dom";
import { H1 } from "../../../styles/Common.style";

const FollowUpPropertyOpenHouse = ({
  setShowOpenHousePropertyFollowUp,
  propertyOverview,
}) => {
  return (
    <div>
      <div className="pt-3 ">
        <H1>Open House Blomsterbjerget</H1>
        <div
          className="light-font-text"
          style={{
            cursor: "pointer",
          }}
        >
          <Link
            to="/properties"
            style={{ textDecoration: "none", color: "black" }}
          >
            <span className="me-3">Property</span>
          </Link>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <Link
            to="/property-units/overview"
            state={{
              Tab: "Overview",
            }}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span className="me-3">
              {propertyOverview?.[0]?.propertyName
                ? propertyOverview?.[0]?.propertyName
                : "propertyName"}
            </span>
          </Link>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <span className="me-3">Events</span>

          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <GreenSpan className="me-3 ">Open House Blomsterbjerget</GreenSpan>
        </div>
      </div>{" "}
      <div className="open-house-container p-4 mt-4 mb-4 radius-8">
        <div className="bg-white radius-8">
          <div className="d-flex justify-content-between px-4 py-3">
            <H2 className="d-flex flex-column ">
              <div className="d-flex">
                Open House Blomsterbjerget
                <span className="ms-2">
                  <CustomToolTip
                    text="Open House Blomsterbjerget"
                    height={12}
                  />
                </span>
              </div>
            </H2>
          </div>
          <hr className="my-0"></hr>

          <Tabs>
            <div className="px-4 py-3">
              <TabList className="light m-0 ps-0">
                <Tab> Participants</Tab>
                <Tab>Registration</Tab>
              </TabList>
            </div>
            <hr className="my-0"></hr>
            <div className="py-3">
              <TabPanel>
                <Participants />
              </TabPanel>

              <TabPanel>
                <Registration />
              </TabPanel>
            </div>
          </Tabs>

          <div className="mt-5 py-2 px-4 d-flex flex-md-row flex-column  footer-section-button-container">
            {" "}
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              // type={"button"}
              onClick={() => {
                setShowOpenHousePropertyFollowUp(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FollowUpPropertyOpenHouse;
