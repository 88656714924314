import React from "react";
import { Col, Row } from "react-bootstrap";
import SigningPackageCard from "../SigningPackageCard";
import ETButton from "../../../../../Components/Buttons/ETButton";

const ParkingSigningPackage = ({
  signingPackageData,
  setSelectedSigningPackageId,
  selectedSigningPackageId,
}) => {
  return (
    <div>
      <div
        style={{ display: signingPackageData?.length <= 0 && "none" }}
        className="ps-4 pt-2"
      >
        Parking signing package:{" "}
      </div>
      <div
        className="px-4"
        style={{
          overflowY: "scroll",
          overflowX: "hidden",
          height: "570px",
        }}
      >
        <Row className="py-3">
          {signingPackageData?.length > 0 ? (
            signingPackageData?.map((item, index) => (
              <Col key={index} className="my-2" lg={6} xl={4}>
                <SigningPackageCard
                  data={item}
                  index={index}
                  setSelectedSigningPackageId={setSelectedSigningPackageId}
                  selectedSigningPackageId={selectedSigningPackageId}
                />
              </Col>
            ))
          ) : (
            <div
              className="d-flex justify-content-center w-100 fs-12 mt-1"
              style={{ color: "#888888" }}
            >
              You don't have any parking signing package created yet or It may
              be possible that the parking signing package is not published
            </div>
          )}
        </Row>
      </div>
      {/* <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-end  justify-content-end footer-section-button-container gap-3">
        <ETButton
          //   disabled={
          //     selectedSigningPackageId === "" || PostCreateSigningOrderIsLoading
          //   }
          variant={"primary"}
          buttonText="CREATE SIGNING ORDER"
          //   onClick={() => handleCreateSigningOrder()}
        />
      </div> */}
    </div>
  );
};

export default ParkingSigningPackage;
