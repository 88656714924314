import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import CustomReactSelect from "./CustomReactSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetGeographyCountry } from "../../hooks/queries/useGeographyQueries";
import { useEffect } from "react";

export const SelectPhoneNumber = ({
  getPhoneId,
  getPhoneNumber,
  errors,
  control,
  setValue,
  getValues = () => {},
  placeholder,
  token,
  isDisabled,
  isPhoneIconRequired,
  onChangeFun,
  isRemoveOpacity,
}) => {
  // console.log(onChangeFun, 'rendewrr')
  const { data: GetGeographyCountryData } = useGetGeographyCountry(token);

  // useEffect
  useEffect(() => {
    if (Object.keys(GetGeographyCountryData ?? {}).length) {
      const filterValue = GetGeographyCountryData?.data?.filter(
        (cd) => cd?.DataValue2 === getValues(getPhoneId)
      );

      if (filterValue.length === 0) setValue(getPhoneId, "");
    }
  }, [getPhoneId, getValues(getPhoneId), GetGeographyCountryData]);

  return (
    <div style={{ opacity: isRemoveOpacity ? "0.5" : "1.0" }}>
      <Row
        className={`country-phone-number-container mx-0 px-0 ${
          (Object.keys(errors?.[getPhoneId] ?? {}).length ||
            Object.keys(errors?.[getPhoneNumber] ?? {}).length) &&
          "error"
        }`}
      >
        <Col xs={5} xxl={4} className="px-0">
          <Controller
            defaultValue=""
            control={control}
            name={getPhoneId}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => {
              const filterValue = GetGeographyCountryData?.data?.filter(
                (cd) => cd?.DataValue2 === value
              );
              const selectedVal = filterValue?.length
                ? {
                    value: filterValue[0]?.Id,
                    label: (
                      <div
                        className="d-flex align-items-center cursor-pointer"
                        style={{
                          fontFamily: "Cerebri sans",
                        }}
                      >
                        <div className="radius-4 me-2">
                          <img
                            src={filterValue[0]?.DataValue1}
                            style={{
                              width: "20px",
                            }}
                            alt="country flag"
                          />
                        </div>
                        <div>
                          <div
                            className="fs-14 light-font-text d-flex gap-1"
                            style={{
                              color: "rgba(0, 0, 0, 1)",
                              opacity: "0.5",
                            }}
                          >
                            <span>{filterValue[0]?.Id}</span>
                            <span>{filterValue[0]?.DataValue2}</span>
                          </div>
                        </div>
                      </div>
                    ),
                  }
                : "";

              return (
                <CustomReactSelect
                  setValue={setValue}
                  name={getPhoneId}
                  bgColor={"#f9fafa"}
                  border={"none"}
                  description={"Search country code"}
                  placeholder={placeholder ? placeholder : ""}
                  value={selectedVal}
                  disabled={isDisabled}
                  handleInputChange={onChangeFun}
                  options={GetGeographyCountryData?.data?.map((item, i) => ({
                    value: item?.DataValue2,
                    label: (
                      <div
                        // key={i}
                        className="d-flex align-items-center"
                        style={{
                          fontFamily: "Cerebri sans",
                        }}
                      >
                        <div className="radius-4 me-2">
                          <img
                            src={item?.DataValue1}
                            style={{
                              width: "20px",
                              marginRight: "10px",
                            }}
                            alt="country flag"
                          />
                        </div>
                        <div className="fs-14 light-font-text d-flex gap-1 w-100 nowrap">
                          <span>{item?.Text}</span>
                          {/* <span>({item?.Text})</span> */}
                          <span className="grey-text">{item?.DataValue2}</span>
                        </div>
                      </div>
                    ),
                    countryName: item?.Text,
                    phoneId: item?.DataValue2,
                  }))}
                  dropDownIndicator={false}
                  isSearchable={false}
                />
              );
            }}
          />
        </Col>
        <Col xs={7} xxl={8} className="px-0">
          <Controller
            defaultValue=""
            control={control}
            name={getPhoneNumber}
            rules={{
              required: true,
            }}
            render={({ field, fieldState }) => {
              return (
                <div className="h-100" style={{ position: "relative" }}>
                  <div
                    className={`phone-number h-100 ${
                      fieldState.error?.[field.name] && "error"
                    }`}
                  >
                    <input
                      className="w-100 h-100 border-0 rounded-end p-2 font-light fs-14"
                      {...field}
                      type="text"
                      placeholder={``}
                      id={"phone number"}
                      disabled={isDisabled}
                      style={{ cursor: isDisabled ? "not-allowed" : "unset" }}
                      onKeyDown={(e) => {
                        const regex = /^[0-9-]+$/;
                        // Allow Backspace key without regex check
                        if (e.key === "Backspace") {
                          return;
                        }
                        const key = e.key || String.fromCharCode(e.code);

                        if (!regex.test(key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                        if (onChangeFun) onChangeFun();
                        // handleFieldCondition(phoneData[0], getValues)
                      }}
                    />

                    {/* <label htmlFor={ phoneData[0].placeholder}>{ phoneData[0].placeholder}</label> */}
                  </div>

                  {isPhoneIconRequired && (
                    <FontAwesomeIcon
                      icon="fa-regular fa-phone"
                      style={{
                        color: "#000000",
                        position: "absolute",
                        bottom: "30%",
                        right: "7%",
                      }}
                    />
                  )}
                </div>
              );
            }}
          />
        </Col>
      </Row>
      {(errors?.[getPhoneId] || errors?.[getPhoneNumber]) && (
        <p className="error-msg text-start" style={{ color: "red" }}>
          <div className=" d-flex align-items-center gap-1">
            <FontAwesomeIcon
              icon="fa-solid fa-circle-exclamation "
              size="sm"
              style={{ color: "#e33716" }}
            />{" "}
            This field is required.
          </div>
        </p>
      )}
    </div>
  );
};
