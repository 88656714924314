import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { H5 } from "../../styles/Common.style";

//========================Error alert========================//

export const ErrorAlert = ({ errorAlertMessage }) => {
  return (
    <div className="d-flex gap-4 py-2 ps-2">
      <div className="d-flex align-items-center">
        {" "}
        <FontAwesomeIcon
          icon="fa-solid fa-circle-exclamation"
          style={{ color: "#aa1518" }}
          size="2xl"
          beat
        />
      </div>
      <div>
        <H5 className="mb-3">Oh no, something went wrong!</H5>
        {errorAlertMessage ? (
          <div className="light-font-text mb-3">{errorAlertMessage}</div>
        ) : (
          <>
            <div className="light-font-text mb-3">
              For an unknown reason, it wasn’t possible to save the requested
              action. Please try again.
            </div>
            <div className="light-font-text">
              If the problem happens again, please contact our support team.
            </div>
          </>
        )}
      </div>
    </div>
  );
};

//========================Loading alert========================//

export const LoadingAlert = ({ loadingAlertMessage, countMessage }) => {
  return (
    <div className="d-flex gap-4 py-2 ps-2">
      <div className="d-flex align-items-center">
        {" "}
        <FontAwesomeIcon
          icon={"fa-solid fa-spinner"}
          spinPulse
          size="2xl"
          style={{ color: "#349032" }}
        />
      </div>
      <div>
        <H5 className="mb-3">We're working on it...</H5>
        <div className="light-font-text mb-3">
          {loadingAlertMessage
            ? loadingAlertMessage
            : `  The system is currently executing the requested action.`}
        </div>

        {countMessage && <div className="light-font-text">{countMessage}</div>}

        {/* <div className="light-font-text">
          Uploading <span className="header-semibold">1</span> out of{" "}
          <span className="header-semibold">15 files </span>
          to the system
        </div> */}
      </div>
    </div>
  );
};

//========================Success alert========================//

export const SucessAlert = ({ successMessage }) => {
  const [stopAnimation, setStopAnimation] = useState(false);

  const onAnimationIterationHandler = () => {
    setStopAnimation(true);
  };
  return (
    <div className="d-flex gap-4 py-2 ps-2">
      <div className="d-flex align-items-center">
        {" "}
        <FontAwesomeIcon
          icon="fa-solid fa-circle-check"
          bounce={!stopAnimation}
          size="2xl"
          onAnimationIteration={onAnimationIterationHandler}
          style={{ color: "#349032" }}
        />
      </div>
      <div>
        <H5 className="mb-3">Yay, we’re done!</H5>
        <div className="light-font-text mb-3">
          {successMessage ? (
            successMessage
          ) : (
            <>
              We have now successfully finished the requested action and <br />
              you’re now ready to continue.
            </>
          )}
        </div>
      </div>
    </div>
  );
};

//========================Delay alert========================//

export const ExtraLoadingAlert = ({ extraLoadingMessage }) => {
  return (
    <div className="d-flex gap-4 py-2 ps-2">
      <div className="d-flex align-items-center pe-4">
        <div
          style={{
            position: "relative",
          }}
        >
          <div style={{ position: "absolute", top: "-18px" }}>
            <FontAwesomeIcon
              icon="fa-solid fa-hourglass-half"
              spin
              size="2xl"
              style={{ color: "#ffdf00" }}
            />
          </div>
          <div
            style={{
              background: "#Ffffff",
              borderRadius: "100px",
              position: "absolute",
              left: "10px",
              top: "-5px",
              padding: "0px 5px 0px 5px",
            }}
          >
            <FontAwesomeIcon
              icon="fa-solid fa-clock-three"
              size="md"
              style={{ color: "#ffdf00" }}
            />
          </div>
        </div>
      </div>
      <div>
        <H5 className="mb-3">Hang on, we're almost done!</H5>
        <div className="light-font-text mb-3">
          {extraLoadingMessage
            ? extraLoadingMessage
            : `Unfortunately, it’s currently taking a little longer to complete the
          requested action, but we’re working to resolve this.`}
        </div>
        <div className="light-font-text">
          Uploading <span className="header-semibold">1</span> out of{" "}
          <span className="header-semibold">15 files </span>
          to the system
        </div>
      </div>
    </div>
  );
};

//Created by Abu
