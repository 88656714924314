import React from "react";
import CustomPopup from "../../../../Components/CustomPopup";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/*-----this is a common component we can use for small popups like (warning ,delete customer, reactivate,activate,send reminder,resend invitation)etc. also can modify this accordingly we needed to!----*/

export const ActionsPopup = ({
  open,
  onHide,
  secondNextShow,
  firstText,
  secondText,
  email = "",
  ThridTextShow,
  ThridText,
  fourthText,
  icon,
  whiteButton,
  primaryButton,
  whiteClick,
  primaryClick,
  firstButton,
  title,
  titleTooltipText,
}) => {
  return (
    <div>
      <CustomPopup
        open={open}
        onHide={onHide}
        title={title}
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        size={"md"}
        cancel_button_bordercolor="grey"
        cancel_button_label={whiteButton}
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
        titleTooltipText={titleTooltipText}
        ThridTextShow={false}
        secondNextShow={false}
      >
        <div className="light-font-text">
          <div className="px-4 pt-4 ">
            <Row className="mt-2 mb-4">
              <Col>{firstText}</Col>
            </Row>
            <Row className="mt-2 mb-2">
              <Col>
                {secondText}{" "}
                {secondNextShow ? (
                  <span className="light-font-text" style={{ color: "#888" }}>
                    {" "}
                    {email}
                  </span>
                ) : null}
              </Col>
            </Row>
            {ThridTextShow ? ( // Conditionally render based on ThridTextShow
              <Row className="mt-2 mb-2 mt-4">
                <Col>
                  {ThridText}
                  <FontAwesomeIcon
                    icon={icon}
                    size="lg"
                    style={{ color: "#000", paddingLeft: "10px" }}
                  />
                </Col>
              </Row>
            ) : null}
            <Row className="mt-2 mb-4">
              <Col>{fourthText}</Col>
            </Row>
          </div>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          {firstButton === true ? (
            ""
          ) : (
            <ETButton
              buttonText={whiteButton}
              variant={"white"}
              onClick={whiteClick}
            />
          )}
          <div className="d-flex ">
            <div>
              <ETButton
                buttonText={primaryButton}
                variant={"primary"}
                onClick={primaryClick}
              />
            </div>
          </div>
        </div>
      </CustomPopup>
    </div>
  );
};

ActionsPopup.defaultProps = {
  firstText:
    "There is no published screening form on the selected unit and therefore a screening cannot be started.",
  secondText:
    "To solve this, go to Property, Settings, Screening and link the unit to a published screening form.",
  ThridText: "Take a shortcut to the solution",
  icon: "fa-regular fa-person-running",
  whiteButton: "Cancel",
  primaryButton: "Close",
  titleTooltipText: "Warning",
};

/*made by Anmol! */
