import { useMutation, useQuery, useQueryClient } from "react-query";
import { Login } from "../../services/login";
import { AuthTokenValidation } from "../../services/AuthTokenValidation";
import { RefreshToken } from "../../services/RefreshToken";
import { LogoutApi } from "../../services/LogoutApi";

export const useLogin = () => {
	return useMutation(Login);
	// useToken();
};
export const useToken = () => {
	const queryClient = useQueryClient();
	return useQuery("JWTTokenValidation", () => AuthTokenValidation(), {
		// onSuccess: () => {
		// 	queryClient.invalidateQueries("JWTTokenValidation");
		// },
		// staleTime: 120000,
		refetchInterval: 120000,
	});
};

export const useRt = () => {
	return useQuery("RefreshToken", () => RefreshToken());
};

export const useLogout = () => {
	return useQuery("logout", () => LogoutApi());
};
