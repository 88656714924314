import React from "react";
import { useBreadcrumb } from "../Components/BreadcrumbContext ";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GreenSpan } from "../styles/Common.style";

function Breadcrumbs() {
  const { breadcrumbs } = useBreadcrumb();
  return (
    <span>
      {breadcrumbs?.map((breadcrumb, index) => (
        <span key={index}>
          {index > 0 && (
            <span className="me-2">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />{" "}
            </span>
          )}
          {/* <span >
            {breadcrumb}
          </span> */}
          <GreenSpan
            className={
              index !== breadcrumbs?.length - 1 ? "me-2" : "me-2 breadcrumbs"
            }
            style={{ color: index !== breadcrumbs?.length - 1 && "#000" }}
          >
            {breadcrumb}
          </GreenSpan>
        </span>
      ))}
    </span>
  );
}

export default Breadcrumbs;
