import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import Accordion from "../../../Components/Accordion/Accordion";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import Toggle from "../../../Components/Switch/Switch";
import StarRating from "../../../Components/rating/StarRating";

const AgentFollowUp = () => {
  const [favorite, setFavorite] = useState(null);

  const localizer = momentLocalizer(moment);
  const calendarConfig = {
    components: {
      // event: CustomEvent,
      toolbar: CustomToolbar,
      // eventWrapper: CustomEventWrapper,
    },
  };
  useEffect(() => {
    setFavorite(2);
  }, []);
  // console.log(favorite, "favorite");
  return (
    <div>
      <div className="pt-2 pb-3 px-4">
        <div className="   form-label-title">Rating</div>
        <div className="light-font-text grey-text pb-1">
          How interested where the participant(s)?
        </div>
        <div>
          <StarRating
            setFavoriteRating={setFavorite}
            favoriteRating={favorite}
          />
          {/* {favorite} */}
        </div>
      </div>
      <hr className="my-2"></hr>
      <div className="pt-2 pb-4 px-4">
        <div className="   form-label-title">Follow up date</div>
        <div className="light-font-text grey-text">
          Let us help you remember to follow up on this participant
        </div>
        <div className="pt-3">
          <Row>
            <Col lg={3}>
              <div className="form-input ">
                <input
                  type="date"
                  placeholder="Follow up date"
                  id="Followupdate"
                  // value={value}
                  // onChange={(e) => {
                  // 	onChange(e);
                  // 	clearErrors("long");
                  // }}
                />
                <label htmlFor="Followupdate">Follow up date</label>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <hr className="my-0"></hr>
      <div className="pt-3 pb-4 ">
        <Accordion
          bgColor={"#FFFFFF"}
          accordianHeight={"40px"}
          accordianBorder={false}
          title={<div className="form-label-title">Private viewing</div>}
          titleDiscriptipon={
            <div className="light-font-text grey-text px-4">
              If you already know that the participant want a follow up private
              viewing. You can book it here.
            </div>
          }
          contentBody={
            <>
              <div className="p-4">
                <Row>
                  <Col lg={6}>
                    <Row>
                      <Col className="pb-2 pe-lg-0">
                        <div className="form-input ">
                          <input
                            type="date"
                            placeholder="Date"
                            id="Date"
                            // value={value}
                            // onChange={(e) => {
                            // 	onChange(e);
                            // 	clearErrors("long");
                            // }}
                          />
                          <label htmlFor="Date">Date</label>
                        </div>
                      </Col>
                      <Col className="pb-2 pe-lg-0">
                        <ReactSelect placeholder={"Starting time"} />
                      </Col>
                      <Col className="pb-2 pe-lg-0">
                        <ReactSelect placeholder={"End time"} />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="py-3 pe-lg-0">
                        <ReactSelect
                          placeholder={
                            "Responsible agent to handle the personal viewing"
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={10} className="pe-lg-0 mb-sm-3 mb-lg-0">
                        <div className="light-font-text d-flex align-items-center">
                          {" "}
                          Send booking confirmation to the participant{" "}
                          <Toggle id={"Id"} />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={{ span: 4, offset: 2 }}>
                    <div
                      className="p-2 radius-8"
                      style={{ border: "1px solid #D1D1D1" }}
                    >
                      <Calendar
                        localizer={localizer}
                        // events={myEventsList}
                        toolbar={(toolbarProps) => (
                          <CustomToolbar {...toolbarProps} />
                        )}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 341 }}
                        components={calendarConfig.components}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          }
        />
      </div>
      <hr className="my-0"></hr>
      <div className="py-3 px-4">
        <div className="   form-label-title">Internal note</div>
        <div className="light-font-text grey-text">
          Add a note so it’s easier to follow up
        </div>

        <div className=" py-4">
          <div className="deposit-text-area">
            <div className="pe-2 pt-2">
              <div
                style={{ height: "219px ", overflowY: "scroll" }}
                className="scrollable-container-y "
              >
                {/* <div className="card-div px-3 py-2 ms-3  mb-2">
                  <div className="d-flex justify-content-between">
                    <div
                      style={{
                        backgroundColor: "#9370DB",
                        color: "white",
                        borderRadius: "4px",
                        fontSize: "9px",
                        alignItems: "center",
                        textTransform: "uppercase",
                      }}
                      className="mt-1 px-2 font-semibold  d-flex"
                    >
                      EVENT
                    </div>
                    <div className="fs-10">18 Mar, 2023 at 09:10</div>
                  </div>

                  <div className="mt-2 font-semibold fs-14">Subject line</div>

                  <div className="mt-1 light-font-text">
                    Ut aut reiciendis voluptatibus maiores alias consequatur aut
                    perferendis doloribus asperiores repellat. Ut aut reiciendis
                    voluptatibus maiores alias reiciendis voluptatibus maiores
                    alias consequatur aut.
                  </div>
                  <div className="mt-2 d-flex justify-content-between">
                    <div className="light-font-text fs-12">
                      by Max Wildersans Brentford
                    </div>
                    <div className="cursor-pointer">
                      <FontAwesomeIcon
                        icon="fa-regular fa-arrow-up-right-from-square"
                        style={{ color: "#000000" }}
                      />
                    </div>
                  </div>
                </div> */}

                <div
                  className="d-flex justify-content-center w-100 fs-14 mt-2"
                  style={{ color: "#888888" }}
                >
                  No notes found
                </div>
              </div>
            </div>
            <div className="text-area p-3">
              <Row>
                <Col>
                  <div className="pb-3">
                    <div className="form-input ">
                      <input
                        type="text"
                        placeholder="subjectline"
                        id="Subject line"
                        // value={value}
                        // onChange={(e) => {
                        // 	onChange(e);
                        // 	clearErrors("long");
                        // }}
                      />
                      <label htmlFor="subjectline">Subject line</label>
                    </div>
                  </div>
                  <div>
                    <ReactSelect placeholder={"Category"} />
                  </div>
                </Col>
                <Col lg={8}>
                  <InputGroup className="notes-textarea">
                    <Form.Control
                      as="textarea"
                      placeholder="Messages"
                      aria-label="Large"
                      aria-describedby="inputGroup-sizing-sm"
                      // value={message}
                      // onChange={(e) => setMessage(e.target.value)}
                    />
                    <InputGroup.Text
                      className="notes-textarea-icon"
                      id="inputGroup-sizing-lg"
                    >
                      <button
                        type="button"
                        // disabled={
                        //   subjectLine?.length == 0 || (message?.length == 0 && true)
                        // }
                        // onClick={() => handleSendDepositNote()}
                        className="message-send-button"
                        // style={{
                        //   opacity:
                        //     subjectLine?.length == 0 || message?.length == 0
                        //       ? "50%"
                        //       : "inherit",
                        // }}
                      >
                        {
                          <FontAwesomeIcon
                            size="xs"
                            icon="fa-regular fa-paper-plane-top"
                            style={{ color: "#FFFFFF" }}
                          />
                        }
                      </button>
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentFollowUp;

const CustomToolbar = ({ label, date, onNavigate }) => {
  const handleNext = () => {
    // Custom logic for the next button
    // For example, you can navigate to the next month
    onNavigate("next", moment(date).add(1, "month"));
  };

  const handlePrev = () => {
    // Custom logic for the back button
    // For example, you can navigate to the previous month
    onNavigate("prev", moment(date).subtract(1, "month"));
  };

  return (
    <div className="rbc-toolbar ">
      <button onClick={handlePrev}>
        <FontAwesomeIcon
          icon="fa-regular fa-angle-left"
          style={{ color: "#000000" }}
        />
      </button>
      <span className="rbc-toolbar-label ">{label}</span>
      <button onClick={handleNext}>
        <FontAwesomeIcon
          icon="fa-regular fa-angle-right"
          style={{ color: "#000000" }}
        />
      </button>
    </div>
  );
};
