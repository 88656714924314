import React from "react";
// import "./check.css";
import styled from "styled-components";

const Check = ({ value, checked, onChange, id, name, bgcolor }) => {
  return (
    // <div>
    //   <input
    //     style={{ backgroundColor: { bgcolor } }}
    //     type="checkbox"
    //     id={id}
    //     name={name}
    //     value={value}
    //     checked={checked}
    //     onChange={onChange}
    //   />
    //   <div className="result">
    //     {/* Above checkbox is {checked ? "checked" : "un-checked"}. */}
    //   </div>
    // </div>
    <>
      <CheckboxInput
        value={value}
        checked={checked}
        onChange={onChange}
        name={name}
        className="styled-checkbox"
        type="checkbox"
        id={id}
      />
      <CheckboxLabel className="checklabel" htmlFor={id}></CheckboxLabel>
    </>
  );
};

export default Check;

const CheckboxInput = styled.input`
  &.styled-checkbox:checked ~ .checklabel {
    background-color: #dc5f47;
    border: none;
  }
  &.styled-checkbox:checked ~ .checklabel::after {
    opacity: 1;
    transform: rotate(35deg) scale(1);
  }
  &.styled-checkbox {
    display: none;
  }
`;

const CheckboxLabel = styled.label`
  &.checklabel {
    position: relative;
    top: 1px;
    width: 16px;
    height: 16px;
    border: 0.5px solid white;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    background-color: transparent;
  }

  &.checklabel:after {
    content: "";
    font-family: Cerebri sans-light;
    position: absolute;
    left: 5.4px;
    top: 1px;
    width: 5px;
    height: 12px;
    border-bottom-right-radius: 2px;
    border-right: 1.7px solid #ffffff;
    border-bottom: 1.7px solid #ffffff;
    transform: rotate(35deg) scale(1);
    opacity: 0;
  }
`;
