import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { H1, H3, H4 } from "../../styles/Common.style";
import {
  useGetDivisionByDivisionId,
  usePostAddDivision,
  usePutEditDivision,
} from "../../hooks/queries/useDivisionQueries";
import {
  useDivisionId,
  useShowDivisionPage,
} from "../../hooks/stores/useDivisionsStore";
import { Controller, useForm } from "react-hook-form";
import { status } from "../../model/Constant";
import { GreenSpan } from "../../styles/Common.style";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import { REQUIRED_ERR } from "../../utils/validationConstants";
import ETButton from "../../Components/Buttons/ETButton";
import { useLocation } from "react-router-dom";
import { useOrganizationId } from "../../hooks/stores/useOrganizationStore";
import { H2 } from "../../styles/Common.style";
import ReactSelect from "../../Components/DropDown/ReactSelect";
import Toggle from "../../Components/Switch/Switch";
import InputField from "../../Components/Textfield/InputField";
import moment from "moment";
import {
  useGetStreetDetails,
  useGetZipcodeByCountry,
} from "../../hooks/queries/useGeographyQueries";
import {
  useGetOrganizationFloorType,
  useGetOrganizationSideType,
} from "../../hooks/queries/useOrganization";
import { useGetGeographyCountry } from "../../hooks/queries/usePropertyQueries";
import { billingDetailsDropdownData } from "../../model/commonDropdownData";
import { useGetOwners } from "../../hooks/queries/useUserQueries";
import {
  ErrorAlert,
  LoadingAlert,
  SucessAlert,
} from "../../Components/alerts/AlertsAndMessages";
import { capitalizeEachWord } from "../../utils/TextTransform";
import ColorInputField from "../../Components/ColorInput/ColorInput";
import DropZone from "../../Components/DropZone/DropZone";
import StreetInput from "../../Components/Textfield/StreetInput";
import { useGetBillingLevelDetails } from "../../hooks/queries/useCommonTypeQueries";
import EtImageUploader from "../../Components/DropZone/EtImageUploader";

const EditDivisions = ({ buttonType = "UPDATE", flag }) => {
  const [checked, setChecked] = useState(false);

  //----------------------------Zustand---------------------//

  const { showDivisionPage, setShowDivisionPage } = useShowDivisionPage(
    (state) => state
  );

  const organizationId = useOrganizationId((state) => state.organizationId);

  //----------------------------react query---------------------//

  const { divisionId } = useDivisionId((state) => state);
  let location = useLocation();
  const { state } = location;
  let editDivisionId =
    state?.divisionId == undefined || null ? divisionId : state?.divisionId;
  const { mutateAsync: PostAddDivision, isLoading: PostAddDivisionIsLoading } =
    usePostAddDivision();
  const {
    data: GetDivisionByDivisionId,
    refetch: divisionByDivisionIdDataRefetch,
  } = useGetDivisionByDivisionId(divisionId);
  // editDivisionId
  const { mutateAsync: putEditDivision, isLoading: putEditDivisionIsLoading } =
    usePutEditDivision();
  const { data: GetOwnerData } = useGetOwners("division", divisionId);
  const { data: GetBillingLevelDetailsData } = useGetBillingLevelDetails(
    "d",
    organizationId
  );

  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    getValues,
    setValue,
    watch,
    resetField,
    formState: { errors },
  } = useForm({
    defaultValues: {
      owner_name: "",
      name: "",
      company_name: "",
      care_of: "",
      vat_number: "",
      gst_number: "",
      cvr_number: "",
      street_name: "",
      house_number: "",
      floor_id: "",
      side_id: "",
      door: "",
      country: "",
      zipcode_id: "",
      city: "",
      contact_person_name: "",
      contact_person_email: "",
      division_owner: "",
      short_name: "",
      contact_person_isd_code: "",
      contact_person_phone_no: "",
      bInfo_company: "",
      bInfo_care_of: "",
      bInfo_company_co: "",
      ean_number: "",
      bInfo_vat_number: "",
      bInfo_gst_number: "",
      bInfo_cvr_number: "",
      bInfo_street_name: "",
      bInfo_house_number: "",
      bInfo_floor_number: "",
      bInfo_side: "",
      bInfo_door: "",
      bInfo_country: "",
      bInfo_zipCode: "",
      bInfo_city: "",
      invoice_email: "",
      attention_person: "",
      is_same_detail_as_defined_general: "",
      divisionName: "",
      billing_method_applicable_id: "",
      website_url: "",
      thumbnail: "",
    },
  });

  const [isSearchStreetVisible, setIsSearchStreetVisible] = useState(false);
  const [isSearchInfoStreetVisible, setIsSearchInfoStreetVisible] =
    useState(false);
  const [searchedStreetName, setSearchedStreetName] = useState("");
  const [isSameDetailAsAboveActive, setIsSameDetailAsAboveActive] =
    useState(false);

  // ------------ state created for upload in file and image -----------//
  const [thumbnailFile, setThumbnailFile] = useState("");
  const [thumbNailFilename, setThumbnailFileName] = useState({
    name: "",
    url: "",
    fileSize: "",
  });
  const [myFileForUploadThumbnail, setMyFileForUploadThumbnail] = useState("");
  const [imageNameUploaded, setImageNameUploaded] = useState("");
  const [isMyFileUploaded, setIsMyFileUploaded] = useState(false);

  // React-Query
  const { data: getStreetDetailsData } =
    useGetStreetDetails(searchedStreetName);
  const { data: countryData } = useGetGeographyCountry();
  const { data: zipCodeByCountryIdData } = useGetZipcodeByCountry(
    getValues("country")?.value
  );

  const { data: zipCodeByCountryIdData2 } = useGetZipcodeByCountry(
    getValues("bInfo_country")?.value
  );
  const { data: getOrganizationFloorTypeData } = useGetOrganizationFloorType();
  const { data: getOrganizationSideTypeData } = useGetOrganizationSideType();

  useEffect(() => {
    if (buttonType == "UPDATE") {
      if (GetDivisionByDivisionId?.data !== null) {
        const divisionByIdData = GetDivisionByDivisionId?.data?.[0];

        setValue("owner_name", divisionByIdData?.owner_name);
        setValue("name", divisionByIdData?.name);
        setValue("company_name", divisionByIdData?.company_name);
        setValue("care_of", divisionByIdData?.care_of);
        setValue("vat_number", divisionByIdData?.public_id1);
        setValue("gst_number", divisionByIdData?.public_id2);
        setValue("cvr_number", divisionByIdData?.public_id3);
        setValue("street_name", divisionByIdData?.street_name);
        setValue("house_number", divisionByIdData?.house_number);
        setValue("floor_id", {
          value: divisionByIdData?.floor_id,
          label: divisionByIdData?.floor_name,
        });
        setValue("side_id", {
          value: divisionByIdData?.side_id,
          label: divisionByIdData?.side_name,
        });
        setValue("door", divisionByIdData?.door);
        setValue("country", {
          value: divisionByIdData?.country_id,
          label: divisionByIdData?.country,
        });
        setValue("zipcode_id", {
          value: divisionByIdData?.zipcode_id,
          label: divisionByIdData?.city,
        });
        setValue("city", divisionByIdData?.city);
        setValue("contact_person_name", divisionByIdData?.contact_person_name);
        setValue(
          "contact_person_email",
          divisionByIdData?.contact_person_email
        );
        setValue("division_owner", {
          value: divisionByIdData?.owner_id,
          label: divisionByIdData?.owner_name,
          isDisabled: true,
        });
        setValue("short_name", divisionByIdData?.shortname);
        if (divisionByIdData?.contact_person_isd_code?.length) {
          setValue("contact_person_isd_code", {
            value: divisionByIdData?.contact_person_isd_code,
            label: divisionByIdData?.contact_person_isd_code,
          });
        }
        setValue(
          "contact_person_phone_no",
          divisionByIdData?.contact_person_phone_no
        );

        //billing info
        setValue(
          "bInfo_company",
          divisionByIdData?.billing_details?.[0]?.company_name
        );
        setValue(
          "bInfo_care_of",
          divisionByIdData?.billing_details?.[0]?.care_of,
          {
            shouldValidate: true,
          }
        );
        setValue(
          "bInfo_company_co",
          divisionByIdData?.billing_details?.[0]?.care_of
        );
        setValue("ean_number", divisionByIdData?.billing_details?.[0]?.ean);
        setValue(
          "bInfo_vat_number",
          divisionByIdData?.billing_details?.[0]?.public_id1
        );
        setValue(
          "bInfo_gst_number",
          divisionByIdData?.billing_details?.[0]?.public_id2
        );
        setValue(
          "bInfo_cvr_number",
          divisionByIdData?.billing_details?.[0]?.public_id3
        );

        setValue(
          "bInfo_street_name",
          divisionByIdData?.billing_details?.[0]?.street_name
        );
        setValue(
          "bInfo_house_number",
          divisionByIdData?.billing_details?.[0]?.house_number
        );
        setValue(
          "bInfo_floor_number",
          divisionByIdData?.billing_details?.[0]?.floor_id === ""
            ? ""
            : {
                value: divisionByIdData?.billing_details?.[0]?.floor_id,
                label: divisionByIdData?.billing_details?.[0]?.floor_name,
              }
        );

        setValue(
          "bInfo_side",
          divisionByIdData?.billing_details?.[0]?.side_id === ""
            ? ""
            : {
                value: divisionByIdData?.billing_details?.[0]?.side_id,
                label: divisionByIdData?.billing_details?.[0]?.side_name,
              }
        );
        setValue("bInfo_door", divisionByIdData?.billing_details?.[0]?.door);
        setValue(
          "bInfo_country",
          divisionByIdData?.billing_details?.[0]?.country_id === ""
            ? ""
            : {
                value: divisionByIdData?.billing_details?.[0]?.country_id,
                label: divisionByIdData?.billing_details?.[0]?.country,
              }
        );
        setValue(
          "bInfo_zipCode",
          divisionByIdData?.billing_details?.[0]?.zipcode_id === ""
            ? ""
            : {
                value: divisionByIdData?.billing_details?.[0]?.zipcode_id,
                label: divisionByIdData?.billing_details?.[0]?.city,
              }
        );
        setValue("bInfo_city", divisionByIdData?.billing_details?.[0]?.city);
        setValue(
          "invoice_email",
          divisionByIdData?.billing_details?.[0]?.invoice_email
        );
        setValue(
          "attention_person",
          divisionByIdData?.billing_details?.[0]?.attention_person_name
        );

        setIsSameDetailAsAboveActive(
          divisionByIdData?.is_same_detail_as_defined_general == 0
            ? false
            : true
        );
        setValue(
          "is_same_detail_as_defined_general",
          divisionByIdData?.is_same_detail_as_defined_general == 0
            ? false
            : true
        );

        setValue("divisionName", GetDivisionByDivisionId?.data?.[0]?.name);
        setValue(
          "billing_method_applicable_id",
          divisionByIdData?.billing_method_applicable_id
            ? {
                value: divisionByIdData?.billing_method_applicable_id,
                label: billingDetailsDropdownData?.filter(
                  (data) =>
                    data?.Id?.toString() ===
                    divisionByIdData?.billing_method_applicable_id?.toString()
                )?.[0]?.Text,
              }
            : ""
        );

        setMyFileForUploadThumbnail({
          ...myFileForUploadThumbnail,
          name: divisionByIdData?.logo_thumb_filename,
          path: divisionByIdData?.logo_thumb_url,
        });
        setThumbnailFile(divisionByIdData?.logo_thumb_url);
        setThumbnailFileName({
          name: divisionByIdData?.logo_thumb_filename,
          url: divisionByIdData?.logo_thumb_url,
          fileSize: divisionByIdData?.logo_thumb_filesize,
        });
        // if (dupmyFileForUploadThumbnail) {
        //   setDupMyFileForUploadThumbnail({
        //     // ...myFileForUploadThumbnail,
        //     name: divisionByIdData?.logo_thumb_filename,
        //     path: divisionByIdData?.logo_thumb_url,
        //   });
        // }

        setImageNameUploaded(divisionByIdData?.logo_thumb_filename);
        setValue("website_url", divisionByIdData?.website_url);
      }
    }
  }, [GetDivisionByDivisionId]);

  useEffect(() => {
    if (
      getValues("cvr_number")?.length ||
      getValues("vat_number")?.length ||
      getValues("gst_number")?.length
    ) {
      clearErrors(["cvr_number", "vat_number", "gst_number"]);
    }
    if (
      getValues("bInfo_cvr_number")?.length ||
      getValues("bInfo_vat_number")?.length ||
      getValues("bInfo_gst_number")?.length
    ) {
      clearErrors(["bInfo_cvr_number", "bInfo_vat_number", "bInfo_gst_number"]);
    }
  }, [
    watch("cvr_number"),
    watch("vat_number"),
    watch("gst_number"),
    watch("bInfo_cvr_number"),
    watch("bInfo_vat_number"),
    watch("bInfo_gst_number"),
  ]);

  //===================Auto save form call when logo updated===================//
  // useEffect(() => {
  //   if (isMyFileUploaded) {
  //     handleInputChange();
  //   }
  // }, [isMyFileUploaded]);

  const handleSetSameDetailAsAbove = (e) => {
    setIsSameDetailAsAboveActive(e.target.checked);
    setValue("is_same_detail_as_defined_general", e.target.checked);
    if (e.target.checked) {
      setValue("bInfo_company", getValues("company_name"), {
        shouldValidate: true,
      });

      setValue("bInfo_care_of", getValues("care_of"), {
        shouldValidate: true,
      });
      setValue("bInfo_cvr_number", getValues("cvr_number"), {
        shouldValidate: false,
      });
      setValue("bInfo_vat_number", getValues("vat_number"), {
        shouldValidate: false,
      });
      setValue("bInfo_gst_number", getValues("gst_number"), {
        shouldValidate: false,
      });
      setValue("bInfo_street_name", getValues("street_name"), {
        shouldValidate: true,
      });
      setValue("bInfo_house_number", getValues("house_number"), {
        shouldValidate: true,
      });
      setValue(
        "bInfo_floor_number",
        {
          value: getValues("floor_id")?.value,
          label: getValues("floor_id")?.label,
        },
        { shouldValidate: true }
      );
      setValue(
        "bInfo_side",
        {
          value: getValues("side_id")?.value,
          label: getValues("side_id")?.label,
        },
        { shouldValidate: true }
      );
      setValue("bInfo_door", getValues("door"), { shouldValidate: true });
      setValue(
        "bInfo_country",
        {
          value: getValues("country")?.value,
          label: getValues("country")?.label,
        },
        { shouldValidate: true }
      );
      setValue(
        "bInfo_zipCode",
        {
          value: getValues("zipcode_id")?.value,
          label: getValues("zipcode_id")?.label,
        },
        { shouldValidate: true }
      );
      setValue("bInfo_city", getValues("city"), { shouldValidate: true });
    } else {
      const divisionByIdData = GetDivisionByDivisionId?.data?.[0];

      setValue("bInfo_company", "");

      setValue("bInfo_care_of", "");

      setValue("bInfo_company_co", "");
      setValue("ean_number", divisionByIdData?.billing_details?.[0]?.ean);
      setValue("bInfo_vat_number", "");
      setValue("bInfo_gst_number", "");
      setValue("bInfo_cvr_number", "");
      setValue("bInfo_street_name", "");
      setValue("bInfo_house_number", "");
      setValue("bInfo_floor_number", "");
      setValue("bInfo_side", "");
      setValue("bInfo_door", "");
      setValue("bInfo_country", "");
      setValue("bInfo_zipCode", "");
      setValue("bInfo_city", "");

      // setValue("bInfo_side_id", {
      //   value: GetDivisionByDivisionId?.data?.[0]?.['billing_details']?.side_id,
      //   label: GetDivisionByDivisionId?.data?.[0]?.['billing_details']?.side_name,
      // });
    }
  };

  const handleEditDivision = async (data) => {
    let id;
    if (buttonType == "UPDATE") {
      let formData = new FormData();
      formData.append(
        "division_id",
        GetDivisionByDivisionId?.data?.[0]?.division_id?.toString()
      );
      formData.append("name", capitalizeEachWord(data?.name));
      formData.append("company_name", capitalizeEachWord(data?.company_name));
      formData.append("shortname", data?.short_name?.toUpperCase());
      formData.append("care_of", data?.care_of);
      formData.append("street_name", data?.street_name);
      formData.append("house_number", data?.house_number);
      formData.append("zipcode_id", data?.zipcode_id?.value);
      formData.append("country_id", data?.country?.value);
      formData.append("owner_id", data?.division_owner?.value);
      formData.append("public_type_id1", "1");
      formData.append("public_id1", data?.vat_number || "");
      formData.append("public_type_id2", "1");
      formData.append("public_id2", data?.gst_number || "");
      formData.append("public_type_id3", "1");
      formData.append("public_id3", data?.cvr_number || "");

      formData.append("website_url", data?.website_url || "");
      formData.append(
        "contact_person_name",
        capitalizeEachWord(data?.contact_person_name)
      );
      formData.append(
        "contact_person_email",
        data?.contact_person_email?.toLowerCase()
      );
      formData.append(
        "contact_person_isd_code",
        data?.contact_person_isd_code?.value
      );
      formData.append("contact_person_phone_no", data?.contact_person_phone_no);
      formData.append("door", data?.door);
      formData.append("side_id", data?.side_id?.value?.toString());
      formData.append("floor_id", data?.floor_id?.value?.toString());
      formData.append(
        "is_same_detail_as_defined_general",
        +data?.is_same_detail_as_defined_general ? "1" : "0"
      );
      formData.append(
        "billing_method_applicable_id",
        data?.billing_method_applicable_id?.value
      );
      // formData.append("ean", data?.ean_number);
      // formData.append("invoice_email", data?.invoice_email?.toLowerCase());
      // formData.append(
      //   "attention_person_name",
      //   capitalizeEachWord(data?.attention_person)
      // );
      formData.append(
        "billing_details",
        data?.billing_method_applicable_id?.value?.toString() === "D"
          ? JSON.stringify({
              company_name: capitalizeEachWord(data?.bInfo_company),
              care_of: capitalizeEachWord(data?.bInfo_care_of),
              street_name: data?.bInfo_street_name,
              house_number: data?.bInfo_house_number,
              door: data?.bInfo_door,
              floor_id: data?.bInfo_floor_number?.value?.toString() || "",
              side_id: data?.bInfo_side?.value?.toString() || "",
              zipcode_id: data?.bInfo_zipCode?.value || "",
              country_id: data?.bInfo_country?.value,
              public_id1: data?.bInfo_vat_number || "",
              public_id2: data?.bInfo_gst_number || "",
              public_id3: data?.bInfo_cvr_number || "",
              public_type_id1: "1",
              public_type_id2: "1",
              public_type_id3: "1",
              ean_number: data?.ean_number,
              invoice_email: data?.invoice_email?.toLowerCase(),
              attention_person: capitalizeEachWord(data?.attention_person),
            })
          : JSON.stringify({})
      );
      // By default I am saving the url in string format, if the string contains thumbnail url then it will send " " otherwise it will send the updated file as the file is always in object format. : Suraj
      formData.append(
        "logo_thumb_file",
        data?.thumbnail ? data?.thumbnail : ""
      );
      formData.append(
        "logo_thumb_filename",
        data?.thumbnail
          ? data?.thumbnail?.name
          : GetDivisionByDivisionId?.data?.[0]?.logo_thumb_filename
      );
      // formData.append(
      //   "logo_thumb_file",
      //   myFileForUploadThumbnail?.length ? myFileForUploadThumbnail?.[0] : ""
      // );
      // formData.append(
      //   "logo_thumb_filename",
      //   myFileForUploadThumbnail?.length
      //     ? myFileForUploadThumbnail?.[0]?.name
      //     : myFileForUploadThumbnail?.name
      // );

      if (!putEditDivisionIsLoading) {
        id = toast.loading(<LoadingAlert />);
      }
      try {
        const response = await putEditDivision(formData);
        if (response.status === status.s && response.data.bReturn) {
          toast.update(id, {
            render: <SucessAlert />,
            isLoading: false,
            type: status.s,
            autoClose: 2000,
          });
          divisionByDivisionIdDataRefetch();
        } else {
          toast.update(id, {
            render: <ErrorAlert />,
            isLoading: false,
            type: status.e,
            autoClose: 2000,
          });
        }
      } catch (error) {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } else if (buttonType == "ADD") {
      const AddDivison = {
        org_id: organizationId?.toString(),
        div_name: data?.divisionName,
      };

      if (!PostAddDivisionIsLoading) {
        id = toast.loading(<LoadingAlert />);
      }
      try {
        const response = await PostAddDivision(AddDivison);
        if (response.status === status.s && response.data.bReturn) {
          toast.update(id, {
            render: <SucessAlert />,
            isLoading: false,
            type: status.s,
            autoClose: 2000,
          });
          setShowDivisionPage("DL");
        } else {
          toast.update(id, {
            render: <ErrorAlert />,
            isLoading: false,
            type: status.e,
            autoClose: 2000,
          });
        }
      } catch (error) {
        toast.update(id, {
          render: <ErrorAlert />,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    }
  };

  //------Handle street field change--------//
  const handleStreetFieldChange = (event) => {
    if (watch("country")?.value == "DK" && event.target.value?.length >= 4) {
      setIsSearchStreetVisible(true);
      setSearchedStreetName(event?.target?.value);
    } else {
      setIsSearchStreetVisible(false);
    }
    handleInputChange();
  };

  //-------Handle street detail click-------// (Dropdown will be visible only when selected country is Denmark)
  const handleStreetDetailClick = (item) => {
    setIsSearchStreetVisible(false);
    setValue("street_name", item?.street_name);
    setValue("city", item?.city, { shouldValidate: item?.city !== "" && true });
    setValue("house_number", item?.house_number, {
      shouldValidate: item?.house_number !== "" && true,
    });
    setValue(
      "zipcode_id",
      item?.zipcode == ""
        ? ""
        : {
            value: item?.zipcode,
            label: zipCodeByCountryIdData?.data?.filter(
              (el) => el.Id == item?.zipcode
            )?.[0]?.Text,
          },
      { shouldValidate: item?.zipcode !== "" && true }
    );
    handleInputChange();
  };

  // selecting dropdown option
  const displayItem = (selected) => {
    return selected
      ? {
          value: selected?.Id,
          label: countryData?.data?.filter(
            (item, i) => item.Id === selected?.value
          )?.[0]?.Text,
        }
      : "";
  };

  // --------------------------handle zipcode change-----------------------//
  const handleZipCode = (e, ActionType) => {
    if (ActionType.action === "clear") {
      setValue("city", "");
    } else {
      if (e?.value != "" || e?.value != "undefined") {
        const HandleCityName = zipCodeByCountryIdData?.data?.filter(
          (data) => data?.Id == e?.value
        );
        const CityData = HandleCityName[0].Text;
        setValue("city", CityData);
      }
    }
  };

  //-------Handle street detail click for billing information-------// (Dropdown will be visible only when selected country is Denmark)
  const handleInfoStreetDetailClick = (item, e) => {
    setIsSearchInfoStreetVisible(false);
    setValue("bInfo_street_name", item?.street_name);
    setValue("bInfo_city", item?.city, {
      shouldValidate: item?.city !== "" && true,
    });
    setValue("bInfo_house_number", item?.house_number, {
      shouldValidate: item?.house_number !== "" && true,
    });
    setValue(
      "bInfo_zipCode",
      item?.zipcode == ""
        ? ""
        : {
            value: item?.zipcode,
            label: zipCodeByCountryIdData2?.data?.filter(
              (el) => el.Id == item?.zipcode
            )?.[0]?.Text,
          },
      { shouldValidate: item?.zipcode !== "" && true }
    );
    handleInputChange();
  };

  //------Handle billing info street field change--------//
  const handleInfoStreetFieldChange = (event) => {
    if (
      watch("bInfo_country")?.value == "DK" &&
      event.target.value?.length >= 4
    ) {
      setIsSearchInfoStreetVisible(true);
      setSearchedStreetName(event?.target?.value);
    } else {
      setIsSearchInfoStreetVisible(false);
    }
    handleInputChange();
  };

  // --------------------------handle billing zipcode change-----------------------//
  const handleBillingZipCode = (e, ActionType) => {
    if (ActionType.action === "clear") {
      setValue("bInfo_city", "");
    } else {
      if (e?.value != "" || e?.value != "undefined") {
        const HandleCityName = zipCodeByCountryIdData2?.data?.filter(
          (data) => data?.Id == e?.value
        );
        const CityData = HandleCityName[0].Text;
        setValue("bInfo_city", CityData);
      }
    }
  };

  //==========================Form Auto Save functionality===========================//
  const [timeOutId, setTimeOutId] = useState(null);

  const handleAutoSave = () => {
    handleSubmit(handleEditDivision)();
  };
  const handleInputChange = (duration = 3000) => {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    const newTimeOutId = setTimeout(() => {
      handleAutoSave();
    }, duration);
    setTimeOutId(newTimeOutId);
  };

  //=================== handle reset billing infprmation fields ================//
  const resetBillingInformationField = () => {
    resetField("bInfo_company");
    resetField("bInfo_care_of");
    resetField("bInfo_cvr_number");
    resetField("bInfo_gst_number");
    resetField("bInfo_vat_number");
    resetField("bInfo_country");
    resetField("bInfo_street_name");
    resetField("bInfo_house_number");
    resetField("bInfo_floor_number");
    resetField("bInfo_side");
    resetField("bInfo_door");
    resetField("bInfo_zipCode");
    resetField("bInfo_city");
    resetField("invoice_email");
    resetField("attention_person");
  };
  return (
    <Container
      fluid
      style={{ backgroundColor: "#F9FAFA" }}
      className="pt-1 px-0"
    >
      {buttonType == "ADD" && (
        <div>
          <div className="pt-3">
            <H1 className="mb-0">Details</H1>
          </div>
          <div
            className="mt-0 pb-4 light-font-text"
            style={{ cursor: "pointer" }}
          >
            <span className="mt-1 ">Division</span>
            <span className="px-3">
              <FontAwesomeIcon
                size="sm"
                icon="fa-regular fa-angle-right"
                style={{ color: "#000000" }}
              />
            </span>
            <GreenSpan>
              {" "}
              {buttonType == "ADD" ? "Create new division" : "Division details"}
            </GreenSpan>
          </div>
        </div>
      )}
      <div className="p-md-4 p-1 mb-4  radius-8 containerStyle">
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            border: "1px solid #F4F5F5",
            fontSize: "14px",
          }}
        >
          <div className="d-flex align-items-center mt-3 ">
            <H2 className="  ps-4 pe-2 ">
              {buttonType == "ADD" ? "Create new division" : "Division details"}
            </H2>
            <div>
              <CustomToolTip
                height={12}
                text={
                  buttonType == "ADD"
                    ? "Create new division"
                    : "Division details"
                }
              />
            </div>
          </div>
          <div></div>

          <hr className="mt-3 mb-0" />
          <Form autoComplete="off" onSubmit={handleSubmit(handleEditDivision)}>
            {/* <Row className="py-1 px-4">
              <Col xl={4} lg={4}>
                <div className=" form-label-title">Organization </div>
              </Col>
            </Row>
            <Row className="pt-3  pb-4 px-4">
              <Col xl={4} lg={5} className="pe-0">
                <Controller
                  name="organization"
                  control={control}
                  {...register("organization")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder="Organization"
                      clearable={false}
                      options={GetOrganizationData?.data?.map((item) => ({
                        value: item?.organisation_id,
                        label: item?.name,
                      }))}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
                {errors?.organization && (
                  <p className="error-msg"> {REQUIRED_ERR}</p>
                )}
              </Col>
            </Row>
            <hr className="hr mt-0" /> */}
            {/* <Row className="py-1 ps-4">
              <Col>
                <div className="form-label-title">Divsion name</div>
              </Col>
            </Row>
            <Row
              className="pt-3 pb-4 ps-4  pe-lg-0 pe-4"
              style={{ position: "relative" }}
            >
              <Col xl={4} lg={5} className="my-2 my-lg-0 pe-lg-2">
                <Controller
                  name="divisionName"
                  control={control}
                  {...register("divisionName")}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <div className="form-input ">
                      <input
                        type="text"
                        placeholder="divisionName"
                        id="divisionName"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        maxLength={30}
                      />
                      <label htmlFor="divisionName">Division name</label>
                    </div>
                  )}
                />
                {errors?.divisionName && (
                  <p className="error-msg"> {REQUIRED_ERR}</p>
                )}
              </Col>
            </Row> */}
            <div className="p-4">
              <Row className=" d-flex">
                <H4 className="pb-3">Division name</H4>
                <Col
                  xl={3}
                  lg={4}
                  md={6}
                  className="pe-sm-3 mb-lg-3 mb-sm-3 mt-lg-2"
                >
                  <Controller
                    {...register(`name`)}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <InputField
                          type={"text"}
                          placeholder={"Name"}
                          value={value}
                          id={"name"}
                          textTransform="capitalize"
                          errorscope
                          iserror={errors?.name}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("name");
                            handleInputChange();
                          }}
                        />
                      </div>
                    )}
                  />
                </Col>
                <Col
                  xl={3}
                  lg={4}
                  md={6}
                  className="pe-3 ps-lg-0 mt-lg-2 mb-sm-3  "
                >
                  <Controller
                    control={control}
                    {...register("short_name")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"short_name"}
                        value={value}
                        type="text"
                        textTransform="uppercase"
                        placeholder="Short name (3 letters max)"
                        errorscope
                        iserror={errors?.short_name}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("short_name");
                          handleInputChange();
                        }}
                        maxLength={3}
                      />
                    )}
                  />
                </Col>
                <Col className="created-by gap-3">
                  <div className="d-flex text-end justify-content-end">
                    <div>
                      Division created:{" "}
                      <span className="ms-2 d-inline-block">
                        {getValues("created_on") === ""
                          ? "-"
                          : moment(getValues("created_on")).format(
                              "DD MMM, YYYY"
                            )}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    {/* <div>3 Nov, 2023 </div> */}
                    <div>
                      Owner:{" "}
                      <span className="ms-2 d-inline-block">
                        {getValues("owner_name")}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col
                  xl={3}
                  lg={4}
                  md={6}
                  className="pe-sm-3 pe-lg-3 mb-lg-0 mb-sm-3 "
                >
                  <Controller
                    control={control}
                    {...register("division_owner")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Division owner"}
                        iserror={errors?.division_owner}
                        options={GetOwnerData?.data?.map((item) => ({
                          value: item?.Id,
                          label: item?.Text,
                          isDisabled: value?.value == item?.Id,
                        }))}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("division_owner");
                          handleInputChange();
                        }}
                        clearable={false}
                      />
                    )}
                  />
                </Col>
              </Row>
            </div>

            <hr className="my-0" />

            {/* Organisation details */}
            <H4 className="pt-4 mb-3 px-4">Company details</H4>

            <div className="px-4">
              <Row>
                <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3 ">
                  <Controller
                    control={control}
                    {...register("company_name")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"company_name"}
                        type="text"
                        placeholder="Company"
                        errorscope={true}
                        textTransform="capitalize"
                        value={value}
                        iserror={errors?.company_name}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("company_name");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                  <Controller
                    control={control}
                    {...register("care_of")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"C/O"}
                        value={value}
                        type="text"
                        placeholder="C/O"
                        textTransform="capitalize"
                        errorscope
                        maxLength={30}
                        iserror={errors?.care_of}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("care_of");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3 ">
                  <Controller
                    control={control}
                    {...register("cvr_number")}
                    rules={{
                      required: !(
                        watch("vat_number")?.length ||
                        watch("gst_number")?.length
                      ),
                    }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"cvr_number"}
                        type="text"
                        placeholder="CVR Number"
                        errorscope={true}
                        value={value}
                        maxLength={30}
                        iserror={errors?.cvr_number}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("cvr_number");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                  <Controller
                    {...register(`vat_number`)}
                    control={control}
                    rules={{
                      required: !(
                        watch("cvr_number")?.length ||
                        watch("gst_number")?.length
                      ),
                    }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <InputField
                          type={"text"}
                          placeholder={"VAT Number"}
                          id={"VATNumber"}
                          errorscope
                          value={value}
                          maxLength={30}
                          iserror={errors?.vat_number}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("vat_number");
                            handleInputChange();
                          }}
                        />
                      </div>
                    )}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="pe-lg-3 ps-lg-0  mb-sm-3">
                  <Controller
                    {...register(`gst_number`)}
                    control={control}
                    rules={{
                      required: !(
                        watch("vat_number")?.length ||
                        watch("cvr_number")?.length
                      ),
                    }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <InputField
                          type={"text"}
                          placeholder={"GST Number"}
                          id={"GSTNumber"}
                          value={value}
                          errorscope
                          maxLength={30}
                          iserror={errors?.gst_number}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("gst_number");

                            handleInputChange();
                          }}
                        />
                      </div>
                    )}
                  />
                </Col>
              </Row>
            </div>
            <div className="px-4">
              <Row>
                <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-3 mb-sm-3">
                  <Controller
                    control={control}
                    {...register("country")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder="Country"
                        clearable={false}
                        options={countryData?.data?.map((item) => ({
                          value: item?.Id,
                          label: (
                            <div
                              className="d-flex align-items-center"
                              style={{ fontFamily: "Cerebri sans" }}
                            >
                              <div className="radius-4 me-2">
                                <img
                                  src={item?.DataValue1}
                                  style={{
                                    width: "20px",
                                    marginRight: "10px",
                                  }}
                                  alt=""
                                />
                              </div>
                              <div>
                                <div className="fs-14 light-font-text ">
                                  {" "}
                                  {item?.Text}
                                </div>
                              </div>
                            </div>
                          ),
                          isDisabled: value?.value == item?.Id,
                        }))}
                        iserror={errors?.country}
                        value={value == undefined ? "" : displayItem(value)}
                        onChange={(e, ActionType) => {
                          onChange(e);
                          resetField("city");
                          resetField("zipcode_id");
                          resetField("street_name");
                          clearErrors("country");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
            </div>
            <div className="px-4">
              <Row>
                <Col
                  xl={3}
                  lg={4}
                  md={6}
                  className="pe-sm-3 mb-lg-0 mb-sm-3  position-relative"
                >
                  <StreetInput
                    isSearchStreetVisible={isSearchStreetVisible}
                    setIsSearchStreetVisible={setIsSearchStreetVisible}
                    handleStreetFieldChange={handleStreetFieldChange}
                    handleStreetDetailClick={handleStreetDetailClick}
                    control={control}
                    // disabledValue={isSameDetailAsAboveActive}
                    removeOpacity={
                      !isSameDetailAsAboveActive && flag !== "Property"
                    }
                    street_name={"street_name"}
                    errors={errors}
                    watch={watch}
                    searchedStreetName={searchedStreetName}
                    clearErrors={clearErrors}
                    country_name={"country"}
                  />
                </Col>
                <Col xl={2} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                  <Controller
                    {...register(`house_number`)}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <InputField
                          type={"text"}
                          placeholder={"No."}
                          value={value}
                          id={"houseNo"}
                          errorscope
                          maxLength={30}
                          iserror={errors?.house_number}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("house_number");
                            handleInputChange();
                          }}
                        />
                      </div>
                    )}
                  />
                </Col>
                <Col xl={2} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3">
                  <Controller
                    control={control}
                    {...register("floor_id")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Floor"}
                        iserror={errors?.floor_id}
                        options={getOrganizationFloorTypeData?.data?.map(
                          (item) => ({
                            value: item?.Id,
                            label: item?.Text,
                            isDisabled: value?.value == item?.Id,
                          })
                        )}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("floor_id");
                          handleInputChange();
                        }}
                        clearable={false}
                      />
                    )}
                  />
                </Col>
                <Col
                  xl={2}
                  lg={4}
                  md={6}
                  className="pe-sm-3 ps-xl-0 mb-lg-0 mb-sm-3 "
                >
                  <Controller
                    control={control}
                    {...register("side_id")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Side"}
                        iserror={errors?.side_id}
                        options={getOrganizationSideTypeData?.data?.map(
                          (item) => ({
                            value: item?.Id,
                            label: item?.Text,
                            isDisabled: value?.value == item?.Id,
                          })
                        )}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("side_id");
                          handleInputChange();
                        }}
                        clearable={false}
                      />
                    )}
                  />
                </Col>
                <Col xl={2} lg={4} md={6} className="pe-3 ps-lg-0  mb-sm-3  ">
                  <Controller
                    {...register(`door`)}
                    control={control}
                    // rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <InputField
                          type={"text"}
                          placeholder={"Door"}
                          id={"door"}
                          errorscope
                          value={value}
                          maxLength={30}
                          iserror={errors?.door}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("door");
                            handleInputChange();
                          }}
                        />
                      </div>
                    )}
                  />
                </Col>
              </Row>
            </div>
            <div className="px-4 ">
              <Row>
                <Col xl={3} lg={4} md={6} className="pe-sm-3 mb-lg-0 mb-sm-3">
                  <Controller
                    control={control}
                    {...register("zipcode_id")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder="Zip code"
                        options={zipCodeByCountryIdData?.data?.map((zip) => ({
                          value: zip?.Id,
                          label: `${zip?.Id} ${zip?.Text}`,
                        }))}
                        clearable={false}
                        iserror={errors?.zipcode_id}
                        value={
                          value?.value
                            ? {
                                value: value?.value,
                                label: value?.value,
                              }
                            : ""
                        }
                        onChange={(e, ActionType) => {
                          handleZipCode(e, ActionType);
                          onChange(e);
                          clearErrors("zipcode_id");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xl={3} lg={4} md={6} className="pe-lg-3 ps-lg-0  mb-sm-3 ">
                  <Controller
                    control={control}
                    {...register("city")}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"city"}
                        value={value}
                        type="text"
                        placeholder="City"
                        errorscope
                        maxLength={30}
                        iserror={errors?.city}
                        disabled
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("city");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
            </div>

            <div className="px-4 border-bottom pb-4">
              <Row>
                <Col lg={5} md={6} className="pe-sm-3 mb-lg-3 mb-sm-3">
                  <Controller
                    {...register(`contact_person_name`)}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <InputField
                          type={"text"}
                          placeholder={"Contact Person"}
                          id={"ContactPerson"}
                          textTransform="capitalize"
                          value={value}
                          iserror={errors?.contact_person_name}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("contact_person_name");
                            handleInputChange();
                          }}
                        />
                      </div>
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={5} md={6} className="pe-sm-3 ">
                  <Controller
                    control={control}
                    {...register("website_url")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        id={"website_url"}
                        value={value}
                        type="text"
                        placeholder="Website"
                        errorscope
                        maxLength={30}
                        iserror={errors?.website_url}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("website_url");
                          handleInputChange();
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
            </div>
            {/* <div className="px-4 ">
              <Row>
                <Col xl={4} lg={4} md={6} className="pt-3 pe-0 pb-3">
                  <div className="form-input ">
                    <input
                      type="text"
                      placeholder="Company website"
                      id="Company_website"
                      maxLength={30}
                    />
                    <label htmlFor="Company_website">Company website</label>
                  </div>
                </Col>
              </Row>
            </div> */}

            {/*--------- Billing details ------------*/}
            <div className="px-4 py-4">
              <H4 className="mb-3">Billing details</H4>

              <Row>
                <Col xl={3} lg={4} md={6} className="pe-lg-0">
                  <Controller
                    control={control}
                    {...register("billing_method_applicable_id")}
                    // rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        placeholder={"Billing method"}
                        clearable={false}
                        iserror={errors?.billing_method_applicable_id}
                        options={billingDetailsDropdownData
                          ?.filter((value) =>
                            GetBillingLevelDetailsData?.data?.[0]?.billing_level
                              ?.split(",")
                              ?.includes(value?.Id)
                          )
                          ?.map((item) => ({
                            value: item?.Id,
                            label: item?.Text,
                            isDisabled:
                              value?.value?.toString() === item?.Id?.toString(),
                          }))}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          clearErrors("billing_method_applicable_id");
                          setIsSameDetailAsAboveActive(false);
                          handleInputChange();
                          resetBillingInformationField();
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
            </div>

            {watch("billing_method_applicable_id")?.value?.toString() ===
            "D" ? (
              <>
                {/* Billing information */}
                <div className="px-4 pb-4">
                  <H4 className="mb-3">Billing information</H4>
                  <div className=" d-flex align-items-center gap-3">
                    <Toggle
                      id={"sameDetailAsAbove"}
                      label={"Same details as above"}
                      checked={isSameDetailAsAboveActive}
                      onChange={(e) => {
                        handleInputChange();
                        handleSetSameDetailAsAbove(e);
                      }}
                    />
                    <span className="paragraph1-regular ">
                      Same details as above
                    </span>
                  </div>
                </div>
                {/* Billing information fields*/}
                <div>
                  <div className="px-4">
                    <Row>
                      <Col xl={3} md={4} className="pe-sm-3 mb-lg-0 mb-sm-3 ">
                        <Controller
                          control={control}
                          {...register("bInfo_company")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <InputField
                                id={"bInfo_company"}
                                value={value}
                                type="text"
                                placeholder="Company"
                                textTransform="capitalize"
                                errorscope
                                disabled={isSameDetailAsAboveActive}
                                iserror={errors?.bInfo_company}
                                removeOpacity={!isSameDetailAsAboveActive}
                                onChange={(e) => {
                                  onChange(e);
                                  clearErrors("bInfo_company");
                                  handleInputChange();
                                }}
                              />
                            );
                          }}
                        />
                      </Col>
                      <Col xl={3} md={4} className=" pe-3 ps-lg-0  mb-sm-3 ">
                        <Controller
                          control={control}
                          {...register("bInfo_care_of")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"C/O"}
                              value={value}
                              type="text"
                              placeholder="C/O"
                              textTransform="capitalize"
                              errorscope
                              maxLength={30}
                              disabled={isSameDetailAsAboveActive}
                              removeOpacity={!isSameDetailAsAboveActive}
                              iserror={errors?.bInfo_care_of}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("bInfo_care_of");
                                handleInputChange();
                              }}
                            />
                          )}
                        />
                      </Col>
                      <Col xl={3} md={4} className=" pe-3 ps-lg-0  mb-sm-3 ">
                        <Controller
                          control={control}
                          {...register("ean_number")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"ean_number"}
                              value={value}
                              type="number"
                              placeholder="EAN number"
                              errorscope
                              maxLength={30}
                              iserror={errors?.ean_number}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("ean_number");
                                handleInputChange();
                              }}
                            />
                          )}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        xl={3}
                        lg={4}
                        md={6}
                        className="pe-sm-3 mb-lg-0 mb-sm-3 "
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_cvr_number")}
                          rules={{
                            required: !(
                              watch("bInfo_vat_number")?.length ||
                              watch("bInfo_gst_number")?.length
                            ),
                          }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"bInfo_cvr_number"}
                              value={value}
                              type="text"
                              placeholder="CVR Number"
                              errorscope
                              maxLength={30}
                              removeOpacity={!isSameDetailAsAboveActive}
                              iserror={errors?.bInfo_cvr_number}
                              disabled={isSameDetailAsAboveActive}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("bInfo_cvr_number");
                                handleInputChange();
                              }}
                            />
                          )}
                        />
                      </Col>
                      <Col
                        xl={3}
                        lg={4}
                        md={6}
                        className="pe-3 ps-lg-0  mb-sm-3"
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_vat_number")}
                          rules={{
                            required: !(
                              watch("bInfo_cvr_number")?.length ||
                              watch("bInfo_gst_number")?.length
                            ),
                          }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"bInfo_vat_number"}
                              value={value}
                              type="text"
                              placeholder="VAT Number"
                              errorscope
                              maxLength={30}
                              removeOpacity={!isSameDetailAsAboveActive}
                              iserror={errors?.bInfo_vat_number}
                              disabled={isSameDetailAsAboveActive}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("bInfo_vat_number");
                                handleInputChange();
                              }}
                            />
                          )}
                        />
                      </Col>
                      <Col
                        xl={3}
                        lg={4}
                        md={6}
                        className="pe-3 ps-lg-0  mb-sm-3"
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_gst_number")}
                          rules={{
                            required: !(
                              watch("bInfo_cvr_number")?.length ||
                              watch("bInfo_vat_number")?.length
                            ),
                          }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"bInfo_gst_number"}
                              value={value}
                              type="text"
                              placeholder="GST Number"
                              errorscope
                              maxLength={30}
                              removeOpacity={!isSameDetailAsAboveActive}
                              iserror={errors?.bInfo_gst_number}
                              disabled={isSameDetailAsAboveActive}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("bInfo_gst_number");
                                handleInputChange();
                              }}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4  ">
                    <Row>
                      <Col
                        xl={3}
                        lg={4}
                        md={6}
                        className="pe-sm-3 mb-lg-3 mb-sm-3 "
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_country")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <ReactSelect
                              disabled={isSameDetailAsAboveActive}
                              placeholder="Country"
                              iserror={errors?.bInfo_country}
                              errorscope
                              clearable={false}
                              options={countryData?.data?.map((item) => ({
                                value: item?.Id,
                                label: (
                                  <div
                                    className="d-flex align-items-center"
                                    style={{ fontFamily: "Cerebri sans" }}
                                  >
                                    <div className="radius-4 me-2">
                                      <img
                                        src={item?.DataValue1}
                                        style={{
                                          width: "20px",
                                          marginRight: "10px",
                                        }}
                                        alt=""
                                      />
                                    </div>
                                    <div>
                                      <div className="fs-14 light-font-text ">
                                        {" "}
                                        {item?.Text}
                                      </div>
                                    </div>
                                  </div>
                                ),
                              }))}
                              value={
                                value == undefined || value?.value === undefined
                                  ? ""
                                  : displayItem(value)
                              }
                              onChange={(e, ActionType) => {
                                onChange(e);
                                resetField("bInfo_zipCode");
                                resetField("bInfo_city");
                                resetField("bInfo_street_name");
                                clearErrors("bInfo_country");
                                handleInputChange();
                              }}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4  ">
                    <Row>
                      <Col
                        xl={3}
                        lg={4}
                        md={6}
                        className="pe-sm-3 mb-lg-0 mb-sm-3  position-relative"
                      >
                        <StreetInput
                          isSearchStreetVisible={isSearchStreetVisible}
                          setIsSearchStreetVisible={setIsSearchStreetVisible}
                          handleStreetFieldChange={handleInfoStreetFieldChange}
                          handleStreetDetailClick={handleInfoStreetDetailClick}
                          control={control}
                          disabledValue={isSameDetailAsAboveActive}
                          removeOpacity={
                            !isSameDetailAsAboveActive && flag !== "Property"
                          }
                          street_name={"bInfo_street_name"}
                          errors={errors}
                          watch={watch}
                          searchedStreetName={searchedStreetName}
                          clearErrors={clearErrors}
                          country_name={"bInfo_country"}
                        />
                      </Col>
                      <Col
                        xl={2}
                        lg={4}
                        md={6}
                        className="pe-3 ps-lg-0  mb-sm-3"
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_house_number")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"bInfo_house_number"}
                              value={value}
                              type="text"
                              placeholder="No."
                              errorscope
                              maxLength={30}
                              removeOpacity={!isSameDetailAsAboveActive}
                              iserror={errors?.bInfo_house_number}
                              disabled={isSameDetailAsAboveActive}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("bInfo_house_number");
                                handleInputChange();
                              }}
                            />
                          )}
                        />
                      </Col>
                      <Col
                        xl={2}
                        lg={4}
                        md={6}
                        className="pe-3 ps-lg-0  mb-sm-3"
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_floor_number")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <ReactSelect
                              placeholder={"Floor"}
                              iserror={errors?.bInfo_floor_number}
                              options={getOrganizationFloorTypeData?.data?.map(
                                (item) => ({
                                  value: item?.Id,
                                  label: item?.Text,
                                })
                              )}
                              disabled={isSameDetailAsAboveActive}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("bInfo_floor_number");
                                handleInputChange();
                              }}
                              clearable={false}
                            />
                          )}
                        />
                      </Col>
                      <Col
                        xl={2}
                        lg={4}
                        md={6}
                        className="pe-sm-3 ps-xl-0 mb-lg-0 mb-sm-3 "
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_side")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <ReactSelect
                              placeholder={"Side"}
                              iserror={errors?.bInfo_side}
                              disabled={isSameDetailAsAboveActive}
                              options={getOrganizationSideTypeData?.data?.map(
                                (item) => ({
                                  value: item?.Id,
                                  label: item?.Text,
                                })
                              )}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("bInfo_side");
                                handleInputChange();
                              }}
                              clearable={false}
                            />
                          )}
                        />
                      </Col>
                      <Col
                        xl={2}
                        lg={4}
                        md={6}
                        className="pe-3 ps-lg-0  mb-sm-3 "
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_door")}
                          rules={{ required: false }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"bInfo_door"}
                              value={value}
                              type="text"
                              placeholder="Door"
                              errorscope
                              maxLength={30}
                              iserror={errors?.bInfo_door}
                              disabled={isSameDetailAsAboveActive}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("bInfo_door");
                                handleInputChange();
                              }}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="px-4 ">
                    <Row>
                      <Col
                        xl={3}
                        lg={4}
                        md={6}
                        className="pe-sm-3 mb-lg-0 mb-sm-3"
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_zipCode")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <ReactSelect
                              disabled={isSameDetailAsAboveActive}
                              placeholder="Zip code"
                              clearable={false}
                              iserror={errors?.bInfo_zipCode}
                              errorscope
                              options={zipCodeByCountryIdData2?.data?.map(
                                (zip) => ({
                                  value: zip?.Id,
                                  label: `${zip?.Id} ${zip?.Text}`,
                                })
                              )}
                              value={
                                value?.value
                                  ? {
                                      value: value?.value,
                                      label: value?.value,
                                    }
                                  : ""
                              }
                              onChange={(e, ActionType) => {
                                handleBillingZipCode(e, ActionType);
                                onChange(e);
                                clearErrors("bInfo_zipCode");
                                handleInputChange();
                              }}
                            />
                          )}
                        />
                      </Col>
                      <Col
                        xl={3}
                        lg={4}
                        md={6}
                        className="pe-lg-3 ps-lg-0  mb-sm-3 "
                      >
                        <Controller
                          control={control}
                          {...register("bInfo_city")}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <InputField
                              id={"bInfo_city"}
                              value={value}
                              type="text"
                              placeholder="City"
                              maxLength={30}
                              disabled
                              removeOpacity={!isSameDetailAsAboveActive}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("bInfo_city");
                                handleInputChange();
                              }}
                            />
                          )}
                        />
                      </Col>
                    </Row>{" "}
                  </div>
                </div>
                <div className="px-4 pb-4">
                  <Row>
                    <Col
                      xl={4}
                      lg={4}
                      md={6}
                      className="pe-sm-3 mb-lg-0 mb-sm-3 "
                    >
                      <Controller
                        control={control}
                        {...register("invoice_email")}
                        rules={{
                          required: "Email is required",
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Please use a valid email address",
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            id={"invoice_email"}
                            value={value}
                            type="email"
                            placeholder="Invoice e-mail"
                            textTransform="lowercase"
                            errormessage={
                              errors?.email?.type === "pattern"
                                ? errors?.email.message
                                : false
                            }
                            errorscope
                            maxLength={30}
                            iserror={errors?.invoice_email}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("invoice_email");
                              handleInputChange();
                            }}
                          />
                        )}
                      />
                    </Col>
                    <Col
                      xl={4}
                      lg={4}
                      md={6}
                      className=" pe-lg-3 ps-lg-0  mb-sm-3"
                    >
                      <Controller
                        control={control}
                        {...register("attention_person")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <InputField
                            id={"attention_person"}
                            value={value}
                            type="text"
                            placeholder="Attention person"
                            textTransform="capitalize"
                            errorscope
                            maxLength={30}
                            iserror={errors?.attention_person}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("attention_person");
                              handleInputChange();
                            }}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <div className="ps-4 pb-4 light-font-text">
                {watch("billing_method_applicable_id")?.value ? (
                  <>
                    As the billing method is chosen to be at{" "}
                    {watch("billing_method_applicable_id")?.value === "D"
                      ? "Division"
                      : watch("billing_method_applicable_id")?.value === "C"
                      ? "Company"
                      : watch("billing_method_applicable_id")?.value === "P"
                      ? "Property"
                      : "Organisation"}{" "}
                    level, billing details must be filled in at{" "}
                    {watch("billing_method_applicable_id")?.value === "D"
                      ? "Division"
                      : watch("billing_method_applicable_id")?.value === "C"
                      ? "Company"
                      : watch("billing_method_applicable_id")?.value === "P"
                      ? "Property"
                      : "Organisation"}{" "}
                    level
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}

            <div className="p-4 border-top">
              <H3 className="mb-4">Visuals</H3>
              <div className="d-flex align-items-center gap-2 mb-3">
                <H4>Logo</H4>
                <CustomToolTip size="sm" text="Logo" />
              </div>
              {/* <div className="light-font-text pb-1">
                Recommended logo size:{" "}
              </div> */}
              <div className="w-50">
                {/* <DropZone
                  setIsMyFileUploaded={setIsMyFileUploaded}
                  setMyFile={setMyFileForUploadThumbnail}
                  initialFileName={
                    myFileForUploadThumbnail?.length
                      ? myFileForUploadThumbnail?.[0]?.name
                      : myFileForUploadThumbnail?.name
                  }
                /> */}

                <EtImageUploader
                  myFile={thumbnailFile}
                  setMyFile={setThumbnailFile}
                  // onFileUploaded={handleInputChange}
                  onFileUploaded={(file) => {
                    setValue("thumbnail", file?.[0]);
                    handleInputChange(100);
                  }}
                  initialFileName={thumbNailFilename.name}
                  initialFileUrl={thumbNailFilename.url}
                  initialFileSize={thumbNailFilename.fileSize}
                  setThumbnailFileName={setThumbnailFileName}
                />
              </div>
            </div>

            {/*Colors */}
            {/* <div className="p-4">
              <H3 className="mb-3">Colors</H3>
              <div className="form-label-title mb-3">
                Use branding from Organisation
              </div>
              <div className="light-font-text mb-3">
                If you don't want to apply the same system branding as defined
                on the Organisation, turn it off here.
              </div>
              <div className="d-flex align-items-center gap-3 mb-4">
                <Toggle
                  id="use_branding_organisation"
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                />
                <span className="light-font-text16">
                  Use branding from Organisation
                </span>
              </div>

              <div className={checked && "desabled"}>
                <Row>
                  <Col xl={3} lg={4} className="pe-lg-3 mb-sm-3">
                    <div className="d-flex align-items-center  gap-2 pb-2">
                      <H4>Primary color</H4>
                      <CustomToolTip size="sm" text="Primary color" />
                    </div>

                    <Controller
                      name="primaryColor"
                      control={control}
                      {...register("primaryColor")}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <ColorInputField
                          label="Primary color"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleInputChange()

                          }}
                        />
                      )}
                    />
                    {errors?.primaryColor && (
                      <p className="error-msg"> {REQUIRED_ERR}</p>
                    )}
                  </Col>
                  <Col xl={3} lg={4} className="ps-lg-0  ">
                    <div className="d-flex align-items-center  gap-2 pb-2">
                      <H4>Secondary color</H4>
                      <CustomToolTip size="sm" text="Secondary color" />
                    </div>
                    <Controller
                      name="secondaryColor"
                      control={control}
                      {...register("secondaryColor")}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <ColorInputField
                          label="Secondary color"
                          // value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleInputChange()

                          }}
                        // onChange={(e) => setPrimaryColor(e.target.value)}
                        />
                      )}
                    />
                    {errors?.secondaryColor && (
                      <p className="error-msg"> {REQUIRED_ERR}</p>
                    )}
                  </Col>
                </Row>

                <Row className="pt-3">
                  <Col xl={3} lg={4} className="pe-lg-3  mb-sm-3">
                    <div className="d-flex align-items-center  gap-2 pb-2">
                      <H4>Stroke</H4>
                      <CustomToolTip size="sm" text="Stroke" />
                    </div>
                    <Controller
                      name="stroke"
                      control={control}
                      {...register("stroke")}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <ColorInputField
                          label="stroke"
                          // value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleInputChange()

                          }}
                        // onChange={(e) => setPrimaryColor(e.target.value)}
                        />
                      )}
                    />
                    {errors?.stroke && (
                      <p className="error-msg"> {REQUIRED_ERR}</p>
                    )}
                  </Col>
                  <Col xl={3} lg={4} className="ps-lg-0  ">
                    <div className="d-flex align-items-center  gap-2 pb-2">
                      <H4>Progress bar</H4>
                      <CustomToolTip size="sm" text="Progress bar" />
                    </div>
                    <Controller
                      name="progressBar"
                      control={control}
                      {...register("progressBar")}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <ColorInputField
                          label="Progress Bar"
                          // value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleInputChange()

                          }}
                        // onChange={(e) => setPrimaryColor(e.target.value)}
                        />
                      )}
                    />
                    {errors?.progressBar && (
                      <p className="error-msg"> {REQUIRED_ERR}</p>
                    )}
                  </Col>
                </Row>
              </div>
            </div> */}

            <div className="mt-3 py-2 px-3 d-sm-flex justify-content-between footer-section-button-container">
              <div className="m-1">
                {buttonType == "ADD" && (
                  <ETButton
                    variant={"white"}
                    buttonText="CANCEL"
                    onClick={() => {
                      setShowDivisionPage("DL");
                    }}
                  />
                )}
              </div>

              <div className="m-1">
                {buttonType == "ADD" && (
                  <ETButton
                    variant={"primary"}
                    disabled={
                      PostAddDivisionIsLoading || putEditDivisionIsLoading
                    }
                    buttonText={
                      buttonType == "ADD" ? "CREATE DIVISION" : "SAVE"
                    }
                    type={"submit"}
                    // onClick={handleEditDivision}
                  />
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default EditDivisions;
