import React from "react";
import { H3, H4 } from "../../../../styles/Common.style";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { TitleWithDescription } from "../../../../widget/Widget";
import InputField from "../../../../Components/Textfield/InputField";
import { Col, Form, Row } from "react-bootstrap";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import ETButton from "../../../../Components/Buttons/ETButton";
import Toggle from "../../../../Components/Switch/Switch";
import { Controller, useForm } from "react-hook-form";
import { REQUIRED_ERR } from "../../../../utils/validationConstants";

const SenderSetting = () => {
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm({});

  const handleSubmitSenderSettings = (data) => {
    console.log("data", data);
  };

  const fromNameCount = watch("fromName")?.length
    ? watch("fromName")?.length
    : 0;
  const senderNameCount = watch("senderName")?.length
    ? watch("senderName")?.length
    : 0;

  return (
    <Form onSubmit={handleSubmit(handleSubmitSenderSettings)}>
      {/* -------------- main heading------------------- */}
      <div className="px-4 py-2">
        <div className="d-flex gap-2 align-items-center">
          <H3>Sender settings</H3>
          <CustomToolTip text={"Sender settings"} />
        </div>
        <div className="light-font-text pt-2">
          This is the name your email will come from. Use something the
          receivers will instantly recognise like your your company name.
        </div>
      </div>

      {/* --------------- Email defaults ------------------------- */}
      <div className="d-flex gap-2 align-items-center px-4 py-3">
        <H4>Email defaults</H4>
        <CustomToolTip text={"Email defaults"} />
      </div>

      {/* ----------------- Default From name  --------------------- */}
      <div className="px-4 pt-2 ">
        <TitleWithDescription
          titleTextFont="H5"
          titleText={"Default From name"}
          tooltipText={"Default From name"}
          descriptionText={
            "This is the name your email will come from. Use something the receivers will instantly recognise like your your company name."
          }
        />
        <Row className="pt-3">
          <Col xl={4} md={5}>
            <Controller
              control={control}
              {...register("fromName")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <InputField
                  id={"fromName"}
                  type="text"
                  placeholder="From name"
                  errorscope={true}
                  iserror={errors?.fromName}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("fromName");
                  }}
                  maxLength={25}
                  showValueCount={true}
                  //   valueCount={fromNameCount}
                />
              )}
            />
          </Col>
        </Row>
      </div>

      {/* ----------------- From email address  --------------------- */}
      <div className="px-4 pt-4 ">
        <TitleWithDescription
          titleTextFont="H5"
          titleText={"From email address"}
          tooltipText={"From email address"}
          descriptionText={
            "The address info_123@estatetool.dk receives reply emails. Check it regularly to stay in touch with your audience."
          }
        />
        <Row className="pt-3">
          <Col xl={4} md={5}>
            <div className="position-relative">
              <Controller
                control={control}
                {...register("fromEmailAddress")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <InputField
                    id={"fromEmailAddress"}
                    type="email"
                    placeholder="From email address"
                    errorscope={true}
                    iserror={errors?.fromEmailAddress}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      clearErrors("fromEmailAddress");
                    }}
                  />
                )}
              />
              <div
                className=" light-font-text "
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  color: "#888888",
                }}
              >
                @estatetool.io
              </div>
            </div>
          </Col>
        </Row>
      </div>

      {/* ----------------- Reply to email  --------------------- */}
      <div className="px-4 pt-4 ">
        <TitleWithDescription
          titleTextFont="H5"
          titleText={"Reply to email"}
          tooltipText={"Reply to email"}
          descriptionText={
            "The address receives reply emails. Check it regularly to stay in touch with your audience."
          }
        />
        <Row className="pt-3">
          <Col xl={4} md={5}>
            <Controller
              control={control}
              {...register("replyToEmail")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <InputField
                  id={"replyToEmail"}
                  type="email"
                  placeholder="Reply to email"
                  errorscope={true}
                  iserror={errors?.replyToEmail}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("replyToEmail");
                  }}
                />
              )}
            />
          </Col>
        </Row>
      </div>

      {/* ----------------- Branding template  --------------------- */}
      <div className="p-4 border-bottom">
        <TitleWithDescription
          titleTextFont="H5"
          titleText={"Branding template"}
          tooltipText={"Branding template"}
          descriptionText={
            "The address receives reply emails. Check it regularly to stay in touch with your audience."
          }
        />
        <Row className="pt-3">
          <Col xl={5} md={6}>
            <Controller
              control={control}
              {...register("brandingTemplate")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => {
                return (
                  <ReactSelect
                    placeholder={"Branding template"}
                    clearable={false}
                    onChange={(e, ActionType) => {
                      onChange(e);
                      clearErrors("brandingTemplate");
                    }}
                  />
                );
              }}
            />

            {errors?.brandingTemplate && (
              <p className="error-msg ">{REQUIRED_ERR}</p>
            )}
          </Col>
        </Row>
      </div>

      {/* -----------------------SMS------------------------ */}
      <div className="d-flex gap-2 align-items-center px-4 py-3">
        <H4>SMS</H4>
        <CustomToolTip text={"SMS"} />
      </div>

      {/* -------------------- SMS notifications -------------------------- */}
      <div className="p-4 border-bottom">
        <TitleWithDescription
          titleTextFont="H5"
          titleText={"SMS notifications"}
          tooltipText={"SMS notifications"}
          descriptionText={
            "You can enable text message notifications for your customer here."
          }
        />

        <div className="d-flex align-items-center gap-2 pt-2 light-font-text">
          <div style={{ marginLeft: "-11px" }}>
            <Toggle id="EnableSMS" />
          </div>
          Enable SMS notifications
        </div>
      </div>

      {/* ----------------- SMS sender name --------------------- */}
      <div className="p-4 border-bottom">
        <TitleWithDescription
          titleTextFont="H5"
          titleText={"SMS sender name"}
          tooltipText={"SMS sender name"}
          descriptionText={
            "Customise the name that will be shown on the text messages that will be shown."
          }
        />
        <Row className="pt-3">
          <Col xl={4} md={5}>
            <Controller
              control={control}
              {...register("senderName")}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <InputField
                  id={"senderName"}
                  type="text"
                  placeholder="Sender name"
                  errorscope={true}
                  value={value}
                  iserror={errors?.senderName}
                  onChange={(e) => {
                    onChange(e);
                    clearErrors("senderName");
                  }}
                  maxLength={25}
                  showValueCount={true}
                />
              )}
            />
          </Col>
        </Row>
      </div>
      <div className="py-2 px-3 d-flex flex-row justify-content-end  footer-section-button-container">
        <ETButton variant={"primary"} buttonText="NEXT" type={"submit"} />
      </div>
    </Form>
  );
};

export default SenderSetting;
