import React, { useMemo, useState } from "react";

import { Col, Row } from "react-bootstrap";
import DropDownMui from "../../../Components/DropdownModal/DropdownModalMui";
import { MaterialReactTable } from "material-react-table";

import Menus from "../../../Components/Menus/Menus";
import CheckBox from "../../../Components/Checkbox/Checkbox";
import CustomPopup from "../../../Components/CustomPopup";
import ETButton from "../../../Components/Buttons/ETButton";
import { GreenSpan } from "../../../styles/Common.style";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import NoDataScreen from "../../../Components/DisplayNoData/NoDataScreen";
import MRTTheme from "../../../Components/table/MRTTheme";
import { NoRecordsToDisplay } from "../../../utils/validationConstants";
import { SortIcon } from "../../../Components/table/MRTGlobalProps";

function Signings() {
  const [showSeeDetails, setShowSeeDetails] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);

  const [remind, setRemind] = useState(false);

  const [showRemindAction, setShowRemindAction] = useState(false);

  const [openCancelSigningOrder, setOpenCancelSigningOrder] = useState(false);
  const [openEditSigningOrder, setOpenEditSigningOrder] = useState(false);
  const [openSigner, setOpenSigner] = useState(false);

  const SigningsColumns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.status} `,

        Cell: ({ row, header }) => (
          <>
            <div className="d-flex justify-content-center">
              <div
                onClick={() => setOpenStatus(true)}
                className="radius-4 px-4 py-1 600 cursor-pointer"
                style={{
                  backgroundColor: "rgba(246, 230, 81, 1)",
                  height: "27px",
                }}
              >
                {row.original.Status}
              </div>
            </div>
          </>
        ),
        header: "Status",
        size: 50,
        enableSorting: true,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorFn: (row) => `${row.Signers} `,

        Cell: ({ row, header }) => (
          <>
            <div className="d-flex align-items-center">
              <div className="fw-300 fs-13">
                {row.original.Signers}
                &nbsp;&nbsp;(
                <GreenSpan
                  className=" nowrap cursor-pointer"
                  onClick={() => setOpenSigner(true)}
                >
                  show more
                </GreenSpan>
                )
              </div>
            </div>
          </>
        ),
        header: "Signers",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },

      {
        accessorKey: "Created",
        header: "Created",
        enableSorting: true,
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "Expires",
        header: "Expires",
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "Createdby",
        header: "Createdby",
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorFn: (row) => `${row.status} `,

        Cell: ({ row, header }) => (
          <>
            <div>
              <div className="fw-600 fs-13">{row.original.unit1}</div>
              <div className="fw-300 fs-13">{row.original.unit2}</div>
            </div>
          </>
        ),
        header: "Unit",
        size: 80,
      },
    ],
    []
  );

  const signingsData = [
    {
      Status: "1/3",
      Signers: "Stig Werner Isaksen Klaus Bødker Udsen",
      Created: "Mar 8, 2022",
      Expires: "Apr 8, 2022",
      Createdby: "Thomas Johannesen",
      unit1: "Fuglebakkevej 88",
      unit2: "Fuglebakkevej 88C 1. th8 - 860W - 1",
    },
    {
      Status: "1/3",
      Signers: "Stig Werner Isaksen Klaus Bødker Udsen",
      Created: "Mar 8, 2022",
      Expires: "Apr 8, 2022",
      Createdby: "Thomas Johannesen",
      unit1: "Fuglebakkevej 88",
      unit2: "Fuglebakkevej 88C 1. th8 - 860W - 1",
    },
    {
      Status: "1/3",
      Signers: "Stig Werner Isaksen Klaus Bødker Udsen",
      Created: "Mar 8, 2022",
      Expires: "Apr 8, 2022",
      Createdby: "Thomas Johannesen",
      unit1: "Fuglebakkevej 88",
      unit2: "Fuglebakkevej 88C 1. th8 - 860W - 1",
    },
    {
      Status: "1/3",
      Signers: "Stig Werner Isaksen Klaus Bødker Udsen",
      Created: "Mar 8, 2022",
      Expires: "Apr 8, 2022",
      Createdby: "Thomas Johannesen",
      unit1: "Fuglebakkevej 88",
      unit2: "Fuglebakkevej 88C 1. th8 - 860W - 1",
    },
    {
      Status: "1/3",
      Signers: "Stig Werner Isaksen Klaus Bødker Udsen",
      Created: "Mar 8, 2022",
      Expires: "Apr 8, 2022",
      Createdby: "Thomas Johannesen",
      unit1: "Fuglebakkevej 88",
      unit2: "Fuglebakkevej 88C 1. th8 - 860W - 1",
    },
  ];

  const EditSigningColumns = useMemo(() => [
    {
      accessorKey: "status",
      header: "Status",
      enableSorting: false,

      size: 50,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      accessorFn: (row) => `${row.status} `,

      Cell: ({ row, header }) => (
        <>
          <div className="form-input ">
            <input type="text" placeholder="Name" id="fName" />
            <label htmlFor="fName">Name</label>
          </div>
        </>
      ),
      header: "Name",
      size: 150,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorFn: (row) => `${row.status} `,

      Cell: ({ row, header }) => (
        <>
          <div className="form-input ">
            <input type="text" placeholder="Name" id="email" />
            <label htmlFor="fName">Email</label>
          </div>
        </>
      ),
      header: "Email",
      size: 150,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorFn: (row) => `${row.status} `,

      Cell: ({ row, header }) => (
        <>
          <ReactSelect
            placeholder="Actor"
            // value={value}
            // clearable={false}
            // isSearchable
            // onChange={(e) => {
            //   onChange(e);
            // }}
            // error={errors.zip}
            // options={repeat?.map((event) => ({
            //   value: event?.id,
            //   label: `${event?.value}`,
            // }))}
          />
        </>
      ),
      header: "Actor",
      size: 150,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "recievedOn",
      header: "Received on",
      size: 50,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "dateOfSignin",
      header: "Date of signing",
      size: 50,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "lastReminder",
      header: "Last reminder",
      size: 50,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
  ]);

  const EditSigningData = [
    {
      status: "Pending",
      recievedOn: "Sep 22, 09:12",
      dateOfSignin: "Sep 23, 10:14",
      lastReminder: "Sep 26, 12:05",
    },
    {
      status: "Pending",
      recievedOn: "Sep 22, 09:12",
      dateOfSignin: "Sep 23, 10:14",
      lastReminder: "Sep 26, 12:05",
    },
    {
      status: "Pending",
      recievedOn: "Sep 22, 09:12",
      dateOfSignin: "Sep 23, 10:14",
      lastReminder: "Sep 26, 12:05",
    },
    {
      status: "Pending",
      recievedOn: "Sep 22, 09:12",
      dateOfSignin: "Sep 23, 10:14",
      lastReminder: "Sep 26, 12:05",
    },
    {
      status: "Pending",
      recievedOn: "Sep 22, 09:12",
      dateOfSignin: "Sep 23, 10:14",
      lastReminder: "Sep 26, 12:05",
    },
    {
      status: "Pending",
      recievedOn: "Sep 22, 09:12",
      dateOfSignin: "Sep 23, 10:14",
      lastReminder: "Sep 26, 12:05",
    },
    {
      status: "Pending",
      recievedOn: "Sep 22, 09:12",
      dateOfSignin: "Sep 23, 10:14",
      lastReminder: "Sep 26, 12:05",
    },
    {
      status: "Pending",
      recievedOn: "Sep 22, 09:12",
      dateOfSignin: "Sep 23, 10:14",
      lastReminder: "Sep 26, 12:05",
    },
    {
      status: "Pending",
      recievedOn: "Sep 22, 09:12",
      dateOfSignin: "Sep 23, 10:14",
      lastReminder: "Sep 26, 12:05",
    },
    {
      status: "Pending",
      recievedOn: "Sep 22, 09:12",
      dateOfSignin: "Sep 23, 10:14",
      lastReminder: "Sep 26, 12:05",
    },
    {
      status: "Pending",
      recievedOn: "Sep 22, 09:12",
      dateOfSignin: "Sep 23, 10:14",
      lastReminder: "Sep 26, 12:05",
    },
    {
      status: "Pending",
      recievedOn: "Sep 22, 09:12",
      dateOfSignin: "Sep 23, 10:14",
      lastReminder: "Sep 26, 12:05",
    },
    {
      status: "Pending",
      recievedOn: "Sep 22, 09:12",
      dateOfSignin: "Sep 23, 10:14",
      lastReminder: "Sep 26, 12:05",
    },
    {
      status: "Pending",
      recievedOn: "Sep 22, 09:12",
      dateOfSignin: "Sep 23, 10:14",
      lastReminder: "Sep 26, 12:05",
    },
  ];

  //   signing status Popup

  const SigningOrderPopupColumns = useMemo(
    () => [
      {
        accessorKey: "Status",
        header: "Status",
        size: 50,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 130,
      },
      {
        accessorKey: "actor",
        header: "Actor",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "signingdate",
        header: "Date of signing",
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "lastreminder",
        header: "Last reminder",
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
    ],
    []
  );

  const SigningsOrderPopupData = [
    {
      Status: "Done",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "Last reminder",
    },
    {
      Status: "Done",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "Last reminder",
    },
    {
      Status: "Done",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "Last reminder",
    },
    {
      Status: "Done",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "Last reminder",
    },
    {
      Status: "Done",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "Last reminder",
    },
    {
      Status: "Done",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "Last reminder",
    },
    {
      Status: "Done",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "Last reminder",
    },
    {
      Status: "Pending",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "Last reminder",
    },
    {
      Status: "Done",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "Last reminder",
    },
    {
      Status: "Pending",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "Last reminder",
    },
    {
      Status: "Pending",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "Last reminder",
    },
    {
      Status: "Pending",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "Last reminder",
    },
    {
      Status: "Pending",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "Last reminder",
    },
  ];

  // Signers (show) POpup
  // SignersOrderPopupData

  const SignersOrderPopupColumns = useMemo(
    () => [
      {
        accessorKey: "Status",
        header: "Status",
        size: 50,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 130,
      },
      {
        accessorKey: "actor",
        header: "Actor",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "signingdate",
        header: "Date of signing",
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "lastreminder",
        header: "Last reminder",
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
    ],
    []
  );

  const SignersOrderPopupData = [
    {
      Status: "Done",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "Last reminder",
    },
    {
      Status: "Done",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "Last reminder",
    },
    {
      Status: "Pending",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "Last reminder",
    },
    {
      Status: "Done",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "Last reminder",
    },
    {
      Status: "Pending",
      name: "Stig Werner Isaksen",
      actor: "Tenant",
      signingdate: "Sep 23, 10:14",
      lastreminder: "Last reminder",
    },
  ];

  // columns={SigningOrderPopupColumns}
  // data={SigningsOrderPopupData}

  return (
    <div className=" radius-8 p-4 containerStyle">
      <Row className="pb-4">
        <Col>
          <DropDownMui
            text="Status"
            width={"250px"}
            component={
              <div className="p-2" style={{ height: "50px" }}>
                {NoRecordsToDisplay}
                {/* <div className="py-2 ps-2" style={{ paddingRight: "120px" }}>
                  <div className="d-flex align-items-center fw-300 fs-14 ">
                    <CheckBox /> Cancelled
                  </div>
                  <div className="d-flex align-items-center fw-300 fs-14 ">
                    <CheckBox /> In process
                  </div>
                  <div className="ms-4">
                    <div className="d-flex align-items-center fw-300 fs-14 ">
                      <CheckBox /> Group 1
                    </div>
                    <div className="d-flex align-items-center fw-300 fs-14 ">
                      <CheckBox /> Group 2
                    </div>
                    <div className="d-flex align-items-center fw-300 fs-14 ">
                      <CheckBox /> Group 3
                    </div>
                    <div className="d-flex align-items-center fw-300 fs-14 ">
                      <CheckBox /> Group 4
                    </div>
                    <div className="d-flex align-items-center fw-300 fs-14 ">
                      <CheckBox /> Group 5
                    </div>
                    <div className="d-flex align-items-center fw-300 fs-14 ">
                      <CheckBox /> Group 6
                    </div>
                  </div>
                  <div className="d-flex align-items-center fw-300 fs-14 ">
                    <CheckBox /> Completed
                  </div>
                </div> */}
              </div>
            }
          />
        </Col>
        <Col>
          <DropDownMui
            text="Created by"
            width={"250px"}
            component={
              <div
                className="py-2 ps-2 pe-5 scrollable-container-y me-1"
                style={{ height: "50px" }}
              >
                {NoRecordsToDisplay}
                {/* <div className="d-flex align-items-center fw-300 fs-14 ">
                  <CheckBox /> All
                </div>
                <div className="ms-4">
                  <div className="d-flex align-items-center fw-300 fs-14 ">
                    <CheckBox /> Nicolaj Eriksen
                  </div>
                  <div className="d-flex align-items-center fw-300 fs-14 ">
                    <CheckBox /> User 1
                  </div>
                  <div className="d-flex align-items-center fw-300 fs-14 ">
                    <CheckBox /> User 2
                  </div>
                  <div className="d-flex align-items-center fw-300 fs-14 ">
                    <CheckBox /> User 3
                  </div>
                  <div className="d-flex align-items-center fw-300 fs-14 ">
                    <CheckBox /> User 4
                  </div>
                  <div className="d-flex align-items-center fw-300 fs-14 ">
                    <CheckBox /> User 5
                  </div>
                  <div className="d-flex align-items-center fw-300 fs-14 ">
                    <CheckBox /> User 6
                  </div>
                  <div className="d-flex align-items-center fw-300 fs-14 ">
                    <CheckBox /> User 7
                  </div>
                </div> */}
              </div>
            }
          />
        </Col>
        <Col sm={6}></Col>
      </Row>
      <div style={{ backgroundColor: "#fff" }} className="radius-4">
        {signingsData.length != 0 ? (
          <Row>
            <Col>
              <MRTTheme
                colorVariantsForTableData={["odd"]}
                table={
                  <MaterialReactTable
                    columns={SigningsColumns}
                    // data={signingsData}
                    data={[]}
                    icons={{ ...SortIcon }}
                    localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
                    enableStickyHeader
                    enableBottomToolbar={false}
                    enableSorting={true}
                    enableColumnActions={false}
                    enablePagination={false}
                    renderTopToolbar={false}
                    enableRowActions
                    positionActionsColumn="last"
                    muiTableContainerProps={{
                      sx: {
                        paddingX: "22px",
                        paddingY: "22px",
                      },
                    }}
                    displayColumnDefOptions={{
                      "mrt-row-actions": {
                        size: 10,
                        header: "",
                      },
                      "mrt-row-select": {
                        header: "",
                      },
                    }}
                    renderRowActions={({ row }) => (
                      <div className="d-flex justify-content-end pe-3">
                        <Menus
                          itemheight={30}
                          itemwidth={30}
                          iconborderradius="4px"
                          itemcontainerwidth="180px"
                          component={
                            <>
                              <div style={{ fontSize: "14px" }}>
                                <div
                                  className="addpropContent px-3 py-2 "
                                  onClick={() => setOpenEditSigningOrder(true)}
                                >
                                  <span className="ms-1">
                                    Edit signing order
                                  </span>
                                </div>
                                <hr className="addpropertyHr m-0"></hr>
                                <div
                                  className="addpropContent px-3 pt-2 pb-2"
                                  onClick={() =>
                                    setOpenCancelSigningOrder(true)
                                  }
                                >
                                  <span className="ms-1">
                                    Cancel signing order
                                  </span>
                                </div>
                              </div>
                            </>
                          }
                        />
                      </div>
                    )}
                  />
                }
              />
            </Col>
          </Row>
        ) : (
          <NoDataScreen height={"377px"} imgHeight={"150px"} />
        )}
      </div>

      <Row>
        <Col>
          <CustomPopup
            open={openStatus}
            onHide={() => setOpenStatus(false)}
            title={<div className="fs-14 form-label-title">Signing order</div>}
            titleTooltipText="Signing order"
            title_color="black"
            titleFont="14px"
            titleTooltip={true}
            enableCrossButton={true}
            size="lg"
            onCloseAction={() => {
              setShowSeeDetails(false);
              alert("Action Peformed Successfuly.");
            }}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={true}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
          >
            <MRTTheme
              colorVariantsForTableData={["odd"]}
              table={
                <MaterialReactTable
                  columns={SigningOrderPopupColumns}
                  data={SigningsOrderPopupData}
                  icons={{ ...SortIcon }}
                  enableBottomToolbar={true}
                  enableSorting={false}
                  enableStickyHeader
                  enableColumnActions={false}
                  enablePagination={false}
                  renderTopToolbar={false}
                  enableRowActions
                  positionActionsColumn="last"
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      size: 10,
                      header: "",
                    },
                    "mrt-row-select": {
                      header: "",
                    },
                  }}
                  // muiTableContainerProps={{
                  //   sx: {
                  //     paddingX: "24px",
                  //     maxHeight: "300px",
                  //     paddingY: "24px",
                  //   },
                  // }}
                  renderRowActions={({ row }) => (
                    <div className="d-flex justify-content-end pe-3">
                      <button
                        onClick={() => {
                          setRemind(true);
                          setOpenStatus(false);
                        }}
                        style={{
                          opacity: "inherit",
                          whiteSpace: "nowrap",
                          backgroundColor: "#619130",
                          color: "#fff",
                          alignItems: "center",
                          display: "flex",
                          gap: "7px",
                          fontFamily: "Cerebri sans",
                          fontWeight: "600",
                          border: "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          textAlign: "center",
                          padding: "5px 25px",
                          fontSize: "13px",
                        }}
                      >
                        REMIND
                      </button>
                    </div>
                  )}
                  muiTableContainerProps={{
                    elevation: 0,
                    sx: {
                      padding: "0px",
                      maxHeight: "300px",
                    },
                  }}
                  muiTablePaperProps={{
                    sx: {
                      paddingLeft: "1rem",
                    },
                  }}
                  // muiTablePaperProps={{
                  //   elevation: 0,
                  //   sx: {
                  //     fontFamily: "Cerebri sans !important",
                  //     borderRadius: "0px 0px 8px 8px ",
                  //     border: "none",
                  //     background: "#F9FAFA",
                  //     fontWeight: "300",
                  //     ".css-1f2qhs8": {
                  //       minWidth: "auto",
                  //       marginRight: "10px",
                  //     },
                  //     th: {
                  //       height: "50px",
                  //       padding: "0.5rem",
                  //       background: "#FFFFFF",
                  //       paddingLeft: "1rem",
                  //       fontFamily: "Cerebri sans !important",
                  //     },
                  //     td: {
                  //       height: "50px",
                  //       padding: "0.5rem",
                  //       fontWeight: "300",
                  //       fontSize: "13px",
                  //       paddingLeft: "1rem",
                  //       fontFamily: "Cerebri sans !important",
                  //     },
                  //     tr: {
                  //       borderRadius: "4px",
                  //       border: "2px solid red !important",
                  //       fontFamily: "Cerebri sans !important",
                  //     },
                  //     ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
                  //       display: "none", // pagination space removing from page
                  //     },
                  //   },
                  // }}
                  // muiTableBodyProps={{
                  //   sx: {
                  //     fontFamily: "Cerebri sans !important",
                  //     "& tr:nth-of-type(odd)": {
                  //       backgroundColor: "#F9FAFA",
                  //       borderRadius: "8px",
                  //       fontWeight: "300",
                  //     },
                  //   },
                  // }}
                  // muiTableContainerProps={{
                  //   elevation: 0,
                  //   sx: {
                  //     background: "#FFFFFF",
                  //     paddingX: "24px",
                  //     maxHeight: "300px",
                  //     paddingY: "24px",
                  //     borderRadius: "0px 0px 8px 8px ",

                  //     boxShadow: "none",
                  //     ".css-vv34gr-MuiTableRow-root": {
                  //       boxShadow: "unset !important",
                  //     },
                  //   },
                  // }}
                  // muiTableHeadCellProps={{
                  //   //simple styling with the `sx` prop, works just like a style prop in this example
                  //   sx: {
                  //     fontWeight: "700",
                  //     fontSize: "13px",
                  //     fontFamily: "Cerebri sans !important",
                  //   },
                  // }}
                />
              }
            />
          </CustomPopup>
        </Col>
      </Row>
      <div>
        <CustomPopup
          title={
            <div className="fs-18 form-label-title">
              Are you sure you want to cancel this order?
            </div>
          }
          titleTooltip="false"
          open={openCancelSigningOrder}
          onHide={() => setOpenCancelSigningOrder(false)}
          size="md"
          onCloseAction={() => {
            setOpenCancelSigningOrder(false);
            alert("Action Peformed Successfuly.");
          }}
        >
          <div className="px-4 py-3">
            <div style={{ backgroundColor: "#f4f5f5" }} className="radius-6">
              <Row className="px-4 py-3">
                <Col lg={5}>
                  <div className="actors-label-title">Kaktus Towers</div>
                  <div className="actors-lite-text">Dybbølsbro 3</div>
                  <div className="actors-lite-text">7-NW320</div>
                </Col>
                <Col>
                  <div className="actors-lite-text">Nikolas Karpermur</div>
                  <div className="actors-lite-text">Sarah Strong</div>
                  <div className="actors-lite-text">
                    +1 (
                    <GreenSpan className=" actors-lite-text fs-12 cursor-pointer">
                      show more
                    </GreenSpan>
                    )
                  </div>
                </Col>
              </Row>
            </div>
            <div className="actors-label-title fs-14 pt-4 pb-2">
              Attach a comment to the cancellation
            </div>
            <div>
              <div className="form-input">
                <textarea
                  className="actor-signing-package-textarea w-100 px-3"
                  style={{
                    resize: "none",
                    border: "1px solid #D1d1d1",
                    borderRadius: "4px",
                    height: "122px",
                  }}
                ></textarea>
              </div>
              <div className="actors-lite-text fs-10 blackColor pb-3">
                Remember: Write clearly so others can understand what your
                comment is about
              </div>
              <div className="grey-text actors-lite-text pb-4 ">
                Kasper Nikolajsen cancel this order Sep 13, 2022
              </div>
            </div>
          </div>
          <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container ">
            <ETButton
              variant={"white"}
              buttonText="NO"
              width="70px"
              onClick={() => setOpenCancelSigningOrder(false)}
            />
            <ETButton
              variant={"primary"}
              buttonText="YES"
              width="70px"
              onClick={() => setOpenCancelSigningOrder(false)}
            />
          </div>
        </CustomPopup>

        {/* Remind Popup */}
        <CustomPopup
          open={remind}
          onHide={() => setRemind(false)}
          title_color="black"
          headerRequired={false}
        >
          <div className="p-4">
            <div className="p-2">
              <div className="form-label-title fs-18 my-1">
                Are you sure that you will remind?
              </div>
              <div className="pb-4 pt-2">
                <div className="form-label-title  pb-1">Michael Rasmussen</div>
                <div className="light-font-text">Developer</div>
              </div>
            </div>
          </div>
          <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container ">
            <ETButton
              variant={"white"}
              buttonText="NO"
              width="70px"
              onClick={() => setRemind(false)}
            />
            <ETButton
              variant={"primary"}
              buttonText="YES"
              width="70px"
              onClick={() => setRemind(false)}
            />
          </div>
        </CustomPopup>
        <CustomPopup
          title={
            <div
              className="d-flex justify-content-between"
              style={{ gap: "260px" }}
            >
              <div className="fs-14 form-label-title">
                Fuglebakkevej 88C 1.th
              </div>
              <div className="fs-14 form-label-title grey-text">
                Fuglebakkevej 88 | 8 - 860W - 1
              </div>
            </div>
          }
          titleTooltipText="Signing order"
          title_color="black"
          titleFont="14px"
          titleTooltip={false}
          enableCrossButton={true}
          size="xl"
          open={openEditSigningOrder}
          onHide={() => setOpenEditSigningOrder(false)}
          onCloseAction={() => {
            setOpenEditSigningOrder(false);
            alert("Action Peformed Successfuly.");
          }}
        >
          <div className="p-4">
            <div
              className="px-4 py-3 radius-4"
              style={{
                backgroundColor: "rgba(97, 145, 48, 0.08)",
                border: "1px solid rgba(97, 145, 48, 0.16)",
              }}
            >
              <Row>
                <Col>
                  <div>
                    <div className="form-label-title fs-12 grey-text pb-2 ps-2">
                      Status
                    </div>
                    <div
                      onClick={() => setOpenStatus(true)}
                      className="radius-4 px-3 py-1 font-medium fs-12"
                      style={{
                        backgroundColor: "rgba(246, 230, 81, 1)",
                        height: "24px",
                        width: "57px",
                      }}
                    >
                      1/3
                    </div>
                  </div>
                </Col>
                <Col>
                  <div>
                    <div className="form-label-title fs-12 grey-text pb-2">
                      Created
                    </div>
                    <div className="light-font-text fs-13">Mar 8, 2022</div>
                  </div>
                </Col>
                <Col>
                  <div>
                    <div className="form-label-title fs-12 grey-text pb-2">
                      Expires
                    </div>
                    <div className="light-font-text fs-13">Apr 8, 2022</div>
                  </div>
                </Col>
                <Col>
                  <div>
                    <div className="form-label-title fs-12 grey-text pb-2">
                      Created by
                    </div>
                    <div className="light-font-text fs-13">
                      Thomas Johannesen
                    </div>
                  </div>
                </Col>
                <Col>
                  <div>
                    <div className="form-label-title fs-12 grey-text pb-2">
                      Reminder
                    </div>
                    <div className="light-font-text fs-13">Mar 28, 2022</div>
                  </div>
                </Col>
                <Col>
                  <div>
                    <div className="form-label-title fs-12 grey-text pb-2">
                      Interval
                    </div>
                    <div className="light-font-text fs-13">3 days</div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="pt-4">
              <MRTTheme
                colorVariantsForTableData={["odd"]}
                table={
                  <MaterialReactTable
                    columns={EditSigningColumns}
                    data={EditSigningData}
                    icons={{ ...SortIcon }}
                    enableStickyHeader
                    enableBottomToolbar={false}
                    enableSorting={true}
                    enableColumnActions={false}
                    enablePagination={false}
                    renderTopToolbar={false}
                    enableRowActions
                    positionActionsColumn="last"
                    displayColumnDefOptions={{
                      "mrt-row-actions": {
                        size: 10,
                        header: "",
                      },
                      "mrt-row-select": {
                        header: "",
                      },
                    }}
                    // muiTableContainerProps={{
                    //   sx: {
                    //     maxHeight: "262px",
                    //   },
                    // }}
                    renderRowActions={({ row }) => (
                      <div className="d-flex justify-content-end pe-3">
                        <Menus
                          itemheight={30}
                          itemwidth={30}
                          iconborderradius="4px"
                          itemcontainerwidth="180px"
                          component={
                            <>
                              <div style={{ fontSize: "14px" }}>
                                <div className="addpropContent px-3 py-2 ">
                                  <span className="ms-1">Remind</span>
                                </div>
                                <hr className="addpropertyHr m-0"></hr>
                                <div className="addpropContent px-3 pt-2 pb-2">
                                  <span className="ms-1">Delete</span>
                                </div>
                              </div>
                            </>
                          }
                        />
                      </div>
                    )}
                    muiTableContainerProps={{
                      sx: {
                        padding: "0px !important",
                      },
                    }}
                    // muiTablePaperProps={{
                    //   elevation: 0,
                    //   sx: {
                    //     fontFamily: "Cerebri sans !important",
                    //     borderRadius: "0px 0px 8px 8px ",
                    //     border: "none",
                    //     background: "#F9FAFA",
                    //     fontWeight: "300",
                    //     ".css-1f2qhs8": {
                    //       minWidth: "auto",
                    //       marginRight: "10px",
                    //     },
                    //     th: {
                    //       height: "50px",
                    //       background: "#FFFFFF",
                    //       paddingLeft: "1.5rem",
                    //       fontFamily: "Cerebri sans !important",
                    //     },
                    //     td: {
                    //       height: "50px",
                    //       // padding: "0.5rem",
                    //       fontWeight: "300",
                    //       fontSize: "13px",
                    //       paddingLeft: "1rem",
                    //       fontFamily: "Cerebri sans !important",
                    //     },
                    //     tr: {
                    //       borderRadius: "4px",
                    //       border: "2px solid red !important",
                    //       fontFamily: "Cerebri sans !important",
                    //     },
                    //     ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
                    //       display: "none", // pagination space removing from page
                    //     },
                    //   },
                    // }}
                    // muiTableBodyProps={{
                    //   sx: {
                    //     fontFamily: "Cerebri sans !important",
                    //     "& tr:nth-of-type(odd)": {
                    //       backgroundColor: "#F9FAFA",
                    //       borderRadius: "8px",
                    //       fontWeight: "300",
                    //     },
                    //   },
                    // }}
                    // muiTableContainerProps={{
                    //   elevation: 0,
                    //   sx: {
                    //     background: "#FFFFFF",
                    //     // paddingX: "22px",
                    //     maxHeight: "262px",
                    //     // paddingY: "22px",
                    //     borderRadius: "0px 0px 8px 8px ",

                    //     boxShadow: "none",
                    //     ".css-vv34gr-MuiTableRow-root": {
                    //       boxShadow: "unset !important",
                    //     },
                    //   },
                    // }}
                    // muiTableHeadCellProps={{
                    //   //simple styling with the `sx` prop, works just like a style prop in this example
                    //   sx: {
                    //     fontWeight: "700",
                    //     fontSize: "13px",
                    //     fontFamily: "Cerebri sans !important",
                    //   },
                    // }}
                  />
                }
              />
            </div>
          </div>
          <div
            className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
            style={{
              backgroundColor: "#F9FAFA",
              borderRadius: " 0px 0px 8px 8px",
              border: "1px solid #F0F0F0",
            }}
          >
            <div></div>
            <div className="d-flex gap-3">
              <ETButton variant={"primary"} buttonText="Update" />
            </div>
          </div>
        </CustomPopup>
        <Row>
          <Col>
            <CustomPopup
              open={openSigner}
              onHide={() => setOpenSigner(false)}
              title={<div className="fw-600 fs-14">Signers Details</div>}
              titleTooltipText="Signers Details"
              title_color="black"
              titleFont="14px"
              titleTooltip={true}
              enableCrossButton={true}
              size="lg"
              onCloseAction={() => {
                setOpenSigner(false);
                alert("Action Peformed Successfuly.");
              }}
              action_button_label="YES"
              action_button_label_color="white"
              action_button_color="#619130"
              cancel_button={true}
              cancel_button_bordercolor="grey"
              cancel_button_label="NO"
              cancel_button_label_color="black"
              cancel_button_color="#fff"
              footer_color={"#F9FAFA"}
            >
              <div className="mt-3">
                <MaterialReactTable
                  columns={SigningOrderPopupColumns}
                  data={SigningsOrderPopupData}
                  icons={{ ...SortIcon }}
                  enableBottomToolbar={true}
                  enableSorting={false}
                  enableColumnActions={false}
                  enableStickyHeader
                  enablePagination={false}
                  renderTopToolbar={false}
                  muiTablePaperProps={{
                    elevation: 0,
                    sx: {
                      fontFamily: "Cerebri sans !important",
                      borderRadius: "0px 0px 8px 8px ",
                      border: "none",
                      background: "#F9FAFA",
                      fontWeight: "300",
                      ".css-1f2qhs8": {
                        minWidth: "auto",
                        marginRight: "10px",
                      },
                      th: {
                        height: "50px",
                        padding: "1.5rem",
                        background: "#FFFFFF",
                        paddingLeft: "1rem",
                        fontFamily: "Cerebri sans !important",
                      },
                      td: {
                        height: "50px",
                        padding: "0.5rem",
                        fontWeight: "300",
                        fontSize: "13px",
                        paddingLeft: "1rem",
                        fontFamily: "Cerebri sans !important",
                      },
                      tr: {
                        borderRadius: "4px",
                        border: "2px solid red !important",
                        fontFamily: "Cerebri sans !important",
                      },
                      ".MuiToolbar-dense.css-g2tram-MuiToolbar-root": {
                        display: "none", // pagination space removing from page
                      },
                    },
                  }}
                  muiTableBodyProps={{
                    sx: {
                      fontFamily: "Cerebri sans !important",
                      "& tr:nth-of-type(odd)": {
                        backgroundColor: "#F9FAFA",
                        borderRadius: "8px",
                        fontWeight: "300",
                      },
                    },
                  }}
                  muiTableContainerProps={{
                    elevation: 0,
                    sx: {
                      maxHeight: "550px",
                      background: "#FFFFFF",
                      paddingX: "24px",
                      paddingBottom: "24px",
                      borderRadius: "0px 0px 8px 8px ",

                      boxShadow: "none",
                      ".css-vv34gr-MuiTableRow-root": {
                        boxShadow: "unset !important",
                      },
                    },
                  }}
                  muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                      fontWeight: "700",
                      fontSize: "13px",
                      fontFamily: "Cerebri sans !important",
                    },
                  }}
                />
              </div>
            </CustomPopup>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Signings;
