import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Button as MUIButton,
  Menu as MUIMenu,
  MenuItem as MUIMenuItem,
} from "@mui/material";
import { H1, H2 } from "../../styles/Common.style";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import SuperEditor from "@ckeditor/ckeditor5-build-classic";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import moment from "moment";
import CustomToolTip from "../../Components/Tooltip/CustomTooltip";
import CustomPopup from "../../Components/CustomPopup";
import ETButton from "../../Components/Buttons/ETButton";
import { useGetTextCategory } from "../../hooks/queries/useUnitQueries";
import { useGetlanguages } from "../../hooks/queries/uselanguageQueries";
import { GetTagsByPropertyId } from "../../services/Tags";
import {
  GetTextByTextId,
  PutUpdateTextCompleteDetails,
} from "../../services/Texts";
import AddEditPackage from "../properties/units/AddEditPackage/AddEditPackage";
import { usePropertyOverview } from "../../hooks/stores/usePropertyStore";
import { useRouting } from "../../hooks/stores/useRoutingStore";
import { useAddEditPackage } from "../../hooks/stores/usePackageStore";
import { status } from "../../model/Constant";
import { GreenSpan, PrimaryBreadcrums } from "../../styles/Common.style";
import CustomCheckBox from "../../Components/Checkbox/Checkbox";
import { CustomersTab } from "../../Components/Tabs/CustomersTabs.style";
import {
  useGetTextSubCategory,
  usePostAddText,
  useUpdateTextBasicDetails,
  useUpdateTextCompleteDetails,
} from "../../hooks/queries/useTextQueries";
import { Link } from "react-router-dom";

const CreateNewTextField = ({}) => {
  //--------States--------------//
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteEditor, setDeleteEditor] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [pageNo, setPageNo] = useState(0);

  const [rotateAddLanguage, setRotateAddLanguage] = useState(false);
  const rotate = rotateAddLanguage ? "rotate(45deg)" : "rotate(0deg)";

  // const [active, setActive] = useState(true);
  // const [textFieldTitle, setTextFieldTitle] = useState("");
  const [textCategory, setTextCategory] = useState("");
  const [subTextCategory, setSubTextCategory] = useState("");
  // const [tagRowData, setTagRowData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [scrollId, setScrollId] = useState("");
  const [addSettingPage, setAddSettingPage] = useState(false);
  const [flagUpdateText, setFlagUpdateText] = useState(false);
  const [textbyproperty, setTextByProperty] = useState([]);

  // const [cancelPopup, setCancelPopup] = useState(false);

  // state used in addEditPackage checkbox logic //
  // const [selected, setSelected] = useState([]);
  // const [selectedData, setSelectedData] = useState([]);
  //--------------------zustand-------------------------//
  const { propOverview } = usePropertyOverview((state) => state);
  const propertyId = propOverview[0]?.property_id;
  const {
    setShowPackage,
    editflag,
    packageId,
    setPackageId,
    packageType: textType,
  } = useRouting((state) => state);

  const { data: GetTextSubCategoryData } = useGetTextSubCategory();
  const {
    title,
    setTitle,
    active,
    setActive,
    tagRowData,
    setTagRowData,
    setSelected,
    setSelectedData,
    cancelPopup,
    setCancelPopup,
    packageType,
  } = useAddEditPackage((state) => state);

  //--------------react Query----------------//
  const { data: textCategoryData } = useGetTextCategory();
  const { data: getlanguagesData } = useGetlanguages();
  const { data: getTextByTextIdData } = useQuery(
    ["getTextByTextId", propertyId, packageId],
    () => GetTextByTextId(propertyId, packageId),
    { enabled: !!editflag }
  );
  const { data: tagsByPropertyIdData } = useQuery(
    ["propertyId", propertyId],
    () => GetTagsByPropertyId(propertyId),
    {
      enabled: !!editflag,
    }
  );
  const { mutateAsync: PostAddText, isLoading: PostAddTextIsLoading } =
    usePostAddText();
  const {
    mutateAsync: PutUpdateTextBasicDetails,
    isLoading: PutUpdateTextBasicDetailsIsLoading,
  } = useUpdateTextBasicDetails();
  const {
    mutateAsync: PutUpdateTextCompleteDetails,
    isLoading: PutUpdateTextCompleteDetailsIsLoading,
  } = useUpdateTextCompleteDetails();

  // Function to generate a random number within a range
  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Generate a unique random ID using a combination of lang_id and random number
  function generateUniqueID(langID) {
    const randomNumber = getRandomNumber(1000, 9999); // Adjust the range as needed
    return `${langID}D${randomNumber}`;
  }
  SuperEditor.defaultConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "insertTable",
        "|",
        "undo",
        "redo",
      ],
    },
    image: {
      toolbar: [
        "imageStyle:full",
        "imageStyle:side",
        "|",
        "imageTextAlternative",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    language: "en",
  };

  useEffect(() => {
    if (editflag) {
      setTitle(getTextByTextIdData?.data[0]?.internal_title);
      setTextByProperty(getTextByTextIdData?.data);

      setTextCategory(getTextByTextIdData?.data[0]?.category_id);
      setSubTextCategory(getTextByTextIdData?.data[0]?.text_sub_category_id);
      setLanguageData(getTextByTextIdData?.data[0]?.language_values_json);
      setActive(getTextByTextIdData?.data[0]?.is_active == 1 ? true : false);
      const filteredData = getTextByTextIdData?.data[0]?.tags_id?.split(",");
      const tagData = tagsByPropertyIdData?.data?.filter((el) =>
        filteredData?.includes(el.tag_id?.toString())
      );
      setTagRowData(tagData);
      setSelected(filteredData);
      setSelectedData(tagData);
    } else {
      setTitle("");
      setTextCategory("");
      setLanguageData([
        {
          lang_id: "en",
          text_value: "",
          language_name: "English",
          is_default: 1,
        },
      ]);
      setActive(true);
      setTagRowData([]);
      setSelected([]);
      setSelectedData([]);
    }
  }, [getTextByTextIdData, tagsByPropertyIdData]);

  useEffect(() => {
    let element = document.getElementById(scrollId);
    setTimeout(() => {
      element?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 200);
  }, [scrollId]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setRotateAddLanguage(!rotateAddLanguage);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setRotateAddLanguage("");
  };

  //-------------handle text editor change----------------//
  const handleChange = (i, langData, data) => {
    let newLanguageText = [...languageData];
    newLanguageText[i] = {
      lang_id: langData?.lang_id,
      text_value: data,
      description: langData?.description,
      language_name: langData?.language_name || langData?.Text,
      is_default: langData?.is_default,
    };
    setLanguageData(newLanguageText);
  };
  // ------------handle description change-------------------//
  const handleChangeDescription = (i, langData, data) => {
    let newLanguageText = [...languageData];
    newLanguageText[i] = {
      lang_id: langData?.lang_id,
      text_value: langData?.text_value,
      language_name: langData?.language_name || langData?.Text,
      description: data,
      is_default: langData?.is_default,
    };
    setLanguageData(newLanguageText);
  };
  //-------------handle delete language field------------//
  const handleDeleteLanguageField = () => {
    let newArray = languageData.filter((item) => item?.lang_id !== deleteId);
    if (newArray?.length > 0) {
      setLanguageData((prev) =>
        prev.map((val, index) => {
          if (val?.lang_id === newArray?.[0]?.lang_id) {
            prev[index].is_default = 1;
          }
          return val;
        })
      );
    }
    setLanguageData(newArray);
    setDeleteEditor(false);
  };

  //-------------- add / update text package page 2-------------//
  const handleAddTextBasicDetails = async () => {
    if (editflag || flagUpdateText) {
      setCancelPopup(false);
      const putEditTextData = {
        package_id: packageId,
        text_category_id: textCategory?.toString(),
        text_sub_category_id:
          textCategory == 3 ? subTextCategory?.toString() : "0",
        package_name: String(title),

        is_active: active ? "1" : "0",
        tags: tagRowData?.map((item) => {
          return item.tag_id;
        }),
      };
      const response = await PutUpdateTextBasicDetails(putEditTextData);
      if (response.status === status.s && response.data.bReturn) {
        toast.success(response.data.message);
        setFlagUpdateText(false);
        setAddSettingPage(true);
      } else {
        toast.info(response.data.message);
      }
    } else {
      const postAddTextData = {
        text_category_id: textCategory?.toString(),
        text_sub_category_id:
          textCategory == 3 ? subTextCategory?.toString() : "0",
        package_name: title?.toString(),

        property_id: propOverview[0]?.property_id?.toString(),
        is_active: active ? "1" : "0",
        tags: tagRowData?.map((item) => {
          return item.tag_id;
        }),
      };
      PostAddText(postAddTextData)
        .then((response) => {
          if (response?.status === status.s && response?.data?.bReturn) {
            toast.success(response?.data?.message);
            setPackageId(response?.data?.data?.text_package_id);
            setAddSettingPage(true);
          } else {
            toast.info(response?.data?.message);
          }
        })
        .catch((error) => {
          toast.error(error?.message);
        });
    }
  };

  //---------------add text package page 1--------------//
  const handleAddTextField = async () => {
    const payloadCompleteDetailsData = {
      package_id: packageId,
      text_category_id: textCategory?.toString(),
      text_sub_category_id:
        textCategory == 3 ? subTextCategory?.toString() : "0",
      package_name: title,
      is_active: active ? "1" : "0",
      tags: tagRowData?.map((item) => {
        return item.tag_id;
      }),
      values: languageData,
    };

    PutUpdateTextCompleteDetails(payloadCompleteDetailsData)
      .then((response) => {
        if (response?.status === status.s && response?.data?.bReturn) {
          toast.success(response?.data?.message);
          setShowPackage(false);
        } else {
          toast.info(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  //----------------select language from drop down--------------//
  const handleLanguageClick = (data) => {
    if (languageData.filter((el) => el?.lang_id == data?.Id).length > 0) {
      toast.error("Language already exist!");
      let element = document?.getElementById(scrollId);
      setTimeout(() => {
        element?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 200);
    } else {
      if (languageData?.length <= 0) {
        setLanguageData([
          ...languageData,
          {
            lang_id: data?.Id,
            text_value: "",
            language_name: data?.Text,
            is_default: 1,
          },
        ]);
      } else {
        setLanguageData([
          ...languageData,
          {
            lang_id: data?.Id,
            text_value: "",
            language_name: data?.Text,
            is_default: 0,
          },
        ]);
      }
    }
  };

  //----------------------handle make default---------------//
  // const handleMakeDefault = (langData, i) => {
  //   if (languageData?.filter((item) => item?.is_default === 1)?.length > 0) {
  //     setLanguageData((prev) =>
  //       prev.map((val, index) => {
  //         if (val?.is_default === 1) {
  //           prev[index].is_default = 0;
  //         }
  //         return val;
  //       })
  //     );
  //   }
  //   setLanguageData((prev) =>
  //     prev.map((val, index) => {
  //       if (index === i) {
  //         prev[index].is_default = 1;
  //       }
  //       return val;
  //     })
  //   );
  // };
  const handleMakeDefault = (langData, i) => {
    if (languageData?.filter((item) => item?.is_default === 1)?.length > 0) {
      setLanguageData((prev) =>
        prev.map((val, index) => {
          if (val?.is_default === 1) {
            prev[index].is_default = 0;
          }
          return val;
        })
      );
    }
    setLanguageData((prev) =>
      prev.map((val, index) => {
        if (index === i) {
          prev[index].is_default = 1;
        }
        return val;
      })
    );
  };

  const modifiedData = textbyproperty?.map((item) => {
    return {
      ...item,
      modifiedOn: moment(item.modified_on).format("DD.MMMM.YYYY | HH:mm:ss"),
      createdOn: moment(item.created_on).format("DD.MMMM.YYYY | HH:mm:ss"),
    };
  });

  return (
    <>
      <Container fluid className="pt-3 px-0 ">
        <H1>{editflag ? "Edit" : "Create new"} package</H1>
        <div className="light-font-text" style={{ cursor: "pointer" }}>
          <Link
            to="/properties"
            style={{ textDecoration: "none", color: "black" }}
          >
            <span className="me-3">Property</span>
          </Link>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />
          </span>
          <Link
            to="/property-units/overview"
            state={{
              Tab: "Overview",
            }}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span className="me-3">{propOverview?.[0]?.propertyName}</span>
          </Link>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <span className="me-3" onClick={() => setShowPackage(false)}>
            Texts
          </span>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <span className="me-3">
            {editflag ? "Edit" : "Create new"} package
          </span>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <PrimaryBreadcrums mode={!addSettingPage} className="me-3">
            1. Settings
          </PrimaryBreadcrums>
          {addSettingPage && (
            <>
              <span className="mx-1">
                <FontAwesomeIcon
                  size="sm"
                  icon="fa-regular fa-angle-right"
                  style={{ color: "#000000" }}
                />{" "}
              </span>
              <PrimaryBreadcrums mode={addSettingPage} className="mx-1">
                2. {editflag ? "Edit" : "Add"} text field
              </PrimaryBreadcrums>
            </>
          )}
        </div>
      </Container>
      <div className="mt-3 pb-3 px-4 containerStyle">
        <div
          className="d-flex gap-2 pt-4 pb-2 mb-1"
          style={{ alignItems: "center", textAlign: "center" }}
        >
          <div>
            <CustomersTab
              mode={!addSettingPage}
              // className={
              //   !addSettingPage
              //     ? "px-2 facility-tabs-active"
              //     : "facility-tabs px-2"
              // }
            >
              {addSettingPage && (
                <FontAwesomeIcon
                  icon="fa-solid fa-circle-check"
                  className="me-2"
                  style={{ color: "#77C7A1", fontSize: "0.9rem" }}
                />
              )}
              1. Settings
            </CustomersTab>
          </div>
          <div style={{ alignItems: "center", textAlign: "center" }}>
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />
          </div>
          <div>
            <CustomersTab
              mode={addSettingPage}
              // className={
              //   addSettingPage === true
              //     ? "px-2 facility-tabs-active"
              //     : "facility-tabs px-2"
              // }
            >
              2. {editflag ? "Edit" : "Add"} text field
            </CustomersTab>
          </div>
        </div>

        <div>
          {!addSettingPage ? (
            <AddEditPackage
              modifiedData={modifiedData}
              packageType={textType}
              // title={textFieldTitle}
              // setTitle={setTextFieldTitle}
              dropDownData={textCategoryData?.data}
              subDropDownData={GetTextSubCategoryData?.data}
              dropDownValue={textCategory}
              setDropDownValue={setTextCategory}
              subTextCategory={subTextCategory}
              setSubTextCategory={setSubTextCategory}
              subDropDownValue={subTextCategory}
              setSubDropDownValue={setSubTextCategory}
              disabledDropDownValue={
                !languageData?.every((el) => el?.text_value !== "") ||
                (languageData?.length === 0 ? true : false)
                  ? false
                  : true
              }
              // tagRowData={tagRowData}
              // setTagRowData={setTagRowData}
              // active={active}
              // setActive={setActive}
              // cancelPackage={setCancelPopup}
              handleNext={handleAddTextBasicDetails}
              setPageNo={setPageNo}
              showActiveInactive={true}
              showDropdown={true}
              // isEditPackage={isEditTextField}
              propertyId={propertyId}
              disableNextButton={
                PostAddTextIsLoading || PutUpdateTextBasicDetailsIsLoading
              }
              // selected={selected}
              // setSelected={setSelected}
              // selectedData={selectedData}
              // setSelectedData={setSelectedData}
            />
          ) : (
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                fontSize: "14px",
                // paddingBottom: "66px",
              }}
            >
              <div
                className="px-3 pb-3"
                style={{ borderBottom: "1px solid #F0F0F0" }}
              >
                <div className="px-2 pt-3 d-flex">
                  <H2>2. {editflag ? "Edit" : "Add"} text field</H2>

                  <div style={{ paddingLeft: "15px" }}>
                    {" "}
                    <CustomToolTip
                      height={12}
                      text={editflag ? "Edit text field" : "Add text field"}
                    />{" "}
                  </div>
                </div>

                <div className="px-2 created-by">
                  {editflag ? (
                    `Last updated by ${
                      getTextByTextIdData?.data[0]?.modified_by
                    }, ${moment(
                      getTextByTextIdData?.data[0]?.modified_on
                    ).format("DD. MMM. YYYY| HH:mm:ss")} `
                  ) : (
                    <>
                      {getTextByTextIdData?.data[0]?.created_by &&
                      getTextByTextIdData?.data[0]?.created_on ? (
                        <>
                          `Created by $
                          {getTextByTextIdData?.data[0]?.created_by}, $
                          {moment(
                            getTextByTextIdData?.data[0]?.created_on
                          ).format("DD. MMM. YYYY | HH:mm:ss")}
                          `
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  {/* <FontAwesomeIcon icon="fa-regular fa-user" /> */}
                </div>
              </div>
              <div className="px-3 pb-2 pt-4 mt-2">
                <div className="d-flex justify-content-between mb-5">
                  <div className="px-2 ">
                    <div className=" d-flex form-label-title">Text fields</div>
                    <div className="light-font-text grey-text">
                      Lorem ipsum dolor sit pluarelis oct
                    </div>
                  </div>
                  <div>
                    {/* <MUIButton
                      disableTouchRipple
                      sx={{
                        color: "#ffff",
                        background: "#619130",
                        width: "227px",
                        height: "44px",
                        borderRadius: "8px",
                        fontWeight: "600",
                        fontFamily: "Cerebri Sans",
                        fontSize: "14px",
                        "&:hover": {
                          backgroundColor: "#619130",
                          boxShadow: "none",
                        },
                        "& .MuiButton-startIcon": {
                          rotate: anchorEl !== null ? "45deg" : "inherit",
                          transition: "all 200ms ease-in-out",
                        },
                      }}
                      startIcon={
                        <FontAwesomeIcon
                          icon="fa-regular fa-plus"
                          style={{ color: "#ffffff" }}
                        />
                      }
                      id="demo-positioned-button"
                      aria-controls={open ? "demo-positioned-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      ADD Language VERSION
                    </MUIButton> */}
                    <ETButton
                      variant={"primary"}
                      buttonText="ADD LANGUAGE VERSION"
                      rotate={rotate}
                      icon={true}
                      onClick={handleClick}
                      width="auto"
                    />

                    <MUIMenu
                      sx={{
                        paddingTop: "0px !important",
                        marginTop: "5px",

                        "& .MuiPaper-root": {
                          border: "1px solid #F0F0F0",
                          borderRadius: "8px",
                          width: "227px",
                          backgroundColor: "#FFFFFF",
                        },

                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: "white",
                        },
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: "#f9fafa",
                        },
                        "& .MuiMenuItem-root.Mui-selected:hover": {
                          backgroundColor: "#f9fafa",
                        },
                      }}
                      elevation={0}
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      {getlanguagesData?.data?.map((data, index) => (
                        <MUIMenuItem
                          key={index}
                          value={data?.Id}
                          onClick={() => {
                            handleClose();
                            handleLanguageClick(data);
                            setScrollId(data?.Id);
                          }}
                        >
                          <div className="d-flex">
                            <div>
                              <img
                                src={data?.DataValue1}
                                width="17px"
                                height="12px"
                                className="me-1"
                              />
                            </div>
                            <div
                              style={{
                                marginLeft: "10px",
                                paddingTop: "1px",
                                fontSize: "14px",
                                fontFamily: "Cerebri sans-light",
                              }}
                            >
                              {data?.Text}
                            </div>
                          </div>
                        </MUIMenuItem>
                      ))}
                    </MUIMenu>
                  </div>
                </div>

                {languageData.length == 0 ? (
                  <div
                    className="grey-tex fs-12 light-font-text"
                    style={{ textAlign: "center" }}
                  >
                    {" "}
                    No text added yet.Click on “+ ADD Language VERSION ” to
                    create your first.
                  </div>
                ) : (
                  <>
                    {languageData?.map((data, index) => (
                      <div
                        key={index}
                        id={data?.lang_id}
                        style={{ position: "relative" }}
                      >
                        <Row className="mt-5 mb-3">
                          <Col>
                            <div className="d-flex align-items-center ps-2 gap-2">
                              <span>
                                <img
                                  style={{ borderRadius: "3px", width: "17px" }}
                                  src={
                                    getlanguagesData?.data?.filter(
                                      (id) => id?.Id === data?.lang_id
                                    )?.[0]?.DataValue1
                                  }
                                />
                              </span>
                              <div
                                className="me-2 form-label-title"
                                style={{ marginTop: "3px" }}
                              >
                                {data?.Text || data?.language_name}
                              </div>
                              <div>{/* <Englandflag /> */}</div>
                            </div>
                            <div
                              className="d-flex"
                              style={{
                                position: "absolute",
                                top: "39px",
                                zIndex: "1",
                                right: "15px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "12px",
                              }}
                            >
                              <div className="d-flex justify-content-between align-items-start me-3">
                                <CustomCheckBox
                                  checked={
                                    data?.is_default === 1 ? true : false
                                  }
                                  onChange={(e) => {
                                    handleMakeDefault(data, index);
                                  }}
                                  label={"Default Text"}
                                />
                                {/* <div>
                                  {data?.is_default === 1
                                    ? "Default"
                                    : "Default"}
                                </div> */}
                              </div>

                              <button
                                onClick={() => {
                                  setDeleteEditor(true);
                                  setDeleteId(data?.lang_id);
                                }}
                                disabled={languageData?.length === 1 && true}
                                style={{
                                  background: "white",
                                  border: "1px solid #D1D1D1",
                                  borderRadius: "4px",
                                  padding: "1px 7px 0px 7px",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon="fa-regular fa-trash"
                                  style={{
                                    color: "#000000",
                                    opacity:
                                      languageData?.length === 1
                                        ? "50%"
                                        : "inherit",
                                  }}
                                />
                              </button>
                            </div>
                          </Col>
                        </Row>

                        <Row className="">
                          <Col className="px-3">
                            <div>
                              <div className="">
                                <CKEditor
                                  key={index}
                                  id={data?.lang_id}
                                  onReady={(editor) => {
                                    // editor.focus();
                                    editor.editing.view.focus();

                                    // editor.setIdToScroll(data?.Id);
                                  }}
                                  focusedElement={true}
                                  editor={SuperEditor}
                                  data={data?.description}
                                  onChange={(event, editor) => {
                                    handleChangeDescription(
                                      index,
                                      data,
                                      editor?.getData()
                                    );
                                  }}
                                />
                              </div>
                              <div className="mt-2">
                                <h3 className="me-2 form-label-title">
                                  Description :
                                </h3>

                                <CKEditor
                                  key={index}
                                  // id={generateUniqueID(data?.lang_id)}
                                  id={data?.lang_id}
                                  onReady={(editor) => {
                                    // editor.focus();
                                    editor?.editing?.view?.focus();

                                    // editor.setIdToScroll(data?.Id);
                                  }}
                                  // focusedElement={false}
                                  editor={SuperEditor}
                                  data={data?.text_value}
                                  onChange={(event, editor) => {
                                    handleChange(
                                      index,
                                      data,
                                      editor?.getData()
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </>
                )}
              </div>

              <CustomPopup
                // modalHeadingStyle={modalHeadingStyle}
                style={{ position: "relative" }}
                size="md"
                open={deleteEditor}
                onHide={() => setDeleteEditor(false)}
                title="Delete text field"
                title_color="black"
                // titleTooltipText="Need Help About Tags"
                // titleTooltip={true}
                enableCrossButton={false}
                onCloseAction={() => {
                  setDeleteEditor(false);
                }}
                action_button_label="YES"
                action_button_label_color="white"
                action_button_color="#619130"
                cancel_button={true}
                cancel_button_bordercolor="grey"
                cancel_button_label="NO"
                cancel_button_label_color="black"
                cancel_button_color="#fff"
                footer_color={"#F9FAFA"}
              >
                <div className="p-4">
                  <div
                    style={{
                      fontSize: "14px",
                      fontFamily: "Cerebri sans-light",
                      textAlign: "left",
                      lineHeight: "17.5px",
                      height: "54px",
                      //   margin: "24px 64px 24px 24px"
                    }}
                  >
                    <div>Are you sure you want to delete this text field? </div>
                  </div>
                </div>

                <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
                  <ETButton
                    variant={"white"}
                    buttonText="CANCEL"
                    onClick={() => setDeleteEditor(false)}
                  />

                  <ETButton
                    variant={"primary"}
                    buttonText="DELETE"
                    onClick={() => {
                      handleDeleteLanguageField();
                    }}
                  />
                </div>
              </CustomPopup>
              <div
                className="py-2 mt-3 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
                style={{
                  backgroundColor: "#F9FAFA",
                  borderRadius: " 0px 0px 8px 8px",
                  border: "1px solid #F0F0F0",
                }}
              >
                <ETButton
                  variant={"white"}
                  buttonText="CANCEL"
                  fontSize="14px"
                  width="fit-content"
                  onClick={() => setCancelPopup(true)}
                />

                <div className="d-flex gap-2">
                  <ETButton
                    variant={"white"}
                    buttonText="PREVIOUS"
                    width="fit-content"
                    onClick={() => {
                      setAddSettingPage(false);
                      setPageNo(0);
                      setFlagUpdateText(true);
                    }}
                  />

                  {!editflag ? (
                    <ETButton
                      disabled={
                        !languageData?.every((el) => el?.text_value !== "") ||
                        (languageData?.length === 0 ? true : false) ||
                        PutUpdateTextCompleteDetailsIsLoading
                      }
                      variant={"primary"}
                      buttonText={"CREATE PACKAGE"}
                      width="fit-content"
                      onClick={handleAddTextField}
                      // onClick={() => {
                      //   // setSelectedTabIndex(1);
                      //   // setSelectedTab("2. Upload");

                      // }}
                    />
                  ) : (
                    <ETButton
                      disabled={
                        !languageData?.every((el) => el?.text_value !== "") ||
                        (languageData?.length === 0 ? true : false) ||
                        PutUpdateTextCompleteDetailsIsLoading
                      }
                      variant={"primary"}
                      buttonText={"SAVE AND CLOSE"}
                      width="fit-content"
                      onClick={handleAddTextField}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <CustomPopup
        style={{ position: "relative" }}
        size="md"
        open={cancelPopup}
        onHide={() => setCancelPopup(false)}
        title="Are you sure you want to cancel?"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() => {
          setCancelPopup(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div>
            <div
              style={{
                fontSize: "14px",
                fontFamily: "Cerebri Sans",
                fontWeight: "500",
                textAlign: "left",
                lineHeight: "17.5px",
              }}
            >
              Cancelling will undo all your changes
            </div>
          </div>
        </div>

        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="NO"
            onClick={() => {
              setCancelPopup(false);
            }}
          />

          <ETButton
            variant={"primary"}
            buttonText="YES"
            // onClick={() => setShowCreateNewTextField(false)}
            onClick={() => {
              setShowPackage(false);
              setCancelPopup(false);
            }}
          />
        </div>
      </CustomPopup>
    </>
  );
};

export default CreateNewTextField;
