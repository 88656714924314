import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../actorcustomers.css";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ProcessTimeline } from "./ProcessTimeline";
import Select from "react-select";
import ReservatationDetails from "../processpopups/ReservatationDetails";
import CustomPopup from "../../../../Components/CustomPopup";
import ScreeningProcessDetails from "../processpopups/ScreeningProcessDetails";
import DepositDetails from "../processpopups/DepositDetails";
import MovingInDetails from "../processpopups/MovingInDetails";
import TerminationDetails from "../processpopups/TerminationDetails";
import MovingOutDetails from "../processpopups/MovingOutDetails";
import RentalContract from "../processpopups/RentalContract";
import LeasedDetails from "../processpopups/LeasedDetails";
import { useGetTenancyOverview } from "../../../../hooks/queries/useSigningQueries";
import moment from "moment";
import { useGetActorByTenancyId } from "../../../../hooks/queries/useActorQueries";
import { GetNotesDetails } from "../../../../services/Notes";
import { useQuery } from "react-query";
import InfiniteScroll from "react-infinite-scroll-component";
import Countdown, { zeroPad } from "react-countdown";
import EmptyMessage from "../../../../utils/EmptyMessage";
import { useBreadcrumb } from "../../../../Components/BreadcrumbContext ";
import { UTCtoLocalTimeZone } from "../../../../utils/UTCtoLocalTimeZone";
import { useUserDetails } from "../../../../hooks/stores/useUserDetailsStore";

const ActivitiesTabs = [
  { id: 1, text: "All" },
  { id: 2, text: "Screening" },
  { id: 3, text: "Communication" },
  { id: 4, text: "Documents" },
  { id: 5, text: "Notes" },
];
const ActivitiesInfo = [
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
  {
    msg: "Automatic request was sent to tenant regarding more information",
    user: "USER",
    date: "Dec 11, 2022 at 16:12",
  },
];
const CommunicationData = [
  {
    id: "1",
    head: "MISSING INFORMATION",
    name: "Jesper Thorkildsen",
    date: "2 Dec at 10:12",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ipsum dolor sit amet, consectetur adipiscing elit...",
    subjet: "ECONOMY",
    notification: 1,
  },
  {
    id: "2",
    head: "DATE OF MOVING IN",
    name: "Max Wildersans Brentford",
    date: "27 Nov at 9:06",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
  },
  {
    id: "3",
    head: "DATE OF MOVING IN",
    name: "Max Wildersans Brentford",
    date: "27 Nov at 9:06",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
  },
  {
    id: "4",
    head: "DATE OF MOVING IN",
    name: "Max Wildersans Brentford",
    date: "27 Nov at 9:06",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
  },

  {
    id: "5",
    head: "DATE OF MOVING IN",
    name: "Max Wildersans Brentford",
    date: "27 Nov at 9:06",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor m dolor sit amet, consectetur adipiscing eli...",
    subjet: "MOVING-IN",
  },
  {
    id: "6",
    head: "MISSING INFORMATION",
    name: "Jesper Thorkildsen",
    date: "2 Dec at 10:12",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ipsum dolor sit amet, consectetur adipiscing elit...",
    subjet: "ECONOMY",
  },
];

const options = [
  { label: "All", value: 1 },
  { label: "Screening", value: 2 },
  { label: "Communication", value: 3 },
  { label: "Documents", value: 4 },
  { label: "Notes", value: 5 },
];

const Overview = ({
  actorByContractIdData,
  contractOverViewData,
  contractId,
  usersByDivisionIdData,
  contractByUnitIdData,
  contractOverViewDataRefetch,
  contractByUnitIdDataRefetch,
  setSelectedTab,
  selectedName,
  initialSelectedName,
  tanentNameTab,
}) => {
  //--------------------States-----------------------//
  const [reservationPopup, setReservationPopup] = useState(false);
  const [ScreeningprocessPopup, setScreeningprocessPopup] = useState(false);
  const [rentalContractPopup, setRentalContractPopup] = useState(false);
  const [depositPopup, setDepositPopup] = useState(false);
  const [leasedPopup, setLeasedPopup] = useState(false);
  const [moveInPopup, setMoveInPopup] = useState(false);
  const [terminationPopup, setTerminationPopup] = useState(false);
  const [moveOutPopup, setMoveOutPopup] = useState(false);

  //-----------------States for notes infinite scroll-----------//
  const [notesData, setNotesData] = useState([]);
  const [notesCount, setNotesCount] = useState(5);

  const { updateBreadcrumbs } = useBreadcrumb();

  const { userDetails } = useUserDetails((state) => state);

  const { convertedDate: reservation_created } = UTCtoLocalTimeZone({
    timestamp: contractOverViewData?.data?.[0]?.reservation_created,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD MMM, YYYY [at] HH:mm:ss",
  });

  const { convertedDate: reservation_deadline } = UTCtoLocalTimeZone({
    timestamp: contractOverViewData?.data?.[0]?.reservation_deadline,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD MMM, YYYY [at] HH:mm:ss",
  });

  const { convertedDate: date_of_rental } = UTCtoLocalTimeZone({
    timestamp: contractOverViewData?.data?.[0]?.date_of_rental,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD MMM, YYYY [at] HH:mm",
  });

  const { convertedDate: moving_in_date } = UTCtoLocalTimeZone({
    timestamp: contractOverViewData?.data?.[0]?.moving_in_date,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD MMM, YYYY [at] HH:mm:ss",
  });

  const { convertedDate: termination_date } = UTCtoLocalTimeZone({
    timestamp: contractOverViewData?.data?.[0]?.termination_date,
    offset_mins: userDetails?.timezone_offset_mins,
    timeFormat: "DD MMM, YYYY [at] HH:mm:ss",
  });

  useEffect(() => {
    updateBreadcrumbs(["Contracts", initialSelectedName, "Overview"]);
  }, [initialSelectedName]);

  useEffect(() => {
    if (tanentNameTab) {
      updateBreadcrumbs(["Contracts", selectedName, "Overview"]);
    } else {
    }
  }, [selectedName]);
  //----------------React query----------------//
  const { data: getNotesDetailsData, refetch: getNotesDetailsDataRefetch } =
    useQuery(
      ["getNotesDetails", contractId, 0],
      () => GetNotesDetails(contractId, 0, 0, 0),
      {
        enabled: !!contractId,
      }
    );

  useEffect(() => {
    setNotesData(getNotesDetailsData?.data);

    return () => {};
  }, [getNotesDetailsData]);

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: "none",

      fontSize: "13px",
      fontWeight: "300",
      fontFamily: "Cerebri sans",
      backgroundColor: "transperant",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: "#fff",
      zIndex: 1,
      color: "#000",
      fontSize: "13px",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 3,
      width: 150,
    }),
  };

  //----------React infinite scroll for notes-------------//
  const fetchMoreNotesData = async () => {
    setTimeout(async () => {
      let newArr = notesData.concat(
        getNotesDetailsData?.data?.slice(notesCount, notesCount + 5)
      );
      setNotesData(newArr);
      setNotesCount(notesCount + 5);
    }, 100);
  };

  // console.log(contractOverViewData);
  // const renderer = ({ days, hours, minutes, seconds, completed }) => {
  //   if (completed) {
  //     // Render a complete state
  //     return <>Expired</>;
  //   } else {
  //     // Render a countdown
  //     return (
  //       <span>
  //         {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
  //       </span>
  //     );
  //   }
  // };
  return (
    <Container
      fluid
      className="px-4"
    >
      <Row>
        <Col
          xl={4}
          className="py-3"
        >
          <div
            className="d-flex justify-content-center align-items-center  radius-8"
            style={{
              backgroundColor: contractOverViewData?.data?.[0]?.bg_color,
              border: `1px solid ${contractOverViewData?.data?.[0]?.bg_color}`,
              height: "75px",
            }}
          >
            <span
              className="form-label-title fs-24"
              style={{
                textTransform: "uppercase",
                color: contractOverViewData?.data?.[0]?.text_color,
              }}
            >
              {contractOverViewData?.data?.[0]?.status_name}
            </span>
          </div>

          <div
            className="radius-8 mt-4 p-3 scrollable-container-y customers-card-layout"
            style={{
              height: "575px",
              overflowY: "scroll",
            }}
          >
            <div className="actors-section-title">Important dates</div>

            <div className="px-2 py-1 mt-2 bg-white radius-4 fs-13 d-flex justify-content-between">
              <span>Reservation created</span>
              <span className="light-font-text fs-13">
                {contractOverViewData?.data?.[0]?.reservation_created !== ""
                  ? reservation_created
                  : "-"}
              </span>
            </div>

            <div className="px-2 py-1 mt-2 bg-white radius-4  fs-13 d-flex justify-content-between">
              <span>Reservation deadline</span>
              <span className="light-font-text fs-13 ">
                {contractOverViewData?.data?.[0]?.reservation_deadline !==
                  "" && (
                  <span className="cursor-pointer">
                    <FontAwesomeIcon
                      icon="fa-regular fa-arrow-up-right-from-square"
                      style={{
                        color: "#000000",
                        width: "12px",
                        height: "12px",
                      }}
                      className="pe-1"
                      onClick={() => setReservationPopup(true)}
                    />
                  </span>
                )}
                {contractOverViewData?.data?.[0]?.reservation_deadline !== ""
                  ? reservation_deadline
                  : "-"}
              </span>

              {/* -------Reservation details popup------- */}
              <CustomPopup
                open={reservationPopup}
                onHide={() => setReservationPopup(false)}
                title="Reservation details"
                title_color="black"
                titleFont="14px"
                titleTooltip={true}
                enableCrossButton={false}
                deadline={true}
                deadlineTime={
                  <Countdown
                    date={moment(
                      contractOverViewData?.data?.[0]?.reservation_deadline
                    ).valueOf()}
                    daysInHours
                    zeroPadTime={2}
                  />
                }
                titleTooltipText="Reservation details"
                size="lg"
                onCloseAction={() => {
                  setReservationPopup(false);
                  alert("Action Peformed Successfuly.");
                }}
                action_button_label="YES"
                action_button_label_color="white"
                action_button_color="#619130"
                cancel_button={true}
                cancel_button_bordercolor="grey"
                cancel_button_label="NO"
                cancel_button_label_color="black"
                cancel_button_color="#fff"
                footer_color={"#F9FAFA"}
              >
                <ReservatationDetails
                  setReservationPopup={setReservationPopup}
                  contractOverViewData={contractOverViewData}
                  actorByContractIdData={actorByContractIdData}
                  usersByDivisionIdData={usersByDivisionIdData}
                  contractByUnitIdData={contractByUnitIdData}
                  contractOverViewDataRefetch={contractOverViewDataRefetch}
                  contractByUnitIdDataRefetch={contractByUnitIdDataRefetch}
                />
              </CustomPopup>
            </div>

            <div className="px-2 py-1 mt-3 bg-white radius-4 fs-13 d-flex justify-content-between">
              <span>Screening started</span>
              <span className="light-font-text fs-13">
                {contractOverViewData?.data?.[0]?.screening_started !== ""
                  ? moment(
                      contractOverViewData?.data?.[0]?.screening_started
                    ).format("DD MMM, YYYY [at] HH:mm:ss")
                  : "-"}
              </span>
            </div>

            <div className="px-2 py-1 mt-2 bg-white radius-4  fs-13 d-flex justify-content-between">
              <span className="">Screening deadline</span>
              <span className="light-font-text fs-13 ">
                {contractOverViewData?.data?.[0]?.screening_deadline !==
                (
                  <span className="cursor-pointer">
                    <FontAwesomeIcon
                      icon="fa-regular fa-arrow-up-right-from-square"
                      style={{
                        color: "#000000",
                        width: "12px",
                        height: "12px",
                      }}
                      className="pe-1"
                      onClick={() => setScreeningprocessPopup(true)}
                    />
                  </span>
                )}
                {contractOverViewData?.data?.[0]?.screening_deadline !== ""
                  ? moment(
                      contractOverViewData?.data?.[0]?.screening_deadline
                    ).format("DD MMM, YYYY [at] HH:mm:ss")
                  : "-"}
              </span>

              {/* ------------Screening process popup------------- */}
              <CustomPopup
                open={ScreeningprocessPopup}
                onHide={() => setScreeningprocessPopup(false)}
                title="Screening process details"
                title_color="black"
                titleFont="14px"
                titleTooltip={true}
                enableCrossButton={false}
                deadline={true}
                deadlineTime={"00:00:00"}
                titleTooltipText="Screening process details"
                size="lg"
                onCloseAction={() => {
                  setScreeningprocessPopup(false);
                }}
                action_button_label="YES"
                action_button_label_color="white"
                action_button_color="#619130"
                cancel_button={true}
                cancel_button_bordercolor="grey"
                cancel_button_label="NO"
                cancel_button_label_color="black"
                cancel_button_color="#fff"
                footer_color={"#F9FAFA"}
              >
                <ScreeningProcessDetails
                  setScreeningprocessPopup={setScreeningprocessPopup}
                />
              </CustomPopup>
            </div>

            <div className="px-2 py-1 mt-3 bg-white radius-4  fs-13 d-flex justify-content-between">
              <span>Signing order status</span>
              <span className="light-font-text fs-13 ">
                <span className="cursor-pointer">
                  <FontAwesomeIcon
                    icon="fa-regular fa-arrow-up-right-from-square"
                    style={{ color: "#000000", width: "12px", height: "12px" }}
                    className="pe-1"
                    onClick={() => setRentalContractPopup(true)}
                  />
                </span>

                {/* -----------Rental contract popup------------ */}
                <CustomPopup
                  open={rentalContractPopup}
                  onHide={() => setRentalContractPopup(false)}
                  title="Rental contract details"
                  title_color="black"
                  titleFont="14px"
                  titleTooltip={true}
                  enableCrossButton={false}
                  deadline={true}
                  deadlineTime={
                    <Countdown
                      date={moment(
                        contractOverViewData?.data?.[0]?.reservation_deadline
                      ).valueOf()}
                      daysInHours
                      zeroPadTime={2}
                    />
                  }
                  titleTooltipText="Rental contract details"
                  size="lg"
                  onCloseAction={() => {
                    setRentalContractPopup(false);
                  }}
                  action_button_label="YES"
                  action_button_label_color="white"
                  action_button_color="#619130"
                  cancel_button={true}
                  cancel_button_bordercolor="grey"
                  cancel_button_label="NO"
                  cancel_button_label_color="black"
                  cancel_button_color="#fff"
                  footer_color={"#F9FAFA"}
                >
                  <RentalContract
                    setRentalContractPopup={setRentalContractPopup}
                    contractId={contractId}
                    contractOverViewData={contractOverViewData}
                    contractOverViewDataRefetch={contractOverViewDataRefetch}
                    setSelectedTab={setSelectedTab}
                  />
                </CustomPopup>
                <span
                  className="p-1 mx-1 radius-4"
                  style={{
                    backgroundColor:
                      contractOverViewData?.data?.[0]?.total_signed == 0
                        ? "#FF6868"
                        : contractOverViewData?.data?.[0]?.total_signed ===
                          contractOverViewData?.data?.[0]?.total_signers
                        ? "#ADECA7"
                        : "#FFD700",
                  }}
                >
                  {contractOverViewData?.data?.[0]?.total_signed} /&nbsp;
                  {contractOverViewData?.data?.[0]?.total_signers}
                </span>
              </span>
            </div>
            <div className="px-2 py-1 mt-2 bg-white radius-4 fs-13 d-flex justify-content-between">
              <span>Signing order expire </span>
              <span className="light-font-text fs-13">
                {contractOverViewData?.data?.[0]?.signing_order_expire !== ""
                  ? moment(
                      contractOverViewData?.data?.[0]?.signing_order_expire
                    ).format("DD MMM, YYYY [at] HH:ss")
                  : "-"}
              </span>
            </div>

            <div className="px-2 py-1 mt-3 bg-white radius-4 fs-13 d-flex justify-content-between">
              <span>Deposit status</span>
              <span className="light-font-text fs-13">
                <span className="cursor-pointer">
                  <FontAwesomeIcon
                    icon="fa-regular fa-arrow-up-right-from-square"
                    style={{ color: "#000000", width: "12px", height: "12px" }}
                    className="pe-1"
                    onClick={() => setDepositPopup(true)}
                  />
                </span>
                {contractOverViewData?.data?.[0]?.deposit_status}
              </span>

              {/* ------------------Deposit detail popup------------- */}
              <CustomPopup
                open={depositPopup}
                onHide={() => setDepositPopup(false)}
                title="Deposit details"
                title_color="black"
                titleFont="14px"
                titleTooltip={true}
                enableCrossButton={false}
                titleTooltipText="Deposit details"
                size="lg"
                onCloseAction={() => {
                  setDepositPopup(false);
                  alert("Action Peformed Successfuly.");
                }}
                action_button_label="YES"
                action_button_label_color="white"
                action_button_color="#619130"
                cancel_button={true}
                cancel_button_bordercolor="grey"
                cancel_button_label="NO"
                cancel_button_label_color="black"
                cancel_button_color="#fff"
                footer_color={"#F9FAFA"}
              >
                <DepositDetails
                  setDepositPopup={setDepositPopup}
                  getNotesDetailsData={getNotesDetailsData}
                  contractId={contractId}
                  getNotesDetailsDataRefetch={getNotesDetailsDataRefetch}
                  contractOverViewDataRefetch={contractOverViewDataRefetch}
                />
              </CustomPopup>
            </div>
            <div className="px-2 py-1 mt-3 bg-white radius-4  fs-13 d-flex justify-content-between">
              <span>Date of rental</span>
              <span className="light-font-text fs-13">
                {contractOverViewData?.data?.[0]?.date_of_rental !== "" && (
                  <span className="cursor-pointer">
                    <FontAwesomeIcon
                      icon="fa-regular fa-arrow-up-right-from-square"
                      style={{
                        color: "#000000",
                        width: "12px",
                        height: "12px",
                      }}
                      className="px-1"
                      onClick={() => setLeasedPopup(true)}
                    />
                  </span>
                )}
                {contractOverViewData?.data?.[0]?.date_of_rental !== ""
                  ? date_of_rental
                  : "-"}
              </span>

              {/* ----------Leased detail popup------------- */}
              <CustomPopup
                open={leasedPopup}
                onHide={() => setLeasedPopup(false)}
                title="Leased details"
                title_color="black"
                titleFont="14px"
                titleTooltip={true}
                enableCrossButton={false}
                deadline={true}
                deadlineTime={
                  <Countdown
                    date={moment(
                      contractOverViewData?.data?.[0]?.reservation_deadline
                    ).valueOf()}
                    daysInHours
                    zeroPadTime={2}
                  />
                }
                titleTooltipText="Leased details"
                size="lg"
                onCloseAction={() => {
                  setLeasedPopup(false);
                  alert("Action Peformed Successfuly.");
                }}
                action_button_label="YES"
                action_button_label_color="white"
                action_button_color="#619130"
                cancel_button={true}
                cancel_button_bordercolor="grey"
                cancel_button_label="NO"
                cancel_button_label_color="black"
                cancel_button_color="#fff"
                footer_color={"#F9FAFA"}
              >
                <LeasedDetails
                  setLeasedPopup={setLeasedPopup}
                  contractId={contractId}
                  contractOverViewDataRefetch={contractOverViewDataRefetch}
                />
              </CustomPopup>
            </div>
            <div className="px-2 py-1 mt-2 bg-white radius-4  fs-13 d-flex justify-content-between">
              <span>Moving in date</span>
              <span className="light-font-text fs-13">
                {contractOverViewData?.data?.[0]?.moving_in_date !== "" && (
                  <span className="cursor-pointer">
                    <FontAwesomeIcon
                      icon="fa-regular fa-arrow-up-right-from-square"
                      style={{
                        color: "#000000",
                        width: "12px",
                        height: "12px",
                      }}
                      className="pe-1"
                      onClick={() => setMoveInPopup(true)}
                    />
                  </span>
                )}
                {contractOverViewData?.data?.[0]?.moving_in_date !== ""
                  ? moving_in_date
                  : "-"}
              </span>

              {/* -------------Moving in detail popup--------- */}
              <CustomPopup
                open={moveInPopup}
                onHide={() => setMoveInPopup(false)}
                title="Moving in details"
                title_color="black"
                titleFont="14px"
                titleTooltip={true}
                enableCrossButton={false}
                titleTooltipText="Moving in details"
                size="lg"
                onCloseAction={() => {
                  setMoveInPopup(false);
                  alert("Action Peformed Successfuly.");
                }}
                action_button_label="YES"
                action_button_label_color="white"
                action_button_color="#619130"
                cancel_button={true}
                cancel_button_bordercolor="grey"
                cancel_button_label="NO"
                cancel_button_label_color="black"
                cancel_button_color="#fff"
                footer_color={"#F9FAFA"}
              >
                <MovingInDetails
                  setMoveInPopup={setMoveInPopup}
                  getNotesDetailsData={getNotesDetailsData}
                  contractId={contractId}
                  getNotesDetailsDataRefetch={getNotesDetailsDataRefetch}
                  contractOverViewData={contractOverViewData}
                />
              </CustomPopup>
            </div>
            <div className="px-2 py-1 mt-3 bg-white radius-4  fs-13 d-flex justify-content-between">
              <span>Termination date</span>
              <span className="light-font-text fs-13">
                {contractOverViewData?.data?.[0]?.termination_date !== "" && (
                  <span className="cursor-pointer">
                    <FontAwesomeIcon
                      icon="fa-regular fa-arrow-up-right-from-square"
                      style={{
                        color: "#000000",
                        width: "12px",
                        height: "12px",
                      }}
                      className="pe-1"
                      onClick={() => setTerminationPopup(true)}
                    />
                  </span>
                )}
                {contractOverViewData?.data?.[0]?.termination_date !== ""
                  ? termination_date
                  : "-"}
              </span>

              {/* ------------Termination detail popup---------------- */}
              <CustomPopup
                open={terminationPopup}
                onHide={() => setTerminationPopup(false)}
                title="Termination details"
                title_color="black"
                titleFont="14px"
                titleTooltip={true}
                enableCrossButton={false}
                deadline={false}
                titleTooltipText="Termination details"
                size="lg"
                onCloseAction={() => {
                  setTerminationPopup(false);
                  alert("Action Peformed Successfuly.");
                }}
                action_button_label="YES"
                action_button_label_color="white"
                action_button_color="#619130"
                cancel_button={true}
                cancel_button_bordercolor="grey"
                cancel_button_label="NO"
                cancel_button_label_color="black"
                cancel_button_color="#fff"
                footer_color={"#F9FAFA"}
              >
                <TerminationDetails
                  setTerminationPopup={setTerminationPopup}
                  getNotesDetailsData={getNotesDetailsData}
                  contractId={contractId}
                  getNotesDetailsDataRefetch={getNotesDetailsDataRefetch}
                  contractOverViewDataRefetch={contractOverViewDataRefetch}
                />
              </CustomPopup>
            </div>
            <div className="px-2 py-1 mt-2 bg-white radius-4  fs-13 d-flex justify-content-between">
              <span>Moving out</span>
              <span className="light-font-text fs-13">
                {contractOverViewData?.data?.[0]?.moving_out_date !== "" && (
                  <span className="cursor-pointer">
                    <FontAwesomeIcon
                      icon="fa-regular fa-arrow-up-right-from-square"
                      style={{
                        color: "#000000",
                        width: "12px",
                        height: "12px",
                      }}
                      className="pe-1"
                      onClick={() => setMoveOutPopup(true)}
                    />
                  </span>
                )}
                {contractOverViewData?.data?.[0]?.moving_out_date !== ""
                  ? moment(
                      contractOverViewData?.data?.[0]?.moving_out_date
                    ).format("DD MMM, YYYY [at] HH:mm:ss")
                  : "-"}
              </span>

              {/* ------------Moving out detail popup---------------- */}
              <CustomPopup
                open={moveOutPopup}
                onHide={() => setMoveOutPopup(false)}
                title="Moving out details"
                title_color="black"
                titleFont="14px"
                titleTooltip={true}
                enableCrossButton={false}
                deadline={false}
                titleTooltipText="Moving out details"
                size="lg"
                onCloseAction={() => {
                  setMoveOutPopup(false);
                  alert("Action Peformed Successfuly.");
                }}
                action_button_label="YES"
                action_button_label_color="white"
                action_button_color="#619130"
                cancel_button={true}
                cancel_button_bordercolor="grey"
                cancel_button_label="NO"
                cancel_button_label_color="black"
                cancel_button_color="#fff"
                footer_color={"#F9FAFA"}
              >
                <MovingOutDetails
                  setMoveOutPopup={setMoveOutPopup}
                  getNotesDetailsData={getNotesDetailsData}
                  contractId={contractId}
                  getNotesDetailsDataRefetch={getNotesDetailsDataRefetch}
                />
              </CustomPopup>
            </div>
          </div>
        </Col>
        <Col
          xl={8}
          className="py-3"
        >
          <div
            className=" radius-8 d-flex flex-column justify-content-center customers-card-layout"
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              height: "75px",
            }}
          >
            <div className="py-2 scrollable-container-y">
              {actorByContractIdData?.data?.map((item, index) => (
                <Row
                  key={index}
                  className="light-font-text "
                >
                  <Col
                    sm={2}
                    className="form-label-title text-center"
                  >
                    Tenant {index + 1}
                  </Col>
                  <Col sm={3}>
                    {item?.first_name}&nbsp;{item?.last_name}
                  </Col>
                  <Col sm={4}>{item?.email}</Col>
                  <Col sm={3}>
                    {item?.country_code}&nbsp;{item?.phone_number}
                  </Col>
                </Row>
              ))}
            </div>
          </div>
          <div
            className="radius-8 mt-4 p-3 customers-card-layout"
            style={{
              height: "575px",
            }}
          >
            <div className="d-flex justify-content-between">
              <div className="actors-section-title">Activities</div>
              <div className="d-flex">
                <Select
                  options={options}
                  styles={style}
                  placeholder={"All"}
                />
                {/* {ActivitiesTabs.map((item) => (
                  <button key={item.id} className="activites-gray-btn px-2">
                    {item.text}
                  </button>
                ))} */}
              </div>
            </div>
            <div
              style={{ height: "510px", overflowY: "scroll" }}
              className="my-2 scrollable-container-y"
            >
              {/* {ActivitiesInfo.map((item, i) => (
                <div
                  key={i}
                  className="d-flex justify-content-between bg-white p-2 radius-8 my-1 lite-grey-border"
                >
                  <div className="d-flex">
                    <div
                      className="px-2 py-2"
                      style={{
                        borderRadius: "100%",
                        backgroundColor: "#A2C8D0",
                        width: "44px",
                        height: "44px",
                      }}
                    >
                      <span className="white-text form-label-title fs-11">
                        {item.user}
                      </span>
                    </div>
                    <div
                      className="actors-lite-text px-2 w-60"
                      style={{ width: "60%" }}
                    >
                      {item.msg}
                    </div>
                  </div>
                  <div className="created-by">{item.date}</div>
                </div>
              ))} */}
              <EmptyMessage />
            </div>
          </div>
        </Col>
      </Row>

      {/*--------------------- Process ---------------------------------*/}
      <Row className="px-3 my-2">
        <Col className="radius-8 customers-card-layout">
          <ProcessTimeline
            setReservationPopup={setReservationPopup}
            setScreeningprocessPopup={setScreeningprocessPopup}
            setRentalContractPopup={setRentalContractPopup}
            setDepositPopup={setDepositPopup}
            setLeasedPopup={setLeasedPopup}
            setMoveInPopup={setMoveInPopup}
            setMoveOutPopup={setMoveOutPopup}
            setTerminationPopup={setTerminationPopup}
          />
        </Col>
      </Row>

      {/*-------------------Communication & Notes-------------------------------------*/}
      <Row className="px-1 mb-3">
        <Col xl={6}>
          <div
            className="radius-8 mt-4 ps-3 py-3 pe-1 customers-card-layout"
            style={{
              height: "575px",
            }}
          >
            <div className=" d-flex justify-content-between">
              {" "}
              <div className="actors-section-title">Communication</div>{" "}
              <div className="fs-14  pe-3">View all </div>
            </div>

            <div
              style={{ height: "510px", overflowY: "scroll" }}
              className="my-2 scrollable-container-y"
            >
              {/* {CommunicationData.map((item, i) => (
                <div
                  key={item.id}
                  className="d-flex  bg-white p-2 me-1 radius-8 my-2 lite-grey-border"
                  style={{
                    alignItems: "center",
                    borderLeft: item.notification
                      ? "5px solid #619130"
                      : "1px solid #F0F0F0",
                  }}
                >
                  <div
                    className="  d-flex justify-content-center "
                    style={{
                      borderRadius: "100%",
                      backgroundColor: "#A2C8D0",
                      alignItems: "center",
                      width: "44px",
                      height: "44px",
                      position: "relative",
                    }}
                  >
                    <span
                      className="white-text fs-11"
                      style={{ position: "relative" }}
                    >
                      {" "}
                      {item.notification && (
                        <span
                          className="px-1 "
                          style={{
                            backgroundColor: " #D00200",
                            borderRadius: "100%",
                            position: "absolute",
                            right: "15px",
                            bottom: "15px",
                          }}
                        >
                          {item.notification}
                        </span>
                      )}{" "}
                      JT
                    </span>
                  </div>
                  <div className="fs-13 fw-300 px-2 w-100">
                    <div className="d-flex justify-content-between">
                      <div className="fs-9 form-label-title">{item.head}</div>
                      <div className="fs-10 form-label-title grey-text">
                        {item.date}
                      </div>
                    </div>
                    <div className="fs-14 form-label-title">{item.name}</div>
                    <div
                      className="actors-lite-text"
                      //  style={{ whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis" }}
                    >
                      {item.msg}
                    </div>
                    <div className="d-flex justify-content-between ">
                      <div className="fs-8  my-2">
                        <span
                          style={{ backgroundColor: "#E1E1E1" }}
                          className="fs-8 p-1 radius-4 font-semibold"
                        >
                          {item.subjet}
                        </span>
                      </div>
                      <div className="mt-2">
                        {" "}
                        <FontAwesomeIcon
                          icon="fa-regular fa-arrow-up-right-from-square"
                          style={{
                            color: "#000000",
                            width: "12px",
                            height: "12px",
                          }}
                          className="pe-1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))} */}
              <EmptyMessage />
            </div>
          </div>
        </Col>
        <Col xl={6}>
          <div
            className="radius-8 mt-4 ps-3 py-3 pe-1 customers-card-layout"
            style={{
              height: "575px",
            }}
          >
            <div className=" d-flex justify-content-between">
              {" "}
              <div className="actors-section-title">Notes</div>
              <div
                onClick={() => setSelectedTab(5)}
                className="fs-14 fw-400 pe-3 cursor-pointer"
              >
                View all
              </div>
            </div>

            {notesData?.length > 0 ? (
              <div
                style={{ height: "510px", overflowY: "scroll" }}
                className="my-2 scrollable-container-y"
                id="scrollableNotesSection"
              >
                <InfiniteScroll
                  dataLength={notesData?.length}
                  next={fetchMoreNotesData}
                  hasMore={true}
                  scrollableTarget="scrollableNotesSection"
                >
                  {notesData?.map((item, index) => (
                    <div
                      key={index}
                      className="px-3 py-2 radius-8 my-2"
                      style={{
                        border: "1px solid #F6EB83",
                        alignItems: "center",
                        backgroundColor: "#FFF599",
                      }}
                    >
                      <div
                        className="d-flex justify-content-between w-100"
                        style={{ alignItems: "center" }}
                      >
                        <div>
                          <span
                            style={{
                              backgroundColor: item?.bg_color || "#9370DB",
                              textTransform: "uppercase",
                            }}
                            className="fs-9 font-semibold p-1 radius-4 white-text"
                          >
                            {item?.category_name}
                          </span>
                        </div>
                        <div className="fs-10 ">
                          {moment(item?.created_on)?.format(
                            "DD MMM, YYYY [at] hh:mm"
                          )}
                        </div>
                      </div>
                      <div className="fs-14 font-semibold py-1">
                        {item?.subject}
                      </div>
                      <div className="light-font-text">{item?.message}</div>
                      <div className="d-flex justify-content-between mt-2 ">
                        <div>
                          <span className="fs-12 light-font-text ">
                            by {item?.created_by}
                          </span>
                        </div>
                        <div className="cursor-pointer">
                          <FontAwesomeIcon
                            icon="fa-regular fa-arrow-up-right-from-square"
                            style={{
                              color: "#000000",
                              width: "12px",
                              height: "12px",
                            }}
                            className="pe-1"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              </div>
            ) : (
              // <div
              //   className="d-flex justify-content-center w-100 fs-12 mt-4"
              //   style={{ color: "#888888" }}
              // >
              //   No notes found
              // </div>
              <div className="pt-2">
                <EmptyMessage />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Overview;
