import React, { useState } from "react";
import ETButton from "../../Components/Buttons/ETButton";
import CustomPopup from "../../Components/CustomPopup";
import { usePropertyOverview } from "../../hooks/stores/usePropertyStore";
import { usePostAddEvent } from "../../hooks/queries/useEventsQueries";
import { useEventStore } from "../../hooks/stores/useEventStore";
import moment from "moment";
import { toast } from "react-toastify";
import { status } from "../../model/Constant";
const SaveAsTemplate = ({
  saveAsDraft,
  setSaveAsDraft,
  saveAsTemplate,
  setSaveAsTemplate,
  getValues,
  endEventValue,
  website,
  eido,
  AssignAgentsData,
  schedule,
  numberOfParticipant,
  intervalTimeDuration,
  breakTime,
  setShowOpenHouseProperty,
  numberOfBooking,
  selectedUnitsId,
}) => {
  //-------------------State--------------------------//

  const [input1Value, setInput1Value] = useState("");
  //-------------------Zustand--------------------------//

  const { typeOfEvents, booking, prescreening, qrCodeGenerate } = useEventStore(
    (state) => state
  );

  const { eventOn } = useEventStore((state) => state);
  const propOverview = usePropertyOverview((state) => state.propOverview);

  //----------------react query----------------//
  const { mutateAsync: postAddEvent, isLoading: postAddEventIsLoading } =
    usePostAddEvent();

  // Event handler for input 1
  const handleInput1Change = (event) => {
    setInput1Value(event.target.value);
  };
  const EventName = getValues("nameofEvent");
  const Streetname = getValues("streetName");
  const Number = getValues("no");
  const AddInfo = getValues("addInfo");
  const Country = getValues("country");
  const Zipcode = getValues("zipcode");
  const Latitude = getValues("latitude");
  const Longitude = getValues("longitude");
  const starttime = getValues("timeTo");
  const endTime = getValues("timeFrom");
  const EventStartingDate = getValues("eventStartingDate");
  const EventRepetition = getValues("events");
  const internalNote = getValues("internalNote");
  const eventEndDateendRepetition = getValues("eventEndDate");
  const eventRepetitionDays = getValues("eventRepetitionDays");
  const detailedInfo = getValues("detailedInfo");
  const responsibleAgent = getValues("responsibleAgent");

  function convertTimeFormat(time, numberOfParticipants) {
    const [start, end] = time.split(" - ");
    return {
      time_from: `${start}:00`,
      time_to: `${end}:00`,
      nof_participants: numberOfParticipants,
    };
  }

  const formattedTimes = schedule?.map((time, index) =>
    convertTimeFormat(time, numberOfParticipant[index])
  );

  const handleSave = async () => {
    const saveEvent = {
      property_id: propOverview?.[0]?.property_id?.toString(),
      event_type_id: typeOfEvents?.toString(),
      time_start: moment(starttime, "hh:mm:ss").format("hh:mm:ss"),
      time_end: moment(endTime, "hh:mm:ss").format("hh:mm:ss"),
      responsible_agent_user_id: responsibleAgent?.value?.toString(),
      street_name: Streetname?.toString(),
      house_number: Number?.toString(),
      side_door: "1",
      zipcode_id: Zipcode?.value?.toString(),
      add_info: AddInfo?.toString(),
      name: EventName?.toString(),
      country_id: Country?.value?.toString(),
      latitude: Latitude?.toString(),
      longitude: Longitude?.toString(),
      internal_note: internalNote?.toString(),
      frequency: EventRepetition?.value ? EventRepetition?.value : "",
      end_date_for_repetition: eventEndDateendRepetition
        ? eventEndDateendRepetition
        : "",
      number_of_repetitions: eventRepetitionDays ? eventRepetitionDays : "",
      max_number_of_bookings: numberOfBooking ? numberOfBooking : "0",
      time_slot_duration: intervalTimeDuration,
      break_duration: breakTime,
      nof_participants_per_slot: "5",
      event_date: EventStartingDate?.toString(),

      is_booking_required: booking == 1 ? "1" : "0",
      has_prescreening: prescreening == 1 ? "1" : "0",
      generate_qr_code: qrCodeGenerate == 1 ? "1" : "0",
      pre_screening_url: "1",
      publish_on_website: website ? "1" : "0",
      publish_in_eido: eido ? "1" : "0",
      handle_bookings_by: "1",
      prescreening_template_id: "1",
      is_draft: "1",
      event_on: "2",
      detailed_info: detailedInfo,
      event_repetition_type: endEventValue?.toString(),
      timeslots: formattedTimes,
      agents: AssignAgentsData?.map((item) => {
        return {
          agent_user_id: item?.user_id,
        };
      }),

      event_unit:
        eventOn === 2
          ? selectedUnitsId?.map((item) => {
              return {
                unit_id: item?.toString(),
              };
            })
          : [
              {
                unit_id: "",
              },
              {
                unit_id: "",
              },
            ],
    };

    let id;
    if (!postAddEventIsLoading) {
      id = toast.loading("Event is being save as draft....");
    }
    try {
      const response = await postAddEvent(saveEvent);
      if (response.status === status.s && response.data.bReturn) {
        toast.update(id, {
          render: response?.data?.message,
          isLoading: false,
          type: status.s,
          autoClose: 2000,
        });
        setShowOpenHouseProperty(false);
      } else {
        toast.update(id, {
          render: response?.data?.message,
          isLoading: false,
          type: status.e,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.update(id, {
        render: error?.message,
        isLoading: false,
        type: status.e,
        autoClose: 2000,
      });
    }
  };

  return (
    <div>
      {" "}
      <CustomPopup
        open={saveAsTemplate}
        onHide={() => setSaveAsTemplate(false)}
        title="Publish event"
        title_color="black"
        size={"md"}
        titleTooltip={true}
        titleTooltipText={"Publish event"}
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4 c-black">
          <div className="mb-3 form-label-title">
            Name your pre-screening template
          </div>
          <div>
            {" "}
            <div className="form-input">
              <input
                className=""
                type="text"
                onChange={handleInput1Change}
                value={input1Value}
                placeholder="Subject line"
                id="subjectline"
              />
              <label for="subjectline">Template title</label>
            </div>
          </div>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => {
              setInput1Value("");
              setSaveAsTemplate(false);
            }}
          />
          <ETButton
            onClick={() => {
              setSaveAsTemplate(false);
              setSaveAsDraft(true);
            }}
            buttonText="SAVE"
            variant={"primary"}
          />
        </div>
      </CustomPopup>
      <CustomPopup
        open={saveAsDraft}
        onHide={() => setSaveAsDraft(false)}
        title="Save As Draft"
        title_color="black"
        size={"md"}
        titleTooltip={true}
        titleTooltipText={"Publish event"}
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4 c-black">
          <div className="mb-3 fs-14">
            Are you sure you want to save your event as a draft.
          </div>
          <div className="fs-14 form-label-title">{EventName} </div>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => {
              setInput1Value("");
              setSaveAsDraft(false);
            }}
          />
          <ETButton
            buttonText="SAVE"
            variant={"primary"}
            disabled={postAddEventIsLoading}
            onClick={handleSave}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default SaveAsTemplate;
