import React from "react";
import CustomPopup from "../CustomPopup";
import ReactSelect from "../DropDown/ReactSelect";
import ETButton from "../Buttons/ETButton";

const ResponsibleAgent = ({
  openChangeAgentPopup,
  setOpenChangeAgentPopup,
  handleUpdateResponsibleAgent,
}) => {
  return (
    <div>
      {" "}
      <CustomPopup
        open={openChangeAgentPopup}
        onHide={() => setOpenChangeAgentPopup(false)}
        title="Change Agent"
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
        deletePopUpStyle={true}
        titleTooltip={true}
      >
        <div className="p-3">
          <div className="px-2">
            <div className="form-label-title  my-2">
              Current responsible agent
            </div>
            <div className="mt-2">
              {" "}
              <div className="form-input">
                <input
                  disabled
                  style={{ margin: "0px" }}
                  type="text"
                  placeholder="Subject line"
                  id="subjectline"
                  //   defaultValue={
                  //     tenancyByUnitIdData?.data?.[0]?.responsible_agent_name
                  //   }
                />
                <label for="firstname">Agent</label>
              </div>
            </div>
          </div>
          <div className="px-2 mt-4">
            <div className="form-label-title my-2 ">New responsible agent</div>
            <div className="mb-3 mt-2">
              <ReactSelect
                placeholder={"Agent"}
                // options={usersByDivisionIdData?.data?.map(
                //   (item, index) => ({ value: item?.Id, label: item?.Text })
                // )}
                // onChange={(e) => setResponsibleAgentId(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container ">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setOpenChangeAgentPopup(false)}
          />
          <ETButton
            // disabled={responsibleAgentId === "" && true}
            variant={"primary"}
            buttonText="CHANGE"
            onClick={() => handleUpdateResponsibleAgent()}
          />
        </div>
      </CustomPopup>
    </div>
  );
};

export default ResponsibleAgent;
