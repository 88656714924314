import React from "react";
import { Col, Row, Container, Form, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import IconTextField from "../../../../Components/GroupText/IconTextField";
import ETButton from "../../../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import email from "../../../../assets/images/Email.png";
import "@dotlottie/player-component";
import sendingEmail from "../../../../assets/animations/sendingEmail.lottie";

const ResentLinkConfirmedSend = ({ data, userEmail = "" }) => {
  return (
    <>
      {data?.map((data, id) => (
        <Container key={id}>
          <Row className="d-flex justify-content-center align-items-center">
            <Col
              sm={12}
              md={7}
              lg={6}
              xl={5}
              xxl={4}
              className="px-xl-2 px-sm-0"
            >
              <div
                className="StandardForm row"
                style={{
                  backgroundColor: data?.ContainerBackground,
                  position: "relative",
                }}
              >
                <div className="px-3" style={{ marginTop: "40px" }}>
                  <Row className="m-auto">
                    <div
                      className="d-flex justify-content-center align-items-center mb-5 p-1"
                      md={{ span: 6, offset: 3 }}
                      xs={{ span: 4, offset: 4 }}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <Image
                          width={"160px"}
                          height={"auto"}
                          src={data?.logo}
                          alt="logo"
                        />
                      </div>
                    </div>
                  </Row>

                  <div
                    className="d-flex justify-content-center flex-column align-items-center mb-4 px-4 mx-1 light-font-text fs-16"
                    style={{ paddingBottom: "0px", color: data?.Biocolor }}
                  >
                    <span>We have now send a new link to</span>
                    <span>{userEmail ? userEmail : `{{Email filled in}}`}</span>
                    <span
                      className=" px-4  mb-4"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      with instructions on how to reset your password
                    </span>
                    <span
                      className=" px-4 "
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Need more help, please contact our support team in
                      estatetool.
                    </span>
                  </div>

                  {/* <Row className="m-auto">
                <div
                  className="d-flex justify-content-center align-items-center mb-4"
                  md={{ span: 6, offset: 3 }}
                  xs={{ span: 4, offset: 4 }}
                >
                  <div>
                    <dotlottie-player
                      src={sendingEmail}
                      autoplay
                      loop
                      style={{ height: "265px", width: "100%" }}
                    />
                  </div>
                </div>
              </Row> */}

                  <div
                    className="d-flex justify-content-center"
                    style={{ height: "190px" }}
                  >
                    <div
                      style={{ position: "absolute", top: "45%", zIndex: 1 }}
                    >
                      <dotlottie-player
                        src={sendingEmail}
                        autoplay
                        loop
                        style={{ height: "265px", width: "100%" }}
                      />
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-start light-font-text"
                    style={{}}
                  >
                    <div className="cursor-pointer">
                      <NavLink
                        to="/forgot-password"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <span className="d-flex light-font-text align-items-center gap-1 mb-3">
                          <FontAwesomeIcon
                            icon="fa-regular fa-arrow-left-long"
                            style={{
                              color: "#f1664b",
                              paddingTop: "2px",
                            }}
                          />
                          <span
                            style={{
                              color: data?.FormLinkandTextboxCheckColor,
                            }}
                          >
                            Didn’t receive an email, try agin
                          </span>
                        </span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      ))}
    </>
  );
};

export default ResentLinkConfirmedSend;
