import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Toggle from "../../../../Components/Switch/Switch";
import PasswordField from "../../../../Components/GroupText/PasswordField";
import ETButton from "../../../../Components/Buttons/ETButton";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { useGetGeographyCountry } from "../../../../hooks/queries/useGeographyQueries";
import { PostAddExtraSigningOrderPerson } from "../../../../services/Signing";
import { toast } from "react-toastify";

const AddExtraSigningPerson = ({
  setAddExtraPerson,
  getSigningOrderDetailDataRefetch,
  signingOrderId,
}) => {
  //-----------------------states-------------------------//
  const [isQaApproverActive, setIsQaApproverActive] = useState(true);
  const [isCopyRecipient, setIsCopyRecipient] = useState(true);
  const [postAddExtraSigningOrderPersonIsLoading, setPostAddExtraSigningOrderPersonIsLoading] = useState(false)
  const [isLandlordSellerSignerActive, setIsLandlordSellerSignerActive] =
    useState(true);
  const [isNotificationRecipientActive, setIsNotificationRecipientActive] =
    useState(true);
  const [isTwoFactorActive, setIsTwoFactorActive] = useState(true);

  //-----------------------Zustand------------------------//
  const { data: countryData } = useGetGeographyCountry();

  //----------------------Validation----------------------//
  const {
    register,
    handleSubmit,
    clearErrors,
    getValues,
    formState: { errors },
    setValue,
    control,
    reset,
  } = useForm();

  //------------------handle add extra person-----------------//
  const handleAddExtraPerson = (data) => {
    setPostAddExtraSigningOrderPersonIsLoading(true)
    const addExtraPersonPayload = {
      first_name: data?.firstName,
      last_name: data?.lastName,
      title: data?.title,
      email: data?.email,
      isd_code: data?.isdCode?.value,
      mobile_phone: data?.phone,
      signing_order_id: String(signingOrderId),
      is_qa_approver: isQaApproverActive ? "1" : "0",
      is_landlord_seller_signer: isLandlordSellerSignerActive ? "1" : "0",
      is_copy_recipient: isCopyRecipient ? "1" : "0",
      is_notification_recipient: isNotificationRecipientActive ? "1" : "0",
      ssn_cvr: data?.ssnCvr,
      use_two_factor_signing: isTwoFactorActive ? "1" : "0",
    };
    PostAddExtraSigningOrderPerson(addExtraPersonPayload).then((response) => {
      if (response.data.bReturn) {
        toast.success(response.data.message)
        setAddExtraPerson(false)
        setPostAddExtraSigningOrderPersonIsLoading(false)
        getSigningOrderDetailDataRefetch()
        reset()
      }
      else {
        toast.error(response.data.message)
        setPostAddExtraSigningOrderPersonIsLoading(false)

      }
    }).catch((error) => {
      toast.error(error.message)
      setPostAddExtraSigningOrderPersonIsLoading(false)

    })

  };

  return (
    <Form onSubmit={handleSubmit(handleAddExtraPerson)}>
      <div className="px-4 py-4">
        <Row>
          <Col>
            <Row className="mb-3">
              <Col>
                <Controller
                  {...register(`title`)}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <div className="form-input">
                      <input
                        type="text"
                        placeholder="Title"
                        id="Title"
                        value={field.value}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors("title");
                        }}
                      />
                      <label for="Title">Title</label>
                    </div>
                  )}
                />
                {errors.title && (
                  <p className="error-msg">This field is required</p>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="mb-sm-3 mb-lg-0">
                <Controller
                  {...register(`firstName`)}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <div className="form-input">
                      <input
                        type="text"
                        placeholder="firstname"
                        id="firstname"
                        value={field.value}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors("firstName");
                        }}
                      />
                      <label for="firstname">First Name</label>
                    </div>
                  )}
                />
                {errors.firstName && (
                  <p className="error-msg">This field is required</p>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Controller
                  {...register(`lastName`)}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <div className="form-input">
                      <input
                        type="text"
                        placeholder="lastname"
                        id="lastname"
                        value={field.value}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors("lastName");
                        }}
                      />
                      <label for="lastname">Last Name</label>
                    </div>
                  )}
                />
                {errors.lastName && (
                  <p className="error-msg">This field is required</p>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Controller
                  {...register(`email`)}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <div className="form-input">
                      <input
                        type="text"
                        placeholder="email"
                        id="email"
                        value={field.value}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors("email");
                        }}
                      />
                      <label for="email">Email</label>
                    </div>
                  )}
                />
                {errors.email && (
                  <p className="error-msg">This field is required</p>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={5}>
                <Controller
                  {...register(`isdCode`)}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder={"Country code"}
                      options={countryData?.data?.map((item) => ({
                        value: item?.DataValue2,
                        label: item?.DataValue2,
                      }))}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("isdCode");
                      }}
                    />
                  )}
                />
                {errors.isdCode && (
                  <p className="error-msg">This field is required</p>
                )}
              </Col>
              <Col sm={7}>
                <Controller
                  {...register(`phone`)}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <div className="form-input">
                      <input
                        type="text"
                        placeholder="phone"
                        id="phone"
                        value={field.value}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors("phone");
                        }}
                      />
                      <label for="phone">Phone </label>
                    </div>
                  )}
                />
                {errors.phone && (
                  <p className="error-msg">This field is required</p>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Controller
                  {...register(`ssnCvr`)}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <PasswordField
                      placeholder="SSN/CVR"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("ssnCvr");
                      }}
                    />
                  )}
                />
                {errors.ssnCvr && (
                  <p className="error-msg">This field is required</p>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col className="pe-lg-0" sm={6}>
                <Row className="d-flex align-items-center">
                  <Col className="form-label-title" lg={6}>
                    QA Approver
                  </Col>
                  <Col className="ps-lg-0" lg={5}>
                    <Toggle
                      id={"Tenancy_qa_approve"}
                      checked={isQaApproverActive}
                      onChange={(e) => setIsQaApproverActive(e.target.checked)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="pe-lg-0" sm={6}>
                <Row className="d-flex align-items-center">
                  <Col className="form-label-title" lg={6}>
                    LandLord/Seller
                  </Col>
                  <Col className="ps-lg-0" lg={5}>
                    <Toggle
                      id={"Tenancy_LandLord_Seller"}
                      checked={isLandlordSellerSignerActive}
                      onChange={(e) =>
                        setIsLandlordSellerSignerActive(e.target.checked)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col className="pe-lg-0" sm={6}>
                <Row className="d-flex align-items-center">
                  <Col className="form-label-title" lg={6}>
                    Copy Recipient
                  </Col>
                  <Col className="ps-lg-0" lg={5}>
                    <Toggle
                      id={"Tenancy_Copy_Recipient"}
                      checked={isCopyRecipient}
                      onChange={(e) => setIsCopyRecipient(e.target.checked)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="pe-lg-0" sm={6}>
                <Row className="d-flex align-items-center">
                  <Col className="form-label-title" lg={6}>
                    Notification
                  </Col>
                  <Col className="ps-lg-0" lg={5}>
                    <Toggle
                      id={"Tenancy_Notification"}
                      checked={isNotificationRecipientActive}
                      onChange={(e) =>
                        setIsNotificationRecipientActive(e.target.checked)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col className="pe-lg-0" sm={6}>
                <Row className="d-flex align-items-center">
                  <Col className="form-label-title" lg={6}>
                    2FA
                  </Col>
                  <Col className="ps-lg-0" lg={5}>
                    <Toggle
                      id={"Tenancy_2FA"}
                      checked={isTwoFactorActive}
                      onChange={(e) => setIsTwoFactorActive(e.target.checked)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div
        className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
        style={{
          backgroundColor: "#F9FAFA",
          borderRadius: " 0px 0px 8px 8px",
          border: "1px solid #F0F0F0",
        }}
      >
        <ETButton
          variant={"white"}
          buttonText="CANCEL"
          onClick={() => setAddExtraPerson(false)}
        />
        <ETButton
          type="submit"
          variant={"primary"}
          buttonText="ADD"
          disabled={postAddExtraSigningOrderPersonIsLoading}
        />
      </div>
    </Form>
  );
};

export default AddExtraSigningPerson;
