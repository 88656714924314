import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toggle from "../../../../Components/Switch/Switch";
import { Controller, useForm } from "react-hook-form";
import ETButton from "../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../Components/CustomPopup";
import FilterComponent from "../../../../reactdataTableComponent/FilterComponent";
import "../actorcustomers.css";
import Menus from "../../../../Components/Menus/Menus";
import moment from "moment";
import PasswordField from "../../../../Components/GroupText/PasswordField";
import { H3, StyledCard } from "../../../../styles/Common.style";
import {
  useGetGeographyCountry,
  useGetStreetDetails,
  useGetZipcodeByCountry,
} from "../../../../hooks/queries/useGeographyQueries";
import {
  useGetActorsBySearch,
  usePostAddActor,
  usePutUpdateActor,
} from "../../../../hooks/queries/useActorQueries";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import { toast } from "react-toastify";
import CustomToolTip from "../../../../Components/Tooltip/CustomTooltip";
import { useUnitOverview } from "../../../../hooks/stores/useUnitStore";
import { usePropertyOverview } from "../../../../hooks/stores/usePropertyStore";
import { DeleteContractActor } from "../../../../services/Actors";
import { status } from "../../../../model/Constant";
import { ReservationForm } from "./ReservationForm";
import { useBreadcrumb } from "../../../../Components/BreadcrumbContext ";
import InputField from "../../../../Components/Textfield/InputField";

const CustomerCard = ({
  data,
  setAddNewCustomer,
  setActorId,
  setOpenDeleteActorPopup,
}) => {
  return (
    <>
      <div
        className="actor-customer-card radius-8 align-items-center"
        style={{ border: "1px solid #F0F0F0" }}
      >
        <div className="p-3 light-font-text" style={{ position: "relative" }}>
          <div className="mb-2">
            <span className="form-label-title text-break">
              #{data?.actor_id}
            </span>
          </div>
          <Row>
            <Col>
              <div className="mb-2">
                <p className="mb-0 form-label-title">First name</p>
                <span className=" text-break">
                  {data?.first_name || data?.firstname}
                </span>
              </div>
            </Col>
            <Col>
              <div className="mb-2">
                <p className="mb-0 form-label-title">Last name</p>
                <span className=" text-break">
                  {data?.last_name || data?.lastname}
                </span>
              </div>
            </Col>
          </Row>
          <div className="mb-2">
            <p className="mb-0 form-label-title">Email</p>
            <span className=" text-break">{data?.email || "-"}</span>
          </div>
          <div className="mb-2">
            <p className="mb-0 form-label-title">Phone</p>
            <span className=" text-break">
              {data?.countryCode?.label || data?.countryCode}{" "}
              {data?.phone_number || data?.mobilenumber}
            </span>
          </div>
          <div className="mb-2">
            <p className="mb-0 form-label-title">DOB</p>
            <span className=" text-break">
              {data?.birthdate == ""
                ? "-"
                : moment(data?.birthdate).format("DD MMM, YYYY")}
            </span>
          </div>
          <div className="mb-1">
            <p className="mb-0 form-label-title">Address</p>
            <div
              style={{
                textOverflow: "ellipsis",
                textWrap: "nowrap",
                overflow: "hidden",
              }}
            >
              <span className=" text-break">
                {data?.street_name || data?.street} {data?.house_number}&nbsp;
              </span>
              <span className=" text-break">
                {data?.zipCode?.value || data?.zipCode} {data?.city}
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="mb-2 ">
              <p className="mb-0 form-label-title">SSN</p>
              <span className=" text-break">
                {data?.social_security_number === ""
                  ? "-"
                  : data?.social_security_number}
              </span>
            </div>
            <div className="d-flex align-items-center">
              <Menus
                itemheight={24}
                itemwidth={24}
                iconborderradius="4px"
                component={
                  <div style={{ fontSize: "14px" }}>
                    <div
                      className="addpropContent px-3 py-2 "
                      onClick={() => {
                        setActorId(data?.actor_id);
                        setAddNewCustomer(true);
                      }}
                    >
                      <span className="ms-1">Edit</span>
                    </div>
                    <hr className="m-0"></hr>
                    <div
                      className="addpropContent px-3 pt-2 pb-2"
                      onClick={() => {
                        setOpenDeleteActorPopup((prev) => ({
                          ...prev,
                          deleteActorPopup: true,
                          actor_id: data?.actor_id,
                        }));
                      }}
                    >
                      <span className="ms-1">Delete</span>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const Tenants = ({
  actorByContractIdData,
  actorByContractIdDataRefetch,
  contractId,
  contractOverViewData,
  initialSelectedName,
  selectedName,
  tanentNameTab,
}) => {
  //--------------------form validations------------------//
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
    getValues,
    clearErrors,
  } = useForm();

  //-----------------------------Zustand---------------------------------//
  const unitOverview = useUnitOverview((state) => state.unitOverview);
  const propOverview = usePropertyOverview((state) => state.propOverview);

  const { updateBreadcrumbs } = useBreadcrumb();
  useEffect(() => {
    updateBreadcrumbs(["Contracts", initialSelectedName, "Tenants"]);
  }, [initialSelectedName]);

  useEffect(() => {
    if (tanentNameTab) {
      updateBreadcrumbs(["Contracts", selectedName, "Tenants"]);
    } else {
    }
  }, [selectedName]);
  //-------------------------------states---------------------------------//
  const [sendReservInfoToCustomerActive, setSendReservInfoToCustomerActive] =
    useState(true);
  const [sendScreeningToCustomerActive, setSendScreeningToCustomerActive] =
    useState(true);
  const [is2FaEnabled, setIs2FaEnabled] = useState(true);
  const [addNewCustomer, setAddNewCustomer] = useState(false);
  const [actorId, setActorId] = useState(""); //ActorId is only used for editing customer & managing condition for add customer popup
  const [searchText, setSearchText] = useState("");
  const [resultDiv, setResultDiv] = useState(false);
  const [searchdiv, setSearchDiv] = useState(true);
  const [isSearchStreetVisible, setIsSearchStreetVisible] = useState(false);
  const [searchedStreetName, setSearchedStreetName] = useState("");

  //-------------------checkbox states------------------//
  const [isPrimary, setIsPrimary] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [isCoResident, setIsCoResident] = useState(false);
  const [isSubTenant, setIsSubTenant] = useState(false);
  const [isGuardian, setIsGuardian] = useState(false);
  const [isInCompetent, setIsInCompetent] = useState(false);
  const [isSigningContract, setIsSigningContract] = useState(false);

  const [existingActorDetailsData, setExistingActorDetailsData] = useState([]);
  const [customerCardData, setCustomerCardData] = useState([]);
  const [customerDetailsData, setCustomerDetailsData] = useState([]);
  const [isSsnExist, setIsSsnExist] = useState(false);

  const [compareCustomer, setCompareCustomer] = useState(false);
  const [openDeleteActorPopup, setOpenDeleteActorPopup] = useState({
    actor_id: "",
    deleteActorPopup: false,
  });
  const [unCheckedPrimaryPopup, setUnCheckedPrimaryPopup] = useState(false);
  //-----------------------------react query------------------------------//
  const { data: countryData } = useGetGeographyCountry();
  const { data: zipCodeByCountryIdData } = useGetZipcodeByCountry(
    watch("country")?.value
  );
  const { mutateAsync: PostAddActor, isLoading: PostAddActorIsLoading } =
    usePostAddActor();
  const { mutateAsync: PutUpdateActor, isLoading: PutUpdateActorIsLoading } =
    usePutUpdateActor();
  const { data: GetActorsBySearch } = useGetActorsBySearch(searchText);
  const { data: getStreetDetailsData } =
    useGetStreetDetails(searchedStreetName);

  const displayItem = (selected) => {
    const countyName = countryData?.data?.filter(
      (item, i) => item.Id === selected?.value
    )?.[0]?.Text;

    return {
      value: selected?.Id,
      label: countryData?.data?.filter(
        (item, i) => item.Id === selected?.value
      )?.[0]?.Text,
    };
  };
  useEffect(() => {
    setCustomerCardData(actorByContractIdData?.data);

    return () => {};
  }, [actorByContractIdData]);

  //--------------------handle select actor from search-------------------//
  const allSelectedData = (data) => {
    clearErrors();
    setValue("firstname", data?.firstname);
    setValue("lastname", data?.lastname);
    setValue("email", data?.email);
    setValue("birthdate", data?.birthdate);
    setValue("mobilenumber", data?.phone_number);
    setValue("floor_no", data?.floor_no);
    setValue("house_number", data?.house_number);
    setValue("street", data?.street_name);
    setValue("side_door", data?.side_door);
    setValue("country", {
      value: data?.country_id,
      label: data?.country,
    });
    setValue("city", data?.city);
    setValue("countryCode", {
      value: data?.country_code,
      label: data?.country_code,
    });
    setValue("zipCode", {
      value: data?.zipCode,
      label: `${data?.zipCode} ${
        zipCodeByCountryIdData?.data?.filter(
          (el) => el.Id == data?.zipCode
        )?.[0]?.Text
      }`,
    });
    setValue("social_security_number", data?.social_security_number);
    setIs2FaEnabled(data?.is_mobilenumber_validated === 1 ? true : false);
  };

  // --------------------------handle zipcode change-----------------------//
  const handleZipCode = (e, ActionType) => {
    if (ActionType.action === "clear") {
      setValue("city", "");
    } else {
      if (e?.value != "" || e?.value != "undefined") {
        const HandleCityName = zipCodeByCountryIdData?.data?.filter(
          (data) => data?.Id == e?.value
        );
        const CityData = HandleCityName[0].Text;
        setValue("city", CityData, { shouldValidate: true });
      }
    }
  };

  //-----------------------handle actor search--------------------//
  const searchField = (e) => {
    setSearchText(e.target.value);
    if (searchText.length > 0) {
      setResultDiv(true);
      setSearchDiv(true);
    } else {
      setResultDiv(false);
    }
  };
  //--------------------handle add actor-----------------------//
  const handleAddCustomer = async (data) => {
    if (actorId === "") {
      setCustomerDetailsData(data);
      const addActorPayload = {
        email: data?.email,
        firstname: data?.firstname,
        mobilenumber: data?.mobilenumber,
        lastname: data?.lastname,
        birthdate: data?.birthdate,
        country_id_nationality: data?.country?.value,
        phone_calling_code_id: data?.countryCode?.label,
        street_name: data?.street,
        house_number: data?.house_number,
        side_door: data?.side_door,
        country_id: data?.country?.value,
        zipcode_id: data?.zipCode?.value,
        social_security_number: data?.social_security_number,
        floor_no: data?.floor_no,
        send_reservation: sendReservInfoToCustomerActive ? "1" : "0",
        screening_invitation: sendScreeningToCustomerActive ? "1" : "0",
        is_mobilenumber_validated: is2FaEnabled ? "1" : "0",
        force_insert: "0",
        contract_id: contractId,
        is_primary: isPrimary ? "1" : "0",
        is_paying: isPaying ? "1" : "0",
        is_coresident: isCoResident ? "1" : "0",
        is_subtenant: isSubTenant ? "1" : "0",
        is_guardian: isGuardian ? "1" : "0",
        guardian_for_actor_id: data?.guardian?.value || "0",
        is_incompetent: isInCompetent ? "1" : "0",
        is_incompetent: isSigningContract ? "1" : "0",
      };
      PostAddActor(addActorPayload)
        .then((response) => {
          if (response.data.bReturn) {
            actorByContractIdDataRefetch();
            // setReservationDetailPage(true);
            toast.success(response.data.message);
            setCustomerCardData([
              ...customerCardData,
              {
                ...data,
                actor_id: response?.data?.data?.actor_details?.actor_id,
                is_primary: isPrimary ? "1" : "0",
                is_paying: isPaying ? "1" : "0",
                is_coresident: isCoResident ? "1" : "0",
                is_subtenant: isSubTenant ? "1" : "0",
                is_guardian: isGuardian ? "1" : "0",
                guardian_for_actor_id: data?.guardian?.value || "0",
                is_incompetent: isInCompetent ? "1" : "0",
                is_signing_contract: isSigningContract ? "1" : "0",
              },
            ]);

            setAddNewCustomer(false);
            addCustomerFormReset();
          } else {
            if (response?.data?.ssn_exist === 1) {
              setIsSsnExist(true);
            } else {
              setIsSsnExist(false);
            }
            setAddNewCustomer(false);
            setCompareCustomer(true);
            setExistingActorDetailsData(response?.data?.data?.actor_details);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      const updateActorPayload = {
        actor_id: actorId,
        email: data?.email,
        firstname: data?.firstname,
        mobilenumber: data?.mobilenumber,
        lastname: data?.lastname,
        birthdate: data?.birthdate,
        country_id_nationality: data?.country?.value,
        phone_calling_code_id: data?.countryCode?.label,
        street_name: data?.street,
        house_number: data?.house_number,
        floor_no: data?.floor_no,
        side_door: data?.side_door,
        country_id: data?.country?.value,
        zipcode_id: data?.zipCode?.value,
        social_security_number: data?.social_security_number,
        send_reservation: sendReservInfoToCustomerActive ? "1" : "0",
        screening_invitation: sendScreeningToCustomerActive ? "1" : "0",
        is_mobilenumber_validated: is2FaEnabled ? "1" : "0",
        contract_id: contractId,
        is_primary: isPrimary ? "1" : "0",
        is_paying: isPaying ? "1" : "0",
        is_coresident: isCoResident ? "1" : "0",
        is_subtenant: isSubTenant ? "1" : "0",
        is_guardian: isGuardian ? "1" : "0",
        guardian_for_actor_id: data?.guardian?.value || "0",
        is_incompetent: isInCompetent ? "1" : "0",
        is_signing_contract: isSigningContract ? "1" : "0",
      };
      PutUpdateActor(updateActorPayload)
        .then((response) => {
          if (response.data.bReturn) {
            actorByContractIdDataRefetch();
            // setReservationDetailPage(true);
            toast.success(response.data.message);
            setAddNewCustomer(false);
            setCustomerCardData((prev) =>
              prev?.map((val) => {
                if (val.actor_id === actorId) {
                  return {
                    ...val,
                    ...data,
                    is_primary: isPrimary ? "1" : "0",
                    is_paying: isPaying ? "1" : "0",
                    is_coresident: isCoResident ? "1" : "0",
                    is_subtenant: isSubTenant ? "1" : "0",
                    is_guardian: isGuardian ? "1" : "0",
                    guardian_for_actor_id: data?.guardian?.value || "0",
                    is_incompetent: isInCompetent ? "1" : "0",
                    is_signing_contract: isSigningContract ? "1" : "0",
                  };
                }

                return val;
              })
            );

            setAddNewCustomer(false);
            addCustomerFormReset();
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => toast.error(error.message));
    }
  };
  //---------------------------handle update actor-------------------------//

  const handleUpdateAndUse = () => {
    const updateActorPayload = {
      actor_id:
        actorId === ""
          ? existingActorDetailsData?.actor_id?.toString()
          : actorId,
      email: customerDetailsData?.email,
      firstname: customerDetailsData?.firstname,
      mobilenumber: customerDetailsData?.mobilenumber,
      lastname: customerDetailsData?.lastname,
      birthdate: customerDetailsData?.birthdate,
      country_id_nationality: customerDetailsData?.country?.value,
      phone_calling_code_id: customerDetailsData?.countryCode?.label,
      street_name: customerDetailsData?.street,
      house_number: customerDetailsData?.house_number,
      floor_no: customerDetailsData?.floor_no,
      side_door: customerDetailsData?.side_door,
      country_id: customerDetailsData?.country?.value,
      zipcode_id: customerDetailsData?.zipCode?.value,
      social_security_number: customerDetailsData?.social_security_number,
      send_reservation: sendReservInfoToCustomerActive ? "1" : "0",
      screening_invitation: sendScreeningToCustomerActive ? "1" : "0",
      is_mobilenumber_validated: is2FaEnabled ? "1" : "0",
      contract_id: contractId,
      is_primary: isPrimary ? "1" : "0",
      is_paying: isPaying ? "1" : "0",
      is_coresident: isCoResident ? "1" : "0",
      is_subtenant: isSubTenant ? "1" : "0",
      is_guardian: isGuardian ? "1" : "0",
      guardian_for_actor_id: customerDetailsData?.guardian?.value || "0",
      is_incompetent: isInCompetent ? "1" : "0",
      is_signing_contract: isSigningContract ? "1" : "0",
    };

    PutUpdateActor(updateActorPayload)
      .then((response) => {
        if (response.data.bReturn) {
          toast.success(response.data.message);
          setCompareCustomer(false);
          // setReservationDetailPage(true);
          if (customerCardData?.length !== 0) {
            if (
              customerCardData?.filter(
                (item) =>
                  item?.actor_id ===
                  existingActorDetailsData?.actor_id?.toString()
              )?.length > 0
            ) {
              setCustomerCardData((prev) =>
                prev.map((val) => {
                  if (
                    val.actor_id ===
                    existingActorDetailsData?.actor_id?.toString()
                  ) {
                    return {
                      ...val,
                      ...customerDetailsData,
                      is_primary: isPrimary ? "1" : "0",
                      is_paying: isPaying ? "1" : "0",
                      is_coresident: isCoResident ? "1" : "0",
                      is_subtenant: isSubTenant ? "1" : "0",
                      is_guardian: isGuardian ? "1" : "0",
                      guardian_for_actor_id:
                        customerDetailsData?.guardian?.value || "0",
                      is_incompetent: isInCompetent ? "1" : "0",
                      is_signing_contract: isSigningContract ? "1" : "0",
                    };
                  }
                  return val;
                })
              );
            } else {
              setCustomerCardData([
                ...customerCardData,
                {
                  ...customerDetailsData,
                  actor_id: String(existingActorDetailsData?.actor_id),
                  is_primary: isPrimary ? "1" : "0",
                  is_paying: isPaying ? "1" : "0",
                  is_coresident: isCoResident ? "1" : "0",
                  is_subtenant: isSubTenant ? "1" : "0",
                  is_guardian: isGuardian ? "1" : "0",
                  guardian_for_actor_id:
                    customerDetailsData?.guardian?.value || "0",
                  is_incompetent: isInCompetent ? "1" : "0",
                  is_signing_contract: isSigningContract ? "1" : "0",
                },
              ]);
            }
          } else {
            setCustomerCardData([
              ...customerCardData,
              {
                ...customerDetailsData,
                actor_id: String(existingActorDetailsData?.actor_id),
                is_primary: isPrimary ? "1" : "0",
                is_paying: isPaying ? "1" : "0",
                is_coresident: isCoResident ? "1" : "0",
                is_subtenant: isSubTenant ? "1" : "0",
                is_guardian: isGuardian ? "1" : "0",
                guardian_for_actor_id:
                  customerDetailsData?.guardian?.value || "0",
                is_incompetent: isInCompetent ? "1" : "0",
                is_signing_contract: isSigningContract ? "1" : "0",
              },
            ]);
          }

          actorByContractIdDataRefetch();
          setAddNewCustomer(false);
          addCustomerFormReset();
        } else {
          setAddNewCustomer(true);
          toast.error(response.data.message);
          setCompareCustomer(false);
        }
      })
      .catch((error) => toast.error(error.message));
  };

  //-----------------------handle delete actor--------------------//
  const handleDeleteActor = async () => {
    const response = await DeleteContractActor(
      contractId,
      openDeleteActorPopup?.actor_id
    );
    try {
      if (response.status == status.s && response.data.bReturn) {
        actorByContractIdDataRefetch();
        setOpenDeleteActorPopup((prev) => ({
          ...prev,
          deleteActorPopup: false,
        }));
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.success(error.message);
    }
  };

  useEffect(() => {
    if (actorId !== "") {
      const filterCustomerData = customerCardData?.filter(
        (item) => item?.actor_id === actorId
      );
      setValue("firstname", filterCustomerData?.[0]?.first_name);
      setValue("lastname", filterCustomerData?.[0]?.last_name);
      setValue("email", filterCustomerData?.[0]?.email);
      setValue("birthdate", filterCustomerData?.[0]?.birthdate);
      setValue("mobilenumber", filterCustomerData?.[0]?.phone_number);
      setValue("floor_no", filterCustomerData?.[0]?.floor_no);
      setValue("house_number", filterCustomerData?.[0]?.house_number);
      setValue("street", filterCustomerData?.[0]?.street_name);
      setValue("side_door", filterCustomerData?.[0]?.side_door);
      setValue("country", {
        value: filterCustomerData?.[0]?.country_id,
        label: filterCustomerData?.[0]?.country,
      });
      setValue("city", filterCustomerData?.[0]?.city);
      setValue("countryCode", {
        value: filterCustomerData?.[0]?.country_code,
        label: filterCustomerData?.[0]?.country_code,
      });
      setValue("zipCode", {
        value: filterCustomerData?.[0]?.zipCode,
        label: filterCustomerData?.[0]?.city,
      });
      setValue(
        "social_security_number",
        filterCustomerData?.[0]?.social_security_number
      );

      setValue("guardian", {
        value: filterCustomerData?.[0]?.guardian_for_actor_id,
        label: `${
          customerCardData?.filter(
            (val) =>
              val?.actor_id == filterCustomerData?.[0]?.guardian_for_actor_id
          )?.[0]?.first_name
        } ${
          customerCardData?.filter(
            (val) =>
              val?.actor_id == filterCustomerData?.[0]?.guardian_for_actor_id
          )?.[0]?.last_name
        }`,
      });
      setIs2FaEnabled(
        filterCustomerData?.[0]?.is_mobilenumber_validated == 1 ? true : false
      );
      setSendScreeningToCustomerActive(
        filterCustomerData?.[0]?.send_screening_invitation_to_customer == 1
          ? true
          : false
      );
      setSendReservInfoToCustomerActive(
        filterCustomerData?.[0]?.send_reservation_to_customer == 1
          ? true
          : false
      );

      setIsPrimary(filterCustomerData?.[0]?.is_primary == "1" ? true : false);
      setIsPaying(filterCustomerData?.[0]?.is_paying == "1" ? true : false);
      setIsCoResident(
        filterCustomerData?.[0]?.is_coresident == "1" ? true : false
      );
      setIsSubTenant(
        filterCustomerData?.[0]?.is_subtenant == "1" ? true : false
      );
      setIsGuardian(filterCustomerData?.[0]?.is_guardian == "1" ? true : false);
      setIsInCompetent(
        filterCustomerData?.[0]?.is_incompetent == "1" ? true : false
      );
      setIsSigningContract(
        filterCustomerData?.[0]?.is_signing_contract == "1" ? true : false
      );
      setCustomerDetailsData(filterCustomerData?.[0]);
    }
    return () => {};
  }, [customerCardData, actorId]);

  //---------------------handle primary checkbox click-----------------//
  const handleCheckedPrimary = (e) => {
    if (actorId == "") {
      if (customerCardData?.some((e) => e?.is_primary == "1")) {
        toast.info("Primary customer already exists");
      } else {
        setIsPrimary(e.target.checked);
        if (e.target.checked) {
          setIsSigningContract(true);
        }
      }
    } else {
      if (
        customerCardData?.filter((data) => data?.actor_id == actorId)?.[0]
          ?.is_primary == 1
      ) {
        toast.info("Cannot remove this customer from primary");
      } else {
        setUnCheckedPrimaryPopup(true);
      }
    }
  };

  //--------------------handle update primary actor----------------------//
  const handleUpdatePrimaryActor = () => {
    const filteredCustomerData = customerCardData?.filter(
      (item) => item?.actor_id === actorId
    )?.[0];
    const updatePrimaryActor = {
      actor_id: filteredCustomerData?.actor_id,
      email: getValues("email"),
      firstname: getValues("firstname"),
      mobilenumber: getValues("mobilenumber"),
      lastname: getValues("lastname"),
      birthdate: getValues("birthdate"),
      country_id_nationality: countryData?.data?.filter(
        (data) => data?.DataValue2 == getValues("countryCode")?.value
      )?.[0]?.Id,
      phone_calling_code_id: getValues("countryCode")?.value,
      street_name: getValues("street"),
      house_number: getValues("house_number"),
      floor_no: getValues("floor_no"),
      side_door: getValues("side_door"),
      country_id: getValues("country")?.value,
      zipcode_id: getValues("zipCode")?.value,
      social_security_number: getValues("social_security_number"),
      send_reservation: sendReservInfoToCustomerActive ? "1" : "0",
      screening_invitation: sendScreeningToCustomerActive ? "1" : "0",
      is_mobilenumber_validated: is2FaEnabled ? "1" : "0",
      contract_id: contractId,
      is_primary: "1",
      is_paying: isPaying ? "1" : "0",
      is_coresident: isCoResident ? "1" : "0",
      is_subtenant: isSubTenant ? "1" : "0",
      is_guardian: isGuardian ? "1" : "0",
      guardian_for_actor_id: getValues("guardian")?.value || "0",
      is_incompetent: isInCompetent ? "1" : "0",
      is_signing_contract: "1",
    };
    PutUpdateActor(updatePrimaryActor)
      .then((response) => {
        if (response.data.bReturn) {
          toast.success(response.data.message);
          setCompareCustomer(false);
          setUnCheckedPrimaryPopup(false);
          actorByContractIdDataRefetch();
          setAddNewCustomer(false);
          addCustomerFormReset();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => toast.error(error.message));
  };
  //------------function to reset form data & other states---------------//
  function addCustomerFormReset() {
    reset();
    setIsPrimary(false);
    setIsPaying(false);
    setIsCoResident(false);
    setIsSubTenant(false);
    setIsGuardian(false);
    setIsInCompetent(false);
    setIsSigningContract(false);
    setSearchText("");
    setActorId("");
  }

  // For Filter Street Name
  const handleStreetDetailChange = (e) => {
    if (watch("country")?.value == "DK" && e.target.value?.length >= 4) {
      setIsSearchStreetVisible(true);
      setSearchedStreetName(e.target.value);
    } else {
      setIsSearchStreetVisible(false);
    }
  };

  //When user selects street details from dorpdown
  const handleStreetDetailClick = (item) => {
    setIsSearchStreetVisible(false);
    setValue("street", item?.street_name);
    setValue("city", item?.city, { shouldValidate: item?.city !== "" && true });
    setValue("house_number", item?.house_number, {
      shouldValidate: item?.house_number !== "" && true,
    });
    setValue(
      "zipCode",
      item?.zipcode == ""
        ? ""
        : {
            value: item?.zipcode,
            label: zipCodeByCountryIdData?.data?.filter(
              (el) => el.Id == item?.zipcode
            )?.[0]?.Text,
          },
      { shouldValidate: item?.zipcode !== "" && true }
    );
  };

  return (
    <>
      <div className="px-4 pb-4 pt-2">
        <Row className="pt-4">
          {/* signing order card component */}
          {customerCardData?.map((item, index) => (
            <Col key={index} className="mb-4" lg={6} xl={4}>
              <CustomerCard
                key={index}
                data={item}
                setAddNewCustomer={setAddNewCustomer}
                setOpenDeleteActorPopup={setOpenDeleteActorPopup}
                setActorId={setActorId}
              />
            </Col>
          ))}

          <Col lg={6} xl={4} className="mb-4">
            <StyledCard height="350px">
              <ETButton
                variant={"primary"}
                buttonText="ADD CUSTOMER"
                icon={true}
                width="fit-content"
                onClick={() => {
                  if (
                    contractOverViewData?.data?.[0]?.order_sent_to_signing == 1
                  ) {
                    toast.info(
                      "Cannot add customer as signing order is already sent for signing"
                    );
                  } else {
                    setAddNewCustomer(true);
                    addCustomerFormReset();
                  }
                }}
              />
            </StyledCard>
          </Col>
          {/* )} */}
        </Row>
      </div>

      <CustomPopup
        open={addNewCustomer}
        onHide={() => {
          setAddNewCustomer(false);
          addCustomerFormReset();
        }}
        headerRequired={false}
        size={"lg"}
        title_color="black"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="px-0 bg-white radius-8 ">
          <div
            className="py-3 px-4 d-sm-flex justify-content-between "
            style={{ borderBottom: "1px solid #F4F5F5" }}
          >
            <div className="d-flex justify-content-center align-items-center gap-2">
              <H3>Customer details</H3>

              <CustomToolTip size="sm" text="Customer details" />
            </div>
            <div style={{ fontSize: "14px", fontWeight: "300", color: "#888" }}>
              {propOverview?.[0]?.propertyName} | {unitOverview?.[0]?.Address}
            </div>
          </div>
          {actorId === "" && ( //actorId is generated only when clicked on edit
            <>
              <Row className="px-4 mt-3">
                <Col lg={8} className="fs-14 ">
                  <div className=" form-label-title ">
                    Search among existing customer
                  </div>
                  <div className="fw-300 grey-text">
                    If you think the customer may already be registered in the
                    system, maybe as a previous tenant, an visitor at a event or
                    as a lead from a campaign, you can find the customer by
                    searching below
                  </div>
                </Col>
              </Row>

              <Row className="px-4 pt-3 pb-4">
                <Col
                  lg={8}
                  sm={12}
                  style={{
                    position: " relative",
                  }}
                >
                  <div
                    style={{
                      position: " relative",
                    }}
                  >
                    <FilterComponent
                      filtertext={searchText}
                      placeholder="123"
                      handleSearch={(e) => searchField(e)}
                      lableText="E.g. name, email, mobilenumber, social security number"
                    />
                  </div>

                  {searchText?.length > 0 && searchdiv == true && (
                    <div style={{}}>
                      <div
                        className="bg-white radius-8 pe-1"
                        style={{
                          border: "1px solid #D1D1D1",
                          position: "absolute",
                          top: "50px",
                          left: "14px",
                          zIndex: "10",
                          width: "97%",
                        }}
                      >
                        {searchText?.length < 2 && (
                          <div className="fs-14 fw-300 grey-text py-2 px-3">
                            Type in minimum 2 characters to get a search result
                          </div>
                        )}
                        {searchText?.length >= 2 && (
                          <>
                            {GetActorsBySearch?.data?.length > 0 ? (
                              <div
                                className="scrollable-container-y"
                                style={{
                                  height: "400px",
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                }}
                              >
                                {GetActorsBySearch?.data?.map((data, index) => (
                                  <div key={index}>
                                    <Row
                                      className="fs-14 fw-300 pt-2 ps-3 pe-1 addpropContent"
                                      style={{
                                        borderBottom: "1px solid #D1D1D1",
                                      }}
                                      onClick={() => {
                                        allSelectedData(data);
                                        setSearchDiv(false);
                                      }}
                                    >
                                      <Col md={5}>
                                        <div className="d-flex flex-column my-2">
                                          <span className="fw-600">
                                            {data?.firstname} {data?.lastname}
                                          </span>{" "}
                                          {/* <span>{data?.social_security_number}</span> */}
                                        </div>
                                        <div className="d-flex flex-column my-2">
                                          <span>{data?.email}</span>{" "}
                                          <span>{data?.phone_number}</span>
                                        </div>
                                        <div className="d-flex flex-column my-2">
                                          <span>
                                            {data?.birthdate == ""
                                              ? "-"
                                              : moment(data?.birthdate).format(
                                                  "DD MMM, YYYY"
                                                )}
                                          </span>{" "}
                                          <span>
                                            {data?.social_security_number}
                                          </span>{" "}
                                        </div>
                                        <div className="d-flex flex-column my-2">
                                          <span>
                                            {data?.street_name +
                                              " " +
                                              data?.house_number}
                                          </span>
                                          <span>
                                            {data?.zipCode + " " + data?.city}
                                          </span>
                                        </div>
                                      </Col>
                                      <Col md={7}>
                                        <div className="fw-600 mt-2 ">
                                          {/* Known activities */}Lorem Ipsum
                                        </div>
                                        <div className="d-flex flex-column mb-3 fs-12">
                                          <span>
                                            {/* Private viewing at Terrasserne 26
                                            Apr, 2023 */}
                                            Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit,
                                          </span>
                                          <span>
                                            {/* Open House at Terrasserne 23 Apr,
                                            2023 */}
                                            Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit,
                                          </span>
                                          <span>
                                            {/* Open House at Blomsterbjerget 16
                                            Mar, 2023 */}
                                            Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit,
                                          </span>
                                          <span>Lead at 12 Mar, 2023</span>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className="fs-14 fw-300 py-2 px-3">
                                Sorry, it was not possible to find a match among
                                existing customers. Please fill in the form
                                below to create a new customer and complete the
                                reservation.
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <hr className="hr mt-1 mb-0"></hr>
            </>
          )}
          <Form onSubmit={handleSubmit(handleAddCustomer)}>
            <div
              className="scrollable-container-y"
              style={{ height: "400px", overflow: "scroll" }}
            >
              <div className="fs-14 py-4 px-4 ms-1  form-label-title">
                Customer information
              </div>
              <Row className="mx-3">
                <Col lg={5} md={6} className="pe-lg-0 pb-3">
                  <Controller
                    control={control}
                    {...register("firstname")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        {/* <input
                          defaultValue=""
                          type="text"
                          placeholder="First name "
                          id="firstname"
                          value={field.value}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            clearErrors("firstname");
                          }}
                        /> */}
                        <InputField
                          id={"firstname"}
                          value={value}
                          type={"text"}
                          placeholder={"First name"}
                          iserror={errors?.firstname}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("firstname");
                          }}
                        />
                        {/* <label htmlFor="firstname">First name </label> */}
                      </div>
                    )}
                  />
                  {/* {errors?.firstname && (
                    <p className="error-msg">This field is required</p>
                  )} */}
                </Col>
                <Col lg={5} md={6} className="pe-lg-0 pb-3">
                  <Controller
                    control={control}
                    {...register("lastname")}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <div className="form-input ">
                        <input
                          defaultValue=""
                          type="text"
                          placeholder="Last name"
                          id="lastname"
                          value={field.value}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            clearErrors("lastname");
                          }}
                        />
                        <label htmlFor="lastname">Last name</label>
                      </div>
                    )}
                  />
                  {errors?.lastname && (
                    <p className="error-msg">This field is required</p>
                  )}
                </Col>
              </Row>

              <Row className="mx-3">
                <Col lg={5} md={6} className="pe-lg-0 pb-3">
                  <Controller
                    control={control}
                    {...register("email")}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        {/* <input
                          defaultValue=""
                          type="email"
                          placeholder="Email (required)"
                          id="Email"
                          value={field.value}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            clearErrors("email");
                          }}
                        /> */}
                        <InputField
                          id={"Email"}
                          value={value}
                          type={"email"}
                          placeholder={"Email"}
                          iserror={errors?.email}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("email");
                          }}
                        />
                        {/* <label htmlFor="Email">Email (required)</label> */}
                      </div>
                    )}
                  />
                  {/* {errors?.email && (
                    <p className="error-msg">This field is required</p>
                  )} */}
                </Col>
                <Col lg={5} md={6} className="pe-lg-0 pb-3">
                  <Controller
                    control={control}
                    {...register("birthdate")}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <div className="form-input ">
                        <input
                          defaultValue=""
                          type="date"
                          placeholder="DOB"
                          id="birthdate"
                          value={field.value}
                          {...field}
                          max={moment(new Date()).format("YYYY-MM-DD")}
                          onChange={(e) => {
                            field.onChange(e);
                            clearErrors("birthdate");
                          }}
                        />
                        <label htmlFor="birthdate">Date of birth</label>
                      </div>
                    )}
                  />
                  {errors?.birthdate && (
                    <p className="error-msg">This field is required</p>
                  )}
                </Col>
              </Row>

              <Row className="mx-3">
                <Col lg={10}>
                  <Row>
                    <Col md={6} lg={3} className="pe-lg-0 pb-3">
                      <Controller
                        control={control}
                        {...register("countryCode")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <ReactSelect
                            defaultValue=""
                            placeholder={"Country code"}
                            iserror={errors.countryCode}
                            options={countryData?.data?.map((item) => ({
                              value: item?.Id,
                              label: item?.DataValue2,
                            }))}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              clearErrors("countryCode");
                            }}
                          />
                        )}
                      />
                      {/* {errors.countryCode && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                    </Col>
                    <Col md={6} lg={9} className="pe-lg-0  pb-3">
                      <Controller
                        control={control}
                        {...register("mobilenumber")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            {/* <input
                              defaultValue=""
                              type="number"
                              placeholder="Phone"
                              id="Phone"
                              value={field.value}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors("mobilenumber");
                              }}
                              maxLength={15}
                            /> */}
                            <InputField
                              id={"Phone"}
                              value={value}
                              type={"number"}
                              maxLength={15}
                              placeholder={"Phone"}
                              iserror={errors?.mobilenumber}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("mobilenumber");
                              }}
                            />
                            {/* <label htmlFor="Phone">Phone</label> */}
                          </div>
                        )}
                      />
                      {/* {errors?.mobilenumber && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mx-3">
                <Col lg={10} className="pe-lg-0  pb-3">
                  <Controller
                    control={control}
                    {...register("social_security_number")}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <>
                        <PasswordField
                          defaultValue=""
                          id={"ssn"}
                          placeholder={"Social Security Number"}
                          value={field.value}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            clearErrors("social_security_number");
                          }}
                          maxLength={15}
                        />
                      </>
                    )}
                  />
                  {errors?.social_security_number && (
                    <p className="error-msg">Too Many Characters</p>
                  )}
                </Col>
              </Row>

              <Row className="mx-3">
                <Col lg={10} className="pe-lg-0 ">
                  <Row style={{ position: "relative" }}>
                    <Col xl={4} lg={4} md={6} className="mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("street")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            {/* <input
                              defaultValue=""
                              type="text"
                              placeholder="Street"
                              id="street"
                              value={field.value}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                handleStreetDetailChange(e);
                                clearErrors("street");
                              }}
                            /> */}
                            <InputField
                              id={"street"}
                              value={value}
                              type={"text"}
                              placeholder={"Street"}
                              iserror={errors.street}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("street");
                              }}
                            />
                            {/* <label htmlFor="street">Street </label> */}
                          </div>
                        )}
                      />
                      {/* {errors.street && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                      {watch("street")?.length < 4 &&
                      watch("street")?.length > 0 &&
                      watch("country")?.value == "DK" ? (
                        <div className="fs-12 fw-400 grey-text pt-1 ">
                          Type in minimum 4 characters to get a search result
                        </div>
                      ) : (
                        <>
                          {watch("country")?.value == "DK" &&
                            isSearchStreetVisible && (
                              <div>
                                <div
                                  className="bg-white radius-8 pe-1 filtered-list"
                                  style={{
                                    border: "1px solid #D1D1D1",
                                    position: "absolute",
                                    // top: "50px",
                                    // left: "14px",
                                    zIndex: "10",
                                    width: "31%",
                                  }}
                                >
                                  <>
                                    {getStreetDetailsData?.data?.length > 0 ? (
                                      <div>
                                        {getStreetDetailsData?.data?.map(
                                          (item, index) => (
                                            <React.Fragment key={index}>
                                              <div
                                                className="mt-1 fs-12"
                                                key={index}
                                                onClick={() => {
                                                  handleStreetDetailClick(item);
                                                }}
                                              >
                                                <span className="px-2">
                                                  {item?.city ||
                                                  item?.zipcode ||
                                                  item?.house_number ? (
                                                    <> {item?.address} </>
                                                  ) : (
                                                    <> {item?.street_name}</>
                                                  )}
                                                </span>
                                              </div>
                                              <hr className="hr mt-1 mb-0"></hr>
                                            </React.Fragment>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      <div className="mt-1 px-2 fw-300 grey-text fs-12">
                                        No data found
                                      </div>
                                    )}
                                  </>
                                </div>
                              </div>
                            )}
                        </>
                      )}
                    </Col>

                    <Col lg={2} md={6} className=" mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("house_number")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            {/* <input
                              defaultValue=""
                              type="text"
                              placeholder="No."
                              id="house_number"
                              value={field.value}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors("house_number");
                              }}
                            /> */}
                            <InputField
                              id={"house_number"}
                              value={value}
                              type={"text"}
                              placeholder={"No."}
                              iserror={errors.house_number}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("house_number");
                              }}
                            />
                            {/* <label htmlFor="house_number">No. </label> */}
                          </div>
                        )}
                      />
                      {/* {errors.house_number && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                    </Col>
                    <Col lg={2} md={6} className=" mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("floor_no")}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <div className="form-input ">
                            <input
                              defaultValue=""
                              type="number"
                              placeholder="Floor"
                              id="floor"
                              value={field.value}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                // setFloor(e.target.value);
                                clearErrors("floor_no");
                              }}
                            />
                            <label htmlFor="floor">Floor </label>
                          </div>
                        )}
                      />
                      {errors.floor_no && (
                        <p className="error-msg">This field is required</p>
                      )}
                    </Col>
                    <Col lg={4} md={6} className="mb-3">
                      <Controller
                        control={control}
                        {...register("side_door")}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <div className="form-input ">
                            <input
                              defaultValue=""
                              type="text"
                              placeholder="Side / Door"
                              id="Side/Door"
                              value={field.value}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors("side_door");
                              }}
                            />
                            <label htmlFor="Side/Door">Side / Door </label>
                          </div>
                        )}
                      />
                      {errors.side_door && (
                        <p className="error-msg">This field is required</p>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mx-3 ">
                <Col lg={10} className="pe-lg-0">
                  <Row>
                    <Col lg={4} className="mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("country")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <ReactSelect
                              defaultValue=""
                              placeholder={"Country"}
                              clearable={false}
                              options={countryData?.data?.map((item) => ({
                                value: item?.Id,
                                label: (
                                  <div
                                    className="d-flex align-items-center"
                                    style={{ fontFamily: "Cerebri sans" }}
                                  >
                                    <div className="radius-4 me-2">
                                      <img
                                        src={item?.DataValue1}
                                        style={{
                                          width: "20px",
                                          marginRight: "10px",
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <div className="fs-14 light-font-text ">
                                        {" "}
                                        {item?.Text}
                                      </div>
                                    </div>
                                  </div>
                                ),
                              }))}
                              value={
                                value === undefined ? "" : displayItem(value)
                              }
                              iserror={errors.country}
                              onChange={(e, ActionType) => {
                                onChange(e);
                                setValue("zipCode", "");
                                setValue("city", "");
                                setValue("street", "");
                                setValue("house_number", "");
                                clearErrors("country");
                              }}
                            />
                          );
                        }}
                      />

                      {/* {errors.country && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                    </Col>
                    <Col lg={4} md={6} className="mb-3 pe-lg-0">
                      <Controller
                        control={control}
                        {...register("zipCode")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <ReactSelect
                              defaultValue=""
                              placeholder={"Zip code"}
                              options={zipCodeByCountryIdData?.data?.map(
                                (zip) => ({
                                  value: zip?.Id,
                                  label: `${zip?.Id} ${zip?.Text}`,
                                })
                              )}
                              value={
                                value?.value
                                  ? {
                                      value: value?.value,
                                      label: value?.value,
                                    }
                                  : ""
                              }
                              iserror={errors.zipCode}
                              onChange={(e, ActionType) => {
                                handleZipCode(e, ActionType);
                                onChange(e);
                                clearErrors("zipCode");
                              }}
                            />
                          );
                        }}
                      />

                      {/* {errors.zipCode && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                    </Col>

                    <Col lg={4} md={6} className="mb-3">
                      <Controller
                        control={control}
                        {...register("city")}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            {/* <input
                              defaultValue=""
                              type="text"
                              disabled
                              placeholder="City"
                              id="city"
                              value={field.value}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors("city");
                              }}
                            /> */}
                            <InputField
                              id={"city"}
                              value={value}
                              type={"text"}
                              placeholder={"City"}
                              iserror={errors.city}
                              onChange={(e) => {
                                onChange(e);
                                clearErrors("city");
                              }}
                            />
                            {/* <label htmlFor="city">City</label> */}
                          </div>
                        )}
                      />
                      {/* {errors.city && (
                        <p className="error-msg">This field is required</p>
                      )} */}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr className="hr mt-1 mb-0"></hr>
              <div className="fs-14 py-4 px-4 ms-1  form-label-title">
                Customer role
              </div>
              <div className="px-3" style={{ width: "80%" }}>
                <Row>
                  <Col lg={3} md={6} className="pe-lg-0">
                    <CustomCheckBox
                      label={"Primary"}
                      checked={isPrimary}
                      onChange={(e) => handleCheckedPrimary(e)}
                    />
                  </Col>
                  <Col lg={3} md={6} className="px-lg-0">
                    <CustomCheckBox
                      label={"Paying"}
                      checked={isPaying}
                      onChange={(e) => setIsPaying(e.target.checked)}
                    />
                  </Col>
                  <Col lg={3} md={6} className="px-lg-0">
                    <CustomCheckBox
                      label={"Co-Resident"}
                      checked={isCoResident}
                      onChange={(e) => setIsCoResident(e.target.checked)}
                    />
                  </Col>
                  <Col lg={3} md={6} className="px-lg-0">
                    <CustomCheckBox
                      label={"Sub-Tenant"}
                      checked={isSubTenant}
                      onChange={(e) => setIsSubTenant(e.target.checked)}
                    />
                  </Col>
                </Row>
              </div>
              <div className="pb-3 px-3" style={{ width: "80%" }}>
                <Row>
                  <Col lg={3} md={6} className="pe-lg-0">
                    <CustomCheckBox
                      label={"Incompetent"}
                      checked={isInCompetent}
                      onChange={(e) => {
                        isGuardian
                          ? toast?.info("Guardian cannot be set as incompetent")
                          : setIsInCompetent(e.target.checked);
                      }}
                    />
                  </Col>
                  <Col lg={3} md={6} className="px-lg-0">
                    <CustomCheckBox
                      label={"Guardian"}
                      // disabled={
                      //   !customerCardData?.some(
                      //     (item) => item?.is_incompetent === "1"
                      //   )
                      // }
                      checked={isGuardian}
                      onChange={(e) => {
                        if (isInCompetent) {
                          toast.info(
                            "Cannot set incompetent customer as guardian"
                          );
                        } else {
                          if (customerCardData?.length < 1) {
                            toast.info(
                              "Single customer cannot be set as guardian"
                            );
                          } else {
                            if (
                              customerCardData?.some(
                                (item) => item?.is_incompetent == "1"
                              )
                            ) {
                              setIsGuardian(e.target.checked);
                              setValue("guardian", "");
                            } else {
                              toast.info(
                                "Atleast one customer should be incompetent"
                              );
                            }
                          }
                        }
                      }}
                    />
                  </Col>
                  {isGuardian && (
                    <Col sm={4} className="px-lg-0">
                      <Controller
                        control={control}
                        {...register("guardian")}
                        rules={{ required: isGuardian ? true : false }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <ReactSelect
                              defaultValue=""
                              height={"20px"}
                              placeholder={"Select customer"}
                              options={customerCardData
                                ?.filter((val) => val?.is_incompetent == "1")
                                ?.map((item) => ({
                                  value: item?.actor_id,
                                  label: `${item?.first_name} ${item?.last_name}`,
                                }))}
                              value={value}
                              onChange={(e, ActionType) => {
                                onChange(e);
                                clearErrors("guardian");
                              }}
                            />
                          );
                        }}
                      />

                      {errors.guardian && (
                        <p className="error-msg">This field is required</p>
                      )}
                    </Col>
                  )}
                </Row>
              </div>

              <hr className="hr mt-1 mb-0"></hr>
              <div className="fs-14 py-4 px-4 ms-1  form-label-title">
                Customer settings
              </div>
              <Row className="ms-3 mb-1 d-flex">
                <Col
                  md={8}
                  className=" d-flex"
                  style={{
                    fontWeight: "200",
                    fontSize: "14px",
                    alignItems: "center",
                  }}
                >
                  Will be signing contract?
                </Col>
                <Col xl={2} lg={2} md={2} className="px-0">
                  <Toggle
                    id={"isSigningContract"}
                    disabled={isPrimary}
                    label={"Sign contract"}
                    checked={isSigningContract}
                    onChange={(e) => setIsSigningContract(e.target.checked)}
                  />
                </Col>
              </Row>
              <Row className="ms-3 mb-1 d-flex">
                <Col
                  md={8}
                  className=" d-flex"
                  style={{
                    fontWeight: "200",
                    fontSize: "14px",
                    alignItems: "center",
                  }}
                >
                  Send reservation confirmation to the customer
                </Col>
                <Col xl={2} lg={2} md={2} className="px-0">
                  <Toggle
                    id={"sendReservInfoToCustomerActive"}
                    checked={sendReservInfoToCustomerActive}
                    onChange={(e) =>
                      setSendReservInfoToCustomerActive(e.target.checked)
                    }
                  />
                </Col>
              </Row>

              <Row className="ms-3 my-1  d-flex">
                <Col
                  md={8}
                  className="  d-flex"
                  style={{
                    fontWeight: "200",
                    fontSize: "14px",
                    alignItems: "center",
                  }}
                >
                  Send screening invitation to the customer
                </Col>
                <Col xl={2} lg={2} md={2} className="px-0">
                  <Toggle
                    id={"sendScreeningToCustomerActive"}
                    checked={sendScreeningToCustomerActive}
                    onChange={(e) =>
                      setSendScreeningToCustomerActive(e.target.checked)
                    }
                  />
                </Col>
              </Row>

              <Row className="ms-3 my-1  d-flex">
                <Col
                  md={8}
                  className="  d-flex"
                  style={{
                    fontWeight: "200",
                    fontSize: "14px",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <span className="fw-600 fs-14 ">2-factor</span>{" "}
                    <span className="fw-300 fs-14 ">
                      (buyer authorizes electronic documents by SMS to mobile
                      number)
                    </span>
                  </div>
                </Col>
                <Col md={2} className="px-0">
                  <Toggle
                    id={"twoFactor"}
                    checked={is2FaEnabled}
                    onChange={(e) => setIs2FaEnabled(e.target.checked)}
                  />
                </Col>
              </Row>
            </div>
            <div className="mt-2 py-2 gap-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
              {" "}
              <ETButton
                variant={"white"}
                buttonText="CANCEL"
                // type={"button"}
                onClick={() => {
                  setAddNewCustomer(false);
                  addCustomerFormReset();
                }}
              />
              <ETButton
                variant={"primary"}
                buttonText={actorId === "" ? "ADD" : "UPDATE"}
                type={"submit"}
                disabled={PostAddActorIsLoading || PutUpdateActorIsLoading}
                // onClick={() => setHideBtn(true)}
              />
            </div>
          </Form>
        </div>
      </CustomPopup>

      {/* -----------------------------------Comparing customer popup ---------------------------------- */}
      <CustomPopup
        open={compareCustomer}
        onHide={() => setCompareCustomer(false)}
        headerRequired={false}
        size="lg"
        enableCrossButton={false}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="pt-3 px-lg-5 px-3 d-flex justify-content-center text-center">
          <div className="px-lg-5 px-3 my-5 fs-14 fw-300">
            <div className="fs-32 fw-600">Existing customer?</div>
            <div className="py-4">
              Based on the information you have just filled in we found a match
              on an existing customer. You can now choose to add this
              reservation to the existing customer by clicking update and use or
              you can choose no to and you will create a new customer.
            </div>
            <div className="fw-600 pt-2 pb-3">
              We found a match on an existing customer:{" "}
              {isSsnExist && "with same SSN number"}
            </div>
            <div
              className="bg-grey w-100 radius-8"
              style={{ border: "1px solid #F0F0F0" }}
            >
              <div>
                <Row
                  className="fs-14 fw-300 text-start"
                  onClick={() => {
                    // allSelectedData(e);
                    setSearchDiv(false);
                  }}
                >
                  <Col
                    xs={5}
                    className="px-4 py-2 "
                    style={{ borderRight: "1px solid #F0F0F0" }}
                  >
                    <div className="px-2">
                      <div className=" d-flex flex-column my-2">
                        <span
                          className={`fw-500 ${
                            customerDetailsData?.firstname ==
                              existingActorDetailsData?.firstname && "red-text"
                          }`}
                        >
                          {existingActorDetailsData?.firstname}&nbsp;
                          {existingActorDetailsData?.lastname}
                        </span>
                        <span>{existingActorDetailsData?.actor_id}</span>
                      </div>
                      <div className="d-flex flex-column my-2">
                        <span
                          className={`fw-300 ${
                            customerDetailsData?.myemail ==
                              existingActorDetailsData?.email && "red-text"
                          }`}
                        >
                          {existingActorDetailsData?.email}
                        </span>
                        <span
                          className={`fw-300 ${
                            customerDetailsData?.phoneno ==
                              existingActorDetailsData?.mobilenumber &&
                            "red-text"
                          }`}
                        >
                          {existingActorDetailsData?.phone_calling_code_id}
                          &nbsp;{existingActorDetailsData?.mobilenumber}
                        </span>
                      </div>
                      <div className="d-flex flex-column my-2">
                        <span>
                          {existingActorDetailsData?.birthdate == ""
                            ? "-"
                            : moment(
                                existingActorDetailsData?.birthdate
                              ).format("DD MMM, YYYY")}
                        </span>
                        <span
                          className={`${
                            customerDetailsData?.socialsecuritynumber ==
                              existingActorDetailsData?.social_security_number &&
                            "red-text"
                          }`}
                        >
                          {existingActorDetailsData?.social_security_number}
                        </span>
                      </div>
                      <div className="d-flex flex-column my-2">
                        <span>
                          existingActorDetailsData
                          {existingActorDetailsData?.street_name}&nbsp;
                          {existingActorDetailsData?.house_number}
                        </span>
                        <span>
                          {existingActorDetailsData?.zipcode_id}&nbsp;
                          {existingActorDetailsData?.city}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={7} className="py-2">
                    <div className="px-2">
                      <div className="fw-600 mt-2 fs-11">Known activities</div>
                      <div className="d-flex flex-column mb-3 fs-12">
                        <span>
                          <FontAwesomeIcon
                            icon="fa-regular fa-house-user"
                            size="xs"
                            style={{ color: "#000000" }}
                          />{" "}
                          Private viewing at Terrasserne 26 Apr, 2023
                        </span>
                        <span>
                          <FontAwesomeIcon
                            icon="fa-regular fa-house"
                            size="xs"
                            style={{ color: "#000000" }}
                          />{" "}
                          Open House at Terrasserne 23 Apr, 2023
                        </span>
                        <span>
                          <FontAwesomeIcon
                            icon="fa-regular fa-house"
                            size="xs"
                            style={{ color: "#000000" }}
                          />{" "}
                          Open House at Blomsterbjerget 16 Mar, 2023
                        </span>
                        <span>
                          <FontAwesomeIcon
                            icon="fa-regular fa-user"
                            size="xs"
                            style={{ color: "#000000" }}
                          />{" "}
                          Lead at 12 Mar, 2023
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className=" py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <div className="my-1">
            <ETButton
              variant={"white"}
              buttonText="CANCEL"
              onClick={() => {
                setCompareCustomer(false);
                setAddNewCustomer(true);
              }}
            />
          </div>
          <div className="d-flex gap-3 my-1">
            {!isSsnExist && (
              <ETButton
                disabled={PostAddActorIsLoading || PutUpdateActorIsLoading}
                variant={"white"}
                buttonText="DO NOT USE EXISTING CUSTOMER"
                // onClick={handleDoNotUseExistingCustomer}
              />
            )}
            <ETButton
              disabled={PostAddActorIsLoading || PutUpdateActorIsLoading}
              variant={"primary"}
              buttonText="UPDATE AND USE"
              onClick={handleUpdateAndUse}
            />
          </div>
        </div>
      </CustomPopup>

      {/* ----------------------------------------Delete actor popup ---------------------------------- */}
      <CustomPopup
        style={{ position: "relative" }}
        size="md"
        open={openDeleteActorPopup?.deleteActorPopup}
        onHide={() =>
          setOpenDeleteActorPopup((prev) => ({
            ...prev,
            deleteActorPopup: false,
          }))
        }
        title={
          customerCardData?.filter(
            (data) => data?.actor_id == openDeleteActorPopup?.actor_id
          )?.[0]?.is_primary == 1
            ? "Cannot delete primary customer"
            : "Delete customer?"
        }
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() =>
          setOpenDeleteActorPopup((prev) => ({
            ...prev,
            deleteActorPopup: false,
          }))
        }
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div
            style={{
              fontSize: "14px",
              fontFamily: "Cerebri Sans",
              fontWeight: "500",
              textAlign: "left",
              lineHeight: "17.5px",
            }}
          >
            {customerCardData?.filter(
              (data) => data?.actor_id == openDeleteActorPopup?.actor_id
            )?.[0]?.is_primary == 1
              ? "If you want to delete the current customer, you have to assign other customer as primary."
              : "Are you sure you want to delete this customer?"}
          </div>
        </div>

        <div
          className={`py-2 px-4 d-flex flex-md-row flex-column ${
            customerCardData?.filter(
              (data) => data?.actor_id == openDeleteActorPopup?.actor_id
            )?.[0]?.is_primary == 0
              ? "justify-content-md-between justify-content-center"
              : "justify-content-end"
          }   footer-section-button-container`}
        >
          {customerCardData?.filter(
            (data) => data?.actor_id == openDeleteActorPopup?.actor_id
          )?.[0]?.is_primary == 0 ? (
            <>
              <ETButton
                variant={"white"}
                buttonText="NO"
                onClick={() =>
                  setOpenDeleteActorPopup((prev) => ({
                    ...prev,
                    deleteActorPopup: false,
                  }))
                }
              />
              <ETButton
                variant={"primary"}
                buttonText="YES"
                onClick={() => handleDeleteActor()}
              />
            </>
          ) : (
            <ETButton
              variant={"primary"}
              buttonText="OKAY"
              onClick={() =>
                setOpenDeleteActorPopup((prev) => ({
                  ...prev,
                  deleteActorPopup: false,
                }))
              }
            />
          )}
        </div>
      </CustomPopup>

      {/* ---------------------------------------- uncheck primary box pop up ---------------------------------- */}
      <CustomPopup
        style={{ position: "relative" }}
        size="md"
        open={unCheckedPrimaryPopup}
        onHide={() => setUnCheckedPrimaryPopup(false)}
        title="Update primary customer"
        title_color="black"
        enableCrossButton={false}
        onCloseAction={() =>
          setUnCheckedPrimaryPopup((prev) => ({
            ...prev,
            unCheckedPrimaryPopup: false,
          }))
        }
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="p-4">
          <div
            style={{
              fontSize: "14px",
              fontFamily: "Cerebri Sans",
              fontWeight: "500",
              textAlign: "left",
              lineHeight: "17.5px",
            }}
          >
            Primary customer already exists
          </div>{" "}
          <div
            style={{
              fontSize: "14px",
              fontFamily: "Cerebri Sans",
              fontWeight: "500",
              textAlign: "left",
              lineHeight: "17.5px",
            }}
          >
            Do you want to set this customer as primary?
          </div>
        </div>

        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="NO"
            onClick={() => setUnCheckedPrimaryPopup(false)}
          />

          <ETButton
            variant={"primary"}
            buttonText="YES"
            onClick={() => handleUpdatePrimaryActor()}
          />
        </div>
      </CustomPopup>
      {/* <div
        className="mt-5 py-2 px-4 d-flex flex-md-row flex-column justify-content-md-end  justify-content-end gap-2 "
        style={{
          backgroundColor: "#F9FAFA",
          borderRadius: " 0px 0px 8px 8px",
          border: "1px solid #F0F0F0",
        }}
      >
        {" "}
        <ETButton
             variant={"primary"}
          buttonText="SAVE"
        />
      </div> */}
    </>
  );
};

export default Tenants;
