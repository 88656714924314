import React from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import ETButton from "../../../../Components/Buttons/ETButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmptyMessage from "../../../../utils/EmptyMessage";

const ScreeningProcessDetails = ({ setScreeningprocessPopup }) => {
  const now = 0;
  return (
    <div>
      <EmptyMessage />
      {/* <div className="px-3 py-4" style={{ borderBottom: "1px Solid #F4F5F5" }}>
        <div className="Reservation-data px-3 py-2 d-flex justify-content-between">
          <div className="fs-14"
          >
            Screening process started
          </div>
          <div
            className="light-font-text"
          >
            17 Mar, 2023 at 13:23
          </div>
        </div>
        <div className="Reservation-data mt-2 px-3 py-2 d-flex justify-content-between">
          <div
            className="fs-14"
          >
            Started by
          </div>
          <div
            className="light-font-text"
          >
            Max Wildersans Brentford
          </div>
        </div>
        <div className="Reservation-data mt-2 px-3 py-2 d-flex justify-content-between">
          <div
            className="fs-14"
          >
            Screening process deadline
          </div>
          <div
            className="light-font-text"
          >
            19 Mar, 2023 at 13:23
          </div>
        </div>
        <div className="Reservation-data mt-2 px-3 py-2 d-flex justify-content-between">
          <div
           className="fs-14"
          >
            Last reminder
          </div>
          <div
            className="light-font-text"
          >
            18 Mar, 2023 at 12:00
          </div>
        </div>
        <div className="Reservation-data mt-2 px-3 py-2 d-flex justify-content-between">
          <div
           className="fs-14"
          >
            Next reminder
          </div>
          <div
            className="light-font-text"
          >
            19 Mar, 2023 at 08:00
          </div>
        </div>
        <div className="Reservation-data mt-2 px-3 py-2 d-flex justify-content-between">
          <div
            className="fs-14"
          >
            Screening approval
          </div>
          <div
            className="light-font-text"
          >
            Missing final approval from the responsible agent
            <FontAwesomeIcon
              className="mx-2"
              icon="fa-regular fa-arrow-up-right-from-square"
              style={{ color: "#000000" }}
            />
          </div>
        </div>
      </div> */}
      {/* ------------------------ProgressBar---------------------------- */}
      <div className="px-3 py-4" style={{ borderBottom: "1px Solid #F4F5F5" }}>
        <div className="light-font-text">Total screening fullfillment</div>
        <div className="mt-3">
          {" "}
          <ProgressBar
            className="lead-loader-color"
            now={now}
            label={`${now}%`}
            visuallyHidden
          />
        </div>

        <div className="d-flex justify-content-between mt-2">
          <div>
            <span className="form-label-title fs-13">0 / 4</span>{" "}
            <span className="light-font-text grey-text fs-13">Total task</span>
          </div>
          <div className="form-label-title fs-12">{`${now}%`}</div>
        </div>
      </div>
      {/* -----------------------DropDown And Datepickers----------------- */}
      <div className="px-3 py-4">
        <div className="light-font-text">
          Below you can see the reservation deadline. It is possible to correct
          the deadline and the responsible agent.
        </div>
        <Row className="mt-2">
          <Col lg={10}>
            <Row>
              <Col lg={4} className="mt-md-3">
                <div className="form-input-group ">
                  <input
                    type="date"
                    id="DateofDeadline"
                    placeholder="DateofDeadline"
                  />
                  <label htmlFor="DateofDeadline">Date of deadline</label>
                </div>
              </Col>
              <Col lg={4} className="my-md-3">
                <div className="form-input-group ">
                  <input
                    type="time"
                    id="TimeofDeadline"
                    placeholder="TimeofDeadline"
                  />
                  <label htmlFor="TimeofDeadline">Time of deadline</label>
                </div>
              </Col>
              <Col lg={4} className="my-md-3">
                <ReactSelect placeholder={"Responsible agent"} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div
        className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center 
        footer-section-button-container"
      >
        <ETButton
          variant={"white"}
          buttonText="CANCEL"
          onClick={() => setScreeningprocessPopup(false)}
        />
        <ETButton variant={"primary"} buttonText="SAVE" />
      </div>
    </div>
  );
};

export default ScreeningProcessDetails;
