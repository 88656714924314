// last updated by Suraj
import React from "react";
import englandflag from "./svg_icons/Englandflag.svg";
import desktoplogo from "./svg_icons/desktop-logo.svg";
import controlcenter from "./svg_icons/control-center.svg";
import properties from "./svg_icons/properties.svg";
import denmarkFlag from "./svg_icons/denmark-flag.svg";
import tabletLogo from "./svg_icons/tablet-logo.svg";
import questionmark from "./svg_icons/questionmark.svg";
import deltaDown from "./svg_icons/delta-down.svg";
import deltaUp from "./svg_icons/delta-up.svg";
import propertytagtooltip from "./svg_icons/PropertyTagToolTip.svg";
import dateIcon from "./svg_icons/Date-icon.svg";
import checkedbox from "./svg_icons/Checkbox.svg";
import uncheckedbox from "./svg_icons/unchecked.svg";
import questionmarkred from "./svg_icons/questionmark-red.svg";
import greenexlametry from "./svg_icons/greenexlametryMark.svg";

export const DesktopLogo = ({ height, width }) => {
  return (
    <>
      <img src={desktoplogo} height={height} width={width} alt="desktop-logo" />
    </>
  );
};
export const PropertyTagToolTip = ({ height, width }) => {
  return (
    <>
      <img
        src={propertytagtooltip}
        height={height}
        width={width}
        alt="Tool-Tip"
      />
    </>
  );
};
export const ControlCenter = ({ height, width }) => {
  return (
    <>
      <img
        src={controlcenter}
        height={height}
        width={width}
        alt="desktop-logo"
      />
    </>
  );
};
export const Properties = ({ height, width, text }) => {
  return (
    <>
      <img src={properties} height={height} width={width} alt="desktop-logo" />
      {text ? <> ({text})</> : ""}
    </>
  );
};

export const DenmarkFlag = ({ height, width }) => {
  return (
    <>
      <img src={denmarkFlag} height={height} width={width} alt="desktop-logo" />
    </>
  );
};
export const TabletLogo = ({ height, width }) => {
  return (
    <>
      <img src={tabletLogo} height={height} width={width} alt="desktop-logo" />
    </>
  );
};

export const QuestionMark = ({ height, width }) => {
  return (
    <>
      <img
        style={{ cursor: "pointer" }}
        src={questionmark}
        height={height}
        width={width}
        alt="Question mark"
      />
    </>
  );
};

export function DeltaArrow({ component }) {
  return (
    <div>
      <img
        height={9}
        className="mb-1 "
        src={component > 0 ? deltaUp : component < 0 ? deltaDown : ""}
        alt=""
      />
    </div>
  );
}

export const DateIcon = ({ height, width }) => {
  return (
    <>
      <img src={dateIcon} height={height} width={width} alt="file-icon" />
    </>
  );
};

export const Checkedbox = ({ height, width }) => {
  return (
    <>
      <img src={checkedbox} height={height} width={width} alt="file-icon" />
    </>
  );
};
export const UnCheckedbox = ({ height, width }) => {
  return (
    <>
      <img src={uncheckedbox} height={height} width={width} alt="file-icon" />
    </>
  );
};

export const QuestionMarkRed = ({ height, width }) => {
  return (
    <>
      <img
        style={{ cursor: "pointer" }}
        src={questionmarkred}
        height={height}
        width={width}
        alt="Question mark"
      />
    </>
  );
};
export const Englandflag = ({ height, width }) => {
  return (
    <>
      <img src={englandflag} height={height} width={width} alt="England-flag" />
    </>
  );
};
export const GreenExlametry = ({ height, width }) => {
  return (
    <>
      <img
        style={{ cursor: "pointer" }}
        src={greenexlametry}
        height={height}
        width={width}
        alt="cancel"
      />
    </>
  );
};
