// created by Suraj
import React, { useEffect, useMemo, useState } from "react";
import ETButton from "../../../../../Components/Buttons/ETButton";
import MRTTheme from "../../../../../Components/table/MRTTheme";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  paginationoptions,
} from "../../../../../Components/table/MRTGlobalProps";
import {
  CircleMsg,
  H4,
  TeamsImageContainer,
} from "../../../../../styles/Common.style";
import { inactiveTeamsData } from "../Teams";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";

const AssignUserToTeams = ({
  handleUserScreen = () => {},
  handleBackOnUserScreen = () => {},
  usedFor = "users",
  GetUserByDetailslevelData,
  rowSelection = {},
  setRowSelection,
  teamData,
  setPreviousRowSelection = () => {},
  EditTeamData,
  proviousRowSelection,
  flag,
  selectedTeamDataValue,
  handleAssignUserToTeams = () => {},
  setClosePopup = () => {},
  isLoading = false,
}) => {
  const [sorting, setSorting] = useState([]);

  // useEffect(() => {
  //   const members = EditTeamData?.[0]?.members;
  //   const memberUserIds = members?.map((member) => member?.user_id);
  //   const rowValue = Object.keys(rowSelection)
  //   const matchingValues = rowValue?.filter((value) => memberUserIds?.includes(value));

  //   console.log("Matching Values:", matchingValues);
  //   // console.log("🚀 ~ file: CreateNewTeamWizard.js:215 ~ handleNextCreateScreen ~ filterMember:", filterMember)
  //   // console.log("🚀 ~ file: CreateNewTeamWizard.js:214 ~ handleNextCreateScreen ~ memberUserIds:", memberUserIds)

  //   const userExistsMap = { ...proviousRowSelection };
  //   // setTeamName(teamName);
  //   if (flag) {
  //     GetUserByDetailslevelData?.data?.forEach((user) => {
  //       const userIdString = user.user_id.toString(); // Convert user_id to string for comparison
  //       if (
  //         memberUserIds?.includes(userIdString) ||
  //         userIdString == selectedTeamDataValue?.manager
  //       ) {
  //         userExistsMap[userIdString] = true;
  //       }
  //     });

  //     setRowSelection(userExistsMap);
  //   } else {
  //     GetUserByDetailslevelData?.data?.forEach((user) => {
  //       // Add default selection logic here
  //       if (user.user_id == selectedTeamDataValue?.manager) {
  //         userExistsMap[user.user_id] = true;
  //       }
  //     });
  //     setRowSelection(userExistsMap);
  //   }
  // }, [GetUserByDetailslevelData, rowSelection])

  // const [rowSelection, setRowSelection] = useState([])
  // const columnsForUsers = useMemo(
  //   () => [
  //     {
  //       accessorKey: "id",
  //       header: "",
  //       enableHiding: false,
  //       size: 40,
  //       enableGlobalFilter: false,
  //       muiTableHeadCellProps: {
  //         sx: {
  //           paddingLeft: "0px !important",
  //           paddingRight: "0px !important",
  //         },
  //       },
  //       muiTableBodyCellProps: {
  //         sx: {
  //           paddingLeft: "0px !important",
  //           paddingRight: "0px !important",
  //         },
  //       },
  //       Cell: ({ row }) => (
  //         <TeamsImageContainer>
  //           {row.original?.team_name?.charAt(0)?.toUpperCase()}
  //         </TeamsImageContainer>
  //       ),
  //     },
  //     {
  //       accessorKey: "team_name",
  //       header: "Team name",
  //       enableHiding: false,
  //       size: 80,
  //       enableGlobalFilter: true,
  //       Cell: ({ row }) => (
  //         <div>{row.original?.team_name ? row.original?.team_name : "-"}</div>
  //       ),
  //       muiTableHeadCellProps: {
  //         sx: {
  //           paddingLeft: "0px !important",
  //         },
  //       },
  //       muiTableBodyCellProps: {
  //         sx: {
  //           paddingLeft: "0px !important",
  //         },
  //       },
  //     },
  //     {
  //       accessorKey: "team_leaders",
  //       header: "Team Leaders",
  //       enableHiding: false,
  //       size: 50,
  //       enableGlobalFilter: false,
  //       Cell: ({ row }) => (
  //         <div>
  //           {row.original?.team_leaders ? row.original?.team_leaders : "-"}{" "}
  //         </div>
  //       ),
  //     },
  //     {
  //       accessorKey: "members",
  //       header: "Members",
  //       enableHiding: false,
  //       size: 50,
  //       enableGlobalFilter: false,
  //       Cell: ({ row }) => (
  //         <div>{row.original?.members ? row.original?.members : "-"} </div>
  //       ),
  //     },
  //     {
  //       accessorKey: "assigned_properties",
  //       header: "Assigned properties",
  //       enableHiding: false,
  //       size: 200,
  //       enableGlobalFilter: false,
  //       Cell: ({ row }) => (
  //         <div className="d-flex">
  //           <div className="truncated-text" style={{ width: "220px" }}>
  //             {" "}
  //             {row.original?.assigned_properties
  //               ? row.original?.assigned_properties
  //               : "-"}
  //           </div>
  //           <div>
  //             <CircleMsg>
  //               <span>0</span>
  //             </CircleMsg>
  //           </div>
  //         </div>
  //       ),
  //     },
  //     {
  //       accessorKey: "created",
  //       header: "Created",
  //       enableHiding: false,
  //       size: 120,
  //       enableGlobalFilter: false,
  //       muiTableHeadCellProps: {
  //         align: "right",
  //       },
  //       muiTableBodyCellProps: {
  //         align: "right",
  //       },
  //       Cell: ({ row }) => (
  //         <div>
  //           {row.original?.created ? row.original?.created : "-"}
  //           {/* {row.original?.created_on
  //                     ? moment(row.original?.created_on, "YYYY-MM-DD HH:mm:ss").format(
  //                         "DD MMM, YYYY"
  //                       )
  //                     : "0"} */}
  //         </div>
  //       ),
  //     },
  //     {
  //       accessorKey: "status",
  //       header: "Status",
  //       enableHiding: false,
  //       size: 100,
  //       enableGlobalFilter: false,
  //       Cell: ({ row }) => (
  //         <div>
  //           {row.original?.status ? row.original?.status : "-"}
  //           {/* {row.original?.deactivated_date
  //                     ? moment(
  //                         row.original?.deactivated_date,
  //                         "YYYY-MM-DD HH:mm:ss"
  //                       ).format("DD MMM, YYYY")
  //                     : "0"} */}
  //         </div>
  //       ),
  //     },
  //   ],
  //   []
  // );
  const columns = useMemo(
    () => [
      // {
      //   accessorKey: "id",
      //   header: "No.",
      //   enableHiding: false,
      //   size: 50,
      //   enableGlobalFilter: true,
      //   Cell: ({ row }) => (
      //     <div>{row.original?.id ? row.original?.id : "-"}</div>
      //   ),
      //   muiTableHeadCellProps: {
      //     sx: {
      //       paddingLeft: "0px !important",
      //     },
      //   },
      //   muiTableBodyCellProps: {
      //     sx: {
      //       paddingLeft: "0px !important",
      //     },
      //   },
      // },
      {
        accessorKey: "first_name",
        header: "First name",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div>{row.original?.first_name ? row.original?.first_name : "-"}</div>
        ),
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
      },
      {
        accessorKey: "last_name",
        header: "Last name",
        enableHiding: false,
        size: 80,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>{row.original?.last_name ? row.original?.last_name : "-"} </div>
        ),
      },
      {
        accessorKey: "roles",
        header: "Roles",
        enableHiding: false,
        size: 80,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>{row.original?.roles ? row.original?.roles : "-"} </div>
        ),
      },
      // {
      //   accessorKey: "roles",
      //   header: "Roles",
      //   enableHiding: false,
      //   size: 100,
      //   enableGlobalFilter: false,
      //   Cell: ({ row }) => (
      //     <div>
      //       {row.original?.roles?.roles
      //         ? row.original?.roles?.roles
      //         : "-"}{" "}
      //     </div>
      //   ),
      // },
      {
        accessorKey: "division_details",
        header: "Divisions",
        enableHiding: false,
        size: 100,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div className="d-flex">
            <div className="truncated-text" style={{ width: "100px" }}>
              {" "}
              {row?.original?.division_details?.[0]?.division_name
                ? row?.original?.division_details?.[0]?.division_name
                : "-"}
            </div>
            <div style={{ cursor: "pointer" }}>
              {row?.original?.division_details?.length - 1 != 0 ? (
                <>
                  <Tooltip
                    title={
                      row.original?.division_details
                        ?.slice(1) // Get elements from index 1 to the end
                        ?.map((divsion) => divsion?.division_name)
                        ?.join(", ") || "-"
                    }
                  >
                    <CircleMsg>
                      <span>
                        {row?.original?.division_details?.length - 1 || 0}
                      </span>
                    </CircleMsg>
                  </Tooltip>
                </>
              ) : (
                <CircleMsg>
                  <span>
                    {row?.original?.division_details?.length - 1 || 0}
                  </span>
                </CircleMsg>
              )}
            </div>
          </div>
        ),
      },
      {
        accessorKey: "joined_on",
        header: "Joined",
        enableHiding: false,
        size: 80,
        enableGlobalFilter: false,
        // muiTableHeadCellProps: {
        //   align: "right",
        // },
        // muiTableBodyCellProps: {
        //   align: "right",
        // },
        Cell: ({ row }) => (
          <div>
            {/* {row.original?.created ? row.original?.created : "-"} */}
            {row?.original?.joined_on
              ? moment(row?.original?.joined_on, "YYYY-MM-DD HH:mm:ss").format(
                  "DD MMM, YYYY"
                )
              : "-"}
          </div>
        ),
      },
      {
        accessorKey: "last_login_on",
        header: "Last active",
        enableHiding: false,
        size: 80,
        enableGlobalFilter: false,
        // muiTableHeadCellProps: {
        //   align: "right",
        // },
        // muiTableBodyCellProps: {
        //   align: "right",
        // },
        Cell: ({ row }) => (
          <div>
            {/* {row.original?.last_active ? row.original?.last_active : "-"} */}
            {row?.original?.last_login_on
              ? moment(
                  row?.original?.last_login_on,
                  "YYYY-MM-DD HH:mm:ss"
                ).format("DD MMM, YYYY")
              : "-"}
          </div>
        ),
      },
    ],
    []
  );

  const handleNext = () => {
    const userId = Object?.keys(rowSelection);

    handleUserScreen(userId);
  };
  const handlePrevious = () => {
    if (usedFor === "assingUsers") {
      setClosePopup(false);
    } else {
      handleBackOnUserScreen();
    }
  };

  //===========================Row selection value=======================//
  const handleRowSelectionChange = (newRowSelection) => {
    setRowSelection(newRowSelection);
    setPreviousRowSelection(newRowSelection); // Assuming newRowSelection contains the selected team manager
  };

  const renderEmptyRowsFallback = () => {
    if (GetUserByDetailslevelData && GetUserByDetailslevelData?.length === 0) {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div>
              You do not have any team added yet. Click on + Create team to
              create your first.
            </div>
          </>
        </div>
      );
    } else {
      return (
        <div
          className="d-flex justify-content-center w-100 grey-text py-3 radius-4"
          style={{ border: "1px solid #d1d1d1", backgroundColor: "#F9FAFA" }}
        >
          <>
            <div className="fs-14">
              Sorry! No result found. Please try again!
            </div>
          </>
        </div>
      );
    }
  };

  return (
    <>
      <div
        style={{ height: "450px", overflowY: "scroll" }}
        className="scrollable-container-y p-4 me-1"
      >
        {/* AssignUserToTeams */}
        {/* AssignProperties */}
        <div className="">
          <H4 className="form-label-title">
            {usedFor == "teams" || usedFor == "assingUsers"
              ? "Which users should be a member of the team?"
              : usedFor == "teams-leaders"
              ? "Which users are teamleader in this team?"
              : "Which teams should the user be a member off?"}
          </H4>
          {usedFor == "teams" || usedFor == "assingUsers" ? (
            <div className="light-font-text pt-3">
              Below is a list of all users created in this division. Select
              which users you want to be a member of the team.
            </div>
          ) : usedFor == "teams-leaders" ? (
            <div className="light-font-text pt-3">
              Below is a list of all users assigned to the team. Select which
              users you want to be a teamleader.
            </div>
          ) : (
            <div className="light-font-text pt-3">
              Below is a list of all teams created in this divisions. which
              users you want to be a member of the team.
            </div>
          )}
        </div>
        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={columns}
              icons={{
                ArrowDownwardIcon: () => SortIcon?.ArrowDownwardIcon(sorting),
                SyncAltIcon: () => SortIcon?.SyncAltIcon(),
              }}
              data={GetUserByDetailslevelData ?? []} //data is undefined on first render
              renderEmptyRowsFallback={renderEmptyRowsFallback}
              enableFilterMatchHighlighting={true}
              enableRowSelection={(row) =>
                row.original.user_id != teamData?.teamManager?.value
              }
              enableGlobalFilter
              enableRowNumbers
              globalFilterFn="contains"
              enableColumnActions={false}
              enableBottomToolbar={true}
              muiSearchTextFieldProps={{
                ...SearchStyle,

                InputProps: {
                  ...SearchIcon,
                },
              }}
              getRowId={(originalRow) => originalRow?.user_id}
              onRowSelectionChange={handleRowSelectionChange}
              state={{ rowSelection, sorting: sorting }}
              onSortingChange={setSorting}
              //give each row a more useful id
              enableSelectAll={true}
              // enableRowSelection
              enableRowActions={false}
              displayColumnDefOptions={{
                "mrt-row-select": {
                  header: "",
                  size: 50,
                  muiTableHeadCellProps: {
                    sx: {
                      paddingRight: "0px !important",
                    },
                  },
                  muiTableBodyCellProps: {
                    sx: {
                      paddingRight: "0px !important",
                    },
                  },
                },
                "mrt-row-numbers": {
                  Header: "No.",
                  size: 50,
                  muiTableHeadCellProps: {
                    sx: {
                      paddingLeft: "0px !important",
                    },
                  },
                  muiTableBodyCellProps: {
                    sx: {
                      paddingLeft: "0px !important",
                    },
                  },
                },
              }}
              renderTopToolbar={({ table }) => (
                <>
                  <div className="pb-2">
                    <div className="d-flex justify-content-end">
                      <MRT_GlobalFilterTextField table={table} />
                    </div>
                  </div>
                </>
              )}
              muiTableContainerProps={{
                elevation: 0,
                sx: {
                  padding: "0px !important",
                  background: "white",
                },
              }}
              muiTablePaperProps={{
                sx: {
                  backgroundColor: "transparent",
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                },
              }}
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiPaginationProps={{
                ...paginationoptions,
              }}
            />
          }
        />

        {/* footer section */}
      </div>
      <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
        <div className="d-flex gap-3">
          <ETButton
            variant={"white"}
            buttonText={usedFor == "teams" ? "BACK" : "CANCEL"}
            onClick={handlePrevious}
          />
        </div>

        <ETButton
          variant={"primary"}
          buttonText={usedFor == "teams" ? "NEXT" : "Save"}
          onClick={
            usedFor == "assingUsers" ? handleAssignUserToTeams : handleNext
          }
          disabled={Object?.keys(rowSelection)?.length === 0 || isLoading}
        />
      </div>
    </>
  );
};

export default AssignUserToTeams;
