import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { GetGeographyCountry } from "../../services/GeographyDropdown";
import {
  GetPropertiesTypes,
  GetAllPropertiesList,
  DeleteProperty,
  PostSetFavouriteProperties,
  GetPropertiesZipCode,
  GetPropertyId,
  GetPropertiesListByPortfolioId,
  PostPropertyList,
  PutProperty,
  GetPropertiesByPortfolioId,
  GetPropertyDetailByPropertyId,
  GetCurrencyListByCountryId,
  GetPropertySettingDetails,
  PutUpdatePropertySettingsDetails,
  GetStatusOverviewByPorpertyId,
  GetStatusOverviewSubDetails,
  GetPropertiesByLevel,
  AddProperty,
  UpdateProperty,
} from "../../services/Properties";
import {
  GetPortfolioProperties,
  PostPropertyAddPortfolio,
  PutPropertyPortfolio,
  DeletePropertyPortfolio,
  GetAllPropertyForPortfolioId,
  DeletePortfolio,
  GetPortfolio,
} from "../../services/Portfolios";
import { GetZipCodeByRegionId } from "../../services/GeographyDropdown";
import { GetPropertyWithOverviewByPropertyId } from "../../services/Units";
import { PostCloneFacilityPackage } from "../../services/Facility";

// to get property overview
export const useGetPropertyWithOverviewByPropertyId = (
  propertyId,
  unitType
) => {
  return useQuery(
    ["qGetPropertyOverviewByPropertyId", propertyId, unitType],
    () => GetPropertyWithOverviewByPropertyId(propertyId, unitType),
    { enabled: !!propertyId },
    { enabled: !!unitType }
  );
};

// to get property types
export const useGetPropertyTypes = () => {
  return useQuery("getPropertyTypes", () => GetPropertiesTypes(), {
    refetchOnMount: false,
    // refetchOnWindowFocus: false,
    cacheTime: 10800000,
  });
};

// to get all properties // will be replaced by get properties by level

export const useAllPropertyList = (level, divisionId, type) => {
  return useQuery(
    ["qGetAllPropertiesList", level, divisionId, type],
    () => GetAllPropertiesList(level, divisionId, type),
    // {
    //   refetchOnMount: false,
    //   refetchOnWindowFocus: false,
    // }
    {
      enabled: !!level && !!divisionId && !!type,
    }
  );
};

export const useGetFavoriteProperties = (level, divisionId, type) => {
  return useQuery(
    "qGetFavoriteProperties",
    () => GetAllPropertiesList(level, divisionId, type),
    {
      refetchOnMount: false,
      // refetchOnWindowFocus: false,
      cacheTime: 10800000,
    }
  );
};

// export const useFetchAllProperties = () => {
// 	const queryClient = useQueryClient();
// 	return queryClient.getQueryData(["qAllPropertiesList"]);
// };

// to get properties list by portfolio id
export const usePropertiesListByPortfolioId = (selectId) => {
  return (
    useQuery("getPropertiesListByPortfolioId", () =>
      GetPropertiesListByPortfolioId(selectId)
    ),
    { enabled: !!selectId },
    {
      refetchOnMount: false,
      // refetchOnWindowFocus: false
    }
  );
};

// to get the country list in properties
export const useGetGeographyCountry = () => {
  return useQuery("countryData", () => GetGeographyCountry(), {
    refetchOnMount: false,
    // refetchOnWindowFocus: false,
    cacheTime: 10800000,
  });
};

// to create a new portfolio
export const useCreatePortfolio = () => {
  return useMutation(PostPropertyAddPortfolio);
};

// to set favourite property
export const useSetFavourite = () => {
  return useMutation(PostSetFavouriteProperties);
};

// to edit portfolio
export const useEditPortfolio = () => {
  return useMutation(PutPropertyPortfolio);
};

// post new property
export const useCreateProperty = () => {
  const queryClient = useQueryClient();

  return useMutation(PostPropertyList, {
    onSuccess: () => {
      queryClient.invalidateQueries("qGetPropertyOverviewByPropertyId");
    },
  });
  // return useMutation(PostPropertyList);
};

// edit property
export const useEditProperty = () => {
  const queryClient = useQueryClient();

  return useMutation(PutProperty, {
    onSuccess: () => {
      queryClient.invalidateQueries("qGetPropertyOverviewByPropertyId");
    },
  });
};

// set favorite property
export const useSetFavouriteProperty = () => {
  return useMutation(PostSetFavouriteProperties);
};
// PostCloneFacilityPackage //

export const usePostCloneFacilityPackage = () => {
  return useMutation(PostCloneFacilityPackage);
};
// to get all properties for a portfolio
export const useGetPropertiesByPortfolioId = (portfolioId) => {
  return useQuery(
    ["GetPropertiesByPortfolioId", portfolioId],
    () => GetPropertiesByPortfolioId(portfolioId),
    { enabled: !!portfolioId },
    {
      refetchOnMount: false,
      // refetchOnWindowFocus: false
    }
  );
};

// GetPropertyDetailByPropertyId

export const useGetPropertyDetailByPropertyId = (propertyId) => {
  return useQuery(
    ["qGetPropertyDetailByPropertyIdData", propertyId],
    () => GetPropertyDetailByPropertyId(propertyId),
    { enabled: !!propertyId }
  );
};

// GetPortfolio

export const useGetPortfolio = (level, level_id) => {
  return useQuery(
    ["qAllPortfoliosData", level, level_id],
    () => GetPortfolio(level, level_id),
    {
      enabled: !!level && !!level_id,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetCurrencyByCountry = (countryId) => {
  return useQuery(
    ["qGetCurrencyByCountryId", countryId],
    () => GetCurrencyListByCountryId(countryId),
    { enabled: !!countryId },
    { refetchOnWindowFocus: false }
  );
};

// Get property settings details
export const useGetPropertySettingDetails = (propertyId) => {
  return useQuery(
    ["qGetPropertySettingDetails", propertyId],
    () => GetPropertySettingDetails(propertyId),
    { enabled: !!propertyId }
  );
};

// Property update property setting details
export const useUpdatePropertySettingsDetails = () => {
  return useMutation(PutUpdatePropertySettingsDetails);
};

// Get status overview by propertyId
export const useGetStatusOverviewByPropertyId = (propertyId) => {
  return useQuery(
    ["qGetStatusOverviewByPropertyId", propertyId],
    () => GetStatusOverviewByPorpertyId(propertyId),
    { enabled: !!propertyId }
  );
};
// Get status overview sub details
export const useGetStatusOverviewSubDetails = (
  property_id,
  main_status_id,
  substatus_id
) => {
  return useQuery(
    ["qGetStatusOverviewSubDetails", property_id, main_status_id, substatus_id],
    () =>
      GetStatusOverviewSubDetails(property_id, main_status_id, substatus_id),
    { enabled: !!property_id && !!main_status_id && !!substatus_id }
  );
};

// Properties_GetPropertiesByLevel
export const useGetPropertiesByLevel = (level, level_id, type, sub_type) => {
  return useQuery(
    ["qGetPropertiesByLevel", level, level_id, type, sub_type],
    () => GetPropertiesByLevel(level, level_id, type, sub_type),
    { enabled: !!level && !!level_id && !!type && !!sub_type }
  );
};

// post Properties
export const useAddProperty = () => {
  return useMutation(AddProperty);
};

//Put Favourite Properties
export const useUpdateProperty = () => {
  return useMutation(UpdateProperty);
};
