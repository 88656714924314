import React, { useState } from "react";
import { CircleMsg, H4 } from "../../../../../styles/Common.style";
import ScreeningAccordionTable from "../../../standardComponents/ScreeningAccordionTable";
import CancelWizard from "../../../standardComponents/cancelSigningOrder/CancelWizard";
import ApproveScreening from "../approveScreenings/ApproveScreening";
import MultipleApproveScreening from "../approveScreenings/MultipleApproveScreening";
import MoveToAnotherUnit from "../../../opportunities/MoveToAnotherUnit";
import AddResponsibleAgent from "../../../standardComponents/AddResponsibleAgent";
import CreateAgreement from "../createAgreement/CreateAgreement";
import MultipleCreateAgreement from "../createAgreement/MultipleCreateAgreement";
import FollowUp from "../../../standardComponents/follow up/FollowUp";

const Completed = () => {
  // Handle the click event based on the item in common component
  const handleItemClick = (itemText) => {
    console.log(`Clicked on: ${itemText}`);
  };
  /*-- cancel screening --*/
  const [cancelScreen, setCancelScreen] = useState(false);
  const [cancelScreenStepWizard, setCancelScreenStepWizard] = useState(
    "SelectReasonSigningOrder"
  );
  const cancelScreening = (item) => {
    setCancelScreen(true);
  };
  const [approveAgreement, setApproveAgreement] = useState(false);
  const [multipleApproveAgreement, setMultipleApproveAgreement] =
    useState(false);
  const createagreements = (item) => {
    item.length > 1
      ? setMultipleApproveAgreement(true)
      : setApproveAgreement(true);
  };

  /*-- Follow-up customer  --*/
  const [showFollowsUpWizard, setShowFollowsUpWizard] = useState(false);
  const [selectedFollowUpIndex, setSelectedFollowUpIndex] = useState(0);
  const handleCustomerDetails = (pageIndex = 0) => {
    // console.log(pageIndex, "i asssssssssssss");
    setShowFollowsUpWizard(true);
    setSelectedFollowUpIndex(pageIndex);
  };
  /*-- change agent --*/
  const [changeAgent, setChangeAgent] = useState(false);
  const handleChangeAgent = (item) => {
    setChangeAgent(true);
  }
  /*-- move unit --*/
  const [moveToAnotherUnits, setMoveToAnotherUnit] = useState(false);
  const handleMoveToAnotherUnit = (item) => {
    setMoveToAnotherUnit(true);
  }
  /*-- decline Screening --*/
  const [declineScreening, setDeclineScreening] = useState(false);
  const [declineStepWizard, setDeclineStepWizard] = useState(
    "SelectReasonSigningOrder"
  );
  /*-- Aprrove Screening --*/
  const [multipleApproveScreening, setMultipleApproveScreening] =
    useState(false);
  const [approveScreening, setApproveScreening] = useState(false);
  const approveScreen = (item) => {
    item.length > 1
      ? setMultipleApproveScreening(true)
      : setApproveScreening(true);
  };
  // // customers context menu bar for declined With No Priority Set
  const CompletedMenuItemsWithNoPrioritySet = [
    {
      text: "Approve screening",
      onClick: approveScreen,
    },
    {
      text: "Decline screening",
      onClick: () => setDeclineScreening(true),
    },
    {
      text: "Cancel screening",
      onClick: cancelScreening,
    },

    {
      text: "Create agreement",
      onClick: createagreements,
    },
    {
      text: "Add priority on Contract",
      onClick: handleItemClick,
    },
    {
      text: "Add follow ups",
      onClick:()=> handleCustomerDetails(0),
    },
    {
      text: "Add customer as favourite",
      onClick: handleItemClick,
    },
    {
      text: "Go to contract",
      onClick: handleItemClick,
    },
    {
      text: "Go to contact card",
      onClick:()=> handleCustomerDetails(0),
    },
    {
      text: "Change responsible agent",
      onClick: handleChangeAgent,
    },
    {
      text: "Move to another unit",
      onClick: handleMoveToAnotherUnit,
    },
  ];

  // // customers context menu bar for declined With Priority Set
  const CompletedMenuItemsWithPrioritySet = [
    {
      text: "Approve screening",
      onClick: handleItemClick,
    },
    {
      text: "Decline screening",
      onClick: () => handleItemClick,
    },
    {
      text: "Cancel screening",
      onClick: handleItemClick,
    },

    {
      text: "Create agreement",
      onClick: handleItemClick,
    },
    {
      text: "Change priority on Contract",
      onClick: handleItemClick,
    },
    {
      text: "Add follow ups",
      onClick:()=>  handleCustomerDetails(0),
    },
    {
      text: "Add customer as favourite",
      onClick: handleItemClick,
    },
    {
      text: "Go to contract",
      onClick: handleItemClick,
    },
    {
      text: "Go to contact card",
      onClick:()=>  handleCustomerDetails(0),
    },
    {
      text: "Change responsible agent",
      onClick: handleItemClick,
    },
    {
      text: "Move to another unit",
      onClick: handleItemClick,
    },
  ];

  // main frame container for decline screen & Cancel screening
  const MainFrameMenuDS = [
    {
      text: "Cancel all screenings",
      onClick: handleItemClick,
    },
    {
      text: "Decline all screenings",
      onClick: handleItemClick,
    },
    {
      text: "Add unit as favourite",
      onClick: handleItemClick,
    },
  ];
  return (
    <div>
      <div className="px-4">
        <div className="d-flex ">
          <div className="my-2">
            <div className="d-flex align-items-center pt-3 pb-2">
              <H4>Completed screenings</H4>
              <CircleMsg>
                <span>{CompletedScreeningData?.length}</span>
              </CircleMsg>
            </div>
            <div className="light-font-text">
              Below is a list of completed screenings i the system right now.
            </div>
          </div>
        </div>
        {/* table section */}
        <div>
          <ScreeningAccordionTable
            data={CompletedScreeningData}
            usedFor="CS"
            MainFrameMenu={MainFrameMenuDS}
            CustomersContextmenuItemsCondition1={
              CompletedMenuItemsWithNoPrioritySet
            }
            CustomersContextmenuItemsCondition2={
              CompletedMenuItemsWithPrioritySet
            }
          />
        </div>
        <CancelWizard
          title={"Cancel Screening"}
          titleTooltip
          titleTooltipText={"Cancel Screening"}
          cancelReasonHeader={"Select Reason for Cancellation"}
          cancelReasonText={
            "When a screening is canclled, you must select one or more cancellation reasons below.If there is no suitable reason, the option Other can be used."
          }
          cancelReasonHeaderTooltip={"Select Reason for Cancellation"}
          Status={"The screening is moved to the Declined screenings tab."}
          openCancelWizardModal={cancelScreen}
          nextWizardScreenText={"SelectReasonSigningOrder"}
          setOpenCancelWizardModal={setCancelScreen}
          switchWizardScreen={cancelScreenStepWizard}
          setSwitchWizardScreen={setCancelScreenStepWizard}
          secondPageStatus={
            "It’s only possible to send to MyPage if the customer has a active profile."
          }
          cancelSecondPageReasonHeader={"Select reason for cancellation"}
          cancelSecondPageReasonHeaderTooltip={"Select reason for cancellation"}
          cancelReasonSecondPageFooter={"Send confirmation to"}
          cancelSecondPageReasonFooterTooltip={"Send confirmation to"}
        />

        <ApproveScreening
          setApproveScreening={setApproveScreening}
          approveScreening={approveScreening}
        />
        <MultipleApproveScreening
          setMultipleApproveScreening={setMultipleApproveScreening}
          multipleApproveScreening={multipleApproveScreening}
        />

        <MoveToAnotherUnit
          showMoveToAnotherUnits={moveToAnotherUnits}
          setShowMoveToAnotherUnits={setMoveToAnotherUnit}
        />


        <AddResponsibleAgent
          showResponsibleAgentPopup={changeAgent}
          setShowResponsibleAgentPopup={setChangeAgent}
          usedFor="EDIT"
          data={""}
        // leadId={leadId}
        // createUpdateLeadAgentMutate={createUpdateLeadAgentMutate}
        // createUpdateLeadAgentLoading={createUpdateLeadAgentLoading}
        // leadsByDivisionIdDataRefetch={leadsByDivisionIdDataRefetch}
        />
        <CreateAgreement
          approveAgreement={approveAgreement}
          setApproveAgreement={setApproveAgreement}
        />
        <MultipleCreateAgreement
          multipleApproveAgreement={multipleApproveAgreement}
          setMultipleApproveAgreement={setMultipleApproveAgreement}
        />
       <FollowUp
          setFollowUp={setShowFollowsUpWizard}
          followUp={showFollowsUpWizard}
          selectedIndex={selectedFollowUpIndex}
          setSelectedIndex={setSelectedFollowUpIndex}
        />
         <CancelWizard
          title={"Decline Screening"}
          titleTooltip
          Status={
            "The screening is moved to the Declined screenings tab."
          }
          titleTooltipText={"Decline Screening"}
          openCancelWizardModal={declineScreening}
          setOpenCancelWizardModal={setDeclineScreening}
          switchWizardScreen={declineStepWizard}
          setSwitchWizardScreen={setDeclineStepWizard}
          nextWizardScreenText={"SelectReasonSigningOrder"}
          cancelReasonHeader={"Select Reason for Decline"}
          cancelReasonText={
            "When a screening is declined, you must select one or more decline reasons below.If there is no suitable reason, the option Other can be used."
          }
          cancelSecondPageReasonHeader={
            "Confirmation that the screening is declined"
          }
          cancelSecondPageReasonHeaderTooltip={
            "Confirmation that the screening is declined"
          }
          cancelReasonSecondPageFooter={"Send confirmation to"}
          cancelSecondPageReasonFooterTooltip={"Send confirmation to"}
          secondPageStatus={
            "It’s only possible to send to MyPage if the customer has a active profile."
          }
        />

      </div>
    </div>
  );
};

export default Completed;

// static data
const CompletedScreeningData = [
  {
    id: 1,
    unit: "Dybbølsbro 3, 1 | 212-004",
    property: "Kaktus Towers",
    deal: "No priority set",
    active_screening: "1",
    screening_started: "2 Jul, 2023 at 10:32",
    status: "WARNING",
    bg_color: "#FFDF00",
    progress: 100,
    iconData: [
      {
        id: 1,
        ico_name: "fa-solid fa-user",
        iconColor: "#FFDF00",
        category: "Information",
      },
      {
        id: 2,
        ico_name: "fa-solid fa-briefcase",
        iconColor: "#D1D1D1",
        category: "Occupation",
      },
      {
        id: 3,
        ico_name: "fa-solid fa-building-columns",
        iconColor: "#349032",
        category: "Economy",
      },
      {
        id: 4,
        ico_name: "fa-solid fa-house-user",
        iconColor: "#FFDF00",
        category: "Household",
      },
      {
        id: 5,
        ico_name: "fa-solid fa-passport",
        iconColor: "#349032",
        category: "Identification",
      },
      {
        id: 6,
        ico_name: "fa-solid fa-circle-check",
        iconColor: "#349032",
        category: "Confirmation",
      },

      {
        id: 7,
        ico_name: "fa-solid fa-circle-info",
        iconColor: "#FFDF00",
        category: "Additional information",
      },
      {
        id: 8,
        ico_name: "fak fa-salary-slip-svg-test2",
        iconColor: "#349032",
        category: "Salary slip",
      },
    ],
  },
  {
    id: 4,
    unit: "Kalundborg Hovedvejen 1,| 049-200",
    property: "Munkesøhuse",
    deal: "No priority set",
    active_screening: "1",
    screening_started: "19 Aug, 2023 at 20:47",
    status: "NORMAL",
    bg_color: "#349032 ",
    progress: 100,
    iconData: [
      {
        id: 1,
        ico_name: "fa-solid fa-user",
        iconColor: "#349032",
        category: "Information",
      },
      {
        id: 2,
        ico_name: "fa-solid fa-briefcase",
        iconColor: "#D1D1D1",
        category: "Occupation",
      },
      {
        id: 3,
        ico_name: "fa-solid fa-building-columns",
        iconColor: "#349032",
        category: "Economy",
      },
      {
        id: 4,
        ico_name: "fa-solid fa-house-user",
        iconColor: "#349032",
        category: "Household",
      },
      {
        id: 5,
        ico_name: "fa-solid fa-passport",
        iconColor: "#349032",
        category: "Identification",
      },
      {
        id: 6,
        ico_name: "fa-solid fa-circle-check",
        iconColor: "#D1D1D1",
        category: "Confirmation",
      },

      {
        id: 7,
        ico_name: "fa-solid fa-circle-info",
        iconColor: "#349032",
        category: "Additional information",
      },
      {
        id: 8,
        ico_name: "fak fa-salary-slip-svg-test2",
        iconColor: "#349032",
        category: "Salary slip",
      },
    ],
  },
  {
    id: 3,
    unit: "Kaj Kantens Vej 89, 17. sal, mf. | 239-013",
    property: "Carolinelunden",
    deal: "Svend Ludoman",
    active_screening: "1",
    screening_started: "14 Sep, 2023 at 09:13",
    status: "WARNING",
    bg_color: "#FFDF00",
    progress: 100,
    iconData: [
      {
        id: 1,
        ico_name: "fa-solid fa-user",
        iconColor: "#349032",
        category: "Information",
      },
      {
        id: 2,
        ico_name: "fa-solid fa-briefcase",
        iconColor: "#349032",
        category: "Occupation",
      },
      {
        id: 3,
        ico_name: "fa-solid fa-building-columns",
        iconColor: "#349032",
        category: "Economy",
      },
      {
        id: 4,
        ico_name: "fa-solid fa-house-user",
        iconColor: "#FFDF00",
        category: "Household",
      },
      {
        id: 5,
        ico_name: "fa-solid fa-passport",
        iconColor: "#349032",
        category: "Identification",
      },
      {
        id: 6,
        ico_name: "fa-solid fa-circle-check",
        iconColor: "#349032",
        category: "Confirmation",
      },

      {
        id: 7,
        ico_name: "fa-solid fa-circle-info",
        iconColor: "#FFDF00",
        category: "Additional information",
      },
      {
        id: 8,
        ico_name: "fak fa-salary-slip-svg-test2",
        iconColor: "#FFDF00",
        category: "Salary slip",
      },
    ],
  },

  {
    id: 5,
    unit: "Kolding Sky 143, 28., door 5 | 631-239",
    property: "Kolding Sky",
    deal: "John Doe",
    active_screening: "1",
    screening_started: "16 Oct, 2023 at 16:21",
    status: "NORMAL",
    bg_color: "#349032 ",
    progress: 100,
    iconData: [
      {
        id: 1,
        ico_name: "fa-solid fa-user",
        iconColor: "#349032",
        category: "Information",
      },
      {
        id: 2,
        ico_name: "fa-solid fa-briefcase",
        iconColor: "#349032",
        category: "Occupation",
      },
      {
        id: 3,
        ico_name: "fa-solid fa-building-columns",
        iconColor: "#349032",
        category: "Economy",
      },
      {
        id: 4,
        ico_name: "fa-solid fa-house-user",
        iconColor: "#d1d1d1",
        category: "Household",
      },
      {
        id: 5,
        ico_name: "fa-solid fa-passport",
        iconColor: "#349032",
        category: "Identification",
      },
      {
        id: 6,
        ico_name: "fa-solid fa-circle-check",
        iconColor: "#349032",
        category: "Confirmation",
      },

      {
        id: 7,
        ico_name: "fa-solid fa-circle-info",
        iconColor: "#349032",
        category: "Additional information",
      },
      {
        id: 8,
        ico_name: "fak fa-salary-slip-svg-test2",
        iconColor: "#349032",
        category: "Salary slip",
      },
    ],
  },
];

// change log and other info
// Created by Suraj
