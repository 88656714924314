import React from "react";
import { H2 } from "../../../styles/Common.style";
import ETButton from "../../../Components/Buttons/ETButton";
import "@dotlottie/player-component";
import Confetti from "../../../assets/animations/Confetti.lottie";

const LetsCelebrate = ({ handleNavigateToLogin }) => {
  return (
    <div className="whiteContainerStyle p-3">
      <div className="mb-5">
        <H2>Let's celebrate!</H2>
      </div>

      <div className="light-font-text mb-3">
        Your account has been created and you now have access to the proptech
        solution of the future, we know you're excited and we understand.
      </div>

      <div className="light-font-text mb-3">
        Click below to log in for the first time.
      </div>

      <div
        style={{ height: "300px" }}
        className="d-flex align-items-center justify-content-center"
      >
        <dotlottie-player
          src={Confetti}
          autoplay
          loop
        />
      </div>

      <ETButton
        variant={"primary"}
        buttonText="GO TO LOGIN"
        onClick={() => handleNavigateToLogin()}
      />
    </div>
  );
};

export default LetsCelebrate;
