import { Delete, Get, Post, Put } from "./API/Method";
import { Endpoint, Site } from "./API/Endpoints";

export const GetScreeningByPropertyId = (id) => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Screening_GetScreeningByPropertyId, null, id)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

export const GetScreeningFlagTriggerAction = () => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Screening_GetScreeningFlagTriggerAction, null)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

export const GetScreeningDeadlineAction = () => {
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Screening_GetScreeningDeadlineAction, null)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

export const GetScreeningFormBasicDetails = (formId) => {
	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.Screening_GetScreeningFormBasicDetails,
			null,
			formId
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

export const GetScreeningCategoryByformId = (formId) => {
	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.Screening_GetScreeningCategoryByformId,
			null,
			formId + "/category"
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

// export const GetScreeningFormSectionDetails = (formId, categoryId) => {
// 	return new Promise((resolve, reject) => {
// 		Get(
// 			Site.ET,
// 			Endpoint.ET.Screening_GetScreeningCategoryByformId,
// 			null,
// 			formId + "/category/" + categoryId + "/section"
// 		)
// 			.then((response) => {
// 				resolve(response.data);
// 			})
// 			.catch((error) => {
// 				reject({ error: true, message: error.message });
// 			});
// 	});
// };

export const GetScreening = (formId, status1, status2) => {
	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.Screening_GetScreening,
			null,
			formId + "/status/" + status1 + "," + status2 + "/list"
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

export const GetScreeningFields = (categoryId) => {
	// console.log("GetScreeningFields categoryId", categoryId);
	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.Screening_GetScreeningFields,
			null,
			categoryId + "/fields"
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

export const UpdateScreeningFormStatus = (body) => {
	return new Promise((resolve, reject) => {
		Put(Site.ET, Endpoint.ET.Screening_UpdateScreeningFormStatus, null, body)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

export const UpdateScreeningActorStaus = (body) => {
	return new Promise((resolve, reject) => {
		Put(Site.ET, Endpoint.ET.Screening_UpdateScreeningActorStaus, null, body)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

export const UpdateScreeningFormUnits = (body) => {
	return new Promise((resolve, reject) => {
		Put(Site.ET, Endpoint.ET.Screening_UpdateScreeningFormUnits, null, body)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

//  Get Linked Unit

export const GetScreeningLinkedUnit = (formId, propertyId) => {
	return new Promise((resolve, reject) => {
		Get(
			Site.ET,
			Endpoint.ET.Screening_GetLinkedUnit,
			null,
			formId + "/p/" + propertyId + "/units"
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

export const AddScreeningForm = (body) => {
	return new Promise((resolve, reject) => {
		Post(Site.ET, Endpoint.ET.Screening_AddScreeningForm, null, body)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};

export const UpdateScreeningForm = (body) => {
	return new Promise((resolve, reject) => {
		Put(Site.ET, Endpoint.ET.Screening_UpdateScreeningForm, null, body)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};
export const GetScreeningTemplate = (isTemplate) => {
	console.log("isTemplate", isTemplate);
	return new Promise((resolve, reject) => {
		Get(Site.ET, Endpoint.ET.Screening_GetScreeningTemplate, null, isTemplate)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject({ error: true, message: error.message });
			});
	});
};
