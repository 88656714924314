import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Col, Row } from "react-bootstrap";
import { ExpandMoreRounded } from "@mui/icons-material";

import ETButton from "../../../../Components/Buttons/ETButton";
import CustomPopup from "../../../../Components/CustomPopup";
import { usePropertyOverview } from "../../../../hooks/stores/usePropertyStore";
import { GreenSpan } from "../../../../styles/Common.style";
import "../labels/statusLabels.css";
import ReactSelect from "../../../../Components/DropDown/ReactSelect";
import { Delete_PromotionLabel } from "../../../../services/PromotionLabel";
import { toast } from "react-toastify";

export const DeletePromotionLabel = ({
  deletePopupToggle,
  setDeletePopupToggle,
  statIdUnitCount,
  handleDeletePromotionLabel,
  getPromotionLabelByPropertyIdData,
  getPromotionLabelByPropertyIdDataRefetch,
  disableDeleteAction,
}) => {
  //------------------------states-----------------//
  const [newPromotionLabelId, setNewPromotionLabelId] = useState("");

  //-----------delete promoton label without units------------//
  const handleNoUnitClick = () => {
    handleDeletePromotionLabel();
  };

  //-----------delete promoton label with units------------//
  const handleDeletePromotionLabelWithUnits = async () => {
    try {
      const response = await Delete_PromotionLabel(
        statIdUnitCount?.promotionLabelId,
        newPromotionLabelId
      );
      if (response?.data?.bReturn) {
        toast.success(response.data.message);
        setDeletePopupToggle(false);
        getPromotionLabelByPropertyIdDataRefetch();
        setNewPromotionLabelId("");
      } else {
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const displayItem = (selected) => {
    return {
      value: selected,
      label: (
        <>
          {getPromotionLabelByPropertyIdData?.data
            .filter((item) => item.promotion_label_id === selected)
            .map((data) => (
              <div
                className="d-flex align-items-center gap-2"
                style={{ marginTop: "2px" }}
              >
                <div
                  style={{
                    background: `linear-gradient(135deg, ${data?.background_color} 50%, ${data?.font_color} 50%)`,
                  }}
                  className="config-color-sec px-2"
                ></div>
                <div>
                  <div className="fs-20 fw-300">
                    {" "}
                    {data?.internal_label !== "" ? data?.internal_label : "-"}
                  </div>
                </div>
              </div>
            ))}
        </>
      ),
    };
  };
  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  const FormatGroupLabel = () => {
    return (
      <div style={groupStyles}>
        <tr className="w-100" style={{ textTransform: "capitalize" }}>
          <th style={{ width: "60px" }}>
            <div className="config-table-heading px-2">Color</div>{" "}
          </th>
          <th style={{ width: "190px" }}>
            <div className="config-table-heading ">Internal name </div>
          </th>
          <th style={{ width: "180px" }}>
            <div className="config-table-heading ">Promotion label</div>
          </th>
          <th style={{ width: "120px" }}>
            <span className="config-table-heading ">Price text label</span>
          </th>
          <th style={{ width: "120px" }}>
            <span className="config-table-heading ">Price visible</span>
          </th>
        </tr>
      </div>
    );
  };
  const PromotionGroupLabel = ({ data, index }) => {
    return (
      <div className="">
        <tr className="cursor-pointer w-100">
          <td className="config-table-data" style={{ width: "60px" }}>
            <div
              style={{
                width: "17px",
                background: `linear-gradient(135deg, ${data?.background_color} 50%, ${data?.font_color} 50%)`,
              }}
              className="config-color-sec"
            ></div>
          </td>
          <td className="config-table-data" style={{ width: "180px" }}>
            <span className="light-font-text ">
              {data?.internal_label !== "" ? data?.internal_label : "-"}
            </span>
          </td>
          <td className="config-table-data" style={{ width: "180px" }}>
            <span className="light-font-text  ">
              {data?.external_label !== "" ? data?.external_label : "-"}
            </span>
          </td>
          <td className="config-table-data" style={{ width: "120px" }}>
            <span className="light-font-text ">
              {data?.price_label ? data?.price_label : "-"}
            </span>
          </td>
          <td className="config-table-data" style={{ width: "120px" }}>
            <span className="light-font-text  ">
              {data?.is_price_visible == "1" ? "Yes" : "No"}
            </span>
          </td>
        </tr>
      </div>
    );
  };

  const groupedOptions = [
    {
      label: <FormatGroupLabel />,
      options: getPromotionLabelByPropertyIdData?.data
        ?.filter(
          (val) => val?.promotion_label_id !== statIdUnitCount?.promotionLabelId
        )
        ?.map((data, index) => ({
          value: data?.promotion_label_id,
          label: <PromotionGroupLabel data={data} index={index} />,
        })),
    },
  ];
  return (
    <>
      {statIdUnitCount?.unitCount == 0 ||
      getPromotionLabelByPropertyIdData?.data?.length === 1 ? (
        <div>
          <CustomPopup
            deletePopUpStyle={true}
            style={{ position: "relative" }}
            size="md"
            open={deletePopupToggle}
            onHide={() => setDeletePopupToggle(false)}
            title="Are you carrying out the trash ?"
            title_color="black"
            enableCrossButton={false}
            onCloseAction={() => {
              setDeletePopupToggle(false);
            }}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={true}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
          >
            <div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: "14px",
                  fontFamily: "Cerebri Sans",
                  lineHeight: "17.5px",
                }}
                className="mx-3 p-2 py-4 fw-300"
              >
                {statIdUnitCount?.unitCount <= 0 ? (
                  <>
                    <div>
                      The Promotion label will be deleted and cannot be undone.
                    </div>
                    <div>
                      We know, it’s not always funny to be the bad guy doing
                      <br /> the dirty work.
                    </div>
                  </>
                ) : (
                  <>
                    {/* <div>
                      Since it’s the only promotion label exist, the units using this label<br/>will be unlinked, it’s better to create a new promotion label and<br/> transfer the units to. 
                      <br />The action cannot be undone and has effect on websites, dashboard, etc.
                    </div> */}
                    <div className="d-flex">
                      Currently, this is the last promotion label exists and is
                      been used on {statIdUnitCount?.unitCount} units. So it's
                      better to create a new promotion label and transfer the
                      units used.
                      <br />
                      The action cannot be undone and has effect on websites,
                      dashboard, etc.
                    </div>
                  </>
                )}
              </div>

              <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
                <ETButton
                  variant={"white"}
                  buttonText="COLD FEET"
                  onClick={() => {
                    setDeletePopupToggle(false);
                  }}
                />
                <ETButton
                  variant={"primary"}
                  buttonText="I'M SURE"
                  disabled={disableDeleteAction}
                  onClick={handleNoUnitClick}
                />
              </div>
            </div>
          </CustomPopup>
        </div>
      ) : (
        <div>
          <CustomPopup
            deletePopUpStyle={true}
            style={{ position: "relative" }}
            size="xl"
            open={deletePopupToggle}
            onHide={() => {
              setDeletePopupToggle(false);
              setNewPromotionLabelId("");
            }}
            title="Delete promotion label"
            titleFont="14px"
            title_color="black"
            titleTooltipText="Need Help to Delete promotion label"
            titleTooltip={true}
            enableCrossButton={false}
            onCloseAction={() => {
              setDeletePopupToggle(false);
              setNewPromotionLabelId("");
            }}
            action_button_label="YES"
            action_button_label_color="white"
            action_button_color="#619130"
            cancel_button={true}
            cancel_button_bordercolor="grey"
            cancel_button_label="NO"
            cancel_button_label_color="black"
            cancel_button_color="#fff"
            footer_color={"#F9FAFA"}
          >
            <div
            //  className="scrollable-container-y"
            >
              <Row style={{ minHeight: "540px" }}>
                <Col md={{ span: 6, offset: 3 }}>
                  <div
                    className="fs-32 fw-600 mt-3 pt-3"
                    style={{ textAlign: "center" }}
                  >
                    Oh, no. This could end badly
                  </div>
                  <div
                    className="light-font-text mt-3"
                    style={{ textAlign: "center" }}
                  >
                    Right now this promotion label is in use. Before the
                    promotion label can be deleted, all units currently using
                    this promotion label must be moved to a new promotion label.
                    Select which promotion label you want to transfer the units
                    to. The action cannot be undone and has effect on websites,
                    dashboard, etc.
                  </div>

                  <div
                    className="fs-14 fw-300 mt-3 py-4"
                    style={{
                      textAlign: "center",
                      backgroundColor: "#F9FAFA",
                      borderRadius: "8px",
                    }}
                  >
                    <GreenSpan style={{ fontSize: "20px" }}>
                      {statIdUnitCount?.unitCount} units and{" "}
                      {statIdUnitCount?.configurationCount} configurations are
                      using the label{" "}
                      {
                        getPromotionLabelByPropertyIdData?.data?.filter(
                          (val) =>
                            val?.promotion_label_id ===
                            statIdUnitCount?.promotionLabelId
                        )?.[0]?.internal_label
                      }
                    </GreenSpan>
                  </div>

                  <div className="mt-3">
                    <ReactSelect
                      placeholder={"Promotion label"}
                      value={displayItem(newPromotionLabelId)}
                      setPlaceholderPosition={true}
                      height={55}
                      clearable={false}
                      searchable={false}
                      options={groupedOptions}
                      onChange={(e) => setNewPromotionLabelId(e.value)}
                      noOptionsMessage={"No promotion label found"}
                    />
                  </div>
                </Col>
              </Row>

              <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
                {" "}
                <ETButton
                  variant={"white"}
                  buttonText="COLD FEET"
                  onClick={() => {
                    setDeletePopupToggle(false);
                    setNewPromotionLabelId("");
                  }}
                />
                <ETButton
                  variant={"primary"}
                  buttonText="TRANSFER AND DELETE"
                  onClick={handleDeletePromotionLabelWithUnits}
                />
              </div>
            </div>
          </CustomPopup>
        </div>
      )}
    </>
  );
};
