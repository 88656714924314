import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  DeleteArchiveDocument,
  GetContractOverviewByContractId,
  GetSigingSettingByPropertyId,
  GetSignerBySignerId,
  GetSignerDetails,
  GetSigningOrderByContractId,
  GetSigningOrderDetailByOrderId,
  GetSigningType,
  PostCancelSigningOrder,
  PostCreateSigningOrder,
  PostSigningOrderManualDocument,
  PostSigningSendToSigning,
  PutUpdateSigningOrder,
  PutUpdateSigningSetting,
} from "../../services/Signing";
import {
  PostAddDocumentPackage,
  PutUpdateDocumentBasicDetails,
} from "../../services/Documents";

//Get Signer details by propertyID
export const useGetSignersDetails = (propertyId) => {
  return useQuery(
    ["getSignerDetailsData", propertyId],
    () => GetSignerDetails(propertyId),
    { enabled: !!propertyId }
  );
};

//Get Signer by signerId
export const useGetSignerBySignerId = (propertyId, signerId, editFlag) => {
  return useQuery(
    ["getSigneBySignerIdData", propertyId, signerId],
    () => GetSignerBySignerId(propertyId, signerId),
    { enabled: !!propertyId, enabled: !!signerId, enabled: !!editFlag }
  );
};

//Get Signer setting by PropertyId
export const useGetSignerSettingByPropertyId = (propertyId) => {
  return useQuery(
    ["getSignerSettingByPropertyIdData", propertyId],
    () => GetSigingSettingByPropertyId(propertyId),
    { enabled: !!propertyId }
  );
};
//Get Signing order by contract id
export const useGetSigningOrderByContractId = (contract_id) => {
  return useQuery(
    ["qGetSigningOrderByContractId", contract_id],
    () => GetSigningOrderByContractId(contract_id),
    { enabled: !!contract_id }
  );
};
//Get Signing order detail by SigningOrderId
export const useGetSigningOrderDetailBySigningOrderId = (signingOrderId) => {
  return useQuery(
    ["qGetSigningOrderDetailBySigningOrderId", signingOrderId],
    () => GetSigningOrderDetailByOrderId(signingOrderId),
    { enabled: !!signingOrderId }
  );
};

// Post Signing send to signing
export const useSendToSigning = () => {
  return useMutation(PostSigningSendToSigning);
};

//Get contract overview by contract id
export const useGetContractOverview = (contract_id) => {
  return useQuery(
    ["qGetContractOverviewByContractId", contract_id],
    () => GetContractOverviewByContractId(contract_id),
    { enabled: !!contract_id }
  );
};

// Post Create signing order
export const usePostCreateSigningOrder = () => {
  const queryClient = useQueryClient();
  return useMutation(PostCreateSigningOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries("useGetStorgeRoomDetails");
      queryClient.invalidateQueries("useGetParkingLotDetails");
    },
  });
};

//Get signing type
export const useGetSigningType = (property_id) => {
  return useQuery(
    ["qGetSigningType", property_id],
    () => GetSigningType(property_id),
    { enabled: !!property_id }
  );
};

//Setting > Signing -- Update signing
export const usePutUpdateSigning = () => {
  return useMutation(PutUpdateSigningSetting);
};

// Signing - UploadSigningOrderManualDocument

export const useUploadSigningOrderManualDocument = () => {
  return useMutation(PostSigningOrderManualDocument);
};

//Signing order -- Update signing order
export const usePutUpdateSigningOrder = () => {
  return useMutation(PutUpdateSigningOrder);
};
//Signing order -- Delete archive documents
export const useDeleteArchiveDocuments = (
  contract_id,
  document_id,
  actor_id,
  signingOrder_id
) => {
  return useMutation(
    ["qDeleteArchiveDocument"],
    (contract_id, document_id, actor_id, signingOrder_id) =>
      DeleteArchiveDocument(
        contract_id,
        document_id,
        actor_id,
        signingOrder_id
      ),
    {
      enabled:
        !!contract_id && !!document_id && !!actor_id && !!signingOrder_id,
    }
  );
};

// Post cancel signing order
export const useCancelSigningOrder = () => {
  return useMutation(PostCancelSigningOrder);
};

//Post Add Signing Package

export const usePostAddDocumentPackage = () => {
  return useMutation(PostAddDocumentPackage);
};
// Put Update Signing Package Basic Details
export const usePutUpdateDocumentBasicDetails = () => {
  return useMutation(PutUpdateDocumentBasicDetails);
};
