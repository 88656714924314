import React from "react";
import { Col, Row } from "react-bootstrap";
import ETButton from "../../../../../Components/Buttons/ETButton";
import { GreenDiv } from "../../../../../styles/Common.style";

const FinalPromotionLabelPage = ({
  isPromotionLabelEdit,
  stepperState,
  setShowPromotionLabelPopup,
  getlanguages
}) => {
  const FinalStatusButton = () => {
    // handleAddStatusLabel();
    setShowPromotionLabelPopup(false);
  };

  return (
    <div>
      <Row
        style={{ height: "500px" }}
        className="scrollable-container-y pe-0 me-2"
      >
        <Col md={{ span: 8, offset: 2 }}>
          <div className="fs-32 form-label-title mt-5 pt-3 d-flex justify-content-center">
            Voila! You are like Picasso!
          </div>
          <div className="light-font-text mt-3" style={{ textAlign: "center" }}>
            Your Promotion label is{" "}
            {isPromotionLabelEdit ? "edited" : "created"}.
          </div>

          <div
            style={{ backgroundColor: "#F9FAFA", border: "1px solid #F4F5F5" }}
            className="radius-8 my-5  py-3 d-flex align-items-center flex-column"
          >
            <GreenDiv className="d-flex justify-content-center fs-20  light-font-text">
              {stepperState?.internal_label}
            </GreenDiv>
            <div className="d-flex flex-column ">
              {stepperState?.language?.map((item, index) => (
                <div key={index} className="fs-12 light-font-text grey-text">
                  {index + 1}. Language: {getlanguages?.data?.filter((el) => el.Id === item?.language_id)?.[0]?.Text}{" "}
                  {console.log("item?.language_text", item?.language_text)} |
                  Promotion label: {item?.external_label} | Price text label:{" "}
                  {item?.price_text}
                </div>
              ))}
            </div>
            <div className="d-flex flex-column ">
              <div className="fs-12 light-font-text grey-text">
                Background color: {stepperState?.background_color} | Font color:{" "}
                {stepperState?.font_color}
              </div>
            </div>
            {/* <div className="fs-12 grey-text d-flex justify-content-center">
              Font color: {fontColor} | Label color: {labelColor}
            </div> */}
          </div>
        </Col>
      </Row>
      <div className="py-2 px-3 d-flex flex-md-row flex-column   justify-content-end footer-section-button-container">
        {" "}
        <ETButton
          variant={"primary"}
          buttonText="GO TO LABELS"
          onClick={FinalStatusButton}
        />
      </div>
    </div>
  );
};

export default FinalPromotionLabelPage;
