// Created by -- Suraj
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Placeholder } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ScreeningFormSettings from "./ScreeningFormSettings";
import ScreeningFormBuild from "./ScreeningFormBuild";
import { CustomersTab } from "../../../../Components/Tabs/CustomersTabs.style";
import {
  usePropertyId,
  usePropertyOverview,
} from "../../../../hooks/stores/usePropertyStore";
import { GreenSpan, H1 } from "../../../../styles/Common.style";
import {
  useAddScreeningForm,
  useUpdateScreeningForm,
} from "../../../../hooks/queries/useScreeningQueries";
// import { useForm } from "react-hook-form";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { toast } from "react-toastify";
import { useScreening } from "../../../../hooks/stores/useScreeningStore";

const CreateScreeningForm = () => {
  //================ Constants ===============//
  const { state } = useLocation();
  const [onStep1, setStep1] = useState(true);
  const [storeScreeningPayLod, setStoreScreeningPayLoad] = useState([]);
  const [unitsData, setUnitsData] = useState([]);
  const [numbersOfCustomersTab, setNumbersOfCustomersTab] = useState(0);

  // Zustand get data for propertyOverview
  const { propOverview, setPropOverview } = usePropertyOverview(
    (state) => state
  );
  const [settingsData, setSettingsData] = useState({});
  const [buildData, setBuildData] = useState({});

  const [categoryPayload, setCategoryPayload] = useState([]);
  const [screeningSectionData, setScreeningSectionData] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const {
    selectedLanguageId,
    formStatusId,
    linkedUnitId,
    sectionJsonData,
    // categoryJsonData,
    fieldConditionRules,
    yellowScreeningConditions,
    redScreeningConditions,
    isYellowFlagActivated,
    setIsYellowFlagActivated,
    isRedFlagActivated,
    setIsRedFlagActivated,
    isYellowFlagMessageActivated,
    isRedFlagMessageActivated,
    screeningLogicType,
    setScreenigLogicType,
    screeningSectionStoreData,
    isFieldConditionActivated,
    actionType,
    logicType,
    possibleValue,
    resetScreeningStore,
    EditscreeningForm,
    screeningFormId,
    setDraft,
    draft,
    parentFormId,
  } = useScreening((state) => state);

  const [yellowFlagMessage, setYellowFlagMessage] = useState(
    selectedLanguageId.map((lang) => ({
      language_id: lang,
      text: "",
    }))
  );

  // console.log(
  // 	"🚀 ~ file: CreateScreeningForm.js:63 ~ CreateScreeningForm ~ yellowFlagMessage:",
  // 	yellowFlagMessage
  // );
  const [redFlagMessage, setRedFlagMessage] = useState(
    selectedLanguageId.map((lang) => ({
      language_id: lang,
      text: "",
    }))
  );
  // console.log(
  // 	"🚀 ~ file: CreateScreeningForm.js:73 ~ CreateScreeningForm ~ redFlagMessage:",
  // 	redFlagMessage
  // );
  const { propertyId } = usePropertyId();
  const {
    mutateAsync: addScreeningFormMuatate,
    isLoading: isAddScreeningLoading,
  } = useAddScreeningForm();

  const {
    mutateAsync: updateScreeningFormMutate,
    isLoading: isUpdateScreeningFormLoading,
  } = useUpdateScreeningForm();
  // const {
  //   control,
  //   register,
  //   handleSubmit,
  //   setValue,
  //   getValues,

  //   clearErrors,
  //   formState: { errors },
  // } = useForm();

  const {
    watch,
    reset,
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    setError,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      screeningSection: [
        {
          screening_category_id: "",
          section_id: Math.floor(Date.now() / 1000),
          section_headline: selectedLanguageId?.map((lang) => ({
            language_id: lang,
            text: "",
          })),
          section_description: selectedLanguageId?.map((lang) => ({
            language_id: lang,
            text: "",
          })),
          display_order_no: "",
          questionFields: [],
        },
      ],
    },
  });
  const {
    fields: fields,
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
    update,
  } = useFieldArray({
    control,
    name: "screeningSection",
  });
  const getOperatorFromLabel = (label) => {
    switch (label) {
      case "equal to":
        return "=";
      case "does not equal to":
        return "!=";
      case "greater than":
        return ">";
      case "no less than":
        return ">=";
      case "has a value":
        return "exists";
      case "has no value":
        return "not exists";
      default:
        return null;
    }
  };
  const yRules = yellowScreeningConditions?.map((yD, index) => {
    const rule = {
      operator: getOperatorFromLabel(yD?.condition?.label),
      value: yD?.optionValue,
    };
    return rule;
  });
  const yellowScreeningRes = [
    {
      flag_type: isYellowFlagActivated ? 1 : "",
      logic_type: screeningLogicType || "",
      show_message: isYellowFlagMessageActivated ? 1 : 0,
      rules: yRules,
    },
  ];
  // console.log("yellowScreeningRes", yellowScreeningRes);
  // setYellowScreeningResults(yellowScreeningRes);
  const rRules = redScreeningConditions?.map((rD, index) => {
    const rule = {
      operator: getOperatorFromLabel(rD?.condition?.label),
      value: rD?.optionValue,
    };
    return rule;
  });
  const redScreeningRes = [
    {
      flag_type: isRedFlagActivated ? 1 : "",
      logic_type: redScreeningConditions.LogicType || "",
      show_message: isRedFlagMessageActivated ? 1 : 0,
      rules: rRules,
    },
  ];
  // console.log("redScreeningRes", redScreeningRes);
  const handleSubmitSettings = (data) => {
    // Handle the submission of ScreeningFormSettings data here
    // console.log("Settings data:", data);
  };

  const handleSubmitBuild = (data) => {
    // Handle the submission of ScreeningFormBuild data here
    // console.log("Build data:", data);
  };

  // console.log("yellowScreeningResults CSF", yellowScreeningResults);
  // console.log("redScreeningResults CSF", redScreeningResults);
  // console.log("screeningSectionStoreData CSF", screeningSectionStoreData);
  // screeningSectionStoreData?.map((sD, sectionIndex) => {
  // 	sD?.questionFields?.map((element, questionIndex) =>
  // 		console.log("element", element)
  // 	);
  // });
  // debugger;
  // console.log("yellowScreeningConditions", yellowScreeningConditions);
  const transformedData = screeningSectionData?.map(
    (section, sectionIndex) => ({
      name: section?.section_headline,
      description: section?.section_description,
      category_id: section?.screening_category_id,
      display_order_no: sectionIndex,
      question: section?.questionFields?.map((questionField, questionId) => ({
        screening_question_id: questionId + 1, // Assuming the questionId starts from 1
        screening_field_id: questionField?.screening_field_id,
        headline: questionField?.headlineArray,
        description: questionField?.descriptionArray,
        placeholder: questionField?.placeholderArray,
        label: questionField?.labelArray,
        possible_values: possibleValue,
        is_required: questionField.is_required || 0,
        field_rules: questionField.field_rules || {},
        is_prescreening_question: 0,
        display_order_no: questionId,
        is_field_condition_activated: isFieldConditionActivated ? 1 : 0,
        field_condition: {
          action_type: actionType,
          logic_type: logicType,
          rules: fieldConditionRules,
        },
        is_screening_condition_activated:
          isYellowFlagActivated || isRedFlagActivated ? 1 : 0,
        screening_condition: [...yellowScreeningRes, ...redScreeningRes],
        yellow_flag_message: yellowFlagMessage,
        red_flag_message: redFlagMessage,
        screening_yellow_flag_condition_id:
          yellowScreeningConditions?.triggerAction?.value == "" ||
          yellowScreeningConditions?.triggerAction?.value == undefined ||
          yellowScreeningConditions?.triggerAction?.value == null
            ? 0
            : yellowScreeningConditions?.triggerAction?.value,
        screening_red_flag_condition_id:
          redScreeningConditions?.triggerAction?.value == "" ||
          redScreeningConditions?.triggerAction?.value == undefined ||
          redScreeningConditions?.triggerAction?.value == null
            ? 0
            : redScreeningConditions?.triggerAction?.value,
      })),
    })
  );
  // console.log(
  // 	"🚀 ~ file: CreateScreeningForm.js:172 ~ CreateScreeningForm ~ screeningSectionStoreData:",
  // 	screeningSectionStoreData
  // );

  // debugger;
  // console.log(transformedData);

  // const questionObjs = screeningSectionStoreData?.map((sD, sectionIndex) => {
  // 	// debugger;
  // 	return sD.questionFields.map((element, questionIndex) => ({

  // 		sectionId: sectionIndex,
  // 		screening_question_id: questionIndex,

  // 		screening_field_id: element.screening_field_id,
  // 		headline: element?.headlineArray,
  // 		description: element?.descriptionArray,
  // 		placeholder: element?.placeholderArray,
  // 		label: element?.labelArray,
  // 		possible_values: element?.possible_values,
  // 		is_required: "",
  // 		field_rules: [],
  // 		is_prescreening_question: "",
  // 		display_order_no: questionIndex,
  // 		is_field_condition_activated: isFieldConditionActivated,
  // 		field_condition: {
  // 			action_type: actionType,
  // 			logic_type: logicType,
  // 			rules: fieldConditionRules,
  // 		},
  // 		is_screening_condition_activated:
  // 			isYellowFlagActivated || isRedFlagActivated ? 1 : 0,
  // 		screening_condition: [...yellowScreeningRes, ...redScreeningRes],
  // 		yellow_flag_message:
  // 			yellowScreeningConditions?.message == ""
  // 				? ""
  // 				: yellowScreeningConditions?.message,
  // 		red_flag_message:
  // 			redScreeningConditions?.message == ""
  // 				? ""
  // 				: redScreeningConditions?.message,
  // 		screening_yellow_flag_condition_id:
  // 			yellowScreeningConditions?.triggerAction?.value == ""
  // 				? ""
  // 				: yellowScreeningConditions?.triggerAction?.value,
  // 		screening_red_flag_condition_id:
  // 			redScreeningConditions?.triggerAction?.value == ""
  // 				? ""
  // 				: redScreeningConditions?.triggerAction?.value,
  // 	}));
  // });
  // console.log("questionObjs--", questionObjs);

  const transFormedCategoryPayload = categoryData?.map((item) => {
    const id = item?.screening_category_id;
    const name = item?.category_name?.map((lang) => ({
      language_id: lang?.language_id,
      text: lang?.name,
    }));
    const description = item?.category_name?.map((lang) => ({
      language_id: lang?.language_id,
      text: lang?.description,
    }));
    const display_order_no = item?.display_order_no;

    return {
      id,
      name,
      description,
      display_order_no,
    };
  });
  const handleAddScreening = async (formStatus) => {
    setStep1(false);
    if (EditscreeningForm) {
      const updateScreeningFormPayload = {
        ...settingsData,
        form_status_id: formStatus,
        screening_form_id: screeningFormId,
        category: transFormedCategoryPayload,
        section: transformedData,
      };

      try {
        const response = await updateScreeningFormMutate(
          updateScreeningFormPayload
        );
        // setStoreScreeningPayLoad(addScreeningFormPayload);
        if (response?.status === "success" && response?.data?.bReturn) {
          toast.success(response?.data?.message);
          resetScreeningStore();
          setSettingsData({});
          setBuildData({});
          navigate("/property-units/settings/screening", {
            state: {
              Tab: "Settings",
            },
          });
        } else {
          toast?.error(response?.data?.message);
        }
      } catch (error) {}
    } else {
      const addScreeningFormPayload = {
        ...settingsData,
        form_status_id: formStatus,
        category: transFormedCategoryPayload,
        section: transformedData,
      };

      // console.log("addScreeningFormPayload----", addScreeningFormPayload);
      try {
        const response = await addScreeningFormMuatate(addScreeningFormPayload);
        // setStoreScreeningPayLoad(addScreeningFormPayload);
        if (response?.status === "success" && response?.data?.bReturn) {
          toast.success(response?.data?.message);
          resetScreeningStore();
          setSettingsData({});
          setBuildData({});
          navigate("/property-units/settings/screening", {
            state: {
              Tab: "Settings",
            },
          });
        } else {
          toast?.error(response?.data?.message);
        }
      } catch (error) {}
    }

    // console.log("handleAddScreening data", data);
  };
  //  this code used to transfer property unit page to property page
  const navigate = useNavigate();
  const navigateToProperty = () => {
    navigate("/properties");
  };
  return (
    <div>
      {/* top bar header and breadcrumbs */}
      <Placeholder animation="glow">
        <H1 className="fs-32 pt-3">Properties</H1>
        <div
          style={{
            fontFamily: " Cerebri sans-light",
            fontSize: "14px",
            cursor: "pointer",
          }}
        >
          <span className="me-3" onClick={navigateToProperty}>
            Properties
          </span>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <Link
            to="/property-units/overview"
            state={{ Tab: "Overview", propId: propOverview?.[0]?.property_id }}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span className="me-3">
              {propOverview?.[0]?.propertyName
                ? propOverview?.[0]?.propertyName
                : "propertyName"}
            </span>
          </Link>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <Link
            to="/property-units/settings"
            state={{ Tab: "Settings", propId: propOverview?.[0]?.property_id }}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span className="me-3">Settings</span>
          </Link>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <Link
            to="/property-units/settings/screening"
            state={{ Tab: "Settings", propId: propOverview?.[0]?.property_id }}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span className="me-3">Screening</span>
          </Link>
          <span className="me-3">
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />{" "}
          </span>
          <GreenSpan>
            {onStep1 == true ? "1. Settings" : "2. Build form"}
          </GreenSpan>
        </div>
      </Placeholder>

      {/* gray div area */}
      <div
        className="mt-3 pb-3 px-4"
        style={{ backgroundColor: "#E4E7E8", borderRadius: "10px" }}
      >
        <div
          className="d-flex gap-2 pt-4 pb-2 mb-1"
          style={{ alignItems: "center", textAlign: "center" }}
        >
          <div>
            <CustomersTab mode={onStep1}>1. Settings</CustomersTab>
          </div>
          <div style={{ alignItems: "center", textAlign: "center" }}>
            <FontAwesomeIcon
              size="sm"
              icon="fa-regular fa-angle-right"
              style={{ color: "#000000" }}
            />
          </div>
          <div>
            <CustomersTab mode={!onStep1}>2. Build form</CustomersTab>
          </div>
        </div>

        {/* white section for both forms */}
        <div className="bg-white radius-8">
          {/* <div>
						<button onClick={handleAddScreening}>CLICK</button>
					</div> */}
          {/* <Form> */}
          <div style={{ display: onStep1 == false && "none" }}>
            <ScreeningFormSettings
              setStep1={setStep1}
              setUnitsData={setUnitsData}
              unitsData={unitsData}
              // getValues={getValues}
              setValue={setValue}
              getValues={getValues}
              register={register}
              errors={errors}
              watch={watch}
              clearErrors={clearErrors}
              // setStoreScreeningPayLoad={setStoreScreeningPayLoad}
              control={control}
              handleAddScreening={handleAddScreening}
              useForm={useForm}
              Controller={Controller}
              handleSubmit={handleSubmit}
              onSubmit={handleSubmitSettings}
              setSettingsData={setSettingsData}
              setCategoryData={setCategoryData}
              fields={fields}
            />
          </div>
          <div style={{ display: onStep1 == true && "none" }}>
            <ScreeningFormBuild
              setStep1={setStep1}
              storeScreeningPayLod={storeScreeningPayLod}
              handleAddScreening={handleAddScreening}
              onSubmit={handleSubmitBuild}
              setValue={setValue}
              getValues={getValues}
              register={register}
              errors={errors}
              control={control}
              useForm={useForm}
              Controller={Controller}
              handleSubmit={handleSubmit}
              setBuildData={setBuildData}
              setYellowFlagMessage={setYellowFlagMessage}
              setRedFlagMessage={setRedFlagMessage}
              categoryPayload={categoryPayload}
              setCategoryPayload={setCategoryPayload}
              // sectionJson={sectionJson}
              // setSectionJson={setSectionJson}
              screeningSectionData={screeningSectionData}
              setScreeningSectionData={setScreeningSectionData}
              categoryData={categoryData}
              setCategoryData={setCategoryData}
              fields={fields}
              append={append}
              remove={remove}
              setError={setError}
              clearErrors={clearErrors}
              trigger={trigger}
            />
          </div>
          {/* </Form> */}
        </div>
      </div>
    </div>
  );
};

export default CreateScreeningForm;
