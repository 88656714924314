import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { Dropdown, Placeholder } from "react-bootstrap";
import CustomPopup from "../../../../Components/CustomPopup";
import MultipleUnits1 from "../unitsCrudOperation/MultipleUnits1";
import { TabsButtonIcon } from "../../../../Components/Tabs/Tabs.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menus from "../../../../Components/Menus/Menus";
import CustomCheckBox from "../../../../Components/Checkbox/Checkbox";
import ETButton from "../../../../Components/Buttons/ETButton";
import {
  useGetEditUnitsTextField,
  useGetParkingLotDetails,
  useGetStorageRoomDetails,
  useGetUnitsByPropertyIdUpdated,
} from "../../../../hooks/queries/useUnitQueries";
import CSVWidget from "../../../../Components/DownloadWidget/CSVWidget";
import Toggle from "../../../../Components/Switch/Switch";
import CustomizeColumns from "./CustomizeColumns";
import { useUnitType } from "../../../../hooks/stores/useUnitStore";
import * as XLSX from "xlsx";

const AddUnits = ({ isLoading, propertyId, propertyOverviw, UnitsData }) => {
  const { typeOfUnit } = useUnitType((state) => state);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [show, setShow] = useState(false);
  const [editmMultipleShow, setEditmMultipleShow] = useState(false);
  const [rotateAddIcon, setRotateAddIcon] = useState(false);
  const [exportFile, setExportFile] = useState(false);
  const [fieldChecked, setFieldChecked] = useState([]);
  const [settingView, setSettingView] = useState(false);
  const [customizeColumnsShow, setCustomizeColumnsShow] = useState(false);
  const [exportType, setExportType] = useState("");
  const rotate = rotateAddIcon ? "rotate(45deg)" : "rotate(0deg)";

  const { data: getUnitTextFields } = useGetEditUnitsTextField(
    propertyId,
    typeOfUnit
  );
  //Units data for exports
  const {
    data: GetUnitsByPropertyIdDataForExport,
    refetch: GetUnitsByPropertyIdDataRefetchForExport,
  } = useGetUnitsByPropertyIdUpdated(propertyId, 1);
  const { data: GetParkingLotDetailsDataForExport } = useGetParkingLotDetails(
    propertyId,
    1
  );
  const { data: GetStorageRoomDetailsDataForExport } = useGetStorageRoomDetails(
    propertyId,
    1
  );

  const AddUnitClick = (event) => {
    setAnchorEl(event?.currentTarget);
    setDropdownOpen(true);
    setRotateAddIcon(!rotateAddIcon);
  };
  const AddUnitClose = () => {
    setAnchorEl(null);
    setDropdownOpen(false);
    setRotateAddIcon(!rotateAddIcon);
  };
  const addMultipleunits = () => {
    setAnchorEl(null);
    setDropdownOpen(false);
    setShow(true);
    setRotateAddIcon(!rotateAddIcon);
  };
  const editMultipleunits = () => {
    setAnchorEl(null);
    setDropdownOpen(false);
    setEditmMultipleShow(true);
    setRotateAddIcon(!rotateAddIcon);
  };
  const handleCancelExport = () => {
    setExportFile(false);
    setFieldChecked([]);
  };
  const filteredTextfieldsData = getUnitTextFields?.data?.filter(
    (item) => item?.is_export !== 0
  );
  const handleAllFields = (e) => {
    if (e.target.checked) {
      const allUnitTypes = filteredTextfieldsData?.map(
        (item) => item?.column_name
      );
      setFieldChecked(allUnitTypes);
    } else {
      setFieldChecked([]);
    }
  };
  const handleCheckedTextfield = (e, item) => {
    if (e?.target?.checked) {
      setFieldChecked([...fieldChecked, item?.column_name]);
    } else {
      setFieldChecked(fieldChecked?.filter((el) => el !== item?.column_name));
    }
  };
  //  csv logic
  function getFieldsArray(fieldNames) {
    return fieldNames?.map((fieldName) => ({ field: fieldName }));
  }
  const fieldsArray = getFieldsArray(fieldChecked);
  const mandatoryColumnsForCsv = [
    typeOfUnit === 1
      ? { field: "unit_id" }
      : typeOfUnit === 2
      ? { field: "parking_lot_id" }
      : { field: "storage_room_id" },
  ];

  const options = {
    keys: mandatoryColumnsForCsv?.concat(fieldsArray),
  };
  const fileName = `${propertyOverviw?.[0]?.propertyName} ${
    typeOfUnit === 1
      ? `Residential`
      : typeOfUnit === 2
      ? `Parking lot`
      : `Storage room`
  } Units`;

  // let data =
  //   (typeOfUnit === 1 && GetUnitsByPropertyIdDataForExport?.data) ||
  //   (typeOfUnit === 2 && GetParkingLotDetailsDataForExport?.data) ||
  //   (typeOfUnit === 3 && GetStorageRoomDetailsDataForExport?.data);

  // const dataArray = Object?.values(data);
  let data = null;

  if (typeOfUnit === 1) {
    data = GetUnitsByPropertyIdDataForExport?.data;
  } else if (typeOfUnit === 2) {
    data = GetParkingLotDetailsDataForExport?.data;
  } else if (typeOfUnit === 3) {
    data = GetStorageRoomDetailsDataForExport?.data;
  }

  const dataArray = data ? Object.values(data) : [];

  const mappedData = dataArray?.map((unit) => {
    const mappedUnit = { ...unit };

    // Convert unit_id to string
    if (typeOfUnit === 1) {
      mappedUnit.unit_id = `'${mappedUnit?.unit_id}'`;
      // Convert date columns to strings
      mappedUnit.vacancy_date = `'${mappedUnit?.vacancy_date}'`;
      mappedUnit.move_in_date = `'${mappedUnit?.move_in_date}'`;
      mappedUnit.move_out_date = `'${mappedUnit?.move_out_date}'`;
      mappedUnit.unit_construction_year = `'${mappedUnit?.unit_construction_year}'`;
    } else if (typeOfUnit === 2) {
      mappedUnit.parking_lot_id = `'${mappedUnit?.parking_lot_id}'`;
    } else if (typeOfUnit === 3) {
      mappedUnit.storage_room_id = `'${mappedUnit?.storage_room_id}'`;
    }

    return mappedUnit;
  });

  data = mappedData;
  // to export units as csv
  const CSVFunction = CSVWidget({ fileName, options, data });

  // to export units as xlsx
  const xlsxFunction = () => {
    const nonExportFields = getUnitTextFields?.data?.filter(
      (item) => item?.is_export === 0
    );
    const nonExportColumnNames = nonExportFields?.map(
      (field) => field?.column_name
    );
    let filteredXlsxData = data?.map((item) => {
      const filteredItem = {};
      for (const key in item) {
        if (!nonExportColumnNames?.includes(key)) {
          filteredItem[key] = item[key];
        }
      }
      return filteredItem;
    });

    let wb = XLSX?.utils?.book_new(),
      ws = XLSX?.utils?.json_to_sheet(filteredXlsxData);
    XLSX?.utils?.book_append_sheet(wb, ws, "sheet");
    XLSX?.writeFile(wb, `${fileName}.xlsx`);
  };

  // Dropdown for filter
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      style={{
        backgroundColor: "#fff",
        border: "1px solid #D1D1D1",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "40px",
        height: "41px",
        cursor: "pointer",
      }}
      className="d-flex"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));
  return (
    <div className="d-flex w-100">
      <div className="pe-3">
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <TabsButtonIcon>
              <FontAwesomeIcon icon="fa-regular fa-gear" />
            </TabsButtonIcon>
          </Dropdown.Toggle>

          <Dropdown.Menu className="py-0" style={{ overflow: "hidden" }}>
            <div>
              <div
                className="px-2 py-2  light-font-text cursor-pointer Dropdown-menu-item"
                onClick={() => setCustomizeColumnsShow(true)}
              >
                Customize columns
              </div>
              {typeOfUnit === 1 && (
                <>
                  <hr className="my-0"></hr>
                  <div
                    className="px-2 py-2 light-font-text cursor-pointer Dropdown-menu-item"
                    onClick={() => setSettingView(true)}
                  >
                    View
                  </div>
                </>
              )}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="w-100">
        {isLoading ? (
          <div className="" style={{ width: "132px" }}>
            <Placeholder animation="glow">
              <div className="placeholder col-10 radius-8 padding-20 bg-secondary"></div>
            </Placeholder>
          </div>
        ) : (
          <ETButton
            variant={"primary"}
            buttonText="ADD UNITS"
            textColor="white"
            rotate={rotate}
            icon={true}
            width="100%"
            onClick={AddUnitClick}
          />
        )}

        <Menu
          anchorEl={anchorEl}
          open={anchorEl}
          onClose={AddUnitClose}
          PaperProps={{
            style: {
              borderRadius: "8px",
              marginTop: "6px",
              border: "0.5px solid #F0F0F0",
              boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.12)",
              paddingTop: "0px",
            },
          }}
          sx={{
            "& .css-6hp17o-MuiList-root-MuiMenu-list": {
              padding: 0,
              borderColor: "##F0F0F0",
            },
          }}
        >
          <div
            className="addpropertyCountryHandle "
            style={{ fontSize: "14px", width: "208px" }}
          >
            <div
              className="addpropContent px-3 py-2 "
              onClick={addMultipleunits}
            >
              <span className="ms-1">Add multiple units</span>
            </div>

            <hr className="addpropertyHr m-0"></hr>
            <div
              className="addpropContent px-3 pt-2 pb-2 "
              onClick={editMultipleunits}
            >
              <span className="ms-1">Edit muliple units</span>
            </div>
          </div>
        </Menu>

        {/* Add multiple units popup*/}

        <CustomPopup
          open={show}
          onHide={() => setShow(false)}
          title="Add multiple units"
          title_color="black"
          titleFont="14px"
          titleTooltip={true}
          titleTooltipText="Add multiple units"
          size="lg"
          enableCrossButton={true}
          onCloseAction={() => {
            setShow(false);
            alert("Action Peformed Successfuly.");
          }}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
          onClickCrossButton={() => setShow(false)}
        >
          <MultipleUnits1
            operationName="addMultipleUnits"
            name={show}
            unitsTableChange={setShow}
            propertyId={propertyId}
            editmMultipleShow={editmMultipleShow}
          />
        </CustomPopup>

        {/* Edit multiple units popup*/}

        <CustomPopup
          open={editmMultipleShow}
          onHide={() => setEditmMultipleShow(false)}
          title="Edit muliple units"
          title_color="black"
          titleFont="14px"
          titleTooltip={true}
          titleTooltipText="Edit muliple units"
          size="lg"
          enableCrossButton={true}
          onCloseAction={() => {
            setEditmMultipleShow(false);
            alert("Action Peformed Successfuly.");
          }}
          action_button_label="YES"
          action_button_label_color="white"
          action_button_color="#619130"
          cancel_button={true}
          cancel_button_bordercolor="grey"
          cancel_button_label="NO"
          cancel_button_label_color="black"
          cancel_button_color="#fff"
          footer_color={"#F9FAFA"}
          onClickCrossButton={() => setEditmMultipleShow(false)}
        >
          <MultipleUnits1
            operationName="editMultipleUnits"
            unitsTableChange={setEditmMultipleShow}
            propertyId={propertyId}
            editmMultipleShow={editmMultipleShow}
          />
        </CustomPopup>
      </div>

      <CustomPopup
        open={exportFile}
        onHide={() => setExportFile(false)}
        title="Export CSV"
        title_color="black"
        titleFont="14px"
        titleTooltip={true}
        titleTooltipText="Edit muliple units"
        size="md"
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div
          style={{
            height: "250px",
            overflow: "scroll",
          }}
        >
          <div className="py-2 ps-2 pe-5">
            <div className="form-label-title mb-2">Select Fields </div>
            <div className="d-flex align-items-center light-font-text ">
              <CustomCheckBox
                id={"handleAllFields"}
                onChange={(e) => handleAllFields(e)}
                checked={
                  fieldChecked?.length === filteredTextfieldsData?.length
                }
                label={" All Fields"}
              />{" "}
            </div>
            {filteredTextfieldsData?.map((item, i) => (
              <>
                <div
                  key={i}
                  className="d-flex align-items-center light-font-text ps-4 "
                >
                  <CustomCheckBox
                    id={item?.field_id}
                    checked={fieldChecked?.includes(item?.column_name)}
                    onChange={(e) => handleCheckedTextfield(e, item)}
                    label={item?.field_label}
                  />{" "}
                  {/* {item.field_label} */}
                </div>
              </>
            ))}
          </div>
        </div>

        <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={handleCancelExport}
          />
          <ETButton
            variant={"primary"}
            buttonText="EXPORT"
            disabled={fieldChecked?.length == 0}
            onClick={() => {
              exportType === "CSV" ? CSVFunction() : xlsxFunction();
              handleCancelExport();
            }}
          />
        </div>
      </CustomPopup>

      {/* {annouonce popup} */}
      <CustomPopup
        open={settingView}
        onHide={() => setSettingView(false)}
        title="Status overview"
        title_color="black"
        titleFont="14px"
        titleTooltip={true}
        titleTooltipText="View"
        size="md"
        enableCrossButton={false}
        onCloseAction={() => {
          setSettingView(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div className="py-4 px-4 fs-14 fw-300">
          <div className="light-font-text ps-2">
            If you want the status overview to always be visible, you can switch
            it on here.
          </div>
          <div className="pb-2 d-flex gap-2 align-items-center pt-2">
            <Toggle id={"333"} />
            <div className="light-font-text">Turn on status view</div>
          </div>
        </div>
        <div className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
          <ETButton
            variant={"white"}
            buttonText="CANCEL"
            onClick={() => setSettingView(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="Confirm"
            onClick={() => setSettingView(false)}
          />
        </div>
      </CustomPopup>

      {/* Customize columns */}
      <CustomPopup
        open={customizeColumnsShow}
        onHide={() => setCustomizeColumnsShow(false)}
        title="Customize columns"
        title_color="black"
        titleFont="14px"
        titleTooltip={true}
        titleTooltipText="Customize columns"
        size="lg"
        enableCrossButton={false}
        onCloseAction={() => {
          setCustomizeColumnsShow(false);
        }}
        action_button_label="YES"
        action_button_label_color="white"
        action_button_color="#619130"
        cancel_button={true}
        cancel_button_bordercolor="grey"
        cancel_button_label="NO"
        cancel_button_label_color="black"
        cancel_button_color="#fff"
        footer_color={"#F9FAFA"}
      >
        <div>
          <CustomizeColumns setCustomizeColumnsShow={setCustomizeColumnsShow} />
        </div>
      </CustomPopup>
    </div>
  );
};

export default AddUnits;
