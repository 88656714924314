import React, { useState } from "react";
import styled from "styled-components";

const Button = styled.button`
  border: ${(props) =>
    props.active
      ? `1px solid ${props.theme.component.button.primary.bgColor}`
      : `1px Solid #D1D1D1`};
  outline: none;
  background-color: ${(props) =>
    props.active
      ? props.theme.component.button.primary.bgColor + "22"
      : "#FFFFFF"};
  color: "#000000";
  height: 40px;
  width: ${(props) => (props.width ? props.width : "140px")};
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Cerebri sans-light";
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
    background-color: white;
    border: 1px solid #d1d1d1;
    color: #000000;
    font-family: Cerebri sans-light;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const YesNoButton = ({
  value,
  onChange,
  btnText1,
  btnText2,
  disabled,
  width,
}) => {
  const handleClick = (newValue) => {
    if (newValue !== value) {
      onChange(newValue);
      //   console.log(value);
    }
  };

  return (
    <ButtonWrapper>
      <Button
        width={width}
        active={value === 1 ? true : false}
        onClick={() => handleClick(1)}
        disabled={disabled}
        type="button"
      >
        {btnText1}
      </Button>
      <Button
        width={width}
        active={value === 0 ? true : false}
        onClick={() => handleClick(0)}
        disabled={disabled}
        type="button"
      >
        {btnText2}
      </Button>
    </ButtonWrapper>
  );
};

export default YesNoButton;
YesNoButton.defaultProps = {
  btnText1: "Yes",
  btnText2: "No",
  disabled: false,
};

// Created by Suraj vishwakarma
// Last modified by Abu hasim on 10/07/23
// Last modified by Suraj removing unwanted props creating error
