import { useMutation, useQuery, useQueryClient } from "react-query";
import {
	GetFacilityCategory,
	GetFacilityGroup,
	GetFacilityByPropertyId,
	GetFacilityPackageByFacilityId,
	GetFacilityPackageByPropertyId,
	AddFacilityPackage,
	AddFacilityAttributeValue,
	CloneFacilityPackage,
	EditFacilityPackageAttributeValue,
	EditFacilityPackage,
	SetActiveFacilityPackage,
	DeleteFacilityPackage,
	AddFinalFacilityPackage,
	EditFinalFacilityPackage,
	EditFacilityPackageBasicDetails,
	GetFacilityAttributesByFacilityId,
	GetFacilityPackageByPackageId,
	EditFacilityDetail,
	GetFacilityByUnitId,
	EditUnitFacilityAttributeValue,
	GetFacilityByPropertyIdUnit,
} from "../../services/Facility";

// get facility by property id
export const useGetFacilityByPropertyId = (id) => {
	return useQuery(["qGetFacilityByPropertyId", id], () =>
		GetFacilityByPropertyId(id)
	);
};
// get facility by property id unit level
export const useGetFacilityByPropertyIdUnit = (id, type) => {
	return useQuery(["qGetFacilityByPropertyId", id, type], () =>
		GetFacilityByPropertyIdUnit(id, type)
	);
};
// GetFacilityPackageByFacilityId

export const useGetFacilityPackageByPackageId = (
	propertyId,
	facilityPackageId,
	// flag
	editflag
) => {
	// console.log(
	// 	"isEditFacilityPackage useGetFacilityPackageByFacilityId",
	// 	isEditFacilityPackage
	// );
	return useQuery(
		["qGetFacilityPackageByPackageId", propertyId, facilityPackageId],
		() => GetFacilityPackageByPackageId(propertyId, facilityPackageId),
		{
			enabled:
				(propertyId !== undefined ||
					propertyId !== null ||
					propertyId !== "") &&
				(facilityPackageId !== null ||
					facilityPackageId !== undefined ||
					facilityPackageId !== "") &&
				editflag,
		}
	);
};

//GetFacilityAttributesByFacilityId

export const useGetFacilityAttributesByFacilityId = (
	propertyId,
	unitId,
	packageId,
	facilityId
) => {
	return useQuery(
		[
			"qGetFacilityAttributesByFacilityId",
			propertyId,
			unitId,
			packageId,
			facilityId,
		],
		() =>
			GetFacilityAttributesByFacilityId(
				propertyId,
				unitId,
				packageId,
				facilityId
			),
		{ enabled: !!facilityId }
	);
};

// GetFacilityPackageByPropertyId

export const useGetFacilityPackageByPropertyId = (propertyId, packageId) => {
	return useQuery("qGetFacilityPackageByPropertyId", () =>
		GetFacilityPackageByPropertyId(propertyId, packageId)
	);
};

//Get Facility Category
export const useGetFacilityCategory = () => {
	return useQuery("qGetFacilityCategory", () => GetFacilityCategory(), {
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		cacheTime: 10800000,
	});
};

//Get Facility Group
export const useGetFacilityGroup = () => {
	return useQuery("qGetFacilityGroup", () => GetFacilityGroup(), {
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		cacheTime: 10800000,
	});
};

// AddFacilityPackage

export const useAddFacilityPackage = () => {
	return useMutation(AddFacilityPackage);
};

// AddFacilityAttributeValue

export const useAddFacilityAttributeValue = () => {
	const queryClient = useQueryClient();

	return useMutation(AddFacilityAttributeValue, {
		onSuccess: () => {
			queryClient.invalidateQueries("qGetFacilityByPropertyId");
			queryClient.invalidateQueries("qGetFacilityPackageByPackageId");
		},
	});
};

// CloneFacilityPackage

export const useCloneFacilityPackage = () => {
	return useMutation(CloneFacilityPackage);
};

// EditFacilityPackageAttributeValue

export const useEditFacilityPackageAttributeValue = () => {
	const queryClient = useQueryClient();

	return useMutation(EditFacilityPackageAttributeValue, {
		onSuccess: () => {
			queryClient.invalidateQueries("qGetFacilityPackageByPackageId");
		},
	});
};

// EditFacilityPackage

export const useEditFacilityPackage = () => {
	return useMutation(EditFacilityPackage);
};

// SetActiveFacilityPackage

export const useSetActiveFacilityPackage = () => {
	return useMutation(SetActiveFacilityPackage);
};

// DeleteFacilityPackage

export const useDeleteFacilityPackage = () => {
	return useMutation(DeleteFacilityPackage);
};

// AddFinalFacilityPackage

export const useAddFinalFacilityPackage = () => {
	return useMutation(AddFinalFacilityPackage);
};

// EditFinalFacilityPackage
export const useEditFinalFacilityPackage = () => {
	return useMutation(EditFinalFacilityPackage);
};

// EditFacilityPackageBasicDetails
export const useEditFacilityPackageBasicDetails = () => {
	return useMutation(EditFacilityPackageBasicDetails);
};

export const useEditFacilityDetail = () => {
	return useMutation(EditFacilityDetail);
};

//GetFacilityByUnitId
export const useGetFacilityByUnitId = (propertyId, unitId) => {
	return useQuery(
		["qGetFacilityByUnitId", unitId],
		() => GetFacilityByUnitId(propertyId, unitId),
		{
			enabled: unitId !== null || unitId !== undefined || unitId !== "",
		},
		{
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		}
	);
};

// EditUnitFacilityAttributeValue
export const useEditUnitFacilityAttributeValue = () => {
	const queryClient = useQueryClient();

	return useMutation(EditUnitFacilityAttributeValue, {
		onSuccess: () => {
			queryClient.invalidateQueries("qGetFacilityPackageByPropertyId");
			queryClient.invalidateQueries("qGetFacilityByPropertyId");
			queryClient.invalidateQueries("qGetFacilityAttributesByFacilityId");
		},
	});
};
