import { Endpoint, Site } from "./API/Endpoints";
import { Delete, Get, Post, Put } from "./API/Method";

//Get Division by user Id
export const GetDivisionByUserId = () => {
  return new Promise((resolve, reject) => {
    Get(Site.ET, Endpoint.ET.Division_GetDivisionByUserId, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Add Division
export const PostAddDivision = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Division_AddDivision, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

// Delete Division by Division Id
export const DeleteDivisionByDivisionId = (divisionId) => {
  return new Promise((resolve, reject) => {
    Delete(Site.ET, Endpoint.ET.Division_DeleteDivision, null, divisionId)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Update Division
export const PutEditDivision = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Division_EditDivision, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

export const GetDivisionByOrganisationId = (OrganisationId, data_type) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Division_GetDivisionByOrganisationId,
      null,
      OrganisationId + "/type/" + data_type
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Get Division By DivisionId
export const GetDivisionByDivisionId = (divisionId) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Division_Get_Division_ByDivisionId,
      null,
      divisionId
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
//Get Division overview by division Id
export const GetDivisionOverviewByDivisionId = (division_id) => {
  return new Promise((resolve, reject) => {
    Get(
      Site.ET,
      Endpoint.ET.Division_GetDivisionOverviewByDivisionId,
      null,
      division_id + "/overview"
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Update Division
export const PostEditDivision = (data) => {
  return new Promise((resolve, reject) => {
    Post(Site.ET, Endpoint.ET.Division_Edit_Division, null, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};

//Post Add division session
export const PostAddDivisionSession = (divisionSessionPayload) => {
  return new Promise((resolve, reject) => {
    Post(
      Site.ET,
      Endpoint.ET.Division_PostAddDivisionSession,
      null,
      divisionSessionPayload
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ error: true, message: error.message });
      });
  });
};
