import React from "react";
import { Col, Row } from "react-bootstrap";

const Communication = () => {
  return (
    <div className="px-4 pt-1 pb-5">
      <div className="form-label-title">Support email address</div>
      <div className="light-font-text pt-2 pb-4 ">
        <Row>
          <Col xl={7} lg={8}>
            Enter the email address to be shown to the customer if they need to
            get in touch with you, and it is not possible to use the integrated
            messaging system
          </Col>
        </Row>
      </div>
      <Row className="pb-5">
        <Col lg={4} md={6}>
          <div className="form-input ">
            <input
              type="email"
              placeholder="Support email address"
              id="Support_email_address"
              maxLength={30}
            />
            <label htmlFor="Support_email_address">Support email address</label>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Communication;
