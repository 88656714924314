//field created for password fields with eye icon created by -- Suraj
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { PASS_REQUIRED_ERR } from "../../utils/validationConstants";

const PasswordField = ({
  placeholder,
  id,
  value,
  onChange,
  maxlength,
  defaultValue = "",
  iserror,
  removeOpacity = false,
  errormessage,
  errorscope = true,
  disabled = false,
}) => {
  const [show, setShow] = useState(true);

  const handleClick = () => {
    if (value?.length) {
      setShow(!show);
    }
  };
  return (
    <>
      <div className="d-flex w-100">
        <div style={{ position: "relative", width: "100%" }}>
          <div
            className={` ${iserror ? "form-error-input" : "form-input"}`}
            style={{ opacity: disabled && !removeOpacity ? "0.7" : "1" }}
          >
            <input
              type={show == true ? "password" : "text"}
              placeholder={placeholder}
              id={id}
              value={value}
              onChange={onChange}
              maxLength={maxlength}
              disabled={disabled}
              defaultValue={defaultValue}
            />
            <label htmlFor={id}>{placeholder}</label>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <FontAwesomeIcon
            onClick={handleClick}
            icon={
              value?.length
                ? show == true
                  ? "fa-regular fa-eye-slash"
                  : "fa-regular fa-eye"
                : "fa-regular fa-lock-keyhole"
            }
            style={{
              color: "#000000",
              position: "absolute",
              top: "12px",
              right: "15px",
            }}
          />
        </div>
      </div>
      {errorscope && iserror && (
        <p className="error-msg">
          {errormessage ? errormessage : PASS_REQUIRED_ERR}
        </p>
      )}
    </>
  );
};
export default PasswordField;
