// created by Suraj
import React, { useEffect, useMemo, useState } from "react";
import ETButton from "../../../../../Components/Buttons/ETButton";
import MRTTheme from "../../../../../Components/table/MRTTheme";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  paginationoptions,
} from "../../../../../Components/table/MRTGlobalProps";
import {
  CircleMsg,
  H4,
  TeamsImageContainer,
} from "../../../../../styles/Common.style";
import { inactiveTeamsData } from "../Teams";
import CustomCheckBox from "../../../../../Components/Checkbox/Checkbox";

const AssignLeaderToTeams = ({
  handleLeaderScreen = () => { },
  handleBackOnLeaderScreen = () => { },
  usedFor = "users",
  teamLeaderData,
  selectedTeamleader,
  setSelectedTeamleader,
  rowSelection,
  setRowSelection,
  setPreviousSelectedTeamManager,
  setPreviousSelectedTeamleader,
  // customeCheckBoxHandler,
  previousSelectedTeamleader,
  EditTeamData, flag

}) => {
  console.log("🚀 ~ file: AssignLeaderToTeams.js:39 ~ selectedTeamleader:", selectedTeamleader)
  // const [rowSelection, setRowSelection] = useState([])
  // const [teamleader, setTeamLeader] = useState([])
  const [sorting, setSorting] = useState([]);

  const teamMember = teamLeaderData.map((user) => ({
    user_id: user.user_id,
    is_team_leader: selectedTeamleader[user?.user_id] ? 1 : 0,
    is_team_manager: rowSelection[user?.user_id] ? 1 : 0,
  }));
  const customeCheckBoxHandler = (userId) => {
    setSelectedTeamleader((prevSelection) => ({
      ...prevSelection,
      [userId]: !prevSelection[userId],
    }));
    setPreviousSelectedTeamleader((prevSelection) => ({
      ...prevSelection,
      [userId]: !prevSelection[userId],
    }))
  };

  useEffect(() => {
    const isLeader = EditTeamData?.map((data) => data?.members)?.[0]
      ?.filter((data) => data?.is_team_leader == 1)
      ?.map((user) => user?.user_id);
    const filteredMembers = isLeader?.filter((id) => selectedTeamleader[id] !== false);
    const memberlist = EditTeamData?.map((data) => data?.members)?.flat();
    if (flag) {
      const defaultTeamLeaderSelection = { ...previousSelectedTeamleader };
      if (filteredMembers && filteredMembers.length > 0) {

        memberlist?.forEach((item) => {
          if (filteredMembers?.includes(item.user_id)) {
            defaultTeamLeaderSelection[item.user_id] = true;
          }
        });
        setSelectedTeamleader(defaultTeamLeaderSelection);
      }
    }
  }, [flag, EditTeamData])
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Team leader",
        enableHiding: false,
        size: 50,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div>
            <CustomCheckBox
              checked={selectedTeamleader[row.original?.user_id]}
              onChange={() => {
                customeCheckBoxHandler(row.original?.user_id);
              }}
            />
          </div>
        ),
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
      },
      {
        accessorKey: "first_name",
        header: "First name",
        enableHiding: false,
        size: 120,
        enableGlobalFilter: true,
        Cell: ({ row }) => (
          <div>{row.original?.first_name ? row.original?.first_name : "-"}</div>
        ),
        muiTableHeadCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            paddingLeft: "0px !important",
          },
        },
      },
      {
        accessorKey: "last_name",
        header: "Last name",
        enableHiding: false,
        size: 80,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>{row.original?.last_name ? row.original?.last_name : "-"} </div>
        ),
      },
      {
        accessorKey: "roles",
        header: "Role",
        enableHiding: false,
        size: 300,
        enableGlobalFilter: false,
        Cell: ({ row }) => (
          <div>{row.original?.roles ? row.original?.roles : "-"} </div>
        ),
      },
      // {
      //   accessorKey: "roles",
      //   header: "Roles",
      //   enableHiding: false,
      //   size: 300,
      //   enableGlobalFilter: false,
      //   Cell: ({ row }) => (
      //     <div>
      //       {row.original?.roles?.[0]?.role_name
      //         ? row.original?.roles?.[0]?.role_name
      //         : "-"}{" "}
      //     </div>
      //   ),
      // },
    ],
    [selectedTeamleader]
  );
  const handleRowSelectionChange = (newRowSelection) => {
    setRowSelection(newRowSelection);
    setPreviousSelectedTeamManager(newRowSelection); // Assuming newRowSelection contains the selected team manager
  };
  const handleNext = () => {
    handleLeaderScreen(teamMember);
  };
  const handlePrevious = () => {
    handleBackOnLeaderScreen();
  };
  const renderEmptyRowsFallback = () => {
    if (teamLeaderData && teamLeaderData?.length === 0) {
      return (
        <div className="d-flex justify-content-center w-100 grey-text py-4">
          <>
            <div>
              You do not have any team added yet. Click on + Create team to
              create your first.
            </div>
          </>
        </div>
      );
    } else {
      return (
        <div
          className="d-flex justify-content-center w-100 grey-text py-3 radius-4"
          style={{ border: "1px solid #d1d1d1", backgroundColor: "#F9FAFA" }}
        >
          <>
            <div className="fs-14">
              Sorry! No result found. Please try again!
            </div>
          </>
        </div>
      );
    }
  };
  return (
    <>
      <div
        style={{ height: "450px", overflowY: "scroll" }}
        className="scrollable-container-y p-4 me-1"
      >
        {/* AssignUserToTeams */}
        {/* AssignProperties */}
        <div className="">
          <H4 className="form-label-title">
            Which users are teamleader in this team?
          </H4>

          <div className="light-font-text pt-3">
            Below is a list of all users assigned to the team. Select which
            users you want to be a teamleader.
          </div>
        </div>
        <MRTTheme
          colorVariantsForTableRow={["gray"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={columns}
              icons={{
                ArrowDownwardIcon: () => SortIcon?.ArrowDownwardIcon(sorting),
                SyncAltIcon: () => SortIcon?.SyncAltIcon(),
              }}
              data={teamLeaderData ?? []} //data is undefined on first render
              renderEmptyRowsFallback={renderEmptyRowsFallback}
              enableFilterMatchHighlighting={true}
              enableGlobalFilter
              globalFilterFn="contains"
              enableColumnActions={false}
              enableBottomToolbar={true}
              muiSearchTextFieldProps={{
                ...SearchStyle,

                InputProps: {
                  ...SearchIcon,
                },
              }}
              getRowId={(originalRow) => originalRow?.user_id}
              //give each row a more useful id
              onRowSelectionChange={handleRowSelectionChange}
              state={{ rowSelection, sorting: sorting }}
              onSortingChange={setSorting}
              enableSelectAll={true}
              enableRowSelection
              enableMultiRowSelection={false}
              enableRowActions={false}
              displayColumnDefOptions={{
                "mrt-row-select": {
                  Header: "Team manager",
                  size: 50,
                  enableSorting: true,
                  // enableSorting,
                  muiTableHeadCellProps: {
                    sx: {
                      paddingRight: "0px !important",
                      paddingLeft: "24px !important",
                    },
                  },
                  muiTableBodyCellProps: {
                    sx: {
                      paddingRight: "0px !important",
                      paddingLeft: "24px !important",
                    },
                  },
                },
              }}
              renderTopToolbar={({ table }) => (
                <>
                  <div className="pb-2">
                    <div className="d-flex justify-content-end">
                      <MRT_GlobalFilterTextField table={table} />
                    </div>
                  </div>
                </>
              )}
              muiTableContainerProps={{
                elevation: 0,
                sx: {
                  background: "white",
                  padding: "0px !important",
                },
              }}
              muiTablePaperProps={{
                sx: {
                  backgroundColor: "transparent",
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                },
              }}
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiPaginationProps={{
                ...paginationoptions,
              }}
            />
          }
        />
      </div>
      {/* footer section */}
      <div className="py-2 px-4 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container">
        <div className="d-flex gap-3">
          <ETButton
            variant={"white"}
            buttonText={usedFor == "teams" ? "BACK" : " CANCEL"}
            onClick={handlePrevious}
          />
        </div>

        <ETButton
          variant={"primary"}
          buttonText={usedFor == "teams" ? "NEXT" : "SAVE"}
          onClick={handleNext}
          disabled={Object.keys(rowSelection).length == 0}
        />
      </div>
    </>
  );
};

export default AssignLeaderToTeams;
