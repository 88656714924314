import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputAdornment } from "@mui/material";

export const paginationoptions = {
  rowsPerPageOptions: [10, 20, 50, 100, 250],
  labelRowsPerPage: "Display :",
};

export const SearchIcon = {
  endAdornment: (
    <InputAdornment position="end">
      <FontAwesomeIcon
        icon="fa-regular fa-magnifying-glass"
        size="xs"
        style={{ color: "#000000" }}
      />
    </InputAdornment>
  ),
};

export const SearchStyle = {
  // className: "",
  placeholder: `Search...`,
  variant: "outlined",
  size: "small",
  style: {
    width: "100% !important",
  },
};

export const TableRowsStyle = {
  boxShadow: "none",
};

export const SortIcon = {
  ArrowDownwardIcon: (sorting) => {
    return (
      <>
        {!sorting?.length ? (
          <div className="flex-column align-items-center d-flex ">
            <FontAwesomeIcon
              icon="fa-solid fa-sort"
              className="ms-2 ps-1"
              style={{ color: "#bfbfbf" }}
            />
          </div>
        ) : sorting?.[0]?.desc === false ? (
          <div
            className="d-flex flex-column"
            style={{ height: "22px", width: "22px" }}
          >
            <div style={{ height: "1px", width: "1px", marginLeft: "8px" }}>
              <FontAwesomeIcon
                icon="fa-solid fa-sort-up"
                style={{ color: "#888888" }}
              />
            </div>
            <span style={{ height: "1px", width: "1px", marginLeft: "8px" }}>
              <FontAwesomeIcon
                icon="fa-solid fa-sort-down"
                style={{ color: "#BFBFBF" }}
              />
            </span>
          </div>
        ) : (
          <div
            className="d-flex flex-column"
            style={{ height: "22px", width: "22px" }}
          >
            <div style={{ height: "1px", width: "1px", marginLeft: "8px" }}>
              <FontAwesomeIcon
                icon="fa-solid fa-sort-up"
                style={{ color: "#BFBFBF" }}
              />
            </div>
            <div style={{ height: "1px", width: "1px", marginLeft: "8px" }}>
              <FontAwesomeIcon
                icon="fa-solid fa-sort-down"
                style={{ color: "#888888" }}
              />
            </div>
          </div>
        )}
      </>
    );
  },
  SyncAltIcon: () => {
    return (
      <div
        className="d-flex flex-column"
        style={{ height: "22px", width: "22px" }}
      >
        <div style={{ height: "1px", width: "1px", marginLeft: "8px" }}>
          <FontAwesomeIcon
            icon="fa-solid fa-sort-up"
            style={{ color: "#00000080" }}
          />
        </div>
        <div style={{ height: "1px", width: "1px", marginLeft: "8px" }}>
          <FontAwesomeIcon
            icon="fa-solid fa-sort-down"
            style={{ color: "#00000080" }}
          />
        </div>
      </div>
      //    <div
      //    className="d-flex flex-column"
      //    style={{ height: "20px", width: "22px" }}
      //  >
      //    <div style={{ height: "1px", width: "1px", marginLeft: "8px" }}>
      //      <FontAwesomeIcon
      //        icon="fa-solid fa-sort"
      //        style={{ color: "#888888" }}
      //      />
      //    </div>
      //  </div>
    );
  },
};
