import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import ETButton from "../../../../Components/Buttons/ETButton";
import "./Popups.css";
import moment from "moment";
import { PostAddNote } from "../../../../services/Notes";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { useGetTerminationPopupDetails } from "../../../../hooks/queries/useActorQueries";
const MovingOutDetails = ({
  setMoveOutPopup,
  getNotesDetailsData,
  contractId,
  getNotesDetailsDataRefetch,
}) => {
  //------------------States-------------------//
  const [subjectLine, setSubjectLine] = useState("");
  const [message, setMessage] = useState("");

  //----------------React query-------------//
  const { data: getContractTerminationPopupDetailsData } =
    useGetTerminationPopupDetails(contractId);

  //----------------Validation---------------//
  const {
    register,
    handleSubmit,
    clearErrors,
    getValues,
    formState: { errors },
    setValue,
    control,
    reset,
  } = useForm();

  useEffect(() => {
    setValue(
      "movingOutDate",
      moment(
        getContractTerminationPopupDetailsData?.data?.[0]?.moving_out_date
      ).format("YYYY-MM-DD")
    );
    return () => { };
  }, [getContractTerminationPopupDetailsData]);

  const handleSendMovingOutNote = () => {
    const sendNotePayload = {
      category_id: "5",
      subject: subjectLine,
      message: message,
      contract_id: String(contractId),
    };

    PostAddNote(sendNotePayload).then((response) => {
      if (response?.data?.bReturn) {
        toast.success(response.data.message);
        setMessage("");
        setSubjectLine("");
        getNotesDetailsDataRefetch();
      }
    });
  };

  const handleConfirmMovingOutDetail = (data) => {
    // const updateLeasedDetailPayload = {
    //   contract_id: String(contractId),
    //   status_type_id: String(data?.currentStatus?.value),
    //   date_of_rental: data?.dateOfRental,
    //   moving_in_date: data?.movingInDate,
    // };
    // PutUpdateLeasedDetails(updateLeasedDetailPayload)
    //   .then((response) => {
    //     if (response?.data?.bReturn) {
    //       toast.success(response.data.message);
    //       setLeasedPopup(false);
    //       tenancyOverViewDataRefetch();
    //     }
    //   })
    //   .catch((error) => {
    //     toast.error(error?.message);
    //   });
  };
  return (
    <div>
      <Form>
        <div
          className="px-3 py-4"
          style={{ borderBottom: "1px Solid #F4F5F5" }}
        >
          <Row className="">
            <Col lg={3}>
              <Controller
                control={control}
                {...register("movingOutDate")}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <div className="form-input-group ">
                    <input
                      disabled={true}
                      type="date"
                      id="movingOutDate"
                      placeholder="movingOutDate"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        clearErrors("movingOutDate");
                      }}
                    />
                    <label htmlFor="movingOutDate">Moving out date</label>
                  </div>
                )}
              />
              {errors.movingOutDate && (
                <div className="error-msg">
                  This field is required
                </div>
              )}
            </Col>
          </Row>
        </div>
        <div className="px-3 py-4">
          <div className="mb-3 font-semibold fs-14">Add a note</div>
          <div className="deposit-text-area">
            <div className="pe-2 pt-2">
              <div
                style={{ height: "219px ", overflowY: "scroll" }}
                className="scrollable-container-y "
              >
                {getNotesDetailsData?.data?.filter(
                  (el) => el?.category_id === 5
                )?.length > 0 ? (
                  getNotesDetailsData?.data
                    ?.filter((el) => el?.category_id === 5)
                    ?.map((item, index) => (
                      <div className="card-div px-3 py-2 ms-3  mb-2">
                        <div className="d-flex justify-content-between">
                          <div
                            style={{
                              backgroundColor: item?.bg_color || "#9370DB",
                              color: "white",
                              borderRadius: "4px",
                              fontSize: "9px",
                              alignItems: "center",
                              textTransform: "uppercase",
                            }}
                            className="mt-1 px-2 d-flex font-semibold "
                          >
                            {item?.category_name}
                          </div>
                          <div className="fs-10">
                            {moment(item?.created_on)?.format(
                              "DD MMM, YYYY [at] hh:mm"
                            )}
                          </div>
                        </div>

                        <div className="mt-2 font-semibold fs-14">
                          {item?.subject}
                        </div>

                        <div className="mt-1 light-font-text ">
                          {item?.message}
                        </div>
                        <div className="mt-2 d-flex justify-content-between">
                          <div className="light-font-text fs-12">
                            by {item?.created_by}
                          </div>
                          <div>
                            <FontAwesomeIcon
                              icon="fa-regular fa-arrow-up-right-from-square"
                              style={{ color: "#000000" }}
                            />
                          </div>
                        </div>
                      </div>
                    ))
                ) : (
                  <div
                    className="d-flex justify-content-center w-100 fs-12 mt-2"
                    style={{ color: "#888888" }}
                  >
                    No notes found
                  </div>
                )}
              </div>
            </div>
            <div className="text-area p-3">
              <InputGroup className="deposit-textfield">
                <Form.Control
                  placeholder="Subject line"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  value={subjectLine}
                  onChange={(e) => setSubjectLine(e.target.value)}
                />
              </InputGroup>
              <InputGroup className="deposit-textarea">
                <Form.Control
                  as="textarea"
                  placeholder="Messages"
                  aria-label="Large"
                  aria-describedby="inputGroup-sizing-sm"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <InputGroup.Text
                  className="deposit-textarea-icon"
                  id="inputGroup-sizing-lg"
                >
                  <button
                    type="button"
                    disabled={
                      subjectLine?.length == 0 || (message?.length == 0 && true)
                    }
                    onClick={() => handleSendMovingOutNote()}
                    className="message-send-button"
                    style={{
                      opacity:
                        subjectLine?.length == 0 || message?.length == 0
                          ? "50%"
                          : "inherit",
                    }}
                  >
                    {
                      <FontAwesomeIcon
                        size="xs"
                        icon="fa-regular fa-paper-plane-top"
                        style={{ color: "#FFFFFF" }}
                      />
                    }
                  </button>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </div>
        <div
          className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center "
          style={{
            backgroundColor: "#F9FAFA",
            borderRadius: " 0px 0px 8px 8px",
            borderTop: "1px solid #F4F5F5",
          }}
        >
          <ETButton
            variant={"white"}
            buttonText="CLOSE"
            onClick={() => setMoveOutPopup(false)}
          />
          <ETButton
            variant={"primary"}
            buttonText="CONFIRM"
            onClick={() => setMoveOutPopup(false)}
          />
        </div>
      </Form>
    </div>
  );
};

export default MovingOutDetails;
