import React, { useEffect, useMemo } from "react";
import CustomToolTip from "../../../../../../../../../Components/Tooltip/CustomTooltip";
import DateRangeFilter from "../../../../../../../../../Components/DateRangePicker/DateRangeFilter";
import ReactSelect from "../../../../../../../../../Components/DropDown/ReactSelect";
import { Col, Row } from "react-bootstrap";
import MRTTheme from "../../../../../../../../../Components/table/MRTTheme";
import { MaterialReactTable } from "material-react-table";

import Menus from "../../../../../../../../../Components/Menus/Menus";
import {
	SortIcon,
	TableRowsStyle,
	paginationoptions,
} from "../../../../../../../../../Components/table/MRTGlobalProps";
import { SigningsTableData } from "../../requests/page/data";
import TagToolTip from "../../../../../../../../../Components/Tooltip/PropertyTagToolTip";
import {
	CircleMsg,
	GreenDiv,
	H3,
	NormalNotification,
} from "../../../../../../../../../styles/Common.style";
import { NoRecordsToDisplay } from "../../../../../../../../../utils/validationConstants";
import { useBreadcrumb } from "../../../../../../../../../Components/BreadcrumbContext ";

const Signings = () => {
	const { updateBreadcrumbs } = useBreadcrumb();
	useEffect(() => {
		updateBreadcrumbs(["Overview", "In Process", "Signings"]);
	}, []);
	const columns = useMemo(
		() => [
			{
				accessorFn: (row) => `${row.name}  `,
				header: "Name",
				enableHiding: false,
				// size: 100,
				enableGlobalFilter: true,
				Cell: ({ row }) => (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "8px",
							cursor: "pointer",
						}}
					>
						<div>
							<TagToolTip text="Need Help About Tags" />
						</div>
						<div>{row.original.name}</div>
					</div>
				),
			},
			{
				accessorKey: "attended_viewing",
				header: "Attended a viewing",
				enableHiding: false,
				// size: 100,
				enableGlobalFilter: true,
			},
			{
				accessorKey: "reservation_created",
				header: "Reservation created",
				enableHiding: false,
				// size: 20,
				enableGlobalFilter: false,
			},
			{
				accessorKey: "signing_started",
				header: "Signing started",
				enableHiding: false,
				// size: 180,
				enableGlobalFilter: false,
			},
			{
				accessorKey: "signing_deadline",
				header: "Signing deadline",
				enableHiding: false,
				// size: 20,
				enableGlobalFilter: false,
			},
			{
				accessorFn: (row) => `${row.name}  `,
				header: "Signing status",
				enableHiding: false,
				size: 80,
				enableGlobalFilter: false,
				Cell: ({ row }) => (
					<div
						className="px-2 radius-4 form-label-title fs-12"
						style={{
							backgroundColor: "#F1664B",
							cursor: "pointer",
							width: "fit-content",
						}}
					>
						1/4
					</div>
				),
			},
			{
				accessorKey: "responsible_agent",
				header: "Responsible agent",
				enableHiding: false,
				size: 200,
				enableGlobalFilter: false,
			},
		],
		[]
	);
	return (
		<div>
			<div className="d-flex w-100">
				<div className="p-4 mt-2 w-75">
					<div className="d-flex align-items-center">
						<H3> Signings </H3>
						<CircleMsg>
							<span>0</span>
						</CircleMsg>
					</div>
					<div className="light-font-text">
						Below is a list of the signatures in progress on this unit. It is
						possible to change or stop a signing order if necessary.
					</div>
				</div>
			</div>

			<div className="px-4 pt-2 pb-5">
				<MRTTheme
					colorVariantsForTableData={["odd"]}
					colorVariantsForTableHead={["white"]}
					table={
						<MaterialReactTable
							columns={columns}
							// data={SigningsTableData} //data is undefined on first render
							data={[]}
							icons={{ ...SortIcon }}
							localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
							enableColumnActions={false}
							enableRowSelection={false}
							enableRowActions
							enableSelectAll
							enableTopToolbar={true}
							enableGlobalFilter
							globalFilterFn="contains"
							initialState={{
								showGlobalFilter: true,
								showColumnFilters: false,
								expanded: true,
							}}
							renderTopToolbar={({ table }) => (
								<>
									<div className="px-0 ">
										<Row>
											<Col xl={4} lg={6} className="pb-2">
												<DateRangeFilter
												// date={dateRange}
												// setDate={setDateRange}
												// handleDateChange={handleDateChange}
												/>
											</Col>
											<Col xl={3} lg={5} className="ps-lg-0 pb-2">
												<ReactSelect placeholder={"Responsible agent"} />
											</Col>
										</Row>
									</div>
								</>
							)}
							muiTableContainerProps={{
								sx: {
									padding: "0px !important",
								},
							}}
							displayColumnDefOptions={{
								"mrt-row-actions": {
									header: "",
								},
							}}
							positionActionsColumn="last"
							renderRowActions={({ row }) => (
								<Menus
									itemheight={23}
									itemwidth={23}
									iconborderradius="4px"
									itemcontainerwidth="180px"
									component={
										<div className="light-font-text">
											<div className="p-2 cursor-pointer">Go to signing</div>
											<hr className="hr m-0"></hr>
											<div className="p-2 cursor-pointer">
												Go to contact card
											</div>
											<hr className="hr m-0"></hr>
											<div className="p-2 cursor-pointer">
												Edit signing order
											</div>
											<hr className="hr m-0"></hr>
											<div className="p-2 cursor-pointer">Delete</div>
										</div>
									}
								/>
							)}
							muiTableBodyRowProps={{
								selected: false,
							}}
							muiTablePaginationProps={{
								...paginationoptions,
							}}
							muiTableHeadRowProps={{
								sx: {
									...TableRowsStyle,
								},
							}}
						/>
					}
				/>
			</div>
		</div>
	);
};

export default Signings;
