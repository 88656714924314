import React, { useState } from "react";
import ETAccordion from "../../../../../Components/Accordion/ETAccordion";
import { H5 } from "../../../../../styles/Common.style";
import CustomToolTip from "../../../../../Components/Tooltip/CustomTooltip";
import Toggle from "../../../../../Components/Switch/Switch";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "../../../../../Components/DropDown/ReactSelect";

const OrganizationConfiguration = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleAccordionToggle = (accordionIndex) => {
    setActiveAccordion(
      activeAccordion === accordionIndex ? null : accordionIndex
    );
  };
  return (
    <div className="p-4">
      {" "}
      {configurationData?.map((item, i) => (
        <ETAccordion
          contextMenuRequired={false}
          accordionIndex={i}
          activeAccordion={activeAccordion}
          onAccordionToggle={handleAccordionToggle}
          header={
            <div className="p-4">
              <H5>{item?.accordingHeading}</H5>
            </div>
          }
          content={
            <div className="p-4">
              <div className="d-flex gap-2 align-items-center mb-3 ">
                <H5>Signing orders</H5>
                <CustomToolTip text={"Signing orders"} />
              </div>
              <div className="light-font-text mb-3">
                Define which email templates should be used in connection with
                this signing order
              </div>
              <div className=" d-flex align-items-center gap-2 mb-4">
                <Toggle id={1} checked={false} />
                <span className="light-font-text">
                  inherit all setting below to division
                </span>
              </div>
              <div className="mb-4">
                <Row>
                  <Col lg={5}>
                    <H5 className="mb-2">Signing order created</H5>
                    <span className="light-font-text mb-3">
                      Sent when a signing order is created
                    </span>
                    <div className="d-flex align-items-center gap-2">
                      <Toggle />{" "}
                      <span className="light-font-text">
                        Inherit setting to division
                      </span>
                    </div>
                  </Col>
                  <Col className="d-flex align-items-end" lg={7}>
                    <ReactSelect placeholder={"Signing order created"} />
                  </Col>
                </Row>
              </div>
              <div className="mb-4">
                <Row>
                  <Col lg={5}>
                    <H5 className="mb-2">Signing confirmation</H5>
                    <span className="light-font-text mb-3">
                      Sent when a signing order is completed
                    </span>
                    <div className="d-flex align-items-center gap-2">
                      <Toggle />{" "}
                      <span className="light-font-text ">
                        Inherit setting to division
                      </span>
                    </div>
                  </Col>
                  <Col className="d-flex align-items-end" lg={7}>
                    <ReactSelect placeholder={"Signing completed"} />
                  </Col>
                </Row>
              </div>
              <div className="mb-4">
                <Row>
                  <Col lg={5}>
                    <H5 className="mb-2">Reminder 1</H5>
                    <span className="light-font-text mb-3">
                      Reminders are sent according to the setting
                    </span>
                    <div className="d-flex align-items-center gap-2">
                      <Toggle />{" "}
                      <span className="light-font-text">
                        Inherit setting to division
                      </span>
                    </div>
                  </Col>
                  <Col className="d-flex align-items-end" lg={7}>
                    <ReactSelect placeholder={"Reminder 1"} />
                  </Col>
                </Row>
              </div>
              <div className="mb-4">
                <Row>
                  <Col lg={5}>
                    {" "}
                    <H5 className="mb-2">Reminder 2</H5>
                    <span className="light-font-text mb-3">
                      Reminders are sent according to the setting
                    </span>
                    <div className="d-flex align-items-center gap-2">
                      <Toggle />{" "}
                      <span className="light-font-text ">
                        Inherit setting to division
                      </span>
                    </div>
                  </Col>
                  <Col className="d-flex align-items-end" lg={7}>
                    <ReactSelect placeholder={"Reminder 2"} />
                  </Col>
                </Row>
              </div>
              <div className="mb-4">
                <Row>
                  <Col lg={5}>
                    {" "}
                    <H5 className="mb-2">Cancelled by customer</H5>
                    <span className="light-font-text mb-3">
                      Confirm customer cancellation of signing order
                    </span>
                    <div className="d-flex align-items-center gap-2">
                      <Toggle />{" "}
                      <span className="light-font-text ">
                        Inherit setting to division
                      </span>
                    </div>
                  </Col>
                  <Col className="d-flex align-items-end" lg={7}>
                    <ReactSelect placeholder={"Cancelled by customer"} />
                  </Col>
                </Row>
              </div>
              <div className="mb-4">
                <Row>
                  <Col lg={5}>
                    {" "}
                    <H5 className="mb-2">Cancelled by agent</H5>
                    <span className="light-font-text mb-3">
                      Confirms agents cancellation of signing order
                    </span>
                    <div className="d-flex align-items-center gap-2">
                      <Toggle />{" "}
                      <span className="light-font-text ">
                        Inherit setting to division
                      </span>
                    </div>
                  </Col>
                  <Col className="d-flex align-items-end" lg={7}>
                    <ReactSelect placeholder={"Cancelled by agent "} />
                  </Col>
                </Row>
              </div>
              <div className="mb-4">
                <Row>
                  <Col lg={5}>
                    {" "}
                    <H5 className="mb-2">Awaiting the initial payment</H5>
                    <span className="light-font-text mb-3 w-75">
                      Inform the customer that the signing order is awaiting
                      initial payment, before owner signes
                    </span>
                    <div className="d-flex align-items-center gap-2">
                      <Toggle />{" "}
                      <span className="light-font-text ">
                        Inherit setting to division
                      </span>
                    </div>
                  </Col>
                  <Col className="d-flex align-items-end" lg={7}>
                    <ReactSelect placeholder={"Awaiting the initial payment"} />
                  </Col>
                </Row>
              </div>
              <div className="mb-4">
                <Row>
                  <Col lg={5}>
                    <H5 className="mb-2">Signing order completed</H5>
                    <span className="light-font-text mb-3">
                      Confirms that all signatories in the signing order has
                      signed.
                    </span>
                    <div className="d-flex align-items-center gap-2">
                      <Toggle />{" "}
                      <span className="light-font-text ">
                        Inherit setting to division
                      </span>
                    </div>
                  </Col>
                  <Col className="d-flex align-items-end" lg={7}>
                    <ReactSelect placeholder={"Signing order finalised"} />
                  </Col>
                </Row>
              </div>
            </div>
          }
        />
      ))}
    </div>
  );
};

export default OrganizationConfiguration;

const configurationData = [
  {
    accordingHeading: "Appoinments",
  },
  {
    accordingHeading: "Events",
  },
  {
    accordingHeading: "Signing orders",
  },
  {
    accordingHeading: "Screening ",
  },
  {
    accordingHeading: "Contact (`$Maybe call it 'Other actions')",
  },
];
