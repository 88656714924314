import React from "react";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "../../../Components/DropDown/ReactSelect";
import { useMemo } from "react";
import MRTTheme from "../../../Components/table/MRTTheme";
import {
  MRT_GlobalFilterTextField,
  MaterialReactTable,
} from "material-react-table";

import Menus from "../../../Components/Menus/Menus";
import {
  SearchIcon,
  SearchStyle,
  SortIcon,
  TableRowsStyle,
  paginationoptions,
} from "../../../Components/table/MRTGlobalProps";
import { assignAgentTable } from "../StaticData";
import { NoRecordsToDisplay } from "../../../utils/validationConstants";

const Status = () => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        enableHiding: false,
        size: 110,
        enableGlobalFilter: true,
      },
      {
        accessorKey: "department",
        header: "Department",
        enableHiding: false,
        enableGlobalFilter: true,
      },
    ],
    []
  );
  return (
    <div className="px-4 py-3">
      <div className=" pt-1 pb-3 form-label-title">Status</div>
      <Row>
        <Col xl={3} lg={4} className="pb-3">
          <ReactSelect placeholder={"Status"} />
        </Col>
      </Row>
      <div className=" pt-4 pb-3 form-label-title">Assign to an agent</div>
      <div className="pb-5">
        <MRTTheme
          colorVariantsForTableData={["odd"]}
          colorVariantsForTableHead={["white"]}
          table={
            <MaterialReactTable
              columns={columns}
              data={[]}
              icons={{...SortIcon}}
              localization={{ noRecordsToDisplay: NoRecordsToDisplay }}
              enableColumnActions={false}
              enableTopToolbar={true}
              enableRowSelection={true}
              enableRowActions={false}
              enableSelectAll={false}
              enableFilterMatchHighlighting={true}
              enableGlobalFilter
              globalFilterFn="contains"
              initialState={{
                showGlobalFilter: true,
                showColumnFilters: false,
              }}
              muiTableContainerProps={{
                sx: {
                  padding: "0px !important",
                },
              }}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "",
                  size: 50,
                  muiTableBodyCellProps: {
                    sx: {
                      // padding: "0px !important",
                      backgroundColor: "#F0F0F0 !important",
                      border: "none !important",
                    },
                  },
                },
              }}
              renderTopToolbar={({ table }) => (
                <>
                  <Row>
                    <Col lg={3} className="pt-1 pb-3">
                      <MRT_GlobalFilterTextField table={table} />
                    </Col>
                  </Row>
                </>
              )}
              muiTablePaperProps={{
                selected: false,
                elevation: 0,
                fontFamily: "Cerebri sans-light !important",
                sx: {
                  borderRadius: "0",
                  border: "none",
                  // backgroundColor: "#F9FAFA",
                  table: {
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  },
                },
              }}
              muiSearchTextFieldProps={{
                ...SearchStyle,

                InputProps: {
                  ...SearchIcon,
                },
              }}
              muiTablePaginationProps={{
                ...paginationoptions,
              }}
              muiTableHeadRowProps={{
                sx: {
                  ...TableRowsStyle,
                },
              }}
            />
          }
        />
      </div>
    </div>
  );
};

export default Status;
