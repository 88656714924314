import React from "react";
import { Col, Row } from "react-bootstrap";
import { GetTextPackage } from "../../../../services/Texts";
import ETButton from "../../../../Components/Buttons/ETButton";
import { useQuery } from "react-query";
import { usePropertyOverview } from "../../../../hooks/stores/usePropertyStore";
import Card from "../../../../Components/card/TextCard";

const SelectTextPackage = ({
  tagName,
  setStepWizard,
  progress,
  setProgress,
  propertyText,
  setPropertyText,
}) => {
  // zustand
  const propOverview = usePropertyOverview((state) => state.propOverview);
  //-------------------------react query------------------------//
  const { data: textpackagesData, refetch: textpackagesDataRefetch } = useQuery(
    ["getTextPackage", propOverview?.[0]?.property_id, 0],
    () => GetTextPackage(propOverview?.[0]?.property_id, 0),
    { enabled: !!propOverview?.[0]?.property_id }
  );
  const handlefourthScreen = () => {
    setStepWizard("SelectFacilityPackage");
    setProgress(progress + 12.5);
  };

  const handleThridScreen = () => {
    setStepWizard("SelectMediaPackage");
    setProgress(progress - 12.5);
  };

  const CheckedTextCards = (event, item) => {
    if (event.target.checked) {
      setPropertyText((value) => [...value, item]);
    } else {
      setPropertyText((value) =>
        value.filter((it) => it?.property_text_id !== item?.property_text_id)
      );
    }
  };
  // console.log(propertyText, "property_text");
  return (
    <div>
      {" "}
      <div className="mb-5 ps-4 pt-3">
        <div className="fs-14 ">
          <span className="form-label-title">Tag name :&nbsp;</span>
          {tagName}
        </div>
        <div className="light-font-text grey-text pb-2 mb-4">
          Do you already have a plan for your new tag. It’s here possible to
          already put the tag on one of the text packages created on the
          property.
        </div>
        <div className="w-100">
          {textpackagesData?.data == 0 ? (
            <div
              className="grey-text w-100 light-font-text"
              style={{ textAlign: "center" }}
            >
              {" "}
              You do not have any package yet.{" "}
            </div>
          ) : (
            <>
              <Row>
                {textpackagesData?.data?.map((item, id) => (
                  <Col xl={4} lg={6} key={id} className="pb-4">
                    <Card
                      textDetailsData={item}
                      isTag={"AddTagT"}
                      checkboxOnChange={(e) => CheckedTextCards(e, item)}
                      checkValue={propertyText?.filter(
                        (x) => x.property_text_id === item?.property_text_id
                      )}
                    />
                  </Col>
                ))}
              </Row>
            </>
          )}
        </div>
      </div>
      <div
        className="py-2 px-3 d-flex flex-md-row flex-column justify-content-md-between  justify-content-center footer-section-button-container"
        style={{
          position: "absolute",
          width: "100%",
          bottom: "0",
        }}
      >
        <ETButton
          variant={"white"}
          buttonText="PREVIOUS"
          onClick={() => handleThridScreen()}
          fontSize="14px"
        />

        <ETButton
          variant={"primary"}
          buttonText="NEXT"
          onClick={() => {
            handlefourthScreen();
          }}
        />
      </div>
    </div>
  );
};

export default SelectTextPackage;
