import { useMutation, useQuery, useQueryClient } from "react-query";
import {
	GetScreeningByPropertyId,
	GetScreeningFlagTriggerAction,
	GetScreeningDeadlineAction,
	UpdateScreeningFormStatus,
	GetScreeningFormBasicDetails,
	GetScreeningCategoryByformId,
	GetScreening,
	UpdateScreeningActorStaus,
	GetScreeningLinkedUnit,
	UpdateScreeningFormUnits,
	AddScreeningForm,
	GetScreeningFields,
	UpdateScreeningForm,
	GetScreeningTemplate,
} from "../../services/Screening";

export const useGetScreeningByPropertyId = (id) => {
	return useQuery(
		["qGetScreeningByPropertyId", id],
		() => GetScreeningByPropertyId(id),
		{
			refetchOnWindowFocus: false,
			enabled: !!id,
		}
	);
};

export const useGetScreeningFlagTriggerAction = () => {
	return useQuery(
		["qGetScreeningFlagTriggerAction"],
		() => GetScreeningFlagTriggerAction(),
		{
			refetchOnWindowFocus: false,
		}
	);
};

export const useGetScreeningDeadlineAction = () => {
	return useQuery(
		"qGetScreeningDeadlineAction",
		() => GetScreeningDeadlineAction(),
		{
			refetchOnWindowFocus: false,
		}
	);
};

export const useGetScreeningFormBasicDetails = (formId) => {
	return useQuery(
		["qGetScreeningFormBasicDetails", formId],
		() => GetScreeningFormBasicDetails(formId),
		{
			refetchOnWindowFocus: false,
			// enabled: !!formId,
		}
	);
};

export const useGetScreeningCategoryByformId = (formId) => {
	return useQuery(
		["qGetScreeningCategoryByformId", formId],
		() => GetScreeningCategoryByformId(formId),
		{
			refetchOnWindowFocus: false,
			enabled: !!formId,
		}
	);
};
// export const useGetScreeningFormSectionDetails = (formId, categoryId) => {
// 	return useQuery(
// 		["qGetScreeningFormSectionDetails", formId, categoryId],
// 		() => GetScreeningFormSectionDetails(formId, categoryId),
// 		{
// 			refetchOnWindowFocus: false,
// 			enabled: !!formId,
// 			enabled: !!categoryId,
// 		}
// 	);
// };

export const useGetScreening = (formId) => {
	// status Id will be 1,2
	return useQuery(["qGetScreening", formId], () => GetScreening(formId, 1, 2), {
		refetchOnWindowFocus: false,
		enabled: !!formId,
	});
};

export const useGetScreeningFields = (categoryId) => {
	return useQuery(
		["qGetScreeningFields", categoryId],
		() => GetScreeningFields(categoryId),
		{
			refetchOnWindowFocus: false,
			enabled: !!categoryId,
		}
	);
};

//  Get Linked Unit
export const useGetScreeningLinkedUnit = (formId, propertyId) => {
	return useQuery(
		["qGetScreeningLinkedUnit", formId, propertyId],
		() => GetScreeningLinkedUnit(formId, propertyId),
		{
			refetchOnWindowFocus: false,
			enabled: !!formId && !!propertyId,
			// enabled: !!propertyId,
		}
	);
};

export const useUpdateScreeningFormStatus = () => {
	const queryClient = useQueryClient();

	return useMutation(UpdateScreeningFormStatus, {
		onSuccess: () => {
			queryClient.invalidateQueries("qGetScreeningByPropertyId");
			queryClient.invalidateQueries("qGetScreening");
		},
	});
};

export const useUpdateScreeningActorStaus = () => {
	const queryClient = useQueryClient();

	return useMutation(UpdateScreeningActorStaus, {
		onSuccess: () => {
			queryClient.invalidateQueries("qGetScreening");
		},
	});
};
//DELINK//
export const useUpdateScreeningFormUnits = () => {
	return useMutation(UpdateScreeningFormUnits);
};

export const useAddScreeningForm = () => {
	return useMutation(AddScreeningForm);
};

export const useUpdateScreeningForm = () => {
	return useMutation(UpdateScreeningForm);
};

export const useGetScreeningTemplate = (templateID) => {
	// console.log("templateID", templateID);
	return useQuery(
		["qGetScreeningTemplate", templateID],
		() => GetScreeningTemplate(templateID),
		{
			refetchOnWindowFocus: false,
			enabled: !!templateID,
		}
	);
};
