import create from "zustand";

export const useLoadingBarStore = create((set) => ({
  isLoading: false,
  progress: 0,
  speed: 0,
  setProgress: (data) => set({ progress: data }),
  setLoading: (isLoading) => set({ isLoading }),
  setLoaderSpeed: (value) => set({ speed: value }),
}));
